import React from 'react';
import { Box, IconButton, CircularProgress } from '@mui/material';
import { Icon } from '@iconify/react';
import Crud_Service from '../../apis/CrudService.jsx';
import { toast } from 'react-toastify';

const UploadSquareBoxes = ({
  handleRemove,
  id,
  imageUrl,
  setImageUrl,
  onChange,
  setComponents,
  setLoading,
  isLoading,
  fileTypeId,
  label = 'Upload Image',
}) => {
  const crud = new Crud_Service();

  const handleImageUpload = async (e) => {
    setLoading((prev) => ({ ...prev, [id]: true }));

    const file = e.target.files[0];
    if (file) {
      const reader = new FileReader();
      reader.onloadend = async () => {
        try {
          const fileFormData = new FormData();
          fileFormData.append('file', file);
          fileFormData.append('fileTypeId', fileTypeId);

          const response = await new Promise((resolve, reject) => {
            crud.create('files/upload', fileFormData, (err, res) => {
              if (res?.status === 200) {
                resolve(res);
              } else {
                reject(new Error('File upload failed'));
              }
            });
          });

          const fileId = response.data.fileId;
          const imagePath = reader.result;

          // Update the component's image and ID based on the uploaded file
          setComponents((prevComponents) =>
            prevComponents.map((component) =>
              component.id === id
                ? { ...component, path: imagePath, Id: fileId }
                : component,
            ),
          );

          if (onChange) {
            onChange(fileId); // Call onChange prop with the new file ID
          }
        } catch (error) {
          // toast.error('Error uploading file:', error);
        } finally {
          setLoading((prev) => ({ ...prev, [id]: false }));
        }
      };
      reader.readAsDataURL(file);
    } else {
      setLoading((prev) => ({ ...prev, [id]: false }));
    }
  };

  return (
    <Box className='relative'>
      <IconButton
        sx={{
          position: 'absolute',
          right: '-10px',
          top: '-3px',
          fontSize: '1.3rem',
          bgcolor: 'error.main',
          color: 'text.white',
          padding: '5px',
          '&:hover': {
            bgcolor: 'text.main',
          },
        }}
        onClick={() => handleRemove(id)}
      >
        <Icon icon='mdi:trash-outline' />
      </IconButton>
      <Box
        component='label'
        htmlFor={`file-upload-${id}`}
        className='inline-flex flex-col justify-center items-center cursor-pointer gap-1'
        sx={{
          border: '1px solid',
          padding: '10px',
          bgcolor: 'background.white',
          borderRadius: '7px',
          height: '100px',
          borderColor: 'border.main',
          backgroundImage: imageUrl ? `url(${imageUrl})` : 'none',
          backgroundSize: '95%',
          backgroundPosition: 'center',
          backgroundRepeat: 'no-repeat',
          width: '100px',
        }}
      >
        {!isLoading && !imageUrl && (
          <Box
            sx={{
              display: 'flex',
              flexDirection: 'column',
              justifyContent: 'center',
              alignItems: 'center',
              height: '100%',
            }}
          >
            <h3 className='text-2xl text-teal-500'>
              <Icon icon='ion:image-outline' />
            </h3>
            <p className='text-center'>{label}</p>
          </Box>
        )}
        {isLoading && <CircularProgress />}
        {imageUrl && !isLoading && <img src={imageUrl} alt='Uploaded' />}
        <input
          type='file'
          id={`file-upload-${id}`}
          className='hidden'
          onChange={(e) => {
            handleImageUpload(e);
            if (onChange) {
              onChange(e); // Call onChange prop if provided
            }
          }}
        />
      </Box>
    </Box>
  );
};

export default UploadSquareBoxes;
