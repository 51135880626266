import React from 'react';
import GlassCard from '../small/GlassCard.tsx';
import { Box } from '@mui/material';

const WidgetOne = ({ icon, title, count, record, path }) => {
  return (
    <GlassCard className='px-2 py-4  w-full relative min-h-full  text-center flex items-center flex-col justify-center hover:bg-zinc-100'>
      <Box
        sx={{ color: 'primary.main' }}
        className='text-4xl  font-semibold mb-2 '
      >
        {count}
      </Box>

      <Box>
        <Box color={'text.main'}>{title}</Box>
      </Box>
    </GlassCard>
  );
};

export default WidgetOne;
