import React, { useEffect } from 'react';
import DashboardLayout from '../../../../../components/DashboardLayout.tsx';
import EditSalesOrder from './EditSalesOrder.tsx';
import { BacktoList } from '../../../../../components/small/BackToButton.tsx';

const ViewCreateSalesQuotation = (props) => {
  const { type } = props;

  useEffect(() => {
    document.title = `View | Sales ${
      type === 2 ? 'Order | Accounts Receivable' : ''
    }${type === 3 ? 'Return | Accounts Receivable' : ''} ${
      type === 'quotation' ? 'Quotation | Work Order | FM Division' : ''
    }${type === 'order' ? 'Order | Work Order | FM Division' : ''} `;
  }, []);

  return (
    <DashboardLayout
      title={`View Sales ${type === 'quotation' ? 'Quotation' : 'Order'}`}
      actionButtons={<BacktoList />}
      titleReverse
    >
      <EditSalesOrder />
    </DashboardLayout>
  );
};

export default ViewCreateSalesQuotation;
