import { yupResolver } from '@hookform/resolvers/yup';
import { Box, Button } from '@mui/material';
import { Icon } from '@iconify/react';
import React, { useEffect, useMemo, useRef, useState } from 'react';
import { useForm } from 'react-hook-form';
import { useNavigate, useParams } from 'react-router-dom';
import { toast } from 'react-toastify';
import Crud_Service from '../../../../../apis/CrudService.jsx';
import TableActionButtons from '../../../../../components/_form/TableActionButtons.jsx';
import FmSearchableSelect from '../../../../../components/_mui/FmSearchableSelect.tsx';
import FmTextField from '../../../../../components/_mui/FmTextField.tsx';
import DashboardLayout from '../../../../../components/DashboardLayout.tsx';
import FmMRTDataTable from '../../../../../components/FmMRTDataTable.tsx';
import useTableLogic from '../../../../../components/helpers/MRTUseTableLogic.tsx';
import { PurchaseVoucherItemSchema } from '../../../../../utils/CommonvalidationSchemas.tsx';
import EditPV from './EditPV.tsx';
import { useHotkeys } from 'react-hotkeys-hook';
import { ViewField } from '../../../../../components/_form/FormElements.jsx';
import useCommonFetchApi from '../../../../../components/helpers/useCommonFetchApi.tsx';
import GlassCard from '../../../../../components/small/GlassCard.tsx';
import CurrencyView from '../../../../../components/_mui/FmCurrencyView.tsx';
import FmCurrencyTextField from '../../../../../components/_mui/FmCurrencyTextField.tsx';
import BillingFooter from '../../../../../components/view/BillingFooter.tsx';
import { BacktoList } from '../../../../../components/small/BackToButton.tsx';
import { NavigationEnum } from '../../../../../utils/CommonVariables.tsx';
import ElementLoader from '../../../../../components/page/ElementLoader.tsx';

const EditPurchaseVoucherList = (props) => {
  const { type } = props;
  const {
    control,
    setValue,
    reset,
    getValues,
    watch,
    formState: { isValid },
  } = useForm({
    resolver: yupResolver(PurchaseVoucherItemSchema),
    mode: 'all',
    defaultValues: {
      quantity: 0,
      rate: 0,
      gross: 0,
      discount: 0,
      vat: 0,
      vatAmount: 0,
      totalAmount: 0,
    },
  });
  const id = useParams()?.id;
  const firstColumnInputRef = useRef(null);
  const statusApprovedItemsRef = useRef(false);
  const [stateError, setStateError] = useState(false);
  const [editingRowIndex, setEditingRowIndex] = useState(null);
  const [editingRowId, setEditingRowId] = useState(null);
  const [editingRowData, setEditingRowData] = useState(null);
  const [loader, setLoader] = useState(false);
  const [formValues, setFormValues] = useState({});
  const [billingValues, setBillingValues] = useState([
    { name: 'Items', value: 0 },
    { name: 'Gross', value: '-' },
    { name: 'Discount', value: 0 },
    { name: 'VAT', value: 0 },
    { name: 'Total', value: 0 },
  ]);
  const specialLabels = ['VAT']; //for reference
  const currencyLabels = ['Gross', 'Discount', 'Total']; //for reference
  const [rowState, setRowState] = useState({
    editingRowId: null,
    newRowId: null,
    isCreatingRow: false,
  });
  const { data: salesorders } = useCommonFetchApi('salesorders');
  const { data: divisions } = useCommonFetchApi('divisions');
  const { data: employees } = useCommonFetchApi('employees');
  const { data: costCenters } = useCommonFetchApi('costCenters');

  const crud = new Crud_Service();
  const selectedSalesId = watch('salesOrderId');
  const selectedDivisionId = watch('divisionId');
  const selectedEmployeeId = watch('employeeId');
  const selectedCCDetail = watch('costCenterId');

  const useEntityEffect = <T,>(
    entities: T[],
    selectedId: string | null,
    setValue: (field: string, value: any) => void,
    idField: keyof T,
    fields: { formField: string; entityField: keyof T }[]
  ) => {
    const selectedEntity = useMemo(() => {
      if (!entities || !selectedId) return null;
      return entities.find((entity) => entity[idField] === selectedId) || null;
    }, [entities, selectedId, idField]);

    useEffect(() => {
      if (!selectedEntity) return;

      fields.forEach(({ formField, entityField }) => {
        setValue(formField, selectedEntity[entityField] ?? '');
      });
    }, [selectedEntity, setValue]);
  };

  useEntityEffect(salesorders, selectedSalesId, setValue, 'salesOrderId', [
    { formField: 'salesOrderId', entityField: 'salesOrderId' },
    { formField: 'salesOrderCode', entityField: 'autoSalesOrderCode' },
    { formField: 'salesOrderName', entityField: 'salesOrderName' },
    { formField: 'contractId', entityField: 'contractId' },
    { formField: 'contractName', entityField: 'contractName' },
    { formField: 'contractCode', entityField: 'contractCode' },
    { formField: 'projectId', entityField: 'projectId' },
    { formField: 'projectName', entityField: 'projectName' },
    { formField: 'projectCode', entityField: 'projectCode' },
  ]);

  useEntityEffect(divisions, selectedDivisionId, setValue, 'divisionId', [
    { formField: 'divisionId', entityField: 'divisionId' },
    { formField: 'divisionName', entityField: 'divisionName' },
    { formField: 'divisionCode', entityField: 'divisionCode' },
  ]);

  useEntityEffect(employees, selectedEmployeeId, setValue, 'employeeId', [
    { formField: 'employeeName', entityField: 'fullName' },
    { formField: 'employeeCode', entityField: 'employeeCode' },
  ]);

  useEntityEffect(costCenters, selectedCCDetail, setValue, 'costCenterId', [
    { formField: 'costCenterName', entityField: 'costCenterName' },
    { formField: 'costCenterCode', entityField: 'costCenterCode' },
  ]);
  const {
    rows,
    setRows,
    isLoading,
    pagination,
    sorting,
    columnFilters,
    globalFilter,
    tableRecordCounts,
    setPagination,
    setSorting,
    setColumnFilters,
    setGlobalFilter,
    fetchData,
    setSearchKeyword,
    searchKeyword,
  } = useTableLogic(
    `purchasevoucheritem?sortDirection=asc&purchaseVoucherId=${id}`
  );
  const [loading, setLoading] = useState(false);
  const navigate = useNavigate();

  const handleAddNewRow = () => {
    const newId = 'new_row_' + Date.now();
    reset();
    setEditingRowData(null);
    setEditingRowId(null);
    setFormValues(null);
    setRowState({
      newRowId: newId,
      isCreatingRow: true,
    });
    setEditingRowIndex(
      editingRowIndex === rows.length ? editingRowIndex + 1 : rows.length
    );
    setRows((prevRows) => [
      ...prevRows,
      {
        purchaseVoucherItemId: newId,
        purchaseVoucherId: id,
        salesOrderId: '',
        divisionId: '',
        inchargeId: '',
        accountId: '',
        quantity: '',
        costCenterId: '',
        rate: '',
        disount: '',
        vat: '',
        vatAmount: '',
        totalAmount: '',
        remarks: '',
        isEditable: true,
        isNew: true,
      },
    ]);
    // reset();
  };

  const handleBlurAndFetch = async () => {
    setLoader(true);

    const values = getValues();

    const updateCombinedData = {
      ...values,
      quantity: values?.quantity || 0,
      purchaseVoucherId: id,
      status: 2,
    };

    await crud.update(
      'purchasevoucheritem',
      editingRowId,
      updateCombinedData,
      (err, res) => {
        if (res?.status === 200) {
          const totalRows = rows.length;
          if (editingRowIndex <= totalRows && rows.some((row) => row.isNew)) {
            const updatedRow = res?.data;
            const updatedRows = [...rows];
            updatedRows[editingRowIndex] = {
              ...updatedRow,
              isEditable: true,
              isNew: true,
            };
            setRows(updatedRows);
            handleAddNewRow();
          } else {
            const updatedRow = res?.data;
            const updatedRows = [...rows];
            updatedRows[editingRowIndex] = {
              ...updatedRow,
              isEditable: false,
              isNew: false,
            };

            setRows(updatedRows);
            setStateError(false);
            setLoader(false);
          }
        } else {
          setStateError(true);
        }
      }
    );
  };
  const handleCancel = (row) => {
    setFormValues(null);
    if (row?.original?.purchaseVoucherItemId === rowState?.newRowId) {
      setRows((prevRows) => {
        if (prevRows?.length > 0) {
          return prevRows?.slice(0, -1);
        }
        return prevRows;
      });

      setRowState((prevState) => ({
        ...prevState,
        newRowId: null,
        isCreatingRow: false,
      }));
    } else {
      setEditingRowId(null);
    }
    reset();
  };

  const handleEdit = (row) => {
    if (statusApprovedItemsRef.current === true) return;
    reset();
    if (rowState?.newRowId) {
      setRows((prevRows) => {
        if (prevRows?.length > 0) {
          return prevRows?.slice(0, -1);
        }
        return prevRows;
      });
      setRowState((prevState) => ({
        ...prevState,
        newRowId: null,
        isCreatingRow: false,
      }));
    }
    setEditingRowId(row?.purchaseVoucherItemId);
    Object.entries({
      ...row,
    }).forEach(([key, value]) => {
      setValue(key, value);
    });
    setEditingRowData({ ...row });
  };

  useHotkeys('Enter', () => handleAddNewRow());

  useHotkeys(
    'Tab',
    (event) => {
      if (event.key !== 'Tab') return;
      if (stateError) {
        event.preventDefault();
        return;
      }
      const totalRows = rows.length;
      const nextRowIndex = editingRowIndex + 1;

      if (
        nextRowIndex < totalRows &&
        stateError === false &&
        loader === false
      ) {
        handleEdit(rows[nextRowIndex]);
        setEditingRowIndex(nextRowIndex);
      }
    },
    [editingRowIndex, rows, stateError, loader, isValid]
  );

  useEffect(() => {
    if (editingRowIndex !== null && firstColumnInputRef.current && !isLoading) {
      firstColumnInputRef.current.focus();
    }
  }, [editingRowIndex, isLoading]);

  const previousValues = useRef(getValues());

  const handleCancelAndAddNewRow = () => {
    const newId = 'new_row_' + Date.now();
    reset();
    setEditingRowData(null);
    setEditingRowId(null);
    setFormValues(null);
    setRowState({
      newRowId: newId,
      isCreatingRow: true,
    });
    setEditingRowIndex(
      editingRowIndex === rows?.length ? editingRowIndex + 1 : rows?.length
    );
    setRows((prevRows) => [
      ...prevRows,
      {
        purchaseVoucherItemId: newId,
        purchaseVoucherId: id,
        salesOrderId: '',
        divisionId: '',
        inchargeId: '',
        accountId: '',
        quantity: '',
        costCenterId: '',
        rate: '',
        disount: '',
        vat: '',
        vatAmount: '',
        totalAmount: '',
        remarks: '',
        isEditable: true,
        isNew: true,
      },
    ]);
    reset();
  };

  const handleBlur = () => {
    const currentValues = getValues();
    const value = getValues('salesOrderId');
    if (!value) {
      // Refocus the field if empty
      firstColumnInputRef.current.focus();
      return;
    }
    const hasChanges = Object.keys(currentValues).some(
      (key) => currentValues[key] !== previousValues.current[key]
    );

    if ((hasChanges && isValid) || (hasChanges && editingRowId)) {
      apiCallAsync(currentValues);
      previousValues.current = currentValues;
    }
  };

  const apiCallAsync = async (values) => {
    setLoader(true);

    if (editingRowId === null) {
      const convertedValues = {
        ...values,
        purchaseVoucherId: id,
        quantity: Number(values.quantity) || 0,
        rate: Number(values.rate) || 0,
        gross: Number(values.gross) || 0,
        discount: Number(values.discount) || 0,
        vat: Number(values.vat) || 0,
        totalAmount: Number(values.totalAmount) || 0,
      };
      try {
        await crud.create(
          'purchasevoucheritem',
          convertedValues,
          (err, res) => {
            if (res?.status === 201) {
              setEditingRowId(res?.data?.purchaseVoucherItemId);
              setStateError(false);
              setEditingRowData({});
            } else {
              setStateError(true);
            }
            setLoader(false);
          }
        );
      } catch (error) {
        setStateError(true);
        setLoader(false);
      }
    } else {
      const updatedCombinedData = {
        ...values,
        amount: Number(values.amount) || 0,
        purchaseVoucherItemId: id,
        quantity: Number(values.quantity) || 0,
        purchaseVoucherId: id,
        status: 2,
      };

      try {
        await crud.update(
          'purchasevoucheritem',
          editingRowId,
          updatedCombinedData,
          (err, res) => {
            if (res?.status === 200) {
              const updatedRow = res?.data;
              const updatedRows = [...rows];
              updatedRows[editingRowIndex] = {
                ...updatedRow,
                isEditable: rows.some((row) => row.isNew),
                isNew: rows.some((row) => row.isNew),
              };
              setRows(updatedRows);
              setStateError(false);
            } else {
              setStateError(true);
            }
            setLoader(false);
          }
        );
      } catch (error) {
        setStateError(true);
        setLoader(false);
      }
    }
  };
  const handleDelete = async (props) => {
    await crud.remove(
      'purchasevoucheritem',
      props?.purchaseVoucherItemId,
      (__err, res) => {
        if (res?.status === 204) {
          toast.success('Purchase Voucher Item Deleted Successfully');
          fetchData();
        } else {
        }
      }
    );
  };

  const ActionData = [
    {
      name: 'Delete',
      icon: <Icon icon='solar:trash-bin-minimalistic-broken' />,
      onClick: (props) => handleDelete(props),
      disabled: statusApprovedItemsRef.current === true ? true : false,
    },
  ];
  const columns = [
    {
      accessorKey: 'salesOrderId',
      header: 'Sales Order',
      enableEditing: true,
      Cell: ({ row }) => {
        const isNewRow =
          row?.original?.purchaseVoucherItemId === rowState?.newRowId;
        const isEditing =
          editingRowId === row?.original?.purchaseVoucherItemId || isNewRow;
        return isEditing ? (
          <FmSearchableSelect
            name='salesOrderId'
            control={control}
            apiUrl='salesorders/order-data'
            valueField='salesOrderId'
            headerField={['Sales Order', 'Project', 'Contract']}
            labelField={['salesOrderCode', 'projectName', 'contractName']}
            stackedFields={{
              salesOrderCode: [
                { Code: 'salesOrderCode' },
                { Name: 'salesOrderName' },
              ],
              projectName: [{ Name: 'projectName' }, { Code: 'projectCode' }],
              contractName: [
                { Name: 'contractName' },
                { Code: 'contractCode' },
              ],
            }}
            showField={['salesOrderCode', 'salesOrderName']}
            label='Sales Order'
            rules={{ required: 'Sales Order is required' }}
            defaultValue={{
              salesOrderId: editingRowData?.salesOrderId,
              salesOrderName: editingRowData?.salesOrderName,
            }}
            inputRef={firstColumnInputRef}
            onBlur={handleBlur}
            required
          />
        ) : (
          <div className='flex flex-col w-full'>
            <div className='flex items-center gap-1'>
              <span>Code:</span>
              {row?.original?.salesOrderCode}
            </div>
            <div className='font-semibold'>{row?.original?.salesOrderName}</div>
          </div>
        );
      },
    },

    {
      accessorKey: 'contractName',
      header: 'Contract',
      enableEditing: true,
      Cell: ({ row }) => {
        const isNewRow =
          row?.original?.purchaseVoucherItemId === rowState?.newRowId;
        const isEditing =
          editingRowId === row?.original?.purchaseVoucherItemId || isNewRow;
        return isEditing ? (
          <ViewField label='' title={watch('contractName')} />
        ) : (
          <div className='flex flex-col w-full'>
            <div className='flex items-center gap-1'>
              <span>Code:</span>
              {row?.original?.contractCode}
            </div>
            <div className='font-semibold'>{row?.original?.contractName}</div>
          </div>
        );
      },
    },
    {
      accessorKey: 'projectName',
      header: 'Projects',
      enableEditing: true,
      Cell: ({ row }) => {
        const isNewRow =
          row?.original?.purchaseVoucherItemId === rowState?.newRowId;
        const isEditing =
          editingRowId === row?.original?.purchaseVoucherItemId || isNewRow;
        return isEditing ? (
          <ViewField label='' title={watch('projectName')} />
        ) : (
          <div className='flex flex-col w-full'>
            <div className='flex items-center gap-1'>
              <span>Code:</span>
              {row?.original?.projectCode}
            </div>
            <div className='font-semibold'>{row?.original?.projectName}</div>
          </div>
        );
      },
    },
    {
      accessorKey: 'costCenterId',
      header: 'Cost Center',
      enableEditing: true,
      Cell: ({ row }) => {
        const isNewRow =
          row?.original?.purchaseVoucherItemId === rowState?.newRowId;
        const isEditing =
          editingRowId === row?.original?.purchaseVoucherItemId || isNewRow;
        return isEditing ? (
          <FmSearchableSelect
            name='costCenterId'
            control={control}
            apiUrl='costCenters'
            valueField='costCenterId'
            labelField={['costCenterCode', 'costCenterName']}
            showField={['costCenterCode', 'costCenterName']}
            label='Cost Center'
          />
        ) : (
          <div className='flex flex-col w-full'>
            <div className='flex items-center gap-1'>
              <span>Code:</span>
              {row?.original?.costCenterCode}
            </div>
            <div className='font-semibold'>{row?.original?.costCenterName}</div>
          </div>
        );
      },
    },
    {
      accessorKey: 'divisionId',
      header: 'Division',
      enableEditing: true,
      Cell: ({ row }) => {
        const isNewRow =
          row?.original?.purchaseVoucherItemId === rowState?.newRowId;
        const isEditing =
          editingRowId === row?.original?.purchaseVoucherItemId || isNewRow;

        return isEditing ? (
          <FmSearchableSelect
            name='divisionId'
            control={control}
            apiUrl='divisions'
            valueField='divisionId'
            labelField={['divisionCode', 'divisionName']}
            showField={['divisionCode', 'divisionName']}
            label='Division'
            onBlur={handleBlur}
            defaultValue={{
              divisionId: editingRowData?.divisionId,
              divisionName: editingRowData?.divisionName,
              divisionCode: editingRowData?.divisionCode,
            }}
          />
        ) : (
          <div className='flex flex-col w-full'>
            <div className='flex items-center gap-1'>
              <span>Code:</span>
              {row?.original?.divisionCode}
            </div>
            <div className='font-semibold'>{row?.original?.divisionName}</div>
          </div>
        );
      },
    },
    {
      accessorKey: 'incharge',
      header: 'InCharge',
      enableEditing: true,
      Cell: ({ row }) => {
        const isNewRow =
          row?.original?.purchaseVoucherItemId === rowState?.newRowId;
        const isEditing =
          editingRowId === row?.original?.purchaseVoucherItemId || isNewRow;
        return isEditing ? (
          <ViewField label='' title={watch('inChargeName')} />
        ) : (
          row?.original?.inChargeName || ''
        );
      },
    },
    {
      accessorKey: 'accountId',
      header: 'Purchase Account',
      enableEditing: true,
      Cell: ({ row }) => {
        const isNewRow =
          row?.original?.purchaseVoucherItemId === rowState?.newRowId;
        const isEditing =
          editingRowId === row?.original?.purchaseVoucherItemId || isNewRow;

        return isEditing ? (
          <FmSearchableSelect
            name='accountId'
            control={control}
            apiUrl='chartofaccounts'
            valueField='chartOfAccountId'
            labelField={['chartOfAccountCode', 'chartOfAccountName']}
            showField={['chartOfAccountCode', 'chartOfAccountName']}
            label='Purchase Account'
            onBlur={handleBlur}
            defaultValue={{
              accountId: editingRowData?.accountId,
              chartOfAccountName: editingRowData?.accountName,
            }}
          />
        ) : (
          row?.original?.accountName || ''
        );
      },
    },
    {
      accessorKey: 'quantity',
      header: 'Quantity',
      size: 100,
      enableEditing: true,
      Cell: ({ row }) => {
        const isNewRow =
          row?.original?.purchaseVoucherItemId === rowState?.newRowId;
        const isEditing =
          editingRowId === row?.original?.purchaseVoucherItemId || isNewRow;

        return isEditing ? (
          <FmTextField
            name='quantity'
            control={control}
            label='Quantity'
            type='number'
            onBlur={handleBlur}
            required
          />
        ) : (
          row?.original?.quantity || ''
        );
      },
    },
    {
      accessorKey: 'rate',
      header: 'Rate',
      size: 130,
      enableEditing: true,
      Cell: ({ row }) => {
        const isNewRow =
          row?.original?.purchaseVoucherItemId === rowState?.newRowId;
        const isEditing =
          editingRowId === row?.original?.purchaseVoucherItemId || isNewRow;

        return isEditing ? (
          <FmCurrencyTextField
            name='rate'
            control={control}
            label='Rate'
            labelRemove={true}
            onBlur={handleBlur}
            onChange={(e) =>
              setFormValues((prevValues) => ({
                ...prevValues,
                rate: e.target.value,
              }))
            }
            required
          />
        ) : (
          <CurrencyView value={row?.original?.rate} currencyType={'AED'} />
        );
      },
    },
    {
      accessorKey: 'gross',
      header: 'Gross',
      size: 100,
      enableEditing: true,
      Cell: ({ row }) => {
        const isNewRow =
          row?.original?.purchaseVoucherItemId === rowState?.newRowId;
        const isEditing =
          editingRowId === row?.original?.purchaseVoucherItemId || isNewRow;

        const gross = watch('gross');

        return isEditing ? (
          <ViewField label='Gross' title={gross} />
        ) : (
          <CurrencyView value={row?.original?.gross} currencyType={'AED'} />
        );
      },
    },
    {
      accessorKey: 'discount',
      header: 'Discount',
      size: 100,
      enableEditing: true,
      Cell: ({ row }) => {
        const isNewRow =
          row?.original?.purchaseVoucherItemId === rowState?.newRowId;
        const isEditing =
          editingRowId === row?.original?.purchaseVoucherItemId || isNewRow;

        return isEditing ? (
          <FmTextField
            name='discount'
            control={control}
            label='Discount'
            onBlur={handleBlur}
            onChange={(e) =>
              setFormValues((prevValues) => ({
                ...prevValues,
                discount: e.target.value,
              }))
            }
          />
        ) : (
          row?.original?.discount || ''
        );
      },
    },
    {
      accessorKey: 'vat',
      header: 'VAT(%)',
      size: 100,
      enableEditing: true,
      Cell: ({ row }) => {
        const isNewRow =
          row?.original?.purchaseVoucherItemId === rowState?.newRowId;
        const isEditing =
          editingRowId === row?.original?.purchaseVoucherItemId || isNewRow;

        return isEditing ? (
          <FmTextField
            name='vat'
            control={control}
            label='VAT'
            onBlur={handleBlur}
            onChange={(e) =>
              setFormValues((prevValues) => ({
                ...prevValues,
                vat: e.target.value,
              }))
            }
          />
        ) : (
          row?.original?.vat || ''
        );
      },
    },
    {
      accessorKey: 'vatAmount',
      header: 'VAT Amount',
      size: 100,
      enableEditing: true,
      Cell: ({ row }) => {
        const isNewRow =
          row?.original?.purchaseVoucherItemId === rowState?.newRowId;
        const isEditing =
          editingRowId === row?.original?.purchaseVoucherItemId || isNewRow;
        const vatAmount = watch('vatAmount');

        return isEditing ? (
          <ViewField label='vatAmount' title={vatAmount} />
        ) : (
          <CurrencyView value={row?.original?.vatAmount} currencyType={'AED'} />
        );
      },
    },
    {
      accessorKey: 'totalAmount',
      header: 'Total',
      size: 100,
      enableEditing: true,
      Cell: ({ row }) => {
        const isNewRow =
          row?.original?.purchaseVoucherItemId === rowState?.newRowId;
        const isEditing =
          editingRowId === row?.original?.purchaseVoucherItemId || isNewRow;
        const total = watch('totalAmount');
        return isEditing ? (
          <ViewField label='Total' title={total} />
        ) : (
          <CurrencyView
            value={row?.original?.totalAmount}
            currencyType={'AED'}
          />
        );
      },
    },
    {
      accessorKey: 'remarks',
      header: 'Remarks',
      enableEditing: true,
      Cell: ({ row }) => {
        const isNewRow =
          row?.original?.purchaseVoucherItemId === rowState?.newRowId;
        const isEditing =
          editingRowId === row?.original?.purchaseVoucherItemId || isNewRow;

        return isEditing ? (
          <FmTextField
            name='remarks'
            control={control}
            label='Remarks'
            onBlur={handleBlurAndFetch}
            multiline
            rows={2}
            maxRows={4}
          />
        ) : (
          row?.original?.remarks || ''
        );
      },
    },
    {
      header: 'Action',
      enableColumnPinning: true,
      enableEditing: false,
      enableSorting: false,
      size: 100,
      muiTableBodyCellProps: {
        className: 'sticky-pinning-right',
      },
      muiTableHeadCellProps: {
        className: 'sticky-pinning-right',
      },
      Cell: ({ row, table }) => {
        const isNewRow =
          row?.original?.purchaseVoucherItemId === rowState?.newRowId;
        const isEditing =
          editingRowId === row?.original?.purchaseVoucherItemId || isNewRow;

        const isLastRow = row.index === table.getRowModel().rows.length - 1;

        return (
          <Box className='flex gap-2'>
            {isEditing ? (
              <>
                <Button
                  onClick={() => handleCancel(row)}
                  className='py-0.5 px-2'
                  variant='contained'
                  color='error'
                >
                  Close
                </Button>
                {isLastRow && rowState?.isCreatingRow === false && (
                  <Button
                    onFocus={() => handleCancelAndAddNewRow()}
                    hidden={true}
                  ></Button>
                )}
              </>
            ) : (
              <Box className='flex gap-2'>
                <TableActionButtons
                  Actions={ActionData?.map((action) => ({
                    ...action,
                    onClick: () => action.onClick(row?.original),
                  }))}
                />
                {isLastRow && (
                  <Button
                    disabled={
                      statusApprovedItemsRef.current === true ? true : false
                    }
                    variant='contained'
                    color='primary'
                    className='px-3 py-1'
                    onClick={() => {
                      setEditingRowIndex(row?.index + 1);
                      handleAddNewRow();
                    }}
                  >
                    Add
                  </Button>
                )}
              </Box>
            )}
          </Box>
        );
      },
    },
  ];

  useEffect(() => {
    if (rows?.length) {
      if (
        editingRowId !== null &&
        (formValues?.discount || formValues?.vat || formValues?.rate)
      ) {
        // Correct condition
        const quantity = Number(watch('quantity')) || 0;
        const rate = Number(watch('rate')) || 0;
        const gross = (quantity * rate).toFixed(2);
        setValue('gross', gross);

        // Calculate discount, VAT, and total amount
        const grossValue = Number(watch('gross')) || 0;
        const discountValue = Number(watch('discount')) || 0;
        const vatPercentage = Number(watch('vat')) || 0;
        const discountAmount = grossValue - discountValue;
        const vatAmount = (discountAmount * vatPercentage) / 100;
        const totalAmount = discountAmount + vatAmount;

        setValue('vatAmount', vatAmount.toFixed(2));
        setValue('totalAmount', totalAmount.toFixed(2));
      }

      const filteredRows = editingRowId
        ? rows.filter((row) => row.purchaseVoucherItemId !== editingRowId)
        : rows;

      const sumField = (field) =>
        filteredRows.reduce(
          (total, row) => total + parseFloat(row[field] || 0),
          0
        );

      const averageField = (field) =>
        filteredRows.length
          ? (sumField(field) / filteredRows.length).toFixed(2)
          : '0.00';

      const grossSum = sumField('gross');
      const totalSum = sumField('totalAmount');
      const discountAvg = sumField('discount');
      const vatAvg = averageField('vat');

      const editedGross = Number(watch('gross') || 0);
      const editedTotalAmount = watch('totalAmount');

      // Update billing values
      setBillingValues((prev) =>
        prev.map((item) => {
          const fieldValues = {
            Items: rows.length || 0,
            Gross: (Number(grossSum) || 0) + (Number(editedGross) || 0),
            Discount: (
              (Number(discountAvg) || 0) +
              parseFloat(formValues?.discount || '0')
            ).toFixed(2),
            VAT: (
              (Number(vatAvg) || 0) +
              parseFloat(formValues?.vat || editingRowData?.vat || '0')
            ).toFixed(2),
            Total: (
              (parseFloat(totalSum) || 0) + (parseFloat(editedTotalAmount) || 0)
            ).toFixed(2),
          };

          return fieldValues[item.name] !== undefined
            ? {
              ...item,
              value: isNaN(fieldValues[item.name])
                ? 0
                : fieldValues[item.name],
            }
            : item;
        })
      );
    }
  }, [rows, formValues?.discount, formValues?.vat, formValues?.rate]);

  const onNavClick = (direction: boolean) => {
    setLoading(true);
    crud.getAll(
      `navigations/${NavigationEnum.PURCHASE_VOUCHER}/api/${id}/navigation/${direction}`,
      '',
      (err, res) => {
        setLoading(false);
        navigate(
          `/financial/accounts-payable/purchase-voucher/edit/${res?.data?.id}`
        );
      }
    );
  };

  return (
    <>
      <DashboardLayout
        title={'Purchase Voucher Items'}
        actionButtons={<BacktoList />}
        titleReverse
        onNavClick={onNavClick}
      >
        {loading ? (
          <ElementLoader image fly />
        ) : (
          <Box className='flex flex-col gap-5'>
            <EditPV
              type={type}
              id={id}
              statusApprovedItemsRef={statusApprovedItemsRef}
            />

            <GlassCard className='h-[450px]'>
              <FmMRTDataTable
                rows={rows}
                columns={columns}
                isCreatingRow={rowState?.isCreatingRow}
                enableRowSelection={false}
                rowCount={tableRecordCounts}
                editingRow={editingRowId}
                pagination={pagination}
                fetchData={fetchData}
                sorting={sorting}
                columnFilters={columnFilters}
                globalFilter={globalFilter}
                setPagination={setPagination}
                setSorting={setSorting}
                setColumnFilters={setColumnFilters}
                setGlobalFilter={setGlobalFilter}
                isLoading={isLoading}
                onCreateRow={handleAddNewRow}
                setSearchKeyword={setSearchKeyword}
                searchKeyword={searchKeyword}
                handleRowDoubleClick={(row) => {
                  handleEdit(row?.original);
                  setEditingRowIndex(row?.index);
                }}
                showSerialNumber={true}
                muiTableContainerProps={{
                  sx: {
                    maxHeight: '100%',
                    flex: 1,
                  },
                }}
              />
            </GlassCard>
            <BillingFooter
              billingValues={billingValues}
              specialLabels={specialLabels}
              currencyLabels={currencyLabels}
            />
          </Box>
        )}
      </DashboardLayout>
    </>
  );
};

export default EditPurchaseVoucherList;
