import React, { useEffect, useState } from 'react';
import { Checkbox, IconButton } from '@mui/material';
import { Controller, useForm } from 'react-hook-form';
import GlassCard from '../../../../components/small/GlassCard.tsx';
import FmFileInput from '../../../../components/_mui/FmFileInput.tsx';
import Crud_Service from '../../../../apis/CrudService.jsx';
import useTableLogic from '../../../../components/helpers/MRTUseTableLogic.tsx';
import { Icon } from '@iconify/react/dist/iconify.js';
import FmMRTDataTable from '../../../../components/FmMRTDataTable.tsx';
import { toast } from 'react-toastify';
import DeleteIcon from '@mui/icons-material/Delete';
import ActionButtons from '../../../../components/_form/ActionButtons.tsx';
import useLoader from '../../../../components/helpers/UseLoader.tsx';

const InternalSalesInvoiceChecklist = (props) => {
  const {
    contractItemId,
    internalInvoiceId,
    clientId,
    openChecklist,
    setOpenChecklist,
    handleModalClose,
  } = props;
  const [rowState, setRowState] = useState({
    editingRowId: null,
    newRowId: null,
    isCreatingRow: false,
  });
  const [editingRowId, setEditingRowId] = useState(null);
  const crud = new Crud_Service();
  const { startCreateLoading, stopCreateLoading, editLoading } = useLoader();
  const {
    rows,
    setRows,
    isLoading,
    pagination,
    sorting,
    columnFilters,
    globalFilter,
    tableRecordCounts,
    setPagination,
    fetchData,
    setSorting,
    setColumnFilters,
    setGlobalFilter,
    setSearchKeyword,
    searchKeyword,
  } = useTableLogic(
    `invoicechecklists`,
    ['internalInvoiceId'],
    [internalInvoiceId]
  );
  const {
    control,
    reset,
    setValue,
    formState: { isValid, errors },
    trigger,
    handleSubmit,
  } = useForm({});
  const [rowLoading, setRowLoading] = useState({});
  const [rules, setRules] = useState(null);

  useEffect(() => {
    if (editingRowId) {
      const rowData = rows?.find(
        (row) => row?.invoiceChecklistId === editingRowId
      );

      if (rowData) {
        setValue('fileId', rowData?.fileId || '');
      }
    }
  }, [editingRowId, rowState, setValue, setRules, rows]);

  const handleCheckboxChange = (row) => {
    const updatedRow = {
      ...row,
      isMandatoryForInvoiceGeneration: !row.isMandatoryForInvoiceGeneration,
    };
    setRows((prevRows) =>
      prevRows.map((r) =>
        r.invoiceChecklistId === row.invoiceChecklistId ? updatedRow : r
      )
    );
    handleUpdateInvoiceChecklist(
      updatedRow,
      !row.isMandatoryForInvoiceGeneration,
      row.fileId
    );
  };

  const handleFileUpload = async (event, rowId) => {
    setRowLoading((prev) => ({ ...prev, [rowId]: true }));
    const file = event.target.files[0];
    if (file) {
      const fileFormData = new FormData();
      fileFormData.append('file', file);
      fileFormData.append('fileTypeId', 74);
      try {
        const response = await new Promise((resolve, reject) => {
          crud.create('files/upload', fileFormData, (_err, res) => {
            if (res?.status === 200) {
              resolve(res);
            } else {
              reject(new Error('File upload failed'));
            }
          });
        });

        setRows((prevRows) => {
          return prevRows.map((row) => {
            if (row.invoiceChecklistId === rowId) {
              const updatedRow = {
                ...row,
                fileId: response?.data?.fileId,
                documentName: response?.data?.originalFileName,
              };
              handleUpdateInvoiceChecklist(
                updatedRow,
                row.isMandatoryForInvoiceGeneration,
                response?.data?.fileId
              );
              return updatedRow;
            }
            return row;
          });
        });
      } catch (error) {
        // Handle error
      } finally {
        setRowLoading((prev) => ({ ...prev, [rowId]: false }));
      }
    }
  };

  const handleUpdateInvoiceChecklist = async (
    values,
    isMandatoryForInvoiceGeneration,
    fileId
  ) => {
    const updateCombinedData = {
      invoiceChecklistId: values.invoiceChecklistId || 0,
      invoiceChecklistTypeId: values.invoiceChecklistTypeId || 0,
      contractChecklistId: values.contractChecklistId || 0,
      isMandatoryForInvoiceGeneration: isMandatoryForInvoiceGeneration,
      isChecklistFile:
        values.isChecklistFile !== undefined ? values.isChecklistFile : null,
      fileId: fileId || null,
      remarks: 't',
      status: 2,
    };
    await crud.update(
      `invoicechecklists/${internalInvoiceId}`,
      '',
      updateCombinedData,
      (err, res) => {
        if (res?.status === 200) {
          toast.success('Invoice Checklist Updated');
          fetchData();
        }
      }
    );
  };

  const columns = [
    {
      accessorKey: 'invoiceChecklistTypeName',
      header: 'Check List Type',
      enableEditing: true,
      Cell: ({ row }) => {
        return row?.original?.invoiceChecklistTypeName;
      },
    },
    {
      accessorKey: 'documentRequired',
      header: 'Document Required',
      enableEditing: true,
      Cell: ({ row }) => {
        return (
          <>
            <Checkbox
              checked={row.original.isMandatoryForInvoiceGeneration}
              onChange={() => handleCheckboxChange(row.original)}
            />
            {row?.original?.isMandatoryForInvoiceGeneration && (
              <p className='error text-red-600 font-semibold font-size-sm'>
                {'Required'}
              </p>
            )}
          </>
        );
      },
    },
    {
      accessorKey: 'fileId',
      header: 'Upload',
      size: 150,
      Cell: ({ row }) => {
        return !row?.original?.fileName ? (
          <>
            <Controller
              name='fileId'
              control={control}
              rules={rules?.fileId}
              render={({ field }) => (
                <FmFileInput
                  {...field}
                  documentName={row?.original?.documentName || ''}
                  onChange={(e) => {
                    handleFileUpload(e, row.original.invoiceChecklistId);
                    field.onChange(e);
                    trigger('fileId');
                  }}
                  loading={rowLoading[row.original.invoiceChecklistId] || false}
                />
              )}
            />
            {row?.original?.isChecklistFile && (
              <p className='error text-red-600 font-semibold font-size-sm'>
                {'File Required'}
              </p>
            )}
          </>
        ) : (
          <div style={{ display: 'flex', alignItems: 'center' }}>
            <span style={{ marginRight: '8px' }}>
              {row?.original?.fileName}
            </span>
            <Icon
              icon='ic:baseline-attachment'
              style={{ fontSize: '24px' }}
              className='text-gray-400'
              color='inherit'
            />
            <IconButton
              onClick={() => {
                handleUpdateInvoiceChecklist(
                  { ...row.original, fileId: null },
                  row.original.isMandatoryForInvoiceGeneration,
                  null
                );
                setRows((prevRows) =>
                  prevRows.map((r) =>
                    r.invoiceChecklistId === row.original.invoiceChecklistId
                      ? { ...r, fileId: null, documentName: null }
                      : r
                  )
                );
              }}
              className='remove-file-button'
              color='secondary'
              aria-label='remove file'
              style={{ marginLeft: '8px' }}
            >
              <DeleteIcon />
            </IconButton>
          </div>
        );
      },
    },
  ];

  const onSubmitReadyForInvoice = async (values) => {
    startCreateLoading();

    await crud.create(
      `internalinvoices/process/${internalInvoiceId}`,
      values,
      (_err, res) => {
        if (res?.status === 201) {
          stopCreateLoading();
          toast.success('Ready to Invoice Created Successfully');
          handleModalClose();
          window.location.reload();
          reset();
          fetchData();
          setOpenChecklist(false);
        } else {
          stopCreateLoading();
        }
      }
    );
  };
  return (
    <>
      {' '}
      <GlassCard className='h-[450px]'>
        <FmMRTDataTable
          rows={rows}
          columns={columns}
          muiTableContainerProps={{
            sx: {
              maxHeight: '100%',
              flex: 1,
            },
          }}
          enableRowSelection={false}
          rowCount={tableRecordCounts}
          editingRow={editingRowId}
          pagination={pagination}
          sorting={sorting}
          columnFilters={columnFilters}
          globalFilter={globalFilter}
          setPagination={setPagination}
          showPagination={false}
          setSorting={setSorting}
          fetchData={fetchData}
          setColumnFilters={setColumnFilters}
          setGlobalFilter={setGlobalFilter}
          isLoading={isLoading}
          setSearchKeyword={setSearchKeyword}
          searchKeyword={searchKeyword}
        />
      </GlassCard>
      <ActionButtons onSubmit={handleSubmit(onSubmitReadyForInvoice)} />
    </>
  );
};

export default InternalSalesInvoiceChecklist;
