import React, { useEffect, useState } from 'react';
import DashboardLayout from '../../../components/DashboardLayout.tsx';
import PageHeader from '../../../components/PageHeader';
import DataTable from '../../../components/DataTable.tsx';
import GlassCard from '../../../components/small/GlassCard.tsx';
import TableActionButtons from '../../../components/_form/TableActionButtons';
import { ViewField, StatusBadge } from '../../../components/_form/FormElements';
import { Icon } from '@iconify/react';
import { Box, Grid } from '@mui/material';
import { CreateButton } from '../../../components/small/Buttons';
import TableSearch from '../../../components/_form/TableSearch.jsx';
import MuiDialogOne from '../../../components/_mui/MuiDialogOne';
import { Nav } from '../../../utils';
import { DocumentType } from '../../../interfaces/EmployeeMasterInterfaces';
import Crud_Service from '../../../apis/CrudService';
import { useForm, SubmitHandler } from 'react-hook-form';
import CommonLoader from '../../../components/page/CommonLoader';
import { useTranslation } from 'react-i18next';
import useLoader from '../../../components/helpers/UseLoader.tsx';
import ActionButtons from '../../../components/_form/ActionButtons.tsx';
import useTableLogic from '../../../components/helpers/UseTableLogic.tsx';
import FmTextField from '../../../components/_mui/FmTextField.tsx';
import FmAutoComplete from '../../../components/_mui/FmAutoComplete.tsx';
import DataTableToolbarButtons from '../../../components/helpers/DataTableToolbarButtons.tsx';
import apiInstance from '../../../apis/ApiService.jsx';
import { toast } from 'react-toastify';

const DocumentTypeMasters = () => {
  const [openCreate, setOpenCreate] = React.useState(false);
  const [openView, setOpenView] = React.useState(false);
  const [documenttypecategories, setDocumentTypeCategories] = useState([]);
  const [viewData, setViewData] = useState();
  const [editModal, setEditModal] = useState(false);
  const crud = new Crud_Service();
  const { t } = useTranslation();
  const { handleSubmit, setValue, control, reset } = useForm<DocumentType>();
  const {
    createLoading,
    startCreateLoading,
    stopCreateLoading,
    editLoading,
    startEditLoading,
    stopEditLoading,
  } = useLoader();
  const {
    rows,
    pageSize,
    pageNumber,
    setSearchKeyword,
    isLoading,
    tableRecordCounts,
    searchKeyword,
    handlePaginationModelChange,
    handleSortModelChange,
    fetchData,
  } = useTableLogic('documenttypes');
  const [loading, setLoader] = useState(false);
  const statusOptions = [
    { statusId: 2, statusName: 'Active' },
    { statusId: 1, statusName: 'Inactive' },
  ];

  useEffect(() => {
    document.title = `HRMS-Document Types`;
    getDocumentTypeCategories();
  }, []);

  //get Document Type Catergory
  const getDocumentTypeCategories = async () => {
    await crud.getAll('documenttypecategories', '', (err, res) => {
      if (err) {
        toast.error(
          'Something Went Wrong fetching Document Type Categories:',
          err
        );
        return;
      }
      if (res?.status === 200) {
        setDocumentTypeCategories(res?.data?.data);
      }
    });
  };

  //create DocumentType
  const handleSubmitDocumentType: SubmitHandler<DocumentType> = async (
    values
  ) => {
    startCreateLoading();
    const combinedData = {
      ...values,
      documentTypeCategory: Number(values?.documentTypeCategory),
    };

    await crud.create('documenttypes', combinedData, (_err, res) => {
      if (res?.status === 201) {
        toast.success('Document Type Created Successfully');
        setOpenCreate(false);
        stopCreateLoading();
        fetchData();
        reset();
      } else {
        stopCreateLoading();
        setOpenCreate(true);
      }
    });
  };

  const handleCreateClickOpen = () => {
    reset();
    setOpenCreate(true);
  };
  const handleCreateClose = () => {
    reset();
    setOpenCreate(false);
  };

  const handleViewClickOpen = async (view) => {
    setOpenView(true);
    setLoader(true);
    await crud.getSingle('documenttypes', view?.documentTypeId, (_err, res) => {
      if (res?.status === 200) {
        setViewData(res?.data);
        setLoader(false);
      } else {
      }
    });
  };

  //setFormValues
  const handleEditClick = async (view) => {
    setEditModal(true);
    setLoader(true);
    await crud.getSingle('documenttypes', view?.documentTypeId, (_err, res) => {
      if (res?.status === 200) {
        Object.entries({
          ...res?.data,
        }).forEach(([key, value]) => {
          setValue(key, value);
        });
        setLoader(false);
      } else {
        setLoader(true);
      }
    });
  };

  //Update DocumentTypes
  const handleEditDocumentType = async (values) => {
    startEditLoading();
    await crud.update(
      'documenttypes',
      values?.documentTypeId,
      values,
      (_err, res) => {
        if (res?.status === 200) {
          toast.success('Document Type Updated Successfully');
          setEditModal(false);
          stopEditLoading();
          fetchData();
        } else {
          stopEditLoading();
          setEditModal(true);
        }
      }
    );
  };

  //Delete DocumentTypes
  const handleDeleteClick = async (view) => {
    await crud.remove('documenttypes', view?.documentTypeId, (_err, res) => {
      if (res?.status === 204) {
        toast.success('Document Type Deleted Successfully');
        fetchData();
      } else {
      }
    });
  };

  const handleViewClose = () => {
    setOpenView(false);
  };

  const counters = [
    {
      name: 'All',
      count: tableRecordCounts?.total || 0,
    },
    {
      name: 'Active',
      count: tableRecordCounts?.active || 0,
    },
    {
      name: 'Deactivated',
      count: tableRecordCounts?.inActive || 0,
    },
  ];

  const ActionData = [
    {
      name: t('view'),
      icon: <Icon icon='solar:eye-linear' />,
      onClick: (props) => handleViewClickOpen(props),
    },
    {
      name: t('edit'),
      icon: <Icon icon='solar:pen-new-round-linear' />,
      onClick: (props) => handleEditClick(props),
    },
    {
      name: t('delete'),
      icon: <Icon icon='solar:trash-bin-2-linear' />,
      onClick: (props) => handleDeleteClick(props),
      danger: true,
    },
  ];

  const columns = [
    {
      field: 'action',
      headerName: '',
      width: 140,
      sortable: false,
      disableClickEventBubbling: false,

      renderCell: (params) => {
        return (
          <TableActionButtons
            Actions={ActionData?.map((action) => ({
              ...action,
              onClick: () => action.onClick(params.row),
            }))}
          />
        );
      },
    },
    {
      field: 'documentTypeCode',
      headerName: t('documentTypeCode'),
      flex: 1,
    },
    {
      field: 'documentTypeName',
      headerName: t('documentTypeName'),
      flex: 1,
    },

    {
      field: 'status',
      headerName: t('status'),
      flex: 1,
      renderCell: (params) => {
        return (
          <StatusBadge
            title={params.value === 2 ? 'Active' : 'Inactive'}
            type={params.value === 2 ? 'green' : 'red'}
          />
        );
      },
    },
  ];
  const downloadDocTypes = async () => {
    const downloadUrl = await apiInstance.getFiles('documenttypes/exportexcel');

    const link = document.createElement('a');
    link.href = downloadUrl;
    link.setAttribute('download', 'DocumentType.xlsx');
    document.body.appendChild(link);
    link.click();
    document.body.removeChild(link);
  };

  return (
    <DashboardLayout
      title='Document Type'
      actionButtons={
        <>
          <PageHeader counters={counters} />
        </>
      }
      hasSubmenu
      menu={Nav[0].child[5].children}
      parentMenu={Nav[0].child[5].name}
    >
      <GlassCard className='h-full'>
        <Box className='h-full'>
          <DataTable
            rows={rows}
            columns={columns}
            loading={isLoading}
            getRowClassName={(params) =>
              `${params.row.status === 0 && 'bg-red-50'}`
            }
            sortingMode='server'
            paginationMode='server'
            onPaginationModelChange={(model) =>
              handlePaginationModelChange(model)
            }
            onSortModelChange={(model) => handleSortModelChange(model)}
            page={pageNumber - 1}
            pageSize={pageSize}
            rowCount={tableRecordCounts?.total}
            slots={{
              toolbar: () => (
                <Box
                  className='p-2 w-full flex justify-between items-center'
                  sx={{
                    borderBottom: '1px solid',
                    borderColor: 'border.main',
                  }}
                >
                  <Box sx={{ maxWidth: '250px' }}>
                    <TableSearch
                      placeholder='Search'
                      fullWidth
                      setSearchKeyword={setSearchKeyword}
                      searchValue={searchKeyword}
                    />
                  </Box>
                  <Box className='flex items-center gap-4 justify-end'>
                    <CreateButton name='Create' onClick={handleCreateClickOpen} />
                    <DataTableToolbarButtons
                      disableColumn
                      handleExcelExport={downloadDocTypes}
                    />
                  </Box>
                </Box>
              ),
            }}
          />
        </Box>
      </GlassCard>

      {/* create */}

      <MuiDialogOne
        title={t('create')}
        open={openCreate}
        onClose={handleCreateClose}
      >
        <form onSubmit={handleSubmit(handleSubmitDocumentType)}>
          <Grid container spacing={3}>
            <Grid item md={12}>
              <FmTextField
                name='documentTypeName'
                control={control}
                label='Document Type Name'
                rules={{
                  required: 'Document Type Name is required',
                }}
              />
            </Grid>
            <Grid item md={12}>
              <FmAutoComplete
                name='documentTypeCategoryId'
                control={control}
                options={documenttypecategories}
                label='Document Type Category'
                displayField='documentTypeCategoryName'
                optionFields={['documentTypeCategoryName']}
                valueKey='documentTypeCategoryId'
                rules={{
                  required: 'Document Type Category is required',
                }}
              />
            </Grid>
            <Grid item md={12}>
              <ActionButtons
                onSubmit={handleSubmit(handleSubmitDocumentType)}
                onReset={reset}
                onCancel={handleCreateClose}
                submitLoading={createLoading}
                cancelLoading={false}
              />
            </Grid>
          </Grid>
        </form>
      </MuiDialogOne>

      {/* view */}

      <MuiDialogOne
        title={t('viewDetails')}
        open={openView}
        onClose={handleViewClose}
      >
        {loading ? (
          <CommonLoader />
        ) : (
          <Grid container spacing={3}>
            <Grid item md={6}>
              <ViewField
                label={t('documentTypeCode')}
                title={viewData?.documentTypeCode}
              />
            </Grid>
            <Grid item md={6}>
              <ViewField
                label={t('documentTypeName')}
                title={viewData?.documentTypeName}
              />
            </Grid>
            <Grid item md={6}>
              <ViewField
                label={t('documentTypeCategory')}
                title={viewData?.documentTypeCatagoryName}
              />
            </Grid>
            <Grid item md={6}>
              <ViewField label={t('status')} title={viewData?.statusName} />
            </Grid>
          </Grid>
        )}
      </MuiDialogOne>

      {/*  edit */}
      <MuiDialogOne
        title={t('updateDocumentTypes')}
        open={editModal}
        onClose={() => setEditModal(false)}
      >
        {loading ? (
          <CommonLoader />
        ) : (
          <form onSubmit={handleSubmit(handleEditDocumentType)}>
            <Grid container spacing={3}>
              <Grid item md={12}>
                <FmTextField
                  name='documentTypeName'
                  control={control}
                  label='Document Type Name'
                  rules={{
                    required: 'Document Type Name is required',
                  }}
                />
              </Grid>
              <Grid item md={12}>
                <FmAutoComplete
                  name='documentTypeCategoryId'
                  control={control}
                  options={documenttypecategories}
                  label='Document Type Category'
                  displayField='documentTypeCategoryName'
                  optionFields={['documentTypeCategoryName']}
                  valueKey='documentTypeCategoryId'
                />
              </Grid>
              <Grid item md={12}>
                <FmAutoComplete
                  name='status'
                  control={control}
                  options={statusOptions}
                  label='Status'
                  displayField='statusName'
                  optionFields={['statusName']}
                  valueKey='statusId'
                />
              </Grid>
              <Grid item md={12}>
                <ActionButtons
                  onSubmit={handleSubmit(handleEditDocumentType)}
                  onReset={reset}
                  onCancel={() => setEditModal(false)}
                  submitLoading={editLoading}
                  cancelLoading={false}
                  submitText='Update'
                />
              </Grid>
            </Grid>
          </form>
        )}
      </MuiDialogOne>
    </DashboardLayout>
  );
};

export default DocumentTypeMasters;
