import React, { useEffect, useState } from 'react';
import GlassCard from '../../../../components/small/GlassCard.tsx';
import { Grid } from '@mui/material';
import { useForm } from 'react-hook-form';
import FmTextField from '../../../../components/_mui/FmTextField.tsx';
import FmDatePicker from '../../../../components/_mui/FmDatePicker.tsx';
import FmSearchableSelect from '../../../../components/_mui/FmSearchableSelect.tsx';
import ActionButtons from '../../../../components/_form/ActionButtons.tsx';
import Crud_Service from '../../../../apis/CrudService.jsx';
import dayjs from 'dayjs';
import { toast } from 'react-toastify';
import useLoader from '../../../../components/helpers/UseLoader.tsx';
import { yupResolver } from '@hookform/resolvers/yup';
import { CBPettyCashValidationSchema } from '../../../../utils/CommonvalidationSchemas.tsx';
import FmFileInput from '../../../../components/_mui/FmFileInput.tsx';
import { useNavigate, useParams } from 'react-router-dom';

const GridSize = 2.4;

const EditCBR = (props) => {
  const { type } = props;
  const crud = new Crud_Service();
  const id = useParams().id;
  const { startCreateLoading, stopCreateLoading } = useLoader();
  const { control, handleSubmit, reset, getValues, setValue } = useForm({
    resolver: yupResolver(CBPettyCashValidationSchema),
    mode: 'onChange',
  });
  const { createLoading } = useLoader();
  const [loading, setLoading] = useState(false);
  const navigate = useNavigate();
  const [fileId, setFileId] = useState(null);
  const [cbr, setCbr] = useState({});

  useEffect(() => {
    fetchData();
  }, []);
  const fetchData = async () => {
    await crud.getSingle('financialtransactions', id, (err, res) => {
      if (err) {
        return;
      }
      if (res?.status === 200) {
        reset(res?.data);
        setFileId(res?.data?.fileId);
        setCbr(res?.data);
      } else {
      }
    });
  };
  const handleSubmitData = async (values) => {
    startCreateLoading();
    const combinedData = {
      ...values,
      paymentStatusId: 1,

      requestDate: dayjs(values?.requestDate).format('YYYY-MM-DD'),
      chequeDate: dayjs(values?.chequeDate).format('YYYY-MM-DD'),
      maturityDate: dayjs(values?.maturityDate).format('YYYY-MM-DD'),
      fileId: fileId,
      typeId: type,
    };
    await crud.update('financialtransactions', id, combinedData, (err, res) => {
      stopCreateLoading();
      if (err) {
        return;
      }
      if (res?.status === 200) {
        toast.success('Updated successfully');
        reset();
      } else {
      }
    });
  };

  const handleFileUpload = async (event) => {
    setLoading(true);
    const file = event.target.files[0];
    if (file) {
      const fileFormData = new FormData();
      fileFormData.append('file', file);
      fileFormData.append('fileTypeId', 33);

      try {
        const response = await new Promise((resolve, reject) => {
          crud.create('files/upload', fileFormData, (_err, res) => {
            if (res?.status === 200) {
              resolve(res);

              setLoading(false);
            } else {
              reject(new Error('File upload failed'));
              setLoading(false);
            }
          });
        });

        setFileId(response?.data?.fileId);
        setValue(`documentName`, response?.data?.originalFileName);
      } catch (error) {
        setLoading(false);
      } finally {
        setLoading(false);
      }
    }
  };
  return (
    <GlassCard className='p-3'>
      <form onSubmit={handleSubmit(handleSubmitData)}>
        <Grid container spacing={2}>
          <Grid item md={GridSize}>
            <FmTextField
              name='financialTransactionCode'
              control={control}
              label='Code'
            />
          </Grid>

          <Grid item md={GridSize}>
            <FmDatePicker
              name='requestDate'
              label='Request Date'
              control={control}
              required
            />
          </Grid>

          <Grid item md={GridSize}>
            <FmDatePicker
              name='maturityDate'
              label='Maturity Date'
              control={control}
              required
            />
          </Grid>

          <Grid item md={GridSize}>
            <FmSearchableSelect
              name='bankId'
              control={control}
              apiUrl='banks'
              valueField='bankId'
              headerField={['Bank Code', 'Bank Name']}
              labelField={['bankCode', 'bankName']}
              showField={['bankName']}
              defaultValue={cbr}
              label='Bank'
            />
          </Grid>

          <Grid item md={GridSize}>
            <FmSearchableSelect
              name='companyId'
              control={control}
              apiUrl='companies'
              valueField='companyId'
              headerField={['Company Code', 'Company Name']}
              labelField={['companyCode', 'companyName']}
              showField={['companyName']}
              defaultValue={cbr}
              label='Company'
            />
          </Grid>

          <Grid item md={GridSize}>
            <FmSearchableSelect
              name='branchId'
              control={control}
              apiUrl='emirates'
              valueField='emirateId'
              labelField={['emirateName']}
              defaultValue={{
                emirateId: cbr?.branchId,
                emirateName: cbr?.branchName,
              }}
              showField={['emirateName']}
              label={'Branch'}
            />
          </Grid>
          {type === 1 && (
            <Grid item md={GridSize}>
              <FmSearchableSelect
                name='salesInvoiceId'
                control={control}
                apiUrl='salesinvoices'
                defaultValue={cbr}
                valueField='salesInvoiceId'
                labelField={['salesInvoiceCode']}
                showField={['salesInvoiceCode']}
                label={'Sales Invoice'}
              />
            </Grid>
          )}
          {(type === 2 || type === 3) && (
            <>
              <Grid item md={GridSize}>
                <FmSearchableSelect
                  name='placeOfSupplyId'
                  control={control}
                  apiUrl='locations'
                  valueField='locationId'
                  labelField={['locationName']}
                  defaultValue={{
                    locationId: cbr?.placeOfSupplyId,
                    locationName: cbr?.placeOfSupplyName,
                  }}
                  showField={['locationName']}
                  label={'Place Of Supply'}
                />
              </Grid>

              <Grid item md={GridSize}>
                <FmSearchableSelect
                  name='jurisdictionId'
                  control={control}
                  apiUrl='emirates'
                  valueField='emirateId'
                  defaultValue={{
                    emirateId: cbr?.jurisdictionId,
                    emirateName: cbr?.jurisdictionName,
                  }}
                  labelField={['emirateName']}
                  showField={['emirateName']}
                  label={'Jurisdiction'}
                />
              </Grid>
              <Grid item md={GridSize}>
                <FmSearchableSelect
                  name='salesOrderId'
                  control={control}
                  defaultValue={cbr}
                  apiUrl='salesorders'
                  valueField='salesOrderId'
                  labelField={['salesOrderName']}
                  showField={['salesOrderName']}
                  label={'Sales Order'}
                />
              </Grid>
              <Grid item md={GridSize}>
                <FmSearchableSelect
                  name='salesOrderDistributionId'
                  control={control}
                  defaultValue={cbr}
                  apiUrl='salesorderdistributions'
                  valueField='salesOrderDistributionId'
                  labelField={['salesOrderDistributionCode']}
                  showField={['salesOrderDistributionCode']}
                  label={'Sales Order Distribution'}
                />
              </Grid>
            </>
          )}

          <Grid item md={GridSize}>
            <FmSearchableSelect
              name='payeeUserId'
              control={control}
              apiUrl='loginusers'
              valueField='loginUserId'
              defaultValue={{
                loginUserId: cbr?.payeeUserId,
                loginUserName: cbr?.payeeUserName,
              }}
              labelField={['emailId', 'loginUserName']}
              showField={['emailId', 'loginUserName']}
              pageSize={50}
              label='Payee User'
            />
          </Grid>

          <Grid item md={GridSize}>
            <FmSearchableSelect
              name='currencyId'
              control={control}
              apiUrl='currencies'
              valueField='currencyId'
              defaultValue={cbr}
              headerField={['Currency Code', 'Currency Name']}
              labelField={['currencyCode', 'currencyName']}
              showField={['currencyName']}
              label='Currency'
            />
          </Grid>

          <Grid item md={GridSize}>
            <FmTextField name='chequeNo' control={control} label='Cheque No' />
          </Grid>

          <Grid item md={GridSize}>
            <FmDatePicker
              name='chequeDate'
              label='Cheque Date'
              control={control}
              required
            />
          </Grid>

          <Grid item md={GridSize}>
            <FmTextField name='pdcNo' control={control} label='PDC No' />
          </Grid>
          <Grid item md={GridSize}>
            <FmTextField
              name='narration'
              multiline={true}
              rows={2}
              maxRows={3}
              control={control}
              label='Narration'
            />
          </Grid>
          <Grid item md={GridSize}>
            <FmTextField
              name='existLock'
              multiline={true}
              rows={2}
              maxRows={3}
              control={control}
              label='Exist Lock'
            />
          </Grid>
          <Grid item md={GridSize}>
            <FmFileInput
              name={`fileId`}
              documentName={getValues(`documentName`)}
              onChange={(e) => handleFileUpload(e)}
              loading={loading}
            />
          </Grid>
        </Grid>

        <ActionButtons
          onSubmit={handleSubmit(handleSubmitData)}
          onReset={reset}
          submitLoading={createLoading}
          submitText='Update'
        />
      </form>
    </GlassCard>
  );
};

export default EditCBR;
