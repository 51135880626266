import React, { useState, useEffect, useMemo } from 'react';
import GlassCard from '../../small/GlassCard.tsx';
import { Icon } from '@iconify/react/dist/iconify.js';
import Crud_Service from '../../../apis/CrudService.jsx';
import ElementLoader from '../../page/ElementLoader.tsx';
import FAStatusCard from './FAStatusCard.tsx';

const FASalesInvoiceGridsApi = ({
  handleStatusChange,
  api = 'salesinvoices',
  layoutType = 1,
}) => {
  const crud = new Crud_Service();
  const [kpyData, setKpyData] = useState({});
  const [loader, setLoader] = useState(true);
  const [active, setActive] = useState(0);

  const fetchKpiData = async () => {
    await crud.getAll(`${api}/status-info`, '', (_err, res) => {
      setLoader(false);
      if (_err) {
        toast.error(_err);
        return;
      }
      if (res?.status === 200) {
        setKpyData(res?.data);
      }
    });
  };

  useEffect(() => {
    fetchKpiData();
  }, []);

  const iconsArrayStatic = useMemo(
    () => [
      {
        id: 'receiptgenerated',
        name: 'Receipt Generated',
        icon: <Icon icon='carbon:book' />,
        color: 'text-teal-600',
      },
      {
        id: 'deliveredtocollectionagent',
        name: 'Delivered',
        icon: <Icon icon='carbon:checkbox-checked' />,
        color: 'text-orange-600',
      },
      {
        id: 'draft',
        name: 'Draft',
        icon: <Icon icon='carbon:license-maintenance-draft' />,
        color: 'text-red-600',
      },
      {
        id: 'acknowledged',
        name: 'Acknowledged',
        icon: <Icon icon='carbon:ibm-knowledge-catalog-premium' />,
        color: 'text-purple-600',
      },
      {
        id: 'approved',
        name: 'Approved',
        icon: <Icon icon='carbon:certificate-check' />,
        color: 'text-blue-600',
      },
      {
        id: 'handovertoclient',
        name: 'Handover',
        icon: <Icon icon='carbon:user-favorite' />,
        color: 'text-yellow-500',
      },
      {
        id: 'submitted',
        name: 'Submitted',
        icon: <Icon icon='carbon:rocket' />,
        color: 'text-yellow-500',
      },
      {
        id: 'payment_voucher_generated',
        name: 'Payment Voucher Generated',
        icon: <Icon icon='carbon:calculator-check' />,
        color: 'text-blue-600',
      },
      {
        id: 'paid',
        name: 'Paid',
        icon: <Icon icon='carbon:checkmark-outline' />,
        color: 'text-teal-600',
      },
      {
        id: 'reject',
        name: 'Reject',
        icon: <Icon icon='carbon:bottom-panel-close' />,
        color: 'text-red-500',
      },
      {
        id: 'paymentcreated',
        name: 'Payement Created',
        icon: <Icon icon='carbon:checkmark-outline' />,
        color: 'text-teal-600',
      },
    ],
    []
  );

  const KPIArray = useMemo(
    () => [
      {
        icon: <Icon icon='carbon:time-plot' />,
        iconsColor: 'text-orange-600',
        title: 'Overdue',
        value:
          kpyData?.overdueInvoices ||
          kpyData?.overduePettyCash ||
          kpyData?.overduePurchaseVoucher ||
          kpyData?.overduePayment ||
          0,
        chartEnabled: true,
      },

      // sales invoices
      ...(api === 'salesinvoices' && kpyData?.salesInvoiceStatusInfo
        ? kpyData.salesInvoiceStatusInfo.map((item) => {
            const matchedIcon = iconsArrayStatic.find(
              (icon) =>
                icon.id ===
                item.salesInvoiceStatus?.toLowerCase().replace(/\s/g, '')
            );

            return {
              icon: matchedIcon ? (
                matchedIcon.icon
              ) : (
                <Icon icon='carbon:help' />
              ),
              iconsColor: matchedIcon?.color || 'text-gray-600',
              title: matchedIcon?.name || item.salesInvoiceStatus,
              value: item.salesInvoiceStatusCount || 0,
              salesInvoiceStatusId: item.salesInvoiceStatusId,
            };
          })
        : []),

      // Petty Cashes
      ...(api === 'pettycashtransactions' &&
      kpyData?.pettyCashTransactionStatusInfo
        ? kpyData.pettyCashTransactionStatusInfo.map((item) => {
            const matchedIcon = iconsArrayStatic.find(
              (icon) =>
                icon.id ===
                item.pettyCashStatusName?.toLowerCase().replace(/\s/g, '')
            );
            return {
              icon: matchedIcon ? (
                matchedIcon.icon
              ) : (
                <Icon icon='carbon:help' />
              ),
              iconsColor: matchedIcon?.color || 'text-gray-600',
              title: matchedIcon?.name || item.pettyCashStatusName,
              value: item.pettyCashStatusCount || 0,
              salesInvoiceStatusId: item.pettyCashStatusId,
            };
          })
        : []),

      ...(api === 'purchasevouchers' && kpyData?.purchaseVoucherStatusInfo
        ? kpyData.purchaseVoucherStatusInfo.map((item) => {
            const matchedIcon = iconsArrayStatic.find(
              (icon) =>
                icon.id ===
                item.purchaseVoucherStatusName?.toLowerCase().replace(/\s/g, '')
            );
            return {
              icon: matchedIcon ? (
                matchedIcon.icon
              ) : (
                <Icon icon='carbon:help' />
              ),
              iconsColor: matchedIcon?.color || 'text-gray-600',
              title: matchedIcon?.name || item.purchaseVoucherStatusName,
              value: item.purchaseVoucherStatusCount || 0,
              salesInvoiceStatusId: item.purchaseVoucherStatusId,
            };
          })
        : []),

      // payments
      ...(api === 'payments' && kpyData?.paymentStatusInfo
        ? kpyData.paymentStatusInfo.map((item) => {
            const matchedIcon = iconsArrayStatic.find(
              (icon) =>
                icon.id ===
                item.paymentStatusName?.toLowerCase().replace(/\s/g, '')
            );
            return {
              icon: matchedIcon ? (
                matchedIcon.icon
              ) : (
                <Icon icon='carbon:help' />
              ),
              iconsColor: matchedIcon?.color || 'text-gray-600',
              title: matchedIcon?.name || item.paymentStatusName,
              value: item.paymentStatusCount || 0,
              salesInvoiceStatusId: item.paymentStatusId,
            };
          })
        : []),

      {
        icon: <Icon icon='carbon:account' />,
        iconsColor: 'text-green-600',
        title: 'Total Invoices',
        value:
          kpyData?.totalInvoices ||
          kpyData?.totalPettyCashes ||
          kpyData?.totalPurchaseVouchers ||
          kpyData?.totalPayments ||
          0,
        salesInvoiceStatusId: 0,
      },
    ],
    [kpyData, iconsArrayStatic, api]
  );

  useEffect(() => {
    setActive(KPIArray?.length - 1);
  }, [KPIArray]);

  const handleClick = (index, id) => {
      setActive(index);
      handleStatusChange(id);
  };

  return (
    <GlassCard className='relative shadow-none min-h-[45px]'>
      {loader ? (
        <ElementLoader fly image />
      ) : (
        <FAStatusCard
          KPIArray={KPIArray}
          active={active}
          handleClick={handleClick}
          type={layoutType}
        />
      )}
    </GlassCard>
  );
};

export default FASalesInvoiceGridsApi;
