import React from 'react';
import TableAvatar from '../../../components/_form/TableAvatar';
import TableDropDown from '../../../components/helpers/TableDropDown.tsx';
import FmViewDateField from '../../../components/_mui/FmViewDateField.tsx';
import TableActionButtons from '../../../components/_form/TableActionButtons.jsx';

interface ActionType {
  name: string;
  icon: JSX.Element;
  onClick: ((props: any, ev: any) => void) | null;
  danger?: boolean;
}

export const getHrmsEMpMasterCol = (
  t: (key: string) => string,
  Actions: ActionType[],
  setSelectedRow: (row: any) => void,
) => [
    {
      field: 'column_sort',
      headerName: '',
      flex: 1,
      minWidth: 120,
      sortable: false,
      disableClickEventBubbling: false,

      renderCell: (params) => {
        return (
          <TableActionButtons
            Actions={Actions?.map((action) => ({
              ...action,
              onClick: () => action.onClick(params?.row),
            }))}
            selectedRow={params?.row}
            setSelectedRow={() => { setSelectedRow(params?.row) }}
          />
        );
      },
    },
    {
      field: 'employeeName',
      headerName: t('name'),
      width: 180,

      renderCell: (params) => {
        return (
          <TableAvatar
            name={params.value}
            img={params.row.imageUrl}
            desc={params.row.mobile_no}
          />
        );
      },
    },
    {
      field: 'employeeCode',
      headerName: t('employee_code'),
      minWidth: 170,
    },

    {
      field: 'designationName',
      headerName: t('designation'),
      minWidth: 170,
    },

    {
      field: 'departmentName',
      headerName: 'Division',
      minWidth: 170,
    },

    {
      field: 'nationalityName',
      headerName: t('nationality'),
      minWidth: 150,
    },
    {
      field: 'dob',
      headerName: t('date_of_birth'),
      minWidth: 150,
      renderCell: (params) => {
        return FmViewDateField({ value: params?.value });
      },
    },

    {
      field: 'genderName',
      headerName: t('gender'),
      minWidth: 150,
    },

    {
      field: 'maritalStatusName',
      headerName: t('marital_status'),
      minWidth: 150,
    },
    {
      field: 'dateOfJoin',
      headerName: t('date_of_join'),
      minWidth: 150,
      renderCell: (params) => {
        return FmViewDateField({ value: params?.value });
      },
    },

    {
      field: 'bloodGroupName',
      headerName: t('blood_group'),
      minWidth: 150,
    },
    {
      field: 'mobileNumber',
      headerName: t('mobile_number'),
      minWidth: 170,
    },
    {
      field: 'placeOfBirth',
      headerName: t('place_of_birth'),
      minWidth: 150,
      renderCell: (params) => {
        return FmViewDateField({ value: params?.value });
      },
    },
    {
      field: 'personalEmailId',
      headerName: t('personal_email_id'),
      minWidth: 200,
    },
    {
      field: 'permanentAddress',
      headerName: t('permanent_address'),
      minWidth: 170,
    },
    {
      field: 'empIdCardProvided',
      headerName: 'Emp Id Card Provided',
      minWidth: 150,
    },
    {
      field: 'unifiedNo',
      headerName: 'Unified No',
      minWidth: 150,
    },

    {
      field: 'molStatusName',
      headerName: 'MOL Status',
      minWidth: 150,
    },
    {
      field: 'molReferenceNumber',
      headerName: 'MOL Reference Number',
      minWidth: 150,
    },

    {
      field: 'employeeStatusName',
      headerName: t('employee_status'),
      minWidth: 150,
    },
    {
      field: 'passportNumber',
      headerName: t('passport_number'),
      minWidth: 150,
    },
    {
      field: 'passportIssuePlace',
      headerName: t('passport_issue_place'),
      minWidth: 150,
    },
    {
      field: 'passportIssueDate',
      headerName: t('passport_issue_date'),
      minWidth: 150,
      renderCell: (params) => {
        return FmViewDateField({ value: params?.value });
      },
    },
    {
      field: 'passportExpiryDate',
      headerName: t('passport_expiry_date'),
      minWidth: 150,
      renderCell: (params) => {
        return FmViewDateField({ value: params?.value });
      },
    },

    {
      field: 'passportStatusName',
      headerName: t('passport_status'),
      minWidth: 150,
    },
    {
      field: 'checkOutDate',
      headerName: t('check_out_date'),
      minWidth: 150,
      renderCell: (params) => {
        return FmViewDateField({ value: params?.value });
      },
    },
    {
      field: 'inOrOut',
      headerName: t('in_or_out'),
      minWidth: 150,
    },
    {
      field: 'employeeVisaDetailId',
      headerName: t('employee_visa_detail_id'),
      minWidth: 150,
    },
    {
      field: 'visaTypeName',
      headerName: t('visa_type'),
      minWidth: 150,
    },
    {
      field: 'visaNumber',
      headerName: t('visa_number'),
      minWidth: 150,
    },
    {
      field: 'issuedOrganization',
      headerName: t('issued_organization'),
      minWidth: 150,
    },
    {
      field: 'issuedDate',
      headerName: t('issued_date'),
      minWidth: 150,
      renderCell: (params) => {
        return FmViewDateField({ value: params?.value });
      },
    },
    {
      field: 'expiryDate',
      headerName: t('expiry_date'),
      minWidth: 150,
      renderCell: (params) => {
        return FmViewDateField({ value: params?.value });
      },
    },
    {
      field: 'remarks',
      headerName: t('remarks'),
      minWidth: 150,
    },

    {
      field: 'immigrationStatusName',
      headerName: t('immigration_status'),
      minWidth: 150,
    },
    {
      field: 'additionalAllowance',
      headerName: t('Additional Allowance'),
      minWidth: 150,
    },
    {
      field: 'basicSalary',
      headerName: t('Basic salary'),
      minWidth: 150,
    },
    {
      field: 'foodAllowance',
      headerName: t('Food Allowance'),
      width: 170,
    },
    {
      field: 'grossSalary',
      headerName: t('Gross salary'),
      width: 150,
    },
    {
      field: 'housingAllowance',
      headerName: t('Housing Allowance'),
      width: 200,
    },
    {
      field: 'incentiveAllowance',
      headerName: t('Incentive Allowance'),
      width: 200,
    },
    {
      field: 'telephoneAllowance',
      headerName: t('Telephone Allowance'),
      width: 200,
    },
    {
      field: 'transportAllowance',
      headerName: t('Allowance'),
      width: 150,
    },

    {
      field: 'statusName',
      headerName: t('status'),
      width: 150,
      cellClassName: 'text-green-600',
    },
    {
      field: 'createdDate',
      headerName: t('created_date'),
      width: 150,
      renderCell: (params) => {
        return FmViewDateField({ value: params?.value });
      },
    },
    {
      field: 'updatedDate',
      headerName: t('updated_date'),
      width: 150,
      renderCell: (params) => {
        return FmViewDateField({ value: params?.value });
      },
    },
  ];
