import React, { useState, useEffect, useMemo } from 'react';
import { Stepper, Step, StepLabel, Box, Button } from '@mui/material';
import { SkeletonLoaderRow } from '../page/SkeletonLoader.tsx';
import useCommonFetchApi from '../helpers/useCommonFetchApi.tsx';
import { Icon } from '@iconify/react/dist/iconify.js';
import ButtonPopoverAlert from '../alert/ButtonPopoverAlert.tsx';
import { toast } from 'react-toastify';
import ButtonModalAlert from '../alert/ButtonModalAlert.tsx';

const MuiStepperOne = ({
  status,
  handleStatusChange,
  api = 'salesinvoicestatuses',
  type = 1,
  previousStatusId,
  updateButtonVisible = [],
}) => {
  const [activeStep, setActiveStep] = useState(null);
  const [rejectActive, setRejectActive] = useState(false);
  const [currentStatus, setCurrentStatus] = useState(null);
  const [isApproved, setIsApproved] = useState(false);
  const { data: saleinvoiceStatus, loading } = useCommonFetchApi(api);

  const sortedData = useMemo(() => {
    if (!saleinvoiceStatus) return [];
    return [...saleinvoiceStatus]
      .filter((item) =>
        type === 1
          ? item.salesInvoiceStatusId !== undefined
          : item.key !== undefined
      )
      .sort((a, b) =>
        type === 1
          ? (a.salesInvoiceStatusId ?? 0) - (b.salesInvoiceStatusId ?? 0)
          : (a.key ?? 0) - (b.key ?? 0)
      );
  }, [saleinvoiceStatus, type]);

  // Split "Rejected" from other steps
  const filteredSteps = sortedData.filter(
    (step) =>
      (step.salesInvoiceStatusName?.toLowerCase() ??
        step.name?.toLowerCase()) !== 'reject'
  );

  const rejectedStep = sortedData.find(
    (step) =>
      (step.salesInvoiceStatusName?.toLowerCase() ??
        step.displayName?.toLowerCase()) === 'reject'
  );

  const approvedStep = sortedData.find(
    (step) =>
      (step.salesInvoiceStatusName?.toLowerCase() ??
        step.displayName?.toLowerCase()) === 'approved'
  );

  const ononfirmReject = () => {
    if (!rejectActive) {
      handleStatusChange(rejectedStep.salesInvoiceStatusId ?? rejectedStep.key);
    } else {
      toast.info('Already Reject!');
    }
  };

  const onSaveAsDraft = () => {
    handleStatusChange(1);
  };

  useEffect(() => {
    if (!filteredSteps.length || status == null) return;
    const foundIndex = filteredSteps.findIndex((step) =>
      type === 1 ? step.salesInvoiceStatusId === status : step.key === status
    );
    setActiveStep(foundIndex !== -1 ? foundIndex + 1 : 0);
    setCurrentStatus(foundIndex !== -1 ? foundIndex : 0);

    // rejected
    if (
      rejectedStep &&
      (rejectedStep.salesInvoiceStatusId === status ||
        rejectedStep.key === status)
    ) {
      setActiveStep(previousStatusId);
      setCurrentStatus(null);
      setRejectActive(true);
      setIsApproved(true);
    } else {
      setRejectActive(false);
    }

    // find approved
    if (
      approvedStep &&
      (approvedStep.salesInvoiceStatusId > status || approvedStep.key > status)
    ) {
      setIsApproved(true);
    } else {
      setIsApproved(
        rejectedStep.salesInvoiceStatusId === status ||
          rejectedStep.key === status
          ? true
          : false
      );
    }
  }, [
    filteredSteps,
    status,
    type,
    rejectedStep,
    approvedStep,
    approvedStep,
    currentStatus,
  ]);

  const handleStepClick = (index, statusId) => {
    if (index === activeStep) {
      setActiveStep(index);
      handleStatusChange(statusId);
    } else {
      toast.info('Please click the Current Status');
      handleStatusChange(1);
    }
  };

  if (!filteredSteps.length) return <SkeletonLoaderRow count={3} />;
  if (loading) return <SkeletonLoaderRow count={3} />;

  return (
    <>
      <Box className='w-full relative'>
        <Box className='w-full flex gap-2 items-start'>
          {updateButtonVisible?.length > 0 &&
            updateButtonVisible?.includes(activeStep) &&
            !rejectActive && (
              <Box className='mt-3'>
                <Button
                  variant='outlined'
                  color='info'
                  className='py-1 px-4 border-none uppercase font-semibold'
                  startIcon={
                    <Icon
                      icon='material-symbols:update'
                      width='20'
                      height='20'
                    />
                  }
                  onClick={() =>
                    handleStatusChange(
                      updateButtonVisible?.filter(
                        (item) => item !== activeStep
                      )[0]
                    )
                  }
                >
                  Update
                </Button>
              </Box>
            )}
          <Box
            className={`flex-1 overflow-auto min-h-0 ${
              rejectActive && 'pointer-events-none grayscale'
            }`}
          >
            <Stepper
              activeStep={activeStep ?? 0}
              alternativeLabel
              sx={styleStepper}
            >
              {filteredSteps.map((step, index) => {
                const isDisabled = index === activeStep;

                return (
                  <Step
                    key={step.salesInvoiceStatusId ?? step.key}
                    onClick={() =>
                      handleStepClick(
                        index,
                        step.salesInvoiceStatusId ?? step.key
                      )
                    }
                    className={
                      isDisabled ? 'cursor-pointer' : 'pointer-events-none'
                    }
                  >
                    {index === currentStatus && (
                      <Box
                        className='absolute left-1/2 -translate-x-1/2 top-[20px] p-1 rounded-full'
                        sx={{ bgcolor: 'secondary.main' }}
                      ></Box>
                    )}

                    {/* reject close */}
                    {rejectActive && isDisabled && (
                      <Box
                        className='absolute -left-[20px] top-[4px] px-2 uppercase'
                        sx={{ bgcolor: 'background.white' }}
                      >
                        <Icon
                          icon='line-md:close-circle-filled'
                          width='20'
                          height='20'
                        />
                      </Box>
                    )}
                    {/* end reject close */}
                    <StepLabel
                      className={`hover-effet ${
                        isDisabled ? 'cursor-pointer' : 'pointer-events-none'
                      }`}
                    >
                      <Box className={!rejectActive ? 'button-style' : ''}>
                        {step.salesInvoiceStatusName ?? step.name}
                      </Box>
                    </StepLabel>
                  </Step>
                );
              })}
            </Stepper>
          </Box>

          {/* Custom Button for "Rejected" */}
          {isApproved && (
            <>
              <ButtonPopoverAlert
                title={'Confirm Rejection'}
                message={
                  'Are you sure? Once rejected, this cannot be reversed.'
                }
                buttonText={'Reject'}
                startIcon={
                  <Icon icon='carbon:close-filled' width='20' height='20' />
                }
                variant={rejectActive ? 'contained' : 'outlined'}
                className={`border-none w-[100px] uppercase font-semibold p-1 mt-1.5 ${
                  rejectActive && 'pointer-events-none'
                }`}
                color='error'
                onConfirm={ononfirmReject}
              />
              {rejectActive && (
                <ButtonModalAlert
                  buttonText={'DRAFT'}
                  className='w-[110px] p-1 mt-1.5 font-semibold'
                  variant='contained'
                  color='success'
                  title={'Are you sure you want to save this entry as a draft?'}
                  icon='draft'
                  startIcon={
                    <Icon icon='carbon:license-draft' width='20' height='20' />
                  }
                  message={
                    'You can make changes later. This will not be finalized until submitted.'
                  }
                  onConfirm={onSaveAsDraft}
                />
              )}
            </>
          )}
        </Box>
      </Box>
    </>
  );
};

const styleStepper = {
  width: '100%',
  overflow: 'auto',
  position: 'relative',
  '& .MuiStepLabel-iconContainer .MuiSvgIcon-root': { fontSize: '25px' },
  '& .MuiStepConnector-root': {
    top: '13px',
    '& .MuiStepConnector-line': { borderWidth: 2 },
  },

  '& .Mui-active .MuiStepConnector-line, & .Mui-completed .MuiStepConnector-line':
    {
      borderColor: 'primary.main',
    },

  '& .MuiStepLabel-root': {
    '&.hover-effet': {
      '&:hover': {
        '& .MuiSvgIcon-root': {
          color: 'primary.main',
        },

        '& .button-style': {
          color: 'primary.main',
        },
      },
    },

    '& .MuiStepLabel-labelContainer': {
      color: 'inherit',

      '& .MuiStepLabel-label': {
        marginTop: '5px',
        fontWeight: 500,

        '& .button-style': {
          padding: '5px',
        },

        '&.Mui-active': {
          '& .button-style': {
            bgcolor: 'primary.main',
            color: '#fff',
            borderRadius: '5px',
          },
        },

        '&.Mui-completed': {
          '& .button-style': {
            bgcolor: 'inherit',
            color: 'primary.main',
          },
        },

        '&.Mui-disabled:not(.Mui-completed, .Mui-active)': {
          '& .button-style': {
            opacity: 0.5,
          },
        },
      },
    },
  },
};

export default MuiStepperOne;
