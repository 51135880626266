import React, { useEffect, useState } from 'react';
import { Grid, Box, IconButton, FormControl, Checkbox } from '@mui/material';
import FmTextField from '../../../../components/_mui/FmTextField.tsx';
import { Controller, useFieldArray, useForm } from 'react-hook-form';
import FmStack from '../../../../components/layout/FmStack.tsx';
import FmSearchableSelect from '../../../../components/_mui/FmSearchableSelect.tsx';
import ActionButtons from '../../../../components/_form/ActionButtons.tsx';
import { Icon } from '@iconify/react';
import ConfirmationPopoverBox from '../../../../components/_form/ConfirmationPopoverBox.tsx';
import { yupResolver } from '@hookform/resolvers/yup';
import { ContractCheckListSchema } from '../../../../utils/CommonvalidationSchemas.tsx';
import Crud_Service from '../../../../apis/CrudService.jsx';
import { toast } from 'react-toastify';
import GlassCard from '../../../../components/small/GlassCard.tsx';
import { useParams } from 'react-router-dom';
import useLoader from '../../../../components/helpers/UseLoader.tsx';

const GridSize = 2.4;
const CreateSalesQuotationChecklist = (props) => {
  const { onClose, salesQuotationId } = props;
  const { startCreateLoading, stopCreateLoading } = useLoader();
  const crud = new Crud_Service();
  const [status, setStatus] = useState(0);
  const {
    control,
    handleSubmit,
    reset,
    setValue,
    formState: { errors },
  } = useForm({
    resolver: yupResolver(ContractCheckListSchema),
    mode: 'onChange',
    defaultValues: { checkListItem: [] },
  });

  const { fields, append, remove } = useFieldArray({
    control,
    name: 'checkListItem',
  });
  const id = useParams().id;
  const [loader, setLoading] = useState(false);
  const [roundDetails, setRoundDetails] = useState([]);
  const [checkListData, setCheckListData] = useState([]);
  // let status = 0;

  useEffect(() => {
    append({});
    fetchDataItems();
    crud.getSingle('salesquotations', id, (err, res) => {
      if (res.data) {
        setStatus(Number(res.data?.quotationStatusId)); // Update state
      }
    });
  }, []);

  const addNewChecklist = () => {
    append({});
  };

  const deleteChecklist = async (salesQuotationChecklistId: number) => {
    setLoading(true);
    if (salesQuotationChecklistId) {
      await crud.remove(
        'salesquotationchecklists',
        salesQuotationChecklistId,
        (err, res) => {
          if (res.status === 204) {
            setLoading(false);
            fetchDataItems();
          }
        }
      );
    }
  };

  const fetchDataItems = async () => {
    setLoading(true);
    await crud.getAll(
      'salesquotationchecklists',
      { salesQuotationId: salesQuotationId },
      (_err, res) => {
        if (res?.status === 200) {
          const updatedData = res?.data?.data.map((item) => ({
            ...item,
            invoiceChecklistTypeId: item.checklistTypeId,
            remarks: item.remarks, // Set default if empty
          }));
          const updatedStatusId = res?.data?.data.map((item) => ({
            ...item,
            salesQuotationChecklistId: item?.salesQuotationChecklistId
          }));

          setRoundDetails(updatedData);
          // setCheckListData(updatedData?.salesQuotationChecklistId);


          reset({ checkListItem: updatedData }); // Reset form with the updated data
          updatedData?.length < 1 && append({});
        }
        setLoading(false);
      }
    );
  };

  const handleRemove = (index) => {
    const itemToDelete = roundDetails[index];
    remove(index);
    if (itemToDelete?.salesQuotationChecklistId) {
      setLoading(true);
      crud.remove(
        'salesquotationchecklists',
        itemToDelete?.salesQuotationChecklistId,
        (_err, res) => {
          if (res?.status === 204) {
            toast.success(`CheckList Deleted Successfully`);
            fetchDataItems();
          }
          setLoading(false);
        }
      );
    }
  };

  const onSubmit = async (values) => {
    startCreateLoading();
    const updatedPayload = {
      salesQuotationId: salesQuotationId?.id,
      createChecklists: values?.checkListItem?.map((item) => ({
        remarks: item.remarks || '',
        checklistTypeId: Number(item.invoiceChecklistTypeId) || 0, // Ensure it's a number
        isMandatoryForInvoiceGeneration: Boolean(item.isMandatoryForInvoiceGeneration),
        isChecklistFile: Boolean(item.isChecklistFile),
      })) || [],
    };
    await crud.create('salesquotationchecklists', updatedPayload, (err, res) => {
      if (err) {
        stopCreateLoading();
        return;
      }
      if (res?.status === 201) {
        toast.success('CheckList Added Successfully');
        fetchDataItems();
        stopCreateLoading();
      } else {
        stopCreateLoading();
      }
    });
  };


  return loader ? (
    ''
  ) : (
    <GlassCard className='p-4'>
      <Box component='form' onSubmit={handleSubmit(onSubmit)}>
        <FmStack spacing={1.5}>
          {fields?.map((field, index) => (
            <Box className='relative pe-[100px]' key={field.id}>
              <Grid container spacing={1.5}>
                <Grid item md={GridSize * 2}>
                  <FmSearchableSelect
                    name={`checkListItem.${index}.invoiceChecklistTypeId`}
                    control={control}
                    apiUrl='invoicechecklisttypes'
                    valueField='invoiceChecklistTypeId'
                    disabled={status === 2}
                    defaultValue={{
                      invoiceChecklistTypeId: field.checklistTypeId,
                      invoiceChecklistTypeName: field.checklistTypeName,
                    }}
                    labelField={[
                      'invoiceChecklistTypeCode',
                      'invoiceChecklistTypeName',
                    ]}
                    showField={[
                      'invoiceChecklistTypeCode',
                      'invoiceChecklistTypeName',
                    ]}
                    pageSize={50}
                    label='Invoice Check List Type'
                    required
                  />
                  <Box className='mt-2'>
                    <FormControl
                      component='fieldset'
                      className='flex items-center'
                    >
                      <div className='flex items-center'>
                        <Controller
                          name={`checkListItem.${index}.isMandatoryForInvoiceGeneration`}
                          control={control}
                          defaultValue={false}
                          disabled={status === 2}
                          render={({ field }) => (
                            <Checkbox
                              checked={Boolean(field.value)}
                              onChange={(ev) => {
                                const newValue = ev.target.checked;
                                setValue(
                                  `checkListItem.${index}.isMandatoryForInvoiceGeneration`,
                                  newValue
                                );
                                field.onChange(newValue);
                              }}
                              color='primary'
                              className='p-0 me-3'
                            />
                          )}
                        />
                        <Box className='mb-1 font-semibold'>
                          Mandatory for Invoice Generation
                        </Box>
                      </div>
                      {errors?.checkListItem?.[index]
                        ?.isMandatoryForInvoiceGeneration && (
                          <p style={{ color: 'red' }}>
                            {
                              errors?.checkListItem?.[index]
                                ?.isMandatoryForInvoiceGeneration?.message
                            }
                          </p>
                        )}
                    </FormControl>
                  </Box>
                </Grid>
                <Grid item md={GridSize * 3}>
                  <FmTextField
                    name={`checkListItem[${index}].remarks`}
                    control={control}
                    disabled={status === 2}
                    label='Remarks'
                    multiline
                  />
                  <Box className='mt-2'>
                    <FormControl
                      component='fieldset'
                      className='flex items-center'
                    >
                      <div className='flex items-center'>
                        <Controller
                          name={`checkListItem.${index}.isChecklistFile`}
                          control={control}
                          disabled={status === 2}
                          defaultValue={false}
                          render={({
                            field: { onChange, value, ...field },
                          }) => (
                            <Checkbox
                              {...field}
                              checked={Boolean(value)}
                              onChange={(e) => {
                                const newValue = e.target.checked;
                                setValue(
                                  `checkListItem.${index}.isChecklistFile`,
                                  newValue
                                );
                                onChange(newValue);
                              }}
                              color='primary'
                              className='p-0 me-3'
                            />
                          )}
                        />
                        <Box className='mb-1 font-semibold'>Checklist File</Box>
                      </div>
                      {errors?.checkListItem?.[index]?.isChecklistFile && (
                        <p style={{ color: 'red' }}>
                          {
                            errors?.checkListItem?.[index]?.isChecklistFile
                              ?.message
                          }
                        </p>
                      )}
                    </FormControl>
                  </Box>
                </Grid>
              </Grid>
              {
                <Box className='flex items-center justify-end gap-2 absolute right-0 top-1/2 translate-y-[-50%]'>
                  <ConfirmationPopoverBox
                    icon={<Icon icon='mdi:trash' width='22' height='22' />}
                    title='Delete Checklist'
                    message='Are you sure you want to delete this checklist?'
                    onConfirm={() =>
                      deleteChecklist(field.salesQuotationChecklistId)
                    }
                    disabled={fields.length === 1}
                  />
                  {index === fields.length - 1 && (
                    <IconButton className='p-2' onClick={addNewChecklist}>
                      <Icon icon='ic:baseline-plus' width='22' height='22' />
                    </IconButton>
                  )}
                </Box>
              }
            </Box>
          ))}
        </FmStack>

        {(status === 1 || status === 3) && (
          <ActionButtons onSubmit={handleSubmit(onSubmit)} onCancel={onClose} />
        )}
      </Box>
    </GlassCard>
  );
};

export default CreateSalesQuotationChecklist;
