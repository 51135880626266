import React, { useState } from 'react';
import Box from '@mui/material/Box';
import Stack from '@mui/material/Stack';
import HeaderMenu from './helpers/HeaderMenu';
import HeaderNotification from './helpers/HeaderNotification';
import HeaderHistoryDropdown from './helpers/HeaderHistoryDropdown';
import PropTypes, { string } from 'prop-types';
import Icons from '../utils/Icon';
import HeaderSearchButton from './helpers/HeaderSearchButton';
import HeaderAppDropdown from './helpers/HeaderAppDropdown';
import { IconButtonWithTooltip } from './small/Buttons';
import { Icon } from '@iconify/react';
import HeaderLanguage from './helpers/HeaderLanguage';
import { useTheme } from '../ThemeContext.jsx';


const Header = (props) => {
  const { type, HandleMenuCollapse, collaspeMenuState } = props;
  const [showMenu, setShowMenu] = useState(false);
  const { toggleTheme, mode } = useTheme();

  const [activeMenuId, setactiveMenuId] = useState(0);

  const handleMenuOpen = (i) => {
    setShowMenu(!showMenu);
    setactiveMenuId(i);
  };

  return (
    <>
      <Box
        id='header_id'
        sx={{
          width: '100%',
          display: 'flex',
          px: '8px',
          py: '0px',
          justifyContent: 'space-between',
          alignItems: 'center',
          zIndex: 11,
          background: (theme) => theme.palette.background.header,
        //  bgcolor: 'background.overlay',
          color: '#ffffff',
          // borderBottom: '1px solid',
          // borderColor: 'border.main',
        }}
        className='sm:flex-col'
      >
        <Stack direction='row' alignItems='center' spacing={1}>
          <Box className='sm:hidden'>
            <IconButtonWithTooltip
              name={!collaspeMenuState ? 'Collapse' : 'Expand'}
              icon={!collaspeMenuState ? Icons.menuClose : Icons.menu}
              onClick={() => HandleMenuCollapse()}
            />
          </Box>
          <Box
            component='img'
            src={
              mode === 'dark'
                ? '/images/logo-white.png'
                : '/images/logo-white.png'
            }
            sx={{ maxWidth: '100%', height: '35px' }}
            loading='lazy'
          />
          {type !== 'ESS' && <HeaderAppDropdown />}
          <HeaderSearchButton />
        </Stack>
        <Stack direction='row' alignItems='center' spacing={4}>
          <Box className='flex items-center gap-3 cursor-pointer '>
            <Box className='relative flex items-center gap-0'>
            <Icon icon="weui:help-outlined" width="24" height="24" />
              {/* <span class='relative flex size-2 '>
                <span class='absolute inline-flex h-full w-full animate-ping rounded-full bg-orange-900 opacity-75'></span>
                <span class='relative inline-flex size-2 rounded-full bg-orange-600'></span>
              </span> */}
            </Box>
            <Box sx={{ lineHeight: '13px' }}>
              <Box className='uppercase text-[10px]'>Help Line - Intercom</Box>
              <Box component='a' className='font-semibold '>
                +971 * ********{' '}
              </Box>
            </Box>
          </Box>
          <Stack direction='row' alignItems='center' spacing={0.2}>
            {/* <IconButtonWithTooltip
              name='Menu'
              icon={Icons.grid}
              onClick={() => handleMenuOpen()}
            /> */}
            <HeaderLanguage />
            <IconButtonWithTooltip
              name={mode === 'dark' ? 'Light Mode' : 'Dark Mode'}
              icon={mode === 'dark' ? Icons.sun : Icons.moon}
              onClick={toggleTheme}
            />
            <HeaderHistoryDropdown />
            <HeaderNotification />
          </Stack>
          <HeaderMenu />
        </Stack>
      </Box>
      {/* <MenuPopper
        open={showMenu}
        handleOpen={handleMenuOpen}
        activeId={activeMenuId}
        handleClose={handleMenuOpen}
      /> */}
    </>
  );
};

Header.propTypes = {
  title: PropTypes.string,
  type: string,
};

export default Header;
