import React, { useEffect, useState } from 'react';
import GlassCard from '../../../../components/small/GlassCard.tsx';
import { Grid } from '@mui/material';
import { useForm } from 'react-hook-form';
import FmTextField from '../../../../components/_mui/FmTextField.tsx';
import FmDatePicker from '../../../../components/_mui/FmDatePicker.tsx';
import FmSearchableSelect from '../../../../components/_mui/FmSearchableSelect.tsx';
import ActionButtons from '../../../../components/_form/ActionButtons.tsx';
import Crud_Service from '../../../../apis/CrudService.jsx';
import dayjs from 'dayjs';
import { toast } from 'react-toastify';
import useLoader from '../../../../components/helpers/UseLoader.tsx';
import { yupResolver } from '@hookform/resolvers/yup';
import { CBPettyCashValidationSchema } from '../../../../utils/CommonvalidationSchemas.tsx';
import { useNavigate, useParams } from 'react-router-dom';

const GridSize = 3;

const EditPettyCash = () => {
    const crud = new Crud_Service();
    const id = useParams().id;
    const { startCreateLoading, stopCreateLoading } = useLoader();
    const { createLoading } = useLoader();
    const [cbr, setCbr] = useState({});
    const { control, handleSubmit, reset } = useForm({
        resolver: yupResolver(CBPettyCashValidationSchema),
        mode: 'onChange',
    });

    useEffect(() => {
        fetchData();
    }, [id]);
    const fetchData = async () => {
        await crud.getSingle('pettycashtransactions', id, (err, res) => {
            if (err) {
                return;
            }
            if (res?.status === 200) {
                reset(res?.data);
                setCbr(res?.data);
            } else {
            }
        });
    };
    const handleSubmitData = async (values) => {
        startCreateLoading();
        const combinedData = {
            ...values,
            requestDate: dayjs(values?.requestDate).format('YYYY-MM-DD'),
        };
        await crud.update('pettycashtransactions', id, combinedData, (err, res) => {
            stopCreateLoading();
            if (err) {
                return;
            }
            if (res?.status === 200) {
                toast.success('Petty Cash Updated successfully');
            } else {
            }
        });
    };
    return (
        <GlassCard className='p-3'>
            <form onSubmit={handleSubmit(handleSubmitData)}>
                <Grid container spacing={2}>
                    <Grid item md={GridSize}>
                        <FmSearchableSelect
                            name='bankId'
                            control={control}
                            apiUrl='banks'
                            valueField='bankId'
                            labelField={['bankCode', 'bankName']}
                            showField={['bankCode', 'bankName']}
                            defaultValue={{
                                bankId: cbr?.bankId,
                                bankName: cbr?.bankName,
                            }}
                            label='Bank Account'
                            required
                        />
                    </Grid>

                    <Grid item md={GridSize}>
                        <FmSearchableSelect
                            name='companyId'
                            control={control}
                            apiUrl='companies'
                            valueField='companyId'
                            labelField={['companyCode', 'companyName']}
                            showField={['companyName']}
                            label='Company'
                            defaultValue={{
                                companyId: cbr?.companyId,
                                companyName: cbr?.companyName,
                            }}
                            required
                        />
                    </Grid>

                    <Grid item md={GridSize}>
                        <FmDatePicker
                            name='requestDate'
                            label='Request Date'
                            control={control}

                        />
                    </Grid>

                    <Grid item md={GridSize}>
                        <FmSearchableSelect
                            name='branchId'
                            control={control}
                            apiUrl='branches'
                            valueField='branchId'
                            labelField={['branchCode', 'branchName']}
                            showField={['branchCode', 'branchName']}
                            defaultValue={{
                                branchId: cbr?.branchId,
                                branchName: cbr?.branchName,
                            }}
                            label={'Branch'}
                        />
                    </Grid>
                    <Grid item md={GridSize}>
                        <FmSearchableSelect
                            name='jurisdictionId'
                            control={control}
                            apiUrl='jurisdictions'
                            valueField='jurisdictionId'
                            labelField={['jurisdictionCode', 'jurisdictionName']}
                            showField={['jurisdictionCode', 'jurisdictionName']}
                            defaultValue={{
                                jurisdictionId: cbr?.jurisdictionId,
                                jurisdictionName: cbr?.jurisdictionName,
                            }}
                            label={'Jurisdiction'}
                        />
                    </Grid>
                    <Grid item md={GridSize}>
                        <FmTextField
                            name='narration'
                            multiline={true}
                            rows={2}
                            maxRows={3}
                            control={control}
                            label='Narration'
                        />
                    </Grid>
                </Grid>

                <ActionButtons
                    onSubmit={handleSubmit(handleSubmitData)}
                    onReset={reset}
                    submitLoading={createLoading}
                    submitText='Update'
                />
            </form>
        </GlassCard>
    );
};

export default EditPettyCash;
