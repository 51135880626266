import React, { useState } from 'react';
import GlassCard from '../../../../components/small/GlassCard.tsx';
import { Grid } from '@mui/material';
import FmTextField from '../../../../components/_mui/FmTextField.tsx';
import { useForm } from 'react-hook-form';
import FmSearchableSelect from '../../../../components/_mui/FmSearchableSelect.tsx';
import FmDatePicker from '../../../../components/_mui/FmDatePicker.tsx';
import ActionButtons from '../../../../components/_form/ActionButtons.tsx';
import useLoader from '../../../../components/helpers/UseLoader.tsx';
import { toast } from 'react-toastify';
import { yupResolver } from '@hookform/resolvers/yup';
import { PettyCashTransactionValidationSchema } from '../../../../utils/CommonvalidationSchemas.tsx';
import Crud_Service from '../../../../apis/CrudService.jsx';
import DashboardLayout from '../../../../components/DashboardLayout.tsx';
import { useNavigate } from 'react-router-dom';
import dayjs from 'dayjs';
import { BacktoList } from '../../../../components/small/BackToButton.tsx';
const GridSize = 2.4;

const AddPettyCash = (props) => {
  const { fetchData } = props;
  const crud = new Crud_Service();
  const [resetKey, setResetKey] = useState(0);
  const navigate = useNavigate();
  const { handleSubmit, control, reset } = useForm({
    resolver: yupResolver(PettyCashTransactionValidationSchema),
    mode: 'onChange',
  });
  const { createLoading, startCreateLoading, stopCreateLoading } = useLoader();
  const handleSubmitPettyCash = async (values) => {
    startCreateLoading();
    const combinedData = {
      ...values,
      requestDate: dayjs(values?.requestDate).format('YYYY-MM-DD'),
    };
    await crud.create('pettycashtransactions', combinedData, (_err, res) => {
      if (res?.status === 201) {
        stopCreateLoading();
        toast.success('Petty Cash Created Successfully');
        reset();
        navigate(
          `/financial/cash-and-bank/petty-cash/view/${res?.data?.pettyCashTransactionId}`
        );
        fetchData();
        setResetKey((prev) => prev + 1);
      } else {
        stopCreateLoading();
      }
    });
  };

  return (
    <DashboardLayout
      title={'Petty Cash'}
      actionButtons={<BacktoList />}
      titleReverse
    >
      <GlassCard className='p-3' component='form'>
        <form onSubmit={handleSubmit(handleSubmitPettyCash)}>
          <Grid container spacing={2}>
            <Grid item md={GridSize}>
              <FmSearchableSelect
                name='bankId'
                control={control}
                apiUrl='chartofaccounts'
                valueField='chartOfAccountId'
                labelField={['chartOfAccountName', 'chartOfAccountCode']}
                showField={['chartOfAccountName', 'chartOfAccountCode']}
                label='Account'
                key={resetKey}
                required
              />
            </Grid>

            <Grid item md={GridSize}>
              <FmSearchableSelect
                name='companyId'
                control={control}
                apiUrl='companies'
                valueField='companyId'
                labelField={['companyCode', 'companyName']}
                showField={['companyCode', 'companyName']}
                label='Company'
                key={resetKey}
                required
              />
            </Grid>
            <Grid item md={GridSize}>
              <FmDatePicker
                name='requestDate'
                label='Request Date'
                control={control}
              />
            </Grid>

            <Grid item md={GridSize}>
              <FmSearchableSelect
                name='branchId'
                control={control}
                apiUrl='branches'
                valueField='branchId'
                labelField={['branchCode', 'branchName']}
                showField={['branchCode', 'branchName']}
                label={'Branch'}
                key={resetKey}
              />
            </Grid>
            <Grid item md={GridSize}>
              <FmSearchableSelect
                name='jurisdictionId'
                control={control}
                apiUrl='jurisdictions'
                valueField='jurisdictionId'
                labelField={['jurisdictionCode', 'jurisdictionName']}
                showField={['jurisdictionCode', 'jurisdictionName']}
                label={'Jurisdiction'}
                key={resetKey}
              />
            </Grid>
            <Grid item md={GridSize}>
              <FmTextField
                name='narration'
                multiline={true}
                rows={2}
                maxRows={3}
                control={control}
                label='Narration'
                key={resetKey}
              />
            </Grid>

            <Grid item md={GridSize * 4}>
              <ActionButtons
                onSubmit={handleSubmit(handleSubmitPettyCash)}
                onReset={reset}
                submitLoading={createLoading}
                onCancel={() => navigate(-1)}
                cancelText='Go Back'
                submitText='Create'
              />
            </Grid>
          </Grid>
        </form>
      </GlassCard>
    </DashboardLayout>
  );
};

export default AddPettyCash;
