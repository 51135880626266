import {
  CircularProgress,
  FormControl,
  FormControlLabel,
  Grid,
  Box,
  Divider,
  Stack,
  Radio,
  RadioGroup,
  TextField,
} from '@mui/material';
import React, { useEffect, useState } from 'react';
import FmTextField from '../../../components/_mui/FmTextField.tsx';
import FmSearchableSelect from '../../../components/_mui/FmSearchableSelect.tsx';
import { Controller, useForm, useWatch } from 'react-hook-form';
import Crud_Service from '../../../apis/CrudService.jsx';
import { useNavigate, useParams } from 'react-router-dom';
import useLoader from '../../../components/helpers/UseLoader.tsx';
import ActionButtons from '../../../components/_form/ActionButtons.tsx';
import FmAutoComplete from '../../../components/_mui/FmAutoComplete.tsx';
import {
  tenderBidbondStatusOptions,
  YesNoOptions,
} from '../../../utils/CommonVariables.tsx';
import { toast } from 'react-toastify';
import FmFileInput from '../../../components/_mui/FmFileInput.tsx';
import { yupResolver } from '@hookform/resolvers/yup';
import { bidBondValidationSchema } from '../../../utils/CommonvalidationSchemas.tsx';
import SimpleCardWithActions from '../../../components/card/SimpleCardWithActions.tsx';
import DashboardLayout from '../../../components/DashboardLayout.tsx';

const GridValue = 2.4;

function EditGaranteeManagement() {
  const { id } = useParams();
  const crud = new Crud_Service();
  const navigate = useNavigate();
  const [selectedOption, setSelectedOption] = useState('');
  const { createLoading } = useLoader();
  const [customer, setCustomer] = useState({});
  const [uploadedFileData, setUploadedFileData] = useState();
  const [uploadLoading, setUploadLoading] = useState(false);
  const [loading, setLoading] = useState(false);
  const {
    handleSubmit: creatCheckListSubmit,
    control: creatCheckListControl,
    setValue,
    getValues,
    watch,
    reset: creatCheckListReset,
  } = useForm({
    resolver: yupResolver(bidBondValidationSchema),
    mode: 'onChange',
  });
  const bidBondStatus = getValues('tenderBidbondSecurityCheckStatusName');

  const [statusLifeCycle, setStatusLifeCycle] = useState(null);
  const bidBondStatusId = getValues('tenderBidbondSecurityCheckStatusId');


  
  useEffect(() => {
    switch (bidBondStatusId) {
      case 1:
        setStatusLifeCycle(tenderBidbondStatusOptions.Submitted);
        break;
      case 2:
        setStatusLifeCycle(tenderBidbondStatusOptions.Approved);
        break;
      case 3:
        setStatusLifeCycle(tenderBidbondStatusOptions.Acknowledged);
        break;
      case 4:
        setStatusLifeCycle(tenderBidbondStatusOptions.Rejected);
        break;
      case 5:
        setStatusLifeCycle(
          tenderBidbondStatusOptions['Submitted for bank process']
        );
        break;
      case 6:
        setStatusLifeCycle(tenderBidbondStatusOptions.Handover);
        break;
      case 7:
        setStatusLifeCycle(tenderBidbondStatusOptions.Received);
        break;
      case 8:
        setStatusLifeCycle(
          tenderBidbondStatusOptions['Have issues in BB or SC']
        );
        break;
      case 9:
      case 10:
        setStatusLifeCycle(tenderBidbondStatusOptions['Received from Client']);
        break;
      case 11:
        setStatusLifeCycle(
          tenderBidbondStatusOptions['Submitted to Accounts Team']
        );
        break;
      default:
        setStatusLifeCycle(null);
    }
  }, [bidBondStatusId]);

  const getButtonLabel = () => {
    switch (bidBondStatusId) {
      case 1:
        return 'Submit';
      case 2:
        return 'Approve';
      case 3:
        return 'Acknowledge';
      case 4:
        return 'Reject';
      case 5:
        return 'Submitted for bank process';
      case 6:
        return 'Handover';
      case 7:
        return 'Received';
      case 8:
        return 'Issue in Bidbond';
      case 9:
        return 'Received from Client';
      case 10:
        return 'Received from Client';
      case 11:
        return 'Submit to Accounts Team';
      default:
        return 'Create';
    }
  };

  const handleFileUpload = async (e) => {
    const file = e.target.files[0];
    if (!file) return;

    setUploadLoading(true);

    const formData = new FormData();
    formData.append('file', file);
    formData.append('fileTypeId', 64);

    await crud.create('/files/upload', formData, (err, res) => {
      setUploadLoading(false);

      if (err || res?.status !== 200) {
        toast.error('File upload failed');
        return;
      }

      setUploadedFileData(res?.data);
    });
  };

  const [radioSelection, setRadioSelection] = useState({
    isBidBond: null,
    isSecurityCheck: null,
  });
  const expectedTenderAmount = useWatch({
    control: creatCheckListControl,
    name: 'expectedTenderAmount',
  });
  const securityCheckPercentageValue = useWatch({
    control: creatCheckListControl,
    name: 'securityCheckPercentageValue',
  });
  const bidBondPercentageValue = useWatch({
    control: creatCheckListControl,
    name: 'bidBondPercentageValue',
  });



  useEffect(() => {
    fetchData();
  }, []);

  const fetchData = async () => {
    setLoading(true);
    if (id) {
      await crud.getSingle(`tenderbidbonds`, id, (_err, res) => {
        if (res?.status === 200 && res?.data) {
          const customerData = res.data;
          setCustomer(customerData);

          // Set form values from fetched data
          Object.entries(customerData).forEach(([key, value]) => {
            setValue(key, value);
          });

          if (customerData.isBidBond === true) {
            setValue('isBidBondOrSecurityCheck', 'isBidBond');
          } else {
            setValue('isBidBondOrSecurityCheck', 'isSecurityCheck');
          }
        }
        setLoading(false);
      });
    }
  };

  const handleEditBidBond = async (data) => {
    const combinedData = {
      ...data,
      // Use the form's radio button values directly
      isBidBond: data.isBidBondOrSecurityCheck === 'isBidBond' || false,
      isSecurityCheck:
        data.isBidBondOrSecurityCheck === 'isSecurityCheck' || false,
      isBidBondFixedValue: data.isBidBondFixedValue || false,
      isBidBondPercentage: data.isBidBondPercentage || false,
      isSecurityCheckFixedValue: data.isSecurityCheckFixedValue || false,
      isSecurityCheckPercentage: data.isSecurityCheckPercentage || false,
      bidBondPercentagePrice: data.bidBondPercentagePrice || 0,
      securityCheckPercentagePrice: data.securityCheckPercentagePrice || 0,
      bidBondPercentageValue: bidBondPercentageValue
        ? bidBondPercentageValue
        : 0,
      tenderBidbondSecurityCheckStatusId: statusLifeCycle,
    };

    // Ensure correct handling of fixed and percentage values
    if (combinedData.isBidBondFixedValue) {
      combinedData.fixedBidBondAmount = data.fixedBidBondAmount || 0; // Default to 0 if null
      combinedData.bidBondPercentagePrice = 0;
    } else if (combinedData.isBidBondPercentage === true) {
      combinedData.bidBondPercentageValue = data.bidBondPercentageValue || 0;
    }

    if (combinedData.isSecurityCheckFixedValue) {
      combinedData.fixedSecurityCheckAmount =
        data.fixedSecurityCheckAmount || 0;
      combinedData.securityCheckPercentagePrice = 0;
    } else if (combinedData.isSecurityCheckPercentage) {
      combinedData.securityCheckPercentageValue =
        data.securityCheckPercentageValue || 0;
    }

    // Additional fields
    combinedData.tenderId = customer?.tenderId;
    combinedData.bidBondCustomFormatFileId = uploadedFileData?.fileId;

    // Create or update logic
    if (customer?.tenderBidbondId) {
      await crud.update(
        'tenderbidbonds',
        customer?.tenderBidbondId,
        combinedData,
        (_err, res) => {
          if (res?.status === 200) {
            toast.success('Bid Bond Updated Successfully');
            navigate(-1);
          }
        }
      );
    } else {
      await crud.create('tenderbidbonds', combinedData, (_err, res) => {
        if (res?.status === 201) {
          toast.success('Bid Bond Created Successfully');
          navigate(-1);
        }
      });
    }
  };

  React.useEffect(() => {
    if (expectedTenderAmount && securityCheckPercentageValue) {
      const calculatedPrice =
        (parseFloat(expectedTenderAmount) *
          parseFloat(securityCheckPercentageValue)) /
        100;
      setValue('securityCheckPercentagePrice', calculatedPrice.toFixed(2));
    } else {
      setValue('securityCheckPercentagePrice', '');
    }
  }, [expectedTenderAmount, securityCheckPercentageValue, setValue]);

  React.useEffect(() => {
    if (expectedTenderAmount && bidBondPercentageValue) {
      const calculatedPrice =
        (parseFloat(expectedTenderAmount) *
          parseFloat(bidBondPercentageValue)) /
        100;
      setValue('bidBondPercentagePrice', calculatedPrice.toFixed(2));
    } else {
      setValue('bidBondPercentagePrice', '');
    }
  }, [expectedTenderAmount, bidBondPercentageValue, setValue]);

  return (
    <>
      <DashboardLayout title='Edit Garantee Management'>
        <SimpleCardWithActions title={'Bid Bond'}>
          <Stack
            spacing={2}
            divider={
              <Divider
                orientation='horizontal'
                flexItem
                sx={{ borderStyle: 'dashed', borderWidth: '1.5px' }}
              />
            }
          >
            <Box>
              <Grid container spacing={2}>
                <Grid item md={4}>
                  <TextField
                    onChange={(e) => e.stopPropagation()}
                    value={customer?.tenderName}
                    label={'Tender Name'}
                    variant='filled'
                    fullWidth
                    InputLabelProps={{ shrink: true }}
                  />
                </Grid>
                <Grid item md={4}>
                  <TextField
                    name='tenderRefNo'
                    label='Tender Ref Number'
                    variant='filled'
                    value={customer?.tenderRefNo}
                    onChange={(e) => e.stopPropagation()}
                    InputLabelProps={{ shrink: true }}
                    fullWidth
                  />
                </Grid>
                <Grid item md={4}>
                  <TextField
                    value={customer?.clientName}
                    label='Client Name'
                    variant='filled'
                    onChange={(e) => e.stopPropagation()}
                    InputLabelProps={{ shrink: true }}
                    fullWidth
                  />
                </Grid>
              </Grid>
            </Box>

            <Box>
              {loading ? (
                <CircularProgress />
              ) : (
                <form onSubmit={creatCheckListSubmit(handleEditBidBond)}>
                  <Box className='mb-4'>
                    <Stack
                      spacing={2}
                      divider={
                        <Divider
                          orientation='horizontal'
                          flexItem
                          sx={{ borderStyle: 'dashed', borderWidth: '1.5px' }}
                        />
                      }
                    >
                      <Box>
                        <Grid container spacing={2}>
                          <Grid item md={GridValue * 2}>
                            <FmTextField
                              name='expectedTenderAmount'
                              control={creatCheckListControl}
                              label='Expected Tender Amount'
                            />
                          </Grid>
                        </Grid>
                      </Box>
                      <Box>
                        <Grid container spacing={2}>
                          <Grid item md={GridValue * 2}>
                            <FormControl component='fieldset'>
                              <h2 className='mb-1 font-semibold mb-1'>
                                Bid Bond/Security Check
                              </h2>
                              <Controller
                                name='isBidBondOrSecurityCheck'
                                control={creatCheckListControl}
                                render={({ field: { onChange, value } }) => {
                                  return (
                                    <RadioGroup
                                      row
                                      value={value}
                                      onChange={(ev) => {
                                        // handleBidBondSecurityCheck(ev);
                                        setValue(
                                          'isBidBondOrSecurityCheck',
                                          ev.target.value
                                        );
                                        onChange(ev.target.value);
                                      }}
                                    >
                                      <FormControlLabel
                                        value='isBidBond'
                                        control={
                                          <Radio
                                            size='small'
                                            className='p-0 me-1'
                                          />
                                        }
                                        label='Bid Bond'
                                        className='me-3'
                                      />
                                      <FormControlLabel
                                        value='isSecurityCheck'
                                        control={
                                          <Radio
                                            size='small'
                                            className='p-0 me-1'
                                          />
                                        }
                                        label='Security Check'
                                      />
                                    </RadioGroup>
                                  );
                                }}
                              />
                            </FormControl>
                          </Grid>
                          {getValues('isBidBondOrSecurityCheck') ===
                            'isBidBond' && (
                            <>
                              <Grid item md={GridValue}>
                                <FmAutoComplete
                                  name='autoRenewal'
                                  label='Auto Renewal'
                                  control={creatCheckListControl}
                                  options={YesNoOptions}
                                  displayField='label'
                                  optionFields={['label']}
                                  valueKey='value'
                                />
                              </Grid>

                              <Grid item md={GridValue}>
                                <FmFileInput
                                  documentName={
                                    uploadedFileData?.fileName || ''
                                  }
                                  onChange={(e) => handleFileUpload(e)}
                                  loading={uploadLoading || false}
                                />
                              </Grid>
                            </>
                          )}
                        </Grid>
                      </Box>
                      <Box>
                        <Grid container spacing={2}>
                          {(getValues('isBidBondOrSecurityCheck') ===
                            'isBidBond' ||
                            watch('isBidBondOrSecurityCheck') ===
                              'isBidBond') && (
                            <Grid item md={GridValue * 2}>
                              <FormControl>
                                <h2 className='mb-1 font-semibold'>
                                  Bid Bond Type
                                </h2>
                                <RadioGroup
                                  sx={{ flexDirection: 'row' }}
                                  value={String(
                                    watch('isBidBondFixedValue') ||
                                      getValues('isBidBondFixedValue')
                                  )}
                                  onChange={(e) => {
                                    const isFixed = e.target.value === 'true';
                                    setValue('isBidBondFixedValue', isFixed);
                                    setValue('isBidBondPercentage', !isFixed); // Ensure the opposite field is updated
                                  }}
                                >
                                  <FormControlLabel
                                    value={true}
                                    control={
                                      <Radio
                                        className='p-0 me-2'
                                        size='small'
                                      />
                                    }
                                    label='Fixed Value'
                                    name='isBidBondFixedValue'
                                    className='me-3'
                                  />
                                  <FormControlLabel
                                    value={false}
                                    control={
                                      <Radio
                                        className='p-0 me-2'
                                        size='small'
                                      />
                                    }
                                    label='Percentage'
                                    name='isBidBondPercentage'
                                  />
                                </RadioGroup>
                              </FormControl>
                            </Grid>
                          )}
                          {(getValues('isBidBondFixedValue') ||
                            watch('isBidBondFixedValue')) &&
                            getValues('isBidBondOrSecurityCheck') ===
                              'isBidBond' && (
                              <Grid item md={GridValue}>
                                <FmTextField
                                  name='fixedBidBondAmount'
                                  control={creatCheckListControl}
                                  label='BidBond Amount'
                                />
                              </Grid>
                            )}
                          {(getValues('isBidBondPercentage') ||
                            watch('isBidBondPercentage')) &&
                            getValues('isBidBondOrSecurityCheck') ===
                              'isBidBond' && (
                              <>
                                <Grid item md={GridValue}>
                                  <FmTextField
                                    name='bidBondPercentageValue'
                                    control={creatCheckListControl}
                                    label='Percentage'
                                    type='percentage'
                                  />
                                </Grid>
                                <Grid item md={GridValue}>
                                  <FmTextField
                                    name='bidBondPercentagePrice'
                                    control={creatCheckListControl}
                                    label='Price'
                                    readOnly
                                  />
                                </Grid>
                              </>
                            )}

                          {(getValues('isBidBondOrSecurityCheck') ===
                            'isSecurityCheck' ||
                            watch('isBidBondOrSecurityCheck') ===
                              'isSecurityCheck') && (
                            <Grid item md={GridValue * 2}>
                              <FormControl>
                                <h2 className='mb-1 font-semibold'>
                                  Security Check Type
                                </h2>
                                <RadioGroup
                                  sx={{ flexDirection: 'row' }}
                                  value={String(
                                    watch('isSecurityCheckFixedValue') ||
                                      getValues('isSecurityCheckFixedValue')
                                  )}
                                  onChange={(e) => {
                                    const isFixed = e.target.value === 'true';
                                    setValue(
                                      'isSecurityCheckFixedValue',
                                      isFixed
                                    );
                                    setValue(
                                      'isSecurityCheckPercentage',
                                      !isFixed
                                    ); // Ensure complementary state is updated
                                  }}
                                >
                                  <FormControlLabel
                                    value={true}
                                    control={
                                      <Radio
                                        size='small'
                                        className='p-0 me-2'
                                      />
                                    }
                                    label='Fixed Value'
                                    name='isSecurityCheckFixedValue'
                                    className='me-3'
                                  />
                                  <FormControlLabel
                                    value={false}
                                    control={
                                      <Radio
                                        size='small'
                                        className='p-0 me-2'
                                      />
                                    }
                                    label='Percentage'
                                    name='isSecurityCheckPercentage'
                                  />
                                </RadioGroup>
                              </FormControl>
                            </Grid>
                          )}

                          {(getValues('isSecurityCheckFixedValue') ||
                            watch('isSecurityCheckFixedValue')) &&
                            getValues('isBidBondOrSecurityCheck') ===
                              'isSecurityCheck' && (
                              <Grid item md={GridValue}>
                                <FmTextField
                                  name='fixedSecurityCheckAmount'
                                  control={creatCheckListControl}
                                  label='Security Check Amount'
                                />
                              </Grid>
                            )}

                          {(getValues('isSecurityCheckPercentage') ||
                            watch('isSecurityCheckPercentage')) &&
                            getValues('isBidBondOrSecurityCheck') ===
                              'isSecurityCheck' && (
                              <>
                                <Grid item md={GridValue}>
                                  <FmTextField
                                    name='securityCheckPercentageValue'
                                    control={creatCheckListControl}
                                    label='Percentage'
                                    type='percentage'
                                  />
                                </Grid>
                                <Grid item md={GridValue}>
                                  <FmTextField
                                    name='securityCheckPercentagePrice'
                                    control={creatCheckListControl}
                                    label='Price'
                                    readOnly
                                  />
                                </Grid>
                              </>
                            )}
                        </Grid>
                      </Box>
                      <Box>
                        <Grid container spacing={2}>
                          <Grid item md={GridValue}>
                            <FmTextField
                              name='validity'
                              label='Validity(Month)'
                              control={creatCheckListControl}
                            />
                          </Grid>
                          <Grid item md={GridValue}>
                            <FmTextField
                              name='tenderBidbondSecurityCheckStatusName'
                              control={creatCheckListControl}
                              label='Tender Bidbond Security Check Status'
                              disabled
                            />
                          </Grid>
                        </Grid>
                      </Box>
                      <Box>
                        <Grid container spacing={2}>
                          <Grid item md={GridValue * 2}>
                            <FmTextField
                              name='remarks'
                              control={creatCheckListControl}
                              label='Remarks'
                              rows={2}
                              maxRows={3}
                              multiline={true}
                            />
                          </Grid>
                        </Grid>
                      </Box>
                    </Stack>
                  </Box>
                  {(bidBondStatusId === 2 ||
                    bidBondStatusId === 3 ||
                    bidBondStatusId === 5 ||
                    bidBondStatusId === 6) && (
                    <ActionButtons
                      onSubmit={creatCheckListSubmit(handleEditBidBond)}
                      onReset={creatCheckListReset}
                      submitLoading={createLoading}
                      submitText={getButtonLabel()}
                    />
                  )}
                </form>
              )}
            </Box>
          </Stack>
        </SimpleCardWithActions>
      </DashboardLayout>
    </>
  );
}

export default EditGaranteeManagement;
