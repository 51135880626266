import React, { useEffect, useState } from 'react';
import DashboardLayout from '../../../components/DashboardLayout.tsx';
import { Box, Grid } from '@mui/material';
import GlassCard from '../../../components/small/GlassCard.tsx';
import ActionButtons from '../../../components/_form/ActionButtons.tsx';
import { useNavigate, useParams } from 'react-router-dom';
import Crud_Service from '../../../apis/CrudService.jsx';
import { useForm } from 'react-hook-form';
import useLoader from '../../../components/helpers/UseLoader.tsx';
import CommonLoader from '../../../components/page/CommonLoader.jsx';
import FmTextField from '../../../components/_mui/FmTextField.tsx';
import { toast } from 'react-toastify';
import FmAutoComplete from '../../../components/_mui/FmAutoComplete.tsx';
import useCommonFetchApi from '../../../components/helpers/useCommonFetchApi.tsx';
import { CreateButton } from '../../../components/small/Buttons.jsx';
import FmSearchableSelect from '../../../components/_mui/FmSearchableSelect.tsx';
import FmDatePicker from '../../../components/_mui/FmDatePicker.tsx';
import { statusOptions } from '../../../utils/CommonVariables.tsx';
import dayjs from 'dayjs';
import { yupResolver } from '@hookform/resolvers/yup';
import { AddUniformRequistionsSchema } from '../../../utils/CommonvalidationSchemas.tsx';

const EditUniformRequisition = () => {
  const navigate = useNavigate();
  const crud = new Crud_Service();
  const [loading, setLoading] = useState(false);
  const [editData, setEditData] = useState(null);
  const { handleSubmit, control, setValue, reset } = useForm({
    mode: 'onChange',
    resolver: yupResolver(AddUniformRequistionsSchema),
  });
  const { startEditLoading, stopEditLoading, editLoading } = useLoader();
  const { id } = useParams();
  const GridValue = 2.4;

  //get All Apis
  const { data: loginusers } = useCommonFetchApi('loginusers');
  const { data: uniformsizes } = useCommonFetchApi('uniformsizes');
  const { data: uniformtypes } = useCommonFetchApi('uniformtypes');

  useEffect(() => {
    getUniforRequisition();
  }, []);

  const getUniforRequisition = async () => {
    setLoading(true);
    await crud.getSingle(
      'uniformandaccessoriesrequisitions',
      id,
      (_err, res) => {
        if (res?.status === 200) {
          const data = res?.data;
          Object.entries({
            ...data,
          }).forEach(([key, value]) => {
            setValue(key, value);
          });
          setEditData(res?.data);
          setLoading(false);
        } else {
        }
      }
    );
  };

  const handleEditUniformRequisition = async (values) => {
    startEditLoading();
    const combinedData = {
      ...values,
      endDate: dayjs(values?.endDate).format('YYYY-MM-DD'),
    };
    await crud.update(
      'uniformandaccessoriesrequisitions',
      id,
      combinedData,
      (err, res) => {
        if (res?.status === 200) {
          toast.success('uniform and Accessories Updated Successfully');
          navigate(-1);
          stopEditLoading();
        } else {
          stopEditLoading();
        }
      }
    );
  };
  return (
    <DashboardLayout
      title='Edit Uniform and Accessories-Requisition'
      actionButtons={
        <>
          <Box className='flex flex-row items-center whitespace-nowrap gap-3'>
            <CreateButton
              name='Create'
              onClick={() =>
                navigate('/admin/uniform-accessories-requistions/list/create')
              }
            />
          </Box>
        </>
      }
    >
      <GlassCard className='p-5'>
        {loading ? (
          <CommonLoader />
        ) : (
          <form onSubmit={handleSubmit(handleEditUniformRequisition)}>
            <Grid container spacing={2}>
              <Grid item md={GridValue}>
                <FmSearchableSelect
                  name='projectId'
                  control={control}
                  apiUrl='projects'
                  valueField='projectId'
                  headerField={['Project Code', 'Project Name']}
                  labelField={['projectCode', 'projectName']}
                  showField={['projectName']}
                  label={'Projects'}
                  defaultValue={{
                    projectId: editData?.projectId,
                    projectName: editData?.projectName,
                  }}
                />
              </Grid>
              <Grid item md={GridValue}>
                <FmSearchableSelect
                  name='contractId'
                  control={control}
                  apiUrl='contracts'
                  valueField='contractId'
                  headerField={['Contract Code', 'Contract Name']}
                  labelField={['contractCode', 'contractName']}
                  showField={['contractName']}
                  label={'Contracts'}
                  defaultValue={{
                    contractId: editData?.contractId,
                    contractName: editData?.contractName,
                  }}
                />
              </Grid>
              <Grid item md={GridValue}>
                <FmDatePicker
                  name='endDate'
                  label='End Date'
                  control={control}
                  required
                />
              </Grid>
              <Grid item md={GridValue}>
                <FmSearchableSelect
                  name='requestedBy'
                  control={control}
                  apiUrl='employees'
                  valueField='employeeId'
                  headerField={['Employee Code', 'Employee Name']}
                  labelField={['employeeCode', 'firstName']}
                  showField={['employeeCode', 'firstName']}
                  label='Requested By'
                  defaultValue={{
                    employeeId: editData?.requestedBy,
                    firstName: editData?.requesterName,
                  }}
                />
              </Grid>
              <Grid item md={GridValue}>
                <FmAutoComplete
                  name='approvedBy'
                  control={control}
                  options={loginusers}
                  label='Approved By'
                  displayField='loginUserName'
                  optionFields={['loginUserName']}
                  valueKey='loginUserId'
                  defaultValue={{
                    loginUserId: editData?.approvedBy,
                    loginUserName: editData?.approvedByName,
                  }}
                />
              </Grid>
              <Grid item md={GridValue}>
                <FmSearchableSelect
                  name='employeeId'
                  control={control}
                  apiUrl='employees'
                  valueField='employeeId'
                  headerField={['Employee Code', 'Employee Name']}
                  labelField={['employeeCode', 'firstName']}
                  showField={['employeeCode', 'firstName']}
                  label='Employees'
                  defaultValue={{
                    employeeId: editData?.employeeId,
                    firstName: editData?.employeeName,
                  }}
                />
              </Grid>
              <Grid item md={GridValue}>
                <FmAutoComplete
                  name='uniformSizeId'
                  control={control}
                  options={uniformsizes}
                  label='Uniform Size'
                  displayField='uniformSizeName'
                  optionFields={['uniformSizeName']}
                  valueKey='uniformSizeId'
                />
              </Grid>
              <Grid item md={GridValue}>
                <FmAutoComplete
                  name='uniformTypeId'
                  control={control}
                  options={uniformtypes}
                  label='Uniform Type'
                  displayField='uniformTypeName'
                  optionFields={['uniformTypeName']}
                  valueKey='uniformTypeId'
                />
              </Grid>
              <Grid item md={GridValue}>
                <FmTextField name='qrCode' control={control} label='QR Code' />
              </Grid>
              <Grid item md={GridValue}>
                <FmTextField
                  name='quantity'
                  control={control}
                  label='Quantity'
                />
              </Grid>
              <Grid item md={GridValue}>
                <FmAutoComplete
                  name='status'
                  control={control}
                  options={statusOptions}
                  label='Status'
                  displayField='statusName'
                  optionFields={['statusName']}
                  valueKey='statusId'
                />
              </Grid>
              <Grid item md={5}>
                <FmTextField
                  name='remarks'
                  control={control}
                  label='Remarks'
                  multiline={true}
                  rows={3}
                />
              </Grid>
            </Grid>
            <ActionButtons
              onSubmit={handleSubmit(handleEditUniformRequisition)}
              onReset={reset}
              onCancel={() => navigate(-1)}
              submitLoading={editLoading}
              submitText='Update'
              cancelText='Go Back'
            />
          </form>
        )}
      </GlassCard>
    </DashboardLayout>
  );
};

export default EditUniformRequisition;
