import { yupResolver } from '@hookform/resolvers/yup';
import { Box, CircularProgress, Grid, Typography } from '@mui/material';
import React, { useEffect, useState } from 'react';
import { useForm } from 'react-hook-form';
import { toast } from 'react-toastify';
import Crud_Service from '../../../apis/CrudService.jsx';
import ActionButtons from '../../../components/_form/ActionButtons.tsx';
import FmAutoComplete from '../../../components/_mui/FmAutoComplete.tsx';
import FmTextField from '../../../components/_mui/FmTextField.tsx';
import useLoader from '../../../components/helpers/UseLoader.tsx';
import { AddItemsProps } from '../../../interfaces/ProcurementInterfaces.ts';
import { itemsSchema } from '../../../utils/CommonvalidationSchemas.tsx';
import GlassCard from './../../../components/small/GlassCard.tsx';
import FmSearchableSelect from '../../../components/_mui/FmSearchableSelect.tsx';
import UploadModalBox from '../../../components/upload/UploadModalBox.tsx';
import apiInstance from '../../../apis/ApiService.jsx';
import useCommonFetchApi from '../../../components/helpers/useCommonFetchApi.tsx';
import {
  ItemConditionEnum,
  ItemFlag,
  OrderFrequency,
  StorageCondition,
} from '../../../utils/CommonVariables.tsx';
import DashboardLayout from '../../../components/DashboardLayout.tsx';
import { BacktoListSimple } from '../../../components/small/BackToButton.tsx';
import { useNavigate } from 'react-router-dom';
import FmFileInput from '../../../components/_mui/FmFileInput.tsx';

const AddItems = (props: AddItemsProps) => {
  const { fetchData } = props;
  const crud = new Crud_Service();
  const { startCreateLoading, stopCreateLoading, createLoading } = useLoader();
  const [formLoader, setFormLoader] = useState(false);
  const [uploadedFileIds, setUploadedFileIds] = useState([]);
  const [previewdData, setPreviewData] = useState([]);
  const navigate = useNavigate();
  const [fileId, setFileId] = useState(null);
  const [loading, setLoading] = useState(false);

  const { handleSubmit, control, reset, getValues, setValue } = useForm({
    defaultValues: {},
    mode: 'onChange',
    resolver: yupResolver(itemsSchema),
  });
  const [files, setFiles] = useState([]);
  const [editData, seteditData] = useState(null);
  const { data: item } = useCommonFetchApi('item');
  const { data: itemtypes } = useCommonFetchApi('itemtypes');
  const { data: unitOptions } = useCommonFetchApi('unitofmeasurements');
  const { data: taxcodes } = useCommonFetchApi('taxcodes');
  const { data: costcenters } = useCommonFetchApi('costcenters');
  const { data: valuationmethods } = useCommonFetchApi('valuationmethods');
  const { data: itemgroups } = useCommonFetchApi('itemgroups');
  const { data: brands } = useCommonFetchApi('brands');

  const handleSubmitItems = async (values) => {
    startCreateLoading();
    const CombinedData = {
      ...values,
      fileUploadIds: uploadedFileIds,
      barcode: fileId
    };
    await crud.create(`item`, CombinedData, (err, res) => {
      if (res?.status === 201) {
        stopCreateLoading();
        reset();
        setFiles([]);
        setUploadedFileIds([]);
        setPreviewData([]);
        fetchData();
        navigate('/procurement/item-management');
        toast.success('Item Added Successfully');
      } else {
        stopCreateLoading();
      }
    });
  };

  const handleFileIds = (fileIds) => {
    setUploadedFileIds(fileIds);
  };
  const handleDownloadFile = async (fileId) => {
    try {
      if (!fileId) {
        return;
      }
      const imageUrl = await apiInstance.getFiles(`files/download/${fileId}`);
      setPreviewData((prevData) => [...prevData, imageUrl, fileId]);
    } catch (err) {
      toast.error(`Error fetching image: ${err.message}`);
    }
  };

  const handleFileUpload = async (event) => {
    setLoading(true);
    const file = event.target.files[0];
    if (file) {
      const fileFormData = new FormData();
      fileFormData.append('file', file);
      fileFormData.append('fileTypeId', 33);
      try {
        const response = await new Promise((resolve, reject) => {
          crud.create('files/upload', fileFormData, (_err, res) => {
            if (res?.status === 200) {
              resolve(res);
              setLoading(false);
            } else {
              reject(new Error('File upload failed'));
              setLoading(false);
            }
          });
        });
        setFileId(response?.data?.fileId);
        setValue(`documentName`, response?.data?.originalFileName);
      } catch (error) {
        setLoading(false);
      } finally {
        setLoading(false);
      }
    }
  };

  useEffect(() => {
    if (editData && editData?.itemFiles) {
      editData?.itemFiles.forEach((image) => {
        handleDownloadFile(image?.fileUploadId);
      });
    }
  }, [editData]);

  return (
    <DashboardLayout
      title='Create New Item'
      actionButtons={<BacktoListSimple />}
      titleReverse
    >
      <GlassCard className='p-4'>
        {formLoader ? (
          <Box
            display='flex'
            flexDirection='column'
            justifyContent='center'
            alignItems='center'
          >
            <CircularProgress />
            <Typography variant='body1' sx={{ mt: 2 }}>
              Loading...
            </Typography>
          </Box>
        ) : (
          <form onSubmit={handleSubmit(handleSubmitItems)}>
            <h2 className='text-md font-bold mb-5'>Item Details</h2>
            <Grid container spacing={3}>
              <Grid item md={2.4}>
                <FmTextField
                  name='itemShortCode'
                  control={control}
                  label='Item Code'
                  required
                />
              </Grid>
              <Grid item md={2.4}>
                <FmTextField
                  name='itemName'
                  control={control}
                  label='Item Name'
                  required
                />
              </Grid>

              <Grid item md={2.4}>
                <FmAutoComplete
                  name='itemGroupId'
                  control={control}
                  options={itemgroups}
                  label='Group Item Type'
                  required
                  displayField='itemGroupName'
                  optionFields={['itemGroupCode', 'itemGroupName']}
                  valueKey='itemGroupId'
                />
              </Grid>
              <Grid item md={2.4}>
                <FmAutoComplete
                  name='itemTypeId'
                  control={control}
                  options={itemtypes}
                  label='Item Type'
                  displayField='itemTypeName'
                  optionFields={['itemTypeCode', 'itemTypeName']}
                  valueKey='itemTypeId'
                  required
                />
              </Grid>
              <Grid item md={2.4}>
                <FmAutoComplete
                  name='itemId'
                  control={control}
                  options={item}
                  label='Parent Item'
                  displayField='itemName'
                  optionFields={['itemCode', 'itemName']}
                  valueKey='itemId'
                />
              </Grid>
              <Grid item md={2.4}>
                <FmAutoComplete
                  name='unitOfMeasurementId'
                  control={control}
                  options={unitOptions}
                  label='Unit Of Measurement'
                  displayField='unitOfMeasurementName'
                  optionFields={[
                    'unitOfMeasurementCode',
                    'unitOfMeasurementName',
                  ]}
                  valueKey='unitOfMeasurementId'
                  required
                />
              </Grid>
              <Grid item md={2.4}>
                <FmAutoComplete
                  name='costCenterId'
                  control={control}
                  options={costcenters}
                  label='Cost Center'
                  displayField='costCenterName'
                  optionFields={['costCenterCode', 'costCenterName']}
                  valueKey='costCenterId'
                  required
                />
              </Grid>
              <Grid item md={12}>
                <h2 className='text-md font-bold'>BarCode</h2>
              </Grid>
              <Grid item md={2.4}>
                <FmFileInput
                  name={`barcode`}
                  documentName={getValues(`documentName`)}
                  onChange={(e) => handleFileUpload(e)}
                  loading={loading}
                />
              </Grid>
            </Grid>
            <br />
            <h2 className='text-md font-bold mb-5'>Other Details</h2>
            <Grid spacing={2} container>
              <Grid item md={2.4}>
                <FmAutoComplete
                  name='brandId'
                  control={control}
                  options={brands}
                  label='Brand'
                  displayField='brandName'
                  optionFields={['brandCode', 'brandName']}
                  valueKey='brandId'
                />
              </Grid>
              <Grid item md={2.4}>
                <FmTextField name='make' control={control} label='Make' />
              </Grid>
              <Grid item md={2.4}>
                <FmSearchableSelect
                  name='modelId'
                  control={control}
                  apiUrl='categories'
                  valueField='categoryId'
                  labelField={['categoryCode', 'categoryName']}
                  showField={['categoryCode', 'categoryName']}
                  label='Model'
                />
              </Grid>
              <Grid item md={2.4}>
                <FmAutoComplete
                  name='valuationMethodId'
                  control={control}
                  options={valuationmethods}
                  label='Valuation Method'
                  displayField='valuationMethodName'
                  optionFields={['valuationMethodName']}
                  valueKey='valuationMethodId'
                />
              </Grid>
              <Grid item md={2.4}>
                <FmAutoComplete
                  name='taxCodeId'
                  control={control}
                  options={taxcodes}
                  label='Tax Code'
                  displayField='taxCodeName'
                  optionFields={['taxCodeCode', 'taxCodeName']}
                  valueKey='taxCodeId'
                />
              </Grid>
              <Grid item md={2.4}>
                <FmTextField
                  name='stockLevelThresholds'
                  control={control}
                  label='Stock Level Thresholds'
                />
              </Grid>
              <Grid item md={2.4}>
                <FmTextField
                  name='minimumOrderQuantity'
                  control={control}
                  label='Minimum Order Quantity'
                />
              </Grid>
              <Grid item md={2.4}>
                <FmAutoComplete
                  name='itemCondition'
                  control={control}
                  options={ItemConditionEnum}
                  label='Item Condition'
                  displayField='label'
                  optionFields={['label']}
                  valueKey='value'
                />
              </Grid>
              <Grid item md={2.4}>
                <FmAutoComplete
                  name='orderFrequency'
                  control={control}
                  options={OrderFrequency}
                  label='Order Frequency'
                  displayField='label'
                  optionFields={['label']}
                  valueKey='value'
                />
              </Grid>
              <Grid item md={2.4}>
                <FmAutoComplete
                  name='storageCondition'
                  control={control}
                  options={StorageCondition}
                  label='Storage Condition'
                  displayField='label'
                  optionFields={['label']}
                  valueKey='value'
                />
              </Grid>
              <Grid item md={2.4}>
                <FmTextField
                  name='expiryNotification'
                  control={control}
                  label='Expiry Notifications(Days)'
                />
              </Grid>
              <Grid item md={2.4}>
                <FmAutoComplete
                  name='itemFlag'
                  control={control}
                  options={ItemFlag}
                  label='Item Flag'
                  displayField='label'
                  optionFields={['label']}
                  valueKey='value'
                />
              </Grid>
              <Grid item md={2.4}>
                <FmTextField
                  name='technicalSpecification'
                  control={control}
                  label='Technical Specifications'
                />
              </Grid>
              <Grid item md={4.8}>
                <FmTextField
                  name='description'
                  control={control}
                  label='Description'
                  multiline
                  rows={3}
                  maxRows={5}
                />
              </Grid>
              <Grid item md={12}>
                <h2 className='text-md font-bold'>Upload</h2>
              </Grid>
              <Grid item md={2.4}>
                <UploadModalBox
                  onSubmitFiles={handleFileIds}
                  files={files}
                  setFiles={setFiles}
                  previewdData={previewdData}
                />
              </Grid>
            </Grid>
            <br />

            <ActionButtons
              onSubmit={handleSubmit(handleSubmitItems)}
              onCancel={() => navigate(-1)}
              onReset={reset}
              submitLoading={createLoading}
              cancelLoading={false}
              submitText='Create'
            />
          </form>
        )}
      </GlassCard>
    </DashboardLayout>
  );
};

export default AddItems;
