import React, { useEffect, useState } from 'react'
import GlassCard from '../../../../../components/small/GlassCard.tsx'
import { Grid } from '@mui/material'
import { useForm } from 'react-hook-form';
import FmAutoComplete from '../../../../../components/_mui/FmAutoComplete.tsx';
import useCommonFetchApi from '../../../../../components/helpers/useCommonFetchApi.tsx';
import FmSearchableSelect from '../../../../../components/_mui/FmSearchableSelect.tsx';
import FmTextField from '../../../../../components/_mui/FmTextField.tsx';
import FmDatePicker from '../../../../../components/_mui/FmDatePicker.tsx';
import { toast } from 'react-toastify';
import useLoader from '../../../../../components/helpers/UseLoader.tsx';
import dayjs from 'dayjs';
import { useNavigate, useParams } from 'react-router-dom';
import Crud_Service from '../../../../../apis/CrudService.jsx';
import ActionButtons from '../../../../../components/_form/ActionButtons.tsx';
import { yupResolver } from '@hookform/resolvers/yup';
import { InventorytransferSchema } from '../../../../../utils/CommonvalidationSchemas.tsx';
import { ViewField } from '../../../../../components/_form/FormElements.jsx';

const CreateInventoryTransferFields = () => {
    const GridValue = 2.4;
    const { control, handleSubmit, reset, setValue } = useForm({
        resolver: yupResolver(InventorytransferSchema),
        mode: 'onChange',
    });
    const navigate = useNavigate();
    const crud = new Crud_Service();
    const { id } = useParams();
    const { data: item } = useCommonFetchApi('item');
    const { data: storelocations } = useCommonFetchApi('storelocations');
    const { createLoading, startCreateLoading, stopCreateLoading } = useLoader();
    const [editData, setEditData] = useState(null);
    const [availableQuantity, setAvailableQuantity] = useState(0);
    const [selectedItem, setSelectedItem] = useState(null);
    const [selectedLocation, setSelectedLocation] = useState(null);
    const [selectedToLocation, setSelectedToLocation] = useState(null);
    const [availableQuantityFrom, setAvailableQuantityFrom] = useState(0); // For From location
    const [availableQuantityTo, setAvailableQuantityTo] = useState(0);
    useEffect(() => {
        if (id) {
            (async () => {
                await crud.getSingle('inventoryitemtransfers', id, (err, res) => {
                    if (res?.status === 200) {
                        setEditData(res?.data);
                        Object.entries({
                            ...res.data,
                        }).forEach(([key, value]) => {
                            setValue(key, value);
                        });
                    } else {
                        setEditData(null);
                    }
                });
            })();
        }
    }, [id]);
    const onSubmit = async (values) => {
        startCreateLoading();
        const CombinedData = {
            ...values,
            transferDate: dayjs(values?.transferDate).format('YYYY-MM-DD'),
        };
        const updateCombinedData = {
            ...values,
            transferDate: dayjs(values?.transferDate).format('YYYY-MM-DD'),
            status: 2,
        };

        if (!id) {
            await crud.create(`inventoryitemtransfers`, CombinedData, (err, res) => {
                if (res?.status === 201) {
                    stopCreateLoading();
                    navigate(`/procurement/inventory-management/inventory-transfer`);
                    toast.success('e Added Successfully');
                } else {
                    stopCreateLoading();
                }
            });
        } else {
            await crud.update(`inventoryitemtransfers`, id, updateCombinedData, (err, res) => {
                if (err) return toast.error(err);
                if (res?.status === 200) {
                    stopCreateLoading();
                    navigate(`/procurement/inventory-management/inventory-transfer`);
                    toast.success('JourInventory Transfer Updated Successfully');
                } else {
                    stopCreateLoading();
                }
            });
        }
    };

    useEffect(() => {
        const fetchItemDetails = async (locationId, itemId, type) => {
            if (!itemId || !locationId) {
                if (type === 'from') {
                    setAvailableQuantityFrom(0);
                    setValue('availableQn', 0);
                    setValue('quantityToTransfer', '');
                } else {
                    setAvailableQuantityTo(0);
                    setValue('availableQnForToLocation', 0);
                }
                return;
            }

            crud.getAll(
                'inventoryitems',
                { storelocationId: locationId, itemId: itemId },
                (err, res) => {
                    if (err) {
                        toast.error(`Failed to fetch ${type} location details. Please try again.`);
                        return;
                    }
                    if (res?.status === 200) {
                        const inventoryData = Array.isArray(res?.data?.data)
                            ? res?.data?.data[0]
                            : res?.data?.data;
                        const qty = inventoryData?.availableQuantity || 0;
                        if (type === 'from') {
                            setAvailableQuantityFrom(qty);
                            setValue('availableQn', qty);
                        } else {
                            setAvailableQuantityTo(qty);
                            setValue('availableQnForToLocation', qty);
                        }
                    } else {
                        toast.error(`Failed to fetch ${type} location details. Please try again.`);
                    }
                }
            );
        };

        // Fetch for From location
        fetchItemDetails(selectedLocation, selectedItem, 'from');

        // Fetch for To location
        fetchItemDetails(selectedToLocation, selectedItem, 'to');
    }, [selectedItem, selectedLocation, selectedToLocation, setValue]);
    return (
        <>
            <GlassCard className='p-4'>
                <form onSubmit={handleSubmit(onSubmit)}>
                    <Grid container spacing={2}>
                        <Grid item md={GridValue}>
                            <FmAutoComplete
                                name='itemId'
                                control={control}
                                options={item}
                                label='Item Name'
                                displayField='itemCode'
                                optionFields={['itemCode', 'itemName']}
                                valueKey='itemId'
                                required
                                onChange={(e) => setSelectedItem(e)}
                            />
                        </Grid>
                        <Grid item md={GridValue}>
                            <FmAutoComplete
                                name='fromStoreLocationId'
                                control={control}
                                options={storelocations}
                                label='From Store Location'
                                displayField='storeLocationName'
                                optionFields={['storeLocationCode', 'storeLocationName']}
                                valueKey='storeLocationId'
                                required
                                onChange={(e) => setSelectedLocation(e)}
                            />
                        </Grid>
                        <Grid item md={GridValue}>
                            <FmTextField
                                name='availableQn'
                                readOnly
                                control={control}
                                label='Available Quantity(From)'
                                value={availableQuantityFrom} />
                        </Grid>
                        <Grid item md={GridValue}>
                            <FmAutoComplete
                                name='toStoreLocationId'
                                control={control}
                                options={storelocations}
                                label='To Store Location'
                                displayField='storeLocationName'
                                optionFields={['storeLocationCode', 'storeLocationName']}
                                valueKey='storeLocationId'
                                required
                                onChange={(e) => setSelectedToLocation(e)}
                            />
                        </Grid>
                        <Grid item md={GridValue}>
                            <FmTextField
                                name="availableQnForToLocation"
                                readOnly
                                control={control}
                                label="Available Quantity(To)"
                                value={availableQuantityTo}
                            />
                        </Grid>
                        <Grid item md={GridValue}>
                            <FmTextField
                                name='quantityToTransfer'
                                control={control}
                                label='Quantity To Transfer'
                                required
                            />
                        </Grid>
                        <Grid item md={GridValue}>
                            <FmDatePicker
                                name='transferDate'
                                control={control}
                                label='Transfer Date'
                                required
                            />
                        </Grid>
                        <Grid item md={GridValue}>
                            <FmSearchableSelect
                                name='transferredBy'
                                control={control}
                                apiUrl='loginusers'
                                valueField='loginUserId'
                                labelField={[
                                    'employeeCode',
                                    'employeeName',
                                    'employeeMail',
                                ]}
                                headerField={['Code', 'Name', 'Email']}
                                showField={['employeeCode', 'employeeName', 'employeeMail']}
                                label='Transferred By'
                                defaultValue={{
                                    loginUserId: editData?.transferredBy,
                                    employeeName: editData?.transferredByName,
                                }}
                                required
                            />
                        </Grid>
                        <Grid item md={12}>
                            <ActionButtons
                                onSubmit={handleSubmit(onSubmit)}
                                onCancel={() => navigate(-1)}
                                onReset={reset}
                                submitLoading={createLoading}
                                cancelLoading={false}
                                cancelText='Back'
                                removeSpacing
                                submitText={id ? 'Update' : 'Create'}
                            />
                        </Grid>
                    </Grid>
                </form>
            </GlassCard>
        </>

    )
}

export default CreateInventoryTransferFields
