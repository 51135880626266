//TODO
// // import React from 'react';
// // import { Grid } from '@mui/material';
// // import { ViewField } from '../_form/FormElements';
// // import useDateFormatter from './useDateFormatter';
// // import FmViewDateField from '../_mui/FmViewDateField.tsx';

// // const DynamicViewFields = ({ data, fields, fieldFormat = '', gridValue = 2.4 }) => {
// //   const formatDate = useDateFormatter();

// //   const cleanLabel = (label) => {
// //     if (label.endsWith('Id')) {
// //       return label.slice(0, -2);
// //     }
// //     return label;
// //   };

// //   const formatTitle = (key, title) => {
// //     if (
// //       key.toLowerCase().endsWith('date') ||
// //       key.toLowerCase().endsWith('day')
// //     ) {
// //       return formatDate(title);
// //     }
// //     if (fieldFormat[key]) {
// //       return fieldFormat[key](title);
// //     } else {
// //       return title;
// //     }
// //   };


// //   return (
// //     <Grid container spacing={3}>
// //       {fields?.map((field) => (
// //         <Grid item md={field?.md || gridValue} key={field?.key}>
// //           {field.key.toLowerCase().endsWith('date') ||
// //           field.key.toLowerCase().endsWith('day') ? (
// //             <FmViewDateField
// //               title={cleanLabel(field?.label)}
// //               value={data[field?.key]}
// //             />
// //           ) : (
// //             <ViewField
// //               label={cleanLabel(field?.label)}
// //               title={formatTitle(field?.key, data[field?.key])}
// //             />
// //           )}
// //         </Grid>
// //       ))}
// //     </Grid>
// //   );
// // };

// // export default DynamicViewFields;
// import React, { useState } from 'react';
// import { Grid } from '@mui/material';
// import { ViewField } from '../_form/FormElements';
// import useDateFormatter from './useDateFormatter';
// import FmViewDateField from '../_mui/FmViewDateField.tsx';
// const DynamicViewFields = ({ data, fields, fieldFormat = '', gridValue = 2.4,excludeNestedArray }) => {
//   const formatDate = useDateFormatter();
//   const [array, setArray] = useState([])

//   const cleanLabel = (label) => {
//     if (label.endsWith('Id')) {
//       return label.slice(0, -2);
//     }
//     return label;
//   };
//   const formatTitle = (key, title) => {

//     setArray(title)
  
//     if (typeof title === 'object' && title !== null) {
//       // Render object or array with specific fields
//       return (
//         <div>
//           {Array.isArray(title)
//             ? title.map((item, index) => (
//                 <div key={index}>
//                   {typeof item === 'object' ? (
//                     <div>
//                       {Object.entries(item)
//                         .filter(([subKey]) => excludeNestedArray?.includes(subKey))
//                         .map(([subKey, subValue]) => (
//                           <div key={subKey}>
//                             <strong>{subKey}:</strong> {String(subValue)}
//                           </div>
//                         ))}
//                     </div>
//                   ) : (
//                     String(item)
//                   )}
//                 </div>
//               ))
//             : Object.entries(title)
//                 .filter(([subKey]) => excludeNestedArray.includes(subKey))
//                 .map(([subKey, subValue]) => (
//                   <div key={subKey}>
//                     <strong>{subKey}:</strong> {String(subValue)}
//                   </div>
//                 ))}
//         </div>
//       );
//     }
  
//     if (key.toLowerCase().endsWith('date') || key.toLowerCase().endsWith('day')) {
//       return formatDate(title);
//     }
  
//     if (fieldFormat[key]) {
//       return fieldFormat[key](title);
//     }
  
//     return title; // Return plain value for non-object titles
//   };
  
  

//   return (
//     <Grid container spacing={3}>
//       {fields?.map((field) => (
//         <Grid item md={field?.md || gridValue} key={field?.key}>
//           {field.key.toLowerCase().endsWith('date') ||
//           field.key.toLowerCase().endsWith('day') ? (
//             <FmViewDateField
//               title={cleanLabel(field?.label)}
//               value={data[field?.key]}
//             />
//           ) : (
//             <ViewField
//               label={cleanLabel(field?.label)}
//               title={formatTitle(field?.key, data[field?.key])}
//             />
//           ) }
//         </Grid>
//       ))}
//     </Grid>
//   );
// };

// export default DynamicViewFields;

import { Grid } from '@mui/material';
import React from 'react';
import { ViewField } from '../_form/FormElements';
import FmViewDateField from '../_mui/FmViewDateField.tsx';
import useDateFormatter from './useDateFormatter';

const DynamicViewFields = ({ data, fields, fieldFormat = '', gridValue = 2.4, excludeNestedArray }) => {
  const formatDate = useDateFormatter();

  const cleanLabel = (label) => {
    if (label.endsWith('Id')) {
      return label.slice(0, -2);
    }
    return label;
  };

  const isArrayType = (value) => {
    return Array.isArray(value);
  };

  const formatTitle = (key, title) => {
    if (typeof title === 'object' && title !== null) {
      return (
        <div>
          {Array.isArray(title)
            ? title.map((item, index) => (
                <div key={index}>
                  {typeof item === 'object' ? (
                    <div>
                      {Object.entries(item)
                        .filter(([subKey]) => excludeNestedArray?.includes(subKey))
                        .map(([subKey, subValue]) => (
                          <div key={subKey}>
                            <strong>{subKey}:</strong> {String(subValue)}
                          </div>
                        ))}
                    </div>
                  ) : (
                    String(item)
                  )}
                </div>
              ))
            : Object.entries(title)
                .filter(([subKey]) => excludeNestedArray?.includes(subKey))
                .map(([subKey, subValue]) => (
                  <div key={subKey}>
                    <strong>{subKey}:</strong> {String(subValue)}
                  </div>
                ))}
        </div>
      );
    }

    if (key.toLowerCase().endsWith('date') || key.toLowerCase().endsWith('day')) {
      return formatDate(title);
    }

    if (fieldFormat[key]) {
      return fieldFormat[key](title);
    }

    return title;
  };

  return (
    <Grid container spacing={2}>
      {fields?.map((field) => (
        <Grid item md={field?.md || gridValue} key={field?.key}>
          {field.key.toLowerCase().endsWith('date') ||
          field.key.toLowerCase().endsWith('day') ? (
            <FmViewDateField
              title={cleanLabel(field?.label)}
              value={data[field?.key]}
            />
          ) : (
            <ViewField
              label={cleanLabel(field?.label)}
              title={formatTitle(field?.key, data[field?.key])}
            />
          )}
        </Grid>
      ))}
    </Grid>
  );
};

export default DynamicViewFields;

