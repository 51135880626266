import React, { useState, useEffect } from 'react';
import GlassCard from '../../../../components/small/GlassCard.tsx';
import Icons from '../../../../utils/Icon.jsx';
import { Box, Button } from '@mui/material';
import { useNavigate, useLocation } from 'react-router-dom';
import FmMRTDataTable from '../../../../components/FmMRTDataTable.tsx';
import { toast } from 'react-toastify';
import dayjs from 'dayjs';
import useLoader from '../../../../components/helpers/UseLoader.tsx';
import TableActionButtons from '../../../../components/_form/TableActionButtons.jsx';
import Crud_Service from '../../../../apis/CrudService.jsx';
import useTableLogic from '../../../../components/helpers/MRTUseTableLogic.tsx';
import { Icon } from '@iconify/react/dist/iconify.js';
import ColumnPettyCash from './ColumnPettyCash.tsx';

const ListPettyCash = ({ status }) => {
  const {
    rows,
    isLoading,
    pagination,
    sorting,
    columnFilters,
    globalFilter,
    tableRecordCounts,
    setPagination,
    setSorting,
    setColumnFilters,
    setGlobalFilter,
    toggleLoading,
    fetchData,
    setSearchKeyword,
    searchKeyword,
  } = useTableLogic(`pettycashtransactions?pettyCashStatusId=${status === -1 ? 0 : status}&overDue=${status === -1 ? true : false}`);

  const navigate = useNavigate();
  const location = useLocation();
  const crud = new Crud_Service();
  const [editingRowId, setEditingRowId] = useState(null);
  const [editingRowData, setEditingRowData] = useState(null);
  const { startEditLoading, stopEditLoading } = useLoader();
  const [rowSelection, setRowSelection] = useState({});
  const [selectedRows, setSelectedRows] = useState([]);

  const handleView = (id) => {
    navigate(`${location.pathname}/view/${id}`);
  };

  const handleUpdateEvent = async (values) => {
    try {
      startEditLoading();
      const combinedData = {
        ...values,
        requestDate: dayjs(values?.requestDate).format('YYYY-MM-DD'),
        Status: 2,
      };
      await crud.update(
        'pettycashtransactions',
        editingRowId,
        combinedData,
        (_err, res) => {
          if (res?.status === 200) {
            stopEditLoading();
            fetchData();
            toast.success('Petty Cash Updated Successfully');
            setEditingRowData(null);
            setEditingRowId(null);
          } else {
            stopEditLoading();
            toast.error('Failed to update');
          }
        }
      );
    } catch (error) {
      stopEditLoading();
      toast.error('An error occurred while updating');
    }
  };
  const handleDelete = async (props) => {
    await crud.remove(
      'pettycashtransactions',
      props?.original?.pettyCashTransactionId,
      (__err, res) => {
        if (res?.status === 204) {
          toast.success('Petty Cash Deleted Successfully');
          fetchData();
        } else {
        }
      }
    );
  };

  const ActionData = [
    {
      name: 'Edit',
      icon: Icons.edit,
      onClick: (props) => {
        handleView(props?.original?.pettyCashTransactionId);
      },
    },

    {
      name: 'Delete',
      icon: Icons.delete,
      onClick: (props) => handleDelete(props),
    },
  ];
  // Define the columns
  const columns = [
    {
      field: 'action',
      header: 'Actions',
      sortable: false,
      hideable: false,
      disableClickEventBubbling: false,
      size: 80,

      Cell: (params) => {
        return (
          <Box className='flex gap-2'>
            {editingRowId === params?.row?.original?.pettyCashTransactionId ? (
              <>
                <Button onClick={handleSubmit(handleUpdateEvent)}>Save</Button>
                <Button onClick={handleCancel}>Cancel</Button>
              </>
            ) : (
              <TableActionButtons
                Actions={ActionData?.map((action) => ({
                  ...action,
                  onClick: () => action.onClick(params.row),
                }))}
                selectedRow={params.row}
                setSelectedRow={() => {}}
              />
            )}
          </Box>
        );
      },
    },
    ...ColumnPettyCash,
  ];

  useEffect(() => {
    fetchData();
  }, [status]);

  useEffect(() => {
    if (rowSelection) {
      const selectedIds = Object.keys(rowSelection)
        .map((rowId) => rows[rowId]?.pettyCashTransactionId)
        .filter(Boolean);
      setSelectedRows(selectedIds);
    }
  }, [rowSelection, rows]);

  const handleNavigate = () => {
    const pathSegments = location.pathname.split('/');
    pathSegments[pathSegments.length - 1] = 'payments';
    const newPath = pathSegments.join('/');
    navigate(newPath);
  };

  const handleSendInvoices = async () => {
    toggleLoading(true);
    const CombinedData = {
      pettyCashIds: selectedRows,
    };
    await crud.create(
      `pettycashtransactions/movetopayment`,
      CombinedData,
      (err, res) => {
        if (res?.status === 201) {
          toast.success('Payment Created Successfully');
          setRowSelection([]);
          setSelectedRows([]);
          fetchData();
          handleNavigate();
          toggleLoading(false);
        } else {
          stopEditLoading();
          toggleLoading(false);
        }
      }
    );
  };

  return (
    <>
      <GlassCard className='flex-1 min-h-0'>
        <FmMRTDataTable
          columns={columns}
          enableRowSelection={false}
          rows={rows}
          rowCount={tableRecordCounts}
          editingRow={editingRowId}
          pagination={pagination}
          sorting={sorting}
          columnFilters={columnFilters}
          globalFilter={globalFilter}
          setPagination={setPagination}
          setSorting={setSorting}
          setColumnFilters={setColumnFilters}
          setGlobalFilter={setGlobalFilter}
          isLoading={isLoading}
          setSearchKeyword={setSearchKeyword}
          searchKeyword={searchKeyword}
          fetchData={fetchData}
          handleRowDoubleClick={(e) =>
            handleView(e?.original?.pettyCashTransactionId)
          }
          enableRowSelection={true}
          rowSelection={rowSelection}
          setRowSelection={setRowSelection}
          muiSelectCheckboxProps={({ row }) => ({
            disabled: row?.original?.pettyCashStatusId !== 3,
          })}
          muiTableContainerProps={{
            sx: {
              maxHeight: '100%',
              flex: 1,
            },
          }}
          showSerialNumber={true}
          rightPinning={['pettyCashTotalAmount']}
          rowSelectionCustomProps={
            <Button
              className='whitespace-nowrap py-0 px-3 me-2 h-[31px]'
              variant='contained'
              color='info'
              startIcon={
                <Icon
                  icon='mdi:invoice-text-send-outline'
                  width='24'
                  height='24'
                />
              }
              onClick={handleSendInvoices}
            >
              Generate Payment Voucher
            </Button>
          }
        />
      </GlassCard>
    </>
  );
};

export default ListPettyCash;
