import React, { useEffect, useState } from 'react';
import DashboardLayout from '../../../../components/DashboardLayout.tsx';
import GlassCard from '../../../../components/small/GlassCard.tsx';
import BackToButton from '../../../../components/small/BackToButton.tsx';
import { Grid, Tab, Tabs } from '@mui/material';
import { ViewTable } from '../../../../components/_form/FormElements';
import { useNavigate, useParams } from 'react-router-dom';
import { StatusBadge } from '../../../../components/_form/FormElements';
import ViewProjectContractsList from './ViewProjectContractsList.tsx';
import ViewProjectsRevenueList from './ViewProjectsRevenueList.tsx';
import ViewProjectCostList from './ViewProjectCostList.tsx';
import Crud_Service from '../../../../apis/CrudService.jsx';
import ViewDetailsCollapse from './../../../../components/view/ViewDetailsCollapse.tsx';
import CurrencyView from '../../../../components/_mui/FmCurrencyView.tsx';

const navMenu = [
  {
    id: 1,
    name: 'Contracts',
  },
  {
    id: 2,
    name: 'Projects Revenue',
  },
  {
    id: 3,
    name: 'Projects Cost',
  },
];

const ViewProjects = () => {
  const [value, setValue] = useState(navMenu[0]?.id || 0);
  const [viewData, setViewData] = useState([]);
  const navigation = useNavigate();

  const { id } = useParams();
  const crud = new Crud_Service();

  const viewDataFetch = async () => {
    crud.getSingle('projects', id, (err, res) => {
      if (res?.status === 200) {
        setViewData(res?.data);
      } else {
        setViewData([]);
      }
    });
  };

  useEffect(() => {
    if (id) {
      viewDataFetch();
    }
  }, [id]);

  const handleChange = (event, newValue) => {
    setValue(newValue);
  };

  useEffect(() => {
    document.title = 'View | Project | Presales';
  });

  return (
    <DashboardLayout
      title='PROJECT-000023'
      actionButtons={
        <BackToButton
          onClick={() => navigation(-1)}
          title='Back to Projcts'
          className='p-0 '
        />
      }
      titleReverse
    >
      <GlassCard className='p-3'>
        <ViewDetailsCollapse>
          <Grid container spacing={2}>
            <Grid item md={4}>
              <ViewTable
                rows={[
                  {
                    label: 'Project Code',
                    title: viewData?.projectCode || 'N/A',
                  },
                  {
                    label: 'Project Name',
                    title: viewData?.projectName || 'N/A',
                  },
                  {
                    label: 'Delivery Date',
                    title: viewData?.deliveryDate,
                  },
                  {
                    label: 'Project InCharge',
                    title: viewData?.projectInChargeName,
                  },
                ]}
              />
            </Grid>
            <Grid item md={4}>
              <ViewTable
                rows={[
                  {
                    label: 'Project Location',
                    title: viewData?.projectLocation || 'N/A',
                  },
                  {
                    label: 'Client Name',
                    title: viewData?.clientName || 'N/A',
                  },
                  {
                    label: 'Tender Name',
                    title: viewData?.tenderName || 'N/A',
                  },
                  {
                    label: 'Division Name',
                    title: viewData?.divisionName || 'N/A',
                  },
                ]}
              />
            </Grid>
            <Grid item md={4}>
              <ViewTable
                rows={[
                  {
                    label: 'Project Type',
                    title: viewData?.projectTypeName || 'N/A',
                  },
                  {
                    label: 'Project Value',
                    title: (
                      <CurrencyView
                        value={viewData?.projectValue || 'N/A'}
                        currencyType={'AED'}
                      />
                    ),
                  },

                  {
                    label: 'Incentive',
                    title: (
                      <CurrencyView
                        value={viewData?.incentive || 'N/A'}
                        currencyType={'AED'}
                      />
                    ),
                  },
                ]}
              />
            </Grid>
          </Grid>
        </ViewDetailsCollapse>
      </GlassCard>
      <GlassCard className='my-2'>
        <Tabs
          value={value}
          onChange={handleChange}
          textColor='secondary'
          indicatorColor='secondary'
          centered
        >
          {navMenu?.map((item) => {
            return <Tab value={item.id} label={item.name} key={item.name} />;
          })}
        </Tabs>
      </GlassCard>
      {value === 1 && <ViewProjectContractsList />}
      {value === 2 && <ViewProjectsRevenueList />}
      {value === 3 && <ViewProjectCostList />}
    </DashboardLayout>
  );
};

export default ViewProjects;
