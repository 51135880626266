import React, { memo } from 'react';
import { Dialog, IconButton, DialogContent, DialogTitle } from '@mui/material';
import { Icon } from '@iconify/react';

const MuiDialogOne = memo(
  ({ title, open, onClose, children, lg, width = '600px', ...props }) => {
    return (
      <Dialog
        onClose={onClose}
        open={open}
        PaperProps={{
          classes: { root: 'w-full' },
          sx: {
            maxWidth: lg ? '900px' : width,
            bgcolor: 'background.white',
          },
        }}
        backdropClick
      >
        <DialogTitle sx={dialogTitleStyles}>{title}</DialogTitle>
        <IconButton aria-label='close' onClick={onClose} sx={iconButtonStyles}>
          <Icon icon='material-symbols:close' />
        </IconButton>
        <DialogContent sx={dialogContentStyles} dividers>
          {children}
        </DialogContent>
      </Dialog>
    );
  }
);

const dialogTitleStyles = {
  m: 0,
  p: 2,
  color: 'text.dark',
  fontWeight: '600',
  fontSize: '16px',
};

const iconButtonStyles = {
  position: 'absolute',
  right: 8,
  top: 8,
  color: 'text.main',
};

const dialogContentStyles = {
  bgcolor: 'background.white',
  padding: '20px',
};

export default MuiDialogOne;
