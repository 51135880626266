import React, { useEffect, useState } from 'react';
import GlassCard from './../../../../../components/small/GlassCard.tsx';
import { Grid, Typography, Box } from '@mui/material';
import * as yup from 'yup';
import { yupResolver } from '@hookform/resolvers/yup';
import Crud_Service from '../../../../../apis/CrudService.jsx';
import { useForm } from 'react-hook-form';
import { toast } from 'react-toastify';
import dayjs from 'dayjs';
import FmTextField from '../../../../../components/_mui/FmTextField.tsx';
import ActionButtons from '../../../../../components/_form/ActionButtons.tsx';
import FmSearchableSelect from '../../../../../components/_mui/FmSearchableSelect.tsx';
import FmDatePicker from '../../../../../components/_mui/FmDatePicker.tsx';
import { useNavigate, useParams } from 'react-router-dom';
import { useDispatch, useSelector } from 'react-redux';
import { updateQuotationSuccess } from '../../../../../store/salesQuotaionSlice.ts';
import { Icon } from '@iconify/react/dist/iconify.js';
import InternalBudget from '../../CreateSalesOrder/internalBudget/InternalBudget.tsx';
import ViewContractDetails from './ViewContractDetails.tsx';
import FmCurrencyTextField from '../../../../../components/_mui/FmCurrencyTextField.tsx';
const GridSize = 3;

const schema = yup.object().shape({
  salesOrderName: yup.string().required('Sales Order Name is required'),
  orderDate: yup.date().required('Order Date is required'),
  contractId: yup
    .number()
    .transform((value, originalValue) => (originalValue === '' ? null : value))
    .required('Contract is required'),
});
interface ContractDetails {
  projectCode?: string;
  projectName?: string;
  clientName?: string;
  contactPerson?: string;
  contactNumber?: string;
  contactEmailId?: string;
  locationName?: string;
  paymentTerms?: string;
}

const EditSalesOrder = (props) => {
  const { setGetData, type } = props;
  const crud = new Crud_Service();
  const navigate = useNavigate();
  const { control, handleSubmit, reset, setValue, getValues, watch } = useForm({
    resolver: yupResolver(schema),
    mode: 'onChange',
  });
  const [selectedContract, setSelectedContract] = useState<ContractDetails>({});
  const AmountFieldValue = watch('amount');
  const [selectedOrder, setSelectedOrder] = useState({});
  const [editingRowData, setEditingRowData] = useState(null);
  const dispatch = useDispatch();
  const { salesQuotation, loading, error } = useSelector(
    (state) => state?.salesQuotation
  );

  useEffect(() => {
    reset();
    setSelectedOrder({});
  }, [type]);

  const id = useParams().id;
  useEffect(() => {
    if (id) {
      (async () => {
        await crud.getSingle('salesorders', id, (err, res) => {
          if (res?.status === 200) {
            setEditingRowData(res.data);
            dispatch(updateQuotationSuccess(res.data));
            Object.entries({
              ...res.data,
            }).forEach(([key, value]) => {
              setValue(key, value);
            });
            res.data.contractId &&
              crud.getSingle('contracts', res.data.contractId, (err, res) => {
                if (res?.status === 200) {
                  setSelectedContract(res.data);
                }
              });
          } else {
            // Handle error response here
          }
        });
      })();
    }
  }, [id]);

  useEffect(() => {
    if (selectedOrder?.salesQuotationId) {
      setValue('deliveryTerms', selectedOrder?.deliveryTerms);
      setValue('narration', selectedOrder?.narration);
      setValue('paymentTerms', selectedOrder?.paymentTerms);
      setValue('remarks', selectedOrder?.remarks);
    }
  }, [selectedOrder]);
  const amount = watch('amount');
  const vat = watch('vat');

  useEffect(() => {
    const amountValue = parseFloat(amount) || 0;
    const vatPercentage = parseFloat(vat) || 0;
    const vatAmount = (amountValue * vatPercentage) / 100;
    const totalAmount = amountValue + vatAmount;

    // Set both VAT amount and total amount with 2 decimal places
    setValue('vatAmount', vatAmount.toFixed(2));
    setValue('totalAmount', totalAmount.toFixed(2));
  }, [amount, vat, setValue]);

  const submitSalesOrder = async (values) => {
    const combinedValues = {
      ...values,
      //TODO: Need to Do Dynamic in future
      salesOrderTypeId: 1,
      orderDate: values?.orderDate
        ? dayjs(values?.orderDate).format('YYYY-MM-DD')
        : null,
    };
    await crud.update('salesorders', id, combinedValues, (_err, res) => {
      if (res?.status === 200) {
        toast.success('Updated Sales Order Successfully');
        dispatch(updateQuotationSuccess(res.data));
        setGetData(true);
        reset();
        setSelectedOrder({});
      } else {
      }
    });
  };

  const handleReset = () => {
    reset();
    setSelectedOrder({});
  };

  return (
    <>
      <Box className='flex flex-col gap-7'>
        <Box>
          <Grid container spacing={2}>
            <Grid item xs={12} md={9}>
              <GlassCard className='p-5'>
                <form onSubmit={handleSubmit(submitSalesOrder)}>
                  <Grid container spacing={2}>
                    <Grid item md={GridSize}>
                      <FmTextField
                        name='salesOrderName'
                        label='Name'
                        control={control}
                        required
                        disabled={salesQuotation?.salesQuotationId !== null}
                      />
                    </Grid>

                    <Grid item md={GridSize}>
                      <FmDatePicker
                        name='orderDate'
                        label='Order Date'
                        control={control}
                        readOnly={salesQuotation?.salesQuotationId !== null}
                      />
                    </Grid>

                    <Grid item md={GridSize * 2}>
                      <FmSearchableSelect
                        name='salesQuotationId'
                        label='Sales Quotation'
                        apiUrl={'salesquotations'}
                        valueField='salesQuotationId'
                        onChangeProp={(value) => setSelectedOrder(value)}
                        labelField={[
                          'salesQuotationCode',
                          'projectName',
                          'contractName',
                        ]}
                        headerField={['Contract', 'Project', 'Sales Quotation']}
                        showField={['contractName', 'salesQuotationCode']}
                        control={control}
                        defaultValue={{
                          salesQuotationId: editingRowData?.salesQuotationId,
                          salesQuotationName:
                            editingRowData?.salesQuotationCode,
                          salesQuotationCode:
                            editingRowData?.salesQuotationCode,
                        }}
                        stackedFields={{
                          salesQuotationCode: [
                            { Code: 'salesQuotationCode' },
                            { Name: 'salesQuotationName' },
                          ],

                          projectName: [
                            { Code: 'projectCode' },
                            { Name: 'projectName' },
                          ],
                          contractName: [
                            { Code: 'contractCode' },
                            { Name: 'contractName' },
                          ],
                        }}
                      />
                    </Grid>
                    <Grid item md={GridSize}>
                      <FmSearchableSelect
                        name='contractId'
                        control={control}
                        apiUrl='contracts'
                        defaultValue={{
                          contractId: editingRowData?.contractId,
                          contractName: editingRowData?.contractName,
                          contractCode: editingRowData?.contractCode,
                        }}
                        valueField='contractId'
                        headerField={['Contract', 'Project']}
                        labelField={['contractCode', 'projectName']}
                        showField={['contractCode', 'projectName']}
                        label='Contract'
                        stackedFields={{
                          contractCode: [
                            { Code: 'contractCode' },
                            { Name: 'contractName' },
                          ],
                          projectName: [
                            { Code: 'projectCode' },
                            { Name: 'projectName' },
                          ],
                        }}
                        onChangeProp={(value) => setSelectedContract(value)}
                        required
                      />
                    </Grid>
                    <Grid item md={GridSize}>
                      <FmSearchableSelect
                        name='currencyId'
                        control={control}
                        apiUrl='currencies'
                        valueField='currencyId'
                        headerField={['Name']}
                        labelField={['currencyName']}
                        showField={['currencyName']}
                        defaultValue={{
                          currencyId: editingRowData?.currencyId,
                          currencyName: editingRowData?.currencyName,
                          currencyCode: editingRowData?.currencyCode,
                        }}
                        label={'Currency'}
                      />
                    </Grid>
                    <Grid item md={GridSize}>
                      <FmSearchableSelect
                        name='paymentTermId'
                        control={control}
                        apiUrl='paymentterms'
                        valueField='paymentTermId'
                        headerField={['Payment Term']}
                        labelField={['paymentTermName']}
                        showField={['paymentTermName']}
                        defaultValue={{
                          paymentTermId: editingRowData?.paymentTermId,
                          paymentTermName: editingRowData?.paymentTermName,
                          paymentTermCode: editingRowData?.paymentTermCode,
                        }}
                        label='Payment Term'
                      />
                    </Grid>

                    <Grid item md={GridSize}>
                      <FmTextField
                        name='paymentTerms'
                        label='Payment Terms'
                        control={control}
                        disabled={salesQuotation?.salesQuotationId !== null}
                      />
                    </Grid>
                    <Grid item md={GridSize}>
                      <FmTextField
                        name='narration'
                        label='Narration'
                        control={control}
                        disabled={salesQuotation?.salesQuotationId !== null}
                      />
                    </Grid>
                    <Grid item md={GridSize}>
                      <FmTextField
                        name='deliveryTerms'
                        label='Delivery Terms'
                        control={control}
                        disabled={salesQuotation?.salesQuotationId !== null}
                      />
                    </Grid>
                    <Grid item md={GridSize}>
                      {/* <FmTextField
                        name='amount'
                        label='Amount'
                        control={control}
                        type='number'
                        onChange={(e) => {
                          setValue('amount', e.target.value);
                          const amountValue = parseFloat(e.target.value) || 0;
                          const vatPercentage =
                            parseFloat(getValues('vat')) || 0;
                          const vatAmount = (amountValue * vatPercentage) / 100;
                          setValue('vatAmount', vatAmount.toFixed(2));
                          setValue(
                            'totalAmount',
                            (amountValue + vatAmount).toFixed(2)
                          );
                        }}
                      /> */}
                      <FmCurrencyTextField
                        name='amount'
                        label='Amount'
                        control={control}
                      />
                    </Grid>
                    <Grid item md={GridSize}>
                      <FmTextField
                        name='vat'
                        label='VAT (%)'
                        control={control}
                        type='Number'
                        onChange={(e) => {
                          setValue('vat', e.target.value);
                          const vatPercentage = parseFloat(e.target.value) || 0;
                          const amountValue =
                            parseFloat(AmountFieldValue ?? '0') || 0;
                          const vatAmount = (amountValue * vatPercentage) / 100;
                          setValue('vatAmount', vatAmount.toFixed(2));
                          setValue(
                            'totalAmount',
                            (amountValue + vatAmount).toFixed(2)
                          );
                        }}
                      />
                    </Grid>
                    <Grid item md={GridSize}>
                      <Typography
                        variant='caption'
                        color='text.secondary'
                        sx={{
                          display: 'block',
                          mb: 0.25,
                          fontWeight: 'bold',
                          mt: 1,
                        }}
                      >
                        {'Total Amount: ' + (getValues('totalAmount') || 0)}
                      </Typography>
                    </Grid>
                    <Grid item md={GridSize * 2}>
                      <FmTextField
                        name='remarks'
                        multiline
                        rows={4}
                        label='Remarks'
                        control={control}
                        disabled={salesQuotation?.salesQuotationId !== null}
                      />
                    </Grid>

                    <Grid item md={12}>
                      <ActionButtons
                        onSubmit={handleSubmit(submitSalesOrder)}
                        onReset={handleReset}
                        submitLoading={false}
                        cancelLoading={false}
                        onCancel={() =>
                          navigate('/financial/accounts-receivable/sales-order')
                        }
                        cancelText='Go Back'
                        submitText='Update'
                        removeSpacing
                      />
                    </Grid>
                  </Grid>
                </form>
              </GlassCard>
            </Grid>
            {/* Right side - Contract Details */}
            <Grid item xs={12} md={3}>
              <Box className='relative h-full w-full'>
                <ViewContractDetails selectedContract={selectedContract} />
              </Box>
            </Grid>
          </Grid>
        </Box>

        <InternalBudget />
      </Box>
    </>
  );
};

export default EditSalesOrder;
