import React from 'react';
import FmTextField from '../../../../components/_mui/FmTextField.tsx';
import FmAutoComplete from '../../../../components/_mui/FmAutoComplete.tsx';
import { useForm } from 'react-hook-form';
import { Grid, Button } from '@mui/material';
import GlassCard from '../../../../components/small/GlassCard.tsx';
import FmSearchableSelect from '../../../../components/_mui/FmSearchableSelect.tsx';
import ActionButtons from '../../../../components/_form/ActionButtons.tsx';
import Crud_Service from '../../../../apis/CrudService.jsx';
import { toast } from 'react-toastify';
import useLoader from '../../../../components/helpers/UseLoader.tsx';
import { CampValidationSchema } from '../../../../utils/CommonvalidationSchemas.tsx';
import { yupResolver } from '@hookform/resolvers/yup';
import DashboardLayout from '../../../../components/DashboardLayout.tsx';
import { navigate } from 'react-big-calendar/lib/utils/constants.js';
import { useNavigate } from 'react-router-dom';

const AddAccomCamps = (props) => {
  const { handleSubmit, control, reset } = useForm({
    mode: 'onChange',
    resolver: yupResolver(CampValidationSchema),
  });
  const { startCreateLoading, stopCreateLoading, createLoading } = useLoader();
  const crud = new Crud_Service();
  const navigate = useNavigate();

  const handleSubmitCamp = async (values) => {
    startCreateLoading();
    const combinedData = {
      ...values,
      // TODO
      employeeRoomStatusId: 1,
    };
    await crud.create('camps', combinedData, (err, res) => {
      stopCreateLoading();
      if (err) {
        return;
      }
      if (res?.status === 201) {
        toast.success('Camp Created successfully');
        navigate(-1);
        reset();
      } else {
      }
    });
  };

  return (
    <DashboardLayout title='Add Camp'>
      <GlassCard className='p-3'>
        <form onSubmit={handleSubmit(handleSubmitCamp)}>
          <Grid container spacing={2}>
            <Grid item md={2.4}>
              <FmTextField
                label='Camp Name'
                name='campName'
                control={control}
              />
            </Grid>

            <Grid item md={2.4}>
              <FmSearchableSelect
                name='accommodationId'
                control={control}
                apiUrl='accommodations'
                valueField='accommodationId'
                headerField={['Accommodation Name']}
                labelField={['accommodationName']}
                showField={['accommodationName']}
                label='Accommodation'
                required
              />
            </Grid>

            <Grid item md={2.4}>
              <FmSearchableSelect
                name='employeeId'
                control={control}
                apiUrl='employees'
                valueField='employeeId'
                headerField={['Code', 'Name', 'Email']}
                labelField={['employeeCode', 'fullName', 'emailId']}
                showField={['employeeCode', 'fullName', 'emailId']}
                label='Employees'
              />
            </Grid>

            <Grid item md={2.4}>
              <FmSearchableSelect
                name='blockId'
                control={control}
                apiUrl='blocks'
                valueField='blockId'
                headerField={['Block Name']}
                labelField={['blockName']}
                showField={['blockName']}
                label='Block'
              />
            </Grid>

            <Grid item md={2.4}>
              <FmSearchableSelect
                name='roomId'
                control={control}
                apiUrl='rooms'
                valueField='roomId'
                headerField={['Room No']}
                labelField={['roomNo']}
                showField={['roomNo']}
                label='Room No'
              />
            </Grid>

            <Grid item md={2.4}>
              <FmTextField label='Occupied' name='occupied' control={control} />
            </Grid>
            {/* TODO */}
            {/* <Grid item md={2.4}>
            <FmSearchableSelect
              name='employeeRoomStatusId'
              control={control}
              apiUrl='rooms'
              valueField='employeeRoomStatusId'
              headerField={['Room Name']}
              labelField={['roomName']}
              showField={['roomName']}
              
              label='Employee Room Status'
              required
            />
          </Grid> */}

            <Grid item md={4}>
              <FmTextField
                label='Remarks'
                name='remarks'
                control={control}
                multiline={true}
                rows={2}
                maxRows={3}
              />
            </Grid>
          </Grid>
          <ActionButtons
            onSubmit={handleSubmit(handleSubmitCamp)}
            onReset={reset}
            submitText='Create'
            onCancel={() => navigate(-1)}
            cancelText='Cancel'
            submitLoading={createLoading}
          />
        </form>
      </GlassCard>
    </DashboardLayout>
  );
};

export default AddAccomCamps;
