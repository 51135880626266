import React from 'react';
import { Box, Button } from '@mui/material';

const EnquiryButtonShape = ({ name, icon, count, active, onClick }) => {
  return (
    <Box
      className={`flex gap-1 items-center relative`}
      sx={{
        bgcolor: active ? 'secondary.main' : 'none',
        padding: '2px 6px',
        borderRadius: '5px',
        fontWeight: active ? 600 : 500,
        color: active ? '#fff' : 'text.main',
        opacity: active ? 1 : 0.7,
      //  border: '1px solid',
        //borderColor: active ? 'primary.main' : 'border.main',
  
       
        '&:hover': {
          bgcolor: active ? 'secondary.main' : 'background.light',
          opacity: 1,
        },
      }}
      component={Button}
      type='button'
      onClick={onClick}
    >
      <Box className='text-xl'>{icon}</Box>
      <Box>{name}</Box>
      <Box className='count'>({count})</Box>
    </Box>
  );
};

export default EnquiryButtonShape;
