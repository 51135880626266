import React, { useEffect } from 'react';
import DashboardLayout from './../../../components/DashboardLayout.tsx';
import ListSnagReport from './ListSnagReport.tsx';
import Nav from '../../../utils/Nav.tsx';
import { useNavigate } from 'react-router-dom';
import { CreateButton } from '../../../components/small/Buttons.jsx';
import { Box } from '@mui/material';

const SnagReport = (props) => {
  const { module } = props;
  useEffect(() => {
    document.title = `Snag Report | ${module === 1 ? 'FM Division' : 'CAFM'}`;
  }, []);
  const navigate = useNavigate();

  return (
    <DashboardLayout
      title='Snag Report'
      hasSubmenu
      menu={
        module === 1 ? Nav[13].child[3].children : Nav[12].child[1].children
      }
      actionButtons={
        <>
          <Box className='flex flex-row items-center whitespace-nowrap gap-3'>
            <CreateButton name='Create' onClick={() => navigate('create')} />
          </Box>
        </>
      }
    >
      <ListSnagReport />
    </DashboardLayout>
  );
};

export default SnagReport;
