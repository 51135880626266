import { yupResolver } from '@hookform/resolvers/yup';
import {
  Box,
  Button,
  Divider,
  FormControlLabel,
  Radio,
  RadioGroup,
  Stack,
} from '@mui/material';
import React, { useEffect, useState } from 'react';
import { useForm } from 'react-hook-form';
import { useNavigate, useParams } from 'react-router-dom';
import { toast } from 'react-toastify';
import apiInstance from '../../../../../apis/ApiService.jsx';
import Crud_Service from '../../../../../apis/CrudService.jsx';
import TableActionButtons from '../../../../../components/_form/TableActionButtons.jsx';
import FmFileInput from '../../../../../components/_mui/FmFileInput.tsx';
import FmSearchableSelect from '../../../../../components/_mui/FmSearchableSelect.tsx';
import FmTextField from '../../../../../components/_mui/FmTextField.tsx';
import MuiDialogOne from '../../../../../components/_mui/MuiDialogOne.jsx';
import DashboardLayout from '../../../../../components/DashboardLayout.tsx';
import FmMRTDataTable from '../../../../../components/FmMRTDataTable.tsx';
import useTableLogic from '../../../../../components/helpers/MRTUseTableLogic.tsx';
import useLoader from '../../../../../components/helpers/UseLoader.tsx';
import { CreateButton } from '../../../../../components/small/Buttons.jsx';
import GlassCard from '../../../../../components/small/GlassCard.tsx';
import { PurchaseVoucherItemSchema } from '../../../../../utils/CommonvalidationSchemas.tsx';
import Icons from '../../../../../utils/Icon.jsx';
import Nav from '../../../../../utils/Nav.tsx';
import AddPVItems from './AddPVItems.tsx';
import EditPV from './EditPV.tsx';

const BillingValues = [
  {
    name: 'Quantity',
    value: 1,
  },

  {
    name: 'Gross',
    value: '-',
  },

  {
    name: 'Discount',
    value: 0,
  },

  {
    name: 'Discount %',
    value: 0,
  },

  {
    name: 'VAT',
    value: 5,
  },

  {
    name: 'Taxable',
    value: 1000,
  },

  {
    name: 'VAT Advance',
    value: 0,
  },

  {
    name: 'Net',
    value: 1050,
  },
];
const EditPurchaseVoucherList = (props) => {
  const { type } = props;
  const { control, setValue, handleSubmit } = useForm({
    resolver: yupResolver(PurchaseVoucherItemSchema),
    mode: 'onChange',
  });
  const id = useParams().id;

  const {
    rows,
    pagination,
    sorting,
    fetchData,
    columnFilters,
    globalFilter,
    tableRecordCounts,
    setPagination,
    setSorting,
    setColumnFilters,
    setGlobalFilter,
    setSearchKeyword,
    searchKeyword,
  } = useTableLogic(`purchasevoucherItem?purchaseVoucherId=${id}`);
  const { startCreateLoading, stopCreateLoading, createLoading } = useLoader();
  const [openUpload, setOpenUpload] = useState(false);
  const [filesData, setFilesData] = useState(null);

  useEffect(() => {
    const len = rows?.length;
    const gross = rows?.reduce((total, row) => total + row?.gross, 0);
    const discount = rows?.reduce((total, row) => total + row?.discount, 0);
    const discountPercent = rows?.reduce(
      (total, row) => total + row?.discountPercentage,
      0
    );
    const net = rows?.reduce((total, row) => total + row?.net, 0);
    BillingValues[0].value = len;
    BillingValues[1].value = gross;
    BillingValues[2].value = discount;
    BillingValues[3].value = discountPercent;
    BillingValues[7].value = net;
  }, [rows]);
  const [editingRowId, setEditingRowId] = useState(null);
  const [editingRowData, setEditingRowData] = useState(null);
  const crud = new Crud_Service();

  const [loader, setLoader] = useState(false);
  const [, setViewdetails] = useState(false);
  const handleEdit = async (row) => {
    setEditingRowId(row?.purchaseVoucherItemId);
    setEditingRowData({ ...row });

    Object.entries({
      ...row,
    }).forEach(([key, value]) => {
      setValue(key, value);
    });
  };
  const handleEditItem = async (values) => {
    const data = {
      ...values,
      status: 2,
      vendorId: values.supplierId,
    };

    await crud.update(
      'purchasevoucheritem',
      editingRowData?.purchaseVoucherItemId,
      data,
      (_err, res) => {
        if (res?.status === 200) {
          toast.success('Purchase Voucher Item Updated Successfully');
          fetchData();
          setEditingRowData(null);
          setEditingRowId(null);
        }
      }
    );
  };
  const handleDeleteVoucher = async (view) => {
    await crud.remove(
      'purchasevoucheritem',
      view?.purchaseVoucherItemId,
      (_err, res) => {
        if (res?.status === 204) {
          fetchData();
          toast.success('Purchase Voucher Item Deleted Successfully');
        } else {
        }
      }
    );
  };

  const handleCancel = () => {
    setEditingRowId(null);
    setEditingRowData(null);
  };

  const ActionData = [
    // TODO
    // {
    //   name: 'View',
    //   icon: Icons.view,
    //   onClick: (_props) => {
    //     setViewdetails(_props.purchaseVoucherItemId);
    //   },
    // },
    {
      name: 'Edit',
      icon: Icons.edit,
      onClick: (props) => {
        handleEdit(props);
      },
    },
    {
      name: 'Delete',
      icon: Icons.delete,
      onClick: (props) => handleDeleteVoucher(props),
      danger: true,
    },
  ];
  const navigate = useNavigate();

  const handleUploadSheet = (event) => {
    startCreateLoading();
    const file = event.target.files[0];
    if (file) {
      const fileFormData = new FormData();
      fileFormData.append('file', file);
      fileFormData.append('fileTypeId', 60);

      crud.create('files/upload', fileFormData, (_err, res) => {
        if (res?.status === 200) {
          setFilesData(res?.data);
          crud.create(
            `purchasevoucheritem/${id}/process/${res.data?.fileId}`,
            '',
            (_err, res) => {
              if (res?.status === 200) {
                stopCreateLoading();
                toast.success('Purchase Voucher Items Uploaded Successfully');
                fetchData();
                setOpenUpload(false);
              } else {
                stopCreateLoading();
              }
            }
          );
        } else {
          setOpenUpload(false);
          stopCreateLoading();
        }
      });
    }
  };
  const handleExport = async () => {
    const downloadUrl = await apiInstance.getFiles(
      `purchasevoucheritem/${id}/export-excel`
    );
    const link = document.createElement('a');
    link.href = downloadUrl;
    link.setAttribute('download', 'Purchase Voucher Items.xlsx');
    document.body.appendChild(link);
    link.click();
    document.body.removeChild(link);
  };
  const handleClose = () => {
    setOpenUpload(false);
    setFilesData(null);
  };

  const columns = [
    {
      header: 'Actions',
      Cell: ({ row }) => (
        <Box className='flex gap-2'>
          {editingRowId === row?.original?.purchaseVoucherItemId ? (
            <>
              <Button onClick={handleSubmit(handleEditItem)}>Save</Button>
              <Button onClick={handleCancel}>Cancel</Button>
            </>
          ) : (
            <TableActionButtons
              Actions={ActionData?.map((action) => ({
                ...action,
                onClick: () => action.onClick(row?.original),
              }))}
            />
          )}
        </Box>
      ),
    },
    {
      accessorKey: 'purchaseVoucherItemName',
      header: 'Item Name',
      Cell: ({ row }) => {
        const isEditing = editingRowId === row?.original?.purchaseVoucherItemId;
        return isEditing ? (
          <FmTextField name='purchaseVoucherItemName' control={control} />
        ) : (
          row?.original?.purchaseVoucherItemName || ''
        );
      },
    },
    {
      accessorKey: 'projectId',
      header: 'Project',
      enableEditing: true,
      Cell: ({ row }) => {
        const isEditing = editingRowId === row?.original?.purchaseVoucherItemId;
        return isEditing ? (
          <FmSearchableSelect
            name='projectId'
            control={control}
            apiUrl='projects'
            valueField='projectId'
            label='Project'
            labelField={['projectName']}
            showField={['projectName']}
            required
            defaultValue={{
              projectId: row?.original?.projectId,
              projectName: row?.original?.projectName,
            }}
          />
        ) : (
          row?.original?.projectName || ''
        );
      },
    },
    {
      accessorKey: 'contractId',
      header: 'Contract',
      enableEditing: true,
      Cell: ({ row }) => {
        const isEditing = editingRowId === row?.original?.purchaseVoucherItemId;
        return isEditing ? (
          <FmSearchableSelect
            name='contractId'
            control={control}
            apiUrl='contracts'
            label='Contract'
            labelField={['contractName', 'contractCode']}
            showField={['contractName', 'contractCode']}
            valueField='contractId'
            required
            defaultValue={{
              contractId: row?.original?.contractId,
              contractName: row?.original?.contractName,
            }}
          />
        ) : (
          row?.original?.contractName || ''
        );
      },
    },
    {
      accessorKey: 'workOrderId',
      header: 'Work Order',
      enableEditing: true,
      Cell: ({ row }) => {
        const isEditing = editingRowId === row?.original?.purchaseVoucherItemId;
        return isEditing ? (
          <FmSearchableSelect
            name='workOrderId'
            control={control}
            apiUrl='workorders'
            label='Work Order'
            labelField={['workOrderName', 'workOrderCode']}
            showField={['workOrderName', 'workOrderCode']}
            valueField='workOrderId'
            required
            defaultValue={{
              workOrderId: row?.original?.workOrderId,
              workOrderName: row?.original?.workOrderName,
            }}
          />
        ) : (
          row?.original?.workOrderName || ''
        );
      },
    },

    {
      accessorKey: 'costCenterId',
      header: 'Cost Center',
      enableEditing: true,
      Cell: ({ row }) => {
        const isEditing = editingRowId === row?.original?.purchaseVoucherItemId;
        return isEditing ? (
          <FmSearchableSelect
            name='costCenterId'
            control={control}
            apiUrl='costcenters'
            label='Cost Center'
            labelField={['costCenterName']}
            valueField='costCenterId'
            showField={['costCenterName']}
            required
            defaultValue={{
              costCenterId: row?.original?.costCenterId,
              costCenterName: row?.original?.costCenterName,
            }}
          />
        ) : (
          row?.original?.costCenterName || ''
        );
      },
    },
    {
      accessorKey: 'taxCodeId',
      header: 'Tax Code',
      enableEditing: true,
      Cell: ({ row }) => {
        const isEditing = editingRowId === row?.original?.purchaseVoucherItemId;
        return isEditing ? (
          <FmSearchableSelect
            name='taxCodeId'
            control={control}
            label='Tax Code'
            apiUrl='taxcodes'
            valueField='taxCodeId'
            labelField={['taxCodeName']}
            showField={['taxCodeName']}
            required
            defaultValue={{
              taxCodeId: row?.original?.taxCodeId,
              taxCodeName: row?.original?.taxCodeName,
            }}
          />
        ) : (
          row?.original?.taxCodeName || ''
        );
      },
    },
    {
      accessorKey: 'purchaseAccount',
      header: 'Purchase Account',
      enableEditing: true,
      Cell: ({ row }) => {
        const isEditing = editingRowId === row?.original?.purchaseVoucherItemId;
        return isEditing ? (
          <FmTextField
            name='purchaseAccount'
            control={control}
            label='Purchase Account'
            required
          />
        ) : (
          row?.original?.purchaseAccount || ''
        );
      },
    },
    {
      accessorKey: 'quantity',
      header: 'Quantity',
      Cell: ({ row }) => {
        const isEditing = editingRowId === row?.original?.purchaseVoucherItemId;
        return isEditing ? (
          <FmTextField name='quantity' type='number' control={control} />
        ) : (
          row?.original?.quantity || ''
        );
      },
    },
    {
      accessorKey: 'rate',
      header: 'Rate',
      Cell: ({ row }) => {
        const isEditing = editingRowId === row?.original?.purchaseVoucherItemId;
        return isEditing ? (
          <FmTextField name='rate' type='number' control={control} />
        ) : (
          row?.original?.rate || ''
        );
      },
    },
    {
      accessorKey: 'gross',
      header: 'Gross',
      Cell: ({ row }) => {
        const isEditing = editingRowId === row?.original?.purchaseVoucherItemId;
        return isEditing ? (
          <FmTextField name='gross' control={control} />
        ) : (
          row?.original?.gross || ''
        );
      },
    },

    {
      accessorKey: 'discount',
      header: 'Discount',
      Cell: ({ row }) => {
        const isEditing = editingRowId === row?.original?.purchaseVoucherItemId;
        return isEditing ? (
          <FmTextField name='discount' control={control} />
        ) : (
          row?.original?.discount || ''
        );
      },
    },
    {
      accessorKey: 'discountPercentage',
      header: 'Discount Percentage',
      Cell: ({ row }) => {
        const isEditing = editingRowId === row?.original?.purchaseVoucherItemId;
        return isEditing ? (
          <FmTextField name='discountPercentage' control={control} />
        ) : (
          row?.original?.discountPercentage || ''
        );
      },
    },
    {
      accessorKey: 'isTaxable',
      header: 'Is Taxable',
      Cell: ({ row }) => {
        // Track taxable state for each row being edited
        const [taxable, setTaxable] = useState(row?.original?.isTaxable);

        const isEditing = editingRowId === row?.original?.purchaseVoucherItemId;

        // Handle change when a user selects a radio button
        const handleTaxableChange = (e) => {
          const newTaxable = e.target.value === 'true';
          setTaxable(newTaxable); // Update the local state of taxable
          row.original.isTaxable = newTaxable; // Update the actual row data
        };

        return isEditing ? (
          <RadioGroup
            sx={{ flexDirection: 'row' }}
            name='isTaxable'
            onChange={handleTaxableChange}
            value={taxable}
          >
            <FormControlLabel value={true} control={<Radio />} label='Yes' />
            <FormControlLabel value={false} control={<Radio />} label='No' />
          </RadioGroup>
        ) : taxable === true ? (
          'Yes'
        ) : (
          'No'
        );
      },
    },
    {
      accessorKey: 'make',
      header: 'Make',
      enableEditing: true,
      Cell: ({ row }) => {
        const isEditing = editingRowId === row?.original?.purchaseVoucherItemId;
        return isEditing ? (
          <FmTextField name='make' control={control} label='Make' required />
        ) : (
          row?.original?.make || ''
        );
      },
    },
    {
      accessorKey: 'model',
      header: 'Modal',
      enableEditing: true,
      Cell: ({ row }) => {
        const isEditing = editingRowId === row?.original?.purchaseVoucherItemId;
        return isEditing ? (
          <FmTextField name='model' control={control} label='Modal' required />
        ) : (
          row?.original?.model || ''
        );
      },
    },

    {
      accessorKey: 'technicalSpecs',
      header: 'Technical Specs',
      enableEditing: true,
      Cell: ({ row }) => {
        const isEditing = editingRowId === row?.original?.purchaseVoucherItemId;
        return isEditing ? (
          <FmTextField
            name='technicalSpecs'
            control={control}
            label='Technical Specs'
            required
          />
        ) : (
          row?.original?.technicalSpecs || ''
        );
      },
    },
    {
      accessorKey: 'areaOfUsage',
      header: 'Area Of Usage',
      Cell: ({ row }) => {
        const isEditing = editingRowId === row?.original?.purchaseVoucherItemId;
        return isEditing ? (
          <FmTextField name='areaOfUsage' control={control} />
        ) : (
          row?.original?.areaOfUsage || ''
        );
      },
    },
    {
      accessorKey: 'remarks',
      header: 'Remarks',
      enableEditing: true,
      Cell: ({ row }) => {
        const isEditing = editingRowId === row?.original?.purchaseVoucherItemId;
        return isEditing ? (
          <FmTextField
            name='remarks'
            control={control}
            label='Remarks'
            multiline
            required
          />
        ) : (
          row?.original?.remarks || ''
        );
      },
    },

    {
      accessorKey: 'description',
      header: 'Description',
      enableEditing: true,
      Cell: ({ row }) => {
        const isEditing = editingRowId === row?.original?.purchaseVoucherItemId;
        return isEditing ? (
          <FmTextField
            name='description'
            control={control}
            label='Description'
            required
          />
        ) : (
          row?.original?.description || ''
        );
      },
    },
  ];

  return (
    <>
      <DashboardLayout
        title={'Purchase Voucher Items'}
        hasSubmenu
        menu={Nav[7].child[2].children}
        parentMenu={Nav[7].child[2].name}
        actionButtons={
          <>
            <Box className='flex flex-row items-center whitespace-nowrap gap-3'>
              <CreateButton
                name='Create'
                onClick={() =>
                  navigate(
                    `/financial/accounts-payable/${
                      type === 1
                        ? 'purchase-vouchers'
                        : type === 3
                        ? 'purchase-return-vouchers'
                        : 'direct-purchase-order'
                    }/create`
                  )
                }
              />
            </Box>
          </>
        }
      >
        <EditPV type={type} id={id} />
        <AddPVItems fetchData={fetchData} setLoader={setLoader} id={id} />
        <FmMRTDataTable
          columns={columns}
          rows={rows || []}
          isLoading={loader}
          rowCount={tableRecordCounts}
          editingRow={editingRowId}
          pagination={pagination}
          sorting={sorting}
          columnFilters={columnFilters}
          globalFilter={globalFilter}
          setPagination={setPagination}
          setSorting={setSorting}
          setColumnFilters={setColumnFilters}
          setGlobalFilter={setGlobalFilter}
          setSearchKeyword={setSearchKeyword}
          searchKeyword={searchKeyword}
          fetchData={fetchData}
          handleImport={() => setOpenUpload(true)}
          handleExport={handleExport}
        />
        <MuiDialogOne
          title='Upload Sheet'
          open={openUpload}
          onClose={handleClose}
        >
          <FmFileInput
            onChange={handleUploadSheet}
            documentName={filesData?.originalFileName}
            loading={createLoading}
          />
        </MuiDialogOne>
        <GlassCard className='p-3'>
          <Stack
            direction='row'
            flexWrap='wrap'
            spacing={3}
            justifyContent='space-between'
            divider={<Divider orientation='vertical' flexItem />}
          >
            {BillingValues?.map((item, i) => {
              return (
                <Box key={i} className='flex justify-between items-center'>
                  <Box>{item.name}</Box>
                  <Box
                    className='font-bold ms-3'
                    sx={{ color: 'success.main' }}
                  >
                    {item.value}
                  </Box>
                </Box>
              );
            })}
          </Stack>
        </GlassCard>
      </DashboardLayout>
    </>
  );
};

export default EditPurchaseVoucherList;
