import React, { useEffect, useRef, useState } from 'react';

declare const Stimulsoft: any;

const ReportDesigner: React.FC = () => {
  const designerRef = useRef<any>(null);
  const [isStimulsoftLoaded, setIsStimulsoftLoaded] = useState(false);

  useEffect(() => {
    const checkStimulsoftLoaded = () => {
      if (typeof Stimulsoft !== 'undefined') {
        setIsStimulsoftLoaded(true);
      } else {
        // Check again in 100ms
        setTimeout(checkStimulsoftLoaded, 100);
      }
    };

    checkStimulsoftLoaded();
  }, []);

  useEffect(() => {
    if (!isStimulsoftLoaded) return;

    try {
      console.log('Loading Designer view');

      // Set full screen mode for the designer
      const options = new Stimulsoft.Designer.StiDesignerOptions();
      options.appearance.fullScreenMode = false;

      // Create the report designer with specified options
      const designer = new Stimulsoft.Designer.StiDesigner(
        options,
        'StiDesigner',
        false
      );

      // Edit report template in the designer
      designer.report = new Stimulsoft.Report.StiReport();

      // Load report from url
      designer.report.loadFile('/reports/SimpleList.mrt');

      // Rendering the designer to selected element
      designer.renderHtml('designer');

      console.log('Loading completed successfully!');

      // Store designer instance in ref for cleanup
      designerRef.current = designer;
    } catch (error) {
      console.error('Error initializing Stimulsoft Designer:', error);
    }

    return () => {
      if (designerRef.current) {
        // Add any necessary cleanup code here
        designerRef.current = null;
      }
    };
  }, [isStimulsoftLoaded]);

  if (!isStimulsoftLoaded) {
    return <div>Loading Stimulsoft Designer...</div>;
  }

  return <div id='designer' style={{ width: '100%', height: '100vh' }} />;
};

export default ReportDesigner;
