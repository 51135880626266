import React, { useEffect, useState } from 'react';
import { Grid } from '@mui/material';
import { AdapterDayjs } from '@mui/x-date-pickers/AdapterDayjs';
import { LocalizationProvider } from '@mui/x-date-pickers/LocalizationProvider';
import { ActionButtons } from '../../../components/_form';
import { useNavigate } from 'react-router-dom';
import Crud_Service from '../../../apis/CrudService';
import { useForm } from 'react-hook-form';
import { toast } from 'react-toastify';
import dayjs from 'dayjs';
import DashboardLayout from '../../../components/DashboardLayout.tsx';
import useLoader from '../../../components/helpers/UseLoader.tsx';
import FmTextField from '../../../components/_mui/FmTextField.tsx';
import FmSearchableSelect from '../../../components/_mui/FmSearchableSelect.tsx';
import GlassCard from '../../../components/small/GlassCard.tsx';
import { yupResolver } from '@hookform/resolvers/yup';
import { EstimationMEPSchema } from '../../../utils/CommonvalidationSchemas.tsx';
import FmDatePicker from '../../../components/_mui/FmDatePicker.tsx';
import useCommonFetchApi from '../../../components/helpers/useCommonFetchApi.tsx';

const GridValue = 3;

const CreateEstimationSheetForAMC = () => {
  const navigate = useNavigate();

  const { handleSubmit, control, reset, setValue, watch } = useForm({
    resolver: yupResolver(EstimationMEPSchema),
    mode: 'onChange',
  });
  const { createLoading, startCreateLoading, stopCreateLoading } = useLoader();
  const crud = new Crud_Service();
  const [selectedTender, setSelectedTender] = useState(null);
  const [selectedProject, setSelectedProject] = useState(null);
  const { data: tenders } = useCommonFetchApi('tenders', {
    sortDirection: 'asc',
    sortBy: 'tenderName',
  });

  const handleSubmitAMC = async (values) => {
    startCreateLoading();
    const combinedData = {
      ...values,
      dateOfEnquiry: dayjs(values?.dateOfEnquiry).format('YYYY-MM-DD'),
      contractPeriod: Number(values?.contractPeriod),
      otherPercent: Number(values?.otherPercent),
      discountOverAllPercent: Number(values?.discountOverAllPercent),
    };

    await crud.create('estimationamcsheets', combinedData, (err, res) => {
      if (err) {
        stopCreateLoading();
        toast.error('Something went wrong:', err);
        return;
      }
      if (res?.status === 201) {
        toast.success('Estimation Sheet AMC Created Successfully');
        navigate(-1);
        stopCreateLoading();
      } else {
        stopCreateLoading();
      }
    });
  };

  useEffect(() => {
    setValue('clientId', selectedTender?.clientId);
  }, [selectedTender]);


  return (
    <>
      <DashboardLayout title='Create New Estimation Sheet AMC'>
        <GlassCard className='p-5'>
          <form onSubmit={handleSubmit(handleSubmitAMC)}>
            <LocalizationProvider dateAdapter={AdapterDayjs}>
              <Grid container spacing={2}>
                <Grid item md={GridValue}>
                  <FmSearchableSelect
                    name='tenderId'
                    control={control}
                    apiUrl='tenders'
                    valueField='tenderId'
                    onChangeProp={setSelectedTender}
                    labelField={['tenderCode', 'tenderName']}
                    showField={['tenderName']}
                    label='Tender'
                    required
                  />
                </Grid>
                <Grid item md={GridValue}>
                  <FmSearchableSelect
                    name='projectId'
                    control={control}
                    apiUrl='projects'
                    valueField='projectId'
                    queryparam='tenderId'
                    queryparamValue={selectedTender?.tenderId}
                    labelField={['projectCode', 'projectName']}
                    showField={['projectName']}
                    onChangeProp={setSelectedProject}
                    label='Project'
                  />
                </Grid>
                <Grid item md={GridValue}>
                  <FmSearchableSelect
                    name='clientId'
                    control={control}
                    apiUrl='clients'
                    valueField='clientId'
                    queryparam='projectId'
                    queryparamValue={selectedProject?.projectId}
                    labelField={['clientCode', 'clientName']}
                    showField={['clientName']}
                    label='Client'
                    defaultValue={{
                      clientName: selectedTender?.clientName,
                      clientId: selectedTender?.clientId,
                    }}
                    required
                  />
                </Grid>

                <Grid item md={GridValue}>
                  <FmSearchableSelect
                    name='buildingId'
                    control={control}
                    apiUrl='buildings'
                    valueField='buildingId'
                    labelField={['buildingCode', 'buildingName']}
                    showField={['buildingName']}
                    label='Building'
                  />
                </Grid>
                <Grid item md={GridValue}>
                  <FmDatePicker
                    name='dateOfEnquiry'
                    label='Date Of Enquiry'
                    control={control}
                  />
                </Grid>
                <Grid item md={GridValue}>
                  <FmTextField
                    name='contractPeriod'
                    control={control}
                    label='Contract Period'
                    required
                  />
                </Grid>
                <Grid item md={GridValue}>
                  <FmTextField
                    name='otherPercent'
                    control={control}
                    label='Other Percent(%)'
                    defaultValue={0}
                    pattern='Percentage'
                  />
                </Grid>
                <Grid item md={GridValue}>
                  <FmTextField
                    name='discountOverAllPercent'
                    control={control}
                    label='Discount Percent(%)'
                    defaultValue={0}
                    pattern='Percentage'
                  />
                </Grid>
                <Grid item md={GridValue}>
                  <FmTextField
                    name='existContract'
                    control={control}
                    label='Exist Contract'
                  />
                </Grid>

                <Grid item md={GridValue}>
                  <FmSearchableSelect
                    name='siteVisitDoneBy'
                    control={control}
                    apiUrl='employees'
                    valueField='employeeId'
                    headerField={['Employee Code', 'Employee Name']}
                    labelField={['employeeCode', 'firstName']}
                    showField={['employeeCode', 'firstName']}
                    label='Site Vist Done By'
                    required
                  />
                </Grid>
                <Grid item md={GridValue}>
                  <FmSearchableSelect
                    name='preparedBy'
                    control={control}
                    apiUrl='employees'
                    required
                    valueField='employeeId'
                    headerField={['Employee Code', 'Employee Name']}
                    labelField={['employeeCode', 'firstName']}
                    showField={['employeeCode', 'firstName']}
                    label='Prepared By'
                  />
                </Grid>
              </Grid>
              <ActionButtons
                onSubmit={handleSubmit(handleSubmitAMC)}
                onCancel={() => navigate(-1)}
                onReset={reset}
                submitLoading={createLoading}
                cancelLoading={false}
                submitText='Create'
              />
            </LocalizationProvider>
          </form>
        </GlassCard>
      </DashboardLayout>
    </>
  );
};

export default CreateEstimationSheetForAMC;
