import React from 'react';
import MenuItem from '@mui/material/MenuItem';

const MuiMenuItem = ({ name, icon, index, danger, ...props }) => {
  return (
    <MenuItem
      onClick={() => props.setActiveMenu(index)}
      sx={{
        color: danger ? 'error.main' : 'text.main',
        fontSize: 'inherit',
        fontWeight: 500,
        py: '7px',
        whiteSpace: 'wrap',
        '&:hover': {
          opacity: 1,
        },
      }}
      {...props}
      key={index}
    >
      {icon && <span className='text-xl me-3'>{icon}</span>}
      <span>{name}</span>
    </MenuItem>
  );
};

export default MuiMenuItem;
