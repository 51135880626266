import React, { useEffect, useState } from 'react'
import DashboardLayout from '../../../components/DashboardLayout.tsx'
import { BacktoList } from '../../../components/small/BackToButton.tsx';
import GlassCard from '../../../components/small/GlassCard.tsx'
import CommonLoader from '../../../components/page/CommonLoader.jsx'
import useLoader from '../../../components/helpers/UseLoader.tsx'
import { Grid } from '@mui/material'
import { useForm } from 'react-hook-form'
import { acknowledgementStatusEnum } from '../../../utils/CommonVariables.tsx'
import FmTextField from '../../../components/_mui/FmTextField.tsx'
import ViewCollectionAgent from './ViewCollectionAgent.tsx'
import { useNavigate, useParams } from 'react-router-dom'
import ActionButtons from '../../../components/_form/ActionButtons.tsx'
import Crud_Service from '../../../apis/CrudService.jsx'
import { toast } from 'react-toastify'
import UploadSquare from '../../../components/upload/UploadSquare.tsx'
import { FileType } from '../../../constants/FileType.ts'

const gridValue = 2.4;

const EditCollectionAgent = () => {
    const { id } = useParams();
    const crud = new Crud_Service();
    const navigate = useNavigate();
    const [loading, setLoading] = useState(false);
    const { createLoading, startCreateLoading, stopCreateLoading } = useLoader();
    const [files, setFiles] = useState([]);
    const [previewdData, setPreviewData] = useState([]);
    const [removedFiles, setRemovedFiles] = useState([]);
    const [loadingFiles, setLoadingFiles] = useState(false);
    const { handleSubmit, control, reset, watch } = useForm();
    const [collectionData, setCollectionData] = useState({});
    const [editData, setEditData] = useState(null);
    const [showFields, setShowFields] = useState(false); // State to control visibility

    const handleHandoverClick = () => {
        setShowFields(true);
        handleSubmitCA(collectionData, true);
    };
    const fetchCollectionAgent = async () => {
        await crud.getSingle('invoicecollectionagents', id, (_err, res) => {
            const invoiceFileIds =
                res?.data?.invoiceCollectionFiles?.map((file) => file.invoiceCollectionFileId) ||
                [];
            if (res?.status === 200) {
                setCollectionData(res?.data);
                setLoading(false);
                setDeleteFile(invoiceFileIds);
            } else {
            }
        });
    };

    useEffect(() => {
        fetchCollectionAgent();
        if (editData && editData?.invoiceCollectionFileData) {
            editData?.invoiceCollectionFileData?.forEach((image) => {
                if (image?.attachmentTypeId === 2) setFile(image);
            });
        }
    }, [id, editData]);

    const handleFileUpload = async () => {
        const fileIds = [];
        for (const file of files) {
            if (file?.apiCallNo === false) {
                continue;
            }
            const formData = new FormData();
            formData.append('file', file);
            formData.append('fileTypeId', FileType?.PettyCash);

            try {
                const fileId = await new Promise((resolve, reject) => {
                    crud.create('/files/upload', formData, (_err, res) => {
                        if (res?.status === 200) {
                            const newFile = {
                                fileName: res?.data?.originalFileName,
                                fileId: res?.data?.fileId,
                            };
                            resolve(newFile.fileId); // Resolve with fileId
                            toast.success('File uploaded successfully');
                        } else {
                            toast.error('Failed to upload file.');
                            reject(new Error('Failed to upload file.'));
                        }
                    });
                });

                if (fileId) {
                    fileIds.push(fileId);
                }
            } catch (error) {
            }
        }

        return fileIds;
    };


    const handleSubmitCA = async (values, isHandover = false) => {
        startCreateLoading();

        let CombinedData;

        if (isHandover) {
            // Payload for Handover to Client
            const uploadedFile = await handleFileUpload();
            const updateVendorDocumentAttachments = [
                ...(values?.invoiceCollectionFileData?.map((file) => ({
                    invoiceCollectionFileId: file.invoiceCollectionFileId,
                    fileId: file.fileId,
                })) || []),
                ...(uploadedFile?.map((fileId) => ({
                    invoiceCollectionFileId: 0,
                    fileId: fileId,
                })) || []),
            ];
            CombinedData = {
                ...values,
                remarks: values?.remarks || '',
                acknowledgmentStatusId: acknowledgementStatusEnum?.delivered,
                upsertInvoiceCollectionFiles: updateVendorDocumentAttachments || [],
                status: 2,
            };
        } else {

            const uploadedFile = await handleFileUpload();
            const updateVendorDocumentAttachments = [
                ...(values?.invoiceCollectionFileData?.map((file) => ({
                    invoiceCollectionFileId: file.invoiceCollectionFileId,
                    fileId: file.fileId,
                })) || []),
                ...(uploadedFile?.map((fileId) => ({
                    invoiceCollectionFileId: 0,
                    fileId: fileId,
                })) || []),
            ];

            CombinedData = {
                ...values,
                remarks: values?.remarks || '',
                acknowledgmentStatusId: acknowledgementStatusEnum?.acknowledged,
                upsertInvoiceCollectionFiles: updateVendorDocumentAttachments || [],
                status: 2,
            };
        }

        await crud.update(`invoicecollectionagents`, id, CombinedData, (_err, res) => {
            stopCreateLoading();
            if (res?.status === 200) {
                if (isHandover) {
                    toast.success('Handover to client successfully!');
                } else {
                    toast.success('Acknowledged Updated Successfully');
                    navigate('/financial/collection-agent');
                }

            } else {

            }
        });
    };


    const handleDelete = async (fileId) => {
        files.forEach((file) => {
            if (file.fileId === fileId) {
                crud.remove(
                    'invoicecollectionfiles',
                    file.invoiceCollectionFileId,
                    async (_err, res) => {
                        if (res?.status === 204) {
                            toast.success('Invoice File Deleted Successfully');
                            setFiles((prevFiles) =>
                                prevFiles.filter(
                                    (file) => file.invoiceCollectionFileId !== invoiceCollectionFileId
                                )
                            );
                        } else {
                        }
                    }
                );
            }
        });
    };

    return (
        <DashboardLayout title='View Collection Agent'
            actionButtons={<BacktoList />}
            titleReverse
        >
            <ViewCollectionAgent collectionData={collectionData} onHandoverClick={handleHandoverClick} isDisabled={showFields} />
            {showFields && (
                <GlassCard className='p-4'>
                    {loading ? (
                        <CommonLoader />
                    ) : (
                        <form onSubmit={handleSubmit((values) => handleSubmitCA(values))}>
                            <Grid container spacing={3}>
                                <Grid item md={gridValue * 2}>
                                    <FmTextField
                                        name='remarks'
                                        control={control}
                                        label='Remarks'
                                        multiline={true}
                                        rows={4}
                                        maxRows={3}
                                    />
                                </Grid>
                                <Grid item md={gridValue}>
                                    <UploadSquare
                                        files={files}
                                        setFiles={setFiles}
                                        onFilesSelected={setFiles}
                                        previewdData={previewdData}
                                        glassCard={false}
                                        loadingFiles={loadingFiles}
                                        setRemovedFiles={setRemovedFiles}
                                        onDeleteFile={(file) => handleDelete(file)}
                                    />
                                </Grid>
                            </Grid>
                            <ActionButtons
                                onSubmit={handleSubmit((values) => handleSubmitCA(values))}
                                onCancel={() => navigate('/financial/collection-agent')}
                                onReset={reset}
                                submitLoading={createLoading}
                                cancelLoading={false}
                                submitText='Acknowledge'
                            />
                        </form>
                    )}
                </GlassCard>
            )}
        </DashboardLayout>
    )
}

export default EditCollectionAgent
