import React from 'react';
import ReactDOM from 'react-dom/client';
import { Provider, useSelector } from 'react-redux';
import { PersistGate } from 'redux-persist/integration/react';
import { ToastContainer } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import 'react-perfect-scrollbar/dist/css/styles.css';
import './index.css';
import './i18n.js';

import App from './App';
import ErrorBoundary from './components/ErrorBoundary.tsx';
import PageLoader from './components/page/PageLoader.jsx';
import ServerToast from './components/alert/ServerToast.tsx';
import { persistor, store } from './store/store.ts';
import { ThemeProvider } from './ThemeContext.jsx';
import { NavProvider } from './utils/NavProvider.tsx';
import reportWebVitals from './reportWebVitals.js';

const Root = () => {
  const loading = useSelector((state) => {
    return state.auth?.loading || false; // Ensure it returns a boolean
  });

  return (
    <ErrorBoundary>
      <ThemeProvider>
        <NavProvider>
          <React.Suspense fallback={<PageLoader />}>
            {loading ? <PageLoader /> : <App />}
            <ToastContainer
              limit={3}
              autoClose={5000}
              closeOnClick
              theme="colored"
              position="top-center"
            />
            <ServerToast />
          </React.Suspense>
        </NavProvider>
      </ThemeProvider>
    </ErrorBoundary>
  );
};

// Render everything inside the root element
const root = ReactDOM.createRoot(document.getElementById('root'));
root.render(
  <Provider store={store}>
    <PersistGate loading={<PageLoader />} persistor={persistor}>
      <Root />
    </PersistGate>
  </Provider>
);

// Report performance metrics
reportWebVitals();
