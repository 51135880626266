import React, { useEffect, useMemo, useState } from 'react';
import DashboardLayout from '../../../components/DashboardLayout.tsx';
import PageHeader from '../../../components/PageHeader';
import DataTable from '../../../components/DataTable.tsx';
import GlassCard from '../../../components/small/GlassCard.tsx';
import TableActionButtons from '../../../components/_form/TableActionButtons';
import { ViewField, StatusBadge } from '../../../components/_form/FormElements';
import { Box, Grid } from '@mui/material';
import { CreateButton } from '../../../components/small/Buttons';
import TableSearch from '../../../components/_form/TableSearch.jsx';
import MuiDialogOne from '../../../components/_mui/MuiDialogOne';
import { Nav } from '../../../utils';
import Crud_Service from '../../../apis/CrudService';
import { useForm, SubmitHandler } from 'react-hook-form';
import { useTranslation } from 'react-i18next';
import CommonLoader from '../../../components/page/CommonLoader';
import useLoader from '../../../components/helpers/UseLoader.tsx';
import ActionButtons from '../../../components/_form/ActionButtons.tsx';
import useTableLogic from '../../../components/helpers/UseTableLogic.tsx';
import FmTextField from '../../../components/_mui/FmTextField.tsx';
import FmAutoComplete from '../../../components/_mui/FmAutoComplete.tsx';
import { MasterDatatableHeight } from '../../../components/helpers/Containers.jsx';
import { statusOptions } from '../../../utils/CommonVariables.tsx';
import { UnitConfigurationProps } from '../../../interfaces/PreSalesInterfaces.ts';
import { toast } from 'react-toastify';
import { yupResolver } from '@hookform/resolvers/yup';
import * as Yup from 'yup';
import Icons from '../../../utils/Icon.jsx';
import { useSelector } from 'react-redux';
import { findNavByName } from '../../../utils/navUtils.ts';
import useCommonFetchApi from '../../../components/helpers/useCommonFetchApi.tsx';
import usePermissions from '../../../utils/CommonUtils/PermissionUtil.tsx';
import { AssetGroupchema } from '../../../utils/CommonvalidationSchemas.tsx';

const AssetGroupMaster = (props) => {
  const { type } = props;
  const [openCreate, setOpenCreate] = React.useState(false);
  const [openView, setOpenView] = React.useState(false);
  const [editModal, setEditModal] = useState(false);
  const [editData, setEditData] = useState(null);
  const crud = new Crud_Service();
  const { t } = useTranslation();
  const [viewData, setViewData] = useState();
  const [loading, setLoader] = useState(false);
 
  const { handleSubmit, setValue, control, reset } =
    useForm<UnitConfigurationProps>({
      resolver: yupResolver(AssetGroupchema),
      mode: 'onChange',
    });
  const {
    createLoading,
    startCreateLoading,
    stopCreateLoading,
    editLoading,
    startEditLoading,
    stopEditLoading,
  } = useLoader();
  const {
    rows,
    pageSize,
    pageNumber,
    setSearchKeyword,
    isLoading,
    tableRecordCounts,
    searchKeyword,
    handlePaginationModelChange,
    handleSortModelChange,
    fetchData,
  } = useTableLogic('assetgroups');

  const menu = useSelector((state) => state?.menu?.items);

  const SubMenu = useMemo(() => {
    return menu?.map((item) => item?.menus?.flat()).flat() || [];
  }, [menu]);

  const resultCafm = useMemo(
    () => findNavByName(SubMenu, 'CAFM', 'All master'),
    [SubMenu]
  );
  const currentPath = location.pathname;
  const index = resultCafm?.findIndex((item) => item.url === currentPath);
  const menuPermission = resultCafm[index]?.permissionDtos;
  const permissions = usePermissions(menuPermission);

  useEffect(() => {
    document.title = `CAFM - Asset Groups`;
  }, []);

  const handleCreateClickOpen = () => {
    reset();
    setOpenCreate(true);
  };
  const handleCreateClose = () => {
    reset();
    setOpenCreate(false);
  };
  const { data: UOM } = useCommonFetchApi('unitofmeasurements') || {
    data: [],
  };
  const { data: assettypes } = useCommonFetchApi('assettypes') || {
    data: [],
  };
  const { data: assetgroups } = useCommonFetchApi('assetgroups') || {
    data: [],
  };
  const handleSubmitAssetGroup: SubmitHandler<UnitConfigurationProps> = async (
    values
  ) => {
    const combinedValues = {
      ...values,
      assetGroupName: values?.assetGroupName,
      assetTypeId: values?.assetTypeId,
      unitOfMeasurementId: values?.unitOfMeasurementId,
      parentAssetGroupId: values?.parentAssetGroupId,
    };
    startCreateLoading();

    await crud.create('assetgroups', combinedValues, (err, res) => {
      if (res?.status === 201) {
        setOpenCreate(false);
        stopCreateLoading();
        toast.success('Asset Group Created Successfully');
        reset();
        fetchData();
      } else {
        stopCreateLoading();
        setOpenCreate(true);
      }
    });
  };

  const handleViewClickOpen = async (view) => {
    setOpenView(true);
    setLoader(true);
    await crud.getSingle('assetgroups', view?.assetGroupId, (err, res) => {
      if (res?.status === 200) {
        setViewData(res?.data);
        setLoader(false);
      } else {
      }
    });
  };

  const handleEditClick = async (view) => {
    setEditModal(true);
    setLoader(true);
    await crud.getSingle('assetgroups', view?.assetGroupId, (err, res) => {
      if (res?.status === 200) {
        Object.entries({
          ...res?.data,
        }).forEach(([key, value]) => {
          setValue(key, value);
        });
        setEditData(res?.data);
        setLoader(false);
      } else {
        setLoader(false);
      }
    });
  };

  const handleEditUnitConfigurations = async (values) => {
    startEditLoading();
    const combinedValues = {
      assetGroupName: values?.assetGroupName,
      assetTypeId: values?.assetTypeId,
      unitOfMeasurementId: values?.unitOfMeasurementId,
      parentAssetGroupId: values?.parentAssetGroupId,
      status: values?.status,
    };
    await crud.update(
      'assetgroups',
      editData?.assetGroupId,
      combinedValues,
      (err, res) => {
        if (res?.status === 200) {
          setEditModal(false);
          stopEditLoading();
          toast.success('Asset Group Updated Successfully');
          fetchData();
        } else {
          stopEditLoading();
          setEditModal(true);
        }
      }
    );
  };

  const handleDeleteClick = async (view) => {
    await crud.remove('assetgroups', view?.assetGroupId, (err, res) => {
      if (res?.status === 204) {
        fetchData();
        toast.success('Asset Group Deleted Successfully');
      } else {
      }
    });
  };

  const handleViewClose = () => {
    setOpenView(false);
  };

  const counters = [
    {
      name: 'All',
      count: tableRecordCounts?.total || 0,
    },
    {
      name: 'Active',
      count: tableRecordCounts?.active || 0,
    },
    {
      name: 'Deactivated',
      count: tableRecordCounts?.inActive || 0,
    },
  ];

  const ActionData = [
    {
      name: t('view'),
      icon: Icons.view,
      hidden: !permissions.view,

      onClick: (props) => handleViewClickOpen(props),
    },
    {
      name: t('edit'),
      icon: Icons.edit,
      hidden: !permissions.edit,

      onClick: (props) => handleEditClick(props),
    },
    {
      name: t('delete'),
      icon: Icons.delete,
      hidden: !permissions.delete,
      onClick: (props) => handleDeleteClick(props),
      danger: true,
    },
  ];

  const columns = [
    {
      field: 'action',
      headerName: '',
      width: 140,
      sortable: false,
      disableClickEventBubbling: false,
      renderCell: (params) => {
        return (
          <TableActionButtons
            Actions={ActionData?.map((action) => ({
              ...action,
              onClick: () => action.onClick(params.row),
            }))}
          />
        );
      },
    },
    {
      field: 'assetGroupCode',
      headerName: 'Asset Group Code',
      flex: 1,
    },
    {
      field: 'assetGroupName',
      headerName: 'Asset Group Name',
      flex: 1,
    },
    {
      field: 'assetTypeName',
      headerName: 'Asset Type',
      flex: 1,
    },
    {
      field: 'parentAssetGroupName',
      headerName: 'Parent Asset Group',
      flex: 1,
    },
    {
      field: 'unitOfMeasurementName',
      headerName: 'Unit of Measurement',
      flex: 1,
    },
    {
      field: 'status',
      headerName: t('status'),
      flex: 1,
      renderCell: (params) => {
        return (
          <StatusBadge
            title={params.value === 2 ? 'Active' : 'Inactive'}
            type={params.value === 2 ? 'green' : 'red'}
          />
        );
      },
    },
  ];

  return (
    <DashboardLayout
      title='Asset Groups'
      actionButtons={
        <>
          <PageHeader counters={counters} />
        </>
      }
      hasSubmenu
      menu={resultCafm}
    >
      <GlassCard className='h-full-css'>
        <MasterDatatableHeight>
          <DataTable
            rows={rows}
            columns={columns}
            loading={isLoading}
            getRowClassName={(params) =>
              `${params.row.status === 0 && 'bg-red-50'}`
            }
            sortingMode='server'
            paginationMode='server'
            onPaginationModelChange={(model) =>
              handlePaginationModelChange(model)
            }
            onSortModelChange={(model) => handleSortModelChange(model)}
            page={pageNumber - 1}
            pageSize={pageSize}
            rowCount={tableRecordCounts?.total}
            slots={{
              toolbar: () => (
                <Box
                  className='p-2 w-full flex justify-between items-center'
                  sx={{
                    borderBottom: '1px solid',
                    borderColor: 'border.main',
                  }}
                >
                  <Box sx={{ maxWidth: '250px' }}>
                    <TableSearch
                      placeholder='Search'
                      fullWidth
                      setSearchKeyword={setSearchKeyword}
                      searchValue={searchKeyword}
                    />
                  </Box>
                  {permissions.create && (
                    <CreateButton name='New' onClick={handleCreateClickOpen} />
                  )}
                </Box>
              ),
            }}
          />
        </MasterDatatableHeight>
      </GlassCard>

      <MuiDialogOne
        title={t('create')}
        open={openCreate}
        onClose={handleCreateClose}
      >
        <form onSubmit={handleSubmit(handleSubmitAssetGroup)}>
          <Grid container spacing={3}>
            <Grid item md={12}>
              <FmTextField
                name='assetGroupName'
                control={control}
                label='Asset Group Name'
                required
                rules={{
                  required: 'Asset Group Name is required',
                }}
              />
            </Grid>
            <Grid item md={12}>
              <FmAutoComplete
                name='parentAssetGroupId'
                control={control}
                options={assetgroups}
                label='Parent Asset Group'
                displayField='assetGroupName'
                optionFields={['assetGroupName']}
                valueKey='assetGroupId'
              />
            </Grid>
            <Grid item md={12}>
              <FmAutoComplete
                name='unitOfMeasurementId'
                control={control}
                options={UOM}
                label='UOM'
                displayField='unitOfMeasurementName'
                optionFields={['unitOfMeasurementName']}
                valueKey='unitOfMeasurementId'
                required
              />
            </Grid>
            <Grid item md={12}>
              <FmAutoComplete
                name='assetTypeId'
                control={control}
                options={assettypes}
                label='Asset Type'
                displayField='assetTypeName'
                optionFields={['assetTypeName']}
                valueKey='assetTypeId'
                required
              />
            </Grid>
            <Grid item md={12}>
              <ActionButtons
                onSubmit={handleSubmit(handleSubmitAssetGroup)}
                onCancel={handleCreateClose}
                onReset={reset}
                submitLoading={createLoading}
                cancelLoading={false}
                submitText='Create'
              />
            </Grid>
          </Grid>
        </form>
      </MuiDialogOne>

      <MuiDialogOne
        title='View Details'
        open={openView}
        onClose={handleViewClose}
      >
        {loading ? (
          <CommonLoader />
        ) : (
          <Grid container spacing={3}>
            <Grid item md={6}>
              <ViewField
                label='Asset Group Code'
                title={viewData?.assetGroupCode}
              />
            </Grid>
            <Grid item md={6}>
              <ViewField label='Asset Group' title={viewData?.assetGroupName} />
            </Grid>
            <Grid item md={6}>
              <ViewField label='Asset Type' title={viewData?.assetTypeName} />
            </Grid>
            <Grid item md={6}>
              <ViewField
                label='Unit of Measurement'
                title={viewData?.unitOfMeasurementName}
              />
            </Grid>
            <Grid item md={6}>
              <ViewField
                label='Parent Asset Group'
                title={viewData?.parentAssetGroupName}
              />
            </Grid>

            <Grid item md={6}>
              <ViewField label={t('status')} title={viewData?.statusName} />
            </Grid>
          </Grid>
        )}
      </MuiDialogOne>

      <MuiDialogOne
        title={t('update')}
        open={editModal}
        onClose={() => setEditModal(false)}
      >
        {loading ? (
          <CommonLoader />
        ) : (
          <form onSubmit={handleSubmit(handleEditUnitConfigurations)}>
            <Grid container spacing={3}>
              <Grid item md={12}>
                <FmTextField
                  name='assetGroupName'
                  required
                  control={control}
                  label='Asset Group Name'
                  rules={{
                    required: 'Asset Group Name is required',
                  }}
                />
              </Grid>
              <Grid item md={12}>
                <FmAutoComplete
                  name='parentAssetGroupId'
                  control={control}
                  options={assetgroups}
                  label='Parent Asset Group'
                  displayField='assetGroupName'
                  optionFields={['assetGroupName']}
                  valueKey='assetGroupId'
                />
              </Grid>
              <Grid item md={12}>
                <FmAutoComplete
                  name='unitOfMeasurementId'
                  control={control}
                  options={UOM}
                  label='UOM'
                  required
                  displayField='unitOfMeasurementName'
                  optionFields={['unitOfMeasurementName']}
                  valueKey='unitOfMeasurementId'
                />
              </Grid>
              <Grid item md={12}>
                <FmAutoComplete
                  name='assetTypeId'
                  control={control}
                  options={assettypes}
                  label='Asset Type'
                  required
                  displayField='assetTypeName'
                  optionFields={['assetTypeName']}
                  valueKey='assetTypeId'
                />
              </Grid>
              <Grid item md={12}>
                <FmAutoComplete
                  name='status'
                  control={control}
                  options={statusOptions}
                  label='Status'
                  displayField='statusName'
                  optionFields={['statusName']}
                  valueKey='statusId'
                />
              </Grid>
              <Grid item md={12}>
                <ActionButtons
                  onSubmit={handleSubmit(handleEditUnitConfigurations)}
                  onCancel={() => setEditModal(false)}
                  submitLoading={editLoading}
                  onReset={reset}
                  cancelLoading={false}
                  submitText='Update'
                />
              </Grid>
            </Grid>
          </form>
        )}
      </MuiDialogOne>
    </DashboardLayout>
  );
};

export default AssetGroupMaster; 