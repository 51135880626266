import { Grid } from '@mui/material';
import dayjs from 'dayjs';
import React, { useEffect, useState } from 'react';
import { useForm } from 'react-hook-form';
import { useNavigate, useParams } from 'react-router-dom';
import { toast } from 'react-toastify';
import apiInstance from '../../../../apis/ApiService.jsx';
import Crud_Service from '../../../../apis/CrudService.jsx';
import { ActionButtons } from '../../../../components/_form';
import FmDatePicker from '../../../../components/_mui/FmDatePicker.tsx';
import FmSearchableSelect from '../../../../components/_mui/FmSearchableSelect.tsx';
import FmTextField from '../../../../components/_mui/FmTextField.tsx';
import useLoader from '../../../../components/helpers/UseLoader.tsx';
import { yupResolver } from '@hookform/resolvers/yup';
import { DebitNotevalidationSchema } from '../../../../utils/CommonvalidationSchemas.tsx';
import GlassCardCollapse from './../../../../components/small/GlassCardCollapse.tsx';
import UploadModalBox from '../../../../components/upload/UploadModalBox.tsx';
import UploadSquare from '../../../../components/upload/UploadSquare.tsx';
import { FileType } from '../../../../constants/FileType.ts';
import { ViewField } from '../../../../components/_form/FormElements.jsx';
import useCommonFetchApi from '../../../../components/helpers/useCommonFetchApi.tsx';
import FmAutoComplete from '../../../../components/_mui/FmAutoComplete.tsx';
import { debitNoteStatusEnum } from '../../../../utils/CommonVariables.tsx';
import EditListDebitNote from './EditListDebitNote.tsx';

const FieldsCreateDebitJournal = () => {
  const GridValue = 2.4;
  const [editingRowData, setEditingRowData] = useState(null);
  const { handleSubmit, control, reset, setValue } = useForm<{
    narration?: string | null;
    requestDate?: Date | null;
    accountId: number;
    branchId: number;
    companyId: number;
    placeOfSupplyId: number;
    jurisdictionId: number;
    purchaseVoucherId: number;
    journalFiles: number[];
  }>({
    mode: 'onChange',
    resolver: yupResolver(DebitNotevalidationSchema) as any,
  });
  const { createLoading, startCreateLoading, stopCreateLoading } = useLoader();
  const [uploadedFileIds, setUploadedFileIds] = useState([]);
  const [files, setFiles] = useState([]);
  const [previewdData, setPreviewData] = useState([]);
  const [removedFiles, setRemovedFiles] = useState([]);
  const [loadingFiles, setLoadingFiles] = useState(false);
  const [editData, setEditData] = useState(null);
  const navigate = useNavigate();
  const crud = new Crud_Service();
  const { id } = useParams();

  const { data: debitNoteStatus } = useCommonFetchApi(
    'enums/debit-note-status '
  );

  const handleFileIds = (fileIds) => {
    setUploadedFileIds(fileIds);
  };

  useEffect(() => {
    if (editData && editData?.journalFiles) {
      editData?.journalFiles?.forEach((image) => {
        handleDownloadFile(image?.fileId, image?.attachmentTypeId === 2);
        if (image?.attachmentTypeId === 2) setFiles(image);
      });
    }
  }, [editData]);
  const [selectedPV, setSelectedPV] = React.useState({});

  useEffect(() => {
    setValue('companyName', selectedPV?.companyName);
  }, [selectedPV]);
  const handleFileUpload = async () => {
    const fileIds = [];

    for (const file of files) {
      if (file?.apiCallNo === false) {
        continue;
      }
      const formData = new FormData();
      formData.append('file', file);
      formData.append('fileTypeId', FileType?.PettyCash);

      try {
        const fileId = await new Promise((resolve, reject) => {
          crud.create('/files/upload', formData, (_err, res) => {
            if (res?.status === 200) {
              const newFile = {
                fileName: res?.data?.originalFileName,
                fileId: res?.data?.fileId,
              };
              resolve(newFile.fileId); // Resolve with fileId
              toast.success('File uploaded successfully');
            } else {
              toast.error('Failed to upload file.');
              reject(new Error('Failed to upload file.'));
            }
          });
        });

        if (fileId) {
          fileIds.push(fileId);
        }
      } catch (error) { }
    }

    return fileIds;
  };

  useEffect(() => {
    if (id) {
      (async () => {
        await crud.getSingle('debitnotes', id, (err, res) => {
          const journalFileIds =
            res?.data?.debitnotefiles?.map((file) => file.journalFileId) || [];
          if (res?.status === 200) {
            setEditingRowData(res.data);
            setEditData(res?.data);
            handleDownloadFile(res?.data?.fileId);
            Object.entries({
              ...res.data,
            }).forEach(([key, value]) => {
              setValue(key, value);
            });
          } else {
            setEditingRowData(null);
            setEditData(null);
          }
        });
      })();
    }
  }, [id]);
  const handleDelete = async (fileId) => {
    files.forEach((file) => {
      if (file.fileId === fileId) {
        crud.remove(
          'debitnotefiles',
          file.debitNoteFileId,
          async (_err, res) => {
            if (res?.status === 204) {
              toast.success('Debit Note File Deleted Successfully');
              setFiles((prevFiles) =>
                prevFiles.filter((file) => file.debitNoteFileId !== fileId)
              );
            } else {
            }
          }
        );
      }
    });
  };
  const onSubmit = async (values) => {
    startCreateLoading();
    const CombinedData = {
      ...values,
      requestDate: dayjs(values?.requestDate).format('YYYY-MM-DD'),
      upsertDebitNoteFiles:
        uploadedFileIds?.length > 0
          ? uploadedFileIds.map((fileId) => ({ debitNoteFileId: 0, fileId }))
          : [],
    };

    const uploadedFile = await handleFileUpload(); // Handle new file upload

    // Move all files (existing + new) into upsertDebitNoteFiles
    const updateVendorDocumentAttachments = [
      ...(values?.debitNoteFiles?.map((file) => ({
        debitNoteFileId: file.debitNoteFileId, // Keep the original ID
        fileId: file.fileId,
      })) || []),
      ...(uploadedFile?.map((fileId) => ({
        debitNoteFileId: 0, // New files get creditNoteFileId: 0
        fileId: fileId,
      })) || []),
    ];

    const updateCombinedData = {
      ...values,
      requestDate: dayjs(values?.requestDate).format('YYYY-MM-DD'),
      upsertDebitNoteFiles: updateVendorDocumentAttachments,
      status: 2,
    };

    if (!id) {
      await crud.create(`debitnotes`, CombinedData, (err, res) => {
        if (res?.status === 201) {
          stopCreateLoading();
          navigate(
            `/financial/journal-entries/debit-notes/edit/${res?.data?.debitNoteId}`
          );
          toast.success('Debit Note Added Successfully');
        } else {
          stopCreateLoading();
        }
      });
    } else {
      await crud.update(`debitnotes`, id, updateCombinedData, (err, res) => {
        if (err) return toast.error(err);
        if (res?.status === 200) {
          stopCreateLoading();
          toast.success('Debit Note Updated Successfully');
        } else {
          stopCreateLoading();
        }
      });
    }
  };

  const handleDownloadFile = async (fileId, deliveryAttachment) => {
    if (!fileId) return;

    setLoadingFiles(true); // Set loading to true before the API call

    try {
      const imageUrl = await apiInstance.getFiles(`files/download/${fileId}`);
      setPreviewData((prevData) => [
        ...prevData,
        { fileId, fileUrl: imageUrl },
      ]);
    } catch (err) {
    } finally {
      setLoadingFiles(false); // Set loading to false after API call completion
    }
  };
  return (
    <>
      <GlassCardCollapse title={!id ? 'Add Debit Note' : 'Update Details'}>
        <form onSubmit={handleSubmit(onSubmit)}>
          <Grid container spacing={2}>
            <Grid item md={GridValue}>
              {id ? (
                <Grid item md={6}>
                  <ViewField
                    label='Purchase Voucher'
                    title={editingRowData?.purchaseVoucherName}
                  />
                </Grid>
              ) : (
                <FmSearchableSelect
                  name='purchaseVoucherId'
                  control={control}
                  apiUrl='purchasevouchers'
                  valueField='purchaseVoucherId'
                  labelField={['purchaseVoucherCode', 'purchaseVoucherName']}
                  showField={['purchaseVoucherCode', 'purchaseVoucherName']}
                  label={'Purchase Voucher'}
                  required
                  onChangeProp={(e) => {
                    setSelectedPV(e);
                  }}
                  disabled={
                    editingRowData?.debitNoteStatusId ===
                    debitNoteStatusEnum?.Approved
                  }
                />
              )}
            </Grid>
            <Grid item md={GridValue}>
              <FmSearchableSelect
                name='accountId'
                control={control}
                apiUrl='chartofaccounts'
                valueField='chartOfAccountId'
                labelField={['chartOfAccountCode', 'chartOfAccountName']}
                showField={['chartOfAccountCode', 'chartOfAccountName']}
                label='Account'
                defaultValue={{
                  chartOfAccountId: editingRowData?.accountId,
                  chartOfAccountName: editingRowData?.accountName,
                }}
                required
                disabled={
                  editingRowData?.debitNoteStatusId ===
                  debitNoteStatusEnum?.Approved
                }
              />
            </Grid>
            <Grid item md={GridValue}>
              <FmSearchableSelect
                name='branchId'
                control={control}
                apiUrl='branches'
                valueField='branchId'
                labelField={['branchCode', 'branchName']}
                showField={['branchCode', 'branchName']}
                label={'Branch'}
                defaultValue={{
                  branchId: editingRowData?.branchId,
                  branchName: editingRowData?.branchName,
                }}
                required
                disabled={
                  editingRowData?.debitNoteStatusId ===
                  debitNoteStatusEnum?.Approved
                }
              />
            </Grid>
            <Grid item md={GridValue}>
              <FmSearchableSelect
                name='placeOfSupplyId'
                control={control}
                apiUrl='placeofsupplies'
                valueField='placeOfSupplyId'
                labelField={['placeOfSupplyCode', 'placeOfSupplyName']}
                showField={['placeOfSupplyCode', 'placeOfSupplyName']}
                label='Place of Supply'
                defaultValue={{
                  placeOfSupplyId: editingRowData?.placeOfSupplyId,
                  placeOfSupplyName: editingRowData?.placeOfSupplyName,
                }}
                disabled={
                  editingRowData?.debitNoteStatusId ===
                  debitNoteStatusEnum?.Approved
                }
              />
            </Grid>
            <Grid item md={GridValue}>
              <FmDatePicker
                name='requestDate'
                label='Request Date'
                control={control}
                maxDate={dayjs().endOf('day')}
                disableFuture
                disabled={
                  editingRowData?.debitNoteStatusId ===
                  debitNoteStatusEnum?.Approved
                }
              />
            </Grid>
            <Grid item md={GridValue}>
              <ViewField
                title={
                  selectedPV?.companyName ||
                  editingRowData?.companyName ||
                  'N/A'
                }
                label='Company'
              />
            </Grid>
            <Grid item md={GridValue}>
              <FmSearchableSelect
                name='jurisdictionId'
                control={control}
                apiUrl='jurisdictions'
                valueField='jurisdictionId'
                labelField={['jurisdictionCode', 'jurisdictionName']}
                showField={['jurisdictionCode', 'jurisdictionName']}
                label='Jurisdiction'
                defaultValue={{
                  jurisdictionId: editingRowData?.jurisdictionId,
                  jurisdictionName: editingRowData?.jurisdictionName,
                }}
                required
                disabled={
                  editingRowData?.debitNoteStatusId ===
                  debitNoteStatusEnum?.Approved
                }
              />
            </Grid>

            <Grid item md={GridValue * 3}>
              {!id ? (
                <UploadModalBox
                  onSubmitFiles={handleFileIds}
                  files={files}
                  setFiles={setFiles}
                  control={control}
                />
              ) : (
                editingRowData?.debitNoteStatusId !== debitNoteStatusEnum?.Approved && (
                  <>
                    <UploadSquare
                      files={files}
                      setFiles={setFiles}
                      onFilesSelected={setFiles}
                      previewdData={previewdData}
                      glassCard={false}
                      loadingFiles={loadingFiles}
                      setRemovedFiles={setRemovedFiles}
                      onDeleteFile={(file) => handleDelete(file)}
                    />
                  </>
                )
              )}
            </Grid>
            {id && (
              <Grid item md={GridValue}>
                <FmAutoComplete
                  name='debitNoteStatusId'
                  control={control}
                  options={debitNoteStatus}
                  label='Status'
                  displayField='displayName'
                  optionFields={['displayName']}
                  valueKey='key'
                  disabled={
                    editingRowData?.debitNoteStatusId ===
                    debitNoteStatusEnum?.Approved
                  }
                />
              </Grid>
            )}
            <Grid item md={GridValue}>
              <FmTextField
                label='Narration'
                name='narration'
                control={control}
                multiline
                rows={4}
                maxLength={255}
                maxRows={6}
                disabled={
                  editingRowData?.debitNoteStatusId ===
                  debitNoteStatusEnum?.Approved
                }
              />
            </Grid>
          </Grid>
        </form>

        {!(
          editingRowData?.debitNoteStatusId === debitNoteStatusEnum?.Approved
        ) && (
            <Grid>
              <ActionButtons
                onSubmit={handleSubmit(onSubmit)}
                onCancel={() =>
                  navigate('/financial/journal-entries/debit-notes')
                }
                onReset={reset}
                cancelLoading={false}
                cancelText='Back'
                submitLoading={createLoading}
              />
            </Grid>
          )}
      </GlassCardCollapse>
      {id && <EditListDebitNote
        debitNoteStatusId={editingRowData?.debitNoteStatusId}
      />}
    </>
  );
};

export default FieldsCreateDebitJournal;
