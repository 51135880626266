import { Box, Button, TextField } from '@mui/material';
import React, { useState } from 'react';
import { useNavigate } from 'react-router-dom';
import Crud_Service from '../../../apis/CrudService.jsx';
import TableActionButtons from '../../../components/_form/TableActionButtons.jsx';
import FmMRTDataTable from '../../../components/FmMRTDataTable.tsx';
import useTableLogic from '../../../components/helpers/MRTUseTableLogic.tsx';
import GlassCard from '../../../components/small/GlassCard.tsx';
import MRTExpandTableStyle from '../../../components/table/MRTExpandTableStyle.tsx';
import Icons from '../../../utils/Icon.jsx';
import { toast } from 'react-toastify';
import { Icon } from '@iconify/react/dist/iconify.js';
import FmViewDateField from '../../../components/_mui/FmViewDateField.tsx';
import DataGridHeaderTooltip from '../../../components/datagrid/DataGridHeaderTooltip.tsx';
import { StatusBadge } from '../../../components/_form/FormElements.jsx';
import FieldPopOverBox from '../../../components/_form/FieldPopOverBox.tsx';
import { DatePicker, LocalizationProvider } from '@mui/x-date-pickers';
import { AdapterDayjs } from '@mui/x-date-pickers/AdapterDayjs';
import apiInstance from '../../../apis/ApiService.jsx';
import dayjs from 'dayjs';
import { VendorDocumentStatusEnum } from '../../../utils/CommonVariables.tsx';

const ListVendorDocuments = (props) => {
  const { type } = props;
  const {
    rows,
    isLoading,
    pagination,
    sorting,
    columnFilters,
    globalFilter,
    tableRecordCounts,
    setPagination,
    setSorting,
    setColumnFilters,
    setGlobalFilter,
    fetchData,
    setSearchKeyword,
    searchKeyword,
  } = useTableLogic(`vendordocuments?statusId=${type === 2 ? 7 : 0}`);
  const crud = new Crud_Service();
  const navigate = useNavigate();
  const [rejectOpen, setRejectOpen] = useState(false);
  const [anchorEl, setAnchorEl] = React.useState(null);
  const [startDate, setStartDate] = useState(null);
  const [endDate, setEndDate] = useState(null);
  const handleRejectClick = (event) => {
    setAnchorEl(event.currentTarget);
  };
  const handleViewClick = async (props, type) => {
    navigate(`${type}/${props?.vendorDocumentId}`);
  };

  const handleDelete = async (props) => {
    try {
      await crud.remove(
        `vendordocuments`,
        props?.vendorDocumentId,
        (err, res) => {
          if (res?.status === 204) {
            fetchData();
            toast.success('Vendor Document Deleted successfully');
          }
        }
      );
    } catch (error) {}
  };

  const updateStatus = async (statusId, data) => {
    try {
      await crud.update(
        `vendordocuments`,
        `${data.vendorDocumentId}/update-status/${statusId}`,
        {},
        (err, res) => {
          if (res?.status === 200) {
            fetchData();
            toast.success('Document Acknowledged');
          }
        }
      );
    } catch (error) {}
  };

  const financeApproval = async (props) => {
    try {
      await crud.update(
        `vendordocuments`,
        `finance-status/${props?.vendorDocumentId}`,
        {
          vendorDocumentStatusId: props?.statusId,
          remarks: props?.remarks || '',
        },
        (err, res) => {
          if (res?.status === 200) {
            fetchData();
            toast.success(
              `Document ${props?.statusId === 9 ? 'Approved' : 'Rejected'}`
            );
          }
        }
      );
    } catch (error) {}
  };

  const handleExport = async () => {
    let url = 'vendordocuments/exportexcel';

    // If both dates are selected, add them to the URL
    if (startDate && endDate) {
      const formattedStartDate = dayjs(startDate).format('YYYY-MM-DD');
      const formattedEndDate = dayjs(endDate).format('YYYY-MM-DD');
      url += `?startDate=${formattedStartDate}&endDate=${formattedEndDate}`;
    }

    const downloadUrl = await apiInstance.getFiles(url);

    const link = document.createElement('a');
    link.href = downloadUrl;
    link.setAttribute('download', 'Vendor_Documents.xlsx');
    document.body.appendChild(link);
    link.click();
    document.body.removeChild(link);
  };

  const ActionData = [
    {
      name: 'Edit',
      icon: Icons.edit,
      onClick: (props) => handleViewClick(props, 'edit'),
    },
    {
      name: 'Delete',
      icon: Icons.delete,
      onClick: (props) => handleDelete(props),
      danger: true,
    },
  ];

  const columns = [
    {
      header: 'Actions',
      size: 200,
      Cell: ({ row }) => {
        const transferred = row?.original.vendorDocumentStatusId === VendorDocumentStatusEnum?.DOCUMENT_TRANSFERRED;

        return (
          <Box className={`flex gap-2 items-center w-full`}>
            <TableActionButtons
              Actions={ActionData?.map((action) => ({
                ...action,
                onClick: () => action.onClick(row?.original),
              }))}
            />
            {type === 2 && (
              <>
                <Box className='flex gap-2'>
                  <Button
                    className='py-0 px-3'
                    onClick={() =>
                      financeApproval({ ...row.original, statusId: 9 })
                    }
                    variant='contained'
                  >
                    Approve
                  </Button>
                  <Button
                    variant='contained'
                    color='error'
                    className='py-0 px-3'
                    onClick={(ev) => {
                      setRejectOpen(true);
                      handleRejectClick(ev);
                    }}
                    startIcon={<Icon icon='mdi:close-circle' />}
                  >
                    Reject
                  </Button>
                </Box>
                <FieldPopOverBox
                  title='Reject Reason'
                  anchorEl={anchorEl}
                  open={rejectOpen}
                  type={2}
                  setRejectOpen={(ev) => {
                    setRejectOpen(ev);
                    fetchData();
                  }}
                  onClose={() => {
                    setRejectOpen(false);
                    fetchData();
                  }}
                  id={row?.original?.vendorDocumentId}
                />
              </>
            )}
            {transferred && type !== 2 && (
              <Box className='inline-block'>
                <Button
                  className='py-0 px-3'
                  onClick={() => updateStatus(3, row?.original)}
                  variant='contained'
                >
                  Acknowledge
                </Button>
              </Box>
            )}
          </Box>
        );
      },
    },
    {
      accessorKey: 'vendorDocumentStatusName',
      header: 'Status',
      size: 250,
      Cell: ({ cell }) => {
        const GetColor = {
          'Upload Document': 'orange',
          'Delivery Notes Submitted': 'indigo',
          'Transferred to Coordinator': 'purple',
          Rejected: 'red',
          Approved: 'blue',
          Verified: 'green',
          Submitted: 'sky',
          Acknowledged: 'orange',
          'FinanceTeam Rejected': 'red',
          'FinanceTeam Approved': 'blue',
          'SiteTeam Approved': 'yellow',
        };

        return (
          <StatusBadge
            title={cell.getValue() === null ? 'N/A' : cell.getValue()}
            type={GetColor[cell.getValue()]}
          />
        );
      },
    },
    {
      accessorKey: 'invoiceNumber',
      header: (
        <DataGridHeaderTooltip
          name='Doc.Ref. No./Invoice No.'
          title='Document Ref. No./Invoice No.'
        />
      ),
      size: 200,
    },

    {
      accessorKey: 'localPurchaseOrderNo',
      header: 'Local PO No.',
      size: 150,
    },
    {
      accessorKey: 'receivedDate',
      header: 'Received Date',
      size: 150,
      cell: ({ params }) => {
        return FmViewDateField({ value: params?.value });
      },
    },
    {
      accessorKey: 'supplierName',
      header: 'Supplier',
      size: 300,
    },
    {
      accessorKey: 'documentTypeName',
      header: 'Document Type',
    },
    {
      accessorKey: 'documentTransferredToEmployeeName',
      header: 'Document Transferred To',
      size: 200,
    },

    {
      accessorKey: 'createdDate',
      header: 'Created Date',
      size: 150,
      cell: ({ params }) => {
        return FmViewDateField({ value: params?.value });
      },
    },
    {
      accessorKey: 'action',
      header: 'Updated Date',
      size: 150,
      cell: ({ params }) => {
        return FmViewDateField({ value: params?.value });
      },
    },
  ];

  return (
    <>
      <GlassCard className='flex-1 min-h-0 overflow-hidden'>
        <MRTExpandTableStyle enableFullHeight>
          <FmMRTDataTable
            rows={rows}
            columns={columns}
            enableRowSelection={false}
            rowCount={tableRecordCounts}
            pagination={pagination}
            sorting={sorting}
            columnFilters={columnFilters}
            globalFilter={globalFilter}
            setPagination={setPagination}
            setSorting={setSorting}
            setColumnFilters={setColumnFilters}
            setGlobalFilter={setGlobalFilter}
            isLoading={isLoading}
            setSearchKeyword={setSearchKeyword}
            searchKeyword={searchKeyword}
            fetchData={fetchData}
            handleRowDoubleClick={(e) => {
              handleViewClick(e?.original, 'edit');
            }}
            renderTopToolbarCustomActionsChildrens={
              <Box display='flex' gap={2}>
                <LocalizationProvider dateAdapter={AdapterDayjs}>
                  <DatePicker
                    label='Start Date'
                    value={startDate}
                    onChange={(newValue) => setStartDate(newValue)}
                    renderInput={(params) => <TextField {...params} />}
                  />
                  <DatePicker
                    label='End Date'
                    value={endDate}
                    onChange={(newValue) => setEndDate(newValue)}
                    renderInput={(params) => <TextField {...params} />}
                  />
                </LocalizationProvider>
                <Button
                  variant='outlined'
                  color='primary'
                  className='px-3 py-1'
                  onClick={handleExport}
                >
                  Export
                </Button>
              </Box>
            }
            muiTableContainerProps={{
              sx: {
                maxHeight: '100%',
                flex: 1,
              },
            }}
            showSerialNumber={true}
          />
        </MRTExpandTableStyle>
      </GlassCard>
    </>
  );
};

export default ListVendorDocuments;
