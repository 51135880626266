import dayjs from 'dayjs';
import React, { CSSProperties } from 'react';
import { useSelector } from 'react-redux';
import { ViewField } from '../_form/FormElements';
import useDateFormatter from '../helpers/useDateFormatter';

interface FmViewDateFieldProps {
  title?: string;
  value: string;
  valueColor?: string;
  time?: boolean;
  style?: CSSProperties;
  dateTime?: boolean;
  color?: string;
}
const FmViewDateField = (props: FmViewDateFieldProps) => {
  const { title, value, style, color, valueColor, time, dateTime } = props;
  const formateDate = useDateFormatter();

  const { timeFormat } = useSelector((state) => state?.time);

  const formatTime = (value) => {
    return dayjs(value, 'HH:mm:ss').format(
      timeFormat === '12h' ? 'hh:mm A' : 'HH:mm'
    );
  };

  const formatDateTime = (value: string) => {
    return `${formateDate(value)} ${formatTime(value)}`;
  };

  const dateFieldStyles: CSSProperties = {
    color: color || '',
    ...style,
  };

  return (
    <span style={dateFieldStyles}>
      {time ? (
        <ViewField
          label={title}
          title={formatTime(value)}
          valueColor={valueColor}
        />
      ) : dateTime ? (
        <ViewField
          label={title}
          title={value && formatDateTime(value)}
          valueColor={valueColor}
        />
      ) : (
        <ViewField
          label={title}
          title={value && String(formateDate(value))}
          valueColor={valueColor}
        />
      )}
    </span>
  );
};

export default FmViewDateField;
