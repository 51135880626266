import { yupResolver } from '@hookform/resolvers/yup';
import { Grid, Box, Typography } from '@mui/material';
import dayjs from 'dayjs';
import React, { useEffect, useState } from 'react';
import { useForm } from 'react-hook-form';
import { useNavigate } from 'react-router-dom';
import { toast } from 'react-toastify';
import * as yup from 'yup';
import Crud_Service from '../../../../apis/CrudService.jsx';
import ActionButtons from '../../../../components/_form/ActionButtons.tsx';
import FmDatePicker from '../../../../components/_mui/FmDatePicker.tsx';
import FmSearchableSelect from '../../../../components/_mui/FmSearchableSelect.tsx';
import FmTextField from '../../../../components/_mui/FmTextField.tsx';
import DashboardLayout from '../../../../components/DashboardLayout.tsx';
import GlassCard from './../../../../components/small/GlassCard.tsx';
import { Icon } from '@iconify/react/dist/iconify.js';
import ViewContractDetails from '../CreateSalesQuotation/View/ViewContractDetails.tsx';
import FmCurrencyTextField from '../../../../components/_mui/FmCurrencyTextField.tsx';

const GridSize = 3;
const schema = yup.object().shape({
  salesOrderName: yup.string().required('Sales Order Name is required'),

  orderDate: yup.date().required('Order Date is required'),

  contractId: yup
    .number()
    .transform((value, originalValue) => (originalValue === '' ? null : value))
    .required('Contract is required'),
});

interface ContractDetails {
  projectCode?: string;
  projectName?: string;
  clientName?: string;
  contactPerson?: string;
  contactNumber?: string;
  contactEmailId?: string;
  locationName?: string;
  paymentTerms?: string;
}

interface FormValues {
  contractId: number;
  salesOrderName: string;
  orderDate: Date;
  deliveryTerms?: string;
  narration?: string;
  paymentTerms?: string;
  remarks?: string;
  salesQuotationId?: number;
  amount?: string;
  vat?: string;
  totalAmount?: string;
  vatAmount?: string;
}

const IconsListing = [
  {
    icon: <Icon icon='fluent:building-retail-20-regular' />,
    label: 'Project Details',
    fields: [
      { key: 'projectCode', label: 'Code' },
      { key: 'projectName', label: 'Name' },
    ],
  },
  {
    icon: <Icon icon='fluent:building-people-20-regular' />,
    label: 'Client Details',
    fields: [{ key: 'clientName', label: 'Name' }],
  },
  {
    icon: <Icon icon='fluent:person-20-regular' />,
    label: 'Contact Details',
    fields: [
      { key: 'contactPerson', label: 'Name' },
      { key: 'contactNumber', label: 'Mobile' },
      { key: 'contactEmailId', label: 'Email' },
    ],
  },
  {
    icon: <Icon icon='fluent:location-20-regular' />,
    label: 'Location',
    fields: [{ key: 'locationName', label: 'Name' }],
  },
  {
    icon: <Icon icon='fluent:payment-20-regular' />,
    label: 'Payment Terms',
    fields: [{ key: 'paymentTerms', label: 'Terms' }],
  },
];

const AddSalesOrder = (props) => {
  const { setGetData, type } = props;
  const crud = new Crud_Service();
  const [loading, setLoading] = useState(false);
  const { control, handleSubmit, reset, setValue, getValues, watch } =
    useForm<FormValues>({
      resolver: yupResolver(schema),
      mode: 'onChange',
    });
  const [selectedOrder, setSelectedOrder] = useState({});
  const [selectedContract, setSelectedContract] = useState<ContractDetails>({});
  const navigate = useNavigate();
  const AmountFieldValue = watch('amount');

  useEffect(() => {
    reset();
    setSelectedOrder({});
  }, [type]);

  useEffect(() => {
    if (selectedOrder?.salesQuotationId) {
      setValue('deliveryTerms', selectedOrder?.deliveryTerms);
      setValue('narration', selectedOrder?.narration);
      setValue('paymentTerms', selectedOrder?.paymentTerms);
      setValue('remarks', selectedOrder?.remarks);
    }
  }, [selectedOrder]);
  const amount = watch('amount');
  const vat = watch('vat');

  useEffect(() => {
    const amountValue = parseFloat(amount) || 0;
    const vatPercentage = parseFloat(vat) || 0;
    const vatAmount = (amountValue * vatPercentage) / 100;
    const totalAmount = amountValue + vatAmount;

    // Set both VAT amount and total amount with 2 decimal places
    setValue('vatAmount', vatAmount.toFixed(2));
    setValue('totalAmount', totalAmount.toFixed(2));
  }, [amount, vat, setValue]);

  const submitSalesOrder = async (values) => {
    setLoading(true);
    const combinedValues = {
      ...values,
      //TODO: Need to Do Dynamic in future
      salesOrderTypeId: type,
      orderDate: values?.orderDate
        ? dayjs(values?.orderDate).format('YYYY-MM-DD')
        : null,
    };
    await crud.create('salesorders', combinedValues, (_err, res) => {
      if (res?.status === 201) {
        toast.success('Added Sales Order Successfully');
        navigate('../view/' + res?.data?.salesOrderId);
        setGetData(true);
        reset();
        setSelectedOrder({});
        setLoading(false);
      } else {
        setLoading(false);
      }
    });
  };

  const handleReset = () => {
    reset();
    setSelectedOrder({});
  };

  return (
    <DashboardLayout title='Create Sales Order'>
      <Grid container spacing={3}>
        {/* Left side - Form */}
        <Grid item xs={12} md={9}>
          <GlassCard className='p-2'>
            <form onSubmit={handleSubmit(submitSalesOrder)}>
              <Grid container spacing={2}>
                <Grid item md={GridSize}>
                  <FmTextField
                    name='salesOrderName'
                    label='Name'
                    control={control}
                    required
                  />
                </Grid>

                <Grid item md={GridSize}>
                  <FmDatePicker
                    name='orderDate'
                    required
                    label='Order Date'
                    control={control}
                  />
                </Grid>

                <Grid item md={GridSize * 2}>
                  <FmSearchableSelect
                    name='salesQuotationId'
                    label='Sales Quotation'
                    apiUrl={'salesquotations/quotation-data'}
                    valueField='salesQuotationId'
                    onChangeProp={(value) => setSelectedOrder(value)}
                    headerField={['Contract', 'Project', 'Sales Quotation']}
                    stackedFields={{
                      salesQuotationCode: [
                        { Code: 'salesQuotationCode' },
                        { Name: 'salesQuotationName' },
                      ],

                      projectName: [
                        { Code: 'projectCode' },
                        { Name: 'projectName' },
                      ],
                      contractName: [
                        { Code: 'contractCode' },
                        { Name: 'contractName' },
                      ],
                    }}
                    labelField={[
                      'salesQuotationCode',
                      'projectName',
                      'contractName',
                    ]}
                    showField={['contractName', 'salesQuotationCode']}
                    control={control}
                  />
                </Grid>

                <Grid item md={GridSize}>
                  <FmSearchableSelect
                    name='contractId'
                    control={control}
                    apiUrl='contracts'
                    valueField='contractId'
                    headerField={['Contract', 'Project']}
                    labelField={['contractCode', 'projectName']}
                    showField={['contractCode', 'projectName']}
                    label='Contract'
                    required
                    stackedFields={{
                      contractCode: [
                        { Code: 'contractCode' },
                        { Name: 'contractName' },
                      ],
                      projectName: [
                        { Code: 'projectCode' },
                        { Name: 'projectName' },
                      ],
                    }}
                    onChangeProp={(value) => setSelectedContract(value)}
                  />
                </Grid>
                <Grid item md={GridSize}>
                  <FmSearchableSelect
                    name='currencyId'
                    control={control}
                    apiUrl='currencies'
                    valueField='currencyId'
                    headerField={['Name']}
                    labelField={['currencyName']}
                    showField={['currencyName']}
                    label={'Currency'}
                  />
                </Grid>
                <Grid item md={GridSize}>
                  <FmSearchableSelect
                    name='paymentTermId'
                    control={control}
                    apiUrl='paymentterms'
                    valueField='paymentTermId'
                    headerField={['Payment Term']}
                    labelField={['paymentTermName']}
                    showField={['paymentTermName']}
                    label='Payment Term'
                  />
                </Grid>

                <Grid item md={GridSize}>
                  <FmTextField
                    name='narration'
                    label='Narration'
                    control={control}
                  />
                </Grid>
                <Grid item md={GridSize}>
                  <FmTextField
                    name='deliveryTerms'
                    label='Delivery Terms'
                    control={control}
                  />
                </Grid>

                <Grid item md={GridSize}>
                  {/*TODO: if We Need Use This Code <FmTextField
                    name='amount'
                    label='Amount'
                    control={control}
                    type='number'
                    onChange={(e) => {
                      setValue('amount', e.target.value);
                      const amountValue = parseFloat(e.target.value) || 0;
                      const vatPercentage = parseFloat(getValues('vat')) || 0;
                      const vatAmount = (amountValue * vatPercentage) / 100;
                      setValue('vatAmount', vatAmount.toFixed(2));
                      setValue(
                        'totalAmount',
                        (amountValue + vatAmount).toFixed(2)
                      );
                    }}
                  /> */}
                  <FmCurrencyTextField
                    name='amount'
                    label='Amount'
                    control={control}
                  />
                </Grid>
                <Grid item md={GridSize}>
                  <FmTextField
                    name='vat'
                    label='VAT (%)'
                    control={control}
                    type='number'
                    onChange={(e) => {
                      setValue('vat', e.target.value);
                      const vatPercentage = parseFloat(e.target.value) || 0;
                      const amountValue =
                        parseFloat(AmountFieldValue ?? '0') || 0;
                      const vatAmount = (amountValue * vatPercentage) / 100;
                      setValue('vatAmount', vatAmount.toFixed(2));
                      setValue(
                        'totalAmount',
                        (amountValue + vatAmount).toFixed(2)
                      );
                    }}
                  />
                </Grid>
                <Grid item md={GridSize}>
                  <Typography
                    variant='caption'
                    color='text.secondary'
                    sx={{
                      display: 'block',
                      mb: 0.25,
                      fontWeight: 'bold',
                      mt: 1,
                    }}
                  >
                    {'Total Amount: ' + (getValues('totalAmount') || 0)}
                  </Typography>
                </Grid>
                <Grid item md={GridSize * 2}>
                  <FmTextField
                    name='remarks'
                    multiline
                    rows={4}
                    label='Remarks'
                    control={control}
                  />
                </Grid>
              </Grid>

              <ActionButtons
                onSubmit={handleSubmit(submitSalesOrder)}
                onReset={handleReset}
                cancelText='Back'
                onCancel={() =>
                  navigate('/financial/accounts-receivable/sales-order')
                }
                submitLoading={loading}
                cancelLoading={false}
                submitText='Create'
              />
            </form>
          </GlassCard>
        </Grid>

        {/* Right side - Contract Details */}
        <Grid item xs={12} md={3}>
          <Box className='relative h-full w-full'>
            <ViewContractDetails selectedContract={selectedContract} />
          </Box>
        </Grid>
      </Grid>
    </DashboardLayout>
  );
};

export default AddSalesOrder;
