import React, { useState, useEffect } from 'react';
import GlassCard from '../../../../components/small/GlassCard.tsx';
import Icons from '../../../../utils/Icon.jsx';
import { Box, Button } from '@mui/material';
import FmMRTDataTable from '../../../../components/FmMRTDataTable.tsx';
import { useForm } from 'react-hook-form';
import { toast } from 'react-toastify';
import Crud_Service from '../../../../apis/CrudService.jsx';
import TableActionButtons from '../../../../components/_form/TableActionButtons.jsx';
import { PurchaseVoucherSchema } from '../../../../utils/CommonvalidationSchemas.tsx';
import { yupResolver } from '@hookform/resolvers/yup';
import { useNavigate } from 'react-router-dom';
import { Icon } from '@iconify/react/dist/iconify.js';
import apiInstance from '../../../../apis/ApiService.jsx';
import useLoader from '../../../../components/helpers/UseLoader.tsx';
import FmPurchaseVoucher from '../../../../components/filter/FmPurchaseVoucher.tsx';
import ColumnPurchaseVoucher from './ColumnPurchaseVoucher.tsx';

const PurchaseVoucherList = (props) => {
  const {
    rows,
    isLoading,
    pagination,
    sorting,
    columnFilters,
    globalFilter,
    tableRecordCounts,
    setPagination,
    setSorting,
    enableRowSelection,
    setColumnFilters,
    setGlobalFilter,
    fetchData,
    setSearchKeyword,
    searchKeyword,
    rowSelection,
    setRowSelection,
    setOnFilter,
    onFilter,
  } = props;
  const [editingRowId, setEditingRowId] = useState(null);
  const { stopEditLoading, createLoading } = useLoader();
  const [selectedRowIds, setSelectedRowIds] = useState([]);
  const crud = new Crud_Service();

  const navigate = useNavigate();

  const { handleSubmit, control } = useForm({
    resolver: yupResolver(PurchaseVoucherSchema),
    mode: 'onChange',
  });

  const handleEdit = (id) => {
    navigate(`edit/${id}`);
  };

  useEffect(() => {
    if (rowSelection) {
      const selectedIds = Object.keys(rowSelection)
        .map((rowId) => rows[rowId]?.purchaseVoucherId)
        .filter(Boolean);

      setSelectedRowIds(selectedIds);
    }
  }, [rowSelection, rows]);

  const handleSendInvoices = async () => {
    const CombinedData = {
      purchaseVoucherIds: selectedRowIds,
    };

    await crud.patch(
      `purchasevouchers?vendorId=${onFilter?.vendorId}`,
      '',
      CombinedData,
      (err, res) => {
        if (res?.status === 200) {
          toast.success('Payment Created Successfully');
          setRowSelection([]);
          navigate(
            `/financial/cash-and-bank/payments/edit/${res?.data?.paymentId}`
          );
          setOnFilter({
            vendorId: 0,
            purchaseVoucherStatusId: 0,
            checkboxSelection: false,
            showChip: false,
          });
          setRowSelection([]);
          fetchData();
        } else {
          stopEditLoading();
        }
      }
    );
  };

  const handleDeleteExtra = () => {
    setRowSelection([]);
  };

  const handleDownloadClick = async (purchaseVoucher) => {
    const { fileId, fileName } = purchaseVoucher.purchaseVoucherAttachments[0];
    try {
      const imageUrl = await apiInstance.getFiles(`files/download/${fileId}`);
      const link = document.createElement('a');
      link.href = imageUrl;
      link.setAttribute('download', fileName);
      document.body.appendChild(link);
      link.click();
      document.body.removeChild(link);
      toast.success(`File "${fileName}" Downloaded Successfully!`);
    } catch (err) {}
  };

  const handleDeleteVoucher = async (props) => {
    await crud.remove(
      'purchasevouchers',
      props?.purchaseVoucherId,
      (_err, res) => {
        if (res?.status === 204) {
          toast.success('Purchase Voucher Deleted Successfully');
          fetchData();
        } else {
        }
      }
    );
  };

  const ActionData = (props) => {
    const actions = [
      {
        name: 'Edit',
        icon: Icons.edit,
        onClick: () => handleEdit(props.purchaseVoucherId),
      },
      {
        name: 'Delete',
        icon: Icons.delete,
        onClick: () => handleDeleteVoucher(props),
        danger: true,
      },
    ];
    if (props?.purchaseVoucherAttachments?.length > 0) {
      actions.unshift({
        name: 'Download',
        icon: Icons.download,
        onClick: () => handleDownloadClick(props),
      });
    }
    return actions;
  };

  const columns = [
    {
      header: 'Actions',
      size: 100,
      Cell: ({ row }) => (
        <Box className='flex gap-2'>
          <TableActionButtons
            Actions={ActionData(row?.original)?.map((action) => ({
              ...action,
              onClick: () => action.onClick(row?.original),
            }))}
          />
        </Box>
      ),
    },
    ...ColumnPurchaseVoucher({ rows: rows }),
  ];

  return (
    <>
      <GlassCard className='flex-1 min-h-0'>
        <FmMRTDataTable
          columns={columns}
          rows={rows}
          rowCount={tableRecordCounts}
          editingRow={editingRowId}
          pagination={pagination}
          sorting={sorting}
          columnFilters={columnFilters}
          globalFilter={globalFilter}
          setPagination={setPagination}
          setSorting={setSorting}
          setColumnFilters={setColumnFilters}
          setGlobalFilter={setGlobalFilter}
          isLoading={isLoading}
          setSearchKeyword={setSearchKeyword}
          searchKeyword={searchKeyword}
          fetchData={fetchData}
          rowSelection={rowSelection}
          setRowSelection={setRowSelection}
          muiTableContainerProps={{
            sx: {
              maxHeight: '100%',
              flex: 1,
            },
          }}
          handleRowDoubleClick={(ev) =>
            handleEdit(ev.original.purchaseVoucherId)
          }
          showSerialNumber={true}
          renderTopToolbarCustomActionsChildrens={
            <FmPurchaseVoucher
              setOnFilter={setOnFilter}
              onFilter={onFilter}
              handleDeleteExtra={handleDeleteExtra}
            />
          }
          enableRowSelection={enableRowSelection}
          muiSelectCheckboxProps={({ row }) => ({
            disabled: row?.original?.purchaseVoucherStatusId !== 4,
          })}
          rightPinning={['purchaseVoucherTotalAmount']}
          enableSelectAll={false}
          rowSelectionCustomProps={
            <Button
              className='whitespace-nowrap py-0 px-3 me-2 h-[31px]'
              variant='contained'
              color='info'
              startIcon={
                <Icon
                  icon='mdi:invoice-text-send-outline'
                  width='24'
                  height='24'
                />
              }
              onClick={handleSendInvoices}
            >
              Generate Payment Voucher
            </Button>
          }
        />
      </GlassCard>
    </>
  );
};

export default PurchaseVoucherList;
