import { Box, Button } from '@mui/material/';
import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useNavigate } from 'react-router-dom';
import MuiAlertModal from '../components/_mui/MuiAlertModal.tsx';
import { userLogout } from '../redux/loginSlice.ts';
import Icons from '../utils/Icon';
import Sidebar from './Sidebar';
import HeaderAppDropdownList from './helpers/HeaderAppDropdownList.tsx';
import PageSubmenu from './page/PageSubmenu';
import Title from './page/Title';
import Header from './Header.jsx';


interface DashboardLayoutProps {
  title: string;
  children: React.ReactNode;
  actionButtons?: React.ReactNode;
  hasSubmenu?: boolean;
  menu?: any;

  enableMenuCollapse?: boolean;
  removeSpace?: boolean;
  titleReverse?: boolean;
}

const DashboardLayout = (props: DashboardLayoutProps) => {
  const {
    title,
    children,
    actionButtons,
    hasSubmenu,
    enableMenuCollapse,
    menu,
    removeSpace,
    titleReverse,
  } = props;
  const navigate = useNavigate();

  const [menuCollapse, setMenuCollapse] = useState(() => {
    const storedValue = localStorage.getItem('menuCollapse');
    return storedValue !== null ? storedValue === 'true' : enableMenuCollapse;
  });

  const [isModalOpen, setIsModalOpen] = useState(false);

  const user = useSelector((state) => state.auth.user);
  const token = useSelector((state) => state.auth.token);
  const dispatch = useDispatch();

  const handleLogout = () => {
    dispatch(userLogout({ user, token, navigate }));
    setIsModalOpen(false);
  };

  const handleOpenModal = () => {
    setIsModalOpen(true);
  };

  const handleCloseModal = (_event, reason) => {
    if (reason !== 'backdropClick') {
      setIsModalOpen(false);
    }
  };

  const HandleMenuCollapse = () => {
    const newState = !menuCollapse;
    setMenuCollapse(newState);
    localStorage.setItem('menuCollapse', newState);
  };

  const ImpersonatorCondition = user?.impersonatorUserName;

  return (
    <>
      <MuiAlertModal
        open={isModalOpen}
        close={handleCloseModal}
        submitOnclick={handleLogout}
        submitText='Confirm Logout'
        desc={
          'Impersonation Mode is accessible to administrators only. Non-admin users will only see a change to their UI if an administrator makes a change'
        }
      />

      <Box
        className='flex flex-col w-full h-screen overflow-hidden sm:h-auto'
        sx={{
          border: ImpersonatorCondition && '5px solid',
          borderColor: ImpersonatorCondition && 'error.main',
        }}
      >
        <Header
          HandleMenuCollapse={HandleMenuCollapse}
          collaspeMenuState={menuCollapse}
        />
        <Box className='w-full flex flex-1 h-full' sx={{ minHeight: 0 }}>
          <Sidebar open={menuCollapse} />
          <Box className='h-full flex-1' sx={{ minWidth: 0 }}>
            <Box
              className='flex flex-col w-full h-full sm:rounded-none sm:h-auto'
              sx={{
                overflow: 'hidden',
              }}
            >
              {/* <HeaderAppDropdownList /> */}
              <Box className='flex flex-col w-full h-full flex-1 min-h-0'>
                {title && (
                  <>
                    <Box>
                      <Title title={title} titleReverse={titleReverse}>
                        {actionButtons}
                      </Title>
                      {hasSubmenu && <PageSubmenu menu={menu} />}
                    </Box>
                  </>
                )}
                <Box
                  className={` ${
                    !removeSpace && 'p-2'
                  }  w-full flex-1  h-full overflow-auto sm:h-auto`}
                  sx={{ minHeight: 0, scrollBehavior: 'smooth' }}
                >
                  {children}
                </Box>
              </Box>

              {ImpersonatorCondition && (
                <Box
                  className='py-1 ps-2  flex justify-between items-center text-white'
                  sx={{
                    bgcolor: 'error.main',
                    paddingRight: '70px',
                  }}
                >
                  <Box className='flex gap-3 items-center'>
                    <p className='text-2xl'>{Icons.impersonator}</p>
                    <p>
                      You are impersonating <b>{user?.impersonatorUserName}</b>
                    </p>
                  </Box>
                  <Button
                    className='text-white font-bold underline p-0'
                    onClick={handleOpenModal}
                  >
                    Leave Impersonator Mode
                  </Button>
                </Box>
              )}
            </Box>
          </Box>
        </Box>
      </Box>
    </>
  );
};

export default DashboardLayout;
