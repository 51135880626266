import React from 'react';
import DashboardLayout from '../../../components/DashboardLayout.tsx';
import GlassCard from '../../../components/small/GlassCard.tsx';
import { useNavigate, useParams } from 'react-router-dom';
import CommonView from '../../../components/helpers/CommonView.jsx';
import DynamicViewFields from '../../../components/helpers/DynamicViewFields.jsx';
import { CreateButton } from '../../../components/small/Buttons.jsx';
import ActionButtons from '../../../components/_form/ActionButtons.tsx';

const ViewAccommodation = () => {
  const navigate = useNavigate();
  const { id } = useParams();

  const excludeKeys = [
    'accommodationImages',
    'accommodationId',
    'locationId',
    'premisePurposeId',
    'premiseTypeId',
    'startDate',
    'updatedDate',
    'status',
  ];

  return (
    <DashboardLayout
      title='View Accommodation'
      actionButtons={
        <CreateButton
          name='Create'
          onClick={() =>
            navigate('/admin/accommodation/accomodations/create')
          }
        />
      }
    >
      <GlassCard className='p-5'>
        <CommonView
          url='accommodations'
          id={id}
          excludeKeys={excludeKeys}
          renderFields={(data, fields) => (
            <DynamicViewFields data={data} fields={fields} />
          )}
        />
        <ActionButtons
          cancelLoading={false}
          cancelText='Go Back'
          submitText='Edit'
          onSubmit={() =>
            navigate(`/admin/accommodation/accomodations/edit/${id}`)
          }
          onCancel={() => navigate('/admin/accommodation/accomodations')}
        />
      </GlassCard>
    </DashboardLayout>
  );
};

export default ViewAccommodation;
