import { Box, CircularProgress } from '@mui/material';
import React from 'react';
import { Icon } from '@iconify/react/dist/iconify.js';

const DatatableLoader = () => {
  return (
    <Box className='flex justify-center items-center h-full w-full bg-white dark:bg-black bg-opacity-80 relative'>
      <CircularProgress size={35} />
    </Box>
  );
};

export default DatatableLoader;
