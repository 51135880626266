import React, { useEffect } from 'react';
import { Box, Button } from '@mui/material';
import FmMRTDataTable from '../../../components/FmMRTDataTable.tsx';
import { Controller, useForm } from 'react-hook-form';
import FmTextField from '../../../components/_mui/FmTextField.tsx';
import FmAutoComplete from '../../../components/_mui/FmAutoComplete.tsx';
import MuiDatePickerOne from '../../../components/_mui/MuiDatePickerOne.tsx';
import dayjs from 'dayjs';
import FmSearchableSelect from '../../../components/_mui/FmSearchableSelect.tsx';
import TableActionButtons from '../../../components/_form/TableActionButtons.jsx';
import GlassCard from '../../../components/small/GlassCard.tsx';
import FmTimePicker from '../../../components/_mui/FmTimePicker.tsx';
import FmViewDateField from '../../../components/_mui/FmViewDateField.tsx';
import { Icon } from '@iconify/react/dist/iconify.js';
import { toast } from 'react-toastify';
import { useNavigate } from 'react-router-dom';

const ListInspectionReport = (props) => {
  const {
    rows,
    tableRecordCounts,
    editingRowId,
    editingRowData,
    setEditingRowData,
    setEditingRowId,
    fetchData,
    handleUpdateInspectionReport,
    handleCancel,
    pagination,
    sorting,
    setPagination,
    setSorting,
    columnFilters,
    globalFilter,
    setColumnFilters,
    setGlobalFilter,
    isLoading,
    setSearchKeyword,
    searchKeyword,
    buildings,
    months,
    crud,
  } = props;
  const navigate = useNavigate();
  const { handleSubmit, control, setValue } = useForm();

  const handleViewClick = async (props) => {
    navigate(`view/${props?.inspectionReportId}`);
  };

  const handleEdit = (row) => {
    setEditingRowId(row?.inspectionReportId);
    setEditingRowData({ ...row });
  };

  const handleDelete = async (props) => {
    await crud.remove(
      'inspectionreports',
      props?.inspectionReportId,
      (_err, res) => {
        if (res?.status === 204) {
          toast.success('Inspection Report Deleted Successfully');
          fetchData();
        } else {
        }
      }
    );
  };

  const ActionData = [
    {
      name: 'View Details',
      icon: <Icon icon='solar:eye-linear' />,
      onClick: (props) => handleViewClick(props),
    },
    {
      name: 'Edit',
      icon: <Icon icon='solar:pen-2-broken' />,
      onClick: (props) => handleEdit(props),
    },
    {
      name: 'Delete',
      icon: <Icon icon='solar:trash-bin-minimalistic-broken' />,
      onClick: (props) => handleDelete(props),
      danger: true,
    },
  ];

  useEffect(() => {
    if (editingRowData) {
      Object.entries({
        ...editingRowData,
      }).forEach(([key, value]) => {
        setValue(key, value);
      });
    }
  }, [editingRowData, setValue]);

  const columns = [
    {
      header: 'Actions',
      enableColumnPinning: true,
      enableEditing: false,
      enableSorting: false,
      Cell: ({ row }) => (
        <Box className='flex gap-2'>
          {editingRowId === row?.original?.inspectionReportId ? (
            <>
              <Button onClick={handleSubmit(handleUpdateInspectionReport)}>
                Save
              </Button>
              <Button onClick={handleCancel}>Cancel</Button>
            </>
          ) : (
            <TableActionButtons
              Actions={ActionData?.map((action) => ({
                ...action,
                onClick: () => action.onClick(row?.original),
              }))}
            />
          )}
        </Box>
      ),
    },
    {
      accessorKey: 'companyId',
      header: 'Companies',
      width: 150,
      enableEditing: true,
      Cell: ({ cell }) => {
        return editingRowId === cell?.row?.original?.inspectionReportId ? (
          <FmSearchableSelect
            name='companyId'
            control={control}
            apiUrl='companies'
            valueField='companyId'
            labelField={['companyCode', 'companyName']}
            showField={['companyCode', 'companyName']}
            pageSize={50}
            defaultValue={{
              companyId: cell?.row?.original?.companyId,
              companyName: cell?.row?.original?.companyName,
            }}
          />
        ) : (
          cell?.row?.original?.companyName
        );
      },
    },
    {
      accessorKey: 'clientId',
      header: 'Clients',
      width: 150,
      enableEditing: true,
      Cell: ({ cell }) => {
        return editingRowId === cell?.row?.original?.inspectionReportId ? (
          <FmSearchableSelect
            name='clientId'
            control={control}
            apiUrl='clients'
            valueField='clientId'
            labelField={['clientCode', 'clientName']}
            showField={['clientCode', 'clientName']}
            pageSize={50}
            defaultValue={{
              clientId: cell?.row?.original?.clientId,
              clientName: cell?.row?.original?.clientName,
            }}
          />
        ) : (
          cell?.row?.original?.clientName
        );
      },
    },
    {
      accessorKey: 'projectId',
      header: 'Projects',
      width: 150,
      enableEditing: true,
      Cell: ({ cell }) => {
        return editingRowId === cell?.row?.original?.inspectionReportId ? (
          <FmSearchableSelect
            name='projectId'
            control={control}
            apiUrl='projects'
            valueField='projectId'
            labelField={['projectCode', 'projectName']}
            showField={['projectCode', 'projectName']}
            pageSize={50}
            defaultValue={{
              projectId: cell?.row?.original?.projectId,
              projectName: cell?.row?.original?.projectName,
            }}
          />
        ) : (
          cell?.row?.original?.projectName
        );
      },
    },
    {
      accessorKey: 'contractId',
      header: 'Contracts',
      width: 150,
      enableEditing: true,
      Cell: ({ cell }) => {
        return editingRowId === cell?.row?.original?.inspectionReportId ? (
          <FmSearchableSelect
            name='contractId'
            control={control}
            apiUrl='contracts'
            valueField='contractId'
            labelField={['contractCode', 'contractName']}
            showField={['contractCode', 'contractName']}
            pageSize={50}
            defaultValue={{
              contractId: cell?.row?.original?.contractId,
              contractName: cell?.row?.original?.contractName,
            }}
          />
        ) : (
          cell?.row?.original?.contractName
        );
      },
    },
    {
      accessorKey: 'locationId',
      header: 'Locations',
      width: 150,
      enableEditing: true,
      Cell: ({ cell }) => {
        return editingRowId === cell?.row?.original?.inspectionReportId ? (
          <FmSearchableSelect
            name='locationId'
            control={control}
            apiUrl='locations'
            valueField='locationId'
            labelField={['locationCode', 'locationName']}
            showField={['locationCode', 'locationName']}
            pageSize={50}
            defaultValue={{
              locationId: cell?.row?.original?.locationId,
              locationName: cell?.row?.original?.locationName,
            }}
          />
        ) : (
          cell?.row?.original?.locationName
        );
      },
    },
    {
      accessorKey: 'attendeesId',
      header: 'Attendees',
      width: 150,
      enableEditing: true,
      Cell: ({ cell }) => {
        return editingRowId === cell?.row?.original?.inspectionReportId ? (
          <FmSearchableSelect
            name='attendeesId'
            control={control}
            apiUrl='loginusers'
            valueField='loginUserId'
            labelField={['emailId', 'loginUserName']}
            showField={['emailId', 'loginUserName']}
            pageSize={50}
            defaultValue={{
              loginUserId: cell?.row?.original?.attendeesId,
              loginUserName: cell?.row?.original?.attendeeName,
            }}
          />
        ) : (
          cell?.row?.original?.attendeeName
        );
      },
    },
    {
      accessorKey: 'conductedBy',
      header: 'Conducted By',
      width: 150,
      enableEditing: true,
      Cell: ({ cell }) => {
        return editingRowId === cell?.row?.original?.inspectionReportId ? (
          <FmSearchableSelect
            name='conductedBy'
            control={control}
            apiUrl='loginusers'
            valueField='loginUserId'
            labelField={['emailId', 'loginUserName']}
            showField={['emailId', 'loginUserName']}
            pageSize={50}
            defaultValue={{
              emailId: cell?.row?.original?.conductedBy,
              loginUserName: cell?.row?.original?.conductedByName,
            }}
          />
        ) : (
          cell?.row?.original?.conductedByName
        );
      },
    },
    {
      accessorKey: 'preparedBy',
      header: 'Prepared By',
      width: 150,
      enableEditing: true,
      Cell: ({ cell }) => {
        return editingRowId === cell?.row?.original?.inspectionReportId ? (
          <FmSearchableSelect
            name='preparedBy'
            control={control}
            apiUrl='loginusers'
            valueField='loginUserId'
            labelField={['emailId', 'loginUserName']}
            showField={['emailId', 'loginUserName']}
            pageSize={50}
            defaultValue={{
              emailId: cell?.row?.original?.preparedBy,
              loginUserName: cell?.row?.original?.preparedByName,
            }}
          />
        ) : (
          cell?.row?.original?.preparedByName
        );
      },
    },
    {
      accessorKey: 'buildingId',
      header: 'Buildings',
      width: 150,
      enableEditing: true,
      Cell: ({ cell }) => {
        return editingRowId === cell?.row?.original?.inspectionReportId ? (
          <FmAutoComplete
            name='buildingId'
            control={control}
            options={buildings}
            label='Buildings'
            displayField='buildingName'
            optionFields={['buildingName']}
            valueKey='buildingId'
          />
        ) : (
          cell?.row?.original?.buildingName
        );
      },
    },
    {
      accessorKey: 'inspectorId',
      header: 'Inspector',
      width: 150,
      enableEditing: true,
      Cell: ({ cell }) => {
        return editingRowId === cell?.row?.original?.inspectionReportId ? (
          <FmSearchableSelect
            name='inspectorId'
            control={control}
            apiUrl='loginusers'
            valueField='loginUserId'
            labelField={['emailId', 'loginUserName']}
            showField={['emailId', 'loginUserName']}
            pageSize={50}
            defaultValue={{
              emailId: cell?.row?.original?.inspectorId,
              loginUserName: cell?.row?.original?.inspectorName,
            }}
          />
        ) : (
          cell?.row?.original?.inspectorName
        );
      },
    },
    {
      accessorKey: 'monthId',
      header: 'Months',
      width: 150,
      enableEditing: true,
      Cell: ({ cell }) => {
        return editingRowId === cell?.row?.original?.inspectionReportId ? (
          <FmAutoComplete
            name='monthId'
            control={control}
            options={months}
            label='Months'
            displayField='monthName'
            optionFields={['monthName']}
            valueKey='monthId'
          />
        ) : (
          cell?.row?.original?.monthName
        );
      },
    },
    {
      accessorKey: 'reportMangerId',
      header: 'Report Manager',
      width: 150,
      enableEditing: true,
      Cell: ({ cell }) => {
        return editingRowId === cell?.row?.original?.inspectionReportId ? (
          <FmSearchableSelect
            name='reportMangerId'
            control={control}
            apiUrl='loginusers'
            valueField='loginUserId'
            labelField={['emailId', 'loginUserName']}
            showField={['emailId', 'loginUserName']}
            pageSize={50}
            defaultValue={{
              emailId: cell?.row?.original?.reportMangerId,
              loginUserName: cell?.row?.original?.reportMangerName,
            }}
          />
        ) : (
          cell?.row?.original?.reportMangerName
        );
      },
    },
    {
      accessorKey: 'verifiedBy',
      header: 'Verified By',
      width: 150,
      enableEditing: true,
      Cell: ({ cell }) => {
        return editingRowId === cell?.row?.original?.inspectionReportId ? (
          <FmSearchableSelect
            name='verifiedBy'
            control={control}
            apiUrl='loginusers'
            valueField='loginUserId'
            labelField={['emailId', 'loginUserName']}
            showField={['emailId', 'loginUserName']}
            pageSize={50}
            defaultValue={{
              emailId: cell?.row?.original?.verifiedBy,
              loginUserName: cell?.row?.original?.verifiedByName,
            }}
          />
        ) : (
          cell?.row?.original?.verifiedByName
        );
      },
    },
    {
      accessorKey: 'referenceNumber',
      header: 'reference Number',
      enableEditing: true,
      Cell: ({ cell }) => {
        return editingRowId === cell?.row?.original?.inspectionReportId ? (
          <FmTextField name='referenceNumber' control={control} />
        ) : (
          cell?.row?.original?.referenceNumber
        );
      },
    },
    {
      accessorKey: 'occupancy',
      header: 'Occupancy',
      enableEditing: true,
      Cell: ({ cell }) => {
        return editingRowId === cell?.row?.original?.inspectionReportId ? (
          <FmTextField name='occupancy' control={control} />
        ) : (
          cell?.row?.original?.occupancy
        );
      },
    },

    {
      accessorKey: 'inspectedArea',
      header: 'Inspected Area',
      enableEditing: true,
      Cell: ({ cell }) => {
        return editingRowId === cell?.row?.original?.inspectionReportId ? (
          <FmTextField name='inspectedArea' control={control} />
        ) : (
          cell?.row?.original?.inspectedArea
        );
      },
    },
    {
      accessorKey: 'inspectionStartDate',
      header: 'Inspection Start Date',
      enableEditing: true,
      Cell: ({ row }) => {
        return editingRowId === row?.original?.inspectionReportId ? (
          <Controller
            name='inspectionStartDate'
            control={control}
            defaultValue={editingRowData?.inspectionStartDate}
            render={({ field }) => (
              <MuiDatePickerOne
                {...field}
                format='DD-MM-YYYY'
                value={field?.value ? dayjs(field?.value) : null}
                onChange={field?.onChange}
              />
            )}
          />
        ) : row?.original?.inspectionStartDate ? (
          FmViewDateField({ value: row?.original?.inspectionStartDate })
        ) : (
          ''
        );
      },
    },
    {
      accessorKey: 'inspectionEndDate',
      header: 'Inspection End Date',
      enableEditing: true,
      Cell: ({ row }) => {
        return editingRowId === row?.original?.inspectionReportId ? (
          <Controller
            name='inspectionEndDate'
            control={control}
            defaultValue={editingRowData?.inspectionEndDate}
            render={({ field }) => (
              <MuiDatePickerOne
                {...field}
                format='DD-MM-YYYY'
                value={field?.value ? dayjs(field?.value) : null}
                onChange={field?.onChange}
              />
            )}
          />
        ) : row?.original?.inspectionEndDate ? (
          FmViewDateField({ value: row?.original?.inspectionEndDate })
        ) : (
          ''
        );
      },
    },
    {
      accessorKey: 'time',
      header: 'Time',
      enableEditing: true,
      Cell: ({ cell }) => {
        const parsedTime = dayjs(cell?.row?.original?.time, 'HH:mm:ss');

        return editingRowId === cell?.row?.original?.inspectionReportId ? (
          <FmTimePicker
            name='time'
            control={control}
            label='Time'
            defaultValue={
              cell?.row?.original?.time || dayjs() // fallback to current time if invalid
            }
          />
        ) : (
          parsedTime.format('hh:mm A')
        );
      },
    },

    {
      accessorKey: 'remarks',
      header: 'Remarks',
      enableEditing: true,
      Cell: ({ cell }) => {
        return editingRowId === cell?.row?.original?.inspectionReportId ? (
          <FmTextField
            name='remarks'
            control={control}
            label='Remarks'
            defaultValue={cell?.row?.original?.remarks}
            multiline={true}
            rows={2}
            maxRows={3}
          />
        ) : (
          cell?.row?.original?.remarks
        );
      },
    },
  ];

  return (
    <GlassCard className='mt-3'>
      <FmMRTDataTable
        columns={columns}
        enableRowSelection={false}
        rows={rows}
        rowCount={tableRecordCounts}
        editingRow={editingRowId}
        pagination={pagination}
        sorting={sorting}
        columnFilters={columnFilters}
        globalFilter={globalFilter}
        setPagination={setPagination}
        setSorting={setSorting}
        setColumnFilters={setColumnFilters}
        setGlobalFilter={setGlobalFilter}
        isLoading={isLoading}
        setSearchKeyword={setSearchKeyword}
        searchKeyword={searchKeyword}
      />
    </GlassCard>
  );
};

export default ListInspectionReport;
