import { yupResolver } from "@hookform/resolvers/yup";
import { Icon } from "@iconify/react";
import { Box, Button } from "@mui/material";
import React, { useEffect, useRef, useState } from "react";
import { useForm } from "react-hook-form";
import { useParams } from "react-router-dom";
import { toast } from "react-toastify";
import Crud_Service from "../../../../apis/CrudService";
import TableActionButtons from "../../../../components/_form/TableActionButtons.jsx";
import FmAutoComplete from "../../../../components/_mui/FmAutoComplete.tsx";
import FmTextField from "../../../../components/_mui/FmTextField.tsx";
import MuiDialogOne from "../../../../components/_mui/MuiDialogOne.jsx";
import CommonView from "../../../../components/helpers/CommonView.jsx";
import DynamicViewFields from "../../../../components/helpers/DynamicViewFields.jsx";
import FmSearchableSelect from "../../../../components/_mui/FmSearchableSelect.tsx";
import apiInstance from "../../../../apis/ApiService.jsx";
import dayjs from "dayjs";
import { useHotkeys } from "react-hotkeys-hook";
import useTableLogic from "../../../../components/helpers/MRTUseTableLogic.tsx";
import useCommonFetchApi from "../../../../components/helpers/useCommonFetchApi.tsx";
import GlassCard from "../../../../components/small/GlassCard.tsx";
import { materialRequestItemSchema } from "../../../../utils/CommonvalidationSchemas.tsx";
import FmMRTDataTable from "./../../../../components/FmMRTDataTable.tsx";
const CartMaterialRequest = ({ locationId }) => {
  const crud = new Crud_Service();
  const { id } = useParams();
  const [rowState, setRowState] = useState({
    newRowId: null,
    isCreatingRow: false,
  });
  const {
    handleSubmit,
    reset,
    setValue,
    getValues,
    control,
    formState: { isValid },
  } = useForm({
    resolver: yupResolver(materialRequestItemSchema),
    mode: "all",
  });
  const [editingRowId, setEditingRowId] = useState(null);
  const [editingRowData, setEditingRowData] = useState(null);
  const [viewdetails, setViewdetails] = useState(null);
  const [availableQuantity, setAvailableQuantity] = useState(0);

  const {
    rows,
    setRows,
    isLoading,
    pagination,
    sorting,
    columnFilters,
    globalFilter,
    tableRecordCounts,
    setSearchKeyword,
    searchKeyword,
    setPagination,
    setSorting,
    setColumnFilters,
    setGlobalFilter,
    fetchData,
  } = useTableLogic(
    `materialrequisitionitems?materialrequisitionid=${id}&sortDirection=asc`
  );

  const [selectedItem, setSelectedItem] = useState(null);
  const { data: item } = useCommonFetchApi("item");
  const firstColumnInputRef = useRef(null);
  const [stateError, setStateError] = useState(false);
  const [editingRowIndex, setEditingRowIndex] = useState(null);
  const [currentRowIndex, setCurrentRowIndex] = useState(0);
  const [newRowCreated, setNewRowCreated] = useState(false);
  const [loader, setLoader] = useState(false);

  useEffect(() => {
    if (!selectedItem || !selectedItem?.itemId) return;

    const { itemId, brandName, modelName } = selectedItem;

    // Set the brand name when selectedItem changes
    setValue("brandName", brandName || "");
    setValue("categoryId", modelName || "");

    // Fetch the item details
    const fetchItemDetails = async () => {
      crud.getAll(
        "inventoryitems",
        { storelocationId: locationId, itemId },
        (err, res) => {
          if (err) {
            toast.error("Failed to fetch item details. Please try again.");
            return;
          }
          if (res?.status === 200) {
            setAvailableQuantity(res?.data);
            setValue(
              "onHandQuantity",
              res?.data?.data[0]?.availableQuantity || 0
            );
          } else {
            toast.error("Failed to fetch item details. Please try again.");
          }
        }
      );
    };

    fetchItemDetails();
  }, [selectedItem, locationId]);

  const { data: salesOrder } = useCommonFetchApi("salesorders");

  const handleDelete = async (props) => {
    await crud.remove(
      "materialrequisitionitems",
      props?.materialRequisitionItemId,
      (_err, res) => {
        if (res?.status === 204) {
          toast.success("Material Requisition Item Deleted Successfully");
          fetchData();
        } else {
        }
      }
    );
  };

  const handleViewClick = (props) => {
    setViewdetails(props);
  };

  const handleEdit = (row) => {
    reset();
    if (rowState?.newRowId) {
      setRows((prevRows) => {
        if (prevRows?.length > 0) {
          return prevRows?.slice(0, -1); // Removes the last row
        }
        return prevRows; // If no rows exist, return the current state
      });
      setRowState((prevState) => ({
        ...prevState,
        newRowId: null,
        isCreatingRow: false,
      }));
      // setEditingRowIndex(null);
    }
    setEditingRowId(row?.materialRequisitionItemId);
    Object.entries({
      ...row,
    }).forEach(([key, value]) => {
      setValue(key, value);
    });
    setEditingRowData({ ...row });
  };

  const ActionData = [
    {
      name: "Delete",
      icon: <Icon icon="mi:delete" />,
      onClick: (props) => handleDelete(props),
      danger: true,
    },
  ];

  const handleCancel = (row) => {
    if (row?.original?.materialRequisitionItemId === rowState?.newRowId) {
      setRows((prevRows) => {
        if (prevRows?.length > 0) {
          return prevRows?.slice(0, -1); // Removes the last row
        }
        return prevRows; // If no rows exist, return the current state
      });
      setRowState((prevState) => ({
        ...prevState,
        newRowId: null,
        isCreatingRow: false,
      }));
    } else {
      setEditingRowId(null);
      setEditingRowIndex(null);
    }
    reset();
  };

  useHotkeys(
    "Enter",
    () => handleAddNewRow(),
    {
      enableOnTags: ["INPUT", "TEXTAREA"], // Optional: Enable hotkey only when focused on specific tags
    },
    [editingRowIndex, rows, stateError, loader]
  );

  useHotkeys(
    "Tab",
    (event) => {
      if (event.key !== "Tab") return;
      if (stateError) {
        event.preventDefault();
        return;
      }
      const totalRows = rows.length;
      const nextRowIndex = editingRowIndex + 1;

      if (
        nextRowIndex < totalRows &&
        stateError === false &&
        loader === false
      ) {
        setCurrentRowIndex(nextRowIndex);
        handleEdit(rows[nextRowIndex]);
        setEditingRowIndex(nextRowIndex);
      }
    },
    [editingRowIndex, rows, stateError, loader, isValid]
  );

  useEffect(() => {
    if (editingRowIndex !== null && firstColumnInputRef.current && !isLoading) {
      firstColumnInputRef.current.focus();
    }
  }, [editingRowIndex, isLoading]);

  const previousValues = useRef(getValues()); // store previous values to compare with

  const handleBlur = () => {
    const currentValues = getValues();
    const value = getValues("quantity");
    if (!value) {
      // Refocus the field if empty
      firstColumnInputRef.current.focus();
      return;
    }
    // Compare the previous values with the current values
    const hasChanges = Object.keys(currentValues).some(
      (key) => currentValues[key] !== previousValues.current[key]
    );

    if ((hasChanges && isValid) || (hasChanges && editingRowId)) {
      apiCallAsync(currentValues); // Call the API with the new values
      previousValues.current = currentValues; // Update previous values
    }
  };

  const apiCallAsync = async (values) => {
    setLoader(true);
    const convertedValues = {
      ...values,
      materialRequisitionId: id,
      quantity: Number(values?.quantity),
      materialRequisitionItemStatusId:
        availableQuantity < values?.quantity ? 1 : 2,
    };

    const updatedCombinedData = {
      ...values,
      categoryId: Number(selectedItem?.modelId),
      materialRequisitionId: Number(id),
      quantity: Number(values?.quantity),
      costCenterId: Number(selectedItem?.costCenterId) || null,
      unitofmeasurementId: Number(selectedItem?.unitOfMeasurementId),
      brandId: Number(selectedItem?.brandId),
      materialRequisitionItemStatusId:
        availableQuantity < values?.quantity ? 1 : 2,
      status: 2,
    };

    if (editingRowId === null) {
      await crud.create(
        "materialrequisitionitems",
        convertedValues,
        (err, res) => {
          if (res?.status === 201) {
            setEditingRowId(res?.data?.materialRequisitionItemId);
            setValue(
              "materialRequisitionItemId",
              res?.data?.materialRequisitionItemId
            );
          } else {
          }
        }
      );
    } else {
      await crud.update(
        "materialrequisitionitems",
        editingRowId,
        updatedCombinedData,
        (err, res) => {
          if (res?.status === 200) {
            if (rows.some((row) => row.isNew)) {
              const updatedRow = res?.data;
              const updatedRows = [...rows];
              updatedRows[editingRowIndex] = {
                ...updatedRow,
                isEditable: true,
                isNew: true,
              };
              setRows(updatedRows);
              setStateError(false);
              setLoader(false);
            } else {
              setStateError(false);
              setLoader(false);
              const updatedRow = res?.data;
              const updatedRows = [...rows];

              updatedRows[editingRowIndex] = updatedRow;

              setRows(updatedRows);
            }
          } else {
            setStateError(true);
          }
        }
      );
    }
  };
  

  const handleBlurAndFetch = async () => {
    setLoader(true);
    const updatedRow = getValues(); // Get the latest values from the form
    const updatedRows = [...rows]; // Clone the rows[0] to avoid direct mutation

    // Update the specific row in the cloned array
    updatedRows[editingRowIndex] = updatedRow;

    setRows(updatedRows);

    const values = getValues();

    const updateCombinedData = {
      ...values, // Spread the rest of the values
      categoryId: Number(selectedItem?.modelId),
      materialRequisitionId: Number(id),
      quantity: Number(values?.quantity),
      costCenterId: Number(selectedItem?.costCenterId) || null,
      unitofmeasurementId: Number(selectedItem?.unitOfMeasurementId),
      brandId: Number(selectedItem?.brandId),
      materialRequisitionItemStatusId:
        availableQuantity < values?.quantity ? 1 : 2,
      status: 2,
    };
    await crud.update(
      "materialrequisitionitems",
      editingRowId,
      updateCombinedData,
      (err, res) => {
        if (res?.status === 200) {
          const totalRows = rows.length;
          if (editingRowIndex <= totalRows && rows.some((row) => row.isNew)) {
            setNewRowCreated(true);
            const updatedRow = res?.data;
            const updatedRows = [...rows];
            updatedRows[editingRowIndex] = {
              ...updatedRow,
              isEditable: true,
              isNew: true,
            };
            setRows(updatedRows);
            handleAddNewRow();
          } else {
            const updatedRow = res?.data;
            const updatedRows = [...rows];
            updatedRows[editingRowIndex] = {
              ...updatedRow,
              isEditable: false,
              isNew: false,
            };

            setRows(updatedRows);
            setStateError(false);
            setLoader(false);
          }
        } else {
          setStateError(true);
        }
      }
    );
  };

  const handleCancelAndAddNewRow = () => {
    const newId = "new_row_" + Date.now();
    reset();
    setEditingRowData(null);
    setEditingRowId(null);
    setRowState({
      newRowId: newId,
      isCreatingRow: true,
    });
    setEditingRowIndex(
      editingRowIndex === rows.length ? editingRowIndex + 1 : rows.length
    );
    setNewRowCreated(false);
    setRows((prevRows) => [
      ...prevRows,
      {
        materialRequisitionItemId: newId,
        materialRequisitionId: id,
        salesOrderId: "",
        brandId: "",
        categoryId: "",
        unitOfMeasurementId: "",
        itemId: "",
        unitOfMeasurementId: "",
        costCenterId: "",
        quantity: "",
        remarks: "",
        description: "",
        isEditable: true,
        isNew: true,
      },
    ]);
    reset();
  };

  const handleUpdateMaterialrequestItems = async (values) => {
    const convertedValues = {
      ...values,
      costCenterId: selectedItem?.costCenterId,
      quantity: Number(values.quantity),
      unitofmeasurementId: selectedItem?.unitOfMeasurementId,
      brandId: editingRowData?.brandId,
      onHandQuantity: Number(editingRowData?.onHandQuantity),
      materialRequisitionItemStatusId:
        availableQuantity < values.quantity ? 1 : 2,
      status: 2,
    };

    const combinedData = {
      materialRequisitionId: id,
      materialRequisitionItems: [convertedValues],
    };
    const updateCombinedData = {
      ...values,
      brandId: editingRowData?.brandId,
      onHandQuantity: Number(editingRowData?.onHandQuantity),
      materialRequisitionItemStatusId:
        availableQuantity < values.quantity ? 1 : 2,
      materialRequisitionId: id,
      status: 2,
    };
    if (rowState?.newRowId) {
      await crud.create(
        "materialrequisitionitems",
        combinedData,
        (_err, res) => {
          if (res?.status === 201) {
            toast.success("Material Requisition Item Created Successfully");
            fetchData();
            setRowState((prevState) => ({
              ...prevState,
              newRowId: null,
              isCreatingRow: false,
            }));
          } else {
          }
        }
      );
    } else {
      await crud.update(
        "materialrequisitionitems",
        editingRowId,
        updateCombinedData,
        (_err, res) => {
          if (res?.status === 200) {
            toast.success("Material Requisition Item Updated Successfully");
            fetchData();
            setEditingRowData(null);
            setEditingRowId(null);
          } else {
          }
        }
      );
    }
  };

  const columns = [
    {
      accessorKey: "quantity",
      header: "Quantity",
      enableEditing: true,
      Cell: ({ row }) => {
        const isNewRow =
          row?.original?.materialRequisitionItemId === rowState?.newRowId;
        const isEditing =
          editingRowId === row?.original?.materialRequisitionItemId || isNewRow;
        return isEditing ? (
          <FmTextField
            name="quantity"
            label="Quantity"
            control={control}
            onBlur={handleBlur}
            inputRef={firstColumnInputRef}
          />
        ) : (
          row?.original?.quantity
        );
      },
    },
    {
      accessorKey: "salesOrderId",
      header: "Sales Order",
      enableEditing: true,
      Cell: ({ row }) => {
        const isNewRow =
          row?.original?.materialRequisitionItemId === rowState?.newRowId;
        const isEditing =
          editingRowId === row?.original?.materialRequisitionItemId || isNewRow;
        return isEditing ? (
          <FmSearchableSelect
            name="salesOrderId"
            control={control}
            apiUrl='salesorders'
            valueField='salesOrderId'
            headerField={['Name', 'Code']}
            labelField={['salesOrderName','salesOrderCode']}
            showField={['salesOrderName','salesOrderCode']}
            label={"Sales Order"}
            onBlur={handleBlur}
            defaultValue={{
              salesOrderId: row?.original?.mrTypeNo ||null,
              salesOrderCode:row?.original?.salesOrderCode|| "",
            }}
          />
        ) : (
          row?.original?.salesOrderCode || ""
        );
      },
    },
    {
      accessorKey: "itemId",
      header: "Item",
      enableEditing: true,
      Cell: ({ row }) => {
        const isNewRow =
          row?.original?.materialRequisitionItemId === rowState?.newRowId;
        const isEditing =
          editingRowId === row?.original?.materialRequisitionItemId || isNewRow;
        return isEditing ? (
          <FmAutoComplete
            name="itemId"
            control={control}
            options={item}
            displayField="itemName"
            optionFields={["itemName"]}
            valueKey="itemId"
            onChangeValue={true}
            onChange={(ev) => setSelectedItem(ev)}
            onBlur={handleBlur}
          />
        ) : (
          row?.original?.itemName || ""
        );
      },
    },
    {
      accessorKey: "brandName",
      header: "Brand",
      enableEditing: false,
      Cell: ({ row }) => {
        const isNewRow =
          row?.original?.materialRequisitionItemId === rowState?.newRowId;
        const isEditing =
          editingRowId === row?.original?.materialRequisitionItemId || isNewRow;
        return isEditing ? (
          <FmTextField
            name="brandName"
            label="Brand"
            disabled={true}
            control={control}
            onBlur={handleBlur}
          />
        ) : (
          row?.original?.brandName
        );
      },
    },

    {
      accessorKey: "unitOfMeasurementId",
      header: "Unit Of Measurement",
      enableEditing: true,
      Cell: ({ row }) => {
        const isNewRow =
          row?.original?.materialRequisitionItemId === rowState?.newRowId;
        const isEditing =
          editingRowId === row?.original?.materialRequisitionItemId || isNewRow;
        return isEditing ? (
          <FmSearchableSelect
            name="unitOfMeasurementId"
            control={control}
            disabled={true}
            apiUrl="unitofmeasurements"
            valueField={"unitOfMeasurementId"}
            labelField={["unitOfMeasurementName"]}
            showField={["unitOfMeasurementName"]}
            label={"Unit Of Measurement"}
            onBlur={handleBlur}
            defaultValue={{
              unitOfMeasurementId:
                selectedItem?.unitOfMeasurementId ||
                row?.original?.unitOfMeasurementId,
              unitOfMeasurementName:
                selectedItem?.unitOfMeasurementName ||
                row?.original?.unitOfMeasurementName,
            }}
          />
        ) : (
          row?.original?.unitOfMeasurementName || ""
        );
      },
    },
    {
      accessorKey: "costCenterId",
      header: "Cost Center",
      enableEditing: true,
      Cell: ({ row }) => {
        const isNewRow =
          row?.original?.materialRequisitionItemId === rowState?.newRowId;
        const isEditing =
          editingRowId === row?.original?.materialRequisitionItemId || isNewRow;
        return isEditing ? (
          <FmSearchableSelect
            name="costCenterId"
            control={control}
            apiUrl="costcenters"
            disabled={true}
            valueField={"costCenterId"}
            labelField={["costCenterName"]}
            showField={["costCenterName"]}
            label={"Cost Center"}
            onBlur={handleBlur}
            defaultValue={{
              costCenterId:
                selectedItem?.costCenterId || row?.original?.costCenterId,
              costCenterName:
                selectedItem?.costCenterName || row?.original?.costCenterName,
            }}
          />
        ) : (
          row?.original?.costCenterName || ""
        );
      },
    },
    {
      accessorKey: "categoryId",
      header: "Category",
      enableEditing: true,
      Cell: ({ row }) => {
        const isNewRow =
          row?.original?.materialRequisitionItemId === rowState?.newRowId;
        const isEditing =
          editingRowId === row?.original?.materialRequisitionItemId || isNewRow;
        return isEditing ? (
          <FmTextField
            name="categoryId"
            control={control}
            label="Category"
            disabled
          />
        ) : (
          row?.original?.categoryName || ""
        );
      },
    },
    {
      accessorKey: "onHandQuantity",
      header: "On Hand Quantity",
      enableEditing: false,
      Cell: ({ row }) => {
        const isNewRow =
          row?.original?.materialRequisitionItemId === rowState?.newRowId;
        const isEditing =
          editingRowId === row?.original?.materialRequisitionItemId || isNewRow;
        return isEditing ? (
          <FmTextField
            name="onHandQuantity"
            label="On Hand Quantity"
            disabled={true}
            control={control}
          />
        ) : (
          row?.original?.onHandQuantity || ""
        );
      },
    },
    {
      accessorKey: "remarks",
      header: "Remarks",
      enableEditing: true,
      Cell: ({ row }) => {
        const isNewRow =
          row?.original?.materialRequisitionItemId === rowState?.newRowId;
        const isEditing =
          editingRowId === row?.original?.materialRequisitionItemId || isNewRow;
        return isEditing ? (
          <FmTextField
            name="remarks"
            control={control}
            multiline={true}
            rows={2}
            maxRows={3}
            onBlur={handleBlur}
          />
        ) : (
          row?.original?.remarks
        );
      },
    },
    {
      accessorKey: "description",
      header: "Description",
      enableEditing: true,
      Cell: ({ row }) => {
        const isNewRow =
          row?.original?.materialRequisitionItemId === rowState?.newRowId;
        const isEditing =
          editingRowId === row?.original?.materialRequisitionItemId || isNewRow;
        return isEditing ? (
          <FmTextField
            name="description"
            control={control}
            multiline={true}
            rows={2}
            maxRows={3}
            onBlur={handleBlurAndFetch}
          />
        ) : (
          row?.original?.description
        );
      },
    },
    {
      header: "Action",
      enableColumnPinning: false,
      enableEditing: false,
      enableSorting: false,
      Cell: ({ row, table }) => {
        const isNewRow =
          row?.original?.materialRequisitionItemId === rowState?.newRowId;
        const isEditing =
          editingRowId === row?.original?.materialRequisitionItemId || isNewRow;
        const isLastRow = row.index === table.getRowModel().rows.length - 1;

        return (
          <Box className="flex gap-2">
            {isEditing ? (
              <>
                <Button onClick={() => handleCancel(row)}>Close</Button>
                {isLastRow && rowState?.isCreatingRow === false && (
                  <Button onFocus={() => handleCancelAndAddNewRow()}></Button>
                )}
              </>
            ) : (
              <Box className="flex gap-2">
                <TableActionButtons
                  Actions={ActionData.map((action) => ({
                    ...action,
                    onClick: () => action.onClick(row?.original),
                  }))}
                />
                {isLastRow && (
                  <Button
                    variant="contained"
                    color="primary"
                    className="px-3 py-1"
                    onClick={() => {
                      setEditingRowIndex(row?.index + 1);
                      handleAddNewRow(row);
                    }}
                  >
                    Add
                  </Button>
                )}
              </Box>
            )}
          </Box>
        );
      },
    },
  ];

  const handleAddNewRow = () => {
    const newId = "new_row_" + Date.now();
    reset();
    setEditingRowData(null);
    setEditingRowId(null);
    setRowState({
      newRowId: newId,
      isCreatingRow: true,
    });

    setEditingRowIndex(
      editingRowIndex === rows.length ? editingRowIndex + 1 : rows.length
    );
    setNewRowCreated(false);
    setRows((prevRows) => [
      ...prevRows,
      {
        materialRequisitionItemId: newId,
        materialRequisitionId: id,
        salesOrderId: "",
        brandId: "",
        categoryId: "",
        unitOfMeasurementId: "",
        itemId: "",
        unitOfMeasurementId: "",
        costCenterId: "",
        quantity: "",
        remarks: "",
        description: "",
        isEditable: true,
        isNew: true,
      },
    ]);
    reset();
  };

  const excludeKeys = [
    "materialRequisitionId",
    "brandId",
    "categoryId",
    "costCenterId",
    "itemId",
    "materialRequisitionItemId",
    "salesOrderId",
    "unitOfMeasurementId",
    "status",
  ];

  return (
    <>
      <GlassCard>
        <FmMRTDataTable
          columns={columns}
          enableRowSelection={false}
          rows={rows}
          onCreateRow={handleAddNewRow}
          isCreatingRow={rowState?.isCreatingRow}
          rowCount={tableRecordCounts}
          editingRow={editingRowId}
          pagination={pagination}
          setSearchKeyword={setSearchKeyword}
          sorting={sorting}
          columnFilters={columnFilters}
          globalFilter={globalFilter}
          setPagination={setPagination}
          setSorting={setSorting}
          setColumnFilters={setColumnFilters}
          setGlobalFilter={setGlobalFilter}
          isLoading={isLoading}
          setSearchKeyword={setSearchKeyword}
          searchKeyword={searchKeyword}
          fetchData={fetchData}
          handleRowDoubleClick={(row) => {
            handleEdit(row?.original);
            setEditingRowIndex(row?.index);
          }}
        />
        <MuiDialogOne
          title="View Details"
          open={viewdetails}
          onClose={() => setViewdetails(!viewdetails)}
        >
          {viewdetails && viewdetails?.materialRequisitionItemId && (
            <CommonView
              url="materialrequisitionitems"
              id={viewdetails?.materialRequisitionItemId}
              excludeKeys={excludeKeys}
              renderFields={(data, fields) => (
                <DynamicViewFields data={data} fields={fields} />
              )}
            />
          )}
        </MuiDialogOne>
      </GlassCard>
    </>
  );
};

export default CartMaterialRequest;
