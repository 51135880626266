import React from 'react';
import { Box } from '@mui/material';
import GlassCard from '../../../../components/small/GlassCard.tsx';
import DataTable from '../../../../components/DataTable.tsx';
import { Icon } from '@iconify/react';
import TableActionButtons from '../../../../components/_form/TableActionButtons';
import { FileNameType } from '../../../../components/_form/FormElements';
import Crud_Service from '../../../../apis/CrudService.jsx';
import { toast } from 'react-toastify';
import TableSearch from '../../../../components/_form/TableSearch.jsx';
import apiInstance from '../../../../apis/ApiService.jsx';

const TicketsDocumentUpload = (props) => {
  const {
    rows,
    pageSize,
    pageNumber,
    setSearchKeyword,
    isLoading,
    tableRecordCounts,
    handlePaginationModelChange,
    handleSortModelChange,
    searchKeyword,
    fetchData,
  } = props;
  const crud = new Crud_Service();
  const handleDelete = async (props) => {
    await crud.remove(
      `ticketattachments`,
      props?.ticketAttachmentId,
      (err, res) => {
        if (res?.status === 204) {
          toast.success('Attachment Deleted Successfully');
          fetchData();
        }
      }
    );
  };
  const downloadDocument = async (props) => {
    try {
      const imageUrl = await apiInstance.getFiles(
        `files/download/${props?.attachmentId}`
      );
      const link = document.createElement('a');
      link.href = imageUrl;
      link.setAttribute('download', `${props?.attachmentName}`);
      document.body.appendChild(link);
      link.click();
      document.body.removeChild(link);
      toast.success('Attachment Downloaded Successfully');
      fetchData();
    } catch (err) {
      toast.error('Error fetching image:', err);
    }
  };

  const ActionData = [
    {
      name: 'Delete',
      icon: <Icon icon='mi:delete' />,
      onClick: (props) => handleDelete(props),
      danger: true,
    },
    {
      name: 'Download',
      icon: <Icon icon='solar:download-outline' />,
      onClick: (props) => downloadDocument(props),
    },
  ];
  const columns = [
    {
      field: 'action',
      headerName: 'Action',
      width: 140,
      sortable: false,
      hideable: false,
      disableClickEventBubbling: false,
      renderCell: (params) => {
        return (
          <TableActionButtons
            Actions={ActionData?.map((action) => ({
              ...action,
              onClick: () => action.onClick(params.row),
            }))}
          />
        );
      },
    },

    {
      field: 'attachmentName',
      headerName: 'Attachment Name',
      width: 300,
      hideable: false,
      renderCell: (params) => {
        return (
          <FileNameType name={params.value} format={params.row.filename} />
        );
      },
    },
  ];

  return (
    <>
      <GlassCard>
        <Box sx={{ height: '550px' }}>
          <DataTable
            rows={rows}
            columns={columns}
            loading={isLoading}
            getRowClassName={(params) =>
              `${
                params.row.workingStatus === 'InActive' &&
                'bg-red-500 bg-opacity-15'
              }`
            }
            sortingMode='server'
            paginationMode='server'
            onPaginationModelChange={(model) =>
              handlePaginationModelChange(model)
            }
            onSortModelChange={(model) => handleSortModelChange(model)}
            page={pageNumber - 1}
            pageSize={pageSize}
            rowCount={tableRecordCounts?.total}
            slots={{
              toolbar: () => (
                <Box
                  className='p-2 w-full flex gap-4 items-center'
                  sx={{
                    borderBottom: '1px solid',
                    borderColor: 'border.main',
                  }}
                >
                  <Box sx={{ width: '100%', maxWidth: '250px' }}>
                    <TableSearch
                      placeholder='Search'
                      fullWidth
                      setSearchKeyword={setSearchKeyword}
                      searchValue={searchKeyword}
                    />
                  </Box>
                </Box>
              ),
            }}
          />
        </Box>
      </GlassCard>
    </>
  );
};

export default TicketsDocumentUpload;
