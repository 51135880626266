import React, { useEffect, useState } from 'react';
import { Box, Button } from '@mui/material';
import { Icon } from '@iconify/react';
import { useNavigate, useLocation } from 'react-router-dom';
import FmAutoComplete from '../../../components/_mui/FmAutoComplete.tsx';
import FmSearchableSelect from '../../../components/_mui/FmSearchableSelect.tsx';
import { Controller, useForm } from 'react-hook-form';
import useCommonFetchApi from '../../../components/helpers/useCommonFetchApi.tsx';
import { DatePicker, LocalizationProvider } from '@mui/x-date-pickers';
import dayjs from 'dayjs';
import FmTextField from '../../../components/_mui/FmTextField.tsx';
import Crud_Service from '../../../apis/CrudService.jsx';
import { toast } from 'react-toastify';
import TableDropDown from '../../../components/helpers/TableDropDown.tsx';
import FmMRTDataTable from '../../../components/FmMRTDataTable.tsx';
import { AdapterDayjs } from '@mui/x-date-pickers/AdapterDayjs';
import useLoader from '../../../components/helpers/UseLoader.tsx';
import FmViewDateField from '../../../components/_mui/FmViewDateField.tsx';

const IncidentReportList = (props) => {
  const crud = new Crud_Service();
  const {
    rows,
    tableRecordCounts,
    pagination,
    sorting,
    setPagination,
    setSorting,
    columnFilters,
    globalFilter,
    setColumnFilters,
    setGlobalFilter,
    isLoading,
    setSearchKeyword,
    searchKeyword,
    fetchData,
  } = props;
  const { control, setValue, handleSubmit } = useForm();
  const [editingRowId, setEditingRowId] = useState(null);
  const [editingRowData, setEditingRowData] = useState(null);
  const { startEditLoading, stopEditLoading } = useLoader();
  const [radioChange, setRadioChange] = useState(1);
  const location = useLocation();
  const navigation = useNavigate();
  const { data: companies } = useCommonFetchApi('companies') || {
    data: [],
  };

  const handleEdit = (row) => {
    const originalData = row?.original || {};
    setEditingRowId(originalData.incidentReportId);
    setEditingRowData({ ...originalData });
    Object.entries(originalData).forEach(([key, value]) => {
      setValue(key, value);
    });
  };

  const handleCancel = () => {
    setEditingRowId(null);
    setEditingRowData(null);
  };
  const { data: contracts } = useCommonFetchApi('contracts') || {
    data: [],
  };

  const { data: loginusers } = useCommonFetchApi('loginusers') || {
    data: [],
  };

  const handleDelete = async (props) => {
    await crud.remove(
      'incidentreports',
      props?.original?.incidentReportId,
      (__err, res) => {
        if (res?.status === 204) {
          toast.success('Incident Report Deleted Successfully');
          fetchData();
        } else {
        }
      }
    );
  };

  const handleUpdateEvent = async (values) => {
    try {
      // Start loading indicator
      startEditLoading();
      // Prepare the data to be updated
      const combinedData = {
        ...values,
        dateOfWorkResumption: dayjs(values?.dateOfWorkResumption).format(
          'YYYY-MM-DD'
        ), // Format resumption date
        incidentDate: dayjs(values?.incidentDate).format('YYYY-MM-DD'),
        Status: 2,
      };
      await crud.update(
        'incidentreports',
        editingRowId,
        combinedData,
        (_err, res) => {
          if (res?.status === 200) {
            stopEditLoading();
            fetchData();
            toast.success('Incident Report Updated Successfully');
            setEditingRowData(null);
            setEditingRowId(null);
          } else {
            stopEditLoading();
            toast.error('Failed to update the Incident Report');
          }
        }
      );
    } catch (error) {
      stopEditLoading();
      toast.error('An error occurred while updating the Incident Report');
    }
  };

  const handleViewClick = (props, type) => {
    navigation(`${type}/${props?.original?.incidentReportId}`);
  };

  const ActionData = [
    {
      name: 'Edit',
      icon: <Icon icon='solar:people-nearby-broken' />,
      onClick: (props) => {
        navigation(`edit/${props?.original?.incidentReportId}`);
      },
    },
    {
      name: 'Incident',
      icon: <Icon icon='solar:book-2-broken' />,
      onClick: (props) => handleViewClick(props, 'incident'),
    },
    {
      name: 'Injuries',
      icon: <Icon icon='solar:health-broken' />,
      onClick: (props) => handleViewClick(props, 'injuries'),
    },
    {
      name: 'Remarks',
      icon: <Icon icon='solar:notes-outline' />,
      onClick: (props) => handleViewClick(props, 'remarks'),
    },
    {
      name: 'Delete',
      icon: <Icon icon='mi:delete' />,
      onClick: (props) => handleDelete(props),
    },
  ];

  const columns = [
    {
      field: 'action',
      header: 'Actions',
      sortable: false,
      hideable: false,
      disableClickEventBubbling: false,

      Cell: (params) => {
        return (
          <Box className='flex gap-2'>
            {editingRowId === params?.row?.original?.incidentReportId ? (
              <>
                <Button onClick={handleSubmit(handleUpdateEvent)}>Save</Button>
                <Button onClick={handleCancel}>Cancel</Button>
              </>
            ) : (
              <TableDropDown
                Actions={ActionData?.map((action) => ({
                  ...action,
                  onClick: () => action.onClick(params?.row),
                }))}
                selectedRow={params?.row}
                setSelectedRow={() => { }}
              />
            )}
          </Box>
        );
      },
    },
    // Todo: Add sign field
    // {
    //   accessorKey: 'sign',
    //   header: 'Sign',
    //   size: 80,
    //   Cell: ({ renderedCellValue }) => (
    //     <Box className='font-semibold text-lg'>
    //       {renderedCellValue === 1 && (
    //         <Icon icon='material-symbols-light:attachment' />
    //       )}
    //     </Box>
    //   ),
    // },
    {
      accessorKey: 'companyName',
      header: 'Company Name',
      enableEditing: true,
      Cell: ({ cell }) => {
        const incident: any = rows?.find(
          (item) =>
            item?.incidentReportId === cell?.row?.original?.incidentReportId
        );
        return editingRowId === cell?.row?.original?.incidentReportId ? (
          <FmSearchableSelect
            name='companyId'
            control={control}
            apiUrl='companies'
            valueField='companyId'
            headerField={['Company Code', 'Company Name']}
            labelField={['companyCode', 'companyName']}
            showField={['companyName']}
            label='Companies'
            defaultValue={{
              companyId: editingRowData?.companyId,
              companyName: editingRowData?.companyName,
            }}
          />
        ) : (
          incident?.companyName || ''
        );
      },
    },
    {
      accessorKey: 'clientName',
      header: 'Client',
      enableEditing: true,
      Cell: ({ cell }) => {
        const incidentReport: any = rows?.find(
          (item) =>
            item?.incidentReportId === cell?.row?.original?.incidentReportId
        );
        return editingRowId === cell?.row?.original?.incidentReportId ? (
          <FmSearchableSelect
            name='clientId'
            control={control}
            apiUrl='clients'
            valueField='clientId'
            headerField={['Client Code', 'Client Name']}
            defaultValue={{
              clientId: editingRowData?.clientId,
              clientName: editingRowData?.clientName,
            }}
            labelField={['clientCode', 'clientName']}
            showField={['clientName']}
            label='Client'
            required
          />
        ) : (
          incidentReport?.clientName || ''
        );
      },
    },
    {
      accessorKey: 'locationName',
      header: 'Location',
      enableEditing: true,
      Cell: ({ cell }) => {
        const incidentReport: any = rows?.find(
          (item) =>
            item?.incidentReportId === cell?.row?.original?.incidentReportId
        );
        return editingRowId === cell?.row?.original?.incidentReportId ? (
          <FmSearchableSelect
            name='locationId'
            control={control}
            apiUrl='locations'
            valueField='locationId'
            headerField={['Location Code', 'Location Name']}
            labelField={['locationCode', 'locationName']}
            showField={['locationName']}
            defaultValue={{
              locationCode: editingRowData?.locationCode,
              locationName: editingRowData?.locationName,
            }}
            label='Location'
            required
          />
        ) : (
          incidentReport?.locationName || ''
        );
      },
    },
    {
      accessorKey: 'divisionName',
      header: 'Division',
      enableEditing: true,
      Cell: ({ cell }) => {
        const incidentReport: any = rows?.find(
          (item) =>
            item?.incidentReportId === cell?.row?.original?.incidentReportId
        );
        return editingRowId === cell?.row?.original?.incidentReportId ? (
          <FmSearchableSelect
            name='divisionId'
            control={control}
            apiUrl='divisions'
            valueField='divisionId'
            headerField={['Division Code', 'Division Name']}
            labelField={['divisionCode', 'divisionName']}
            showField={['divisionName']}
            defaultValue={{
              divisionCode: editingRowData?.divisionCode,
              divisionName: editingRowData?.divisionName,
            }}
            label='Division'
            required
          />
        ) : (
          incidentReport?.divisionName || ''
        );
      },
    },
    {
      accessorKey: 'projectName',
      header: 'Project Code',
      enableEditing: true,
      Cell: ({ cell }) => {
        const incidentReport: any = rows?.find(
          (item) =>
            item?.incidentReportId === cell?.row?.original?.incidentReportId
        );
        return editingRowId === cell?.row?.original?.incidentReportId ? (
          <FmSearchableSelect
            name='projectId'
            control={control}
            apiUrl='projects'
            valueField='projectId'
            headerField={['Project Code', 'Project Name']}
            labelField={['projectCode', 'projectName']}
            showField={['projectName']}
            defaultValue={{
              projectCode: editingRowData?.projectCode,
              projectName: editingRowData?.projectName,
            }}
            label='Project'
            required
          />
        ) : (
          incidentReport?.projectName || ''
        );
      },
    },
    {
      accessorKey: 'contractName',
      header: 'Contract Name',
      enableEditing: true,
      Cell: ({ cell }) => {
        const incidentReport: any = rows?.find(
          (item) =>
            item?.incidentReportId === cell?.row?.original?.incidentReportId
        );
        return editingRowId === cell?.row?.original?.incidentReportId ? (
          <FmSearchableSelect
            name='contractId'
            control={control}
            apiUrl='contracts'
            valueField='contractId'
            headerField={['Contract Code', 'Contract Name']}
            labelField={['contractCode', 'contractName']}
            showField={['contractName']}
            label={'Contracts'}
            defaultValue={{
              contractId: editingRowData?.contractId,
              contractName: editingRowData?.contractName,
            }}
          />
        ) : (
          incidentReport?.contractName || ''
        );
      },
    },
    {
      accessorKey: 'buildingName',
      header: 'Building',
      enableEditing: true,
      Cell: ({ cell }) => {
        const incidentReport: any = rows?.find(
          (item) =>
            item?.incidentReportId === cell?.row?.original?.incidentReportId
        );
        return editingRowId === cell?.row?.original?.incidentReportId ? (
          <FmSearchableSelect
            name='buildingId'
            control={control}
            apiUrl='buildings'
            valueField='buildingId'
            headerField={['Building Code', 'Building Name']}
            defaultValue={{
              buildingCode: editingRowData?.buildingCode,
              buildingName: editingRowData?.buildingName,
            }}
            labelField={['buildingCode', 'buildingName']}
            showField={['buildingName']}
            label='Building'
            required
          />
        ) : (
          incidentReport?.buildingName || ''
        );
      },
    },
    {
      accessorKey: 'area',
      header: 'Area',
      enableEditing: true,
      Cell: ({ row }) => {
        return editingRowId === row?.original?.incidentReportId ? (
          <FmTextField
            name='area'
            control={control}
            defaultValue={row?.original?.area}
          />
        ) : (
          row.original.area
        );
      },
    },
    {
      accessorKey: 'preparedByName',
      header: 'Prepared By',
      enableEditing: true,
      Cell: ({ cell }) => {
        const incidentReport: any = rows?.find(
          (item) =>
            item?.incidentReportId === cell?.row?.original?.incidentReportId
        );
        return editingRowId === cell?.row?.original?.incidentReportId ? (
          <FmSearchableSelect
            name='loginUserId'
            control={control}
            apiUrl='loginusers'
            valueField='loginUserId'
            labelField={['emailId', 'loginUserName']}
            showField={['emailId', 'loginUserName']}
            pageSize={50}
            label='Prepared By'
            defaultValue={{
              loginUserId: editingRowData?.preparedBy,
              loginUserName: editingRowData?.preparedByName,
            }}
          />
        ) : (
          incidentReport?.preparedByName || ''
        );
      },
    },
    {
      accessorKey: 'verifiedBy',
      header: 'Verified By',
      enableEditing: true,
      Cell: ({ cell }) => {
        const incidentReport: any = rows?.find(
          (item) =>
            item?.incidentReportId === cell?.row?.original?.incidentReportId
        );
        return editingRowId === cell?.row?.original?.incidentReportId ? (
          <FmSearchableSelect
            name='loginUserId'
            control={control}
            apiUrl='loginusers'
            valueField='loginUserId'
            labelField={['emailId', 'loginUserName']}
            showField={['emailId', 'loginUserName']}
            pageSize={50}
            label='Verified By'
            defaultValue={{
              loginUserId: editingRowData?.verifiedBy,
              loginUserName: editingRowData?.verifiedByName,
            }}
          />
        ) : (
          incidentReport?.verifiedByName || ''
        );
      },
    },
    {
      accessorKey: 'incidentDate',
      header: 'Date of Incident',
      enableEditing: true,
      Cell: ({ cell }) => {
        const incidentReport: any = rows?.find(
          (item) =>
            item?.incidentReportId === cell?.row?.original?.incidentReportId
        );
        return editingRowId === cell?.row?.original?.incidentReportId ? (
          <LocalizationProvider dateAdapter={AdapterDayjs}>
            <Controller
              name='incidentDate'
              control={control}
              defaultValue={dayjs().format('DD/MM/YYYY')}
              render={({ field: { onChange, value } }) => (
                <DatePicker
                  label={
                    <>
                      Date of Incident
                      <span style={{ color: 'red' }}> *</span>
                    </>
                  }
                  value={value ? dayjs(value) : null}
                  format='DD/MM/YYYY'
                  onChange={(newValue) => {
                    onChange(newValue);
                  }}
                  sx={{ width: '100%' }}
                />
              )}
            />
          </LocalizationProvider>
        ) : (
          FmViewDateField({ value: incidentReport?.incidentDate })
        );
      },
    },
    {
      accessorKey: 'dateOfWorkResumption',
      header: 'Date of Work Resumption',
      enableEditing: true,
      Cell: ({ cell }) => {
        const incidentReport: any = rows?.find(
          (item) =>
            item?.incidentReportId === cell?.row?.original?.incidentReportId
        );
        return editingRowId === cell?.row?.original?.incidentReportId ? (
          <LocalizationProvider dateAdapter={AdapterDayjs}>
            <Controller
              name='dateOfWorkResumption'
              control={control}
              defaultValue={dayjs().format('DD/MM/YYYY')}
              render={({ field: { onChange, value } }) => (
                <DatePicker
                  label={
                    <>
                      Date of Work Resumption
                      <span style={{ color: 'red' }}> *</span>
                    </>
                  }
                  value={value ? dayjs(value) : null}
                  format='DD/MM/YYYY'
                  onChange={(newValue) => {
                    onChange(newValue);
                    // setSelectedValue(newValue, 'date');
                  }}
                  sx={{ width: '100%' }}
                />
              )}
            />
          </LocalizationProvider>
        ) : (
          FmViewDateField({ value: incidentReport?.dateOfWorkResumption })
        );
      },
    },

    {
      accessorKey: 'typeOfIncidentName',
      header: 'Type Of Incident',
      enableEditing: true,
      Cell: ({ cell }) => {
        const incidentReport: any = rows?.find(
          (item) =>
            item?.incidentReportId === cell?.row?.original?.incidentReportId
        );
        return editingRowId === cell?.row?.original?.incidentReportId ? (
          <FmSearchableSelect
            name='typeOfIncidentId'
            control={control}
            apiUrl='typeofincidents'
            valueField='typeOfIncidentId'
            headerField={['Incident Code', 'Incident Name']}
            labelField={['typeOfIncidentCode', 'typeOfIncidentName']}
            showField={['typeOfIncidentName']}
            label='Type of Accident'
            defaultValue={{
              typeOfIncidentId: editingRowData?.typeOfIncidentId,
              typeOfIncidentName: editingRowData?.typeOfIncidentName,
            }}
          />
        ) : (
          incidentReport?.typeOfIncidentName || ''
        );
      },
    },
    {
      accessorKey: 'incidentDescription',
      header: 'Incident Description',
      enableEditing: true,
      Cell: ({ row }) => {
        return editingRowId === row?.original?.incidentReportId ? (
          <FmTextField
            name='incidentDescription'
            control={control}
            defaultValue={row?.original?.incidentDescription}
          />
        ) : (
          row.original.incidentDescription
        );
      },
    },
    {
      accessorKey: 'incidentImpact',
      header: 'Incident Impact',
      enableEditing: true,
      Cell: ({ row }) => {
        return editingRowId === row?.original?.incidentReportId ? (
          <FmTextField
            control={control}
            name='incidentImpact'
            defaultValue={row?.original?.incidentImpact}
          />
        ) : (
          row.original.incidentImpact
        );
      },
    },
    {
      accessorKey: 'briefDescription',
      header: 'Brief Description',
      enableEditing: true,
      Cell: ({ row }) => {
        return editingRowId === row?.original?.incidentReportId ? (
          <FmTextField
            control={control}
            name='briefDescription'
            defaultValue={row?.original?.briefDescription}
          />
        ) : (
          row.original.briefDescription
        );
      },
    },
    {
      accessorKey: 'rootCauseAnalysis',
      header: 'Root Cause Analysis',
      enableEditing: true,
      Cell: ({ row }) => {
        return editingRowId === row?.original?.incidentReportId ? (
          <FmTextField
            control={control}
            name='rootCauseAnalysis'
            defaultValue={row?.original?.rootCauseAnalysis}
          />
        ) : (
          row.original.rootCauseAnalysis
        );
      },
    },
    {
      accessorKey: 'actionPlan',
      header: 'Action Plan',
      enableEditing: true,
      Cell: ({ row }) => {
        return editingRowId === row?.original?.incidentReportId ? (
          <FmTextField
            control={control}
            name='actionPlan'
            defaultValue={row?.original?.actionPlan}
          />
        ) : (
          row.original.actionPlan
        );
      },
    },
    {
      accessorKey: 'remarks',
      header: 'Remarks',
      enableEditing: true,
      Cell: ({ row }) => {
        return editingRowId === row?.original?.incidentReportId ? (
          <FmTextField
            control={control}
            name='remarks'
            defaultValue={editingRowData?.remarks}
          />
        ) : (
          row.original.remarks
        );
      },
    },
  ];

  return (
    <>
      <FmMRTDataTable
        columns={columns}
        enableRowSelection={false}
        rows={rows}
        rowCount={tableRecordCounts}
        editingRow={editingRowId}
        pagination={pagination}
        sorting={sorting}
        columnFilters={columnFilters}
        globalFilter={globalFilter}
        setPagination={setPagination}
        setSorting={setSorting}
        setColumnFilters={setColumnFilters}
        setGlobalFilter={setGlobalFilter}
        isLoading={isLoading}
        setSearchKeyword={setSearchKeyword}
        searchKeyword={searchKeyword}
      />
    </>
  );
};

export default IncidentReportList;
