import React, { useEffect, useState } from 'react';
import { Grid } from '@mui/material';
import GlassCard from '../../../components/small/GlassCard.tsx';
import Crud_Service from '../../../apis/CrudService.jsx';
import { Controller, useForm } from 'react-hook-form';
import { DatePicker, LocalizationProvider } from '@mui/x-date-pickers';
import { AdapterDayjs } from '@mui/x-date-pickers/AdapterDayjs';
import useLoader from '../../../components/helpers/UseLoader.tsx';
import { toast } from 'react-toastify';
import useCommonFetchApi from '../../../components/helpers/useCommonFetchApi.tsx';
import FmAutoComplete from '../../../components/_mui/FmAutoComplete.tsx';
import FmTextField from '../../../components/_mui/FmTextField.tsx';
import dayjs from 'dayjs';
import ActionButtons from '../../../components/_form/ActionButtons.tsx';
import FmFileInput from '../../../components/_mui/FmFileInput.tsx';
import DashboardLayout from '../../../components/DashboardLayout.tsx';
import { useNavigate } from 'react-router-dom';
import { yupResolver } from '@hookform/resolvers/yup';
import { CompanyInsurancePolicySchema } from '../../../utils/CommonvalidationSchemas.tsx';
import FmDatePicker from '../../../components/_mui/FmDatePicker.tsx';
import UploadModalBox from '../../../components/upload/UploadModalBox.tsx';

const GridValue = 2.4;

const AddCompanyInsurancePolicy = (props) => {
  const { fetchData } = props;
  const crud = new Crud_Service();
  const { startCreateLoading, stopCreateLoading, createLoading } = useLoader();
  const [fileInputs, setFileInputs] = useState([
    { id: 1, file: null, fileName: '' },
    { id: 2, file: null, fileName: '' },
    { id: 3, file: null, fileName: '' },
  ]);
  const navigate = useNavigate();
  const [fileId, setFileId] = useState(null);

  const {
    control,
    handleSubmit,
    watch,
    setError,
    setValue,
    clearErrors,
    reset,
    getValues,
  } = useForm({
    mode: 'onChange',
    resolver: yupResolver(CompanyInsurancePolicySchema),
  });
  const [uploadedFileIds, setUploadedFileIds] = useState([]);
  const [files, setFiles] = useState([]);
  const handleFileIds = (fileIds) => {
    setUploadedFileIds(fileIds);
  };
  const { data: insurancePolicy } = useCommonFetchApi(
    'companyinsurancepolicies'
  ) || {
    data: [],
  };
  const { data: endUser } = useCommonFetchApi(
    'companyinsurancepolicyendusers'
  ) || {
    data: [],
  };
  const { data: typeOfProduct } = useCommonFetchApi(
    'companyinsurancepolicytypeofproducts'
  ) || {
    data: [],
  };
  const { data: insurer } = useCommonFetchApi(
    'companyinsurancepolicyinsurers'
  ) || {
    data: [],
  };
  const { data: premium } = useCommonFetchApi(
    'companyinsurancepolicypremiums'
  ) || {
    data: [],
  };

  const handleSubmitPolicy = async (values) => {
    const formattedStartDate = values.startDate
      ? dayjs(values.startDate).format('YYYY-MM-DD')
      : null;
    const formattedEndDate = values.endDate
      ? dayjs(values.endDate).format('YYYY-MM-DD')
      : null;

    startCreateLoading();

    const payload = {
      companyInsurancePolicyId: values?.companyInsurancePolicyId || null, // Default to null if undefined
      description: values?.description || "", // Default to an empty string if undefined
      companyInsurancePolicyEndUserId: values?.companyInsurancePolicyEndUserId || null,
      companyInsurancePolicyTypeOfProductId: values?.companyInsurancePolicyTypeOfProductId || null,
      companyInsurancePolicyInsurerId: values?.companyInsurancePolicyInsurerId || null,
      companyInsurancePolicyPremiumId: values?.companyInsurancePolicyPremiumId || null,
      policyDetail: values?.policyDetail || "",
      brokerName: values?.brokerName || "",
      mobileNumber: values?.mobileNumber || "",
      startDate: formattedStartDate,
      endDate: formattedEndDate,
      priorNotificationDays: values?.priorNotificationDays || null,
      insuredSum: values?.insuredSum || 0,
      remarks: values?.remarks || "",
      fileUploadIds:uploadedFileIds
    };

    try {
      await crud.create(
        'companyInsurancePolicyDetails',
        payload,
        (_err, res) => {
          if (res?.status === 201) {
            navigate(-1);
            toast.success('Added Insurance Policy Successfully');
          } else {
            stopCreateLoading();
          }
        }
      );
    } catch (error) {
      stopCreateLoading();
      toast.error('Error occurred during submission');
    }
  };



  const [selectedInsurancePolicyId, setSelectedInsurancePolicyId] =
    useState(null);
  const [selectedInsurancePolicyTypeId, setSelectedInsurancePolicyTypeId] =
    useState(null);
  const [calculatedAmount, setCalculatedAmount] = useState(0);

  useEffect(() => {
    if (selectedInsurancePolicyId && selectedInsurancePolicyTypeId) {
      const selectedPremium = premium.find(
        (item) =>
          item.companyInsurancePolicyId === selectedInsurancePolicyId &&
          item.companyInsurancePolicyTypeId === selectedInsurancePolicyTypeId
      );
      if (selectedPremium) {
        setCalculatedAmount(selectedPremium.amount);
      } else {
        setCalculatedAmount(0);
      }
    }
  }, [selectedInsurancePolicyId, selectedInsurancePolicyTypeId, premium]);

  const startDate=watch('startDate');

  return (
    <DashboardLayout title='Add Company Insurance Policy'>
      <GlassCard className='p-4 mb-4'>
        <form onSubmit={handleSubmit(handleSubmitPolicy)}>
          <Grid container spacing={3}>
            <Grid item md={GridValue}>
              <FmAutoComplete
                name='companyInsurancePolicyId'
                control={control}
                options={insurancePolicy}
                label='Insurance Policy'
                displayField='companyInsurancePolicyName'
                optionFields={['companyInsurancePolicyName']}
                valueKey='companyInsurancePolicyId'
              />
            </Grid>
            <Grid item md={GridValue}>
              <FmTextField
                name='description'
                label='Description'
                control={control}
              />
            </Grid>
            <Grid item md={GridValue}>
              <FmAutoComplete
                name='companyInsurancePolicyEndUserId'
                control={control}
                options={endUser}
                label='End User'
                displayField='companyInsurancePolicyEndUserName'
                optionFields={['companyInsurancePolicyEndUserName']}
                valueKey='companyInsurancePolicyEndUserId'
              />
            </Grid>
            <Grid item md={GridValue}>
              <FmAutoComplete
                name='companyInsurancePolicyTypeOfProductId'
                control={control}
                options={typeOfProduct}
                label='Type of Product'
                displayField='companyInsurancePolicyTypeOfProductName'
                optionFields={['companyInsurancePolicyTypeOfProductName']}
                valueKey='companyInsurancePolicyTypeOfProductId'
              />
            </Grid>
            <Grid item md={GridValue}>
              <FmAutoComplete
                name='companyInsurancePolicyInsurerId'
                control={control}
                options={insurer}
                label='Insurer'
                displayField='companyInsurancePolicyInsurerName'
                optionFields={['companyInsurancePolicyInsurerName']}
                valueKey='companyInsurancePolicyInsurerId'
              />
            </Grid>
            <Grid item md={GridValue}>
              <FmTextField
                name='companyInsurancePolicyPremiumId'
                label='Amount'
                control={control}
                pattern='Number'
              />
            </Grid>
            <Grid item md={GridValue}>
              <FmTextField
                name='policyDetail'
                label='Policy Details'
                control={control}
              />
            </Grid>
            <Grid item md={GridValue}>
              <FmTextField
                name='brokerName'
                label='Broker Name'
                control={control}
              />
            </Grid>
            <Grid item md={GridValue}>
              <FmTextField
                name='mobileNumber'
                pattern='Number'
                label='Mobile Number'
                control={control}
              />
            </Grid>
            <Grid item md={GridValue}>
              <FmDatePicker
                name='startDate'
                label='Start Date'
                control={control}
              />
            </Grid>
            <Grid item md={GridValue}>
            <FmDatePicker
                name='endDate'
                label='End Date'
                control={control}
                defaultValue={dayjs().add(1, 'day')}
                minDate={dayjs(startDate).add(1, 'day')}
              />
            </Grid>
            <Grid item md={GridValue}>
              <FmTextField
                name='insuredSum'
                label='Insured Sum Details'
                control={control}
                pattern='Number'
              />
            </Grid>
            <Grid item md={GridValue}>
              <FmTextField
                name='priorNotificationDays'
                label='Prior Notification Days'
                control={control}
                pattern='Number'
                minLength={1}
              />
            </Grid>
            <Grid item md={GridValue}>
            <UploadModalBox
                  onSubmitFiles={handleFileIds}
                  files={files}
                  setFiles={setFiles}
                />
            </Grid>
            <Grid item md={GridValue}>
                  <FmTextField
                    name="description"
                    label="Description"
                    control={control}
                    multiline
                    rows={4}
                  />
                </Grid>
            <Grid item md={GridValue}>
              <FmTextField
                name='remarks'
                label='Remarks'
                control={control}
                multiline
                rows={4}
              />
            </Grid>
 
          </Grid>
          <ActionButtons
            onSubmit={handleSubmit(handleSubmitPolicy)}
            onCancel={() => navigate(-1)}
            submitText='create'
            cancelText='Go Back'
            submitLoading={createLoading}
            onReset={reset}
          />
        </form>
      </GlassCard>
    </DashboardLayout>
  );
};

export default AddCompanyInsurancePolicy;
