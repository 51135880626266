import React from 'react';
import DashboardLayout from '../../../../components/DashboardLayout.tsx';
import GlassCard from '../../../../components/small/GlassCard.tsx';
import { useNavigate, useParams } from 'react-router-dom';
import CommonView from '../../../../components/helpers/CommonView.jsx';
import DynamicViewFields from '../../../../components/helpers/DynamicViewFields.jsx';
import { CreateButton } from '../../../../components/small/Buttons.jsx';
import ActionButtons from '../../../../components/_form/ActionButtons.tsx';

const ViewAccomCamps = () => {
  const navigate = useNavigate();
  const { id } = useParams();

  const excludeKeys = [
    'accommodationId',
    'blockId',
    'campId',
    'employeeId',
    'employeeRoomStatusId',
    'roomId',
    'startDate',
    'updatedDate',
    'status',
  ];

  return (
    <DashboardLayout
      title='View Accommodation Camp'
      actionButtons={
        <CreateButton
          name='Create'
          onClick={() => navigate('/admin/accommodation/camp/create')}
        />
      }
    >
      <GlassCard className='p-5'>
        <CommonView
          url='camps'
          id={id}
          excludeKeys={excludeKeys}
          renderFields={(data, fields) => (
            <DynamicViewFields data={data} fields={fields} />
          )}
        />
        <ActionButtons
          cancelLoading={false}
          cancelText='Go Back'
          submitText='Edit'
          onSubmit={() => navigate(`/admin/accommodation/camp/edit/${id}`)}
          onCancel={() => navigate('/admin/accommodation/camp')}
        />
      </GlassCard>
    </DashboardLayout>
  );
};

export default ViewAccomCamps;
