import React, { useEffect, useMemo, useState } from 'react';
import DashboardLayout from '../../../components/DashboardLayout.tsx';
import PageHeader from '../../../components/PageHeader';
import DataTable from '../../../components/DataTable.tsx';
import GlassCard from '../../../components/small/GlassCard.tsx';
import TableActionButtons from '../../../components/_form/TableActionButtons';
import { ViewField, StatusBadge } from '../../../components/_form/FormElements';
import { Box, Grid } from '@mui/material';
import { CreateButton } from '../../../components/small/Buttons';
import TableSearch from '../../../components/_form/TableSearch.jsx';
import MuiDialogOne from '../../../components/_mui/MuiDialogOne';
import Crud_Service from '../../../apis/CrudService';
import { useForm, SubmitHandler } from 'react-hook-form';
import { useTranslation } from 'react-i18next';
import CommonLoader from '../../../components/page/CommonLoader';
import useLoader from '../../../components/helpers/UseLoader.tsx';
import ActionButtons from '../../../components/_form/ActionButtons.tsx';
import useTableLogic from '../../../components/helpers/UseTableLogic.tsx';
import FmTextField from '../../../components/_mui/FmTextField.tsx';
import FmAutoComplete from '../../../components/_mui/FmAutoComplete.tsx';
import { MasterDatatableHeight } from '../../../components/helpers/Containers.jsx';
import { BooleanOptions, UnitTypeForUnitBase } from '../../../utils/CommonVariables.tsx';
import { BaseUnitDetails } from '../../../interfaces/PreSalesInterfaces.ts';
import { toast } from 'react-toastify';
import { yupResolver } from '@hookform/resolvers/yup';
import Icons from '../../../utils/Icon.jsx';
import { useSelector } from 'react-redux';
import { findNavByName } from '../../../utils/navUtils.ts';
import useCommonFetchApi from '../../../components/helpers/useCommonFetchApi.tsx';
import usePermissions from '../../../utils/CommonUtils/PermissionUtil.tsx';
import FmSearchableSelect from '../../../components/_mui/FmSearchableSelect.tsx';
import { BaseUnitSchema } from '../../../utils/CommonvalidationSchemas.tsx';

const BaseUnit = () => {
    const [openCreate, setOpenCreate] = React.useState(false);
    const [openView, setOpenView] = React.useState(false);
    const [editModal, setEditModal] = useState(false);
    const [editData, setEditData] = useState(null);
    const crud = new Crud_Service();
    const { t } = useTranslation();
    const [viewData, setViewData] = useState();
    const [loading, setLoader] = useState(false);
    const { handleSubmit, setValue, control, reset } =
        useForm<BaseUnitDetails>({
            mode: 'onChange',
            resolver: yupResolver(BaseUnitSchema) as any,
        });
    const {
        createLoading,
        startCreateLoading,
        stopCreateLoading,
        editLoading,
        startEditLoading,
        stopEditLoading,
    } = useLoader();
    const {
        rows,
        pageSize,
        pageNumber,
        setSearchKeyword,
        isLoading,
        tableRecordCounts,
        searchKeyword,
        handlePaginationModelChange,
        handleSortModelChange,
        fetchData,
    } = useTableLogic('baseunits', 'unittype',UnitTypeForUnitBase?.BASEUNIT);

    const menu = useSelector((state) => state?.menu?.items);

    const SubMenu = useMemo(() => {
        return menu?.map((item) => item?.menus?.flat()).flat() || [];
    }, [menu]);

    const resultCafm = useMemo(
        () => findNavByName(SubMenu, 'CAFM', 'All master'),
        [SubMenu]
    );
    const currentPath = location.pathname;
    const index = resultCafm?.findIndex((item) => item.url === currentPath);
    const menuPermission = resultCafm[index]?.permissionDtos;
    const permissions = usePermissions(menuPermission);

    useEffect(() => {
        document.title = `CAFM - Base Units`;
    }, []);

    const handleCreateClickOpen = () => {
        reset();
        setOpenCreate(true);
    };
    const handleCreateClose = () => {
        reset();
        setOpenCreate(false);
    };
    const { data: unittypes } = useCommonFetchApi('unittypes') || {
        data: [],
    };
    const { data: floors } = useCommonFetchApi('floors') || {
        data: [],
    };
    const handleSubmitAssetGroup: SubmitHandler<BaseUnitDetails> = async (
        values
    ) => {
        startCreateLoading();
        await crud.create('baseunits', values, (err, res) => {
            if (res?.status === 201) {
                setOpenCreate(false);
                stopCreateLoading();
                toast.success('Base Unit Created Successfully');
                reset();
                fetchData();
            } else {
                stopCreateLoading();
                setOpenCreate(true);
            }
        });
    };

    const handleViewClickOpen = async (view) => {
        setOpenView(true);
        setLoader(true);
        await crud.getSingle('baseunits', view?.baseUnitId, (err, res) => {
            if (res?.status === 200) {
                setViewData(res?.data);
                setLoader(false);
            } else {
            }
        });
    };

    const handleEditClick = async (view) => {
        setEditModal(true);
        setLoader(true);
        await crud.getSingle('baseunits', view?.baseUnitId, (err, res) => {
            if (res?.status === 200) {
                Object.entries({
                    ...res?.data,
                }).forEach(([key, value]) => {
                    setValue(key, value);
                });
                setEditData(res?.data);
                setLoader(false);
            } else {
                setLoader(false);
            }
        });
    };

    const handleEditUnitConfigurations = async (values) => {
        startEditLoading();
        await crud.update(
            'baseunits',
            editData?.baseUnitId,
            values,
            (err, res) => {
                if (res?.status === 200) {
                    setEditModal(false);
                    stopEditLoading();
                    toast.success('Base Unit Updated Successfully');
                    fetchData();
                } else {
                    stopEditLoading();
                    setEditModal(true);
                }
            }
        );
    };

    const handleDeleteClick = async (view) => {
        await crud.remove('baseunits', view?.baseUnitId, (err, res) => {
            if (res?.status === 204) {
                fetchData();
                toast.success('Base Unit Deleted Successfully');
            } else {
            }
        });
    };

    const handleViewClose = () => {
        setOpenView(false);
    };

    const counters = [
        {
            name: 'All',
            count: tableRecordCounts?.total || 0,
        },
        {
            name: 'Active',
            count: tableRecordCounts?.active || 0,
        },
        {
            name: 'Deactivated',
            count: tableRecordCounts?.inActive || 0,
        },
    ];

    const ActionData = [
        {
            name: t('view'),
            icon: Icons.view,
            hidden: !permissions.view,

            onClick: (props) => handleViewClickOpen(props),
        },
        {
            name: t('edit'),
            icon: Icons.edit,
            hidden: !permissions.edit,

            onClick: (props) => handleEditClick(props),
        },
        {
            name: t('delete'),
            icon: Icons.delete,
            hidden: !permissions.delete,
            onClick: (props) => handleDeleteClick(props),
            danger: true,
        },
    ];

    const columns = [
        {
            field: 'action',
            headerName: '',
            width: 140,
            sortable: false,
            disableClickEventBubbling: false,
            renderCell: (params) => {
                return (
                    <TableActionButtons
                        Actions={ActionData?.map((action) => ({
                            ...action,
                            onClick: () => action.onClick(params.row),
                        }))}
                    />
                );
            },
        },
        { field: 'baseUnitName', headerName: 'Base Unit Name', flex: 1 },
        { field: 'unitTypeName', headerName: 'Unit Type Name', flex: 1 },
        { field: 'floorName', headerName: 'Floor Name', flex: 1 },
        { field: 'wingName', headerName: 'Wing Name', flex: 1 },
        { field: 'unitArea', headerName: 'Unit Area', flex: 1 },
        { field: 'unitOfMeasurementName', headerName: 'Unit of Measurement Name', flex: 1 },
        { field: 'unitUsageName', headerName: 'Unit Usage Name', flex: 1 },
        { field: 'unitConfigurationName', headerName: 'Unit Configuration Name', flex: 1 },
        {
            field: 'balconyAvailability', headerName: 'Balcony Availability', flex: 1,
            renderCell: (params) => {
                return params.value === true ? 'Yes' : 'No';
            }
        },
        { field: 'ownerName', headerName: 'Owner Name', flex: 1 },
        { field: 'tenantName', headerName: 'Tenant Name', flex: 1 },
        { field: 'subUnitTypeName', headerName: 'Sub Unit Type Name', flex: 1 },
        { field: 'ownershipTypeName', headerName: 'Ownership Type Name', flex: 1 },
        { field: 'remarks', headerName: 'Remarks', flex: 1 },
        {
            field: 'status',
            headerName: t('status'),
            flex: 1,
            renderCell: (params) => {
                return (
                    <StatusBadge
                        title={params.value === 2 ? 'Active' : 'Inactive'}
                        type={params.value === 2 ? 'green' : 'red'}
                    />
                );
            },
        },
    ];

    return (
        <DashboardLayout
            title='Base Units'
            actionButtons={
                <>
                    <PageHeader counters={counters} />
                </>
            }
            hasSubmenu
            menu={resultCafm}
        >
            <GlassCard className='h-full-css'>
                <MasterDatatableHeight>
                    <DataTable
                        rows={rows}
                        columns={columns}
                        loading={isLoading}
                        getRowClassName={(params) =>
                            `${params.row.status === 0 && 'bg-red-50'}`
                        }
                        sortingMode='server'
                        paginationMode='server'
                        onPaginationModelChange={(model) =>
                            handlePaginationModelChange(model)
                        }
                        onSortModelChange={(model) => handleSortModelChange(model)}
                        page={pageNumber - 1}
                        pageSize={pageSize}
                        rowCount={tableRecordCounts?.total}
                        slots={{
                            toolbar: () => (
                                <Box
                                    className='p-2 w-full flex justify-between items-center'
                                    sx={{
                                        borderBottom: '1px solid',
                                        borderColor: 'border.main',
                                    }}
                                >
                                    <Box sx={{ maxWidth: '250px' }}>
                                        <TableSearch
                                            placeholder='Search'
                                            fullWidth
                                            setSearchKeyword={setSearchKeyword}
                                            searchValue={searchKeyword}
                                        />
                                    </Box>
                                    {permissions.create && (
                                        <CreateButton name='New' onClick={handleCreateClickOpen} />
                                    )}
                                </Box>
                            ),
                        }}
                    />
                </MasterDatatableHeight>
            </GlassCard>

            <MuiDialogOne
                title={t('create')}
                open={openCreate}
                onClose={handleCreateClose}
            >
                <form onSubmit={handleSubmit(handleSubmitAssetGroup)}>
                    <Grid container spacing={3}>
                        <Grid item md={6}>
                            <FmTextField
                                name='baseUnitName'
                                control={control}
                                label='Base Unit Name'
                                required
                            />
                        </Grid>
                        <Grid item md={6}>
                            <FmAutoComplete
                                name='unitTypeId'
                                control={control}
                                options={unittypes}
                                label='Unit Types'
                                displayField='unitTypeName'
                                optionFields={['unitTypeName']}
                                valueKey='unitTypeId'
                                required
                            />
                        </Grid>
                        <Grid item md={6}>
                            <FmAutoComplete
                                name='floorId'
                                control={control}
                                options={floors}
                                label='Floor'
                                displayField='floorName'
                                optionFields={['floorName']}
                                valueKey='floorId'
                                required
                            />
                        </Grid>

                        <Grid item md={6}>
                            <FmSearchableSelect
                                name='parentBaseUnitId'
                                control={control}
                                apiUrl='baseunits'
                                valueField='baseUnitId'
                                labelField={['baseUnitCode', 'baseUnitName']}
                                showField={['baseUnitCode', 'baseUnitName']}
                                label='Parent Base Unit'
                                required
                            />
                        </Grid>
                        <Grid item md={6}>
                            <FmSearchableSelect
                                name='wingId'
                                control={control}
                                apiUrl='wings'
                                valueField='wingId'
                                labelField={['wingCode', 'wingName']}
                                showField={['wingCode', 'wingName']}
                                label='Wings'
                            />
                        </Grid>
                        <Grid item md={6}>
                            <FmTextField
                                name='unitArea'
                                control={control}
                                label='Unit Area'
                            />
                        </Grid>
                        <Grid item md={6}>
                            <FmSearchableSelect
                                name='unitOfMeasurementId'
                                control={control}
                                apiUrl='unitofmeasurements'
                                valueField='unitOfMeasurementId'
                                labelField={['unitOfMeasurementCode', 'unitOfMeasurementName']}
                                showField={['unitOfMeasurementCode', 'unitOfMeasurementName']}
                                label='UOM'
                            />
                        </Grid>
                        <Grid item md={6}>
                            <FmSearchableSelect
                                name='unitUsageId'
                                control={control}
                                apiUrl='unitusages'
                                valueField='unitUsageId'
                                labelField={['unitUsageCode', 'unitUsageName']}
                                showField={['unitUsageCode', 'unitUsageName']}
                                label='Unit Usage'
                            />
                        </Grid>
                        <Grid item md={6}>
                            <FmSearchableSelect
                                name='unitConfigurationId'
                                control={control}
                                apiUrl='unitconfiguration'
                                valueField='unitConfigurationId'
                                labelField={['unitConfigurationCode', 'unitConfigurationName']}
                                showField={['unitConfigurationCode', 'unitConfigurationName']}
                                label='Unit Configuration'
                            />
                        </Grid>
                        <Grid item md={6}>
                            <FmAutoComplete
                                name='balconyAvailability'
                                control={control}
                                options={BooleanOptions}
                                label='Balcony Availability'
                                displayField='label'
                                optionFields={['label']}
                                valueKey='value'
                            />
                        </Grid>
                        <Grid item md={6}>
                            <FmTextField
                                name='ownerName'
                                control={control}
                                label='Owner Name'
                            />
                        </Grid>
                        <Grid item md={6}>
                            <FmTextField
                                name='tenantName'
                                control={control}
                                label='Tenant Name'
                            />
                        </Grid>
                        <Grid item md={6}>
                            <FmSearchableSelect
                                name='subUnitTypeId'
                                control={control}
                                apiUrl='subunittypes'
                                valueField='subUnitTypeId'
                                labelField={['subUnitTypeCode', 'subUnitTypeName']}
                                showField={['subUnitTypeCode', 'subUnitTypeName']}
                                label='Sub Unit Type'
                            />
                        </Grid>
                        <Grid item md={6}>
                            <FmSearchableSelect
                                name='ownershipTypeId'
                                control={control}
                                apiUrl='ownershiptypes'
                                valueField='ownershipTypeId'
                                labelField={['ownershipTypeCode', 'ownershipTypeName']}
                                showField={['ownershipTypeCode', 'ownershipTypeName']}
                                label='Ownership Type'
                            />
                        </Grid>
                        <Grid item md={6}>
                            <FmTextField
                                label='Remarks'
                                name='remarks'
                                control={control}
                                multiline={true}
                                rows={2}
                                maxRows={3}
                            />
                        </Grid>
                    </Grid>
                    <Grid item md={6}>
                        <ActionButtons
                            onSubmit={handleSubmit(handleSubmitAssetGroup)}
                            onCancel={handleCreateClose}
                            onReset={reset}
                            submitLoading={createLoading}
                            cancelLoading={false}
                            submitText='Create'
                        />
                    </Grid>
                </form>
            </MuiDialogOne>

            <MuiDialogOne
                title='View Details'
                open={openView}
                onClose={handleViewClose}
            >
                {loading ? (
                    <CommonLoader />
                ) : (
                    <Grid container spacing={3}>
                        <Grid item md={6}>
                            <ViewField label="Base Unit" title={viewData?.baseUnitName || 'N/A'} />
                        </Grid>
                        <Grid item md={6}>
                            <ViewField label="Unit Type" title={viewData?.unitTypeName || 'N/A'} />
                        </Grid>
                        <Grid item md={6}>
                            <ViewField label="Floor" title={viewData?.floorName || 'N/A'} />
                        </Grid>
                        <Grid item md={6}>
                            <ViewField label="Base Unit" title={viewData?.baseUnitName || 'N/A'} />
                        </Grid>
                        <Grid item md={6}>
                            <ViewField label="Wing" title={viewData?.wingName || 'N/A'} />
                        </Grid>
                        <Grid item md={6}>
                            <ViewField label="Unit Area" title={viewData?.unitArea || 'N/A'} />
                        </Grid>
                        <Grid item md={6}>
                            <ViewField label="UOM" title={viewData?.unitOfMeasurementName || 'N/A'} />
                        </Grid>
                        <Grid item md={6}>
                            <ViewField label="Unit Usage" title={viewData?.unitUsageName || 'N/A'} />
                        </Grid>
                        <Grid item md={6}>
                            <ViewField label="Unit Configuration" title={viewData?.unitConfigurationName || 'N/A'} />
                        </Grid>
                        <Grid item md={6}>
                            <ViewField
                                label="Balcony Availability"
                                title={viewData?.balconyAvailability === true ? 'Yes' : viewData?.balconyAvailability === false ? 'No' : 'N/A'}
                            />
                        </Grid>
                        <Grid item md={6}>
                            <ViewField label="Owner" title={viewData?.ownerName || 'N/A'} />
                        </Grid>
                        <Grid item md={6}>
                            <ViewField label="Tenant" title={viewData?.tenantName || 'N/A'} />
                        </Grid>
                        <Grid item md={6}>
                            <ViewField label="Sub Unit Type" title={viewData?.subUnitTypeName || 'N/A'} />
                        </Grid>
                        <Grid item md={6}>
                            <ViewField label="Ownership Type" title={viewData?.ownershipTypeName || 'N/A'} />
                        </Grid>
                        <Grid item md={6}>
                            <ViewField label="Remarks" title={viewData?.remarks || 'N/A'} />
                        </Grid>
                        <Grid item md={6}>
                            <ViewField label={t('status')} title={viewData?.statusName || 'N/A'} />
                        </Grid>
                    </Grid>
                )}
            </MuiDialogOne>

            <MuiDialogOne
                title={t('update')}
                open={editModal}
                onClose={() => setEditModal(false)}
            >
                {loading ? (
                    <CommonLoader />
                ) : (
                    <form onSubmit={handleSubmit(handleEditUnitConfigurations)}>
                        <Grid container spacing={3}>
                            <Grid item md={6}>
                                <FmTextField
                                    name='baseUnitName'
                                    control={control}
                                    label='Base Unit Name'
                                    required
                                />
                            </Grid>
                            <Grid item md={6}>
                                <FmAutoComplete
                                    name='unitTypeId'
                                    control={control}
                                    options={unittypes}
                                    label='Unit Types'
                                    displayField='unitTypeName'
                                    optionFields={['unitTypeName']}
                                    valueKey='unitTypeId'
                                    required
                                />
                            </Grid>
                            <Grid item md={6}>
                                <FmAutoComplete
                                    name='floorId'
                                    control={control}
                                    options={floors}
                                    label='Floor'
                                    displayField='floorName'
                                    optionFields={['floorName']}
                                    valueKey='floorId'
                                    required
                                />
                            </Grid>

                            <Grid item md={6}>
                                <FmSearchableSelect
                                    name='parentBaseUnitId'
                                    control={control}
                                    apiUrl='baseunits'
                                    valueField='baseUnitId'
                                    labelField={['baseUnitCode', 'baseUnitName']}
                                    showField={['baseUnitCode', 'baseUnitName']}
                                    label='Unit'
                                    required
                                />
                            </Grid>
                            <Grid item md={6}>
                                <FmSearchableSelect
                                    name='wingId'
                                    control={control}
                                    apiUrl='wings'
                                    valueField='wingId'
                                    labelField={['wingCode', 'wingName']}
                                    showField={['wingCode', 'wingName']}
                                    label='Wings'
                                />
                            </Grid>
                            <Grid item md={6}>
                                <FmTextField
                                    name='unitArea'
                                    control={control}
                                    label='Unit Area'
                                />
                            </Grid>
                            <Grid item md={6}>
                                <FmSearchableSelect
                                    name='unitOfMeasurementId'
                                    control={control}
                                    apiUrl='unitofmeasurements'
                                    valueField='unitOfMeasurementId'
                                    labelField={['unitOfMeasurementCode', 'unitOfMeasurementName']}
                                    showField={['unitOfMeasurementCode', 'unitOfMeasurementName']}
                                    label='UOM'
                                />
                            </Grid>
                            <Grid item md={6}>
                                <FmSearchableSelect
                                    name='unitUsageId'
                                    control={control}
                                    apiUrl='unitusages'
                                    valueField='unitUsageId'
                                    labelField={['unitUsageCode', 'unitUsageName']}
                                    showField={['unitUsageCode', 'unitUsageName']}
                                    label='Unit Usage'
                                />
                            </Grid>
                            <Grid item md={6}>
                                <FmSearchableSelect
                                    name='unitConfigurationId'
                                    control={control}
                                    apiUrl='unitconfiguration'
                                    valueField='unitConfigurationId'
                                    labelField={['unitConfigurationCode', 'unitConfigurationName']}
                                    showField={['unitConfigurationCode', 'unitConfigurationName']}
                                    label='Unit Configuration'
                                />
                            </Grid>
                            <Grid item md={6}>
                                <FmAutoComplete
                                    name='balconyAvailability'
                                    control={control}
                                    options={BooleanOptions}
                                    label='Balcony Availability'
                                    displayField='label'
                                    optionFields={['label']}
                                    valueKey='value'
                                />
                            </Grid>
                            <Grid item md={6}>
                                <FmTextField
                                    name='ownerName'
                                    control={control}
                                    label='Owner Name'
                                />
                            </Grid>
                            <Grid item md={6}>
                                <FmTextField
                                    name='tenantName'
                                    control={control}
                                    label='Tenant Name'
                                />
                            </Grid>
                            <Grid item md={6}>
                                <FmSearchableSelect
                                    name='subUnitTypeId'
                                    control={control}
                                    apiUrl='subunittypes'
                                    valueField='subUnitTypeId'
                                    labelField={['subUnitTypeCode', 'subUnitTypeName']}
                                    showField={['subUnitTypeCode', 'subUnitTypeName']}
                                    label='Sub Unit Type'
                                />
                            </Grid>
                            <Grid item md={6}>
                                <FmSearchableSelect
                                    name='ownershipTypeId'
                                    control={control}
                                    apiUrl='ownershiptypes'
                                    valueField='ownershipTypeId'
                                    labelField={['ownershipTypeCode', 'ownershipTypeName']}
                                    showField={['ownershipTypeCode', 'ownershipTypeName']}
                                    label='Ownership Type'
                                />
                            </Grid>
                            <Grid item md={6}>
                                <FmTextField
                                    label='Remarks'
                                    name='remarks'
                                    control={control}
                                    multiline={true}
                                    rows={2}
                                    maxRows={3}
                                />
                            </Grid>   <Grid item md={6}>
                                <ActionButtons
                                    onSubmit={handleSubmit(handleEditUnitConfigurations)}
                                    onCancel={() => setEditModal(false)}
                                    submitLoading={editLoading}
                                    onReset={reset}
                                    cancelLoading={false}
                                    submitText='Update'
                                />
                            </Grid>
                        </Grid>
                    </form>
                )}
            </MuiDialogOne>
        </DashboardLayout>
    );
};

export default BaseUnit; 