import React, { useEffect, useState } from 'react';
import GlassCard from './../../../../components/small/GlassCard.tsx';
import { Box, Button } from '@mui/material';
import useTableLogic from '../../../../components/helpers/MRTUseTableLogic.tsx';
import Icons from '../../../../utils/Icon.jsx';
import Crud_Service from '../../../../apis/CrudService.jsx';
import { toast } from 'react-toastify';
import dayjs from 'dayjs';
import { useForm } from 'react-hook-form';
import TableActionButtons from '../../../../components/_form/TableActionButtons.jsx';
import FmMRTDataTable from '../../../../components/FmMRTDataTable.tsx';
import { yupResolver } from '@hookform/resolvers/yup';
import { editSalesQuotationSchema } from '../../../../utils/CommonvalidationSchemas.tsx';
import { useNavigate } from 'react-router-dom';
import StatusNewBadge from '../../../../components/_form/StatusNewBadge.tsx';

const CreateSalesQuotationList = (props) => {
  const { type, getData } = props;
  const {
    rows,
    isLoading,
    pagination,
    sorting,
    columnFilters,
    globalFilter,
    tableRecordCounts,
    setPagination,
    setSorting,
    setColumnFilters,
    setGlobalFilter,
    fetchData,
    setSearchKeyword,
    searchKeyword,
  } = useTableLogic('salesquotations');
  const crud = new Crud_Service();
  const navigate = useNavigate();
  const { handleSubmit } = useForm({
    resolver: yupResolver(editSalesQuotationSchema),
    mode: 'onChange',
  });
  const [editingRowData, setEditingRowData] = useState([]);
  const [editingRowId, setEditingRowId] = useState(null);

  useEffect(() => {
    getData && fetchData();
  }, [getData]);

  const handleDeleteSalesQuotation = async (view) => {
    await crud.remove(
      'salesquotations',
      view?.salesQuotationId,
      (_err, res) => {
        if (res?.status === 204) {
          fetchData();
          toast.success('Deleted Successfully');
        } else {
        }
      }
    );
  };

  const ActionData = [
    {
      name: 'Edit',
      icon: Icons.edit,
      onClick: (props) => navigate(`edit/${props?.salesQuotationId}`),
    },
    {
      name: 'Delete',
      icon: Icons.delete,
      onClick: (props) => handleDeleteSalesQuotation(props),
      danger: true,
    },
  ];

  const handleEditItem = async (values) => {
    const data = {
      ...values,
      quotationDate: dayjs(values?.quotationDate).format('YYYY-MM-DD'),
      contactPersonId:
        values.contactPersonId || editingRowData?.contactPersonId,
      companyId: values?.companyId || editingRowData?.companyId,
      regionId: values.regionId || editingRowData?.regionId,
      workOrderId: values.workOrderId || editingRowData?.workOrderId,
      quotationStatusId:
        values.quotationStatusId || editingRowData?.quotationStatusId,
      status: 2,
      salesOrderTypeId: type,
    };
    setEditingRowId(values?.salesQuotationId);
    setEditingRowData(values);
    await crud.update(
      'salesquotations',
      editingRowData?.salesQuotationId,
      data,
      (_err, res) => {
        if (res?.status === 200) {
          toast.success('Updated Successfully');
          fetchData();
          setEditingRowId(null);
        } else {
        }
      }
    );
  };
  const columns = [
    {
      header: 'Actions',
      Cell: ({ row }) => (
        <Box className='flex gap-2'>
          {editingRowId === row?.original?.salesQuotationId ? (
            <>
              <Button onClick={handleSubmit(handleEditItem)}>Save</Button>
              <Button onClick={handleCancel}>Cancel</Button>
            </>
          ) : (
            <TableActionButtons
              Actions={ActionData?.map((action) => ({
                ...action,
                onClick: () => action.onClick(row?.original),
              }))}
            />
          )}
        </Box>
      ),
      size: 100,
    },
    {
      accessorKey: 'salesQuotationName',
      header: 'Sales Quotation Name',
      enableEditing: true,
    },

    {
      accessorKey: 'contractName',
      header: 'Contract Name',
      enableEditing: true,
    },
    {
      accessorKey: 'branchName',
      header: 'Branch Name',
      enableEditing: true,
    },
    {
      accessorKey: 'paymentTermName',
      header: 'Payment Terms',
      enableEditing: true,
    },
    {
      accessorKey: 'narration',
      header: 'Narration',
      enableEditing: true,
    },
    {
      accessorKey: 'deliveryTerms',
      header: 'Delivery Terms',
      enableEditing: true,
    },

    {
      accessorKey: 'contactPersonName',
      header: 'Contact Person',
      enableEditing: true,
    },
    {
      accessorKey: 'quotationValidity',
      header: 'Quotation Validity(Days)',
      enableEditing: true,
      size: 200,
    },
    {
      accessorKey: 'quotationStatusName',
      header: 'Quotation Status',
      size: 150,
      enableEditing: true,
      Cell: ({ cell }) => {
        return (
          <StatusNewBadge
            title={cell?.row?.original?.quotationStatusName}
            type={cell?.row?.original?.quotationStatusName}
          />
        );
      },
    },
    {
      accessorKey: 'remarks',
      header: 'Remarks',
      enableEditing: true,
    },
    {
      accessorKey: 'contractTypeName',
      header: 'Type',
      enableEditing: true,
    },
  ];

  const handleCancel = () => {
    setEditingRowId(null);
    setEditingRowData(null);
  };

  return (
    <>
      <GlassCard className='h-full-css'>
        <FmMRTDataTable
          columns={columns}
          enableRowSelection={false}
          rows={rows}
          rowCount={tableRecordCounts}
          editingRow={editingRowId}
          pagination={pagination}
          sorting={sorting}
          columnFilters={columnFilters}
          globalFilter={globalFilter}
          setPagination={setPagination}
          setSorting={setSorting}
          setColumnFilters={setColumnFilters}
          setGlobalFilter={setGlobalFilter}
          isLoading={isLoading}
          setSearchKeyword={setSearchKeyword}
          searchKeyword={searchKeyword}
          muiTableContainerProps={{
            sx: {
              maxHeight: '100%',
              flex: 1,
            },
          }}
        />
      </GlassCard>
    </>
  );
};

export default CreateSalesQuotationList;
