import React, { useEffect, useMemo } from 'react';
import DashboardLayout from '../../../../components/DashboardLayout.tsx';
import AddPettyCash from './AddPettyCash.tsx';
import ListPettyCash from './ListPettyCash.tsx';
import useTableLogic from '../../../../components/helpers/MRTUseTableLogic.tsx';
import { useNavigate } from 'react-router-dom';
import { Box } from '@mui/material';
import { CreateButton } from '../../../../components/small/Buttons.jsx';
import { findNavByName } from '../../../../utils/navUtils.ts';
import { useSelector } from 'react-redux';

const PettyCash = () => {
  const {
    rows,
    isLoading,
    pagination,
    sorting,
    columnFilters,
    globalFilter,
    tableRecordCounts,
    setPagination,
    setSorting,
    setColumnFilters,
    setGlobalFilter,
    fetchData,
    setSearchKeyword,
    searchKeyword,
  } = useTableLogic(`pettycashtransactions`);
  const navigate = useNavigate();
  useEffect(() => {
    document.title = 'Petty Cash| Cash and Bank | Financial';
  }, []);

  const menu = useSelector((state) => state?.menu?.items);

  // Flatten menu only once and store it for reuse
  const SubMenu = useMemo(() => {
    return menu?.map((item) => item?.menus?.flat()).flat() || [];
  }, [menu]);
  const result = useMemo(
    () => findNavByName(SubMenu, 'Financial', 'Cash and Bank'),
    [SubMenu]
  );

  return (
    <DashboardLayout
      title={'Petty Cash'}
      hasSubmenu
      menu={result}
      actionButtons={
        <>
          <Box className='flex flex-row items-center whitespace-nowrap gap-3'>
            <CreateButton name='Create' onClick={() => navigate('create')} />
          </Box>
        </>
      }
    >
      <ListPettyCash
        rows={rows}
        tableRecordCounts={tableRecordCounts}
        pagination={pagination}
        sorting={sorting}
        fetchData={fetchData}
        setPagination={setPagination}
        setSorting={setSorting}
        columnFilters={columnFilters}
        globalFilter={globalFilter}
        setColumnFilters={setColumnFilters}
        setGlobalFilter={setGlobalFilter}
        isLoading={isLoading}
        setSearchKeyword={setSearchKeyword}
        searchKeyword={searchKeyword}
      />
    </DashboardLayout>
  );
};

export default PettyCash;
