import React from 'react';
import DashboardLayout from '../../../components/DashboardLayout.tsx';
import { Box, Grid } from '@mui/material';
import GlassCard from '../../../components/small/GlassCard.tsx';
import { ActionButtons } from '../../../components/_form';
import { useNavigate } from 'react-router-dom';
import Crud_Service from '../../../apis/CrudService';
import { useForm } from 'react-hook-form';
import { useTranslation } from 'react-i18next';
import { LocalizationProvider } from '@mui/x-date-pickers/LocalizationProvider';
import { AdapterDayjs } from '@mui/x-date-pickers/AdapterDayjs';
import useLoader from '../../../components/helpers/UseLoader.tsx';
import FmTextField from '../../../components/_mui/FmTextField.tsx';
import { toast } from 'react-toastify';
import * as Yup from 'yup';
import { yupResolver } from '@hookform/resolvers/yup';

const Createclient = () => {
  const navigate = useNavigate();
  const crud = new Crud_Service();
  const validationSchema = Yup.object().shape({
    clientName: Yup.string()
      .required('Client Name is required')
      .min(2, 'Client Name must be at least 2 characters')
      .max(100, 'Client Name must not exceed 100 characters'),
    contactEmailId: Yup.string()
      .required('Contact email ID is required')
      .test(
        'is-valid-email',
        'Contact email ID must be a valid email address',
        (value) =>
          /^[a-zA-Z0-9._%+-]+@[a-zA-Z0-9.-]+\.[a-zA-Z]{2,}$/.test(value)
      ),
    clientIndustry: Yup.string().required('Client Industry is required'),
    contactPerson: Yup.string().required('Contact Person is required'),
    contactNumber: Yup.string()
      .required('Contact Number is required')
      .min(8, 'Contact Number cannot be less than 8')
      .max(13, 'Contact Number cannot exceed 13'),
    headOfficeAddress: Yup.string().required('Head Office Address is required'),
  });

  const { handleSubmit, control, reset } = useForm({
    resolver: yupResolver(validationSchema),
    mode: 'onChange',
  });
  const { createLoading, startCreateLoading, stopCreateLoading } = useLoader();
  const { t } = useTranslation();

  const handleSubmitProject = async (values) => {
    startCreateLoading();

    await crud.create(`clients`, values, (_err, res) => {
      if (res?.status === 201) {
        toast.success(t('client Created Successfully'));
        navigate(-1);
        stopCreateLoading();
      } else {
        stopCreateLoading();
      }
    });
  };

  return (
    <DashboardLayout title='Create New Client'>
      <Box className='mx-auto' sx={{ maxWidth: '800px' }}>
        <GlassCard className='p-5'>
          <form onSubmit={handleSubmit(handleSubmitProject)}>
            <LocalizationProvider dateAdapter={AdapterDayjs}>
              <Grid container spacing={3}>
                <Grid item md={6}>
                  <FmTextField
                    name='clientName'
                    control={control}
                    label='Client Name'
                    required
                  />
                </Grid>
                <Grid item md={6}>
                  <FmTextField
                    name='clientIndustry'
                    control={control}
                    label='Client Industry'
                    required
                  />
                </Grid>
                <Grid item md={12}>
                  <FmTextField
                    name='headOfficeAddress'
                    control={control}
                    label='Head Office Address'
                    multiline
                    rows={3}
                    maxRows={4}
                    required
                  />
                </Grid>

                <Grid item md={6}>
                  <FmTextField
                    name='contactPerson'
                    control={control}
                    label='Contact Person'
                    required
                  />
                </Grid>

                <Grid item md={6}>
                  <FmTextField
                    name='contactNumber'
                    control={control}
                    label='Contact Number'
                    pattern='Number'
                    required
                  />
                </Grid>
                <Grid item md={6}>
                  <FmTextField
                    name='contactEmailId'
                    control={control}
                    label='Contact Email'
                    type='email'
                    required
                  />
                </Grid>
              </Grid>
              <ActionButtons
                onSubmit={handleSubmit(handleSubmitProject)}
                onCancel={() => navigate(-1)}
                onReset={reset}
                submitLoading={createLoading}
                cancelLoading={false}
                submitText='Create'
              />
            </LocalizationProvider>
          </form>
        </GlassCard>
      </Box>
    </DashboardLayout>
  );
};

export default Createclient;
