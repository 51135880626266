import React from 'react';
import { Box, TextField } from '@mui/material';
import { useSelector } from 'react-redux';
import { Controller } from 'react-hook-form';

const FmCurrencyTextField = ({
  name,
  control,
  label,
  onChange,
  onBlur,
  readOnly = false,
  required = false,
  disabled = false,
  className,
}) => {
  const currency = useSelector((state) => state?.settings?.currency);

  // Currency settings for formatting
  const currencySettings = {
    INR: {
      intlConfig: { locale: 'en-IN', currency: 'INR' },
      formatValue: (val) =>
        new Intl.NumberFormat('en-IN', {
          style: 'currency',
          currency: 'INR',
          minimumFractionDigits: 0,
        }).format(val || 0),
    },
    AED: {
      prefix: 'AED ', // AED prefix
      groupSeparator: ',',
      decimalSeparator: '.',
      decimalsLimit: 2,
    },
  };

  const getFormattedValue = (value) => {
    if (value === null || value === undefined) {
      return '';
    }

    let formattedValue = value;

    Object.keys(currencySettings).forEach((currencyKey) => {
      if (currency === currencyKey && value) {
        const settings = currencySettings[currencyKey];

        if (currencyKey === 'INR') {
          const parsedValue = parseFloat(value);
          formattedValue = settings.formatValue(parsedValue);
        } else if (currencyKey === 'AED') {
          formattedValue = value.replace(
            /\B(?=(\d{3})+(?!\d))/g,
            settings.groupSeparator
          );
          formattedValue = `${settings.prefix}${formattedValue}`;
        }
      }
    });

    return formattedValue;
  };

  return (
    <Box className='flex w-full items-center gap-4'>
      <Box className='flex-1'>
        <Controller
          name={name}
          control={control}
          render={({ field }) => {
            const formattedValue = getFormattedValue(field.value?.toString());
            return (
              <TextField
                {...field}
                id='currency-input'
                name='currency-input'
                className={className}
                label={label ? label : ` Enter amount in ${currency}`}
                value={formattedValue}
                onChange={(e) => {
                  const rawValue = e.target.value.replace(/[^\d]/g, ''); // Remove non-numeric characters
                  field.onChange(rawValue); // Update the field value

                  // Call the custom onChange prop if provided
                  if (onChange) {
                    onChange(e);
                  }
                }}
                required={required}
                inputProps={{
                  ...(readOnly ? { readOnly: true } : { min: 0 }),
                }}
                fullWidth
                variant='filled'
                onBlur={() => {
                  field.onBlur();
                  onBlur?.();
                }}
                disabled={disabled ? true : false}
              />
            );
          }}
        />
      </Box>
    </Box>
  );
};

export default FmCurrencyTextField;
