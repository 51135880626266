import React, { useState } from 'react';
import { useForm } from 'react-hook-form';
import FmAutoComplete from '../../../../components/_mui/FmAutoComplete.tsx';
import ActionButtons from '../../../../components/_form/ActionButtons.tsx';
import { Grid } from '@mui/material';
import GlassCard from '../../../../components/small/GlassCard.tsx';
import { useNavigate } from 'react-router-dom';
import FmFileInput from '../../../../components/_mui/FmFileInput.tsx';
import FmDatePicker from '../../../../components/_mui/FmDatePicker.tsx';
import useCommonFetchApi from '../../../../components/helpers/useCommonFetchApi.tsx';
import FmCurrencyTextField from '../../../../components/_mui/FmCurrencyTextField.tsx';
import Crud_Service from '../../../../apis/CrudService.jsx';
import { toast } from 'react-toastify';
import useLoader from '../../../../components/helpers/UseLoader.tsx';
import dayjs from 'dayjs';
import { yupResolver } from '@hookform/resolvers/yup';
import { ReconciliationSchema } from '../../../../utils/CommonvalidationSchemas.tsx';
import { BooleanOptions } from '../../../../utils/CommonVariables.tsx';
import BackToButton from '../../../../components/small/BackToButton.tsx';
import DashboardLayout from '../../../../components/DashboardLayout.tsx';

const PRReconciliationForm = (props) => {
  const { fetchData } = props;
  const gridName = 2;
  const navigate = useNavigate();
  const [loading, setLoading] = useState(false);
  const crud = new Crud_Service();
  const [fileId, setFileId] = useState(null);
  const { control, handleSubmit, reset, setValue, getValues, watch } = useForm({
    resolver: yupResolver(ReconciliationSchema),
    mode: 'onChange',
  });
  const { startCreateLoading, createLoading, stopCreateLoading } = useLoader();
  const { data: bankaccounts } = useCommonFetchApi('bankaccounts') || {
    data: [],
  };

  const handleFileUpload = async (event) => {
    setLoading(true);
    const file = event.target.files[0];
    if (file) {
      const fileFormData = new FormData();
      fileFormData.append('file', file);
      fileFormData.append('fileTypeId', 33);
      try {
        const response = await new Promise((resolve, reject) => {
          crud.create('files/upload', fileFormData, (_err, res) => {
            if (res?.status === 200) {
              resolve(res);
              setLoading(false);
            } else {
              reject(new Error('File upload failed'));
              setLoading(false);
            }
          });
        });
        setFileId(response?.data?.fileId);
        setValue(`documentName`, response?.data?.originalFileName);
      } catch (error) {
        setLoading(false);
      } finally {
        setLoading(false);
      }
    }
  };
  const submitReconciliation = async (values) => {
    startCreateLoading();
    const data = {
      ...values,
      startDate: dayjs(values?.startDate).format('YYYY-MM-DD'),
      endDate: dayjs(values?.endDate).format('YYYY-MM-DD'),
      openingBalance: values?.openingBalance || 0,
      closingBalance: values?.closingBalance || 0,
      fileId: fileId,
    };
    await crud.create('customerAccountStatements', data, (_err, res) => {
      if (res?.status === 201) {
        toast.success('Reconciliation Created Successfully');
        navigate('/financial/reconciliation/bank-statements');
        stopCreateLoading();
        reset();
        fetchData();
      } else {
        stopCreateLoading();
      }
    });
  };
  return (
    <DashboardLayout
      title={'Create Bank Statements'}
      titleReverse
      actionButtons={
        <BackToButton
          title='Back to List'
          className='px-0 py-0'
          onClick={() => navigate('/financial/reconciliation/bank-statements')}
        />
      }
    >
      <GlassCard component={'form'} className='p-4'>
        <form onSubmit={handleSubmit(submitReconciliation)}>
          <Grid container spacing={2}>
            <Grid item md={gridName}>
              <FmAutoComplete
                name='bankAccountId'
                control={control}
                options={bankaccounts}
                label='Account'
                displayField='bankAccountCode'
                optionFields={['bankAccountCode']}
                valueKey='bankAccountId'
                required
              />
            </Grid>

            <Grid item md={gridName}>
              <FmFileInput
                name={`fileId`}
                documentName={getValues(`documentName`)}
                onChange={(e) => handleFileUpload(e)}
                loading={loading}
              />
            </Grid>

            <Grid item md={gridName}>
              <FmCurrencyTextField
                name='openingBalance'
                label='Beginning balance'
                control={control}
              />
            </Grid>
            <Grid item md={gridName}>
              <FmCurrencyTextField
                name='closingBalance'
                label='Ending balance'
                control={control}
              />
            </Grid>
            <Grid item md={gridName}>
              <FmDatePicker
                name='startDate'
                label='Start Date'
                control={control}
              />
            </Grid>
            <Grid item md={gridName}>
              <FmDatePicker
                name='endDate'
                label='Ending Date'
                control={control}
                defaultValue={dayjs().add(1, 'day')}
              />
            </Grid>
            <Grid item md={gridName}>
              <FmAutoComplete
                name='reconcileStatus'
                control={control}
                options={BooleanOptions}
                label='Reconcile Status'
                displayField='label'
                optionFields={['label']}
                valueKey='value'
              />
            </Grid>
            <Grid item md={gridName}>
              <ActionButtons
                onSubmit={handleSubmit(submitReconciliation)}
                onReset={reset}
                submitLoading={createLoading}
                submitText='Create'
                submitFullWidth
                removeSpacing
              />
            </Grid>
          </Grid>
        </form>
      </GlassCard>
    </DashboardLayout>
  );
};

export default PRReconciliationForm;
