import { Icon } from '@iconify/react/dist/iconify.js';
import { Box, Button, Grid, IconButton } from '@mui/material';
import { LocalizationProvider } from '@mui/x-date-pickers';
import { AdapterDayjs } from '@mui/x-date-pickers/AdapterDayjs';
import dayjs from 'dayjs';
import React, { useEffect, useState } from 'react';
import { useFieldArray, useForm } from 'react-hook-form';
import { useNavigate, useParams } from 'react-router-dom';
import { toast } from 'react-toastify';
import Crud_Service from '../../../apis/CrudService.jsx';
import DashboardLayout from '../../../components/DashboardLayout.tsx';
import ActionButtons from '../../../components/_form/ActionButtons.tsx';
import FmAutoComplete from '../../../components/_mui/FmAutoComplete.tsx';
import FmDatePicker from '../../../components/_mui/FmDatePicker.tsx';
import FmFileInput from '../../../components/_mui/FmFileInput.tsx';
import FmSearchableSelect from '../../../components/_mui/FmSearchableSelect.tsx';
import FmTextField from '../../../components/_mui/FmTextField.tsx';
import useLoader from '../../../components/helpers/UseLoader.tsx';
import useCommonFetchApi from '../../../components/helpers/useCommonFetchApi.tsx';
import GlassCard from '../../../components/small/GlassCard.tsx';
import { yupResolver } from '@hookform/resolvers/yup';
import { followupscreatevalidationSchema } from '../../../utils/CommonvalidationSchemas.tsx';

const GridValue = 2.4;

const FollowupCreate = () => {
  const navigate = useNavigate();
  const [loader, setLoader] = useState(false);
  const [tenders, setTenders] = useState(null);
  const { id } = useParams();
  const { control, handleSubmit, reset, setValue, watch, trigger, formState: { errors } } = useForm({
    resolver: yupResolver(followupscreatevalidationSchema),
  });
  const [followUpType, setFollowUpType] = useState(null);

  const [uploadLoading, setUploadLoading] = useState({});
  const [uploadedFileData, setUploadedFileData] = useState({});
  const { startCreateLoading, stopCreateLoading, createLoading } = useLoader();
  const rescheduleDate = watch('scheduleDate');
  const remainderDate = watch('remainderDate');
  const crud = new Crud_Service();

  const defaultValues = {
    tenderId: tenders?.tenderId,
    tenderName: tenders?.tenderName,
  };

  const { fields, append, remove, update } = useFieldArray({
    control,
    name: 'followUpSchedules',
  });

  const {
    fields: attachmentFields,
    append: appendAttachment,
    remove: removeAttachment,
  } = useFieldArray({
    control,
    name: 'attachments',
  });

  useEffect(() => {
    getTender();
  }, []);

  //gte All Apis
  const { data: tenderfollowuptypes } = useCommonFetchApi(
    'tenderfollowuptypes'
  );
  const { data: contactmodes } = useCommonFetchApi('contactmodes');

  const getTender = async () => {
    setLoader(true);
    await crud.getSingle('tenders', id, (err, res) => {
      if (err) {
        toast.error('Error fetching Tenders:', err);
        return;
      }
      if (res?.status === 200) {
        setTenders(res?.data);
        setLoader(false);
        setValue('tenderId', res?.data?.tenderId);
        setValue('tenderName', res?.data?.tenderName);
      }
    });
  };

  const handleFileUpload = async (e, index) => {
    const file = e.target.files[0];
    if (!file) return;

    setUploadLoading((prev) => ({ ...prev, [index]: true }));

    const formData = new FormData();
    formData.append('file', file);
    formData.append('fileTypeId', 64);

    await crud.create('/files/upload', formData, (err, res) => {
      setUploadLoading((prev) => ({ ...prev, [index]: false }));

      if (err || res?.status !== 200) {
        toast.error('File upload failed');
        return;
      }

      setUploadedFileData((prev) => ({
        ...prev,
        [index]: res?.data,
      }));
    });
  };

  const handleAddNestedField = (index: number) => {
    const updatedFields = [...fields];
    updatedFields[index].createTenderFollowUpAssigns.push({
      assignId: '',
      name: '',
      email: '',
      contactNo: '',
    });
    update(index, updatedFields[index]);
  };

  const handleRemoveNestedField = (outerIndex: number, innerIndex: number) => {
    const updatedFields = [...fields];
    updatedFields[outerIndex].createTenderFollowUpAssigns.splice(innerIndex, 1);
    update(outerIndex, updatedFields[outerIndex]);
  };

  //Create Followups
  const handleSubmitTenderFollowups = async (values) => {
    startCreateLoading();
    const uploadedFilesArray = Object.values(uploadedFileData);

    const combinedData = {
      ...values,
      scheduleDate: dayjs(values?.scheduleDate).format('YYYY-MM-DD'),
      reminderDate: dayjs(values?.reminderDate).format('YYYY-MM-DD'),
      attachments: values?.attachments?.map((note, index) => ({
        ...note,
        fileId: uploadedFilesArray[index]?.fileId || null,
        remarks: note?.remarks || null,
      })),
      followUpSchedules: values?.followUpSchedules?.map((assign) => ({
        ...assign,
        scheduleDate: dayjs(assign?.scheduleDate).format('YYYY-MM-DD'),
        reminderDate: dayjs(assign?.reminderDate).format('YYYY-MM-DD'),
        createTenderFollowUpAssigns: [
          {
            assignIds: assign?.createTenderFollowUpAssigns?.map(
              (item) => item?.assignId
            ),
          },
        ],
      })),
    };

    await crud.create(`tenderfollowups`, combinedData, (err, res) => {
      if (err) {
        stopCreateLoading();
        return;
      }
      if (res?.status === 201) {
        toast.success('Enquiry Followup Created Successfully');
        reset();
        stopCreateLoading();
        navigate(-1);
      } else {
        stopCreateLoading();
        return;
      }
    });
  };

  const handleChangeFollowUpTypes = (e) => {
    setFollowUpType(e);
  };

  const handleChangeLoginUsers = (e, index, index2) => {
    setValue(
      `followUpSchedules[${index}].createTenderFollowUpAssigns[${index2}].name`,
      e?.loginUserName
    );
    setValue(
      `followUpSchedules[${index}].createTenderFollowUpAssigns[${index2}].email`,
      e?.employeeMail
    );
    setValue(`followUpSchedules[${index}].createTenderFollowUpAssigns[${index2}].contactNo`, e?.employeeContactNo);
  };

  return (
    <DashboardLayout title='Create FollowUp'>
      <LocalizationProvider dateAdapter={AdapterDayjs}>
        <GlassCard className='mb-8 p-4'>
          <form onSubmit={handleSubmit(handleSubmitTenderFollowups)}>
            <Grid container spacing={2}>
              <Grid item md={GridValue}>
                <FmTextField
                  name='tenderName'
                  control={control}
                  label='Tender'
                  defaultValue={
                    tenders?.tenderId && tenders?.tenderName
                      ? defaultValues
                      : ''
                  }
                  readOnly
                />
              </Grid>
              <Grid item md={GridValue}>
                <FmTextField
                  name='primaryContactPerson'
                  control={control}
                  label='Primary Contact Person'
                  required
                />
              </Grid>
              <Grid item md={GridValue}>
                <FmTextField
                  name='primaryContactMail'
                  control={control}
                  label='Primary Contact Mail'
                  required
                />
              </Grid>
              <Grid item md={GridValue}>
                <FmTextField
                  name='primaryContactNumber'
                  control={control}
                  label='Primary Contact Number'
                  required
                />
              </Grid>
              <Grid item md={GridValue}>
                <FmTextField
                  name='secondaryContactPerson'
                  control={control}
                  label='Secondary Contact Person'
                />
              </Grid>
              <Grid item md={GridValue}>
                <FmTextField
                  name='secondaryContactMail'
                  control={control}
                  label='Secondary Contact Mail'
                />
              </Grid>
              <Grid item md={GridValue}>
                <FmTextField
                  name='secondaryContactNumber'
                  control={control}
                  label='Secondary Contact Number'
                />
              </Grid>
              <Grid item md={GridValue}>
                <FmDatePicker
                  name='scheduleDate'
                  label='Schedule Date'
                  control={control}
                  minDate={remainderDate ? dayjs(remainderDate) : null}
                />
              </Grid>
              <Grid item md={GridValue}>
                <FmAutoComplete
                  name='tenderFollowupTypeId'
                  control={control}
                  options={tenderfollowuptypes}
                  label='Enquiry Followup Type'
                  displayField='tenderFollowupTypeName'
                  optionFields={['tenderFollowupTypeName']}
                  valueKey='tenderFollowupTypeId'
                  required
                  onChangeValue={true}
                  onChange={(e) => handleChangeFollowUpTypes(e)}

                />
              </Grid>
              <Grid item md={GridValue}>
                <FmDatePicker
                  name='reminderDate'
                  label='Remainder Date'
                  control={control}
                  maxDate={rescheduleDate ? dayjs(rescheduleDate) : null}
                />
              </Grid>

              <Grid item md={GridValue * 2}>
                <FmTextField
                  name='comments'
                  control={control}
                  label='Comments'
                  multiline={true}
                  rows={1}
                  maxRows={3}
                />
              </Grid>
            </Grid>
            <Box className='mt-4'>
              {' '}
              {attachmentFields?.map((field, index) => (
                <GlassCard className='p-4 mb-3 flex items-center gap-5'>
                  <Grid item xs={12} container spacing={2} key={field?.id}>
                    <Grid item md={3}>
                      <FmFileInput
                        name={`attachments[${index}].fileId`}
                        documentName={
                          uploadedFileData[index]?.originalFileName || ''
                        }
                        onChange={(e) => handleFileUpload(e, index)}
                        loading={uploadLoading[index] || false}
                      />
                    </Grid>
                    <Grid item md={3}>
                      <FmDatePicker
                        name={`attachments[${index}].date`}
                        control={control}
                        label='Date'
                      />
                    </Grid>
                    <Grid item md={5}>
                      <FmTextField
                        name={`attachments[${index}].remarks`}
                        control={control}
                        label='Remarks'
                        multiline={true}
                        rows={2}
                        maxRows={3}
                      />
                    </Grid>
                    <Grid item md={1}>
                      <IconButton
                        onClick={() => {
                          removeAttachment(index);
                        }}
                        sx={{
                          padding: '1px',
                        }}
                        color='secondary'
                      >
                        <Icon icon='ic:baseline-clear' />
                      </IconButton>
                    </Grid>
                  </Grid>
                </GlassCard>
              ))}
            </Box>

            <Box className='mt-5 w-full flex justify-end'>
              <Button
                variant='text'
                onClick={() => appendAttachment({})}
                className='uppercase font-bold text-orange-500'
                startIcon={<Icon icon='ic:baseline-plus' />}
              >
                Attachments
              </Button>
            </Box>

            {followUpType && followUpType?.tenderFollowupTypeId === 11 && (
              <>
                {fields?.map((_field, index) => (
                  <GlassCard className='p-4 mb-3 mt-3 ml-5 flex flex-col gap-5'>
                    {_field?.createTenderFollowUpAssigns?.map(
                      (_field2, index2) => (
                        <Grid
                          container
                          spacing={2}
                          direction='row'
                          key={index2}
                        >
                          <Grid item md={GridValue}>
                            <FmSearchableSelect
                              name={`followUpSchedules[${index}].createTenderFollowUpAssigns[${index2}].assignId`}
                              control={control}
                              apiUrl='loginUsers'
                              valueField='loginUserId'
                              headerField={[
                                'Code', 'Name', 'Mail'
                              ]}
                              labelField={[
                                'employeeCode',
                                'employeeName',
                                'employeeMail',
                              ]}
                              showField={[
                                'employeeCode',
                                'employeeName',
                                'employeeMail',
                              ]}
                              label='Assigned To'
                              onChangeProp={(e) =>
                                handleChangeLoginUsers(e, index, index2)
                              }
                            />
                          </Grid>

                          <Grid item md={GridValue}>
                            <FmTextField
                              name={`followUpSchedules[${index}].createTenderFollowUpAssigns[${index2}].email`}
                              control={control}
                              label='Email'
                              disabled={true}
                            />
                          </Grid>
                          <Grid item md={GridValue}>
                            <FmTextField
                              name={`followUpSchedules[${index}].createTenderFollowUpAssigns[${index2}].contactNo`}
                              control={control}
                              label='Contact No'
                              disabled={true}
                            />
                          </Grid>
                          <Grid item md={GridValue}>
                            <Button
                              startIcon={<Icon icon='ion:remove' />}
                              className='p-0 text-orange-500 font-semibold disabled:text-gray-300'
                              onClick={() =>
                                handleRemoveNestedField(index, index2)
                              }
                            >
                              Remove
                            </Button>
                          </Grid>
                        </Grid>
                      )
                    )}

                    <Box className='mt-5 w-full flex justify-end'>
                      <Button
                        variant='text'
                        onClick={() => handleAddNestedField(index)}
                        className='uppercase font-bold text-orange-500'
                        startIcon={<Icon icon='ic:baseline-plus' />}
                      >
                        Add Assignee
                      </Button>
                    </Box>

                    <Grid container spacing={2}>
                      <Grid item md={GridValue}>
                        <FmDatePicker
                          name={`followUpSchedules[${index}].scheduleDate`}
                          label='Schedule Date'
                          control={control}
                          required
                          minDate={dayjs()}
                          maxDate={dayjs(watch('scheduleDate'))}
                          onChange={(newDate) => {
                            setValue(`followUpSchedules[${index}].reminderDate`, null);
                            trigger(`followUpSchedules[${index}].reminderDate`);
                          }}
                          error={!!errors?.followUpSchedules?.[index]?.scheduleDate}
                          helperText={errors?.followUpSchedules?.[index]?.scheduleDate?.message}
                        // rules={{
                        //   validate: (value) => {
                        //     const scheduleDate1 = dayjs(watch('scheduleDate')); // Get value of scheduleDate-1
                        //     if (value && scheduleDate1.isBefore(value)) {
                        //       return "Schedule Date-2 must be before Schedule Date-1";
                        //     }
                        //     return true;
                        //   }
                        // }}
                        />
                      </Grid>
                      <Grid item md={GridValue}>
                        <FmDatePicker
                          name={`followUpSchedules[${index}].reminderDate`}
                          label='Remainder Date'
                          control={control}
                          required
                          minDate={dayjs()}
                          maxDate={watch(`followUpSchedules[${index}].scheduleDate`)}
                        />
                      </Grid>
                    </Grid>
                    <Button
                      startIcon={<Icon icon='ion:remove' />}
                      className='p-0 text-orange-500 font-semibold disabled:text-gray-300'
                      onClick={() => remove(index)}
                    >
                      Remove
                    </Button>
                  </GlassCard>
                ))}
              </>
            )}

            {followUpType && followUpType?.tenderFollowupTypeId === 11 && (
              <Box className='mt-5 w-full flex justify-end'>
                <Button
                  variant='text'
                  onClick={() =>
                    append({
                      createTenderFollowUpAssigns: [
                        { assignId: '', name: '', email: '', contactNo: '' },
                      ],
                      scheduleDate: null,
                      reminderDate: null,
                    })
                  }
                  className='uppercase font-bold text-orange-500'
                  startIcon={<Icon icon='ic:baseline-plus' />}
                >
                  Assignees
                </Button>
              </Box>
            )}
            <ActionButtons
              onSubmit={handleSubmit(handleSubmitTenderFollowups)}
              onCancel={() => navigate(-1)}
              submitLoading={createLoading}
              submitText='Create'
              cancelText='Go Back'
            />
          </form>
        </GlassCard>
      </LocalizationProvider>
    </DashboardLayout>
  );
};

export default FollowupCreate;
