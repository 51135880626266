import React, { useEffect, useState } from 'react';
import { Box, Grid } from '@mui/material';
import GlassCard from '../../../../components/small/GlassCard.tsx';
import FmSearchableSelect from '../../../../components/_mui/FmSearchableSelect.tsx';
import FmTextField from '../../../../components/_mui/FmTextField.tsx';
import { useForm } from 'react-hook-form';
import GlassCardCollapse from '../../../../components/small/GlassCardCollapse.tsx';
import useLoader from '../../../../components/helpers/UseLoader.tsx';
import { toast } from 'react-toastify';
import { useNavigate, useParams } from 'react-router-dom';
import FmDatePicker from '../../../../components/_mui/FmDatePicker.tsx';
import ActionButtons from '../../../../components/_form/ActionButtons.tsx';
import dayjs from 'dayjs';
import JobNotes from './JobNotes.tsx';
import { TicketValidationSchema } from '../../../../utils/CommonvalidationSchemas.tsx';
import { yupResolver } from '@hookform/resolvers/yup';
import useCommonFetchApi from '../../../../components/helpers/useCommonFetchApi.tsx';
import FmAutoComplete from '../../../../components/_mui/FmAutoComplete.tsx';

const JobDetails = (props) => {
  const { crud, fetchData } = props;
  const { id } = useParams();
  const {
    editLoading,
    startCreateLoading,
    stopCreateLoading,
    createLoading,
    stopEditLoading,
    startEditLoading,
  } = useLoader();
  const [params, setParams] = useState({});
  const [openCreate, setOpenCreate] = useState(false);
  const [editingRowId, setEditingRowId] = useState(null);
  const [loading, setLoading] = useState(false);
  const [ticketData, setTicketData] = useState({});
  const [editingRowData, setEditingRowData] = useState(null);
  const navigate = useNavigate();
  const { handleSubmit, control, setValue, reset, watch } = useForm({
    mode: 'onChange',
    resolver: yupResolver(TicketValidationSchema),
  });
  const { data: resources } = useCommonFetchApi('employees');
  const viewDataFetch = async () => {
    setLoading(true);
    await crud.getSingle('tickets', id, (err, res) => {
      if (res?.status === 200) {
        setTicketData(res?.data);
        Object.entries({
          ...res?.data,
        }).forEach(([key, value]) => {
          setValue(key, value);
        });
        setLoading(false);
      }
    });
  };

  const handleSubmitTickets = async (values) => {
    startEditLoading();
    const combinedData = {
      requestModeId: values?.requestModeId || 0,
      requesterPhone: values?.requesterPhone || '',
      requesterName: values?.requesterName || '',
      contractId: values?.contractId || 0,
      buildingId: values?.buildingId || 0,
      blockId: values?.blockId || 0,
      floorId: values?.floorId || 0,
      unitId: values?.unitId || 0,
      subUnitId: values?.subUnitId || 0,
      assetId: values?.assetId || 0,
      callClassificationId: values?.callClassificationId || 0,
      callerTypeId: values?.callerTypeId || 1,
      callTypeId: values?.callTypeId || 1,
      resolveDate: dayjs(values?.resolveDate).format('YYYY-MM-DD'),
      ticketStatusId: values?.ticketStatusId || 0,
      categoryId: values?.categoryId || 0,
      subCategoryId: values?.subCategoryId || 0,
      issueId: values?.issueId || 0,
      priorityId: values?.priorityId || 0,
      technicianId: values?.technicianId || 0,
      supervisorId: values?.supervisorId || 0,
      requesterEmail: values?.requesterEmail || '',
      unitType: values?.unitType || '',
      customerReferenceNumber: values?.customerReferenceNumber || '',
      clientId: values?.clientId || 0,
      siteId: values?.siteId || 0,
      aliasName: values?.aliasName || '',
      description: values?.description || '',
      specialComments: values?.specialComments || '',
      wingId: values?.wingId || 0,
      slaId: values?.slaId || 0,
      status: values?.status || 1,
      resources: [],
      attachments:
        values?.attachments?.map((att) => ({
          ticketAttachmentId: att?.ticketAttachmentId || 0,
          attachmentId: att?.attachmentId || 0,
          status: att?.status || 1,
        })) || [],
    };
    await crud.update('tickets', id, combinedData, (err, res) => {
      if (res?.status === 200) {
        toast.success('Ticket Updated Successfully');
        stopEditLoading();
        fetchData();
        setEditingRowData(null);
        setEditingRowId(null);
      } else {
        stopEditLoading();
      }
    });
  };

  useEffect(() => {
    if (id) {
      viewDataFetch();
    }
  }, [id]);

  const handleCreateClose = () => {
    reset();
    setOpenCreate(false);
  };

  return (
    <JobNotes
      handleCreateClose={handleCreateClose}
      openCreate={openCreate}
      setOpenCreate={setOpenCreate}
      navigate={navigate}
      createLoading={createLoading}
      id={id}
      editingRowId={editingRowId}
      fetchData={fetchData}
      setEditingRowId={setEditingRowId}
    />
  );
};

export default JobDetails;
