import { Box, Button } from '@mui/material';
import dayjs from 'dayjs';
import React, { useEffect, useState } from 'react';
import { useForm, get } from 'react-hook-form';
import { useLocation, useNavigate } from 'react-router-dom';
import { toast } from 'react-toastify';
import Crud_Service from '../../../../apis/CrudService.jsx';
import { ViewTable } from '../../../../components/_form/FormElements.jsx';
import TableActionButtons from '../../../../components/_form/TableActionButtons.jsx';
import FmSearchableSelect from '../../../../components/_mui/FmSearchableSelect.tsx';
import FmTextField from '../../../../components/_mui/FmTextField.tsx';
import MuiDialogOne from '../../../../components/_mui/MuiDialogOne.jsx';
import FmMRTDataTable from '../../../../components/FmMRTDataTable.tsx';
import useTableLogic from '../../../../components/helpers/MRTUseTableLogic.tsx';
import ViewTableLightText from '../../../../components/view/ViewTableLightText.tsx';
import Icons from '../../../../utils/Icon.jsx';
import GlassCard from './../../../../components/small/GlassCard.tsx';
import {
  ProjectName,
  ContractName,
  SalesOrderName,
  TotalAmount,
} from '../../../../components/table/MRTCommonColumns.tsx';
import StatusNewBadge from '../../../../components/_form/StatusNewBadge.tsx';
import CurrencyView from '../../../../components/_mui/FmCurrencyView.tsx';

const CreateSalesOrderList = (props) => {
  const { type, getData } = props;
  const [viewDetails, setViewdetails] = useState(null);
  const [editingRowData, setEditingRowData] = useState(null);
  const [editingRowId, setEditingRowId] = useState(null);
  const crud = new Crud_Service();
  const [viewRow, setViewRow] = useState([]);
  const { handleSubmit, control } = useForm();
  const {
    rows,
    isLoading,
    pagination,
    sorting,
    columnFilters,
    globalFilter,
    tableRecordCounts,
    setPagination,
    setSorting,
    setColumnFilters,
    setGlobalFilter,
    fetchData,
    setSearchKeyword,
    searchKeyword,
  } = useTableLogic(`salesorders?salesOrderTypeId=${type}`);

  useEffect(() => {
    fetchData();
  }, [type]);

  useEffect(() => {
    viewDetails && fetchSalesOrder();
  }, [viewDetails]);

  useEffect(() => {
    getData && fetchData();
  }, [getData]);

  const fetchSalesOrder = async () => {
    await crud.getSingle('salesorders', viewDetails, (_err, res) => {
      if (res?.status === 200) {
        setViewRow(res?.data);
      } else {
      }
    });
  };

  const handleEditItem = async (values) => {
    const data = {
      ...values,
      orderDate: dayjs(values?.orderDate).format('YYYY-MM-DD'),
      contactPersonId:
        values.contactPersonId || editingRowData?.contactPersonId,
      locationId: values.locationId || editingRowData?.locationId,
      regionId: values.regionId || editingRowData?.regionId,
      salesQuotationId:
        values.salesQuotationId || editingRowData?.salesQuotationId,
      orderStatusId: values.orderStatusId || editingRowData?.orderStatusId,
      status: 2,
      salesOrderTypeId: type,
    };
    setEditingRowId(values?.salesOrderId);
    setEditingRowData(values);
    await crud.update(
      'salesorders',
      editingRowData?.salesOrderId,
      data,
      (_err, res) => {
        if (res?.status === 200) {
          toast.success('Sales Order Updated Successfully');
          fetchData();
        } else {
        }
      }
    );
  };

  const handleDeleteSalesOrder = async (view) => {
    await crud.remove('salesorders', view?.salesOrderId, (_err, res) => {
      if (res?.status === 204) {
        fetchData();
        toast.success('Deleted Successfully');
      } else {
      }
    });
  };

  const navigate = useNavigate();
  const location = useLocation();
  const handleCancel = () => {
    setEditingRowId(null);
    setEditingRowData(null);
  };

  const ActionData = [
    {
      name: 'Edit',
      icon: Icons.edit,
      onClick: (_props) => {
        navigate(`${location.pathname}/view/${_props?.salesOrderId}`);
      },
    },
    {
      name: 'Delete',
      icon: Icons.delete,
      onClick: (props) => handleDeleteSalesOrder(props),
      danger: true,
    },

    // type === 2 && {
    //   name: 'Internal Budgeting',
    //   icon: <Icon icon='mdi:dollar' width='24' height='24' />,
    //   onClick: (props) => {
    //     navigate(`internal-budget/${props?.salesOrderId}`);
    //   },
    // },
  ];

  const columns = [
    {
      header: 'Actions',
      size: 80,
      minSize: 80,
      Cell: ({ row }) => (
        <Box className='flex gap-2'>
          {editingRowId === row?.original?.salesOrderId ? (
            <>
              <Button onClick={handleSubmit(handleEditItem)}>Save</Button>
              <Button onClick={handleCancel}>Cancel</Button>
            </>
          ) : (
            <TableActionButtons
              Actions={ActionData?.map((action) => ({
                ...action,
                onClick: () => action.onClick(row?.original),
              }))}
            />
          )}
        </Box>
      ),
    },
    {
      accessorKey: 'autoSalesOrderCode',
      header: 'Sales Order Code',
      size: 150,
    },
    {
      accessorKey: 'orderStatusId',
      header: 'Order Status',
      enableEditing: true,
      size: 130,
      Cell: ({ cell }) => {
        const order = rows?.find(
          (row) => row?.salesOrderId === cell?.row?.original?.salesOrderId
        );
        return editingRowId === cell?.row?.original?.salesOrderId ? (
          <FmSearchableSelect
            name='orderStatusId'
            control={control}
            apiUrl='salesorders/statuses'
            valueField='salesOrderStatusId'
            headerField={['salesOrderStatusName']}
            defaultValue={{
              salesOrderStatusId: editingRowData?.orderStatusId,
              salesOrderStatusName: editingRowData?.orderStatusName,
            }}
            labelField={['salesOrderStatusName']}
            showField={['salesOrderStatusName']}
            label={'Order Status'}
          />
        ) : (
          order?.orderStatusName && (
            <StatusNewBadge
              title={order?.orderStatusName}
              type={order?.orderStatusName}
            />
          )
        );
      },
    },
    SalesOrderName,
    ProjectName,
    ContractName,
    {
      accessorKey: 'contractTypeName',
      header: 'Type',
      size: 100,
      enableEditing: true,
      muiTableBodyCellProps: {
        className: 'bg-blue-50',
        align: 'center',
      },
      muiTableHeadCellProps: {
        align: 'center',
      },
    },

    {
      accessorKey: 'salesQuotationCode', // Searchable select for Sales Quotation
      header: 'Sales Quotation',
      enableEditing: true,
      size: 120,
    },
    {
      accessorKey: 'divisionName', // Searchable select for Sales Quotation
      header: 'Division',
      enableEditing: true,
      size: 150,
    },

    {
      accessorKey: 'paymentTermName', // Payment Terms field
      header: 'Payment Terms',
      enableEditing: true,
      Cell: ({ cell }) => {
        return <ViewTableLightText name={cell?.getValue() || '-'} />;
      },
    },

    {
      accessorKey: 'narration',
      header: 'Narration',
      enableEditing: true,
      size: 150,
      Cell: ({ cell }) => {
        const order = rows?.find(
          (row) => row?.salesOrderId === cell?.row?.original?.salesOrderId
        );
        return editingRowId === cell?.row?.original?.salesOrderId ? (
          <FmTextField
            name='narration'
            control={control}
            defaultValue={editingRowData?.narration}
            label='Narration'
          />
        ) : (
          order?.narration
        );
      },
    },
    {
      accessorKey: 'deliveryTerms',
      header: 'Delivery Terms',
      enableEditing: true,
      Cell: ({ cell }) => {
        const order = rows?.find(
          (row) => row?.salesOrderId === cell?.row?.original?.salesOrderId
        );
        return editingRowId === cell?.row?.original?.salesOrderId ? (
          <FmTextField
            name='deliveryTerms'
            control={control}
            defaultValue={editingRowData?.deliveryTerms}
            label='Delivery Terms'
          />
        ) : (
          order?.deliveryTerms
        );
      },
    },

    {
      accessorKey: 'remarks',
      header: 'Remarks',
      enableEditing: true,
      Cell: ({ cell }) => {
        const order = rows?.find(
          (row) => row?.salesOrderId === cell?.row?.original?.salesOrderId
        );
        return editingRowId === cell?.row?.original?.salesOrderId ? (
          <FmTextField
            name='remarks'
            control={control}
            defaultValue={editingRowData?.remarks}
            label='Remarks'
          />
        ) : (
          order?.remarks
        );
      },
    },
    {
      accessorKey: 'salesOrderGrossAmount',
      header: 'Gross',
      Cell: ({ cell }) => {
        return (
          <Box>
            <CurrencyView value={cell.getValue()} />
          </Box>
        );
      },
    },
    {
      accessorKey: 'salesOrderVATAmount',
      header: 'VAT Amount',
      Cell: ({ cell }) => {
        return (
          <Box>
            <CurrencyView value={cell.getValue()} />
          </Box>
        );
      },
    },
    {
      accessorKey: 'salesOrderTotalAmount',
      header: 'Total Amount',
      Cell: ({ cell }) => {
        return (
          <Box>
            <CurrencyView value={cell.getValue()} />
          </Box>
        );
      },
    },
  ];

  return (
    <>
      <GlassCard className='h-full-css'>
        <FmMRTDataTable
          columns={columns}
          enableRowSelection={false}
          rows={rows}
          rowCount={tableRecordCounts}
          editingRow={editingRowId}
          pagination={pagination}
          sorting={sorting}
          fetchData={fetchData}
          columnFilters={columnFilters}
          globalFilter={globalFilter}
          setPagination={setPagination}
          setSorting={setSorting}
          setColumnFilters={setColumnFilters}
          setGlobalFilter={setGlobalFilter}
          isLoading={isLoading}
          rightPinning={['salesOrderTotalAmount']}
          setSearchKeyword={setSearchKeyword}
          searchKeyword={searchKeyword}
          muiTableContainerProps={{
            sx: {
              maxHeight: '100%',
              flex: 1,
            },
          }}
          showSerialNumber={true}
        />
      </GlassCard>
      <MuiDialogOne
        title='View Sales Order Details'
        open={viewDetails !== null}
        onClose={() => setViewdetails(null)}
      >
        <ViewTable
          rows={[
            {
              label: 'Sales Order Name',
              title: viewRow?.salesOrderName,
            },
            {
              label: 'Order Date',
              title: viewRow?.orderDate,
            },
            {
              label: 'Region',
              title: viewRow?.regionName,
            },
            {
              label: 'Payment Terms',
              title: viewRow?.paymentTerms,
            },
          ]}
        />
      </MuiDialogOne>
    </>
  );
};

export default CreateSalesOrderList;
