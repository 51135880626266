import React from 'react';
import { Button } from '@mui/material';
import { Icon } from '@iconify/react';

const BackToButton = ({ title, ...props }) => {
  return (
    <Button
      variant='text'
      {...props}
      sx={{
        textTransform: 'inherit',
        color: 'primary.main',
        display: 'flex',
        alignItems: 'center',
        fontSize: 'inherit',
        fontWeight: '700',
        borderRadius: '35px',
        px: '20px',
        '& svg': {
          fontSize: '22px',
          marginRight: '10px',
        },
      }}
    >
      <Icon icon='mdi:arrow-back' /> {title}
    </Button>
  );
};

export const HeaderBackToButton = ({ title, ...props }) => {
  return (
    <Button
      variant='text'
      {...props}
      sx={{
        textTransform: 'inherit',
        color: 'text.main',
        display: 'flex',
        alignItems: 'center',
        fontWeight: '600',
        borderRadius: '4px',
        '& svg': {
          fontSize: '25px',
        },
      }}
      startIcon={<Icon icon='radix-icons:arrow-left' />}
    >
      {title}
    </Button>
  );
};

export default BackToButton;
