import React, { useEffect, useRef, useState } from 'react';
import GlassCard from '../../../../components/small/GlassCard.tsx';
import { Box, Button, IconButton } from '@mui/material';
import Crud_Service from '../../../../apis/CrudService.jsx';
import { useParams } from 'react-router-dom';
import useTableLogic from '../../../../components/helpers/UseTableLogic.tsx';
import { toast } from 'react-toastify';
import { Icon } from '@iconify/react/dist/iconify.js';
import MuiDialogOne from '../../../../components/_mui/MuiDialogOne.jsx';
import UploadSquare from '../../../../components/upload/UploadSquare.tsx';
import ActionButtons from '../../../../components/_form/ActionButtons.tsx';
import useLoader from '../../../../components/helpers/UseLoader.tsx';
import { useForm } from 'react-hook-form';
import apiInstance from '../../../../apis/ApiService.jsx';
import FmTextField from '../../../../components/_mui/FmTextField.tsx';
import CommonLoader from '../../../../components/page/CommonLoader.jsx';
import GalleryImageGrid from '../../../../components/small/GalleryImageGrid.tsx';
import IconButtonDesign from '../../../../utils/IconButtonPrevNext.tsx';
import SimpleCard from '../../../../components/card/SimpleCardWithActions.tsx';

const ViewTenderBuildingImages = (props) => {
  const crud = new Crud_Service();
  const { startCreateLoading, stopCreateLoading, createLoading } = useLoader();
  const { handleSubmit, control, getValues, setValue } = useForm({});
  const [openCreate, setOpenCreate] = useState(false);
  const [openView, setOpenView] = useState({
    open: false,
    loading: false,
  });
  const [selectedRow, setSelectedRow] = useState(null);
  const {
    rows,
    pageSize,
    pageNumber,
    setSearchKeyword,
    isLoading,
    tableRecordCounts,
    searchKeyword,
    handlePaginationModelChange,
    handleSortModelChange,
    fetchData,
  } = useTableLogic(
    'tenderinspectionbuildingimages',
    'tenderInspectionChecklistId',
    props?.tenderChecklistDetailId
  );
  const [currentIndex, setCurrentIndex] = useState(0);
  const [previewdData, setPreviewData] = useState(rows[currentIndex]);
  const [files, setFiles] = useState([]);

  useEffect(() => {
    document.title = `Presales | Tender Building Images`;
  }, []);

  //  handle image preview and Remarks
  const updatePreview = async (newIndex) => {
    setCurrentIndex(newIndex);
    const newFileId = rows[newIndex]?.fileId;
    const newRemarks = rows[newIndex]?.remarks || '';
    setValue('remarks', newRemarks);
    if (newFileId) {
      await PreviewImage(newFileId);
    }
  };

  // Handle Previous Images
  const handlePrevClick = () => {
    if (currentIndex > 0) {
      const newIndex = currentIndex - 1;
      updatePreview(newIndex);
    }
  };

  // Handle Next Images
  const handleNextClick = () => {
    if (currentIndex < rows.length - 1) {
      const newIndex = currentIndex + 1;
      updatePreview(newIndex);
    }
  };

  // Handle Viewing an Image
  const handleViewClick = async (props) => {
    const clickedIndex = rows?.findIndex(
      (row) => row?.fileId === props?.fileId
    );
    if (clickedIndex === -1) {
      toast.error('Error: File not found in the list');
      return;
    }
    setCurrentIndex(clickedIndex);
    setOpenView((prev) => ({ ...prev, open: true, loading: true }));
    setSelectedRow(props);
    setValue('remarks', props?.remarks || '');
    if (props?.fileId) {
      await PreviewImage(props?.fileId);
    }
  };

  // Fetch and Preview Image
  const PreviewImage = async (fileId) => {
    try {
      const imageUrl = await apiInstance.getFiles(`files/download/${fileId}`);
      setPreviewData(imageUrl);
    } catch (err) {
      toast.error(`Error fetching image: ${err.message}`);
    } finally {
      setOpenView((prev) => ({ ...prev, loading: false }));
    }
  };

  const handleDownloadClick = async () => {
    try {
      const fileId = rows[currentIndex]?.fileId;
      const fileName = rows[currentIndex]?.fileName;

      const imageUrl = await apiInstance.getFiles(`files/download/${fileId}`);
      const link = document.createElement('a');
      link.href = imageUrl;
      link.setAttribute('download', `${fileName}`);
      document.body.appendChild(link);
      link.click();
      document.body.removeChild(link);
    } catch (err) {
      toast.error('Error fetching image:', err);
    }
  };

  const FileActionData = [
    {
      name: 'View Details',
      icon: <Icon icon='solar:eye-linear' />,
      onClick: (props) => handleViewClick(props),
    },
    // {
    //   name: 'Download',
    //   icon: <Icon icon='solar:download-minimalistic-outline' />,
    //   onClick: (props) => handleDownloadClick(props),
    // },
    // {
    //   name: 'Delete',
    //   icon: <Icon icon='solar:trash-bin-minimalistic-broken' />,
    //   onClick: (props) => handleDelete(props),
    //   danger: true,
    // },
  ];

  const handleFileUpload = async () => {
    const fileIds = [];

    for (const file of files) {
      if (!file.type.startsWith('image/')) {
        toast.error(`${file.name} is not a valid image file.`);
        continue; // Skip non-image files
      }

      const formData = new FormData();
      formData.append('file', file);
      formData.append('fileTypeId', 50);

      try {
        const fileId = await new Promise((resolve, reject) => {
          crud.create('/files/upload', formData, (_err, res) => {
            if (res?.status === 200) {
              const newFile = {
                fileName: res?.data?.originalFileName,
                fileId: res?.data?.fileId,
              };
              resolve(newFile.fileId); // Resolve with fileId
              toast.success('File uploaded successfully');
            } else {
              toast.error('Failed to upload file.');
              reject(new Error('Failed to upload file.'));
            }
          });
        });

        if (fileId) {
          fileIds.push(fileId);
        }
      } catch (error) {
        console.error(`Error uploading file ${file.name}:`, error);
      }
    }

    return fileIds;
  };

  const handleSubmitBuildingImages = async () => {
    startCreateLoading();

    try {
      const uploadedFileIds = await handleFileUpload();

      const CombinedData = {
        tenderInspectionChecklistId: Number(props?.tenderChecklistDetailId),
        createTenderInspectionBuildingImages: uploadedFileIds?.map((fileId) => {
          return {
            fileId: fileId,
          };
        }),
      };

      const response = await new Promise((resolve, reject) => {
        crud.create(
          `tenderinspectionbuildingimages`,
          CombinedData,
          (_err, res) => {
            if (res?.status === 201) {
              resolve(res);
            } else {
              reject(new Error('Failed to create building images.'));
            }
          }
        );
      });

      toast.success('Building Images Created Successfully');
      setOpenCreate(false);
      fetchData();
    } catch (error) {
      toast.error(error.message || 'An error occurred');
    } finally {
      stopCreateLoading();
    }
  };

  // get the FileTypes
  const getFileExtension = (fileName) => {
    return fileName.split('.').pop().toUpperCase();
  };

  const handleBlur = async () => {
    const currentValues = getValues();
    await handleUpdateBuildingImage(currentValues);
  };

  const previousValues = useRef(getValues());
  //update Building Images
  const handleUpdateBuildingImage = async (values) => {
    const currentValues = getValues();

    const hasChanges = Object.keys(currentValues).some(
      (key) => currentValues[key] !== previousValues.current[key]
    );

    if (!hasChanges || values?.remarks === previousValues.current?.remarks)
      return;

    const combinedData = {
      tenderInspectionChecklistId: Number(props?.tenderChecklistDetailId),
      status: 2,
      updateTenderInspectionBuildingImages: [
        {
          tenderInspectionBuildingImageId:
            selectedRow?.tenderInspectionBuildingImageId,
          fileId: selectedRow?.fileId,
          remarks: values?.remarks,
        },
      ],
    };

    await crud.update(
      'tenderinspectionbuildingimages',
      '',
      combinedData,
      (_err, res) => {
        if (res?.status === 200) {
          toast.success('Building Images Updated Successfully');
          fetchData();
        } else {
        }
      }
    );
  };

  return (
    <>
      {props?.tenderChecklistDetailId ? (
        <SimpleCard
          title='Building Images'
          actions={
            <Button
              variant='contained'
              color='primary'
              startIcon={<Icon icon='ph:upload' />}
              onClick={() => setOpenCreate(true)}
              className='px-3 py-1'
            >
              Upload Document
            </Button>
          }
        >
          <Box className='flex flex-wrap gap-2'>
            {rows?.map((item: any) => (
              <>
                <GalleryImageGrid
                  name={item?.fileName}
                  key={item.tenderInspectionBuildingImageId}
                  image={
                    'https://images.pexels.com/photos/3585095/pexels-photo-3585095.jpeg?auto=compress&cs=tinysrgb&w=1260&h=750&dpr=1'
                  }
                  onClick={() => handleViewClick(item)}
                />
                {/* <FileCardGrid
               name={item?.fileName}
               size='15 KB'
               format={getFileExtension(item?.fileName)}
               Actions={FileActionData?.map((action) => ({
                 ...action,
                 onClick: () => action.onClick(item),
               }))}
               selectedRow={selectedRow}
               setSelectedRow={setSelectedRow}
               onClickView={() => handleViewClick(item)}
             /> */}
              </>
            ))}
          </Box>
          {/* Upload Building Images Dialog */}
          <MuiDialogOne
            open={openCreate}
            title='Upload Building Images'
            onClose={() => setOpenCreate(false)}
            width='900px'
          >
            <form onSubmit={handleSubmitBuildingImages}>
              <UploadSquare
                files={files}
                setFiles={setFiles}
                onFilesSelected={setFiles}
                fileType='image'
              />

              <ActionButtons
                onSubmit={handleSubmitBuildingImages}
                submitLoading={createLoading}
                cancelLoading={false}
                submitText='Save'
              />
            </form>
          </MuiDialogOne>

          {/* View Building Images Dialog */}
          <MuiDialogOne
            open={openView?.open}
            title='View Building Images'
            onClose={() => setOpenView({ open: false, loading: false })}
          >
            {openView?.loading ? (
              <CommonLoader />
            ) : (
              <form onSubmit={handleSubmit(handleUpdateBuildingImage)}>
                <Box
                  className='relative min-h-52 mb-5 overflow-hidden'
                  sx={{
                    borderRadius: '10px',
                    border: '1px solid',
                    borderColor: 'border.main',
                  }}
                >
                  <IconButtonDesign
                    prev
                    disabled={currentIndex === 0}
                    onClick={handlePrevClick}
                  />
                  <Box
                    src={previewdData}
                    alt={previewdData?.fileName}
                    component={'img'}
                  />
                  <IconButtonDesign
                    disabled={currentIndex === rows?.length - 1}
                    onClick={handleNextClick}
                  />
                </Box>

                <FmTextField
                  name='remarks'
                  label='Remarks'
                  multiline={true}
                  rows={2}
                  maxRows={4}
                  control={control}
                  onBlur={handleBlur}
                />
              </form>
            )}
          </MuiDialogOne>
        </SimpleCard>
      ) : (
        <GlassCard className='p-4 text-center'>
          <Box className='text-center mt-5'>
            Please Add Tender Inspection Checklist to View (or) Create Building
            Images
          </Box>
        </GlassCard>
      )}
    </>
  );
};

export default ViewTenderBuildingImages;
