import React from 'react';
import { Box } from '@mui/material';
import { Icon } from '@iconify/react/dist/iconify.js';

const PRCardDrag = ({
  description,
  bankReferenceNo,
  customerReferenceNo,
  debitAmount,
  creditAmount,
  financialTransactionReceiptCode,
  receiptTotalAmount,
  paymentTotalAmount,
  chequeNo,
  isTransaction,
  iconChange,
}) => {

  const isDebit = debitAmount >= 0;
  const isCredit = creditAmount >= 0;
  const isReceipt = receiptTotalAmount >= 0;
  const isPayment = paymentTotalAmount >= 0;

  const amount = Number(
    isDebit ? debitAmount :
      isCredit ? creditAmount :
        isReceipt ? receiptTotalAmount :
          isPayment ? paymentTotalAmount : 0
  );

  let textStyle = 'text-gray-600';
  if (isDebit) {
    textStyle = 'text-red-600';
  } else if (isCredit) {
    textStyle = 'text-green-600';
  } else if (isReceipt) {
    textStyle = 'text-green-600';
  } else if (isPayment) {
    textStyle = 'text-red-600';
  }

  return (
    <Box
      className='flex items-center w-full justify-between gap-3 p-1.5 rounded-md mb-2'
      sx={{
        bgcolor: 'background.white',
        border: '1px solid',
        borderColor: 'border.main',
      }}
    >
      <Box className=''>
        {iconChange ? (
          <Icon icon='lets-icons:wallet-alt-duotone' width='24' height='24' />
        ) : (
          <Icon icon='lets-icons:book-duotone' width='24' height='24' />
        )}
      </Box>
      <Box className='flex-1'>
        <Box className='font-semibold'>{description || 'Transaction'}</Box>
        <Box className='flex gap-2 items-center text-[11px]'>
          {isTransaction ? (
            <Box>Cheque No: {chequeNo || 'N/A'}</Box>
          ) : (
            <Box>Ref.No: {customerReferenceNo}</Box>
          )}
          {isTransaction ? null : (
            <Box className='opacity-65'>|</Box>
          )}
          {isTransaction ? null : (
            <Box>Transaction.No: {bankReferenceNo || financialTransactionReceiptCode}</Box>
          )}
        </Box>
      </Box>

      <Box className={`flex-8 text-right font-semibold ${textStyle}`}>
        AED {amount.toFixed(2)}
      </Box>
    </Box>
  );
};

export default PRCardDrag;