import React, { useEffect, useState } from 'react';
import DashboardLayout from '../../../../components/DashboardLayout.tsx';
import GlassCard from '../../../../components/small/GlassCard.tsx';
import BackToButton from '../../../../components/small/BackToButton.tsx';
import { Box, Grid } from '@mui/material';
import { useNavigate, useParams } from 'react-router-dom';
import ViewContractCustomer from './ViewContractCustomer.tsx';
import ViewContractLocationList from './ViewContractLocationList.tsx';
import ViewContractProjectTeam from './ViewContractProjectTeam.tsx';
import ViewContractRevenue from './ViewContractRevenue.tsx';
import ViewContractCost from './ViewContractCost.tsx';
import ViewContractSubContract from './ViewContractSubContract.tsx';
import ViewContractSchedule from './ViewContractSchedule.tsx';
import Crud_Service from '../../../../apis/CrudService.jsx';
import ElementLoader from './../../../../components/page/ElementLoader.tsx';
import { ViewField } from '../../../../components/_form/FormElements.jsx';
import ViewStartEndDate from './../../../../components/view/ViewStartEndDate.tsx';
import FmViewDateField from './../../../../components/_mui/FmViewDateField.tsx';
import TableAvatar from '../../../../components/_form/TableAvatar.jsx';
import ViewChipText from '../../../../components/view/ViewChipText.tsx';
import ViewContractRevenueSchedule from './ViewRevenueSchedule.tsx';
import ViewContractCheckList from './ViewContractCheckList.tsx';
import ViewContractBudgeting from './ViewContractBudgeting.tsx';
import ViewFieldsHori from '../../../../components/view/ViewFieldsHori.tsx';
import { ViewTable } from '../../../../components/_form/FormElements.jsx';
import FmTabsOne from '../../../../components/tabs/FmTabsOne.tsx';
import ViewContractMileStone from './ViewContractMileStone.tsx';
import CurrencyView from '../../../../components/_mui/FmCurrencyView.tsx';
import ViewContractScopeNotes from '../ViewContractScopeNotes.tsx';

const navMenu = [
  {
    id: 1,
    name: 'Checklist',
  },
  {
    id: 2,
    name: 'Customer',
  },
  {
    id: 3,
    name: 'Location',
  },
  {
    id: 4,
    name: 'Project Team',
  },
  {
    id: 7,
    name: 'Supplier',
  },
  {
    id: 8,
    name: 'Invoice Schedule',
  },
  {
    id: 9,
    name: 'Revenue Schedule',
  },
  {
    id: 10,
    name: 'Contract Budget',
  },
  {
    id: 11,
    name: 'MileStone',
  },
  {
    id: 12,
    name: 'Scope Notes',
  },
];

const ViewContracts = () => {
  const [value, setValue] = useState(() => {
    return parseInt(localStorage.getItem('selectedTabContracts')) || 0;
  });
  const id = useParams().id;
  const crud = new Crud_Service();
  const navigation = useNavigate();
  const handleChange = (_event, newValue) => {
    setValue(newValue);
    localStorage.setItem('selectedTabContracts', newValue);
  };
  const [contractData, setContractData] = useState({});
  const [loader, setLoader] = useState(false);

  const fetchContractData = async () => {
    await crud.getSingle('contracts', id, (_err, res) => {
      if (res?.status === 200) {
        setContractData(res?.data);
        setLoader(false);
      } else {
      }
    });
  };
  const filteredNavMenu = React.useMemo(() => {
    if (!contractData?.typeId) return navMenu; // Show all if undefined

    const typeId = Number(contractData.typeId); // Convert to number

    return typeId === 1
      ? navMenu.filter((item) =>
          [1, 2, 3, 4, 7, 8, 9, 10, 12].includes(item.id)
        )
      : typeId === 3
      ? navMenu.filter((item) => [1, 2, 3, 4, 7, 10, 11].includes(item.id)) // Excludes 9 (Revenue Schedule)
      : navMenu;
  }, [contractData?.typeId]);

  useEffect(() => {
    document.title = 'View | Contract | Presales';
    fetchContractData();
    setLoader(true);
  }, []);

  return (
    <DashboardLayout
      title={contractData?.contractCode}
      actionButtons={
        <BackToButton
          onClick={() => navigation(-1)}
          title='Back to Contract'
          className='p-0'
        />
      }
      titleReverse
    >
      <GlassCard className='p-3  mb-4'>
        {loader ? (
          <div className='flex justify-center'>
            <ElementLoader />
          </div>
        ) : (
          <>
            <Box>
              <Box className='mb-4'>
                <Grid container spacing={2}>
                  <Grid item md={6}>
                    <Box className='font-bold text-base mb-2'>
                      {contractData?.contractName} - {contractData?.typeName}
                    </Box>

                    <Box className='flex gap-2 mb-2'>
                      <ViewField
                        label='Contract Code'
                        title={contractData?.contractCode}
                      />
                      <ViewField
                        label='Project Code'
                        title={contractData?.projectCode}
                      />
                      <ViewField
                        label='Project Name'
                        title={contractData?.projectName}
                      />
                    </Box>

                    <Box className='flex gap-2 mb-2'>
                      <ViewField
                        label='Contract Start Date'
                        title={
                          <FmViewDateField
                            value={contractData?.contractStartDate}
                          />
                        }
                      />
                      <ViewField
                        label='Contract End Date'
                        title={
                          <FmViewDateField
                            value={contractData?.contractEndDate}
                          />
                        }
                      />
                      <ViewField
                        label='Client '
                        title={contractData?.clientName}
                      />
                    </Box>

                    <Box className='flex items-center gap-3 my-3'>
                      <ViewField
                        label='Contact Person'
                        title={contractData?.contactPerson}
                      />
                      <ViewField
                        label='Contact Email'
                        title={contractData?.contactEmailId}
                      />
                      <ViewField
                        label='Contact Number'
                        title={contractData?.contactNumber}
                      />
                    </Box>
                    <Box className='flex items-center gap-8'>
                      <ViewField
                        label='Gurantee Start Date'
                        title={
                          <FmViewDateField
                            value={contractData?.guaranteeStartDate || 'null'}
                          />
                        }
                      />
                      <ViewField
                        label='Gurantee End Date'
                        title={
                          <FmViewDateField
                            value={contractData?.guaranteeEndDate || 'null'}
                          />
                        }
                      />
                      <Box className='w-full'></Box>
                    </Box>
                  </Grid>
                  <Grid item md={6}>
                    <Box className='flex gap-2 mb-3 items-center'>
                      <ViewField
                        label='Budget'
                        title={`${contractData?.budget} ${
                          contractData?.currencyName || '-'
                        }`}
                      />

                      <ViewField
                        label='Contract Cost'
                        title={`${contractData?.contractCost} ${
                          contractData?.currencyName || '-'
                        }`}
                      />

                      <ViewField
                        label='Contract Terms'
                        title={contractData?.contractTerms}
                      />

                      <ViewField
                        label='Contract Mode '
                        title={contractData?.contractModeName}
                      />
                    </Box>

                    <Grid container spacing={2}>
                      <Grid item md={6}>
                        <ViewTable
                          paddingLow
                          rows={[
                            {
                              label: 'Previous Contract Code',
                              title: contractData?.perviousContractCode,
                            },
                            {
                              label: 'Reference Number',
                              title: contractData?.referenceNumber,
                            },
                            {
                              label: 'Contract Type ',
                              title: contractData?.contractTypeName,
                            },
                            {
                              label: 'Contract Scope ',
                              title: contractData?.contractScopeName || '-',
                            },
                            {
                              label: 'Billing Frequency ',
                              title: contractData?.billingFrequencyName || '-',
                            },
                          ]}
                        />
                      </Grid>
                      <Grid item md={6}>
                        <ViewTable
                          paddingLow
                          rows={[
                            {
                              label: 'Threshold Value',
                              title: (
                                <CurrencyView
                                  value={contractData?.thresholdValue}
                                  currencyType={'AED'}
                                />
                              ),
                            },
                            {
                              label: 'Currency ',
                              title: contractData?.currencyName || '-',
                            },
                            {
                              label: 'Guarantee ',
                              title: contractData?.guaranteeName || '-',
                            },
                            {
                              label: 'Guarantee Amount',
                              title: (
                                <CurrencyView
                                  value={contractData?.guaranteeAmount}
                                  currencyType={'AED'}
                                />
                              ),
                            },
                            {
                              label: 'Remarks',
                              title: contractData?.remarks || '-',
                            },
                          ]}
                        />
                      </Grid>
                    </Grid>
                  </Grid>
                </Grid>
              </Box>
            </Box>
          </>
        )}
      </GlassCard>
      <GlassCard className='mb-4'>
        <FmTabsOne
          data={filteredNavMenu}
          value={value}
          handleChange={handleChange}
          centered
        />
      </GlassCard>
      {value === 1 && <ViewContractCheckList contractId={id} />}
      {value === 2 && <ViewContractCustomer contractId={id} />}
      {value === 3 && (
        <ViewContractLocationList
          contractId={id}
          projectId={contractData?.projectId}
        />
      )}
      {value === 4 && <ViewContractProjectTeam contractId={id} />}
      {value === 5 && <ViewContractRevenue />}
      {value === 6 && <ViewContractCost />}
      {value === 7 && <ViewContractSubContract contractId={id} />}
      {value === 8 && <ViewContractSchedule contractId={id} />}
      {value === 9 && <ViewContractRevenueSchedule contractId={id} />}
      {value === 10 && <ViewContractBudgeting contractData={contractData} />}
      {value === 11 && <ViewContractMileStone contractId={id} />}
      {value === 12 && <ViewContractScopeNotes />}
    </DashboardLayout>
  );
};

export default ViewContracts;
