import React, { useState } from 'react';
import { useForm } from 'react-hook-form';
import { Box, Button, Tooltip } from '@mui/material';
import TableActionButtons from '../../../components/_form/TableActionButtons.jsx';
import FmAutoComplete from '../../../components/_mui/FmAutoComplete.tsx';
import FmTextField from '../../../components/_mui/FmTextField.tsx';
import FmMRTDataTable from '../../../components/FmMRTDataTable.tsx';
import FmSearchableSelect from '../../../components/_mui/FmSearchableSelect.tsx';
import FmDatePicker from '../../../components/_mui/FmDatePicker.tsx';
import FmViewDateField from '../../../components/_mui/FmViewDateField.tsx';
import { yupResolver } from '@hookform/resolvers/yup';
import { uniformsSchama } from '../../../utils/CommonvalidationSchemas.tsx';
import { Icon } from '@iconify/react/dist/iconify.js';
import { toast } from 'react-toastify';
import { useNavigate } from 'react-router-dom';
import apiInstance from '../../../apis/ApiService.jsx';
import Icons from '../../../utils/Icon.jsx';

const UniformDataTable = (props) => {
  const {
    rows,
    editingRowId,
    isLoading,
    setEditingRowId,
    setEditingRowData,
    fetchData,
    handleUpdateUniforms,
    uniformtypes,
    uniformcategories,
    pagination,
    sorting,
    columnFilters,
    setColumnFilters,
    globalFilter,
    setGlobalFilter,
    tableRecordCounts,
    setPagination,
    setSorting,
    setSearchKeyword,
    searchKeyword,
    setViewdetails,
    crud,
  } = props;
  const { handleSubmit, control, setValue } = useForm({
    resolver: yupResolver(uniformsSchama),
    mode: 'onChange',
  });
  const [uniformsizes, setuniformsizes] = useState([]);
  const navigate = useNavigate();

  const handleEdit = async (row) => {
    navigate(`edit/${row?.uniformId}`);
  };

  const handleDelete = async (props) => {
    await crud.remove('uniforms', props?.uniformId, (err, res) => {
      if (res?.status === 204) {
        toast.success('uniform and Accessories Deleted Successfully');
        fetchData();
      } else {
      }
    });
  };

  const handleViewClick = async (props) => {
    navigate(`view/${props?.uniformId}`);
  };

  const handleCancel = () => {
    setEditingRowId(null);
    setEditingRowData(null);
  };

  const ActionData = [
    {
      name: 'View Details',
      icon: <Icon icon='solar:eye-linear' />,
      onClick: (props) => handleViewClick(props),
    },
    {
      name: 'Edit',
      icon: <Icon icon='solar:pen-2-broken' />,
      onClick: (props) => handleEdit(props),
    },
    {
      name: 'Delete',
      icon: <Icon icon='solar:trash-bin-minimalistic-broken' />,
      onClick: (props) => handleDelete(props),
      danger: true,
    },
  ];

  const handleChangeuniformType = async (e) => {
    if (e?.uniformTypeId) {
      await crud.getAll(
        'uniformsizes',
        { uniformTypeId: e?.uniformTypeId },
        (err, res) => {
          if (res?.status === 200) {
            setuniformsizes(res?.data?.data);
          } else {
            setuniformsizes([]);
          }
        }
      );
    } else {
      setuniformsizes([]);
    }
  };

  const columns = [
    {
      header: 'Actions',
      Cell: ({ row }) => (
        <Box className='flex gap-2'>
          {editingRowId === row?.original?.uniformId ? (
            <>
              <Button onClick={handleSubmit(handleUpdateUniforms)}>Save</Button>
              <Button onClick={handleCancel}>Cancel</Button>
            </>
          ) : (
            <TableActionButtons
              Actions={ActionData?.map((action) => ({
                ...action,
                onClick: () => action.onClick(row?.original),
              }))}
            />
          )}
        </Box>
      ),
    },
    {
      accessorKey: 'projectId',
      header: 'Projects',
      enableEditing: true,

      Cell: ({ cell }) => {
        return editingRowId === cell?.row?.original?.uniformId ? (
          <FmSearchableSelect
            name='projectId'
            control={control}
            apiUrl='projects'
            valueField='projectId'
            headerField={['Project Code', 'Project Name']}
            labelField={['projectCode', 'projectName']}
            showField={['projectName']}
            defaultValue={{
              projectId: cell?.row?.orginal?.projectId,
              projectName: cell?.row?.original?.projectName,
            }}
          />
        ) : (
          cell?.row?.original?.projectName
        );
      },
    },
    {
      accessorKey: 'uniformTypeId',
      header: 'Uniform Type',
      enableEditing: true,
      Cell: ({ cell }) => {
        return editingRowId === cell?.row?.original?.uniformId ? (
          <FmAutoComplete
            name='uniformTypeId'
            control={control}
            options={uniformtypes}
            label='Uniform Type'
            displayField='uniformTypeName'
            optionFields={['uniformTypeName']}
            valueKey='uniformTypeId'
            onChangeValue={true}
            onChange={handleChangeuniformType}
          />
        ) : (
          cell?.row?.original?.uniformTypeName
        );
      },
    },
    {
      accessorKey: 'uniformSizeId',
      header: 'Uniform Size',
      enableEditing: true,
      Cell: ({ cell }) => {
        return editingRowId === cell?.row?.original?.uniformId ? (
          <FmAutoComplete
            name='uniformSizeId'
            control={control}
            options={uniformsizes}
            label='Uniform Size'
            displayField='uniformSizeName'
            optionFields={['uniformSizeName']}
            valueKey='uniformSizeId'
          />
        ) : (
          cell?.row?.original?.uniformSizeName
        );
      },
    },
    {
      accessorKey: 'uniformCategoryId',
      header: 'Uniform Category',
      enableEditing: true,
      Cell: ({ cell }) => {
        return editingRowId === cell?.row?.original?.uniformId ? (
          <FmAutoComplete
            name='uniformCategoryId'
            control={control}
            options={uniformcategories}
            label='Uniform Category'
            displayField='uniformCategoryName'
            optionFields={['uniformCategoryName']}
            valueKey='uniformCategoryId'
          />
        ) : (
          cell?.row?.original?.uniformSizeName
        );
      },
    },
    {
      accessorKey: 'requestDate',
      header: 'Request Date',
      enableEditing: true,
      Cell: ({ row }) => {
        return editingRowId === row?.original?.uniformId ? (
          <FmDatePicker
            name='requestDate'
            control={control}
            label='Request Date'
          />
        ) : (
          FmViewDateField({ value: row?.original?.requestDate })
        );
      },
    },

    {
      accessorKey: 'quantity',
      header: 'Quantity',
      enableEditing: true,
      Cell: ({ row }) => {
        return editingRowId === row?.original?.uniformId ? (
          <FmTextField name='quantity' control={control} />
        ) : (
          row?.original?.quantity
        );
      },
    },
  ];

  const exportExcel = async () => {
    const downloadUrl = await apiInstance.getFiles('uniforms/exportexcel');

    const link = document.createElement('a');
    link.href = downloadUrl;
    link.setAttribute('download', 'Uniform.xlsx');
    document.body.appendChild(link);
    link.click();
    document.body.removeChild(link);
  };

  return (
    <FmMRTDataTable
      rows={rows}
      columns={columns}
      enableRowSelection={false}
      rowCount={tableRecordCounts}
      editingRow={editingRowId}
      pagination={pagination}
      sorting={sorting}
      columnFilters={columnFilters}
      globalFilter={globalFilter}
      setPagination={setPagination}
      setSorting={setSorting}
      fetchData={fetchData}
      setColumnFilters={setColumnFilters}
      setGlobalFilter={setGlobalFilter}
      isLoading={isLoading}
      setSearchKeyword={setSearchKeyword}
      searchKeyword={searchKeyword}
      handleRowDoubleClick={(e) => handleEdit(e?.original)}
      renderTopToolbarCustomActionsChildrens={
        <Button
          variant='outlined'
          color='primary'
          className='px-3 py-1'
          onClick={exportExcel}
        >
          <Tooltip title='Export Excel'>{Icons?.download}</Tooltip>
        </Button>
      }
      muiTableContainerProps={{
        sx: {
          maxHeight: '100%',
          flex: 1,
        },
      }}
    />
  );
};

export default UniformDataTable;
