import React from 'react';
import Box from '@mui/material/Box';
import { Menu, Button, Tooltip, IconButton } from '@mui/material/';
import { useNavigate } from 'react-router-dom';
import MuiMenuItem from '../_mui/MuiMenuItem';
import { Icon } from '@iconify/react';

const AccountMenu = [
  {
    title: 'Holidays',
    icon: <Icon icon='fluent:beach-28-regular' />,
    path: '/fm/holidays',
  },

  {
    title: 'General Documents',
    icon: <Icon icon='fluent:document-bullet-list-20-regular' />,
    path: '/fm/general-documents',
  },

  {
    title: 'Workflow Builder',
    icon: <Icon icon='fluent:branch-fork-link-20-regular' />,
    path: '/fm/approval-node',
  },

  {
    title: 'Calendar Booking',
    icon: <Icon icon='fluent:calendar-24-regular' />,
    path: '/fm/calendar-booking',
  },
  {
    title: 'Form Builder',
    icon: <Icon icon='fluent:form-24-regular' />,
    path: '/fm/template',
  },
  {
    title: 'Questions Builder',
    icon: <Icon icon='fluent:chat-bubbles-question-32-regular' />,
    path: '/fm/questions',
  },
  {
    title: 'Mail Template',
    icon: <Icon icon='fluent:mail-inbox-all-24-regular' />,
    path: '/mailTemplate',
  },
  {
    title: 'Text Editor',
    icon: <Icon icon='fluent:panel-left-text-32-regular' />,
    path: '/text-editor',
  },

  {
    title: 'Role Management',
    icon: <Icon icon='fluent:incognito-24-regular' />,
    path: '/fm/role-management',
  },

  {
    title: 'Questions Template',
    icon: <Icon icon='fluent:notebook-question-mark-24-regular' />,
    path: '/fm/questions-template',
  },
  {
    title: 'Table Tree',
    icon: <Icon icon='fluent:notebook-question-mark-24-regular' />,
    path: '/fm/table-tree',
  },

  {
    title: 'Attendance Summary',
    icon: <Icon icon='gg:check-o' />,
    path: '/hrms/employee/attendance-summary-new',
  },
  {
    title: 'Report Builder',
    icon: <Icon icon='fluent:report-data-bar-vertical-24-regular' />,
    path: '/fm/report-builder',
  },
];

const SidebarSettingsDropdown = ({ openToggle }) => {
  const navigate = useNavigate();
  const [anchorEl, setAnchorEl] = React.useState(null);

  const open = Boolean(anchorEl);

  const handleClick = (event) => {
    setAnchorEl(event.currentTarget);
  };

  const handleClose = () => {
    setAnchorEl(null);
  };

  return (
    <Box>
      {openToggle ? (
        <Box className='flex justify-center'>
          <Tooltip title='Quick Links' arrow placement='right-end'>
            <IconButton
              onClick={handleClick}
              sx={{ color: 'inherit', fontSize: '20px', opacity: 0.85 }}
            >
              <Icon icon='ri:links-fill' />
            </IconButton>
          </Tooltip>
        </Box>
      ) : (
        <Button
          startIcon={<Icon icon='fluent:link-multiple-16-regular' />}
          onClick={handleClick}
          sx={{
            color: 'inherit',
            gap: '5px',
            py: 0.2,
            opacity: 0.85,
            fontWeight: 500,
            '& .MuiButton-icon': {
              width: '32px',
              height: '32px',
              display: 'flex',
              justifyContent: 'center',
              alignItems: 'center',
              borderRadius: '50%',
            },
          }}
          fullWidth
          className='justify-start'
        >
          Quick Links
        </Button>
      )}

      <Menu
        anchorEl={anchorEl}
        open={open}
        onClose={handleClose}
        anchorOrigin={{
          vertical: 'bottom',
          horizontal: 'right',
        }}
        transformOrigin={{
          vertical: 'center',
          horizontal: 'left',
        }}
        sx={{
          '& .MuiPaper-root': {
            '& .MuiList-root': {
              padding: '10px 0',
            },
          },
        }}
      >
        {AccountMenu?.map((menu, i) => (
          <MuiMenuItem
            onClick={() => navigate(menu.path)}
            key={i}
            name={menu.title}
            icon={menu.icon}
          />
        ))}
      </Menu>
    </Box>
  );
};

export default SidebarSettingsDropdown;
