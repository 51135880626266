import React from 'react';
import { Menu, IconButton, Box, Button } from '@mui/material';
import { Icon } from '@iconify/react';

const ConfirmationPopoverBox = ({ title, icon, onConfirm, message }) => {
  const [anchorEl, setAnchorEl] = React.useState(null);
  const open = Boolean(anchorEl);

  const handleClick = (event) => {
    setAnchorEl(event.currentTarget);
  };

  const handleClose = (event) => {
    setAnchorEl(null);
  };

  return (
    <>
      <Box>
        <IconButton onClick={handleClick}>{icon}</IconButton>

        <Menu
          anchorEl={anchorEl}
          open={open}
          onClose={handleClose}
          component='div'
          anchorOrigin={{
            vertical: 'center',
            horizontal: 'center',
          }}
          transformOrigin={{
            vertical: 'center',
            horizontal: 'center',
          }}
          sx={{
            '& .MuiPaper-root': {
              padding: 0,
              boxShadow: 2,
            },
            '& .MuiList-root': { padding: 0 },
          }}
        >
          <Box className='p-4'>
            <Box className='flex items-center gap-4'>
              <Box color={'error.main'}>
                <Icon icon='fluent:delete-20-regular' width='30' height='30' />
              </Box>

              <Box className='flex-1'>
                <h3 className='font-semibold'>{title}</h3>
                <p className='opacity-75'>
                  {message || 'Are you sure you want to delete this?'}
                </p>
              </Box>
            </Box>

            <div className='flex justify-end items-center gap-2 mt-5'>
              <Button
                variant='outlined'
                color='error'
                className='px-2 py-1'
                onClick={handleClose}
              >
                No
              </Button>
              <Button
                onClick={onConfirm}
                variant='contained'
                sx={{
                  fontWeight: 700,
                  padding: '4px 8px',
                  fontSize: '0.875rem',
                }}
                className='px-2 py-1'
                color='error'
              >
                Yes
              </Button>
            </div>
          </Box>
        </Menu>
      </Box>
    </>
  );
};

export default ConfirmationPopoverBox;
