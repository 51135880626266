import React, { useEffect, useState } from 'react';
import DashboardLayout from '../../../components/DashboardLayout.tsx';
import { CreateButton } from '../../../components/small/Buttons.jsx';
import GlassCard from '../../../components/small/GlassCard.tsx';
import { Box } from '@mui/material';
import DataTable from '../../../components/DataTable.tsx';
import { Icon } from '@iconify/react';
import TableActionButtons from '../../../components/_form/TableActionButtons.jsx';
import Crud_Service from '../../../apis/CrudService.jsx';
import { StatusBadge } from '../../../components/_form/FormElements.jsx';
import { useNavigate } from 'react-router-dom';
import useTableLogic from '../../../components/helpers/UseTableLogic.tsx';
import TableSearch from '../../../components/_form/TableSearch.jsx';
import FmViewDateField from '../../../components/_mui/FmViewDateField.tsx';
import CommonView from '../../../components/helpers/CommonView.jsx';
import DynamicViewFields from '../../../components/helpers/DynamicViewFields.jsx';
import BackToButton from '../../../components/small/BackToButton.jsx';
import { toast } from 'react-toastify';
import DataTableNoRow from '../../../components/small/DataTableNoRow.tsx';

const ManageContracts = () => {
  const [viewData, setViewData] = useState(null);
  const crud = new Crud_Service();
  const {
    rows,
    pageSize,
    pageNumber,
    isLoading,
    tableRecordCounts,
    handlePaginationModelChange,
    handleSortModelChange,
    setSearchKeyword,
    searchKeyword,
    fetchData,
  } = useTableLogic('contracts');

  const navigate = useNavigate();

  useEffect(() => {
    document.title = `HRMS-Manage Contracts`;
  }, []);

  //setvalues to the Inputs
  const handleEditClick = async (props) => {
    navigate(`edit/${props?.contractId}`);
  };

  //view the ShiftTiming
  const handleViewClick = async (view) => {
    setViewData(view);
    navigate(`view/${view?.contractId}`);
  };

  //handle Delete
  const handleDelete = async (props) => {
    await crud.remove('contracts', props?.contractId, (_err, res) => {
      if (res?.status === 204) {
        fetchData();
        toast.success('Contract Deleted successfully');
      } else {
      }
    });
  };

  const ActionData = [
    {
      name: 'View',
      icon: <Icon icon='solar:eye-linear' />,
      onClick: (props) => handleViewClick(props),
    },
    {
      name: 'Edit',
      icon: <Icon icon='solar:pen-new-round-linear' />,
      // onClick: () => setOpenCreate(!openCreate),
      onClick: (props) => handleEditClick(props),
    },
    {
      name: 'Delete',
      icon: <Icon icon='mi:delete' />,
      onClick: (props) => handleDelete(props),
      danger: true,
    },
  ];

  const columns = [
    {
      field: 'action',
      headerName: '',
      width: 120,
      sortable: false,
      disableClickEventBubbling: false,
      renderCell: (params) => {
        return (
          <TableActionButtons
            Actions={ActionData?.map((action) => ({
              ...action,
              onClick: () => action.onClick(params.row),
            }))}
          />
        );
      },
    },

    {
      field: 'contractCode',
      headerName: 'Contract Code',
      minWidth: 150,
    },

    {
      field: 'contractName',
      headerName: 'Contract Name',
      minWidth: 180,
    },

    {
      field: 'projectName',
      headerName: 'Project Name',
      minWidth: 250,
    },
    {
      field: 'clientName',
      headerName: 'Client Name',
      minWidth: 170,
    },
    {
      field: 'contractStartDate',
      headerName: 'Contract Start Date',
      minWidth: 180,
      renderCell: (params) => {
        return FmViewDateField({ value: params?.value });
      },
    },
    {
      field: 'contractEndDate',
      headerName: 'Contract End Date',
      minWidth: 180,
      renderCell: (params) => {
        return FmViewDateField({ value: params?.value });
      },
    },
    {
      field: 'contactPerson',
      headerName: 'Contact Person',
      minWidth: 170,
    },
    {
      field: 'contactNumber',
      headerName: 'Contact Number',
      minWidth: 170,
    },
    {
      field: 'contactEmailId',
      headerName: 'Contact EmailId',
      minWidth: 170,
    },

    {
      field: 'contractCost',
      headerName: 'Contract Cost',
      minWidth: 170,
    },
    {
      field: 'budget',
      headerName: 'Budget',
      minWidth: 120,
    },
    {
      field: 'contractTerms',
      headerName: 'Contract Terms',
      minWidth: 300,
    },
    {
      field: 'perviousContractCode',
      headerName: 'Pervious Contract Code',
      minWidth: 300,
    },
    {
      field: 'contractModeName',
      headerName: 'Contract Mode Name',
      minWidth: 300,
    },
    {
      field: 'referenceNumber',
      headerName: 'Reference Number',
      minWidth: 300,
    },
    {
      field: 'contractTypeName',
      headerName: 'Contract Type Name',
      minWidth: 300,
    },
    {
      field: 'contractScopeMaps',
      headerName: 'Contract Scope Name',
      minWidth: 300,
      valueGetter: (params) => {
        if (Array.isArray(params.row.contractScopeMaps) && params.row.contractScopeMaps.length > 0) {
          return params.row.contractScopeMaps.map(guarantee => guarantee.contractScopeName).join(', ');
        }
        return '';
      },
    },
    {
      field: 'billingFrequencyName',
      headerName: 'Billing Frequency Name',
      minWidth: 300,
    },
    {
      field: 'thresholdValue',
      headerName: 'Threshold Value',
      minWidth: 300,
    },
    {
      field: 'currencyName',
      headerName: 'Currency Name',
      minWidth: 300,
    },
    {
      field: 'contractGuarantees',
      headerName: 'Guarantee Name',
      minWidth: 300,
      valueGetter: (params) => {
        if (Array.isArray(params.row.contractGuarantees) && params.row.contractGuarantees.length > 0) {
          return params.row.contractGuarantees.map(guarantee => guarantee.guaranteeName).join(', ');
        }
        return '';
      },
    },
    {
      field: 'guaranteeStartDate',
      headerName: 'Guarantee Start Date',
      minWidth: 180,
      renderCell: (params) => {
        return FmViewDateField({ value: params?.value });
      },
    },
    {
      field: 'guaranteeEndDate',
      headerName: 'Guarantee End Date',
      minWidth: 180,
      renderCell: (params) => {
        return FmViewDateField({ value: params?.value });
      },
    },
    {
      field: 'guaranteeAmount',
      headerName: 'Guarantee Amount',
      minWidth: 300,
    },
    {
      field: 'remarks',
      headerName: 'Remarks',
      minWidth: 300,
    },

    {
      field: 'status',
      headerName: 'Status',

      renderCell: (params) => {
        return (
          <StatusBadge
            title={params?.value === 2 ? 'Active' : 'Inactive'}
            type={params?.value === 2 ? 'green' : 'red'}
          />
        );
      },
    },
  ];

  const onRowDoubleClick = (e) => {
    if (e?.row) {
      handleEditClick(e?.row);
    }
  };

  const excludeKeys = [
    'billingFrequencyId',
    'clientId',
    'contractId',
    'contractModeId',
    'contractScopeId',
    'projectTypeId',
    'contractTypeId',
    'currencyId',
    'divisionId',
    'guaranteeId',
    'projectId',
    'projectInChargeId',
    'status',
    'updatedDate',
  ];

  return (
    <>
      <DashboardLayout
        title='Contracts'
        removeSpace
        actionButtons={
          <CreateButton name='New' onClick={() => navigate('create')} />
        }
      >
        <>
          {/* Listing */}
          {!viewData && (
            <GlassCard className='h-full-css rounded-none border-0'>
              <Box className='h-full-css'>
                <DataTable
                  rows={rows}
                  columns={columns}
                  loading={isLoading}
                  onRowDoubleClick={(e) => onRowDoubleClick(e)}
                  getRowClassName={(params) =>
                    `${params.row.workingStatus === 'InActive' &&
                    'bg-red-500 bg-opacity-15'
                    }`
                  }
                  sortingMode='server'
                  paginationMode='server'
                  onPaginationModelChange={(model) =>
                    handlePaginationModelChange(model)
                  }
                  onSortModelChange={(model) => handleSortModelChange(model)}
                  page={pageNumber - 1}
                  pageSize={pageSize}
                  rowCount={tableRecordCounts?.total}
                  slots={{
                    toolbar: () => (
                      <Box
                        className='p-2 w-full flex justify-between items-center'
                        sx={{
                          borderBottom: '1px solid',
                          borderColor: 'border.main',
                        }}
                      >
                        <Box sx={{ maxWidth: '350px', width: '100%' }}>
                          <TableSearch
                            placeholder='Search'
                            fullWidth
                            setSearchKeyword={setSearchKeyword}
                            searchValue={searchKeyword}
                          />
                        </Box>
                      </Box>
                    ),
                    noRowsOverlay: DataTableNoRow,
                  }}
                />
              </Box>
            </GlassCard>
          )}
          {/* Listing */}
        </>

        {/* view */}
        {viewData && viewData?.contractId && (
          <>
            <BackToButton
              title='Back to List'
              className='px-2 mb-4'
              onClick={() => navigate(-1)}
            />
            <GlassCard className='p-5'>
              <CommonView
                url='contracts'
                id={viewData?.contractId}
                excludeKeys={excludeKeys}
                renderFields={(data, fields) => (
                  <DynamicViewFields data={data} fields={fields} />
                )}
              />
            </GlassCard>
          </>
        )}
      </DashboardLayout>
    </>
  );
};

export default ManageContracts;
