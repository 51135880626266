import { Box } from '@mui/material';
import React from 'react';

const ViewTwoline = ({ data, rounded }) => {
  return (
    <Box className='flex flex-col gap-0.5 w-full'>
      {data?.map((item, i) => {
        return (
          <Box
            key={i}
            className={`${item.className} flex items-center gap-1 ${
              rounded && 'rounded  justify-between '
            }`}
          >
            {/* highlight text equal */}
            {item.name && <span>{item.name} </span>}
            <span className='font-500'>{item.value}</span>
          </Box>
        );
      })}
    </Box>
  );
};

export default ViewTwoline;
