import React, { useEffect, useState } from 'react';
import GlassCard from './../../../../components/small/GlassCard.tsx';
import { Grid } from '@mui/material';
import * as yup from 'yup';
import { yupResolver } from '@hookform/resolvers/yup';
import Crud_Service from '../../../../apis/CrudService.jsx';
import { useForm } from 'react-hook-form';
import { toast } from 'react-toastify';
import dayjs from 'dayjs';
import FmTextField from '../../../../components/_mui/FmTextField.tsx';
import ActionButtons from '../../../../components/_form/ActionButtons.tsx';
import FmSearchableSelect from '../../../../components/_mui/FmSearchableSelect.tsx';
import FmDatePicker from '../../../../components/_mui/FmDatePicker.tsx';
import DashboardLayout from '../../../../components/DashboardLayout.tsx';
import Nav from '../../../../utils/Nav.tsx';
import { useNavigate } from 'react-router-dom';
const GridSize = 2.4;
const schema = yup.object().shape({
  salesOrderName: yup.string().required('Sales Order Name is required'),

  regionId: yup
    .number()
    .transform((value, originalValue) => (originalValue === '' ? null : value))
    .required('Region is required'),
  locationId: yup
    .number()
    .transform((value, originalValue) => (originalValue === '' ? null : value))
    .required('Location is required'),

  orderStatusId: yup
    .number()
    .transform((value, originalValue) => (originalValue === '' ? null : value))
    .required('Order Status is required'),
  customerId: yup
    .number()
    .transform((value, originalValue) => (originalValue === '' ? null : value))
    .required('Customer is required'),
});

const AddSalesOrder = (props) => {
  const { setGetData, type } = props;
  const crud = new Crud_Service();
  const [loading, setLoading] = useState(false);
  const { control, handleSubmit, reset, setValue } = useForm({
    resolver: yupResolver(schema),
    mode: 'onChange',
  });
  const [selectedOrder, setSelectedOrder] = useState({});
  const navigate = useNavigate();

  useEffect(() => {
    reset();
    setSelectedOrder({});
  }, [type]);

  useEffect(() => {
    if (selectedOrder?.salesQuotationId) {
      setValue('contactPersonId', selectedOrder?.contactPersonId);
      setValue('deliveryTerms', selectedOrder?.deliveryTerms);
      setValue('locationId', selectedOrder?.locationId);
      setValue('narration', selectedOrder?.narration);
      setValue('notes', selectedOrder?.notes);
      setValue('validity', selectedOrder?.validity);
      setValue('paymentTerms', selectedOrder?.paymentTerms);
      setValue('subject', selectedOrder?.subject);
      setValue('regionId', selectedOrder?.regionId);
      setValue('remarks', selectedOrder?.remarks);
      setValue('attn', selectedOrder?.attn);
      setValue('loginUserId', selectedOrder?.contactPersonId);
    }
  }, [selectedOrder]);

  const submitSalesOrder = async (values) => {
    setLoading(true);
    const combinedValues = {
      ...values,
      //TODO: Need to Do Dynamic in future
      salesOrderTypeId: type,
      orderDate: values?.orderDate
        ? dayjs(values?.orderDate).format('YYYY-MM-DD')
        : null,
    };
    await crud.create('salesorders', combinedValues, (_err, res) => {
      if (res?.status === 201) {
        toast.success('Added Sales Order Successfully');
        navigate(-1);
        setGetData(true);
        reset();
        setSelectedOrder({});
        setLoading(false);
      } else {
        setLoading(false);
      }
    });
  };

  const handleReset = () => {
    reset();
    setSelectedOrder({});
  };

  return (
    <DashboardLayout title='Sales Order' hasSubmenu={type !== 4}>
      <GlassCard className='p-3'>
        <form onSubmit={handleSubmit(submitSalesOrder)}>
          <Grid container spacing={2}>
            <Grid item md={GridSize}>
              <FmTextField
                name='salesOrderName'
                label='Sales Order Name'
                control={control}
                required
              />
            </Grid>

            <Grid item md={GridSize}>
              <FmDatePicker
                name='orderDate'
                label='Order Date'
                control={control}
              />
            </Grid>

            <Grid item md={GridSize}>
              <FmSearchableSelect
                name='salesQuotationId'
                label='Sales Quotation'
                apiUrl={'salesquotations'}
                valueField='salesQuotationId'
                onChangeProp={(value) => setSelectedOrder(value)}
                labelField={['salesQuotationName', 'salesQuotationCode']}
                showField={['salesQuotationName', 'salesQuotationCode']}
                control={control}
              />
            </Grid>
            <Grid item md={GridSize}>
              <FmSearchableSelect
                name='customerId'
                label='Customer'
                apiUrl={'customers'}
                valueField='customerId'
                labelField={['customerName', 'customerCode']}
                required
                showField={['customerName', 'customerCode']}
                control={control}
              />
            </Grid>

            <Grid item md={GridSize}>
              <FmSearchableSelect
                name='regionId'
                label='Region'
                required
                apiUrl={'regions'}
                valueField='regionId'
                labelField={['regionName', 'regionCode']}
                defaultValue={{
                  regionId: selectedOrder?.regionId,
                  regionName: selectedOrder?.regionName,
                }}
                showField={['regionName', 'regionCode']}
                control={control}
              />
            </Grid>

            <Grid item md={GridSize}>
              <FmSearchableSelect
                name='locationId'
                label='Location'
                apiUrl={'locations'}
                required
                valueField='locationId'
                labelField={['locationName']}
                showField={['locationName']}
                defaultValue={{
                  locationId: selectedOrder?.locationId,
                  locationName: selectedOrder?.locationName,
                }}
                control={control}
              />
            </Grid>

            <Grid item md={GridSize}>
              <FmSearchableSelect
                name='workOrderId'
                label='Work Order'
                apiUrl={'workorders'}
                valueField='workOrderId'
                labelField={['workOrderName', 'workOrderCode']}
                showField={['workOrderName', 'workOrderCode']}
                defaultValue={{
                  workOrderId: selectedOrder?.workOrderId,
                  workOrderName: selectedOrder?.workOrderName,
                }}
                control={control}
              />
            </Grid>
            <Grid item md={GridSize}>
              <FmTextField
                name='paymentTerms'
                label='Payment Terms'
                control={control}
              />
            </Grid>
            <Grid item md={GridSize}>
              <FmTextField
                name='narration'
                label='Narration'
                control={control}
              />
            </Grid>
            <Grid item md={GridSize}>
              <FmTextField
                name='deliveryTerms'
                label='Delivery Terms'
                control={control}
              />
            </Grid>
            <Grid item md={GridSize}>
              <FmTextField name='subject' label='Subject' control={control} />
            </Grid>
            <Grid item md={GridSize}>
              <FmTextField name='notes' label='Notes' control={control} />
            </Grid>
            <Grid item md={GridSize}>
              <FmSearchableSelect
                name='contactPersonId'
                label='Contact Person'
                apiUrl={'loginusers'}
                valueField='loginUserId'
                defaultValue={{
                  loginUserId: selectedOrder?.contactPersonId,
                  loginUserName: selectedOrder?.contactPersonName,
                }}
                labelField={['loginUserName']}
                showField={['loginUserName']}
                control={control}
              />
            </Grid>

            <Grid item md={GridSize}>
              <FmTextField name='validity' label='Validity' control={control} />
            </Grid>

            <Grid item md={GridSize}>
              <FmTextField
                name='attn'
                label='Attention (Attn)'
                control={control}
              />
            </Grid>

            <Grid item md={GridSize}>
              <FmSearchableSelect
                name='orderStatusId'
                label='Order Status'
                apiUrl={'salesorders/statuses'}
                required
                valueField='salesOrderStatusId'
                labelField={['salesOrderStatusName']}
                showField={['salesOrderStatusName']}
                control={control}
              />
            </Grid>
            <Grid item md={GridSize}>
              <FmTextField
                name='remarks'
                multiline
                rows={4}
                label='Remarks'
                control={control}
              />
            </Grid>
          </Grid>
          <ActionButtons
            onSubmit={handleSubmit(submitSalesOrder)}
            onReset={handleReset}
            cancelText='Back'
            onCancel={() => navigate(-1)}
            submitLoading={loading}
            cancelLoading={false}
            submitText='Create'
          />
        </form>
      </GlassCard>
    </DashboardLayout>
  );
};

export default AddSalesOrder;
