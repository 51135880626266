import React from 'react';
import { Box } from '@mui/material';
import { Icon } from '@iconify/react/dist/iconify.js';
import Lottie from 'react-lottie';
import * as animationData from '../../assets/lottie/nodata.json';

const NoDataAvailable = () => {
  return (
    <div
      style={{
        display: 'flex',
        justifyContent: 'center',
        alignItems: 'center',
        height: '100%',
      }}
    >
      <div className='text-center flex-col flex gap-6 justify-center items-center  '>
        <Box
          sx={{ filter: 'hue-rotate(2.6735rad)', opacity: 0.3 }}
          className='relative grayscale'
        >
          <Lottie
            options={{
              loop: true,
              autoplay: true,
              animationData: animationData,
            }}
            height={'400px'}
            width={'400px'}
          />
          {/* <Icon icon='bi:database-fill-x' width='65' height='65' /> */}
          <h3 className='text-base absolute bottom-0 left-0 w-full'>
            No data available
          </h3>
        </Box>
      </div>
    </div>
  );
};

export default NoDataAvailable;
