import React, { useEffect, useMemo } from 'react';
import DashboardLayout from '../../../../components/DashboardLayout.tsx';
import Nav from '../../../../utils/Nav.tsx';
import PurchaseVoucherList from './PurchaseVoucherList.tsx';
import { useLocation, useNavigate } from 'react-router-dom';
import useTableLogic from '../../../../components/helpers/MRTUseTableLogic.tsx';
import { Box } from '@mui/material';
import { CreateButton } from '../../../../components/small/Buttons.jsx';
import { useSelector } from 'react-redux';
import { findNavByName } from '../../../../utils/navUtils.ts';

const PurchaseVoucher = (props) => {
  const { type } = props;
  const location = useLocation();


  const [getData, setGetData] = React.useState(false);

  useEffect(() => {
    document.title = `Purchase Vouchers | Accounts Payable | Financial`;
  }, []);

  const {
    rows,
    isLoading,
    pagination,
    sorting,
    columnFilters,
    globalFilter,
    tableRecordCounts,
    setPagination,
    setSorting,
    setColumnFilters,
    setGlobalFilter,
    fetchData,
    setSearchKeyword,
    searchKeyword,
    showSerialNumber,
  } = useTableLogic(`purchasevouchers?purchaseVoucherTypeId=${type}`);
  const navigate = useNavigate();

  useEffect(() => {
    fetchData();
  }, [type]);
  const menu = useSelector((state) => state?.menu?.items);

  // Flatten menu only once and store it for reuse
  const SubMenu = useMemo(() => {
    return menu?.map(item => item?.menus?.flat()).flat() || [];
  }, [menu]);

  // Use findNavByName to get the "Accounts Receivable" menu or its child menus
  const result = useMemo(() => findNavByName(SubMenu, "Financial", "Accounts Payable"), [SubMenu]);



  return (
    <>
      <DashboardLayout
        title={'Purchase Vouchers'}
        hasSubmenu
        menu={result}
        actionButtons={
          <CreateButton name='Create' onClick={() => navigate('create')} />
        }
      >
        <PurchaseVoucherList
          type={type}
          rows={rows}
          isLoading={isLoading}
          pagination={pagination}
          sorting={sorting}
          columnFilters={columnFilters}
          globalFilter={globalFilter}
          tableRecordCounts={tableRecordCounts}
          setPagination={setPagination}
          setSorting={setSorting}
          setColumnFilters={setColumnFilters}
          setGlobalFilter={setGlobalFilter}
          fetchData={fetchData}
          setSearchKeyword={setSearchKeyword}
          searchKeyword={searchKeyword}
          showSerialNumber={true}
        />
      </DashboardLayout>
    </>

  );
};

export default PurchaseVoucher;
