import { yupResolver } from '@hookform/resolvers/yup';
import { Icon } from '@iconify/react';
import { Box, Button } from '@mui/material';
import React, { useEffect, useRef, useState } from 'react';
import { useForm } from 'react-hook-form';
import { useParams } from 'react-router-dom';
import { toast } from 'react-toastify';
import Crud_Service from '../../../../apis/CrudService';
import TableActionButtons from '../../../../components/_form/TableActionButtons.jsx';
import FmAutoComplete from '../../../../components/_mui/FmAutoComplete.tsx';
import FmTextField from '../../../../components/_mui/FmTextField.tsx';
import FmSearchableSelect from '../../../../components/_mui/FmSearchableSelect.tsx';
import { useHotkeys } from 'react-hotkeys-hook';
import useTableLogic from '../../../../components/helpers/MRTUseTableLogic.tsx';
import useCommonFetchApi from '../../../../components/helpers/useCommonFetchApi.tsx';
import GlassCard from '../../../../components/small/GlassCard.tsx';
import { materialRequestItemSchema } from '../../../../utils/CommonvalidationSchemas.tsx';
import FmMRTDataTable from './../../../../components/FmMRTDataTable.tsx';
import TableAvatar from '../../../../components/_form/TableAvatar.jsx';
import { ViewField } from '../../../../components/_form/FormElements.jsx';

const TableCode = ({ title, desc }) => {
  return (
    <TableAvatar name={title} desc={desc && `Code: ${desc}`} removeImage />
  );
};

const CartMaterialRequest = ({ locationId }) => {
  const crud = new Crud_Service();
  const { id } = useParams();
  const [rowState, setRowState] = useState({
    newRowId: null,
    isCreatingRow: false,
  });
  const {
    reset,
    setValue,
    getValues,
    control,
    formState: { isValid },
  } = useForm({
    resolver: yupResolver(materialRequestItemSchema),
    mode: 'all',
  });
  const [editingRowId, setEditingRowId] = useState(null);
  const [editingRowData, setEditingRowData] = useState(null);
  const [availableQuantity, setAvailableQuantity] = useState(0);
  const [selectedItem, setSelectedItem] = useState(null);
  const { data: item } = useCommonFetchApi('item');
  const firstColumnInputRef = useRef(null);
  const [stateError, setStateError] = useState(false);
  const [editingRowIndex, setEditingRowIndex] = useState(null);
  const [currentRowIndex, setCurrentRowIndex] = useState(0);
  const [newRowCreated, setNewRowCreated] = useState(false);
  const [loader, setLoader] = useState(false);
  const {
    rows,
    setRows,
    isLoading,
    pagination,
    sorting,
    columnFilters,
    globalFilter,
    tableRecordCounts,
    setSearchKeyword,
    searchKeyword,
    setPagination,
    setSorting,
    setColumnFilters,
    setGlobalFilter,
    fetchData,
  } = useTableLogic(
    `materialrequisitionitems?materialrequisitionid=${id}&sortDirection=asc`
  );



  useEffect(() => {
    if (!selectedItem || !selectedItem?.itemId) return;
    const { brandName, modelId, costCenterName } = selectedItem;
    setValue('brandName', brandName || '');
    setValue('categoryId', modelId || '');
    setValue('costCenterName', costCenterName || '');

    const fetchItemDetails = async () => {
      crud.getAll(
        'inventoryitems',
        { storelocationId: locationId, itemId: selectedItem?.itemId },
        (err, res) => {
          if (err) {
            toast.error('Failed to fetch item details. Please try again.');
            return;
          }
          if (res?.status === 200) {
            setAvailableQuantity(res?.data);
            setValue(
              'onHandQuantity',
              res?.data?.data?.availableQuantity || 0
            );
          } else {
            toast.error('Failed to fetch item details. Please try again.');
          }
        }
      );
    };

    fetchItemDetails();
  }, [selectedItem, locationId]);

  const handleDelete = async (props) => {
    await crud.remove(
      'materialrequisitionitems',
      props?.materialRequisitionItemId,
      (_err, res) => {
        if (res?.status === 204) {
          toast.success('Material Requisition Item Deleted Successfully');
          fetchData();
        } else {
        }
      }
    );
  }

  const handleEdit = (row) => {
    reset();
    if (rowState?.newRowId) {
      setRows((prevRows) => {
        if (prevRows?.length > 0) {
          return prevRows?.slice(0, -1);
        }
        return prevRows;
      });
      setRowState((prevState) => ({
        ...prevState,
        newRowId: null,
        isCreatingRow: false,
      }));
      // setEditingRowIndex(null);
    }
    setEditingRowId(row?.materialRequisitionItemId);
    Object.entries({
      ...row,
    }).forEach(([key, value]) => {
      setValue(key, value);
    });
    setEditingRowData({ ...row });
  };

  const ActionData = [
    {
      name: 'Delete',
      icon: <Icon icon='mi:delete' />,
      onClick: (props) => handleDelete(props),
      danger: true,
    },
  ];

  const handleCancel = (row) => {
    if (row?.original?.materialRequisitionItemId === rowState?.newRowId) {
      setRows((prevRows) => {
        if (prevRows?.length > 0) {
          return prevRows?.slice(0, -1);
        }
        return prevRows;
      });
      setRowState((prevState) => ({
        ...prevState,
        newRowId: null,
        isCreatingRow: false,
      }));
    } else {
      setEditingRowId(null);
      setEditingRowIndex(null);
    }
    reset();
  };

  useHotkeys(
    'Enter',
    () => handleAddNewRow(),
    {
      enableOnTags: ['INPUT', 'TEXTAREA'],
    },
    [editingRowIndex, rows, stateError, loader]
  );

  useHotkeys(
    'Tab',
    (event) => {
      if (event.key !== 'Tab') return;
      if (stateError) {
        event.preventDefault();
        return;
      }
      const totalRows = rows.length;
      const nextRowIndex = editingRowIndex + 1;

      if (
        nextRowIndex < totalRows &&
        stateError === false &&
        loader === false
      ) {
        setCurrentRowIndex(nextRowIndex);
        handleEdit(rows[nextRowIndex]);
        setEditingRowIndex(nextRowIndex);
      }
    },
    [editingRowIndex, rows, stateError, loader, isValid]
  );

  useEffect(() => {
    if (editingRowIndex !== null && firstColumnInputRef.current && !isLoading) {
      firstColumnInputRef.current.focus();
    }
  }, [editingRowIndex, isLoading]);

  const previousValues = useRef(getValues());

  const handleBlur = () => {
    const currentValues = getValues();
    const value = getValues('salesOrderId');
    if (!value) {
      firstColumnInputRef.current.focus();
      return;
    }
    const hasChanges = Object.keys(currentValues).some(
      (key) => currentValues[key] !== previousValues.current[key]
    );

    if ((hasChanges && isValid) || (hasChanges && editingRowId)) {
      apiCallAsync(currentValues);
      previousValues.current = currentValues;
    }
  };

  const apiCallAsync = async (values) => {
    setLoader(true);
    const convertedValues = {
      ...values,
      materialRequisitionId: id,
      quantity: Number(values?.quantity),
      materialRequisitionItemStatusId:
        availableQuantity < values?.quantity ? 1 : 2,
    };

    const updatedCombinedData = {
      ...values,
      categoryId: Number(selectedItem?.modelId),
      materialRequisitionId: Number(id),
      quantity: Number(values?.quantity) || 0,
      costCenterId: Number(values?.costCenterId),
      unitofmeasurementId: Number(selectedItem?.unitOfMeasurementId),
      brandId: Number(selectedItem?.brandId),
      materialRequisitionItemStatusId:
        availableQuantity < values?.quantity ? 1 : 2,
      status: 2,
    };

    if (editingRowId === null) {
      await crud.create(
        'materialrequisitionitems',
        convertedValues,
        (err, res) => {
          if (res?.status === 201) {
            setEditingRowId(res?.data?.materialRequisitionItemId);
            setValue(
              'materialRequisitionItemId',
              res?.data?.materialRequisitionItemId
            );
          } else {
          }
        }
      );
    } else {
      await crud.update(
        'materialrequisitionitems',
        editingRowId,
        updatedCombinedData,
        (err, res) => {
          if (res?.status === 200) {
            if (rows.some((row) => row.isNew)) {
              const updatedRow = res?.data;
              const updatedRows = [...rows];
              updatedRows[editingRowIndex] = {
                ...updatedRow,
                isEditable: true,
                isNew: true,
              };
              setRows(updatedRows);
              setStateError(false);
              setLoader(false);
            } else {
              setStateError(false);
              setLoader(false);
              const updatedRow = res?.data;
              const updatedRows = [...rows];

              updatedRows[editingRowIndex] = updatedRow;

              setRows(updatedRows);
            }
          } else {
            setStateError(true);
          }
        }
      );
    }
  };

  const handleBlurAndFetch = async () => {
    setLoader(true);
    const updatedRow = getValues();
    const updatedRows = [...rows];
    updatedRows[editingRowIndex] = updatedRow;
    setRows(updatedRows);
    const values = getValues();
    const updateCombinedData = {
      ...values,
      categoryId: Number(selectedItem?.modelId),
      materialRequisitionId: Number(id),
      quantity: Number(values?.quantity),
      costCenterId: Number(selectedItem?.costCenterId) || null,
      unitofmeasurementId: Number(selectedItem?.unitOfMeasurementId),
      brandId: Number(selectedItem?.brandId),
      materialRequisitionItemStatusId:
        availableQuantity < values?.quantity ? 1 : 2,
      status: 2,
    };
    await crud.update(
      'materialrequisitionitems',
      editingRowId,
      updateCombinedData,
      (err, res) => {
        if (res?.status === 200) {
          const totalRows = rows.length;
          if (editingRowIndex <= totalRows && rows.some((row) => row.isNew)) {
            setNewRowCreated(true);
            const updatedRow = res?.data;
            const updatedRows = [...rows];
            updatedRows[editingRowIndex] = {
              ...updatedRow,
              isEditable: true,
              isNew: true,
            };
            setRows(updatedRows);
            handleAddNewRow();
          } else {
            const updatedRow = res?.data;
            const updatedRows = [...rows];
            updatedRows[editingRowIndex] = {
              ...updatedRow,
              isEditable: false,
              isNew: false,
            };

            setRows(updatedRows);
            setStateError(false);
            setLoader(false);
          }
        } else {
          setStateError(true);
        }
      }
    );
  };
  const handleAddNewRow = () => {
    const newId = 'new_row_' + Date.now();
    reset();
    setEditingRowData(null);
    setEditingRowId(null);
    setRowState({
      newRowId: newId,
      isCreatingRow: true,
    });

    setEditingRowIndex(
      editingRowIndex === rows.length ? editingRowIndex + 1 : rows.length
    );
    setNewRowCreated(false);
    setRows((prevRows) => [
      ...prevRows,
      {
        materialRequisitionItemId: newId,
        materialRequisitionId: id,
        salesOrderId: '',
        itemId: '',
        costCenterId: '',
        quantity: '',
        brandId: '',
        unitOfMeasurementId: '',
        categoryId: '',
        quantity: '',
        remarks: '',
        isEditable: true,
        isNew: true,
      },
    ]);
    reset();
  };

  const handleCancelAndAddNewRow = () => {
    const newId = 'new_row_' + Date.now();
    reset();
    setEditingRowData(null);
    setEditingRowId(null);
    setRowState({
      newRowId: newId,
      isCreatingRow: true,
    });
    setEditingRowIndex(
      editingRowIndex === rows.length ? editingRowIndex + 1 : rows.length
    );
    setNewRowCreated(false);
    setRows((prevRows) => [
      ...prevRows,
      {
        materialRequisitionItemId: newId,
        materialRequisitionId: id,
        salesOrderId: '',
        itemId: '',
        costCenterId: '',
        quantity: '',
        brandId: '',
        unitOfMeasurementId: '',
        categoryId: '',
        quantity: '',
        remarks: '',
        isEditable: true,
        isNew: true,
      },
    ]);
    reset();
  };

  const columns = [
    {
      accessorKey: 'salesOrderId',
      header: 'Sales Order',
      enableEditing: true,
      Cell: ({ row }) => {
        const isNewRow =
          row?.original?.materialRequisitionItemId === rowState?.newRowId;
        const isEditing =
          editingRowId === row?.original?.materialRequisitionItemId || isNewRow;
        return isEditing ? (
          <FmSearchableSelect
            name='salesOrderId'
            control={control}
            apiUrl='salesorders'
            valueField='salesOrderId'
            labelField={['salesOrderCode', 'salesOrderName']}
            showField={['salesOrderCode', 'salesOrderName']}
            label={'Sales Order'}
            inputRef={firstColumnInputRef}
            onBlur={handleBlur}
            defaultValue={{
              salesOrderId: editingRowData?.salesOrderId,
              salesOrderName: editingRowData?.salesOrderName,
            }}
          />
        ) : (
          <TableCode
            title={row?.original?.salesOrderName}
            desc={row?.original?.salesOrderCode}
          />
        );
      },
    },

    {
      accessorKey: 'itemId',
      header: 'Item',
      enableEditing: true,
      Cell: ({ row }) => {
        const isNewRow =
          row?.original?.materialRequisitionItemId === rowState?.newRowId;
        const isEditing =
          editingRowId === row?.original?.materialRequisitionItemId || isNewRow;
        return isEditing ? (
          <FmAutoComplete
            name='itemId'
            control={control}
            options={item}
            displayField='itemName'
            optionFields={['itemName']}
            valueKey='itemId'
            onChangeValue={true}
            onChange={(ev) => setSelectedItem(ev)}
            onBlur={handleBlur}
          />
        ) : (
          row?.original?.itemName || '-'
        );
      },
    },

    {
      accessorKey: 'costCenterId',
      header: 'Cost Center',
      enableEditing: true,
      size: 120,
      Cell: ({ row }) => {
        const isNewRow =
          row?.original?.materialRequisitionItemId === rowState?.newRowId;
        const isEditing =
          editingRowId === row?.original?.materialRequisitionItemId || isNewRow;
        return isEditing ? (
          // <ViewField label='Cost Center' title={selectedItem?.costCenterName} />
          <FmSearchableSelect
            name='costCenterId'
            control={control}
            apiUrl='costcenters'
            valueField='costCenterId'
            labelField={['costCenterCode', 'costCenterName']}
            showField={['costCenterName']}
            label={'Cost Center'}
            defaultValue={{
              costCenterId: editingRowData?.costCenterId || selectedItem?.costCenterId,
              costCenterName: editingRowData?.costCenterName || selectedItem?.costCenterName,
            }}
          />

        ) : (
          row?.original?.costCenterName || '-'
        );
      },
    },

    {
      accessorKey: 'quantity',
      header: 'Quantity',
      size: 100,
      enableEditing: true,
      Cell: ({ row }) => {
        const isNewRow =
          row?.original?.materialRequisitionItemId === rowState?.newRowId;
        const isEditing =
          editingRowId === row?.original?.materialRequisitionItemId || isNewRow;
        return isEditing ? (
          <FmTextField
            name='quantity'
            label='Quantity'
            control={control}
            onBlur={handleBlur}
          />
        ) : (
          row?.original?.quantity
        );
      },
    },

    {
      accessorKey: 'brandName',
      header: 'Brand',
      enableEditing: false,
      Cell: ({ row }) => {
        const isNewRow =
          row?.original?.materialRequisitionItemId === rowState?.newRowId;
        const isEditing =
          editingRowId === row?.original?.materialRequisitionItemId || isNewRow;
        return isEditing ? (
          <ViewField label='Brand' title={selectedItem?.brandName} />
        ) : (
          row?.original?.brandName
        );
      },
    },

    {
      accessorKey: 'unitOfMeasurementId',
      header: 'UOM',
      enableEditing: true,
      Cell: ({ row }) => {
        const isNewRow =
          row?.original?.materialRequisitionItemId === rowState?.newRowId;
        const isEditing =
          editingRowId === row?.original?.materialRequisitionItemId || isNewRow;
        return isEditing ? (
          <ViewField label='UOM' title={selectedItem?.unitOfMeasurementId} />
        ) : (
          row?.original?.unitOfMeasurementName || '-'
        );
      },
    },

    {
      accessorKey: 'categoryId',
      header: 'Category',
      enableEditing: true,
      Cell: ({ row }) => {
        const isNewRow =
          row?.original?.materialRequisitionItemId === rowState?.newRowId;
        const isEditing =
          editingRowId === row?.original?.materialRequisitionItemId || isNewRow;
        return isEditing ? (
          <ViewField label='Category' title={selectedItem?.modelName} />
        ) : (
          row?.original?.categoryName || '-'
        );
      },
    },

    {
      accessorKey: 'onHandQuantity',
      header: 'On Hand Quantity',
      enableEditing: false,
      Cell: ({ row }) => {
        const isNewRow =
          row?.original?.materialRequisitionItemId === rowState?.newRowId;
        const isEditing =
          editingRowId === row?.original?.materialRequisitionItemId || isNewRow;
        return isEditing ? (
          <FmTextField
            name='onHandQuantity'
            label='On Hand Quantity'
            control={control}
            onBlur={handleBlur}
          />
        ) : (
          row?.original?.onHandQuantity || '-'
        );
      },
    },

    {
      accessorKey: 'remarks',
      header: 'Remarks',
      enableEditing: true,
      Cell: ({ row }) => {
        const isNewRow =
          row?.original?.materialRequisitionItemId === rowState?.newRowId;
        const isEditing =
          editingRowId === row?.original?.materialRequisitionItemId || isNewRow;
        return isEditing ? (
          <FmTextField
            name='remarks'
            control={control}
            label='Remarks'
            multiline={true}
            rows={1}
            maxRows={1}
            onBlur={handleBlurAndFetch}
          />
        ) : (
          row?.original?.remarks
        );
      },
    },

    {
      header: 'Action',
      enableColumnPinning: false,
      enableEditing: false,
      enableSorting: false,
      Cell: ({ row, table }) => {
        const isNewRow =
          row?.original?.materialRequisitionItemId === rowState?.newRowId;
        const isEditing =
          editingRowId === row?.original?.materialRequisitionItemId || isNewRow;
        const isLastRow = row.index === table.getRowModel().rows.length - 1;

        return (
          <Box className='flex gap-2'>
            {isEditing ? (
              <>
                <Button onClick={() => handleCancel(row)}>Close</Button>
                {isLastRow && rowState?.isCreatingRow === false && (
                  <Button onFocus={() => handleCancelAndAddNewRow()}></Button>
                )}
              </>
            ) : (
              <Box className='flex gap-2'>
                <TableActionButtons
                  Actions={ActionData.map((action) => ({
                    ...action,
                    onClick: () => action.onClick(row?.original),
                  }))}
                />
                {isLastRow && (
                  <Button
                    variant='contained'
                    color='primary'
                    className='px-3 py-1'
                    onClick={() => {
                      setEditingRowIndex(row?.index + 1);
                      handleAddNewRow(row);
                    }}
                  >
                    Add
                  </Button>
                )}
              </Box>
            )}
          </Box>
        );
      },
    },
  ];



  return (
    <>
      <GlassCard className='h-[450px]'>
        <FmMRTDataTable
          columns={columns}
          enableRowSelection={false}
          rows={rows}
          muiTableContainerProps={{
            sx: {
              maxHeight: '100%',
              flex: 1,
            },
          }}
          onCreateRow={handleAddNewRow}
          isCreatingRow={rowState?.isCreatingRow}
          rowCount={tableRecordCounts}
          editingRow={editingRowId}
          pagination={pagination}
          setSearchKeyword={setSearchKeyword}
          sorting={sorting}
          columnFilters={columnFilters}
          globalFilter={globalFilter}
          setPagination={setPagination}
          setSorting={setSorting}
          setColumnFilters={setColumnFilters}
          setGlobalFilter={setGlobalFilter}
          isLoading={isLoading}
          setSearchKeyword={setSearchKeyword}
          searchKeyword={searchKeyword}
          fetchData={fetchData}
          handleRowDoubleClick={(row) => {
            handleEdit(row?.original);
            setEditingRowIndex(row?.index);
          }}
        />
      </GlassCard>
    </>
  );
};

export default CartMaterialRequest;
