import React, { useState, useRef, useEffect } from 'react';
import ReactQuill from 'react-quill';
import 'react-quill/dist/quill.snow.css';
import { Box, IconButton, Tooltip } from '@mui/material';
import { Icon } from '@iconify/react/dist/iconify.js';

const ScopeNotesTextEditor = ({scopeNotes,setScopeNotes}) => {
  const [value, setValue] = useState(scopeNotes||'');
  const [maximize, setMaximize] = useState(false);
  const editorRef = useRef(null);

  const quillModules = {
    toolbar: {
      container: [
        [{ header: '1' }, { header: '2' }, { font: [] }],
        [{ list: 'ordered' }, { list: 'bullet' }],
        ['bold', 'italic', 'underline', 'strike', 'blockquote'],
        [{ align: [] }],
        ['link'],
        ['clean'],
      ],
    },
  };
  useEffect(() => {
    if(value){
      setScopeNotes(value)
    }
  },[value])
  

  // 🖨️ Function to trigger print preview
  const handlePrint = () => {
    if (editorRef.current) {
      const content = editorRef.current.querySelector('.ql-editor').innerHTML;

      // Create a new print-friendly section
      const printContainer = document.createElement('div');
      printContainer.innerHTML = content;
      printContainer.style.padding = '20px';
      printContainer.style.fontFamily = 'Arial, sans-serif';
      printContainer.style.color = '#000';

      // Temporarily replace body content with the Quill content
      const originalContent = document.body.innerHTML;
      document.body.innerHTML = printContainer.outerHTML;

      window.print();

      // Restore original body content after printing
      document.body.innerHTML = originalContent;
      window.location.reload(); // Ensures UI is restored correctly
    }
  };

  return (
    <Box
      className={
        maximize
          ? 'fixed top-0 left-0 z-50  bg-white rounded-none w-full h-full border-none shadow-none p-2 overflow-auto'
          : 'mt-3 relative overflow-hidden w-full'
      }
    >
      <Box
        className='w-full relative'
        ref={editorRef}
        sx={{
          height: maximize ? 'calc(100% - 50px)' : '300px',
          '& .quill': {
            display: 'flex',
            flexDirection: 'column',
            height: '100%',
          },

          '& .ql-editor': {
            flex: 1,
            minHeight: 0,
            overflow: 'auto',

            '& p': {
              margin: '10px 0',
            },
          },
        }}
      >
        <Box className='absolute top-1 right-1 flex items-center gap-2'>
          {/* 🖨️ Print Button */}
          <Tooltip title='Print'>
            <IconButton className='p-2' onClick={handlePrint}>
              <Icon icon='material-symbols:print' width='20' height='20' />
            </IconButton>
          </Tooltip>

          {/* 🔄 Maximize Button */}
          <Tooltip title={maximize ? 'Minimize' : 'Maximize'}>
            <IconButton className='p-2' onClick={() => setMaximize(!maximize)}>
              {maximize ? (
                <Icon icon='gg:minimize' width='20' height='20' />
              ) : (
                <Icon icon='gg:maximize' width='20' height='20' />
              )}
            </IconButton>
          </Tooltip>
        </Box>

        {/* 📝 Quill Editor */}
        <ReactQuill value={value||scopeNotes} onChange={setValue} modules={quillModules} />
      </Box>
    </Box>
  );
};

export default ScopeNotesTextEditor;
