import React, { useEffect, useState } from 'react';
import { toast } from 'react-toastify';
import useLoader from '../../../components/helpers/UseLoader.tsx';
import { useForm } from 'react-hook-form';
import { itemsSchema } from '../../../utils/CommonvalidationSchemas.tsx';
import { yupResolver } from '@hookform/resolvers/yup';
import { useNavigate, useParams } from 'react-router-dom';
import Crud_Service from '../../../apis/CrudService.jsx';
import { ActionButtons } from '../../../components/_form';
import { Box, CircularProgress, Grid, Typography } from '@mui/material';
import FmTextField from '../../../components/_mui/FmTextField.tsx';
import {
  ItemConditionEnum,
  ItemFlag,
  OrderFrequency,
  StorageCondition,
} from '../../../utils/CommonVariables.tsx';
import FmAutoComplete from '../../../components/_mui/FmAutoComplete.tsx';
import useCommonFetchApi from '../../../components/helpers/useCommonFetchApi.tsx';
import FmSearchableSelect from '../../../components/_mui/FmSearchableSelect.tsx';
import DashboardLayout from '../../../components/DashboardLayout.tsx';
import { BacktoListSimple } from '../../../components/small/BackToButton.tsx';
import GlassCard from '../../../components/small/GlassCard.tsx';
import UploadSquare from '../../../components/upload/UploadSquare.tsx';
import apiInstance from '../../../apis/ApiService.jsx';
import FmFileInput from '../../../components/_mui/FmFileInput.tsx';

const EditItems = () => {
  const { id } = useParams();
  const crud = new Crud_Service();
  const navigate = useNavigate();
  const [files, setFiles] = useState([]);
  const [previewdData, setPreviewData] = useState([]);
  const [removedFiles, setRemovedFiles] = useState([]);
  const [loadingFiles, setLoadingFiles] = useState(false);
  const [formLoader, setFormLoader] = useState(false);
  const [loading, setLoading] = useState(false);
  const [editData, setEditData] = useState(null);

  const {
    handleSubmit,
    control,
    setValue,
    reset,
    getValues,
    formState: { errors },
  } = useForm({
    resolver: yupResolver(itemsSchema),
    mode: 'onChange',
  });
  const { createLoading, startCreateLoading, stopCreateLoading } = useLoader();
  const { data: unitOptions } = useCommonFetchApi('unitofmeasurements');
  const { data: taxcodes } = useCommonFetchApi('taxcodes');
  const { data: itemtypes } = useCommonFetchApi('itemtypes');
  const { data: item } = useCommonFetchApi('item');
  const { data: costcenters } = useCommonFetchApi('costcenters');
  const { data: valuationmethods } = useCommonFetchApi('valuationmethods');
  const { data: itemgroups } = useCommonFetchApi('itemgroups');
  const { data: brands } = useCommonFetchApi('brands');
  const handleFileUpload = async () => {
    const fileIds = [];

    for (const file of files) {
      if (file?.apiCallNo === false) {
        continue;
      }
      const formData = new FormData();
      formData.append('file', file);
      formData.append('fileTypeId', 50);

      try {
        const fileId = await new Promise((resolve, reject) => {
          crud.create('/files/upload', formData, (_err, res) => {
            if (res?.status === 200) {
              const newFile = {
                fileName: res?.data?.originalFileName,
                fileId: res?.data?.fileId,
              };
              resolve(newFile.fileId); // Resolve with fileId
              toast.success('File uploaded successfully');
            } else {
              toast.error('Failed to upload file.');
              reject(new Error('Failed to upload file.'));
            }
          });
        });

        if (fileId) {
          fileIds.push(fileId);
        }
      } catch (error) { }
    }

    return fileIds;
  };
  const getAllItems = async () => {
    setLoading(true);
    await crud.getSingle('item', id, (_err, res) => {
      if (res?.status === 200) {
        Object.entries({
          ...res?.data,
        }).forEach(([key, value]) => {
          setValue(key, value);
        });
        setEditData(res?.data);
        setLoading(false);
      } else {
        setLoading(false);
      }
    });
  };
  const handleDownloadFile = async (fileId, deliveryAttachment) => {
    if (!fileId) return;

    setLoadingFiles(true); // Set loading to true before the API call

    try {
      const imageUrl = await apiInstance.getFiles(`files/download/${fileId}`);
      setPreviewData((prevData) => [
        ...prevData,
        { fileId, fileUrl: imageUrl },
      ]);
    } catch (err) {
    } finally {
      setLoadingFiles(false); // Set loading to false after API call completion
    }
  };
  const handleUpdateItem = async (values) => {
    startCreateLoading();
    const uploadedFileIds = await handleFileUpload();
    const matchingAttachments = (values?.itemFiles ?? []).filter((attachment) =>
      (removedFiles ?? []).some(
        (removedFileId) => removedFileId?.fileId === attachment?.fileId
      )
    );
    const newAttachments = uploadedFileIds?.map((fileId) => ({
      fileId: fileId,
    }));

    const updateItemFiles = [...matchingAttachments, ...newAttachments];
    const updateCombinedData = {
      ...values,
      fileUploadIds: updateItemFiles.map((attachment) => attachment.fileId),
      status: 2,
    };

    await crud.update(`item`, id, updateCombinedData, (_err, res) => {
      if (res?.status === 200) {
        toast.success('Item Updated Successfully');
        navigate('/procurement/item-management');
        stopCreateLoading();
      } else {
        stopCreateLoading();
      }
    });
  };
  useEffect(() => {
    getAllItems();
  }, []);

  useEffect(() => {
    if (editData && editData?.itemFiles) {
      editData?.itemFiles?.forEach((image) => {
        handleDownloadFile(image?.fileId, image?.attachmentTypeId === 2);
        if (image?.attachmentTypeId === 2) setFile(image);
      });
    }
  }, [editData]);
  return (
    <DashboardLayout
      title='Edit Item'
      actionButtons={<BacktoListSimple />}
      titleReverse
    >
      <GlassCard className='p-4'>
        {formLoader ? (
          <Box
            display='flex'
            flexDirection='column'
            justifyContent='center'
            alignItems='center'
          >
            <CircularProgress />
            <Typography variant='body1' sx={{ mt: 2 }}>
              Loading...
            </Typography>
          </Box>
        ) : (
          <form onSubmit={handleSubmit(handleUpdateItem)}>
            <h2 className='text-md font-bold mb-5'>Item Details</h2>
            <Grid container spacing={3}>
              <Grid item md={2.4}>
                <FmTextField
                  name='itemShortCode'
                  control={control}
                  label='Item Code'
                  required
                />
              </Grid>
              <Grid item md={2.4}>
                <FmTextField
                  name='itemName'
                  control={control}
                  label='Item Name'
                  required
                />
              </Grid>

              <Grid item md={2.4}>
                <FmAutoComplete
                  name='itemGroupId'
                  control={control}
                  options={itemgroups}
                  label='Group Item Type'
                  required
                  displayField='itemGroupName'
                  optionFields={['itemGroupCode', 'itemGroupName']}
                  valueKey='itemGroupId'
                />
              </Grid>
              <Grid item md={2.4}>
                <FmAutoComplete
                  name='itemTypeId'
                  control={control}
                  options={itemtypes}
                  label='Item Type'
                  displayField='itemTypeName'
                  optionFields={['itemTypeCode', 'itemTypeName']}
                  valueKey='itemTypeId'
                  required
                />
              </Grid>
              <Grid item md={2.4}>
                <FmAutoComplete
                  name='itemId'
                  control={control}
                  options={item}
                  label='Parent Item'
                  displayField='itemName'
                  optionFields={['itemCode', 'itemName']}
                  valueKey='itemId'
                />
              </Grid>
              <Grid item md={2.4}>
                <FmAutoComplete
                  name='unitOfMeasurementId'
                  control={control}
                  options={unitOptions}
                  label='Unit Of Measurement'
                  displayField='unitOfMeasurementName'
                  optionFields={[
                    'unitOfMeasurementCode',
                    'unitOfMeasurementName',
                  ]}
                  valueKey='unitOfMeasurementId'
                  required
                />
              </Grid>
              <Grid item md={2.4}>
                <FmAutoComplete
                  name='costCenterId'
                  control={control}
                  options={costcenters}
                  label='Cost Center'
                  displayField='costCenterName'
                  optionFields={['costCenterCode', 'costCenterName']}
                  valueKey='costCenterId'
                  required
                />
              </Grid>
              <Grid item md={12}>
                <h2 className='text-md font-bold'>BarCode</h2>
              </Grid>
              <Grid item md={2.4}>
                <FmFileInput
                  name={`barcode`}
                  documentName={getValues(`documentName`)}
                  onChange={(e) => handleFileUpload(e)}
                  loading={loading}

                />
              </Grid>
            </Grid>
            <br />
            <h2 className='text-md font-bold mb-5'>Other Details</h2>
            <Grid spacing={2} container>
              <Grid item md={2.4}>
                <FmAutoComplete
                  name='brandId'
                  control={control}
                  options={brands}
                  label='Brand'
                  displayField='brandName'
                  optionFields={['brandCode', 'brandName']}
                  valueKey='brandId'
                />
              </Grid>
              <Grid item md={2.4}>
                <FmTextField name='make' control={control} label='Make' />
              </Grid>
              <Grid item md={2.4}>
                <FmSearchableSelect
                  name='modelId'
                  control={control}
                  apiUrl='categories'
                  valueField='categoryId'
                  labelField={['categoryCode', 'categoryName']}
                  showField={['categoryCode', 'categoryName']}
                  label='Model'
                />
              </Grid>
              <Grid item md={2.4}>
                <FmAutoComplete
                  name='valuationMethodId'
                  control={control}
                  options={valuationmethods}
                  label='Valuation Method'
                  displayField='valuationMethodName'
                  optionFields={['valuationMethodName']}
                  valueKey='valuationMethodId'
                />
              </Grid>
              <Grid item md={2.4}>
                <FmAutoComplete
                  name='taxCodeId'
                  control={control}
                  options={taxcodes}
                  label='Tax Code'
                  displayField='taxCodeName'
                  optionFields={['taxCodeCode', 'taxCodeName']}
                  valueKey='taxCodeId'
                />
              </Grid>
              <Grid item md={2.4}>
                <FmTextField
                  name='stockLevelThresholds'
                  control={control}
                  label='Stock Level Thresholds'
                />
              </Grid>
              <Grid item md={2.4}>
                <FmTextField
                  name='minimumOrderQuantity'
                  control={control}
                  label='Minimum Order Quantity'
                />
              </Grid>
              <Grid item md={2.4}>
                <FmAutoComplete
                  name='itemCondition'
                  control={control}
                  options={ItemConditionEnum}
                  label='Item Condition'
                  displayField='label'
                  optionFields={['label']}
                  valueKey='value'
                />
              </Grid>
              <Grid item md={2.4}>
                <FmAutoComplete
                  name='orderFrequency'
                  control={control}
                  options={OrderFrequency}
                  label='Order Frequency'
                  displayField='label'
                  optionFields={['label']}
                  valueKey='value'
                />
              </Grid>
              <Grid item md={2.4}>
                <FmAutoComplete
                  name='storageCondition'
                  control={control}
                  options={StorageCondition}
                  label='Storage Condition'
                  displayField='label'
                  optionFields={['label']}
                  valueKey='value'
                />
              </Grid>
              <Grid item md={2.4}>
                <FmTextField
                  name='expiryNotification'
                  control={control}
                  label='Expiry Notifications(Days)'
                />
              </Grid>
              <Grid item md={2.4}>
                <FmAutoComplete
                  name='itemFlag'
                  control={control}
                  options={ItemFlag}
                  label='Item Flag'
                  displayField='label'
                  optionFields={['label']}
                  valueKey='value'
                />
              </Grid>
              <Grid item md={2.4}>
                <FmTextField
                  name='technicalSpecification'
                  control={control}
                  label='Technical Specifications'
                />
              </Grid>
              <Grid item md={4.8}>
                <FmTextField
                  name='description'
                  control={control}
                  label='Description'
                  multiline
                  rows={3}
                  maxRows={5}
                />
              </Grid>
              <Grid item md={2.4}>
                <UploadSquare
                  files={files}
                  setFiles={setFiles}
                  onFilesSelected={setFiles}
                  previewdData={previewdData}
                  // disableUpload={!notEditable}
                  glassCard={false}
                  loadingFiles={loadingFiles}
                  setRemovedFiles={setRemovedFiles}
                />
              </Grid>
            </Grid>
            <br />

            <ActionButtons
              onSubmit={handleSubmit(handleUpdateItem)}
              onCancel={() => navigate('/procurement/item-management')}
              onReset={reset}
              submitLoading={createLoading}
              cancelLoading={false}
              submitText='Update'
            />
          </form>
        )}
      </GlassCard>
    </DashboardLayout>
  );
};

export default EditItems;
