import React, { useEffect, useRef, useState } from 'react';
import { useForm } from 'react-hook-form';
import useLoader from '../../../../../components/helpers/UseLoader.tsx';
import Crud_Service from '../../../../../apis/CrudService.jsx';
import { toast } from 'react-toastify';
import { Box, Button, Divider, Stack } from '@mui/material';
import TableActionButtons from '../../../../../components/_form/TableActionButtons.jsx';
import FmSearchableSelect from '../../../../../components/_mui/FmSearchableSelect.tsx';
import FmTextField from '../../../../../components/_mui/FmTextField.tsx';
import FmMRTDataTable from '../../../../../components/FmMRTDataTable.tsx';
import GlassCard from '../../../../../components/small/GlassCard.tsx';
import MRTExpandTableStyle from '../../../../../components/table/MRTExpandTableStyle.tsx';
import { Icon } from '@iconify/react';
import { useHotkeys } from 'react-hotkeys-hook';
import { useParams } from 'react-router-dom';
import { yupResolver } from '@hookform/resolvers/yup';
import { salesretentionitemsSchema } from '../../../../../utils/CommonvalidationSchemas.tsx';
import FmCurrencyTextField from '../../../../../components/_mui/FmCurrencyTextField.tsx';
import CurrencyView from '../../../../../components/_mui/FmCurrencyView.tsx';
const SalesRetentionItemListTable = (props) => {
  const {
    rows,
    setRows,
    tableRecordCounts,
    pagination,
    sorting,
    setPagination,
    setSorting,
    columnFilters,
    globalFilter,
    setColumnFilters,
    setGlobalFilter,
    isLoading,
    setSearchKeyword,
    searchKeyword,
    fetchData,
    setEditingRowId,
    setEditingRowData,
    editingRowId,
    editingRowData,
  } = props;

  const {
    handleSubmit,
    control,
    reset,
    setValue,
    getValues,
    formState: { isValid },
  } = useForm({
    resolver: yupResolver(salesretentionitemsSchema),
    mode: 'all',
  });
  const { startEditLoading, stopEditLoading } = useLoader();
  const id = useParams()?.id;
  const crud = new Crud_Service();
  const [rowState, setRowState] = useState({
    newRowId: null,
    isCreatingRow: false,
  });
  const firstColumnInputRef = useRef(null);
  const [stateError, setStateError] = useState(false);
  const [editingRowIndex, setEditingRowIndex] = useState(null);
  const [currentRowIndex, setCurrentRowIndex] = useState(0);
  const [newRowCreated, setNewRowCreated] = useState(false);
  const [loader, setLoader] = useState(false);
  const [formValues, setFormValues] = useState({});
  const [billingValues, setBillingValues] = useState([
    { name: 'Quantity', value: 1 },
    { name: 'Gross', value: '-' },
    { name: 'Discount', value: 0 },
    { name: 'VAT', value: 5 },
    { name: 'Total', value: 0 },
  ]);

  const handleAddNewRow = () => {
    const newId = 'new_row_' + Date.now();
    reset();
    setEditingRowData(null);
    setEditingRowId(null);
    setRowState({
      newRowId: newId,
      isCreatingRow: true,
    });

    setEditingRowIndex(
      editingRowIndex === rows.length ? editingRowIndex + 1 : rows.length
    );
    setNewRowCreated(false);
    setRows((prevRows) => [
      ...prevRows,
      {
        salesRetentionId: id,
        companyId: 0,
        itemId: 0,
        description: '',
        taxId: 0,
        unitOfMeasurementId: 0,
        quantity: 0,
        rate: 0,
        gross: 0,
        discount: 0,
        discountPercent: 0,
        vat: 0,
        amountWithTax: 0,
        remarks: '',
        salesRetentionItemId: newId,
        isEditable: true,
        isNew: true,
      },
    ]);
    reset();
  };

  const handleEdit = (row) => {
    reset();
    if (rowState?.newRowId) {
      setRows((prevRows) => {
        if (prevRows?.length > 0) {
          return prevRows?.slice(0, -1); // Removes the last row
        }
        return prevRows; // If no rows exist, return the current state
      });
      setRowState((prevState) => ({
        ...prevState,
        newRowId: null,
        isCreatingRow: false,
      }));
      // setEditingRowIndex(null);
    }
    setEditingRowId(row?.salesRetentionItemId);
    Object.entries({
      ...row,
    }).forEach(([key, value]) => {
      setValue(key, value);
    });
    setEditingRowData({ ...row });
  };
  const handleCancel = (row) => {
    if (row?.original?.salesRetentionItemId === rowState?.newRowId) {
      setRows((prevRows) => {
        if (prevRows?.length > 0) {
          return prevRows?.slice(0, -1); // Removes the last row
        }
        return prevRows; // If no rows exist, return the current state
      });
      setRowState((prevState) => ({
        ...prevState,
        newRowId: null,
        isCreatingRow: false,
      }));
    } else {
      setEditingRowId(null);
      setEditingRowIndex(null);
    }
    reset();
  };

  const handleDelete = async (props) => {
    await crud.remove(
      'salesretentionitems',
      props?.original?.salesRetentionItemId,
      (__err, res) => {
        if (res?.status === 204) {
          toast.success('Sales Retention Item Deleted Successfully');
          fetchData();
        } else {
        }
      }
    );
  };

  const ActionData = [
    {
      name: 'Delete',
      icon: <Icon icon='solar:trash-bin-minimalistic-broken' />,
      onClick: (props) => handleDelete(props),
    },
  ];
  const columns = [
    {
      field: 'action',
      header: 'Action',
      sortable: false,
      hideable: false,
      size: 100,
      muiTableBodyCellProps: {
        sx: {
          borderLeft: '1px solid',
          borderColor: 'border.main',
        },
      },
      disableClickEventBubbling: false,
      Cell: ({ row, table }) => {
        const isNewRow =
          row?.original?.salesRetentionItemId === rowState?.newRowId;
        const isEditing =
          editingRowId === row?.original?.salesRetentionItemId || isNewRow;
        const isLastRow = row.index === table.getRowModel().rows.length - 1;

        return (
          <Box className='flex gap-2'>
            {isEditing ? (
              <>
                <Button
                  className='py-0.5 px-2'
                  variant='contained'
                  color='error'
                  onClick={() => handleCancel(row)}
                >
                  Close
                </Button>
                {isLastRow && rowState?.isCreatingRow === false && (
                  <Button onFocus={() => handleCancelAndAddNewRow()}></Button>
                )}
              </>
            ) : (
              <Box className='flex gap-2'>
                <TableActionButtons
                  Actions={ActionData.map((action) => ({
                    ...action,
                    onClick: () => action.onClick(row?.original),
                  }))}
                />
                {isLastRow && (
                  <Button
                    className='py-0.5 px-2'
                    variant='contained'
                    color='primary'
                    onClick={() => {
                      setEditingRowIndex(row?.index + 1);
                      handleAddNewRow(row);
                    }}
                  >
                    Add
                  </Button>
                )}
              </Box>
            )}
          </Box>
        );
      },
    },
    {
      accessorKey: 'itemId',
      header: 'Item',
      enableEditing: true,
      Cell: ({ row }) => {
        const isNewRow =
          row?.original?.salesRetentionItemId === rowState?.newRowId;
        const isEditing =
          editingRowId === row?.original?.salesRetentionItemId || isNewRow;
        return isEditing ? (
          <FmSearchableSelect
            name='itemId'
            control={control}
            apiUrl='item'
            valueField='itemId'
            onBlur={handleBlur}
            headerField={['Item Name']}
            labelField={['itemName']}
            inputRef={firstColumnInputRef}
            showField={['itemName']}
            label='Item Name'
            defaultValue={{
              itemId: editingRowData?.itemId,
              itemName: editingRowData?.itemName,
            }}
          />
        ) : (
          row?.original?.itemName || ''
        );
      },
    },
    {
      accessorKey: 'companyId',
      header: 'Company Code',
      enableEditing: true,
      Cell: ({ cell }) => {
        const salesItemDetails: any = rows?.find(
          (item) =>
            item?.salesRetentionItemId ===
            cell?.row?.original?.salesRetentionItemId
        );
        return editingRowId === cell?.row?.original?.salesRetentionItemId ? (
          <FmSearchableSelect
            name='companyId'
            control={control}
            apiUrl='companies'
            valueField='companyId'
            headerField={['Company Code', 'Company Name']}
            labelField={['companyCode', 'companyName']}
            onBlur={handleBlur}
            showField={['companyName']}
            label='Company Code'
            defaultValue={{
              companyId: editingRowData?.companyId,
              companyName: editingRowData?.companyName,
            }}
          />
        ) : (
          salesItemDetails?.companyName || ''
        );
      },
    },

    {
      accessorKey: 'description',
      header: 'Description',
      enableEditing: true,
      Cell: ({ row }) => {
        const isNewRow =
          row?.original?.salesRetentionItemId === rowState?.newRowId;
        const isEditing =
          editingRowId === row?.original?.salesRetentionItemId || isNewRow;
        return isEditing ? (
          <FmTextField
            name='description'
            onBlur={handleBlur}
            control={control}
            label='Description'
          />
        ) : (
          row?.original?.description || ''
        );
      },
    },
    {
      accessorKey: 'taxCodeId',
      header: 'Tax Code',
      enableEditing: true,
      Cell: ({ row }) => {
        const isNewRow =
          row?.original?.salesRetentionItemId === rowState?.newRowId;
        const isEditing =
          editingRowId === row?.original?.salesRetentionItemId || isNewRow;
        return isEditing ? (
          <FmSearchableSelect
            name='taxCodeId'
            control={control}
            apiUrl='taxcodes'
            onBlur={handleBlur}
            valueField='taxCodeId'
            headerField={['Tax Code']}
            labelField={['taxCodeCode']}
            showField={['taxCodeCode']}
            label='Tax Code'
            defaultValue={{
              taxCodeId: editingRowData?.taxId,
              taxCodeCode: editingRowData?.taxCodeName,
            }}
          />
        ) : (
          row?.original?.taxCodeId || ''
        );
      },
    },
    {
      accessorKey: 'unitOfMeasurementId',
      header: 'Units',
      enableEditing: true,
      Cell: ({ row }) => {
        const isNewRow =
          row?.original?.salesRetentionItemId === rowState?.newRowId;
        const isEditing =
          editingRowId === row?.original?.salesRetentionItemId || isNewRow;
        return isEditing ? (
          <FmSearchableSelect
            name='unitOfMeasurementId'
            control={control}
            apiUrl='unitofmeasurements'
            onBlur={handleBlur}
            valueField='unitOfMeasurementId'
            headerField={['Unit']}
            labelField={['unitOfMeasurementName']}
            showField={['unitOfMeasurementName']}
            label='Units'
            defaultValue={{
              unitOfMeasurementId: editingRowData?.unitOfMeasurementId,
              unitOfMeasurementName: editingRowData?.unitOfMeasurementName,
            }}
          />
        ) : (
          row?.original?.unitOfMeasurementName || ''
        );
      },
    },
    {
      accessorKey: 'quantity',
      header: 'Quantity',
      enableEditing: true,
      size: 100,
      Cell: ({ row }) => {
        const isNewRow =
          row?.original?.salesRetentionItemId === rowState?.newRowId;
        const isEditing =
          editingRowId === row?.original?.salesRetentionItemId || isNewRow;
        return isEditing ? (
          <FmTextField
            name='quantity'
            control={control}
            label='Quantity'
            onBlur={handleBlur}
          />
        ) : (
          row?.original?.quantity || ''
        );
      },
    },
    {
      accessorKey: 'rate',
      header: 'Rate',
      size: 100,
      enableEditing: true,
      Cell: ({ row }) => {
        const isNewRow =
          row?.original?.salesRetentionItemId === rowState?.newRowId;
        const isEditing =
          editingRowId === row?.original?.salesRetentionItemId || isNewRow;
        return isEditing ? (
          <FmCurrencyTextField
            name='rate'
            control={control}
            label='Rate'
            onBlur={handleBlur}
          />
        ) : (
          <CurrencyView
            value={row?.original?.rate}
            currencyType={row?.original?.currencyName}
          />
        );
      },
    },
    {
      accessorKey: 'gross',
      header: 'Gross',
      size: 100,
      enableEditing: true,
      Cell: ({ row }) => {
        const isNewRow =
          row?.original?.salesRetentionItemId === rowState?.newRowId;
        const isEditing =
          editingRowId === row?.original?.salesRetentionItemId || isNewRow;
        return isEditing ? (
          <FmCurrencyTextField
            name='gross'
            control={control}
            label='Gross'
            onBlur={handleBlur}
          />
        ) : (
          <CurrencyView
            value={row?.original?.gross}
            currencyType={row?.original?.currencyName}
          />
        );
      },
    },
    {
      accessorKey: 'discount',
      header: 'Discount',
      size: 100,
      enableEditing: true,
      Cell: ({ row }) => {
        const isNewRow =
          row?.original?.salesRetentionItemId === rowState?.newRowId;
        const isEditing =
          editingRowId === row?.original?.salesRetentionItemId || isNewRow;
        return isEditing ? (
          <FmCurrencyTextField
            name='discount'
            control={control}
            label='Discount'
            onBlur={handleBlur}
          />
        ) : (
          <CurrencyView
            value={row?.original?.discount}
            currencyType={row?.original?.currencyName}
          />
        );
      },
    },
    {
      accessorKey: 'discountPercent',
      header: 'Discount %',
      size: 120,
      enableEditing: true,
      Cell: ({ row }) => {
        const isNewRow =
          row?.original?.salesRetentionItemId === rowState?.newRowId;
        const isEditing =
          editingRowId === row?.original?.salesRetentionItemId || isNewRow;
        return isEditing ? (
          <FmTextField
            name='discountPercent'
            control={control}
            label='Discount %'
            onBlur={handleBlur}
          />
        ) : (
          row?.original?.discountPercent || ''
        );
      },
    },
    {
      accessorKey: 'vat',
      header: 'VAT',
      size: 100,
      enableEditing: true,
      Cell: ({ row }) => {
        const isNewRow =
          row?.original?.salesRetentionItemId === rowState?.newRowId;
        const isEditing =
          editingRowId === row?.original?.salesRetentionItemId || isNewRow;
        return isEditing ? (
          <FmTextField
            name='vat'
            control={control}
            label='Vat'
            onBlur={handleBlur}
          />
        ) : (
          row?.original?.vat || ''
        );
      },
    },
    {
      accessorKey: 'taxable',
      header: 'Taxable',
      size: 100,
      enableEditing: true,
      Cell: ({ row }) => {
        const isNewRow =
          row?.original?.salesRetentionItemId === rowState?.newRowId;
        const isEditing =
          editingRowId === row?.original?.salesRetentionItemId || isNewRow;
        return isEditing ? (
          <FmTextField
            name='taxable'
            control={control}
            label='Taxable'
            onBlur={handleBlur}
          />
        ) : (
          row?.original?.taxable || ''
        );
      },
    },
    {
      accessorKey: 'amountWithTax',
      header: 'Amount',
      size: 100,
      enableEditing: true,
      Cell: ({ row }) => {
        const isNewRow =
          row?.original?.salesRetentionItemId === rowState?.newRowId;
        const isEditing =
          editingRowId === row?.original?.salesRetentionItemId || isNewRow;
        return isEditing ? (
          <FmTextField
            name='amountWithTax'
            control={control}
            label='Amount With Tax'
            onBlur={handleBlur}
          />
        ) : (
          row?.original?.amountWithTax || ''
        );
      },
    },

    {
      accessorKey: 'remarks',
      header: 'Remarks',
      enableEditing: true,
      Cell: ({ row }) => {
        const isNewRow =
          row?.original?.salesRetentionItemId === rowState?.newRowId;
        const isEditing =
          editingRowId === row?.original?.salesRetentionItemId || isNewRow;
        return isEditing ? (
          <FmTextField
            name='remarks'
            control={control}
            label='Remarks'
            multiline={true}
            onBlur={handleBlurAndFetch}
            maxRows={3}
            rows={2}
          />
        ) : (
          row?.original?.remarks || ''
        );
      },
    },
  ];

  useEffect(() => {
    if (editingRowData) {
      Object.entries({
        ...editingRowData,
      }).forEach(([key, value]) => {
        setValue(key, value);
      });
    }
  }, [editingRowData, setValue]);

  useHotkeys(
    'Enter',
    () => handleAddNewRow(),
    {
      enableOnTags: ['INPUT', 'TEXTAREA'], // Optional: Enable hotkey only when focused on specific tags
    },
    [editingRowIndex, rows, stateError, loader]
  );

  useHotkeys(
    'Tab',
    (event) => {
      if (event.key !== 'Tab') return;
      if (stateError) {
        event.preventDefault();
        return;
      }
      const totalRows = rows.length;
      const nextRowIndex = editingRowIndex + 1;

      if (
        nextRowIndex < totalRows &&
        stateError === false &&
        loader === false
      ) {
        setCurrentRowIndex(nextRowIndex);
        handleEdit(rows[nextRowIndex]);
        setEditingRowIndex(nextRowIndex);
      }
    },
    [editingRowIndex, rows, stateError, loader, isValid]
  );

  useEffect(() => {
    if (editingRowIndex !== null && firstColumnInputRef.current && !isLoading) {
      firstColumnInputRef.current.focus();
    }
  }, [editingRowIndex, isLoading]);

  const previousValues = useRef(getValues());

  const handleBlur = () => {
    const currentValues = getValues();
    const value = getValues('itemId');
    if (!value) {
      // Refocus the field if empty
      firstColumnInputRef?.current.focus();
      return;
    }
    // Compare the previous values with the current values
    const hasChanges = Object.keys(currentValues).some(
      (key) => currentValues[key] !== previousValues.current[key]
    );

    if ((hasChanges && isValid) || (hasChanges && editingRowId)) {
      apiCallAsync(currentValues); // Call the API with the new values
      previousValues.current = currentValues; // Update previous values
    }
  };

  const apiCallAsync = async (values) => {
    setLoader(true);
    const convertedValues = {
      ...values,
      salesRetentionId: id,
    };

    const updatedCombinedData = {
      ...values,
      salesRetentionId: Number(id),
      status: 2,
    };

    if (editingRowId === null) {
      await crud.create('salesretentionitems', convertedValues, (err, res) => {
        if (res?.status === 201) {
          setEditingRowId(res?.data?.salesRetentionItemId);
          setValue('salesRetentionItemId', res?.data?.salesRetentionItemId);
        } else {
        }
      });
    } else {
      await crud.update(
        'salesretentionitems',
        editingRowId,
        updatedCombinedData,
        (err, res) => {
          if (res?.status === 200) {
            if (rows.some((row) => row.isNew)) {
              const updatedRow = res?.data;
              const updatedRows = [...rows];
              updatedRows[editingRowIndex] = {
                ...updatedRow,
                isEditable: true,
                isNew: true,
              };
              setRows(updatedRows);
              setStateError(false);
              setLoader(false);
            } else {
              setStateError(false);
              setLoader(false);
              const updatedRow = res?.data;
              const updatedRows = [...rows];

              updatedRows[editingRowIndex] = updatedRow;

              setRows(updatedRows);
            }
          } else {
            setStateError(true);
          }
        }
      );
    }
  };

  const handleBlurAndFetch = async () => {
    setLoader(true);
    const updatedRow = getValues(); // Get the latest values from the form
    const updatedRows = [...rows]; // Clone the rows[0] to avoid direct mutation

    // Update the specific row in the cloned array
    updatedRows[editingRowIndex] = updatedRow;

    setRows(updatedRows);

    const values = getValues();

    const updateCombinedData = {
      ...values, // Spread the rest of the values
      salesRetentionId: Number(id),
      status: 2,
    };
    await crud.update(
      'materialrequisitionitems',
      editingRowId,
      updateCombinedData,
      (err, res) => {
        if (res?.status === 200) {
          const totalRows = rows.length;
          if (editingRowIndex <= totalRows && rows.some((row) => row.isNew)) {
            setNewRowCreated(true);
            const updatedRow = res?.data;
            const updatedRows = [...rows];
            updatedRows[editingRowIndex] = {
              ...updatedRow,
              isEditable: true,
              isNew: true,
            };
            setRows(updatedRows);
            handleAddNewRow();
          } else {
            const updatedRow = res?.data;
            const updatedRows = [...rows];
            updatedRows[editingRowIndex] = {
              ...updatedRow,
              isEditable: false,
              isNew: false,
            };

            setRows(updatedRows);
            setStateError(false);
            setLoader(false);
          }
        } else {
          setStateError(true);
        }
      }
    );
  };

  const handleCancelAndAddNewRow = () => {
    const newId = 'new_row_' + Date.now();
    reset();
    setEditingRowData(null);
    setEditingRowId(null);
    setRowState({
      newRowId: newId,
      isCreatingRow: true,
    });
    setEditingRowIndex(
      editingRowIndex === rows.length ? editingRowIndex + 1 : rows.length
    );
    setNewRowCreated(false);
    setRows((prevRows) => [
      ...prevRows,
      {
        salesRetentionItemId: newId,
        materialRequisitionId: id,
        salesOrderId: '',
        brandId: '',
        categoryId: '',
        unitOfMeasurementId: '',
        itemId: '',
        unitOfMeasurementId: '',
        costCenterId: '',
        quantity: '',
        remarks: '',
        description: '',
        isEditable: true,
        isNew: true,
      },
    ]);
    reset();
  };

  useEffect(() => {
    if (rows?.length > 0) {
      const filteredRows = editingRowId
        ? rows.filter((row) => row.salesOrderItemId !== editingRowId) // Exclude the editing row
        : rows; // If no editingRowId, use all rows

      const gross = filteredRows.reduce(
        (total, row) => total + parseFloat(row.gross || 0),
        0
      );
      const discount = filteredRows.reduce(
        (total, row) => total + parseFloat(row.discount || 0),
        0
      );

      const net = filteredRows.reduce(
        (total, row) => total + parseFloat(row.net || 0),
        0
      );
      const vat = filteredRows.reduce(
        (total, row) => total + parseFloat(row.vat || 0),
        0
      );
      const total = filteredRows.reduce(
        (total, row) =>
          total + parseFloat((row.rate || 0) * (row.quantity || 0)),
        0
      );

      // Update billing values
      setBillingValues((prev) =>
        prev.map((item) => {
          switch (item.name) {
            case 'Quantity':
              return { ...item, value: rows.length }; // Add form value if available
            case 'Gross':
              return {
                ...item,
                value:
                  gross +
                  parseFloat(
                    formValues?.gross == 0
                      ? 0
                      : formValues?.gross || editingRowData?.gross || 0
                  ),
              }; // Add form value if available
            case 'Discount':
              return {
                ...item,
                value:
                  discount +
                  parseFloat(
                    formValues?.discount == 0
                      ? 0
                      : formValues?.discount || editingRowData?.discount || 0
                  ),
              }; // Add form value if available
            case 'Net':
              return {
                ...item,
                value:
                  net +
                  parseFloat(
                    formValues?.net == 0
                      ? 0
                      : formValues?.net || editingRowData?.net || 0
                  ),
              }; // Add form value if available
            case 'VAT':
              return {
                ...item,
                value:
                  vat +
                  parseFloat(
                    formValues?.vat == 0
                      ? 0
                      : formValues?.vat || editingRowData?.vat || 0
                  ),
              }; // Add form value if available
            case 'Total':
              return {
                ...item,
                value:
                  total +
                  (formValues?.quantity == '' || formValues?.rate == ''
                    ? 0
                    : parseFloat(
                        (formValues?.rate || editingRowData?.rate || 0) *
                          (formValues?.quantity ||
                            editingRowData?.quantity ||
                            0)
                      )),
              };
            default:
              return item;
          }
        })
      );
    }
  }, [
    formValues?.discount,
    formValues?.quantity,
    formValues?.gross,
    formValues?.vat,
    formValues?.net,
    formValues?.rate,
    editingRowId,
    rows,
    editingRowData?.salesOrderItemId,
  ]);

  return (
    <>
      <Box className='flex flex-1 min-h-0 flex-col'>
        <GlassCard className='flex-1 min-h-0'>
          <FmMRTDataTable
            columns={columns}
            enableRowSelection={false}
            rows={rows}
            showPagination={false}
            rowCount={tableRecordCounts}
            editingRow={editingRowId}
            pagination={pagination}
            sorting={sorting}
            fetchData={fetchData}
            columnFilters={columnFilters}
            globalFilter={globalFilter}
            setPagination={setPagination}
            setSorting={setSorting}
            setColumnFilters={setColumnFilters}
            setGlobalFilter={setGlobalFilter}
            isLoading={isLoading}
            setSearchKeyword={setSearchKeyword}
            searchKeyword={searchKeyword}
            handleRowDoubleClick={(row) => {
              handleEdit(row?.original);
              setEditingRowIndex(row?.index);
            }}
            showSerialNumber={true}
            muiTableContainerProps={{
              sx: {
                maxHeight: '100%',
                flex: 1,
              },
            }}
            enableBottomToolbar={false}
          />
        </GlassCard>

        <GlassCard className='p-2 bg-zinc-700 uppercase font-semibold text-white'>
          <Stack
            direction='row'
            flexWrap='wrap'
            spacing={3}
            justifyContent='space-between'
          >
            {billingValues?.map((item, i) => {
              return (
                <Box key={i} className='flex justify-between items-center'>
                  <Box>{item.name}</Box>
                  <Box className='font-bold ms-3'>{item.value}</Box>
                </Box>
              );
            })}
          </Stack>
        </GlassCard>
      </Box>
    </>
  );
};

export default SalesRetentionItemListTable;
