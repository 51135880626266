import React from 'react';
import { MuiTelInputInfo } from 'mui-tel-input';
import { Controller, Control } from 'react-hook-form';
import { PhoneInput } from 'react-international-phone';
import 'react-international-phone/style.css';

interface FmTelInputProps {
  name: string;
  control: Control<any>;
  handleChange?: (value: string, info: MuiTelInputInfo) => void;
  anyCountry?: boolean;
  defaultValue?: string;
  defaultCountry?: string;
}

const FmTelInput: React.FC<FmTelInputProps> = ({
  name,
  control,
  handleChange,
  anyCountry,
  defaultValue = '',
  defaultCountry = 'ae',
}) => {
  return (
    <Controller
      name={name}
      control={control}
      defaultValue={defaultValue}
      render={({ field: { onChange, value }, fieldState: { error } }) => (
        <PhoneInput
          defaultCountry={anyCountry ? undefined : defaultCountry}
          value={value || ''}
          onChange={(value, info) => {
            onChange(value, info);
            if (handleChange) {
              handleChange(value, info);
            }
          }}
          className={'w-full'}
          inputClassName={'flex-1'}
          countrySelectorStyleProps={{
            style: {
              zIndex: 1000,
            },
          }}
        />
      )}
    />
  );
};

export default FmTelInput;
