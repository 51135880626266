import { Box, Tooltip } from '@mui/material';
import React, { memo } from 'react';
import ViewTableLightText from '../view/ViewTableLightText.tsx';

const TableYesWithCaptions = ({ name, type, tooltipName, showName }) => {
  return (
    <Tooltip arrow title={tooltipName}>
      <Box className='flex flex-col gap-0.5' sx={{ minWidth: '40px' }}>
        <ViewTableLightText name={name} />
        <Box
          className={`font-semibold ${
            type ? 'text-green-600' : 'text-red-500'
          } `}
        >
          {showName ? type : type ? 'Yes' : 'No'}
        </Box>
      </Box>
    </Tooltip>
  );
};

export default memo(TableYesWithCaptions);
