import React, { useState } from 'react';
import FmTextField from '../../../../../components/_mui/FmTextField.tsx';
import { Grid } from '@mui/material';
import GlassCard from '../../../../../components/small/GlassCard.tsx';
import { useForm } from 'react-hook-form';
import Crud_Service from '../../../../../apis/CrudService.jsx';
import { toast } from 'react-toastify';
import FmSearchableSelect from '../../../../../components/_mui/FmSearchableSelect.tsx';
import { ActionButtons } from '../../../../../components/_form';
import { useParams } from 'react-router-dom';
import { yupResolver } from '@hookform/resolvers/yup';
import { SalesRetentionItemvalidationSchema } from '../../../../../utils/CommonvalidationSchemas.tsx';
import EditSalesRetention from '../EditSalesRetention.tsx';
import GlassCardCollapse from './../../../../../components/small/GlassCardCollapse.tsx';

const GridSize = 2.4;

const AddViewSalesRetention = (props) => {
  const { fetchData, setEditingRowId, setEditingRowData } = props;
  const { id } = useParams();
  const [loading, setLoading] = useState(false);
  const { control, handleSubmit, reset } = useForm({
    resolver: yupResolver(SalesRetentionItemvalidationSchema),
  });
  const crud = new Crud_Service();

  const handleSubmitSalesRetention = async (values) => {
    setLoading(true);
    const combinedData = {
      ...values,
      salesRetentionId: id,
    };

    await crud.create('salesretentionitems', combinedData, (err, res) => {
      setLoading(false);

      if (res?.status === 201) {
        toast.success('Sales Retention Item Created Successfully');
        setLoading(false);
        fetchData();
        reset();
        setEditingRowId(null);
        setEditingRowData(null);
      } else {
        setLoading(false);
      }
    });
  };

  return (
    <>
      <GlassCardCollapse title='Edit Sales Retention Item' collapsed>
        <EditSalesRetention fetchData={fetchData} id={id} />
      </GlassCardCollapse>
    </>
  );
};

export default AddViewSalesRetention;
