import {
  CompanyName,
  BranchName,
  BankName,
} from '../../../../components/table/MRTCommonColumns.tsx';
import { Box } from '@mui/material';
import CurrencyView from '../../../../components/_mui/FmCurrencyView.tsx';
import StatusNewBadge from './../../../../components/_form/StatusNewBadge.tsx';
import { TextCalendarCellIcons } from '../../../../components/layout/RenderMRTCellIcons.tsx';

const ColumnPettyCash = [
  {
    accessorKey: 'pettyCashTransactionCode',
    header: 'Code',
    size: 150,
  },
  {
    accessorKey: 'pettyCashStatusName',
    header: 'Status',
    enableColumnPinning: true,
    Cell: ({ cell }) => {
      return (
        <StatusNewBadge
          title={cell?.row?.original?.pettyCashStatusName}
          type={cell?.row?.original?.pettyCashStatusName?.toLowerCase()}
        />
      );
    },
  },
  BankName,
  CompanyName,
  {
    accessorKey: 'requestDate',
    header: 'Request Date',
    enableEditing: true,
    size: 130,
    Cell: ({ row }) => {
      return <TextCalendarCellIcons value={row?.original?.requestDate} />;
    },
  },
  BranchName,
  {
    accessorKey: 'jurisdictionName',
    header: 'Jurisdiction',
    enableEditing: true,
    size: 150,
  },
  {
    accessorKey: 'narration',
    header: 'Narration',
    enableEditing: true,
    size: 150,
  },
  {
    accessorKey: 'pettyCashGrossAmount',
    header: 'Gross',
    size: 150,
    Cell: ({ cell }) => {
      return (
        <Box>
          <CurrencyView value={cell.getValue()} />
        </Box>
      );
    },
  },
  {
    accessorKey: 'pettyCashVATAmount',
    header: 'VAT Amount',
    size: 150,
    Cell: ({ cell }) => {
      return (
        <Box>
          <CurrencyView value={cell.getValue()} />
        </Box>
      );
    },
  },
  {
    accessorKey: 'pettyCashTotalAmount',
    header: 'Total Amount',
    Cell: ({ cell }) => {
      return (
        <Box>
          <CurrencyView value={cell.getValue()} />
        </Box>
      );
    },
  },
];

export default ColumnPettyCash;
