import React, { useEffect, useState, useMemo } from 'react';
import { Box, CircularProgress, Grid } from '@mui/material';
import { Icon } from '@iconify/react';
import { NavLink } from 'react-router-dom';
import WidgetOne from '../../../components/widgets/WidgetOne';
import Crud_Service from '../../../apis/CrudService';
import { toast } from 'react-toastify';
import { useSelector } from 'react-redux';
import { findNavByName } from '../../../utils/navUtils.ts';
import SkeletonLoader from './../../../components/page/SkeletonLoader.tsx';

const HRMSEmployeeDashboard = () => {
  const [counts, setCounts] = useState({});
  const crud = new Crud_Service();
  const [loader, setLoader] = useState(true);
  const menu = useSelector((state) => state?.menu?.items);

  useEffect(() => {
    crud.getSingle('employees/employee_counts', '', (err, res) => {
      if (err) {
        toast.error(err);
        return;
      }
      setCounts(res?.data);
      setLoader(false);
    });
  }, []);

  const apiKeyToNavName = {
    employeeDailyAttendances: 'Attendance',
    employeeLeaveRequest: 'Leave Request',
    employeeTransactions: 'Training Transaction',
    employeeLeaveJoins: 'Leave Join',
    employeePassportRequests: 'Passport Request',
    employementCancellations: 'Employee Exit / Cancellation',
    employeeCertifications: 'Certificates',
    candidate: 'Candidates',
    employees: 'Employees',
    finalSettlements: 'Final Settlements',
    employeeAttendanceCalculations: 'Employee Attendance Calculations',
  };

  // Function to get count for a given widget name
  const getCountForNavItem = (name) => {
    const entry = Object.entries(apiKeyToNavName).find(
      ([, navName]) => navName.toLowerCase() === name.toLowerCase()
    );
    return entry && counts ? counts[entry[0]] : 0;
  };

  const SubMenu = useMemo(() => {
    return menu?.map((item) => item?.menus?.flat()).flat() || [];
  }, [menu]);

  const resultNav = useMemo(
    () => findNavByName(SubMenu, 'HRMS', 'Employee'),
    [SubMenu]
  );

  return (
    <>
      {loader ? (
        <SkeletonLoader count={35} height={45} />
      ) : (
        <Grid container spacing={1} className='mb-5'>
          <Grid item md={12}>
            <Box className='flex gap-2 flex-wrap'>
              {resultNav?.map((item, i) => {
                return (
                  <Box
                    key={i}
                    sx={{
                      width: '191px',
                      '&:hover': {
                        bgcolor: 'background.light',
                      },
                    }}
                    component={NavLink}
                    to={item.url}
                  >
                    <WidgetOne
                      title={item.menuName}
                      icon={<Icon icon='material-symbols:event-note-outline' />}
                      path={item?.url}
                      count={getCountForNavItem(item?.menuName) || 0}
                      record='25.25%'
                    />
                  </Box>
                );
              })}
            </Box>
          </Grid>
        </Grid>
      )}
    </>
  );
};

export default HRMSEmployeeDashboard;
