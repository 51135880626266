import { yupResolver } from '@hookform/resolvers/yup';
import { Icon } from '@iconify/react';
import { Box, Button, Grid, MenuItem, TextField } from '@mui/material';
import { AdapterDayjs } from '@mui/x-date-pickers/AdapterDayjs';
import { DatePicker } from '@mui/x-date-pickers/DatePicker';
import { LocalizationProvider } from '@mui/x-date-pickers/LocalizationProvider';
import dayjs from 'dayjs';
import React, { useEffect, useState } from 'react';
import { Controller, useForm } from 'react-hook-form';
import { useNavigate, useParams } from 'react-router-dom';
import { toast } from 'react-toastify';
import Crud_Service from '../../../apis/CrudService';
import { ActionButtons } from '../../../components/_form';
import UploadSquareBoxes from '../../../components/_form/UploadSquareBoxes.tsx';
import FmAutoComplete from '../../../components/_mui/FmAutoComplete.tsx';
import FmTextField from '../../../components/_mui/FmTextField.tsx';
import DashboardLayout from '../../../components/DashboardLayout.tsx';
import useCommonFetchApi from '../../../components/helpers/useCommonFetchApi.tsx';
import useLoader from '../../../components/helpers/UseLoader.tsx';
import CommonLoader from '../../../components/page/CommonLoader';
import GlassCard from '../../../components/small/GlassCard.tsx';
import { TenderInspectionvalidationSchema } from '../../../utils/CommonvalidationSchemas.tsx';

const GridValue = 3;

const EditTenderInspectionChecklist = () => {
  const navigate = useNavigate();
  const crud = new Crud_Service();
  const { id } = useParams();
  const [loading, setLoading] = useState(false);
  const {
    handleSubmit,
    control,
    setValue,
    getValues,
    setError,
    clearErrors,
    reset,
    formState: { errors },
  } = useForm({
    resolver: yupResolver(TenderInspectionvalidationSchema),
    mode: 'onChange',
  });
  const [counter, setCounter] = useState(1);
  const { startEditLoading, stopEditLoading, editLoading } = useLoader();
  const [components, setComponents] = useState([]);
  const [imageUrl, setImageUrl] = useState([]);
  const [imageLoading, setImageLoading] = useState(
    components.reduce((acc, component) => {
      acc[component.id] = false; // Initialize loading state for each component
      return acc;
    }, {})
  );

  useEffect(() => {
    getTenderChecklist();
  }, []);

  //get ALl Api
  const { data: clients } = useCommonFetchApi('clients');
  const { data: locations } = useCommonFetchApi('locations');
  const { data: regions } = useCommonFetchApi('regions');
  const { data: buildings } = useCommonFetchApi('buildings');
  const { data: tenders } = useCommonFetchApi('tenders');

  const getTenderChecklist = async () => {
    setLoading(true);
    await crud.getSingle('tenderinspectionchecklists', id, (err, res) => {
      if (res?.status === 200) {
        Object.entries({
          ...res?.data,
        }).forEach(([key, value]) => {
          setValue(key, value);
        });
        setLoading(false);
      } else {
      }
    });
  };

  //Update tender Inspection Checklists
  const handleEditTenderChecklist = (values) => {
    startEditLoading();
    const updateAttachments = components.map((component) => ({
      tenderInspectionCheckListAttachmentId: component?.id || id,
      fileId: component?.fileId || id,
      status: 2,
    }));

    const combinedData = {
      ...values,
      visitedDate: dayjs(values?.visitedDate).format('YYYY-MM-DD'),
      noOfFloor: Number(values?.noOfFloor),
      flatPerFloor: Number(values?.flatPerFloor),
      updateAttachments,
    };

    crud.update(`tenderinspectionchecklists`, id, combinedData, (err, res) => {
      if (err) {
        toast.error(err.message || 'Something went wrong');
      } else if (res?.status === 200) {
        toast.success('Tender Checklist Updated Successfully');
        navigate(-1);
      } else {
      }
      stopEditLoading();
    });
  };

  const handleAddComponent = () => {
    const currentFileIds = getValues('fileUploadIds') || [];
    if (currentFileIds.length >= 3) {
      // Set an error if the file count is already at the limit
      setError('fileUploadIds', {
        type: 'manual',
        message: 'You can upload a maximum of 3 files.',
      });
      return; // Prevent adding a new component if validation fails
    } else {
      // Clear any existing errors if the file count is within the limit
      clearErrors('fileUploadIds');
    }

    // Add the new component and increment the counter
    setComponents([...components, { id: counter }]);
    setCounter(counter + 1);
  };

  const handleRemoveComponent = (id) => {
    setComponents(components.filter((component) => component.id !== id));
    setImageUrl((prev) => {
      const updatedUrls = { ...prev };
      delete updatedUrls[id];
      return updatedUrls;
    });
    clearErrors('fileUploadIds');
  };
  const handleFileChange = (id, fileId) => {
    if (typeof fileId === 'number') {
      const currentFileIds = getValues('fileUploadIds') || [];
      const updatedFileIds = [...currentFileIds, fileId];
      setValue('fileUploadIds', updatedFileIds);
    }
  };

  return (
    <DashboardLayout title='Edit Tender Inspection Checklist'>
      <GlassCard className='p-3'>
        {loading ? (
          <CommonLoader />
        ) : (
          <form onSubmit={handleSubmit(handleEditTenderChecklist)}>
            <LocalizationProvider dateAdapter={AdapterDayjs}>
              <Grid container spacing={2}>
                <Grid item md={GridValue}>
                  <FmAutoComplete
                    name='tenderId'
                    control={control}
                    options={tenders}
                    label='Tenders'
                    displayField='tenderName'
                    optionFields={['tenderName']}
                    valueKey='tenderId'
                    required
                  />
                </Grid>
                <Grid item md={GridValue}>
                  <FmTextField
                    name='propertyName'
                    label='Property Name'
                    control={control}
                    required
                  />
                </Grid>
                <Grid item md={GridValue}>
                  <Controller
                    name='visitedDate'
                    control={control}
                    defaultValue={null}
                    render={({ field: { onChange, value } }) => (
                      <DatePicker
                        label={<>Site Visited Date</>}
                        value={value ? dayjs(value) : null}
                        format='DD/MM/YYYY'
                        onChange={(newValue) => {
                          onChange(newValue);
                        }}
                        sx={{ width: '100%' }}
                      />
                    )}
                  />
                </Grid>
                <Grid item md={GridValue}>
                  <FmAutoComplete
                    name='clientId'
                    control={control}
                    options={clients}
                    label='Clients'
                    displayField='clientName'
                    optionFields={['clientName']}
                    valueKey='clientId'
                    required
                  />
                </Grid>
                <Grid item md={GridValue}>
                  <FmTextField
                    name='existServiceProvider'
                    label='Exist Service Provider'
                    control={control}
                    required
                  />
                </Grid>
                <Grid item md={GridValue}>
                  <FmAutoComplete
                    name='locationId'
                    control={control}
                    options={locations}
                    label='Locations'
                    displayField='locationName'
                    optionFields={['locationName']}
                    valueKey='locationId'
                    required
                  />
                </Grid>
                <Grid item md={GridValue}>
                  <FmAutoComplete
                    name='emiratedId'
                    control={control}
                    options={regions}
                    label='Emirates'
                    displayField='regionName'
                    optionFields={['regionName']}
                    valueKey='regionId'
                    required
                  />
                </Grid>
                <Grid item md={GridValue}>
                  <FmAutoComplete
                    name='buildingId'
                    control={control}
                    options={buildings}
                    label='Buildings'
                    displayField='buildingName'
                    optionFields={['buildingName']}
                    valueKey='buildingId'
                    required
                  />
                </Grid>
                <Grid item md={GridValue}>
                  <FmTextField
                    name='flatPerFloor'
                    label='Flats'
                    control={control}
                    pattern='Number'
                  />
                </Grid>
                <Grid item md={GridValue}>
                  <FmTextField
                    label='No.of Floors'
                    name='noOfFloor'
                    control={control}
                    pattern='Number'
                  />
                </Grid>

                <Grid item md={GridValue}>
                  <Controller
                    name='status'
                    control={control}
                    render={({ field }) => (
                      <TextField
                        {...field}
                        label='Status'
                        fullWidth
                        select
                        variant='filled'
                      >
                        <MenuItem value={2}>Active</MenuItem>
                        <MenuItem value={1}>Inactive</MenuItem>
                      </TextField>
                    )}
                  />
                </Grid>
                <Grid item md={GridValue * 2}>
                  <FmTextField
                    name='remarks'
                    label='Remarks'
                    control={control}
                  />
                </Grid>
                <Grid item md={GridValue * 3}>
                  <Box>
                    <h3 className='mb-3 font-semibold'>
                      Additional Images Upload
                    </h3>
                    <Box className='flex gap-5 flex-wrap'>
                      <Button
                        onClick={handleAddComponent}
                        sx={{
                          minWidth: '100px',
                          height: '100px',
                          border: '1px solid',
                          borderColor: 'border.main',
                          flexDirection: 'column',
                          padding: 0,
                          bgcolor: 'background.white',
                        }}
                      >
                        <span className='text-teal-600 text-3xl mb-3'>
                          <Icon icon='simple-line-icons:plus' />
                        </span>
                        <span>Add Upload</span>
                      </Button>

                      {components.map((component) => (
                        <UploadSquareBoxes
                          key={component.id}
                          imageUrl={component.path}
                          setImageUrl={setImageUrl} // If you need this, otherwise omit
                          handleRemove={handleRemoveComponent}
                          setComponents={setComponents}
                          id={component.id}
                          setLoading={setImageLoading}
                          isLoading={imageLoading[component.id]}
                          onChange={(fileId) =>
                            handleFileChange(component.id, fileId)
                          }
                          fileTypeId={38}
                        />
                      ))}
                    </Box>
                    {errors.fileUploadIds && (
                      <span style={{ color: 'red' }}>
                        {errors.fileUploadIds.message}
                      </span>
                    )}
                  </Box>
                </Grid>
              </Grid>
            </LocalizationProvider>
            <ActionButtons
              onSubmit={handleSubmit(handleEditTenderChecklist)}
              onCancel={() => navigate(-1)}
              onReset={reset}
              submitLoading={editLoading}
              cancelLoading={false}
              submitText='Update'
            />
          </form>
        )}
      </GlassCard>
    </DashboardLayout>
  );
};

export default EditTenderInspectionChecklist;
