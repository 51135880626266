import React, { useEffect, useState, useRef } from 'react';
import InfiniteScroll from 'react-infinite-scroll-component';
import {
  Box,
  Tooltip,
  IconButton,
  Badge,
  CircularProgress,
  Divider,
} from '@mui/material';
import Crud_Service from '../../apis/CrudService';
import useDateFormatter from './useDateFormatter';
import Icons from '../../utils/Icon';
import { Icon } from '@iconify/react/dist/iconify.js';
import { toast } from 'react-toastify';
import FmDrawerOne from '../_mui/FmDrawerOne.tsx';
import GlassCard from './../small/GlassCard.tsx';

const HeaderNotification = () => {
  const [anchorEl, setAnchorEl] = useState(null);
  const [notifications, setNotifications] = useState([]);
  const [pageNumber, setPageNumber] = useState(1);
  const [hasMore, setHasMore] = useState(true);
  const open = Boolean(anchorEl);
  const crud = new Crud_Service();
  const formatDate = useDateFormatter();
  const scrollRef = useRef(null);

  const getNotification = async (isLoadMore = false) => {
    const params = {};

    await crud.getAll(
      `usernotifications?pageSize=25&pageNumber=${pageNumber}&sortDirection=desc`,
      params,
      (err, res) => {
        if (err) {
          toast.error(err);
          return;
        }

        if (res) {
          const newNotifications = res?.data?.data;

          if (isLoadMore) {
            setNotifications((prevNotifications) => [
              ...prevNotifications,
              ...newNotifications,
            ]);
          } else {
            setNotifications(newNotifications);
          }

          if (newNotifications?.length < 25) {
            setHasMore(false);
          }
        }
      }
    );
  };

  const loadMoreNotifications = () => {
    if (hasMore) {
      setPageNumber((prev) => prev + 1);
      getNotification(true);
    }
  };

  const handleClick = (event) => {
    setAnchorEl(event.currentTarget);
    if (notifications?.length === 0) {
      getNotification();
    }
  };

  const handleClose = () => {
    setAnchorEl(null);
  };

  useEffect(() => {
    if (pageNumber > 1) {
      getNotification(true);
    }
  }, [pageNumber]);

  const NotificationOverlay = () => {
    return (
      <Box className='flex justify-center items-center min-h-52'>
        <CircularProgress />
      </Box>
    );
  };

  return (
    <Box>
      {/* button start */}
      <Tooltip title='Notification' arrow>
        <IconButton onClick={handleClick} sx={{ color: 'inherit' }}>
          <Badge color='secondary' variant='dot'>
            {Icons.bell}
          </Badge>
        </IconButton>
      </Tooltip>

      {/* button end */}

      <FmDrawerOne
        open={open}
        onClose={handleClose}
        title='Notifications'
        width='350px'
        paddingRemoved
      >
        <Box
          className='h-[calc(100vh_-_60px)] overflow-auto'
          id='scrollableDiv'
        >
          <Box className='p-4'>
            <InfiniteScroll
              dataLength={notifications?.length}
              next={loadMoreNotifications}
              hasMore={hasMore}
              loader={<NotificationOverlay />}
              endMessage={
                <Divider sx={{ my: 2 }}>
                  <b>No more notifications</b>
                </Divider>
              }
              scrollableTarget='scrollableDiv'
            >
              {notifications?.map((menu) => (
                <GlassCard
                  key={menu.userNotificationId}
                  className='flex items-center gap-3 px-2 py-1 mb-3 hover:bg-gray-100 shadow-none'
                >
                  <Box className=' text-lg' color={'primary.main'}>
                    <Icon
                      icon='oui:ml-create-multi-metric-job'
                      width='25'
                      height='25'
                    />
                  </Box>
                  <Box>
                    {menu.notificationStatusName === 'UnRead' ? (
                      <Box className='font-semibold' color={'text.dark'}>
                        {menu.message}
                      </Box>
                    ) : (
                      <Box className='opacity-70 font-light'>
                        {menu.message}
                      </Box>
                    )}
                    <Box className='opacity-80'>
                      {formatDate(menu.createdDate)}
                    </Box>
                  </Box>
                </GlassCard>
              ))}
            </InfiniteScroll>
          </Box>
        </Box>
      </FmDrawerOne>

      {/* <Menu
        anchorEl={anchorEl}
        open={open}
        onClose={handleClose}
        PaperProps={{
          sx: { width: 250, overflow: 'hidden', mt: '5px' },
        }}
        disableScrollLock={true}
        transformOrigin={{ horizontal: 'right', vertical: 'top' }}
        anchorOrigin={{ horizontal: 'right', vertical: 'bottom' }}
      >
        <Box
          className='w-full px-3 pb-2 flex flex-row justify-between'
          component='li'
          sx={{ borderBottom: '1px solid', borderColor: 'border.main' }}
        >
          <Box className='font-bold flex flex-row items-center'>
            Notifications
          </Box>
        </Box>

       

      </Menu> */}
    </Box>
  );
};

export default HeaderNotification;
