export const ExpiryDateCellColor = (value) => {
  const currentDate = new Date();
  const targetDate = new Date(value);

  const timeDiff = targetDate - currentDate;
  const diffDays = Math.ceil(timeDiff / (1000 * 60 * 60 * 24));

  if (diffDays < 0) {
    return 'bg-red-50';
  } else if (diffDays <= 90) {
    return 'bg-blue-100';
  } else if (diffDays <= 180) {
    return 'bg-blue-50';
  } else {
    return '';
  }
};

export const ExpiryDateDueCellText = (value) => {
  const currentDate = new Date();
  const targetDate = new Date(value);

  const timeDiff = targetDate - currentDate;
  const diffDays = Math.ceil(timeDiff / (1000 * 60 * 60 * 24));

  if (diffDays < 0) {
    return 'text-red-500';
  } else if (diffDays <= 6) {
    return 'text-blue-500';
  } else {
    return '';
  }
};

export const ExpiryMatureDateCellText = (value) => {
  const currentDate = new Date();
  const targetDate = new Date(value);

  const timeDiff = targetDate - currentDate;
  const diffDays = Math.ceil(timeDiff / (1000 * 60 * 60 * 24));

  if (diffDays < 0) {
    return 'bg-red-100';
  } else if (diffDays <= 6) {
    return 'bg-orange-100';
  } else {
    return '';
  }
};
