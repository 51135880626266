import React, { memo } from 'react';
import { Box, Avatar, Tooltip } from '@mui/material';
import { colorOnlyClasses } from './../../utils/Colors/ColorClasses.tsx';
import { Icon } from '@iconify/react/dist/iconify.js';

const TableAvatar = ({
  name,
  img,
  desc,
  fontWeightRemoved = false,
  removeImage,
  genderIcon,
  normalImageSize = false,
}) => {
  const initial = name ? name.charAt(0).toUpperCase() : '';

  const GetGenderIcons = (e) => {
    return (
      <Box>
        {e?.toLowerCase() === 'male' && (
          <Box>
            <Icon icon='fontisto:male' width='18' height='18' />
          </Box>
        )}

        {e?.toLowerCase() === 'female' && (
          <Box>
            <Icon icon='fontisto:famale' width='18' height='18' />
          </Box>
        )}
      </Box>
    );
  };

  const getBgColor =
    genderIcon?.toLowerCase() === 'female'
      ? 'secondary.light'
      : 'primary.light';

  const getColor =
    genderIcon?.toLowerCase() === 'female' ? 'secondary.main' : 'primary.main';

  return (
    <Box className='flex gap-2 flex-row items-center'>
      {!removeImage && (
        <Avatar
          alt={name}
          src={img}
          sx={{
            width: normalImageSize ? 30 : 25,
            height: normalImageSize ? 30 : 25,
            fontSize: normalImageSize ? 14 : 12,
            textTransform: 'uppercase',
            bgcolor: genderIcon ? getBgColor : 'primary.light',
            color: genderIcon ? getColor : 'primary.main',
            //  border: '1px solid',
            // borderColor: 'border.main',
            fontWeight: 700,
          }}
        >
          {genderIcon ? GetGenderIcons(genderIcon) : initial}
        </Avatar>
      )}
      <Box>
        <p
          style={{
            fontWeight: fontWeightRemoved ? 400 : 500,
            textTransform: 'capitalize',
          }}
        >
          {name}
        </p>
        <p className='opacity-80'>{desc}</p>
      </Box>
    </Box>
  );
};

export const TableAvatarStatus = memo(
  ({ name, icon, desc, color, title, avatar = true }) => {
    return (
      <Box className='flex gap-2 flex-row justify-between w-full items-center'>
        <Box>
          <Box sx={{ fontWeight: 500 }}>{name}</Box>
          <p style={{ fontWeight: 400 }}>{desc}</p>
        </Box>
        <Tooltip title={title} arrow>
          {avatar && (
            <Avatar
              alt={name}
              sx={{
                width: 25,
                height: 25,
                fontSize: 16,
                color: 'text.main',
                background: 'none',
                //  border: '1px solid',
              }}
              className={`${colorOnlyClasses[color]}`}
            >
              {icon}
            </Avatar>
          )}
        </Tooltip>
      </Box>
    );
  }
);

export default memo(TableAvatar);
