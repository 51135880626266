import React, { useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import Crud_Service from '../apis/CrudService.jsx';
import { RootState } from '../store/store.ts';

export const NavProvider: React.FC<{ children: React.ReactNode }> = ({
  children,
}) => {
  const { error } = useSelector((state: RootState) => state.menu);

  return (
    <>
      {error && <div className='error-banner'>{error}</div>}
      {children}
    </>
  );
};