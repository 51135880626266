import React, { memo } from 'react';
import { Box } from '@mui/material';
import { FontSize } from '../../theme/FontSize';

const ViewTableLightText = ({ name }) => {
  return (
    <Box className='opacity-85' sx={{ fontSize: FontSize.bodyMinusOne }}>
      {name}
    </Box>
  );
};

export default memo(ViewTableLightText);
