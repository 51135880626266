import React, { useState } from 'react';
import DashboardLayout from '../../../components/DashboardLayout.tsx';
import CreatePurchaseForms from './Form/CreatePurchaseForms.tsx';
import { useParams } from 'react-router-dom';
import { BacktoListSimple } from '../../../components/small/BackToButton.tsx';
import PurchaseOrderViewGroupItems from './PurchaseOrderViewGroupItems.tsx';

const CreatePurchaseOrderView = () => {
  const [localPurchaseOrderId, setLocalPurchaseOrderId] = useState(0);
  const [materialRequest, setMaterialRequest] = useState({});
  const [editData, setEditData] = useState(null);
  const { id } = useParams();

  return (
    <DashboardLayout
      title={id ? 'Edit Purchase Order' : 'Create Purchase Order'}
      actionButtons={<BacktoListSimple />}
      titleReverse
    >
      <CreatePurchaseForms
        setLocalPurchaseOrderId={setLocalPurchaseOrderId}
        setMaterialRequest={setMaterialRequest}
        setEditData={setEditData}
      />
      {editData && (
        <>
          <div className='font-bold my-5'>Purchase Order Items</div>
          <PurchaseOrderViewGroupItems editData={editData} />
        </>
      )}
    </DashboardLayout>
  );
};

export default CreatePurchaseOrderView;
