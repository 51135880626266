import React, { useState } from 'react';
import GlassCard from '../../../../components/small/GlassCard.tsx';
import { Grid } from '@mui/material';
import FmSearchableSelect from '../../../../components/_mui/FmSearchableSelect.tsx';
import { yupResolver } from '@hookform/resolvers/yup';
import { useForm } from 'react-hook-form';
import ActionButtons from '../../../../components/_form/ActionButtons.tsx';
import Crud_Service from '../../../../apis/CrudService.jsx';
import { toast } from 'react-toastify';
import { addCheckInvalidationSchema } from '../../../../utils/CommonvalidationSchemas.tsx';
import DashboardLayout from '../../../../components/DashboardLayout.tsx';
import { useNavigate } from 'react-router-dom';
const GridSize = 2.4;

const AddCheckInForm = (props) => {
  const { fetchData } = props;
  const navigate = useNavigate();
  const crud = new Crud_Service();
  const [loading, setLoading] = useState(false)
  const { handleSubmit, control, reset } = useForm({
    resolver: yupResolver(addCheckInvalidationSchema),
    mode: 'onChange',
  });
  const handleSubmitProject = async (values) => {
    setLoading(true);

    await crud.create('checkinforms', values, (err, res) => {
      if (res?.status === 201) {
        toast.success('Check In Created successfully');
        navigate(-1);
        fetchData();
        reset();
        setLoading(false);
      } else {
        setLoading(false);
      }
    });
  };

  return (
    <DashboardLayout title='Add Check In Form' hasSubmenu>
      <GlassCard className='p-3'>
        <form onSubmit={handleSubmit(handleSubmitProject)}>
          <Grid container spacing={2}>
            <Grid item md={GridSize}>
              <FmSearchableSelect
                name='clientId'
                control={control}
                apiUrl='clients'
                valueField='clientId'
                headerField={['Client Name']}
                labelField={['clientName']}
                showField={['clientName']}
                
                label='Client'
              />
            </Grid>

            <Grid item md={GridSize}>
              <FmSearchableSelect
                name='companyId'
                control={control}
                apiUrl='companies'
                valueField='companyId'
                headerField={['Company Name']}
                labelField={['companyName']}
                showField={['companyName']}
                
                label='Company'
              />
            </Grid>

            <Grid item md={GridSize}>
              <FmSearchableSelect
                name='projectId'
                control={control}
                apiUrl='projects'
                valueField='projectId'
                headerField={['Project Code']}
                labelField={['projectCode']}
                showField={['projectCode']}
                
                label='Project Code'
              />
            </Grid>

            <Grid item md={GridSize}>
              <FmSearchableSelect
                name='employeeId'
                control={control}
                apiUrl='employees'
                valueField='employeeId'
                headerField={['Employee Name']}
                labelField={['firstName']}
                showField={['firstName']}
                
                label='Employee'
              />
            </Grid>
            <Grid item md={GridSize}>
              <FmSearchableSelect
                name='workOrderId'
                control={control}
                apiUrl='workOrders'
                valueField='workOrderId'
                headerField={['Work Order Code']}
                labelField={['workOrderCode']}
                showField={['workOrderCode']}
                
                label='Work Order'
              />
            </Grid>

            <Grid item md={GridSize}>
              <FmSearchableSelect
                name='contractId'
                control={control}
                apiUrl='contracts'
                valueField='contractId'
                headerField={['Contract Code']}
                labelField={['contractCode']}
                showField={['contractCode']}
                
                label='Contract'
              />
            </Grid>
          </Grid>
        </form>
        <ActionButtons
          onSubmit={handleSubmit(handleSubmitProject)}
          onReset={reset}
          submitLoading={loading}
          cancelLoading={false}
        />
      </GlassCard>
    </DashboardLayout>
  );
};

export default AddCheckInForm;
