import React, { useEffect, useState } from 'react';
import GlassCard from '../../../../components/small/GlassCard.tsx';
import { Box, Grid, Button } from '@mui/material';
import TableActionButtons from '../../../../components/_form/TableActionButtons.jsx';
import FmDatePicker from '../../../../components/_mui/FmDatePicker.tsx';
import FmSearchableSelect from '../../../../components/_mui/FmSearchableSelect.tsx';
import FmTextField from '../../../../components/_mui/FmTextField.tsx';
import FmViewDateField from '../../../../components/_mui/FmViewDateField.tsx';
import { useForm } from 'react-hook-form';
import { yupResolver } from '@hookform/resolvers/yup';
import { VendorInvoiceItemsSchema } from '../../../../utils/CommonvalidationSchemas.tsx';
import { Icon } from '@iconify/react/dist/iconify.js';
import Icons from '../../../../utils/Icon.jsx';
import { toast } from 'react-toastify';
import Crud_Service from '../../../../apis/CrudService.jsx';
import useTableLogic from '../../../../components/helpers/MRTUseTableLogic.tsx';
import FmMRTDataTable from '../../../../components/FmMRTDataTable.tsx';
import dayjs from 'dayjs';
import { useParams } from 'react-router-dom';

import GlassCardCollapse from './../../../../components/small/GlassCardCollapse.tsx';

const MdItemValue = 2.4;

const ViewVendorInvoiceList = (props) => {
  const { data, getData } = props;
  const [editingRowData, setEditingRowData] = useState(null);
  const [editingRowId, setEditingRowId] = useState(null);
  const crud = new Crud_Service();
  const { handleSubmit, control, setValue, reset } = useForm({
    resolver: yupResolver(VendorInvoiceItemsSchema),
    mode: 'onChange',
  });
  const {
    handleSubmit: handleLineSubmit,
    control: lineControl,
    reset: lineReset,
    setValue: setLineValue,
  } = useForm({
    resolver: yupResolver(VendorInvoiceItemsSchema),
    mode: 'onChange',
  });
  const id = useParams().id;

  const {
    rows,
    isLoading,
    pagination,
    sorting,
    columnFilters,
    globalFilter,
    tableRecordCounts,
    setPagination,
    setSorting,
    setColumnFilters,
    setGlobalFilter,
    fetchData,
    setSearchKeyword,
    searchKeyword,
  } = useTableLogic(`vendorinvoiceitems`, 'vendorInvoiceId', id);
  const handleEdit = (row) => {
    setEditingRowId(row?.vendorInvoiceItemId);
    setEditingRowData({ ...row });

    Object.entries({
      ...row,
    }).forEach(([key, value]) => {
      setLineValue(key, value);
    });
  };

  useEffect(() => {
    data && fetchData();
    getData(false);
  }, [data]);
  const handleCancel = () => {
    setEditingRowId(null);
    setEditingRowData(null);
  };
  const handleDelete = async (values) => {
    crud.remove(
      'salesorderdistributions',
      values.vendorInvoiceItemId,
      (_err, res) => {
        if (res?.status === 204) {
          fetchData();
          toast.success('Sales Order Distribution deleted Successfully');
        } else {
        }
      }
    );
  };

  const ActionData = [
    {
      name: 'View Details',
      icon: <Icon icon='solar:eye-linear' />,
      onClick: (_props) => {},
    },
    {
      name: 'Edit',
      icon: Icons.edit,
      onClick: (props) => {
        handleEdit(props);
      },
    },
    {
      name: 'Delete',
      icon: <Icon icon='solar:trash-bin-minimalistic-broken' />,
      onClick: (props) => handleDelete(props),
      danger: true,
    },
  ];
  const handleEditItem = async (values) => {
    crud.update(
      'vendorinvoiceitems',
      values.vendorInvoiceItemId,
      values,
      (_err, res) => {
        if (res?.status === 200) {
          fetchData();
          toast.success('Vendor Invoice Item updated Successfully');
          handleCancel();
          lineReset();
        } else {
        }
      }
    );
  };
  const columns = [
    {
      header: 'Actions',
      size: 100,
      Cell: ({ row }) => (
        <Box className='flex gap-2'>
          {editingRowId === row?.original?.vendorInvoiceItemId ? (
            <>
              <Button onClick={handleLineSubmit(handleEditItem)}>Save</Button>
              <Button onClick={handleCancel}>Cancel</Button>
            </>
          ) : (
            <TableActionButtons
              Actions={ActionData?.map((action) => ({
                ...action,
                onClick: () => action.onClick(row?.original),
              }))}
            />
          )}
        </Box>
      ),
    },
    {
      accessorKey: 'vendorId',
      header: 'Vendor',
      enableEditing: true,
      size: 300,
      Cell: ({ row }) => {
        return editingRowId === row?.original?.vendorInvoiceItemId ? (
          <FmSearchableSelect
            name='vendorId'
            control={lineControl}
            apiUrl='suppliers'
            valueField='vendorId'
            defaultValue={{
              vendorId: editingRowData?.vendorId,
              officialSupplierName: editingRowData?.vendorName,
            }}
            headerField={['Name']}
            labelField={['officialSupplierName']}
            showField={['officialSupplierName']}
            label='Vendor'
            required
          />
        ) : (
          row?.original?.vendorName
        );
      },
    },
    {
      accessorKey: 'documentTypeId',
      header: 'Document Type',
      size: 220,
      enableEditing: true,
      Cell: ({ row }) => {
        return editingRowId === row?.original?.vendorInvoiceItemId ? (
          <FmSearchableSelect
            name='documentTypeId'
            control={lineControl}
            apiUrl='documenttypes'
            valueField='documentTypeId'
            defaultValue={{
              documentTypeId: editingRowData?.documentTypeId,
              documentTypeName: editingRowData?.documentTypeName,
            }}
            headerField={['Document Type']}
            labelField={['documentTypeName']}
            showField={['documentTypeName']}
            label='Document Type'
          />
        ) : (
          row?.original?.documentTypeName
        );
      },
    },
    {
      accessorKey: 'documentNo',
      header: 'Document No',
      enableEditing: true,
      size: 220,
      Cell: ({ row }) => {
        return editingRowId === row?.original?.vendorInvoiceItemId ? (
          <FmTextField
            name='documentNo'
            control={lineControl}
            defaultValue={editingRowData?.documentNo}
            label='Document No'
          />
        ) : (
          row?.original?.documentNo
        );
      },
    },
    {
      accessorKey: 'documentDate',
      header: 'Document Date',
      size: 220,
      enableEditing: true,
      Cell: ({ row }) => {
        return editingRowId === row?.original?.vendorInvoiceItemId ? (
          <FmDatePicker
            name='documentDate'
            control={lineControl}
            defaultValue={editingRowData?.documentDate}
            label='Document Date'
            required
          />
        ) : (
          <FmViewDateField
            value={row?.original?.documentDate}
          ></FmViewDateField>
        );
      },
    },
    {
      accessorKey: 'amountPayable',
      header: 'Amount Payable',
      enableEditing: true,
      size: 220,
      Cell: ({ row }) => {
        return editingRowId === row?.original?.vendorInvoiceItemId ? (
          <FmTextField
            name='amountPayable'
            label='Amount Payable'
            control={lineControl}
            type='number'
            required
            defaultValue={editingRowData?.amountPayable}
            variant='filled'
          />
        ) : (
          row?.original?.amountPayable
        );
      },
    },
    {
      accessorKey: 'localPurchaseOrderId',
      header: 'Local Purchase Order',
      enableEditing: true,
      size: 300,
      Cell: ({ cell }) => {
        const details: any = rows?.find(
          (item) =>
            item?.vendorInvoiceItemId ===
            cell?.row?.original?.vendorInvoiceItemId
        );
        return editingRowId === cell?.row?.original?.vendorInvoiceItemId ? (
          <FmSearchableSelect
            name='localPurchaseOrderId'
            control={lineControl}
            apiUrl='localpurchaseorders'
            valueField='localPurchaseOrderId'
            headerField={['Code']}
            labelField={['localPurchaseOrderNumber']}
            showField={['localPurchaseOrderNumber']}
            label='Local Purchase Order'
            required
            defaultValue={{
              localPurchaseOrderId: details?.localPurchaseOrderId || null,
              localPurchaseOrderNumber: details?.lpoNumber || null,
            }}
          />
        ) : (
          cell?.row?.original?.lpoNumber
        );
      },
    },
    {
      accessorKey: 'responseDate',
      header: 'Response Date',
      enableEditing: true,
      size: 300,
      Cell: ({ row }) => {
        return editingRowId === row?.original?.vendorInvoiceItemId ? (
          <FmDatePicker
            name='responseDate'
            label='Response Date'
            control={lineControl}
            defaultValue={editingRowData?.responseDate}
            minDate={dayjs()}
          />
        ) : (
          <FmViewDateField
            value={row?.original?.responseDate}
          ></FmViewDateField>
        );
      },
    },
    {
      accessorKey: 'contactNo',
      header: 'Contact No',
      enableEditing: true,
      Cell: ({ row }) => {
        return editingRowId === row?.original?.vendorInvoiceItemId ? (
          <FmTextField
            label='Contact No'
            pattern='Number'
            defaultValue={editingRowData?.contactNo}
            control={lineControl}
            name='contactNo'
          />
        ) : (
          row?.original?.contactNo
        );
      },
    },
    {
      accessorKey: 'vendorRef',
      header: 'Vendor Ref',
      enableEditing: true,
      Cell: ({ row }) => {
        return editingRowId === row?.original?.vendorInvoiceItemId ? (
          <FmTextField
            label='Vendor Ref'
            control={lineControl}
            defaultValue={editingRowData?.vendorRef}
            name='vendorRef'
          />
        ) : (
          row?.original?.vendorRef
        );
      },
    },
    {
      accessorKey: 'idNo',
      header: 'ID No',
      size: 300,
      enableEditing: true,
      Cell: ({ row }) => {
        return editingRowId === row?.original?.vendorInvoiceItemId ? (
          <FmTextField
            defaultValue={editingRowData?.idNo}
            label='ID No'
            control={lineControl}
            name='idNo'
          />
        ) : (
          row?.original?.idNo
        );
      },
    },
    {
      accessorKey: 'remarks',
      header: 'Remarks',
      enableEditing: true,
      size: 300,
      Cell: ({ row }) => {
        const incidentReport: any = rows?.find(
          (item) =>
            item?.vendorInvoiceItemId === row?.original?.vendorInvoiceItemId
        );
        return editingRowId === row?.original?.vendorInvoiceItemId ? (
          <FmTextField
            label='Remarks'
            multiline
            rows={2}
            control={lineControl}
            name='remarks'
          />
        ) : (
          incidentReport?.remarks || ''
        );
      },
    },
  ];
  const onSubmit = async (data: any) => {
    const values = {
      ...data,
      vendorInvoiceId: id,
      documentDate: data?.documentDate?.format('YYYY-MM-DD'),
      responseDate: data?.responseDate?.format('YYYY-MM-DD'),
    };
    await crud.create('vendorinvoiceitems', values, (err, res) => {
      if (res && res.status === 201) {
        toast.success('Vendor Invoice Item Created Successfully');
        getData(true);
        reset();
      }
    });
  };

  return (
    <>
      <Box className='flex flex-col min-h-0 gap-3 flex-1'>
        <GlassCardCollapse title='Add Vendor Invoice'>
          <form onSubmit={handleSubmit(onSubmit)}>
            <Grid container spacing={2}>
              <Grid item md={MdItemValue}>
                <FmSearchableSelect
                  name='vendorId'
                  label='Vendor'
                  apiUrl='suppliers'
                  control={control}
                  valueField='supplierId'
                  headerField={['Namw', 'Code']}
                  labelField={['officialSupplierName', 'supplierCode']}
                  showField={['officialSupplierName', 'supplierCode']}
                  required
                />
              </Grid>

              <Grid item md={MdItemValue}>
                <FmSearchableSelect
                  name='documentTypeId'
                  label='Document Type'
                  apiUrl='documenttypes'
                  control={control}
                  valueField='documentTypeId'
                  labelField={['documentTypeCode', 'documentTypeName']}
                  showField={['documentTypeCode', 'documentTypeName']}
                />
              </Grid>
              <Grid item md={MdItemValue}>
                <FmTextField
                  name='documentNo'
                  label='Document No'
                  control={control}
                  variant='filled'
                />
              </Grid>
              <Grid item md={MdItemValue}>
                <FmDatePicker
                  name='documentDate'
                  label='Document Date'
                  control={control}
                  minDate={dayjs()}
                  variant='filled'
                />
              </Grid>
              <Grid item md={MdItemValue}>
                <FmTextField
                  name='amountPayable'
                  label='Amount Payable'
                  control={control}
                  required
                  type='number'
                  variant='filled'
                />
              </Grid>
              <Grid item md={MdItemValue}>
                <FmSearchableSelect
                  name='localPurchaseOrderId'
                  control={control}
                  apiUrl='localpurchaseorders'
                  valueField='localPurchaseOrderId'
                  headerField={['Code']}
                  labelField={['localPurchaseOrderNumber']}
                  showField={['localPurchaseOrderNumber']}
                  label='Local Purchase Order'
                  required
                />
              </Grid>
              <Grid item md={MdItemValue}>
                <FmDatePicker
                  name='responseDate'
                  label='Response Date'
                  control={control}
                  minDate={dayjs()}
                />
              </Grid>
              <Grid item md={MdItemValue}>
                <FmTextField
                  label='Contact No'
                  pattern='Number'
                  control={control}
                  name='contactNo'
                />
              </Grid>
              <Grid item md={MdItemValue}>
                <FmTextField
                  label='Vendor Ref'
                  control={control}
                  name='vendorRef'
                />
              </Grid>
              <Grid item md={MdItemValue}>
                <FmTextField label='ID No' control={control} name='idNo' />
              </Grid>

              <Grid item md={MdItemValue * 2}>
                <FmTextField
                  label='Remarks'
                  multiline
                  rows={1}
                  control={control}
                  name='remarks'
                />
              </Grid>
              {/* <Grid item md={MdItemValue}>
                <UploadModalBox />
              </Grid> */}
              <Grid item md={MdItemValue}>
                <Button
                  type='submit'
                  onSubmit={handleSubmit(onSubmit)}
                  fullWidth
                  variant='contained'
                >
                  Create New
                </Button>
              </Grid>
            </Grid>
          </form>
        </GlassCardCollapse>

        <GlassCard className='flex-1 min-h-0'>
          <FmMRTDataTable
            rows={rows}
            columns={columns}
            enableRowSelection={false}
            rowCount={tableRecordCounts}
            pagination={pagination}
            sorting={sorting}
            columnFilters={columnFilters}
            globalFilter={globalFilter}
            setPagination={setPagination}
            setSorting={setSorting}
            setColumnFilters={setColumnFilters}
            setGlobalFilter={setGlobalFilter}
            isLoading={isLoading}
            setSearchKeyword={setSearchKeyword}
            searchKeyword={searchKeyword}
            fetchData={fetchData}
            muiTableContainerProps={{
              sx: {
                maxHeight: '100%',
                flex: 1,
              },
            }}
          />
        </GlassCard>
      </Box>
    </>
  );
};

export default ViewVendorInvoiceList;
