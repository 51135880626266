import React from 'react';
import { Controller } from 'react-hook-form';
import { Box, RadioGroup, FormControlLabel, Radio, FormHelperText } from '@mui/material';

interface CommonRadioButtonProps {
  name: string;
  control: any; // Adjust the type based on your `react-hook-form` setup
  label: string;
  options: { value: any; label: string }[];
  onValueChange?: (value: any) => void;
}

const FmRadioButton: React.FC<CommonRadioButtonProps> = ({
  name,
  control,
  label,
  options,
  onValueChange,
}) => {
  return (
    <Controller
      name={name}
      control={control}
      render={({ field, fieldState }) => {
        const { error } = fieldState;

        return (
          <>
            <Box id={`${name}-label`}>{label}</Box>
            <RadioGroup
              row
              {...field}
              onChange={(event) => {
                const value = event.target.value;
                field.onChange(value);
                if (onValueChange) {
                  onValueChange(value);
                }
              }}
            >
              {options.map((option) => (
                <FormControlLabel
                  key={option.value}
                  value={option.value}
                  control={<Radio />}
                  label={option.label}
                />
              ))}
            </RadioGroup>
            {error && <FormHelperText error>{error.message}</FormHelperText>}
          </>
        );
      }}
    />
  );
};

export default FmRadioButton;
