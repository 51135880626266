import React, { useEffect } from 'react';
import { Button, Box } from '@mui/material';
import MuiDialogOne from './MuiDialogOne.jsx';
import { Icon } from '@iconify/react/dist/iconify.js';
import SignatureCanvas from 'react-signature-canvas';

const MuiSignModal = ({
  editSignatureImage,
  savedSignature,
  signatureImage,
  sigCanvas,
  showModal,
  setShowModal,
  handleClearSignature,
  handleSaveSignature,
  handleDownloadSignature,
  isEdit,
}) => {
  const handleCloseModal = (_event, reason) => {
    if (reason !== 'backdropClick') {
      setShowModal(false);
    }
  };

  const handleOpenModal = () => {
    setShowModal(true);
  };

  useEffect(() => {
    if (!showModal) return;
    const timer = setTimeout(() => {
      if (sigCanvas.current) {
        sigCanvas.current.clear();
        if (savedSignature) {
          sigCanvas.current.fromDataURL(savedSignature);
        } else if (isEdit && editSignatureImage) {
          sigCanvas.current.clear();
          sigCanvas.current.fromDataURL(editSignatureImage);
        } else if (signatureImage) {
          sigCanvas.current.clear();
          sigCanvas.current.fromDataURL(signatureImage);
        } else {
          sigCanvas.current.clear();
        }
      }
    }, 100);
    return () => clearTimeout(timer);
  }, [
    showModal,
    editSignatureImage,
    signatureImage,
    isEdit,
    sigCanvas,
    savedSignature,
  ]);

  return (
    <>
      <Button
        onClick={handleOpenModal}
        fullWidth
        className='flex justify-start items-center gap-4  font-semibold'
      >
        <span className='text-2xl'>
          <Icon icon='ph:signature-light' />
        </span>
        <span>Signature</span>
      </Button>

      <MuiDialogOne
        open={showModal}
        onClose={handleCloseModal}
        title='Signature'
      >
        <Box
          sx={{
            border: '2px dashed',
            borderColor: 'primary.main',
            bgcolor: 'background.mainLight',
            borderRadius: '10px',
            minHeight: 200,
          }}
        >
          <SignatureCanvas
            ref={sigCanvas}
            penColor='black'
            canvasProps={{
              width: 500,
              height: 200,
              className: 'signature-canvas',
            }}
          />
        </Box>

        <Box className='mt-5 flex justify-end gap-5'>
          <Button
            className='min-w-28'
            variant='outlined'
            onClick={handleClearSignature}
          >
            Clear
          </Button>
          {isEdit && editSignatureImage && (
            <Button
              className='min-w-28'
              variant='outlined'
              onClick={handleDownloadSignature}
            >
              Download
            </Button>
          )}
          <Button
            className='min-w-28'
            variant='contained'
            onClick={handleSaveSignature}
          >
            {isEdit && editSignatureImage ? 'Update' : 'Save'}
          </Button>
        </Box>
      </MuiDialogOne>
    </>
  );
};

export default MuiSignModal;
