import React, { useState, useEffect } from 'react';
import {
  TextField,
  Table,
  TableContainer,
  TableBody,
  TableCell,
  TableHead,
  TableRow,
  Button,
  Stack,
  Divider,
  IconButton,
  FormControl,
  Box,
  FormControlLabel,
  RadioGroup,
  Radio,
} from '@mui/material';
import { useForm } from 'react-hook-form';
import useCommonFetchApi from '../../../../components/helpers/useCommonFetchApi.tsx';
import FmAutoComplete from '../../../../components/_mui/FmAutoComplete.tsx';
import FmTextField from '../../../../components/_mui/FmTextField.tsx';
import GlassCard from '../../../../components/small/GlassCard.tsx';
import Crud_Service from '../../../../apis/CrudService';
import { Icon } from '@iconify/react/dist/iconify.js';
import { toast } from 'react-toastify';
import { useNavigate } from 'react-router-dom';
import CommonLoader from '../../../../components/page/CommonLoader.jsx';

const CreatePurchaseItem = (props) => {
  const { localPurchaseOrderId, materialRequest, editData } = props;
  const [mrItemList, setMrItemsList] = useState([]);
  const [supplierItems, setSupplierItems] = useState([]);
  const [rowData, setRowData] = useState(null);
  const [selectedSuppliers, setSelectedSuppliers] = useState([]);
  const [selectedSupplier, setSelectedSupplier] = useState({});
  const [loading, setLoading] = useState(false);
  const { control, setValue } = useForm();
  const crud = new Crud_Service();
  const navigate = useNavigate();

  const { data: item } = useCommonFetchApi('item');
  const { data: costCenters } = useCommonFetchApi('costcenters?pageSize=100');

  useEffect(() => {
    getAllLocalPurchaseOrder();
    getSupplierItems();
  }, [localPurchaseOrderId]);

  useEffect(() => {
    handleSupplierSelect(0, supplierItems[0], 0);
  }, [supplierItems]);

  const getAllLocalPurchaseOrder = async () => {
    setLoading(true);
    await crud.getAll(
      'localpurchaseorderitems',
      {
        localPurchaseOrderId:
          localPurchaseOrderId || editData?.localPurchaseOrderId,
      },
      (err, res) => {
        if (res?.status === 200) {
          const items = res?.data?.data?.map((item, index) => {
            setValue(`quantity[${index}]`, item?.quantity);
            setValue(`remarks[${index}]`, item?.remarks);
            setValue(`salesOrderId[${index}]`, item?.salesOrderId);
            setValue(`itemId[${index}]`, item?.itemId);
            setValue(`costCenterName[${index}]`, item?.costCenterId);
            item?.supplierDetails?.forEach((supplier, supplierIndex) => {
              setValue(
                `supplierId[${index}][${supplierIndex}]`,
                supplier?.supplierId
              );
              setValue(
                `supplierDetails[${index}][${supplierIndex}].price`,
                supplier?.price
              );
            });

            return {
              ...item,
              isEditable: false,
              supplierDetails: item?.supplierDetails || [{}, {}, {}],
            };
          });
          setMrItemsList(items);
          setLoading(false);
        } else {
          setLoading(false);
        }
      }
    );
  };

  const getSupplierItems = async (id) => {
    await crud.getAll('supplieritems', { itemId: id }, (err, res) => {
      if (res?.status === 200) {
        setSupplierItems(res?.data?.data);
      } else {
      }
    });
  };

  const handleAddRow = () => {
    const newRow = {
      id: '',
      itemId: '',
      itemCode: '',
      salesOrderId: '',
      quantity: 0,
      supplierDetails: [{}, {}, {}], // Initialize supplier details with three empty objects
      isEditable: true,
    };
    setMrItemsList([...mrItemList, newRow]);
  };

  const handleEditClick = (rowIndex) => {
    const updatedTableData = [...mrItemList];
    updatedTableData[rowIndex].isEditable =
      !updatedTableData[rowIndex].isEditable;

    setRowData(updatedTableData[rowIndex]);
    setMrItemsList(updatedTableData);

    getSupplierItems(updatedTableData[rowIndex]?.itemId);
  };

  const handleInputChange = (event, rowIndex, field) => {
    const updatedTableData = [...mrItemList];
    const value = event.target.value;

    setValue(`${field}[${rowIndex}]`, value);
    if (field.startsWith('supplierDetails')) {
      const [_, index, prop] = field.match(/\[(\d+)\]\.(\w+)/) || [];
      if (index !== undefined && prop !== undefined) {
        updatedTableData[rowIndex].supplierDetails[index][prop] = value;
      }
    } else {
      updatedTableData[rowIndex][field] = value;
    }

    setMrItemsList(updatedTableData);
  };

  const handleSupplierPriceChange = (e, index, rowIndex) => {
    const price = e.target.value;
    const updatedTableData = [...mrItemList];
    updatedTableData[rowIndex].supplierDetails[index] = {
      ...updatedTableData[rowIndex].supplierDetails[index],
      unitPrice: price,
    };

    setMrItemsList(updatedTableData);
  };

  const handleSupplierChange = (e, rowIndex, index) => {
    const supplierId = e.target.value;
    setValue(`supplierDetails[${rowIndex}][${index}].supplierId`, supplierId);
    const updatedTableData = [...mrItemList];
    updatedTableData[rowIndex].supplierDetails[index].supplierId = supplierId;

    setSelectedSupplier({
      supplierId,
      index,
    });

    setMrItemsList(updatedTableData);
    handleInputChange(e, rowIndex, `supplierDetails[${index}].supplierId`);
  };

  const handleSupplierSelect = (rowIndex, supplier, index) => {
    setSelectedSuppliers((prev) => {
      const updatedSuppliers = [...prev];

      // Ensure the array has the rowIndex position (fill with default values if necessary)
      while (updatedSuppliers?.length <= rowIndex) {
        updatedSuppliers?.push({ supplierId: '', index: -1 });
      }

      // Update the supplier for the specific rowIndex
      updatedSuppliers[rowIndex] = {
        supplierId: supplier?.supplierId,
        index: index,
      };

      // Set selected supplier
      setSelectedSupplier({
        supplierId: supplier?.supplierId,
        index,
      });

      return updatedSuppliers;
    });
  };

  // Select supplier radio
  const isSupplierSelected = (rowIndex, index) => {
    return selectedSuppliers[rowIndex]?.index === index;
  };

  // Handles changes to the supplier name dropdown
  const handleSupplierNameChange = (event, rowIndex, index) => {
    if (!mrItemList[rowIndex]?.supplierDetails) {
      toast.error('supplierDetails is undefined for rowIndex:', rowIndex);
      return;
    }

    const supplierId = event ? event?.supplierId : '';

    if (index >= mrItemList[rowIndex].supplierDetails.length) {
      toast.error('Index out of bounds:', index);
      return;
    }

    const updatedTableData = [...mrItemList];
    updatedTableData[rowIndex].supplierDetails[index] = {
      ...updatedTableData[rowIndex].supplierDetails[index],
      supplierId: supplierId,
    };

    setSelectedSupplier({
      supplierId,
      index,
    });

    setMrItemsList(updatedTableData);
  };

  const columns = [
    {
      id: 'itemId',
      label: 'Item',
      width: 200,
      renderCell: (row, rowIndex) => (
        <Box sx={{ width: '250px' }}>
          <FmAutoComplete
            name={`itemId[${rowIndex}]`}
            control={control}
            options={item}
            label='Item'
            optionFields={['itemName']}
            displayField='itemName'
            valueKey='itemId'
            disabled={row.isEditable || !row.isEditable}
          />
        </Box>
      ),
    },
    {
      id: 'costCenterName',
      label: 'Cost Center',
      width: 200,
      renderCell: (row, rowIndex) => (
        <Box sx={{ width: '250px' }}>
          <FmAutoComplete
            name={`costCenterName[${rowIndex}]`}
            control={control}
            options={costCenters}
            displayField='costCenterName'
            optionFields={['costCenterName']}
            valueKey='costCenterId'
            disabled={!row.isEditable}
          />
        </Box>
      ),
    },
    {
      id: 'quantity',
      label: 'Required Quantity',
      width: 200,
      renderCell: (row, rowIndex) => (
        <Box sx={{ width: '120px' }}>
          <FmTextField
            name={`quantity[${rowIndex}]`}
            control={control}
            pattern='Number'
            disabled={!row.isEditable}
            label='Required Quantity'
          />
        </Box>
      ),
    },
    {
      id: 'onHand',
      label: ' On Hand',
      width: 200,
      renderCell: (row, rowIndex) => (
        <Box sx={{ width: '120px' }}>
          <FmTextField
            name={`onHand[${rowIndex}]`}
            control={control}
            defaultValue={row.onHand || 0}
            pattern='Number'
            disabled={!row.isEditable}
            label='On Hand'
          />
        </Box>
      ),
    },
    {
      id: 'remarks',
      label: 'Remarks',
      width: 250,
      renderCell: (row, rowIndex) => (
        <Box sx={{ width: '250px' }}>
          <FmTextField
            name={`remarks[${rowIndex}]`}
            control={control}
            label='Remarks'
            disabled={!row.isEditable}
          />
        </Box>
      ),
    },

    {
      id: 'supplierDetails',
      label: 'Supplier Details',
      width: 1000,
      renderCell: (row, rowIndex) => {
        return (
          <Stack
            direction='row'
            spacing={2}
            divider={<Divider orientation='vertical' flexItem />}
            alignItems='center'
            sx={{ display: 'flex', justifyContent: 'space-between' }}
          >
            {row?.supplierDetails?.map((supplier, index) => {
              // You are now checking if the entire row is editable
              const isEditable = row?.isEditable === true;

              return (
                <React.Fragment key={index}>
                  {/* Radio Button */}
                  <FormControl component='fieldset'>
                    <RadioGroup
                      name={`supplier${rowIndex}`}
                      value={supplier?.supplierId || ''}
                      onChange={(e) =>
                        handleSupplierSelect(rowIndex, supplier, index)
                      }
                      disabled={!isEditable} // Check the overall row's editability
                    >
                      <FormControlLabel
                        control={
                          <Radio
                            value={supplier?.supplierId}
                            checked={
                              isSupplierSelected(rowIndex, index) ||
                              (index === 0 && !selectedSuppliers[rowIndex])
                            }
                            onChange={() =>
                              handleSupplierSelect(rowIndex, supplier, index)
                            }
                            color='primary'
                            size='small'
                            sx={{ padding: '5px' }}
                          />
                        }
                      />
                    </RadioGroup>
                  </FormControl>

                  {/* Supplier Name Dropdown */}
                  <Box sx={{ width: '250px' }}>
                    <FmAutoComplete
                      name={`supplierId[${rowIndex}][${index}]`}
                      control={control}
                      options={supplierItems}
                      displayField='supplierName'
                      optionFields={['supplierName']}
                      valueKey='supplierId'
                      value={supplier?.supplierId || ''}
                      disabled={
                        selectedSuppliers[rowIndex]?.index !== index ||
                        !isEditable
                      }
                      onChangeValue={true}
                      onChange={(e) =>
                        handleSupplierNameChange(e, rowIndex, index)
                      }
                    />
                  </Box>

                  {/* Supplier Price Field */}
                  <Box sx={{ width: '120px' }}>
                    <TextField
                      label='Price'
                      type='number'
                      defaultValue={supplier?.unitPrice || ''}
                      onChange={(e) =>
                        handleSupplierPriceChange(e, index, rowIndex)
                      }
                      fullWidth
                      disabled={
                        selectedSuppliers[rowIndex]?.index !== index ||
                        !isEditable // Check the overall row's editability
                      }
                      size='small'
                      variant='filled'
                    />
                  </Box>
                </React.Fragment>
              );
            })}
          </Stack>
        );
      },
    },
  ];

  const handleSaveLocalPurchaseItems = async (rowIndex) => {
    const row = mrItemList[rowIndex];
    const selectedSupplierData =
      row.supplierDetails[selectedSupplier.index] || {};
    const purchaseItem = {
      ...row,
      itemId: row?.itemId?.value,
      salesOrderId: row?.salesOrderId?.value,
      selectedSuppliers: {
        supplierId: selectedSupplierData
          ? selectedSupplierData.supplierId
          : row?.supplierDetails[0]?.supplierId,
        price: selectedSupplierData.price
          ? selectedSupplierData.unitPrice
          : row?.supplierDetails[0]?.unitPrice,
        quantity: selectedSupplierData.quantity
          ? selectedSupplierData.quantity
          : row?.supplierDetails[0]?.quantity,
      },
    };
    const combinedData = [
      {
        localPurchaseOrderId:
          localPurchaseOrderId || editData?.localPurchaseOrderId,
        supplierId: purchaseItem?.selectedSuppliers?.supplierId,
        lpoSupplierItemDetails: [
          {
            localPurchaseOrderItemId: purchaseItem?.localPurchaseOrderItemId,
            unitPrice: purchaseItem?.selectedSuppliers?.price,
          },
        ],
      },
    ];

    // const apiData = {
    //   localPurchaseOrderId: purchaseItem?.localPurchaseOrderId,
    //   itemId: purchaseItem?.itemId,
    //   quantity: purchaseItem?.quantity,
    //   remarks: purchaseItem?.remarks,
    //   onHand: purchaseItem?.onHand,
    //   categoryId: purchaseItem?.categoryId,
    //   costCenterId: purchaseItem?.costCenterId,
    //   unitOfMeasurementId: purchaseItem?.unitOfMeasurementId,
    //   salesOrderId: purchaseItem?.salesOrderId,
    //   status: 2,
    // };

    // await crud.update(
    //   'localPurchaseOrderItems',
    //   purchaseItem?.localPurchaseOrderItemId,
    //   apiData,
    //   (err, res) => {
    //     if (res?.status === 200) {
    //       getAllLocalPurchaseOrder();
    //       toast.success('Purchase Order Item Updated Successfully');
    //     } else {
    //
    //     }
    //   }
    // );

    //Supplier Create
    await crud.create('lposuppliers', combinedData, (err, res) => {
      if (res?.status === 201) {
        toast.success('Supplier Updated for That Item Successfully');
        getAllLocalPurchaseOrder();
      } else {
      }
    });
  };

  const generatePreview = async () => {
    const previewData = mrItemList?.map((item) => {
      // Always select the first supplier if no supplier is selected
      const selectedSupplierData =
        selectedSupplier?.index !== undefined &&
        item?.supplierDetails[selectedSupplier?.index]
          ? item?.supplierDetails[selectedSupplier?.index]
          : item?.supplierDetails[0]; // Fallback to the first supplier

      return {
        ...item,
        itemId: item?.itemId?.value || item?.itemId,
        salesOrderId: item?.salesOrderId?.value || item?.salesOrderId,
        selectedSuppliers: {
          supplierId: selectedSupplierData?.supplierId || null,
          price: selectedSupplierData?.unitPrice || 0,
          quantity: selectedSupplierData?.quantity || item?.quantity,
        },
      };
    });

    // Create the CombinedData array for API request
    const CombinedData = previewData?.map((item) => ({
      localPurchaseOrderId:
        item?.localPurchaseOrderId || editData?.localPurchaseOrderId,
      supplierId: item?.selectedSuppliers?.supplierId,
      lpoSupplierItemDetails: [
        {
          localPurchaseOrderItemId: item?.localPurchaseOrderItemId,
          unitPrice: item?.selectedSuppliers?.price,
          quantity: item?.selectedSuppliers?.quantity,
        },
      ],
    }));

    // Uncomment the following to send the data to your API

    await crud.create('lposuppliers', CombinedData, (err, res) => {
      if (res?.status === 201) {
        toast.success('Supplier preview Updated Successfully for all items');
        getAllLocalPurchaseOrder();
        navigate(
          `/procurement/purchase-order-view/preview/${
            localPurchaseOrderId || editData?.localPurchaseOrderId
          }`
        );
      } else {
      }
    });
  };

  return (
    <>
      {/* <Button
        variant='contained'
        color='primary'
        onClick={handleAddRow}
        style={{ margin: '10px' }}
      >
        Add New
      </Button> */}
      {loading ? (
        <CommonLoader />
      ) : (
        <GlassCard className='mt-5'>
          <Box
            sx={{
              '& .MuiTableCell-head, & .MuiTableCell-root': {
                fontSize: 'inherit',
                whiteSpace: 'nowrap',
                padding: '5px 10px',
                borderColor: 'border.main',
              },
              '& .MuiTableHead-root tr': {
                bgcolor: 'background.light',
              },
            }}
          >
            <TableContainer sx={{ maxHeight: 440 }}>
              <Table>
                <TableHead>
                  <TableRow>
                    <TableCell style={{ width: '150px' }}>Action</TableCell>
                    {columns?.map((column) => (
                      <TableCell
                        key={column?.id}
                        style={{ width: column?.width }}
                      >
                        {column?.label}
                      </TableCell>
                    ))}
                  </TableRow>
                </TableHead>
                <TableBody>
                  {mrItemList?.map((row, rowIndex) => (
                    <TableRow key={row?.id}>
                      <TableCell>
                        <Box className='flex gap-2'>
                          <IconButton
                            onClick={() => handleEditClick(rowIndex)}
                            size='small'
                          >
                            <Icon icon='fluent:edit-28-regular' />
                          </IconButton>
                          <IconButton
                            onClick={() =>
                              handleSaveLocalPurchaseItems(rowIndex)
                            }
                            size='small'
                          >
                            <Icon icon='fluent:save-28-regular' />
                          </IconButton>
                        </Box>
                      </TableCell>
                      {columns?.map((column) => (
                        <TableCell
                          key={column?.id}
                          style={{ width: column?.width }}
                        >
                          {column?.renderCell(row, rowIndex)}
                        </TableCell>
                      ))}
                    </TableRow>
                  ))}
                </TableBody>
              </Table>
            </TableContainer>
          </Box>
        </GlassCard>
      )}

      <Box className='mt-4'>
        <Button variant='contained' onClick={generatePreview}>
          Generate Preview
        </Button>
      </Box>
    </>
  );
};

export default CreatePurchaseItem;
