import React, { useEffect, useState } from 'react';
import DashboardLayout from '../../../../components/DashboardLayout.tsx';
import GlassCard from '../../../../components/small/GlassCard.tsx';
import { Icon } from '@iconify/react/dist/iconify.js';
import {
  Grid,
  TextField,
  Box,
  Stack,
  RadioGroup,
  FormControlLabel,
  Radio,
} from '@mui/material';
import { useNavigate, useLocation, useParams } from 'react-router-dom';
import { Controller, useForm } from 'react-hook-form';
import { LocalizationProvider } from '@mui/x-date-pickers/LocalizationProvider';
import { AdapterDayjs } from '@mui/x-date-pickers/AdapterDayjs';
import FmSearchableSelect from '../../../../components/_mui/FmSearchableSelect.tsx';
import Crud_Service from '../../../../apis/CrudService.jsx';
import dayjs from 'dayjs';
import { DatePicker } from '@mui/x-date-pickers/DatePicker';
import FmTextField from '../../../../components/_mui/FmTextField.tsx';
import useCommonFetchApi from '../../../../components/helpers/useCommonFetchApi.tsx';
import FmAutoComplete from '../../../../components/_mui/FmAutoComplete.tsx';
import { toast } from 'react-toastify';
import FmDatePicker from '../../../../components/_mui/FmDatePicker.tsx';
import ActionButtons from '../../../../components/_form/ActionButtons.tsx';
import useLoader from '../../../../components/helpers/UseLoader.tsx';
import CommonLoader from '../../../../components/page/CommonLoader.jsx';
import { CreateButton } from '../../../../components/small/Buttons.jsx';
import { yupResolver } from '@hookform/resolvers/yup';
import { CompanyMedicalInsurancePolicySchema } from '../../../../utils/CommonvalidationSchemas.tsx';

const GridValue = 2.4;

const HeadingBox = ({ icon, title }) => {
  return (
    <GlassCard className='p-3 px-4 flex items-center gap-3 font-semibold bg-blue-50'>
      <Box className='text-xl'>{icon}</Box>
      <Box>{title}</Box>
    </GlassCard>
  );
};

const EditCompanyMedicalInsurancePolicy = () => {
  const navigate = useNavigate();
  const location = useLocation();
  const fullPath = location.pathname;
  const { id } = useParams();
  const { startEditLoading, stopEditLoading, editLoading } = useLoader();
  const pathWithoutLastSlash = fullPath.substring(0, fullPath.lastIndexOf('/'));
  const [, setregionSearchValue] = useState('');
  const [ageBands, setAgeBands] = useState([]);
  const [dependency, setDependency] = useState([]);
  const crud = new Crud_Service();
  const [types, setTypes] = useState([]);
  const [insuranceStatus, setInsuranceStatus] = useState([]);
  const [categories, setCategories] = useState([]);
  const [selectedAgeBand, setSelectedAgeBand] = useState();
  const [loading, setLoading] = useState(false);
  const [editData, setEditData] = useState(null);

  const handleAgeChange = (age) => {
    if (age) {
      const filteredBands = ageBands.filter(
        (band) => age >= band.minimumAge && age <= band.maximumAge
      );
      setSelectedAgeBand(filteredBands[0]);
    }
  };

  const { handleSubmit, control, setValue, getValues, reset } = useForm({
    mode: 'onChange',
    resolver: yupResolver(CompanyMedicalInsurancePolicySchema),
  });


  useEffect(() => {
    document.title = `Edit | Company Medical Insurance Policy | PR`;
    getAgeBands();
    getDependencys();
    getPolicyTypes();
    getInsuranceStatus();
    getCategories();
    getPassportNumber(1);
    getCompanyMedicalInsurancePolicy();
  }, []);
  const { data: employees } = useCommonFetchApi('employees/all-employees');

  useEffect(() => {
    const employeeId = getValues('employeeId');

    if (employeeId) {
      setValue(
        'firstName',
        employees?.find(
          (employee) => employee?.employeeId === employeeId.employeeId
        )?.firstName
      );
      setValue(
        'gender',
        employees?.find(
          (employee) => employee?.employeeId === employeeId.employeeId
        )?.genderName
      );
      setValue(
        'dob',
        dayjs(
          employees?.find(
            (employee) => employee?.employeeId === employeeId.employeeId
          )?.dob
        ).format('MM-DD-YYYY')
      );
      setValue(
        'age',
        dayjs().diff(
          employees?.find(
            (employee) => employee?.employeeId === employeeId.employeeId
          )?.dob,
          'year'
        )
      );
      setValue(
        'maritalStatusName',
        employees?.find(
          (employee) => employee?.employeeId === employeeId.employeeId
        )?.maritalStatusName
      );
      setValue(
        'nationalityName',
        employees?.find(
          (employee) => employee?.employeeId === employeeId.employeeId
        )?.nationalityName
      );
      setValue(
        'employeeDetailId',
        employees?.find(
          (employee) => employee?.employeeId === employeeId.employeeId
        )?.employeeDetailId
      );
    }
  }, [getValues('employeeId'), getValues, setValue]);

  const { data: companymedicalinsurancetypes } = useCommonFetchApi(
    'companymedicalinsurancetypes'
  );

  const getCompanyMedicalInsurancePolicy = async () => {
    setLoading(true);
    await crud.getSingle('companymedicalinsurancepolicies', id, (_err, res) => {
      if (res?.status === 200) {
        Object.entries({
          ...res?.data,
        }).forEach(([key, value]) => {
          setValue(key, value);
        });
        setEditData(res?.data);
        setLoading(false);
      } else {
        setEditData(null);
        setLoading(true);
      }
    });
  };

  const getPassportNumber = (employeeId) => {
    crud.getAll('employeepassports', { employeeId: employeeId }, (err, res) => {
      if (err) {
        toast.error(err);
      }
      if (res?.status === 200) {
        // setValue('passportNumber', res?.data?.data[0]?.passportNumber);
        // setValue('visaIssuedFrom', res?.data?.data[0]?.passportIssuePlace);
        // setValue('documentId', res?.data?.data[0]?.documentId);
      }
    });
  };
  const handleCalculation = async () => {
    await crud.getAll(
      'medicalinsurancepolicies/medicalinsurancepolicycalculation',
      {
        employeeId: getValues('employeeId'),
        regionId: getValues('regionId'),
        companyMedicalInsuranceAgeBandId: getValues(
          'companyMedicalInsuranceAgeBandId'
        ),
        fromDate: dayjs(getValues('startDate')).format('MM-DD-YYYY'),
        toDate: dayjs(getValues('toDate')).format('MM-DD-YYYY'),
      },
      (err, res) => {
        if (err) {
          toast.error(err);
        }
        if (res?.status === 200) {
          setValue('principal', res?.data?.data[0]?.calculateAmount);
        }
      }
    );
  };

  const getAgeBands = async () => {
    try {
      await crud.getAll('companymedicalinsuranceagebands', '', (err, res) => {
        if (err) {
          toast.error(err);
        }
        if (res?.status === 200) {
          setAgeBands(res?.data?.data);
        }
      });
    } catch (error) {
      toast.error(error);
    }
  };

  const getDependencys = async () => {
    try {
      await crud.getAll(
        'companymedicalinsurancedependencies',
        '',
        (err, res) => {
          if (err) {
            toast.error(err);
          }
          if (res?.status === 200) {
            setDependency(res?.data?.data);
          }
        }
      );
    } catch (error) {}
  };

  const getPolicyTypes = async () => {
    try {
      await crud.getAll('companyinsurancepolicytypes', '', (err, res) => {
        if (err) {
          toast.error(err);
        }
        if (res?.status === 200) {
          setTypes(res?.data?.data);
        }
      });
    } catch (error) {
      toast.error(error);
    }
  };

  const getInsuranceStatus = async () => {
    try {
      await crud.getAll('companymedicalinsurancestatuses', '', (err, res) => {
        if (err) {
          toast.error(err);
        }
        if (res?.status === 200) {
          setInsuranceStatus(res?.data?.data);
        }
      });
    } catch (error) {
      toast.error(error);
    }
  };

  const getCategories = async () => {
    try {
      await crud.getAll('companyinsurancepolicycategories', '', (err, res) => {
        if (err) {
          toast.error(err);
        }
        if (res?.status === 200) {
          setCategories(res?.data?.data);
        }
      });
    } catch (error) {}
  };

  const handleEditMedicalInsurance = async (values) => {
    const payload = {
      ...values,
      CompanyMedicalInsurancePolicyId: id,
      startDate: values?.startDate
        ? dayjs(values?.startDate).format('YYYY-MM-DD')
        : null,
      endDate: values?.endDate
        ? dayjs(values?.endDate).format('YYYY-MM-DD')
        : null,
      principal: values?.principal,
      maternity: values?.maternity,
      vat: values?.vat,
      total: values?.total,
      status: 2,
    };
    startEditLoading();

    await crud.update(
      'companymedicalinsurancepolicies',
      id,
      payload,
      (err, res) => {
        if (res?.status === 200) {
          stopEditLoading();
          toast.success('Policy Updated Successfully');
          navigate(-1);
        } else {
          stopEditLoading();
        }
      }
    );

    // try {
    //   const response = await crud.update(
    //     'companymedicalinsurancepolicies',
    //     id,
    //     payload,
    //     () => {}
    //   );

    //   if (response?.status === 200) {
    //     stopEditLoading();
    //     toast.success('Policy Updated Successfully');
    //     navigate(-1);
    //   } else {
    //     throw new Error('Update failed');
    //   }
    // } catch (error) {
    //   stopEditLoading();

    //   toast.error('Error:', error);
    // }
  };

  const setSelectedValue = (data, type) => {
    setValue(type, data);
    const employeeId = getValues('employeeId');
    if (
      employeeId &&
      getValues('regionId') &&
      getValues('companyMedicalInsuranceAgeBandId') &&
      getValues('startDate') &&
      getValues('toDate')
    ) {
      handleCalculation(employeeId.employeeId);
    }
  };

  const handleCalculations = (value, key) => {
    setValue(key, value);
    const principal = Number(getValues('principal'));
    const vat = Number(getValues('vat'));
    const maternity = Number(getValues('maternity'));

    if (vat > 0 && principal > 0 && maternity > 0) {
      setValue('total', principal + vat + maternity);
    }
  };
  const handleEmployeeSelection = (selectedEmployee) => {
    if (selectedEmployee) {
      setValue('firstName', selectedEmployee.firstName);
      setValue('gender', selectedEmployee.genderName); // Assuming genderName is "Male" or "Female"
      setValue('nationalityName', selectedEmployee.nationalityName);
      setValue('maritalStatusName', selectedEmployee.maritalStatusName);
      setValue(
        'dob',
        selectedEmployee.dob ? dayjs(selectedEmployee.dob) : null
      );

      // Calculate age from dob
      if (selectedEmployee.dob) {
        const age = dayjs().diff(dayjs(selectedEmployee.dob), 'year');
        setValue('age', age);
      }
    }
  };
  return (
    <DashboardLayout
      title='Edit Company Medical Insurance Policy'
      actionButtons={
        <>
          <Box className='flex flex-row items-center whitespace-nowrap gap-3'>
            <CreateButton
              name='Create'
              onClick={() =>
                navigate('/admin/company-medical-insurance-policy/list/create')
              }
            />
          </Box>
        </>
      }
    >
      {loading ? (
        <CommonLoader />
      ) : (
        <form onSubmit={handleSubmit(handleEditMedicalInsurance)}>
          <LocalizationProvider dateAdapter={AdapterDayjs}>
            <Stack spacing={3}>
              <HeadingBox
                title='Policy Details'
                icon={<Icon icon='tdesign:secured' />}
              />

              <GlassCard className='p-4'>
                <Grid container spacing={2}>
                  <Grid item md={6}>
                    <FmSearchableSelect
                      name='regionId'
                      control={control}
                      apiUrl='regions'
                      valueField='regionId'
                      headerFields={['Region Code', 'Region Name']}
                      labelField={['regionCode', 'regionName']}
                      showField={['regionName']}
                      label='Region'
                      defaultValue={{
                        regionId: editData?.regionId,
                        regionName: editData?.regionName,
                      }}
                    />
                  </Grid>
                  {/* <Grid item md={GridValue}>
                    <FmAutoComplete
                      name='companyMedicalInsuranceTypeId'
                      control={control}
                      options={types}
                      label='Policy Name'
                      displayField='companyInsurancePolicyTypeName'
                      optionFields={['companyInsurancePolicyTypeName']}
                      valueKey='companyMedicalInsuranceTypeId'
                    />
                  </Grid> */}
                  <Grid item md={GridValue}>
                    <FmAutoComplete
                      name='companyMedicalInsuranceTypeId'
                      control={control}
                      options={companymedicalinsurancetypes}
                      label='Company Medical Insurance Type'
                      displayField='companyMedicalInsuranceTypeName'
                      optionFields={['companyMedicalInsuranceTypeName']}
                      valueKey='companyMedicalInsuranceTypeId'
                    />
                  </Grid>
                  <Grid item md={GridValue}>
                    <Controller
                      name='startDate'
                      control={control}
                      defaultValue={dayjs()}
                      render={({ field: { onChange, value } }) => (
                        <DatePicker
                          label={
                            <>
                              Start Date
                              <span style={{ color: 'red' }}> *</span>
                            </>
                          }
                          value={value ? dayjs(value) : null}
                          format='DD/MM/YYYY'
                          onChange={(newValue) => {
                            onChange(newValue);
                            setSelectedValue(newValue, 'startDate');
                          }}
                          sx={{ width: '100%' }}
                        />
                      )}
                    />{' '}
                  </Grid>
                  <Grid item md={GridValue}>
                    <Controller
                      name='toDate'
                      control={control}
                      defaultValue={dayjs()}
                      render={({ field: { onChange, value } }) => (
                        <DatePicker
                          label={
                            <>
                              End Date
                              <span style={{ color: 'red' }}> *</span>
                            </>
                          }
                          value={value ? dayjs(value) : null}
                          format='DD/MM/YYYY'
                          onChange={(newValue) => {
                            onChange(newValue);
                            setSelectedValue(newValue, 'toDate');
                          }}
                          sx={{ width: '100%' }}
                        />
                      )}
                    />{' '}
                  </Grid>
                  <Grid item md={GridValue}>
                    <FmAutoComplete
                      name='companyMedicalInsuranceStatusId'
                      control={control}
                      options={insuranceStatus}
                      label='Insurance Status'
                      displayField='companyMedicalInsuranceStatusName'
                      optionFields={['companyMedicalInsuranceStatusName']}
                      valueKey='companyMedicalInsuranceStatusId'
                    />
                  </Grid>
                  <Grid item md={GridValue}>
                    <FmTextField
                      name='cardNumber'
                      label='Card Number'
                      control={control}
                      rules={{ required: 'Card Number is required' }}
                    />
                  </Grid>
                  <Grid item md={GridValue}>
                    <FmAutoComplete
                      name='companyInsurancePolicyCategoryId'
                      control={control}
                      options={categories}
                      label='Category'
                      displayField='companyInsurancePolicyCategoryName'
                      optionFields={['companyInsurancePolicyCategoryName']}
                      valueKey='companyInsurancePolicyCategoryId'
                    />
                  </Grid>
                  <Grid item md={GridValue}>
                    <Controller
                      name='individualOrFamily'
                      control={control}
                      render={({ field }) => (
                        <RadioGroup
                          aria-labelledby='Individual/Family'
                          row
                          {...field}
                        >
                          <FormControlLabel
                            value='individual'
                            control={<Radio />}
                            label='Individual'
                          />
                          <FormControlLabel
                            value='family'
                            control={<Radio />}
                            label='Family'
                          />
                        </RadioGroup>
                      )}
                    />
                  </Grid>
                  <Grid item md={GridValue}>
                    <FmAutoComplete
                      name='companyMedicalInsuranceDependencyId'
                      control={control}
                      options={dependency}
                      label='Dependency'
                      displayField='companyMedicalInsuranceDependencyName'
                      optionFields={['companyMedicalInsuranceDependencyName']}
                      valueKey='companyMedicalInsuranceDependencyId'
                    />
                  </Grid>
                </Grid>
              </GlassCard>

              <HeadingBox
                title='Beneficiary Details'
                icon={<Icon icon='mdi:person-box-outline' />}
              />

              <GlassCard className='p-4'>
                <Grid container spacing={2}>
                  <Grid item md={GridValue}>
                    <FmSearchableSelect
                      name='employeeId'
                      setSearchValue={setregionSearchValue}
                      control={control}
                      apiUrl='employees'
                      onChangeProp={handleEmployeeSelection}
                      headerField={['Employee Code', 'Employee Name']}
                      labelField={['employeeCode', 'firstName']}
                      showField={['employeeCode', 'firstName']}
                      valueField='employeeId'
                      label='Employee'
                      defaultValue={{
                        employeeId: editData?.employeeId,
                        firstName: editData?.employeeName,
                      }}
                    />
                  </Grid>
                  <Grid item md={GridValue}>
                    <TextField label='Ref' fullWidth variant='filled' />
                  </Grid>
                  <Grid item md={GridValue}>
                    <FmTextField
                      name='firstName'
                      label='First Name'
                      control={control}
                      readOnly
                    />
                  </Grid>
                  <Grid item md={GridValue}>
                    <FmTextField
                      name='gender'
                      label='Gender'
                      readOnly
                      control={control}
                    />
                  </Grid>
                  <Grid item md={GridValue}>
                    <FmSearchableSelect
                      name='nationalityName'
                      label='Nationality'
                      apiUrl='nationalities'
                      control={control}
                      labelField={['nationalityName']}
                      showField={['nationalityName']}
                      valueField='nationalityId'
                      defaultValue={{
                        nationalityId: editData?.nationalityId,
                        nationalityName: editData?.nationalityName,
                      }}
                    />{' '}
                  </Grid>
                  <Grid item md={GridValue}>
                    <FmTextField
                      name='maritalStatusName'
                      label='Marital Status'
                      readOnly
                      control={control}
                    />
                  </Grid>
                  <Grid item md={GridValue}>
                    <FmDatePicker
                      name='dob'
                      readOnly
                      label='Date of Birth'
                      control={control}
                    />
                  </Grid>
                  <Grid item md={GridValue}>
                    <FmTextField
                      name='age'
                      label='Age'
                      readOnly
                      control={control}
                      onChange={(e) => handleAgeChange(e.target.value)}
                    />{' '}
                  </Grid>
                  <Grid item md={4}>
                    <FmAutoComplete
                      name='companyMedicalInsuranceAgeBandId'
                      label='Age Band.'
                      options={ageBands}
                      displayField='companyMedicalInsuranceAgeBandName'
                      defaultValue={
                        selectedAgeBand?.companyMedicalInsuranceAgeBandId
                      }
                      optionFields={['companyMedicalInsuranceAgeBandName']}
                      valueKey='companyMedicalInsuranceAgeBandId'
                      control={control}
                    />
                  </Grid>
                </Grid>
              </GlassCard>

              <HeadingBox
                title='Residence Permit / Passport / EID Details'
                icon={<Icon icon='bi:building-slash' />}
              />

              <GlassCard className='p-4'>
                <Grid container spacing={2}>
                  <Grid item md={GridValue}>
                    <FmTextField
                      name='employeeDetailId'
                      label='Employee Detail Id'
                      control={control}
                    />
                  </Grid>
                  <Grid item md={GridValue}>
                    <FmTextField
                      name='passportNumber'
                      label='Passport No.'
                      control={control}
                    />
                  </Grid>
                  <Grid item md={GridValue}>
                    <FmTextField
                      name='visaIssuedFrom'
                      label='Visa Issued From'
                      control={control}
                    />
                  </Grid>
                  <Grid item md={GridValue}>
                    <TextField label='UID No.' fullWidth variant='filled' />
                  </Grid>
                  <Grid item md={GridValue}>
                    <FmTextField
                      name='documentId'
                      label='File No'
                      control={control}
                    />
                  </Grid>
                </Grid>
              </GlassCard>

              <HeadingBox
                title='Costing Details'
                icon={<Icon icon='solar:wallet-money-linear' />}
              />

              <GlassCard className='p-4'>
                <Grid container spacing={2}>
                  <Grid item md={GridValue}>
                    <FmTextField
                      name='principal'
                      label='Principal'
                      control={control}
                    />
                  </Grid>
                  <Grid item md={GridValue}>
                    <FmTextField
                      name='maternity'
                      label='Maternity'
                      control={control}
                      onChange={(ev) =>
                        handleCalculations(ev.target.value, 'maternity')
                      }
                    />
                  </Grid>
                  <Grid item md={GridValue}>
                    <FmTextField
                      name='vat'
                      label='VAT'
                      onChange={(ev) =>
                        handleCalculations(ev.target.value, 'vat')
                      }
                      control={control}
                    />
                  </Grid>
                  <Grid item md={GridValue}>
                    <FmTextField name='total' label='Total' control={control} />
                  </Grid>
                </Grid>
              </GlassCard>
              <GlassCard className='p-4'>
                <ActionButtons
                  onSubmit={handleSubmit(handleEditMedicalInsurance)}
                  onCancel={() => navigate(-1)}
                  submitLoading={editLoading}
                  onReset={reset}
                  submitText='Update'
                  cancelText='Go Back'
                />
              </GlassCard>
            </Stack>
          </LocalizationProvider>
        </form>
      )}
    </DashboardLayout>
  );
};

export default EditCompanyMedicalInsurancePolicy;
