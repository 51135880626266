import { Box } from '@mui/material';
import React, { useEffect, useState } from 'react';
import { useLocation, useNavigate } from 'react-router-dom';
import { toast } from 'react-toastify';
import Crud_Service from '../../../../apis/CrudService.jsx';
import TableActionButtons from '../../../../components/_form/TableActionButtons.jsx';
import FmMRTDataTable from '../../../../components/FmMRTDataTable.tsx';
import useTableLogic from '../../../../components/helpers/MRTUseTableLogic.tsx';
import Icons from '../../../../utils/Icon.jsx';
import GlassCard from '../../../../components/small/GlassCard.tsx';
import { StatusBadge } from '../../../../components/_form/FormElements.jsx';
import MuiDialogOne from '../../../../components/_mui/MuiDialogOne.jsx';
import InternalSalesInvoiceChecklist from './InternalSalesInvoiceChecklist.tsx';
import { useForm } from 'react-hook-form';

const ListInternalInvoicesAR = () => {
  const navigation = useNavigate();
  const location = useLocation();
  const [openChecklist, setOpenChecklist] = useState(false);
  const { setValue } = useForm();

  const [data, setData] = useState({});
  const crud = new Crud_Service();
  const [selectedSalesOrderIds, setSelectedSalesOrderIds] = useState([]);
  const [, setCreatingRow] = useState(null);
  const [loader, setLoader] = useState(true);

  const {
    rows,
    isLoading,
    pagination,
    sorting,
    columnFilters,
    globalFilter,
    tableRecordCounts,
    setPagination,
    setSorting,
    setColumnFilters,
    setGlobalFilter,
    fetchData,
    setSearchKeyword,
    searchKeyword,
  } = useTableLogic(`internalinvoices`);

  const handleEdit = (id) => {
    navigation(`${location.pathname}/edit/${id}`);
  };
  const handleDelete = async (props) => {
    await crud.remove('internalinvoices', props, (err, res) => {
      if (res?.status === 204) {
        toast.success('Internal Invoice Deleted Successfully');
        fetchData();
      }
    });
  };

  const [selectedSalesOrderId, setSelectedSalesOrderId] = useState(null);
  const [selectedInternalInvoiceId, setSelectedInternalInvoiceId] =
    useState(null);

  const [contractId, setContractId] = useState(null);
  const handleCheckList = (salesOrderId, internalInvoiceId) => {
    setSelectedSalesOrderId(salesOrderId);
    setSelectedInternalInvoiceId(internalInvoiceId);

    // Fetch contractId from salesorders API
    crud.getSingle('salesorders', salesOrderId, (_err, res) => {
      if (res) {
        setContractId(res?.data?.contractId);
        setOpenChecklist(true);
      } else {
      }
    });
  };

  const ActionData = [
    {
      name: 'Check List',
      icon: Icons.checklist,
      onClick: (props) => {
        handleCheckList(props?.salesOrderId, props?.internalInvoiceId);
      },
    },
    {
      name: 'Edit',
      icon: Icons.edit,
      onClick: (props) => {
        handleEdit(props.internalInvoiceId);
      },
    },
    {
      name: 'Delete',
      icon: Icons.delete,
      onClick: (props) => handleDelete(props.internalInvoiceId),
      danger: true,
    },
  ];
  // Define the columns
  const columns = [
    {
      header: 'Actions',
      size: 70,
      maxSize: 70,
      Cell: ({ row }) => (
        <Box className='flex gap-2'>
          {
            <TableActionButtons
              Actions={ActionData?.map((action) => ({
                ...action,
                onClick: () => action.onClick(row?.original),
              }))}
            />
          }
        </Box>
      ),
    },

    {
      accessorKey: 'invoiceStatusName',
      header: 'Invoice Status',
      size: 120,

      Cell: ({ cell }) => {
        const GetStatusColor = {
          'Ready To Invoice': 'green',
          Draft: 'red',
        };
        return (
          <StatusBadge
            title={cell?.getValue()}
            type={GetStatusColor[cell?.getValue()]}
          />
        );
      },
    },

    {
      accessorKey: 'internalInvoiceCode',
      header: 'Invoice Code',
      maxSize: 120,
    },

    {
      accessorKey: 'internalInvoiceDate',
      header: 'Invoice Date',
      maxSize: 120,
    },

    {
      accessorKey: 'clientName',
      header: 'Client',
    },
    {
      accessorKey: 'salesOrderCode',
      header: 'Sales Order',
      maxSize: 100,
    },

    {
      accessorKey: 'projectName',
      header: 'Project',
      maxSize: 100,
    },
    {
      accessorKey: 'contractName',
      header: 'Contract',
      maxSize: 100,
    },
    {
      accessorKey: 'dueDate',
      header: 'Due Date',
      maxSize: 90,
    },
  ];

  return (
    <>
      <GlassCard className='overflow-hidden h-full-css'>
        <FmMRTDataTable
          columns={columns}
          rows={rows}
          rowCount={tableRecordCounts}
          pagination={pagination}
          sorting={sorting}
          columnFilters={columnFilters}
          globalFilter={globalFilter}
          setPagination={setPagination}
          setSorting={setSorting}
          setColumnFilters={setColumnFilters}
          setGlobalFilter={setGlobalFilter}
          isLoading={isLoading}
          setSearchKeyword={setSearchKeyword}
          searchKeyword={searchKeyword}
          fetchData={fetchData}
          handleRowDoubleClick={(ev) =>
            handleEdit(ev?.original?.internalInvoiceId)
          }
          muiTableContainerProps={{ sx: { maxHeight: '100%', flex: 1 } }}
          showSerialNumber={true}
        />{' '}
      </GlassCard>

      {/* checklist modal */}
      <MuiDialogOne
        title='Check List - Internal Invoices'
        open={openChecklist}
        width='100%'
        onClose={() => setOpenChecklist(!openChecklist)}
      >
        <InternalSalesInvoiceChecklist
          contractItemId={contractId}
          salesOrderId={selectedSalesOrderId}
          internalInvoiceId={selectedInternalInvoiceId}
        />
      </MuiDialogOne>
      {/* check list modal */}
    </>
  );
};

export default ListInternalInvoicesAR;
