import React from 'react';
import { Button, Menu, Box } from '@mui/material';
import { Icon } from '@iconify/react/dist/iconify.js';
import MuiDialogOne from './../_mui/MuiDialogOne.jsx';

const getIcon = {
  alert: <Icon icon='twemoji:warning' width='50' height='50' />,
  draft: <Icon icon='fluent-color:drafts-24' width='50' height='50' />,
};

const ButtonModalAlert = ({
  title,
  message,
  onConfirm,
  icon = 'alert',
  buttonText,
  ...props
}) => {
  const [anchorEl, setAnchorEl] = React.useState(null);
  const open = Boolean(anchorEl);

  const handleClick = (event) => {
    setAnchorEl(event.currentTarget);
  };

  const handleClose = (event) => {
    setAnchorEl(null);
  };

  return (
    <>
      <Button onClick={handleClick} {...props}>
        {buttonText}
      </Button>
      <MuiDialogOne open={open} onClose={handleClose} width='400px'>
        <Box className='py-4'>
          <Box className='flex items-center flex-col text-center gap-4'>
            <Box>{getIcon[icon]}</Box>
            <Box className='flex-1 flex flex-col gap-3'>
              <h3 className='font-bold'>{title}</h3>
              <p className='opacity-75'>
                {message || 'Are you sure you want to delete this?'}
              </p>
            </Box>
          </Box>

          <div className='flex justify-center items-center gap-2 mt-4'>
            <Button
              variant='outlined'
              color='error'
              className='px-2 py-1'
              onClick={handleClose}
            >
              No
            </Button>
            <Button
              onClick={() => {
                onConfirm();
                handleClose();
              }}
              variant='contained'
              sx={{
                fontWeight: 700,
                padding: '4px 8px',
                fontSize: '0.875rem',
              }}
              className='px-2 py-1'
              color='error'
            >
              Yes
            </Button>
          </div>
        </Box>
      </MuiDialogOne>
    </>
  );
};

export default ButtonModalAlert;
