import React, { useState } from 'react';
import GlassCard from '../../../../components/small/GlassCard.tsx';
import MRTExpandTableStyle from '../../../../components/table/MRTExpandTableStyle.tsx';
import Icons from '../../../../utils/Icon.jsx';
import { Box, Button } from '@mui/material';
import { useNavigate, useLocation } from 'react-router-dom';
import FmMRTDataTable from '../../../../components/FmMRTDataTable.tsx';
import { toast } from 'react-toastify';
import dayjs from 'dayjs';
import useLoader from '../../../../components/helpers/UseLoader.tsx';
import FmSearchableSelect from '../../../../components/_mui/FmSearchableSelect.tsx';
import TableActionButtons from '../../../../components/_form/TableActionButtons.jsx';
import FmDatePicker from '../../../../components/_mui/FmDatePicker.tsx';
import FmTextField from '../../../../components/_mui/FmTextField.tsx';
import FmViewDateField from '../../../../components/_mui/FmViewDateField.tsx';
import TextElipseTooltip from '../../../../components/datagrid/TextElipseTooltip.tsx';
import Crud_Service from '../../../../apis/CrudService.jsx';
import useTableLogic from '../../../../components/helpers/MRTUseTableLogic.tsx';
const TextOverflow = ({ name, width = '180px' }) => {
  return (
    <Box sx={{ width: width }}>
      <TextElipseTooltip name={name} />
    </Box>
  );
};

const ListPettyCash = () => {

  const {
    rows,
    isLoading,
    pagination,
    sorting,
    columnFilters,
    globalFilter,
    tableRecordCounts,
    setPagination,
    setSorting,
    setColumnFilters,
    setGlobalFilter,
    fetchData,
    setSearchKeyword,
    searchKeyword,
  } = useTableLogic('pettycashtransactions');

  const navigate = useNavigate();
  const location = useLocation();
  const crud = new Crud_Service();
  const [editingRowId, setEditingRowId] = useState(null);
  const [editingRowData, setEditingRowData] = useState(null);
  const { startEditLoading, stopEditLoading } = useLoader();
  const handleView = (id) => {
    navigate(`${location.pathname}/view/${id}`);
  };

  const handleUpdateEvent = async (values) => {
    try {
      startEditLoading();
      const combinedData = {
        ...values,
        requestDate: dayjs(values?.requestDate).format('YYYY-MM-DD'),
        Status: 2,
      };
      await crud.update(
        'pettycashtransactions',
        editingRowId,
        combinedData,
        (_err, res) => {
          if (res?.status === 200) {
            stopEditLoading();
            fetchData();
            toast.success('Petty Cash Updated Successfully');
            setEditingRowData(null);
            setEditingRowId(null);
          } else {
            stopEditLoading();
            toast.error('Failed to update');
          }
        }
      );
    } catch (error) {
      stopEditLoading();
      toast.error('An error occurred while updating');
    }
  };
  const handleDelete = async (props) => {
    await crud.remove(
      'pettycashtransactions',
      props?.original?.pettyCashTransactionId,
      (__err, res) => {
        if (res?.status === 204) {
          toast.success('Petty Cash Deleted Successfully');
          fetchData();
        } else {
        }
      }
    );
  };

  const ActionData = [
    {
      name: 'Edit',
      icon: Icons.edit,
      onClick: (props) => {
        handleView(props?.original?.pettyCashTransactionId);
      },
    },

    {
      name: 'Delete',
      icon: Icons.delete,
      onClick: (props) => handleDelete(props),
    },
  ];
  // Define the columns
  const columns = [
    {
      field: 'action',
      header: 'Actions',
      sortable: false,
      hideable: false,
      disableClickEventBubbling: false,
      size: 100,

      Cell: (params) => {
        return (
          <Box className='flex gap-2'>
            {editingRowId === params?.row?.original?.pettyCashTransactionId ? (
              <>
                <Button onClick={handleSubmit(handleUpdateEvent)}>Save</Button>
                <Button onClick={handleCancel}>Cancel</Button>
              </>
            ) : (
              <TableActionButtons
                Actions={ActionData?.map((action) => ({
                  ...action,
                  onClick: () => action.onClick(params.row),
                }))}
                selectedRow={params.row}
                setSelectedRow={() => { }}
              />
            )}
          </Box>
        );
      },
    },
    {
      accessorKey: 'bankName',
      header: 'Bank Account',
      size: 100,
      enableEditing: true,
      Cell: ({ cell }) => {
        const salesItemDetails: any = rows?.find(
          (item) =>
            item?.pettyCashTransactionId ===
            cell?.row?.original?.pettyCashTransactionId
        );
        return editingRowId === cell?.row?.original?.pettyCashTransactionId ? (
          <FmSearchableSelect
            name='bankId'
            control={control}
            apiUrl='banks'
            valueField='bankId'
            labelField={['bankCode', 'bankName']}
            showField={['bankName']}
            label='Bank'
            defaultValue={{
              bankId: editingRowData?.bankId,
              bankName: editingRowData?.bankName,
            }}
          />
        ) : (
          <TextOverflow name={salesItemDetails?.bankName || ''} width='100px' />
        );
      },
    },
    {
      accessorKey: 'companyId',
      header: 'Company',
      enableEditing: true,
      size: 100,
      Cell: ({ cell }) => {
        const cashAndBankDetails: any = rows?.find(
          (item) =>
            item?.pettyCashTransactionId ===
            cell?.row?.original?.pettyCashTransactionId
        );
        return editingRowId === cell?.row?.original?.pettyCashTransactionId ? (
          <FmSearchableSelect
            name='companyId'
            control={control}
            apiUrl='companies'
            valueField='companyId'
            headerField={['Company Code', 'Company Name']}
            labelField={['companyCode', 'companyName']}
            showField={['companyName']}
            label='Company'
            defaultValue={{
              companyId: editingRowData?.companyId,
              companyName: editingRowData?.companyName,
            }}
          />
        ) : (
          <TextOverflow name={cashAndBankDetails?.companyName || ''} />
        );
      },
    },
    {
      accessorKey: 'requestDate',
      header: 'Request Date',
      enableEditing: true,
      size: 150,
      Cell: ({ cell }) => {
        return editingRowId === cell?.row?.original?.pettyCashTransactionId ? (
          <FmDatePicker
            name='requestDate'
            label='Request Date'
            control={control}
            required
          />
        ) : (
          FmViewDateField({ value: cell?.row?.original?.requestDate })
        );
      },
    },
    {
      accessorKey: 'branchId',
      header: 'Branch',
      enableEditing: true,
      Cell: ({ cell }) => {
        const cashAndBankDetails: any = rows?.find(
          (item) =>
            item?.pettyCashTransactionId ===
            cell?.row?.original?.pettyCashTransactionId
        );
        return editingRowId === cell?.row?.original?.pettyCashTransactionId ? (
          <FmSearchableSelect
            name='branchId'
            control={control}
            apiUrl='branches'
            valueField='branchId'
            labelField={['branchName']}
            showField={['branchName']}
            label={'Branch'}
            defaultValue={{
              branchId: editingRowData?.branchId,
              branchName: editingRowData?.branchName,
            }}
          />
        ) : (
          cashAndBankDetails?.branchName || ''
        );
      },
    },
    {
      accessorKey: 'jurisdictionId',
      header: 'Jurisdiction',
      enableEditing: true,
      Cell: ({ cell }) => {
        const cashAndBankDetails: any = rows?.find(
          (item) =>
            item?.pettyCashTransactionId ===
            cell?.row?.original?.pettyCashTransactionId
        );
        return editingRowId === cell?.row?.original?.pettyCashTransactionId ? (
          <FmSearchableSelect
            name='jurisdictionId'
            control={control}
            apiUrl='jurisdictions'
            valueField='jurisdictionId'
            labelField={['jurisdictionName']}
            showField={['jurisdictionName']}
            label={'Jurisdiction'}
            defaultValue={{
              jurisdictionId: editingRowData?.jurisdictionId,
              jurisdictionName: editingRowData?.jurisdictionName,
            }}
          />
        ) : (
          cashAndBankDetails?.jurisdictionName || ''
        );
      },
    },
    {
      accessorKey: 'narration',
      header: 'Narration',
      enableEditing: true,
      Cell: ({ cell }) => {
        const cashAndBankDetails: any = rows?.find(
          (item) =>
            item?.pettyCashTransactionId ===
            cell?.row?.original?.pettyCashTransactionId
        );
        return editingRowId === cell?.row?.original?.pettyCashTransactionId ? (
          <FmTextField name='narration' control={control} label='Narration' />
        ) : (
          cashAndBankDetails?.narration || ''
        );
      },
    },

  ];

  return (
    <>
      <GlassCard className='h-full'>
        <MRTExpandTableStyle enableFullHeight>
          <FmMRTDataTable
            columns={columns}
            enableRowSelection={false}
            rows={rows}
            rowCount={tableRecordCounts}
            editingRow={editingRowId}
            pagination={pagination}
            sorting={sorting}
            columnFilters={columnFilters}
            globalFilter={globalFilter}
            setPagination={setPagination}
            setSorting={setSorting}
            setColumnFilters={setColumnFilters}
            setGlobalFilter={setGlobalFilter}
            isLoading={isLoading}
            setSearchKeyword={setSearchKeyword}
            searchKeyword={searchKeyword}
            fetchData={fetchData}
            handleRowDoubleClick={(e) =>
              handleView(e?.original?.pettyCashTransactionId)
            }
            muiTableContainerProps={{
              sx: {
                maxHeight: '100%',
                flex: 1,
              },
            }}
            showSerialNumber={true}
          />
        </MRTExpandTableStyle>
      </GlassCard>
    </>
  );
};

export default ListPettyCash;
