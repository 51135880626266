import { yupResolver } from '@hookform/resolvers/yup';
import { Box, Grid } from '@mui/material';
import { AdapterDayjs } from '@mui/x-date-pickers/AdapterDayjs';
import { LocalizationProvider } from '@mui/x-date-pickers/LocalizationProvider';
import dayjs from 'dayjs';
import React, { useEffect } from 'react';
import { useForm } from 'react-hook-form';
import { useTranslation } from 'react-i18next';
import { useNavigate } from 'react-router-dom';
import { toast } from 'react-toastify';
import * as Yup from 'yup';
import Crud_Service from '../../../apis/CrudService';
import { ActionButtons } from '../../../components/_form';
import FmAutoComplete from '../../../components/_mui/FmAutoComplete.tsx';
import FmDatePicker from '../../../components/_mui/FmDatePicker.tsx';
import FmSearchableSelect from '../../../components/_mui/FmSearchableSelect.tsx';
import FmTextField from '../../../components/_mui/FmTextField.tsx';
import DashboardLayout from '../../../components/DashboardLayout.tsx';
import useLoader from '../../../components/helpers/UseLoader.tsx';
import GlassCard from '../../../components/small/GlassCard.tsx';
import { CommonTrueFalse } from '../../../utils/CommonVariables.tsx';
import FmTelInput from '../../../components/_mui/FmTelInput.tsx';
import { CreateClientvalidationSchema } from '../../../utils/CommonvalidationSchemas.tsx';

const Createclient = () => {
  const navigate = useNavigate();
  const crud = new Crud_Service();

  const { handleSubmit, control, reset, setValue } = useForm({
    resolver: yupResolver(CreateClientvalidationSchema),
    mode: 'onChange',
  });
  const { createLoading, startCreateLoading, stopCreateLoading } = useLoader();
  const { t } = useTranslation();
  const [COA, setCOA] = React.useState(false);
  const [ICV, setICV] = React.useState(false);

  useEffect(() => {
    !COA && setValue('advanceAccountNeededInChartOfAccount', null);
    !COA && setValue('retentionAccountNeededInChartOfAccount', null);
    !ICV && setValue('icvCertifiedScore', '');
  }, [COA, ICV]);

  const handleSubmitClients = async (values) => {
    startCreateLoading();

    const combinedData = {
      ...values,
      icvCertifiedScore: values?.icvCertifiedScore || 0,
      ownername: values?.Ownername || 'test',
      advanceAccountNeededInChartOfAccount:
        values?.advanceAccountNeededInChartOfAccount || false,
      retentionAccountNeededInChartOfAccount:
        values?.retentionAccountNeededInChartOfAccount || false,
      tradeLicenseStartDate: dayjs(values?.tradeLicenseStartDate).isValid()
        ? dayjs(values.tradeLicenseStartDate).format('YYYY-MM-DD')
        : null,

      tradeLicenseEndDate: dayjs(values?.tradeLicenseEndDate).isValid()
        ? dayjs(values.tradeLicenseEndDate).format('YYYY-MM-DD')
        : null,
    };

    await crud.create(`clients`, combinedData, (_err, res) => {
      if (res?.status === 201) {
        toast.success(t('created_successfully'));
        navigate(-1);
        stopCreateLoading();
      } else {
        stopCreateLoading();
      }
    });
  };

  return (
    <DashboardLayout title='Create New Client'>
      <Box className='mx-auto' sx={{ maxWidth: '800px' }}>
        <GlassCard className='p-4'>
          <form onSubmit={handleSubmit(handleSubmitClients)}>
            <LocalizationProvider dateAdapter={AdapterDayjs}>
              <Grid container spacing={2}>
                <Grid item md={6}>
                  <FmTextField
                    name='clientName'
                    control={control}
                    label='Name'
                    required
                  />
                </Grid>
                <Grid item md={6}>
                  <FmSearchableSelect
                    name='parentClientId'
                    control={control}
                    apiUrl='clients'
                    valueField='clientId'
                    headerField={['Code', 'Name']}
                    labelField={['clientCode', 'clientName']}
                    showField={['clientName']}
                    label='Parent Client'
                  />
                </Grid>
                <Grid item md={6}>
                  <FmTextField
                    name='clientIndustry'
                    control={control}
                    label='Client Industry'
                    required
                  />
                </Grid>
                <Grid item md={12}>
                  <FmTextField
                    name='headOfficeAddress'
                    control={control}
                    label='Head Office Address'
                    multiline
                    rows={3}
                    maxRows={4}
                    required
                  />
                </Grid>

                <Grid item md={6}>
                  <FmTextField
                    name='contactPerson'
                    control={control}
                    label='Contact Person'
                  />
                </Grid>

                <Grid item md={6}>
                  <FmTelInput
                    name='contactNumber'
                    control={control}
                    label='Contact Number'
                    onlyCountries={['AE']}
                  />
                </Grid>
                <Grid item md={6}>
                  <FmTextField
                    name='contactEmailId'
                    control={control}
                    label='Contact Email'
                    type='email'
                  />
                </Grid>
                <Grid item md={6}>
                  <FmTextField
                    name='ownerName'
                    control={control}
                    label='Owner Name'
                  />
                </Grid>
                {ICV && (
                  <Grid item md={6}>
                    <FmTextField
                      name='icvCertifiedScore'
                      control={control}
                      label='ICV Certified Score'
                    />
                  </Grid>
                )}
                {ICV && <Grid item md={6}></Grid>}

                <Grid item md={6}>
                  <FmAutoComplete
                    name='accountNeededInChartOfAccount'
                    control={control}
                    options={CommonTrueFalse}
                    label='Account Needed In Chart Of Account'
                    displayField='label'
                    onChange={(e) => setCOA(e)}
                    optionFields={['label']}
                    valueKey='value'
                  />
                </Grid>
                {COA && (
                  <>
                    <Grid item md={6}>
                      <FmAutoComplete
                        name='advanceAccountNeededInChartOfAccount'
                        control={control}
                        options={CommonTrueFalse}
                        label='Advance Account Needed In Chart Of Account'
                        displayField='label'
                        optionFields={['label']}
                        valueKey='value'
                      />
                    </Grid>
                    <Grid item md={6}>
                      <FmAutoComplete
                        name='retentionAccountNeededInChartOfAccount'
                        control={control}
                        options={CommonTrueFalse}
                        label='Retention Account Needed In Chart Of Account'
                        displayField='label'
                        optionFields={['label']}
                        valueKey='value'
                      />
                    </Grid>
                  </>
                )}
                <Grid item md={6}>
                  <FmAutoComplete
                    name='icvCertified'
                    control={control}
                    options={CommonTrueFalse}
                    label='ICV Certified'
                    displayField='label'
                    onChange={(e) => setICV(e)}
                    optionFields={['label']}
                    valueKey='value'
                  />
                </Grid>
                <Grid item md={6}>
                  <FmTextField
                    name='trnNumber'
                    control={control}
                    label='TRN Number'
                  />
                </Grid>
                <Grid item md={6}></Grid>
                <Grid item md={6}>
                  <FmDatePicker
                    name='tradeLicenseStartDate'
                    control={control}
                    label='Trade License Start Date'
                    defaultValue={null}
                  />
                </Grid>
                <Grid item md={6}>
                  <FmDatePicker
                    name='tradeLicenseEndDate'
                    control={control}
                    label='Trade License End Date'
                    defaultValue={null}
                  />
                </Grid>
              </Grid>
              <ActionButtons
                onSubmit={handleSubmit(handleSubmitClients)}
                onCancel={() => navigate(-1)}
                onReset={reset}
                submitLoading={createLoading}
                cancelLoading={false}
                cancelText={t('Cancel')}
                submitText={t('Create')}
              />
            </LocalizationProvider>
          </form>
        </GlassCard>
      </Box>
    </DashboardLayout>
  );
};

export default Createclient;
