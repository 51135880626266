import React, { useEffect, useState } from 'react';
import GlassCard from '../../../../components/small/GlassCard.tsx';
import { Grid } from '@mui/material';
import { useForm } from 'react-hook-form';
import FmTextField from '../../../../components/_mui/FmTextField.tsx';
import FmDatePicker from '../../../../components/_mui/FmDatePicker.tsx';
import FmSearchableSelect from '../../../../components/_mui/FmSearchableSelect.tsx';
import ActionButtons from '../../../../components/_form/ActionButtons.tsx';
import Crud_Service from '../../../../apis/CrudService.jsx';
import dayjs from 'dayjs';
import { toast } from 'react-toastify';
import useLoader from '../../../../components/helpers/UseLoader.tsx';
import { yupResolver } from '@hookform/resolvers/yup';
import { CBPettyCashValidationSchema } from '../../../../utils/CommonvalidationSchemas.tsx';
import FmFileInput from '../../../../components/_mui/FmFileInput.tsx';
import { useNavigate, useParams } from 'react-router-dom';

const GridSize = 3;

const EditCBR = (props) => {
  const { type } = props;
  const crud = new Crud_Service();
  const id = useParams().id;
  const { startCreateLoading, stopCreateLoading } = useLoader();
  const { control, handleSubmit, reset, getValues, setValue } = useForm({
    resolver: yupResolver(CBPettyCashValidationSchema),
    mode: 'onChange',
  });
  const { createLoading } = useLoader();
  const [loading, setLoading] = useState(false);
  const navigate = useNavigate();
  const [fileId, setFileId] = useState(null);
  const [cbr, setCbr] = useState({});

  useEffect(() => {
    if (type === 2) {
      fetchData('payments', id);
    }
    if (type === 1) {
      fetchData('financialtransactions', id);
    }
  }, [type, id]); // Added dependencies
  
  const fetchData = async (url, id) => {
    await crud.getSingle(url, id, (err, res) => {
      if (err) {
        return;
      }
      if (res?.status === 200) {
        reset(res?.data);
        setFileId(res?.data?.fileId);
        setCbr(res?.data);
      }
    });
  };
  const handleSubmitData = async (values) => {
    startCreateLoading();
    const combinedData = {
      ...values,
      paymentStatusId: 1,

      requestDate: dayjs(values?.requestDate).format('YYYY-MM-DD'),
      chequeDate: dayjs(values?.chequeDate).format('YYYY-MM-DD'),
      maturityDate: dayjs(values?.maturityDate).format('YYYY-MM-DD'),
      fileId: fileId,
      typeId: type,
    };
    await crud.update('financialtransactions', id, combinedData, (err, res) => {
      stopCreateLoading();
      if (err) {
        return;
      }
      if (res?.status === 200) {
        toast.success('Updated successfully');
        reset();
      } else {
      }
    });
  };

  const handleFileUpload = async (event) => {
    setLoading(true);
    const file = event.target.files[0];
    if (file) {
      const fileFormData = new FormData();
      fileFormData.append('file', file);
      fileFormData.append('fileTypeId', 33);

      try {
        const response = await new Promise((resolve, reject) => {
          crud.create('files/upload', fileFormData, (_err, res) => {
            if (res?.status === 200) {
              resolve(res);

              setLoading(false);
            } else {
              reject(new Error('File upload failed'));
              setLoading(false);
            }
          });
        });

        setFileId(response?.data?.fileId);
        setValue(`documentName`, response?.data?.originalFileName);
      } catch (error) {
        setLoading(false);
      } finally {
        setLoading(false);
      }
    }
  };
  return (
    <GlassCard className='p-3'>
      <form onSubmit={handleSubmit(handleSubmitData)}>
        <Grid container spacing={2}>
          <Grid item md={GridSize}>
            <FmSearchableSelect
              name='bankId'
              control={control}
              apiUrl='banks'
              valueField='bankId'
              headerField={['Bank Code', 'Bank Name']}
              labelField={['bankCode', 'bankName']}
              showField={['bankName']}
              defaultValue={cbr}
              label='Bank'
            />
          </Grid>

          <Grid item md={GridSize}>
            <FmSearchableSelect
              name='companyId'
              control={control}
              apiUrl='companies'
              valueField='companyId'
              headerField={['Company Code', 'Company Name']}
              labelField={['companyCode', 'companyName']}
              showField={['companyName']}
              defaultValue={cbr}
              label='Company'
            />
          </Grid>

          <Grid item md={GridSize}>
            <FmDatePicker
              name='requestDate'
              label='Request Date'
              control={control}
              required
            />
          </Grid>

          <Grid item md={GridSize}>
            <FmSearchableSelect
              name='branchId'
              control={control}
              apiUrl='branches'
              valueField='branchId'
              labelField={['branchName']}
              defaultValue={{
                branchId: cbr?.branchId,
                branchName: cbr?.branchName,
              }}
              showField={['branchName']}
              label={'Branch'}
            />
          </Grid>
          {type === 1 && (
            <Grid item md={GridSize}>
              <FmSearchableSelect
                name='salesInvoiceId'
                control={control}
                apiUrl='salesinvoices'
                defaultValue={cbr}
                valueField='salesInvoiceId'
                labelField={['salesInvoiceCode']}
                showField={['salesInvoiceCode']}
                label={'Sales Invoice'}
              />
            </Grid>
          )}
          {(type === 2 || type === 3) && (
            <>
              <Grid item md={GridSize}>
                <FmSearchableSelect
                  name='placeOfSupplyId'
                  control={control}
                  apiUrl='placeofsupplies'
                  valueField='placeOfSupplyId'
                  labelField={['placeOfSupplyName']}
                  defaultValue={{
                    placeOfSupplyId: cbr?.placeOfSupplyId,
                    placeOfSupplyName: cbr?.placeOfSupplyName,
                  }}
                  showField={['placeOfSupplyName']}
                  label={'Place Of Supply'}
                />
              </Grid>

              <Grid item md={GridSize}>
                <FmSearchableSelect
                  name='jurisdictionId'
                  control={control}
                  apiUrl='jurisdictions'
                  valueField='jurisdictionId'
                  defaultValue={{
                    jurisdictionId: cbr?.jurisdictionId,
                    jurisdictionName: cbr?.jurisdictionName,
                  }}
                  labelField={['jurisdictionName']}
                  showField={['jurisdictionName']}
                  label={'Jurisdiction'}
                />
              </Grid>
            </>
          )}

          <Grid item md={GridSize}>
            <FmSearchableSelect
              name='currencyId'
              control={control}
              apiUrl='currencies'
              valueField='currencyId'
              defaultValue={cbr}
              headerField={['Currency Code', 'Currency Name']}
              labelField={['currencyCode', 'currencyName']}
              showField={['currencyName']}
              label='Currency'
            />
          </Grid>

          <Grid item md={GridSize}>
            <FmTextField
              name='narration'
              multiline={true}
              rows={2}
              maxRows={3}
              control={control}
              label='Narration'
            />
          </Grid>
        </Grid>

        <ActionButtons
          onSubmit={handleSubmit(handleSubmitData)}
          onReset={reset}
          submitLoading={createLoading}
          submitText='Update'
        />
      </form>
    </GlassCard>
  );
};

export default EditCBR;
