import React, { useEffect, useState } from 'react';
import DashboardLayout from '../../../../components/DashboardLayout.tsx';
import { Box, Grid } from '@mui/material';
import GlassCard from '../../../../components/small/GlassCard.tsx';
import ActionButtons from '../../../../components/_form/ActionButtons.tsx';
import { useNavigate, useParams } from 'react-router-dom';
import Crud_Service from '../../../../apis/CrudService.jsx';
import { useForm } from 'react-hook-form';
import useLoader from '../../../../components/helpers/UseLoader.tsx';
import CommonLoader from '../../../../components/page/CommonLoader.jsx';
import FmTextField from '../../../../components/_mui/FmTextField.tsx';
import { CreateButton } from '../../../../components/small/Buttons.jsx';
import FmSearchableSelect from '../../../../components/_mui/FmSearchableSelect.tsx';
import { statusOptions } from '../../../../utils/CommonVariables.tsx';
import { toast } from 'react-toastify';
import dayjs from 'dayjs';
import FmTimePicker from '../../../../components/_mui/FmTimePicker.tsx';
import FmDatePicker from '../../../../components/_mui/FmDatePicker.tsx';
import { yupResolver } from '@hookform/resolvers/yup';
import { VehicleAdvertisementSchema } from '../../../../utils/CommonvalidationSchemas.tsx';

const EditAdvertisement = () => {
  const navigate = useNavigate();
  const crud = new Crud_Service();
  const [loading, setLoading] = useState(false);
  const [editData, setEditData] = useState(null);
  const { handleSubmit, control, setValue, watch,trigger,reset} = useForm({
    resolver: yupResolver(VehicleAdvertisementSchema),
    mode: 'onChange',
  });
  const { startEditLoading, stopEditLoading, editLoading } = useLoader();
  const { id } = useParams();
  const GridSize = 2.4;

  useEffect(() => {
    getOwnVehicles();
  }, []);

  const getOwnVehicles = async () => {
    setLoading(true);
    await crud.getSingle('vehicleadvertisements', id, (_err, res) => {
      if (res?.status === 200) {
        const data = res?.data;
        Object.entries({
          ...data,
        }).forEach(([key, value]) => {
          setValue(key, value);
        });
        setEditData(res?.data);
        setLoading(false);
      } else {
      }
    });
  };

  const handleUpdateAdvertisement = async (values) => {
    try {
      startEditLoading();
      const combinedData = {
        ...values,
        expiryDate: dayjs(values?.expiryDate).format('YYYY-MM-DD'),
        issueDate: dayjs(values?.issueDate).format('YYYY-MM-DD'),
        Status: 2,
      };
      await crud.update(
        'vehicleadvertisements',
        id,
        combinedData,
        (_err, res) => {
          if (res?.status === 200) {
            stopEditLoading();
            toast.success('Advertisement Updated Successfully');
            navigate(-1);
           
          } else {
            stopEditLoading();
          }
        }
      );
    } catch (error) {
      stopEditLoading();
      toast.error('An error occurred while updating the vehicle Report');
    }
  };
  const issueDate = watch('issueDate');
  return (
    <DashboardLayout
      title='Edit Advertisement'
      actionButtons={
        <>
          <Box className='flex flex-row items-center whitespace-nowrap gap-3'>
            <CreateButton
              name='Create'
              onClick={() => navigate('/admin/vehicles/advertisement/create')}
            />
          </Box>
        </>
      }
    >
      <GlassCard className='p-5'>
        {loading ? (
          <CommonLoader />
        ) : (
          <form onSubmit={handleSubmit(handleUpdateAdvertisement)}>
            <Grid container spacing={2}>
              <Grid item md={GridSize}>
                <FmSearchableSelect
                  name='vehicleId'
                  label='Vehicle'
                  apiUrl={'vehicles'}
                  valueField='vehicleId'
                  required
                  labelField={['vehicleCode', 'vehicleName']}
                  showField={['vehicleCode', 'vehicleName']}
                  control={control}
                  defaultValue={{
                    vehicleId: editData?.vehicleId,
                    vehicleName: editData?.vehicleName,
                  }}
                />
              </Grid>
              <Grid item md={GridSize}>
                <FmTextField
                  name='permitNumber'
                  required
                  label='Permit Number'
                  control={control}
                  pattern='Number'
                />
              </Grid>
              <Grid item md={GridSize}>
              <FmDatePicker
                name='issueDate'
                label='Issue Date'
                control={control}
                required
                onChangeProp={() => trigger("expiryDate")}
              />
            </Grid>
            <Grid item md={GridSize}>
              <FmDatePicker
                name='expiryDate'
                label='Expiry Date'
                control={control}
                onChangeProp={() => trigger("issueDate")}
                defaultValue={dayjs().add(1, "day")}
                minDate={issueDate?dayjs(issueDate):null}
                required
              />
            </Grid>

              <Grid item md={GridSize}>
                <FmTextField
                  name='remarks'
                  label='Remarks'
                  required
                  control={control}
                  multiline
                  rows={4}
                />
              </Grid>
              <Grid item md={12}>
                <ActionButtons
                  onSubmit={handleSubmit(handleUpdateAdvertisement)}
                  onReset={reset}
                  submitLoading={editLoading}
                  submitText='Update'
                  cancelText='Go Back'
                  onCancel={() => navigate(-1)}
                />
              </Grid>
            </Grid>
          </form>
        )}
      </GlassCard>
    </DashboardLayout>
  );
};

export default EditAdvertisement;
