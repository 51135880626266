
import { Box } from '@mui/material';
import React, { useEffect, useState } from 'react';
import { useFieldArray, useForm } from 'react-hook-form';
import { useNavigate, useParams } from 'react-router-dom';
import { toast } from 'react-toastify';
import Crud_Service from '../../../apis/CrudService';
import ActionButtons from '../../../components/_form/ActionButtons.tsx';
import FmFileInput from '../../../components/_mui/FmFileInput.tsx';
import useLoader from '../../../components/helpers/UseLoader.tsx';
import GlassCard from '../../../components/small/GlassCard.tsx';
import ScopeNotesTextEditor from '../../../components/page/ScopeNotesTextEditor.tsx';


const ViewContractScopeNotes = (props) => {
  const { id } = props;
  const { control, handleSubmit, reset, setValue } = useForm({
    defaultValues: {
      scopeNotes: [
        {
          subject: '',
          fileId: null,
        },
      ],
    },
  });

  const { fields } = useFieldArray({
    control,
    name: 'scopeNotes',
  });

  const crud = new Crud_Service();
  const { createLoading, startCreateLoading, stopCreateLoading } = useLoader();
  const [uploadLoading, setUploadLoading] = useState({});
  const [uploadedFileData, setUploadedFileData] = useState({});
  const contractId = useParams().id;
  const navigate = useNavigate();
  const [scopeNotes, setScopeNotes] = useState(null);
  const [checkContractScope, setCheckContractScope] = useState(false);
  const [data, setData] = useState([]);

  useEffect(() => {
    crud.getAll(
      `contractscopenote?contractId=${id ? id : contractId}`,
      {},
      (err, res) => {
        if (res?.data?.data?.length > 0) {
          setCheckContractScope(true);
          setScopeNotes(res?.data?.data[0]?.subject);
          setUploadedFileData(res?.data?.data);
          setData(res?.data?.data);
        } else {
          setCheckContractScope(false);
          setUploadedFileData({});
          setScopeNotes(null)
          setData([])
        }
      }
    );
  }, []);

  const onSubmit = async (values) => {
    startCreateLoading();
    const formattedValues = {
      ...values,
      contractId: Number(contractId),

      subject: scopeNotes || '',
      fileId: uploadedFileData['0']?.fileId || null, // Extracting fileId safely
    };
    const UpdateformattedValues = {
      contractId: Number(contractId),

      subject: scopeNotes || '',
      fileId: values?.fileId || uploadedFileData['0']?.fileId || null, // Extracting fileId safely
      status: 2,
    };
    if (checkContractScope) {
      await crud.update(
        'contractscopenote',
        data[0]?.contractScopeNoteId,
        UpdateformattedValues,
        (err, res) => {
          stopCreateLoading();

          if (err) {
            toast.error(err);
            stopCreateLoading();
            return;
          }

          if (res?.status === 201) {
            toast.success('Scope Notes Updated Successfully');
            stopCreateLoading();
          } else {
            stopCreateLoading();
          }
        }
      );
    } else {
      await crud.create('contractscopenote', formattedValues, (err, res) => {
        stopCreateLoading();

        if (err) {
          toast.error(err);
          stopCreateLoading();
          return;
        }

        if (res?.status === 201) {
          toast.success('Scope Notes Created Successfully');
          stopCreateLoading();
        } else {
          stopCreateLoading();
        }
      });
    }
  };

  const handleFileUpload = async (e, index) => {
    const file = e.target.files[0];
    if (!file) return;

    setUploadLoading((prev) => ({ ...prev, [index]: true }));

    const formData = new FormData();
    formData.append('file', file);
    formData.append('fileTypeId', 64);

    await crud.create('/files/upload', formData, (err, res) => {
      setUploadLoading((prev) => ({ ...prev, [index]: false }));

      if (err || res?.status !== 200) {
        toast.error('File upload failed');
        return;
      }

      setUploadedFileData((prev) => ({
        ...prev,
        [index]: res?.data,
      }));
    });
  };

  return (
    <GlassCard className='p-2'>
      <form onSubmit={handleSubmit(onSubmit)}>
        {fields?.map((field, index) => (
          <Box key={field?.id}>
            <Box className='flex justify-between items-center mb-2'>
              <FmFileInput
                name={`scopeNotes[${index}].attachment`}
                documentName={
                  uploadedFileData[index]?.fileName || 'Upload Scope Notes'
                }
                disabled={id ? true : false}
                onChange={(e) => handleFileUpload(e, index)}
                loading={uploadLoading[index] || false}
              />
            </Box>
            <ScopeNotesTextEditor
              scopeNotes={scopeNotes}
              setScopeNotes={setScopeNotes}
              readOnly={id ? true : false}
            />
          </Box>
        ))}

        {!id && (
          <Box className='mt-5'>
            <ActionButtons
              submitText={checkContractScope ? 'Save' : 'Create'}
              onSubmit={handleSubmit(onSubmit)}
              onCancel={() => navigate(-1)}
              submitLoading={createLoading}
            />
          </Box>
        )}
      </form>
    </GlassCard>
  );
};
  

  export default ViewContractScopeNotes;