import { Box } from '@mui/material';
import React from 'react';
import { useParams } from 'react-router-dom';
import TableSearch from '../../../../components/_form/TableSearch.jsx';
import FmViewDateField from '../../../../components/_mui/FmViewDateField.tsx';
import DataTable from '../../../../components/DataTable.tsx';
import useTableLogic from '../../../../components/helpers/UseTableLogic.tsx';
import GlassCard from '../../../../components/small/GlassCard.tsx';
import CurrencyView from '../../../../components/_mui/FmCurrencyView.tsx';

const ViewContractRevenueSchedule = (props) => {
  const { contractId } = props;
  const {
    rows,
    pageSize,
    pageNumber,
    setSearchKeyword,
    isLoading,
    tableRecordCounts,
    searchKeyword,
    fetchData,
    handlePaginationModelChange,
    handleSortModelChange,
  } = useTableLogic('contractrevenueschedules', 'contractId', contractId);

  const totalExpectedRevenue = rows.reduce(
    (acc, row) => acc + row.expectedRevenue,
    0
  );
  const totalActualRevenue = rows.reduce(
    (acc, row) => acc + row.actualRevenue,
    0
  );

  const columns = [
    //TODO: for Future Use
    // {
    //   field: 'action',
    //   headerName: '',
    //   width: 50,
    //   sortable: false,
    //   disableClickEventBubbling: false,

    //   renderCell: (params) => {
    //     return (
    //       <TableActionButtons
    //         Actions={ActionData?.map((action) => ({
    //           ...action,
    //           onClick: () => action.onClick(params.row),
    //         }))}
    //         selectedRow={params.row}
    //       />
    //     );
    //   },
    // },
    // {
    //   field: 'contractRevenueScheduleCode',
    //   headerName: 'Revenue Schedule Code',
    //   width: 200,
    //   flex: 1,
    // },
    // {
    //   field: 'contractName',
    //   headerName: 'Contract Name',
    //   flex: 1,
    // },
    // {
    //   field: 'contractCost',
    //   headerName: 'Contract Cost',
    //   flex: 1,
    // },
    {
      field: 'contractBudget',
      headerName: 'Contract Budget',
      flex: 1,
      renderCell: (params) => {
        return <CurrencyView value={params?.row?.contractBudget} />
      },
    },
    {
      field: 'startDate',
      headerName: 'start Date',
      flex: 1,
      renderCell: (params) => {
        return <FmViewDateField value={params?.value} />;
      },
    },
    {
      field: 'endDate',
      headerName: 'End Date',
      flex: 1,
      renderCell: (params) => {
        return <FmViewDateField value={params?.value} />;
      },
    },
    {
      field: 'expectedRevenue',
      headerName: 'Expected Revenue',
      flex: 1,
      renderCell: (params) => {
        return <CurrencyView value={params?.row?.expectedRevenue} />
      },
    },
    {
      field: 'actualRevenue',
      headerName: 'Actual Revenue',
      flex: 1,
      renderCell: (params) => {
        return <CurrencyView value={params?.row?.actualRevenue} />
      },
    },
  ];

  return (
    <>
      <GlassCard>
        <Box className='h-[350px]'>
          <DataTable
            rows={rows}
            columns={columns}
            loading={isLoading}
            getRowClassName={(params) =>
              `${params.row.status === 0 && 'bg-red-50'}`
            }
            sortingMode='server'
            paginationMode='server'
            onPaginationModelChange={(model) =>
              handlePaginationModelChange(model)
            }
            onSortModelChange={(model) => handleSortModelChange(model)}
            page={pageNumber - 1}
            pageSize={pageSize}
            rowCount={tableRecordCounts?.total}
            fetchData={fetchData}
            slots={{
              toolbar: () => (
                <>
                  <Box
                    className='p-2 w-full flex justify-between items-center'
                    sx={{
                      borderBottom: '1px solid',
                      borderColor: 'border.main',
                    }}
                  >
                    <Box sx={{ maxWidth: '250px' }}>
                      <TableSearch
                        placeholder='Search'
                        fullWidth
                        setSearchKeyword={setSearchKeyword}
                        searchValue={searchKeyword}
                      />
                    </Box>
                    <Box
                      display='flex'
                      justifyContent='flex-end'
                      alignItems='center'
                      gap={2}
                    >
                      <Box className='font-semibold' color={'primary.main'}>
                        Expected Total : <CurrencyView value={totalExpectedRevenue} />
                      </Box>
                      <Box className='font-semibold' color={'secondary.main'}>
                        Actual Total : <CurrencyView value={totalActualRevenue} />

                      </Box>
                    </Box>
                  </Box>
                </>
              ),
            }}
          />
        </Box>
      </GlassCard>
    </>
  );
};

export default ViewContractRevenueSchedule;
