import React, { useEffect } from 'react';
import DashboardLayout from './../../../../components/DashboardLayout.tsx';
import CreateSalesOrderList from './CreateSalesOrderList.tsx';
import Nav from './../../../../utils/Nav.tsx';
import { Box } from '@mui/material';
import { CreateButton } from '../../../../components/small/Buttons.jsx';
import { useNavigate } from 'react-router-dom';

const CreateSalesOrder = (props) => {
  const { type } = props;
  const [getData] = React.useState(false);
  const navigate = useNavigate();

  const getTitleByType = (type) => {
    switch (type) {
      case 2:
      case 1:
      case 4:
        return 'Sales Orders';
      case 3:
        return 'Sales Returns';
      default:
        return 'Sales Quotation';
    }
  };

  useEffect(() => {
    document.title = getTitleByType(type);
  }, [type]);

  return (
    <DashboardLayout
      title={getTitleByType(type)}
      hasSubmenu={type !== 4}
      menu={
        type === 1
          ? Nav[12].child[11].children
          : type === 2 || type === 3
          ? Nav[7].child[3].children
          : ''
      }
      actionButtons={
        <>
          <Box className='flex flex-row items-center whitespace-nowrap gap-3'>
            <CreateButton name='Create' onClick={() => navigate('create')} />
          </Box>
        </>
      }
    >
      <CreateSalesOrderList type={type} getData={getData} />
    </DashboardLayout>
  );
};

export default CreateSalesOrder;
