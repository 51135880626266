import { yupResolver } from '@hookform/resolvers/yup';
import { Icon } from '@iconify/react';
import { Box, Button } from '@mui/material';
import dayjs from 'dayjs';
import { t } from 'i18next';
import React, { useEffect, useState } from 'react';
import { useForm } from 'react-hook-form';
import { useNavigate } from 'react-router-dom';
import { toast } from 'react-toastify';
import apiInstance from '../../../apis/ApiService.jsx';
import Crud_Service from '../../../apis/CrudService.jsx';
import TableSearch from '../../../components/_form/TableSearch.jsx';
import FmDatePicker from '../../../components/_mui/FmDatePicker.tsx';
import FmSearchableSelect from '../../../components/_mui/FmSearchableSelect.tsx';
import FmTextField from '../../../components/_mui/FmTextField.tsx';
import FmViewDateField from '../../../components/_mui/FmViewDateField.tsx';
import { ToolbarBoxesFlex } from '../../../components/boxes/Boxes.jsx';
import DashboardLayout from '../../../components/DashboardLayout.tsx';
import FmMRTDataTable from '../../../components/FmMRTDataTable.tsx';
import DataTableToolbarButtons from '../../../components/helpers/DataTableToolbarButtons.tsx';
import useTableLogic from '../../../components/helpers/MRTUseTableLogic.tsx';
import TableDropDown from '../../../components/helpers/TableDropDown.tsx';
import useDateFormatter from '../../../components/helpers/useDateFormatter.jsx';
import GlassCard from '../../../components/small/GlassCard.tsx';
import { requestForQuoteSchemaTableEdit } from '../../../utils/CommonvalidationSchemas.tsx';

const PurchaseOrderViewList = () => {
  const {
    rows,
    rowCount,
    isLoading,
    pagination,
    sorting,
    columnFilters,
    globalFilter,
    tableRecordCounts,
    setPagination,
    setSorting,
    setColumnFilters,
    setGlobalFilter,
    fetchData,
    setSearchKeyword,
    searchKeyword,
  } = useTableLogic('requestforquotes');
  const crud = new Crud_Service();
  const navigate = useNavigate();
  const fromateDate = useDateFormatter();
  const [editingRowData, setEditingRowData] = useState(null);
  const [editingRowId, setEditingRowId] = useState(null);
  const defaultValues = {
    requestForQuoteStartDate: editingRowData?.requestForQuoteStartDate
      ? editingRowData.requestForQuoteStartDate
      : null,
    requestForQuoteEndDate: editingRowData?.requestForQuoteEndDate
      ? editingRowData.requestForQuoteEndDate
      : null,
  };
  const {
    handleSubmit,
    control,
    setValue,
    formState: { errors },
  } = useForm({
    defaultValues,
    resolver: yupResolver(requestForQuoteSchemaTableEdit),
    mode: 'onChange',
  });
  useEffect(() => {
    document.title = `Request Quote Management  | Procurement`;
  }, []);

  //Edit Purchase Order
  const handleCancel = () => {
    setEditingRowId(null);
    setEditingRowData(null);
  };
  const handleEdit = (row) => {
    // Set the editing row ID and data
    setEditingRowId(row?.original?.requestForQuoteId);
    setEditingRowData({ ...row?.original });

    // Loop through the keys of row.original and set form values using setValue
    Object.keys(row?.original).forEach((key) => {
      setValue(key, row?.original[key]);
    });
  };

  const handleEditPurchaseOrdre = async (props) => {
    navigate(`edit/${props?.requestForQuoteId}`, {
      state: { locationData: props },
    });
  };

  const handleComparisonPage = async (props) => {
    navigate(
      `request-for-quote-management-comparison/${props?.requestForQuoteId}`,
      {
        state: { locationData: props },
      }
    );
  };

  const handleDelete = async (props) => {
    await crud.remove(
      'requestforquotes',
      props?.original?.requestForQuoteId,
      (_err, res) => {
        if (res?.status === 204) {
          toast.success(t('RFQ_deleted_successfully'));
          fetchData();
        } else {
        }
      }
    );
  };

  const handleDownload = async (props) => {
    const downloadUrl = await apiInstance.getFiles(
      `requestforquotes/exportpdf?requestForQuoteId=${props?.requestForQuoteId}`
    );

    const link = document.createElement('a');
    link.href = downloadUrl;
    link.setAttribute('download', 'RequestForQuote.pdf');
    document.body.appendChild(link);
    link.click();
    document.body.removeChild(link);
  };

  const ActionData = [
    {
      name: 'RFQ Items',
      icon: <Icon icon='solar:list-broken' />,
      onClick: (props) => handleEditPurchaseOrdre(props?.original),
    },
    {
      name: 'RFQ Comparison',
      icon: <Icon icon='teenyicons:git-compare-outline' />,
      onClick: (props) => handleComparisonPage(props?.original),
    },
    {
      name: 'Download',
      icon: <Icon icon='material-symbols:download' />,
      onClick: (props) => handleDownload(props),
    },
    {
      name: 'Delete',
      icon: <Icon icon='solar:trash-bin-minimalistic-broken' />,
      onClick: (props) => handleDelete(props),
      danger: true,
    },
  ];
  const handleCreateLPO = async (props) => {
    const combinedata = {
      requestForQuoteId: props?.requestForQuoteId,
    };

    await crud.create(
      'localpurchaseorders/requestforquoteid',
      combinedata,
      (err, res) => {
        if (err) {
          return;
        }
        if (res?.status === 201) {
          toast.success(t('LPO_Created_successfully'));
          navigate(
            `/procurement/purchase-order-view/edit/${res?.data?.localPurchaseOrderId}`
          );
          fetchData();
        }
      }
    );
  };
  const ProjectUpdateActiviy = async (values) => {
    const combinedData = {
      ...values,
      requestForQuoteStartDate: dayjs(values?.requestForQuoteStartDate).format(
        'YYYY-MM-DD'
      ),
      requestForQuoteEndingDate: dayjs(
        values?.requestForQuoteEndingDate
      ).format('YYYY-MM-DD'),
      status: 2,
    };

    await crud.update(
      'requestforquotes',
      editingRowId,
      combinedData,
      (err, res) => {
        if (res?.status === 200) {
          toast.success(t('RFQ_Updated_Successfully'));
          fetchData();
          setEditingRowData(null);
          setEditingRowId(null);
        } else {
        }
      }
    );
  };

  const columns = [
    {
      header: 'Actions',
      enableColumnPinning: true,
      enableEditing: false,
      enableSorting: false,
      Cell: ({ row }) => (
        <Box className='flex gap-2'>
          {editingRowId === row?.original?.requestForQuoteId ? (
            <>
              <Button onClick={handleSubmit(ProjectUpdateActiviy)}>Save</Button>
              <Button onClick={handleCancel}>Cancel</Button>
            </>
          ) : (
            <Box className='flex gap-2'>
              <>
                <TableDropDown
                  Actions={ActionData?.map((action) => ({
                    ...action,
                    onClick: () => action.onClick(row),
                  }))}
                  selectedRow={row}
                  setSelectedRow={() => {}}
                />
                <Button
                  className='whitespace-nowrap mx-2 p-1 px-3'
                  onClick={() => handleCreateLPO(row?.original)}
                  variant='contained'
                  disabled={row?.original?.isMRGenerated === true}
                >
                  {row?.original?.isMRGenerated === true
                    ? 'Created LPO'
                    : 'Create LPO'}
                </Button>
              </>
            </Box>
          )}
        </Box>
      ),
    },
    {
      accessorKey: 'requestForQuoteNumber',
      header: 'Request For Quote Number',
      size: 150,
      Cell: ({ cell }) => {
        return editingRowId === cell?.row?.original?.requestForQuoteId ? (
          <FmTextField
            name='requestForQuoteNumber'
            control={control}
            label='Request For Quote Number'
          />
        ) : (
          cell?.getValue() || ''
        );
      },
    },
    {
      accessorKey: 'materialRequisitionCode',
      header: 'Material Requisition Code',
      size: 150,
      Cell: ({ cell }) => {
        return editingRowId === cell?.row?.original?.requestForQuoteId ? (
          <FmTextField
            name='materialRequisitionCode'
            control={control}
            label='Material Requisition Code'
          />
        ) : (
          cell?.getValue() || ''
        );
      },
    },
    {
      accessorKey: 'companyName',
      header: 'Company Name',
      Cell: ({ cell }) => {
        return editingRowId === cell?.row?.original?.requestForQuoteId ? (
          <FmSearchableSelect
            name='companyId'
            control={control}
            apiUrl='companies'
            valueField='companyId'
            labelField={['companyName', 'companyCode']}
            showField={['companyName']}
            label={'Company'}
            defaultValue={{
              companyName: editingRowData?.companyName,
              companyName: editingRowData?.companyName,
            }}
          />
        ) : (
          cell?.getValue() || ''
        );
      },
    },
    {
      accessorKey: 'divisionName',
      header: 'Division Name',
      Cell: ({ cell }) => {
        return editingRowId === cell?.row?.original?.requestForQuoteId ? (
          <FmSearchableSelect
            name='divisionId'
            control={control}
            apiUrl='divisions'
            valueField='divisionId'
            labelField={['divisionName', 'divisionCode']}
            showField={['divisionName']}
            label={'Company'}
            defaultValue={{
              divisionId: editingRowData?.divisionId,
              divisionName: editingRowData?.divisionName,
            }}
          />
        ) : (
          cell?.getValue() || ''
        );
      },
    },
    {
      accessorKey: 'projectName',
      header: 'Project Name',
      Cell: ({ cell }) => {
        return editingRowId === cell?.row?.original?.requestForQuoteId ? (
          <FmSearchableSelect
            name='projectId'
            control={control}
            apiUrl='projects'
            valueField='projectId'
            labelField={['projectName', 'projectCode']}
            showField={['projectName']}
            label={'Project'}
            defaultValue={{
              projectId: editingRowData?.projectId,
              projectName: editingRowData?.projectName,
            }}
          />
        ) : (
          cell?.getValue() || ''
        );
      },
    },
    {
      accessorKey: 'regionName',
      header: 'Region Name',
      Cell: ({ cell }) => {
        return editingRowId === cell?.row?.original?.requestForQuoteId ? (
          <FmSearchableSelect
            name='regionId'
            control={control}
            apiUrl='regions'
            valueField='regionId'
            labelField={['regionName', 'regionCode']}
            showField={['regionName']}
            label={'Project'}
            defaultValue={{
              regionId: editingRowData?.regionId,
              regionName: editingRowData?.regionName,
            }}
          />
        ) : (
          cell?.getValue() || ''
        );
      },
    },
    {
      accessorKey: 'requestForQuoteStartDate',
      header: 'Request For Quote Start Date',
      Cell: ({ row, cell }) => {
        return editingRowId === row?.original?.requestForQuoteId ? (
          <FmDatePicker
            name='requestForQuoteStartDate'
            label='Request For Quote Start Date'
            control={control}
            required
          />
        ) : (
          FmViewDateField({
            value: cell?.row?.original?.requestForQuoteStartDate,
          })
        );
      },
    },
    {
      accessorKey: 'requestForQuoteEndingDate',
      header: 'Request For Quote Ending Date',
      Cell: ({ row, cell }) => {
        return editingRowId === row?.original?.requestForQuoteId ? (
          <FmDatePicker
            name='requestForQuoteEndingDate'
            label='Request For Quote End Date'
            control={control}
            required
          />
        ) : (
          FmViewDateField({
            value: cell?.row?.original?.requestForQuoteEndingDate,
          })
        );
      },
    },
  ];

  const handleSelectionModelChange = () => {
    // const selectedRowsData = newSelection.map((id) =>
    //   rows.find((row) => row.id === id)
    // );
    // setSideRow(selectedRowsData);
  };

  const SearchFilteredBoxes = () => {
    return (
      <ToolbarBoxesFlex>
        <Box className='w-full' sx={{ maxWidth: '280px' }}>
          <TableSearch
            placeholder='Search'
            fullWidth
            setSearchKeyword={setSearchKeyword}
            searchValue={searchKeyword}
          />
        </Box>
        <DataTableToolbarButtons />
      </ToolbarBoxesFlex>
    );
  };

  return (
    <DashboardLayout
      title={'Request Quote Management'}
      //TODO
      // actionButtons={
      //   <CreateButton
      //     name='New'
      //     onClick={() => Navigate(`${Location.pathname}/create`)}
      //   />
      // }
    >
      {/* <OrderViewSidebarBoxes side={<Requisitions matReqData={sideRow} />}> */}

      {
        //TODO
        /* <GlassCard className='h-full-css'> <Tabs
            value={value}
            onChange={handleChange}
            textColor='secondary'
            indicatorColor='secondary'
          >
            {TabsList?.map((item) => {
              return <Tab value={item.id} label={item.name} key={item.name} />;
            })}
          </Tabs> </GlassCard> */
      }

      <GlassCard className='h-full'>
        <FmMRTDataTable
          rows={rows}
          columns={columns}
          enableRowSelection={false}
          rowCount={tableRecordCounts}
          editingRow={editingRowId}
          pagination={pagination}
          sorting={sorting}
          columnFilters={columnFilters}
          globalFilter={globalFilter}
          setPagination={setPagination}
          setSorting={setSorting}
          setColumnFilters={setColumnFilters}
          setGlobalFilter={setGlobalFilter}
          isLoading={isLoading}
          setSearchKeyword={setSearchKeyword}
          searchKeyword={searchKeyword}
          muiTableContainerProps={{
            sx: {
              maxHeight: '100%',
              flex: 1,
            },
          }}
          // handleRowDoubleClick={(e) => handleEdit(e)}
        />
      </GlassCard>
      {/* </OrderViewSidebarBoxes> */}
    </DashboardLayout>
  );
};

export default PurchaseOrderViewList;
