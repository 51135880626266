import { Box, Grid, IconButton } from '@mui/material';
import React, { useState, useEffect, useRef } from 'react';
import { useForm } from 'react-hook-form';
import { toast } from 'react-toastify';
import Crud_Service from '../../../../apis/CrudService.jsx';
import FmSearchableSelect from '../../../../components/_mui/FmSearchableSelect.tsx';
import DashboardLayout from '../../../../components/DashboardLayout.tsx';
import GlassCard from '../../../../components/small/GlassCard.tsx';
import FmMRTDataTable from '../../../../components/FmMRTDataTable.tsx';
import useTableLogic from '../../../../components/helpers/MRTUseTableLogic.tsx';
import TableActionButtons from '../../../../components/_form/TableActionButtons.jsx';
import MRTSaveAndCancel from '../../../../components/table/MRTSaveAndCancel.tsx';
import { Icon } from '@iconify/react';
import useLoader from '../../../../components/helpers/UseLoader.tsx';
import { CreateButton } from '../../../../components/small/Buttons.jsx';
import MuiDialogOne from '../../../../components/_mui/MuiDialogOne.jsx';
import ActionButtons from '../../../../components/_form/ActionButtons.tsx';
import FmTextField from '../../../../components/_mui/FmTextField.tsx';
import { yupResolver } from '@hookform/resolvers/yup';
import { passwordValidationSchema } from '../../../../utils/CommonvalidationSchemas.tsx';
import FmStack from '../../../../components/layout/FmStack.tsx';

const CreateUser = () => {
  const crud = new Crud_Service();
  const { control, handleSubmit, reset, trigger } = useForm({
    mode: 'onChange',
    resolver: yupResolver(passwordValidationSchema),
  });
  const { control: roleControl, setValue, getValues } = useForm();
  const [isOpen, setIsOpen] = useState(false);
  const {
    rows,
    isLoading,
    pagination,
    setRows,
    sorting,
    setSearchKeyword,
    columnFilters,
    globalFilter,
    tableRecordCounts,
    setPagination,
    setSorting,
    setGlobalFilter,
    searchKeyword,
    setColumnFilters,
    fetchData,
  } = useTableLogic(`loginusers`, ['employeeId', 'cadreId'], ['0', '0']);
  const [editingRowId, setEditingRowId] = useState(null);
  const [editingRowData, setEditingRowData] = useState(null);
  const { startCreateLoading, stopCreateLoading, createLoading } = useLoader();
  const [selectedRole, setSelectedRole] = useState(null);

  const [passwordConditions, setPasswordConditions] = useState({
    minLength: false,
    hasUppercase: false,
    hasLowercase: false,
    hasSpecialChar: false,
    notSimilarToUsername: false,
  });
  const [password, setPassword] = useState('');
  const [showPasswordVisible, setShowPasswordVisible] = useState(false);
  const [showPasswordVisibleTwo, setShowPasswordVisibleTwo] = useState(false);
  const [isPasswordValid, setIsPasswordValid] = useState(false);

  const passwordRef = useRef('');

  const handlePasswordChange = (e) => {
    const value = e.target.value;
    passwordRef.current = value; // Update without causing re-render

    const username = getValues('loginUserName');

    trigger('confirmPassword');

    const newConditions = {
      minLength: value.length >= 8,
      hasUppercase: /[A-Z]/.test(value),
      hasLowercase: /[a-z]/.test(value),
      hasSpecialChar: /[!@#$%^&*(),.?":{}|<>]/.test(value),
      notSimilarToUsername: value !== username,
    };

    // Only update state if conditions actually change (avoids unnecessary re-renders)
    setPasswordConditions((prev) => {
      if (JSON.stringify(prev) !== JSON.stringify(newConditions)) {
        return newConditions;
      }
      return prev;
    });
  };

  useEffect(() => {
    setIsPasswordValid(Object.values(passwordConditions).every(Boolean));
  }, [passwordConditions]);

  const handleSaveUser = async (values) => {
    startCreateLoading();
    await crud.create('loginusers/add-user-with-role', values, (_err, res) => {
      if (res?.status === 201) {
        toast.success('User Role Created Successfully.');
        stopCreateLoading();
        setIsOpen(false);
        reset();
        fetchData();
      } else {
        stopCreateLoading();
      }
    });
  };
  const handleEdit = async (row) => {
    setEditingRowId(row?.loginUserId);
    setEditingRowData({ ...row });

    Object.entries({
      ...row,
    }).forEach(([key, value]) => {
      setValue(key, value);
    });
  };
  const handleDeleteClick = async (props) => {
    await crud.remove('loginusers', props?.loginUserId, (err, res) => {
      if (res?.status === 204) {
        toast.success('User Role Deleted Successfully');
        fetchData();
        setEditingRowData(null);
        setEditingRowId(null);
      } else {
      }
    });
  };
  const [rowState, setRowState] = useState({
    newRowId: null,
    isCreatingRow: false,
  });

  const ActionData = [
    {
      name: 'Edit',
      icon: <Icon icon='solar:pen-new-round-linear' />,
      onClick: (props) => {
        handleEdit(props);
      },
    },
    {
      name: 'Delete',
      icon: <Icon icon='mi:delete' />,
      onClick: (props) => handleDeleteClick(props),
      danger: true,
    },
  ];

  const handleCancel = (row) => {
    if (row?.original?.loginUserId === rowState?.newRowId) {
      setRows((prevRows) =>
        prevRows.filter((r) => r?.loginUserId !== rowState?.newRowId)
      );
      setRowState((prevState) => ({
        ...prevState,
        newRowId: null,
        isCreatingRow: false,
      }));
    } else {
      setEditingRowId(null);
    }
    reset();
  };

  const handleEditRole = async () => {
    await crud.update(
      `loginuserroles`,
      editingRowData?.loginUserId,
      {
        roleId: selectedRole?.roleId,
        loginUserId: editingRowData?.loginUserId,
        status: 2,
      },
      (err, res) => {
        if (err) {
          stopCreateLoading();
          toast.error(err);
        }
        if (res?.status === 200) {
          stopCreateLoading();
          setEditingRowId(null);
          fetchData();
          toast.success('User Role Updated Successfully');
        } else {
          stopCreateLoading();
        }
      }
    );
  };
  const columns = [
    {
      header: 'Actions',
      enableColumnPinning: true,
      enableEditing: false,
      enableSorting: false,
      size: 80,
      Cell: ({ row }) => {
        const isNewRow = row?.original?.loginUserId === rowState?.newRowId;
        const isEditing =
          editingRowId === row?.original?.loginUserId || isNewRow;

        return (
          <Box className='flex gap-2'>
            {isEditing ? (
              <MRTSaveAndCancel
                onClickCancel={() => handleCancel(row)}
                onClickSave={handleEditRole}
              />
            ) : (
              <TableActionButtons
                Actions={ActionData?.map((action) => ({
                  ...action,
                  onClick: () => action.onClick(row?.original),
                }))}
              />
            )}
          </Box>
        );
      },
    },

    {
      accessorKey: 'loginUserName',
      header: 'User',
      enableEditing: true,
      Cell: ({ row }) => {
        return row?.original?.loginUserName || '';
      },
    },
    {
      accessorKey: 'employeeName',
      header: 'Employee Name',
      enableEditing: true,
      Cell: ({ row }) => {
        return row?.original?.employeeName || '';
      },
    },
    {
      accessorKey: 'role',
      header: 'Role',
      enableEditing: true,
      Cell: ({ row }) => {
        const isNewRow = row?.original?.loginUserId === rowState?.newRowId;
        const isEditing =
          editingRowId === row?.original?.loginUserId || isNewRow;
        return isEditing ? (
          <FmSearchableSelect
            name='roleId'
            label='Role'
            apiUrl='roles'
            control={roleControl}
            onChangeProp={setSelectedRole}
            defaultValue={{
              roleId: row?.original?.roleId,
              roleName: row?.original?.role,
            }}
            valueField='role'
            labelField={['roleName']}
            showField={['roleName']}
          />
        ) : (
          row?.original?.role || ''
        );
      },
    },

    {
      accessorKey: 'createdDate',
      header: 'Created Date',
    },
    {
      accessorKey: 'updateDate',
      header: 'Updated Date',
    },
  ];

  return (
    <>
      <DashboardLayout
        title='Role Management'
        hasSubmenu
        menu={[
          {
            menuName: 'Create Role Permissions',
            url: '/fm/role-management',
          },
          {
            menuName: 'Create Roles',
            url: '/fm/role-management/Create-Roles',
          },
          {
            menuName: 'Create Permission',
            url: '/fm/role-management/Create-Permissions',
          },
          {
            menuName: 'Create User',
            url: '/fm/role-management/create-user',
          },
        ]}
        actionButtons={
          <CreateButton
            name='Create'
            onClick={() => {
              setIsOpen(true);
              reset();
            }}
          />
        }
      >
        <GlassCard className='h-full overflow-hidden'>
          <FmMRTDataTable
            columns={columns}
            enableRowSelection={false}
            rows={rows || []}
            rowCount={tableRecordCounts}
            editingRow={editingRowId}
            pagination={pagination}
            setSearchKeyword={setSearchKeyword}
            sorting={sorting}
            columnFilters={columnFilters}
            globalFilter={globalFilter}
            setPagination={setPagination}
            setSorting={setSorting}
            isLoading={isLoading}
            searchKeyword={searchKeyword}
            fetchData={fetchData}
            muiTableContainerProps={{
              sx: {
                maxHeight: '100%',
                flex: 1,
              },
            }}
          />
        </GlassCard>

        <MuiDialogOne
          title='Create User'
          open={isOpen}
          onClose={() => {
            setIsOpen(false);
            setPasswordConditions({
              minLength: false,
              hasUppercase: false,
              hasLowercase: false,
              hasSpecialChar: false,
              notSimilarToUsername: false,
            });
          }}
        >
          <form onSubmit={handleSubmit(handleSaveUser)}>
            <FmStack spacing={2}>
              <Box>
                <Grid container spacing={2}>
                  <Grid item md={6}>
                    <FmTextField
                      name='loginUserName'
                      control={control}
                      label='User Name'
                      autoComplete='off'
                    />
                  </Grid>
                </Grid>
              </Box>

              <Box>
                <Grid container spacing={2}>
                  <Grid item md={6}>
                    <Box className='flex gap-1 w-full items-center'>
                      <Box className='flex-1'>
                        <FmTextField
                          name='loginUserPassword'
                          control={control}
                          label='Password'
                          type={showPasswordVisible ? 'text' : 'password'}
                          onChange={handlePasswordChange}
                          autoComplete='off'
                        />
                      </Box>
                      <IconButton
                        onClick={() =>
                          setShowPasswordVisible(!showPasswordVisible)
                        }
                        sx={{ fontSize: '20px', padding: '5px' }}
                      >
                        {showPasswordVisible ? (
                          <Icon icon='material-symbols:visibility-off' />
                        ) : (
                          <Icon icon='material-symbols:visibility' />
                        )}
                      </IconButton>
                    </Box>
                  </Grid>
                  <Grid item md={6}>
                    <Box className='flex gap-1 w-full items-center'>
                      <Box className='flex-1'>
                        <FmTextField
                          name='confirmPassword'
                          control={control}
                          label='Confirm Password'
                          type={showPasswordVisibleTwo ? 'text' : 'password'}
                          autoComplete='off'
                        />
                      </Box>
                      <IconButton
                        onClick={() =>
                          setShowPasswordVisibleTwo(!showPasswordVisibleTwo)
                        }
                        sx={{ fontSize: '20px', padding: '5px' }}
                      >
                        {showPasswordVisibleTwo ? (
                          <Icon icon='material-symbols:visibility-off' />
                        ) : (
                          <Icon icon='material-symbols:visibility' />
                        )}
                      </IconButton>
                    </Box>
                  </Grid>
                </Grid>
              </Box>

              <Box>
                <Grid container spacing={2}>
                  <Grid item md={6}>
                    <FmSearchableSelect
                      name='roleId'
                      label='Role'
                      apiUrl='roles'
                      control={control}
                      valueField='roleId'
                      labelField={['roleCode', 'roleName']}
                      showField={['roleCode', 'roleName']}
                    />
                  </Grid>

                  <Grid item md={6}>
                    <FmSearchableSelect
                      name='employeeId'
                      label='Employees'
                      apiUrl='employees'
                      control={control}
                      valueField='employeeId'
                      headerField={['Code', 'Name', 'Email']}
                      labelField={['employeeCode', 'fullName', 'emailId']}
                      showField={['employeeCode', 'fullName', 'emailId']}
                    />
                  </Grid>
                </Grid>
              </Box>
            </FmStack>

            <Box className='mt-3 flex justify-start'>
              <div className='text-[11px] italic'>
                {!isPasswordValid && (
                  <>
                    <span className='font-semibold mb-1 flex '>
                      Password Requirements:
                    </span>
                    <ul
                      style={{
                        margin: '0px 0 0',
                        padding: 'inherit',
                        listStyle: 'inherit',
                        paddingLeft: '15px',
                      }}
                    >
                      {!passwordConditions.minLength && (
                        <li>Minimum 8 characters</li>
                      )}

                      {!passwordConditions.hasUppercase && (
                        <li>include uppercase</li>
                      )}

                      {!passwordConditions.hasLowercase && (
                        <li>include lowercase</li>
                      )}

                      {!passwordConditions.hasUppercase ||
                        (!passwordConditions.hasLowercase && (
                          <li>include uppercase and lowercase letters</li>
                        ))}

                      {!passwordConditions.hasSpecialChar && (
                        <li>special symbols</li>
                      )}
                      {!passwordConditions.notSimilarToUsername && (
                        <li>must not be similar to the username</li>
                      )}
                    </ul>
                  </>
                )}
              </div>
            </Box>

            <ActionButtons
              onSubmit={handleSubmit(handleSaveUser)}
              onCancel={() => setIsOpen(false)}
              submitLoading={createLoading}
              submitText='Create'
              disabled={!isPasswordValid}
            />
          </form>
        </MuiDialogOne>
      </DashboardLayout>
    </>
  );
};

export default CreateUser;
