import React, { useState } from 'react';
import MuiMenuItem from '../_mui/MuiMenuItem';
import Menu from '@mui/material/Menu';
import Tooltip from '@mui/material/Tooltip';
import IconButton from '@mui/material/IconButton';
import Zoom from '@mui/material/Zoom';
import { useNavigate } from 'react-router-dom';
import Crud_Service from '../../apis/CrudService';
import Icons from '../../utils/Icon';
import { toast } from 'react-toastify';
const DropDown = ({ data, title }) => {
  const navigate = useNavigate();
  const crud = new Crud_Service();
  const [historyData, setHistoryData] = useState([]);

  const [anchorEl, setAnchorEl] = React.useState(null);
  const open = Boolean(anchorEl);
  const handleClick = async (event) => {
    setAnchorEl(event.currentTarget);
    fetchHistoryData();
  };
  const fetchHistoryData = async () => {
    try {
      await crud.getSingle('userpagehistory', '', (err, res) => {
        const data = res?.data?.data?.map((item) => ({
          url: item?.url,
          pageName: item.pageName,
        }));

        setHistoryData(data);
      }); // Replace with your API URL
    } catch (error) {
      toast.error('Error fetching history data:', error);
    }
  };

  const handleClose = () => {
    setAnchorEl(null);
  };

  return (
    <>
      <Tooltip title={title} arrow TransitionComponent={Zoom}>
        <IconButton
          sx={{ color: 'inherit', padding: '5px' }}
          onClick={handleClick}
        >
          {Icons.history}
        </IconButton>
      </Tooltip>

      <Menu
        id={`basic-menu`}
        anchorEl={anchorEl}
        open={open}
        onClose={handleClose}
        PaperProps={{
          sx: { maxWidth: 320, mt: '5px' },
        }}
        MenuListProps={{ sx: { pt: 0 } }}
        disableScrollLock={true}
        anchorOrigin={{
          vertical: 'bottom',
          horizontal: 'left',
        }}
        transformOrigin={{
          vertical: 'top',
          horizontal: 'center',
        }}
      >
        {historyData?.map((menu, i) => (
          <MuiMenuItem
            onClick={() => navigate(menu.url)}
            key={i}
            name={menu.pageName}
          />
        ))}
      </Menu>
    </>
  );
};

export default DropDown;
