import { yupResolver } from '@hookform/resolvers/yup';
import { Icon } from '@iconify/react/dist/iconify.js';
import { Box, Button, CircularProgress } from '@mui/material';
import React, { useEffect, useReducer, useRef, useState } from 'react';
import { useForm } from 'react-hook-form';
import { useHotkeys } from 'react-hotkeys-hook';
import { useNavigate, useParams } from 'react-router-dom';
import { toast } from 'react-toastify';
import apiInstance from '../../../apis/ApiService.jsx';
import Crud_Service from '../../../apis/CrudService';
import FmMRTDataTable from '../../../components/FmMRTDataTable.tsx';
import ActionButtons from '../../../components/_form/ActionButtons.tsx';
import FmAutoComplete from '../../../components/_mui/FmAutoComplete.tsx';
import FmFileInput from '../../../components/_mui/FmFileInput.tsx';
import FmTextField from '../../../components/_mui/FmTextField.tsx';
import MuiDialogOne from '../../../components/_mui/MuiDialogOne.jsx';
import useTableLogic from '../../../components/helpers/MRTUseTableLogic.tsx';
import useLoader from '../../../components/helpers/UseLoader.tsx';
import useCommonFetchApi from '../../../components/helpers/useCommonFetchApi.tsx';
import GlassCard from '../../../components/small/GlassCard.tsx';
import { AMCTableValidationSchema } from '../../../utils/CommonvalidationSchemas.tsx';
import TotalBox from '../../Presales/EstimationSheetForAMC/TotalBox.jsx';
import axios from 'axios';
import NewUploadModalBox from '../../../components/upload/NewUploadModelBox.tsx';
import FmSearchableSelect from '../../../components/_mui/FmSearchableSelect.tsx';
import CurrencyView from '../../../components/_mui/FmCurrencyView.tsx';

const ViewDetails = ({ handleBackButton, TableData }) => {

  const [amcEstimationSheet, setAMCEstimationSheetId] = useState(null);
  const [amcSheetDetailsCall, setAmcSheetDetailsCall] = useState(false);
  const [tenderCheckLoader, setTenderCheckLoader] = useState(false);
  const [uploadBoxLoader, setUploadBoxLoader] = useState(false);
  const [previewdData, setPreviewData] = useState([]);
  const [files, setFiles] = useState([]);
  const [uploadedFileIds, setUploadedFileIds] = useState([]);
  const [productChange, setProductChange] = useState(false);
  const [total, setTotalAmount] = useState(0);
  const navigate = useNavigate();
  const id = useParams()?.id;

  useEffect(() => {
    getEstimationAMC();
  }, []);

  const getEstimationAMC = async () => {
    setTenderCheckLoader(true);
    setTotalBoxLoader(true);
    await crud.getAll(`estimationamcsheets`, { tenderId: id }, (err, res) => {
      if (err) {
        setTenderCheckLoader(false);
      }
      if (res?.status === 200) {
        setEstimationAMC(res?.data);
        setAMCEstimationSheetId(res?.data?.data[0]);
        setAmcSheetDetailsCall(true);
        setAmcSheetsPercent((prev) => ({
          ...prev,
          overHeadOverAllPercent: res?.data?.overHeadOverAllPercent || 0,
          overHeadCost: res?.data?.overHeadCost || 0,
          contingencyOverAllPercent: res?.data?.contingencyOverAllPercent || 0,
          contingencyCost: res?.data?.contingencyCost || 0,
          otherOverAllPercent: res?.data?.otherOverAllPercent,
          otherCost: res?.data?.otherCost,
          markUpOverAllPercent: res?.data?.markUpOverAllPercent || 0,
          markUpCost: res?.data?.markUpCost || 0,
          discountOverAllPercent: res?.data?.discountOverAllPercent || 0,
          discountCost: res?.data?.discountCost,
          negotiationPercent: res?.data?.negotiationPercent || 0,
          negotiationCost: res?.data?.negotiationCost,
          totalEstimationCost: res?.data?.totalEstimationCost || 0,
          note: res?.data?.note || '',
        }));
        setTotalBoxLoader(false);
        setTenderCheckLoader(false);
      }
    });
  };

  const {
    rows,
    setRows,
    isLoading,
    pagination,
    sorting,
    columnFilters,
    globalFilter,
    setSearchKeyword,
    searchKeyword,
    setPagination,
    setSorting,
    setColumnFilters,
    setGlobalFilter,
    fetchData,
  } = useTableLogic(
    `estimationamcsheetdetails/${amcEstimationSheet?.estimationAmcSheetId || 0
    }/amc-details?sortDirection=asc`
  );
  const [estimationAMC, setEstimationAMC] = useState({});

  useEffect(() => {
    if (total === 0) {
      setTotalAmount(rows[0]?.totalAmcCostDetails?.totalAmcAmount || 0);
    }
  }, [rows])

  const {
    reset,
    control,
    setValue,
    getValues,
    formState: { isValid },
  } = useForm({
    resolver: yupResolver(AMCTableValidationSchema),
    mode: 'all',
    defaultValues: {
      sspDetail: '',
      productCode: '',
      workManPowerName: '',
      description: '',
      lineItemName: '',
      unitOfMeasurementId: 0,
      quantity: 0,
      months: 0,
      unitSellingPrice: 0,
      subTotalAmount: 0,
      actualUnitPrice: 0,
      overHeadPercent: 0,
      overHeadUnitPrice: 0,
      contingencyPercent: 0,
      contingencyUnitPrice: 0,
      markUpPercent: 0,
      markupUnitPrice: 0,
      discountPercent: 0,
      discountUnitPrice: 0,
      negotiationPercent: 0,
      negotiationUnitPrice: 0,
      unitPrice: 0,
      totalAmcAmount: 0,
      currencyId: 0,
    },
  });

  useEffect(() => {
    if (amcSheetDetailsCall) {
      fetchData();
    }
  }, [amcSheetDetailsCall]);

  const [amcSheetsPercent, setAmcSheetsPercent] = useState({
    note: '',
    overHeadOverAllPercent: 0,
    overHeadCost: 0,
    contingencyOverAllPercent: 0,
    contingencyCost: 0,
    otherOverAllPercent: 0,
    otherCost: 0,
    markUpOverAllPercent: 0,
    markUpCost: 0,
    discountOverAllPercent: 0,
    discountCost: 0,
    negotiationPercent: 0,
    negotiationCost: 0,
    totalEstimationCost: 0,
  });
  const [fieldEdited, setFieldEdited] = useState(false);
  const [totalBoxLoader, setTotalBoxLoader] = useState(true);
  const [openUpload, setOpenUpload] = useState(false);
  const [uploadLoading, setUploadLoading] = useState(false);
  const [uploadedfileData, setUploadedfileData] = useState(null);
  const { startCreateLoading, stopCreateLoading, createLoading } = useLoader();
  const crud = new Crud_Service();
  const [editingRowData, setEditingRowData] = useState(null);
  const [editingRowId, setEditingRowId] = useState(null);
  const [selectedRate, setSelectedRate] = useState(null);
  const [currentRowIndex, setCurrentRowIndex] = useState(0);
  const [editingRowIndex, setEditingRowIndex] = useState(null);
  const [newRowCreated, setNewRowCreated] = useState(false);
  const [amcSubtotal, setAmcSubtotal] = useState(0);
  const [loader, setLoader] = useState(false);
  const [rowSelection, setRowSelection] = useState({});
  const [selectedRowIds, setSelectedRowIds] = useState([]);
  const [rowState, setRowState] = useState({
    newRowId: null,
    isCreatingRow: false,
  });
  const firstColumnInputRef = useRef(null);
  const [stateError, setStateError] = useState(false);



  const { data: estimationTypes } = useCommonFetchApi('estimationTypes') || [];
  const { data: manpowerrates } = useCommonFetchApi('manpowerrates');
  const { data: unitofmeasurements } = useCommonFetchApi('unitofmeasurements');
  const { data: currencies } = useCommonFetchApi('currencies');
  const totalReducer = (state, action) => {
    switch (action.type) {
      case 'UPDATE_UNIT_SELLING_PRICE_TOTAL':
        return {
          ...state,
          unitSellingPriceTotal: action.payload,
        };
      case 'UPDATE_SUB_TOTAL':
        return {
          ...state,
          subTotalAmount: action.payload,
        };
      case 'UPDATE_TOTAL_OVER_HEAD_UNIT_PRICE':
        return {
          ...state,
          subTotalAmount: action.payload,
        };
      default:
        return state;
    }
  };
  const [state, dispatch] = useReducer(totalReducer, {
    unitSellingPriceTotal: 0,
    subTotalAmount: 0,
    totalActualUnitPrice: 0,
    totalOverHeadUnitPrice: 0,
    totalContigencyUnitPrice: 0,
    totalMarkUpUnitPrice: 0,
    totalNegotiationUnitPrice: 0,
    totalDiscountUnitPrice: 0,
    grandTotalAmcAmount: 0
  });

  const handleExportAMC = async () => {
    if (!TableData || !TableData.estimationAmcSheetId) {
      console.error('TableData or estimationAmcSheetId is undefined');
      return;
    }

    try {
      const downloadUrl = await apiInstance.getFiles(
        `estimationamcsheetdetails/${TableData.estimationAmcSheetId}/exportexcel`
      );
      const link = document.createElement('a');
      link.href = downloadUrl;
      link.setAttribute('download', 'AMC Details.xlsx');
      document.body.appendChild(link);
      link.click();
      document.body.removeChild(link);
    } catch (error) {
      console.error('Error exporting AMC details:', error);
    }
  };

  const handleTotalSubmit = () => {
    if (!amcEstimationSheet?.estimationAmcSheetId) {
      return;
    }

    const data = {
      ...amcSheetsPercent,
      contingencyCost: Number(amcSheetsPercent.contingencyCost),
      discountCost: Number(amcSheetsPercent.discountCost),
      markUpCost: Number(amcSheetsPercent.markUpCost),
      otherCost: Number(amcSheetsPercent.otherCost),
      overHeadCost: Number(amcSheetsPercent.overHeadCost),
      negotiationCost: Number(amcSheetsPercent.negotiationCost),
      totalEstimationCost: Number(amcSheetsPercent.totalEstimationCost),
    };

    // First API Call
    crud.update(
      `estimationamcsheets/${amcEstimationSheet.estimationAmcSheetId}/update-note`,
      '',
      data,
      (err, res) => {
        if (err) {
          return;
        }
        if (res?.status === 200) {
          toast.success('Estimation AMC Sheet Detail Created Successfully');
          fetchData();
        }
      }
    );

    // Second API Call
    crud.update(
      `estimationamcsheetdetails/${amcEstimationSheet.estimationAmcSheetId}/bulk-update`,
      '',
      rows[0]?.estimationAmcSheetDetails,
      (err, res) => {
        if (err) {
          return;
        }
        if (res?.status === 200) {
          toast.success("Bulk update successful");
          fetchData();
        }
      }
    );
  };


  const handleFileUpload = async (e) => {
    setUploadLoading(true);
    const file = e.target.files[0];
    if (file) {
      const fileFormData = new FormData();
      fileFormData.append('file', file);
      fileFormData.append('fileTypeId', 40);

      await crud.create(`/files/upload`, fileFormData, (err, res) => {
        if (res?.status === 200) {
          setUploadLoading(false);
          setUploadedfileData(res?.data);
        } else {
          setUploadLoading(false);
        }
      });
    }
  };

  const handleSubmitImport = async () => {
    startCreateLoading();
    await crud.create(
      `estimationamcsheetdetails/${TableData?.estimationAmcSheetId}/process/${uploadedfileData?.fileId}`,
      '',
      (err, res) => {
        if (res?.status === 200) {
          toast.success('Estimation AMC Sheet Detail Created Successfully');
          stopCreateLoading();
          setOpenUpload(false);
          setUploadedfileData(null);
          fetchData();
        } else {
          stopCreateLoading();
        }
      }
    );
  };

  const handleAddNewRow = () => {
    const newId = 'new_row_' + Date.now();

    reset();
    setEditingRowData(null);
    setEditingRowId(null);

    setRowState({
      newRowId: newId,
      isCreatingRow: true,
    });

    setEditingRowIndex(
      editingRowIndex === rows[0]?.estimationAmcSheetDetails?.length
        ? editingRowIndex + 1
        : rows[0]?.estimationAmcSheetDetails?.length
    );

    // Add a new row to the rows state
    setRows((prevRows) => {
      if (prevRows.length === 0) {
        return [
          {
            id: newId,
            estimationAmcSheetDetails: [
              {
                estimationAmcSheetDetailId: newId,
                estimationAmcSheetId: newId,
                productCode: '',
                estimationTypeId: '',
                workManPowerName: '',
                unitSellingPrice: 0,
                description: '',
                months: 0,
                quantity: 0,
                unitOfMeasurementId: '',
                currencyId: '',
                isEditable: true,
                isNew: true,
              },
            ],
          },
        ];
      } else {
        // If rows are not empty, modify the first row to add a new entry
        return prevRows.map((row, index) => {
          if (index === 0) {
            return {
              ...row,
              estimationAmcSheetDetails: [
                ...row.estimationAmcSheetDetails,
                {
                  estimationAmcSheetDetailId: newId,
                  estimationAmcSheetId: newId,
                  productCode: '',
                  estimationTypeId: '',
                  workManPowerName: '',
                  unitSellingPrice: 0,
                  description: '',
                  months: 0,
                  quantity: 0,
                  unitOfMeasurementId: '',
                  currencyId: '',
                  isEditable: true,
                  isNew: true,
                },
              ],
            };
          }
          return row; // Return other rows unchanged
        });
      }
    });

    // Reset the form again after row addition
    reset();
  };

  const previousValues = useRef(getValues()); // store previous values to compare with

  const handleBlur = () => {
    const currentValues = getValues();
    const value = getValues('productCode');
    if (!value) {
      // Refocus the field if empty
      firstColumnInputRef.current.focus();
      return;
    }

    // if (hasFieldChanges) {
    //   setFieldEdited(true); // Mark field as edited
    // }
    // Compare the previous values with the current values
    const hasChanges = Object.keys(currentValues).some(
      (key) => currentValues[key] !== previousValues.current[key]
    );

    if ((hasChanges && isValid) || (hasChanges && editingRowId)) {
      apiCallAsync(currentValues);
      previousValues.current = currentValues; // Update previous values
    }
  };

  const apiCallAsync = async (values) => {
    setLoader(true);
    const convertedValues = {
      ...values,
      estimationAmcSheetId: amcEstimationSheet?.estimationAmcSheetId,
      sspDetail: 'Some SSP Detail', // Example static field
      description: values?.description, // Ensure the correct field is passed
      lineItemName: values?.lineItemName, // If this exists in your form
      unitSellingPrice:
        values?.unitSellingPrice || editingRowData?.unitSellingPrice, // Get latest or fallback to editingRowData
      discountUnitPrice: editingRowData?.discountUnitPrice,
      markupUnitPrice: editingRowData?.markupUnitPrice,
      negotiationUnitPrice: editingRowData?.negotiationUnitPrice,
      overHeadUnitPrice: editingRowData?.overHeadUnitPrice,
      subTotalAmount: editingRowData?.subTotalAmount,
      totalAmcAmount: editingRowData?.totalAmcAmount,
      actualUnitPrice: editingRowData?.actualUnitPrice,
      contingencyUnitPrice: editingRowData?.contingencyUnitPrice,
      unitOfMeasurementId: values?.unitOfMeasurementId || null,
      unitPrice: editingRowData?.unitPrice || values?.unitPrice,
      months: values?.months || 1,
      quantity: values?.quantity || 1,
      currencyId: values?.currencyId || null,
      createEstimationAmcDetailAttachments: [],
    };

    const combinedData = {
      estimationAmcSheetId: TableData?.estimationAmcSheetId,
      ...convertedValues,
    };

    const updateCombinedData = {
      ...values, // Same as above, combining updated values
      estimationAmcSheetDetailId: TableData?.estimationAmcSheetDetailId, // Ensure proper ID is passed
      unitSellingPrice:
        values?.unitSellingPrice || editingRowData?.unitSellingPrice,
      discountUnitPrice: editingRowData?.discountUnitPrice,
      markupUnitPrice: editingRowData?.markupUnitPrice,
      negotiationUnitPrice: editingRowData?.negotiationUnitPrice,
      overHeadUnitPrice: editingRowData?.overHeadUnitPrice,
      subTotalAmount: editingRowData?.subTotalAmount,
      totalAmcAmount: editingRowData?.totalAmcAmount,
      actualUnitPrice: editingRowData?.actualUnitPrice,
      unitPrice: editingRowData?.unitPrice || values?.unitPrice,
      contingencyUnitPrice: editingRowData?.contingencyUnitPrice, // Ensure this field is always set
      status: 1,
      unitOfMeasurementId: values?.unitOfMeasurementId || null,
      months: values?.months || 1,
      quantity: values?.quantity || 1,
      currencyId: values?.currencyId || null,
      updateEstimationAmcDetailAttachments: uploadedFileIds.flatMap((item) => {
        // Ensure the item exists and its index matches editingRowIndex
        if (item && item.index === editingRowIndex && item.fileIds) {
          return item.fileIds.map((fileId) => {
            // Find an existing attachment with the same fileId
            const existingAttachment =
              values?.estimationAmcDetailAttachments?.find(
                (attachment) =>
                  attachment.fileId === fileId &&
                  attachment.index === editingRowIndex
              );
            // Return the attachment object, with ID retained if it exists
            return {
              estimationAmcDetailAttachmentId:
                existingAttachment?.estimationAmcDetailAttachmentId || 0,
              fileId: fileId,
            };
          });
        }
        return []; // Return an empty array if index doesn't match
      }),
    };

    // Conditional API call based on whether this is a new row or an update
    if (editingRowId === null) {
      await crud.create(
        'estimationamcsheetdetails',
        combinedData,
        (err, res) => {
          if (res?.status === 201) {
            // fetchData()
            setEditingRowId(res?.data?.estimationAmcSheetDetailId);
            setValue(
              'estimationAmcSheetDetailId',
              res?.data?.estimationAmcSheetDetailId
            );
          } else {
          }
        }
      );
    } else {
      await crud.update(
        'estimationamcsheetdetails',
        editingRowId,
        updateCombinedData,
        (err, res) => {
          if (res?.status === 200) {
            if (rows[0]?.estimationAmcSheetDetails?.some((row) => row.isNew)) {
              const updatedRow = res?.data;
              const updatedRows = [...rows[0]?.estimationAmcSheetDetails];
              updatedRows[editingRowIndex] = {
                ...updatedRow,
                isEditable: true,
                isNew: true,
              };
              setRows([{ ...rows[0], estimationAmcSheetDetails: updatedRows }]);
              setStateError(false);
              setLoader(false);
            } else {
              setStateError(false);
              setLoader(false);
              const updatedRow = res?.data;
              const updatedRows = [...rows[0]?.estimationAmcSheetDetails];

              updatedRows[editingRowIndex] = updatedRow;

              setRows([{ ...rows[0], estimationAmcSheetDetails: updatedRows }]);
            }
          }
        }
      );
    }
  };

  const handleCancel = (row) => {
    if (row?.original?.estimationAmcSheetId === rowState?.newRowId) {
      setRows((prevRows) =>
        prevRows.filter((r) => r?.estimationAmcSheetId !== rowState?.newRowId)
      );
      setRowState((prevState) => ({
        ...prevState,
        newRowId: null,
        isCreatingRow: false,
      }));
      setEditingRowIndex(null);
    } else {
      setEditingRowId(null);
      setEditingRowIndex(null);
    }
    reset();
  };

  const calculateUnitPrice = (basePrice, percent, additionalPrice = 0) =>
    (basePrice * (percent / 100) + additionalPrice).toFixed(2);

  useEffect(() => {
    if (!editingRowData) return;

    // Clone the data to avoid mutating the state directly
    const updatedRowData = { ...editingRowData };

    // Update `unitSellingPrice` if `selectedRate` is defined
    if (selectedRate) {
      const selectedManPowerRate = manpowerrates.find(
        (item) => item.manPowerRateId === selectedRate
      );

      if (selectedManPowerRate?.ctcPerMonth) {
        setValue('unitSellingPrice', selectedManPowerRate.ctcPerMonth);
        updatedRowData.unitSellingPrice = selectedManPowerRate.ctcPerMonth;
      }
    }

    // Perform calculations and update derived fields
    if (updatedRowData.unitSellingPrice) {
      updatedRowData.actualUnitPrice = updatedRowData.unitSellingPrice;

      if (updatedRowData.quantity && updatedRowData.months) {
        updatedRowData.subTotalAmount =
          updatedRowData.quantity *
          updatedRowData.months *
          updatedRowData.unitSellingPrice;
      }

      if (updatedRowData.overHeadPercent) {
        // updateTotalOverHeadPrice(updatedRowData);
        updatedRowData.overHeadUnitPrice = calculateUnitPrice(
          updatedRowData.actualUnitPrice,
          updatedRowData.overHeadPercent,
          updatedRowData.unitSellingPrice
        );
      }

      if (updatedRowData.contingencyPercent) {
        updatedRowData.contingencyUnitPrice = calculateUnitPrice(
          parseFloat(
            updatedRowData.overHeadUnitPrice || updatedRowData.actualUnitPrice
          ),
          updatedRowData.contingencyPercent,
          parseFloat(updatedRowData.overHeadUnitPrice || 0)
        );
      }

      if (updatedRowData.markUpPercent) {
        updatedRowData.markupUnitPrice = calculateUnitPrice(
          parseFloat(
            updatedRowData.contingencyUnitPrice ||
            updatedRowData.overHeadUnitPrice
          ),
          updatedRowData.markUpPercent,
          parseFloat(updatedRowData.contingencyUnitPrice || 0)
        );
      }

      if (updatedRowData.negotiationPercent) {
        updatedRowData.negotiationUnitPrice = calculateUnitPrice(
          parseFloat(
            updatedRowData.markupUnitPrice ||
            updatedRowData.contingencyUnitPrice
          ),
          updatedRowData.negotiationPercent,
          parseFloat(updatedRowData.markupUnitPrice || 0)
        );
      }

      if (updatedRowData.discountPercent) {
        updatedRowData.discountUnitPrice = (
          parseFloat(
            updatedRowData.negotiationUnitPrice ||
            updatedRowData.markupUnitPrice
          ) *
          (1 - updatedRowData.discountPercent / 100) +
          parseFloat(updatedRowData.markupUnitPrice || 0)
        ).toFixed(2);
      }

      // Calculate and update `unitPrice`
      const discountUnitPrice = parseFloat(
        updatedRowData?.discountUnitPrice || 0
      );
      const quantity = parseFloat(updatedRowData?.quantity || 0);

      if (discountUnitPrice && quantity) {
        updatedRowData.unitPrice = (discountUnitPrice * quantity).toFixed(2);
        setValue('unitPrice', updatedRowData.unitPrice);
      } else {
        console.error(
          'Invalid values for unitPrice calculation: discountUnitPrice or months.'
        );
      }

      if (updatedRowData.months && updatedRowData.unitPrice) {
        updatedRowData.totalAmcAmount = (
          updatedRowData.months * parseFloat(updatedRowData.unitPrice || 0)
        ).toFixed(2);
      }
    }

    // Calculate `amcSubtotal`
    const amcSubtotal = rows[0]?.estimationAmcSheetDetails?.reduce(
      (total, row, index) => {
        const amountToAdd =
          index === editingRowIndex
            ? parseFloat(updatedRowData.totalAmcAmount || 0)
            : parseFloat(row.totalAmcAmount || 0);

        return total + amountToAdd;
      },
      0
    );

    setAmcSubtotal(amcSubtotal);

    // Download attachment files
    if (
      editingRowData?.estimationAmcDetailAttachments?.length > 0 &&
      previewdData?.length === 0
    ) {
      setUploadBoxLoader(true);

      const downloadFiles = async () => {
        // Wait for all downloads to complete
        await Promise.all(
          editingRowData.estimationAmcDetailAttachments.map(async (image) => {
            await handleDownloadFile(image?.fileId);
          })
        );

        // Set loader to false once all downloads are finished
        setUploadBoxLoader(false);
      };

      downloadFiles();
    }
    // Update editingRowData state if there are changes
    setEditingRowData((prevData) => {
      if (JSON.stringify(prevData) !== JSON.stringify(updatedRowData)) {
        return updatedRowData;
      }
      return prevData;
    });
  }, [
    selectedRate,
    editingRowData?.unitSellingPrice,
    editingRowData?.quantity,
    editingRowData?.months,
    editingRowData?.overHeadPercent,
    editingRowData?.contingencyPercent,
    editingRowData?.markUpPercent,
    editingRowData?.negotiationPercent,
    editingRowData?.discountPercent,
    editingRowIndex,
  ]);

  const handleEdit = (row) => {
    reset();
    if (rowState?.newRowId) {
      setRows((prevRows) =>
        prevRows.filter((r) => r?.estimationAmcSheetId !== rowState?.newRowId)
      );
      setRowState((prevState) => ({
        ...prevState,
        newRowId: null,
        isCreatingRow: false,
      }));
      // setEditingRowIndex(null);
    }
    setEditingRowId(row?.estimationAmcSheetDetailId);
    Object.entries({
      ...row,
    }).forEach(([key, value]) => {
      setValue(key, value);
    });
    setEditingRowData({ ...row });
  };

  useHotkeys(
    'Enter',
    () => handleAddNewRow(),
    {
      enableOnTags: ['INPUT', 'TEXTAREA'], // Optional: Enable hotkey only when focused on specific tags
    },
    [editingRowIndex, rows, stateError, loader]
  );

  useHotkeys(
    'Tab',
    (event) => {
      if (event.key !== 'Tab') return;
      if (stateError) {
        event.preventDefault();
        return;
      }
      const totalRows = rows[0]?.estimationAmcSheetDetails?.length;
      const nextRowIndex = editingRowIndex + 1;

      if (
        nextRowIndex < totalRows &&
        stateError === false &&
        loader === false
      ) {
        setCurrentRowIndex(nextRowIndex);
        handleEdit(rows[0]?.estimationAmcSheetDetails[nextRowIndex]);
        setEditingRowIndex(nextRowIndex);
      }
    },
    [
      editingRowIndex,
      rows[0]?.estimationAmcSheetDetails,
      stateError,
      loader,
      isValid,
    ]
  );

  const handleDownloadFile = async (fileId) => {
    try {
      if (!fileId) {
        return;
      }

      const imageUrl = await apiInstance.getFiles(`files/download/${fileId}`);

      setPreviewData((prevData) => {
        // Check if the fileId already exists in prevData
        const exists = prevData.some(
          (data, index) => index % 2 !== 0 && data === fileId
        ); // Check fileId only in the odd indices
        if (exists) {
          return prevData; // Return the same data if fileId exists
        }
        return [...prevData, imageUrl, fileId]; // Append imageUrl and fileId if not already in the data
      });
    } catch (err) {
      console.error(`Error fetching image: ${err.message}`);
    }
  };

  const handleBlurAndFetch = async () => {
    setLoader(true);
    const updatedRow = getValues(); // Get the latest values from the form
    const updatedRows = [...rows[0]?.estimationAmcSheetDetails]; // Clone the rows[0] to avoid direct mutation

    // Update the specific row in the cloned array
    updatedRows[editingRowIndex] = updatedRow;

    setRows([{ ...rows[0], estimationAmcSheetDetails: updatedRows }]);

    const values = getValues();
    const updateCombinedData = {
      ...values, // Same as above, combining updated values
      estimationAmcSheetDetailId: TableData?.estimationAmcSheetDetailId, // Ensure proper ID is passed
      unitSellingPrice:
        values?.unitSellingPrice || editingRowData?.unitSellingPrice,
      discountUnitPrice: editingRowData?.discountUnitPrice,
      markupUnitPrice: editingRowData?.markupUnitPrice,
      negotiationUnitPrice: editingRowData?.negotiationUnitPrice,
      overHeadUnitPrice: editingRowData?.overHeadUnitPrice,
      subTotalAmount: editingRowData?.subTotalAmount,
      totalAmcAmount: editingRowData?.totalAmcAmount,
      actualUnitPrice: editingRowData?.actualUnitPrice,
      contingencyUnitPrice: editingRowData?.contingencyUnitPrice, // Ensure this field is always set
      unitOfMeasurementId: values?.unitOfMeasurementId || null,
      months: values?.months || 1,
      quantity: values?.quantity || 1,
      currencyId: values?.currencyId || null,
      updateEstimationAmcDetailAttachments: [],
      status: 1, // Static status value
    };
    await crud.update(
      'estimationamcsheetdetails',
      editingRowId,
      updateCombinedData,
      (err, res) => {
        if (res?.status === 200) {
          const totalRows = rows[0]?.estimationAmcSheetDetails?.length;
          if (
            editingRowIndex <= totalRows &&
            rows[0]?.estimationAmcSheetDetails.some((row) => row.isNew)
          ) {
            setNewRowCreated(true);
            const updatedRow = res?.data;
            const updatedRows = [...rows[0]?.estimationAmcSheetDetails];
            updatedRows[editingRowIndex] = {
              ...updatedRow,
              isEditable: true,
              isNew: true,
            };
            setRows([{ ...rows[0], estimationAmcSheetDetails: updatedRows }]);
            handleAddNewRow();
          } else {
            const updatedRow = res?.data;
            const updatedRows = [...rows[0]?.estimationAmcSheetDetails];
            updatedRows[editingRowIndex] = {
              ...updatedRow,
              isEditable: false,
              isNew: false,
            };
            setRows([{ ...rows[0], estimationAmcSheetDetails: updatedRows }]);
            setStateError(false);
            setLoader(false);
          }
        } else {
          setStateError(true);
        }
      }
    );
  };

  const handleCancelAndAddNewRow = () => {
    const newId = 'new_row_' + Date.now();
    setEditingRowData(null);
    setEditingRowId(null);
    setRowState({
      newRowId: newId,
      isCreatingRow: true,
    });

    setEditingRowIndex(
      editingRowIndex === rows[0]?.estimationAmcSheetDetails?.length
        ? editingRowIndex + 1
        : rows[0]?.estimationAmcSheetDetails?.length
    );
    setNewRowCreated(false);
    setRows((prevRows) =>
      prevRows.map((row, index) => {
        if (index === 0) {
          // Add a new row to the estimationAmcSheetDetails of the first row
          return {
            ...row,
            estimationAmcSheetDetails: [
              ...row.estimationAmcSheetDetails,
              {
                estimationAmcSheetDetailId: newId,
                estimationAmcSheetId: newId,
                productCode: '',
                estimationTypeId: '',
                workManPowerName: '',
                unitSellingPrice: 0,
                description: '',
                months: 0,
                quantity: 0,
                unitOfMeasurementId: '',
                currencyId: '',
                isEditable: true,
                isNew: true,
              },
            ],
          };
        }
        return row; // Return other rows unchanged
      })
    );
    reset();
  };

  useEffect(() => {
    if (editingRowIndex !== null && firstColumnInputRef.current && !isLoading) {
      firstColumnInputRef.current.focus();
      setPreviewData([]);
    }
  }, [editingRowIndex, isLoading]);

  useEffect(() => {
    // Filter rows[0]?.estimationAmcSheetDetails? based on rowSelection keys
    if (rowSelection) {
      const selectedRowIds = rows[0]?.estimationAmcSheetDetails
        ?.filter((_, index) => rowSelection[index]) // Check if row index is selected in rowSelection
        .map((row) => row?.estimationAmcSheetDetailId); // Get the `estimationAmcSheetDetailId` of selected rows[0]?.estimationAmcSheetDetails?

      setSelectedRowIds(selectedRowIds);
    }
  }, [rowSelection]);

  const delectCheckBoxClicked = async () => {
    try {
      const response = await axios.delete(
        `${process.env.REACT_APP_BASE}/estimationamcsheetdetails`,
        {
          headers: {
            accept: '*/*',
            'Content-Type': 'application/json-patch+json',
          },
          data: {
            estimationAmcSheetDetailIds: selectedRowIds,
          },
        }
      );
      toast.success('Data Deleted Successfully');
      fetchData();
      setRowSelection({});
      setSelectedRowIds([]);
    } catch (error) {
      console.error('Error deleting data:', error);
    }
  };

  const handleFileIds = (fileIds, rowIndex) => {


    setUploadedFileIds((prev) => {
      const updatedIds = prev.filter((item) => item !== null); // Remove any existing `null` values
      const existingIndex = updatedIds.findIndex(
        (item) => item?.index === rowIndex
      );

      if (existingIndex !== -1) {
        // If the index already exists, update the fileIds
        updatedIds[existingIndex] = { index: rowIndex, fileIds };
      } else {
        // Otherwise, add a new entry for the index
        updatedIds.push({ index: rowIndex, fileIds });
      }

      return updatedIds;
    });
  };




  // Function to update total dynamically
  const updateTotal = (updatedData) => {
    const total = updatedData.reduce(
      (sum, row) => sum + (Number(row.unitSellingPrice) || 0),
      0
    );
    dispatch({ type: 'UPDATE_UNIT_SELLING_PRICE_TOTAL', payload: total });
  };
  const updateTotalOverHeadPrice = (updatedData) => {
    const total = updatedData.reduce(
      (sum, row) => sum + (Number(row.totalOverHeadUnitPrice) || 0),
      0
    );
    dispatch({ type: 'UPDATE_TOTAL_OVER_HEAD_UNIT_PRICE', payload: total });
  };

  const columns = [
    {
      accessorKey: 'productCode',
      header: 'Category',
      enableEditing: true,
      size: 120,
      Cell: ({ cell }) => {
        const isNewRow =
          cell?.row?.original?.estimationAmcSheetId === rowState?.newRowId;
        const isEditing =
          editingRowId === cell?.row?.original?.estimationAmcSheetDetailId ||
          isNewRow;
        return isEditing ? (
          <FmAutoComplete
            name='productCode'
            control={control}
            options={estimationTypes}
            label='Category'
            inputRef={firstColumnInputRef}
            displayField='estimationTypeName'
            optionFields={['estimationTypeName']}
            required
            onChange={(e) => {
              const value = e;
              if (value?.estimationTypeId === 1) {
                setProductChange(true);
              } else {
                setProductChange(false);
              }
            }}
            onChangeValue={true}
            valueKey='estimationTypeId'
            onBlur={handleBlur}
          />
        ) : (
          estimationTypes.find(
            (type) => type.estimationTypeId == cell?.row?.original?.productCode
          )?.estimationTypeName || ''
        );
      },
    },
    {
      accessorKey: 'description',
      header: 'Comments',
      enableEditing: true,
      Cell: ({ row }) => {
        const isNewRow =
          row?.original?.estimationAmcSheetId === rowState?.newRowId;
        const isEditing =
          editingRowId === row?.original?.estimationAmcSheetDetailId ||
          isNewRow;
        return isEditing ? (
          <>
            <FmTextField
              name='description'
              control={control}
              label='Comments'
              onBlur={handleBlur} // Trigger onBlur
            />
          </>
        ) : (
          row?.original?.description
        );
      },
    },

    {
      accessorKey: 'workManPowerName',
      header: 'Description',
      enableEditing: true,
      Cell: ({ row }) => {
        const isNewRow =
          row?.original?.estimationAmcSheetId === rowState?.newRowId;
        const isEditing =
          editingRowId === row?.original?.estimationAmcSheetDetailId ||
          isNewRow;

        return isEditing ? (
          productChange ? (
            <FmSearchableSelect
              name='workManPowerName'
              control={control}
              apiUrl='manpowerrates'
              valueField='designationName'
              labelField={['designationName']}
              showField={['designationName']}
              label='Description'
              headerField={['designationName']}
              onBlur={handleBlur}
              onChangeProp={(value) => {
                setValue('unitSellingPrice', value?.ctcPerMonth);
                setEditingRowData((prev) => ({
                  ...prev,
                  unitSellingPrice: value?.ctcPerMonth,
                }));
              }}
            />
          ) : (
            <FmTextField
              name='workManPowerName'
              control={control}
              onBlur={handleBlur}
              label='Description'
            />
          )
        ) : (
          row?.original?.workManPowerName || '-'
        );
      },
    },

    {
      accessorKey: 'vendorInHouse',
      header: 'Vendor In House',
      enableEditing: true,
      Cell: ({ row }) => {
        const isNewRow =
          row?.original?.estimationAmcSheetId === rowState?.newRowId;
        const isEditing =
          editingRowId === row?.original?.estimationAmcSheetDetailId ||
          isNewRow;
        return isEditing ? (
          <>
            <FmTextField
              name='vendorInHouse'
              control={control}
              label='Vendor In House'
              onBlur={handleBlur} // Trigger onBlur
            />
          </>
        ) : (
          row?.original?.vendorInHouse
        );
      },
    },
    {
      accessorKey: 'estimationAmcDetailAttachments',
      header: 'Attachments',
      enableEditing: true,
      size: 120,
      Cell: ({ row }) => {
        const isNewRow =
          row?.original?.estimationAmcSheetId === rowState?.newRowId;
        const isEditing =
          editingRowId === row?.original?.estimationAmcSheetDetailId ||
          isNewRow;
        const rowIndex = row.index;
        return isEditing ? (
          <div>
            <NewUploadModalBox
              uploadBoxLoader={uploadBoxLoader}
              setUploadBoxLoader={setUploadBoxLoader}
              onSubmitFiles={(fileIds) => handleFileIds(fileIds, rowIndex)}
              files={files}
              setFiles={setFiles}
              previewdData={previewdData}
            />
          </div>
        ) : (
          <Icon icon='ic:baseline-attachment' style={{ fontSize: '24px' }} />
        );
      },
    },
    {
      accessorKey: 'unitSellingPrice',
      header: 'Unit Selling Price',
      enableEditing: true,
      Footer: ({ table }) => {
        const total = table.getFilteredRowModel().rows.reduce(
          (sum, row) => sum + (Number(row.getValue("unitSellingPrice")) || 0),
          0
        );

        const displayTotal =
          state.unitSellingPriceTotal && state.unitSellingPriceTotal !== 0
            ? state.unitSellingPriceTotal.toFixed(2)
            : total.toFixed(2);

        return (
          <Box className="flex justify-between items-center gap-2 w-full p-2 border-t">
            <Box className="font-semibold text-green-600 dark:text-green-300">
              <CurrencyView
                value={displayTotal}
                currencyType={'AED'}
              />
            </Box>
          </Box>
        );
      },
      Cell: ({ row, table }) => {
        const isNewRow =
          row?.original?.estimationAmcSheetId === rowState?.newRowId;
        const isEditing =
          editingRowId === row?.original?.estimationAmcSheetDetailId ||
          isNewRow;

        return isEditing ? (
          <FmTextField
            name={`unitSellingPrice`}
            control={control}
            label='Unit Selling Price'
            prefix='AED'
            pattern='Number'
            onBlur={handleBlur}
            onChange={(e) => {
              const value = parseFloat(e.target.value) || 0;

              // Update the unitSellingPrice in editingRowData
              setEditingRowData((prev) => ({
                ...prev,
                unitSellingPrice: value,
              }));

              // Update table data and recalculate total
              table.options.meta?.updateData(
                row.index,
                'unitSellingPrice',
                value
              );
              const updatedData = table.getFilteredRowModel().rows.map((r) => ({
                ...r.original,
                unitSellingPrice:
                  r.index === row.index
                    ? value
                    : r.getValue('unitSellingPrice'),
              }));

              updateTotal(updatedData); // Dispatch to update total
            }}
          />
        ) : (
          <CurrencyView value={row?.original?.unitSellingPrice} />
        );
      },
    },
    {
      accessorKey: 'unitOfMeasurementId',
      header: 'UoM',
      size: 100,
      enableEditing: true,
      Cell: ({ row }) => {
        const isNewRow =
          row?.original?.estimationAmcSheetId === rowState?.newRowId;
        const isEditing =
          editingRowId === row?.original?.estimationAmcSheetDetailId ||
          isNewRow;
        return isEditing ? (
          <FmAutoComplete
            name='unitOfMeasurementId'
            control={control}
            options={unitofmeasurements}
            label='UOM'
            displayField='unitOfMeasurementName'
            optionFields={['unitOfMeasurementName']}
            valueKey='unitOfMeasurementId'
            onBlur={handleBlur}
          />
        ) : (
          row?.original?.unitOfMeasurementName || ''
        );
      },
    },
    {
      accessorKey: 'months',
      header: 'Months',
      enableEditing: true,
      size: 100,
      Cell: ({ row }) => {
        const isNewRow =
          row?.original?.estimationAmcSheetId === rowState?.newRowId;
        const isEditing =
          editingRowId === row?.original?.estimationAmcSheetDetailId ||
          isNewRow;
        return isEditing ? (
          <FmTextField
            name='months'
            control={control}
            label='Months'
            pattern='Number'
            defaultValue={editingRowData?.months || ''}
            onChange={(e) => {
              setEditingRowData((prev) => ({
                ...prev,
                months: e.target.value,
              }));
            }}
            onBlur={handleBlur}
          />
        ) : (
          row?.original?.months || 0
        );
      },
    },
    {
      accessorKey: 'quantity',
      header: 'Quantity',
      size: 100,
      enableEditing: true,
      Cell: ({ row }) => {
        const isNewRow =
          row?.original?.estimationAmcSheetId === rowState?.newRowId;
        const isEditing =
          editingRowId === row?.original?.estimationAmcSheetDetailId ||
          isNewRow;
        return isEditing ? (
          <FmTextField
            name='quantity'
            control={control}
            label='Quantity'
            pattern='Number'
            defaultValue={editingRowData?.quantity || ''}
            onChange={(e) => {
              setEditingRowData((prev) => ({
                ...prev,
                quantity: e.target.value,
              }));
            }}
            onBlur={handleBlur}
          />
        ) : (
          row?.original?.quantity || 0
        );
      },
    },

    {
      accessorKey: 'subTotalAmount',
      header: 'Sub Total',
      size: 100,
      enableEditing: true,
      Footer: ({ table }) => {
        const total = table.getFilteredRowModel().rows.reduce(
          (sum, row) => sum + (Number(row.getValue("subTotalAmount")) || 0),
          0
        );

        const displayTotal =
          state.subTotalAmount && state.subTotalAmount !== 0
            ? state.subTotalAmount.toFixed(2)
            : total.toFixed(2);

        return (
          <Box className="flex justify-between items-center gap-2 w-full p-2 border-t">
            <Box className="font-semibold text-green-600 dark:text-green-300">
              <CurrencyView
                value={displayTotal}
                currencyType={'AED'}
              />
            </Box>
          </Box>
        );
      },
      Cell: ({ row }) => {
        const isNewRow =
          row?.original?.estimationAmcSheetId === rowState?.newRowId;
        const isEditing =
          editingRowId === row?.original?.estimationAmcSheetDetailId ||
          isNewRow;
        const subTotalAmount =
          editingRowData?.subTotalAmount ?? row?.original?.subTotalAmount ?? 0;
        const unitSellingPrice =
          editingRowData?.unitSellingPrice ??
          row?.original?.unitSellingPrice ??
          0;
        // return isEditing
        //   ? `AED ${subTotalAmount || unitSellingPrice}`
        //   : `AED ${row?.original?.subTotalAmount ||
        //   row?.original?.unitSellingPrice ||
        //   0
        //   }`;
        return isEditing ? (
          <CurrencyView value={subTotalAmount || unitSellingPrice} currencyType="AED" />
        ) : (
          <CurrencyView
            value={row?.original?.subTotalAmount || row?.original?.unitSellingPrice || 0}
            currencyType="AED"
          />
        );
      },
    },
    {
      accessorKey: 'actualUnitPrice',
      header: 'Actual Unit Price',
      enableEditing: true,
      Footer: ({ table }) => {
        const total = table.getFilteredRowModel().rows.reduce(
          (sum, row) => sum + (Number(row.getValue("actualUnitPrice")) || 0),
          0
        );

        const displayTotal =
          state.totalActualUnitPrice && state.totalActualUnitPrice !== 0
            ? state.totalActualUnitPrice.toFixed(2)
            : total.toFixed(2);

        return (
          <Box className="flex justify-between items-center gap-2 w-full p-2 border-t">
            <Box className="font-semibold text-green-600 dark:text-green-300">
              <CurrencyView
                value={displayTotal}
                currencyType={'AED'}
              />
            </Box>
          </Box>
        );
      },
      Cell: ({ row }) => {
        const isNewRow =
          row?.original?.estimationAmcSheetId === rowState?.newRowId;
        const isEditing =
          editingRowId === row?.original?.estimationAmcSheetDetailId ||
          isNewRow;
        return isEditing ? (
          <CurrencyView value={editingRowData?.unitSellingPrice} currencyType="AED" />
        ) : (
          <CurrencyView
            value={row?.original?.actualUnitPrice || 0}
            currencyType="AED"
          />
        );
      }
    },

    {
      accessorKey: 'overHeadPercent',
      header: 'Over Head(%)',

      enableEditing: true,
      Cell: ({ row }) => {
        const isNewRow =
          row?.original?.estimationAmcSheetId === rowState?.newRowId;
        const isEditing =
          editingRowId === row?.original?.estimationAmcSheetDetailId ||
          isNewRow;

        return isEditing ? (
          <FmTextField
            name='overHeadPercent'
            control={control}
            label='Over Head(%)'
            pattern='Percentage'
            defaultValue={editingRowData?.overHeadPercent || 0}
            onChange={(e) => {
              setFieldEdited(true);
              setEditingRowData((prev) => ({
                ...prev,
                overHeadPercent: e.target.value,
              }));
            }}
            onBlur={handleBlur}
          />
        ) : (
          row?.original?.overHeadPercent
        );
      },
    },
    {
      accessorKey: 'overHeadUnitPrice',
      header: 'Over Head With Unit Price',
      Footer: ({ table }) => {
        const total = table.getFilteredRowModel().rows.reduce(
          (sum, row) => sum + (Number(row.getValue("overHeadUnitPrice")) || 0),
          0
        );

        const displayTotal =
          state.totalOverHeadUnitPrice && state.totalOverHeadUnitPrice !== 0
            ? state.totalOverHeadUnitPrice.toFixed(2)
            : total.toFixed(2);

        return (
          <Box className="flex justify-between items-center gap-2 w-full p-2 border-t">
            <Box className="font-semibold text-green-600 dark:text-green-300">
              <CurrencyView
                value={displayTotal}
                currencyType={'AED'}
              />
            </Box>
          </Box>
        );
      },
      enableEditing: true,
      Cell: ({ row }) => {
        const isNewRow =
          row?.original?.estimationAmcSheetId === rowState?.newRowId;
        const isEditing =
          editingRowId === row?.original?.estimationAmcSheetDetailId ||
          isNewRow;
        const actualUnitPrice =
          editingRowData?.actualUnitPrice ??
          row?.original?.actualUnitPrice ??
          0;
        return isEditing ? (
          <CurrencyView
            value={editingRowData?.overHeadUnitPrice || actualUnitPrice}
            currencyType="AED"
          />
        ) : (
          <CurrencyView
            value={row?.original?.overHeadUnitPrice || 0}
            currencyType="AED"
          />
        );
      }
    },
    {
      accessorKey: 'contingencyPercent',
      header: 'Contingency (%)',
      // Footer: () => (
      //   <Box className='flex justify-between items-center gap-2 w-full'>
      //     <Box className='font-semibold text-green-600 dark:text-green-300'>
      //       220
      //     </Box>
      //   </Box>
      // ),
      enableEditing: true,
      Cell: ({ row }) => {
        const isNewRow =
          row?.original?.estimationAmcSheetId === rowState?.newRowId;
        const isEditing =
          editingRowId === row?.original?.estimationAmcSheetDetailId ||
          isNewRow;
        return isEditing ? (
          <FmTextField
            name='contingencyPercent'
            control={control}
            label='Contingency (%)'
            pattern='Percentage'
            defaultValue={editingRowData?.contingencyPercent || 0}
            onChange={(e) => {
              setFieldEdited(true);
              setEditingRowData((prev) => ({
                ...prev,
                contingencyPercent: e.target.value,
              }));
            }}
            onBlur={handleBlur}
          />
        ) : (
          row?.original?.contingencyPercent
        );
      },
    },
    {
      accessorKey: 'contingencyUnitPrice',
      header: 'Contingency With Unit Price',
      Footer: ({ table }) => {
        const total = table.getFilteredRowModel().rows.reduce(
          (sum, row) => sum + (Number(row.getValue("contingencyUnitPrice")) || 0),
          0
        );

        const displayTotal =
          state.totalContigencyUnitPrice && state.totalContigencyUnitPrice !== 0
            ? state.totalContigencyUnitPrice.toFixed(2)
            : total.toFixed(2);

        return (
          <Box className="flex justify-between items-center gap-2 w-full p-2 border-t">
            <Box className="font-semibold text-green-600 dark:text-green-300">
              <CurrencyView
                value={displayTotal}
                currencyType={'AED'}
              />
            </Box>
          </Box>
        );
      },
      enableEditing: true,
      Cell: ({ row }) => {
        const isNewRow =
          row?.original?.estimationAmcSheetId === rowState?.newRowId;
        const isEditing =
          editingRowId === row?.original?.estimationAmcSheetDetailId ||
          isNewRow;
        const overHeadUnitPrice =
          editingRowData?.overHeadUnitPrice ??
          row?.original?.overHeadUnitPrice ??
          editingRowData?.unitSellingPrice ??
          0;
        return isEditing ? (
          <CurrencyView
            value={editingRowData?.contingencyUnitPrice || overHeadUnitPrice}
            currencyType="AED"
          />
        ) : (
          <CurrencyView
            value={row?.original?.contingencyUnitPrice || 0}
            currencyType="AED"
          />
        );
      }
    },
    {
      accessorKey: 'markUpPercent',
      header: 'Mark Up (%)',
      enableEditing: true,
      // Footer: () => (
      //   <Box className='flex justify-between items-center gap-2 w-full'>
      //     <Box className='font-semibold text-green-600 dark:text-green-300'>
      //       220
      //     </Box>
      //   </Box>
      // ),
      Cell: ({ row }) => {
        const isNewRow =
          row?.original?.estimationAmcSheetId === rowState?.newRowId;
        const isEditing =
          editingRowId === row?.original?.estimationAmcSheetDetailId ||
          isNewRow;
        return isEditing ? (
          <FmTextField
            name='markUpPercent'
            control={control}
            label='Mark Up (%)'
            pattern='Percentage'
            defaultValue={editingRowData?.markUpPercent || 0}
            onChange={(e) => {
              setFieldEdited(true);
              setEditingRowData((prev) => ({
                ...prev,
                markUpPercent: e.target.value,
              }));
            }}
            onBlur={handleBlur}
          />
        ) : (
          row?.original?.markUpPercent
        );
      },
    },
    {
      accessorKey: 'markupUnitPrice',
      header: 'Mark Up With Unit Price',
      enableEditing: true,
      Footer: ({ table }) => {
        const total = table.getFilteredRowModel().rows.reduce(
          (sum, row) => sum + (Number(row.getValue("markupUnitPrice")) || 0),
          0
        );

        const displayTotal =
          state.totalMarkUpUnitPrice && state.totalMarkUpUnitPrice !== 0
            ? state.totalMarkUpUnitPrice.toFixed(2)
            : total.toFixed(2);

        return (
          <Box className="flex justify-between items-center gap-2 w-full p-2 border-t">
            <Box className="font-semibold text-green-600 dark:text-green-300">
              <CurrencyView
                value={displayTotal}
                currencyType={'AED'}
              />
            </Box>
          </Box>
        );
      },
      Cell: ({ row }) => {
        const isNewRow =
          row?.original?.estimationAmcSheetId === rowState?.newRowId;
        const isEditing =
          editingRowId === row?.original?.estimationAmcSheetDetailId ||
          isNewRow;
        const contingencyUnitPrice =
          editingRowData?.contingencyUnitPrice ??
          row?.original?.contingencyUnitPrice ??
          editingRowData?.unitSellingPrice ??
          editingRowData?.unitSellingPrice ??
          0;
        return isEditing ? (
          <CurrencyView
            value={editingRowData?.markupUnitPrice || contingencyUnitPrice}
            currencyType="AED"
          />
        ) : (
          <CurrencyView
            value={row?.original?.markupUnitPrice || 0}
            currencyType="AED"
          />
        );
      },
    },
    {
      accessorKey: 'negotiationPercent',
      header: 'Negotiation (%)',
      enableEditing: true,
      // Footer: () => (
      //   <Box className='flex justify-between items-center gap-2 w-full'>
      //     <Box className='font-semibold text-green-600 dark:text-green-300'>
      //       220
      //     </Box>
      //   </Box>
      // ),
      Cell: ({ row }) => {
        const isNewRow =
          row?.original?.estimationAmcSheetId === rowState?.newRowId;
        const isEditing =
          editingRowId === row?.original?.estimationAmcSheetDetailId ||
          isNewRow;

        return isEditing ? (
          <FmTextField
            name='negotiationPercent'
            control={control}
            label='Negotiation (%)'
            pattern='Percentage'
            defaultValue={row.original.negotiationPercent || 0}
            onChange={(e) => {
              setFieldEdited(true);
              setEditingRowData((prev) => ({
                ...prev,
                negotiationPercent: e.target.value,
              }));
            }}
            onBlur={handleBlur}
          />
        ) : (
          row?.original?.negotiationPercent
        );
      },
    },
    {
      accessorKey: 'negotiationUnitPrice',
      header: 'Negotiation With Unit Price',
      enableEditing: true,
      Footer: ({ table }) => {
        const total = table.getFilteredRowModel().rows.reduce(
          (sum, row) => sum + (Number(row.getValue("negotiationUnitPrice")) || 0),
          0
        );

        const displayTotal =
          state.totalNegotiationUnitPrice && state.totalNegotiationUnitPrice !== 0
            ? state.totalNegotiationUnitPrice.toFixed(2)
            : total.toFixed(2);

        return (
          <Box className="flex justify-between items-center gap-2 w-full p-2 border-t">
            <Box className="font-semibold text-green-600 dark:text-green-300">
              <CurrencyView
                value={displayTotal}
                currencyType={'AED'}
              />
            </Box>
          </Box>
        );
      },
      Cell: ({ row }) => {
        const isNewRow =
          row?.original?.estimationAmcSheetId === rowState?.newRowId;
        const isEditing =
          editingRowId === row?.original?.estimationAmcSheetDetailId ||
          isNewRow;
        const markupUnitPrice =
          editingRowData?.markupUnitPrice ??
          row?.original?.markupUnitPrice ??
          editingRowData?.unitSellingPrice ??
          0;
        return isEditing ? (
          <CurrencyView
            value={editingRowData?.negotiationUnitPrice || markupUnitPrice}
            currencyType="AED"
          />
        ) : (
          <CurrencyView
            value={row?.original?.negotiationUnitPrice || 0}
            currencyType="AED"
          />
        );

      },
    },
    {
      accessorKey: 'discountPercent',
      header: 'Discount (%)',
      enableEditing: true,
      // Footer: () => (
      //   <Box className='flex justify-between items-center gap-2 w-full'>
      //     <Box className='font-semibold text-green-600 dark:text-green-300'>
      //       220
      //     </Box>
      //   </Box>
      // ),
      Cell: ({ row }) => {
        const isNewRow =
          row?.original?.estimationAmcSheetId === rowState?.newRowId;
        const isEditing =
          editingRowId === row?.original?.estimationAmcSheetDetailId ||
          isNewRow;
        return isEditing ? (
          <FmTextField
            name='discountPercent'
            control={control}
            label='Discount (%)'
            pattern='Percentage'
            defaultValue={editingRowData?.discountPercent || 0}
            onChange={(e) => {
              setFieldEdited(true);
              setEditingRowData((prev) => ({
                ...prev,
                discountPercent: e.target.value,
              }));
            }}
            onBlur={handleBlurAndFetch}
          />
        ) : (
          row?.original?.discountPercent
        );
      },
    },
    {
      accessorKey: 'discountUnitPrice',
      header: 'Discount With Unit Price',
      enableEditing: true,
      Footer: ({ table }) => {
        const total = table.getFilteredRowModel().rows.reduce(
          (sum, row) => sum + (Number(row.getValue("discountUnitPrice")) || 0),
          0
        );

        const displayTotal =
          state.totalDiscountUnitPrice && state.totalDiscountUnitPrice !== 0
            ? state.totalDiscountUnitPrice.toFixed(2)
            : total.toFixed(2);

        return (
          <Box className="flex justify-between items-center gap-2 w-full p-2 border-t">
            <Box className="font-semibold text-green-600 dark:text-green-300">
              <CurrencyView
                value={displayTotal}
                currencyType={'AED'}
              />
            </Box>
          </Box>
        );
      },
      Cell: ({ row }) => {
        const isNewRow =
          row?.original?.estimationAmcSheetId === rowState?.newRowId;
        const isEditing =
          editingRowId === row?.original?.estimationAmcSheetDetailId ||
          isNewRow;
        const negotiationUnitPrice =
          editingRowData?.negotiationUnitPrice ??
          row?.original?.negotiationUnitPrice ??
          editingRowData?.unitSellingPrice ??
          editingRowData?.unitSellingPrice ??
          0;
        return isEditing ? (
          <CurrencyView
            value={editingRowData?.discountUnitPrice || negotiationUnitPrice}
            currencyType="AED"
          />
        ) : (
          <CurrencyView
            value={row?.original?.discountUnitPrice || 0}
            currencyType="AED"
          />
        );
      },
    },

    {
      accessorKey: 'unitPrice',
      header: 'Unit Price',
      enableEditing: true,
      Cell: ({ row, cell }) => {
        const isNewRow =
          row?.original?.estimationAmcSheetId === rowState?.newRowId;
        const isEditing =
          editingRowId === row?.original?.estimationAmcSheetDetailId ||
          isNewRow;
        return isEditing ? (
          <CurrencyView
            value={editingRowData?.unitPrice || 0}
            currencyType="AED"
          />
        ) : (
          <CurrencyView
            value={row?.original?.unitPrice || 0}
            currencyType="AED"
          />
        );

      },
    },
    {
      accessorKey: 'totalAmcAmount',
      header: 'Total Price',
      enableEditing: true,
      Cell: ({ row }) => {
        const isNewRow =
          row?.original?.estimationAmcSheetId === rowState?.newRowId;
        const isEditing =
          editingRowId === row?.original?.estimationAmcSheetDetailId ||
          isNewRow;
        return isEditing ? (
          <CurrencyView
            value={editingRowData?.totalAmcAmount || 0}
            currencyType="AED"
          />
        ) : (
          <CurrencyView
            value={row?.original?.totalAmcAmount || 0}
            currencyType="AED"
          />
        );
      },
      Footer: ({ table }) => {
        const total = table.getFilteredRowModel().rows.reduce(
          (sum, row) => sum + (Number(row.getValue("totalAmcAmount")) || 0),
          0
        );

        const displayTotal =
          state.grandTotalAmcAmount && state.grandTotalAmcAmount !== 0
            ? state.grandTotalAmcAmount.toFixed(2)
            : total.toFixed(2);

        return (
          <Box className="flex justify-between items-center gap-2 w-full p-2 border-t">
            <Box className="font-semibold text-green-600 dark:text-green-300">
              <CurrencyView
                value={displayTotal}
                currencyType={'AED'}
              />
            </Box>
          </Box>
        );
      },
    },

    {
      header: 'Action',
      size: 80,
      enableColumnPinning: true,
      muiTableBodyCellProps: {
        sx: {
          borderLeft: '1px solid',
          borderColor: 'border.main',
        },
        className: 'bg-orange-50 dark:bg-orange-900',
      },
      // enableEditing: false,
      // enableSorting: false,
      Cell: ({ row, table }) => {
        const isNewRow =
          row?.original?.estimationAmcSheetId === rowState?.newRowId;
        const isEditing =
          editingRowId === row?.original?.estimationAmcSheetDetailId ||
          isNewRow;

        // Determine if the current row is the last row in the table
        const isLastRow =
          row.index === rows[0]?.estimationAmcSheetDetails?.length - 1;
        return (
          <Box className='flex gap-2'>
            {isEditing ? (
              <>
                {/* <Button onClick={handleSubmit(handleUpdateAMCDetails)}>
                  Save
                </Button> */}
                <Button onClick={() => handleCancel(row)}>Close</Button>
                {isLastRow && rowState?.isCreatingRow === false && (
                  <Button
                    onFocus={() => {
                      handleCancelAndAddNewRow(row);
                    }}
                  ></Button>
                )}
              </>
            ) : (
              <Box className='flex gap-2'>
                {isLastRow && (
                  <Button
                    variant='contained'
                    color='primary'
                    className='px-3 py-1'
                    onClick={() => {
                      setEditingRowIndex(row?.index + 1);
                      handleAddNewRow(row);
                    }}
                  >
                    Add
                  </Button>
                )}
              </Box>
            )}
          </Box>
        );
      },
    },
  ];

  useEffect(() => {
    if (
      amcSheetsPercent.overHeadOverAllPercent ||
      amcSheetsPercent.contingencyOverAllPercent ||
      amcSheetsPercent.markUpOverAllPercent ||
      amcSheetsPercent.discountOverAllPercent ||
      amcSheetsPercent.negotiationPercent
    ) {
      const updatedRows = rows.map((row) => ({
        ...row,
        estimationAmcSheetDetails: row.estimationAmcSheetDetails?.map(
          (item) => {
            const updatedItem = { ...item }; // Avoid mutating the original item

            // Apply overHeadOverAllPercent if available
            if (amcSheetsPercent.overHeadOverAllPercent !== undefined) {
              updatedItem.overHeadPercent =
                amcSheetsPercent.overHeadOverAllPercent;
              updatedItem.overHeadUnitPrice = parseFloat(
                (
                  (updatedItem.actualUnitPrice * updatedItem.overHeadPercent) /
                  100 +
                  updatedItem.actualUnitPrice
                ).toFixed(2)
              );
            }

            // Apply contingencyOverAllPercent with condition
            if (amcSheetsPercent.contingencyOverAllPercent !== undefined) {
              updatedItem.contingencyPercent =
                amcSheetsPercent.contingencyOverAllPercent === 0
                  ? updatedItem.contingencyPercent
                  : amcSheetsPercent.contingencyOverAllPercent;
              updatedItem.contingencyUnitPrice = parseFloat(
                (
                  (updatedItem.overHeadUnitPrice *
                    updatedItem.contingencyPercent) /
                  100 +
                  updatedItem.overHeadUnitPrice
                ).toFixed(2)
              );
            }

            // Apply markUpOverAllPercent with condition
            if (amcSheetsPercent.markUpOverAllPercent !== undefined) {
              updatedItem.markUpPercent =
                amcSheetsPercent.markUpOverAllPercent === 0
                  ? updatedItem.markUpPercent
                  : amcSheetsPercent.markUpOverAllPercent;
              updatedItem.markupUnitPrice = parseFloat(
                (
                  (updatedItem.contingencyUnitPrice *
                    updatedItem.markUpPercent) /
                  100 +
                  updatedItem.contingencyUnitPrice
                ).toFixed(2)
              );
            }

            // Apply negotiationPercent with condition
            if (amcSheetsPercent.negotiationPercent !== undefined) {
              updatedItem.negotiationPercent =
                amcSheetsPercent.negotiationPercent === 0
                  ? updatedItem.negotiationPercent
                  : amcSheetsPercent.negotiationPercent;
              updatedItem.negotiationUnitPrice = parseFloat(
                (
                  (updatedItem.markupUnitPrice *
                    updatedItem.negotiationPercent) /
                  100 +
                  updatedItem.markupUnitPrice
                ).toFixed(2)
              );
            }

            // Apply discountOverAllPercent with condition
            if (amcSheetsPercent.discountOverAllPercent !== undefined) {
              updatedItem.discountPercent =
                amcSheetsPercent.discountOverAllPercent === 0
                  ? updatedItem.discountPercent
                  : amcSheetsPercent.discountOverAllPercent;
              updatedItem.discountUnitPrice = parseFloat(
                (
                  (updatedItem.negotiationUnitPrice *
                    updatedItem.discountPercent) /
                  100 +
                  updatedItem.negotiationUnitPrice
                ).toFixed(2)
              );
            }

            if (updatedItem?.discountUnitPrice && updatedItem?.quantity) {
              updatedItem.unitPrice = parseFloat(
                (updatedItem.discountUnitPrice * updatedItem.quantity).toFixed(
                  2
                )
              );
            }

            // Calculate total AMC amount
            updatedItem.totalAmcAmount = parseFloat(
              (updatedItem.discountUnitPrice * updatedItem.months).toFixed(2)
            );

            return updatedItem;
          }
        ),
      }));
      const amcSubtotal = updatedRows[0]?.estimationAmcSheetDetails?.reduce(
        (total, row, index) => {
          const amountToAdd = parseFloat(row.totalAmcAmount || 0)
          return total + amountToAdd;
        },
        0
      );

      setAmcSubtotal(amcSubtotal);

      setRows(updatedRows);
    }
  }, [amcSheetsPercent]);

  return (
    <>
      {tenderCheckLoader ? (
        <Box
          sx={{
            display: 'flex',
            justifyContent: 'center',
            alignItems: 'center',
          }}
        >
          <CircularProgress /> {/* MUI Loader */}
        </Box>
      ) : amcEstimationSheet ? (
        <>
          <GlassCard className='overflow-hidden'>
            <FmMRTDataTable
              muiTableContainerProps={{
                sx: {
                  maxHeight: '400px',
                  position: 'relative',
                },
              }}
              muiTableFooterCellProps={{
                sx: {
                  bgcolor: 'background.light',
                },
              }}
              columns={columns}
              enableRowSelection
              enableBottomToolbar={false}
              rowSelection={rowSelection}
              setRowSelection={setRowSelection}
              delectCheckBoxClicked={delectCheckBoxClicked}
              rows={rows[0]?.estimationAmcSheetDetails || []}
              handleRowDoubleClick={(row) => {
                setPreviewData([]);
                handleEdit(row?.original);
                setEditingRowIndex(row?.index);
                if (row?.original?.productCode == '1') {
                  setProductChange(true);
                } else {
                  setProductChange(false);
                }
              }}
              showPagination={false}
              onCreateRow={handleAddNewRow}
              isCreatingRow={rowState?.isCreatingRow}
              rowCount={rows[0]?.estimationAmcSheetDetails?.length || 0}
              editingRow={editingRowId}
              pagination={pagination}
              setSearchKeyword={setSearchKeyword}
              sorting={sorting}
              columnFilters={columnFilters}
              globalFilter={globalFilter}
              setPagination={setPagination}
              setSorting={setSorting}
              setColumnFilters={setColumnFilters}
              setGlobalFilter={setGlobalFilter}
              isLoading={isLoading}
              enableStickyFooter={true}
              disableNewButton={
                rows[0]?.estimationAmcSheetDetails?.length === 0
              }
              searchKeyword={searchKeyword}
              fetchData={fetchData}
              handleExport={handleExportAMC}
              handleImport={() => {
                setOpenUpload(true);
                setUploadedfileData(null);
              }}
            />
          </GlassCard>

          {!totalBoxLoader && (
            <TotalBox
              rows={rows[0]?.estimationAmcSheetDetails || []}
              editedingRowData={editingRowData}
              amcSheetsPercent={amcSheetsPercent}
              total={amcSubtotal !== 0 ? amcSubtotal : total}
              setAmcSheetsPercent={setAmcSheetsPercent}
              handleTotalSubmit={handleTotalSubmit}
              fieldEdited={fieldEdited}
            />
          )}
        </>
      ) : (
        <Box className='text-center mt-5'>
          Please Add Enquiry with AMC Details
        </Box>
      )}

      <MuiDialogOne
        open={openUpload}
        onClose={() => setOpenUpload(false)}
        title='Import AMC Details'
        description='Upload your amc details Xlsx file'
      >
        <FmFileInput
          name='file'
          documentName={uploadedfileData?.originalFileName || ''}
          onChange={handleFileUpload}
          loading={uploadLoading}
        />
        <ActionButtons
          onSubmit={handleSubmitImport}
          onCancel={() => setOpenUpload(false)}
          submitLoading={createLoading}
          onReset={reset}
          cancelLoading={false}
        />
      </MuiDialogOne>
    </>
  );
};

export default ViewDetails;
