import React from 'react';
import { DatePicker, LocalizationProvider } from '@mui/x-date-pickers';
import { Box } from '@mui/material';
import { Control, Controller, FieldValues } from 'react-hook-form';
import { AdapterDayjs } from '@mui/x-date-pickers/AdapterDayjs';
import dayjs, { Dayjs } from 'dayjs';

interface FmDatePickerProps {
  name: string;
  label: string;
  control: Control<FieldValues>;
  required?: boolean;
  readOnly?: boolean;
  format?: string;
  sx?: React.CSSProperties;
  defaultValue?: Dayjs | undefined;
  minDate?: Dayjs | undefined;
  maxDate?: Dayjs | undefined;
  onChangeProp?: (value: Dayjs | null) => void;
  onBlur?: () => void;
  inputRef?: React.Ref<any>;
}
const FmDatePicker = (props: FmDatePickerProps) => {
  const {
    name,
    label,
    control,
    required,
    format,
    sx,
    defaultValue = dayjs(),
    minDate,
    maxDate,
    readOnly,
    onChangeProp,
    onBlur,
    inputRef,
  } = props;

  return (
    <LocalizationProvider dateAdapter={AdapterDayjs}>
      <Controller
        name={name}
        control={control}
        defaultValue={defaultValue}
        render={({
          field: { onChange, onBlur: fieldOnBlur, value },
          fieldState: { error },
        }) => (
          <Box>
            <DatePicker
              label={label}
              minDate={minDate || undefined}
              format={format || 'DD/MM/YYYY'}
              sx={sx || { width: '100%' }}
              readOnly={readOnly}
              value={value ? dayjs(value) : null}
              onChange={(newValue) => {
                onChange(dayjs(newValue));
                onChangeProp && onChangeProp(newValue);
              }}
              maxDate={maxDate || undefined}
              onBlur={(e) => {
                fieldOnBlur();
                if (onBlur) {
                  onBlur();
                }
              }}
              slotProps={{
                textField: {
                  fullWidth: true,
                  error: !!error,
                  helperText: error ? error.message : null,
                  required: required,
                  inputRef: inputRef,
                },
              }}
            />
          </Box>
        )}
      />
    </LocalizationProvider>
  );
};

export default FmDatePicker;
