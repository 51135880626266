import React from 'react';
import { Box } from '@mui/material';

const FAStatusCard = ({ KPIArray, active, handleClick, type = 1 }) => {
  return (
    <Box className='flex'>
      {KPIArray.map((item, i) => {
        const opacityto = active === i ? 'opacity-100' : 'opacity-85';
        return (
          <Box
            className={`flex items-center py-1 relative px-3  flex-grow cursor-pointer   ${
              type === 2 ? 'flex-col text-center gap-1' : 'gap-3'
            } `}
            key={i}
            onClick={() => {
              item?.chartEnabled
                ? handleClick(i, -1)
                : handleClick(i, item?.salesInvoiceStatusId);
            }}
            sx={{
              color: active === i && 'primary.main',
              bgcolor: 'none',

              '& svg': {
                width: '25px',
                height: '25px',
              },

              ':before': {
                content: '""',
                height: '1px',
                width: active === i ? '100%' : '0%',
                bgcolor: 'primary.main',
                position: 'absolute',
                left: 0,
                bottom: 0,
                transition: '0.5s all',
              },
              ':hover': {
                bgcolor: active === i ? 'background.light' : 'background.light',
                ':before': {
                  width: '100%',
                  bgcolor: active === i ? 'primary.main' : 'background.light',
                },
              },
            }}
          >
            {type === 1 && <Box className={opacityto}>{item.icon}</Box>}
            <Box>
              <p className={`font-bold`}>{item?.value}</p>
              <h3 className={`${opacityto} capitalize`}>
                {item.title?.toLowerCase()}
              </h3>
            </Box>
          </Box>
        );
      })}
    </Box>
  );
};

export default FAStatusCard;
