import { Icon } from '@iconify/react';
import { Box, Button } from '@mui/material';
import dayjs from 'dayjs';
import React, { useEffect, useState } from 'react';
import { useForm, get } from 'react-hook-form';
import { useLocation, useNavigate } from 'react-router-dom';
import { toast } from 'react-toastify';
import Crud_Service from '../../../../apis/CrudService.jsx';
import {
  ViewTable,
  StatusBadge,
} from '../../../../components/_form/FormElements.jsx';
import TableActionButtons from '../../../../components/_form/TableActionButtons.jsx';
import FmSearchableSelect from '../../../../components/_mui/FmSearchableSelect.tsx';
import FmTextField from '../../../../components/_mui/FmTextField.tsx';
import FmViewDateField from '../../../../components/_mui/FmViewDateField.tsx';
import MuiDialogOne from '../../../../components/_mui/MuiDialogOne.jsx';
import TableExtraContentTooltip from '../../../../components/datagrid/TableExtraContentTooltip.tsx';
import FmMRTDataTable from '../../../../components/FmMRTDataTable.tsx';
import useTableLogic from '../../../../components/helpers/MRTUseTableLogic.tsx';
import ViewColoredDate from '../../../../components/view/ViewColoredDate.tsx';
import ViewTableLightText from '../../../../components/view/ViewTableLightText.tsx';
import Icons from '../../../../utils/Icon.jsx';
import GlassCard from './../../../../components/small/GlassCard.tsx';
import TableAvatar from '../../../../components/_form/TableAvatar.jsx';
import SimpleIconText from '../../../../components/elements/SimpleIconText.tsx';
import StatusColorText from '../../../../utils/StatusColorText.tsx';

const BoldBox = ({ title, primary }) => {
  return (
    <Box className='font-semibold' sx={{ color: primary && 'primary.main' }}>
      {title}
    </Box>
  );
};

const CreateSalesOrderList = (props) => {
  const { type, getData } = props;
  const [viewDetails, setViewdetails] = useState(null);
  const [editingRowData, setEditingRowData] = useState(null);
  const [editingRowId, setEditingRowId] = useState(null);
  const crud = new Crud_Service();
  const [viewRow, setViewRow] = useState([]);

  const { handleSubmit, control } = useForm();
  const {
    rows,
    isLoading,
    pagination,
    sorting,
    columnFilters,
    globalFilter,
    tableRecordCounts,
    setPagination,
    setSorting,
    setColumnFilters,
    setGlobalFilter,
    fetchData,
    setSearchKeyword,
    searchKeyword,
  } = useTableLogic(`salesorders?salesOrderTypeId=${type}`);

  useEffect(() => {
    fetchData();
  }, [type]);

  useEffect(() => {
    viewDetails && fetchSalesOrder();
  }, [viewDetails]);

  useEffect(() => {
    getData && fetchData();
  }, [getData]);
  const fetchSalesOrder = async () => {
    await crud.getSingle('salesorders', viewDetails, (_err, res) => {
      if (res?.status === 200) {
        setViewRow(res?.data);
      } else {
      }
    });
  };

  const handleEditItem = async (values) => {
    const data = {
      ...values,
      orderDate: dayjs(values?.orderDate).format('YYYY-MM-DD'),
      contactPersonId:
        values.contactPersonId || editingRowData?.contactPersonId,
      locationId: values.locationId || editingRowData?.locationId,
      regionId: values.regionId || editingRowData?.regionId,
      salesQuotationId:
        values.salesQuotationId || editingRowData?.salesQuotationId,
      orderStatusId: values.orderStatusId || editingRowData?.orderStatusId,
      status: 2,
      salesOrderTypeId: type,
    };
    setEditingRowId(values?.salesOrderId);
    setEditingRowData(values);
    await crud.update(
      'salesorders',
      editingRowData?.salesOrderId,
      data,
      (_err, res) => {
        if (res?.status === 200) {
          toast.success('Sales Order Updated Successfully');
          fetchData();
        } else {
        }
      }
    );
  };

  const handleDeleteSalesOrder = async (view) => {
    await crud.remove('salesorders', view?.salesOrderId, (_err, res) => {
      if (res?.status === 204) {
        fetchData();
        toast.success('Deleted Successfully');
      } else {
      }
    });
  };

  const navigate = useNavigate();
  const location = useLocation();
  const handleCancel = () => {
    setEditingRowId(null);
    setEditingRowData(null);
  };

  const ActionData = [
    {
      name: 'Edit',
      icon: Icons.edit,
      onClick: (_props) => {
        navigate(`${location.pathname}/view/${_props?.salesOrderId}`);
      },
    },
    {
      name: 'Delete',
      icon: Icons.delete,
      onClick: (props) => handleDeleteSalesOrder(props),
      danger: true,
    },
    // {
    //   name: 'Internal Budgetting',
    //   icon: <Icon icon='mdi:dollar' width='24' height='24' />,
    //   onClick: (props) => {
    //     navigate(`internal-budget/${props?.salesOrderId}`);
    //   },
    // },
  ];

  const columns = [
    {
      header: 'Actions',
      size: 60,
      Cell: ({ row }) => (
        <Box className='flex gap-2'>
          {editingRowId === row?.original?.salesOrderId ? (
            <>
              <Button onClick={handleSubmit(handleEditItem)}>Save</Button>
              <Button onClick={handleCancel}>Cancel</Button>
            </>
          ) : (
            <TableActionButtons
              Actions={ActionData?.map((action) => ({
                ...action,
                onClick: () => action.onClick(row?.original),
              }))}
            />
          )}
        </Box>
      ),
    },
    {
      accessorKey: 'salesOrderName',
      header: 'Sales Order',
      enableEditing: true,
      size: 250,
      Cell: ({ cell, row }) => {
        return (
          <TableAvatar
            name={cell?.getValue()}
            removeImage={true}
            desc={
              row?.original?.salesQuotationCode &&
              `Quotation Code : ${row?.original?.salesQuotationCode}`
            }
          />
        );
      },
    },
    {
      accessorKey: 'projectName',
      header: 'Project',
      enableEditing: true,
      renderCell: (params) => {
        return <BoldBox title={params?.row?.projectName} />;
      },
    },
    {
      accessorKey: 'contractName',
      header: 'Contract',
      enableEditing: true,
      renderCell: (params) => {
        return <BoldBox title={params?.row?.contractName} />;
      },
    },
    {
      accessorKey: 'salesQuotationCode', // Searchable select for Sales Quotation

      header: 'Sales Quotation',
      enableEditing: true,

      renderCell: (params) => {
        return (
          <ViewTableLightText
            name={
              <Box>
                <span className='font-semibold'>Code:</span>{' '}
                {params?.row?.salesQuotationCode}
              </Box>
            }
          />
        );
      },
    },
    {
      accessorKey: 'divisionName', // Searchable select for Sales Quotation
      header: 'Division',
      enableEditing: true,
      Cell: ({ cell }) => {
        return <ViewTableLightText name={cell?.getValue() || '-'} />;
      },
    },
    // {
    //   accessorKey: 'customerId', // Searchable select for Sales Quotation
    //   header: 'Customer',
    //   enableEditing: true,
    //   size: 250,
    //   Cell: ({ cell }) => {
    //     const order = rows?.find(
    //       (row) => row?.salesOrderId === cell?.row?.original?.salesOrderId
    //     );
    //     return editingRowId === cell?.row?.original?.salesOrderId ? (
    //       <FmSearchableSelect
    //         name='customerId'
    //         label='Customer'
    //         apiUrl={'customers'}
    //         valueField='customerId'
    //         labelField={['customerName', 'customerCode']}
    //         required
    //         showField={['customerName', 'customerCode']}
    //         control={control}
    //         defaultValue={editingRowData}
    //       />
    //     ) : (
    //       order?.customerName && <TableAvatar name={order?.customerName} />
    //     );
    //   },
    // },
    // {
    //   accessorKey: 'regionId', // Searchable select for Region
    //   header: 'Region',
    //   enableEditing: true,
    //   size: 250,
    //   Cell: ({ cell }) => {
    //     const order = rows?.find(
    //       (row) => row?.salesOrderId === cell?.row?.original?.salesOrderId
    //     );
    //     return editingRowId === cell?.row?.original?.salesOrderId ? (
    //       <FmSearchableSelect
    //         name='regionId'
    //         control={control}
    //         apiUrl='regions'
    //         valueField='regionId'
    //         headerField={['regionCode', 'regionName']}
    //         defaultValue={editingRowData}
    //         labelField={['regionCode', 'regionName']}
    //         showField={['regionName']}
    //         label={'Region'}
    //       />
    //     ) : (
    //       <TableAvatar
    //         name={order?.regionName}
    //         desc={order?.locationName}
    //         removeImage={true}
    //       />
    //     );
    //   },
    // },
    // {
    //   accessorKey: 'workOrderId',
    //   header: 'Work Order & Date',
    //   enableEditing: true,
    //   size: 220,
    //   Cell: ({ cell }) => {
    //     const order = rows?.find(
    //       (row) => row?.salesOrderId === cell?.row?.original?.salesOrderId
    //     );
    //     return (
    //       <TableAvatar
    //         name={order?.workOrderName}
    //         desc={order?.orderDate}
    //         removeImage={true}
    //       />
    //     );
    //   },
    // },

    {
      accessorKey: 'paymentTermName', // Payment Terms field
      header: 'Payment Terms',
      enableEditing: true,
      Cell: ({ cell }) => {
        return <ViewTableLightText name={cell?.getValue() || '-'} />;
      },
    },

    {
      accessorKey: 'narration',
      header: 'Narration',
      enableEditing: true,
      Cell: ({ cell }) => {
        const order = rows?.find(
          (row) => row?.salesOrderId === cell?.row?.original?.salesOrderId
        );
        return editingRowId === cell?.row?.original?.salesOrderId ? (
          <FmTextField
            name='narration'
            control={control}
            defaultValue={editingRowData?.narration}
            label='Narration'
          />
        ) : (
          order?.narration
        );
      },
    },
    {
      accessorKey: 'deliveryTerms',
      header: 'Delivery Terms',
      enableEditing: true,
      Cell: ({ cell }) => {
        const order = rows?.find(
          (row) => row?.salesOrderId === cell?.row?.original?.salesOrderId
        );
        return editingRowId === cell?.row?.original?.salesOrderId ? (
          <FmTextField
            name='deliveryTerms'
            control={control}
            defaultValue={editingRowData?.deliveryTerms}
            label='Delivery Terms'
          />
        ) : (
          order?.deliveryTerms
        );
      },
    },
    // {
    //   accessorKey: 'subject',
    //   header: 'Subject',
    //   enableEditing: true,
    //   Cell: ({ cell }) => {
    //     const order = rows?.find(
    //       (row) => row?.salesOrderId === cell?.row?.original?.salesOrderId
    //     );
    //     return editingRowId === cell?.row?.original?.salesOrderId ? (
    //       <FmTextField
    //         name='subject'
    //         control={control}
    //         defaultValue={editingRowData?.subject}
    //         label='Subject'
    //       />
    //     ) : (
    //       order?.subject
    //     );
    //   },
    // },
    // {
    //   accessorKey: 'notes',
    //   header: 'Notes',
    //   enableEditing: true,
    //   Cell: ({ cell }) => {
    //     const order = rows?.find(
    //       (row) => row?.salesOrderId === cell?.row?.original?.salesOrderId
    //     );
    //     return editingRowId === cell?.row?.original?.salesOrderId ? (
    //       <FmTextField
    //         name='notes'
    //         control={control}
    //         defaultValue={editingRowData?.notes}
    //         label='Notes'
    //       />
    //     ) : (
    //       order?.notes
    //     );
    //   },
    // },
    // {
    //   accessorKey: 'validity',
    //   header: 'Validity',
    //   enableEditing: true,
    //   size: 100,
    //   Cell: ({ cell }) => {
    //     const order = rows?.find(
    //       (row) => row?.salesOrderId === cell?.row?.original?.salesOrderId
    //     );
    //     return editingRowId === cell?.row?.original?.salesOrderId ? (
    //       <FmTextField
    //         name='validity'
    //         control={control}
    //         defaultValue={editingRowData?.validity}
    //         label='Validity'
    //       />
    //     ) : (
    //       order?.validity
    //     );
    //   },
    // },

    {
      accessorKey: 'remarks',
      header: 'Remarks',
      enableEditing: true,
      Cell: ({ cell }) => {
        const order = rows?.find(
          (row) => row?.salesOrderId === cell?.row?.original?.salesOrderId
        );
        return editingRowId === cell?.row?.original?.salesOrderId ? (
          <FmTextField
            name='remarks'
            control={control}
            defaultValue={editingRowData?.remarks}
            label='Remarks'
          />
        ) : (
          order?.remarks
        );
      },
    },
    // {
    //   accessorKey: 'attn',
    //   header: 'Attn',
    //   enableEditing: true,
    //   Cell: ({ cell }) => {
    //     const order = rows?.find(
    //       (row) => row?.salesOrderId === cell?.row?.original?.salesOrderId
    //     );
    //     return editingRowId === cell?.row?.original?.salesOrderId ? (
    //       <FmTextField
    //         name='attn'
    //         control={control}
    //         defaultValue={editingRowData?.attn}
    //         label='Attn'
    //       />
    //     ) : (
    //       order?.attn
    //     );
    //   },
    // },
    // {
    //   accessorKey: 'contactPersonId',
    //   header: 'Contact Person',
    //   enableEditing: true,
    //   Cell: ({ cell }) => {
    //     const order = rows?.find(
    //       (row) => row?.salesOrderId === cell?.row?.original?.salesOrderId
    //     );
    //     return editingRowId === cell?.row?.original?.salesOrderId ? (
    //       <FmSearchableSelect
    //         name='contactPersonId'
    //         control={control}
    //         apiUrl='loginusers'
    //         valueField='loginUserId'
    //         defaultValue={{
    //           loginUserId: editingRowData?.contactPersonId,
    //           employeeName: editingRowData?.contactPersonName,
    //           employeeMail: editingRowData?.contactPersonMail,
    //         }}
    //         labelField={['employeeCode', 'employeeName', 'employeeMail']}
    //         showField={['employeeCode', 'employeeName', 'employeeMail']}
    //         label={'Contact Person'}
    //       />
    //     ) : (
    //       order?.contactPersonName && (
    //         <TableAvatar name={order?.contactPersonName} fontWeightRemoved />
    //       )
    //     );
    //   },
    // },
    {
      accessorKey: 'orderStatusId',
      header: 'Order Status',
      enableEditing: true,

      Cell: ({ cell }) => {
        const order = rows?.find(
          (row) => row?.salesOrderId === cell?.row?.original?.salesOrderId
        );
        return editingRowId === cell?.row?.original?.salesOrderId ? (
          <FmSearchableSelect
            name='orderStatusId'
            control={control}
            apiUrl='salesorders/statuses'
            valueField='salesOrderStatusId'
            headerField={['salesOrderStatusName']}
            defaultValue={{
              salesOrderStatusId: editingRowData?.orderStatusId,
              salesOrderStatusName: editingRowData?.orderStatusName,
            }}
            labelField={['salesOrderStatusName']}
            showField={['salesOrderStatusName']}
            label={'Order Status'}
          />
        ) : (
          order?.orderStatusName && (
            <StatusBadge
              title={order?.orderStatusName}
              type={StatusColorText[order?.orderStatusName?.toLowerCase()]}
            />
          )
        );
      },
    },
    {
      accessorKey: 'currencyName', // Currency field
      header: 'Currency',
      enableEditing: true,
      Cell: ({ cell }) => {
        const order = rows?.find(
          (row) => row?.salesOrderId === cell?.row?.original?.salesOrderId
        );
        return editingRowId === cell?.row?.original?.salesOrderId ? (
          <FmTextField
            name='currencyName'
            control={control}
            defaultValue={editingRowData?.currencyName}
            label='Currency'
          />
        ) : (
          order?.currencyName
        );
      },
    },
  ];

  return (
    <>
      <GlassCard className='h-full-css'>
        <FmMRTDataTable
          columns={columns}
          enableRowSelection={false}
          rows={rows}
          rowCount={tableRecordCounts}
          editingRow={editingRowId}
          pagination={pagination}
          sorting={sorting}
          fetchData={fetchData}
          columnFilters={columnFilters}
          globalFilter={globalFilter}
          setPagination={setPagination}
          setSorting={setSorting}
          setColumnFilters={setColumnFilters}
          setGlobalFilter={setGlobalFilter}
          isLoading={isLoading}
          setSearchKeyword={setSearchKeyword}
          searchKeyword={searchKeyword}
          muiTableContainerProps={{
            sx: {
              maxHeight: '100%',
              flex: 1,
            },
          }}
          showSerialNumber={true}
          leftPinning={['serialNumber', 'salesOrderName']}
        />
      </GlassCard>
      <MuiDialogOne
        title='View Sales Order Details'
        open={viewDetails !== null}
        onClose={() => setViewdetails(null)}
      >
        <ViewTable
          rows={[
            {
              label: 'Sales Order Name',
              title: viewRow?.salesOrderName,
            },
            {
              label: 'Order Date',
              title: viewRow?.orderDate,
            },
            {
              label: 'Region',
              title: viewRow?.regionName,
            },
            {
              label: 'Payment Terms',
              title: viewRow?.paymentTerms,
            },
          ]}
        />
      </MuiDialogOne>
    </>
  );
};

export default CreateSalesOrderList;
