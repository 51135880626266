import { Box } from '@mui/material';
import React, { useEffect, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { toast } from 'react-toastify';
import Crud_Service from '../../../apis/CrudService.jsx';
import DashboardLayout from '../../../components/DashboardLayout.tsx';
import DataTable from '../../../components/DataTable.tsx';
import TableActionButtons from '../../../components/_form/TableActionButtons.jsx';
import TableSearch from '../../../components/_form/TableSearch.jsx';
import FmViewDateField from '../../../components/_mui/FmViewDateField.tsx';
import CommonView from '../../../components/helpers/CommonView.jsx';
import DynamicViewFields from '../../../components/helpers/DynamicViewFields.jsx';
import useTableLogic from '../../../components/helpers/UseTableLogic.tsx';
import BackToButton from '../../../components/small/BackToButton.tsx';
import { CreateButton } from '../../../components/small/Buttons.jsx';
import GlassCard from '../../../components/small/GlassCard.tsx';
import Icons from '../../../utils/Icon.jsx';
import ViewTableTwoDate from '../../../components/table/ViewTableTwoDate.tsx';
import TableAvatar from '../../../components/_form/TableAvatar.jsx';
import DataGridHeaderTooltip from '../../../components/datagrid/DataGridHeaderTooltip.tsx';
import DataTableToolbarButtons from '../../../components/helpers/DataTableToolbarButtons.tsx';
import StatusNewBadge from '../../../components/_form/StatusNewBadge.tsx';
import ExpiredContractDateColumns from '../../../components/helpers/ExpiredContractDateColumns.tsx';
import StatusColorText from '../../../utils/StatusColorText.tsx';
import CurrencyView from '../../../components/_mui/FmCurrencyView.tsx';

const ManageContracts = () => {
  const [viewData, setViewData] = useState(null);
  const crud = new Crud_Service();
  const {
    rows,
    pageSize,
    pageNumber,
    isLoading,
    tableRecordCounts,
    handlePaginationModelChange,
    handleSortModelChange,
    setSearchKeyword,
    searchKeyword,
    fetchData,
  } = useTableLogic('contracts');

  const navigate = useNavigate();

  useEffect(() => {
    document.title = `Presales-Manage Contracts`;
  }, []);

  //setvalues to the Inputs
  const handleEditClick = async (props) => {
    navigate(`edit/${props?.contractId}`);
  };

  //view the ShiftTiming
  const handleViewClick = async (view) => {
    setViewData(view);
    navigate(`view/${view?.contractId}`);
  };

  //handle Delete
  const handleDelete = async (props) => {
    await crud.remove('contracts', props?.contractId, (_err, res) => {
      if (res?.status === 204) {
        fetchData();
        toast.success('Contract Deleted Successfully');
      } else {
      }
    });
  };

  const ActionData = [
    {
      name: 'View',
      icon: Icons.view,
      onClick: (props) => handleViewClick(props),
    },
    {
      name: 'Edit',
      icon: Icons.edit,
      // onClick: () => setOpenCreate(!openCreate),
      onClick: (props) => handleEditClick(props),
    },
    {
      name: 'Delete',
      icon: Icons.delete,
      onClick: (props) => handleDelete(props),
      danger: true,
    },
  ];

  const columns = [
    {
      field: 'action',
      headerName: '',
      width: 90,
      sortable: false,
      disableClickEventBubbling: false,
      renderCell: (params) => {
        return (
          <TableActionButtons
            Actions={ActionData?.map((action) => ({
              ...action,
              onClick: () => action.onClick(params.row),
            }))}
          />
        );
      },
    },
    {
      field: 'contractStatusName',
      headerName: 'Status',
      size: 150,
      enableEditing: true,
      renderCell: (params) => {
        return (
          <StatusNewBadge
            title={params?.row?.contractStatusName}
            type={params?.row?.contractStatusName}
          />
        );
      },
    },
    {
      field: 'typeName',
      headerName: 'Project Type',
      minWidth: 120,
    },

    {
      field: 'contractCode',
      headerName: 'Contract Code',
      minWidth: 200,
      renderCell: (params) => {
        return (
          <TableAvatar
            name={params?.value}
            desc={
              <ExpiredContractDateColumns
                value={params?.row?.contractEndDate}
                terminationDate={params?.row?.terminationDate}
              />
            }
            removeImage
          />
        );
      },
    },

    {
      field: 'contractName',
      headerName: 'Contract',
      minWidth: 220,
    },

    {
      field: 'companyName',
      headerName: 'Company',
      minWidth: 220,
    },

    {
      field: 'projectName',
      headerName: 'Project',
      minWidth: 220,
    },
    {
      field: 'clientName',
      headerName: 'Client',
      minWidth: 250,
    },
    {
      field: 'contractStartDate',
      headerName: 'Contract Start/End Date',
      minWidth: 200,
      renderCell: (params) => {
        return (
          <ViewTableTwoDate
            data={[
              {
                name: 'Start',
                date: FmViewDateField({ value: params?.value }),
              },
              {
                name: 'End',
                date: FmViewDateField({
                  value: params?.row?.contractEndDate,
                }),
                red: true,
              },
            ]}
          />
        );
      },
    },

    {
      field: 'contactPerson',
      headerName: 'Contact Person',
      minWidth: 200,
      renderCell: (params) => {
        return (
          <TableAvatar name={params?.value} desc={params?.row?.contactNumber} />
        );
      },
    },
    {
      field: 'contactEmailId',
      headerName: 'Contact Email Id',
      minWidth: 170,
    },
    {
      field: 'contractCost',
      headerName: 'Contract Cost',
      minWidth: 150,
      renderCell: (params) => {
        return <CurrencyView value={params?.value} currencyType={'AED'} />;
      },
    },
    {
      field: 'budget',
      headerName: 'Budget',
      minWidth: 100,
      renderCell: (params) => {
        return <CurrencyView value={params?.value} currencyType={'AED'} />;
      },
    },
    {
      field: 'contractTerms',
      headerName: 'Contract Terms',
      minWidth: 250,
    },
    {
      field: 'perviousContractCode',
      headerName: (
        <DataGridHeaderTooltip name='Prev.CC' title='Previous Contract Code' />
      ),
      minWidth: 120,
    },
    {
      field: 'contractModeName',
      headerName: 'Contract Mode',
      minWidth: 150,
    },
    {
      field: 'referenceNumber',
      headerName: 'Reference Number',
      minWidth: 150,
    },
    {
      field: 'contractTypeName',
      headerName: 'Contract Type',
      minWidth: 150,
    },
    {
      field: 'contractScopeMaps',
      headerName: 'Contract Scope',
      minWidth: 300,
      valueGetter: (params) => {
        if (
          Array.isArray(params.row.contractScopeMaps) &&
          params.row.contractScopeMaps.length > 0
        ) {
          return params.row.contractScopeMaps
            .map((guarantee) => guarantee.contractScopeName)
            .join(', ');
        }
        return '';
      },
      cellClassName: 'nowrap-cell',
    },
    {
      field: 'billingFrequencyName',
      headerName: 'Billing Frequency',
      minWidth: 150,
    },
    {
      field: 'retentionPercentage',
      headerName: 'Retention(%)',
      minWidth: 150,
    },
    {
      field: 'thresholdValue',
      headerName: 'Threshold Value',
      minWidth: 150,
      renderCell: (params) => {
        return <CurrencyView value={params?.value} currencyType={'AED'} />;
      },
    },
    {
      field: 'currencyName',
      headerName: 'Currency',
      minWidth: 100,
    },
    {
      field: 'contractGuarantees',
      headerName: 'Guarantee',
      minWidth: 150,
      valueGetter: (params) => {
        if (
          Array.isArray(params.row.contractGuarantees) &&
          params.row.contractGuarantees.length > 0
        ) {
          return params.row.contractGuarantees
            .map((guarantee) => guarantee.guaranteeName)
            .join(', ');
        }
        return '';
      },
      cellClassName: 'nowrap-cell',
    },
    {
      field: 'guaranteeStartDate',
      headerName: 'Guarantee Date',
      minWidth: 180,
      renderCell: (params) => {
        return (
          <ViewTableTwoDate
            data={[
              {
                name: 'Start',
                date: FmViewDateField({ value: params?.value }),
              },
              {
                name: 'End',
                date: FmViewDateField({
                  value: params?.row?.guaranteeEndDate,
                }),
                red: true,
              },
            ]}
          />
        );
      },
    },

    {
      field: 'guaranteeAmount',
      headerName: 'Guarantee Amount',
      minWidth: 170,
      renderCell: (params) => {
        return <CurrencyView value={params?.value} currencyType={'AED'} />;
      },
    },

    {
      field: 'remarks',
      headerName: 'Remarks',
      minWidth: 150,
      cellClassName: 'nowrap-cell',
    },
  ];

  const onRowDoubleClick = (e) => {
    if (e?.row) {
      handleEditClick(e?.row);
    }
  };

  const excludeKeys = [
    'billingFrequencyId',
    'clientId',
    'contractId',
    'contractModeId',
    'contractScopeId',
    'projectTypeId',
    'contractTypeId',
    'currencyId',
    'divisionId',
    'guaranteeId',
    'projectId',
    'projectInChargeId',
    'status',
    'updatedDate',
  ];

  return (
    <>
      <DashboardLayout
        title='Contracts'
        actionButtons={
          <CreateButton name='New' onClick={() => navigate('create')} />
        }
      >
        <>
          {/* Listing */}
          {!viewData && (
            <GlassCard className='h-full-css'>
              <Box className='h-full-css'>
                <DataTable
                  rows={rows}
                  columns={columns}
                  loading={isLoading}
                  onRowDoubleClick={(e) => onRowDoubleClick(e)}
                  getRowClassName={(params) =>
                    `${
                      params.row.workingStatus === 'InActive' &&
                      'bg-red-500 bg-opacity-15'
                    }`
                  }
                  sortingMode='server'
                  paginationMode='server'
                  onPaginationModelChange={(model) =>
                    handlePaginationModelChange(model)
                  }
                  onSortModelChange={(model) => handleSortModelChange(model)}
                  page={pageNumber - 1}
                  pageSize={pageSize}
                  rowCount={tableRecordCounts?.total}
                  fetchData={fetchData}
                  slots={{
                    toolbar: () => (
                      <Box
                        className='p-2 w-full flex justify-between items-center'
                        sx={{
                          borderBottom: '1px solid',
                          borderColor: 'border.main',
                        }}
                      >
                        <Box sx={{ maxWidth: '350px', width: '100%' }}>
                          <TableSearch
                            placeholder='Search'
                            fullWidth
                            setSearchKeyword={setSearchKeyword}
                            searchValue={searchKeyword}
                          />
                        </Box>
                        <DataTableToolbarButtons
                          disableExport
                          disableImport
                          disablePrintCSV
                        />
                      </Box>
                    ),
                  }}
                />
              </Box>
            </GlassCard>
          )}
          {/* Listing */}
        </>

        {/* view */}
        {viewData && viewData?.contractId && (
          <>
            <BackToButton
              title='Back to List'
              className='px-2 mb-4'
              onClick={() => navigate(-1)}
            />
            <GlassCard className='p-5'>
              <CommonView
                url='contracts'
                id={viewData?.contractId}
                excludeKeys={excludeKeys}
                renderFields={(data, fields) => (
                  <DynamicViewFields data={data} fields={fields} />
                )}
              />
            </GlassCard>
          </>
        )}
      </DashboardLayout>
    </>
  );
};

export default ManageContracts;
