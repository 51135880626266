import React, { useState, useEffect } from 'react';
import { Box, Grid } from '@mui/material';
import { AdapterDayjs } from '@mui/x-date-pickers/AdapterDayjs';
import { LocalizationProvider } from '@mui/x-date-pickers/LocalizationProvider';
import FmTextField from '../../../../components/_mui/FmTextField.tsx';
import FmAutoComplete from '../../../../components/_mui/FmAutoComplete.tsx';
import GlassCard from '../../../../components/small/GlassCard.tsx';
import useLoader from '../../../../components/helpers/UseLoader.tsx';
import Crud_Service from '../../../../apis/CrudService';
import { toast } from 'react-toastify';
import dayjs from 'dayjs';
import { useNavigate, useParams } from 'react-router-dom';
import { ActionButtons } from '../../../../components/_form';
import FmSearchableSelect from '../../../../components/_mui/FmSearchableSelect.tsx';
import FmDatePicker from '../../../../components/_mui/FmDatePicker.tsx';
import { SkeletonLoaderRow } from './../../../../components/page/SkeletonLoader.tsx';
import SidePanelInfoBox from '../../../../components/layout/SidePanelInfoBox.tsx';
import {
  materialRequestTypeIdEnum,
  materialRequestStatusEnum,
} from '../../../../utils/CommonVariables.tsx';
import UploadSquareBoxesNew from './../../../../components/_form/UploadSquareBoxesNew.tsx';
import useCommonFetchApi from '../../../../components/helpers/useCommonFetchApi.tsx';

const GridValue = 3;

const AddMaterialRequest = ({
  control,
  setValue,
  getValues,
  reset,
  handleSubmit,
  location,
  region,
  watch,
  register,
  loading,
  editedData,
}) => {
  const [components, setComponents] = useState([]);
  const [regionId, setRegionId] = useState(null);
  const { id } = useParams();
  const { createLoading, stopCreateLoading, startCreateLoading } = useLoader();
  const navigate = useNavigate();
  const [salesOrderData, setSalesOrderData] = useState(null);
  const [requesterData, setRequesterData] = useState(null);
  const [sidePanelLoader, setSidePanelLoader] = useState(false);
  const [emptySide, setEmptySide] = useState(false);
  const crud = new Crud_Service();
  const [uploadGetData, setUploadGetData] = useState(null);

  const { data: materialrequisitionstatuses } = useCommonFetchApi(
    'materialrequisitionstatuses'
  ) || {
    data: [],
  };

  const getSalesOrderSingle = async (id) => {
    setSidePanelLoader(true);
    await crud.getAll(`salesorders/${id}`, {}, (err, res) => {
      if (err) {
        toast.error(err);
      }
      if (res?.status === 200) {
        setSalesOrderData(res?.data);
        setSidePanelLoader(false);
        setEmptySide(true);
      }
    });
  };

  const getTicketsSingle = async (id) => {
    setSidePanelLoader(true);
    await crud.getAll(`tickets/${id}`, {}, (err, res) => {
      if (err) {
        toast.error(err);
      }
      if (res?.status === 200) {
        setSidePanelLoader(false);
        setSalesOrderData(res?.data);
        setEmptySide(true);
      }
    });
  };

  const getRequesterSingle = async (id) => {
    if (!id) return;
    setSidePanelLoader(true);
    await crud.getAll(`loginusers/${id}`, {}, (err, res) => {
      if (err) {
        toast.error(err);
      }
      if (res?.status === 200) {
        setRequesterData(res?.data);
        setSidePanelLoader(false);
        setEmptySide(true);
      }
    });
  };

  useEffect(() => {
    register('upsertMaterialRequisitionFiles');
  }, [register]);

  const handleFileIdCreate = (fileId) => {
    if (typeof fileId === 'number') {
      const currentFiles = getValues('upsertMaterialRequisitionFiles') || [];
      const updatedFiles = [
        ...currentFiles,
        {
          materialRequisitionFileId: 0,
          fileUploadId: fileId,
        },
      ];
      setValue('upsertMaterialRequisitionFiles', updatedFiles);
    }
  };

  const handleFileIdDelete = async (data) => {
    if (!Array.isArray(data) || data.length === 0) return;

    const fileId = data[0]?.materialRequisitionFileId;

    await crud.remove(`materialrequisitionfiles/${fileId}`, '', (err, res) => {
      if (err) {
        toast.error(err);
      }
      if (res?.status === 204) {
        toast.success('File Deleted Successfully');
        window.location.reload();
      }
    });
  };

  const onSubmit = async (data) => {
    startCreateLoading();

    const uploadFilesNotGetted = data?.upsertMaterialRequisitionFiles || [];

    const combinedData = {
      ...data,
      requestDate: data?.requestDate
        ? dayjs(data?.requestDate).format('YYYY-MM-DD')
        : null,
      requestedDeliveryDate: data?.requestedDeliveryDate
        ? dayjs(data?.requestedDeliveryDate).format('YYYY-MM-DD')
        : null,
      mrTypeNo: data?.mrTypeNo ? data?.mrTypeNo : 0,
      status: id && 1,
      upsertMaterialRequisitionFiles: uploadFilesNotGetted,
    };

    if (id) {
      await crud.update(
        'materialrequisitions',
        id,
        combinedData,
        (err, res) => {
          if (err) {
            stopCreateLoading();
            return;
          }
          if (res?.status === 200) {
            toast.success('Material Request Updated successfully');
            stopCreateLoading();
            window.location.reload();
          } else {
            stopCreateLoading();
            toast.error('Something Went Wrong TestingEdit!');
          }
        }
      );
    } else {
      await crud.create('materialrequisitions', combinedData, (err, res) => {
        if (res?.status === 201) {
          toast.success('Material Request Created successfully');
          navigate(-1);
          stopCreateLoading();
        } else {
          stopCreateLoading();
          toast.error('Something Went Wrong Testing Create!');
        }
      });
    }
  };

  useEffect(() => {
    if (id) {
      if (
        editedData?.materialRequisitionTypeId ===
        materialRequestTypeIdEnum?.salesorder
      ) {
        getSalesOrderSingle(editedData?.mrTypeNo);
      } else if (
        editedData?.materialRequisitionTypeId ===
        materialRequestTypeIdEnum?.helpdesk
      ) {
        getTicketsSingle(editedData?.mrTypeNo);
      }
      getRequesterSingle(editedData?.requesterId);
      setUploadGetData(editedData?.materialRequisitionFiles || []);
    }
  }, [
    id,
    editedData?.materialRequisitionTypeId,
    editedData?.requesterId,
    editedData?.mrTypeNo,
  ]);

  return (
    <Box>
      <Box className={'w-full'}>
        <Grid spacing={2} container>
          {loading ? (
            <Grid item md={12}>
              <SkeletonLoaderRow count={10} />
            </Grid>
          ) : (
            <>
              <Grid item md={9}>
                <GlassCard className='p-4'>
                  <form onSubmit={handleSubmit(onSubmit)}>
                    <LocalizationProvider dateAdapter={AdapterDayjs}>
                      <Grid container spacing={2}>
                        <Grid item md={GridValue}>
                          <FmSearchableSelect
                            name='materialRequisitionTypeId'
                            control={control}
                            required
                            apiUrl='materialrequisitiontypes'
                            valueField='materialRequisitionTypeID'
                            labelField={['materialRequisitionTypeName']}
                            showField={['materialRequisitionTypeName']}
                            label={'MR Type'}
                            onChange={true}
                            defaultValue={{
                              materialRequisitionTypeId:
                                editedData?.materialRequisitionTypeId || '',
                              materialRequisitionTypeName:
                                editedData?.materialRequisitionTypeName || '',
                            }}
                            disabled={id ? true : false}
                          />
                        </Grid>

                        {watch('materialRequisitionTypeId') ===
                          materialRequestTypeIdEnum?.helpdesk && (
                          <Grid item md={GridValue}>
                            <FmSearchableSelect
                              name='mrTypeNo'
                              control={control}
                              apiUrl='tickets'
                              valueField='ticketId'
                              headerField={[
                                'Code',
                                'Request No',
                                'Requester Name',
                              ]}
                              labelField={[
                                'ticketCode',
                                'requestNo',
                                'requesterName',
                              ]}
                              showField={[
                                'autoSalesOrderCode',
                                'requestNo',
                                'requesterName,',
                              ]}
                              label={'Tickets'}
                              defaultValue={{
                                autoSalesOrderCode: editedData?.mrTypeNo || '',
                                requestNo: salesOrderData?.requesterName || '',
                              }}
                              onChangeProp={(value) => {
                                getTicketsSingle(value?.ticketId);
                                setValue(
                                  'contractId',
                                  value?.contractId || null
                                );
                                setValue(
                                  'divisionId',
                                  value?.divisionId || null
                                );
                                setValue('projectId', value?.projectId || null);
                                setValue('companyId', value?.companyId || null);
                              }}
                              disabled={id ? true : false}
                            />
                          </Grid>
                        )}

                        {getValues('materialRequisitionTypeId') ===
                          materialRequestTypeIdEnum?.salesorder && (
                          <Grid item md={GridValue}>
                            <FmSearchableSelect
                              name='mrTypeNo'
                              control={control}
                              apiUrl='salesorders'
                              valueField='salesOrderId'
                              headerField={['Code', 'Name']}
                              labelField={[
                                'autoSalesOrderCode',
                                'salesOrderName',
                              ]}
                              showField={[
                                'autoSalesOrderCode',
                                'salesOrderName',
                              ]}
                              label={'Sales Order'}
                              defaultValue={{
                                salesOrderId: editedData?.mrTypeNo || '',
                                salesOrderName:
                                  salesOrderData?.salesOrderName || '',
                              }}
                              onChangeProp={(value) => {
                                getSalesOrderSingle(value?.salesOrderId);
                                setValue(
                                  'contractId',
                                  value?.contractId || null
                                );
                                setValue(
                                  'divisionId',
                                  value?.divisionId || null
                                );
                                setValue('projectId', value?.projectId || null);
                                setValue('companyId', value?.companyId || null);
                              }}
                              disabled={id ? true : false}
                            />
                          </Grid>
                        )}

                        <Grid item md={GridValue}>
                          <FmDatePicker
                            name='requestDate'
                            control={control}
                            label='Request Date'
                            disabled={true}
                          />
                        </Grid>

                        <Grid item md={GridValue}>
                          <FmSearchableSelect
                            name='divisionId'
                            control={control}
                            apiUrl='divisions'
                            valueField='divisionId'
                            labelField={['divisionCode', 'divisionName']}
                            showField={['divisionCode', 'divisionName']}
                            label={'Divisions'}
                            defaultValue={{
                              divisionId: editedData?.divisionId || '',
                              divisionName: editedData?.divisionName || '',
                            }}
                          />
                        </Grid>
                        <Grid item md={GridValue}>
                          <FmSearchableSelect
                            name='requesterId'
                            control={control}
                            apiUrl='loginusers'
                            valueField='loginUserId'
                            headerField={['Code', 'Name']}
                            labelField={['employeeCode', 'loginUserName']}
                            showField={['employeeCode', 'loginUserName']}
                            label={'Requester'}
                            defaultValue={{
                              employeeCode: editedData?.requesterId || '',
                              loginUserName: editedData?.requesterName,
                            }}
                            onChangeProp={(value) => {
                              setRequesterData(value);
                              value && setEmptySide(true);
                              setValue('requestorEmailId', value?.employeeMail);
                            }}
                          />
                        </Grid>
                        <Grid item md={GridValue}>
                          <FmSearchableSelect
                            name='locationId'
                            control={control}
                            apiUrl='locations'
                            valueField='locationId'
                            labelField={['locationName']}
                            showField={['locationName']}
                            label={'Locations'}
                            defaultValue={{
                              locationId: editedData?.locationId || '',
                              locationName: editedData?.locationName || '',
                            }}
                          />
                        </Grid>

                        <Grid item md={GridValue}>
                          <FmSearchableSelect
                            name='storeLocationId'
                            control={control}
                            apiUrl='storeLocations'
                            queryparamValue={regionId}
                            queryparam={'regionId'}
                            valueField='storeLocationId'
                            labelField={['storeLocationName']}
                            showField={['storeLocationName']}
                            pageSize={50}
                            label='Store Location'
                            defaultValue={{
                              storeLocationId:
                                editedData?.storeLocationId || '',
                              storeLocationName:
                                editedData?.storeLocationName || '',
                            }}
                            onChangeProp={(value) => {
                              setValue('regionId', value?.regionId);
                            }}
                          />
                        </Grid>

                        <Grid item md={GridValue}>
                          <FmTextField
                            name='unitId'
                            label='Unit No'
                            control={control}
                            rules={{ required: 'Unit No Id is required' }}
                          />
                        </Grid>

                        <Grid item md={GridValue}>
                          <FmDatePicker
                            name='requestedDeliveryDate'
                            control={control}
                            label='Request Delivery Date'
                          />
                        </Grid>

                        {id ? (
                          <Grid item md={GridValue}>
                            <FmAutoComplete
                              name='materialRequisitionStatusId'
                              control={control}
                              options={materialrequisitionstatuses}
                              label='Material requisition Status'
                              displayField='materialRequisitionStatusName'
                              optionFields={['materialRequisitionStatusName']}
                              valueKey='materialRequisitionStatusId'
                            />
                          </Grid>
                        ) : (
                          <input
                            name='materialRequisitionStatusId'
                            value={materialRequestStatusEnum?.Draft}
                            type='hidden'
                            {...register('materialRequisitionStatusId')}
                          />
                        )}

                        <Grid item md={GridValue * 2}>
                          <FmTextField
                            name='remarks'
                            control={control}
                            multiline={true}
                            rows={3}
                            maxRows={3}
                            minRows={3}
                            label='Remarks'
                          />
                        </Grid>

                        <Grid item md={GridValue * 2}>
                          <UploadSquareBoxesNew
                            getFiles={
                              editedData?.materialRequisitionFiles || []
                            }
                            handleFileIdCreate={handleFileIdCreate}
                            getUploadedFileState={uploadGetData}
                            handleFileIdDelete={handleFileIdDelete}
                          />
                        </Grid>

                        <Grid item md={12}>
                          <ActionButtons
                            onSubmit={handleSubmit(onSubmit)}
                            onCancel={() => navigate(-1)}
                            onReset={reset}
                            cancelText='Back'
                            submitLoading={createLoading}
                            cancelLoading={false}
                            submitText={id ? 'Update' : 'Submit'}
                            removeSpacing
                            // errorFields={!isValid}
                          />
                        </Grid>
                      </Grid>
                    </LocalizationProvider>
                  </form>
                </GlassCard>
              </Grid>

              <Grid item md={3}>
                <Box className='h-full w-full relative'>
                  <Box className='absolute left-0 top-0 w-full h-full overflow-auto'>
                    {emptySide && (
                      <SidePanelInfoBox
                        infoData={[
                          getValues('materialRequisitionTypeId') ===
                            materialRequestTypeIdEnum?.salesorder && {
                            name: 'salesorder',
                            source: salesOrderData || false,
                          },
                          getValues('materialRequisitionTypeId') ===
                            materialRequestTypeIdEnum?.helpdesk && {
                            name: 'tickets',
                            source: salesOrderData || false,
                          },
                          {
                            name: 'requester',
                            source: requesterData || false,
                          },
                        ]}
                        loader={sidePanelLoader}
                      />
                    )}
                  </Box>
                </Box>
              </Grid>
            </>
          )}
        </Grid>
      </Box>
    </Box>
  );
};

export default AddMaterialRequest;
