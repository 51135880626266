import { Icon } from '@iconify/react';
import { Box, Stack } from '@mui/material';
import React, { useEffect, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { toast } from 'react-toastify';
import Crud_Service from '../../../apis/CrudService';
import { CommonTableStatus } from '../../../components/_form/FormElements.jsx';
import TableActionButtons from '../../../components/_form/TableActionButtons.jsx';
import { TableAvatarStatus } from '../../../components/_form/TableAvatar.jsx';
import TableSearch from '../../../components/_form/TableSearch.jsx';
import FmViewDateField from '../../../components/_mui/FmViewDateField.tsx';
import { ToolbarBoxesFlex } from '../../../components/boxes/Boxes.jsx';
import EnquiryButtonShape from '../../../components/boxes/EnquiryButtonShape.tsx';
import DashboardLayout from '../../../components/DashboardLayout.tsx';
import DataGridHeaderTooltip from '../../../components/datagrid/DataGridHeaderTooltip.tsx';
import TableExtraContentTooltip from '../../../components/datagrid/TableExtraContentTooltip.tsx';
import TableYesWithCaptions from '../../../components/datagrid/TableYesWithCaptions.tsx';
import TextElipseTooltip from '../../../components/datagrid/TextElipseTooltip.tsx';
import DataTable from '../../../components/DataTable.tsx';
import DataTableToolbarButtons from '../../../components/helpers/DataTableToolbarButtons.tsx';
import PageHeader from '../../../components/PageHeader';
import { CreateButton } from '../../../components/small/Buttons';
import GlassCard from '../../../components/small/GlassCard.tsx';
import ViewColoredDate from '../../../components/view/ViewColoredDate.tsx';
import ViewTableLightText from '../../../components/view/ViewTableLightText.tsx';
import Icons from '../../../utils/Icon.jsx';
import { statusColorsMap, statusIconMap } from './EnqExportElement.tsx';

const Enquiry = () => {
  const navigate = useNavigate();
  const [rows, setRows] = useState([]);
  const [statusActive, setStatusActive] = useState(null);
  const [loading, setLoading] = useState(false);
  const [enquiryCounts, setEnquiryCounts] = useState([]);
  const [tablerecordCounts, setTableRecordCounts] = useState(null);
  const [pageSize, setPageSize] = useState(50);
  const [pageNumber, setPageNumber] = useState(1);
  const [searchKeyword, setSearchKeyword] = useState('');
  const [sortBy, setSortBy] = useState('');
  const [sortDirection, setSortDirection] = useState('');

  const crud = new Crud_Service();

  useEffect(() => {
    getAllEnquiryCounts();
    document.title = `Presales | Enquiry`;
  }, []);

  useEffect(() => {
    if (enquiryCounts?.length > 0) {
      const openIndex = enquiryCounts.findIndex(
        (item) => item.tenderStatusId === 1
      );
      const defaultIndex = openIndex !== -1 ? openIndex : null;
      const defaultStatusId = enquiryCounts[defaultIndex]?.tenderStatusId || 0;
      handleStatusClick(defaultIndex, defaultStatusId);
      setStatusActive(defaultIndex);
    }
  }, [
    enquiryCounts,
    pageNumber,
    pageSize,
    searchKeyword,
    sortBy,
    sortDirection,
  ]);

  const getAllEnquiryCounts = async () => {
    await crud.getAll('tenders/status-count', '', (err, res) => {
      if (err) {
        toast.error('Error fetching Enquiry', err);
        return;
      }
      if (res?.status === 200) {
        setEnquiryCounts(res?.data?.data);
      } else {
        setEnquiryCounts([]);
      }
    });
  };

  //Load Grid Data Based on Selected Enquiries
  const handleStatusClick = async (index, tenderStatusId) => {
    setLoading(true);
    setStatusActive(index);
    await crud.getAll(
      `tenders`,
      {
        pageSize,
        pageNumber,
        tenderStatusId,
        searchKeyword,
        sortBy,
        sortDirection,
      },
      (err, res) => {
        if (err) {
          toast.error('Error fetching Tenders:', err);
          setLoading(false);
          return;
        }
        if (res?.status === 200) {
          setTableRecordCounts(res?.data);
          const data = res?.data?.data || [];
          const transformedData = data.map((row) => ({
            ...row,
            id: row?.tenderId,
          }));
          setRows(transformedData);
        } else {
          setRows([]);
        }
        setLoading(false);
      }
    );
  };

  //Pagination and Filter
  const handlePagination = (model) => {
    setPageSize(model?.pageSize);
    setPageNumber(model?.page + 1);
  };

  const handleSortChange = (sortModel) => {
    if (sortModel.length > 0) {
      setSortBy(sortModel[0].field);
      setSortDirection(sortModel[0].sort);
    } else {
      setSortBy('');
      setSortDirection('');
    }
  };

  const handleViewClickOpen = async (props) => {
    navigate(`view/${props?.tenderId}`, {
      state: { tenderResponsiblePersonId: props?.tenderResponsiblePersonId },
    });
  };

  const handleEditClick = (props) => {
    navigate(`edit/${props?.tenderId}`);
  };

  const handleDelete = async (props) => {
    await crud.remove('tenders', props?.tenderId, (_err, res) => {
      if (res?.status === 204) {
        handleStatusClick(0, 0);
        getAllEnquiryCounts();
        toast.success('Enquiry Deleted Successfully');
      } else {
      }
    });
  };
  const Actions = [
    {
      name: 'View',
      icon: Icons.view,
      onClick: (props) => handleViewClickOpen(props),
    },
    {
      name: 'Edit',
      icon: Icons.edit,
      onClick: (props) => handleEditClick(props),
    },
    {
      name: 'Delete',
      icon: Icons.delete,
      onClick: (props) => handleDelete(props),
      danger: true,
    },
  ];

  const columns = [
    {
      field: 'action',
      headerName: '',
      flex: 1,
      minWidth: 80,
      cellClassName: 'p-0',
      align: 'center',
      sortable: false,
      disableClickEventBubbling: false,
      renderCell: (params) => {
        return (
          <TableActionButtons
            Actions={Actions?.map((action) => ({
              ...action,
              onClick: () => action.onClick(params.row),
            }))}
          />
        );
      },
    },

    {
      field: 'tenderName',
      headerName: 'Tender Name',
      minWidth: 220,
      flex: 1,
      Sort: 'asc',
      renderCell: (params) => {
        return (
          <TableAvatarStatus
            name={params.value}
            desc={
              <Box className='flex items-center gap-1'>
                <DataGridHeaderTooltip name='E.No:' title='Tender Number' />
                {params.row.tenderCode}
              </Box>
            }
            icon={statusIconMap[params?.row?.tenderStatusName] || null}
            color={statusColorsMap[params?.row?.tenderStatusName] || null}
            title={params?.row?.tenderStatusName}
            avatar={false}
          />
        );
      },
    },
    {
      field: 'tenderRefNo',
      headerName: (
        <DataGridHeaderTooltip name='Tender Ref No' title='Tender Ref No' />
      ),
      minWidth: 200,
      flex: 1,
      renderCell: (params) => {
        return (
          <>
            <Box className='w-full'>
              <Box>{params.value}</Box>
            </Box>
          </>
        );
      },
    },

    {
      field: 'companyName',
      headerName: (
        <DataGridHeaderTooltip name='Company' title='Quote By Company' />
      ),
      minWidth: 200,
      flex: 1,
      renderCell: (params) => {
        return (
          <>
            <Box className='w-full'>
              <Box>{params.value}</Box>
            </Box>
          </>
        );
      },
    },
    {
      field: 'divisionName',
      headerName: 'Division & Emirate',
      minWidth: 200,
      flex: 1,
      renderCell: (params) => {
        return (
          <Box className='w-full'>
            <TextElipseTooltip name={params.value} />
            <ViewTableLightText
              name={
                <Box>
                  {Array.isArray(params.row?.emirateName)
                    ? params.row.emirateName.map((name, index) => (
                        <div key={index}>
                          <span className='font-semibold'>Emirate:</span> {name}
                        </div>
                      ))
                    : params.row?.emirateName && (
                        <div>
                          <span className='font-semibold'>Emirate:</span>{' '}
                          {params.row.emirateName}
                        </div>
                      )}
                </Box>
              }
            />
          </Box>
        );
      },
    },

    {
      field: 'tenderEnquiryTypeName',
      headerName: 'Mode of Submission',
      minWidth: 200,
      flex: 1,
      renderCell: (params) => {
        return (
          <Box>
            <Box>{params?.value}</Box>
          </Box>
        );
      },
    },

    {
      field: 'bidBond',
      headerName: '',
      headerAlign: 'center',
      minWidth: 200,
      flex: 1,
      sortable: false,
      renderCell: (params) => {
        return (
          <Stack direction={'row'} spacing={2}>
            <TableYesWithCaptions
              type={params.value}
              name={'BidBond'}
              tooltipName='BidBond'
            />

            <TableYesWithCaptions
              type={params?.row?.buyTenderDocument}
              name={'BTD'}
              tooltipName='Buy Enquiry Document'
            />
            <TableYesWithCaptions
              type={params?.row?.expressOfInterest}
              title={params?.row?.expressOfInterest === true ? 'Yes' : 'No'}
              name={'EOI'}
              tooltipName='Express of Interest'
            />
          </Stack>
        );
      },
    },

    {
      field: 'createdDate',
      headerName: 'Date',
      minWidth: 130,
      flex: 1,
      renderCell: (params) => {
        return (
          <TableExtraContentTooltip
            titleRows={[
              { label: 'Created Date', title: params?.row?.createdDate },
              { label: 'Updated Date', title: params?.row?.updatedDate },
              { label: 'Submission Date', title: params?.row?.subMissionDate },
              { label: 'EOI Date', title: params?.row?.expressOfInterestDate },
            ]}
            dark={true}
          >
            <ViewColoredDate
              date={FmViewDateField({ value: params?.value })}
              originalDate={params?.value}
              icon={<Icon icon='mdi-light:calendar' />}
            />
          </TableExtraContentTooltip>
        );
      },
    },
    {
      field: 'status',
      headerName: 'Enquiry Status',
      minWidth: 150,
      renderCell: (params) => {
        const statusName = params?.row?.tenderStatusName;
        const statusColor =
          {
            Closed: 'gray',
            Open: 'blue',
            'Submitted Under Follow-up': 'purple',
            'Under Follow-up': 'orange',
            Awarded: 'green',
            'Not Awarded': 'red',
            Cancelled: 'darkred',
            'UpComing Tender': 'blue',
            'Tender Closed': 'gray',
          }[statusName] || ''; // Default to no color if statusName is not matched

        return <CommonTableStatus name={statusName} color={statusColor} />;
      },
    },
    {
      field: 'remarks',
      headerName: 'Remarks',
      minWidth: 220,
      flex: 1,
      renderCell: (params) => {
        return <TextElipseTooltip name={params.value} twoline />;
      },
    },
  ];

  const counters = [];

  const onRowDoubleClick = (e) => {
    if (e?.row) {
      handleViewClickOpen(e?.row);
    }
  };

  return (
    <DashboardLayout
      title='Enquiries'
      actionButtons={
        <>
          <Box className='flex flex-row items-center whitespace-nowrap gap-3'>
            <PageHeader counters={counters} />
            <Box>
              <CreateButton name={'New'} onClick={() => navigate('create')} />
            </Box>
          </Box>
        </>
      }
    >
      <Box className='flex flex-col h-full gap-2'>
        <GlassCard className='flex w-full overflow-hidden gap-1.5 flex-wrap p-1 '>
          <EnquiryButtonShape
            name='All'
            count={enquiryCounts.reduce((sum, item) => sum + item.count, 0)}
            onClick={() => handleStatusClick(null, 0)}
            active={statusActive === null}
            icon={<Icon icon='fluent:mail-unread-24-regular' />}
          />
          {enquiryCounts
            ?.sort((a, b) => a?.tenderStatusId - b?.tenderStatusId)
            .map((item, index) => (
              <EnquiryButtonShape
                key={item?.tenderStatusId}
                name={item?.tenderStatusName}
                count={item?.count}
                onClick={() => {
                  handleStatusClick(index, item?.tenderStatusId);
                }}
                active={statusActive === index}
                icon={statusIconMap[item?.tenderStatusName]}
              />
            ))}
        </GlassCard>

        <GlassCard className='h-full-css flex-1 min-h-0 overflow-hidden'>
          <Box className='h-full-css'>
            <DataTable
              rows={rows}
              columns={columns}
              loading={loading}
              onRowDoubleClick={(e) => onRowDoubleClick(e)}
              getRowClassName={(params) =>
                `${
                  params.row.workingStatus === 'InActive' &&
                  'bg-red-500 bg-opacity-15'
                }`
              }
              pageSizeOptions={[2, 10, 20, 50, 100]}
              sortingMode='server'
              paginationMode='server'
              onPaginationModelChange={(model) => handlePagination(model)}
              onSortModelChange={(model) => handleSortChange(model)}
              page={pageNumber - 1}
              pageSize={pageSize}
              rowCount={tablerecordCounts?.total}
              fetchData={() => handleStatusClick(0, 0)}
              slots={{
                toolbar: () => (
                  <ToolbarBoxesFlex>
                    <Box className='w-full' sx={{ maxWidth: '350px' }}>
                      <TableSearch
                        placeholder='Search'
                        fullWidth
                        setSearchKeyword={setSearchKeyword}
                        searchValue={searchKeyword}
                      />
                    </Box>

                    <DataTableToolbarButtons disableExport />
                  </ToolbarBoxesFlex>
                ),
              }}
              showSerialNumber={true}
            />
          </Box>
        </GlassCard>
      </Box>
    </DashboardLayout>
  );
};

export default Enquiry;
