import React, { useState } from 'react';
import { Box, Radio } from '@mui/material';
import { Icon } from '@iconify/react';

const options = [
  { label: 'Default', value: 'info', icon: 'mdi:information-outline' },
  {
    label: 'Direct',
    value: 'direct',
    icon: 'mdi:account-badge-outline',
  },
  { label: 'Email', value: 'email', icon: 'mdi:email-multiple-outline' },
  {
    label: 'News Paper',
    value: 'newspaper',
    icon: 'mdi:newspaper-variant-multiple-outline',
  },
  {
    label: 'Online Portal',
    value: 'portal',
    icon: 'mdi:world-wide-web',
  },
];

const FMSelectWithIcons = () => {
  const [selectedValue, setSelectedValue] = useState(null);

  const handleChange = (event) => {
    setSelectedValue(event.target.value);
  };

  return (
    <Box className='w-full mb-1' sx={{ color: 'text.main' }}>
      <Box className='mb-2'>Choose Icon</Box>
      <Box className='flex gap-2 flex-wrap'>
        {options.map((option) => (
          <Box
            key={option.value}
            onClick={() => setSelectedValue(option.value)}
            className='flex items-center gap-2 p-2'
            sx={{
              border: '1px solid',
              borderColor:
                selectedValue === option.value ? 'primary.main' : 'border.dark',
              color:
                selectedValue === option.value ? 'primary.main' : 'inherit',
              borderRadius: '5px',
              cursor: 'pointer',
              bgcolor:
                selectedValue === option.value
                  ? 'background.mainLightBase'
                  : 'background.light',
            }}
          >
            <Radio
              checked={selectedValue === option.value}
              onChange={handleChange}
              value={option.value}
              className='p-0'
              size='small'
            />
            <Box>{option.label}</Box>
            <Icon icon={option.icon} fontSize={22} />
          </Box>
        ))}
      </Box>
    </Box>
  );
};

export default FMSelectWithIcons;
