import React from 'react';
import {
  Modal,
  Box,
  IconButton,
  TextField,
  Stack,
  InputAdornment,
} from '@mui/material';
import { Icon } from '@iconify/react';

const ModalWithSearch = ({
  open,
  onClose,
  searchPlaceholder,
  searchValue,
  searchHandleChange,
  modalWidth,
  disableRestoreFocus,
  children,
}) => {
  return (
    <>
      <Modal
        open={open}
        onClose={onClose}
        disableRestoreFocus={disableRestoreFocus}
      >
        <Box className='flex flex-row items-center justify-center min-h-screen'>
          <Box
            sx={{
              width: modalWidth ? `${modalWidth}px` : '850px',
              borderRadius: '10px',
              overflow: 'hidden',
              outline: 'none',
              bgcolor: 'background.white',
              color: 'text.main',
              boxShadow: 10,
            }}
          >
            {/* header */}
            <Box
              sx={{
                display: 'flex',
                justifyContent: 'space-between',
                alignItems: 'center',
              }}
              className='p-4 px-4'
            >
              <Stack
                spacing={2}
                direction='row'
                className='w-full'
                justifyContent='space-between'
              >
                <TextField
                  placeholder={searchPlaceholder}
                  id='outlined-size-small'
                  size='small'
                  type='search'
                  value={searchValue}
                  fullWidth
                  autoComplete='off'
                  InputProps={{
                    startAdornment: (
                      <InputAdornment
                        position='start'
                        sx={{
                          fontSize: '22px',
                          color: 'inherit',
                          opacity: 0.5,
                        }}
                      >
                        <Icon icon='material-symbols:search' />
                      </InputAdornment>
                    ),
                    autoFocus: true,
                  }}
                  onChange={searchHandleChange}
                  autoFocus={true}
                  sx={{
                    '& .MuiInputBase-root': {
                      bgcolor: 'background.white',
                      borderRadius: '5px',
                      color: 'text.main',
                      border: '1px solid',
                      borderColor: 'border.main',
                    },

                    '& .MuiOutlinedInput-notchedOutline': {
                      display: 'none',
                    },

                    '&.Mui-focused': {
                      borderColor: '#ddd',
                    },
                  }}
                />

                <IconButton
                  onClick={onClose}
                  sx={{ color: 'inherit', '& svg': { fontSize: '25px' } }}
                  disableRipple
                >
                  <Icon icon='material-symbols:close' />
                </IconButton>
              </Stack>
            </Box>
            {/* header */}
            {children}
          </Box>
        </Box>
      </Modal>
    </>
  );
};

export default ModalWithSearch;
