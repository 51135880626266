import React from 'react';
import DashboardLayout from '../../../../components/DashboardLayout.tsx';
import Nav from '../../../../utils/Nav.tsx';
import AddAdvertisement from './AddAdvertisement.tsx';
import useTableLogic from '../../../../components/helpers/MRTUseTableLogic.tsx';
import AdvertisementList from './AdvertisementList.tsx';
import { CreateButton } from '../../../../components/small/Buttons.jsx';
import { useNavigate } from 'react-router-dom';

const Advertisement = () => {
  const {
    rows,
    rowCount,
    isLoading,
    pagination,
    sorting,
    columnFilters,
    globalFilter,
    tableRecordCounts,
    setPagination,
    setSorting,
    setColumnFilters,
    setGlobalFilter,
    fetchData,
    setSearchKeyword,
    searchKeyword,
  } = useTableLogic('vehicleadvertisements');
  const navigate = useNavigate();

  return (
    <DashboardLayout
      title='Advertisement'
      hasSubmenu
      actionButtons={
        <CreateButton name='Create' onClick={() => navigate('create')} />
      }
      menu={Nav[10].child[7].children}
    >
      {/* <AddAdvertisement fetchData={fetchData} /> */}
      <AdvertisementList
        rows={rows}
        rowCount={rowCount}
        isLoading={isLoading}
        pagination={pagination}
        sorting={sorting}
        columnFilters={columnFilters}
        globalFilter={globalFilter}
        tableRecordCounts={tableRecordCounts}
        setPagination={setPagination}
        setSorting={setSorting}
        setColumnFilters={setColumnFilters}
        setGlobalFilter={setGlobalFilter}
        fetchData={fetchData}
        searchKeyword={searchKeyword}
        setSearchKeyword={setSearchKeyword}
      />
    </DashboardLayout>
  );
};

export default Advertisement;
