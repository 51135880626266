import { Grid, Box, Button, Icon, Typography } from '@mui/material';
import dayjs from 'dayjs';
import React, { useEffect, useState } from 'react';
import { useForm } from 'react-hook-form';
import { toast } from 'react-toastify';
import Crud_Service from '../../../../apis/CrudService.jsx';
import { ActionButtons } from '../../../../components/_form';
import { ViewField } from '../../../../components/_form/FormElements';
import FmDatePicker from '../../../../components/_mui/FmDatePicker.tsx';
import FmSearchableSelect from '../../../../components/_mui/FmSearchableSelect.tsx';
import FmTextField from '../../../../components/_mui/FmTextField.tsx';
import MuiDialogFullscreen from '../../../../components/_mui/MuiDialogFullscreen.tsx';
import MuiDialogOne from '../../../../components/_mui/MuiDialogOne';
import FileViewer from '../../../../components/elements/FileViewer.tsx';
import useLoader from '../../../../components/helpers/UseLoader.tsx';
import GlassCard from '../../../../components/small/GlassCard.tsx';
import UploadModalBox from '../../../../components/upload/UploadModalBox.tsx';
import { useNavigate, useParams } from 'react-router-dom';
import DashboardLayout from '../../../../components/DashboardLayout.tsx';
import { yupResolver } from '@hookform/resolvers/yup';
import { tenderInspectionvalidationSchema } from '../../../../utils/CommonvalidationSchemas.tsx';

const TenderInspectionCheckListCreate = () => {
  const clientDetails = [
    { key: 'clientName', title: 'Client Name' },
    { key: 'clientContactPerson', title: 'Contact Person' },
    { key: 'clientMail', title: 'Email' },
    { key: 'clientContactNo', title: 'Contact Number' },
  ];
  const crud = new Crud_Service();
  const [tenderChecklist, setTenderChecklist] = useState(null);
  const [scopeNotes, setScopeNotes] = useState([]);
  const [uploadedFileIds, setUploadedFileIds] = useState([]);
  const [files, setFiles] = useState([]);
  const { startCreateLoading, stopCreateLoading, createLoading } = useLoader();
  const [previewdData, setPreviewData] = useState([]);
  const [previewCheckListData, setPreviewCheckListData] = useState([]);
  const [viewScopeNotes, setViewScopeNotes] = useState(false);
  const [scopeFileViewer, setScopeFileViewer] = useState(false);
  const [tenderChecklistDetailId, setTenderChecklistDetailId] = useState(null);
  const [editData, setEditData] = useState(null);
  const navigate = useNavigate();
  const { id } = useParams();
  const GridValue = 3;
  const {
    handleSubmit: creatCheckListSubmit,
    control: creatCheckListControl,
    setValue: creatCheckListSetValue,
    reset,
  } = useForm({
    resolver: yupResolver(tenderInspectionvalidationSchema),
  });
  const handleFileIds = (fileIds) => {
    setUploadedFileIds(fileIds);
  };
  const handleSubmitTenderChecklist = async (values) => {
    startCreateLoading();

    const formattedData = {
      ...values,
      siteVisitDate: dayjs(values?.siteVisitDate).isValid()
        ? dayjs(values?.siteVisitDate).format('YYYY-MM-DD')
        : null,

      tenderId: Number(id),
    };

    // Create API call
    const createData = {
      ...formattedData,
      fileUploadIds: uploadedFileIds || [],
    };

    await crud.create(`tenderinspectionchecklists`, createData, (err, res) => {
      stopCreateLoading();
      if (err) {
        toast.error(err);
      } else if (res?.status === 201) {
        toast.success('Tender Checklist Created Successfully');
        navigate(-1);
      }
    });
  };

  useEffect(() => {
    const fetchTenderChecklist = async () => {
      if (id) {
        await crud.getSingle(`tenders`, id, (err, res) => {
          if (res?.status === 200) {
            setTenderChecklist(res?.data);
            const value = res?.data;
            creatCheckListSetValue('clientId', value?.clientId);
            creatCheckListSetValue('clientName', value?.clientName);
            creatCheckListSetValue(
              'ClientContactPerson ',
              value?.ClientContactPerson
            );
            creatCheckListSetValue('ClientMail', value?.ClientMail);
            creatCheckListSetValue('ClientContactNo', value?.ClientContactNo);
          }
        });
      }
    };

    fetchTenderChecklist();
    // CheckTenderChecklist();
  }, [id]);

  
  return (
    <DashboardLayout title='Create Tender Checklist'>
      <form onSubmit={creatCheckListSubmit(handleSubmitTenderChecklist)}>
        <GlassCard className='p-4 mb-4'>
          <Grid container spacing={2}>
            {/* Tender Selection */}
            <Grid item md={GridValue}>
              <FmSearchableSelect
                name='tenderId'
                control={creatCheckListControl}
                apiUrl='tenders'
                valueField='tenderId'
                labelField={['tenderCode', 'tenderName']}
                showField={['tenderCode', 'tenderName']}
                disabled
                defaultValue={
                  tenderChecklist
                    ? {
                        tenderId: tenderChecklist?.tenderId,
                        tenderCode: tenderChecklist?.tenderCode,
                        tenderName: tenderChecklist?.tenderName,
                      }
                    : null
                }
                label={'Tenders'}
              />
            </Grid>

            {/* Tender Reference Number */}
            <Grid item md={GridValue}>
              <FmTextField
                defaultValue={tenderChecklist?.tenderRefNo}
                name='tenderRegNo'
                label='Tender Reg No'
                control={creatCheckListControl}
                disabled
              />
            </Grid>

            <Grid item md={12}>
              {/* Client Details */}
              <GlassCard className='p-2' bgLight={true}>
                <Grid container spacing={2}>
                  {tenderChecklist &&
                    clientDetails?.map(
                      (detail) =>
                        tenderChecklist[detail.key] && (
                          <Grid item md={GridValue} key={detail.key}>
                            <ViewField
                              label={detail?.title}
                              title={tenderChecklist[detail?.key]}
                            />
                          </Grid>
                        )
                    )}
                </Grid>
              </GlassCard>
            </Grid>

            {/* Site Visit Details */}
            <Grid item md={GridValue}>
              <FmDatePicker
                name='siteVisitDate'
                label='Site Visit Date'
                control={creatCheckListControl}
              />
            </Grid>
            <Grid item md={GridValue}>
              <FmSearchableSelect
                name='siteVisitDoneBy'
                control={creatCheckListControl}
                apiUrl='loginusers'
                valueField='loginUserId'
                headerField={['Code', 'Name', 'Email']}
                labelField={['employeeCode', 'employeeName', 'employeeMail']}
                showField={['employeeCode', 'employeeName', 'employeeMail']}
                label={'Site Visited Done By'}
              />
            </Grid>

            {/* Location Details */}
            <Grid item md={GridValue}>
              <FmTextField
                name='locationName'
                label='Location Name'
                control={creatCheckListControl}
              />
            </Grid>
            <Grid item md={GridValue}>
              <FmTextField
                name='buildingName'
                label='Building Name'
                control={creatCheckListControl}
                required
              />
            </Grid>

            {/* Upload Modal */}
            <Grid item md={GridValue}>
              <UploadModalBox
                onSubmitFiles={handleFileIds}
                files={files}
                setFiles={setFiles}
                previewdData={previewCheckListData}
              />
            </Grid>
            <Grid item md={GridValue}>
              <FmTextField
                name='municipalityNumber'
                control={creatCheckListControl}
                label='Municipality Number'
              />
            </Grid>
            <Grid item md={GridValue}>
              <FmTextField
                name='areaSqt'
                control={creatCheckListControl}
                label='Area Square Meter'
                type='number'
              />
            </Grid>
            {/* Additional Details */}

            <Grid item md={GridValue}>
              <FmTextField
                name='plotNumber'
                control={creatCheckListControl}
                label='Plot Number'
                type='number'
              />
            </Grid>
            <Grid item md={GridValue}>
              <FmTextField
                name='existingManpowerDetail'
                label='Existing Manpower Detail'
                control={creatCheckListControl}
                rows={3}
                multiline
              />
            </Grid>
            <Grid item md={GridValue}>
              <FmTextField
                name='existServiceProvider'
                label='Existing Contract Name'
                control={creatCheckListControl}
                rows={3}
                multiline
              />
            </Grid>

            <Grid item md={GridValue}>
              <FmTextField
                name='remarks'
                label='Remarks'
                control={creatCheckListControl}
                rows={3}
                multiline
              />
            </Grid>
          </Grid>
        </GlassCard>

        {/* Scope Notes Section */}
        <Box>
          <Box className='flex gap-3 items-center'>
            <Button
              variant='outlined'
              startIcon={<Icon icon='cil:notes' width='22' height='22' />}
              className='font-semibold min-w-48'
              onClick={() => setViewScopeNotes(!viewScopeNotes)}
            >
              View Scope Notes
            </Button>
            <Button
              variant='outlined'
              startIcon={
                <Icon
                  icon='material-symbols-light:attachment'
                  width='24'
                  height='24'
                />
              }
              className='font-semibold min-w-48'
              onClick={() => setScopeFileViewer(!scopeFileViewer)}
            >
              View Scope Attachment
            </Button>
          </Box>
          <MuiDialogOne
            title='Scope Notes'
            open={viewScopeNotes}
            onClose={() => setViewScopeNotes(!viewScopeNotes)}
            width='1000px'
          >
            <Box>
              {scopeNotes?.map((note, index) => (
                <Box
                  key={index}
                  dangerouslySetInnerHTML={{
                    __html: note.subject,
                  }}
                  sx={{
                    '& p': {
                      margin: '10px 0',
                    },
                    '& a': {
                      fontWeight: 600,
                      textDecoration: 'underline',
                    },
                  }}
                />
              ))}
              {scopeNotes?.length === 0 && (
                <Typography
                  variant='body2'
                  sx={{ color: 'gray', textAlign: 'center', py: 2 }}
                >
                  No scope notes available
                </Typography>
              )}
            </Box>
          </MuiDialogOne>

          <MuiDialogFullscreen
            title='Scope Notes'
            open={scopeFileViewer}
            onClose={() => setScopeFileViewer(!scopeFileViewer)}
          >
            <FileViewer
              documents={[
                {
                  uri: '/images/sample.pdf',
                },
              ]}
              onClose={() => setScopeFileViewer(!scopeFileViewer)}
              name={'File Name'}
            />
          </MuiDialogFullscreen>
        </Box>

        {/* Action Buttons */}
        <Box className='mb-4'>
          <ActionButtons
            onSubmit={creatCheckListSubmit(handleSubmitTenderChecklist)}
            onCancel={() => navigate(-1)}
            submitLoading={createLoading}
            cancelLoading={false}
            submitText={
              tenderChecklistDetailId?.tenderInspectionChecklistId
                ? 'Update'
                : 'Save'
            }
          />
        </Box>
      </form>
    </DashboardLayout>
  );
};

export default TenderInspectionCheckListCreate;
