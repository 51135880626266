import React, { useEffect, useState } from 'react';
import DashboardLayout from '../../../../components/DashboardLayout.tsx';
import { Box, FormControlLabel, Grid, Radio, RadioGroup } from '@mui/material';
import GlassCard from '../../../../components/small/GlassCard.tsx';
import ActionButtons from '../../../../components/_form/ActionButtons.tsx';
import { useNavigate, useParams } from 'react-router-dom';
import Crud_Service from '../../../../apis/CrudService.jsx';
import { Controller, useForm } from 'react-hook-form';
import useLoader from '../../../../components/helpers/UseLoader.tsx';
import CommonLoader from '../../../../components/page/CommonLoader.jsx';
import FmTextField from '../../../../components/_mui/FmTextField.tsx';
import { CreateButton } from '../../../../components/small/Buttons.jsx';
import FmSearchableSelect from '../../../../components/_mui/FmSearchableSelect.tsx';
import { statusOptions } from '../../../../utils/CommonVariables.tsx';
import { toast } from 'react-toastify';
import { yupResolver } from '@hookform/resolvers/yup';
import { vehicelsSchema } from '../../../../utils/CommonvalidationSchemas.tsx';
import FmRadioButton from '../../../../components/_mui/FmRadioButton.tsx';

const EditOwnVehicles = () => {
  const navigate = useNavigate();
  const crud = new Crud_Service();
  const [loading, setLoading] = useState(false);
  const [editData, setEditData] = useState(null);
  const [adRequired, setAdRequired] = React.useState(false);
  const { handleSubmit, control, setValue, reset } = useForm({
    resolver: yupResolver(vehicelsSchema),
    mode: 'onChange',
    defaultValues: {
      advertisement: false,
    },
  });
  const { startEditLoading, stopEditLoading, editLoading } = useLoader();
  const { id } = useParams();
  const GridSize = 2.4;

  useEffect(() => {
    getOwnVehicles();
  }, []);

  const getOwnVehicles = async () => {
    setLoading(true);
    await crud.getSingle('ownvehicles', id, (_err, res) => {
      if (res?.status === 200) {
        const data = res?.data;
        Object.entries({
          ...data,
        }).forEach(([key, value]) => {
          setValue(key, value);
          if (key === 'advertisement' && value === true) {
            setAdRequired(true);
          }
        });
        setEditData(res?.data);
        setLoading(false);
      } else {
      }
    });
  };

  const handleEditOwnVehicle = async (values) => {
    startEditLoading();
    const combinedData = {
      ...values,
      advertisementId: Number(values?.advertisementId) || null,
      Status: 2,
    };
    await crud.update('ownvehicles', id, combinedData, (err, res) => {
      if (res?.status === 200) {
        toast.success('Own Vechicles Updated Successfully');
        stopEditLoading();
        navigate(-1);
      } else {
        stopEditLoading();
      }
    });
  };

  return (
    <DashboardLayout
      title='Edit Own Vehicles'
      actionButtons={
        <>
          <Box className='flex flex-row items-center whitespace-nowrap gap-3'>
            <CreateButton
              name='Create'
              onClick={() => navigate('/admin/vehicles/own-vehicles/create')}
            />
          </Box>
        </>
      }
    >
      <GlassCard className='p-5'>
        {loading ? (
          <CommonLoader />
        ) : (
          <form onSubmit={handleSubmit(handleEditOwnVehicle)}>
            <Grid container spacing={2}>
              <Grid item md={GridSize}>
                <FmSearchableSelect
                  name='vehicleId'
                  label='Vehicle'
                  apiUrl={'vehicles'}
                  valueField='vehicleId'
                  required
                  labelField={['vehicleCode', 'vehicleName']}
                  showField={['vehicleCode', 'vehicleName']}
                  control={control}
                  defaultValue={{
                    vehicleId: editData?.vehicleId,
                    vehicleName: editData?.vehicleName,
                  }}
                />
              </Grid>
              <Grid item md={GridSize}>
                <FmTextField
                  name='trafficCodeNo'
                  required
                  label='Traffic Code No'
                  control={control}
                />
              </Grid>

              <Grid item md={GridSize}>
                <FmSearchableSelect
                  name='insuranceId'
                  label='Insurance'
                  required
                  apiUrl={'vehicleinsurances'}
                  valueField='vehicleInsuranceId'
                  labelField={[
                    'vehicleInsuranceCode',
                    'policyNumber',
                    'premiumAmount',
                  ]}
                  showField={[
                    'vehicleInsuranceCode',
                    'policyNumber',
                    'premiumAmount',
                  ]}
                  control={control}
                  defaultValue={{
                    vehicleInsuranceId: editData?.insuranceId,
                    vehicleInsuranceCode: editData?.insuranceName,
                  }}
                />
              </Grid>

              <Grid item md={GridSize}>
                <FmSearchableSelect
                  name='driverId'
                  label='Driver'
                  apiUrl={'drivers'}
                  required
                  valueField='driverId'
                  labelField={['driverCode', 'driverName']}
                  showField={['driverCode', 'driverName']}
                  control={control}
                  defaultValue={{
                    driverId: editData?.driverId,
                    driverName: editData?.employeeName,
                  }}
                />
              </Grid>
              <Grid item md={GridSize}>
                <FmSearchableSelect
                  name='divisionId'
                  label='Division'
                  apiUrl={'divisions'}
                  required
                  valueField='divisionId'
                  labelField={['divisionCode', 'divisionName']}
                  showField={['divisionCode', 'divisionName']}
                  control={control}
                  defaultValue={{
                    divisionId: editData?.divisionId,
                    divisionName: editData?.divisionName,
                  }}
                />
              </Grid>
              <Grid item md={GridSize}>
                <FmRadioButton
                  name='advertisement'
                  control={control}
                  options={[
                    { value: false, label: 'No' },
                    { value: true, label: 'Yes' },
                  ]}
                  label='Advertisement'
                  onValueChange={(value) => {
                    setAdRequired(value === 'true');
                  }}
                />
              </Grid>
              {adRequired && (
                <Grid item md={GridSize}>
                  <FmSearchableSelect
                    name='advertisementId'
                    label='Advertisement'
                    apiUrl={'vehicleadvertisements'}
                    required
                    valueField='vehicleAdvertisementId'
                    labelField={['vehicleAdvertisementCode', 'permitNumber']}
                    showField={['vehicleAdvertisementCode', 'permitNumber']}
                    control={control}
                    defaultValue={{
                      vehicleAdvertisementId: editData?.vehicleAdvertisementId,
                      vehicleAdvertisementCode:
                        editData?.vehicleAdvertisementCode,
                    }}
                  />
                </Grid>
              )}
              <Grid item md={12}>
                <ActionButtons
                  onSubmit={handleSubmit(handleEditOwnVehicle)}
                  onReset={reset}
                  submitLoading={editLoading}
                  submitText='Update'
                  cancelText='Cancel'
                  onCancel={() => navigate(-1)}
                />
              </Grid>
            </Grid>
          </form>
        )}
      </GlassCard>
    </DashboardLayout>
  );
};

export default EditOwnVehicles;
