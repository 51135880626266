import { Icon } from '@iconify/react/dist/iconify.js';
import { Box } from '@mui/material';
import React, { useEffect, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import Crud_Service from '../../../apis/CrudService.jsx';
import TableActionButtons from '../../../components/_form/TableActionButtons.jsx';
import FmMRTDataTable from '../../../components/FmMRTDataTable.tsx';
import useTableLogic from '../../../components/helpers/MRTUseTableLogic.tsx';
import GlassCard from '../../../components/small/GlassCard.tsx';
import MRTExpandTableStyle from '../../../components/table/MRTExpandTableStyle.tsx';
import Icons from '../../../utils/Icon.jsx';

const BoldBox = ({ title, primary }) => {
  return (
    <Box className='font-semibold' sx={{ color: primary && 'primary.main' }}>
      {title}
    </Box>
  );
};

const ListVendorInvoiceReceivable = (props) => {
  const { data, getData } = props;
  const [, setCreatingRow] = useState(null);

  const {
    rows,
    isLoading,
    pagination,
    sorting,
    columnFilters,
    globalFilter,
    tableRecordCounts,
    setPagination,
    setSorting,
    setColumnFilters,
    setGlobalFilter,
    fetchData,
    setSearchKeyword,
    searchKeyword,
  } = useTableLogic(`vendorinvoices`);
  const navigate = useNavigate();
  const crud = new Crud_Service();

  useEffect(() => {
    if (data) {
      fetchData();
      getData(false);
    }
  }, [data]);
  const handleViewClick = async (id) => {
    navigate(`edit/${id}`);
  };

  const handleDelete = async (id) => {
    try {
      await crud.remove(`vendorinvoices/${id}`, '', (err, res) => {
        if (res?.status === 204) {
          fetchData();
        }
      });
    } catch (error) {}
  };

  const ActionData = [
    {
      name: 'Edit',
      icon: Icons.edit,
      onClick: (props) => handleViewClick(props?.vendorInvoiceId),
    },

    {
      name: 'Delete',
      icon: <Icon icon='solar:trash-bin-minimalistic-broken' />,
      onClick: (props) => handleDelete(props?.vendorInvoiceId),
      danger: true,
    },
  ];

  const columns = [
    {
      header: 'Actions',
      size: 100,
      Cell: ({ row }) => (
        <Box className='flex gap-2'>
          <TableActionButtons
            Actions={ActionData?.map((action) => ({
              ...action,
              onClick: () => action.onClick(row?.original),
            }))}
          />
        </Box>
      ),
    },
    {
      accessorKey: 'referenceNo',
      header: 'Reference #',
    },
    {
      accessorKey: 'vendorInvoiceDate',
      header: 'Date',
    },
    {
      accessorKey: 'receiverName',
      header: 'Received By',
    },
    {
      accessorKey: 'departmentName',
      header: 'Department',
      Cell: ({ renderedCellValue }) => <BoldBox title={renderedCellValue} />,
    },

    {
      accessorKey: 'designationName',
      header: 'Designation',
      Cell: ({ renderedCellValue }) => <BoldBox title={renderedCellValue} />,
    },
  ];

  return (
    <>
      <GlassCard className='flex-1 min-h-0'>
        <MRTExpandTableStyle enableFullHeight>
          <FmMRTDataTable
            rows={rows}
            columns={columns}
            enableRowSelection={false}
            rowCount={tableRecordCounts}
            pagination={pagination}
            sorting={sorting}
            columnFilters={columnFilters}
            globalFilter={globalFilter}
            setPagination={setPagination}
            setSorting={setSorting}
            setColumnFilters={setColumnFilters}
            setGlobalFilter={setGlobalFilter}
            isLoading={isLoading}
            setSearchKeyword={setSearchKeyword}
            searchKeyword={searchKeyword}
            fetchData={fetchData}
            muiTableContainerProps={{
              sx: {
                maxHeight: '100%',
                flex: 1,
              },
            }}
          />
        </MRTExpandTableStyle>
      </GlassCard>
    </>
  );
};

export default ListVendorInvoiceReceivable;
