import React from 'react';
import { Box } from '@mui/material';

const AttendanceTimingColumn = React.memo(({ data }) => {
  return (
    <Box className='flex flex-col gap-1 w-full'>
      {data?.map((item, index) => {
        return (
          <Box
            className='flex items-center w-full justify-between gap-1'
            key={index}
          >
            <Box>{item?.name}</Box>
            <Box className='flex-8 text-right font-semibold'>{item?.value}</Box>
          </Box>
        );
      })}
    </Box>
  );
});

AttendanceTimingColumn.displayName = 'AttendanceTimingColumn';

export default AttendanceTimingColumn;
