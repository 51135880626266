import React from 'react';
import TableAvatar from '../../../components/_form/TableAvatar';
import FmViewDateField from '../../../components/_mui/FmViewDateField.tsx';
import TableActionButtons from '../../../components/_form/TableActionButtons.jsx';
import { Icon } from '@iconify/react/dist/iconify.js';
import { Box } from '@mui/material';
import SimpleIconText from '../../../components/elements/SimpleIconText.tsx';
import ViewFieldsHori from '../../../components/view/ViewFieldsHori.tsx';
import {
  StatusBadge,
  ViewField,
} from '../../../components/_form/FormElements.jsx';
import Icons from '../../../utils/Icon.jsx';
import ViewTableTwoDate from '../../../components/table/ViewTableTwoDate.tsx';
import IconWithTooltip from '../../../components/elements/IconWithTooltip.tsx';
import DataGridHeaderTooltip from '../../../components/datagrid/DataGridHeaderTooltip.tsx';
import TextVisa from '../../../components/boxes/TextVisa.tsx';

interface ActionType {
  name: string;
  icon: JSX.Element;
  onClick: ((props: any, ev: any) => void) | null;
  danger?: boolean;
}

export const getHrmsEMpMasterCol = (
  t: (key: string) => string,
  Actions: ActionType[],
  setSelectedRow: (row: any) => void
) => [
    {
      field: 'column_sort',
      headerName: '',
      flex: 1,
      minWidth: 100,
      sortable: false,
      disableClickEventBubbling: false,

      renderCell: (params) => {
        return (
          <TableActionButtons
            Actions={Actions?.map((action) => ({
              ...action,
              onClick: () => action.onClick(params?.row),
            }))}
            selectedRow={params?.row}
            setSelectedRow={() => {
              setSelectedRow(params?.row);
            }}
          />
        );
      },
    },
    // separate column for employee code
    // {
    //   field: 'employeeCode',
    //   headerName: 'Emp.Id',
    //   minWidth: 130,
    // },
    {
      field: 'employeeName',
      headerName: 'Name',
      width: 280,

      renderCell: (params) => {
        return (
          <TableAvatar
            name={params.value}
            img={params?.row?.image}
            desc={`Code : ${params.row.employeeCode}`}
            genderIcon={params?.row?.genderName}
          />
        );
      },
    },
    {
      field: 'nationalityName',
      headerName: 'Nationality & DOB',
      minWidth: 150,
      renderCell: (params) => {
        return (
          <div className='flex flex-col w-full'>
            <div className='font-semibold'>{params?.row?.nationalityName}</div>
            <div className='flex items-center gap-1'>
              <span>DOB:</span>
              {FmViewDateField({
                value: params?.row?.dob,
                tableFontWeightRemove: true,
              })}
            </div>
          </div>
        );
      },
    },
    {
      field: 'genderName',
      headerName: 'Gender',
      minWidth: 150,
    },
    // separate column for dob
    // {
    //   field: 'dob',
    //   headerName: 'DOB',
    //   minWidth: 150,
    //   renderCell: (params) => {
    //     return FmViewDateField({ value: params?.value });
    //   },
    // },
    {
      field: 'age',
      headerName: 'Age',
      minWidth: 150,
    },

    {
      field: 'dateOfJoin',
      headerName: 'DOJ',
      minWidth: 150,

      renderCell: (params) => {
        return (
          <div className='flex gap-2 items-center justify-between w-full'>
            <div>
              <Box color={'primary.main'}>
                {FmViewDateField({ value: params?.value })}
              </Box>
              <div className='flex items-center gap-2'>
                {params?.row?.bloodGroupName && (
                  <>
                    <span>{t('blood_group')}</span>
                    <span className='text-red-600'>
                      {params?.row?.bloodGroupName}
                    </span>
                  </>
                )}
              </div>
            </div>
          </div>
        );
      },
    },

    // {
    //   field: 'dob',
    //   headerName: t('date_of_birth'),
    //   minWidth: 150,
    //   renderCell: (params) => {
    //     return FmViewDateField({ value: params?.value });
    //   },
    // },

    // {
    //   field: 'genderName',
    //   headerName: t('gender'),
    //   minWidth: 150,
    // },

    // {
    //   field: 'maritalStatusName',
    //   headerName: t('marital_status',
    //   minWidth: 150,
    // },
    {
      field: 'lastAnnualReturnDate',
      headerName: 'RFL/LJR',
      minWidth: 150,
      renderCell: (params) => {
        return FmViewDateField({ value: params?.value });
      },
    },
    {
      field: 'adeebExperience',
      headerName: 'Yrs.in AG',
      minWidth: 150,
      // renderCell: (params) => {
      //   const joiningDate = new Date(params?.row?.dateOfJoin);
      //   const currentDate = new Date();
      //   const experienceYears =
      //     currentDate.getFullYear() - joiningDate.getFullYear();
      //   const monthDiff = currentDate.getMonth() - joiningDate.getMonth();

      //   // Adjust years if the current month is before the joining month
      //   const totalYears = monthDiff < 0 ? experienceYears - 1 : experienceYears;

      //   return `${totalYears} ${totalYears === 1 ? 'year' : 'years'}`;
      // },
    },

    {
      field: 'gradeName',
      headerName: 'Grade',
      minWidth: 150,
    },
    {
      field: 'designationName',
      headerName: 'Designation',
      minWidth: 130,
    },

    {
      field: 'departmentName',
      headerName: 'Department',
      minWidth: 120,
    },
    //TODO
    // {
    //   field: 'grossSalary',
    //   headerName: 'Gross Salary',
    //   minWidth: 120,
    // },
    // {
    //   field: 'basicSalary',
    //   headerName: 'Basic',
    //   minWidth: 120,
    // },
    // {
    //   field: 'housingAllowance',
    //   headerName: 'HRA',
    //   minWidth: 120,
    // },
    // {
    //   field: 'transportAllowance',
    //   headerName: (
    //     <DataGridHeaderTooltip title={'Transport Allowance'} name={'TA'} />
    //   ),
    //   minWidth: 120,
    // },
    // {
    //   field: 'otherAllowance',
    //   headerName: <DataGridHeaderTooltip title={'Other Allowance'} name={'OA'} />,
    //   minWidth: 120,
    // },
    // {
    //   field: 'additionalAllowance',
    //   headerName: (
    //     <DataGridHeaderTooltip title={'Additional Allowance'} name={'AA'} />
    //   ),
    //   minWidth: 120,
    // },
    // {
    //   field: 'telephoneAllowance',
    //   headerName: (
    //     <DataGridHeaderTooltip title={'Telephone Allowance'} name={'TA'} />
    //   ),
    //   minWidth: 120,
    // },
    // {
    //   field: 'incentiveAllowance',
    //   headerName: (
    //     <DataGridHeaderTooltip title={'Incentive Allowance'} name={'IA'} />
    //   ),
    //   minWidth: 120,
    // },
    {
      field: 'workStatusName',
      headerName: 'Work Status',
      minWidth: 120,
      renderCell: (params) => {
        const statusBadges = {
          Active: 'green',
          Working: 'blue',
          Cancelled: 'red',
          Terminated: 'red',
          'On Leave': 'orange',
          Retired: 'red',
          Resigned: 'red',
        };
        if (!params.value) {
          return '';
        }
        return (
          <StatusBadge title={params.value} type={statusBadges[params.value]} />
        );
      },
    },
    //TODO
    // {
    //   field: 'payRoll',
    //   headerName: 'Pay Roll',
    //   minWidth: 120,
    // },
    {
      field: 'emirateName',
      headerName: (
        <DataGridHeaderTooltip
          title={'WP/Visa Issue From Emirate'}
          name={'WPIE'}
        />
      ),
      minWidth: 220,
    },
    {
      field: 'companyName',
      headerName: (
        <DataGridHeaderTooltip title={'WP/Visa Issue From Trade'} name={'WPIT'} />
      ),
      minWidth: 220,
    },
    {
      field: 'visaType',
      headerName: 'Type of Visa',
      minWidth: 120,
    },
    {
      field: 'visaStatusName',
      headerName: 'Visa Status',
      minWidth: 120,
    },
    // {
    //   field: 'unifiedNo',
    //   headerName: 'Residence/ UID.No',
    //   minWidth: 120,
    // },
    {
      field: 'empIdCardProvided',
      headerName: 'Residence/ UID.No',
      minWidth: 180,
      renderCell: (params) => {
        return (
          <div>
            <div>
              {params?.row?.empIdCardProvided ? (
                <IconWithTooltip
                  title='Provided'
                  icon={
                    <Icon
                      icon='fluent-emoji-flat:check-mark-button'
                      width='20'
                      height='20'
                      title='Provided'
                    />
                  }
                />
              ) : (
                <IconWithTooltip
                  icon={
                    <Icon
                      icon='fluent-emoji-flat:no-entry'
                      width='20'
                      height='20'
                      title='Not Provided'
                    />
                  }
                  title='Not Provided'
                />
              )}
            </div>
            <div>
              <span className='font-semibold'>UID.No :</span>{' '}
              {params?.row?.unifiedNo}
            </div>
          </div>
        );
      },
    },
    {
      field: 'visaFileNo',
      headerName: 'Visa File No',
      minWidth: 120,
      renderCell: (params) => {
        return (
          params?.value && (
            <TextVisa
              text={params?.value}
              color={params?.row?.visaExpriryColor}
              expiryMessage={params?.row?.expiryMessage}
            />
          )
        );
      },
    },
    {
      field: 'issuedDate',
      headerName: 'Visa Issue Date',
      minWidth: 180,

      renderCell: (params) => {
        return (
          <ViewTableTwoDate
            data={[
              {
                name: t('issued_date'),
                date: FmViewDateField({ value: params?.value }),
              },
              {
                name: t('expiry_date'),
                date: FmViewDateField({ value: params?.row?.expiryDate }),
                red: true,
              },
            ]}
          />
        );
      },
    },
    {
      field: 'labourPersonalNumber',
      headerName: 'Labour Personal No',
      minWidth: 180,
    },
    {
      field: 'labourCardNumber',
      headerName: 'Labour Card No',
      minWidth: 180,
    },
    {
      field: 'labourCardExpiryDate',
      headerName: 'LC Expiry Date',
      minWidth: 180,
      renderCell: (params) => {
        return FmViewDateField({ value: params?.value });
      },
    },
    {
      field: 'eidNo',
      headerName: 'EID No',
      minWidth: 150,
    },

    // {
    //   field: 'passportIssueDate',
    //   headerName: 'Passport Issue Date',
    //   minWidth: 150,
    //   renderCell: (params) => {
    //     return FmViewDateField({ value: params?.value });
    //   },
    // },
    // {
    //   field: 'passportExpiryDate',
    //   headerName: 'Passport Expiry Date',
    //   minWidth: 150,
    //   renderCell: (params) => {
    //     return FmViewDateField({ value: params?.value });
    //   },
    // },

    {
      field: 'mobileNumber',
      headerName: 'Mobile Number',
      minWidth: 230,
      renderCell: (params) => {
        return (
          <div className='flex gap-2 items-center justify-between w-full'>
            <div>
              <SimpleIconText icon={Icons.mobile} text={params?.value} />
              {params?.row?.personalEmailId && (
                <SimpleIconText
                  icon={Icons.email}
                  text={params?.row?.personalEmailId}
                />
              )}
            </div>
          </div>
        );
      },
    },
    {
      field: 'remarks',
      headerName: 'Remarks',
      minWidth: 150,
    },

    // {
    //   field: 'bloodGroupName',
    //   headerName: t('blood_group'),
    //   minWidth: 150,
    // },

    // {
    //   field: 'placeOfBirth',
    //   headerName: t('place_of_birth'),
    //   minWidth: 150,
    //   renderCell: (params) => {
    //     return FmViewDateField({ value: params?.value });
    //   },
    // },
    // {
    //   field: 'personalEmailId',
    //   headerName: t('personal_email_id'),
    //   minWidth: 200,
    // },
    {
      field: 'permanentAddress',
      headerName: 'Permanent Address',
      minWidth: 170,
    },

    // {
    //   field: 'unifiedNo',
    //   headerName: 'Unified No',
    //   minWidth: 150,
    // },
    //TODO
    // {
    //   field: 'molStatusName',
    //   headerName: 'MOL',
    //   minWidth: 160,
    //   renderCell: (params) => {
    //     return (
    //       <div className='flex flex-col'>
    //         <ViewFieldsHori
    //           label='Status'
    //           value={params?.value || '-'}
    //           fwRemove
    //         />
    //         <ViewFieldsHori
    //           label='Ref.No'
    //           value={params?.row?.molReferenceNumber || '-'}
    //           fwRemove
    //         />
    //       </div>
    //     );
    //   },
    // },

    // {
    //   field: 'employeeStatusName',
    //   headerName: 'Employee Status',
    //   minWidth: 150,
    //   renderCell: (params) => {
    //     const statusBadges = {
    //       Active: 'green',
    //       Working: 'blue',
    //       Cancelled: 'red',
    //       Terminated: 'red',
    //       'On Leave': 'orange',
    //       Retired: 'red',
    //       Resigned: 'red',
    //     };
    //     return (
    //       <StatusBadge title={params.value} type={statusBadges[params.value]} />
    //     );
    //   },
    // },

    {
      field: 'passportNumber',
      headerName: 'Passport Number',
      minWidth: 150,
      renderCell: (params) => {
        return (
          <div>
            {params?.value && (
              <SimpleIconText
                icon={
                  <Box color={'primary.main'}>
                    <Icon
                      icon='solar:passport-minimalistic-bold-duotone'
                      width='18'
                      height='18'
                    />
                  </Box>
                }
                text={params?.value}
                textFontWeight
              />
            )}
            {params?.row?.passportIssuePlace && (
              <ViewFieldsHori
                label='Issue Place'
                value={params?.row?.passportIssuePlace}
              />
            )}
          </div>
        );
      },
    },
    // {
    //   field: 'passportIssuePlace',
    //   headerName: t('passport_issue_place'),
    //   minWidth: 150,
    // },
    {
      field: 'passportIssueDate',
      headerName: 'Passport Issue Date',
      minWidth: 180,

      renderCell: (params) => {
        return (
          <ViewTableTwoDate
            data={[
              {
                name: t('issued_date'),
                date: FmViewDateField({ value: params?.value }),
              },
              {
                name: t('expiry_date'),
                date: FmViewDateField({ value: params?.row?.passportExpiryDate }),
                red: true,
              },
            ]}
          />
        );
      },
    },

    {
      field: 'passportStatusName',
      headerName: 'Passport Status',
      minWidth: 150,
    },
    {
      field: 'checkOutDate',
      headerName: 'Check Out Date',
      minWidth: 150,
      renderCell: (params) => {
        return (
          <SimpleIconText
            icon={Icons.date}
            text={FmViewDateField({ value: params?.value })}
          />
        );
      },
    },
    {
      field: 'inOrOut',
      headerName: 'In/Out',
      minWidth: 70,
      renderCell: (params) => {
        const displayValue =
          params?.value === 1 ? 'IN' : params?.value === 2 ? 'OUT' : '-';
        return <ViewField title={displayValue} />;
      },
    },

    {
      field: 'employeeVisaDetailId',
      headerName: 'Visa',
      minWidth: 150,
    },
    {
      field: 'visaTypeName',
      headerName: 'Visa Type',
      minWidth: 150,
    },
    {
      field: 'visaNumber',
      headerName: 'Visa Number',
      minWidth: 150,
    },
    {
      field: 'visaDesignationName',
      headerName: 'Designation MOL',
      minWidth: 150,
    },
    {
      field: 'skillCadre',
      headerName: 'Skill Cadre',
      minWidth: 150,
    },
    {
      field: 'issuedOrganization',
      headerName: 'Issued Organization',
      minWidth: 300,
    },
    //TODO
    // {
    //   field: 'immigrationStatusName',
    //   headerName: 'Immigration Status',
    //   minWidth: 150,
    // },
    //TODO
    // {
    //   field: 'foodAllowance',
    //   headerName: 'Food Allowance',
    //   width: 170,
    // },

    {
      field: 'statusName',
      headerName: 'status',
      width: 100,
      renderCell: (params) => {
        return (
          <StatusBadge
            title={params?.value}
            type={params?.value.toLowerCase() === 'active' ? 'green' : 'red'}
          />
        );
      },
    },
    // {
    //   field: 'createdDate',
    //   headerName: t('created_date'),
    //   width: 230,
    //   renderCell: (params) => {
    //     return (
    //       <ViewTableTwoDate
    //         data={[
    //           {
    //             name: t('created_date'),
    //             date: FmViewDateField({ value: params?.value }),
    //           },
    //           {
    //             name: t('updated_date'),
    //             date: FmViewDateField({ value: params?.row?.updatedDate }),
    //             red: true,
    //           },
    //         ]}
    //       />
    //     );
    //   },
    // },
  ];
