import { Box } from '@mui/material';
import React, { useState } from 'react';
import { useLocation, useNavigate } from 'react-router-dom';
import { toast } from 'react-toastify';
import Crud_Service from '../../../../apis/CrudService.jsx';
import TableActionButtons from '../../../../components/_form/TableActionButtons.jsx';
import FmMRTDataTable from '../../../../components/FmMRTDataTable.tsx';
import useTableLogic from '../../../../components/helpers/MRTUseTableLogic.tsx';
import Icons from '../../../../utils/Icon.jsx';
import GlassCard from './../../../../components/small/GlassCard.tsx';
import { StatusBadge } from '../../../../components/_form/FormElements.jsx';

const StatusColor = {
  Draft: 'orange',
  'Ready To Invoice': 'green',
};

const ListSalesInvoicesAR = () => {
  const navigation = useNavigate();
  const location = useLocation();

  const [data, setData] = useState({});
  const crud = new Crud_Service();

  const [, setCreatingRow] = useState(null);
  const {
    rows,
    isLoading,
    pagination,
    sorting,
    columnFilters,
    globalFilter,
    tableRecordCounts,
    setPagination,
    setSorting,
    setColumnFilters,
    setGlobalFilter,
    fetchData,
    setSearchKeyword,
    searchKeyword,
  } = useTableLogic(`salesinvoices`);

  const handleEdit = (id) => {
    navigation(`${location.pathname}/edit/${id}`);
  };
  const handleDelete = async (props) => {
    await crud.remove('salesinvoices', props, (err, res) => {
      if (res?.status === 204) {
        toast.success('Sales Invoice Deleted Successfully');
        fetchData();
      }
    });
  };
  const ActionData = [
    {
      name: 'Edit',
      icon: Icons.edit,
      onClick: (props) => {
        handleEdit(props.salesInvoiceId);
      },
    },
    {
      name: 'Delete',
      icon: Icons.delete,
      onClick: (props) => handleDelete(props.salesInvoiceId),
      danger: true,
    },
  ];
  // Define the columns
  const columns = [
    {
      header: 'Actions',
      size: 70,
      Cell: ({ row }) => (
        <Box className='flex gap-2'>
          {
            <TableActionButtons
              Actions={ActionData?.map((action) => ({
                ...action,
                onClick: () => action.onClick(row?.original),
              }))}
            />
          }
        </Box>
      ),
    },
    {
      accessorKey: 'clientName',
      header: 'Client',
      size: 300,
      muiTableBodyCellProps: {
        className: 'font-semibold',
      },
    },
    {
      accessorKey: 'internalInvoiceCode',
      header: 'Internal Invoice',
      size: 140,
    },
    {
      accessorKey: 'invoiceStatusName',
      header: 'Invoice Status',
      Cell: ({ cell }) => (
        <StatusBadge
          title={cell?.getValue()}
          type={StatusColor[cell?.getValue()]}
        />
      ),
    },
    {
      accessorKey: 'jurisdictionName',
      header: 'Jurisdiction',
    },
    {
      accessorKey: 'branchName',
      header: 'Branch',
    },
    {
      accessorKey: 'salesInvoiceDate',
      header: 'Invoice Date',
      size: 120,
    },
    {
      accessorKey: 'dueDate',
      header: 'Due Date',
      size: 120,
    },
  ];

  return (
    <>
      <GlassCard className='overflow-hidden h-full-css'>
        <FmMRTDataTable
          columns={columns}
          rows={rows}
          rowCount={tableRecordCounts}
          pagination={pagination}
          sorting={sorting}
          columnFilters={columnFilters}
          globalFilter={globalFilter}
          setPagination={setPagination}
          setSorting={setSorting}
          setColumnFilters={setColumnFilters}
          setGlobalFilter={setGlobalFilter}
          isLoading={isLoading}
          setSearchKeyword={setSearchKeyword}
          searchKeyword={searchKeyword}
          fetchData={fetchData}
          handleRowDoubleClick={(ev) =>
            handleEdit(ev?.original?.salesInvoiceId)
          }
          muiTableContainerProps={{ sx: { maxHeight: '100%', flex: 1 } }}
          showSerialNumber={true}
        />{' '}
      </GlassCard>
    </>
  );
};

export default ListSalesInvoicesAR;
