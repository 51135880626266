import React from 'react';
import {
  Timeline,
  TimelineItem,
  TimelineSeparator,
  TimelineDot,
  TimelineConnector,
  TimelineContent,
} from '@mui/lab';
import { Box } from '@mui/material';

// Define TypeScript interface for props
interface TimeLineItem {
  id: number | string;
  createdDate: string;
  createdByName: string;
  message: string;
}

interface TimeLineBoxContentProps {
  item: TimeLineItem;
}

interface MuiTimeLineTwoProps {
  data: TimeLineItem[];
}

const TimeLineBoxContent: React.FC<TimeLineBoxContentProps> = ({ item }) => {
  const parsedMessage = item.message
    .replace(/^{"|"}$/g, '')
    .replace(/"/g, '')
    .replace(/,/g, '<br />');

  return (
    <Box>
      <p>{item.createdDate}</p>
      <h3 className='font-bold'>{item.createdByName}</h3>
      <Box
        className='my-2 opacity-75 font-normal'
        dangerouslySetInnerHTML={{ __html: parsedMessage }}
        sx={{
          '& b': {
            fontWeight: 'normal',
          },
        }}
      ></Box>
    </Box>
  );
};

const MuiTimeLineTwo: React.FC<MuiTimeLineTwoProps> = ({ data = [] }) => {
  return (
    <Timeline position='alternate-reverse'>
      {data.map((item, index) => (
        <TimelineItem key={item.id}>
          <TimelineSeparator>
            <TimelineDot />
            {index !== data.length - 1 && <TimelineConnector />}
          </TimelineSeparator>
          <TimelineContent>
            <TimeLineBoxContent item={item} />
          </TimelineContent>
        </TimelineItem>
      ))}
    </Timeline>
  );
};

export default MuiTimeLineTwo;
