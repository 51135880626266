import React, { useState } from 'react';
import GlassCard from '../../../../../../components/small/GlassCard.tsx';
import {
  Box,
  FormControl,
  FormControlLabel,
  Grid,
  Radio,
  RadioGroup,
} from '@mui/material';
import ActionButtons from '../../../../../../components/_form/ActionButtons.tsx';
import useTableLogic from '../../../../../../components/helpers/MRTUseTableLogic.tsx';
import Crud_Service from '../../../../../../apis/CrudService.jsx';
import { Controller, useForm } from 'react-hook-form';
import FmTextField from '../../../../../../components/_mui/FmTextField.tsx';
import { Icon } from '@iconify/react/dist/iconify.js';
import { toast } from 'react-toastify';
import WitnessDataTable from './WitnessDataTable.tsx';
import MuiDialogOne from '../../../../../../components/_mui/MuiDialogOne.jsx';
import CommonView from '../../../../../../components/helpers/CommonView.jsx';
import DynamicViewFields from '../../../../../../components/helpers/DynamicViewFields.jsx';
import FmSearchableSelect from '../../../../../../components/_mui/FmSearchableSelect.tsx';
import { DatePicker, LocalizationProvider } from '@mui/x-date-pickers';
import dayjs from 'dayjs';
import { AdapterDayjs } from '@mui/x-date-pickers/AdapterDayjs';

const MdItemValue = 3;

const WitnessList = () => {
  const [viewDetails, setViewdetails] = useState(false);
  const {
    rows,
    rowCount,
    isLoading,
    pagination,
    sorting,
    columnFilters,
    globalFilter,
    tableRecordCounts,
    setPagination,
    setSorting,
    setColumnFilters,
    setGlobalFilter,
    fetchData,
    setSearchKeyword,
    searchKeyword,
  } = useTableLogic('accidentwitnesses');
  const crud = new Crud_Service();
  const { handleSubmit, control, reset } = useForm({
    defaultValues: {
      dateOfWorkResumption: dayjs(),
    },
  });
  const [editingRowId, setEditingRowId] = useState(null);
  const [editingRowData, setEditingRowData] = useState(null);
  const [radioChange, setRadioChange] = useState(1);

  const handleDelete = async (props) => {
    await crud.remove(
      'accidentwitnesses',
      props?.incidentWitnessId,
      (err, res) => {
        if (res?.status === 204) {
          toast.success('Accident Witness Deleted Successfully');
          fetchData();
        } else {
        }
      }
    );
  };

  const handleEdit = async (row) => {
    setEditingRowId(row?.accidentWitnessId);
    setEditingRowData({ ...row });
  };

  const handleViewClick = async (props) => {
    setViewdetails(props);
  };

  const handleCancel = () => {
    setEditingRowId(null);
    setEditingRowData(null);
  };

  const ActionData = [
    {
      name: 'View Details',
      icon: <Icon icon='solar:eye-linear' />,
      onClick: (props) => handleViewClick(props),
    },
    {
      name: 'Edit',
      icon: <Icon icon='solar:pen-2-broken' />,
      onClick: (props) => handleEdit(props),
    },
    {
      name: 'Delete',
      icon: <Icon icon='solar:trash-bin-minimalistic-broken' />,
      onClick: (props) => handleDelete(props),
      danger: true,
    },
  ];

  const handleSubmitWitness = async (values) => {
    const combinedData = {
      ...values,
      //TODO: In Future We Do Dynamic
      accidentReportId: 3,
      dateOfWorkResumption: dayjs(values?.dateOfWorkResumption).format(
        'YYYY-MM-DD'
      ),
    };

    await crud.create('accidentwitnesses', combinedData, (err, res) => {
      if (res?.status === 201) {
        toast.success('Witness Created Successfully');
        fetchData();
      } else {
      }
    });
  };

  const excludeKeys = ['createdDate', 'updatedDate', 'status'];

  const handleUpdateWitness = async (values) => {
    await crud.update('accidentwitnesses', editingRowId, values, (err, res) => {
      if (res?.status === 200) {
        toast.success('Witness Updated Successfully');
        fetchData();
        setEditingRowData(null);
        setEditingRowId(null);
      } else {
      }
    });
  };

  return (
    <>
      <h3 className='mb-3 text-sm font-bold'>Witnesses</h3>

      <GlassCard className='my-4'>
        <Box className='p-3'>
          <form onSubmit={handleSubmit(handleSubmitWitness)}>
            <LocalizationProvider dateAdapter={AdapterDayjs}>
              <Grid container spacing={2}>
                <Grid item md={MdItemValue}>
                  <FormControl>
                    <RadioGroup
                      sx={{ flexDirection: 'row' }}
                      defaultValue={1}
                      onChange={(e) => setRadioChange(Number(e.target.value))}
                    >
                      <FormControlLabel
                        value={1}
                        control={<Radio />}
                        label='Witness Users'
                      />
                      <FormControlLabel
                        value={2}
                        control={<Radio />}
                        label='Witness Name'
                      />
                    </RadioGroup>
                  </FormControl>
                </Grid>
                <Grid item md={MdItemValue}>
                  {radioChange === 1 ? (
                    <FmSearchableSelect
                      name='accidentWitnessUserId'
                      control={control}
                      apiUrl='loginusers'
                      valueField='loginUserId'
                      labelField={[
                        'employeeCode',
                        'employeeName',
                        'employeeMail',
                      ]}
                      showField={[
                        'employeeCode',
                        'employeeName',
                        'employeeMail',
                      ]}
                      label='Accident Witness User'
                    />
                  ) : (
                    <FmTextField
                      name='accidentWitnessName'
                      label='Accident Witness Name'
                      control={control}
                    />
                  )}
                </Grid>

                <Grid item md={MdItemValue}>
                  <Controller
                    name='dateOfWorkResumption'
                    control={control}
                    defaultValue={null}
                    render={({ field: { onChange, value } }) => (
                      <DatePicker
                        label='Date of Work Resumption'
                        value={value ? dayjs(value) : null}
                        format='DD/MM/YYYY'
                        onChange={(newValue) => {
                          onChange(newValue);
                        }}
                        sx={{ width: '100%' }}
                        slotProps={{
                          textField: {
                            variant: 'filled',
                          },
                        }}
                      />
                    )}
                  />
                </Grid>
              </Grid>
            </LocalizationProvider>

            <ActionButtons
              onSubmit={handleSubmit(handleSubmitWitness)}
              onReset={reset}
            />
          </form>
        </Box>

        <WitnessDataTable
          rows={rows}
          tableRecordCounts={tableRecordCounts}
          editingRowId={editingRowId}
          editingRowData={editingRowData}
          ActionData={ActionData}
          handleUpdateWitness={handleUpdateWitness}
          handleCancel={handleCancel}
          pagination={pagination}
          sorting={sorting}
          setPagination={setPagination}
          setSorting={setSorting}
          columnFilters={columnFilters}
          globalFilter={globalFilter}
          setColumnFilters={setColumnFilters}
          setGlobalFilter={setGlobalFilter}
          isLoading={isLoading}
          setSearchKeyword={setSearchKeyword}
          searchKeyword={searchKeyword}
          crud={crud}
        />
      </GlassCard>

      <MuiDialogOne
        title='View Details'
        open={viewDetails}
        onClose={() => setViewdetails(!viewDetails)}
      >
        {viewDetails && viewDetails?.accidentWitnessId && (
          <CommonView
            url='accidentwitnesses'
            id={viewDetails?.accidentWitnessId}
            excludeKeys={excludeKeys}
            renderFields={(data, fields) => (
              <DynamicViewFields data={data} fields={fields} />
            )}
          />
        )}
      </MuiDialogOne>
    </>
  );
};

export default WitnessList;
