import React, { useEffect, useRef } from 'react';
import { Box, Stack } from '@mui/material';
import SidebarMenu from './helpers/SidebarMenu';
import PageMenu from './PageMenu';
import { Icon } from '@iconify/react';
import { Nav } from '../utils';
import PerfectScrollbar from 'react-perfect-scrollbar';
import { useLocation } from 'react-router-dom';
import { SidebarLogo } from './layout/Logo.tsx';
import HeaderSearchButton from './helpers/HeaderSearchButton.jsx';
import HeaderGroupIcons from './HeaderGroupIcons.tsx';
import SidebarSettingsDropdown from './helpers/SidebarSettingsDropdown.jsx';

const EssNav = {
  id: 1,
  name: 'ESS',
  icon: <Icon icon='solar:accessibility-broken' />,
  color: '#067e89',
  child: [
    {
      name: 'Profile',
      icon: <Icon icon='solar:chart-broken' />,
      path: '/essPortal/dashboard',
      children: null,
    },
    {
      name: 'Leave Request',
      icon: <Icon icon='streamline:database-refresh' />,
      count: '3',
      path: '/essPortal/leaveRequest',
      children: [],
    },
    {
      name: 'Leave Join',
      icon: <Icon icon='streamline:definition-search-book' />,
      path: '/essPortal/leaveJoin',
      children: null,
    },

    {
      name: 'Resignation',
      path: '/essPortal/resignation',
      icon: <Icon icon='streamline:bag-suitcase-1' />,
      children: [],
    },
    {
      name: 'Passport Request',
      path: '/essPortal/passportRequest',
      icon: <Icon icon='streamline:receipt-check' />,
    },

    {
      name: 'Document uploads',
      path: '/essPortal/documentUploads',
      icon: <Icon icon='simple-line-icons:calender' />,
      children: [],
    },
  ],
};

const Sidebar = (props) => {
  const { type, open } = props;
  const [activeMenuOpen, setActiveMenuOpen] = React.useState(false);
  const menu = localStorage.getItem('menu') || 0;

  const scrollbarRef = useRef(null);
  const location = useLocation();

  const handleActiveMenuLeaveOpen = () => {
    setActiveMenuOpen(false);
  };

  useEffect(() => {
    const container = scrollbarRef.current;

    if (container) {
      const activeItem = container.querySelector('.active');

      if (activeItem) {
        const { offsetTop } = activeItem;
        const { clientHeight } = container;
        container.scrollTop = offsetTop - clientHeight / 2;
      }
    }
  }, [location.pathname]);

  return (
    <>
      <Box
        sx={{
          minWidth: !open ? '210px' : '60px',
          flexBasis: !open ? '210px' : '60px',
          display: 'flex',
          height: '100%',
          flexDirection: 'column',
          zIndex: 3,
          overflow: !open && 'hidden',
          borderRadius: !open && '0px',
          color: '#D9D9D9',
          // borderRight: '1px solid',
          // borderColor: 'border.main',
          bgcolor: 'background.sidebar',
        }}
        className='sm:hidden '
      >
        <Box className='flex flex-col gap-2 p-2'>
          <Box className='flex px-2 '>
            <SidebarLogo />
          </Box>
          <HeaderSearchButton />
        </Box>

        <Box
          className='flex-1 min-h-0 h-full overflow-auto'
          sx={{
            borderBottom: '1px solid',
            borderTop: '1px solid',
            borderColor: '#474a4e',
          }}
        >
          <PerfectScrollbar
            options={{ wheelPropagation: false }}
            containerRef={(ref) => (scrollbarRef.current = ref)}
          >
            <Box className='p-2'>
              <Stack direction='column' className='w-full' spacing={0.3}>
                {type === 'ESS'
                  ? EssNav.child.map((item, i) => {
                      return (
                        item.name && (
                          <SidebarMenu
                            icon={item.icon}
                            key={i}
                            name={item.name}
                            to={item.path}
                            open={open}
                          />
                        )
                      );
                    })
                  : Nav[menu].child?.map((item, i) => {
                      return (
                        item.name && (
                          <SidebarMenu
                            icon={item.icon}
                            key={i}
                            name={item.name}
                            to={item.path}
                            open={open}
                          />
                        )
                      );
                    })}
              </Stack>
            </Box>
          </PerfectScrollbar>
        </Box>
        <Box className='flex flex-col'>
          <SidebarSettingsDropdown />
          <Box sx={{ borderTop: '1px solid', borderColor: '#474a4e' }}></Box>
          <HeaderGroupIcons />
        </Box>
      </Box>
      <PageMenu open={activeMenuOpen} leavePanel={handleActiveMenuLeaveOpen} />
    </>
  );
};

export default Sidebar;
