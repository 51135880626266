import { yupResolver } from '@hookform/resolvers/yup';
import { Icon } from '@iconify/react';
import { Box, Button } from '@mui/material';
import dayjs from 'dayjs';
import React, { useState } from 'react';
import { useForm } from 'react-hook-form';
import { useLocation, useNavigate } from 'react-router-dom';
import { toast } from 'react-toastify';
import Crud_Service from '../../../apis/CrudService.jsx';
import FmMRTDataTable from '../../../components/FmMRTDataTable.tsx';
import TableActionButtons from '../../../components/_form/TableActionButtons.jsx';
import FmDatePicker from '../../../components/_mui/FmDatePicker.tsx';
import FmSearchableSelect from '../../../components/_mui/FmSearchableSelect.tsx';
import FmTextField from '../../../components/_mui/FmTextField.tsx';
import FmViewDateField from '../../../components/_mui/FmViewDateField.tsx';
import { SalesOrdervalidationSchema } from '../../../utils/CommonvalidationSchemas.tsx';
import Icons from '../../../utils/Icon.jsx';
import GlassCard from './../../../components/small/GlassCard.tsx';
import MRTExpandTableStyle from './../../../components/table/MRTExpandTableStyle.tsx';
import FmCurrencyTextField from '../../../components/_mui/FmCurrencyTextField.tsx';
import CurrencyView from '../../../components/_mui/FmCurrencyView.tsx';

const ListSalesOrderDistribution = (props) => {
  const [, setCreatingRow] = useState(null);
  const [editingRowData, setEditingRowData] = useState(null);
  const [editingRowId, setEditingRowId] = useState(null);
  const [selectedClient, setSelectedClient] = React.useState(null);
  const [selectedProject, setSelectedProject] = React.useState(null);
  const navigate = useNavigate();
  const location = useLocation();

  const crud = new Crud_Service();
  const { handleSubmit, control, trigger, setValue } = useForm({
    resolver: yupResolver(SalesOrdervalidationSchema),
    mode: 'onChange',
  });

  const {
    rows,
    isLoading,
    pagination,
    sorting,
    columnFilters,
    globalFilter,
    tableRecordCounts,
    setPagination,
    setSorting,
    setColumnFilters,
    setGlobalFilter,
    fetchData,
    setSearchKeyword,
    searchKeyword,
  } = props;

  const handleEdit = (row) => {
    setEditingRowId(row?.salesOrderDistributionId);
    setEditingRowData({ ...row });
    Object.entries({
      ...row,
    }).forEach(([key, value]) => {
      setValue(key, value);
    });
  };
  const handleCancel = () => {
    setEditingRowId(null);
    setEditingRowData(null);
  };
  const handleContractChange = (selectedContract) => {
    if (selectedContract) {
      setValue('totalContractValue', selectedContract.contractCost ?? '', {
        shouldValidate: true,
      });
    }
  };
  const handleSelectedClient = (e) => {
    setSelectedClient(e);
  };

  const handleSalesOrderUpdate = async (values) => {
    const combinedData = {
      ...values,
      companyId: values?.companyId,
      salesOrderDistribustionDate: dayjs(
        values?.salesOrderDistribustionDate
      ).format('YYYY-MM-DD'),
      status: 2,
    };

    await crud.update(
      'salesorderdistributions',
      editingRowId,
      combinedData,
      (err, res) => {
        if (res?.status === 200) {
          toast.success('Sales Order Distribution Updated Successfully');
          fetchData();
          setEditingRowData(null);
          setEditingRowId(null);
        } 
      }
    );
  };
  const handleDeleteSalerOrder = async (values) => {
    crud.remove(
      'salesorderdistributions',
      values.salesOrderDistributionId,
      (err, res) => {
        if (res?.status === 204) {
          fetchData();
          toast.success('Sales Order Distribution deleted Successfully');
        } else {
        }
      }
    );
  };

  const handleViewClick = async (id) => {
    navigate(`${location.pathname}/view/${id}`);
  };

  const ActionData = [
    {
      name: 'View Details',
      icon: <Icon icon='solar:eye-linear' />,
      onClick: (props) => handleViewClick(props?.salesOrderDistributionId),
    },
    {
      name: 'Edit',
      icon: Icons.edit,
      onClick: (props) => {
        handleEdit(props);
      },
    },
    {
      name: 'Delete',
      icon: <Icon icon='solar:trash-bin-minimalistic-broken' />,
      onClick: (props) => handleDeleteSalerOrder(props),
      danger: true,
    },
  ];
  const columns = [
    {
      header: 'Actions',
      size: 100,
      Cell: ({ row }) => (
        <Box className='flex gap-2'>
          {editingRowId === row?.original?.salesOrderDistributionId ? (
            <>
              <Button onClick={handleSubmit(handleSalesOrderUpdate)}>
                Save
              </Button>
              <Button onClick={handleCancel}>Cancel</Button>
            </>
          ) : (
            <TableActionButtons
              Actions={ActionData?.map((action) => ({
                ...action,
                onClick: () => action.onClick(row?.original),
              }))}
            />
          )}
        </Box>
      ),
    },
    {
      accessorKey: 'companyId',
      header: 'Company',
      enableEditing: true,
      size: 300,
      Cell: ({ row }) => {
        return editingRowId === row?.original?.salesOrderDistributionId ? (
          <FmSearchableSelect
            name='companyId'
            control={control}
            apiUrl='companies'
            defaultValue={{
              companyId: editingRowData?.companyId,
              companyName: editingRowData?.companyName,
            }}
            valueField='companyId'
            labelField={['companyCode', 'company Name']}
            showField={['companyCode', 'companyName']}
            label='Company Name'
            required
          />
        ) : (
          row?.original?.companyName
        );
      },
    },
    {
      accessorKey: 'clientId',
      header: 'Client',
      size: 220,
      enableEditing: true,
      Cell: ({ row }) => {
        return editingRowId === row?.original?.salesOrderDistributionId ? (
          <FmSearchableSelect
            name='clientId'
            control={control}
            apiUrl='clients'
            valueField='clientId'
            defaultValue={{
              clientId: editingRowData?.clientId,
              clientName: editingRowData?.clientName,
            }}
            labelField={['clientCode', 'clientName']}
            showField={['clientCode', 'clientName']}
            onChangeProp={handleSelectedClient}
            label='Client'
            required
          />
        ) : (
          row?.original?.clientName
        );
      },
    },
    {
      accessorKey: 'projectId',
      header: 'Project',
      enableEditing: true,
      size: 220,
      Cell: ({ row }) => {
        return editingRowId === row?.original?.salesOrderDistributionId ? (
          <FmSearchableSelect
            name='projectId'
            control={control}
            apiUrl='projects'
            queryparam='clientId'
            queryparamValue={selectedClient?.clientId}
            onChangeProp={(selectedProject) =>
              setSelectedProject(selectedProject)
            }
            valueField='projectId'
            defaultValue={{
              projectId: editingRowData?.projectId,
              projectName: editingRowData?.projectName,
            }}
            labelField={['projectCode', 'projectName']}
            showField={['projectCode', 'projectName']}
            label='Project Name'
            required
          />
        ) : (
          row?.original?.projectName
        );
      },
    },
    {
      accessorKey: 'contractId',
      header: 'Contract',
      size: 220,
      enableEditing: true,
      Cell: ({ row }) => {
        return editingRowId === row?.original?.salesOrderDistributionId ? (
          <FmSearchableSelect
            name='contractId'
            control={control}
            apiUrl='contracts'
            queryparam='projectId'
            queryparamValue={selectedProject?.projectId}
            defaultValue={{
              contractId: editingRowData?.contractId,
              contractName: editingRowData?.contractName,
            }}
            valueField='contractId'
            labelField={['contractCode', 'contractName']}
            showField={['contractCode', 'contractName']}
            label='Contract'
            onChangeProp={(selectedContract) =>
              handleContractChange(selectedContract)
            }
            required
          />
        ) : (
          row?.original?.contractName
        );
      },
    },
    {
      accessorKey: 'workOrderId',
      header: 'Work Order',
      enableEditing: true,
      size: 220,
      Cell: ({ row }) => {
        return editingRowId === row?.original?.salesOrderDistributionId ? (
          <FmSearchableSelect
            name='workOrderId'
            control={control}
            apiUrl='workOrders'
            defaultValue={{
              workOrderId: editingRowData?.workOrderId,
              workOrderName: editingRowData?.workOrderName,
            }}
            valueField='workOrderId'
            labelField={['workOrderCode', 'workOrderName']}
            showField={['workOrderCode', 'workOrderName']}
            label='Work Order'
            required
          />
        ) : (
          row?.original?.workOrderName
        );
      },
    },
    {
      accessorKey: 'totalContractValue',
      header: 'Total Contract (AED)',
      enableEditing: true,
      size: 300,
      Cell: ({ row }) => {
        return editingRowId === row?.original?.salesOrderDistributionId ? (
          <FmCurrencyTextField
            name='totalContractValue'
            control={control}
            defaultValue={editingRowData?.totalContractValue}
            maxLength={10}
            minLength={1}
          />
        ) : (
          <CurrencyView
            value={row?.original?.totalContractValue}
            currencyType={'AED'}
          />
        );
      },
    },
    {
      accessorKey: 'budgetValue',
      header: 'Budget Value (%)',
      enableEditing: true,
      Cell: ({ row }) => {
        return editingRowId === row?.original?.salesOrderDistributionId ? (
          <FmTextField
            name='budgetValue'
            control={control}
            defaultValue={editingRowData?.budgetValue}
            pattern='Percentage'
            onChange={(ev) => {
              const newPercentage = parseFloat(ev.target.value) || 0;
              const newContractValue =
                (newPercentage / 100) * editingRowData?.totalContractValue;
              setValue('totalBudgetValue', newContractValue);
            }}
            minLength={0}
            maxLength={100}
          />
        ) : (
          row?.original?.budgetValue
        );
      },
    },
    {
      accessorKey: 'totalBudgetValue',
      header: 'Total Budget (AED)',
      enableEditing: true,
      Cell: ({ row }) => {
        return editingRowId === row?.original?.salesOrderDistributionId ? (
          <FmCurrencyTextField
            name='totalBudgetValue'
            control={control}
            defaultValue={editingRowData?.totalBudgetValue}
            maxLength={10}
            onChange={(ev) => {
              const newContractValue = parseFloat(ev.target.value) || 0;
              const newPercentage =
                (newContractValue / editingRowData?.totalContractValue) * 100;
              setValue('budgetValue', newPercentage.toFixed(2));
              trigger('budgetValue');
            }}
            minLength={1}
          />
        ) : (
          <CurrencyView
            value={row?.original?.totalBudgetValue}
            currencyType={'AED'}
          />
        );
      },
    },
    {
      accessorKey: 'approvedBy',
      header: 'Approved By',
      size: 300,
      enableEditing: true,
      Cell: ({ row }) => {
        return editingRowId === row?.original?.salesOrderDistributionId ? (
          <FmSearchableSelect
            name='approvedBy'
            label='Approved By'
            control={control}
            apiUrl='loginusers'
            valueField='loginUserId'
            headerField={['Code', 'Name', 'Email']}
            labelField={['employeeCode', 'employeeName', 'employeeMail']}
            showField={['employeeCode', 'employeeName', 'employeeMail']}
            defaultValue={{
              loginUserId: editingRowData?.approvedBy || null,
              loginUserName: editingRowData?.approvedUserName || '',
            }}
          />
        ) : (
          row?.original?.approvedUserName
        );
      },
    },
    {
      accessorKey: 'preparedBy',
      header: 'Prepared By',
      enableEditing: true,
      size: 300,
      Cell: ({ row }) => {
        const incidentReport: any = rows?.find(
          (item) =>
            item?.salesOrderDistributionId ===
            row?.original?.salesOrderDistributionId
        );
        return editingRowId === row?.original?.salesOrderDistributionId ? (
          <FmSearchableSelect
            name='preparedBy'
            control={control}
            apiUrl='loginusers'
            label='Prepared By'
            valueField='loginUserId'
            headerField={['Code', 'Name', 'Email']}
            labelField={['employeeCode', 'employeeName', 'employeeMail']}
            showField={['employeeCode', 'employeeName', 'employeeMail']}
            defaultValue={{
              loginUserId: editingRowData?.preparedBy || null,
              loginUserName: editingRowData?.preparedUserName || '',
            }}
          />
        ) : (
          incidentReport?.preparedUserName || ''
        );
      },
    },
    {
      accessorKey: 'salesOrderDistribustionDate',
      header: 'Date',
      enableEditing: true,
      size: 250,
      Cell: ({ row }) => {
        return editingRowId === row?.original?.salesOrderDistributionId ? (
          <FmDatePicker
            name='salesOrderDistribustionDate'
            label='Date'
            control={control}
            required
          />
        ) : (
          FmViewDateField({ value: row?.original?.salesOrderDistribustionDate })
        );
      },
    },
  ];
  return (
    <>
      <GlassCard className='overflow-hidden  flex-1 min-h-0'>
        <MRTExpandTableStyle enableFullHeight>
          <FmMRTDataTable
            rows={rows}
            columns={columns}
            enableRowSelection={false}
            rowCount={tableRecordCounts}
            editingRow={editingRowId}
            pagination={pagination}
            sorting={sorting}
            columnFilters={columnFilters}
            globalFilter={globalFilter}
            setPagination={setPagination}
            setSorting={setSorting}
            setColumnFilters={setColumnFilters}
            setGlobalFilter={setGlobalFilter}
            isLoading={isLoading}
            setSearchKeyword={setSearchKeyword}
            searchKeyword={searchKeyword}
            fetchData={fetchData}
            muiTableContainerProps={{
              sx: {
                maxHeight: '100%',
                flex: 1,
              },
            }}
            handleRowDoubleClick={(e) => handleEdit(e?.original)}
          />
        </MRTExpandTableStyle>
      </GlassCard>
    </>
  );
};

export default ListSalesOrderDistribution;
