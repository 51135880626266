import { Box, Grid } from '@mui/material';
import React, { useEffect, useState } from 'react';
import { set, useForm } from 'react-hook-form';
import { useTranslation } from 'react-i18next';
import Icons from '../../../utils/Icon.jsx';

import apiInstance from '../../../apis/ApiService.jsx';
import Crud_Service from '../../../apis/CrudService';
import ActionButtons from '../../../components/_form/ActionButtons.tsx';
import { StatusBadge, ViewField } from '../../../components/_form/FormElements';
import TableActionButtons from '../../../components/_form/TableActionButtons';
import TableSearch from '../../../components/_form/TableSearch.jsx';
import FmAutoComplete from '../../../components/_mui/FmAutoComplete.tsx';
import FmTextField from '../../../components/_mui/FmTextField.tsx';
import MuiDialogOne from '../../../components/_mui/MuiDialogOne';
import DashboardLayout from '../../../components/DashboardLayout.tsx';
import DataTable from '../../../components/DataTable.tsx';
import DataTableToolbarButtons from '../../../components/helpers/DataTableToolbarButtons.tsx';
import useLoader from '../../../components/helpers/UseLoader.tsx';
import useTableLogic from '../../../components/helpers/UseTableLogic.tsx';
import CommonLoader from '../../../components/page/CommonLoader';
import PageHeader from '../../../components/PageHeader';
import { CreateButton } from '../../../components/small/Buttons';
import GlassCard from '../../../components/small/GlassCard.tsx';
import { Location } from '../../../interfaces/EmployeeMasterInterfaces';
import { Nav } from '../../../utils';
import { toast } from 'react-toastify';
import { statusOptions } from '../../../utils/CommonVariables.tsx';
import FmSearchableSelect from '../../../components/_mui/FmSearchableSelect.tsx';
import DynamicViewFields from '../../../components/helpers/DynamicViewFields.jsx';
import CommonView from '../../../components/helpers/CommonView.jsx';

const Division = (props) => {
    const { type } = props;
    const [openCreate, setOpenCreate] = React.useState(false);
    const [openView, setOpenView] = React.useState(false);
    const [loading, setLoader] = useState(false);
    const crud = new Crud_Service();
    const [selectedRow, setSelectedRow] = useState();
    const [viewDetails, setViewdetails] = useState(false);

    const [openEdit, setOpenEdit] = React.useState(false);
    const [editData, setEditData] = useState(null);
    const {
        createLoading,
        startCreateLoading,
        stopCreateLoading,
        editLoading,
        startEditLoading,
        stopEditLoading,
    } = useLoader();
    const {
        rows,
        pageSize,
        pageNumber,
        setSearchKeyword,
        isLoading,
        tableRecordCounts,
        searchKeyword,
        handlePaginationModelChange,
        handleSortModelChange,
        fetchData,
    } = useTableLogic('divisions');
    const { handleSubmit, setValue, control, reset } = useForm();
    useTranslation();

    useEffect(() => {
        document.title = `HRMS-Division`;
    }, []);

    const downloadDivision = async () => {
        const downloadUrl = await apiInstance.getFiles('divisions/exportexcel');

        const link = document.createElement('a');
        link.href = downloadUrl;
        link.setAttribute('download', 'location.xlsx');
        document.body.appendChild(link);
        link.click();
        document.body.removeChild(link);
    };

    const handleSubmitDivision = async (values) => {
        startCreateLoading();

        await crud.create('divisions', values, (_err, res) => {
            if (res?.status === 201) {
                stopCreateLoading();
                setOpenCreate(false);
                fetchData();
                reset();
                toast.success('Division Created Successfully');
            } else {
                stopCreateLoading();
                setOpenCreate(true);
            }
        });
    };

    const handleCreateClickOpen = () => {
        reset();
        setOpenCreate(true);
    };
    const handleCreateClose = () => {
        reset();
        setOpenCreate(false);
    };
    const handleViewClickOpen = async (props) => {
        setOpenView(true);
        setLoader(true);
        setViewdetails(props);
        await crud.getSingle('divisions', props?.divisionId, (_err, res) => {
            if (res?.status === 200) {
                setSelectedRow(res?.data);
                setLoader(false);
            }
        });
    };
    const handleViewClose = () => {
        setOpenView(false);
    };

    const handleEditOpen = async (props) => {
        setOpenEdit(true);
        setLoader(true);
        await crud.getSingle('divisions', props?.divisionId, (_err, res) => {
            if (res?.status === 200) {
                Object.entries({
                    ...res?.data,
                }).forEach(([key, value]) => {
                    setValue(key, value);
                });
                setEditData(res?.data);
                setLoader(false);
            } else {
                setLoader(true);
            }
        });
    };
    const handleEditDivision = async (values) => {
        startEditLoading();
        await crud.update(
            'divisions',
            editData?.divisionId,
            values,
            (_err, res) => {
                if (res?.status === 200) {
                    stopEditLoading();
                    setOpenEdit(false);
                    fetchData();
                    toast.success('Division Updated Successfully');
                } else {
                    stopEditLoading();
                }
            }
        );
    };

    const handleDelete = async (props) => {
        await crud.remove('divisions', props?.divisionId, (_err, res) => {
            if (res?.status === 204) {
                fetchData();
                toast.success('Division Deleted Successfully');
            }
        });
    };

    const counters = [
        {
            name: 'All',
            count: tableRecordCounts?.total || 0,
        },
        {
            name: 'Active',
            count: tableRecordCounts?.active || 0,
        },
        {
            name: 'Deactivated',
            count: tableRecordCounts?.inActive || 0,
        },
    ];
    const ActionData = [
        {
            name: 'View',
            icon: Icons.view,
            onClick: (props) => handleViewClickOpen(props),
        },
        {
            name: 'Edit',
            icon: Icons.edit,
            onClick: (props) => handleEditOpen(props),
        },
        {
            name: 'Delete',
            icon: Icons.delete,
            onClick: (props) => handleDelete(props),
            danger: true,
        },
    ];
    const excludeKeys = [
        'divisionId',
        'parentDivisionId',
        'updatedDate',
        'createdDate',
        'status',
    ];

    const columns = [
        {
            field: 'action',
            headerName: '',
            width: 140,
            sortable: false,
            disableClickEventBubbling: false,

            renderCell: (params) => {
                return (
                    <TableActionButtons
                        Actions={ActionData?.map((action) => ({
                            ...action,
                            onClick: () => action.onClick(params.row),
                        }))}
                        selectedRow={params.row}
                        setSelectedRow={setSelectedRow}
                    />
                );
            },
        },
        {
            field: 'divisionCode',
            headerName: 'Division Code',
            flex: 1,
        },
        {
            field: 'divisionName',
            headerName: 'Division Name',
            flex: 1,
        },
        {
            field: 'parentDivisionName',
            headerName: 'Parent Division',
            flex: 1,
        },
        {
            field: 'status',
            headerName: 'Status',
            flex: 1,
            renderCell: (params) => {
                return (
                    <StatusBadge
                        title={params.value === 2 ? 'Active' : 'Inactive'}
                        type={params.value === 2 ? 'green' : 'red'}
                    />
                );
            },
        },
    ];

    return (
        <DashboardLayout
            title='Division'
            actionButtons={
                <>
                    <PageHeader counters={counters} />
                </>
            }
            hasSubmenu
            menu={
                type === 'pr'
                    ? Nav[3].child[2].children
                    : type === 'hrms'
                        ? Nav[0].child[5].children
                        : type === 'presales'
                            ? Nav[4].child[1].children
                            : Nav[0].child[5].children
            }

            parentMenu={
                type === 'pr'
                    ? null
                    : type === 'hrms'
                        ? Nav[3].child[2].children
                        : type === 'presales'
                            ? Nav[4].child[1].children
                            : Nav[0].child[5].children
            }

        >
            <GlassCard className='h-full-css'>
                <Box className='h-full-css'>
                    <DataTable
                        rows={rows}
                        columns={columns}
                        loading={isLoading}
                        getRowClassName={(params) =>
                            `${params.row.status === 0 && 'bg-red-50'}`
                        }
                        sortingMode='server'
                        paginationMode='server'
                        onPaginationModelChange={(model) =>
                            handlePaginationModelChange(model)
                        }
                        onSortModelChange={(model) => handleSortModelChange(model)}
                        page={pageNumber - 1}
                        pageSize={pageSize}
                        rowCount={tableRecordCounts?.total}
                        slots={{
                            toolbar: () => (
                                <Box
                                    className='p-2 w-full flex justify-between items-center'
                                    sx={{
                                        borderBottom: '1px solid',
                                        borderColor: 'border.main',
                                    }}
                                >
                                    <Box sx={{ maxWidth: '250px' }}>
                                        <TableSearch
                                            placeholder='Search'
                                            fullWidth
                                            setSearchKeyword={setSearchKeyword}
                                            searchValue={searchKeyword}
                                        />
                                    </Box>

                                    <Box className='flex items-center gap-4 justify-end'>
                                        <CreateButton name='Create' onClick={handleCreateClickOpen} />
                                        <DataTableToolbarButtons
                                            disableColumn
                                            handleExcelExport={downloadDivision}
                                        />
                                    </Box>
                                </Box>
                            ),
                        }}
                    />
                </Box>
            </GlassCard>

            {/* create edit */}

            <MuiDialogOne
                title='Create'
                open={openCreate}
                onClose={handleCreateClose}
            >
                <form onSubmit={handleSubmit(handleSubmitDivision)}>
                    <Grid container spacing={3}>
                        <Grid item md={12}>
                            <FmTextField
                                name='divisionName'
                                control={control}
                                label='Division Name'
                                rules={{
                                    required: 'Division Name is required',
                                }}
                            />
                        </Grid>
                        <Grid item md={12}>
                            <FmSearchableSelect
                                name='parentDivisionId'
                                control={control}
                                apiUrl='divisions'
                                valueField='divisionId'
                                labelField={['divisionCode', 'divisionName']}
                                showField={['divisionCode', 'divisionName']}
                                label={'Parent Division'}
                            />
                        </Grid>

                        <Grid item md={12}>
                            <ActionButtons
                                onSubmit={handleSubmit(handleSubmitDivision)}
                                onReset={reset}
                                onCancel={handleCreateClose}
                                submitLoading={createLoading}
                                cancelLoading={false}
                                submitText='Create'
                            />
                        </Grid>
                    </Grid>
                </form>
            </MuiDialogOne>

            {/* create edit */}

            <MuiDialogOne
                title='View Details'
                open={openView}
                onClose={handleViewClose}
            >
                {viewDetails && viewDetails?.divisionId && (
                    <CommonView
                        url='divisions'
                        id={viewDetails?.divisionId}
                        excludeKeys={excludeKeys}
                        renderFields={(data, fields) => (
                            <DynamicViewFields data={data} fields={fields} />
                        )}
                    />
                )}
            </MuiDialogOne>

            {/*EDIT*/}
            <MuiDialogOne
                title='Edit'
                open={openEdit}
                onClose={() => setOpenEdit(false)}
            >
                {loading ? (
                    <CommonLoader />
                ) : (
                    <form onSubmit={handleSubmit(handleEditDivision)}>
                        <Grid container spacing={3}>
                            <Grid item md={12}>
                                <FmTextField
                                    name='divisionName'
                                    control={control}
                                    label='Division Name'
                                    rules={{
                                        required: 'Division Name is required',
                                    }}
                                />
                            </Grid>
                            <Grid item md={12}>
                                <FmSearchableSelect
                                    name='parentDivisionId'
                                    control={control}
                                    apiUrl='divisions'
                                    valueField='divisionId'
                                    labelField={['divisionCode', 'divisionName']}
                                    showField={['divisionCode', 'divisionName']}
                                    label={'Parent Division'}
                                    defaultValue={{
                                        divisionId: editData?.parentDivisionId,
                                        divisionName: editData?.parentDivisionName,
                                    }}
                                />
                            </Grid>

                            <Grid item md={12}>
                                <FmAutoComplete
                                    name='status'
                                    control={control}
                                    options={statusOptions}
                                    label='Status'
                                    displayField='statusName'
                                    optionFields={['statusName']}
                                    valueKey='statusId'
                                />
                            </Grid>
                            <Grid item md={12}>
                                <ActionButtons
                                    onSubmit={handleSubmit(handleEditDivision)}
                                    onReset={reset}
                                    onCancel={() => setOpenEdit(false)}
                                    submitLoading={editLoading}
                                    cancelLoading={false}
                                    submitText='Update'
                                />
                            </Grid>
                        </Grid>
                    </form>
                )}
            </MuiDialogOne>
        </DashboardLayout>
    );
};

export default Division;
