import { Icon } from '@iconify/react';
import {
  Box,
  Button,
  Checkbox,
  Table,
  TableBody,
  TableCell,
  TableRow,
  Tooltip,
} from '@mui/material';
import React from 'react';
import { colorClassesNew } from '../../utils/Colors/ColorClasses.tsx';
import { AttendanceText, FileNameTypeFunc } from '../../utils/JsUtils';
import FmViewDateField from '../_mui/FmViewDateField.tsx';
import TableExtraContentTooltip from '../datagrid/TableExtraContentTooltip.tsx';

const colorClasses = {
  green: 'text-green-700 bg-green-200 dark:bg-green-800 dark:text-green-200',
  red: 'text-red-700 bg-red-200 dark:bg-red-800 dark:text-red-200',
  blue: 'text-blue-700 bg-blue-200 dark:bg-blue-800 dark:text-blue-200',
  black: 'text-gray-700 bg-gray-200 dark:bg-black dark:text-gray-200',
  sky: 'text-sky-700 bg-sky-200 dark:bg-sky-800 dark:text-sky-200',
  purple:
    'text-purple-700 bg-purple-200 dark:bg-purple-800 dark:text-purple-200',
  orange:
    'text-orange-700 bg-orange-200 dark:bg-orange-800 dark:text-orange-200',
  teal: 'text-teal-700 bg-teal-200 dark:bg-teal-800 dark:text-teal-200',
  indigo:
    'text-indigo-700 bg-indigo-200 dark:bg-indigo-800 dark:text-indigo-200',
  yellow:
    'text-yellow-700 bg-yellow-200 dark:bg-yellow-800 dark:text-yellow-200',
  active: 'active',
  // Add more colors as needed
};

const TextColors = {
  green: 'text-green-700',
  red: 'text-red-700',
  blue: 'text-blue-700',
};

const ViewField = ({ title, label, valueColor, widthAuto }) => {
  const capitalizedLabel = label
    ? label.charAt(0).toUpperCase() + label.slice(1)
    : '';

  return (
    <Box className={!widthAuto ? 'w-full' : ''}>
      <Box className='opacity-75'>{capitalizedLabel}</Box>
      <Box
        sx={{ fontWeight: 500, wordBreak: 'break-word' }}
        className={valueColor}
      >
        {title}
      </Box>
    </Box>
  );
};

const FileCard = ({ title, size }) => {
  return (
    <Box
      className='flex flex-row items-center w-full p-3 py-5'
      sx={{
        bgcolor: 'background.white',
        border: '1px solid',
        borderColor: 'border.main',
        borderRadius: '15px',
      }}
    >
      <Box className='text-4xl me-5'>
        <Icon icon='pepicons-print:file' />
      </Box>
      <Box>
        <Box className='font-semibold'>{title}</Box>
        <Box className='opacity-50'>{size}</Box>
      </Box>
    </Box>
  );
};

const StatusBadge = ({ title, type, normal, dotRemove, fontNormal, date }) => {
  const badgeClass = colorClasses[type] || 'text-gray-700 bg-gray-200';

  return (
    <>
      <Box
        className={`${badgeClass}  ${
          normal ? 'px-2 py-1' : 'px-1 py-0.5'
        }  gap-0.5   inline-flex items-center`}
        sx={{
          fontSize: normal ? 'inherit' : fontNormal ? 'inherit' : '11px',
          borderRadius: '4px',
          textTransform: 'uppercase',
          fontWeight: 500,
        }}
      >
        {!dotRemove ? (
          <span>
            <Icon icon='radix-icons:dot-filled' />
          </span>
        ) : null}
        {date ? <FmViewDateField value={title} /> : title}
      </Box>
    </>
  );
};

const StatusBadgeWithClick = ({ title, type, ...props }) => {
  const badgeClass = colorClasses[type] || 'text-gray-700 bg-gray-200';

  return (
    <>
      <Button
        className={`${badgeClass} px-2 py-0.5  d-flex items-center gap-1 font-semibold`}
        sx={{
          fontSize: '10px',
          textTransform: 'uppercase',
          borderRadius: '8px',
          '&.active': { bgcolor: 'primary.main', color: 'text.white' },
        }}
        {...props}
      >
        <span>
          <Icon icon='radix-icons:dot-filled' />
        </span>
        {title}
      </Button>
    </>
  );
};

const TextColor = ({ title, color }) => {
  const colors = {
    red: 'text-red-600',
    green: 'text-green-600',
  };
  return (
    <>
      <Box
        sx={{
          color: 'text.main',
          textTransform: 'uppercase',
        }}
        className={colors[color]}
      >
        {title}
      </Box>
    </>
  );
};

const CheckedInTable = ({ color, defaultChecked }) => {
  return (
    <Box>
      <Checkbox
        defaultChecked={defaultChecked}
        disabled
        color={color}
        sx={{
          '&.MuiCheckbox-colorError': {
            color: 'red',
          },
          '&.MuiCheckbox-colorSuccess': {
            color: 'green',
          },
        }}
      />
    </Box>
  );
};

const LeaveColors = ({
  text,
  tooltipOff,
  tooltipContent,
  onClick,
  startEnd,
  attendanceStatusApproved,
  disabled,
}) => {
  var AttendanceLoop = AttendanceText(text);

  const BoxTitle = ({ name, className }) => {
    return (
      <Box className='flex flex-col items-center w-full gap-1'>
        <Box
          sx={{
            fontSize: '22px',
            height: '20px',
            lineHeight: '20px',
          }}
          className={`${className} ${
            disabled ? 'cursor-pointer' : 'pointer-events-none'
          } `}
          onClick={onClick}
          role='button'
          tabIndex={0}
          disabled={!disabled}
        >
          {name}
        </Box>
        <Box
          className='flex justify-between items-center w-full text-center flex-col'
          sx={{ fontSize: '9px', lineHeight: 1 }}
        >
          {text === 'SL' && attendanceStatusApproved && (
            <Icon
              icon='ix:certificate-success-filled'
              width='18'
              height='18'
              className='text-orange-500 status-approved-check'
              title='Approved'
            />
          )}
          {startEnd?.map((item, index) => (
            <Box title={item?.label} key={index}>
              {item?.title}
            </Box>
          ))}
        </Box>
      </Box>
    );
  };

  return (
    <Box>
      {!tooltipOff ? (
        <TableExtraContentTooltip titleRows={tooltipContent}>
          <Box>
            <BoxTitle
              name={AttendanceLoop.icon}
              className={AttendanceLoop.textColor}
              tools
            />
          </Box>
        </TableExtraContentTooltip>
      ) : (
        <BoxTitle
          name={AttendanceLoop.icon}
          className={AttendanceLoop.textColor}
          tools
        />
      )}
    </Box>
  );
};

const LeaveText = ({
  text,
  tooltipOff,
  tooltipContent,
  onClick,
  attendanceStatusApproved,
  startEnd,
  disabled,
}) => {
  var AttendanceLoop = AttendanceText(text);

  const TextBoxCustomize = ({ className, name }) => {
    return (
      <Box className='flex flex-col items-center w-full gap-1 '>
        <Box
          sx={{
            fontSize: '11px',
            color: 'text.white',
            width: '20px',
            height: '20px',
            textAlign: 'center',
            lineHeight: '20px',
            borderRadius: '3px',
          }}
          className={`${className} relative ${
            !disabled
              ? 'pointer-events-none'
              : 'cursor-pointer hover:ring-2 hover:ring-teal-500'
          }`}
          onClick={onClick}
          role='button'
          tabIndex={0}
          disabled={!disabled}
          title={name}
        >
          {name}
        </Box>

        <Box
          className='flex justify-between items-center w-full text-center flex-col'
          sx={{ fontSize: '9px', lineHeight: 1 }}
        >
          {text === 'SL' && attendanceStatusApproved && (
            <Icon
              icon='ix:certificate-success-filled'
              width='18'
              height='18'
              className='text-orange-500 status-approved-check'
              title='Approved'
            />
          )}

          {startEnd?.map((item, index) => (
            <Box title={item?.label} key={index}>
              {item?.title}
            </Box>
          ))}
        </Box>
      </Box>
    );
  };
  return (
    <Box>
      {tooltipOff ? (
        <TextBoxCustomize
          className={AttendanceLoop.bg}
          name={AttendanceLoop.shortName}
        />
      ) : (
        <TableExtraContentTooltip titleRows={tooltipContent}>
          <Box>
            <TextBoxCustomize
              className={AttendanceLoop.bg}
              name={AttendanceLoop.shortName}
            />
          </Box>
        </TableExtraContentTooltip>
      )}
    </Box>
  );
};

const Indicators = ({ small, title, open }) => {
  return (
    <Box>
      <Box
        className='flex items-center'
        sx={{
          '& .status-approved-check': {
            display: 'none',
          },
        }}
      >
        {open ? (
          <LeaveText tooltipOff={true} text={small} />
        ) : (
          <LeaveColors tooltipOff={true} text={small} />
        )}
        <Box className='opacity-65 ms-2'> {title}</Box>
      </Box>
    </Box>
  );
};

const FilledTextPercentage = ({ text }) => {
  return (
    <Box
      className='flex justify-center items-center'
      sx={{
        position: 'relative',
        width: '100%',
        border: '1px solid',
        borderColor: 'secondary.main',
        height: '25px',
      }}
    >
      <Box
        sx={{
          fontWeight: 700,
          color: 'text.dark',
          position: 'relative',
          zIndex: 1,
        }}
      >
        {' '}
        {text}
      </Box>
      <Box
        sx={{
          position: 'absolute',
          left: 0,
          top: 0,
          height: '100%',
          opacity: 0.7,
          width: text,
        }}
        className='bg-gradient-to-r from-yellow-500 to-green-300 ...'
      ></Box>
    </Box>
  );
};

const ViewTable = ({
  rows,
  disableBorder,
  paddingLow,
  right,
  inverseFontWeight = false,
}) => {
  return (
    <>
      <Table sx={{}}>
        <TableBody>
          {rows.map((row, i) => (
            <TableRow
              key={i}
              sx={{
                '& .MuiTableCell-root': {
                  border: !disableBorder ? '1px solid' : 'none',
                  borderColor: 'border.main',
                  padding: !paddingLow ? '8px' : '4px',
                  fontSize: 'inherit',
                  color: 'inherit',
                },
              }}
            >
              <TableCell className={!inverseFontWeight && 'font-semibold'}>
                {row.label}
              </TableCell>
              {row.label === 'Date of Birth' ||
              row.label === 'Date of Joining' ||
              row.label === 'Date' ||
              row.label === 'Start Date' ||
              row.label === 'Inspection Start Date' ||
              row.label === 'End Date' ||
              row.label === 'Date of Prior Notification' ||
              row.label === 'Updated Date' ||
              row.date ? (
                <TableCell align={right && 'right'}>
                  {' '}
                  <FmViewDateField value={row.title} />{' '}
                </TableCell>
              ) : (
                <TableCell
                  align={right && 'right'}
                  className={`${row.titleBold && 'font-semibold'} ${
                    inverseFontWeight && 'font-semibold'
                  }`}
                >
                  {row.title}
                </TableCell>
              )}
            </TableRow>
          ))}
        </TableBody>
      </Table>
    </>
  );
};

const FileNameType = ({ name }) => {
  const fileName = name;
  const parts = fileName.split('.');
  const baseName = parts.slice(0, -1).join('.');
  const extension = parts[parts.length - 1];
  const FileTypeLoop = FileNameTypeFunc(extension);
  return (
    <Box className='flex items-center gap-3'>
      <Box className={`text-2xl ${FileTypeLoop.textColor} `}>
        {FileTypeLoop.icon}
      </Box>
      <Box className='font-semibold'>{name}</Box>
    </Box>
  );
};

const OrderNotesTooltip = ({ title, color }) => {
  return (
    <Box>
      <Tooltip title={title} arrow placement='top'>
        <Box className={`text-2xl ${TextColors[color]}`}>
          <Icon icon='carbon:information' />
        </Box>
      </Tooltip>
    </Box>
  );
};

const EnquiryStatus = ({ icon, name, color }) => {
  return (
    <Box
      className={`${colorClassesNew[color]} w-full justify-center flex py-1 px-2 font-semibold gap-1 items-center`}
      sx={{ fontSize: '12px', borderRadius: '5px' }}
    >
      <Box className='text-lg'>{icon}</Box>
      <Box>{name}</Box>
    </Box>
  );
};

const CommonTableStatus = ({ name, color }) => {
  return (
    <Box
      className={`${colorClassesNew[color]} w-full justify-center flex py-1 px-2 font-semibold gap-1 items-center`}
      sx={{ fontSize: '12px', borderRadius: '5px' }}
    >
      <Box>{name}</Box>
    </Box>
  );
};

export {
  CheckedInTable,
  EnquiryStatus,
  CommonTableStatus,
  FileCard,
  FileNameType,
  FilledTextPercentage,
  Indicators,
  LeaveColors,
  LeaveText,
  OrderNotesTooltip,
  StatusBadge,
  StatusBadgeWithClick,
  TextColor,
  ViewField,
  ViewTable,
};
