export interface LoaderState {
  isLoading: boolean;
  createLoading: boolean;
  editLoading: boolean;
}

// Initial state
export const initialState: LoaderState = {
  isLoading: true,
  createLoading: false,
  editLoading: false,
};

// Action Types
export const SET_LOADING = 'SET_LOADING';
export const SET_CREATE_LOADING = 'SET_CREATE_LOADING';
export const SET_EDIT_LOADING = 'SET_EDIT_LOADING';

// Reducer
export function loaderReducer(
  state: LoaderState = initialState,
  action: { type: string; payload: boolean }
): LoaderState {
  switch (action.type) {
    case SET_LOADING:
      return { ...state, isLoading: action.payload };
    case SET_CREATE_LOADING:
      return { ...state, createLoading: action.payload };
    case SET_EDIT_LOADING:
      return { ...state, editLoading: action.payload };
    default:
      return state;
  }
}

// Action Creators
export const setLoading = (payload: boolean) => ({
  type: SET_LOADING,
  payload,
});

export const setCreateLoading = (payload: boolean) => ({
  type: SET_CREATE_LOADING,
  payload,
});

export const setEditLoading = (payload: boolean) => ({
  type: SET_EDIT_LOADING,
  payload,
});

// Initialize state
let currentState = initialState;

// Accessor function to get the current state
export function getLoaderState(): LoaderState {
  return currentState;
}

// Dispatch function to update state using the reducer
export function dispatch(action: { type: string; payload: boolean }) {
  currentState = loaderReducer(currentState, action);
}

