import React from 'react';
import GlassCard from '../../../components/small/GlassCard.tsx';
import MRTExpandTableStyle from '../../../components/table/MRTExpandTableStyle.tsx';
import { Box } from '@mui/material';
import useTableLogic from '../../../components/helpers/MRTUseTableLogic.tsx';
import FmMRTDataTable from '../../../components/FmMRTDataTable.tsx';
import dayjs from 'dayjs';
import DataGridHeaderTooltip from '../../../components/datagrid/DataGridHeaderTooltip.tsx';
import TableAvatar from '../../../components/_form/TableAvatar.jsx';
import ViewTwoline from '../../../components/view/ViewTwoline.tsx';
import { StatusBadge } from '../../../components/_form/FormElements.jsx';
import FmViewDateField from '../../../components/_mui/FmViewDateField.tsx';

const GratuityAccruals = () => {
  const {
    rows,
    rowCount,
    isLoading,
    pagination,
    sorting,
    columnFilters,
    globalFilter,
    tableRecordCounts,
    setPagination,
    setSorting,
    setColumnFilters,
    setGlobalFilter,
    fetchData,
    setSearchKeyword,
    searchKeyword,
  } = useTableLogic(
    'employeeaccrualsgratuities',
    ['fromDate'],
    [dayjs().format('YYYY-MM-DD')]
  );

  // Define the columns
  const columns = [
    {
      accessorKey: 'employeeName',
      header: 'Employee Name',
      Cell: ({ cell, row }) => (
        <TableAvatar
          name={cell.getValue()}
          desc={`Code : ${row?.original?.employeeCode}`}
        />
      ),
      size: 300,
    },

    {
      accessorKey: 'joiningDate',
      header: 'Joining Date',
      customDate: true,
      Cell: ({ renderedCellValue }) => (
        <ViewTwoline
          data={[
            {
              name: false,
              value: <FmViewDateField value={renderedCellValue} />,
            },
          ]}
        />
      ),
    },

    {
      accessorKey: 'startDate',
      header: 'Date',
      customDate: true,
      size: 180,
      Cell: ({ row }) => (
        <ViewTwoline
          data={[
            {
              name: 'Start :',
              value: <FmViewDateField value={row.original['startDate']} />,
              className: 'text-green-600',
            },
            {
              name: 'End :',
              value: <FmViewDateField value={row.original['endDate']} />,
              className: ' text-red-600',
            },
          ]}
        />
      ),
    },

    {
      accessorKey: 'gratuityPerDay',
      header: 'Gratuity',
      size: 140,
      Cell: ({ renderedCellValue, row }) => (
        <ViewTwoline
          data={[
            {
              name: 'Per Day :',
              value: renderedCellValue,
              className: ' w-full',
            },
            {
              name: 'Total :',
              value: row?.original?.gratuityTotal,
              className: ' w-full  font-500',
            },
          ]}
          rounded
        />
      ),
      muiTableBodyCellProps: {
        className: 'bg-yellow-50',
      },
    },

    {
      accessorKey: 'lastGenerationDate',
      header: 'Last Generation',
      size: 140,
      customDate: true,
      Cell: ({ renderedCellValue, row }) => (
        <ViewTwoline
          data={[
            {
              name: 'Per Day :',
              value: (
                <FmViewDateField value={row.original['lastGenerationDate']} />
              ),
              className: ' w-full',
            },
            {
              name: 'Days :',
              value: row?.original?.lastGenerationDays,
              className: ' w-full ',
            },
          ]}
          rounded
        />
      ),
      muiTableBodyCellProps: {
        className: 'bg-sky-50',
      },
    },

    {
      accessorKey: 'runningStatusName',
      header: 'Running Status',
      size: 150,
      Cell: ({ renderedCellValue }) => (
        <StatusBadge title={renderedCellValue} type={'green'} />
      ),
    },
  ];

  return (
    <GlassCard className='overflow-hidden h-full-css'>
      <MRTExpandTableStyle enableFullHeight>
        <Box>
          <FmMRTDataTable
            columns={columns}
            enableRowSelection={false}
            rows={rows}
            rowCount={tableRecordCounts}
            // editingRow={editingRowId}
            pagination={pagination}
            sorting={sorting}
            columnFilters={columnFilters}
            globalFilter={globalFilter}
            setPagination={setPagination}
            setSorting={setSorting}
            fetchData={fetchData}
            setColumnFilters={setColumnFilters}
            setGlobalFilter={setGlobalFilter}
            isLoading={isLoading}
            setSearchKeyword={setSearchKeyword}
            searchKeyword={searchKeyword}
            // showSerialNumber={true}
          />
        </Box>
      </MRTExpandTableStyle>
    </GlassCard>
  );
};

export default GratuityAccruals;
