import React, { useEffect, useState } from 'react';
import DashboardLayout from '../../../components/DashboardLayout.tsx';
import { Box, Grid } from '@mui/material';
import GlassCard from '../../../components/small/GlassCard.tsx';
import { ActionButtons } from '../../../components/_form';
import { useNavigate, useParams } from 'react-router-dom';
import Crud_Service from '../../../apis/CrudService';
import { useForm } from 'react-hook-form';
import { useTranslation } from 'react-i18next';
import { LocalizationProvider } from '@mui/x-date-pickers/LocalizationProvider';
import { AdapterDayjs } from '@mui/x-date-pickers/AdapterDayjs';
import dayjs from 'dayjs';
import useLoader from '../../../components/helpers/UseLoader.tsx';
import FmTextField from '../../../components/_mui/FmTextField.tsx';
import FmAutoComplete from '../../../components/_mui/FmAutoComplete.tsx';
import { yupResolver } from '@hookform/resolvers/yup';
import { BooleanOptions } from '../../../utils/CommonVariables.tsx';
import { toast } from 'react-toastify';
import useCommonFetchApi from '../../../components/helpers/useCommonFetchApi.tsx';
import FmSearchableSelect from '../../../components/_mui/FmSearchableSelect.tsx';
import FmDatePicker from '../../../components/_mui/FmDatePicker.tsx';
import { enquiryValidationSchema } from '../../../utils/CommonvalidationSchemas.tsx';
import ElementLoader from './../../../components/page/ElementLoader.tsx';

const EditEnquiry = () => {
  const navigate = useNavigate();
  const crud = new Crud_Service();
  const { id } = useParams();
  const [loading, setLoading] = useState(false);
  const [buyer, setBuyer] = useState(null);
  const { editLoading, startEditLoading, stopEditLoading } = useLoader();
  const [bidBond, setBidbond] = useState();
  const [expressOfInterest, setExpressOfInterest] = useState(null);
  const { t } = useTranslation();
  const [tender, setTender] = useState({});
  const { setValue, handleSubmit, control, watch, getValues, reset } = useForm({
    resolver: yupResolver(enquiryValidationSchema),
    mode: 'onChange',
  });
  const statusOptions = [
    { statusId: 2, statusName: 'Active' },
    { statusId: 1, statusName: 'Inactive' },
  ];
  const defaultValues = {
    tenderId: tender?.tenderId,
    tenderName: tender?.tenderName,
  };
  useEffect(() => {
    getTenders();
  }, []);

  useEffect(() => {
    watch('startDate') && setValue('lastDate', watch('startDate'));
  }, [watch('startDate')]);

  const getTenders = async () => {
    setLoading(true);
    await crud.getSingle('tenders', id, (_err, res) => {
      if (res?.status === 200) {
        setTender(res?.data);
        Object.entries({
          ...res?.data,
        }).forEach(([key, value]) => {
          setValue(key, value);
          if (key === 'buyTenderDocument') {
            setBuyer(value);
            !value && setValue('cost', null);
          }
          if (key === 'bidBond') {
            setBidbond(value);
            !value && setValue('bidBondFormatId', null);
            !value && setValue('bidBondValue', null);
            !value && setValue('bidBondTypeId', null);
            !value && setValue('bidBondTypeValue', null);
          }
        });

        setLoading(false);
      } else {
        toast.error(t('something_Went_Wrong'));
      }
    });
  };
  const { data: tenderEnquiryTypes } = useCommonFetchApi('tenderenquirytypes');
  const { data: tenderProjectTypes } = useCommonFetchApi('tenderprojecttypes');
  const { data: tenderFormats } = useCommonFetchApi('tenderformats');
  const { data: tenderStatuses } = useCommonFetchApi('tenderstatuses');

  const handleEditTenders = async (values) => {
    startEditLoading();
    const CombinedData = {
      ...values,
      bidBondValue: Number(values?.bidBondValue) || null,
      startDate: dayjs(values?.startDate).format('YYYY-MM-DD'),
      lastDate: dayjs(values?.lastDate).format('YYYY-MM-DD'),
      tenderValue: Number(values?.tenderValue),
      status: Number(values?.status),
    };

    await crud.update('tenders', id, CombinedData, (_err, res) => {
      if (res?.status === 200) {
        navigate(-1);
        stopEditLoading();
        toast.success('Enquiry Updated Successfully');
      } else {
        toast.error(t('something_Went_Wrong'));
        stopEditLoading();
      }
    });
  };
  const handleBidBondChange = (e) => {
    setBidbond(e);
    !e && setValue('bidBondFormatId', null);
    !e && setValue('bidBondValue', null);
    !e && setValue('bidBondTypeId', null);
    !e && setValue('bidBondTypeValue', null);
  };

  const handleOnChangeBuyer = (e) => {
    setBuyer(e?.value);
    !e?.value && setValue('cost', null);
  };
  const handleExpressOfInterest = (e) => {
    setExpressOfInterest(e?.value);
  };

  return (
    <DashboardLayout title={`Edit Enquiry -  ${tender?.tenderCode}`}>
      <GlassCard className='p-4'>
        {loading ? (
          <ElementLoader />
        ) : (
          <form onSubmit={handleSubmit(handleEditTenders)}>
            <LocalizationProvider dateAdapter={AdapterDayjs}>
              <Grid container spacing={2}>
                <Grid item md={3}>
                  <FmTextField
                    name='tenderName'
                    control={control}
                    label='Tender Name'
                    defaultValue={defaultValues}
                    readOnly
                  />
                </Grid>
                <Grid item md={3}>
                  <FmTextField
                    name='tenderNumber'
                    control={control}
                    label='Tender Reference Number'
                    required
                  />
                </Grid>
                <Grid item md={3}>
                  <FmSearchableSelect
                    name='companyId'
                    control={control}
                    apiUrl='companies'
                    label='Quote By Company'
                    defaultValue={{
                      companyName: tender?.companyName,
                      companyId: tender?.companyId,
                    }}
                    showField={['companyName']}
                    headerField={['companyName']}
                    labelField={['companyName']}
                    valueField='companyId'
                    required
                  />
                </Grid>
                {/* TODO */}
                {/* <Grid item md={3}>
                  <FmTextField
                    name='requestForEstimate'
                    control={control}
                    label='Request For Estimate'
                    required
                  />
                </Grid> */}
                <Grid item md={3}>
                  <FmSearchableSelect
                    name='clientId'
                    control={control}
                    apiUrl='clients'
                    valueField='clientId'
                    defaultValue={{
                      clientName: tender?.clientName,
                      clientId: tender?.clientId,
                    }}
                    headerField={['Client Code', 'Client Name']}
                    labelField={['clientCode', 'clientName']}
                    showField={['clientName']}
                    pageSize={20}
                    label='Client'
                    required
                  />
                </Grid>
                <Grid item md={3}>
                  <FmAutoComplete
                    name='tenderEnquiryTypeId'
                    control={control}
                    options={tenderEnquiryTypes}
                    label='Mode of submission'
                    displayField='tenderEnquiryTypeName'
                    optionFields={['tenderEnquiryTypeName']}
                    valueKey='tenderEnquiryTypeId'
                    required
                  />
                </Grid>
                <Grid item md={3}>
                  <FmSearchableSelect
                    name='divisionId'
                    control={control}
                    apiUrl='divisions'
                    valueField='divisionId'
                    labelField={['divisionCode', 'divisionName']}
                    showField={['divisionCode', 'divisionName']}
                    label={'Division'}
                  />
                </Grid>
                <Grid item md={3}>
                  <FmSearchableSelect
                    name='emirateId '
                    control={control}
                    apiUrl='emirates'
                    valueField='emirateId'
                    labelField={['emirateName']}
                    showField={['emirateName']}
                    label={'Emirate'}
                  />
                </Grid>
                <Grid item md={3}>
                  <FmAutoComplete
                    name='tenderProjectTypeId'
                    control={control}
                    options={tenderProjectTypes}
                    label='Enquiry Type'
                    displayField='tenderProjectTypeName'
                    optionFields={['tenderProjectTypeName']}
                    valueKey='tenderProjectTypeId'
                    required
                  />
                </Grid>
                <Grid item md={3}>
                  <FmAutoComplete
                    name='tenderFormatId'
                    control={control}
                    options={tenderFormats}
                    label='Enquiry Format'
                    displayField='tenderFormatName'
                    optionFields={['tenderFormatName']}
                    valueKey='tenderFormatId'
                    required
                  />
                </Grid>

                <Grid item md={3}>
                  <FmAutoComplete
                    name='bidBond'
                    control={control}
                    options={[
                      { value: true, bidBondName: 'Yes' },
                      { value: false, bidBondName: 'No' },
                    ]}
                    label='Bid Bond'
                    displayField='bidBondName'
                    optionFields={['bidBondName']}
                    valueKey='value'
                    onChange={handleBidBondChange}
                  />
                </Grid>
                {bidBond === true && (
                  <>
                    <Grid item md={3}>
                      <FmAutoComplete
                        name='bidBondFormatId'
                        control={control}
                        options={[
                          {
                            name: 'Standard',
                            value: 1,
                          },
                          {
                            name: 'Specific',
                            value: 2,
                          },
                        ]}
                        label='Bid Bond Format'
                        displayField='name'
                        optionFields={['name']}
                        valueKey='value'
                        required
                      />
                    </Grid>
                    <Grid item md={3}>
                      <FmAutoComplete
                        name='bidBondTypeValue'
                        control={control}
                        options={[
                          { name: 'Percentage', value: 'Percentage' },
                          { name: 'Flat', value: 'Flat' },
                        ]}
                        label='Bid Bond Type Value'
                        displayField='name'
                        optionFields={['name']}
                        valueKey='value'
                        required
                      />
                    </Grid>
                    <Grid item md={3}>
                      <FmTextField
                        name='bidBondValue'
                        control={control}
                        label='Bid Bond Value'
                        required
                        pattern='Number'
                        onChange={(e) => {
                          let value = parseFloat(e.target.value);
                          if (getValues('bidBondTypeValue') === 'Percentage') {
                            if (isNaN(value)) {
                              value = 0;
                            }
                            if (value > 100) {
                              value = 100;
                            } else if (value < 0) {
                              value = 0;
                            }
                          } else {
                            value = value;
                          }
                          setValue('bidBondValue', value);
                        }}
                      />
                    </Grid>

                    <Grid item md={3}>
                      <FmAutoComplete
                        name='bidBondTypeId'
                        control={control}
                        options={[
                          {
                            name: 'Auto Renewal',
                            value: 1,
                          },
                          {
                            name: 'Fixed Enquiry',
                            value: 2,
                          },
                        ]}
                        label='Bid Bond Type'
                        displayField='name'
                        optionFields={['name']}
                        valueKey='value'
                        required
                      />
                    </Grid>
                  </>
                )}
                <Grid item md={3}>
                  <FmAutoComplete
                    name='autoRenewal'
                    control={control}
                    options={[
                      {
                        name: 'Yes',
                        value: true,
                      },
                      {
                        name: 'No',
                        value: false,
                      },
                    ]}
                    label='Auto Renewal'
                    displayField='name'
                    optionFields={['name']}
                    valueKey='value'
                  />
                </Grid>

                <Grid item md={3}>
                  <FmAutoComplete
                    name='subContractRequired'
                    control={control}
                    options={[
                      {
                        name: 'Yes',
                        value: true,
                      },
                      {
                        name: 'No',
                        value: false,
                      },
                    ]}
                    label='Sub Contract Required'
                    displayField='name'
                    optionFields={['name']}
                    valueKey='value'
                    required
                  />
                </Grid>

                <Grid item md={3}>
                  <FmDatePicker
                    control={control}
                    name='startDate'
                    label='Start Date'
                    required
                  />
                </Grid>

                <Grid item md={3}>
                  <FmDatePicker
                    control={control}
                    name='lastDate'
                    label='Last Date'
                    minDate={
                      dayjs(watch('startDate')) || dayjs(getValues('startDate'))
                    }
                  />
                </Grid>

                <Grid item md={3}>
                  <FmTextField
                    name='tenderValue'
                    control={control}
                    label='Tender / Final Proposal Value'
                    pattern='Number'
                  />
                </Grid>

                <Grid item md={3}>
                  <FmAutoComplete
                    name='buyTenderDocument'
                    control={control}
                    options={BooleanOptions}
                    label='Buy Enquiry Document'
                    displayField='label'
                    optionFields={['label']}
                    valueKey='value'
                    onChange={handleOnChangeBuyer}
                    onChangeValue={true}
                  />
                </Grid>
                {buyer === true && (
                  <Grid item md={3}>
                    <FmTextField
                      name='cost'
                      control={control}
                      label='Costs'
                      pattern='Number'
                    />
                  </Grid>
                )}
                <Grid item md={3}>
                  <FmAutoComplete
                    name='expressOfInterest'
                    control={control}
                    options={[
                      {
                        name: 'Yes',
                        value: true,
                      },
                      {
                        name: 'No',
                        value: false,
                      },
                    ]}
                    label='Express Of Interest'
                    displayField='name'
                    optionFields={['name']}
                    valueKey='value'
                    onChange={handleExpressOfInterest}
                    onChangeValue={true}
                    required
                  />
                </Grid>
                {expressOfInterest === true && (
                  <Grid item md={3}>
                    <FmDatePicker
                      control={control}
                      name="expressOfInterestDate"
                      label="EOI Date"
                    />
                  </Grid>
                )}
                <Grid item md={3}>
                  <FmAutoComplete
                    name='tenderStatusId'
                    control={control}
                    options={tenderStatuses}
                    label='Enquiry Status'
                    displayField='tenderStatusName'
                    optionFields={['tenderStatusName']}
                    valueKey='tenderStatusId'
                    required
                  />
                </Grid>
                <Grid item md={3}>
                  <FmAutoComplete
                    name='status'
                    control={control}
                    options={statusOptions}
                    label='Status'
                    displayField='statusName'
                    optionFields={['statusName']}
                    valueKey='statusId'
                  />
                </Grid>
                <Grid item md={12}>
                  <Grid container spacing={1}>
                    <Grid item md={6}>
                      <FmTextField
                        name='remarks'
                        control={control}
                        label='Enquiry Remarks'
                        multiline
                        rows={3}
                        maxRows={3}
                      />
                    </Grid>
                  </Grid>
                </Grid>
              </Grid>
            </LocalizationProvider>
            <ActionButtons
              onSubmit={handleSubmit(handleEditTenders)}
              onCancel={() => navigate(-1)}
              onReset={reset}
              submitLoading={editLoading}
              cancelLoading={false}
              submitText='Update'
            />
          </form>
        )}
      </GlassCard>
    </DashboardLayout>
  );
};

export default EditEnquiry;
