import React from 'react';
import { Box } from '@mui/material';
import { Icon } from '@iconify/react/dist/iconify.js';

const NoDataResults = () => {
  return (
    <div
      style={{
        display: 'flex',
        justifyContent: 'center',
        alignItems: 'center',
        height: '100%',
      }}
    >
      <div className='text-center flex-col flex gap-6 justify-center items-center'>
        <Box className='relative'>
          <div className='w-full flex justify-center items-center mb-1 opacity-30'>
            <Icon icon='icon-park-twotone:data' width='120' height='120' />
          </div>

          <div className=' w-full'>
            <h3 className='text-base font-semibold opacity-50'>
              No Data Found
            </h3>
            <p className='opacity-50'>
              There is no data to show you right now.
            </p>
          </div>
        </Box>
      </div>
    </div>
  );
};

const NoSearchAvailable = () => {
  return (
    <div
      style={{
        display: 'flex',
        justifyContent: 'center',
        alignItems: 'center',
        height: '100%',
      }}
    >
      <div className='text-center flex-col flex gap-6 justify-center items-center opacity-30'>
        <Box className='relative'>
          <div className='w-full flex justify-center items-center mb-1'>
            <Icon
              icon='lets-icons:folder-search-duotone'
              width='120'
              height='120'
            />
          </div>

          <div className=' w-full'>
            <h3 className='text-base font-semibold'>No Data Found</h3>
            <p>There is no data to show you right now.</p>
          </div>
        </Box>
      </div>
    </div>
  );
};

const NoDataAvailable = ({ searchText }) => {
  return searchText ? <NoSearchAvailable /> : <NoDataResults />;
};

export default NoDataAvailable;
