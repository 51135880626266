import React, { useState, useRef, useEffect } from 'react';
import {
  Box,
  TextField,
  InputAdornment,
  IconButton,
  CircularProgress,
} from '@mui/material';
import { Icon } from '@iconify/react';
import { useHotkeys } from 'react-hotkeys-hook';

const TableSearch = ({
  setSearchKeyword,
  searchValue,
  fullWidth,
  fullWidthNew,
  bgLight,
  ...props
}) => {
  const [value, setValue] = useState(searchValue);

  const inputRef = useRef(null);

  const handleSubmit = (event) => {
    event.preventDefault();
    event.stopPropagation();
    setSearchKeyword(value);
  };

  const handleChange = (event) => {
    setValue(event.target.value);
  };

  const handleClear = () => {
    setValue('');
    setSearchKeyword('');
    inputRef.current.focus();
  };

  useHotkeys('ctrl+s', (event) => {
    event.preventDefault();
    inputRef.current.focus();
  });

  useEffect(() => {
    setValue(searchValue);
  }, [searchValue]);

  return (
    <Box
      className={`flex items-center h-[30px]  ${
        !fullWidth ? 'w-[250px]' : 'w-250px'
      } ${fullWidthNew && 'w-full'}`}
      component={'form'}
      onSubmit={(e) => handleSubmit(e)}
    >
      <TextField
        {...props}
        value={value}
        onChange={handleChange}
        inputRef={inputRef}
        fullWidth
        InputProps={{
          endAdornment: (
            <>
              {' '}
              <InputAdornment position='end'>
                {value && (
                  <IconButton onClick={handleClear} size='small'>
                    <Icon icon='fluent:dismiss-48-filled' />
                  </IconButton>
                )}

                <IconButton
                  onClick={(e) => handleSubmit(e)}
                  type='submit'
                  className='p-0 w-[25px] h-[25px]'
                >
                  <Icon icon='fluent:search-32-filled' width='18' height='18' />
                </IconButton>
              </InputAdornment>
            </>
          ),
        }}
        sx={{
          '& .MuiInputBase-input': {
            padding: '0px 10px!important',
            height: '31px',
          },
          '& .MuiInputAdornment-root': {
            marginTop: '0 !important',
            fontSize: '13px',
            ...(bgLight && {
              bgcolor: 'background.light',
            }),
          },
          '& .MuiInputBase-root': {
            fontWeight: 600,
            paddingRight: '3px',
            height: '31px',
            ...(bgLight && {
              bgcolor: 'background.light',
            }),
          },
        }}
      />
    </Box>
  );
};

export default TableSearch;
