import React, { useEffect } from 'react';
import { Box } from '@mui/material';
import FmSearchableSelect from '../_mui/FmSearchableSelect.tsx';
import FmAutoComplete from '../_mui/FmAutoComplete.tsx';
import {
  statementFilterOptions,
  transactionFilterOptions,
} from '../../utils/CommonVariables.tsx';

const PRCardHeader = ({
  handleBankStatementFilterChange,
  handleTransactionFilterChange,
  control,
  watch,
  setValue,
}) => {
  const statementFilter = watch('statementFilter', 'all');
  const transactionFilter = watch('transactionFilter', 'all');

  const syncAndUpdateFilters = (changedField, selectedValue) => {
    let newStatementFilter = statementFilter;
    let newTransactionFilter = transactionFilter;

    if (changedField === 'statementFilter') {
      newStatementFilter = selectedValue;
      if (selectedValue === 'false') {
        newTransactionFilter = 'true';
      } else if (selectedValue === 'true') {
        newTransactionFilter = 'false';
      }
      setValue('statementFilter', newStatementFilter);
      setValue('transactionFilter', newTransactionFilter);
      handleBankStatementFilterChange({
        target: { name: 'statementFilter', value: newStatementFilter },
      });
    } else if (changedField === 'transactionFilter') {
      newTransactionFilter = selectedValue;
      if (selectedValue === 'true') {
        newStatementFilter = 'false';
      } else if (selectedValue === 'false') {
        newStatementFilter = 'true';
      }
      setValue('transactionFilter', newTransactionFilter);
      setValue('statementFilter', newStatementFilter);
      handleTransactionFilterChange({
        target: { name: 'transactionFilter', value: newTransactionFilter },
      });
    }
  };

  return (
    <Box
      className='grid grid-cols-2 gap-[65px] p-2'
      sx={{
        bgcolor: 'background.white',
        color: 'text.dark',
        borderBottom: '1px solid',
        borderColor: 'border.main',
      }}
    >
      <Box className='font-semibold flex items-center gap-4'>
        Bank Statement
        <Box className='w-[200px]'>
          <FmAutoComplete
            name='statementFilter'
            control={control}
            options={statementFilterOptions}
            displayField='label'
            TextFieldVariant='outlined'
            optionFields={['label']}
            valueKey='value'
            onChange={(selectedValue) => {
              syncAndUpdateFilters('statementFilter', selectedValue);
            }}
            className='filter-autocomplete'
          />
        </Box>
      </Box>
      <Box className='font-semibold flex items-center gap-4'>
        Transaction Statement
        <Box className='w-[200px]'>
          <FmAutoComplete
            name='transactionFilter'
            control={control}
            options={transactionFilterOptions}
            displayField='label'
            optionFields={['label']}
            valueKey='value'
            TextFieldVariant='outlined'
            onChange={(selectedValue) => {
              syncAndUpdateFilters('transactionFilter', selectedValue);
            }}
            className='filter-autocomplete'
          />
        </Box>
      </Box>
    </Box>
  );
};

export default PRCardHeader;
