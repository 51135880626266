import React, { useState, useEffect } from 'react';
import { DragDropContext, Droppable, Draggable } from 'react-beautiful-dnd';
import { Box, Checkbox } from '@mui/material';
import GlassCard from '../../../../components/small/GlassCard.tsx';
import PRCardDrag from '../../../../components/page/PRCardDrag.tsx';
import PRCardHeader from '../../../../components/page/PRCardHeader.tsx';
import ActionButtons from './../../../../components/_form/ActionButtons.tsx';
import { useNavigate, useParams } from 'react-router-dom';
import Crud_Service from '../../../../apis/CrudService.jsx';
import { toast } from 'react-toastify';
import { useForm } from 'react-hook-form';
import FmViewDateField from '../../../../components/_mui/FmViewDateField.tsx';
import NoItemsSelectedState from '../../../../components/card/NoItemsSelectedState.tsx';
import FmSearchableSelect from '../../../../components/_mui/FmSearchableSelect.tsx';
import { SkeletonLoaderRow } from '../../../../components/page/SkeletonLoader.tsx';
import { Icon } from '@iconify/react/dist/iconify.js';

const PRReconciliationMatch = (props) => {
  const matchedArrays = [
    { id: 1, name: 'All' },
    { id: 2, name: 'Matched' },
    { id: 3, name: 'Unmatched' },
  ];
  const { startDate, endDate, isEditable = false, viewData } = props;

  const [data, setData] = useState([]);
  const [active, setActive] = useState(3);
  const [statementFilter, setStatementFilter] = useState('all');
  const [transactionFilter, setTransactionFilter] = useState('all');
  const [reconciledData, setReconciledData] = useState([]);
  const [filteredReconciledData, setFilteredReconciledData] = useState([]);
  const { handleSubmit, control, watch, setValue } = useForm();
  const [checkedPairs, setCheckedPairs] = useState([]);
  const [bankId, setBankId] = useState(null);
  const [loading, setLoading] = useState(false);
  const navigate = useNavigate();
  const crud = new Crud_Service();
  const { id } = useParams();

  const normalizeDate = (date) => {
    if (!date) return null;
    const [day, month, year] = date.split('-');
    return `${year}-${month}-${day}`;
  };

  const isAnyCheckboxChecked = checkedPairs.some(
    (group) => group && group.some((checked) => checked)
  );

  const isGroupChecked = (index) => {
    return checkedPairs[index]?.every((item) => item === true) || false;
  };
  const calculateReconciliation = (data) => {
    return data.map((entry) => {
      if (
        !entry.customerAccountStatements?.length ||
        !entry.customerTransactionStatements?.length
      ) {
        return { ...entry, reconciled: false };
      }
      const bankTotal = entry.customerAccountStatements.reduce(
        (sum, item) => sum + (item.data.creditAmount || 0),
        0
      );
      const transactionTotal = entry.customerTransactionStatements.reduce(
        (sum, item) => sum + (item.data.totalAmount || 0),
        0
      );
      return { ...entry, reconciled: bankTotal === transactionTotal };
    });
  };

  const getReconciledData = async (isMatchStatus) => {
    const selectedBankId = bankId?.bankId ?? bankId;
    if (!selectedBankId) return;
    const isCredit = statementFilter === 'all' ? null : statementFilter;

    let isPayable = null;
    if (isCredit === 'true') {
      isPayable = 'false';
    } else if (isCredit === 'false') {
      isPayable = 'true';
    }

    setLoading(true);

    await crud.getAll(
      `customeraccountstatementitems/bank/${selectedBankId}`,
      {
        customerAccountStatementId: id,
        startDate: normalizeDate(startDate),
        endDate: normalizeDate(endDate),
        isCredit,
        isPayable,
        bankId: selectedBankId,
        isMatchStatus,
      },
      (err, res) => {
        if (err) {
          toast.error(err);
          setLoading(false);
        }
        if (res?.status === 200) {
          const updatedData = res?.data?.data.map((item) => {
            return {
              ...item,
              reconciled:
                item.customerAccountStatements?.some(
                  (statement) => statement.data.reconcileStatus === true
                ) || false,
            };
          });
          setReconciledData(updatedData);

          // Initialize checkedPairs with isMatchStatus instead of reconcileStatus
          const newCheckedPairs = updatedData.map((items) =>
            items.customerAccountStatements.map(
              (statement) => statement.data.isMatchStatus ?? false
            )
          );
          setCheckedPairs(newCheckedPairs);
          setData(updatedData);
          filterData(updatedData, active);
          setLoading(false);
        }
      }
    );
  };

  const handleCheckReconciled = async () => {
    const selectedCustomerAccountStatementItems = [];
    const accountStatementItemIds = reconciledData.flatMap(
      (items) =>
        items.customerAccountStatements?.map(
          (accountStmt) => accountStmt.data?.customerAccountStatementItemId
        ) || []
    );

    if (!Array.isArray(reconciledData) || reconciledData.length === 0) {
      toast.error('No reconciled data available to process');
      return;
    }

    reconciledData.forEach((items, index) => {
      if (
        items.customerTransactionStatements &&
        Array.isArray(items.customerTransactionStatements)
      ) {
        items.customerTransactionStatements.forEach(
          (transactionStatement, statementIndex) => {
            if (checkedPairs[index]?.[statementIndex]) {
              const selectedStatement = transactionStatement;
              const correspondingAccountStatement =
                items.customerAccountStatements?.[statementIndex];
              if (
                correspondingAccountStatement &&
                !correspondingAccountStatement.data?.reconcileStatus
              ) {
                selectedCustomerAccountStatementItems.push({
                  customerAccountStatementItemId:
                    accountStatementItemIds[index] || null,
                  paymentId: selectedStatement.data?.paymentId || null,
                  receiptId:
                    selectedStatement.data?.financialTransactionReceiptId ||
                    null,
                  reconcileStatus: checkedPairs[index]?.[statementIndex],
                });
              }
            }
          }
        );
      } else {
        console.warn(
          `customerTransactionStatements is missing or not an array for item at index ${index}`,
          items
        );
      }
    });

    if (selectedCustomerAccountStatementItems.length > 0) {
      const payload = {
        customerAccountStatementItems: selectedCustomerAccountStatementItems,
      };
      await crud.patch(
        `customeraccountstatementitems`,
        '',
        payload,
        (err, res) => {
          if (res?.status === 200) {
            toast.success('Reconciliation Successfully');
            navigate(-1);
          } else {
          }
        }
      );
    } else {
      toast.error('No items selected for reconciliation');
    }
  };

  const onDragEnd = (result) => {
    if (!result.destination) return;

    const { source, destination } = result;
    const [sourceDateId, sourceType] = source.droppableId.split('-');
    const [destDateId, destType] = destination.droppableId.split('-');

    if (sourceType !== destType) return;

    const newData = [...data];
    const sourceDateIndex = newData.findIndex((d) => d.date === sourceDateId);
    const destDateIndex = newData.findIndex((d) => d.date === destDateId);

    const sourceList =
      sourceType === 'bank'
        ? newData[sourceDateIndex].customerAccountStatements
        : newData[sourceDateIndex].customerTransactionStatements;
    const destList =
      destType === 'bank'
        ? newData[destDateIndex].customerAccountStatements
        : newData[destDateIndex].customerTransactionStatements;

    const [movedItem] = sourceList.splice(source.index, 1);
    destList.splice(destination.index, 0, movedItem);

    const updatedData = calculateReconciliation(newData);
    setData(updatedData);
    setReconciledData(updatedData); // Sync reconciledData
    filterData(updatedData, active);
  };

  const filterData = (data, tabId) => {
    let filtered = [...data];
    if (tabId === 2) {
      filtered = filtered.filter((item) =>
        item.customerAccountStatements?.some(
          (stmt) => stmt.data.isMatchStatus === true
        )
      );
    } else if (tabId === 3) {
      filtered = filtered.filter((item) =>
        item.customerAccountStatements?.every(
          (stmt) => stmt.data.isMatchStatus !== true
        )
      );
    } // No filtering for tabId === 1 (All)
    if (statementFilter !== 'all') {
      filtered = filtered.map((item) => ({
        ...item,
        customerAccountStatements:
          item.customerAccountStatements?.filter(
            (b) => b.data.type === statementFilter
          ) || [],
      }));
    }
    if (transactionFilter !== 'all') {
      filtered = filtered.map((item) => ({
        ...item,
        customerTransactionStatements:
          item.customerTransactionStatements?.filter(
            (t) => t.data.type === transactionFilter
          ) || [],
      }));
    }
    setFilteredReconciledData(filtered);
  };

  const handleTabClick = (id) => {
    setActive(id);
    let isMatchStatus = null; // Default to null for All tab
    if (id === 2) {
      isMatchStatus = true; // Matched tab
    } else if (id === 3) {
      isMatchStatus = false; // Unmatched tab
    }
    getReconciledData(isMatchStatus); // Fetch data with updated isMatchStatus
    filterData(data, id); // Filter the data based on the tab
  };

  const handleTransactionFilterChange = async (event) => {
    const newTransactionFilter = event.target.value;
    setReconciledData([]);
    setTransactionFilter(newTransactionFilter);
  };

  const handleBankStatementFilterChange = async (event) => {
    const newStatementFilter = event.target.value;
    setReconciledData([]);
    setStatementFilter(newStatementFilter);
  };

  useEffect(() => {
    const fetchData = async () => {
      if (bankId) {
        setReconciledData([]);
        await getReconciledData(false);
        filterData(data, active);
      }
    };
    fetchData();

    if (isEditable) {
      setBankId(viewData?.bankId);
    }
  }, [
    bankId,
    isEditable,
    startDate,
    endDate,
    statementFilter,
    transactionFilter,
  ]);

  useEffect(() => {
    setFilteredReconciledData(reconciledData);
  }, [reconciledData]);

  useEffect(() => {
    handleTabClick(3);
  }, []);

  return (
    <Box className='h-full flex flex-col relative gap-2'>
      <Box className='w-[250px]'>
        <FmSearchableSelect
          name='bankId'
          apiUrl='banks'
          control={control}
          valueField='bankId'
          headerField={['Bank Code', 'Bank Name']}
          labelField={['bankCode', 'bankName']}
          showField={['bankName']}
          defaultValue={{
            bankId: viewData?.bankId,
            bankName: viewData?.bankName,
          }}
          disabled={isEditable}
          label='Bank'
          onChangeProp={(selectedValue) => setBankId(selectedValue)}
        />
      </Box>

      {bankId ? (
        <>
          <GlassCard className='inline-flex gap-1 items-center'>
            {matchedArrays.map((item, i) => {
              return (
                <Box
                  key={i}
                  className={`cursor-pointer p-2 px-4`}
                  sx={{
                    color: active === i + 1 && 'secondary.main',
                    fontWeight: 600,
                    bgcolor: active === i + 1 && 'secondary.light',
                    opacity: active === i + 1 ? 1 : 0.8,
                    borderBottom: '3px solid',
                    borderColor:
                      active === i + 1 ? 'secondary.main' : 'transparent',
                  }}
                  onClick={() => handleTabClick(item.id)}
                >
                  {item.name}
                </Box>
              );
            })}
          </GlassCard>

          <GlassCard className='flex-1 min-h-0 flex flex-col relative' bgLight>
            <PRCardHeader
              filteredData={reconciledData}
              handleBankStatementFilterChange={handleBankStatementFilterChange}
              statementFilter={statementFilter}
              bankFilter={bankId}
              setBankFilter={setBankId}
              handleTransactionFilterChange={handleTransactionFilterChange}
              transactionFilter={transactionFilter}
              control={control}
              setValue={setValue}
              watch={watch}
              getReconciledData={getReconciledData}
            />

            <Box className='flex flex-col flex-1 min-h-0 overflow-auto'>
              {loading ? (
                <Box className='grid grid-cols-2 gap-[60px] relative'>
                  <Box>
                    <SkeletonLoaderRow count={15} />
                  </Box>
                  <Box>
                    <SkeletonLoaderRow count={15} />
                  </Box>
                </Box>
              ) : (
                <DragDropContext onDragEnd={onDragEnd}>
                  {filteredReconciledData.map((items, index) => (
                    <Box
                      key={items?.date}
                      className='w-full relative'
                      sx={{
                        borderBottom:
                          index !== filteredReconciledData.length - 1 &&
                          '1px solid',
                        borderColor: 'border.main',
                        bgcolor:
                          data[index]?.customerAccountStatements?.length > 0 &&
                          isGroupChecked(index) &&
                          'background.mainLight',
                      }}
                    >
                      <Box className='  p-2 pb-0 flex gap-2 items-center'>
                        <Box className='flex gap-1 items-center'>
                          <Icon
                            icon='stash:data-date-light'
                            width='22'
                            height='22'
                          />
                          <FmViewDateField value={items.date} />
                        </Box>
                        {data[index]?.customerAccountStatements?.length > 0 &&
                          isGroupChecked(index) && (
                            <span className='bg-green-600 px-1 rounded-sm text-white text-[9px]'>
                              MATCHED
                            </span>
                          )}
                      </Box>
                      <Box className='grid grid-cols-2 gap-[60px] relative'>
                        <Droppable droppableId={`${items.date}-bank`}>
                          {(provided) => (
                            <Box
                              ref={provided.innerRef}
                              {...provided.droppableProps}
                              className='w-full min-h-[60px] p-2'
                              sx={{ border: 'none' }}
                            >
                              {items.customerAccountStatements?.map(
                                (statement, itemIndex) => (
                                  <Draggable
                                    key={
                                      statement.data
                                        .customerAccountStatementItemId
                                    }
                                    draggableId={`${statement.data.customerAccountStatementItemId}`}
                                    index={itemIndex}
                                  >
                                    {(provided) => (
                                      <Box
                                        ref={provided.innerRef}
                                        {...provided.draggableProps}
                                        {...provided.dragHandleProps}
                                        sx={{
                                          display: 'flex',
                                          alignItems: 'center',
                                          border: 'none',
                                        }}
                                      >
                                        <PRCardDrag
                                          description={
                                            statement.data.description
                                          }
                                          bankReferenceNo={
                                            statement.data.bankReferenceNo
                                          }
                                          customerReferenceNo={
                                            statement.data.customerReferenceNo
                                          }
                                          debitAmount={
                                            statement.data.debitAmount
                                          }
                                          creditAmount={
                                            statement.data.creditAmount
                                          }
                                          chequeNo={statement.data.chequeNo}
                                        />
                                      </Box>
                                    )}
                                  </Draggable>
                                )
                              )}
                              {provided.placeholder}
                            </Box>
                          )}
                        </Droppable>
                        <Box
                          className='min-h-[60px] w-[60px] absolute left-1/2 -translate-x-1/2 p-2 flex flex-col items-center'
                          sx={{ border: 'none' }}
                        >
                          {items.customerAccountStatements?.map(
                            (statement, statementIndex) => {
                              const isMatchStatus =
                                statement.data.isMatchStatus ?? false;

                              const isReconciled =
                                statement.data.reconcileStatus ?? false;
                              const isChecked =
                                checkedPairs?.[index]?.[statementIndex] !==
                                undefined
                                  ? checkedPairs[index][statementIndex]
                                  : isMatchStatus;

                              return (
                                <Checkbox
                                  name='reconcileStatus'
                                  key={statementIndex}
                                  checked={isChecked}
                                  disabled={isReconciled}
                                  onChange={(e) => {
                                    const checked = e.target.checked;
                                    setCheckedPairs((prev) =>
                                      prev.map((group, groupIndex) => {
                                        if (groupIndex === index) {
                                          return group.map((item, itemIndex) =>
                                            itemIndex === statementIndex
                                              ? checked
                                              : item
                                          );
                                        }
                                        return group;
                                      })
                                    );
                                  }}
                                  sx={{ margin: 'auto' }}
                                />
                              );
                            }
                          )}
                        </Box>
                        <Droppable droppableId={`${items.date}-transaction`}>
                          {(provided) => (
                            <Box
                              ref={provided.innerRef}
                              {...provided.droppableProps}
                              className='w-full min-h-[60px] p-2'
                              sx={{ border: 'none' }}
                            >
                              {items.customerTransactionStatements?.length >
                              0 ? (
                                items.customerTransactionStatements.map(
                                  (statement, statementIndex) => (
                                    <Draggable
                                      key={
                                        statement.data
                                          .financialTransactionReceiptId
                                      }
                                      draggableId={`${statement.data.financialTransactionReceiptId}`}
                                      index={statementIndex}
                                    >
                                      {(provided) => (
                                        <Box
                                          ref={provided.innerRef}
                                          {...provided.draggableProps}
                                          {...provided.dragHandleProps}
                                          sx={{ border: 'none' }}
                                        >
                                          <PRCardDrag
                                            isTransaction={true}
                                            description={statement.data.remarks}
                                            financialTransactionReceiptCode={
                                              statement.data
                                                .financialTransactionReceiptCode ||
                                              statement.data.paymentCode
                                            }
                                            receiptTotalAmount={
                                              statement.data.totalAmount ||
                                              statement.data.paymentTotalAmount
                                            }
                                            paymentTotalAmount
                                            customerReferenceNo={items.date}
                                            chequeNo={statement.data.chequeNo}
                                          />
                                        </Box>
                                      )}
                                    </Draggable>
                                  )
                                )
                              ) : (
                                <Box className='text-center text-gray-500 p-2'>
                                  No transactions found for {items.date}
                                </Box>
                              )}
                              {provided.placeholder}
                            </Box>
                          )}
                        </Droppable>
                      </Box>
                    </Box>
                  ))}
                </DragDropContext>
              )}
            </Box>
          </GlassCard>

          <ActionButtons
            onSubmit={handleSubmit(handleCheckReconciled)}
            removeSpacing
            submitText='Submit Reconciled'
            onCancel={() => navigate(-1)}
            disabled={!isAnyCheckboxChecked}
          />
        </>
      ) : (
        <Box className='h-full flex-col items-center justify-center gap-5'>
          <NoItemsSelectedState
            caption={'Reconciliation'}
            subCaption={'Please select a bank to proceed.'}
            iconName={'bank'}
          />
        </Box>
      )}
    </Box>
  );
};

export default PRReconciliationMatch;
