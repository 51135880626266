import React, { useState } from 'react';
import { Button, Box } from '@mui/material';
import Icons from '../../utils/Icon';
import { useTranslation } from 'react-i18next';
import SearchModal from '../SearchModal';
import { useHotkeys } from 'react-hotkeys-hook';

const HeaderSearchButton = () => {
  const { t } = useTranslation();
  const [showSearch, setShowSearch] = useState(false);

  const handleOpen = () => setShowSearch(true);
  const handleClose = () => setShowSearch(false);

  useHotkeys('ctrl+k', (event) => {
    event.preventDefault();
    handleOpen();
  });

  return (
    <>
      <Button
        sx={{
          textTransform: 'none',
          padding: '3px 12px',
          color: 'inherit',
          width: '100%',
          minWidth: '360px',
          fontWeight: 400,
          textAlign: 'left',
          fontSize: 'inherit',
          cursor: 'text',
          bgcolor: 'background.overlayTwo',
          '&:hover': {
            bgcolor: 'background.overlayTwo',
          },
        }}
        className='gap-3 sm:hidden'
        onClick={handleOpen}
      >
        <Box sx={{ fontSize: '18px', display: 'flex', alignItems: 'center' }}>
          {Icons.search}
        </Box>
        <p className='flex-1'>{t('search') + '...'}</p>
        <Box
          sx={{
            fontSize: '13px',
            marginLeft: '5px',
            color: 'inherit',
            opacity: 0.7,
          }}
        >
          ⌘K
        </Box>
      </Button>

      <SearchModal openprop={showSearch} onCloseprop={handleClose} />
    </>
  );
};

export default HeaderSearchButton;
