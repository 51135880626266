import React from 'react';
import { Stack, Divider } from '@mui/material';

const FmStack = ({ children, dividerDisable, ...props }) => {
  return (
    <Stack
      divider={
        !dividerDisable ? (
          <Divider
            orientation='horizontal'
            flexItem
            sx={{ borderStyle: 'dashed', borderWidth: '1.5px' }}
          />
        ) : (
          false
        )
      }
      {...props}
    >
      {children}
    </Stack>
  );
};

export default FmStack;
