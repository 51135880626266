import React, { useState, useRef, useEffect } from 'react';
import GlassCard from '../../../../components/small/GlassCard.tsx';
import { Box, Button } from '@mui/material';
import { useParams } from 'react-router-dom';
import TableActionButtons from '../../../../components/_form/TableActionButtons.jsx';
import FmSearchableSelect from '../../../../components/_mui/FmSearchableSelect.tsx';
import FmTextField from '../../../../components/_mui/FmTextField.tsx';
import { Icon } from '@iconify/react';
import { useForm } from 'react-hook-form';
import useTableLogic from '../../../../components/helpers/MRTUseTableLogic.tsx';
import Crud_Service from '../../../../apis/CrudService.jsx';
import { toast } from 'react-toastify';
import FmMRTDataTable from '../../../../components/FmMRTDataTable.tsx';
import { useHotkeys } from 'react-hotkeys-hook';
import { yupResolver } from '@hookform/resolvers/yup';
import { journalItemsSchema } from '../../../../utils/CommonvalidationSchemas.tsx';
import { ViewField } from '../../../../components/_form/FormElements.jsx';
import useCommonFetchApi from '../../../../components/helpers/useCommonFetchApi.tsx';
import CurrencyView from '../../../../components/_mui/FmCurrencyView.tsx';
import FmCurrencyTextField from '../../../../components/_mui/FmCurrencyTextField.tsx';
import BillingFooter from '../../../../components/view/BillingFooter.tsx';
import {
  CoaTxnType,
  creditNoteStatusEnum,
} from '../../../../utils/CommonVariables.tsx';

const EditListCreditNote = (props) => {
  const { id } = useParams();
  const { creditNoteStatusId } = props;
  const {
    rows,
    setRows,
    isLoading,
    pagination,
    sorting,
    columnFilters,
    globalFilter,
    tableRecordCounts,
    setSearchKeyword,
    searchKeyword,
    setPagination,
    setSorting,
    setColumnFilters,
    setGlobalFilter,
    fetchData,
  } = useTableLogic('creditnoteitems', ['creditNoteId'], [id]);
  const crud = new Crud_Service();
  const {
    handleSubmit,
    reset,
    setValue,
    control,
    getValues,
    watch,
    formState: { errors, isValid },
  } = useForm({
    resolver: yupResolver(journalItemsSchema),
    mode: 'all',
    defaultValues: {
      amount: '',
      vat: '',
      vatTotal: '',
      totalAmount: '',
    },
  });
  const [editingRowId, setEditingRowId] = useState(null);
  const [editingRowData, setEditingRowData] = useState(null);
  const firstColumnInputRef = useRef(null);
  const [stateError, setStateError] = useState(false);
  const [editingRowIndex, setEditingRowIndex] = useState(null);
  const [currentRowIndex, setCurrentRowIndex] = useState(0);
  const [newRowCreated, setNewRowCreated] = useState(false);
  const [loader, setLoader] = useState(false);
  const selectedSalesId = watch('salesOrderId');
  const selectedEmployeeId = watch('employeeId');
  const selectedCCDetail = watch('costCenterId');
  const { data: salesorders } = useCommonFetchApi('salesorders');
  const { data: employees } = useCommonFetchApi('employees');
  const { data: costCenters } = useCommonFetchApi('costCenters');
  const [formValues, setFormValues] = useState({});
  const [billingValues, setBillingValues] = useState([
    { name: 'LineItem', value: 0 },
    { name: 'Amount', value: 0 },
    { name: 'VAT', value: 0 },
    { name: 'vatTotal', value: 0 },
    { name: 'Total', value: 0 },
  ]);
  const specialLabels = ['VAT']; //for reference
  const currencyLabels = ['Amount', 'vatTotal', 'Total']; //for reference
  const [rowState, setRowState] = useState({
    newRowId: null,
    isCreatingRow: false,
  });
  const handleAddNewRow = () => {
    const newId = 'new_row_' + Date.now();
    reset();
    setEditingRowData(null);
    setEditingRowId(null);
    setRowState({
      newRowId: newId,
      isCreatingRow: true,
    });

    setEditingRowIndex(
      editingRowIndex === rows.length ? editingRowIndex + 1 : rows.length
    );
    setNewRowCreated(false);
    setRows((prevRows) => [
      ...prevRows,
      {
        creditNoteItemId: newId,
        salesOrderId: '',
        contractId: '',
        projectId: '',
        employeeId: '',
        costCenterId: '',
        accountId: '',
        taxCodeId: '',
        branchId: '',
        chequeNo: '',
        amount: 0,
        vat: 0,
        totalAmount: 0,
        reference: '',
        remarks: '',
        isEditable: true,
        isNew: true,
      },
    ]);
    reset();
  };

  const handleCancel = (row) => {
    setFormValues(null);
    if (row?.original?.creditNoteItemId === rowState?.newRowId) {
      setRows((prevRows) => {
        if (prevRows?.length > 0) {
          return prevRows?.slice(0, -1);
        }
        return prevRows;
      });
      setRowState((prevState) => ({
        ...prevState,
        newRowId: null,
        isCreatingRow: false,
      }));
    } else {
      setEditingRowId(null);
      setEditingRowIndex(null);
    }
    reset();
  };

  const handleEdit = (row) => {
    if (creditNoteStatusId === creditNoteStatusEnum?.Approved) {
      return;
    }
    reset();
    if (rowState?.newRowId) {
      setRows((prevRows) => {
        if (prevRows?.length > 0) {
          return prevRows?.slice(0, -1);
        }
        return prevRows;
      });
      setRowState((prevState) => ({
        ...prevState,
        newRowId: null,
        isCreatingRow: false,
      }));
    }
    setEditingRowId(row?.creditNoteItemId);
    Object.entries({
      ...row,
    }).forEach(([key, value]) => {
      setValue(key, value);
    });
    setEditingRowData({ ...row });
  };

  useHotkeys(
    'Enter',
    () => handleAddNewRow(),
    {
      enableOnTags: ['INPUT', 'TEXTAREA'],
    },
    [editingRowIndex, rows, stateError, loader]
  );

  useHotkeys(
    'Tab',
    (event) => {
      if (event.key !== 'Tab') return;
      if (stateError) {
        event.preventDefault();
        return;
      }
      const totalRows = rows.length;
      const nextRowIndex = editingRowIndex + 1;
      if (
        nextRowIndex < totalRows &&
        stateError === false &&
        loader === false
      ) {
        setCurrentRowIndex(nextRowIndex);
        handleEdit(rows[nextRowIndex]);
        setEditingRowIndex(nextRowIndex);
      }
    },
    [editingRowIndex, rows, stateError, loader, isValid]
  );

  const previousValues = useRef(getValues());
  const handleBlur = () => {
    const currentValues = getValues();
    const hasChanges = Object.keys(currentValues).some(
      (key) => currentValues[key] !== previousValues.current[key]
    );
    if ((hasChanges && isValid) || (hasChanges && editingRowId)) {
      apiCallAsync(currentValues);
      previousValues.current = currentValues;
    }
  };
  const apiCallAsync = async (values) => {
    setLoader(true);
    const convertedValues = {
      ...values,
      creditNoteId: id,
    };

    const updateCombinedData = {
      ...values,
      creditNoteId: id,
      status: 2,
    };
    if (editingRowId === null) {
      await crud.create('creditnoteitems', convertedValues, (err, res) => {
        if (res?.status === 201) {
          setEditingRowId(res?.data?.creditNoteItemId);
          setValue('creditNoteItemId', res?.data?.creditNoteItemId);
        } else {
        }
      });
    } else {
      await crud.update(
        'creditnoteitems',
        editingRowId,
        updateCombinedData,
        (err, res) => {
          if (res?.status === 200) {
            if (rows.some((row) => row.isNew)) {
              const updatedRow = res?.data;
              const updatedRows = [...rows];
              updatedRows[editingRowIndex] = {
                ...updatedRow,
                isEditable: true,
                isNew: true,
              };
              setRows(updatedRows);
              setStateError(false);
              setLoader(false);
            } else {
              setStateError(false);
              setLoader(false);
              const updatedRow = res?.data;
              const updatedRows = [...rows];

              updatedRows[editingRowIndex] = updatedRow;

              setRows(updatedRows);
            }
          } else {
            setStateError(true);
          }
        }
      );
    }
  };

  const handleBlurAndFetch = async () => {
    setLoader(true);
    const updatedRow = getValues();
    const updatedRows = [...rows];
    updatedRows[editingRowIndex] = updatedRow;
    setRows(updatedRows);
    const values = getValues();
    const { creditNoteItemId, ...restValues } = values;
    const updateCombinedData = {
      ...restValues,
      creditNoteId: id,
      status: 2,
    };
    await crud.update(
      'creditnoteitems',
      editingRowId,
      updateCombinedData,
      (err, res) => {
        if (res?.status === 200) {
          const totalRows = rows.length;
          if (editingRowIndex <= totalRows && rows.some((row) => row.isNew)) {
            setNewRowCreated(true);
            const updatedRow = res?.data;
            const updatedRows = [...rows];
            updatedRows[editingRowIndex] = {
              ...updatedRow,
              isEditable: true,
              isNew: true,
            };
            setRows(updatedRows);
            handleAddNewRow();
          } else {
            const updatedRow = res?.data;
            const updatedRows = [...rows];
            updatedRows[editingRowIndex] = {
              ...updatedRow,
              isEditable: false,
              isNew: false,
            };

            setRows(updatedRows);
            setStateError(false);
            setLoader(false);
          }
        } else {
          setStateError(true);
        }
      }
    );
  };

  useEffect(() => {
    if (editingRowIndex !== null && firstColumnInputRef.current && !isLoading) {
      firstColumnInputRef.current.focus();
    }
  }, [editingRowIndex, isLoading]);

  const handleCancelAndAddNewRow = () => {
    const newId = 'new_row_' + Date.now();
    reset();
    setEditingRowData(null);
    setEditingRowId(null);
    setRowState({
      newRowId: newId,
      isCreatingRow: true,
    });
    setEditingRowIndex(
      editingRowIndex === rows.length ? editingRowIndex + 1 : rows.length
    );
    setNewRowCreated(false);
    setRows((prevRows) => [
      ...prevRows,
      {
        creditNoteItemId: newId,
        salesOrderId: '',
        contractId: '',
        projectId: '',
        employeeId: '',
        costCenterId: '',
        accountId: '',
        taxCodeId: '',
        branchId: '',
        chequeNo: '',
        amount: 0,
        vat: 0,
        totalAmount: 0,
        reference: '',
        remarks: '',
        isEditable: true,
        isNew: true,
      },
    ]);
    reset();
  };

  const handleDelete = async (props) => {
    await crud.remove(
      'creditnoteitems',
      props?.creditNoteItemId,
      (_err, res) => {
        if (res?.status === 204) {
          toast.success('Journal Item Deleted Successfully');
          fetchData();
        } else {
        }
      }
    );
  };
  const useSalesOrderEffect = (
    salesorders: SalesOrder[],
    selectedSalesId: string,
    setValue: any
  ) => {
    useEffect(() => {
      if (!salesorders || !selectedSalesId) return;

      const selectedSODetail = salesorders.find(
        (sales) => sales.salesOrderId === selectedSalesId
      );

      if (selectedSODetail) {
        setValue('salesOrderId', selectedSODetail.salesOrderId);
        setValue('salesOrderCode', selectedSODetail.autoSalesOrderCode);
        setValue('salesOrderName', selectedSODetail.salesOrderName);
        setValue('contractId', selectedSODetail.contractId ?? '');
        setValue('contractName', selectedSODetail.contractName ?? '');
        setValue('contractCode', selectedSODetail.contractCode ?? '');
        setValue('projectId', selectedSODetail.projectId ?? '');
        setValue('projectName', selectedSODetail.projectName ?? '');
        setValue('projectCode', selectedSODetail.projectCode ?? '');
      }
    }, [selectedSalesId, salesorders, setValue]);
  };
  const useEmployeeEffect = (
    employees: Employee[],
    selectedEmployeeId: string,
    setValue: any
  ) => {
    useEffect(() => {
      if (!employees || !selectedEmployeeId) return;

      const selectedEmployeeDetail = employees.find(
        (employee) => employee?.employeeId === selectedEmployeeId
      );

      if (selectedEmployeeDetail) {
        setValue('employeeName', selectedEmployeeDetail.fullName ?? '');
        setValue('employeeCode', selectedEmployeeDetail.employeeCode ?? '');
      }
    }, [selectedEmployeeId, employees, setValue]);
  };

  const useCostCenterEffect = (
    costCenters: CostCenter[],
    selectedCCDetail: string,
    setValue: any
  ) => {
    useEffect(() => {
      if (!costCenters || !selectedCCDetail) return;

      const selectedCostCenterDetail = costCenters.find(
        (costCenter) => costCenter?.costCenterId === selectedCCDetail
      );

      if (selectedCostCenterDetail) {
        setValue(
          'costCenterName',
          selectedCostCenterDetail.costCenterName ?? ''
        );
        setValue(
          'costCenterCode',
          selectedCostCenterDetail.costCenterCode ?? ''
        );
      }
    }, [selectedCCDetail, costCenters, setValue]);
  };
  useSalesOrderEffect(salesorders, selectedSalesId, setValue);
  useEmployeeEffect(employees, selectedEmployeeId, setValue);
  useCostCenterEffect(costCenters, selectedCCDetail, setValue);

  useEffect(() => {
    if (!rows?.length) return; // Exit early if no rows exist

    // Extract values
    let amount = parseFloat(formValues?.amount) || 0;
    let vatPercentage = parseFloat(formValues?.vat) || 0;
    let vatTotal = (amount * vatPercentage) / 100;
    let totalAmount = amount + vatTotal;

    setValue('totalAmount', totalAmount.toFixed(2));
    setValue('vatTotal', vatTotal.toFixed(2));

    // Filter rows based on editing state
    const filteredRows = editingRowId
      ? rows.filter((row) => row.creditNoteItemId !== editingRowId)
      : rows;

    // Helper functions
    const sumField = (field) =>
      filteredRows.reduce((sum, row) => sum + (parseFloat(row[field]) || 0), 0);

    const averageField = (field) =>
      filteredRows.length
        ? (sumField(field) / filteredRows.length).toFixed(2)
        : '0.00';

    // Compute aggregated values
    const vatAvg = averageField('vat');
    const totalSumAmount = sumField('amount');
    const totalSumvatTotal = sumField('vatTotal');
    const totalSum = sumField('totalAmount');

    // Update billing values
    setBillingValues((prev) =>
      prev.map((item) => {
        const fieldValues = {
          LineItem: rows.length,
          VAT: parseFloat(vatAvg).toFixed(2),
          Amount: totalSumAmount.toFixed(2),
          vatTotal: totalSumvatTotal.toFixed(2),
          Total: (totalSum + totalAmount).toFixed(2),
        };

        return fieldValues[item.name] !== undefined
          ? { ...item, value: fieldValues[item.name] }
          : item;
      })
    );
  }, [rows, formValues?.vat, formValues?.amount]);

  const ActionData = [
    {
      name: 'Delete',
      icon: <Icon icon='mi:delete' />,
      onClick: (props) => handleDelete(props),
      danger: true,
    },
  ];
  const columns = [
    {
      accessorKey: 'salesOrderId',
      header: 'Sales Order',
      enableEditing: true,
      Cell: ({ row }) => {
        const isNewRow = row?.original?.creditNoteItemId === rowState?.newRowId;
        const isEditing =
          editingRowId === row?.original?.creditNoteItemId || isNewRow;
        return isEditing ? (
          <FmSearchableSelect
            name='salesOrderId'
            control={control}
            apiUrl='salesorders/order-data'
            valueField='salesOrderId'
            headerField={['Sales Order', 'Project', 'Contract']}
            labelField={['salesOrderCode', 'projectName', 'contractName']}
            stackedFields={{
              salesOrderCode: [
                { Code: 'salesOrderCode' },
                { Name: 'salesOrderName' },
              ],
              projectName: [{ Name: 'projectName' }, { Code: 'projectCode' }],
              contractName: [
                { Name: 'contractName' },
                { Code: 'contractCode' },
              ],
            }}
            showField={['salesOrderCode', 'salesOrderName']}
            label='Sales Order'
            rules={{ required: 'Sales Order is required' }}
            defaultValue={{
              salesOrderId: row?.original?.salesOrderId,
              salesOrderName: row?.original?.salesOrderName,
            }}
            onBlur={handleBlur}
            inputRef={firstColumnInputRef}
            required
          />
        ) : (
          <div className='flex flex-col w-full'>
            <div className='flex items-center gap-1'>
              <span>Code:</span>
              {row?.original?.salesOrderCode}
            </div>
            <div className='font-semibold'>{row?.original?.salesOrderName}</div>
          </div>
        );
      },
    },
    {
      accessorKey: 'contractName',
      header: 'Contract',
      enableEditing: true,
      Cell: ({ row }) => {
        const isNewRow = row?.original?.creditNoteItemId === rowState?.newRowId;
        const isEditing =
          editingRowId === row?.original?.creditNoteItemId || isNewRow;
        return isEditing ? (
          <ViewField label='' title={watch('contractName')} />
        ) : (
          <div className='flex flex-col w-full'>
            <div className='flex items-center gap-1'>
              <span>Code:</span>
              {row?.original?.contractCode}
            </div>
            <div className='font-semibold'>{row?.original?.contractName}</div>
          </div>
        );
      },
    },
    {
      accessorKey: 'projectName',
      header: 'Projects',
      enableEditing: true,
      Cell: ({ row }) => {
        const isNewRow = row?.original?.creditNoteItemId === rowState?.newRowId;
        const isEditing =
          editingRowId === row?.original?.creditNoteItemId || isNewRow;
        return isEditing ? (
          <ViewField label='' title={watch('projectName')} />
        ) : (
          <div className='flex flex-col w-full'>
            <div className='flex items-center gap-1'>
              <span>Code:</span>
              {row?.original?.projectCode}
            </div>
            <div className='font-semibold'>{row?.original?.projectName}</div>
          </div>
        );
      },
    },
    {
      accessorKey: 'employeeId',
      header: 'Employee',
      enableEditing: true,
      Cell: ({ row }) => {
        const isNewRow = row?.original?.creditNoteItemId === rowState?.newRowId;
        const isEditing =
          editingRowId === row?.original?.creditNoteItemId || isNewRow;

        return isEditing ? (
          <FmSearchableSelect
            name='employeeId'
            control={control}
            apiUrl='employees'
            valueField='employeeId'
            labelField={['employeeCode', 'fullName', 'emailId']}
            showField={['employeeCode', 'fullName', 'emailId']}
            label='Employees'
            headerField={['Code', 'Name', 'Email']}
            onBlur={handleBlur}
            defaultValue={{
              employeeId: row?.original?.employeeId,
              fullName: row?.original?.employeeName,
            }}
          />
        ) : (
          <div className='flex flex-col w-full'>
            <div className='flex items-center gap-1'>
              <span>Code:</span>
              {row?.original?.employeeCode}
            </div>
            <div className='font-semibold'>{row?.original?.employeeName}</div>
          </div>
        );
      },
    },
    {
      accessorKey: 'costCenterId',
      header: 'Cost Center',
      enableEditing: true,
      Cell: ({ row }) => {
        const isNewRow = row?.original?.creditNoteItemId === rowState?.newRowId;
        const isEditing =
          editingRowId === row?.original?.creditNoteItemId || isNewRow;

        return isEditing ? (
          <FmSearchableSelect
            name='costCenterId'
            control={control}
            apiUrl='costcenters'
            valueField='costCenterId'
            labelField={['costCenterCode', 'costCenterName']}
            showField={['costCenterCode', 'costCenterName']}
            label='Cost Centers'
            onBlur={handleBlur}
            defaultValue={{
              costCenterId: row?.original?.costCenterId,
              costCenterName: row?.original?.costCenterName,
            }}
          />
        ) : (
          <div className='flex flex-col w-full'>
            <div className='flex items-center gap-1'>
              <span>Code:</span>
              {row?.original?.costCenterCode}
            </div>
            <div className='font-semibold'>{row?.original?.costCenterName}</div>
          </div>
        );
      },
    },
    {
      accessorKey: 'accountId',
      header: 'Accounts',
      enableEditing: true,
      Cell: ({ row }) => {
        const isNewRow = row?.original?.creditNoteItemId === rowState?.newRowId;
        const isEditing =
          editingRowId === row?.original?.creditNoteItemId || isNewRow;

        return isEditing ? (
          <FmSearchableSelect
            name='accountId'
            control={control}
            apiUrl='chartofaccounts'
            valueField='chartOfAccountId'
            labelField={['chartOfAccountCode', 'chartOfAccountName']}
            showField={['chartOfAccountCode', 'chartOfAccountName']}
            queryparam='coaTransactionTypeIds'
            queryparamValue={[
              CoaTxnType.REVENUE_AND_CLIENTS_REVENUE,
              CoaTxnType.SUPPLIERS_AND_VAT,
            ]}
            label='Accounts'
            onBlur={handleBlur}
            defaultValue={{
              chartOfAccountId: row?.original?.accountId,
              chartOfAccountName: row?.original?.accountName,
            }}
          />
        ) : (
          row?.original?.accountName || ''
        );
      },
    },
    {
      accessorKey: 'taxCodeId',
      header: 'Tax Code',
      enableEditing: true,
      Cell: ({ row }) => {
        const isNewRow = row?.original?.creditNoteItemId === rowState?.newRowId;
        const isEditing =
          editingRowId === row?.original?.creditNoteItemId || isNewRow;

        return isEditing ? (
          <FmSearchableSelect
            name='taxCodeId'
            control={control}
            apiUrl='taxcodes'
            valueField='taxCodeId'
            labelField={['taxCodeCode', 'taxCodeName']}
            showField={['taxCodeCode', 'taxCodeName']}
            label='Tac Code'
            onBlur={handleBlur}
            defaultValue={{
              taxCodeId: row?.original?.taxCodeId,
              taxCodeName: row?.original?.taxCodeName,
            }}
          />
        ) : (
          row?.original?.taxCodeName || ''
        );
      },
    },
    {
      accessorKey: 'branchId',
      header: 'Branch Name',
      enableEditing: true,
      Cell: ({ row }) => {
        const isNewRow = row?.original?.creditNoteItemId === rowState?.newRowId;
        const isEditing =
          editingRowId === row?.original?.creditNoteItemId || isNewRow;

        return isEditing ? (
          <FmSearchableSelect
            name='branchId'
            control={control}
            apiUrl='branches'
            valueField='branchId'
            labelField={['branchCode', 'branchName']}
            showField={['branchCode', 'branchName']}
            label='Branch Name'
            onBlur={handleBlur}
            defaultValue={{
              branchId: row?.original?.branchId,
              branchName: row?.original?.branchName,
            }}
          />
        ) : (
          row?.original?.branchName || ''
        );
      },
    },
    {
      accessorKey: 'chequeNo',
      header: 'Cheque No',
      enableEditing: true,
      Cell: ({ row }) => {
        const isNewRow = row?.original?.creditNoteItemId === rowState?.newRowId;
        const isEditing =
          editingRowId === row?.original?.creditNoteItemId || isNewRow;
        return isEditing ? (
          <FmTextField
            name='chequeNo'
            label='Cheque No'
            control={control}
            onBlur={handleBlur}
            defaultValue={row?.original?.chequeNo}
          />
        ) : (
          row?.original?.chequeNo || ''
        );
      },
    },
    {
      accessorKey: 'amount',
      header: 'Amount',
      enableEditing: true,
      Cell: ({ row }) => {
        const isNewRow = row?.original?.creditNoteItemId === rowState?.newRowId;
        const isEditing =
          editingRowId === row?.original?.creditNoteItemId || isNewRow;

        return isEditing ? (
          <FmCurrencyTextField
            name='amount'
            label='Amount'
            control={control}
            onBlur={handleBlur}
            defaultValue={row?.original?.amount}
            onChange={(e) =>
              setFormValues((prevValues) => ({
                ...prevValues,
                amount: e.target.value,
              }))
            }
          />
        ) : (
          <CurrencyView value={row?.original?.amount} currencyType={'AED'} />
        );
      },
    },
    {
      accessorKey: 'vat',
      header: 'Vat(%)',
      enableEditing: true,
      size: 100,
      Cell: ({ row }) => {
        const isNewRow = row?.original?.creditNoteItemId === rowState?.newRowId;
        const isEditing =
          editingRowId === row?.original?.creditNoteItemId || isNewRow;

        return isEditing ? (
          <FmTextField
            name='vat'
            label='Vat'
            control={control}
            onBlur={handleBlur}
            defaultValue={row?.original?.vat}
            onChange={(e) =>
              setFormValues((prevValues) => ({
                ...prevValues,
                vat: e.target.value,
              }))
            }
          />
        ) : (
          row?.original?.vat || ''
        );
      },
    },
    {
      accessorKey: 'vatTotal',
      header: 'VAT Total',
      enableEditing: false,
      Cell: ({ row }) => {
        const isNewRow = row?.original?.creditNoteItemId === rowState?.newRowId;
        const isEditing =
          editingRowId === row?.original?.creditNoteItemId || isNewRow;
        const vatTotal = watch('vatTotal');
        return isEditing ? (
          <ViewField label={`VAT Amount`} title={vatTotal} />
        ) : (
          <CurrencyView value={row?.original?.vatTotal} currencyType={'AED'} />
        );
      },
    },
    {
      accessorKey: 'totalAmount',
      header: 'Total',
      enableEditing: false,
      Cell: ({ row }) => {
        const isNewRow = row?.original?.creditNoteItemId === rowState?.newRowId;
        const isEditing =
          editingRowId === row?.original?.creditNoteItemId || isNewRow;
        const totalAmount = watch('totalAmount');
        return isEditing ? (
          <ViewField label={`Total Amount`} title={totalAmount} />
        ) : (
          <CurrencyView
            value={row?.original?.totalAmount}
            currencyType={'AED'}
          />
        );
      },
    },
    {
      accessorKey: 'reference',
      header: 'Reference',
      enableEditing: true,
      Cell: ({ row }) => {
        const isNewRow = row?.original?.creditNoteItemId === rowState?.newRowId;
        const isEditing =
          editingRowId === row?.original?.creditNoteItemId || isNewRow;

        return isEditing ? (
          <FmTextField
            name='reference'
            label='Reference'
            control={control}
            onBlur={handleBlur}
            defaultValue={row?.original?.reference}
          />
        ) : (
          row?.original?.reference || ''
        );
      },
    },
    {
      accessorKey: 'remarks',
      header: 'Remarks',
      enableEditing: true,
      Cell: ({ row }) => {
        const isNewRow = row?.original?.creditNoteItemId === rowState?.newRowId;
        const isEditing =
          editingRowId === row?.original?.creditNoteItemId || isNewRow;

        return isEditing ? (
          <FmTextField
            name='remarks'
            label='Remarks'
            control={control}
            onBlur={handleBlurAndFetch}
            defaultValue={row?.original?.remarks}
          />
        ) : (
          row?.original?.remarks || ''
        );
      },
    },
    {
      header: 'Action',
      enableColumnPinning: false,
      enableEditing: false,
      enableSorting: false,
      Cell: ({ row, table }) => {
        const isNewRow = row?.original?.creditNoteItemId === rowState?.newRowId;
        const isEditing =
          editingRowId === row?.original?.creditNoteItemId || isNewRow;
        const isLastRow = row.index === table.getRowModel().rows.length - 1;
        return (
          <Box className='flex gap-2'>
            {isEditing ? (
              <>
                <Button onClick={() => handleCancel(row)}>Close</Button>
                {isLastRow && rowState?.isCreatingRow === false && (
                  <Button onFocus={() => handleCancelAndAddNewRow()}></Button>
                )}
              </>
            ) : (
              <Box className='flex gap-2'>
                {creditNoteStatusId !== creditNoteStatusEnum?.Approved && (
                  <TableActionButtons
                    Actions={ActionData.map((action) => ({
                      ...action,
                      onClick: () => action.onClick(row?.original),
                    }))}
                  />
                )}
                {isLastRow &&
                  creditNoteStatusId !== creditNoteStatusEnum?.Approved && (
                    <Button
                      variant='contained'
                      color='primary'
                      className='px-3 py-1'
                      onClick={() => {
                        setEditingRowIndex(row?.index + 1);
                        handleAddNewRow(row);
                      }}
                    >
                      Add
                    </Button>
                  )}
              </Box>
            )}
          </Box>
        );
      },
    },
  ];

  return (
    <>
      <GlassCard className='h-[450px]'>
        <FmMRTDataTable
          columns={columns}
          rows={rows}
          onCreateRow={
            creditNoteStatusId !== creditNoteStatusEnum?.Approved
              ? undefined
              : handleAddNewRow
          }
          isCreatingRow={rowState?.isCreatingRow}
          rowCount={rows.length}
          editingRow={editingRowId}
          pagination={pagination}
          setSearchKeyword={setSearchKeyword}
          sorting={sorting}
          fetchData={fetchData}
          columnFilters={columnFilters}
          globalFilter={globalFilter}
          setPagination={setPagination}
          setSorting={setSorting}
          setGlobalFilter={setGlobalFilter}
          isLoading={isLoading}
          setSearchKeyword={setSearchKeyword}
          searchKeyword={searchKeyword}
          enableStickyHeader
          handleRowDoubleClick={(row) => {
            handleEdit(row?.original);
            setEditingRowIndex(row?.index);
          }}
          disableNewButton={
            creditNoteStatusId === creditNoteStatusEnum?.Approved ? false : true
          }
          muiTableContainerProps={{ sx: { maxHeight: '100%', flex: 1 } }}
          showSerialNumber={true}
        />
      </GlassCard>
      <BillingFooter
        billingValues={billingValues}
        specialLabels={specialLabels}
        currencyLabels={currencyLabels}
      />
    </>
  );
};

export default EditListCreditNote;
