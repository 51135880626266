import { yupResolver } from '@hookform/resolvers/yup';
import {
  Box,
  Button,
  Divider,
  FormControlLabel,
  Radio,
  RadioGroup,
  Stack,
} from '@mui/material';
import React, { useEffect, useMemo, useRef, useState } from 'react';
import { useForm } from 'react-hook-form';
import { useNavigate, useParams } from 'react-router-dom';
import { toast } from 'react-toastify';
import apiInstance from '../../../../../apis/ApiService.jsx';
import Crud_Service from '../../../../../apis/CrudService.jsx';
import TableActionButtons from '../../../../../components/_form/TableActionButtons.jsx';
import FmFileInput from '../../../../../components/_mui/FmFileInput.tsx';
import FmSearchableSelect from '../../../../../components/_mui/FmSearchableSelect.tsx';
import FmTextField from '../../../../../components/_mui/FmTextField.tsx';
import MuiDialogOne from '../../../../../components/_mui/MuiDialogOne.jsx';
import DashboardLayout from '../../../../../components/DashboardLayout.tsx';
import FmMRTDataTable from '../../../../../components/FmMRTDataTable.tsx';
import useTableLogic from '../../../../../components/helpers/MRTUseTableLogic.tsx';
import useLoader from '../../../../../components/helpers/UseLoader.tsx';
import { CreateButton } from '../../../../../components/small/Buttons.jsx';
import GlassCard from '../../../../../components/small/GlassCard.tsx';
import { PurchaseVoucherItemSchema } from '../../../../../utils/CommonvalidationSchemas.tsx';
import Icons from '../../../../../utils/Icon.jsx';
import Nav from '../../../../../utils/Nav.tsx';
import AddPVItems from './AddPVItems.tsx';
import EditPV from './EditPV.tsx';
import { useSelector } from 'react-redux';
import { findNavByName } from '../../../../../utils/navUtils.ts';
import { useHotkeys } from 'react-hotkeys-hook';

const EditPurchaseVoucherList = (props) => {
  const { type } = props;
  const {
    control,
    setValue,
    handleSubmit,
    reset,
    getValues,
    watch,
    formState: { isValid },
  } = useForm({
    resolver: yupResolver(PurchaseVoucherItemSchema),
    mode: 'onChange',
  });
  const id = useParams()?.id;

  const {
    rows,
    setRows,
    pagination,
    sorting,
    columnFilters,
    globalFilter,
    tableRecordCounts,
    isLoading,
    setPagination,
    setSorting,
    setColumnFilters,
    setGlobalFilter,
    setSearchKeyword,
    fetchData,
    searchKeyword,
  } = useTableLogic(
    `purchasevoucherItem?sortDirection=asc&purchaseVoucherId=${id}`
  );
  const { startCreateLoading, stopCreateLoading, createLoading } = useLoader();
  const [openUpload, setOpenUpload] = useState(false);
  const [filesData, setFilesData] = useState(null);
  const firstColumnInputRef = useRef(null);
  const [stateError, setStateError] = useState(false);
  const [editingRowIndex, setEditingRowIndex] = useState(null);
  const [currentRowIndex, setCurrentRowIndex] = useState(0);
  const [newRowCreated, setNewRowCreated] = useState(false);
  const [editingRowId, setEditingRowId] = useState(null);
  const [editingRowData, setEditingRowData] = useState(null);
  const quantityInputRef = useRef(null);
  const [loader, setLoader] = useState(false);
  const [rowState, setRowState] = useState({
    newRowId: null,
    isCreatingRow: false,
  });
  const [formValues, setFormValues] = useState({});
  const [billingValues, setBillingValues] = useState([
    { name: 'Quantity', value: 1 },
    { name: 'Gross', value: '-' },
    { name: 'Discount', value: 0 },
    { name: 'Discount %', value: 0 },
    { name: 'VAT', value: 5 },
    // { name: 'Taxable', value: 1000 },
    // { name: 'VAT Advance', value: 0 },
    // { name: 'Net', value: 1050 },
  ]);

  // useEffect(() => {
  //   if(rows?.length > 0){
  //     const filteredRows = editingRowId
  //   ? rows.filter((row) => row.purchaseVoucherItemId !== editingRowId) // Exclude the editing row
  //   : rows; // If no editingRowId, use all rows
  //     const gross = filteredRows.reduce((total, row) => total + parseFloat(row.gross || 0), 0);
  //     const discount = filteredRows.reduce((total, row) => total + parseFloat(row.discount || 0), 0);
  //     const discountPercent = filteredRows.reduce((total, row) => total + parseFloat(row.discountPercentage || 0), 0);
  //     const net = filteredRows.reduce((total, row) => total + parseFloat(row.net || 0), 0);

  //     // Update billing values
  //     setBillingValues((prev) =>
  //       prev.map((item) => {
  //         switch (item.name) {
  //           case 'Quantity':
  //             return { ...item, value: rows.length}; // Add form value if available
  //           case 'Gross':
  //             return { ...item, value: gross + parseFloat(formValues?.gross || 0) }; // Add form value if available
  //           case 'Discount':
  //             return { ...item, value: discount + parseFloat(formValues?.discount || 0) }; // Add form value if available
  //           case 'Discount %':
  //             return { ...item, value: discountPercent + parseFloat(formValues?.discountPercentage || 0) }; // Add form value if available
  //           case 'Net':
  //             return { ...item, value: net + parseFloat(formValues?.net || 0) }; // Add form value if available
  //           default:
  //             return item;
  //         }
  //       })
  //     );
  //   }

  // }, [
  //   formValues?.discount,
  //   formValues?.quantity,
  //   formValues?.gross,
  //   formValues?.discountPercentage,
  //   formValues?.net,
  //   editingRowId,
  //   rows,
  //   editingRowData?.purchaseVoucherItemId
  // ]);
  useEffect(() => {
    if (rows?.length > 0) {
      const filteredRows = editingRowId
        ? rows.filter((row) => row.purchaseVoucherItemId !== editingRowId) // Exclude the editing row
        : rows; // If no editingRowId, use all rows

      const gross = filteredRows.reduce(
        (total, row) => total + parseFloat(row.gross || 0),
        0
      );
      const discount = filteredRows.reduce(
        (total, row) => total + parseFloat(row.discount || 0),
        0
      );
      const discountPercent = filteredRows.reduce(
        (total, row) => total + parseFloat(row.discountPercentage || 0),
        0
      );
      const net = filteredRows.reduce(
        (total, row) => total + parseFloat(row.net || 0),
        0
      );
      const vat = filteredRows.reduce(
        (total, row) => total + parseFloat(row.vat || 0),
        0
      );
      // Calculate average discount percentage from both rows and formValues
      const totalDiscountPercent =
        discountPercent +
        parseFloat(
          formValues?.discountPercentage ||
          editingRowData?.discountPercentage ||
          0
        );
      const averageDiscountPercent = totalDiscountPercent / (rows.length + 1);
      // Update billing values
      setBillingValues((prev) =>
        prev.map((item) => {
          switch (item.name) {
            case 'Quantity':
              return { ...item, value: rows.length }; // Add form value if available
            case 'Gross':
              return {
                ...item,
                value:
                  gross +
                  parseFloat(
                    formValues?.gross == 0
                      ? 0
                      : formValues?.gross || editingRowData?.gross || 0
                  ),
              }; // Add form value if available
            case 'Discount':
              return {
                ...item,
                value:
                  discount +
                  parseFloat(
                    formValues?.discount == 0
                      ? 0
                      : formValues?.discount || editingRowData?.discount || 0
                  ),
              }; // Add form value if available
            case 'Discount %':
              return { ...item, value: averageDiscountPercent?.toFixed(2) }; // Use average discount percentage
            case 'Net':
              return {
                ...item,
                value:
                  net +
                  parseFloat(
                    formValues?.net == 0
                      ? 0
                      : formValues?.net || editingRowData?.net || 0
                  ),
              }; // Add form value if available
            case 'VAT':
              return {
                ...item,
                value:
                  vat +
                  parseFloat(
                    formValues?.vat == 0
                      ? 0
                      : formValues?.vat || editingRowData?.vat || 0
                  ),
              }; // Add form value if available
            default:
              return item;
          }
        })
      );
    }
  }, [
    formValues?.discount,
    formValues?.quantity,
    formValues?.gross,
    formValues?.vat,
    formValues?.discountPercentage,
    formValues?.net,
    editingRowId,
    rows,
    editingRowData?.purchaseVoucherItemId,
  ]);

  const crud = new Crud_Service();

  const [, setViewdetails] = useState(false);
  const handleEdit = (row) => {
    reset();
    if (rowState?.newRowId) {
      setRows((prevRows) => {
        if (prevRows?.length > 0) {
          return prevRows?.slice(0, -1); // Removes the last row
        }
        return prevRows; // If no rows exist, return the current state
      });
      setRowState((prevState) => ({
        ...prevState,
        newRowId: null,
        isCreatingRow: false,
      }));
      // setEditingRowIndex(null);
    }
    setEditingRowId(row?.purchaseVoucherItemId);
    Object.entries({
      ...row,
    }).forEach(([key, value]) => {
      setValue(key, value);
    });
    setEditingRowData({ ...row });
  };

  const handleDeleteVoucher = async (view) => {
    await crud.remove(
      'purchasevoucheritem',
      view?.purchaseVoucherItemId,
      (_err, res) => {
        if (res?.status === 204) {
          fetchData();
          toast.success('Purchase Voucher Item Deleted Successfully');
        } else {
        }
      }
    );
  };

  const handleCancel = () => {
    setEditingRowId(null);
    setEditingRowData(null);
  };

  const ActionData = [
    {
      name: 'Delete',
      icon: Icons.delete,
      onClick: (props) => handleDeleteVoucher(props),
      danger: true,
    },
  ];
  const navigate = useNavigate();

  const handleUploadSheet = (event) => {
    startCreateLoading();
    const file = event.target.files[0];
    if (file) {
      const fileFormData = new FormData();
      fileFormData.append('file', file);
      fileFormData.append('fileTypeId', 60);

      crud.create('files/upload', fileFormData, (_err, res) => {
        if (res?.status === 200) {
          setFilesData(res?.data);
          crud.create(
            `purchasevoucheritem/${id}/process/${res.data?.fileId}`,
            '',
            (_err, res) => {
              if (res?.status === 200) {
                stopCreateLoading();
                toast.success('Purchase Voucher Items Uploaded Successfully');
                fetchData();
                setOpenUpload(false);
              } else {
                stopCreateLoading();
              }
            }
          );
        } else {
          setOpenUpload(false);
          stopCreateLoading();
        }
      });
    }
  };
  const handleExport = async () => {
    const downloadUrl = await apiInstance.getFiles(
      `purchasevoucheritem/${id}/export-excel`
    );
    const link = document.createElement('a');
    link.href = downloadUrl;
    link.setAttribute('download', 'Purchase Voucher Items.xlsx');
    document.body.appendChild(link);
    link.click();
    document.body.removeChild(link);
  };
  const handleClose = () => {
    setOpenUpload(false);
    setFilesData(null);
  };
  useEffect(() => {
    fetchData();
  }, []);

  const columns = [
    {
      header: 'Action',
      enableColumnPinning: false,
      enableEditing: false,
      enableSorting: false,
      Cell: ({ row, table }) => {
        const isNewRow =
          row?.original?.purchaseVoucherItemId === rowState?.newRowId;
        const isEditing =
          editingRowId === row?.original?.purchaseVoucherItemId || isNewRow;
        const isLastRow = row.index === table.getRowModel().rows.length - 1;

        return (
          <Box className='flex gap-2'>
            {isEditing ? (
              <>
                <Button onClick={() => handleCancel(row)}>Close</Button>
                {isLastRow && rowState?.isCreatingRow === false && (
                  <Button onFocus={() => handleCancelAndAddNewRow()}></Button>
                )}
              </>
            ) : (
              <Box className='flex gap-2'>
                <TableActionButtons
                  Actions={ActionData.map((action) => ({
                    ...action,
                    onClick: () => action.onClick(row?.original),
                  }))}
                />
                {isLastRow && (
                  <Button
                    variant='contained'
                    color='primary'
                    className='px-3 py-1'
                    onClick={() => {
                      setEditingRowIndex(row?.index + 1);
                      handleAddNewRow(row);
                    }}
                  >
                    Add
                  </Button>
                )}
              </Box>
            )}
          </Box>
        );
      },
    },
    {
      accessorKey: 'projectId',
      header: 'Project',
      enableEditing: true,
      Cell: ({ row }) => {
        const isNewRow =
          row?.original?.purchaseVoucherItemId === rowState?.newRowId;
        const isEditing =
          editingRowId === row?.original?.purchaseVoucherItemId || isNewRow;
        return isEditing ? (
          <FmSearchableSelect
            name='projectId'
            control={control}
            apiUrl='projects'
            valueField='projectId'
            label='Project'
            inputRef={firstColumnInputRef}
            onBlur={handleBlur}
            labelField={['projectName']}
            showField={['projectName']}
            required
            defaultValue={{
              projectId: row?.original?.projectId,
              projectName: row?.original?.projectName,
            }}
          />
        ) : (
          row?.original?.projectName || ''
        );
      },
    },

    {
      accessorKey: 'contractId',
      header: 'Contract',
      enableEditing: true,
      Cell: ({ row }) => {
        const isNewRow =
          row?.original?.purchaseVoucherItemId === rowState?.newRowId;
        const isEditing =
          editingRowId === row?.original?.purchaseVoucherItemId || isNewRow;
        return isEditing ? (
          <FmSearchableSelect
            name='contractId'
            control={control}
            apiUrl='contracts'
            label='Contract'
            onBlur={handleBlur}
            labelField={['contractName', 'contractCode']}
            showField={['contractName', 'contractCode']}
            valueField='contractId'
            defaultValue={{
              contractId: row?.original?.contractId,
              contractName: row?.original?.contractName,
            }}
          />
        ) : (
          row?.original?.contractName || ''
        );
      },
    },

    {
      accessorKey: 'workOrderId',
      header: 'Work Order',
      enableEditing: true,
      Cell: ({ row }) => {
        const isNewRow =
          row?.original?.purchaseVoucherItemId === rowState?.newRowId;
        const isEditing =
          editingRowId === row?.original?.purchaseVoucherItemId || isNewRow;
        return isEditing ? (
          <FmSearchableSelect
            name='workOrderId'
            control={control}
            apiUrl='workorders'
            label='Work Order'
            onBlur={handleBlur}
            labelField={['workOrderName', 'workOrderCode']}
            showField={['workOrderName', 'workOrderCode']}
            valueField='workOrderId'
            defaultValue={{
              workOrderId: row?.original?.workOrderId,
              workOrderName: row?.original?.workOrderName,
            }}
          />
        ) : (
          row?.original?.workOrderName || ''
        );
      },
    },
    {
      accessorKey: 'salesOrderName',
      header: 'Sales Order',
      enableEditing: true,
      Cell: ({ row }) => {
        const isNewRow =
          row?.original?.purchaseVoucherItemId === rowState?.newRowId;
        const isEditing =
          editingRowId === row?.original?.purchaseVoucherItemId || isNewRow;
        return isEditing ? (
          <FmSearchableSelect
            name='salesOrderId'
            control={control}
            apiUrl='salesorders'
            label='Sales Order'
            onBlur={handleBlur}
            labelField={['salesOrderName', 'salesOrderCode']}
            showField={['salesOrderName', 'salesOrderCode']}
            valueField='salesOrderId'
            defaultValue={{
              salesOrderId: row?.original?.salesOrderId,
              salesOrderName: row?.original?.salesOrderName,
            }}
          />
        ) : (
          row?.original?.salesOrderName || ''
        );
      },
    },
    {
      accessorKey: 'inChargeUserName',
      header: 'In Charge',
      enableEditing: true,
      Cell: ({ row }) => {
        const isNewRow =
          row?.original?.purchaseVoucherItemId === rowState?.newRowId;
        const isEditing =
          editingRowId === row?.original?.purchaseVoucherItemId || isNewRow;
        return isEditing ? (
          <FmSearchableSelect
            name='inCharge'
            control={control}
            apiUrl='loginusers'
            label='In Charge'
            onBlur={handleBlur}
            labelField={['employeeCode', 'employeeName', 'employeeMail']}
            showField={['employeeCode', 'employeeName', 'employeeMail']}
            valueField='loginUserId'
            defaultValue={{
              loginUserId: row?.original?.inCharge,
              employeeName: row?.original?.inChargeUserName,
              employeeMail: row?.original?.inChargeMail,
            }}
          />
        ) : (
          row?.original?.inChargeUserName || ''
        );
      },
    },
    {
      accessorKey: 'divisionId',
      header: 'Division',
      enableEditing: true,
      Cell: ({ row }) => {
        const isNewRow =
          row?.original?.purchaseVoucherItemId === rowState?.newRowId;
        const isEditing =
          editingRowId === row?.original?.purchaseVoucherItemId || isNewRow;
        return isEditing ? (
          <FmSearchableSelect
            name='divisionId'
            control={control}
            apiUrl='divisions'
            label='Division'
            onBlur={handleBlur}
            labelField={['divisionCode', 'divisionName']}
            showField={['divisionCode', 'divisionName']}
            valueField='divisionId'
            defaultValue={{
              divisionId: row?.original?.divisionId,
              divisionName: row?.original?.divisionName,
            }}
          />
        ) : (
          row?.original?.divisionName || ''
        );
      },
    },
    {
      accessorKey: 'locationName',
      header: 'Location',
      enableEditing: true,
      Cell: ({ row }) => {
        const isNewRow =
          row?.original?.purchaseVoucherItemId === rowState?.newRowId;
        const isEditing =
          editingRowId === row?.original?.purchaseVoucherItemId || isNewRow;
        return isEditing ? (
          <FmSearchableSelect
            name='locationId'
            control={control}
            apiUrl='locations'
            label='Location'
            onBlur={handleBlur}
            labelField={['locationCode', 'locationName']}
            showField={['locationCode', 'locationName']}
            valueField='locationId'
            defaultValue={{
              locationId: row?.original?.locationId,
              locationName: row?.original?.locationName,
            }}
          />
        ) : (
          row?.original?.locationName || ''
        );
      },
    },
    {
      accessorKey: 'costCenterId',
      header: 'Cost Center',
      enableEditing: true,
      Cell: ({ row }) => {
        const isNewRow =
          row?.original?.purchaseVoucherItemId === rowState?.newRowId;
        const isEditing =
          editingRowId === row?.original?.purchaseVoucherItemId || isNewRow;
        return isEditing ? (
          <FmSearchableSelect
            name='costCenterId'
            control={control}
            apiUrl='costcenters'
            label='Cost Center'
            onBlur={handleBlur}
            labelField={['costCenterName']}
            valueField='costCenterId'
            showField={['costCenterName']}
            defaultValue={{
              costCenterId: row?.original?.costCenterId,
              costCenterName: row?.original?.costCenterName,
            }}
          />
        ) : (
          row?.original?.costCenterName || ''
        );
      },
    },
    {
      accessorKey: 'itemName',
      header: 'Item Name',
      enableEditing: true,
      Cell: ({ row }) => {
        const isNewRow =
          row?.original?.purchaseVoucherItemId === rowState?.newRowId;
        const isEditing =
          editingRowId === row?.original?.purchaseVoucherItemId || isNewRow;
        return isEditing ? (
          <FmSearchableSelect
            name='itemId'
            control={control}
            apiUrl='item'
            label='Item'
            onBlur={handleBlur}
            labelField={['itemCode', 'itemName']}
            valueField='costCenterId'
            showField={['itemCode', 'itemName']}
            defaultValue={{
              itemId: row?.original?.itemId,
              itemName: row?.original?.itemName,
            }}
          />
        ) : (
          row?.original?.costCenterName || ''
        );
      },
    },
    {
      accessorKey: 'description',
      header: 'Description',
      enableEditing: true,
      Cell: ({ row }) => {
        const isNewRow =
          row?.original?.purchaseVoucherItemId === rowState?.newRowId;
        const isEditing =
          editingRowId === row?.original?.purchaseVoucherItemId || isNewRow;
        return isEditing ? (
          <FmTextField
            name='description'
            control={control}
            label='Description'
            onBlur={handleBlurAndFetch}
          />
        ) : (
          row?.original?.description || ''
        );
      },
    },
    {
      accessorKey: 'taxCodeId',
      header: 'Tax Code',
      enableEditing: true,
      Cell: ({ row }) => {
        const isNewRow =
          row?.original?.purchaseVoucherItemId === rowState?.newRowId;
        const isEditing =
          editingRowId === row?.original?.purchaseVoucherItemId || isNewRow;
        return isEditing ? (
          <FmSearchableSelect
            name='taxCodeId'
            control={control}
            label='Tax Code'
            onChange={(e) =>
              setFormValues((prevValues) => ({
                ...prevValues,
                taxable: e.target.value,
              }))
            }
            onBlur={handleBlur}
            apiUrl='taxcodes'
            valueField='taxCodeId'
            labelField={['taxCodeName']}
            showField={['taxCodeName']}
            defaultValue={{
              taxCodeId: row?.original?.taxCodeId,
              taxCodeName: row?.original?.taxCodeName,
            }}
          />
        ) : (
          row?.original?.taxCodeName || ''
        );
      },
    },
    {
      accessorKey: 'purchaseAccount',
      header: 'Purchase Account',
      enableEditing: true,
      Cell: ({ row }) => {
        const isNewRow =
          row?.original?.purchaseVoucherItemId === rowState?.newRowId;
        const isEditing =
          editingRowId === row?.original?.purchaseVoucherItemId || isNewRow;
        return isEditing ? (
          <FmTextField
            name='purchaseAccount'
            control={control}
            label='Purchase Account'
            onBlur={handleBlur}
          />
        ) : (
          row?.original?.purchaseAccount || ''
        );
      },
    },
    {
      accessorKey: 'quantity',
      header: 'Quantity',
      Cell: ({ row }) => {
        const isNewRow =
          row?.original?.purchaseVoucherItemId === rowState?.newRowId;
        const isEditing =
          editingRowId === row?.original?.purchaseVoucherItemId || isNewRow;
        return isEditing ? (
          <FmTextField
            name='quantity'
            pattern='Number'
            label='Quantity'
            onChange={(e) =>
              setFormValues((prevValues) => ({
                ...prevValues,
                quantity: e.target.value,
              }))
            }
            required
            control={control}
            onBlur={handleBlur}
          />
        ) : (
          row?.original?.quantity || ''
        );
      },
    },
    {
      accessorKey: 'rate',
      header: 'Rate',
      Cell: ({ row }) => {
        const isNewRow =
          row?.original?.purchaseVoucherItemId === rowState?.newRowId;
        const isEditing =
          editingRowId === row?.original?.purchaseVoucherItemId || isNewRow;
        return isEditing ? (
          <FmTextField
            name='rate'
            type='number'
            control={control}
            onBlur={handleBlur}
          />
        ) : (
          row?.original?.rate || ''
        );
      },
    },
    {
      accessorKey: 'gross',
      header: 'Gross',
      Cell: ({ row }) => {
        const isNewRow =
          row?.original?.purchaseVoucherItemId === rowState?.newRowId;
        const isEditing =
          editingRowId === row?.original?.purchaseVoucherItemId || isNewRow;
        return isEditing ? (
          <FmTextField
            name='gross'
            control={control}
            onBlur={handleBlur}
            onChange={(e) =>
              setFormValues((prevValues) => ({
                ...prevValues,
                gross: e.target.value,
              }))
            }
          />
        ) : (
          row?.original?.gross || ''
        );
      },
    },
    {
      accessorKey: 'discount',
      header: 'Discount',
      Cell: ({ row }) => {
        const isNewRow =
          row?.original?.purchaseVoucherItemId === rowState?.newRowId;
        const isEditing =
          editingRowId === row?.original?.purchaseVoucherItemId || isNewRow;
        return isEditing ? (
          <FmTextField
            name='discount'
            control={control}
            onBlur={handleBlur}
            onChange={(e) =>
              setFormValues((prevValues) => ({
                ...prevValues,
                discount: e.target.value,
              }))
            }
          />
        ) : (
          row?.original?.discount || ''
        );
      },
    },
    {
      accessorKey: 'discountPercentage',
      header: 'Discount Percentage',
      Cell: ({ row }) => {
        const isNewRow =
          row?.original?.purchaseVoucherItemId === rowState?.newRowId;
        const isEditing =
          editingRowId === row?.original?.purchaseVoucherItemId || isNewRow;
        return isEditing ? (
          <FmTextField
            name='discountPercentage'
            control={control}
            onChange={(e) =>
              setFormValues((prevValues) => ({
                ...prevValues,
                discountPercentage: e.target.value,
              }))
            }
            onBlur={handleBlur}
          />
        ) : (
          row?.original?.discountPercentage || ''
        );
      },
    },
    {
      accessorKey: 'vat',
      header: 'VAT',
      Cell: ({ row }) => {
        const isNewRow =
          row?.original?.purchaseVoucherItemId === rowState?.newRowId;
        const isEditing =
          editingRowId === row?.original?.purchaseVoucherItemId || isNewRow;
        return isEditing ? (
          <FmTextField
            name='vat'
            control={control}
            onBlur={handleBlur}
            onChange={(e) =>
              setFormValues((prevValues) => ({
                ...prevValues,
                vat: e.target.value,
              }))
            }
          />
        ) : (
          row?.original?.vat || ''
        );
      },
    },
    {
      accessorKey: 'isTaxable',
      header: 'Is Taxable',
      Cell: ({ row }) => {
        // Track taxable state for each row being edited
        const [taxable, setTaxable] = useState(row?.original?.isTaxable);

        const isNewRow =
          row?.original?.purchaseVoucherItemId === rowState?.newRowId;
        const isEditing =
          editingRowId === row?.original?.purchaseVoucherItemId || isNewRow;

        // Handle change when a user selects a radio button
        const handleTaxableChange = (e) => {
          const newTaxable = e.target.value === 'true';
          setTaxable(newTaxable); // Update the local state of taxable
          row.original.isTaxable = newTaxable; // Update the actual row data
        };

        return isEditing ? (
          <RadioGroup
            sx={{ flexDirection: 'row' }}
            name='isTaxable'
            onChange={handleTaxableChange}
            value={taxable}
          >
            <FormControlLabel value={true} control={<Radio />} label='Yes' />
            <FormControlLabel value={false} control={<Radio />} label='No' />
          </RadioGroup>
        ) : taxable === true ? (
          'Yes'
        ) : (
          'No'
        );
      },
    },
    {
      accessorKey: 'remarks',
      header: 'Remarks',
      enableEditing: true,
      Cell: ({ row }) => {
        const isNewRow =
          row?.original?.purchaseVoucherItemId === rowState?.newRowId;
        const isEditing =
          editingRowId === row?.original?.purchaseVoucherItemId || isNewRow;
        return isEditing ? (
          <FmTextField
            name='remarks'
            control={control}
            label='Remarks'
            multiline
            onBlur={handleBlur}
          />
        ) : (
          row?.original?.remarks || ''
        );
      },
    },
    {
      accessorKey: 'make',
      header: 'Make',
      enableEditing: true,
      Cell: ({ row }) => {
        const isNewRow =
          row?.original?.purchaseVoucherItemId === rowState?.newRowId;
        const isEditing =
          editingRowId === row?.original?.purchaseVoucherItemId || isNewRow;
        return isEditing ? (
          <FmTextField
            name='make'
            control={control}
            label='Make'
            onBlur={handleBlur}
          />
        ) : (
          row?.original?.make || ''
        );
      },
    },
    {
      accessorKey: 'model',
      header: 'Modal',
      enableEditing: true,
      Cell: ({ row }) => {
        const isNewRow =
          row?.original?.purchaseVoucherItemId === rowState?.newRowId;
        const isEditing =
          editingRowId === row?.original?.purchaseVoucherItemId || isNewRow;
        return isEditing ? (
          <FmTextField
            name='model'
            control={control}
            label='Modal'
            onBlur={handleBlur}
          />
        ) : (
          row?.original?.model || ''
        );
      },
    },
    {
      accessorKey: 'technicalSpecs',
      header: 'Technical Specs',
      enableEditing: true,
      Cell: ({ row }) => {
        const isNewRow =
          row?.original?.purchaseVoucherItemId === rowState?.newRowId;
        const isEditing =
          editingRowId === row?.original?.purchaseVoucherItemId || isNewRow;
        return isEditing ? (
          <FmTextField
            name='technicalSpecs'
            control={control}
            label='Technical Specs'
            onBlur={handleBlur}
          />
        ) : (
          row?.original?.technicalSpecs || ''
        );
      },
    },
    {
      accessorKey: 'areaOfUsage',
      header: 'Area Of Usage',
      Cell: ({ row }) => {
        const isNewRow =
          row?.original?.purchaseVoucherItemId === rowState?.newRowId;
        const isEditing =
          editingRowId === row?.original?.purchaseVoucherItemId || isNewRow;
        return isEditing ? (
          <FmTextField
            name='areaOfUsage'
            control={control}
            onBlur={handleBlur}
          />
        ) : (
          row?.original?.areaOfUsage || ''
        );
      },
    },
    {
      accessorKey: 'purchaseVoucherItemName',
      header: 'Purchase Item Name',
      Cell: ({ row }) => {
        const isNewRow =
          row?.original?.purchaseVoucherItemId === rowState?.newRowId;
        const isEditing =
          editingRowId === row?.original?.purchaseVoucherItemId || isNewRow;
        return isEditing ? (
          <FmTextField
            name='purchaseVoucherItemName'
            label='Name'
            control={control}
            onBlur={handleBlur}
          />
        ) : (
          row?.original?.purchaseVoucherItemName || ''
        );
      },
    },
  ];
  const menu = useSelector((state) => state?.menu?.items);

  // Flatten menu only once and store it for reuse
  const SubMenu = useMemo(() => {
    return menu?.map((item) => item?.menus?.flat()).flat() || [];
  }, [menu]);

  // Use findNavByName to get the "Accounts Receivable" menu or its child menus
  const result = useMemo(
    () => findNavByName(SubMenu, 'Financial', 'Accounts Payable'),
    [SubMenu]
  );
  //Tab
  useHotkeys(
    'Enter',
    () => handleAddNewRow(),
    {
      enableOnTags: ['INPUT', 'TEXTAREA'], // Optional: Enable hotkey only when focused on specific tags
    },
    [editingRowIndex, rows, stateError, loader]
  );
  useHotkeys(
    'Tab',
    (event) => {
      if (event.key !== 'Tab') return;
      if (stateError) {
        event.preventDefault();
        return;
      }
      const totalRows = rows.length;
      const nextRowIndex = editingRowIndex + 1;

      if (
        nextRowIndex < totalRows &&
        stateError === false &&
        loader === false
      ) {
        setCurrentRowIndex(nextRowIndex);
        handleEdit(rows[nextRowIndex]);
        setEditingRowIndex(nextRowIndex);
      }
    },
    [editingRowIndex, rows, stateError, loader, isValid]
  );

  useEffect(() => {
    if (editingRowIndex !== null && firstColumnInputRef.current && !isLoading) {
      firstColumnInputRef.current.focus();
    }
  }, [editingRowIndex, isLoading]);

  const previousValues = useRef(getValues()); // store previous values to compare with

  const handleBlur = () => {
    const currentValues = getValues();
    const projectId = getValues('projectId');
    const quantity = getValues('quantity');
    if (!projectId) {
      firstColumnInputRef.current.focus(); // Focus on the projectId field
      return; // Stop execution
    }

    // If 'projectId' is valid, move on to check 'quantity'
    // if (!quantity) {
    //   quantityInputRef.current.focus(); // Focus on the quantity field
    //   return; // Stop execution
    // }

    const hasChanges = Object.keys(currentValues).some(
      (key) => currentValues[key] !== previousValues.current[key]
    );

    if ((hasChanges && isValid) || (hasChanges && editingRowId)) {
      apiCallAsync(currentValues); // Call the API with the new values
      previousValues.current = currentValues; // Update previous values
    }
  };

  const apiCallAsync = async (values) => {
    setLoader(true);
    const convertedValues = {
      ...values,
      purchaseVoucherId: id,
    };

    const updatedCombinedData = {
      ...values,
      purchaseVoucherId: id,
      status: 2,
    };

    if (editingRowId === null) {
      await crud.create('purchasevoucheritem', convertedValues, (err, res) => {
        if (res?.status === 201) {
          setEditingRowId(res?.data?.purchaseVoucherItemId);
          setValue('purchaseVoucherItemId', res?.data?.purchaseVoucherItemId);
        } else {
        }
      });
    } else {
      await crud.update(
        'purchasevoucheritem',
        editingRowId,
        updatedCombinedData,
        (err, res) => {
          if (res?.status === 200) {
            if (rows.some((row) => row.isNew)) {
              const updatedRow = res?.data;
              const updatedRows = [...rows];
              updatedRows[editingRowIndex] = {
                ...updatedRow,
                isEditable: true,
                isNew: true,
              };
              setRows(updatedRows);
              setStateError(false);
              setLoader(false);
            } else {
              setStateError(false);
              setLoader(false);
              const updatedRow = res?.data;
              const updatedRows = [...rows];

              updatedRows[editingRowIndex] = updatedRow;

              setRows(updatedRows);
            }
          } else {
            setStateError(true);
          }
        }
      );
    }
  };

  const handleBlurAndFetch = async () => {
    setLoader(true);
    const updatedRow = getValues(); // Get the latest values from the form
    const updatedRows = [...rows]; // Clone the rows[0] to avoid direct mutation

    // Update the specific row in the cloned array
    updatedRows[editingRowIndex] = updatedRow;

    setRows(updatedRows);

    const values = getValues();

    const updateCombinedData = {
      ...values,
      purchaseVoucherId: id,
      status: 2,
    };
    await crud.update(
      'purchasevoucheritem',
      editingRowId,
      updateCombinedData,
      (err, res) => {
        if (res?.status === 200) {
          const totalRows = rows.length;
          if (editingRowIndex <= totalRows && rows.some((row) => row.isNew)) {
            setNewRowCreated(true);
            const updatedRow = res?.data;
            const updatedRows = [...rows];
            updatedRows[editingRowIndex] = {
              ...updatedRow,
              isEditable: true,
              isNew: true,
            };
            setRows(updatedRows);
            handleAddNewRow();
          } else {
            const updatedRow = res?.data;
            const updatedRows = [...rows];
            updatedRows[editingRowIndex] = {
              ...updatedRow,
              isEditable: false,
              isNew: false,
            };

            setRows(updatedRows);
            setStateError(false);
            setLoader(false);
          }
        } else {
          setStateError(true);
        }
      }
    );
  };

  const handleCancelAndAddNewRow = () => {
    const newId = 'new_row_' + Date.now();
    reset();
    setEditingRowData(null);
    setEditingRowId(null);
    setRowState({
      newRowId: newId,
      isCreatingRow: true,
    });
    setEditingRowIndex(
      editingRowIndex === rows.length ? editingRowIndex + 1 : rows.length
    );
    setNewRowCreated(false);
    setRows((prevRows) => [
      ...prevRows,
      {
        purchaseVoucherItemId: newId,
        materialRequisitionId: id,
        salesOrderId: '',
        brandId: '',
        categoryId: '',
        unitOfMeasurementId: '',
        itemId: '',
        unitOfMeasurementId: '',
        costCenterId: '',
        quantity: '',
        remarks: '',
        description: '',
        isEditable: true,
        isNew: true,
      },
    ]);
    reset();
  };

  const handleAddNewRow = () => {
    const newId = 'new_row_' + Date.now();
    reset();
    setEditingRowData(null);
    setEditingRowId(null);
    setRowState({
      newRowId: newId,
      isCreatingRow: true,
    });

    setEditingRowIndex(
      editingRowIndex === rows.length ? editingRowIndex + 1 : rows.length
    );
    setNewRowCreated(false);
    setRows((prevRows) => [
      ...prevRows,
      {
        purchaseVoucherItemId: newId,
        purchaseVoucherItemName: '',
        purchaseVoucherId: 0,
        projectId: 0,
        contractId: 0,
        workOrderId: 0,
        salesOrderId: 0,
        inCharge: 0,
        divisionId: 0,
        locationId: 0,
        costCenterId: 0,
        itemId: 0,
        description: '',
        taxCodeId: 0,
        purchaseAccount: '',
        quantity: 0,
        rate: 0,
        gross: 0,
        discount: 0,
        discountPercentage: 0,
        vat: 0,
        isTaxable: true,
        remarks: '',
        make: '',
        model: '',
        technicalSpecs: '',
        areaOfUsage: '',
      },
    ]);
    reset();
  };

  return (
    <>
      <DashboardLayout
        title={'Purchase Voucher Items'}
        hasSubmenu
        menu={result}
        actionButtons={
          <>
            <Box className='flex flex-row items-center whitespace-nowrap gap-3'>
              <CreateButton
                name='Create'
                onClick={() =>
                  navigate(
                    `/financial/accounts-payable/${type === 1
                      ? 'purchase-vouchers'
                      : type === 3
                        ? 'purchase-return-vouchers'
                        : 'direct-purchase-order'
                    }/create`
                  )
                }
              />
            </Box>
          </>
        }
      >
        <EditPV type={type} id={id} />
        {/* <AddPVItems fetchData={fetchData} setLoader={setLoader} id={id} /> */}
        <FmMRTDataTable
          columns={columns}
          rows={rows || []}
          isLoading={isLoading}
          rowCount={tableRecordCounts}
          onCreateRow={handleAddNewRow}
          isCreatingRow={rowState?.isCreatingRow}
          editingRow={editingRowId}
          pagination={pagination}
          sorting={sorting}
          columnFilters={columnFilters}
          globalFilter={globalFilter}
          setPagination={setPagination}
          setSorting={setSorting}
          setColumnFilters={setColumnFilters}
          showPagination={false}
          setGlobalFilter={setGlobalFilter}
          setSearchKeyword={setSearchKeyword}
          searchKeyword={searchKeyword}
          fetchData={fetchData}
          handleImport={() => setOpenUpload(true)}
          handleExport={handleExport}
          handleRowDoubleClick={(row) => {
            handleEdit(row?.original);
            setEditingRowIndex(row?.index);
          }}
          showSerialNumber={true}
        />
        <MuiDialogOne
          title='Upload Sheet'
          open={openUpload}
          onClose={handleClose}
        >
          <FmFileInput
            onChange={handleUploadSheet}
            documentName={filesData?.originalFileName}
            loading={createLoading}
          />
        </MuiDialogOne>
        <GlassCard className='p-3'>
          <Stack
            direction='row'
            flexWrap='wrap'
            spacing={3}
            justifyContent='space-between'
            divider={<Divider orientation='vertical' flexItem />}
          >
            {billingValues?.map((item, i) => {
              return (
                <Box key={i} className='flex justify-between items-center'>
                  <Box>{item.name}</Box>
                  <Box
                    className='font-bold ms-3'
                    sx={{ color: 'success.main' }}
                  >
                    {item.value}
                  </Box>
                </Box>
              );
            })}
          </Stack>
        </GlassCard>
      </DashboardLayout>
    </>
  );
};

export default EditPurchaseVoucherList;
