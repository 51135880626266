import React, { useEffect, useState } from 'react';
import GlassCard from '../../../components/small/GlassCard.tsx';
import { Box, Grid, TextField } from '@mui/material';
import Crud_Service from '../../../apis/CrudService.jsx';
import ActionButtons from '../../../components/_form/ActionButtons.tsx';
import DashboardLayout from '../../../components/DashboardLayout.tsx';
import Nav from '../../../utils/Nav.tsx';
import { useNavigate, useParams } from 'react-router-dom';
import CommonLoader from '../../../components/page/CommonLoader.jsx';
import ViewFieldInline from '../../../components/view/ViewFieldInline.tsx';
import FmViewDateField from '../../../components/_mui/FmViewDateField.tsx';
import { CreateButton } from '../../../components/small/Buttons.jsx';
import CommonView from '../../../components/helpers/CommonView.jsx';
import DynamicViewFields from '../../../components/helpers/DynamicViewFields.jsx';
import { LocalizationProvider } from '@mui/x-date-pickers';
import { AdapterDayjs } from '@mui/x-date-pickers/AdapterDayjs';
import FmAutoComplete from '../../../components/_mui/FmAutoComplete.tsx';
import FmSearchableSelect from '../../../components/_mui/FmSearchableSelect.tsx';
import FmTextField from '../../../components/_mui/FmTextField.tsx';
import { statusOptions } from '../../../utils/CommonVariables.tsx';
import useLoader from '../../../components/helpers/UseLoader.tsx';
import { useForm } from 'react-hook-form';
import useCommonFetchApi from '../../../components/helpers/useCommonFetchApi.tsx';
import { toast } from 'react-toastify';
import FmTimePicker from '../../../components/_mui/FmTimePicker.tsx';
import dayjs from 'dayjs';
import { yupResolver } from '@hookform/resolvers/yup';
import { shiftTimeCreateShema } from '../../../utils/CommonvalidationSchemas.tsx';

const GridSize = 2.4;

const CreateShiftTime = () => {
  const crud = new Crud_Service();
  const id = useParams().id;
  const [workOrder, setWorkOrder] = useState({});
  const [loader, setLoader] = useState(true);
  const navigate = useNavigate();
  const {
    createLoading,
    startCreateLoading,
    stopCreateLoading,
    editLoading,
    startEditLoading,
    stopEditLoading,
  } = useLoader();
  const { control, handleSubmit, register, setValue, reset } = useForm({
    mode: 'onChange',
    resolver: yupResolver(shiftTimeCreateShema),
    defaultValues: {
      checkInTime: dayjs(),
      checkOutTime: dayjs(),
      checkInStartTime: dayjs(),
      checkInEndTime: dayjs(),
      checkOutStartTime: dayjs(),
      checkOutEndTime: dayjs(),
    },
  });

  const { data: projects } = useCommonFetchApi('projects');
  const { data: locations } = useCommonFetchApi('locations');
  const { data: companies } = useCommonFetchApi('companies');

  const handleSubmitShiftTimings = async (values) => {
    const data = {
      ...values,
      checkInTime: dayjs(values?.checkInTime).isValid()
        ? dayjs(values?.checkInTime).format('HH:mm:ss')
        : values?.checkInTime,

      checkOutTime: dayjs(values?.checkOutTime).isValid()
        ? dayjs(values?.checkOutTime).format('HH:mm:ss')
        : values?.checkOutTime,

      checkInStartTime: dayjs(values?.checkInStartTime).isValid()
        ? dayjs(values?.checkInStartTime).format('HH:mm:ss')
        : values?.checkInStartTime,

      checkInEndTime: dayjs(values?.checkInEndTime).isValid()
        ? dayjs(values?.checkInEndTime).format('HH:mm:ss')
        : values?.checkInEndTime,

      checkOutStartTime: dayjs(values?.checkOutStartTime).isValid()
        ? dayjs(values?.checkOutStartTime).format('HH:mm:ss')
        : values?.checkOutStartTime,

      checkOutEndTime: dayjs(values?.checkOutEndTime).isValid()
        ? dayjs(values?.checkOutEndTime).format('HH:mm:ss')
        : values?.checkOutEndTime,
    };
    startCreateLoading();
    await crud.create('shifttimings', data, (_err, res) => {
      if (res?.status === 201) {
        toast.success('Shift Time Created Successfully!');
        stopCreateLoading();
        navigate(-1);
      } else {
        stopCreateLoading();
      }
    });
  };

  return (
    <DashboardLayout
      title='Shift Time'
    >
      <GlassCard className='p-3'>
        <form onSubmit={handleSubmit(handleSubmitShiftTimings)}>
          <LocalizationProvider dateAdapter={AdapterDayjs}>
            <Grid container spacing={2}>
              <Grid item md={GridSize}>
                <FmSearchableSelect
                  name='companyId'
                  control={control}
                  headers={['Code', 'Name']}
                  apiUrl='companies'
                  valueField='companyId'
                  labelField={['companyCode', 'companyName']}
                  showField={['companyName']}
                  label={'Company'}
                  required
                />
              </Grid>
              <Grid item md={GridSize}>
                <FmAutoComplete
                  name='locationId'
                  control={control}
                  options={locations}
                  label='Locations'
                  displayField='locationName'
                  optionFields={['locationName']}
                  valueKey='locationId'
                />
              </Grid>
              <Grid item md={GridSize}>
                <FmSearchableSelect
                  name='projectId'
                  control={control}
                  headers={['Code', 'Name']}
                  apiUrl='projects'
                  valueField='projectId'
                  labelField={['projectCode', 'projectName']}
                  showField={['projectName']}
                  pageSize={20}
                  label={'Projects'}
                />
              </Grid>
              <Grid item md={GridSize}>
                <FmTextField
                  name='shiftTimingName'
                  control={control}
                  label='Shift Name'
                  required
                />
              </Grid>

              <Grid item md={6}>
                <Box className='font-semibold text-lg'>Timing</Box>
              </Grid>

              <Grid item md={12}>
                <Grid container spacing={2}>
                  <Grid item md={GridSize}>
                    <FmTimePicker
                      name='checkInTime'
                      control={control}
                      label='Check in Time'
                    />
                  </Grid>
                  <Grid item md={GridSize}>
                    {/* <MuiMobileTimepicker label='Checkout Time' /> */}
                    <FmTimePicker
                      name='checkOutTime'
                      control={control}
                      label='Check out Time'
                      defaultValue={null}
                    />
                  </Grid>
                  <Grid item md={GridSize}>
                    {/* <MuiMobileTimepicker label='Checkin Start Time' /> */}
                    <FmTimePicker
                      name='checkInStartTime'
                      control={control}
                      label='Checkin Start Time'
                    />
                  </Grid>
                  <Grid item md={GridSize}>
                    {/* <MuiMobileTimepicker label='Checkin End Time' /> */}
                    <FmTimePicker
                      name='checkInEndTime'
                      control={control}
                      label='Checkin End Time'
                    />
                  </Grid>
                  <Grid item md={GridSize}>
                    <FmTimePicker
                      name='checkOutStartTime'
                      control={control}
                      label='Checkout Start Time'
                    />
                  </Grid>
                  <Grid item md={GridSize}>
                    <FmTimePicker
                      name='checkOutEndTime'
                      control={control}
                      label='Checkout End Time'
                    />
                  </Grid>
                </Grid>
              </Grid>
              <Grid item md={12}>
                <ActionButtons
                  onSubmit={handleSubmit(handleSubmitShiftTimings)}
                  onReset={reset}
                  submitLoading={editLoading}
                  onCancel={() => navigate(-1)}
                  cancelLoading={false}
                  submitText='Create'
                  cancelText='Back'
                />
              </Grid>
            </Grid>
          </LocalizationProvider>
        </form>
      </GlassCard>
    </DashboardLayout>
  );
};

export default CreateShiftTime;
