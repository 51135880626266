import React from 'react';
import Lottie from 'react-lottie';
import * as animationData from '../../assets/lottie/server-error.json';
import { Box } from '@mui/material';
import { Icon } from '@iconify/react/dist/iconify.js';

const ServerDownUI = () => {
  return (
    <Box className='w-full flex items-center justify-center whitespace-normal'>
      <Box className='text-center'>
        <Box className='justify-center flex '>
          <Box className='relative'>
            <Box
              className='pointer-events-none'
              sx={{ filter: 'hue-rotate(5.66rad)' }}
            >
              <Lottie
                options={{
                  loop: true,
                  autoplay: true,
                  animationData: animationData,
                }}
                height={'300px'}
                width={'100%'}
              />
            </Box>

            <Box className='absolute left-1/2 top-1/2 transform -translate-x-1/2 -translate-y-1/2'>
              <Icon
                icon='fluent-color:dismiss-circle-28'
                width='65'
                height='65'
              />
            </Box>
          </Box>
        </Box>
        <Box className='font-bold text-base flex justify-center items-center gap-2'>
          We are tidying up!
        </Box>
        <p>Sorry for the inconvenience!</p>
        <div className='my-5'>
          <p>Currently updating servers to improve service.</p>
          <p>Thank you for your patience!</p>
        </div>
        <Box>Your beloved site will be available in a few minutes.</Box>
      </Box>
    </Box>
  );
};

export default ServerDownUI;
