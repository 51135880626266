import React, { useState } from 'react';
import GlassCard from '../../../../components/small/GlassCard.tsx';
import MRTExpandTableStyle from '../../../../components/table/MRTExpandTableStyle.tsx';
import MuiDialogOne from '../../../../components/_mui/MuiDialogOne.jsx';
import { ViewTable } from '../../../../components/_form/FormElements.jsx';
import { StatusBadge } from '../../../../components/_form/FormElements.jsx';
import useTableLogic from '../../../../components/helpers/MRTUseTableLogic.tsx';
import ContractScheduleListTable from './ContractScheduleListTable.tsx';
import CurrencyView from '../../../../components/_mui/FmCurrencyView.tsx';
const ViewContractSchedule = (props) => {
  const { contractId } = props;
  const {
    rows,
    isLoading,
    pagination,
    sorting,
    columnFilters,
    globalFilter,
    tableRecordCounts,
    setPagination,
    setSorting,
    setColumnFilters,
    setGlobalFilter,
    fetchData,
    setSearchKeyword,
    searchKeyword,
  } = useTableLogic('contractschedules', ['contractId'], [contractId]);
  const [view, setView] = useState(false);
  const [viewRow, setViewRow] = useState([]);

  return (
    <>
      <GlassCard>
        <MRTExpandTableStyle>
          <ContractScheduleListTable
            rows={rows}
            tableRecordCounts={tableRecordCounts}
            pagination={pagination}
            sorting={sorting}
            fetchData={fetchData}
            setPagination={setPagination}
            setSorting={setSorting}
            columnFilters={columnFilters}
            globalFilter={globalFilter}
            setColumnFilters={setColumnFilters}
            setGlobalFilter={setGlobalFilter}
            isLoading={isLoading}
            setSearchKeyword={setSearchKeyword}
            searchKeyword={searchKeyword}
          />
        </MRTExpandTableStyle>
      </GlassCard>

      <MuiDialogOne
        title='View details'
        open={view}
        onClose={() => setView(!view)}
      >
        <ViewTable
          rows={[
            {
              label: 'Contract',
              title: viewRow?.contract,
            },
            {
              label: 'Frequency',
              title: viewRow?.frequency,
            },
            {
              label: 'Date 1',
              title: viewRow?.date1,
            },
            {
              label: 'Date 2',
              title: viewRow?.date2,
            },
            {
              label: 'Amount To Be Invoiced',
              title: (
                <CurrencyView
                  value={viewRow?.amounttobeInvoiced}
                  currencyType={'AED'}
                />
              ),
            },
            {
              label: 'Amount Invoiced',
              title: viewRow?.amountInvoiced,
            },
            {
              label: 'Invoice Ref',
              title: viewRow?.invoiceRef,
            },
            {
              label: 'Payment Received',
              title: <StatusBadge title={'Yes'} type='green' />,
            },
            {
              label: 'Pay Amount',
              title: (
                <CurrencyView
                  value={viewRow?.payAmount}
                  currencyType={'AED'}
                />
              ),
            },

            {
              label: 'Pay Ref',
              title: viewRow?.payRef,
            },
            {
              label: 'Deductions (if any)',
              title: viewRow?.deducations,
            },
            {
              label: 'Remarks',
              title: viewRow?.remarks,
            },
          ]}
        />
      </MuiDialogOne>
    </>
  );
};

export default ViewContractSchedule;
