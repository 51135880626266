import { yupResolver } from '@hookform/resolvers/yup';
import { Box, Checkbox, FormControl, FormHelperText, Grid } from '@mui/material';
import dayjs from 'dayjs';
import React, { useEffect } from 'react';
import { Controller, useForm } from 'react-hook-form';
import { useTranslation } from 'react-i18next';
import { useNavigate } from 'react-router-dom';
import { toast } from 'react-toastify';
import Crud_Service from '../../../apis/CrudService';
import { ActionButtons } from '../../../components/_form';
import FmAutoComplete from '../../../components/_mui/FmAutoComplete.tsx';
import FmDatePicker from '../../../components/_mui/FmDatePicker.tsx';
import FmSearchableSelect from '../../../components/_mui/FmSearchableSelect.tsx';
import FmTextField from '../../../components/_mui/FmTextField.tsx';
import DashboardLayout from '../../../components/DashboardLayout.tsx';
import useCommonFetchApi from '../../../components/helpers/useCommonFetchApi.tsx';
import useLoader from '../../../components/helpers/UseLoader.tsx';
import GlassCard from '../../../components/small/GlassCard.tsx';
import { contractSchema } from '../../../utils/CommonvalidationSchemas.tsx';
import { projectStatusEnum, projectTypes } from '../../../utils/CommonVariables.tsx';
import BackToButton from '../../../components/small/BackToButton.tsx';
import FmCurrencyTextField from '../../../components/_mui/FmCurrencyTextField.tsx';

const gridValue = 2.4;

const CreateContracts = () => {
  const navigate = useNavigate();
  const crud = new Crud_Service();

  const { handleSubmit, control, reset, watch, setValue, formState: { errors } } = useForm({
    resolver: yupResolver(contractSchema),
    mode: 'onChange',
    defaultValues: {
      typeId: null,
      retention: false,
      retentionPercentage: null,
    },
  });
  const selectedProjectId = watch('projectId');
  const projectTypeId = watch('typeId');
  const retentionChecked = watch('retention');
  useEffect(() => {
    const selectedProject = projects.find(
      (project) => project?.projectId === selectedProjectId
    );
    if (selectedProject) {
      const projectEndDate = new Date(selectedProject.deliveryDate);
      const today = new Date();
      if (projectEndDate < today) {
        setValue('contractEndDate', today);
        setValue('clientId', selectedProject.clientId);
        setValue('contractCost', selectedProject.projectValue);
      } else {
        setValue('contractEndDate', projectEndDate);
      }
    }
  }, [selectedProjectId, setValue]);
  const [selectedTender, setSelectedTender] = React.useState({});

  useEffect(() => {
    setValue('companyId', selectedTender?.companyId);
    setValue('clientId', selectedTender?.clientId);
    setValue('divisionId', selectedTender?.divisionId);
    setValue('locationId', selectedTender?.locationId);
    setValue('branchId', selectedTender?.branchId);
  }, [selectedTender]);
  const { createLoading, startCreateLoading, stopCreateLoading } = useLoader();
  useTranslation();
  const { data: contractmodes } = useCommonFetchApi(
    'contracts/contract-modes',
    {
      sortDirection: 'desc',
      sortBy: 'contractModeName',
    }
  );
  const { data: projects } = useCommonFetchApi('projects', {
    sortDirection: 'asc',
    sortBy: 'projectName',
    tenderStatusId: 4,
  });
  const { data: clients } = useCommonFetchApi('clients', {
    sortDirection: 'asc',
    sortBy: 'clientName',
  });
  const { data: contracttypes } = useCommonFetchApi('contracttypes', {
    sortDirection: 'asc',
    sortBy: 'contractTypeName',
  });
  const { data: contractscopes } = useCommonFetchApi('contractscopes');
  const { data: billingfrequencies } = useCommonFetchApi('billingfrequencies');
  const { data: guarantees } = useCommonFetchApi('contractguarantees', {
    sortDirection: 'asc',
    sortBy: 'guaranteeName',
  });
  const { data: currencies } = useCommonFetchApi('currencies');

  const handleSubmitProject = async (values) => {
    startCreateLoading();
    const CombinedData = {
      ...values,
      guaranteeIds: values.guaranteeIds ? [values.guaranteeIds] : [],
      contractStartDate: dayjs(values?.contractStartDate).format('YYYY-MM-DD'),
      contractEndDate: dayjs(values?.contractEndDate).format('YYYY-MM-DD'),
      guaranteeStartDate: dayjs(values?.guaranteeStartDate).isValid()
        ? dayjs(values?.guaranteeStartDate).format('YYYY-MM-DD')
        : null,
      guaranteeEndDate: dayjs(values?.guaranteeEndDate).isValid()
        ? dayjs(values?.guaranteeEndDate).format('YYYY-MM-DD')
        : null,
      expectedContractNumber: values?.expectedContractNumber,
      contractScopeIds: values.contractScopeIds ? values.contractScopeIds : [],
      contractStatusId: 1,
    };
    await crud.create(`contracts`, CombinedData, (_err, res) => {
      if (res?.status === 201) {
        toast.success('Contract Created Successfully');
        navigate(-1);
        stopCreateLoading();
      } else {
        stopCreateLoading();
      }
    });
  };

  return (
    <DashboardLayout title='Create New Contract'>
      <BackToButton title='Back' onClick={() => navigate(-1)} />
      <GlassCard className='p-4'>
        <form onSubmit={handleSubmit(handleSubmitProject)}>
          <Grid container spacing={2}>
            <Grid item md={gridValue}>
              <FmTextField
                name='contractName'
                control={control}
                required
                label='Contract Name'
              />
            </Grid>

            <Grid item md={gridValue}>
              <FmTextField
                name='contractManualCode'
                control={control}
                label='Contract Manual Code'
                required
              />
            </Grid>
            <Grid item md={gridValue}>
              <FmSearchableSelect
                name='companyId'
                control={control}
                apiUrl='companies'
                valueField='companyId'
                labelField={['companyCode', 'companyName']}
                showField={['companyCode', 'companyName']}
                label='Company' />
            </Grid>
            <Grid item md={gridValue}>
              <FmSearchableSelect
                name='projectId'
                control={control}
                apiUrl='projects'
                valueField='projectId'
                labelField={['projectCode', 'projectName']}
                showField={['projectCode', 'projectName']}
                label={'Project'}
                required
                onChangeProp={(e) => {
                  setSelectedTender(e);
                }}
              />
            </Grid>

            <Grid item md={gridValue}>
              <FmSearchableSelect
                name='clientId'
                control={control}
                apiUrl='clients'
                valueField='clientId'
                labelField={['clientCode', 'clientName']}
                showField={['clientCode', 'clientName']}
                label={'Clients'}
                required
                defaultValue={{
                  clientName: selectedTender?.clientName,
                  clientId: selectedTender?.clientId,
                }}
              />

            </Grid>

            <Grid item md={gridValue}>
              <FmSearchableSelect
                name='branchId'
                control={control}
                apiUrl='branches'
                valueField='branchId'
                labelField={['branchCode', 'branchName']}
                showField={['branchCode', 'branchName']}
                label={'Branch'}
                defaultValue={{
                  branchId: selectedTender?.branchId,
                  branchName: selectedTender?.branchName
                }}
              />
            </Grid>
            <Grid item md={gridValue}>
              <FmSearchableSelect
                name='divisionId'
                control={control}
                apiUrl='divisions'
                valueField='divisionId'
                labelField={['divisionCode', 'divisionName']}
                showField={['divisionCode', 'divisionName']}
                label='Division'
                defaultValue={{
                  divisionId: selectedTender?.divisionId,
                  divisionName: selectedTender?.divisionName,
                }}
                required
              />
            </Grid>
            <Grid item md={gridValue}>
              <FmSearchableSelect
                name='locationId'
                control={control}
                apiUrl='locations'
                label='Location'
                valueField='locationId'
                pageSize={1000}
                labelField={['locationCode', 'locationName']}
                showField={['locationCode', 'locationName']}
                defaultValue={{
                  locationId: selectedTender?.locationId,
                  locationName: selectedTender?.projectLocation,
                }}
              />
            </Grid>
            <Grid item md={gridValue}>
              <FmDatePicker
                name='contractStartDate'
                control={control}
                label='Contract Start Date'
                minDate={dayjs(new Date())}
                defaultValue={null}
                required
              />
            </Grid>
            <Grid item md={gridValue}>
              <FmDatePicker
                name='contractEndDate'
                control={control}
                label='Contract End Date'
                defaultValue={null}
                required
              />
            </Grid>
            <Grid item md={gridValue}>
              <FmTextField
                name='contactPerson'
                control={control}
                required
                label='Contact Person'
              />
            </Grid>
            <Grid item md={gridValue}>
              <FmTextField
                name='contactNumber'
                control={control}
                required
                label='Contact Number'
                pattern='Number'
              />
            </Grid>
            <Grid item md={gridValue}>
              <FmTextField
                name='contactEmailId'
                required
                control={control}
                label='Contact Email'
                type='email'
              />
            </Grid>
            <Grid item md={gridValue}>
              <FmCurrencyTextField
                name='contractCost'
                label='Contract Cost'
                control={control}
                required

              />
            </Grid>
            <Grid item md={gridValue}>
              <FmCurrencyTextField
                name='budget'
                required
                label='Budget'
                control={control}

              />
            </Grid>

            <Grid item md={gridValue}>
              <FmTextField
                name='contractTerms'
                label='Contract Terms'
                control={control}
                required
                multiline={true}
              />
            </Grid>
            <Grid item md={gridValue}>
              <FmSearchableSelect
                name='perviousContractId'
                control={control}
                apiUrl='contracts'
                valueField='contractId'
                labelField={['contractCode', 'contractName']}
                showField={['contractCode', 'contractName']}
                label={'Previous Contract Code'}
              />
            </Grid>
            <Grid item md={gridValue}>
              <FmAutoComplete
                name='contractModeId'
                control={control}
                required
                label='Contract Mode'
                options={contractmodes}
                optionFields={['contractModeName']}
                displayField='contractModeName'
                valueKey='contractModeId'
              />
            </Grid>
            <Grid item md={gridValue}>
              <FmTextField
                name='referenceNumber'
                control={control}
                label='Reference Number'
              />
            </Grid>
            <Grid item md={gridValue}>
              <FmAutoComplete
                name='contractTypeId'
                control={control}
                required
                label='Contract Types'
                options={contracttypes}
                optionFields={['contractTypeName']}
                displayField='contractTypeName'
                valueKey='contractTypeId'
              />
            </Grid>
            <Grid item md={gridValue * 2}>
              <FmAutoComplete
                name='contractScopeIds'
                control={control}
                label='Contract Scopes'
                options={contractscopes}
                optionFields={['contractScopeName']}
                displayField='contractScopeName'
                valueKey='contractScopeId'
                isMultiSelect
              />
            </Grid>
            <Grid item md={gridValue}>
              <FmAutoComplete
                name='billingFrequencyId'
                control={control}
                label='Billing Frequency'
                options={billingfrequencies}
                optionFields={['billingFrequencyName']}
                displayField='billingFrequencyName'
                valueKey='billingFrequencyId'
                required
              />
            </Grid>
            <Grid item md={gridValue}>
              <FmCurrencyTextField
                name='thresholdValue'
                control={control}
                label='Threshold Value'
                required
              />
            </Grid>
            <Grid item md={gridValue}>
              <FmAutoComplete
                name='currencyId'
                control={control}
                label='Currrencies'
                options={currencies}
                optionFields={['currencyName']}
                displayField='currencyName'
                valueKey='currencyId'
              />
            </Grid>
            <Grid item md={gridValue * 2}>
              <FmSearchableSelect
                name='guaranteeIds'
                control={control}
                apiUrl='guarantees'
                valueField='guaranteeId'
                labelField={['guaranteeCode', 'guaranteeName']}
                showField={['guaranteeCode', 'guaranteeName']}
                label={'Guarantee'}
              />
            </Grid>
            <Grid item md={gridValue}>
              <FmDatePicker
                name='guaranteeStartDate'
                control={control}
                onChangeProp={(e) =>
                  setValue('guaranteeEndDate', dayjs(e).add(1, 'day'))
                }
                label='Guarantee Start Date'
                defaultValue={null}

              />
            </Grid>
            <Grid item md={gridValue}>
              <FmDatePicker
                name='guaranteeEndDate'
                control={control}
                label='Guarantee End Date'
                defaultValue={null}
                minDate={
                  watch('guaranteeStartDate')
                    ? dayjs(watch('guaranteeStartDate')).add(1, 'day')
                    : dayjs(new Date()).add(1, 'day')
                }

              />
            </Grid>
            <Grid item md={gridValue}>
              <FmCurrencyTextField
                name='guaranteeAmount'
                control={control}
                label='Guarantee Amount'
              />
            </Grid>

            <Grid item md={gridValue * 2}>
              <FmTextField
                name='remarks'
                control={control}
                label='Remarks'
                multiline={true}
                rows={2}
                maxRows={3}
              />
            </Grid>
            <Grid item md={gridValue}>
              <FmAutoComplete
                name='typeId'
                control={control}
                options={projectTypes}
                label='Project Type'
                displayField='projectTypeName'
                optionFields={['projectTypeName']}
                valueKey='projectTypeId'
                required
              />
            </Grid>
            <Grid item md={gridValue}>
              {projectTypeId === projectStatusEnum?.AMC && (
                <FormControl component="fieldset" error={!!errors?.retention} className="flex flex-col">
                  <div className="flex items-center">
                    <Controller
                      name="retention"
                      control={control}
                      rules={{ required: 'Retention is required' }}
                      render={({ field: { onChange, value, ...field } }) => (
                        <Checkbox
                          {...field}
                          checked={Boolean(value)}
                          onChange={(e) => onChange(e.target.checked)}
                          color="primary"
                        />
                      )}
                    />
                    <Box className="mb-1 font-semibold">Retention</Box>
                  </div>
                  {errors?.retention && (
                    <FormHelperText>{errors.retention.message}</FormHelperText>
                  )}
                </FormControl>
              )}
            </Grid>

            {projectTypeId === projectStatusEnum?.AMC && retentionChecked && (
              <Grid item md={gridValue}>
                <FmTextField
                  name='retentionPercentage'
                  control={control}
                  label='Retention(%)'
                  required
                />
              </Grid>
            )}
          </Grid>
          <ActionButtons
            onSubmit={handleSubmit(handleSubmitProject)}
            onCancel={() => navigate(-1)}
            onReset={reset}
            submitLoading={createLoading}
            cancelLoading={false}
            submitText='Create'
          />
        </form>
      </GlassCard>
    </DashboardLayout>
  );
};

export default CreateContracts;
