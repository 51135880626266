import { Box, Tab, Tabs } from '@mui/material';
import React, { useEffect, useRef, useState } from 'react';
import { NavLink, useLocation } from 'react-router-dom';

const PageSubmenu = (props) => {
  const { menu } = props;
  const tabsContainerRef = useRef(null);
  const [activeIndex, setActiveIndex] = useState(0);
  const location = useLocation();

  useEffect(() => {
    const container = tabsContainerRef.current;

    if (container) {
      const activeTab = container.querySelector('.active'); // Target the active NavLink tab
      if (activeTab) {
        activeTab.scrollIntoView({
          behavior: 'smooth',
          inline: 'center', // Ensures horizontal centering
          block: 'nearest',
        });
      }
    }
  }, [activeIndex]);

  useEffect(() => {
    const currentPath = location.pathname;
    const index = menu?.findIndex((item) => item.url === currentPath);
    setActiveIndex(index);
  }, [location, menu]);

  return (
    <>
      <Box className=''>
        <Box
          role='tablist'
          ref={tabsContainerRef}
          sx={{
            overflowX: 'auto',
            borderBottom: '1px solid',
            borderRadius: '0px',
            borderColor: 'border.main',
            bgcolor: 'inherit',
            whiteSpace: 'nowrap',
            padding: '0px',
            // bgcolor: 'background.light',

            scrollBehavior: 'smooth',

            '& .MuiTabs-scroller': {
              // padding: '3px 0',
            },

            '& .MuiTabs-root': {
              alignItems: 'center',
              minHeight: '36px',
              padding: '0px',
              '& .MuiTabScrollButton-root': {
                minWidth: '32px',
                height: '36px',
                borderRadius: '0px',
                bgcolor: 'none',
                color: 'text.main',
                justifyContent: 'center',
                minHeight: '36px',
                width: '32px',
                '&:hover': {
                  bgcolor: 'background.overlay',
                },
                '& svg': {
                  margin: 0,
                  fontSize: '25px',
                },
                '&.Mui-disabled': {
                  opacity: 0.15,
                },
              },
              '& .MuiTab-root': {
                minHeight: '36px',
                fontWeight: 500,
                padding: '0 3px',
                textAlign: 'center',
                justifyContent: 'center',
                opacity: 0.75,
                borderRadius: '0px',
                minWidth: 'inherit',
                margin: '0px 8px',

                '&:hover': {
                  opacity: 1,

                  color: 'text.dark',
                },
                '&.Mui-selected': {
                  fontWeight: 700,
                  opacity: 1,
                  color: 'text.dark',
                },
              },
            },
          }}
        >
          <Tabs
            value={activeIndex}
            variant='scrollable'
            scrollButtons='auto'
            aria-label='scrollable auto tabs example'
          >
            {menu?.map((value) => (
              <Tab
                key={value?.menuId}
                label={value?.menuName}
                component={NavLink}
                to={value?.url}
                className={({ isActive }) => (isActive ? 'active' : '')}
                sx={{
                  textDecoration: 'none',
                  '&.active': {
                    fontWeight: 'bold',
                    // color: 'primary.main',
                  },
                }}
              />
            ))}
          </Tabs>
        </Box>
      </Box>
    </>
  );
};

export default PageSubmenu;
