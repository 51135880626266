import React, { useCallback, useEffect, useMemo, useState } from 'react';
import Crud_Service from '../../../../apis/CrudService.jsx';
import FmMRTDataTable from '../../../../components/FmMRTDataTable.tsx';
import useTableLogic from '../../../../components/helpers/MRTUseTableLogic.tsx';
import GlassCard from './../../../../components/small/GlassCard.tsx';
import StatusNewBadge from '../../../../components/_form/StatusNewBadge.tsx';
import CurrencyView from '../../../../components/_mui/FmCurrencyView.tsx';
import FmAutoComplete from '../../../../components/_mui/FmAutoComplete.tsx';
import { useForm } from 'react-hook-form';
import FmSearchableSelect from '../../../../components/_mui/FmSearchableSelect.tsx';
import useCommonFetchApi from '../../../../components/helpers/useCommonFetchApi.tsx';
import { Box, Chip, Grid } from '@mui/material';

interface InventoryItemsListProps {
  itemId?: number;
  supplierId?: number;
  storeLocationId?: number;
  height: string;
}

const InventoryItemsList: React.FC<InventoryItemsListProps> = ({
  height,
}) => {
  const [storeLocationId, setStoreLocationId] = useState(0);
  const [supplierId, setSupplierId] = useState(0);
  const { control, watch, setValue, getValues } = useForm();
  const [onFilter, setOnFilter] = useState({
    supplierId: 0,
    storeLocationId: 0,
    showChip: false,
  });
  const { data: storelocations } = useCommonFetchApi('storelocations');
  const {
    rows,
    isLoading,
    pagination,
    sorting,
    columnFilters,
    globalFilter,
    tableRecordCounts,
    setPagination,
    setSorting,
    setColumnFilters,
    setGlobalFilter,
    fetchData,
    setSearchKeyword,
    searchKeyword,
  } = useTableLogic(
    `inventoryitems?supplierId=${onFilter?.supplierId}&storeLocationId=${onFilter?.storeLocationId}`
  );
  const handleDelete = useCallback((field) => {
    if (field === "supplierId") {
      setOnFilter((prev) => ({
        ...prev,
        supplierId: 0,
        showChip: prev.storeLocationId !== 0,
      }));
      setSupplierId(0);
      setValue("supplierId", 0);
      setValue("officialSupplierName", "");
    } else if (field === "storeLocationId") {
      setOnFilter((prev) => ({
        ...prev,
        storeLocationId: 0,
        showChip: prev.supplierId !== 0,
      }));
      setStoreLocationId(0);
      setValue("storeLocationId", 0);
      setValue("storeLocationName", "");
    }
  }, [setOnFilter, setSupplierId, setStoreLocationId, setValue]);

  const columns = [
    {
      accessorKey: 'itemName',
      header: 'Item Name',
      size: 200,
    },
    {
      accessorKey: 'supplierName',
      header: 'Supplier Name',
      size: 250,
    },

    {
      accessorKey: 'storeLocationName',
      header: 'Store Location Name',
      size: 200,
    },
    {
      accessorKey: 'skuCode',
      header: 'SKU',
      size: 150,
    },

    {
      accessorKey: 'purchasePrice',
      header: 'Purchase Price',
      size: 120,
      muiTableBodyCellProps: {
        className: 'bg-blue-50',
      },
      Cell: ({ cell }) => {
        return <CurrencyView value={cell.getValue()} />;
      },
    },

    {
      accessorKey: 'stockInHand',
      header: 'Stock in Hand',
      size: 100,
    },

    {
      accessorKey: 'stockInOrder',
      header: 'Stock in Order',
      size: 100,
    },

    {
      accessorKey: 'warrantyStartDate',
      header: 'Warranty Start Date',
      size: 160,
    },
    {
      accessorKey: 'warrantyEndDate',
      header: 'Warranty End Date',
      size: 160,
    },
    {
      accessorKey: 'statusName',
      header: 'Status',
      size: 120,
      Cell: ({ cell }) => {
        return (
          <StatusNewBadge title={cell.getValue()} type={cell.getValue()} />
        );
      },
    },
  ];
  const selectedSupplierName = useMemo(
    () => getValues('officialSupplierName') || '',
    [watch('officialSupplierName')]
  );
  const selectedStoreLocation = useMemo(
    () => getValues('storeLocationName') || '',
    [watch('storeLocationName')]
  );

  useEffect(() => {
    if (supplierId !== 0 || storeLocationId !== 0) {
      fetchData();
    }
  }, [supplierId, storeLocationId]);
  return (
    <GlassCard className={`${height ? `h-[${height}]` : 'h-full'}`}>
      <>
        <FmMRTDataTable
          columns={columns}
          enableRowSelection={false}
          rows={rows}
          rowCount={tableRecordCounts}
          pagination={pagination}
          sorting={sorting}
          fetchData={fetchData}
          columnFilters={columnFilters}
          globalFilter={globalFilter}
          setPagination={setPagination}
          setSorting={setSorting}
          setColumnFilters={setColumnFilters}
          setGlobalFilter={setGlobalFilter}
          isLoading={isLoading}
          setSearchKeyword={setSearchKeyword}
          searchKeyword={searchKeyword}
          muiTableContainerProps={{
            sx: {
              maxHeight: '100%',
              flex: 1,
            },
          }}
          showSerialNumber={true}
          renderTopToolbarCustomActionsChildrens={
            <>
              {onFilter.showChip && selectedSupplierName ? (
                <Chip
                  label={selectedSupplierName}
                  onDelete={() => handleDelete("supplierId")}
                  className="max-w-[250px]"
                />
              ) : (
                <Box className="w-[200px]">
                  <FmSearchableSelect
                    name="supplierId"
                    control={control}
                    apiUrl="suppliers"
                    valueField="supplierId"
                    labelField={['supplierCode', 'officialSupplierName']}
                    showField={['supplierCode', 'officialSupplierName']}
                    label="Supplier"
                    onChangeProp={(ev) => {
                      const newSupplierId = ev?.supplierId || 0;
                      setValue('supplierId', newSupplierId);
                      setValue('officialSupplierName', ev?.officialSupplierName || '');
                      setSupplierId(newSupplierId);
                      setOnFilter((prev) => ({
                        ...prev,
                        supplierId: newSupplierId,
                        showChip: newSupplierId !== 0 || prev.storeLocationId !== 0,
                      }));
                    }}
                  />
                </Box>
              )}

              {onFilter.showChip && selectedStoreLocation ? (
                <Chip
                  label={selectedStoreLocation}
                  onDelete={() => handleDelete("storeLocationId")}
                  className="max-w-[250px]"
                />
              ) : (
                <Box className="w-[200px]">
                  <FmAutoComplete
                    name="storeLocationId"
                    control={control}
                    options={storelocations}
                    label="Store Locations"
                    displayField="storeLocationName"
                    optionFields={['storeLocationName']}
                    valueKey="storeLocationId"
                    onChange={(storeLocationId) => {
                      const selectedLocation = storelocations.find(
                        (loc) => loc.storeLocationId === storeLocationId
                      ) || {};
                      setValue("storeLocationId", selectedLocation.storeLocationId || 0);
                      setValue("storeLocationName", selectedLocation.storeLocationName || "");
                      setStoreLocationId(selectedLocation.storeLocationId || 0);
                      setOnFilter((prev) => ({
                        ...prev,
                        storeLocationId: selectedLocation.storeLocationId || 0,
                        showChip: selectedLocation.storeLocationId !== 0 || prev.supplierId !== 0,
                      }));
                    }}
                  />
                </Box>
              )}
            </>
          }
        />
      </>
    </GlassCard>
  );
};

export default InventoryItemsList;
