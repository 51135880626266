import React, { memo } from 'react';
import { Drawer, IconButton, DialogContent, DialogTitle } from '@mui/material';
import { Icon } from '@iconify/react';

const FmDrawerOne = memo(
  ({
    title,
    open,
    onClose,
    children,
    lg,
    width = '600px',
    paddingRemoved,
    ...props
  }) => {
    return (
      <Drawer
        onClose={onClose}
        open={open}
        anchor='right'
        PaperProps={{
          classes: { root: 'w-full' },
          sx: {
            maxWidth: lg ? '900px' : width,
            bgcolor: 'background.white',
            color: 'inherit',
          },
        }}
        disableEscapeKeyDown={true}
      >
        <DialogTitle sx={dialogTitleStyles}>{title}</DialogTitle>
        <IconButton aria-label='close' onClick={onClose} sx={iconButtonStyles}>
          <Icon icon='material-symbols:close' />
        </IconButton>
        <DialogContent
          sx={{
            bgcolor: 'background.white',
            padding: paddingRemoved ? '0px' : '20px',
          }}
          dividers
        >
          {children}
        </DialogContent>
      </Drawer>
    );
  }
);

const dialogTitleStyles = {
  m: 0,
  p: '10px 16px',
  color: 'text.dark',
  fontWeight: '600',
  fontSize: '16px',
};

const iconButtonStyles = {
  position: 'absolute',
  right: 8,
  top: 3,
  color: 'text.main',
};

export default FmDrawerOne;
