import React, { useState, useEffect } from 'react';
import { Box } from '@mui/material';
import { useParams } from 'react-router-dom';
import Crud_Service from '../../../../apis/CrudService';
import MuiTimeLineTwo from '../../../../components/_mui/MuiTimeLineTwo.tsx';

const TicketsDetailsHistory = () => {
  const { id } = useParams();
  const [timelineData, setTimelineData] = useState([]);
  const crud = new Crud_Service();

  const fetchTicketTimeLine = async () => {
    await crud.getAll(`ticketactivities/${id}/ticket`, '', (err, res) => {
      if (res?.status === 200) {
        setTimelineData(res?.data?.data);
      }
    });
  };

  useEffect(() => {
    if (id) {
      fetchTicketTimeLine();
    }
  }, [id]);
  return (
    <Box className='w-full ps-8 mt-8'>
      <MuiTimeLineTwo data={timelineData} />
    </Box>
  );
};

export default TicketsDetailsHistory;
