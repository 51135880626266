import React, { useEffect, useState } from 'react';
import DashboardLayout from '../../../components/DashboardLayout.tsx';
import { CreateButton } from '../../../components/small/Buttons.jsx';
import GlassCard from '../../../components/small/GlassCard.tsx';
import { Box } from '@mui/material';
import DataTable from '../../../components/DataTable.tsx';
import TableActionButtons from '../../../components/_form/TableActionButtons.jsx';
import { useTranslation } from 'react-i18next';
import { useLocation, useNavigate } from 'react-router-dom';
import useTableLogic from '../../../components/helpers/UseTableLogic.tsx';
import TableSearch from '../../../components/_form/TableSearch.jsx';
import FmViewDateField from '../../../components/_mui/FmViewDateField.tsx';
import Icons from '../../../utils/Icon.jsx';
import FmTabsOne from '../../../components/tabs/FmTabsOne.tsx';
import { bool } from 'prop-types';
import { CommonTableStatus } from '../../../components/_form/FormElements.jsx';
import ViewColoredDate from '../../../components/view/ViewColoredDate.tsx';
import TableExtraContentTooltip from '../../../components/datagrid/TableExtraContentTooltip.tsx';
import { Icon } from '@iconify/react';
import { TenderBidbondSecurityCheckStatus, tenderBidbondStatusColors } from '../../../utils/CommonVariables.tsx';
import { DateField } from '@mui/x-date-pickers';
import CurrencyView from '../../../components/_mui/FmCurrencyView.tsx';
const navMenu = [
  {
    id: 1,
    name: 'Bid Bond Items',
  },
  {
    id: 2,
    name: 'Security Check Items',
  },
];

const BidBondManagement = () => {
  const [isBidBondCheckFilter, setIsBidBondOrSecurityCheck] = useState(false);
  const location = useLocation();
  const {
    rows,
    pageSize,
    pageNumber,
    setSearchKeyword,
    isLoading,
    tableRecordCounts,
    searchKeyword,
    handlePaginationModelChange,
    handleSortModelChange,
    fetchData,
  } = useTableLogic(`tenderbidbonds?tenderBidbondSecurityCheckStatusIds=${TenderBidbondSecurityCheckStatus?.SUBMITTED}`, 'isSecurityCheck', isBidBondCheckFilter,);
  const navigate = useNavigate();

  useEffect(() => {
    document.title = `Presales-BidBond Management`;
  }, []);
  const [tabValue, setTabValue] = useState(1);

  const handleChange = (_event, newValue) => {
    setTabValue(newValue);
    setIsBidBondOrSecurityCheck(newValue === 1 ? false : true);
  };
  useEffect(() => {
    fetchData();
  }, [isBidBondCheckFilter]);

  //setvalues to the Inputs
  const handleEditClick = (props) => {
    if (location.pathname.startsWith('/financial/bidbond-management')) {
      navigate(`/financial/bidbond-management/edit/${props?.tenderBidbondId}`);
    } else {
      navigate(`/presales/enquiry/view/${props?.tenderId}`);
    }

    // Store the selected tab in local storage
    // localStorage.setItem('BidBondSelectedTab', 2);
  };

  const ActionData = [
    {
      name: 'Edit',
      icon: Icons.edit,
      onClick: (props) => handleEditClick(props),
    },
  ];

  const columns = [
    {
      field: 'action',
      headerName: '',
      width: 90,
      sortable: false,
      disableClickEventBubbling: false,
      renderCell: (params) => {
        return (
          <TableActionButtons
            Actions={ActionData?.map((action) => ({
              ...action,
              onClick: () => action.onClick(params.row),
            }))}
          />
        );
      },
    },
    {
      field: 'tenderBidbondCode',
      headerName: 'Tender Bidbond Code',
      minWidth: 150,
    },
    {
      field: 'tenderName',
      headerName: 'Tender',
      minWidth: 150,
    },

    {
      field: 'validity',
      headerName: 'Validity',
      minWidth: 150,
    },
    {
      field: 'expectedTenderAmount',
      headerName: 'Expected Tender Amount',
      minWidth: 150,
      renderCell: (params) => {
        return (
          <CurrencyView
            value={params?.row?.expectedTenderAmount}
            currencyType={'AED'}
          />
        );
      },
    },
    {
      field: 'fixedBidBondAmount',
      headerName: 'Fixed Bid Bond Amount',
      minWidth: 150,
      renderCell: (params) => {
        return (
          <CurrencyView
            value={params?.row?.fixedBidBondAmount}
            currencyType={'AED'}
          />
        );
      },
    },
    {
      field: 'bidBondPercentageValue',
      headerName: 'Bid Bond Percentage Value',
      minWidth: 150,
      // renderCell: (params) => {
      //   return (
      //     <CurrencyView
      //       value={params?.row?.bidBondPercentageValue}
      //       currencyType={'AED'}
      //     />

      //   )
      // }
    },
    {
      field: 'bidBondPercentagePrice',
      headerName: 'Bid Bond Percentage Price',
      minWidth: 150,
      renderCell: (params) => {
        return (
          <CurrencyView
            value={params?.row?.bidBondPercentagePrice}
            currencyType={'AED'}
          />
        );
      },
    },
    {
      field: 'fixedSecurityCheckAmount',
      headerName: 'Fixed Security Check Amount',
      minWidth: 150,
      renderCell: (params) => {
        return (
          <CurrencyView
            value={params?.row?.fixedSecurityCheckAmount}
            currencyType={'AED'}
          />
        );
      },
    },
    {
      field: 'securityCheckPercentageValue',
      headerName: 'Security Check Percentage(%)',
      minWidth: 150,
    },
    {
      field: 'securityCheckPercentagePrice',
      headerName: 'Security Check Percentage Price',
      minWidth: 150,
      renderCell: (params) => {
        return (
          <CurrencyView
            value={params?.row?.securityCheckPercentagePrice}
            currencyType={'AED'}
          />
        );
      },
    },
    {
      field: 'tenderBidbondSecurityCheckStatusName',
      headerName: isBidBondCheckFilter === false ? 'Bid Bond Status' : 'Security Check Status',
      minWidth: 150,
      renderCell: ({ row }) => {
        const statusName = row?.tenderBidbondSecurityCheckStatusName;
        const statusColor = tenderBidbondStatusColors[statusName] || 'gray';

        return <CommonTableStatus name={statusName} color={statusColor} />;
      },
    },
    {
      field: 'expenseAmount',
      headerName: 'Expense Amount',
      minWidth: 150,
      renderCell: (params) => {
        return (
          <CurrencyView
            value={params?.row?.expenseAmount}
            currencyType={'AED'}
          />
        );
      },
    },
    {
      field: 'bidBondUserName',
      headerName: 'Bid Bond User',
      minWidth: 150,
    },
    {
      field: 'remarks',
      headerName: 'Remarks',
      minWidth: 150,
    },
    {
      field: 'createdDate',
      headerName: 'Date',
      minWidth: 130,
      flex: 1,
      renderCell: ({ row }) => {
        return (
          <TableExtraContentTooltip
            titleRows={[
              { label: 'Created Date', title:row?.createdDate,date:true},
              { label: 'Updated Date', title:row?.updatedDate,date:true }
            ]}
            dark={true}
          >
            <ViewColoredDate
              date={FmViewDateField({ value: row?.createdDate })}
              originalDate={row?.createdDate}
              icon={<Icon icon='material-symbols:calendar-month-outline' />}
            />
          </TableExtraContentTooltip>
        );
      },
    },
  ];

  const onRowDoubleClick = (e) => {
    if (e?.row) {
      handleEditClick(e?.row);
    }
  };

  return (
    <>
      <DashboardLayout title='BidBond Management'>
        <Box className='h-full flex flex-col gap-1'>
          <GlassCard>
            <FmTabsOne
              value={tabValue}
              handleChange={handleChange}
              data={navMenu}
            />
          </GlassCard>
          <GlassCard className='flex-1 min-h-0'>
            <Box className='h-full-css'>
              <DataTable
                rows={rows}
                columns={columns}
                loading={isLoading}
                onRowDoubleClick={(e) => onRowDoubleClick(e)}
                getRowClassName={(params) =>
                  `${
                    params.row.workingStatus === 'InActive' &&
                    'bg-red-500 bg-opacity-15'
                  }`
                }
                sortingMode='server'
                paginationMode='server'
                onPaginationModelChange={(model) =>
                  handlePaginationModelChange(model)
                }
                initialState={{
                  columns: {
                    columnVisibilityModel: {
                      fixedBidBondAmount: !isBidBondCheckFilter,
                      bidBondPercentageValue: !isBidBondCheckFilter,
                      bidBondPercentagePrice: !isBidBondCheckFilter,
                      bidBondUserName: !isBidBondCheckFilter,
                      fixedSecurityCheckAmount: isBidBondCheckFilter,
                      securityCheckPercentageValue: isBidBondCheckFilter,
                      securityCheckPercentagePrice: isBidBondCheckFilter,
                    },
                  },
                }}
                columnVisibilityModel={{
                  fixedBidBondAmount: !isBidBondCheckFilter,
                  bidBondPercentageValue: !isBidBondCheckFilter,
                  bidBondPercentagePrice: !isBidBondCheckFilter,
                  bidBondUserName: !isBidBondCheckFilter,
                  fixedSecurityCheckAmount: isBidBondCheckFilter,
                  securityCheckPercentageValue: isBidBondCheckFilter,
                  securityCheckPercentagePrice: isBidBondCheckFilter,
                }}
                onSortModelChange={(model) => handleSortModelChange(model)}
                page={pageNumber - 1}
                pageSize={pageSize}
                rowCount={tableRecordCounts?.total}
                fetchData={fetchData}
                slots={{
                  toolbar: () => (
                    <Box
                      className='p-2 w-full flex justify-between items-center'
                      sx={{
                        borderBottom: '1px solid',
                        borderColor: 'border.main',
                      }}
                    >
                      <Box sx={{ maxWidth: '250px', width: '100%' }}>
                        <TableSearch
                          placeholder='Search'
                          fullWidth
                          setSearchKeyword={setSearchKeyword}
                          searchValue={searchKeyword}
                        />
                      </Box>
                    </Box>
                  ),
                }}
              />
            </Box>
          </GlassCard>
        </Box>
      </DashboardLayout>
    </>
  );
};

export default BidBondManagement;
