import React, { useEffect, useRef, useState } from 'react';
import Crud_Service from '../../../../../apis/CrudService.jsx';
import { useParams } from 'react-router-dom';
import { useForm } from 'react-hook-form';
import { ContractBudgetSchema } from '../../../../../utils/CommonvalidationSchemas.tsx';
import { useHotkeys } from 'react-hotkeys-hook';
import FmTextField from '../../../../../components/_mui/FmTextField.tsx';
import { Box, Button } from '@mui/material';
import TableActionButtons from '../../../../../components/_form/TableActionButtons.jsx';
import GlassCard from '../../../../../components/small/GlassCard.tsx';
import FmMRTDataTable from '../../../../../components/FmMRTDataTable.tsx';
import useTableLogic from '../../../../../components/helpers/MRTUseTableLogic.tsx';
import { yupResolver } from '@hookform/resolvers/yup';
import FmSearchableSelect from '../../../../../components/_mui/FmSearchableSelect.tsx';
import Icons from '../../../../../utils/Icon.jsx';
import { toast } from 'react-toastify';
import FmCurrencyTextField from '../../../../../components/_mui/FmCurrencyTextField.tsx';
import CurrencyView from '../../../../../components/_mui/FmCurrencyView.tsx';

const ARReceiptsItemList = () => {
  const { id } = useParams();
  const {
    rows,
    setRows,
    rowCount,
    isLoading,
    pagination,
    sorting,
    columnFilters,
    globalFilter,
    tableRecordCounts,
    setPagination,
    setSorting,
    setColumnFilters,
    setGlobalFilter,
    fetchData,
    setSearchKeyword,
    searchKeyword,
  } = useTableLogic(
    `financialtransactionreceiptitems`
    //  ['financialTransactionReceiptId'], [id]
  );
  const crud = new Crud_Service();
  const [editingRowId, setEditingRowId] = useState(null);
  const [editingRowData, setEditingRowData] = useState(null);
  const {
    control,
    reset,
    getValues,
    setValue,
    formState: { isValid },
  } = useForm({
    // resolver: yupResolver(ContractBudgetSchema),
    mode: 'all',
  });
  const firstColumnInputRef = useRef(null);
  const [stateError, setStateError] = useState(false);
  const [loader, setLoader] = useState(false);
  const [editingRowIndex, setEditingRowIndex] = useState(null);
  const [rowState, setRowState] = useState({
    editingRowId: null,
    newRowId: null,
    isCreatingRow: false,
  });

  const handleCancel = (row) => {
    if (
      row?.original?.financialTransactionReceiptItemId === rowState?.newRowId
    ) {
      setRows((prevRows) => {
        if (prevRows?.length > 0) {
          return prevRows?.slice(0, -1);
        }
        return prevRows;
      });

      setRowState((prevState) => ({
        ...prevState,
        newRowId: null,
        isCreatingRow: false,
      }));
    } else {
      setEditingRowId(null);
    }
    reset();
  };

  const handleEdit = (row) => {
    reset();
    if (rowState?.newRowId) {
      setRows((prevRows) => {
        if (prevRows?.length > 0) {
          return prevRows?.slice(0, -1);
        }
        return prevRows;
      });
      setRowState((prevState) => ({
        ...prevState,
        newRowId: null,
        isCreatingRow: false,
      }));
      // setEditingRowIndex(null);
    }
    setEditingRowId(row?.financialTransactionReceiptItemId);
    Object.entries({
      ...row,
    }).forEach(([key, value]) => {
      setValue(key, value);
    });
    setEditingRowData({ ...row });
  };

  useHotkeys(
    'Enter',
    () => handleAddNewRow(),
    {
      enableOnTags: ['INPUT', 'TEXTAREA'], // Optional: Enable hotkey only when focused on specific tags
    },
    [editingRowIndex, rows, stateError, loader]
  );

  useHotkeys(
    'Tab',
    (event) => {
      if (event.key !== 'Tab') return;
      if (stateError) {
        event.preventDefault();
        return;
      }
      const totalRows = rows.length;
      const nextRowIndex = editingRowIndex + 1;

      if (
        nextRowIndex < totalRows &&
        stateError === false &&
        loader === false
      ) {
        handleEdit(rows[nextRowIndex]);
        setEditingRowIndex(nextRowIndex);
      }
    },
    [editingRowIndex, rows, stateError, loader, isValid]
  );

  useEffect(() => {
    if (editingRowIndex !== null && firstColumnInputRef.current && !isLoading) {
      firstColumnInputRef.current.focus();
    }
  }, [editingRowIndex, isLoading]);

  const previousValues = useRef(getValues());

  const handleCancelAndAddNewRow = () => {
    const newId = 'new_row_' + Date.now();
    reset();
    setEditingRowData(null);
    setEditingRowId(null);

    setRowState({
      newRowId: newId,
      isCreatingRow: true,
    });
    setEditingRowIndex(
      editingRowIndex === rows?.length ? editingRowIndex + 1 : rows?.length
    );
    setRows((prevRows) => [
      ...prevRows,
      {
        financialTransactionReceiptItemId: newId,
        financialTransactionReceiptId: id,
        salesInvoiceId: '',
        receivedAmount: '',
        isEditable: true,
        isNew: true,
      },
    ]);
    reset();
  };

  const handleBlur = () => {
    const currentValues = getValues();
    const salesInvoiceId = getValues('salesInvoiceId');

    if (!salesInvoiceId) {
      firstColumnInputRef.current.focus();
      return;
    }

    const hasChanges = Object.keys(currentValues).some(
      (key) => currentValues[key] !== previousValues.current[key]
    );

    if ((hasChanges && isValid) || (hasChanges && editingRowId)) {
      apiCallAsync(currentValues); // Call the API with the new values
      previousValues.current = currentValues; // Update previous values
    }
  };

  const apiCallAsync = async (values) => {
    setLoader(true);
    const convertedValues = {
      ...values,
      receivedAmount: Number(values?.receivedAmount),
      financialTransactionReceiptId: Number(id),
    };

    const updatedCombinedData = {
      ...values,
      receivedAmount: Number(values?.receivedAmount),
      financialTransactionReceiptId: Number(id),
      status: 2,
    };

    if (editingRowId === null) {
      await crud.create(
        'financialtransactionreceiptitems',
        convertedValues,
        (err, res) => {
          if (res?.status === 201) {
            setEditingRowId(res?.data?.financialTransactionReceiptItemId);
            // setValue('financialTransactionReceiptItemId', res?.data?.financialTransactionReceiptItemId);
          } else {
          }
        }
      );
    } else {
      await crud.update(
        'financialtransactionreceiptitems',
        editingRowId,
        updatedCombinedData,
        (err, res) => {
          if (res?.status === 200) {
            if (rows?.some((row) => row?.isNew)) {
              const updatedRow = res?.data;
              const updatedRows = [...rows];
              updatedRows[editingRowIndex] = {
                ...updatedRow,
                isEditable: true,
                isNew: true,
              };
              setRows(updatedRows);
              setStateError(false);
              setLoader(false);
            } else {
              setStateError(false);
              setLoader(false);
              const updatedRow = res?.data;
              const updatedRows = [...rows];
              updatedRows[editingRowIndex] = updatedRow;
              setRows(updatedRows);
            }
          } else {
            setStateError(true);
          }
        }
      );
    }
  };

  //final Step Api Call
  const handleBlurAndFetch = async () => {
    setLoader(true);
    const values = getValues();

    const updateCombinedData = {
      ...values,
      receivedAmount: Number(values?.receivedAmount),
      financialTransactionReceiptId: Number(id),
      status: 2,
    };
    await crud.update(
      'financialtransactionreceiptitems',
      editingRowId,
      updateCombinedData,
      (err, res) => {
        if (res?.status === 200) {
          const totalRows = rows.length;
          if (editingRowIndex <= totalRows && rows.some((row) => row.isNew)) {
            const updatedRow = res?.data;
            const updatedRows = [...rows];
            updatedRows[editingRowIndex] = {
              ...updatedRow,
              isEditable: true,
              isNew: true,
            };
            setRows(updatedRows);
            handleAddNewRow();
          } else {
            const updatedRow = res?.data;
            const updatedRows = [...rows];
            updatedRows[editingRowIndex] = {
              ...updatedRow,
              isEditable: false,
              isNew: false,
            };

            setRows(updatedRows);
            setStateError(false);
            setLoader(false);
          }
        } else {
          setStateError(true);
        }
      }
    );
  };

  const handleDeleteBudget = async (view) => {
    await crud.remove(
      'financialtransactionreceiptitems',
      view?.financialTransactionReceiptItemId,
      (err, res) => {
        if (res?.status === 204) {
          fetchData();
          toast.success('Receipt Item Deleted Successfully');
        } else {
        }
      }
    );
  };

  const handleSelectedSalesInvoice = (data) => {
    setValue('projectName', data?.projectName);
    setValue('contractName', data?.contractName);
  };

  const ActionData = [
    {
      name: 'Delete',
      icon: Icons.delete,
      onClick: (props) => handleDeleteBudget(props),
      danger: true,
    },
  ];

  const columns = [
    {
      accessorKey: 'salesInvoiceId',
      header: 'Sales Invoice',
      enableEditing: true,
      Cell: ({ row }) => {
        const isNewRow =
          row?.original?.financialTransactionReceiptItemId ===
          rowState?.newRowId;
        const isEditing =
          editingRowId === row?.original?.financialTransactionReceiptItemId ||
          isNewRow;
        return isEditing ? (
          <FmSearchableSelect
            name='salesInvoiceId'
            control={control}
            apiUrl='salesinvoices'
            valueField={'salesInvoiceId'}
            headerField={['Code']}
            labelField={['salesInvoiceCode']}
            showField={['salesInvoiceCode']}
            onChangeProp={handleSelectedSalesInvoice}
            inputRef={firstColumnInputRef}
            label={'Sales Invoice'}
            onBlur={handleBlur}
            defaultValue={{
              salesInvoiceId: row?.original?.salesInvoiceId,
              salesInvoiceCode: row?.original?.salesInvoiceCode,
            }}
          />
        ) : (
          row?.original?.salesInvoiceCode || ''
        );
      },
    },
    {
      accessorKey: 'projectName',
      header: 'Project',
      size: 250,
      Cell: ({ row }) => {
        return row?.original?.projectName;
      },
    },
    {
      accessorKey: 'contractName',
      header: 'Contract',
      size: 200,
      Cell: ({ row }) => {
        return row?.original?.contractName;
      },
    },

    {
      accessorKey: 'invoicedAmount',
      header: 'Invoiced Amount',
      enableEditing: true,
      size: 150,
      Cell: ({ row }) => {
        return <CurrencyView value={row?.original?.invoicedAmount} />;
      },
    },
    {
      accessorKey: 'receivedAmount',
      header: 'Received Amount',
      enableEditing: true,
      size: 150,
      Cell: ({ row }) => {
        const isNewRow =
          row?.original?.financialTransactionReceiptItemId ===
          rowState?.newRowId;
        const isEditing =
          editingRowId === row?.original?.financialTransactionReceiptItemId ||
          isNewRow;
        return isEditing ? (
          <FmCurrencyTextField
            name='receivedAmount'
            label='Discount'
            control={control}
            onBlur={handleBlurAndFetch}
          />
        ) : (
          <CurrencyView value={row?.original?.receivedAmount} />
        );
      },
    },
    {
      accessorKey: 'pendingAmount',
      header: 'Pending Amount',
      enableEditing: true,
      size: 150,
      Cell: ({ row }) => {
        return <CurrencyView value={row?.original?.pendingAmount} />;
      },
    },
    {
      header: 'Action',
      enableColumnPinning: true,
      enableEditing: false,
      enableSorting: false,
      size: 150,
      muiTableBodyCellProps: {
        sx: {
          borderLeft: '1px solid',
          borderColor: 'border.main',
        },
      },
      Cell: ({ row, table }) => {
        const isNewRow =
          row?.original?.financialTransactionReceiptItemId ===
          rowState?.newRowId;
        const isEditing =
          editingRowId === row?.original?.financialTransactionReceiptItemId ||
          isNewRow;

        // Check if the current row is the last row in the table
        const isLastRow = row.index === table.getRowModel().rows.length - 1;

        return (
          <Box className='flex gap-2'>
            {isEditing ? (
              <>
                <Button
                  onClick={() => handleCancel(row)}
                  variant='contained'
                  color='error'
                  className='px23 py-0.5'
                >
                  Close
                </Button>
                {isLastRow && rowState?.isCreatingRow === false && (
                  <Button
                    onFocus={() => handleCancelAndAddNewRow()}
                    hidden={true}
                  ></Button>
                )}
              </>
            ) : (
              <Box className='flex gap-2'>
                <TableActionButtons
                  Actions={ActionData?.map((action) => ({
                    ...action,
                    onClick: () => action.onClick(row?.original),
                  }))}
                />
                {isLastRow && (
                  <Button
                    variant='contained'
                    color='primary'
                    className='px23 py-0.5'
                    onClick={() => {
                      setEditingRowIndex(row?.index + 1);
                      handleAddNewRow();
                    }}
                  >
                    Add Row
                  </Button>
                )}
              </Box>
            )}
          </Box>
        );
      },
    },
  ];

  const handleAddNewRow = () => {
    const newId = 'new_row_' + Date.now();
    reset();
    setEditingRowData(null);
    setEditingRowId(null);
    setRowState({
      newRowId: newId,
      isCreatingRow: true,
    });
    setEditingRowIndex(
      editingRowIndex === rows.length ? editingRowIndex + 1 : rows.length
    );
    setRows((prevRows) => [
      ...prevRows,
      {
        financialTransactionReceiptItemId: newId,
        financialTransactionReceiptId: id,
        salesInvoiceId: '',
        receivedAmount: '',
        isEditable: true,
        isNew: true,
      },
    ]);
    reset();
  };

  return (
    <>
      <GlassCard className='h-[500px]'>
        <FmMRTDataTable
          rows={rows}
          columns={columns}
          onCreateRow={handleAddNewRow}
          isCreatingRow={rowState?.isCreatingRow}
          enableRowSelection={false}
          rowCount={tableRecordCounts}
          editingRow={editingRowId}
          fetchData={fetchData}
          pagination={pagination}
          sorting={sorting}
          columnFilters={columnFilters}
          globalFilter={globalFilter}
          setPagination={setPagination}
          setSorting={setSorting}
          setColumnFilters={setColumnFilters}
          setGlobalFilter={setGlobalFilter}
          isLoading={isLoading}
          setSearchKeyword={setSearchKeyword}
          searchKeyword={searchKeyword}
          handleRowDoubleClick={(row) => {
            handleEdit(row?.original);
            setEditingRowIndex(row?.index);
          }}
          muiTableContainerProps={{
            sx: {
              maxHeight: '100%',
              flex: 1,
            },
          }}
          showSerialNumber={true}
        />
      </GlassCard>
    </>
  );
};

export default ARReceiptsItemList;
