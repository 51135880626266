import React, { useEffect, useState, useMemo } from 'react';
import {
  Box,
  Chip,
  FormControl,
  InputLabel,
  MenuItem,
  Select,
} from '@mui/material';
import FmSearchableSelect from './../_mui/FmSearchableSelect.tsx';
import { useForm } from 'react-hook-form';

type FmFilterSelectButtonProps = {
  onFilterChange: (value: { clientId?: string; parentClientId?: string; yesNoValue: string }) => void;
  handleDelete: () => void;
  yesNoValue: string;
};

const FmFilterSelectButton: React.FC<FmFilterSelectButtonProps> = ({
  onFilterChange,
  handleDelete,
  yesNoValue: initialYesNoValue,
}) => {
  const [anchorEl, setAnchorEl] = React.useState<null | HTMLElement>(null);
  const [toggleButtons, setToggleButtons] = useState(false);
  const [selectedValue, setSelectedValue] = useState('');
  const [parentClientId, setParentClientId] = useState('');
  const [yesNoValue, setYesNoValue] = useState(initialYesNoValue);
  const [isValidParentClient, setIsValidParentClient] = useState(false);
  const { control, setValue, handleSubmit, reset } = useForm();
  useEffect(() => {
    setYesNoValue(initialYesNoValue);
  }, [initialYesNoValue]);
  const validateParentClientId = async (clientId: string) => {
    const isValid = !isNaN(Number(clientId)) && Number(clientId) > 0;
    return isValid;
  };

  const onSubmit = async (data: { clientId: string }) => {
    event?.preventDefault();
    if (data.clientId) {
      let filterData;
      if (yesNoValue === 'yes') {
        const isValid = await validateParentClientId(data.clientId);
        setIsValidParentClient(isValid);
        filterData = {
          yesNoValue,
          ...(isValid ? { parentClientId: data.clientId } : { clientId: data.clientId }),
        };
      } else {
        filterData = {
          yesNoValue,
          clientId: data.clientId,
        };
      }
      onFilterChange(filterData);
      setToggleButtons(true);
      setAnchorEl(null);
    }
  };
  const handleYesNoChange = (event: React.ChangeEvent<{ value: unknown }>) => {
    const newYesNoValue = event.target.value as string;
    setYesNoValue(newYesNoValue);
    setSelectedValue('');
    setParentClientId('');
    setIsValidParentClient(false);
    setToggleButtons(false);
    reset();
    onFilterChange({
      yesNoValue: newYesNoValue,
    });
  };

  const getApiUrl = () => {
    if (yesNoValue === 'no') {
      return 'clients';
    }

    const baseUrl = 'clients/getallclientvalues';
    if (yesNoValue === 'yes' && parentClientId && !toggleButtons && isValidParentClient) {
      return `${baseUrl}?parentClientId=${parentClientId}`;
    }
    return baseUrl;
  };
  const apiUrl = useMemo(() => getApiUrl(), [yesNoValue, parentClientId, toggleButtons, isValidParentClient]);

  return (
    <>
      {toggleButtons ? (
        <Chip
          label={`${selectedValue}`}
          onDelete={() => {
            setSelectedValue('');
            setParentClientId('');
            setIsValidParentClient(false);
            handleDelete();
            setToggleButtons(false);
            reset();
            onFilterChange({
              yesNoValue,
            });
          }}
          className='max-w-[250px]'
        />
      ) : (
        <Box sx={{ display: 'flex', gap: '20px' }} className='w-[350px]'>
          <Box sx={{ minWidth: 120 }}>
            <FormControl fullWidth variant='filled'>
              <InputLabel id='yes-no-select-label'>Group Client</InputLabel>
              <Select
                labelId='yes-no-select-label'
                id='yes-no-select'
                value={yesNoValue}
                onChange={handleYesNoChange}
                className='table-filter-button'
              >
                <MenuItem value='yes'>Yes</MenuItem>
                <MenuItem value='no'>No</MenuItem>
              </Select>
            </FormControl>
          </Box>
          {!toggleButtons && (
            <FmSearchableSelect
              name='clientId'
              control={control}
              apiUrl={apiUrl}
              valueField='clientId'
              headerField={['Name', 'Code']}
              labelField={['clientName', 'clientCode']}
              showField={['clientName', 'clientCode']}
              label='Client'
              onChangeProp={(e) => {
                if (e?.clientId) {
                  setValue('clientId', e.clientId);
                  setSelectedValue(e.clientName || '');
                  setParentClientId(e.clientId);
                  handleSubmit(onSubmit)();
                }
              }}
              className='table-filter-button'
            />
          )}
        </Box>
      )}
    </>
  );
};

export default FmFilterSelectButton;