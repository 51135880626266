import React, { useEffect, useState } from "react";
import GlassCard from "../../../components/small/GlassCard.tsx";
import { Grid } from "@mui/material";
import MuiDatePickerOne from "../../../components/_mui/MuiDatePickerOne.tsx";
import FmSearchableSelect from "../../../components/_mui/FmSearchableSelect.tsx";
import FmTextField from "../../../components/_mui/FmTextField.tsx";
import { Controller, set, useForm } from "react-hook-form";
import ActionButtons from "../../../components/_form/ActionButtons.tsx";
import { useNavigate, useParams } from "react-router-dom";
import useLoader from "../../../components/helpers/UseLoader.tsx";
import FmAutoComplete from "../../../components/_mui/FmAutoComplete.tsx";
import { statusOptions } from "../../../utils/CommonVariables.tsx";
import * as Yup from "yup";
import { yupResolver } from "@hookform/resolvers/yup";
import dayjs from "dayjs";
import { toast } from "react-toastify";
import Crud_Service from "../../../apis/CrudService.jsx";
import FmDatePicker from "../../../components/_mui/FmDatePicker.tsx";
import DashboardLayout from "../../../components/DashboardLayout.tsx";

const GridSize = 2.4;

const AddSnagReport = () => {
  const navigate = useNavigate();
  const crud = new Crud_Service();
  const id = useParams()?.id
  const { createLoading, startCreateLoading, stopCreateLoading } = useLoader();
  const [editingRowData, setEditingRowData] = useState(null);
  const validationSchema = Yup.object().shape({
    snagReportName: Yup.string().required("Snag report name is required"),

    snagReportCode: Yup.string().required("Snag report code is required"),

    projectId: Yup.number()
      .required("Project is required")
      .min(1, "Project must be greater than 0"),

    contractId: Yup.number()
      .nullable()
      .typeError("Contract must be a number")
      .min(1, "Contract must be greater than 0"),

    companyId: Yup.number()
      .nullable()
      .typeError("Company must be a number")
      .min(1, "Company must be greater than 0"),

    clientId: Yup.number()
      .nullable()
      .typeError("Client must be a number")
      .min(1, "Client must be greater than 0"),

    divisionId: Yup.number()
      .nullable()
      .typeError("Division must be a number")
      .min(1, "Division must be greater than 0"),

    locationId: Yup.number()
      .nullable()
      .typeError("Location must be a number")
      .min(1, "Location must be greater than 0"),

    buildingId: Yup.number()
      .nullable()
      .typeError("Building must be a number")
      .min(1, "Building must be greater than 0"),

    snagReportDate: Yup.date()
      .required("Snag report date is required")
      .typeError("Invalid date format"),

    snagTypeId: Yup.number()
      .nullable()
      .typeError("Snag type must be a number")
      .min(1, "Snag type must be greater than 0"),

    areaId: Yup.number()
      .typeError("Area must be a number")
      .nullable()
      .min(1, "Area must be greater than 0"),

    inspectedBy: Yup.number()
      .nullable()
      .min(1, "Inspector must be greater than 0")
      .typeError("Inspector must be a number"),

    propertyManager: Yup.string()
      .nullable()
      .typeError("Property manager must be a string"),

    notes: Yup.string().nullable(),
  });
  const { handleSubmit, control, reset, setValue } = useForm({
    resolver: yupResolver(validationSchema),
    mode: "onChange",
    defaultValues: {
      snagReportDate: dayjs().format('YYYY-MM-DD'),
    },
  });

  useEffect(() => {
    if (id) {
      (async () => {
        await crud.getSingle('snagreports', id, (err, res) => {
          if (res?.status === 200) {
            setEditingRowData(res?.data);
            Object.entries({
              ...res.data,
            }).forEach(([key, value]) => {
              setValue(key, value);
            });
          } else {
            // Handle error response here
          }
        });
      })();
    }
  }, [id]);

  const handleSubmitProject = async (values) => {
    startCreateLoading();
    const combinedData = {
      ...values,
      snagReportDate: dayjs(values?.snagReportDate).format("YYYY-MM-DD"),
    };
    await crud.create("snagreports", combinedData, (err, res) => {
      if (err) {
        stopCreateLoading();
        toast.error("Something went wrong:", err);
        return;
      }
      if (res?.status === 201) {
        toast.success("Snag Report Created successfully");
        stopCreateLoading();
        navigate(-1);
        reset();
      } else {
        stopCreateLoading();
        toast.error("Something Went Wrong!");
      }
    });
  };

  //TODO need to handle reset
  const handleReset = () => {
    reset({});
  };

  return (
    <DashboardLayout title='Snag Report Create'
      hasSubmenu>
      <GlassCard className="p-3">
        {!createLoading && (
          <form onSubmit={handleSubmit(handleSubmitProject)}>
            <Grid container spacing={2}>
              <Grid item md={GridSize}>
                <FmTextField
                  name="snagReportName"
                  control={control}
                  label="Snag Report Name"
                  required
                />
              </Grid>
              <Grid item md={GridSize}>
                <FmTextField
                  name="snagReportCode"
                  control={control}
                  label="Snag Report Code"
                  required
                />
              </Grid>
              <Grid item md={GridSize}>
                <FmSearchableSelect
                  name="projectId"
                  control={control}
                  apiUrl="projects"
                  valueField="projectId"
                  headerField={["Project Code"]}
                  labelField={["projectCode"]}
                  showField={["projectCode"]}
                  
                  label="Project Code"
                  required
                  defaultValue={{
                    projectId: editingRowData?.projectId || null,
                    projectCode: editingRowData?.projectName || null
                  }}
                />
              </Grid>
              <Grid item md={GridSize}>
                <FmSearchableSelect
                  name="contractId"
                  control={control}
                  apiUrl="contracts"
                  valueField="contractId"
                  headerField={["Contract Code"]}
                  labelField={["contractCode"]}
                  showField={["contractCode"]}
                  
                  label="Contract"
                  defaultValue={{
                    contractId: editingRowData?.contractId || null,
                    contractCode: editingRowData?.contractName || null
                  }}
                />
              </Grid>
              <Grid item md={GridSize}>
                <FmSearchableSelect
                  name="companyId"
                  control={control}
                  apiUrl="companies"
                  valueField="companyId"
                  headerField={["Code"]}
                  labelField={["companyCode"]}
                  showField={["companyCode"]}
                  
                  label="Company"
                  defaultValue={{
                    companyId: editingRowData?.companyId || null,
                    companyCode: editingRowData?.companyName || null
                  }}
                />
              </Grid>

              <Grid item md={GridSize}>
                <FmSearchableSelect
                  name="clientId"
                  control={control}
                  apiUrl="clients"
                  valueField="clientId"
                  headerField={["Client Name"]}
                  labelField={["clientName"]}
                  showField={["clientName"]}
                  
                  label="Client"
                  defaultValue={{
                    clientId: editingRowData?.clientId || null,
                    clientName: editingRowData?.clientName || null
                  }}
                />
              </Grid>

              <Grid item md={GridSize}>
                <FmSearchableSelect
                  name="divisionId"
                  control={control}
                  apiUrl="divisions"
                  valueField="divisionId"
                  headerField={["Division Name"]}
                  labelField={["divisionName"]}
                  showField={["divisionName"]}
                  
                  label="Division"
                  defaultValue={{
                    divisionId: editingRowData?.divisionId || null,
                    divisionName: editingRowData?.divisionName || null
                  }}
                />
              </Grid>

              <Grid item md={GridSize}>
                <FmSearchableSelect
                  name="locationId"
                  control={control}
                  apiUrl="locations"
                  valueField="locationId"
                  headerField={["Location Name"]}
                  labelField={["locationName"]}
                  showField={["locationName"]}
                  
                  label="Location"
                  defaultValue={{
                    locationId: editingRowData?.locationId || null,
                    locationName: editingRowData?.locationName || null
                  }}
                />
              </Grid>
              <Grid item md={GridSize}>
                <FmSearchableSelect
                  name="buildingId"
                  control={control}
                  apiUrl="buildings"
                  valueField="buildingId"
                  headerField={["Building Name"]}
                  labelField={["buildingName"]}
                  showField={["buildingName"]}
                  
                  label="Building"
                  defaultValue={{
                    buildingId: editingRowData?.buildingId || null,
                    buildingName: editingRowData?.buildingName || null
                  }}
                />
              </Grid>
              <Grid item md={GridSize}>
                <FmDatePicker
                  name="snagReportDate"
                  label="Snag Report Date"
                  control={control}
                  required
                />
              </Grid>

              <Grid item md={GridSize}>
                <FmSearchableSelect
                  name="snagTypeId"
                  control={control}
                  apiUrl="snagtypes"
                  valueField="snagTypeId"
                  headerField={["Snag Name"]}
                  labelField={["snagTypeName"]}
                  showField={["snagTypeName"]}
                  
                  label="Snag Type"
                  required
                  defaultValue={{
                    snagTypeId: editingRowData?.snagTypeId || null,
                    snagTypeName: editingRowData?.snagTypeName || null
                  }}
                />
              </Grid>

              <Grid item md={GridSize}>
                <FmTextField
                  name="lumpsumAmount"
                  control={control}
                  label="Lumpsum Amount"
                  pattern="Number"
                />
              </Grid>

              <Grid item md={GridSize}>
                <FmSearchableSelect
                  name="areaId"
                  control={control}
                  apiUrl="areas"
                  valueField="areaId"
                  headerField={["Area Name"]}
                  labelField={["areaName"]}
                  showField={["areaName"]}
                  
                  label="Area"
                  defaultValue={{
                    areaId: editingRowData?.areaId || null,
                    areaName: editingRowData?.areaName || null
                  }}
                />
              </Grid>
              <Grid item md={GridSize}>
                <FmSearchableSelect
                  name="inspectedBy"
                  control={control}
                  apiUrl="loginusers"
                  valueField="loginUserId"
                  headerField={["User Name"]}
                  labelField={["loginUserName"]}
                  showField={["loginUserName"]}
                  
                  label="InspectedBy"
                  defaultValue={{
                    loginUserId: editingRowData?.inspectedBy || null,
                    loginUserName: editingRowData?.inspectedUserName || null
                  }}
                />
              </Grid>

              <Grid item md={GridSize}>
                <FmTextField
                  name="propertyManager"
                  control={control}
                  label="Property Manager"
                />
              </Grid>


              <Grid item md={GridSize}>
                <FmTextField
                  name="notes"
                  control={control}
                  label="Notes"
                  multiline={true}
                />
              </Grid>
            </Grid>
            <Grid item md={GridSize}>
              <ActionButtons
                onSubmit={handleSubmit(handleSubmitProject)}
                onCancel={() => navigate(-1)}
                onReset={handleReset}
                submitLoading={createLoading}
                cancelLoading={false}
              />
            </Grid>
          </form>
        )}
      </GlassCard>
    </DashboardLayout>
  );
};

export default AddSnagReport;
