import React from 'react';
import { Button, Menu, Box } from '@mui/material';
import { Icon } from '@iconify/react/dist/iconify.js';

const getIcon = {
  alert: <Icon icon='twemoji:warning' width='36' height='36' />,
};

const ButtonPopoverAlert = ({
  title,
  message,
  onConfirm,
  icon = 'alert',
  buttonText,
  ...props
}) => {
  const [anchorEl, setAnchorEl] = React.useState(null);
  const open = Boolean(anchorEl);

  const handleClick = (event) => {
    setAnchorEl(event.currentTarget);
  };

  const handleClose = (event) => {
    setAnchorEl(null);
  };

  return (
    <>
      <Button onClick={handleClick} {...props}>
        {buttonText}
      </Button>
      <Menu
        anchorEl={anchorEl}
        open={open}
        onClose={handleClose}
        component='div'
        anchorOrigin={{
          vertical: 'center',
          horizontal: 'center',
        }}
        transformOrigin={{
          vertical: 'center',
          horizontal: 'center',
        }}
        sx={{
          '& .MuiPaper-root': {
            width: '300px',
          },
          '& .MuiList-root': { padding: 0 },
        }}
      >
        <Box className='p-4'>
          <Box className='flex items-center gap-4'>
            <Box>{getIcon[icon]}</Box>
            <Box className='flex-1'>
              <h3 className='font-bold'>{title}</h3>
              <p className='opacity-75'>
                {message || 'Are you sure you want to delete this?'}
              </p>
            </Box>
          </Box>

          <div className='flex justify-end items-center gap-2 mt-5'>
            <Button
              variant='outlined'
              color='error'
              className='px-2 py-1'
              onClick={handleClose}
            >
              No
            </Button>
            <Button
              onClick={() => {
                onConfirm();
                handleClose();
              }}
              variant='contained'
              sx={{
                fontWeight: 700,
                padding: '4px 8px',
                fontSize: '0.875rem',
              }}
              className='px-2 py-1'
              color='error'
            >
              Yes
            </Button>
          </div>
        </Box>
      </Menu>
    </>
  );
};

export default ButtonPopoverAlert;
