import React, { useEffect, useState } from 'react';
import { Box, Grid, Button, TextField } from '@mui/material';
import GlassCard from '../../../components/small/GlassCard.tsx';
import CurrencyView from '../../../components/_mui/FmCurrencyView.tsx';

const TotalBox = (props) => {
  const {
    amcSheetsPercent,
    setAmcSheetsPercent,
    total,
    handleTotalSubmit,
    fieldEdited,
    rows,
    editedingRowData,
  } = props;
  const [totalSubAmount, setTotalSubAmount] = useState(0);
  const calculateCost = (base, percentage) => {
    return percentage > 0 ? (base * percentage) / 100 : 0;
  };

  useEffect(() => {
    const updateRowsAndCalculateTotal = (key) => {
      const updatedRows = rows?.map((row) =>
        row?.[key] === editedingRowData?.[key]
          ? { ...row, subTotalAmount: editedingRowData?.subTotalAmount }
          : row
      );

      // Recalculate the total subTotalAmount after the update
      const total = updatedRows?.reduce(
        (sum, item) => sum + (item?.subTotalAmount || 0),
        0
      );

      setTotalSubAmount(total);
    };

    if (editedingRowData?.estimationAmcSheetDetailId || rows?.length) {
      updateRowsAndCalculateTotal('estimationAmcSheetDetailId');
    } else if (editedingRowData?.estimationMepSheetDetailId) {
      updateRowsAndCalculateTotal('estimationMepSheetDetailId');
    }
  }, [rows, editedingRowData]);

  useEffect(() => {
    const overHeadCost =
      total + calculateCost(total, amcSheetsPercent?.overHeadOverAllPercent);

    const contingencyCost =
      overHeadCost +
      calculateCost(overHeadCost, amcSheetsPercent?.contingencyOverAllPercent);

    const otherCost =
      contingencyCost +
      calculateCost(contingencyCost, amcSheetsPercent?.otherOverAllPercent);

    const markUpCost =
      otherCost +
      calculateCost(otherCost, amcSheetsPercent?.markUpOverAllPercent);

    const discountCost =
      markUpCost -
      calculateCost(markUpCost, amcSheetsPercent?.discountOverAllPercent);

    const negotiationCost =
      discountCost -
      calculateCost(discountCost, amcSheetsPercent?.negotiationPercent);

    const totalEstimationCost = negotiationCost;

    setAmcSheetsPercent((prev) => ({
      ...prev,
      overHeadCost: overHeadCost.toFixed(2),
      contingencyCost: contingencyCost.toFixed(2),
      otherCost: otherCost.toFixed(2),
      markUpCost: markUpCost.toFixed(2),
      discountCost: discountCost.toFixed(2),
      negotiationCost: negotiationCost.toFixed(2),
      totalEstimationCost: totalEstimationCost.toFixed(2),
    }));
  }, [
    amcSheetsPercent?.overHeadOverAllPercent,
    amcSheetsPercent?.contingencyOverAllPercent,
    amcSheetsPercent?.otherOverAllPercent,
    amcSheetsPercent?.markUpOverAllPercent,
    amcSheetsPercent?.discountOverAllPercent,
    amcSheetsPercent?.negotiationPercent,
    total,
  ]);

  return (
    <Box className='mt-5'>
      <Grid container spacing={2}>
        <Grid item md={8}>
          <GlassCard className='p-3' bgWhite>
            <Box className='flex justify-between items-center mb-3'>
              <h3 className='font-semibold'>
                {/* {`SubTotal - ${(Number(totalSubAmount) || 0).toFixed(2)}`} */}
                SubTotal :{' '}
                <CurrencyView value={totalSubAmount} currencyType='AED' />
              </h3>
              <h3 className='font-semibold'>
                Total : <CurrencyView value={total} currencyType='AED' />
              </h3>
            </Box>
            <Box
              sx={{
                '& table': {
                  width: '100%',
                  '& td': {
                    padding: '3px 10px',
                    border: '1px solid',
                    borderColor: 'border.main',
                  },
                },
              }}>
              <table>
                <tbody>
                  <tr className='font-semibold'>
                    <td>No</td>
                    <td>Total</td>
                    <td align='center'>% Value</td>
                    <td align='center'>Cost in AED</td>
                  </tr>
                  <tr>
                    <td>1</td>
                    <td>Over Head value in %</td>
                    <td align='center'>
                      <TextField
                        value={amcSheetsPercent?.overHeadOverAllPercent}
                        fullWidth
                        type='number'
                        disabled={fieldEdited}
                        InputProps={{ inputProps: { min: 0, max: 100 } }}
                        onChange={(e) => {
                          let value = e.target.value; // Get the raw input value

                          // Allow empty strings or numbers within range
                          if (
                            value === '' ||
                            (!isNaN(value) &&
                              parseFloat(value) >= 0 &&
                              parseFloat(value) <= 100)
                          ) {
                            setAmcSheetsPercent((prev) => ({
                              ...prev,
                              overHeadOverAllPercent: value,
                            }));
                          }
                        }}
                        placeholder='% Value'
                      />
                    </td>
                    <td align='center'>
                      <CurrencyView value={amcSheetsPercent?.overHeadCost} />
                    </td>
                  </tr>
                  <tr>
                    <td>2</td>
                    <td>Contingency value in %</td>
                    <td align='center'>
                      <TextField
                        value={
                          amcSheetsPercent?.contingencyOverAllPercent ?? ''
                        }
                        fullWidth
                        type='number'
                        disabled={fieldEdited}
                        InputProps={{ inputProps: { min: 0, max: 100 } }}
                        onChange={(e) => {
                          const rawValue = e.target.value; // Raw input value as a string
                          let value = parseFloat(rawValue);

                          // Validate and ensure value remains within bounds
                          if (rawValue === '') {
                            value = ''; // Allow clearing the field
                          } else if (isNaN(value) || value < 0) {
                            value = 0; // Enforce minimum value
                          } else if (value > 100) {
                            value = 100; // Enforce maximum value
                          }

                          setAmcSheetsPercent((prev) => ({
                            ...prev,
                            contingencyOverAllPercent: value,
                          }));
                        }}
                        placeholder='% Value'
                      />
                    </td>
                    <td align='center'>
                      <CurrencyView value={amcSheetsPercent?.contingencyCost} />
                    </td>
                  </tr>
                  <tr>
                    <td>3</td>
                    <td>Other Taxes if any in %</td>
                    <td align='center'>
                      <TextField
                        value={amcSheetsPercent?.otherOverAllPercent || 0}
                        fullWidth
                        disabled={fieldEdited}
                        type='number'
                        InputProps={{ inputProps: { min: 0, max: 100 } }}
                        onChange={(e) => {
                          const rawValue = e.target.value; // Raw input as a string
                          let value = parseFloat(rawValue);

                          // Validate and normalize the value
                          if (rawValue === '') {
                            value = ''; // Allow empty input
                          } else if (isNaN(value) || value < 0) {
                            value = 0; // Set minimum value
                          } else if (value > 100) {
                            value = 100; // Set maximum value
                          }

                          setAmcSheetsPercent((prev) => ({
                            ...prev,
                            otherOverAllPercent: value,
                          }));
                        }}
                        placeholder='% Value'
                      />
                    </td>
                    <td align='center'>
                      <CurrencyView value={amcSheetsPercent?.otherCost} />
                    </td>
                  </tr>
                  <tr>
                    <td>4</td>
                    <td>Mark up value in %</td>
                    <td align='center'>
                      <TextField
                        value={amcSheetsPercent?.markUpOverAllPercent ?? ''}
                        fullWidth
                        type='number'
                        disabled={fieldEdited}
                        InputProps={{ inputProps: { min: 0, max: 100 } }}
                        onChange={(e) => {
                          const rawValue = e.target.value; // Get raw input as a string
                          let value = parseFloat(rawValue);

                          // Validate and normalize the value
                          if (rawValue === '') {
                            value = ''; // Allow empty input
                          } else if (isNaN(value) || value < 0) {
                            value = 0; // Clamp to minimum value
                          } else if (value > 100) {
                            value = 100; // Clamp to maximum value
                          }

                          setAmcSheetsPercent((prev) => ({
                            ...prev,
                            markUpOverAllPercent: value,
                          }));
                        }}
                        placeholder='% Value'
                      />
                    </td>
                    <td align='center'>
                      <CurrencyView value={amcSheetsPercent?.markUpCost} />
                    </td>
                  </tr>
                  <tr>
                    <td>5</td>
                    <td>Discount Value in %</td>
                    <td align='center'>
                      <TextField
                        value={amcSheetsPercent?.discountOverAllPercent ?? ''}
                        fullWidth
                        type='number'
                        disabled={fieldEdited}
                        InputProps={{ inputProps: { min: 0, max: 100 } }}
                        onChange={(e) => {
                          const rawValue = e.target.value; // Raw input as a string
                          let value = parseFloat(rawValue);

                          // Validate and normalize the value
                          if (rawValue === '') {
                            value = ''; // Allow empty input
                          } else if (isNaN(value) || value < 0) {
                            value = 0; // Clamp to minimum value
                          } else if (value > 100) {
                            value = 100; // Clamp to maximum value
                          }

                          setAmcSheetsPercent((prev) => ({
                            ...prev,
                            discountOverAllPercent: value,
                          }));
                        }}
                        placeholder='% Value'
                      />
                    </td>
                    <td align='center'>
                      <CurrencyView value={amcSheetsPercent?.discountCost} />
                    </td>
                  </tr>
                  <tr>
                    <td>6</td>
                    <td>Negotiation Value in %</td>
                    <td align='center'>
                      <TextField
                        value={amcSheetsPercent?.negotiationPercent ?? ''}
                        fullWidth
                        disabled={fieldEdited}
                        type='number'
                        InputProps={{ inputProps: { min: 0, max: 100 } }}
                        onChange={(e) => {
                          const rawValue = e.target.value; // Raw input as a string
                          let value = parseFloat(rawValue);

                          // Validate and normalize the value
                          if (rawValue === '') {
                            value = ''; // Allow empty input
                          } else if (isNaN(value) || value < 0) {
                            value = 0; // Clamp to minimum value
                          } else if (value > 100) {
                            value = 100; // Clamp to maximum value
                          }

                          setAmcSheetsPercent((prev) => ({
                            ...prev,
                            negotiationPercent: value,
                          }));
                        }}
                        placeholder='% Value'
                      />
                    </td>
                    <td align='center'>
                      <CurrencyView value={amcSheetsPercent?.negotiationCost} />
                    </td>
                  </tr>
                  <tr className='font-bold bg-teal-50 text-teal-600'>
                    <td colSpan={3} align='right' className='py-4'>
                      Total Estimated Cost
                    </td>
                    <td align='center' className='py-4'>
                      {/* {amcSheetsPercent?.totalEstimationCost
                        ? `${amcSheetsPercent?.totalEstimationCost} AED`
                        : '0 AED'} */}
                      {amcSheetsPercent?.totalEstimationCost ? (
                        <CurrencyView
                          value={amcSheetsPercent?.totalEstimationCost}
                          currencyType='AED'
                        />
                      ) : (
                        <CurrencyView value={0} currencyType='AED' />
                      )}
                    </td>
                  </tr>
                </tbody>
              </table>
            </Box>
          </GlassCard>
        </Grid>
        <Grid item md={4}>
          <GlassCard className='p-3'>
            <TextField
              fullWidth
              label='Note'
              multiline
              value={amcSheetsPercent?.note}
              onChange={(e) =>
                setAmcSheetsPercent((prev) => ({
                  ...prev,
                  note: e.target.value,
                }))
              }
            />
            <Box className='mt-5 flex justify-end'>
              <Button
                variant='contained'
                color='primary'
                onClick={() => handleTotalSubmit(amcSheetsPercent)}>
                Submit
              </Button>
            </Box>
          </GlassCard>
        </Grid>
      </Grid>
    </Box>
  );
};

export default TotalBox;
