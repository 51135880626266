import React from 'react';
import { useNavigate } from 'react-router-dom';
import DashboardLayout from '../../../../components/DashboardLayout.tsx';
import BackToButton from '../../../../components/small/BackToButton.tsx';
import FieldsCreateDebitJournal from './FieldsCreateDebitJournal.tsx';

const CreateDebitNote = () => {
    const navigation = useNavigate();

    return (
        <DashboardLayout
            title={'Create Debit Note'}
            actionButtons={
                <BackToButton
                    title='Back to List'
                    onClick={() => navigation('/financial/journal-entries/debit-notes')}
                    className='px-0 py-0'
                />
            }
            titleReverse
        >
            <FieldsCreateDebitJournal />
        </DashboardLayout>
    );
};

export default CreateDebitNote;
