import React from 'react';
import { Box, IconButton, Tooltip } from '@mui/material';
import { Icon } from '@iconify/react';

const SideBarIconButton = ({ name, icon, ...props }) => {
  const renderIcon = (iconStr) => {
    const match = iconStr.match(/icon=['"](.+?)['"]/);
    if (!match) return null;

    return <Icon icon={match[1]} />;
  };

  return (
    <Box className='flex justify-center'>
      <Tooltip title={name} placement='right' arrow>
        <IconButton
          sx={{
            color: 'inherit',
            padding: '5px',
            borderRadius: '5px',
            opacity: 0.5,
            fontSize: '22px',

            '&:before': {
              content: '""',
              width: '2px',
              height: '0px',
              position: 'absolute',
              top: '0px',
              bgcolor: 'text.main',
              left: '-8px',
              transition: '0.2s all',
            },

            '&:hover': {
              bgcolor: 'background.overlayTwo',
              opacity: 1,
            },
            '&.active': {
              bgcolor: 'secondary.main',
              color: '#fff',
              opacity: 1,
              '&:before': {
                height: '100%',
                bgcolor: 'secondary.main',
              },
            },
          }}
          {...props}
        >
          {renderIcon(icon)}
        </IconButton>
      </Tooltip>
    </Box>
  );
};

export default SideBarIconButton;
