export const colorClasses = {
  green: 'text-green-700 bg-green-200',
  red: 'text-red-700 bg-red-200',
  blue: 'text-blue-700 bg-blue-200',
  black: 'text-gray-900 bg-gray-200',
  purple: 'text-purple-700 bg-purple-200',
  orange: 'text-orange-700 bg-orange-200',
  teal: 'text-teal-700 bg-teal-200',
  indigo: 'text-indigo-700 bg-indigo-200',
  yellow: 'text-yellow-700 bg-yellow-200',
  pink: 'text-pink-700 bg-pink-200',
  gray: 'text-gray-700 bg-gray-200',
  cyan: 'text-cyan-700 bg-cyan-200',
  lime: 'text-lime-700 bg-lime-200',
  amber: 'text-amber-700 bg-amber-200',
  rose: 'text-rose-700 bg-rose-200',
  active: 'active',
};

export const colorOnlyClasses = {
  green: 'text-green-600',
  red: 'text-red-600',
  blue: 'text-blue-600',
  black: 'text-gray-600',
  purple: 'text-purple-600',
  orange: 'text-orange-600',
  teal: 'text-teal-600',
  indigo: 'text-indigo-600',
  yellow: 'text-yellow-600',
  pink: 'text-pink-600',
  gray: 'text-gray-600',
  cyan: 'text-cyan-600',
  lime: 'text-lime-600',
  amber: 'text-amber-600',
  rose: 'text-rose-600',
  active: 'active',
};

export const colorClassesNew = {
  green: 'text-green-700 bg-green-200',
  red: 'text-red-700 bg-red-200',
  blue: 'text-blue-700 bg-blue-200',
  black: 'text-gray-900 bg-gray-200',
  purple: 'text-purple-700 bg-purple-200',
  orange: 'text-orange-700 bg-orange-200',
  teal: 'text-teal-700 bg-teal-200',
  indigo: 'text-indigo-700 bg-indigo-200',
  yellow: 'text-yellow-700 bg-yellow-200',
  pink: 'text-pink-700 bg-pink-200',
  gray: 'text-gray-700 bg-gray-200',
  cyan: 'text-cyan-700 bg-cyan-200',
  lime: 'text-lime-700 bg-lime-200',
  amber: 'text-amber-700 bg-amber-200',
  rose: 'text-rose-700 bg-rose-200',
  active: 'active',
};

export const colorBgOnly = {
  green: 'bg-green-600',
  red: 'bg-red-600',
  blue: 'bg-blue-600',
  black: 'bg-gray-600',
  purple: 'bg-purple-600',
  orange: 'bg-orange-600',
  teal: 'bg-teal-600',
  indigo: 'bg-indigo-600',
  yellow: 'bg-yellow-600',
  pink: 'bg-pink-600',
  gray: 'bg-gray-600',
  cyan: 'bg-cyan-600',
  lime: 'bg-lime-600',
  amber: 'bg-amber-600',
  rose: 'bg-rose-600',
  active: 'active',
};
