import React from 'react';
import { Box, Avatar, Tooltip } from '@mui/material';
import { Icon } from '@iconify/react/dist/iconify.js';
import TableExtraContentTooltip from '../datagrid/TableExtraContentTooltip.tsx';

const AttendanceAvatar = ({
  name,
  currentDate,
  cadre,
  TotalDate,
  desc,
  tooltip = 'false',
  tooltipContent,
  cadreText,
}) => {
  return (
    <Box className='flex gap-2 items-center w-full'>
      {!cadre ? (
        <Avatar
          sx={{
            width: '25px',
            height: '25px',
            bgcolor: 'secondary.main',
            color: '#ffffff',
          }}
        />
      ) : (
        <Box color={'secondary.main'} className='relative'>
          {cadreText === 'Workmen-OTNA' && (
            <Box className='absolute bottom-0 right-0 text-red-500 pointer-events-none z-[1]'>
              <Icon
                icon='fluent-emoji-flat:alarm-clock'
                width='16'
                height='16'
              />
            </Box>
          )}
          <Tooltip arrow title={cadreText} placement='left'>
            <Avatar
              sx={{
                width: '25px',
                height: '25px',
                bgcolor: 'primary.light',
                color: 'primary.main',
              }}
            >
              {' '}
              <Icon
                icon={
                  cadreText === 'Staff'
                    ? 'foundation:torso-business'
                    : cadreText === 'Workmen-OTNA'
                    ? 'healthicons:construction-worker-outline'
                    : 'healthicons:construction-worker'
                }
                width='20'
                height='20'
              />{' '}
            </Avatar>
          </Tooltip>
        </Box>
      )}

      <Box className='flex-1 '>
        <Box className='font-semibold'>{name}</Box>
        <Box className='flex items-center gap-2 justify-between'>
          {desc && <Box className='opacity-80'>{desc}</Box>}

          {/* <Box className='flex items-center gap-2'>
            {tooltip ? (
              <TableExtraContentTooltip titleRows={tooltipContent}>
                <Box className='opacity-50 cursor-pointer'>
                  <Icon icon='material-symbols:info' width='20' height='20' />
                </Box>
              </TableExtraContentTooltip>
            ) : (
              <Box className='opacity-50'>
                <Icon icon='material-symbols:info' width='20' height='20' />
              </Box>
            )}

            <Box className='flex items-center'>
              <span className='font-semibold'>{currentDate}</span>/
              <span>{TotalDate}</span>
            </Box>
          </Box> */}
        </Box>
      </Box>
    </Box>
  );
};

export default AttendanceAvatar;
