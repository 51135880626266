import React, { useEffect, useState } from 'react';
import { Box, Grid } from '@mui/material';
import GlassCard from '../../../../components/small/GlassCard.tsx';
import FmSearchableSelect from '../../../../components/_mui/FmSearchableSelect.tsx';
import FmTextField from '../../../../components/_mui/FmTextField.tsx';
import { useForm } from 'react-hook-form';
import useLoader from '../../../../components/helpers/UseLoader.tsx';
import { toast } from 'react-toastify';
import { useNavigate, useParams } from 'react-router-dom';
import FmDatePicker from '../../../../components/_mui/FmDatePicker.tsx';
import ActionButtons from '../../../../components/_form/ActionButtons.tsx';
import dayjs from 'dayjs';
import { TicketValidationSchema } from '../../../../utils/CommonvalidationSchemas.tsx';
import { yupResolver } from '@hookform/resolvers/yup';
import useCommonFetchApi from '../../../../components/helpers/useCommonFetchApi.tsx';
import { SkeletonLoaderRow } from '../../../../components/page/SkeletonLoader.tsx';

const UpdateTicket = (props) => {
  const { crud, fetchData, onClose } = props;
  const { id } = useParams();
  const {
    editLoading,
    startCreateLoading,
    stopCreateLoading,
    createLoading,
    stopEditLoading,
    startEditLoading,
  } = useLoader();

  const [params, setParams] = useState({});
  const [openCreate, setOpenCreate] = useState(false);
  const [editingRowId, setEditingRowId] = useState(null);
  const [loading, setLoading] = useState(false);
  const [ticketData, setTicketData] = useState({});
  const [editingRowData, setEditingRowData] = useState(null);
  const navigate = useNavigate();
  const { handleSubmit, control, setValue, reset, watch } = useForm({
    mode: 'onChange',
    resolver: yupResolver(TicketValidationSchema),
  });
  const { data: resources } = useCommonFetchApi('employees');
  const viewDataFetch = async () => {
    setLoading(true);
    await crud.getSingle('tickets', id, (err, res) => {
      if (res?.status === 200) {
        setTicketData(res?.data);
        Object.entries({
          ...res?.data,
        }).forEach(([key, value]) => {
          setValue(key, value);
        });
        setLoading(false);
      }
    });
  };

  const handleSubmitTickets = async (values) => {
    startEditLoading();
    const combinedData = {
      requestModeId: values?.requestModeId || 0,
      requesterPhone: values?.requesterPhone || '',
      requesterName: values?.requesterName || '',
      contractId: values?.contractId || 0,
      buildingId: values?.buildingId || 0,
      blockId: values?.blockId || 0,
      floorId: values?.floorId || 0,
      unitId: values?.unitId || 0,
      subUnitId: values?.subUnitId || 0,
      assetId: values?.assetId || 0,
      callClassificationId: values?.callClassificationId || 0,
      callerTypeId: values?.callerTypeId || 1,
      callTypeId: values?.callTypeId || 1,
      resolveDate: dayjs(values?.resolveDate).format('YYYY-MM-DD'),
      ticketStatusId: values?.ticketStatusId || 0,
      categoryId: values?.categoryId || 0,
      subCategoryId: values?.subCategoryId || 0,
      issueId: values?.issueId || 0,
      priorityId: values?.priorityId || 0,
      technicianId: values?.technicianId || 0,
      supervisorId: values?.supervisorId || 0,
      requesterEmail: values?.requesterEmail || '',
      unitType: values?.unitType || '',
      customerReferenceNumber: values?.customerReferenceNumber || '',
      clientId: values?.clientId || 0,
      siteId: values?.siteId || 0,
      aliasName: values?.aliasName || '',
      description: values?.description || '',
      specialComments: values?.specialComments || '',
      wingId: values?.wingId || 0,
      slaId: values?.slaId || 0,
      status: values?.status || 1,
      resources: [],
      attachments:
        values?.attachments?.map((att) => ({
          ticketAttachmentId: att?.ticketAttachmentId || 0,
          attachmentId: att?.attachmentId || 0,
          status: att?.status || 1,
        })) || [],
    };
    await crud.update('tickets', id, combinedData, (err, res) => {
      if (res?.status === 200) {
        toast.success('Ticket Updated Successfully');
        stopEditLoading();
        fetchData();
        setEditingRowData(null);
        setEditingRowId(null);
      } else {
        stopEditLoading();
      }
    });
  };

  useEffect(() => {
    if (id) {
      viewDataFetch();
    }
  }, [id]);

  const handleCreateClose = () => {
    reset();
    setOpenCreate(false);
  };

  const handleDelete = async (props) => {
    await crud.remove('ticketnotes', props?.ticketNoteId, (_err, res) => {
      if (res?.status === 204) {
        toast.success('Ticket Note Deleted Successfully');
        fetchData();
      }
    });
  };
  ////////////
  const handleFilterChange = (filterKey, value) => {
    setParams((prev) => ({
      ...prev,
      [filterKey]: Array.isArray(value) ? value : [value], // Ensure the value is always an array
    }));
  };

  const handleContractChange = (event) => {
    const selectedContractorIds = event.contractId; // Adjust as per your input event structure
    handleFilterChange('contractIds', selectedContractorIds);
  };

  const handleBuildingChange = (event) => {
    const selectedBuildingIds = event.buildingId; // Adjust as per your input event structure
    handleFilterChange('buildingIds', selectedBuildingIds);
  };
  const handleFloorChange = (event) => {
    const selectedFloorIds = event.floorId; // Adjust as per your input event structure
    handleFilterChange('floorIds', selectedFloorIds);
  };

  const handleProjectChange = (event) => {
    const selectedId = event.projectId; // Adjust as per your input event structure
    handleFilterChange('projectId', selectedId);
  };

  const handleBlockChange = (event) => {
    const selectedId = event.blockId; // Adjust as per your input event structure
    handleFilterChange('blockId', selectedId);
  };
  const handleUnitChange = (event) => {
    const selectedId = event.unitId; // Adjust as per your input event structure
    handleFilterChange('unitId', selectedId);
  };

  const handleCategoryChange = (event) => {
    const selectedId = event.categoryId; // Adjust as per your input event structure
    handleFilterChange('categoryId', selectedId);
  };

  const handleEmailChange = (event) => {
    const selectedValue = event?.target?.value; // Adjust as per your input event structure
    handleFilterChange('email', selectedValue);
  };

  const handlePhoneChange = (event) => {
    const selectedValue = event?.target?.value; // Adjust as per your input event structure
    handleFilterChange('phone', selectedValue);
  };

  return (
    <>
      {loading ? (
        <SkeletonLoaderRow count={15} />
      ) : (
        <Box>
          <form onSubmit={handleSubmit(handleSubmitTickets)}>
            <GlassCard className='mb-5 p-3'>
              <h3 className='font-bold mb-3'>Caller Details</h3>
              <Grid container spacing={2}>
                <Grid item md={3}>
                  <FmSearchableSelect
                    name='requestModeId'
                    control={control}
                    apiUrl='requestmodes'
                    valueField='requestModeId'
                    headerField={['Request Mode Name', 'Request Mode Code']}
                    labelField={['requestModeName', 'requestModeCode']}
                    showField={['requestModeName']}
                    label='Request Mode'
                    defaultValue={{
                      requestModeId: ticketData?.requestModeId,
                      requestModeName: ticketData?.requestModeName,
                    }}
                  />
                </Grid>
                <Grid item md={3}>
                  <FmTextField
                    label='Caller Name'
                    name='requesterName'
                    control={control}
                  />
                </Grid>
                <Grid item md={3}>
                  <FmTextField
                    label='Caller Phone'
                    name='requesterPhone'
                    control={control}
                    onChange={handlePhoneChange}
                  />
                </Grid>
                <Grid item md={3}>
                  <FmTextField
                    label='Caller Email'
                    name='requesterEmail'
                    control={control}
                    onChange={handleEmailChange}
                  />
                </Grid>
                <Grid item md={3}>
                  <FmTextField
                    label='Unit Type'
                    name='unitType'
                    control={control}
                  />
                </Grid>
                <Grid item md={3}>
                  <FmTextField
                    label='Customer Reference Number'
                    name='customerReferenceNumber'
                    control={control}
                  />
                </Grid>
              </Grid>
            </GlassCard>

            <GlassCard className='mb-5 p-3'>
              <h3 className='font-bold mb-3'>Client Details</h3>
              <Grid container spacing={2}>
                <Grid item md={3}>
                  <FmSearchableSelect
                    name='clientId'
                    control={control}
                    apiUrl='clients'
                    valueField='clientId'
                    labelField={['clientName', 'clientCode']}
                    showField={['clientName', 'clientCode']}
                    label='Clients'
                    defaultValue={{
                      clientId: ticketData?.clientId,
                      clientName: ticketData?.clientName,
                    }}
                  />
                </Grid>
                <Grid item md={3}>
                  <FmSearchableSelect
                    name='contractId'
                    control={control}
                    apiUrl='contracts'
                    valueField='contractId'
                    headerField={['Contract Code', 'Contract Name']}
                    labelField={['contractCode', 'contractName']}
                    showField={['contractName']}
                    label={'Contracts'}
                    onChangeProp={handleContractChange}
                    defaultValue={{
                      contractId: ticketData?.contractId,
                      contractName: ticketData?.contractName,
                    }}
                  />
                </Grid>

                <Grid item md={3}>
                  <FmSearchableSelect
                    name='siteId'
                    control={control}
                    apiUrl='sites'
                    valueField='siteId'
                    labelField={['siteName', 'siteCode']}
                    showField={['siteName', 'siteCode']}
                    label='Sites'
                    defaultValue={{
                      siteId: ticketData?.siteId,
                      siteName: ticketData?.siteName,
                    }}
                  />
                </Grid>
                <Grid item md={3}>
                  <FmSearchableSelect
                    name='buildingId'
                    control={control}
                    apiUrl='buildings'
                    valueField='buildingId'
                    queryparam='contractIds'
                    queryparamValue={params?.contractIds}
                    onChangeProp={handleBuildingChange}
                    headerField={['Building Code', 'Building Name']}
                    labelField={['buildingCode', 'buildingName']}
                    showField={['buildingName']}
                    label='Building'
                    required
                    defaultValue={{
                      buildingId: ticketData?.buildingId,
                      buildingName: ticketData?.buildingName,
                    }}
                  />
                </Grid>
                <Grid item md={3}>
                  <FmTextField
                    label='Alias Name'
                    name='aliasName'
                    control={control}
                  />
                </Grid>
                <Grid item md={3}>
                  <FmSearchableSelect
                    name='blockId'
                    control={control}
                    apiUrl='blocks'
                    valueField='blockId'
                    queryparam='buildingId'
                    queryparamValue={params?.buildingId}
                    onChangeProp={handleBlockChange}
                    headerField={['Block Name', 'Block Code']}
                    labelField={['blockName', 'blockCode']}
                    showField={['blockName']}
                    label='Block'
                    defaultValue={{
                      blockId: ticketData?.blockId,
                      blockName: ticketData?.blockName,
                    }}
                  />
                </Grid>
                <Grid item md={3}>
                  <FmSearchableSelect
                    name='floorId'
                    control={control}
                    apiUrl='floors'
                    valueField='floorId'
                    queryparam='blockId'
                    queryparamValue={params?.blockId}
                    onChangeProp={handleFloorChange}
                    headerField={['Floor Name', 'Floor Code']}
                    labelField={['floorName', 'floorCode']}
                    showField={['floorName']}
                    label='Floor'
                    defaultValue={{
                      floorId: ticketData?.floorId,
                      floorName: ticketData?.floorName,
                    }}
                  />
                </Grid>

                <Grid item md={3}>
                  <FmSearchableSelect
                    name='wingId'
                    control={control}
                    apiUrl='wings'
                    valueField='wingId'
                    labelField={['wingName', 'wingCode']}
                    showField={['wingName', 'wingCode']}
                    label='Wings'
                    defaultValue={{
                      wingId: ticketData?.wingId,
                      wingName: ticketData?.wingName,
                    }}
                  />
                </Grid>

                <Grid item md={3}>
                  <FmSearchableSelect
                    name='unitId'
                    control={control}
                    apiUrl='baseunits'
                    valueField='baseUnitId'
                    queryparam='floorId'
                    onChangeProp={handleUnitChange}
                    queryparamValue={params?.floorId}
                    headerField={['Unit Name', 'Unit Code']}
                    labelField={['baseUnitName', 'baseUnitCode']}
                    showField={['baseUnitName']}
                    label='Unit'
                    defaultValue={{
                      baseUnitId: ticketData?.unitId,
                      baseUnitName: ticketData?.unitName,
                    }}
                  />
                </Grid>
                <Grid item md={3}>
                  <FmSearchableSelect
                    name='subUnitId'
                    control={control}
                    apiUrl='baseunits'
                    valueField='baseUnitId'
                    queryparam='unitId'
                    queryparamValue={params?.unitId}
                    headerField={['Unit Name', 'Unit Code']}
                    labelField={['baseUnitName', 'baseUnitCode']}
                    showField={['baseUnitName']}
                    label='Sub Unit'
                    defaultValue={{
                      subUnitId: ticketData?.subUnitId,
                      baseUnitName: ticketData?.subUnitName,
                    }}
                  />
                </Grid>
                <Grid item md={3}>
                  <FmSearchableSelect
                    name='assetId'
                    control={control}
                    apiUrl='assets'
                    valueField='assetId'
                    headerField={['Asset Name', 'Asset Code']}
                    labelField={['assetName', 'assetCode']}
                    showField={['assetName', 'assetCode']}
                    label='Assets'
                    defaultValue={{
                      assetId: ticketData?.assetId,
                      assetName: ticketData?.assetName,
                    }}
                  />
                </Grid>
              </Grid>
            </GlassCard>

            <GlassCard className='mb-5 p-3'>
              <h3 className='font-bold mb-3'>Issue Details</h3>
              <Grid container spacing={3}>
                <Grid item md={3}>
                  <FmSearchableSelect
                    name='callClassificationId'
                    control={control}
                    apiUrl='callclassifications'
                    valueField='callClassificationId'
                    headerField={[
                      'Call Classification Name',
                      'Call Classification Code',
                    ]}
                    labelField={[
                      'callClassificationName',
                      'callClassificationCode',
                    ]}
                    showField={['callClassificationName']}
                    label='Call Classification'
                    defaultValue={{
                      callClassificationId: ticketData?.callClassificationId,
                      callClassificationName:
                        ticketData?.callClassificationName,
                    }}
                  />
                </Grid>
                <Grid item md={3}>
                  <FmSearchableSelect
                    name='callTypeId'
                    control={control}
                    apiUrl='tickets/call-types'
                    valueField='callTypeId'
                    headerField={['Call Type Name']}
                    labelField={['callTypeName']}
                    showField={['callTypeName']}
                    label='Call Type'
                    defaultValue={{
                      callTypeId: ticketData?.callTypeId,
                      callTypeName: ticketData?.callTypeName,
                    }}
                  />
                </Grid>

                <Grid item md={3}>
                  <FmDatePicker
                    name='resolveDate'
                    label='Resolve Date'
                    control={control}
                  />
                </Grid>
                <Grid item md={3}>
                  <FmSearchableSelect
                    name='categoryId'
                    control={control}
                    apiUrl='categories'
                    valueField='categoryId'
                    onChangeProp={handleCategoryChange}
                    headerField={['Category Name', 'Category Code']}
                    labelField={['categoryName', 'categoryCode']}
                    showField={['categoryName']}
                    label='Category'
                    defaultValue={{
                      categoryId: ticketData?.categoryId,
                      categoryName: ticketData?.categoryName,
                    }}
                  />
                </Grid>
                <Grid item md={3}>
                  <FmSearchableSelect
                    name='categorySubId'
                    control={control}
                    apiUrl='categories'
                    valueField='categoryId'
                    queryparam='categoryId'
                    queryparamValue={params?.categoryId}
                    headerField={['Sub Category Name', 'Sub Category Code']}
                    labelField={['categoryName', 'categoryCode']}
                    showField={['categoryName']}
                    label='Sub Category'
                    defaultValue={{
                      categoryId: ticketData?.categorySubId,
                      categoryName: ticketData?.subCategoryName,
                    }}
                  />
                </Grid>

                <Grid item md={3}>
                  <FmSearchableSelect
                    name='issueId'
                    control={control}
                    apiUrl='issues'
                    valueField='issueId'
                    headerField={['Issue Name', 'Issue Code']}
                    labelField={['issueName', 'issueCode']}
                    showField={['issueName']}
                    label='Issue'
                    defaultValue={{
                      issueId: ticketData?.issueId,
                      issueName: ticketData?.issueName,
                    }}
                  />
                </Grid>
                <Grid item md={3}>
                  <FmSearchableSelect
                    name='slaId'
                    control={control}
                    apiUrl='slas'
                    valueField='slaId'
                    headerField={['SLA Name', 'SLA Code']}
                    labelField={['slaName', 'slaCode']}
                    showField={['slaName']}
                    label='SLA'
                    defaultValue={{
                      slaId: ticketData?.slaId,
                      slaName: ticketData?.slaName,
                    }}
                  />
                </Grid>
                <Grid item md={3}>
                  <FmSearchableSelect
                    name='priorityId'
                    control={control}
                    apiUrl='priorities'
                    valueField='priorityId'
                    headerField={['Priority', 'Priority Code']}
                    labelField={['priorityName', 'priorityCode']}
                    showField={['priorityName']}
                    label='Priority'
                    defaultValue={{
                      priorityId: ticketData?.priorityId,
                      priorityName: ticketData?.priorityName,
                    }}
                  />
                </Grid>
                <Grid item md={6}>
                  <FmTextField
                    name='description'
                    control={control}
                    label='description'
                    multiline={true}
                    rows={2}
                    maxRows={3}
                  />
                </Grid>
                <Grid item md={6}>
                  <FmTextField
                    name='specialComments'
                    control={control}
                    label='Special Comment'
                    multiline={true}
                    rows={2}
                    maxRows={3}
                  />
                </Grid>
              </Grid>
            </GlassCard>

            <GlassCard className='mb-5 p-3'>
              <h3 className='font-bold mb-3'>Assignment</h3>

              <Grid container spacing={3}>
                <Grid item md={3}>
                  <FmSearchableSelect
                    name='ticketStatusId'
                    control={control}
                    apiUrl='ticketstatuses'
                    valueField='ticketStatusId'
                    headerField={['Status Name', 'Status Code']}
                    labelField={['ticketStatusName', 'ticketStatusCode']}
                    showField={['ticketStatusName']}
                    label='Status'
                    defaultValue={{
                      ticketStatusId: ticketData?.ticketStatusId,
                      ticketStatusName: ticketData?.ticketStatusName,
                    }}
                  />
                </Grid>
                <Grid item md={3}>
                  <FmSearchableSelect
                    name='technicianId'
                    control={control}
                    apiUrl='employees'
                    valueField='employeeId'
                    headerField={['Name', 'Code', 'Email']}
                    labelField={['firstName', 'employeeCode', 'emailId']}
                    showField={['firstName', 'employeeCode', 'emailId']}
                    label='Technician Name'
                    defaultValue={{
                      technicianId: ticketData?.technicianId,
                      firstName: ticketData?.technicianName,
                    }}
                  />
                </Grid>
                <Grid item md={3}>
                  <FmSearchableSelect
                    name='supervisorId'
                    control={control}
                    apiUrl='employees'
                    valueField='employeeId'
                    headerField={['Code', 'Name', 'Email']}
                    labelField={['employeeCode', 'fullName', 'emailId']}
                    showField={['employeeCode', 'fullName', 'emailId']}
                    label='Supervisor'
                    defaultValue={{
                      employeeId: ticketData?.supervisorId,
                      fullName: ticketData?.supervisorName,
                    }}
                  />
                </Grid>
              </Grid>
            </GlassCard>

            <ActionButtons
              onSubmit={handleSubmit(handleSubmitTickets)}
              onCancel={onClose}
              onReset={reset}
              submitLoading={editLoading}
              cancelLoading={false}
              submitText='Update'
            />
          </form>
        </Box>
      )}
    </>
  );
};

export default UpdateTicket;
