import React from 'react';
import { useParams } from 'react-router-dom';
import FmMRTDataTable from '../../../../../components/FmMRTDataTable.tsx';
import useTableLogic from '../../../../../components/helpers/MRTUseTableLogic.tsx';
import FmViewDateField from '../../../../../components/_mui/FmViewDateField.tsx';
import Typography from '@mui/material/Typography';
import CurrencyView from '../../../../../components/_mui/FmCurrencyView.tsx';
import GlassCard from '../../../../../components/small/GlassCard.tsx';

const SchedulesView = () => {
  const { id } = useParams();
  const {
    rows,
    setRows,
    rowCount,
    isLoading,
    pagination,
    sorting,
    columnFilters,
    globalFilter,
    tableRecordCounts,
    setPagination,
    setSorting,
    setColumnFilters,
    setGlobalFilter,
    fetchData,
    setSearchKeyword,
    searchKeyword,
  } = useTableLogic(`internalbudgetschedules`, ['salesOrderId'], [id]);

  const columns = [
    {
      accessorKey: 'divisionName',
      header: 'Division',
      enableEditing: true,
      Cell: ({ row }) => {
        return row?.original?.divisionName || '';
      },
    },

    {
      accessorKey: 'startDate',
      header: 'Start Date',
      enableEditing: true,
      Cell: ({ row }) => {
        return <FmViewDateField value={row?.original?.startDate} />;
      },
    },

    {
      accessorKey: 'endDate',
      header: 'End Date',
      enableEditing: true,
      Cell: ({ row }) => {
        return <FmViewDateField value={row?.original?.endDate} />;
      },
    },
    {
      accessorKey: 'amountToBeInvoiced',
      header: 'Amount to be Invoiced',
      enableEditing: true,
      Cell: ({ row }) => {
        return <CurrencyView value={row?.original?.amountToBeInvoiced} />;
      },
    },
    {
      accessorKey: 'dailyCost',
      header: 'Daily Cost',
      enableEditing: true,
      Cell: ({ row }) => {
        return <CurrencyView value={row?.original?.dailyCost} />;
      },
    },
    {
      accessorKey: 'days',
      header: 'Days',
      enableEditing: true,
      Cell: ({ row }) => {
        return row?.original?.days;
      },
    },
    {
      accessorKey: 'invoicedAmount',
      header: 'Invoiced Amount',
      size: 100,
      Cell: ({ row }) => {
        return <CurrencyView value={row?.original?.invoicedAmount} />;
      },
    },
    {
      accessorKey: 'invoiceRef',
      header: 'Invoice Reference',
      size: 100,
      Cell: ({ row }) => {
        return <Typography>{row?.original?.invoiceRef} </Typography>;
      },
    },
    {
      accessorKey: 'paymentAmount',
      header: 'Payment Amount',
      size: 100,
      Cell: ({ row }) => {
        return <CurrencyView value={row?.original?.paymentAmount} />;
      },
    },
    {
      accessorKey: 'paymentRef',
      header: 'Payment Reference',
      size: 100,
      Cell: ({ row }) => {
        return <Typography>{row?.original?.paymentRef}</Typography>;
      },
    },
  ];

  return (
    <GlassCard className='h-[400px]'>
      <FmMRTDataTable
        rows={rows}
        columns={columns}
        enableRowSelection={false}
        rowCount={tableRecordCounts}
        fetchData={fetchData}
        pagination={pagination}
        sorting={sorting}
        columnFilters={columnFilters}
        buttonName='Approve'
        buttonColor='secondary'
        globalFilter={globalFilter}
        setPagination={setPagination}
        setSorting={setSorting}
        setColumnFilters={setColumnFilters}
        setGlobalFilter={setGlobalFilter}
        isLoading={isLoading}
        setSearchKeyword={setSearchKeyword}
        searchKeyword={searchKeyword}
        showSerialNumber={true}
        muiTableContainerProps={{
          sx: {
            maxHeight: '100%',
            flex: 1,
          },
        }}
        rightPinning={['Supplier']}
      />
    </GlassCard>
  );
};

export default SchedulesView;
