import { yupResolver } from '@hookform/resolvers/yup';
import { Icon } from '@iconify/react/dist/iconify.js';
import { Box, Button, Stack } from '@mui/material';
import dayjs from 'dayjs';
import React, { useEffect, useRef, useState } from 'react';
import { useForm } from 'react-hook-form';
import { useHotkeys } from 'react-hotkeys-hook';
import { useParams } from 'react-router-dom';
import { toast } from 'react-toastify';
import Crud_Service from '../../../../../apis/CrudService.jsx';
import {
  ViewField,
  ViewTable,
} from '../../../../../components/_form/FormElements.jsx';
import TableActionButtons from '../../../../../components/_form/TableActionButtons.jsx';
import FmAutoComplete from '../../../../../components/_mui/FmAutoComplete.tsx';
import FmSearchableSelect from '../../../../../components/_mui/FmSearchableSelect.tsx';
import FmTextField from '../../../../../components/_mui/FmTextField.tsx';
import MuiDialogOne from '../../../../../components/_mui/MuiDialogOne.jsx';
import FmMRTDataTable from '../../../../../components/FmMRTDataTable.tsx';
import useTableLogic from '../../../../../components/helpers/MRTUseTableLogic.tsx';
import useCommonFetchApi from '../../../../../components/helpers/useCommonFetchApi.tsx';
import GlassCard from '../../../../../components/small/GlassCard.tsx';
import { InternalInvoiceItemSchema } from '../../../../../utils/CommonvalidationSchemas.tsx';
import DataGridHeaderTooltip from '../../../../../components/datagrid/DataGridHeaderTooltip.tsx';
import CurrencyView from '../../../../../components/_mui/FmCurrencyView.tsx';
import FmCurrencyTextField from '../../../../../components/_mui/FmCurrencyTextField.tsx';
import {
  ReportTypes,
  internalInvoiceStatusEnum,
} from '../../../../../utils/CommonVariables.tsx';
import ReportDesigner from '../../../../../components/ReportDesigner/ReportDesigner.tsx';

const EditListInternalInvoicesAR = (props) => {
  const { invoiceStatusId } = props;
  const [viewDetails, setViewDetails] = useState(false);
  const [getData, setGetData] = useState([]);
  const { id } = useParams();
  const {
    control,
    reset,
    setValue,
    formState: { isValid },
    getValues,
    watch,
  } = useForm({
    resolver: yupResolver(InternalInvoiceItemSchema),
    mode: 'all',
  });
  const {
    rows,
    setRows,
    isLoading,
    pagination,
    sorting,
    columnFilters,
    globalFilter,
    tableRecordCounts,
    setPagination,
    setSorting,
    setColumnFilters,
    setGlobalFilter,
    fetchData,
    setSearchKeyword,
    searchKeyword,
  } = useTableLogic(`internalinvoiceitems?internalInvoiceId=${id}`);
  const [editingRowId, setEditingRowId] = useState(null);
  const [editingRowData, setEditingRowData] = useState(null);
  const [filesData, setFilesData] = useState({
    fileId: null,
    originalFileName: '',
  });

  const [rowState, setRowState] = useState({
    editingRowId: null,
    newRowId: null,
    isCreatingRow: false,
  });
  const firstColumnInputRef = useRef(null);
  const [stateError, setStateError] = useState(false);
  const [editingRowIndex, setEditingRowIndex] = useState(null);
  const [loader, setLoader] = useState(false);
  const crud = new Crud_Service();
  const { data: unitofmeasurements } = useCommonFetchApi('unitofmeasurements');
  const [selectedContract, setSelectedContract] = useState({});
  const [tableData, setTableData] = useState(rows); // initialData is your table's initial rows
  const [formValues, setFormValues] = useState({});
  const [openPrintScreen, setOpenPrintScreen] = useState(false);
  const [billingValues, setBillingValues] = useState([
    { name: 'Items', value: 0 },
    { name: 'Gross', value: '-' },
    { name: 'Discount', value: 0 },
    { name: 'VAT', value: 0 },
    { name: 'Total', value: 0 },
  ]);
  const handlePrint = () => {
    setOpenPrintScreen(true);
  };
  useEffect(() => {
    selectedContract && setValue('dueDate', selectedContract?.contractEndDate);
  }, [selectedContract]);

  useEffect(() => {
    setTableData(rows);
  }, [rows]);

  const GetViewDetails = (value) => {
    const getvalueArray = getData[value];
    return getvalueArray;
  };

  useHotkeys('Enter', () => handleAddNewRow());

  useHotkeys(
    'Tab',
    (event) => {
      if (event.key !== 'Tab') return;
      if (stateError) {
        event.preventDefault();
        return;
      }
      const totalRows = rows.length;
      const nextRowIndex = editingRowIndex + 1;

      if (
        nextRowIndex < totalRows &&
        stateError === false &&
        loader === false
      ) {
        handleEdit(rows[nextRowIndex]);
        setEditingRowIndex(nextRowIndex);
      }
    },
    [editingRowIndex, rows, stateError, loader, isValid]
  );

  const previousValues = useRef(getValues());

  const handleBlur = () => {
    const currentValues = getValues();
    const divisionId = getValues('divisionId');
    if (!divisionId) {
      firstColumnInputRef.current.focus();
      return;
    }

    const hasChanges = Object.keys(currentValues).some(
      (key) => currentValues[key] !== previousValues.current[key]
    );

    if ((hasChanges && isValid) || (hasChanges && editingRowId)) {
      apiCallAsync(currentValues); // Call the API with the new values
      previousValues.current = currentValues; // Update previous values
    }
  };

  //api call
  const apiCallAsync = async (values) => {
    setLoader(true);
    const convertedValues = {
      ...values,
      deductionAmount: Number(values?.deductionAmount) || 0,
      internalInvoiceId: id,
      rate: Number(values?.rate) || 0,
    };

    const updateCombinedData = {
      ...values,
      internalInvoiceId: id,
      rate: Number(values?.rate) || 0,
      deductionAmount: Number(values?.deductionAmount) || 0,
      status: 2,
    };

    if (editingRowId === null) {
      await crud.create('internalinvoiceitems', convertedValues, (err, res) => {
        if (res?.status === 201) {
          setEditingRowId(res?.data?.internalInvoiceItemId);
        } else {
        }
      });
    } else {
      await crud.update(
        'internalinvoiceitems',
        editingRowId,
        updateCombinedData,
        (err, res) => {
          if (res?.status === 200) {
            if (rows.some((row) => row.isNew)) {
              const updatedRow = res?.data;
              const updatedRows = [...rows];
              updatedRows[editingRowIndex] = {
                ...updatedRow,
                isEditable: true,
                isNew: true,
              };
              setRows(updatedRows);
              setStateError(false);
              setLoader(false);
            } else {
              setStateError(false);
              setLoader(false);
              const updatedRow = res?.data;
              const updatedRows = [...rows];

              updatedRows[editingRowIndex] = updatedRow;

              setRows(updatedRows);
            }
          } else {
            setStateError(true);
          }
        }
      );
    }
  };

  const handleCancel = (row) => {
    if (row?.original?.internalInvoiceItemId === rowState?.newRowId) {
      setRows((prevRows) => {
        if (prevRows?.length > 0) {
          return prevRows?.slice(0, -1);
        }
        return prevRows;
      });

      setRowState((prevState) => ({
        ...prevState,
        newRowId: null,
        isCreatingRow: false,
      }));
    } else {
      setEditingRowId(null);
    }
    reset();
  };

  const handleEdit = (row) => {
    if (invoiceStatusId === internalInvoiceStatusEnum?.Approved || invoiceStatusId === internalInvoiceStatusEnum?.InvoiceGenerated) {
      return;
    }
    reset();
    if (rowState?.newRowId) {
      setRows((prevRows) => {
        if (prevRows?.length > 0) {
          return prevRows?.slice(0, -1);
        }
        return prevRows;
      });
      setRowState((prevState) => ({
        ...prevState,
        newRowId: null,
        isCreatingRow: false,
      }));
    }
    setEditingRowId(row?.internalInvoiceItemId);
    Object.entries({
      ...row,
    }).forEach(([key, value]) => {
      setValue(key, value);
    });
    setEditingRowData({ ...row });
  };

  const handleDelete = async (props) => {
    await crud.remove(
      'internalinvoiceitems',
      props?.internalInvoiceItemId,
      (_err, res) => {
        if (res?.status === 204) {
          toast.success('Internal Invoice Item Deleted Successfully');
          fetchData();
        } else {
        }
      }
    );
  };

  useEffect(() => {
    if (editingRowIndex !== null && firstColumnInputRef.current && !isLoading) {
      firstColumnInputRef.current.focus();
    }
  }, [editingRowIndex, isLoading]);

  const ActionData = [
    {
      name: 'Delete',
      icon: <Icon icon='mi:delete' />,
      onClick: (props) => {
        handleDelete(props);
      },
      danger: true,
    },
  ];

  const handleBlurAndFetch = async () => {
    setLoader(true);
    const updatedRow = getValues(); // Get the latest values from the form
    const updatedRows = [...rows]; // Clone the rows[0] to avoid direct mutation

    updatedRows[editingRowIndex] = updatedRow;
    setRows(updatedRows);
    const values = getValues();
    const { internalInvoiceItemId, ...restValues } = values; // Destructure and exclude salesQuotationItemId

    const updateCombinedData = {
      ...restValues,
      status: 2,
      internalInvoiceId: id,
      deductionAmount: Number(values?.deductionAmount) || 0,
      dueDate: dayjs(values?.dueDate).format('YYYY-MM-DD'),
      fileId: filesData?.fileId,
    };
    await crud.update(
      'internalinvoiceitems',
      editingRowId,
      updateCombinedData,
      (err, res) => {
        if (res?.status === 200) {
          const totalRows = rows.length;
          if (editingRowIndex <= totalRows && rows.some((row) => row.isNew)) {
            const updatedRow = res?.data;
            const updatedRows = [...rows];
            updatedRows[editingRowIndex] = {
              ...updatedRow,
              isEditable: true,
              isNew: true,
            };
            setRows(updatedRows);
            handleAddNewRow();
          } else {
            const updatedRow = res?.data;
            const updatedRows = [...rows];
            updatedRows[editingRowIndex] = {
              ...updatedRow,
              isEditable: false,
              isNew: false,
            };

            setRows(updatedRows);
            setStateError(false);
            setLoader(false);
          }
        } else {
          setStateError(true);
        }
      }
    );
  };

  const columns = [
    {
      accessorKey: 'divisionId',
      header: 'Division',
      enableEditing: true,
      Cell: ({ row }) => {
        const isNewRow =
          row?.original?.internalInvoiceItemId === rowState?.newRowId;
        const isEditing =
          editingRowId === row?.original?.internalInvoiceItemId || isNewRow;
        return isEditing ? (
          <FmSearchableSelect
            name='divisionId'
            control={control}
            apiUrl='divisions'
            valueField={'divisionId'}
            labelField={['divisionCode', 'divisionName']}
            showField={['divisionCode', 'divisionName']}
            inputRef={firstColumnInputRef}
            onBlur={handleBlur}
            label='Division'
            defaultValue={{
              divisionId: editingRowData?.divisionId,
              divisionName: editingRowData?.divisionName,
            }}
          />
        ) : (
          row?.original?.divisionName || ''
        );
      },
    },
    {
      accessorKey: 'description',
      header: 'Description',
      enableEditing: true,
      Cell: ({ row }) => {
        const isNewRow =
          row?.original?.internalInvoiceItemId === rowState?.newRowId;
        const isEditing =
          editingRowId === row?.original?.internalInvoiceItemId || isNewRow;
        return isEditing ? (
          <FmTextField
            name='description'
            control={control}
            onBlur={handleBlur}
          />
        ) : (
          row?.original?.description
        );
      },
    },

    {
      accessorKey: 'unitOfMeasurementId',
      header: (
        <DataGridHeaderTooltip name={'UOM'} title={'Unit of Measurement'} />
      ),
      enableEditing: true,
      size: 200,
      Cell: ({ row }) => {
        const isNewRow =
          row?.original?.internalInvoiceItemId === rowState?.newRowId;
        const isEditing =
          editingRowId === row?.original?.internalInvoiceItemId || isNewRow;
        return isEditing ? (
          <Box sx={{ width: '100%' }}>
            <FmAutoComplete
              name='unitOfMeasurementId'
              control={control}
              options={unitofmeasurements}
              displayField='unitOfMeasurementName'
              optionFields={['unitOfMeasurementName']}
              valueKey='unitOfMeasurementId'
              onBlur={handleBlur}
            />
          </Box>
        ) : (
          row?.original?.unitOfMeasurementName
        );
      },
    },
    {
      accessorKey: 'rate',
      header: 'Rate',
      enableEditing: true,
      Cell: ({ row }) => {
        const isNewRow =
          row?.original?.internalInvoiceItemId === rowState?.newRowId;
        const isEditing =
          editingRowId === row?.original?.internalInvoiceItemId || isNewRow;
        return isEditing ? (
          <FmCurrencyTextField
            name='rate'
            control={control}
            label='Rate'
            onBlur={handleBlur}
            labelRemove={true}
            onChange={(e) =>
              setFormValues((prevValues) => ({
                ...prevValues,
                rate: e.target.value,
              }))
            }
          />
        ) : (
          <CurrencyView value={row?.original?.rate} />
        );
      },
    },

    {
      accessorKey: 'gross',
      header: 'Gross',
      enableEditing: true,
      Cell: ({ row }) => {
        const isNewRow =
          row?.original?.internalInvoiceItemId === rowState?.newRowId;
        const isEditing =
          editingRowId === row?.original?.internalInvoiceItemId || isNewRow;
        const gross = watch('rate');
        return isEditing ? (
          <CurrencyView value={gross} />
        ) : (
          <CurrencyView value={row?.original?.gross} />
        );
      },
    },
    // TODO
    // {
    //   accessorKey: 'tax',
    //   header: 'Tax %',
    //   size: 100,
    //   enableEditing: true,
    //   Cell: ({ row }) => {
    //     const isNewRow =
    //       row?.original?.internalInvoiceItemId === rowState?.newRowId;
    //     const isEditing =
    //       editingRowId === row?.original?.internalInvoiceItemId || isNewRow;
    //     return isEditing ? (
    //       <FmTextField
    //         name='tax'
    //         control={control}
    //         onBlur={handleBlur}
    //         type='decimal'
    //       />
    //     ) : (
    //       row?.original?.tax
    //     );
    //   },
    // },
    {
      accessorKey: 'discount',
      header: 'Discount',
      size: 150,
      enableEditing: true,
      Cell: ({ row }) => {
        const isNewRow =
          row?.original?.internalInvoiceItemId === rowState?.newRowId;
        const isEditing =
          editingRowId === row?.original?.internalInvoiceItemId || isNewRow;
        return isEditing ? (
          <FmCurrencyTextField
            name='discount'
            control={control}
            label='Discount'
            labelRemove={true}
            onBlur={handleBlur}
            onChange={(e) =>
              setFormValues((prevValues) => ({
                ...prevValues,
                discount: e.target.value,
              }))
            }
          />
        ) : (
          <CurrencyView value={row?.original?.discount} />
        );
      },
    },

    {
      accessorKey: 'deductionAmount',
      header: 'Adjustment',
      enableEditing: true,
      Cell: ({ row }) => {
        const isNewRow =
          row?.original?.internalInvoiceItemId === rowState?.newRowId;
        const isEditing =
          editingRowId === row?.original?.internalInvoiceItemId || isNewRow;
        return isEditing ? (
          <FmCurrencyTextField
            name='deductionAmount'
            control={control}
            label='Adjustment'
            labelRemove={true}
            onBlur={handleBlur}
            onChange={(e) =>
              setFormValues((prevValues) => ({
                ...prevValues,
                deductionAmount: e.target.value,
              }))
            }
          />
        ) : (
          <CurrencyView
            value={row?.original?.deductionAmount}
            currencyType={'AED'}
          />
        );
      },
    },
    {
      accessorKey: 'vat',
      header: 'VAT(%)',
      size: 100,
      enableEditing: true,
      Cell: ({ row }) => {
        const isNewRow =
          row?.original?.internalInvoiceItemId === rowState?.newRowId;
        const isEditing =
          editingRowId === row?.original?.internalInvoiceItemId || isNewRow;
        return isEditing ? (
          <FmTextField
            name='vat'
            pattern='Percentage'
            control={control}
            onBlur={handleBlur}
            onChange={(e) =>
              setFormValues((prevValues) => ({
                ...prevValues,
                vat: e.target.value,
              }))
            }
          />
        ) : (
          row?.original?.vat
        );
      },
    },
    {
      accessorKey: 'vatAmount',
      header: 'VAT Amount',
      enableEditing: true,
      Cell: ({ row }) => {
        const isNewRow =
          row?.original?.internalInvoiceItemId === rowState?.newRowId;
        const isEditing =
          editingRowId === row?.original?.internalInvoiceItemId || isNewRow;
        const vatAmount = watch('vatAmount');
        return isEditing ? (
          <ViewField label='vatAmount' title={vatAmount} />
        ) : (
          <CurrencyView value={row?.original?.vatAmount} />
        );
      },
    },
    {
      accessorKey: 'total',
      header: 'Total',
      enableEditing: false,
      Cell: ({ row }) => {
        const isNewRow =
          row?.original?.internalInvoiceItemId === rowState?.newRowId;
        const isEditing =
          editingRowId === row?.original?.internalInvoiceItemId || isNewRow;
        const total = watch('total');
        return isEditing ? (
          <CurrencyView value={total} />
        ) : (
          <CurrencyView value={row?.original?.total} />
        );
      },
    },
    {
      accessorKey: 'remarks',
      header: 'Remarks',
      size: 150,
      enableEditing: true,
      Cell: ({ row }) => {
        const isNewRow =
          row?.original?.internalInvoiceItemId === rowState?.newRowId;
        const isEditing =
          editingRowId === row?.original?.internalInvoiceItemId || isNewRow;
        return isEditing ? (
          <FmTextField
            name='remarks'
            control={control}
            onBlur={handleBlurAndFetch}
          />
        ) : (
          row?.original?.remarks
        );
      },
    },
    {
      header: 'Action',
      enableColumnPinning: true,
      enableEditing: false,
      enableSorting: false,
      Cell: ({ row, table }) => {
        const isNewRow =
          row?.original?.internalInvoiceItemId === rowState?.newRowId;
        const isEditing =
          editingRowId === row?.original?.internalInvoiceItemId || isNewRow;

        const isLastRow = row.index === table.getRowModel().rows.length - 1;

        return (
          <Box className='flex gap-2'>
            {isEditing ? (
              <>
                <Button
                  onClick={() => handleCancel(row)}
                  variant='contained'
                  color='error'
                  className='py-0.5 px-2'
                >
                  Close
                </Button>
                {isLastRow && rowState?.isCreatingRow === false && (
                  <Button
                    onFocus={() => handleAddNewRow()}
                    hidden={true}
                  ></Button>
                )}
              </>
            ) : (
              <Box className='flex gap-2'>
                {!(invoiceStatusId === internalInvoiceStatusEnum?.Approved ||
                  invoiceStatusId === internalInvoiceStatusEnum?.InvoiceGenerated) && (
                    <TableActionButtons
                      Actions={ActionData?.map((action) => ({
                        ...action,
                        onClick: () => action.onClick(row?.original),
                      }))}
                    />
                  )}

                {isLastRow &&
                  !(invoiceStatusId === internalInvoiceStatusEnum?.Approved || invoiceStatusId === internalInvoiceStatusEnum?.InvoiceGenerated) && (
                    <Button
                      variant='contained'
                      color='primary'
                      className='px-2 py-0.5'
                      onClick={() => {
                        setEditingRowIndex(row?.index + 1);
                        handleAddNewRow(row);
                      }}
                    >
                      Add Row
                    </Button>
                  )}
              </Box>
            )}
          </Box>
        );
      },
      muiTableBodyCellProps: {
        sx: {
          borderLeft: '1px solid',
          borderColor: 'border.main',
        },
      },
    },
  ];

  const handleAddNewRow = () => {
    reset();
    const newId = 'new_row_' + Date.now();
    setEditingRowData(null);
    setEditingRowId(null);
    setRowState({
      newRowId: newId,
      isCreatingRow: true,
    });
    setEditingRowIndex(
      editingRowIndex === rows.length ? editingRowIndex + 1 : rows.length
    );
    setRows((prevRows) => [
      ...prevRows,
      {
        internalInvoiceItemId: newId,
        internalInvoiceId: id,
        description: '',
        unitOfMeasurementId: '',
        rate: '',
        gross: '',
        discount: '',
        deductionAmount: '',
        vat: '',
        remaks: '',
        isEditable: true,
        isNew: true,
      },
    ]);
    reset();
  };

  useEffect(() => {
    if (!rows?.length) return; // Exit early if no rows exist
    const rate = Number(watch('rate')) || 0;
    const discount = Number(watch('discount')) || 0;
    const vatPercentage = Number(watch('vat')) || 0;
    const deductionAmount = Number(getValues('deductionAmount')) || 0;

    if (
      editingRowId !== null &&
      (formValues?.discount ||
        formValues?.vat ||
        formValues?.rate ||
        formValues?.deductionAmount)
    ) {
      const vatAmount =
        ((rate - discount - deductionAmount) * vatPercentage) / 100;
      const total = rate - discount - deductionAmount + vatAmount;

      setValue('gross', rate.toFixed(2));
      setValue('vatAmount', vatAmount.toFixed(2));
      setValue('total', total.toFixed(2));
    }

    // Filter rows based on editing state
    const filteredRows = editingRowId
      ? rows.filter((row) => row.internalInvoiceItemId !== editingRowId)
      : rows;
    const sumField = (field) =>
      filteredRows.reduce(
        (total, row) => total + (parseFloat(row[field]) || 0),
        0
      );

    const averageField = (field) =>
      filteredRows.length
        ? (sumField(field) / filteredRows.length).toFixed(2)
        : '0.00';

    const grossSum = sumField('rate');
    const totalSum = sumField('amount');
    const discountAvg = sumField('discount');
    const vatAvg = averageField('vat');

    const editedTotalAmount = parseFloat(watch('amount')) || 0;

    setBillingValues((prev) =>
      prev.map((item) => {
        const fieldValues = {
          Items: rows.length,
          Gross: (grossSum + rate).toFixed(2),
          Discount: (discountAvg + discount).toFixed(2),
          VAT: parseFloat(vatAvg).toFixed(2),
          Total: (totalSum + editedTotalAmount).toFixed(2),
        };
        return fieldValues[item.name] !== undefined
          ? { ...item, value: fieldValues[item.name] }
          : item;
      })
    );
  }, [
    isLoading,
    rows,
    formValues?.discount,
    formValues?.rate,
    formValues?.deductionAmount,
    formValues?.vat,
  ]);

  return (
    <Box className=' flex flex-col flex-1 min-h-0 gap-2'>
      <GlassCard className='flex-1 min-h-0'>
        <FmMRTDataTable
          rows={tableData}
          columns={columns}
          muiTableContainerProps={{
            sx: {
              maxHeight: '100%',
              flex: 1,
            },
          }}
          isCreatingRow={rowState?.isCreatingRow}
          enableRowSelection={false}
          rowCount={tableRecordCounts}
          editingRow={editingRowId}
          pagination={pagination}
          sorting={sorting}
          columnFilters={columnFilters}
          globalFilter={globalFilter}
          setPagination={setPagination}
          showPagination={false}
          setSorting={setSorting}
          fetchData={fetchData}
          setColumnFilters={setColumnFilters}
          setGlobalFilter={setGlobalFilter}
          isLoading={isLoading}
          setSearchKeyword={setSearchKeyword}
          handlePrint={handlePrint}
          disableNewButton={
            invoiceStatusId === internalInvoiceStatusEnum?.Approved ||
              invoiceStatusId === internalInvoiceStatusEnum?.InvoiceGenerated
              ? false
              : true
          }
          searchKeyword={searchKeyword}
          onCreateRow={
            invoiceStatusId === internalInvoiceStatusEnum?.Approved ||
              invoiceStatusId === internalInvoiceStatusEnum?.InvoiceGenerated
              ? undefined
              : handleAddNewRow
          }
          handleRowDoubleClick={(row) => {
            if (invoiceStatusId !== internalInvoiceStatusEnum?.Approved ||
              invoiceStatusId === internalInvoiceStatusEnum?.InvoiceGenerated) {
              handleEdit(row?.original);
              setEditingRowIndex(row?.index);
            }
          }}
          showSerialNumber={true}
          enableBottomToolbar={false}
        />
      </GlassCard>

      <MuiDialogOne
        open={viewDetails}
        title='View Details'
        onClose={() => setViewDetails(!viewDetails)}
      >
        <ViewTable
          rows={columns?.map((item) => ({
            label: item.header,
            title: GetViewDetails(item.accessorKey),
          }))}
        />
      </MuiDialogOne>

      <GlassCard className='p-2 bg-teal-700 uppercase font-semibold text-white'>
        <Stack
          direction='row'
          flexWrap='wrap'
          spacing={3}
          justifyContent='space-between'
        >
          {billingValues?.map((item, i) => {
            return (
              <Box key={i} className='flex justify-between items-center'>
                <Box>{item.name}</Box>
                <Box className='font-bold ms-3'>{item.value}</Box>
              </Box>
            );
          })}
        </Stack>
      </GlassCard>
      {!(invoiceStatusId === internalInvoiceStatusEnum?.InvoiceGenerated ||
        invoiceStatusId === internalInvoiceStatusEnum?.Approved) && (
          <MuiDialogOne
            open={openPrintScreen}
            title="Print Invoice"
            width="1600px"
            onClose={() => setOpenPrintScreen(false)}
          >
            <ReportDesigner type={ReportTypes.INTERNAL_INVOICE_REPORT} id={id} />
          </MuiDialogOne>
        )}

    </Box>
  );
};

export default EditListInternalInvoicesAR;
