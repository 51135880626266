import React from 'react';
import { Box, LinearProgress } from '@mui/material';

const PageLoader = () => {
  return (
    <>
      <Box
        sx={{
          position: 'fixed',
          left: 0,
          top: 0,
          width: '100%',
          height: '100%',
          display: 'flex',
          flexDirection: 'column',
          alignItems: 'center',
          zIndex: 9999999999,
          justifyContent: 'center',
          bgcolor: 'background.body',
        }}
      >
        <Box
          component='img'
          src='/images/logo-black.png'
          sx={{ maxWidth: 230, height: 'auto' }}
        />

        <Box sx={{ marginTop: '35px', width: '230px', padding: '0 25px' }}>
          <LinearProgress />
        </Box>
      </Box>
    </>
  );
};

export default PageLoader;
