import React, { useEffect, useState } from 'react';
import DashboardLayout from '../../../../components/DashboardLayout.tsx';
import {
  Box,
  FormControl,
  FormControlLabel,
  Grid,
  Radio,
  RadioGroup,
} from '@mui/material';
import GlassCard from '../../../../components/small/GlassCard.tsx';
import ActionButtons from '../../../../components/_form/ActionButtons.tsx';
import { useNavigate, useParams } from 'react-router-dom';
import Crud_Service from '../../../../apis/CrudService.jsx';
import { Controller, useForm } from 'react-hook-form';
import useLoader from '../../../../components/helpers/UseLoader.tsx';
import CommonLoader from '../../../../components/page/CommonLoader.jsx';
import FmTextField from '../../../../components/_mui/FmTextField.tsx';
import { CreateButton } from '../../../../components/small/Buttons.jsx';
import FmSearchableSelect from '../../../../components/_mui/FmSearchableSelect.tsx';
import { statusOptions } from '../../../../utils/CommonVariables.tsx';
import { toast } from 'react-toastify';
import dayjs from 'dayjs';
import FmTimePicker from '../../../../components/_mui/FmTimePicker.tsx';
import { DatePicker, LocalizationProvider } from '@mui/x-date-pickers';
import { AdapterDayjs } from '@mui/x-date-pickers/AdapterDayjs';
import FmDatePicker from '../../../../components/_mui/FmDatePicker.tsx';
import FmRadioButton from '../../../../components/_mui/FmRadioButton.tsx';
import { yupResolver } from '@hookform/resolvers/yup';
import { vehiclemonthlyupdatesSchema } from '../../../../utils/CommonvalidationSchemas.tsx';

const EditMonthUpdate = () => {
  const navigate = useNavigate();
  const crud = new Crud_Service();
  const [loading, setLoading] = useState(false);
  const [editData, setEditData] = useState(null);
  const { handleSubmit, control, setValue, reset, watch, trigger } = useForm({
    resolver: yupResolver(vehiclemonthlyupdatesSchema),
    mode: 'onChange',
  });
  const { startEditLoading, stopEditLoading, editLoading } = useLoader();
  const { id } = useParams();
  const GridValue = 2.4;
  const vehicleMethodChange = watch('vehicleMethod') === 'true' ? true : false;

  useEffect(() => {
    getOwnVehicles();
  }, []);

  const getOwnVehicles = async () => {
    setLoading(true);
    await crud.getSingle('vehiclemonthlyupdates', id, (_err, res) => {
      if (res?.status === 200) {
        const data = res?.data;
        Object.entries({
          ...data,
        }).forEach(([key, value]) => {
          setValue(key, value);
        });
        setEditData(res?.data);
        setLoading(false);
      } else {
      }
    });
  };

  const handleEditMonthUpdate = async (values) => {
    startEditLoading();
    const updateCombinedData = {
      ...values,
      ownVehicleId: values?.ownVehicleId || null,
      hiredVehicleId: values?.hiredVehicleId || null,
      contractStartingPeriod: dayjs(values?.contractStartingPeriod).format(
        'YYYY-MM-DD'
      ),
      contractEndingPeriod: dayjs(values?.contractEndingPeriod).format(
        'YYYY-MM-DD'
      ),
      status: 2,
    };

    await crud.update(
      'vehiclemonthlyupdates',
      id,
      updateCombinedData,
      (_err, res) => {
        if (res?.status === 200) {
          toast.success('MonthlyUpdate Updated Successfully');
          navigate(-1);
          stopEditLoading();
        } else {
          stopEditLoading();
        }
      }
    );
  };
  const contractStartingPeriod = watch('contractStartingPeriod');

  return (
    <DashboardLayout
      title='Edit Monthly Update'
      actionButtons={
        <>
          <Box className='flex flex-row items-center whitespace-nowrap gap-3'>
            <CreateButton
              name='Create'
              onClick={() => navigate('/admin/vehicles/month-updates/create')}
            />
          </Box>
        </>
      }
    >
      <GlassCard className='p-5'>
        {loading ? (
          <CommonLoader />
        ) : (
          <form onSubmit={handleSubmit(handleEditMonthUpdate)}>
            <LocalizationProvider dateAdapter={AdapterDayjs}>
              <Grid container spacing={2}>
                <Grid item md={GridValue}>
                  <FmSearchableSelect
                    name='divisionId'
                    control={control}
                    apiUrl='divisions'
                    valueField='divisionId'
                    labelField={['divisionName']}
                    showField={['divisionName']}
                    label='Division'
                    defaultValue={{
                      divisionId: editData?.divisionId,
                      divisionName: editData?.divisionName,
                    }}
                  />
                </Grid>
                <Grid item md={GridValue}>
                  <FmRadioButton
                    name='vehicleMethod'
                    control={control}
                    options={[
                      { value: false, label: 'No' },
                      { value: true, label: 'Yes' },
                    ]}
                    label='Vehicle Method'
                  />
                </Grid>
                <Grid item md={GridValue}>
                  {vehicleMethodChange === true ? (
                    <FmSearchableSelect
                      name='ownVehicleId'
                      control={control}
                      apiUrl='ownvehicles'
                      valueField='ownVehicleId'
                      labelField={['ownVehicleTypeName']}
                      showField={['ownVehicleTypeName']}
                      label='Own Vehicle'
                      defaultValue={{
                        ownVehicleId: editData?.ownVehicleId,
                        ownVehicleTypeName: editData?.ownVehicleTypeName,
                      }}
                    />
                  ) : (
                    <FmSearchableSelect
                      name='hiredVehicleId'
                      control={control}
                      apiUrl='hiredVehicles'
                      valueField='hiredVehicleId'
                      labelField={['hiredVehicleTypeName']}
                      showField={['hiredVehicleTypeName']}
                      label='Hired Vehicle'
                      defaultValue={{
                        hiredVehicleId: editData?.hiredVehicleId,
                        hiredVehicleTypeName: editData?.hiredVehicleTypeName,
                      }}
                    />
                  )}
                </Grid>
                <Grid item md={GridValue}>
                  <FmSearchableSelect
                    name='month'
                    control={control}
                    apiUrl='months'
                    valueField='monthId'
                    labelField={['monthName']}
                    showField={['monthName']}
                    label='Month'
                    defaultValue={{
                      monthId: editData?.monthId,
                      monthName: editData?.monthName,
                    }}
                  />
                </Grid>
                <Grid item md={GridValue}>
                  <Controller
                    name='year'
                    control={control}
                    render={({
                      field: { onChange, value },
                      fieldState: { error },
                    }) => (
                      <DatePicker
                        label='Year'
                        views={['year']}
                        value={value ? dayjs().set('year', value) : null}
                        onChange={(newValue) => {
                          const year = newValue ? dayjs(newValue).year() : null;
                          onChange(year);
                        }}
                        slotProps={{
                          textField: {
                            fullWidth: true,
                            error: !!error,
                            helperText: error ? error.message : null,
                            required: true,
                          },
                        }}
                      />
                    )}
                  />
                </Grid>
                <Grid item md={GridValue}>
                  <FmSearchableSelect
                    name='locationId'
                    control={control}
                    apiUrl='locations'
                    valueField='locationId'
                    labelField={['locationName']}
                    showField={['locationName']}
                    label='Location'
                    defaultValue={{
                      locationId: editData?.locationId,
                      locationName: editData?.locationName,
                    }}
                  />
                </Grid>
                <Grid item md={GridValue}>
                  <FmSearchableSelect
                    name='projectId'
                    control={control}
                    apiUrl='projects'
                    valueField='projectId'
                    labelField={['projectName']}
                    showField={['projectName']}
                    label='Project'
                    defaultValue={{
                      projectId: editData?.projectId,
                      projectName: editData?.projectName,
                    }}
                  />
                </Grid>
                <Grid item md={GridValue}>
                  <FmSearchableSelect
                    name='siteId'
                    control={control}
                    apiUrl='buildings'
                    valueField='buildingId'
                    labelField={['buildingName']}
                    showField={['buildingName']}
                    label='Site'
                    defaultValue={{
                      buildingId: editData?.siteId,
                      buildingName: editData?.siteName,
                    }}
                  />
                </Grid>
                <Grid item md={GridValue}>
                  <FmTextField
                    name='currentKilometer'
                    label='Current Kilometer'
                    control={control}
                  />
                </Grid>
                <Grid item md={GridValue}>
                  <FmTimePicker
                    name='pickUpTime'
                    label='Pick-Up Time'
                    control={control}
                    required
                  />
                </Grid>
                <Grid item md={GridValue}>
                  <FmTimePicker
                    name='dropoffTime'
                    label='Drop-Off Time'
                    control={control}
                    required
                  />
                </Grid>
                <Grid item md={GridValue}>
                  <FmTextField
                    name='transporterName'
                    label='Transporter Name'
                    control={control}
                  />
                </Grid>
                <Grid item md={GridValue}>
                  <FmDatePicker
                    name='contractStartingPeriod'
                    label='Contract Starting Period'
                    control={control}
                    onChangeProp={() => trigger('contractEndingPeriod')}
                  />
                </Grid>
                <Grid item md={GridValue}>
                  <FmDatePicker
                    name='contractEndingPeriod'
                    label='Contract Ending Period'
                    control={control}
                    onChangeProp={() => trigger('contractStartingPeriod')}
                    defaultValue={dayjs().add(1, 'day')}
                    minDate={
                      contractStartingPeriod
                        ? dayjs(contractStartingPeriod)
                        : null
                    }
                  />
                </Grid>
                <Grid item md={GridValue}>
                  <FmTextField
                    name='rentAmount'
                    pattern='Decimal'
                    label='Rent Amount'
                    control={control}
                  />
                </Grid>
                <Grid item md={GridValue}>
                  <FmSearchableSelect
                    name='contactPersonId'
                    control={control}
                    apiUrl='loginusers'
                    valueField='loginUserId'
                    labelField={[
                      'employeeCode',
                      'employeeName',
                      'employeeMail',
                    ]}
                    showField={['employeeCode', 'employeeName', 'employeeMail']}
                    label='Contact Person'
                    defaultValue={{
                      loginUserId: editData?.contactPerson,
                      loginUserName: editData?.contactPersonName,
                    }}
                  />
                </Grid>
                <Grid item md={6}>
                  <FmTextField
                    name='remarks'
                    control={control}
                    label='Remarks'
                    multiline={true}
                    rows={2}
                    maxRows={3}
                  />
                </Grid>
              </Grid>
              <ActionButtons
                onSubmit={handleSubmit(handleEditMonthUpdate)}
                onReset={reset}
                submitLoading={editLoading}
                submitText='Update'
                cancelText='Cancel'
                onCancel={() => navigate(-1)}
              />
            </LocalizationProvider>
          </form>
        )}
      </GlassCard>
    </DashboardLayout>
  );
};

export default EditMonthUpdate;
