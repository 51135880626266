import { yupResolver } from '@hookform/resolvers/yup';
import { Grid, Box } from '@mui/material';
import { AdapterDayjs } from '@mui/x-date-pickers/AdapterDayjs';
import { LocalizationProvider } from '@mui/x-date-pickers/LocalizationProvider';
import dayjs from 'dayjs';
import React, { useEffect, useState } from 'react';
import { useForm } from 'react-hook-form';
import { useTranslation } from 'react-i18next';
import { useNavigate, useParams } from 'react-router-dom';
import { toast } from 'react-toastify';
import Crud_Service from '../../../apis/CrudService';
import { ActionButtons } from '../../../components/_form';
import FmAutoComplete from '../../../components/_mui/FmAutoComplete.tsx';
import FmDatePicker from '../../../components/_mui/FmDatePicker.tsx';
import FmSearchableSelect from '../../../components/_mui/FmSearchableSelect.tsx';
import FmTextField from '../../../components/_mui/FmTextField.tsx';
import DashboardLayout from '../../../components/DashboardLayout.tsx';
import useCommonFetchApi from '../../../components/helpers/useCommonFetchApi.tsx';
import useLoader from '../../../components/helpers/UseLoader.tsx';
import GlassCard from '../../../components/small/GlassCard.tsx';
import { enquiryEditValidationSchema, enquiryValidationSchema } from '../../../utils/CommonvalidationSchemas.tsx';
import { BooleanOptions } from '../../../utils/CommonVariables.tsx';
import ElementLoader from './../../../components/page/ElementLoader.tsx';
import FmStack from '../../../components/layout/FmStack.tsx';

const EditEnquiry = () => {
  const navigate = useNavigate();
  const crud = new Crud_Service();
  const { id } = useParams();
  const [loading, setLoading] = useState(false);
  const [buyer, setBuyer] = useState(null);
  const { editLoading, startEditLoading, stopEditLoading } = useLoader();
  const [, setBidbond] = useState();
  // const [expressOfInterest, setExpressOfInterest] = useState(null);
  const { t } = useTranslation();
  const [tender, setTender] = useState({});

  const { setValue, handleSubmit, control, watch, reset } = useForm({
    resolver: yupResolver(enquiryEditValidationSchema),
    mode: 'onChange',
  });
  const expressOfInterest = watch('expressOfInterest');
  const statusOptions = [
    { statusId: 2, statusName: 'Active' },
    { statusId: 1, statusName: 'Inactive' },
  ];
  const defaultValues = {
    tenderId: tender?.tenderId,
    tenderName: tender?.tenderName,
  };
  useEffect(() => {
    getTenders();
  }, []);

  useEffect(() => {
    watch('startDate') && setValue('lastDate', watch('startDate'));
  }, [watch('startDate')]);

  const getTenders = async () => {
    setLoading(true);
    await crud.getSingle('tenders', id, (_err, res) => {
      if (res?.status === 200) {
        setTender(res?.data);

        Object.entries({
          ...res?.data,
        }).forEach(([key, value]) => {
          if (key === 'tenderEmirates') {
            const emirateIds = value?.map((item) => item.emirateId);
            setValue('emirateIds', emirateIds);
            return;
          }
          setValue(key, value);
          if (key === 'buyTenderDocument') {
            setBuyer(value);
            !value && setValue('cost', null);
          }
          if (key === 'bidBond') {
            setBidbond(value);
            !value && setValue('bidBondFormatId', null);
            !value && setValue('bidBondValue', null);
            !value && setValue('bidBondTypeId', null);
            !value && setValue('bidBondTypeValue', null);
          }
        });

        setLoading(false);
      } else {
        toast.error(t('something_Went_Wrong'));
      }
    });
  };
  const { data: tenderEnquiryTypes } = useCommonFetchApi('tenderenquirytypes');

  const { data: tenderFormats } = useCommonFetchApi('tenderformats');
  const { data: tenderStatuses } = useCommonFetchApi('tenderstatuses');

  const handleEditTenders = async (values) => {
    startEditLoading();
    const CombinedData = {
      ...values,
      bidBondValue: Number(values?.bidBondValue) || null,
      receivedDate: dayjs(values?.receivedDate).format('YYYY-MM-DD'),
      subMissionDate:
        dayjs(values?.subMissionDate).format('YYYY-MM-DD') || null,
      submissionRevisedDate: dayjs(values?.submissionRevisedDate).format(
        'YYYY-MM-DD'
      ),
      // expressOfInterestDate: dayjs(values?.expressOfInterestDate).format(
      //   'YYYY-MM-DD'
      // ),
      expressOfInterestDate: values?.expressOfInterest
        ? dayjs(values?.expressOfInterestDate).format('YYYY-MM-DD')
        : null,
      tenderValue: Number(values?.tenderValue),
      status: Number(values?.status),
      tenderEmirates: undefined,
      tenderFormatId: 3,
    };

    await crud.update('tenders', id, CombinedData, (_err, res) => {
      if (res?.status === 200) {
        navigate(-1);
        stopEditLoading();
        toast.success('Enquiry Updated Successfully');
      } else {
        stopEditLoading();
      }
    });
  };
  const { data: emirates } = useCommonFetchApi('emirates');

  const handleBidBondChange = (e) => {
    setBidbond(e);
    !e && setValue('bidBondFormatId', null);
    !e && setValue('bidBondValue', null);
    !e && setValue('bidBondTypeId', null);
    !e && setValue('bidBondTypeValue', null);
  };

  const handleOnChangeBuyer = (e) => {
    setBuyer(e?.value);
    !e?.value && setValue('cost', null);
  };

  return (
    <DashboardLayout title={`Edit Enquiry -  ${tender?.tenderCode}`}>
      <GlassCard className='p-4'>
        {loading ? (
          <ElementLoader />
        ) : (
          <form onSubmit={handleSubmit(handleEditTenders)}>
            <LocalizationProvider dateAdapter={AdapterDayjs}>
              <FmStack spacing={2}>
                <Box>
                  <Grid container spacing={2}>
                    <Grid item md={3}>
                      <FmTextField
                        name='tenderName'
                        control={control}
                        label='Tender Name'
                        defaultValue={defaultValues}
                        readOnly
                      />
                    </Grid>
                    <Grid item md={3}>
                      <FmTextField
                        name='tenderRefNo'
                        control={control}
                        label='Tender Reference Number'
                      />
                    </Grid>
                    {/* <Grid item md={3}>
                  <FmTextField
                    name='tenderNumber'
                    control={control}
                    label='Tender Number'
                    required
                  />
                </Grid> */}
                    <Grid item md={3}>
                      <FmSearchableSelect
                        name='companyId'
                        control={control}
                        apiUrl='companies'
                        label='Quote By Company'
                        defaultValue={{
                          companyName: tender?.companyName,
                          companyId: tender?.companyId,
                        }}
                        showField={['companyCode', 'companyName']}
                        labelField={['companyCode', 'companyName']}
                        valueField='companyId'
                        required
                      />
                    </Grid>
                    {/* TODO */}
                    {/* <Grid item md={3}>
                  <FmTextField
                    name='requestForEstimate'
                    control={control}
                    label='Request For Estimate'
                    required
                  />
                </Grid> */}
                    <Grid item md={3}>
                      <FmSearchableSelect
                        name='clientId'
                        control={control}
                        apiUrl='clients'
                        valueField='clientId'
                        defaultValue={{
                          clientName: tender?.clientName,
                          clientId: tender?.clientId,
                        }}
                        labelField={['clientCode', 'clientName']}
                        showField={['clientCode', 'clientName']}
                        pageSize={20}
                        label='Client'
                        required
                      />
                    </Grid>
                    <Grid item md={3}>
                      <FmSearchableSelect
                        name='tenderProjectTypeId'
                        control={control}
                        apiUrl='tenderProjectTypes'
                        valueField='tenderProjectTypeId'
                        labelField={[
                          'tenderProjectTypeCode',
                          'tenderProjectTypeName',
                        ]}
                        defaultValue={{
                          tenderProjectTypeName: tender?.tenderProjectTypeName,
                          tenderProjectTypeCode: tender?.tenderProjectTypeCode,
                        }}
                        showField={['tenderProjectTypeName']}
                        pageSize={20}
                        label='Tender Project Type'
                        required
                      />
                    </Grid>
                    <Grid item md={3}>
                      <FmAutoComplete
                        name='tenderEnquiryTypeId'
                        control={control}
                        options={tenderEnquiryTypes}
                        label='Mode of Submission'
                        displayField='tenderEnquiryTypeName'
                        optionFields={['tenderEnquiryTypeName']}
                        valueKey='tenderEnquiryTypeId'
                        required
                      />
                    </Grid>
                    <Grid item md={3}>
                      <FmSearchableSelect
                        name='divisionId'
                        control={control}
                        apiUrl='divisions'
                        valueField='divisionId'
                        labelField={['divisionCode', 'divisionName']}
                        showField={['divisionCode', 'divisionName']}
                        defaultValue={{
                          divisionName: tender?.divisionName,
                        }}
                        pageSize={20}
                        label={'Division'}
                      />
                    </Grid>
                  </Grid>
                </Box>

                <Box>
                  <Grid container spacing={2}>
                    <Grid item md={3 * 2}>
                      <FmAutoComplete
                        name='emirateIds'
                        control={control}
                        options={emirates}
                        required
                        displayField='emirateName'
                        optionFields={['emirateName']}
                        valueKey='emirateId'
                        defaultValue={tender?.tenderEmirates}
                        label='Emirates'
                        isMultiSelect
                      />
                    </Grid>
                    {/* 
                <Grid item md={3}>
                  <FmAutoComplete
                    name='tenderFormatId'
                    control={control}
                    options={tenderFormats}
                    label='Enquiry Format'
                    displayField='tenderFormatName'
                    optionFields={['tenderFormatName']}
                    valueKey='tenderFormatId'
                    required
                  />
                </Grid> */}
                  </Grid>
                </Box>

                <Box>
                  <Grid container spacing={2}>
                    <Grid item md={3}>
                      <FmAutoComplete
                        name='bidBond'
                        control={control}
                        options={[
                          { value: true, bidBondName: 'Yes' },
                          { value: false, bidBondName: 'No' },
                        ]}
                        label='Bid Bond'
                        displayField='bidBondName'
                        optionFields={['bidBondName']}
                        valueKey='value'
                        onChange={handleBidBondChange}
                      />
                    </Grid>
                    {/* <Grid item md={3}>
                  <FmAutoComplete
                    name='autoRenewal'
                    control={control}
                    options={[
                      {
                        name: 'Yes',
                        value: true,
                      },
                      {
                        name: 'No',
                        value: false,
                      },
                    ]}
                    label='Auto Renewal'
                    displayField='name'
                    optionFields={['name']}
                    valueKey='value'
                  />
                </Grid> */}

                    {/* <Grid item md={3}>
                  <FmAutoComplete
                    name='subContractRequired'
                    control={control}
                    options={[
                      {
                        name: 'Yes',
                        value: true,
                      },
                      {
                        name: 'No',
                        value: false,
                      },
                    ]}
                    label='Sub Contract Required'
                    displayField='name'
                    optionFields={['name']}
                    valueKey='value'
                    required
                  />
                </Grid> */}

                    <Grid item md={3}>
                      <FmDatePicker
                        control={control}
                        name='receivedDate'
                        label='Received Date'
                        required
                      />
                    </Grid>

                    <Grid item md={3}>
                      <FmDatePicker
                        control={control}
                        name='subMissionDate'
                        label='Submission Date'
                        minDate={
                          dayjs(watch('receivedDate'))

                        }
                        defaultValue={null}
                        required

                      />
                    </Grid>
                    <Grid item md={3}>
                      <FmDatePicker
                        control={control}
                        name='submissionRevisedDate'
                        label='Submission Revised Date'
                        minDate={dayjs(watch('subMissionDate'))}
                        defaultValue={null}
                      />
                    </Grid>
                  </Grid>
                </Box>

                <Box>
                  <Grid container spacing={2}>
                    <Grid item md={3}>
                      <FmTextField
                        name='tenderValue'
                        control={control}
                        label='Tender / Final Proposal Value'
                        pattern='Number'
                      />
                    </Grid>
                  </Grid>
                </Box>

                <Box>
                  <Grid container spacing={2}>
                    <Grid item md={3}>
                      <FmAutoComplete
                        name='buyTenderDocument'
                        control={control}
                        options={BooleanOptions}
                        label='Buy Enquiry Document'
                        displayField='label'
                        optionFields={['label']}
                        valueKey='value'
                        onChange={handleOnChangeBuyer}
                        onChangeValue={true}
                      />
                    </Grid>
                    {buyer === true && (
                      <Grid item md={3}>
                        <FmTextField
                          name='cost'
                          control={control}
                          label='Costs'
                          pattern='Decimal'
                        />
                      </Grid>
                    )}
                  </Grid>
                </Box>

                <Box>
                  <Grid container spacing={2}>
                    <Grid item md={3}>
                      <FmAutoComplete
                        name='expressOfInterest'
                        control={control}
                        options={[
                          {
                            name: 'Yes',
                            value: true,
                          },
                          {
                            name: 'No',
                            value: false,
                          },
                        ]}
                        label='Express Of Interest'
                        displayField='name'
                        optionFields={['name']}
                        valueKey='value'
                        // onChange={handleExpressOfInterest}
                        // onChangeValue={true}
                        required
                      />
                    </Grid>
                    {expressOfInterest === true && (
                      <Grid item md={3}>
                        <FmDatePicker
                          control={control}
                          name='expressOfInterestDate'
                          label='EOI Date'
                        />
                      </Grid>
                    )}
                    <Grid item md={3}>
                      <FmAutoComplete
                        name='tenderStatusId'
                        control={control}
                        options={tenderStatuses}
                        label='Enquiry Status'
                        displayField='tenderStatusName'
                        optionFields={['tenderStatusName']}
                        valueKey='tenderStatusId'
                        required
                      />
                    </Grid>
                    <Grid item md={3}>
                      <FmAutoComplete
                        name='status'
                        control={control}
                        options={statusOptions}
                        label='Status'
                        displayField='statusName'
                        optionFields={['statusName']}
                        valueKey='statusId'
                      />
                    </Grid>
                  </Grid>
                </Box>
                <Box>
                  <Grid container spacing={2}>
                    <Grid item md={12}>
                      <Grid container spacing={1}>
                        <Grid item md={6}>
                          <FmTextField
                            name='remarks'
                            control={control}
                            label='Enquiry Remarks'
                            multiline
                            rows={3}
                            maxRows={3}
                          />
                        </Grid>
                      </Grid>
                    </Grid>
                  </Grid>
                </Box>
              </FmStack>
            </LocalizationProvider>
            <ActionButtons
              onSubmit={handleSubmit(handleEditTenders)}
              onCancel={() => navigate(-1)}
              onReset={reset}
              submitLoading={editLoading}
              cancelLoading={false}
              submitText='Update'
            />
          </form>
        )}
      </GlassCard>
    </DashboardLayout>
  );
};

export default EditEnquiry;
