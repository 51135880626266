import { Icon } from '@iconify/react';
import { Box } from '@mui/material';
import { GridPagination } from '@mui/x-data-grid';
import React, { useEffect, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { toast } from 'react-toastify';
import Crud_Service from '../../../apis/CrudService';
import { TextColor } from '../../../components/_form/FormElements.jsx';
import TableSearch from '../../../components/_form/TableSearch.jsx';
import FmViewDateField from '../../../components/_mui/FmViewDateField.tsx';
import { ToolbarBoxesFlex } from '../../../components/boxes/Boxes.jsx';
import DashboardLayout from '../../../components/DashboardLayout.tsx';
import DataGridHeaderTooltip from '../../../components/datagrid/DataGridHeaderTooltip.tsx';
import TableExtraContentTooltip from '../../../components/datagrid/TableExtraContentTooltip.tsx';
import TextElipseTooltip from '../../../components/datagrid/TextElipseTooltip.tsx';
import DataTable from '../../../components/DataTable.tsx';
import DataTableToolbarButtons from '../../../components/helpers/DataTableToolbarButtons.tsx';
import GlassCard from '../../../components/small/GlassCard.tsx';
import ViewColoredDate from '../../../components/view/ViewColoredDate.tsx';
import ViewTableLightText from '../../../components/view/ViewTableLightText.tsx';

const GLAList = () => {
  const crud = new Crud_Service();
  const [gla, setGLA] = useState([]);
  const navigate = useNavigate();
  const [pageSize, setPageSize] = useState(50);
  const [pageNumber, setPageNumber] = useState(1);
  const [searchKeyword, setSearchKeyword] = useState('');
  const [sortBy, setSortBy] = useState('');
  const [sortDirection, setSortDirection] = useState('');
  const [rows, setRows] = useState([]);
  const [loading, setLoading] = useState(false);
  const [tablerecordCounts, setTableRecordCounts] = useState(null);

  const handlePagination = (model) => {
    setPageSize(model?.pageSize);
    setPageNumber(model?.page + 1);
  };
  useEffect(() => {
    document.title = `General Ledger | Financial`;
  }, []);
  useEffect(() => {
    getGLA();
  }, [pageSize, pageNumber, searchKeyword, sortBy, sortDirection]);
  const getGLA = async () => {
    await crud.getAll(
      'generalledgers',
      {
        pageSize,
        pageNumber,

        searchKeyword,
        sortBy,
        sortDirection,
      },
      (err, res) => {
        if (err) {
          toast.error('Error fetching General Ledgers', err);
          return;
        } else if (res?.status === 200) {
          setTableRecordCounts(res?.data);
          const data = res?.data?.data || [];
          const transformedData = data.map((row) => ({
            ...row,
            id: row?.generalLedgerId,
          }));
          setRows(transformedData);
        } else {
          setGLA([]);
        }
      }
    );
  };
  const handleViewClickOpen = async (props) => {
    navigate(`view/${props?.generalLedgerId}`);
  };

  //   const Actions = [
  //     {
  //       name: 'View',
  //       icon: Icons.view,
  //       onClick: (props) => handleViewClickOpen(props),
  //     },
  //   ];
  const handleSortChange = (sortModel) => {
    if (sortModel.length > 0) {
      setSortBy(sortModel[0].field);
      setSortDirection(sortModel[0].sort);
    } else {
      setSortBy('');
      setSortDirection('');
    }
  };
  const columns = [
    // {
    //   field: 'action',
    //   headerName: '',
    //   flex: 1,
    //   minWidth: 80,
    //   cellClassName: 'p-0',
    //   align: 'center',
    //   sortable: false,
    //   disableClickEventBubbling: false,
    //   renderCell: (params) => {
    //     return (
    //       <TableActionButtons
    //         Actions={Actions?.map((action) => ({
    //           ...action,
    //           onClick: () => action.onClick(params.row),
    //         }))}
    //       />
    //     );
    //   },
    // },

    {
      field: 'generalLedgerCode',
      headerName: (
        <DataGridHeaderTooltip name='General Ledger' title='General Ledger' />
      ),
      minWidth: 160,
      flex: 1,
      renderCell: (params) => {
        return (
          <>
            <Box className='w-full'>
              <Box sx={{ color: 'text.dark' }}>
                <TextElipseTooltip name={params.value} />{' '}
              </Box>
              <ViewTableLightText
                name={
                  <Box className='flex gap-1 w-full'>
                    <span className='font-semibold'>
                      <DataGridHeaderTooltip
                        name='GLA'
                        title='General Ledger Account'
                      />
                    </span>
                    {params?.row?.generalLedgerName}
                  </Box>
                }
              />
            </Box>
          </>
        );
      },
    },
    {
      field: 'salesOrderCode',
      headerName: 'Codes',
      minWidth: 40,

      renderCell: (params) => {
        return (
          <TableExtraContentTooltip
            titleRows={[
              { label: 'Sales Order', title: params?.row?.salesOrderCode },
              { label: 'Project', title: params?.row?.projectCode },
              { label: 'Contract', title: params?.row?.contractCode },
              { label: 'Work Order', title: params?.row?.workOrderCode },
            ]}
          >
            <Icon icon='tabler:device-desktop-code' width='24' height='24' />
          </TableExtraContentTooltip>
        );
      },
    },
    {
      field: 'account',
      headerName: 'Account',
      minWidth: 200,
      flex: 2,
      renderCell: (params) => {
        return (
          <Box>
            <Box sx={{ color: 'text.dark' }}>{params?.value}</Box>

            <ViewTableLightText
              name={
                <Box className='flex gap-1 w-full'>{params?.row?.account1}</Box>
              }
            />
          </Box>
        );
      },
    },
    {
      field: 'employeeName',
      headerName: 'Employee',
      minWidth: 200,
      flex: 2,
      renderCell: (params) => {
        return (
          <Box>
            <Box sx={{ color: 'text.dark' }}>{params?.value}</Box>
          </Box>
        );
      },
    },
    {
      field: 'costCenterName',
      headerName: 'Cost Center',
      minWidth: 100,
      renderCell: (params) => {
        return <TextColor title={params.value} color={'green'} />;
      },
    },
    {
      field: 'inChargeUserName',
      headerName: 'In Charge',
      minWidth: 100,
      renderCell: (params) => {
        return <TextColor title={params.value} color={'green'} />;
      },
    },
    {
      field: 'divisionName',
      headerName: 'Division',
      minWidth: 120,
      renderCell: (params) => {
        return <TextColor title={params.value} color={'green'} />;
      },
    },
    {
      field: 'branchName',
      headerName: 'Branch',
      minWidth: 100,
      renderCell: (params) => {
        return <TextColor title={params.value} color={'green'} />;
      },
    },

    {
      field: 'generalLedgerDate',
      headerName: 'General Ledger Date',
      minWidth: 120,
      flex: 1,
      renderCell: (params) => {
        return (
          <TableExtraContentTooltip
            titleRows={[
              {
                label: 'General Ledger Date',
                title: params?.row?.generalLedgerDate,
              },
            ]}
          >
            <ViewColoredDate
              date={FmViewDateField({ value: params?.value })}
              originalDate={params?.value}
              icon={<Icon icon='mdi-light:calendar' />}
            />
          </TableExtraContentTooltip>
        );
      },
    },
    {
      field: 'debit',
      headerName: 'Debit (AED)',
      minWidth: 100,
      renderCell: (params) => {
        return <TextColor title={params.value} color={'red'} />;
      },
    },
    {
      field: 'credit',
      headerName: 'Credit (AED)',
      minWidth: 100,
      renderCell: (params) => {
        return <TextColor title={params.value} color={'green'} />;
      },
    },
    {
      field: 'vatPercent',
      headerName: 'VAT %',
      minWidth: 70,
      renderCell: (params) => {
        return <TextColor title={params.value} />;
      },
    },
    {
      field: 'vat',
      headerName: 'VAT',
      minWidth: 70,
      renderCell: (params) => {
        return <TextColor title={params.value} />;
      },
    },
    {
      field: 'retention',
      headerName: 'Retention',
      minWidth: 100,
      renderCell: (params) => {
        return <TextColor title={params.value} />;
      },
    },
    {
      field: 'remarks',
      headerName: 'Remarks',
      minWidth: 220,
      flex: 1,
      renderCell: (params) => {
        return <TextElipseTooltip name={params.value} twoline />;
      },
    },
  ];
  const onRowDoubleClick = (e) => {
    if (e?.row) {
      handleViewClickOpen(e?.row);
    }
  };

  return (
    <DashboardLayout title='General Ledgers'>
      <Box className='flex flex-col h-full gap-2'>
        <GlassCard className='h-full-css flex-1 min-h-0 overflow-hidden'>
          <Box className='h-full-css'>
            <DataTable
              rows={rows}
              columns={columns}
              loading={loading}
              onRowDoubleClick={(e) => onRowDoubleClick(e)}
              getRowClassName={(params) =>
                `${
                  params.row.workingStatus === 'InActive' &&
                  'bg-red-500 bg-opacity-15'
                }`
              }
              pageSizeOptions={[2, 10, 20, 50, 100]}
              sortingMode='server'
              paginationMode='server'
              onPaginationModelChange={(model) => handlePagination(model)}
              onSortModelChange={(model) => handleSortChange(model)}
              page={pageNumber - 1}
              pageSize={pageSize}
              hideFooter
              rowCount={tablerecordCounts?.total}
              slots={{
                toolbar: () => (
                  <ToolbarBoxesFlex>
                    <Box className='w-full' sx={{ maxWidth: '350px' }}>
                      <TableSearch
                        placeholder='Search'
                        fullWidth
                        setSearchKeyword={setSearchKeyword}
                        searchValue={searchKeyword}
                      />
                    </Box>
                    <Box
                      className='flex-1'
                      sx={{
                        '& .MuiTablePagination-root': {
                          '& .MuiToolbar-root': {
                            minHeight: '34px',
                          },
                        },
                      }}
                    >
                      <GridPagination />
                    </Box>
                    <DataTableToolbarButtons disableExport />
                  </ToolbarBoxesFlex>
                ),
              }}
            />
          </Box>
        </GlassCard>
      </Box>
    </DashboardLayout>
  );
};

export default GLAList;
