import React from 'react';
import { Box } from '@mui/material';
import { Icon } from '@iconify/react/dist/iconify.js';

const ViewStartEndDate = ({ start, end }) => {
  return (
    <Box>
      {start && (
        <Box
          className='flex gap-2 items-center  font-semibold'
          color={'primary.main'}
        >
          <Box className='text-base'>
            <Icon icon='fa6-regular:calendar-check' />
          </Box>
          <Box> {start}</Box>
        </Box>
      )}
      {end && (
        <Box
          className='flex gap-2 items-center  font-semibold'
          color={'error.main'}
        >
          <Box className='text-base'>
            <Icon icon='fa6-regular:calendar-xmark' />
          </Box>
          <Box>{end}</Box>
        </Box>
      )}
    </Box>
  );
};

export default ViewStartEndDate;
