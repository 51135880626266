import React, { useState, useMemo } from 'react';
import { Box, Button } from '@mui/material';
import { Icon } from '@iconify/react';
import MuiDialogOne from '../_mui/MuiDialogOne.jsx';

const HelpCenter = () => {
  const [open, setOpen] = useState(false);

  const email = 'support@technoduces.com'; // Support email
  const subject = 'Technical Support | Technoduces';

  const emailUrl = useMemo(() => {
    return `mailto:${email}?subject=${encodeURIComponent(subject)}`;
  }, [email, subject]);

  const handleEmailClick = () => {
    window.location.href = emailUrl;
  };

  return (
    <Box>
      <Button
        startIcon={
          <Icon
            icon={'fluent:call-connecting-20-regular'}
            width='24'
            height='24'
            style={{ transition: '0.5s all' }}
          />
        }
        sx={{ color: 'inherit', fontWeight: 500 }}
        className='py-0 px-2  transition-all'
        onClick={() => setOpen(true)}
      >
        Help Center
      </Button>

      <MuiDialogOne
        title='Help Center'
        open={open}
        onClose={() => setOpen(false)}
        width='350px'
      >
        <Box className='text-center'>
          <Box className='font-semibold text-lg mb-2'>How can we help you?</Box>
          <Box>
            Our support team is available 24/7. Reach out via call or email.
          </Box>
        </Box>

        <Box className='flex items-center gap-2 flex-col mt-5'>
          <Button
            variant='contained'
            fullWidth
            startIcon={
              <Icon
                icon='fluent:call-connecting-20-regular'
                width='26'
                height='26'
              />
            }
          >
            289 (Intercom)
          </Button>
          <Button
            variant='contained'
            startIcon={
              <Icon
                icon='fluent:mail-checkmark-24-regular'
                width='26'
                height='26'
              />
            }
            fullWidth
            onClick={handleEmailClick}
          >
            {email}
          </Button>
        </Box>
      </MuiDialogOne>
    </Box>
  );
};

export default HelpCenter;
