import { Box } from '@mui/material';
import React, { useEffect } from 'react';
import { useNavigate } from 'react-router-dom';
import { CreateButton } from '../../../../components/small/Buttons.jsx';
import Nav from '../../../../utils/Nav.tsx';
import DashboardLayout from './../../../../components/DashboardLayout.tsx';
import CreateSalesQuotationList from './CreateSalesQuotationList.tsx';

const CreateSalesQuotation = (props) => {
  const { type } = props;
  const [getData] = React.useState(false);
  const navigate = useNavigate();

  useEffect(() => {
    document.title = 'Sales Quotation';
  }, []);

  return (
    <DashboardLayout
      title={`Sales Quotations`}
      hasSubmenu={type === 4}
      menu={type === 1 ? Nav[12].child[1].children : Nav[7].child[3].children}
      actionButtons={
        <>
          <Box className='flex flex-row items-center whitespace-nowrap gap-3'>
            <CreateButton
              name='Create'
              onClick={() => navigate('create')}
            />
          </Box>
        </>
      }
    >
      <CreateSalesQuotationList type={type} getData={getData} />
    </DashboardLayout>
  );
};

export default CreateSalesQuotation;
