import React, { useEffect, useMemo, useState } from 'react';
import DashboardLayout from '../../../../components/DashboardLayout.tsx';
import ListPettyCash from './ListPettyCash.tsx';
import useTableLogic from '../../../../components/helpers/MRTUseTableLogic.tsx';
import { useNavigate } from 'react-router-dom';
import { Box } from '@mui/material';
import { CreateButton } from '../../../../components/small/Buttons.jsx';
import { findNavByName } from '../../../../utils/navUtils.ts';
import { useSelector } from 'react-redux';
import FASalesInvoiceGrids from '../../../../components/dashboard/Financial/FASalesInvoiceGrids.tsx';
import FASalesInvoiceGridsApi from '../../../../components/dashboard/Financial/FASalesInvoiceGridsApi.tsx';

const PettyCash = () => {
  const navigate = useNavigate();
  const [getStatus, setGetStatus] = useState(0);

  useEffect(() => {
    document.title = 'Petty Cash| Cash and Bank | Financial';
  }, []);

  const menu = useSelector((state) => state?.menu?.items);
  // Flatten menu only once and store it for reuse
  const SubMenu = useMemo(() => {
    return menu?.map((item) => item?.menus?.flat()).flat() || [];
  }, [menu]);
  const result = useMemo(
    () => findNavByName(SubMenu, 'Financial', 'Cash and Bank'),
    [SubMenu]
  );

  const handleStatusChange = (status: number) => {
    setGetStatus(status);
  };

  return (
    <DashboardLayout
      title={'Petty Cash'}
      hasSubmenu
      menu={result}
      actionButtons={
        <CreateButton name='Create' onClick={() => navigate('create')} />
      }
    >
      <Box className='flex flex-col gap-2 h-full'>
        <FASalesInvoiceGridsApi
          handleStatusChange={handleStatusChange}
          api='pettycashtransactions'
        />
        <ListPettyCash status={getStatus} />
      </Box>
    </DashboardLayout>
  );
};

export default PettyCash;
