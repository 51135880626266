import React, { useEffect, useState } from "react";
import DashboardLayout from "../../../components/DashboardLayout.tsx";
import { Box, Grid, TextField, MenuItem } from "@mui/material";
import GlassCard from "../../../components/small/GlassCard.tsx";
import { ActionButtons } from "../../../components/_form";
import { useNavigate, useParams } from "react-router-dom";
import Crud_Service from "../../../apis/CrudService";
import { useForm, Controller } from "react-hook-form";
import { useTranslation } from "react-i18next";
import { LocalizationProvider } from "@mui/x-date-pickers/LocalizationProvider";
import { AdapterDayjs } from "@mui/x-date-pickers/AdapterDayjs";
import useLoader from "../../../components/helpers/UseLoader.tsx";
import CommonLoader from "../../../components/page/CommonLoader.jsx";
import FmTextField from "../../../components/_mui/FmTextField.tsx";
import { toast } from "react-toastify";
import FmAutoComplete from "../../../components/_mui/FmAutoComplete.tsx";
import { DatePicker } from "@mui/x-date-pickers";
import dayjs from "dayjs";
import useCommonFetchApi from "../../../components/helpers/useCommonFetchApi.tsx";
import { CreateButton } from "../../../components/small/Buttons.jsx";
import FmDatePicker from "../../../components/_mui/FmDatePicker.tsx";
import { yupResolver } from "@hookform/resolvers/yup";
import { CompanyInsurancePolicySchema } from "../../../utils/CommonvalidationSchemas.tsx";
import FmFileInput from "../../../components/_mui/FmFileInput.tsx";
import UploadModalBox from "../../../components/upload/UploadModalBox.tsx";
import apiInstance from "../../../apis/ApiService.jsx";

const EditCompanyInsurancePolicy = () => {
  const navigate = useNavigate();
  const crud = new Crud_Service();
  const [loading, setLoading] = useState(false);
  const { id } = useParams();
  const GridValue = 2.4;
  const [fileId, setFileId] = useState(null);
  const { handleSubmit, control, setValue, reset, getValues, watch } = useForm({
    mode: "onChange",
    resolver: yupResolver(CompanyInsurancePolicySchema),
  });
  const { editLoading, startEditLoading, stopEditLoading } = useLoader();
  const { t } = useTranslation();
  const [files, setFiles] = useState([]);
  const [uploadedFileIds, setUploadedFileIds] = useState([]);
  const [editData, setEditData] = useState(null);
  const [previewdData, setPreviewData] = useState([]);
  const handleFileIds = (fileIds) => {
    setUploadedFileIds(fileIds);
  };

  const { data: insurancePolicy } = useCommonFetchApi(
    "companyinsurancepolicies"
  );
  const { data: endUser } = useCommonFetchApi("companyinsurancepolicyendusers");
  const { data: typeOfProduct } = useCommonFetchApi(
    "companyinsurancepolicytypeofproducts"
  );
  const { data: insurer } = useCommonFetchApi("companyinsurancepolicyinsurers");

  useEffect(() => {
    getCompanyInsurancePolicy();
  }, []);

  //   Setting the updated client values
  const getCompanyInsurancePolicy = async () => {
    setLoading(true);
    await crud.getSingle("companyInsurancePolicyDetails", id, (_err, res) => {
      if (res?.status === 200) {
        setEditData(res?.data);
        Object.entries({
          ...res?.data,
        }).forEach(([key, value]) => {
          setValue(key, value);
        });
        setLoading(false);
      } else {
        setLoading(false);
      }
    });
  };



  const handleUpdateCompanyInsurance = async (values) => {
    startEditLoading();
    const payload = {
      companyInsurancePolicyId: values?.companyInsurancePolicyId,
      description: values?.description,
      companyInsurancePolicyEndUserId: values?.companyInsurancePolicyEndUserId,
      companyInsurancePolicyTypeOfProductId: values?.companyInsurancePolicyTypeOfProductId,
      companyInsurancePolicyInsurerId: values?.companyInsurancePolicyInsurerId,
      companyInsurancePolicyPremiumId: values?.companyInsurancePolicyPremiumId,
      policyDetail: values?.policyDetail,
      brokerName: values?.brokerName,
      mobileNumber: values?.mobileNumber,
      startDate: values?.startDate ? dayjs(values.startDate).format('YYYY-MM-DD') : null,
      endDate: values?.endDate ? dayjs(values.endDate).format('YYYY-MM-DD') : null,
      priorNotificationDays: values?.priorNotificationDays,
      insuredSum: values?.insuredSum,
      remarks: values?.remarks,
      status: values?.status,
      updateCompanyInsurancePolicyDetailImages: uploadedFileIds?.map((fileId, index) => ({
        companyInsurancePolicyDetailImageId: values?.companyInsurancePolicyDetailImages?.[index]?.companyInsurancePolicyDetailImageId || 0,
        fileId: values?.companyInsurancePolicyDetailImages?.[index]?.fileId || fileId,
        status: 1,
      })),
    };
    
    await crud.update(
      "companyInsurancePolicyDetails",
      values?.companyInsurancePolicyDetailId,
      payload,
      (_err, res) => {
        if (res?.status === 200) {
          //   setOpenUpdate(false);
          stopEditLoading();
          toast.success("Updated successfully!");
          navigate(-1);
        } else {
          stopEditLoading();
        }
      }
    );
  };
  const startDate = watch("startDate");

  const handleDownloadFile = async (fileId) => {
    try {
      // Check if fileId is null, undefined, or an empty string
      if (!fileId) {
        return; // Exit early without calling the API
      }
  
      const imageUrl = await apiInstance.getFiles(`files/download/${fileId}`);
  
      
      setPreviewData((prevData) => [...prevData, imageUrl,fileId]);

    } catch (err) {
      toast.error(`Error fetching image: ${err.message}`);
    }
  };
  const [dawnloadFileId, setDownloadFileId] = useState(false);
  useEffect(() => {
    if (editData && editData.companyInsurancePolicyDetailImages) {
      editData.companyInsurancePolicyDetailImages.forEach((image) => {
        handleDownloadFile(image.fileId);
        setDownloadFileId(image.fileId)
      });
    }
  }, [editData]);

  return (
    <DashboardLayout
      title="Edit Company Insurance Policy"
      actionButtons={
        <>
          <Box className="flex flex-row items-center whitespace-nowrap gap-3">
            <CreateButton
              name="Create"
              onClick={() =>
                navigate("/admin/company-insurance-policy/list/create")
              }
            />
          </Box>
        </>
      }
    >
      <GlassCard className="p-5">
        {loading ? (
          <CommonLoader />
        ) : (
          <form onSubmit={handleSubmit(handleUpdateCompanyInsurance)}>
            <LocalizationProvider dateAdapter={AdapterDayjs}>
              <Grid container spacing={3}>
                <Grid item md={GridValue}>
                  <FmAutoComplete
                    name="companyInsurancePolicyId"
                    control={control}
                    options={insurancePolicy}
                    label="Insurance Policy"
                    displayField="companyInsurancePolicyName"
                    optionFields={["companyInsurancePolicyName"]}
                    valueKey="companyInsurancePolicyId"
                  />
                </Grid>
                <Grid item md={GridValue}>
                  <FmAutoComplete
                    name="companyInsurancePolicyEndUserId"
                    control={control}
                    options={endUser}
                    label="End User"
                    displayField="companyInsurancePolicyEndUserName"
                    optionFields={["companyInsurancePolicyEndUserName"]}
                    valueKey="companyInsurancePolicyEndUserId"
                  />
                </Grid>
                <Grid item md={GridValue}>
                  <FmAutoComplete
                    name="companyInsurancePolicyTypeOfProductId"
                    control={control}
                    options={typeOfProduct}
                    label="Type of Product"
                    displayField="companyInsurancePolicyTypeOfProductName"
                    optionFields={["companyInsurancePolicyTypeOfProductName"]}
                    valueKey="companyInsurancePolicyTypeOfProductId"
                  />
                </Grid>
                <Grid item md={GridValue}>
                  <FmAutoComplete
                    name="companyInsurancePolicyTypeId"
                    control={control}
                    options={insurer}
                    label="Insurer"
                    displayField="companyInsurancePolicyInsurerName"
                    optionFields={["companyInsurancePolicyInsurerName"]}
                    valueKey="companyInsurancePolicyTypeId"
                  />
                </Grid>
                <Grid item md={GridValue}>
                  <FmTextField
                    name="companyInsurancePolicyPremiumId"
                    label="Amount"
                    control={control}
                    pattern="Number"
                  />
                </Grid>
                <Grid item md={GridValue}>
                  <FmTextField
                    name="policyDetail"
                    label="Policy Details"
                    control={control}
                  />
                </Grid>
                <Grid item md={GridValue}>
                  <FmTextField
                    name="brokerName"
                    control={control}
                    label="Broker"
                  />
                </Grid>
                <Grid item md={GridValue}>
                  <FmTextField
                    name="mobileNumber"
                    control={control}
                    label="Mobile Number"
                    pattern="Number"
                  />
                </Grid>
                <Grid item md={GridValue}>
                  <FmDatePicker
                    name="startDate"
                    label="Start Date"
                    control={control}
                  />
                </Grid>
                <Grid item md={GridValue}>
                  <FmDatePicker
                    name="endDate"
                    label="End Date"
                    control={control}
                    defaultValue={null}
                    minDate={dayjs(startDate).add(1, "day")}
                  />
                </Grid>
                <Grid item md={GridValue}>
                  <FmTextField
                    name="insuredSum"
                    control={control}
                    label="Insured Sum Details"
                  />
                </Grid>
                <Grid item md={GridValue}>
                  <FmTextField
                    name="priorNotificationDays"
                    label="Prior Notification Days"
                    control={control}
                    pattern="Number"
                    minLength={1}
                  />
                </Grid>
                <Grid item md={GridValue}>
                  <UploadModalBox
                    onSubmitFiles={handleFileIds}
                    files={files}
                    setFiles={setFiles}
                    previewdData={previewdData}
                  />
                </Grid>
                <Grid item md={GridValue}>
                  <FmTextField
                    name="description"
                    label="Description"
                    control={control}
                    multiline
                    rows={4}
                  />
                </Grid>
                <Grid item md={GridValue}>
                  <FmTextField
                    name="remarks"
                    control={control}
                    label="Remarks Notification"
                    multiline
                    rows={4}
                  />
                </Grid>
              </Grid>
            </LocalizationProvider>
            <ActionButtons
              onSubmit={handleSubmit(handleUpdateCompanyInsurance)}
              onCancel={() => navigate(-1)}
              onReset={reset}
              submitText="Update"
              cancelText="Go Back"
              submitLoading={editLoading}
              cancelLoading={false}
              //
            />
          </form>
        )}
      </GlassCard>
    </DashboardLayout>
  );
};

export default EditCompanyInsurancePolicy;
