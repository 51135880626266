import React from 'react';
import { useNavigate } from 'react-router-dom';
import DashboardLayout from '../../../../components/DashboardLayout.tsx';
import { BacktoList } from '../../../../components/small/BackToButton.tsx';
import FieldsCreateCreditJournal from './FieldsCreateCreditJournal.tsx';

const CreateJournalEntries = () => {


  return (
    <DashboardLayout
      title={'Create Credit Note'}
      actionButtons={<BacktoList />}
      titleReverse
    >
      <FieldsCreateCreditJournal />
    </DashboardLayout>
  );
};

export default CreateJournalEntries;
