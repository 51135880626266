import { yupResolver } from '@hookform/resolvers/yup';
import { Grid } from '@mui/material';
import { AdapterDayjs } from '@mui/x-date-pickers/AdapterDayjs';
import { LocalizationProvider } from '@mui/x-date-pickers/LocalizationProvider';
import dayjs from 'dayjs';
import React, { useEffect, useState } from 'react';
import { useForm } from 'react-hook-form';
import { useTranslation } from 'react-i18next';
import { useNavigate, useParams } from 'react-router-dom';
import { toast } from 'react-toastify';
import Crud_Service from '../../../apis/CrudService';
import { ActionButtons } from '../../../components/_form';
import FmAutoComplete from '../../../components/_mui/FmAutoComplete.tsx';
import FmDatePicker from '../../../components/_mui/FmDatePicker.tsx';
import FmTextField from '../../../components/_mui/FmTextField.tsx';
import DashboardLayout from '../../../components/DashboardLayout.tsx';
import useCommonFetchApi from '../../../components/helpers/useCommonFetchApi.tsx';
import useLoader from '../../../components/helpers/UseLoader.tsx';
import CommonLoader from '../../../components/page/CommonLoader.jsx';
import GlassCard from '../../../components/small/GlassCard.tsx';
import { contractSchema } from '../../../utils/CommonvalidationSchemas.tsx';
import FmSearchableSelect from '../../../components/_mui/FmSearchableSelect.tsx';
import {contractStatus,statusOptions} from '../../../utils/CommonVariables.tsx';

const gridValue = 2.4;

const EditContracts = () => {
  const navigate = useNavigate();
  const crud = new Crud_Service();
  const [loading, setLoading] = useState(false);
  const [editData, setEditData] = useState(null);
  const [contractGuarantees, setContractGuarantees] = useState([]);
  const [contractScopeMaps, setContractScopeMaps] = useState([]);
  const [contractScopeIds, setContractScopeIds] = useState([]);
  const [guaranteeIds, setGuaranteeIds] = useState([]);
  const { id } = useParams();

  const { handleSubmit, control, setValue, reset, watch } = useForm({
    resolver: yupResolver(contractSchema),
    mode: 'onChange',
  });
  const { createLoading, startCreateLoading, stopCreateLoading } = useLoader();
  const { t } = useTranslation();

  useEffect(() => {
    getAllContracts();
  }, []);
  //fetch All Apis
  const { data: contractmodes } = useCommonFetchApi('contracts/contract-modes');
  const { data: projects } = useCommonFetchApi('projects');
  const { data: clients } = useCommonFetchApi('clients');
  const { data: contracttypes } = useCommonFetchApi('contracttypes');
  const { data: contractscopes } = useCommonFetchApi('contractscopes');
  const { data: billingfrequencies } = useCommonFetchApi('billingfrequencies');
  const { data: guarantees } = useCommonFetchApi('contractguarantees');
  const { data: currencies } = useCommonFetchApi('currencies');
  const getAllContracts = async () => {
    setLoading(true);
    await crud.getSingle('contracts', id, (_err, res) => {
      if (res?.status === 200) {
        const { contractGuarantees, contractScopeMaps, ...formData } = res.data;

        const contractScopeIds = contractScopeMaps.map(
          (item) => item.contractScopeId
        );
        const guaranteeIds = contractGuarantees.map((item) => item.guaranteeId);
        setValue('guaranteeIds', guaranteeIds);
        setValue('contractScopeIds', contractScopeIds);
        setContractGuarantees(contractGuarantees);
        setContractScopeMaps(contractScopeMaps);
        setContractScopeIds(contractScopeIds);
        setGuaranteeIds(guaranteeIds);

        Object.entries(formData).forEach(([key, value]) => {
          setValue(key, value);
        });
        setEditData(res?.data);
      }
      setLoading(false);
    });
  };

  const handleSubmitProject = async (values) => {
    startCreateLoading();
    const CombinedData = {
      ...values,
      guaranteeIds,
      contractStartDate: dayjs(values?.contractStartDate).format('YYYY-MM-DD'),
      contractEndDate: dayjs(values?.contractEndDate).format('YYYY-MM-DD'),
      guaranteeStartDate: dayjs(values?.guaranteeStartDate).format(
        'YYYY-MM-DD'
      ),
      guaranteeEndDate: dayjs(values?.guaranteeEndDate).format('YYYY-MM-DD'),
    };

    await crud.update(`contracts`, id, CombinedData, (_err, res) => {
      if (res?.status === 200) {
        toast.success('Contract Updated Successfully');
        navigate(-1);
        stopCreateLoading();
      } else {
        stopCreateLoading();
      }
    });
  };

  return (
    <DashboardLayout title='Edit Contract'>
      <GlassCard className='p-4'>
        {loading ? (
          <CommonLoader />
        ) : (
          <form onSubmit={handleSubmit(handleSubmitProject)}>
            <LocalizationProvider dateAdapter={AdapterDayjs}>
              <Grid container spacing={3}>
                <Grid item md={gridValue}>
                  <FmTextField
                    name='contractName'
                    control={control}
                    label='Contract Name'
                    required
                  />
                </Grid>
                <Grid item md={gridValue}>
                  <FmTextField
                    name='expectedContractNumber'
                    control={control}
                    label='Contract Code'
                    required
                  />
                </Grid>

                <Grid item md={gridValue}>
                  <FmSearchableSelect
                    name='projectId'
                    control={control}
                    apiUrl='projects'
                    valueField='projectId'
                    labelField={['projectCode', 'projectName']}
                    showField={['projectCode', 'projectName']}
                    label={'Project'}
                    required
                    defaultValue={{
                      projectId: editData?.projectId,
                      projectName: editData?.projectName
                    }}
                  />
                </Grid>

                <Grid item md={gridValue}>
                  <FmSearchableSelect
                    name='clientId'
                    control={control}
                    apiUrl='clients'
                    valueField='clientId'
                    labelField={['clientCode', 'clientName']}
                    showField={['clientCode', 'clientName']}
                    label={'Clients'}
                    required
                    defaultValue={{
                      clientId: editData?.clientId,
                      clientName: editData?.clientName
                    }}
                  />

                </Grid>

                <Grid item md={gridValue}>
                  <FmSearchableSelect
                    name='branchId'
                    control={control}
                    apiUrl='branches'
                    valueField='branchId'
                    headerField={['Code', 'Name']}
                    labelField={['branchCode', 'branchName']}
                    showField={['branchCode', 'branchName']}
                    label={'Branch'}
                    defaultValue={{
                      branchId: editData?.branchId,
                      branchName: editData?.branchName
                    }}
                  />
                </Grid>
                <Grid item md={gridValue}>
                  <FmSearchableSelect
                    name='divisionId'
                    control={control}
                    apiUrl='divisions'
                    valueField='divisionId'
                    headerField={['Code', 'Name']}
                    labelField={['divisionCode', 'divisionName']}
                    showField={['divisionCode', 'divisionName']}
                    label='Division'
                    defaultValue={{
                      divisionId: editData?.divisionId,
                      divisionName: editData?.divisionName
                    }}
                  />
                </Grid>
                <Grid item md={gridValue}>
                  <FmSearchableSelect
                    name='locationId'
                    control={control}
                    apiUrl='locations'
                    label='Location'
                    valueField='locationId'
                    pageSize={1000}
                    headerField={['Code', 'Name']}
                    labelField={['locationCode', 'locationName']}
                    showField={['locationCode', 'locationName']}
                    defaultValue={{
                      locationId: editData?.locationId,
                      locationName: editData?.locationName
                    }}

                  />
                </Grid>
                <Grid item md={gridValue}>
                  <FmDatePicker
                    name='contractStartDate'
                    control={control}
                    label='Contract Start Date'
                  />
                </Grid>
                <Grid item md={gridValue}>
                  <FmDatePicker
                    name='contractEndDate'
                    control={control}
                    label='Contract End Date'
                    minDate={dayjs(watch('contractStartDate'))}
                  />
                </Grid>
                <Grid item md={gridValue}>
                  <FmTextField
                    name='contactPerson'
                    control={control}
                    label='Contact Person'
                    required
                  />
                </Grid>
                <Grid item md={gridValue}>
                  <FmTextField
                    name='contactNumber'
                    control={control}
                    label='Contact Number'
                    pattern='Number'
                    required
                  />
                </Grid>
                <Grid item md={gridValue}>
                  <FmTextField
                    name='contactEmailId'
                    control={control}
                    label='Contact Email'
                    pattern='Email'
                    required
                  />
                </Grid>
                <Grid item md={gridValue}>
                  <FmTextField
                    name='contractCost'
                    label='Contract Cost'
                    control={control}
                    pattern='Number'
                    required
                  />
                </Grid>
                <Grid item md={gridValue}>
                  <FmTextField
                    name='budget'
                    label='Budget'
                    control={control}
                    pattern='Number'
                    required
                  />
                </Grid>
                <Grid item md={gridValue}>
                  <FmTextField
                    name='contractTerms'
                    label='Contract Terms'
                    control={control}
                    required
                    multiline={true}
                  />
                </Grid>
                <Grid item md={gridValue}>
                  <FmTextField
                    name='perviousContractCode'
                    control={control}
                    required
                    label='Previous Contract Code'
                  />
                </Grid>
                <Grid item md={gridValue}>
                  <FmAutoComplete
                    name='contractModeId'
                    control={control}
                    label='Contract Mode'
                    options={contractmodes}
                    optionFields={['contractModeName']}
                    displayField='contractModeName'
                    valueKey='contractModeId'
                    required
                  />
                </Grid>

                <Grid item md={gridValue}>
                  <FmTextField
                    name='referenceNumber'
                    control={control}
                    label='Reference Number'
                    required
                  />
                </Grid>
                <Grid item md={gridValue}>
                  <FmAutoComplete
                    name='contractTypeId'
                    control={control}
                    label='Contract Types'
                    options={contracttypes}
                    optionFields={['contractTypeName']}
                    displayField='contractTypeName'
                    valueKey='contractTypeId'
                    required
                  />
                </Grid>
                <Grid item md={gridValue * 2}>
                  <FmAutoComplete
                    name='contractScopeIds'
                    control={control}
                    label='Contract Scopes'
                    options={contractscopes}
                    optionFields={['contractScopeName']}
                    displayField='contractScopeName'
                    valueKey='contractScopeId'
                    isMultiSelect
                  // defaultValue={contractScopeIds}
                  />
                </Grid>
                <Grid item md={gridValue}>
                  <FmAutoComplete
                    name='billingFrequencyId'
                    control={control}
                    label='Billing Frequency'
                    options={billingfrequencies}
                    optionFields={['billingFrequencyName']}
                    displayField='billingFrequencyName'
                    valueKey='billingFrequencyId'
                  />
                </Grid>
                <Grid item md={gridValue}>
                  <FmTextField
                    name='thresholdValue'
                    control={control}
                    label='Threshold Value'
                  />
                </Grid>
                <Grid item md={gridValue}>
                  <FmAutoComplete
                    name='currencyId'
                    control={control}
                    label='Currrencies'
                    options={currencies}
                    optionFields={['currencyName']}
                    displayField='currencyName'
                    valueKey='currencyId'
                  />
                </Grid>
                <Grid item md={gridValue * 2}>
                  <FmAutoComplete
                    name='guaranteeIds'
                    control={control}
                    label='Guarantees'
                    options={guarantees}
                    optionFields={['guaranteeName']}
                    displayField='guaranteeName'
                    valueKey='guaranteeId'
                    required
                    isMultiSelect
                    defaultValue={guaranteeIds}
                  />
                </Grid>
                <Grid item md={gridValue}>
                  <FmDatePicker
                    name='guaranteeStartDate'
                    control={control}
                    label='Guarantee Start Date'
                  />
                </Grid>
                <Grid item md={gridValue}>
                  <FmDatePicker
                    name='guaranteeEndDate'
                    control={control}
                    label='Guarantee End Date'
                    minDate={dayjs(watch('guaranteeStartDate'))}
                  />
                </Grid>
                <Grid item md={gridValue}>
                  <FmTextField
                    name='guaranteeAmount'
                    control={control}
                    label='Guarantee Amount'
                    pattern='Number'
                  />
                </Grid>
                <Grid item md={gridValue}>
                  <FmAutoComplete
                    name='status'
                    control={control}
                    label='Status'
                    options={statusOptions}
                    optionFields={['statusName']}
                    displayField='statusName'
                    valueKey='statusId'
                  />
                </Grid>
                <Grid item md={gridValue}>
                  <FmAutoComplete
                    name='contractStatusId'
                    control={control}
                    label='Contract Status'
                    options={contractStatus}
                    optionFields={['contractStatusName']}
                    displayField='contractStatusName'
                    valueKey='contractStatusId'
                    defaultValue={
                      editData?.contractStatusId
                        ? {
                          contractStatusId: editData?.contractStatusId,
                          contractStatusName: editData?.contractStatusName,
                        }
                        : contractStatus.find((status) => status.contractStatusId === 1) // Default to "Draft"
                    }
                  />
                </Grid>
                <Grid item md={gridValue * 3}>
                  <FmTextField
                    name='remarks'
                    control={control}
                    label='Remarks'
                    multiline={true}
                    rows={2}
                    maxRows={3}
                  />
                </Grid>
              </Grid>
              <br />
              <ActionButtons
                onSubmit={handleSubmit(handleSubmitProject)}
                onCancel={() => navigate(-1)}
                onReset={reset}
                submitLoading={createLoading}
                cancelLoading={false}
                submitText='Update'
              />
            </LocalizationProvider>
          </form>
        )}
      </GlassCard>
    </DashboardLayout>
  );
};

export default EditContracts;
