import React, { useEffect, useRef, useState } from 'react';
import GlassCard from '../../../components/small/GlassCard.tsx';
import { Grid } from '@mui/material';
import MuiSignModal from '../../../components/_mui/MuiSignModal.tsx';
import useCommonFetchApi from '../../../components/helpers/useCommonFetchApi.tsx';
import { DatePicker, LocalizationProvider } from '@mui/x-date-pickers';
import { AdapterDayjs } from '@mui/x-date-pickers/AdapterDayjs';
import { Controller, useForm } from 'react-hook-form';
import FmSearchableSelect from '../../../components/_mui/FmSearchableSelect.tsx';
import dayjs from 'dayjs';
import { yupResolver } from '@hookform/resolvers/yup';
import Crud_Service from '../../../apis/CrudService.jsx';
import useLoader from '../../../components/helpers/UseLoader.tsx';
import { toast } from 'react-toastify';
import FmTextField from '../../../components/_mui/FmTextField.tsx';
import FmAutoComplete from '../../../components/_mui/FmAutoComplete.tsx';
import { t } from 'i18next';
import ActionButtons from '../../../components/_form/ActionButtons.tsx';
import { AddIncidentReportSchema } from '../../../utils/CommonvalidationSchemas.tsx';
import DashboardLayout from '../../../components/DashboardLayout.tsx';
import { useParams } from 'react-router-dom';

const GridSize = 2.4;

const AddIncidentReport = () => {
  const crud = new Crud_Service();
  const { startCreateLoading, stopCreateLoading, createLoading } = useLoader();
  const sigCanvas = useRef(null);
  const [signatureImage, setSignatureImage] = useState(null);
  const [showModal, setShowModal] = useState(false);
  const [editData,setEditData]=React.useState(null)
  const id=useParams()?.id


  const { data: contracts } = useCommonFetchApi('contracts') || {
    data: [],
  };

  const { data: loginusers } = useCommonFetchApi('loginusers') || {
    data: [],
  };

  const { control, handleSubmit, reset } = useForm({
    resolver: yupResolver(AddIncidentReportSchema),
    mode: 'onChange',
    defaultValues: {
      incidentDate: dayjs().format('YYYY-MM-DD'),
      dateOfWorkResumption: dayjs().format('YYYY-MM-DD'),
    },
  });


  useEffect(() => {
    if (id) {
      (async () => {
        await crud.getSingle('incidentreports',id, (err, res) => {
          if (res?.status === 200) {
            setEditData(res.data)
            Object.entries({
              ...res.data,
            }).forEach(([key, value]) => {
              // Add your logic here for handling key-value pairs
            });
          } else {
            // Handle error response here
          }
        });
      })();
    }
  }, [id]);
  

  const [signatureId, setSignatureId] = useState(null);

  const handleSignatureUpload = (fileId) => {
    setSignatureId(fileId);
  };

  const handleSaveSignature = async () => {
    if (sigCanvas.current && !sigCanvas.current.isEmpty()) {
      const signatureImage = sigCanvas.current.toDataURL('image/png');
      await uploadSignature(signatureImage);
    }
    setShowModal(false);
  };

  const handleSubmitIncidentReport = async (data) => {
    startCreateLoading();
    if (!signatureId) {
      toast.error('Please Upload Signature');
      return;
    }
    const combinedData = {
      ...data,
      preparedBy: data.preparedBy,
      incidentDate: dayjs(data.date).format('YYYY-MM-DD'),
      sign: signatureId,
      dateOfWorkResumption: dayjs(data.date).format('YYYY-MM-DD'),
    };
    await crud.create('incidentreports', combinedData, (err, res) => {
      stopCreateLoading();
      if (err) {
        return;
      }
      if (res?.status === 201) {
        toast.success('Incident Report Created successfully');
        reset();
      } else {
      }
    });
  };
  const handleClearSignature = () => {
    if (sigCanvas.current) {
      sigCanvas.current.clear();
    }
    setSignatureImage(null);
  };

  const uploadSignature = async (base64Image) => {
    const blob = await fetch(base64Image).then((res) => res.blob());
    const fileFormData = new FormData();
    fileFormData.append('file', blob, 'signature.png');
    fileFormData.append('fileTypeId', 52);
    await crud.create(`/files/upload`, fileFormData, (_err, res) => {
      if (res?.status === 200) {
        setSignatureId(res?.data?.fileId);
        toast.success(t('signature_upload_successful'));
      } else {
      }
    });
  };

  return (
    <DashboardLayout title='Incident Report'>
      <GlassCard className='p-3'>
        <form onSubmit={handleSubmit(handleSubmitIncidentReport)}>
          <LocalizationProvider dateAdapter={AdapterDayjs}>
            <Grid container spacing={2}>
              <Grid item md={GridSize}>
                <FmSearchableSelect
                  name='companyId'
                  control={control}
                  apiUrl='companies'
                  valueField='companyId'
                  headerField={['Company Code', 'Company Name']}
                  labelField={['companyCode', 'companyName']}
                  showField={['companyName']}
                  label='Company'
                  required
                  defaultValue={{
                    companyId: editData?.companyId || null,
                    companyName: editData?.companyName || '',
                  }}
                />
              </Grid>
              <Grid item md={GridSize}>
                <FmSearchableSelect
                  name='locationId'
                  control={control}
                  apiUrl='locations'
                  valueField='locationId'
                  headerField={['Location Code', 'Location Name']}
                  labelField={['locationCode', 'locationName']}
                  showField={['locationName']}
                  label='Location'
                  required
                  defaultValue={{
                    locationId: editData?.locationId || null,
                    locationName: editData?.locationName || '',
                  }}
                />
              </Grid>

              <Grid item md={GridSize}>
                <FmSearchableSelect
                  name='clientId'
                  control={control}
                  apiUrl='clients'
                  valueField='clientId'
                  headerField={['Client Code', 'Client Name']}
                  labelField={['clientCode', 'clientName']}
                  showField={['clientName']}
                  label='Client'
                  required
                  defaultValue={{
                    clientId: editData?.clientId || null,
                    clientName: editData?.clientName || '',
                  }}
                />
              </Grid>

              <Grid item md={GridSize}>
                <FmSearchableSelect
                  name='divisionId'
                  control={control}
                  apiUrl='divisions'
                  valueField='divisionId'
                  headerField={['Division Code', 'Division Name']}
                  labelField={['divisionCode', 'divisionName']}
                  showField={['divisionName']}
                  label='Division'
                  required
                  defaultValue={{
                    divisionId: editData?.divisionId || null,
                    divisionName: editData?.divisionName || '',
                  }}
                />
              </Grid>

              <Grid item md={GridSize}>
                <FmSearchableSelect
                  name='projectId'
                  control={control}
                  apiUrl='projects'
                  valueField='projectId'
                  headerField={['Project Code', 'Project Name']}
                  labelField={['projectCode', 'projectName']}
                  showField={['projectName']}
                  label='Project'
                  required
                  defaultValue={{
                    projectId: editData?.projectId || null,
                    projectName: editData?.projectName || '',
                  }}
                />
              </Grid>

              <Grid item md={GridSize}>
                <FmAutoComplete
                  name='contractId'
                  control={control}
                  options={contracts}
                  label='Contract Code'
                  displayField='contractCode'
                  optionFields={['contractCode']}
                  valueKey='contractId'
                />
              </Grid>

              <Grid item md={GridSize}>
                <FmSearchableSelect
                  name='buildingId'
                  control={control}
                  apiUrl='buildings'
                  valueField='buildingId'
                  headerField={['Building Code', 'Building Name']}
                  labelField={['buildingCode', 'buildingName']}
                  showField={['buildingName']}
                  label='Building'
                  required
                  defaultValue={{
                    buildingId: editData?.buildingId || null,
                    buildingName: editData?.buildingName || '',
                  }}
                />
              </Grid>

              <Grid item md={GridSize}>
                <FmTextField name='area' label='Area' control={control} />
              </Grid>

              <Grid item md={GridSize}>
                <FmAutoComplete
                  name='preparedBy'
                  control={control}
                  options={loginusers}
                  label='Prepared By'
                  displayField='employeeName'
                  optionFields={['employeeName']}
                  valueKey='loginUserId'
                />
              </Grid>
              <Grid item md={GridSize}>
                <MuiSignModal
                  onSignatureUpload={handleSignatureUpload}
                  // handleUpload={handleUpload}
                  signatureImage={signatureImage}
                  sigCanvas={sigCanvas}
                  handleSaveSignature={handleSaveSignature}
                  showModal={showModal}
                  setShowModal={setShowModal}
                  handleClearSignature={handleClearSignature}
                />
              </Grid>

              <Grid item md={GridSize}>
                <FmAutoComplete
                  name='verifiedBy'
                  control={control}
                  options={loginusers}
                  label='Verified By'
                  displayField='employeeName'
                  optionFields={['employeeName']}
                  valueKey='loginUserId'
                />
              </Grid>

              <Grid item md={GridSize}>
                <FmSearchableSelect
                  name='typeOfIncidentId'
                  control={control}
                  apiUrl='typeofincidents'
                  valueField='typeOfIncidentId'
                  headerField={['Incident Code', 'Incident Name']}
                  labelField={['typeOfIncidentCode', 'typeOfIncidentName']}
                  showField={['typeOfIncidentName']}
                  label='Type of Accident'
                  required
                  defaultValue={{
                    typeOfIncidentId: editData?.typeOfIncidentId || null,
                    typeOfIncidentName: editData?.typeOfIncidentName || '',
                  }}
                />
              </Grid>

              <Grid item md={GridSize}>
                <Controller
                  name='incidentDate'
                  control={control}
                  render={({ field: { onChange, value } }) => (
                    <DatePicker
                      label={<>Date of Incident</>}
                      value={value ? dayjs(value) : null}
                      format='DD/MM/YYYY'
                      onChange={(newValue) => {
                        onChange(newValue);
                        // setSelectedValue(newValue, 'date');
                      }}
                      sx={{ width: '100%' }}
                    />
                  )}
                />{' '}
              </Grid>

              <Grid item md={GridSize}>
                <Controller
                  name='dateOfWorkResumption'
                  control={control}
                  render={({ field: { onChange, value } }) => (
                    <DatePicker
                      label={
                        <>
                          Date of Work Resumption
                          <span style={{ color: 'red' }}> *</span>
                        </>
                      }
                      value={value ? dayjs(value) : null}
                      format='DD/MM/YYYY'
                      onChange={(newValue) => {
                        onChange(newValue);
                        // setSelectedValue(newValue, 'date');
                      }}
                      sx={{ width: '100%' }}
                    />
                  )}
                />{' '}
              </Grid>

              <Grid item md={GridSize}>
                <FmTextField
                  name='incidentDescription'
                  label='Incident Description'
                  control={control}
                />
              </Grid>

              <Grid item md={GridSize}>
                <FmTextField
                  name='incidentImpact'
                  label='Incident Impact'
                  control={control}
                />
              </Grid>
              <Grid item md={GridSize}>
                <FmTextField
                  name='briefDescription'
                  label='Brief Description'
                  control={control}
                />
              </Grid>
              <Grid item md={GridSize}>
                <FmTextField
                  name='rootCauseAnalysis'
                  label='Root Cause Analysis'
                  control={control}
                />
              </Grid>
              <Grid item md={GridSize}>
                <FmTextField
                  name='actionPlan'
                  label='Action Plan'
                  control={control}
                />
              </Grid>
              <Grid item md={GridSize * 3}>
                <FmTextField name='remarks' label='Remarks' control={control} />
              </Grid>
            </Grid>
            <ActionButtons
              onSubmit={handleSubmit(handleSubmitIncidentReport)}
              onReset={reset}
              // submitLoading={createLoading}
            />
          </LocalizationProvider>
        </form>
      </GlassCard>
    </DashboardLayout>
  );
};

export default AddIncidentReport;
