import React, { useEffect } from 'react';
import { Box, Button } from '@mui/material';
import { Controller, useForm } from 'react-hook-form';
import TableActionButtons from '../../../../../../components/_form/TableActionButtons.jsx';
import FmAutoComplete from '../../../../../../components/_mui/FmAutoComplete.tsx';
import FmTextField from '../../../../../../components/_mui/FmTextField.tsx';
import MuiDatePickerOne from '../../../../../../components/_mui/MuiDatePickerOne.tsx';
import { BooleanOptions } from '../../../../../../utils/CommonVariables.tsx';
import dayjs from 'dayjs';
import FmSearchableSelect from '../../../../../../components/_mui/FmSearchableSelect.tsx';
import FmMRTDataTable from '../../../../../../components/FmMRTDataTable.tsx';
import { yupResolver } from '@hookform/resolvers/yup';
import { InjuriesValidationSchema } from '../../../../../../utils/CommonvalidationSchemas.tsx';
import FmDatePicker from '../../../../../../components/_mui/FmDatePicker.tsx';

const InjuriesDataTable = (props) => {
  const {
    rows,
    editingRowId,
    editingRowData,
    handleUpdateInjuries,
    ActionData,
    handleCancel,
    pagination,
    sorting,
    setPagination,
    setSorting,
    columnFilters,
    globalFilter,
    setColumnFilters,
    setGlobalFilter,
    isLoading,
    setSearchKeyword,
    searchKeyword,
    tableRecordCounts,
  } = props;

  const { handleSubmit, control, setValue } = useForm({
    resolver: yupResolver(InjuriesValidationSchema),
    mode: 'onChange',
  });

  useEffect(() => {
    if (editingRowData) {
      Object.entries({
        ...editingRowData,
      }).forEach(([key, value]) => {
        setValue(key, value);
      });
    }
  }, [editingRowData, setValue]);

  const columns = [
    {
      header: 'Actions',
      enableColumnPinning: true,
      enableEditing: false,
      enableSorting: false,
      Cell: ({ row }) => (
        <Box className='flex gap-2'>
          {editingRowId === row?.original?.injuriesDetailId ? (
            <>
              <Button onClick={handleSubmit(handleUpdateInjuries)}>Save</Button>
              <Button onClick={handleCancel}>Cancel</Button>
            </>
          ) : (
            <TableActionButtons
              Actions={ActionData?.map((action) => ({
                ...action,
                onClick: () => action.onClick(row?.original),
              }))}
            />
          )}
        </Box>
      ),
    },
    {
      accessorKey: 'injuriesUserName',
      header: 'Injuries User',
      width: 150,
      enableEditing: true,
      Cell: ({ cell }) => {
        return editingRowId === cell?.row?.original?.injuriesDetailId ? (
          <FmTextField
            name='injuriesUserName'
            control={control}
            defaultValue={cell?.row?.original?.injuriesUserName}
          />
        ) : (
          rows?.find((item) => item?.injuriesUserName === cell.getValue())
            ?.injuriesUserName || ''
        );
      },
    },
    {
      accessorKey: 'employeeId',
      header: 'Employees',
      width: 150,
      enableEditing: true,
      Cell: ({ cell }) => {
        return editingRowId === cell?.row?.original?.injuriesDetailId ? (
          <FmSearchableSelect
            name='employeeId'
            control={control}
            apiUrl='employees'
            valueField='employeeId'
            headerField={['Code', 'Name', 'Email']}
            labelField={['employeeCode', 'fullName', 'emailId']}
            showField={['employeeCode', 'fullName', 'emailId']}
            label='Employees'
            required
            defaultValue={{
              employeeId: cell?.row?.original?.employeeId,
              firstName: cell?.row?.original?.employeeName,
            }}
          />
        ) : (
          rows?.find((item) => item?.employeeId === cell.getValue())
            ?.employeeName || ''
        );
      },
    },
    {
      accessorKey: 'companyId',
      header: 'Companies',
      width: 150,
      enableEditing: true,
      Cell: ({ cell }) => {
        const projectTypeId = cell.getValue();

        return editingRowId === cell?.row?.original?.injuriesDetailId ? (
          <FmSearchableSelect
            name='companyId'
            control={control}
            apiUrl='companies'
            valueField='companyId'
            headerField={['Company Code', 'Company Name']}
            labelField={['companyCode', 'companyName']}
            showField={['companyName']}
            
            label='Companies'
            defaultValue={{
              companyId: cell?.row?.original?.companyId,
              companyName: cell?.row?.original?.companyName,
            }}
          />
        ) : (
          rows?.find((item) => item.companyId === projectTypeId)?.companyName ||
            ''
        );
      },
    },
    {
      accessorKey: 'positionId',
      header: 'Positions',
      width: 150,
      enableEditing: true,
      Cell: ({ cell }) => {
        return editingRowId === cell?.row?.original?.injuriesDetailId ? (
          <FmSearchableSelect
            name='positionId'
            control={control}
            apiUrl='positions'
            valueField='positionId'
            headerField={['Position Code', 'Position Name']}
            labelField={['positionCode', 'positionName']}
            showField={['positionName']}
            
            label='Positions'
            defaultValue={{
              positionId: cell?.row?.original?.positionId,
              positionName: cell?.row?.original?.positionName,
            }}
          />
        ) : (
          rows?.find((item) => item?.positionId === cell.getValue())
            ?.positionName || ''
        );
      },
    },
    {
      accessorKey: 'dateOfSafetyInduction',
      header: 'Date of Safety Induction',
      enableEditing: true,
      Cell: ({ row }) => {
        return editingRowId === row?.original?.injuriesDetailId ? (
          <FmDatePicker
              name="dateOfSafetyInduction"
              label="Requested Date"
              defaultValue={editingRowData?.dateOfSafetyInduction}
              control={control}
              required
            />
        ) : row?.original?.dateOfSafetyInduction ? (
          dayjs(row?.original?.dateOfSafetyInduction).format('DD-MM-YYYY')
        ) : (
          ''
        );
      },
    },

    {
      accessorKey: 'hadIPBriefing',
      header: 'Had IP Briefing',
      enableEditing: true,
      Cell: ({ cell }) => {
        const hadIpBreifing = cell.getValue();
        return editingRowId === cell?.row?.original?.injuriesDetailId ? (
          <FmAutoComplete
            name='hadIPBriefing'
            control={control}
            options={BooleanOptions}
            displayField='label'
            optionFields={['label']}
            valueKey='value'
          />
        ) : hadIpBreifing === 'true' ? (
          'Yes'
        ) : (
          'No' ||
          rows?.find((item) => item?.hadIPBriefing === hadIpBreifing)
            ?.hadIPBriefing ||
          ''
        );
      },
    },
    {
      accessorKey: 'description',
      header: 'Description',
      enableEditing: true,
      Cell: ({ cell }) => {
        return editingRowId === cell?.row?.original?.injuriesDetailId ? (
          <FmTextField
            name='description'
            control={control}
            label='Description'
            defaultValue={cell?.row?.original?.description}
            multiline={true}
            rows={2}
            maxRows={3}
          />
        ) : (
          rows?.find((item) => item?.description === cell.getValue())
            ?.description || ''
        );
      },
    },
    {
      accessorKey: 'remarks',
      header: 'Remarks',
      enableEditing: true,
      Cell: ({ cell }) => {
        return editingRowId === cell?.row?.original?.injuriesDetailId ? (
          <FmTextField
            name='remarks'
            control={control}
            label='Remarks'
            defaultValue={cell?.row?.original?.remarks}
            multiline={true}
            rows={2}
            maxRows={3}
          />
        ) : (
          rows?.find((item) => item?.remarks === cell.getValue())?.remarks || ''
        );
      },
    },
  ];

  return (
    <div>
      <FmMRTDataTable
        columns={columns}
        enableRowSelection={false}
        rows={rows}
        rowCount={tableRecordCounts}
        editingRow={editingRowId}
        pagination={pagination}
        sorting={sorting}
        columnFilters={columnFilters}
        globalFilter={globalFilter}
        setPagination={setPagination}
        setSorting={setSorting}
        setColumnFilters={setColumnFilters}
        setGlobalFilter={setGlobalFilter}
        isLoading={isLoading}
        setSearchKeyword={setSearchKeyword}
        searchKeyword={searchKeyword}
      />
    </div>
  );
};

export default InjuriesDataTable;
