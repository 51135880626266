import { Icon } from '@iconify/react';

export const AttendanceText = (text) => {
  switch (text) {
    case 'P':
      return {
        bg: 'bg-green-500',
        textColor: 'text-green-500',
        name: 'Present',
        shortName: text,
        icon: <Icon icon='f7:square-fill' />,
      };

    case 'PH':
      return {
        bg: 'bg-purple-500',
        textColor: 'text-purple-500',
        name: 'Public Holiday',
        shortName: text,
        icon: <Icon icon='f7:square-fill' />,
      };

    case 'H':
      return {
        bg: 'bg-indigo-500',
        textColor: 'text-indigo-500',
        name: 'Holiday',
        shortName: text,
        icon: <Icon icon='f7:square-fill' />,
      };

    case 'O':
      return {
        bg: 'bg-green-800',
        textColor: 'text-green-800',
        name: 'Overtime',
        shortName: text,
        icon: <Icon icon='f7:square-fill' />,
      };

    case 'A':
      return {
        bg: 'bg-red-500',
        textColor: 'text-red-500',
        name: 'Absent',
        shortName: text,
        icon: <Icon icon='f7:square-fill' />,
      };

    case 'FH':
      return {
        bg: 'bg-red-500',
        textColor: 'text-red-500',
        name: 'First Half',
        shortName: text,
        icon: <Icon icon='f7:square-righthalf-fill' />,
      };

    case 'SH':
      return {
        bg: 'bg-red-500',
        textColor: 'text-red-500',
        name: 'Second Half',
        shortName: text,
        icon: <Icon icon='f7:square-lefthalf-fill' />,
      };

    default:
      return {
        bg: 'bg-gray-200',
        textColor: 'text-gray-200',
        name: 'Not Available',
        shortName: text,
        icon: <Icon icon='f7:square-fill' />,
      };
  }
};

export const FileNameTypeFunc = (text) => {
  switch (text) {
    case 'doc':
      return {
        textColor: 'text-green-500',
        icon: <Icon icon='flowbite:file-doc-solid' />,
      };

    case 'pdf':
      return {
        textColor: 'text-purple-500',
        icon: <Icon icon='teenyicons:pdf-solid' />,
      };

    case 'jpg':
      return {
        textColor: 'text-indigo-500',
        icon: <Icon icon='teenyicons:jpg-solid' />,
      };

    case 'docx':
      return {
        textColor: 'text-green-800',
        icon: <Icon icon='tabler:file-type-docx' />,
      };

    case 'ppt':
      return {
        textColor: 'text-purple-600',
        icon: <Icon icon='teenyicons:ppt-solid' />,
      };

    case 'xlsx':
      return {
        textColor: 'text-red-600',
        icon: <Icon icon='bi:filetype-xlsx' />,
      };

    case 'txt':
      return {
        textColor: 'text-orange-600',
        icon: <Icon icon='grommet-icons:document-txt' />,
      };

    case 'svg':
      return {
        textColor: 'text-blue-600',
        icon: <Icon icon='teenyicons:svg-solid' />,
      };

    case 'png':
      return {
        textColor: 'text-blue-600',
        icon: <Icon icon='teenyicons:png-solid' />,
      };

    default:
      return {
        textColor: 'text-teal-500',
        icon: <Icon icon='bxs:file' />,
      };
  }
};
