import React, { useEffect, useState } from 'react';
import DashboardLayout from '../../../../components/DashboardLayout.tsx';
import Crud_Service from '../../../../apis/CrudService.jsx';
import { toast } from 'react-toastify';
import GlassCard from '../../../../components/small/GlassCard.tsx';
import { useFieldArray, useForm } from 'react-hook-form';
import {
  Box,
  Button,
  CircularProgress,
  Grid,
  IconButton,
  Tooltip,
} from '@mui/material';
import { Icon } from '@iconify/react';
import { useNavigate } from 'react-router-dom';
import FmSearchableSelect from '../../../../components/_mui/FmSearchableSelect.tsx';
import FmDatePicker from '../../../../components/_mui/FmDatePicker.tsx';
import FmTimePicker from '../../../../components/_mui/FmTimePicker.tsx';
import dayjs from 'dayjs';
import ConfirmationPopoverBox from '../../../../components/_form/ConfirmationPopoverBox.tsx';
import BackToButton from '../../../../components/small/BackToButton.jsx';
import FmAutoComplete from '../../../../components/_mui/FmAutoComplete.tsx';
import useCommonFetchApi from '../../../../components/helpers/useCommonFetchApi.tsx';

function CreateAttendanceForm() {
  const [isLoading, setIsLoading] = useState(false);
  const [createLoading, setCreateLoading] = useState(false);
  const [selectedSalesOrders, setSelectedSalesOrders] = useState({});
  const [formIndex, setFormIndex] = useState(0);
  const [editedFields, setEditedFields] = useState({});
  const [absenceMessages, setAbsenceMessages] = useState([]);
  const { control, handleSubmit, setValue, getValues, watch } = useForm();
  const [disabled, setDisabled] = useState([]);

  const navigate = useNavigate();
  const {
    fields,
    append,
    remove,
    update: updateField,
  } = useFieldArray({
    control,
    name: 'fields',
  });

  useEffect(() => {
    if (fields.length === 0) {
      append({});
    }
  }, [fields.length, append]);

  const crud = new Crud_Service();

  const { data: statusOptions } = useCommonFetchApi(
    'employeeattendancetransactiondisplaycolors'
  );

  const onSubmit = async (values, index = formIndex) => {
    setCreateLoading(true);
    const combinedDate = {
      employeeId: values?.fields?.[index]?.employeeId,
      contractId: values?.fields?.[index]?.contractId,
      projectId: values?.fields?.[index]?.projectId,
      salesOrderId: values?.fields?.[index]?.salesOrderId,
      attendanceStatusId: values?.fields?.[index]?.attendanceStatusId || 1,
      attendanceStatusCode: values?.fields?.[index]?.attendanceCode || 'P',
      startTime:
        values?.fields?.[index]?.attendanceStatusId === 3 ||
        values?.fields?.[index]?.attendanceStatusId === 4 ||
        values?.fields?.[index]?.attendanceStatusId === 5
          ? null
          : dayjs(values?.fields?.[index]?.startTime).isValid()
          ? dayjs(values?.fields?.[index]?.startTime).format('HH:mm:ss')
          : values?.fields?.[index]?.startTime,
      endTime:
        values?.fields?.[index]?.attendanceStatusId === 3 ||
        values?.fields?.[index]?.attendanceStatusId === 4 ||
        values?.fields?.[index]?.attendanceStatusId === 5
          ? null
          : dayjs(values?.fields?.[index]?.endTime).isValid()
          ? dayjs(values?.fields?.[index]?.endTime).format('HH:mm:ss')
          : values?.fields?.[index]?.endTime,
      attendanceDate: dayjs(values?.fields?.[index]?.date).isValid()
        ? dayjs(values?.fields?.[index]?.date).format('YYYY-MM-DD')
        : values?.fields?.[index]?.date,
    };

    const field = fields[index];

    if (field.employeeMonthlyAttendanceId) {
      // Update existing record
      await crud.update(
        'employeemonthlyattendances',
        field.employeeMonthlyAttendanceId,
        combinedDate,
        (err, res) => {
          setCreateLoading(false);
          if (err) {
            toast.error(err);
            return;
          }
          if (res?.status === 200) {
            toast.success('Attendance Updated Successfully!');
            setEditedFields((prev) => {
              const updated = { ...prev };
              delete updated[index];
              return updated;
            });
          }
        }
      );
    } else {
      // Create new record
      await crud.create(
        'employeemonthlyattendances',
        combinedDate,
        (err, res) => {
          setCreateLoading(false);

          if (err) {
            toast.error(err);
            return;
          }

          if (res?.status === 201) {
            const updatedField = {
              ...res.data,
              employeeName:
                res.data.employeeName || fields[index]?.employeeName,
              employeeId: res.data.employeeId || fields[index]?.employeeId,
              salesOrderId:
                res.data.salesOrderId || fields[index]?.salesOrderId,
              salesOrderName:
                res.data.salesOrderName || fields[index]?.salesOrderName,
              attendanceStatusName:
                res.data.attendanceStatusName ||
                fields[index]?.attendanceStatusName,
              attendanceCode: res.data.attendanceCode || 'P',
              date:
                dayjs(res.data.attendanceDate) || values?.fields?.[index]?.date,
              startTime:
                res.data.startTime || values?.fields?.[index]?.startTime,
              endTime: res.data.endTime || values?.fields?.[index]?.endTime,
            };
            setEditedFields({});
            updateField(index, updatedField);
            toast.success('Attendance Created Successfully!');

            if (index === formIndex) {
              setFormIndex((prevIndex) => prevIndex + 1);
            }
          }
        }
      );
    }
  };

  const handleSave = async (index: number) => {
    const values = await control._getWatch();
    onSubmit(values, index);
  };

  const handleRemove = (id, index) => {
    if (id) {
      crud.remove('employeemonthlyattendances', id, (_err, res) => {
        if (res?.status === 204) {
          toast.success('Attendance Deleted Successfully!');
          remove(index);
          setSelectedSalesOrders((prev) => {
            const updated = { ...prev };
            delete updated[index];
            return updated;
          });
          setFormIndex((prevIndex) => prevIndex - 1);
        }
      });
    } else {
      setSelectedSalesOrders((prev) => {
        const updated = { ...prev };
        delete updated[index];
        return updated;
      });
      remove(index);

      setFormIndex((prevIndex) => prevIndex - 1);
    }
  };

  const handleSalesOrderChange = (index, salesOrder) => {
    setSelectedSalesOrders((prev) => ({
      ...prev,
      [index]: salesOrder,
    }));

    handleFieldChange(index, 'salesOrderId');
    setValue('salesOrderId', salesOrder.salesOrderId);
    setValue(`fields[${index}].projectId`, salesOrder.projectId || '');
    setValue(`fields[${index}].contractId`, salesOrder.contractId || '');
  };

  const handleFieldChange = (index: number, fieldName: string) => {
    setEditedFields((prev) => {
      const currentRowEdits = prev[index] || {};
      return {
        ...prev,
        [index]: {
          ...currentRowEdits,
          [fieldName]: true,
          isEdited: true,
        },
      };
    });
  };

  const handleChangeStatus = async (e, index) => {
    setDisabled((prev) => {
      const newDisabled = [...prev];
      newDisabled[index] = e === 3 || e === 4 || e === 5 || e === 8;
      return newDisabled;
    });
  };

  const checkEmployeeLeaveStatus = async (employeeId, date, index) => {
    try {
      await crud.getAll(
        `employeeleaverequests/${employeeId}/date/${date}`,
        {},
        (err, res) => {
          if (err) {
            setAbsenceMessages((prev) => {
              const updated = [...prev];
              updated[index] = 'Error checking absence status.';
              return updated;
            });
            return;
          }

          if (res.data?.data?.length > 0) {
            setAbsenceMessages((prev) => {
              const updated = [...prev];
              updated[index] = 'This employee is absent on the selected date.';
              return updated;
            });
          } else {
            setAbsenceMessages((prev) => {
              const updated = [...prev];
              updated[index] = '';
              return updated;
            });
          }
        }
      );
    } catch (error) {}
  };

  const checkEmployeeLeave = (employeeId, index) => {
    const date = getValues(`fields[${index}].date`);
    if (employeeId && date) {
      checkEmployeeLeaveStatus(
        employeeId,
        dayjs(date).format('YYYY-MM-DD'),
        index
      );
    }
  };

  const handleTimeChange = (index, timeField: 'startTime' | 'endTime') => {
    handleFieldChange(index, timeField);
  };

  const handleEmployeeChange = (e, index) => {
    handleFieldChange(e?.employeeId, 'employeeId');
    checkEmployeeLeave(e?.employeeId, index);
  };

  const getCardBorderStyle = (field, index) => {
    if (editedFields[index]?.isEdited) {
      return 'border-2 border-yellow-500'; // Yellow border for unsaved changes
    }
    if (field.employeeMonthlyAttendanceId) {
      return 'border-2 border-green-500'; // Green border for saved records
    }
    return ''; // No border for new unsaved records
  };

  return (
    <DashboardLayout
      title='Create New Attendance'
      actionButtons={
        <BackToButton
          title={'Go Back'}
          onClick={() => navigate(-1)}
          className='px-0  py-0'
        />
      }
      titleReverse
    >
      <form onSubmit={handleSubmit(onSubmit)}>
        {fields?.map((field, index) => (
          <GlassCard
            key={field.id}
            className={`p-3 mb-3 flex items-center gap-5 ${
              field.employeeMonthlyAttendanceId ? 'opacity-100' : 'opacity-70'
            } ${getCardBorderStyle(field, index)}`}
          >
            <Grid container spacing={1}>
              {/* Employee Name */}
              <Grid item md={3}>
                <FmSearchableSelect
                  control={control}
                  name={`fields[${index}].employeeId`}
                  label='Employee Name'
                  apiUrl='employees'
                  valueField='employeeId'
                  onChangeProp={(e) => handleEmployeeChange(e, index)}
                  headerField={['Code', 'Name', 'Email']}
                  labelField={['employeeCode', 'fullName', 'emailId']}
                  showField={['employeeCode', 'fullName', 'emailId']}
                  defaultValue={{
                    employeeId: field?.employeeId,
                    fullName: field?.employeeName,
                  }}
                  disabled={!!field.employeeMonthlyAttendanceId}
                  required
                />
                {/* Display absence message next to employee field */}
                {absenceMessages[index] && (
                  <div className='text-red-600 text-sm'>
                    {absenceMessages[index]}
                  </div>
                )}
              </Grid>

              {/* Sales Order */}
              <Grid item md={3}>
                <FmSearchableSelect
                  control={control}
                  name={`fields[${index}].salesOrderId`}
                  label='Sales Order'
                  apiUrl='salesorders'
                  valueField='salesOrderId'
                  defaultValue={{
                    salesOrderId: fields[index]?.salesOrderId,
                    salesOrderName: fields[index]?.salesOrderName,
                  }}
                  headerField={['Code', 'Name']}
                  labelField={['autoSalesOrderCode', 'salesOrderName']}
                  showField={['autoSalesOrderCode', 'salesOrderName']}
                  onChangeProp={(ev) => handleSalesOrderChange(index, ev)}
                />
              </Grid>

              <Grid item md={3}>
                <Box>
                  <label className='text-sm text-gray-600'>Contract</label>
                  <div className='text-sm font-bold'>
                    {selectedSalesOrders[index]?.contractName || '-'}
                  </div>
                </Box>
              </Grid>

              <Grid item md={3}>
                <Box>
                  <label className='text-sm text-gray-600'>Project</label>
                  <div className='text-sm font-bold'>
                    {selectedSalesOrders[index]?.projectName || '-'}
                  </div>
                </Box>
              </Grid>

              {/* Attendance Color */}
              <Grid item md={3}>
                <FmAutoComplete
                  name={`fields[${index}].attendanceStatusId`}
                  control={control}
                  label='Attendance Status'
                  options={statusOptions}
                  displayField='attendanceName'
                  valueKey='employeeAttendanceTransactionDisplayColorId'
                  optionFields={['attendanceCode', 'attendanceName']}
                  onChange={(e) => handleChangeStatus(e, index)}
                  required
                />
              </Grid>

              {/* Date */}
              <Grid item md={2}>
                <FmDatePicker
                  control={control}
                  name={`fields[${index}].date`}
                  label='Date'
                  required
                  minDate={dayjs().subtract(31, 'day')}
                  maxDate={dayjs()}
                  onChangeProp={() =>
                    checkEmployeeLeave(
                      getValues(`fields[${index}].employeeId`),
                      index
                    )
                  }
                />
              </Grid>

              {!disabled[index] && (
                <>
                  {' '}
                  <Grid item md={2}>
                    <FmTimePicker
                      control={control}
                      name={`fields[${index}].startTime`}
                      label='Start Time'
                      disabled={disabled[index]}
                      required
                      onChangeProp={() => handleTimeChange(index, 'startTime')}
                    />
                  </Grid>
                  <Grid item md={2}>
                    <FmTimePicker
                      control={control}
                      name={`fields[${index}].endTime`}
                      label='End Time'
                      disabled={disabled[index]}
                      required
                      onChangeProp={() => handleTimeChange(index, 'endTime')}
                    />
                  </Grid>
                </>
              )}
            </Grid>

            <Box className='flex gap-2 ml-auto'>
              <Tooltip
                title={
                  !isLoading && editedFields[index]?.isEdited
                    ? field.employeeMonthlyAttendanceId
                      ? 'Save Changes'
                      : 'Add New Attendance'
                    : ''
                }
              >
                <span>
                  {createLoading ? (
                    <CircularProgress size={24} />
                  ) : (
                    <IconButton
                      onClick={() => handleSave(index)}
                      disabled={
                        isLoading ||
                        !editedFields[index]?.isEdited ||
                        (!disabled[index] &&
                          (watch(`fields[${index}].startTime`) === null ||
                            watch(`fields[${index}].endTime`) === null))
                      }
                      color='primary'
                      sx={{
                        opacity: editedFields[index]?.isEdited ? 1 : 0.3,
                        transition: 'opacity 0.2s',
                      }}
                    >
                      <Icon icon='material-symbols:save' />
                    </IconButton>
                  )}
                </span>
              </Tooltip>
              <ConfirmationPopoverBox
                icon={<Icon icon='tabler:trash' />}
                onConfirm={() =>
                  handleRemove(field?.employeeMonthlyAttendanceId, index)
                }
                title='Delete'
              />
            </Box>
          </GlassCard>
        ))}

        <Box className='flex justify-end'>
          <Button
            variant='contained'
            onClick={() => append({})}
            className='uppercase font-bold py-1 px-3'
            startIcon={<Icon icon='ic:baseline-plus' />}
          >
            Add Employee
          </Button>
        </Box>
      </form>
    </DashboardLayout>
  );
}

export default CreateAttendanceForm;
