import React from 'react';
import { Box } from '@mui/material';
import FmViewDateField from '../../../../components/_mui/FmViewDateField.tsx';
import CurrencyView from '../../../../components/_mui/FmCurrencyView.tsx';
import StatusNewBadge from '../../../../components/_form/StatusNewBadge.tsx';
import { ExpiryDateDueCellText } from '../../../../utils/Date/ExpiryDateCellColor.tsx';
import SimpleIconText from '../../../../components/elements/SimpleIconText.tsx';
import Icons from '../../../../utils/Icon.jsx';
import { PurcaseVoucherName } from '../../../../components/table/MRTCommonColumns.tsx';

const defaultSizes = [120, 180, 60];

const BoldBox = ({ title }) => {
  return <Box>{title}</Box>;
};

const ColumnPurchaseVoucher = ({ rows }) => [
  {
    accessorKey: 'autoPurchaseVoucherCode',
    header: 'Code',
    size: 150,
    Cell: ({ cell }) => {
      const order: any = rows?.find(
        (row: any) =>
          row?.purchaseVoucherId === cell?.row?.original?.purchaseVoucherId
      );
      return (
        <div className='flex flex-col w-full'>
          <div className='flex items-center gap-1'>
            {order?.autoPurchaseVoucherCode}
          </div>
        </div>
      );
    },
  },
  {
    accessorKey: 'purchaseVoucherStatusName',
    header: 'Status',
    size: defaultSizes[0],
    Cell: ({ cell }) => {
      return (
        <StatusNewBadge
          title={cell?.row?.original?.purchaseVoucherStatusName}
          type={cell?.row?.original?.purchaseVoucherStatusName}
        />
      );
    },
  },

  {
    accessorKey: 'paymentDueDate',
    header: 'Due Date',
    enableEditing: true,
    size: 130,
    muiTableBodyCellProps: ({ row }) => ({
      className: ExpiryDateDueCellText(row?.original?.paymentDueDate),
    }),
    Cell: ({ cell }) => {
      return (
        <>
          {cell?.row?.original?.paymentDueDate && (
            <SimpleIconText
              icon={Icons.date}
              text={FmViewDateField({
                value: cell?.row?.original?.paymentDueDate,
                tableFontWeightRemove: true,
              })}
            />
          )}
        </>
      );
    },
  },
  PurcaseVoucherName,
  {
    accessorKey: 'salesOrderId',
    header: 'Sales Order',
    size: defaultSizes[0],
    Cell: ({ cell }) => {
      const order: any = rows?.find(
        (row: any) =>
          row?.purchaseVoucherId === cell?.row?.original?.purchaseVoucherId
      );
      return (
        <div className='flex flex-col w-full'>
          <div className='flex items-center gap-1'>
            <span>Code:</span>
            {order?.salesOrderCode}
          </div>
          <div className='font-semibold'>{order?.salesOrderName}</div>
        </div>
      );
    },
  },

  {
    accessorKey: 'vendorId',
    header: 'Supplier',
    size: defaultSizes[1],
    Cell: ({ cell }) => {
      const order: any = rows?.find(
        (row: any) =>
          row?.purchaseVoucherId === cell?.row?.original?.purchaseVoucherId
      );
      return <BoldBox title={order?.vendorName} />;
    },
  },

  {
    accessorKey: 'currencyId',
    header: 'Currency',
    size: defaultSizes[0],
    Cell: ({ cell }) => {
      const order: any = rows?.find(
        (row: any) =>
          row?.purchaseVoucherId === cell?.row?.original?.purchaseVoucherId
      );
      return <BoldBox title={order?.currencyName} />;
    },
  },

  {
    accessorKey: 'companyId',
    header: 'Company',
    size: defaultSizes[1],
    Cell: ({ cell }) => {
      const order: any = rows?.find(
        (row: any) =>
          row?.purchaseVoucherId === cell?.row?.original?.purchaseVoucherId
      );
      return <BoldBox title={order?.companyName} />;
    },
  },

  {
    accessorKey: 'inCharge',
    header: 'In Charge',
    size: defaultSizes[0],
    Cell: ({ cell }) => {
      const order: any = rows?.find(
        (row: any) =>
          row?.purchaseVoucherId === cell?.row?.original?.purchaseVoucherId
      );
      return <BoldBox title={order?.inChargeUserName} />;
    },
  },

  {
    accessorKey: 'paymentTermId',
    header: 'Payment Terms',
    size: defaultSizes[1],
    Cell: ({ cell }) => {
      const order: any = rows?.find(
        (row: any) =>
          row?.purchaseVoucherId === cell?.row?.original?.purchaseVoucherId
      );
      return <BoldBox title={order?.paymentTermName} />;
    },
  },

  {
    accessorKey: 'paymentTypeId',
    header: 'Payment Type',
    size: defaultSizes[0],
    Cell: ({ cell }) => {
      const order: any = rows?.find(
        (row: any) =>
          row?.purchaseVoucherId === cell?.row?.original?.purchaseVoucherId
      );
      return <BoldBox title={order?.paymentTypeName} />;
    },
  },

  {
    accessorKey: 'divisionId',
    header: 'Division',
    size: defaultSizes[0],
    Cell: ({ cell }) => {
      const order: any = rows?.find(
        (row: any) =>
          row?.purchaseVoucherId === cell?.row?.original?.purchaseVoucherId
      );
      return <BoldBox title={order?.divisionName} />;
    },
  },

  {
    accessorKey: 'supplierInvoiceDate',
    header: 'Invoice Date',
    size: defaultSizes[0],
    customDate: true,
    Cell: ({ cell }) => {
      return (
        <>
          {cell?.row?.original?.supplierInvoiceDate && (
            <SimpleIconText
              icon={Icons.date}
              text={FmViewDateField({
                value: cell?.row?.original?.supplierInvoiceDate,
                tableFontWeightRemove: true,
              })}
            />
          )}
        </>
      );
    },
  },

  {
    accessorKey: 'invoiceNo',
    header: 'Invoice No',
    size: defaultSizes[0],
    Cell: ({ cell }) => {
      const order: any = rows?.find(
        (row: any) =>
          row?.purchaseVoucherId === cell?.row?.original?.purchaseVoucherId
      );
      return <BoldBox title={order?.invoiceNo} />;
    },
  },

  {
    accessorKey: 'exchangeRate',
    header: 'Exchange Rate',
    size: defaultSizes[0],
    Cell: ({ cell }) => {
      return (
        <Box>
          <CurrencyView
            value={cell.getValue()}
            currencyType={cell?.row?.original?.currencyName}
          />
        </Box>
      );
    },
  },

  {
    accessorKey: 'remarks',
    header: 'Remarks',
    size: defaultSizes[0],
    Cell: ({ cell }) => {
      const order: any = rows?.find(
        (row: any) =>
          row?.purchaseVoucherId === cell?.row?.original?.purchaseVoucherId
      );
      return <BoldBox title={order?.remarks} />;
    },
  },

  {
    accessorKey: 'purchaseVoucherGrossAmount',
    header: 'Gross',
    size: defaultSizes[0],
    Cell: ({ cell }) => {
      return (
        <Box>
          <CurrencyView value={cell.getValue()} />
        </Box>
      );
    },
  },

  {
    accessorKey: 'purchaseVoucherVATAmount',
    header: 'VAT Amount',
    size: defaultSizes[0],
    Cell: ({ cell }) => {
      return (
        <Box>
          <CurrencyView value={cell.getValue()} />
        </Box>
      );
    },
  },

  {
    accessorKey: 'purchaseVoucherTotalAmount',
    header: 'Total Amount',
    size: defaultSizes[0],
    Cell: ({ cell }) => {
      return (
        <Box>
          <CurrencyView value={cell.getValue()} />
        </Box>
      );
    },
  },
];

export default ColumnPurchaseVoucher;
