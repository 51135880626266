import React from 'react';
import { Box, Tooltip } from '@mui/material';

const TextElipseTooltip = ({ name, twoline }) => {
  return (
    <Tooltip title={name} arrow>
      <Box
        className={`overflow-hidden w-full ${
          twoline ? 'line-clamp-2' : 'whitespace-nowrap text-ellipsis'
        }`}
        sx={{
          display: twoline ? '-webkit-box' : 'block',
          WebkitLineClamp: twoline ? 2 : undefined,
          WebkitBoxOrient: 'vertical',
        }}
      >
        {name}
      </Box>
    </Tooltip>
  );
};

export default TextElipseTooltip;
