import React, { useEffect, useState } from 'react';
import GlassCard from './../../../../../components/small/GlassCard.tsx';
import { Grid } from '@mui/material';
import { useForm } from 'react-hook-form';
import FmTextField from '../../../../../components/_mui/FmTextField.tsx';
import * as yup from 'yup';
import { yupResolver } from '@hookform/resolvers/yup';
import dayjs from 'dayjs';
import FmSearchableSelect from '../../../../../components/_mui/FmSearchableSelect.tsx';
import ActionButtons from '../../../../../components/_form/ActionButtons.tsx';
import Crud_Service from '../../../../../apis/CrudService.jsx';
import { toast } from 'react-toastify';
import useLoader from '../../../../../components/helpers/UseLoader.tsx';
import FmDatePicker from '../../../../../components/_mui/FmDatePicker.tsx';
import { useNavigate, useParams } from 'react-router-dom';
import { useDispatch,useSelector} from 'react-redux';
import { 
  updateQuotationStart, 
  updateQuotationSuccess, 
  updateQuotationFailure 
} from '../../../../../store/salesQuotaionSlice.ts';

const GridSize = 2.4;

const schema = yup.object().shape({
  salesQuotationName: yup.string().required('Sales Quotation Name is required'),

  quotationStatusId: yup.number().required('Status is required'),
});
const EditSalesQuotation = () => {
  const crud = new Crud_Service();
  const navigate = useNavigate();
  const { startCreateLoading, stopCreateLoading, createLoading } = useLoader();
  const [editingRowData, setEditingRowData] = useState(null);
  const { control, handleSubmit, reset, setValue } = useForm({
    resolver: yupResolver(schema),
    mode: 'onChange',
  });
  const id=useParams()
  const dispatch = useDispatch();
  const { salesQuotation, loading, error } = useSelector(
    (state) => state?.salesQuotation
  );

  
  useEffect(() => {
    if (id?.id) {
      (async () => {
        await crud.getSingle('salesquotations', id.id, (err, res) => {
          if (res?.status === 200) {
            setEditingRowData(res.data);
            dispatch(updateQuotationSuccess(res.data));
            Object.entries({
              ...res.data,
            }).forEach(([key, value]) => {
              setValue(key, value);
            });
          } else {
            // Handle error response here
          }
        });
      })();
    }
  }, [id]);


  const handleEditItem = async (values) => {
    startCreateLoading();
    const data = {
      ...values,
      quotationDate: dayjs(values?.quotationDate).format('YYYY-MM-DD'),
      contactPersonId: values.contactPersonId,
      companyId: values?.companyId,
      locationId: values.locationId,
      regionId: values.regionId,
      workOrderId: values.workOrderId,
      quotationStatusId: values.quotationStatusId,
      status: 2,
      salesOrderTypeId: 3,
    };
  

      await crud.update('salesquotations', id?.id, data, (_err, response)=>{
        if (response?.status === 200) {
          dispatch(updateQuotationSuccess(response.data));
          toast.success('Updated Successfully');
          stopCreateLoading();
        }else{
          stopCreateLoading();
        }
      }
  
    )
  };

  const submitSalesQuotation = async (values: any) => {
    startCreateLoading();
    const data = {
      ...values,
      quotationDate: dayjs(values?.quotationDate).format('YYYY-MM-DD'),
      salesOrderTypeId: 3,
      quotationStatusId: 1,
    };
    await crud.create('salesquotations', data, (_err, res) => {
      if (res?.status === 201) {
        toast.success('Added Successfully');
        navigate(-1);
        reset();
      } else {
        stopCreateLoading();
      }
    });
  };
  const [, setSelectedClient] = React.useState(null);

  const handleSelectedClient = (e) => {
    setSelectedClient(e);
  };
  return (
    <GlassCard className='p-3'>
      <Grid container spacing={2}>
        <Grid item md={GridSize}>
          <FmSearchableSelect
            name='companyId'
            label='Company'
            apiUrl={'companies'}
            required
            disabled={salesQuotation?.quotationStatusId === 2}
            valueField='companyId'
            labelField={['companyName', 'companyCode']}
            showField={['companyName', 'companyCode']}
            control={control}
            defaultValue={{
              companyId: editingRowData?.companyId,
              companyName: editingRowData?.companyName,
            }}
          />
        </Grid>
        <Grid item md={GridSize}>
          <FmSearchableSelect
            name='clientId'
            label='Client'
            apiUrl='clients'
            valueField='clientId'
            disabled={salesQuotation?.quotationStatusId === 2}
            onChangeProp={handleSelectedClient}
            labelField={['clientName', 'clientCode']}
            showField={['clientName', 'clientCode']}
            control={control}
            defaultValue={{
              clientId: editingRowData?.clientId,
              clientName: editingRowData?.clientName,
            }}
          />
        </Grid>
        <Grid item md={GridSize}>
          <FmTextField
            name='salesQuotationName'
            label='Sales Quotation Name'
            required
            control={control}
            disabled={salesQuotation?.quotationStatusId === 2}
          />
        </Grid>

        <Grid item md={GridSize}>
          <FmDatePicker
            name='quotationDate'
            control={control}
            label='Quotation Date'
            readOnly={salesQuotation?.quotationStatusId === 2}
          />
        </Grid>
        <Grid item md={GridSize}>
          <FmSearchableSelect
            name='workOrderId'
            label='Work Order'
            apiUrl={'workorders'}
            valueField='workOrderId'
            labelField={['workOrderName', 'autoWorkOrderCode']}
            showField={['workOrderName', 'autoWorkOrderCode']}
            control={control}
            defaultValue={{
              workOrderId: editingRowData?.workOrderId,
              workOrderName: editingRowData?.workOrderName,
            }}
            disabled={salesQuotation?.quotationStatusId === 2}
          />
        </Grid>

        <Grid item md={GridSize}>
          <FmSearchableSelect
            name='regionId'
            label='Region'
            apiUrl={'regions'}
            valueField='regionId'
            labelField={['regionName', 'regionCode']}
            showField={['regionName', 'regionCode']}
            control={control}
            disabled={salesQuotation?.quotationStatusId === 2}
            defaultValue={{
              regionId: editingRowData?.regionId,
              regionName: editingRowData?.regionName,
            }}
          />
        </Grid>

        <Grid item md={GridSize}>
          <FmSearchableSelect
            name='locationId'
            label='Supply Location'
            apiUrl={'locations'}
            valueField='locationId'
            labelField={['locationName', 'locationCode']}
            showField={['locationName', 'locationCode']}
            control={control}
            disabled={salesQuotation?.quotationStatusId === 2}
            defaultValue={{
              locationId: editingRowData?.locationId,
              locationName: editingRowData?.locationName,
            }}
          />
        </Grid>

        <Grid item md={GridSize}>
          <FmTextField
            name='paymentTerms'
            label='Payment Terms'
            control={control}
            disabled={salesQuotation?.quotationStatusId === 2}
          />
        </Grid>
        <Grid item md={GridSize}>
          <FmTextField name='narration' label='Narration' control={control}  disabled={salesQuotation?.quotationStatusId === 2}/>
        </Grid>
        <Grid item md={GridSize}>
          <FmTextField
            name='deliveryTerms'
            label='Delivery Terms'
            control={control}
            disabled={salesQuotation?.quotationStatusId === 2}
          />{' '}
        </Grid>
        <Grid item md={GridSize}>
          <FmTextField
            name='subject'
            label='Subject'
            control={control}
            required
            disabled={salesQuotation?.quotationStatusId === 2}
          />
        </Grid>
        <Grid item md={GridSize}>
          <FmSearchableSelect
            name='contactPersonId'
            label='Contact Person'
            apiUrl={'loginusers'}
            valueField='loginUserId'
            labelField={['loginUserName']}
            showField={['loginUserName']}
            control={control}
            defaultValue={{
              contactPersonId: editingRowData?.contactPersonId,
              loginUserName: editingRowData?.contactPersonName,
            }}
            disabled={salesQuotation?.quotationStatusId === 2}
          />
        </Grid>
        <Grid item md={GridSize}>
          <FmTextField name='notes' label='Notes' required control={control} disabled={salesQuotation?.quotationStatusId === 2} />{' '}
        </Grid>
        <Grid item md={GridSize}>
          <FmTextField
            name='validity'
            label='Validity'
            required
            control={control}
            disabled={salesQuotation?.quotationStatusId === 2}
          />
        </Grid>
        <Grid item md={GridSize}>
          <FmTextField
            name='remarks'
            required
            label='Remarks'
            control={control}
            disabled={salesQuotation?.quotationStatusId === 2}
          />
        </Grid>
        <Grid item md={GridSize}>
          <FmTextField name='attn' label='Attn' required control={control} disabled={salesQuotation?.quotationStatusId === 2}/>
        </Grid>
        <Grid item md={GridSize}>
          <FmSearchableSelect
            name='quotationStatusId'
            label='Quotation Status'
            apiUrl={'salesquotations/statuses'}
            required
            valueField='salesQuotationStatusId'
            labelField={['salesQuotationStatusName']}
            showField={['salesQuotationStatusName']}
            control={control}
            defaultValue={{
              quotationStatusId: editingRowData?.quotationStatusId,
              salesQuotationStatusName: editingRowData?.quotationStatusName,
            }}
          />{' '}
        </Grid>

        <Grid item md={12}>
          <ActionButtons
            onSubmit={handleSubmit(
              id?.id ? handleEditItem : submitSalesQuotation
            )}
            onReset={reset}
            onCancel={() => navigate(-1)}
            submitLoading={createLoading}
            cancelLoading={false}
            submitText='Update'
          />
        </Grid>
      </Grid>
    </GlassCard>
  );
};

export default EditSalesQuotation;
