import React, { useEffect, useState } from 'react';
import GlassCard from './../../../../../components/small/GlassCard.tsx';
import { Grid } from '@mui/material';
import { useForm } from 'react-hook-form';
import FmTextField from '../../../../../components/_mui/FmTextField.tsx';
import { yupResolver } from '@hookform/resolvers/yup';
import FmSearchableSelect from '../../../../../components/_mui/FmSearchableSelect.tsx';
import ActionButtons from '../../../../../components/_form/ActionButtons.tsx';
import Crud_Service from '../../../../../apis/CrudService.jsx';
import { toast } from 'react-toastify';
import useLoader from '../../../../../components/helpers/UseLoader.tsx';
import { useNavigate, useParams } from 'react-router-dom';
import { useDispatch, useSelector } from 'react-redux';
import {
  updateQuotationSuccess,
} from '../../../../../store/salesQuotaionSlice.ts';
import { SalesQuotationSchema } from '../../../../../utils/CommonvalidationSchemas.tsx';
import FmAutoComplete from '../../../../../components/_mui/FmAutoComplete.tsx';
import { QuotationStatusEnum } from '../../../../../utils/CommonVariables.tsx';

const GridSize = 2.4;

const EditSalesQuotation = () => {
  const crud = new Crud_Service();
  const navigate = useNavigate();
  const { startCreateLoading, stopCreateLoading, createLoading } = useLoader();
  const [editingRowData, setEditingRowData] = useState(null);
  const { control, handleSubmit, reset, setValue } = useForm({
    resolver: yupResolver(SalesQuotationSchema),
    mode: 'onChange',
  });
  const id = useParams()
  const dispatch = useDispatch();
  const { salesQuotation } = useSelector((state) => state?.salesQuotation);

  useEffect(() => {
    if (id?.id) {
      (async () => {
        await crud.getSingle('salesquotations', id.id, (err, res) => {
          if (res?.status === 200) {
            setEditingRowData(res.data);
            dispatch(updateQuotationSuccess(res.data));
            Object.entries({
              ...res.data,
            }).forEach(([key, value]) => {
              setValue(key, value);
            });
          } else {
            // Handle error response here
          }
        });
      })();
    }
  }, [id]);


  const handleEditItem = async (values) => {
    startCreateLoading();
    const data = {
      ...values,
      quotationStatusId: values.quotationStatusId,
      status: 2,
      salesOrderTypeId: 3,
    };


    await crud.update('salesquotations', id?.id, data, (_err, response) => {
      if (response?.status === 200) {
        dispatch(updateQuotationSuccess(response.data));
        toast.success('Sales Quotation Updated Successfully');
        stopCreateLoading();
      } else {
        stopCreateLoading();
      }
    }

    )
  };

  const submitSalesQuotation = async (values: any) => {
    startCreateLoading();
    const data = {
      ...values,
      salesOrderTypeId: 3,
      quotationStatusId: 1,
    };
    await crud.create('salesquotations', data, (_err, res) => {
      if (res?.status === 201) {
        toast.success('Added Successfully');
        navigate(-1);
        reset();
      } else {
        stopCreateLoading();
      }
    });
  };
  return (
    <GlassCard className='p-3'>
      <Grid container spacing={2}>
        <Grid item md={GridSize}>
          <FmSearchableSelect
            name='contractId'
            control={control}
            apiUrl='contracts'
            valueField='contractId'
            labelField={['contractCode', 'contractName']}
            showField={['contractCode', 'contractName']}
            label={'Contract'}
            defaultValue={{
              contractId: editingRowData?.contractId,
              contractName: editingRowData?.contractName,
            }}
            required
          />
        </Grid>
        <Grid item md={GridSize}>
          <FmTextField
            name='salesQuotationName'
            label='Sales Quotation Name'
            required
            control={control}
            disabled={salesQuotation?.quotationStatusId === 2}
          />
        </Grid>
        <Grid item md={GridSize}>
          <FmSearchableSelect
            name='branchId'
            control={control}
            apiUrl='branches'
            valueField='branchId'
            labelField={['branchCode', 'branchName']}
            showField={['branchCode', 'branchName']}
            label={'Branch'}
            defaultValue={{
              branchId: editingRowData?.branchId,
              branchName: editingRowData?.branchName,
            }}
          />
        </Grid>

        <Grid item md={GridSize}>
          <FmSearchableSelect
            name='paymentTermId'
            control={control}
            apiUrl='paymentterms'
            valueField='paymentTermId'
            labelField={['paymentTermCode', 'paymentTermName']}
            showField={['paymentTermCode', 'paymentTermName']}
            defaultValue={{
              paymentTermId: editingRowData?.paymentTermId,
              paymentTermName: editingRowData?.paymentTermName,
            }}
            disabled={salesQuotation?.quotationStatusId === 2}
            label='Payment Term'
          />
        </Grid>
        <Grid item md={GridSize}>
          <FmTextField
            name='deliveryTerms'
            label='Delivery Terms'
            control={control}
            disabled={salesQuotation?.quotationStatusId === 2}
          />{' '}
        </Grid>
        <Grid item md={GridSize * 2}>
          <FmTextField
            name='narration'
            label='Narration'
            control={control}
            disabled={salesQuotation?.quotationStatusId === 2}
          />
        </Grid>
        <Grid item md={GridSize}>
          <FmTextField
            name='contactPersonName'
            label='Contact Person'
            control={control}
          />{' '}
        </Grid>

        <Grid item md={GridSize}>
          <FmTextField
            name='quotationValidity'
            label='Quotation Validity(Days)'
            required
            control={control}
            disabled={salesQuotation?.quotationStatusId === 2}
          />
        </Grid>
        <Grid item md={GridSize}>
          <FmAutoComplete
            name='quotationStatusId'
            control={control}
            options={QuotationStatusEnum}
            label='Status'
            displayField='label'
            optionFields={['label']}
            valueKey='value'
          />
        </Grid>

        <Grid item md={GridSize}>
          <FmTextField
            name='remarks'
            label='Remarks'
            control={control}
            multiline={true}
            rows={4}
            maxRows={4}
            disabled={salesQuotation?.quotationStatusId === 2}
          />
        </Grid>
        <Grid item md={12}>
          <ActionButtons
            onSubmit={handleSubmit(
              id?.id ? handleEditItem : submitSalesQuotation
            )}
            onReset={reset}
            onCancel={() => navigate(-1)}
            submitLoading={createLoading}
            cancelLoading={false}
            submitText='Update'
          />
        </Grid>
      </Grid>
    </GlassCard>
  );
};

export default EditSalesQuotation;
