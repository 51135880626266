import React, { useState, useRef, useEffect, useCallback } from 'react';
import { Box, CircularProgress, Button } from '@mui/material';
import { Icon } from '@iconify/react';
import Crud_Service from '../../apis/CrudService.jsx';
import MuiDialogFullscreen from '../_mui/MuiDialogFullscreen.tsx'; // Assuming this is JS-compatible
import FileViewer from '../elements/FileViewer.tsx'; // Assuming this is JS-compatible
import apiInstance from '../../apis/ApiService.jsx';
import ConfirmationPopoverBox from './ConfirmationPopoverBox.tsx';
import { toast } from 'react-toastify';

const UploadSquareBoxesNew = ({
  fileTypeId = 38,
  onUploadSuccess,
  onlyView = false,
  handleFileIdCreate,
  getUploadedFileState = [],
  handleFileIdDelete,
}) => {
  const crud = new Crud_Service();
  const fileInputRef = useRef(null);
  const [uploadData, setUploadData] = useState(getUploadedFileState);
  const [imageLoading, setImageLoading] = useState(false);
  const [fileViewState, setFileViewState] = useState({
    show: false,
    path: '',
    name: '',
    loading: false,
  });
  const [deleteData, setDeleteData] = useState([]);

  const handleFileView = useCallback(async (fileId, name) => {
    if (!fileId) {
      toast.error('No file ID provided');
      return;
    }

    setFileViewState((prev) => ({ ...prev, show: true, loading: true }));

    try {
      const docUrl = await apiInstance.getFiles(`files/download/${fileId}`);
      setFileViewState((prev) => ({
        ...prev,
        path: docUrl,
        name: name,
        loading: false,
      }));
    } catch (err) {
      toast.error('Error fetching file');
      setFileViewState((prev) => ({ ...prev, loading: false }));
    }
  }, []);

  const handleFileDelete = (fileId) => {
    const filesToDelete = uploadData.filter(
      (item) => item.fileUploadId === fileId
    );
    setUploadData((prev) =>
      prev.filter((item) => item.fileUploadId !== fileId)
    );
    const newDeleteData = [...deleteData, ...filesToDelete];
    setDeleteData(newDeleteData);
    handleFileIdDelete(newDeleteData);
  };

  const handleImageUpload = async (e) => {
    const file = e.target.files?.[0];
    if (!file) return;

    setImageLoading(true);
    const formData = new FormData();
    formData.append('file', file);
    formData.append('fileTypeId', fileTypeId.toString());

    try {
      const res = await new Promise((resolve, reject) => {
        crud.create('files/upload', formData, (err, res) => {
          if (err) reject(err);
          if (res?.status === 200) resolve(res);
          else reject(new Error('File upload failed'));
        });
      });

      const newFileId = res.data?.fileId;
      if (!newFileId) throw new Error('No fileId received from server');

      handleFileIdCreate(newFileId);
      setUploadData((prev) => [...prev, { ...res.data }]);
      if (onUploadSuccess) onUploadSuccess(newFileId);
    } catch (error) {
      toast.error('Upload failed');
    } finally {
      setImageLoading(false);
    }
  };

  useEffect(() => {
    if (getUploadedFileState?.length > 0) {
      setUploadData(getUploadedFileState);
    } else {
      setUploadData([]);
    }
  }, [getUploadedFileState]);

  const closeFileViewer = useCallback(() => {
    setFileViewState({ show: false, path: '', loading: false, name: '' });
  }, []);

  return (
    <Box>
      {!onlyView && (
        <>
          <Button
            startIcon={
              !imageLoading ? (
                <Icon icon='ep:upload' width='22' height='22' />
              ) : (
                <CircularProgress size={20} />
              )
            }
            className='px-3 py-1'
            onClick={() => fileInputRef.current?.click()}
            disabled={imageLoading}
            variant='outlined'
          >
            {imageLoading ? 'Uploading...' : 'Click to Upload'}
          </Button>

          <input
            type='file'
            ref={fileInputRef}
            onChange={handleImageUpload}
            style={{ display: 'none' }}
            accept='image/*,.pdf,.doc,.docx,.xls,.xlsx'
          />
        </>
      )}

      <Box className='flex flex-col gap-1 mt-2'>
        {uploadData?.map((item) => (
          <Box
            key={item.id}
            className='flex gap-2 items-center justify-between p-1'
            sx={{
              border: '1px solid',
              borderColor: 'border.main',
              borderRadius: '2px',
              bgcolor: 'background.mainLight',
            }}
          >
            <Box
              onClick={() =>
                handleFileView(
                  item.fileUploadId,
                  item.fileName || item.originalFileName
                )
              }
              className='flex gap-1 flex-1 min-w-0 items-center cursor-pointer hover:underline font-semibold'
            >
              <Icon
                icon={
                  item?.originalFileName
                    ? 'line-md:uploading-loop'
                    : 'iconamoon:attachment-light'
                }
                width='16'
                height='16'
              />
              <span className='flex-1 min-w-0 flex'>
                <span className='text-ellipsis overflow-hidden whitespace-nowrap'>
                  {item.originalFileName || item.fileName}
                </span>
              </span>
            </Box>
            {!onlyView && (
              <ConfirmationPopoverBox
                icon={<Icon icon='mdi:delete-outline' width='16' height='16' />}
                title={item.originalFileName || item.fileName}
                message='Are you sure you want to delete this file?'
                onConfirm={() => handleFileDelete(item.fileUploadId)}
                className='p-0 opacity-65 hover:opacity-100'
              />
            )}
          </Box>
        ))}
      </Box>

      <MuiDialogFullscreen
        title={false}
        open={fileViewState.show}
        onClose={closeFileViewer}
      >
        {fileViewState.loading ? (
          <Box className='flex justify-center items-center h-full'>
            <CircularProgress />
          </Box>
        ) : (
          fileViewState.path && (
            <FileViewer
              documents={[{ uri: fileViewState.path }]}
              onClose={closeFileViewer}
              name={fileViewState.name}
            />
          )
        )}
      </MuiDialogFullscreen>
    </Box>
  );
};

export default UploadSquareBoxesNew;
