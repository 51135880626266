import { Box, Button } from '@mui/material';
import React, { useEffect, useState } from 'react';
import { useLocation, useNavigate } from 'react-router-dom';
import { toast } from 'react-toastify';
import Crud_Service from '../../../../apis/CrudService.jsx';
import TableActionButtons from '../../../../components/_form/TableActionButtons.jsx';
import FmMRTDataTable from '../../../../components/FmMRTDataTable.tsx';
import useTableLogic from '../../../../components/helpers/MRTUseTableLogic.tsx';
import Icons from '../../../../utils/Icon.jsx';
import GlassCard from './../../../../components/small/GlassCard.tsx';
import { StatusBadge } from '../../../../components/_form/FormElements.jsx';
import StatusNewBadge from './../../../../components/_form/StatusNewBadge.tsx';
import FmFilterSelectButton from '../../../../components/filter/FmFilterSelectButton.tsx';
import { Icon } from '@iconify/react/dist/iconify.js';
import ElementLoader from '../../../../components/page/ElementLoader.tsx';
import CurrencyView from '../../../../components/_mui/FmCurrencyView.tsx';
import FmViewDateField from '../../../../components/_mui/FmViewDateField.tsx';
import {
  ContractName,
  ProjectName,
  BranchName,
} from '../../../../components/table/MRTCommonColumns.tsx';
import dayjs from 'dayjs';
import { useForm } from 'react-hook-form';
import useLoader from '../../../../components/helpers/UseLoader.tsx';
import { SalesInvoiceStatus } from '../../../../utils/CommonVariables.tsx';
import TransferToCollectionTeamModal from './Edit/TransferToCollectionTeamModal.tsx';
type FilterState =
  | false
  | {
    clientId?: string;
    parentClientId?: string;
    yesNoValue: string;
  };
const ListSalesInvoicesAR = ({ status }) => {
  const navigation = useNavigate();
  const location = useLocation();
  const [onFilter, setOnFilter] = useState<FilterState>(false);
  const [yesNoValue, setYesNoValue] = useState('no');
  const crud = new Crud_Service();
  const [rowSelect, setRowSelect] = useState([]);
  const [selectedRowIds, setSelectedRowIds] = useState([]);
  const [sendLoading, setSendLoading] = useState(false);
  const [approvedId, setAppprovedId] = useState([]);
  const [disbleStatus, setDisableStatus] = useState([
    SalesInvoiceStatus?.APPROVED,
    SalesInvoiceStatus?.ACKNOWLEDGED,
  ]);
  const [openContractChecklist, setOpenContractChecklist] = useState(false);
  const { handleSubmit, control, reset } = useForm();
  const { createLoading } = useLoader();
  const baseUrl = `salesinvoices?invoiceStatusId=${status === -1 ? 0 : status}`;
  let clientIdParam = '&clientId=0';
  let parentClientIdParam = '';

  if (onFilter !== false && (onFilter.clientId || onFilter.parentClientId)) {
    if (!onFilter.yesNoValue || onFilter.yesNoValue === 'no' && onFilter.clientId) {
      clientIdParam = `&clientId=${onFilter.clientId}`;
    } else if (onFilter.yesNoValue === 'yes') {
      if (onFilter.parentClientId) {
        parentClientIdParam = `&parentClientId=${onFilter.parentClientId}`;
      } else if (onFilter.clientId) {
        clientIdParam = `&clientId=${onFilter.clientId}`;
      }
    }
  }

  const overDueParam = status === -1 ? '&OverDue=true' : '&OverDue=false';
  const apiUrl = `${baseUrl}${clientIdParam}${parentClientIdParam}${overDueParam}`;
  const {
    rows,
    isLoading,
    pagination,
    sorting,
    columnFilters,
    globalFilter,
    tableRecordCounts,
    setPagination,
    setSorting,
    setColumnFilters,
    setGlobalFilter,
    fetchData,
    setSearchKeyword,
    searchKeyword,
  } = useTableLogic(apiUrl);

  const handleEdit = (id) => {
    navigation(`${location.pathname}/edit/${id}`);
  };
  const handleDelete = async (props) => {
    await crud.remove('salesinvoices', props, (err, res) => {
      if (res?.status === 204) {
        toast.success('Sales Invoice Deleted Successfully');
        fetchData();
      }
    });
  };

  const ActionData = [
    {
      name: 'Edit',
      icon: Icons.edit,
      onClick: (props) => {
        handleEdit(props.salesInvoiceId);
      },
    },
    {
      name: 'Delete',
      icon: Icons.delete,
      onClick: (props) => handleDelete(props.salesInvoiceId),
      danger: true,
    },
  ];

  const columns = [
    {
      header: 'Actions',
      size: 70,
      Cell: ({ row }) => (
        <Box className='flex gap-2'>
          {
            <TableActionButtons
              Actions={ActionData?.map((action) => ({
                ...action,
                onClick: () => action.onClick(row?.original),
              }))}
            />
          }
        </Box>
      ),
    },

    {
      accessorKey: 'salesInvoiceCode',
      header: 'Sales Invoice Code',
      size: 150,
    },

    {
      accessorKey: 'invoiceStatusName',
      header: 'Invoice Status',
      size: 200,
      Cell: ({ cell }) => (
        <StatusNewBadge title={cell?.getValue()} type={cell?.getValue()} />
      ),
    },

    {
      accessorKey: 'clientName',
      header: 'Client',
      size: 250,
      Cell: ({ cell }) => (
        <div className='flex flex-col w-full'>
          <div className='font-semibold'>{cell?.row?.original?.clientName}</div>
          <div className='flex items-center gap-1'>
            <span>Code:</span>
            {cell?.row?.original?.clientCode}
          </div>
        </div>
      ),
    },
    {
      accessorKey: 'salesInvoiceDate',
      header: 'Sales Invoice',
      size: 250,
      customDate: true,
      Cell: ({ cell, row }) => (
        <Box>
          <Box className='flex justify-between items-center'>
            <Box>
              <Box>Invoice Date</Box>
              <Box sx={{ color: 'primary.main' }}>
                {' '}
                <FmViewDateField value={row?.original?.salesInvoiceDate} />
              </Box>
            </Box>
            <Box>-</Box>
            <Box>
              <Box>Due Date</Box>
              <StatusBadge
                title={row?.original?.dueDate}
                date
                type={
                  dayjs(row?.original?.dueDate).diff(dayjs()) < 0
                    ? 'red'
                    : 'blue'
                }
              />
            </Box>
          </Box>
        </Box>
      ),
    },

    {
      accessorKey: 'companyName',
      header: 'Company',
      size: 140,
    },

    ContractName,
    ProjectName,
    {
      accessorKey: 'jurisdictionName',
      header: 'Jurisdiction',
    },
    BranchName,
    {
      accessorKey: 'salesInvoiceGrossAmount',
      header: 'Gross',
      Cell: ({ cell }) => {
        return (
          <Box>
            <CurrencyView value={cell.getValue()} />
          </Box>
        );
      },
    },
    {
      accessorKey: 'salesInvoiceVATAmount',
      header: 'VAT Amount',
      Cell: ({ cell }) => {
        return (
          <Box>
            <CurrencyView value={cell.getValue()} />
          </Box>
        );
      },
    },
    {
      accessorKey: 'salesInvoiceTotalAmount',
      header: 'Total Amount',
      Cell: ({ cell }) => {
        return (
          <CurrencyView value={cell?.row?.original?.salesInvoiceTotalAmount} />
        );
      },
    },
  ];
  const onFilterChange = (value: { clientId?: string; parentClientId?: string; yesNoValue: string }) => {
    setYesNoValue(value.yesNoValue);
    if (value.clientId || value.parentClientId) {
      setOnFilter(value);
      setRowSelect([]);
    }
  };

  const handleSendInvoices = async () => {
    setSendLoading(true);
    const fileFormData = {
      salesInvoiceIds: approvedId || false,
    };
    await crud.create(
      'salesinvoices/generate-receipts',
      fileFormData,
      (err, res) => {
        if (res?.status === 200) {
          toast.success('Receipts Generated successfully');
          setSendLoading(false);
          setRowSelect([]);
          setOnFilter(false);
        } else {
          setSendLoading(false);
        }
      }
    );
  };
  const handleCA = async (data) => {
    setSendLoading(true);
    setOpenContractChecklist(false);
    const combinedData = {
      employeeId: data?.employeeId,
      salesInvoiceIds: selectedRowIds || false,
    };
    await crud.create('invoicecollectionagents', combinedData, (err, res) => {
      if (res?.status === 201) {
        toast.success('Invoice Collection Agent Added successfully');
        setSendLoading(false);
        setRowSelect([]);
        setOnFilter(false);
      } else {
        setSendLoading(false);
      }
    });
  };

  useEffect(() => {
    if (
      status !== undefined &&
      onFilter !== false &&
      (onFilter.clientId && onFilter.clientId !== "0") ||
      (onFilter.parentClientId && onFilter.parentClientId !== "0")
    ) {
      fetchData();
    }
  }, [status, onFilter?.clientId, onFilter?.parentClientId]);


  useEffect(() => {
    if (rowSelect.length === 0) {
      setSelectedRowIds([]);
      setAppprovedId([]);
    } else {
      let selectedIds = [];
      let approvedIds = [];
      let hasSelectedStatus2 = false;
      let hasSelectedStatus7 = false;

      Object.keys(rowSelect).forEach((rowId) => {
        const row = rows[rowId];
        if (row) {
          if (row.invoiceStatusId === SalesInvoiceStatus?.APPROVED)
            hasSelectedStatus2 = true;
          if (row.invoiceStatusId === SalesInvoiceStatus?.ACKNOWLEDGED)
            hasSelectedStatus7 = true;
        }
      });

      Object.keys(rowSelect).forEach((rowId) => {
        const row = rows[rowId];
        if (row) {
          if (
            row.invoiceStatusId === SalesInvoiceStatus?.APPROVED &&
            row.salesInvoiceId &&
            !hasSelectedStatus7
          ) {
            selectedIds.push(row.salesInvoiceId);
          } else if (
            row.invoiceStatusId === SalesInvoiceStatus?.ACKNOWLEDGED &&
            row.salesInvoiceId &&
            !hasSelectedStatus2
          ) {
            approvedIds.push(row.salesInvoiceId);
          }
        }
      });
      setAppprovedId(approvedIds);
      setSelectedRowIds(selectedIds);
    }
  }, [rowSelect, rows]);

  return (
    <GlassCard className='flex-1 min-h-0 relative'>
      {sendLoading && <ElementLoader image fly />}

      <FmMRTDataTable
        columns={columns}
        rows={rows}
        rowCount={tableRecordCounts}
        pagination={pagination}
        sorting={sorting}
        columnFilters={columnFilters}
        onColumnFiltersChange={setColumnFilters}
        globalFilter={globalFilter}
        setPagination={setPagination}
        setSorting={setSorting}
        setGlobalFilter={setGlobalFilter}
        isLoading={isLoading}
        setSearchKeyword={setSearchKeyword}
        searchKeyword={searchKeyword}
        rightPinning={['salesInvoiceTotalAmount']}
        fetchData={fetchData}
        enableRowSelection={onFilter ? true : false}
        rowSelection={rowSelect}
        setRowSelection={setRowSelect}
        handleRowDoubleClick={(ev) => handleEdit(ev?.original?.salesInvoiceId)}
        muiTableContainerProps={{ sx: { maxHeight: '100%', flex: 1 } }}
        showSerialNumber={true}
        renderTopToolbarCustomActionsChildrens={
          <FmFilterSelectButton
            onFilterChange={onFilterChange}
            handleDelete={() => {
              setOnFilter(false);
              setRowSelect([]);
            }}
            yesNoValue={yesNoValue} // Pass yesNoValue as a prop
          />
        }
        muiSelectCheckboxProps={({ row }) => ({
          disabled: !disbleStatus.includes(row.original.invoiceStatusId),
        })}
        enableSelectAll={false}
        rowSelectionCustomProps={
          <Box className='flex items-center'>
            <Button
              className='whitespace-nowrap py-0 px-3 me-2 h-[31px]'
              variant='contained'
              color='info'
              startIcon={<Icon icon='tabler:transfer' width='24' height='24' />}
              onClick={() => {
                setOpenContractChecklist(true);
                setSelectedRowIds(selectedRowIds);
              }}
              disabled={selectedRowIds.length === 0}
            >
              Transfer To Collection Team
            </Button>

            <Button
              className='whitespace-nowrap py-0 px-3 me-2 h-[31px]'
              variant='contained'
              color='warning'
              disabled={approvedId.length === 0}
              startIcon={
                <Icon
                  icon='mdi:invoice-text-send-outline'
                  width='24'
                  height='24'
                />
              }
              onClick={() => {
                handleSendInvoices();
              }}
            >
              Generate Receipt
            </Button>
          </Box>
        }
      />

      <TransferToCollectionTeamModal
        openContractChecklist={openContractChecklist}
        setOpenContractChecklist={setOpenContractChecklist}
        handleCA={handleCA}
        createLoading={createLoading}
        control={control}
        handleSubmit={handleSubmit}
        reset={reset}
      />
    </GlassCard>
  );
};

export default ListSalesInvoicesAR;
