import dayjs from 'dayjs';
import * as Yup from 'yup';

export const supplierValidationSchema = Yup.object().shape({
  supplierCode: Yup.string().required('Supplier Code is required'),
  officialSupplierName: Yup.string().required(
    'Official Supplier Name is required'
  ),
  debitORCredit: Yup.boolean().notRequired(),
  creditLimit: Yup.number()
    .nullable()
    .transform((value, originalValue) =>
      originalValue === '' ? undefined : value
    )
    .when('debitORCredit', {
      is: false, // When debitORCredit is false (Credit selected)
      then: (schema) =>
        schema
          .required('Credit Limit is required')
          .min(0, 'Credit Limit cannot be negative'),
      otherwise: (schema) => schema.notRequired(),
    }),
  supplierTypeId: Yup.number()
    .transform((value, originalValue) => (originalValue === '' ? null : value))
    .required('Supplier Type is required')
    .positive('Supplier Type must be a positive number')
    .integer('Supplier Type must be an integer'),
});
export const DepartmentShiftSchema = Yup.object().shape({
  departmentId: Yup.number().required("Department is required")
    .typeError("Department is required"),
  shiftTimingId: Yup.number().required("Shift Timing is required")
    .typeError("Shift Timing is required"),
});

export const AccrualsSchema = Yup.object().shape({
  accrualEtisalatBillAmountPerYear: Yup.number()
    .typeError('Etisalat CUG Amount must be a valid number') // Prevents non-numeric input
    .required('Etisalat CUG Amount is required')
    .min(0, 'Etisalat CUG Amount cannot be negative'), // Allows 0 but prevents negatives

  accrualInsuranceAmount: Yup.number()
    .typeError('Insurance Amount must be a valid number')
    .required('Insurance Amount is required')
    .min(0, 'Insurance Amount cannot be negative'),

  accrualVisaExpenseAmount: Yup.number()
    .typeError('VISA Amount must be a valid number')
    .required('VISA Amount is required')
    .min(0, 'VISA Amount cannot be negative'),

  accrualLabourCampAmountPerYear: Yup.number()
    .typeError('Camp Amount must be a valid number')
    .required('Camp Amount is required')
    .min(0, 'Camp Amount cannot be negative'),

  noOfDependents: Yup.number()
    .typeError('No. of Dependents must be a valid number')
    .required('No. of Dependents is required')
    .integer('No. of Dependents must be a whole number')
    .min(0, 'No. of Dependents cannot be negative'),
});


export const wpsSchema = Yup.object().shape({
  wpsCategoryName: Yup.string().required('WPS Category Name is required'),
  basicSalaryPercent: Yup.number()
    .transform((value, originalValue) => (originalValue === '' ? null : value))
    .required('Basic Salary Percentage is required')
    .min(0, 'Basic Salary Percentage cannot be less than 0')
    .max(100, 'Basic Salary Percentage cannot exceed 100'),
  housingAllowancePercent: Yup.number()
    .transform((value, originalValue) => (originalValue === '' ? null : value))
    .required('Housing Allowance Percentage is required')
    .min(0, 'Housing Allowance Percentage cannot be less than 0')
    .max(100, 'Housing Allowance Percentage cannot exceed 100'),
  additionalAllowancePercent: Yup.number()
    .transform((value, originalValue) => (originalValue === '' ? null : value))
    .required('Additional Allowance Percentage is required')
    .min(0, 'Additional Allowance Percentage cannot be less than 0')
    .max(100, 'Additional Allowance Percentage cannot exceed 100'),
  incentivePercent: Yup.number()
    .transform((value, originalValue) => (originalValue === '' ? null : value))
    .required('Incentives Percentage is required')
    .min(0, 'Incentives Percentage cannot be less than 0')
    .max(100, 'Incentives Percentage cannot exceed 100'),
  otherAllowancePercent: Yup.number()
    .transform((value, originalValue) => (originalValue === '' ? null : value))
    .required('Other Allowance Percentage is required')
    .min(0, 'Other Allowance Percentage cannot be less than 0')
    .max(100, 'Other Allowance Percentage cannot exceed 100'),
});

export const supplierItemSchema = Yup.object().shape({
  supplierId: Yup.number()
    .transform((value, originalValue) => (originalValue === '' ? null : value))
    .required('Supplier is required')
    .positive('Supplier must be a positive number')
    .integer('Supplier must be an integer'),

  itemId: Yup.number()
    .transform((value, originalValue) => (originalValue === '' ? null : value))
    .required('Item is required')
    .positive('Item must be a positive number')
    .integer('Item must be an integer'),

  description: Yup.string().required('Description is required'),
  notes: Yup.string().nullable(),

  sku: Yup.string().required('SKU is required'),

  unitPrice: Yup.number()
    .transform((value, originalValue) => (originalValue === '' ? null : value))
    .required('Unit Price is required')
    .min(0, 'Unit Price cannot be negative'),

  quantity: Yup.number()
    .transform((value, originalValue) => (originalValue === '' ? null : value))
    .required('Quantity is required')
    .positive('Quantity must be greater than 0')
    .integer('Quantity must be a whole number'),

  taxCodeId: Yup.number()
    .transform((value, originalValue) => (originalValue === '' ? null : value))
    .required('Tax Code is required')
    .positive('Tax Code must be a positive number')
    .integer('Tax Code must be an integer'),

  discountPercent: Yup.number()
    .transform((value, originalValue) => (originalValue === '' ? null : value))
    .required('Discount Percent is required')
    .min(0, 'Discount Percent cannot be negative')
    .max(100, 'Discount Percent cannot exceed 100'),

  totalPrice: Yup.number()
    .transform((value, originalValue) => (originalValue === '' ? null : value))
    .required('Total Price is required')
    .min(0, 'Total Price cannot be negative'),
});

export const EstimationMEPSchema = Yup.object().shape({
  dateOfEnquiry: Yup.date()
    .required('Date of Enquiry is required')
    .nullable()
    .typeError('Invalid date format')
    .test('invalidDate', 'Invalid Date', (value) => {
      return value instanceof Date && !isNaN(value.getTime());
    }),
  clientId: Yup.number()
    .transform((value, originalValue) => (originalValue === '' ? null : value))
    .required('Client is required')
    .positive('Client must be a positive number')
    .integer('Client must be an integer'),

  tenderId: Yup.number()
    .transform((value, originalValue) => (originalValue === '' ? null : value))
    .required('Tender is required')
    .positive('Tender must be a positive number')
    .integer('Tender must be an integer'),

  buildingId: Yup.number()
    .transform((value, originalValue) => (originalValue === '' ? null : value))
    .required('Building is required')
    .positive('Building must be a positive number')
    .integer('Building must be an integer'),

  contractPeriod: Yup.number()
    .transform((value, originalValue) => (originalValue === '' ? null : value))
    .required('Contract Period is required')
    .positive('Contract Period must be a positive number')
    .integer('Contract Period must be an integer'),

  siteVisitDoneBy: Yup.number()
    .transform((value, originalValue) => (originalValue === '' ? null : value))
    .required('Site Visit Done By is required')
    .positive('Site Visit Done By must be a positive number')
    .integer('Site Visit Done By must be an integer'),
  preparedBy: Yup.number()
    .transform((value, originalValue) => (originalValue === '' ? null : value))
    .required('Prepared By is required')
    .positive('Prepared By must be a positive number')
    .integer('Prepared By must be an integer'),
});
export const leaveRequestSchema = Yup.object().shape({
  employeeId: Yup.number()
    .transform((value, originalValue) => (originalValue === '' ? null : value))
    .required('Employee is required'),
  leaveTypeId: Yup.number()
    .transform((value, originalValue) => (originalValue === '' ? null : value))
    .required('Leave Type is required'),
  fromDate: Yup.date()
    .required('From date is required')
    .typeError('Please enter a valid date')
    .min(dayjs().startOf('day').toDate(), 'From date cannot be in the past'),
  toDate: Yup.date()
    .required('To date is required')
    .typeError('Please enter a valid date')
    .min(
      Yup.ref('fromDate'),
      'To date must be greater than or equal to from date'
    ),
  dateOfTravel: Yup.date()
    .nullable()
    .typeError('Please enter a valid date')
    .test('within-2-days', 'Date of travel must be within 2 days before or after from date', function (value) {
      const { fromDate } = this.parent;
      if (!value || !fromDate) return true;
      const diff = Math.abs(dayjs(fromDate).diff(dayjs(value), 'day'));
      return diff <= 2; // Allow within 2 days before or after fromDate
    }),
  returnLeaveDate: Yup.date()
    .nullable()
    .typeError('Please enter a valid date')
    .min(
      Yup.ref('toDate'),
      'Return date must be greater than or equal to to date'
    ),
  reason: Yup.string().required('Reason is required')
    .min(5, 'Reason must be at least 5 characters')
    .max(100, 'Reason must be at most 100 characters'),

  mailAddress: Yup.string()
    .nullable()
    .transform((value) => (value === '' ? null : value)) // Convert empty string to null
    .test('is-required', 'Email Address is required', function (value) {
      const { leaveTypeId } = this.parent;
      if ([1, 2, 3].includes(leaveTypeId)) {
        return value !== null && value !== undefined;
      }
      return true; // Skip validation if leaveTypeId is not 1, 2, or 3
    })
    .email('Invalid email format'), // Validates email only if a value is present


  // mailAddress: Yup.string()
  //   .nullable()
  //   .transform((value, originalValue) => (originalValue === '' ? null : value))
  //   .test(
  //     'is-required',
  //     'Mail Address is required',
  //     function (value) {
  //       const { leaveTypeId } = this.parent;
  //       if (![1, 2, 3].includes(leaveTypeId)) {
  //         return Boolean(value); // Ensures non-null, non-empty value
  //       }
  //       return true;
  //     }
  //   )
  //   .email('Invalid email format'),



  homeCountryContactNumber: Yup.number()
    .nullable()
    .transform((value, originalValue) => (originalValue === '' ? null : value)) // Convert empty string to null
    .typeError('Home Country Number must be a valid number') // Ensure only numbers
    .test(
      'valid-length',
      'Home Country Number must be between 8 and 16 digits',
      function (value) {
        if (value === null || value === undefined) return true; // Skip if null (optional field)
        const numberStr = String(value); // Convert to string
        return numberStr.length >= 8 && numberStr.length <= 16;
      }
    )
    .test(
      'is-required',
      'Home Country Number is required',
      function (value) {
        const { leaveTypeId } = this.parent;
        if ([1, 2, 3].includes(leaveTypeId)) {
          return value !== null && value !== undefined;
        }
        return true; // Skip validation if leaveTypeId is not 1, 2, or 3
      }
    ),

  uaeNumber: Yup.number()
    .nullable()
    .transform((value, originalValue) => (originalValue === '' ? null : value)) // Convert empty string to null
    .typeError('UAE Number must be a valid number') // Ensure only numbers
    .test(
      'valid-length',
      'UAE Number must be between 8 and 16 digits',
      function (value) {
        if (value === null || value === undefined) return true; // Skip if null (optional field)
        const numberStr = String(value); // Convert to string
        return numberStr.length >= 8 && numberStr.length <= 16;
      }
    )
    .test(
      'is-required',
      'UAE Number is required',
      function (value) {
        const { leaveTypeId } = this.parent;
        if ([1, 2, 3].includes(leaveTypeId)) {
          return value !== null && value !== undefined;
        }
        return true; // Skip validation if leaveTypeId is not 1, 2, or 3
      }
    ),

  address: Yup.string()
    .nullable()
    .transform((value, originalValue) => (originalValue === '' ? null : value)) // Convert empty string to null
    .typeError('Communication Address is required') // Ensure only numbers
    .test(
      'valid-length',
      'Communication Address is required',
      function (value) {
        if (value === null || value === undefined) return true;
        const numberStr = String(value); // Convert to string
        return numberStr.length >= 8 && numberStr.length <= 100;
      }
    )
    .test(
      'is-required',
      'Communication Address is required',
      function (value) {
        const { leaveTypeId } = this.parent;
        if ([1, 2, 3].includes(leaveTypeId)) {
          return value !== null && value !== undefined;
        }
        return true; // Skip validation if leaveTypeId is not 1, 2, or 3
      }
    ),

  // totalLeave: Yup.number()
  //   .transform((value, originalValue) => (originalValue === '' ? null : value))
  //   .required('Total Leave is required'),
  // usedLeave: Yup.number()
  //   .transform((value, originalValue) => (originalValue === '' ? null : value))
  //   .required('Used Leave is required'),
  // balanceLeave: Yup.number()
  //   .transform((value, originalValue) => (originalValue === '' ? null : value))
  //   .required('Balance Leave is required'),
  // dateOfTravel: Yup.date().required('Date of Travel is required'),
});

export const leaveJoinSchema = Yup.object().shape({
  // employeeId: Yup.number()
  //   .transform((value, originalValue) => (originalValue === '' ? null : value))
  //   .required('Employee is required'),

  dateOfArrival: Yup.date().required('Date of Arrival is required'),
  dateOfResume: Yup.date().required('Date of Resume is required'),
  additionalDays: Yup.number().required('Additional Days is required'),
  delayDays: Yup.number().required('Delay Days is required'),
  remarks: Yup.string()
    .transform((value) => (value === '' ? null : value))
    .nullable()
    .notRequired()
    .min(5, 'Remarks must be at least 5 characters')
    .max(100, 'Remarks must be at most 100 characters'),
});


export const passportRequestSchema = Yup.object().shape({
  employeeId: Yup.number()
    .transform((value, originalValue) => (originalValue === '' ? null : value))
    .required('Employee is required'),
  passportRequestTypeId: Yup.number()
    .transform((value, originalValue) => (originalValue === '' ? null : value))
    .required('Passport Request Type is required'),
  requestedById: Yup.string().required('Requested By is required'),
});
export const passportRequestSubmission = Yup.object().shape({
  passportRenewedOrNormalSubmission: Yup.number()
    .transform((value, originalValue) => (originalValue === '' ? null : value))
    .required('Submission Type is required'),
  passportSubmittedById: Yup.number()
    .transform((value, originalValue) => (originalValue === '' ? null : value))
    .required('Passport Submitted By is required'),
  signatureImage: Yup.number()
    .nullable()
    .required('This field is required')
    .positive('Value must be positive')
    .integer('Value must be an integer'),
});
export const passportRequestIssued = Yup.object().shape({
  issuedById: Yup.number()
    .transform((value, originalValue) => (originalValue === '' ? null : value))
    .required('Issued By is required'),
  receivedById: Yup.number()
    .transform((value, originalValue) => (originalValue === '' ? null : value))
    .required('Received By is required'),
  signatureImage: Yup.number()
    .nullable()
    .required('This field is required')
    .positive('Value must be positive')
    .integer('Value must be an integer'),
});

export const employeeCancellationSchema = Yup.object().shape({
  employeeId: Yup.number()
    .transform((value, originalValue) => (originalValue === '' ? null : value))
    .required('Employee is required'),
  cancellationTypeId: Yup.number()
    .transform((value, originalValue) => (originalValue === '' ? null : value))
    .required('Cancellation Type is required'),
  cancellationDate: Yup.date().required('Cancellation Date is required'),
  reason: Yup.string().required('Reason is required'),
  lastDate: Yup.date().required('Last Date is required'),
  departmentClearanceDocumentPath: Yup.string().required(
    'Document Path is required'
  ),
  contractBreach: Yup.string().required('Contract Breach is required'),
  exitday: Yup.date().required('Exit Day is required'),
  remarks: Yup.string().required('Remarks is required'),
});

export const certificateSchema = Yup.object().shape({
  employeeId: Yup.number()
    .transform((value, originalValue) => (originalValue === '' ? null : value))
    .required('Employee is required'),
  createCertificateCatalog: Yup.object().shape({
    certificationId: Yup.number()
      .required('Certification is required')
      .transform((value, originalValue) => (originalValue === '' ? null : value)),
    certificateTypeId: Yup.number()
      .required('Certificate Type is required')
      .transform((value, originalValue) => (originalValue === '' ? null : value)),
  }),

});

export const certificateSchemaEdit = Yup.object().shape({
  employeeId: Yup.number()
    .transform((value, originalValue) => (originalValue === '' ? null : value))
    .required('Employee is required'),
  certificateCatalog: Yup.object().shape({
    certificationId: Yup.number()
      .required('Certification is required')
      .transform((value, originalValue) => (originalValue === '' ? null : value)),
    certificateTypeId: Yup.number()
      .required('Certificate Type is required')
      .transform((value, originalValue) => (originalValue === '' ? null : value)),
  }),

});

export const jobCertificationSchema = Yup.object().shape({
  requisitionTitle: Yup.string().required('Requisition Title is required'),
  requisitionDescription: Yup.string().required(
    'Requisition Description is required'
  ),
  jobRequisitionManualCode: Yup.string().nullable(),
  requisitionReason: Yup.string().nullable(),

  createJobRequisitionDetails: Yup.array()
    .of(
      Yup.object().shape({
        experienceLevel: Yup.string().required('Experience Level is required'),
        closedDate: Yup.date().required('Closed Date is required'),
        noOfPosition: Yup.number()
          .transform((value, originalValue) =>
            originalValue === '' ? null : value
          )
          .required('No of Position is required'),
        fromBudget: Yup.number().transform((value, originalValue) =>
          originalValue === '' ? null : value
        ),
        toBudget: Yup.number()
          .transform((value, originalValue) =>
            originalValue === '' ? null : value
          )
          .required('To Budget is required'),
        requestedBy: Yup.number()
          .transform((value, originalValue) =>
            originalValue === '' ? null : value
          )
          .required('Requested By is required'),
        skill: Yup.string().required('Skill is required'),
        jobRequisitionStatusId: Yup.number()
          .transform((value, originalValue) =>
            originalValue === '' ? null : value
          )
          .required('Job Requisition Status is required'),
      })
    )
    .required('Job Requisition Details is required'),
});

export const eventsSchema = Yup.object().shape({
  eventName: Yup.string().required('Event Name is required'),
  eventTopic: Yup.string().required('Event Topic is required'),
  eventDescription: Yup.string().required('Event Description is required'),
  eventDate: Yup.date().required('Event Date is required'),
  locationId: Yup.number()
    .transform((value, originalValue) => (originalValue === '' ? null : value))
    .required('Location is required'),
  organizedBy: Yup.number()
    .transform((value, originalValue) => (originalValue === '' ? null : value))
    .required('Organized By is required'),
  attendeessCount: Yup.number()
    .transform((value, originalValue) => (originalValue === '' ? null : value))
    .required('Attendees Count is required'),
  eventStatusId: Yup.number()
    .transform((value, originalValue) => (originalValue === '' ? null : value))
    .required('Event Status is required'),
  approvalStatusId: Yup.number()
    .transform((value, originalValue) => (originalValue === '' ? null : value))
    .required('Approval Status is required'),
});

export const grnSchema = Yup.object().shape({
  supplierId: Yup.number()
    .transform((value, originalValue) => (originalValue === '' ? null : value))
    .required('Supplier is required'),
  deliveryChallanNumber: Yup.string().required(
    'Delivery Challan Number is required'
  ),
  deliveryDate: Yup.date().required('Delivery Date is required'),
  grnDate: Yup.date().required('GRN Date is required'),
  paymentStatusId: Yup.number()
    .transform((value, originalValue) => (originalValue === '' ? null : value))
    .required('Payment Status is required'),
  goodsReceivedNoteStatusId: Yup.number()
    .typeError('Invalid GRN Status')
    .required('GRN Status is required'),
});

export const purchaseFormSchema = Yup.object().shape({
  manualLPOCode: Yup.string()
    .matches(/^[a-zA-Z0-9]*$/, 'Manual LPO Code must be alphanumeric')
    .required('Manual LPO Code is required'),
  lpoDate: Yup.date().required('LPO Date is required'),
  vendorAccount: Yup.string()
    .matches(/^[a-zA-Z0-9]*$/, 'Vendor Account must be alphanumeric')
    .required('Vendor Account is required'),
  currencyId: Yup.number()
    .transform((value, originalValue) => (originalValue === '' ? null : value))
    .required('Currency is required'),
  exchangeRate: Yup.number()
    .transform((value, originalValue) => (originalValue === '' ? null : value))
    .required('Exchange Rate is required'),
  companyId: Yup.number()
    .transform((value, originalValue) => (originalValue === '' ? null : value))
    .required('Company is required'),
  projectId: Yup.number()
    .transform((value, originalValue) => (originalValue === '' ? null : value))
    .required('Project is required'),
  contractId: Yup.number()
    .transform((value, originalValue) => (originalValue === '' ? null : value))
    .required('Contract is required'),
  divisionId: Yup.number()
    .transform((value, originalValue) => (originalValue === '' ? null : value))
    .required('Division is required'),
  costCenterId: Yup.number()
    .transform((value, originalValue) => (originalValue === '' ? null : value))
    .required('Cost Center is required'),
  jurisdiction: Yup.string().required('Jurisdiction is required'),
  inCharge: Yup.string().required('In Charge is required'),
  requestor: Yup.string().required('Requestor is required'),
  deliveryLocation: Yup.string().required('Delivery Location is required'),
  supplierSPOCId: Yup.number()
    .transform((value, originalValue) => (originalValue === '' ? null : value))
    .required('Supplier SPOC is required'),
  // materialRequisitionId: Yup.number()
  //   .transform((value, originalValue) => (originalValue === '' ? null : value))
  //   .required('Material Requisition is required'),

  deliveryTerms: Yup.string().required('Delivery Terms is required'),

  paymentTermId: Yup.number()
    .transform((value, originalValue) => (originalValue === '' ? null : value))
    .required('Payment Term is required'),
  remarks: Yup.string().required('Remarks is required'),
});

export const itemsSchema = Yup.object().shape({
  itemShortCode: Yup.string().required('Item Short Code is required'),
  itemName: Yup.string().required('Item Name is required'),
  unitOfMeasurementId: Yup.number()
    .transform((value, originalValue) => (originalValue === '' ? null : value))
    .required('Unit of Measurement is required'),
  itemTypeId: Yup.number()
    .transform((value, originalValue) => (originalValue === '' ? null : value))
    .required('Item Type is required'),
  costCenterId: Yup.number()
    .transform((value, originalValue) => (originalValue === '' ? null : value))
    .required('Cost Center is required'),
  itemGroupId: Yup.number()
    .transform((value, originalValue) => (originalValue === '' ? null : value))
    .required('Item Group is required'),
  brandId: Yup.number()
    .transform((value, originalValue) => (originalValue === '' ? null : value))
    .required('Brand is required'),

  groupTypeId: Yup.number()
    .transform((value, originalValue) => (originalValue === '' ? null : value))
    .required('Group Type is required'),
});

export const CompanyInsurancePolicySchema = Yup.object().shape({
  companyInsurancePolicyId: Yup.number()
    .positive('Company Insurance Policy must be a positive number')
    .required('Company Insurance Policy is required'),
  companyInsurancePolicyEndUserId: Yup.number()
    .positive('End User must be a positive number')
    .required('End User is required'),
  companyInsurancePolicyTypeOfProductId: Yup.number()
    .positive('Type of Product must be a positive number')
    .required('Type of Product is required'),
  mobileNumber: Yup.string()
    .nullable() // Allows null or empty value
    .matches(/^[0-9]*$/, 'Mobile Number must be numeric') // Validates only if the value exists
    .test(
      'length-check',
      'Mobile Number must be at least 8 digits and cannot exceed 15 digits',
      (value) => !value || (value.length >= 8 && value.length <= 15) // Check only when value exists
    ),
  companyInsurancePolicyInsurerId: Yup.number()
    .positive('Insurer must be a positive number')
    .required('Insurer is required'),
  // policyDetail: Yup.string().required('Policy Detail is required'),
  // insuredSum: Yup.number()
  //   .typeError('Insured Sum must be a number')
  //   .positive('Insured Sum must be a positive number')
  //   .required('Insured Sum is required'),
  startDate: Yup.date().nullable(),
  endDate: Yup.date()
    .nullable()
    .typeError('End Date must be a valid date')
    .min(Yup.ref('startDate'), 'End Date must be less than Start Date'),
});

export const CompanyInsurancePolicyComparisonSchema = Yup.object().shape({
  companyInsurancePolicyId: Yup.number()
    .positive('Company Insurance Policy must be a positive number')
    .required('Company Insurance Policy is required'),
  companyInsurancePolicyEndUserId: Yup.number()
    .positive('End User must be a positive number')
    .required('End User is required'),
  companyInsurancePolicyTypeOfProductId: Yup.number()
    .positive('Type of Product must be a positive number')
    .required('Type of Product is required'),
  companyInsurancePolicyTypeId: Yup.number()
    .positive('Policy Type must be a positive number')
    .required('Policy Type is required'),
  companyInsurancePolicyInsurerId: Yup.number()
    .positive('Insurer must be a positive number')
    .required('Insurer is required'),
  description: Yup.string()
    .required('Description is required')
    .min(5, 'Description must be at least 5 characters long'),
  policyDetail: Yup.string().required('Policy Detail is required'),
  insuredSum: Yup.number()
    .typeError('Insured Sum must be a number')
    .positive('Insured Sum must be a positive number')
    .required('Insured Sum is required'),
  remarks: Yup.string().required('Remarks is required'),
  startDate: Yup.date().required('Start Date is required'),
  endDate: Yup.date().required('End Date is required'),
  dateOfPriorNotification: Yup.date().required(
    'Date of Prior Notification is required'
  ),
});
export const MIPPremiunCalculatorSchema = Yup.object().shape({
  companyMedicalInsuranceAgeBandId: Yup.number()
    .positive('Age Band must be a positive number')
    .required('Age Band is required'),

  companyMedicalInsuranceDependencyId: Yup.number()
    .positive('Dependency must be a positive number')
    .required('Dependency is required'),

  medicalInsurancePolicyCategories: Yup.array().of(
    Yup.object().shape({
      gradeId: Yup.number()
        .positive('Grade must be a positive number')
        .required('Grade is required'),
    })
  ),
});

export const ProjectMainCostSchema = Yup.object().shape({
  projectDivisionId: Yup.number()
    .positive('Project Division must be a positive number')
    .required('Project Division is required'),
  projectTypeId: Yup.number()
    .positive('Project Type must be a positive number')
    .required('Project Type is required'),
  billId: Yup.number()
    .positive('BOQ must be a positive number')
    .required('BOQ is required'),
  headId: Yup.number()
    .positive('Package must be a positive number')
    .required('Package is required'),
  subHeadId: Yup.number()
    .positive('SubHead must be a positive number')
    .required('SubHead is required'),
  lineItemId: Yup.number()
    .positive('Line Item must be a positive number')
    .required('Line Item is required'),
  requestedDate: Yup.date()
    .nullable()
    .transform((value) => (value === null ? undefined : value))
    .typeError('Requested Date must be a valid date')
    .required('Requested Date is required'),
  originalCost: Yup.number()
    .typeError('This field should allow numbers only')
    .transform((value, originalValue) => (originalValue === '' ? null : value))
    .min(0, 'Original Cost must be at least 0')
    .required('Original Cost is required'),
  budgetCost: Yup.number()
    .typeError('This field should allow numbers only')
    .transform((value, originalValue) => (originalValue === '' ? null : value))
    .min(0, 'Budget Cost must be at least 0')
    .required('Budget Cost Amount is required'),
  budgetCOD: Yup.number()
    .typeError('This field should allow numbers only')
    .transform((value, originalValue) => (originalValue === '' ? null : value))
    .min(0, 'Budget COD must be at least 0')
    .required('Budget COD is required'),
  budgetCODPercent: Yup.number()
    .typeError('This field should allow numbers only')
    .transform((value, originalValue) => (originalValue === '' ? null : value))
    .min(0, 'Budget COD Percent must be at least 0')
    .max(100, 'Budget COD Percent cannot be more than 100')
    .required('Budget COD Percent is required'),
  actualCost: Yup.number()
    .typeError('This field should allow numbers only')
    .transform((value, originalValue) => (originalValue === '' ? null : value))
    .min(0, 'Actual Cost must be at least 0')
    .required('Actual Cost is required'),
});

export const AccommodationSchema = Yup.object().shape({
  locationId: Yup.number()
    .min(1, 'Location must be greater than 0')
    .required('Location is required'),
  premiseTypeId: Yup.number()
    .min(1, 'Type must be greater than 0')
    .required('Type is required'),
  premisePurposeId: Yup.number()
    .min(1, 'Purpose must be greater than 0')
    .required('Purpose is required'),
  duration: Yup.number()
    .typeError('This field should allow numbers only')
    .transform((value, originalValue) => (originalValue === '' ? null : value))
    .min(1, 'Duration must be greater than 0')
    .required('Duration is required'),
  noOfRoom: Yup.number()
    .typeError('This field should allow numbers only')
    .transform((value, originalValue) => (originalValue === '' ? null : value))
    .min(1, 'Number of rooms must be greater than 0')
    .required('Number of rooms is required'),
  noOfBedSpace: Yup.number()
    .typeError('This field should allow numbers only')
    .transform((value, originalValue) => (originalValue === '' ? null : value))
    .min(1, 'Number of bed spaces must be greater than 0')
    .required('Number of bed spaces is required'),
  startDate: Yup.date().required('Start date is required').nullable(),
  endDate: Yup.date()
    .min(Yup.ref('startDate'), 'End date cannot be before start date')
    .required('End date is required')
    .nullable(),
  beneficiaryDetails: Yup.string().required('Beneficiary details are required'),
  contactInformation: Yup.string().required('Contact information is required'),
  contractNo: Yup.string().required('Contract number is required'),
  size: Yup.number()
    .typeError('This field should allow numbers only')
    .transform((value, originalValue) => (originalValue === '' ? null : value))
    .min(1, 'Size must be greater than 0')
    .required('Size is required'),
  securityDeposit: Yup.number()
    .typeError('This field should allow numbers only')
    .transform((value, originalValue) => (originalValue === '' ? null : value))
    .min(0, 'Security deposit cannot be negative')
    .required('Security deposit is required'),
  rentalCharges: Yup.number()
    .typeError('This field should allow numbers only')
    .transform((value, originalValue) => (originalValue === '' ? null : value))
    .min(0, 'Rental charges cannot be negative')
    .required('Rental charges are required'),
  laundryCharges: Yup.number()
    .typeError('This field should allow numbers only')
    .transform((value, originalValue) => (originalValue === '' ? null : value))
    .min(0, 'Laundry charges cannot be negative')
    .required('Laundry charges are required'),
  chargesMonth: Yup.number()
    .typeError('This field should allow numbers only')
    .transform((value, originalValue) => (originalValue === '' ? null : value))
    .min(1, 'Charges month must be greater than 0')
    .required('Charges month is required'),
  negotiation: Yup.number()
    .typeError('This field should allow numbers only')
    .transform((value, originalValue) => (originalValue === '' ? null : value))
    .min(0, 'Negotiation must be greater than or equal to 0')
    .required('Negotiation is required'),
  remarks: Yup.string().nullable(),
});

export const BudgetCostTrackingValidationSchema = Yup.object().shape({
  billId: Yup.number()
    .positive('BOQ must be a positive number')
    .required('BOQ is required'),
  headId: Yup.number()
    .positive('Package must be a positive number')
    .required('Package is required'),
  subHeadId: Yup.number()
    .positive('SubHead must be a positive number')
    .required('SubHead is required'),
  lineItemId: Yup.number()
    .positive('Line Item must be a positive number')
    .required('Line Item is required'),
  materialAmount: Yup.number()
    .typeError('This field should allow numbers only')
    .transform((value, originalValue) => (originalValue === '' ? null : value))
    .min(0, 'Material Amount must be at least 0')
    .required('Material Amount is required'),
  manPowerAmount: Yup.number()
    .typeError('This field should allow numbers only')
    .transform((value, originalValue) => (originalValue === '' ? null : value))
    .min(0, 'Manpower Amount must be at least 0')
    .required('Manpower Amount is required'),
  machineryAmount: Yup.number()
    .typeError('This field should allow numbers only')
    .transform((value, originalValue) => (originalValue === '' ? null : value))
    .min(0, 'Machinery Amount must be at least 0')
    .required('Machinery Amount is required'),
});

export const ManpowerValidationSchema = Yup.object().shape({
  billId: Yup.number()
    .positive('BOQ must be a positive number')
    .required('BOQ is required'),
  headId: Yup.number()
    .positive('Package must be a positive number')
    .required('Package is required'),
  subHeadId: Yup.number()
    .positive('SubHead must be a positive number')
    .required('SubHead is required'),
  lineItemId: Yup.number()
    .positive('Line Item must be a positive number')
    .required('Line Item is required'),
  description: Yup.string().required('Description is required'),
  remarks: Yup.string().required('Remarks is required'),
});

export const PettyCashValidationSchema = Yup.object().shape({
  pettyCashCategoryId: Yup.number()
    .required('Petty Cash Category is required')
    .min(1, 'Petty Cash Category must be greater than 0'),

  expenseTypeId: Yup.number()
    .required('Expense Type is required')
    .min(1, 'Expense Type must be greater than 0'),

  refNumber: Yup.string().required('Reference Number is required'),
  pettyCashNumber: Yup.string().required('Petty Cash Number is required'),

  amount: Yup.number()
    .typeError('This field should allow numbers only')
    .transform((value, originalValue) => (originalValue === '' ? null : value))
    .required('Amount is required')
    .min(1, 'Amount must be greater than 0'),
  remarks: Yup.string().required('Remarks are required'),
});
export const PettyCashTransactionValidationSchema = Yup.object().shape({
  bankId: Yup.number()
    .required('Bank is required')
    .min(1, 'Bank must be greater than 0'),
  companyId: Yup.number()
    .required('Company is required')
    .min(1, 'Company must be greater than 0'),
  narration: Yup.string()
    .nullable()
    .max(255, 'Narration cannot exceed 255 characters'),
});

export const InjuriesValidationSchema = Yup.object().shape({
  employeeId: Yup.number()
    .required('Employee is required')
    .min(1, 'Employee must be greater than 0'),
  companyId: Yup.number()
    .required('Company is required')
    .min(1, 'Company must be greater than 0'),
  positionId: Yup.number()
    .required('Position is required')
    .min(1, 'Position must be greater than 0'),
  description: Yup.string().required('description are required'),
});

export const CheckoutItemsValidationSchema = Yup.object().shape({
  checkInLocationId: Yup.string().required('Check In Location is required'),
});

export const CreateCheckOutSchemaSchema = Yup.object().shape({
  inChargeId: Yup.number()
    .min(1, 'InCharge must be greater than 0')
    .required('InCharge is required'),
  contractId: Yup.number()
    .min(1, 'Contract must be greater than 0')
    .required('Contract is required'),
  companyId: Yup.number()
    .min(1, 'Company must be greater than 0')
    .required('Company is required'),
  projectId: Yup.number()
    .min(1, 'Project must be greater than 0')
    .required('Project is required'),
  clientId: Yup.number()
    .min(1, 'Client must be greater than 0')
    .required('Client is required'),
  workOrderId: Yup.number()
    .min(1, 'Work Order must be greater than 0')
    .required('Work Order is required'),
});

export const materialRequestItemSchema = Yup.object().shape({
  //TODO:
  // materialRequisitionId: Yup.number().required(
  //   'Material Requisition is required'
  // ),
  quantity: Yup.number()
    .typeError('This field should allow numbers only')
    .required('Quantity is required')
    .positive('Quantity must be greater than 0'),
});

export const addprojectactivitiesSchema = Yup.object().shape({
  costCenterId: Yup.number()
    .required('Cost Center is required')
    .positive('Cost Center must be a positive number')
    .integer('Cost Center must be an integer'),

  activityDate: Yup.date().required('Activity Date is required').nullable(),

  billId: Yup.number()
    .required('BOQ is required')
    .positive('BOQ must be a positive number')
    .integer('BOQ must be an integer'),

  headId: Yup.number()
    .required('Package is required')
    .positive('Package must be a positive number')
    .integer('Package must be an integer'),

  subHeadId: Yup.number()
    .required('Sub Head is required')
    .positive('Sub Head must be a positive number')
    .integer('Sub Head must be an integer'),

  lineItemId: Yup.number()
    .required('Line Item is required')
    .positive('Line Item must be a positive number')
    .integer('Line Item must be an integer')
    .typeError('Line Item must be a number'),

  qtyCompleted: Yup.number()
    .required('Quantity Completed is required')
    .positive('Quantity Completed must be a positive number')
    .integer('Quantity Completed must be an integer'),

  unitOfMeasurementId: Yup.number()
    .required('Unit Of Measurement is required')
    .positive('Unit Of Measurement must be a positive number')
    .integer('Unit Of Measurement must be an integer'),

  projectDivisionStatusId: Yup.number()
    .required('Project Division Status is required')
    .positive(
      'Project Division Status Project Division Status  must be a positive number'
    )
    .integer(
      'Project Division Status Project Division Status  must be an integer'
    ),
  assigneeIds: Yup.array()
    .of(
      Yup.number()
        .positive('Assignee must be a positive number')
        .integer('Assignee must be an integer')
    )
    .required('Assignee is required')
    .min(1, 'At least one Assignee is required'),
});
export const editprojectactivitiesSchema = Yup.object().shape({
  costCenterId: Yup.number()
    .required('Cost Center is required')
    .positive('Cost Center must be a positive number')
    .integer('Cost Center must be an integer'),

  activityDate: Yup.date().required('Activity Date is required').nullable(),

  billId: Yup.number()
    .required('BOQ is required')
    .positive('BOQ must be a positive number')
    .integer('BOQ must be an integer'),

  headId: Yup.number()
    .required('Package is required')
    .positive('Package must be a positive number')
    .integer('Package must be an integer'),

  subHeadId: Yup.number()
    .required('Sub Head is required')
    .positive('Sub Head must be a positive number')
    .integer('Sub Head must be an integer'),

  lineItemId: Yup.number()
    .required('Line Item is required')
    .positive('Line Item must be a positive number')
    .integer('Line Item must be an integer'),

  qtyCompleted: Yup.number()
    .required('Quantity Completed is required')
    .positive('Quantity Completed must be a positive number')
    .integer('Quantity Completed must be an integer'),

  projectDivisionStatusId: Yup.number()
    .required('Project Division Status is required')
    .positive(
      'Project Division Status Project Division Status  must be a positive number'
    )
    .integer(
      'Project Division Status Project Division Status  must be an integer'
    ),

  unitOfMeasurmentId: Yup.number()
    .required('Unit of Measurement is required')
    .positive('Unit of Measurement  must be a positive number')
    .integer('Unit of Measurement  must be an integer'),
});

export const snagReportvalidationSchema = Yup.object().shape({
  snagReportDetailName: Yup.string().required(
    'Snag Report Detail Name is required'
  ),
  // FEW FIELDS ARE NOT MANDATORY IN API
  // equipment: Yup.string().required('Equipment is required'),
  description: Yup.string().required('Description is required'),
  // comments: Yup.string().nullable(), // Optional field
  // blockName: Yup.string().required('Block Name is required'),
  // floorName: Yup.string().required('Floor Name is required'),
  // divisionName: Yup.string().required('Division Name is required'),
  // condition: Yup.string().required('Condition is required'),
  // areaName: Yup.string().required('Area Name is required'),
  // priorityId: Yup.number()
  //   .required('Priority is required')
  //   .min(1, 'Priority must be greater than 0'), // Adjust as necessary
  snagReportId: Yup.number()
    .required('Snag Report is required')
    .min(1, 'Snag Report must be greater than 0'), // Adjust as necessary
  // unitId: Yup.number()
  //   .required('Unit is required')
  //   .min(1, 'Unit must be greater than 0'), // Adjust as necessary
  // subUnitId: Yup.number()
  //   .required('subUnit is required')
  //   .min(1, 'subUnit must be greater than 0'),
  // snagTakenBy: Yup.number()
  //   .required('Snag Taken By is required')
  //   .min(1, 'Snag Taken By must be greater than 0'), // Adjust as necessary
  // approvedBy: Yup.number()
  //   .required('ApprovedBy is required')
  //   .min(1, 'ApprovedBy must be greater than 0'),
  // price: Yup.number()
  //   .required('Price is required')
  //   .min(0, 'Price must be a positive number'), // Adjust as necessary
  // negotiatedPrice: Yup.number()
  //   .nullable() // Optional field
  //   .min(0, 'Negotiated Price must be a positive number'), // Adjust as necessary
});

export const addCheckInvalidationSchema = Yup.object().shape({
  clientId: Yup.number()
    .required('Client is required')
    .min(1, 'Client must be selected'), // Ensures it's not 0
  companyId: Yup.number()
    .required('Company is required')
    .min(1, 'Company must be selected'),
  projectId: Yup.number()
    .required('Project is required')
    .min(1, 'Project must be selected'),
  employeeId: Yup.number()
    .required('Employee is required')
    .min(1, 'Employee must be selected'),
  workOrderId: Yup.number()
    .required('Work order is required')
    .min(1, 'Work order must be selected'),
  contractId: Yup.number()
    .required('Contract is required')
    .min(1, 'Contract must be selected'),
});
export const addCheckOutvalidationSchema = Yup.object().shape({
  loginUserId: Yup.string().required('In Charge Name is required'),
  contractId: Yup.string().required('Contract is required'),
  companyId: Yup.string().required('Company is required'),
  clientId: Yup.string().required('Client is required'),
  projectId: Yup.string().required('Project is required'),
  workOrderId: Yup.string().required('Work order is required'),
});

export const inspectionreportitems = Yup.object().shape({
  description: Yup.string()
    .required('Description is required')
    .min(3, 'Description must be at least 3 characters long'),
  verified: Yup.boolean()
    .required('Verified status is required')
    .oneOf([true, false], 'Verified must be a boolean value'),

  targetDateForRectification: Yup.date()
    .required('Target Date for Rectification is required')
    .typeError('Invalid date format'),
});

export const addprojectdivisionplan = Yup.object().shape({
  activityId: Yup.string()
    .required('Activity is required')
    .matches(
      /^[A-Za-z0-9_-]*$/,
      'Activity can only contain letters, numbers, underscores, and dashes'
    ),

  activityName: Yup.string()
    .required('Activity Name is required')
    .max(255, 'Activity Name cannot exceed 255 characters'),

  orgDuration: Yup.number()
    .typeError('Original Duration must be a number')
    .required('Original Duration is required')
    .min(1, 'Original Duration must be greater than 0'),

  startDate: Yup.date()
    .nullable()
    .required('Start Date is required') // required date, can be null
    .min(
      dayjs().subtract(1, 'day').toDate(),
      'Start Date cannot be in the past'
    ), // prevent past dates
  endDate: Yup.date()
    .nullable()
    .required('End Date is required') // required date, can be null
    .min(Yup.ref('startDate'), 'End Date must be after Start Date'),

  totalFloat: Yup.number()
    .typeError('Total Float must be a number')
    .required('Total Float is required')
    .min(0, 'Total Float must be 0 or more'),
});

export const TenantVehiclevalidationSchema = Yup.object().shape({
  vehicleBrandId: Yup.number()
    .required('Vehicle brand is required')
    .min(1, 'Vehicle brandID must be greater than 0'),

  vehicleColor: Yup.string().required('Vehicle color code is required'),

  registrationNumber: Yup.number()
    .typeError('Registration Number must be a number')
    .required('Registration Number is required')
    .min(0, 'Registration Number cannot be negative'),
});

export const ItemPropertyvalidationSchema = Yup.object().shape({
  propertyId: Yup.number()
    .required('Property is required')
    .min(1, 'Property must be greater than 0'),
  checkInLocationId: Yup.number()
    .required('Location is required')
    .min(1, 'Location must be greater than 0'),
});

export const mRSchema = Yup.object().shape({
  companyId: Yup.number()
    .typeError('Company must be a number')
    .required('Company is required'),
  projectId: Yup.number()
    .typeError('Project must be a number')
    .required('Project is required'),
  materialRequisitionStatusId: Yup.number()
    .typeError('Material Requisition Status must be a number')
    .required('Material Requisition Status is required'),
  contractId: Yup.number()
    .typeError('Contract must be a number')
    .required('Contract is required'),
  divisionId: Yup.number()
    .typeError('Division must be a number')
    .required('Division is required'),
  requesterId: Yup.number()
    .typeError('Requester must be a number')
    .required('Requester is required'),
  locationId: Yup.number()
    .typeError('Location must be a number')
    .required('Location is required'),
  materialRequisitionTypeId: Yup.number()
    .typeError('Material Requisition Type must be a number')
    .required('Material Requisition Type is required'),
  regionId: Yup.number()
    .typeError('Region must be a number')
    .required('Region is required'),
  supplierSPOCId: Yup.number()
    .typeError('Supplier SPOC must be a number')
    .required('Supplier SPOC is required'),
  spocEmailId: Yup.string().email('Invalid email format'),
});

export const AddIncidentReportSchema = Yup.object().shape({
  companyId: Yup.number()
    .required('Company is required')
    .min(1, 'Company must be greater than 0'),
  locationId: Yup.number()
    .required('Location is required')
    .min(1, 'Location must be greater than 0'),
  clientId: Yup.number()
    .required('Client is required')
    .min(1, 'Client must be greater than 0'),
  divisionId: Yup.number()
    .required('Division is required')
    .min(1, 'Division must be greater than 0'),
  projectId: Yup.number()
    .required('Project is required')
    .min(1, 'Project must be greater than 0'),
  contractId: Yup.number()
    .required('Contract is required')
    .min(1, 'Contract must be greater than 0'),
  buildingId: Yup.number()
    .required('Building is required')
    .min(1, 'Building must be greater than 0'),
  area: Yup.string().required('Area is required'),
  verifiedBy: Yup.number()
    .required('Verified By is required')
    .min(1, 'Verified By must be greater than 0'),
  preparedBy: Yup.number()
    .required('Prepared By is required')
    .min(1, 'Prepared By must be greater than 0'),
  typeOfIncidentId: Yup.number()
    .required('Type of Incident is required')
    .min(1, 'Type of Incident must be greater than 0'),
  incidentDescription: Yup.string().required(
    'Incident Description is required'
  ),
  incidentImpact: Yup.string().required('Incident Impact is required'),
  briefDescription: Yup.string().required('Brief Description is required'),
  rootCauseAnalysis: Yup.string().required('Root Cause Analysis is required'),
  actionPlan: Yup.string().required('Action Plan is required'),
});
export const SalesOrdervalidationSchema = Yup.object().shape({
  projectId: Yup.number()
    .required('Project is required')
    .min(1, 'Project must be greater than 0')
    .typeError('Project must be a number'),

  contractId: Yup.number()
    .required('Contract is required')
    .min(1, 'Contract must be greater than 0')
    .typeError('Contract must be a number'),

  workOrderId: Yup.number()
    .required('Work Order is required')
    .min(1, 'Work Order must be greater than 0')
    .typeError('Work Order must be a number'),
  companyId: Yup.number()
    .required('Company is required')
    .min(1, 'Company must be greater than 0')
    .typeError('Company must be a number'),

  clientId: Yup.number()
    .required('Client is required')
    .min(1, 'Client must be greater than 0')
    .typeError('Client must be a number'),

  salesOrderDistribustionDate: Yup.date()
    .required('date is required')
    .nullable()
    .typeError('Invalid date format')
    .test('invalidDate', 'Invalid Date', (value) => {
      return value instanceof Date && !isNaN(value.getTime());
    }),

  preparedBy: Yup.number()
    .required('Prepared by is required')
    .min(1, 'Prepared by must be greater than 0')
    .typeError('Prepared by must be a number'),

  approvedBy: Yup.number()
    .required('Approved by is required')
    .min(1, 'Approved by must be greater than 0')
    .typeError('Approved by must be a number'),
  totalContractValue: Yup.number()
    .typeError('Total contract value must be a number')
    .required('Total contract value is required')
    .min(0, 'Total contract value cannot be negative'),

  totalBudgetValue: Yup.number()
    .typeError('Total budget value must be a number')
    .required('Total budget is required')
    .min(1, 'Total budget must be greater than 0'),
  budgetValue: Yup.number()
    .typeError('Budget value must be a number')
    .required('Budget value is required')
    .min(0.0001, 'Budget value must be greater than 0.0001')
    .max(100, 'Budget value cannot be more than 100'),
});

export const SalesOrderBudgetvalidationSchema = Yup.object().shape({
  divisionId: Yup.number()
    .required('Division is required')
    .typeError('Division must be a number')
    .min(1, 'Division must be greater than 0'),

  totalBudgetValuePercentage: Yup.number()
    .typeError('Total Budget value Percentage must be a number')
    .required('Total Budget value Percentage is required')
    .min(0.0001, 'Total Budget value must be greater than 0.0001')
    .max(100, 'Percentage cannot be more than 100'),
  totalContractValuePercentage: Yup.number()
    .typeError('Total Contract value Percentage must be a number')
    .min(0.0001, 'Total Contract value Percentage must be greater than 0.0001')
    .max(100, 'Percentage cannot be more than 100'),
});
export const contractSchema = Yup.object().shape({
  contractName: Yup.string().required('Contract name is required'),
  contractManualCode: Yup.string().required('Contract manual code is required'),
  billingFrequencyId: Yup.number()
    .required('Billing frequency is required')
    .min(1, 'Billing frequency must be greater than or equal to 1'),
  projectId: Yup.number()
    .required('Project is required')
    .min(1, 'Project must be greater than or equal to 1'),
  clientId: Yup.number()
    .required('Client is required')
    .min(1, 'Client must be greater than or equal to 1'),
  contactPerson: Yup.string().required('Contact person is required'),
  contactNumber: Yup.string().required('Contact number is required'),
  contactEmailId: Yup.string()
    .email('Contact email must be a valid email address')
    .required('Contact email is required'),
  contractCost: Yup.number()
    .typeError('Contract cost must be a number')
    .required('Contract cost is required')
    .min(0, 'Contract cost must be greater than or equal to 0'),
  budget: Yup.number()
    .typeError('Budget must be a number')
    .required('Budget is required')
    .min(0, 'Budget must be greater than or equal to 0')
    .test(
      'budget-vs-contractCost',
      'Budget should not exceed the contract cost',
      function (value) {
        const { contractCost } = this.parent;
        return value <= contractCost;
      }
    ),
  thresholdValue: Yup.number()
    .typeError('Threshold value must be a number')
    .required('Threshold value is required'),
  contractTerms: Yup.string().required('Contract terms are required'),
  contractModeId: Yup.number()
    .required('Contract mode is required')
    .min(1, 'Contract mode must be greater than or equal to 1'),
  contractTypeId: Yup.number()
    .required('Contract type is required')
    .min(0, 'Contract type must be greater than or equal to 0'),
  contractStartDate: Yup.date().required('Contract start date is required'),
  contractEndDate: Yup.date().required('Contract end date is required'),
  guaranteeStartDate: Yup.date().required('Guarantee start date is required'),
  guaranteeEndDate: Yup.date().required('Guarantee end date is required'),
});

export const salesorderitemschema = Yup.object({
  salesOrderItemName: Yup.string().required(
    'Sales Order Item Name is required'
  ),
  // itemId: Yup.number()
  //   .required('Item ID is required')
  //   .min(1, 'Item ID must be greater than 0'),
  contractId: Yup.number()
    .required('Contract is required')
    .min(1, 'Contract must be greater than 0'),
  quantity: Yup.number()
    .required('Quantity is required')
    .min(1, 'Quantity must be greater than 0')
    .typeError('Quantity must be a number'),
  amount: Yup.number().required('Amount is required'),
});

export const salesquotationItemsSchema = Yup.object().shape({
  salesQuotationItemName: Yup.string()
    .required('Item Name is required')
    .min(2, 'Name must be at least 2 characters')
    .max(100, 'Name cannot exceed 100 characters'),
  quantity: Yup.number()
    .required('Quantity is required')
    .min(1, 'Quantity must be at least 1')
    .typeError('Quantity must be a number'),
  rate: Yup.number().typeError('Rate must be a number'),
  gross: Yup.number().typeError('Gross must be a number'),
});
export const TenderInspectionvalidationSchema = Yup.object().shape({
  tenderId: Yup.number()
    .required('Tender is required')
    .positive('Tender must be greater than 0'),
  existServiceProvider: Yup.string().required(
    'Existing Service Provider is required'
  ),
});
export const contractCustomerSchema = Yup.object().shape({
  contractCustomerName: Yup.string().required(
    'Contract customer name is required'
  ),
  groupTypeId: Yup.number()
    .transform((value, originalValue) => (originalValue === '' ? null : value))
    .required('Group type is required'),
});

export const contractScheduleSchema = Yup.object().shape({
  contractId: Yup.number()
    .required('Contract is required')
    .min(1, 'Contract must be greater than 0'),
  billingFrequencyId: Yup.number()
    .required('Billing frequency is required')
    .min(1, 'Billing frequency must be greater than 0'),
  amountToBeInvoiced: Yup.number()
    .transform((value, originalValue) => (originalValue === '' ? null : value))
    .required('Amount to be invoiced is required'),
  invoicedAmount: Yup.number()
    .transform((value, originalValue) => (originalValue === '' ? null : value))
    .required('Invoiced amount is required'),
  invoiceRef: Yup.string().required('Invoice is required'),
  paymentAmount: Yup.number()
    .transform((value, originalValue) => (originalValue === '' ? null : value))
    .required('Payment amount is required'),
  paymentRef: Yup.string().required('Payment is required'),
  deductionAmount: Yup.number()
    .transform((value, originalValue) => (originalValue === '' ? null : value))
    .required('Deduction is required'),
});

export const ProjectTeamsSchema = Yup.object().shape({
  employeeId: Yup.number()
    .required('Employee is required')
    .min(1, 'Employee must be greater than 0')
    .typeError('Employee must be a number'),
});
export const contractSubSchema = Yup.object().shape({
  divisionId: Yup.number()
    .required('Division is required')
    .typeError('Division is required'),
  supplierId: Yup.number()
    .required('Supplier is required')
    .typeError('Supplier must be a number'),
});
export const requestForQuoteSchemaTableEdit = Yup.object().shape({
  requestForQuoteNumber: Yup.string().required(
    'Request for Quote Number is required'
  ),

  companyId: Yup.number()
    .required('Company is required')
    .min(1, 'Company must be greater than 0'),

  projectId: Yup.number()
    .required('Project is required')
    .min(1, 'Project must be greater than 0'),

  regionId: Yup.number()
    .required('Region is required')
    .min(1, 'Region must be greater than 0'),

  divisionId: Yup.number()
    .required('Division is required')
    .min(1, 'Division must be greater than 0'),
});
export const employeeEducationSchema = Yup.object().shape({
  employeeEducations: Yup.array().of(
    Yup.object().shape({
      qualificationName: Yup.string()
        .min(2, 'Qualification Name must be at least 2 characters')
        .required('Qualification Name is required'),

      completedYear: Yup.string()
        .matches(/^\d{4}$/, 'Completed Year must be a valid 4-digit year')
        .required('Completed Year is required'),

      attestationStatus: Yup.boolean()
        .required('Attestation Status is required')
        .typeError('Attestation Status must be a boolean'),
    })
  ),
});
export const manpowerSchema = Yup.object().shape({
  designationId: Yup.number()
    .required('Designation is required')
    .typeError('Designation must be a number')
    .min(1, 'Designation must be greater than 0'),

  companyId: Yup.number()
    .required('Company is required')
    .typeError('Company must be a number')
    .min(1, 'Company must be greater than 0'),
  regionId: Yup.number()
    .required('Region is required')
    .typeError('Region must be a number')
    .min(1, 'Region must be greater than 0'),
  basicSalary: Yup.number()
    .required('Basic Salary is required')
    .typeError('Basic Salary must be a number')
    .min(0, 'Basic Salary must be at least 0'),
  housingAllowance: Yup.number()
    .required('Housing Allowance is required')
    .typeError('Housing Allowance must be a number')
    .min(0, 'Housing Allowance must be at least 0'),
  transportAllowance: Yup.number()
    .required('Transport Allowance is required')
    .typeError('Transport Allowance must be a number')
    .min(0, 'Transport Allowance must be at least 0'),
  foodAllowance: Yup.number()
    .required('Food Allowance is required')
    .typeError('Food Allowance must be a number')
    .min(0, 'Food Allowance must be at least 0'),
  otherAllowance: Yup.number()
    .required('Other Allowance is required')
    .typeError('Other Allowance must be a number')
    .min(0, 'Other Allowance must be at least 0'),
  telephoneAllowance: Yup.number()
    .required('Telephone Allowance is required')
    .typeError('Telephone Allowance must be a number')
    .min(0, 'Telephone Allowance must be at least 0'),
  additionalAllowance: Yup.number()
    .required('Additional Allowance is required')
    .typeError('Additional Allowance must be a number')
    .min(0, 'Additional Allowance must be at least 0'),
  incentiveAllowance: Yup.number()
    .required('Incentive Allowance is required')
    .typeError('Incentive Allowance must be a number')
    .min(0, 'Incentive Allowance must be at least 0'),
  deductions: Yup.number()
    .required('Deductions is required')
    .typeError('Deductions must be a number')
    .min(0, 'Deductions must be at least 0'),
  ctcPerMonth: Yup.number()
    .typeError('CTC Per Month must be a valid number') // Validates the type
    .required('CTC Per Month is required') // Ensure field is required
    .min(0, 'CTC Per Month must be at least 0') // Allow 0 or greater
    .test(
      'is-decimal',
      'CTC Per Month must be a positive number',
      (value) => value === 0 || (value > 0 && /^\d*\.?\d*$/.test(value))
    ),
});

export const CustomerSatisfactionSchema = Yup.object().shape({
  areaId: Yup.number().required('Area is required'),
  subAreaId: Yup.number().required('Sub Area is required'),
  mark: Yup.string().required('Mark is required'),
  remarks: Yup.string().required('Remarks is required'),
});
export const CustomerSatisfactionItemSchema = Yup.object().shape({
  projectId: Yup.number().required('Project is required'),
  locationId: Yup.number().required('Location is required'),
  buildingId: Yup.number().required('Building is required'),
  surveyTaker: Yup.number().required('Survey Taker is required'),
  designationId: Yup.number().required('Designation is required'),
  firstAddress: Yup.string().required('First Address is required'),
  secondAddress: Yup.string().required('Second Address is required'),
  date: Yup.string().required('Date is required'),
  remarks: Yup.string().required('Remarks is required'),
});
export const CustomersSchema = Yup.object().shape({
  customerName: Yup.string().required('Customer Name is required'),
});

export const AreasSchema = Yup.object().shape({
  areaName: Yup.string().required('Area Name is required'),
});
export const SubAreaSchema = Yup.object().shape({
  subAreaName: Yup.string().required('Sub Area Name is required'),
  areaId: Yup.number()
    .transform((value, originalValue) => (originalValue === '' ? null : value))
    .required('Area is required'),
});

export const contractLocationSchema = Yup.object().shape({
  regionId: Yup.number().required('Region is required'),

  emirateId: Yup.number().required('Emirate is required'),

  zoneId: Yup.number().required('Zone is required'),

  locationId: Yup.number().required('Location is required'),
  buildingId: Yup.number().required('Building is required'),
});

export const BlockValidationSchema = Yup.object().shape({
  blockName: Yup.string().required('Block Name is required'),
});
export const BlockEditValidationSchema = Yup.object().shape({
  blockName: Yup.string().required('Block Name is required'),
});
export const RoomValidationSchema = Yup.object().shape({
  roomName: Yup.string().required('Room Name is required'),
  roomNo: Yup.number()
    .required('Room No is required')
    .typeError('Room No must be a number'),
  totalCapacity: Yup.number()
    .required('Total Capacity is required')
    .typeError('Total Capacity must be a number'),
});
export const RoomEditValidationSchema = Yup.object().shape({
  roomName: Yup.string().required('Room Name is required'),
  roomNo: Yup.number()
    .required('Room No is required')
    .typeError('Room No must be a number'),
  totalCapacity: Yup.number()
    .required('Total Capacity is required')
    .typeError('Total Capacity must be a number'),
});

export const monthsupdateschema = Yup.object().shape({
  divisionId: Yup.number()
    .min(1, 'Division must be greater than 0')
    .required('Division is required'),
  vehicleMethod: Yup.boolean().required('Vehicle method is required'),
  month: Yup.number()
    .min(1, 'Month must be between 1 and 12')
    .max(12, 'Month must be between 1 and 12')
    .required('Month is required'),
  year: Yup.number()
    .min(1900, 'Year must be valid')
    .max(new Date().getFullYear(), 'Year cannot be in the future')
    .required('Year is required'),
  locationId: Yup.number()
    .min(1, 'Location must be greater than 0')
    .required('Location is required'),
  projectId: Yup.number()
    .min(1, 'Project must be greater than 0')
    .required('Project is required'),
  siteId: Yup.number()
    .min(1, 'Site must be greater than 0')
    .required('Site is required'),
  currentKilometer: Yup.number()
    .min(0, 'Current kilometer must be greater than or equal to 0')
    .required('Current kilometer is required'),
  pickUpTime: Yup.string().required('Pick-up time is required'),
  dropoffTime: Yup.string().required('Dropoff time is required'),
  remarks: Yup.string()
    .max(500, 'Remarks must be 500 characters or less')
    .required('Remarks are required'),
  transporterName: Yup.string()
    .max(255, 'Transporter name must be 255 characters or less')
    .required('Transporter name is required'),
  contractStartingPeriod: Yup.date()
    .required('Contract starting period is required')
    .max(
      Yup.ref('contractEndingPeriod'),
      'Start date must be before the end date'
    ),
  contractEndingPeriod: Yup.date()
    .required('Contract ending period is required')
    .min(
      Yup.ref('contractStartingPeriod'),
      'End date must be after the start date'
    ),
  rentAmount: Yup.number()
    .min(0, 'Rent amount must be greater than or equal to 0')
    .required('Rent amount is required'),
  contactPerson: Yup.number()
    .min(1, 'Contact person must be greater than 0')
    .required('Contact person is required'),
});
export const VehicleAdvertisementSchema = Yup.object().shape({
  vehicleId: Yup.number().required('Vehicle is required'),
  permitNumber: Yup.string().required('Premit Number is required'),
  issueDate: Yup.date().required('Issue Date is required'),
  expiryDate: Yup.date()
    .required('Expiry Date is required')
    .nullable()
    .when('issueDate', (issueDate, schema) => {
      return issueDate
        ? schema.min(issueDate, 'Expiry Date must be later than Issue Date')
        : schema;
    }),
});

export const addVehicleMastervalidationSchema = Yup.object().shape({
  vehicleCategoryId: Yup.string().required('vehicle category is required'),
  vehicleName: Yup.number().required('vehicle name is required'),
  vehicleBrandId: Yup.number()
    .required('Project is required')
    .min(1, 'Project must be selected'),
  vehiclePlateNo: Yup.string().required('vehicle plate no is required'),
  chasisNo: Yup.string().required('Chasis no is required'),
  engineNo: Yup.number().required('Engine is required'),
  vehicleStatusId: Yup.string().required('vehicle Status is required'),
});
export const AdminVehiclevalidationSchema = Yup.object().shape({
  vehicleTypeName: Yup.string().required('Vehicle Type is required'),
});
export const DriverMastervalidationSchema = Yup.object().shape({
  driverName: Yup.string().required('Driver Name is required'),
  licenseNumber: Yup.string().required('License Number is required'),
  loginUserId: Yup.string().required('Prepared By is required'),
});
export const TrafficSourcevalidationSchema = Yup.object().shape({
  trafficSourceName: Yup.string().required('Traffic Source is required'),
  emirateId: Yup.string().required('Emirate is required'),
});
export const FloorvalidationSchema = Yup.object().shape({
  floorName: Yup.string().required('Floor is required'),
  blockId: Yup.string().required('Block is required'),
});
export const LevelsvalidationSchema = Yup.object().shape({
  levelName: Yup.string().required('Level is required'),
  floorId: Yup.string().required('Floor is required'),
});
export const VehicleBrandvalidationSchema = Yup.object().shape({
  vehicleBrandName: Yup.string().required('Vehicle Brand is required'),
});
export const VehicleCategoryvalidationSchema = Yup.object().shape({
  vehicleCategoryName: Yup.string().required('Vehicle Category is required'),
});
export const VehicleInsurancevalidationSchema = Yup.object().shape({
  vehicleInsuranceName: Yup.string().required(
    'Vehicle Insurance Name is required'
  ),
  policyNumber: Yup.string().required('Policy Number is required'),
  premiumAmount: Yup.number()
    .required('Premium Amount is required')
    .typeError('Premium Amount must be a number'),
  startDate: Yup.string().required('Start Date is required'),
  endDate: Yup.string().required('End Date is required'),
  policyType: Yup.string().required('Policy Type is required'),
});

export const VehiclevalidationSchema = Yup.object().shape({
  vehicleName: Yup.string().required('Vehicle Name is required'),
  vehicleCategoryId: Yup.string().required('Vehicle Category is required'),
  vehicleTypeId: Yup.string().required('Vehicle Type is required'),
  vehiclePlateTypeId: Yup.string().required('Plate Type is required'),
  vehicleRegistered: Yup.string().required('Vehicle Registered is required'),
  presentlyLocatedId: Yup.string().required('Location is required'),
  vehicleBrandId: Yup.string().required('Vehicle Brand is required'),
  vehicleStatusId: Yup.string().required('Vehicle Status is required'),
  vehiclePlateNo: Yup.string().required('Vehicle Plate is required'),
  chasisNo: Yup.string().required('Chasis Number is required'),
  engineNo: Yup.string().required('Engine Number is required'),
  registeredName: Yup.string().required('Registered Name is required'),
  trafficCodeNo: Yup.string().required('Traffic Code is required'),
  registeredDate: Yup.string().required('Registered Date is required'),
  registeredExpiryDate: Yup.string().required(
    'Registered Expiry Date is required'
  ),
  insuranceExpiryDate: Yup.string().required(
    'Insurance Expiry Date is required'
  ),
  vehicleModel: Yup.string().required('Vehicle Model is required'),
});
export const VehicleStatusvalidationSchema = Yup.object().shape({
  vehicleStatusName: Yup.string().required('Vehicle Status is required'),
});
export const VehicleSuppliervalidationSchema = Yup.object().shape({
  supplierCode: Yup.string().required('Supplier Code is required'),
  officialSupplierName: Yup.string().required('Supplier Name is required'),
  emailId: Yup.string().required('Email is required'),
  taxPercentage: Yup.number()
    .transform((value, originalValue) =>
      originalValue === '' ? undefined : value
    )
    .nullable()
    .min(0, 'Tax Percentage cannot be less than 0')
    .max(100, 'Tax Percentage cannot exceed 100'),

  supplierTypeId: Yup.string().required('Supplier Type is required'),
});
export const vehicelsSchema = Yup.object().shape({
  vehicleId: Yup.string().required('Vehicle is required'),
  trafficCodeNo: Yup.string().required('Traffic Code No is required'),
  insuranceId: Yup.string().required('Insurance is required'),
  driverId: Yup.string().required('Driver is required'),
  divisionId: Yup.string().required('Division is required'),
  advertisement: Yup.boolean().required('Advertisement is required'),
});

export const HiredVechiclesSchema = Yup.object().shape({
  vehicleRegNo: Yup.string()
    .transform((value) => (value === '' ? null : value))
    .nullable()
    .matches(
      /^[A-Za-z0-9-\s]+$/,
      'Vehicle registration number must be alphanumeric, may include hyphens, and can contain spaces'
    ),

  vehiclePlateNo: Yup.string()
    .transform((value) => (value === '' ? null : value))
    .nullable()
    .matches(
      /^[A-Za-z0-9-\s]+$/,
      'Vehicle plate number must be alphanumeric, may include hyphens, and can contain spaces'
    ),
  vehicleTypeId: Yup.number()
    .required('Vehicle type is required')
    .positive('Vehicle type must be greater than zero')
    .integer('Vehicle type must be an integer'),

  siteId: Yup.number()
    .required('Site is required')
    .positive('Site must be greater than zero')
    .integer('Site must be an integer'),

  locationId: Yup.number()
    .required('Location is required')
    .positive('Location must be greater than zero')
    .integer('Location must be an integer'),

  divisionId: Yup.number()
    .required('Division is required')
    .positive('Division must be greater than zero')
    .integer('Division must be an integer'),

  pickUpTime: Yup.string().required('Pick-up time is required'),

  dropUpTime: Yup.string().required('Drop-off time is required'),

  currentKilometer: Yup.number()
    .required('Current kilometer is required')
    .typeError('Current kilometer must be a number')
    .positive('Current kilometer must be greater than zero')
    .integer('Current kilometer must be an integer'),

  supplierId: Yup.number()
    .required('Supplier is required')
    .positive('Supplier must be greater than zero')
    .integer('Supplier must be an integer'),

  stationId: Yup.number()
    .required('Station is required')
    .positive('Station must be greater than zero')
    .integer('Station must be an integer'),

  driverId: Yup.number()
    .required('Driver is required')
    .positive('Driver must be greater than zero')
    .integer('Driver must be an integer'),

  projectId: Yup.number()
    .required('Project is required')
    .positive('Project must be greater than zero')
    .integer('Project must be an integer'),

  contractId: Yup.number()
    .required('Contract is required')
    .positive('Contract must be greater than zero')
    .integer('Contract must be an integer'),

  rentAmount: Yup.number()
    .required('Rent amount is required')
    .typeError('Rent amount must be a number')
    .positive('Rent amount must be greater than zero'),

  contactPersonId: Yup.number()
    .required('Contact person is required')
    .positive('Contact person must be greater than zero')
    .integer('Contact person must be an integer'),

  remarks: Yup.string().optional(),
});
export const supplierDocumentsSchema = Yup.object().shape({
  supplierDocumentName: Yup.string().required('Document name is required'),
  validityEndDate: Yup.date().required('End date is required'),
  validityStartDate: Yup.date().required('Start date is required'),
  // fileUploadId: Yup.number().required('File is required'),
});

export const uniformsSchama = Yup.object().shape({
  requestDate: Yup.date()
    .required('Request date is required')
    .typeError('Invalid date format'),
  uniformSizeId: Yup.number()
    .required('Uniform Size is required')
    .min(1, 'Uniform Size must be greater than 0')
    .typeError('Uniform Size must be a number'),
  uniformTypeId: Yup.number()
    .required('Uniform Type is required')
    .min(1, 'Uniform Type must be greater than 0')
    .typeError('Uniform Type must be a number'),
  quantity: Yup.number()
    .required('Quantity is required')
    .typeError('Quantity must be a number'),
});

export const uniformRequsitionReturnSchema = Yup.object().shape({
  requisitionDate: Yup.date().nullable().typeError('Invalid date format'),
  employeeId: Yup.number()
    .positive('Employee must be greater than 0')
    .required('Employee is required'),
  uniformAndAccessoriesRequisitionId: Yup.number()
    .positive('Requisition must be greater than 0')
    .required('Requisition is required'),
});

export const CampValidationSchema = Yup.object().shape({
  campName: Yup.string().required('Camp Name is required'),
  accommodationId: Yup.number().required('Accommodation is required'),
  employeeId: Yup.number().required('Employee is required'),
  blockId: Yup.number().required('Block is required'),
  roomId: Yup.number().required('Room is required'),
  occupied: Yup.number()
    .required('Occupied is required')
    .typeError('Occupied must be a number'),
  // TODO
  // employeeRoomStatusId: Yup.number().required('Employee Room Status is required'),
});

export const PurchaseVoucherSchema = Yup.object().shape({
  purchaseVoucherCode: Yup.string().required(
    'Purchase Voucher Code is required'
  ),
  supplierId: Yup.number()
    .required('Supplier is required')
    .typeError('Supplier must be a number'),
  currencyId: Yup.mixed()
    .required('Currency is required')
    .typeError('Currency must be a number'),
  locationId: Yup.number()
    .required('Location is required')
    .typeError('Location must be a number'),
  supplyLocationId: Yup.number()
    .required('Supply Location is required')
    .typeError('Supply Location must be a number'),
  invoiceNo: Yup.string()
    .nullable()
    .min(0, 'Invoice number must be at least 0')
    .max(32, 'Invoice number must be less than 32 characters'),
});

export const PurchaseVoucherItemSchema = Yup.object().shape({
  projectId: Yup.number().required('Project is required'),
});
export const CustomersatisfactionReportValidationSchema = Yup.object().shape({
  customerName: Yup.string().required('Customer Name is required'),
  customerDesignation: Yup.string().required(
    'Customer Designation is required'
  ),
  contactNumber: Yup.string().required('Contact Number is required'),
  jobOrderId: Yup.number().required('Job Order is required'),
  locationId: Yup.number().required('Location is required'),
  customerSatisfactionQuestionTypeId: Yup.number().required(
    'Customer Satisfaction Question is required'
  ),
  question: Yup.string().required('Question is required'),
  performanceRanking: Yup.number()
    .required('Performance Ranking is required')
    .typeError('Performance Ranking must be a number'),
  priorityRanking: Yup.number()
    .required('Priority Ranking is required')
    .typeError('Priority Ranking must be a number'),
  remarks: Yup.string().required('Remarks is required'),
});
export const ReceiptKeyValidationSchema = Yup.object().shape({
  quantity: Yup.number()
    .typeError('Quantity must be a number')
    .required('Quantity Number is required')
    .min(0, 'Quantity Number cannot be negative'),

  codeNumber: Yup.string().required('Code Number is required'),
});

export const SalesRetentionItemvalidationSchema = Yup.object().shape({
  companyId: Yup.number().required('Company is required'),
  itemId: Yup.number().required('Item is required'),
  unitOfMeasurementId: Yup.number().required('UOM is required'),
  quantity: Yup.number()
    .required('Quantity is required')
    .typeError('Quantity must be a number'),
});

export const editSalesQuotationSchema = Yup.object().shape({
  salesQuotationName: Yup.string().required('Sales Quotation Name is required'),
  quotationDate: Yup.date().required('Quotation Date is required'),
  workOrderId: Yup.number()
    .transform((value, originalValue) => (originalValue === '' ? null : value))
    .required('Work Order is required'),
  regionId: Yup.number()
    .transform((value, originalValue) => (originalValue === '' ? null : value))
    .required('Region is required'),
  locationId: Yup.number()
    .transform((value, originalValue) => (originalValue === '' ? null : value))
    .required('Location is required'),
  paymentTerms: Yup.string().required('Payment Terms is required'),
  contactPersonId: Yup.number()
    .transform((value, originalValue) => (originalValue === '' ? null : value))
    .required('Contact Person is required'),
  deliveryTerms: Yup.string().required('Delivery Terms is required'),
  subject: Yup.string().required('Subject is required'),
  notes: Yup.string().required('Notes is required'),
  attn: Yup.string().required('Attn is required'),
  validity: Yup.string().required('Validity is required'),
  quotationStatusId: Yup.number()
    .transform((value, originalValue) => (originalValue === '' ? null : value))
    .required('Quotation Status is required'),
});

export const AddSalesRetentionSehema = Yup.object().shape({
  salesRetentionCode: Yup.string()
    .required('Sales Retention Code is required')
    .max(100, 'Sales Retention Code must be 100 characters or less'),
  customerId: Yup.number().required('Customer is required'),
  companyId: Yup.number().required('Company is required'),
  currencyId: Yup.number().required('Currency is required'),

  projectId: Yup.number().required('Project is required'),
  contractId: Yup.number().required('Contract is required'),
});
export const CBPettyCashValidationSchema = Yup.object().shape({
  bankId: Yup.number().required('Bank is required'),
  companyId: Yup.number().required('Company is required'),
  branchId: Yup.number().required('Branch is required'),
});

export const AMCTableValidationSchema = Yup.object().shape({
  productCode: Yup.string().required('productCode is required'),
});

export const MEPTableValidationSchema = Yup.object().shape({
  overHeadPercent: Yup.number()
    .nullable()
    .transform((value, originalValue) => (originalValue === '' ? null : value))
    .min(0, 'Overhead Percent cannot be negative')
    .max(100, 'Overhead Percent cannot exceed 100'),
  contingencyPercent: Yup.number()
    .nullable()
    .transform((value, originalValue) => (originalValue === '' ? null : value))
    .min(0, 'Contingency Percent cannot be negative')
    .max(100, 'Contingency Percent cannot exceed 100'),
  markUpPercent: Yup.number()
    .nullable()
    .transform((value, originalValue) => (originalValue === '' ? null : value))
    .min(0, 'Markup Percent cannot be negative')
    .max(100, 'Markup Percent cannot exceed 100'),
  discountPercent: Yup.number()
    .nullable()
    .transform((value, originalValue) => (originalValue === '' ? null : value))
    .min(0, 'Discount Percent cannot be negative')
    .max(100, 'Discount Percent cannot exceed 100'),
  negotiationPercent: Yup.number()
    .nullable()
    .transform((value, originalValue) => (originalValue === '' ? null : value))
    .min(0, 'Negotiation Percent cannot be negative')
    .max(100, 'Negotiation Percent cannot exceed 100'),
  bill: Yup.string().required('Bill is required'),
  head: Yup.string().required('Head is required'),
  subHead: Yup.string().required('Sub Head is required'),
  lineItem: Yup.string().required('Line Item is required'),
});

export const AddUniformRequistionsSchema = Yup.object().shape({
  projectId: Yup.number()
    .required('Project is required')
    .positive('Project must be a positive number')
    .integer('Project must be an integer'),

  contractId: Yup.number()
    .required('Contract is required')
    .positive('Contract must be a positive number')
    .integer('Contract must be an integer'),

  endDate: Yup.date()
    .required('End Date is required')
    .typeError('End Date must be a valid date'),

  requestedBy: Yup.number()
    .required('Requested By is required')
    .positive('Requested By must be a positive number')
    .integer('Requested By must be an integer'),

  approvedBy: Yup.number()
    .required('Approved By is required')
    .positive('Approved By must be a positive number')
    .integer('Approved By must be an integer'),

  employeeId: Yup.number()
    .required('Employee is required')
    .positive('Employee must be a positive number')
    .integer('Employee must be an integer'),

  uniformSizeId: Yup.number()
    .required('Uniform Size is required')
    .positive('Uniform Size must be a positive number')
    .integer('Uniform Size must be an integer'),

  uniformTypeId: Yup.number()
    .required('Uniform Type is required')
    .positive('Uniform Type must be a positive number')
    .integer('Uniform Type must be an integer'),

  qrCode: Yup.string()
    .required('QR Code is required')
    .trim('QR Code cannot include leading or trailing spaces'),

  quantity: Yup.number()
    .transform((value, originalValue) =>
      originalValue === '' ? undefined : value
    ) // Transform empty strings to undefined
    .required('Quantity is required')
    .positive('Quantity must be a positive number')
    .integer('Quantity must be an integer'),
});
export const enquiryValidationSchema = Yup.object().shape({
  tenderName: Yup.string().required('Tender Name is required'),
  clientId: Yup.number()
    .positive('Client must be greater than 0')
    .typeError('Client must be a number')
    .required('Client is required'),

  receivedDate: Yup.date()
    .required('Receive Date is required')
    .typeError('Invalid date format')
    .test('invalidDate', 'Invalid Date', (value) => {
      return value instanceof Date && !isNaN(value.getTime());
    }),
  // subMissionDate: Yup.date().required('Submission Date is required'),
  emirateIds: Yup.array().required('Emirate is required'),
  tenderProjectTypeId: Yup.number()
    .positive('Tender Project Type must be greater than 0')
    .required('Tender Project Type is required'),
  expressOfInterest: Yup.boolean().required(
    'Express of Interest field is required'
  ),
  buyTenderDocument: Yup.boolean().notRequired(),
  cost: Yup.number()
    .transform((value, originalValue) =>
      originalValue === '' ? undefined : value
    )
    .when('buyTenderDocument', {
      is: true, // when buyTenderDocument is true
      then: (schema) =>
        schema
          .required('Cost is required when Buy Tender Document is selected')
          .min(0, 'Cost limit cannot be negative'),
      otherwise: (schema) => schema.notRequired(),
    }),

  tenderValue: Yup.string()
    .min(0, 'Tender Value must be at least 0')
    .max(16, 'Tender Value must be less than 16 characters')
    .nullable(),
  // tenderStatusId: Yup.number()
  //   .positive('Tender Status must be greater than 0')
  //   .required('Tender Status is required'),
  tenderEnquiryTypeId: Yup.number()
    .positive('Mode of Submission must be greater than 0')
    .required('Mode of Submission is required'),

  // TODO
  // requestForEstimate: Yup.string().required('Request For Estimate is required'),
  companyId: Yup.number()
    .required('Quoted By Company is required')
    .typeError('Quoted By Company must be a number'),
  bidBond: Yup.boolean(),
});
export const enquiryEditValidationSchema = Yup.object().shape({
  tenderName: Yup.string().required('Tender Name is required'),
  clientId: Yup.number()
    .positive('Client must be greater than 0')
    .typeError('Client must be a number')
    .required('Client is required'),

  receivedDate: Yup.date()
    .required('Receive Date is required')
    .typeError('Invalid date format')
    .test('invalidDate', 'Invalid Date', (value) => {
      return value instanceof Date && !isNaN(value.getTime());
    }),
  subMissionDate: Yup.date().required('Submission Date is required')
    .typeError('Invalid date format'),
  emirateIds: Yup.array().required('Emirate is required'),
  tenderProjectTypeId: Yup.number()
    .positive('Tender Project Type must be greater than 0')
    .required('Tender Project Type is required'),
  expressOfInterest: Yup.boolean().required(
    'Express of Interest field is required'
  ),
  buyTenderDocument: Yup.boolean().notRequired(),
  cost: Yup.number()
    .transform((value, originalValue) =>
      originalValue === '' ? undefined : value
    )
    .when('buyTenderDocument', {
      is: true, // when buyTenderDocument is true
      then: (schema) =>
        schema
          .required('Cost is required when Buy Tender Document is selected')
          .min(0, 'Cost limit cannot be negative'),
      otherwise: (schema) => schema.notRequired(),
    }),

  tenderValue: Yup.string()
    .min(0, 'Tender Value must be at least 0')
    .max(16, 'Tender Value must be less than 16 characters')
    .nullable(),
  // tenderStatusId: Yup.number()
  //   .positive('Tender Status must be greater than 0')
  //   .required('Tender Status is required'),
  tenderEnquiryTypeId: Yup.number()
    .positive('Mode of Submission must be greater than 0')
    .required('Mode of Submission is required'),

  // TODO
  // requestForEstimate: Yup.string().required('Request For Estimate is required'),
  companyId: Yup.number()
    .required('Quoted By Company is required')
    .typeError('Quoted By Company must be a number'),
  bidBond: Yup.boolean(),
});
export const AddInspectionReportSchema = Yup.object({
  companyId: Yup.number()
    .nullable()
    .required('This field is required')
    .positive('Value must be positive')
    .integer('Value must be an integer'),
  clientId: Yup.number()
    .nullable()
    .required('This field is required')
    .positive('Value must be positive')
    .integer('Value must be an integer'),
  projectId: Yup.number()
    .nullable()
    .required('This field is required')
    .positive('Value must be positive')
    .integer('Value must be an integer'),
  contractId: Yup.number()
    .nullable()
    .required('This field is required')
    .positive('Value must be positive')
    .integer('Value must be an integer'),
  locationId: Yup.number()
    .nullable()
    .required('This field is required')
    .positive('Value must be positive')
    .integer('Value must be an integer'),
  buildingId: Yup.number()
    .nullable()
    .required('This field is required')
    .positive('Value must be positive')
    .integer('Value must be an integer'),

  monthId: Yup.number()
    .nullable()
    .required('This field is required')
    .positive('Value must be positive')
    .integer('Value must be an integer'),
  signatureImage: Yup.number()
    .nullable()
    .required('This field is required')
    .positive('Value must be positive')
    .integer('Value must be an integer'),
  referenceNumber: Yup.string().required('This field is required'),
  occupancy: Yup.string().required('This field is required'),
  inspectedArea: Yup.string().required('This field is required'),
});

export const ViewEnquiryValidationSchema = Yup.object({
  tenderFollowupTypeId: Yup.number().required('This field is required'),
  contactMail: Yup.string()
    .email('Invalid email format')
    .required('This field is required'),
  contactPerson: Yup.string().required('This field is required'),
  contactNumber: Yup.string()
    .required('This field is required')
    .min(8, 'Contact Number cannot be less than 8')
    .max(13, 'Contact Number cannot exceed 13'),
  contactModeId: Yup.number().required('This field is required'),
  comments: Yup.string().required('This field is required'),
  assignTo: Yup.number().required('This field is required'),
});
export const responsiblePersonSchema = Yup.object({
  responsiblePersonId: Yup.number().required('This field is required'),
});

export const documentUploadValidationSchema = Yup.object({
  files: Yup.array().of(
    Yup.object().shape({
      documentName: Yup.string()
        .required('This field is required')
        .typeError('This field is required'),
      // TODO
      // fileId: Yup.number()
      //   .required('This field is required')
      //   .typeError('This field is required'),
    })
  ),
});

// export const documentManagementValidationSchema = Yup.object({
//   files: Yup.array().of(
//     Yup.object().shape({
//       documentTypeId: Yup.number()
//         .required('This field is required')
//         .typeError('Document Type must be a number'),
//       documentName: Yup.string()
//         .required('This field is required')
//         .typeError('Document Name must be a number'),
//     })
//   ),
// });

export const documentManagementValidationSchema = Yup.object().shape({
  files: Yup.array()
    .of(
      Yup.object().shape({
        documentTypeId: Yup.number()
          .required('Document Type is required')
          .typeError('Document Type is required'),
        companyId: Yup.number()
          .required('Company is required')
          .typeError('Company is required'),
        documentName: Yup.string().required('Document is required'),
        remarks: Yup.string()
          .max(256, 'Remarks should not exceed 256 characters')
          .nullable(), // Optional field
      })
    )
    .min(1, 'At least one document is required') // Ensures the array has at least one item
    .required('Files field is required'), // Ensures the `files` array itself is not null or undefined
});

export const FTItemsSchema = Yup.object({
  amount: Yup.number()
    .required('Amount is required')
    .transform((value, originalValue) => (originalValue === '' ? null : value))
    .typeError('Amount must be a number')
    .min(0, 'Amount cannot be less than 0'),
  vat: Yup.number()
    .transform((value, originalValue) => (originalValue === '' ? null : value))
    .nullable()
    .typeError('Vat must be a number')
    .min(0, 'Vat cannot be less than 0')
    .max(100, 'Vat cannot exceed 100'),
});

export const VendorDocumentSchema = Yup.object({
  vendorDocuments: Yup.array().of(
    Yup.object().shape({
      invoiceNumber: Yup.string()
        .max(32, 'Invoice Number must be at most 32 characters')
        .test(
          'is-valid',
          'Invoice Number must be at most 32 characters',
          (value) => value?.trim()?.length <= 32
        ),

      localPurchaseOrderNo: Yup.string()
        .max(128, 'Local Purchase Order Number must be at most 128 characters')
        .test(
          'is-valid',
          'Local Purchase Order Number must be at most 128 characters',
          (value) => value?.trim()?.length <= 128
        ),
      documentTypeId: Yup.string().required('Document Type ID is required'),
      supplierId: Yup.string().required('Supplier ID is required'),
    })
  ),
});

export const VendorInvoiceItemsSchema = Yup.object({
  vendorId: Yup.number().required('Vendor is required'),

  amountPayable: Yup.number()
    .required('Amount Payable is required')
    .typeError('Amount Payable must be a number'),
  localPurchaseOrderId: Yup.string().required(
    'Local Purchase Order is required'
  ),
});

export const SalesInvoiceItemSchema = Yup.object({
  description: Yup.string().required('Description is required'),
  tax: Yup.number()
    .nullable()
    .transform((value, originalValue) => (originalValue === '' ? null : value))
    .min(0, 'Negotiation Percent cannot be negative')
    .max(100, 'Negotiation Percent cannot exceed 100'),
  vat: Yup.number()
    .nullable()
    .transform((value, originalValue) => (originalValue === '' ? null : value))
    .min(0, 'Discount Percent cannot be negative')
    .max(100, 'Discount Percent cannot exceed 100'),
});
export const InternalInvoiceItemSchema = Yup.object().shape({
  divisionId: Yup.number()
    .required('Division is required')
    .min(1, 'Division must be greater than 0'),
  tax: Yup.number()
    .nullable()
    .transform((value, originalValue) => (originalValue === '' ? null : value))
    .min(0, 'Negotiation Percent cannot be negative')
    .max(100, 'Negotiation Percent cannot exceed 100'),
  vat: Yup.number()
    .nullable()
    .transform((value, originalValue) => (originalValue === '' ? null : value))
    .min(0, 'Discount Percent cannot be negative')
    .max(100, 'Discount Percent cannot exceed 100'),
});

export const journalItemsSchema = Yup.object().shape({
  contractId: Yup.number()
    .required('Contract ID is required')
    .min(1, 'Contract ID must be greater than 0'),
});

export const FinancialReportsGenerateSchema = Yup.object().shape({
  // companyId: Yup.number()
  //   .required('Company is required')
  //   .min(1, 'Company must be greater than 0')
  //   .typeError('Company must be a number'),
  // clientId: Yup.number()
  //   .required('Client is required')
  //   .min(1, 'Client must be greater than 0')
  //   .typeError('Client must be a number'),
  // projectId: Yup.number()
  //   .required('Project is required')
  //   .min(1, 'Project must be greater than 0')
  //   .typeError('Project must be a number'),
  contractId: Yup.number()
    .required('Contract is required')
    .min(1, 'Contract must be greater than 0')
    .typeError('Contract must be a number'),
});
export const createCertificateSchema = Yup.object().shape({
  createCertificateCatalog: Yup.object().shape({
    certificateTypeId: Yup.string().required('Certificate Type is required'),
    certificationId: Yup.string().required('Certificate is required'),
    certificateStatusId: Yup.string().required(
      'Certificate Status is required'
    ),
    issuedDate: Yup.date()
      .nullable()
      .optional()
      .required('Issued Date is required'),
    expiryDate: Yup.date().when('issuedDate', (issuedDate, schema) => {
      return (
        issuedDate &&
        schema.min(issuedDate, 'Expiry Date must be Greater Than Issued Date')
      );
    }),
  }),
  expenseTypeId: Yup.string().required('Expense Type is required'),
  paymentDate: Yup.date().required('Payment Date is required'),
  costCenterId: Yup.string().required('Cost Center is required'),
});
export const EditCertificationSchema = Yup.object().shape({
  updateCertificateCatalog: Yup.object().shape({
    certificateTypeId: Yup.string().required('Certificate Type is required'),
    certificationId: Yup.string().required('Certificate is required'),
    certificateStatusId: Yup.string().required(
      'Certificate Status is required'
    ),
    issuedDate: Yup.date()
      .nullable()
      .optional()
      .required('Issued Date is required'),
    expiryDate: Yup.date()
      .nullable()
      .optional()
      .when('issuedDate', (issuedDate, schema) => {
        return (
          issuedDate &&
          schema.min(issuedDate, 'Expiry Date must be Greater Than Issued Date')
        );
      })
      .nullable(),
  }),
  expenseTypeId: Yup.string().required('Expense Type is required'),
  paymentDate: Yup.date().required('Payment Date is required'),
  costCenterId: Yup.string().required('Cost Center is required'),
});
export const TicketValidationSchema = Yup.object({
  requestNo: Yup.string().required('Request No is required'),
  requesterName: Yup.string().required('Caller Name is required'),
  requesterEmail: Yup.string()
    .email('Invalid email format')
    .required('Caller Email is required'),
  requesterPhone: Yup.string()
    .required('Caller Phone is required')
    .min(0, 'Caller Phone must be at least 0')
    .max(16, 'Caller Phone must be less than 16 characters'),
});
export const TicketNoteValidationSchema = Yup.object({
  ticketNoteStatusId: Yup.string()
    .required('Ticket Note Status is required')
    .min(1, 'Ticket Note Status must be greater than 0')
    .typeError('Ticket Note Status must be a number'),
});

export const AssetpurchasedetailsValidation = Yup.object({
  assetPurchaseDetailName: Yup.string().required(
    'Asset Purchase Name is required'
  ),
  supplierId: Yup.string()
    .required('Supplier is required')
    .min(1, 'Supplier must be greater than 0'),
  contactDetails: Yup.string().required('Contact Details is required'),
  price: Yup.number()
    .required('Price is required')
    .typeError('Price must be a number'),
  cost: Yup.number()
    .required('Contact Details is required')
    .typeError('Cost must be a number'),
  purchaseDate: Yup.date()
    .required('Purchase Date is required')
    .nullable()
    .typeError('Invalid date format')
    .test('invalidDate', 'Invalid Date', (value) => {
      return value instanceof Date && !isNaN(value.getTime());
    }),
});
export const AssetInstallationDetailsValidation = Yup.object({
  assetInstallationDetailName: Yup.string().required(
    'Installation Name is required'
  ),
  installationDate: Yup.date()
    .required('Installation Date is required')
    .nullable()
    .typeError('Invalid date format')
    .test('invalidDate', 'Invalid Date', (value) => {
      return value instanceof Date && !isNaN(value.getTime());
    }),
});
export const AssetmaintenancesValidation = Yup.object({
  creationDate: Yup.date()
    .required('Creation Date is required')
    .nullable()
    .typeError('Invalid date format')
    .max(Yup.ref('resolvedDate'), 'Creation Date must be before End Date')
    .test('invalidDate', 'Invalid Date', (value) => {
      return value instanceof Date && !isNaN(value.getTime());
    }),
  callTypeId: Yup.string()
    .required('Call Type is required')
    .min(1, 'Call Type must be greater than 0'),
  priorityId: Yup.string()
    .required('Priority is required')
    .min(1, 'Priority must be greater than 0'),
  frequencyId: Yup.string()
    .required('Frequency is required')
    .min(1, 'Frequency must be greater than 0'),
  materialRequisitionId: Yup.string()
    .required('Material Requisition is required')
    .min(1, 'Material Requisition must be greater than 0'),
  technicianId: Yup.string()
    .required('Technician is required')
    .min(1, 'Technician must be greater than 0'),
  costOfMaintenance: Yup.number()
    .required('Cost of Maintenance is required')
    .typeError('Cost of Maintenance must be a number'),
});
export const AssetOperationalDetailsValidation = Yup.object({
  totalOperationHours: Yup.string().required(
    'Total Operation Hours is required'
  ),
  startTime: Yup.string().required('Start Time is required'),
  stopTime: Yup.string().required('Stop Time is required'),
  date: Yup.date()
    .required('Date is required')
    .nullable()
    .typeError('Invalid date format')
    .test('invalidDate', 'Invalid Date', (value) => {
      return value instanceof Date && !isNaN(value.getTime());
    }),
});
export const AssetReadingsValidation = Yup.object({
  measurementSection: Yup.string().required('Measurement Section is required'),
  presetRangeValue: Yup.string().required('Preset Range is required'),
  currentReadings: Yup.string().required('Current Readings is required'),
  date: Yup.date()
    .required('Date is required')
    .nullable()
    .typeError('Invalid date format')
    .test('invalidDate', 'Invalid Date', (value) => {
      return value instanceof Date && !isNaN(value.getTime());
    }),
});
export const AssetModificationDetailsValidation = Yup.object({
  modifiedBy: Yup.string()
    .required('Modified By is required')
    .min(1, 'Modified By must be greater than 0'),
  modificationDueTo: Yup.string().required('Modification Due To is required'),
  modificationDate: Yup.date()
    .required('Modification Date is required')
    .nullable()
    .typeError('Invalid date format')
    .test('invalidDate', 'Invalid Date', (value) => {
      return value instanceof Date && !isNaN(value.getTime());
    }),
});

export const AssetDisposalsValidation = Yup.object({
  disposalReasonId: Yup.string()
    .required('Disposal Reason is required')
    .min(1, 'Disposal Reason  must be greater than 0'),
  disposalMethodId: Yup.string()
    .required('Disposal Method is required')
    .min(1, 'Disposal Method  must be greater than 0'),
  disposedBy: Yup.string()
    .required('Disposed By is required')
    .min(1, 'Disposed By must be greater than 0'),
  assetDisposalName: Yup.string().required('Disposal Name is required'),
  disposalAmount: Yup.number()
    .required('Disposal Amount is required')
    .typeError('Disposal Amount must be a number'),
  disposalDate: Yup.date()
    .required('Disposal Date is required')
    .nullable()
    .typeError('Invalid date format')
    .test('invalidDate', 'Invalid Date', (value) => {
      return value instanceof Date && !isNaN(value.getTime());
    }),
});

export const AssetlifecyclecostsValidation = Yup.object({
  disposalDate: Yup.date()
    .required('Disposal Date is required')
    .nullable()
    .typeError('Invalid date format')
    .test('invalidDate', 'Invalid Date', (value) => {
      return value instanceof Date && !isNaN(value.getTime());
    }),
  acquisitionCosts: Yup.number()
    .required('Acquisition Costs is required')
    .typeError('Acquisition Costs must be a number'),
  operatingCosts: Yup.number()
    .required('Operating Costs is required')
    .typeError('Operating Costs must be a number'),
  maintenanceCosts: Yup.number()
    .required('Maintenance Costs is required')
    .typeError('Maintenance Costs must be a number'),
  downtimeCosts: Yup.number()
    .required('Downtime Costs is required')
    .typeError('Downtime Costs must be a number'),
  disposalCosts: Yup.number()
    .required('Disposal Costs is required')
    .typeError('Disposal Costs must be a number'),
  totalCosts: Yup.number()
    .required('Total Costs is required')
    .typeError('Total Costs must be a number'),
});

export const AssetInspectionDetailsValidation = Yup.object({
  inspectorId: Yup.string()
    .required('Inspector is required')
    .min(1, 'Disposal Reason  must be greater than 0')
    .typeError('Disposal Reason  must be a number'),

  previousInspectionDate: Yup.date()
    .required('Previous Inspection Date is required')
    .nullable()
    .typeError('Invalid date format')
    .max(
      Yup.ref('nextInspectionDate'),
      'Previous Inspection Date must be before End Date'
    )
    .test('invalidDate', 'Invalid Date', (value) => {
      return value instanceof Date && !isNaN(value.getTime());
    }),
  nextInspectionDate: Yup.date()
    .required('Next Inspection Date is required')
    .nullable()
    .typeError('Invalid date format')
    .min(
      Yup.ref('previousInspectionDate'),
      'Next Inspection Date must be after Start Date'
    )
    .test('invalidDate', 'Invalid Date', (value) => {
      return value instanceof Date && !isNaN(value.getTime());
    }),
});

export const AssetHistoryShema = Yup.object({
  assetName: Yup.string().required('Asset Name is required'),
  serialNumber: Yup.string().required('Serial Number is required'),
  isOwnAsset: Yup.string().required('Is Own Asset is required'),
});

export const shiftTimeCreateShema = Yup.object({
  companyId: Yup.number().required('Company is required'),
  shiftTimingName: Yup.string().required('Shift Timing Name is required'),
});

export const candidateChecklistSchema = Yup.object().shape({
  candidateId: Yup.string().required('Candidate Id is required'),
  visaTypeId: Yup.string().required('Visa Type is required'),
  contactNumber: Yup.string()
    .required('Contact Number is required')
    .max(16, 'Contact Number cannot exceed 16 characters'),
  mailId: Yup.string()
    .required('Mail is required')
    .max(64, 'Mail id cannot exceed 64 characters'),
  uae: Yup.string()
    .required('UAE is required')
    .max(64, 'UAE cannot exceed 64 characters'),
  // here the permitPlaceId is different
  permitPlaceId: Yup.string().required('Sponsor Name is required'),
  permitTypeId: Yup.string().required('Permit Type is required'),
  visaStatus: Yup.string().required('Visa Status is required'),
});

export const candidateConfirmationSchema = Yup.object().shape({
  designationId: Yup.number()
    .required('Designation is required')
    .min(1, 'Designation must be greater than 0'),
  articleTenClause: Yup.string()
    .required('Article Ten Clause is required')
    .max(256, 'Article Ten Clause cannot exceed 256 characters'),
  probationPeriod: Yup.string()
    .required('Probation Period is required')
    .max(128, 'Probation Period cannot exceed 128 characters'),
  condition: Yup.string()
    .required('Condition is required')
    .max(256, 'Condition cannot exceed 256 characters'),
  visaProfession: Yup.string()
    .required('Visa Profession is required')
    .max(256, 'Visa Profession cannot exceed 256 characters'),
  probationPeriodNumber: Yup.string()
    .required('Probation Period Number is required')
    .max(128, 'Probation Period Number cannot exceed 128 characters'),
});

export const CandidateDetailSchema = Yup.object().shape({
  firstName: Yup.string()
    .required('First Name is required')
    .max(128, 'First Name cannot exceed 128 characters'),

  lastName: Yup.string()
    .required('Last Name is required')
    .max(128, 'Last Name cannot exceed 128 characters'),

  positionId: Yup.number()
    .required('Position ID is required')
    .min(1, 'Position ID must be greater than 0'),

  qualificationId: Yup.number()
    .required('Qualification ID is required')
    .min(1, 'Qualification ID must be greater than 0'),

  nationalityId: Yup.number()
    .required('Nationality ID is required')
    .min(1, 'Nationality ID must be greater than 0'),

  maritalStatus: Yup.string().required('Marital Status is required'),

  emailId: Yup.string()
    .required('Email ID is required')
    .email('Invalid email format')
    .max(256, 'Email ID cannot exceed 256 characters'),

  uaeMobileNumber: Yup.string()
    .required('UAE Mobile Number is required')
    .max(16, 'UAE Mobile Number cannot exceed 16 characters'),

  homeCountryContactNumber: Yup.string()
    .required('Home Country Contact Number is required')
    .max(16, 'Home Country Contact Number cannot exceed 16 characters'),

  expectedDateOfJoining: Yup.date().required(
    'Expected Date of Joining is required'
  ),

  languageId: Yup.number()
    .required('Language ID is required')
    .min(1, 'Language ID must be greater than 0'),

  experienceTypeId: Yup.number()
    .required('Experience Type ID is required')
    .min(1, 'Experience Type ID must be greater than 0'),

  certificateStatusId: Yup.number()
    .required('Certificate Status ID is required')
    .min(1, 'Certificate Status ID must be greater than 0'),

  visaStatusId: Yup.number()
    .required('Visa Status ID is required')
    .min(1, 'Visa Status ID must be greater than 0'),
});

export const workerConfigurationSchema = Yup.object().shape({
  staffWorkingHours: Yup.number()
    .min(0, 'Must be at least 0')
    .required('This field is required')
    .typeError('This field must be a number'),
  workmanWorkingHours: Yup.number()
    .min(0, 'Must be at least 0')
    .required('This field is required')
    .typeError('This field must be a number'),
  workmanNonOTWorkingHours: Yup.number()
    .min(0, 'Must be at least 0')
    .required('This field is required')
    .typeError('This field must be a number'),
  trainingHoursPerYear: Yup.number()
    .min(0, 'Must be at least 0')
    .required('This field is required')
    .typeError('This field must be a number'),
  casualLeavePerYear: Yup.number()
    .min(0, 'Must be at least 0')
    .required('This field is required')
    .typeError('This field must be a number'),
  medicalLeavePerYear: Yup.number()
    .min(0, 'Must be at least 0')
    .required('This field is required')
    .typeError('This field must be a number'),
  medicalLeaveFullPayDays: Yup.number()
    .min(0, 'Must be at least 0')
    .required('This field is required')
    .typeError('This field must be a number'),
  medicalLeaveHalfPayDays: Yup.number()
    .min(0, 'Must be at least 0')
    .required('This field is required')
    .typeError('This field must be a number'),
  medicalLeaveNoPayDays: Yup.number()
    .min(0, 'Must be at least 0')
    .required('This field is required')
    .typeError('This field must be a number'),
  otHoursWorkingDay: Yup.number()
    .min(0, 'Must be at least 0')
    .required('This field is required')
    .typeError('This field must be a number'),
  otHoursHoliday: Yup.number()
    .min(0, 'Must be at least 0')
    .required('This field is required')
    .typeError('This field must be a number'),
  otHoursPublicHoliday: Yup.number()
    .min(0, 'Must be at least 0')
    .required('This field is required')
    .typeError('This field must be a number'),
  fourWeekPerMonthHolidayOT: Yup.number()
    .min(0, 'Must be at least 0')
    .required('This field is required')
    .typeError('This field must be a number'),
  fiveWeekPerMonthHolidayOT: Yup.number()
    .min(0, 'Must be at least 0')
    .required('This field is required')
    .typeError('This field must be a number'),
  fourWeekPerMonthPublicHolidayOT: Yup.number()
    .min(0, 'Must be at least 0')
    .required('This field is required')
    .typeError('This field must be a number'),
  fiveWeekPerMonthPublicHolidayOT: Yup.number()
    .min(0, 'Must be at least 0')
    .required('This field is required')
    .typeError('This field must be a number'),
  maternityLeaveFullPayDay: Yup.number()
    .min(0, 'Must be at least 0')
    .required('This field is required')
    .typeError('This field must be a number'),
  minimumMaternityLeaveHalfPayDay: Yup.number()
    .min(0, 'Must be at least 0')
    .required('This field is required')
    .typeError('This field must be a number'),
  maximumMaternityLeaveHalfPayDay: Yup.number()
    .min(0, 'Must be at least 0')
    .required('This field is required'),
  maternityDailyPermissionPeriod: Yup.number()
    .min(0, 'Must be at least 0')
    .required('This field is required')
    .typeError('This field must be a number'),
  maternityDailyPermissionHours: Yup.number()
    .min(0, 'Must be at least 0')
    .required('This field is required')
    .typeError('This field must be a number'),
  paternityLeaveDay: Yup.number()
    .min(0, 'Must be at least 0')
    .required('This field is required')
    .typeError('This field must be a number'),
  minimumGratuityYear: Yup.number()
    .min(0, 'Must be at least 0')
    .required('This field is required')
    .typeError('This field must be a number'),
  maximumGratuityYear: Yup.number()
    .min(0, 'Must be at least 0')
    .required('This field is required')
    .typeError('This field must be a number'),
  minimumGratuityDaysFromBasic: Yup.number()
    .min(0, 'Must be at least 0')
    .required('This field is required')
    .typeError('This field must be a number'),
  maximumGratuityDaysFromBasic: Yup.number()
    .min(0, 'Must be at least 0')
    .required('This field is required')
    .typeError('This field must be a number'),
});

export const EventBudgetSchema = Yup.object().shape({
  itemId: Yup.string().required('Item is required'),
  quantity: Yup.number()
    .typeError('Quantity must be a number')
    .required('quantity is required'),
  unitOfMeasurementId: Yup.string().required('UOM is required'),
  unitPrice: Yup.number()
    .typeError('Unit Price must be a number')
    .required('Unit Price is required'),
  itemTotal: Yup.number()
    .typeError('Item Total must be a number')
    .required('Item Total is required'),
});

export const CompanyMedicalInsurancePolicySchema = Yup.object().shape({
  companyMedicalInsuranceStatusId: Yup.string().required(
    'Company Medical Insurance Status is required'
  ),
  companyInsurancePolicyCategoryId: Yup.string().required(
    'Company Insurance Policy category is required'
  ),
  companyMedicalInsuranceAgeBandId: Yup.string().required(
    'Company Medical Insurance Age Band is required'
  ),
  regionId: Yup.string().required('Region is required'),
  companyMedicalInsuranceDependencyId: Yup.string().required(
    'Company Medical Insurance Dependency is required'
  ),
  companyMedicalInsuranceTypeId: Yup.string().required(
    'Company Medical Insurance Type is required'
  ),
  startDate: Yup.date()
    .required('Start Date is required')
    .typeError('Start Date must be a valid date'), // Ensures proper error message if invalid date
  endDate: Yup.date()
    .required('End Date is required')
    .typeError('End Date must be a valid date')
    .min(Yup.ref('startDate'), 'Last Date must be the same or after From Date'),
  employeeId: Yup.string().required('Employee is required'),
  principal: Yup.string().required('Principal is required'),
  maternity: Yup.string().required('Maternity is required'),
  vat: Yup.string().required('VAT is required'),
  total: Yup.string().required('Total is required'),
});

export const MIPPremiumCalculatorSchema = Yup.object().shape({
  companyMedicalInsuranceAgeBandId: Yup.string().required(
    'Company Medical Insurance Age Band is required'
  ),
  companyMedicalInsuranceDependencyId: Yup.string().required(
    'Company Medical Insurance Dependency is required'
  ),
  regionId: Yup.string().required('Region is required'),
  createMedicalInsurancePolicyCategories: Yup.array().of(
    Yup.object().shape({
      gradeId: Yup.string().required('Grade is required'),
      male: Yup.number()
        .required('Male is required')
        .typeError('This field must be a number'),
      female: Yup.number()
        .required('Female is required')
        .typeError('This field must be a number'),
    })
  ),
});

export const EditMIPPremiumCalculatorSchema = Yup.object().shape({
  companyMedicalInsuranceAgeBandId: Yup.string().required(
    'Company Medical Insurance Age Band is required'
  ),
  companyMedicalInsuranceDependencyId: Yup.string().required(
    'Company Medical Insurance Dependency is required'
  ),
  regionId: Yup.string().required('Region is required'),
  principal: Yup.number()
    .required('Principal is required')
    .typeError('This field must be a number'),
  maternity: Yup.number()
    .required('Maternity is required')
    .typeError('This field must be a number'),
  createMedicalInsurancePolicyCategories: Yup.array().of(
    Yup.object().shape({
      gradeId: Yup.string().required('Grade is required'),
      male: Yup.number()
        .required('Male is required')
        .typeError('This field must be a number'),
      female: Yup.number()
        .required('Female is required')
        .typeError('This field must be a number'),
    })
  ),
});

export const employeeWiseMedicalInsuranceSchema = Yup.object().shape({
  employeeId: Yup.string().required('Employee is required'),
  regionId: Yup.string().required('Region is required'),
  costCenterId: Yup.string().required('Cost Center is required'),
  companyMedicalInsuranceAgeBandId: Yup.string().required(
    'Company Medical Insurance Age Band is required'
  ),
  startDate: Yup.date()
    .required('Start Date is required')
    .typeError('Start Date must be a valid date'),
  endDate: Yup.date()
    .required('End Date is required')
    .typeError('End Date must be a valid date')
    .min(Yup.ref('startDate'), 'Last Date must be the same or after From Date'),
  premiumAmount: Yup.number()
    .required('Premium Amount is required')
    .typeError('This field must be a number'),
});
export const companyinsurancepolicycomparisonsSchema = Yup.object().shape({
  companyInsurancePolicyId: Yup.string()
    .required('Company Insurance Policy ID is required')
    .min(1, 'Company Insurance Policy ID must be greater than 0'),
  companyInsurancePolicyTypeOfProductId: Yup.string()
    .required('Type of Product ID is required')
    .min(1, 'Type of Product ID must be greater than 0'),
  startDate: Yup.date()
    .nullable()
    .max(Yup.ref('endDate'), 'Start Date must be before End Date'),
  endDate: Yup.date()
    .nullable()
    .min(Yup.ref('startDate'), 'End Date must be after Start Date'),
  firstInsurer: Yup.string()
    .required('First Insurer is required')
    .min(1, 'First Insurer must be greater than 0'),
  secondInsurer: Yup.string()
    .required('Second Insurer is required')
    .min(0, 'Second Insurer must be greater than or equal to 0'),
  thirdInsurer: Yup.string()
    .required('Third Insurer is required')
    .min(0, 'Third Insurer ID must be greater than or equal to 0'),
});
export const employeeAttendenceSchema = (isNew) =>
  Yup.object({
    employeeId: isNew
      ? Yup.string().required('Employee is required')
      : Yup.string(),
    date: isNew
      ? Yup.date().required('Attendence Date is required')
      : Yup.date().nullable(),
    startTime: Yup.string().required('Start Time is required'),
    endTime: isNew
      ? Yup.string()
        .required('End Time is required')
        .test(
          'is-greater',
          'End Time must be greater than Start Time',
          function (value) {
            const { startTime } = this.parent; // Access the startTime field
            if (!startTime || !value) return true; // Skip validation if either is missing
            return (
              new Date(`1970-01-01T${value}`) >
              new Date(`1970-01-01T${startTime}`)
            );
          }
        )
      : Yup.string().required('End Time is required'),
  });

export const EmployeevalidationSchema = Yup.object().shape({
  firstName: Yup.string().required('Given Name is required'),
  lastName: Yup.string().nullable(),
  dob: Yup.string().required('Date of birth is required'),
  gender: Yup.number().required('Gender is required'),
  mobileNumber: Yup.string().nullable()
    .max(15, 'Mobile number must be less than 15 characters'),
  personalEmailId: Yup.string()
    .nullable()
    .email('Invalid email format'),
  workStatusId: Yup.number().required('Work Status is required'),
  employeeCode: Yup.string().required('Employee Code is required'),
});

export const cashAccountShema = Yup.object({
  cashAccountPaymentModeTypeId: Yup.string().required(
    'Payment Mode is required'
  ),
  bankName: Yup.string().required('Bank Name is required'),
  balance: Yup.number()
    .min(0, 'Must be at least 0')
    .required('This field is required')
    .typeError('This field must be a number'),
  cashAccountStatusId: Yup.string().required('Cash Account Status is required'),
  notificationThreshold: Yup.number()
    .required('Notification Threshold is required')
    .typeError('This field must be a number'),
  lastFundedDate: Yup.date()
    .required('Last Funded Date is required')
    .typeError('Invalid date format')
    .test('invalidDate', 'Invalid Date', (value) => {
      return value instanceof Date && !isNaN(value.getTime());
    }),
});

export const vehiclemonthlyupdatesSchema = Yup.object().shape({
  divisionId: Yup.number()
    .required('Division ID is required')
    .min(1, 'Division ID must be greater than 0'),
  vehicleMethod: Yup.boolean().required('Vehicle Method is required'),
  ownVehicleId: Yup.number()
    .nullable()
    .typeError('Own Vehicle ID must be a number')
    .test(
      'validate-ownVehicleId',
      'Own Vehicle ID is required when Vehicle Method is Yes',
      function (value) {
        const { vehicleMethod } = this.parent; // Access sibling fields
        if (vehicleMethod && (!value || value < 1)) {
          return false;
        }
        return true;
      }
    ),
  hiredVehicleId: Yup.number()
    .nullable()
    .typeError('Hired Vehicle ID must be a number')
    .test(
      'validate-hiredVehicleId',
      'Hired Vehicle ID is required when Vehicle Method is No',
      function (value) {
        const { vehicleMethod } = this.parent; // Access sibling fields
        if (!vehicleMethod && (!value || value < 1)) {
          return false;
        }
        return true;
      }
    ),
  month: Yup.number()
    .required('Month is required')
    .min(1, 'Month must be between 1 and 12')
    .max(12, 'Month must be between 1 and 12'),
  year: Yup.number()
    .required('Year is required')
    .min(1900, 'Year must be greater than 1900'),
  locationId: Yup.number()
    .required('Location ID is required')
    .min(1, 'Location ID must be greater than 0'),
  projectId: Yup.number()
    .required('Project ID is required')
    .min(1, 'Project ID must be greater than 0'),
  siteId: Yup.number()
    .required('Site ID is required')
    .min(1, 'Site ID must be greater than 0'),
  currentKilometer: Yup.number()
    .required('Current Kilometer is required')
    .typeError('Current Kilometer must be a number')
    .min(0, 'Current Kilometer must be greater than or equal to 0'),
  pickUpTime: Yup.string()
    .required('Pick Up Time is required')
    .matches(
      /^([01]\d|2[0-3]):([0-5]\d)(:[0-5]\d)?$/,
      'Pick Up Time must be in the format HH:mm or HH:mm:ss'
    ),
  dropoffTime: Yup.string()
    .required('Dropoff Time is required')
    .matches(
      /^([01]\d|2[0-3]):([0-5]\d)(:[0-5]\d)?$/,
      'Dropoff Time must be in the format HH:mm or HH:mm:ss'
    )
    .test(
      'is-after-pickup',
      'Dropoff Time must be later than Pick Up Time',
      function (value) {
        const { pickUpTime } = this.parent;
        if (!pickUpTime || !value) {
          return true; // Skip validation if either time is missing (handled by required).
        }
        return (
          new Date(`1970-01-01T${value}`) > new Date(`1970-01-01T${pickUpTime}`)
        );
      }
    ),
  remarks: Yup.string().optional(),
  transporterName: Yup.string()
    .required('Transporter Name is required')
    .max(100, 'Transporter Name must not exceed 100 characters'),
  contractStartingPeriod: Yup.date()
    .required('Contract Starting Period is required')
    .typeError('Invalid date format')
    .max(
      Yup.ref('contractEndingPeriod'),
      'Contract Starting Period must be before the ending period'
    ),

  contractEndingPeriod: Yup.date()
    .required('Contract Ending Period is required')
    .typeError('Invalid date format')
    .min(
      Yup.ref('contractStartingPeriod'),
      'Contract Ending Period must be after the starting period'
    ),
  rentAmount: Yup.number()
    .required('Rent Amount is required')
    .typeError('Rent Amount must be a number')
    .min(0, 'Rent Amount must be greater than or equal to 0'),
  contactPersonId: Yup.number()
    .required('Contact Person ID is required')
    .min(1, 'Contact Person ID must be greater than 0'),
});
export const eventCalculationSchema = Yup.object().shape({
  eventCalendarName: Yup.string()
    .typeError('Event name must be a string')
    .required('Event name is required'),

  preparedBy: Yup.number().required('Prepared By is required'),
});

export const transactionValidationSchema = Yup.object().shape({
  employeeId: Yup.number()
    .required('Employee is required')
    .typeError('Employee is required'),
});
export const EidValidationSchema = Yup.object().shape({
  employeeId: Yup.number().required('Employee is required'),
  sponsorId: Yup.number().required('Sponsor is required'),
});
export const leaveRequestSchemaEdit = Yup.object().shape({
  // employeeId: Yup.number()
  //   .transform((value, originalValue) => (originalValue === '' ? null : value))
  //   .required('Employee is required'),
  leaveRequestStatusId: Yup.number()
    .transform((value, originalValue) => (originalValue === '' ? null : value))
    .required('Leave Request Status is required'),
  leaveTypeId: Yup.number()
    .transform((value, originalValue) => (originalValue === '' ? null : value))
    .required('Leave Type is required'),
  fromDate: Yup.date()
    .required('From date is required')
    .typeError('Please enter a valid date')
    .min(dayjs().startOf('day').toDate(), 'From date cannot be in the past'),
  toDate: Yup.date()
    .required('To date is required')
    .typeError('Please enter a valid date')
    .min(
      Yup.ref('fromDate'),
      'To date must be greater than or equal to from date'
    ),
  dateOfTravel: Yup.date()
    .nullable()
    .typeError('Please enter a valid date')
    .test('within-2-days', 'Date of travel must be within 2 days before or after from date', function (value) {
      const { fromDate } = this.parent;
      if (!value || !fromDate) return true;
      const diff = Math.abs(dayjs(fromDate).diff(dayjs(value), 'day'));
      return diff <= 2; // Allow within 2 days before or after fromDate
    }),
  lastAnnualReturnLeaveDate: Yup.date()
    .nullable()
    .typeError('Please enter a valid date')
    .min(
      Yup.ref('toDate'),
      'Return date must be greater than or equal to to date'
    ),

  reason: Yup.string().required('Reason is required')
    .min(5, 'Reason must be at least 5 characters')
    .max(100, 'Reason must be at most 100 characters'),

  mailAddress: Yup.string()
    .nullable()
    .transform((value) => (value === '' ? null : value)) // Convert empty string to null
    .test('is-required', 'Email Address is required', function (value) {
      const { leaveTypeId } = this.parent;
      if ([1, 2, 3].includes(leaveTypeId)) {
        return value !== null && value !== undefined;
      }
      return true; // Skip validation if leaveTypeId is not 1, 2, or 3
    })
    .email('Invalid email format'),
  homeCountryContactNumber: Yup.number()
    .nullable()
    .transform((value, originalValue) => (originalValue === '' ? null : value)) // Convert empty string to null
    .typeError('Home Country Number must be a valid number') // Ensure only numbers
    .test(
      'valid-length',
      'Home Country Number must be between 8 and 16 digits',
      function (value) {
        if (value === null || value === undefined) return true; // Skip if null (optional field)
        const numberStr = String(value); // Convert to string
        return numberStr.length >= 8 && numberStr.length <= 16;
      }
    )
    .test(
      'is-required',
      'Home Country Number is required',
      function (value) {
        const { leaveTypeId } = this.parent;
        if ([1, 2, 3].includes(leaveTypeId)) {
          return value !== null && value !== undefined;
        }
        return true; // Skip validation if leaveTypeId is not 1, 2, or 3
      }
    ),

  uaeNumber: Yup.number()
    .nullable()
    .transform((value, originalValue) => (originalValue === '' ? null : value)) // Convert empty string to null
    .typeError('UAE Number must be a valid number') // Ensure only numbers
    .test(
      'valid-length',
      'UAE Number must be between 8 and 16 digits',
      function (value) {
        if (value === null || value === undefined) return true; // Skip if null (optional field)
        const numberStr = String(value); // Convert to string
        return numberStr.length >= 8 && numberStr.length <= 16;
      }
    )
    .test(
      'is-required',
      'UAE Number is required',
      function (value) {
        const { leaveTypeId } = this.parent;
        if ([1, 2, 3].includes(leaveTypeId)) {
          return value !== null && value !== undefined;
        }
        return true; // Skip validation if leaveTypeId is not 1, 2, or 3
      }
    ),


  address: Yup.string()
    .nullable()
    .transform((value, originalValue) => (originalValue === '' ? null : value)) // Convert empty string to null
    .typeError('Communication Address is required') // Ensure only numbers
    .test(
      'valid-length',
      'Communication Address is required',
      function (value) {
        if (value === null || value === undefined) return true;
        const numberStr = String(value); // Convert to string
        return numberStr.length >= 8 && numberStr.length <= 100;
      }
    )
    .test(
      'is-required',
      'Communication Address is required',
      function (value) {
        const { leaveTypeId } = this.parent;
        if ([1, 2, 3].includes(leaveTypeId)) {
          return value !== null && value !== undefined;
        }
        return true; // Skip validation if leaveTypeId is not 1, 2, or 3
      }
    ),
  ////////////
  addSubstitute: Yup.boolean().required('Substitute is required'),
  substituteEmployeeId: Yup.number()
    .nullable() // Allow null values initially
    .transform((value, originalValue) => (originalValue === '' ? null : value))
    .test(
      'is-required-if-substitute',
      'Substitute Employee is required',
      function (value) {
        const { addSubstitute } = this.parent; // Access other field values
        if (addSubstitute) {
          return value !== null && value !== undefined;
        }
        return true; // Skip validation if addSubstitute is false
      }
    ),
  substituteEmployeeContact: Yup.number()
    .nullable()
    .transform((value, originalValue) => {
      return originalValue === '' || originalValue === null ? null : value;
    }) // Convert empty string to null
    .typeError('Substitute Employee Contact Number must be a valid number') // Ensure only numbers
    .test(
      'valid-length',
      'Substitute Employee Contact Number must be between 8 and 16 digits',
      function (value) {
        if (value === null || value === undefined) return true; // Skip validation if empty
        const numberStr = String(value); // Convert to string for digit length check
        return /^\d{8,16}$/.test(numberStr); // Ensure only digits & check length
      }
    )
    .test(
      'is-required',
      'Substitute Employee Contact Number is required',
      function (value) {
        const { addSubstitute } = this.parent; // Check if substitute is required
        if (addSubstitute) {
          return !!value; // Required only if `addSubstitute` is true
        }
        return true; // Skip validation if `addSubstitute` is false
      }
    ),


  // approvedBy: Yup.number()
  //   .nullable()
  //   .transform((value, originalValue) => (originalValue === '' ? null : value))
  //   .test(
  //     'is-required-if-substitute',
  //     'Approved By is required',
  //     function (value) {
  //       const { addSubstitute } = this.parent; // Access other field values
  //       if (addSubstitute) {
  //         return value !== null && value !== undefined;
  //       }
  //       return true; // Skip validation if addSubstitute is false
  //     }
  //   ),
});
export const jobRecruitmentSchema = Yup.object().shape({
  jobRequisitionDetailId: Yup.number()
    .required('Job Requisition Detail ID is required.')
    .integer('Job Requisition Detail ID must be an integer.')
    .min(1, 'Job Requisition Detail ID must be greater than 0.'),
  fromBudget: Yup.number()
    .required('From Budget is required.')
    .min(0, 'From Budget cannot be negative.')
    .typeError('From Budget must be a number.'),
  toBudget: Yup.number()
    .required('To Budget is required.')
    .min(
      Yup.ref('fromBudget'),
      'To Budget must be greater than or equal to From Budget.'
    )
    .typeError('From Budget must be a number.'),
  jobRecruitmentStatusId: Yup.number()
    .required('Job Recruitment Status ID is required.')
    .integer('Job Recruitment Status ID must be an integer.')
    .min(1, 'Job Recruitment Status ID must be greater than 0.'),
  recruitmentClosedDate: Yup.date()
    .required('Recruitment Closed Date is required.')
    .typeError('Recruitment Closed Date must be a valid date.'),
});
export const jobRequisitionSchema = Yup.object().shape({
  requisitionTitle: Yup.string()
    .required('Requisition Title is required')
    .max(256, 'Requisition Title cannot exceed 256 characters'),
  requisitionDescription: Yup.string().optional(),
  requisitionReason: Yup.string().nullable(),

  createJobRequisitionDetails: Yup.array()
    .of(
      Yup.object().shape({
        experienceLevel: Yup.string()
          .required('Experience Level is required')
          .max(256, 'Experience Level cannot exceed 256 characters'),
        closedDate: Yup.date().required('Closed Date is required'),
        noOfPosition: Yup.number()
          .min(1, 'No of Position must be greater than 0')
          .transform((value, originalValue) =>
            originalValue === '' ? null : value
          )
          .required('No of Position is required'),
        fromBudget: Yup.number()
          .nullable() // Allows null values
          .transform((value, originalValue) =>
            originalValue === '' ? null : value
          ), // Transform empty string to null

        toBudget: Yup.number()
          .nullable() // Allows null values
          .transform((value, originalValue) =>
            originalValue === '' ? null : value
          )
          .test(
            'is-greater',
            'To Budget must be greater than or equal to From Budget',
            function (toBudget) {
              const { fromBudget } = this.parent;
              // Skip validation if either field is null
              if (toBudget == null || fromBudget == null) return true;
              return toBudget >= fromBudget;
            }
          ),
        requestedBy: Yup.string().required('Requested By is required'),
        skill: Yup.string()
          .required('Skill is required')
          .max(256, 'Skill cannot exceed 256 characters'),
      })
    )
    .required('Job Requisition Details is required'),
});

export const EditJobRequisitionSchema = Yup.object().shape({
  requisitionTitle: Yup.string()
    .required('Requisition Title is required')
    .max(256, 'Requisition Title cannot exceed 256 characters'),
  requisitionDescription: Yup.string().optional(),
  jobRequisitionManualCode: Yup.string()
    .nullable()
    .max(16, 'Requisition Manual Code cannot exceed 16 characters'),
  requisitionReason: Yup.string().nullable(),

  updateJobRequisitionDetails: Yup.array()
    .of(
      Yup.object().shape({
        experienceLevel: Yup.string()
          .required('Experience Level is required')
          .max(256, 'Experience Level cannot exceed 256 characters'),
        closedDate: Yup.date().required('Closed Date is required'),
        noOfPosition: Yup.number()
          .min(1, 'No of Position must be greater than 0')
          .transform((value, originalValue) =>
            originalValue === '' ? null : value
          )
          .required('No of Position is required'),
        fromBudget: Yup.number()
          .nullable() // Allows null values
          .transform((value, originalValue) =>
            originalValue === '' ? null : value
          ), // Transform empty string to null

        toBudget: Yup.number()
          .nullable() // Allows null values
          .transform((value, originalValue) =>
            originalValue === '' ? null : value
          )
          .test(
            'is-greater',
            'To Budget must be greater than or equal to From Budget',
            function (toBudget) {
              const { fromBudget } = this.parent;
              // Skip validation if either field is null
              if (toBudget == null || fromBudget == null) return true;
              return toBudget >= fromBudget;
            }
          ),
        requestedBy: Yup.string().required('Requested By is required'),
        skill: Yup.string()
          .required('Skill is required')
          .max(256, 'Skill cannot exceed 256 characters'),
      })
    )
    .required('Job Requisition Details is required'),
});
export const CandidateSchema = Yup.object().shape({
  jobRecruitmentId: Yup.string().required('Job Recruitment is required'),
  candidates: Yup.array().of(
    Yup.object().shape({
      firstName: Yup.string()
        .required('First Name is required')
        .max(128, 'FirstName cannot exceed 128 characters'),
      lastName: Yup.string()
        .required('Last Name is required')
        .max(128, 'LastName cannot exceed 128 characters'),
      emailId: Yup.string()
        .email('Enter a valid email')
        .required('Email is required')
        .max(256, 'Email cannot exceed 256 characters'),
      passportNumber: Yup.string()
        .nullable() // Allows null or undefined values without triggering validation
        .test(
          'is-valid-passport-number',
          'Passport Number must be between 6 and 9 characters',
          (value) => {
            if (!value) return true; // If empty or null, it's valid
            return value.length >= 6 && value.length <= 9; // Validate only when there is a value
          }
        ),
      dob: Yup.date().required('Date of Birth is required'),
      gender: Yup.string().required('Gender is required'),
      mobileNumber: Yup.string().required('Mobile Number is required'),
      // .matches(/^\d{8,15}$/, 'Mobile Number must be between 8 and 15 digits')
      // .max(20, 'Mobile Number cannot exceed 20 digits'),
      reference: Yup.string().max(
        512,
        'Reference cannot exceed 512 characters'
      ),
      candidateQualification: Yup.string().max(
        512,
        'Candiadte Qualification cannot exceed 512 characters'
      ),
      gulfExperience: Yup.string().test(
        'valid-range',
        'Gulf experience must be between 0 and 50',
        (value) => value === undefined || (value >= 0 && value <= 50)
      ),
      otherExperience: Yup.string().test(
        'valid-range',
        'Other experience must be between 0 and 50',
        (value) => value === undefined || (value >= 0 && value <= 50)
      ),
      lastEmployementCompanyName: Yup.string().max(
        512,
        'Last Company Name Period cannot exceed 512 characters'
      ),
    })
  ),
});
export const candidateEditSchema = Yup.object().shape({
  // jobRecruitmentId: Yup.string().required('Job Recruitment is required'),
  firstName: Yup.string()
    .required('First Name is required')
    .max(128, 'FirstName cannot exceed 128 characters'),
  lastName: Yup.string()
    .required('Last Name is required')
    .max(128, 'LastName cannot exceed 128 characters'),
  emailId: Yup.string()
    .email('Enter a valid email')
    .required('Email is required')
    .max(256, 'Email cannot exceed 256 characters'),
  passportNumber: Yup.string()
    .required('Passport Number is required')
    .test(
      'is-valid-passport-number',
      'Passport Number must be between 6 and 9 characters',
      (value) => {
        if (!value) return true; // If empty or null, it's valid
        return value.length >= 6 && value.length <= 9; // Validate only when there is a value
      }
    ),
  dob: Yup.date().required('Date of Birth is required'),
  gender: Yup.string().required('Gender is required'),
  mobileNumber: Yup.string().required('Mobile Number is required'),

  // fileId: Yup.number()
  //   .min(1, 'File ID must be greater than 0')
  //   .required('File is required'),
  // locationId: Yup.string().required('Location is required'),
  reference: Yup.string().max(512, 'Reference cannot exceed 512 characters'),
  candidateQualification: Yup.string().max(
    512,
    'Candiadte Qualification cannot exceed 512 characters'
  ),
  gulfExperience: Yup.string()
    .nullable()
    .test(
      'valid-range',
      'Gulf experience must be between 0 and 50',

      (value) => value === undefined || (value >= 0 && value <= 50)
    ),
  otherExperience: Yup.string().test(
    'valid-range',
    'Other experience must be between 0 and 50',
    (value) => value === undefined || (value >= 0 && value <= 50)
  ),
  lastEmployementCompanyName: Yup.string().max(
    512,
    'Last Company Name Period cannot exceed 512 characters'
  ),
});

export const requestQuoteSchema = Yup.object().shape({
  requestForQuoteStartDate: Yup.date().required('Start Date is required'),
  requestForQuoteEndingDate: Yup.date()
    .required('End Date is required')
    .min(
      Yup.ref('requestForQuoteStartDate'),
      'End Date must be after Start Date'
    ),
});
export const ManageAssigneeschema = Yup.object().shape({
  employeeId: Yup.string().required('Employee is required'),
  contractTypeId: Yup.string().required('Contract Type is required'),
  reportingTo: Yup.string().required('Reporting is required'),
  startDate: Yup.date()
    .required('Start Date is required')
    .max(Yup.ref('endDate'), 'Start Date must be before End Date'),
  endDate: Yup.date()
    .required('End Date is required')
    .min(Yup.ref('startDate'), 'End Date must be after Start Date'),
});

export const BuildingSchema = Yup.object().shape({
  buildingName: Yup.string().required('Building Name is required'),
  contractId: Yup.number()
    .required('Contract is required')
    .typeError('Contract must be a number'),
});
export const JournalEntriesvalidationSchema = Yup.object().shape({
  journalCode: Yup.string().required('Journal Code is required'),
  requestDate: Yup.date()
    .required('Request Date is required')
    .typeError('Invalid date format'),
  companyId: Yup.number()
    .required('Company is required')
    .min(1, 'Company must be greater than 0'),
  placeOfSupplyId: Yup.number()
    .required('Place of Supply is required')
    .min(1, 'Place of Supply must be greater than 0'),
  jurisdictionId: Yup.number()
    .required('Jurisdiction is required')
    .min(1, 'Jurisdiction must be greater than 0'),
  narration: Yup.string().required('Narration is required'),
});

export const LevelSchema = Yup.object().shape({
  levelName: Yup.string().required('Level Name is required'),
  buildingId: Yup.number()
    .required('Building is required')
    .typeError('Building must be a number'),
});

export const LevelUnitShema = Yup.object().shape({
  levelUnitName: Yup.string().required('Level Unit Name is required'),
  parentLevelUnitId: Yup.number()
    .required('Parent Level Unit is required')
    .typeError('Parent Level Unit must be a number'),
  levelId: Yup.number()
    .required('Level is required')
    .typeError('Level must be a number'),
});

export const BranchSchema = Yup.object().shape({
  branchName: Yup.string().required('Branch Name is required'),
});
export const PlaceOfSupplySchema = Yup.object().shape({
  placeOfSupplyName: Yup.string().required('Place of Supply Name is required'),
});
export const JurisdictionSchema = Yup.object().shape({
  jurisdictionName: Yup.string().required('Jurisdiction Name is required'),
});
export const guaranteesSchema = Yup.object().shape({
  guaranteeName: Yup.string().required('Guarantee Name is required'),
});
export const projectTeamRoleSchema = Yup.object().shape({
  projectTeamRoleName: Yup.string().required(
    'Project Team Role Name is required'
  ),
});

export const bidBondValidationSchema = Yup.object().shape({
  validity: Yup.string().required('Validity is required'),
  isBidBondOrSecurityCheck: Yup.string().required(
    'Bid Bond or Security Check is required'
  ),
  expectedTenderAmount: Yup.string().required(
    'Expected Tender Amount is required'
  ),
  bidBondPercentageValue: Yup.number()
    .transform((value, originalValue) => (originalValue === '' ? null : value))
    .nullable()
    .typeError('Bid Bond Percentage must be a number')
    .min(0, 'Bid Bond Percentage cannot be less than 0')
    .max(100, 'Bid Bond Percentage cannot exceed 100'),

  securityCheckPercentageValue: Yup.number()
    .transform((value, originalValue) => (originalValue === '' ? null : value))
    .nullable()
    .typeError('Security Check Percentage must be a number')
    .min(0, 'Security Check Percentage cannot be less than 0')
    .max(100, 'Security Check Percentage cannot exceed 100'),
});

export const followupscreatevalidationSchema = Yup.object().shape({
  tenderId: Yup.number()
    .required('Tender ID is required')
    .min(1, 'Tender ID must be greater than 0'),
  primaryContactPerson: Yup.string().required(
    'Primary Contact Person is required'
  ),
  primaryContactMail: Yup.string()
    .required('Primary Contact Mail is required')
    .email('Invalid email format'),
  primaryContactNumber: Yup.string()
    .required('Primary Contact Number is required')
    .matches(/^\d+$/, 'Primary Contact Number must contain only numbers'),
  tenderFollowupTypeId: Yup.number()
    .required('Tender Follow-up Type ID is required')
    .min(1, 'Tender Follow-up Type ID must be greater than 0'),
  followUpSchedules: Yup.array().of(
    Yup.object().shape({
      scheduleDate: Yup.date()
        .required('Schedule Date is required')
        .min(
          Yup.ref('reminderDate'),
          'Schedule Date must be after Reminder Date'
        ),

      reminderDate: Yup.date()
        .required('Reminder Date is required')
        .max(
          Yup.ref('scheduleDate'),
          'Reminder Date must be before Schedule Date'
        ),
    })
  ),
});

export const TaxCodeSchema = Yup.object().shape({
  taxCodeName: Yup.string().required('Tax Code Name is required'),
  taxCodeValue: Yup.string().required('Tax Code Value is required'),
});

export const UnitOfMeasurementSchema = Yup.object().shape({
  unitOfMeasurementName: Yup.string().required(
    'Unit of Measurement Name is required'
  ),
  baseUnitId: Yup.string().required('Base Unit is Required'),
  conversionFactor: Yup.string().required('Conversion Factor is required'),
});

export const passwordValidationSchema = Yup.object().shape({
  loginUserName: Yup.string().required('Username is required'),
  confirmPassword: Yup.string()
    .required('Confirm Password is required')
    .oneOf(
      [Yup.ref('loginUserPassword')],
      'Password and Confirm Password must match'
    ),
  roleId: Yup.string().required('Role is required'),
});

export const ContractCheckListSchema = Yup.object().shape({
  checkListItem: Yup.array().of(
    Yup.object().shape({
      invoiceChecklistTypeId: Yup.number()
        .nullable()
        .required('Invoice Check List Type is required'),
      remarks: Yup.string()
        .nullable()
        .max(255, 'Remarks cannot exceed 255 characters'),

    })
  ),
});
export const ARReceiptSchema = Yup.object().shape({
  companyId: Yup.string().required('Company is required'),
  clientId: Yup.string().required('Client is required'),
  paymentModeId: Yup.string().required('Payment Mode is required'),
  bankId: Yup.string().required('Bank is required'),
  chequeNo: Yup.string().required('Cheque No is required'),
  totalAmount: Yup.number()
    .typeError('Total amount must be a valid number')
    .required('Total amount is required')
    .min(0, 'Total amount cannot be negative'),
  currencyId: Yup.string().nullable().typeError('Currency is required'),
});

export const salesretentionitemsSchema = Yup.object().shape({
  itemId: Yup.number()
    .required('Item ID is required')
    .min(1, 'Item ID must be greater than 0'),
  // description: Yup.string()
  //   .required('Description is required')
  //   .max(255, 'Description cannot exceed 255 characters'),
  // taxId: Yup.number()
  //   .required('Tax ID is required')
  //   .min(1, 'Tax ID must be greater than 0'),
  // unitOfMeasurementId: Yup.number()
  //   .required('Unit of Measurement ID is required')
  //   .min(1, 'Unit of Measurement ID must be greater than 0'),
  // quantity: Yup.number()
  //   .required('Quantity is required')
  //   .min(1, 'Quantity must be greater than 0'),
  // rate: Yup.number()
  //   .required('Rate is required')
  //   .min(0, 'Rate cannot be negative'),
  // gross: Yup.number()
  //   .required('Gross is required')
  //   .min(0, 'Gross cannot be negative'),
  // discount: Yup.number()
  //   .required('Discount is required')
  //   .min(0, 'Discount cannot be negative'),
  // discountPercent: Yup.number()
  //   .required('Discount Percent is required')
  //   .min(0, 'Discount Percent cannot be negative')
  //   .max(100, 'Discount Percent cannot exceed 100'),
  // vat: Yup.number()
  //   .required('VAT is required')
  //   .min(0, 'VAT cannot be negative'),
  // amountWithTax: Yup.number()
  //   .required('Amount with Tax is required')
  //   .min(0, 'Amount with Tax cannot be negative'),
  // remarks: Yup.string()
  //   .max(255, 'Remarks cannot exceed 255 characters'),
});

export const ContractBudgetSchema = Yup.object().shape({
  divisionId: Yup.string().required('Divition is required'),
  upsertInternalBudgetingSuppliers: Yup.array().of(
    Yup.object().shape({
      supplierId: Yup.string().required('Supplier is required'),
      amount: Yup.number()
        .required('Amount is required')
        .min(0, 'Amount cannot be negative'),
    })
  ),
  budget: Yup.number()
    .required('Budget is required')
    .min(0, 'Budget cannot be negative'),
});




export const candidatedetailsstepOneSchema = Yup.object().shape({
  firstName: Yup.string()
    .required('First Name is required')
    .max(50, 'First Name cannot exceed 50 characters'),
  lastName: Yup.string()
    .required('Last Name is required')
    .max(50, 'Last Name cannot exceed 50 characters'),
  fullName: Yup.string()
    .required('Full Name is required')
    .max(100, 'Full Name cannot exceed 100 characters'),
  designationId: Yup.number()
    .required('Designation ID is required')
    .integer('Designation ID must be an integer')
    .min(1, 'Designation ID must be greater than 0'),
  basicSalary: Yup.number()
    .required('Basic Salary is required')
    .min(0, 'Basic Salary cannot be negative')
    .typeError('Basic Salary must be a valid number'),
  accommodation: Yup.number()
    .required('Accommodation amount is required')
    .min(0, 'Accommodation amount cannot be negative')
    .typeError('Accommodation amount must be a valid number'),
  transportation: Yup.number()
    .required('Transportation amount is required')
    .min(0, 'Transportation amount cannot be negative')
    .typeError('Transportation amount must be a valid number'),
  otherAllowance: Yup.number()
    .required('Other Allowance is required')
    .min(0, 'Other Allowance cannot be negative')
    .typeError('Other Allowance must be a valid number'),
  totalSalary: Yup.number()
    .required('Total Salary is required')
    .min(0, 'Total Salary cannot be negative')
    .typeError('Total Salary must be a valid number'),
});

export const candidatedetailsstepTwoSchema = Yup.object().shape({
  visaProfessionId: Yup.number()
    .required('Visa Profession ID is required')
    .integer('Visa Profession ID must be an integer')
    .min(1, 'Visa Profession ID must be greater than 0'),
  mohreCompanyId: Yup.number()
    .required('MOHRE Company ID is required')
    .integer('MOHRE Company ID must be an integer')
    .min(1, 'MOHRE Company ID must be greater than 0'),
  labourContract: Yup.string()
    .required('Labour Contract is required')
    .max(255, 'Labour Contract cannot exceed 255 characters'),
  probationPeriod: Yup.string()
    .required('Probation Period is required')
    .max(50, 'Probation Period cannot exceed 50 characters'),
  conditions: Yup.string()
    .required('Conditions are required')
    .max(500, 'Conditions cannot exceed 500 characters'),
  candidateConfirmationStep1Id: Yup.number()
    .required('Candidate Confirmation Step 1 ID is required')
    .integer('Candidate Confirmation Step 1 ID must be an integer')
    .min(1, 'Candidate Confirmation Step 1 ID must be greater than 0'),
});




export const joApplicationvalidationSchema = Yup.object().shape({
  joAppliedOn: Yup.date()
    .required('Job Applied On is required')
    .typeError('Job Applied On must be a valid date'),
  joApplicationAmount: Yup.number()
    .required('Job Application Amount is required')
    .min(0, 'Job Application Amount cannot be negative'),
  joApplicationRemarks: Yup.string()
    .required('Job Application Remarks are required')
    .max(500, 'Job Application Remarks cannot exceed 500 characters'),
  joApplicationPenaltyAmount: Yup.number()
    .required('Job Application Penalty Amount is required')
    .min(0, 'Job Application Penalty Amount cannot be negative')
    .typeError('Job Application Penalty Amount must be a valid number'),
  joApplicationPenaltyAccruals: Yup.number()
    .required('Job Application Penalty Accruals is required')
    .min(0, 'Job Application Penalty Accruals cannot be negative')
    .typeError('Job Application Penalty Accruals must be a valid number'),
  joApprovedOrRejectedOn: Yup.date()
    .required('Job Approved or Rejected On date is required')
    .typeError('Job Approved or Rejected On must be a valid date'),
  joApplicationReceiptFileId: Yup.number()
    .required('Job Application Receipt is required')
});



export const mohrevalidationSchema = Yup.object().shape({
  mohreAppliedOn: Yup.date()
    .required('MOHRE Applied On date is required')
    .typeError('MOHRE Applied On must be a valid date'),
  mohreApplicationAmount: Yup.number()
    .required('MOHRE Application Amount is required')
    .min(0, 'MOHRE Application Amount cannot be negative'),
  mohreApplicationReceiptFileId: Yup.number()
    .required('MOHRE Application Receipt is required'),
  mohreApplicationRemarks: Yup.string()
    .required('MOHRE Application Remarks are required')
    .max(500, 'MOHRE Application Remarks cannot exceed 500 characters'),
  mohreApprovedOrRejectedOn: Yup.date()
    .required('MOHRE Approved or Rejected On date is required')
    .typeError('MOHRE Approved or Rejected On must be a valid date'),
});

export const vpApplicationvalidationSchema = Yup.object().shape({
  wpAppliedOn: Yup.date()
    .required('Work Permit Applied On date is required')
    .typeError('Work Permit Applied On must be a valid date'),
  wpApplicationAmount: Yup.number()
    .required('Work Permit Application Amount is required')
    .min(0, 'Work Permit Application Amount cannot be negative')
    .typeError('Work Permit Application Amount must be a valid number'),
  wpApplicationReceiptFileId: Yup.number()
    .required('Work Permit Application Receipt is required'),
  wpApplicationRemarks: Yup.string()
    .required('Work Permit Application Remarks are required')
    .max(500, 'Work Permit Application Remarks cannot exceed 500 characters'),
  wpApprovedOrRejectedOn: Yup.date()
    .required('Work Permit Approved or Rejected On date is required')
    .typeError('Work Permit Approved or Rejected On must be a valid date'),
});

export const entryPermitvalidationSchema = Yup.object().shape({
  entryPermitAppliedOn: Yup.date()
    .required('Entry Permit Applied On date is required')
    .typeError('Entry Permit Applied On must be a valid date'),
  entryPermitApplicationAmount: Yup.number()
    .required('Entry Permit Application Amount is required')
    .min(0, 'Entry Permit Application Amount cannot be negative')
    .typeError('Entry Permit Application Amount must be a valid number'),
  entryPermitApplicationReceiptFileId: Yup.number()
    .required('Entry Permit Application Receipt is required'),
  entryPermitApplicationRemarks: Yup.string()
    .required('Entry Permit Application Remarks are required')
    .max(500, 'Entry Permit Application Remarks cannot exceed 500 characters'),
  entryPermitApprovedOrRejectedOn: Yup.date()
    .required('Entry Permit Approved or Rejected On date is required')
    .typeError('Entry Permit Approved or Rejected On must be a valid date'),
});


export const visaProcessingvalidationSchema = Yup.object().shape({
  visaAppliedOn: Yup.date()
    .required('Visa Applied On date is required')
    .typeError('Visa Applied On must be a valid date'),
  visaApplicationAmount: Yup.number()
    .required('Visa Application Amount is required')
    .min(0, 'Visa Application Amount cannot be negative')
    .typeError('Visa Application Amount must be a valid number'),
  visaApplicationReceiptFileId: Yup.number()
    .required('Visa Application Receipt File is required'),
  visaApplicationRemarks: Yup.string()
    .required('Visa Application Remarks are required')
    .max(500, 'Visa Application Remarks cannot exceed 500 characters'),
  visaApprovedOrRejectedOn: Yup.date()
    .required('Visa Approved or Rejected On date is required')
    .typeError('Visa Approved or Rejected On must be a valid date'),
  visaEmirateId: Yup.number()
    .required('Visa Emirate ID is required')
    .integer('Visa Emirate ID must be an integer')
    .min(1, 'Visa Emirate ID must be greater than 0'),
  visaSponsorId: Yup.number()
    .required('Visa Sponsor ID is required')
    .integer('Visa Sponsor ID must be an integer')
    .min(1, 'Visa Sponsor ID must be greater than 0'),
  visaTypeId: Yup.number()
    .required('Visa Type ID is required')
    .integer('Visa Type ID must be an integer')
    .min(1, 'Visa Type ID must be greater than 0'),
  visaExpiryDate: Yup.date()
    .required('Visa Expiry Date is required')
    .typeError('Visa Expiry Date must be a valid date'),
  emiratesIdNo: Yup.string()
    .required('Emirates ID No is required')
    .max(50, 'Emirates ID No cannot exceed 50 characters'),
  emiratesExpiryDate: Yup.date()
    .required('Emirates Expiry Date is required')
    .typeError('Emirates Expiry Date must be a valid date'),
  labourCardNo: Yup.string()
    .required('Labour Card No is required')
    .max(50, 'Labour Card No cannot exceed 50 characters'),
  labourPersonalNumber: Yup.string()
    .required('Labour Personal Number is required')
    .max(50, 'Labour Personal Number cannot exceed 50 characters'),
  labourCardExpiryDate: Yup.date()
    .required('Labour Card Expiry Date is required')
    .typeError('Labour Card Expiry Date must be a valid date'),
});

export const visaStampingvalidationSchema = Yup.object().shape({
  visaStampingAppliedOn: Yup.date()
    .required('Visa Stamping Applied On date is required')
    .typeError('Visa Stamping Applied On must be a valid date'),
  visaStampingApplicationAmount: Yup.number()
    .required('Visa Stamping Application Amount is required')
    .min(0, 'Visa Stamping Application Amount cannot be negative')
    .typeError('Visa Stamping Application Amount must be a valid number'),
  visaStampingApplicationReceiptFileId: Yup.number()
    .required('Visa Stamping Application Receipt File is required'),
  visaStampingApplicationRemarks: Yup.string()
    .required('Visa Stamping Application Remarks are required')
    .max(500, 'Visa Stamping Application Remarks cannot exceed 500 characters'),
  visaStampingApprovedOrRejectedOn: Yup.date()
    .required('Visa Stamping Approved or Rejected On date is required')
    .typeError('Visa Stamping Approved or Rejected On must be a valid date'),
});
export const PettyCashManagementValidationSchema = Yup.object().shape({
  employeeId: Yup.string().required('Employee is required'),
  amount: Yup.number().required('Amount is required')
    .min(0, 'Amount cannot be negative')
    .positive('Amount cannot be negative')
    .typeError('Amount must be a valid number'),
  issuedBy: Yup.string().required('Issued By is required'),
});

