import React, { useEffect, useState } from 'react';
import DashboardLayout from '../../../components/DashboardLayout.tsx';
import PageHeader from '../../../components/PageHeader.jsx';
import DataTable from '../../../components/DataTable.tsx';
import GlassCard from '../../../components/small/GlassCard.tsx';
import TableActionButtons from '../../../components/_form/TableActionButtons.jsx';
import {
    StatusBadge,
} from '../../../components/_form/FormElements.jsx';
import { Icon } from '@iconify/react';
import { Box, Grid } from '@mui/material';
import { CreateButton } from '../../../components/small/Buttons.jsx';
import MuiDialogOne from '../../../components/_mui/MuiDialogOne.jsx';
import Crud_Service from '../../../apis/CrudService.jsx';
import { useForm } from 'react-hook-form';
import CommonLoader from '../../../components/page/CommonLoader.jsx';
import useLoader from '../../../components/helpers/UseLoader.tsx';
import ActionButtons from '../../../components/_form/ActionButtons.tsx';
import useTableLogic from '../../../components/helpers/UseTableLogic.tsx';
import FmTextField from '../../../components/_mui/FmTextField.tsx';
import TableSearch from '../../../components/_form/TableSearch.jsx';
import FmAutoComplete from '../../../components/_mui/FmAutoComplete.tsx';
import { toast } from 'react-toastify';
import { Nav } from '../../../utils/index.jsx';
import useCommonFetchApi from '../../../components/helpers/useCommonFetchApi.tsx';
import { LocalizationProvider } from '@mui/x-date-pickers';
import { AdapterDayjs } from '@mui/x-date-pickers/AdapterDayjs';
import dayjs from 'dayjs';
import { useTranslation } from 'react-i18next';
import FmDatePicker from '../../../components/_mui/FmDatePicker.tsx';
import { yupResolver } from '@hookform/resolvers/yup';
import { VehicleAdvertisementSchema } from '../../../utils/CommonvalidationSchemas.tsx';
import { statusOptions } from '../../../utils/CommonVariables.tsx';
import CommonView from '../../../components/helpers/CommonView.jsx';
import DynamicViewFields from '../../../components/helpers/DynamicViewFields.jsx';
import FmSearchableSelect from '../../../components/_mui/FmSearchableSelect.tsx';

const VehicleAdvertisement = () => {
  const { t } = useTranslation();
  const [openCreate, setOpenCreate] = useState(false);
  const [openEdit, setOpenEdit] = useState(false);
  const [loading, setLoading] = useState(false);
  const { handleSubmit, setValue, reset, control } = useForm({
    resolver: yupResolver(VehicleAdvertisementSchema),
    mode: 'onChange',
    defaultValues: {
      issueDate: dayjs(),
      expiryDate: dayjs(),
    },
  });
  const {
    createLoading,
    startCreateLoading,
    stopCreateLoading,
    editLoading,
    startEditLoading,
    stopEditLoading,
  } = useLoader();
  const {
    rows,
    pageSize,
    pageNumber,
    setSearchKeyword,
    isLoading,
    tableRecordCounts,
    handlePaginationModelChange,
    handleSortModelChange,
    searchKeyword,
    setRows,
    fetchData,
  } = useTableLogic('vehicleadvertisements');
  const crud = new Crud_Service();
  const [viewDetails, setViewdetails] = useState(false);
  const { data: vehicles } = useCommonFetchApi('vehicles') || {
    data: [],
  };
  const handleViewClick = async (props) => {
    setViewdetails(props);
  };
  useEffect(() => {
    document.title = `Vehicle Advertisement`;
  }, []);

  //create the Card Type
  const handleSubmitVehicleAdvertisement = async (values) => {
    startCreateLoading();
    const combinedData = {
      ...values,
      issueDate: dayjs(values?.issueDate).format('YYYY-MM-DD'),
      expiryDate: dayjs(values?.expiryDate).format('YYYY-MM-DD'),
    };
    await crud.create('vehicleadvertisements', combinedData, (err, res) => {
      if (err) {
        toast.error(t('something_Went_Wrong!'), err);
        return;
      }
      if (res?.status === 201) {
        toast.success('Insurance Created Successfully!');
        setOpenCreate(false);
        stopCreateLoading();
        fetchData();
      } else {
        stopCreateLoading();
        toast.error(t('something_Went_Wrong!'), res);
      }
    });
  };

  const handleCreateClickOpen = () => {
    reset();
    setOpenCreate(true);
  };
  const handleCreateClose = () => {
    reset();
    setOpenCreate(false);
  };

  const counters = [
    {
      name: 'All',
      count: tableRecordCounts?.total || 0,
    },
    {
      name: 'Active',
      count: tableRecordCounts?.active || 0,
    },
    {
      name: 'Deactivated',
      count: tableRecordCounts?.inActive || 0,
    },
  ];

  //handle Delete Record
  const handleDeleteClick = async (view) => {
    await crud.remove(
      'vehicleadvertisements',
      view?.vehicleAdvertisementId,
      (err, res) => {
        if (res?.status === 204) {
          toast.success('Vehicle Advertisement Deleted Successfully!');
          fetchData();
        } else {
        }
      }
    );
  };

  //SetValues to the Form
  const handleEditClick = async (view) => {
    setOpenEdit(true);
    setLoading(true);
    await crud.getSingle(
      'vehicleadvertisements',
      view?.vehicleAdvertisementId,
      (err, res) => {
        if (res?.status === 200) {
          Object.entries({
            ...res?.data,
          }).forEach(([key, value]) => {
            setValue(key, value);
          });
          setLoading(false);
        } else {
        }
      }
    );
  };

  //handle Update
  const handleEditVehicleTypes = async (values) => {
    startEditLoading();
    await crud.update(
      'vehicleadvertisements',
      values?.vehicleAdvertisementId,
      values,
      (err, res) => {
        if (res?.status === 200) {
          toast.success('Vehicle Advertisement Updated Successfully!');
          setOpenEdit(false);
          stopEditLoading();
          fetchData();
        } else {
          stopEditLoading();
          setOpenEdit(true);
        }
      }
    );
  };

  const excludeKeys = ['vehicleAdvertisementId', 'vechicleId', 'status'];

  const ActionData = [
    {
      name: 'View',
      icon: <Icon icon='solar:eye-linear' />,
      onClick: (props) => handleViewClick(props),
    },
    {
      name: 'Edit',
      icon: <Icon icon='solar:pen-new-round-linear' />,
      onClick: (props) => handleEditClick(props),
    },
    {
      name: 'Delete',
      icon: <Icon icon='solar:trash-bin-2-linear' />,
      onClick: (props) => handleDeleteClick(props),
      danger: true,
    },
  ];

  const columns = [
    {
      field: 'action',
      headerName: '',
      width: 140,
      sortable: false,
      disableClickEventBubbling: false,

      renderCell: (params) => {
        return (
          <TableActionButtons
            Actions={ActionData?.map((action) => ({
              ...action,
              onClick: () => action.onClick(params.row),
            }))}
          />
        );
      },
    },
    {
      field: 'vehicleAdvertisementName',
      headerName: 'Vehicle Advertisement Name',
      flex: 1,
    },
    {
      field: 'permitNumber',
      headerName: 'Permit Number',
      flex: 1,
    },
    {
      field: 'remarks',
      headerName: 'Remarks',
      flex: 1,
    },
    {
      field: 'issueDate',
      headerName: 'Issue Date',
      flex: 1,
    },
    {
      field: 'expiryDate',
      headerName: 'Expiry Date',
      flex: 1,
    },

    {
      field: 'status',
      headerName: 'Status',
      flex: 1,
      renderCell: (params) => {
        return (
          <StatusBadge
            title={params.value === 2 ? 'Active' : 'Inactive'}
            type={params.value === 2 ? 'green' : 'red'}
          />
        );
      },
    },
  ];

  return (
    <DashboardLayout
      title='Advertisement'
      actionButtons={
        <>
          <PageHeader counters={counters} />
        </>
      }
      menu={Nav[10]?.child[6]?.children}
      hasSubmenu
    >
      <GlassCard>
        <Box sx={{ height: 'calc(100vh - 200px)' }}>
          <DataTable
            rows={rows}
            columns={columns}
            loading={isLoading}
            getRowClassName={(params) =>
              `${params.row.status === 0 && 'bg-red-50'}`
            }
            sortingMode='server'
            paginationMode='server'
            onPaginationModelChange={(model) =>
              handlePaginationModelChange(model)
            }
            onSortModelChange={(model) => handleSortModelChange(model)}
            page={pageNumber - 1}
            pageSize={pageSize}
            rowCount={tableRecordCounts?.total}
            slots={{
              toolbar: () => (
                <Box
                  className='p-2 w-full flex justify-between items-center'
                  sx={{
                    borderBottom: '1px solid',
                    borderColor: 'border.main',
                  }}
                >
                  <Box sx={{ maxWidth: '250px' }}>
                    <TableSearch
                      placeholder='Search'
                      fullWidth
                      setSearchKeyword={setSearchKeyword}
                      searchValue={searchKeyword}
                    />
                  </Box>
                  <CreateButton name='New' onClick={handleCreateClickOpen} />
                </Box>
              ),
            }}
          />
        </Box>
      </GlassCard>

      {/* create */}

      <MuiDialogOne
        title='Create New'
        open={openCreate}
        onClose={handleCreateClose}
      >
        <form onSubmit={handleSubmit(handleSubmitVehicleAdvertisement)}>
          <LocalizationProvider dateAdapter={AdapterDayjs}>
            <Grid container spacing={3}>
              <Grid item md={12}>
                <FmTextField
                  name='permitNumber'
                  control={control}
                  label='Permit Number'
                />
              </Grid>
              <Grid item md={12}>
                <FmSearchableSelect
                  name='vechicleId'
                  control={control}
                  apiUrl='vehicles'
                  headerField={['Vehicle Code', 'Vehicle Name']}
                  labelField={['vehicleCode', 'vehicleName']}
                  showField={['vehicleCode', 'vehicleName']}
                  
                  valueField='vehicleId'
                  label='Vehicle'
                />
              </Grid>
              <Grid item md={12}>
                <FmTextField name='remarks' control={control} label='Remarks' />
              </Grid>
              <Grid item md={12}>
                <FmDatePicker
                  name='issueDate'
                  label='Issue Date'
                  control={control}
                  required
                />
              </Grid>
              <Grid item md={12}>
                <FmDatePicker
                  name='expiryDate'
                  label='Expiry Date'
                  control={control}
                  required
                />
              </Grid>

              <Grid item md={12}>
                <ActionButtons
                  onSubmit={handleSubmit(handleSubmitVehicleAdvertisement)}
                  onCancel={handleCreateClose}
                  onReset={reset}
                  submitLoading={createLoading}
                  cancelLoading={false}
                />
              </Grid>
            </Grid>
          </LocalizationProvider>
        </form>
      </MuiDialogOne>

      {/* create */}

      {/* view */}
      <MuiDialogOne
        title='View Details'
        open={viewDetails}
        onClose={() => setViewdetails(!viewDetails)}
      >
        {viewDetails && viewDetails?.vehicleAdvertisementId && (
          <CommonView
            url='vehicleadvertisements'
            id={viewDetails?.vehicleAdvertisementId}
            excludeKeys={excludeKeys}
            renderFields={(data, fields) => (
              <DynamicViewFields data={data} fields={fields} />
            )}
          />
        )}
      </MuiDialogOne>

      {/* edit */}
      <MuiDialogOne
        title='Update'
        open={openEdit}
        onClose={() => setOpenEdit(false)}
      >
        {loading ? (
          <CommonLoader />
        ) : (
          <form onSubmit={handleSubmit(handleEditVehicleTypes)}>
            <LocalizationProvider dateAdapter={AdapterDayjs}>
              <Grid container spacing={3}>
                <Grid item md={12}>
                  <FmTextField
                    name='permitNumber'
                    control={control}
                    label='Premium Number'
                  />
                </Grid>
                <Grid item md={12}>
                  <FmSearchableSelect
                    name='vechicleId'
                    control={control}
                    apiUrl='vehicles'
                    headerField={['Vehicle Code', 'Vehicle Name']}
                    labelField={['vehicleCode', 'vehicleName']}
                    showField={['vehicleCode', 'vehicleName']}
                    
                    valueField='vehicleId'
                    label='Vehicle'
                    defaultValue={{
                      vechicleId: viewDetails?.vehicleId,
                      vehicleName: viewDetails?.vehicleName,
                    }}
                  />
                </Grid>
                <Grid item md={12}>
                  <FmDatePicker
                    name='issueDate'
                    label='Issue Date'
                    control={control}
                    required
                  />
                </Grid>
                <Grid item md={12}>
                  <FmDatePicker
                    name='expiryDate'
                    label='Expiry Date'
                    control={control}
                    required
                  />
                </Grid>
                <Grid item md={12}>
                  <FmTextField
                    name='remarks'
                    control={control}
                    label='Remarks'
                  />
                </Grid>
                <Grid item md={12}>
                  <FmAutoComplete
                    name='status'
                    control={control}
                    options={statusOptions}
                    label='Status'
                    displayField='statusName'
                    optionFields={['statusName']}
                    valueKey='statusId'
                  />
                </Grid>
                <Grid item md={12}>
                  <ActionButtons
                    onSubmit={handleSubmit(handleEditVehicleTypes)}
                    onCancel={() => setOpenEdit(false)}
                    onReset={reset}
                    submitLoading={editLoading}
                    cancelLoading={false}
                    submitText='Update'
                  />
                </Grid>
              </Grid>
            </LocalizationProvider>
          </form>
        )}
      </MuiDialogOne>
    </DashboardLayout>
  );
};

export default VehicleAdvertisement;
