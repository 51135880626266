import React, { useEffect, useState } from 'react';
import GlassCard from '../../../components/small/GlassCard.tsx';
import { Button, Box } from '@mui/material';
import Icons from '../../../utils/Icon.jsx';
import { MaterialReactTable } from 'material-react-table';
import MRTExpandTableStyle from '../../../components/table/MRTExpandTableStyle.tsx';

const EditVendorDocumentsDeliveryNotesTable = ({
  editButtonOnClick,
  docDeliveryNotes,
  DeliveryNotesNotEditable,
}) => {
  const [data, setData] = useState(docDeliveryNotes);

  useEffect(() => {
    setData(docDeliveryNotes);
  }, [docDeliveryNotes]);

  // Define the columns
  const columns = [
    {
      accessorKey: 'projectName',
      header: 'Project',
    },

    {
      accessorKey: 'contractName',
      header: 'contract',
    },

    {
      accessorKey: 'description',
      header: 'Description',
    },

    {
      accessorKey: 'quantity',
      header: 'Quantity',
    },

    {
      accessorKey: 'rate',
      header: 'Rate (AED)',
    },

    {
      accessorKey: 'vat',
      header: 'VAT %',
    },

    {
      accessorKey: 'totalAmount',
      header: 'Amount (AED)',
    },

    {
      accessorKey: 'remarks',
      header: 'Remarks',
    },
  ];

  return (
    <GlassCard className='my-4'>
      <Box className='flex justify-between items-center  p-2'>
        <h3 className=' font-semibold'>Delivery Notes</h3>
        {!DeliveryNotesNotEditable && (
          <Button
            startIcon={Icons.edit}
            variant='contained'
            className='px-3 py-0.5'
            onClick={editButtonOnClick}
          >
            Edit
          </Button>
        )}
      </Box>
      {/* list */}
      <MRTExpandTableStyle>
        <MaterialReactTable
          columns={columns}
          data={data}
          enableColumnActions={false}
          enableDensityToggle={false}
          positionGlobalFilter='left'
          enableGlobalFilter={true}
          enableFilters={false}
          initialState={{
            pagination: { pageIndex: 0, pageSize: 25 },
            showGlobalFilter: true,
          }}
          muiTableContainerProps={{
            sx: {
              height: '300px',
            },
          }}
          enableTopToolbar={false}
          editDisplayMode='row'
          createDisplayMode='row'
          enableStickyHeader
        />
      </MRTExpandTableStyle>
    </GlassCard>
  );
};

export default EditVendorDocumentsDeliveryNotesTable;
