import React from 'react';
import { Box } from '@mui/material';
import FmViewDateField from '../_mui/FmViewDateField.tsx';

const SideViewTable = ({ rows }) => {
  return (
    <Box
      sx={{
        '& table': {
          width: '100%',
          '& tr': {
            '& td': {
              padding: '2px 0px',
              wordBreak: 'break-all',
            },
          },
        },
      }}
    >
      <table>
        <tbody>
          {rows.map((row, i) => (
            <tr key={i}>
              <td
                style={{
                  width: '120px',
                  minWidth: '120px',
                  opacity: 0.8,
                }}
              >
                {row.label}
              </td>
              {row?.date ? (
                <td className='font-semibold'>
                  <FmViewDateField value={row.title} />{' '}
                </td>
              ) : (
                <td className='font-semibold'>{row.title}</td>
              )}
            </tr>
          ))}
        </tbody>
      </table>
    </Box>
  );
};

export default SideViewTable;
