import React, { useEffect, useState } from 'react';
import { useNavigate, useParams } from 'react-router-dom';
import DashboardLayout from '../../../../components/DashboardLayout.tsx';
import PRReconciliationMatch from '../Edit/PRReconciliationMatch.tsx';
import BackToButton from '../../../../components/small/BackToButton.tsx';
import Crud_Service from '../../../../apis/CrudService.jsx';
import ElementLoader from '../../../../components/page/ElementLoader.tsx';

const BankStatements = () => {
  const navigate = useNavigate();
  const id = useParams().id;
  const [data, setData] = useState([]);
  const crud = new Crud_Service();
  const [loading, setLoading] = useState(false);

  const fetchData = async () => {
    setLoading(true);
    await crud.getSingle('customeraccountstatements', id, (err, res) => {
      if (res?.status === 200) {
        setData(res?.data);
        setLoading(false);
      }
    });
  };

  useEffect(() => {
    document.title = 'Bank Statements | Reconciliation | Financial';
  });

  useEffect(() => {
    fetchData();
  }, []);

  return (
    <DashboardLayout
      title={data?.bankName || 'Bank Statements'}
      titleReverse
      actionButtons={
        <BackToButton
          title='Back to Bank Statements'
          onClick={() => navigate('/financial/reconciliation/bank-statements')}
          className='px-0 py-0'
        />
      }
    >
      {loading ? (
        <div className='h-full w-full relative'>
          <ElementLoader image fly />
        </div>
      ) : (
        <PRReconciliationMatch isEditable={true} viewData={data} />
      )}
    </DashboardLayout>
  );
};

export default BankStatements;
