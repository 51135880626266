import React, { memo } from 'react';
import { Box, Avatar, Tooltip } from '@mui/material';
import { colorOnlyClasses } from './../../utils/Colors/ColorClasses.tsx';

const TableAvatar = ({
  name,
  img,
  desc,
  fontWeightRemoved = false,
  removeImage,
}) => {
  const initial = name ? name.charAt(0).toUpperCase() : '';

  return (
    <Box className='flex gap-2 flex-row'>
      {!removeImage && (
        <Avatar
          alt={name}
          src={img}
          sx={{
            width: 25,
            height: 25,
            fontSize: 11,
            textTransform: 'uppercase',
            bgcolor: 'info.main',
            color: 'text.white',
            fontWeight: 400,
          }}
        >
          {initial}
        </Avatar>
      )}
      <Box>
        <p style={{ fontWeight: fontWeightRemoved ? 400 : 500 }}>{name}</p>
        <p className='opacity-70'>{desc}</p>
      </Box>
    </Box>
  );
};

export const TableAvatarStatus = memo(({ name, icon, desc, color, title }) => {
  return (
    <Box className='flex gap-2 flex-row justify-between w-full items-center'>
      <Box>
        <Box sx={{ fontWeight: 500, color: 'text.dark' }}>{name}</Box>
        <p style={{ fontWeight: 300 }}>{desc}</p>
      </Box>
      <Tooltip title={title} arrow>
        <Avatar
          alt={name}
          sx={{
            width: 25,
            height: 25,
            fontSize: 16,
            color: 'text.main',
            background: 'none',
            border: '1px solid',
          }}
          className={`${colorOnlyClasses[color]}`}
        >
          {icon}
        </Avatar>
      </Tooltip>
    </Box>
  );
});

export default memo(TableAvatar);
