import { yupResolver } from '@hookform/resolvers/yup';
import { Grid } from '@mui/material';
import dayjs from 'dayjs';
import React, { useEffect, useMemo, useState } from 'react';
import { useForm } from 'react-hook-form';
import { useSelector } from 'react-redux';
import { useNavigate, useParams } from 'react-router-dom';
import { toast } from 'react-toastify';
import * as yup from 'yup';
import Crud_Service from '../../../../apis/CrudService.jsx';
import ActionButtons from '../../../../components/_form/ActionButtons.tsx';
import FmDatePicker from '../../../../components/_mui/FmDatePicker.tsx';
import FmSearchableSelect from '../../../../components/_mui/FmSearchableSelect.tsx';
import FmTextField from '../../../../components/_mui/FmTextField.tsx';
import DashboardLayout from '../../../../components/DashboardLayout.tsx';
import useLoader from '../../../../components/helpers/UseLoader.tsx';
import { findNavByName } from '../../../../utils/navUtils.ts';
import GlassCard from './../../../../components/small/GlassCard.tsx';

const GridSize = 2.4;
const schema = yup.object().shape({
  salesQuotationName: yup.string().required('Sales Quotation Name is required'),
  quotationStatusId: yup
    .number()
    .integer('Quotation Status ID must be an integer')
    .positive('Quotation Status ID must be positive')
    .required('Quotation Status ID is required'),
});
const AddCreateSalesQuotation = (props) => {
  const { type, setGetData } = props;
  const crud = new Crud_Service();
  const navigate = useNavigate();
  const { startCreateLoading, stopCreateLoading, createLoading } = useLoader();
  const [editingRowData, setEditingRowData] = useState(null);
  const { control, handleSubmit, reset, setValue } = useForm({
    resolver: yupResolver(schema),
    mode: 'onChange',
  });
  const id = useParams();
  useEffect(() => {
    if (id?.id) {
      (async () => {
        await crud.getSingle('salesquotations', id.id, (_err, res) => {
          if (res?.status === 200) {
            setEditingRowData(res.data);
            Object.entries({
              ...res.data,
            }).forEach(([key, value]) => {
              setValue(key, value);
            });
          } else {
            // Handle error response here
          }
        });
      })();
    }
  }, [id]);
  const handleEditItem = async (values) => {
    const data = {
      ...values,
      quotationDate: dayjs(values?.quotationDate).format('YYYY-MM-DD'),
      contactPersonId: values.contactPersonId,
      companyId: values?.companyId,
      locationId: values.locationId,
      regionId: values.regionId,
      workOrderId: values.workOrderId,
      quotationStatusId: values.quotationStatusId,
      status: 2,
      salesOrderTypeId: type,
    };
    await crud.update('salesquotations', id?.id, data, (_err, res) => {
      if (res?.status === 200) {
        toast.success('Updated Successfully');
        navigate(-1);
      } else {
      }
    });
  };

  const submitSalesQuotation = async (values: any) => {
    startCreateLoading();
    const data = {
      ...values,
      quotationDate: dayjs(values?.quotationDate).format('YYYY-MM-DD'),
      salesOrderTypeId: type,
      quotationStatusId: 1,
    };
    await crud.create('salesquotations', data, (_err, res) => {
      if (res?.status === 201) {
        toast.success('Added Successfully');
        navigate(-1);
        reset();
        setGetData(true);
      } else {
        stopCreateLoading();
      }
    });
  };
  const [, setSelectedClient] = React.useState(null);

  const handleSelectedClient = (e) => {
    setSelectedClient(e);
  };
  const menu = useSelector((state) => state?.menu?.items);

  const SubMenu = useMemo(() => {
    return menu?.map((item) => item?.menus?.flat()).flat() || [];
  }, [menu]);

  const result = useMemo(
    () => findNavByName(SubMenu, 'CAFM', 'Work Order'),
    [SubMenu]
  );
  return (
    <DashboardLayout title='Create Sales Quotation' hasSubmenu menu={result}>
      <GlassCard className='p-3'>
        <Grid container spacing={2}>
          <Grid item md={GridSize}>
            <FmSearchableSelect
              name='companyId'
              label='Company'
              apiUrl={'companies'}
              valueField='companyId'
              labelField={['companyName', 'companyCode']}
              showField={['companyName', 'companyCode']}
              control={control}
              defaultValue={{
                companyId: editingRowData?.companyId,
                companyName: editingRowData?.companyName,
              }}
            />
          </Grid>
          <Grid item md={GridSize}>
            <FmSearchableSelect
              name='clientId'
              label='Client'
              apiUrl='clients'
              valueField='clientId'
              onChangeProp={handleSelectedClient}
              labelField={['clientName', 'clientCode']}
              showField={['clientName', 'clientCode']}
              control={control}
              defaultValue={{
                clientId: editingRowData?.clientId,
                clientName: editingRowData?.clientName,
              }}
            />
          </Grid>
          <Grid item md={GridSize}>
            <FmTextField
              name='salesQuotationName'
              label='Sales Quotation Name'
              control={control}
              required
            />
          </Grid>

          <Grid item md={GridSize}>
            <FmDatePicker
              name='quotationDate'
              control={control}
              label='Quotation Date'
            />
          </Grid>
          <Grid item md={GridSize}>
            <FmSearchableSelect
              name='workOrderId'
              label='Work Order'
              apiUrl={'workorders'}
              valueField='workOrderId'
              labelField={['workOrderName', 'autoWorkOrderCode']}
              showField={['workOrderName', 'autoWorkOrderCode']}
              control={control}
              defaultValue={{
                workOrderId: editingRowData?.workOrderId,
                workOrderName: editingRowData?.workOrderName,
              }}
            />
          </Grid>

          <Grid item md={GridSize}>
            <FmSearchableSelect
              name='regionId'
              label='Region'
              apiUrl={'regions'}
              valueField='regionId'
              labelField={['regionName', 'regionCode']}
              showField={['regionName', 'regionCode']}
              control={control}
              defaultValue={{
                regionId: editingRowData?.regionId,
                regionName: editingRowData?.regionName,
              }}
            />
          </Grid>

          <Grid item md={GridSize}>
            <FmSearchableSelect
              name='locationId'
              label='Supply Location'
              apiUrl={'locations'}
              valueField='locationId'
              labelField={['locationName', 'locationCode']}
              showField={['locationName', 'locationCode']}
              control={control}
              defaultValue={{
                locationId: editingRowData?.locationId,
                locationName: editingRowData?.locationName,
              }}
            />
          </Grid>

          <Grid item md={GridSize}>
            <FmTextField
              name='paymentTerms'
              label='Payment Terms'
              control={control}
            />
          </Grid>
          <Grid item md={GridSize}>
            <FmTextField name='narration' label='Narration' control={control} />
          </Grid>
          <Grid item md={GridSize}>
            <FmTextField
              name='deliveryTerms'
              label='Delivery Terms'
              control={control}
            />{' '}
          </Grid>
          <Grid item md={GridSize}>
            <FmTextField name='subject' label='Subject' control={control} />
          </Grid>
          <Grid item md={GridSize}>
            <FmSearchableSelect
              name='contactPersonId'
              label='Contact Person'
              apiUrl={'loginusers'}
              valueField='loginUserId'
              labelField={['employeeName']}
              showField={['employeeName']}
              control={control}
              defaultValue={{
                contactPersonId: editingRowData?.contactPersonId,
                loginUserName: editingRowData?.loginUserName,
              }}
            />
          </Grid>
          <Grid item md={GridSize}>
            <FmTextField name='notes' label='Notes' control={control} />{' '}
          </Grid>
          <Grid item md={GridSize}>
            <FmTextField name='validity' label='Validity' control={control} />
          </Grid>
          <Grid item md={GridSize}>
            <FmTextField name='remarks' label='Remarks' control={control} />
          </Grid>
          <Grid item md={GridSize}>
            <FmTextField name='attn' label='Attn' control={control} />
          </Grid>
          <Grid item md={GridSize}>
            <FmSearchableSelect
              name='quotationStatusId'
              label='Quotation Status'
              apiUrl={'salesquotations/statuses'}
              required
              valueField='salesQuotationStatusId'
              labelField={['salesQuotationStatusName']}
              showField={['salesQuotationStatusName']}
              control={control}
              defaultValue={{
                quotationStatusId: editingRowData?.quotationStatusId,
                salesQuotationStatusName:
                  editingRowData?.salesQuotationStatusName,
              }}
            />{' '}
          </Grid>

          <Grid item md={12}>
            <ActionButtons
              onSubmit={handleSubmit(
                id?.id ? handleEditItem : submitSalesQuotation
              )}
              onReset={reset}
              onCancel={() => navigate(-1)}
              submitLoading={createLoading}
              cancelLoading={false}
              cancelText='Go Back'
              submitText='Create'
            />
          </Grid>
        </Grid>
      </GlassCard>
    </DashboardLayout>
  );
};

export default AddCreateSalesQuotation;
