/* eslint-disable */
import React, { useEffect, useRef, useState } from "react";
import BackToButton from "./../../../components/small/BackToButton";
import GlassCard from "../../../components/small/GlassCard.tsx";
import { ViewField } from "../../../components/_form/FormElements";
import { Icon } from "@iconify/react";
import Crud_Service from "../../../apis/CrudService.jsx";
import { toast } from "react-toastify";
import useLoader from "../../../components/helpers/UseLoader.tsx";
import DashboardLayout from "../../../components/DashboardLayout.tsx";
import { useNavigate, useParams } from "react-router-dom";
import Dialogs from "./Dialogs.jsx";
import { Box, Grid, Stack, Button } from "@mui/material";
import useCommonFetchApi from "../../../components/helpers/useCommonFetchApi.tsx";
import TotalBox from "../EstimationSheetForAMC/TotalBox.jsx";
import MuiDialogOne from "../../../components/_mui/MuiDialogOne.jsx";
import FmFileInput from "../../../components/_mui/FmFileInput.tsx";
import ActionButtons from "../../../components/_form/ActionButtons.tsx";
import apiInstance from "../../../apis/ApiService.jsx";
import { useForm } from "react-hook-form";
import FmViewDateField from "../../../components/_mui/FmViewDateField.tsx";
import useTableLogic from "../../../components/helpers/MRTUseTableLogic.tsx";
import FmMRTDataTable from "../../../components/FmMRTDataTable.tsx";
import TableActionButtons from "../../../components/_form/TableActionButtons.jsx";
import FmAutoComplete from "../../../components/_mui/FmAutoComplete.tsx";
import FmTextField from "../../../components/_mui/FmTextField.tsx";
import { yupResolver } from "@hookform/resolvers/yup";
import { MEPTableValidationSchema } from "../../../utils/CommonvalidationSchemas.tsx";
import { useHotkeys } from "react-hotkeys-hook";

const ViewEstimationMEP = () => {
  const [total, setTotalAmount] = useState(0);

  const { id } = useParams();
  const {
    rows,
    setRows,
    isLoading,
    pagination,
    sorting,
    columnFilters,
    globalFilter,
    tableRecordCounts,
    setSearchKeyword,
    searchKeyword,
    setPagination,
    setSorting,
    setColumnFilters,
    setGlobalFilter,
    fetchData,
  } = useTableLogic(
    `estimationmepsheetdetails?estimationMepSheetId=${id}&sortDirection=asc`,
    [""],
    [""]
  );

  const [viewData, setViewData] = useState([]);
  const [productView, setProductView] = useState(false);
  const [estimationMEP, setEstimationMEP] = useState<any>({});
  const [pageSize, setPageSize] = useState(20);
  const [pageNumber, setPageNumber] = useState(1);
  const [sortBy, setSortBy] = useState("");
  const [sortDirection, setSortDirection] = useState("");
  const {
    reset,
    setValue,
    control,
    getValues,
    formState: { isValid },
  } = useForm({
    resolver: yupResolver(MEPTableValidationSchema),
    mode: "all",
  });

  const [amcSheetsPercent, setAmcSheetsPercent] = useState({
    note: "",
    overHeadOverAllPercent: 0,
    overHeadCost: 0,
    contingencyOverAllPercent: 0,
    contingencyCost: 0,
    otherOverAllPercent: 0,
    otherCost: 0,
    markUpOverAllPercent: 0,
    markUpCost: 0,
    discountOverAllPercent: 0,
    discountCost: 0,
    negotiationPercent: 0,
    negotiationCost: 0,
    totalEstimationCost: 0,
  });

  const [openUpload, setOpenUpload] = useState(false);
  const [uploadLoading, setUploadLoading] = useState(false);
  const [uploadedfileData, setUploadedfileData] = useState(null);
  const [amcAllEstimationSheets, setAllEstimationAMC] = useState([]);
  const [tablerecordCounts, setTableRecordCounts] = useState(null);
  const navigate = useNavigate();
  const { startCreateLoading, stopCreateLoading, createLoading } = useLoader();
  const crud = new Crud_Service();
  const [estimationTypes, setEstimationTypes] = useState([]);
  const [manPower, setManPower] = useState(false);
  const [manPowerData, setManPowerData] = useState<any>([]);
  const [nextId, setNextId] = useState();
  const [tableLoad, setTableLoad] = useState(false);
  const [editingRowId, setEditingRowId] = useState(null);
  const [selectedRate, setSelectedRate] = useState(null);
  const [editingRowData, setEditingRowData] = useState(null);
  const [currentRowIndex, setCurrentRowIndex] = useState(0);
  const [activeRowIndex, setActiveRowIndex] = useState(null);
  const [activeField, setActiveField] = useState(null);
  const [editingRowIndex, setEditingRowIndex] = useState(null);
  // new states
  const [newRowCreated, setNewRowCreated] = useState(false);
  const [stateError, setStateError] = useState(false);
  const [totalBoxLoader, setTotalBoxLoader] = useState(true);
  const [amcSubtotal, setAmcSubtotal] = useState(0);
  const [rowSelection, setRowSelection] = useState({});
  const [selectedRowIds, setSelectedRowIds] = useState([]);
  const [rowState, setRowState] = useState({
    newRowId: null,
    isCreatingRow: false,
  });
  const firstColumnInputRef = useRef(null);

  useEffect(() => {
    setTotalAmount(rows[0]?.totalAmount);
  }, [rows]);



  const handleAddNewRow = () => {
    const newId = "new_row_" + Date.now();
    reset();
    setEditingRowData(null);
    setEditingRowId(null);

    setRowState({
      newRowId: newId,
      isCreatingRow: true,
    });

    setEditingRowIndex(
      editingRowIndex === rows[0]?.estimationMepSheetDetails?.length
        ? editingRowIndex + 1
        : rows[0]?.estimationMepSheetDetails?.length
    );

    setNewRowCreated(false);
    setRows((prevRows) =>
      prevRows.map((row, index) => {
        if (index === 0) {
          // Add a new row to the estimationAmcSheetDetails of the first row
          return {
            ...row,
            estimationMepSheetDetails: [
              ...row.estimationMepSheetDetails,
              {
                estimationMepSheetId: newId,
                estimationMepSheetDetailId: id,
                bill: "",
                estimationTypeId: "",
                lineItem: "",
                unitSellingPrice: 0,
                subHead: "",
                quantity: 0,
                unitOfMeasurementId: "",
                currencyId: "",
                materialCost: 0,
                installationCost: 0,
                subTotalAmount: 0,
                actualUnitPrice: 0,
                overHeadPercent: 0,
                overHeadUnitPrice: 0,
                contingencyPercent: 0,
                contingencyUnitPrice: 0,
                markUpPercent: 0,
                markupUnitPrice: 0,
                discountPercent: 0,
                discountUnitPrice: 0,
                negotiationPercent: 0,
                negotiationUnitPrice: 0,
                totalMepAmount: 0,
                isEditable: true,
                isNew: true,
              },
            ],
          };
        }
        return row; // Return other rows unchanged
      })
    );
    reset();
  };

  const previousValues = useRef(getValues()); // store previous values to compare with

  const handleBlur = () => {
    const currentValues = getValues();

    // Compare the previous values with the current values
    const hasChanges = Object.keys(currentValues).some(
      (key) => currentValues[key] !== previousValues.current[key]
    );
    if (hasChanges && isValid || hasChanges && editingRowId) {
      apiCallAsync(currentValues); // Call the API with the new values
      previousValues.current = currentValues; // Update previous values
    }
  };

  const apiCallAsync = async (values) => {
    const convertedValues = {
      ...values,
      status: 2, // Static status value
      discountUnitPrice: Number(editingRowData?.discountUnitPrice),
      markupUnitPrice: Number(editingRowData?.markupUnitPrice),
      negotiationUnitPrice: Number(editingRowData?.negotiationUnitPrice),
      overHeadUnitPrice: Number(editingRowData?.overHeadUnitPrice),
      subTotalAmount: Number(editingRowData?.subTotalAmount),
      totalMepAmount: Number(editingRowData?.totalMepAmount),
      actualUnitPrice: Number(editingRowData?.actualUnitPrice),
      contingencyUnitPrice: Number(editingRowData?.contingencyUnitPrice),
      unitPrice: Number(editingRowData?.materialCost),
      contingencyPercent: Number(editingRowData?.contingencyPercent),
      discountPercent: Number(editingRowData?.discountPercent),
      installationCost: Number(editingRowData?.installationCost),
      negotiationPercent: Number(editingRowData?.negotiationPercent),
      overHeadPercent: Number(editingRowData?.overHeadPercent),
      quantity: Number(editingRowData?.quantity),
      markUpPercent: Number(editingRowData?.markUpPercent),
    };

    const combinedData = {
      estimationMepSheetId: Number(id),
      ...convertedValues,
      // estimationMepSheetDetails: [convertedValues], // Combine to match API format
    };

    const updateCombinedData = {
      ...values, // Same as above, combining updated values
      estimationMepSheetDetailId: id, // Ensure proper ID is passed
      status: 2, // Static status value
      discountUnitPrice: Number(editingRowData?.discountUnitPrice),
      markupUnitPrice: Number(editingRowData?.markupUnitPrice),
      negotiationUnitPrice: Number(editingRowData?.negotiationUnitPrice),
      overHeadUnitPrice: Number(editingRowData?.overHeadUnitPrice),
      subTotalAmount: Number(editingRowData?.subTotalAmount),
      totalMepAmount: Number(editingRowData?.totalMepAmount),
      actualUnitPrice: Number(editingRowData?.actualUnitPrice),
      contingencyUnitPrice: Number(editingRowData?.contingencyUnitPrice),
      unitPrice: Number(editingRowData?.materialCost),
      contingencyPercent: Number(editingRowData?.contingencyPercent),
      discountPercent: Number(editingRowData?.discountPercent),
      installationCost: Number(editingRowData?.installationCost),
      negotiationPercent: Number(editingRowData?.negotiationPercent),
      overHeadPercent: Number(editingRowData?.overHeadPercent),
      quantity: Number(editingRowData?.quantity),
      markUpPercent: Number(editingRowData?.markUpPercent),
    };

    // Conditional API call based on whether this is a new row or an update
    if (editingRowId === null) {
      await crud.create(
        "estimationmepsheetdetails",
        combinedData,
        (err, res) => {
          if (res?.status === 201) {
            setEditingRowId(res?.data?.estimationMepSheetDetailId);
            setValue(
              "estimationMepSheetDetailId",
              res?.data?.estimationMepSheetDetailId
            );
          } else {
            // Handle error
          }
        }
      );
    } else {
      await crud.update(
        "estimationmepsheetdetails",
        editingRowId,
        updateCombinedData,
        (err, res) => {
          if (res?.status === 200) {
            if (rows[0]?.estimationMepSheetDetails?.some((row) => row.isNew)) {
              const updatedRow = res?.data;
              const updatedRows = [...rows[0]?.estimationMepSheetDetails];
              updatedRows[editingRowIndex] = {
                ...updatedRow,
                isEditable: true,
                isNew: true,
              };
              setRows([
                {
                  ...rows[0],
                  estimationMepSheetDetails: updatedRows,
                },
              ]);
            } else {
              const updatedRow = res?.data;
              const updatedRows = [...rows[0]?.estimationMepSheetDetails];

              updatedRows[editingRowIndex] = updatedRow;

              setRows([
                {
                  ...rows[0],
                  estimationMepSheetDetails: updatedRows,
                },
              ]);
            }
          } else {
            // Handle error
          }
        }
      );
    }
  };
  useEffect(() => {
    document.title = `Presales | Estimation Sheet Details`;
  }, [pageNumber, pageSize, searchKeyword, sortBy, sortDirection]);

  useEffect(() => {
    getEstimationMEP();
    getAllAMC();
    getEstimationTypes();
  }, []);

  const [loader, setLoader] = useState(false);
  useEffect(() => {
    manPower && getManPower();
  }, [manPower]);

  const getManPower = async () => {
    await crud
      .getAll(`manpowerrates?pageSize=100&pageNumber=1`, "", (err, res) => {
        if (res?.data) {
          setManPowerData(res?.data?.data);
        }
      })
      .catch((err) => {
        toast.error(err?.message);
      });
  };

  const getEstimationTypes = async () => {
    await crud.getAll(
      "estimationtypes?pageSize=100&pageNumber=1",
      "",
      (err, res) => {
        if (err) {
          toast.error(err);
          return;
        }
        if (res?.status === 200) {
          setEstimationTypes(res?.data.data);
        }
      }
    );
  };

  const getEstimationMEP = async () => {
    // setTableLoad(false);
    setTotalBoxLoader(true);
    await crud.getSingle(`estimationmepsheets`, id, (err, res) => {
      if (err) {
        toast.error(err);
      }
      if (res?.status === 200) {
        setEstimationMEP(res?.data);

        setAmcSheetsPercent((prev) => ({
          ...prev,
          overHeadOverAllPercent: res?.data?.overHeadPercent || 0,
          overHeadCost: res?.data?.overHeadUnitPrice || 0,
          contingencyOverAllPercent: res?.data?.contingencyOverAllPercent || 0,
          contingencyCost: res?.data?.contingencyCost || 0,
          otherOverAllPercent: res?.data?.otherOverAllPercent,
          otherCost: res?.data?.otherCost,
          markUpOverAllPercent: res?.data?.markUpOverAllPercent || 0,
          markUpCost: res?.data?.markUpCost || 0,
          discountOverAllPercent: res?.data?.discountOverAllPercent,
          discountCost: res?.data?.discountCost,
          negotiationPercent: res?.data?.negotiationPercent,
          negotiationCost: res?.data?.negotiationCost,
          totalEstimationCost: res?.data?.totalEstimationCost || 0,
          note: res?.data?.note || "",
        }));
        // setTableLoad(true);
        setTotalBoxLoader(false);
      }
    });
  };

  const getAllAMC = async () => {
    await crud.getAll(`estimationmepsheets`, {}, (err, res) => {
      if (err) {
        toast.error(err);
      }
      if (res?.status === 200) {
        setAllEstimationAMC(res?.data?.data);
      }
    });
  };

  const handleDeleteSheet = async (props) => {
    await crud.remove(
      `estimationmepsheetdetails?estimationMepSheetDetailIds=${props?.estimationMepSheetId}`,
      '',
      (err, res) => {
        if (res?.status === 204) {
          toast.success("MEP Sheet deleted Successfully");
          fetchData();
        } else {
          toast.error("Something Went Wrong!");
        }
      }
    );
  };

  const handleEdit = (row) => {
    if (rowState?.newRowId) {
      setRows((prevRows) =>
        prevRows.filter((r) => r?.estimationMepSheetId !== rowState?.newRowId)
      );
      setRowState((prevState) => ({
        ...prevState,
        newRowId: null,
        isCreatingRow: false,
      }));
      // setEditingRowIndex(null);
    }
    setEditingRowId(row?.estimationMepSheetDetailId);
    Object.entries({
      ...row,
    }).forEach(([key, value]) => {
      setValue(key, value);
    });
    setEditingRowData({ ...row });
  };

  const { data: unitofmeasurements } =
    useCommonFetchApi("unitofmeasurements") || [];
  const { data: manpowerrates } = useCommonFetchApi("manpowerrates");
  const { data: currencies } = useCommonFetchApi("currencies");

  const handleExportMEP = async () => {
    const downloadUrl = await apiInstance.getFiles(
      `estimationmepsheetdetails/${id}/exportexcel`
    );

    const link = document.createElement("a");
    link.href = downloadUrl;
    link.setAttribute("download", "MEP Details.xlsx");
    document.body.appendChild(link);
    link.click();
    document.body.removeChild(link);
  };

  const handleTotalSubmit = () => {
    const data = {
      ...amcSheetsPercent,
      contingencyCost: Number(amcSheetsPercent.contingencyCost),
      discountCost: Number(amcSheetsPercent.discountCost),
      markUpCost: Number(amcSheetsPercent.markUpCost),
      otherCost: Number(amcSheetsPercent.otherCost),
      overHeadCost: Number(amcSheetsPercent.overHeadCost),
      negotiationCost: Number(amcSheetsPercent.negotiationCost),
      totalEstimationCost: Number(amcSheetsPercent.totalEstimationCost),
      actualUnitPrice: Number(amcSheetsPercent.actualUnitPrice),
    };
    crud.update(
      `estimationmepsheets/${id}/update-note`,
      "",
      data,
      (err, res) => {
        if (err) {
          toast.error(err);
          return;
        }
        if (res?.status === 200) {
          toast.success("Estimation Sheet Detail Created Successfully");
          fetchData();
        } else {
        }
      }
    );
  };
  const handleFileUpload = async (e) => {
    setUploadLoading(true);
    const file = e.target.files[0];
    if (file) {
      const fileFormData = new FormData();
      fileFormData.append("file", file);
      fileFormData.append("fileTypeId", 41);

      await crud.create(`/files/upload`, fileFormData, (err, res) => {
        if (res?.status === 200) {
          setUploadLoading(false);
          setUploadedfileData(res?.data);
        } else {
          setUploadLoading(false);
        }
      });
    }
  };

  const handleSubmitImport = async () => {
    startCreateLoading();
    await crud.create(
      `estimationmepsheetdetails/${id}/process/${uploadedfileData?.fileId}`,
      "",
      (err, res) => {
        if (res?.status === 200) {
          toast.success("Estimation MEP Sheet Detail Created Successfully");
          stopCreateLoading();
          setOpenUpload(false);
          setUploadedfileData(null);
          fetchData();
        } else {
          stopCreateLoading();
        }
      }
    );
  };

  const handleCancel = (row) => {
    if (row?.original?.estimationMepSheetId === rowState?.newRowId) {
      setRows((prevRows) =>
        prevRows.filter((r) => r?.estimationMepSheetId !== rowState?.newRowId)
      );
      setRowState((prevState) => ({
        ...prevState,
        newRowId: null,
        isCreatingRow: false,
      }));
      setEditingRowIndex(null);
    } else {
      setEditingRowId(null);
      setEditingRowIndex(null);
    }
    reset();
  };
  useEffect(() => {
    if (selectedRate) {
      const selectedManPowerRate = manpowerrates.find(
        (item) => item.manPowerRateId === selectedRate
      );

      if (selectedManPowerRate?.ctcPerMonth) {
        setEditingRowData((prev) => ({
          ...prev,
          materialCost: selectedManPowerRate.ctcPerMonth,
        }));
      }
    }
  }, [selectedRate, manpowerrates]);

  useEffect(() => {
    const { quantity, materialCost, installationCost } = editingRowData || {};
    if (quantity != null && materialCost != null && installationCost != null) {
      const calculatedSubTotal =
        (parseFloat(materialCost) + parseFloat(installationCost)) *
        parseFloat(quantity);
      setEditingRowData((prev) => ({
        ...prev,
        subTotalAmount: calculatedSubTotal,
      }));
    }
  }, [
    editingRowData?.quantity,
    editingRowData?.materialCost,
    editingRowData?.installationCost,
  ]);

  useEffect(() => {
    const { materialCost, installationCost } = editingRowData || {};
    if (materialCost && installationCost) {
      const calculatedActualUnitPrice =
        parseFloat(materialCost) + parseFloat(installationCost);
      setEditingRowData((prev) => ({
        ...prev,
        actualUnitPrice: calculatedActualUnitPrice,
      }));
    }
  }, [editingRowData?.materialCost, editingRowData?.installationCost]);

  // First useEffect for overHeadUnitPrice

  useEffect(() => {
    const { overHeadPercent, actualUnitPrice } = editingRowData || {};
    const basePrice = actualUnitPrice || 0;
    const calculatedOverHeadUnitPrice =
      basePrice * (1 + (overHeadPercent || 0) / 100);
    setEditingRowData((prev) => ({
      ...prev,
      overHeadUnitPrice: calculatedOverHeadUnitPrice.toFixed(2),
    }));
  }, [editingRowData?.overHeadPercent, editingRowData?.actualUnitPrice]);
  // Second useEffect for contingencyUnitPrice

  useEffect(() => {
    const { contingencyPercent, overHeadUnitPrice } = editingRowData || {};

    const basePrice = overHeadUnitPrice || editingRowData?.actualUnitPrice || 0;
    const calculatedContingencyUnitPrice =
      basePrice * (1 + (contingencyPercent || 0) / 100);
    setEditingRowData((prev) => ({
      ...prev,
      contingencyUnitPrice: calculatedContingencyUnitPrice.toFixed(2),
    }));
  }, [
    editingRowData?.contingencyPercent,
    editingRowData?.overHeadUnitPrice,
    editingRowData?.actualUnitPrice,
  ]);
  // Third useEffect for markupUnitPrice

  useEffect(() => {
    const { markUpPercent, contingencyUnitPrice } = editingRowData || {};

    const basePrice =
      contingencyUnitPrice ||
      editingRowData?.overHeadUnitPrice ||
      editingRowData?.actualUnitPrice ||
      0;
    const calculatedMarkupPrice = basePrice * (1 + (markUpPercent || 0) / 100);

    setEditingRowData((prev) => ({
      ...prev,
      markupUnitPrice: calculatedMarkupPrice.toFixed(2),
    }));
  }, [
    editingRowData?.markUpPercent,
    editingRowData?.contingencyUnitPrice,
    editingRowData?.contingencyPercent,
    editingRowData?.overHeadUnitPrice,
    editingRowData?.actualUnitPrice,
  ]);
  // Fourth useEffect for discountUnitPrice
  useEffect(() => {
    const { discountPercent, markupUnitPrice } = editingRowData || {};

    const basePrice =
      markupUnitPrice ||
      editingRowData?.contingencyUnitPrice ||
      editingRowData?.overHeadUnitPrice ||
      editingRowData?.actualUnitPrice ||
      0;
    const calculatedDiscountPrice =
      basePrice * (1 - (discountPercent || 0) / 100);

    setEditingRowData((prev) => ({
      ...prev,
      discountUnitPrice: calculatedDiscountPrice.toFixed(2),
    }));
  }, [
    editingRowData?.discountPercent,
    editingRowData?.markupUnitPrice,
    editingRowData?.contingencyUnitPrice,
    editingRowData?.overHeadUnitPrice,
    editingRowData?.actualUnitPrice,
  ]);

  // Fifth useEffect for negotiationUnitPrice
  useEffect(() => {
    const { negotiationPercent, discountUnitPrice } = editingRowData || {};

    const basePrice =
      discountUnitPrice ||
      editingRowData?.markupUnitPrice ||
      editingRowData?.contingencyUnitPrice ||
      editingRowData?.overHeadUnitPrice ||
      editingRowData?.actualUnitPrice ||
      0;
    const calculatedNegotiationPrice =
      basePrice * (1 - (negotiationPercent || 0) / 100);

    setEditingRowData((prev) => ({
      ...prev,
      negotiationUnitPrice: calculatedNegotiationPrice.toFixed(2),
    }));
  }, [
    editingRowData?.negotiationPercent,
    editingRowData?.discountUnitPrice,
    editingRowData?.markupUnitPrice,
    editingRowData?.contingencyUnitPrice,
    editingRowData?.overHeadUnitPrice,
    editingRowData?.actualUnitPrice,
  ]);

  useEffect(() => {
    const { quantity, negotiationUnitPrice, negotiationPercent } =
      editingRowData || {};
    if (quantity && negotiationUnitPrice && negotiationPercent) {
      const calculatedAMCAmount = quantity * negotiationUnitPrice;
      setEditingRowData((prev) => ({
        ...prev,
        totalMepAmount: calculatedAMCAmount.toFixed(2),
      }));
    }
  }, [
    editingRowData?.quantity,
    editingRowData?.negotiationUnitPrice,
    editingRowData?.negotiationPercent,
  ]);

  useEffect(() => {
    if (editingRowData) {
      const amcSubtotal = rows[0]?.estimationMepSheetDetails?.reduce(
        (total, row, index) => {
          const amountToAdd =
            index === editingRowIndex
              ? Number(editingRowData?.totalMepAmount) || 0
              : Number(row.totalMepAmount) || 0;

          return total + amountToAdd;
        },
        0
      );
      setAmcSubtotal(amcSubtotal);
    }
  }, [editingRowData]);

  const handleBlurAndFetch = async () => {
    setLoader(true);
    const updatedRow = getValues(); // Get the latest values from the form
    const updatedRows = [...rows[0]?.estimationMepSheetDetails];

    updatedRows[editingRowIndex] = updatedRow;
    setRows([
      {
        ...rows[0],
        estimationMepSheetDetails: updatedRows,
      },
    ]);

    const values = getValues();
    const updateCombinedData = {
      ...values,
      estimationMepSheetDetailId: id,
      status: 2,
      discountUnitPrice: Number(editingRowData?.discountUnitPrice),
      markupUnitPrice: Number(editingRowData?.markupUnitPrice),
      negotiationUnitPrice: Number(editingRowData?.negotiationUnitPrice),
      overHeadUnitPrice: Number(editingRowData?.overHeadUnitPrice),
      subTotalAmount: Number(editingRowData?.subTotalAmount),
      totalMepAmount: Number(editingRowData?.totalMepAmount),
      actualUnitPrice: Number(editingRowData?.actualUnitPrice),
      contingencyUnitPrice: Number(editingRowData?.contingencyUnitPrice),
      unitPrice: Number(editingRowData?.materialCost),
      contingencyPercent: Number(editingRowData?.contingencyPercent),
      discountPercent: Number(editingRowData?.discountPercent),
      installationCost: Number(editingRowData?.installationCost),
      negotiationPercent: Number(editingRowData?.negotiationPercent),
      overHeadPercent: Number(editingRowData?.overHeadPercent),
      quantity: Number(editingRowData?.quantity),
      markUpPercent: Number(editingRowData?.markUpPercent),
    };
    await crud.update(
      "estimationMepSheetDetails",
      editingRowId,
      updateCombinedData,
      (err, res) => {
        if (res?.status === 200) {
          const totalRows = rows[0]?.estimationMepSheetDetails?.length;
          if (
            editingRowIndex <= totalRows &&
            rows[0]?.estimationMepSheetDetails?.some((row) => row.isNew)
          ) {
            setNewRowCreated(true);
            const updatedRow = res?.data;
            const updatedRows = [...rows[0]?.estimationMepSheetDetails];
            updatedRows[editingRowIndex] = {
              ...updatedRow,
              isEditable: true,
              isNew: true,
            };
            setRows([
              {
                ...rows[0],
                estimationMepSheetDetails: updatedRows,
              },
            ]);

            handleAddNewRow();
          } else {
            setStateError(false);
            setLoader(false);
          }
        } else {
          setStateError(true);
        }
      }
    );
  };

  const ActionData = [
    // {
    //   name: 'Edit',
    //   icon: <Icon icon='solar:pen-2-broken' />,
    //   onClick: (props) => handleEdit(props),
    // },
    {
      name: "Delete",
      icon: <Icon icon="mi:delete" />,
      onClick: (props) => handleDeleteSheet(props),
      danger: true,
    },
  ];
  useHotkeys(
    "Enter",
    () => handleAddNewRow(),
    {
      enableOnTags: ["INPUT", "TEXTAREA"], // Optional: Enable hotkey only when focused on specific tags
    },
    [editingRowIndex, rows[0]?.estimationMepSheetDetails, stateError, loader]
  );

  useHotkeys(
    "Tab",
    (event) => {
      if (event.key !== "Tab") return;

      // Prevent default tab behavior if there is an error
      if (stateError) {
        event.preventDefault();
        return;
      }
      const totalRows = rows[0]?.estimationMepSheetDetails?.length;
      const nextRowIndex = editingRowIndex + 1;

      if (
        nextRowIndex < totalRows &&
        stateError === false &&
        loader === false
      ) {
        setCurrentRowIndex(nextRowIndex);
        handleEdit(rows[0]?.estimationMepSheetDetails[nextRowIndex]);
        setEditingRowIndex(nextRowIndex);
      }
    },
    [editingRowIndex, rows[0]?.estimationMepSheetDetails, stateError, loader]
  );
  useEffect(() => {
    if (editingRowIndex !== null && firstColumnInputRef.current && !isLoading) {
      firstColumnInputRef.current.focus();
    }
  }, [editingRowIndex, isLoading]);

  useEffect(() => {
    // Filter rows based on rowSelection keys
    if (rowSelection) {
      const selectedRowIds = rows
        ?.filter((_, index) => rowSelection[index]) // Check if row index is selected in rowSelection
        .map((row) => row?.estimationMepSheetDetails); // Get the `estimationAmcSheetDetailId` of selected rows[0]?.estimationMepSheetDetails?

      setSelectedRowIds(selectedRowIds);
    }
  }, [rowSelection]);

  const handleCancelAndAddNewRow = () => {
    const newId = "new_row_" + Date.now();
    reset();
    setEditingRowData(null);
    setEditingRowId(null);
    setRowState({
      newRowId: newId,
      isCreatingRow: true,
    });

    setEditingRowIndex(
      editingRowIndex === rows[0]?.estimationMepSheetDetails?.length
        ? editingRowIndex + 1
        : rows[0]?.estimationMepSheetDetails?.length
    );
    setNewRowCreated(false);
    setRows((prevRows) =>
      prevRows.map((row, index) => {
        if (index === 0) {
          // Add a new row to the estimationAmcSheetDetails of the first row
          return {
            ...row,
            estimationMepSheetDetails: [
              ...row.estimationMepSheetDetails,
              {
                estimationMepSheetId: newId,
                estimationMepSheetDetailId: id,
                bill: "",
                estimationTypeId: "",
                lineItem: "",
                unitSellingPrice: 0,
                subHead: "",
                quantity: 0,
                unitOfMeasurementId: "",
                currencyId: "",
                materialCost: 0,
                installationCost: 0,
                subTotalAmount: 0,
                actualUnitPrice: 0,
                overHeadPercent: 0,
                overHeadUnitPrice: 0,
                contingencyPercent: 0,
                contingencyUnitPrice: 0,
                markUpPercent: 0,
                markupUnitPrice: 0,
                discountPercent: 0,
                discountUnitPrice: 0,
                negotiationPercent: 0,
                negotiationUnitPrice: 0,
                totalMepAmount: 0,
                isEditable: true,
                isNew: true,
              },
            ],
          };
        }
        return row; // Return other rows unchanged
      })
    );
    reset();
  };

  const columns = [
    {
      accessorKey: "bill",
      header: "Bill",
      enableEditing: true,
      Cell: ({ cell }) => {
        const isNewRow =
          cell?.row?.original?.estimationMepSheetId === rowState?.newRowId;
        const isEditing =
          editingRowId === cell?.row?.original?.estimationMepSheetDetailId ||
          isNewRow;

        return isEditing ? (
          <FmTextField
            name="bill"
            control={control}
            label="Bill"
            onBlur={handleBlur}
            inputRef={firstColumnInputRef}
          />
        ) : (
          <div
            onClick={() => {
              handleEdit(cell?.row?.original);
              setEditingRowIndex(cell?.row?.index);
            }}
          >
            {cell?.row?.original?.bill}
          </div>
        );
      },
    },
    {
      accessorKey: "head",
      header: "Head",
      enableEditing: true,
      Cell: ({ row }) => {
        const isNewRow =
          row?.original?.estimationMepSheetId === rowState?.newRowId;
        const isEditing =
          editingRowId === row?.original?.estimationMepSheetDetailId ||
          isNewRow;
        return isEditing ? (
          <FmTextField
            name="head"
            control={control}
            label="Head"
            onBlur={handleBlur}
          />
        ) : (
          <div
            onClick={() => {
              handleEdit(row?.original);
              setEditingRowIndex(row?.index);
            }}
          >
            {row?.original?.head}
          </div>
        );
      },
    },
    {
      accessorKey: "subHead",
      header: "Sub Head",
      enableEditing: true,
      Cell: ({ row }) => {
        const isNewRow =
          row?.original?.estimationMepSheetId === rowState?.newRowId;
        const isEditing =
          editingRowId === row?.original?.estimationMepSheetDetailId ||
          isNewRow;
        return isEditing ? (
          <FmTextField
            name="subHead"
            control={control}
            label="subHead"
            onBlur={handleBlur}
          />
        ) : (
          <div
            onClick={() => {
              handleEdit(row?.original);
              setEditingRowIndex(row?.index);
            }}
          >
            {row?.original?.subHead}
          </div>
        );
      },
    },

    {
      accessorKey: "lineItem",
      header: "Line Item",
      enableEditing: true,
      Cell: ({ row }) => {
        const isNewRow =
          row?.original?.estimationMepSheetId === rowState?.newRowId;
        const isEditing =
          editingRowId === row?.original?.estimationMepSheetDetailId ||
          isNewRow;
        return isEditing ? (
          <FmTextField
            name="lineItem"
            control={control}
            label="Line Item"
            onBlur={handleBlur}
          />
        ) : (
          <div
            onClick={() => {
              handleEdit(row?.original);
              setEditingRowIndex(row?.index);
            }}
          >
            {row?.original?.lineItem}
          </div>
        );
      },
    },

    {
      accessorKey: "lineItemShort",
      header: "Line Item Short",
      enableEditing: true,
      Cell: ({ row }) => {
        const isNewRow =
          row?.original?.estimationMepSheetId === rowState?.newRowId;
        const isEditing =
          editingRowId === row?.original?.estimationMepSheetDetailId ||
          isNewRow;
        return isEditing ? (
          <FmTextField
            name="lineItemShort"
            control={control}
            label="Line Item Short"
            onBlur={handleBlur}
          />
        ) : (
          <div
            onClick={() => {
              handleEdit(row?.original);
              setEditingRowIndex(row?.index);
            }}
          >
            {row?.original?.lineItemShort}
          </div>
        );
      },
    },
    {
      accessorKey: "quantity",
      header: "Quantity",
      enableEditing: true,
      Cell: ({ row }) => {
        const isNewRow =
          row?.original?.estimationMepSheetId === rowState?.newRowId;
        const isEditing =
          editingRowId === row?.original?.estimationMepSheetDetailId ||
          isNewRow;
        return isEditing ? (
          <FmTextField
            name="quantity"
            control={control}
            label="Quantity"
            pattern="Number"
            defaultValue={editingRowData?.quantity || ""}
            onChange={(e) => {
              setEditingRowData((prev) => ({
                ...prev,
                quantity: e.target.value,
              }));
            }}
            onBlur={handleBlur}
          />
        ) : (
          <div
            onClick={() => {
              handleEdit(row?.original);
              setEditingRowIndex(row?.index);
            }}
          >
            {row?.original?.quantity}
          </div>
        );
      },
    },
    {
      accessorKey: "currencyId",
      header: "Currency",
      enableEditing: true,
      Cell: ({ row }) => {
        const isNewRow =
          row?.original?.estimationMepSheetId === rowState?.newRowId;
        const isEditing =
          editingRowId === row?.original?.estimationMepSheetDetailId ||
          isNewRow;
        return isEditing ? (
          <FmAutoComplete
            name="currencyId"
            control={control}
            options={currencies}
            label="currency"
            displayField="currencyName"
            optionFields={["currencyName"]}
            valueKey="currencyId"
            onBlur={handleBlur}
          />
        ) : (
          <div
            onClick={() => {
              handleEdit(row?.original);
              setEditingRowIndex(row?.index);
            }}
          >
            {row?.original?.currencyName || ""}
          </div>
        );
      },
    },
    {
      accessorKey: "unitOfMeasurementId",
      header: "Unit Of Measurement",
      enableEditing: true,
      Cell: ({ row }) => {
        const isNewRow =
          row?.original?.estimationMepSheetId === rowState?.newRowId;
        const isEditing =
          editingRowId === row?.original?.estimationMepSheetDetailId ||
          isNewRow;
        return isEditing ? (
          <FmAutoComplete
            name="unitOfMeasurementId"
            control={control}
            options={unitofmeasurements}
            label="UOM"
            displayField="unitOfMeasurementName"
            optionFields={["unitOfMeasurementName"]}
            valueKey="unitOfMeasurementId"
            onBlur={handleBlur}
          />
        ) : (
          <div
            onClick={() => {
              handleEdit(row?.original);
              setEditingRowIndex(row?.index);
            }}
          >
            {row?.original?.unitOfMeasurementName || ""}
          </div>
        );
      },
    },
    {
      accessorKey: "materialCost",
      header: "Material Cost",
      enableEditing: true,
      Cell: ({ row }) => {
        const isNewRow =
          row?.original?.estimationMepSheetId === rowState?.newRowId;
        const isEditing =
          editingRowId === row?.original?.estimationMepSheetDetailId ||
          isNewRow;
        return isEditing ? (
          <FmTextField
            name="materialCost"
            control={control}
            label="Material Cost"
            pattern="Number"
            defaultValue={editingRowData?.materialCost || ""}
            onChange={(e) => {
              setEditingRowData((prev) => ({
                ...prev,
                materialCost: e.target.value,
              }));
            }}
            onBlur={handleBlur}
          />
        ) : (
          <div
            onClick={() => {
              handleEdit(row?.original);
              setEditingRowIndex(row?.index);
            }}
          >
            {row?.original?.materialCost}
          </div>
        );
      },
    },

    {
      accessorKey: "installationCost",
      header: "Installation Cost",
      enableEditing: true,
      Cell: ({ row }) => {
        const isNewRow =
          row?.original?.estimationMepSheetId === rowState?.newRowId;
        const isEditing =
          editingRowId === row?.original?.estimationMepSheetDetailId ||
          isNewRow;
        return isEditing ? (
          <FmTextField
            name="installationCost"
            control={control}
            label="Installation Cost"
            pattern="Number"
            defaultValue={editingRowData?.installationCost || ""}
            onChange={(e) => {
              setEditingRowData((prev) => ({
                ...prev,
                installationCost: e.target.value,
              }));
            }}
            onBlur={handleBlur}
          />
        ) : (
          <div
            onClick={() => {
              handleEdit(row?.original);
              setEditingRowIndex(row?.index);
            }}
          >
            {row?.original?.installationCost}
          </div>
        );
      },
    },

    {
      accessorKey: "subTotalAmount",
      header: "Sub Total",
      enableEditing: true,
      Cell: ({ row }) => {
        const isNewRow =
          row?.original?.estimationMepSheetId === rowState?.newRowId;
        const isEditing =
          editingRowId === row?.original?.estimationMepSheetDetailId ||
          isNewRow;
        return isEditing ? (
          editingRowData?.subTotalAmount || ""
        ) : (
          <div
            onClick={() => {
              handleEdit(row?.original);
              setEditingRowIndex(row?.index);
            }}
          >
            {row?.original?.subTotalAmount || 0}
          </div>
        );
      },
    },
    // {
    //   accessorKey: 'actualUnitPrice',
    //   header: 'Actual Unit Price',
    //   enableEditing: true,
    //   Cell: ({ row }) => {
    //     const isNewRow =
    //       row?.original?.estimationMepSheetId === rowState?.newRowId;
    //     const isEditing =
    //       editingRowId === row?.original?.estimationMepSheetDetailId || isNewRow;
    //     return isEditing ? (
    //       editingRowData?.unitSellingPrice || ""
    //     ) : (
    //       <div onClick={() => {
    //         handleEdit(row?.original);
    //         setEditingRowIndex(row?.index);
    //       }}>
    //         {row?.original?.actualUnitPrice || 0}
    //       </div>
    //     );
    //   },
    // },
    {
      accessorKey: "actualUnitPrice",
      header: "Actual Unit Price",
      enableEditing: true,
      Cell: ({ row }) => {
        const isNewRow =
          row?.original?.estimationMepSheetId === rowState?.newRowId;
        const isEditing =
          editingRowId === row?.original?.estimationMepSheetDetailId ||
          isNewRow;
        return isEditing ? (
          editingRowData?.actualUnitPrice || 0
        ) : (
          <div
            onClick={() => {
              handleEdit(row?.original);
              setEditingRowIndex(row?.index);
            }}
          >
            {row?.original?.actualUnitPrice || 0}
          </div>
        );
      },
    },
    {
      accessorKey: "overHeadPercent",
      header: "Over Head(%)",
      enableEditing: true,
      Cell: ({ row }) => {
        const isNewRow =
          row?.original?.estimationMepSheetId === rowState?.newRowId;
        const isEditing =
          editingRowId === row?.original?.estimationMepSheetDetailId ||
          isNewRow;
        return isEditing ? (
          <FmTextField
            name="overHeadPercent"
            control={control}
            label="Over Head(%)"
            pattern="Number"
            defaultValue={editingRowData?.overHeadPercent || ""}
            onChange={(e) => {
              setEditingRowData((prev) => ({
                ...prev,
                overHeadPercent: e.target.value,
              }));
            }}
            onBlur={handleBlur}
          />
        ) : (
          <div
            onClick={() => {
              handleEdit(row?.original);
              setEditingRowIndex(row?.index);
            }}
          >
            {row?.original?.overHeadPercent}
          </div>
        );
      },
    },
    {
      accessorKey: "overHeadUnitPrice",
      header: "Over Head With Unit Price",
      enableEditing: true,
      Cell: ({ row }) => {
        const isNewRow =
          row?.original?.estimationMepSheetId === rowState?.newRowId;
        const isEditing =
          editingRowId === row?.original?.estimationMepSheetDetailId ||
          isNewRow;
        return isEditing ? (
          editingRowData?.overHeadUnitPrice || 0
        ) : (
          <div
            onClick={() => {
              handleEdit(row?.original);
              setEditingRowIndex(row?.index);
            }}
          >
            {row?.original?.overHeadUnitPrice || 0}
          </div>
        );
      },
    },
    {
      accessorKey: "contingencyPercent",
      header: "Contingency (%)",
      enableEditing: true,
      Cell: ({ row }) => {
        const isNewRow =
          row?.original?.estimationMepSheetId === rowState?.newRowId;
        const isEditing =
          editingRowId === row?.original?.estimationMepSheetDetailId ||
          isNewRow;
        return isEditing ? (
          <FmTextField
            name="contingencyPercent"
            control={control}
            label="Contingency (%)"
            pattern="Number"
            defaultValue={editingRowData?.contingencyPercent || ""}
            onChange={(e) => {
              setEditingRowData((prev) => ({
                ...prev,
                contingencyPercent: e.target.value,
              }));
            }}
            onBlur={handleBlur}
          />
        ) : (
          <div onClick={() => handleEdit(row?.original)}>
            {row?.original?.contingencyPercent}
          </div>
        );
      },
    },
    {
      accessorKey: "contingencyUnitPrice",
      header: "Contingency With Unit Price",
      enableEditing: true,
      Cell: ({ row }) => {
        const isNewRow =
          row?.original?.estimationMepSheetId === rowState?.newRowId;
        const isEditing =
          editingRowId === row?.original?.estimationMepSheetDetailId ||
          isNewRow;
        return isEditing ? (
          editingRowData?.contingencyUnitPrice || 0
        ) : (
          <div
            onClick={() => {
              handleEdit(row?.original);
              setEditingRowIndex(row?.index);
            }}
          >
            {row?.original?.contingencyUnitPrice || 0}
          </div>
        );
      },
    },
    {
      accessorKey: "markUpPercent",
      header: "Mark Up (%)",
      enableEditing: true,
      Cell: ({ row }) => {
        const isNewRow =
          row?.original?.estimationMepSheetId === rowState?.newRowId;
        const isEditing =
          editingRowId === row?.original?.estimationMepSheetDetailId ||
          isNewRow;
        return isEditing ? (
          <FmTextField
            name="markUpPercent"
            control={control}
            label="Mark Up (%)"
            pattern="Number"
            defaultValue={editingRowData?.markUpPercent || ""}
            onChange={(e) => {
              setEditingRowData((prev) => ({
                ...prev,
                markUpPercent: e.target.value,
              }));
            }}
            onBlur={handleBlur}
          />
        ) : (
          <div
            onClick={() => {
              handleEdit(row?.original);
              setEditingRowIndex(row?.index);
            }}
          >
            {row?.original?.markUpPercent}
          </div>
        );
      },
    },
    {
      accessorKey: "markupUnitPrice",
      header: "Mark Up With Unit Price",
      enableEditing: true,
      Cell: ({ row }) => {
        const isNewRow =
          row?.original?.estimationMepSheetId === rowState?.newRowId;
        const isEditing =
          editingRowId === row?.original?.estimationMepSheetDetailId ||
          isNewRow;
        return isEditing ? (
          editingRowData?.markupUnitPrice || 0
        ) : (
          <div
            onClick={() => {
              handleEdit(row?.original);
              setEditingRowIndex(row?.index);
            }}
          >
            {row?.original?.markupUnitPrice || 0}
          </div>
        );
      },
    },
    {
      accessorKey: "discountPercent",
      header: "Discount (%)",
      enableEditing: true,
      Cell: ({ row }) => {
        const isNewRow =
          row?.original?.estimationMepSheetId === rowState?.newRowId;
        const isEditing =
          editingRowId === row?.original?.estimationMepSheetDetailId ||
          isNewRow;
        return isEditing ? (
          <FmTextField
            name="discountPercent"
            control={control}
            label="Discount (%)"
            pattern="Percentage"
            defaultValue={editingRowData?.discountPercent || 0}
            onChange={(e) => {
              setEditingRowData((prev) => ({
                ...prev,
                discountPercent: e.target.value,
              }));
            }}
            onBlur={handleBlur}
          />
        ) : (
          <div
            onClick={() => {
              handleEdit(row?.original);
              setEditingRowIndex(row?.index);
            }}
          >
            {row?.original?.discountPercent}
          </div>
        );
      },
    },
    {
      accessorKey: "discountUnitPrice",
      header: "Discount With Unit Price",
      enableEditing: true,
      Cell: ({ row }) => {
        const isNewRow =
          row?.original?.estimationMepSheetId === rowState?.newRowId;
        const isEditing =
          editingRowId === row?.original?.estimationMepSheetDetailId ||
          isNewRow;
        return (
          <div
            onClick={() => {
              handleEdit(row?.original);
              setEditingRowIndex(row?.index);
            }}
          >
            {isEditing
              ? editingRowData?.discountUnitPrice || 0
              : row?.original?.discountUnitPrice || 0}
          </div>
        );
      },
    },
    {
      accessorKey: "negotiationPercent",
      header: "Negotiation (%)",
      enableEditing: true,
      Cell: ({ row }) => {
        const isNewRow =
          row?.original?.estimationMepSheetId === rowState?.newRowId;
        const isEditing =
          editingRowId === row?.original?.estimationMepSheetDetailId ||
          isNewRow;
        return isEditing ? (
          <FmTextField
            name="negotiationPercent"
            control={control}
            label="Negotiation (%)"
            pattern="Percentage"
            defaultValue={editingRowData?.negotiationPercent || ""}
            onChange={(e) => {
              setEditingRowData((prev) => ({
                ...prev,
                negotiationPercent: e.target.value,
              }));
            }}
            onBlur={handleBlurAndFetch}
          />
        ) : (
          <div
            onClick={() => {
              handleEdit(row?.original);
              setEditingRowIndex(row?.index);
            }}
          >
            {row?.original?.negotiationPercent}
          </div>
        );
      },
    },
    {
      accessorKey: "negotiationUnitPrice",
      header: "Negotiation With Unit Price",
      enableEditing: true,
      Cell: ({ row }) => {
        const isNewRow =
          row?.original?.estimationMepSheetId === rowState?.newRowId;
        const isEditing =
          editingRowId === row?.original?.estimationMepSheetDetailId ||
          isNewRow;
        return isEditing ? (
          editingRowData?.negotiationUnitPrice || 0
        ) : (
          <div onClick={() => handleEdit(row?.original)}>
            {row?.original?.negotiationUnitPrice}
          </div>
        );
      },
    },
    {
      accessorKey: "unitPrice",
      header: "Unit Price",
      enableEditing: true,
      Cell: ({ row }) => {
        const isNewRow =
          row?.original?.estimationMepSheetId === rowState?.newRowId;
        const isEditing =
          editingRowId === row?.original?.estimationMepSheetDetailId ||
          isNewRow;
        return (
          <div
            onClick={() => {
              handleEdit(cell?.row?.original);
              setEditingRowIndex(cell?.row?.index);
            }}
          >
            {isEditing
              ? editingRowData?.materialCost || 0
              : row?.original?.materialCost || 0}
          </div>
        );
      },
    },
    {
      accessorKey: "totalMepAmount",
      header: "Total MEP Amount",
      enableEditing: true,
      Cell: ({ row }) => {
        const isNewRow =
          row?.original?.estimationMepSheetId === rowState?.newRowId;
        const isEditing =
          editingRowId === row?.original?.estimationMepSheetDetailId ||
          isNewRow;
        return (
          <div onDoubleClick={() => handleEdit(row?.original)}>
            {isEditing
              ? editingRowData?.totalMepAmount || 0
              : row?.original?.totalMepAmount || 0}
          </div>
        );
      },
    },

    {
      header: "Action",
      size: 60,
      // enableColumnPinning: true,
      // enableEditing: false,
      // enableSorting: false,
      Cell: ({ row, table }) => {
        const isNewRow =
          row?.original?.estimationMepSheetId === rowState?.newRowId;

        const isEditing =
          editingRowId === row?.original?.estimationMepSheetDetailId ||
          isNewRow;

        // Determine if the current row is the last row in the table
        const isLastRow = row.index === rows[0]?.estimationMepSheetDetails?.length - 1;

        return (
          <Box className="flex gap-2">
            {isEditing ? (
              <>
                {/* <Button onClick={handleSubmit(handleUpdateAMCDetails)}>
                  Save
                </Button> */}
                <Button onClick={() => handleCancel(row)}>Close</Button>
                {isLastRow && rowState?.isCreatingRow === false && (
                  <Button
                    onFocus={() => {
                      handleCancelAndAddNewRow(row);
                    }}
                  ></Button>
                )}
                {isLastRow && rowState?.isCreatingRow === true && (
                  <Button
                    onFocus={() => {
                      handleAddNewRow();
                    }}
                  ></Button>
                )}
              </>
            ) : (
              <Box className="flex gap-2">
                <TableActionButtons
                  Actions={ActionData.map((action) => ({
                    ...action,
                    onClick: () => action.onClick(row?.original),
                  }))}
                />
                {/* Show Add button only for the last row */}
                {isLastRow && (
                  <Button
                    variant="contained"
                    color="primary"
                    className="px-3 p-1 ms-3"
                    onClick={() => {
                      setEditingRowIndex(row?.index + 1);
                      handleAddNewRow(row);
                    }}
                  >
                    Add
                  </Button>
                )}
              </Box>
            )}
          </Box>
        );
      },
    },
  ];

  return (
    <DashboardLayout
      title="View Estimation Sheet MEP"
      actionButtons={
        <BackToButton
          title="Back to List"
          onClick={() => navigate(-1)}
          className="px-2 mb-2"
        />
      }
      titleReverse
    >
      <Box>
        <GlassCard className="p-4 mb-5">
          <Grid container spacing={2}>
            <Grid item md={3}>
              <Stack spacing={0.5}>
                <Box>
                  <span className="bg-blue-200 text-blue-700 inline-block px-2 rounded-md text-center">
                    Tender No :{estimationMEP?.tenderNumber}
                  </span>
                </Box>
                <h3 className="font-bold text-xl">
                  {estimationMEP?.tenderName}
                </h3>
                <p>
                  <FmViewDateField
                    title="Enquiry Date"
                    value={estimationMEP?.dateOfEnquiry}
                  />
                  <br /> Client : {estimationMEP?.clientName}
                </p>
              </Stack>
            </Grid>
            <Grid item md={3}>
              <Stack spacing={1}>
                <ViewField
                  label="Building Name"
                  title={estimationMEP?.buildingName}
                />
                <FmViewDateField
                  title="Tender Submission Date"
                  value={estimationMEP?.tenderSubmissionDate || ""}
                />
              </Stack>
            </Grid>
            <Grid item md={3}>
              <Stack spacing={1}>
                <ViewField
                  label="Tender Bond/Bidbond Required"
                  title={estimationMEP?.bidBond === true ? "Yes" : "No"}
                />
                <ViewField
                  label="Fixed Tender Bond/Bidbond Value"
                  title={estimationMEP?.bidBondValue}
                />
              </Stack>
            </Grid>
            <Grid item md={3}>
              <Stack spacing={1}>
                <ViewField
                  label="Site Visit Done By"
                  title={estimationMEP?.sitVisitDoneByName}
                />
                <ViewField
                  label="Prepared By"
                  title={estimationMEP?.preparedByName}
                />
              </Stack>
            </Grid>
          </Grid>
        </GlassCard>
        {/* Glass Card */}
        <GlassCard className="overflow-hidden">
          {/* New Table */}

          <FmMRTDataTable
            muiTableContainerProps={{
              sx: {
                maxHeight: "300px",
              },
            }}
            columns={columns}
            enableRowSelection={false}
            setRowSelected={setRowSelection}
            rows={rows[0]?.estimationMepSheetDetails || []}
            onCreateRow={handleAddNewRow}
            isCreatingRow={rowState?.isCreatingRow}
            rowCount={rows[0]?.estimationMepSheetDetails?.length}
            editingRow={editingRowId}
            pagination={pagination}
            setSearchKeyword={setSearchKeyword}
            sorting={sorting}
            columnFilters={columnFilters}
            globalFilter={globalFilter}
            setPagination={setPagination}
            setSorting={setSorting}
            setColumnFilters={setColumnFilters}
            setGlobalFilter={setGlobalFilter}
            isLoading={isLoading}
            fetchData={fetchData}
            disableNewButton={false}
            setSearchKeyword={setSearchKeyword}
            searchKeyword={searchKeyword}
            disableNewButton={rows[0]?.estimationMepSheetDetails?.length === 0}
            renderTopToolbarCustomActionsChildrens={
              <Box className="flex gap-2 items-center me-2">
                <Button
                  variant="outlined"
                  color="primary"
                  className="py-1 px-4"
                  onClick={() => {
                    setOpenUpload(true);
                    setUploadedfileData(null);
                  }}
                >
                  Import MEP Details
                </Button>
                <Button
                  variant="outlined"
                  color="primary"
                  className="py-1 px-4"
                  onClick={handleExportMEP}
                >
                  Export MEP Details
                </Button>
              </Box>
            }
          />
        </GlassCard>
        {!totalBoxLoader && (
          <TotalBox
            amcSheetsPercent={amcSheetsPercent}
            total={amcSubtotal != 0 ? amcSubtotal : total}
            setAmcSheetsPercent={setAmcSheetsPercent}
            handleTotalSubmit={handleTotalSubmit}
          />
        )}{" "}
      </Box>
      <Dialogs
        productView={productView}
        setProductView={setProductView}
        viewData={viewData}
      />
      <MuiDialogOne
        open={openUpload}
        onClose={() => setOpenUpload(false)}
        title="Import MEP Details"
        subHead="Upload your amc details Xlsx file"
      >
        <FmFileInput
          name="file"
          documentName={uploadedfileData?.originalFileName || ""}
          onChange={handleFileUpload}
          loading={uploadLoading}
        />
        <ActionButtons
          onSubmit={handleSubmitImport}
          onCancel={() => setOpenUpload(false)}
          onReset={reset}
          submitLoading={createLoading}
          cancelLoading={false}
        />
      </MuiDialogOne>
    </DashboardLayout>
  );
};

export default ViewEstimationMEP;
