import React from 'react';
import DashboardLayout from '../../../../components/DashboardLayout.tsx';
import GlassCard from '../../../../components/small/GlassCard.tsx';
import { useNavigate, useParams } from 'react-router-dom';
import CommonView from '../../../../components/helpers/CommonView.jsx';
import DynamicViewFields from '../../../../components/helpers/DynamicViewFields.jsx';
import { CreateButton } from '../../../../components/small/Buttons.jsx';
import ActionButtons from '../../../../components/_form/ActionButtons.tsx';

const ViewHiredOwnVehicles = () => {
  const navigate = useNavigate();
  const { id } = useParams();

  const excludeKeys = [
    'contactPersonId',
    'contractId',
    'divisionId',
    'driverId',
    'hiredVehicleId',
    'locationId',
    'projectId',
    'siteId',
    'stationId',
    'supplierId',
    'vehicleTypeId',
    'createdDate',
    'updatedDate',
    'status',
  ];

  return (
    <DashboardLayout
      title='View Hired Vehicles'
      actionButtons={
        <CreateButton
          name='Create'
          onClick={() => navigate('/admin/vehicles/hired-vehicles/create')}
        />
      }
    >
      <GlassCard className='p-5'>
        <CommonView
          url='hiredVehicles'
          id={id}
          excludeKeys={excludeKeys}
          renderFields={(data, fields) => (
            <DynamicViewFields data={data} fields={fields} />
          )}
        />
        <ActionButtons
          cancelLoading={false}
          cancelText='Go Back'
          submitText='Edit'
          onSubmit={() => navigate(`/admin/vehicles/hired-vehicles/edit/${id}`)}
          onCancel={() => navigate('/admin/vehicles/hired-vehicles')}
        />
      </GlassCard>
    </DashboardLayout>
  );
};

export default ViewHiredOwnVehicles;
