import React, { useEffect, useMemo, useState } from 'react';
import DashboardLayout from '../../../../components/DashboardLayout.tsx';
import PurchaseVoucherList from './PurchaseVoucherList.tsx';
import { useNavigate } from 'react-router-dom';
import useTableLogic from '../../../../components/helpers/MRTUseTableLogic.tsx';
import { Box } from '@mui/material';
import { CreateButton } from '../../../../components/small/Buttons.jsx';
import { useSelector } from 'react-redux';
import { findNavByName } from '../../../../utils/navUtils.ts';
import FASalesInvoiceGridsApi from '../../../../components/dashboard/Financial/FASalesInvoiceGridsApi.tsx';


const PurchaseVoucher = () => {
  const [onFilter, setOnFilter] = useState({
    vendorId: 0,
    purchaseVoucherStatusId: 0,
    checkboxSelection: false,
    showChip: false,
  });

  const [rowSelect, setRowSelect] = useState([]);

  useEffect(() => {
    document.title = `Purchase Vouchers | Accounts Payable | Financial`;
  }, []);

  const getPurchaseVoucherId =
    onFilter?.purchaseVoucherStatusId === -1
      ? 0
      : onFilter?.purchaseVoucherStatusId;
  const getOverDue = onFilter?.purchaseVoucherStatusId === -1 ? true : false;
  const {
    rows,
    isLoading,
    pagination,
    sorting,
    columnFilters,
    globalFilter,
    tableRecordCounts,
    setPagination,
    setSorting,
    setColumnFilters,
    setGlobalFilter,
    fetchData,
    setSearchKeyword,
    searchKeyword,
  } = useTableLogic(
    `purchasevouchers?vendorId=${onFilter?.vendorId}&purchaseVoucherStatusId=${getPurchaseVoucherId}&overDue=${getOverDue}`
  );
  // useEffect(() => {
  //   if (onFilter?.vendorId !== undefined && onFilter?.vendorId !== 0 && onFilter?.purchaseVoucherStatusId !== undefined) {
  //     fetchData();
  //   }
  // }, [onFilter?.vendorId, onFilter?.purchaseVoucherStatusId]);
  useEffect(() => {
    fetchData();
  }, [onFilter]);

  const navigate = useNavigate();

  const menu = useSelector((state) => state?.menu?.items);

  // Flatten menu only once and store it for reuse
  const SubMenu = useMemo(() => {
    return menu?.map((item) => item?.menus?.flat()).flat() || [];
  }, [menu]);

  // Use findNavByName to get the "Accounts Receivable" menu or its child menus
  const result = useMemo(
    () => findNavByName(SubMenu, 'Financial', 'Accounts Payable'),
    [SubMenu]
  );

  const handleStatusChange = (status: number) => {
    setOnFilter({ ...onFilter, purchaseVoucherStatusId: status });
  };

  return (
    <>
      <DashboardLayout
        title={'Purchase Vouchers'}
        hasSubmenu
        menu={result}
        actionButtons={
          <CreateButton name='Create' onClick={() => navigate('create')} />
        }
      >
        <Box className='flex flex-col gap-2 h-full'>
          <FASalesInvoiceGridsApi
            handleStatusChange={handleStatusChange}
            api='purchasevouchers'
          />
          <PurchaseVoucherList
            rows={rows}
            isLoading={isLoading}
            pagination={pagination}
            sorting={sorting}
            columnFilters={columnFilters}
            globalFilter={globalFilter}
            tableRecordCounts={tableRecordCounts}
            setPagination={setPagination}
            setSorting={setSorting}
            setColumnFilters={setColumnFilters}
            setGlobalFilter={setGlobalFilter}
            fetchData={fetchData}
            setSearchKeyword={setSearchKeyword}
            searchKeyword={searchKeyword}
            showSerialNumber={true}
            enableRowSelection={onFilter?.checkboxSelection}
            onFilter={onFilter}
            setOnFilter={setOnFilter}
            rowSelection={rowSelect}
            setRowSelection={setRowSelect}
          />
        </Box>
      </DashboardLayout>
    </>
  );
};

export default PurchaseVoucher;
