import React from 'react';

const ViewFieldsHori = ({ label, value, fwRemove }) => {
  return (
    <div className='flex gap-1'>
      <span className={!fwRemove ? 'font-semibold' : ''}>{label}</span> :{' '}
      {value}
    </div>
  );
};

export default ViewFieldsHori;
