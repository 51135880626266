import React, { useEffect, useState } from 'react';
import DashboardLayout from '../../../components/DashboardLayout.tsx';
import PageHeader from '../../../components/PageHeader';
import DataTable from '../../../components/DataTable.tsx';
import GlassCard from '../../../components/small/GlassCard.tsx';
import TableActionButtons from '../../../components/_form/TableActionButtons';
import { ViewField, StatusBadge } from '../../../components/_form/FormElements';
import { Icon } from '@iconify/react';
import { Box, Grid } from '@mui/material';
import { CreateButton } from '../../../components/small/Buttons';
import TableSearch from '../../../components/_form/TableSearch.jsx';
import MuiDialogOne from '../../../components/_mui/MuiDialogOne';
import { Nav } from '../../../utils';
import { Nationality } from '../../../interfaces/EmployeeMasterInterfaces';
import { useForm } from 'react-hook-form';
import Crud_Service from '../../../apis/CrudService';
import { useTranslation } from 'react-i18next';
import CommonLoader from '../../../components/page/CommonLoader';
import useLoader from '../../../components/helpers/UseLoader.tsx';
import ActionButtons from '../../../components/_form/ActionButtons.tsx';
import useTableLogic from '../../../components/helpers/UseTableLogic.tsx';
import FmTextField from '../../../components/_mui/FmTextField.tsx';
import FmAutoComplete from '../../../components/_mui/FmAutoComplete.tsx';
import DataTableToolbarButtons from '../../../components/helpers/DataTableToolbarButtons.tsx';
import apiInstance from '../../../apis/ApiService.jsx';
import { toast } from 'react-toastify';

const NationalityMasters = () => {
  const [openCreate, setOpenCreate] = React.useState(false);
  const [openView, setOpenView] = React.useState(false);
  const [loading, setLoader] = useState(false);
  const crud = new Crud_Service();
  const [selectedRow, setSelectedRow] = useState<Nationality>();
  const [openEdit, setOpenEdit] = React.useState(false);
  const { register, handleSubmit, setValue, control, reset } =
    useForm<Nationality>();
  const {
    createLoading,
    startCreateLoading,
    stopCreateLoading,
    editLoading,
    startEditLoading,
    stopEditLoading,
  } = useLoader();
  const {
    rows,
    pageSize,
    pageNumber,
    setSearchKeyword,
    isLoading,
    tableRecordCounts,
    searchKeyword,
    handlePaginationModelChange,
    handleSortModelChange,
    fetchData,
  } = useTableLogic('nationalities');
  const { t } = useTranslation();
  const statusOptions = [
    { statusId: 2, statusName: 'Active' },
    { statusId: 1, statusName: 'Inactive' },
  ];

  useEffect(() => {
    document.title = `HRMS-Nationality`;
  }, []);

  const handleSubmitNationalities = async (values) => {
    startCreateLoading();
    await crud.create('nationalities', values, (err, res) => {
      if (res?.status === 201) {
        toast.success('Nationality Created Successfully');
        stopCreateLoading();
        setOpenCreate(false);
        fetchData();
      } else {
        stopCreateLoading();
        setOpenCreate(true);
      }
    });
  };

  const handleCreateClickOpen = () => {
    reset();
    setOpenCreate(true);
  };
  const handleCreateClose = () => {
    reset();
    setOpenCreate(false);
  };

  const handleViewClickOpen = async (props) => {
    setOpenView(true);
    setLoader(true);
    await crud.getSingle('nationalities', props?.nationalityId, (err, res) => {
      if (res?.status === 200) {
        setSelectedRow(res?.data);
        setLoader(false);
      } else {
      }
    });
  };

  const handleViewClose = () => {
    setOpenView(false);
  };
  const handleEditOpen = async (props) => {
    setOpenEdit(true);
    setLoader(true);
    await crud.getSingle('nationalities', props?.nationalityId, (err, res) => {
      if (res?.status === 200) {
        Object.entries({
          ...res?.data,
        }).forEach(([key, value]) => {
          setValue(key, value);
        });
        setLoader(false);
      } else {
        setLoader(true);
      }
    });
  };
  const handleEditNationalities = async (values) => {
    startEditLoading();
    await crud.update(
      'nationalities',
      values?.nationalityId,
      values,
      (err, res) => {
        if (res?.status === 200) {
          toast.success('Nationality Updated Successfully');
          stopEditLoading();
          setOpenEdit(false);
          fetchData();
        } else {
          stopEditLoading();
          setOpenEdit(true);
        }
      }
    );
  };

  const handleDelete = async (props) => {
    await crud.remove('nationalities', props?.nationalityId, (err, res) => {
      if (res?.status === 204) {
        toast.success('Nationality Deleted Successfully');
        fetchData();
      } else {
      }
    });
  };

  const counters = [
    {
      name: 'All',
      count: tableRecordCounts?.total || 0,
    },
    {
      name: 'Active',
      count: tableRecordCounts?.active || 0,
    },
    {
      name: 'Deactivated',
      count: tableRecordCounts?.inActive || 0,
    },
  ];

  const ActionData = [
    {
      name: 'View',
      icon: <Icon icon='solar:eye-linear' />,
      onClick: (props) => handleViewClickOpen(props),
    },
    {
      name: 'Edit',
      icon: <Icon icon='solar:pen-new-round-linear' />,
      onClick: (props) => handleEditOpen(props),
    },
    {
      name: 'Delete',
      icon: <Icon icon='solar:trash-bin-2-linear' />,
      onClick: (props) => handleDelete(props),
      danger: true,
    },
  ];

  const columns = [
    {
      field: 'action',
      headerName: '',
      width: 140,
      sortable: false,
      disableClickEventBubbling: false,

      renderCell: (params) => {
        return (
          <TableActionButtons
            Actions={ActionData?.map((action) => ({
              ...action,
              onClick: () => action.onClick(params.row),
            }))}
            selectedRow={params.row}
            setSelectedRow={setSelectedRow}
          />
        );
      },
    },
    {
      field: 'nationalityCode',
      headerName: 'Code',
      flex: 1,
    },
    {
      field: 'nationalityName',
      headerName: 'Name',
      flex: 1,
    },
    {
      field: 'status',
      headerName: 'Status',
      flex: 1,
      renderCell: (params) => {
        return (
          <StatusBadge
            title={params.value === 2 ? 'Active' : 'Inactive'}
            type={params.value === 2 ? 'green' : 'red'}
          />
        );
      },
    },
  ];

  const downloadNationalities = async () => {
    const downloadUrl = await apiInstance.getFiles('nationalities/exportexcel');

    const link = document.createElement('a');
    link.href = downloadUrl;
    link.setAttribute('download', 'Nationality.xlsx');
    document.body.appendChild(link);
    link.click();
    document.body.removeChild(link);
  };

  return (
    <DashboardLayout
      title='Nationality'
      actionButtons={
        <>
          <PageHeader counters={counters} />
        </>
      }
      hasSubmenu
      menu={Nav[0].child[5].children}
      parentMenu={Nav[0].child[5].name}
    >
      <GlassCard className='h-full'>
        <Box className='h-full'>
          <DataTable
            rows={rows}
            columns={columns}
            loading={isLoading}
            getRowClassName={(params) =>
              `${params.row.status === 0 && 'bg-red-50'}`
            }
            sortingMode='server'
            paginationMode='server'
            onPaginationModelChange={(model) =>
              handlePaginationModelChange(model)
            }
            onSortModelChange={(model) => handleSortModelChange(model)}
            page={pageNumber - 1}
            pageSize={pageSize}
            rowCount={tableRecordCounts?.total}
            slots={{
              toolbar: () => (
                <Box
                  className='p-2 w-full flex justify-between items-center'
                  sx={{
                    borderBottom: '1px solid',
                    borderColor: 'border.main',
                  }}
                >
                  <Box sx={{ maxWidth: '250px' }}>
                    <TableSearch
                      placeholder='Search'
                      fullWidth
                      setSearchKeyword={setSearchKeyword}
                      searchValue={searchKeyword}
                    />
                  </Box>
                  <Box className='flex items-center gap-4 justify-end'>
                    <CreateButton name='Create' onClick={handleCreateClickOpen} />
                    <DataTableToolbarButtons
                      disableColumn
                      handleExcelExport={downloadNationalities}
                    />
                  </Box>
                </Box>
              ),
            }}
          />
        </Box>
      </GlassCard>

      {/* create  */}

      <MuiDialogOne
        title='Create'
        open={openCreate}
        onClose={handleCreateClose}
      >
        <form onSubmit={handleSubmit(handleSubmitNationalities)}>
          <Grid container spacing={3}>
            <Grid item md={12}>
              <FmTextField
                name='nationalityName'
                control={control}
                label='Nationality Name'
                rules={{
                  required: 'Nationality Name is required',
                }}
              />
            </Grid>
            <Grid item md={12}>
              <ActionButtons
                onSubmit={handleSubmit(handleSubmitNationalities)}
                onReset={reset}
                onCancel={handleCreateClose}
                submitLoading={createLoading}
                cancelLoading={false}
              />
            </Grid>
          </Grid>
        </form>
      </MuiDialogOne>

      {/* view */}

      <MuiDialogOne
        title='View Details'
        open={openView}
        onClose={handleViewClose}
      >
        {loading ? (
          <CommonLoader />
        ) : (
          <Grid container spacing={2}>
            <Grid item md={6}>
              <ViewField
                label='Nationality Code'
                title={selectedRow?.nationalityCode}
              />
            </Grid>
            <Grid item md={6}>
              <ViewField
                label='Nationality Name'
                title={selectedRow?.nationalityName}
              />
            </Grid>
            <Grid item md={6}>
              <ViewField
                label='Status'
                title={selectedRow?.status === 2 ? 'Active' : 'Inactive'}
              />
            </Grid>
          </Grid>
        )}
      </MuiDialogOne>

      {/*EDIT*/}
      <MuiDialogOne
        title='Edit'
        open={openEdit}
        onClose={() => setOpenEdit(false)}
      >
        {loading ? (
          <CommonLoader />
        ) : (
          <form onSubmit={handleSubmit(handleEditNationalities)}>
            <Grid container spacing={3}>
              <Grid item md={12}>
                <FmTextField
                  name='nationalityName'
                  control={control}
                  label='Nationality Name'
                  rules={{
                    required: 'Nationality Name is required',
                  }}
                />
              </Grid>
              <Grid item md={12}>
                <FmAutoComplete
                  name='status'
                  control={control}
                  options={statusOptions}
                  label='Status'
                  displayField='statusName'
                  optionFields={['statusName']}
                  valueKey='statusId'
                />
              </Grid>
              <Grid item md={12}>
                <ActionButtons
                  onSubmit={handleSubmit(handleEditNationalities)}
                  onReset={reset}
                  onCancel={() => setOpenEdit(false)}
                  submitLoading={editLoading}
                  cancelLoading={false}
                  submitText='Update'
                />
              </Grid>
            </Grid>
          </form>
        )}
      </MuiDialogOne>
    </DashboardLayout>
  );
};

export default NationalityMasters;
