import { yupResolver } from '@hookform/resolvers/yup';
import { Icon } from '@iconify/react/dist/iconify.js';
import { Box, Button, Divider, Stack } from '@mui/material';
import dayjs from 'dayjs';
import React, { useCallback, useEffect, useRef, useState } from 'react';
import { useForm } from 'react-hook-form';
import { useHotkeys } from 'react-hotkeys-hook';
import { useParams } from 'react-router-dom';
import { toast } from 'react-toastify';
import apiInstance from '../../../../../apis/ApiService.jsx';
import Crud_Service from '../../../../../apis/CrudService.jsx';
import { ViewTable } from '../../../../../components/_form/FormElements.jsx';
import TableActionButtons from '../../../../../components/_form/TableActionButtons.jsx';
import FmAutoComplete from '../../../../../components/_mui/FmAutoComplete.tsx';
import FmFileInput from '../../../../../components/_mui/FmFileInput.tsx';
import FmSearchableSelect from '../../../../../components/_mui/FmSearchableSelect.tsx';
import FmTextField from '../../../../../components/_mui/FmTextField.tsx';
import MuiDialogOne from '../../../../../components/_mui/MuiDialogOne.jsx';
import FmMRTDataTable from '../../../../../components/FmMRTDataTable.tsx';
import useTableLogic from '../../../../../components/helpers/MRTUseTableLogic.tsx';
import useCommonFetchApi from '../../../../../components/helpers/useCommonFetchApi.tsx';
import useLoader from '../../../../../components/helpers/UseLoader.tsx';
import GlassCard from '../../../../../components/small/GlassCard.tsx';
import { InternalInvoiceItemSchema } from '../../../../../utils/CommonvalidationSchemas.tsx';
import DataGridHeaderTooltip from '../../../../../components/datagrid/DataGridHeaderTooltip.tsx';
import CurrencyView from '../../../../../components/_mui/FmCurrencyView.tsx';
import FmCurrencyTextField from '../../../../../components/_mui/FmCurrencyTextField.tsx';

const EditListInternalInvoicesAR = () => {
  const [viewDetails, setViewDetails] = useState(false);
  const [getData, setGetData] = useState([]);
  const { id } = useParams();
  const {
    control,
    reset,
    setValue,
    formState: { isValid },
    getValues,
    watch,
  } = useForm({
    resolver: yupResolver(InternalInvoiceItemSchema),
    mode: 'all',
  });
  const {
    rows,
    setRows,
    isLoading,
    pagination,
    sorting,
    columnFilters,
    globalFilter,
    tableRecordCounts,
    setPagination,
    setSorting,
    setColumnFilters,
    setGlobalFilter,
    fetchData,
    setSearchKeyword,
    searchKeyword,
  } = useTableLogic(`internalinvoiceitems?internalInvoiceId=${id}`);
  const [editingRowId, setEditingRowId] = useState(null);
  const [editingRowData, setEditingRowData] = useState(null);
  const [uploadLoading, setUploadLoading] = useState(false);
  const [filesData, setFilesData] = useState({
    fileId: null,
    originalFileName: '',
  });
  const [openUpload, setOpenUpload] = useState(false);
  const { createLoading, stopCreateLoading, startCreateLoading } = useLoader();
  const [rowState, setRowState] = useState({
    editingRowId: null,
    newRowId: null,
    isCreatingRow: false,
  });
  const firstColumnInputRef = useRef(null);
  const [stateError, setStateError] = useState(false);
  const [editingRowIndex, setEditingRowIndex] = useState(null);
  const [loader, setLoader] = useState(false);
  const crud = new Crud_Service();
  const [selectedProject, setSelectedProject] = React.useState({});
  const { data: unitofmeasurements } = useCommonFetchApi('unitofmeasurements');
  const [selectedWorkorder, setSelectedWorkOrder] = useState({});
  const [selectedContract, setSelectedContract] = useState({});
  const [tableData, setTableData] = useState(rows); // initialData is your table's initial rows
  const [formValues, setFormValues] = useState({});

  const rate = watch('rate', 0);
  const discountPercent = watch('discount', 0);
  const tax = watch('tax', 0);
  const vat = watch('vat', 0);
  const [billingValues, setBillingValues] = useState([
    { name: 'Quantity', value: 0 },
    { name: 'Gross', value: '-' },
    { name: 'Discount', value: 0 },
    { name: 'VAT', value: 0 },
    { name: 'Total', value: 0 },
  ]);

  useEffect(() => {
    selectedContract && setValue('dueDate', selectedContract?.contractEndDate);
  }, [selectedContract]);

  useEffect(() => {
    setTableData(rows);
  }, [rows]);

  const GetViewDetails = (value) => {
    const getvalueArray = getData[value];
    return getvalueArray;
  };
  const [selectedSO, setSelectedSO] = useState({});

  useHotkeys(
    'Enter',
    () => handleAddNewRow(),
    {
      enableOnTags: ['INPUT', 'TEXTAREA'], // Optional: Enable hotkey only when focused on specific tags
    },
    [editingRowIndex, rows, stateError, loader]
  );

  useHotkeys(
    'Tab',
    (event) => {
      if (event.key !== 'Tab') return;
      if (stateError) {
        event.preventDefault();
        return;
      }
      const totalRows = rows.length;
      const nextRowIndex = editingRowIndex + 1;

      if (
        nextRowIndex < totalRows &&
        stateError === false &&
        loader === false
      ) {
        handleEdit(rows[nextRowIndex]);
        setEditingRowIndex(nextRowIndex);
      }
    },
    [editingRowIndex, rows, stateError, loader, isValid]
  );

  const previousValues = useRef(getValues());

  const handleBlur = () => {
    const currentValues = getValues();
    const divisionId = getValues('divisionId');
    if (!divisionId) {
      firstColumnInputRef.current.focus();
      return;
    }

    const hasChanges = Object.keys(currentValues).some(
      (key) => currentValues[key] !== previousValues.current[key]
    );

    if ((hasChanges && isValid) || (hasChanges && editingRowId)) {
      apiCallAsync(currentValues); // Call the API with the new values
      previousValues.current = currentValues; // Update previous values
    }
  };

  //api call
  const apiCallAsync = async (values) => {
    setLoader(true);
    const convertedValues = {
      ...values,
      internalInvoiceId: id,
      rate: Number(values?.rate),
      // fileId: filesData?.fileId,
      // dueDate: dayjs(values?.dueDate).format('YYYY-MM-DD'),
    };

    const updateCombinedData = {
      ...values,
      internalInvoiceId: id,
      rate: Number(values?.rate) || 0,
      // dueDate: dayjs(values?.dueDate).format('YYYY-MM-DD'),
      // fileId: filesData?.fileId,
      status: 2,
    };

    if (editingRowId === null) {
      await crud.create('internalinvoiceitems', convertedValues, (err, res) => {
        if (res?.status === 201) {
          setEditingRowId(res?.data?.internalInvoiceItemId);
        } else {
        }
      });
    } else {
      await crud.update(
        'internalinvoiceitems',
        editingRowId,
        updateCombinedData,
        (err, res) => {
          if (res?.status === 200) {
            if (rows.some((row) => row.isNew)) {
              const updatedRow = res?.data;
              const updatedRows = [...rows];
              updatedRows[editingRowIndex] = {
                ...updatedRow,
                isEditable: true,
                isNew: true,
              };
              setRows(updatedRows);
              setStateError(false);
              setLoader(false);
            } else {
              setStateError(false);
              setLoader(false);
              const updatedRow = res?.data;
              const updatedRows = [...rows];

              updatedRows[editingRowIndex] = updatedRow;

              setRows(updatedRows);
            }
          } else {
            setStateError(true);
          }
        }
      );
    }
  };

  const handleCancel = (row) => {
    if (row?.original?.internalInvoiceItemId === rowState?.newRowId) {
      setRows((prevRows) => {
        if (prevRows?.length > 0) {
          return prevRows?.slice(0, -1);
        }
        return prevRows;
      });

      setRowState((prevState) => ({
        ...prevState,
        newRowId: null,
        isCreatingRow: false,
      }));
    } else {
      setEditingRowId(null);
    }
    reset();
  };

  const handleEdit = (row) => {
    reset();
    if (rowState?.newRowId) {
      setRows((prevRows) => {
        if (prevRows?.length > 0) {
          return prevRows?.slice(0, -1);
        }
        return prevRows;
      });
      setRowState((prevState) => ({
        ...prevState,
        newRowId: null,
        isCreatingRow: false,
      }));
      // setEditingRowIndex(null);
    }
    setEditingRowId(row?.internalInvoiceItemId);
    Object.entries({
      ...row,
    }).forEach(([key, value]) => {
      setValue(key, value);
    });
    setEditingRowData({ ...row });
  };

  const handleDelete = async (props) => {
    await crud.remove(
      'internalinvoiceitems',
      props?.internalInvoiceItemId,
      (_err, res) => {
        if (res?.status === 204) {
          toast.success('Internal Invoice Item Deleted Successfully');
          fetchData();
        } else {
        }
      }
    );
  };

  useEffect(() => {
    if (editingRowIndex !== null && firstColumnInputRef.current && !isLoading) {
      firstColumnInputRef.current.focus();
    }
  }, [editingRowIndex, isLoading]);

  const ActionData = [
    // {
    //   name: 'Delete',
    //   icon: <Icon icon='mi:delete' />,
    //   onClick: (props) => handleDelete(props),
    //   danger: true,
    // },
    {
      name: 'Delete',
      icon: <Icon icon='mi:delete' />,
      onClick: (props) => {
        handleDelete(props);
      },
      danger: true,
    },
  ];

  //File Uplaod
  const handleFileUpload = async (e) => {
    const files = e.target.files[0];
    setUploadLoading(true);
    if (files) {
      const fileFormData = new FormData();
      fileFormData.append('file', files);
      fileFormData.append('fileTypeId', 54);

      await crud.create(`/files/upload`, fileFormData, (err, res) => {
        if (res?.status === 200) {
          setUploadLoading(false);
          setFilesData(res?.data);
          const values = getValues();
          const combinedData = {
            ...values,
            fileId: res?.data?.fileId,
          };
          apiCallAsync(combinedData);
        } else {
          setUploadLoading(false);
          toast.error('something_Went_Wrong');
        }
      });
    }
  };

  const handleBlurAndFetch = async () => {
    setLoader(true);
    const updatedRow = getValues(); // Get the latest values from the form
    const updatedRows = [...rows]; // Clone the rows[0] to avoid direct mutation

    updatedRows[editingRowIndex] = updatedRow;
    setRows(updatedRows);
    const values = getValues();
    const { internalInvoiceItemId, ...restValues } = values; // Destructure and exclude salesQuotationItemId

    const updateCombinedData = {
      ...restValues,
      status: 2,
      internalInvoiceId: id,
      dueDate: dayjs(values?.dueDate).format('YYYY-MM-DD'),
      fileId: filesData?.fileId,
    };
    await crud.update(
      'internalinvoiceitems',
      editingRowId,
      updateCombinedData,
      (err, res) => {
        if (res?.status === 200) {
          const totalRows = rows.length;
          if (editingRowIndex <= totalRows && rows.some((row) => row.isNew)) {
            const updatedRow = res?.data;
            const updatedRows = [...rows];
            updatedRows[editingRowIndex] = {
              ...updatedRow,
              isEditable: true,
              isNew: true,
            };
            setRows(updatedRows);
            handleAddNewRow();
          } else {
            const updatedRow = res?.data;
            const updatedRows = [...rows];
            updatedRows[editingRowIndex] = {
              ...updatedRow,
              isEditable: false,
              isNew: false,
            };

            setRows(updatedRows);
            setStateError(false);
            setLoader(false);
          }
        } else {
          setStateError(true);
        }
      }
    );
  };

  const handleCancelAndAddNewRow = () => {
    const newId = 'new_row_' + Date.now();
    reset();
    setEditingRowData(null);
    setEditingRowId(null);

    setRowState({
      newRowId: newId,
      isCreatingRow: true,
    });
    setEditingRowIndex(
      editingRowIndex === rows?.length ? editingRowIndex + 1 : rows?.length
    );
    setRows((prevRows) => [
      ...prevRows,
      {
        internalInvoiceItemId: newId,
        internalInvoiceId: id,
        divisionId: '',
        description: '',
        tax: '',
        unitOfMeasurementId: '',
        rate: '',
        gross: '',
        discount: '',
        vat: '',
        remarks: '',
        isEditable: true,
        isNew: true,
      },
    ]);
    reset();
  };

  const calculateTotal = useCallback(() => {
    const rateValue = parseFloat(getValues('rate')) || 0;
    const taxValue = parseFloat(getValues('tax')) || 0;
    const vatValue = parseFloat(getValues('vat')) || 0;
    const discountValue = parseFloat(getValues('discount')) || 0; // Flat discount

    // Step 1: Gross should always be the same as rate
    setValue('gross', rateValue);

    // Step 2: Tax is added to rate, but NOT stored anywhere
    const rateAfterTax = rateValue + rateValue * (taxValue / 100);

    // Step 3: Apply Flat Discount (directly subtracting discount value)
    const rateAfterDiscount = rateAfterTax - discountValue;

    // Step 4: Apply VAT on final discounted value
    const vatAmount = rateAfterDiscount * (vatValue / 100);
    const totalAmount = rateAfterDiscount + vatAmount;

    // Step 5: Update Total
    setValue('total', totalAmount.toFixed(2));
  }, [getValues, setValue]);

  // Automatically update on value changes
  useEffect(() => {
    calculateTotal();
  }, [rate, discountPercent, tax, vat, calculateTotal]);

  const columns = [
    {
      accessorKey: 'divisionId',
      header: 'Division',
      enableEditing: true,
      Cell: ({ row }) => {
        const isNewRow =
          row?.original?.internalInvoiceItemId === rowState?.newRowId;
        const isEditing =
          editingRowId === row?.original?.internalInvoiceItemId || isNewRow;
        return isEditing ? (
          <FmSearchableSelect
            name='divisionId'
            control={control}
            apiUrl='divisions'
            valueField={'divisionId'}
            labelField={['divisionCode', 'divisionName']}
            showField={['divisionCode', 'divisionName']}
            inputRef={firstColumnInputRef}
            onBlur={handleBlur}
            label='Division'
            defaultValue={{
              divisionId: editingRowData?.divisionId,
              divisionName: editingRowData?.divisionName,
            }}
          />
        ) : (
          row?.original?.divisionName || ''
        );
      },
    },
    {
      accessorKey: 'description',
      header: 'Description',
      enableEditing: true,
      Cell: ({ row }) => {
        const isNewRow =
          row?.original?.internalInvoiceItemId === rowState?.newRowId;
        const isEditing =
          editingRowId === row?.original?.internalInvoiceItemId || isNewRow;
        return isEditing ? (
          <FmTextField
            name='description'
            control={control}
            onBlur={handleBlur}
          />
        ) : (
          row?.original?.description
        );
      },
    },

    {
      accessorKey: 'unitOfMeasurementId',
      header: (
        <DataGridHeaderTooltip name={'UOM'} title={'Unit of Measurement'} />
      ),
      enableEditing: true,
      size: 200,
      Cell: ({ row }) => {
        const isNewRow =
          row?.original?.internalInvoiceItemId === rowState?.newRowId;
        const isEditing =
          editingRowId === row?.original?.internalInvoiceItemId || isNewRow;
        return isEditing ? (
          <Box sx={{ width: '100%' }}>
            <FmAutoComplete
              name='unitOfMeasurementId'
              control={control}
              options={unitofmeasurements}
              displayField='unitOfMeasurementName'
              optionFields={['unitOfMeasurementName']}
              valueKey='unitOfMeasurementId'
              onBlur={handleBlur}
            />
          </Box>
        ) : (
          row?.original?.unitOfMeasurementName
        );
      },
    },
    {
      accessorKey: 'rate',
      header: 'Rate',
      size: 100,
      enableEditing: true,
      Cell: ({ row }) => {
        const isNewRow =
          row?.original?.internalInvoiceItemId === rowState?.newRowId;
        const isEditing =
          editingRowId === row?.original?.internalInvoiceItemId || isNewRow;
        return isEditing ? (
          <FmCurrencyTextField
            name='rate'
            control={control}
            label='Rate'
            onBlur={() => {
              handleBlur();
              calculateTotal(row?.original?.internalInvoiceItemId);
            }}
          />
        ) : (
          <CurrencyView value={row?.original?.rate} currencyType={'AED'} />
        );
      },
    },

    {
      accessorKey: 'gross',
      header: 'Gross',
      size: 100,
      enableEditing: true,
      Cell: ({ row }) => {
        const isNewRow =
          row?.original?.internalInvoiceItemId === rowState?.newRowId;
        const isEditing =
          editingRowId === row?.original?.internalInvoiceItemId || isNewRow;
        return isEditing ? (
          <FmCurrencyTextField
            name='gross'
            control={control}
            label='Gross'
            onBlur={handleBlur}
            readOnly
          />
        ) : (
          <CurrencyView value={row?.original?.gross} currencyType={'AED'} />
        );
      },
    },
    {
      accessorKey: 'tax',
      header: 'Tax %',
      size: 100,
      enableEditing: true,
      Cell: ({ row }) => {
        const isNewRow =
          row?.original?.internalInvoiceItemId === rowState?.newRowId;
        const isEditing =
          editingRowId === row?.original?.internalInvoiceItemId || isNewRow;
        return isEditing ? (
          <FmTextField
            name='tax'
            control={control}
            onBlur={handleBlur}
            type='decimal'
          />
        ) : (
          row?.original?.tax
        );
      },
    },
    {
      accessorKey: 'discount',
      header: 'Discount',
      size: 80,
      enableEditing: true,
      Cell: ({ row }) => {
        const isNewRow =
          row?.original?.internalInvoiceItemId === rowState?.newRowId;
        const isEditing =
          editingRowId === row?.original?.internalInvoiceItemId || isNewRow;
        return isEditing ? (
          <FmCurrencyTextField
            name='discount'
            control={control}
            label='Discount'
            onBlur={() => {
              handleBlur();
              calculateTotal(row.original.internalInvoiceItemId);
            }}
          />
        ) : (
          <CurrencyView value={row?.original?.discount} currencyType={'AED'} />
        );
      },
    },

    {
      accessorKey: 'vat',
      header: 'VAT %',
      size: 100,
      enableEditing: true,
      Cell: ({ row }) => {
        const isNewRow =
          row?.original?.internalInvoiceItemId === rowState?.newRowId;
        const isEditing =
          editingRowId === row?.original?.internalInvoiceItemId || isNewRow;
        return isEditing ? (
          <FmTextField
            name='vat'
            pattern='Percentage'
            control={control}
            onBlur={() => {
              handleBlur();
              calculateTotal(row.original.internalInvoiceItemId);
            }}
          />
        ) : (
          row?.original?.vat
        );
      },
    },

    {
      accessorKey: 'total',
      header: 'Total',
      size: 100,
      enableEditing: false,
      Cell: ({ row }) => {
        const isNewRow =
          row?.original?.internalInvoiceItemId === rowState?.newRowId;
        const isEditing =
          editingRowId === row?.original?.internalInvoiceItemId || isNewRow;
        return isEditing ? (
          <FmCurrencyTextField
            name='total'
            control={control}
            onBlur={handleBlur}
            readOnly
          />
        ) : (
          <CurrencyView value={row?.original?.total} currencyType={'AED'} />
        );
      },
    },
    {
      accessorKey: 'remarks',
      header: 'Remarks',
      size: 150,
      enableEditing: true,
      Cell: ({ row }) => {
        const isNewRow =
          row?.original?.internalInvoiceItemId === rowState?.newRowId;
        const isEditing =
          editingRowId === row?.original?.internalInvoiceItemId || isNewRow;
        return isEditing ? (
          <FmTextField
            name='remarks'
            control={control}
            onBlur={handleBlurAndFetch}
          />
        ) : (
          row?.original?.remarks
        );
      },
    },
    {
      header: 'Action',
      enableColumnPinning: true,
      enableEditing: false,
      enableSorting: false,
      Cell: ({ row, table }) => {
        const isNewRow =
          row?.original?.internalInvoiceItemId === rowState?.newRowId;
        const isEditing =
          editingRowId === row?.original?.internalInvoiceItemId || isNewRow;

        // Check if the current row is the last row in the table
        const isLastRow = row.index === table.getRowModel().rows.length - 1;

        return (
          <Box className='flex gap-2'>
            {isEditing ? (
              <>
                <Button
                  onClick={() => handleCancel(row)}
                  variant='contained'
                  color='error'
                  className='py-0.5 px-2'
                >
                  Close
                </Button>
                {isLastRow && rowState?.isCreatingRow === false && (
                  <Button
                    onFocus={() => handleCancelAndAddNewRow()}
                    hidden={true}
                  ></Button>
                )}
              </>
            ) : (
              <Box className='flex gap-2'>
                <TableActionButtons
                  Actions={ActionData?.map((action) => ({
                    ...action,
                    onClick: () => action.onClick(row?.original),
                  }))}
                />
                {isLastRow && (
                  <Button
                    variant='contained'
                    color='primary'
                    className='px-2 py-0.5'
                    onClick={() => {
                      setEditingRowIndex(row?.index + 1);
                      handleAddNewRow(row);
                    }}
                  >
                    Add
                  </Button>
                )}
              </Box>
            )}
          </Box>
        );
      },
      muiTableBodyCellProps: {
        sx: {
          borderLeft: '1px solid',
          borderColor: 'border.main',
        },
      },
    },
  ];

  const handleAddNewRow = () => {
    setSelectedWorkOrder({});
    const newId = 'new_row_' + Date.now();
    reset();
    setEditingRowData(null);
    setEditingRowId(null);
    setRowState({
      newRowId: newId,
      isCreatingRow: true,
    });
    setEditingRowIndex(
      editingRowIndex === rows.length ? editingRowIndex + 1 : rows.length
    );
    setRows((prevRows) => [
      ...prevRows,
      {
        internalInvoiceItemId: newId,
        internalInvoiceId: id,
        divisionId: '',
        description: '',
        tax: '',
        unitOfMeasurementId: '',
        rate: '',
        gross: '',
        discount: '',
        vat: '',
        remaks: '',
        isEditable: true,
        isNew: true,
      },
    ]);
    reset();
  };

  const handleExport = async () => {
    const downloadUrl = await apiInstance.getFiles(
      `internalinvoiceitems/${id}/exportexcel`
    );

    const link = document.createElement('a');
    link.href = downloadUrl;
    link.setAttribute('download', 'Sales Invoice.xlsx');
    document.body.appendChild(link);
    link.click();
    document.body.removeChild(link);
  };
  const handleClose = () => {
    setOpenUpload(false);
    setFilesData(null);
  };

  const handleUploadSheet = (event) => {
    startCreateLoading();
    const file = event.target.files[0];
    if (file) {
      const fileFormData = new FormData();
      fileFormData.append('file', file);
      fileFormData.append('fileTypeId', 4);

      crud.create('files/upload', fileFormData, (_err, res) => {
        if (res?.status === 200) {
          setFilesData(res?.data);
          crud.create(
            `internalinvoiceitems/${id}/process/${res.data?.fileId}`,
            '',
            (_err, res) => {
              if (res?.status === 200) {
                stopCreateLoading();
                toast.success('Internal Invoice Items Uploaded Successfully');
                fetchData();
                setOpenUpload(false);
              } else {
                stopCreateLoading();
              }
            }
          );
        } else {
          setOpenUpload(false);
          stopCreateLoading();
        }
      });
    }
  };

  useEffect(() => {
    if (rows?.length > 0) {
      const filteredRows = editingRowId
        ? rows.filter((row) => row.salesOrderItemId !== editingRowId) // Exclude the editing row
        : rows; // If no editingRowId, use all rows

      const gross = filteredRows.reduce(
        (total, row) => total + parseFloat(row.gross || 0),
        0
      );
      const discount = filteredRows.reduce(
        (total, row) => total - parseFloat(row.discount || 0),
        0
      );

      const net = filteredRows.reduce(
        (total, row) => total + parseFloat(row.net || 0),
        0
      );
      const vat = filteredRows.reduce(
        (total, row) => total + parseFloat(row.vat || 0),
        0
      );
      const total = filteredRows.reduce(
        (total, row) =>
          total + parseFloat((row.rate || 0) * (row.quantity || 0)),
        0
      );

      // Update billing values
      setBillingValues((prev) =>
        prev.map((item) => {
          switch (item.name) {
            case 'Quantity':
              return { ...item, value: rows.length }; // Add form value if available
            case 'Gross':
              return {
                ...item,
                value:
                  gross +
                  parseFloat(
                    formValues?.gross == 0
                      ? 0
                      : formValues?.gross || editingRowData?.gross || 0
                  ),
              }; // Add form value if available
            case 'Discount':
              return {
                ...item,
                value:
                  discount +
                  parseFloat(
                    formValues?.discount == 0
                      ? 0
                      : formValues?.discount || editingRowData?.discount || 0
                  ),
              }; // Add form value if available
            case 'Net':
              return {
                ...item,
                value:
                  net +
                  parseFloat(
                    formValues?.net == 0
                      ? 0
                      : formValues?.net || editingRowData?.net || 0
                  ),
              }; // Add form value if available
            case 'VAT':
              return {
                ...item,
                value:
                  vat +
                  parseFloat(
                    formValues?.vat == 0
                      ? 0
                      : formValues?.vat || editingRowData?.vat || 0
                  ),
              }; // Add form value if available
            case 'Total':
              return {
                ...item,
                value:
                  total +
                  (formValues?.quantity == '' || formValues?.rate == ''
                    ? 0
                    : parseFloat(
                        (formValues?.rate || editingRowData?.rate || 0) *
                          (formValues?.quantity ||
                            editingRowData?.quantity ||
                            0)
                      )),
              };
            default:
              return item;
          }
        })
      );
    }
  }, [
    formValues?.discount,
    formValues?.quantity,
    formValues?.gross,
    formValues?.vat,
    formValues?.net,
    formValues?.rate,
    editingRowId,
    rows,
    editingRowData?.salesOrderItemId,
  ]);

  return (
    <Box className=' flex flex-col flex-1 min-h-0'>
      <GlassCard className='flex-1 min-h-0'>
        <FmMRTDataTable
          rows={tableData}
          columns={columns}
          muiTableContainerProps={{
            sx: {
              maxHeight: '100%',
              flex: 1,
            },
          }}
          enableRowSelection={false}
          rowCount={tableRecordCounts}
          editingRow={editingRowId}
          pagination={pagination}
          sorting={sorting}
          columnFilters={columnFilters}
          globalFilter={globalFilter}
          setPagination={setPagination}
          showPagination={false}
          setSorting={setSorting}
          fetchData={fetchData}
          setColumnFilters={setColumnFilters}
          setGlobalFilter={setGlobalFilter}
          isLoading={isLoading}
          setSearchKeyword={setSearchKeyword}
          searchKeyword={searchKeyword}
          onCreateRow={handleAddNewRow}
          handleRowDoubleClick={(row) => {
            handleEdit(row?.original);
            setEditingRowIndex(row?.index);
          }}
          showSerialNumber={true}
          enableBottomToolbar={false}
          // handleExport={handleExport}
          // handleImport={() => setOpenUpload(true)}
        />
        <MuiDialogOne
          title='Upload Sheet'
          open={openUpload}
          onClose={handleClose}
        >
          <FmFileInput
            onChange={handleUploadSheet}
            documentName={filesData?.originalFileName}
            loading={createLoading}
          />
        </MuiDialogOne>
      </GlassCard>

      <MuiDialogOne
        open={viewDetails}
        title='View Details'
        onClose={() => setViewDetails(!viewDetails)}
      >
        <ViewTable
          rows={columns?.map((item) => ({
            label: item.header,
            title: GetViewDetails(item.accessorKey),
          }))}
        />
      </MuiDialogOne>

      <GlassCard className='p-2 bg-zinc-700 uppercase font-semibold text-white'>
        <Stack
          direction='row'
          flexWrap='wrap'
          spacing={3}
          justifyContent='space-between'
      
        >
          {billingValues?.map((item, i) => {
            return (
              <Box key={i} className='flex justify-between items-center'>
                <Box>{item.name}</Box>
                <Box className='font-bold ms-3'>{item.value}</Box>
              </Box>
            );
          })}
        </Stack>
      </GlassCard>
    </Box>
  );
};

export default EditListInternalInvoicesAR;
