import React, { useEffect, useState } from 'react';
import { useParams } from 'react-router-dom';
import { Box } from '@mui/material';
import ElementLoader from '../../../../components/page/ElementLoader.tsx';
import CreateInvetoryTransfer from './Create/CreateInvetoryTransfer.tsx';

const EditInventoryTransfer = () => {
    const [loading, setLoading] = useState(false);
    const { id } = useParams();

    useEffect(() => {
        document.title = 'Edit | Edit Inventory Transfer | Procurement';
    });
    return (
        <>
            <Box className='flex flex-col gap-3'>
                {loading ? <ElementLoader image fly /> : < CreateInvetoryTransfer transferId={id} />}
            </Box>
        </>
    );
};

export default EditInventoryTransfer;
