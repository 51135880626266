import React, { useState } from 'react';
import { Grid } from '@mui/material';
import GlassCard from '../../../../components/small/GlassCard.tsx';
import useCommonFetchApi from '../../../../components/helpers/useCommonFetchApi.tsx';
import { DatePicker, LocalizationProvider } from '@mui/x-date-pickers';
import { Controller, useForm } from 'react-hook-form';
import useLoader from '../../../../components/helpers/UseLoader.tsx';
import { toast } from 'react-toastify';
import Crud_Service from '../../../../apis/CrudService.jsx';
import dayjs from 'dayjs';
import FmAutoComplete from '../../../../components/_mui/FmAutoComplete.tsx';
import ActionButtons from '../../../../components/_form/ActionButtons.tsx';
import FmTextField from '../../../../components/_mui/FmTextField.tsx';
import DashboardLayout from '../../../../components/DashboardLayout.tsx';
import { useNavigate } from 'react-router-dom';
import FmSearchableSelect from '../../../../components/_mui/FmSearchableSelect.tsx';
import { AdapterDayjs } from '@mui/x-date-pickers/AdapterDayjs';
import FmDatePicker from '../../../../components/_mui/FmDatePicker.tsx';
import { yupResolver } from '@hookform/resolvers/yup';
import { employeeWiseMedicalInsuranceSchema } from '../../../../utils/CommonvalidationSchemas.tsx';

const GridItem = 2.4;
const AddEmployeeWiseMIPCalculator = (props) => {
  const { fetchData } = props;
  const { control, handleSubmit, reset, getValues, setValue, watch, trigger } = useForm({
    mode: 'onChange',
    resolver: yupResolver(employeeWiseMedicalInsuranceSchema),
  });
  const startDate = watch('startDate');
  const navigate = useNavigate();
  const { startCreateLoading, stopCreateLoading, createLoading } = useLoader();
  const crud = new Crud_Service();

  //get All Apis
  const { data: ageBands } = useCommonFetchApi(
    'companymedicalinsuranceagebands'
  ) || { data: [] };
  const { data: regions } = useCommonFetchApi('regions') || { data: [] };

  const calculate = () => {
    if (
      getValues('regionId') &&
      getValues('employeeId') &&
      getValues('startDate') &&
      getValues('endDate')
    ) {
      handleCalculation(getValues('employeeId'));
    }
  };

  const handleCalculation = async (employeeId) => {
    await crud.getAll(
      'medicalinsurancepolicies/medicalinsurancepolicycalculation',
      {
        employeeId: getValues('employeeId'),
        regionId: getValues('regionId'),
        companyMedicalInsuranceAgeBandId: getValues(
          'companyMedicalInsuranceAgeBandId'
        ),
        fromDate: dayjs(getValues('startDate')).format('MM-DD-YYYY'),
        toDate: dayjs(getValues('endDate')).format('MM-DD-YYYY'),
      },
      (err, res) => {
        if (err) {
          toast.error(err);
        }
        if (res?.status === 200) {
          setValue('premiumAmount', res?.data?.data[0]?.calculateAmount);
        }
      }
    );
  };

  const handleSubmitPolicy = async (values) => {
    startCreateLoading();

    const formattedValues = {
      ...values,
      startDate: dayjs(values.startDate).format('YYYY-MM-DD'),
      endDate: dayjs(values.endDate).format('YYYY-MM-DD'),
    };

    await crud.create(
      'employeemedicalinsurancepolicies',
      formattedValues,
      (err, res) => {
        if (res?.status === 201) {
          stopCreateLoading();
          toast.success('Policy Created Successfully');
          navigate(-1);
          reset();
        } else {
          stopCreateLoading();
        }
      }
    );
  };

  // This function handles the insurer selection

  return (
    <DashboardLayout title='Create Employee Wise Policy'>
      <GlassCard className='p-4 mb-4'>
        <form onSubmit={handleSubmit(handleSubmitPolicy)}>
          <LocalizationProvider dateAdapter={AdapterDayjs}>
            <Grid container spacing={2}>
              <Grid item md={GridItem}>
                <FmSearchableSelect
                  name='employeeId'
                  control={control}
                  apiUrl='employees'
                  valueField='employeeId'
                  headerField={['Code', 'Name']}
                  labelField={['employeeCode', 'firstName']}
                  showField={['firstName']}
                  label={'Employee'}
                  onChangeProp={calculate}
                  setSearchValue={() => {}}
                />
              </Grid>

              <Grid item md={GridItem}>
                <FmDatePicker
                  name='startDate'
                  control={control}
                  label='Start Date'
                  onChangeProp={() => trigger("endDate")}
                />
              </Grid>

              <Grid item md={GridItem}>
                <FmDatePicker
                  name='endDate'
                  control={control}
                  label='End Date'
                  minDate={startDate ? dayjs(startDate) : null}
                />
              </Grid>

              <Grid item md={GridItem}>
                <FmAutoComplete
                  name='companyMedicalInsuranceAgeBandId'
                  control={control}
                  options={ageBands}
                  label='Age Band'
                  displayField='companyMedicalInsuranceAgeBandName'
                  optionFields={['companyMedicalInsuranceAgeBandName']}
                  valueKey='companyMedicalInsuranceAgeBandId'
                  onChangeProp={calculate}
                  setSearchValue={() => {}}
                />
              </Grid>

              <Grid item md={GridItem}>
                <FmSearchableSelect
                  name='costCenterId'
                  control={control}
                  apiUrl='costCenters'
                  valueField='costCenterId'
                  headerField={['Cost Center Code', 'Cost Center Name']}
                  labelField={['costCenterCode', 'costCenterName']}
                  showField={['costCenterName']}
                  label='Cost Center'
                />
              </Grid>

              <Grid item md={GridItem}>
                <FmAutoComplete
                  name='regionId'
                  control={control}
                  options={regions}
                  label='Emirate'
                  displayField='regionCode'
                  optionFields={['regionCode']}
                  valueKey='regionId'
                  onChangeProp={calculate}
                  setSearchValue={() => {}}
                />
              </Grid>

              <Grid item md={GridItem}>
                <FmTextField
                  name='premiumAmount'
                  label='Premium Amount'
                  control={control}
                />
              </Grid>
            </Grid>

            <ActionButtons
              onSubmit={handleSubmit(handleSubmitPolicy)}
              onCancel={() => navigate(-1)}
              onReset={() => reset()}
              submitText='Create'
              cancelText='Go Back'
              submitLoading={createLoading}
              cancelLoading={false}
            />
          </LocalizationProvider>
        </form>
      </GlassCard>
    </DashboardLayout>
  );
};

export default AddEmployeeWiseMIPCalculator;
