import React, { useEffect, useState } from 'react';
import GlassCard from '../../../../components/small/GlassCard.tsx';
import MRTExpandTableStyle from '../../../../components/table/MRTExpandTableStyle.tsx';
import { Box, Stack, Divider, Button } from '@mui/material';
import { toast } from 'react-toastify';
import FmMRTDataTable from '../../../../components/FmMRTDataTable.tsx';
import FmTextField from '../../../../components/_mui/FmTextField.tsx';
import FmSearchableSelect from '../../../../components/_mui/FmSearchableSelect.tsx';
import TableActionButtons from '../../../../components/_form/TableActionButtons.jsx';
import { Icon } from '@iconify/react';
import useLoader from '../../../../components/helpers/UseLoader.tsx';
import { useForm } from 'react-hook-form';
import Crud_Service from '../../../../apis/CrudService.jsx';
import FmDatePicker from '../../../../components/_mui/FmDatePicker.tsx';
import FmViewDateField from '../../../../components/_mui/FmViewDateField.tsx';
import dayjs from 'dayjs';
import { useLocation, useNavigate } from 'react-router-dom';
import { AddSalesRetentionSehema } from '../../../../utils/CommonvalidationSchemas.tsx';
import { yupResolver } from '@hookform/resolvers/yup';

const BillingValues = [
  {
    name: 'Net',
    value: 1,
  },
];

const ListSalesRetention = (props) => {
  const {
    rows,
    tableRecordCounts,
    pagination,
    sorting,
    setPagination,
    setSorting,
    columnFilters,
    globalFilter,
    setColumnFilters,
    setGlobalFilter,
    isLoading,
    setSearchKeyword,
    searchKeyword,
    fetchData,
  } = props;

  const [editingRowId, setEditingRowId] = useState(null);
  const [editingRowData, setEditingRowData] = useState(null);
  const { handleSubmit, control, reset, setValue } = useForm({
    resolver: yupResolver(AddSalesRetentionSehema),
    mode: 'onChange',
  });
  const { startEditLoading, stopEditLoading } = useLoader();
  const navigate = useNavigate();
  const location = useLocation();
  const crud = new Crud_Service();
  const handleEdit = (row) => {
    setEditingRowId(row?.original?.salesRetentionId);
    setEditingRowData({ ...row?.original });
    Object.entries({
      ...row,
    }).forEach(([key, value]) => {
      setValue(key, value);
    });
  };
  const handleView = (id) => {
    navigate(`${location.pathname}/view/${id}`);
  };
  const handleCancel = () => {
    setEditingRowId(null);
    setEditingRowData(null);
  };
  useEffect(() => {
    if (editingRowData) {
      Object.entries({
        ...editingRowData,
      }).forEach(([key, value]) => {
        setValue(key, value);
      });
    }
  }, [editingRowData, setValue]);
  const handleDelete = async (props) => {
    await crud.remove(
      'salesretentions',
      props?.original?.salesRetentionId,
      (__err, res) => {
        if (res?.status === 204) {
          toast.success('Sales Retention Deleted Successfully');
          fetchData();
        } else {
        }
      }
    );
  };
  const handleUpdateBlock = async (values) => {
    const combinedData = {
      ...values,
      salesRetentionDate: values?.salesRetentionDate
        ? dayjs(values.salesRetentionDate).format('YYYY-MM-DD')
        : null,
      salesOrderDate: values?.salesOrderDate
        ? dayjs(values.salesOrderDate).format('YYYY-MM-DD')
        : null,
      dueDate: values?.dueDate
        ? dayjs(values.dueDate).format('YYYY-MM-DD')
        : null,
      workOrderDate: values?.workOrderDate
        ? dayjs(values.workOrderDate).format('YYYY-MM-DD')
        : null,
    };
    try {
      startEditLoading();
      await crud.update(
        'salesretentions',
        values?.salesRetentionId,
        combinedData,
        (_err, res) => {
          if (res?.status === 200) {
            stopEditLoading();
            fetchData();
            toast.success('Sales Retention Updated Successfully');
            setEditingRowData(null);
            setEditingRowId(null);
          } else {
            stopEditLoading();
            toast.error('Failed to update the Sales Retention');
          }
        }
      );
    } catch (error) {
      stopEditLoading();
      toast.error('An error occurred while updating the Sales Retention');
    }
  };

  const ActionData = [
    {
      name: 'View',
      icon: <Icon icon='solar:eye-linear' />,
      onClick: (props) => {
        handleView(props?.original?.salesRetentionId);
      },
    },
    {
      name: 'Edit',
      icon: <Icon icon='solar:pen-2-broken' />,
      onClick: (props) => {
        handleEdit(props);
      },
    },
    {
      name: 'Delete',
      icon: <Icon icon='solar:trash-bin-minimalistic-broken' />,
      onClick: (props) => handleDelete(props),
    },
  ];

  const columns = [
    {
      field: 'action',
      header: 'Actions',
      sortable: false,
      hideable: false,
      disableClickEventBubbling: false,

      Cell: ({ row }) => {
        return (
          <Box className='flex gap-2'>
            {editingRowId === row?.original?.salesRetentionId ? (
              <>
                <Button onClick={handleSubmit(handleUpdateBlock)}>Save</Button>
                <Button onClick={handleCancel}>Cancel</Button>
              </>
            ) : (
              <TableActionButtons
                Actions={ActionData?.map((action) => ({
                  ...action,
                  onClick: () => action.onClick(row),
                }))}
                selectedRow={row}
                setSelectedRow={() => {}}
              />
            )}
          </Box>
        );
      },
    },

    {
      accessorKey: 'salesRetentionCode',
      header: 'Sales Retention Code',
      enableEditing: true,
      Cell: ({ cell }) => {
        const salesDetails: any = rows?.find(
          (item) =>
            item?.salesRetentionId === cell?.row?.original?.salesRetentionId
        );
        return editingRowId === cell?.row?.original?.salesRetentionId ? (
          <FmTextField
            name='salesRetentionCode'
            control={control}
            defaultValue={salesDetails?.salesRetentionCode}
            label='Sales Retention Code'
          />
        ) : (
          salesDetails?.salesRetentionCode || ''
        );
      },
    },
    // TODO
    // {
    //   accessorKey: 'customerId',
    //   header: 'Customer',
    //   enableEditing: true,
    //   Cell: ({ cell }) => {
    //     const salesDetails: any = rows?.find(
    //       (item) => item?.salesRetentionId === cell?.row?.original?.salesRetentionId
    //     );
    //     return editingRowId === cell?.row?.original?.salesRetentionId ? (
    //       <FmSearchableSelect
    //         name='customerId'
    //         control={control}
    //         apiUrl='customers'
    //         valueField='customerId'
    //         headerField={['Customer Code', 'Customer Name']}
    //         labelField={['customerCode', 'customerName']}
    //         showField={['customerName']}
    //
    //         label='Customer Name'
    //       />
    //     ) : (
    //       salesDetails?.customerName || ''
    //     );
    //   },
    // },
    {
      accessorKey: 'companyId',
      header: 'Company',
      enableEditing: true,
      Cell: ({ cell }) => {
        const salesDetails: any = rows?.find(
          (item) =>
            item?.salesRetentionId === cell?.row?.original?.salesRetentionId
        );
        return editingRowId === cell?.row?.original?.salesRetentionId ? (
          <FmSearchableSelect
            name='companyId'
            control={control}
            apiUrl='companies'
            valueField='companyId'
            headerField={['Company Code', 'Company Name']}
            labelField={['companyCode', 'companyName']}
            showField={['companyName']}
            label='Companies'
            defaultValue={{
              companyId: editingRowData?.companyId,
              companyName: editingRowData?.companyName,
            }}
          />
        ) : (
          salesDetails?.companyName || ''
        );
      },
    },
    {
      accessorKey: 'projectId',
      header: 'Project',
      enableEditing: true,
      Cell: ({ cell }) => {
        const salesDetails: any = rows?.find(
          (item) =>
            item?.salesRetentionId === cell?.row?.original?.salesRetentionId
        );
        return editingRowId === cell?.row?.original?.salesRetentionId ? (
          <FmSearchableSelect
            name='projectId'
            control={control}
            apiUrl='projects'
            valueField='projectId'
            headerField={['Project Code', 'Project Name']}
            labelField={['projectCode', 'projectName']}
            showField={['projectName']}
            label={'Projects'}
            defaultValue={{
              projectId: editingRowData?.projectId,
              projectName: editingRowData?.projectName,
            }}
          />
        ) : (
          salesDetails?.projectName || ''
        );
      },
    },
    {
      accessorKey: 'contractId',
      header: 'Contract',
      enableEditing: true,
      Cell: ({ cell }) => {
        const salesDetails: any = rows?.find(
          (item) =>
            item?.salesRetentionId === cell?.row?.original?.salesRetentionId
        );
        return editingRowId === cell?.row?.original?.salesRetentionId ? (
          <FmSearchableSelect
            name='contractId'
            control={control}
            apiUrl='contracts'
            valueField='contractId'
            headerField={['Contract Code', 'Contract Name']}
            labelField={['contractCode', 'contractName']}
            showField={['contractName']}
            label={'Contracts'}
            defaultValue={{
              contractId: editingRowData?.contractId,
              contractName: editingRowData?.contractName,
            }}
          />
        ) : (
          salesDetails?.contractName || ''
        );
      },
    },
    {
      accessorKey: 'locationId',
      header: 'Location',
      enableEditing: true,
      Cell: ({ cell }) => {
        const salesDetails: any = rows?.find(
          (item) =>
            item?.salesRetentionId === cell?.row?.original?.salesRetentionId
        );
        return editingRowId === cell?.row?.original?.salesRetentionId ? (
          <FmSearchableSelect
            name='locationId'
            control={control}
            apiUrl='locations'
            valueField='locationId'
            labelField={['locationCode', 'locationName']}
            showField={['locationCode', 'locationName']}
            label={'Location'}
            defaultValue={{
              locationId: editingRowData?.locationId,
              locationName: editingRowData?.locationName,
            }}
          />
        ) : (
          salesDetails?.locationName || ''
        );
      },
    },
    {
      accessorKey: 'emirateId',
      header: 'Emirate',
      enableEditing: true,
      Cell: ({ cell }) => {
        const salesDetails: any = rows?.find(
          (item) =>
            item?.salesRetentionId === cell?.row?.original?.salesRetentionId
        );
        return editingRowId === cell?.row?.original?.salesRetentionId ? (
          <FmSearchableSelect
            name='emirateId'
            control={control}
            apiUrl='emirates'
            valueField='emirateId'
            labelField={['emirateName']}
            showField={['emirateName']}
            label={'Emirate'}
            defaultValue={{
              emirateId: editingRowData?.emirateId,
              emirateName: editingRowData?.emirateName,
            }}
          />
        ) : (
          salesDetails?.emirateName || ''
        );
      },
    },
    {
      accessorKey: 'currencyId',
      header: 'Currency',
      enableEditing: true,
      Cell: ({ cell }) => {
        const salesDetails: any = rows?.find(
          (item) =>
            item?.salesRetentionId === cell?.row?.original?.salesRetentionId
        );
        return editingRowId === cell?.row?.original?.salesRetentionId ? (
          <FmSearchableSelect
            name='currencyId'
            control={control}
            apiUrl='currencies'
            valueField='currencyId'
            headerField={['Currency Code', 'Currency Name']}
            labelField={['currencyCode', 'currencyName']}
            showField={['currencyName']}
            label='Currency'
            defaultValue={{
              currencyId: editingRowData?.currencyId,
              currencyName: editingRowData?.currencyName,
            }}
          />
        ) : (
          salesDetails?.currencyName || ''
        );
      },
    },
    {
      accessorKey: 'exchangeRate',
      header: 'Exchange Rate',
      enableEditing: true,
      Cell: ({ cell }) => {
        const salesDetails: any = rows?.find(
          (item) =>
            item?.salesRetentionId === cell?.row?.original?.salesRetentionId
        );
        return editingRowId === cell?.row?.original?.salesRetentionId ? (
          <FmTextField
            name='exchangeRate'
            control={control}
            label='Exchange Rate'
          />
        ) : (
          salesDetails?.exchangeRate || ''
        );
      },
    },
    {
      accessorKey: 'salesOrderId',
      header: 'Sales Order',
      enableEditing: true,
      Cell: ({ cell }) => {
        const salesDetails: any = rows?.find(
          (item) =>
            item?.salesRetentionId === cell?.row?.original?.salesRetentionId
        );
        return editingRowId === cell?.row?.original?.salesRetentionId ? (
          <FmSearchableSelect
            name='salesOrderId'
            control={control}
            apiUrl='salesorders'
            valueField='salesOrderId'
            headerField={['Code']}
            labelField={['salesOrderCode']}
            showField={['salesOrderCode']}
            label='Sales Order Code'
            defaultValue={{
              salesOrderId: editingRowData?.salesOrderId,
              salesOrderCode: editingRowData?.salesOrderCode,
            }}
          />
        ) : (
          salesDetails?.salesOrderCode || ''
        );
      },
    },
    {
      accessorKey: 'salesRetentionDate',
      header: 'Sales Retention Date',
      enableEditing: true,
      Cell: ({ row }) => {
        return editingRowId === row?.original?.salesRetentionId ? (
          <FmDatePicker
            name='salesRetentionDate'
            label='Sales Retention Date'
            control={control}
            required
          />
        ) : row?.original?.salesRetentionDate ? (
          <FmViewDateField value={row?.original?.salesRetentionDate} />
        ) : (
          ''
        );
      },
    },
    {
      accessorKey: 'salesOrderDate',
      header: 'Sales Order Date',
      enableEditing: true,
      Cell: ({ row }) => {
        return editingRowId === row?.original?.salesRetentionId ? (
          <FmDatePicker
            name='salesOrderDate'
            label='Sales Order Date'
            control={control}
            required
          />
        ) : row?.original?.salesOrderDate ? (
          <FmViewDateField value={row?.original?.salesOrderDate} />
        ) : (
          ''
        );
      },
    },
    {
      accessorKey: 'workOrderId',
      header: 'Work Order',
      enableEditing: true,
      Cell: ({ cell }) => {
        const salesDetails: any = rows?.find(
          (item) =>
            item?.salesRetentionId === cell?.row?.original?.salesRetentionId
        );
        return editingRowId === cell?.row?.original?.salesRetentionId ? (
          <FmSearchableSelect
            name='workOrderId'
            control={control}
            apiUrl='workorders'
            valueField='workOrderId'
            headerField={['Name']}
            labelField={['workOrderCode']}
            showField={['workOrderCode']}
            label='work Order'
            defaultValue={{
              workOrderId: editingRowData?.workOrderId,
              workOrderCode: editingRowData?.workOrderCode,
            }}
          />
        ) : (
          salesDetails?.workOrderCode || ''
        );
      },
    },
    {
      accessorKey: 'dueDate',
      header: 'Due Date',
      enableEditing: true,
      Cell: ({ row }) => {
        return editingRowId === row?.original?.salesRetentionId ? (
          <FmDatePicker name='dueDate' label='Due Date' control={control} />
        ) : row?.original?.dueDate ? (
          <FmViewDateField value={row?.original?.dueDate} />
        ) : (
          ''
        );
      },
    },

    {
      accessorKey: 'salesOrderDate',
      header: 'Sales Order Date',
      enableEditing: true,
      Cell: ({ row }) => {
        return editingRowId === row?.original?.salesRetentionId ? (
          <FmDatePicker
            name='salesOrderDate'
            label='Sales Order Date'
            control={control}
            required
          />
        ) : row?.original?.salesOrderDate ? (
          <FmViewDateField value={row?.original?.salesOrderDate} />
        ) : (
          ''
        );
      },
    },

    {
      accessorKey: 'paymentTerms',
      header: 'Payment Term',
      enableEditing: true,
      Cell: ({ cell }) => {
        const salesDetails: any = rows?.find(
          (item) =>
            item?.salesRetentionId === cell?.row?.original?.salesRetentionId
        );
        return editingRowId === cell?.row?.original?.salesRetentionId ? (
          <FmTextField
            name='paymentTerms'
            control={control}
            label='Payment Terms'
          />
        ) : (
          salesDetails?.paymentTerms || ''
        );
      },
    },
    {
      accessorKey: 'narration',
      header: 'Narration',
      enableEditing: true,
      Cell: ({ cell }) => {
        const salesDetails: any = rows?.find(
          (item) =>
            item?.salesRetentionId === cell?.row?.original?.salesRetentionId
        );
        return editingRowId === cell?.row?.original?.salesRetentionId ? (
          <FmTextField name='narration' control={control} label='Narration' />
        ) : (
          salesDetails?.narration || ''
        );
      },
    },
    {
      accessorKey: 'requestorId',
      header: 'Requestor',
      enableEditing: true,
      Cell: ({ cell }) => {
        const salesDetails: any = rows?.find(
          (item) =>
            item?.salesRetentionId === cell?.row?.original?.salesRetentionId
        );
        return editingRowId === cell?.row?.original?.salesRetentionId ? (
          <FmSearchableSelect
            name='requestorId'
            control={control}
            apiUrl='loginusers'
            valueField='loginUserId'
            headerField={['Employee']}
            labelField={['loginUserName']}
            showField={['loginUserName']}
            label='Approved By'
            defaultValue={{
              loginUserId: editingRowData?.requestorId,
              loginUserName: editingRowData?.requestorUserName,
            }}
          />
        ) : (
          salesDetails?.requestorUserName || ''
        );
      },
    },
    {
      accessorKey: 'paymentTypeId',
      header: 'Payment Type',
      enableEditing: true,
      Cell: ({ cell }) => {
        const salesDetails: any = rows?.find(
          (item) =>
            item?.salesRetentionId === cell?.row?.original?.salesRetentionId
        );
        return editingRowId === cell?.row?.original?.salesRetentionId ? (
          <FmSearchableSelect
            name='paymentTypeId'
            control={control}
            apiUrl='paymenttypes'
            valueField='paymentTypeId'
            labelField={['paymentTypeName']}
            showField={['paymentTypeName']}
            label={'Payment Type'}
            defaultValue={{
              paymentTypeId: editingRowData?.paymentTypeId,
              paymentTypeName: editingRowData?.paymentTypeName,
            }}
          />
        ) : (
          salesDetails?.paymentTypeName || ''
        );
      },
    },
    {
      accessorKey: 'inCharge',
      header: 'In Charge',
      enableEditing: true,
      Cell: ({ cell }) => {
        const salesDetails: any = rows?.find(
          (item) =>
            item?.salesRetentionId === cell?.row?.original?.salesRetentionId
        );
        return editingRowId === cell?.row?.original?.salesRetentionId ? (
          <FmSearchableSelect
            name='inCharge'
            control={control}
            apiUrl='loginusers'
            valueField='loginUserId'
            labelField={['emailId', 'loginUserName']}
            showField={['emailId', 'loginUserName']}
            pageSize={50}
            label='In Charge'
            defaultValue={{
              inCharge: editingRowData?.inCharge,
              loginUserName: editingRowData?.inChargeUserName,
            }}
          />
        ) : (
          salesDetails?.inChargeUserName || ''
        );
      },
    },
    {
      accessorKey: 'salesQuotationId',
      header: 'Sales Quotation',
      enableEditing: true,
      Cell: ({ cell }) => {
        const salesDetails: any = rows?.find(
          (item) =>
            item?.salesRetentionId === cell?.row?.original?.salesRetentionId
        );
        return editingRowId === cell?.row?.original?.salesRetentionId ? (
          <FmSearchableSelect
            name='salesQuotationId'
            control={control}
            apiUrl='salesquotations'
            valueField='salesQuotationId'
            headerField={['Sales Quotation Name']}
            labelField={['salesQuotationCode']}
            showField={['salesQuotationCode']}
            label='Sales Quotation'
            defaultValue={{
              salesQuotationId: editingRowData?.salesQuotationId,
              salesQuotationCode: editingRowData?.salesQuotationCode,
            }}
          />
        ) : (
          salesDetails?.salesQuotationCode || ''
        );
      },
    },
    {
      accessorKey: 'remarks',
      header: 'Remarks',
      enableEditing: true,
      Cell: ({ cell }) => {
        const salesDetails: any = rows?.find(
          (item) =>
            item?.salesRetentionId === cell?.row?.original?.salesRetentionId
        );
        return editingRowId === cell?.row?.original?.salesRetentionId ? (
          <FmTextField name='remarks' control={control} label='Remarks' />
        ) : (
          salesDetails?.remarks || ''
        );
      },
    },
  ];

  return (
    <>
      <Box className='h-full-css flex flex-col gap-3'>
        <GlassCard className='flex-1 min-h-0'>
          <FmMRTDataTable
            columns={columns}
            enableRowSelection={false}
            rows={rows}
            rowCount={tableRecordCounts}
            editingRow={editingRowId}
            pagination={pagination}
            sorting={sorting}
            fetchData={fetchData}
            columnFilters={columnFilters}
            globalFilter={globalFilter}
            setPagination={setPagination}
            setSorting={setSorting}
            setColumnFilters={setColumnFilters}
            setGlobalFilter={setGlobalFilter}
            isLoading={isLoading}
            setSearchKeyword={setSearchKeyword}
            searchKeyword={searchKeyword}
            handleRowDoubleClick={(e) => handleEdit(e)}
            muiTableContainerProps={{
              sx: {
                maxHeight: '100%',
                flex: 1,
              },
            }}
          />
        </GlassCard>

        <GlassCard className='p-3'>
          <Stack
            direction='row'
            flexWrap='wrap'
            spacing={3}
            justifyContent='space-between'
            divider={<Divider orientation='vertical' flexItem />}
          >
            {BillingValues?.map((item, i) => {
              return (
                <Box key={i} className='flex justify-between items-center'>
                  <Box>{item.name}</Box>
                  <Box
                    className='font-bold ms-3'
                    sx={{ color: 'success.main' }}
                  >
                    {item.value}
                  </Box>
                </Box>
              );
            })}
          </Stack>
        </GlassCard>
      </Box>
    </>
  );
};

export default ListSalesRetention;
