import React, { useEffect, useState } from "react";
import {
  Box,
  Grid,
  Button,
  Divider,
  FormControlLabel,
  Radio,
  RadioGroup,
} from "@mui/material";
import { Icon } from "@iconify/react";
import { useParams } from "react-router-dom";
import Crud_Service from "../../../../apis/CrudService";
import UploadSquareBoxes from "../../../../components/_form/UploadSquareBoxes.tsx";
import GlassCard from "../../../../components/small/GlassCard.tsx";
import { Controller, useForm } from "react-hook-form";
import FmSearchableSelect from "../../../../components/_mui/FmSearchableSelect.tsx";
import ActionButtons from "../../../../components/_form/ActionButtons.tsx";
import useLoader from "../../../../components/helpers/UseLoader.tsx";
import { toast } from "react-toastify";
import FmTextField from "../../../../components/_mui/FmTextField.tsx";
import FmAutoComplete from "../../../../components/_mui/FmAutoComplete.tsx";
import { statusOptions } from "../../../../utils/CommonVariables.tsx";
import dayjs from "dayjs";
import CommonLoader from "../../../../components/page/CommonLoader.jsx";
import { yupResolver } from "@hookform/resolvers/yup";
import { purchaseFormSchema } from "../../../../utils/CommonvalidationSchemas.tsx";
import MuiDatePickerOne from "../../../../components/_mui/MuiDatePickerOne.tsx";
import useCommonFetchApi from "../../../../components/helpers/useCommonFetchApi.tsx";

const CreatePurchaseForms = (props) => {
  const { setLocalPurchaseOrderId, setMaterialRequest, setEditData } = props;
  const { id } = useParams();
  const [components, setComponents] = useState<any>([]);
  const [imageUrl, setImageUrl] = useState([]);
  const [counter, setCounter] = useState(1);
  const [datas, setData] = useState([]);
  const [editLoading, setEditLoading] = useState(true);
  const [editValues, setEditValues] = useState({});
  const [imageLoading, setImageLoading] = useState(
    components?.reduce((acc, component) => {
      acc[component?.id] = false;
      return acc;
    }, {})
  );

  const crud = new Crud_Service();
  const defaultValues = {
    selectmode: 1,
    lpoDate: dayjs(new Date()),
  };

  const {
    control,
    handleSubmit,
    reset,
    setValue,
    getValues,
    watch,
    formState: { errors },
  } = useForm({
    defaultValues,
    resolver: yupResolver(purchaseFormSchema),
    mode: "onChange",
  });
  const { startCreateLoading, stopCreateLoading, createLoading } = useLoader();

  //fetch APis
  const { data: currencies } = useCommonFetchApi("currencies");

  useEffect(() => {
    if (id) {
      getLocalPurchaseOrder();
    }
  }, [id]);

  const getLocalPurchaseOrder = async () => {
    setEditLoading(true);
    await crud.getSingle("localpurchaseorders", id, (err, res) => {
      if (err) {
        toast.error(err);
        setEditLoading(false);
      } else if (res?.status === 200) {
        Object.entries({ ...res?.data }).forEach(([key, value]) => {
          setValue(key, value);
        });
        setEditValues(res?.data);
        setEditData(res?.data);
        setData(res?.data);
        setEditLoading(false);
      } else {
        setEditLoading(false);
        toast.error("Something Went Wrong!");
      }
    });
  };

  const handleAddComponent = () => {
    setComponents([...components, { id: counter }]);
    setCounter(counter + 1);
  };

  const handleRemoveComponent = (id) => {
    setComponents(components.filter((component) => component.id !== id));
  };

  const onSubmit = async (data) => {
    startCreateLoading();

    const formattedData = {
      ...data,
      narration: "String",
      lpoDate: dayjs(data?.lpoDate).format("YYYY-MM-DD"),
      exchangeRate: parseFloat(data?.exchangeRate),
      supplierContactPerson: data?.supplierContactPerson || null,
      materialRequisitionId: data?.materialRequisitionId || null,
      supplierSPOCId: data?.supplierSPOCId || null,
      fileUploadIds: getValues("fileUploadIds") || [],
    };

    if (id) {
      await crud.update(
        "localpurchaseorders",
        id,
        { ...formattedData, status: 2 },
        (err, res) => {
          if (res?.status === 200) {
            stopCreateLoading();
            toast.success("Purchase Order Updated Successfully");
            setLocalPurchaseOrderId(res?.data?.localPurchaseOrderId);
            if (data?.materialRequisitionId) {
              handleLocalpurchaseOrderItems(res?.data?.localPurchaseOrderId);
            }
            reset();
          } else {
            stopCreateLoading();
            toast.error("Something went wrong");
          }
        }
      );
    } else {
      await crud.create("localpurchaseorders", formattedData, (err, res) => {
        if (res?.status === 201) {
          stopCreateLoading();
          toast.success("Purchase Order Created Successfully");
          setLocalPurchaseOrderId(res?.data?.localPurchaseOrderId);
          if (data?.materialRequisitionId) {
            handleLocalpurchaseOrderItems(res?.data?.localPurchaseOrderId);
          }
          reset();
        } else {
          stopCreateLoading();
          toast.error("Something went wrong");
        }
      });
    }
  };

  const handleLocalpurchaseOrderItems = async (purchaseOrderId) => {
    await crud.create(
      "localpurchaseorderitems",
      {
        localPurchaseOrderId: purchaseOrderId,
        localPurchaseOrderItems: [],
      },
      (err, res) => {
        if (res?.status === 201) {
          toast.success("Purchase Order items Created successfully");
        } else {
          toast.error("Something went wrong");
        }
      }
    );
  };

  const handleFileChange = (id, fileId) => {
    if (typeof fileId === "number") {
      const currentFileIds = getValues("fileUploadIds") || [];
      const updatedFileIds = [...currentFileIds, fileId];
      setValue("fileUploadIds", updatedFileIds);
    }
  };
  const selectedMode = watch("selectmode");

  return (
    <>
      <GlassCard className="mb-8 p-5">
        {id && editLoading ? (
          <CommonLoader />
        ) : (
          <form onSubmit={handleSubmit(onSubmit)}>
            <Grid container spacing={2}>
              <React.Fragment>
                <Grid item md={3}>
                  <FmTextField
                    name="manualLPOCode"
                    control={control}
                    label="Manual LPO Code"
                    required
                  />
                </Grid>
                <Grid item md={3}>
                  <Controller
                    name="lpoDate"
                    control={control}
                    defaultValue={null}
                    render={({ field }) => (
                      <MuiDatePickerOne
                        {...field}
                        label="LPO Date"
                        format="DD/MM/YYYY"
                        value={field?.value ? dayjs(field?.value) : null}
                        onChange={field?.onChange}
                      />
                    )}
                  />
                </Grid>
                <Grid item md={3}>
                  <FmTextField
                    name="vendorAccount"
                    control={control}
                    label="Vendor Account"
                    required
                  />
                </Grid>
                <Grid item md={3}>
                  <FmAutoComplete
                    label="Currency"
                    name="currencyId"
                    control={control}
                    options={currencies}
                    displayField="currencyName"
                    optionFields={["currencyName"]}
                    valueKey="currencyId"
                  />
                </Grid>
                <Grid item md={3}>
                  <FmTextField
                    name="exchangeRate"
                    control={control}
                    pattern="Number"
                    label="Exchange Rate"
                    required
                  />
                </Grid>
                <Grid item md={3}>
                  <FmSearchableSelect
                    name="companyId"
                    control={control}
                    apiUrl="companies"
                    valueField="companyId"
                    headerField={["Code", "Name"]}
                    labelField={["companyManualCode", "companyName"]}
                    showField={["companyName"]}
                    
                    label={"Company"}
                    defaultValue={{
                      companyId: editValues?.companyId,
                      companyName: editValues?.companyName,
                    }}
                  />
                </Grid>
                <Grid item md={3}>
                  <FmSearchableSelect
                    name="localPurchaseOrderStatusId"
                    control={control}
                    apiUrl="localpurchaseorderstatuses"
                    valueField="localPurchaseOrderStatusId"
                    headerField={["Code", "Name"]}
                    labelField={[
                      "localPurchaseOrderStatusCode",
                      "localPurchaseOrderStatusName",
                    ]}
                    showField={["localPurchaseOrderStatusName"]}
                    
                    label={"Local Purchase Order Status"}
                    defaultValue={{
                      localPurchaseOrderStatusId:
                        editValues?.localPurchaseOrderStatusId,
                      localPurchaseOrderStatusName:
                        editValues?.localPurchaseOrderStatusName,
                    }}
                  />
                </Grid>
                <Grid item md={3}>
                  <FmSearchableSelect
                    name="costCenterId"
                    control={control}
                    apiUrl="costcenters"
                    valueField="costCenterId"
                    headerField={["Code", "Name"]}
                    labelField={["costCenterCode", "costCenterName"]}
                    showField={["costCenterName"]}
                    
                    label={"Cost Center"}
                    defaultValue={{
                      costCenterId: editValues?.costCenterId,
                      costCenterName: editValues?.costCenterName,
                    }}
                  />
                </Grid>

                <Grid item md={3}>
                  <FmSearchableSelect
                    headerField={["Code", "Project Manager", "Name"]}
                    name="projectId"
                    control={control}
                    apiUrl="projects"
                    valueField="projectId"
                    labelField={[
                      "projectCode",
                      "projectManager",
                      "projectName",
                    ]}
                    showField={["projectName"]}
                    
                    label={"Project"}
                    defaultValue={{
                      projectId: editValues?.projectId,
                      projectName: editValues?.projectName,
                    }}
                  />
                </Grid>
                <Grid item md={3}>
                  <FmSearchableSelect
                    name="contractId"
                    control={control}
                    apiUrl="contracts"
                    valueField="contractId"
                    headerField={["Code", "Name"]}
                    labelField={["contractCode", "contractName"]}
                    showField={["contractName"]}
                    
                    label={"Contract"}
                    defaultValue={{
                      contractId: editValues?.contractId,
                      contractName: editValues?.contractName,
                    }}
                  />
                </Grid>
                <Grid item md={3}>
                  <FmSearchableSelect
                    headerField={["Code", "Name"]}
                    name="divisionId"
                    control={control}
                    apiUrl="divisions"
                    valueField="divisionId"
                    labelField={["divisionName", "divisionCode"]}
                    showField={["divisionName"]}
                    
                    label={"Department"}
                    defaultValue={{
                      divisionId: editValues?.divisionId,
                      divisionName: editValues?.divisionName,
                    }}
                  />
                </Grid>
                <Grid item md={3}>
                  <FmSearchableSelect
                    name="jurisdiction"
                    control={control}
                    apiUrl="regions"
                    valueField="regionId"
                    headerField={["Code", "Name"]}
                    labelField={["regionCode", "regionName"]}
                    showField={["regionName"]}
                    
                    label={"Jurisdiction"}
                    defaultValue={{
                      regionId: editValues?.jurisdiction,
                      regionName: editValues?.jurisdictionName,
                    }}
                  />
                </Grid>
                <Grid item md={3}>
                  <FmSearchableSelect
                    name="inCharge"
                    control={control}
                    apiUrl="loginusers"
                    valueField="loginUserId"
                    headerField={["Name", "Email"]}
                    labelField={["loginUserName", "emailId"]}
                    showField={["loginUserName"]}
                    
                    label={"In Charge"}
                    defaultValue={{
                      loginUserId: editValues?.inCharge,
                      loginUserName: editValues?.inChargeName,
                    }}
                  />
                </Grid>
                <Grid item md={3}>
                  <Controller
                    name="selectmode"
                    control={control}
                    render={({ field }) => (
                      <RadioGroup
                        aria-labelledby="mode-label"
                        defaultValue={1}
                        {...field}
                        sx={{ flexDirection: "row" }}
                      >
                        <FormControlLabel
                          value={1}
                          control={<Radio size="small" />}
                          label="Material Requisition"
                        />
                        <FormControlLabel
                          value={2}
                          control={<Radio size="small" />}
                          label="Request For Quote"
                        />
                      </RadioGroup>
                    )}
                  />
                </Grid>

                {/* Conditionally render Material Requisition Type dropdown */}
                {selectedMode == 1 && (
                  <Grid item md={3}>
                    <FmSearchableSelect
                      name={"materialRequisitionId"}
                      control={control}
                      label="Material Requisition"
                      apiUrl="materialrequisitions"
                      valueField="materialRequisitionId"
                      headerField={["Code", "Type Name"]}
                      labelField={[
                        "materialRequisitionCode",
                        "materialRequisitionTypeName",
                      ]}
                      showField={["materialRequisitionCode"]}
                      
                      defaultValue={{
                        materialRequisitionId:
                          editValues?.materialRequisitionId,
                        materialRequisitionCode:
                          editValues?.materialRequisitionCode,
                      }}
                    />
                  </Grid>
                )}

                {/* Conditionally render Request For Quote dropdown */}
                {selectedMode == 2 && (
                  <Grid item md={3}>
                    <FmSearchableSelect
                      name="requestForQuoteId"
                      control={control}
                      apiUrl="requestforquotes"
                      valueField="requestForQuoteId"
                      headerField={["Code"]}
                      labelField={["requestForQuoteNumber"]}
                      showField={["requestForQuoteNumber"]}
                      
                      label="Request For Quote"
                      defaultValue={{
                        requestForQuoteId: editValues?.requestForQuoteId,
                        requestForQuoteNumber:
                          editValues?.requestForQuoteNumber,
                      }}
                    />
                  </Grid>
                )}
                <Grid item md={3}>
                  <FmSearchableSelect
                    name="requestor"
                    control={control}
                    apiUrl="loginusers"
                    valueField="loginUserId"
                    headerField={["Name", "Email"]}
                    labelField={["loginUserName", "emailId"]}
                    showField={["loginUserName"]}
                    
                    label={"Requestor"}
                    defaultValue={{
                      loginUserId: editValues?.requestor,
                      loginUserName: editValues?.requestorName,
                    }}
                  />
                </Grid>
                <Grid item md={3}>
                  <FmSearchableSelect
                    name={"deliveryLocation"}
                    control={control}
                    label="Delivery Location"
                    apiUrl="locations"
                    valueField="locationId"
                    headerField={["Code", "Name"]}
                    labelField={["locationCode", "locationName"]}
                    showField={["locationName"]}
                    
                    defaultValue={{
                      locationId: editValues?.deliveryLocation,
                      locationName: editValues?.locationName,
                    }}
                  />
                </Grid>

                <Grid item md={3}>
                  <FmSearchableSelect
                    name={"supplierContactPerson"}
                    control={control}
                    apiUrl="suppliers"
                    valueField="supplierId"
                    headerField={["Code", "Name"]}
                    labelField={["supplierCode", "officialSupplierName"]}
                    showField={["officialSupplierName"]}
                    
                    label="Suppliers"
                    defaultValue={{
                      supplierId: editValues?.supplierId,
                      supplierName: editValues?.supplierName,
                    }}
                  />
                </Grid>
                <Grid item md={3}>
                  <FmSearchableSelect
                    name={"supplierSPOCId"}
                    control={control}
                    label="Site Contact Person"
                    apiUrl="supplierspocs"
                    valueField="supplierSPOCId"
                    headerField={["Name"]}
                    labelField={["supplierSPOCName"]}
                    showField={["supplierSPOCName"]}
                    
                    defaultValue={{
                      supplierSPOCId: editValues?.supplierSPOCId,
                      supplierSPOCName: editValues?.supplierSPOCName,
                    }}
                  />
                </Grid>
                <Grid item md={3}>
                  <FmSearchableSelect
                    name={"paymentTermId"}
                    control={control}
                    label="Payment Term"
                    apiUrl="paymentterms"
                    valueField="paymentTermId"
                    headerField={["Code", "Name"]}
                    labelField={["paymentTermCode", "paymentTermName"]}
                    showField={["paymentTermName"]}
                    
                    defaultValue={{
                      paymentTermId: editValues?.paymentTermId,
                      paymentTermName: editValues?.paymentTermName,
                    }}
                  />
                </Grid>
                <Grid item md={3}>
                  <FmSearchableSelect
                    name="materialRequisitionTypeId"
                    control={control}
                    apiUrl="materialrequisitiontypes"
                    valueField="materialRequisitionTypeID"
                    headerField={["Code", "Name"]}
                    labelField={[
                      "materialRequisitionTypeCode",
                      "materialRequisitionTypeName",
                    ]}
                    showField={["materialRequisitionTypeName"]}
                    
                    label="Material Requisition Type"
                    defaultValue={{
                      materialRequisitionTypeId:
                        editValues?.materialRequisitionTypeId,
                      materialRequisitionTypeName:
                        editValues?.materialRequisitionTypeName,
                    }}
                  />
                </Grid>

                <Grid item md={12}>
                  <Divider className="my-1" />
                </Grid>

                <Grid item md={3}>
                  <FmTextField
                    name={"remarks"}
                    control={control}
                    required
                    multiline
                    rows={4}
                    label={"Remarks"}
                  />
                </Grid>
                <Grid item md={3}>
                  <FmTextField
                    name={"deliveryTerms"}
                    control={control}
                    required
                    multiline
                    rows={4}
                    label={"Delivery Terms"}
                  />
                </Grid>

                <Grid item md={6}>
                  <Box>
                    <h3 className="mb-3 font-semibold">
                      Additional files Upload
                    </h3>
                    <Box className="flex gap-5 flex-wrap">
                      <Button
                        onClick={handleAddComponent}
                        sx={{
                          minWidth: "100px",
                          height: "100px",
                          border: "1px solid",
                          borderColor: "border.main",
                          flexDirection: "column",
                          padding: 0,
                          bgcolor: "background.white",
                        }}
                      >
                        <span className="text-teal-600 text-3xl mb-3">
                          <Icon icon="simple-line-icons:plus" />
                        </span>
                        <span>Add Upload</span>
                      </Button>

                      {components?.map((component) => (
                        <UploadSquareBoxes
                          key={component?.id}
                          imageUrl={component?.path}
                          setImageUrl={setImageUrl}
                          handleRemove={handleRemoveComponent}
                          setComponents={setComponents}
                          id={component?.id}
                          setLoading={setImageLoading}
                          isLoading={imageLoading[component?.id]}
                          onChange={(fileId) =>
                            handleFileChange(component?.id, fileId)
                          }
                          fileTypeId={38}
                        />
                      ))}
                    </Box>
                  </Box>
                </Grid>
              </React.Fragment>
            </Grid>
            <ActionButtons
              onSubmit={handleSubmit(onSubmit)}
              onReset={reset}
              submitLoading={createLoading}
              submitText={id ? "Update" : "Create"}
            />
          </form>
        )}
      </GlassCard>
    </>
  );
};

export default CreatePurchaseForms;
