import { yupResolver } from '@hookform/resolvers/yup';
import { Icon } from '@iconify/react';
import { Box, Button, Stack } from '@mui/material';
import React, { useEffect, useRef, useState } from 'react';
import { useForm } from 'react-hook-form';
import { toast } from 'react-toastify';
import Crud_Service from '../../../../../apis/CrudService.jsx';
import TableActionButtons from '../../../../../components/_form/TableActionButtons.jsx';
import FmSearchableSelect from '../../../../../components/_mui/FmSearchableSelect.tsx';
import FmTextField from '../../../../../components/_mui/FmTextField.tsx';
import FmMRTDataTable from '../../../../../components/FmMRTDataTable.tsx';
import useTableLogic from '../../../../../components/helpers/MRTUseTableLogic.tsx';
import useLoader from '../../../../../components/helpers/UseLoader.tsx';
import GlassCard from '../../../../../components/small/GlassCard.tsx';
import { FTItemsSchema } from '../../../../../utils/CommonvalidationSchemas.tsx';
import MuiDialogOne from '../../../../../components/_mui/MuiDialogOne.jsx';
import { useParams } from 'react-router-dom';
import FmFileInput from '../../../../../components/_mui/FmFileInput.tsx';
import { useHotkeys } from 'react-hotkeys-hook';
import useCommonFetchApi from '../../../../../components/helpers/useCommonFetchApi.tsx';
import { ViewField } from '../../../../../components/_form/FormElements.jsx';
import { FileType } from '../../../../../constants/FileType.ts';
import CurrencyView from '../../../../../components/_mui/FmCurrencyView.tsx';
import FmCurrencyTextField from '../../../../../components/_mui/FmCurrencyTextField.tsx';
import BillingFooter from '../../../../../components/view/BillingFooter.tsx';
import { pettyCashStatusEnum } from '../../../../../utils/CommonVariables.tsx';

// Create interfaces for the data structures
interface SalesOrder {
  salesOrderId: string;
  salesOrderCode: string;
  salesOrderName: string;
  contractId: string;
  contractName: string;
  contractCode: string;
  projectId: string;
  projectName: string;
  projectCode: string;
}

interface Division {
  divisionId: string;
  divisionName: string;
  divisionCode: string;
  inchargeName: string;
}

interface Employee {
  employeeId: string;
  fullName: string;
  employeeCode: string;
}

interface CostCenter {
  costCenterId: string;
  costCenterName: string;
  costCenterCode: string;
}

// Create custom hooks for each piece of logic
const useSalesOrderEffect = (
  salesorders: SalesOrder[],
  selectedSalesId: string,
  setValue: any
) => {
  useEffect(() => {
    if (!salesorders || !selectedSalesId) return;

    const selectedSODetail = salesorders.find(
      (sales) => sales.salesOrderId === selectedSalesId
    );

    if (selectedSODetail) {
      // Set all the related fields
      setValue('salesOrderId', selectedSODetail.salesOrderId);
      setValue('salesOrderCode', selectedSODetail.autoSalesOrderCode);
      setValue('salesOrderName', selectedSODetail.salesOrderName);

      // Contract related fields
      setValue('contractId', selectedSODetail.contractId ?? '');
      setValue('contractName', selectedSODetail.contractName ?? '');
      setValue('contractCode', selectedSODetail.contractCode ?? '');

      // Project related fields
      setValue('projectId', selectedSODetail.projectId ?? '');
      setValue('projectName', selectedSODetail.projectName ?? '');
      setValue('projectCode', selectedSODetail.projectCode ?? '');
    }
  }, [selectedSalesId, salesorders, setValue]);
};

const useDivisionEffect = (
  divisions: Division[],
  selectedDivisionId: string,
  setValue: any
) => {
  useEffect(() => {
    if (!divisions || !selectedDivisionId) return;

    const selectedDivisionDetail = divisions.find(
      (division) => division?.divisionId === selectedDivisionId
    );

    if (selectedDivisionDetail) {
      setValue('divisionName', selectedDivisionDetail.divisionName ?? '');
      setValue('divisionCode', selectedDivisionDetail.divisionCode ?? '');
      setValue('inchargeName', selectedDivisionDetail.inchargeName ?? '');
    }
  }, [selectedDivisionId, divisions, setValue]);
};

const useEmployeeEffect = (
  employees: Employee[],
  selectedEmployeeId: string,
  setValue: any
) => {
  useEffect(() => {
    if (!employees || !selectedEmployeeId) return;

    const selectedEmployeeDetail = employees.find(
      (employee) => employee?.employeeId === selectedEmployeeId
    );

    if (selectedEmployeeDetail) {
      setValue('employeeName', selectedEmployeeDetail.fullName ?? '');
      setValue('employeeCode', selectedEmployeeDetail.employeeCode ?? '');
    }
  }, [selectedEmployeeId, employees, setValue]);
};

const useCostCenterEffect = (
  costCenters: CostCenter[],
  selectedCCDetail: string,
  setValue: any
) => {
  useEffect(() => {
    if (!costCenters || !selectedCCDetail) return;

    const selectedCostCenterDetail = costCenters.find(
      (costCenter) => costCenter?.costCenterId === selectedCCDetail
    );

    if (selectedCostCenterDetail) {
      setValue('costCenterName', selectedCostCenterDetail.costCenterName ?? '');
      setValue('costCenterCode', selectedCostCenterDetail.costCenterCode ?? '');
    }
  }, [selectedCCDetail, costCenters, setValue]);
};

const ListViewPettyCashItem = (props) => {
  const { pettyCashTransactionId, exchangeRate, pettyCashStatusId } = props;
  const [editingRowId, setEditingRowId] = useState(null);
  const [editingRowData, setEditingRowData] = useState(null);
  const id = useParams()?.id;
  const [openUpload, setOpenUpload] = useState(false);
  const [filesData, setFilesData] = useState(null);
  const crud = new Crud_Service();
  const firstColumnInputRef = useRef(null);
  const [loader, setLoader] = useState(false);
  const [editingRowIndex, setEditingRowIndex] = useState(null);
  const [stateError, setStateError] = useState(false);
  const { data: salesorders } = useCommonFetchApi('salesorders');
  const { data: divisions } = useCommonFetchApi('divisions');
  const { data: employees } = useCommonFetchApi('employees');
  const { data: costCenters } = useCommonFetchApi('costCenters');
  const [uploadLoading, setUploadLoading] = useState(false);
  const [uploadedfileData, setUploadedfileData] = useState(null);
  const [formValues, setFormValues] = useState({});
  const [billingValues, setBillingValues] = useState([
    { name: 'Items', value: 0 },
    { name: 'VAT', value: 0 },
    { name: 'Amount', value: 0 },
    { name: 'Total', value: 0 },
  ]);
  const specialLabels = ['VAT']; //for reference
  const currencyLabels = ['Amount', 'Total']; //for reference
  const [rowState, setRowState] = useState({
    editingRowId: null,
    newRowId: null,
    isCreatingRow: false,
  });
  const { createLoading, startCreateLoading, stopCreateLoading } = useLoader();
  const {
    control,
    setValue,
    reset,
    getValues,
    watch,
    formState: { isValid },
  } = useForm({
    resolver: yupResolver(FTItemsSchema),
    mode: 'all',
  });
  const selectedSalesId = watch('salesOrderId');
  const selectedDivisionId = watch('divisionId');
  const selectedEmployeeId = watch('employeeId');
  const selectedCCDetail = watch('costCenterId');
  const previousValues = useRef(getValues());

  const {
    rows,
    setRows,
    isLoading,
    pagination,
    sorting,
    columnFilters,
    globalFilter,
    tableRecordCounts,
    setPagination,
    setSorting,
    setColumnFilters,
    setGlobalFilter,
    fetchData,
    setSearchKeyword,
    searchKeyword,
  } = useTableLogic(
    'pettycashtransactionitems',
    ['pettyCashTransactionId'],
    [id]
  );

  useSalesOrderEffect(salesorders, selectedSalesId, setValue);
  useDivisionEffect(divisions, selectedDivisionId, setValue);
  useEmployeeEffect(employees, selectedEmployeeId, setValue);
  useCostCenterEffect(costCenters, selectedCCDetail, setValue);

  const handleFileUpload = async (e) => {
    const file = e.target.files[0];
    setUploadLoading(true);
    if (file) {
      setUploadedfileData(null);
      const fileFormData = new FormData();
      fileFormData.append('file', file);
      fileFormData.append('fileTypeId', FileType.PettyCash);

      await crud.create(`/files/upload`, fileFormData, (err, res) => {
        if (res?.status === 200) {
          setUploadLoading(false);
          setUploadedfileData(res?.data);
        } else {
          setUploadLoading(false);
        }
      });
    }
  };

  const handleAddNewRow = () => {
    const newId = 'new_row_' + Date.now();
    reset();
    setEditingRowData(null);
    setEditingRowId(null);
    setUploadedfileData(null);
    setRowState({
      newRowId: newId,
      isCreatingRow: true,
    });
    setEditingRowIndex(
      editingRowIndex === rows.length ? editingRowIndex + 1 : rows.length
    );
    setRows((prevRows) => [
      ...prevRows,
      {
        pettyCashTransactionItemId: newId,
        pettyCashTransactionId: pettyCashTransactionId,
        salesOrderId: '',
        costCenterId: '',
        employeeId: '',
        divisionId: '',
        inchargeId: '',
        chartOfAccountId: '',
        amount: '',
        taxId: '',
        vat: '',
        totalAmount: '',
        fileId: null,
        remarks: '',
        isEditable: true,
        isNew: true,
      },
    ]);
    // reset();
  };

  const handleBlurAndFetch = async () => {
    setLoader(true);

    const values = getValues();

    const updateCombinedData = {
      ...values,
      pettyCashTransactionId: pettyCashTransactionId,
      fileId: uploadedfileData?.fileId || values?.fileId,
      status: 2,
    };

    await crud.update(
      'pettycashtransactionitems',
      editingRowId,
      updateCombinedData,
      (err, res) => {
        if (res?.status === 200) {
          const totalRows = rows.length;
          if (editingRowIndex <= totalRows && rows.some((row) => row.isNew)) {
            const updatedRow = res?.data;
            const updatedRows = [...rows];
            updatedRows[editingRowIndex] = {
              ...updatedRow,
              isEditable: true,
              isNew: true,
            };
            setRows(updatedRows);
            handleAddNewRow();
          } else {
            const updatedRow = res?.data;
            const updatedRows = [...rows];
            updatedRows[editingRowIndex] = {
              ...updatedRow,
              isEditable: false,
              isNew: false,
            };

            setRows(updatedRows);
            setStateError(false);
            setLoader(false);
          }
        } else {
          setStateError(true);
        }
      }
    );
  };

  const handleCancel = (row) => {
    setFormValues(null);
    if (row?.original?.pettyCashTransactionItemId === rowState?.newRowId) {
      setRows((prevRows) => {
        if (prevRows?.length > 0) {
          return prevRows?.slice(0, -1);
        }
        return prevRows;
      });

      setRowState((prevState) => ({
        ...prevState,
        newRowId: null,
        isCreatingRow: false,
      }));
    } else {
      setEditingRowId(null);
    }

    setUploadedfileData(null);
    reset();
  };

  const handleEdit = (row) => {
    if (pettyCashStatusId === pettyCashStatusEnum?.Approved ||
      pettyCashStatusId === pettyCashStatusEnum?.Paid
    ) {
      return;
    }
    reset();
    setUploadedfileData(null);
    if (rowState?.newRowId) {
      setRows((prevRows) => {
        if (prevRows?.length > 0) {
          return prevRows?.slice(0, -1);
        }
        return prevRows;
      });
      setRowState((prevState) => ({
        ...prevState,
        newRowId: null,
        isCreatingRow: false,
      }));
    }
    setEditingRowId(row?.pettyCashTransactionItemId);
    Object.entries({
      ...row,
    }).forEach(([key, value]) => {
      setValue(key, value);
    });
    setEditingRowData({ ...row });
  };

  useHotkeys(
    'Tab',
    (event) => {
      if (event.key !== 'Tab') return;
      if (stateError) {
        event.preventDefault();
        return;
      }
      const totalRows = rows.length;
      const nextRowIndex = editingRowIndex + 1;

      if (
        nextRowIndex < totalRows &&
        stateError === false &&
        loader === false
      ) {
        handleEdit(rows[nextRowIndex]);
        setEditingRowIndex(nextRowIndex);
      }
    },
    [editingRowIndex, rows, stateError, loader, isValid]
  );

  useHotkeys('Enter', () => handleAddNewRow());

  useEffect(() => {
    if (editingRowIndex !== null && firstColumnInputRef.current && !isLoading) {
      firstColumnInputRef.current.focus();
    }
  }, [editingRowIndex, isLoading]);

  useEffect(() => {
    if (editingRowData) {
      Object.entries(editingRowData).forEach(([key, value]) => {
        setValue(key, value);
      });
    }
  }, [editingRowData, setValue]);

  const handleBlur = () => {
    const currentValues = getValues();
    const value = getValues('amount');
    if (!value) {
      return;
    }
    const hasChanges = Object.keys(currentValues).some(
      (key) => currentValues[key] !== previousValues.current[key]
    );

    if ((hasChanges && isValid) || (hasChanges && editingRowId)) {
      apiCallAsync(currentValues);
      previousValues.current = currentValues;
    }
  };

  const apiCallAsync = async (values) => {
    setLoader(true);
    let fileIdToUse = uploadedfileData?.fileId;
    if (editingRowId === null) {
      const convertedValues = {
        ...values,
        amount: Number(values.amount),
        pettyCashTransactionId: pettyCashTransactionId,
        fileId: fileIdToUse,
        vat: Number(values.vat) || 0,
      };

      try {
        await crud.create(
          'pettycashtransactionitems',
          convertedValues,
          (err, res) => {
            if (res?.status === 201) {
              setEditingRowId(res?.data?.pettyCashTransactionItemId);
              setStateError(false);
              setEditingRowData({});
              setUploadedfileData(null);
            } else {
              setStateError(true);
            }
            setLoader(false);
          }
        );
      } catch (error) {
        setStateError(true);
        setLoader(false);
      }
    } else {
      const updatedCombinedData = {
        ...values,
        amount: Number(values.amount),
        pettyCashTransactionId: pettyCashTransactionId,
        status: 2,
        fileId: fileIdToUse || values?.fileId,
      };

      try {
        await crud.update(
          'pettycashtransactionitems',
          editingRowId,
          updatedCombinedData,
          (err, res) => {
            if (res?.status === 200) {
              const updatedRow = res?.data;
              const updatedRows = [...rows];
              updatedRows[editingRowIndex] = {
                ...updatedRow,
                isEditable: rows.some((row) => row.isNew),
                isNew: rows.some((row) => row.isNew),
              };
              setRows(updatedRows);
              setStateError(false);
              setUploadedfileData(null);
            } else {
              setStateError(true);
            }
            setLoader(false);
          }
        );
      } catch (error) {
        setStateError(true);
        setLoader(false);
      }
    }
  };

  const handleDelete = async (props) => {
    await crud.remove(
      'pettycashtransactionitems',
      props?.pettyCashTransactionItemId,
      (__err, res) => {
        if (res?.status === 204) {
          toast.success('Petty Cash Item Deleted Successfully');
          fetchData();
        } else {
        }
      }
    );
  };
  useEffect(() => {
    if (!rows?.length) return; // Exit early if no rows exist

    // Extract values
    let amount = parseFloat(formValues?.amount) || 0;
    let vatPercentage = parseFloat(formValues?.vat) || 0;
    let deductionAmount = parseFloat(formValues?.deductionAmount) || 0;

    // Calculate VAT amount and total amount
    let vatAmount = (amount * vatPercentage) / 100;
    let totalAmount = amount + vatAmount;

    if (editingRowId !== null && (formValues?.amount || formValues?.vat)) {
      deductionAmount = parseFloat(formValues?.amount) || editingRowData?.amount || 0;
      vatPercentage = parseFloat(formValues?.vat) || editingRowData?.vat || 0;

      // Calculate discount and deductions
      const discountAmount = deductionAmount * (vatPercentage / 100);
      totalAmount = discountAmount + deductionAmount;

      setValue('totalAmount', totalAmount?.toFixed(2));
      setValue('vatAmount', vatAmount.toFixed(2));  // Ensure this is calculated correctly
    }

    // Filter rows based on editing state
    const filteredRows = editingRowId
      ? rows.filter((row) => row.pettyCashTransactionItemId !== editingRowId)
      : rows;

    // Helper functions
    const sumField = (field) =>
      filteredRows.reduce((sum, row) => sum + (parseFloat(row[field]) || 0), 0);

    const averageField = (field) =>
      filteredRows.length
        ? (sumField(field) / filteredRows.length).toFixed(2)
        : '0.00';

    // Compute aggregated values
    const vatAvg = averageField('vat');
    const totalSumAmount = sumField('amount');
    const totalSumvatTotal = sumField('vatAmount');
    const totalSum = sumField('totalAmount'); // Ensure this calculation is correct

    // Update billing values
    setBillingValues((prev) =>
      prev.map((item) => {
        const fieldValues = {
          Items: rows.length,
          Amount: totalSumAmount + deductionAmount,
          VAT: parseFloat(vatAvg).toFixed(2),
          vatAmount: totalSumvatTotal.toFixed(2),
          Total: (totalSum + totalAmount).toFixed(2),
        };

        return fieldValues[item.name] !== undefined
          ? { ...item, value: fieldValues[item.name] }
          : item;
      })
    );
  }, [rows, formValues?.vat, formValues?.amount]);


  const ActionData = [
    {
      name: 'Delete',
      icon: <Icon icon='solar:trash-bin-minimalistic-broken' />,
      onClick: (props) => handleDelete(props),
    },
  ];

  const columns = [
    {
      accessorKey: 'salesOrderId',
      header: 'Sales Order',
      enableEditing: true,
      Cell: ({ row }) => {
        const isNewRow =
          row?.original?.pettyCashTransactionItemId === rowState?.newRowId;
        const isEditing =
          editingRowId === row?.original?.pettyCashTransactionItemId ||
          isNewRow;
        return isEditing ? (
          <FmSearchableSelect
            name='salesOrderId'
            control={control}
            apiUrl='salesorders/order-data'
            valueField='salesOrderId'
            headerField={['Sales Order', 'Project', 'Contract']}
            labelField={['salesOrderCode', 'projectName', 'contractName']}
            stackedFields={{
              salesOrderCode: [
                { Code: 'salesOrderCode' },
                { Name: 'salesOrderName' },
              ],
              projectName: [{ Name: 'projectName' }, { Code: 'projectCode' }],
              contractName: [
                { Name: 'contractName' },
                { Code: 'contractCode' },
              ],
            }}
            showField={['salesOrderCode', 'salesOrderName']}
            label='Sales Order'
            rules={{ required: 'Sales Order is required' }}
            defaultValue={{
              salesOrderId: editingRowData?.salesOrderId,
              salesOrderName: editingRowData?.salesOrderName,
            }}
            required
          />
        ) : (
          <div className='flex flex-col w-full'>
            <div className='flex items-center gap-1'>
              <span>Code:</span>
              {row?.original?.salesOrderCode}
            </div>
            <div className='font-semibold'>{row?.original?.salesOrderName}</div>
          </div>
        );
      },
    },

    {
      accessorKey: 'contractName',
      header: 'Contract',
      enableEditing: true,
      Cell: ({ row }) => {
        const isNewRow =
          row?.original?.pettyCashTransactionItemId === rowState?.newRowId;
        const isEditing =
          editingRowId === row?.original?.pettyCashTransactionItemId ||
          isNewRow;
        return isEditing ? (
          <ViewField label='' title={watch('contractName')} />
        ) : (
          <div className='flex flex-col w-full'>
            <div className='flex items-center gap-1'>
              <span>Code:</span>
              {row?.original?.contractCode}
            </div>
            <div className='font-semibold'>{row?.original?.contractName}</div>
          </div>
        );
      },
    },

    {
      accessorKey: 'projectName',
      header: 'Projects',
      enableEditing: true,
      Cell: ({ row }) => {
        const isNewRow =
          row?.original?.pettyCashTransactionItemId === rowState?.newRowId;
        const isEditing =
          editingRowId === row?.original?.pettyCashTransactionItemId ||
          isNewRow;
        return isEditing ? (
          <ViewField label='' title={watch('projectName')} />
        ) : (
          <div className='flex flex-col w-full'>
            <div className='flex items-center gap-1'>
              <span>Code:</span>
              {row?.original?.projectCode}
            </div>
            <div className='font-semibold'>{row?.original?.projectName}</div>
          </div>
        );
      },
    },
    {
      accessorKey: 'costCenterId',
      header: 'Cost Center',
      enableEditing: true,
      Cell: ({ row }) => {
        const isNewRow =
          row?.original?.pettyCashTransactionItemId === rowState?.newRowId;
        const isEditing =
          editingRowId === row?.original?.pettyCashTransactionItemId ||
          isNewRow;
        return isEditing ? (
          <FmSearchableSelect
            name='costCenterId'
            control={control}
            apiUrl='costCenters'
            valueField='costCenterId'
            labelField={['costCenterCode', 'costCenterName']}
            showField={['costCenterCode', 'costCenterName']}
            label='Cost Center'
            onBlur={handleBlur}
            defaultValue={{
              costCenterName: editingRowData?.costCenterName,
              costCenterId: editingRowData?.costCenterId,
            }}
          />
        ) : (
          <div className='flex flex-col w-full'>
            <div className='flex items-center gap-1'>
              <span>Code:</span>
              {row?.original?.costCenterCode}
            </div>
            <div className='font-semibold'>{row?.original?.costCenterName}</div>
          </div>
        );
      },
    },
    {
      accessorKey: 'employeeId',
      header: 'Employee',
      enableEditing: true,
      Cell: ({ row }) => {
        const isNewRow =
          row?.original?.pettyCashTransactionItemId === rowState?.newRowId;
        const isEditing =
          editingRowId === row?.original?.pettyCashTransactionItemId ||
          isNewRow;
        return isEditing ? (
          <FmSearchableSelect
            name='employeeId'
            control={control}
            apiUrl='employees'
            valueField='employeeId'
            labelField={['employeeCode', 'fullName', 'emailId']}
            showField={['employeeCode', 'fullName', 'emailId']}
            headerField={['Code', 'Name', 'Email']}
            label='Employees'
            onBlur={handleBlur}
            defaultValue={{
              employeeId: editingRowData?.employeeId,
              firstName: editingRowData?.employeeName,
            }}
          />
        ) : (
          <div className='flex flex-col w-full'>
            <div className='flex items-center gap-1'>
              <span>Code:</span>
              {row?.original?.employeeCode}
            </div>
            <div className='font-semibold'>{row?.original?.employeeName}</div>
          </div>
        );
      },
    },
    {
      accessorKey: 'divisionId',
      header: 'Division',
      enableEditing: true,
      Cell: ({ row }) => {
        const isNewRow =
          row?.original?.pettyCashTransactionItemId === rowState?.newRowId;
        const isEditing =
          editingRowId === row?.original?.pettyCashTransactionItemId ||
          isNewRow;

        return isEditing ? (
          <FmSearchableSelect
            name='divisionId'
            control={control}
            apiUrl='divisions'
            valueField='divisionId'
            labelField={['divisionCode', 'divisionName']}
            showField={['divisionCode', 'divisionName']}
            label='Division'
            onBlur={handleBlur}
            defaultValue={{
              divisionId: editingRowData?.divisionId,
              divisionName: editingRowData?.divisionName,
            }}
          />
        ) : (
          <div className='flex flex-col w-full'>
            <div className='flex items-center gap-1'>
              <span>Code:</span>
              {row?.original?.divisionCode}
            </div>
            <div className='font-semibold'>{row?.original?.divisionName}</div>
          </div>
        );
      },
    },

    {
      accessorKey: 'inchargeId',
      header: 'In Charge',
      enableEditing: true,
      Cell: ({ row }) => {
        const isNewRow =
          row?.original?.pettyCashTransactionItemId === rowState?.newRowId;
        const isEditing =
          editingRowId === row?.original?.pettyCashTransactionItemId ||
          isNewRow;

        return isEditing ? (
          <ViewField label='' title={watch('inchargeName')} />
        ) : (
          row?.original?.inchargeName || ''
        );
      },
    },

    {
      accessorKey: 'chartOfAccountId',
      header: 'Accounts',
      enableEditing: true,
      Cell: ({ row }) => {
        const isNewRow =
          row?.original?.pettyCashTransactionItemId === rowState?.newRowId;
        const isEditing =
          editingRowId === row?.original?.pettyCashTransactionItemId ||
          isNewRow;

        return isEditing ? (
          <FmSearchableSelect
            name='chartOfAccountId'
            control={control}
            apiUrl='chartofaccounts'
            valueField='chartOfAccountId'
            labelField={['chartOfAccountCode', 'chartOfAccountName']}
            showField={['chartOfAccountCode', 'chartOfAccountName']}
            label='Accounts'
            onBlur={handleBlur}
            defaultValue={{
              chartOfAccountId: row?.original?.chartOfAccountId,
              chartOfAccountName: row?.original?.chartOfAccountName,
            }}
          />
        ) : (
          row?.original?.chartOfAccountName || ''
        );
      },
    },
    {
      accessorKey: 'taxId',
      header: 'Tax Code',
      enableEditing: true,
      size: 160,
      Cell: ({ row }) => {
        const isNewRow =
          row?.original?.pettyCashTransactionItemId === rowState?.newRowId;
        const isEditing =
          editingRowId === row?.original?.pettyCashTransactionItemId ||
          isNewRow;

        return isEditing ? (
          <FmSearchableSelect
            name='taxId'
            control={control}
            apiUrl='taxcodes'
            valueField='taxCodeId'
            labelField={['taxCodeCode', 'taxCodeName']}
            showField={['taxCodeCode', 'taxCodeName']}
            label='Tax Code'
            onBlur={handleBlur}
            defaultValue={{
              taxId: editingRowData?.taxId,
              taxCodeName: editingRowData?.taxCodeName,
            }}
          />
        ) : (
          <div className='flex flex-col w-full'>
            <div className='flex items-center gap-1'>
              <span>Code:</span>
              {row?.original?.taxCodeCode}
            </div>
            <div className='font-semibold'>{row?.original?.taxCodeName}</div>
          </div>
        );
      },
    },

    {
      accessorKey: 'amount',
      header: 'Amount',
      enableEditing: true,
      Cell: ({ row }) => {
        const isNewRow =
          row?.original?.pettyCashTransactionItemId === rowState?.newRowId;
        const isEditing =
          editingRowId === row?.original?.pettyCashTransactionItemId ||
          isNewRow;

        return isEditing ? (
          <FmCurrencyTextField
            name='amount'
            control={control}
            label='Amount'
            labelRemove={true}
            type='number'
            onBlur={handleBlur}
            onChange={(e) =>
              setFormValues((prevValues) => ({
                ...prevValues,
                amount: e.target.value,
              }))
            }
            required
            rules={{ required: 'Amount is required' }}
          />
        ) : (
          <CurrencyView value={row?.original?.amount} currencyType={'AED'} />
        );
      },
    },
    {
      accessorKey: 'vat',
      header: 'VAT (%)',
      size: 100,
      enableEditing: true,
      Cell: ({ row }) => {
        const isNewRow =
          row?.original?.pettyCashTransactionItemId === rowState?.newRowId;
        const isEditing =
          editingRowId === row?.original?.pettyCashTransactionItemId ||
          isNewRow;

        return isEditing ? (
          <FmTextField
            name='vat'
            control={control}
            label='VAT'
            pattern='Percentage'
            onChange={(e) =>
              setFormValues((prevValues) => ({
                ...prevValues,
                vat: e.target.value,
              }))
            }
            onBlur={handleBlur}
          />
        ) : row?.original?.vat ? (
          `${row?.original?.vat}`
        ) : (
          ''
        );
      },
    },
    {
      accessorKey: 'vatAmount',
      header: 'VAT Amount',
      enableEditing: true,
      Cell: ({ row }) => {
        const isNewRow =
          row?.original?.pettyCashTransactionItemId === rowState?.newRowId;
        const isEditing =
          editingRowId === row?.original?.pettyCashTransactionItemId ||
          isNewRow;
        const vatAmount = watch('vatAmount');  // This should now reflect the correct value from formValues

        return isEditing ? (
          <ViewField label={`VAT Amount`} title={vatAmount} />
        ) : (
          <CurrencyView
            value={row?.original?.vatAmount}  // Ensure this value is being correctly passed
            currencyType={'AED'}
          />
        );
      },
    },
    {
      accessorKey: 'totalAmount',
      header: 'Total',
      enableEditing: true,
      Cell: ({ row }) => {
        const isNewRow =
          row?.original?.pettyCashTransactionItemId === rowState?.newRowId;
        const isEditing =
          editingRowId === row?.original?.pettyCashTransactionItemId ||
          isNewRow;
        const totalAmount = watch('totalAmount');
        return isEditing ? (
          <ViewField label={`Total Amount`} title={totalAmount} />
        ) : (
          <CurrencyView
            value={row?.original?.totalAmount}
            currencyType={'AED'}
          />
        );
      },
    },

    {
      accessorKey: 'fileId',
      header: 'Upload',
      enableEditing: true,
      Cell: ({ row }) => {
        const isNewRow =
          row?.original?.pettyCashTransactionItemId === rowState?.newRowId;
        const isEditing =
          editingRowId === row?.original?.pettyCashTransactionItemId ||
          isNewRow;

        return isEditing ? (
          <FmFileInput
            name='fileId'
            documentName={
              uploadedfileData?.originalFileName ||
              row?.original?.fileName ||
              ''
            }
            onChange={handleFileUpload}
            loading={uploadLoading}
          />
        ) : (
          row?.original?.fileName || ''
        );
      },
    },

    {
      accessorKey: 'remarks',
      header: 'Remarks',
      enableEditing: true,
      Cell: ({ row }) => {
        const isNewRow =
          row?.original?.pettyCashTransactionItemId === rowState?.newRowId;
        const isEditing =
          editingRowId === row?.original?.pettyCashTransactionItemId ||
          isNewRow;
        return isEditing ? (
          <FmTextField
            name='remarks'
            control={control}
            multiline={true}
            rows={2}
            maxRows={3}
            onBlur={handleBlurAndFetch}
          />
        ) : (
          row?.original?.remarks
        );
      },
    },
    {
      header: 'Action',
      enableColumnPinning: true,
      enableEditing: false,
      enableSorting: false,
      size: 100,
      muiTableBodyCellProps: {
        className: 'sticky-pinning-right',
      },
      muiTableHeadCellProps: {
        className: 'sticky-pinning-right',
      },
      Cell: ({ row, table }) => {
        const isNewRow =
          row?.original?.pettyCashTransactionItemId === rowState?.newRowId;
        const isEditing =
          editingRowId === row?.original?.pettyCashTransactionItemId ||
          isNewRow;

        const isLastRow = row.index === table.getRowModel().rows.length - 1;

        return (
          <Box className='flex gap-2'>
            {isEditing ? (
              <>
                <Button
                  onClick={() => handleCancel(row)}
                  className='py-0.5 px-2'
                  variant='contained'
                  color='error'
                >
                  Close
                </Button>
                {isLastRow && rowState?.isCreatingRow === false && (
                  <Button
                    onFocus={() => handleAddNewRow()}
                    hidden={true}
                  ></Button>
                )}
              </>
            ) : (
              <Box className='flex gap-2'>
                <TableActionButtons
                  Actions={ActionData?.map((action) => ({
                    ...action,
                    onClick: () => action.onClick(row?.original),
                  }))}
                />
                {isLastRow &&
                  (pettyCashStatusId !== pettyCashStatusEnum?.Approved &&
                    pettyCashStatusId !== pettyCashStatusEnum?.Paid) && (
                    <Button
                      variant='contained'
                      color='primary'
                      className='px-3 py-1'
                      onClick={() => {
                        setEditingRowIndex(row?.index + 1);
                        handleAddNewRow();
                      }}
                    >
                      Add
                    </Button>
                  )}

              </Box>
            )}
          </Box>
        );
      },
    },
  ];

  const handleClose = () => {
    setOpenUpload(false);
    setFilesData(null);
  };
  const handleUploadSheet = (event) => {
    startCreateLoading();
    const file = event.target.files[0];
    if (file) {
      const fileFormData = new FormData();
      fileFormData.append('file', file);
      fileFormData.append('fileTypeId', 59);

      crud.create('files/upload', fileFormData, (_err, res) => {
        setFilesData(res?.data);
        if (res?.status === 200) {
          crud.create(
            `pettycashtransactionitems/${id}/process/${res.data?.fileId}`,
            '',
            (_err, res) => {
              if (res?.status === 200) {
                stopCreateLoading();
                toast.success('Receipt Items Uploaded Successfully');
                fetchData();
                setOpenUpload(false);
              } else {
                stopCreateLoading();
              }
            }
          );
        } else {
          setOpenUpload(false);
          stopCreateLoading();
        }
      });
    }
  };
  return (
    <Box className=' flex flex-col flex-1 min-h-0'>
      <GlassCard className='h-[400px] my-5'>
        <FmMRTDataTable
          rows={rows}
          columns={columns}
          onCreateRow={
            pettyCashStatusId === pettyCashStatusEnum?.Approved || pettyCashStatusId === pettyCashStatusEnum?.Paid
              ? undefined
              : handleAddNewRow
          }
          isCreatingRow={rowState?.isCreatingRow}
          enableRowSelection={false}
          rowCount={tableRecordCounts}
          editingRow={editingRowId}
          pagination={pagination}
          fetchData={fetchData}
          sorting={sorting}
          columnFilters={columnFilters}
          globalFilter={globalFilter}
          setPagination={setPagination}
          setSorting={setSorting}
          setColumnFilters={setColumnFilters}
          setGlobalFilter={setGlobalFilter}
          isLoading={isLoading}
          setSearchKeyword={setSearchKeyword}
          searchKeyword={searchKeyword}
          handleRowDoubleClick={(row) => {
            handleEdit(row?.original);
            setEditingRowIndex(row?.index);
          }}
          showSerialNumber={true}
          muiTableContainerProps={{
            sx: {
              maxHeight: '100%',
              flex: 1,
            },
          }}
        />
        <MuiDialogOne
          title='Upload Sheet'
          open={openUpload}
          onClose={handleClose}
        >
          <FmFileInput
            onChange={handleUploadSheet}
            documentName={filesData?.originalFileName}
            loading={createLoading}
          />
        </MuiDialogOne>
      </GlassCard>

      <BillingFooter
        billingValues={billingValues}
        specialLabels={specialLabels}
        currencyLabels={currencyLabels}
      />
    </Box>
  );
};
export default ListViewPettyCashItem;
