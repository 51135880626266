import { useState, useEffect } from 'react';
import Crud_Service from '../CrudService';
import { toast } from 'react-toastify';

const useCrudTableHook = ({ api, viewId = 'id', deleteHandleCustom }) => {
  const crud = new Crud_Service();
  const [loading, setLoading] = useState(false);
  const [viewDetail, setViewDetail] = useState(null);
  const [singleDetail, setSingleDetails] = useState(null);

  const handleViewClick = async (view) => {
    const id = view?.[viewId];
    if (!id) return;
    setLoading(true);

    await crud.getSingle(api, id, (err, res) => {
      if (res?.status === 200) {
        setViewDetail(res?.data);
      }
      setLoading(false);
    });
  };

  const handleDelete = async (view) => {
    const id = view?.[viewId];
    if (!id) return;
    setLoading(true);
    await crud.remove(api, id, (err, res) => {
      if (res?.status === 204) {
        deleteHandleCustom();
        toast.success('Deleted Successfully');
      }
      setLoading(false);
    });
  };

  const handleSinglePageViewDetail = async (id) => {
    setLoading(true);
    await crud.getSingle(api, id, (err, res) => {
      if (res?.status === 200) {
        setSingleDetails(res?.data);
      }
      setLoading(false);
    });
  };

  return {
    loading,
    viewDetail,
    handleViewClick,
    handleDelete,
    handleSinglePageViewDetail,
    singleDetail,
  };
};

export default useCrudTableHook;
