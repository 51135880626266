import { yupResolver } from '@hookform/resolvers/yup';
import { Icon } from '@iconify/react';
import { Box, Button, Divider, Grid, Stack, TextField } from '@mui/material';
import { LocalizationProvider } from '@mui/x-date-pickers';
import { AdapterDayjs } from '@mui/x-date-pickers/AdapterDayjs';
import dayjs from 'dayjs';
import React, { useEffect, useState } from 'react';
import { useForm } from 'react-hook-form';
import { useTranslation } from 'react-i18next';
import { useLocation, useNavigate, useParams } from 'react-router-dom';
import { toast } from 'react-toastify';
import Crud_Service from '../../../apis/CrudService.jsx';
import ActionButtons from '../../../components/_form/ActionButtons.tsx';
import { ViewField } from '../../../components/_form/FormElements';
import TableActionButtons from '../../../components/_form/TableActionButtons.jsx';
import TableSearch from '../../../components/_form/TableSearch.jsx';
import UploadSquareBoxes from '../../../components/_form/UploadSquareBoxes.tsx';
import FmAutoComplete from '../../../components/_mui/FmAutoComplete.tsx';
import FmDatePicker from '../../../components/_mui/FmDatePicker.tsx';
import FmSearchableSelect from '../../../components/_mui/FmSearchableSelect.tsx';
import FmTextField from '../../../components/_mui/FmTextField.tsx';
import FmViewDateField from '../../../components/_mui/FmViewDateField.tsx';
import MuiDialogOne from '../../../components/_mui/MuiDialogOne';
import DashboardLayout from '../../../components/DashboardLayout.tsx';
import TableYesWithCaptions from '../../../components/datagrid/TableYesWithCaptions.tsx';
import DataTable from '../../../components/DataTable.tsx';
import useCommonFetchApi from '../../../components/helpers/useCommonFetchApi.tsx';
import useTableLogic from '../../../components/helpers/UseTableLogic.tsx';
import ElementLoader from '../../../components/page/ElementLoader.tsx';
import BackToButton from '../../../components/small/BackToButton.tsx';
import DataTableNoRow from '../../../components/small/DataTableNoRow.tsx';
import GlassCard from '../../../components/small/GlassCard.tsx';
import ViewFieldInline from '../../../components/view/ViewFieldInline.tsx';
import ViewStatus from '../../../components/view/ViewStatus.tsx';
import ViewWaterMarkEnq from '../../../components/view/ViewWaterMarkEnq.tsx';
import {
  ViewEnquiryValidationSchema,
  responsiblePersonSchema,
} from '../../../utils/CommonvalidationSchemas.tsx';
import AMCSheetView from './AMCSheetView.tsx';
import BidBond from './BidBond.tsx';
import DocumentUpload from './DocumentUpload.tsx';
import { statusColorsMap, statusIconMap } from './EnqExportElement.tsx';
import EnquiryProposalView from './EnquiryProposalView.tsx';
import MEPSheetView from './MEPSheetView.tsx';
import ScopeNotes from './ScopeNotes.tsx';
import ViewTenderBasedCheckList from './ViewTenderInspectionChecklist/ViewTenderBasedChecklist.tsx';
import useLoader from '../../../components/helpers/UseLoader.tsx';
import ViewFieldsHori from '../../../components/view/ViewFieldsHori.tsx';
import FmTabsOne from '../../../components/tabs/FmTabsOne.tsx';
import { COMPACT_DENSITY_FACTOR } from '@mui/x-data-grid/hooks/features/density/useGridDensity';

const gridValue = 2.4;

const ViewEnquiry = () => {
  const [values, setValues] = useState(() => {
    return parseInt(localStorage.getItem('selectedTab')) || 0;
  });
  const { id } = useParams();
  const location = useLocation();
  const { tenderResponsiblePersonId } = location.state || {};

  const [followView, setFollowView] = useState(false);
  const [followupsOpen, setFollowupsOpen] = useState(false);
  const [followupsEdit, setFollowupsEdit] = useState(false);
  const [loading, setLoading] = useState(false);
  const [tenders, setTenders] = useState({});
  const [responsePerson, setResponsePerson] = useState({});
  const [tenderFollowupsTypes, setTenderFollowupsTypes] = useState([]);
  const [contactModes, setContactModes] = useState([]);
  const [users, setUsers] = useState([]);
  const [loader, setLoader] = useState(false);
  const [counter, setCounter] = useState(1);
  const [components, setComponents] = useState([]);
  const [imageUrl, setImageUrl] = useState([]);
  const [followupsViewdata, setFollowupsViewData] = useState({});
  const [tenderResponsiblePerson, setTenderResponsiblePerson] = useState('');
  const { t } = useTranslation();
  const navigate = useNavigate();
  const reponsePersonId = tenders?.tenderResponsiblePersonId;

  const [imageLoading, setImageLoading] = useState(
    components.reduce((acc, component) => {
      acc[component.id] = false; // Initialize loading state for each component
      return acc;
    }, {})
  );
  const defaultValues = {
    tenderId: tenders?.tenderId,
    tenderName: tenders?.tenderName,
  };

  const {
    control,
    handleSubmit,
    register,
    setValue,
    reset,
    watch,
    clearErrors,
    getValues,
    setError,
    formState: { errors },
  } = useForm({
    resolver: yupResolver(ViewEnquiryValidationSchema),
    defaultValues,
    mode: 'all',
  });
  const {
    handleSubmit: handleUpdateSubmit,
    control: updateControl,
    setValue: updateSetValue,
    watch: updateWatch,
  } = useForm({
    resolver: yupResolver(responsiblePersonSchema),
    mode: 'onChange',
    defaultValues: {
      responsiblePersonEmailId: '',
      responsiblePersonContact: '',
      responsiblePersonId: '',
    },
  });
  const { createLoading } = useForm();
  const { editLoading, startEditLoading, stopEditLoading } = useLoader();
  const rescheduleDate = watch('rescheduleDate');
  const remainderDate = watch('remainderDate');
  const responsiblePersonEmailId = updateWatch('responsiblePersonEmailId');
  const responsiblePersonContact = updateWatch('responsiblePersonContact');
  const {
    rows,
    pageSize,
    pageNumber,
    setSearchKeyword,
    isLoading,
    tableRecordCounts,
    searchKeyword,
    handlePaginationModelChange,
    handleSortModelChange,
    fetchData,
  } = useTableLogic(`tenderfollowups?tenderId=${id}`);

  const crud = new Crud_Service();
  const statusOptions = [
    { statusId: 2, statusName: 'Active' },
    { statusId: 1, statusName: 'Inactive' },
  ];
  const handleChange = (event, newValue) => {
    setValues(newValue);
    localStorage.setItem('selectedTab', newValue);
  };

  useEffect(() => {
    if (tenders) {
      reset({
        tenderId: tenders?.tenderId,
        tenderName: tenders?.tenderName,
      });
    }
  }, [tenders, reset]);

  useEffect(() => {
    getAllTenderFollowupsTypes();
    getContactModes();
    getLoginUsers();
    getTenderResponsePerson();
    getTender();
  }, []);

  //get Single Tenders
  const getTender = async () => {
    setLoader(true);
    await crud.getSingle('tenders', id, (err, res) => {
      if (err) {
        toast.error('Error fetching Tenders:', err);
        return;
      }
      if (res?.status === 200) {
        setTenders(res?.data);
        setResponsePerson(res?.data);
        setLoader(false);
        setValue('tenderId', res?.data?.tenderId);
        setValue('tenderName', res?.data?.tenderName);
        updateSetValue(
          'responsiblePersonId',
          res?.data.tenderResponsiblePersonId || ''
        );
        updateSetValue(
          'responsiblePersonEmailId',
          res?.data.tenderResponsiblePersonEmailId || ''
        );
        updateSetValue(
          'responsiblePersonContact',
          res?.data.tenderResponsiblePersonContact || ''
        );
        updateSetValue(
          'responsiblePersonName',
          res?.data.tenderResponsiblePersonName || ''
        );
      }
    });
  };

  const getTenderResponsePerson = async (view) => {
    if (!tenderResponsiblePersonId) {
      return;
    }

    try {
      setLoader(true);
      await crud.getSingle(
        'tenderresponsiblepersons',
        tenderResponsiblePersonId,
        (err, res) => {
          if (err) {
            toast.error('Error fetching Tenders:', err);
            return;
          }
          if (res?.status === 200) {
            // setResponsePerson(res?.data);
            // updateSetValue(
            //   'responsiblePersonId',
            //   res?.data.responsiblePersonId || ''
            // );
            // updateSetValue(
            //   'responsiblePersonEmailId',
            //   res?.data.responsiblePersonEmailId || ''
            // );
            // updateSetValue(
            //   'responsiblePersonContact',
            //   res?.data.responsiblePersonContact || ''
            // );
            // updateSetValue(
            //   'responsiblePersonName',
            //   res?.data.responsiblePersonName || ''
            // );
          }
        }
      );
    } catch (error) {
    } finally {
      setLoader(false);
    }
  };

  //get All Tender FollowUps Types
  const getAllTenderFollowupsTypes = async () => {
    await crud.getAll('tenderfollowuptypes', '', (err, res) => {
      if (err) {
        return;
      }
      if (res?.status === 200) {
        setTenderFollowupsTypes(res?.data?.data);
      }
    });
  };
  //get All Tender FollowUps Types
  const getContactModes = async () => {
    await crud.getAll('contactmodes', '', (err, res) => {
      if (err) {
        return;
      }
      if (res?.status === 200) {
        setContactModes(res?.data?.data);
      }
    });
  };

  //get All Login Users
  const getLoginUsers = async () => {
    await crud.getAll('loginusers', '', (err, res) => {
      if (err) {
        return;
      }
      if (res?.status === 200) {
        setUsers(res?.data?.data);
      }
    });
  };

  const ViewSourceArray = [
    { id: 0, name: 'Documents' },
    { id: 1, name: 'Follow Up' },
    { id: 2, name: 'Bid Bond', hidden: !tenders?.bidBond },
    { id: 3, name: 'Scope Notes' },
    { id: 4, name: 'Inspection Checklist' },
    {
      id: 5,
      name: 'AMC Estimation',
      hidden: tenders?.tenderProjectTypeId !== 1,
    },
    {
      id: 6,
      name: 'MEP Estimation',
      hidden: tenders?.tenderProjectTypeId !== 2,
    },
    { id: 7, name: 'Proposal' },
  ];

  //View Modal tender Followps
  const handleViewClick = async (props) => {
    setFollowView(true);
    setLoading(true);
    await crud.getSingle(
      `tenderfollowups`,
      props?.tenderFollowupId,
      (_err, res) => {
        if (res?.status === 200) {
          setFollowupsViewData(res?.data);
          setLoading(false);
        } else {
        }
      }
    );
  };

  const handleEditClick = async (props) => {
    navigate(`edit/${props?.tenderFollowupId}/followup-edit`);
  };

  const handleEditTenderFollowups = async (values) => {
    const combinedData = {
      ...values,
      rescheduleDate: dayjs(values?.rescheduleDate).format('YYYY-MM-DD'),
      remainderDate: dayjs(values?.remainderDate).format('YYYY-MM-DD'),
    };

    await crud.update(
      `tenderfollowups`,
      values?.tenderFollowupId,
      combinedData,
      (err, res) => {
        if (err) {
          return;
        }
        if (res?.status === 200) {
          toast.success('Enquiry Followup Updated Successfully');
          setFollowupsEdit(false);
          fetchData();
        }
      }
    );
  };

  //Delete Followups
  const handleDelete = async (props) => {
    await crud.remove(
      `tenderfollowups`,
      props?.tenderFollowupId,
      (err, res) => {
        if (err) {
          return;
        }
        if (res?.status === 204) {
          fetchData();
          toast.success('Followps Deleted Successfully');
        }
      }
    );
  };

  const MAX_IMAGES = 5;

  const handleAddComponent = () => {
    if (components.length >= MAX_IMAGES) {
      toast.error(`You can only upload a maximum of ${MAX_IMAGES} images.`);
      return;
    }

    const currentFileIds = getValues('fileUploadIds') || [];
    if (currentFileIds.length >= 3) {
      setError('fileUploadIds', {
        type: 'manual',
        message: 'You can upload a maximum of 3 files.',
      });
      return;
    } else {
      clearErrors('fileUploadIds');
    }

    setComponents([...components, { id: counter }]);
    setCounter(counter + 1);
  };

  const handleRemoveComponent = (id) => {
    setComponents(components.filter((component) => component.id !== id));
    setImageUrl((prev) => {
      const updatedUrls = { ...prev };
      delete updatedUrls[id];
      return updatedUrls;
    });
    clearErrors('fileUploadIds');
  };
  const handleFileChange = (id, fileId) => {
    if (typeof fileId === 'number') {
      const currentFileIds = getValues('fileUploadIds') || [];
      const updatedFileIds = [...currentFileIds];
      setValue('fileUploadIds', updatedFileIds);
    }
  };

  const [selectedPerson, setSelectedPerson] = React.useState(null);

  useEffect(() => {
    updateSetValue(
      'responsiblePersonEmailId',
      selectedPerson?.employeeMail || ''
    );
    updateSetValue(
      'responsiblePersonContact',
      selectedPerson?.employeeContactNo || ''
    );
  }, [selectedPerson]);

  const ActionData = [
    // TODO
    // {
    //   name: 'View',
    //   icon: <Icon icon='solar:eye-linear' />,
    //   onClick: (props) => handleViewClick(props),
    // },
    {
      name: t('edit'),
      icon: <Icon icon='solar:pen-new-round-linear' />,
      onClick: (props) => handleEditClick(props),
    },
    {
      name: t('delete'),
      icon: <Icon icon='solar:trash-bin-2-linear' />,
      onClick: (props) => handleDelete(props),
      danger: true,
    },
  ];

  const columns = [
    {
      field: 'action',
      headerName: '',
      width: 120,
      sortable: false,
      disableClickEventBubbling: false,
      renderCell: (params) => {
        return (
          <TableActionButtons
            Actions={ActionData?.map((action) => ({
              ...action,
              onClick: () => action.onClick(params.row),
            }))}
            selectedRow={params.row}
            setSelectedRow={() => {}}
          />
        );
      },
    },
    {
      field: 'primaryContactPerson',
      headerName: 'Primary Contact Person',
      minWidth: 200,
      cellClassName: 'font-semibold',
    },
    {
      field: 'primaryContactMail',
      headerName: 'Primary Contact Mail',
      minWidth: 250,
    },
    {
      field: 'primaryContactNumber',
      headerName: 'Primary Contact Number',
      minWidth: 180,
    },
    {
      field: 'scheduleDate',
      headerName: 'Schedule Date',
      flex: 1,
      minWidth: 130,
      renderCell: (params) => {
        return FmViewDateField({ value: params?.value });
      },
    },
    {
      field: 'reminderDate',
      headerName: 'Reminder Date',
      flex: 1,
      minWidth: 130,
      renderCell: (params) => {
        return FmViewDateField({ value: params?.value });
      },
    },
    {
      field: 'tenderFollowupTypeName',
      headerName: 'Enquiry Followup Type',
      flex: 1,
      minWidth: 250,
      cellClassName: 'font-semibold',
    },

    {
      field: 'comments',
      headerName: 'Comments',
      flex: 1,
      minWidth: 130,
    },
    {
      field: 'createdDate',
      headerName: 'Created Date',
      flex: 1,
      minWidth: 130,
      renderCell: (params) => {
        return FmViewDateField({ value: params?.value });
      },
    },
    {
      field: 'updatedDate',
      headerName: 'Updated Date',
      flex: 1,
      minWidth: 130,
      renderCell: (params) => {
        return FmViewDateField({ value: params?.value });
      },
    },
    // {
    //   field: 'remainderDate',
    //   headerName: 'Remainder Date',
    //   flex: 1,
    //   minWidth: 120,

    //   renderCell: (params) => {
    //     return FmViewDateField({ value: params?.value });
    //   },
    // },
    //TODO: For Future Use
    // {
    //   field: 'document',
    //   headerName: 'Attached Document',
    //   flex: 1,
    //   minWidth: 120,
    //   renderCell: (_params) => (
    //     <IconButton aria-label='download' onClick={() => {}}>
    //       <DownloadIcon />
    //     </IconButton>
    //   ),
    // },
  ];

  const handleEditTender = async (data) => {
    const values = {
      responsiblePersonId: data?.responsiblePersonId,
      tenderId: id,
    };
    const updatedvalues = {
      responsiblePersonId: data?.responsiblePersonId,
      tenderId: id,
      statusName: 'string',
      status: 2,
    };
    if (!tenderResponsiblePersonId) {
      await crud.create('tenderresponsiblepersons', values, (_err, res) => {
        if (res?.status === 201) {
          toast.success('Tender Person Created Successfully');
          reset();
        }
      });
    } else {
      await crud.update(
        'tenderresponsiblepersons',
        tenderResponsiblePersonId,
        updatedvalues,
        (_err, res) => {
          if (res?.status === 200) {
            toast.success('Tender Person Updated Successfully');
          }
        }
      );
    }
  };
  const handleSubmitTenderFollowups = async (values) => {
    const combinedData = {
      ...values,
      rescheduleDate: dayjs(values?.rescheduleDate).format('YYYY-MM-DD'),
      remainderDate: dayjs(values?.remainderDate).format('YYYY-MM-DD'),
    };

    await crud.create(`tenderfollowups`, combinedData, (err, res) => {
      if (err) {
        return;
      }
      if (res?.status === 201) {
        toast.success('Enquiry Followup Created Successfully');
        setFollowupsOpen(false);
        fetchData();
      } else {
        return;
      }
    });
  };

  const onRowDoubleClick = (e) => {
    if (e?.row) {
      handleEditClick(e?.row);
    }
  };

  return (
    <DashboardLayout
      title={`Tender Information - ${tenders?.tenderCode}`}
      actionButtons={
        <BackToButton
          onClick={() => navigate(-1)}
          className='p-1 text-teal-700 font-semibold'
          title='Back to List'
        />
      }
      titleReverse
    >
      {/* TODO */}
      {/* <GlassCard className='w-full p-2'>
        <HorizantalSteps data={tenders?.tenderStatusTrackings} />
      </GlassCard> */}

      {/* DETAILS */}
      <GlassCard
        className='mb-4 p-3 relative overflow-hidden shadow-none'
        alignItems='center'
      >
        {loader ? (
          <Box className='my-5'>
            <ElementLoader />
          </Box>
        ) : (
          <>
            <Grid container spacing={2}>
              <Grid item md={6}>
                <Box className='mb-2'>
                  <Box className='text-sm font-semibold'>
                    {tenders?.tenderName}
                  </Box>
                  <Box className='opacity-80'>{tenders?.companyName}</Box>
                </Box>
                <Stack
                  spacing={2}
                  direction={'row'}
                  divider={<Divider orientation='vertical' flexItem />}
                >
                  <ViewFieldsHori
                    label='Enquiry Number'
                    value={tenders?.tenderNumber}
                  />
                  <ViewFieldsHori label='Code' value={tenders?.tenderCode} />
                </Stack>
                <Box className='my-2'>
                  <ViewFieldsHori
                    label={'Client'}
                    value={tenders?.clientName}
                  />
                </Box>
                <Stack
                  spacing={2}
                  direction={'row'}
                  divider={<Divider orientation='vertical' flexItem />}
                >
                  <ViewFieldsHori
                    label='Mode of Submission'
                    value={tenders?.tenderEnquiryTypeName}
                  />
                  <ViewFieldsHori
                    label='Tender Status'
                    value={
                      <Box className={`flex gap-2 items-center uppercase`}>
                        <ViewStatus
                          title={tenders?.tenderStatusName}
                          icon={statusIconMap[tenders?.tenderStatusName]}
                          type={statusColorsMap[tenders?.tenderStatusName]}
                        />
                      </Box>
                    }
                  />
                </Stack>
              </Grid>
              <Grid item md={6}>
                <Box className='flex justify-between gap-2'>
                  <Box>
                    <Box className='flex gap-10'>
                      <TableYesWithCaptions
                        name={'Bidbond'}
                        tooltipName={'Bidbond'}
                        type={tenders?.bidBond}
                      />
                      {/* {tenders?.bidBond && (
                    <>
                      <TableYesWithCaptions
                        name={'Format'}
                        tooltipName={'Format'}
                        type={tenders?.bidBondFormatName}
                        showName
                      />
                      <TableYesWithCaptions
                        name={'Type Value'}
                        tooltipName={'Type Value'}
                        type={tenders?.bidBondTypeValue}
                        showName
                      />

                      <TableYesWithCaptions
                        name={'Bid Bond Type'}
                        tooltipName={'Bid Bond Type'}
                        type={tenders?.bidBondTypeName}
                        showName
                      />
                    </>
                  )} */}
                      {/* <TableYesWithCaptions
                    name={'Is Subcontract Required'}
                    tooltipName={'Is Subcontract Required'}
                    type={tenders?.subContractRequired}
                  /> */}

                      <TableYesWithCaptions
                        name={'Express of Interest'}
                        tooltipName={'Express of Interest'}
                        type={tenders?.expressOfInterest}
                      />
                    </Box>
                    <Box className='flex gap-10 mt-2'>
                      <TableYesWithCaptions
                        name={'Buy Enquiry Document'}
                        tooltipName={'Buy Enquiry Document'}
                        type={tenders?.buyTenderDocument}
                      />
                      {/* <TableYesWithCaptions
                    name={'Auto Renewal'}
                    tooltipName={'Auto Renewal'}
                    type={tenders?.autoRenewal}
                  /> */}
                    </Box>
                  </Box>
                  <Box className='flex gap-10'>
                    <Stack spacing={1}>
                      {tenders?.expressOfInterest === true && (
                        <FmViewDateField
                          title='Express of Interest Date'
                          value={tenders?.expressOfInterestDate}
                        />
                      )}
                      <FmViewDateField
                        title='Received Date'
                        value={tenders?.receivedDate}
                      />
                      <Box>
                        {/* <Box>Enquiry Closing Date</Box> */}
                        <Box>
                          <FmViewDateField
                            title='Submission Date'
                            value={tenders?.subMissionDate}
                          />
                          {/* TODO */}
                          {/* <ViewColoredDate
                        date={tenders?.lastDate}
                        originalDate={tenders?.lastDate}
                      /> */}
                        </Box>
                      </Box>
                    </Stack>
                  </Box>
                </Box>
              </Grid>
            </Grid>
            {tenders?.tenderStatusName === 'Cancelled' && (
              <ViewWaterMarkEnq
                name={'Cancelled'}
                icon={statusIconMap[tenders?.tenderStatusName]}
              />
            )}
          </>
        )}
      </GlassCard>
      <GlassCard className='p-3 mb-2 shadow-none'>
        <form onSubmit={handleUpdateSubmit(handleEditTender)}>
          <Grid
            container
            spacing={2}
            alignItems='center'
            className='full-width'
          >
            <Grid item md={gridValue * 2}>
              <FmSearchableSelect
                name='responsiblePersonId'
                control={updateControl}
                apiUrl='loginusers'
                valueField='loginUserId'
                headerField={['Code', 'Name', 'Mail']}
                labelField={['employeeCode', 'employeeName', 'employeeMail']}
                showField={['employeeCode', 'employeeName', 'employeeMail']}
                label='Tender Responsible Person'
                onChangeProp={(e) => {
                  setSelectedPerson(e); // Update state with selected person
                }}
                defaultValue={{
                  loginUserId: responsePerson?.tenderResponsiblePersonId,
                  employeeCode: responsePerson?.tenderResponsiblePersonCode,
                  employeeName: responsePerson?.tenderResponsiblePersonName,
                }}
              />
            </Grid>

            <Grid item md={gridValue}>
              <FmTextField
                name='responsiblePersonEmailId'
                control={updateControl}
                label='Tender Responsible Person Email'
                readOnly
              />
            </Grid>
            <Grid item md={gridValue}>
              <FmTextField
                name='responsiblePersonContact'
                control={updateControl}
                label='Tender Responsible Person Contact'
                readOnly
              />
            </Grid>

            <Grid item md={gridValue}>
              <ActionButtons
                onSubmit={handleUpdateSubmit(handleEditTender)}
                submitLoading={createLoading}
                cancelLoading={false}
                submitText='Save'
                removeSpacing
              />
            </Grid>
          </Grid>
        </form>
      </GlassCard>
      <GlassCard className='my-4'>
        <FmTabsOne
          value={values}
          handleChange={handleChange}
          data={ViewSourceArray}
          centered={true}
        />
      </GlassCard>
      {values === 0 && <DocumentUpload id={id} />}
      {values === 1 && (
        <Box>
          <GlassCard className='overflow-hidden' bgWhite>
            <Box sx={{ height: '400px' }}>
              <DataTable
                rows={rows}
                columns={columns}
                loading={isLoading}
                sortingMode='server'
                paginationMode='server'
                onPaginationModelChange={(model) =>
                  handlePaginationModelChange(model)
                }
                onSortModelChange={(model) => handleSortModelChange(model)}
                onRowDoubleClick={(e) => onRowDoubleClick(e)}
                page={pageNumber - 1}
                pageSize={pageSize}
                rowCount={tableRecordCounts?.total}
                slots={{
                  toolbar: () => (
                    <Box
                      className='p-2 w-full flex justify-between items-center'
                      sx={{
                        borderBottom: '1px solid',
                        borderColor: 'border.main',
                      }}
                    >
                      <Box sx={{ maxWidth: '350px', width: '100%' }}>
                        <TableSearch
                          placeholder='Search'
                          fullWidth
                          setSearchKeyword={setSearchKeyword}
                          searchValue={searchKeyword}
                        />
                      </Box>
                      <Button
                        variant='contained'
                        color='primary'
                        className='py-1 px-3'
                        startIcon={<Icon icon='ic:round-plus' />}
                        onClick={() => {
                          // setFollowupsOpen(true);
                          // reset();
                          navigate(`followup-create`);
                        }}
                      >
                        New
                      </Button>
                    </Box>
                  ),
                  noRowsOverlay: DataTableNoRow,
                }}
              />
            </Box>
          </GlassCard>
        </Box>
      )}
      {values === 2 && <BidBond id={id} />}
      {values === 3 && <ScopeNotes />}
      {values === 4 && <ViewTenderBasedCheckList />}
      {values === 5 && <AMCSheetView />}
      {values === 6 && <MEPSheetView id={id} />}
      {values === 7 && <EnquiryProposalView type={'view'} />}

      {/*Create Followups */}
      <MuiDialogOne
        title='Create Followups'
        open={followupsOpen}
        onClose={() => setFollowupsOpen(false)}
      >
        <form onSubmit={handleSubmit(handleSubmitTenderFollowups)}>
          <LocalizationProvider dateAdapter={AdapterDayjs}>
            <Grid container spacing={2}>
              <Grid item md={12}>
                <FmTextField
                  name='tenderName'
                  control={control}
                  label='Tender'
                  defaultValue={defaultValues}
                  readOnly
                />
              </Grid>
              <Grid item md={6}>
                <FmTextField
                  name='contactPerson'
                  control={control}
                  label='Contact Person'
                  required
                />
              </Grid>
              <Grid item md={6}>
                <FmTextField
                  name='contactMail'
                  control={control}
                  label='Contact Mail'
                  required
                />
              </Grid>
              <Grid item md={6}>
                <FmTextField
                  name='contactNumber'
                  control={control}
                  label='Contact Number'
                  required
                />
              </Grid>
              <Grid item md={6}>
                <FmDatePicker
                  name='rescheduleDate'
                  label='schedule Date'
                  control={control}
                  required
                  minDate={remainderDate ? dayjs(remainderDate) : null}
                />
              </Grid>
              <Grid item md={6}>
                <FmAutoComplete
                  name='tenderFollowupTypeId'
                  control={control}
                  options={tenderFollowupsTypes}
                  label='Enquiry Followup Type'
                  displayField='tenderFollowupTypeName'
                  optionFields={['tenderFollowupTypeName']}
                  valueKey='tenderFollowupTypeId'
                  required
                />
              </Grid>
              <Grid item md={6}>
                <FmAutoComplete
                  name='contactModeId'
                  control={control}
                  options={contactModes}
                  label='Contact Modes'
                  displayField='contactModeName'
                  optionFields={['contactModeName']}
                  valueKey='contactModeId'
                  required
                />
              </Grid>

              <Grid item md={12}>
                <FmTextField
                  name='comments'
                  control={control}
                  label='Comments'
                  multiline={true}
                  rows={2}
                  maxRows={3}
                  required
                />
              </Grid>
              <Grid item md={6}>
                <FmDatePicker
                  name='remainderDate'
                  label='Remainder Date'
                  control={control}
                  required
                  maxDate={rescheduleDate ? dayjs(rescheduleDate) : null}
                />
              </Grid>
              <Grid item md={6}>
                <FmAutoComplete
                  name='assignTo'
                  control={control}
                  options={users}
                  label='Assign To'
                  displayField='employeeName'
                  optionFields={['employeeName']}
                  valueKey='loginUserId'
                  required
                />
              </Grid>
              <Grid item md={12}>
                <Box>
                  <h3 className='mb-3 font-semibold'>
                    Additional Images Upload
                  </h3>
                  <Box className='flex gap-5 flex-wrap'>
                    <Button
                      onClick={handleAddComponent}
                      sx={{
                        minWidth: '100px',
                        height: '100px',
                        border: '1px solid',
                        borderColor: 'border.main',
                        flexDirection: 'column',
                        padding: 0,
                        bgcolor: 'background.white',
                      }}
                    >
                      <span className='text-teal-600 text-3xl mb-3'>
                        <Icon icon='simple-line-icons:plus' />
                      </span>
                      <span>Add Upload</span>
                    </Button>

                    {components.map((component) => (
                      <UploadSquareBoxes
                        key={component.id}
                        imageUrl={component.path}
                        setImageUrl={setImageUrl} // If you need this, otherwise omit
                        handleRemove={handleRemoveComponent}
                        setComponents={setComponents}
                        id={component.id}
                        setLoading={setImageLoading}
                        isLoading={imageLoading[component.id]}
                        onChange={(fileId) =>
                          handleFileChange(component.id, fileId)
                        }
                        fileTypeId={38}
                      />
                    ))}
                  </Box>
                  {errors.fileUploadIds && (
                    <span style={{ color: 'red' }}>
                      {errors.fileUploadIds.message}
                    </span>
                  )}
                </Box>
              </Grid>
              <Grid item md={12}>
                <Box className='text-end mt-3'>
                  <Button
                    variant='contained'
                    sx={{ minWidth: '180px' }}
                    type='submit'
                  >
                    Submit
                  </Button>
                </Box>
              </Grid>
            </Grid>
          </LocalizationProvider>
        </form>
      </MuiDialogOne>
      {/*Update Followups */}
      <MuiDialogOne
        title='Update Followups'
        open={followupsEdit}
        onClose={() => setFollowupsEdit(false)}
      >
        {loading ? (
          <ElementLoader />
        ) : (
          <form onSubmit={handleSubmit(handleEditTenderFollowups)}>
            <LocalizationProvider dateAdapter={AdapterDayjs}>
              <Grid container spacing={2}>
                <Grid item md={6}>
                  <FmTextField
                    name='tenderName'
                    control={control}
                    label='Tender'
                    defaultValue={defaultValues}
                    readOnly
                  />
                </Grid>
                <TextField
                  {...register('tenderFollowupId')}
                  fullWidth
                  variant='filled'
                  className='hidden'
                />

                <Grid item md={6}>
                  <TextField
                    {...register('contactPerson')}
                    fullWidth
                    label='Contact Person'
                    variant='filled'
                  />
                </Grid>
                <Grid item md={6}>
                  <TextField
                    {...register('contactMail')}
                    fullWidth
                    label='Contact Mail'
                    variant='filled'
                  />
                </Grid>
                <Grid item md={6}>
                  <TextField
                    {...register('contactNumber')}
                    fullWidth
                    label='Contact Number'
                    variant='filled'
                  />
                </Grid>
                <Grid item md={6}>
                  <FmDatePicker
                    name='rescheduleDate'
                    label='schedule Date'
                    control={control}
                    required
                    minDate={remainderDate ? dayjs(remainderDate) : null}
                  />
                </Grid>
                <Grid item md={6}>
                  <FmAutoComplete
                    name='tenderFollowupTypeId'
                    control={control}
                    options={tenderFollowupsTypes}
                    label='Enquiry Followup Type'
                    displayField='tenderFollowupTypeName'
                    optionFields={['tenderFollowupTypeName']}
                    valueKey='tenderFollowupTypeId'
                  />
                </Grid>
                <Grid item md={6}>
                  <FmAutoComplete
                    name='contactModeId'
                    control={control}
                    options={contactModes}
                    label='Contact Modes'
                    displayField='contactModeName'
                    optionFields={['contactModeName']}
                    valueKey='contactModeId'
                  />
                </Grid>

                <Grid item md={12}>
                  <TextField
                    {...register('comments')}
                    fullWidth
                    label='Comments'
                    variant='filled'
                    multiline
                    rows={3}
                    maxRows={4}
                  />
                </Grid>
                <Grid item md={6}>
                  <FmDatePicker
                    name='remainderDate'
                    label='Remainder Date'
                    control={control}
                    required
                    maxDate={rescheduleDate ? dayjs(rescheduleDate) : null}
                  />
                </Grid>
                <Grid item md={6}>
                  <FmAutoComplete
                    name='assignTo'
                    control={control}
                    options={users}
                    label='Assign To'
                    displayField='employeeName'
                    optionFields={['employeeName']}
                    valueKey='loginUserId'
                  />
                </Grid>
                <Grid item md={6}>
                  <FmAutoComplete
                    name='status'
                    control={control}
                    options={statusOptions}
                    label='Status'
                    displayField='statusName'
                    optionFields={['statusName']}
                    valueKey='statusId'
                  />
                </Grid>
                <Grid item md={12}>
                  <Box className='text-end mt-3'>
                    <Button
                      variant='contained'
                      sx={{ minWidth: '180px' }}
                      type='submit'
                    >
                      Submit
                    </Button>
                  </Box>
                </Grid>
              </Grid>
            </LocalizationProvider>
          </form>
        )}
      </MuiDialogOne>
      <MuiDialogOne
        title='View Details'
        open={followView}
        onClose={() => setFollowView(!followView)}
      >
        <Grid container spacing={2}>
          <Grid item md={6}>
            <ViewField
              label='Enquiry Followup Type'
              title={followupsViewdata?.tenderFollowupTypeName}
            />
          </Grid>
          <Grid item md={6}>
            <ViewField
              label='Enquiry Name'
              title={followupsViewdata?.tenderName}
            />
          </Grid>
          <Grid item md={6}>
            <ViewField
              label='Contact Person'
              title={followupsViewdata?.contactPerson}
            />
          </Grid>
          <Grid item md={6}>
            <ViewField
              label='Contact Mode Name'
              title={followupsViewdata?.contactModeName}
            />
          </Grid>
          <Grid item md={6}>
            <ViewField
              label='Contact Mail'
              title={followupsViewdata?.contactMail}
            />
          </Grid>
          <Grid item md={6}>
            <ViewField
              label='Contact Number'
              title={followupsViewdata?.contactNumber}
            />
          </Grid>
          <Grid item md={6}>
            <ViewField
              label='Assigned To'
              title={followupsViewdata?.assignToName}
            />
          </Grid>
          <Grid item md={6}>
            <FmViewDateField
              title='Remainder date'
              value={followupsViewdata?.remainderDate}
            />
          </Grid>
          <Grid item md={6}>
            <FmViewDateField
              title='Reschedule Date'
              value={followupsViewdata?.rescheduleDate}
            />
          </Grid>
          <Grid item md={6}>
            <ViewField label='Status' title={followupsViewdata?.statusName} />
          </Grid>
          <Grid item md={12}>
            <ViewField label='Comments' title={followupsViewdata?.comments} />
          </Grid>
        </Grid>
      </MuiDialogOne>
    </DashboardLayout>
  );
};

export default ViewEnquiry;
