import React, { useState } from 'react';
import Box from '@mui/material/Box';
import Button from '@mui/material/Button';
import { Icon } from '@iconify/react/dist/iconify.js';
import MenuItem from '@mui/material/MenuItem';
import Menu from '@mui/material/Menu';
import { setActiveTab } from '../../store/tabsSlice.ts';
import { useNavigate } from 'react-router-dom';
import { useSelector, useDispatch } from 'react-redux';

const colors = [
  '#ff6f61', // Coral (Red + Orange)
  '#4caf50', // Leaf Green
  '#42a5f5', // Sky Blue
  '#ff8f00', // Amber Orange
  '#8e24aa', // Vibrant Purple
  '#0acc9b', // Aqua Green
  '#d81b60', // Cerise Pink
  '#7986cb', // Indigo Blue
  '#00acc1', // Cyan Blue
  '#f4511e', // Red-Orange
  '#7cb342', // Chartreuse Green
  '#c2185b', // Magenta Red
  '#ff6f61', // Coral (Red + Orange)
  '#4caf50', // Leaf Green
  '#42a5f5', // Sky Blue
  '#ff8f00', // Amber Orange
  '#8e24aa', // Vibrant Purple
  '#0acc9b', // Aqua Green
  '#d81b60', // Cerise Pink
  '#7986cb', // Indigo Blue
  '#00acc1', // Cyan Blue
  '#f4511e', // Red-Orange
  '#7cb342', // Chartreuse Green
  '#c2185b', // Magenta Red
];

const HeaderAppDropdown = () => {
  const [anchorEl, setAnchorEl] = React.useState(null);
  const open = Boolean(anchorEl);

  const [activeIndex, setActiveIndex] = useState(
    Number(sessionStorage.getItem('tab')) ||
      Number(localStorage.getItem('menu'))
  );

  const dispatch = useDispatch();
  const navigate = useNavigate();
  const menu = useSelector((state) => state.menu.items);

  const BySetActiveMenu = (tab) => {
    setActiveIndex(tab);
    localStorage.setItem('menu', tab);
    sessionStorage.setItem('tab', tab);
    dispatch(setActiveTab(tab));
  };

  const handleClick = (event) => {
    setAnchorEl(event.currentTarget);
  };

  const renderIcon = (iconStr) => {
    try {
      // Check if icon is in the expected format
      const iconName = iconStr.match(/'(.+)'/)[1]; // Extract icon name from the string

      // Dynamically render the Icon component from Iconify using the icon name
      return <Icon icon={iconName} />;
    } catch (error) {
      console.error('Invalid icon string format:', error);
      return null;
    }
  };

  const handleClose = () => {
    setAnchorEl(null);
  };

  const handleMenuClick = (index, path) => {
    BySetActiveMenu(index);
    navigate(`${path}`);
  };

  const MuiMenuItem = ({ name, icon, index, onClick, ...props }) => {
    return (
      <MenuItem
        onClick={onClick}
        sx={{
          color: index === activeIndex ? 'primary.main' : 'inherit',
          fontSize: 'inherit',
          py: 0,
          fontWeight: index === activeIndex ? 500 : 500,
          borderRadius: (theme) => theme.shape.main,
          opacity: index === activeIndex ? 1 : 0.9,
          gap: '5px',
          margin: '4px 0',
          px: 1,
          position: 'relative',

          '&:before': {
            content: '""',
            width: '3px',
            height: '0px',
            position: 'absolute',
            top: '0px',
            bgcolor: 'text.main',
            left: '-10px',
            transition: '0.2s all',
            height: index === activeIndex ? '100%' : '0%',
            bgcolor: 'primary.main',
          },

          '&:hover': {
            bgcolor: index === activeIndex ? 'primary.light' : 'primary.light',
            color: index === activeIndex ? 'primary.main' : 'primary.main',
            opacity: 1,
            '&:before': {
              height: '100%',
            },
          },
          bgcolor: index === activeIndex ? 'primary.light' : 'inherit',
        }}
        {...props}
      >
        <Box
          sx={{
            fontSize: '20px',
            width: '22px',
            height: '22px',
            borderRadius: (theme) => theme.shape.main,
            opacity: index === activeIndex ? 1 : 0.75,
          }}
          className='flex justify-center items-center'
        >
          {renderIcon(icon)}
        </Box>

        <Box>
          <Box className='uppercase'>{name}</Box>
        </Box>
      </MenuItem>
    );
  };

  const activeItem = menu?.find((_, index) => index === activeIndex);

  return (
    <Box className='sm:hidden'>
      <Button
        variant='text'
        sx={{
          textAlign: 'left',
          p: '0px 10px',
          lineHeight: 1.2,
          color: 'inherit',
          whiteSpace: 'nowrap',
          borderRadius: '5px',
          fontWeight: 500,
          height: '35px',
          gap: '10px',
          minWidth: '10px',
          justifyContent: 'space-between',
          transition: '0.5s all',
          width: '100%',

          '&:hover': {},
        }}
        onClick={handleClick}
      >
        <Box
          sx={{
            width: 28,
            height: 28,
            fontSize: '20px',
            color: 'inherit',
            borderRadius: '5px',
          }}
          className='flex flex-row items-center justify-center me-1'
        >
          {/* {renderIcon(activeItem?.icon)} */}
          <Icon icon='fluent:grid-dots-28-regular' width='22' height='22' />
        </Box>
        <Box className='flex-1'>
          <Box className='uppercase'>{activeItem?.moduleName}</Box>
        </Box>
        <Box>
          {!open ? (
            <Icon icon='fluent:chevron-up-down-24-regular' />
          ) : (
            <Icon icon='fluent:chevron-up-down-24-regular' />
          )}
        </Box>
      </Button>

      <Menu
        id={'app-dropdown'}
        anchorEl={anchorEl}
        open={open}
        onClose={handleClose}
        disableScrollLock={true}
        anchorOrigin={{
          vertical: 'bottom',
          horizontal: 'center',
        }}
        transformOrigin={{
          vertical: 'top',
          horizontal: 'center',
        }}
        PaperProps={{
          sx: {
            width: '200px',
            color: 'inherit',
          },
        }}
        MenuListProps={{
          sx: {
            padding: '10px',
            '& li': {
              borderRadius: '4px',
              py: 0.4,
              gap: '13px',
            },
          },
        }}
      >
        {menu?.map(
          (menu, i) =>
            menu.moduleName && (
              <MuiMenuItem
                index={i}
                // setActiveMenu={activeIndex}
                key={i}
                icon={menu.icon}
                name={`${menu.moduleName}`}
                onClick={() => handleMenuClick(i, menu?.url)}
              />
            )
        )}
      </Menu>
    </Box>
  );
};

export default HeaderAppDropdown;
