import { yupResolver } from '@hookform/resolvers/yup';
import { Grid, Box } from '@mui/material';
import dayjs from 'dayjs';
import React, { useEffect, useState } from 'react';
import { useForm } from 'react-hook-form';
import { useNavigate } from 'react-router-dom';
import { toast } from 'react-toastify';
import Crud_Service from '../../../../apis/CrudService.jsx';
import ActionButtons from '../../../../components/_form/ActionButtons.tsx';
import FmDatePicker from '../../../../components/_mui/FmDatePicker.tsx';
import FmFileInput from '../../../../components/_mui/FmFileInput.tsx';
import FmSearchableSelect from '../../../../components/_mui/FmSearchableSelect.tsx';
import FmTextField from '../../../../components/_mui/FmTextField.tsx';
import DashboardLayout from '../../../../components/DashboardLayout.tsx';
import BackToButton from '../../../../components/small/BackToButton.jsx';
import GlassCard from '../../../../components/small/GlassCard.tsx';
import { PurchaseVoucherSchema } from '../../../../utils/CommonvalidationSchemas.tsx';
import useLoader from '../../../../components/helpers/UseLoader.tsx';
import {
  ViewField,
  StatusBadge,
} from '../../../../components/_form/FormElements.jsx';
import FmCurrencyTextField from '../../../../components/_mui/FmCurrencyTextField.tsx';

const GridSize = 3;

const CreatePurchaseVoucher = (props) => {
  const { type } = props;
  const navigate = useNavigate();
  const crud = new Crud_Service();
  const [salesOrder, setSalesOrder] = React.useState();
  const { startCreateLoading, createLoading, stopCreateLoading } = useLoader();
  const { control, handleSubmit, reset, setValue, getValues } = useForm({
    resolver: yupResolver(PurchaseVoucherSchema),
    mode: 'onChange',
  });
  const [loading, setLoading] = useState(false);

  useEffect(() => {
    reset();
  }, [type]);
  const [fileId, setFileId] = useState(null);

  const submitPurchaseVoucher = async (values) => {
    startCreateLoading();
    const data = {
      ...values,
      purchaseVoucherDate: dayjs(values?.purchaseVoucherDate).format(
        'YYYY-MM-DD'
      ),
      paymentDueDate: dayjs(values?.paymentDueDate).format('YYYY-MM-DD'),
      invoiceDate: dayjs(values?.invoiceDate).format('YYYY-MM-DD'),
      purchaseVoucherTypeId: type,
      purchaseVoucherStatusId: 1,
      vendorId: values.supplierId,
      divisionId: values.divisionId,
      purchaseVoucherFileId: fileId,
    };
    await crud.create('purchasevouchers', data, (_err, res) => {
      if (res?.status === 201) {
        toast.success('Purchase Voucher Created Successfully');
        stopCreateLoading();
        reset();
        navigate('../edit/' + res?.data?.purchaseVoucherId);
      } else {
        stopCreateLoading();
      }
    });
  };

  const handleSalesOrderChange = (ev) => {
    setSalesOrder(ev);
    setValue('locationId', ev?.locationId ?? null);
    setValue('currencyId', ev?.currencyId ?? '');
    setValue('companyId', ev?.companyId ?? null);
    setValue('divisionId', ev?.divisionId ?? null);
    setValue('requestorId', ev?.requestorId ?? null);
    setValue('paymentTerms', ev?.paymentTerms ?? null);
    setValue('paymentTypeId', ev?.paymentTypeId ?? null);
    setValue('exchangeRate', ev?.exchangeRate ?? null);
    setValue('approvedBy', ev?.approvedBy ?? null);
  };

  const handleFileUpload = async (event) => {
    setLoading(true);
    const file = event.target.files[0];
    if (file) {
      const fileFormData = new FormData();
      fileFormData.append('file', file);
      fileFormData.append('fileTypeId', 33);
      try {
        const response = await new Promise((resolve, reject) => {
          crud.create('files/upload', fileFormData, (_err, res) => {
            if (res?.status === 200) {
              resolve(res);
              setLoading(false);
            } else {
              reject(new Error('File upload failed'));
              setLoading(false);
            }
          });
        });
        setFileId(response?.data?.fileId);
        setValue(`documentName`, response?.data?.originalFileName);
      } catch (error) {
        setLoading(false);
      } finally {
        setLoading(false);
      }
    }
  };
  const [, setSelectedVendorInvoice] = useState(null);
  const handleVendorInvoiceChange = (ev) => {
    setSelectedVendorInvoice(ev);
  };
  return (
    <DashboardLayout
      title={`Create ${
        type === 1
          ? 'Purchase Voucher'
          : type === 3
          ? 'Purchase Return'
          : 'Direct Purchase Order'
      }`}
      actionButtons={
        <BackToButton
          title='Back to List'
          onClick={() => navigate(-1)}
          className='px-0 py-0'
        />
      }
      titleReverse
    >
      <Grid container spacing={1}>
        <Grid item md={10}>
          <GlassCard className='p-3'>
            <form onSubmit={handleSubmit(submitPurchaseVoucher)}>
              <Grid container spacing={2}>
                <Grid item md={GridSize}>
                  <FmTextField
                    name='purchaseVoucherCode'
                    label='Code'
                    control={control}
                    required
                  />
                </Grid>
                <Grid item md={GridSize}>
                  <FmTextField
                    name='purchaseVoucherName'
                    label='Name'
                    control={control}
                    type='string'
                  />
                </Grid>
                <Grid item md={GridSize * 2}>
                  <FmSearchableSelect
                    name='salesOrderId'
                    control={control}
                    apiUrl='salesorders/order-data'
                    valueField='salesOrderId'
                    headerField={['Sales Order', 'Project', 'Contact']}
                    labelField={[
                      'salesOrderCode',
                      'projectName',
                      'contractName',
                    ]}
                    stackedFields={{
                      salesOrderCode: [
                        { Code: 'salesOrderCode' },
                        { Name: 'salesOrderName' },
                      ],
                      projectName: [
                        { Name: 'projectName' },
                        { Code: 'projectCode' },
                      ],
                      contractName: [
                        { Name: 'contractName' },
                        { Code: 'contractCode' },
                      ],
                    }}
                    showField={['salesOrderCode', 'salesOrderName']}
                    onChangeProp={(ev) => handleSalesOrderChange(ev)}
                    label='Sales Order'
                  />
                </Grid>
                <Grid item md={GridSize}>
                  <FmSearchableSelect
                    name='companyId'
                    label='Company'
                    apiUrl={'companies'}
                    valueField='companyId'
                    labelField={['companyCode', 'companyName']}
                    showField={['companyCode', 'companyName']}
                    control={control}
                    defaultValue={{
                      companyId: salesOrder?.companyId,
                      companyName: salesOrder?.companyName,
                    }}
                  />
                </Grid>
                <Grid item md={GridSize}>
                  <FmSearchableSelect
                    name='divisionId'
                    control={control}
                    apiUrl='divisions'
                    valueField='divisionId'
                    labelField={['divisionCode', 'divisionName']}
                    showField={['divisionCode', 'divisionName']}
                    label='Division'
                    defaultValue={{
                      divisionId: salesOrder?.divisionId,
                      divisionName: salesOrder?.divisionName,
                    }}
                  />
                </Grid>

                <Grid item md={GridSize}>
                  <FmSearchableSelect
                    name='supplierId'
                    control={control}
                    apiUrl='suppliers'
                    valueField='supplierId'
                    labelField={['supplierCode', 'officialSupplierName']}
                    showField={['supplierCode', 'officialSupplierName']}
                    required
                    label={'Vendor'}
                  />
                </Grid>
                <Grid item md={GridSize}>
                  <FmSearchableSelect
                    name='vendorDocumentId'
                    control={control}
                    apiUrl='vendordocuments'
                    valueField='vendorDocumentId'
                    headerField={['Invoice No', 'LPO No']}
                    labelField={['invoiceNumber', 'localPurchaseOrderNo']}
                    showField={['invoiceNumber', 'localPurchaseOrderNo']}
                    onChangeProp={(ev) => handleVendorInvoiceChange(ev)}
                    label={'Vendor Document'}
                  />
                </Grid>

                <Grid item md={GridSize}>
                  <FmSearchableSelect
                    name='locationId'
                    label='Location'
                    required
                    apiUrl={'locations'}
                    valueField='locationId'
                    labelField={['locationCode', 'locationName']}
                    showField={['locationCode', 'locationName']}
                    control={control}
                    defaultValue={{
                      locationId: salesOrder?.locationId,
                      locationName: salesOrder?.locationName,
                    }}
                  />
                </Grid>

                <Grid item md={GridSize}>
                  <FmSearchableSelect
                    name='supplyLocationId'
                    label='Supply Location'
                    apiUrl='locations'
                    valueField='locationId'
                    required
                    labelField={['locationCode', 'locationName']}
                    showField={['locationCode', 'locationName']}
                    control={control}
                  />
                </Grid>

                <Grid item md={GridSize}>
                  <FmDatePicker
                    name='invoiceDate'
                    label='Supplier Invoice Date'
                    control={control}
                  />
                </Grid>

                <Grid item md={GridSize}>
                  <FmTextField
                    name='invoiceNo'
                    label='Invoice No'
                    control={control}
                  />
                </Grid>

                <Grid item md={GridSize}>
                  <FmSearchableSelect
                    name='currencyId'
                    label='Currency'
                    apiUrl={'currencies'}
                    valueField='currencyId'
                    required
                    labelField={['currencyName', 'currencyCode']}
                    showField={['currencyName', 'currencyCode']}
                    control={control}
                    defaultValue={{
                      currencyId: salesOrder?.currencyId,
                      currencyName: salesOrder?.currencyName,
                    }}
                  />
                </Grid>

                <Grid item md={GridSize}>
                  <FmSearchableSelect
                    name='paymentTermId'
                    control={control}
                    apiUrl='paymentterms'
                    valueField='paymentTermId'
                    headerField={['Payment Term']}
                    defaultValue={salesOrder?.paymentTerms}
                    labelField={['paymentTermName']}
                    showField={['paymentTermName']}
                    label='Payment Term'
                  />
                </Grid>

                <Grid item md={GridSize}>
                  <FmSearchableSelect
                    name='paymentTypeId'
                    control={control}
                    apiUrl='paymenttypes'
                    valueField='paymentTypeId'
                    headerField={['Payment Type']}
                    labelField={['paymentTypeName']}
                    showField={['paymentTypeName']}
                    label='Payment Type'
                    defaultValue={{
                      paymentTypeId: salesOrder?.paymentTypeId,
                      paymentTypeName: salesOrder?.paymentTypeName,
                    }}
                  />
                </Grid>

                <Grid item md={GridSize}>
                  <FmDatePicker
                    name='paymentDueDate'
                    label='Payment Due Date'
                    control={control}
                  />
                </Grid>

                <Grid item md={GridSize}>
                  <FmCurrencyTextField
                    name='exchangeRate'
                    label='Exchange Rate'
                    control={control}
                  />
                </Grid>
                <Grid item md={GridSize}>
                  <FmSearchableSelect
                    name='inCharge'
                    control={control}
                    apiUrl='loginusers'
                    valueField='loginUserId'
                    headerField={['Code', 'Name', 'Email']}
                    labelField={[
                      'employeeCode',
                      'employeeName',
                      'employeeMail',
                    ]}
                    showField={['employeeCode', 'employeeName', 'employeeMail']}
                    label='In Charge'
                  />
                </Grid>

                <Grid item md={GridSize}>
                  <FmTextField
                    name='billStatus'
                    label='Bill Status'
                    control={control}
                  />
                </Grid>

                <Grid item md={GridSize}>
                  <FmSearchableSelect
                    name='requestorId'
                    control={control}
                    apiUrl='loginusers'
                    valueField='loginUserId'
                    headerField={['Code', 'Name', 'Email']}
                    labelField={[
                      'employeeCode',
                      'employeeName',
                      'employeeMail',
                    ]}
                    showField={['employeeCode', 'employeeName', 'employeeMail']}
                    defaultValue={{
                      loginUserId: salesOrder?.requestorId,
                      employeeName: salesOrder?.requestorName,
                      employeeMail: salesOrder?.requestorMail,
                    }}
                    label='Requestor'
                  />
                </Grid>

                <Grid item md={GridSize}>
                  <FmSearchableSelect
                    name='approvedBy'
                    control={control}
                    apiUrl='loginusers'
                    valueField='loginUserId'
                    labelField={[
                      'employeeCode',
                      'employeeName',
                      'employeeMail',
                    ]}
                    showField={['employeeCode', 'employeeName', 'employeeMail']}
                    label='Approved By'
                    defaultValue={{
                      loginUserId: salesOrder?.approvedBy,
                      employeeName: salesOrder?.approvedByName,
                      employeeMail: salesOrder?.approvedByMail,
                    }}
                  />
                </Grid>

                {/* <Grid item md={GridSize}>
              <FmDatePicker
                name='purchaseVoucherDate'
                label='Voucher Date'
                control={control}
              />
            </Grid> */}

                <Grid item md={GridSize}>
                  <FmFileInput
                    name={`fileId`}
                    documentName={getValues(`documentName`)}
                    onChange={(e) => handleFileUpload(e)}
                    loading={loading}
                  />
                </Grid>

                <Grid item md={GridSize * 2}>
                  <FmTextField
                    name='remarks'
                    label='Remarks'
                    control={control}
                    multiline={true}
                    rows={4}
                  />
                </Grid>

                <Grid item md={12}>
                  <ActionButtons
                    onSubmit={handleSubmit(submitPurchaseVoucher)}
                    onReset={reset}
                    submitLoading={createLoading}
                    onCancel={() => navigate('../')}
                    cancelText='Go Back'
                    submitText='Create'
                    removeSpacing
                  />
                </Grid>
              </Grid>
            </form>
          </GlassCard>
        </Grid>
        <Grid item md={2}>
          <GlassCard className='p-2' bgLight={true}>
            <Box className='mb-2'>
              <StatusBadge title={'View Details'} type={'teal'} />
            </Box>
            <Box className='flex flex-col gap-2'>
              <ViewField
                label={'Sales Order'}
                title={'SO-2025-001 - Annual Maintenance Contract'}
              />
              <ViewField label={'Division'} title={'COMMON-000066 - MEP'} />
              <ViewField label={'Payment Type'} title={'Cash'} />
              <ViewField label={'Bill Status'} title={'Pending'} />
            </Box>
          </GlassCard>
        </Grid>
      </Grid>
    </DashboardLayout>
  );
};

export default CreatePurchaseVoucher;
