import React, { useState, useEffect } from 'react';
import ReactDOM from 'react-dom';
import MuiDialogOne from '../_mui/MuiDialogOne.jsx';
import API from '../../apis/ApiService';
import apiInstance from '../../apis/ApiService';
import ServerDownUI from './ServerDownUI.tsx';
import ServerReadyUi from './ServerReadyUi.tsx';
import { toast } from 'react-toastify';

let showToast;

const ServerToastComponent = () => {
  const [open, setOpen] = useState(false);
  const [isServerLive, setIsServerLive] = useState(false);

  showToast = () => setOpen(true);

  useEffect(() => {
    const checkServerStatus = async () => {
      const interval = setInterval(async () => {
        const response = await apiInstance.pingServer();
        if (response?.status === 200) {
          setIsServerLive(true);
          // toast.success('Server is live! Kindly refresh the page.');
          setOpen(true);
          clearInterval(interval);
        }
      }, 10000);

      return () => clearInterval(interval);
    };

    open && checkServerStatus();
  }, [open]);

  return ReactDOM.createPortal(
    <MuiDialogOne
      title={isServerLive ? 'Server is live!' : 'Server Under Maintenance'}
      open={open}
      onClose={() => setOpen(false)}
      width='500px'
    >
      {isServerLive ? <ServerReadyUi /> : <ServerDownUI />}
    </MuiDialogOne>,
    document.body
  );
};

// Expose the function to show the toast
export const serverToast = () => {
  if (showToast) {
    showToast();
  }
};

export default ServerToastComponent;
