import React from 'react';
import GlassCard from '../small/GlassCard.tsx';
import { Box } from '@mui/material';

const WidgetOne = ({ icon, title, count, record, path }) => {
  return (
    <GlassCard className='px-2 py-4  w-full relative min-h-full  text-center flex items-center flex-col justify-center hover:bg-zinc-200'>
      {/* <Box
        className='p-2 text-4xl rounded-full  inline-block'
        sx={{
          bgcolor: (theme) => alpha(theme.palette.primary.main, 0.15),
          color: "primary.main",
        }}
      >
        {icon}  
      </Box> */}

      <Box
        sx={{ color: 'primary.main' }}
        className='text-4xl  font-semibold mb-2 '
      >
        {count}
      </Box>

      {/* <Box className="flex gap-3 items-center rounded-md justify-center my-5 text-base font-bold text-green-600">
        {record} <Icon icon="teenyicons:up-solid" />
      </Box> */}

      <Box>
        <Box color={'text.main'}>
          {title}
        </Box>
      </Box>
    </GlassCard>
  );
};

export default WidgetOne;
