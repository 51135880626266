import {
  Autocomplete,
  Box,
  CircularProgress,
  Paper,
  Table,
  TableCell,
  TableContainer,
  TableFooter,
  TableHead,
  TableRow,
  TextField,
  Typography,
  TableBody,
  Tooltip,
} from '@mui/material';
import axios from 'axios';
import debounce from 'lodash/debounce';
import React, {
  useCallback,
  useEffect,
  useState,
  useRef,
  forwardRef,
} from 'react';
import { Controller } from 'react-hook-form';
import { toast } from 'react-toastify';

interface FmSearchableSelectProps {
  name: string;
  control: any;
  apiUrl: string;
  valueField: string;
  labelField: string[];
  pageSize?: number;
  required?: boolean;
  label: string;
  defaultValue?: any[];
  showField?: string[];
  onChangeProp?: (value: any) => void;
  resultChange?: string;
  disabled?: boolean;
  callApi?: string | null;
  queryparam?: string;
  queryparamValue?: any;
  queryparam2?: string;
  queryparamValue2?: any;
  onBlur?: () => void;
  inputRef?: any;
  sortBy?: string[];
  sortDirection?: 'asc' | 'desc';
  inputRef?: any;
  disabledOptions?: any;
  clearSelection?:() => void
}

const FmSearchableSelect = (props: FmSearchableSelectProps) => {
  const {
    name,
    control,
    apiUrl,
    valueField,
    labelField,
    pageSize = 25,
    required,
    label,
    defaultValue,
    showField,
    onChangeProp,
    resultChange,
    disabled,
    callApi,
    queryparam,
    queryparamValue,
    queryparam2,
    queryparamValue2,
    onBlur,
    sortBy,
    sortDirection = 'asc',
    inputRef,
    disabledOptions,
    clearSelection
  } = props;

  const [pageCount, setPageCount] = useState(1);
  const [searchQuery, setSearchQuery] = useState('');
  const [options, setOptions] = useState([]);
  const [loading, setLoading] = useState(false);
  const [initialLoad, setInnitialLoad] = useState(false);
  const [opttionsEmpty, setOpttionsEmpty] = useState(false);
  const rooturl = process.env.REACT_APP_BASE;
  const [hasMore, setHasMore] = useState(true);
  const [totalLength, setTotalLength] = useState(0);
  const listboxRef = useRef(null);
  const [defaultValueState, setDefaultValueState] = useState(null);



  useEffect(() => {
    if(defaultValue){
      setDefaultValueState(defaultValue)
    }
  },[defaultValue])

  const fetchData = useCallback(
    debounce(async (page, search = '') => {
      setLoading(true);
      try {
        const config = getAxiosConfig();
        const regionQuery =
          (queryparamValue ? `${queryparam}=${queryparamValue}&` : '') +
          (queryparam2 && queryparamValue2
            ? `${queryparam2}=${queryparamValue2}&`
            : '');

        const sortQuery = sortBy
          ? `&sortBy=${sortBy}&sortDirection=${sortDirection}`
          : '';

        const url = `${rooturl}/${apiUrl}?${regionQuery}pageSize=${pageSize}&pageNumber=${page}&searchKeyword=${search}${sortQuery}`;
        //TODO
        // const url = `${rooturl}/${apiUrl}?${
        //   query ? query : ''
        // }pageSize=${pageSize}&pageNumber=${page}&searchKeyword=${search}`;
        const response = await axios.get(url, config);
        const result =
          resultChange === 'candidates'
            ? response?.data?.candidates?.data
            : response?.data?.data || [];
        setTotalLength(response?.data?.total);

        setHasMore(response?.data?.data?.length > 0);

        if (result.length === 0) {
          setOpttionsEmpty(true);
        } else {
          setOpttionsEmpty(false);
        }
        if (disabledOptions) {
          const filteredResult = result.filter(
            (item) => item.employeeId !== disabledOptions
          );
          setOptions((prev) =>
            page === 1 ? filteredResult : [...prev, ...filteredResult]
          );
        } else {
          setOptions((prev) => (page === 1 ? result : [...prev, ...result]));
        }
      } catch (error) {
        toast.error('Error fetching data:', error);
      } finally {
        setLoading(false);
      }
    }, 500),
    [apiUrl, pageSize, queryparamValue, sortBy, sortDirection]
  );

  useEffect(() => {
    if (initialLoad) {
      fetchData(pageCount, searchQuery);
    }
  }, [initialLoad, fetchData, pageCount, searchQuery, callApi]);

  const getAxiosConfig = () => {
    const token = localStorage.getItem('token');
    return {
      headers: {
        Authorization: `Bearer ${token}`,
      },
    };
  };

  // Label text Overwrite
  function processText(text, maxLength) {
    const isTruncated = text.length > maxLength;
    return isTruncated ? `${text.slice(0, maxLength)}...` : text;
  }

  const getOptionLabel = (option) => {
    if (option.isLoading) return 'Loading more...';
    return (
      showField
        ?.map((field) => option[field])
        .filter(Boolean)
        .join(' - ') || ''
    );
  };

  const handleScroll = (event) => {
    const scrollContainer = event.target;
    const bottom =
      scrollContainer.scrollHeight - 5 <
      scrollContainer.scrollTop + scrollContainer.clientHeight;

    if (bottom && hasMore && !loading) {
      listboxRef.current = scrollContainer.scrollTop;
      setPageCount((prev) => prev + 1);
    }
  };

  useEffect(() => {
    if (listboxRef.current && !loading) {
      const lazyContainer = document.querySelector('#lazyLoadDropdown-listbox');
      if (lazyContainer && listboxRef.current !== null) {
        lazyContainer.scrollTop = listboxRef.current;
      }
    }
  }, [loading]);

  const CustomListbox = forwardRef((props, ref) => (
    <TableBody
      ref={ref}
      {...props}
      onScroll={handleScroll}
      sx={{
        display: 'block',
        padding: '0 !important',
        transition: '0.5s all',
        '& .MuiTableRow-root': {
          display: 'table-row !important',
        },
      }}
    />
  ));

  return (
    <Controller
      name={name}
      control={control}
      render={({ field, fieldState }) => (
        <Autocomplete
          id='lazyLoadDropdown'
          disabled={disabled}
          options={loading ? [...options, { isLoading: true }] : options}
          value={
            options.find((option) => option[valueField] === field.value) ||
            defaultValueState ||
            null
          }
          getOptionLabel={getOptionLabel}
          ListboxComponent={CustomListbox}
          renderOption={(props, option) => (
            <TableRow
              {...props}
              sx={{
                cursor: 'pointer',
                '&:hover, &.Mui-focused': {
                  backgroundColor: 'background.light',
                },
              }}
              key={option.id}
              className={option.statusName === 'INACTIVE' && 'bg-red-100'}
            >
              {labelField?.map((field, index) => (
                <TableCell key={index} colSpan={labelField?.length === 1 && 2}>
                  <Box className='title'>{option[field]}</Box>
                </TableCell>
              ))}
            </TableRow>
          )}
          PaperComponent={(props) => (
            <Paper
              {...props}
              sx={{
                my: 0.5,
                minWidth: '400px',
                boxShadow: 4,
              }}
            >
              <TableContainer
                sx={{
                  position: 'relative',
                  '& .MuiTableCell-root': {
                    fontSize: 'inherit',
                    color: 'inherit',
                    padding: '5px 10px',
                    wordBreak: 'break-word',
                    border: '1px solid',
                    borderColor: 'border.main',
                    width: labelField?.length === 1 ? '400px' : '250px',
                    '&.full-width': {
                      width: '400px',
                    },
                  },
                  '& .MuiTableCell-head, & .MuiTableCell-footer': {
                    border: 'none',
                  },
                  '& .MuiTableFooter-root': {
                    borderTop: '1px solid',
                    borderColor: 'border.main',
                  },
                }}
              >
                {loading && (
                  <Box
                    sx={{
                      position: 'absolute',
                      left: 0,
                      top: 0,
                      bgcolor: 'background.overlay',
                      zIndex: 1,
                    }}
                    className='w-full h-full flex justify-center items-center'
                  >
                    <CircularProgress color='primary' size={40} />
                  </Box>
                )}

                <Table sx={{ tableLayout: 'fixed' }}>
                  <TableHead>
                    <TableRow
                      sx={{ display: 'block', bgcolor: 'background.body' }}
                    >
                      {labelField.length === 1 ? (
                        <TableCell key={field} sx={{ fontWeight: 'bold' }}>
                          {'Name'}
                        </TableCell>
                      ) : (
                        <>
                          <TableCell key={field} sx={{ fontWeight: 'bold' }}>
                            {'Code'}
                          </TableCell>
                          <TableCell key={field} sx={{ fontWeight: 'bold' }}>
                            {'Name'}
                          </TableCell>
                        </>
                      )}
                    </TableRow>
                  </TableHead>
                  {props.children}
                  <TableFooter sx={{ display: 'block' }}>
                    <TableRow>
                      <TableCell
                        colSpan={labelField?.length > 1 ? 2 : 1}
                        className={labelField?.length > 1 ? 'full-width' : ''}
                      >
                        <Box className='flex justify-between items-center w-full'>
                          <Box className='flex-1'>
                            Total Results:{' '}
                            <span className='font-semibold'>
                              {' '}
                              {totalLength}
                            </span>
                          </Box>
                          <Box className='text-end flex-1'>
                            {options.length} / {totalLength}
                          </Box>
                        </Box>
                      </TableCell>
                    </TableRow>
                  </TableFooter>
                </Table>
              </TableContainer>
            </Paper>
          )}
          slotProps={{
            popper: {
              sx: {
                minWidth: '400px !important',
              },
            },
          }}
          renderInput={(params) => (
            <TextField
              {...params}
              label={processText(label, 20)}
              title={label}
              disabled={disabled || loading}
              variant='outlined'
              required={required}
              error={!!fieldState.error}
              inputRef={inputRef}
              onBlur={() => {
                field.onBlur();
                onBlur?.();
              }}
              helperText={fieldState.error ? fieldState.error.message : null}
            />
          )}
          onInputChange={(_event, value, reason) => {
            if (reason === 'input') {
              setPageCount(1);
              setSearchQuery(value);
              field.onChange(value);
            }
          }}
          onChange={(_event, option, reason) => {
            if (reason === 'selectOption') {
              field.onChange(option ? option[valueField] : null);
              onChangeProp
                ? onChangeProp(option)
                : field.onChange(option[valueField]);
            }
            if (reason === 'clear') {
              field.onChange(null);
              //TODO
              if (clearSelection) {
                clearSelection(name);
            } setDefaultValueState(null);
              setPageCount(1);
              setSearchQuery('');
            }
          }}
          onOpen={() => {
            setTimeout(() => {
              setInnitialLoad(true);
              const lazyContainer = document.querySelector(
                '#lazyLoadDropdown-listbox'
              );
              if (lazyContainer) {
                lazyContainer.addEventListener('scroll', handleScroll);
              }
            }, 100);
          }}
          onClose={() => {
            const lazyContainer = document.querySelector(
              '#lazyLoadDropdown-listbox'
            );
            if (lazyContainer) {
              lazyContainer.removeEventListener('scroll', handleScroll);
            }
          }}
        />
      )}
    />
  );
};

export default FmSearchableSelect;
