import React, { useEffect, useMemo } from 'react';
import DashboardLayout from '../../../../../components/DashboardLayout.tsx';
import BackToButton from '../../../../../components/small/BackToButton.tsx';
import { useNavigate } from 'react-router-dom';
import { useSelector } from 'react-redux';
import { findNavByName } from '../../../../../utils/navUtils.ts';
import AddInternalInvoiceAR from './AddInternalInvoicesAR.tsx';

const CreateInternalInvoicesAR = () => {
  const navigate = useNavigate();
  const menu = useSelector((state) => state?.menu?.items);

  useEffect(() => {
    document.title = 'New Internal Invoices  | Accounts Receivable | Financial';
  }, []);

  const SubMenu = useMemo(() => {
    return menu?.map((item) => item?.menus?.flat()).flat() || [];
  }, [menu]);

  const result = useMemo(
    () => findNavByName(SubMenu, 'Financial', 'Accounts Receivable'),
    [SubMenu]
  );

  return (
    <DashboardLayout
      title='New Internal Invoices'
      menu={result}
      actionButtons={
        <BackToButton
          onClick={() => navigate(-1)}
          title='Back to List'
          className='px-0 py-0'
        />
      }
      titleReverse
    >
      <AddInternalInvoiceAR />
    </DashboardLayout>
  );
};

export default CreateInternalInvoicesAR;
