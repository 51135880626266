import React, { useState } from "react";
import { Grid, TextField, MenuItem, Select } from "@mui/material";
import GlassCard from "../../../../components/small/GlassCard.tsx";
import useCommonFetchApi from "../../../../components/helpers/useCommonFetchApi.tsx";
import { DatePicker, LocalizationProvider } from "@mui/x-date-pickers";
import { AdapterDayjs } from "@mui/x-date-pickers/AdapterDayjs";
import { Controller, useForm } from "react-hook-form";
import useLoader from "../../../../components/helpers/UseLoader.tsx";
import { toast } from "react-toastify";
import Crud_Service from "../../../../apis/CrudService.jsx";
import dayjs from "dayjs";
import FmAutoComplete from "../../../../components/_mui/FmAutoComplete.tsx";
import ActionButtons from "../../../../components/_form/ActionButtons.tsx";
import FmTextField from "../../../../components/_mui/FmTextField.tsx";
import DashboardLayout from "../../../../components/DashboardLayout.tsx";
import { useNavigate } from "react-router-dom";
import { yupResolver } from "@hookform/resolvers/yup";
import { companyinsurancepolicycomparisonsSchema } from "../../../../utils/CommonvalidationSchemas.tsx";
import FmDatePicker from "../../../../components/_mui/FmDatePicker.tsx";
import FmSearchableSelect from "../../../../components/_mui/FmSearchableSelect.tsx";
const commonStyle = {
  minWidth: "150px",
  fontSize: "12px",
  height: "40px",
};
const GridValue = 2.4;
const AddComparisonPolicy = (props) => {
  const { fetchData } = props;
  const [loading, setLoading] = useState(false);
  const [insurerSelections, setInsurerSelections] = useState([
    { id: "", amount: "" },
    { id: "", amount: "" },
    { id: "", amount: "" },
  ]);
  const {
    control,
    handleSubmit,
    reset,
    watch,
    setValue,
    formState: { isValid },
    trigger,
  } = useForm({
    mode: "onChange",
    resolver: yupResolver(companyinsurancepolicycomparisonsSchema),
  });
  const navigate = useNavigate();
  const { startCreateLoading, stopCreateLoading, createLoading } = useLoader();
  const crud = new Crud_Service();

  //get All Apis
  const { data: insurancePolicy } = useCommonFetchApi(
    "companyinsurancepolicies"
  ) || {
    data: [],
  };

  const { data: insurer } = useCommonFetchApi(
    "companyinsurancepolicyinsurers"
  ) || {
    data: [],
  };

  const { data: premiumAmount } = useCommonFetchApi(
    "companyinsurancepolicypremiums"
  ) || {
    data: [],
  };

  const { data: typeOfProduct } = useCommonFetchApi(
    "companyinsurancepolicytypeofproducts"
  ) || {
    data: [],
  };


  const handleFirstInsurerChange = (event) => {
    const selectedInsurerId = event?.companyInsurancePolicyInsurerId;
    const selectedPremium = premiumAmount.find(
      (premium) => premium.companyInsurancePolicyInsurerId === selectedInsurerId
    );
    setValue('firstInsurerAmount', selectedPremium?.amount || 0);
  };
  const handleSecondInsurerChange = (event) => {
    const selectedInsurerId = event?.companyInsurancePolicyInsurerId;
    const selectedPremium = premiumAmount.find(
      (premium) => premium.companyInsurancePolicyInsurerId === selectedInsurerId
    );
    setValue('secondInsurerAmount', selectedPremium?.amount || 0);
  };
  const handleThirdInsurerChange = (event) => {
    const selectedInsurerId = event?.companyInsurancePolicyInsurerId;
    const selectedPremium = premiumAmount.find(
      (premium) => premium.companyInsurancePolicyInsurerId === selectedInsurerId
    );
    setValue('thirdInsurerAmount', selectedPremium?.amount || 0);
  };
 

  const handleSubmitComparisonPolicy = async (values) => {
    setLoading(true);
    startCreateLoading();

    const payload = {
      companyInsurancePolicyId: values.companyInsurancePolicyId,
      description: values.description,
      companyInsurancePolicyTypeOfProductId:
        values.companyInsurancePolicyTypeOfProductId,
      policyDetail: values.policyDetail,
      startDate: values.startDate
      ? dayjs(values.startDate).format('YYYY-MM-DD')
      : null,
      endDate:values.endDate
      ? dayjs(values.endDate).format('YYYY-MM-DD')
      : null,
      firstInsurer:values?.firstInsurer || null,
      firstInsurerAmount: values?.firstInsurerAmount || 0,
      secondInsurer: values?.secondInsurer || null,
      secondInsurerAmount: values?.secondInsurerAmount || 0,
      thirdInsurer: values?.thirdInsurer || null,
      thirdInsurerAmount: values?.thirdInsurerAmount || 0,
    };
    await crud.create(
      "companyinsurancepolicycomparisons",
      payload,
      (err, res) => {
        if (res?.status === 201) {
          stopCreateLoading();
          reset({
            companyInsurancePolicyId: "",
            description: "",
            companyInsurancePolicyTypeOfProductId: "",
            policyDetail: "",
            startDate: null,
            endDate: null,
          });
          setInsurerSelections([
            { id: "", amount: "" },
            { id: "", amount: "" },
            { id: "", amount: "" },
          ]);
          fetchData();
          toast.success("Added Insurance Policy Comparison Successfully");
        } else {
          stopCreateLoading();
        }
      }
    );
  };

  const startDate = watch("startDate");
  return (
    <DashboardLayout title="Add Comparison Policy">
      <GlassCard className="p-4 mb-4">
        <form onSubmit={handleSubmit(handleSubmitComparisonPolicy)}>
          <LocalizationProvider dateAdapter={AdapterDayjs}>
            <Grid container spacing={3}>
              <Grid item md={GridValue}>
                <FmAutoComplete
                  name="companyInsurancePolicyId"
                  control={control}
                  options={insurancePolicy}
                  label="Insurance Policy"
                  displayField="companyInsurancePolicyName"
                  optionFields={["companyInsurancePolicyName"]}
                  valueKey="companyInsurancePolicyId"
                />
              </Grid>
              <Grid item md={GridValue}>
                <FmTextField
                  name="description"
                  control={control}
                  label="Description"
                />
              </Grid>
              <Grid item md={GridValue}>
                <FmAutoComplete
                  name="companyInsurancePolicyTypeOfProductId"
                  control={control}
                  options={typeOfProduct}
                  label="Type of Product"
                  displayField="companyInsurancePolicyTypeOfProductName"
                  optionFields={["companyInsurancePolicyTypeOfProductName"]}
                  valueKey="companyInsurancePolicyTypeOfProductId"
                />
              </Grid>
              <Grid item md={GridValue}>
                <FmTextField
                  name="policyDetail"
                  control={control}
                  label="Policy Details"
                />
              </Grid>
              <Grid item md={GridValue}>
                <FmDatePicker
                  name="startDate"
                  control={control}
                  label="Start Date"
                  onChangeProp={() => trigger("endDate")}
                />
              </Grid>
              <Grid item md={GridValue}>
                <FmDatePicker
                  name="endDate"
                  control={control}
                  label="End Date"
                  onChangeProp={() => trigger("startDate")}
                  defaultValue={dayjs().add(1, "day")}
                  minDate={startDate}
                />
              </Grid>

              <Grid item md={GridValue}>
                <FmSearchableSelect
                  name="firstInsurer"
                  control={control}
                  apiUrl="companyinsurancepolicyinsurers"
                  valueField="companyInsurancePolicyInsurerId"
                  labelField={[
                    "companyInsurancePolicyInsurerCode",
                    "companyInsurancePolicyInsurerName",
                  ]}
                  showField={[
                    "companyInsurancePolicyInsurerCode",
                    "companyInsurancePolicyInsurerName",
                  ]}
                  label="First Insurer"
                  onChangeProp={handleFirstInsurerChange}
                />
              </Grid>
              <Grid item md={GridValue}>
                <FmTextField
                  name="firstInsurerAmount"
                  control={control}
                  label="Accommodation Amount"
                />
              </Grid>
              <Grid item md={GridValue}>
                <FmSearchableSelect
                  name="secondInsurer"
                  control={control}
                  apiUrl="companyinsurancepolicyinsurers"
                  valueField="companyInsurancePolicyInsurerId"
                  labelField={[
                    "companyInsurancePolicyInsurerCode",
                    "companyInsurancePolicyInsurerName",
                  ]}
                  showField={[
                    "companyInsurancePolicyInsurerCode",
                    "companyInsurancePolicyInsurerName",
                  ]}
                  label="Second Insurer"
                  onChangeProp={handleSecondInsurerChange}
                />
              </Grid>
              <Grid item md={GridValue}>
                <FmTextField
                  name="secondInsurerAmount"
                  control={control}
                  label="Second Insurer Amount"
                />
              </Grid>
              <Grid item md={GridValue}>
                <FmSearchableSelect
                  name="thirdInsurer"
                  control={control}
                  apiUrl="companyinsurancepolicyinsurers"
                  valueField="companyInsurancePolicyInsurerId"
                  labelField={[
                    "companyInsurancePolicyInsurerCode",
                    "companyInsurancePolicyInsurerName",
                  ]}
                  showField={[
                    "companyInsurancePolicyInsurerCode",
                    "companyInsurancePolicyInsurerName",
                  ]}
                  label="Third Insurer"
                  onChangeProp={handleThirdInsurerChange}
                />
              </Grid>
              <Grid item md={GridValue}>
                <FmTextField
                  name="thirdInsurerAmount"
                  control={control}
                  label="Accommodation Amount"
                />
              </Grid>
            </Grid>
          </LocalizationProvider>
          <ActionButtons
            onSubmit={handleSubmit(handleSubmitComparisonPolicy)}
            onCancel={() => navigate(-1)}
            onReset={() => reset()}
            submitText="Submit"
            cancelText="Go Back"
            submitLoading={createLoading}
            cancelLoading={false}
          />
        </form>
      </GlassCard>
    </DashboardLayout>
  );
};

export default AddComparisonPolicy;
