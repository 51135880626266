import { Box } from '@mui/material';
import React, { useEffect, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { toast } from 'react-toastify';
import Crud_Service from '../../../apis/CrudService';
import TableSearch from '../../../components/_form/TableSearch.jsx';
import { ToolbarBoxesFlex } from '../../../components/boxes/Boxes.jsx';
import DashboardLayout from '../../../components/DashboardLayout.tsx';
import DataGridHeaderTooltip from '../../../components/datagrid/DataGridHeaderTooltip.tsx';
import DataTable from '../../../components/DataTable.tsx';
import DataTableToolbarButtons from '../../../components/helpers/DataTableToolbarButtons.tsx';
import GlassCard from '../../../components/small/GlassCard.tsx';
import ViewTableLightText from '../../../components/view/ViewTableLightText.tsx';

const CBAList = () => {
  const crud = new Crud_Service();
  const [gla, setGLA] = useState([]);
  const navigate = useNavigate();
  const [pageSize, setPageSize] = useState(50);
  const [pageNumber, setPageNumber] = useState(1);
  const [searchKeyword, setSearchKeyword] = useState('');
  const [sortBy, setSortBy] = useState('');
  const [sortDirection, setSortDirection] = useState('');
  const [rows, setRows] = useState([]);
  const [loading, setLoading] = useState(false);
  const [tablerecordCounts, setTableRecordCounts] = useState(null);

  const handlePagination = (model) => {
    setPageSize(model?.pageSize);
    setPageNumber(model?.page + 1);
  };
  useEffect(() => {
    document.title = `Company Bank Accounts | Financial`;
  }, []);
  useEffect(() => {
    getGLA();
  }, [pageSize, pageNumber, searchKeyword, sortBy, sortDirection]);

  const getGLA = async () => {
    await crud.getAll(
      'companybankaccounts',
      {
        pageSize,
        pageNumber,

        searchKeyword,
        sortBy,
        sortDirection,
      },
      (err, res) => {
        if (err) {
          toast.error('Error fetching Company Bank Accounts', err);
          return;
        } else if (res?.status === 200) {
          setTableRecordCounts(res?.data);
          const data = res?.data?.data || [];
          const transformedData = data.map((row) => ({
            ...row,
            id: row?.companyBankAccountId,
          }));
          setRows(transformedData);
        } else {
          setGLA([]);
        }
      }
    );
  };
  const handleViewClickOpen = async (props) => {
    navigate(`view/${props?.companyBankAccountId}`);
  };

  //   const Actions = [
  //     {
  //       name: 'View',
  //       icon: Icons.view,
  //       onClick: (props) => handleViewClickOpen(props),
  //     },
  //   ];
  const handleSortChange = (sortModel) => {
    if (sortModel.length > 0) {
      setSortBy(sortModel[0].field);
      setSortDirection(sortModel[0].sort);
    } else {
      setSortBy('');
      setSortDirection('');
    }
  };
  const columns = [
    // {
    //   field: 'action',
    //   headerName: '',
    //   flex: 1,
    //   minWidth: 80,
    //   cellClassName: 'p-0',
    //   align: 'center',
    //   sortable: false,
    //   disableClickEventBubbling: false,
    //   renderCell: (params) => {
    //     return (
    //       <TableActionButtons
    //         Actions={Actions?.map((action) => ({
    //           ...action,
    //           onClick: () => action.onClick(params.row),
    //         }))}
    //       />
    //     );
    //   },
    // },

    {
      field: 'companyBankAccountCode',
      headerName: (
        <DataGridHeaderTooltip name='Account Code' title='Bank Account' />
      ),
      minWidth: 160,
      flex: 1,
      renderCell: (params) => {
        return (
          <>
            <Box>
              <Box sx={{ color: 'text.dark' }}>{params?.value}</Box>
              <ViewTableLightText
                name={
                  <Box className='flex gap-1 w-full'>
                    <span className='font-semibold'>
                      <DataGridHeaderTooltip
                        name='Manual Code'
                        title='Manual Code'
                      />
                    </span>
                    {params?.row?.companyBankAccountManualCode}
                  </Box>
                }
              />
            </Box>
          </>
        );
      },
    },

    {
      field: 'companyBankAccountNo',
      headerName: 'Account No',
      minWidth: 200,
      flex: 1,
      renderCell: (params) => {
        return (
          <Box>
            <Box sx={{ color: 'text.dark' }}>{params?.value}</Box>
          </Box>
        );
      },
    },
    {
      field: 'companyBankAccountName',
      headerName: 'Account Name',
      minWidth: 200,
      flex: 1,
      renderCell: (params) => {
        return (
          <Box>
            <Box sx={{ color: 'text.dark' }}>{params?.value}</Box>
          </Box>
        );
      },
    },
    {
      field: 'paymentTypeName',
      headerName: 'Payment Type',
      minWidth: 450,
      flex: 1,
      renderCell: (params) => {
        return (
          <Box>
            <Box sx={{ color: 'text.dark' }}>{params?.value}</Box>
          </Box>
        );
      },
    },
  ];
  const onRowDoubleClick = (e) => {
    if (e?.row) {
      handleViewClickOpen(e?.row);
    }
  };

  return (
    <DashboardLayout title='Company Bank Account'>
      <Box className='flex flex-col h-full gap-2'>
        <GlassCard className='h-full-css flex-1 min-h-0 overflow-hidden'>
          <Box className='h-full-css'>
            <DataTable
              rows={rows}
              columns={columns}
              loading={loading}
              onRowDoubleClick={(e) => onRowDoubleClick(e)}
              getRowClassName={(params) =>
                `${params.row.workingStatus === 'InActive' &&
                'bg-red-500 bg-opacity-15'
                }`
              }
              pageSizeOptions={[2, 10, 20, 50, 100]}
              sortingMode='server'
              paginationMode='server'
              onPaginationModelChange={(model) => handlePagination(model)}
              onSortModelChange={(model) => handleSortChange(model)}
              page={pageNumber - 1}
              pageSize={pageSize}
              rowCount={tablerecordCounts?.total}
              fetchData={getGLA}
              slots={{
                toolbar: () => (
                  <ToolbarBoxesFlex>
                    <Box className='w-full' sx={{ maxWidth: '350px' }}>
                      <TableSearch
                        placeholder='Search'
                        fullWidth
                        setSearchKeyword={setSearchKeyword}
                        searchValue={searchKeyword}
                      />
                    </Box>

                    <DataTableToolbarButtons disableExport />
                  </ToolbarBoxesFlex>
                ),
              }}
              showSerialNumber={true}
            />
          </Box>
        </GlassCard>
      </Box>
    </DashboardLayout>
  );
};

export default CBAList;
