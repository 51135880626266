import React, { useEffect, useState } from "react";
import GlassCard from "../../../components/small/GlassCard.tsx";
import { Box, Grid } from "@mui/material";
import Crud_Service from "../../../apis/CrudService.jsx";
import ActionButtons from "../../../components/_form/ActionButtons.tsx";
import DashboardLayout from "../../../components/DashboardLayout.tsx";
import Nav from "../../../utils/Nav.tsx";
import { useNavigate, useParams } from "react-router-dom";
import CommonLoader from "../../../components/page/CommonLoader.jsx";
import ViewFieldInline from "../../../components/view/ViewFieldInline.tsx";
import FmViewDateField from "../../../components/_mui/FmViewDateField.tsx";
import { CreateButton } from "../../../components/small/Buttons.jsx";
import CommonView from "../../../components/helpers/CommonView.jsx";
import DynamicViewFields from "../../../components/helpers/DynamicViewFields.jsx";

const GridSize = 2.4;
const ViewCompanyCertificate = () => {
  const crud = new Crud_Service();
  const id = useParams().id;
  const [workOrder, setWorkOrder] = useState({});
  const [loader, setLoader] = useState(true);
  const navigate = useNavigate();

  useEffect(() => {
    crud.getSingle("companycertifications", id, (_err, res) => {
      if (res) {
        setWorkOrder(res.data);
        setLoader(false);
      }
    });
  }, []);

  const excludeKeys = [
    "companyName",
    "certificationName",
    "locationName",
    "certificateStatusName",
    "contractName",
    "companyInsurancePolicyPremiumId",
    "companyInsurancePolicyTypeOfProductId",
    "startDate",
    "expiryDate",
    "status",
  ];

  return (
    <DashboardLayout
      title="VIew Company Certificate"
      actionButtons={
        <>
          <Box className="flex flex-row items-center whitespace-nowrap gap-3">
            <CreateButton
              name="Create"
              onClick={() =>
                navigate("create")
              }
            />
          </Box>
        </>
      }
    >
      <GlassCard className="p-3">
        {loader ? (
          <CommonLoader />
        ) : (
          <>
            <CommonView
              url="companycertifications"
              id={id}
              excludeKeys={excludeKeys}
              renderFields={(data, fields) => (
                <DynamicViewFields data={data} fields={fields} />
              )}
            />
            <Grid item md={12}>
              <ActionButtons
                cancelLoading={false}
                cancelText="Go Back"
                onCancel={() =>
                  navigate("/hrms/company-certificate")
                }
              />
            </Grid>
          </>
        )}
      </GlassCard>
    </DashboardLayout>
  );
};

export default ViewCompanyCertificate;
