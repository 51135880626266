import { useForm } from 'react-hook-form';
import useLoader from '../../../../components/helpers/UseLoader.tsx';
import Crud_Service from '../../../../apis/CrudService';
import { FormHelperText, Grid } from '@mui/material';
import GlassCard from '../../../../components/small/GlassCard.tsx';
import { ActionButtons } from '../../../../components/_form';
import { toast } from 'react-toastify';
import FmSearchableSelect from '../../../../components/_mui/FmSearchableSelect.tsx';
import { yupResolver } from '@hookform/resolvers/yup';
import * as Yup from 'yup';

const ProjectDetails = (props) => {
  const { setEnabledTabs, setValues, createdEmployee } = props;
  const validationSchema = Yup.object().shape({
    projectId: Yup.string().required('Project is required'),
  });
  const {
    handleSubmit,
    control,
    reset,
    formState: { errors },
  } = useForm({
    resolver: yupResolver(validationSchema),
    mode: 'onChange',
  });
  const { startCreateLoading, stopCreateLoading, createLoading } = useLoader();
  const crud = new Crud_Service();

  // Handle Form Submission
  const handleProjectSubmit = (values) => {
    startCreateLoading();

    const combinedData = {
      ...values,
      employeeId: createdEmployee?.employeeId,
      status: 2,
    };

    crud.update(
      `employees/${createdEmployee?.employeeId}/employee-project-detail/${createdEmployee?.employeeProjectDetailId}`,
      '',
      combinedData,
      (err, res) => {
        if (err) {
          stopCreateLoading();
          toast.error('Error creating employee:', err);
          return;
        }
        if (res?.status === 200) {
          stopCreateLoading();
          toast.success('Project details Updated successfully');
          setValues(6);
          setEnabledTabs(1);
          setEnabledTabs(2);
          setEnabledTabs(3);
          setEnabledTabs(4);
          setEnabledTabs(5);
          setEnabledTabs(6);
        } else {
          stopCreateLoading();
        }
      }
    );

    stopCreateLoading();
  };

  return (
    <GlassCard className='p-4'>
      <form onSubmit={handleSubmit(handleProjectSubmit)}>
        <Grid container spacing={3}>
          <Grid item md={6}>
            <FmSearchableSelect
              name='projectId'
              control={control}
              apiUrl='projects'
              valueField='projectId'
              labelField={['projectCode', 'projectName']}
              showField={['projectName']}
              label={'Projects'}
              required
            />
          </Grid>
        </Grid>

        <ActionButtons
          onSubmit={handleSubmit(handleProjectSubmit)}
          onCancel={() => setValues(4)}
          onReset={reset}
          submitLoading={createLoading}
          cancelLoading={false}
        />
      </form>
    </GlassCard>
  );
};

export default ProjectDetails;
