import { Box } from '@mui/material';
import React, { useEffect, useState } from 'react';
import { useFieldArray, useForm } from 'react-hook-form';
import { useNavigate, useParams } from 'react-router-dom';
import { toast } from 'react-toastify';
import Crud_Service from '../../../apis/CrudService';
import ActionButtons from '../../../components/_form/ActionButtons.tsx';
import FmFileInput from '../../../components/_mui/FmFileInput.tsx';
import useLoader from '../../../components/helpers/UseLoader.tsx';
import GlassCard from '../../../components/small/GlassCard.tsx';
import ScopeNotesTextEditor from '../../../components/page/ScopeNotesTextEditor.tsx';

const ScopeNotes = () => {
  const { control, handleSubmit, reset, setValue } = useForm({
    defaultValues: {
      scopeNotes: [
        {
          subject: '',
          fileId: null,
        },
      ],
    },
  });

  const { fields, append, remove } = useFieldArray({
    control,
    name: 'scopeNotes',
  });

  const crud = new Crud_Service();
  const { createLoading, startCreateLoading, stopCreateLoading } = useLoader();
  const [uploadLoading, setUploadLoading] = useState({});
  const [uploadedFileData, setUploadedFileData] = useState({});
  const tenderId = useParams().id;
  const navigate = useNavigate();
  const [scopeNotes, setScopeNotes] = useState(null);
  const [checkTenderScope, setCheckTenderScope] = useState(false);

  useEffect(() => {
    crud.getAll(`tenderscopes?tenderId=${tenderId}`, {}, (err, res) => {
      if (res?.data?.data?.length > 0) {
        setCheckTenderScope(true);
        setScopeNotes(res?.data?.data?.[0]?.subject);
        setUploadedFileData(res?.data?.data);
      } else {
        setCheckTenderScope(false);
      }
    });
  }, []);

  const onSubmit = async (values) => {
    startCreateLoading();
    const formattedValues = {
      ...values,
      tenderId: Number(tenderId),
      scopeNotes: [
        {
          subject: scopeNotes || '',
          fileId: uploadedFileData['0']?.fileId || null, // Extracting fileId safely
        },
      ],
    };
    const UpdateformattedValues = {
      scopeNotes: [
        {
          subject: scopeNotes || '',
          fileId: values?.fileId || uploadedFileData['0']?.fileId || null, // Extracting fileId safely
        },
      ],
    };
    if (checkTenderScope) {
      await crud.update(
        'tenderscopes',
        tenderId,
        UpdateformattedValues,
        (err, res) => {
          stopCreateLoading();

          if (err) {
            toast.error(err);
            stopCreateLoading();
            return;
          }

          if (res?.status === 201) {
            toast.success('Scope Notes Updated Successfully');
            stopCreateLoading();
          } else {
            stopCreateLoading();
          }
        }
      );
    } else {
      await crud.create('tenderscopes', formattedValues, (err, res) => {
        stopCreateLoading();

        if (err) {
          toast.error(err);
          stopCreateLoading();
          return;
        }

        if (res?.status === 201) {
          toast.success('Scope Notes Created Successfully');
          stopCreateLoading();
        } else {
          stopCreateLoading();
        }
      });
    }
  };

  const handleFileUpload = async (e, index) => {
    const file = e.target.files[0];
    if (!file) return;

    setUploadLoading((prev) => ({ ...prev, [index]: true }));

    const formData = new FormData();
    formData.append('file', file);
    formData.append('fileTypeId', 64);

    await crud.create('/files/upload', formData, (err, res) => {
      setUploadLoading((prev) => ({ ...prev, [index]: false }));

      if (err || res?.status !== 200) {
        toast.error('File upload failed');
        return;
      }

      setUploadedFileData((prev) => ({
        ...prev,
        [index]: res?.data,
      }));
    });
  };

  const handleRemove = (index) => {
    remove(index);

    // Remove corresponding uploadedFileData entry
    setUploadedFileData((prev) => {
      const updatedData = { ...prev };
      delete updatedData[index];

      // Re-index remaining data to ensure alignment with field array
      return Object.keys(updatedData).reduce((acc, key) => {
        const newKey = key > index ? key - 1 : key;
        acc[newKey] = updatedData[key];
        return acc;
      }, {});
    });

    // Remove loading state for the removed index
    setUploadLoading((prev) => {
      const updatedLoading = { ...prev };
      delete updatedLoading[index];
      return updatedLoading;
    });
  };
  return (
    <GlassCard className='p-2'>
      <form onSubmit={handleSubmit(onSubmit)}>
        {fields?.map((field, index) => (
          <Box key={field?.id}>
            {/* <Grid container spacing={2}>
              <Grid item md={2}>
                <FmFileInput
                  name={`scopeNotes[${index}].attachment`}
                  documentName={uploadedFileData[index]?.fileName || ''}
                  onChange={(e) => handleFileUpload(e, index)}
                  loading={uploadLoading[index] || false}
                />
              </Grid>
              <Grid item md={10}>
                <FmTextField
                  name={`scopeNotes[${index}].subject`}
                  control={control}
                  label='Scope Notes'
                  required
                  maxRows={3}
                  rows={1}
                  multiline
                />
              </Grid>
            </Grid> */}
            <Box className='flex justify-between items-center mb-2'>
              <FmFileInput
                name={`scopeNotes[${index}].attachment`}
                documentName={
                  uploadedFileData[index]?.fileName || 'Upload Scope Notes'
                }
                onChange={(e) => handleFileUpload(e, index)}
                loading={uploadLoading[index] || false}
              />
            </Box>
            <ScopeNotesTextEditor
              scopeNotes={scopeNotes}
              setScopeNotes={setScopeNotes}
            />
            {/* <IconButton
              onClick={() => handleRemove(index)}
              disabled={fields.length === 1}
              sx={{ padding: '1px' }}
              color='error'
            >
              <Icon icon='ic:baseline-clear' />
            </IconButton> */}
          </Box>
        ))}
        {/* <Box className='mt-5 w-full flex justify-end'>
          <Button
            variant='text'
            onClick={() => append({ notes: '', attachment: null })}
            className='uppercase font-bold text-red-500 pe-0'
            startIcon={<Icon icon='ic:baseline-plus' />}
          >
            Add Field
          </Button>
        </Box> */}
        <Box className='mt-5'>
          <ActionButtons
            submitText={checkTenderScope ? 'Save' : 'Create'}
            onSubmit={handleSubmit(onSubmit)}
            onCancel={() => navigate(-1)}
            submitLoading={createLoading}
          />
        </Box>
      </form>
    </GlassCard>
  );
};

export default ScopeNotes;
