import React from 'react'
import GlassCard from '../../../components/small/GlassCard.tsx'
import { Button, Grid } from '@mui/material'
import { Box } from '@mui/material'
import { ViewField } from '../../../components/_form/FormElements.jsx'
import FmViewDateField from '../../../components/_mui/FmViewDateField.tsx'
import { acknowledgementStatusEnum } from '../../../utils/CommonVariables.tsx'
import StatusNewBadge from '../../../components/_form/StatusNewBadge.tsx'

const ViewCollectionAgent = (props) => {
    const { collectionData, onHandoverClick, isDisabled } = props;
    return (
        <>
            <GlassCard className='p-3  mb-4'>
                <>
                    <Box>
                        <Box className='mb-4'>
                            <Grid container spacing={2}>
                                <Grid item md={12}>
                                    <Box className='font-bold text-base mb-2'>
                                        {collectionData?.salesInvoiceCode || 'N/A'}
                                    </Box>

                                    <Box className='flex gap-2 mb-2'>
                                        <ViewField
                                            label='Employee Code'
                                            title={collectionData?.employeeCode || 'N/A'}
                                        />
                                        <ViewField
                                            label='Employee Name'
                                            title={collectionData?.employeeName || 'N/A'}
                                        />
                                        <ViewField
                                            label='Delivery Date'
                                            title={
                                                <FmViewDateField
                                                    value={collectionData?.deliveryDate || 'N/A'}
                                                />
                                            }
                                        />
                                        <ViewField
                                            label='Invoice Date'
                                            title={
                                                <FmViewDateField
                                                    value={collectionData?.invoiceDate || 'N/A'}
                                                />
                                            }
                                        />
                                        <ViewField
                                            label='Handover To Client'
                                            title={
                                                <FmViewDateField
                                                    value={collectionData?.acknowledgeDate || 'N/A'}
                                                />
                                            }
                                        />
                                        <ViewField
                                            label='Client Code'
                                            title={collectionData?.clientCode || 'N/A'}
                                        />
                                        <ViewField
                                            label='Client Name'
                                            title={collectionData?.clientName || 'N/A'}
                                        />
                                    </Box>
                                    <Box className='flex gap-2 mb-2'>
                                        <ViewField
                                            label='Status'
                                            title={<StatusNewBadge
                                                title={collectionData?.acknowledgmentStatusName}
                                                type={collectionData?.acknowledgmentStatusName?.toLowerCase()}
                                            />}
                                        />
                                    </Box>
                                </Grid>
                            </Grid>
                        </Box>
                        {collectionData?.acknowledgmentStatusId === acknowledgementStatusEnum?.pending && (
                            <Button variant='contained'
                                className='flex flex-end justify-end'
                                onClick={onHandoverClick}
                                disabled={isDisabled}
                            >Handover To Client</Button>
                        )}
                    </Box>
                </>
            </GlassCard>
        </>
    )
}

export default ViewCollectionAgent
