import React from 'react';
import { Button, Box } from '@mui/material';

const SideBarMenuButton = ({ name, icon, ref, ...props }) => {
  return (
    <Button
      sx={{
        width: '100%',
        justifyContent: 'flex-start',
        px: '2px',
        py: 0.3,
        borderRadius: '7px',
        textAlign: 'left',
        fontWeight: 600,
        color: 'inherit',
        '&:hover': {
          bgcolor: 'background.overlayTwo',
        },
        '&.active': {
          bgcolor: 'primary.main',
          color: 'text.white',
          boxShadow: '0px 0px 5px 0px rgb(0 0 0 / 8%)',
        },
      }}
      title={name}
      ref={ref}
      {...props}
    >
      <Box
        className='icon flex justify-center items-center'
        sx={{
          minWidth: '30px',
          height: '30px',
          marginRight: '5px',
          '& svg': {
            fontSize: '20px',
          },
        }}
      >
        {icon}
      </Box>
      <Box sx={{ width: 'calc(100% - 45px)' }}>
        <Box className='w-full whitespace-nowrap overflow-hidden text-ellipsis'>
          {name}
        </Box>
      </Box>
    </Button>
  );
};

export default SideBarMenuButton;
