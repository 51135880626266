import React, { useEffect, useMemo, useState, useCallback } from 'react';
import { Icon } from '@iconify/react';
import { Box, Button, Stack, Tooltip, CircularProgress } from '@mui/material';
import dayjs from 'dayjs';
import { useSelector } from 'react-redux';
import { toast } from 'react-toastify';
import apiInstance from '../../../../apis/ApiService.jsx';
import Crud_Service from '../../../../apis/CrudService.jsx';
import {
  LeaveColors,
  LeaveText,
} from '../../../../components/_form/FormElements.jsx';
import MuiPopoverCustom from '../../../../components/_form/MuiPopoverCustom.jsx';
import TableSearch from '../../../../components/_form/TableSearch.jsx';
import MuiDialogOne from '../../../../components/_mui/MuiDialogOne.jsx';
import DashboardLayout from '../../../../components/DashboardLayout.tsx';
import DataTable from '../../../../components/DataTable.tsx';
import DataTableToolbarButtons from '../../../../components/helpers/DataTableToolbarButtons.tsx';
import { CreateButton } from '../../../../components/small/Buttons.jsx';
import GlassCard from '../../../../components/small/GlassCard.tsx';
import { findNavByName } from '../../../../utils/navUtils.ts';
import AttendanceTimePicker from './AttendanceTimePicker.tsx';
import { useNavigate, useLocation } from 'react-router-dom';
import { AttendanceText } from '../../../../utils/JsUtils.jsx';
import AttendanceAvatar from '../../../../components/attendance/AttendanceAvatar.tsx';
import useCommonFetchApi from '../../../../components/helpers/useCommonFetchApi.tsx';
import FmDateTableFilter from '../../../../components/filter/FmDateTableFilter.tsx';
import FmViewDateField from '../../../../components/_mui/FmViewDateField.tsx';
import DataGridHeaderTooltip from '../../../../components/datagrid/DataGridHeaderTooltip.tsx';
import AttendanceTimingColumn from '../../../../components/attendance/AttendanceTimingColumn.tsx';
import { attendanceMinDate } from '../../../../utils/CommonVariables.tsx';
import FmFileInput from '../../../../components/_mui/FmFileInput.tsx';

const getCurrentMonthCalculation = () => {
  const today = dayjs();
  if (today.date() < 21) {
    return dayjs().endOf('month');
  } else {
    return dayjs().startOf('month').add(1, 'month');
  }
};

const formatHours = (value) => {
  if (!value) return '';

  const hours = Math.floor(value / 60)
    .toString()
    .padStart(2, '0');
  const minutes = (value % 60).toString().padStart(2, '0');

  return `${hours}:${minutes}`;
};

const ColumnWidth = {
  minWidth: 45,
  maxWidth: 45,
  sortable: false,
  headerClassName: 'justify-center text-center',
  cellClassName: 'justify-center bg-purple-50',
  headerAlign: 'center',
};

const ColumnWidthOther = {
  minWidth: 100,
  maxWidth: 100,
  sortable: false,
  headerClassName: 'justify-center text-center',
  cellClassName: 'justify-center bg-cyan-50',
  headerAlign: 'center',
};

const IndicadreorsData = [
  {
    smalltext: 'P',
    text: 'Present',
  },
  {
    smalltext: 'PH',
    text: 'Public Holiday',
  },
  {
    smalltext: 'H',
    text: 'Holiday',
  },
  {
    smalltext: 'SL',
    text: 'Sick Leave',
  },
  {
    smalltext: 'L',
    text: 'Leave',
  },
  {
    smalltext: 'FH',
    text: 'First Half',
  },
  {
    smalltext: 'SH',
    text: 'Second Half',
  },
  {
    smalltext: '',
    text: 'Not Available',
  },
];

const AttendanceSummary = () => {
  const [textShow, setTextShow] = useState(false);
  const crud = new Crud_Service();
  const [rowData, setRowData] = useState({});
  const [isLoading, setIsLoading] = useState(false);
  const [openUpload, setOpenUpload] = useState(false);
  const [searchKeyword, setSearchKeyword] = useState('');
  const [selectedDate, setSelectedDate] = useState(
    getCurrentMonthCalculation()
  );

  const [pageSize, setPageSize] = useState(50);
  const [pageNumber, setPageNumber] = useState(1);
  const [sortBy, setSortBy] = useState('');
  const [tablerecordCounts, setTableRecordCounts] = useState(null);
  const [sortDirection, setSortDirection] = useState('');
  const [selectedDayData, setSelectedDayData] = useState(null);
  const menu = useSelector((state) => state?.menu?.items);
  const location = useLocation();
  const [UploadingFile, setUploadingFile] = useState(false);
  const [uploadedfileData, setUploadedfileData] = useState(null);

  const navigate = useNavigate();

  const { data: statusOptions } = useCommonFetchApi(
    'employeeattendancetransactiondisplaycolors'
  );

  useEffect(() => {}, [selectedDayData]);

  const SubMenu = useMemo(() => {
    return menu?.map((item) => item?.menus?.flat()).flat() || [];
  }, [menu]);

  const resultHRMS = useMemo(
    () => findNavByName(SubMenu, 'HRMS', 'Employee'),
    [SubMenu]
  );

  const toggleLoading = (loading) => {
    setIsLoading(loading);
  };

  useEffect(() => {
    fetchData(selectedDate, searchKeyword);
  }, [
    pageNumber,
    pageSize,
    sortBy,
    sortDirection,
    searchKeyword,
    selectedDate,
  ]);

  const handlePagination = (model) => {
    setPageSize(model?.pageSize);
    setPageNumber(model?.page + 1);
  };

  const fetchData = async (date) => {
    toggleLoading(true);
    const year = date.format('YYYY');
    const month = date.format('MM');
    const api =
      searchKeyword === ''
        ? `employeemonthlyattendances/${year}/${month}`
        : `employeemonthlyattendances/${year}/${month}?searchKeyword=${searchKeyword}`;

    crud.getAll(
      api,
      {
        pageSize,
        pageNumber,
        sortBy,
        sortDirection,
        searchKeyword,
      },
      (err, res) => {
        if (err) {
          toast.error('Error fetching Employee Attendance:', err);
          toggleLoading(false);
          return;
        } else if (res?.status === 204) {
          toggleLoading(false);
          setRowData({});
          return;
        }
        setTableRecordCounts(res?.data);
        setRowData(res.data.data);
        toggleLoading(false);
      }
    );
  };

  const handleTextShow = () => {
    setTextShow(!textShow);
  };

  const handleSortChange = (sortModel) => {
    if (sortModel.length > 0) {
      setSortBy(sortModel[0].field);
      setSortDirection(sortModel[0].sort);
    } else {
      setSortBy('');
      setSortDirection('');
    }
  };

  const isWeekend = (year, month, day) => {
    const date = new Date(year, month, day);
    const dayOfWeek = date.getDay();
    return dayOfWeek === 0;
  };

  const headerTextWrapper = (month, day, date) => {
    return (
      <Box>
        <Box sx={{ fontWeight: 600 }}>
          {month} {day}
        </Box>
        <Box sx={{ fontSize: '10px', opacity: 1, lineHeight: 1 }}>{date}</Box>
      </Box>
    );
  };

  const isCurrentDate = (year, month, day) => {
    const today = new Date();
    return (
      today.getFullYear() === year &&
      today.getMonth() === month &&
      today.getDate() === day
    );
  };

  const isWithinLast7Days = (year, month, day) => {
    const today = new Date();
    const checkDate = new Date(year, month, day);
    const sevenDaysAgo = new Date(today);
    sevenDaysAgo.setDate(today.getDate() - attendanceMinDate);
    return checkDate >= sevenDaysAgo && checkDate <= today;
  };

  const GetdisabledButton = (date) => {
    const today = new Date();
    const checkDate = new Date(date);
    const sixDaysAgo = new Date(today);
    sixDaysAgo.setDate(today.getDate() - attendanceMinDate);
    today.setHours(0, 0, 0, 0);
    checkDate.setHours(0, 0, 0, 0);
    sixDaysAgo.setHours(0, 0, 0, 0);
    return checkDate >= sixDaysAgo && checkDate <= today;
  };

  const handleClose = () => {
    setOpenUpload(false);
    setUploadedfileData(null);
  };

  const generateRows = (employees) => {
    return employees.map((employee, index) => {
      const attendance = {};

      employee.days.forEach((day, dayIndex) => {
        const dayKey = `day_${dayIndex}`;
        attendance[dayKey] = day.attendanceCode;
      });

      return {
        id: index,
        ...attendance,
        ...employee,
      };
    });
  };

  const rows = useMemo(
    () => generateRows(rowData[0]?.employeeMonthlyAttendances || []),
    [rowData[0]?.employeeMonthlyAttendances]
  );

  const handleCellClick = useCallback((params, disabled) => {
    if (!disabled) {
      return toast.error('Oops! This date is not editable.');
    } else {
      const { field, row } = params;
      if (field.startsWith('day_')) {
        const dayIndex = parseInt(field.split('_')[1], 10);

        const dayData = row.days?.[dayIndex];

        if (dayData?.employeeMonthlyAttendanceId) {
          setSelectedDayData((prev) =>
            prev !== dayData.employeeMonthlyAttendanceId
              ? dayData.employeeMonthlyAttendanceId
              : prev
          );
        } else {
          navigate(`${location.pathname}/create`, {
            state: {
              employeeFields: row,
              date: dayData?.date,
            },
          });
        }
      }
    }
  }, []);

  const renderAttendanceCell = useCallback(
    (dayData, textShow, handleCellClick, params) => {
      const AttendanceLoop = AttendanceText(dayData?.attendanceCode);

      const tooltipContent = [
        { label: AttendanceLoop?.name, title: '' },
        { label: 'Start Time', title: dayData?.startTime },
        { label: 'End Time', title: dayData?.endTime },
        { label: 'OT', title: dayData?.otMinutes },
        { label: 'Additional OT', title: dayData?.additionalOTMinutes },
      ];

      const startEnd = [
        { label: 'Start Time', title: dayData?.startTime },
        { label: 'End Time', title: dayData?.endTime },
      ];

      const commonProps = {
        text: dayData?.attendanceCode,
        tooltipOff: true,
        attendanceStatusApproved: dayData?.attendanceStatusApproved,
        tooltipContent,
        onClick: () =>
          handleCellClick(params, GetdisabledButton(dayData?.date)),
        startEnd,
        disabled: GetdisabledButton(dayData?.date),
      };

      return textShow ? (
        <LeaveColors {...commonProps} />
      ) : (
        <LeaveText {...commonProps} />
      );
    },
    []
  );

  const generateDateColumns = useCallback(
    (days) => {
      return days.map((day, index) => ({
        field: `day_${index}`,
        headerName: headerTextWrapper(
          new Date(day.date).toLocaleString('default', { month: 'short' }),
          new Date(day.date).getDate(),
          day.day
        ),
        headerClassName: `text-no-wrap p-0 ${
          isWeekend(
            new Date(day.date).getFullYear(),
            new Date(day.date).getMonth(),
            new Date(day.date).getDate()
          )
            ? 'right-border-add'
            : isCurrentDate(
                new Date(day.date).getFullYear(),
                new Date(day.date).getMonth(),
                new Date(day.date).getDate()
              )
            ? 'text-orange-600 bg-orange-100'
            : ''
        }`,
        width: 60,
        sortable: false,
        disableClickEventBubbling: false,
        cellClassName: `${
          isWeekend(
            new Date(day.date).getFullYear(),
            new Date(day.date).getMonth(),
            new Date(day.date).getDate()
          ) && 'right-border-add'
        } ${
          isCurrentDate(
            new Date(day.date).getFullYear(),
            new Date(day.date).getMonth(),
            new Date(day.date).getDate()
          ) && 'bg-orange-50'
        } ${GetdisabledButton(day?.date) && 'bg-blue-50'} `,
        align: 'center',
        headerAlign: 'center',
        renderCell: (params) => {
          const dayData = params.row.days?.[index];
          return renderAttendanceCell(
            dayData,
            textShow,
            handleCellClick,
            params
          );
        },
      }));
    },
    [textShow, handleCellClick, renderAttendanceCell]
  );

  const columns = useMemo(
    () => [
      {
        field: 'employeeName',
        headerName: 'Name',
        hideable: false,
        width: 320,
        cellClassName: 'sticky-column',
        headerClassName: 'sticky-header',
        renderCell: (params) => {
          return (
            <AttendanceAvatar
              name={params?.value}
              currentDate={10}
              TotalDate={22}
              desc={`Code: ${params?.row?.employeeCode}${
                params?.row?.divisionName
                  ? ` | Division: ${params?.row?.divisionName}`
                  : ''
              }`}
              tooltip={false}
              tooltipContent={[
                { label: 'Total Working Hours', title: '40H : 00M' },
                { label: 'Early Out Hours', title: '02H : 30M' },
                { label: 'Late In Hours', title: '26H : 00M' },
              ]}
              cadre
              cadreText={params?.row?.cadre}
            />
          );
        },
      },

      {
        field: 'contractCode',
        headerName: 'Code',
        // headerClassName: 'bg-yellow-100',
        cellClassName: 'bg-yellow-50',
        sortable: false,
        width: 150,
        renderCell: (params) => {
          return (
            <Box>
              {' '}
              {params?.row?.salesOrderCode && (
                <Box title='Sales Order Code'>
                  {params?.row?.salesOrderCode}
                </Box>
              )}
              {params?.value && (
                <Box title='Contract Code'> {params?.value}</Box>
              )}
              {params?.row?.projectCode && (
                <Box title='Project Code'>{params?.row?.projectCode}</Box>
              )}
            </Box>
          );
        },
      },

      {
        field: 'presentDays',
        headerName: <DataGridHeaderTooltip name='P' title='Present' />,
        ...ColumnWidth,
      },
      {
        field: 'absentDays',
        headerName: <DataGridHeaderTooltip name='A' title='Absent' />,
        ...ColumnWidth,
      },
      {
        field: 'otherLeave',
        headerName: <DataGridHeaderTooltip name='L' title='Leave' />,
        ...ColumnWidth,
      },
      {
        field: 'medicalLeave',
        headerName: <DataGridHeaderTooltip name='ML' title='Medical Leave' />,
        ...ColumnWidth,
      },
      {
        field: 'notMinutes',
        headerName: (
          <DataGridHeaderTooltip name='NOT' title='Normal Overtime' />
        ),
        ...ColumnWidthOther,
        renderCell: (params) => {
          const data = [{ name: 'OT:', value: formatHours(params?.value) }];

          if (params?.row?.showAdditionalOTMinutes) {
            data.push({
              name: 'Ad.OT:',
              value: formatHours(params?.row?.additionalNOTMinutes),
            });
          }
          return <AttendanceTimingColumn data={data} />;
        },

        ...ColumnWidthOther,
      },
      {
        field: 'wotMinutes',
        headerName: (
          <DataGridHeaderTooltip name='WOT' title='Weekend Overtime' />
        ),
        sortable: false,
        ...ColumnWidthOther,
        renderCell: (params) => {
          const data = [{ name: 'OT:', value: formatHours(params?.value) }];

          if (params?.row?.showAdditionalOTMinutes) {
            data.push({
              name: 'Ad.OT:',
              value: formatHours(params?.row?.additionalWOTMinutes),
            });
          }

          return <AttendanceTimingColumn data={data} />;
        },
      },

      {
        field: 'photMinutes',
        headerName: (
          <DataGridHeaderTooltip name='PHOT' title='Public Holiday Overtime' />
        ),
        disableColumnMenu: true,
        ...ColumnWidthOther,
        renderCell: (params) => {
          const data = [{ name: 'OT:', value: formatHours(params?.value) }];

          if (params?.row?.showAdditionalOTMinutes) {
            data.push({
              name: 'Ad.OT:',
              value: formatHours(params?.row?.additionalPHOTMinutes),
            });
          }

          return <AttendanceTimingColumn data={data} />;
        },
      },

      ...generateDateColumns(
        rowData[0]?.employeeMonthlyAttendances[0]?.days || []
      ),
    ],
    [rowData, textShow, handleCellClick, generateDateColumns]
  );

  const handleUploadSheet = (event) => {
    setUploadingFile(true);
    const file = event.target.files[0];
    setUploadedfileData(file);

    const year = selectedDate?.format('YYYY');
    const month = selectedDate?.format('MM');
    if (file) {
      const fileFormData = new FormData();
      fileFormData.append('file', file);
      fileFormData.append('fileTypeId', 4);

      crud.create('files/upload', fileFormData, (_err, res) => {
        if (res?.status === 200) {
          crud.create(
            `files/process/${res.data?.fileId}?year=${year}&month=${month}`,
            '',
            (_err, res) => {
              if (res?.status === 200) {
                fetchData(selectedDate);
                setOpenUpload(false);
                setUploadingFile(false);
                setUploadedfileData(null);
                toast.success('Attendance uploaded successfully');
              } else {
                setUploadingFile(false);
              }
            }
          );
        } else {
          setOpenUpload(false);
          setUploadingFile(false);
          setUploadedfileData(null);
        }
      });
    }
  };

  const handleMonthChange = (event) => {
    setSelectedDate(event);
  };

  const handleDialogClose = useCallback(() => {
    setSelectedDayData(null);
  }, []);

  const handleExcelExport = async (selectedDate) => {
    const year = selectedDate.format('YYYY');
    const month = selectedDate.format('MM');
    const downloadUrl = await apiInstance.getFiles(
      `employeemonthlyattendances/export-excel/${year}/${month}`
    );

    const link = document.createElement('a');
    link.href = downloadUrl;
    link.setAttribute('download', 'Attendance.xlsx');
    document.body.appendChild(link);
    link.click();
    document.body.removeChild(link);
  };

  // Memoize the toolbar component
  const TableToolbar = React.memo(
    ({
      searchKeyword,
      setSearchKeyword,
      selectedDate,
      handleMonthChange,
      handleExcelExport,
    }) => {
      // Calculate the start and end dates for the selected month
      const startDate = selectedDate.startOf('month').format('MMMM D, YYYY');
      const endDate = selectedDate.endOf('month').format('MMMM D, YYYY');

      return (
        <Box
          className='flex justify-between items-center p-1'
          sx={{
            bgcolor: 'background.white',
            borderBottom: '1px solid',
            borderColor: 'border.main',
          }}
        >
          <Box className='flex-1'>
            <Stack direction='row' spacing={1} alignItems='center'>
              <Box sx={{ minWidth: '250px' }}>
                <TableSearch
                  placeholder='Search'
                  fullWidth
                  setSearchKeyword={setSearchKeyword}
                  searchValue={searchKeyword}
                />
              </Box>
              {rowData[0]?.attendanceStartDate && (
                <Box className='flex items-center justify-center gap-2 w-full text-sm'>
                  <span>
                    <Icon icon='stash:calendar-light' width='24' height='24' />
                  </span>
                  <FmViewDateField
                    value={rowData[0]?.attendanceStartDate}
                    valueColor='primary'
                  />
                  to <FmViewDateField value={rowData[0]?.attendanceEndDate} />
                </Box>
              )}
            </Stack>
          </Box>
          <Stack direction='row' spacing={2} alignItems='center'>
            <FmDateTableFilter
              value={selectedDate}
              onChange={(day) => handleMonthChange(day)}
              onHandlePrev={() =>
                handleMonthChange(selectedDate.subtract(1, 'month'))
              }
              onHandleNext={() =>
                handleMonthChange(selectedDate.add(1, 'month'))
              }
            />

            <Tooltip title='Upload Attendance Sheet' arrow>
              <Button
                onClick={() => setOpenUpload(true)}
                startIcon={<Icon icon='material-symbols:upload' />}
                className='py-1 px-3 font-semibold'
              >
                Upload
              </Button>
            </Tooltip>

            <DataTableToolbarButtons
              handleExcelExport={() => handleExcelExport(selectedDate)}
              disablePrintCSV={true}
            />
          </Stack>
        </Box>
      );
    }
  );

  const memoizedAttendanceTimePicker = useMemo(
    () =>
      selectedDayData ? (
        <AttendanceTimePicker
          selectedDayData={selectedDayData}
          handleDialogClose={handleDialogClose}
          onSuccess={() => fetchData(selectedDate)}
          statusOptions={statusOptions}
        />
      ) : null,
    [selectedDayData, handleDialogClose, selectedDate]
  );

  return (
    <>
      <DashboardLayout
        title='Attendance Summary'
        hasSubmenu
        menu={resultHRMS}
        actionButtons={
          <>
            <Box className='flex gap-2 items-center'>
              <MuiPopoverCustom
                data={IndicadreorsData}
                openState={!textShow}
                handleTextShow={handleTextShow}
              />

              <CreateButton
                variant='contained'
                name='Create Attendance'
                onClick={() => navigate('create')}
              />
            </Box>
          </>
        }
      >
        {/* Listing */}
        {
          <GlassCard className='h-full overflow-hidden'>
            <Box
              className='h-full-css'
              sx={{
                '& .sticky-column': {
                  position: 'sticky',
                  left: 0,
                  backgroundColor: 'white',
                  zIndex: 1,
                },

                '& .MuiDataGrid-columnHeaders': {
                  position: 'sticky',
                  top: 0,

                  '& .sticky-header': {
                    position: 'sticky',
                    left: 0,
                    zIndex: 2,
                  },
                },

                '& .text-no-wrap': {},
                '& .text-no-wrap .MuiDataGrid-columnHeaderTitle': {
                  whiteSpace: 'normal',
                  lineHeight: '18px',
                  textAlign: 'center',
                  fontWeight: 300,
                },
                '& .MuiDataGrid-root  .MuiDataGrid-cell.right-border-add, & .MuiDataGrid-root .MuiDataGrid-columnHeader.right-border-add ':
                  {
                    borderRight: '2px dashed #9b9b9b',
                  },
              }}
            >
              <DataTable
                rows={rows}
                columns={columns}
                loading={isLoading}
                sortingMode='server'
                paginationMode='server'
                onPaginationModelChange={handlePagination}
                onSortModelChange={handleSortChange}
                page={pageNumber - 1}
                pageSize={pageSize}
                rowCount={tablerecordCounts?.total}
                fetchData={() => fetchData(selectedDate)}
                slots={{
                  toolbar: () => (
                    <TableToolbar
                      searchKeyword={searchKeyword}
                      setSearchKeyword={setSearchKeyword}
                      selectedDate={selectedDate}
                      handleMonthChange={handleMonthChange}
                      handleExcelExport={handleExcelExport}
                    />
                  ),
                }}
                disableColumnFilter
                disableColumnMenu
                disableDensitySelector
                rowBuffer={10}
                rowThreshold={20}
                rowHeight={52}
                headerHeight={56}
                virtualisation
                disableRowSelectionOnClick
                autoHeight={false}
              />
            </Box>
            {/* Attendance upload dialog */}
            <MuiDialogOne
              title='Upload Attendance Document'
              open={openUpload}
              onClose={handleClose}
              width={'450px'}
            >
              <Box className='flex gap-2 items-center mb-4'>
                <Icon
                  icon='fluent:calendar-16-regular'
                  width='24'
                  height='24'
                />
                <Box>
                  Attendance Month -{' '}
                  <Box
                    className='font-semibold'
                    component={'span'}
                    color={'secondary.main'}
                  >
                    {' '}
                    {selectedDate?.format('MMMM YYYY')}
                  </Box>
                </Box>
              </Box>

              <GlassCard
                className='overflow-hidden w-full relative  flex px-3 min-h-[50px] items-center'
                bgLight
              >
                <FmFileInput
                  onChange={handleUploadSheet}
                  disabled={UploadingFile}
                  loading={UploadingFile}
                  name='file'
                  documentName={uploadedfileData?.name}
                  accept='.csv, application/vnd.openxmlformats-officedocument.spreadsheetml.sheet, application/vnd.ms-excel'
                />
              </GlassCard>
            </MuiDialogOne>

            {/* Attendance update dialog */}
            {selectedDayData && memoizedAttendanceTimePicker}
          </GlassCard>
        }
      </DashboardLayout>
    </>
  );
};

export default AttendanceSummary;
