import React from 'react';
import GlassCard from '../../../components/small/GlassCard.tsx';
import { Grid } from '@mui/material';
import Crud_Service from '../../../apis/CrudService.jsx';
import { useForm } from 'react-hook-form';
import { yupResolver } from '@hookform/resolvers/yup';
import { VendorInvoiceSchema } from '../../../utils/CommonvalidationSchemas.tsx';
import FmTextField from '../../../components/_mui/FmTextField.tsx';
import { toast } from 'react-toastify';
import FmDatePicker from '../../../components/_mui/FmDatePicker.tsx';
import FmSearchableSelect from '../../../components/_mui/FmSearchableSelect.tsx';
import ViewFieldInline from '../../../components/view/ViewFieldInline.tsx';
import ActionButtons from '../../../components/_form/ActionButtons.tsx';
import dayjs from 'dayjs';

const GridSize = 2;

const AddVendorInvoiceReceivable = (props) => {
  const { getData } = props;
  const crud = new Crud_Service();
  const [selectedUser, setSelectedUser] = React.useState({});

  const { handleSubmit, control, reset } = useForm({
    resolver: yupResolver(VendorInvoiceSchema),
    mode: 'onChange',
  });

  const handleSubmitVendorInvoice = async (data: any) => {
    const values = {
      ...data,
      vendorinvoiceDate: data?.vendorinvoiceDate?.format('YYYY-MM-DD'),
    };
    await crud.create('vendorinvoices', values, (_err, res) => {
      if (res && res.status === 201) {
        toast.success('Vendor Invoice Created Successfully');
        getData(true);
        reset();
        setSelectedUser({});
      }
    });
  };
  const handleUser = async (e: any) => {
    await crud.getSingle('employees', e?.employeeId, (_err, res) => {
      setSelectedUser(res?.data);
    });
  };

  return (
    <GlassCard className='p-3'>
      <form onSubmit={handleSubmit(handleSubmitVendorInvoice)}>
        <Grid container spacing={2}>
          <Grid item md={GridSize}>
            <FmTextField
              name='referenceNo'
              label='Reference #'
              control={control}
              variant='filled'
            />
          </Grid>
          <Grid item md={GridSize}>
            <FmDatePicker
              name='vendorinvoiceDate'
              label='Invoice Date'
              control={control}
              minDate={dayjs()}
              variant='filled'
            />
          </Grid>
          <Grid item md={GridSize}>
            <FmSearchableSelect
              name='receivedBy'
              label='Received By'
              apiUrl='loginusers'
              onChangeProp={handleUser}
              control={control}
              valueField='loginUserId'
              labelField={['emailId', 'loginUserName']}
              showField={['loginUserName']}
              required
            />
          </Grid>

          <Grid item md={GridSize}>
            <ViewFieldInline
              name='Department'
              value={selectedUser?.departmentName}
            ></ViewFieldInline>
          </Grid>

          <Grid item md={GridSize}>
            <ViewFieldInline
              name='Designation'
              value={selectedUser?.designationName}
            ></ViewFieldInline>
          </Grid>

          <Grid item md={12}>
            <ActionButtons
              onSubmit={handleSubmit(handleSubmitVendorInvoice)}
              onReset={() => {
                reset();
                setSelectedUser({});
              }}
              removeSpacing
              submitText='Create'
            />
          </Grid>
        </Grid>
      </form>
    </GlassCard>
  );
};

export default AddVendorInvoiceReceivable;
