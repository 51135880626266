import { Box, Grid } from '@mui/material';
import React, { useState, useEffect, useMemo } from 'react';
import { useLocation, useNavigate } from 'react-router-dom';
import Crud_Service from '../../../../apis/CrudService.jsx';
import { ViewField } from '../../../../components/_form/FormElements.jsx';
import TableActionButtons from '../../../../components/_form/TableActionButtons.jsx';
import MuiDialogOne from '../../../../components/_mui/MuiDialogOne.jsx';
import FmMRTDataTable from '../../../../components/FmMRTDataTable.tsx';
import useTableLogic from '../../../../components/helpers/MRTUseTableLogic.tsx';
import Icons from '../../../../utils/Icon.jsx';
import GlassCard from './../../../../components/small/GlassCard.tsx';
import {
  ProjectName,
  ContractName,
} from '../../../../components/table/MRTCommonColumns.tsx';
import StatusNewBadge from '../../../../components/_form/StatusNewBadge.tsx';
import DashboardLayout from './../../../../components/DashboardLayout.tsx';
import { CreateButton } from '../../../../components/small/Buttons.jsx';
import { SkeletonLoaderRow } from '../../../../components/page/SkeletonLoader.tsx';
import useCrudTableHook from '../../../../apis/Hook/useCrudTableHook.tsx';
import { useSelector } from 'react-redux';
import { findNavByName } from '../../../../utils/navUtils.ts';

const StoreLocations = () => {
  const [showView, setShowView] = useState(false);
  const crud = new Crud_Service();
  const { handleViewClick, loading, viewDetail, handleDelete } =
    useCrudTableHook({
      api: 'storelocations',
      viewId: 'storeLocationId',
      deleteHandleCustom: () => fetchData(),
    });
  const menu = useSelector((state) => state?.menu?.items);
  const SubMenu = useMemo(() => {
    return menu?.map((item) => item?.menus?.flat()).flat() || [];
  }, [menu]);

  const result = useMemo(
    () => findNavByName(SubMenu, 'Procurement', 'Inventory'),
    [SubMenu]
  );
  const {
    rows,
    isLoading,
    pagination,
    sorting,
    columnFilters,
    globalFilter,
    tableRecordCounts,
    setPagination,
    setSorting,
    setColumnFilters,
    setGlobalFilter,
    fetchData,
    setSearchKeyword,
    searchKeyword,
  } = useTableLogic(`storelocations`);
  const navigate = useNavigate();
  const location = useLocation();
  const ActionData = [
    {
      name: 'View',
      icon: Icons.view,
      onClick: (props) => {
        handleViewClick(props);
        setShowView(true);
      },
    },
    {
      name: 'Edit',
      icon: Icons.edit,
      onClick: (_props) => {
        navigate(`${location.pathname}/edit/${_props?.storeLocationId}`);
      },
    },
    {
      name: 'Delete',
      icon: Icons.delete,
      onClick: (props) => handleDelete(props),
      danger: true,
    },
  ];
  const columns = [
    {
      header: 'Actions',
      size: 80,
      minSize: 80,
      Cell: ({ row }) => (
        <Box className='flex gap-2'>
          <TableActionButtons
            Actions={ActionData?.map((action) => ({
              ...action,
              onClick: () => action.onClick(row?.original),
            }))}
          />
        </Box>
      ),
    },
    {
      accessorKey: 'storeLocationCode',
      header: 'Store Location Code',
      size: 150,
    },
    {
      accessorKey: 'storeLocationName',
      header: 'Store Location Name',
      size: 130,
    },
    {
      accessorKey: 'parentStoreLocationName',
      header: 'Parent Store Location Name',
      size: 210,
    },
    ContractName,
    {
      accessorKey: 'locationName',
      header: 'Location',
      size: 200,
      muiTableBodyCellProps: {
        className: 'bg-blue-50',
      },
    },

    {
      accessorKey: 'regionName',
      header: 'Region',
      size: 150,
    },

    ProjectName,

    {
      accessorKey: 'description',
      header: 'Description',
      size: 160,
    },
    {
      accessorKey: 'statusName',
      header: 'Status',
      size: 120,
      Cell: ({ cell }) => {
        return (
          <StatusNewBadge title={cell.getValue()} type={cell.getValue()} />
        );
      },
    },
  ];
  useEffect(() => {
    document.title = 'Store Locations | Inventory | Procurement';
  }, []);
  return (
    <DashboardLayout
      title='Store Locations'
      hasSubmenu={true}
      menu={result}
      actionButtons={
        <CreateButton
          name='Create'
          onClick={() => navigate(`${location.pathname}/create`)}
        />
      }
    >
      <GlassCard className='h-full-css'>
        <FmMRTDataTable
          columns={columns}
          enableRowSelection={false}
          rows={rows}
          rowCount={tableRecordCounts}
          pagination={pagination}
          sorting={sorting}
          fetchData={fetchData}
          columnFilters={columnFilters}
          globalFilter={globalFilter}
          setPagination={setPagination}
          setSorting={setSorting}
          setColumnFilters={setColumnFilters}
          setGlobalFilter={setGlobalFilter}
          isLoading={isLoading}
          setSearchKeyword={setSearchKeyword}
          searchKeyword={searchKeyword}
          muiTableContainerProps={{
            sx: {
              maxHeight: '100%',
              flex: 1,
            },
          }}
          showSerialNumber={true}
        />
      </GlassCard>
      {/* view */}
      <MuiDialogOne
        title='View'
        open={showView}
        onClose={() => setShowView(false)}
      >
        {loading ? (
          <SkeletonLoaderRow count={5} />
        ) : (
          <Grid container spacing={3}>
            <Grid item md={6}>
              <ViewField
                label='Store Location Code'
                title={viewDetail?.storeLocationCode}
              />
            </Grid>
            <Grid item md={6}>
              <ViewField
                label='Store Location Name'
                title={viewDetail?.storeLocationName}
              />
            </Grid>
            <Grid item md={6}>
              <ViewField
                label='Location Name'
                title={viewDetail?.locationName}
              />
            </Grid>

            <Grid item md={6}>
              <ViewField label='Region Name' title={viewDetail?.regionName} />
            </Grid>
            <Grid item md={6}>
              <ViewField label='Project Name' title={viewDetail?.projectName} />
            </Grid>
            <Grid item md={6}>
              <ViewField label='Status' title={viewDetail?.statusName} />
            </Grid>
          </Grid>
        )}
      </MuiDialogOne>
    </DashboardLayout>
  );
};

export default StoreLocations;
