import React, { useEffect, useState } from 'react';
import DashboardLayout from './../../../components/DashboardLayout.tsx';
import { CreateButton } from '../../../components/small/Buttons.jsx';
import DataTable from './../../../components/DataTable.tsx';
import GlassCard from './../../../components/small/GlassCard.tsx';
import { Icon } from '@iconify/react';
import { Box, Tab, Tabs } from '@mui/material';
import { useNavigate, useLocation } from 'react-router-dom';
import TableDropDown from '../../../components/helpers/TableDropDown.tsx';
import DataTableToolbarButtons from '../../../components/helpers/DataTableToolbarButtons.tsx';
import { ToolbarBoxesFlex } from '../../../components/boxes/Boxes.jsx';
import { StatusBadge } from '../../../components/_form/FormElements.jsx';
import useTableLogic from '../../../components/helpers/UseTableLogic.tsx';
import TableSearch from '../../../components/_form/TableSearch.jsx';
import FmViewDateField from '../../../components/_mui/FmViewDateField.tsx';
import useCommonFetchApi from '../../../components/helpers/useCommonFetchApi.tsx';

const PurchaseOrderViewList = () => {
  const [statusId, setStatusId] = useState(null);
  const {
    rows,
    pageSize,
    pageNumber,
    setSearchKeyword,
    fetchData,
    isLoading,
    tableRecordCounts,
    searchKeyword,
    handlePaginationModelChange,
    handleSortModelChange,
  } = useTableLogic(
    'localpurchaseorders',
    'localPurchaseOrderStatusId',
    statusId
  );
  const Location = useLocation();
  const Navigate = useNavigate();
  const [value, setValue] = React.useState(0);

  //fetch Apis
  const { data: localpurchaseorderstatuses } = useCommonFetchApi(
    'localpurchaseorderstatuses'
  );

  useEffect(() => {
    document.title = `Purchase Order  | Procurement`;
    fetchData();
  }, [statusId]);

  //Edit Purchase Order
  const handleEditPurchaseOrdre = async (props) => {
    Navigate(`edit/${props?.localPurchaseOrderId}`);
  };

  const handleLpoPreview = (props) => {
    Navigate(
      `/procurement/purchase-order-view/preview/${props?.localPurchaseOrderId}`
    );
  };

  const ActionData = [
    {
      name: 'Edit',
      icon: <Icon icon='solar:pen-2-broken' />,
      onClick: (props) => handleEditPurchaseOrdre(props),
    },
    {
      name: 'LPO Preview',
      icon: <Icon icon='solar:eye-outline' />,
      onClick: (props) => handleLpoPreview(props),
    },
  ];

  const columns = [
    {
      field: 'action',
      headerName: '',
      width: 80,
      sortable: false,
      hideable: false,
      disableClickEventBubbling: false,
      renderCell: (params) => {
        return (
          <TableDropDown
            Actions={ActionData?.map((action) => ({
              ...action,
              onClick: () => action.onClick(params.row),
            }))}
            selectedRow={params.row}
            setSelectedRow={() => { }}
          />
        );
      },
    },
    //TODO: for Future Use
    // {
    //   field: 'attachment',
    //   headerName: '',
    //   width: 50,
    //   hideable: false,
    //   renderCell: (params) => {
    //     return (
    //       <>
    //         {params.value && (
    //           <Box className='text-xl'>
    //             <Badge badgeContent={2} color='secondary'>
    //               <Icon icon='ic:outline-attachment' />
    //             </Badge>
    //           </Box>
    //         )}
    //       </>
    //     );
    //   },
    // },

    {
      field: 'localPurchaseOrderNumber',
      headerName: 'PO Number',
      minWidth: 150,
      hideable: false,
      flex: 1,
    },

    {
      field: 'manualLPOCode',
      headerName: 'Manual LPO Code',
      minWidth: 150,
      hideable: false,
      flex: 1,
    },
    {
      field: 'lpoDate',
      headerName: 'PO Date',
      minWidth: 250,
      hideable: false,
      flex: 1,
      renderCell: (params) => {
        return <FmViewDateField value={params.value} />;
      },
    },
    {
      field: 'vendorAccount',
      headerName: 'Vendor Account',
      minWidth: 150,
      flex: 1,
    },
    {
      field: 'exchangeRate',
      headerName: 'Exchange Rate',
      minWidth: 150,
      flex: 1,
    },
    {
      field: 'companyName',
      headerName: 'Company Name',
      minWidth: 150,
      flex: 1,
    },
    {
      field: 'projectName',
      headerName: 'Project Name',
      minWidth: 150,
      flex: 1,
    },
    {
      field: 'contractName',
      headerName: 'Contract Name',
      minWidth: 150,
      flex: 1,
    },
    {
      field: 'requestorName',
      headerName: 'Requestor Name',
      minWidth: 150,
      flex: 1,
    },
    {
      field: 'locationName',
      headerName: 'Location Name',
      minWidth: 150,
      flex: 1,
    },
    {
      field: 'paymentTermName',
      headerName: 'Payment Name',
      minWidth: 150,
      flex: 1,
    },
    {
      field: 'status',
      headerName: 'Status',
      minWidth: 150,
      flex: 1,
      renderCell: (params) => {
        return (
          <StatusBadge
            title={params.value === 2 ? 'Active' : 'Inactive'}
            type={params.value === 2 ? 'green' : 'red'}
          />
        );
      },
    },
    // {
    //   field: 'mrStatus',
    //   headerName: 'MR Status',
    //   minWidth: 200,
    //   flex: 1,
    //   renderCell: (param) => {
    //     return (
    //       <StatusBadge
    //         title={param.value}
    //         type={
    //           param.value === 'Pending'
    //             ? 'red'
    //             : param.value === 'Ready'
    //             ? 'green'
    //             : param.value === 'Review'
    //             ? 'orange'
    //             : param.value === 'Draft'
    //             ? 'blue'
    //             : param.value === 'Close'
    //             ? 'cyan'
    //             : 'black'
    //         }
    //       />
    //     );
    //   },
    // },
  ];

  const SearchFilteredBoxes = () => {
    return (
      <ToolbarBoxesFlex>
        <Box className='w-full' sx={{ maxWidth: '280px' }}>
          <TableSearch
            placeholder='Search'
            fullWidth
            setSearchKeyword={setSearchKeyword}
            searchValue={searchKeyword}
          />
        </Box>

        <DataTableToolbarButtons />
      </ToolbarBoxesFlex>
    );
  };

  const onRowDoubleClick = (e) => {
    if (e?.row) {
      handleEditPurchaseOrdre(e?.row);
    }
  };

  return (
    <DashboardLayout
      title={'Purchase Order'}
      actionButtons={
        <CreateButton
          name='New'
          onClick={() => Navigate(`${Location.pathname}/create`)}
        />
      }
    >
      <Box className='flex flex-col w-full h-full'>
        <GlassCard className='mb-2 w-full' bgLight>
          <Tabs
            value={value}
            onChange={(_event, newValue) => {
              if (newValue === 0) {
                setStatusId(null);
                setSearchKeyword('');
              } else {
                setStatusId(
                  localpurchaseorderstatuses[newValue - 1]
                    ?.localPurchaseOrderStatusId
                );
              }
              setValue(newValue);
              setSearchKeyword('');
            }}
            sx={{
              minHeight: '38px',
              '& .MuiTabs-flexContainer': {
                //  margin: '5px 0px',
              },
              '& .MuiTab-root': {
                minHeight: '38px',
                fontWeight: 500,
                padding: '10px 5px',
                textAlign: 'center',
                justifyContent: 'center',
                opacity: 0.8,
                borderRadius: '0px',
                marginRight: '5px',

                '&:hover': {
                  opacity: 1,
                },
                '&.Mui-selected': {
                  fontWeight: 600,
                  opacity: 1,
                  color: 'text.dark',
                },
              },
            }}
          >
            <Tab label='All' />
            {localpurchaseorderstatuses?.map((item) => {
              return (
                <Tab
                  key={item?.localPurchaseOrderStatusId}
                  label={item?.localPurchaseOrderStatusName}
                  value={item?.localPurchaseOrderStatusId}
                />
              );
            })}
          </Tabs>
        </GlassCard>

        <GlassCard className='overflow-hidden w-full flex-1 min-h-0'>
          <Box className='h-full-css'>
            <DataTable
              rows={rows}
              columns={columns}
              loading={isLoading}
              sortingMode='server'
              paginationMode='server'
              onPaginationModelChange={(model) =>
                handlePaginationModelChange(model)
              }
              onRowDoubleClick={(e) => onRowDoubleClick(e)}
              onSortModelChange={(model) => handleSortModelChange(model)}
              page={pageNumber - 1}
              pageSize={pageSize}
              rowCount={tableRecordCounts?.total}
              slots={{
                toolbar: () => (
                  <Box>
                    <SearchFilteredBoxes />
                  </Box>
                ),
              }}
            />
          </Box>
        </GlassCard>
      </Box>
    </DashboardLayout>
  );
};

export default PurchaseOrderViewList;
