import React, { useEffect, useMemo, useState } from 'react';
import DashboardLayout from '../../../../components/DashboardLayout.tsx';
import { useLocation, useNavigate } from 'react-router-dom';
import { CreateButton } from '../../../../components/small/Buttons.jsx';
import ListSalesInvoicesAR from './ListSalesInvoicesAR.tsx';
import { useSelector } from 'react-redux';
import { findNavByName } from '../../../../utils/navUtils.ts';
import { Box } from '@mui/material';
import FASalesInvoiceGridsApi from '../../../../components/dashboard/Financial/FASalesInvoiceGridsApi.tsx';

const SalesInvoicesAR = () => {
  const navigation = useNavigate();
  const location = useLocation();
  const menu = useSelector((state) => state?.menu?.items);
  const [getStatus, setGetStatus] = useState(0);

  // Flatten menu only once and store it for reuse
  const SubMenu = useMemo(() => {
    return menu?.map((item) => item?.menus?.flat()).flat() || [];
  }, [menu]);

  // Use findNavByName to get the "Accounts Receivable" menu or its child menus
  const result = useMemo(
    () => findNavByName(SubMenu, 'Financial', 'Accounts Receivable'),
    [SubMenu]
  );

  useEffect(() => {
    document.title = `Sales Invoices | Accounts Receivable | Financial`;
  }, []);

  const handleStatusChange = (status: number) => {
    setGetStatus(status);
  };

  return (
    <DashboardLayout
      title='Sales Invoices'
      hasSubmenu
      menu={result}
      actionButtons={
        <CreateButton
          name='New'
          onClick={() => navigation(`${location.pathname}/create`)}
        />
      }
    >
      <Box className='flex flex-col gap-2 h-full'>
        <FASalesInvoiceGridsApi
          handleStatusChange={handleStatusChange}
          layoutType={2}
        />
        <ListSalesInvoicesAR status={getStatus} />
      </Box>
    </DashboardLayout>
  );
};

export default SalesInvoicesAR;
