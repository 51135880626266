import React from 'react';
import { Icon } from '@iconify/react';
import { IconButton, Popover, Box } from '@mui/material';
import { Indicators } from './FormElements';

const MuiPopoverCustom = ({ data, openState, handleTextShow }) => {
  const [anchorEl, setAnchorEl] = React.useState(null);

  const handlePopoverOpen = (event) => {
    setAnchorEl(event.currentTarget);
  };

  const handlePopoverClose = () => {
    setAnchorEl(null);
  };

  const open = Boolean(anchorEl);
  return (
    <>
      <IconButton
        onClick={handleTextShow}
        onMouseEnter={handlePopoverOpen}
        onMouseLeave={handlePopoverClose}
        sx={{ position: 'relative' }}
      >
        {openState ? (
          <Icon icon='ic:outline-info' width='24' height='24' />
        ) : (
          <Icon icon='ic:outline-info' width='24' height='24' />
        )}
      </IconButton>

      <Popover
        id='mouse-over-popover'
        open={open}
        anchorEl={anchorEl}
        anchorOrigin={{
          vertical: 'bottom',
          horizontal: 'left',
        }}
        transformOrigin={{
          vertical: 'top',
          horizontal: 'left',
        }}
        sx={{
          pointerEvents: 'none',
        }}
        onClose={handlePopoverClose}
        disableRestoreFocus
      >
        <Box component='ul' sx={{ p: 1, minWidth: '200px' }}>
          {data?.map((item, i) => {
            return (
              <Box component='li' className='px-2 py-1' key={i}>
                <Indicators
                  small={item.smalltext}
                  title={item.text}
                  open={openState}
                />
              </Box>
            );
          })}
        </Box>
      </Popover>
    </>
  );
};

export default MuiPopoverCustom;
