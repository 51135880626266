import React, { useState,useRef,useEffect} from 'react';
import GlassCard from '../../../../components/small/GlassCard.tsx';
import { toast } from 'react-toastify';
import FmMRTDataTable from './../../../../components/FmMRTDataTable.tsx';
import useTableLogic from '../../../../components/helpers/MRTUseTableLogic.tsx';
import { useSelector } from 'react-redux';
import FmTextField from '../../../../components/_mui/FmTextField.tsx';
import { useForm } from 'react-hook-form';
import { Icon } from '@iconify/react';
import Crud_Service from '../../../../apis/CrudService.jsx';
import { Box, Button } from '@mui/material';
import TableDropDown from '../../../../components/helpers/TableDropDown.tsx';
import { useParams } from 'react-router-dom';
import TableActionButtons from '../../../../components/_form/TableActionButtons.jsx';
const CreatePurchaseItem = (props) => {
  const paramsId = useParams();
  const {
    rows,
    setRows,
    rowCount,
    isLoading,
    pagination,
    sorting,
    columnFilters,
    globalFilter,
    tableRecordCounts,
    setPagination,
    setSorting,
    setColumnFilters,
    setGlobalFilter,
    fetchData,
    setSearchKeyword,
    searchKeyword,
  } = useTableLogic('requestforquoteitems', 'requestForQuoteId', paramsId.id);

  const [editingRowData, setEditingRowData] = useState(null);
  const [editingRowId, setEditingRowId] = useState(null);
  const crud = new Crud_Service();
  const firstColumnInputRef = useRef(null);
  const [editingRowIndex, setEditingRowIndex] = useState(null);
  const {
    handleSubmit,
    control,
    setValue,
    getValues,
    formState: {isValid},
  } = useForm();

  const handleCancel = () => {
    setEditingRowId(null);
    setEditingRowData(null);
  };
  const handleEdit = (row) => {
    setEditingRowId(row?.requestForQuoteItemId);
    setEditingRowData({ ...row });
    Object.keys(row).forEach((key) => {
      setValue(key, row[key]);
    });
  };

  const handleDeleteItems = async (values) => {
    crud.remove(
      'requestforquoteitems',
      values?.requestForQuoteItemId,
      (err, res) => {
        if (res?.status === 204) {
          fetchData();
          toast.success('Item deleted Successfully');
        } else {
        }
      }
    );
  };

  const ActionData = [
    {
      name: 'Delete',
      icon: <Icon icon='solar:trash-bin-minimalistic-broken' />,
      onClick: (props) => handleDeleteItems(props),
      danger: true,
    },
  ];

  const handleEditItem = async (values) => {
    await crud.patch(
      `requestforquoteitems/${values?.requestForQuoteId}?requestForQuoteItemId=${editingRowId}&estimatedPrice=${values?.estimatedPrice}`,
      '',
      '',
      (err, res) => {
        if (res?.status === 200) {
          const updatedRow = res?.data;
          const updatedRows = [...rows];
          updatedRows[editingRowIndex] = {
            ...updatedRow,
            isEditable: true,
            isNew: true,
          };
          setRows(updatedRows);
        } else {
        }
      }
    );
  };
  const previousValues = useRef(getValues());
  const handleBlur = () => {
    const currentValues = getValues();
    // Check if the `estimatedPrice` field has changed
    const hasEstimatedPriceChanged =
      currentValues.estimatedPrice !== previousValues.current.estimatedPrice;
  
    if (hasEstimatedPriceChanged && (isValid || editingRowId)) {
      handleEditItem(currentValues); // Call the API with the new values
      previousValues.current = currentValues; // Update previous values
    }
  };

  useEffect(() => {
    if (editingRowIndex !== null && firstColumnInputRef.current && !isLoading) {
      firstColumnInputRef.current.focus();
    }
  }, [editingRowIndex, isLoading]);

  const columns = [
    {
      accessorKey: 'itemName',
      header: 'Item Name',
      size: 150,
      enableEditing: false,
    },
    {
      accessorKey: 'categoryName',
      header: 'Category Name',
      size: 150,
      enableEditing: false,
    },
    {
      accessorKey: 'brandName',
      header: 'Brand Name',
      size: 150,
      enableEditing: false,
    },
    {
      accessorKey: 'unitOfMeasurementName',
      header: 'Unit of Measurement',
      size: 150,
      enableEditing: false,
    },
    {
      accessorKey: 'costCenterName',
      header: 'Cost Center Name',
      size: 150,
      enableEditing: false,
    },
    {
      accessorKey: 'taxCodeName',
      header: 'Tax Code Name',
      size: 150,
      enableEditing: false,
    },
    {
      accessorKey: 'estimatedPrice',
      header: 'Estimated Price',
      size: 120,
      enableEditing: true,
      Cell: ({ cell }) => {
        return editingRowId === cell?.row?.original?.requestForQuoteItemId ? (
          <FmTextField
            name='estimatedPrice'
            control={control}
            onBlur={handleBlur}
            inputRef={firstColumnInputRef}
            label='Estimated Price'
          />
        ) : (
          cell?.getValue() || ''
        );
      },
    },
    {
      accessorKey: 'description',
      header: 'Description',
      size: 200,
    },
    {
      accessorKey: 'quantity',
      header: 'Quantity',
      size: 100,
    },
    {
      accessorKey: 'remarks',
      header: 'Remarks',
      size: 200,
    },
    {
      header: 'Action',
      enableColumnPinning: false,
      enableEditing: false,
      enableSorting: false,
      Cell: ({ row }) => (
        <Box className='flex gap-2'>
          {editingRowId === row?.original?.requestForQuoteItemId ? (
            <>
              <Button onClick={handleCancel}>Cancel</Button>
              <Button
                    onFocus={() =>{
                      handleEdit(rows[editingRowIndex+1])
                      setEditingRowIndex(editingRowIndex+1)
                    }
                    }
                  ></Button>
            </>
          ) : (
            <TableActionButtons
              Actions={ActionData?.map((action) => ({
                ...action,
                onClick: () => action.onClick(row?.original),
              }))}
              selectedRow={row?.original}
              setSelectedRow={() => {}}
            />
          )}
        </Box>
      ),
    },
  ];

  return (
    <>
      <GlassCard
        className='mt-5'
        style={{ overflowX: 'auto', maxWidth: '100%' }}
      >
        <FmMRTDataTable
          rows={rows}
          columns={columns}
          enableRowSelection={false}
          rowCount={tableRecordCounts}
          editingRow={editingRowId}
          pagination={pagination}
          sorting={sorting}
          columnFilters={columnFilters}
          globalFilter={globalFilter}
          setPagination={setPagination}
          setSorting={setSorting}
          setColumnFilters={setColumnFilters}
          setGlobalFilter={setGlobalFilter}
          isLoading={isLoading}
          setSearchKeyword={setSearchKeyword}
          searchKeyword={searchKeyword}
          handleRowDoubleClick={(row) => {
            handleEdit(row?.original);
            setEditingRowIndex(row?.index);
          }}
        />
      </GlassCard>
    </>
  );
};

export default CreatePurchaseItem;
