import React from 'react';
import { Box } from '@mui/material';

interface GlassCardProps {
  className?: string;
  children?: React.ReactNode;
  bgLight?: boolean;
  bgWhite?: boolean;
  restProps?: any;
  nakedTable?: boolean;
  bgDark?: boolean;
}

const GlassCard = (props: GlassCardProps) => {
  const { children, className, bgLight, bgWhite, nakedTable, bgDark, ...restProps } = props;
  return (
    <Box
      className={className}
      sx={{
        borderRadius: '0px',
        bgcolor: bgLight
          ? 'background.light'
          : bgWhite
          ? 'background.white'
          : nakedTable
          ? 'inherit'
          : bgDark
          ? 'background.sideBoxes'
          : 'background.white',
        border: nakedTable ? 'none' : '1px solid',
        borderColor: 'border.main',
        boxShadow: nakedTable ? 'none' : 'rgba(0, 0, 0, 0.05) 0px 1px 2px 0px',
        overflow: 'hidden',
        color:  'inherit',
      }}
      {...restProps}
    >
      {children}
    </Box>
  );
};

export default GlassCard;
