import React from 'react';
import { MuiTelInput } from 'mui-tel-input';
import { Controller } from 'react-hook-form';
import PropTypes from 'prop-types';

const FmCurrencyInput = ({
  name,
  control,
  defaultCountry = 'AE',
  label,
  fullWidth = true,
}) => {
  return (
    <Controller
      name={name}
      control={control}
      render={({ field, fieldState: { error } }) => (
        <MuiTelInput
          {...field}
          defaultCountry={defaultCountry}
          label={label}
          fullWidth={fullWidth}
          error={!!error}
          helperText={error ? error.message : ''}
          onChange={(value) => field.onChange(value)}
        />
      )}
    />
  );
};

FmCurrencyInput.propTypes = {
  name: PropTypes.string.isRequired,
  control: PropTypes.object.isRequired,
  defaultCountry: PropTypes.string,
  label: PropTypes.string,
  fullWidth: PropTypes.bool,
};

export default FmCurrencyInput;