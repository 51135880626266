import React, { useEffect, useState } from 'react';
import GlassCard from '../../../../components/small/GlassCard.tsx';
import MRTExpandTableStyle from '../../../../components/table/MRTExpandTableStyle.tsx';
import { Grid } from '@mui/material';
import FmSearchableSelect from '../../../../components/_mui/FmSearchableSelect.tsx';
import { useForm } from 'react-hook-form';
import { LocalizationProvider } from '@mui/x-date-pickers';
import dayjs from 'dayjs';
import FmTextField from '../../../../components/_mui/FmTextField.tsx';
import ActionButtons from '../../../../components/_form/ActionButtons.tsx';
import useLoader from '../../../../components/helpers/UseLoader.tsx';
import Crud_Service from '../../../../apis/CrudService.jsx';
import { toast } from 'react-toastify';
import { AdapterDayjs } from '@mui/x-date-pickers/AdapterDayjs';
import useTableLogic from '../../../../components/helpers/MRTUseTableLogic.tsx';
import ContractProjectTeamTable from './ContractProjectTeamTable.tsx';
import { ProjectTeamsSchema } from '../../../../utils/CommonvalidationSchemas.tsx';
import { yupResolver } from '@hookform/resolvers/yup';
import FmDatePicker from '../../../../components/_mui/FmDatePicker.tsx';
import { useParams } from 'react-router-dom';
import GlassCardCollapse from '../../../../components/small/GlassCardCollapse.tsx';
import { ViewField } from '../../../../components/_form/FormElements.jsx';
const gridValue = 3;

const AddFields = (props) => {
  const { fetchData } = props;
  const { id } = useParams();
  const [contractData, setContractData] = useState(null);
  const [resetKey, setResetKey] = useState(0);
  const { handleSubmit, control, watch, reset, setValue } = useForm({
    resolver: yupResolver(ProjectTeamsSchema),
    mode: 'onChange',
    defaultValues: {
      employeeId: '',
      projectTeamRoleId: '',
    },
  });
  const { startCreateLoading, stopCreateLoading, createLoading } = useLoader();
  const crud = new Crud_Service();
  const [emp, setEmp] = React.useState();

  const getProjectTeam = async () => {
    await crud.getSingle('contracts', id, (_err, res) => {
      if (res?.status === 200) {
        setContractData(res?.data);
      }
    });
  };

  useEffect(() => {
    getProjectTeam();
  }, []);

  const handleSubmitProjectTeam = async (data) => {
    startCreateLoading();
    const combinedData = {
      ...data,
      projectId: contractData?.projectId,
      contractId: contractData?.contractId,
      typeId: 1,
      startDate: dayjs(data?.startDate).isValid()
        ? dayjs(data?.startDate).format('YYYY-MM-DD')
        : null,
      endDate: dayjs(data?.endDate).isValid()
        ? dayjs(data?.endDate).format('YYYY-MM-DD')
        : null,
      designationId: emp?.designationId ?? '',
      projectTeamRoleId: data?.projectTeamRoleId,
    };
    await crud.create('projectteams', combinedData, (err, res) => {
      stopCreateLoading();
      if (err) {
        return;
      }
      if (res?.status === 201) {
        toast.success('Project Team Created successfully');
        fetchData();
        reset();
        setResetKey((prev) => prev + 1);
        setEmp(null);
      }
    });
  };

  const handleProjectTeamChange = (ev) => {
    setEmp(ev);
    setValue('designationId', ev?.designationId ?? '', {
      shouldValidate: true,
    });
  };

  return (
    <GlassCardCollapse title='Add Project Team' className='mb-2'>
      <form onSubmit={handleSubmit(handleSubmitProjectTeam)}>
        <LocalizationProvider dateAdapter={AdapterDayjs}>
          <Grid container spacing={2}>
            <Grid item md={gridValue}>
              <FmSearchableSelect
                name='employeeId'
                control={control}
                apiUrl='employees'
                valueField='employeeId'
                headerField={['Code', 'Name', 'Email']}
                labelField={['employeeCode', 'fullName', 'emailId']}
                showField={['employeeCode', 'fullName', 'emailId']}
                label='Employee'
                onChangeProp={(ev) => handleProjectTeamChange(ev)}
                required
                key={resetKey}
              />{' '}
            </Grid>
            <Grid item md={gridValue}>
              <FmSearchableSelect
                name='projectTeamRoleId'
                control={control}
                apiUrl='roles'
                valueField='roleId'
                labelField={['roleCode', 'roleName']}
                showField={['roleCode', 'roleName']}
                label='Project Team Role'
                key={resetKey}
              />{' '}
            </Grid>
            <Grid item md={gridValue}>
              <ViewField label='Designation' title={emp?.designationName} />
            </Grid>
            <Grid item md={gridValue}>
              <FmDatePicker
                name='startDate'
                label='Start Date'
                control={control}
              />
            </Grid>
            <Grid item md={gridValue}>
              <FmDatePicker
                name='endDate'
                label='End Date'
                control={control}
                minDate={watch('startDate')}
              />
            </Grid>
            <Grid item md={gridValue * 2}>
              <FmTextField
                name='remarks'
                multiline
                rows={1}
                control={control}
                label='Remarks'
              />
            </Grid>
            <Grid item md={gridValue}>
              <ActionButtons
                onSubmit={handleSubmit(handleSubmitProjectTeam)}
                onReset={() => {
                  reset();
                }}
                submitLoading={createLoading}
                submitText='Create'
                removeSpacing={true}
              />
            </Grid>
          </Grid>
        </LocalizationProvider>
      </form>
    </GlassCardCollapse>
  );
};

const ViewContractProjectTeam = (props) => {
  const { contractId } = props;
  const {
    rows,
    isLoading,
    pagination,
    sorting,
    columnFilters,
    globalFilter,
    tableRecordCounts,
    setPagination,
    setSorting,
    setColumnFilters,
    setGlobalFilter,
    fetchData,
    setSearchKeyword,
    searchKeyword,
  } = useTableLogic('projectteams', ['contractId'], [contractId]);

  return (
    <>
      <AddFields fetchData={fetchData} />
      <GlassCard>
        <MRTExpandTableStyle>
          <ContractProjectTeamTable
            rows={rows}
            tableRecordCounts={tableRecordCounts}
            pagination={pagination}
            sorting={sorting}
            setPagination={setPagination}
            setSorting={setSorting}
            columnFilters={columnFilters}
            globalFilter={globalFilter}
            setColumnFilters={setColumnFilters}
            setGlobalFilter={setGlobalFilter}
            isLoading={isLoading}
            setSearchKeyword={setSearchKeyword}
            searchKeyword={searchKeyword}
            fetchData={fetchData}
          />
        </MRTExpandTableStyle>
      </GlassCard>
    </>
  );
};

export default ViewContractProjectTeam;
function t(_arg0: string): import('react-toastify').ToastContent<unknown> {
  throw new Error('Function not implemented.');
}
