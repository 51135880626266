import React from 'react';
import { useForm } from 'react-hook-form';
import { Box, Button } from '@mui/material';
import TableActionButtons from '../../../components/_form/TableActionButtons.jsx';
import FmTextField from '../../../components/_mui/FmTextField.tsx';
import FmMRTDataTable from '../../../components/FmMRTDataTable.tsx';
import FmSearchableSelect from '../../../components/_mui/FmSearchableSelect.tsx';
import FmDatePicker from '../../../components/_mui/FmDatePicker.tsx';
import FmViewDateField from '../../../components/_mui/FmViewDateField.tsx';
import { yupResolver } from '@hookform/resolvers/yup';
import { uniformRequsitionReturnSchema } from '../../../utils/CommonvalidationSchemas.tsx';
import { Icon } from '@iconify/react/dist/iconify.js';
import { toast } from 'react-toastify';
import { useNavigate } from 'react-router-dom';

const UniformReturnDataTable = (props) => {
  const {
    rows,
    editingRowId,
    isLoading,
    setEditingRowId,
    setEditingRowData,
    fetchData,
    handleUpdateUniforms,
    pagination,
    sorting,
    columnFilters,
    setColumnFilters,
    globalFilter,
    setGlobalFilter,
    tableRecordCounts,
    setPagination,
    setSorting,
    setSearchKeyword,
    searchKeyword,
    setViewdetails,
    crud,
  } = props;
  const { handleSubmit, control, setValue } = useForm({
    resolver: yupResolver(uniformRequsitionReturnSchema),
    mode: 'onChange',
  });
  const navigate = useNavigate();

  const handleEdit = async (row) => {
    navigate(`edit/${row?.uniformAndAccessoriesRequisitionReturnId}`);
  };

  const handleDelete = async (props) => {
    await crud.remove(
      'uniformandaccessoriesrequisitionreturns',
      props?.uniformAndAccessoriesRequisitionReturnId,
      (err, res) => {
        if (res?.status === 204) {
          toast.success('uniform and Accessories return Deleted Successfully');
          fetchData();
        } else {
        }
      }
    );
  };

  const handleViewClick = async (props) => {
    navigate(`view/${props?.uniformAndAccessoriesRequisitionReturnId}`);
  };

  const handleCancel = () => {
    setEditingRowId(null);
    setEditingRowData(null);
  };

  const ActionData = [
    {
      name: 'View Details',
      icon: <Icon icon='solar:eye-linear' />,
      onClick: (props) => handleViewClick(props),
    },
    {
      name: 'Edit',
      icon: <Icon icon='solar:pen-2-broken' />,
      onClick: (props) => handleEdit(props),
    },
    {
      name: 'Delete',
      icon: <Icon icon='solar:trash-bin-minimalistic-broken' />,
      onClick: (props) => handleDelete(props),
      danger: true,
    },
  ];

  const columns = [
    {
      header: 'Actions',
      Cell: ({ row }) => (
        <Box className='flex gap-2'>
          {editingRowId ===
          row?.original?.uniformAndAccessoriesRequisitionReturnId ? (
            <>
              <Button onClick={handleSubmit(handleUpdateUniforms)}>Save</Button>
              <Button onClick={handleCancel}>Cancel</Button>
            </>
          ) : (
            <TableActionButtons
              Actions={ActionData?.map((action) => ({
                ...action,
                onClick: () => action.onClick(row?.original),
              }))}
            />
          )}
        </Box>
      ),
    },
    {
      accessorKey: 'employeeId',
      header: 'Employees',
      enableEditing: true,

      Cell: ({ cell }) => {
        return editingRowId ===
          cell?.row?.original?.uniformAndAccessoriesRequisitionReturnId ? (
          <FmSearchableSelect
            name='employeeId'
            control={control}
            apiUrl='employees'
            valueField='employeeId'
            labelField={['employeeCode', 'fullName']}
            showField={['employeeCode', 'fullName']}
            label={'Employees'}
            defaultValue={{
              employeeId: cell?.row?.original?.employeeId,
              fullName: cell?.row?.original?.employeeName,
            }}
          />
        ) : (
          cell?.row?.original?.employeeName
        );
      },
    },
    {
      accessorKey: 'uniformAndAccessoriesRequisitionId',
      header: 'Uniform And Accessories Requisition',
      enableEditing: true,
      Cell: ({ cell }) => {
        return editingRowId ===
          cell?.row?.original?.uniformAndAccessoriesRequisitionReturnId ? (
          <FmSearchableSelect
            name='uniformAndAccessoriesRequisitionId'
            control={control}
            apiUrl='uniformandaccessoriesrequisitions'
            valueField='uniformAndAccessoriesRequisitionId'
            headerField={['uniformAndAccessoriesRequisitionCode']}
            labelField={['uniformAndAccessoriesRequisitionCode']}
            showField={['uniformAndAccessoriesRequisitionCode']}
            label={'Uniform And Accessories Requisition'}
            defaultValue={{
              uniformAndAccessoriesRequisitionId:
                cell?.row?.original?.uniformAndAccessoriesRequisitionId,
              uniformAndAccessoriesRequisitionCode:
                cell?.row?.original?.uniformAndAccessoriesRequisitionReturnCode,
            }}
          />
        ) : (
          cell?.row?.original?.uniformAndAccessoriesRequisitionReturnCode
        );
      },
    },

    {
      accessorKey: 'requisitionDate',
      header: 'Requisition Date',
      enableEditing: true,
      Cell: ({ row }) => {
        return editingRowId ===
          row?.original?.uniformAndAccessoriesRequisitionReturnId ? (
          <FmDatePicker
            name='requisitionDate'
            control={control}
            label='Requestion Date'
          />
        ) : (
          FmViewDateField({ value: row?.original?.requisitionDate })
        );
      },
    },

    {
      accessorKey: 'qrCode',
      header: 'QR Code',
      enableEditing: true,
      Cell: ({ row }) => {
        return editingRowId ===
          row?.original?.uniformAndAccessoriesRequisitionReturnId ? (
          <FmTextField name='qrCode' control={control} label='QR Code' />
        ) : (
          row?.original?.qrCode
        );
      },
    },
    {
      accessorKey: 'remarks',
      header: 'Remarks',
      enableEditing: true,
      Cell: ({ row }) => {
        return editingRowId ===
          row?.original?.uniformAndAccessoriesRequisitionReturnId ? (
          <FmTextField
            name='remarks'
            control={control}
            label='Remarks'
            multiline={true}
            rows={2}
            maxRows={3}
          />
        ) : (
          row?.original?.remarks
        );
      },
    },
  ];

  return (
    <FmMRTDataTable
      rows={rows}
      columns={columns}
      enableRowSelection={false}
      rowCount={tableRecordCounts}
      editingRow={editingRowId}
      pagination={pagination}
      sorting={sorting}
      columnFilters={columnFilters}
      globalFilter={globalFilter}
      setPagination={setPagination}
      setSorting={setSorting}
      fetchData={fetchData}
      setColumnFilters={setColumnFilters}
      setGlobalFilter={setGlobalFilter}
      isLoading={isLoading}
      setSearchKeyword={setSearchKeyword}
      searchKeyword={searchKeyword}
      handleRowDoubleClick={(e) => handleEdit(e?.original)}
      muiTableContainerProps={{
        sx: {
          maxHeight: '100%',
          flex: 1,
        },
      }}
    />
  );
};

export default UniformReturnDataTable;
