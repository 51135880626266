import React, { useEffect, useState } from 'react';
import GlassCard from '../../../../../components/small/GlassCard.tsx';
import BackToButton from '../../../../../components/small/BackToButton.jsx';
import { useNavigate, useParams } from 'react-router-dom';

import DashboardLayout from './../../../../../components/DashboardLayout.tsx';
import ViewCashBankReceiptList from './ViewCashBankReceiptList.tsx';
import Crud_Service from '../../../../../apis/CrudService.jsx';

import EditCBR from '../EditCBR.tsx';

const ViewCashBankReceipt = (props) => {
  const { type } = props;
  const navigation = useNavigate();
  const { id } = useParams();
  const crud = new Crud_Service();
  const [viewData, setViewData] = useState({});

  const viewDataFetch = async () => {
    crud.getSingle('financialtransactions', id, (err, res) => {
      if (res?.status === 200) {
        setViewData(res?.data);
      } else {
        setViewData([]);
      }
    });
  };
  useEffect(() => {
    if (id) {
      viewDataFetch();
    }
  }, [id]);

  useEffect(() => {
    document.title = `View | ${
      type === 1 ? ' Receipt' : type === 2 ? ' Payment' : 'Petty Cash'
    }| Cash and Bank | Financial`;
  });

  return (
    <>
      <DashboardLayout
        title={`View ${
          type === 1 ? 'Receipt' : type === 2 ? 'Payment' : 'Petty Cash'
        }`}
        actionButtons={
          <BackToButton
            title='Back to List'
            onClick={() => navigation(-1)}
            className='font-bold py-0 px-1'
          />
        }
        titleReverse
      >
        {/* glasscard */}
        <GlassCard className='p-3 mb-4'>
          {/* <Grid container spacing={3}>
            <Grid item md={4}>
              <ViewTable
                rows={[
                  {
                    label: 'Finacial Transaction Code',
                    title: viewData?.financialTransactionCode || 'N/A',
                  },
                  {
                    label: 'Cheque Date',
                    title: viewData?.chequeDate || 'N/A',
                  },
                  {
                    label: 'Payee User',
                    title: viewData?.payeeUserName || 'N/A',
                  },
                ]}
              />
            </Grid>
            <Grid item md={4}>
              <ViewTable
                rows={[
                  {
                    label: 'Request Date',
                    title: viewData?.requestDate,
                  },
                  {
                    label: 'Currency',
                    title: viewData?.currencyName || 'N/A',
                  },
                  {
                    label: 'PDC No',
                    title: viewData?.pdcNo || 'N/A',
                  },
                ]}
              />
            </Grid>
            <Grid item md={4}>
              <ViewTable
                rows={[
                  {
                    label: 'Bank Name',
                    title: viewData?.bankName || 'N/A',
                  },

                ]}
              />
            </Grid>
          </Grid> */}
          <EditCBR type={type} />
        </GlassCard>
        {/* glasscard */}
        <ViewCashBankReceiptList financialTransactionId={id} />
      </DashboardLayout>
    </>
  );
};

export default ViewCashBankReceipt;
