import { yupResolver } from '@hookform/resolvers/yup';
import { Box, Grid, Button, CircularProgress } from '@mui/material';
import dayjs from 'dayjs';
import React, { useEffect, useState, useCallback, useMemo } from 'react';
import { useForm } from 'react-hook-form';
import { toast } from 'react-toastify';
import ActionButtons from '../../../../components/_form/ActionButtons.tsx';
import FmDatePicker from '../../../../components/_mui/FmDatePicker.tsx';
import FmSearchableSelect from '../../../../components/_mui/FmSearchableSelect.tsx';
import FmTimePicker from '../../../../components/_mui/FmTimePicker.tsx';
import MuiDialogOne from '../../../../components/_mui/MuiDialogOne.jsx';
import { employeeAttendenceSchema } from '../../../../utils/CommonvalidationSchemas.tsx';
import Crud_Service from '../../../../apis/CrudService.jsx';
import CommonLoader from '../../../../components/page/CommonLoader.jsx';
import FmAutoComplete from '../../../../components/_mui/FmAutoComplete.tsx';
import { Icon } from '@iconify/react';
import { ViewTable } from '../../../../components/_form/FormElements.jsx';
import FmStack from './../../../../components/layout/FmStack.tsx';

const GridValue = 12;

interface AttendanceTimePickerProps {
  selectedDayData: string | null;
  handleDialogClose: () => void;
  onSuccess: () => void;
  statusOptions?: any;
}

interface AttendanceFormData {
  employeeId: string;
  date: Date | null;
  startTime: string | null;
  endTime: string | null;
  remarks?: string;
  attendanceStatusId: string;
  salesOrderId?: string;
}

const AttendanceTimePicker = React.memo((props: AttendanceTimePickerProps) => {
  const { selectedDayData, handleDialogClose, onSuccess, statusOptions } =
    props;

  const crud = new Crud_Service();
  const [attendanceData, setAttendanceData] = useState<any>(null);
  const [isLoading, setIsLoading] = useState(true);
  const [selectedSO, setSelectedSO] = useState<any>({});
  const [updateLoader, setUpdateLoader] = useState(false);
  const [isInitializing, setIsInitializing] = useState(true);
  const [deleteLoader, setDeleteLoader] = useState(false);
  const [showDeleteConfirm, setShowDeleteConfirm] = useState(false);
  const [disabled, setDisabled] = useState(false);

  const { control, handleSubmit, reset, setValue, trigger, watch, getValues } =
    useForm<AttendanceFormData>({
      mode: 'onChange',
      resolver: yupResolver(employeeAttendenceSchema),
      defaultValues: {
        employeeId: '',
        date: null,
        startTime: null,
        endTime: null,
        attendanceStatusId: '',
      },
    });

  const fetchAttendanceData = useCallback(
    async (id: string) => {
      try {
        const response = await new Promise((resolve, reject) => {
          crud.getSingle('employeemonthlyattendances', id, (_err, res) => {
            if (_err) reject(_err);
            resolve(res);
          });
        });

        if (response?.status === 200) {
          const data = response.data;
          setAttendanceData(data);
          reset({
            employeeId: data.employeeId,
            date: dayjs(data.attendanceDate).toDate(),
            startTime: data.startTime,
            endTime: data.endTime,
            attendanceStatusId: data.attendanceStatusId,
            salesOrderId: data.salesOrderId,
          });
          setDisabled(
            data.attendanceStatusId === 3 ||
              data.attendanceStatusId === 4 ||
              data.attendanceStatusId === 5 ||
              data.attendanceStatusId === 8
          );
        }
      } catch (error) {
        console.error(error);
      } finally {
        setIsLoading(false);
        setIsInitializing(false);
      }
    },
    [crud, reset]
  );

  useEffect(() => {
    if (selectedDayData) {
      fetchAttendanceData(selectedDayData);
    }
  }, [selectedDayData]);

  useEffect(() => {
    const statusId = getValues('attendanceStatusId');
    if (statusId === 1) {
      trigger('startTime');
      trigger('endTime');
    }
  }, [getValues('attendanceStatusId')]);

  const handleDialogCloseWithReset = useCallback(() => {
    const cleanup = () => {
      reset();
      setSelectedSO({});
      setAttendanceData(null);
      setIsLoading(true);
      setIsInitializing(true);
    };

    cleanup();
    handleDialogClose();
  }, [handleDialogClose, reset]);

  const handleSubmitAttendance = async (values: AttendanceFormData) => {
    try {
      setUpdateLoader(true);
      const formattedData = {
        employeeId: values.employeeId,
        attendanceDate: dayjs(values.date).format('YYYY-MM-DD'),
        startTime:
          values.attendanceStatusId === 3 ||
          values.attendanceStatusId === 4 ||
          values.attendanceStatusId === 5
            ? null
            : dayjs(values.startTime).isValid()
            ? dayjs(values.startTime).format('HH:mm:ss')
            : values.startTime,
        endTime:
          values.attendanceStatusId === 3 ||
          values.attendanceStatusId === 4 ||
          values.attendanceStatusId === 5
            ? null
            : dayjs(values.endTime).isValid()
            ? dayjs(values.endTime).format('HH:mm:ss')
            : values.endTime,
        attendanceStatusId: values.attendanceStatusId,

        salesOrderId: values.salesOrderId,
      };

      crud.update(
        'employeemonthlyattendances',
        attendanceData?.employeeMonthlyAttendanceId,
        formattedData,
        (_err, res) => {
          if (res?.status === 200) {
            toast.success('Attendance Updated Successfully');
            handleDialogClose();
            onSuccess();
          } else {
            toast.error('Failed to update attendance');
          }
          setUpdateLoader(false);
        }
      );
    } catch (error) {
      console.error(error);
    }
  };

  const handleDeleteClick = useCallback(() => {
    setShowDeleteConfirm(true);
  }, []);

  const handleConfirmDelete = useCallback(async () => {
    try {
      setDeleteLoader(true);
      crud.remove(
        'employeemonthlyattendances',
        attendanceData?.employeeMonthlyAttendanceId,
        (_err, res) => {
          if (res?.status === 204) {
            toast.success('Attendance Deleted Successfully');
            handleDialogClose();
            onSuccess();
          } else {
            toast.error('Failed to delete attendance');
          }
          setDeleteLoader(false);
          setShowDeleteConfirm(false);
        }
      );
    } catch (error) {
      setDeleteLoader(false);
      setShowDeleteConfirm(false);
    }
  }, [
    attendanceData?.employeeMonthlyAttendanceId,
    crud,
    handleDialogClose,
    onSuccess,
  ]);

  const handleCancelDelete = useCallback(() => {
    setShowDeleteConfirm(false);
  }, []);

  const formContent = (
    <Box>
      <FmStack spacing={3}>
        <Box className='w-full'>
          <ViewTable
            rows={[
              {
                label: 'Employee Name',
                title: `${attendanceData?.employeeCode} - ${attendanceData?.employeeName}`,
              },
              {
                label: 'Contract Name',
                title: `${
                  selectedSO?.contractName ||
                  attendanceData?.contractName ||
                  '-'
                }`,
              },
              {
                label: 'Project Name',
                title: `${
                  selectedSO?.projectName || attendanceData?.projectName || '-'
                }`,
              },
            ]}
            inverseFontWeight
          />
        </Box>

        <Box>
          <Grid container spacing={2}>
            <Grid item md={GridValue}>
              <FmSearchableSelect
                name='salesOrderId'
                control={control}
                apiUrl='salesorders'
                valueField='salesOrderId'
                onChangeProp={(ev) => {
                  setSelectedSO(ev);
                }}
                defaultValue={{
                  salesOrderName: attendanceData?.salesOrderName,
                  autoSalesOrderCode: attendanceData?.salesOrderCode,
                }}
                headerField={['Code', 'Name']}
                labelField={['autoSalesOrderCode', 'salesOrderName']}
                showField={['autoSalesOrderCode', 'salesOrderName']}
                label='Sales Order'
              />
            </Grid>
            <Grid item md={GridValue}>
              <Box>
                <label className='text-sm text-gray-600'>Date</label>
                <div className='text-sm font-bold'>
                  {dayjs(attendanceData?.attendanceDate).format('DD/MM/YYYY')}
                </div>
              </Box>
            </Grid>
            <Grid item md={12}>
              <FmAutoComplete
                name={'attendanceStatusId'}
                control={control}
                label='Attendance Status'
                options={statusOptions}
                displayField='attendanceName'
                valueKey='employeeAttendanceTransactionDisplayColorId'
                optionFields={['attendanceCode', 'attendanceName']}
                defaultValue={attendanceData?.attendanceStatusId}
                required
                onChange={(e) => {
                  const statusId = e;
                  setValue('startTime', null);
                  setValue('endTime', null);
                  setValue('attendanceStatusId', statusId);
                  setDisabled(
                    statusId === 3 ||
                      statusId === 4 ||
                      statusId === 5 ||
                      statusId === 8
                  );
                  trigger('startTime');
                  trigger('endTime');
                }}
              />
            </Grid>
            <Grid item md={GridValue}>
              {!disabled && (
                <Grid container spacing={2}>
                  <Grid item md={6}>
                    <FmTimePicker
                      control={control}
                      required={watch('attendanceStatusId') === 1}
                      name='startTime'
                      label='Start Time'
                      defaultValue={dayjs(attendanceData?.startTime)}
                      disabled={disabled}
                      onChangeProp={() => {
                        trigger('endTime');
                      }}
                    />
                  </Grid>
                  <Grid item md={6}>
                    <FmTimePicker
                      control={control}
                      required={watch('attendanceStatusId') === 1}
                      defaultValue={dayjs(attendanceData?.endTime)}
                      name='endTime'
                      label='End Time'
                      disabled={disabled}
                    />
                  </Grid>
                </Grid>
              )}
            </Grid>

            <Grid item md={12}>
              <Box
                sx={{
                  display: 'flex',
                  justifyContent: 'space-between',
                  alignItems: 'center',
                  gap: 2,
                  marginTop: 5,
                }}
              >
                <Button
                  variant='outlined'
                  color='error'
                  onClick={handleDeleteClick}
                  disabled={deleteLoader}
                  startIcon={
                    deleteLoader ? (
                      <CircularProgress size={20} />
                    ) : (
                      <Icon icon='lucide:trash-2' />
                    )
                  }
                >
                  Delete Attendance
                </Button>

                <ActionButtons
                  removeSpacing
                  onSubmit={handleSubmit(handleSubmitAttendance)}
                  onReset={reset}
                  submitLoading={updateLoader}
                  submitText={'Update'}
                />
              </Box>
            </Grid>
          </Grid>
        </Box>
      </FmStack>
    </Box>
  );

  const TransitionProps = useMemo(
    () => ({
      onExited: () => {
        reset();
        setSelectedSO({});
      },
    }),
    [reset]
  );

  return (
    <>
      {!isInitializing && (
        <>
          <form onSubmit={handleSubmit(handleSubmitAttendance)}>
            <MuiDialogOne
              open={!!selectedDayData}
              onClose={handleDialogCloseWithReset}
              title={'Update Attendance'}
              keepMounted={false}
              disablePortal={false}
              closeAfterTransition
              width='500px'
              fullWidth
            >
              {isLoading ? <CommonLoader /> : formContent}
            </MuiDialogOne>
          </form>

          <MuiDialogOne
            open={showDeleteConfirm}
            onClose={handleCancelDelete}
            title={'Confirm Delete'}
            keepMounted={false}
            maxWidth='xs'
            fullWidth
          >
            <Box>
              <Box sx={{ mb: 2 }}>
                Are you sure you want to delete this attendance record?
              </Box>
              <Box sx={{ display: 'flex', justifyContent: 'flex-end', gap: 1 }}>
                <Button
                  variant='outlined'
                  onClick={handleCancelDelete}
                  disabled={deleteLoader}
                >
                  Cancel
                </Button>
                <Button
                  variant='contained'
                  color='error'
                  onClick={handleConfirmDelete}
                  disabled={deleteLoader}
                  startIcon={deleteLoader && <CircularProgress size={20} />}
                >
                  Delete
                </Button>
              </Box>
            </Box>
          </MuiDialogOne>
        </>
      )}
    </>
  );
});

export default AttendanceTimePicker;
