import React, { useState } from 'react';
import { FormControlLabel, Grid, Radio, RadioGroup } from '@mui/material';
import { useForm } from 'react-hook-form';
import { yupResolver } from '@hookform/resolvers/yup';
import { toast } from 'react-toastify';
import Crud_Service from '../../../../../apis/CrudService.jsx';
import FmSearchableSelect from '../../../../../components/_mui/FmSearchableSelect.tsx';
import FmTextField from '../../../../../components/_mui/FmTextField.tsx';
import GlassCard from '../../../../../components/small/GlassCard.tsx';
import { PurchaseVoucherItemSchema } from '../../../../../utils/CommonvalidationSchemas.tsx';
import ActionButtons from '../../../../../components/_form/ActionButtons.tsx';
const GridSize = 2.4;

const AddPVItems = (props) => {
  const { fetchData, setLoader, id } = props;
  const crud = new Crud_Service();
  const [taxable, setTaxable] = useState(false);
  const { control, handleSubmit, reset, setValue } = useForm({
    resolver: yupResolver(PurchaseVoucherItemSchema),
    mode: 'onChange',
  });
  const submitPurchaseVoucher = async (values) => {
    const data = {
      ...values,
      purchaseVoucherId: id,
    };
    setLoader(true);
    await crud.create('purchasevoucheritem', data, (_err, res) => {
      if (res?.status === 201) {
        toast.success('Purchase Voucher Item Created Successfully');
        fetchData();
        setLoader(false);
        reset();
      } else {
      }
    });
  };
  const [selectedProject, setSelectedProject] = useState({});
  const [selectedContract, setSelectedContract] = useState({});
  return (
    <GlassCard className='p-3'>
      <form onSubmit={handleSubmit(submitPurchaseVoucher)}>
        <Grid container spacing={2}>
          <Grid item md={GridSize}>
            <FmTextField
              name='purchaseVoucherItemName'
              label='Item Name'
              control={control}
            />
          </Grid>

          <Grid item md={GridSize}>
            <FmSearchableSelect
              name='projectId'
              label='Project'
              control={control}
              apiUrl='projects'
              onChangeProp={setSelectedProject}
              valueField='projectId'
              labelField={['projectName']}
              showField={['projectName']}
            />
          </Grid>
          <Grid item md={GridSize}>
            <FmSearchableSelect
              name='contractId'
              label='Contract'
              control={control}
              apiUrl='contracts'
              queryparam='projectId'
              onChangeProp={setSelectedContract}
              queryparamValue={selectedProject?.projectId}
              valueField='contractId'
              labelField={['contractName', 'contractCode']}
              showField={['contractName', 'contractCode']}
            />
          </Grid>
          <Grid item md={GridSize}>
            <FmSearchableSelect
              name='workOrderId'
              label='Work Order'
              control={control}
              queryparam='contractId'
              queryparamValue={selectedContract?.contractId}
              queryparam2='projectId'
              queryparamValue2={selectedProject?.projectId}
              apiUrl='workorders'
              valueField='workOrderId'
              labelField={['workOrderName', 'workOrderCode']}
              showField={['workOrderName', 'workOrderCode']}
            />
          </Grid>

          <Grid item md={GridSize}>
            <FmSearchableSelect
              name='costCenterId'
              label='Cost Center'
              control={control}
              apiUrl='costcenters'
              valueField='costCenterId'
              labelField={['costCenterName']}
              showField={['costCenterName']}
            />
          </Grid>

          <Grid item md={GridSize}>
            <FmSearchableSelect
              name='taxCodeId'
              label='Tax Code'
              control={control}
              apiUrl='taxcodes'
              valueField='taxCodeId'
              labelField={['taxCodeCode']}
              showField={['taxCodeCode']}
            />
          </Grid>
          <Grid item md={GridSize}>
            <FmTextField
              name='purchaseAccount'
              label='Purchase Account'
              control={control}
            />
          </Grid>
          <Grid item md={GridSize}>
            <FmTextField
              name='quantity'
              label='Quantity'
              control={control}
              type='number'
            />
          </Grid>
          <Grid item md={GridSize}>
            <FmTextField
              name='rate'
              label='Rate'
              control={control}
              type='number'
            />
          </Grid>
          <Grid item md={GridSize}>
            <FmTextField
              name='gross'
              label='Gross'
              control={control}
              type='number'
            />
          </Grid>
          <Grid item md={GridSize}>
            <FmTextField
              name='discount'
              label='Discount'
              control={control}
              type='number'
            />
          </Grid>
          <Grid item md={GridSize}>
            <FmTextField
              name='discountPercentage'
              label='Discount Percentage'
              control={control}
              type='number'
            />
          </Grid>

          <Grid item md={GridSize}>
            <RadioGroup
              sx={{ flexDirection: 'row' }}
              defaultValue={false}
              onChange={(e) => {
                setTaxable(e.target.value === 'true');
                setValue('vat', 0);
                setValue('taxable', e.target.value === 'true');
              }}
            >
              {'Taxable'}
              <FormControlLabel value={true} control={<Radio />} label='Yes' />
              <FormControlLabel value={false} control={<Radio />} label='No' />
            </RadioGroup>
          </Grid>
          {taxable && (
            <Grid item md={GridSize}>
              <FmTextField
                name='vat'
                label='VAT %'
                control={control}
                type='number'
              />
            </Grid>
          )}

          <Grid item md={GridSize}>
            <FmTextField name='make' label='Make' control={control} />
          </Grid>
          <Grid item md={GridSize}>
            <FmTextField name='model' label='Model' control={control} />
          </Grid>
          <Grid item md={GridSize}>
            <FmTextField
              name='technicalSpecs'
              label='Technical Specs'
              control={control}
            />
          </Grid>
          <Grid item md={GridSize}>
            <FmTextField
              name='areaOfUsage'
              label='Area Of Usage'
              control={control}
            />
          </Grid>
          <Grid item md={GridSize * 2}>
            <FmTextField
              name='remarks'
              multiline
              rows={4}
              label='Remarks'
              control={control}
            />
          </Grid>
          <Grid item md={GridSize * 2}>
            <FmTextField
              name='description'
              label='Description'
              multiline
              rows={4}
              control={control}
            />
          </Grid>

          <Grid item md={12}>
            <ActionButtons
              onSubmit={handleSubmit(submitPurchaseVoucher)}
              onReset={reset}
              submitLoading={false}
              cancelLoading={false}
              submitText='Create'
            />
          </Grid>
        </Grid>
      </form>
    </GlassCard>
  );
};

export default AddPVItems;
