import React from 'react';
import { Box } from '@mui/material';
import { Icon } from '@iconify/react/dist/iconify.js';

const getIcons = {
  folder: <Icon icon='mdi:folder-account' width='90' height='90' />,
  search_off: (
    <Icon icon='material-symbols:search-off' width='90' height='90' />
  ),
  reports: <Icon icon='iconoir:reports' width='90' height='90' />,
  bank: <Icon icon='cil:bank' width='100' height='100' />,
};

const NoItemsSelectedState = ({ iconName, caption, subCaption }) => {
  return (
    <Box className='w-full h-full flex items-center justify-center'>
      <Box className='flex flex-col gap-5 justify-center items-center'>
        <div className='opacity-50'>{getIcons[iconName]}</div>
        <Box>
          <p className='text-center font-semibold'>{caption}</p>
          <p className='text-cente opacity-80'>{subCaption}</p>
        </Box>
      </Box>
    </Box>
  );
};

export default NoItemsSelectedState;
