import { Box } from '@mui/material';
import React, { useState } from 'react';
import { useLocation, useNavigate } from 'react-router-dom';
import { toast } from 'react-toastify';
import Crud_Service from '../../../../apis/CrudService.jsx';
import TableActionButtons from '../../../../components/_form/TableActionButtons.jsx';
import FmMRTDataTable from '../../../../components/FmMRTDataTable.tsx';
import useTableLogic from '../../../../components/helpers/MRTUseTableLogic.tsx';
import Icons from '../../../../utils/Icon.jsx';
import GlassCard from '../../../../components/small/GlassCard.tsx';
import StatusNewBadge from '../../../../components/_form/StatusNewBadge.tsx';
import MuiDialogOne from '../../../../components/_mui/MuiDialogOne.jsx';
import InternalSalesInvoiceChecklist from './InternalSalesInvoiceChecklist.tsx';
import {
  SalesOrderName,
  ProjectName,
  ContractName,
  ClientName,
} from '../../../../components/table/MRTCommonColumns.tsx';
import { TextCalendarCellIcons } from '../../../../components/layout/RenderMRTCellIcons.tsx';
import { ExpiryDateDueCellText } from '../../../../utils/Date/ExpiryDateCellColor.tsx';
import CurrencyView from '../../../../components/_mui/FmCurrencyView.tsx';

const ListInternalInvoicesAR = () => {
  const navigation = useNavigate();
  const location = useLocation();
  const [openChecklist, setOpenChecklist] = useState(false);
  const crud = new Crud_Service();

  const {
    rows,
    isLoading,
    pagination,
    sorting,
    columnFilters,
    globalFilter,
    tableRecordCounts,
    setPagination,
    setSorting,
    setColumnFilters,
    setGlobalFilter,
    fetchData,
    setSearchKeyword,
    searchKeyword,
  } = useTableLogic(`internalinvoices`);

  const handleEdit = (id) => {
    navigation(`${location.pathname}/edit/${id}`);
  };
  const handleDelete = async (props) => {
    await crud.remove('internalinvoices', props, (err, res) => {
      if (res?.status === 204) {
        toast.success('Internal Invoice Deleted Successfully');
        fetchData();
      }
    });
  };

  const [selectedSalesOrderId, setSelectedSalesOrderId] = useState(null);
  const [selectedInternalInvoiceId, setSelectedInternalInvoiceId] =
    useState(null);

  const [contractId, setContractId] = useState(null);
  const handleCheckList = (salesOrderId, internalInvoiceId) => {
    setSelectedSalesOrderId(salesOrderId);
    setSelectedInternalInvoiceId(internalInvoiceId);

    crud.getSingle('salesorders', salesOrderId, (_err, res) => {
      if (res) {
        setContractId(res?.data?.contractId);
        setOpenChecklist(true);
      } else {
      }
    });
  };

  const ActionData = [
    {
      name: 'Edit',
      icon: Icons.edit,
      onClick: (props) => {
        handleEdit(props.internalInvoiceId);
      },
    },
    {
      name: 'Delete',
      icon: Icons.delete,
      onClick: (props) => handleDelete(props.internalInvoiceId),
      danger: true,
    },
  ];
  // Define the columns
  const columns = [
    {
      header: 'Actions',
      size: 70,
      maxSize: 70,
      Cell: ({ row }) => (
        <Box className='flex gap-2'>
          {
            <TableActionButtons
              Actions={ActionData?.map((action) => ({
                ...action,
                onClick: () => action.onClick(row?.original),
              }))}
            />
          }
        </Box>
      ),
    },

    {
      accessorKey: 'internalInvoiceCode',
      header: 'Invoice Code',
      maxSize: 120,
    },

    {
      accessorKey: 'invoiceStatusName',
      header: 'Invoice Status',
      size: 140,

      Cell: ({ cell }) => {
        return (
          <StatusNewBadge title={cell?.getValue()} type={cell?.getValue()} />
        );
      },
    },

    {
      accessorKey: 'dueDate',
      header: 'Due Date',
      customDate: true,
      maxSize: 120,
      Cell: ({ cell }) => <TextCalendarCellIcons value={cell?.getValue()} />,
      muiTableBodyCellProps: ({ row }) => ({
        className: ExpiryDateDueCellText(row?.original?.dueDate),
      }),
    },

    {
      accessorKey: 'internalInvoiceDate',
      header: 'Invoice Date',
      customDate: true,
      maxSize: 120,
      Cell: ({ cell }) => <TextCalendarCellIcons value={cell?.getValue()} />,
    },

    ClientName,
    SalesOrderName,
    ProjectName,
    ContractName,
    {
      accessorKey: 'totalInternalInvoiceAmount',
      header: 'Total Amount',
      size: 180,
      Cell: ({ cell }) => {
        return (
          <Box>
            <CurrencyView value={cell.getValue()} />
          </Box>
        );
      },
    },
  ];

  return (
    <>
      <GlassCard className='overflow-hidden h-full-css'>
        <FmMRTDataTable
          columns={columns}
          rows={rows}
          rowCount={tableRecordCounts}
          pagination={pagination}
          sorting={sorting}
          columnFilters={columnFilters}
          globalFilter={globalFilter}
          setPagination={setPagination}
          setSorting={setSorting}
          setColumnFilters={setColumnFilters}
          setGlobalFilter={setGlobalFilter}
          isLoading={isLoading}
          rightPinning={['totalInternalInvoiceAmount']}
          setSearchKeyword={setSearchKeyword}
          searchKeyword={searchKeyword}
          fetchData={fetchData}
          handleRowDoubleClick={(ev) =>
            handleEdit(ev?.original?.internalInvoiceId)
          }
          muiTableContainerProps={{ sx: { maxHeight: '100%', flex: 1 } }}
          showSerialNumber={true}
        />{' '}
      </GlassCard>

      {/* checklist modal */}
      <MuiDialogOne
        title='Check List - Internal Invoices'
        open={openChecklist}
        lg={false}
        width='50%'
        onClose={() => setOpenChecklist(!openChecklist)}
      >
        <InternalSalesInvoiceChecklist
          contractItemId={contractId}
          salesOrderId={selectedSalesOrderId}
          internalInvoiceId={selectedInternalInvoiceId}
          setOpenChecklist={setOpenChecklist}
          openChecklist={openChecklist}
        />
      </MuiDialogOne>
      {/* check list modal */}
    </>
  );
};

export default ListInternalInvoicesAR;
