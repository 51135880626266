import React, { useEffect, useState } from 'react';
import GlassCard from '../../../components/small/GlassCard.tsx';
import { StyledCard } from '../../../components/_form';
import { Box, Button } from '@mui/material';
import Crud_Service from '../../../apis/CrudService';
import { Icon } from '@iconify/react/dist/iconify.js';
import StaticTable from './../../../components/_form/StaticTable';
import { StatusBadge } from '../../../components/_form/FormElements';
import apiInstance from '../../../apis/ApiService';
import { toast } from 'react-toastify';
import useTableLogic from '../../../components/helpers/MRTUseTableLogic.tsx';
import DataGridHeaderTooltip from '../../../components/datagrid/DataGridHeaderTooltip.tsx';
import IconWithTooltip from '../../../components/elements/IconWithTooltip.tsx';
import ViewTwoline from '../../../components/view/ViewTwoline.tsx';
import FmViewDateField from '../../../components/_mui/FmViewDateField.tsx';
import FmDateTableFilter from '../../../components/filter/FmDateTableFilter.tsx';
import FmMRTDataTable from '../../../components/FmMRTDataTable.tsx';
import dayjs, { Dayjs } from 'dayjs';
import TableAvatar from '../../../components/_form/TableAvatar.jsx';

const Accruals = (props) => {
  const crud = new Crud_Service();
  const [selectedDate, setSelectedDate] = useState<Dayjs>(dayjs());
  const [leaveAirTicketAccruals, setLeaveAirTicketAccruals] = useState(null);
  const [gratuityAccruals, setGratuityAccruals] = useState(null);
  const { id, i, value } = props;

  const {
    rows,
    rowCount,
    isLoading,
    pagination,
    sorting,
    columnFilters,
    globalFilter,
    tableRecordCounts,
    setPagination,
    setSorting,
    setColumnFilters,
    setGlobalFilter,
    fetchData,
    setSearchKeyword,
    searchKeyword,
  } = useTableLogic(
    `employeeaccrualsleaves/employee/${id}`,
    ['sortBy', 'sortDirection', 'fromDate'],
    ['startDate', 'desc', selectedDate?.format('YYYY-MM-DD')]
  );

  const {
    rows:rowsGratuity,
    rowCount:rowCountGratuity,
    isLoading:isLoadingGratuity,
    pagination:paginationGratuity,
    sorting:sortingGratuity,
    columnFilters:columnFiltersGratuity,
    globalFilter:globalFilterGratuity,
    tableRecordCounts:tableRecordCountsGratuity,
    setPagination:setPaginationGratuity,
    setSorting:setSortingGratuity,
    setColumnFilters:setColumnFiltersGratuity,
    setGlobalFilter:setGlobalFilterGratuity,
    fetchData:fetchDataGratuity,
    setSearchKeyword:setSearchKeywordGratuity,
    searchKeyword:searchKeywordGratuity,
  } = useTableLogic(
    `employeeaccrualsgratuities/employee/${id}`,
    ['sortBy', 'sortDirection'],
    ['startDate', 'desc']
  );

  const handlePrevMonth = () => {
    setSelectedDate((prevDate) => prevDate.subtract(1, 'month'));
  };

  const handleNextMonth = () => {
    setSelectedDate((prevDate) => prevDate.add(1, 'month'));
  };

  const handleDateOnChange = (e) => {
    setSelectedDate(e);
  };
  const columns = [
    {
      accessorKey: 'joiningOrReturnFromLeaveDate',
      header: (
        <DataGridHeaderTooltip
          name='Joining or RFL'
          title='Joining Or Return From Leave'
        />
      ),
      customDate: true,
      size: 120,
      Cell: ({ renderedCellValue, row }) => (
        <Box>
          <Box className='flex gap-2 items-center'>
            <IconWithTooltip
              icon={
                row?.original?.joiningOrReturnFromLeaveDateType === 1 ? (
                  <Icon
                    icon='fluent:people-add-32-light'
                    width='24'
                    height='24'
                  />
                ) : row?.original?.joiningOrReturnFromLeaveDateType === 2 ? (
                  <Icon
                    icon='proicons:airplane-landing'
                    width='24'
                    height='24'
                  />
                ) : null
              }
              title={row?.original?.joiningOrReturnFromLeaveDateTypeName}
            />
            <ViewTwoline
              data={[
                {
                  name: false,
                  value: <FmViewDateField value={renderedCellValue} />,
                },
              ]}
            />
          </Box>
        </Box>
      ),
    },

    {
      accessorKey: 'startDate',
      header: 'Date',
      customDate: true,
      size: 180,
      Cell: ({ row }) => (
        <ViewTwoline
          data={[
            {
              name: 'Start :',
              value: <FmViewDateField value={row.original['startDate']} />,
              className: 'text-green-600',
            },
            {
              name: 'End :',
              value: <FmViewDateField value={row.original['endDate']} />,
              className: ' text-red-600',
            },
          ]}
        />
      ),
    },

    {
      accessorKey: 'airTicketPerDay',
      header: 'Air Ticket ',
      size: 140,
      Cell: ({ renderedCellValue, row }) => (
        <ViewTwoline
          data={[
            {
              name: 'Per Day :',
              value: renderedCellValue,
              className: ' w-full',
            },
            {
              name: 'Total :',
              value: row?.original?.airTicketTotal,
              className: ' w-full  font-semibold',
            },
          ]}
          rounded
        />
      ),
      muiTableBodyCellProps: {
        className: 'bg-yellow-50',
      },
    },

    {
      accessorKey: 'leavePerDay',
      header: 'Leave',
      size: 120,
      muiTableBodyCellProps: {
        className: 'bg-sky-50',
      },
      Cell: ({ renderedCellValue, row }) => (
        <ViewTwoline
          data={[
            {
              name: 'Per Day :',
              value: renderedCellValue,
            },
            {
              name: 'Total :',
              value: row?.original?.leaveTotal,
              className: 'font-semibold',
            },
          ]}
          rounded
        />
      ),
    },

    {
      accessorKey: 'lastGenerationDate',
      header: 'Last Generation',
      customDate: true,
      size: 160,
      Cell: ({ renderedCellValue, row }) => (
        <ViewTwoline
          data={[
            {
              name: false,
              value: renderedCellValue,
              className: 'font-semibold',
            },
            {
              name: false,
              value: `Days - ${row?.original?.lastGenerationDays}`,
            },
          ]}
        />
      ),
    },

    {
      accessorKey: 'runningStatusName',
      header: 'Running Status',
      size: 150,
      Cell: ({ renderedCellValue }) => (
        <StatusBadge title={renderedCellValue} type={'green'} />
      ),
    },
  ];

  const columnsGratuity = [
    {
      accessorKey: 'joiningDate',
      header: 'Joining Date',
      customDate: true,
      Cell: ({ renderedCellValue }) => (
        <ViewTwoline
          data={[
            {
              name: false,
              value: <FmViewDateField value={renderedCellValue} />,
            },
          ]}
        />
      ),
    },

    {
      accessorKey: 'startDate',
      header: 'Date',
      customDate: true,
      size: 180,
      Cell: ({ row }) => (
        <ViewTwoline
          data={[
            {
              name: 'Start :',
              value: <FmViewDateField value={row.original['startDate']} />,
              className: 'text-green-600',
            },
            {
              name: 'End :',
              value: <FmViewDateField value={row.original['endDate']} />,
              className: ' text-red-600',
            },
          ]}
        />
      ),
    },

    {
      accessorKey: 'gratuityPerDay',
      header: 'Gratuity',
      size: 140,
      Cell: ({ renderedCellValue, row }) => (
        <ViewTwoline
          data={[
            {
              name: 'Per Day :',
              value: renderedCellValue,
              className: ' w-full',
            },
            {
              name: 'Total :',
              value: row?.original?.gratuityTotal,
              className: ' w-full  font-500',
            },
          ]}
          rounded
        />
      ),
      muiTableBodyCellProps: {
        className: 'bg-yellow-50',
      },
    },

    {
      accessorKey: 'lastGenerationDate',
      header: 'Last Generation',
      size: 140,
      customDate: true,
      Cell: ({ renderedCellValue, row }) => (
        <ViewTwoline
          data={[
            {
              name: 'Per Day :',
              value: (
                <FmViewDateField value={row.original['lastGenerationDate']} />
              ),
              className: ' w-full',
            },
            {
              name: 'Days :',
              value: row?.original?.lastGenerationDays,
              className: ' w-full ',
            },
          ]}
          rounded
        />
      ),
      muiTableBodyCellProps: {
        className: 'bg-sky-50',
      },
    },

    {
      accessorKey: 'runningStatusName',
      header: 'Running Status',
      size: 150,
      Cell: ({ renderedCellValue }) => (
        <StatusBadge title={renderedCellValue} type={'green'} />
      ),
    },
  ];

  useEffect(() => {
    crud.getSingle(`employeeaccrualsleaves/employee/${id}`, '', (err, res) => {
      if (err) {
        toast.error(err);
      } else {
        const leaves = res?.data?.data?.map((item) => ({
          ...item,
          attestationStatus: (
            <StatusBadge
              title={item?.attestationStatus === 1 ? 'Yes' : 'No'}
              type='green'
            />
          ),
          attachment: item?.fileId ? (
            <Button
              startIcon={<Icon icon='basil:attach-outline' />}
              onClick={() => handleDownload(item?.fileId, item?.fileName)}
            >
              Download
            </Button>
          ) : (
            '-'
          ),
        }));
        setLeaveAirTicketAccruals(leaves);
      }
    });
    crud.getSingle(`employeeaccrualsgratuities/employee/${id}`, '', (err, res) => {
      if (err) {
        toast.error(err);
      } else {
        const gratuityAccruals = res?.data?.data?.map((item) => ({
          ...item,
          attestationStatus: (
            <StatusBadge
              title={item?.attestationStatus === 1 ? 'Yes' : 'No'}
              type='green'
            />
          ),
          attachment: item?.fileId ? (
            <Button
              startIcon={<Icon icon='basil:attach-outline' />}
              onClick={() => handleDownload(item?.fileId, item?.fileName)}
            >
              Download
            </Button>
          ) : (
            '-'
          ),
        }));
        setGratuityAccruals(gratuityAccruals);
      }
    });
  }, [id]);

  const handleDownload = async (params, fileName) => {
    try {
      const imageUrl = await apiInstance.getFiles(`files/download/${params}`);
      const link = document.createElement('a');
      link.href = imageUrl;
      link.setAttribute('download', `${fileName}`);
      document.body.appendChild(link);
      link.click();
      document.body.removeChild(link);
    } catch (err) {
      toast.error('Error fetching image:', err);
    }
  };

  return (
    <>
        <GlassCard className={value === i ? 'w-full p-5' : 'hidden'}>
          <StyledCard title={'Leave & Air Ticket Accruals'}></StyledCard>

          <Box className='mt-5'>
            <FmMRTDataTable
              columns={columns}
              enableRowSelection={false}
              rows={rows}
              rowCount={tableRecordCounts}
              pagination={pagination}
              sorting={sorting}
              columnFilters={columnFilters}
              globalFilter={globalFilter}
              setPagination={setPagination}
              setSorting={setSorting}
              fetchData={fetchData}
              muiTableContainerProps={{
                sx: {
                  maxHeight: '100%',
                  flex: 1,
                },
              }}
              setColumnFilters={setColumnFilters}
              setGlobalFilter={setGlobalFilter}
              isLoading={isLoading}
              setSearchKeyword={setSearchKeyword}
              searchKeyword={searchKeyword}
              renderTopToolbarCustomActionsChildrens={
                <FmDateTableFilter
                  setSelectedDate={setSelectedDate}
                  onHandleNext={handleNextMonth}
                  onHandlePrev={handlePrevMonth}
                  onChange={(e) => handleDateOnChange(e)}
                  value={selectedDate}
                />
              }
              // showSerialNumber={true}
            />
          </Box>
        </GlassCard>
        <div className='my-4'></div>
        <GlassCard className={value === i ? 'w-full p-5' : 'hidden'}>
        <StyledCard title={'Gratuity Accruals'}></StyledCard>

        <Box className='mt-5'>
        <FmMRTDataTable
              columns={columnsGratuity}
              enableRowSelection={false}
              rows={rowsGratuity}
              rowCount={tableRecordCountsGratuity}
              pagination={paginationGratuity}
              sorting={sortingGratuity}
              columnFilters={columnFiltersGratuity}
              globalFilter={globalFilterGratuity}
              setPagination={setPaginationGratuity}
              setSorting={setSortingGratuity}
              fetchData={fetchDataGratuity}
              muiTableContainerProps={{
                sx: {
                  maxHeight: '100%',
                  flex: 1,
                },
              }}
              setColumnFilters={setColumnFilters}
              setGlobalFilter={setGlobalFilter}
              isLoading={isLoading}
              setSearchKeyword={setSearchKeyword}
              searchKeyword={searchKeyword}
              renderTopToolbarCustomActionsChildrens={
                <FmDateTableFilter
                  setSelectedDate={setSelectedDate}
                  onHandleNext={handleNextMonth}
                  onHandlePrev={handlePrevMonth}
                  onChange={(e) => handleDateOnChange(e)}
                  value={selectedDate}
                />
              }
            />
        </Box>
      </GlassCard>
    </>
  );
};

export default Accruals;
