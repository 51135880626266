import React, { useEffect, useRef, useState } from 'react';
import Crud_Service from '../../apis/CrudService.jsx';
import { toast } from 'react-toastify';
import { Button, Grid, Typography } from '@mui/material';
import FmTextField from '../_mui/FmTextField.tsx';
import { useForm } from 'react-hook-form';
import FmAutoComplete from '../_mui/FmAutoComplete.tsx';

declare const Stimulsoft: any;
const ReportDesigner = (props) => {
  const { reportTemplate } = props;
  const designerRef = useRef<any>(null);
  const [isStimulsoftLoaded, setIsStimulsoftLoaded] = useState(false);
  const crud = new Crud_Service();
  const { control, handleSubmit, setValue } = useForm();
  const [types, settypes] = useState([]);

  useEffect(() => {
    setValue('reportName', reportTemplate?.reportName);
    setValue('reportTypeId', reportTemplate?.reportTypeId);
  }, [reportTemplate]);

  useEffect(() => {
    const checkStimulsoftLoaded = () => {
      if (typeof Stimulsoft !== 'undefined') {
        setIsStimulsoftLoaded(true);
      } else {
        setTimeout(checkStimulsoftLoaded, 100);
      }
    };
    checkStimulsoftLoaded();
    crud.getAll('reportTypes', '', (err, res) => {
      settypes(res?.data?.data);
    });
  }, []);

  useEffect(() => {
    if (!isStimulsoftLoaded || !reportTemplate) return;

    try {
      const options = new Stimulsoft.Designer.StiDesignerOptions();
      options.appearance.fullScreenMode = false;
      const designer = new Stimulsoft.Designer.StiDesigner(
        options,
        'StiDesigner',
        false
      );

      designer.onSaveReport = function (args) {
        args.preventDefault = true;
        var jsonReport = args.report.saveToJsonString();
        crud.update(
          'stimulsoftreports',
          reportTemplate?.stimulsoftReportId,
          {
            reportName: reportTemplate?.reportName,
            reportTemplate: jsonReport,
            clientId: reportTemplate.clientId,
            companyId: reportTemplate.companyId,
            reportTypeId: reportTemplate?.reportTypeId,
            status: 2,
          },
          () => {
            toast('Report Saved');
          }
        );
      };

      designer.report = new Stimulsoft.Report.StiReport();
      designer.report.dictionary.visible = true;
      designer.report.dictionary.synchronize();

      if (reportTemplate) {
        designer.report.load(reportTemplate?.reportTemplate);
      }

      if (designer.report.dataSources.dictionary.databases.list.length > 0) {
        designer.report.dataSources.dictionary.databases.list[0].connectionString =
          designer.report.dataSources.dictionary.databases.list[0].connectionString.replace(
            /salesInvoiceId\s*:\s*\d+/,
            `salesInvoiceId:${116}`
          );
      }

      options.appearance.theme =
        Stimulsoft.Designer.StiDesignerTheme.Office2022WhiteTeal;
      designer.renderHtml('designer');
      designerRef.current = designer;
    } catch (error) {
      console.error('Error initializing Stimulsoft Designer:', error);
    }

    return () => {
      if (designerRef.current) {
        designerRef.current = null;
      }
    };
  }, [isStimulsoftLoaded, reportTemplate]);

  if (!isStimulsoftLoaded) {
    return <div>Loading Stimulsoft Designer...</div>;
  }
  const onSubmit = (data) => {
    crud.update(
      'stimulsoftreports',
      reportTemplate?.stimulsoftReportId,
      {
        ...reportTemplate,
        reportName: data.reportName,
        reportTypeId: data.reportTypeId,
      },
      () => {
        toast('Report Saved');
      }
    );
  };
  return (
    <>
      <Grid item md={12}>
        <form onSubmit={handleSubmit(onSubmit)}>
          <Grid container spacing={3} alignItems='center'>
            <Grid item>
              <FmTextField control={control} name='reportName' label='Name' />
            </Grid>
            <Grid item md={3}>
              <FmAutoComplete
                control={control}
                options={types}
                name='reportTypeId'
                label='Type'
                optionFields={['reportTypeName']}
                displayField='reportTypeName'
                valueKey='reportTypeId'
              />
            </Grid>
            <Grid item>
              <FmTextField
                control={control}
                name='clientName'
                label='Client Name'
              />
            </Grid>
            <Grid item>
              <FmTextField
                control={control}
                name='companyName'
                label='Company Name'
              />
            </Grid>
            <Grid item>
              <Button
                className='btn btn-primary'
                type='submit'
                variant='contained'
              >
                Submit
              </Button>
            </Grid>
          </Grid>
        </form>
      </Grid>
      <div id='designer' style={{ width: '100%', height: '100%' }} />
    </>
  );
};

export default ReportDesigner;
