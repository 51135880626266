import React from 'react';
import { useTranslation } from 'react-i18next';
import { Link, useLocation } from 'react-router-dom';
import { Icon } from '@iconify/react';

const Breadcums = () => {
  const location = useLocation();
  const pathnames = location.pathname
    .split('/')
    .filter((x) => x && !isNaN(x) === false)
    .map((x) => (x === 'pr' ? 'PR' : x));
  const { t } = useTranslation();

  return (
    <nav
      className='flex'
      aria-label='Breadcrumb'
      style={{ color: 'text.main' }}
    >
      <ol className='inline-flex items-center space-x-1 md:space-x-2 rtl:space-x-reverse'>
        {pathnames.map((name, index) => {
          const routeTo = `/${pathnames.slice(0, index + 1).join('/')}`;
          const isLast = index === pathnames.length - 1;
          const capitalized = name.charAt(0).toUpperCase() + name.slice(1);
          return isLast ? (
            <li aria-current='page' key={name}>
              <div className='flex items-center'>
                <span className='me-1 capitalize  opacity-65'>
                  {capitalized}
                </span>
              </div>
            </li>
          ) : (
            <li key={name}>
              <div className='flex items-center'>
                <Link to={routeTo} className='me-1 capitalize  '>
                  {capitalized}
                </Link>
                <span className='mx-1'>
                  <Icon icon='formkit:right' />
                </span>
              </div>
            </li>
          );
        })}
      </ol>
    </nav>
  );
};

export default Breadcums;
