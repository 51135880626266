import React, { useEffect, useMemo, useState } from 'react';
import DashboardLayout from '../../../components/DashboardLayout.tsx';
import PageHeader from '../../../components/PageHeader';
import DataTable from '../../../components/DataTable.tsx';
import GlassCard from '../../../components/small/GlassCard.tsx';
import TableActionButtons from '../../../components/_form/TableActionButtons';
import { ViewField, StatusBadge } from '../../../components/_form/FormElements';
import { Box, Grid } from '@mui/material';
import { CreateButton } from '../../../components/small/Buttons';
import TableSearch from '../../../components/_form/TableSearch.jsx';
import MuiDialogOne from '../../../components/_mui/MuiDialogOne';
import { Nav } from '../../../utils';
import Crud_Service from '../../../apis/CrudService';
import { useForm, SubmitHandler } from 'react-hook-form';
import { useTranslation } from 'react-i18next';
import CommonLoader from '../../../components/page/CommonLoader';
import useLoader from '../../../components/helpers/UseLoader.tsx';
import ActionButtons from '../../../components/_form/ActionButtons.tsx';
import useTableLogic from '../../../components/helpers/UseTableLogic.tsx';
import FmTextField from '../../../components/_mui/FmTextField.tsx';
import FmAutoComplete from '../../../components/_mui/FmAutoComplete.tsx';
import { MasterDatatableHeight } from '../../../components/helpers/Containers.jsx';
import { statusOptions } from '../../../utils/CommonVariables.tsx';
import { ZoneProps } from '../../../interfaces/PreSalesInterfaces.ts';
import { toast } from 'react-toastify';
import { yupResolver } from '@hookform/resolvers/yup';
import * as Yup from 'yup';
import Icons from '../../../utils/Icon.jsx';
import { useSelector } from 'react-redux';
import { findNavByName } from '../../../utils/navUtils.ts';
import FmSearchableSelect from '../../../components/_mui/FmSearchableSelect.tsx';

const ZoneMaster = (props) => {
    const { type } = props;
    const [openCreate, setOpenCreate] = React.useState(false);
    const [openView, setOpenView] = React.useState(false);
    const [editModal, setEditModal] = useState(false);
    const [editData, setEditData] = useState(null);
    const crud = new Crud_Service();
    const { t } = useTranslation();
    const [viewData, setViewData] = useState();
    const [editingRowData, setEditingRowData] = useState(null);

    const [loading, setLoader] = useState(false);
    const ZoneSchema = Yup.object().shape({
        zoneName: Yup.string().required('Zone Name is required'),
        regionId: Yup.number().required('Region is required'),
    });
    const { handleSubmit, setValue, control, reset } = useForm<ZoneProps>({
        resolver: yupResolver(ZoneSchema),
        mode: 'onChange',
    });
    const {
        createLoading,
        startCreateLoading,
        stopCreateLoading,
        editLoading,
        startEditLoading,
        stopEditLoading,
    } = useLoader();
    const {
        rows,
        pageSize,
        pageNumber,
        setSearchKeyword,
        isLoading,
        tableRecordCounts,
        searchKeyword,
        handlePaginationModelChange,
        handleSortModelChange,
        fetchData,
    } = useTableLogic('zones');
    const menu = useSelector((state) => state?.menu?.items);

    const SubMenu = useMemo(() => {
        return menu?.map(item => item?.menus?.flat()).flat() || [];
    }, [menu]);

    const resultCafm = useMemo(() => findNavByName(SubMenu, "CAFM", "All master"), [SubMenu]);

    useEffect(() => {
        document.title = `CAFM - Zones`;
    }, []);

    const handleCreateClickOpen = () => {
        reset();
        setOpenCreate(true);
    };
    const handleCreateClose = () => {
        reset();
        setOpenCreate(false);
    };
    const handleSubmitZones: SubmitHandler<ZoneProps> = async (values) => {
        startCreateLoading();
        await crud.create('zones', values, (err, res) => {
            if (res?.status === 201) {
                setOpenCreate(false);
                stopCreateLoading();
                toast.success('Zone Created Successfully');
                reset();
                fetchData();
            } else {
                stopCreateLoading();
                setOpenCreate(true);
            }
        });
    };

    const handleViewClickOpen = async (view) => {
        setOpenView(true);
        setLoader(true);
        await crud.getSingle('zones', view?.zoneId, (err, res) => {
            if (res?.status === 200) {
                setViewData(res?.data);
                setLoader(false);
            } else {
            }
        });
    };

    const handleEditClick = async (view) => {
        setEditModal(true);
        setLoader(true);
        await crud.getSingle('zones', view?.zoneId, (err, res) => {
            if (res?.status === 200) {
                setEditingRowData(res.data);
                Object.entries({
                    ...res?.data,
                }).forEach(([key, value]) => {
                    setValue(key, value);
                });
                setEditData(res?.data);
                setLoader(false);
            } else {
                setLoader(false);
            }
        });
    };

    const handleEditZones = async (values) => {
        startEditLoading();
        await crud.update('zones', editData?.zoneId, values, (err, res) => {
            if (res?.status === 200) {
                setEditModal(false);
                stopEditLoading();
                toast.success('Zone Updated Successfully');
                fetchData();
            } else {
                stopEditLoading();
                setEditModal(true);
            }
        });
    };

    const handleDeleteClick = async (view) => {
        await crud.remove('zones', view?.zoneId, (err, res) => {
            if (res?.status === 204) {
                fetchData();
                toast.success('Zone Deleted Successfully');
            } else {
            }
        });
    };

    const handleViewClose = () => {
        setOpenView(false);
    };

    const counters = [
        {
            name: 'All',
            count: tableRecordCounts?.total || 0,
        },
        {
            name: 'Active',
            count: tableRecordCounts?.active || 0,
        },
        {
            name: 'Deactivated',
            count: tableRecordCounts?.inActive || 0,
        },
    ];

    const ActionData = [
        {
            name: t('view'),
            icon: Icons.view,
            onClick: (props) => handleViewClickOpen(props),
        },
        {
            name: t('edit'),
            icon: Icons.edit,
            onClick: (props) => handleEditClick(props),
        },
        {
            name: t('delete'),
            icon: Icons.delete,
            onClick: (props) => handleDeleteClick(props),
            danger: true,
        },
    ];

    const columns = [
        {
            field: 'action',
            headerName: '',
            width: 140,
            sortable: false,
            disableClickEventBubbling: false,

            renderCell: (params) => {
                return (
                    <TableActionButtons
                        Actions={ActionData?.map((action) => ({
                            ...action,
                            onClick: () => action.onClick(params.row),
                        }))}
                    />
                );
            },
        },
        {
            field: 'zoneCode',
            headerName: 'Zone Code',
            flex: 1,
        },
        {
            field: 'zoneName',
            headerName: 'Zone Name',
            flex: 1,
        },
        {
            field: 'regionCode',
            headerName: 'Region Code',
            flex: 1,

        },
        {
            field: 'regionName',
            headerName: 'Region Name',
            flex: 1,

        },
        {
            field: 'status',
            headerName: t('status'),
            flex: 1,
            renderCell: (params) => {
                return (
                    <StatusBadge
                        title={params.value === 2 ? 'Active' : 'Inactive'}
                        type={params.value === 2 ? 'green' : 'red'}
                    />
                );
            },
        },
    ];

    return (
        <DashboardLayout
            title='Zones'
            actionButtons={
                <>
                    <PageHeader counters={counters} />
                </>
            }
            hasSubmenu
            menu={resultCafm}
        >
            <GlassCard className='h-full-css'>
                <MasterDatatableHeight>
                    <DataTable
                        rows={rows}
                        columns={columns}
                        loading={isLoading}
                        getRowClassName={(params) =>
                            `${params.row.status === 0 && 'bg-red-50'}`
                        }
                        sortingMode='server'
                        paginationMode='server'
                        onPaginationModelChange={(model) =>
                            handlePaginationModelChange(model)
                        }
                        onSortModelChange={(model) => handleSortModelChange(model)}
                        page={pageNumber - 1}
                        pageSize={pageSize}
                        rowCount={tableRecordCounts?.total}
                        fetchData={fetchData}
                        slots={{
                            toolbar: () => (
                                <Box
                                    className='p-2 w-full flex justify-between items-center'
                                    sx={{
                                        borderBottom: '1px solid',
                                        borderColor: 'border.main',
                                    }}
                                >
                                    <Box sx={{ maxWidth: '250px' }}>
                                        <TableSearch
                                            placeholder='Search'
                                            fullWidth
                                            setSearchKeyword={setSearchKeyword}
                                            searchValue={searchKeyword}
                                        />
                                    </Box>
                                    <CreateButton name='New' onClick={handleCreateClickOpen} />
                                </Box>
                            ),
                        }}
                    />
                </MasterDatatableHeight>
            </GlassCard>

            <MuiDialogOne
                title={t('create')}
                open={openCreate}
                onClose={handleCreateClose}
            >
                <form onSubmit={handleSubmit(handleSubmitZones)}>
                    <Grid container spacing={3}>
                        <Grid item md={12}>
                            <FmTextField
                                name='zoneName'
                                control={control}
                                label='Zone Name'
                                rules={{
                                    required: 'Zone Name is required',
                                }}
                                required
                            />
                        </Grid>
                        <Grid item md={12}>
                            <FmSearchableSelect
                                name='regionId'
                                label='Region'
                                apiUrl={'regions'}
                                valueField='regionId'
                                labelField={['regionName', 'regionCode']}
                                showField={['regionName', 'regionCode']}
                                control={control}
                                required
                            />
                        </Grid>
                        <Grid item md={12}>
                            <ActionButtons
                                onSubmit={handleSubmit(handleSubmitZones)}
                                onCancel={handleCreateClose}
                                onReset={reset}
                                submitLoading={createLoading}
                                cancelLoading={false}
                                submitText='Create'
                            />
                        </Grid>
                    </Grid>
                </form>
            </MuiDialogOne>

            <MuiDialogOne
                title='View Details'
                open={openView}
                onClose={handleViewClose}
            >
                {loading ? (
                    <CommonLoader />
                ) : (
                    <Grid container spacing={3}>
                        <Grid item md={6}>
                            <ViewField label='Zone Code' title={viewData?.zoneCode} />
                        </Grid>
                        <Grid item md={6}>
                            <ViewField label='Zone Name' title={viewData?.zoneName} />
                        </Grid>
                        <Grid item md={6}>
                            <ViewField label='Region Code' title={viewData?.regionCode} />
                        </Grid>
                        <Grid item md={6}>
                            <ViewField label='Region Name' title={viewData?.regionName} />
                        </Grid>
                        <Grid item md={6}>
                            <ViewField label={t('status')} title={viewData?.statusName} />
                        </Grid>
                    </Grid>
                )}
            </MuiDialogOne>

            <MuiDialogOne
                title={t('update')}
                open={editModal}
                onClose={() => setEditModal(false)}
            >
                {loading ? (
                    <CommonLoader />
                ) : (
                    <form onSubmit={handleSubmit(handleEditZones)}>
                        <Grid container spacing={3}>
                            <Grid item md={12}>
                                <FmTextField
                                    name='zoneName'
                                    control={control}
                                    label='Zone Name'
                                    rules={{
                                        required: 'Zone Name is required',
                                    }}
                                    required
                                />
                            </Grid>
                            <Grid item md={12}>
                                <FmSearchableSelect
                                    name='regionId'
                                    label='Region'
                                    apiUrl={'regions'}
                                    valueField='regionId'
                                    labelField={['regionName', 'regionCode']}
                                    showField={['regionName', 'regionCode']}
                                    control={control}
                                    required
                                    defaultValue={{
                                        regionId: editingRowData?.regionId,
                                        regionName: editingRowData?.regionName,
                                    }} />
                            </Grid>
                            <Grid item md={12}>

                                <FmAutoComplete
                                    name='status'
                                    control={control}
                                    options={statusOptions}
                                    label='Status'
                                    displayField='statusName'
                                    optionFields={['statusName']}
                                    valueKey='statusId'
                                />
                            </Grid>
                            <Grid item md={12}>
                                <ActionButtons
                                    onSubmit={handleSubmit(handleEditZones)}
                                    onCancel={() => setEditModal(false)}
                                    submitLoading={editLoading}
                                    onReset={reset}
                                    cancelLoading={false}
                                    submitText='Update'
                                />
                            </Grid>
                        </Grid>
                    </form>
                )}
            </MuiDialogOne>
        </DashboardLayout>
    );
};

export default ZoneMaster;