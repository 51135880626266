import React, { useEffect, useState } from 'react';
import DashboardLayout from '../../../components/DashboardLayout.tsx';
import GlassCard from '../../../components/small/GlassCard.tsx';
import BackToButton from '../../../components/small/BackToButton.tsx';
import { Box } from '@mui/material';
import CommonView from '../../../components/helpers/CommonView.jsx';
import DynamicViewFields from '../../../components/helpers/DynamicViewFields.jsx';
import { useNavigate, useParams } from 'react-router-dom';
import UploadSquareBoxesNew from '../../../components/_form/UploadSquareBoxesNew.tsx';

const ViewMaterialRequest = () => {
  const navigate = useNavigate();
  const [data, setData] = useState(null);
  const { id } = useParams();

  const viewData = (data) => setData(data);

  console.log(data);

  const excludeKeys = [
    'companyId',
    'contractId',
    'departmentId',
    'locationId',
    'materialRequisitionId',
    'materialRequisitionStatusId',
    'materialRequisitionFiles',
    'materialRequisitionItems',
    'materialRequisitionTypeId',
    'projectId',
    'regionId',
    'requesterId',
    'storeLocationId',
    'supplierId',
    'supplierSPOCId',
    'unitId',
    'inCharge',
    'updatedDate',
    'approvalDate',
    'status',
  ];

  return (
    <DashboardLayout
      title='View Material Request'
      actionButtons={
        <BackToButton
          title='Back to List'
          className='px-1 py-0 '
          onClick={() => navigate(-1)}
        />
      }
      titleReverse
    >
      <Box className=''>
        <GlassCard className='p-5'>
          <CommonView
            url='materialrequisitions'
            id={id}
            excludeKeys={excludeKeys}
            renderFields={(data, fields) => (
              <DynamicViewFields data={data} fields={fields} />
            )}
            viewData={viewData}
          />

          {data?.materialRequisitionFiles?.length > 0 && (
            <Box className='w-[300px] mt-8'>
              <h3 className='font-semibold'>Uploaded Files</h3>
              <UploadSquareBoxesNew
                getUploadedFileState={data?.materialRequisitionFiles}
                onlyView
              />
            </Box>
          )}
        </GlassCard>
      </Box>
    </DashboardLayout>
  );
};

export default ViewMaterialRequest;
