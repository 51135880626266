import React from 'react';
import DashboardLayout from '../../../components/DashboardLayout.tsx';
import CollectionAgentList from './CollectionAgentList.tsx';
const InvoiceCollectionAgent = () => {
  return (
    <DashboardLayout title='Invoice Collection Agent'>
      <CollectionAgentList />
    </DashboardLayout>
  );
};

export default InvoiceCollectionAgent;
