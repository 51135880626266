import React from 'react';
import { Box } from '@mui/material';

const TableYesWithCaptions = ({ title, name, type }) => {
  return (
    <Box className='flex flex-col gap-0.5' sx={{ minWidth: '40px' }}>
      <Box
        className={`font-semibold ${type ? 'text-green-600' : 'text-red-500'}`}
      >
        {title}
      </Box>
      <Box className='font-light'>{name}</Box>
    </Box>
  );
};

export default TableYesWithCaptions;
