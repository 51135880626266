import { Box } from '@mui/material';
import React, { useEffect, useRef, useState } from 'react';
import { useFieldArray, useForm, useWatch } from 'react-hook-form';
import { useNavigate, useParams } from 'react-router-dom';
import { toast } from 'react-toastify';
import Crud_Service from '../../../apis/CrudService.jsx';
import DashboardLayout from '../../../components/DashboardLayout.tsx';
import useLoader from '../../../components/helpers/UseLoader.tsx';
import BackToButton from '../../../components/small/BackToButton.jsx';
import GlassCard from '../../../components/small/GlassCard.tsx';
import dayjs from 'dayjs';
import apiInstance from '../../../apis/ApiService.jsx';
import CommonLoader from '../../../components/page/CommonLoader.jsx';
import FilePreviewUpload from '../../../components/modules/FilePreviewUpload.tsx';
import { yupResolver } from '@hookform/resolvers/yup';
import * as Yup from 'yup';
import MuiDialogFullscreen from '../../../components/_mui/MuiDialogFullscreen.tsx';
import EditVendorDocumentsDeliveryNotesTable from './EditVendorDocumentsDeliveryNotesTable.tsx';
import VendorDocumentsPreviewTable from './VendorDocumentsPreviewTable.tsx';
import SplitPane from 'react-split-pane';
import CreateForm from './CreateForm.tsx';
import { FileType } from '../../../constants/FileType.ts';
import { vendorDocumentStatusOptions } from '../../../utils/CommonVariables.tsx';

const schema = Yup.object().shape({
  invoiceNumber: Yup.string()
    .max(32, 'Invoice Number must be at most 32 characters')
    .test(
      'is-valid',
      'Invoice Number must be at most 32 characters',
      (value) => value?.trim()?.length <= 32
    ),

  localPurchaseOrderNo: Yup.string()
    .max(128, 'Local Purchase Order Number must be at most 128 characters')
    .test(
      'is-valid',
      'Local Purchase Order Number must be at most 128 characters',
      (value) => value?.trim()?.length <= 128
    ),
  documentTypeId: Yup.string().required('Document Type ID is required'),
  supplierId: Yup.string().required('Supplier ID is required'),
  documentTransferredTo: Yup.string().required(
    'Document Transferred To is required'
  ),
});

const EditVendorDocuments = (props) => {
  const { type } = props;
  const navigation = useNavigate();
  const crud = new Crud_Service();
  const { id } = useParams();
  const { startEditLoading, stopEditLoading, editLoading } = useLoader();
  const navigate = useNavigate();
  const [files, setFiles] = useState([]);
  const [loading, setLoading] = useState(false);
  const [editData, setEditData] = useState(null);
  const [previewdData, setPreviewData] = useState([]);
  const [removedFiles, setRemovedFiles] = useState([]);
  const [rejectOpen, setRejectOpen] = useState(false);
  const { handleSubmit, control, setValue, getValues, setFocus, watch } =
    useForm({
      resolver: yupResolver(schema),
      mode: 'onChange',
      defaultValues: {
        createDeliveryNotes: [{ quantity: '', rate: '', amount: '' }],
      },
    });
  const { fields, append, remove, replace } = useFieldArray({
    control,
    name: 'createDeliveryNotes',
  });
  const [open, setOpen] = useState(false);
  const [disable, setDisable] = useState(false);
  const [loadingFiles, setLoadingFiles] = useState(false);
  const [file, setFile] = useState(null);
  const [sizes, setSizes] = useState(['50%', 'auto']);

  const notEditable = getValues('vendorDocumentStatusId') > 3;

  const DeliveryNotesNotEditable =
    getValues('vendorDocumentStatusId') ===
      vendorDocumentStatusOptions.Verified ||
    getValues('vendorDocumentStatusId') ===
      vendorDocumentStatusOptions.SiteTeamApproved;
  const [docDeliveryNotes, setDocDeliveryNotes] = useState([]);

  useEffect(() => {
    document.title = 'Edit Vendor Document | Financial';
    getVendorDocuments();
  }, []);

  const getVendorDocuments = async () => {
    setLoading(true);

    await crud.getSingle('vendordocuments', id, (_err, res) => {
      if (res?.status === 200) {
        Object.entries({
          ...res?.data,
        }).forEach(([key, value]) => {
          setValue(key, value);
        });
        setEditData(res?.data);
        setLoading(false);
      } else {
        setLoading(false);
      }
    });

    // !notEditable &&
    await crud.getAll(
      'vendordocumentdeliverynotes',
      { vendorDocumentId: id },
      (_err, res) => {
        if (res?.status === 200) {
          const deliveryNotes = res?.data?.data || [];
          // Convert API response into expected field structure
          const formattedNotes = deliveryNotes.map((item) => ({
            vendorDocumentDeliveryNoteId: item.vendorDocumentDeliveryNoteId, // Keeping ID for reference
            contractId: item.contractId,
            contractName: item.contractName || '',
            projectId: item.projectId,
            projectName: item.projectName || '',
            description: item.description || '',
            quantity: item.quantity || 0,
            rate: item.rate || 0,
            amount: item.amount || 0,
            totalAmount: item.totalAmount || 0,
            vat: item.vat || 0,
            remarks: item.remarks || '',
          }));

          replace(formattedNotes);

          setDocDeliveryNotes(formattedNotes);
        }
      }
    );
  };

  useEffect(() => {
    if (editData && editData?.vendorDocumentAttachments) {
      editData?.vendorDocumentAttachments?.forEach((image) => {
        handleDownloadFile(image?.fileId, image?.attachmentTypeId === 2);
        if (image?.attachmentTypeId === 2) setFile(image);
      });
    }
  }, [editData]);

  const handleDownloadFile = async (fileId, deliveryAttachment) => {
    if (!fileId) return;

    setLoadingFiles(true); // Set loading to true before the API call

    try {
      const imageUrl = await apiInstance.getFiles(`files/download/${fileId}`);
      setPreviewData((prevData) => [
        ...prevData,
        { fileId, fileUrl: imageUrl },
      ]);
    } catch (err) {
      console.error('Error downloading file:', err);
    } finally {
      setLoadingFiles(false); // Set loading to false after API call completion
    }
  };

  const handleFileUpload = async () => {
    const fileIds = [];

    for (const file of files) {
      if (file?.apiCallNo === false) {
        continue;
      }
      const formData = new FormData();
      formData.append('file', file);
      formData.append('fileTypeId', 50);

      try {
        const fileId = await new Promise((resolve, reject) => {
          crud.create('/files/upload', formData, (_err, res) => {
            if (res?.status === 200) {
              const newFile = {
                fileName: res?.data?.originalFileName,
                fileId: res?.data?.fileId,
              };
              resolve(newFile.fileId); // Resolve with fileId
              toast.success('File uploaded successfully');
            } else {
              toast.error('Failed to upload file.');
              reject(new Error('Failed to upload file.'));
            }
          });
        });

        if (fileId) {
          fileIds.push(fileId);
        }
      } catch (error) {
        console.error(`Error uploading file ${file.name}:`, error);
      }
    }

    return fileIds;
  };

  const handleEditVendorDocument = async (values) => {
    if (getValues('vendorDocumentStatusName') === 'Acknowledged') return;
    startEditLoading();
    try {
      const uploadedFileIds = await handleFileUpload();
      setDisable(true);
      const matchingAttachments = values?.vendorDocumentAttachments?.filter(
        (attachment) =>
          removedFiles?.some(
            (removedFileId) => removedFileId?.fileId === attachment?.fileId
          )
      );

      const newAttachments = uploadedFileIds?.map((fileId) => ({
        vendorDocumentAttachmentId: 0, // New attachment
        fileId: fileId,
        status: 2,
      }));

      const updateVendorDocumentAttachments = [
        ...matchingAttachments,
        ...newAttachments,
      ];

      const combinedData = {
        ...values,
        status: 2,
        vendorDocumentStatusId:
          editData?.documentTransferredTo === null &&
          values?.documentTransferredTo
            ? 2
            : getValues('vendorDocumentStatusName') ===
              'Transferred to Coordinator'
            ? 3
            : getValues('vendorDocumentStatusName') === 'Acknowledged'
            ? 4
            : getValues('vendorDocumentStatusName') === 'Upload Document'
            ? 5
            : getValues('vendorDocumentStatusName') ===
              'Delivery Notes Submitted'
            ? 6
            : getValues('vendorDocumentStatusName') === 'Verified'
            ? 7
            : getValues('vendorDocumentStatusName') === 'Rejected'
            ? 8
            : 1,
        responseDate: dayjs().format('YYYY-MM-DD'),
        updateVendorDocumentAttachments: updateVendorDocumentAttachments,
      };
      const response = await new Promise((resolve, reject) => {
        crud.update(`vendordocuments`, id, combinedData, (_err, res) => {
          if (res?.status === 200) {
            resolve(res);
            setOpen(false);
          } else {
            reject(new Error('Failed to Update Vendor Document.'));
          }
        });
      });

      toast.success('Vendor Documents Updated Successfully');
      stopEditLoading();
      navigation(-1);
    } catch (error) {
      toast.error(error.message || 'An error occurred');
    } finally {
      stopEditLoading();
    }
  };
  const [selectedFiles, setSelectedFiles] = React.useState(null);

  const handleUpdatedItems = (e) => {
    setSelectedFiles(e);
    const fileFormData = new FormData();
    fileFormData.append('file', e);
    fileFormData.append('fileTypeId', FileType.VendorDocument);
    crud.create('files/upload', fileFormData, (_err, res) => {
      if (res?.status === 200) {
        const data = {
          vendorDocumentId: id,
          fileIds: [res?.data?.fileId],
        };

        crud.create(
          'vendordocuments/vendordocumentattachments',
          data,
          (_err, res) => {
            if (res?.status === 200) {
              toast.success('File uploaded successfully');
              setDisable(false);
            }
          }
        );
      }
    });
    append({});
  };

  const [anchorEl, setAnchorEl] = React.useState(null);

  const handleRejectClick = (event) => {
    setAnchorEl(event.currentTarget); // Store the button as the anchor
  };

  const handleRejectClose = () => {
    setAnchorEl(null); // Close popover
    setRejectOpen(false);
  };
  const watchedFields =
    useWatch({ control, name: 'createDeliveryNotes' }) || [];

  const prevFieldsRef = useRef([]);
  useEffect(() => {
    const prevFields = prevFieldsRef.current || [];

    const updates = [];
    watchedFields?.forEach((field, index) => {
      if (!field) return;

      const quantity = parseFloat(field.quantity) || 0;
      const rate = parseFloat(field.rate) || 0;
      const amount = quantity * rate || 0; // Calculate amount
      const vat = (field.vat ? parseFloat(field.vat) : 0) || 0;
      const total = amount + (amount * vat) / 100 || 0; // Calculate total with VAT

      if (!isNaN(amount) && prevFields[index]?.amount !== amount.toFixed(2)) {
        updates.push({ index, field: 'amount', value: amount.toFixed(2) });
      }

      if (!isNaN(total) && prevFields[index]?.total !== total.toFixed(2)) {
        updates.push({ index, field: 'total', value: total.toFixed(2) });
      }
    });

    // Batch updates to avoid multiple re-renders
    if (updates.length > 0) {
      updates.forEach(({ index, field, value }) => {
        setValue(`createDeliveryNotes.${index}.${field}`, value);
      });
      prevFieldsRef.current = watchedFields; // Update previous values
    }
  }, [watchedFields]);

  return (
    <>
      <DashboardLayout
        title='Edit Vendor Document'
        actionButtons={
          <BackToButton
            title='Back to List'
            onClick={() => navigation(-1)}
            className='font-semibold px-1 py-0'
          />
        }
        titleReverse
      >
        {loading ? (
          <CommonLoader />
        ) : (
          <>
            {' '}
            <GlassCard className='p-2'>
              <form onSubmit={handleSubmit(handleEditVendorDocument)}>
                <CreateForm
                  control={control}
                  editData={editData}
                  notEditable={notEditable}
                  getValues={getValues}
                  files={files}
                  setFiles={setFiles}
                  previewdData={previewdData}
                  type={type}
                  loadingFiles={loadingFiles}
                  setRemovedFiles={setRemovedFiles}
                  editLoading={editLoading}
                  handleSubmit={handleSubmit}
                  setOpen={setOpen}
                  handleEditVendorDocument={handleEditVendorDocument}
                  anchorEl={anchorEl}
                  rejectOpen={rejectOpen}
                  handleRejectClose={handleRejectClose}
                  id={id}
                  setRejectOpen={setRejectOpen}
                  handleRejectClick={handleRejectClick}
                />{' '}
              </form>
            </GlassCard>
            {
              <MuiDialogFullscreen
                title={'Upload Documents'}
                open={open}
                onClose={() => {
                  setOpen(false);
                  getVendorDocuments();
                }}
                aria-labelledby='alert-dialog-title'
                aria-describedby='alert-dialog-description'
                width={'90%'}
              >
                <Box
                  className='flex flex-col h-full relative'
                  sx={{
                    '& .Pane2': { minWidth: '0px' },
                  }}
                >
                  <SplitPane
                    split='vertical'
                    defaultSize={sizes[0]}
                    onChange={(size) => setSizes([size], size[1])}
                  >
                    <FilePreviewUpload
                      outerFile={handleUpdatedItems}
                      name={'Upload Files'}
                      desc={'Upload Document for vendor'}
                      file={file}
                      editData={editData}
                    />
                    <Box className='h-full w-full'>
                      <VendorDocumentsPreviewTable />
                    </Box>
                  </SplitPane>
                </Box>
              </MuiDialogFullscreen>
            }
            {docDeliveryNotes?.length > 0 && (
              <>
                <EditVendorDocumentsDeliveryNotesTable
                  docDeliveryNotes={docDeliveryNotes}
                  editButtonOnClick={() => setOpen(true)}
                  DeliveryNotesNotEditable={DeliveryNotesNotEditable}
                />
              </>
            )}
          </>
        )}
      </DashboardLayout>
    </>
  );
};

export default EditVendorDocuments;
