import React from 'react';
import GlassCard from '../small/GlassCard.tsx';
import { Box } from '@mui/material';
import { SkeletonLoaderRow } from '../page/SkeletonLoader.tsx';
import SideViewTable from '../_form/SideViewTable.tsx';

const getTitle = {
  salesorder: 'Sales Order Details',
  requester: 'Requester Details',
  tickets: 'Tickets Details',
};

const SalesOrderGetData = (item) => {
  if (!item) return []; // Ensure it doesn't break if item is null/undefined
  return [
    { name: 'Sale Order Code', title: item.salesOrderCode || 'N/A' },
    { name: 'Sale Order Name', title: item.salesOrderName || 'N/A' },
    { name: 'Contract Name', title: item.contractName || 'N/A' },
    { name: 'Contract Code', title: item.contractCode || 'N/A' },
    { name: 'Project Code', title: item.projectCode || 'N/A' },
    { name: 'Project Name', title: item.projectName || 'N/A' },
    { name: 'Client Name', title: item.clientName || 'N/A' },
    { name: 'Branch Name', title: item.branchName || 'N/A' },
    { name: 'Company Name', title: item.companyName || 'N/A' },
  ];
};

const TicketsDetailsData = (item) => {
  if (!item) return []; // Ensure it doesn't break if item is null/undefined
  return [
    { name: 'Ticket Code', title: item.ticketCode || 'N/A' },
    { name: 'Request No', title: item.requestNo || 'N/A' },
    { name: 'Requester Name', title: item.requesterName || 'N/A' },
    { name: 'Contract Code', title: item.contractCode || 'N/A' },
    { name: 'Project Code', title: item.projectCode || 'N/A' },
    { name: 'Project Name', title: item.projectName || 'N/A' },
    { name: 'Building Name', title: item.buildingName || 'N/A' },
    { name: 'InCharge Name', title: item.inChargeName || 'N/A' },
    { name: 'Asset Name', title: item.assetName || 'N/A' },
  ];
};

const RequesterGetData = (item) => {
  if (!item) return []; // Ensure it doesn't break if item is null/undefined
  return [
    { name: 'Code', title: item.employeeCode || 'N/A' },
    { name: 'Name', title: item.employeeName || 'N/A' },
    { name: 'Contact No', title: item.employeeContactNo || 'N/A' },
    { name: 'Contact Email', title: item.employeeMail || 'N/A' },
  ];
};

const dataCenter = {
  salesorder: SalesOrderGetData,
  requester: RequesterGetData,
  tickets: TicketsDetailsData,
};

const SidePanelInfoBox = ({ infoData, loader }) => {
  if (!infoData || !infoData.length) return null;

  const matchedData = infoData
    .map((item) => {
      const key = item.name?.toLowerCase();
      const dataFunction = dataCenter[key];

      return {
        name: getTitle[key] || item.name,
        data: item?.source ? dataFunction(item?.source || {}) : [],
      };
    })
    .filter((item) => item.data.length > 0);

  if (!matchedData.length) {
    return (
      <GlassCard className='p-2'>
        <Box className=' text-center'>No data available</Box>
      </GlassCard>
    );
  }

  return (
    <GlassCard className='p-2' bgDark>
      {loader ? (
        <SkeletonLoaderRow count={10} />
      ) : (
        <Box className='flex flex-col gap-2'>
          {matchedData.map((group) => (
            <Box key={group.name}>
              <Box className='font-bold mb-2' color={'primary.main'}>
                {group.name}
              </Box>
              <Box className='mb-1'>
                <SideViewTable
                  rows={group.data.map((item) => ({
                    label: item.name,
                    title: item.title,
                  }))}
                />
              </Box>
            </Box>
          ))}
        </Box>
      )}
    </GlassCard>
  );
};

export default SidePanelInfoBox;
