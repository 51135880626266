import React from 'react';
import { Box } from '@mui/material';

const ViewFieldInline = ({ name, value }) => {
  return (
    <Box
      sx={{
        minWidth: '100px',
      }}
    >
      <Box className='opacity-80'>{name}</Box>
      <Box sx={{ fontWeight: 500 }}>{value}</Box>
    </Box>
  );
};

export default ViewFieldInline;
