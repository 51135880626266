import { Box, Stack, IconButton } from '@mui/material';
import React, { useEffect, useRef, useState } from 'react';
import PerfectScrollbar from 'react-perfect-scrollbar';
import { useLocation } from 'react-router-dom';
import SidebarMenu from './helpers/SidebarMenu';
import SidebarSettingsDropdown from './helpers/SidebarSettingsDropdown.jsx';
import PageMenu from './PageMenu';
import { useSelector, useDispatch } from 'react-redux';
import { Icon } from '@iconify/react/dist/iconify.js';

const Sidebar = (props) => {
  const { type, open, handleOpenSide } = props;
  const menu = useSelector((state) => state?.menu?.items);

  const [activeIndex, setActiveIndex] = useState(
    Number(sessionStorage.getItem('tab')) ||
      Number(localStorage.getItem('menu'))
  );

  const activeItem = menu?.find((_, index) => index === activeIndex);

  const [activeMenuOpen, setActiveMenuOpen] = React.useState(false);

  const scrollbarRef = useRef(null);
  const location = useLocation();
  const dispatch = useDispatch();

  const handleActiveMenuLeaveOpen = () => {
    setActiveMenuOpen(false);
  };

  useEffect(() => {
    const container = scrollbarRef.current;

    if (container) {
      const activeItem = container.querySelector('.active');

      if (activeItem) {
        const { offsetTop } = activeItem;
        const { clientHeight } = container;
        container.scrollTop = offsetTop - clientHeight / 2;
      }
    }
  }, [location.pathname]);

  return (
    <>
      <Box
        sx={{
          minWidth: !open ? '220px' : '50px',
          flexBasis: !open ? '220px' : '50px',
          display: 'flex',
          height: '100%',
          flexDirection: 'column',
          zIndex: 3,
          overflow: !open && 'hidden',
          borderRadius: !open && '0px 0px 0px 0px',
          borderRight: '1px solid',
          borderColor: 'border.main',
          background: (theme) => theme.palette.background.sidebar,
        }}
        className='sm:hidden relative'
      >
        <Box className='flex-1 min-h-0 h-full overflow-auto'>
          <PerfectScrollbar
            options={{ wheelPropagation: false }}
            containerRef={(ref) => (scrollbarRef.current = ref)}
          >
            <Box className={!open ? 'p-2' : 'p-1'}>
              <Stack direction='column' className='w-full' spacing={0.2}>
                {menu &&
                  menu[localStorage.getItem('menu') || 0]?.menus?.map(
                    (item, i) => {
                      return (
                        item.menuName && (
                          <SidebarMenu
                            icon={item.icon}
                            key={i}
                            name={item.menuName}
                            to={item.url}
                            open={open}
                          />
                        )
                      );
                    }
                  )}
              </Stack>
            </Box>
          </PerfectScrollbar>
        </Box>
        <Box
          className='flex flex-col  relative'
          sx={{ borderTop: '1px solid', borderColor: 'border.main' }}
        >
          <IconButton
            className='p-1 absolute right-[5px] top-[-15px] z-10'
            onClick={handleOpenSide}
            sx={{
              bgcolor: 'background.sidebar',
              border: '1px solid',
              borderColor: 'border.main',
            }}
          >
            <Icon
              icon='ic:baseline-keyboard-double-arrow-right'
              width='20'
              height='20'
            />
          </IconButton>

          <SidebarSettingsDropdown openToggle={open} />
        </Box>
      </Box>
      <PageMenu open={activeMenuOpen} leavePanel={handleActiveMenuLeaveOpen} />
    </>
  );
};

export default Sidebar;
