import { yupResolver } from '@hookform/resolvers/yup';
import { Icon } from '@iconify/react';
import {
  Box,
  Button,
  CircularProgress,
  FormControl,
  FormControlLabel,
  FormHelperText,
  FormLabel,
  Grid,
  IconButton,
  Radio,
  RadioGroup,
  TextField,
} from '@mui/material';
import { DatePicker } from '@mui/x-date-pickers';
import { AdapterDayjs } from '@mui/x-date-pickers/AdapterDayjs';
import { LocalizationProvider } from '@mui/x-date-pickers/LocalizationProvider';
import dayjs from 'dayjs';
import { MuiTelInput } from 'mui-tel-input';
import React, { useEffect, useState } from 'react';
import { Controller, useForm } from 'react-hook-form';
import { useNavigate } from 'react-router-dom';
import { toast } from 'react-toastify';
import apiInstance from '../../../../apis/ApiService';
import Crud_Service from '../../../../apis/CrudService';
import { ActionButtons } from '../../../../components/_form';
import FmAutoComplete from '../../../../components/_mui/FmAutoComplete.tsx';
import FmDatePicker from '../../../../components/_mui/FmDatePicker.tsx';
import FmSearchableSelect from '../../../../components/_mui/FmSearchableSelect.tsx';
import FmTextField from '../../../../components/_mui/FmTextField.tsx';
import useCommonFetchApi from '../../../../components/helpers/useCommonFetchApi.tsx';
import useLoader from '../../../../components/helpers/UseLoader.tsx';
import GlassCard from '../../../../components/small/GlassCard.tsx';
import { EmployeevalidationSchema } from '../../../../utils/CommonvalidationSchemas.tsx';
import FmTelInput from '../../../../components/_mui/FmTelInput.tsx';

const EmployeeProfile = (props) => {
  const {
    setEnabledTabs,
    setValues,
    setEmployees,
    setCreatedEmployee,
    createdEmployee,
    value,
    type,
  } = props;
  const crud = new Crud_Service();
  const [preview, setPreview] = useState(null);
  const [fileId, setFileId] = useState(null);
  const [designation, setDesignation] = useState([]);
  const [bloodGroup, setBloodGroup] = useState([]);
  const [nationalities, setNatinalities] = useState([]);
  const [employeeStatus, setEmployeeStatus] = useState([]);
  const [grades, setGrades] = useState([]);
  const [emirates, setEmirates] = useState([]);
  const birthDate = dayjs().subtract(18, 'years');
  const {
    handleSubmit,
    reset,
    control,
    formState: { errors },
  } = useForm({
    resolver: yupResolver(EmployeevalidationSchema),
    mode: 'onChange',
    defaultValues: {
      dateOfJoin: dayjs(),
      empIdCardProvided: true,
    },
  });
  const { createLoading, startCreateLoading, stopCreateLoading } = useLoader();
  const navigate = useNavigate();

  useEffect(() => {
    if (value === 0) {
      getAllDesignations();
      getEmployees('');
      getBloodGroups();
      getNationalities();
      getEmployeeStatus();
      getGrades();
      getEmirates();
    }
  }, [value]);

  //getAll Employees
  const getEmployees = async (employeeSearchValue) => {
    await apiInstance.getAllEmployee(employeeSearchValue).then((res) => {
      if (res?.status === 200) {
        setEmployees(res?.data?.data);
      } else {
      }
    });
  };

  const { data: wpsCategories } = useCommonFetchApi('wpscategories', '');
  const { data: workingstatuses } = useCommonFetchApi('workingstatuses', '');

  //getAll the Designation
  const getAllDesignations = async () => {
    await crud.getAll(
      'designations',
      {
        pageSize: 1000,
      },
      (_err, res) => {
        if (res?.status === 200) {
          const transformedData = res?.data?.data?.map((row) => ({
            ...row,
            id: row?.designationId,
          }));
          setDesignation(transformedData);
        }
      }
    );
  };

  //getAll Blood groups
  const getBloodGroups = () => {
    crud.getAll('bloodgroups', '', (err, res) => {
      if (err) {
        toast.error('Error fetching blood groups:', err);
        return;
      }
      if (res?.status === 200) {
        const transformedData = res?.data?.data.map((row) => ({
          ...row,
          id: row?.bloodGroupId,
        }));
        setBloodGroup(transformedData);
      }
    });
  };

  //get All natinalities
  const getNationalities = () => {
    crud.getAll('nationalities', '', (err, res) => {
      if (err) {
        toast.error('Error fetching Nationalities:', err);
        return;
      }
      if (res?.status === 200) {
        const transformedData = res?.data?.data.map((row) => ({
          ...row,
          id: row?.nationalityId,
        }));
        setNatinalities(transformedData);
      }
    });
  };

  //get  All Cost Centers
  const getEmployeeStatus = () => {
    crud.getAll('employeestatuses', '', (err, res) => {
      if (err) {
        toast.error('Error fetching Employee Status:', err);
        return;
      }
      if (res?.status === 200) {
        setEmployeeStatus(res?.data?.data);
      } else {
      }
    });
  };

  //get  All Grades
  const getGrades = () => {
    crud.getAll('grades', '', (err, res) => {
      if (err) {
        toast.error('Error fetching Employee Grades:', err);
        return;
      }
      if (res?.status === 200) {
        setGrades(res?.data?.data);
      } else {
      }
    });
  };
  //get  All Grades
  const getEmirates = () => {
    crud.getAll('emirates', '', (err, res) => {
      if (err) {
        toast.error('Error fetching Emirates :', err);
        return;
      }
      if (res?.status === 200) {
        setEmirates(res?.data?.data);
      } else {
      }
    });
  };
  const [imageloading, setLoading] = useState(false);
  const handleFileUpload = async (event) => {
    setPreview(URL.createObjectURL(event.target.files[0]));
    startCreateLoading();
    setLoading(true);
    const file = event.target.files[0];

    const fileFormData = new FormData();
    fileFormData.append('file', file);
    fileFormData.append('fileTypeId', 2);
    await crud.create(`/files/upload`, fileFormData, (_err, res) => {
      if (res?.status === 200) {
        stopCreateLoading();
        setLoading(false);
        toast.success('Profile Uplaoded');
        setFileId(res?.data?.fileId);
      } else {
        stopCreateLoading();
        setLoading(false);
        toast.error('Something Went Wrong Uplaod!');
      }
    });
  };

  const handleRemoveFile = () => {
    setPreview(null);
  };

  const handleSubmitnew = (values) => {
    startCreateLoading();
    const CombinedData = {
      ...values,
      reportingTo: values?.reportingTo,
      dateOfJoin: dayjs(values?.dateOfJoin).format('YYYY-MM-DD'),
      dob: dayjs(values?.dob).format('YYYY-MM-DD'),
      fileId: fileId,
      applicableDate:
        values?.applicableDate && dayjs(values.applicableDate).isValid()
          ? dayjs(values.applicableDate).format('YYYY-MM-DD')
          : null,
      lastAnnualReturnLeaveDate: dayjs(
        values?.lastAnnualReturnLeaveDate
      ).format('YYYY-MM-DD'),
      issueDate: dayjs(values?.issueDate).format('YYYY-MM-DD'),
      expiryDate: dayjs(values?.expiryDate).format('YYYY-MM-DD'),
      gender: Number(values?.gender),
      maritalStatus: Number(values?.maritalStatus) || null,
      empIdCardProvided: values?.empIdCardProvided == 'false' ? false : true,
      revisionDate:
        values?.revisionDate && dayjs(values.revisionDate).isValid()
          ? dayjs(values.revisionDate).format('YYYY-MM-DD')
          : null,
    };

    const UpdateCombinedData = {
      ...values,
      employeeDetailId: createdEmployee?.employeeDetailId,
      employeeProjectDetailId: createdEmployee?.employeeProjectDetailId,
      dob: dayjs(values?.dob).format('YYYY-MM-DD'),
      gender: Number(values?.gender),
      fileId: fileId,
      maritalStatus: Number(values?.maritalStatus) || null,
      dateOfJoin: dayjs(values?.dateOfJoin).format('YYYY-MM-DD'),
      gradeId: values?.gradeId || null,
      applicableDate:
        values?.applicableDate && dayjs(values.applicableDate).isValid()
          ? dayjs(values.applicableDate).format('YYYY-MM-DD')
          : null,
      revisionDate:
        values?.revisionDate && dayjs(values.revisionDate).isValid()
          ? dayjs(values.revisionDate).format('YYYY-MM-DD')
          : null,
      expiryDate: dayjs(values?.expiryDate).format('YYYY-MM-DD'),
      issueDate: dayjs(values?.issueDate).format('YYYY-MM-DD'),
      empIdCardProvided: values?.empIdCardProvided,
      nativeMobileNumber: values?.nativeMobileNumber,
      status: 2,
    };
    if (createdEmployee && createdEmployee?.employeeId) {
      crud.update(
        'employees',
        createdEmployee?.employeeId,
        UpdateCombinedData,
        (err, res) => {
          if (err) {
            stopCreateLoading();
            toast.error('Error creating employee:', err);
            return;
          }
          if (res?.status === 200) {
            toast.success('Employee Profile Updated Successfully');
            setCreatedEmployee(res?.data);
            stopCreateLoading();
            setValues(1);
            setEnabledTabs(1);
            setEnabledTabs(2);
            setEnabledTabs(3);
            setEnabledTabs(4);
            setEnabledTabs(5);
            setEnabledTabs(6);
          } else {
            stopCreateLoading();
          }
        }
      );
    } else {
      crud.create('employees', CombinedData, (err, res) => {
        if (err) {
          stopCreateLoading();
          toast.error('Error creating employee:', err);
          return;
        }
        if (res?.status === 201) {
          setCreatedEmployee(res?.data);
          toast.success('Employee Created Successfully');
          setEnabledTabs(1);
          setEnabledTabs(2);
          setEnabledTabs(3);
          setEnabledTabs(4);
          setEnabledTabs(5);
          setEnabledTabs(6);
          setValues(1);
          stopCreateLoading();
        } else {
          stopCreateLoading();
        }
      });
    }
  };

  return (
    <>
      <form noValidate onSubmit={handleSubmit(handleSubmitnew)}>
        <GlassCard className='flex flex-row justify-between mb-2 p-4 rounded-none'>
          <Box>
            <Box
              sx={{ color: 'text.dark', fontWeight: 600 }}
              className='text-sm mb-2'>
              Upload Profile Picture
            </Box>
            <Box className='opacity-70'>
              Allowed *.jpeg, *.jpg, *.png, *.gif max size of 3 Mb
            </Box>
          </Box>

          {preview ? (
            <Box className='flex flex-row items-center'>
              {imageloading ? (
                <CircularProgress size={24} />
              ) : (
                <img
                  src={preview}
                  alt='Profile Preview'
                  className='rounded-full'
                  style={{ width: 60, height: 60 }}
                />
              )}
              {!imageloading && (
                <IconButton
                  onClick={handleRemoveFile}
                  sx={{ ml: 1 }}
                  aria-label='upload picture'
                  component='span'>
                  <Icon icon='mdi:close' />
                </IconButton>
              )}
            </Box>
          ) : (
            <Box className='flex flex-col items-center'>
              <Button
                component='label'
                variant='text'
                tabIndex={-1}
                sx={{
                  flexDirection: 'column',
                  textTransform: 'inherit',
                  minWidth: 50,
                  minHeight: 50,
                  borderRadius: '50%',
                  color: 'text.main',
                  bgcolor: 'background.light',
                  p: 0,
                  '& svg': {
                    fontSize: '40px',
                  },
                  '&:hover': {
                    bgcolor: 'primary.main',
                    color: 'text.white',
                  },
                }}>
                <Box className='opacity-50'>
                  <Icon icon='fluent:image-circle-24-regular' />
                </Box>
                <input
                  type='file'
                  accept='image/*'
                  onChange={handleFileUpload}
                  style={{ display: 'none' }}
                />
              </Button>
            </Box>
          )}
        </GlassCard>

        <GlassCard className='p-5 rounded-none'>
          <Grid container spacing={2}>
            <Grid item md={6}>
              <FmTextField
                name='firstName'
                control={control}
                label='First Name'
                required
              />
            </Grid>
            <Grid item md={6}>
              <FmTextField
                name='lastName'
                control={control}
                label='Last Name'
              />
            </Grid>
            <Grid item md={6}>
              <FmTextField
                name='fatherName'
                control={control}
                label='Name of Father/Legal Guardian'
              />
            </Grid>
            <Grid item md={6}>
              <FmTextField
                name='motherName'
                control={control}
                label='Name of Mother'
              />
            </Grid>

            <Grid item md={6}>
              <FmTextField
                name='employeeCode'
                control={control}
                label='Manual Employee Code'
                required
              />
            </Grid>
            <Grid item md={6}></Grid>
            <Grid item md={6}>
              <FmSearchableSelect
                name='divisionId'
                control={control}
                apiUrl='divisions'
                valueField='divisionId'
                labelField={['divisionCode', 'divisionName']}
                showField={['divisionCode', 'divisionName']}
                label={'Division'}
              />
            </Grid>
            <Grid item md={6}>
              <FmSearchableSelect
                name='departmentId'
                control={control}
                apiUrl='departments'
                valueField='departmentId'
                labelField={['departmentCode', 'departmentName']}
                showField={['departmentCode', 'departmentName']}
                label={'Department'}
              />
            </Grid>
            <Grid item md={6}>
              <FmAutoComplete
                name='designationId'
                control={control}
                options={designation}
                label='Designation'
                displayField='designationName'
                optionFields={['designationName']}
                valueKey='designationId'
              />
            </Grid>
            <Grid item md={6}>
              <FmAutoComplete
                name='visaDesignationId'
                control={control}
                options={designation?.filter(
                  (item) => item?.designationTypeId === 2
                )}
                label='Visa Designation'
                displayField='designationName'
                optionFields={['designationName']}
                valueKey='designationId'
              />
            </Grid>

            <Grid item md={6}>
              <FmTextField
                name='emailId'
                control={control}
                label='Company Email Id'
                type='email'
              />
            </Grid>
            <Grid item md={6}>
              <FmTelInput
                name='mobileNumber'
                control={control}
                label='UAE Contact Number'
                onlyCountries={['AE']}
              />
            </Grid>
            <Grid item md={6}>
              <FmTelInput
                name='nativeMobileNumber'
                control={control}
                anyCountry
                label='Native Contact Number'
                fullWidth
              />
            </Grid>
            <LocalizationProvider dateAdapter={AdapterDayjs}>
              <Grid item md={6}>
                <Controller
                  name='dateOfJoin'
                  control={control}
                  defaultValue={null}
                  render={({
                    field: { onChange, value },
                    fieldState: { error },
                  }) => (
                    <>
                      <DatePicker
                        label='Joining Date'
                        value={value}
                        defaultValue={dayjs(new Date())}
                        format='DD/MM/YYYY'
                        onChange={(newValue) => {
                          onChange(newValue); // Make sure this is passing the correct date object
                        }}
                        slotProps={{
                          textField: {
                            variant: 'filled',
                            fullWidth: true,
                          },
                        }}
                        renderInput={(params) => (
                          <TextField
                            {...params}
                            error={!!error} // Display error state if there's an error
                            helperText={error ? error.message : ''} // Display the error message
                            sx={{ width: '100%' }}
                            variant='filled'
                          />
                        )}
                      />
                    </>
                  )}
                />
              </Grid>

              <Grid item md={6}>
                <FmDatePicker
                  name='revisionDate'
                  control={control}
                  label='Revision Date'
                  defaultValueNotRequired
                />
              </Grid>

              <Grid item md={6}>
                <FmDatePicker
                  name='applicableDate'
                  control={control}
                  defaultValueNotRequired
                  label='Applicable Date'
                />
              </Grid>
            </LocalizationProvider>
            <Grid item md={6}>
              <FmDatePicker
                name='lastAnnualReturnLeaveDate'
                control={control}
                label='Last Annual Return Leave Date'
                defaultValueNotRequired
              />
            </Grid>
            <Grid item md={6}>
              <Controller
                name='gender'
                control={control}
                defaultValue={null}
                render={({ field, fieldState: { error } }) => (
                  <FormControl component='fieldset' error={!!error}>
                    <FormLabel
                      id='gender'
                      className='font-semibold'
                      required
                      sx={{ fontSize: 'inherit' }}>
                      Gender
                    </FormLabel>
                    <RadioGroup
                      aria-labelledby='gender-radio-group-label'
                      {...field}
                      sx={{ flexDirection: 'row' }}>
                      <FormControlLabel
                        value={1}
                        control={<Radio size='small' />}
                        label='Male'
                      />
                      <FormControlLabel
                        value={2}
                        control={<Radio size='small' />}
                        label='Female'
                      />
                    </RadioGroup>
                    {error && <FormHelperText>{error.message}</FormHelperText>}
                  </FormControl>
                )}
              />
            </Grid>
            <Grid item md={6}>
              <FmSearchableSelect
                name='reportingTo'
                control={control}
                apiUrl='employees'
                valueField='employeeId'
                headerField={['Code', 'Name', 'Email']}
                labelField={['employeeCode', 'fullName', 'emailId']}
                showField={['employeeCode', 'fullName', 'emailId']}
                label={'Reporting To'}
              />
            </Grid>
            <Grid item md={6}>
              <FmAutoComplete
                name='bloodGroupId'
                control={control}
                options={bloodGroup}
                label='Blood Group'
                displayField='bloodGroupName'
                optionFields={['bloodGroupName']}
                valueKey='bloodGroupId'
              />
            </Grid>
            <Grid item md={6}>
              <Controller
                name='maritalStatus'
                control={control}
                defaultValue={null}
                render={({ field, fieldState: { error } }) => (
                  <FormControl component='fieldset' error={!!error}>
                    <FormLabel
                      id='gender'
                      className='font-semibold'
                      sx={{ fontSize: 'inherit' }}>
                      Marital Status
                    </FormLabel>
                    <RadioGroup
                      aria-labelledby='gender-radio-group-label'
                      {...field}
                      sx={{ flexDirection: 'row' }}>
                      <FormControlLabel
                        value={1}
                        control={<Radio size='small' />}
                        label='Single'
                      />
                      <FormControlLabel
                        value={2}
                        control={<Radio size='small' />}
                        label='Married'
                      />
                      <FormControlLabel
                        value={3}
                        control={<Radio size='small' />}
                        label='Divorced'
                      />
                      <FormControlLabel
                        value={4}
                        control={<Radio size='small' />}
                        label='Widowed'
                      />
                    </RadioGroup>
                  </FormControl>
                )}
              />
            </Grid>
            <Grid item md={6}>
              <FmAutoComplete
                name='nationalityId'
                control={control}
                options={nationalities}
                label='Nationality'
                displayField='nationalityName'
                optionFields={['nationalityName']}
                valueKey='nationalityId'
              />
            </Grid>
            <LocalizationProvider dateAdapter={AdapterDayjs}>
              <Grid item md={6}>
                <Controller
                  name='dob'
                  control={control}
                  defaultValue={null}
                  render={({
                    field: { onChange, value },
                    fieldState: { error },
                  }) => (
                    <>
                      <DatePicker
                        label={
                          <span>
                            DOB
                            <span style={{ color: 'red' }}>*</span>
                          </span>
                        }
                        value={value}
                        format='DD/MM/YYYY'
                        maxDate={birthDate}
                        onChange={(newValue) => {
                          onChange(newValue);
                        }}
                        slotProps={{
                          textField: {
                            variant: 'filled',
                            fullWidth: true,
                            error: !!error,
                            helperText: error ? error.message : '',
                          },
                        }}
                        renderInput={(params) => (
                          <TextField
                            {...params}
                            error={!!error}
                            helperText={error ? error.message : ''}
                            sx={{ width: '100%' }}
                          />
                        )}
                      />
                    </>
                  )}
                />
              </Grid>
              <Grid item md={6}>
                <FmTextField
                  name='placeOfBirth'
                  label='Place Of Birth'
                  control={control}
                />
              </Grid>
              <Grid item md={6}>
                <FmSearchableSelect
                  name='contractTypeId'
                  control={control}
                  apiUrl='contracttypes'
                  valueField='contractTypeId'
                  labelField={['contractTypeCode', 'contractTypeName']}
                  showField={['contractTypeName']}
                  label={'Contract Types'}
                />
              </Grid>
              <Grid item md={6}>
                <FmTextField
                  name='unifiedNo'
                  label='Unified No'
                  control={control}
                  pattern='Number'
                />
              </Grid>
              <Grid item md={6}>
                <FmAutoComplete
                  name='wpsCategoryId'
                  control={control}
                  options={wpsCategories}
                  label='WPS Categories'
                  displayField='wpsCategoryName'
                  optionFields={['wpsCategoryName']}
                  valueKey='wpsCategoryId'
                />
              </Grid>
              <Grid item md={6}>
                <FormControl>
                  <FormLabel
                    id='demo-radio-buttons-group-label'
                    className='font-semibold'
                    sx={{ fontSize: 'inherit' }}>
                    EMP ID Card Provided
                  </FormLabel>

                  <Controller
                    name='empIdCardProvided'
                    control={control}
                    defaultValue={true}
                    render={({ field }) => (
                      <RadioGroup
                        {...field}
                        row
                        aria-labelledby='demo-radio-buttons-group-label'>
                        <FormControlLabel
                          value={true}
                          control={<Radio size='small' />}
                          label='Yes'
                        />
                        <FormControlLabel
                          value={false}
                          control={<Radio size='small' />}
                          label='No'
                        />
                      </RadioGroup>
                    )}
                  />
                  {errors.empIdCardProvided && (
                    <p style={{ color: 'red' }}>
                      {errors.empIdCardProvided.message}
                    </p>
                  )}
                </FormControl>
              </Grid>
              <Grid item md={6}>
                <FmTextField
                  name='permanentAddress'
                  label='Permanent Home Country Address'
                  control={control}
                  multiline
                  type='text'
                  fullWidth
                  rows={2}
                  minRows={3}
                  variant='filled'
                />
              </Grid>
              <Grid item md={6}>
                <FmTextField
                  name='personalEmailId'
                  control={control}
                  label='Personal Email'
                  type='email'
                />
              </Grid>
              <Grid item md={6}>
                <FmSearchableSelect
                  name='companyId'
                  control={control}
                  apiUrl='companies'
                  valueField='companyId'
                  labelField={['companyCode', 'companyName']}
                  showField={['companyName']}
                  label={'Sponser'}
                />
              </Grid>
              <Grid item md={6}>
                <FmSearchableSelect
                  name='costCenterId'
                  control={control}
                  apiUrl='costcenters'
                  valueField='costCenterId'
                  labelField={['costCenterCode', 'costCenterName']}
                  showField={['costCenterName']}
                  label={'Cost Center'}
                />
              </Grid>
              <Grid item md={6}>
                <FmAutoComplete
                  name='employeeStatusId'
                  control={control}
                  options={employeeStatus}
                  label='Employee Status'
                  displayField='employeeStatusName'
                  optionFields={['employeeStatusName']}
                  valueKey='employeeStatusId'
                />
              </Grid>
              <Grid item md={6}>
                <FmAutoComplete
                  name='gradeId'
                  control={control}
                  options={grades}
                  label='Grades'
                  displayField='gradeName'
                  optionFields={['gradeName']}
                  valueKey='gradeId'
                />
              </Grid>
              <Grid item md={6}>
                <FmAutoComplete
                  name='emirateId'
                  control={control}
                  options={emirates}
                  label='Emirates'
                  displayField='emirateName'
                  optionFields={['emirateName']}
                  valueKey='emirateId'
                />
              </Grid>
              <Grid item md={6}>
                <FmTextField
                  name='personCode'
                  label='Person Code'
                  control={control}
                />
              </Grid>
              <Grid item md={6}>
                <FmAutoComplete
                  name='workStatusId'
                  control={control}
                  options={workingstatuses}
                  label='Work Status'
                  displayField='workingStatusName'
                  optionFields={['workingStatusName']}
                  valueKey='workingStatusId'
                  required
                />
              </Grid>
              <Grid item md={6}>
                <FmTextField name='eidNo' label='EID No' control={control} />
              </Grid>
              <Grid item md={6}>
                <FmDatePicker
                  name='issueDate'
                  label='Issue Date'
                  control={control}
                />
              </Grid>
              <Grid item md={6}>
                <FmDatePicker
                  name='expiryDate'
                  label='Expiry Date'
                  control={control}
                />
              </Grid>
            </LocalizationProvider>
          </Grid>

          <ActionButtons
            onSubmit={handleSubmit(handleSubmitnew)}
            onReset={reset}
            onCancel={() => navigate(-1)}
            submitLoading={createLoading}
            //
          />
        </GlassCard>
      </form>
    </>
  );
};

export default EmployeeProfile;
