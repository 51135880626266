import React, { useEffect, useMemo, useState } from 'react';
import DashboardLayout from '../../../../components/DashboardLayout.tsx';
import { Box } from '@mui/material';
import useTableLogic from '../../../../components/helpers/MRTUseTableLogic.tsx';
import GlassCard from '../../../../components/small/GlassCard.tsx';
import FmMRTDataTable from '../../../../components/FmMRTDataTable.tsx';
import FmViewDateField from '../../../../components/_mui/FmViewDateField.tsx';
import CurrencyView from '../../../../components/_mui/FmCurrencyView.tsx';
import { useSelector } from 'react-redux';
import { findNavByName } from '../../../../utils/navUtils.ts';
import FmAutoComplete from '../../../../components/_mui/FmAutoComplete.tsx';
import { useForm } from 'react-hook-form';
import { pendingPaymentTypes } from '../../../../utils/CommonVariables.tsx';

const PendingPayments = () => {
  const menu = useSelector((state) => state?.menu?.items);

  const SubMenu = useMemo(() => {
    return menu?.map((item) => item?.menus?.flat()).flat() || [];
  }, [menu]);

  const result = useMemo(
    () => findNavByName(SubMenu, 'Financial', 'Accounts Receivable'),
    [SubMenu]
  );
  const [selectedStatus, setSelectedStatus] = useState(null);
  const [ReceiptGenerated, setReceiptGenerated] = useState(null);

  const {
    rows,
    isLoading,
    pagination,
    sorting,
    columnFilters,
    globalFilter,
    tableRecordCounts,
    setPagination,
    setSorting,
    setColumnFilters,
    setGlobalFilter,
    fetchData,
    setSearchKeyword,
    searchKeyword,
  } = useTableLogic(
    `invoicepayments`,
    ['paymentStatus', 'receiptGenerated'],
    [selectedStatus, ReceiptGenerated !== null ? ReceiptGenerated : '']
  );
  const { handleSubmit, control, watch, setValue } = useForm();

  const columns = [
    {
      header: 'Client',
      headerStyle: { width: '150px' },
      Cell: ({ row }) => {
        return (
          <>
            {' '}
            <Box>
              <Box>{row?.original?.clientName}</Box>
            </Box>
            <Box>
              <Box>{row?.original?.clientCode}</Box>
            </Box>
          </>
        );
      },
    },
    {
      header: 'Project',
      headerStyle: { width: '150px' },
      Cell: ({ row }) => {
        return (
          <>
            {' '}
            <Box>
              <Box>{row?.original?.projectName}</Box>
            </Box>
            <Box>
              <Box>{row?.original?.projectCode}</Box>
            </Box>
          </>
        );
      },
    },
    {
      header: 'Contract',
      headerStyle: { width: '150px' },
      Cell: ({ row }) => {
        return (
          <>
            {' '}
            <Box>
              <Box>{row?.original?.contractName}</Box>
            </Box>
            <Box>
              <Box>{row?.original?.contractCode}</Box>
            </Box>
          </>
        );
      },
    },
    {
      header: 'Contract Date',
      headerStyle: { width: '150px' },
      Cell: ({ row }) => {
        return (
          <>
            <Box sx={{ display: 'flex', alignItems: 'center' }}>
              <FmViewDateField
                color='green'
                value={row?.original?.contractStartDate}
              />
              {' - '}
              <FmViewDateField
                color='red'
                value={row?.original?.contractEndDate}
              />
            </Box>
          </>
        );
      },
    },
    {
      header: 'Contract Cost',
      headerStyle: { width: '150px' },
      Cell: ({ row }) => {
        return <CurrencyView value={row?.original?.contractCost} />;
      },
    },
    {
      header: 'Sales Order',
      headerStyle: { width: '150px' },
      Cell: ({ row }) => {
        return (
          <>
            {' '}
            <Box>
              <Box>{row?.original?.salesOrderName}</Box>
            </Box>
            <Box>
              <Box>{row?.original?.salesOrderCode}</Box>
            </Box>
          </>
        );
      },
    },
    // {
    //   header: 'Contract Schedule Code',
    //   headerStyle: { width: '150px' },
    //   Cell: ({ row }) => {
    //     return <Box>{row?.original?.contractScheduleCode}</Box>;
    //   },
    // },

    {
      header: 'Invoice Date',
      headerStyle: { width: '150px' },
      Cell: ({ row }) => {
        return (
          <>
            <Box sx={{ display: 'flex', alignItems: 'center' }}>
              <FmViewDateField color='green' value={row?.original?.startDate} />
              {' - '}
              <FmViewDateField color='red' value={row?.original?.endDate} />
            </Box>
          </>
        );
      },
    },
    {
      header: 'Billing Frequency',
      headerStyle: { width: '150px' },
      Cell: ({ row }) => {
        return <Box>{row?.original?.billingFrequencyName}</Box>;
      },
    },

    // {
    //   header: 'Code',
    //   headerStyle: { width: '150px' },
    //   Cell: ({ row }) => {
    //     return (
    //       <TableExtraContentTooltip
    //         titleRows={[
    //           {
    //             label: 'Internal Invoice Code',
    //             title: row?.internalInvoiceCode,
    //           },
    //           { label: 'Sales Invoice Code', title: row?.salesInvoiceCode },
    //           {
    //             label: 'Sales Invoice Item Code',
    //             title: row?.salesInvoiceItemCode,
    //           },
    //           {
    //             label: 'Transaction Receipt Code',
    //             title: row?.financeTransactionReceiptCode,
    //           },
    //           {
    //             label: 'Receipt item Code',
    //             title: row?.financeTransactionReceiptItemCode,
    //           },
    //         ]}
    //         dark={true}
    //       >
    //         <Box className='flex gap-2 items-center'>
    //           <Box className='text-2xl '>
    //             <Icon icon='carbon:code-block' width='32' height='32' />
    //           </Box>
    //         </Box>
    //       </TableExtraContentTooltip>
    //     );
    //   },
    // },
    {
      header: ' Invoice Scheduled',
      headerStyle: { width: '150px' },
      Cell: ({ row }) => {
        return row?.original?.amountToBeInvoiced;
      },
    },
    {
      header: 'Invoiced Amount',
      headerStyle: { width: '150px' },
      Cell: ({ row }) => {
        return row?.original?.invoicedAmount;
      },
    },

    {
      header: 'Paid',
      headerStyle: { width: '150px' },
      Cell: ({ row }) => {
        return row?.original?.paymentAmount;
      },
    },
    {
      header: 'Pending Payment',
      headerStyle: { width: '150px' },
      Cell: ({ row }) => {
        return row?.original?.pendingAmount;
      },
    },
    {
      header: 'Deduction Amount',
      headerStyle: { width: '150px' },
      Cell: ({ row }) => {
        return row?.original?.deductionAmount;
      },
    },
    {
      header: 'Is Payment Received',
      headerStyle: { width: '150px' },
      Cell: ({ row }) => {
        return row?.original?.paymentReceived === true ? 'Yes' : 'No';
      },
    },

    // {
    //   header: 'Reference',
    //   headerStyle: { width: '150px' },
    //   Cell: ({ row }) => {
    //     return (
    //       <TableExtraContentTooltip
    //         titleRows={[
    //           {
    //             label: 'Invoice Reference',
    //             title: row?.invoiceRef,
    //           },
    //           { label: 'Payment Reference', title: row?.paymentRef },
    //         ]}
    //         dark={true}
    //       >
    //         <Box className='flex gap-2 items-center'>
    //           <Box className='text-2xl'>
    //             <Icon icon='carbon:code-block' width='32' height='32' />
    //           </Box>
    //         </Box>
    //       </TableExtraContentTooltip>
    //     );
    //   },
    // },
    // {
    //   header: 'Remarks',
    //   headerStyle: { width: '150px' },
    //   Cell: ({ row }) => {
    //     return row?.original?.remarks;
    //   },
    // },
  ];
useEffect(() => {
  console.log(
    ReceiptGenerated,
    'receiptGenerated',
    selectedStatus,
    'selectedStatus'
  );
}, [ReceiptGenerated, selectedStatus]);
const handleFilterChange = (selectedValue, Receipt) => {
  if (Receipt) {
    setReceiptGenerated(selectedValue);
  } else {
    setSelectedStatus(selectedValue);
  }
};

useEffect(() => {
  fetchData();
}, [selectedStatus, ReceiptGenerated]);

return (
  <>
    <DashboardLayout title={'Pending Payment'} hasSubmenu menu={result}>
      <Box className='flex flex-col gap-2 h-full'>
        <GlassCard className='flex-1 min-h-0'>
          <FmMRTDataTable
            columns={columns}
            rows={rows}
            rowCount={tableRecordCounts}
            pagination={pagination}
            sorting={sorting}
            columnFilters={columnFilters}
            globalFilter={globalFilter}
            setPagination={setPagination}
            setSorting={setSorting}
            setColumnFilters={setColumnFilters}
            setGlobalFilter={setGlobalFilter}
            isLoading={isLoading}
            setSearchKeyword={setSearchKeyword}
            renderTopToolbarLeftChildrens={
              <>
                {' '}
                <Box className='w-[200px]'>
                  <FmAutoComplete
                    name='reciptFilter'
                    control={control}
                    label='Receipt Generated'
                    options={[
                      { value: 'true', label: 'Yes' },
                      { value: 'false', label: 'No' },
                    ]}
                    displayField='label'
                    TextFieldVariant='outlined'
                    defaultValue={{
                      value: ReceiptGenerated,
                      label: ReceiptGenerated === 'true' ? 'Yes' : 'No',
                    }}
                    optionFields={['label']}
                    valueKey='value'
                    onChange={(selectedValue) => {
                      handleFilterChange(selectedValue, true);
                    }}
                    className='filter-autocomplete'
                  />
                </Box>
                <Box className='w-[200px]'>
                  <FmAutoComplete
                    name='statementFilter'
                    control={control}
                    options={pendingPaymentTypes}
                    displayField='label'
                    TextFieldVariant='outlined'
                    defaultValue={{ label: 'All' }}
                    optionFields={['label']}
                    label='Payment Status'
                    valueKey='value'
                    onChange={(selectedValue) => {
                      handleFilterChange(selectedValue, false);
                    }}
                    className='filter-autocomplete'
                  />
                </Box>
              </>
            }
            searchKeyword={searchKeyword}
            fetchData={fetchData}
            muiTableContainerProps={{
              sx: {
                maxHeight: '100%',
                flex: 1,
              },
            }}
            showSerialNumber={true}
            muiSelectCheckboxProps={({ row }) => ({
              disabled: row?.original?.invoicepaymentstatusId !== 4,
            })}
            enableSelectAll={false}
          />
        </GlassCard>
      </Box>
    </DashboardLayout>
  </>
);
};

export default PendingPayments;
