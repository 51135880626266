import React from 'react';
import { Menu, IconButton, Box, Button } from '@mui/material';
import { Icon } from '@iconify/react';
import FmTextField from '../_mui/FmTextField.tsx';
import { useForm } from 'react-hook-form';
import Crud_Service from '../../apis/CrudService.jsx';
import { toast } from 'react-toastify';
import { useNavigate } from 'react-router-dom';

const FieldPopOverBox = ({
  title,
  anchorEl,
  open,
  onClose,
  setRejectOpen,
  id,
  type,
}) => {
  const crud = new Crud_Service();

  const { control, handleSubmit } = useForm();
  const navigation = useNavigate();

  const handleRejectReason = async (values) => {
    const data = {
      vendorDocumentStatusId: type === 1 ? 8 : 10, //reject status
      remarks: values.rejectReason,
    };
    if (type === 1) {
      crud.update('vendordocuments/site-status', id, data, (_err, res) => {
        if (res?.status === 200) {
          toast.success('Rejected Successfully');
          setRejectOpen(false);
        }
      });
    } else if (type === 3) {
      crud.update(
        'vendordocuments/rejection',
        id,
        {
          vendorDocumentStatusId: 12,
          remarks: values.rejectReason,
        },
        (_err, res) => {
          if (res?.status === 200) {
            toast.success('Rejected Successfully');
            setRejectOpen(false);
            navigation(-1);
          }
        }
      );
    } else {
      crud.update('vendordocuments/finance-status', id, data, (_err, res) => {
        if (res?.status === 200) {
          toast.success('Rejected Successfully');
          setRejectOpen(false);
        }
      });
    }
  };

  return (
    <Menu
      anchorEl={anchorEl}
      open={open}
      onClose={onClose}
      anchorOrigin={{
        vertical: 'bottom', // Popover opens below the button
        horizontal: 'center',
      }}
      transformOrigin={{
        vertical: 'top', // Align top of the popover with button
        horizontal: 'center',
      }}
      sx={{
        '& .MuiPaper-root': { padding: 0, boxShadow: 2 },
        '& .MuiList-root': { padding: 0 },
      }}
    >
      <form onSubmit={handleSubmit(handleRejectReason)}>
        <Box className='p-5'>
          <div className='font-bold mb-2 flex gap-3 items-center'>
            <FmTextField
              control={control}
              name='rejectReason'
              multiline
              rows={4}
              value={title}
            />
          </div>
          <div className='w-72'>Are you sure you want to reject this?</div>
          <div className='flex justify-end items-center gap-5'>
            <Button variant='outlined' color='primary' onClick={onClose}>
              No
            </Button>
            <Button
              type='submit'
              variant='contained'
              sx={{ fontWeight: 700 }}
              color='error'
            >
              Yes
            </Button>
          </div>
        </Box>
      </form>
    </Menu>
  );
};

export default FieldPopOverBox;
