import React, { useEffect, useRef, useState } from 'react';
import GlassCard from '../../../../components/small/GlassCard.tsx';
import { useNavigate, useParams } from 'react-router-dom';
import BackToButton from '../../../../components/small/BackToButton.tsx';
import FmSearchableSelect from '../../../../components/_mui/FmSearchableSelect.tsx';
import { useForm } from 'react-hook-form';
import { yupResolver } from '@hookform/resolvers/yup';
import { ContractBudgetSchema } from '../../../../utils/CommonvalidationSchemas.tsx';
import useTableLogic from '../../../../components/helpers/MRTUseTableLogic.tsx';
import Crud_Service from '../../../../apis/CrudService.jsx';
import { useHotkeys } from 'react-hotkeys-hook';
import { toast } from 'react-toastify';
import { Box, Button, Typography } from '@mui/material';
import FmMRTDataTable from '../../../../components/FmMRTDataTable.tsx';
import TableActionButtons from '../../../../components/_form/TableActionButtons.jsx';
import Icons from '../../../../utils/Icon.jsx';
import FmTextField from '../../../../components/_mui/FmTextField.tsx';

const CreateSalesQuotationInternalBudget = () => {
  const navigate = useNavigate();
  const { id } = useParams();

  const {
    rows,
    setRows,
    isLoading,
    pagination,
    sorting,
    columnFilters,
    globalFilter,
    tableRecordCounts,
    setPagination,
    setSorting,
    setColumnFilters,
    setGlobalFilter,
    fetchData,
    setSearchKeyword,
    searchKeyword,
  } = useTableLogic(`salesquotationbudgets`, ['salesQuotationId'], [id]);
  const [, setCreatingRow] = useState(null);
  const [editingRowId, setEditingRowId] = useState(null);
  const {
    control,
    reset,
    getValues,
    setValue,
    trigger,
    formState: { isValid },
  } = useForm({
    resolver: yupResolver(ContractBudgetSchema),
    mode: 'all',
  });
  const firstColumnInputRef = useRef(null);
  const secondColumnInputRef = useRef(null);
  const [stateError, setStateError] = useState(false);
  const [loader, setLoader] = useState(false);
  const [editingRowIndex, setEditingRowIndex] = useState(null);
  const crud = new Crud_Service();

  useEffect(() => {
    const updatedRows = rows.map((row) => {
      const totalAmount =
        (Number(row.staffSalariesAmount) || 0) +
        (Number(row.otherPreliminariesAmount) || 0) +
        (Number(row.labourAmount) || 0) +
        (Number(row.materialsAmount) || 0);
      return {
        ...row,
        totalAmount,
      };
    });

    if (JSON.stringify(updatedRows) !== JSON.stringify(rows)) {
      setRows(updatedRows);
    }
  }, [rows]);

  const handleCreateNewRow = (newRow) => {
    setCreatingRow(null);
  };

  useEffect(() => {
    document.title = 'Internal Budget | Sales Quotation';
    fetchQuotationData();
  }, []);

  const [rowState, setRowState] = useState({
    editingRowId: null,
    newRowId: null,
    isCreatingRow: false,
  });

  useHotkeys(
    'Enter',
    () => handleAddNewRow(),
    {
      enableOnTags: ['INPUT', 'TEXTAREA'], // Optional: Enable hotkey only when focused on specific tags
    },
    [editingRowIndex, rows, stateError, loader]
  );

  useHotkeys(
    'Tab',
    (event) => {
      if (event.key !== 'Tab') return;
      if (stateError) {
        event.preventDefault();
        return;
      }
      const totalRows = rows.length;
      const nextRowIndex = editingRowIndex + 1;

      if (
        nextRowIndex < totalRows &&
        stateError === false &&
        loader === false
      ) {
        handleEdit(rows[nextRowIndex]);
        setEditingRowIndex(nextRowIndex);
      }
    },
    [editingRowIndex, rows, stateError, loader, isValid]
  );

  const handleCancel = (row) => {
    if (row?.original?.salesQuotationBudgetId === rowState?.newRowId) {
      setRows((prevRows) => {
        if (prevRows?.length > 0) {
          return prevRows?.slice(0, -1);
        }
        return prevRows;
      });

      setRowState((prevState) => ({
        ...prevState,
        newRowId: null,
        isCreatingRow: false,
      }));
    } else {
      setEditingRowId(null);
    }
    reset();
  };

  const handleCancelAndAddNewRow = () => {
    const newId = 'new_row_' + Date.now();
    reset();

    setEditingRowId(null);

    setRowState({
      newRowId: newId,
      isCreatingRow: true,
    });
    setEditingRowIndex(
      editingRowIndex === rows?.length ? editingRowIndex + 1 : rows?.length
    );
    setRows((prevRows) => [
      ...prevRows,
      {
        salesQuotationBudgetId: newId,
        salesQuotationId: id,
        divisionId: '',
        budget: '',
        staffSalariesAmount: '',
        otherPreliminariesAmount: '',
        labourAmount: '',
        materialsAmount: '',
        isEditable: true,
        isNew: true,
      },
    ]);
    reset();
  };

  const handleBlur = () => {
    const currentValues = getValues();
    const divisionId = getValues('divisionId');
    const budget = getValues('budget');

    if (!divisionId) {
      firstColumnInputRef.current.focus();
      return;
    }

    if (!budget) {
      secondColumnInputRef.current.focus();
      return;
    }

    const hasChanges = Object.keys(currentValues).some(
      (key) => currentValues[key] !== previousValues.current[key]
    );

    if ((hasChanges && isValid) || (hasChanges && editingRowId)) {
      apiCallAsync(currentValues); // Call the API with the new values
      previousValues.current = currentValues; // Update previous values
    }
  };

  //asyncronous api call
  const apiCallAsync = async (values) => {
    setLoader(true);
    const convertedValues = {
      ...values,
      budget: Number(values?.budget),
      labourAmount: Number(values?.labourAmount),
      materialsAmount: Number(values?.materialsAmount),
      otherPreliminariesAmount: Number(values?.otherPreliminariesAmount),
      staffSalariesAmount: Number(values?.staffSalariesAmount),
      salesQuotationId: Number(id),
    };

    const updatedCombinedData = {
      ...values,
      salesQuotationId: Number(id),
      divisionId: values?.divisionId,
      budget: Number(values?.budget),
      staffSalariesAmount: Number(values?.staffSalariesAmount),
      otherPreliminariesAmount: Number(values?.otherPreliminariesAmount),
      labourAmount: Number(values?.labourAmount),
      materialsAmount: Number(values?.materialsAmount),
      status: 2,
    };
    if (editingRowId === null) {
      await crud.create(
        'salesquotationbudgets',
        convertedValues,
        (err, res) => {
          if (res?.status === 201) {
            setEditingRowId(res?.data?.salesQuotationBudgetId);
            // setValue('salesQuotationBudgetId', res?.data?.salesQuotationBudgetId);
          } else {
            setEditingRowId(null);
          }
        }
      );
    } else {
      editingRowId &&
        (await crud.update(
          'salesquotationbudgets',
          editingRowId,
          updatedCombinedData,
          (err, res) => {
            if (res?.status === 200) {
              if (rows.some((row) => row.isNew)) {
                const updatedRow = res?.data;
                const updatedRows = [...rows];
                updatedRows[editingRowIndex] = {
                  ...updatedRow,
                  isEditable: true,
                  isNew: true,
                };
                setRows(updatedRows);
                setStateError(false);
                setLoader(false);
              } else {
                setStateError(false);
                setLoader(false);
                const updatedRow = res?.data;
                const updatedRows = [...rows];

                updatedRows[editingRowIndex] = updatedRow;

                setRows(updatedRows);
              }
            } else {
              setStateError(true);
            }
          }
        ));
    }
  };
  const handleAddNewRow = () => {
    const newId = 'new_row_' + Date.now();
    reset();

    setEditingRowId(null);
    setRowState({
      newRowId: newId,
      isCreatingRow: true,
    });
    setEditingRowIndex(
      editingRowIndex === rows.length ? editingRowIndex + 1 : rows.length
    );
    setRows((prevRows) => [
      ...prevRows,
      {
        salesQuotationBudgetId: newId,
        salesQuotationId: id,
        divisionId: '',
        budget: '',
        staffSalariesAmount: '',
        otherPreliminariesAmount: '',
        labourAmount: '',
        materialsAmount: '',
        isEditable: true,
        isNew: true,
      },
    ]);
    reset();
  };

  //final Step Api Call
  const handleBlurAndFetch = async () => {
    setLoader(true);
    const values = getValues();

    const updateCombinedData = {
      ...values,
      salesQuotationId: id,
      status: 2,
    };
    await crud.update(
      'salesquotationbudgets',
      editingRowId,
      updateCombinedData,
      (err, res) => {
        if (res?.status === 200) {
          const totalRows = rows.length;
          if (editingRowIndex <= totalRows && rows.some((row) => row.isNew)) {
            const updatedRow = res?.data;
            const updatedRows = [...rows];
            updatedRows[editingRowIndex] = {
              ...updatedRow,
              isEditable: true,
              isNew: true,
            };
            setRows(updatedRows);
            handleAddNewRow();
          } else {
            const updatedRow = res?.data;
            const updatedRows = [...rows];
            updatedRows[editingRowIndex] = {
              ...updatedRow,
              isEditable: false,
              isNew: false,
            };

            setRows(updatedRows);
            setStateError(false);
            setLoader(false);
          }
        } else {
          setStateError(true);
        }
      }
    );
  };
  const handleInputChange = async (e, field) => {
    const value = parseFloat(e.target.value) || 0;
    const currentValues = getValues();
    const updatedValues = {
      ...currentValues,
      [field]: value,
    };

    const newTotal =
      (Number(updatedValues?.staffSalariesAmount) || 0) +
      (Number(updatedValues?.otherPreliminariesAmount) || 0) +
      (Number(updatedValues?.labourAmount) || 0) +
      (Number(updatedValues?.materialsAmount) || 0);

    setValue('totalAmount', newTotal);
    setValue(field, value);

    await trigger();
  };
  const [quotationData, setQuotationData] = useState({});

  const fetchQuotationData = async () => {
    await crud.getSingle('salesQuotations', id, (_err, res) => {
      if (res?.status === 200) {
        setQuotationData(res?.data);
        setLoader(false);
      } else {
      }
    });
  };
  const validateTotalBudget = () => {
    const currentValues = getValues();
    const totalBudget =
      rows
        .filter(
          (row) =>
            row?.salesQuotationBudgetId !==
            currentValues?.salesQuotationBudgetId
        )
        .reduce((acc, row) => acc + (row?.budget || 0), 0) +
      (currentValues?.budget || 0);

    if (totalBudget > quotationData?.budget) {
      toast.error('The total budget of all rows exceeds the contract budget.');
      return false;
    }

    return true;
  };

  const handleEdit = (row) => {
    reset();
    if (rowState?.newRowId) {
      setRows((prevRows) => {
        if (prevRows?.length > 0) {
          return prevRows?.slice(0, -1);
        }
        return prevRows;
      });
      setRowState((prevState) => ({
        ...prevState,
        newRowId: null,
        isCreatingRow: false,
      }));
      // setEditingRowIndex(null);
    }
    setEditingRowId(row?.salesQuotationBudgetId);
    Object.entries({
      ...row,
    }).forEach(([key, value]) => {
      setValue(key, value);
    });
  };
  useEffect(() => {
    if (editingRowIndex !== null && firstColumnInputRef.current && !isLoading) {
      firstColumnInputRef.current.focus();
    }
  }, [editingRowIndex, isLoading]);

  const previousValues = useRef(getValues());

  const excludeKeys = [
    'itemName',
    'quantity',
    'unitOfMeasurementId',
    'unitPrice',
    'itemTotal',
    'remarks',
  ];
  const handleDeleteBudget = async (view) => {
    await crud.remove(
      'salesquotationbudgets',
      view?.salesQuotationBudgetId,
      (err, res) => {
        if (res?.status === 204) {
          fetchData();
          toast.success('Sales Quotation Budget Deleted Successfully');
        } else {
        }
      }
    );
  };
  const ActionData = [
    {
      name: 'Delete',
      icon: Icons.delete,
      onClick: (props) => handleDeleteBudget(props),
      danger: true,
      hidden: quotationData?.quotationStatusId === 2,
    },
  ];

  const columns = [
    {
      accessorKey: 'divisionId',
      header: 'Division',
      enableEditing: true,
      Cell: ({ row }) => {
        const isNewRow =
          row?.original?.salesQuotationBudgetId === rowState?.newRowId;
        const isEditing =
          editingRowId === row?.original?.salesQuotationBudgetId || isNewRow;
        return isEditing ? (
          <FmSearchableSelect
            name='divisionId'
            control={control}
            apiUrl='divisions'
            valueField={'divisionId'}
            headerField={['Name', 'Code']}
            labelField={['divisionName', 'divisionCode']}
            showField={['divisionName', 'divisionCode']}
            inputRef={firstColumnInputRef}
            label={'Division'}
            onBlur={handleBlur}
            defaultValue={{
              divisionId: row?.original?.divisionId,
              divisionName: row?.original?.divisionName,
            }}
          />
        ) : (
          row?.original?.divisionName || ''
        );
      },
    },
    {
      accessorKey: 'budget',
      header: 'Budget Amount',
      enableEditing: true,
      Cell: ({ row }) => {
        const isNewRow =
          row?.original?.salesQuotationBudgetId === rowState?.newRowId;
        const isEditing =
          editingRowId === row?.original?.salesQuotationBudgetId || isNewRow;
        return isEditing ? (
          <Box sx={{ width: '100%' }}>
            <FmTextField
              name='budget'
              control={control}
              onBlur={handleBlur}
              inputRef={secondColumnInputRef}
              type='number'
              onChange={(e) => handleInputChange(e, 'budget')}
            />
          </Box>
        ) : (
          row?.original?.budget
        );
      },
    },

    {
      accessorKey: 'staffSalariesAmount',
      header: 'Staff Salaries',
      enableEditing: true,
      Cell: ({ row }) => {
        const isNewRow =
          row?.original?.salesQuotationBudgetId === rowState?.newRowId;
        const isEditing =
          editingRowId === row?.original?.salesQuotationBudgetId || isNewRow;
        return isEditing ? (
          <FmTextField
            name='staffSalariesAmount'
            control={control}
            onBlur={handleBlur}
            type='Number'
            onChange={(e) => handleInputChange(e, 'staffSalariesAmount')}
          />
        ) : (
          row?.original?.staffSalariesAmount
        );
      },
    },
    {
      accessorKey: 'otherPreliminariesAmount',
      header: 'Other Preliminaries',
      enableEditing: true,
      Cell: ({ row }) => {
        const isNewRow =
          row?.original?.salesQuotationBudgetId === rowState?.newRowId;
        const isEditing =
          editingRowId === row?.original?.salesQuotationBudgetId || isNewRow;
        return isEditing ? (
          <FmTextField
            name='otherPreliminariesAmount'
            control={control}
            onBlur={handleBlur}
            type='Number'
            onChange={(e) => handleInputChange(e, 'otherPreliminariesAmount')}
          />
        ) : (
          row?.original?.otherPreliminariesAmount
        );
      },
    },
    {
      accessorKey: 'labourAmount',
      header: 'Labour Amount',
      enableEditing: true,
      Cell: ({ row }) => {
        const isNewRow =
          row?.original?.salesQuotationBudgetId === rowState?.newRowId;
        const isEditing =
          editingRowId === row?.original?.salesQuotationBudgetId || isNewRow;
        return isEditing ? (
          <FmTextField
            name='labourAmount'
            control={control}
            onBlur={handleBlur}
            type='Number'
            onChange={(e) => handleInputChange(e, 'labourAmount')}
          />
        ) : (
          row?.original?.labourAmount
        );
      },
    },
    {
      accessorKey: 'materialsAmount',
      header: 'Material Amount',
      enableEditing: true,
      size: 250,
      Cell: ({ row }) => {
        const isNewRow =
          row?.original?.salesQuotationBudgetId === rowState?.newRowId;
        const isEditing =
          editingRowId === row?.original?.salesQuotationBudgetId || isNewRow;
        return isEditing ? (
          <Box sx={{ width: '100%' }}>
            <FmTextField
              name='materialsAmount'
              control={control}
              onBlur={handleBlurAndFetch}
              type='Number'
              sx={{ width: '100%' }}
              onChange={(e) => handleInputChange(e, 'materialsAmount')}
            />
          </Box>
        ) : (
          row?.original?.materialsAmount
        );
      },
    },
    {
      accessorKey: 'totalAmount',
      header: 'Total Amount',
      enableEditing: false,
      Cell: ({ row }) => {
        const isNewRow =
          row?.original?.salesQuotationBudgetId === rowState?.newRowId;
        const isEditing =
          editingRowId === row?.original?.salesQuotationBudgetId || isNewRow;
        const currentValues = getValues();
        const totalAmount =
          (Number(currentValues?.staffSalariesAmount) || 0) +
          (Number(currentValues?.otherPreliminariesAmount) || 0) +
          (Number(currentValues?.labourAmount) || 0) +
          (Number(currentValues?.materialsAmount) || 0);

        return isEditing ? (
          <Box sx={{ width: '100%' }}>
            {totalAmount}
            {
              <Typography
                variant='caption'
                className='text-yellow-400 text-xs block mt-1'
              >
                {getValues('totalAmount') > getValues('budget')
                  ? 'The total amount exceeds the budget.'
                  : ''}
              </Typography>
            }
          </Box>
        ) : (
          row.original?.totalAmount
        );
      },
    },
    {
      header: 'Action',
      enableColumnPinning: true,
      enableEditing: false,
      enableSorting: false,
      size: 100,
      Cell: ({ row, table }) => {
        const isNewRow =
          row?.original?.salesQuotationBudgetId === rowState?.newRowId;
        const isEditing =
          editingRowId === row?.original?.salesQuotationBudgetId || isNewRow;

        // Check if the current row is the last row in the table
        const isLastRow = row.index === table.getRowModel().rows.length - 1;

        return (
          <Box className='flex gap-2'>
            {isEditing ? (
              <>
                <Button onClick={() => handleCancel(row)}>Close</Button>
                {isLastRow && rowState?.isCreatingRow === false && (
                  <Button
                    onFocus={() => handleCancelAndAddNewRow()}
                    hidden={true}
                  ></Button>
                )}
              </>
            ) : (
              <Box className='flex gap-2'>
                <TableActionButtons
                  Actions={ActionData?.map((action) => ({
                    ...action,
                    onClick: () => action.onClick(row?.original),
                  }))}
                />
                {isLastRow && quotationData?.quotationStatusId === 1 && (
                  <Button
                    variant='contained'
                    color='primary'
                    className='px-3 py-1'
                    onClick={() => {
                      setEditingRowIndex(row?.index + 1);
                      handleAddNewRow();
                    }}
                  >
                    Add
                  </Button>
                )}
              </Box>
            )}
          </Box>
        );
      },
    },
  ];

  return (
    <GlassCard className='w-full'>
      <FmMRTDataTable
        rows={rows}
        columns={columns}
        onCreateRow={
          quotationData?.quotationStatusId === 1 ? handleAddNewRow : null
        }
        isCreatingRow={rowState?.isCreatingRow}
        enableRowSelection={false}
        rowCount={tableRecordCounts}
        editingRow={editingRowId}
        fetchData={fetchData}
        pagination={pagination}
        sorting={sorting}
        columnFilters={columnFilters}
        globalFilter={globalFilter}
        setPagination={setPagination}
        setSorting={setSorting}
        setColumnFilters={setColumnFilters}
        setGlobalFilter={setGlobalFilter}
        isLoading={isLoading}
        setSearchKeyword={setSearchKeyword}
        renderTopToolbarCustomActionsChildrens={
          <Typography
            variant='caption'
            className='text-red-600 text-xs block mt-1'
          >
            {!validateTotalBudget() && (
              <Typography color='error'>
                The total budget of all rows exceeds the contract budget.
              </Typography>
            )}
          </Typography>
        }
        searchKeyword={searchKeyword}
        handleRowDoubleClick={(row) => {
          if (quotationData?.quotationStatusId === 1) {
            handleEdit(row?.original);
            setEditingRowIndex(row?.index);
          }
        }}
        muiTableContainerProps={{
          sx: {
            maxHeight: '350px',
            flex: 1,
          },
        }}
      />
    </GlassCard>
  );
};

export default CreateSalesQuotationInternalBudget;
