import React, { useEffect, useRef, useState } from 'react';
import Crud_Service from '../../../apis/CrudService.jsx';
import { useParams } from 'react-router-dom';
import { useForm } from 'react-hook-form';
import { useHotkeys } from 'react-hotkeys-hook';
import FmTextField from '../../../components/_mui/FmTextField.tsx';
import { Box, Button, IconButton } from '@mui/material';
import TableActionButtons from '../../../components/_form/TableActionButtons.jsx';
import GlassCard from '../../../components/small/GlassCard.tsx';
import FmMRTDataTable from '../../../components/FmMRTDataTable.tsx';
import useTableLogic from '../../../components/helpers/MRTUseTableLogic.tsx';
import { yupResolver } from '@hookform/resolvers/yup';
import FmSearchableSelect from '../../../components/_mui/FmSearchableSelect.tsx';
import Icons from '../../../utils/Icon.jsx';
import { toast } from 'react-toastify';
import * as Yup from 'yup';
import FmCurrencyTextField from '../../../components/_mui/FmCurrencyTextField.tsx';
import CurrencyView from '../../../components/_mui/FmCurrencyView.tsx';
import { ContractBudgetPreviewSchema } from '../../../utils/CommonvalidationSchemas.tsx';

const VendorDocumentsPreviewTable = (props) => {
  const { id } = useParams();
  const {
    rows,
    setRows,
    rowCount,
    isLoading,
    pagination,
    sorting,
    columnFilters,
    globalFilter,
    tableRecordCounts,
    setPagination,
    setSorting,
    setColumnFilters,
    setGlobalFilter,
    fetchData,
    setSearchKeyword,
    searchKeyword,
  } = useTableLogic(`vendordocumentdeliverynotes`, ['vendorDocumentId'], [id]);
  const crud = new Crud_Service();
  const [editingRowId, setEditingRowId] = useState(null);
  const [editingRowData, setEditingRowData] = useState(null);
  const [editRowIdApi, setEditRowIdApi] = useState(null);
  const {
    control,
    reset,
    getValues,
    setValue,
    trigger,
    formState: { isValid, errors },
    watch,
  } = useForm({
    resolver: yupResolver(ContractBudgetPreviewSchema),
    mode: 'all',
  });
  const firstColumnInputRef = useRef(null);
  const secondColumnInputRef = useRef(null);
  const thirdColumnInputRef = useRef(null);
  const fourthColumnInputRef = useRef(null);
  const [stateError, setStateError] = useState(false);
  const [loader, setLoader] = useState(false);
  const [editingRowIndex, setEditingRowIndex] = useState(null);
  const [rowState, setRowState] = useState({
    editingRowId: null,
    newRowId: null,
    isCreatingRow: false,
  });

  const handleCancel = (row) => {
    if (row?.original?.vendorDocumentDeliveryNoteId === rowState?.newRowId) {
      setRows((prevRows) => {
        if (prevRows?.length > 0) {
          return prevRows?.slice(0, -1);
        }
        return prevRows;
      });
      setRowState((prevState) => ({
        ...prevState,
        newRowId: null,
        isCreatingRow: false,
      }));
    } else {
      setEditingRowId(null);
    }
    reset();
  };
  const handleEdit = (row) => {
    reset();
    if (rowState?.newRowId) {
      setRows((prevRows) => {
        if (prevRows?.length > 0) {
          return prevRows?.slice(0, -1);
        }
        return prevRows;
      });
      setRowState((prevState) => ({
        ...prevState,
        newRowId: null,
        isCreatingRow: false,
      }));
    }
    setEditingRowId(row?.vendorDocumentDeliveryNoteId);
    setEditRowIdApi(row?.vendorDocumentId);
    Object.entries({
      ...row,
    }).forEach(([key, value]) => {
      setValue(key, value);
    });
    setEditingRowData({ ...row });
  };

  useHotkeys(
    'Enter',
    () => handleAddNewRow(),
    {
      enableOnTags: ['INPUT', 'TEXTAREA'], // Optional: Enable hotkey only when focused on specific tags
    },
    [editingRowIndex, rows, stateError, loader]
  );

  useHotkeys(
    'Tab',
    (event) => {
      if (event.key !== 'Tab') return;
      if (stateError) {
        event.preventDefault();
        return;
      }
      const totalRows = rows.length;
      const nextRowIndex = editingRowIndex + 1;
      if (
        nextRowIndex < totalRows &&
        stateError === false &&
        loader === false
      ) {
        handleEdit(rows[nextRowIndex]);
        setEditingRowIndex(nextRowIndex);
      }
    },
    [editingRowIndex, rows, stateError, loader, isValid]
  );

  useEffect(() => {
    if (editingRowIndex !== null && firstColumnInputRef.current && !isLoading) {
      firstColumnInputRef.current.focus();
    }
  }, [editingRowIndex, isLoading]);

  const previousValues = useRef(getValues());
  const handleCancelAndAddNewRow = () => {
    const newId = 'new_row_' + Date.now();
    reset();
    setEditingRowData(null);
    setEditingRowId(null);
    setRowState({
      newRowId: newId,
      isCreatingRow: true,
    });
    setEditingRowIndex(
      editingRowIndex === rows?.length ? editingRowIndex + 1 : rows?.length
    );
    setRows((prevRows) => [
      ...prevRows,
      {
        vendorDocumentDeliveryNoteId: newId,
        vendorDocumentId: id,
        projectId: '',
        contractId: '',
        description: '',
        quantity: '',
        rate: '',
        amount: '',
        vat: '',
        remarks: '',
        isEditable: true,
        isNew: true,
      },
    ]);
    reset();
  };

  const handleBlur = () => {
    const currentValues = getValues();
    const projectId = getValues('projectId');
    const contractId = getValues('contractId');
    const description = getValues('description');
    const quantityField = getValues('quantity');

    if (!projectId) {
      firstColumnInputRef.current.focus();
      return;
    }
    if (!contractId) {
      secondColumnInputRef.current.focus();
      return;
    }
    if (!description) {
      thirdColumnInputRef.current.focus();
      return;
    }
    if (!quantityField) {
      fourthColumnInputRef.current.focus();
      return;
    }

    const quantity = Number(currentValues.quantity || 0);
    const rate = Number(currentValues.rate || 0);
    const amount = quantity * rate;

    currentValues.amount = amount;
    currentValues.totalAmount = amount * (1 + (currentValues.vat || 0) / 100);

    const hasChanges = Object.keys(currentValues).some(
      (key) => currentValues[key] !== previousValues.current[key]
    );
    if ((hasChanges && isValid) || (hasChanges && editingRowId)) {
      apiCallAsync(currentValues); // Call the API with the new values
      previousValues.current = currentValues; // Update previous values
    }
  };

  const apiCallAsync = async (values) => {
    setLoader(true);

    const convertedValues = {
      vendorDocumentId: Number(id),
      ...values,
      quantity: Number(values?.quantity),
      rate: Number(values?.rate),
      amount: Number(values?.amount),
      vat: Number(values?.vat),
    };

    if (editingRowId === null) {
      await crud.create(
        'vendordocumentdeliverynotes/vendordocument',
        convertedValues,
        (err, res) => {
          if (res?.status === 201) {
            setEditingRowId(res?.data?.vendorDocumentDeliveryNoteId);
            setEditRowIdApi(res?.data?.vendorDocumentId);
            setValue('vendorDocumentId', res?.data?.vendorDocumentId);
          } else {
          }
        }
      );
    } else {
      const updatedCombinedData = {
        vendorDocumentId: values?.vendorDocumentId,
        contractId: values?.contractId,
        projectId: values?.projectId,
        description: values?.description,
        quantity: Number(values?.quantity),
        rate: Number(values?.rate),
        amount: Number(values?.amount),
        vat: Number(values?.vat),
        remarks: values?.remarks,
        status: 2,
      };

      editingRowId &&
        (await crud.update(
          'vendordocumentdeliverynotes/vendordocument',
          editingRowId,
          updatedCombinedData,
          (err, res) => {
            if (res?.status === 200) {
              const totalRows = rows.length;
              if (
                editingRowIndex <= totalRows &&
                rows?.some((row) => row.isNew)
              ) {
                const updatedRow = res?.data;
                const updatedRows = [...rows];
                updatedRows[editingRowIndex] = {
                  ...updatedRow,
                  isEditable: true,
                  isNew: true,
                };
                setRows(updatedRows);
                setStateError(false);
                setLoader(false);
                // handleAddNewRow();
              } else {
                setStateError(false);
                setLoader(false);
                const updatedRow = res?.data;
                const updatedRows = [...rows];
                updatedRows[editingRowIndex] = updatedRow;
                setRows(updatedRows);
              }
            } else {
              setStateError(true);
            }
          }
        ));
    }
  };
  //final Step Api Call
  const handleBlurAndFetch = async () => {
    setLoader(true);

    const values = getValues();

    const updatedCombinedData = {
      projectId: values?.projectId,
      contractId: values?.contractId,
      description: values?.description,
      remarks: values?.remarks,
      quantity: Number(values?.quantity),
      rate: Number(values?.rate),
      amount: Number(values?.amount),
      vat: Number(values?.vat),
      status: 2,
      vendorDocumentId: editRowIdApi,
    };

    editingRowId &&
      (await crud.update(
        'vendordocumentdeliverynotes/vendordocument',
        editingRowId,
        updatedCombinedData,
        (err, res) => {
          if (res?.status === 200) {
            const totalRows = rows?.length;
            if (
              editingRowIndex <= totalRows &&
              rows?.some((row) => row.isNew)
            ) {
              const updatedRow = res?.data;
              const updatedRows = [...rows];
              updatedRows[editingRowIndex] = {
                ...updatedRow,
                isEditable: true,
                isNew: true,
              };
              setRows(updatedRows);
              handleAddNewRow();
            } else {
              const updatedRow = res?.data;
              const updatedRows = [...rows];
              updatedRows[editingRowIndex] = {
                ...updatedRow,
                isEditable: false,
                isNew: false,
              };
              setRows(updatedRows);
              setStateError(false);
              setLoader(false);
            }
          } else {
            setStateError(true);
          }
        }
      ));
  };
  const handleDeleteBudget = async (view) => {
    await crud.remove(
      'vendordocumentdeliverynotes',
      view?.vendorDocumentDeliveryNoteId,
      (err, res) => {
        if (res?.status === 204) {
          fetchData();
          toast.success('Delivery Note Deleted Successfully');
        } else {
        }
      }
    );
  };

  const handleInputChange = async (e, field) => {
    const value = parseFloat(e.target.value) || 0;
    const currentValues = getValues();
    const rate = watch('rate');
    const updatedValues = { ...currentValues, rate, [field]: value };

    const newAmount =
      (Number(updatedValues?.quantity) || 0) *
      (Number(updatedValues?.rate) || 0);
    const vatPercentage = (Number(updatedValues?.vat) || 0) / 100;
    const totalAmount = newAmount * (1 + vatPercentage);
    setValue(field, value);
    setValue('amount', newAmount);
    setValue('totalAmount', totalAmount);

    await trigger();
  };

  const ActionData = [
    //TODO:For Future Use
    // {
    //   name: 'View Details',
    //   icon: Icons.view,
    //   onClick: (props) => {
    //     handleView(props);
    //   },
    // },
    // {
    //   name: 'Edit',
    //   icon: Icons.edit,
    //   onClick: (props) => {
    //     handleEdit(props);
    //   },
    // },
    {
      name: 'Delete',
      icon: Icons.delete,
      onClick: (props) => handleDeleteBudget(props),
      danger: true,
    },
  ];

  const columns = [
    {
      accessorKey: 'projectId',
      header: 'Project',
      enableEditing: true,
      Cell: ({ row }) => {
        const isNewRow =
          row?.original?.vendorDocumentDeliveryNoteId === rowState?.newRowId;
        const isEditing =
          editingRowId === row?.original?.vendorDocumentDeliveryNoteId ||
          isNewRow;
        return isEditing ? (
          <FmSearchableSelect
            name='projectId'
            control={control}
            apiUrl='projects'
            valueField={'projectId'}
            headerField={['Code', 'Name']}
            labelField={['projectCode', 'projectName']}
            showField={['projectCode', 'projectName']}
            inputRef={firstColumnInputRef}
            label={'Project'}
            onBlur={handleBlur}
            defaultValue={{
              projectId: row?.original?.projectId,
              projectName: row?.original?.projectName,
            }}
          />
        ) : (
          row?.original?.projectName || ''
        );
      },
    },
    {
      accessorKey: 'contractId',
      header: 'Contract',
      enableEditing: true,
      Cell: ({ row }) => {
        const isNewRow =
          row?.original?.vendorDocumentDeliveryNoteId === rowState?.newRowId;
        const isEditing =
          editingRowId === row?.original?.vendorDocumentDeliveryNoteId ||
          isNewRow;
        return isEditing ? (
          <FmSearchableSelect
            name='contractId'
            control={control}
            apiUrl='contracts'
            valueField={'contractId'}
            headerField={['Code', 'Name']}
            labelField={['contractCode', 'contractName']}
            showField={['contractCode', 'contractName']}
            label={'Contract'}
            onBlur={handleBlur}
            inputRef={secondColumnInputRef}
            defaultValue={{
              contractId: row?.original?.contractId,
              contractName: row?.original?.contractName,
            }}
          />
        ) : (
          row?.original?.contractName || ''
        );
      },
    },
    {
      accessorKey: 'description',
      header: 'Description',
      enableEditing: true,
      Cell: ({ row }) => {
        const isNewRow =
          row?.original?.vendorDocumentDeliveryNoteId === rowState?.newRowId;
        const isEditing =
          editingRowId === row?.original?.vendorDocumentDeliveryNoteId ||
          isNewRow;
        return isEditing ? (
          <FmTextField
            name='description'
            control={control}
            rows={2}
            maxRows={3}
            onBlur={handleBlur}
            inputRef={thirdColumnInputRef}
          />
        ) : (
          row?.original?.description
        );
      },
    },
    {
      accessorKey: 'quantity',
      header: 'Quantity',
      enableEditing: true,
      Cell: ({ row }) => {
        const isNewRow =
          row?.original?.vendorDocumentDeliveryNoteId === rowState?.newRowId;
        const isEditing =
          editingRowId === row?.original?.vendorDocumentDeliveryNoteId ||
          isNewRow;
        return isEditing ? (
          <FmTextField
            name='quantity'
            control={control}
            onBlur={handleBlur}
            type='Number'
            inputRef={fourthColumnInputRef}
            onChange={(e) => handleInputChange(e, 'quantity')}
          />
        ) : (
          row?.original?.quantity
        );
      },
    },
    {
      accessorKey: 'rate',
      header: 'Rate',
      enableEditing: true,
      Cell: ({ row }) => {
        const isNewRow =
          row?.original?.vendorDocumentDeliveryNoteId === rowState?.newRowId;
        const isEditing =
          editingRowId === row?.original?.vendorDocumentDeliveryNoteId ||
          isNewRow;
        return isEditing ? (
          <FmCurrencyTextField
            name='rate'
            control={control}
            label='Rate'
            onBlur={handleBlur}
          />
        ) : (
          <CurrencyView value={row?.original?.rate} />
        );
      },
    },
    {
      accessorKey: 'amount',
      header: 'Amount',
      enableEditing: true,
      Cell: ({ row }) => {
        const isNewRow =
          row?.original?.vendorDocumentDeliveryNoteId === rowState?.newRowId;
        const isEditing =
          editingRowId === row?.original?.vendorDocumentDeliveryNoteId ||
          isNewRow;

        return isEditing ? (
          <FmCurrencyTextField
            control={control}
            className='border-0 focus:ring-0 focus:outline-none bg-transparent'
            name='amount'
            disabled
          />
        ) : (
          <CurrencyView value={row?.original?.amount} />
        );
      },
    },
    {
      accessorKey: 'vat',
      header: 'VAT',
      enableEditing: true,
      Cell: ({ row }) => {
        const isNewRow =
          row?.original?.vendorDocumentDeliveryNoteId === rowState?.newRowId;
        const isEditing =
          editingRowId === row?.original?.vendorDocumentDeliveryNoteId ||
          isNewRow;
        return isEditing ? (
          <FmTextField
            name='vat'
            control={control}
            onBlur={handleBlur}
            type='Number'
            onChange={(e) => handleInputChange(e, 'vat')}
          />
        ) : (
          row?.original?.vat
        );
      },
    },
    {
      accessorKey: 'totalAmount',
      header: 'Total Amount',
      enableEditing: true,
      Cell: ({ row }) => {
        const isNewRow =
          row?.original?.vendorDocumentDeliveryNoteId === rowState?.newRowId;
        const isEditing =
          editingRowId === row?.original?.vendorDocumentDeliveryNoteId ||
          isNewRow;

        return isEditing ? (
          <FmCurrencyTextField
            control={control}
            className='border-0 focus:ring-0 focus:outline-none bg-transparent'
            name='totalAmount'
            disabled
          />
        ) : (
          <CurrencyView value={row?.original?.totalAmount} />
        );
      },
    },
    {
      accessorKey: 'remarks',
      header: 'Remarks',
      enableEditing: true,
      Cell: ({ row }) => {
        const isNewRow =
          row?.original?.vendorDocumentDeliveryNoteId === rowState?.newRowId;
        const isEditing =
          editingRowId === row?.original?.vendorDocumentDeliveryNoteId ||
          isNewRow;
        return isEditing ? (
          <FmTextField
            name='remarks'
            control={control}
            onBlur={handleBlurAndFetch}
            rows={2}
            maxRows={3}
          />
        ) : (
          row?.original?.remarks
        );
      },
    },
    {
      header: 'Action',
      enableColumnPinning: true,
      enableEditing: false,
      enableSorting: false,
      size: 110,
      muiTableBodyCellProps: {
        sx: {
          borderLeft: '1px solid',
          borderColor: 'border.dark',
        },
      },
      Cell: ({ row, table }) => {
        const isNewRow =
          row?.original?.vendorDocumentDeliveryNoteId === rowState?.newRowId;
        const isEditing =
          editingRowId === row?.original?.vendorDocumentDeliveryNoteId ||
          isNewRow;
        // Check if the current row is the last row in the table
        const isLastRow = row.index === table.getRowModel().rows.length - 1;
        return (
          <Box className='flex gap-2'>
            {isEditing ? (
              <>
                <IconButton onClick={() => handleCancel(row)} error>
                  {Icons.clear}
                </IconButton>

                {isLastRow && rowState?.isCreatingRow === false && (
                  <Button
                    onFocus={() => handleCancelAndAddNewRow()}
                    hidden={true}
                  ></Button>
                )}
              </>
            ) : (
              <Box className='flex gap-2'>
                <TableActionButtons
                  Actions={ActionData?.map((action) => ({
                    ...action,
                    onClick: () => action.onClick(row?.original),
                  }))}
                />
              </Box>
            )}
          </Box>
        );
      },
    },
  ];
  const handleAddNewRow = () => {
    const newId = 'new_row_' + Date.now();
    reset();
    setEditingRowData(null);
    setEditingRowId(null);
    setRowState({
      newRowId: newId,
      isCreatingRow: true,
    });
    setEditingRowIndex(
      editingRowIndex === rows.length ? editingRowIndex + 1 : rows.length
    );
    setRows((prevRows) => [
      ...prevRows,
      {
        vendorDocumentDeliveryNoteId: newId,
        vendorDocumentId: id,
        projectId: '',
        contractId: '',
        description: '',
        quantity: '',
        rate: '',
        amount: '',
        vat: '',
        remarks: '',
        isEditable: true,
        isNew: true,
      },
    ]);
    reset();
  };
  return (
    <>
      <GlassCard className='h-full overflow-hidden'>
        <FmMRTDataTable
          rows={rows}
          columns={columns}
          onCreateRow={handleAddNewRow}
          isCreatingRow={rowState?.isCreatingRow}
          enableRowSelection={false}
          rowCount={tableRecordCounts}
          editingRow={editingRowId}
          fetchData={fetchData}
          pagination={pagination}
          sorting={sorting}
          columnFilters={columnFilters}
          globalFilter={globalFilter}
          setPagination={setPagination}
          setSorting={setSorting}
          setColumnFilters={setColumnFilters}
          setGlobalFilter={setGlobalFilter}
          isLoading={isLoading}
          setSearchKeyword={setSearchKeyword}
          searchKeyword={searchKeyword}
          handleRowDoubleClick={(row) => {
            handleEdit(row?.original);
            setEditingRowIndex(row?.index);
          }}
          muiTableContainerProps={{
            sx: {
              maxHeight: '100%',
              flex: 1,
            },
          }}
        />
      </GlassCard>
    </>
  );
};
export default VendorDocumentsPreviewTable;
