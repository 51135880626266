import React, { ChangeEvent, RefObject } from 'react';
import { Box, TextField, InputAdornment } from '@mui/material';
import { useSelector } from 'react-redux';
import { Control, Controller, FieldValues, Path } from 'react-hook-form';

interface CurrencySettings {
  [key: string]: {
    intlConfig?: { locale: string; currency: string };
    formatValue?: (val: number) => string;
    prefix?: string;
    groupSeparator?: string;
    decimalSeparator?: string;
    decimalsLimit?: number;
  };
}

interface FmCurrencyTextFieldProps<T extends FieldValues> {
  name: string;
  control: Control<T>;
  label?: string;
  onChange?: (e: ChangeEvent<HTMLInputElement>) => void;
  onBlur?: () => void;
  pattern?: 'Number' | 'Decimal';
  prefix?: string;
  suffix?: string;
  readOnly?: boolean;
  required?: boolean;
  disabled?: boolean;
  labelRemove?: boolean;
  inputRef?: RefObject<HTMLInputElement>;
  type?: string;
  defaultValue?: string | number;
  className?: string;
}

const handleInputChange = (
  e: React.ChangeEvent<HTMLInputElement>,
  pattern?: string,
  prefix?: string,
  suffix?: string
): string => {
  let inputValue = e.target.value;
  if (prefix && inputValue.startsWith(prefix)) {
    inputValue = inputValue.substring(prefix.length);
  }
  if (suffix && inputValue.endsWith(suffix)) {
    inputValue = inputValue.slice(0, -suffix.length);
  }

  let filteredValue = inputValue;

  switch (pattern) {
    case 'Number':
      filteredValue = inputValue.replace(/[^\d]/g, '');
      break;
    case 'Decimal': {
      filteredValue = inputValue.replace(/[^\d.]/g, '');

      const parts = filteredValue.split('.');

      if (parts.length > 2) {
        const integerPart = parts.shift();
        const decimalPart = parts.join('');
        filteredValue = integerPart + '.' + decimalPart;
      } else {
        parts[0] = parts[0].slice(0, 18);

        if (parts[1]) {
          parts[1] = parts[1].slice(0, 5);
        }

        filteredValue = parts.join('.');
      }

      break;
    }
    default:
      filteredValue = inputValue;
      break;
  }

  return `${filteredValue}${suffix || ''}`;
};

const FmCurrencyTextField = <T extends FieldValues>({
  name,
  control,
  label,
  onChange,
  onBlur,
  pattern,
  prefix,
  suffix,
  readOnly = false,
  required = false,
  disabled = false,
  labelRemove = false,
  inputRef,
  type = 'text',
  defaultValue,
  className,
}: FmCurrencyTextFieldProps<T>) => {
  const currency = useSelector((state: any) => state?.settings?.currency);

  const currencySettings: CurrencySettings = {
    INR: {
      intlConfig: { locale: 'en-IN', currency: 'INR' },
      formatValue: (val) =>
        new Intl.NumberFormat('en-IN', {
          style: 'currency',
          currency: 'INR',
          minimumFractionDigits: 0,
        }).format(val || 0),
    },
    AED: {
      prefix: 'AED ',
      groupSeparator: ',',
      decimalSeparator: '.',
      decimalsLimit: 2,
    },
  };

  const getFormattedValue = (value: string | number | undefined): string => {
    if (value === null || value === undefined || value === '') {
      return '0'; // Default to '0' if value is empty
    }

    let formattedValue = value.toString();

    Object.keys(currencySettings).forEach((currencyKey) => {
      if (currency === currencyKey && value) {
        const settings = currencySettings[currencyKey];

        if (currencyKey === 'INR') {
          const parsedValue = parseFloat(value.toString());
          formattedValue = settings.formatValue!(parsedValue);
        } else if (currencyKey === 'AED') {
          formattedValue = value
            .toString()
            .replace(/\B(?=(\d{3})+(?!\d))/g, settings.groupSeparator!);
          formattedValue = `${formattedValue}`;
        }
      }
    });

    return formattedValue;
  };

  return (
    <Box className="flex w-full items-center gap-4">
      <Box className="flex-1">
        <Controller
          name={name}
          control={control}
          defaultValue={defaultValue as any} // Pass defaultValue to Controller
          render={({ field, fieldState: { error } }) => {
            const formattedValue = getFormattedValue(field.value);
            return (
              <TextField
                {...field}
                id="currency-input"
                name={name}
                type="decimal"
                className={`currency-mui-styles ${labelRemove && 'removeLabelMui'
                  }`}
                label={label ? label : `Enter amount in ${currency}`}
                value={formattedValue}
                inputRef={inputRef}
                onChange={(e: ChangeEvent<HTMLInputElement>) => {
                  const fullValue = handleInputChange(e, pattern, prefix, suffix);
                  field.onChange(fullValue);
                  onChange?.(e);
                }}
                required={required}
                error={!!error}
                helperText={error?.message || ''}
                inputProps={{
                  ...(readOnly ? { readOnly: true } : { min: 0 }),
                }}
                fullWidth
                variant="filled"
                onBlur={() => {
                  field.onBlur();
                  onBlur?.();
                }}
                disabled={disabled}
                InputProps={{
                  startAdornment: (
                    <InputAdornment position="start">
                      <Box className="font-semibold" color={'text.dark'}>
                        {currency}
                      </Box>
                    </InputAdornment>
                  ),
                }}
              />
            );
          }}
        />
      </Box>
    </Box>
  );
};

export default FmCurrencyTextField;