import { Box, Tooltip } from '@mui/material';
import React, { useMemo } from 'react';
import { ViewTable } from '../_form/FormElements.jsx';

const TableExtraContentTooltip = ({ children, titleRows, dark }) => {
  // Memoized TitleContent to prevent unnecessary recalculations
  const TitleContent = useMemo(
    () => (
      <Box className='min-w-48 p-2 flex flex-col gap-1 min-h-4'>
        <ViewTable disableBorder paddingLow right rows={titleRows} />
      </Box>
    ),
    [titleRows]
  ); // Only recompute when titleRows changes

  return (
    <Tooltip
      title={TitleContent}
      disableInteractive // Uses memoized tooltip content
      placement='left'
      enterDelay={300}
      leaveDelay={100}
      arrow
      classes={{
        tooltip: 'px-0 py-1 rounded-lg',
        arrow: dark ? 'text.dark' : 'text-white',
      }}
      slotProps={{
        tooltip: {
          sx: {
            background: dark ? 'text.dark' : '#fff',
            boxShadow: dark ? 0 : 2,
            color: dark ? '#fff' : 'text.main',
          },
        },
      }}
    >
      <Box className='w-full'>{children}</Box>
    </Tooltip>
  );
};

export default TableExtraContentTooltip;
