import React from 'react';
import {
  TableContainer,
  Table,
  TableHead,
  TableRow,
  TableBody,
  TableCell,
} from '@mui/material';
import FmViewDateField from '../_mui/FmViewDateField.tsx';

const StaticTable = ({ columns, data }) => {
  return (
    <>
      <TableContainer>
        <Table
          sx={{
            border: '1px solid',
            borderColor: 'border.main',
            '& th, & td': {
              padding: '7px',
              fontSize: 'inherit',
              color: 'inherit',
              border: '1px solid',
              borderColor: 'border.main',
            },
          }}
        >
          <TableHead>
            <TableRow sx={{ bgcolor: 'background.light' }}>
              {columns.map((column) => (
                <TableCell
                  sx={{ lineHeight: '16px', whiteSpace: 'nowrap' }}
                  key={column.field}
                >
                  {column.headerName}
                </TableCell>
              ))}
            </TableRow>
          </TableHead>
          <TableBody>
            {data?.map((row, rowIndex) => (
              <TableRow key={rowIndex} className={`${row.color}`}>
                {columns?.map((column) => (
                  <TableCell key={column?.field}>
                    {column.field === 'reqDate' ? (
                      <FmViewDateField value={row[column?.field]} />
                    ) : (
                      row[column?.field]
                    )}
                  </TableCell>
                ))}
              </TableRow>
            ))}
          </TableBody>
        </Table>
      </TableContainer>
    </>
  );
};

const StaticTableDashboard = ({ columns, data, color }) => {
  return (
    <>
      <TableContainer>
        <Table
          sx={{
            border: '1px solid',
            borderColor: 'border.main',
            '& th, & td': {
              padding: '10px',
              color: 'inherit',
              fontSize: 'inherit',
            },
          }}
        >
          <TableHead>
            <TableRow sx={{ bgcolor: 'background.light' }}>
              {columns.map((column) => (
                <TableCell
                  sx={{ lineHeight: '16px', whiteSpace: 'nowrap' }}
                  key={column.field}
                >
                  {column.headerName}
                </TableCell>
              ))}
            </TableRow>
          </TableHead>
          <TableBody>
            {data.map((row, rowIndex) => (
              <TableRow
                key={rowIndex}
                className={`${row.color} cursor-pointer`}
              >
                {columns.map((column) => (
                  <TableCell key={column.field}>{row[column.field]}</TableCell>
                ))}
              </TableRow>
            ))}
          </TableBody>
        </Table>
      </TableContainer>
    </>
  );
};

export default StaticTable;
export { StaticTableDashboard };
