import React, { useEffect, useMemo, useState } from 'react';
import { Box, Button, Grid, IconButton, Typography } from '@mui/material';
import { useForm, useFieldArray } from 'react-hook-form';
import FmTextField from '../../../../../components/_mui/FmTextField.tsx';
import useLoader from '../../../../../components/helpers/UseLoader.tsx';
import { Icon } from '@iconify/react';
import Crud_Service from '../../../../../apis/CrudService.jsx';
import CommonLoader from '../../../../../components/page/CommonLoader.jsx';
import ActionButtons from '../../../../../components/_form/ActionButtons.tsx';
import FmSearchableSelect from '../../../../../components/_mui/FmSearchableSelect.tsx';
import * as yup from 'yup';
import { yupResolver } from '@hookform/resolvers/yup';
import GlassCard from '../../../../../components/small/GlassCard.tsx';
import { toast } from 'react-toastify';
import ConfirmationPopoverBox from '../../../../../components/_form/ConfirmationPopoverBox.tsx';

interface InternalBudgetSupplierForm {
  upsertInternalBudgetingSuppliers: {
    supplierId: string;
    officialSupplierName: string;
    amount: number;
    internalBudgetingSupplierId?: number;
  }[];
}

// Update the schema with better validation
const schema = yup.object().shape({
  upsertInternalBudgetingSuppliers: yup
    .array()
    .of(
      yup.object().shape({
        supplierId: yup.string().required('Supplier is required'),
        amount: yup
          .number()
          .transform((value) => (isNaN(value) ? undefined : value))
          .required('Amount is required')
          .min(0.01, 'Amount must be greater than 0'),
        internalBudgetingSupplierId: yup.number().optional(),
      })
    )
    .required('At least one supplier is required'),
});

const InternalBudgetSupplier = (props) => {
  const { setOpen, id, rows, addSuppliers } = props;

  const [loading, setLoading] = useState(false);

  const existingSuppliers = useMemo(() => {
    return rows[id]?.upsertInternalBudgetingSuppliers || [];
  }, [rows, id]);

  const {
    handleSubmit,
    control,
    watch,
    setValue,
    reset,
    formState: { errors, isValid },
  } = useForm<InternalBudgetSupplierForm>({
    mode: 'all',
    resolver: yupResolver(schema),
    defaultValues: {
      upsertInternalBudgetingSuppliers: existingSuppliers.length
        ? existingSuppliers
        : [
            {
              internalBudgetingSupplierId: 0,
              supplierId: '',
              officialSupplierName: '',
              amount: 0,
            },
          ],
    },
  });

  const [balances, setBalances] = useState({
    supplierTotal: 0,
    remaining: rows[id]?.balance,
  });

  const { fields, append, remove } = useFieldArray({
    control,
    name: 'upsertInternalBudgetingSuppliers',
  });

  const handleValueChange = (index: number, value: string) => {
    const numValue = parseFloat(value) || 0;
    setValue(`upsertInternalBudgetingSuppliers.${index}.amount`, numValue);
    updateBalances();
  };

  const handleTabKeyPress = (e: React.KeyboardEvent) => {
    if (e.key === 'Tab') {
      append({
        supplierId: '',
        officialSupplierName: '',
        amount: 0,
        internalBudgetingSupplierId: 0,
      });
    }
  };

  const handleSupplierAdd = async (values: InternalBudgetSupplierForm) => {
    try {
      setLoading(true);

      // Validate total amount against balance
      const totalAmount = values.upsertInternalBudgetingSuppliers.reduce(
        (sum, supplier) => sum + (Number(supplier.amount) || 0),
        0
      );

      if (totalAmount > rows[id]?.balance) {
        toast.error('Total supplier amount cannot exceed the balance');
        return;
      }

      // Call the parent component's addSuppliers function
      await addSuppliers(values, id);
      setOpen(0);
    } catch (error) {
      console.error('Error submitting form:', error);
      alert('Error submitting form. Please try again.');
    } finally {
      setLoading(false);
    }
  };

  const updateBalances = () => {
    const upsertInternalBudgetingSuppliers = watch(
      'upsertInternalBudgetingSuppliers'
    );
    const totalSupplierAmount = upsertInternalBudgetingSuppliers?.reduce(
      (total, item) => {
        return total + (parseFloat(item.amount) || 0);
      },
      0
    );

    const remainingBalance = rows[id]?.balance - totalSupplierAmount;
    setBalances({
      supplierTotal: totalSupplierAmount,
      remaining: remainingBalance,
    });
  };

  useEffect(() => {
    updateBalances();
  }, [existingSuppliers, rows[id]?.balance]);

  // Add this useEffect to debug form validation
  useEffect(() => {
    if (Object.keys(errors).length > 0) {
    }
  }, [errors]);

  return (
    <div>
      {loading ? (
        <CommonLoader />
      ) : (
        <form onSubmit={handleSubmit(handleSupplierAdd)} noValidate>
          {fields?.map((item, index) => {
            return (
              <React.Fragment key={item.id}>
                <Box className='mb-4'>
                  <Grid container spacing={2}>
                    <Grid item md={7}>
                      <FmSearchableSelect
                        name={`upsertInternalBudgetingSuppliers.${index}.supplierId`}
                        control={control}
                        apiUrl='suppliers'
                        valueField={'supplierId'}
                        headerField={['Code', 'Name']}
                        labelField={['supplierCode', 'officialSupplierName']}
                        showField={['supplierCode', 'officialSupplierName']}
                        label={'Supplier'}
                        error={
                          !!errors?.upsertInternalBudgetingSuppliers?.[index]
                            ?.supplierId
                        }
                        helperText={
                          errors?.upsertInternalBudgetingSuppliers?.[index]
                            ?.supplierId?.message
                        }
                        defaultValue={{
                          officialSupplierName: item.supplierName,
                        }}
                        onChangeProp={(selectedSupplier) => {
                          setValue(
                            `upsertInternalBudgetingSuppliers.${index}.supplierId`,
                            selectedSupplier.supplierId
                          );
                          setValue(
                            `upsertInternalBudgetingSuppliers.${index}.officialSupplierName`,
                            selectedSupplier.officialSupplierName
                          );
                        }}
                      />
                    </Grid>
                    <Grid item md={3}>
                      <FmTextField
                        name={`upsertInternalBudgetingSuppliers.${index}.amount`}
                        control={control}
                        pattern='Number'
                        label='Amount'
                        error={
                          !!errors?.upsertInternalBudgetingSuppliers?.[index]
                            ?.amount
                        }
                        helperText={
                          errors?.upsertInternalBudgetingSuppliers?.[index]
                            ?.amount?.message
                        }
                        onKeyDown={handleTabKeyPress}
                        onChange={(e) => {
                          handleValueChange(index, e.target.value);
                        }}
                        className='w-full'
                        autocomplete='off'
                      />
                    </Grid>
                    <Grid item md={2}>
                      <Box className='flex justify-end'>
                        <ConfirmationPopoverBox
                          icon={
                            <Icon icon='mdi:trash' width='24' height='24' />
                          }
                          onConfirm={() => {
                            remove(index);
                            updateBalances();
                          }}
                          title='Delete Supplier'
                          message='Are you sure you want to delete this supplier?'
                          disabled={fields.length === 1}
                        />

                        {/* <IconButton
                         
                          disabled={fields.length === 1}
                          sx={{ padding: '1px' }}
                        >
                         <Icon icon="mdi:trash" width="24" height="24" />
                        </IconButton> */}
                      </Box>
                    </Grid>
                  </Grid>
                </Box>
              </React.Fragment>
            );
          })}

          {/* Display Total Supplier Amount and Remaining Balance */}
          <GlassCard className='mt-5 p-2' bgLight>
            <Grid container spacing={3}>
              <Grid item md={4}>
                <Box>
                  <Box>Total Supplier Amount:</Box>
                  <Box className='font-semibold text-green-600'>
                    {balances.supplierTotal} AED
                  </Box>
                </Box>
              </Grid>
              <Grid item md={4}>
                <Box>
                  <Box>Remaining Balance:</Box>
                  <Box className='font-semibold text-red-600'>
                    {balances.remaining || 0} AED
                  </Box>
                </Box>
              </Grid>
              <Grid item md={4}>
                <Box className='flex justify-end'>
                  <Button
                    variant='text'
                    onClick={() => {
                      append({
                        supplierId: '',
                        officialSupplierName: '',
                        amount: 0,
                        internalBudgetingSupplierId: 0,
                      });
                      updateBalances();
                    }}
                    className='uppercase font-bold text-orange-500'
                    startIcon={<Icon icon='ic:baseline-plus' />}
                  >
                    Add Supplier
                  </Button>
                </Box>
              </Grid>
            </Grid>
          </GlassCard>

          {/* Add error summary if needed */}
          {Object.keys(errors).length > 0 && (
            <Box sx={{ color: 'error.main', mt: 2 }}>
              Please fix the form errors before submitting
            </Box>
          )}

          <ActionButtons
            onReset={() => {
              reset();
              updateBalances();
            }}
            onSubmit={handleSubmit(handleSupplierAdd)}
            onCancel={() => setOpen(0)}
            // submitText={'Add Suppliers'}
            cancelLoading={loading}
            submitDisabled={!isValid || loading}
          />
        </form>
      )}
    </div>
  );
};

export default InternalBudgetSupplier;
