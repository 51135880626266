import React, { useEffect, useState } from 'react';
import GlassCard from '../../../../components/small/GlassCard.tsx';
import { Grid } from '@mui/material';
import { useForm, useWatch } from 'react-hook-form';
import FmTextField from '../../../../components/_mui/FmTextField.tsx';
import FmDatePicker from '../../../../components/_mui/FmDatePicker.tsx';
import FmSearchableSelect from '../../../../components/_mui/FmSearchableSelect.tsx';
import ActionButtons from '../../../../components/_form/ActionButtons.tsx';
import Crud_Service from '../../../../apis/CrudService.jsx';
import dayjs from 'dayjs';
import { toast } from 'react-toastify';
import useLoader from '../../../../components/helpers/UseLoader.tsx';
import { yupResolver } from '@hookform/resolvers/yup';
import { CBPettyCashValidationSchema } from '../../../../utils/CommonvalidationSchemas.tsx';
import { useNavigate, useParams } from 'react-router-dom';
import FmAutoComplete from '../../../../components/_mui/FmAutoComplete.tsx';
import useCommonFetchApi from '../../../../components/helpers/useCommonFetchApi.tsx';
import ListViewPettyCashItem from './View/ListViewPettyCashItem.tsx';
import { pettyCashStatusEnum } from '../../../../utils/CommonVariables.tsx';

const GridSize = 2.4;

const EditPettyCash = ({ setExchangeRate, exchangeRate }) => {
  const crud = new Crud_Service();
  const id = useParams().id;
  const navigate = useNavigate();
  const { startCreateLoading, stopCreateLoading } = useLoader();
  const { createLoading } = useLoader();
  const [cbr, setCbr] = useState({});
  const [selectedCompany, setSelectedCompany] = useState(null);
  const { control, handleSubmit, reset, watch } = useForm({
    resolver: yupResolver(CBPettyCashValidationSchema),
    mode: 'onChange',
  });
  const { data: pettycashStatus } = useCommonFetchApi(
    'enums/petty-cash-status'
  );

  useEffect(() => {
    fetchData();
  }, [id]);
  const fetchData = async () => {
    await crud.getSingle('pettycashtransactions', id, (err, res) => {
      if (err) {
        return;
      }
      if (res?.status === 200) {
        reset(res?.data);
        setCbr(res?.data);
      } else {
      }
    });
  };
  const handleSubmitData = async (values) => {
    startCreateLoading();
    const combinedData = {
      ...values,
      requestDate: dayjs(values?.requestDate).format('YYYY-MM-DD'),
    };
    await crud.update('pettycashtransactions', id, combinedData, (err, res) => {
      stopCreateLoading();
      if (err) {
        return;
      }
      if (res?.status === 200) {
        toast.success('Petty Cash Updated successfully');
      } else {
      }
    });
  };
  useEffect(() => {
    setExchangeRate(watch('exchangeRate'));
  }, [watch('exchangeRate')]);

  return (
    <>
      <GlassCard className='p-3'>
        <form onSubmit={handleSubmit(handleSubmitData)}>
          <Grid container spacing={2}>
            <Grid item md={GridSize}>
              <FmSearchableSelect
                name='bankId'
                control={control}
                apiUrl='chartofaccounts'
                valueField='chartOfAccountId'
                labelField={['chartOfAccountName', 'chartOfAccountCode']}
                showField={['chartOfAccountName', 'chartOfAccountCode']}
                label='Account'
                defaultValue={{
                  bankId: cbr?.bankId,
                  chartOfAccountName: cbr?.bankName,
                  chartOfAccountCode: cbr?.bankCode,
                }}
                disabled={
                  cbr?.pettyCashStatusId === pettyCashStatusEnum?.Approved ||
                  cbr?.pettyCashStatusId === pettyCashStatusEnum?.Paid
                }
                required
              />
            </Grid>

            <Grid item md={GridSize}>
              <FmSearchableSelect
                name='companyId'
                control={control}
                apiUrl='companies'
                valueField='companyId'
                onChangeProp={(ev) => setSelectedCompany(ev)}
                labelField={['companyCode', 'companyName']}
                showField={['companyName']}
                label='Company'
                defaultValue={{
                  companyId: cbr?.companyId,
                  companyName: cbr?.companyName,
                }}
                disabled={
                  cbr?.pettyCashStatusId === pettyCashStatusEnum?.Approved ||
                  cbr?.pettyCashStatusId === pettyCashStatusEnum?.Paid
                }
                required
              />
            </Grid>

            <Grid item md={GridSize}>
              <FmDatePicker
                name='requestDate'
                label='Request Date'
                control={control}
                disabled={
                  cbr?.pettyCashStatusId === pettyCashStatusEnum?.Approved ||
                  cbr?.pettyCashStatusId === pettyCashStatusEnum?.Paid
                }
              />
            </Grid>

            <Grid item md={GridSize}>
              <FmSearchableSelect
                name='branchId'
                control={control}
                apiUrl='branches'
                valueField='branchId'
                labelField={['branchCode', 'branchName']}
                showField={['branchCode', 'branchName']}
                defaultValue={{
                  branchId: cbr?.branchId,
                  branchName: cbr?.branchName,
                }}
                disabled={
                  cbr?.pettyCashStatusId === pettyCashStatusEnum?.Approved ||
                  cbr?.pettyCashStatusId === pettyCashStatusEnum?.Paid
                }
                label={'Branch'}
              />
            </Grid>
            <Grid item md={GridSize}>
              <FmSearchableSelect
                name='jurisdictionId'
                control={control}
                apiUrl='jurisdictions'
                valueField='jurisdictionId'
                labelField={['jurisdictionCode', 'jurisdictionName']}
                showField={['jurisdictionCode', 'jurisdictionName']}
                defaultValue={{
                  jurisdictionId: cbr?.jurisdictionId,
                  jurisdictionName: cbr?.jurisdictionName,
                }}
                disabled={
                  cbr?.pettyCashStatusId === pettyCashStatusEnum?.Approved ||
                  cbr?.pettyCashStatusId === pettyCashStatusEnum?.Paid
                }
                label={'Jurisdiction'}
              />
            </Grid>
            <Grid item md={GridSize}>
              <FmTextField
                name='narration'
                multiline={true}
                rows={2}
                maxRows={3}
                control={control}
                label='Narration'
                disabled={
                  cbr?.pettyCashStatusId === pettyCashStatusEnum?.Approved ||
                  cbr?.pettyCashStatusId === pettyCashStatusEnum?.Paid
                }
              />
            </Grid>
            {selectedCompany?.exchangeRate && (
              <Grid item md={GridSize}>
                <FmTextField
                  name='exchangeRate'
                  type='number'
                  control={control}
                  label='Exchange Rate'
                  disabled={
                    cbr?.pettyCashStatusId === pettyCashStatusEnum?.Approved ||
                    cbr?.pettyCashStatusId === pettyCashStatusEnum?.Paid
                  }
                />
              </Grid>
            )}
            <Grid item md={GridSize}>
              <FmAutoComplete
                name='pettyCashStatusId'
                control={control}
                options={pettycashStatus}
                label='Status'
                displayField='displayName'
                optionFields={['displayName']}
                valueKey='key'
                disabled={
                  cbr?.pettyCashStatusId === pettyCashStatusEnum?.Approved ||
                  cbr?.pettyCashStatusId === pettyCashStatusEnum?.Paid
                }
              />
            </Grid>
            {
              !(
                cbr?.pettyCashStatusId ===
                pettyCashStatusEnum?.Approved || cbr?.pettyCashStatusId ===
                pettyCashStatusEnum?.Paid
              ) && (
                <Grid item md={GridSize * 4}>
                  <ActionButtons
                    onSubmit={handleSubmit(handleSubmitData)}
                    onCancel={() =>
                      navigate(`/financial/cash-and-bank/petty-cash`)
                    }
                    onReset={reset}
                    submitLoading={createLoading}
                    submitText='Update'
                  />
                </Grid>
              )
            }

          </Grid>
        </form>
      </GlassCard>
      <ListViewPettyCashItem
        pettyCashTransactionId={id}
        exchangeRate={exchangeRate}
        pettyCashStatusId={cbr?.pettyCashStatusId}
      />
    </>
  );
};

export default EditPettyCash;
