import React, { useEffect, useState } from 'react';
import DashboardLayout from '../../../../components/DashboardLayout.tsx';
import GlassCard from '../../../../components/small/GlassCard.tsx';
import BackToButton from '../../../../components/small/BackToButton.jsx';
import { Tab, Tabs, Box, Stack, Divider } from '@mui/material';
import { useNavigate, useParams } from 'react-router-dom';
import ViewContractCustomer from './ViewContractCustomer.tsx';
import ViewContractLocationList from './ViewContractLocationList.tsx';
import ViewContractProjectTeam from './ViewContractProjectTeam.tsx';
import ViewContractRevenue from './ViewContractRevenue.tsx';
import ViewContractCost from './ViewContractCost.tsx';
import ViewContractSubContract from './ViewContractSubContract.tsx';
import ViewContractSchedule from './ViewContractSchedule.tsx';
import Crud_Service from '../../../../apis/CrudService.jsx';
import ElementLoader from './../../../../components/page/ElementLoader.tsx';
import { ViewField } from '../../../../components/_form/FormElements.jsx';
import ViewStartEndDate from './../../../../components/view/ViewStartEndDate.tsx';
import FmViewDateField from './../../../../components/_mui/FmViewDateField.tsx';
import TableAvatar from '../../../../components/_form/TableAvatar.jsx';
import ViewChipText from '../../../../components/view/ViewChipText.tsx';
import ViewContractRevenueSchedule from './ViewRevenueSchedule.tsx';

const navMenu = [
  {
    id: 1,
    name: 'Customer',
  },
  {
    id: 2,
    name: 'Location',
  },
  {
    id: 3,
    name: 'Project Team',
  },
  // TODO: Add revenue and cost tabs when required
  // {
  //   id: 4,
  //   name: 'Revenue Tab',
  // },
  // {
  //   id: 5,
  //   name: 'Cost Tab',
  // },
  {
    id: 6,
    name: 'Sub Contractors',
  },
  {
    id: 7,
    name: 'Schedule',
  },
  {
    id: 8,
    name: 'Revenue Schedule',
  },
];

const ViewContracts = () => {
  const [value, setValue] = useState(navMenu[0]?.id || 0);
  const id = useParams().id;
  const crud = new Crud_Service();
  const navigation = useNavigate();
  const handleChange = (_event, newValue) => {
    setValue(newValue);
  };
  const [contractData, setContractData] = useState({});
  const [loader, setLoader] = useState(false);

  const fetchContractData = async () => {
    await crud.getSingle('contracts', id, (_err, res) => {
      if (res?.status === 200) {
        setContractData(res?.data);
        setLoader(false);
      } else {
      }
    });
  };
  useEffect(() => {
    document.title = 'View | Contract | Presales';
    fetchContractData();
    setLoader(true);
  }, []);

  return (
    <DashboardLayout
      title={contractData?.contractCode}
      actionButtons={
        <BackToButton
          onClick={() => navigation(-1)}
          title='Back to Contract'
          className='p-0'
        />
      }
      titleReverse
    >
      <GlassCard className='p-3 mb-2'>
        {loader ? (
          <div className='flex justify-center'>
            <ElementLoader />
          </div>
        ) : (
          <>
            <Box>
              <Stack
                direction={'row'}
                spacing={2}
                divider={<Divider orientation='vertical' flexItem />}
                flexWrap={'wrap'}
              >
                <Box className='flex flex-col gap-2'>
                  <Box className='font-bold text-base' color={'primary.main'}>
                    {contractData?.contractName}
                  </Box>
                  <Box>{contractData?.contractCode}</Box>
                  <Box>
                    <ViewStartEndDate
                      start={
                        <FmViewDateField
                          value={contractData?.contractStartDate}
                        />
                      }
                      end={
                        <FmViewDateField
                          value={contractData?.contractEndDate}
                        />
                      }
                    />
                  </Box>
                  <ViewChipText
                    colored
                    label={contractData?.contractTypeName}
                  />
                </Box>
                <Box className='flex flex-col gap-2'>
                  <ViewField
                    label='Project Code'
                    title={contractData?.projectCode}
                  />
                  <ViewField
                    label='Project Name'
                    title={contractData?.projectName}
                  />
                  <ViewField
                    label='Client Name'
                    title={contractData?.clientName}
                  />
                </Box>
                <Box className='flex flex-col gap-2'>
                  <TableAvatar
                    name={contractData?.contactPerson}
                    desc={contractData?.contactEmailId}
                  />

                  <ViewField
                    label='Contact Number'
                    title={contractData?.contactNumber}
                  />

                  <ViewField
                    label='Contract Cost'
                    title={`${contractData?.contractCost} ${
                      contractData?.currencyName || '-'
                    }`}
                  />
                </Box>
                <Box className='flex flex-col gap-2'>
                  <Box className='flex gap-2'>
                    <ViewChipText
                      label={`Budget : ${contractData?.budget} ${
                        contractData?.currencyName || '-'
                      }`}
                    />
                  </Box>

                  <ViewField
                    label='Contract Terms'
                    title={contractData?.contractTerms}
                  />

                  <ViewField
                    label='Contract Mode Name'
                    title={contractData?.contractModeName}
                  />
                </Box>
                <Box className='flex flex-col gap-2'>
                  <ViewField
                    label='Previous Contract Code'
                    title={contractData?.perviousContractCode}
                  />
                  <ViewField
                    label='Reference Number'
                    title={contractData?.referenceNumber}
                  />
                  <ViewField
                    label='Billing Frequency Name	'
                    title={contractData?.billingFrequencyName || '-'}
                  />
                </Box>
                <Box className='flex flex-col gap-2'>
                  <ViewField
                    label='Contract Scope Name'
                    title={contractData?.contractScopeName || '-'}
                  />
                  <ViewField
                    label='Threshold Value'
                    title={`${contractData?.thresholdValue || '0'} ${
                      contractData?.currencyName || '-'
                    }`}
                  />
                  <ViewField
                    label='Guarantee Name'
                    title={contractData?.guaranteeName || '-'}
                  />
                </Box>
                <Box className='flex flex-col gap-2'>
                  <Box className='flex items-center gap-2'>
                    <Box className='font-semibold'>
                      Gurantee <br /> Date
                    </Box>
                    <ViewStartEndDate
                      start={
                        <FmViewDateField
                          value={contractData?.guaranteeStartDate || 'null'}
                        />
                      }
                      end={
                        <FmViewDateField
                          value={contractData?.guaranteeEndDate || 'null'}
                        />
                      }
                    />
                  </Box>
                  <ViewField
                    label='Guarantee Amount'
                    title={`${contractData?.guaranteeAmount || '-'} ${
                      contractData?.currencyName || '-'
                    }`}
                  />
                  <ViewField
                    label='Remarks'
                    title={contractData?.remarks || '-'}
                  />
                </Box>
              </Stack>
            </Box>

            {/* <Grid container spacing={2}>
                <Grid item md={4}>
                  <ViewTable
                    rows={[
                      {
                        label: 'Contract Code',
                        title: contractData?.contractCode,
                      },
                      {
                        label: 'Contract Name',
                        title: contractData?.contractName,
                      },
                      {
                        label: 'Project Code',
                        title: contractData?.projectCode,
                      },
                      {
                        label: 'Project Name',
                        title: contractData?.projectName,
                      },
                      {
                        label: 'Client Name',
                        title: contractData?.clientName,
                      },
                      {
                        label: 'Contract Start Date',
                        title: contractData?.contractStartDate,
                        date: true,
                      },
                      {
                        label: 'Contract End Date',
                        title: contractData?.contractEndDate,
                        date: true,
                      },
                      {
                        label: 'Contact Person',
                        title: contractData?.contactPerson,
                      },
                      {
                        label: 'Contact Number',
                        title: contractData?.contactNumber,
                      },
                      {
                        label: 'Contact Email',
                        title: contractData?.contactEmailId,
                      },
                      {
                        label: 'Contract Cost',
                        title: contractData?.contractCost,
                      },
                    ]}
                  />
                </Grid>
                <Grid item md={4}>
                  <ViewTable
                    rows={[
                      {
                        label: 'Budget',
                        title: contractData?.budget,
                      },
                      {
                        label: 'Contract Terms',
                        title: contractData?.contractTerms,
                      },

                      {
                        label: 'Previous Contract Code',
                        title: contractData?.perviousContractCode,
                      },
                      {
                        label: 'Contract Mode Name',
                        title: contractData?.contractModeName,
                      },
                      {
                        label: 'Reference Number',
                        title: contractData?.referenceNumber,
                      },
                      {
                        label: 'Contract Type Name',
                        title: contractData?.contractTypeName,
                      },
                    ]}
                  />
                </Grid>
                <Grid item md={4}>
                  <ViewTable
                    rows={[
                      {
                        label: 'Contract Scope Name',
                        title: contractData?.contractScopeName || '-',
                      },
                      {
                        label: 'Billing Frequency Name',
                        title: contractData?.billingFrequencyName || '-',
                      },
                      {
                        label: 'Threshold Value',
                        title: contractData?.thresholdValue || '0',
                      },
                      {
                        label: 'Currency Name',
                        title: contractData?.currencyName || '-',
                      },
                      {
                        label: 'Guarantee Name',
                        title: contractData?.guaranteeName || '-',
                      },
                      {
                        label: 'Guarantee Start Date',
                        title: contractData?.guaranteeStartDate || 'null',
                        date: true,
                      },
                      {
                        label: 'Guarantee End Date',
                        title: contractData?.guaranteeEndDate || 'null',
                        date: true,
                      },
                      {
                        label: 'Guarantee Amount',
                        title: contractData?.guaranteeAmount || '-',
                      },
                      {
                        label: 'Remarks',
                        title: contractData?.remarks || '-',
                      },
                    ]}
                  />
                </Grid>
              </Grid> */}
          </>
        )}
      </GlassCard>
      <GlassCard className='mb-2'>
        <Tabs
          value={value}
          onChange={handleChange}
          textColor='secondary'
          indicatorColor='secondary'
          centered
        >
          {navMenu?.map((item) => {
            return <Tab value={item.id} label={item.name} key={item.name} />;
          })}
        </Tabs>
      </GlassCard>
      {value === 1 && <ViewContractCustomer contractId={id} />}
      {value === 2 && (
        <ViewContractLocationList
          contractId={id}
          projectId={contractData?.projectId}
        />
      )}
      {value === 3 && <ViewContractProjectTeam contractId={id} />}
      {value === 4 && <ViewContractRevenue />}
      {value === 5 && <ViewContractCost />}
      {value === 6 && <ViewContractSubContract contractId={id} />}
      {value === 7 && <ViewContractSchedule contractId={id} />}
      {value === 8 && <ViewContractRevenueSchedule  />}
    </DashboardLayout>
  );
};

export default ViewContracts;
