import React, { useEffect, useMemo, useState } from 'react';
import DashboardLayout from '../../../components/DashboardLayout.tsx';
import PageHeader from '../../../components/PageHeader';
import DataTable from '../../../components/DataTable.tsx';
import GlassCard from '../../../components/small/GlassCard.tsx';
import TableActionButtons from '../../../components/_form/TableActionButtons';
import { ViewField, StatusBadge } from '../../../components/_form/FormElements';
import { Box, Grid } from '@mui/material';
import { CreateButton } from '../../../components/small/Buttons';
import TableSearch from '../../../components/_form/TableSearch.jsx';
import MuiDialogOne from '../../../components/_mui/MuiDialogOne';
import { Nav } from '../../../utils';
import Crud_Service from '../../../apis/CrudService';
import { useForm, SubmitHandler } from 'react-hook-form';
import { useTranslation } from 'react-i18next';
import CommonLoader from '../../../components/page/CommonLoader';
import useLoader from '../../../components/helpers/UseLoader.tsx';
import ActionButtons from '../../../components/_form/ActionButtons.tsx';
import useTableLogic from '../../../components/helpers/UseTableLogic.tsx';
import FmTextField from '../../../components/_mui/FmTextField.tsx';
import FmAutoComplete from '../../../components/_mui/FmAutoComplete.tsx';
import { MasterDatatableHeight } from '../../../components/helpers/Containers.jsx';
import { statusOptions } from '../../../utils/CommonVariables.tsx';
import { UnitTypeProps } from '../../../interfaces/PreSalesInterfaces.ts';
import { toast } from 'react-toastify';
import { yupResolver } from '@hookform/resolvers/yup';
import * as Yup from 'yup';
import Icons from '../../../utils/Icon.jsx';
import { useSelector } from 'react-redux';
import { findNavByName } from '../../../utils/navUtils.ts';
import useCommonFetchApi from '../../../components/helpers/useCommonFetchApi.tsx';

const UnitTypeMaster = (props) => {
    const { type } = props;
    const [openCreate, setOpenCreate] = React.useState(false);
    const [openView, setOpenView] = React.useState(false);
    const [editModal, setEditModal] = useState(false);
    const [editData, setEditData] = useState(null);
    const crud = new Crud_Service();
    const { t } = useTranslation();
    const [viewData, setViewData] = useState();
    const [loading, setLoader] = useState(false);
    const [parentUnitTypes, setParentUnitTypes] = useState([]);

    const UnitTypeSchema = Yup.object().shape({
        unitTypeName: Yup.string().required('Unit Type Name is required'),
        parentUnitTypeId: Yup.number().nullable(),
    });

    const { handleSubmit, setValue, control, reset } = useForm<UnitTypeProps>({
        resolver: yupResolver(UnitTypeSchema),
        mode: 'onChange',
    });

    const {
        createLoading,
        startCreateLoading,
        stopCreateLoading,
        editLoading,
        startEditLoading,
        stopEditLoading,
    } = useLoader();

    const {
        rows,
        pageSize,
        pageNumber,
        setSearchKeyword,
        isLoading,
        tableRecordCounts,
        searchKeyword,
        handlePaginationModelChange,
        handleSortModelChange,
        fetchData,
    } = useTableLogic('unittypes');

    const menu = useSelector((state) => state?.menu?.items);

    const SubMenu = useMemo(() => {
        return menu?.map(item => item?.menus?.flat()).flat() || [];
    }, [menu]);

    const resultCafm = useMemo(() => findNavByName(SubMenu, "CAFM", "All master"), [SubMenu]);
    const { data: unittypes } = useCommonFetchApi('unittypes');

    useEffect(() => {
        document.title = `CAFM - Unit Types`;
    }, []);



    const handleViewClickOpen = async (view) => {
        setOpenView(true);
        setLoader(true);
        await crud.getSingle('unittypes', view?.unitTypeId, (err, res) => {
            if (res?.status === 200) {
                setViewData(res?.data);
                setLoader(false);
            } else {
            }
        });
    };

    const handleEditClick = async (view) => {
        setEditModal(true);
        setLoader(true);
        await crud.getSingle('unittypes', view?.unitTypeId, (err, res) => {
            if (res?.status === 200) {
                Object.entries({
                    ...res?.data,
                }).forEach(([key, value]) => {
                    setValue(key, value);
                });
                setEditData(res?.data);
                setLoader(false);
            } else {
                setLoader(false);
            }
        });
    };
    const handleDeleteClick = async (view) => {
        await crud.remove('unittypes', view?.unitTypeId, (err, res) => {
            if (res?.status === 204) {
                fetchData();
                toast.success('Floor Type Deleted Successfully');
            } else {
            }
        });
    };

    const handleCreateClickOpen = () => {
        reset();
        setOpenCreate(true);
    };
    const handleCreateClose = () => {
        reset();
        setOpenCreate(false);
    };
    const handleSubmitUnitTypes = async (values) => {
        startCreateLoading();
        await crud.create('unittypes', values, (err, res) => {
            if (res?.status === 201) {
                setOpenCreate(false);
                stopCreateLoading();
                toast.success('Unit Type Created Successfully');
                reset();
                fetchData();
            } else {
                stopCreateLoading();
                setOpenCreate(true);
            }
        });
    };

    const handleViewClose = () => {
        setOpenView(false);
    };
    const handleEditUnitTypes = async (values) => {
        startEditLoading();
        await crud.update('unittypes', editData?.unitTypeId, values, (err, res) => {
            if (res?.status === 200) {
                setEditModal(false);
                stopEditLoading();
                toast.success('Building System Updated Successfully');
                fetchData();
            } else {
                stopEditLoading();
                setEditModal(true);
            }
        });
    };
    const counters = [
        {
            name: 'All',

            count: tableRecordCounts?.total || 0,
        },
        {
            name: 'Active',
            count: tableRecordCounts?.active || 0,
        },
        {
            name: 'Deactivated',
            count: tableRecordCounts?.inActive || 0,
        },
    ];

    const ActionData = [
        {
            name: t('view'),
            icon: Icons.view,
            onClick: (props) => handleViewClickOpen(props),
        },
        {
            name: t('edit'),
            icon: Icons.edit,
            onClick: (props) => handleEditClick(props),
        },
        {
            name: t('delete'),
            icon: Icons.delete,
            onClick: (props) => handleDeleteClick(props),
            danger: true,
        },
    ];

    const columns = [
        {
            field: 'action',
            headerName: '',
            width: 140,
            sortable: false,
            disableClickEventBubbling: false,
            renderCell: (params) => {
                return (
                    <TableActionButtons
                        Actions={ActionData?.map((action) => ({
                            ...action,
                            onClick: () => action.onClick(params.row),
                        }))}
                    />
                );
            },
        },
        {
            field: 'unitTypeCode',
            headerName: 'Unit Type Code',
            flex: 1,
        },
        {
            field: 'unitTypeName',
            headerName: 'Unit Type Name',
            flex: 1,
        },
        {
            field: 'parentUnitTypeCode',
            headerName: 'Parent Unit Type Code',
            flex: 1,

        },
        {
            field: 'parentUnitTypeName',
            headerName: 'Parent Unit Type',
            flex: 1,
        },
        {
            field: 'status',
            headerName: t('status'),
            flex: 1,
            renderCell: (params) => {
                return (
                    <StatusBadge
                        title={params.value === 2 ? 'Active' : 'Inactive'}
                        type={params.value === 2 ? 'green' : 'red'}
                    />
                );
            },
        },
    ];

    return (
        <DashboardLayout
            title='Unit Types'
            actionButtons={
                <>
                    <PageHeader counters={counters} />
                </>
            }
            hasSubmenu
            menu={resultCafm}
        >
            <GlassCard className='h-full-css'>
                <MasterDatatableHeight>
                    <DataTable
                        rows={rows}
                        columns={columns}
                        loading={isLoading}
                        getRowClassName={(params) =>
                            `${params.row.status === 0 && 'bg-red-50'}`
                        }
                        sortingMode='server'
                        paginationMode='server'
                        onPaginationModelChange={(model) =>
                            handlePaginationModelChange(model)
                        }
                        onSortModelChange={(model) => handleSortModelChange(model)}
                        page={pageNumber - 1}
                        pageSize={pageSize}
                        rowCount={tableRecordCounts?.total}
                        slots={{
                            toolbar: () => (
                                <Box
                                    className='p-2 w-full flex justify-between items-center'
                                    sx={{
                                        borderBottom: '1px solid',
                                        borderColor: 'border.main',
                                    }}
                                >
                                    <Box sx={{ maxWidth: '250px' }}>
                                        <TableSearch
                                            placeholder='Search'
                                            fullWidth
                                            setSearchKeyword={setSearchKeyword}
                                            searchValue={searchKeyword}
                                        />
                                    </Box>
                                    <CreateButton name='New' onClick={handleCreateClickOpen} />
                                </Box>
                            ),
                        }}
                    />
                </MasterDatatableHeight>
            </GlassCard>

            <MuiDialogOne
                title={t('create')}
                open={openCreate}
                onClose={handleCreateClose}
            >
                <form onSubmit={handleSubmit(handleSubmitUnitTypes)}>
                    <Grid container spacing={3}>
                        <Grid item md={12}>
                            <FmTextField
                                name='unitTypeName'
                                control={control}
                                label='Unit Type Name'
                                rules={{
                                    required: 'Unit Type Name is required',
                                }}
                                required
                            />
                        </Grid>
                        <Grid item md={12}>
                            <FmAutoComplete
                                name='parentUnitTypeId'
                                control={control}
                                options={unittypes}
                                label='Parent Unit Type'
                                displayField='unitTypeName'
                                optionFields={['unitTypeName']}
                                valueKey='unitTypeId'
                            />
                        </Grid>
                        <Grid item md={12}>
                            <ActionButtons
                                onSubmit={handleSubmit(handleSubmitUnitTypes)}
                                onCancel={handleCreateClose}
                                onReset={reset}
                                submitLoading={createLoading}
                                cancelLoading={false}
                                submitText='Create'
                            />
                        </Grid>
                    </Grid>
                </form>
            </MuiDialogOne>

            <MuiDialogOne
                title='View Details'
                open={openView}
                onClose={handleViewClose}
            >
                {loading ? (
                    <CommonLoader />
                ) : (
                    <Grid container spacing={3}>
                        <Grid item md={6}>
                            <ViewField label='Unit Type Code' title={viewData?.unitTypeCode} />
                        </Grid>
                        <Grid item md={6}>
                            <ViewField label='Unit Type Name' title={viewData?.unitTypeName} />
                        </Grid>
                        <Grid item md={6}>
                            <ViewField label='Parent Unit Type Code' title={viewData?.parentUnitTypeCode} />
                        </Grid>
                        <Grid item md={6}>
                            <ViewField label='Parent Unit Type' title={viewData?.parentUnitTypeName} />
                        </Grid>
                        <Grid item md={6}>
                            <ViewField label={t('status')} title={viewData?.statusName} />
                        </Grid>
                    </Grid>
                )}
            </MuiDialogOne>

            <MuiDialogOne
                title={t('update')}
                open={editModal}
                onClose={() => setEditModal(false)}
            >
                {loading ? (
                    <CommonLoader />
                ) : (
                    <form onSubmit={handleSubmit(handleEditUnitTypes)}>
                        <Grid container spacing={3}>
                            <Grid item md={12}>
                                <FmTextField
                                    name='unitTypeName'
                                    control={control}
                                    label='Unit Type Name'
                                    rules={{
                                        required: 'Unit Type Name is required',
                                    }}
                                    required
                                />
                            </Grid>
                            <Grid item md={12}>
                                <FmAutoComplete
                                    name='parentUnitTypeId'
                                    control={control}
                                    options={parentUnitTypes}
                                    label='Parent Unit Type'
                                    displayField='unitTypeName'
                                    optionFields={['unitTypeName']}
                                    valueKey='unitTypeId'
                                />
                            </Grid>
                            <Grid item md={12}>
                                <FmAutoComplete
                                    name='status'
                                    control={control}
                                    options={statusOptions}
                                    label='Status'
                                    displayField='statusName'
                                    optionFields={['statusName']}
                                    valueKey='statusId'
                                />
                            </Grid>
                            <Grid item md={12}>
                                <ActionButtons
                                    onSubmit={handleSubmit(handleEditUnitTypes)}
                                    onCancel={() => setEditModal(false)}
                                    submitLoading={editLoading}
                                    onReset={reset}
                                    cancelLoading={false}
                                    submitText='Update'
                                />
                            </Grid>
                        </Grid>
                    </form>
                )}
            </MuiDialogOne>
        </DashboardLayout>
    );
};

export default UnitTypeMaster; 