import React, { useEffect, useState } from 'react';
import DashboardLayout from '../../../components/DashboardLayout.tsx';
import PageHeader from '../../../components/PageHeader.jsx';
import DataTable from '../../../components/DataTable.tsx';
import GlassCard from '../../../components/small/GlassCard.tsx';
import TableActionButtons from '../../../components/_form/TableActionButtons.jsx';
import { StatusBadge } from '../../../components/_form/FormElements.jsx';
import { Icon } from '@iconify/react';
import { Box, Grid } from '@mui/material';
import { CreateButton } from '../../../components/small/Buttons.jsx';
import MuiDialogOne from '../../../components/_mui/MuiDialogOne.jsx';
import Crud_Service from '../../../apis/CrudService.jsx';
import { useForm } from 'react-hook-form';
import CommonLoader from '../../../components/page/CommonLoader.jsx';
import useLoader from '../../../components/helpers/UseLoader.tsx';
import ActionButtons from '../../../components/_form/ActionButtons.tsx';
import useTableLogic from '../../../components/helpers/UseTableLogic.tsx';
import FmTextField from '../../../components/_mui/FmTextField.tsx';
import TableSearch from '../../../components/_form/TableSearch.jsx';
import FmAutoComplete from '../../../components/_mui/FmAutoComplete.tsx';
import { toast } from 'react-toastify';
import { Nav } from '../../../utils/index.jsx';
import useCommonFetchApi from '../../../components/helpers/useCommonFetchApi.tsx';
import { LocalizationProvider } from '@mui/x-date-pickers';
import { AdapterDayjs } from '@mui/x-date-pickers/AdapterDayjs';
import dayjs from 'dayjs';
import FmDatePicker from '../../../components/_mui/FmDatePicker.tsx';
import { useTranslation } from 'react-i18next';
import { VehiclevalidationSchema } from '../../../utils/CommonvalidationSchemas.tsx';
import { yupResolver } from '@hookform/resolvers/yup';
import CommonView from '../../../components/helpers/CommonView.jsx';
import DynamicViewFields from '../../../components/helpers/DynamicViewFields.jsx';

const Vehicles = () => {
  const [openCreate, setOpenCreate] = useState(false);
  const [openEdit, setOpenEdit] = useState(false);
  const [openView, setOpenView] = useState(false);
  const [loading, setLoading] = useState(false);
  const { t } = useTranslation();
  const [selectedRowData, setSelectedRowData] = useState(null);
  const { handleSubmit, setValue, reset, control } = useForm({
    resolver: yupResolver(VehiclevalidationSchema),
    defaultValues: {
      insuranceExpiryDate: dayjs(),
      registeredExpiryDate: dayjs(),
      registeredDate: dayjs(),
    },
  });

  const {
    createLoading,
    startCreateLoading,
    stopCreateLoading,
    editLoading,
    startEditLoading,
    stopEditLoading,
  } = useLoader();
  const {
    rows,
    pageSize,
    pageNumber,
    setSearchKeyword,
    isLoading,
    tableRecordCounts,
    handlePaginationModelChange,
    handleSortModelChange,
    searchKeyword,
    setRows,
    fetchData,
  } = useTableLogic('vehicles');
  const crud = new Crud_Service();
  const { data: vehiclebrands } = useCommonFetchApi('vehiclebrands') || {
    data: [],
  };
  const { data: vehiclecategories } = useCommonFetchApi(
    'vehiclecategories'
  ) || {
    data: [],
  };
  const { data: vehiclestatuses } = useCommonFetchApi('vehiclestatuses') || {
    data: [],
  };
  const { data: locations } = useCommonFetchApi('locations') || {
    data: [],
  };
  const { data: vehicleplatetypes } = useCommonFetchApi(
    'vehicleplatetypes'
  ) || {
    data: [],
  };
  const { data: emirates } = useCommonFetchApi('emirates') || {
    data: [],
  };
  const { data: vehicletypes } = useCommonFetchApi('vehicletypes') || {
    data: [],
  };
  useEffect(() => {
    document.title = `Vehicle`;
  }, []);

  //create the Card Type
  const handleSubmitVehicles = async (values) => {
    startCreateLoading();
    const combinedData = {
      ...values,
      registeredDate: dayjs(values?.registeredDate).format('YYYY-MM-DD'),
      insuranceExpiryDate: dayjs(values?.insuranceExpiryDate).format(
        'YYYY-MM-DD'
      ),
      registeredExpiryDate: dayjs(values?.registeredExpiryDate).format(
        'YYYY-MM-DD'
      ),
    };
    await crud.create('vehicles', combinedData, (err, res) => {
      if (err) {
        toast.error(t('something_Went_Wrong!'), err);
        return;
      }
      if (res?.status === 201) {
        toast.success('Vehicle Created Successfully!');
        setOpenCreate(false);
        stopCreateLoading();
        fetchData();
      } else {
        stopCreateLoading();
        toast.error(t('something_Went_Wrong!'), res);
      }
    });
  };

  const handleCreateClickOpen = () => {
    reset();
    setOpenCreate(true);
  };
  const handleCreateClose = () => {
    reset();
    setOpenCreate(false);
  };
  const [viewDetails, setViewdetails] = useState(false);
  const handleViewClick = async (props) => {
    setViewdetails(props);
  };
  const counters = [
    {
      name: 'All',
      count: tableRecordCounts?.total || 0,
    },
    {
      name: 'Active',
      count: tableRecordCounts?.active || 0,
    },
    {
      name: 'Deactivated',
      count: tableRecordCounts?.inActive || 0,
    },
  ];

  //handle Delete Record
  const handleDeleteClick = async (view) => {
    await crud.remove('vehicles', view?.vehicleId, (err, res) => {
      if (res?.status === 204) {
        toast.success('Vehicle Deleted Successfully!');
        setRows(rows?.filter((row) => row.id !== view?.vehicleId));
        fetchData();
      } else {
      }
    });
  };

  //SetValues to the Form
  const handleEditClick = async (view) => {
    setOpenEdit(true);
    setLoading(true);
    await crud.getSingle('vehicles', view?.vehicleId, (err, res) => {
      if (res?.status === 200) {
        Object.entries({
          ...res?.data,
        }).forEach(([key, value]) => {
          setValue(key, value);
        });
        setLoading(false);
      } else {
      }
    });
  };

  const handleEditVehicleTypes = async (values) => {
    startEditLoading();
    await crud.update('vehicles', values?.vehicleId, values, (err, res) => {
      if (res?.status === 200) {
        toast.success('Vehicle Updated Successfully!');
        setOpenEdit(false);
        stopEditLoading();
        fetchData();
      } else {
        stopEditLoading();
        setOpenEdit(true);
      }
    });
  };

  const ActionData = [
    {
      name: 'View',
      icon: <Icon icon='solar:eye-linear' />,
      onClick: (props) => handleViewClick(props),
    },
    {
      name: 'Edit',
      icon: <Icon icon='solar:pen-new-round-linear' />,
      onClick: (props) => handleEditClick(props),
    },
    {
      name: 'Delete',
      icon: <Icon icon='solar:trash-bin-2-linear' />,
      onClick: (props) => handleDeleteClick(props),
      danger: true,
    },
  ];
  const excludeKeys = [
    'vehicleId',
    'vehicleCategoryId',
    'vehicleCategoryName',
    'vehicleBrandId',
    'vehicleTypeId',
    'vehiclePlateTypeId',
    'vehicleStatusId',
    'createdDate',
    'updatedDate',
    'presentlyLocatedId',
    'vehicleRegistered',
    'status',
  ];

  const columns = [
    {
      field: 'action',
      headerName: '',
      width: 140,
      sortable: false,
      disableClickEventBubbling: false,

      renderCell: (params) => {
        return (
          <TableActionButtons
            Actions={ActionData?.map((action) => ({
              ...action,
              onClick: () => action.onClick(params.row),
            }))}
          />
        );
      },
    },
    {
      field: 'vehicleCode',
      headerName: 'Vehicle Code',
      flex: 1,
    },
    {
      field: 'vehicleName',
      headerName: 'Vehicle Name',
      flex: 1,
    },
    {
      field: 'vehicleBrandName',
      headerName: 'Vehicle Brand Name',
      flex: 1,
    },
    {
      field: 'vehicleTypeName',
      headerName: 'Vehicle Type Name',
      flex: 1,
    },
    {
      field: 'vehiclePlateTypeName',
      headerName: 'Vehicle Plate Type Name',
      flex: 1,
    },
    {
      field: 'registeredExpiryDate',
      headerName: 'Registered Expiry Date',
      flex: 1,
    },
    {
      field: 'insuranceExpiryDate',
      headerName: 'Insurance Expiry Date',
      flex: 1,
    },

    {
      field: 'status',
      headerName: 'Status',
      flex: 1,
      renderCell: (params) => {
        return (
          <StatusBadge
            title={params.value === 2 ? 'Active' : 'Inactive'}
            type={params.value === 2 ? 'green' : 'red'}
          />
        );
      },
    },
  ];

  return (
    <DashboardLayout
      title='Advertisement'
      actionButtons={
        <>
          <PageHeader counters={counters} />
        </>
      }
      menu={Nav[10]?.child[6]?.children}
      hasSubmenu
    >
      <GlassCard className='h-full'>
        <Box className='h-full'>
          <DataTable
            rows={rows}
            columns={columns}
            loading={isLoading}
            getRowClassName={(params) =>
              `${params.row.status === 0 && 'bg-red-50'}`
            }
            sortingMode='server'
            paginationMode='server'
            onPaginationModelChange={(model) =>
              handlePaginationModelChange(model)
            }
            onSortModelChange={(model) => handleSortModelChange(model)}
            page={pageNumber - 1}
            pageSize={pageSize}
            rowCount={tableRecordCounts?.total}
            slots={{
              toolbar: () => (
                <Box
                  className='p-2 w-full flex justify-between items-center'
                  sx={{
                    borderBottom: '1px solid',
                    borderColor: 'border.main',
                  }}
                >
                  <Box sx={{ maxWidth: '250px' }}>
                    <TableSearch
                      placeholder='Search'
                      fullWidth
                      setSearchKeyword={setSearchKeyword}
                      searchValue={searchKeyword}
                    />
                  </Box>
                  <CreateButton name='New' onClick={handleCreateClickOpen} />
                </Box>
              ),
            }}
          />
        </Box>
      </GlassCard>

      {/* create */}

      <MuiDialogOne
        title='Create New'
        open={openCreate}
        onClose={handleCreateClose}
      >
        <form onSubmit={handleSubmit(handleSubmitVehicles)}>
          <LocalizationProvider dateAdapter={AdapterDayjs}>
            <Grid container spacing={3}>
              <Grid item md={6}>
                <FmTextField
                  name='vehicleName'
                  control={control}
                  label='Vehicle'
                />
              </Grid>
              <Grid item md={6}>
                <FmAutoComplete
                  name='vehicleCategoryId'
                  control={control}
                  options={vehiclecategories}
                  label='Vehicle Category'
                  displayField='vehicleCategoryName'
                  optionFields={['vehicleCategoryName']}
                  valueKey='vehicleCategoryId'
                />
              </Grid>
              <Grid item md={6}>
                <FmAutoComplete
                  name='vehicleTypeId'
                  control={control}
                  options={vehicletypes}
                  label='Vehicle Type'
                  displayField='vehicleTypeName'
                  optionFields={['vehicleTypeName']}
                  valueKey='vehicleTypeId'
                />
              </Grid>
              <Grid item md={6}>
                <FmAutoComplete
                  name='vehicleBrandId'
                  control={control}
                  options={vehiclebrands}
                  label='Vehicle Brand'
                  displayField='vehicleBrandName'
                  optionFields={['vehicleBrandName']}
                  valueKey='vehicleBrandId'
                />
              </Grid>
              <Grid item md={6}>
                <FmAutoComplete
                  name='vehiclePlateTypeId'
                  control={control}
                  options={vehicleplatetypes}
                  label='Vehicle Plate Type'
                  displayField='vehiclePlateTypeName'
                  optionFields={['vehiclePlateTypeName']}
                  valueKey='vehiclePlateTypeId'
                />
              </Grid>
              <Grid item md={6}>
                <FmTextField
                  name='vehiclePlateNo'
                  control={control}
                  label='vehiclePlateNo'
                />
              </Grid>
              <Grid item md={6}>
                <FmTextField
                  name='chasisNo'
                  control={control}
                  label='Chasis No'
                />
              </Grid>
              <Grid item md={6}>
                <FmTextField
                  name='engineNo'
                  control={control}
                  label='Engine No'
                />
              </Grid>
              <Grid item md={6}>
                <FmAutoComplete
                  name='vehicleStatusId'
                  control={control}
                  options={vehiclestatuses}
                  label='Vehicle Status'
                  displayField='vehicleStatusName'
                  optionFields={['vehicleStatusName']}
                  valueKey='vehicleStatusId'
                />
              </Grid>
              <Grid item md={6}>
                <FmAutoComplete
                  name='presentlyLocatedId'
                  control={control}
                  options={locations}
                  label='Location'
                  displayField='locationName'
                  optionFields={['locationName']}
                  valueKey='locationId'
                />
              </Grid>
              <Grid item md={6}>
                <FmAutoComplete
                  name='vehicleRegistered'
                  control={control}
                  options={emirates}
                  label='Emirate'
                  displayField='emirateName'
                  optionFields={['emirateName']}
                  valueKey='regionId'
                />
              </Grid>
              <Grid item md={6}>
                <FmTextField
                  name='registeredName'
                  control={control}
                  label='Registered Name'
                />
              </Grid>
              <Grid item md={6}>
                <FmTextField
                  name='vehicleModel'
                  control={control}
                  label='Vehicle Model'
                />
              </Grid>
              <Grid item md={6}>
                <FmTextField
                  name='trafficCodeNo'
                  control={control}
                  label='Traffic Code No'
                />
              </Grid>
              <Grid item md={6}>
                <FmDatePicker
                  name='registeredDate'
                  label='Registered Date'
                  control={control}
                  required
                />
              </Grid>
              <Grid item md={6}>
                <FmDatePicker
                  name='registeredExpiryDate'
                  label='Registered Expiry Date'
                  control={control}
                  required
                />
              </Grid>
              <Grid item md={6}>
                <FmDatePicker
                  name='insuranceExpiryDate'
                  label='Insurance Expiry Date'
                  control={control}
                  required
                />
              </Grid>
            </Grid>
          </LocalizationProvider>
          <ActionButtons
            onSubmit={handleSubmit(handleSubmitVehicles)}
            onCancel={handleCreateClose}
            onReset={reset}
            submitLoading={createLoading}
            cancelLoading={false}
          />
        </form>
      </MuiDialogOne>

      {/* create */}

      {/* view */}
      <MuiDialogOne
        title='View Details'
        open={viewDetails}
        onClose={() => setViewdetails(!viewDetails)}
      >
        {viewDetails && viewDetails?.vehicleId && (
          <CommonView
            url='vehicles'
            id={viewDetails?.vehicleId}
            excludeKeys={excludeKeys}
            renderFields={(data, fields) => (
              <DynamicViewFields data={data} fields={fields} />
            )}
          />
        )}
      </MuiDialogOne>

      {/* edit */}
      <MuiDialogOne
        title='Update'
        open={openEdit}
        onClose={() => setOpenEdit(false)}
      >
        {loading ? (
          <CommonLoader />
        ) : (
          // <VehiclesTable
          //     fetchData={fetchData}
          //     setOpenEdit={setOpenEdit}
          //     handleCreateClose={handleCreateClose}
          //     handleEditVehicleTypes={handleEditVehicleTypes}
          // />
          <>
            <form onSubmit={handleSubmit(handleEditVehicleTypes)}>
              <Grid container spacing={3}>
                <Grid item md={6}>
                  <FmTextField
                    name='vehicleName'
                    control={control}
                    label='Vehicle Number'
                    rules={{ required: 'Vehicle Number is required' }}
                  />
                </Grid>
                <Grid item md={6}>
                  <FmAutoComplete
                    name='vehicleCategoryId'
                    control={control}
                    options={vehiclecategories}
                    label='Vehicle Category'
                    displayField='vehicleCategoryName'
                    optionFields={['vehicleCategoryName']}
                    valueKey='vehicleCategoryId'
                  />
                </Grid>
                <Grid item md={6}>
                  <FmAutoComplete
                    name='vehicleBrandId'
                    control={control}
                    options={vehiclebrands}
                    label='Vehicle Brand'
                    displayField='vehicleBrandName'
                    optionFields={['vehicleBrandName']}
                    valueKey='vehicleBrandId'
                  />
                </Grid>
                <Grid item md={6}>
                  <FmTextField
                    name='vehiclePlateNo'
                    control={control}
                    label='vehiclePlateNo'
                  />
                </Grid>
                <Grid item md={6}>
                  <FmTextField
                    name='chasisNo'
                    control={control}
                    label='Chasis No'
                  />
                </Grid>
                <Grid item md={6}>
                  <FmTextField
                    name='engineNo'
                    control={control}
                    label='Engine No'
                  />
                </Grid>
                <Grid item md={6}>
                  <FmAutoComplete
                    name='vehicleStatusId'
                    control={control}
                    options={vehiclestatuses}
                    label='Vehicle Status'
                    displayField='vehicleStatusName'
                    optionFields={['vehicleStatusName']}
                    valueKey='vehicleStatusId'
                  />
                </Grid>
                <Grid item md={6}>
                  <FmTextField
                    name='registeredName'
                    control={control}
                    label='Registered Name'
                  />
                </Grid>
                <Grid item md={6}>
                  <FmTextField
                    name='trafficCodeNo'
                    control={control}
                    label='Traffic Code No'
                  />
                </Grid>
                <Grid item md={6}>
                  <FmDatePicker
                    name='registeredDate'
                    label='Registered Date'
                    control={control}
                    required
                  />
                </Grid>
                <Grid item md={6}>
                  <FmDatePicker
                    name='registeredExpiryDate'
                    label='Registered Expiry Date'
                    control={control}
                    required
                  />
                </Grid>
                <Grid item md={6}>
                  <FmDatePicker
                    name='insuranceExpiryDate'
                    label='Insurance Expiry Date'
                    control={control}
                    required
                  />
                </Grid>

                <Grid item md={12}>
                  <ActionButtons
                    onSubmit={handleSubmit(handleEditVehicleTypes)}
                    onCancel={handleCreateClose}
                    onReset={reset}
                    submitLoading={createLoading}
                    cancelLoading={false}
                    submitText='Update'
                  />
                </Grid>
              </Grid>
            </form>
          </>
        )}
      </MuiDialogOne>
    </DashboardLayout>
  );
};

export default Vehicles;
