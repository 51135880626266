import { createAsyncThunk, createSlice } from '@reduxjs/toolkit';
import axios from 'axios';
import apiInstance from '../apis/ApiService';
import { resetMenu  } from './menuSlice.ts';
import { populateNav } from '../utils/Nav.tsx';

export const fetchMenu = createAsyncThunk(
  'menu/fetchMenu',
  async ({ id, navigate }: { id: number; navigate: any }, thunkAPI) => {
    const { dispatch, rejectWithValue } = thunkAPI;
    try {
      const response = await apiInstance.NavMenu(`loginusers/${id}/menus`);
      // localStorage.setItem('menu', response?.data?.modules[0]);
      const SubMenu = response?.data?.modules[0]?.menus;
      const url = SubMenu[0]?.childMenus?.[0]?.url || '/hrms/dashboard';
      navigate(`${url}`);

      populateNav(response?.data?.modules, dispatch);
      return response?.data?.modules || [];
    } catch (error: any) {
      return rejectWithValue(error.response?.data || error.message || 'Failed to fetch menu');
    }
  }
);

export const loginRequest = createAsyncThunk(
  'auth/login',
  async ({ payload, navigate }: { payload: any; navigate: any }, thunkAPI) => {
    const { dispatch, rejectWithValue } = thunkAPI;
    try {
      const response = await axios.post(`${process.env.REACT_APP_BASE}/auth/login`, payload);
      if (response.status === 200) {
        const { token, currentUser } = response.data;
        const id = currentUser.id;
        await dispatch(fetchMenu({ id, navigate })); // Pass navigate to fetchMenu
        return { token, user: currentUser };
      } else {
        return rejectWithValue('Invalid credentials');
      }
    } catch (error: any) {
      return rejectWithValue(error?.response?.data?.message || 'Login failed');
    }
  }
);

export const impersonateRequest = createAsyncThunk(
  'auth/impersonateRequest',
  async ({ values, navigate }: { values: any; navigate: any }, thunkAPI) => {
    try {
      const payload = { impersonatorUserEmail: values?.email };
      const response = await apiInstance.impersonate(payload);

      if (response?.status === 200) {
        const { token, currentUser } = response.data;
        navigate('/hrms/dashboard');
        return { token, user: currentUser };
      } else {
        return thunkAPI.rejectWithValue(response?.data || 'Impersonation failed');
      }
    } catch (error: any) {
      return thunkAPI.rejectWithValue(
        error.response?.data || error.message || 'An error occurred during impersonation'
      );
    }
  }
);

export const userLogout = createAsyncThunk(
  'auth/logout',
  async (
    { user, navigate }: { user: any; navigate: any },
    thunkAPI
  ) => {
    const { dispatch, rejectWithValue } = thunkAPI;
    try {
      if (user?.impersonatorUserName !== null) {
        const response = await apiInstance.logoutImpersonate(`auth/impersonate/logout`, {});
        if (response?.status === 200) {
          const { token, currentUser } = response.data;
          navigate('/hrms/dashboard');
          return { token, user: currentUser };
        }
      } else {
        await apiInstance.logout(`auth/logout`, {});
        dispatch(resetMenu());
        navigate('/');
        const selectedFont = localStorage.getItem('selectedFontThree');
        localStorage.clear();
        if (selectedFont) {
          localStorage.setItem('selectedFontThree', selectedFont);
        }
        sessionStorage.clear();
        return { token: null, user: null };
      }
    } catch (error: any) {
      return rejectWithValue(error?.message || 'Logout failed');
    }
  }
);

const loginSlice = createSlice({
  name: 'auth',
  initialState: {
    token: null,
    user: null,
    loading: false,
    error: null,
  },
  reducers: {},
  extraReducers: (builder) => {
    builder
      .addCase(loginRequest.pending, (state) => {
        state.loading = false;
        state.error = null;
      })
      .addCase(loginRequest.fulfilled, (state, action) => {
        state.loading = false;
        state.token = action.payload.token;
        state.user = action.payload.user;
      })
      .addCase(loginRequest.rejected, (state, action) => {
        state.loading = false;
        state.error = action.payload;
      })
      .addCase(impersonateRequest.pending, (state) => {
        state.loading = true;
        state.error = null;
      })
      .addCase(impersonateRequest.fulfilled, (state, action) => {
        state.loading = false;
        state.token = action.payload?.token;
        state.user = action.payload?.user;
      })
      .addCase(impersonateRequest.rejected, (state, action) => {
        state.loading = false;
        state.error = action.payload;
      })
      .addCase(userLogout.pending, (state) => {
        state.loading = true;  // Show loader on logout
      })
      .addCase(userLogout.fulfilled, (state) => {
        state.token = null;
        state.user = null;
        state.loading = false; // Hide loader after logout
      })
      .addCase(userLogout.rejected, (state, action) => {
        state.loading = false;
        state.error = action.payload;
      });
  },
});

export default loginSlice.reducer;

