import React, { useEffect, useRef, useState } from 'react';
import Crud_Service from '../../../../apis/CrudService';
import { useParams } from 'react-router-dom';
import { useForm } from 'react-hook-form';
import { ContractBudgetSchema } from '../../../../utils/CommonvalidationSchemas.tsx';
import { useHotkeys } from 'react-hotkeys-hook';
import FmTextField from '../../../../components/_mui/FmTextField.tsx';
import { Box, Button, Typography } from '@mui/material';
import TableActionButtons from '../../../../components/_form/TableActionButtons';
import GlassCard from '../../../../components/small/GlassCard.tsx';
import FmMRTDataTable from '../../../../components/FmMRTDataTable.tsx';
import MuiDialogOne from '../../../../components/_mui/MuiDialogOne';
import CommonView from '../../../../components/helpers/CommonView';
import useTableLogic from '../../../../components/helpers/MRTUseTableLogic.tsx';
import { yupResolver } from '@hookform/resolvers/yup';
import DynamicViewFields from '../../../../components/helpers/DynamicViewFields.jsx';
import FmSearchableSelect from '../../../../components/_mui/FmSearchableSelect.tsx';
import Icons from '../../../../utils/Icon.jsx';
import { toast } from 'react-toastify';

const ViewContractBudgeting = ({ contractData }) => {
  const { id } = useParams();
  const {
    rows,
    setRows,
    isLoading,
    pagination,
    sorting,
    columnFilters,
    globalFilter,
    tableRecordCounts,
    setPagination,
    setSorting,
    setColumnFilters,
    setGlobalFilter,
    fetchData,
    setSearchKeyword,
    searchKeyword,
  } = useTableLogic(`contractbudgetings`, ['contractId'], [id]);
  const crud = new Crud_Service();
  const [viewDetails, setViewDetails] = useState(false);
  const [editingRowId, setEditingRowId] = useState(null);
  const {
    control,
    reset,
    getValues,
    setValue,
    trigger,
    formState: { isValid },
  } = useForm({
    resolver: yupResolver(ContractBudgetSchema),
    mode: 'all',
  });
  const firstColumnInputRef = useRef(null);
  const secondColumnInputRef = useRef(null);
  const [stateError, setStateError] = useState(false);
  const [loader, setLoader] = useState(false);
  const [editingRowIndex, setEditingRowIndex] = useState(null);

  useEffect(() => {
    const updatedRows = rows.map((row) => {
      const totalAmount =
        (Number(row.staffSalariesAmount) || 0) +
        (Number(row.otherPreliminariesAmount) || 0) +
        (Number(row.labourAmount) || 0) +
        (Number(row.materialsAmount) || 0);
      return {
        ...row,
        totalAmount,
      };
    });

    // Only update state if rows have actually changed
    if (JSON.stringify(updatedRows) !== JSON.stringify(rows)) {
      setRows(updatedRows);
    }
  }, [rows]);

  const [rowState, setRowState] = useState({
    editingRowId: null,
    newRowId: null,
    isCreatingRow: false,
  });

  const excludeKeys = [
    'itemName',
    'quantity',
    'unitOfMeasurementId',
    'unitPrice',
    'itemTotal',
    'remarks',
  ];
  const handleCancel = (row) => {
    if (row?.original?.contractBudgetingId === rowState?.newRowId) {
      setRows((prevRows) => {
        if (prevRows?.length > 0) {
          return prevRows?.slice(0, -1);
        }
        return prevRows;
      });

      setRowState((prevState) => ({
        ...prevState,
        newRowId: null,
        isCreatingRow: false,
      }));
    } else {
      setEditingRowId(null);
    }
    reset();
  };

  const handleEdit = (row) => {
    reset();
    if (rowState?.newRowId) {
      setRows((prevRows) => {
        if (prevRows?.length > 0) {
          return prevRows?.slice(0, -1);
        }
        return prevRows;
      });
      setRowState((prevState) => ({
        ...prevState,
        newRowId: null,
        isCreatingRow: false,
      }));
      // setEditingRowIndex(null);
    }
    setEditingRowId(row?.contractBudgetingId);
    Object.entries({
      ...row,
    }).forEach(([key, value]) => {
      setValue(key, value);
    });
  };

  useHotkeys(
    'Enter',
    () => handleAddNewRow(),
    {
      enableOnTags: ['INPUT', 'TEXTAREA'], // Optional: Enable hotkey only when focused on specific tags
    },
    [editingRowIndex, rows, stateError, loader]
  );

  useHotkeys(
    'Tab',
    (event) => {
      if (event.key !== 'Tab') return;
      if (stateError) {
        event.preventDefault();
        return;
      }
      const totalRows = rows.length;
      const nextRowIndex = editingRowIndex + 1;

      if (
        nextRowIndex < totalRows &&
        stateError === false &&
        loader === false
      ) {
        handleEdit(rows[nextRowIndex]);
        setEditingRowIndex(nextRowIndex);
      }
    },
    [editingRowIndex, rows, stateError, loader, isValid]
  );

  useEffect(() => {
    if (editingRowIndex !== null && firstColumnInputRef.current && !isLoading) {
      firstColumnInputRef.current.focus();
    }
  }, [editingRowIndex, isLoading]);

  const previousValues = useRef(getValues());

  const handleCancelAndAddNewRow = () => {
    const newId = 'new_row_' + Date.now();
    reset();

    setEditingRowId(null);

    setRowState({
      newRowId: newId,
      isCreatingRow: true,
    });
    setEditingRowIndex(
      editingRowIndex === rows?.length ? editingRowIndex + 1 : rows?.length
    );
    setRows((prevRows) => [
      ...prevRows,
      {
        contractBudgetingId: newId,
        contractId: id,
        divisionId: '',
        budget: '',
        staffSalariesAmount: '',
        otherPreliminariesAmount: '',
        labourAmount: '',
        materialsAmount: '',
        isEditable: true,
        isNew: true,
      },
    ]);
    reset();
  };

  const handleBlur = () => {
    const currentValues = getValues();
    const divisionId = getValues('divisionId');
    const budget = getValues('budget');

    if (!divisionId) {
      firstColumnInputRef.current.focus();
      return;
    }

    if (!budget) {
      secondColumnInputRef.current.focus();
      return;
    }

    const hasChanges = Object.keys(currentValues).some(
      (key) => currentValues[key] !== previousValues.current[key]
    );

    if ((hasChanges && isValid) || (hasChanges && editingRowId)) {
      apiCallAsync(currentValues); // Call the API with the new values
      previousValues.current = currentValues; // Update previous values
    }
  };

  //asyncronous api call
  const apiCallAsync = async (values) => {
    setLoader(true);
    const convertedValues = {
      ...values,
      budget: Number(values?.budget),
      labourAmount: Number(values?.labourAmount),
      materialsAmount: Number(values?.materialsAmount),
      otherPreliminariesAmount: Number(values?.otherPreliminariesAmount),
      staffSalariesAmount: Number(values?.staffSalariesAmount),
      contractId: Number(id),
    };

    const updatedCombinedData = {
      ...values,
      budget: Number(values?.budget),
      labourAmount: Number(values?.labourAmount),
      materialsAmount: Number(values?.materialsAmount),
      otherPreliminariesAmount: Number(values?.otherPreliminariesAmount),
      staffSalariesAmount: Number(values?.staffSalariesAmount),
      contractId: Number(id),
      status: 2,
    };

    if (editingRowId === null) {
      await crud.create('contractbudgetings', convertedValues, (err, res) => {
        if (res?.status === 201) {
          setEditingRowId(res?.data?.contractBudgetingId);
          // setValue('contractBudgetingId', res?.data?.contractBudgetingId);
        } else {
          setEditingRowId(null);
        }
      });
    } else {
      editingRowId &&
        (await crud.update(
          'contractbudgetings',
          editingRowId,
          updatedCombinedData,
          (err, res) => {
            if (res?.status === 200) {
              if (rows.some((row) => row.isNew)) {
                const updatedRow = res?.data;
                const updatedRows = [...rows];
                updatedRows[editingRowIndex] = {
                  ...updatedRow,
                  isEditable: true,
                  isNew: true,
                };
                setRows(updatedRows);
                setStateError(false);
                setLoader(false);
              } else {
                setStateError(false);
                setLoader(false);
                const updatedRow = res?.data;
                const updatedRows = [...rows];

                updatedRows[editingRowIndex] = updatedRow;

                setRows(updatedRows);
              }
            } else {
              setStateError(true);
            }
          }
        ));
    }
  };

  //final Step Api Call
  const handleBlurAndFetch = async () => {
    setLoader(true);
    const values = getValues();

    const updateCombinedData = {
      ...values,
      contractId: id,
      status: 2,
    };
    await crud.update(
      'contractbudgetings',
      editingRowId,
      updateCombinedData,
      (err, res) => {
        if (res?.status === 200) {
          const totalRows = rows.length;
          if (editingRowIndex <= totalRows && rows.some((row) => row.isNew)) {
            const updatedRow = res?.data;
            const updatedRows = [...rows];
            updatedRows[editingRowIndex] = {
              ...updatedRow,
              isEditable: true,
              isNew: true,
            };
            setRows(updatedRows);
            handleAddNewRow();
          } else {
            const updatedRow = res?.data;
            const updatedRows = [...rows];
            updatedRows[editingRowIndex] = {
              ...updatedRow,
              isEditable: false,
              isNew: false,
            };

            setRows(updatedRows);
            setStateError(false);
            setLoader(false);
          }
        } else {
          setStateError(true);
        }
      }
    );
  };

  const handleDeleteBudget = async (view) => {
    await crud.remove(
      'contractbudgetings',
      view?.contractBudgetingId,
      (err, res) => {
        if (res?.status === 204) {
          fetchData();
          toast.success('Contract Budget Deleted Successfully');
        } else {
        }
      }
    );
  };

  const handleInputChange = async (e, field) => {
    const value = parseFloat(e.target.value) || 0;
    const currentValues = getValues();
    const updatedValues = {
      ...currentValues,
      [field]: value,
    };

    const newTotal =
      (Number(updatedValues?.staffSalariesAmount) || 0) +
      (Number(updatedValues?.otherPreliminariesAmount) || 0) +
      (Number(updatedValues?.labourAmount) || 0) +
      (Number(updatedValues?.materialsAmount) || 0);

    setValue('totalAmount', newTotal);
    setValue(field, value);

    await trigger();
  };
  // const validateTotalBudget = () => {
  //   const currentValues = getValues();
  //   const totalBudget =
  //     rows
  //       .filter(
  //         (row) =>
  //           row?.contractBudgetingId !== currentValues?.contractBudgetingId
  //       )
  //       .reduce((acc, row) => acc + (row?.budget || 0), 0) +
  //     (currentValues?.budget || 0);
  //   return totalBudget <= contractData?.budget;
  // };
  const validateTotalBudget = () => {
    const currentValues = getValues();
    const totalBudget =
      rows
        .filter(
          (row) =>
            row?.contractBudgetingId !== currentValues?.contractBudgetingId
        )
        .reduce((acc, row) => acc + (row?.budget || 0), 0) +
      (currentValues?.budget || 0);

    if (totalBudget > contractData?.budget) {
      toast.error('The total budget of all rows exceeds the contract budget.');
      return false;
    }

    return true;
  };

  const ActionData = [
    {
      name: 'Delete',
      icon: Icons.delete,
      onClick: (props) => handleDeleteBudget(props),
      danger: true,
    },
  ];

  const handleAddNewRow = () => {
    const newId = 'new_row_' + Date.now();
    reset();

    setEditingRowId(null);
    setRowState({
      newRowId: newId,
      isCreatingRow: true,
    });
    setEditingRowIndex(
      editingRowIndex === rows.length ? editingRowIndex + 1 : rows.length
    );
    setRows((prevRows) => [
      ...prevRows,
      {
        contractBudgetingId: newId,
        contractId: id,
        divisionId: '',
        budget: '',
        staffSalariesAmount: '',
        otherPreliminariesAmount: '',
        labourAmount: '',
        materialsAmount: '',
        isEditable: true,
        isNew: true,
      },
    ]);
    reset();
  };

  const columns = [
    {
      accessorKey: 'divisionId',
      header: 'Division',
      enableEditing: true,
      Cell: ({ row }) => {
        const isNewRow =
          row?.original?.contractBudgetingId === rowState?.newRowId;
        const isEditing =
          editingRowId === row?.original?.contractBudgetingId || isNewRow;
        return isEditing ? (
          <FmSearchableSelect
            name='divisionId'
            control={control}
            apiUrl='divisions'
            valueField={'divisionId'}
            headerField={['Name', 'Code']}
            labelField={['divisionName', 'divisionCode']}
            showField={['divisionName', 'divisionCode']}
            inputRef={firstColumnInputRef}
            label={'Division'}
            onBlur={handleBlur}
            defaultValue={{
              divisionId: row?.original?.divisionId,
              divisionName: row?.original?.divisionName,
            }}
          />
        ) : (
          row?.original?.divisionName || ''
        );
      },
    },
    {
      accessorKey: 'budget',
      header: 'Budget Amount',
      enableEditing: true,
      Cell: ({ row }) => {
        const isNewRow =
          row?.original?.contractBudgetingId === rowState?.newRowId;
        const isEditing =
          editingRowId === row?.original?.contractBudgetingId || isNewRow;
        return isEditing ? (
          <Box sx={{ width: '100%' }}>
            <FmTextField
              name='budget'
              control={control}
              onBlur={handleBlur}
              inputRef={secondColumnInputRef}
              type='number'
              onChange={(e) => handleInputChange(e, 'budget')}
            />
          </Box>
        ) : (
          row?.original?.budget
        );
      },
    },

    {
      accessorKey: 'staffSalariesAmount',
      header: 'Staff Salaries',
      enableEditing: true,
      Cell: ({ row }) => {
        const isNewRow =
          row?.original?.contractBudgetingId === rowState?.newRowId;
        const isEditing =
          editingRowId === row?.original?.contractBudgetingId || isNewRow;
        return isEditing ? (
          <FmTextField
            name='staffSalariesAmount'
            control={control}
            onBlur={handleBlur}
            type='Number'
            onChange={(e) => handleInputChange(e, 'staffSalariesAmount')}
          />
        ) : (
          row?.original?.staffSalariesAmount
        );
      },
    },
    {
      accessorKey: 'otherPreliminariesAmount',
      header: 'Other Preliminaries',
      enableEditing: true,
      Cell: ({ row }) => {
        const isNewRow =
          row?.original?.contractBudgetingId === rowState?.newRowId;
        const isEditing =
          editingRowId === row?.original?.contractBudgetingId || isNewRow;
        return isEditing ? (
          <FmTextField
            name='otherPreliminariesAmount'
            control={control}
            onBlur={handleBlur}
            type='Number'
            onChange={(e) => handleInputChange(e, 'otherPreliminariesAmount')}
          />
        ) : (
          row?.original?.otherPreliminariesAmount
        );
      },
    },
    {
      accessorKey: 'labourAmount',
      header: 'Labour Amount',
      enableEditing: true,
      Cell: ({ row }) => {
        const isNewRow =
          row?.original?.contractBudgetingId === rowState?.newRowId;
        const isEditing =
          editingRowId === row?.original?.contractBudgetingId || isNewRow;
        return isEditing ? (
          <FmTextField
            name='labourAmount'
            control={control}
            onBlur={handleBlur}
            type='Number'
            onChange={(e) => handleInputChange(e, 'labourAmount')}
          />
        ) : (
          row?.original?.labourAmount
        );
      },
    },
    {
      accessorKey: 'materialsAmount',
      header: 'Material Amount',
      enableEditing: true,
      size: 250,
      Cell: ({ row }) => {
        const isNewRow =
          row?.original?.contractBudgetingId === rowState?.newRowId;
        const isEditing =
          editingRowId === row?.original?.contractBudgetingId || isNewRow;
        return isEditing ? (
          <Box sx={{ width: '100%' }}>
            <FmTextField
              name='materialsAmount'
              control={control}
              onBlur={handleBlurAndFetch}
              type='Number'
              sx={{ width: '100%' }}
              onChange={(e) => handleInputChange(e, 'materialsAmount')}
            />
          </Box>
        ) : (
          row?.original?.materialsAmount
        );
      },
    },
    {
      accessorKey: 'totalAmount',
      header: 'Total Amount',
      enableEditing: false,
      Cell: ({ row }) => {
        const isNewRow =
          row?.original?.contractBudgetingId === rowState?.newRowId;
        const isEditing =
          editingRowId === row?.original?.contractBudgetingId || isNewRow;
        const currentValues = getValues();
        const totalAmount =
          (Number(currentValues?.staffSalariesAmount) || 0) +
          (Number(currentValues?.otherPreliminariesAmount) || 0) +
          (Number(currentValues?.labourAmount) || 0) +
          (Number(currentValues?.materialsAmount) || 0);

        return isEditing ? (
          <Box sx={{ width: '100%' }}>
            {totalAmount}
            {
              <Typography
                variant='caption'
                className='text-yellow-400 text-xs block mt-1'
              >
                {getValues('totalAmount') > getValues('budget')
                  ? 'The total amount exceeds the budget.'
                  : ''}
              </Typography>
            }
          </Box>
        ) : (
          row.original?.totalAmount
        );
      },
    },
    {
      header: 'Action',
      enableColumnPinning: true,
      enableEditing: false,
      enableSorting: false,
      size: 100,
      Cell: ({ row, table }) => {
        const isNewRow =
          row?.original?.contractBudgetingId === rowState?.newRowId;
        const isEditing =
          editingRowId === row?.original?.contractBudgetingId || isNewRow;

        // Check if the current row is the last row in the table
        const isLastRow = row.index === table.getRowModel().rows.length - 1;

        return (
          <Box className='flex gap-2'>
            {isEditing ? (
              <>
                <Button onClick={() => handleCancel(row)}>Close</Button>
                {isLastRow && rowState?.isCreatingRow === false && (
                  <Button
                    onFocus={() => handleCancelAndAddNewRow()}
                    hidden={true}
                  ></Button>
                )}
              </>
            ) : (
              <Box className='flex gap-2'>
                <TableActionButtons
                  Actions={ActionData?.map((action) => ({
                    ...action,
                    onClick: () => action.onClick(row?.original),
                  }))}
                />
                {isLastRow && (
                  <Button
                    variant='contained'
                    color='primary'
                    className='px-3 py-1'
                    onClick={() => {
                      setEditingRowIndex(row?.index + 1);
                      handleAddNewRow();
                    }}
                  >
                    Add
                  </Button>
                )}
              </Box>
            )}
          </Box>
        );
      },
    },
  ];

  return (
    <>
      <GlassCard className='mt-5'>
        <FmMRTDataTable
          rows={rows}
          columns={columns}
          onCreateRow={handleAddNewRow}
          isCreatingRow={rowState?.isCreatingRow}
          enableRowSelection={false}
          rowCount={tableRecordCounts}
          editingRow={editingRowId}
          fetchData={fetchData}
          pagination={pagination}
          sorting={sorting}
          columnFilters={columnFilters}
          globalFilter={globalFilter}
          setPagination={setPagination}
          setSorting={setSorting}
          setColumnFilters={setColumnFilters}
          setGlobalFilter={setGlobalFilter}
          isLoading={isLoading}
          setSearchKeyword={setSearchKeyword}
          renderTopToolbarCustomActionsChildrens={
            <Typography
              variant='caption'
              className='text-red-600 text-xs block mt-1'
            >
              {!validateTotalBudget() && (
                <Typography color='error'>
                  The total budget of all rows exceeds the contract budget.
                </Typography>
              )}
            </Typography>
          }
          searchKeyword={searchKeyword}
          handleRowDoubleClick={(row) => {
            handleEdit(row?.original);
            setEditingRowIndex(row?.index);
          }}
          muiTableContainerProps={{
            sx: {
              maxHeight: '350px',
              flex: 1,
            },
          }}
        />
      </GlassCard>

      <MuiDialogOne
        title='View Details'
        open={viewDetails}
        onClose={() => setViewDetails(!viewDetails)}
        width='500px'
      >
        {viewDetails && viewDetails?.contractBudgetingId && (
          <CommonView
            url='contractbudgetings'
            id={viewDetails?.contractBudgetingId}
            excludeKeys={excludeKeys}
            renderFields={(data, fields) => (
              <DynamicViewFields
                data={Object.fromEntries(
                  Object.entries(data).map(([key, value]) => [
                    key,
                    typeof value === 'object' ? JSON.stringify(value) : value,
                  ])
                )}
                fields={fields}
              />
            )}
          />
        )}
      </MuiDialogOne>
    </>
  );
};

export default ViewContractBudgeting;
