import { Icon } from '@iconify/react';
import { Alert, Avatar, Box, Button, IconButton } from '@mui/material';
import React, { useEffect, useState } from 'react';
import { useDropzone } from 'react-dropzone';
import { toast } from 'react-toastify';
import Crud_Service from '../../apis/CrudService';
import MuiDialogOne from '../_mui/MuiDialogOne';
import GlassCard from '../small/GlassCard.tsx';
import UploadModalFileViewer from './UploadModalFileViewer.tsx';

const UploadModalBox = ({
  onSubmitFiles,
  removeBorder,
  previewdData,
  uploadSingle = false,
  label = 'Upload',
}) => {
  const [files, setFiles] = useState([]);
  const [loading, setLoading] = useState(false);
  const [view, setView] = useState(false);
  const crud = new Crud_Service();
  const [singleFile, setSingleFile] = useState(false);
  const [errorMessage, setErrorMessage] = useState('');
  const [openFile, setOpenFile] = useState(false);
  const [disableSubmit, setDisableSubmit] = useState(false);

  const handleCloseModal = () => {
    setView(false);
  };

  const { getRootProps, getInputProps, isDragActive } = useDropzone({
    accept: 'image/*,application/pdf',
    multiple: true,
    onDrop: (acceptedFiles) => {
      setLoading(true);
      setFiles((prevFiles) => {
        const existingPaths = new Set(prevFiles?.map((file) => file?.path));
        const filteredFiles = acceptedFiles?.filter(
          (file) => !existingPaths.has(file?.path)
        );
        const newFiles = filteredFiles?.map((file) =>
          Object.assign(file, {
            preview: URL.createObjectURL(file),
          })
        );
        return [...prevFiles, ...newFiles];
      });
      setLoading(false);
    },
  });

  useEffect(() => {
    if (files?.length > 1 && uploadSingle) {
      setSingleFile(true);
      setErrorMessage('Only one file is allowed');
    } else {
      setSingleFile(false);
      setErrorMessage('');
    }
  }, [files]);

  const handleRemoveFile = (fileName) => {
    setFiles((prevFiles) => prevFiles?.filter((file) => file.name !== fileName));
  };

  const handleViewFile = (file) => {
    setOpenFile(!openFile);
  };

  const handleSubmit = async () => {
    try {
      setLoading(true);

      const fileIds = await Promise.all(
        files.map(async (file) => {
          if (file?.apiCallNo === false) {
            return;
          } else {
            const fileId = await uploadFile(file);
            return fileId; // Collect fileId for each file
          }
        })
      );

      const validFileIds = fileIds.filter((fileId) => fileId !== null);
      onSubmitFiles(validFileIds);
      setLoading(false);
    } catch (error) {
      setLoading(false);
    }
  };

  const uploadFile = async (file) => {
    const formData = new FormData();
    setDisableSubmit(true);
    formData.append('file', file);
    formData.append('fileTypeId', 81);

    try {
      return await new Promise((resolve, reject) => {
        crud.create('/files/upload', formData, (_err, res) => {
          if (res?.status === 200) {
            const newFile = {
              fileName: res?.data?.originalFileName,
              fileId: res?.data?.fileId,
            };
            resolve(newFile.fileId); // Resolve with fileId
            toast.success('File uploaded successfully');
            setView(false);
            setLoading(false);
          } else {
            toast.error('Failed to upload file.');
            reject(new Error('Failed to upload file.'));
            setLoading(false);
            setView(false);
          }
          setDisableSubmit(false);
        });
      });
    } catch (error) {
      setLoading(false);
      setView(false);
      return null;
    }
  };

  useEffect(() => {
    if (previewdData) {
      const parsedFiles = previewdData
        .map((data, index) => {
          if (typeof data === 'string' && data.includes(';base64,')) {
            const [type] = data.split(';base64,');
            const fileType = type.split(':')[1];
            return {
              type: fileType,
              preview: data,
              name: `File-${index + 1}`,
              apiCallNo: false,
            };
          }
          return null;
        })
        .filter((file) => file !== null);

      setFiles(parsedFiles);
    }
  }, [previewdData]);

  return (
    <>
      <Button
        className='flex items-center gap-3 justify-start py-3 font-semibold'
        onClick={() => setView(!view)}
        fullWidth
        sx={{
          border: !removeBorder && '1px solid',
          borderColor: !removeBorder && 'border.dark',
          borderRadius: '0px',
        }}
      >
        {files?.length > 0 ? (
          <>
            <span className='text-lg'>
              <Icon icon='oi:circle-check' />
            </span>
            <span>Uploaded files - {files?.length}</span>
          </>
        ) : (
          <>
            {' '}
            <span className='text-lg'>
              <Icon icon='ph:upload-bold' />
            </span>
            <span style={{ lineHeight: 1 }}>{label}</span>
          </>
        )}
      </Button>
      <MuiDialogOne
        title='Upload Images'
        open={view}
        onClose={handleCloseModal}
        width='550px'
      >
        {singleFile && (
          <Alert variant='filled' severity='error' className='mb-5'>
            {errorMessage}
          </Alert>
        )}

        <Box
          {...getRootProps()}
          className='w-full py-8 px-2 rounded-sm text-center'
          sx={{
            border: '2px dashed',
            borderColor: 'primary.main',
            bgcolor: isDragActive ? 'background.light' : 'background.mainLight',
            cursor: 'pointer',
          }}
        >
          <input {...getInputProps()} disabled={loading} />

          <Box
            className='text-5xl flex justify-center mb-4'
            sx={{ color: 'primary.main' }}
          >
            <Icon icon='ph:upload' />
          </Box>

          <Box className='text-base font-semibold mb-2'>
            {isDragActive
              ? 'Drop files here...'
              : 'Drag & drop files here, or click to select files'}
          </Box>

          <Button
            variant='outlined'
            color='primary'
            className='font-semibold'
            onClick={() => { }}
          >
            Upload Files
          </Button>
        </Box>

        <Box className='mt-5'>
          {files.length > 0 &&
            files.map((file, index) => (
              <GlassCard
                key={index}
                className='flex justify-between items-center p-1 px-2 mb-3'
              >
                <Box className='flex gap-3 items-center w-full'>
                  {file.type.startsWith('image/') ? (
                    <Avatar
                      sx={{ bgcolor: 'primary.main' }}
                      src={file.preview}
                      alt={file.name}
                    ></Avatar>
                  ) : (
                    <Avatar sx={{ bgcolor: 'primary.main' }}>
                      <Icon icon='pepicons-pencil:file' />
                    </Avatar>
                  )}

                  <h3 className='font-bold flex-1 min-w-0'>{file.name}</h3>

                  <Box className='flex gap-2 items-center'>
                    <IconButton
                      className='p-2'
                      onClick={() => handleViewFile(file)}
                    >
                      <Icon icon='mdi:eye' width='24' height='24' />
                    </IconButton>
                    <UploadModalFileViewer
                      open={openFile}
                      onClose={() => setOpenFile(!openFile)}
                      documents={[
                        {
                          uri: file?.preview,
                        },
                      ]}
                    />
                    <IconButton
                      color='error'
                      onClick={() => handleRemoveFile(file.name)}
                      className='p-2'
                    >
                      <Icon icon='ic:baseline-clear' />
                    </IconButton>
                  </Box>
                </Box>
              </GlassCard>
            ))}
        </Box>

        <Box className='flex justify-between gap-4 mt-10'>
          <Button
            variant='outlined'
            onClick={() => setFiles([])}
            className='min-w-28'
          >
            Clear
          </Button>
          <Button
            variant='contained'
            onClick={handleSubmit}
            disabled={
              files.length === 0 ||
              (uploadSingle === true && singleFile) ||
              disableSubmit
            }
            className='min-w-28 font-bold'
          >
            Submit
          </Button>
        </Box>
      </MuiDialogOne>
    </>
  );
};

export default UploadModalBox;

