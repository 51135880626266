import React, { useEffect, useState } from 'react';
import {
  Grid,
  Box,
  IconButton,
  Checkbox,
  FormControlLabel,
} from '@mui/material';
import FmTextField from '../../../../components/_mui/FmTextField.tsx';
import { Controller, useFieldArray, useForm, useWatch } from 'react-hook-form';
import FmStack from '../../../../components/layout/FmStack.tsx';
import ActionButtons from '../../../../components/_form/ActionButtons.tsx';
import { Icon } from '@iconify/react';
import ConfirmationPopoverBox from '../../../../components/_form/ConfirmationPopoverBox.tsx';
import { yupResolver } from '@hookform/resolvers/yup';
import { SalesOrderMileStoneSchema } from '../../../../utils/CommonvalidationSchemas.tsx';
import Crud_Service from '../../../../apis/CrudService.jsx';
import { toast } from 'react-toastify';
import GlassCard from '../../../../components/small/GlassCard.tsx';
import { SkeletonLoaderRow } from '../../../../components/page/SkeletonLoader.tsx';
import useLoader from '../../../../components/helpers/UseLoader.tsx';
import { useParams } from 'react-router-dom';
import { salesOrderStatusEnum } from '../../../../utils/CommonVariables.tsx';

const GridSize = 2.4;

const SOMilestones = (props) => {
  const { onClose } = props;
  const { startCreateLoading, stopCreateLoading } = useLoader();
  const salesOrderId = useParams().id;
  const crud = new Crud_Service();
  const {
    control,
    handleSubmit,
    reset,
    setValue,
    watch,
    getValues,
    formState: { errors },
  } = useForm({
    resolver: yupResolver(SalesOrderMileStoneSchema),
    mode: 'onChange',
    defaultValues: { salesMileStoneAmounts: [] },
  });
  const { fields, append, remove } = useFieldArray({
    control,
    name: 'salesMileStoneAmounts',
  });
  const [loader, setLoading] = useState(false);
  const [roundDetails, setRoundDetails] = useState([]);

  useEffect(() => {
    append({});
    fetchDataItems();
  }, []);

  const addNewMilestone = () => {
    append({});
  };

  const deleteMilestone = async (
    salesMileStoneId: number,
    index: number
  ) => {
    remove(index);
    // setLoading(true);
    // await crud.remove(
    //   'salesquotationmileStones',
    //   salesMileStoneId,
    //   (err, res) => {
    //     if (res.status === 204) {
    //       setLoading(false);
    //       fetchDataItems();
    //     }
    //   }
    // );
  };

  const fetchDataItems = async () => {
    setLoading(true);
    await crud.getAll(
      'salesmilestones',
      { salesId: salesOrderId },
      (_err, res) => {
        if (res?.status === 200) {
          setRoundDetails(res?.data?.data[0]);
          reset({
            salesMileStoneAmounts:
              res?.data?.data[0]?.salesMileStoneAmounts,
            advanceAmount: res?.data?.data[0]?.advanceAmount,
            retentionAmount: res?.data?.data[0]?.retentionAmount,
            totalBudget: res?.data?.data[0]?.totalBudget,
          });
          res?.data?.data[0]?.salesMileStoneAmounts?.length < 1 &&
            append({});
        }
        setLoading(false);
      }
    );
  };
  const onSubmit = async (values) => {
    startCreateLoading();
    const updatedPayload = {
      salesOrderId: salesOrderId,
      advanceAmount: values.advanceAmount,
      retentionAmount: values.retentionAmount,
      createSalesMileStoneAmounts:
        values?.salesMileStoneAmounts.map((item) => ({
          mileStoneName: item.mileStoneName ? item.mileStoneName : '',
          mileStoneAmount: item.mileStoneAmount ? item.mileStoneAmount : 0,
          totalBudget: item.totalBudget ? item.totalBudget : 0,
          salesMileStoneId: item.salesMileStoneId || 0,
        })),
    };

    await crud.create(
      'salesmilestones',
      updatedPayload,
      (err, res) => {
        if (err) {
          stopCreateLoading();
          return;
        }
        if (res?.status === 201) {
          toast.success('Milestone Added Successfully');
          fetchDataItems();
          stopCreateLoading();
        } else {
          stopCreateLoading();
        }
      }
    );
  };
  const handleRemove = async (index) => {
    const itemToDelete = roundDetails?.salesMileStoneAmounts[index];
    if (!itemToDelete?.salesMileStoneId) {
      // Remove the item directly if it doesn't have a salesMileStoneId
      remove(index);
      return;
    }

    await crud.remove(
      'salesmilestones',
      itemToDelete.salesMileStoneId,
      (err, res) => {
        if (res?.status === 204) {
          toast.success('Sales Milestone Deleted Successfully');
          fetchDataItems();
        } else {
        }
      }
    );
  };
  useEffect(() => {
    // Automatically check the checkboxes if the respective amounts are greater than 0
    if (roundDetails?.advanceAmount > 0) {
      setValue('isAdvanceAmount', true);
    } else {
      setValue('isAdvanceAmount', false);
    }

    if (roundDetails?.retentionAmount > 0) {
      setValue('isRetentionAmount', true);
    } else {
      setValue('isRetentionAmount', false);
    }
  }, [roundDetails?.advanceAmount, roundDetails?.retentionAmount, setValue]);

  // Watch the checkbox values dynamically
  const isAdvanceChecked = useWatch({ control, name: 'isAdvanceAmount' });
  const isRetentionChecked = useWatch({ control, name: 'isRetentionAmount' });

  return loader ? (
    <SkeletonLoaderRow count={10} />
  ) : (
    <GlassCard className='p-4'>
      <Box component='form' onSubmit={handleSubmit(onSubmit)}>
        <FmStack spacing={1.5}>
          {/* Advance Amount Checkbox and Input */}

          <Box>
            <Grid container spacing={1}>
              <Grid item md={6} xs={12}>
                <Box className='mb-2'>
                  <Controller
                    name={`isAdvanceAmount`}
                    control={control}
                    render={({ field }) => (
                      <FormControlLabel
                        control={
                          <Checkbox
                            checked={Boolean(field.value)}
                            onChange={(ev) => {
                              const newValue = ev.target.checked;
                              setValue('isAdvanceAmount', newValue);
                              field.onChange(newValue);
                            }}
                            color='primary'
                            className='p-0 me-3'

                          />
                        }
                        label='Advance Amount'
                      />
                    )}
                  />
                </Box>
                {(roundDetails?.advanceAmount > 0 || isAdvanceChecked) && (
                  <FmTextField
                    name={`advanceAmount`}
                    control={control}
                    label='Advance Amount'
                    defaultValue={roundDetails?.advanceAmount}
                    type='number'

                  />
                )}
              </Grid>
              <Grid item md={6} xs={12}>
                <Box className='mb-2'>
                  <Controller
                    name={`isRetentionAmount`}
                    control={control}
                    render={({ field }) => (
                      <FormControlLabel
                        control={
                          <Checkbox
                            checked={Boolean(field.value)}
                            onChange={(ev) => {
                              const newValue = ev.target.checked;
                              setValue(`isRetentionAmount`, newValue);
                              field.onChange(newValue);
                            }}
                            color='primary'
                            className='p-0 me-3'

                          />
                        }

                        label='Retention Amount'
                      />
                    )}
                  />
                </Box>
                {(roundDetails?.retentionAmount > 0 || isRetentionChecked) && (
                  <FmTextField
                    name={`retentionAmount`}
                    defaultValue={roundDetails?.retentionAmount}
                    control={control}
                    label='Retention Amount'
                    type='number'
                  />
                )}
              </Grid>
            </Grid>
          </Box>

          {fields?.map((field, index) => (
            <GlassCard
              className='relative p-2 pe-[100px]'
              key={field.id}
              bgLight
            >
              <Box>
                {/* Multiple MileStone Fields */}

                <Grid container spacing={3}>
                  <Grid item md={GridSize * 3}>
                    <FmTextField
                      name={`salesMileStoneAmounts.${index}.mileStoneName`}
                      control={control}
                      label='Name'
                      required

                    />
                  </Grid>
                  <Grid item md={GridSize * 1.5}>
                    <FmTextField
                      name={`salesMileStoneAmounts.${index}.mileStoneAmount`}
                      control={control}
                      label='Total Amount'
                      type='number'
                      defaultValue={0}

                    />
                  </Grid>
                  <Grid item md={GridSize * 1.5}>
                    <FmTextField
                      name={`salesMileStoneAmounts.${index}.totalBudget`}
                      control={control}
                      label='Total Budget'
                      type='number'
                      defaultValue={0}
                    />
                  </Grid>
                </Grid>
              </Box>

              {/* Delete Milestone Button */}
              {
                <Box className='flex items-center justify-end gap-2 absolute right-2 top-1/2 translate-y-[-50%]'>
                  {index === fields.length - 1 && (
                    <IconButton
                      className='p-2'
                      onClick={() =>
                        append({
                          mileStoneName: '',
                          mileStoneAmount: '',
                          totalBudget: ''
                        })
                      }
                    >
                      <Icon icon='ic:baseline-plus' width='22' height='22' />
                    </IconButton>
                  )}

                  <ConfirmationPopoverBox
                    icon={<Icon icon='tabler:trash' width='22' height='22' />}
                    title='Delete Milestone'
                    message='Are you sure you want to delete this Milestone?'
                    onConfirm={() => handleRemove(index)}
                    disabled={fields.length === 1}
                  />
                </Box>
              }
            </GlassCard>
          ))}

        </FmStack>
        <ActionButtons onSubmit={handleSubmit(onSubmit)} onCancel={onClose} />
      </Box>
    </GlassCard>
  );
};

export default SOMilestones;
