import React from 'react';
import { Button, Box } from '@mui/material';
import { Icon } from '@iconify/react';

const SideBarMenuButton = ({ name, icon, ref, ...props }) => {
  const renderIcon = (iconStr) => {
    const match = iconStr.match(/icon=['"](.+?)['"]/);
    if (!match) return null;

    return <Icon icon={match[1]} />;
  };

  return (
    <Button
      sx={{
        width: '100%',
        justifyContent: 'flex-start',
        paddingLeft: '5px',
        paddingRight: '5px',
        py: 0,
        textAlign: 'left',
        lineHeight: '20px',
        fontWeight: 500,
        borderRadius: '5px',
        opacity: 1,
        color: 'inherit',
        '& .icon': {
          opacity: 0.7,
        },
        '&:hover': {
          bgcolor: 'primary.light',
          '& .icon': {
            opacity: 1,
          },
          '&:before': {
            height: '100%',
          },
        },
        '&:before': {
          content: '""',
          width: '2px',
          height: '0px',
          position: 'absolute',
          top: '0px',
          bgcolor: 'text.main',
          left: '-8px',
          transition: '0.2s all',
        },
        '&.active': {
          bgcolor: 'primary.light',
          color: 'primary.main',
          fontWeight: 500,
          opacity: 1,
          '& .icon': {
            opacity: 1,
          },
          '&:before': {
            height: '100%',
            bgcolor: 'primary.main',
          },
        },
      }}
      title={name}
      ref={ref}
      {...props}
    >
      <Box
        className='icon flex justify-center items-center'
        sx={{
          minWidth: '28px',
          height: '28px',
          marginRight: '5px',
          '& svg': {
            fontSize: '18px',
          },
        }}
      >
        {renderIcon(icon)}
      </Box>
      <Box sx={{ width: 'calc(100% - 45px)' }}>
        <Box className='w-full whitespace-nowrap overflow-hidden text-ellipsis'>
          {name}
        </Box>
      </Box>
    </Button>
  );
};

export default SideBarMenuButton;
