// useDateFormatter.js
import dayjs from 'dayjs';
import customParseFormat from 'dayjs/plugin/customParseFormat';
import utc from 'dayjs/plugin/utc';
import { useSelector } from 'react-redux';

dayjs.extend(customParseFormat);
dayjs.extend(utc);

const useDateFormatter = () => {
  const settings = useSelector((state) => state.settings);

  const commonDateFormats = [
    'YYYY-MM-DD',
    'YYYY-MM-DDTHH:mm:ss',
    'YYYY-MM-DDTHH:mm:ss.SSSZ',
    'YYYY-MM-DDTHH:mm:ss.SSSSSSS',
  ];

  const isDateField = (value) => {
    return commonDateFormats.some((format) =>
      dayjs(value, format, true).isValid()
    );
  };

  const formatDate = (date) => {
    if (isDateField(date) && settings?.dateFormat) {
      const parsedDate = dayjs(date).format(settings.dateFormat || 'DD-MM-YYYY');

      if (parsedDate !== 'Invalid Date') {
        return parsedDate;
      }
    }

    return date;
  };

  return formatDate;
};

export default useDateFormatter;
