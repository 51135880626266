import React from 'react';
import { Box, Button, CircularProgress } from '@mui/material';
import { useHotkeys } from 'react-hotkeys-hook';

interface ActionButtonsImpl {
  onSubmit: () => void;
  onCancel?: () => void;
  submitLoading?: boolean;
  cancelLoading?: boolean;
  submitText?: string | undefined;
  cancelText?: string | undefined;
  removeSpacing?: boolean | undefined;
  errorFields?: boolean;
  disabled?: boolean;
  color?: string;
  submitFullWidth?: boolean;
  loadingText?: String;
}

const ActionButtons = (props: ActionButtonsImpl) => {
  const {
    onSubmit,
    onCancel,
    submitLoading,
    cancelLoading,
    color,
    submitText = 'Submit',
    cancelText = 'Cancel',
    removeSpacing,
    errorFields,
    disabled = false,
    loadingText = 'Submitting...',
    submitFullWidth = false,
  } = props;

  useHotkeys(
    'ctrl+s',
    (event) => {
      event.preventDefault();
      if (!submitLoading && !cancelLoading && !errorFields && !disabled) {
        onSubmit();
      }
    },
    [onSubmit, submitLoading, cancelLoading, errorFields, disabled]
  );

  return (
    <Box
      className={`flex flex-row gap-2 ${removeSpacing ? '' : 'mt-5'}`}
      sx={{
        '& .MuiButtonBase-root': {
          minWidth: '120px',
          minHeight: 40,
          fontWeight: 600,
        },
        display: 'flex',
        justifyContent: 'flex-end',
      }}
    >
      <Box
        className={`flex items-center gap-2 ${submitFullWidth ? 'w-full' : ''}`}
      >
        {onCancel && (
          <Button
            variant='outlined'
            onClick={onCancel}
            disabled={submitLoading || cancelLoading}
            className='px-4 py-1'
          >
            {cancelLoading ? 'Cancelling...' : cancelText}
          </Button>
        )}

        {onSubmit && (
          <Button
            color={color || 'primary'}
            variant='contained'
            onClick={onSubmit}
            disabled={submitLoading || cancelLoading || errorFields || disabled}
            className={`px-4 py-1 relative ${submitFullWidth && 'w-full'}`}
          >
            {submitLoading ? loadingText : submitText}

            {submitLoading && (
              <Box className='absolute right-3 top-0 flex h-full items-center'>
                <CircularProgress size={17} color='inherit' />
              </Box>
            )}
          </Button>
        )}
      </Box>
    </Box>
  );
};

export default ActionButtons;
