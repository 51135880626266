import { CircularProgress } from '@mui/material';
import Alert from '@mui/material/Alert';
import Box from '@mui/material/Box';
import Button from '@mui/material/Button';
import Checkbox from '@mui/material/Checkbox';
import FormControlLabel from '@mui/material/FormControlLabel';
import Stack from '@mui/material/Stack';
import TextField from '@mui/material/TextField';
import { InputAdornment, IconButton } from '@mui/material';
import * as React from 'react';
import { useForm } from 'react-hook-form';
import { useTranslation } from 'react-i18next';
import Lottie from 'react-lottie';
import { useDispatch } from 'react-redux';
import { Link, useNavigate } from 'react-router-dom';
import * as animationData from '../../assets/lottie/login.json';
import LoginLayout from '../../components/LoginLayout';
import ArrowWithLink from '../../components/small/ArrowWithLink.tsx';
import { loginRequest } from '../../redux/loginSlice.ts';
import { Icon } from '@iconify/react/dist/iconify.js';

export default function Login() {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const { t } = useTranslation();
  const { handleSubmit, register } = useForm();
  const [loading, setLoading] = React.useState(false);
  const [errorMessage, setErrorMessage] = React.useState('');
  const [passwordVisible, setPasswordVisible] = React.useState(false);

  const handleLogin = async (values) => {
    setLoading(true);
    setErrorMessage('');

    try {
      const result = await dispatch(
        loginRequest({
          payload: values,
          navigate, // Pass navigate here
        })
      );

      if (loginRequest.fulfilled.match(result)) {
        // navigate('/hrms/dashboard');
      } else if (loginRequest.rejected.match(result)) {
        setErrorMessage(result.payload || 'Login failed. Please try again.');
      }
    } catch (error) {
      setErrorMessage('An unexpected error occurred. Please try again.');
    } finally {
      setLoading(false);
    }
  };

  return (
    <>
      <LoginLayout
        lottieFiles={
          <Lottie
            options={{
              loop: true,
              autoplay: true,
              animationData: animationData,
            }}
            height={'98%'}
            width={'98%'}
          />
        }
      >
        {/* box */}

        {/* form */}
        <Box
          className='flex flex-col justify-between w-full min-h-full gap-5 items-center'
          sx={{
            maxWidth: '380px',
          }}
        >
          <Box
            sx={{
              width: '100%',
              textAlign: 'center',
              color: 'text.main',
            }}
          >
            <Box
              component='img'
              src='/images/logo-black.png'
              sx={{ maxWidth: 220, marginBottom: '10px' }}
              className='inline-block'
            />
          </Box>

          <Box
            component='form'
            onSubmit={handleSubmit(handleLogin)}
            noValidate
            sx={{ width: '100%' }}
          >
            <Stack direction='column' sx={{ width: '100%' }}>
              <Box className='mb-4'>
                <Box
                  sx={{ color: 'text.dark' }}
                  className='font-semibold text-lg mb-2'
                >
                  {t('greeting')}
                </Box>
                <Box>{t('greeting_sub')}</Box>
              </Box>
              {errorMessage && (
                <Alert severity='error' sx={{ mb: 2 }}>
                  {errorMessage}
                </Alert>
              )}
              <Box>
                <TextField
                  margin='normal'
                  required
                  fullWidth
                  label={'User Name'}
                  {...register('username')}
                  autoComplete='username'
                  variant='filled'
                />
              </Box>

              <Box>
                <TextField
                  margin='normal'
                  required
                  fullWidth
                  label='Password'
                  type={passwordVisible ? 'text' : 'password'}
                  {...register('password')}
                  autoComplete='current-password'
                  variant='filled'
                  InputProps={{
                    endAdornment: (
                      <InputAdornment position='end'>
                        <IconButton
                          onClick={() => setPasswordVisible(!passwordVisible)}
                          sx={{ fontSize: '20px', padding: '5px' }}
                        >
                          {passwordVisible ? (
                            <Icon icon='material-symbols:visibility-off' />
                          ) : (
                            <Icon icon='material-symbols:visibility' />
                          )}
                        </IconButton>
                      </InputAdornment>
                    ),
                  }}
                />
              </Box>

              <Box className='flex flex-row justify-between items-center my-4'>
                <FormControlLabel
                  control={
                    <Checkbox
                      value='remember'
                      className='p-0 me-2'
                      color='primary'
                    />
                  }
                  label={t('remember_me')}
                  componentsProps={{
                    typography: {
                      sx: {
                        color: 'text.main',
                        fontSize: 'inherit',
                      },
                    },
                  }}
                />
                <Link
                  to='/forgot-password'
                  sx={{ fontWeight: 400, textDecoration: 'inherit' }}
                >
                  {t('forgot_password')}
                </Link>
              </Box>

              <Box className='text-center'>
                <Button
                  type='submit'
                  variant='contained'
                  fullWidth
                  sx={{
                    mt: 1,
                    mb: 0,
                    position: 'relative',
                  }}
                  className='font-bold'
                  loading={loading}
                  disabled={loading ? true : false}
                  className='relative'
                >
                  {loading ? 'Please wait...' : t('login')}
                  {loading && (
                    <Box className='absolute right-3 top-0 flex h-full items-center'>
                      <CircularProgress size={17} color='inherit' />
                    </Box>
                  )}
                </Button>
              </Box>
            </Stack>
          </Box>

          {/* box */}
          <ArrowWithLink
            text={'ESS Portal Login'}
            right
            to='/essPortal'
            className='font-semibold'
          />
        </Box>

        {/* form */}
      </LoginLayout>
    </>
  );
}
