import React, { useState, useEffect } from 'react';
import {
  FormControl,
  RadioGroup,
  Radio,
  FormLabel,
  FormControlLabel,
} from '@mui/material';
import FmDrawerOne from '../_mui/FmDrawerOne.tsx';

const fontOptions = [
  'Inter',
  'Public Sans',
  'Noto Sans',
  'IBM Plex Sans',
  'Poppins',
  'Schibsted Grotesk',
  '-apple-system',
];

// const colorOptions = [
//   '#067E89',
//   '#3F51B5',
//   '#4CAF50',
//   '#FF9800',
//   '#E91E63',
//   '#2196F3',
// ];

const HeaderMenuSettings = ({ open, onClose }) => {
  const [selectedFont, setSelectedFont] = useState(
    localStorage.getItem('selectedFontThree') || 'Inter'
  );

  // const [selectedColor, setSelectedColor] = useState(
  //   localStorage.getItem('selectedColor') || '#067E89'
  // );

  useEffect(() => {
    localStorage.setItem('selectedFontThree', selectedFont);
    /// localStorage.setItem('selectedColor', selectedColor);
  }, [selectedFont]);

  const handleFontChange = (event) => {
    const newFont = event.target.value;
    setSelectedFont(newFont);
    localStorage.setItem('selectedFontThree', newFont);
    window.location.reload();
  };

  // const handleColorChange = (event) => {
  //   const newColor = event.target.value;
  //   setSelectedColor(newColor);
  //   localStorage.setItem('selectedColor', newColor);
  //   window.location.reload();
  // };

  return (
    <FmDrawerOne title='Settings' open={open} onClose={onClose} width='400px'>
      <FormControl
        fullWidth
        margin='normal'
        sx={{ border: '1px solid', borderColor: 'border.main' }}
        className='shadow-md rounded-sm overflow-hidden mt-0'
      >
        <FormLabel
          id='font-radio-group-label'
          sx={{
            color: 'primary.main',
            fontWeight: 700,
            bgcolor: 'background.light',
          }}
          className='p-3'
        >
          Typography
        </FormLabel>
        <RadioGroup
          aria-labelledby='font-radio-group-label'
          value={selectedFont}
          onChange={handleFontChange}
          className='p-3'
        >
          {fontOptions.map((font) => (
            <FormControlLabel
              key={font}
              value={font}
              control={<Radio className='p-0 me-3' />}
              label={font}
              className='my-2'
            />
          ))}
        </RadioGroup>
      </FormControl>

      {/* <FormControl
        fullWidth
        margin='normal'
        sx={{ border: '1px solid', borderColor: 'border.main' }}
        className='shadow-md rounded-sm overflow-hidden mt-0'
      >
        <FormLabel
          id='font-radio-group-label'
          sx={{
            color: 'primary.main',
            fontWeight: 700,
            bgcolor: 'background.light',
          }}
          className='p-3'
        >
          Color
        </FormLabel>
        <RadioGroup
          aria-labelledby='font-radio-group-label'
          value={selectedColor}
          onChange={handleColorChange}
          className='p-3'
        >
          {colorOptions.map((font) => (
            <FormControlLabel
              key={font}
              value={font}
              control={<Radio className='p-0 me-3' />}
              label={font}
              className='my-2'
            />
          ))}
        </RadioGroup>
      </FormControl> */}
    </FmDrawerOne>
  );
};

export default HeaderMenuSettings;
