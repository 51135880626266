import TableAvatar from '../_form/TableAvatar.jsx';
import Icons from '../../utils/Icon.jsx';
import SimpleIconText from '../elements/SimpleIconText.tsx';
import FmViewDateField from '../_mui/FmViewDateField.tsx';

const SalesOrderName = {
  accessorKey: 'salesOrderName',
  header: 'Sales Order',
  size: 220,
  Cell: ({ cell, row }) => {
    return (
      <TableAvatar
        name={cell?.getValue()}
        desc={
          row?.original?.salesOrderCode && `${row?.original?.salesOrderCode}`
        }
        removeImage
      />
    );
  },
};

const CompanyName = {
  accessorKey: 'companyName',
  header: 'Company Name',
  size: 220,
  Cell: ({ cell, row }) => {
    return (
      <TableAvatar
        name={cell?.getValue()}
        desc={row?.original?.companyCode && `${row?.original?.companyCode}`}
        removeImage
      />
    );
  },
};

const ProjectName = {
  accessorKey: 'projectName',
  header: 'Project',
  size: 220,
  Cell: ({ cell, row }) => {
    return (
      <TableAvatar
        name={cell?.getValue()}
        desc={row?.original?.projectCode && `${row?.original?.projectCode}`}
        removeImage
      />
    );
  },
};

const ContractName = {
  accessorKey: 'contractName',
  header: 'Contract',
  size: 220,
  Cell: ({ cell, row }) => {
    return (
      <TableAvatar
        name={cell?.getValue()}
        desc={row?.original?.contractCode && `${row?.original?.contractCode}`}
        removeImage
      />
    );
  },
};

const TotalAmount = {
  accessorKey: 'totalAmount',
  header: 'Total Amount',
  size: 150,
  Cell: ({ cell, row }) => {
    const currencyNamesOne =
      row?.original?.currencyName && row?.original?.currencyName;
    const toTalValue = cell?.getValue() && cell?.getValue();
    return <div>{`${currencyNamesOne} ${toTalValue}`}</div>;
  },
  muiTableBodyCellProps: {
    align: 'center',
    className: 'font-semibold',
  },
  muiTableHeadCellProps: {
    align: 'center',
  },
};

const BranchName = {
  accessorKey: 'branchName',
  header: 'Branch',
  size: 220,
  Cell: ({ cell, row }) => {
    return (
      <TableAvatar
        name={cell?.getValue()}
        desc={row?.original?.branchCode && `${row?.original?.branchCode}`}
        removeImage
      />
    );
  },
};

const ClientName = {
  accessorKey: 'clientName',
  header: 'Client',
  size: 220,
  Cell: ({ cell, row }) => {
    return (
      <TableAvatar
        name={cell?.getValue()}
        desc={row?.original?.clientCode && `${row?.original?.clientCode}`}
        removeImage
      />
    );
  },
};

const AccountName = {
  accessorKey: 'accountName',
  header: 'Account Name',
  size: 220,
  Cell: ({ cell, row }) => {
    return (
      <TableAvatar
        name={cell?.getValue()}
        desc={row?.original?.accountCode && `${row?.original?.accountCode}`}
        removeImage
      />
    );
  },
};

const RequestDate = {
  accessorKey: 'requestDate',
  header: 'Request Date',
  size: 150,
  customDate: true,
  Cell: ({ cell }) => {
    return (
      <>
        {cell?.row?.original?.requestDate && (
          <SimpleIconText
            icon={Icons.date}
            text={FmViewDateField({
              value: cell?.row?.original?.requestDate,
              tableFontWeightRemove: true,
            })}
          />
        )}
      </>
    );
  },
};

const BankName = {
  accessorKey: 'bankName',
  header: 'Bank Account',
  size: 220,
  Cell: ({ cell, row }) => {
    return (
      <TableAvatar
        name={cell?.getValue()}
        desc={
          row?.original?.chartOfAccountCode &&
          `${row?.original?.chartOfAccountCode}`
        }
        removeImage
      />
    );
  },
};

const PlaceOfSupplyName = {
  accessorKey: 'placeOfSupplyName',
  header: 'Place of Supply',
  size: 220,
  Cell: ({ cell, row }) => {
    return (
      <TableAvatar
        name={cell?.getValue()}
        desc={
          row?.original?.placeOfSupplyCode &&
          `${row?.original?.placeOfSupplyCode}`
        }
        removeImage
      />
    );
  },
};

const JurisdictionName = {
  accessorKey: 'jurisdictionName',
  header: 'Jurisdiction Name',
  size: 220,
  Cell: ({ cell, row }) => {
    return (
      <TableAvatar
        name={cell?.getValue()}
        desc={
          row?.original?.jurisdictionCode &&
          `${row?.original?.jurisdictionCode}`
        }
        removeImage
      />
    );
  },
};

const PurcaseVoucherName = {
  accessorKey: 'purchaseVoucherName',
  header: 'Purchase Voucher',
  size: 220,
  Cell: ({ cell, row }) => {
    return (
      <TableAvatar
        name={cell?.getValue()}
        desc={
          row?.original?.purchaseVoucherCode &&
          `${row?.original?.purchaseVoucherCode}`
        }
        removeImage
      />
    );
  },
};

const SupplierName = {
  accessorKey: 'supplierName',
  header: 'Supplier',
  size: 220,
  Cell: ({ cell, row }) => {
    return (
      <TableAvatar
        name={cell?.getValue()}
        desc={row?.original?.supplierCode && `${row?.original?.supplierCode}`}
        removeImage
      />
    );
  },
};

const CustomDateCell = (cellName) => {
  return (
    <>
      {cellName && (
        <SimpleIconText
          icon={Icons.date}
          text={FmViewDateField({
            value: cellName,
            tableFontWeightRemove: true,
          })}
        />
      )}
    </>
  );
};

export {
  ProjectName,
  ContractName,
  SalesOrderName,
  TotalAmount,
  BranchName,
  ClientName,
  AccountName,
  CompanyName,
  RequestDate,
  BankName,
  PlaceOfSupplyName,
  JurisdictionName,
  PurcaseVoucherName,
  SupplierName,
  CustomDateCell,
};
