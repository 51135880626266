import React, { useEffect, useState } from 'react';
import DashboardLayout from '../../../components/DashboardLayout.tsx';
import { Box, Grid } from '@mui/material';
import GlassCard from '../../../components/small/GlassCard.tsx';
import { ActionButtons } from '../../../components/_form';
import { useNavigate } from 'react-router-dom';
import Crud_Service from '../../../apis/CrudService';
import { useForm } from 'react-hook-form';
import { LocalizationProvider } from '@mui/x-date-pickers/LocalizationProvider';
import { AdapterDayjs } from '@mui/x-date-pickers/AdapterDayjs';
import dayjs from 'dayjs';
import useLoader from '../../../components/helpers/UseLoader.tsx';
import FmTextField from '../../../components/_mui/FmTextField.tsx';
import FmAutoComplete from '../../../components/_mui/FmAutoComplete.tsx';
import { BooleanOptions } from '../../../utils/CommonVariables.tsx';
import { toast } from 'react-toastify';
import useCommonFetchApi from '../../../components/helpers/useCommonFetchApi.tsx';
import FmDatePicker from '../../../components/_mui/FmDatePicker.tsx';
import { yupResolver } from '@hookform/resolvers/yup';
import { enquiryValidationSchema } from '../../../utils/CommonvalidationSchemas.tsx';
import FmSearchableSelect from '../../../components/_mui/FmSearchableSelect.tsx';

const CreateEnquiry = () => {
  const navigate = useNavigate();
  const crud = new Crud_Service();

  const { handleSubmit, control, setValue, reset, trigger, watch } = useForm({
    resolver: yupResolver(enquiryValidationSchema),
    mode: 'onChange',
    defaultValues: {
      startDate: dayjs(),
      lastDate: dayjs(),
    },
  });
  const [bidBond, setBidbond] = useState(false);
  const [bidBondValue, setBidbondValue] = useState(null);
  const [buyer, setBuyer] = useState(null);
  const { createLoading, startCreateLoading, stopCreateLoading } = useLoader();
  useCommonFetchApi('clients');
  const { data: tenderEnquiryTypes } = useCommonFetchApi('tenderenquirytypes', {
    sortBy: 'tenderEnquiryTypeName',
    sortDirection: 'asc',
  });
  const { data: tenderProjectTypes } = useCommonFetchApi('tenderprojecttypes', {
    sortBy: 'tenderProjectTypeName',
    sortDirection: 'asc',
  });

  const { data: tenderFormats } = useCommonFetchApi('tenderformats', {
    sortBy: 'tenderFormatName',
    sortDirection: 'asc',
  });

  const handleSubmitTendors = async (values) => {
    startCreateLoading();

    const CombinedData = {
      ...values,
      bidBondValue: Number(values?.bidBondValue) || null,
      startDate: dayjs(values?.startDate).format('YYYY-MM-DD'),
      lastDate: dayjs(values?.lastDate).format('YYYY-MM-DD'),
      subMissionDate: dayjs(values?.lastDate).format('YYYY-MM-DD'),
      tenderValue: Number(values?.tenderValue),
      bidBondTypeValue: values?.bidBondTypeValue || null,
      remarks: values?.remarks || null,
      autoRenewal: values?.autoRenewal || false,
      cost: values?.cost || null,
      tenderStatusId: 1,
      companyId: values?.companyId || null,
    };
    await crud.create('tenders', CombinedData, (_err, res) => {
      if (res?.status === 201) {
        navigate(-1);
        toast.success('Enquiry created successfully');
        stopCreateLoading();
      } else {
        stopCreateLoading();
      }
    });
  };
  useEffect(() => {
    watch('startDate') && setValue('lastDate', watch('startDate'));
  }, [watch('startDate')]);
  //handle Bidbond
  const handleBidBondChange = (e) => {
    setBidbond(e);
    !e && setValue('bidBondFormatId', null);
    !e && setValue('bidBondValue', null);
    !e && setValue('bidBondTypeId', null);
    !e && setValue('bidBondTypeValue', null);
  };
  useEffect(() => {
    setValue('tenderStatusId', 1);
  }, []);
  const handleOnChangeBuyer = (e) => {
    setBuyer(e?.value);
    setValue('cost', '');
  };

  return (
    <DashboardLayout title='Enquiry'>
      <Box className='mt-10 mx-auto' sx={{ maxWidth: '1200px' }}>
        <GlassCard className='p-5'>
          <h2 className='text-lg font-bold mb-5'>Create Enquiry</h2>
          <form onSubmit={handleSubmit(handleSubmitTendors)}>
            <LocalizationProvider dateAdapter={AdapterDayjs}>
              <Grid container spacing={2}>
                <Grid item md={3}>
                  <FmTextField
                    name='tenderName'
                    control={control}
                    label='Enquiry Name'
                    required
                    minLength={2}
                  />
                </Grid>

                <Grid item md={3}>
                  <FmTextField
                    name='tenderNumber'
                    control={control}
                    label='Enquiry Number'
                    required
                  />
                </Grid>
                <Grid item md={3}>
                  <FmSearchableSelect
                    name='companyId'
                    control={control}
                    apiUrl='companies'
                    label='Company'
                    showField={['companyName']}
                    headerField={['companyName']}
                    labelField={['companyName']}
                    valueField='companyId'
                    required
                  />
                </Grid>
                <Grid item md={3}>
                  <FmTextField
                    name='requestForEstimate'
                    control={control}
                    label='Request For Estimate'
                    required
                  />
                </Grid>
                <Grid item md={3}>
                  <FmSearchableSelect
                    name='clientId'
                    control={control}
                    apiUrl='clients'
                    valueField='clientId'
                    headerField={['Client Code', 'Client Name']}
                    labelField={['clientCode', 'clientName']}
                    showField={['clientName']}
                    pageSize={20}
                    label='Client'
                    required
                  />
                </Grid>
                <Grid item md={3}>
                  <FmAutoComplete
                    name='tenderEnquiryTypeId'
                    control={control}
                    options={tenderEnquiryTypes}
                    label='Mode of Enquiry'
                    displayField='tenderEnquiryTypeName'
                    optionFields={['tenderEnquiryTypeName']}
                    valueKey='tenderEnquiryTypeId'
                    required
                  />
                </Grid>
                <Grid item md={3}>
                  <FmAutoComplete
                    name='tenderProjectTypeId'
                    control={control}
                    options={tenderProjectTypes}
                    label='Enquiry Type'
                    displayField='tenderProjectTypeName'
                    optionFields={['tenderProjectTypeName']}
                    valueKey='tenderProjectTypeId'
                    required
                  />
                </Grid>
                <Grid item md={3}>
                  <FmAutoComplete
                    name='tenderFormatId'
                    control={control}
                    options={tenderFormats}
                    label='Enquiry Format'
                    displayField='tenderFormatName'
                    optionFields={['tenderFormatName']}
                    valueKey='tenderFormatId'
                    required
                  />
                </Grid>
                <Grid item md={3}>
                  <FmAutoComplete
                    name='bidBond'
                    control={control}
                    options={[
                      { value: true, bidBondName: 'Yes' },
                      { value: false, bidBondName: 'No' },
                    ]}
                    label='Bid Bond'
                    displayField='bidBondName'
                    optionFields={['bidBondName']}
                    valueKey='value'
                    onChange={handleBidBondChange}
                  />
                </Grid>
                {bidBond === true && (
                  <>
                    <Grid item md={3}>
                      <FmAutoComplete
                        name='bidBondFormatId'
                        control={control}
                        options={[
                          {
                            name: 'Standard',
                            value: 1,
                          },
                          {
                            name: 'Specific',
                            value: 2,
                          },
                        ]}
                        label='Bid Bond Format'
                        displayField='name'
                        optionFields={['name']}
                        valueKey='value'
                        required
                      />
                    </Grid>
                    <Grid item md={3}>
                      <FmAutoComplete
                        name='bidBondTypeValue'
                        control={control}
                        options={[
                          { name: 'Percentage', value: 'Percentage' },
                          { name: 'Flat', value: 'Flat' },
                        ]}
                        label='Bid Bond Type Value'
                        displayField='name'
                        optionFields={['name']}
                        valueKey='value'
                        required
                        onChange={(e) => {
                          setBidbondValue(e);
                          setValue('bidBondValue', '');
                          trigger('bidBondValue');
                        }}
                        onChangeValue={true}
                      />
                    </Grid>
                    <Grid item md={3}>
                      <FmTextField
                        name='bidBondValue'
                        required
                        control={control}
                        label='Bid Bond Value'
                        pattern={
                          bidBondValue?.value === 'Flat'
                            ? 'Decimal'
                            : 'Percentage'
                        }
                      />
                    </Grid>

                    <Grid item md={3}>
                      <FmAutoComplete
                        name='bidBondTypeId'
                        control={control}
                        options={[
                          {
                            name: 'Auto Renewal',
                            value: 1,
                          },
                          {
                            name: 'Fixed Enquiry',
                            value: 2,
                          },
                        ]}
                        label='Bid Bond Type'
                        displayField='name'
                        optionFields={['name']}
                        valueKey='value'
                        required
                      />
                    </Grid>
                  </>
                )}
                <Grid item md={3}>
                  <FmAutoComplete
                    name='autoRenewal'
                    control={control}
                    options={[
                      {
                        name: 'Yes',
                        value: true,
                      },
                      {
                        name: 'No',
                        value: false,
                      },
                    ]}
                    label='Auto Renewal'
                    displayField='name'
                    optionFields={['name']}
                    valueKey='value'
                  />
                </Grid>
                <Grid item md={3}>
                  <FmAutoComplete
                    name='subContractRequired'
                    control={control}
                    options={[
                      {
                        name: 'Yes',
                        value: true,
                      },
                      {
                        name: 'No',
                        value: false,
                      },
                    ]}
                    label='Sub Contract Required'
                    displayField='name'
                    optionFields={['name']}
                    valueKey='value'
                    required
                  />
                </Grid>
                <Grid item md={3}>
                  <FmDatePicker
                    control={control}
                    name='startDate'
                    label='Start Date'
                    required
                  />
                </Grid>

                <Grid item md={3}>
                  <FmDatePicker
                    control={control}
                    name='lastDate'
                    label='Last Date'
                    minDate={watch('startDate')}
                  />
                </Grid>
                <Grid item md={3}>
                  <FmTextField
                    name='tenderValue'
                    control={control}
                    label='Tender / Final Proposal Value'
                    pattern='Number'
                  />
                </Grid>
                <Grid item md={3}>
                  <FmAutoComplete
                    name='buyTenderDocument'
                    control={control}
                    options={BooleanOptions}
                    label='Buy Enquiry Document'
                    displayField='label'
                    optionFields={['label']}
                    valueKey='value'
                    onChange={handleOnChangeBuyer}
                    onChangeValue={true}
                  />
                </Grid>
                {buyer === true && (
                  <Grid item md={3}>
                    <FmTextField
                      name='cost'
                      control={control}
                      label='Costs'
                      pattern='Number'
                    />
                  </Grid>
                )}
                <Grid item md={3}>
                  <FmAutoComplete
                    name='expressOfInterest'
                    control={control}
                    options={[
                      {
                        name: 'Yes',
                        value: true,
                      },
                      {
                        name: 'No',
                        value: false,
                      },
                    ]}
                    label='Express Of Interest'
                    displayField='name'
                    optionFields={['name']}
                    valueKey='value'
                    required
                  />
                </Grid>

                <Grid item md={3}>
                  <FmTextField
                    name='remarks'
                    control={control}
                    label='Enquiry Remarks'
                    multiline
                    rows={3}
                    maxRows={3}
                  />
                </Grid>
              </Grid>
            </LocalizationProvider>
            <ActionButtons
              onSubmit={handleSubmit(handleSubmitTendors)}
              onCancel={() => navigate(-1)}
              onReset={reset}
              submitLoading={createLoading}
              cancelLoading={false}
            />
          </form>
        </GlassCard>
      </Box>
    </DashboardLayout>
  );
};
export default CreateEnquiry;
