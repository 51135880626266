import React, { useEffect, useState } from 'react';
import DashboardLayout from '../../../components/DashboardLayout.tsx';
import { Box, Grid } from '@mui/material';
import GlassCard from '../../../components/small/GlassCard.tsx';
import ActionButtons from '../../../components/_form/ActionButtons.tsx';
import { useNavigate, useParams } from 'react-router-dom';
import Crud_Service from '../../../apis/CrudService.jsx';
import { Controller, useForm } from 'react-hook-form';
import useLoader from '../../../components/helpers/UseLoader.tsx';
import CommonLoader from '../../../components/page/CommonLoader.jsx';
import FmTextField from '../../../components/_mui/FmTextField.tsx';
import { toast } from 'react-toastify';
import FmAutoComplete from '../../../components/_mui/FmAutoComplete.tsx';
import useCommonFetchApi from '../../../components/helpers/useCommonFetchApi.tsx';
import { CreateButton } from '../../../components/small/Buttons.jsx';
import dayjs from 'dayjs';
import { DatePicker, LocalizationProvider } from '@mui/x-date-pickers';
import { AdapterDayjs } from '@mui/x-date-pickers/AdapterDayjs';
import { yupResolver } from '@hookform/resolvers/yup';
import { eventCalculationSchema } from '../../../utils/CommonvalidationSchemas.tsx';
import FmDatePicker from '../../../components/_mui/FmDatePicker.tsx';

const EditEventsCalendar = () => {
  const navigate = useNavigate();
  const crud = new Crud_Service();
  const [loading, setLoading] = useState(false);
  const [editData, setEditData] = useState(null);
  const { handleSubmit, control, setValue, reset } = useForm({
    resolver: yupResolver(eventCalculationSchema),
    mode: 'onChange',
  });
  const { startEditLoading, stopEditLoading, editLoading } = useLoader();
  const { id } = useParams();
  const GridValue = 2.4;

  //get All Apis
  const { data: prepareBy } = useCommonFetchApi('loginUsers') || {
    data: [],
  };

  useEffect(() => {
    getEventsCalendar();
  }, []);

  const getEventsCalendar = async () => {
    setLoading(true);
    await crud.getSingle('eventcalendars', id, (_err, res) => {
      if (res?.status === 200) {
        const data = res?.data;
        Object.entries({
          ...data,
        }).forEach(([key, value]) => {
          setValue(key, value);
        });
        setEditData(res?.data);
        setLoading(false);
      } else {
      }
    });
  };

  const handleUpdateEvent = async (values) => {
    startEditLoading();

    const combinedData = {
      ...values,
      eventDate: dayjs(values?.eventDate).format('YYYY-MM-DD'),
    };

    await crud.update(
      'eventcalendars',
      values?.eventCalendarId,
      combinedData,
      (err, res) => {
        if (res?.status === 200) {
          stopEditLoading();
          toast.success('Event Updated Successfully');
          navigate(-1);
        } else {
          stopEditLoading();
        }
      }
    );
  };

  return (
    <DashboardLayout
      title='Edit Uniform and Accessories-Requisition'
      actionButtons={
        <>
          <Box className='flex flex-row items-center whitespace-nowrap gap-3'>
            <CreateButton
              name='Create'
              onClick={() => navigate('/admin/events-calendar/list/create')}
            />
          </Box>
        </>
      }
    >
      <GlassCard className='p-5'>
        {loading ? (
          <CommonLoader />
        ) : (
          <form onSubmit={handleSubmit(handleUpdateEvent)}>
            <LocalizationProvider dateAdapter={AdapterDayjs}>
              <Grid container spacing={2}>
                <Grid item md={GridValue}>
                  <FmDatePicker
                    name='eventDate'
                    control={control}
                    label='Event Date'
                    required
                  />
                </Grid>

                <Grid item md={GridValue}>
                  <FmTextField
                    name='eventCalendarName'
                    control={control}
                    label='Event Name'
                    required
                  />
                </Grid>
                <Grid item md={GridValue}>
                  <FmTextField
                    name='grantTotal'
                    control={control}
                    label='Grand Total'
                    pattern='Number'
                  />
                </Grid>
                <Grid item md={GridValue}>
                  <FmAutoComplete
                    name='preparedBy'
                    control={control}
                    options={prepareBy}
                    label='Prepared By'
                    displayField='employeeName'
                    optionFields={['employeeName']}
                    valueKey='loginUserId'
                    required
                  />
                </Grid>
              </Grid>
              <ActionButtons
                onSubmit={handleSubmit(handleUpdateEvent)}
                onReset={reset}
                submitLoading={editLoading}
                cancelLoading={false}
                submitText='Update'
                cancelText='Cancel'
                onCancel={() => navigate(-1)}
              />
            </LocalizationProvider>
          </form>
        )}
      </GlassCard>
    </DashboardLayout>
  );
};

export default EditEventsCalendar;
