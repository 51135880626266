import React, { memo } from 'react';
import ViewTableLightText from './ViewTableLightText.tsx';
import { Box } from '@mui/material';

const getColorBasedOnDateDifference = (date) => {
  const currentDate = new Date();
  const targetDate = new Date(date);

  const timeDiff = targetDate - currentDate;
  const diffDays = Math.ceil(timeDiff / (1000 * 60 * 60 * 24));

  if (diffDays < 0) {
    return '';
  } else if (diffDays === 0) {
    return 'text-red-700';
  } else if (diffDays <= 3) {
    return 'text-red-400';
  } else if (diffDays <= 7) {
    return 'text-green-600';
  } else {
    return 'text-green-600';
  }
};

const ViewColoredDate = ({ date, originalDate, icon }) => {
  const colorClass = getColorBasedOnDateDifference(originalDate);

  const currentDate = new Date();
  const targetDate = new Date(originalDate);
  const timeDiff = targetDate - currentDate;
  const diffDays = Math.ceil(timeDiff / (1000 * 60 * 60 * 24));

  return (
    <Box className={`flex items-center gap-2 ${colorClass}`}>
      {icon && <div className='text-xl'>{icon}</div>}
      <div className='flex flex-col' style={{ lineHeight: 1.3 }}>
        <span>{date}</span>
        <ViewTableLightText
          name={
            diffDays === 0
              ? 'Today'
              : diffDays > 0
              ? `${diffDays} days to go`
              : ''
          }
        />
      </div>
    </Box>
  );
};

export default memo(ViewColoredDate);
