import React, { useEffect, useState } from 'react';
import DashboardLayout from '../../../../components/DashboardLayout.tsx';
import { Box, Grid } from '@mui/material';
import GlassCard from '../../../../components/small/GlassCard.tsx';
import { ActionButtons } from '../../../../components/_form/index.jsx';
import { useNavigate } from 'react-router-dom';
import Crud_Service from '../../../../apis/CrudService.jsx';
import { useForm } from 'react-hook-form';
import dayjs from 'dayjs';
import useLoader from '../../../../components/helpers/UseLoader.tsx';
import FmTextField from '../../../../components/_mui/FmTextField.tsx';
import FmSearchableSelect from '../../../../components/_mui/FmSearchableSelect.tsx';
import FmAutoComplete from '../../../../components/_mui/FmAutoComplete.tsx';
import { toast } from 'react-toastify';
import FmFileInput from '../../../../components/_mui/FmFileInput.tsx';
import { yupResolver } from '@hookform/resolvers/yup';
import { createCertificateSchema } from '../../../../utils/CommonvalidationSchemas.tsx';
import FmDatePicker from '../../../../components/_mui/FmDatePicker.tsx';
import useCommonFetchApi from '../../../../components/helpers/useCommonFetchApi.tsx';
import SimpleCard from '../../../../components/small/SimpleCard.tsx';
import { CommonTrueFalse } from '../../../../utils/CommonVariables.tsx';

const CreateCompanyCertificates = () => {
  const { handleSubmit, control, trigger, setValue } = useForm({
    mode: 'onChange',
    resolver: yupResolver(createCertificateSchema),
  });
  const [loading, setLoading] = useState({
    certificationFileId: false,
    receiptFileId: false,
  });
  const [filesData, setFilesData] = useState({
    certificationFileId: null,
    receiptFileId: null,
  });
  const gridvalue = 2.4;
  const crud = new Crud_Service();
  const { startCreateLoading, stopCreateLoading, createLoading } = useLoader();
  const navigate = useNavigate();

  //get All Api
  const { data: certificatetypes } = useCommonFetchApi('certificatetypes');
  const { data: certifications } = useCommonFetchApi('certifications');

  const handleFileUpload = async (event, fieldName) => {
    setLoading((prev) => ({
      ...prev,
      [fieldName]: true,
    }));

    const file = event.target.files[0];
    if (file) {
      const fileFormData = new FormData();
      fileFormData.append('file', file);

      // Set fileTypeId dynamically based on the field name
      const fileTypeId = fieldName === 'certificationFileId' ? 50 : 60;
      fileFormData.append('fileTypeId', fileTypeId);

      try {
        const response = await new Promise((resolve, reject) => {
          crud.create('files/upload', fileFormData, (err, res) => {
            if (res?.status === 200) {
              resolve(res);
            } else {
              reject(new Error('File upload failed'));
            }
          });
        });

        // Dynamically update the corresponding fields
        setFilesData((prev) => ({
          ...prev,
          [fieldName]: response?.data,
        }));
        trigger(fieldName);
        setValue(`${fieldName}`, response?.data?.originalFileName);
      } catch (error) {
        toast.error(error.message);
      } finally {
        setLoading(false);
      }
    }
  };

  const handleSubmitCertificateCatelog = async (values) => {
    startCreateLoading();

    const combinedData = {
      ...values,
      costCenterId: Number(values?.costCenterId),
      expenseTypeId: Number(values?.expenseTypeId),
      cost: Number(values?.cost),
      paymentDate: dayjs(values?.paymentDate).format('YYYY-MM-DD'),
      receiptFileId: filesData?.receiptFileId?.fileId,
      createCertificateCatalog: {
        ...values?.createCertificateCatalog,
        issuedDate: dayjs(values?.createCertificateCatalog?.issuedDate).format(
          'YYYY-MM-DD'
        ),
        expiryDate: dayjs(values?.createCertificateCatalog?.expiryDate).format(
          'YYYY-MM-DD'
        ),
        certificationFileId: filesData?.certificationFileId?.fileId,
      },
    };

    await crud.create(
      'certificatecatalogpayments',
      combinedData,
      (err, res) => {
        if (res?.status === 201) {
          toast.success('Certificate Created Successfully');
          stopCreateLoading();
          navigate(-1);
        } else {
          stopCreateLoading();
        }
      }
    );
  };

  const handleChangeExpenseType = (e) => {
    if (e?.cost) {
      setValue('cost', e?.cost);
    } else {
      setValue('cost', '');
    }
  };

  return (
    <DashboardLayout title='Company Certificate Create'>
      <GlassCard className='p-4'>
        <Box
          component={'form'}
          className='w-full'
          onSubmit={handleSubmit(handleSubmitCertificateCatelog)}>
          <SimpleCard title={'Certificate Details'}>
            <Grid container spacing={2}>
              <Grid item md={gridvalue}>
                <FmAutoComplete
                  name='createCertificateCatalog.certificateTypeId'
                  control={control}
                  options={certificatetypes}
                  label='Certificate Type'
                  displayField='certificateTypeName'
                  optionFields={['certificateTypeName']}
                  valueKey='certificateTypeId'
                  required
                />
              </Grid>
              <Grid item md={gridvalue}>
                <FmSearchableSelect
                  name='createCertificateCatalog.certificationId'
                  control={control}
                  apiUrl='certifications'
                  valueField='certificationId'
                  labelField={['certificationCode', 'certificationName']}
                  showField={['certificationCode', 'certificationName']}
                  label='Certificate'
                  required
                />
              </Grid>
              <Grid item md={gridvalue}>
                <FmAutoComplete
                  name='createCertificateCatalog.certificateShouldBeRenewed'
                  control={control}
                  options={CommonTrueFalse}
                  label='Certificate Should Be Renewed'
                  displayField='label'
                  optionFields={['label']}
                  valueKey='value'
                />
              </Grid>

              <Grid item md={gridvalue}>
                <FmTextField
                  name='createCertificateCatalog.certificationNumber'
                  control={control}
                  label='Certification Number'
                  pattern='Number'
                />
              </Grid>
            </Grid>
          </SimpleCard>

          <SimpleCard title={'Dates'}>
            <Grid container spacing={2}>
              <Grid item md={gridvalue}>
                <FmDatePicker
                  name='createCertificateCatalog.issuedDate'
                  control={control}
                  label='issued Date'
                />
              </Grid>
              <Grid item md={gridvalue}>
                <FmDatePicker
                  name='createCertificateCatalog.expiryDate'
                  control={control}
                  label='Expiry Date'
                  defaultValue={dayjs().add(1, 'day')}
                  minDate={dayjs().add(1, 'day')}
                />
              </Grid>
            </Grid>
          </SimpleCard>

          <SimpleCard title={'Certificate & Status'}>
            <Grid container spacing={2}>
              <Grid item md={gridvalue}>
                <FmFileInput
                  name='createCertificateCatalog.certificationFileId'
                  documentName={
                    filesData?.certificationFileId?.originalFileName
                  }
                  onChange={(e) => handleFileUpload(e, 'certificationFileId')}
                  loading={loading?.certificationFileId}
                />
              </Grid>
              <Grid item md={gridvalue}>
                <FmSearchableSelect
                  name='createCertificateCatalog.certificateStatusId'
                  control={control}
                  apiUrl='certificatestatuses'
                  valueField='certificateStatusId'
                  labelField={[
                    'certificateStatusCode',
                    'certificateStatusName',
                  ]}
                  showField={['certificateStatusCode', 'certificateStatusName']}
                  label={'Certificate Status'}
                  required
                />
              </Grid>
              <Grid item md={gridvalue * 2}>
                <FmTextField
                  name='createCertificateCatalog.remarks'
                  control={control}
                  label='Remarks'
                  multiline={true}
                  rows={2}
                  maxRows={5}
                />
              </Grid>
            </Grid>
          </SimpleCard>
          <SimpleCard title={'Expenses'}>
            <Grid container spacing={2}>
              <Grid item md={gridvalue}>
                <FmSearchableSelect
                  name='expenseTypeId'
                  control={control}
                  apiUrl='expensetypes'
                  valueField='expenseTypeId'
                  labelField={['expenseTypeCode', 'expenseTypeName']}
                  showField={['expenseTypeCode', 'expenseTypeName']}
                  label={'Expense Type'}
                  onChangeProp={(e) => handleChangeExpenseType(e)}
                  required
                />
              </Grid>
              <Grid item md={gridvalue}>
                <FmTextField
                  name='cost'
                  control={control}
                  label='Cost'
                  pattern='Decimal'
                />
              </Grid>
              <Grid item md={gridvalue}>
                <FmDatePicker
                  name='paymentDate'
                  control={control}
                  label='Payment Date'
                />
              </Grid>
            </Grid>
          </SimpleCard>
          <SimpleCard title={'Transaction Details'}>
            <Grid container spacing={2}>
              <Grid item md={gridvalue}>
                <FmSearchableSelect
                  name='costCenterId'
                  control={control}
                  apiUrl='costcenters'
                  valueField='costCenterId'
                  labelField={['costCenterCode', 'costCenterName']}
                  showField={['costCenterCode', 'costCenterName']}
                  label={'Cost Center'}
                  required
                />
              </Grid>
              <Grid item md={gridvalue}>
                <FmTextField
                  name='transactionNumber'
                  control={control}
                  label='Transaction Number'
                  pattern='Number'
                />
              </Grid>
              <Grid item md={gridvalue}>
                <FmFileInput
                  name='receiptFileId'
                  documentName={filesData?.receiptFileId?.originalFileName}
                  onChange={(e) => handleFileUpload(e, 'receiptFileId')}
                  loading={loading?.receiptFileId}
                />
              </Grid>
              <Grid item md={gridvalue * 2}>
                <FmTextField
                  name='remarks'
                  control={control}
                  label='Remarks'
                  multiline={true}
                  maxRows={5}
                  rows={2}
                />
              </Grid>
            </Grid>
          </SimpleCard>
          <ActionButtons
            onSubmit={handleSubmit(handleSubmitCertificateCatelog)}
            onCancel={() => navigate(-1)}
            submitLoading={createLoading}
            cancelText='Go Back'
          />
        </Box>
      </GlassCard>
    </DashboardLayout>
  );
};

export default CreateCompanyCertificates;
