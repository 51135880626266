import { yupResolver } from '@hookform/resolvers/yup';
import { Grid } from '@mui/material';
import dayjs from 'dayjs';
import React, { useEffect, useMemo, useState } from 'react';
import { useForm } from 'react-hook-form';
import { useSelector } from 'react-redux';
import { useNavigate, useParams } from 'react-router-dom';
import { toast } from 'react-toastify';
import Crud_Service from '../../../../apis/CrudService.jsx';
import ActionButtons from '../../../../components/_form/ActionButtons.tsx';
import FmDatePicker from '../../../../components/_mui/FmDatePicker.tsx';
import FmSearchableSelect from '../../../../components/_mui/FmSearchableSelect.tsx';
import FmTextField from '../../../../components/_mui/FmTextField.tsx';
import DashboardLayout from '../../../../components/DashboardLayout.tsx';
import useLoader from '../../../../components/helpers/UseLoader.tsx';
import { findNavByName } from '../../../../utils/navUtils.ts';
import GlassCard from './../../../../components/small/GlassCard.tsx';
import { SalesQuotationSchema } from '../../../../utils/CommonvalidationSchemas.tsx';

const GridSize = 2.4;
const AddCreateSalesQuotation = (props) => {
  const { type, setGetData } = props;
  const crud = new Crud_Service();
  const navigate = useNavigate();
  const { startCreateLoading, stopCreateLoading, createLoading } = useLoader();
  const [editingRowData, setEditingRowData] = useState(null);
  const { control, handleSubmit, reset, setValue } = useForm({
    resolver: yupResolver(SalesQuotationSchema),
    mode: 'onChange',
  });
  const id = useParams();
  useEffect(() => {
    if (id?.id) {
      (async () => {
        await crud.getSingle('salesquotations', id.id, (_err, res) => {
          if (res?.status === 200) {
            setEditingRowData(res.data);
            Object.entries({
              ...res.data,
            }).forEach(([key, value]) => {
              setValue(key, value);
            });
          } else {
            // Handle error response here
          }
        });
      })();
    }
  }, [id]);
  const handleEditItem = async (values) => {
    const data = {
      ...values,

      quotationStatusId: values.quotationStatusId,
      status: 2,
      salesOrderTypeId: type,
    };
    await crud.update('salesquotations', id?.id, data, (_err, res) => {
      if (res?.status === 200) {
        toast.success('Sales Quotation Updated Successfully');
        navigate(-1);
      } else {
      }
    });
  };

  const submitSalesQuotation = async (values: any) => {
    startCreateLoading();
    const data = {
      ...values,
      salesOrderTypeId: type,
      quotationStatusId: 1,
    };
    await crud.create('salesquotations', data, (_err, res) => {
      if (res?.status === 201) {
        toast.success('Sales Quotation Added Successfully');
        navigate(`/fm-division/create-sales-quotation/edit/${res?.data?.salesQuotationId}`)
        reset();
        setGetData(true);
      } else {
        stopCreateLoading();
      }
    });
  };

  const menu = useSelector((state) => state?.menu?.items);

  const SubMenu = useMemo(() => {
    return menu?.map((item) => item?.menus?.flat()).flat() || [];
  }, [menu]);

  const result = useMemo(
    () => findNavByName(SubMenu, 'CAFM', 'Work Order'),
    [SubMenu]
  );
  return (
    <DashboardLayout title='Create Sales Quotation' hasSubmenu menu={result}>
      <GlassCard className='p-3'>
        <Grid container spacing={2}>
          <Grid item md={GridSize}>
            <FmTextField
              name='salesQuotationName'
              label='Sales Quotation Name'
              control={control}
              required
            />
          </Grid>
          <Grid item md={GridSize}>
            <FmSearchableSelect
              name='contractId'
              control={control}
              apiUrl='contracts'
              valueField='contractId'
              labelField={['contractCode', 'contractName']}
              showField={['contractCode', 'contractName']}
              label={'Contract'}
              defaultValue={{
                contractId: editingRowData?.contractId,
                contractName: editingRowData?.contractName,
              }}
              required
            />
          </Grid>
          <Grid item md={GridSize}>
            <FmSearchableSelect
              name='branchId'
              control={control}
              apiUrl='branches'
              valueField='branchId'
              labelField={['branchCode', 'branchName']}
              showField={['branchCode', 'branchName']}
              label={'Branch'}
              defaultValue={{
                branchId: editingRowData?.branchId,
                branchName: editingRowData?.branchName,
              }}
            />
          </Grid>
          <Grid item md={GridSize}>
            <FmSearchableSelect
              name='paymentTermId'
              control={control}
              apiUrl='paymentterms'
              valueField='paymentTermId'
              labelField={['paymentTermCode', 'paymentTermName']}
              showField={['paymentTermCode', 'paymentTermName']}
              defaultValue={{
                paymentTermId: editingRowData?.paymentTermId,
                paymentTermName: editingRowData?.paymentTermName,
              }}
              label='Payment Term'
            />
          </Grid>

          <Grid item md={GridSize}>
            <FmTextField
              name='deliveryTerms'
              label='Delivery Terms'
              control={control}
            />{' '}
          </Grid>
          <Grid item md={GridSize * 2}>
            <FmTextField name='narration' label='Narration' control={control} />
          </Grid>
          <Grid item md={GridSize}>
            <FmTextField
              name='contactPersonName'
              label='Contact Person'
              control={control}
            />{' '}
          </Grid>
          <Grid item md={GridSize}>
            <FmTextField name='quotationValidity' label='Quotation Validity(Days)' control={control} required />
          </Grid>
          <Grid item md={GridSize}>
            <FmTextField
              name='remarks'
              label='Remarks'
              control={control}
              multiline={true}
              rows={4}
              maxRows={4} />
          </Grid>
          <Grid item md={12}>
            <ActionButtons
              onSubmit={handleSubmit(
                id?.id ? handleEditItem : submitSalesQuotation
              )}
              onReset={reset}
              onCancel={() => navigate(-1)}
              submitLoading={createLoading}
              cancelLoading={false}
              cancelText='Go Back'
              submitText='Create'
            />
          </Grid>
        </Grid>
      </GlassCard>
    </DashboardLayout>
  );
};

export default AddCreateSalesQuotation;
