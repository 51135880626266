import React, { useEffect, useState } from 'react';
import GlassCard from './../../../../../components/small/GlassCard.tsx';
import { Grid, Box } from '@mui/material';
import * as yup from 'yup';
import { yupResolver } from '@hookform/resolvers/yup';
import Crud_Service from '../../../../../apis/CrudService.jsx';
import { useForm } from 'react-hook-form';
import { toast } from 'react-toastify';
import dayjs from 'dayjs';
import FmTextField from '../../../../../components/_mui/FmTextField.tsx';
import ActionButtons from '../../../../../components/_form/ActionButtons.tsx';
import FmSearchableSelect from '../../../../../components/_mui/FmSearchableSelect.tsx';
import FmDatePicker from '../../../../../components/_mui/FmDatePicker.tsx';
import { useNavigate, useParams } from 'react-router-dom';
import { useDispatch, useSelector } from 'react-redux';
import { updateQuotationSuccess } from '../../../../../store/salesQuotaionSlice.ts';
import { Icon } from '@iconify/react/dist/iconify.js';
import ViewContractDetails from './ViewContractDetails.tsx';
import VewMrtTableSalesOrder from '../../CreateSalesOrder/View/VewMrtTableSalesOrder.tsx';
import FmTabsOne from './../../../../../components/tabs/FmTabsOne.tsx';
import Containers from '../../../../../components/helpers/Containers.jsx';
import InternalBudget from '../../CreateSalesOrder/internalBudget/InternalBudget.tsx';
import SOMilestones from '../SOMilestones.tsx';
import SchedulesView from '../../CreateSalesOrder/internalBudget/SchedulesView.tsx';
import CurrencyView from '../../../../../components/_mui/FmCurrencyView.tsx';

const navMenu = [
  {
    id: 1,
    name: 'Create Sales Order List',
  },
  {
    id: 2,
    name: 'Internal Budget',
  },

  {
    id: 3,
    name: 'Schedules',
  },
  {
    id: 4,
    name: 'Milestones',
  },
];

const GridSize = 3;

const schema = yup.object().shape({
  salesOrderName: yup.string().required('Sales Order Name is required'),
  orderDate: yup.date().required('Order Date is required'),
});
interface ContractDetails {
  projectCode?: string;
  projectName?: string;
  clientName?: string;
  contactPerson?: string;
  contactNumber?: string;
  contactEmailId?: string;
  locationName?: string;
  paymentTerms?: string;
}
let IconsListing = [
  {
    icon: <Icon icon='fluent:building-retail-20-regular' />,
    label: 'Type',
    fields: [{ key: 'typeName' }],
  },
  {
    icon: <Icon icon='fluent:building-retail-20-regular' />,
    label: 'Project Details',
    fields: [
      { key: 'projectCode', label: 'Code' },
      { key: 'projectName', label: 'Name' },
    ],
  },
  {
    icon: <Icon icon='fluent:building-people-20-regular' />,
    label: 'Client Details',
    fields: [{ key: 'clientName', label: 'Name' }],
  },
  {
    icon: <Icon icon='fluent:person-20-regular' />,
    label: 'Contact Details',
    fields: [
      { key: 'contactPerson', label: 'Name' },
      { key: 'contactNumber', label: 'Mobile' },
      { key: 'contactEmailId', label: 'Email' },
    ],
  },
  {
    icon: <Icon icon='fluent:location-20-regular' />,
    label: 'Location',
    fields: [{ key: 'locationName', label: 'Name' }],
  },
  {
    icon: <Icon icon='fluent:payment-20-regular' />,
    label: 'Payment Terms',
    fields: [{ key: 'paymentTerms', label: 'Terms' }],
  },
  {
    icon: <Icon icon='fluent:payment-20-regular' />,
    label: 'Branch',
    fields: [{ key: 'branchName', label: 'Branch' }],
  },
];

const EditSalesOrder = (props) => {
  const { setGetData, type } = props;
  const crud = new Crud_Service();
  const navigate = useNavigate();
  const { control, handleSubmit, reset, setValue, getValues, watch } = useForm({
    resolver: yupResolver(schema),
    mode: 'onChange',
  });
  const [selectedContract, setSelectedContract] = useState<ContractDetails>({});
  const [selectedOrder, setSelectedOrder] = useState(null);
  const [editingRowData, setEditingRowData] = useState(null);
  const dispatch = useDispatch();

  const { salesQuotation } = useSelector((state) => state?.salesQuotation);

  const [tabValue, setTabValue] = useState(
    Number(localStorage.getItem('SalesQuoatSelectedTab')) || 1
  );

  const handleChange = (_event, newValue) => {
    setTabValue(newValue);
    localStorage.setItem('SalesQuoatSelectedTab', newValue);
  };

  useEffect(() => {
    reset();
    setSelectedOrder(null);
  }, [type]);

  const id = useParams().id;
  useEffect(() => {
    if (id) {
      (async () => {
        await crud.getSingle('salesorders', id, (err, res) => {
          if (res?.status === 200) {
            setEditingRowData(res.data);

            dispatch(updateQuotationSuccess(res.data));
            Object.entries({
              ...res.data,
            }).forEach(([key, value]) => {
              setValue(key, value);
            });
            res.data.contractId &&
              crud.getSingle('contracts', res.data.contractId, (err, res) => {
                if (res?.status === 200) {
                  setSelectedContract(res.data);
                }
              });
          } else {
          }
        });
      })();
    }
  }, [id]);

  useEffect(() => {
    if (selectedOrder?.salesQuotationId) {
      setValue('deliveryTerms', selectedOrder?.deliveryTerms);
      setValue('narration', selectedOrder?.narration);
      setValue('paymentTerms', selectedOrder?.paymentTerms);
      setValue('remarks', selectedOrder?.remarks);
    }
  }, [selectedOrder]);

  const amount = watch('amount');
  const vat = watch('vat');

  useEffect(() => {
    const amountValue = parseFloat(amount) || 0;
    const vatPercentage = parseFloat(vat) || 0;
    const vatAmount = (amountValue * vatPercentage) / 100;
    const totalAmount = amountValue + vatAmount;

    setValue('vatAmount', vatAmount.toFixed(2));
    setValue('totalAmount', totalAmount.toFixed(2));
  }, [amount, vat, setValue]);

  const submitSalesOrder = async (values) => {
    const combinedValues = {
      ...values,
      //TODO: Need to Do Dynamic in future
      salesOrderTypeId: 1,
      orderDate: values?.orderDate
        ? dayjs(values?.orderDate).format('YYYY-MM-DD')
        : null,
    };
    await crud.update('salesorders', id, combinedValues, (_err, res) => {
      if (res?.status === 200) {
        toast.success('Updated Sales Order Successfully');
        dispatch(updateQuotationSuccess(res.data));
        setGetData(true);
        reset();
        setSelectedOrder(null);
      } else {
      }
    });
  };

  const handleSelectedQuotation = (props) => {
    const salesQuotationDetails = {
      icon: <Icon icon='fluent:building-retail-20-regular' />,
      label: 'Sales Quotation Details',
      fields: [
        { key: 'salesQuotationCode', label: 'Code' },
        { key: 'salesQuotationName', label: 'Name' },
      ],
    };

    IconsListing = IconsListing.filter(
      (icon) => icon.label !== 'Sales Quotation Details'
    );
    IconsListing.unshift(salesQuotationDetails);

    setSelectedOrder(props);
  };

  const handleReset = () => {
    reset();
    setSelectedOrder(null);
  };

  const handleClearSelection = () => {
    IconsListing = IconsListing.filter(
      (icon) => icon.label !== 'Sales Quotation Details'
    );
    setSelectedOrder(null);
  };
  const filteredNavMenu = React.useMemo(() => {
    if (!editingRowData?.contractTypeId) return navMenu;

    const contractTypeId = Number(editingRowData.contractTypeId);
    const hasQuotationId = Boolean(editingRowData.salesQuotationId);

    let filteredMenu = contractTypeId === 1
      ? navMenu.filter((item) => [1, 2, 3].includes(item.id))
      : contractTypeId === 3
        ? navMenu.filter((item) => [1, 2, 4].includes(item.id))
        : navMenu;

    if (hasQuotationId) {
      filteredMenu = filteredMenu.filter((item) => item.id !== 1);
    }

    return filteredMenu;
  }, [editingRowData?.contractTypeId, editingRowData?.salesQuotationId]);

  return (
    <>
      <Box className='flex flex-col'>
        <Box className='mb-5'>
          <Grid container spacing={2}>
            <Grid item xs={12} md={9}>
              <GlassCard className='p-4'>
                <form onSubmit={handleSubmit(submitSalesOrder)}>
                  <Grid container spacing={2}>
                    <Grid item md={GridSize}>
                      <FmTextField
                        name='salesOrderName'
                        label='Name'
                        control={control}
                        required
                        disabled={
                          getValues('orderStatusId') === 2 ||
                          salesQuotation?.salesQuotationId !== null
                        }
                      />
                    </Grid>

                    <Grid item md={GridSize}>
                      <FmDatePicker
                        name='orderDate'
                        label='Order Date'
                        control={control}
                        readOnly={
                          salesQuotation?.salesQuotationId !== null ||
                          getValues('orderStatusId') === 2
                        }
                        required
                      />
                    </Grid>

                    <Grid item md={GridSize * 2}>
                      <FmSearchableSelect
                        name='salesQuotationId'
                        label='Sales Quotation'
                        apiUrl={'salesquotations'}
                        disabled={getValues('orderStatusId') === 2}
                        valueField='salesQuotationId'
                        onChangeProp={(value) => handleSelectedQuotation(value)}
                        clearSelection={handleClearSelection}
                        labelField={[
                          'salesQuotationCode',
                          'projectName',
                          'contractName',
                        ]}
                        headerField={['Contract', 'Project', 'Sales Quotation']}
                        showField={['contractName', 'salesQuotationCode']}
                        control={control}
                        defaultValue={{
                          salesQuotationId: editingRowData?.salesQuotationId,
                          salesQuotationName:
                            editingRowData?.salesQuotationCode,
                          salesQuotationCode:
                            editingRowData?.salesQuotationCode,
                        }}
                        stackedFields={{
                          salesQuotationCode: [
                            { Code: 'salesQuotationCode' },
                            { Name: 'salesQuotationName' },
                          ],

                          projectName: [
                            { Code: 'projectCode' },
                            { Name: 'projectName' },
                          ],
                          contractName: [
                            { Code: 'contractCode' },
                            { Name: 'contractName' },
                          ],
                        }}
                        queryparam={'quotationStatusId'}
                        queryparamValue={2}
                      />
                    </Grid>
                    <Grid item md={GridSize}>
                      <FmSearchableSelect
                        name='contractId'
                        control={control}
                        apiUrl='contracts'
                        defaultValue={{
                          contractId: editingRowData?.contractId,
                          contractName: editingRowData?.contractName,
                          contractCode: editingRowData?.contractCode,
                        }}
                        clearSelection={() => setSelectedContract({})}
                        valueField='contractId'
                        headerField={['Contract', 'Project']}
                        labelField={['contractCode', 'projectName']}
                        disabled={getValues('orderStatusId') === 2}
                        showField={['contractCode', 'projectName']}
                        label='Contract'
                        stackedFields={{
                          contractCode: [
                            { Code: 'contractCode' },
                            { Name: 'contractName' },
                          ],
                          projectName: [
                            { Code: 'projectCode' },
                            { Name: 'projectName' },
                          ],
                        }}
                        onChangeProp={(value) => setSelectedContract(value)}
                      />
                    </Grid>
                    <Grid item md={GridSize}>
                      <FmSearchableSelect
                        name='currencyId'
                        control={control}
                        apiUrl='currencies'
                        valueField='currencyId'
                        headerField={['Name']}
                        labelField={['currencyName']}
                        disabled={getValues('orderStatusId') === 2}
                        showField={['currencyName']}
                        defaultValue={{
                          currencyId: editingRowData?.currencyId,
                          currencyName: editingRowData?.currencyName,
                          currencyCode: editingRowData?.currencyCode,
                        }}
                        label={'Currency'}
                      />
                    </Grid>
                    <Grid item md={GridSize}>
                      <FmSearchableSelect
                        name='paymentTermId'
                        control={control}
                        apiUrl='paymentterms'
                        disabled={getValues('orderStatusId') === 2}
                        valueField='paymentTermId'
                        headerField={['Payment Term']}
                        labelField={['paymentTermName']}
                        showField={['paymentTermName']}
                        defaultValue={{
                          paymentTermId: editingRowData?.paymentTermId,
                          paymentTermName: editingRowData?.paymentTermName,
                          paymentTermCode: editingRowData?.paymentTermCode,
                        }}
                        label='Payment Term'
                      />
                    </Grid>

                    <Grid item md={GridSize}>
                      <FmTextField
                        name='narration'
                        label='Narration'
                        control={control}
                        disabled={
                          salesQuotation?.salesQuotationId !== null ||
                          getValues('orderStatusId') === 2
                        }
                      />
                    </Grid>
                    <Grid item md={GridSize}>
                      <FmTextField
                        name='deliveryTerms'
                        label='Delivery Terms'
                        control={control}
                        disabled={
                          salesQuotation?.salesQuotationId !== null ||
                          getValues('orderStatusId') === 2
                        }
                      />
                    </Grid>

                    <Grid item md={GridSize}>
                      <FmTextField
                        name='vat'
                        label='VAT (%)'
                        disabled={getValues('orderStatusId') === 2}
                        control={control}
                        pattern='Percentage'
                      />
                    </Grid>
                    <Grid item md={GridSize}>
                      <Box className='flex justify-between flex-col gap-0.5'>
                        <Box className='mb-1 flex items-center gap-2 font-semibold justify-between'>
                          <Box>
                            Amount :{' '}
                            <CurrencyView
                              value={selectedContract?.budget}
                              currencyType={'AED'}
                            />
                          </Box>
                        </Box>
                      </Box>
                    </Grid>
                    <Grid item md={GridSize * 2}>
                      <FmTextField
                        name='remarks'
                        multiline
                        rows={4}
                        label='Remarks'
                        control={control}
                        disabled={salesQuotation?.salesQuotationId !== null}
                      />
                    </Grid>

                    <Grid item md={GridSize * 2}>
                      <ActionButtons
                        onSubmit={handleSubmit(submitSalesOrder)}
                        onReset={handleReset}
                        submitLoading={false}
                        cancelLoading={false}
                        onCancel={() =>
                          navigate('/financial/accounts-receivable/sales-order')
                        }
                        cancelText='Go Back'
                        submitText='Update'
                      />
                    </Grid>
                  </Grid>
                </form>
              </GlassCard>
            </Grid>
            {/* Right side - Contract Details */}
            <Grid item xs={12} md={3}>
              <Box className='relative h-full w-full'>
                <ViewContractDetails
                  selectedContract={selectedContract}
                  IconsListing={IconsListing}
                  selectedOrder={selectedOrder}
                />
              </Box>
            </Grid>
          </Grid>
        </Box>

        {/* <InternalBudget /> */}

        <GlassCard className='mb-1 px-2'>
          <FmTabsOne
            value={tabValue}
            handleChange={handleChange}
            data={filteredNavMenu}
            centered
          />
        </GlassCard>

        {tabValue === 1 && <VewMrtTableSalesOrder type={type} id={id} />}

        {tabValue === 2 && <InternalBudget />}

        {tabValue === 3 && <SchedulesView />}
        {tabValue === 4 && (
          <Containers width={800}>
            {' '}
            <SOMilestones />
          </Containers>
        )}
      </Box>
    </>
  );
};

export default EditSalesOrder;
