import React, { useEffect, useState } from 'react';
import { Box, IconButton, Button, CircularProgress } from '@mui/material';
import { useDropzone } from 'react-dropzone';
import { Icon } from '@iconify/react/dist/iconify.js';
import GlassCard from '../small/GlassCard.tsx';
import { toast } from 'react-toastify';
import UploadModalFileViewer from './UploadModalFileViewer.tsx';

const UploadSquare = ({
  onFilesSelected,
  previewdData = [],
  uploadSingle = false,
  name = 'Multiple Upload File',
  fileType,
  glassCard,
  setRemovedFiles,
  disableUpload = true,
  loadingFiles = false,
}) => {
  const [files, setFiles] = useState([]);

  const allowedTypes = {
    image: 'image/jpeg,image/png,image/jpg,image/gif,image/webp',
    all: '*',
  };

  const [openFile, setOpenFile] = useState(false);

  useEffect(() => {
    if (previewdData?.length > 0) {
      const filteredPreviewdData = previewdData.reduce((acc, data) => {
        if (!acc.some((file) => file.fileId === data.fileId)) {
          acc.push(data);
        }
        return acc;
      }, []);

      const parsedFiles = filteredPreviewdData
        ?.map((data, index) => {
          if (
            typeof data?.fileUrl === 'string' &&
            data?.fileUrl?.includes(';base64,')
          ) {
            const [type] = data?.fileUrl?.split(';base64,');
            const fileType = type.split(':')[1];

            return {
              type: fileType,
              preview: data?.fileUrl,
              name: `File-${index + 1}`,
              apiCallNo: false,
              isExisting: true, // Indicate it's an existing file
              fileId: data?.fileId,
            };
          }
          return null;
        })
        .filter(Boolean);
      setFiles(parsedFiles);
    }
  }, [previewdData]);

  const { getRootProps, getInputProps, isDragActive } = useDropzone({
    accept: allowedTypes[fileType] || allowedTypes.all,
    multiple: true,
    onDrop: async (acceptedFiles, rejectedFiles) => {
      try {
        let filteredFiles = acceptedFiles;

        if (fileType === 'image') {
          filteredFiles = acceptedFiles.filter((file) => {
            const isImage = [
              'image/jpeg',
              'image/png',
              'image/jpg',
              'image/gif',
              'image/webp',
            ].includes(file.type);
            if (!isImage) {
              toast.error(
                `${file.name} is not a valid image type. only images are allowed`,
                { position: 'top-right' }
              );
            }
            return isImage;
          });
        }

        const newFiles = filteredFiles?.map((file) =>
          Object.assign(file, { preview: URL.createObjectURL(file) })
        );

        const uniqueFiles = Array.from(
          new Map(
            [...(files || []), ...newFiles].map((file) => [file?.name, file])
          ).values()
        );

        setFiles(uniqueFiles);

        if (typeof onFilesSelected === 'function') {
          onFilesSelected(uniqueFiles);
        }

        if (rejectedFiles?.length > 0) {
          console.warn('Rejected files:', rejectedFiles);
        }
      } catch (error) {
        console.error('Error processing files:', error);
      }
    },
  });

  const removeNewFile = (file) => {
    const updatedFiles = files?.filter((f) =>
      previewdData?.length > 0
        ? f?.fileId !== file?.fileId
        : f?.name === file?.name
    );

    setFiles(updatedFiles);

    if (typeof onFilesSelected === 'function') {
      onFilesSelected(updatedFiles);
    }
  };

  return glassCard ? (
    <>
      <GlassCard>
        {name && (
          <Box
            className='font-semibold p-2'
            sx={{ borderBottom: '1px solid', borderColor: 'border.main' }}
          >
            {name}
          </Box>
        )}
        <Box className='flex flex-wrap gap-2 p-2'>
          {/* New Files Preview */}
          {files?.map((file, index) => (
            <Box
              key={`new-${index}`}
              sx={{
                position: 'relative',
                width: '150px',
                height: '150px',
                border: '1px solid',
                borderColor: 'border.main',
                borderRadius: '5px',
                overflow: 'hidden',
              }}
            >
              {file.type.startsWith('image/') ? (
                <Box
                  component='img'
                  src={file?.preview}
                  alt={file?.name}
                  sx={{
                    width: '100%',
                    height: '100%',
                    objectFit: 'cover',
                  }}
                />
              ) : (
                <Icon
                  icon='teenyicons:pdf-solid'
                  width='60'
                  height='60'
                  style={{ color: 'red' }}
                />
              )}
              <IconButton
                onClick={() => removeNewFile(file)}
                sx={{
                  position: 'absolute',
                  top: '8px',
                  right: '8px',
                  backgroundColor: 'error.main',
                  color: '#fff',
                  '&:hover': {
                    backgroundColor: 'error.dark',
                  },
                }}
              >
                <Icon icon='material-symbols:close' width='20' height='20' />
              </IconButton>
            </Box>
          ))}
          {/* Upload Button */}
          <Button
            {...getRootProps()}
            sx={{
              width: '150px',
              height: '150px',
              border: '1px dashed',
              borderColor: 'primary.main',
              borderRadius: '5px',
              cursor: 'pointer',
              bgcolor: isDragActive
                ? 'background.light'
                : 'background.mainLight',
            }}
          >
            <input {...getInputProps()} />
            <Box>
              <Icon icon='ph:plus-light' width='45' height='45' />
            </Box>
            <Box>Add Upload</Box>
          </Button>
        </Box>
      </GlassCard>
    </>
  ) : (
    <>
      <Box className='flex flex-wrap gap-2'>
        {/* New Files Preview */}
        {loadingFiles ? (
          <CircularProgress />
        ) : (
          files?.map((file, index) => (
            <Box
              key={`new-${index}`}
              sx={{
                position: 'relative',
                width: '100px',
                height: '100px',
                border: '1px solid',
                borderColor: 'border.main',
                borderRadius: '5px',
                overflow: 'hidden',
              }}
              className='flex justify-center items-center'
            >
              {file.type.startsWith('image/') ? (
                <Box
                  component='img'
                  src={file?.preview}
                  alt={file?.name}
                  sx={{
                    width: '100%',
                    height: '100%',
                    objectFit: 'cover',
                  }}
                />
              ) : (
                <>
                  <UploadModalFileViewer
                    open={openFile}
                    onClose={() => setOpenFile(!openFile)}
                    documents={[
                      {
                        uri: file?.preview,
                      },
                    ]}
                    titleEnable={file?.name}
                  />
                  <Icon
                    icon='teenyicons:pdf-solid'
                    width='60'
                    height='60'
                    style={{ color: 'red' }}
                    onClick={() => setOpenFile(!openFile)}
                    className='cursor-pointer'
                  />
                </>
              )}
              {disableUpload && (
                <IconButton
                  onClick={() => removeNewFile(file?.fileId)}
                  sx={{
                    position: 'absolute',
                    top: '8px',
                    right: '8px',
                    backgroundColor: 'error.main',
                    color: '#fff',
                    '&:hover': {
                      backgroundColor: 'error.dark',
                    },
                  }}
                  className='p-0'
                >
                  <Icon icon='material-symbols:close' width='20' height='20' />
                </IconButton>
              )}
            </Box>
          ))
        )}
        {/* Upload Button */}
        {disableUpload && (
          <Button
            {...getRootProps()}
            sx={{
              width: '100px',
              height: '100px',
              border: '1px dashed',
              borderColor: 'border.main',
              borderRadius: '5px',
              cursor: 'pointer',
              bgcolor: isDragActive
                ? 'background.mainLight'
                : 'background.light',
            }}
            className='flex flex-col justify-center items-center gap-2 p-0'
          >
            <input {...getInputProps()} />
            <Box>
              <Icon icon='ph:plus-light' width='45' height='45' />
            </Box>
            <Box>Add Upload</Box>
          </Button>
        )}
      </Box>
    </>
  );
};

export default UploadSquare;
