import React, { useState } from 'react';
import GlassCard from '../../../components/small/GlassCard.tsx';
import { Box, Button, Grid, IconButton } from '@mui/material';
import Crud_Service from '../../../apis/CrudService.jsx';
import { useFieldArray, useForm } from 'react-hook-form';
import { yupResolver } from '@hookform/resolvers/yup';
import { VendorDocumentSchema } from '../../../utils/CommonvalidationSchemas.tsx';
import FmTextField from '../../../components/_mui/FmTextField.tsx';
import { toast } from 'react-toastify';
import FmSearchableSelect from '../../../components/_mui/FmSearchableSelect.tsx';
import ActionButtons from '../../../components/_form/ActionButtons.tsx';
import dayjs from 'dayjs';
import DashboardLayout from '../../../components/DashboardLayout.tsx';
import { Icon } from '@iconify/react/dist/iconify.js';
import { useNavigate } from 'react-router-dom';
import useLoader from '../../../components/helpers/UseLoader.tsx';
import UploadModalBox from '../../../components/upload/UploadModalBox.tsx';
import { FileType } from '../../../constants/FileType.ts';

const GridSize = 3;

const AddVendorDocuments = () => {
  const crud = new Crud_Service();
  const navigate = useNavigate();
  const [uploadedFileIds, setUploadedFileIds] = useState([]);
  const [files, setFiles] = useState([]);
  const { handleSubmit, control, reset } = useForm({
    defaultValues: {
      vendorDocuments: [
        {
          invoiceNumber: '',
          supplierId: '',
          localPurchaseOrderNo: '',
          documentTypeId: '',
        },
      ],
    },
    resolver: yupResolver(VendorDocumentSchema),
    mode: 'onChange',
  });
  const { startCreateLoading, stopCreateLoading, createLoading } = useLoader();
  const { fields, append, remove } = useFieldArray({
    control,
    name: 'vendorDocuments',
  });

  const handleSubmitVendorInvoice = async (values) => {
    startCreateLoading();

    const combinedData = values?.vendorDocuments?.map((item, MainIndex) => {
      return {
        ...item,
        receivedDate: dayjs().format('YYYY-MM-DD'),
        attachmentIds:
          uploadedFileIds[MainIndex]?.length > 0
            ? uploadedFileIds[MainIndex]?.map((file) => file)
            : [],
        documentTransferredTo: null,
      };
    });

    await crud.create('vendordocuments', combinedData, (_err, res) => {
      if (res?.status === 201) {
        toast.success('Vendor Document Created Successfully');
        reset();
        stopCreateLoading();
        navigate(-1);
      } else {
        stopCreateLoading();
      }
    });
  };

  const handleFileIds = (fileIds, rowIndex) => {
    setUploadedFileIds((prev) => {
      const updatedFileIds = [...prev];
      updatedFileIds[rowIndex] = fileIds; // Assign file IDs to the specific row
      return updatedFileIds;
    });
  };

  return (
    <DashboardLayout title='Add Vendor Document'>
      <form onSubmit={handleSubmit(handleSubmitVendorInvoice)}>
        {fields?.map((item, index) => (
          <GlassCard className='p-4 mb-3 flex items-center gap-5 flex-col'>
            <Grid container spacing={2} key={item?.id}>
              <Grid item md={GridSize}>
                <FmSearchableSelect
                  name={`vendorDocuments[${index}].supplierId`}
                  control={control}
                  apiUrl='suppliers'
                  required
                  valueField='supplierId'
                  headerField={['Code', 'Name']}
                  labelField={[
                    'supplierInvitationCode',
                    'officialSupplierName',
                  ]}
                  showField={['supplierInvitationCode', 'officialSupplierName']}
                  sortBy={'officialSupplierName'}
                  label='Supplier'
                />
              </Grid>
              <Grid item md={GridSize}>
                <FmSearchableSelect
                  name={`vendorDocuments[${index}].documentTypeId`}
                  control={control}
                  apiUrl='documenttypes'
                  valueField='documentTypeId'
                  required
                  headerField={['Document Type']}
                  queryparam={'documentTypeCategoryId'}
                  queryparamValue={11}
                  labelField={['documentTypeName']}
                  showField={['documentTypeName']}
                  sortBy={'sortNumber'}
                  label='Document Type'
                />
              </Grid>
              <Grid item md={GridSize}>
                <FmTextField
                  name={`vendorDocuments[${index}].localPurchaseOrderNo`}
                  label='Local Purchase Order No.'
                  control={control}
                  type='string'
                />
              </Grid>
              <Grid item md={GridSize}>
                <FmTextField
                  name={`vendorDocuments[${index}].invoiceNumber`}
                  label='Document Ref. No./Invoice No.'
                  control={control}
                  type='string'
                />
              </Grid>
            </Grid>
            <Grid container spacing={2}>
              <Grid item md={GridSize}>
                <UploadModalBox
                  onSubmitFiles={(fileIds) => handleFileIds(fileIds, index)}
                  files={files}
                  setFiles={setFiles}
                  fileType={FileType.VendorDocument}
                />
              </Grid>
            </Grid>
            <Grid container spacing={2} justifyContent='flex-end'>
              <Grid item md={GridSize * 2}>
                <FmTextField
                  name={`vendorDocuments[${index}].remarks`}
                  label='Remarks'
                  control={control}
                  multiline={true}
                  rows={2}
                  maxRows={3}
                />
              </Grid>
              <IconButton
                onClick={() => remove(index)}
                disabled={fields?.length === 1}
                sx={{
                  padding: '1px',
                  marginLeft: 'auto',
                }}
                color='secondary'
              >
                <Icon icon='ic:baseline-clear' />
              </IconButton>
            </Grid>
          </GlassCard>
        ))}
        <Box className='mt-5 w-full flex justify-end'>
          <Button
            variant='text'
            onClick={() => append({})}
            className='uppercase font-bold text-orange-500'
            startIcon={<Icon icon='ic:baseline-plus' />}
          >
            Add Field
          </Button>
        </Box>
        <ActionButtons
          onSubmit={handleSubmit(handleSubmitVendorInvoice)}
          onReset={() => {
            reset();
          }}
          onCancel={() => navigate(-1)}
          submitLoading={createLoading}
          submitText='Submit'
          cancelText='Go Back'
        />
      </form>
    </DashboardLayout>
  );
};

export default AddVendorDocuments;
