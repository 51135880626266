import { Box } from '@mui/material';
import React from 'react';

const DatatableLoader = () => {
  return (
    <Box className='flex justify-center items-center h-full w-full bg-white relative'>
      <Box
        src='/images/loading.gif'
        component={'img'}
        sx={{ width: '45px', height: 'auto' }}
      />
    </Box>
  );
};

export default DatatableLoader;
