import { yupResolver } from '@hookform/resolvers/yup';
import { Box, Grid } from '@mui/material';
import dayjs from 'dayjs';
import React, { useEffect, useState } from 'react';
import { useForm } from 'react-hook-form';
import { useNavigate, useParams } from 'react-router-dom';
import { toast } from 'react-toastify';
import * as Yup from 'yup';
import { object } from 'yup';
import Crud_Service from '../../../../../apis/CrudService.jsx';
import FmDatePicker from '../../../../../components/_mui/FmDatePicker.tsx';
import FmSearchableSelect from '../../../../../components/_mui/FmSearchableSelect.tsx';
import GlassCard from '../../../../../components/small/GlassCard.tsx';
import { ViewField } from '../../../../../components/_form/FormElements.jsx';
import ActionButtons from '../../../../../components/_form/ActionButtons.tsx';
import useLoader from '../../../../../components/helpers/UseLoader.tsx';
import FmStack from '../../../../../components/layout/FmStack.tsx';
import { Icon } from '@iconify/react/dist/iconify.js';
import MuiDialogOne from '../../../../../components/_mui/MuiDialogOne.jsx';
import InternalSalesInvoiceChecklist from '../InternalSalesInvoiceChecklist.tsx';
import EditListInternalInvoicesAR from '../Edit/EditListInternalInvoicesAR.tsx';
import useCommonFetchApi from '../../../../../components/helpers/useCommonFetchApi.tsx';
import MuiStepperOne from '../../../../../components/_mui/MuiStepperOne.jsx';
import { internalInvoiceStatusEnum } from '../../../../../utils/CommonVariables.tsx';

const IconsListing = [
  {
    icon: <Icon icon='fluent:book-database-20-regular' />,
  },
  {
    icon: <Icon icon='tabler:building' />,
  },
  {
    icon: <Icon icon='tabler:building' />,
  },
  {
    icon: <Icon icon='fluent:building-people-16-regular' />,
  },
  {
    icon: <Icon icon='fluent:building-people-16-regular' />,
  },
  {
    icon: <Icon icon='tabler:building' />,
  },
  {
    icon: <Icon icon='fluent:book-number-24-regular' />,
  },
  {
    icon: <Icon icon='fluent:notebook-question-mark-20-regular' />,
  },
];

const validationSchema = object({
  clientId: Yup.number().required('Client is required'),
  salesOrderId: Yup.number().required('Sales Order is required'),
}).required();

const AddInternalInvoiceAR = ({ editMode }) => {
  const {
    createLoading,
    startCreateLoading,
    stopCreateLoading,
    editLoading,
    startEditLoading,
    stopEditLoading,
  } = useLoader();
  const GridValue = 3;
  const navigate = useNavigate();
  const [openChecklist, setOpenChecklist] = useState(false);
  const {
    handleSubmit,
    control,
    reset,
    register,
    setValue,
    formState: { isValid },
  } = useForm({
    resolver: yupResolver(validationSchema),
    mode: 'onSubmit',
  });
  const id = useParams()?.id;
  const { data: internalInvoice } = useCommonFetchApi(
    'enums/internal-invoice-status'
  );
  const crud = new Crud_Service();

  useEffect(() => {
    register('invoiceStatusId');
  }, [register]);

  const [invoice, setInvoice] = React.useState({});

  const getSalesInvoice = async () => {
    await crud.getSingle(`internalinvoices`, id, (_err, res) => {
      if (res?.status === 200) {
        reset(res?.data);
        setInvoice(res?.data);
        setSelectedClient(res?.data?.clientId);
        if (res?.data?.salesOrderId) {
          crud.getSingle(
            `salesorders`,
            res?.data?.salesOrderId,
            (_err, res) => {
              if (res?.status === 200) {
                setSelectedSalesOrder(res?.data);
              }
            }
          );
        }
      }
    });
  };

  useEffect(() => {
    id && getSalesInvoice();
  }, [id]);

  const [selectedClient, setSelectedClient] = React.useState(null);
  const [selectedSalesOrder, setSelectedSalesOrder] = React.useState(null);
  const handleSelectedClient = (data) => {
    setSelectedClient(data?.clientId);
  };
  const handleSelectedSalesOrder = (data) => {
    setSelectedSalesOrder(data);
  };

  const onSubmitDraft = async (data) => {
    startEditLoading();
    const values = {
      ...data,
      invoiceStatusId: id ? data?.invoiceStatusId : 1,
      dueDate: dayjs(data?.dueDate).format('YYYY-MM-DD'),
      internalInvoiceDate: dayjs(data?.internalInvoiceDate).format(
        'YYYY-MM-DD'
      ),
      salesInvoiceDate: dayjs(data?.salesInvoiceDate).format('YYYY-MM-DD'),
    };
    if (id) {
      await crud.update('internalinvoices', id, values, (_err, res) => {
        if (res?.status === 200) {
          toast.success('Sales Invoice Updated Successfully');
          stopEditLoading();
          window.location.reload();
        }
        setOpenChecklist(false);
      });
    } else {
      await crud.create('internalinvoices', values, (_err, res) => {
        if (res?.status === 201) {
          toast.success('Sales Invoice Created Successfully');
          stopEditLoading();
          reset();
          navigate('../edit/' + res?.data?.internalInvoiceId);
        }
        setOpenChecklist(false);
      });
    }
  };
  const handleOpenChecklist = () => {
    setOpenChecklist(true);
  };
  const onSubmitReadyForInvoice = async (values) => {
    startCreateLoading();

    await crud.create(`internalinvoices/process/${id}`, values, (_err, res) => {
      if (res?.status === 201) {
        stopCreateLoading();
        toast.success('Sales Invoice Created');
        reset();
        setOpenChecklist(false);
      } else {
        stopCreateLoading();
      }
    });
  };

  const handleStatusChange = (statusNumber) => {
    setValue('invoiceStatusId', statusNumber, { shouldValidate: true });
    if (statusNumber === internalInvoiceStatusEnum?.Approved) {
      handleOpenChecklist();
    } else {
      handleSubmit(onSubmitDraft)();
    }
  };

  const isEditable = [
    internalInvoiceStatusEnum?.Draft,
    internalInvoiceStatusEnum?.ReadyToInvoice,
  ].includes(invoice?.invoiceStatusId);
  const disbaledFields = id ? (isEditable ? false : true) : false;

  const handleModalClose = () => {
    setOpenChecklist(false);
  };

  return (
    <Box className='flex flex-col gap-2 h-full'>
      <GlassCard className='p-4'>
        {' '}
        <Box className='w-full'>
          <Grid container spacing={2}>
            <Grid item md={9}>
              <form>
                <FmStack spacing={2}>
                  <Box>
                    <Grid container spacing={2}>
                      <Grid item md={GridValue * 2}>
                        <FmSearchableSelect
                          name='clientId'
                          control={control}
                          apiUrl='clients'
                          valueField='clientId'
                          labelField={['clientName', 'clientCode']}
                          showField={['clientName', 'clientCode']}
                          label='Client'
                          onChangeProp={handleSelectedClient}
                          defaultValue={{
                            clientId: invoice?.clientId,
                            clientName: invoice?.clientName,
                          }}
                          required
                          disabled={disbaledFields}
                        />
                      </Grid>
                      <Grid item md={GridValue * 2}>
                        <FmSearchableSelect
                          name='salesOrderId'
                          control={control}
                          apiUrl='salesorders'
                          valueField='salesOrderId'
                          headerField={['Sales Order', 'Project', 'Contract']}
                          labelField={[
                            'salesOrderCode',
                            'projectName',
                            'contractName',
                          ]}
                          showField={['salesOrderCode', 'salesOrderName']}
                          stackedFields={{
                            salesOrderCode: [
                              { Code: 'salesOrderCode' },
                              { Name: 'salesOrderName' },
                            ],
                            projectName: [
                              { Code: 'projectCode' },
                              { Name: 'projectName' },
                            ],
                            contractName: [
                              { Code: 'contractCode' },
                              { Name: 'contractName' },
                            ],
                          }}
                          queryparam='clientId'
                          queryparamValue={selectedClient}
                          onChangeProp={handleSelectedSalesOrder}
                          label='Sales Order'
                          defaultValue={{
                            salesOrderId: invoice?.salesInvoiceId,
                            salesOrderCode: invoice?.salesOrderCode,
                            salesOrderName: invoice?.salesOrderName,
                          }}
                          required
                          disabled={disbaledFields}
                        />
                      </Grid>
                      <Grid item md={GridValue}>
                        <FmSearchableSelect
                          name='jurisdictionId'
                          control={control}
                          apiUrl='jurisdictions'
                          labelField={['jurisdictionCode', 'jurisdictionName']}
                          showField={['jurisdictionCode', 'jurisdictionName']}
                          defaultValue={{
                            jurisdictionId: invoice?.juridicationId,
                            jurisdictionName: invoice?.jurisdictionName,
                          }}
                          valueField='jurisdictionId'
                          label={'Jurisdiction'}
                          disabled={disbaledFields}
                        />
                      </Grid>
                    </Grid>
                  </Box>

                  <Box>
                    <Grid container spacing={2}>
                      <Grid item md={GridValue}>
                        <FmDatePicker
                          name='internalInvoiceDate'
                          label='Invoice Date'
                          control={control}
                          disabled={disbaledFields}
                        />
                      </Grid>
                      <Grid item md={GridValue}>
                        <FmDatePicker
                          name='dueDate'
                          label='Due Date'
                          control={control}
                          disabled={disbaledFields}
                        />
                      </Grid>
                      <Grid item md={GridValue}>
                        <FmSearchableSelect
                          name='currencyId'
                          label='Currency'
                          apiUrl={'currencies'}
                          valueField='currencyId'
                          labelField={['currencyCode', 'currencyName']}
                          showField={['currencyCode', 'currencyName']}
                          control={control}
                          defaultValue={{
                            currencyId: invoice?.currencyId,
                            currencyName: invoice?.currencyName,
                          }}
                          disabled={disbaledFields}
                        />
                      </Grid>
                    </Grid>
                  </Box>

                  {!id ? (
                    <ActionButtons
                      onSubmit={handleSubmit(onSubmitDraft)}
                      onCancel={() => navigate(-1)}
                      submitLoading={createLoading}
                      submitText={'Submit'}
                      removeSpacing
                    />
                  ) : (
                    <MuiStepperOne
                      api='enums/internal-invoice-status'
                      type={2}
                      status={invoice?.invoiceStatusId}
                      handleStatusChange={handleStatusChange}
                      previousStatusId={invoice?.previousInvoiceStatusId}
                    />
                  )}
                </FmStack>
              </form>
            </Grid>
            <Grid item md={3}>
              <Box className='relative min-h-full'>
                <GlassCard
                  className='p-2 absolute left-0 top-0 w-full h-full overflow-auto'
                  bgDark
                >
                  <Grid container spacing={1}>
                    {[
                      'contractTypeName',
                      'contractCode',
                      'contractName',
                      'projectCode',
                      'projectName',
                      'branchName',
                      'contractIncharge',
                      'paymentTermName',
                    ].map((key, i) => (
                      <Grid item md={12} key={key}>
                        <Box className='flex items-center gap-3'>
                          <Box className='text-xl' color={'primary.main'}>
                            {IconsListing[i].icon}
                          </Box>
                          <ViewField
                            label={key.replace(/([A-Z])/g, ' $1').trim()} // Converts camelCase to readable label
                            title={selectedSalesOrder?.[key] || 'N/A'} // Safely access the key or show fallback
                          />
                        </Box>
                      </Grid>
                    ))}
                  </Grid>
                </GlassCard>
              </Box>
            </Grid>
          </Grid>
        </Box>
        {id && (
          <MuiDialogOne
            title='Check List - Internal Invoices'
            open={openChecklist}
            width='80%'
            onClose={() => setOpenChecklist(!openChecklist)}
          >
            <InternalSalesInvoiceChecklist
              contractItemId={invoice?.contractId}
              salesOrderId={invoice?.salesOrderId}
              internalInvoiceId={invoice?.internalInvoiceId}
              clientId={invoice?.clientId}
              handleModalClose={handleModalClose}
            />
          </MuiDialogOne>
        )}
      </GlassCard>
      {id && (
        <EditListInternalInvoicesAR
          invoiceStatusId={invoice?.invoiceStatusId}
        />
      )}
    </Box>
  );
};

export default AddInternalInvoiceAR;
