import React, { useEffect, useState } from 'react';
import { useNavigate, useParams } from 'react-router-dom';
import BackToButton from '../../../../../components/small/BackToButton.tsx';
import Crud_Service from '../../../../../apis/CrudService.jsx';
import DashboardLayout from './../../../../../components/DashboardLayout.tsx';
import ViewPettyCashList from '../ListPettyCash.tsx';
import EditCBR from '../../Receipts/EditCBR.tsx';
import ListPettyCash from '../ListPettyCash.tsx';
import ViewCashBankReceiptList from '../../Receipts/View/ViewCashBankReceiptList.tsx';
import EditPettyCash from '../EditPettyCash.tsx';
import ListViewPettyCashItem from './ListViewPettyCashItem.tsx';
import { NavigationEnum } from '../../../../../utils/CommonVariables.tsx';
import ElementLoader from '../../../../../components/page/ElementLoader.tsx';

const ViewPettyCash = (props) => {
  //Note
  //Type 1 for Receipt, Type 2 for PettyCash
  const { type } = props;
  const navigation = useNavigate();
  const { id } = useParams();
  const crud = new Crud_Service();
  const [viewData, setViewData] = useState({});
  const [exchangeRate, setExchangeRate] = useState(null);
  const [loading, setLoading] = useState(false);

  const viewDataFetch = async (type, id) => {
    await crud.getSingle(type, id, (err, res) => {
      if (err) {
        setViewData([]);
        return;
      }
      setViewData(res?.data);
    });
  };

  useEffect(() => {
    if (type === 1) {
      viewDataFetch('financialtransactions', id);
    } else {
      viewDataFetch('pettycashtransactions', id);
    }
  }, [type, id]);

  useEffect(() => {
    document.title = `View |'Petty Cash'| Cash and Bank | Financial`;
  });

  const onNavClick = (direction: boolean) => {
    setLoading(true);
    crud.getAll(
      `navigations/${NavigationEnum.PETTY_CASH}/api/${id}/navigation/${direction}`,
      '',
      (err, res) => {
        setLoading(false);
        navigation(`/financial/cash-and-bank/petty-cash/view/${res?.data?.id}`);
      }
    );
  };

  return (
    <>
      <DashboardLayout
        title={'Petty Cash'}
        onNavClick={onNavClick}
        actionButtons={
          <BackToButton
            title='Back to List'
            onClick={() => navigation(-1)}
            className='font-bold py-0 px-1'
          />
        }
        titleReverse
      >
        {loading ? (
          <ElementLoader fly image />
        ) : (
          <>
            {' '}
            <EditPettyCash setExchangeRate={setExchangeRate} exchangeRate={exchangeRate} />

          </>
        )}
      </DashboardLayout>
    </>
  );
};

export default ViewPettyCash;
