import React, { useEffect, useState } from 'react';
import DashboardLayout from '../../../components/DashboardLayout.tsx';
import { CreateButton } from '../../../components/small/Buttons.jsx';
import GlassCard from '../../../components/small/GlassCard.tsx';
import { Box } from '@mui/material';
import DataTable from '../../../components/DataTable.tsx';
import TableActionButtons from '../../../components/_form/TableActionButtons.jsx';
import Crud_Service from '../../../apis/CrudService.jsx';
import { useNavigate } from 'react-router-dom';
import useTableLogic from '../../../components/helpers/UseTableLogic.tsx';
import TableSearch from '../../../components/_form/TableSearch.jsx';
import FmViewDateField from '../../../components/_mui/FmViewDateField.tsx';
import CommonView from '../../../components/helpers/CommonView.jsx';
import DynamicViewFields from '../../../components/helpers/DynamicViewFields.jsx';
import BackToButton from '../../../components/small/BackToButton.jsx';
import { toast } from 'react-toastify';
import DataTableNoRow from '../../../components/small/DataTableNoRow.tsx';
import Icons from '../../../utils/Icon.jsx';
import TextElipseTooltip from '../../../components/datagrid/TextElipseTooltip.tsx';
import ViewTableLightText from '../../../components/view/ViewTableLightText.tsx';
import ViewStartEndDate from '../../../components/view/ViewStartEndDate.tsx';
import TableAvatar from '../../../components/_form/TableAvatar.jsx';
import TableExtraContentTooltip from '../../../components/datagrid/TableExtraContentTooltip.tsx';
import { Icon } from '@iconify/react/dist/iconify.js';
import { GridPagination } from '@mui/x-data-grid';

const getContractScopeNames = (row) => {
  if (
    Array.isArray(row.contractScopeMaps) &&
    row.contractScopeMaps.length > 0
  ) {
    return row.contractScopeMaps
      .map((item) => item.contractScopeName)
      .join('<br />');
  }
  return '';
};

const getGuranteeNames = (row) => {
  if (
    Array.isArray(row.contractGuarantees) &&
    row.contractGuarantees.length > 0
  ) {
    return row.contractGuarantees
      .map((item) => item.guaranteeName)
      .join('<br />');
  }
  return '';
};

const ManageContracts = () => {
  const [viewData, setViewData] = useState(null);
  const crud = new Crud_Service();
  const {
    rows,
    pageSize,
    pageNumber,
    isLoading,
    tableRecordCounts,
    handlePaginationModelChange,
    handleSortModelChange,
    setSearchKeyword,
    searchKeyword,
    fetchData,
  } = useTableLogic('contracts');

  const navigate = useNavigate();

  useEffect(() => {
    document.title = `Presales-Manage Contracts`;
  }, []);

  //setvalues to the Inputs
  const handleEditClick = async (props) => {
    navigate(`edit/${props?.contractId}`);
  };

  //view the ShiftTiming
  const handleViewClick = async (view) => {
    setViewData(view);
    navigate(`view/${view?.contractId}`);
  };

  //handle Delete
  const handleDelete = async (props) => {
    await crud.remove('contracts', props?.contractId, (_err, res) => {
      if (res?.status === 204) {
        fetchData();
        toast.success('Contract Deleted Successfully');
      } else {
      }
    });
  };

  const ActionData = [
    {
      name: 'View',
      icon: Icons.view,
      onClick: (props) => handleViewClick(props),
    },
    {
      name: 'Edit',
      icon: Icons.edit,
      // onClick: () => setOpenCreate(!openCreate),
      onClick: (props) => handleEditClick(props),
    },
    {
      name: 'Delete',
      icon: Icons.delete,
      onClick: (props) => handleDelete(props),
      danger: true,
    },
  ];

  const columns = [
    {
      field: 'action',
      headerName: '',
      width: 90,
      sortable: false,
      disableClickEventBubbling: false,
      renderCell: (params) => {
        return (
          <TableActionButtons
            Actions={ActionData?.map((action) => ({
              ...action,
              onClick: () => action.onClick(params.row),

            }))}
          />
        );
      },
    },

    {
      field: 'contractName',
      headerName: 'Contract',
      minWidth: 220,
      flex: 1,
      renderCell: (params) => {
        return (
          <Box>
            <Box className='font-semibold'>{params?.value}</Box>
            <ViewTableLightText name={params?.row?.contractCode} />
          </Box>
        );
      },
    },

    {
      field: 'projectName',
      headerName: 'Project',
      minWidth: 220,
      flex: 1,
      renderCell: (params) => {
        return (
          <TableExtraContentTooltip
            titleRows={[
              {
                label: 'Previous Contract Code',
                title:
                  params?.row?.perviousContractCode === ''
                    ? '-'
                    : params?.row?.perviousContractCode,
              },
              {
                label: 'Contract Mode',
                title: params?.row?.contractModeName,
              },
              {
                label: 'Reference Number',
                title: params?.row?.referenceNumber,
              },

              {
                label: 'Contract Type',
                title: params?.row?.contractTypeName,
              },
              {
                label: 'Contract Scope',
                title: (
                  <div
                    dangerouslySetInnerHTML={{
                      __html: getContractScopeNames(params?.row),
                    }}
                  />
                ),
              },
            ]}
          >
            <Box className='flex gap-2 w-full'>
              <Box className='text-yellow-500'>
                <Icon icon='ic:twotone-info' width='24' height='24' />
              </Box>
              <Box>
                <Box>{params?.value}</Box>
                <ViewTableLightText
                  name={`COST : ${params?.row?.contractCost}`}
                />
              </Box>
            </Box>
          </TableExtraContentTooltip>
        );
      },
    },
    {
      field: 'clientName',
      headerName: 'Client',
      minWidth: 170,

      flex: 1,

      renderCell: (params) => {
        return (
          <Box>
            <Box className='font-semibold'>{params?.value}</Box>
            <ViewTableLightText name={`BUDGET : ${params?.row?.budget}`} />
          </Box>
        );
      },
    },
    {
      field: 'contractStartDate',
      headerName: 'Contract  Date',
      minWidth: 160,
      flex: 1,
      renderCell: (params) => {
        return (
          <>
            {params?.value && (
              <ViewStartEndDate
                start={
                  <FmViewDateField value={params?.value} dateTime={true} />
                }
                end={
                  <FmViewDateField
                    value={params?.row?.contractEndDate}
                    dateTime={true}
                  />
                }
              />
            )}
          </>
        );
      },
    },

    {
      field: 'contactPerson',
      headerName: 'Contact',
      minWidth: 220,
      flex: 1,
      renderCell: (params) => {
        return (
          <TableExtraContentTooltip
            titleRows={[
              { label: 'Contact Number', title: params?.row?.contactNumber },
              { label: 'Contract Terms', title: params?.row?.contractTerms },
              {
                label: 'Billing Frequency',
                title: params?.row?.billingFrequencyName,
              },

              {
                label: 'Threshold Value',
                title: params?.row?.thresholdValue,
              },
              {
                label: 'Currency',
                title: params?.row?.currencyName,
              },
            ]}
          >
            {params?.value && (
              <TableAvatar
                name={params?.value}
                desc={params?.row?.contactEmailId}
              />
            )}
          </TableExtraContentTooltip>
        );
      },
    },

    {
      field: 'guaranteeStartDate',
      headerName: 'Guarantee Date',
      minWidth: 180,
      flex: 1,
      renderCell: (params) => {
        return (
          <TableExtraContentTooltip
            titleRows={[
              {
                label: 'Guarantee Amount',
                title: params?.row?.guaranteeAmount,
              },
              {
                label: 'Guarantee',
                title: (
                  <div
                    dangerouslySetInnerHTML={{
                      __html: getGuranteeNames(params?.row),
                    }}
                  />
                ),
              },
            ]}
          >
            {params?.value && (
              <ViewStartEndDate
                start={FmViewDateField({ value: params?.value })}
                end={FmViewDateField({ value: params?.row?.guaranteeEndDate })}
              />
            )}
          </TableExtraContentTooltip>
        );
      },
    },

    {
      field: 'remarks',
      headerName: 'Remarks',
      minWidth: 180,
      flex: 1,
      renderCell: (params) => {
        return <TextElipseTooltip name={params?.value} twoline />;
      },
    },
  ];

  const onRowDoubleClick = (e) => {
    if (e?.row) {
      handleEditClick(e?.row);
    }
  };

  const excludeKeys = [
    'billingFrequencyId',
    'clientId',
    'contractId',
    'contractModeId',
    'contractScopeId',
    'projectTypeId',
    'contractTypeId',
    'currencyId',
    'divisionId',
    'guaranteeId',
    'projectId',
    'projectInChargeId',
    'status',
    'updatedDate',
  ];

  return (
    <>
      <DashboardLayout
        title='Contracts'
        actionButtons={
          <CreateButton name='New' onClick={() => navigate('create')} />
        }
      >
        <>
          {/* Listing */}
          {!viewData && (
            <GlassCard className='h-full-css'>
              <Box className='h-full-css'>
                <DataTable
                  rows={rows}
                  columns={columns}
                  loading={isLoading}
                  onRowDoubleClick={(e) => onRowDoubleClick(e)}
                  getRowClassName={(params) =>
                    `${params.row.workingStatus === 'InActive' &&
                    'bg-red-500 bg-opacity-15'
                    }`
                  }
                  sortingMode='server'
                  paginationMode='server'
                  onPaginationModelChange={(model) =>
                    handlePaginationModelChange(model)
                  }
                  onSortModelChange={(model) => handleSortModelChange(model)}
                  page={pageNumber - 1}
                  pageSize={pageSize}
                  rowCount={tableRecordCounts?.total}
                  hideFooter
                  slots={{
                    toolbar: () => (
                      <Box
                        className='p-2 w-full flex justify-between items-center'
                        sx={{
                          borderBottom: '1px solid',
                          borderColor: 'border.main',
                        }}
                      >
                        <Box sx={{ maxWidth: '350px', width: '100%' }}>
                          <TableSearch
                            placeholder='Search'
                            fullWidth
                            setSearchKeyword={setSearchKeyword}
                            searchValue={searchKeyword}
                          />
                        </Box>

                        <Box
                          sx={{
                            '& .MuiTablePagination-root': {
                              '& .MuiToolbar-root': {
                                minHeight: '34px',
                              },
                            },
                          }}
                        >
                          <GridPagination />
                        </Box>
                      </Box>
                    ),
                    noRowsOverlay: DataTableNoRow,
                  }}
                />
              </Box>
            </GlassCard>
          )}
          {/* Listing */}
        </>

        {/* view */}
        {viewData && viewData?.contractId && (
          <>
            <BackToButton
              title='Back to List'
              className='px-2 mb-4'
              onClick={() => navigate(-1)}
            />
            <GlassCard className='p-5'>
              <CommonView
                url='contracts'
                id={viewData?.contractId}
                excludeKeys={excludeKeys}
                renderFields={(data, fields) => (
                  <DynamicViewFields data={data} fields={fields} />
                )}
              />
            </GlassCard>
          </>
        )}
      </DashboardLayout>
    </>
  );
};

export default ManageContracts;
