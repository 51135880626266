import React, { useEffect, useState } from 'react';
import { useNavigate, useParams } from 'react-router-dom';
import BackToButton from '../../../../../components/small/BackToButton.jsx';
import Crud_Service from '../../../../../apis/CrudService.jsx';
import DashboardLayout from './../../../../../components/DashboardLayout.tsx';
import ViewPettyCashList from '../ListPettyCash.tsx';
import EditCBR from '../../Receipts/EditCBR.tsx';
import ListPettyCash from '../ListPettyCash.tsx';
import ViewCashBankReceiptList from '../../Receipts/View/ViewCashBankReceiptList.tsx';
import EditPettyCash from '../EditPettyCash.tsx';
import ListViewPettyCash from './ListViewPettyCashItem.tsx';
import ListViewPettyCashItem from './ListViewPettyCashItem.tsx';


const ViewPettyCash = (props) => {
  const { type } = props;
  const navigation = useNavigate();
  const { id } = useParams();
  const crud = new Crud_Service();
  const [viewData, setViewData] = useState({});

  const viewDataFetch = async () => {
    crud.getSingle('pettycashtransactions', id, (err, res) => {
      if (res?.status === 200) {
        setViewData(res?.data);
      } else {
        setViewData([]);
      }
    });
  };
  useEffect(() => {
    if (id) {
      viewDataFetch();
    }
  }, [id]);

  useEffect(() => {
    document.title = `View |'Petty Cash'| Cash and Bank | Financial`;
  });

  return (
    <>
      <DashboardLayout
        title={'Petty Cash'}
        actionButtons={
          <BackToButton
            title='Back to List'
            onClick={() => navigation(-1)}
            className='font-bold py-0 px-1'
          />
        }
        titleReverse
      >
        <EditPettyCash />
        <ListViewPettyCashItem
          pettyCashTransactionId={id}
        />

        {/* <ViewPettyCashList
          pettyCashTransactionId={id}
        /> */}
        {/* <ViewPettyCashList pettyCashTransactionId={id} /> */}
      </DashboardLayout>
    </>
  );
};

export default ViewPettyCash;
