import React from 'react';
import GlassCard from './../../../../../components/small/GlassCard.tsx';
import { Box } from '@mui/material';
import {
  StatusBadge,
  ViewField,
} from '../../../../../components/_form/FormElements.jsx';
import FmStack from '../../../../../components/layout/FmStack.tsx';

const ViewContractDetails = (props) => {
  const { selectedContract, IconsListing, selectedOrder } = props;

  return (
    <GlassCard
      className='p-2 h-full absolute left-0 top-0 overflow-auto w-full'
      bgDark
    >
      <FmStack spacing={1}>

        {IconsListing?.map((item, index) => {
          if (item.label === 'Location') {
            return (
              <Box key={index} className='flex flex-col gap-1'>
                <Box>
                  <StatusBadge title={item.label} type='black' />
                </Box>
                <Box className='flex flex-col gap-1'>
                  {item.fields.map((field) => {
                    return (
                      <ViewField
                        label={field.label}
                        title={selectedContract[field.key] || '-'}
                        key={field.key}
                      />
                    );
                  })}
                </Box>
              </Box>
            );
          }
          if (item.label === 'Type' && selectedOrder) {
            return (
              <Box key={index} className='flex flex-col gap-1'>
                <Box>
                  <StatusBadge title={item.label} type='black' />
                </Box>
                <Box className='flex flex-col gap-1'>
                  {item.fields.map((field) => {
                    return (
                      <ViewField
                        label={field.label}
                        title={selectedOrder[field.key] || '-'}
                        key={field.key}
                      />
                    );
                  })}
                </Box>
              </Box>
            );
          }
          if (item.label === 'Project Details' && selectedOrder) {
            return (
              <Box key={index} className='flex flex-col gap-1'>
                <Box>
                  <StatusBadge title={item.label} type='black' />
                </Box>
                <Box className='flex flex-col gap-1'>
                  {item.fields.map((field) => {
                    return (
                      <ViewField
                        label={field.label}
                        title={selectedOrder[field.key] || '-'}
                        key={field.key}
                      />
                    );
                  })}
                </Box>
              </Box>
            );
          }
          if (item.label === 'Contact Details' && selectedOrder) {
            return (
              <Box key={index} className='flex flex-col gap-1'>
                <Box>
                  <StatusBadge title={item.label} type='black' />
                </Box>
                <Box className='flex flex-col gap-1'>
                  {item.fields.map((field) => {
                    return (
                      <ViewField
                        label={field.label}
                        title={selectedContract[field.key] || '-'}
                        key={field.key}
                      />
                    );
                  })}
                </Box>
              </Box>
            );
          }
          if (item.label === 'Payment Terms' && selectedOrder) {
            return (
              <Box key={index} className='flex flex-col gap-1'>
                <Box>
                  <StatusBadge title={item.label} type='black' />
                </Box>
                <Box className='flex flex-col gap-1'>
                  {item.fields.map((field) => {
                    return (
                      <ViewField
                        label={field.label}
                        title={selectedOrder[field.key] || '-'}
                        key={field.key}
                      />
                    );
                  })}
                </Box>
              </Box>
            );
          }
          if (item.label === 'Branch' && selectedOrder) {
            return (
              <Box key={index} className='flex flex-col gap-1'>
                <Box>
                  <StatusBadge title={item.label} type='black' />
                </Box>
                <Box className='flex flex-col gap-1'>
                  {item.fields.map((field) => {
                    return (
                      <ViewField
                        label={field.label}
                        title={selectedOrder[field.key] || '-'}
                        key={field.key}
                      />
                    );
                  })}
                </Box>
              </Box>
            );
          }
          if (item.label === 'Sales Quotation Details' && selectedOrder) {
            return (
              <Box key={index} className='flex flex-col gap-1'>
                <Box>
                  <StatusBadge title={item.label} type='black' />
                </Box>
                <Box className='flex flex-col gap-1'>
                  {item.fields.map((field) => {
                    return (
                      <ViewField
                        label={field.label}
                        title={selectedOrder[field.key] || '-'}
                        key={field.key}
                      />
                    );
                  })}
                </Box>
              </Box>
            );
          }
          if (item.label === 'Client Details') {
            return (
              <FmStack key={index} spacing={1}>
                <Box>
                  <StatusBadge title={item.label} type='black' />
                  <Box className='flex flex-col gap-1'>
                    {item.fields.map((field) => {
                      return (
                        <ViewField
                          label={field.label}
                          title={
                            selectedContract
                              ? selectedContract[field.key]
                              : selectedOrder
                                ? selectedOrder[field.key] || '-'
                                : '-'
                          }
                          key={field.key}
                        />
                      );
                    })}
                  </Box>
                </Box>
              </FmStack>
            );
          }

          return (
            <Box key={index} className='flex flex-col gap-1'>
              <Box>
                <StatusBadge title={item.label} type='black' />
              </Box>
              <Box className='flex flex-col gap-1'>
                {item.fields.map((field) => {
                  return (
                    <ViewField
                      label={field.label}
                      title={
                        selectedContract
                          ? selectedContract[field.key]
                          : selectedOrder
                            ? selectedOrder[field.key] || '-'
                            : '-'
                      }
                      key={field.key}
                    />
                  );
                })}
              </Box>
            </Box>
          );
        })}
      </FmStack>
    </GlassCard>
  );
};

export default ViewContractDetails;
