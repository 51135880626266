import { createTheme } from '@mui/material/styles';
import { red } from '@mui/material/colors';
import { FontSize } from './FontSize';

const getStoredFont = () => {
  const selectedFont = localStorage.getItem('selectedFontThree') || 'Inter';
  return `${selectedFont},system-ui,BlinkMacSystemFont,Segoe UI,Roboto,Oxygen,Ubuntu,Fira Sans,Droid Sans,Helvetica Neue,sans-serif`;
};

const MainColors = {
  main: '#067E89',
  secondary: '#bf740c',
  info: '#0f73d1',
  border: '#c8c8c8',
  yellow: '#fcfc03',
  bgLight: '#f9f9f9',
  textMain: '#494e50',
  textDark: '#1d2c2f',
  textLight: '#888888',
  white: '#ffffff',
  mainLight: '#eaf4f5',
  lightsm: '#f0f0f0',
  glassBg: 'rgba(255, 255, 255, 0.38)',
  blue: '#1d4ed8',
  blueLight: '#e9f6ff',
  mainLightBase: '#e0fcff',
  bgOverlay: 'rgba(255, 255, 255, 0.50)',
  bgOverlayTwo: 'rgba(185, 199, 221, 0.25)',
  bgOverlayThree: 'rgba(255, 255, 255, 0.55)',
  borderDark: '#CECECE',
  bodyBg: '#f5f5f5',
  sidebarColor: '#fafafa',
  secondaryLight: '#ffebce',
  primaryLight: '#c9e2e4',
  primaryLightTwo: '#e1f4f6',
  tableHead: '#fafafa',
  header: 'linear-gradient(to right, #204f51, #258588)',
  primaryDark: '#15292d',
  tableHover: '#e1eff0',
  tableEven: '#ffffff',
  tableBorder: '#cecece',
  sideBoxes: '#e1e9e7',
  shape: '4px',
};

// Create a theme instance.
const theme = createTheme({
  shape: {
    main: MainColors.shape,
  },
  palette: {
    primary: {
      main: MainColors.main,
      light: MainColors.primaryLight,
      lightTwo: MainColors.primaryLightTwo,
      dark: MainColors.primaryDark,
    },
    secondary: {
      main: MainColors.secondary,
      light: MainColors.secondaryLight,
    },
    error: {
      main: red.A400,
    },
    info: {
      main: MainColors.info,
    },
    yellow: {
      main: MainColors.yellow,
    },
    border: {
      main: MainColors.border,
      dark: MainColors.borderDark,
      glass: 'rgba(255, 255, 255, 0.3)',
      table: MainColors.tableBorder,
    },
    text: {
      main: MainColors.textMain,
      dark: MainColors.textDark,
      light: MainColors.textLight,
      white: MainColors.white,
    },
    background: {
      default: MainColors.white,
      light: MainColors.bgLight,
      white: MainColors.white,
      mainLight: MainColors.mainLight,
      mainLightBase: MainColors.mainLightBase,
      lightsm: MainColors.lightsm,
      glass: MainColors.glassBg,
      overlay: MainColors.bgOverlay,
      overlayTwo: MainColors.bgOverlayTwo,
      blueLight: MainColors.blueLight,
      body: MainColors.bodyBg,
      sidebar: MainColors.sidebarColor,
      overlayThree: MainColors.bgOverlayThree,
      table: MainColors.tableHead,
      header: MainColors.header,
      tableHover: MainColors.tableHover,
      sideBoxes: MainColors.sideBoxes,
      tableEven: MainColors.tableEven,
    },
    shadow: {
      main: '0 1px 10px rgba(0, 0, 0, 0.1)',
      glass: '0 4px 30px rgba(0, 0, 0, 0.1',
    },
  },
  typography: {
    //fontFamily: `system-ui,BlinkMacSystemFont,Segoe UI,Roboto,Oxygen,Ubuntu,Fira Sans,Droid Sans,Helvetica Neue,sans-serif`,
    fontFamily: getStoredFont(),

    allVariants: {
      color: MainColors.textMain,
      fontSize: FontSize.body,
    },
  },

  components: {
    MuiCssBaseline: {
      styleOverrides: {
        body: {
          background: MainColors.bodyBg,
          fontSize: FontSize.body,
          backgroundSize: 'cover',
          letterSpacing: '0.10px',
        },
      },
    },

    MuiButton: {
      defaultProps: {
        disableElevation: true,
      },
      styleOverrides: {
        root: {
          textTransform: 'inherit',
          fontSize: 'inherit',
        },
      },
      variants: [
        {
          props: { variant: 'contained' },
          style: {
            fontWeight: '500',
            borderRadius: MainColors.shape,
            paddingTop: '8px',
            paddingBottom: '8px',
          },
        },
        {
          props: { variant: 'outlined' },
          style: {
            fontWeight: '500',
            borderRadius: MainColors.shape,
            paddingTop: '8px',
            paddingBottom: '8px',
          },
        },
        {
          props: { variant: 'text' },
          style: {
            fontWeight: '500',
            borderRadius: MainColors.shape,
            paddingLeft: '15px',
            paddingRight: '15px',
            color: MainColors.textMain,
          },
        },
      ],
    },

    MuiMenu: {
      styleOverrides: {
        paper: {
          boxShadow: 5,
        },
      },
    },

    MuiListItemIcon: {
      styleOverrides: {
        root: {
          minWidth: '30px !important',
          fontSize: '18px',
          color: 'inherit',
        },
      },
    },

    MuiPopover: {
      styleOverrides: {
        paper: {
          borderRadius: MainColors.shape,
          backgroundColor: MainColors.white,
        },
      },
    },

    MuiMenuItem: {
      styleOverrides: {
        root: {
          fontSize: 'inherit',
          '&:hover': {
            background: MainColors.bgLight,
          },
        },
      },
    },

    MuiTooltip: {
      styleOverrides: {
        tooltip: {
          fontSize: '13px',
          color: '#ffffff',
          backgroundColor: '#000000',
          fontWeight: 500,
          padding: '3px 10px',
        },
        arrow: {
          color: '#000000',
        },
      },
    },

    MuiTextField: {
      variants: [
        {
          props: { variant: 'filled' },

          style: {
            '& .MuiFormLabel-root': {
              fontSize: 'inherit',
              transform: 'translate(8px, 10px) scale(1)',
              opacity: 1,
              '&.MuiInputLabel-shrink': {
                transform: 'translate(8px, 3px) scale(0.85)',
                fontWeight: 400,
                opacity: 0.75,
                '&.Mui-disabled': {
                  color: 'inherit',
                },
              },
              '& .MuiTypography-root': {
                fontSize: 'inherit',
              },
            },

            // table autocomplete

            '&.table-filter-button': {
              '& .MuiFormLabel-root': {
                transform: ' translate(8px, 8px) scale(1)',
                '&.MuiInputLabel-shrink': {
                  transform: 'translate(5px, 1px) scale(0.85)',
                },
              },
              '& .MuiInputBase-root.MuiFilledInput-root': {
                padding: '0 5px',
              },
            },

            '&.MuiTelInput-TextField': {
              '& .MuiInputLabel-shrink': {
                transform: 'translate(51px, 3px) scale(0.85)',
              },

              '& .MuiInputBase-root ': {
                paddingLeft: 0,
                '& .MuiButtonBase-root': {
                  padding: 0,
                  marginLeft: '10px',

                  '& MuiInputBase-input': {
                    paddingLeft: 0,
                  },
                },
              },
            },

            '&.currency-mui-styles': {
              '& .MuiInputLabel-shrink': {
                transform: 'translate(51px, 3px) scale(0.85)',
              },

              '&.removeLabelMui': {
                '& .MuiFormLabel-root': {
                  display: 'none',
                },
                '& .MuiInputBase-input': {
                  paddingTop: '10px',
                  paddingBottom: '10px',
                },
              },

              '& .MuiInputBase-root ': {
                paddingLeft: '10px',
                '& .MuiButtonBase-root': {
                  padding: 0,
                  marginLeft: '10px',

                  '& MuiInputBase-input': {
                    paddingLeft: 0,
                  },
                },
              },
            },

            '& .MuiFormHelperText-root.Mui-error': {
              margin: 0,
              fontSize: '8px',
            },

            '& .MuiInputBase-root': {
              border: '1px solid',
              borderColor: MainColors.borderDark,
              borderRadius: MainColors.shape,
              overflow: 'hidden',
              fontWeight: 500,
              color: MainColors.textDark,
              background: MainColors.white,

              fontSize: 'inherit',
              '&:before, &:after': {
                display: 'none',
              },
              '&:hover, &.Mui-focused': {
                background: MainColors.mainLight,

                borderColor: MainColors.main,
              },

              '& .MuiInputAdornment-root .MuiButtonBase-root .MuiSvgIcon-root':
                {
                  fontSize: '1.2rem',
                },

              '& .MuiInputAdornment-positionStart.MuiInputAdornment-filled': {
                marginTop: '0px !important',
              },

              '&.MuiAutocomplete-inputRoot': {
                paddingTop: '6px',
                paddingLeft: '7px',

                '&.Mui-disabled': {
                  background: MainColors.light,
                  color: 'inherit',
                  //border: 'none',
                  '& .MuiInputBase-input': {
                    background: 'inherit',
                    color: 'inherit',
                    WebkitTextFillColor: 'inherit',
                  },
                  '& .MuiButtonBase-root.Mui-disabled': {
                    display: 'none',
                  },
                },

                '& .MuiInputBase-input': {
                  padding: '11px 0 2px 0',
                },
              },

              '&.MuiInputBase-multiline': {
                padding: '7px',
                paddingTop: '15px',
              },

              '& .MuiInputBase-input': {
                paddingTop: '16px',
                paddingBottom: '4px',
                paddingLeft: '7px',
                paddingRight: '7px',
                transition: 'inherit',
                '&:-webkit-autofill, :-webkit-autofill:hover, :-webkit-autofill:focus':
                  {
                    boxShadow: '0 0 0px 1000px #fff inset !important',
                  },

                '&.MuiInputBase-inputMultiline': {
                  padding: 0,
                },
              },

              '&.MuiFilledInput-root.Mui-disabled': {
                background: MainColors.bodyBg,
                //border: 'none',
                color: 'inherit',
                opacity: 1,
                pointerEvents: 'none',
                '& .MuiInputBase-input': {
                  background: 'inherit',
                  color: 'inherit',
                  WebkitTextFillColor: 'inherit',
                },
              },
            },
          },
        },
        {
          props: { variant: 'outlined' },
          style: {
            '& .MuiFormLabel-root': {
              fontSize: 'inherit',
              transform: 'translate(11px, 10px) scale(1)',
              '&.MuiInputLabel-shrink': {
                transform: 'translate(11px, -6px) scale(0.8)',
                color: MainColors.textLight,
              },
            },
            '& .MuiInputLabel-shrink.MuiFormLabel-root': {
              marginTop: '0px',
            },

            // filter autocomplete
            '&.filter-autocomplete': {
              '& .MuiFormLabel-root': {
                transform: 'translate(10px, 6px) scale(1)',
                '&.MuiInputLabel-shrink': {
                  transform: 'translate(12px, -5px) scale(0.8)',
                },
              },
              '& .MuiInputBase-root': {
                '& .MuiInputBase-input': {
                  padding: '6px 11px',
                },
              },
            },

            // datepicker filter
            '&.filter-date': {
              '& .MuiFormLabel-root': {
                transform: 'translate(10px, 6px) scale(1)',
                '&.MuiInputLabel-shrink': {
                  transform: 'translate(12px, -7px) scale(0.8)',
                },
              },
              '& .MuiInputBase-root': {
                '& .MuiInputBase-input': {
                  padding: '6px 11px',
                },
              },
            },

            '& .MuiAutocomplete-inputRoot ': {
              paddingTop: '1px',
              paddingBottom: '1px',
              paddingLeft: '1px',
              '&.MuiOutlinedInput-root ': {
                paddingTop: '1px',
                paddingBottom: '1px',
                paddingLeft: '1px',
              },
            },

            '& .MuiFormHelperText-root.Mui-error': {
              margin: '0',
              fontSize: '8px',
            },

            '& .MuiInputBase-root': {
              //  background: MainColors.white,
              color: MainColors.textDark,
              fontSize: 'inherit',
              borderRadius: MainColors.shape,
              fontWeight: 500,
              '&:hover, &.Mui-focused': {
                //  backgroundColor: MainColors.white,
              },

              '&.Mui-focused': {
                '& .MuiOutlinedInput-notchedOutline': {
                  borderWidth: '1px',
                  borderColor: MainColors.primary,
                },
              },

              '& .MuiOutlinedInput-notchedOutline': {
                borderColor: MainColors.border,
              },

              '& .MuiInputBase-input': {
                padding: '10px 11px',
                '&.MuiInputBase-inputMultiline': {
                  padding: 0,
                },
              },
            },
          },
        },
        {
          props: { variant: 'standard' },
          style: {
            '& .MuiInputBase-root': {
              fontSize: 'inherit',
              fontWeight: 500,
            },
          },
        },
      ],
    },

    MuiChip: {
      styleOverrides: {
        root: {
          fontSize: 'inherit',
          color: 'inherit',
          '& .MuiSvgIcon-root': {
            color: 'inherit',
            opacity: '0.5',
            '&:hover': {
              opacity: '1',
              color: 'inherit',
            },
          },
        },
      },
    },

    MuiTabs: {
      styleOverrides: {
        root: {
          '& .MuiButtonBase-root': {
            fontSize: 'inherit',
            fontWeight: 500,
            textTransform: 'inherit',
            flexDirection: 'row',
            justifyContent: 'flex-start',
            borderRadius: MainColors.shape,
            minHeight: 35,
            // padding: "5px 10px",
            color: MainColors.textMain,
            // marginBottom: "5px",
            '& svg': {
              marginBottom: 0,
              marginRight: '10px',
              fontSize: '20px',
            },
          },
          '& .MuiTabPanel-root': {
            padding: 0,
          },
          '& .Mui-selected': {
            // background: MainColors.main,
            // color: `${MainColors.white}!important`,
          },
        },
      },
    },

    MuiFormControlLabel: {
      styleOverrides: {
        root: {
          margin: 0,
        },
        label: {
          fontSize: 'inherit',
        },
      },
    },

    MuiAutocomplete: {
      styleOverrides: {
        root: {
          fontSize: 'inherit',
        },
        paper: {
          fontSize: 'inherit',
        },
        listbox: {
          '& .MuiAutocomplete-option.Mui-focused': {
            backgroundColor: MainColors.bgLight,
          },
        },
      },
    },

    MuiBackdrop: {
      styleOverrides: {
        root: {
          background: 'rgba(0, 0, 0, 0.50)',
        },
        invisible: {
          background: 'inherit',
          backdropFilter: 'blur(0px)',
        },
      },
    },
  },
});

export default theme;
