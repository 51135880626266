import React from 'react';
import GlassCard from './../../../../../components/small/GlassCard.tsx';
import { Grid, Typography, Box } from '@mui/material';
import { Icon } from '@iconify/react/dist/iconify.js';
import {
  StatusBadge,
  ViewField,
} from '../../../../../components/_form/FormElements.jsx';
import FmStack from '../../../../../components/layout/FmStack.tsx';

const IconsListing = [
  {
    icon: <Icon icon='fluent:building-retail-20-regular' />,
    label: 'Project Details',
    fields: [
      { key: 'projectCode', label: 'Code' },
      { key: 'projectName', label: 'Name' },
    ],
  },
  {
    icon: <Icon icon='fluent:building-people-20-regular' />,
    label: 'Client Details',
    fields: [{ key: 'clientName', label: 'Name' }],
  },
  {
    icon: <Icon icon='fluent:person-20-regular' />,
    label: 'Contact Details',
    fields: [
      { key: 'contactPerson', label: 'Name' },
      { key: 'contactNumber', label: 'Mobile' },
      { key: 'contactEmailId', label: 'Email' },
    ],
  },
  {
    icon: <Icon icon='fluent:location-20-regular' />,
    label: 'Location',
    fields: [{ key: 'locationName', label: 'Name' }],
  },
  {
    icon: <Icon icon='fluent:payment-20-regular' />,
    label: 'Payment Terms',
    fields: [{ key: 'paymentTerms', label: 'Terms' }],
  },
];

const ViewContractDetails = (props) => {
  const { selectedContract } = props;
  return (
    <GlassCard
      className='p-2 h-full absolute left-0 top-0 overflow-auto w-full'
      bgDark
    >
      <FmStack spacing={1}>
        {IconsListing.map((item, index) => {
          if (item.label === 'Location') {
            return null; // Skip rendering Location section separately
          }

          if (item.label === 'Client Details') {
            return (
              <FmStack key={index} spacing={1}>
                <Box>
                  <StatusBadge title={item.label} type='black' />
                  <Box className='flex flex-col gap-1'>
                    {item.fields.map((field) => (
                      <ViewField
                        label={field.label}
                        title={selectedContract[field.key] || '-'}
                        key={field.key}
                      />
                    ))}
                  </Box>
                </Box>
                <Box>
                  <StatusBadge title={'Location'} type='black' />

                  {IconsListing.find((i) => i.label === 'Location')?.fields.map(
                    (field) => (
                      <ViewField
                        label={field.label}
                        title={selectedContract[field.key] || '-'}
                        key={field.key}
                      />
                    )
                  )}
                </Box>
              </FmStack>
            );
          }

          return (
            <Box key={index} className='flex flex-col gap-1'>
              <Box>
                <StatusBadge title={item.label} type='black' />
              </Box>
              <Box className='flex flex-col gap-1'>
                {item.fields.map((field) => (
                  <ViewField
                    label={field.label}
                    title={selectedContract[field.key] || '-'}
                    key={field.key}
                  />
                ))}
              </Box>
            </Box>
          );
        })}
      </FmStack>
    </GlassCard>
  );
};

export default ViewContractDetails;
