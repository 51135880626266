import React from 'react';
import CreateInventoryTransferFields from './CreateInventoryTransferFields.tsx';
import DashboardLayout from '../../../../../components/DashboardLayout.tsx';
import { BacktoList } from '../../../../../components/small/BackToButton.tsx';

const CreateInvetoryTransfer = (transferId) => {

    return (
        <DashboardLayout
            title={transferId ? 'Edit Inventory Transfer' : 'Create Inventory Transfer'}
            actionButtons={<BacktoList />}
            titleReverse
        >
            <CreateInventoryTransferFields />
        </DashboardLayout>
    );
};

export default CreateInvetoryTransfer;
