import React, { useState } from 'react';
import DashboardLayout from '../../../../../components/DashboardLayout.tsx';
import SalesRetentionItemListTable from './SalesRetentionItemListTable.tsx';
import AddViewSalesRetention from './AddViewSalesRetention.tsx';
import useTableLogic from '../../../../../components/helpers/MRTUseTableLogic.tsx';
import { Box } from '@mui/material';
import { navigate } from 'react-big-calendar/lib/utils/constants.js';
import { CreateButton } from '../../../../../components/small/Buttons.jsx';
import { useNavigate } from 'react-router-dom';
const SalesRetentionItem = (props) => {
  const { salesRententionId } = props;
  const {
    rows,
    rowCount,
    isLoading,
    pagination,
    sorting,
    columnFilters,
    globalFilter,
    tableRecordCounts,
    setPagination,
    setSorting,
    setColumnFilters,
    setGlobalFilter,
    fetchData,
    setSearchKeyword,
    searchKeyword,
  } = useTableLogic('salesretentionitems');
  const [editingRowId, setEditingRowId] = useState(null);
  const [editingRowData, setEditingRowData] = useState(null);
  const navigate = useNavigate();


  return (
    <DashboardLayout
      title='Sales Retention Item'
      actionButtons={
        <>
          <Box className='flex flex-row items-center whitespace-nowrap gap-3'>
            <CreateButton
              name='Create'
              onClick={() =>
                navigate(
                  '/financial/accounts-receivable/sales-retention/create'
                )
              }
            />
          </Box>
        </>
      }
    >
      <AddViewSalesRetention
        fetchData={fetchData}
        salesRententionId={salesRententionId}
        setEditingRowId={setEditingRowId}
        setEditingRowData={setEditingRowData}
        editingRowId={editingRowId}
        editingRowData={editingRowData}
      />
      <SalesRetentionItemListTable
        rows={rows}
        tableRecordCounts={tableRecordCounts}
        pagination={pagination}
        sorting={sorting}
        fetchData={fetchData}
        setPagination={setPagination}
        setSorting={setSorting}
        columnFilters={columnFilters}
        globalFilter={globalFilter}
        setColumnFilters={setColumnFilters}
        setGlobalFilter={setGlobalFilter}
        isLoading={isLoading}
        setSearchKeyword={setSearchKeyword}
        searchKeyword={searchKeyword}
        setEditingRowId={setEditingRowId}
        setEditingRowData={setEditingRowData}
        editingRowId={editingRowId}
        editingRowData={editingRowData}
      />
    </DashboardLayout>
  );
};

export default SalesRetentionItem;
