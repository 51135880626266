import React from 'react';
import DashboardLayout from '../../../../components/DashboardLayout.tsx';
import GlassCard from '../../../../components/small/GlassCard.tsx';
import { useNavigate, useParams } from 'react-router-dom';
import CommonView from '../../../../components/helpers/CommonView.jsx';
import DynamicViewFields from '../../../../components/helpers/DynamicViewFields.jsx';
import { CreateButton } from '../../../../components/small/Buttons.jsx';
import ActionButtons from '../../../../components/_form/ActionButtons.tsx';

const ViewAdvertisement = () => {
  const navigate = useNavigate();
  const { id } = useParams();

  const excludeKeys = [
    'vehicleAdvertisementId',
    'vehicleId',
    'createdDate',
    'updatedDate',
    'status',
  ];

  return (
    <DashboardLayout
      title='View Advertisement'
      actionButtons={
        <CreateButton
          name='Create'
          onClick={() => navigate('/admin/vehicles/advertisement/create')}
        />
      }
    >
      <GlassCard className='p-5'>
        <CommonView
          url='vehicleadvertisements'
          id={id}
          excludeKeys={excludeKeys}
          renderFields={(data, fields) => (
            <DynamicViewFields data={data} fields={fields} />
          )}
        />
        <ActionButtons
          cancelLoading={false}
          cancelText='Go Back'
          submitText='Edit'
          onSubmit={() => navigate(`/admin/vehicles/advertisement/edit/${id}`)}
          onCancel={() => navigate('/admin/vehicles/advertisement')}
        />
      </GlassCard>
    </DashboardLayout>
  );
};

export default ViewAdvertisement;
