import React from 'react';
import { GridToolbarColumnsButton, GridToolbarExport } from '@mui/x-data-grid';
import { Box, IconButton, Tooltip } from '@mui/material';
import { Icon } from '@iconify/react/dist/iconify.js';

const styleSx = {
  padding: '0px',
  minWidth: 'inherit',
  width: '32px',
  height: '32px',
  borderRadius: '35px',
  fontSize: '1.3rem',
  '& .MuiButton-startIcon': {
    margin: '0px',
    '& svg': {
      fontSize: '1.3rem',
    },
  },
};

interface DataTableToolbarButtonsProps {
  disableColumn?: boolean;
  disableExport?: boolean;
  disablePrintCSV?: boolean;
  handleExport?: () => void;
  handleExcelExport?: () => void;
}
const DataTableToolbarButtons = (props: DataTableToolbarButtonsProps) => {
  const {
    disableColumn,
    disableExport,
    disablePrintCSV,
    handleExport,
    handleExcelExport,
  } = props;
  return (
    <Box className='flex flex-row gap-2'>
      {!disableColumn && <GridToolbarColumnsButton sx={styleSx} />}
      {!disablePrintCSV && (
        <GridToolbarExport onClick={handleExport} sx={styleSx} />
      )}
      {!disableExport && (
        <>
          <Tooltip title='Download Excel' arrow>
            <IconButton sx={styleSx} onClick={handleExcelExport}>
              <Icon icon='file-icons:microsoft-excel' />
            </IconButton>
          </Tooltip>
        </>
      )}
    </Box>
  );
};

export default DataTableToolbarButtons;
