import React from 'react';
import GlassCard from '../../../components/small/GlassCard.tsx';
import { Box } from '@mui/material';
import FmMRTDataTable from '../../../components/FmMRTDataTable.tsx';
import useTableLogic from '../../../components/helpers/MRTUseTableLogic.tsx';
import Icons from '../../../utils/Icon.jsx';
import TableActionButtons from '../../../components/_form/TableActionButtons.jsx';
import FmViewDateField from '../../../components/_mui/FmViewDateField.tsx';
import StatusNewBadge from '../../../components/_form/StatusNewBadge.tsx';
import { useNavigate } from 'react-router-dom';
import Crud_Service from '../../../apis/CrudService.jsx';
import { toast } from 'react-toastify';
import TableAvatar from '../../../components/_form/TableAvatar.jsx';
import {
  ClientName,
  CustomDateCell,
} from '../../../components/table/MRTCommonColumns.tsx';

const defaultSizes = [120, 180, 60];
const BoldBox = ({ title }) => {
  return <Box>{title}</Box>;
};
const CollectionAgentList = () => {
  const navigate = useNavigate();
  const crud = new Crud_Service();
  const {
    rows,
    isLoading,
    pagination,
    sorting,
    columnFilters,
    globalFilter,
    tableRecordCounts,
    setPagination,
    setSorting,
    setColumnFilters,
    setGlobalFilter,
    fetchData,
    setSearchKeyword,
    searchKeyword,
  } = useTableLogic(`invoicecollectionagents`);
  const handleEdit = (id) => {
    navigate(`edit/${id}`);
  };

  const handleDelete = async (props) => {
    await crud.remove(
      'invoicecollectionagents',
      props?.invoiceCollectionAgentId,
      (_err, res) => {
        if (res?.status === 204) {
          toast.success('Collection Agent Deleted Successfully');
          fetchData();
        } else {
        }
      }
    );
  };
  const ActionData = [
    {
      name: 'Edit',
      icon: Icons.edit,
      onClick: (props) => {
        handleEdit(props.invoiceCollectionAgentId);
      },
    },
    {
      name: 'Delete',
      icon: Icons.delete,
      onClick: (props) => {
        handleDelete(props);
      },
      danger: true,
    },
  ];

  const columns = [
    {
      header: 'Actions',
      size: 100,
      Cell: ({ row }) => (
        <Box className='flex gap-2'>
          <TableActionButtons
            Actions={ActionData?.map((action) => ({
              ...action,
              onClick: () => action.onClick(row?.original),
            }))}
          />
        </Box>
      ),
    },
    {
      accessorKey: 'salesInvoiceId',
      header: 'Sales Invoice',
      size: 130,
      Cell: ({ cell }) => {
        const order: any = rows?.find(
          (row: any) =>
            row?.invoiceCollectionAgentId ===
            cell?.row?.original?.invoiceCollectionAgentId
        );
        return <BoldBox title={order?.salesInvoiceCode} />;
      },
    },

    {
      accessorKey: 'acknowledgmentStatusName',
      header: 'Status',
      size: defaultSizes[0],
      Cell: ({ cell }) => {
        return (
          <StatusNewBadge
            title={cell?.row?.original?.acknowledgmentStatusName}
            type={cell?.row?.original?.acknowledgmentStatusName}
          />
        );
      },
    },

    {
      accessorKey: 'employeeId',
      header: 'Name',
      size: 320,
      Cell: ({ cell }) => {
        const order: any = rows?.find(
          (row: any) =>
            row?.invoiceCollectionAgentId ===
            cell?.row?.original?.invoiceCollectionAgentId
        );
        return (
          <TableAvatar
            name={order?.employeeName}
            desc={order?.employeeCode && `Code : ${order?.employeeCode}`}
          />
        );
      },
    },

    {
      accessorKey: 'invoiceDate',
      header: 'Invoice Date',
      size: defaultSizes[0],
      customDate: true,
      Cell: ({ cell }) => CustomDateCell(cell?.row?.original?.invoiceDate),
    },

    {
      accessorKey: 'clientId',
      header: 'Client',
      size: 200,
      Cell: ({ cell }) => {
        const order: any = rows?.find(
          (row: any) =>
            row?.invoiceCollectionAgentId ===
            cell?.row?.original?.invoiceCollectionAgentId
        );
        return (
          <TableAvatar
            name={order?.clientName}
            desc={order?.clientCode && `Code : ${order?.clientCode}`}
            removeImage
          />
        );
      },
    },
    {
      accessorKey: 'deliveryDate',
      header: 'Received Date',
      size: defaultSizes[0],
      customDate: true,
      Cell: ({ cell }) => CustomDateCell(cell?.row?.original?.deliveryDate),
    },
    {
      accessorKey: 'acknowledgeDate',
      header: 'Handover To Client',
      size: defaultSizes[0],
      customDate: true,
      Cell: ({ cell }) => CustomDateCell(cell?.row?.original?.acknowledgeDate),
    },
    {
      accessorKey: 'remarks',
      header: 'Remarks',
      size: defaultSizes[0],
      Cell: ({ cell }) => {
        const order: any = rows?.find(
          (row: any) =>
            row?.invoiceCollectionAgentId ===
            cell?.row?.original?.invoiceCollectionAgentId
        );
        return <BoldBox title={order?.remarks} />;
      },
    },
  ];
  return (
    <GlassCard className='h-full'>
      <FmMRTDataTable
        columns={columns}
        rows={rows}
        rowCount={tableRecordCounts}
        pagination={pagination}
        sorting={sorting}
        columnFilters={columnFilters}
        globalFilter={globalFilter}
        setPagination={setPagination}
        setSorting={setSorting}
        setColumnFilters={setColumnFilters}
        setGlobalFilter={setGlobalFilter}
        isLoading={isLoading}
        setSearchKeyword={setSearchKeyword}
        searchKeyword={searchKeyword}
        fetchData={fetchData}
        muiTableContainerProps={{
          sx: {
            maxHeight: '100%',
            flex: 1,
          },
        }}
        handleRowDoubleClick={(ev) =>
          handleEdit(ev.original.invoiceCollectionAgentId)
        }
        showSerialNumber={true}
      />
    </GlassCard>
  );
};

export default CollectionAgentList;
