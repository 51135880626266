import React from 'react';
import { Box } from '@mui/material';

interface GlassCardProps {
  className?: string;
  children?: React.ReactNode;
  bgLight?: boolean;
  bgWhite?: boolean;
  restProps?: any;
  nakedTable?: boolean;
  bgDark?: boolean;
}

const GlassCard = (props: GlassCardProps) => {
  const {
    children,
    className,
    bgLight,
    bgWhite,
    nakedTable,
    bgDark,
    ...restProps
  } = props;
  return (
    <Box
      className={className}
      sx={{
        borderRadius: (theme) => theme.shape.main,
        bgcolor: bgLight
          ? 'background.light'
          : bgWhite
          ? 'background.white'
          : nakedTable
          ? 'inherit'
          : bgDark
          ? 'background.sideBoxes'
          : 'background.white',
        border: nakedTable ? 'none' : '1px solid',
        borderColor: 'border.main',
        overflow: 'hidden',
        color: 'inherit',
        //  boxShadow: '0px 1px 3px 1px rgb(0 0 0 / 4%)',
      }}
      {...restProps}
    >
      {children}
    </Box>
  );
};

export default GlassCard;
