import React, { useState } from 'react';
import { Box, IconButton, Collapse } from '@mui/material';
import { Icon } from '@iconify/react/dist/iconify.js';
import GlassCard from './GlassCard.tsx';

interface GlassCardProps {
  className?: string;
  title?: string;
  children?: React.ReactNode;
  collapsed?: boolean;
}

const GlassCardCollapse = (props: GlassCardProps) => {
  const { children, title, className, collapsed } = props;
  const [collapse, setCollapse] = useState(collapsed || false);
  return (
    <GlassCard className={className}>
      <Box
        className='flex items-center justify-between cursor-pointer gap-4 py-1 px-3'
        sx={{
          borderBottom: !collapse && '1px solid',
          borderColor: !collapse && 'border.main',
        }}
        onClick={() => setCollapse(!collapse)}
      >
        <Box className='font-semibold'>{title}</Box>
        <IconButton className='p-0 text-3xl'>
          {collapse ? (
            <Icon icon='material-symbols:keyboard-arrow-down' />
          ) : (
            <Icon icon='material-symbols:keyboard-arrow-up' />
          )}
        </IconButton>
      </Box>
      <Collapse in={!collapse}>
        <Box className='p-3'>{children}</Box>
      </Collapse>
    </GlassCard>
  );
};

export default GlassCardCollapse;
