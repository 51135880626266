import React, { useState, useEffect, useRef } from 'react';
import Box from '@mui/material/Box';
import MenuItem from '@mui/material/MenuItem';
import Button from '@mui/material/Button';
import Menu from '@mui/material/Menu';
import { useDispatch } from 'react-redux';
import { useNavigate } from 'react-router-dom';
import { setActiveTab } from '../../store/tabsSlice.ts';
import { Nav } from '../../utils';
import { Icon } from '@iconify/react/dist/iconify.js';
import HeaderMenu from './HeaderMenu.jsx';

const colors = [
  '#ff6f61', // Coral (Red + Orange)
  '#4caf50', // Leaf Green
  '#42a5f5', // Sky Blue
  '#ff8f00', // Amber Orange
  '#8e24aa', // Vibrant Purple
  '#0acc9b', // Aqua Green
  '#d81b60', // Cerise Pink
  '#7986cb', // Indigo Blue
  '#00acc1', // Cyan Blue
  '#f4511e', // Red-Orange
  '#7cb342', // Chartreuse Green
  '#c2185b', // Magenta Red
  '#ff6f61', // Coral (Red + Orange)
  '#4caf50', // Leaf Green
  '#42a5f5', // Sky Blue
  '#ff8f00', // Amber Orange
  '#8e24aa', // Vibrant Purple
  '#0acc9b', // Aqua Green
  '#d81b60', // Cerise Pink
  '#7986cb', // Indigo Blue
  '#00acc1', // Cyan Blue
  '#f4511e', // Red-Orange
  '#7cb342', // Chartreuse Green
  '#c2185b', // Magenta Red
];

const HeaderAppDropdownList = () => {
  const [activeIndex, setActiveIndex] = useState(
    Number(localStorage.getItem('menu')) || 0
  );
  const [visibleMenu, setVisibleMenu] = useState([]);
  const [overflowMenu, setOverflowMenu] = useState([]);
  const [moreAnchorEl, setMoreAnchorEl] = useState(null);
  const menuContainerRef = useRef(null);
  const dispatch = useDispatch();
  const navigate = useNavigate();


  const typeMap = [
    { key: 'HRMS', value: 'hrms' },
    { key: 'CRM', value: 'crm' },
    { key: 'ERP', value: 'erp' },
    { key: 'PR', value: 'pr' },
    { key: 'PRESALES', value: 'presales' },
    { key: 'CAFM', value: 'cafm' },
    { key: 'PROCUREMENT', value: 'procurement' },
    { key: 'FINANCIAL', value: 'financial' },
    { key: 'BUDGETING', value: 'budgeting' },
    { key: 'HSE', value: 'hse' },
    { key: 'ADMIN', value: 'admin' },
    { key: 'PROJECT-DIVISION', value: 'project-division' },
    { key: 'FM-DIVISION', value: 'fm-division' },
    { key: 'ELV', value: 'elv' },
    { key: 'QAQC', value: 'qaqc' },
    { key: 'REPORTS', value: 'reports' },
  ];

  // Set active menu in local storage
  const setActiveMenu = (tab) => {
    setActiveIndex(tab);
    localStorage.setItem('menu', tab);
    dispatch(setActiveTab(tab));
  };

  const handleMenuClick = (index) => {
    setActiveMenu(index);
    navigate(`/${typeMap[index].value}/dashboard`);
  };

  const handleMoreClick = (event) => {
    setMoreAnchorEl(event.currentTarget);
  };

  const handleMoreClose = () => {
    setMoreAnchorEl(null);
  };

  const checkOverflow = () => {
    if (!menuContainerRef.current) return;

    const containerWidth = menuContainerRef.current.offsetWidth - 100;
    let currentWidth = 0;
    const visible = [];
    const overflow = [];

    Nav.forEach((menu) => {
      currentWidth += 100; // Approximate width for each menu item
      if (currentWidth < containerWidth) {
        visible.push(menu);
      } else {
        overflow.push(menu);
      }
    });

    setVisibleMenu(visible);
    setOverflowMenu(overflow);
  };

  useEffect(() => {
    checkOverflow();
    window.addEventListener('resize', checkOverflow);
    return () => window.removeEventListener('resize', checkOverflow);
  }, []);

  const isOverflowActive = overflowMenu.some(
    (menu) => Nav.indexOf(menu) === activeIndex
  );

  return (
    <Box
      className='flex justify-between items-center gap-5'
      sx={{
        borderBottom: '1px solid',
        borderTop: '1px solid',
        borderColor: 'border.main',
        bgcolor: 'background.white',
        paddingRight: '10px',
      }}
    >
      <Box
        ref={menuContainerRef}
        className='flex flex-1 pe-4'
        sx={{ borderRight: '1px solid', borderColor: 'border.main' }}
      >
        <Box className='flex justify-between items-center w-full'>
          <Box component='ul' className='flex flex-wrap flex-1'>
            {visibleMenu.map(
              (menu, i) =>
                Object.keys(menu)?.length > 0 && (
                  <MenuItem
                    key={i}
                    onClick={() => handleMenuClick(i)}
                    sx={{
                      color: i === activeIndex ? 'primary.main' : 'text.main',
                      fontSize: 'inherit',
                      py: 1,
                      fontWeight: 500,
                      borderRadius: '0px',
                      gap: '5px',
                      borderBottom: '2px solid',
                      borderColor:
                        i === activeIndex ? 'primary.main' : 'transparent',
                      px: 2,
                      '&:hover': {
                        bgcolor: 'background.overlayTwo',
                        borderColor: 'border.main',
                      },
                    }}
                  >
                    <Box
                      sx={{
                        fontSize: '20px',
                        width: '22px',
                        height: '22px',
                        borderRadius: '5px',
                        color: i === activeIndex ? 'primary.main' : colors[i],
                      }}
                    >
                      {menu.icon}
                    </Box>
                    <Box className='uppercase font-semibold'>{menu.name}</Box>
                  </MenuItem>
                )
            )}
          </Box>

          {overflowMenu.length > 0 && (
            <Button
              onClick={handleMoreClick}
              sx={{
                color: isOverflowActive ? 'primary.main' : 'text.dark',
                fontWeight: 700,
                padding: '3px 8px',
                bgcolor: isOverflowActive ? 'background.mainLight' : 'inherit',
                '&:hover': {
                  bgcolor: 'background.light',
                },
              }}
              className='uppercase'
              startIcon={
                Boolean(moreAnchorEl) ? (
                  <Icon icon='mdi:keyboard-arrow-up' />
                ) : (
                  <Icon icon='mdi:keyboard-arrow-down' />
                )
              }
            >
              More
            </Button>
          )}
        </Box>

        <Menu
          anchorEl={moreAnchorEl}
          open={Boolean(moreAnchorEl)}
          onClose={handleMoreClose}
          anchorOrigin={{
            vertical: 'bottom',
            horizontal: 'right',
          }}
          transformOrigin={{
            vertical: 'top',
            horizontal: 'right',
          }}
          PaperProps={{
            sx: {
              boxShadow: 5,
              border: '1px solid',
              borderRadius: '5px',
              borderColor: 'border.main',
              minWidth: '200px',
              mt: '5px',
            },
          }}
        >
          {overflowMenu.map((menu, i) => (
            <MenuItem
              key={i}
              onClick={() => {
                handleMenuClick(Nav.indexOf(menu));
                handleMoreClose();
              }}
              sx={{
                color:
                  Nav.indexOf(menu) === activeIndex
                    ? 'primary.main'
                    : 'text.main',
                fontSize: 'inherit',
                py: 1,
                borderLeft: '4px solid',
                borderColor:
                  Nav.indexOf(menu) === activeIndex
                    ? 'primary.main'
                    : 'transparent',
                fontWeight: 500,
                borderRadius: '0px',
                gap: '15px',
                px: 2,

                '&:hover': {
                  bgcolor: 'background.light',
                  color: 'primary.main',
                },
                bgcolor:
                  Nav.indexOf(menu) === activeIndex && 'background.mainLight',
              }}
            >
              <Box
                sx={{
                  fontSize: '20px',
                  width: '22px',
                  height: '22px',
                  color:
                    Nav.indexOf(menu) === activeIndex
                      ? 'primary.main'
                      : colors[i],
                }}
              >
                {menu.icon}
              </Box>
              <Box className='uppercase font-semibold'>{menu.name}</Box>
            </MenuItem>
          ))}
        </Menu>
      </Box>
      <HeaderMenu headerStyle />
    </Box>
  );
};

export default HeaderAppDropdownList;
