import React from 'react';
import { Box } from '@mui/material';

const ViewTableTwoDate = ({ data }) => {
  return (
    <div className='flex justify-between gap-2 items-center w-full'>
      {data?.map((item, index) => (
        <div key={index}>
          <div>
            <div>{item?.name}</div>
            <Box color={item?.red ? 'error.main' : 'primary.main'}>
              {item?.date}
            </Box>
          </div>
        </div>
      ))}
    </div>
  );
};

export default ViewTableTwoDate;
