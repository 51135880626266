import { Box, Grid } from '@mui/material';
import dayjs from 'dayjs';
import React, { useEffect, useState } from 'react';
import { useForm } from 'react-hook-form';
import { useTranslation } from 'react-i18next';
import { useNavigate, useParams } from 'react-router-dom';
import { toast } from 'react-toastify';
import Crud_Service from '../../../../../apis/CrudService.jsx';
import FmSearchableSelect from '../../../../../components/_mui/FmSearchableSelect.tsx';
import FmTextField from '../../../../../components/_mui/FmTextField.tsx';
import DashboardLayout from '../../../../../components/DashboardLayout.tsx';
import useLoader from '../../../../../components/helpers/UseLoader.tsx';
import GlassCardCollapse from '../../../../../components/small/GlassCardCollapse.tsx';
import FmStack from '../../../../../components/layout/FmStack.tsx';
import FmDatePicker from '../../../../../components/_mui/FmDatePicker.tsx';
import FmFileInput from '../../../../../components/_mui/FmFileInput.tsx';
import ActionButtons from '../../../../../components/_form/ActionButtons.tsx';
import ARReceiptsItemList from './ARReceiptItemList.tsx';
import CommonLoader from '../../../../../components/page/CommonLoader.jsx';
import BackToButton from '../../../../../components/small/BackToButton.jsx';
import FmCurrencyTextField from '../../../../../components/_mui/FmCurrencyTextField.tsx';

const EditARReceipts = () => {
  const navigate = useNavigate();
  const crud = new Crud_Service();
  const [loading, setLoading] = useState(false);
  const GridSize = 2.4;
  const { id } = useParams();
  const { handleSubmit, control, setValue, reset } = useForm({});
  const { editLoading, stopEditLoading, startEditLoading } = useLoader();
  const { t } = useTranslation();
  const [editData, setEditData] = useState(null);
  const [uploadedfileData, setUploadedfileData] = useState(null);
  const [uploadLoading, setUploadLoading] = useState(false);

  useEffect(() => {
    getReceipts();
  }, []);

  const getReceipts = async () => {
    setLoading(true);
    await crud.getSingle('financialtransactionreceipts', id, (_err, res) => {
      if (res?.status === 200) {
        Object.entries({
          ...res?.data,
        }).forEach(([key, value]) => {
          setValue(key, value);
        });
        setEditData(res?.data);
        setLoading(false);
      } else {
        setLoading(false);
      }
    });
  };

  const handleFileUpload = async (e) => {
    const file = e.target.files[0];
    setUploadLoading(true);
    if (file) {
      const fileFormData = new FormData();
      fileFormData.append('file', file);
      fileFormData.append('fileTypeId', 64);

      await crud.create(`/files/upload`, fileFormData, (err, res) => {
        if (res?.status === 200) {
          setUploadLoading(false);
          setUploadedfileData(res?.data);
        } else {
          setUploadLoading(false);
        }
      });
    }
  };

  const handleEditClients = async (values) => {
    startEditLoading();

    const combinedData = {
      ...values,
      receivedDate: dayjs(values?.receivedDate).format('YYYY-MM-DD'),
      maturityDate: dayjs(values?.maturityDate).format('YYYY-MM-DD'),
      fileId: uploadedfileData?.fileId || editData?.fileId,
    };

    await crud.update(
      `financialtransactionreceipts`,
      id,
      combinedData,
      (_err, res) => {
        if (res?.status === 200) {
          toast.success('Receipt Updated Successfully');
          navigate(-1);
          stopEditLoading();
        } else {
          stopEditLoading();
        }
      }
    );
  };

  return (
    <DashboardLayout
      title='Edit Receipts'
      actionButtons={
        <BackToButton
          title={'Back to List'}
          onClick={() => navigate(-1)}
          className='p-0'
        />
      }
      titleReverse
    >
      <Box className='flex flex-col  gap-6'>
        <GlassCardCollapse title='Edit Receipts'>
          {loading ? (
            <CommonLoader />
          ) : (
            <Box component='form' onSubmit={handleSubmit(handleEditClients)}>
              <FmStack spacing={1.5}>
                <Box>
                  <Grid container spacing={1.5}>
                    <Grid item md={GridSize * 2}>
                      <FmSearchableSelect
                        name='companyId'
                        control={control}
                        apiUrl='companies'
                        valueField='companyId'
                        headerField={['Company Code', 'Company Name']}
                        labelField={['companyCode', 'companyName']}
                        showField={['companyName']}
                        label='Company'
                        required
                        defaultValue={{
                          companyId: editData?.companyId,
                          companyName: editData?.companyName,
                        }}
                      />
                    </Grid>
                    <Grid item md={GridSize * 2}>
                      <FmSearchableSelect
                        name='clientId'
                        control={control}
                        apiUrl='clients'
                        valueField='clientId'
                        headerField={['Client Code', 'Client Name']}
                        labelField={['clientCode', 'clientName']}
                        showField={['clientCode', 'clientName']}
                        label='Client'
                        required
                        defaultValue={{
                          clientId: editData?.clientId,
                          clientName: editData?.clientName,
                        }}
                      />
                    </Grid>
                  </Grid>
                </Box>

                <Box>
                  <Grid container spacing={1.5}>
                    <Grid item md={GridSize}>
                      <FmSearchableSelect
                        name='paymentModeId'
                        control={control}
                        apiUrl='paymenttypes'
                        valueField='paymentTypeId'
                        headerField={['Payment Type']}
                        labelField={['paymentTypeName']}
                        showField={['paymentTypeName']}
                        label='Payment Mode'
                        required
                        defaultValue={{
                          paymentTypeId: editData?.paymentModeId,
                          paymentTypeName: editData?.paymentModeName,
                        }}
                      />
                    </Grid>
                    <Grid item md={GridSize * 2}>
                      <FmSearchableSelect
                        name='bankId'
                        control={control}
                        apiUrl='banks'
                        valueField='bankId'
                        headerField={['Bank Code', 'Bank Name']}
                        labelField={['bankCode', 'bankName']}
                        showField={['bankName']}
                        label='Bank'
                        required
                        defaultValue={{
                          bankId: editData?.bankId,
                          bankName: editData?.bankName,
                        }}
                      />
                    </Grid>
                    <Grid item md={GridSize}>
                      <FmDatePicker
                        name='receivedDate'
                        label='Received Date'
                        control={control}
                        required
                      />
                    </Grid>

                    <Grid item md={GridSize}>
                      <FmDatePicker
                        name='maturityDate'
                        label='Maturity Date'
                        control={control}
                        required
                      />
                    </Grid>

                    <Grid item md={GridSize}>
                      <FmTextField
                        name='chequeNo'
                        control={control}
                        label='Cheque / Transaction No'
                        required
                      />
                    </Grid>

                    <Grid item md={GridSize}>
                      <FmSearchableSelect
                        name='currencyId'
                        control={control}
                        apiUrl='currencies'
                        valueField='currencyId'
                        headerField={['Currency Code', 'Currency Name']}
                        labelField={['currencyCode', 'currencyName']}
                        showField={['currencyName']}
                        label='Currency'
                        required
                        defaultValue={{
                          currencyId: editData?.currencyId,
                          currencyName: editData?.currencyName,
                        }}
                      />
                    </Grid>

                    <Grid item md={GridSize}>
                      <FmCurrencyTextField
                        name='totalAmount'
                        label='Total Amount'
                        control={control}
                        required
                      />
                    </Grid>
                  </Grid>
                </Box>

                <Box>
                  <Grid container spacing={1.5}>
                    <Grid item md={GridSize * 2}>
                      <FmTextField
                        label='Remarks'
                        name='remarks'
                        control={control}
                        multiline={true}
                        rows={2}
                      />
                    </Grid>

                    <Grid item md={GridSize}>
                      <FmFileInput
                        name='fileId'
                        documentName={
                          uploadedfileData?.originalFileName ||
                          editData?.fileName
                        }
                        onChange={handleFileUpload}
                        loading={uploadLoading}
                      />
                    </Grid>
                  </Grid>
                  <ActionButtons
                    onSubmit={handleSubmit(handleEditClients)}
                    submitLoading={editLoading}
                    submitText='Update'
                    onCancel={() => navigate(-1)}
                  />
                </Box>
              </FmStack>
            </Box>
          )}
        </GlassCardCollapse>
        <ARReceiptsItemList />
      </Box>
    </DashboardLayout>
  );
};

export default EditARReceipts;
