import React, { useState, useEffect } from 'react';
import Lottie from 'react-lottie';
import * as animationData from '../../assets/lottie/server-ready.json';
import { Box, Button } from '@mui/material';
import { Icon } from '@iconify/react/dist/iconify.js';
import { use } from 'i18next';

const ServerReadyUi = () => {
  const [time, setTime] = useState(15);

  useEffect(() => {
    const interval = setInterval(() => {
      setTime((prevTime) => {
        if (prevTime <= 1) {
          clearInterval(interval);
          window.location.reload();
          return 0;
        }
        return prevTime - 1;
      });
    }, 1000);

    return () => clearInterval(interval);
  }, []);

  return (
    <Box className='w-full flex items-center justify-center whitespace-normal'>
      <Box className='text-center'>
        <Box className='justify-center flex '>
          <Box className='relative'>
            <Box className='pointer-events-none'>
              <Lottie
                options={{
                  loop: true,
                  autoplay: true,
                  animationData: animationData,
                }}
                height={'300px'}
                width={'100%'}
              />
            </Box>
          </Box>
        </Box>
        <Box className='font-bold text-base flex justify-center items-center gap-2'>
          Server is live!
        </Box>
        <p>
          Everything is up and running smoothly. <br /> Enjoy the speed and
          stability! ⚡🔥
        </p>
        <div className='my-5'>
          <Box>Kindly refresh the page.</Box>
        </div>
        <Box>
          <Button
            variant='contained'
            color='primary'
            onClick={() => window.location.reload()}
            className='min-w-32'
          >
            Reload Page ({time}s)
          </Button>
        </Box>
      </Box>
    </Box>
  );
};

export default ServerReadyUi;
