import React, { useState } from 'react';
import GlassCard from '../../../components/small/GlassCard.tsx';
import { Box, Button } from '@mui/material';
import { Icon } from '@iconify/react';
import { useNavigate, useLocation } from 'react-router-dom';
import useTableLogic from '../../../components/helpers/MRTUseTableLogic.tsx';
import FmMRTDataTable from '../../../components/FmMRTDataTable.tsx';
import Crud_Service from '../../../apis/CrudService.jsx';
import { toast } from 'react-toastify';
import FmTextField from '../../../components/_mui/FmTextField.tsx';
import { useForm } from 'react-hook-form';
import FmSearchableSelect from '../../../components/_mui/FmSearchableSelect.tsx';
import FmAutoComplete from '../../../components/_mui/FmAutoComplete.tsx';
import { statusOptions } from '../../../utils/CommonVariables.tsx';
import TableDropDown from '../../../components/helpers/TableDropDown.tsx';
import FmViewDateField from '../../../components/_mui/FmViewDateField.tsx';
import FmDatePicker from '../../../components/_mui/FmDatePicker.tsx';

const ListSnagReport = () => {
  const [editingRowData, setEditingRowData] = useState(null);
  const [editingRowId, setEditingRowId] = useState(null);
  const location = useLocation();
  const navigation = useNavigate();
  const crud = new Crud_Service();
  const { handleSubmit, control, setValue } = useForm();

  const {
    rows,
    isLoading,
    pagination,
    sorting,
    columnFilters,
    globalFilter,
    tableRecordCounts,
    setPagination,
    setSorting,
    setColumnFilters,
    setGlobalFilter,
    fetchData,
    setSearchKeyword,
    searchKeyword,
  } = useTableLogic('snagreports');

  const handleEdit = (row) => {
    setEditingRowId(row?.snagReportId);
    setEditingRowData({ ...row });

    // Use setValue from react-hook-form for setting form values
    Object.keys(row).forEach((key) => {
      setValue(key, row[key]); // If using react-hook-form's setValue
    });
  };
  const handleCancel = () => {
    setEditingRowId(null);
    setEditingRowData(null);
  };
  const handleDeleteItems = async (values) => {
    crud.remove('snagreports', values.snagReportId, (_err, res) => {
      if (res?.status === 204) {
        fetchData();
        toast.success('Snag Report deleted Successfully');
      } else {
      }
    });
  };

  const handleEditItem = async (values) => {
    // Extract projectDivisionId from the original row data
    const combinedData = {
      ...values,
      status: 2,
    };
    await crud.update(
      'snagreports',
      editingRowData?.snagReportId,
      combinedData,
      (_err, res) => {
        if (res?.status === 200) {
          toast.success('Snag Report Updated Successfully');
          setEditingRowId(null);
          setEditingRowData(null);
          fetchData();
        } else {
        }
      }
    );
  };

  const ActionData = [
    {
      name: 'Edit',
      icon: <Icon icon='solar:pen-2-broken' />,
      onClick: (props) =>
        navigation(`${location.pathname}/edit/${props?.snagReportId}`),
    },
    {
      name: 'Delete',
      icon: <Icon icon='solar:trash-bin-minimalistic-broken' />,
      onClick: (props) => handleDeleteItems(props),
      danger: true,
    },
    {
      name: 'Snag Tab',
      icon: <Icon icon='solar:basketball-linear' />,
      onClick: (props) => {
        const snagReportId = props?.snagReportId;
        navigation(`${location.pathname}/snag-tab/${snagReportId}`);
      },
    },
    {
      name: 'Functional Test Comments Tab',
      icon: <Icon icon='solar:chat-square-call-linear' />,
      onClick: (props) => {
        const snagReportId = props?.snagReportId;
        navigation(
          `${location.pathname}/functional-test-comments-tab/${snagReportId}`
        );
      },
    },
  ];

  const columns = [
    {
      accessorKey: 'snagReportName',
      header: 'Snag Report Name',
      enableEditing: true,
      Cell: ({ cell }) => {
        return editingRowId === cell?.row?.original?.snagReportId ? (
          <FmTextField
            name='snagReportName'
            control={control}
            label='Snag Report Name'
            required
          />
        ) : (
          rows?.find((item) => item?.snagReportName === cell.getValue())
            ?.snagReportName || ''
        );
      },
    },
    {
      accessorKey: 'snagReportCode',
      header: 'Snag Report Code',
      enableEditing: true,
      Cell: ({ cell }) => {
        return editingRowId === cell?.row?.original?.snagReportId ? (
          <FmTextField
            name='snagReportCode'
            control={control}
            label='Snag Report Code'
            required
          />
        ) : (
          rows?.find((item) => item?.snagReportCode === cell.getValue())
            ?.snagReportCode || ''
        );
      },
    },

    {
      accessorKey: 'projectId',
      header: 'Project',
      enableEditing: true,
      Cell: ({ row }) => {
        return editingRowId === row?.original?.snagReportId ? (
          <FmSearchableSelect
            name='projectId'
            control={control}
            apiUrl='projects'
            valueField='projectId'
            headerField={['Project Name']}
            labelField={['projectName']}
            showField={['projectName']}
            label='Project'
            required
            defaultValue={{
              projectId: editingRowData?.projectId,
              projectName: editingRowData?.projectName,
            }}
          />
        ) : (
          row?.original?.projectName
        );
      },
    },
    {
      accessorKey: 'contractId',
      header: 'Contract',
      enableEditing: true,
      Cell: ({ row }) => {
        return editingRowId === row?.original?.snagReportId ? (
          <FmSearchableSelect
            name='contractId'
            control={control}
            apiUrl='contracts'
            valueField='contractId'
            headerField={['Contract Code']}
            labelField={['contractName', 'contractCode']}
            showField={['contractName', 'contractCode']}
            label='Contract'
            required
            defaultValue={{
              contractId: editingRowData?.contractId,
              contractName: editingRowData?.contractName,
            }}
          />
        ) : (
          row?.original?.contractName
        );
      },
    },
    {
      accessorKey: 'companyId',
      header: 'Company',
      enableEditing: true,
      Cell: ({ row }) => {
        return editingRowId === row?.original?.snagReportId ? (
          <FmSearchableSelect
            name='companyId'
            control={control}
            apiUrl='companies'
            valueField='companyId'
            headerField={['Code']}
            labelField={['companyName']}
            showField={['companyName']}
            label='Company'
            required
            defaultValue={{
              companyId: editingRowData?.companyId,
              companyName: editingRowData?.companyName,
            }}
          />
        ) : (
          row?.original?.clientName
        );
      },
    },
    {
      accessorKey: 'clientId',
      header: 'Client',
      enableEditing: true,
      Cell: ({ row }) => {
        return editingRowId === row?.original?.snagReportId ? (
          <FmSearchableSelect
            name='clientId'
            control={control}
            apiUrl='clients'
            valueField='clientId'
            headerField={['Client Name']}
            labelField={['clientName']}
            showField={['clientName']}
            label='Client'
            required
            defaultValue={{
              clientId: editingRowData?.clientId,
              clientName: editingRowData?.clientName,
            }}
          />
        ) : (
          row?.original?.clientName
        );
      },
    },
    {
      accessorKey: 'divisionId',
      header: 'Division',
      enableEditing: true,
      Cell: ({ row }) => {
        return editingRowId === row?.original?.snagReportId ? (
          <FmSearchableSelect
            name='divisionId'
            control={control}
            apiUrl='divisions'
            valueField='divisionId'
            headerField={['Division Name']}
            labelField={['divisionName']}
            showField={['divisionName']}
            label='Division'
            required
            defaultValue={{
              divisionId: editingRowData?.divisionId,
              divisionName: editingRowData?.divisionName,
            }}
          />
        ) : (
          row?.original?.divisionName
        );
      },
    },
    {
      accessorKey: 'locationId',
      header: 'Location',
      enableEditing: true,
      Cell: ({ row }) => {
        return editingRowId === row?.original?.snagReportId ? (
          <FmSearchableSelect
            name='locationId'
            control={control}
            apiUrl='locations'
            valueField='locationId'
            headerField={['Location Name']}
            labelField={['locationName']}
            showField={['locationName']}
            label='Location'
            required
            defaultValue={{
              locationId: editingRowData?.locationId,
              locationName: editingRowData?.locationName,
            }}
          />
        ) : (
          row?.original?.locationName
        );
      },
    },
    {
      accessorKey: 'buildingId',
      header: 'Building',
      enableEditing: true,
      Cell: ({ row }) => {
        return editingRowId === row?.original?.snagReportId ? (
          <FmSearchableSelect
            name='buildingId'
            control={control}
            apiUrl='buildings'
            valueField='buildingId'
            headerField={['Building Name']}
            labelField={['buildingName']}
            showField={['buildingName']}
            label='Building'
            required
            defaultValue={{
              buildingId: editingRowData?.buildingId,
              buildingName: editingRowData?.buildingName,
            }}
          />
        ) : (
          row?.original?.buildingName
        );
      },
    },
    {
      accessorKey: 'snagReportStatusId',
      header: 'Snag Report Status',
      enableEditing: true,
      Cell: ({ row }) => {
        return editingRowId === row?.original?.snagReportId ? (
          <FmAutoComplete
            name='snagReportStatusId'
            control={control}
            options={statusOptions}
            label='Status'
            displayField='statusName'
            optionFields={['statusName']}
            valueKey='statusId'
          />
        ) : (
          row?.original?.statusName
        );
      },
    },
    {
      accessorKey: 'snagReportDate',
      header: 'Snag Report Date',
      enableEditing: false,
      Cell: ({ row }) => {
        return editingRowId === row?.original?.snagReportId ? (
          <FmDatePicker
            name='snagReportDate'
            control={control}
            label='Snag Report Date'
          />
        ) : (
          <FmViewDateField value={row?.original?.snagReportDate} />
        );
      },
    },
    {
      accessorKey: 'snagTypeId',
      header: 'Snag Type',
      enableEditing: true,
      Cell: ({ row }) => {
        return editingRowId === row?.original?.snagReportId ? (
          <FmSearchableSelect
            name='snagTypeId'
            control={control}
            apiUrl='snagtypes'
            valueField='snagTypeId'
            headerField={['Snag Name']}
            labelField={['snagTypeName']}
            showField={['snagTypeName']}
            label='Snag Type'
            required
            defaultValue={{
              snagTypeId: editingRowData?.snagTypeId,
              snagTypeName: editingRowData?.snagTypeName,
            }}
          />
        ) : (
          row?.original?.snagTypeName
        );
      },
    },
    {
      accessorKey: 'lumpsumAmount',
      header: 'Lumpsum Amount',
      enableEditing: true,
      Cell: ({ cell }) => {
        return editingRowId === cell?.row?.original?.snagReportId ? (
          <FmTextField
            name='lumpsumAmount'
            control={control}
            label='Lumpsum Amount'
            pattern='Number'
            required
          />
        ) : (
          rows?.find((item) => item?.lumpsumAmount === cell.getValue())
            ?.lumpsumAmount || ''
        );
      },
    },
    {
      accessorKey: 'areaId',
      header: 'Area',
      enableEditing: true,
      Cell: ({ row }) => {
        return editingRowId === row?.original?.snagReportId ? (
          <FmSearchableSelect
            name='areaId'
            control={control}
            apiUrl='areas'
            valueField='areaId'
            headerField={['Area Name']}
            labelField={['areaName']}
            showField={['areaName']}
            label='Area'
            required
            defaultValue={{
              areaId: editingRowData?.areaId,
              areaName: editingRowData?.areaName,
            }}
          />
        ) : (
          row?.original?.areaName
        );
      },
    },
    {
      accessorKey: 'inspectedBy',
      header: 'InspectedBy',
      enableEditing: true,
      Cell: ({ row }) => {
        return editingRowId === row?.original?.snagReportId ? (
          <FmSearchableSelect
            name='inspectedBy'
            control={control}
            apiUrl='loginusers'
            valueField='loginUserId'
            headerField={['User Name']}
            labelField={['loginUserName']}
            showField={['loginUserName']}
            label='inspectedBy'
            required
            defaultValue={{
              inspectedBy: editingRowData?.inspectedBy,
              loginUserName: editingRowData?.inspectedByName,
            }}
          />
        ) : (
          row?.original?.inspectedUserName
        );
      },
    },
    {
      accessorKey: 'propertyManager',
      header: 'Property Manager',
      enableEditing: true,
      Cell: ({ cell }) => {
        return editingRowId === cell?.row?.original?.snagReportId ? (
          <FmTextField
            name='propertyManager'
            control={control}
            label='Property Manager'
            required
          />
        ) : (
          rows?.find((item) => item?.propertyManager === cell.getValue())
            ?.propertyManager || ''
        );
      },
    },
    {
      accessorKey: 'notes',
      header: 'Notes',
      enableEditing: true,
      Cell: ({ cell }) => {
        return editingRowId === cell?.row?.original?.snagReportId ? (
          <FmTextField
            name='notes'
            control={control}
            label='Notes'
            required
            multiline={true}
          />
        ) : (
          rows?.find((item) => item?.notes === cell.getValue())?.notes || ''
        );
      },
    },
    {
      header: 'Actions',
      enableColumnPinning: true,
      enableEditing: false,
      enableSorting: false,
      Cell: ({ row }) => (
        <Box className='flex gap-2'>
          {editingRowId === row?.original?.snagReportId ? (
            <>
              <Button
                onClick={handleSubmit(handleEditItem)}
                loading={isLoading}
              >
                Save
              </Button>
              <Button onClick={handleCancel}>Cancel</Button>
            </>
          ) : (
            <TableDropDown
              Actions={ActionData?.map((action) => ({
                ...action,
                onClick: () => action.onClick(row?.original),
              }))}
              selectedRow={row?.original}
              setSelectedRow={() => {}}
            />
          )}
        </Box>
      ),
    },
  ];

  return (
    <>
      <GlassCard className='h-full-css overflow-hidden'>
        <FmMRTDataTable
          rows={rows}
          columns={columns}
          enableRowSelection={false}
          rowCount={tableRecordCounts}
          editingRow={editingRowId}
          pagination={pagination}
          sorting={sorting}
          columnFilters={columnFilters}
          globalFilter={globalFilter}
          setPagination={setPagination}
          setSorting={setSorting}
          setColumnFilters={setColumnFilters}
          setGlobalFilter={setGlobalFilter}
          isLoading={isLoading}
          setSearchKeyword={setSearchKeyword}
          searchKeyword={searchKeyword}
          muiTableContainerProps={{
            sx: { maxHeight: '100%', flex: 1 },
          }}
        />
      </GlassCard>
    </>
  );
};

export default ListSnagReport;
