import React from 'react';
import { Box } from '@mui/material';
import { Icon } from '@iconify/react/dist/iconify.js';

const ViewStartEndDate = ({ start, end }) => {
  return (
    <Box>
      <Box className='flex gap-2 items-center text-teal-600 font-semibold'>
        <Box className='text-base'>
          <Icon icon='fa6-regular:calendar-check' />
        </Box>
        <Box> {start}</Box>
      </Box>
      <Box className='flex gap-2 items-center text-red-600 font-semibold'>
        <Box className='text-base'>
          <Icon icon='fa6-regular:calendar-xmark' />
        </Box>
        <Box>{end}</Box>
      </Box>
    </Box>
  );
};

export default ViewStartEndDate;
