import React, { useEffect, useState } from 'react';
import {
  Box,
  Grid,
} from '@mui/material';
import GlassCard from '../../../../components/small/GlassCard.tsx';
import FmSearchableSelect from '../../../../components/_mui/FmSearchableSelect.tsx';
import FmTextField from '../../../../components/_mui/FmTextField.tsx';
import { useForm } from 'react-hook-form';
import GlassCardCollapse from '../../../../components/small/GlassCardCollapse.tsx';
import useLoader from '../../../../components/helpers/UseLoader.tsx';
import { toast } from 'react-toastify';
import { useNavigate, useParams } from 'react-router-dom';
import FmDatePicker from '../../../../components/_mui/FmDatePicker.tsx';
import ActionButtons from '../../../../components/_form/ActionButtons.tsx';
import dayjs from 'dayjs';
import JobNotes from './JobNotes.tsx';
import { TicketValidationSchema } from '../../../../utils/CommonvalidationSchemas.tsx';
import { yupResolver } from '@hookform/resolvers/yup';

const JobDetails = (props) => {
  const { crud, fetchData } = props;
  const { id } = useParams();
  const { editLoading, startCreateLoading, stopCreateLoading, createLoading, stopEditLoading, startEditLoading } = useLoader();
  const [openCreate, setOpenCreate] = useState(false);
  const [editingRowId, setEditingRowId] = useState(null)
  const [loading, setLoading] = useState(false);
  const [ticketData, setTicketData] = useState({});
  const [editingRowData, setEditingRowData] = useState(null);
  const navigate = useNavigate();
  const { handleSubmit, control, setValue, reset } = useForm({
    mode: 'onChange',
    resolver: yupResolver(TicketValidationSchema),
  });
  const viewDataFetch = async () => {
    setLoading(true);
    await crud.getSingle('tickets', id, (err, res) => {
      if (res?.status === 200) {
        setTicketData(res?.data);
        Object.entries({
          ...res?.data,
        }).forEach(([key, value]) => {
          setValue(key, value);
        });
        setLoading(false);
      }
    });
  };
  const handleSubmitTickets = async (values) => {
    startEditLoading();
    const combinedData = {
      ...values,
      // TODO - we need roleId
      roleId: 1,
      resolveDate: dayjs(values?.resolveDate).format('YYYY-MM-DD'),
      preferredDate: dayjs(values?.preferredDate).format('YYYY-MM-DD'),
      // TODO - should remove from the backend side
      resources: [],
      attachments: []
    };
    await crud.update('tickets', id, combinedData, (err, res) => {
      if (res?.status === 200) {
        toast.success('Ticket Updated Successfully');
        stopEditLoading();
        fetchData();
        setEditingRowData(null);
        setEditingRowId(null);
      } else {
        stopEditLoading();
      }
    });
  };

  useEffect(() => {
    if (id) {
      viewDataFetch();
    }
  }, [id]);

  const handleCreateClose = () => {
    reset();
    setOpenCreate(false);
  };



  const handleDelete = async (props) => {
    await crud.remove(
      'ticketnotes',
      props?.ticketNoteId,
      (_err, res) => {
        if (res?.status === 204) {
          toast.success('Ticket Note Deleted Successfully');
          fetchData();
        }
      }
    );
  };
  return (
    <>
      <Box className='mt-5 '>
        <GlassCard className='overflow-hidden  min-h-full '>
          <form onSubmit={handleSubmit(handleSubmitTickets)}>
            <GlassCardCollapse title='Update'>
              <Grid container spacing={2}>
                <Grid item md={3}>
                  <FmTextField
                    label='Request No'
                    name='requestNo'
                    control={control}
                  />
                </Grid>
                <Grid item md={3}>
                  <FmSearchableSelect
                    name='requestModeId'
                    control={control}
                    apiUrl='requestmodes'
                    valueField='requestModeId'
                    headerField={['Request Mode Name', 'Request Mode Code']}
                    labelField={['requestModeName', 'requestModeCode']}
                    showField={['requestModeName']}
                    label='Request Mode'
                    defaultValue={{
                      requestModeId: ticketData?.requestModeId,
                      requestModeName: ticketData?.requestModeName,
                    }}
                  />
                </Grid>
                <Grid item md={3}>
                  <FmTextField
                    label='Caller Name'
                    name='requesterName'
                    control={control}
                  />
                </Grid>
                <Grid item md={3}>
                  <FmTextField
                    label='Caller Email'
                    name='requesterEmail'
                    control={control}
                  />
                </Grid>
                <Grid item md={3}>
                  <FmTextField
                    label='Caller Mobile'
                    name='requesterPhone'
                    control={control}
                  />
                </Grid>
              </Grid>
              <Grid container spacing={2} sx={{ mt: 0 }}>
                <Grid item md={3}>
                  <FmSearchableSelect
                    name='projectId'
                    control={control}
                    apiUrl='projects'
                    valueField='projectId'
                    headerField={['Project Code', 'Project Name']}
                    labelField={['projectCode', 'projectName']}
                    showField={['projectName']}
                    label='Project'
                    defaultValue={{
                      projectId: ticketData?.projectId,
                      projectName: ticketData?.projectName,
                    }}
                    required
                  />
                </Grid>
                <Grid item md={3}>
                  <FmSearchableSelect
                    name='contractId'
                    control={control}
                    apiUrl='contracts'
                    valueField='contractId'
                    headerField={['Contract Code', 'Contract Name']}
                    labelField={['contractCode', 'contractName']}
                    showField={['contractName']}
                    label={'Contracts'}
                    defaultValue={{
                      contractId: ticketData?.contractId,
                      contractName: ticketData?.contractName,
                    }}
                  />
                </Grid>
                <Grid item md={3}>
                  <FmSearchableSelect
                    name='buildingId'
                    control={control}
                    apiUrl='buildings'
                    valueField='buildingId'
                    headerField={['Building Code', 'Building Name']}
                    labelField={['buildingCode', 'buildingName']}
                    showField={['buildingName']}
                    label='Building'
                    required
                    defaultValue={{
                      buildingId: ticketData?.buildingId,
                      buildingName: ticketData?.buildingName,
                    }}
                  />
                </Grid>
                <Grid item md={3}>
                  <FmSearchableSelect
                    name='blockId'
                    control={control}
                    apiUrl='blocks'
                    valueField='blockId'
                    headerField={['Block Name', 'Block Code']}
                    labelField={['blockName', 'blockCode']}
                    showField={['blockName']}
                    label='Block'
                    defaultValue={{
                      blockId: ticketData?.blockId,
                      blockName: ticketData?.blockName,
                    }}
                  />
                </Grid>

                <Grid item md={3}>
                  <FmSearchableSelect
                    name='floorId'
                    control={control}
                    apiUrl='floors'
                    valueField='floorId'
                    headerField={['Floor Name', 'Floor Code']}
                    labelField={['floorName', 'floorCode']}
                    showField={['floorName']}
                    label='Floor'
                    defaultValue={{
                      floorId: ticketData?.floorId,
                      floorName: ticketData?.floorName,
                    }}
                  />
                </Grid>
                <Grid item md={3}>
                  <FmSearchableSelect
                    name='unitId'
                    control={control}
                    apiUrl='baseunits'
                    valueField='baseUnitId'
                    headerField={['Unit Name', 'Unit Code']}
                    labelField={['baseUnitName', 'baseUnitCode']}
                    showField={['baseUnitName']}
                    label='Unit'
                    defaultValue={{
                      baseUnitId: ticketData?.unitId,
                      baseUnitName: ticketData?.unitName,
                    }}
                  />
                </Grid>
                <Grid item md={3}>
                  <FmSearchableSelect
                    name='subUnitId'
                    control={control}
                    apiUrl='baseunits'
                    valueField='baseUnitId'
                    headerField={['Unit Name', 'Unit Code']}
                    labelField={['baseUnitName', 'baseUnitCode']}
                    showField={['baseUnitName']}
                    label='Sub Unit'
                    defaultValue={{
                      baseUnitId: ticketData?.subUnitId,
                      baseUnitName: ticketData?.subUnitName,
                    }}
                  />
                </Grid>
                <Grid item md={3}>
                  <FmSearchableSelect
                    name='unitSubLocationId'
                    control={control}
                    apiUrl='locations'
                    valueField='locationId'
                    headerField={['Unit Name']}
                    labelField={['locationName']}
                    showField={['locationName']}
                    label='Unit Sub Location'
                    defaultValue={{
                      locationId: ticketData?.subLocationId,
                      locationName: ticketData?.subLocationName,
                    }}
                  />
                </Grid>
                <Grid item md={3}>
                  <FmSearchableSelect
                    name='callClassificationId'
                    control={control}
                    apiUrl='callclassifications'
                    valueField='callClassificationId'
                    headerField={[
                      'Call Classification Name',
                      'Call Classification Code',
                    ]}
                    labelField={[
                      'callClassificationName',
                      'callClassificationCode',
                    ]}
                    showField={['callClassificationName']}
                    label='Call Classification'
                    defaultValue={{
                      callClassificationId: ticketData?.callClassificationId,
                      callClassificationName:
                        ticketData?.callClassificationName,
                    }}
                  />
                </Grid>
                <Grid item md={3}>
                  <FmSearchableSelect
                    name='callerTypeId'
                    control={control}
                    apiUrl='tickets/caller-types'
                    valueField='callerTypeId'
                    headerField={['Caller Type Name']}
                    labelField={['callerTypeName']}
                    showField={['callerTypeName']}
                    label='Caller Type'
                    defaultValue={{
                      callerTypeId: ticketData?.callerTypeId,
                      callerTypeName: ticketData?.callerTypeName,
                    }}
                  />
                </Grid>
                <Grid item md={3}>
                  <FmSearchableSelect
                    name='callTypeId'
                    control={control}
                    apiUrl='tickets/call-types'
                    valueField='callTypeId'
                    headerField={['Call Type Name']}
                    labelField={['callTypeName']}
                    showField={['callTypeName']}
                    label='Call Type'
                    defaultValue={{
                      callTypeId: ticketData?.callTypeId,
                      callTypeName: ticketData?.callTypeName,
                    }}
                  />
                </Grid>
                <Grid item md={3}>
                  <FmDatePicker
                    name='resolveDate'
                    label='Resolve Date'
                    control={control}
                  />
                </Grid>
                <Grid item md={3}>
                  <FmSearchableSelect
                    name='ticketStatusId'
                    control={control}
                    apiUrl='ticketstatuses'
                    valueField='ticketStatusId'
                    headerField={['Status Name', 'Status Code']}
                    labelField={['ticketStatusName', 'ticketStatusCode']}
                    showField={['ticketStatusName']}
                    label='Status'
                    defaultValue={{
                      ticketStatusId: ticketData?.ticketStatusId,
                      ticketStatusName: ticketData?.ticketStatusName,
                    }}
                  />
                </Grid>
                <Grid item md={3}>
                  <FmSearchableSelect
                    name='priorityId'
                    control={control}
                    apiUrl='priorities'
                    valueField='priorityId'
                    headerField={['Priority', 'Priority Code']}
                    labelField={['priorityName', 'priorityCode']}
                    showField={['priorityName']}
                    label='Priority'
                    defaultValue={{
                      priorityId: ticketData?.priorityId,
                      priorityName: ticketData?.priorityName,
                    }}
                  />
                </Grid>
                <Grid item md={3}>
                  <FmSearchableSelect
                    name='categoryId'
                    control={control}
                    apiUrl='categories'
                    valueField='categoryId'
                    headerField={['Category Name', 'Category Code']}
                    labelField={['categoryName', 'categoryCode']}
                    showField={['categoryName']}
                    label='Category'
                    defaultValue={{
                      categoryId: ticketData?.categoryId,
                      categoryName: ticketData?.categoryName,
                    }}
                  />
                </Grid>
                <Grid item md={3}>
                  <FmSearchableSelect
                    name='categorySubId'
                    control={control}
                    apiUrl='categories'
                    valueField='categoryId'
                    headerField={['Sub Category Name', 'Sub Category Code']}
                    labelField={['categoryName', 'categoryCode']}
                    showField={['categoryName']}
                    label='Sub Category'
                    defaultValue={{
                      categoryId: ticketData?.categorySubId,
                      categoryName: ticketData?.subCategoryName,
                    }}
                  />
                </Grid>
                <Grid item md={3}>
                  <FmSearchableSelect
                    name='issueId'
                    control={control}
                    apiUrl='issues'
                    valueField='issueId'
                    headerField={['Issue Name', 'Issue Code']}
                    labelField={['issueName', 'issueCode']}
                    showField={['issueName']}
                    label='Issue'
                    defaultValue={{
                      issueId: ticketData?.issueId,
                      issueName: ticketData?.issueName,
                    }}
                  />
                </Grid>
                <Grid item md={3}>
                  <FmDatePicker
                    name='preferredDate'
                    label='Preferred Date'
                    control={control}
                  />
                </Grid>
                <Grid item md={3}>
                  <FmSearchableSelect
                    name='slaId'
                    control={control}
                    apiUrl='slas'
                    valueField='slaId'
                    headerField={['SLA Name', 'SLA Code']}
                    labelField={['slaName', 'slaCode']}
                    showField={['slaName']}
                    label='SLA'
                    defaultValue={{
                      slaId: ticketData?.slaId,
                      slaName: ticketData?.slaName,
                    }}
                  />
                </Grid>
                <Grid item md={3}>
                  <FmSearchableSelect
                    name='frequencyId'
                    control={control}
                    apiUrl='frequencies'
                    valueField='frequencyId'
                    headerField={['Frequency Name', 'Frequency Code']}
                    labelField={['frequencyName', 'frequencyCode']}
                    showField={['frequencyName']}
                    label='Frequency'
                    defaultValue={{
                      frequencyId: ticketData?.frequencyId,
                      frequencyName: ticketData?.frequencyName,
                    }}
                  />
                </Grid>
                <Grid item md={3}>
                  <FmSearchableSelect
                    name='queueId'
                    control={control}
                    apiUrl='tickets/queues'
                    valueField='queueId'
                    headerField={['Queue Name']}
                    labelField={['queueName']}
                    showField={['queueName']}
                    label='Queue'
                    defaultValue={{
                      queueId: ticketData?.queueId,
                      queueName: ticketData?.queueName,
                    }}
                  />
                </Grid>
                <Grid item md={3}>
                  <FmSearchableSelect
                    name='liableBy'
                    control={control}
                    apiUrl='liables'
                    valueField='liableId'
                    headerField={['Liable Name', 'Liable Code']}
                    labelField={['liableName', 'liableCode']}
                    showField={['liableName']}
                    label='Liable By'
                    required
                    defaultValue={{
                      liableId: ticketData?.liableId,
                      liableName: ticketData?.liableName,
                    }}
                  />
                </Grid>
                {/* TODO */}
                {/* <Grid item md={3}>
                  <FmTextField
                    name='roleName'
                    required
                    control={control}
                    label='Role'
                  />
                </Grid> */}
                <Grid item md={3}>
                  <FmTextField
                    name='comment'
                    control={control}
                    label='Special Comment'
                    multiline={true}
                    rows={2}
                    maxRows={3}
                  />
                </Grid>
                <Grid item md={3}>
                  <FmTextField
                    name='description'
                    control={control}
                    label='Description'
                    multiline={true}
                    rows={2}
                    maxRows={3}
                  />
                </Grid>
                <Grid item md={3}>
                  <FmSearchableSelect
                    name='technicianId'
                    control={control}
                    apiUrl='employees'
                    valueField='employeeId'
                    headerField={['Code', 'Name', 'Email']}
                    labelField={['employeeCode', 'fullName', 'emailId']}
                    labelField={['employeeCode', 'fullName', 'emailId']}
                    label='Technician Name'
                    onChangeProp={(value) => {
                      if (value?.technicianDepartmentId) {
                        setValue(
                          'technicianDepartmentId',
                          value?.technicianDepartmentId
                        );
                      }
                    }}
                    defaultValue={{
                      employeeId: ticketData?.technicianId,
                      firstName: ticketData?.technicianName,
                    }}
                  />
                </Grid>
                <Grid item md={3}>
                  <FmSearchableSelect
                    name='departmentId'
                    control={control}
                    apiUrl='departments'
                    valueField='departmentId'
                    headerField={['Code', 'Name']}
                    labelField={['departmentCode', 'departmentName']}
                    showField={['departmentName']}
                    label={'Departments'}
                    defaultValue={{
                      departmentId: ticketData?.technicianDepartmentId,
                      departmentName: ticketData?.technicianDepartmentName,
                    }}
                  />
                </Grid>
                <Grid item md={3}>
                  <FmTextField
                    label='Employee Mobile'
                    name='technicianMoileNumber'
                    control={control}
                  />
                </Grid>
                <Grid item md={3}>
                  <FmTextField
                    label='Employee Email'
                    name='technicianEmail'
                    control={control}
                  />
                </Grid>
                <Grid item md={3}>
                  <FmTextField
                    label='PDA User Name'
                    name='pdaUsername'
                    control={control}
                  />
                </Grid>
                <Grid item md={3}>
                  <FmTextField
                    label='In Charge Email'
                    name='inchargeEmail'
                    control={control}
                  />
                </Grid>
                <Grid item md={3}>
                  <FmSearchableSelect
                    name='engineerId'
                    control={control}
                    apiUrl='employees'
                    valueField='employeeId'
                    headerField={['Code', 'Name', 'Email']}
                    labelField={['employeeCode', 'fullName', 'emailId']}
                    labelField={['employeeCode', 'fullName', 'emailId']}
                    label='Engineer'
                    defaultValue={{
                      employeeId: ticketData?.engineerId,
                      firstName: ticketData?.engineerName,
                    }}
                  />
                </Grid>
                <Grid item md={3}>
                  <FmSearchableSelect
                    name='supervisorId'
                    control={control}
                    apiUrl='employees'
                    valueField='employeeId'
                    headerField={['Code', 'Name', 'Email']}
                    labelField={['employeeCode', 'fullName', 'emailId']}
                    labelField={['employeeCode', 'fullName', 'emailId']}
                    label='Supervisor'
                    defaultValue={{
                      employeeId: ticketData?.supervisorId,
                      firstName: ticketData?.supervisorName,
                    }}
                  />
                </Grid>
                <Grid item md={3}>
                  <FmTextField
                    label='Response SLA Breach Justification'
                    name='responseSLABreachJustification'
                    control={control}
                  />
                </Grid>
                <Grid item md={3}>
                  <FmTextField
                    label='Resolution SLA Breach Justification'
                    name='resolutionSLABreachJustification'
                    control={control}
                  />
                </Grid>

                {/* TODO */}
                {/* <Grid item md={3}>
              <GlassCard className='overflow-hidden  min-h-full '>
                <ViewTable
                  disableBorder
                  rows={[
                    {
                      label: 'Assigned To',
                      title: (
                        <InlineEditPopover title={'Mohamed'}>
                          <Autocomplete
                            options={top100Films}
                            renderInput={(params) => (
                              <TextField
                                {...params}
                                label='Assigned To'
                                fullWidth
                                variant='filled'
                              />
                            )}
                          />
                        </InlineEditPopover>
                      ),
                    },
                    { label: 'Technician', title: 'Jamesh Adams' },
                    { label: 'Resolution', title: 'Floor Clean' },
                    { label: 'Resolution Date', title: '20/06/2024 10:30 AM' },
                    { label: 'Job Duration', title: '2hrs' },
                  ]}
                />
              </GlassCard>
            </Grid>
            <Grid item md={3}>
              <GlassCard className='overflow-hidden  min-h-full '>
                <ViewTable
                  disableBorder
                  rows={[
                    { label: 'Contract', title: 'Building 4' },
                    { label: 'Location', title: 'Floor 2' },
                    { label: 'Contact Name', title: 'William' },
                    { label: 'Telephone', title: '+966 564 2545' },
                    { label: 'Email', title: 'William@gmail.com' },
                  ]}
                />
              </GlassCard>
            </Grid>
            <Grid item md={3}>
              <GlassCard className='overflow-hidden  min-h-full '>
                <ViewTable
                  disableBorder
                  rows={[
                    { label: 'Created', title: '20/06/2024 :10:30AM' },
                    { label: 'Created By', title: 'Mohamed' },
                    { label: 'Reported By', title: 'William' },
                    { label: 'Order No', title: '55656556' },
                    {
                      label: 'Alerts',
                      title: <StatusBadge title='Create Alert' type='red' />,
                    },
                  ]}
                />
              </GlassCard>
            </Grid> */}
              </Grid>
              <ActionButtons
                onSubmit={handleSubmit(handleSubmitTickets)}
                onCancel={() => navigate(-1)}
                onReset={reset}
                submitLoading={editLoading}
                cancelLoading={false}
                submitText='Update'
              />
            </GlassCardCollapse>
          </form>
          <JobNotes
            handleCreateClose={handleCreateClose}
            openCreate={openCreate}
            setOpenCreate={setOpenCreate}
            navigate={navigate}
            createLoading={createLoading}
            id={id}
            editingRowId={editingRowId}
            fetchData={fetchData}
            setEditingRowId={setEditingRowId}
          />
        </GlassCard>
      </Box>
    </>
  );
};

export default JobDetails;
