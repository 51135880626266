import React from 'react';
import { Box } from '@mui/material';
import { Icon } from '@iconify/react';

const ProgressCircleStep = ({ apiresponse }) => {
  return (
    apiresponse?.length > 0 && (
      <Box className='flex justify-between'>
        {apiresponse
          ?.filter((itm) => itm.statusName !== '')
          ?.map((itm, ind) => (
            <Box
              className='flex flex-col items-center text-center w-full relative'
              key={itm.id}
              sx={{
                '&:last-child': {
                  '& .dividers': {
                    display: 'none',
                  },
                },
              }}
            >
              <Box
                className={`dividers absolute ${
                  apiresponse.length === itm.id && 'hidden'
                }`}
                sx={{
                  borderTop: '3px dashed',
                  borderColor: 'border.main',
                  left: '50%',
                  width: '100%',
                  top: '23px',
                }}
              ></Box>
              <Box
                sx={{
                  width: '45px',
                  height: '45px',
                  bgcolor:
                    itm.status === true ? 'secondary.main' : 'background.white',
                  borderRadius: '50%',
                  border: '1px solid',
                  borderColor:
                    itm.status === true ? 'secondary.main' : 'border.main',
                }}
                className='flex justify-center items-center relative'
              >
                <Box
                  className={`flex justify-center items-center text-3xl ${
                    itm.status === false && 'opacity-50'
                  }`}
                  sx={{ color: itm.status && 'text.white' }}
                >
                  {itm.status ? (
                    <Icon icon='mage:message-check-round-fill' />
                  ) : (
                    <Icon icon='material-symbols-light:timelapse-outline-rounded' />
                  )}
                </Box>
              </Box>
              <h3
                className={`font-semibold mt-3 ${
                  itm.status === false && 'opacity-50'
                }`}
              >
                {itm.statusName}
              </h3>
            </Box>
          ))}
      </Box>
    )
  );
};

export default ProgressCircleStep;
