import { yupResolver } from '@hookform/resolvers/yup';
import {
  Box,
  Checkbox,
  FormControl,
  FormHelperText,
  Grid,
} from '@mui/material';
import { AdapterDayjs } from '@mui/x-date-pickers/AdapterDayjs';
import { LocalizationProvider } from '@mui/x-date-pickers/LocalizationProvider';
import dayjs from 'dayjs';
import React, { useEffect, useState } from 'react';
import { Controller, useForm } from 'react-hook-form';
import { useTranslation } from 'react-i18next';
import { useNavigate, useParams } from 'react-router-dom';
import { toast } from 'react-toastify';
import Crud_Service from '../../../apis/CrudService';
import { ActionButtons } from '../../../components/_form';
import FmAutoComplete from '../../../components/_mui/FmAutoComplete.tsx';
import FmDatePicker from '../../../components/_mui/FmDatePicker.tsx';
import FmTextField from '../../../components/_mui/FmTextField.tsx';
import DashboardLayout from '../../../components/DashboardLayout.tsx';
import useCommonFetchApi from '../../../components/helpers/useCommonFetchApi.tsx';
import useLoader from '../../../components/helpers/UseLoader.tsx';
import CommonLoader from '../../../components/page/CommonLoader.jsx';
import GlassCard from '../../../components/small/GlassCard.tsx';
import { contractSchema } from '../../../utils/CommonvalidationSchemas.tsx';
import FmSearchableSelect from '../../../components/_mui/FmSearchableSelect.tsx';
import {
  contractStatus,
  contractStatusEnum,
  projectStatusEnum,
  projectTypes,
  statusOptions,
} from '../../../utils/CommonVariables.tsx';
import BackToButton from '../../../components/small/BackToButton.tsx';
import FmCurrencyTextField from '../../../components/_mui/FmCurrencyTextField.tsx';

const gridValue = 2.4;

const EditContracts = () => {
  const navigate = useNavigate();
  const crud = new Crud_Service();
  const [loading, setLoading] = useState(false);
  const [editData, setEditData] = useState(null);
  const [contractGuarantees, setContractGuarantees] = useState([]);
  const [contractScopeMaps, setContractScopeMaps] = useState([]);
  const [contractScopeIds, setContractScopeIds] = useState([]);
  const [guaranteeIds, setGuaranteeIds] = useState([]);
  const { id } = useParams();

  const {
    handleSubmit,
    control,
    setValue,
    reset,
    watch,
    formState: { errors },
  } = useForm({
    resolver: yupResolver(contractSchema),
    mode: 'onChange',
    defaultValues: { retention: false },
  });
  const { createLoading, startCreateLoading, stopCreateLoading } = useLoader();
  const { t } = useTranslation();

  useEffect(() => {
    getAllContracts();
  }, []);
  //fetch All Apis
  const { data: contractmodes } = useCommonFetchApi('contracts/contract-modes');
  const { data: contracttypes } = useCommonFetchApi('contracttypes');
  const { data: contractscopes } = useCommonFetchApi('contractscopes');
  const { data: billingfrequencies } = useCommonFetchApi('billingfrequencies');
  const { data: guarantees } = useCommonFetchApi('guarantees');
  const { data: currencies } = useCommonFetchApi('currencies');
  const getAllContracts = async () => {
    setLoading(true);
    await crud.getSingle('contracts', id, (_err, res) => {
      if (res?.status === 200) {
        const { contractGuarantees, contractScopeMaps, ...formData } = res.data;

        const contractScopeIds = contractScopeMaps.map(
          (item) => item.contractScopeId
        );
        const guaranteeIds = contractGuarantees.map((item) => item.guaranteeId);
        setValue('guaranteeIds', guaranteeIds);
        setValue('contractScopeIds', contractScopeIds);
        setContractGuarantees(contractGuarantees);
        setContractScopeMaps(contractScopeMaps);
        setContractScopeIds(contractScopeIds);
        setGuaranteeIds(guaranteeIds);

        Object.entries(formData).forEach(([key, value]) => {
          setValue(key, value);
        });
        setEditData(res?.data);
      }
      setLoading(false);
    });
  };

  const handleSubmitProject = async (values) => {
    startCreateLoading();
    const CombinedData = {
      ...values,
      guaranteeIds,
      contractStartDate: dayjs(values?.contractStartDate).isValid()
        ? dayjs(values?.contractStartDate).format('YYYY-MM-DD')
        : null,

      contractEndDate: dayjs(values?.contractEndDate).isValid()
        ? dayjs(values?.contractEndDate).format('YYYY-MM-DD')
        : null,

      guaranteeStartDate: dayjs(values?.guaranteeStartDate).isValid()
        ? dayjs(values?.guaranteeStartDate).format('YYYY-MM-DD')
        : null,

      guaranteeEndDate: dayjs(values?.guaranteeEndDate).isValid()
        ? dayjs(values?.guaranteeEndDate).format('YYYY-MM-DD')
        : null,
    };

    await crud.update(`contracts`, id, CombinedData, (_err, res) => {
      if (res?.status === 200) {
        toast.success('Contract Updated Successfully');
        navigate(-1);
        stopCreateLoading();
      } else {
        stopCreateLoading();
      }
    });
  };
  const projectTypeId = watch('typeId'); // Watching selected project type
  const retentionChecked = watch('retention');

  return (
    <DashboardLayout title='Edit Contract'>
      <BackToButton title='Back' onClick={() => navigate(-1)} />
      <GlassCard className='p-4'>
        {loading ? (
          <CommonLoader />
        ) : (
          <form onSubmit={handleSubmit(handleSubmitProject)}>
            <LocalizationProvider dateAdapter={AdapterDayjs}>
              <Grid container spacing={3}>
                <Grid item md={gridValue}>
                  <FmTextField
                    name='contractName'
                    control={control}
                    label='Contract Name'
                    required
                    disabled={
                      editData?.contractStatusId ===
                      contractStatusEnum?.Approved
                    }
                  />
                </Grid>
                <Grid item md={gridValue}>
                  <FmTextField
                    name='contractManualCode'
                    control={control}
                    label='Contract Code'
                    required
                    disabled={
                      editData?.contractStatusId ===
                      contractStatusEnum?.Approved
                    }
                  />
                </Grid>
                <Grid item md={gridValue}>
                  <FmSearchableSelect
                    name='companyId'
                    control={control}
                    apiUrl='companies'
                    valueField='companyId'
                    labelField={['companyCode', 'companyName']}
                    showField={['companyCode', 'companyName']}
                    label='Company'
                    disabled={
                      editData?.contractStatusId ===
                      contractStatusEnum?.Approved
                    }
                  />
                </Grid>
                <Grid item md={gridValue}>
                  <FmSearchableSelect
                    name='projectId'
                    control={control}
                    apiUrl='projects'
                    valueField='projectId'
                    labelField={['projectCode', 'projectName']}
                    showField={['projectCode', 'projectName']}
                    label={'Project'}
                    required
                    defaultValue={{
                      projectId: editData?.projectId,
                      projectName: editData?.projectName,
                    }}
                    disabled={
                      editData?.contractStatusId ===
                      contractStatusEnum?.Approved
                    }
                  />
                </Grid>

                <Grid item md={gridValue}>
                  <FmSearchableSelect
                    name='clientId'
                    control={control}
                    apiUrl='clients'
                    valueField='clientId'
                    labelField={['clientCode', 'clientName']}
                    showField={['clientCode', 'clientName']}
                    label={'Clients'}
                    required
                    defaultValue={{
                      clientId: editData?.clientId,
                      clientName: editData?.clientName,
                    }}
                    disabled={
                      editData?.contractStatusId ===
                      contractStatusEnum?.Approved
                    }
                  />
                </Grid>

                <Grid item md={gridValue}>
                  <FmSearchableSelect
                    name='branchId'
                    control={control}
                    apiUrl='branches'
                    valueField='branchId'
                    labelField={['branchCode', 'branchName']}
                    showField={['branchCode', 'branchName']}
                    label={'Branch'}
                    defaultValue={{
                      branchId: editData?.branchId,
                      branchName: editData?.branchName,
                    }}
                    disabled={
                      editData?.contractStatusId ===
                      contractStatusEnum?.Approved
                    }
                  />
                </Grid>
                <Grid item md={gridValue}>
                  <FmSearchableSelect
                    name='divisionId'
                    control={control}
                    apiUrl='divisions'
                    valueField='divisionId'
                    labelField={['divisionCode', 'divisionName']}
                    showField={['divisionCode', 'divisionName']}
                    label='Division'
                    defaultValue={{
                      divisionId: editData?.divisionId,
                      divisionName: editData?.divisionName,
                    }}
                    required
                    disabled={
                      editData?.contractStatusId ===
                      contractStatusEnum?.Approved
                    }
                  />
                </Grid>
                <Grid item md={gridValue}>
                  <FmSearchableSelect
                    name='locationId'
                    control={control}
                    apiUrl='locations'
                    label='Location'
                    valueField='locationId'
                    pageSize={1000}
                    labelField={['locationCode', 'locationName']}
                    showField={['locationCode', 'locationName']}
                    defaultValue={{
                      locationId: editData?.locationId,
                      locationName: editData?.locationName,
                    }}
                    disabled={
                      editData?.contractStatusId ===
                      contractStatusEnum?.Approved
                    }
                  />
                </Grid>
                <Grid item md={gridValue}>
                  <FmDatePicker
                    name='contractStartDate'
                    control={control}
                    label='Contract Start Date'
                    disabled={
                      editData?.contractStatusId ===
                      contractStatusEnum?.Approved
                    }
                  />
                </Grid>
                <Grid item md={gridValue}>
                  <FmDatePicker
                    name='contractEndDate'
                    control={control}
                    label='Contract End Date'
                    minDate={dayjs(watch('contractStartDate'))}
                    disabled={
                      editData?.contractStatusId ===
                      contractStatusEnum?.Approved
                    }
                  />
                </Grid>
                <Grid item md={gridValue}>
                  <FmTextField
                    name='contactPerson'
                    control={control}
                    label='Contact Person'
                    required
                    disabled={
                      editData?.contractStatusId ===
                      contractStatusEnum?.Approved
                    }
                  />
                </Grid>
                <Grid item md={gridValue}>
                  <FmTextField
                    name='contactNumber'
                    control={control}
                    label='Contact Number'
                    pattern='Number'
                    required
                    disabled={
                      editData?.contractStatusId ===
                      contractStatusEnum?.Approved
                    }
                  />
                </Grid>
                <Grid item md={gridValue}>
                  <FmTextField
                    name='contactEmailId'
                    control={control}
                    label='Contact Email'
                    pattern='Email'
                    required
                    disabled={
                      editData?.contractStatusId ===
                      contractStatusEnum?.Approved
                    }
                  />
                </Grid>
                <Grid item md={gridValue}>
                  <FmCurrencyTextField
                    name='contractCost'
                    label='Contract Cost'
                    control={control}
                    required
                    disabled={
                      editData?.contractStatusId ===
                      contractStatusEnum?.Approved
                    }
                  />
                </Grid>
                <Grid item md={gridValue}>
                  <FmCurrencyTextField
                    name='budget'
                    label='Budget'
                    control={control}
                    required
                    disabled={
                      editData?.contractStatusId ===
                      contractStatusEnum?.Approved
                    }
                  />
                </Grid>
                <Grid item md={gridValue}>
                  <FmTextField
                    name='contractTerms'
                    label='Contract Terms'
                    control={control}
                    required
                    multiline={true}
                    disabled={
                      editData?.contractStatusId ===
                      contractStatusEnum?.Approved
                    }
                  />
                </Grid>
                <Grid item md={gridValue}>
                  <FmSearchableSelect
                    name='perviousContractId'
                    control={control}
                    apiUrl='contracts'
                    valueField='contractId'
                    labelField={['contractCode', 'contractName']}
                    showField={['contractCode', 'contractName']}
                    label={'Previous Contract Code'}
                    disabled={
                      editData?.contractStatusId ===
                      contractStatusEnum?.Approved
                    }
                  />
                </Grid>
                <Grid item md={gridValue}>
                  <FmAutoComplete
                    name='contractModeId'
                    control={control}
                    label='Contract Mode'
                    options={contractmodes}
                    optionFields={['contractModeName']}
                    displayField='contractModeName'
                    valueKey='contractModeId'
                    required
                    disabled={
                      editData?.contractStatusId ===
                      contractStatusEnum?.Approved
                    }
                  />
                </Grid>

                <Grid item md={gridValue}>
                  <FmTextField
                    name='referenceNumber'
                    control={control}
                    label='Reference Number'
                    required
                    disabled={
                      editData?.contractStatusId ===
                      contractStatusEnum?.Approved
                    }
                  />
                </Grid>
                <Grid item md={gridValue}>
                  <FmAutoComplete
                    name='contractTypeId'
                    control={control}
                    label='Contract Types'
                    options={contracttypes}
                    optionFields={['contractTypeName']}
                    displayField='contractTypeName'
                    valueKey='contractTypeId'
                    required
                    disabled={
                      editData?.contractStatusId ===
                      contractStatusEnum?.Approved
                    }
                  />
                </Grid>
                <Grid item md={gridValue * 2}>
                  <FmAutoComplete
                    name='contractScopeIds'
                    control={control}
                    label='Contract Scopes'
                    options={contractscopes}
                    optionFields={['contractScopeName']}
                    displayField='contractScopeName'
                    valueKey='contractScopeId'
                    isMultiSelect
                    disabled={
                      editData?.contractStatusId ===
                      contractStatusEnum?.Approved
                    }
                  // defaultValue={contractScopeIds}
                  />
                </Grid>
                <Grid item md={gridValue}>
                  <FmAutoComplete
                    name='billingFrequencyId'
                    control={control}
                    label='Billing Frequency'
                    options={billingfrequencies}
                    optionFields={['billingFrequencyName']}
                    displayField='billingFrequencyName'
                    valueKey='billingFrequencyId'
                    disabled={
                      editData?.contractStatusId ===
                      contractStatusEnum?.Approved
                    }
                  />
                </Grid>
                <Grid item md={gridValue}>
                  <FmCurrencyTextField
                    name='thresholdValue'
                    control={control}
                    label='Threshold Value'
                    disabled={
                      editData?.contractStatusId ===
                      contractStatusEnum?.Approved
                    }
                  />
                </Grid>
                <Grid item md={gridValue}>
                  <FmAutoComplete
                    name='currencyId'
                    control={control}
                    label='Currrencies'
                    options={currencies}
                    optionFields={['currencyName']}
                    displayField='currencyName'
                    valueKey='currencyId'
                    disabled={
                      editData?.contractStatusId ===
                      contractStatusEnum?.Approved
                    }
                  />
                </Grid>
                <Grid item md={gridValue * 2}>
                  <FmAutoComplete
                    name='guaranteeIds'
                    control={control}
                    label='Guarantees'
                    options={guarantees}
                    optionFields={['guaranteeName']}
                    displayField='guaranteeName'
                    valueKey='guaranteeId'
                    isMultiSelect
                    defaultValue={guaranteeIds}
                    disabled={
                      editData?.contractStatusId ===
                      contractStatusEnum?.Approved
                    }
                  />
                </Grid>
                <Grid item md={gridValue}>
                  <FmDatePicker
                    name='guaranteeStartDate'
                    control={control}
                    label='Guarantee Start Date'
                    disabled={
                      editData?.contractStatusId ===
                      contractStatusEnum?.Approved
                    }
                  />
                </Grid>
                <Grid item md={gridValue}>
                  <FmDatePicker
                    name='guaranteeEndDate'
                    control={control}
                    label='Guarantee End Date'
                    minDate={dayjs(watch('guaranteeStartDate'))}
                    disabled={
                      editData?.contractStatusId ===
                      contractStatusEnum?.Approved
                    }
                  />
                </Grid>
                <Grid item md={gridValue}>
                  <FmCurrencyTextField
                    name='guaranteeAmount'
                    control={control}
                    label='Guarantee Amount'
                    disabled={
                      editData?.contractStatusId ===
                      contractStatusEnum?.Approved
                    }
                  />
                </Grid>
                <Grid item md={gridValue}>
                  <FmAutoComplete
                    name='status'
                    control={control}
                    label='Status'
                    options={statusOptions}
                    optionFields={['statusName']}
                    displayField='statusName'
                    valueKey='statusId'
                    disabled={
                      editData?.contractStatusId ===
                      contractStatusEnum?.Approved
                    }
                  />
                </Grid>
                <Grid item md={gridValue}>
                  <FmAutoComplete
                    name='contractStatusId'
                    control={control}
                    label='Contract Status'
                    options={contractStatus}
                    optionFields={['contractStatusName']}
                    displayField='contractStatusName'
                    valueKey='contractStatusId'
                    defaultValue={
                      editData?.contractStatusId
                        ? {
                          contractStatusId: editData?.contractStatusId,
                          contractStatusName: editData?.contractStatusName,
                        }
                        : contractStatus.find(
                          (status) => status.contractStatusId === 1
                        ) // Default to "Draft"
                    }
                    disabled={
                      editData?.contractStatusId ===
                      contractStatusEnum?.Approved
                    }
                  />
                </Grid>
                <Grid item md={gridValue}>
                  <FmAutoComplete
                    name='typeId'
                    control={control}
                    options={projectTypes}
                    label='Project Type'
                    displayField='projectTypeName'
                    optionFields={['projectTypeName']}
                    valueKey='projectTypeId'
                    required
                    disabled={
                      editData?.contractStatusId ===
                      contractStatusEnum?.Approved
                    }
                  />
                </Grid>
                {projectTypeId === projectStatusEnum?.AMC && (
                  <Grid item md={gridValue}>
                    <FormControl
                      component="fieldset"
                      error={!!errors?.retention}
                      className="flex flex-col"
                    >
                      <div className="flex items-center">
                        <Controller
                          name="retention"
                          control={control}
                          rules={{
                            validate: (value) =>
                              value || "Retention is required",
                          }}
                          render={({ field: { onChange, value, ...field } }) => (
                            <Checkbox
                              {...field}
                              checked={Boolean(value)} // This will be true based on payload
                              onChange={(e) => onChange(e.target.checked)}
                              color="primary"
                            />
                          )}
                        />
                        <Box className="mb-1 font-semibold">Retention</Box>
                      </div>
                      {errors?.retention && (
                        <FormHelperText>{errors.retention.message}</FormHelperText>
                      )}
                    </FormControl>
                  </Grid>
                )}
                {projectTypeId === projectStatusEnum?.AMC && retentionChecked && (
                  <Grid item md={gridValue}>
                    <FmTextField
                      name="retentionPercentage"
                      control={control}
                      label="Retention(%)"
                      required
                    />
                  </Grid>
                )}
                <Grid item md={gridValue * 3}>
                  <FmTextField
                    name='remarks'
                    control={control}
                    label='Remarks'
                    multiline={true}
                    rows={2}
                    maxRows={3}
                    disabled={
                      editData?.contractStatusId ===
                      contractStatusEnum?.Approved
                    }
                  />
                </Grid>
              </Grid>
              <br />
              {!(
                editData?.contractStatusId === contractStatusEnum?.Approved
              ) && (
                  <ActionButtons
                    onSubmit={handleSubmit(handleSubmitProject)}
                    onCancel={() => navigate(-1)}
                    onReset={reset}
                    submitLoading={createLoading}
                    cancelLoading={false}
                    submitText='Update'
                  />
                )}
            </LocalizationProvider>
          </form>
        )}
      </GlassCard>
    </DashboardLayout>
  );
};

export default EditContracts;
