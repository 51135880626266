import { Icon } from '@iconify/react/dist/iconify.js';
import { Box } from '@mui/material';
import React, { useState } from 'react';
import { useNavigate } from 'react-router-dom';
import TableActionButtons from '../_form/TableActionButtons.jsx';
import GlassCard from '../small/GlassCard.tsx';
import FmMRTDataTable from '../FmMRTDataTable.tsx';
import useTableLogic from '../helpers/MRTUseTableLogic.tsx';
import DashboardLayout from '../DashboardLayout.tsx';
import ReportDesigner from './ReportDesigner.tsx';
import BackToButton from '../small/BackToButton.tsx';
import Crud_Service from '../../apis/CrudService.jsx';
import { toast } from 'react-toastify';

const ListReportTemplates = () => {
  const navigate = useNavigate();
  const [selectedType, setSelectedType] = useState<number>(0);
  const {
    rows,
    isLoading,
    pagination,
    fetchData,
    sorting,
    columnFilters,
    globalFilter,
    tableRecordCounts,
    setPagination,
    setSorting,
    setColumnFilters,
    setGlobalFilter,
    setSearchKeyword,
    searchKeyword,
  } = useTableLogic('stimulsoftreports');
  const crud = new Crud_Service();

  const handleEdit = (row) => {
    setSelectedType(row.stimulsoftReportId);
  };

  const handleDelete = (row) => {
    crud.remove('stimulsoftreports', row.stimulsoftReportId, (err, res) => {
      if (res?.status === 204) {
        fetchData();
      } else {
        toast.error('something_Went_Wrong!');
      }
    });
  };

  const handleDuplicate = (row) => {
    crud.create(
      `stimulsoftreports/${row?.stimulsoftReportId}`,
      '',
      (err, res) => {
        if (res?.status === 201) {
          fetchData();
        }
      }
    );
  };

  const ActionData = [
    {
      name: 'Duplicate',
      icon: <Icon icon='solar:copy-broken' />,
      onClick: (props) => handleDuplicate(props),
    },
    {
      name: 'Edit',
      icon: <Icon icon='solar:pen-2-broken' />,
      onClick: (props) => handleEdit(props),
    },
    {
      name: 'Delete',
      icon: <Icon icon='solar:trash-bin-minimalistic-broken' />,
      onClick: (props) => handleDelete(props),
      danger: true,
    },
  ];

  // Define the columns
  const columns = [
    {
      header: 'Actions',
      enableColumnPinning: true,
      enableEditing: false,
      enableSorting: false,
      Cell: ({ row }) => (
        <Box className='flex gap-2'>
          <TableActionButtons
            Actions={ActionData?.map((action) => ({
              ...action,
              onClick: () => action.onClick(row?.original),
            }))}
          />
        </Box>
      ),
    },
    {
      accessorKey: 'reportTypeName',
      header: 'Type',
    },
    {
      accessorKey: 'reportName',
      header: 'Name',
      width: 250,
    },
    {
      accessorKey: 'clientName',
      header: 'Company',
    },
    {
      accessorKey: 'clientName',
      header: 'Client',
      width: 300,
    },
  ];

  return (
    <>
      <DashboardLayout title='Report Template List'>
        {selectedType ? (
          <>
            <BackToButton title={'Back'} onClick={() => setSelectedType(0)} />
            <ReportDesigner
              isAdmin
              reportTemplate={rows.find(
                (rows) => rows.stimulsoftReportId === selectedType
              )}
            />
          </>
        ) : (
          <GlassCard className='overflow-hidden h-full-css'>
            <FmMRTDataTable
              columns={columns}
              enableRowSelection={false}
              rows={rows}
              rowCount={tableRecordCounts}
              pagination={pagination}
              sorting={sorting}
              fetchData={fetchData}
              columnFilters={columnFilters}
              fetchData={fetchData}
              globalFilter={globalFilter}
              setPagination={setPagination}
              setSorting={setSorting}
              setColumnFilters={setColumnFilters}
              setGlobalFilter={setGlobalFilter}
              isLoading={isLoading}
              setSearchKeyword={setSearchKeyword}
              searchKeyword={searchKeyword}
            />
          </GlassCard>
        )}
      </DashboardLayout>
    </>
  );
};

export default ListReportTemplates;
