import { yupResolver } from '@hookform/resolvers/yup';
import { Grid } from '@mui/material';
import dayjs from 'dayjs';
import React from 'react';
import { useForm } from 'react-hook-form';
import { useTranslation } from 'react-i18next';
import { useNavigate } from 'react-router-dom';
import { toast } from 'react-toastify';
import Crud_Service from '../../../../apis/CrudService.jsx';
import { ActionButtons } from '../../../../components/_form/index.jsx';
import FmDatePicker from '../../../../components/_mui/FmDatePicker.tsx';
import FmSearchableSelect from '../../../../components/_mui/FmSearchableSelect.tsx';
import FmTextField from '../../../../components/_mui/FmTextField.tsx';
import DashboardLayout from '../../../../components/DashboardLayout.tsx';
import useLoader from '../../../../components/helpers/UseLoader.tsx';
import GlassCard from '../../../../components/small/GlassCard.tsx';
import { VehicleAdvertisementSchema } from '../../../../utils/CommonvalidationSchemas.tsx';

const GridSize = 2.4;
const AddAdvertisement = () => {
  const crud = new Crud_Service();
  const navigate = useNavigate();
  const { t } = useTranslation();
  const { control, handleSubmit, reset, watch, trigger } = useForm({
    resolver: yupResolver(VehicleAdvertisementSchema),
    mode: 'onChange',
  });
  const { createLoading, startCreateLoading, stopCreateLoading } = useLoader();

  const handleReset = () => {
    reset();
  };

  const submitAdvertisement = async (values) => {
    startCreateLoading();
    const combinedData = {
      ...values,
      issueDate: dayjs(values?.issueDate).format('YYYY-MM-DD'),
      expiryDate: dayjs(values?.expiryDate).format('YYYY-MM-DD'),
    };
    await crud.create('vehicleadvertisements', combinedData, (err, res) => {
      if (res?.status === 201) {
        toast.success('Advertisement Created Successfully!');
        stopCreateLoading();
        reset();
        navigate(-1);
      } else {
        stopCreateLoading();
      }
    });
  };
  const issueDate = watch('issueDate');
  return (
    <DashboardLayout title='Create Advertisement'>
      <GlassCard className='p-3'>
        <form onSubmit={handleSubmit(submitAdvertisement)}>
          <Grid container spacing={2}>
            <Grid item md={GridSize}>
              <FmSearchableSelect
                name='vehicleId'
                label='Vehicle'
                apiUrl={'vehicles'}
                valueField='vehicleId'
                required
                labelField={['vehicleCode', 'vehicleName']}
                showField={['vehicleCode', 'vehicleName']}
                control={control}
              />
            </Grid>
            <Grid item md={GridSize}>
              <FmTextField
                name='permitNumber'
                required
                label='Permit Number'
                control={control}
                pattern='Number'
              />
            </Grid>
            <Grid item md={GridSize}>
              <FmDatePicker
                name='issueDate'
                label='Issue Date'
                control={control}
                required
                onChangeProp={() => trigger('expiryDate')}
              />
            </Grid>
            <Grid item md={GridSize}>
              <FmDatePicker
                name='expiryDate'
                label='Expiry Date'
                control={control}
                onChangeProp={() => trigger('issueDate')}
                defaultValue={dayjs().add(1, 'day')}
                minDate={issueDate}
                required
              />
            </Grid>

            <Grid item md={GridSize}>
              <FmTextField
                name='remarks'
                label='Remarks'
                required
                control={control}
                multiline
                rows={4}
              />
            </Grid>
            <Grid item md={12}>
              <ActionButtons
                onSubmit={handleSubmit(submitAdvertisement)}
                onReset={handleReset}
                submitLoading={createLoading}
                submitText='Create'
                cancelText='Go Back'
                onCancel={() => navigate(-1)}
              />
            </Grid>
          </Grid>
        </form>
      </GlassCard>
    </DashboardLayout>
  );
};

export default AddAdvertisement;
