import { yupResolver } from '@hookform/resolvers/yup';
import { Box, Grid } from '@mui/material';
import dayjs from 'dayjs';
import React, { useEffect } from 'react';
import { useForm } from 'react-hook-form';
import { useNavigate, useParams } from 'react-router-dom';
import { toast } from 'react-toastify';
import * as Yup from 'yup';
import { object } from 'yup';
import Crud_Service from '../../../../../apis/CrudService.jsx';
import FmDatePicker from '../../../../../components/_mui/FmDatePicker.tsx';
import FmSearchableSelect from '../../../../../components/_mui/FmSearchableSelect.tsx';
import GlassCard from '../../../../../components/small/GlassCard.tsx';
import { ViewField } from '../../../../../components/_form/FormElements.jsx';
import ActionButtons from '../../../../../components/_form/ActionButtons.tsx';
import useLoader from '../../../../../components/helpers/UseLoader.tsx';
import FmStack from '../../../../../components/layout/FmStack.tsx';
import { Icon } from '@iconify/react/dist/iconify.js';
import GlassCardCollapse from './../../../../../components/small/GlassCardCollapse.tsx';

const IconsListing = [
  {
    icon: <Icon icon='fluent:book-database-20-regular' />,
  },
  {
    icon: <Icon icon='tabler:building' />,
  },
  {
    icon: <Icon icon='fluent:building-people-16-regular' />,
  },
  {
    icon: <Icon icon='fluent:book-number-24-regular' />,
  },
  {
    icon: <Icon icon='fluent:notebook-question-mark-20-regular' />,
  },
];

const validationSchema = object({
  clientId: Yup.number().required('Client is required'),
  salesOrderId: Yup.number().required('Sales Order is required'),
}).required();

const AddInternalInvoiceAR = ({ editMode }) => {
  const {
    createLoading,
    startCreateLoading,
    stopCreateLoading,
    editLoading,
    startEditLoading,
    stopEditLoading,
  } = useLoader();
  const GridValue = 3;
  const navigate = useNavigate();
  const {
    handleSubmit,
    control,
    reset,
    formState: { isValid },
  } = useForm({
    resolver: yupResolver(validationSchema),
    mode: 'onSubmit',
  });
  const id = useParams()?.id;

  const crud = new Crud_Service();

  const [invoice, setInvoice] = React.useState({});
  const getSalesInvoice = async () => {
    await crud.getSingle(`internalinvoices`, id, (_err, res) => {
      if (res?.status === 200) {
        reset(res?.data);
        setInvoice(res?.data);
        setSelectedClient(res?.data?.clientId);
        setSelectedSalesOrder(res?.data);
      }
    });
  };

  useEffect(() => {
    id && getSalesInvoice();
  }, [id]);

  const [selectedClient, setSelectedClient] = React.useState(null);
  const [selectedSalesOrder, setSelectedSalesOrder] = React.useState(null);
  const handleSelectedClient = (data) => {
    setSelectedClient(data?.clientId);
  };
  const handleSelectedSalesOrder = (data) => {
    setSelectedSalesOrder(data);
  };

  const onSubmitReadyForInvoice = async (data) => {
    startCreateLoading();
    const values = {
      ...data,
      invoiceStatusId: 3,
      internalInvoiceDate: dayjs(data?.internalInvoiceDate).format(
        'YYYY-MM-DD'
      ),
      dueDate: dayjs(data?.dueDate).format('YYYY-MM-DD'),
    };
    if (id) {
      await crud.update('internalinvoices', id, values, (_err, res) => {
        if (res?.status === 200) {
          toast.success('Internal Invoice Updated Successfully');
          navigate(-1);
          stopCreateLoading();
        }
      });
    } else {
      await crud.create('internalinvoices', values, (_err, res) => {
        if (res?.status === 201) {
          toast.success('Internal Invoice Created Successfully');
          stopCreateLoading();
          reset();
          navigate(-1);
        }
      });
    }
  };
  const onSubmitDraft = async (data) => {
    startEditLoading();
    const values = {
      ...data,
      invoiceStatusId: 2,
      dueDate: dayjs(data?.dueDate).format('YYYY-MM-DD'),
      internalInvoiceDate: dayjs(data?.internalInvoiceDate).format(
        'YYYY-MM-DD'
      ),
      salesInvoiceDate: dayjs(data?.salesInvoiceDate).format('YYYY-MM-DD'),
    };
    if (id) {
      await crud.update('internalinvoices', id, values, (_err, res) => {
        if (res?.status === 200) {
          toast.success('Sales Invoice Updated Successfully');
          navigate(-1);
          stopEditLoading();
        }
      });
    } else {
      await crud.create('internalinvoices', values, (_err, res) => {
        if (res?.status === 201) {
          toast.success('Sales Invoice Created Successfully');
          stopEditLoading();
          reset();
          navigate('../edit/' + res?.data?.internalInvoiceId);
        }
      });
    }
  };

  return (
    <GlassCardCollapse title={editMode ? 'Edit Internal Invoice' : 'New Internal Invoice'} collapsed={editMode}>
      <Box className='w-full'>
        <Grid container spacing={2}>
          <Grid item md={9}>
           
              <form>
                <FmStack spacing={2}>
                  <Box>
                    <Grid container spacing={2}>
                      <Grid item md={GridValue * 2}>
                        <FmSearchableSelect
                          name='clientId'
                          control={control}
                          apiUrl='clients'
                          valueField='clientId'
                          labelField={['clientName', 'clientCode']}
                          showField={['clientName', 'clientCode']}
                          label='Client'
                          onChangeProp={handleSelectedClient}
                          defaultValue={{
                            clientId: invoice?.clientId,
                            clientName: invoice?.clientName,
                          }}
                          required
                        />
                      </Grid>
                      <Grid item md={GridValue * 2}>
                        <FmSearchableSelect
                          name='salesOrderId'
                          control={control}
                          apiUrl='salesorders'
                          valueField='salesOrderId'
                          headerField={['Sales Order', 'Project', 'Contact']}
                          labelField={[
                            'salesOrderCode',
                            'projectName',
                            'contractName',
                          ]}
                          showField={['salesOrderCode', 'salesOrderName']}
                          stackedFields={{
                            salesOrderCode: [
                              { Code: 'salesOrderCode' },
                              { Name: 'salesOrderName' },
                            ],
                            projectName: [
                              { Code: 'projectCode' },
                              { Name: 'projectName' },
                            ],
                            contractName: [
                              { Code: 'contractCode' },
                              { Name: 'contractName' },
                            ],
                          }}
                          queryparam='clientId'
                          queryparamValue={selectedClient}
                          // disabled={selectedClient ? false : true}
                          onChangeProp={handleSelectedSalesOrder}
                          label='Sales Order'
                          defaultValue={{
                            salesOrderId: invoice?.salesInvoiceId,
                            salesOrderCode: invoice?.salesOrderCode,
                          }}
                          required
                        />
                      </Grid>
                      <Grid item md={GridValue}>
                        <FmSearchableSelect
                          name='jurisdictionId'
                          control={control}
                          apiUrl='jurisdictions'
                          labelField={['jurisdictionCode', 'jurisdictionName']}
                          showField={['jurisdictionCode', 'jurisdictionName']}
                          defaultValue={{
                            jurisdictionId: invoice?.juridicationId,
                            jurisdictionName: invoice?.jurisdictionName,
                          }}
                          valueField='jurisdictionId'
                          label={'Jurisdiction'}
                        />
                      </Grid>
                      <Grid item md={GridValue}>
                        <FmSearchableSelect
                          name='branchId'
                          control={control}
                          apiUrl='branches'
                          valueField='branchId'
                          labelField={['branchCode', 'branchName']}
                          defaultValue={{
                            emirateId: invoice?.branchId,
                            branchName: invoice?.branchName,
                          }}
                          showField={['branchCode', 'branchName']}
                          label={'Branch'}
                        />
                      </Grid>
                    </Grid>
                  </Box>

                  <Box>
                    <Grid container spacing={2}>
                      <Grid item md={GridValue}>
                        <FmDatePicker
                          name='internalInvoiceDate'
                          label='Invoice Date'
                          control={control}
                        />
                      </Grid>
                      <Grid item md={GridValue}>
                        <FmDatePicker
                          name='dueDate'
                          label='Due Date'
                          control={control}
                        />
                      </Grid>
                      <Grid item md={GridValue}>
                        <FmSearchableSelect
                          name='currencyId'
                          label='Currency'
                          apiUrl={'currencies'}
                          valueField='currencyId'
                          labelField={['currencyCode', 'currencyName']}
                          showField={['currencyCode', 'currencyName']}
                          control={control}
                          defaultValue={{
                            currencyId: invoice?.currencyId,
                            currencyName: invoice?.currencyName,
                          }}
                        />
                      </Grid>
                    </Grid>
                  </Box>

                  <Box className='flex gap-3 w-full justify-end'>
                    <ActionButtons
                      onSubmit={handleSubmit(onSubmitReadyForInvoice)}
                      onCancel={() => navigate(-1)}
                      submitLoading={createLoading}
                      submitText={'Ready For Invoice'}
                      disabled={!isValid}
                      removeSpacing
                    />
                    <ActionButtons
                      onSubmit={handleSubmit(onSubmitDraft)}
                      submitLoading={editLoading}
                      submitText={'Draft'}
                      disabled={!isValid}
                      color='secondary'
                      removeSpacing
                    />
                  </Box>
                </FmStack>
              </form>
            
          </Grid>
          <Grid item md={3}>
            <GlassCard className='p-2' bgDark>
              <Grid container spacing={1}>
                {[
                  'contractName',
                  'projectName',
                  'inCharge',
                  'PO.No',
                  'paymentTerms',
                ].map((key, i) => (
                  <Grid item md={12} key={key}>
                    <Box className='flex items-center gap-3'>
                      <Box className='text-xl' color={'primary.main'}>
                        {IconsListing[i].icon}
                      </Box>
                      <ViewField
                        label={key.replace(/([A-Z])/g, ' $1').trim()} // Converts camelCase to readable label
                        title={selectedSalesOrder?.[key] || 'N/A'} // Safely access the key or show fallback
                      />
                    </Box>
                  </Grid>
                ))}
              </Grid>
            </GlassCard>
          </Grid>
        </Grid>
      </Box>
    </GlassCardCollapse>
  );
};

export default AddInternalInvoiceAR;
