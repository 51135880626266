import React, { useEffect } from 'react';
import GlassCard from '../../../../components/small/GlassCard.tsx';
import BackToButton from '../../../../components/small/BackToButton.jsx';
import { useNavigate, useLocation, useParams } from 'react-router-dom';
import { Grid } from '@mui/material';
import DashboardLayout from '../../../../components/DashboardLayout.tsx';
import ViewVendorLPOList from './EditVendorInvoiceList.tsx';
import { yupResolver } from '@hookform/resolvers/yup';
import dayjs from 'dayjs';
import { useForm } from 'react-hook-form';
import { toast } from 'react-toastify';
import Crud_Service from '../../../../apis/CrudService.jsx';
import ActionButtons from '../../../../components/_form/ActionButtons.tsx';
import FmDatePicker from '../../../../components/_mui/FmDatePicker.tsx';
import FmSearchableSelect from '../../../../components/_mui/FmSearchableSelect.tsx';
import FmTextField from '../../../../components/_mui/FmTextField.tsx';
import ViewFieldInline from '../../../../components/view/ViewFieldInline.tsx';
import { VendorInvoiceSchema } from '../../../../utils/CommonvalidationSchemas.tsx';
import { Box } from '@mui/material';

const GridSize = 2.4;

const EditVendorInvoiceReceivable = () => {
  const navigation = useNavigate();
  const location = useLocation();
  const crud = new Crud_Service();
  const [data, getData] = React.useState(false);
  const [selectedUser, setSelectedUser] = React.useState({});
  const id = useParams().id;

  const { handleSubmit, control, reset } = useForm({
    resolver: yupResolver(VendorInvoiceSchema),
    mode: 'onChange',
  });

  const getVI = async () => {
    await crud.getSingle('vendorinvoices', id, (_err, res) => {
      setSelectedUser(res?.data);
      reset(res?.data);
    });
  };

  useEffect(() => {
    getVI();
    document.title = 'Edit | Vendor Invoice Receivable | Financial';
  }, []);

  const handleSubmitVendorInvoice = async (data: any) => {
    const values = {
      ...data,
      vendorinvoiceDate: data?.vendorinvoiceDate?.format('YYYY-MM-DD'),
    };
    await crud.update('vendorinvoices', id, values, (_err, res) => {
      if (res && res.status === 201) {
        toast.success('Vendor Invoice Created Successfully');
        getData(true);
        reset();
        setSelectedUser({});
      }
    });
  };

  const handleUser = async (e: any) => {
    await crud.getSingle('employees', e?.employeeId, (_err, res) => {
      setSelectedUser(res?.data);
    });
  };

  return (
    <>
      <DashboardLayout
        title='Edit Vendor Invoice Receivable'
        actionButtons={
          <BackToButton
            title='Back to List'
            onClick={() => navigation(-1)}
            className='font-bold px-1 py-0'
          />
        }
        titleReverse
      >
        <Box className='flex flex-col gap-3 h-full-css'>
          {/* glasscard */}
          <GlassCard className='p-3'>
            <form onSubmit={handleSubmit(handleSubmitVendorInvoice)}>
              <Grid container spacing={1.5}>
                <Grid item md={GridSize}>
                  <FmTextField
                    name='referenceNo'
                    label='Reference #'
                    control={control}
                    type='number'
                  />
                </Grid>
                <Grid item md={GridSize}>
                  <FmDatePicker
                    name='vendorinvoiceDate'
                    label='Invoice Date'
                    control={control}
                    minDate={dayjs()}
                    variant='filled'
                  />
                </Grid>
                <Grid item md={GridSize}>
                  <FmSearchableSelect
                    name='receivedBy'
                    label='Received By'
                    apiUrl='loginusers'
                    onChangeProp={handleUser}
                    control={control}
                    valueField='loginUserId'
                    defaultValue={{
                      loginUserId: selectedUser?.receivedBy,
                      loginUserName: selectedUser?.receiverName,
                    }}
                    labelField={['emailId', 'loginUserName']}
                    showField={['loginUserName']}
                    required
                  />
                </Grid>

                <Grid item md={GridSize}>
                  <ViewFieldInline
                    name='Department'
                    value={selectedUser?.departmentName}
                    border
                  ></ViewFieldInline>
                </Grid>

                <Grid item md={GridSize}>
                  <ViewFieldInline
                    name='Designation'
                    value={selectedUser?.designationName}
                    border
                  ></ViewFieldInline>
                </Grid>

                <Grid item md={12}>
                  <ActionButtons
                    onSubmit={handleSubmit(handleSubmitVendorInvoice)}
                    onReset={() => {
                      reset();
                      setSelectedUser({});
                    }}
                    submitText='Create'
                    removeSpacing
                  />
                </Grid>
              </Grid>
            </form>
          </GlassCard>

          <ViewVendorLPOList getData={getData} data={data} />
        </Box>
      </DashboardLayout>
    </>
  );
};

export default EditVendorInvoiceReceivable;
