import { Icon } from '@iconify/react/dist/iconify.js';
import { Box } from '@mui/material';
import React from 'react';
import { FontSize } from './../../theme/FontSize';

const TextVisa = ({ text, color, expiryMessage }) => {
  return (
    <div className='w-full'>
      <div className='flex gap-1'>
        <Box className='font-semibold'>{text}</Box>
      </div>
      <Box fontSize={FontSize.bodyMinusOne} color={color}>
        {expiryMessage}
      </Box>
    </div>
  );
};

export default TextVisa;
