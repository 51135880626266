import React from 'react';
import { Box, Avatar, Tooltip } from '@mui/material';
import { colorOnlyClasses } from './../../utils/Colors/ColorClasses.tsx';

const TableAvatar = ({ name, img, desc, removeImage }) => {
  const initial = name ? name.charAt(0).toUpperCase() : '';

  return (
    <Box className='flex gap-2 flex-row items-center'>
      {!removeImage && (
        <Avatar
          alt={name}
          src={img}
          sx={{
            width: 22,
            height: 22,
            fontSize: 11,
            textTransform: 'uppercase',
            bgcolor: 'info.main',
            color: 'text.white',
            fontWeight: 400,
          }}
        >
          {initial}
        </Avatar>
      )}
      <Box>
        <p style={{ fontWeight: 500 }}>{name}</p>
        <p className='opacity-70' style={{ fontSize: '10px' }}>
          {desc}
        </p>
      </Box>
    </Box>
  );
};

export const TableAvatarStatus = ({ name, icon, desc, color, title }) => {
  return (
    <Box className='flex gap-2 flex-row items-center'>
      <Tooltip title={title} arrow>
        <Avatar
          alt={name}
          sx={{
            width: 22,
            height: 22,
            fontSize: 18,
            color: 'text.main',
            background: 'none',
          }}
          className={`${colorOnlyClasses[color]}`}
        >
          {icon}
        </Avatar>
      </Tooltip>
      <Box>
        <Box sx={{ fontWeight: 500, color: 'text.dark' }}>{name}</Box>
        <p className='opacity-70' style={{ fontWeight: 300 }}>
          {desc}
        </p>
      </Box>
    </Box>
  );
};

export default TableAvatar;
