// src/utils/usePermissions.ts
import { useMemo } from 'react';
import { permissionId } from '../CommonVariables.tsx';

const usePermissions = (menuPermission) => {
  return useMemo(() => {
    return {
      view: menuPermission.some(
        (permission) => permission.permissionId === permissionId.VIEW
      ),
      edit: menuPermission.some(
        (permission) => permission.permissionId === permissionId.EDIT
      ),
      delete: menuPermission.some(
        (permission) => permission.permissionId === permissionId.DELETE
      ),
      create: menuPermission.some(
        (permission) => permission.permissionId === permissionId.CREATE
      ),
      import: menuPermission.some(
        (permission) => permission.permissionId === permissionId.IMPORT
      ),
      export: menuPermission.some(
        (permission) => permission.permissionId === permissionId.EXPORT
      ),
    };
  }, [menuPermission]);
};

export default usePermissions;
