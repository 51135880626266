/* eslint-disable */
import React, { useState } from 'react';
import { Box, Grid } from '@mui/material';
import { AdapterDayjs } from '@mui/x-date-pickers/AdapterDayjs';
import { LocalizationProvider } from '@mui/x-date-pickers/LocalizationProvider';
import { ActionButtons } from '../../../components/_form';
import { useNavigate } from 'react-router-dom';
import Crud_Service from '../../../apis/CrudService';
import { useForm } from 'react-hook-form';
import { toast } from 'react-toastify';
import dayjs from 'dayjs';
import DashboardLayout from '../../../components/DashboardLayout.tsx';
import useLoader from '../../../components/helpers/UseLoader.tsx';
import FmTextField from '../../../components/_mui/FmTextField.tsx';
import FmSearchableSelect from '../../../components/_mui/FmSearchableSelect.tsx';
import GlassCard from '../../../components/small/GlassCard.tsx';
import { yupResolver } from '@hookform/resolvers/yup';
import { EstimationMEPSchema } from '../../../utils/CommonvalidationSchemas.tsx';
import FmDatePicker from '../../../components/_mui/FmDatePicker.tsx';

const CreateEstimationSheetForMEP = () => {
  const navigate = useNavigate();

  const { handleSubmit, control, reset } = useForm({
    resolver: yupResolver(EstimationMEPSchema),
    mode: 'onChange',
  });
  const { createLoading, startCreateLoading, stopCreateLoading } = useLoader();
  const crud = new Crud_Service();
  const [selectedTender, setSelectedTender] = useState(null);
  const [selectedProject, setSelectedProject] = useState(null);

  const handleSubmitAMC = async (values) => {
    startCreateLoading();
    const combinedData = {
      ...values,
      dateOfEnquiry: dayjs(values?.dateOfEnquiry).format('YYYY-MM-DD'),
      contractPeriod: Number(values?.contractPeriod),
      otherPercent: Number(values?.otherPercent),
      discountOverAllPercent: Number(values?.discountOverAllPercent),
    };

    await crud.create('estimationmepsheets', combinedData, (err, res) => {
      if (err) {
        stopCreateLoading();

        return;
      }
      if (res?.status === 201) {
        toast.success('Estimation Sheet MEP Created Successfully');
        navigate(-1);
        stopCreateLoading();
      } else {
        stopCreateLoading();
      }
    });
  };

  return (
    <>
      <DashboardLayout title='Create New Estimation Sheet MEP'>
        <Box className='mx-auto' sx={{ maxWidth: '1000px' }}>
          <GlassCard className='p-4'>
            <form onSubmit={handleSubmit(handleSubmitAMC)}>
              <LocalizationProvider dateAdapter={AdapterDayjs}>
                <Grid container spacing={2}>
                  <Grid item md={6}>
                    <FmSearchableSelect
                      name='tenderId'
                      control={control}
                      apiUrl='tenders'
                      valueField='tenderId'
                      labelField={['tenderCode', 'tenderName']}
                      showField={['tenderCode', 'tenderName']}
                      onChangeProp={setSelectedTender}
                      label='Tender'
                      required
                    />
                  </Grid>
                  <Grid item md={6}>
                    <FmSearchableSelect
                      name='projectId'
                      control={control}
                      apiUrl='projects'
                      valueField='projectId'
                      headerField={['Code', 'Name']}
                      queryparam='tenderId'
                      queryparamValue={selectedTender?.tenderId}
                      labelField={['projectCode', 'projectName']}
                      showField={['projectCode', 'projectName']}
                      onChangeProp={setSelectedProject}
                      label='Project'
                    />
                  </Grid>
                  <Grid item md={6}>
                    <FmSearchableSelect
                      name='clientId'
                      control={control}
                      apiUrl='clients'
                      valueField='clientId'
                      queryparam='projectId'
                      queryparamValue={selectedProject?.projectId}
                      headerField={['Code', 'Name']}
                      labelField={['clientCode', 'clientName']}
                      showField={['clientCode', 'clientName']}
                      label='Client'
                      required
                    />
                  </Grid>

                  <Grid item md={6}>
                    <FmSearchableSelect
                      name='buildingId'
                      control={control}
                      apiUrl='buildings'
                      valueField='buildingId'
                      headerField={['Code', 'Name']}
                      labelField={['buildingCode', 'buildingName']}
                      showField={['buildingCode', 'buildingName']}
                      label='Building'
                      required
                    />
                  </Grid>
                  <Grid item md={3}>
                    <FmDatePicker
                      name='dateOfEnquiry'
                      label='Date Of Enquiry'
                      control={control}
                      required
                    />
                  </Grid>
                  <Grid item md={3}>
                    <FmTextField
                      name='contractPeriod'
                      control={control}
                      label='Contract Period'
                      required
                    />
                  </Grid>
                  <Grid item md={3}>
                    <FmTextField
                      name='otherPercent'
                      control={control}
                      label='Other Percent(%)'
                      defaultValue={0}
                      pattern='Percentage'
                    />
                  </Grid>
                  <Grid item md={3}>
                    <FmTextField
                      name='discountOverAllPercent'
                      control={control}
                      label='Discount Percent(%)'
                      defaultValue={0}
                      pattern='Percentage'
                    />
                  </Grid>
                  <Grid item md={6}>
                    <FmTextField
                      name='existContract'
                      control={control}
                      label='Exist Contract'
                    />
                  </Grid>

                  <Grid item md={6}>
                    <FmSearchableSelect
                      name='siteVisitDoneBy'
                      control={control}
                      apiUrl='employees'
                      valueField='employeeId'
                      headerField={['Code', 'Name', 'Email']}
                      labelField={['employeeCode', 'fullName', 'emailId']}
                      labelField={['employeeCode', 'fullName', 'emailId']}
                      label='Site Vist Done By'
                      required
                    />
                  </Grid>
                  <Grid item md={6}>
                    <FmSearchableSelect
                      name='preparedBy'
                      control={control}
                      apiUrl='employees'
                      required
                      valueField='employeeId'
                      headerField={['Code', 'Name', 'Email']}
                      labelField={['employeeCode', 'fullName', 'emailId']}
                      labelField={['employeeCode', 'fullName', 'emailId']}
                      label='Prepared By'
                    />
                  </Grid>
                </Grid>
                <ActionButtons
                  onSubmit={handleSubmit(handleSubmitAMC)}
                  onCancel={() => navigate(-1)}
                  onReset={reset}
                  submitLoading={createLoading}
                  cancelLoading={false}
                  submitText='Create'
                />
              </LocalizationProvider>
            </form>
          </GlassCard>
        </Box>
      </DashboardLayout>
    </>
  );
};

export default CreateEstimationSheetForMEP;
