import React from 'react';
import { Box, Button } from '@mui/material';

const EnquiryButtonShape = ({ name, icon, count, active, color, onClick }) => {
  return (
    <Box
      className={`flex gap-2 items-center relative`}
      sx={{
        bgcolor: active ? 'info.main' : 'background.light',
        padding: '5px 10px',
        borderRadius: '5px',
        fontWeight: 400,
        color: active ? 'text.white' : 'text.main',
        zIndex: active && 1,
        border: '1px solid',
        borderColor: 'border.main',
        '& .count': {
          fontWeight: 700,
        },
        '&:hover': {
          bgcolor: active ? 'info.main' : 'background.white',
        },
      }}
      component={Button}
      type='button'
      onClick={onClick}
    >
      <Box className='text-xl'>{icon}</Box>
      <Box>{name}</Box>
      <Box className='count'>({count})</Box>
    </Box>
  );
};

export default EnquiryButtonShape;
