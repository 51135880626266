import { useEffect, useState } from 'react';
import { Controller, useForm } from 'react-hook-form';
import useLoader from '../../../../components/helpers/UseLoader.tsx';
import Crud_Service from '../../../../apis/CrudService';
import { Grid, FormHelperText } from '@mui/material';
import GlassCard from '../../../../components/small/GlassCard.tsx';
import { ActionButtons } from '../../../../components/_form';
import { toast } from 'react-toastify';
import FmTextField from '../../../../components/_mui/FmTextField.tsx';
import { DatePicker, LocalizationProvider } from '@mui/x-date-pickers';
import { AdapterDayjs } from '@mui/x-date-pickers/AdapterDayjs';
import axios from 'axios';
import FmSearchableSelect from '../../../../components/_mui/FmSearchableSelect.tsx';
import dayjs from 'dayjs';
import FmAutoComplete from '../../../../components/_mui/FmAutoComplete.tsx';
import { yupResolver } from '@hookform/resolvers/yup';
import * as Yup from 'yup';
import FmDatePicker from '../../../../components/_mui/FmDatePicker.tsx';
import { log } from 'util';

const EmployeeConfigration = (props) => {
  const { setEnabledTabs, setValues, createdEmployee, value } = props;

  const [companies, setCompanies] = useState([]);
  const [workStatus, setWorkStatus] = useState([]);
  const [resignationDateState, setResignationEndDate] = useState(null);
  const token = localStorage.getItem('token');
  const contractBrech = [
    {
      label: 'Yes',
      value: 1,
    },
    {
      label: 'No',
      value: 2,
    },
  ];
  const validationSchema = Yup.object({
    workStatusId: Yup.number().required('Work status ID is required'),
    noticePeriod: Yup.number()
      .required('Notice period is required')
      .typeError('Notice period is required'),
    probationEndDate: Yup.date()
      .required('Probation end date is required')
      .min(
        dayjs().startOf('day').toDate(),
        'Probation end date must be today or in the future'
      )
      .nullable(),

    probationMonths: Yup.number()
      .required('Probation months are required')
      .min(0, 'Probation months must be a positive number'),
  });
  const {
    handleSubmit,
    control,
    reset,
    setValue,
    setError,
    clearErrors,
    formState: { errors },
  } = useForm({
    resolver: yupResolver(validationSchema),
    mode: 'onChange',
    defaultValues: {
      probationEndDate: dayjs().toDate(),
    },
  });
  const { startCreateLoading, stopCreateLoading, createLoading } = useLoader();
  const crud = new Crud_Service();

  useEffect(() => {
    if (value === 2) {
      getCompanies();
      getWorkingStatus();
    }
  }, [value]);

  //get All Projects
  const getCompanies = async () => {
    await crud.getAll('companies', {}, (err, res) => {
      if (res?.status === 200) {
        setCompanies(res?.data?.data);
      } else {
      }
    });
  };

  const getWorkingStatus = async () => {
    await crud.getAll('workingstatuses', {}, (err, res) => {
      if (res?.status === 200) {
        setWorkStatus(res?.data?.data);
      } else {
      }
    });
  };

  const validateFields = () => {
    const tempErrors = {};
    // if (!formData.project) {
    //   tempErrors.project = "Please enter a project name";
    // }
    // if (!formData.costCenter) {
    //   tempErrors.costCenter = "Please enter a cost center";
    // }
    // setErrors(tempErrors);
    return Object.keys(tempErrors).length === 0;
  };

  const handleProjectSubmit = async (values) => {
    startCreateLoading();

    if (validateFields()) {
      const CombinedData = {
        ...values,
        employeeResignationRequestId: null,
        probationEndDate: dayjs(values?.probationEndDate).format('YYYY-MM-DD'),
        resignationDate:
          values?.resignationDate &&
          dayjs(values?.resignationDate).format('YYYY-MM-DD'),
        probationMonths: Number(values?.probationMonths),
        noticePeriod: Number(values?.noticePeriod),
      };

      const employeeId = createdEmployee?.employeeId;
      const employeeDetailId = createdEmployee?.employeeDetailId;

      const Base_URL = process.env.REACT_APP_BASE;
      const url = `${Base_URL}/employees/${employeeId}/employee-details/${employeeDetailId}/configure-detail`;

      try {
        const res = await axios.put(url, CombinedData, {
          headers: {
            Authorization: `Bearer ${token}`,
          },
        });

        if (res?.status === 200) {
          stopCreateLoading();
          toast.success('Employee Configuration Created Successfully');
          setValues(3);
          setEnabledTabs(1);
          setEnabledTabs(2);
          setEnabledTabs(3);
          setEnabledTabs(4);
          setEnabledTabs(5);
          setEnabledTabs(6);
        } else {
          stopCreateLoading();
        }
      } catch (err) {
        stopCreateLoading();
        toast.error('Error creating employee:', err);
      }
    }
  };

  return (
    <GlassCard className='p-4'>
      <form onSubmit={handleSubmit(handleProjectSubmit)}>
        <LocalizationProvider dateAdapter={AdapterDayjs}>
          <Grid container spacing={3}>
            <Grid item md={6}>
              <FmAutoComplete
                name='workStatusId'
                control={control}
                options={workStatus}
                label='Working Status'
                displayField='workingStatusName'
                optionFields={['workingStatusName']}
                valueKey='workingStatusId'
                required
              />
            </Grid>
            <Grid item md={6}>
              <Controller
                name='probationMonths'
                control={control}
                rules={{ required: 'Probation period is required' }}
                render={({ field }) => (
                  <>
                    <DatePicker
                      {...field}
                      label='Probation Period Months'
                      views={['month']}
                      value={
                        field.value ? dayjs().month(field.value - 1) : null
                      } // Use field.value from react-hook-form state
                      onChange={(newValue) => {
                        const month = newValue
                          ? dayjs(newValue).month() + 1
                          : null;
                        setValue('probationMonths', month);
                        clearErrors('probationMonths');
                      }}
                      slotProps={{
                        textField: {
                          error: !!errors.probationMonths,
                          helperText: errors.probationMonths?.message,
                          required: true,
                        },
                      }}
                    />
                    {/* {errors.probationMonths && (
                      <FormHelperText error>
                        {errors.probationMonths.message}
                      </FormHelperText>
                    )} */}
                  </>
                )}
              />
            </Grid>
            <Grid item md={6}>
              <FmDatePicker
                name='probationEndDate'
                label='Probation End date'
                control={control}
                minDate={dayjs()}
              />
            </Grid>
            <Grid item md={6}>
              <FmDatePicker
                name='resignationDate'
                label='Resignation End date'
                control={control}
                minDate={dayjs()}
                defaultValueNotRequired
              />
            </Grid>
            <Grid item md={6}>
              <FmTextField
                name='noticePeriod'
                label='Notice Period'
                control={control}
                pattern='NumberWithMonthRange'
                required
              />
            </Grid>
            <Grid item md={6}>
              <FmAutoComplete
                name='contractBreachId'
                control={control}
                options={contractBrech}
                label='Contract Breach'
                displayField='label'
                optionFields={['label']}
                valueKey='value'
              />
            </Grid>
            <Grid item md={6}>
              <FmSearchableSelect
                name='companyId'
                control={control}
                apiUrl='companies'
                valueField='companyId'
                labelField={['companyCode', 'companyName']}
                showField={['companyName']}
                label={'Companies'}
              />
            </Grid>
          </Grid>
        </LocalizationProvider>

        <ActionButtons
          onSubmit={handleSubmit(handleProjectSubmit)}
          onCancel={() => setValues(1)}
          onReset={reset}
          submitLoading={createLoading}
          cancelLoading={false}
        />
      </form>
    </GlassCard>
  );
};

export default EmployeeConfigration;
