import React, { useState } from 'react';
import GlassCard from '../../../../components/small/GlassCard.tsx';
import { Box, Grid } from '@mui/material';
import FmSearchableSelect from '../../../../components/_mui/FmSearchableSelect.tsx';
import { useForm } from 'react-hook-form';
import FmTextField from '../../../../components/_mui/FmTextField.tsx';
import ActionButtons from '../../../../components/_form/ActionButtons.tsx';
import Crud_Service from '../../../../apis/CrudService.jsx';
import useLoader from '../../../../components/helpers/UseLoader.tsx';
import { toast } from 'react-toastify';
import useTableLogic from '../../../../components/helpers/MRTUseTableLogic.tsx';
import ViewSalesOrderDistributionBudgetsTable from './ViewSalesOrderDistributionBudgetsTable.tsx';
import { yupResolver } from '@hookform/resolvers/yup';
import { SalesOrderBudgetvalidationSchema } from '../../../../utils/CommonvalidationSchemas.tsx';
import GlassCardCollapse from './../../../../components/small/GlassCardCollapse.tsx';

const MdItemValue = 2.4;

const ViewTotalValueList = (props) => {
  const { viewData, salesOrderDistributionId } = props;
  const {
    rows,
    isLoading,
    pagination,
    sorting,
    columnFilters,
    globalFilter,
    tableRecordCounts,
    setPagination,
    setSorting,
    setColumnFilters,
    setGlobalFilter,
    fetchData,
    setSearchKeyword,
    searchKeyword,
  } = useTableLogic(
    'salesorderdistributionbudgets',
    'salesOrderDistributionId',
    salesOrderDistributionId
  );
  const [] = useState(null);

  const { handleSubmit, control, reset, setValue } = useForm({
    resolver: yupResolver(SalesOrderBudgetvalidationSchema),
    mode: 'onChange',
  });
  const crud = new Crud_Service();
  const { createLoading, startCreateLoading, stopCreateLoading } = useLoader();
  const handleSubmitSalesOrderBudget = async (values) => {
    startCreateLoading();
    const combinedData = {
      ...values,
      salesOrderDistributionId: salesOrderDistributionId,
    };
    await crud.create(
      'salesorderdistributionbudgets',
      combinedData,
      (err, res) => {
        if (err) {
          stopCreateLoading();
          toast.error('Something went wrong:', err);
          return;
        }
        if (res?.status === 201) {
          toast.success('Sales Order Distribution Budget Created successfully');
          fetchData();
          reset();
          stopCreateLoading();
        } else {
          stopCreateLoading();
        }
      }
    );
  };

  return (
    <>
      <GlassCardCollapse title='Create New' className='my-2'>
        <form>
          <Box>
            <Grid container spacing={2}>
              <Grid item md={MdItemValue}>
                <FmSearchableSelect
                  name='divisionId'
                  control={control}
                  apiUrl='divisions'
                  valueField='divisionId'
                  headerField={['Code']}
                  labelField={['divisionCode']}
                  showField={['divisionCode']}
                  pageSize={20}
                  label='Division Code'
                  required
                />
              </Grid>
              <Grid item md={MdItemValue}>
                <FmTextField
                  name='totalContractValuePercentage'
                  control={control}
                  label='Total Contract Value Percentage'
                  pattern='Decimal'
                  required
                  onChange={(ev) => {
                    const percentage = parseFloat(ev.target.value);
                    const totalContractValue = viewData.totalContractValue;
                    if (!isNaN(percentage) && totalContractValue) {
                      setValue(
                        'totalContractValue',
                        ((percentage / 100) * totalContractValue).toFixed(2)
                      );
                    }
                  }}
                />
              </Grid>
              <Grid item md={MdItemValue}>
                <FmTextField
                  name='totalContractValue'
                  control={control}
                  label='Total Contract Value'
                  readOnly
                  pattern='Decimal'
                />
              </Grid>
              <Grid item md={MdItemValue}>
                <FmTextField
                  name='totalBudgetValuePercentage'
                  control={control}
                  label='Total Budget Value Percentage'
                  pattern='PercentageWithoutDecimal'
                  onChange={(ev) => {
                    const percentage = parseFloat(ev.target.value);
                    const totalBudgetValue = viewData.totalBudgetValue;
                    if (!isNaN(percentage) && totalBudgetValue) {
                      setValue(
                        'totalBudgetValue',
                        ((percentage / 100) * totalBudgetValue).toFixed(2)
                      );
                    }
                  }}
                />
              </Grid>
              <Grid item md={MdItemValue}>
                <FmTextField
                  name='totalBudgetValue'
                  control={control}
                  label='Total Budget'
                  pattern='Decimal'
                  readOnly
                />
              </Grid>
            </Grid>

            <ActionButtons
              onSubmit={handleSubmit(handleSubmitSalesOrderBudget)}
              onReset={reset}
              submitLoading={createLoading}
              cancelLoading={false}
              disabled={!viewData?.isWorkOrderCreated}
            // submitText='Create'
            />
          </Box>
        </form>
      </GlassCardCollapse>

      <GlassCard>
        <ViewSalesOrderDistributionBudgetsTable
          salesOrderDistributionId={salesOrderDistributionId}
          rows={rows}
          tableRecordCounts={tableRecordCounts}
          isLoading={isLoading}
          pagination={pagination}
          sorting={sorting}
          setPagination={setPagination}
          setSorting={setSorting}
          columnFilters={columnFilters}
          globalFilter={globalFilter}
          setColumnFilters={setColumnFilters}
          setGlobalFilter={setGlobalFilter}
          setSearchKeyword={setSearchKeyword}
          searchKeyword={searchKeyword}
          fetchData={fetchData}
        />
      </GlassCard>
    </>
  );
};

export default ViewTotalValueList;
