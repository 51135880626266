import React, { useState, useEffect } from 'react';
import { Box } from '@mui/material';
import { useParams } from 'react-router-dom';
import Crud_Service from '../../../../apis/CrudService';

const TicketsDetailsHistory = () => {
  const { id } = useParams();
  const [timelineData, setTimelineData] = useState([]);
  const crud = new Crud_Service();

  const fetchTicketTimeLine = async () => {
    await crud.getAll(`ticketactivities/${id}/ticket`, '', (err, res) => {
      if (res?.status === 200) {
        setTimelineData(res?.data?.data);
      }
    });
  };

  useEffect(() => {
    if (id) {
      fetchTicketTimeLine();
    }
  }, [id]);
  return (
    <Box className='mt-10 px-10'>
      <ol className='relative border-s border-orange-600'>
        {timelineData.map((activity, index) => {
          const parsedMessage = activity.message
            .replace(/^{"|"}$/g, '')
            .replace(/"/g, '')
            .replace(/,/g, '<br />');
          return (
            <li key={activity.ticketActivityId || index} className='mb-10 ms-10'>
              <div className='absolute w-4 h-4 bg-orange-600 rounded-full  -start-2 '></div>
              <time className='mb-1 opacity-60 font-normal'>{activity.createdDate}</time>
              <h3 className='text-base font-semibold'>{activity.createdByName}</h3>
              <p
                className="mb-4 font-normal opacity-60"
                dangerouslySetInnerHTML={{ __html: parsedMessage }}
              ></p>
            </li>
          );
        })}
      </ol>
    </Box>

  );
};

export default TicketsDetailsHistory;
