import React, { useEffect, useState } from 'react';
import { Grid } from '@mui/material';
import { useParams } from 'react-router-dom';
import Crud_Service from '../../../../apis/CrudService';
import GlassCard from '../../../../components/small/GlassCard.tsx';
import { useForm } from 'react-hook-form';
import FmSearchableSelect from '../../../../components/_mui/FmSearchableSelect.tsx';
import ActionButtons from '../../../../components/_form/ActionButtons.tsx';
import useLoader from '../../../../components/helpers/UseLoader.tsx';
import { toast } from 'react-toastify';
import FmTextField from '../../../../components/_mui/FmTextField.tsx';
import FmAutoComplete from '../../../../components/_mui/FmAutoComplete.tsx';
import dayjs from 'dayjs';
import { yupResolver } from '@hookform/resolvers/yup';
import { purchaseFormSchema } from '../../../../utils/CommonvalidationSchemas.tsx';
import useCommonFetchApi from '../../../../components/helpers/useCommonFetchApi.tsx';
import SkeletonLoader, {
  SkeletonLoaderRow,
} from '../../../../components/page/SkeletonLoader.tsx';
import UploadSquareBoxesNew from '../../../../components/_form/UploadSquareBoxesNew.tsx';
import FmDatePicker from '../../../../components/_mui/FmDatePicker.tsx';
import { useNavigate, useLocation } from 'react-router-dom';

const gridValue = 3;

const CreatePurchaseForms = (props) => {
  const { setLocalPurchaseOrderId, setEditData } = props;
  const { id } = useParams();
  const [editLoading, setEditLoading] = useState(true);
  const [editValues, setEditValues] = useState({});
  const [uploadGetData, setUploadGetData] = useState(null);
  const navigate = useNavigate();
  const location = useLocation();

  const trimLastPathSegment = (path) => {
    return path.substring(0, path.lastIndexOf('/')) || '/';
  };

  const crud = new Crud_Service();

  const {
    control,
    handleSubmit,
    reset,
    setValue,
    getValues,
    register,
    formState: { errors },
  } = useForm({
    resolver: yupResolver(purchaseFormSchema),
    mode: 'onChange',
  });
  const { startCreateLoading, stopCreateLoading, createLoading } = useLoader();

  //fetch APis
  const { data: localPurchaseOrderType } = useCommonFetchApi(
    'enums/local-purchase-order-type'
  );

  const { data: priorityLevel } = useCommonFetchApi('enums/priority-level');

  useEffect(() => {
    if (id) {
      getLocalPurchaseOrder();
    }
  }, [id]);

  const getLocalPurchaseOrder = async () => {
    setEditLoading(true);
    await crud.getSingle('localpurchaseorders', id, (err, res) => {
      if (err) {
        toast.error(err);
        setEditLoading(false);
      } else if (res?.status === 200) {
        Object.entries({ ...res?.data }).forEach(([key, value]) => {
          setValue(key, value);
        });
        setEditValues(res?.data);
        setEditData(res?.data);
        setEditLoading(false);
        setUploadGetData(res?.data?.localPurchaseOrderDocuments || []);
      } else {
        setEditLoading(false);
        toast.error('Something Went Wrong!');
      }
    });
  };

  const onSubmit = async (data) => {
    startCreateLoading();
    const formattedData = {
      ...data,
      lpoDate: dayjs(data.lpoDate).format('YYYY-MM-DD'),
      fileUploadIds: getValues('fileUploadIds') || [],
      updateLocalPurchaseOrderDocuments:
        getValues('updateLocalPurchaseOrderDocuments') || [],
    };

    if (id) {
      await crud.update(
        'localpurchaseorders',
        id,
        { ...formattedData, status: 1 },
        (err, res) => {
          if (res?.status === 200) {
            stopCreateLoading();
            toast.success('Purchase Order Updated Successfully');
            navigate(-1);

            reset();
          } else {
            stopCreateLoading();
            toast.error('Something went wrong');
          }
        }
      );
    } else {
      await crud.create('localpurchaseorders', formattedData, (err, res) => {
        if (res?.status === 201) {
          stopCreateLoading();
          toast.success('Purchase Order Created Successfully');
          navigate(-1);
          reset();
        } else {
          stopCreateLoading();
          toast.error('Something went wrong');
        }
      });
    }
  };

  useEffect(() => {
    register('fileUploadIds');
  }, [register]);

  // create
  const handleFileIdCreate = (fileId) => {
    if (typeof fileId === 'number') {
      const currentFiles = getValues('fileUploadIds') || [];
      const updatedFiles = [...currentFiles, fileId];
      setValue('fileUploadIds', updatedFiles);
    }
  };

  // update
  const handleFileUpdate = (fileId) => {
    const currentFiles = getValues('updateLocalPurchaseOrderDocuments') || [];

    const updatedFiles = [
      ...currentFiles,
      {
        localPurchaseOrderDocumentId: 0,
        fileId: fileId,
        status: 1,
      },
    ];

    setValue('updateLocalPurchaseOrderDocuments', updatedFiles);
  };

  const handleFileIdDelete = async (data) => {
    if (!Array.isArray(data) || data.length === 0) return;

    const fileId = data[0]?.localPurchaseOrderDocumentId;

    await crud.remove(
      `localpurchaseorderdocuments/${fileId}`,
      '',
      (err, res) => {
        if (err) {
          toast.error(err);
        }
        if (res?.status === 204) {
          toast.success('File Deleted Successfully');
          window.location.reload();
        }
      }
    );
  };

  return (
    <>
      <GlassCard className='p-4 mb-4'>
        {id && editLoading ? (
          <SkeletonLoaderRow count={10} />
        ) : (
          <form onSubmit={handleSubmit(onSubmit)}>
            <Grid container spacing={2}>
              <Grid item md={gridValue}>
                <FmDatePicker
                  name='lpoDate'
                  control={control}
                  label='LPO Date'
                  required
                />
              </Grid>

              <Grid item md={gridValue}>
                <FmAutoComplete
                  label='Local Purchase Order Type'
                  name='localPurchaseOrderTypeId'
                  control={control}
                  options={localPurchaseOrderType}
                  displayField='displayName'
                  optionFields={['displayName']}
                  valueKey='key'
                />
              </Grid>

              <Grid item md={gridValue}>
                <FmAutoComplete
                  label='Priority Level'
                  name='priorityLevelId'
                  control={control}
                  options={priorityLevel}
                  displayField='displayName'
                  optionFields={['displayName']}
                  valueKey='key'
                />
              </Grid>

              <Grid item md={gridValue}>
                <FmSearchableSelect
                  name='contractId'
                  control={control}
                  apiUrl='contracts'
                  valueField='contractId'
                  headerField={['Contract Code', 'Contract Name']}
                  labelField={['contractCode', 'contractName']}
                  showField={['contractName']}
                  label={'Contracts'}
                  defaultValue={{
                    contractId: editValues?.contractId,
                    contractName: editValues?.contractName,
                  }}
                  required
                />
              </Grid>

              <Grid item md={gridValue}>
                <FmSearchableSelect
                  name='divisionId'
                  control={control}
                  apiUrl='divisions'
                  valueField='divisionId'
                  labelField={['divisionName']}
                  showField={['divisionName']}
                  label='Division'
                  defaultValue={{
                    divisionId: editValues?.divisionId,
                    divisionName: editValues?.divisionName,
                  }}
                />
              </Grid>

              <Grid item md={gridValue}>
                <FmSearchableSelect
                  name='costCenterId'
                  control={control}
                  apiUrl='costCenters'
                  valueField='costCenterId'
                  headerField={['costCenterName']}
                  labelField={['costCenterName']}
                  showField={['costCenterName']}
                  required
                  label='Cost Centers'
                  defaultValue={{
                    costCenterId: editValues?.costCenterId,
                    costCenterName: editValues?.costCenterName,
                  }}
                />
              </Grid>

              <Grid item md={gridValue}>
                <FmSearchableSelect
                  name='paymentTermId'
                  control={control}
                  apiUrl='paymentterms'
                  valueField='paymentTermId'
                  headerField={['Payment Term']}
                  labelField={['paymentTermName']}
                  showField={['paymentTermName']}
                  defaultValue={{
                    paymentTermId: editValues?.paymentTermId,
                    paymentTermName: editValues?.paymentTermName,
                  }}
                  label='Payment Term'
                />
              </Grid>

              <Grid item md={gridValue * 2}>
                <FmTextField
                  name={'remarks'}
                  control={control}
                  required
                  multiline
                  rows={3}
                  label={'Remarks'}
                />
              </Grid>

              <Grid item md={gridValue * 2}>
                <UploadSquareBoxesNew
                  getFiles={editValues?.localPurchaseOrderDocuments || []}
                  handleFileIdCreate={
                    id ? handleFileUpdate : handleFileIdCreate
                  }
                  getUploadedFileState={uploadGetData}
                  handleFileIdDelete={handleFileIdDelete}
                />
              </Grid>
              <Grid item md={12}>
                <ActionButtons
                  onSubmit={handleSubmit(onSubmit)}
                  onReset={reset}
                  submitLoading={createLoading}
                  submitText={id ? 'Update' : 'Create'}
                  removeSpacing
                />
              </Grid>
            </Grid>
          </form>
        )}
      </GlassCard>
    </>
  );
};

export default CreatePurchaseForms;
