import React from 'react';
import { Chip } from '@mui/material';

const ViewChipText = ({ label, colored }) => {
  return (
    <Chip
      label={label}
      sx={{
        bgcolor: colored ? 'background.mainLightBase' : 'background.light',
        color: 'inherit',
        py: 0.5,
        px: 1.5,
        height: 'inherit',
        '& .MuiChip-label': {
          p: 0,
        },
      }}
    />
  );
};

export default ViewChipText;
