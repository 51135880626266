import { AdapterDayjs } from '@mui/x-date-pickers/AdapterDayjs';
import { LocalizationProvider } from '@mui/x-date-pickers/LocalizationProvider';
import { TimePicker } from '@mui/x-date-pickers/TimePicker';
import dayjs, { Dayjs } from 'dayjs';
import * as React from 'react';
import { Control, Controller } from 'react-hook-form';
import { useSelector } from 'react-redux';

interface BasicTimePickerProps {
  control: Control<any>;
  name: string;
  label?: string;
  defaultValue?: string | Dayjs | null;
  required?: boolean;
  width?: string;
  onChangeProp?: (value: string) => void;
  disabled?: boolean;
}

const FmTimePicker: React.FC<BasicTimePickerProps> = ({
  control,
  name,
  label = 'Select Time',
  defaultValue = null,
  required = false,
  width = '100%',
  onChangeProp,
  disabled = false,
}) => {
  const { timeFormat } = useSelector((state) => state?.time);
  return (
    <LocalizationProvider dateAdapter={AdapterDayjs}>
      <Controller
        name={name}
        control={control}
        defaultValue={defaultValue}
        render={({ field: { onChange, value }, fieldState: { error } }) => {
          const timeValue = value
            ? dayjs(new Date().toISOString().split('T')[0] + 'T' + value)
            : null;

          return (
            <TimePicker
              label={label}
              ampm={timeFormat === '12h'}
              disabled={disabled}
              value={timeValue}
              onChange={(newValue) => {
                if (newValue) {
                  const formattedValue = newValue.format('HH:mm:ss');
                  onChange(formattedValue);
                  onChangeProp?.(formattedValue);
                } else {
                  onChange(null);
                }
              }}
              slotProps={{
                textField: {
                  fullWidth: true,
                  variant: 'filled',
                  size: 'small',
                  required,
                  error: !!error,
                  helperText: error?.message,
                  sx: { width },
                },
              }}
            />
          );
        }}
      />
    </LocalizationProvider>
  );
};

export default FmTimePicker;
