import React, { useState, useEffect } from 'react';
import { Box, IconButton, Tooltip, Collapse } from '@mui/material';
import { Icon } from '@iconify/react/dist/iconify.js';

const ViewDetailsCollapse = ({ children }) => {
  const [view, setView] = useState(false);

  // Load the initial view state from localStorage
  useEffect(() => {
    const savedViewState = localStorage.getItem('viewDetailsState');
    if (savedViewState !== null) {
      setView(JSON.parse(savedViewState)); // Parse to Boolean
    }
  }, []);

  // Toggle view and save to localStorage
  const handleToggle = () => {
    const newViewState = !view;
    setView(newViewState);
    localStorage.setItem('viewDetailsState', JSON.stringify(newViewState));
  };

  return (
    <Box className='relative'>
      <Collapse in={view} collapsedSize={65}>
        {children}
      </Collapse>
      <Box
        className='flex justify-center w-full'
        sx={{
          position: !view ? 'absolute' : 'relative',
          bottom: 0,
          left: 0,
          mt: !view ? 0 : 0,
          background:
            !view &&
            'linear-gradient(to bottom, hsl(0deg 0% 100% / 35%), rgb(255 255 255 / 97%))',
        }}
      >
        <Tooltip title={view ? 'Hide Details' : 'Show More Details'} arrow>
          <IconButton
            className='font-bold'
            sx={{
              bgcolor: 'background.white',
            }}
            onClick={handleToggle}
          >
            {view ? (
              <Icon icon='material-symbols:keyboard-double-arrow-down' />
            ) : (
              <Icon icon='material-symbols:keyboard-double-arrow-up' />
            )}
          </IconButton>
        </Tooltip>
      </Box>
    </Box>
  );
};

export default ViewDetailsCollapse;
