import React, { useEffect, useState } from 'react';
import DashboardLayout from '../../../../components/DashboardLayout.tsx';
import { useNavigate, useParams } from 'react-router-dom';
import BackToButton from '../../../../components/small/BackToButton.tsx';
import FieldsCreateJournalEntries from '../Create/FieldsCreateJournalEntries.tsx';
import { Box, CircularProgress } from '@mui/material';
import ListCreditNote from './ListCreditNote.tsx';
import EditListCreditNote from './EditListCreditNote.tsx';
import FieldsCreateCreditJournal from './FieldsCreateCreditJournal.tsx';
import Crud_Service from '../../../../apis/CrudService.jsx';
import { NavigationEnum } from '../../../../utils/CommonVariables.tsx';
import ElementLoader from '../../../../components/page/ElementLoader.tsx';

const EditCreditNote = () => {
  const navigation = useNavigate();
  const [loading, setLoading] = useState(false);
  const crud = new Crud_Service();
  const { id } = useParams();

  useEffect(() => {
    document.title = 'Edit | Credit Note | Financial';
  });

  const onNavClick = (direction: boolean) => {
    setLoading(true);
    crud.getAll(
      `navigations/${NavigationEnum.CREDIT_NOTE}/api/${id}/navigation/${direction}`,
      '',
      (err, res) => {
        setLoading(false);
        navigation(
          `/financial/journal-entries/credit-notes/edit/${res?.data?.id}`
        );
      }
    );
  };

  return (
    <DashboardLayout
      title='Update Credit Note'
      onNavClick={onNavClick}
      actionButtons={
        <BackToButton
          title='Back to List'
          onClick={() => navigation('/financial/journal-entries/credit-notes')}
          className='px-0 py-0'
        />
      }
      titleReverse
    >
      <Box className='flex flex-col gap-3'>
        {loading ? <ElementLoader image fly /> : <FieldsCreateCreditJournal />}
      </Box>
    </DashboardLayout>
  );
};

export default EditCreditNote;
