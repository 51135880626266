import { Box, Checkbox, FormControlLabel, Grid } from '@mui/material';
import React, { useState } from 'react';
import { Controller, useForm } from 'react-hook-form';
import { useNavigate, useParams } from 'react-router-dom';
import { toast } from 'react-toastify';
import Crud_Service from '../../../apis/CrudService.jsx';
import { ActionButtons } from '../../../components/_form/index.jsx';
import DashboardLayout from '../../../components/DashboardLayout.tsx';
import useLoader from '../../../components/helpers/UseLoader.tsx';
import UploadWithLoading from '../../../components/upload/UploadWithLoading.tsx';

const UploadBoxStyle = {
  border: '2px dashed',
  borderColor: 'border.main',
  padding: '15px',
  marginBottom: '30px',
  borderRadius: '6px',
  bgcolor: 'background.white',
};

const CreateEnquiryDocumentUpload = () => {
  const { id } = useParams();
  const { handleSubmit, control, reset } = useForm();
  const [uploadView, setUploadView] = useState(false);
  const { startCreateLoading, stopCreateLoading, createLoading } = useLoader();
  const [uploadProgress, setUploadProgress] = useState({});
  const navigate = useNavigate();
  const crud = new Crud_Service();
  const [uploadedFileIds, setUploadedFileIds] = useState([]);
  const [files, setFiles] = useState([]);
  const handleFileIds = (fileIds) => {
    setUploadedFileIds(fileIds);
  };

  const handleSubmitDocuments = async (values) => {
    startCreateLoading();
    const combinedData = {
      ...values,
      tenderId: Number(id),
      fileIds: uploadedFileIds,
    };

    try {
      crud.create('tenderdocuments', combinedData, (err, res) => {
        if (res?.status === 201) {
          stopCreateLoading();
          setUploadView(false);
          toast.success('Tender Documents Uploaded Successfully');
          reset();
          navigate(-1);
          setUploadProgress({});
        } else {
          stopCreateLoading();
        }
      });
    } catch (error) {
      stopCreateLoading();
      toast.error(error?.message);
    }
  };

  return (
    <DashboardLayout title='Create New Enquiry Document'>
      <form onSubmit={handleSubmit(handleSubmitDocuments)}>
        <Box sx={UploadBoxStyle}>
          <Box className='flex flex-col mt-3'>
            <Box className='flex justify-between gap-10 mt-3 items-center'>
              <Box className='flex-1 flex items-center gap-10'>
                <Grid container spacing={2}>
                  <Grid item md={3}>
                    <Controller
                      name='accessRights'
                      control={control}
                      defaultValue={false}
                      render={({ field: { onChange, value } }) => (
                        <FormControlLabel
                          control={
                            <Checkbox
                              checked={value}
                              onChange={(e) => {
                                onChange(e.target.checked);
                              }}
                            />
                          }
                          label='Access To Everyone'
                        />
                      )}
                    />
                  </Grid>
                  <Grid item md={8}>
                    <UploadWithLoading
                      onSubmitFiles={handleFileIds}
                      files={files}
                      setFiles={setFiles}
                      modal={false}
                    />
                  </Grid>
                </Grid>
              </Box>
            </Box>
          </Box>
        </Box>
        <ActionButtons
          onSubmit={handleSubmit(handleSubmitDocuments)}
          onCancel={() => navigate(-1)}
          onReset={reset}
          disabled={uploadedFileIds?.length === 0}
          submitLoading={createLoading}
          cancelLoading={false}
        />
      </form>
    </DashboardLayout>
  );
};

export default CreateEnquiryDocumentUpload;
