import React from 'react';
import { Box } from '@mui/material';

const SortableTreeBoxStyle = ({ readonly, children, editmode }) => {
  return (
    <Box
      className='h-full'
      sx={{
        border: '1px solid',
        borderColor: 'border.main',
        borderRadius: '0px',
        p: 1,
        paddingRight: 0,
        minHeight: editmode ? '100%' : '100%',
        height: '100%',
        bgcolor: 'background.light',

        '& div[data-viewport-type="element"]': {
          pointerEvents: readonly ? 'none' : 'auto',
        },

        '& .rst__moveHandle, & .rst__loadingHandle': {
          bgcolor: 'primary.main',
          height: '42px',
          border: 'none',
          boxShadow: 'none',
          borderRadius: '5px 0px 0px 5px',
        },

        '& .rst__rowContents': {
          border: '1px solid',
          borderColor: 'primary.main',
          boxShadow: 'none',
          paddingRight: '5px',
          borderRadius: '0px 5px 5px 0px',
          marginLeft: '-1px',
          bgcolor: 'background.mainLight',
        },

        '& .rst__rowLabel': {
          padding: 0,
          width: '100%',
        },
      }}
    >
      {children}
    </Box>
  );
};

export default SortableTreeBoxStyle;
