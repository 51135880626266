import SimpleIconText from './../elements/SimpleIconText.tsx';
import Icons from '../../utils/Icon.jsx';
import { Icon } from '@iconify/react/dist/iconify.js';
import FmViewDateField from '../_mui/FmViewDateField.tsx';

export const TextCalendarCellIcons = ({ value }) => {
  return (
    <SimpleIconText
      icon={Icons.date}
      text={FmViewDateField({ value: value, tableFontWeightRemove: true })}
    />
  );
};
