import React, { useEffect, useState } from 'react';
import DashboardLayout from '../../../components/DashboardLayout.tsx';
import {
  Box,
  Grid,
  TextField,
  Autocomplete,
  MenuItem,
  FormControlLabel,
  Radio,
  RadioGroup,
} from '@mui/material';
import { ActionButtons } from '../../../components/_form';
import { useNavigate, useParams } from 'react-router-dom';
import Crud_Service from '../../../apis/CrudService';
import CommonLoader from '../../../components/page/CommonLoader';
import { Controller, useForm } from 'react-hook-form';
import useLoader from '../../../components/helpers/UseLoader.tsx';
import FmTextField from '../../../components/_mui/FmTextField.tsx';
import GlassCard from '../../../components/small/GlassCard.tsx';
import { yupResolver } from '@hookform/resolvers/yup';
import { supplierValidationSchema } from '../../../utils/CommonvalidationSchemas.tsx';
import { toast } from 'react-toastify';
import FmCurrencyInput from '../../../components/_mui/FmCurrencyInput.tsx';

const EditSuppliers = () => {
  const navigate = useNavigate();
  const crud = new Crud_Service();
  const { id } = useParams();
  const [loading, setLoading] = useState(false);
  const [editedData, setEditedData] = useState(null);
  const {
    setValue,
    handleSubmit,
    control,
    formState: { errors },
    reset,
    watch,
  } = useForm({
    resolver: yupResolver(supplierValidationSchema),
    mode: 'onChange',
    defaultValues: {
      agreement: editedData?.agreement || false,
      creditLimit: editedData?.creditLimit || 0,
      debitORCredit: editedData?.debitORCredit || false,
    },
  });
  const [supplierTypes, setSupplierTypes] = useState([]);
  const { editLoading, startEditLoading, stopEditLoading } = useLoader();
  const [countries, setCountries] = useState([]);
  const [regions, setRegions] = useState([]);

  useEffect(() => {
    getEstimationAMC();
    getCountries();
    getRegions();
    getSupplierTypes();
  }, [id]);

  const getEstimationAMC = async () => {
    setLoading(true);
    await crud.getSingle('suppliers', id, (_err, res) => {
      if (res?.status === 200) {
        Object.entries(res?.data).forEach(([key, value]) => {
          setValue(key, value);
        });
        setLoading(false);
        setEditedData(res?.data);
      } else {
        setLoading(false);
      }
    });
  };

  //get All countries
  const getCountries = async () => {
    await crud.getAll(`countries`, '', (err, res) => {
      if (err) {
        toast.error('Error fetching clients:', err);
        return;
      }
      if (res?.status === 200) {
        setCountries(res?.data?.data);
      }
    });
  };

  //get All Regions
  const getRegions = async () => {
    await crud.getAll(`regions`, '', (err, res) => {
      if (err) {
        toast.error('Error fetching regions:', err);
        return;
      }
      if (res?.status === 200) {
        setRegions(res?.data?.data);
      }
    });
  };

  //get All Supllier Types
  const getSupplierTypes = async () => {
    await crud.getAll(`suppliertypes`, '', (err, res) => {
      if (err) {
        toast.error('Error fetching supplier types:', err);
        return;
      }
      if (res?.status === 200) {
        setSupplierTypes(res?.data?.data);
      }
    });
  };

  const handleEditSuppliers = async (values) => {
    startEditLoading();
    const CombinedData = {
      ...values,
      agreement: values?.agreement || false,
      creditLimit: Number(values?.creditLimit) || 0,
      debitORCredit: values?.debitORCredit || true,
      emailId: values?.emailId || null,
    };
    await crud.update('suppliers', id, CombinedData, (err, res) => {
      if (err) {
        stopEditLoading();

        return;
      }
      if (res?.status === 200) {
        stopEditLoading();
        toast.success('Supplier Updated Successfully');
        navigate(-1);
      } else {
        stopEditLoading();
      }
    });
  };
  const debitORCreditchange = watch('debitORCredit');
  useEffect(() => {
    setValue('creditLimit', debitORCreditchange?.creditLimit);
  }, [debitORCreditchange]);

  return (
    <DashboardLayout title='Edit Suppliers'>
      <Box className='mx-auto' sx={{ maxWidth: '950px' }}>
        <GlassCard className='p-5'>
          {loading ? (
            <CommonLoader />
          ) : (
            <form onSubmit={handleSubmit(handleEditSuppliers)}>
              <Grid container spacing={2}>
                <Grid item md={6}>
                  <FmTextField
                    name='supplierCode'
                    control={control}
                    label='Supplier Code'
                    required
                  />
                </Grid>
                <Grid item md={6}>
                  <FmTextField
                    name='officialSupplierName'
                    control={control}
                    label='Supplier Name'
                    required
                  />
                </Grid>

                <Grid item md={6}>
                  <Controller
                    name='supplierTypeId'
                    control={control}
                    render={({ field }) => (
                      <Autocomplete
                        {...field}
                        options={supplierTypes}
                        value={supplierTypes?.find(
                          (item) => item.supplierTypeId === field.value
                        )}
                        getOptionLabel={(option) => option?.supplierTypeName}
                        onChange={(_event, value) =>
                          field.onChange(value?.supplierTypeId)
                        }
                        renderInput={(params) => (
                          <TextField
                            {...params}
                            label='Supplier Types'
                            variant='filled'
                            fullWidth
                            required
                            error={Boolean(errors.supplierTypeId)} // Set error prop
                            helperText={
                              errors.supplierTypeId
                                ? errors.supplierTypeId.message
                                : ''
                            } // Display error message
                          />
                        )}
                      />
                    )}
                  />
                </Grid>
                <Grid item md={6}>
                  <FmCurrencyInput
                    name='mobileNumber'
                    control={control}
                    label='Mobile Number'
                  />
                </Grid>
                <Grid item md={6}>
                  <FmTextField
                    name='emailId'
                    control={control}
                    label='Email Id'
                  />
                </Grid>

                <Grid item md={6}>
                  <Controller
                    name='regionId'
                    control={control}
                    render={({ field }) => (
                      <Autocomplete
                        {...field}
                        options={regions}
                        value={regions?.find(
                          (item) => item?.regionId === field?.value
                        )}
                        getOptionLabel={(option) => option?.regionName}
                        onChange={(_event, value) =>
                          field.onChange(value?.regionId)
                        }
                        renderInput={(params) => (
                          <TextField
                            {...params}
                            label='Regions'
                            variant='filled'
                            fullWidth
                            error={Boolean(errors.regionId)} // Set error prop
                            helperText={
                              errors.regionId ? errors.regionId.message : ''
                            } // Display error message
                          />
                        )}
                      />
                    )}
                  />
                </Grid>
                <Grid item md={6}>
                  <Controller
                    name='countryId'
                    control={control}
                    render={({ field }) => (
                      <Autocomplete
                        {...field}
                        options={countries}
                        getOptionLabel={(option) => option?.countryName}
                        onChange={(_event, value) =>
                          field.onChange(value?.countryId)
                        }
                        value={countries?.find(
                          (item) => item?.countryId === field?.value
                        )}
                        renderInput={(params) => (
                          <TextField
                            {...params}
                            label='Countries'
                            variant='filled'
                            fullWidth
                            error={Boolean(errors.countryId)} // Set error prop
                            helperText={
                              errors.countryId ? errors.countryId.message : ''
                            } // Display error message
                          />
                        )}
                      />
                    )}
                  />
                </Grid>
                <Grid item md={6}>
                  <FmTextField
                    name='taxNumber'
                    control={control}
                    label='Tax Number'
                    pattern='Number'
                  />
                </Grid>
                <Grid item md={6}>
                  <FmTextField
                    name='taxPercentage'
                    control={control}
                    label='Tax Percentage'
                    pattern='Number'
                    onChange={(e) => {
                      const { value } = e.target;
                      if (value > 100) {
                        setValue('taxPercentage', 100);
                      }
                    }}
                  />
                </Grid>
                <Grid item md={6}>
                  <FmTextField
                    name='tradeLicenseNumber'
                    control={control}
                    label='Trade License Number'
                  />
                </Grid>
                <Grid item md={3}>
                  <h3 className='mb-1 font-semibold'>Agreement</h3>
                  <Controller
                    name='agreement'
                    control={control} // Make sure you have passed 'control' from useForm
                    render={({ field }) => (
                      <RadioGroup
                        {...field} // Spread the field props into the RadioGroup
                        row
                        aria-labelledby='demo-row-radio-buttons-group-label'
                      >
                        <FormControlLabel
                          value='true' // Radio values should be strings if you're using 'true'/'false'
                          control={
                            <Radio className='m-0 p-0 me-2' size='small' />
                          }
                          label='Yes'
                          className='me-4'
                        />
                        <FormControlLabel
                          value='false' // Same here for 'false'
                          control={
                            <Radio className='m-0 p-0 me-2' size='small' />
                          }
                          label='No'
                        />
                      </RadioGroup>
                    )}
                  />
                </Grid>
                <Grid item md={3}>
                  <h3 className='mb-1 font-semibold'>Debit/Credit</h3>
                  <Controller
                    name='debitORCredit'
                    control={control}
                    render={({ field }) => (
                      <RadioGroup
                        row
                        aria-labelledby='demo-row-radio-buttons-group-label'
                        {...field}
                      >
                        <FormControlLabel
                          value={false}
                          control={
                            <Radio className='m-0 p-0 me-2' size='small' />
                          }
                          label='Debit'
                          className='me-4'
                        />
                        <FormControlLabel
                          value={true}
                          control={
                            <Radio className='m-0 p-0 me-2' size='small' />
                          }
                          label='Credit'
                        />
                      </RadioGroup>
                    )}
                  />
                </Grid>

                {(debitORCreditchange === true ||
                  debitORCreditchange === 'true') && (
                  <Grid item md={3}>
                    <FmTextField
                      name='creditLimit'
                      control={control}
                      label='Credit Limit'
                      pattern='Number'
                      required
                    />
                  </Grid>
                )}
                <Grid item md={6}>
                  <FmTextField
                    name='address'
                    control={control}
                    label='Address'
                    multiline
                    rows={3}
                    maxRows={4}
                  />
                </Grid>
                <Grid item md={6}>
                  <FmTextField
                    name='headOfficeAddress'
                    control={control}
                    label='Head Office Address'
                    multiline
                    rows={3}
                    maxRows={4}
                  />
                </Grid>

                <Grid item md={6}>
                  <Controller
                    name='status'
                    control={control}
                    render={({ field }) => (
                      <TextField
                        {...field}
                        label='Status'
                        fullWidth
                        select
                        variant='filled'
                      >
                        <MenuItem value={2}>Active</MenuItem>
                        <MenuItem value={1}>Inactive</MenuItem>
                      </TextField>
                    )}
                  />
                </Grid>
              </Grid>
              <ActionButtons
                onSubmit={handleSubmit(handleEditSuppliers)}
                onCancel={() => navigate(-1)}
                onReset={reset}
                submitLoading={editLoading}
                cancelLoading={false}
                submitText='Update'
              />
            </form>
          )}
        </GlassCard>
      </Box>
    </DashboardLayout>
  );
};

export default EditSuppliers;
