import { Grid } from '@mui/material';
import React from 'react';
import { ViewField } from '../_form/FormElements';
import FmViewDateField from '../_mui/FmViewDateField.tsx';
import useDateFormatter from './useDateFormatter';

interface DynamicViewFieldsProps {
  data: Record<string, any>;
  fields: { key: string, label: string }[];
  fieldFormat?: string;
  gridValue?: number;
  excludeNestedArray?: boolean;
  gridSpacing?: number;
}

const DynamicViewFields: React.FC<DynamicViewFieldsProps> = ({
  data,
  fields,
  fieldFormat = '',
  gridValue = 2.4,
  excludeNestedArray,
  gridSpacing = 2,
}) => {
  const formatDate = useDateFormatter();

  const cleanLabel = (label) => {
    if (label.endsWith('Id')) {
      return label.slice(0, -2);
    }
    return label;
  };

  const isArrayType = (value) => {
    return Array.isArray(value);
  };

  const formatTitle = (key, title) => {
    if (typeof title === 'object' && title !== null) {
      return (
        <div>
          {Array.isArray(title)
            ? title.map((item, index) => (
                <div key={index}>
                  {typeof item === 'object' ? (
                    <div>
                      {Object.entries(item)
                        .filter(([subKey]) =>
                          excludeNestedArray?.includes(subKey)
                        )
                        .map(([subKey, subValue]) => (
                          <div key={subKey}>
                            <strong>{subKey}:</strong> {String(subValue)}
                          </div>
                        ))}
                    </div>
                  ) : (
                    String(item)
                  )}
                </div>
              ))
            : Object.entries(title)
                .filter(([subKey]) => excludeNestedArray?.includes(subKey))
                .map(([subKey, subValue]) => (
                  <div key={subKey}>
                    <strong>{subKey}:</strong> {String(subValue)}
                  </div>
                ))}
        </div>
      );
    }

    if (
      key.toLowerCase().endsWith('date') ||
      key.toLowerCase().endsWith('day')
    ) {
      return formatDate(title);
    }

    if (fieldFormat[key]) {
      return fieldFormat[key](title);
    }

    return title;
  };

  return (
    <Grid container spacing={gridSpacing}>
      {fields?.map((field) => (
        <Grid item md={field?.md || gridValue} key={field?.key}>
          {field.key.toLowerCase().endsWith('date') ||
          field.key.toLowerCase().endsWith('day') ? (
            <FmViewDateField
              title={cleanLabel(field?.label)}
              value={data[field?.key]}
            />
          ) : (
            <ViewField
              label={cleanLabel(field?.label)}
              title={formatTitle(field?.key, data[field?.key])}
            />
          )}
        </Grid>
      ))}
    </Grid>
  );
};

export default DynamicViewFields;
