import React from 'react';
import MuiDialogOne from '../../../../../components/_mui/MuiDialogOne.jsx';
import ActionButtons from '../../../../../components/_form/ActionButtons.tsx';
import { Grid } from '@mui/material';
import FmSearchableSelect from '../../../../../components/_mui/FmSearchableSelect.tsx';

const TransferToCollectionTeamModal = ({
  openContractChecklist,
  setOpenContractChecklist,
  handleCA,
  createLoading,
  control,
  handleSubmit,
  reset,
}) => {
  return (
    <MuiDialogOne
      open={openContractChecklist}
      onClose={() => setOpenContractChecklist(false)}
      width='450px'
      title='Transfer To Collection Team'
    >
      <form onSubmit={handleSubmit(handleCA)}>
        <Grid container spacing={2}>
          <Grid item md={12}>
            <FmSearchableSelect
              name='employeeId'
              control={control}
              apiUrl='employees'
              valueField='employeeId'
              headerField={['Code', 'Name']}
              labelField={['employeeCode', 'fullName']}
              showField={['employeeCode', 'fullName']}
              label={'Employee'}
              required
            />
          </Grid>
          <Grid item md={12}>
            <ActionButtons
              onSubmit={handleSubmit(handleCA)}
              onReset={reset}
              submitLoading={createLoading}
              submitText='Submit'
              submitFullWidth
              removeSpacing
            />
          </Grid>
        </Grid>
      </form>
    </MuiDialogOne>
  );
};

export default TransferToCollectionTeamModal;
