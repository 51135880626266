import React, { useState, useEffect } from 'react';
import DataTable from '../../../components/DataTable.tsx';
import { Box } from '@mui/material';
import GlassCard from '../../../components/small/GlassCard.tsx';
import Icons from '../../../utils/Icon.jsx';
import { CreateButton } from '../../../components/small/Buttons.jsx';
import { useNavigate } from 'react-router-dom';
import Crud_Service from '../../../apis/CrudService.jsx';
import { toast } from 'react-toastify';
import useDateFormatter from '../../../components/helpers/useDateFormatter.jsx';
import {
  EstimationStatus,
  StatusBadge,
  TextColor,
} from '../../../components/_form/FormElements.jsx';
import { useTranslation } from 'react-i18next';
import DashboardLayout from '../../../components/DashboardLayout.tsx';
import useTableLogic from '../../../components/helpers/UseTableLogic.tsx';
import TableSearch from '../../../components/_form/TableSearch.jsx';
import TableActionButtons from '../../../components/_form/TableActionButtons.jsx';

const EstimationSheetForMEP = () => {
  const navigate = useNavigate();
  const [, setSelectedRow] = useState();
  const formateDate = useDateFormatter();
  const {
    rows,
    pageSize,
    pageNumber,
    setSearchKeyword,
    isLoading,
    tableRecordCounts,
    searchKeyword,
    handlePaginationModelChange,
    handleSortModelChange,
    fetchData,
  } = useTableLogic('estimationmepsheets');
  const crud = new Crud_Service();
  const { t } = useTranslation();

  useEffect(() => {
    document.title = `Presales | Estimation Sheet For MEP`;
  }, []);

  const handleViewClick = async (estimationMepSheetId) => {
    const id =
      estimationMepSheetId && estimationMepSheetId.estimationMepSheetId
        ? estimationMepSheetId.estimationMepSheetId
        : estimationMepSheetId;
    navigate(`view/${id}`);
  };

  const handleEditClick = async (props) => {
    navigate(`edit/${props?.estimationMepSheetId}`);
  };

  const handledeleteClick = async (props) => {
    await crud.remove(
      `estimationmepsheets`,
      props?.estimationMepSheetId,
      (err, res) => {
        if (err) {
          toast.error(err);
          return;
        }
        if (res?.status === 204) {
          toast.success('Deleted Successfully');
          fetchData();
        }
      }
    );
  };

  const ActionData = [
    {
      name: 'View Details',
      icon: Icons.view,
      onClick: (props) => handleViewClick(props),
    },
    {
      name: t('edit'),
      icon: Icons.edit,
      onClick: (props) => handleEditClick(props),
    },
    {
      name: 'Delete',
      icon: Icons.delete,
      onClick: (props) => handledeleteClick(props),
      danger: true,
    },
  ];

  const columns = [
    {
      field: 'action',
      headerName: '',
      width: 130,
      sortable: false,
      hideable: false,
      disableClickEventBubbling: false,
      renderCell: (params) => {
        return (
          <TableActionButtons
            Actions={ActionData?.map((action) => ({
              ...action,
              onClick: () => action.onClick(params.row),
            }))}
            selectedRow={params.row}
            setSelectedRow={setSelectedRow}
          />
        );
      },
    },
    {
      field: 'tenderName',
      headerName: 'Tender',
      width: 200,
    },
    {
      field: 'projectName',
      headerName: 'Project',
      width: 300,
    },
    {
      field: 'clientName',
      headerName: 'Client',
      width: 200,
    },
    {
      field: 'buildingName',
      headerName: 'Building',
      width: 200,
    },
    {
      field: 'dateOfEnquiry',
      headerName: 'Date of Enquiry',
      width: 150,

      renderCell: (params) => {
        return formateDate(params.value);
      },
    },
    {
      field: 'contractPeriod',
      headerName: 'Contract Period',
      width: 150,
    },
    //TODO: for Future Use
    // {
    //   field: 'tenderSubmissionDate',
    //   headerName: 'Tender Submission Date',
    //   width: 200,
    //   renderCell: (params) => {
    //     return formateDate(params.value);
    //   },
    // },

    {
      field: 'bidBond',
      headerName: 'Bidbond',

      width: 150,
      renderCell: (params) => {
        return (
          <TextColor
            title={params.value === true ? 'Yes' : 'No'}
            color={params.value === true ? 'green' : 'red'}
          />
        );
      },
    },
    {
      field: 'bidBondValue',
      headerName: 'Bidbond Value',
      width: 150,
    },

    {
      field: 'sitVisitDoneByName',
      headerName: 'Site Visit Done By',
      width: 250,
    },
    {
      field: 'existContract',
      headerName: 'Exist Contract',
      width: 150,
    },
    {
      field: 'preparedByName',
      headerName: 'Prepared By',
      width: 220,
    },
    {
      field: 'otherPercent',
      headerName: 'Other Percent',
      width: 150,
    },
    {
      field: 'discountOverAllPercent',
      headerName: 'Discount (%)',
      width: 200,
    },
    {
      field: 'status',
      headerName: 'Estimation Status',
      minWidth: 130,
      renderCell: (params) => {
        const statusId = params?.row?.estimationStatusId;
        const statusName = params?.row?.estimationStatusName;
        const statusColor =
          statusId === 3
            ? 'red'
            : statusId === 2
            ? 'green'
            : statusId === 1
            ? 'blue'
            : ''; // Example logic

        return <EstimationStatus name={statusName} color={statusColor} />;
      },
    },
  ];

  const onRowDoubleClick = (e) => {
    if (e?.row) {
      handleEditClick(e?.row);
    }
  };

  return (
    <DashboardLayout
      title='Estimation Sheet for MEP'
      actionButtons={
        <CreateButton name='New' onClick={() => navigate('create')} />
      }
    >
      <GlassCard className='overflow-hidden h-full-css'>
        <Box className='h-full-css'>
          <DataTable
            rows={rows}
            columns={columns}
            loading={isLoading}
            onRowDoubleClick={(e) => onRowDoubleClick(e)}
            sortingMode='server'
            paginationMode='server'
            onPaginationModelChange={(model) =>
              handlePaginationModelChange(model)
            }
            onSortModelChange={(model) => handleSortModelChange(model)}
            page={pageNumber - 1}
            pageSize={pageSize}
            rowCount={tableRecordCounts?.total}
            fetchData={fetchData}
            slots={{
              toolbar: () => (
                <Box
                  className='p-2 w-full flex justify-between items-center'
                  sx={{
                    borderBottom: '1px solid',
                    borderColor: 'border.main',
                  }}
                >
                  <Box sx={{ maxWidth: '250px' }}>
                    <TableSearch
                      placeholder='Search'
                      fullWidth
                      setSearchKeyword={setSearchKeyword}
                      searchValue={searchKeyword}
                    />
                  </Box>
                </Box>
              ),
            }}
          />
        </Box>
      </GlassCard>
      {/* {assetView && (
        <ViewDetails
          handleBackButton={() => setAssetView(!assetView)}
          TableData={selectedRow}
        />
      )} */}
    </DashboardLayout>
  );
};

export default EstimationSheetForMEP;
