import React, { useEffect, useState } from 'react';
import GlassCard from '../../../../../components/small/GlassCard.tsx';
import { Grid } from '@mui/material';
import FmSearchableSelect from '../../../../../components/_mui/FmSearchableSelect.tsx';
import { yupResolver } from '@hookform/resolvers/yup';
import { useForm } from 'react-hook-form';
import ActionButtons from '../../../../../components/_form/ActionButtons.tsx';
import Crud_Service from '../../../../../apis/CrudService.jsx';
import { toast } from 'react-toastify';
import { addCheckInvalidationSchema } from '../../../../../utils/CommonvalidationSchemas.tsx';
import { useParams } from 'react-router-dom';
import CommonLoader from '../../../../../components/page/CommonLoader.jsx';
const GridSize = 2.4;

const AddCheckInForm = (props) => {
  const { fetchData } = props;
  const id = useParams()?.id;
  const crud = new Crud_Service();
  const [getLoading, setGetLoading] = useState(false);
  const [loading, setLoading] = useState(false);
  const [editData, setEditData] = React.useState(null);
  const { handleSubmit, control, reset, setValue } = useForm({
    resolver: yupResolver(addCheckInvalidationSchema),
    mode: 'onChange',
  });

  useEffect(() => {
    if (id) {
      (async () => {
        setGetLoading(true);
        await crud.getSingle('checkinforms', id, (err, res) => {
          if (res?.status === 200) {
            setEditData(res?.data);
            Object.entries({
              ...res.data,
            }).forEach(([key, value]) => {
              setValue(key, value);
            });
            setGetLoading(false);
          } else {
            setGetLoading(false);
            // Handle error response here
          }
        });
      })();
    }
  }, [id]);

  const handleSubmitProject = async (values) => {
    setLoading(true);

    await crud.update('checkinforms', id, values, (err, res) => {
      if (res?.status === 200) {
        toast.success('Check In Updated successfully');
        fetchData();
        setLoading(false);
      } else {
        setLoading(false);
      }
    });
  };

  return (
    <GlassCard className='p-3'>
      {getLoading ? (
        <CommonLoader />
      ) : (
        <form onSubmit={handleSubmit(handleSubmitProject)}>
          <Grid container spacing={2}>
            <Grid item md={GridSize}>
              <FmSearchableSelect
                name='clientId'
                control={control}
                apiUrl='clients'
                valueField='clientId'
                headerField={['Client Name']}
                labelField={['clientName']}
                showField={['clientName']}
                
                label='Client'
                defaultValue={{
                  clientId: editData?.clientId || null,
                  clientName: editData?.clientName || '',
                }}
              />
            </Grid>

            <Grid item md={GridSize}>
              <FmSearchableSelect
                name='companyId'
                control={control}
                apiUrl='companies'
                valueField='companyId'
                headerField={['Company Name']}
                labelField={['companyName']}
                showField={['companyName']}
                
                label='Company'
                defaultValue={{
                  companyId: editData?.clientId || null,
                  companyName: editData?.companyName || '',
                }}
              />
            </Grid>

            <Grid item md={GridSize}>
              <FmSearchableSelect
                name='projectId'
                control={control}
                apiUrl='projects'
                valueField='projectId'
                headerField={['Project Code']}
                labelField={['projectCode']}
                showField={['projectCode']}
                
                label='Project Code'
                defaultValue={{
                  projectId: editData?.projectId || null,
                  projectCode: editData?.projectCode || '',
                }}
              />
            </Grid>

            <Grid item md={GridSize}>
              <FmSearchableSelect
                name='employeeId'
                control={control}
                apiUrl='employees'
                valueField='employeeId'
                headerField={['Employee Name']}
                labelField={['firstName']}
                showField={['firstName']}
                
                label='Employee'
                defaultValue={{
                  employeeId: editData?.employeeId || null,
                  firstName: editData?.employeeName || '',
                }}
              />
            </Grid>
            <Grid item md={GridSize}>
              <FmSearchableSelect
                name='workOrderId'
                control={control}
                apiUrl='workOrders'
                valueField='workOrderId'
                headerField={['Work Order Code']}
                labelField={['workOrderCode']}
                showField={['workOrderCode']}
                
                label='Work Order'
                defaultValue={{
                  workOrderId: editData?.workOrderId || null,
                  workOrderCode: editData?.workOrderCode || '',
                }}
              />
            </Grid>

            <Grid item md={GridSize}>
              <FmSearchableSelect
                name='contractId'
                control={control}
                apiUrl='contracts'
                valueField='contractId'
                headerField={['Contract Code']}
                labelField={['contractCode']}
                showField={['contractCode']}
                
                label='Contract'
                defaultValue={{
                  contractId: editData?.contractId || null,
                  contractCode: editData?.contractCode || '',
                }}
              />
            </Grid>
          </Grid>
          <ActionButtons
            onSubmit={handleSubmit(handleSubmitProject)}
            onReset={reset}
            submitText='Update'
            submitLoading={loading}
            cancelLoading={false}
          />
        </form>
      )}
    </GlassCard>
  );
};

export default AddCheckInForm;
