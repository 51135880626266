import React from 'react';
import { Box } from '@mui/material';
const SimpleIconText = ({ icon, text, textFontWeight }) => {
  return (
    <Box className={`flex items-center gap-1`}>
      <span className='text-xl opacity-80'>{icon}</span>
      <span className={textFontWeight && 'font-semibold'}>{text}</span>
    </Box>
  );
};

export default SimpleIconText;
