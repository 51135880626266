import React, { useState } from 'react';
import DashboardLayout from '../../../components/DashboardLayout.tsx';
import CreatePurchaseItem from './Form/CreatePurchaseItem.tsx';
import ProgressCircleStep from '../../../components/page/ProgressCircleStep.tsx';
import useCommonFetchApi from '../../../components/helpers/useCommonFetchApi.tsx';
import {useLocation, useParams } from 'react-router-dom';
import EditRequestQuotes from './EditRequestQuotes.tsx';


const CreatePurchaseOrderView = () => {
  const location = useLocation();
  const {locationData} = location?.state || {};
  const [localPurchaseOrderId, setLocalPurchaseOrderId] = useState(0);
  const [materialRequest, setMaterialRequest] = useState({});
  const { id } = useParams();
  const { data} = useCommonFetchApi(`materialrequisitions/${locationData?.materialRequisitionId}/status-info`);
  return (
    <DashboardLayout title='Request for Quote Items'>
      <EditRequestQuotes/>
      <ProgressCircleStep apiresponse={data}/>
      {/* <CreatePurchaseForms
        setLocalPurchaseOrderId={setLocalPurchaseOrderId}
        setMaterialRequest={setMaterialRequest}
      /> */}
        <CreatePurchaseItem
          localPurchaseOrderId={localPurchaseOrderId}
          materialRequest={materialRequest}
        />
    </DashboardLayout>
  );
};

export default CreatePurchaseOrderView;
