
import { Icon } from '@iconify/react';
import {
  Box,
  Button,
  FormControlLabel,
  Switch,
} from '@mui/material';
import React, { useEffect, useRef, useState } from 'react';
import { useForm } from 'react-hook-form';
import { useNavigate, useParams } from 'react-router-dom';
import { toast } from 'react-toastify';
import * as yup from 'yup';
import apiInstance from '../../../../apis/ApiService.jsx';
import Crud_Service from '../../../../apis/CrudService.jsx';
import TableActionButtons from '../../../../components/_form/TableActionButtons.jsx';
import FmTextField from '../../../../components/_mui/FmTextField.tsx';
import MuiDialogOne from '../../../../components/_mui/MuiDialogOne.jsx';
import FmMRTDataTable from '../../../../components/FmMRTDataTable.tsx';
import useTableLogic from '../../../../components/helpers/MRTUseTableLogic.tsx';
import useLoader from '../../../../components/helpers/UseLoader.tsx';
import GlassCard from '../../../../components/small/GlassCard.tsx';
import { useHotkeys } from 'react-hotkeys-hook';
import ViewTenderBuildingImages from './ViewTenderBuildingImages.tsx';
import ViewTenderSiteVisitImages from './ViewTenderSiteVisitImages.tsx';
import FmTabsOne from '../../../../components/tabs/FmTabsOne.tsx';
import { t } from 'i18next';
import { yupResolver } from '@hookform/resolvers/yup';

const navMenu = [
  {
    id: 1,
    name: 'Checklist Items',
  },
  {
    id: 2,
    name: 'Building Images',
  },
  {
    id: 3,
    name: 'Site Visit Images',
  },
];

const TenderInspectionCheckListItem = () => {
  const { id } = useParams();
  const [updateView, setUpdateView] = useState(false);
  const [tenderChecklist, setTenderChecklist] = useState(null);
  const [tenderChecklistDetailId, setTenderChecklistDetailId] = useState(null);
  const [scopeNotes, setScopeNotes] = useState([]);
  const [uploadedFileIds, setUploadedFileIds] = useState([]);
  const [files, setFiles] = useState([]);
  const [editingRowIndex, setEditingRowIndex] = useState(null);
  const { startCreateLoading, stopCreateLoading, createLoading } = useLoader();
  const [editData, setEditData] = useState(null);
  const firstColumnInputRef = useRef(null);
  const [previewdData, setPreviewData] = useState([]);
  const [previewCheckListData, setPreviewCheckListData] = useState([]);
  const [stateError, setStateError] = useState(false);
  const { startEditLoading, stopEditLoading } = useLoader();
  const [loader, setLoader] = useState(false);
  const [tabValue, setTabValue] = useState(1);
  const [editingRowData, setEditingRowData] = useState(null);
  const {
    rows,
    isLoading,
    pagination,
    setRows,
    sorting,
    setSearchKeyword,
    columnFilters,
    globalFilter,
    tableRecordCounts,
    setPagination,
    setSorting,
    setColumnFilters,
    setGlobalFilter,
    searchKeyword,
    fetchData,
  } = useTableLogic(`tenderinspectionchecklistdetail`, ['tenderInspectionCheckListId'], [id]);
  const crud = new Crud_Service();
  const navigate = useNavigate();
  const [rowState, setRowState] = useState({
    newRowId: null,
    isCreatingRow: false,
  });
  const validationSchema = yup.object().shape({
    sspName: yup.string().required('SSP Name is required'),
  });
  const {
    handleSubmit,
    control,
    reset,
    setValue,
    getValues,
    formState: { isValid },
  } = useForm({
    resolver: yupResolver(validationSchema),
    mode: 'onChange',
  });

  const {
    handleSubmit: creatCheckListSubmit,
    control: creatCheckListControl,
    setValue: creatCheckListSetValue,
  } = useForm();

  const handleAddNewRow = () => {
    const newId = 'new_row_' + Date.now();
    reset();
    setEditingRowData(null);
    setEditingRowId(null);
    setRowState({
      newRowId: newId,
      isCreatingRow: true,
    });
    setEditingRowIndex(
      editingRowIndex === rows.length ? editingRowIndex + 1 : rows.length
    );

    setRows((prevRows) => [
      ...prevRows,
      {
        tenderInspectionChecklistDetailId: newId,
        assetName: '',
        subLocation: '',
        quantity: '',
        make: '',
        sspName: '',
        condition: '',
        remarks: '',
        isEditable: true,
        isNew: true,
      },
    ]);
    reset();
  };

  const handleChange = (_event, newValue) => {
    setTabValue(newValue);
  };

  

  //Delete Assets
  const handleDeleteClick = async (props) => {
    await crud.remove(
      'tenderinspectionchecklistdetail',
      props?.tenderInspectionChecklistDetailId,
      (err, res) => {
        if (res?.status === 204) {
          toast.success('CheckList Deleted Successfully');
          fetchData();
        } else {
        }
      }
    );
  };
  
  const handleEdit = async (row) => {
    setEditingRowId(row?.tenderInspectionChecklistDetailId);
    setEditingRowData({ ...row });

    Object.entries({
      ...row,
    }).forEach(([key, value]) => {
      setValue(key, value);
    });
  };
  const handleDownloadFile = async (fileId) => {
    try {
      // Check if fileId is null, undefined, or an empty string
      if (!fileId) {
        return; // Exit early without calling the API
      }

      const imageUrl = await apiInstance.getFiles(`files/download/${fileId}`);

      setPreviewData((prevData) => [...prevData, imageUrl]);
    } catch (err) {
      toast.error(`Error fetching image: ${err.message}`);
    }
  };
  useEffect(() => {
    if (scopeNotes.length > 0) {
      scopeNotes?.forEach((image) => {
        handleDownloadFile(image?.fileId);
      });
    }
  }, [scopeNotes]);
  const handleEditClick = async (props) => {
    navigate(`/presales/enquiry/view/${props?.tenderInspectionChecklistId}/edit`);
  };

  const ActionData = [
    // TODO
    // {
    //   name: 'View',
    //   icon: <Icon icon='solar:eye-linear' />,
    //   onClick: (props) => handleViewClick(props),
    // },
    {
      name: t('delete'),
      icon: <Icon icon='solar:trash-bin-2-linear' />,
      onClick: (props) => handleDeleteClick(props),
      danger: true,
    },
  ];

  const [editingRowId, setEditingRowId] = useState(null);
  const [openUpload, setOpenUpload] = useState(false);

  const handleCancel = (row) => {
    if (
      row?.original?.tenderInspectionChecklistDetailId === rowState?.newRowId
    ) {
      setRows((prevRows) =>
        prevRows.filter(
          (r) => r?.tenderInspectionChecklistDetailId !== rowState?.newRowId
        )
      );
      setRowState((prevState) => ({
        ...prevState,
        newRowId: null,
        isCreatingRow: false,
      }));
    } else {
      setEditingRowId(null);
    }
    reset();
  };
  const previousValues = useRef(getValues());
  const [showTabs, setShowTabs] = useState(false);
  const handleBlur = () => {
    const currentValues = getValues();
    const value = getValues('sspName');
    if (!value) {
      // Refocus the field if empty
      firstColumnInputRef.current.focus();
      return;
    }
    // Compare the previous values with the current values
    const hasChanges = Object.keys(currentValues).some(
      (key) => currentValues[key] !== previousValues.current[key]
    );

    if ((hasChanges && isValid) || (hasChanges && editingRowId)) {
      apiCallAsync(currentValues); // Call the API with the new values
      previousValues.current = currentValues; // Update previous values
    }
  };

  const apiCallAsync = async (values) => {
    setLoader(true);
    const convertedValues = {
      ...values,
      quantity: values?.quantity||null,
      sspName: values?.sspName,
      remarks: values?.remarks,
      isAvailable: values?.availability,
    };
    const combinedData = {
      tenderInspectionCheckListId: Number(id) || null,
      ...convertedValues,
    };
    const updateCombinedData = {
      ...values,
      quantity: values?.quantity,
      remarks: values?.remarks,
      sspName: values?.sspName,
      isAvailable: values?.availability,
      status: 2,
    };

    if (editingRowId === null) {
      await crud.create(
        'tenderinspectionchecklistdetail',
        combinedData,
        (err, res) => {
          if (res?.status === 201) {
            setEditingRowId(res?.data?.tenderInspectionChecklistDetailId);
            setValue(
              'tenderInspectionChecklistDetailId',
              res?.data?.tenderInspectionChecklistDetailId
            );
          } else {
          }
        }
      );
    } else {
      await crud.update(
        'tenderinspectionchecklistdetail',
        editingRowId,
        updateCombinedData,
        (err, res) => {
          if (res?.status === 200) {
            if (rows.some((row) => row.isNew)) {
              const updatedRow = res?.data;
              const updatedRows = [...rows];
              updatedRows[editingRowIndex] = {
                ...updatedRow,
                isEditable: true,
                isNew: true,
              };
              setRows(updatedRows);
              setStateError(false);
              setLoader(false);
            } else {
              setStateError(false);
              setLoader(false);
              const updatedRow = res?.data;
              const updatedRows = [...rows];

              updatedRows[editingRowIndex] = updatedRow;

              setRows(updatedRows);
            }
          } else {
            setStateError(true);
          }
        }
      );
    }
  };

  useHotkeys(
    'Enter',
    () => handleAddNewRow(),
    {
      enableOnTags: ['INPUT', 'TEXTAREA'], // Optional: Enable hotkey only when focused on specific tags
    },
    [editingRowIndex, rows, stateError, loader]
  );

  useHotkeys(
    'Tab',
    (event) => {
      if (event.key !== 'Tab') return;
      if (stateError) {
        event.preventDefault();
        return;
      }
      const totalRows = rows.length;
      const nextRowIndex = editingRowIndex + 1;

      if (
        nextRowIndex < totalRows &&
        stateError === false &&
        loader === false
      ) {
        handleEdit(rows[nextRowIndex]);
        setEditingRowIndex(nextRowIndex);
      }
    },
    [editingRowIndex, rows, stateError, loader, isValid]
  );

  useEffect(() => {
    if (editingRowIndex !== null && firstColumnInputRef.current && !isLoading) {
      firstColumnInputRef.current.focus();
    }
  }, [editingRowIndex, isLoading]);

  const handleCancelAndAddNewRow = () => {
    const newId = 'new_row_' + Date.now();
    reset();
    setEditingRowData(null);
    setEditingRowId(null);
    setRowState({
      newRowId: newId,
      isCreatingRow: true,
    });
    setEditingRowIndex(
      editingRowIndex === rows.length ? editingRowIndex + 1 : rows.length
    );
    setRows((prevRows) => [
      ...prevRows,
      {
        tenderInspectionChecklistDetailId: newId,
        assetName: '',
        subLocation: '',
        quantity: '',
        make: '',
        sspName: '',
        condition: '',
        remarks: '',
        isEditable: true,
        isNew: true,
      },
    ]);
    reset();
  };

   const columns = [
      {
        header: 'Action',
        enableColumnPinning: true,
        enableEditing: false,
        enableSorting: false,
        size: 80,
        Cell: ({ row, table }) => {
          const isNewRow =
            row?.original?.tenderInspectionChecklistDetailId ===
            rowState?.newRowId;
          const isEditing =
            editingRowId === row?.original?.tenderInspectionChecklistDetailId ||
            isNewRow;
          const isLastRow = row.index === table.getRowModel().rows.length - 1;
          return (
            <Box className='flex gap-2'>
              {isEditing ? (
                <>
                  <Button onClick={() => handleCancel(row)}>Close</Button>
                  {isLastRow && rowState?.isCreatingRow === false && (
                    <Button onFocus={() => handleCancelAndAddNewRow()}></Button>
                  )}
                </>
              ) : (
                <Box className='flex gap-2'>
                  <TableActionButtons
                    Actions={ActionData?.map((action) => ({
                      ...action,
                      onClick: () => action.onClick(row?.original),
                    }))}
                  />
                  {isLastRow && (
                    <Button
                      variant='contained'
                      color='primary'
                      className='px-3 py-1'
                      onClick={() => {
                        setEditingRowIndex(row?.index + 1);
                        handleAddNewRow(row);
                      }}
                    >
                      Add
                    </Button>
                  )}
                </Box>
              )}
            </Box>
          );
        },
      },
      {
        accessorKey: 'sspName',
        header: 'SSP Name',
        enableEditing: true,
        Cell: ({ row }) => {
          const isNewRow =
            row?.original?.tenderInspectionChecklistDetailId ===
            rowState?.newRowId;
          const isEditing =
            editingRowId === row?.original?.tenderInspectionChecklistDetailId ||
            isNewRow;
          return isEditing ? (
            <FmTextField
              name='sspName'
              onBlur={handleBlur}
              control={control}
              inputRef={firstColumnInputRef}
            />
          ) : (
            row?.original?.sspName || ''
          );
        },
      },

      {
        accessorKey: 'description',
        header: 'Description',
        enableEditing: true,
        Cell: ({ row }) => {
          const isNewRow =
            row?.original?.tenderInspectionChecklistDetailId ===
            rowState?.newRowId;
          const isEditing =
            editingRowId === row?.original?.tenderInspectionChecklistDetailId ||
            isNewRow;
          return isEditing ? (
            <FmTextField
              name='description'
              control={control}
              onBlur={handleBlur}
            />
          ) : (
            row?.original?.description || ''
          );
        },
      },
      {
        accessorKey: 'isAvailable',
        header: 'Availability',
        enableEditing: true,
        Cell: ({ row }) => {
          const isNewRow =
            row?.original?.tenderInspectionChecklistDetailId ===
            rowState?.newRowId;
          const isEditing =
            editingRowId === row?.original?.tenderInspectionChecklistDetailId ||
            isNewRow;
          return isEditing ? (
            <FormControlLabel
              value='availability'
              control={
                <Switch
                  color='warning'
                  size='small'
                  defaultChecked={row?.original?.isAvailable || false}
                  onChange={(e) => setValue('availability', e.target.checked)}
                />
              }
              label='Yes/No'
              // labelPlacement='end'
            />
          ) : (
            (row?.original?.isAvailable ? 'Yes' : 'No') || ''
          );
        },
      },
      {
        accessorKey: 'quantity',
        header: 'Quantity',
        enableEditing: true,
        Cell: ({ row }) => {
          const isNewRow =
            row?.original?.tenderInspectionChecklistDetailId ===
            rowState?.newRowId;
          const isEditing =
            editingRowId === row?.original?.tenderInspectionChecklistDetailId ||
            isNewRow;
          return isEditing ? (
            <FmTextField
              pattern='Number'
              name='quantity'
              onBlur={handleBlur}
              control={control}
            />
          ) : (
            row?.original?.quantity || ''
          );
        },
      },
      {
        accessorKey: 'remarks',
        header: 'Remarks',
        enableEditing: true,
        Cell: ({ row }) => {
          const isNewRow =
            row?.original?.tenderInspectionChecklistDetailId ===
            rowState?.newRowId;
          const isEditing =
            editingRowId === row?.original?.tenderInspectionChecklistDetailId ||
            isNewRow;
          return isEditing ? (
            <FmTextField name='remarks' control={control} onBlur={handleBlur} />
          ) : (
            row?.original?.remarks || ''
          );
        },
      },
    ];

  const handleExport = async () => {
    const downloadUrl = await apiInstance.getFiles(
      `tenderinspectionchecklistdetail/${tenderChecklistDetailId}/export-excel`
    );

    const link = document.createElement('a');
    link.href = downloadUrl;
    link.setAttribute('download', 'CheckList.xlsx');
    document.body.appendChild(link);
    link.click();
    document.body.removeChild(link);
  };
  const handleUploadSheet = (event) => {
    const file = event.target.files[0];
    if (file) {
      const fileFormData = new FormData();
      fileFormData.append('file', file);
      fileFormData.append('fileTypeId', 4);

      crud.create('files/upload', fileFormData, (_err, res) => {
        if (res?.status === 200) {
          crud.create(
            `tenderinspectionchecklistdetail/${id}/process/${res.data?.fileId}`,
            '',
            (_err, res) => {
              if (res?.status === 200) {
                fetchData();
                setOpenUpload(false);
              }
            }
          );
        } else {
          setOpenUpload(false);
        }
      });
    }
  };
  const handleClose = () => {
    setOpenUpload(false);
  };

  const GridValue = 3;

  useEffect(() => {
    const fetchTenderChecklist = async () => {
      if (id) {
        await crud.getSingle(`tenders`, id, (err, res) => {
          if (res?.status === 200) {
            setTenderChecklist(res?.data);
            const value = res?.data;
            creatCheckListSetValue('clientId', value?.clientId);
            creatCheckListSetValue('clientName', value?.clientName);
            creatCheckListSetValue(
              'ClientContactPerson ',
              value?.ClientContactPerson
            );
            creatCheckListSetValue('ClientMail', value?.ClientMail);
            creatCheckListSetValue('ClientContactNo', value?.ClientContactNo);
          }
        });
      }
    };

    fetchTenderChecklist();
    CheckTenderChecklist();
  }, [id]);

  const CheckTenderChecklist = async () => {
    if (id) {
      await crud.getSingle(
        `tenderinspectionchecklists`,id,
        (err, res) => {
          if (res?.status === 200) {
            const data = res?.data;
            setTenderChecklistDetailId(data);
            setEditData(data);
            if (data) {
              Object.entries(data).forEach(([key, value]) => {
                creatCheckListSetValue(key, value);
              });
            }
          }
        }
      );

      await crud.getAll(`tenderscopes?tenderId=${id}`, {}, (err, res) => {
        if (res?.status === 200) {
          setScopeNotes(res?.data?.data);
        }
      });
    }
  };

  useEffect(() => {
    if (tenderChecklistDetailId) {
      fetchData(); // Or any other action that depends on tenderChecklistDetailId
    }
  }, [tenderChecklistDetailId]);

  const handleFileIds = (fileIds) => {
    setUploadedFileIds(fileIds);
  };

  useEffect(() => {
    if (editData && editData?.tenderInspectionCheckListAttachments) {
      editData?.tenderInspectionCheckListAttachments?.forEach((image) => {
        handleDownloadFileChecklist(image?.fileId);
      });
    }
  }, [editData]);
  const handleDownloadFileChecklist = async (fileId) => {
    try {
      // Check if fileId is null, undefined, or an empty string
      if (!fileId) {
        return; // Exit early without calling the API
      }

      const imageUrl = await apiInstance.getFiles(`files/download/${fileId}`);

      setPreviewCheckListData((prevData) => [...prevData, imageUrl, fileId]);
    } catch (err) {
      toast.error(`Error fetching image: ${err.message}`);
    }
  };

  return (
    <>
      {/* Only show tabs and their content when tenderChecklistDetailId exists */}
      {tenderChecklistDetailId?.tenderInspectionChecklistId && (
        <>
          <GlassCard className='mb-2 shadow-none' bgLight>
            <FmTabsOne
              value={tabValue}
              handleChange={handleChange}
              data={navMenu}
              centered
            />
          </GlassCard>

          {/* Tab Content */}
          {tabValue === 1 && (
            <GlassCard className='overflow-hidden mt-4'>
              <FmMRTDataTable
                columns={columns}
                enableRowSelection={false}
                rows={rows || []}
                isCreatingRow={rowState?.isCreatingRow}
                rowCount={tableRecordCounts}
                editingRow={editingRowId}
                pagination={pagination}
                setSearchKeyword={setSearchKeyword}
                sorting={sorting}
                columnFilters={columnFilters}
                globalFilter={globalFilter}
                setPagination={setPagination}
                setSorting={setSorting}
                setColumnFilters={setColumnFilters}
                setGlobalFilter={setGlobalFilter}
                isLoading={isLoading}
                searchKeyword={searchKeyword}
                fetchData={fetchData}
                handleExport={handleExport}
                handleImport={() => setOpenUpload(true)}
                handleRowDoubleClick={(row) => {
                  handleEdit(row?.original);
                  setEditingRowIndex(row?.index);
                }}
                muiTableContainerProps={{
                  sx: {
                    maxHeight: '400px',
                  },
                }}
              />
              <MuiDialogOne
                title='Upload Sheet'
                open={openUpload}
                onClose={handleClose}
              >
                <input
                  type='file'
                  onChange={handleUploadSheet}
                  id='file'
                  accept='.csv, application/vnd.openxmlformats-officedocument.spreadsheetml.sheet, application/vnd.ms-excel'
                />
              </MuiDialogOne>
            </GlassCard>
          )}

          {tabValue === 2 && (
            <ViewTenderBuildingImages
              tenderChecklistDetailId={tenderChecklistDetailId}
            />
          )}
          {tabValue === 3 && (
            <ViewTenderSiteVisitImages
              tenderChecklistDetailId={tenderChecklistDetailId}
            />
          )}
        </>
      )}
    </>
  );
};

export default TenderInspectionCheckListItem;
