import { yupResolver } from '@hookform/resolvers/yup';
import { Box, Button } from '@mui/material';
import React, { useState } from 'react';
import { useForm } from 'react-hook-form';
import { toast } from 'react-toastify';
import Crud_Service from '../../../../apis/CrudService.jsx';
import FmMRTDataTable from '../../../../components/FmMRTDataTable.tsx';
import TableActionButtons from '../../../../components/_form/TableActionButtons.jsx';
import FmSearchableSelect from '../../../../components/_mui/FmSearchableSelect.tsx';
import FmTextField from '../../../../components/_mui/FmTextField.tsx';
import { SalesOrderBudgetvalidationSchema } from '../../../../utils/CommonvalidationSchemas.tsx';
import Icons from '../../../../utils/Icon.jsx';

const ViewSalesOrderDistributionBudgetsTable = (props) => {
  const { handleSubmit, control, setValue, watch } = useForm({
    resolver: yupResolver(SalesOrderBudgetvalidationSchema),
    mode: 'onChange',
  });
  const crud = new Crud_Service();
  const [editingRowId, setEditingRowId] = useState(null);
  const [editingRowData, setEditingRowData] = useState(null);

  const handleEdit = (row) => {
    if (!row.workOrderCreated) {
      setEditingRowId(row?.salesOrderDistributionBudgetId);
      setEditingRowData({ ...row });
      Object.entries({
        ...row,
      }).forEach(([key, value]) => {
        setValue(key, value);
      });
    } else {
      toast.info('Sales Order Already Created');
    }
  };

  const handleCancel = () => {
    setEditingRowId(null);
    setEditingRowData(null);
  };

  const {
    salesOrderDistributionId,
    rows,
    tableRecordCounts,
    pagination,
    sorting,
    setPagination,
    setSorting,
    columnFilters,
    globalFilter,
    setColumnFilters,
    setGlobalFilter,
    isLoading,
    setSearchKeyword,
    searchKeyword,
    fetchData,
  } = props;
  const [edit, disableEdit] = useState(false);
  const handleDeleteItem = async (view) => {
    await crud.remove(
      'salesorderdistributionbudgets',
      view?.salesOrderDistributionBudgetId,
      (_err, res) => {
        if (res?.status === 204) {
          toast.success('Sales Order Distribution Budget Deleted Successfully');
          fetchData();
        } else {
        }
      }
    );
  };

  const handleUpdateSalesOrderBudget = async (values) => {
    const combinedData = {
      ...values,
      salesOrderDistributionId:
        salesOrderDistributionId?.salesOrderDistributionId ||
        salesOrderDistributionId,
      salesOrderId: values?.salesOrderId,
      divisionId: values?.divisionId,
      status: 2,
    };
    await crud.update(
      'salesorderdistributionbudgets',
      editingRowId,
      combinedData,
      (_err, res) => {
        if (res?.status === 200) {
          toast.success('Sales Order Distribution Budget Updated Successfully');
          fetchData();
          handleCancel();
        } else {
        }
      }
    );
  };

  const handleCreateWorkOrder = async () => {
    const allRowsIds = rows?.map((row) => row?.salesOrderDistributionBudgetId);
    const dataToSend = {
      salesOrderDistributionBudgetIds: allRowsIds,
    };
    await crud.create(
      'salesorderdistributionbudgets/salesorder',
      dataToSend,
      (_err, res) => {
        if (res?.status === 204) {
          toast.success('Sales Order Created successfully');
          disableEdit(true);
          handleCancel();
        }
      }
    );
  };

  const ActionData = [
    {
      name: 'Edit',
      icon: Icons.edit,
      onClick: (props) => {
        !edit ? handleEdit(props) : toast.info('Work Order Already Created');
      },
    },
    {
      name: 'Delete',
      icon: Icons.delete,
      onClick: (props) => handleDeleteItem(props),
      danger: true,
    },
  ];
  const columns = [
    {
      header: 'Actions',
      Cell: ({ row }) => (
        <Box className='flex gap-2 '>
          {editingRowId === row?.original?.salesOrderDistributionBudgetId ? (
            <>
              <Button onClick={handleSubmit(handleUpdateSalesOrderBudget)}>
                Save
              </Button>
              <Button onClick={handleCancel}>Cancel</Button>
            </>
          ) : (
            <TableActionButtons
              Actions={ActionData?.map((action) => ({
                ...action,
                onClick: () => {
                  action.onClick(row?.original);
                },
              }))}
            />
          )}
        </Box>
      ),
    },
    {
      accessorKey: 'divisionId',
      header: 'Division',
      enableEditing: true,
      Cell: ({ row }) => {
        return editingRowId ===
          row?.original?.salesOrderDistributionBudgetId ? (
          <FmSearchableSelect
            name='divisionId'
            control={control}
            defaultValue={{
              divisionId: editingRowData?.divisionId,
              divisionName: editingRowData?.divisionName,
            }}
            apiUrl='divisions'
            valueField='divisionId'
            headerField={['Name', 'Code']}
            labelField={['divisionCode', 'divisionName']}
            showField={['divisionCode', 'divisionName']}
            label='Division'
            required
          />
        ) : (
          row?.original?.divisionName
        );
      },
    },
    {
      accessorKey: 'totalContractValuePercentage',
      header: 'Total Contract Value (%)',
      enableEditing: true,
      Cell: ({ row }) => {
        return editingRowId ===
          row?.original?.salesOrderDistributionBudgetId ? (
          <FmTextField
            name='totalContractValuePercentage'
            control={control}
            label='Total Contract Value (%)'
            defaultValue={row?.original?.totalContractValuePercentage}
            onChange={(ev) => {
              const newPercentage = parseFloat(ev.target.value) || 0;
              const newContractValue =
                (newPercentage / 100) * editingRowData?.totalContractValue;
              setValue('totalContractValue', newContractValue);
              const originalBudgetPercentage =
                row?.original?.totalBudgetValuePercentage || 0;
              const newBudgetValue =
                (originalBudgetPercentage / 100) * newContractValue;
              setValue('totalBudgetValue', newBudgetValue);
            }}
          />
        ) : (
          row?.original?.totalContractValuePercentage
        );
      },
    },
    {
      accessorKey: 'totalContractValue',
      header: 'Total Contract Value',
      enableEditing: true,
      Cell: ({ row }) => {
        const totalContractValue = row?.original?.totalContractValue;

        const formattedValue =
          typeof totalContractValue === 'number'
            ? totalContractValue.toFixed(2)
            : '0.00';

        return editingRowId ===
          row?.original?.salesOrderDistributionBudgetId ? (
          <FmTextField
            name='totalContractValue'
            control={control}
            label='Total Contract Value'
            defaultValue={formattedValue}
            readOnly
            pattern='Decimal'
          />
        ) : (
          formattedValue
        );
      },
    },
    {
      accessorKey: 'totalBudgetValuePercentage',
      header: 'Total Budget (%)',
      enableEditing: true,
      Cell: ({ row }) => {
        const handleBudgetPercentageChange = (e) => {
          const percentage = parseFloat(e.target.value);
          const contractValue = watch('totalContractValue'); // Watch for contract value
          if (!isNaN(percentage) && contractValue) {
            const calculatedBudget = (percentage / 100) * contractValue;
            setValue('totalBudgetValue', calculatedBudget.toFixed(2)); // Update totalBudgetValue
          }
        };

        return editingRowId ===
          row?.original?.salesOrderDistributionBudgetId ? (
          <FmTextField
            name='totalBudgetValuePercentage'
            control={control}
            label='Total Budget Value (%)'
            defaultValue={row?.original?.totalBudgetValuePercentage}
            onChange={handleBudgetPercentageChange} // Handle percentage change
          />
        ) : (
          row?.original?.totalBudgetValuePercentage
        );
      },
    },
    {
      accessorKey: 'totalBudgetValue',
      header: 'Total Budget',
      enableEditing: true,
      Cell: ({ row }) => {
        const totalBudgetValue = row?.original?.totalBudgetValue;

        return editingRowId ===
          row?.original?.salesOrderDistributionBudgetId ? (
          <FmTextField
            name='totalBudgetValue'
            control={control}
            label='Total Budget Value'
            defaultValue={totalBudgetValue?.toFixed(2)}
            readOnly
          />
        ) : (
          totalBudgetValue?.toFixed(2)
        );
      },
    },
  ];

  return (
    <>
      <FmMRTDataTable
        columns={columns}
        enableRowSelection={false}
        rows={rows}
        rowCount={tableRecordCounts}
        editingRow={editingRowId}
        pagination={pagination}
        sorting={sorting}
        columnFilters={columnFilters}
        globalFilter={globalFilter}
        setPagination={setPagination}
        setSorting={setSorting}
        buttonName='Create Sales Order'
        onCreateRow={handleCreateWorkOrder}
        setColumnFilters={setColumnFilters}
        setGlobalFilter={setGlobalFilter}
        isLoading={isLoading}
        setSearchKeyword={setSearchKeyword}
        searchKeyword={searchKeyword}
        fetchData={fetchData}
      />
    </>
  );
};

export default ViewSalesOrderDistributionBudgetsTable;
