import React, { useEffect, useState } from 'react';
import FmMRTDataTable from '../../../../components/FmMRTDataTable.tsx';
import { Icon } from '@iconify/react/dist/iconify.js';
import { Box, Button } from '@mui/material';
import dayjs from 'dayjs';
import { useForm } from 'react-hook-form';
import { toast } from 'react-toastify';
import FmSearchableSelect from '../../../../components/_mui/FmSearchableSelect.tsx';
import FmTextField from '../../../../components/_mui/FmTextField.tsx';
import Crud_Service from '../../../../apis/CrudService.jsx';
import useLoader from '../../../../components/helpers/UseLoader.tsx';
import { yupResolver } from '@hookform/resolvers/yup';
import { ProjectTeamsSchema } from '../../../../utils/CommonvalidationSchemas.tsx';
import TableActionButtons from '../../../../components/_form/TableActionButtons.jsx';
import FmDatePicker from '../../../../components/_mui/FmDatePicker.tsx';
import FmViewDateField from '../../../../components/_mui/FmViewDateField.tsx';
import { useTranslation } from 'react-i18next';
const ContractProjectTeamTable = (props) => {
  const crud = new Crud_Service();
  const [editingRowId, setEditingRowId] = useState(null);
  const [editingRowData, setEditingRowData] = useState(null);
  const { startEditLoading, stopEditLoading } = useLoader();
  const { t } = useTranslation();
  const { control, setValue, handleSubmit, watch } = useForm({
    resolver: yupResolver(ProjectTeamsSchema),
    mode: 'onChange',
    defaultValues: { startDate: dayjs(), endDate: dayjs() },
  });
  const {
    rows,
    tableRecordCounts,
    pagination,
    sorting,
    setPagination,
    setSorting,
    columnFilters,
    globalFilter,
    setColumnFilters,
    setGlobalFilter,
    isLoading,
    setSearchKeyword,
    searchKeyword,
    fetchData,
  } = props;
  const ActionData = [
    {
      name: 'Edit',
      icon: <Icon icon='solar:pen-2-broken' />,
      onClick: (props) => handleEdit(props),
    },
    {
      name: 'Delete',
      icon: <Icon icon='solar:trash-bin-minimalistic-broken' />,
      onClick: (props) => handleDelete(props),
      danger: true,
    },
  ];
  const columns = [
    {
      field: 'action',
      header: 'Actions',
      sortable: false,
      hideable: false,
      disableClickEventBubbling: false,

      Cell: (params) => {
        return (
          <Box className='flex gap-2'>
            {editingRowId === params?.row?.original?.projectTeamId ? (
              <>
                <Button onClick={handleSubmit(handleUpdateProjectTeam)}>
                  Save
                </Button>
                <Button onClick={handleCancel}>Cancel</Button>
              </>
            ) : (
              <TableActionButtons
                Actions={ActionData?.map((action) => ({
                  ...action,
                  onClick: () => action.onClick(params.row),
                }))}
                selectedRow={params.row}
                setSelectedRow={() => {}}
              />
            )}
          </Box>
        );
      },
    },
    {
      accessorKey: 'employeeId',
      header: 'Employee',
      width: 150,
      enableEditing: true,
      Cell: ({ cell }) => {
        return editingRowId === cell?.row?.original?.projectTeamId ? (
          <FmSearchableSelect
            name='employeeId'
            control={control}
            apiUrl='employees'
            valueField='employeeId'
            headerField={['Code', 'Name', 'Email']}
            labelField={['employeeCode', 'fullName', 'emailId']}
            showField={['employeeCode', 'fullName', 'emailId']}
            label='Employees'
            defaultValue={{
              employeeId: editingRowData?.employeeId,
              firstName: editingRowData?.employeeName,
            }}
            required
          />
        ) : (
          rows?.find((item) => item?.employeeId === cell.getValue())
            ?.employeeName || ''
        );
      },
    },
    {
      accessorKey: 'projectTeamRoleId',
      header: 'Project Team Role',
      width: 150,
      enableEditing: true,
      Cell: ({ cell }) => {
        return editingRowId === cell?.row?.original?.projectTeamId ? (
          <FmSearchableSelect
            name='projectTeamRoleId'
            control={control}
            apiUrl='projectteamroles'
            valueField='projectTeamRoleId'
            headerField={['Code', 'Name']}
            labelField={['projectTeamRoleCode', 'projectTeamRoleName']}
            showField={['projectTeamRoleCode', 'projectTeamRoleName']}
            label='Project Team Role'
            defaultValue={{
              projectTeamRoleId: editingRowData?.projectTeamRoleId,
              projectTeamRoleName: editingRowData?.projectTeamRoleName,
            }}
          />
        ) : (
          rows?.find((item) => item?.projectTeamRoleId === cell.getValue())
            ?.projectTeamRoleName || ''
        );
      },
    },
    {
      accessorKey: 'designationId',
      header: 'Designation',
      enableEditing: true,
      Cell: ({ row }) => {
        return editingRowId === row?.original?.projectTeamId ? (
          <FmTextField
            name='designationName'
            control={control}
            defaultValue={editingRowData?.designationName}
          />
        ) : (
          row?.original?.designationName
        );
      },
    },
    {
      accessorKey: 'startDate',
      header: 'Start Date',
      enableEditing: true,
      Cell: ({ cell }) => {
        const incidentReport: any = rows?.find(
          (item) => item?.projectTeamId === cell?.row?.original?.projectTeamId
        );
        return editingRowId === cell?.row?.original?.projectTeamId ? (
          <FmDatePicker name='startDate' label='Start Date' control={control} />
        ) : (
          <FmViewDateField value={incidentReport?.startDate || ''} />
        );
      },
    },
    {
      accessorKey: 'endDate',
      header: 'End Date',
      enableEditing: true,
      Cell: ({ cell }) => {
        const incidentReport: any = rows?.find(
          (item) => item?.projectTeamId === cell?.row?.original?.projectTeamId
        );
        return editingRowId === cell?.row?.original?.projectTeamId ? (
          <FmDatePicker
            name='endDate'
            label='End Date'
            control={control}
            required
          />
        ) : (
          <FmViewDateField value={incidentReport?.endDate || ''} />
        );
      },
    },

    {
      accessorKey: 'remarks',
      header: 'Remarks',
      enableEditing: true,
      Cell: ({ row }) => {
        return editingRowId === row?.original?.projectTeamId ? (
          <FmTextField control={control} name='remarks' />
        ) : (
          row.original.remarks
        );
      },
    },
  ];
  const handleEdit = async (row) => {
    setEditingRowId(row?.original?.projectTeamId);
    setEditingRowData({ ...row?.original });
  };
  const handleCancel = () => {
    setEditingRowId(null);
    setEditingRowData(null);
  };

  const handleDelete = async (props) => {
    await crud.remove(
      'projectteams',
      props?.original?.projectTeamId,
      (err, res) => {
        if (res?.status === 204) {
          toast.success(t('deleted_successfully'));
          fetchData();
        }
      }
    );
  };
  const handleUpdateProjectTeam = async (values) => {
    try {
      // Start loading indicator
      startEditLoading();
      // Prepare the data to be updated
      const combinedData = {
        ...values,
        startDate: dayjs(values?.startDate).isValid()
          ? dayjs(values.startDate).format('YYYY-MM-DD')
          : null,

        endDate: dayjs(values?.endDate).isValid()
          ? dayjs(values.endDate).format('YYYY-MM-DD')
          : null,
        typeID: 1,
        Status: 2,
      };
      await crud.update(
        'projectteams',
        editingRowId,
        combinedData,
        (err, res) => {
          if (res?.status === 200) {
            stopEditLoading();
            fetchData();
            toast.success(t('updated_successfully'));
            setEditingRowData(null);
            setEditingRowId(null);
          } else {
            stopEditLoading();
          }
        }
      );
    } catch (error) {
      stopEditLoading();
    }
  };
  useEffect(() => {
    if (editingRowData) {
      Object.entries({
        ...editingRowData,
      }).forEach(([key, value]) => {
        setValue(key, value);
      });
    }
  }, [editingRowData, setValue]);
  return (
    <>
      <FmMRTDataTable
        columns={columns}
        enableRowSelection={false}
        rows={rows}
        rowCount={tableRecordCounts}
        editingRow={editingRowId}
        pagination={pagination}
        sorting={sorting}
        fetchData={fetchData}
        columnFilters={columnFilters}
        globalFilter={globalFilter}
        setPagination={setPagination}
        setSorting={setSorting}
        setColumnFilters={setColumnFilters}
        setGlobalFilter={setGlobalFilter}
        isLoading={isLoading}
        setSearchKeyword={setSearchKeyword}
        searchKeyword={searchKeyword}
        muiTableContainerProps={{
          sx: {
            maxHeight: '350px',
          },
        }}
      />
    </>
  );
};

export default ContractProjectTeamTable;
