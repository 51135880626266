import Icons from "./Icon";

export const getActionButton = (handlers, permissions) => {
    const { handleView, handleEdit, handleDelete } = handlers;
    const { canView, editable, deleteable } = permissions;

    return [
        {
            name: 'View',
            icon: Icons.view,
            onClick: (props) => handleView(props),
            hidden: !canView,
        },
        {
            name: 'Edit',
            icon: Icons.edit,
            onClick: (props) => handleEdit(props),
            hidden: !editable,
        },
        {
            name: 'Delete',
            icon: Icons.delete,
            onClick: (props) => handleDelete(props),
            danger: true,
            hidden: !deleteable,
        },
    ];
};