import { Box, Grid } from '@mui/material';
import dayjs from 'dayjs';
import React, { useEffect, useState } from 'react';
import { useForm } from 'react-hook-form';
import { useNavigate, useParams } from 'react-router-dom';
import { toast } from 'react-toastify';
import Crud_Service from '../../../../../apis/CrudService.jsx';
import FmSearchableSelect from '../../../../../components/_mui/FmSearchableSelect.tsx';
import FmTextField from '../../../../../components/_mui/FmTextField.tsx';
import DashboardLayout from '../../../../../components/DashboardLayout.tsx';
import useLoader from '../../../../../components/helpers/UseLoader.tsx';
import FmStack from '../../../../../components/layout/FmStack.tsx';
import FmDatePicker from '../../../../../components/_mui/FmDatePicker.tsx';
import FmFileInput from '../../../../../components/_mui/FmFileInput.tsx';
import ActionButtons from '../../../../../components/_form/ActionButtons.tsx';
import ARReceiptsItemList from './ARReceiptItemList.tsx';
import BackToButton from '../../../../../components/small/BackToButton.tsx';
import { NavigationEnum } from '../../../../../utils/CommonVariables.tsx';
import ElementLoader from '../../../../../components/page/ElementLoader.tsx';
import GlassCard from '../../../../../components/small/GlassCard.tsx';
import useCommonFetchApi from '../../../../../components/helpers/useCommonFetchApi.tsx';
import FmAutoComplete from '../../../../../components/_mui/FmAutoComplete.tsx';

const EditARReceipts = () => {
  const navigate = useNavigate();
  const crud = new Crud_Service();
  const [loading, setLoading] = useState(false);
  const GridSize = 2.4;
  const { id } = useParams();
  const { handleSubmit, control, setValue, getValues } = useForm({});
  const { editLoading, stopEditLoading, startEditLoading } = useLoader();
  const [editData, setEditData] = useState(null);
  const [uploadedfileData, setUploadedfileData] = useState(null);
  const [uploadLoading, setUploadLoading] = useState(false);
  const [reciptId, setReciptId] = useState(id);
  const { data: receiptStatus } = useCommonFetchApi('enums/receipt-status');
  const [selectedClient, setselectedClient] = useState(editData?.clientId);

  useEffect(() => {
    setselectedClient(editData?.clientId);
  }, [editData]);

  useEffect(() => {
    getReceipts();
  }, [reciptId]);

  const getReceipts = async () => {
    setLoading(true);
    await crud.getSingle('financialtransactionreceipts', id, (_err, res) => {
      if (res?.status === 200) {
        Object.entries({
          ...res?.data,
        }).forEach(([key, value]) => {
          setValue(key, value);
        });
        setEditData(res?.data);
        setLoading(false);
      } else {
        setLoading(false);
      }
    });
  };

  const handleFileUpload = async (e) => {
    const file = e.target.files[0];
    setUploadLoading(true);
    if (file) {
      const fileFormData = new FormData();
      fileFormData.append('file', file);
      fileFormData.append('fileTypeId', 64);

      await crud.create(`/files/upload`, fileFormData, (err, res) => {
        if (res?.status === 200) {
          setUploadLoading(false);
          setUploadedfileData(res?.data);
        } else {
          setUploadLoading(false);
        }
      });
    }
  };

  const handleEditClients = async (values) => {
    startEditLoading();
    const combinedData = {
      ...values,
      receivedDate: dayjs(values?.receivedDate).format('YYYY-MM-DD'),
      totalAmount: 0,
      maturityDate: dayjs(values?.maturityDate).format('YYYY-MM-DD'),
      fileId: uploadedfileData?.fileId || editData?.fileId,
    };

    await crud.update(
      `financialtransactionreceipts`,
      id,
      combinedData,
      (_err, res) => {
        if (res?.status === 200) {
          toast.success('Receipt Updated Successfully');
          navigate('/financial/accounts-receivable/receipts');
          stopEditLoading();
        } else {
          stopEditLoading();
        }
      }
    );
  };

  const onNavClick = (direction: boolean) => {
    crud.getAll(
      `navigations/${NavigationEnum.FINANCIAL_TRANSACTION_RECEIPT}/api/${id}/navigation/${direction}`,
      '',
      (err, res) => {
        setReciptId(res?.data?.id);
        navigate(
          `/financial/accounts-receivable/receipts/edit/${res?.data?.id}`
        );
      }
    );
  };

  return (
    <DashboardLayout
      title='Edit Receipts'
      onNavClick={onNavClick}
      actionButtons={
        <BackToButton
          title={'Back to List'}
          onClick={() => navigate('/financial/accounts-receivable/receipts')}
          className='p-0'
        />
      }
      titleReverse
    >
      {loading ? (
        <ElementLoader fly image />
      ) : (
        <Box className='flex flex-col  gap-3'>
          <GlassCard className='p-3'>
            <Box component='form' onSubmit={handleSubmit(handleEditClients)}>
              <FmStack spacing={1.5}>
                <Box>
                  <Grid container spacing={1.5}>
                    <Grid item md={GridSize * 2}>
                      <FmSearchableSelect
                        name='companyId'
                        control={control}
                        apiUrl='companies'
                        valueField='companyId'
                        labelField={['companyCode', 'companyName']}
                        showField={['companyName']}
                        label='Company'
                        required
                        defaultValue={{
                          companyId: editData?.companyId,
                          companyName: editData?.companyName,
                        }}
                      />
                    </Grid>
                    <Grid item md={GridSize * 2}>
                      <FmSearchableSelect
                        name='clientId'
                        control={control}
                        apiUrl='clients'
                        valueField='clientId'
                        labelField={['clientCode', 'clientName']}
                        showField={['clientCode', 'clientName']}
                        label='Client'
                        required
                        defaultValue={{
                          clientId: editData?.clientId,
                          clientName: editData?.clientName,
                        }}
                        onChangeProp={(ev) => setselectedClient(ev.clientId)}
                      />
                    </Grid>
                  </Grid>
                </Box>

                <Box>
                  <Grid container spacing={1.5}>
                    <Grid item md={GridSize}>
                      <FmSearchableSelect
                        name='paymentModeId'
                        control={control}
                        apiUrl='paymenttypes'
                        valueField='paymentTypeId'
                        headerField={['Payment Type']}
                        labelField={['paymentTypeName']}
                        showField={['paymentTypeName']}
                        label='Payment Mode'
                        required
                        defaultValue={{
                          paymentTypeId: editData?.paymentModeId,
                          paymentTypeName: editData?.paymentModeName,
                        }}
                      />
                    </Grid>
                    <Grid item md={GridSize * 2}>
                      <FmSearchableSelect
                        name='bankId'
                        control={control}
                        apiUrl='chartofaccounts'
                        valueField='chartOfAccountId'
                        labelField={[
                          'chartOfAccountCode',
                          'chartOfAccountName',
                        ]}
                        showField={['chartOfAccountCode', 'chartOfAccountName']}
                        label='Bank'
                        required
                        defaultValue={{
                          bankId: editData?.bankId,
                          chartOfAccountName: editData?.bankName,
                        }}
                      />
                    </Grid>
                    <Grid item md={GridSize}>
                      <FmDatePicker
                        name='receivedDate'
                        label='Received Date'
                        control={control}
                        required
                      />
                    </Grid>

                    <Grid item md={GridSize}>
                      <FmDatePicker
                        name='maturityDate'
                        label='Maturity Date'
                        control={control}
                        required
                      />
                    </Grid>

                    <Grid item md={GridSize}>
                      <FmTextField
                        name='chequeNo'
                        control={control}
                        label='Cheque / Transaction No'
                        required
                      />
                    </Grid>

                    <Grid item md={GridSize}>
                      <FmSearchableSelect
                        name='currencyId'
                        control={control}
                        apiUrl='currencies'
                        valueField='currencyId'
                        headerField={['Currency Code', 'Currency Name']}
                        labelField={['currencyCode', 'currencyName']}
                        showField={['currencyName']}
                        label='Currency'
                        required
                        defaultValue={{
                          currencyId: editData?.currencyId,
                          currencyName: editData?.currencyName,
                        }}
                      />
                    </Grid>
                  </Grid>
                </Box>

                <Box>
                  <Grid container spacing={1.5}>
                    <Grid item md={GridSize * 2}>
                      <FmTextField
                        label='Remarks'
                        name='remarks'
                        control={control}
                        multiline={true}
                        rows={2}
                      />
                    </Grid>

                    <Grid item md={GridSize}>
                      <FmFileInput
                        name='fileId'
                        documentName={
                          uploadedfileData?.originalFileName ||
                          editData?.fileName
                        }
                        onChange={handleFileUpload}
                        loading={uploadLoading}
                      />
                    </Grid>
                    {id && (
                      <Grid item md={GridSize}>
                        <FmAutoComplete
                          name='receiptStatusId'
                          control={control}
                          options={receiptStatus}
                          label='Status'
                          displayField='displayName'
                          optionFields={['displayName']}
                          valueKey='key'
                        />
                      </Grid>
                    )}
                    <Grid item md={GridSize * 2}>
                      <ActionButtons
                        onSubmit={handleSubmit(handleEditClients)}
                        submitLoading={editLoading}
                        submitText='Update'
                        onCancel={() => navigate(-1)}
                        removeSpacing
                      />
                    </Grid>
                  </Grid>
                </Box>
              </FmStack>
            </Box>
          </GlassCard>
          <ARReceiptsItemList clientId={selectedClient} />
        </Box>
      )}
    </DashboardLayout>
  );
};

export default EditARReceipts;
