import React from 'react';
import GlassCard from '../../../../components/small/GlassCard.tsx';
import MRTExpandTableStyle from '../../../../components/table/MRTExpandTableStyle.tsx';
import { Grid } from '@mui/material';
import FmSearchableSelect from '../../../../components/_mui/FmSearchableSelect.tsx';
import { useForm } from 'react-hook-form';
import Crud_Service from '../../../../apis/CrudService.jsx';
import useLoader from '../../../../components/helpers/UseLoader.tsx';
import { toast } from 'react-toastify';
import ActionButtons from '../../../../components/_form/ActionButtons.tsx';
import useTableLogic from '../../../../components/helpers/MRTUseTableLogic.tsx';
import ContractLocationListTable from './ContractLocationListTable.tsx';
import { contractLocationSchema } from '../../../../utils/CommonvalidationSchemas.tsx';
import { yupResolver } from '@hookform/resolvers/yup';
import { useParams } from 'react-router-dom';
import GlassCardCollapse from '../../../../components/small/GlassCardCollapse.tsx';

const gridValue = 3;

const AddFields = (props) => {
  const { fetchData, projectId } = props;
  const contractId = useParams().id;

  const { control, handleSubmit, reset } = useForm({
    mode: 'onChange',
    resolver: yupResolver(contractLocationSchema),
  });
  const { startCreateLoading, stopCreateLoading } = useLoader();
  const crud = new Crud_Service();
  const handleSubmitContractLocation = async (data) => {
    startCreateLoading();
    const combinedData = {
      ...data,
      contractId: contractId,
      projectId: projectId,
    };
    await crud.create('projectlocationdetails', combinedData, (err, res) => {
      if (err) {
        stopCreateLoading();
        toast.error('Something went wrong:', err);
        return;
      }
      if (res?.status === 201) {
        toast.success('Location Created successfully');
        fetchData();
        reset();
        stopCreateLoading();
      } else {
        stopCreateLoading();
      }
    });
  };

  return (
    <GlassCardCollapse
      orientation='horizontal'
      title='Create New'
      className='mb-2'
      collapsed
    >
      <form onSubmit={handleSubmit(handleSubmitContractLocation)}>
        <Grid container spacing={2}>
          <Grid item md={gridValue}>
            <FmSearchableSelect
              name='regionId'
              control={control}
              apiUrl='regions'
              valueField='regionId'
              headerField={['Name']}
              labelField={['regionName', 'regionCode']}
              showField={['regionName', 'regionCode']}
              required
              label='Regions'
            />
          </Grid>
          <Grid item md={gridValue}>
            <FmSearchableSelect
              name='emirateId'
              control={control}
              apiUrl='emirates'
              valueField='emirateId'
              headerField={['Name']}
              labelField={['emirateName']}
              showField={['emirateName']}
              required
              label='Emirates'
            />
          </Grid>
          <Grid item md={gridValue}>
            <FmSearchableSelect
              name='zoneId'
              control={control}
              apiUrl='zones'
              valueField='zoneId'
              headerField={['Name']}
              labelField={['zoneName']}
              showField={['zoneName']}
              required
              label='Zones'
            />
          </Grid>
          <Grid item md={gridValue}>
            <FmSearchableSelect
              name='locationId'
              control={control}
              apiUrl='locations'
              valueField='locationId'
              headerField={['Name']}
              labelField={['locationName']}
              showField={['locationName']}
              required
              label='Locations'
            />
          </Grid>

          <Grid item md={gridValue}>
            <FmSearchableSelect
              name='buildingId'
              control={control}
              apiUrl='buildings'
              valueField='buildingId'
              headerField={['Name']}
              labelField={['buildingName']}
              showField={['buildingName']}
              required
              label='Buildings'
            />
          </Grid>
        </Grid>
        <ActionButtons
          onSubmit={handleSubmit(handleSubmitContractLocation)}
          onReset={reset}
          // submitLoading={createLoading}
          cancelLoading={false}
          submitText='Create'
        />
      </form>
    </GlassCardCollapse>
  );
};

const ViewContractLocationList = (props) => {
  const { contractId, projectId } = props;
  const {
    rows,
    isLoading,
    pagination,
    sorting,
    columnFilters,
    globalFilter,
    tableRecordCounts,
    setPagination,
    setSorting,
    setColumnFilters,
    setGlobalFilter,
    fetchData,
    setSearchKeyword,
    searchKeyword,
  } = useTableLogic('projectlocationdetails', 'contractId', contractId);
  return (
    <>
      <AddFields fetchData={fetchData} projectId={projectId} />

      <GlassCard>
        <MRTExpandTableStyle>
          <ContractLocationListTable
            rows={rows}
            tableRecordCounts={tableRecordCounts}
            pagination={pagination}
            sorting={sorting}
            setPagination={setPagination}
            setSorting={setSorting}
            columnFilters={columnFilters}
            globalFilter={globalFilter}
            setColumnFilters={setColumnFilters}
            setGlobalFilter={setGlobalFilter}
            isLoading={isLoading}
            setSearchKeyword={setSearchKeyword}
            searchKeyword={searchKeyword}
            fetchData={fetchData}
          />
        </MRTExpandTableStyle>
      </GlassCard>
    </>
  );
};

export default ViewContractLocationList;
