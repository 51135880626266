import React, { useEffect, useState } from 'react';
import DashboardLayout from '../../../components/DashboardLayout.tsx';
import PageHeader from '../../../components/PageHeader.jsx';
import DataTable from '../../../components/DataTable.tsx';
import GlassCard from '../../../components/small/GlassCard.tsx';
import TableActionButtons from '../../../components/_form/TableActionButtons.jsx';
import {
    StatusBadge,
} from '../../../components/_form/FormElements.jsx';
import { Icon } from '@iconify/react';
import { Box, Grid } from '@mui/material';
import { CreateButton } from '../../../components/small/Buttons.jsx';
import MuiDialogOne from '../../../components/_mui/MuiDialogOne.jsx';
import Crud_Service from '../../../apis/CrudService.jsx';
import { useForm } from 'react-hook-form';
import CommonLoader from '../../../components/page/CommonLoader.jsx';
import useLoader from '../../../components/helpers/UseLoader.tsx';
import ActionButtons from '../../../components/_form/ActionButtons.tsx';
import useTableLogic from '../../../components/helpers/UseTableLogic.tsx';
import FmTextField from '../../../components/_mui/FmTextField.tsx';
import TableSearch from '../../../components/_form/TableSearch.jsx';
import FmAutoComplete from '../../../components/_mui/FmAutoComplete.tsx';
import { toast } from 'react-toastify'
import { Nav } from '../../../utils/index.jsx';
import useCommonFetchApi from '../../../components/helpers/useCommonFetchApi.tsx';
import { useTranslation } from 'react-i18next';
import { yupResolver } from '@hookform/resolvers/yup';
import { statusOptions } from '../../../utils/CommonVariables.tsx';
import CommonView from '../../../components/helpers/CommonView.jsx';
import DynamicViewFields from '../../../components/helpers/DynamicViewFields.jsx';
import { LevelsvalidationSchema } from '../../../utils/CommonvalidationSchemas.tsx';

const Level = () => {
    const { t } = useTranslation();
    const [openCreate, setOpenCreate] = useState(false);
    const [openEdit, setOpenEdit] = useState(false);
    const [loading, setLoading] = useState(false);
    const [editData, setEditData] = useState(null);
    const { handleSubmit, setValue, reset, control } = useForm({
        resolver: yupResolver(LevelsvalidationSchema),
    });
    const {
        createLoading,
        startCreateLoading,
        stopCreateLoading,
        editLoading,
        startEditLoading,
        stopEditLoading,
    } = useLoader();
    const {
        rows,
        pageSize,
        pageNumber,
        setSearchKeyword,
        isLoading,
        tableRecordCounts,
        handlePaginationModelChange,
        handleSortModelChange,
        searchKeyword,
        setRows,
        fetchData,
    } = useTableLogic('levels');
    const crud = new Crud_Service();
    const [viewDetails, setViewdetails] = useState(false);
    const handleViewClick = async (props) => {
        setViewdetails(props);
    };
    const { data: floors } = useCommonFetchApi(
        'floors'
    ) || {
        data: [],
    };

    useEffect(() => {
        document.title = `Level | Admin`;
    }, []);

    //create the Card Type
    const handleSubmitLevel = async (values) => {
        startCreateLoading();
        await crud.create('levels', values, (err, res) => {
            if (err) {

                return;
            }
            if (res?.status === 201) {
                toast.success('Level Created Successfully!');
                setOpenCreate(false);
                stopCreateLoading();
                fetchData();
            } else {
                stopCreateLoading();

            }
        });
    };

    const handleCreateClickOpen = () => {
        reset();
        setOpenCreate(true);
    };
    const handleCreateClose = () => {
        reset();
        setOpenCreate(false);
    };

    const counters = [
        {
            name: 'All',
            count: tableRecordCounts?.total || 0,
        },
        {
            name: 'Active',
            count: tableRecordCounts?.active || 0,
        },
        {
            name: 'Deactivated',
            count: tableRecordCounts?.inActive || 0,
        },
    ];

    const excludeKeys = [
        'floorId',
        'floorId',
        'createdDate',
        'updatedDate',
        'levelId',
        'status'
    ];

    //handle Delete Record
    const handleDeleteClick = async (view) => {
        await crud.remove('levels', view?.levelId, (err, res) => {
            if (res?.status === 204) {
                toast.success('Level Deleted Successfully!');
                fetchData();
            } else {

            }
        });
    };

    const handleEditClick = async (view) => {
        setOpenEdit(true);
        setLoading(true);
        await crud.getSingle('levels', view?.levelId, (err, res) => {
            if (res?.status === 200) {
                Object.entries({
                    ...res?.data,
                }).forEach(([key, value]) => {
                    setValue(key, value);
                });
                setEditData(res?.data);
                setLoading(false);
            } else {

            }
        });
    };

    const handleEditLevel = async (values) => {
        startEditLoading();
        await crud.update('levels', editData?.levelId, values, (err, res) => {
            if (res?.status === 200) {
                toast.success('Level Updated Successfully!');
                setOpenEdit(false);
                stopEditLoading();
                fetchData();
            } else {
                stopEditLoading();
                setOpenEdit(true);
            }
        });
    };

    const ActionData = [
        {
            name: 'View',
            icon: <Icon icon='solar:eye-linear' />,
            onClick: (props) => handleViewClick(props),
        },
        {
            name: 'Edit',
            icon: <Icon icon='solar:pen-new-round-linear' />,
            onClick: (props) => handleEditClick(props),
        },
        {
            name: 'Delete',
            icon: <Icon icon='solar:trash-bin-2-linear' />,
            onClick: (props) => handleDeleteClick(props),
            danger: true,
        },
    ];

    const columns = [
        {
            field: 'action',
            headerName: '',
            width: 140,
            sortable: false,
            disableClickEventBubbling: false,

            renderCell: (params) => {
                return (
                    <TableActionButtons
                        Actions={ActionData?.map((action) => ({
                            ...action,
                            onClick: () => action.onClick(params.row),
                        }))}
                    />
                );
            },
        },
        {
            field: 'levelCode',
            headerName: 'Level Code',
            flex: 1,
        },
        {
            field: 'levelName',
            headerName: 'Level Name',
            flex: 1,
        },
        {
            field: 'floorName',
            headerName: 'Floor Name',
            flex: 1,
        },

        {
            field: 'status',
            headerName: 'Status',
            flex: 1,
            renderCell: (params) => {
                return (
                    <StatusBadge
                        title={params.value === 2 ? 'Active' : 'Inactive'}
                        type={params.value === 2 ? 'green' : 'red'}
                    />
                );
            },
        },
    ];

    return (
        <DashboardLayout
            title='Levels'
            actionButtons={
                <>
                    <PageHeader counters={counters} />
                </>
            }
            menu={Nav[10]?.child[6]?.children}
            hasSubmenu>
            <GlassCard>
                <Box sx={{ height: 'calc(100vh - 200px)' }}>
                    <DataTable
                        rows={rows}
                        columns={columns}
                        loading={isLoading}
                        getRowClassName={(params) =>
                            `${params.row.status === 0 && 'bg-red-50'}`
                        }
                        sortingMode='server'
                        paginationMode='server'
                        onPaginationModelChange={(model) =>
                            handlePaginationModelChange(model)
                        }
                        onSortModelChange={(model) => handleSortModelChange(model)}
                        page={pageNumber - 1}
                        pageSize={pageSize}
                        rowCount={tableRecordCounts?.total}
                        slots={{
                            toolbar: () => (
                                <Box
                                    className='p-2 w-full flex justify-between items-center'
                                    sx={{
                                        borderBottom: '1px solid',
                                        borderColor: 'border.main',
                                    }}>
                                    <Box sx={{ maxWidth: '250px' }}>
                                        <TableSearch
                                            placeholder='Search'
                                            fullWidth
                                            setSearchKeyword={setSearchKeyword}
                                            searchValue={searchKeyword}
                                        />
                                    </Box>
                                    <CreateButton name='New' onClick={handleCreateClickOpen} />
                                </Box>
                            ),
                        }}
                    />
                </Box>
            </GlassCard>

            {/* create */}

            <MuiDialogOne
                title='Create New'
                open={openCreate}
                onClose={handleCreateClose}>
                <form onSubmit={handleSubmit(handleSubmitLevel)}>
                    <Grid container spacing={3}>
                        <Grid item md={12}>
                            <FmTextField
                                name='levelName'
                                control={control}
                                label='Level Name'
                                required
                            />
                        </Grid>
                        <Grid item md={12}>
                            <FmAutoComplete
                                name='floorId'
                                control={control}
                                options={floors}
                                label='Floor'
                                displayField='floorName'
                                optionFields={['floorName']}
                                valueKey='floorId'
                                required
                            />
                        </Grid>

                        <Grid item md={12}>
                            <ActionButtons
                                onSubmit={handleSubmit(handleSubmitLevel)}
                                onCancel={handleCreateClose}
                                onReset={reset}
                                submitLoading={createLoading}
                                cancelLoading={false}
                                submitText='Create'
                            />
                        </Grid>
                    </Grid>
                </form>
            </MuiDialogOne>

            {/* view */}
            <MuiDialogOne
                title='View Details'
                open={viewDetails}
                onClose={() => setViewdetails(!viewDetails)}>
                {viewDetails && viewDetails?.levelId && (
                    <CommonView
                        url='levels'
                        id={viewDetails?.levelId}
                        excludeKeys={excludeKeys}
                        renderFields={(data, fields) => (
                            <DynamicViewFields data={data} fields={fields} />
                        )}
                    />
                )}
            </MuiDialogOne>

            {/* edit */}
            <MuiDialogOne
                title='Update'
                open={openEdit}
                onClose={() => setOpenEdit(false)}>
                {loading ? (
                    <CommonLoader />
                ) : (
                    <form onSubmit={handleSubmit(handleEditLevel)}>
                        <Grid container spacing={3}>
                            <Grid item md={12}>
                                <FmTextField
                                    name='levelName'
                                    control={control}
                                    label='Level Name'
                                    required

                                />
                            </Grid>
                            <Grid item md={12}>
                                <FmAutoComplete
                                    name='floorId'
                                    control={control}
                                    options={floors}
                                    label='Floor'
                                    displayField='floorName'
                                    optionFields={['floorName']}
                                    valueKey='floorId'
                                    required
                                />
                            </Grid>
                            <Grid item md={12}>
                                <FmAutoComplete
                                    name='status'
                                    control={control}
                                    options={statusOptions}
                                    label='Status'
                                    displayField='statusName'
                                    optionFields={['statusName']}
                                    valueKey='statusId'
                                />
                            </Grid>
                            <Grid item md={12}>
                                <ActionButtons
                                    onSubmit={handleSubmit(handleEditLevel)}
                                    onCancel={() => setOpenEdit(false)}
                                    onReset={reset}
                                    submitLoading={editLoading}
                                    cancelLoading={false}
                                    submitText='Update'
                                />
                            </Grid>
                        </Grid>
                    </form>
                )}
            </MuiDialogOne>
        </DashboardLayout>
    );
};

export default Level;
