import React from 'react';
import { Tabs, Tab } from '@mui/material';

const FmTabsOne = ({
  value,
  handleChange,
  data,
  centered = false,
  variant,
}) => {
  return (
    <Tabs
      value={value}
      onChange={handleChange}
      centered={centered}
      variant={variant || 'standard'}
      sx={{
        height: '38px',
        minHeight: 38,
        '& .MuiTabs-flexContainer': {},
        '& .MuiTab-root': {
          minHeight: 38,
          fontWeight: 500,
          padding: '0px 5px',
          textAlign: 'center',
          justifyContent: 'center',
          opacity: 0.8,
          borderRadius: (theme) => theme.shape.main,
          marginRight: '10px',

          '&:hover': {
            opacity: 1,
          },
          '&.Mui-selected': {
            fontWeight: 600,
            opacity: 1,
            color: 'text.dark',
          },
        },
      }}
    >
      {data.map((item, i) => {
        return (
          !item.hidden && <Tab value={item.id} label={item.name} key={i} />
        );
      })}
    </Tabs>
  );
};

export default FmTabsOne;
