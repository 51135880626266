import { yupResolver } from '@hookform/resolvers/yup';
import { Grid } from '@mui/material';
import dayjs from 'dayjs';
import React, { useEffect } from 'react';
import { useForm } from 'react-hook-form';
import ActionButtons from '../../../components/_form/ActionButtons.tsx';
import FmSearchableSelect from '../../../components/_mui/FmSearchableSelect.tsx';
import FmTextField from '../../../components/_mui/FmTextField.tsx';
import FmTimePicker from '../../../components/_mui/FmTimePicker.tsx';
import MuiDialogOne from '../../../components/_mui/MuiDialogOne.jsx';
import { employeeAttendenceSchema } from '../../../utils/CommonvalidationSchemas.tsx';
const AttendanceTimePicker = (props) => {
  const {
    selectedDayData,
    handleSubmitAttendance,
    setSelectedDayData,
    setDialogData,
    dialogData,
    dialogOpen,
    setDialogOpen,
    isNew,
    createLoading,
    handleDialogClose,
  } = props;

  const {
    control,
    handleSubmit,
    reset,
    setValue,
    getValues,
    trigger,
    watch,
    clearErrors,
  } = useForm({
    mode: 'onChange',
    resolver: yupResolver(employeeAttendenceSchema(isNew)),
  });

  const startTime = watch('startTime');

  const values = getValues();

  useEffect(() => {
    reset();

    if (selectedDayData) {
      Object.entries(selectedDayData).forEach(([key, value]) => {
        if (key === 'startTime' || key === 'endTime') {
          setValue(key, value ? dayjs(value, 'HH:mm') : null);
        } else {
          setValue(key, value);
        }
      });

      // setStartTime(selectedDayData?.startTime || '');
      // setEndTime(selectedDayData?.endTime || '');
      setDialogOpen(selectedDayData !== null || isNew);
    } else {
      setDialogOpen(false);
    }
  }, [selectedDayData, setValue]);

  return (
    <form onSubmit={handleSubmit(handleSubmitAttendance)}>
      <MuiDialogOne
        open={dialogOpen || isNew}
        onClose={handleDialogClose}
        title={
          !selectedDayData || selectedDayData?.employeeMonthlyAttendanceId === 0
            ? 'Add Attendance'
            : 'Update Attendance'
        }
      >
        <Grid container spacing={2}>
          <Grid item md={6}>
            <FmSearchableSelect
              control={control}
              name='employeeId'
              label='Employee Name'
              defaultValue={{ firstName: dialogData?.employeeName }}
              apiUrl='employees'
              valueField='employeeId'
              headerField={['Employee Code', 'Employee Name']}
              labelField={['employeeCode', 'firstName']}
              showField={['firstName']}
              required
              disabled={!isNew}
            />
          </Grid>
          <Grid item md={6}>
            <FmSearchableSelect
              name='contractId'
              control={control}
              apiUrl='contracts'
              valueField='contractId'
              headerField={['Contract Code', 'Contract Name']}
              labelField={['contractCode', 'contractName']}
              showField={['contractCode', 'contractName']}
              label={'Contract'}
            />
          </Grid>
          <Grid item md={6}>
            <FmSearchableSelect
              name='projectId'
              control={control}
              apiUrl='projects'
              valueField='projectId'
              headerField={['Project Code', 'Project Name']}
              labelField={['projectCode', 'projectName']}
              showField={['projectCode', 'projectName']}
              label={'Project'}
            />
          </Grid>
          <Grid item md={6}>
            <FmSearchableSelect
              name='departmentId'
              control={control}
              apiUrl='departments'
              valueField='departmentId'
              headerField={['Department Code', 'Department Name']}
              labelField={['departmentCode', 'departmentName']}
              showField={['departmentCode', 'departmentName']}
              label={'Department'}
            />
          </Grid>
          <Grid item md={6}>
            <FmSearchableSelect
              name='inCharge'
              control={control}
              apiUrl='loginusers'
              valueField='loginUserId'
              headerField={['Email', 'Name']}
              labelField={['emailId', 'loginUserName']}
              showField={['emailId', 'loginUserName']}
              label={'In Charge'}
            />
          </Grid>
          <Grid item md={6}>
            <FmSearchableSelect
              name='costCenterId'
              control={control}
              apiUrl='costcenters'
              valueField='costCenterId'
              headerField={['CostCenter Code', 'CostCenter Name']}
              labelField={['costCenterCode', 'costCenterName']}
              showField={['costCenterCode', 'costCenterName']}
              label={'CostCenter'}
            />
          </Grid>
          <Grid item md={6}>
            <FmSearchableSelect
              name='salesOrderId'
              control={control}
              apiUrl='salesorders'
              valueField='salesOrderId'
              headerField={['SalesOrder Code', 'salesOrder Name']}
              labelField={['autoSalesOrderCode', 'salesOrderName']}
              showField={['autoSalesOrderCode', 'salesOrderName']}
              label={'Sales Order'}
            />
          </Grid>
          <Grid item md={6}>
            <FmTextField
              control={control}
              name='date'
              label='Date'
              defaultValue={dayjs(selectedDayData?.date).format('DD-MM-YYYY')}
              readOnly
            />
          </Grid>
          <Grid item md={6}>
            <FmTimePicker
              control={control}
              name='startTime'
              label='Start Time'
              defaultValue={null}
              required={true}
              onChangeProp={(value) => {
                trigger('endTime');
              }}
            />
          </Grid>
          <Grid item md={6}>
            <FmTimePicker
              control={control}
              name='endTime'
              label='End Time'
              defaultValue={null}
              required={true}
            />
          </Grid>
          <Grid item md={12}>
            <ActionButtons
              onSubmit={handleSubmit(handleSubmitAttendance)}
              onReset={() => {
                reset();
              }}
              submitLoading={createLoading}
              submitText='Create'
            />
          </Grid>
        </Grid>
      </MuiDialogOne>
    </form>
  );
};

export default AttendanceTimePicker;
