import apiInstance from './ApiService';

//Remove Empty Parameters Not Having Value
const filterEmptyParams = (params) => {
  const filteredParams = { ...params };

  // Loop through each key in the params
  Object.keys(filteredParams).forEach((key) => {
    // Remove empty or undefined values
    if (filteredParams[key] === undefined || filteredParams[key] === '') {
      delete filteredParams[key];
    }

    // If the key is 'filters', process it into the required format
    if (key === 'filters' && Array.isArray(filteredParams[key])) {
      // Filter out any empty or invalid filter objects
      filteredParams[key] = filteredParams[key].filter(
        (filter) =>
          filter.id && filter.value !== undefined && filter.value !== ''
      );

      // If filters are valid, map them to the JSON format and encode it
      if (filteredParams[key].length > 0) {
        const formattedFilters = JSON.stringify(filteredParams[key]);
        // URL encode the JSON string for safe use in a URL
        filteredParams[key] = encodeURIComponent(formattedFilters);
      } else {
        // If filters are empty after cleanup, remove the key
        delete filteredParams[key];
      }
    }
  });

  return filteredParams;
};

class Crud_Service {
  //getall function
  getAll = async (url, params, callback) => {
    const qparms = filterEmptyParams(params);

    await apiInstance
      .getAll(url, qparms)
      .then((res) => {
        callback(null, res);
      })
      .catch((err) => {
        callback(err, null);
      });
  };

  //getsingle function
  getSingle = async (url, params, callback) => {
    await apiInstance
      .getSingle(url, params)
      .then((res) => {
        callback(null, res);
      })
      .catch((err) => {
        callback(err, null);
      });
  };

  //create function
  create = async (url, data, callback) => {
    await apiInstance
      .create(url, data)
      .then((res) => {
        callback(null, res);
      })
      .catch((err) => {
        callback(err, null);
      });
  };

  //update function
  update = async (url, params = {}, data, callback) => {
    await apiInstance
      .update(url, params, data)
      .then((res) => {
        callback(null, res);
      })
      .catch((err) => {
        callback(err, null);
      });
  };

  //Patch update function
  patch = async (url, params = {}, data, callback) => {
    await apiInstance
      .patch(url, params, data)
      .then((res) => {
        callback(null, res);
      })
      .catch((err) => {
        callback(err, null);
      });
  };

  //remove function
  remove = async (url, params, callback) => {
    await apiInstance
      .remove(url, params)
      .then((res) => {
        callback(null, res);
      })
      .catch((err) => {
        callback(err, null);
      });
  };

  removeWithData = async (url, data, callback) => {
    await apiInstance
      .delete(url, data)
      .then((res) => {
        callback(null, res);
      })
      .catch((err) => {
        callback(err, null);
      });
  };
}

export default Crud_Service;
