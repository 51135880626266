import React, { useState } from 'react';
import GlassCard from '../../../components/small/GlassCard.tsx';
import { Grid, Box } from '@mui/material';
import { LocalizationProvider } from '@mui/x-date-pickers';
import { useForm } from 'react-hook-form';
import { AdapterDayjs } from '@mui/x-date-pickers/AdapterDayjs';
import dayjs from 'dayjs';
import FmAutoComplete from '../../../components/_mui/FmAutoComplete.tsx';
import FmTextField from '../../../components/_mui/FmTextField.tsx';
import Crud_Service from '../../../apis/CrudService.jsx';
import useLoader from '../../../components/helpers/UseLoader.tsx';
import { yupResolver } from '@hookform/resolvers/yup';
import ActionButtons from '../../../components/_form/ActionButtons.tsx';
import { attachments } from '../../../utils/CommonVariables.tsx';
import { toast } from 'react-toastify';
import { useNavigate } from 'react-router-dom';
import { AccommodationSchema } from '../../../utils/CommonvalidationSchemas.tsx';
import DashboardLayout from '../../../components/DashboardLayout.tsx';
import useCommonFetchApi from '../../../components/helpers/useCommonFetchApi.tsx';
import UploadModalBox from '../../../components/upload/UploadModalBox.tsx';
import FmDatePicker from '../../../components/_mui/FmDatePicker.tsx';

const GridValue = 2.4;

const AddAccommodation = (props) => {
  const [fileInputs, setFileInputs] = useState(attachments);
  const [uploadLoading, setUploadLoading] = useState([]);
  const navigate = useNavigate();
  const [files, setFiles] = useState([]);
  const [uploadedFileIds, setUploadedFileIds] = useState([]);
  //TODO:For Future Use
  // const fileCount = attachments?.filter(
  //   (component) => component?.fileName
  // )?.length;
  const { control, handleSubmit, reset } = useForm({
    mode: 'onChange',
    resolver: yupResolver(AccommodationSchema),
  });
  const { startCreateLoading, createLoading, stopCreateLoading } = useLoader();
  const crud = new Crud_Service();

  const handleFileIds = (fileIds) => {
    setUploadedFileIds(fileIds);
  };

  //get All Apis
  const { data: locations } = useCommonFetchApi('locations');
  const { data: premisetypes } = useCommonFetchApi('premisetypes');
  const { data: premisepurposes } = useCommonFetchApi('premisepurposes');

  // Function to handle file upload
  const handleFileUpload = async (e, index) => {
    const file = e.target.files[0];
    if (!file) return;

    if (file) {
      const newLoadingState = [...uploadLoading];
      newLoadingState[index] = true;
      setUploadLoading(newLoadingState);

      const fileFormData = new FormData();
      fileFormData.append('file', file);
      fileFormData.append('fileTypeId', 50);

      await crud.create(`/files/upload`, fileFormData, (__err, res) => {
        if (res?.status === 200) {
          newLoadingState[index] = false;
          setUploadLoading(newLoadingState);
          setFileInputs((prevFiles) => {
            const updatedFiles = [...prevFiles];
            updatedFiles[index].fileId = res?.data?.fileId;
            updatedFiles[index].fileName = res?.data?.originalFileName;
            return updatedFiles;
          });
        } else {
          newLoadingState[index] = false;
          setUploadLoading(newLoadingState);
        }
      });
    }
  };

  const handleSubmitAccommodation = async (values) => {
    startCreateLoading();

    const combinedData = {
      ...values,
      duration: values?.duration ? Number(values?.duration) : 0,
      noOfRoom: values?.noOfRoom ? Number(values?.noOfRoom) : 0,
      noOfBedSpace: values?.noOfBedSpace ? Number(values?.noOfBedSpace) : 0,
      size: values?.size ? Number(values?.size) : 0,
      securityDeposit: values?.securityDeposit
        ? Number(values?.securityDeposit)
        : 0,
      rentalCharges: values?.rentalCharges ? Number(values?.rentalCharges) : 0,
      laundryCharges: values?.laundryCharges
        ? Number(values?.laundryCharges)
        : 0,
      chargesMonth: values?.chargesMonth ? Number(values?.chargesMonth) : 0,
      negotiation: values?.negotiation ? Number(values?.negotiation) : 0,
      startDate: dayjs(values?.startDate).format('YYYY-MM-DD'),
      endDate: dayjs(values?.endDate).format('YYYY-MM-DD'),
      // fileUploadIds: fileInputs
      //   .filter((file) => file?.fileId !== null && file?.fileId !== undefined) // Remove null or undefined fileId entries
      //   .map((file) => file?.fileId),
      fileUploadIds: uploadedFileIds,
    };

    await crud.create('accommodations', combinedData, (_err, res) => {
      if (res?.status === 201) {
        toast.success('Accommodation Created Successfully!');
        stopCreateLoading();
        navigate(-1);
        reset();
        setFileInputs(
          attachments?.map((attachment) => ({
            id: attachment?.id,
            file: null,
            fileName: '',
            fileId: null,
          }))
        );
      } else {
        stopCreateLoading();
      }
    });
  };

  return (
    <DashboardLayout title='Create Accommodation'>
      <LocalizationProvider dateAdapter={AdapterDayjs}>
        <GlassCard className='mb-8 p-4'>
          <form onSubmit={handleSubmit(handleSubmitAccommodation)}>
            <Grid container spacing={3}>
              <Grid item md={GridValue}>
                <FmTextField
                  name='accommodationName'
                  control={control}
                  label='Accommodation Name'
                />
              </Grid>
              <Grid item md={GridValue}>
                <FmAutoComplete
                  name='locationId'
                  control={control}
                  options={locations}
                  label='Location'
                  displayField='locationName'
                  optionFields={['locationName']}
                  valueKey='locationId'
                />
              </Grid>
              <Grid item md={GridValue}>
                <FmAutoComplete
                  name='premiseTypeId'
                  control={control}
                  options={premisetypes}
                  label='Premise Types'
                  displayField='premiseTypeName'
                  optionFields={['premiseTypeName']}
                  valueKey='premiseTypeId'
                />
              </Grid>
              <Grid item md={GridValue}>
                <FmAutoComplete
                  name='premisePurposeId'
                  control={control}
                  options={premisepurposes}
                  label='Premise Purposes'
                  displayField='premisePurposeName'
                  optionFields={['premisePurposeName']}
                  valueKey='premisePurposeId'
                />
              </Grid>
              <Grid item md={GridValue}>
                <FmTextField
                  name='duration'
                  control={control}
                  label='Duration'
                />
              </Grid>
              <Grid item md={GridValue}>
                <FmTextField
                  name='noOfRoom'
                  control={control}
                  label='No. of Room'
                />
              </Grid>
              <Grid item md={GridValue}>
                <FmTextField
                  name='noOfBedSpace'
                  control={control}
                  label='No. of Bed Space'
                />
              </Grid>
              <Grid item md={GridValue}>
                <FmTextField name='size' control={control} label='Size' />
              </Grid>
              <Grid item md={GridValue}>
                <FmDatePicker
                  name='startDate'
                  control={control}
                  label='Start Date'
                  defaultValue={dayjs().add(-1, 'day')}
                />
                {/* <Controller
                  name='startDate'
                  control={control}
                  defaultValue={null}
                  render={({ field: { onChange, value } }) => (
                    <DatePicker
                      label='Start Date'
                      value={value ? dayjs(value) : null}
                      format='DD/MM/YYYY'
                      onChange={(newValue) => {
                        onChange(newValue);
                        setStartDate(newValue);
                      }}
                    />
                  )}
                /> */}
              </Grid>
              <Grid item md={GridValue}>
                <FmDatePicker
                  name='endDate'
                  control={control}
                  label='End Date'
                />
                {/* <Controller
                  name='endDate'
                  control={control}
                  defaultValue={null}
                  render={({ field: { onChange, value } }) => (
                    <DatePicker
                      label='End Date'
                      value={value ? dayjs(value) : null}
                      format='DD/MM/YYYY'
                      minDate={startDate ? dayjs(startDate) : null}
                      onChange={(newValue) => {
                        onChange(newValue);
                      }}
                    />
                  )}
                /> */}
              </Grid>
              <Grid item md={GridValue}>
                <FmTextField
                  name='beneficiaryDetails'
                  control={control}
                  label='Beneficiary Details'
                />
              </Grid>
              <Grid item md={GridValue}>
                <FmTextField
                  name='contactInformation'
                  control={control}
                  label='Contact Information'
                />
              </Grid>
              <Grid item md={GridValue}>
                <FmTextField
                  name='contractNo'
                  control={control}
                  label='Contract No'
                />
              </Grid>
              <Grid item md={GridValue}>
                <FmTextField
                  name='securityDeposit'
                  control={control}
                  label='Security Deposit'
                  pattern='Decimal'
                />
              </Grid>
              <Grid item md={GridValue}>
                <FmTextField
                  name='rentalCharges'
                  control={control}
                  label='Rental Charges'
                  pattern='Decimal'
                />
              </Grid>
              <Grid item md={GridValue}>
                <FmTextField
                  name='laundryCharges'
                  control={control}
                  label='Laundry Charges'
                  pattern='Decimal'
                />
              </Grid>
              <Grid item md={GridValue}>
                <FmTextField
                  name='chargesMonth'
                  control={control}
                  label='Charge Month'
                  pattern='Decimal'
                />
              </Grid>
              <Grid item md={GridValue}>
                <FmTextField
                  name='negotiation'
                  control={control}
                  label='Negotiation'
                  pattern='Decimal'
                />
              </Grid>
              <Grid item md={4.8}>
                <UploadModalBox
                  onSubmitFiles={handleFileIds}
                  files={files}
                  setFiles={setFiles}
                />
              </Grid>
              <Grid item md={5}>
                <FmTextField
                  name='remarks'
                  control={control}
                  label='Remarks'
                  multiline={true}
                  rows={2}
                  maxRows={3}
                />
              </Grid>
            </Grid>
            {/* <Grid container spacing={3} className='mt-1'>
              <Grid item md={10}>
                <Box className='flex flex-wrap gap-2 items-center'>
                  {/* {attachments?.map((_file, index) => (
                    <FmFileInput
                      name='fileId'
                      documentName={fileInputs[index].fileName}
                      onChange={(e) => handleFileUpload(e, index)}
                      loading={uploadLoading[index]}
                    />
                  ))} 
                  <UploadModalBox
                    onSubmitFiles={handleFileIds}
                    files={files}
                    setFiles={setFiles}
                  />
                </Box>
              </Grid>
            </Grid> */}
            <ActionButtons
              onSubmit={handleSubmit(handleSubmitAccommodation)}
              onCancel={() => navigate(-1)}
              submitText='Create'
              cancelText='Go Back'
              onReset={() => {
                reset(); // Reset the form fields
                setFileInputs(
                  attachments?.map((attachment) => ({
                    id: attachment?.id,
                    file: null,
                    fileName: '',
                    fileId: null,
                  }))
                );
              }}
              submitLoading={createLoading}
              cancelLoading={false}
            />
          </form>
        </GlassCard>
      </LocalizationProvider>
    </DashboardLayout>
  );
};

export default AddAccommodation;
