import React, { useEffect, useState } from 'react';
import { Box, Button } from '@mui/material';
import { Icon } from '@iconify/react/dist/iconify.js';
import FileViewerVendorDocuments from '../elements/FileViewerVendorDocuments.tsx';
import apiInstance from '../../apis/ApiService.jsx';
import CommonLoader from '../page/CommonLoader.jsx';

const NoFiles = ({ icons, name, desc, button }) => {
  return (
    <Box className='flex flex-col items-center justify-center h-full gap-2 p-2 text-center'>
      <Box className='text-6xl opacity-50'>{icons}</Box>
      <Box className='text-base opacity-50 font-semibold'>{name}</Box>
      <Box className='opacity-50'>{desc}</Box>
      {button}
    </Box>
  );
};

const FilePreviewUpload = ({
  outerFile,
  name,
  desc,
  file,
  disableFileName,
  editData,
}) => {
  const [uploadedFiles, setUploadedFiles] = useState<File | null>(null);
  const [selectedFile, setSelectedFile] = useState<File | null>(null);
  const [previewImage, setPreviewImage] = useState(null);
  const [loading, setLoading] = useState(false);

  useEffect(() => {
    if (editData?.vendorDocumentAttachments?.length > 0) {
      const filteredAttachment = editData?.vendorDocumentAttachments?.find(
        (attachment) => attachment?.attachmentTypeId === 2
      );

      if (filteredAttachment) {
        handleDownloadFile(
          filteredAttachment?.fileId,
          filteredAttachment?.fileName
        );
      }
    }
  }, [editData]);

  const handleDownloadFile = async (fileId, fileName) => {
    setLoading(true);
    if (!fileId) return;
    try {
      const imageUrl = await apiInstance.getFiles(`files/download/${fileId}`);
      setPreviewImage({
        fileName: fileName,
        fileUrl: imageUrl,
      });
      setLoading(false);
    } catch (err) {
      setLoading(false);
      console.error('Error downloading file:', err);
    }
  };

  const handleFileUpload = (event: React.ChangeEvent<HTMLInputElement>) => {
    const file = event.target.files?.[0];
    if (file) {
      setUploadedFiles(file);
      setSelectedFile(file);
      outerFile(file);
    }
  };

  const handleRemoveFile = () => {
    if (uploadedFiles) {
      setUploadedFiles(null);
      setSelectedFile(null);
      outerFile(null);
    }
  };

  const handleFileSelect = (file: File) => {
    setSelectedFile(file);
    outerFile(file);
  };


  return (
    <Box
      className='w-full h-full'
      sx={{
        borderRight: '1px solid',
        borderColor: 'border.main',
      }}
    >
      {/* Right Panel: File Viewer */}

      {(selectedFile || previewImage) && loading ? (
        <CommonLoader />
      ) : selectedFile || previewImage ? (
        <FileViewerVendorDocuments
          documents={[
            {
              uri: selectedFile
                ? URL.createObjectURL(selectedFile)
                : previewImage?.fileUrl,
              name: selectedFile?.name || previewImage?.fileName,
            },
          ]}
          name={disableFileName ? '' : selectedFile?.name}
          removeOnclick={() => handleRemoveFile()}
        />
      ) : (
        <NoFiles
          icons={<Icon icon='dashicons:pdf' width='65' height='65' />}
          name={'Select a file to preview.'}
          button={
            !selectedFile && (
              <Box className='mb-3'>
                {/* Hidden File Input */}
                <input
                  type='file'
                  id='file-upload'
                  accept='application/pdf'
                  style={{ display: 'none' }}
                  onChange={handleFileUpload}
                />

                {/* Upload Button */}
                <Button
                  variant='contained'
                  startIcon={
                    <Icon icon='ic:baseline-plus' width='24' height='24' />
                  }
                  onClick={() =>
                    document.getElementById('file-upload')?.click()
                  }
                  className='h-[40px] font-semibold min-w-28'
                  color='secondary'
                >
                  Upload
                </Button>
              </Box>
            )
          }
        />
      )}
    </Box>
  );
};

export default FilePreviewUpload;
