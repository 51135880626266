import { TextField } from '@mui/material';
import { AdapterDayjs } from '@mui/x-date-pickers/AdapterDayjs';
import { LocalizationProvider } from '@mui/x-date-pickers/LocalizationProvider';
import { TimePicker } from '@mui/x-date-pickers/TimePicker';
import dayjs, { Dayjs } from 'dayjs';
import * as React from 'react';
import { Control, Controller } from 'react-hook-form';
import { useSelector } from 'react-redux';

interface BasicTimePickerProps {
  control: Control<any>;
  name: string;
  label?: string;
  defaultValue?: Dayjs | null;
  required?: boolean;
  width?: string;
  onChangeProp?: () => void;
}

const FmTimePicker: React.FC<BasicTimePickerProps> = ({
  control,
  name,
  label = 'Select Time',
  defaultValue = null,
  required = false,
  width = '100%',
  onChangeProp,
}) => {
  const { timeFormat } = useSelector(
    (state) => state?.time
  );

  return (
    <LocalizationProvider dateAdapter={AdapterDayjs}>
      <Controller
        name={name}
        control={control}
        defaultValue={
          defaultValue
            ? dayjs(defaultValue, 'HH:mm:ss').format('HH:mm:ss')
            : null
        }
        render={({
          field: { onChange, value, ref },
          fieldState: { error },
        }) => (
          <TimePicker
            label={label}
            // value={
            //   value
            //     ? dayjs(value, 'HH:mm:ss').isValid()
            //       ? dayjs(value, 'HH:mm:ss')
            //       : dayjs()
            //     : dayjs()
            // }
            
         
            ampm={timeFormat === '12h'}
            value={value ? dayjs(value, 'hh:mm:ss') : null}
            onChange={(newValue) => {
              if (newValue) {
                const formattedValue = newValue.format('HH:mm:ss');
                onChange(formattedValue);
                onChangeProp && onChangeProp(formattedValue);
              } else {
                onChange(null);
              }
            }}
            renderInput={(params) => (
              <TextField
                {...params}
                inputRef={ref}
                error={!!error}
                helperText={error ? error.message : null}
                required={required}
                sx={{ width }}
                fullWidth
              />
            )}
            slotProps={{
              textField: {
                fullWidth: true,
                variant: 'filled',
                size: 'small',
                required: required,
                sx: { width },
                inputRef: ref,
                error: !!error,
                helperText: error ? error.message : null,
              },
            }}
          />
        )}
      />
    </LocalizationProvider>
  );
};

export default FmTimePicker;
