import React from 'react';
import { Box } from '@mui/material';
import { StatusBadge } from '../_form/FormElements.jsx';
import FmViewDateField from '../_mui/FmViewDateField.tsx';

const ExpiredContractDateColumns = ({ value, terminationDate }) => {
  const ExpiryDateContractColor = (value) => {
    const currentDate = new Date();
    const targetDate = new Date(value);
    const timeDiff = targetDate - currentDate;
    const diffDays = Math.ceil(timeDiff / (1000 * 60 * 60 * 24));

    if (terminationDate) {
      return (
        <StatusBadge
          title={
            <Box className='flex items-center gap-1'>
              Terminated <FmViewDateField value={terminationDate} />{' '}
            </Box>
          }
          type='red'
          fontNormal
        />
      );
    } else if (diffDays < 0) {
      return <StatusBadge title={'Expired'} type='orange' fontNormal />;
    } else if (diffDays <= 60) {
      return <StatusBadge title={'Expires Soon'} type='blue' fontNormal />;
    } else {
      return null;
    }
  };

  return (
    ExpiryDateContractColor(value, terminationDate) && (
      <Box className='text-[9px]'>
        {ExpiryDateContractColor(value, terminationDate)}
      </Box>
    )
  );
};

export default ExpiredContractDateColumns;
