import { Box } from '@mui/material';
import { MaterialReactTable } from 'material-react-table';
import React from 'react';
import DashboardLayout from '../../../components/DashboardLayout.tsx';
import GlassCard from '../../../components/small/GlassCard.tsx';
import MRTExpandTableStyle from '../../../components/table/MRTExpandTableStyle.tsx';

const MuiTableCustomClass = {
  muiTableBodyCellProps: {
    className: 'bg-cyan-50 border-cyan-300',
  },
  muiTableHeadCellProps: {
    className: 'bg-cyan-100 border-cyan-300',
  },
  muiTableFooterCellProps: {
    className: 'bg-cyan-100 border-cyan-300',
  },
};
const data = [
  {
    ParentCode: 'P001',
    AccountID: 'A001',
    Code: 'C001',
    particulars: 'Particular 1',
    parentCode: 'PC001',
    level: 'L1',
    debitOpeningBalance: 10000,
    creditOpeningBalance: 5000,
    debitForPeriod: 3000,
    creditForPeriod: 1500,
    debitClosingBalance: 13000,
    creditClosingBalance: 6500,
    revenue: 5000,
    cost: 2000,
    gp: 3000,
    gppercentage: 60,
    ga: 1000,
    np: 500,
    nppercentage: 10,
  },
  {
    ParentCode: 'P001',
    AccountID: 'A00122',
    Code: 'C00122',
    particulars: 'Particular 122',
    parentCode: 'PC00122',
    level: 'L1',
    debitOpeningBalance: 10030,
    creditOpeningBalance: 5400,
    debitForPeriod: 3050,
    creditForPeriod: 1560,
    debitClosingBalance: 13060,
    creditClosingBalance: 6560,
    revenue: 50060,
    cost: 20040,
    gp: 30030,
    gppercentage: 630,
    ga: 10080,
    np: 5090,
    nppercentage: 100,
  },
  {
    ParentCode: 'P002',
    AccountID: 'A002',
    Code: 'C002',
    particulars: 'Particular 2',
    parentCode: 'PC002',
    level: 'L2',
    debitOpeningBalance: 1503,
    creditOpeningBalance: 7502,
    debitForPeriod: 4300,
    creditForPeriod: 2060,
    debitClosingBalance4: 19500,
    creditClosingBalance: 9550,
    revenue: 70000,
    cost: 30000,
    gp: 40000,
    gppercentage: 570,
    ga: 15030,
    np: 7000,
    nppercentage: 100,
  },
  {
    ParentCode: 'P003',
    AccountID: 'A003',
    Code: 'C003',
    particulars: 'Particular 3',
    parentCode: 'PC003',
    level: 'L3',
    debitOpeningBalance: 20000,
    creditOpeningBalance: 10000,
    debitForPeriod: 6000,
    creditForPeriod: 3000,
    debitClosingBalance: 26000,
    creditClosingBalance: 1300,
    revenue: 100000,
    cost: 40000,
    gp: 60000,
    gppercentage: 60,
    ga: 2000,
    np: 1000,
    nppercentage: 10,
  },
];

const ChartOfAccounts = () => {
  document.title = 'Chart Of Accounts | Financial';

  const columns = [
    {
      accessorKey: 'ParentCode',
      header: 'Parent Code',
      size: 220,
      enableSorting: false,
      GroupedCell: ({ cell }) => (
        <Box className='font-semibold' color={'text.dark'}>
          {cell.getValue()}
        </Box>
      ),
    },

    {
      accessorKey: 'AccountID',
      header: 'Account Id',
      size: 550,
      Cell: ({ cell }) => (
        <Box className='font-semibold' color={'text.dark'}>
          {cell.getValue()}
        </Box>
      ),
    },

    {
      accessorKey: 'Code',
      header: 'Code',
      size: 140,
      Cell: ({ cell }) => (
        <Box className='font-semibold' color={'text.dark'}>
          {cell.getValue()}
        </Box>
      ),
    },
    {
      accessorKey: 'particulars',
      header: 'Particulars ',
      size: 120,
      Cell: ({ cell }) => (
        <Box className='font-semibold' color={'text.dark'}>
          {cell.getValue()}
        </Box>
      ),
    },
    {
      accessorKey: 'parentCode',
      header: 'Parent Code',
      size: 90,
      Cell: ({ cell }) => (
        <Box className='font-semibold' color={'text.dark'}>
          {cell.getValue()}
        </Box>
      ),

      ...MuiTableCustomClass,
    },
    {
      accessorKey: 'level',
      header: 'Level',
      size: 80,
      Cell: ({ cell }) => (
        <Box className='font-semibold' color={'text.dark'}>
          {cell.getValue()}
        </Box>
      ),

      ...MuiTableCustomClass,
    },
    {
      accessorKey: 'debitOpeningBalance',
      header: 'Debit Opening Balance (AED)',
      size: 90,
      Cell: ({ cell }) => (
        <Box className='font-semibold' color={'text.dark'}>
          {cell.getValue()}
        </Box>
      ),

      ...MuiTableCustomClass,
    },
    {
      accessorKey: 'creditOpeningBalance',
      header: 'Credit Opening Balance (AED)',
      size: 90,
      Cell: ({ cell }) => (
        <Box className='font-semibold' color={'text.dark'}>
          {cell.getValue()}
        </Box>
      ),

      ...MuiTableCustomClass,
    },
    {
      accessorKey: 'debitForPeriod',
      header: 'Debit For Period',
      size: 90,
      Cell: ({ cell }) => (
        <Box className='font-semibold' color={'text.dark'}>
          {cell.getValue()}
        </Box>
      ),

      ...MuiTableCustomClass,
    },
    {
      accessorKey: 'creditForPeriod',
      header: 'Credit For Period',
      size: 90,
      Cell: ({ cell }) => (
        <Box className='font-semibold' color={'text.dark'}>
          {cell.getValue()}
        </Box>
      ),

      ...MuiTableCustomClass,
    },
    {
      accessorKey: 'debitClosingBalance',
      header: 'Debit Closing Balance (AED)',
      size: 90,
      Cell: ({ cell }) => (
        <Box className='font-semibold' color={'text.dark'}>
          {cell.getValue()}
        </Box>
      ),

      ...MuiTableCustomClass,
    },
    {
      accessorKey: 'creditClosingBalance',
      header: 'Credit Closing Balance (AED)',
      size: 90,
      Cell: ({ cell }) => (
        <Box className='font-semibold' color={'text.dark'}>
          {cell.getValue()}
        </Box>
      ),

      ...MuiTableCustomClass,
    },
  ];

  return (
    <DashboardLayout title='Client Reports'>
      <Box className='flex flex-col w-full h-full gap-2'>
        <GlassCard className='overflow-hidden h-full-css'>
          <MRTExpandTableStyle enableFullHeight>
            <Box
              sx={{
                '& .MuiTableRow-footer': {
                  '& td': {
                    verticalAlign: 'bottom',
                    fontWeight: 500,
                  },
                },

                '& .MuiTableCell-root[data-index="1"]': {
                  width: '35px',
                  minWidth: '35px',
                  maxWidth: '35px',
                  '& .MuiIconButton-root': {
                    padding: 0,
                    width: '20px',
                    height: '20px',
                    '&.Mui-disabled': {
                      display: 'none',
                    },
                  },
                },
              }}
            >
              <MaterialReactTable
                columns={columns}
                data={data}
                enableFullScreenToggle={false}
                enableColumnActions={false}
                enableDensityToggle={false}
                enableHiding={false}
                enableGlobalFilter={false}
                enableFilters={false}
                enableColumnDragging={false}
                enableGrouping
                initialState={{
                  pagination: { pageSize: 20, pageIndex: 0 },
                  grouping: ['ParentCode'],
                  expanded: true,
                }}
                enablePagination={true}
                muiTableContainerProps={{
                  sx: {
                    maxHeight: '100%',
                    flex: 1,
                  },
                }}
                muiTableBodyRowProps={({ row }) => ({
                  sx: {
                    backgroundColor: row.getIsGrouped() ? '#f0f0f0' : 'inherit',
                  },
                })}
                enableStickyHeader
                enableStickyFooter
                enableTopToolbar={false}
              />
            </Box>
          </MRTExpandTableStyle>
        </GlassCard>
      </Box>
    </DashboardLayout>
  );
};

export default ChartOfAccounts;
