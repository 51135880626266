import React from 'react';
import { Tab, Tabs } from '@mui/material';

const FmTabs = ({ value, onChange, scrollButtons, variant, TabsList }) => {
  return (
    <Tabs
      value={value}
      onChange={onChange}
      textColor='primary'
      indicatorColor='primary'
      sx={{
        minHeight: '35px',
        '& .MuiTab-root.MuiButtonBase-root': {
          minHeight: '35px',
          py: 1.5,
          fontWeight: 600,
        },

        '& .MuiButtonBase-root.MuiTabScrollButton-root': {
          minHeight: '35px',
          justifyContent: 'center',
          position: 'relative',
          '& svg': {
            margin: 0,
            fontSize: '30px',
          },
          '&.Mui-disabled': {
            opacity: 0.3,
          },
        },
      }}
      scrollButtons={scrollButtons}
      variant={variant}
    >
      {TabsList?.map((item) => {
        return <Tab value={item.id} label={item.name} key={item.name} />;
      })}
    </Tabs>
  );
};

export default FmTabs;
