import React, { useEffect } from 'react';
import GlassCard from '../../../../components/small/GlassCard.tsx';
import { Grid } from '@mui/material';
import Crud_Service from '../../../../apis/CrudService.jsx';
import FmTextField from '../../../../components/_mui/FmTextField.tsx';
import FmSearchableSelect from '../../../../components/_mui/FmSearchableSelect.tsx';
import ActionButtons from '../../../../components/_form/ActionButtons.tsx';
import useLoader from '../../../../components/helpers/UseLoader.tsx';
import { useForm } from 'react-hook-form';
import FmDatePicker from '../../../../components/_mui/FmDatePicker.tsx';
import { toast } from 'react-toastify';
import dayjs from 'dayjs';
import { yupResolver } from '@hookform/resolvers/yup';
import { AddSalesRetentionSehema } from '../../../../utils/CommonvalidationSchemas.tsx';
import { useNavigate, useParams } from 'react-router-dom';

const GridSize = 2.4;

const EditSalesRetention = (props) => {
  const { control, handleSubmit, reset } = useForm({
    resolver: yupResolver(AddSalesRetentionSehema),
    mode: 'onChange',
  });
  const { startCreateLoading, createLoading, stopCreateLoading } = useLoader();
  const crud = new Crud_Service();
  const navigate = useNavigate();
  const { id } = useParams();
  const [sr, setSr] = React.useState({});
  useEffect(() => {
    fetchSR();
  }, []);
  const fetchSR = async () => {
    await crud.getSingle('salesretentions', id, (err, res) => {
      if (res) {
        setSr(res?.data);
        reset(res?.data);
      }
    });
  };
  const handleSubmitSalesRetention = async (values) => {
    startCreateLoading();
    const combinedData = {
      ...values,
      salesRetentionDate: dayjs(values?.salesRetentionDate).format(
        'YYYY-MM-DD'
      ),
      salesOrderDate: dayjs(values?.salesOrderDate).format('YYYY-MM-DD'),
      dueDate: dayjs(values?.dueDate).format('YYYY-MM-DD'),
      workOrderDate: dayjs(values?.workOrderDate).format('YYYY-MM-DD'),
    };

    await crud.update('salesretentions', id, combinedData, (err, res) => {
      stopCreateLoading();
      if (err) {
        return;
      }
      if (res?.status === 201) {
        toast.success('Sales Retention Updated successfully');
        reset();
      } else {
        stopCreateLoading();
      }
    });
  };

  return (
    <GlassCard className='p-3'>
      <form onSubmit={handleSubmit(handleSubmitSalesRetention)}>
        <Grid container spacing={2}>
          <Grid item md={GridSize}>
            <FmSearchableSelect
              name='companyId'
              control={control}
              apiUrl='companies'
              valueField='companyId'
              headerField={['Company Code', 'Company Name']}
              labelField={['companyCode', 'companyName']}
              showField={['companyName']}
              defaultValue={sr}
              label='Company'
              required
            />
          </Grid>
          <Grid item md={GridSize}>
            <FmSearchableSelect
              name='customerId'
              control={control}
              apiUrl='customers'
              defaultValue={sr}
              valueField='customerId'
              headerField={['Customer Code', 'Customer Name']}
              labelField={['customerCode', 'customerName']}
              showField={['customerName']}
              label='Customer'
              required
            />
          </Grid>
          <Grid item md={GridSize}>
            <FmSearchableSelect
              name='projectId'
              control={control}
              apiUrl='projects'
              valueField='projectId'
              headerField={['Project Code', 'Project Name']}
              labelField={['projectCode', 'projectName']}
              showField={['projectName']}
              defaultValue={sr}
              label={'Project'}
              required
            />
          </Grid>
          <Grid item md={GridSize}>
            <FmSearchableSelect
              name='contractId'
              control={control}
              apiUrl='contracts'
              valueField='contractId'
              headerField={['Contract Code', 'Contract Name']}
              labelField={['contractCode', 'contractName']}
              showField={['contractName']}
              defaultValue={sr}
              label={'Contract'}
              required
            />
          </Grid>

          <Grid item md={GridSize}>
            <FmTextField
              name='salesRetentionCode'
              control={control}
              label='Sales Retention Code'
              required
            />
          </Grid>

          <Grid item md={GridSize}>
            <FmSearchableSelect
              name='locationId'
              control={control}
              apiUrl='locations'
              valueField='locationId'
              defaultValue={sr}
              labelField={['locationCode', 'locationName']}
              showField={['locationCode', 'locationName']}
              label={'Location'}
            />
          </Grid>
          <Grid item md={GridSize}>
            <FmSearchableSelect
              name='emirateId'
              control={control}
              apiUrl='emirates'
              valueField='emirateId'
              defaultValue={sr}
              labelField={['emirateName']}
              showField={['emirateName']}
              label={'Emirate'}
            />
          </Grid>
          <Grid item md={GridSize}>
            <FmSearchableSelect
              name='currencyId'
              defaultValue={sr}
              control={control}
              apiUrl='currencies'
              valueField='currencyId'
              headerField={['Currency Code', 'Currency Name']}
              labelField={['currencyCode', 'currencyName']}
              showField={['currencyName']}
              label='Currency'
              required
            />
          </Grid>

          <Grid item md={GridSize}>
            <FmTextField
              name='exchangeRate'
              control={control}
              label='Exchange Rate'
            />
          </Grid>
          <Grid item md={GridSize}>
            <FmSearchableSelect
              name='salesOrderId'
              control={control}
              apiUrl='salesorders'
              valueField='salesOrderId'
              headerField={['Code']}
              labelField={['salesOrderCode', 'salesQuotationCode']}
              showField={['salesOrderCode', 'salesQuotationCode']}
              defaultValue={sr}
              label='Sales Order Code'
            />
          </Grid>

          <Grid item md={GridSize}>
            <FmDatePicker
              name='salesRetentionDate'
              label='Sales Retention Date'
              control={control}
            />
          </Grid>
          <Grid item md={GridSize}>
            <FmDatePicker
              name='salesOrderDate'
              label='Sales Order Date'
              control={control}
            />
          </Grid>
          <Grid item md={GridSize}>
            <FmSearchableSelect
              name='workOrderId'
              control={control}
              defaultValue={sr}
              apiUrl='workorders'
              valueField='workOrderId'
              headerField={['User Name']}
              labelField={['workOrderName', 'workOrderCode']}
              showField={['workOrderName', 'workOrderCode']}
              label='Work Order'
            />
          </Grid>
          <Grid item md={GridSize}>
            <FmDatePicker
              name='workOrderDate'
              label='Work Order Date'
              control={control}
            />
          </Grid>

          <Grid item md={GridSize}>
            <FmSearchableSelect
              name='requestorId'
              control={control}
              apiUrl='loginusers'
              valueField='loginUserId'
              defaultValue={{
                loginUserId: sr?.requestorId,
                loginUserName: sr?.requestorUserName,
              }}
              headerField={['Employee']}
              labelField={['loginUserName']}
              showField={['loginUserName']}
              label='Approved By'
            />
          </Grid>

          <Grid item md={GridSize}>
            <FmSearchableSelect
              name='paymentTypeId'
              control={control}
              apiUrl='paymenttypes'
              valueField='paymentTypeId'
              labelField={['paymentTypeName']}
              showField={['paymentTypeName']}
              defaultValue={sr}
              label={'Payment Type'}
            />
          </Grid>

          <Grid item md={GridSize}>
            <FmSearchableSelect
              name='inCharge'
              control={control}
              apiUrl='loginusers'
              defaultValue={{
                loginUserId: sr?.inCharge,
                loginUserName: sr?.inChargeName,
              }}
              valueField='loginUserId'
              labelField={['emailId', 'loginUserName']}
              showField={['emailId', 'loginUserName']}
              pageSize={50}
              label='In Charge'
            />
          </Grid>
          <Grid item md={GridSize}>
            <FmSearchableSelect
              name='salesQuotationId'
              control={control}
              apiUrl='salesquotations'
              valueField='salesQuotationId'
              headerField={['Sales Quotation Code', 'Sales Quotation Name']}
              labelField={['salesQuotationCode', 'salesQuotationName']}
              showField={['salesQuotationCode', 'salesQuotationName']}
              defaultValue={sr}
              label='Sales Quotation'
            />
          </Grid>

          <Grid item md={GridSize}>
            <FmTextField
              name='paymentTerms'
              control={control}
              multiline={true}
              rows={2}
              maxRows={3}
              label='Payment Terms'
            />
          </Grid>
          <Grid item md={GridSize}>
            <FmTextField
              name='narration'
              multiline={true}
              rows={2}
              maxRows={3}
              control={control}
              label='Narration'
            />
          </Grid>
          <Grid item md={GridSize}>
            <FmTextField
              name='remarks'
              control={control}
              label='Remarks'
              multiline={true}
              rows={2}
              maxRows={3}
            />
          </Grid>
        </Grid>
        <ActionButtons
          onSubmit={handleSubmit(handleSubmitSalesRetention)}
          onReset={reset}
          onCancel={() => navigate(-1)}
          cancelText='Go Back'
          submitLoading={createLoading}
          submitText='Update'
        />
      </form>
    </GlassCard>
  );
};

export default EditSalesRetention;
