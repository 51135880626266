import React, { useState } from 'react';
import DashboardLayout from '../../../components/DashboardLayout.tsx';
import { CreateButton } from '../../../components/small/Buttons.jsx';
import GlassCard from '../../../components/small/GlassCard.tsx';
import { Box, CircularProgress, Grid, Icon } from '@mui/material';
import DataTable from '../../../components/DataTable.tsx';
import Crud_Service from '../../../apis/CrudService.jsx';
import TableActionButtons from '../../../components/_form/TableActionButtons.jsx';
import {
  StatusBadge,
  ViewField,
} from '../../../components/_form/FormElements.jsx';
import { useNavigate } from 'react-router-dom';
import useTableLogic from '../../../components/helpers/UseTableLogic.tsx';
import { toast } from 'react-toastify';
import MuiDialogOne from '../../../components/_mui/MuiDialogOne.jsx';
import DataTableTopBar from '../../../components/datagrid/DataTableTopBar.tsx';
import Icons from '../../../utils/Icon.jsx';
import TableAvatar, { TableAvatarStatus } from '../../../components/_form/TableAvatar.jsx';
import SimpleIconText from '../../../components/elements/SimpleIconText.tsx';
import DataGridHeaderTooltip from '../../../components/datagrid/DataGridHeaderTooltip.tsx';
import { statusColorsMap } from '../EnquiryMaster/EnqExportElement.tsx';

const Clients = () => {
  const crud = new Crud_Service();
  const navigate = useNavigate();
  const [viewData, setViewData] = useState(false);
  const [viewLoading, setViewLoading] = useState(true);
  const [selectedRow, setSelectedRow] = useState();
  const {
    rows,
    pageSize,
    pageNumber,
    setSearchKeyword,
    isLoading,
    tableRecordCounts,
    searchKeyword,
    handlePaginationModelChange,
    handleSortModelChange,
    fetchData,
  } = useTableLogic('clients');

  //view the ShiftTiming
  const handleViewClick = async (view) => {
    setViewData(true);
    setViewLoading(true);
    await crud.getSingle('clients', view?.clientId, (err, res) => {
      if (res?.status === 200) {
        setViewLoading(false);
        setSelectedRow(res?.data);
      } else {
      }
    });
  };

  //handle Delete
  const handleDelete = async (props) => {
    await crud.remove('clients', props?.clientId, (err, res) => {
      if (res?.status === 204) {
        fetchData();
        toast.success('Client Deleted successfully');
      }
    });
  };

  const handleEditClick = async (props) => {
    navigate(`edit/${props?.clientId}`);
  };

  const ActionData = [
    {
      name: 'View',
      icon: Icons.view,
      onClick: (props) => handleViewClick(props),
    },
    {
      name: 'Edit',
      icon: Icons.edit,
      // onClick: () => setOpenCreate(!openCreate),
      onClick: (props) => handleEditClick(props),
    },
    {
      name: 'Delete',
      icon: Icons.delete,
      onClick: (props) => handleDelete(props),
      danger: true,
    },
  ];
  const columns = [
    {
      field: 'action',
      headerName: '',
      width: 90,
      sortable: false,
      disableClickEventBubbling: false,
      renderCell: (params) => {
        return (
          <TableActionButtons
            Actions={ActionData?.map((action) => ({
              ...action,
              onClick: () => action.onClick(params.row),
            }))}
          />
        );
      },
    },
    {
      field: 'clientName',
      headerName: 'Client',
      minWidth: 280,
      flex: 1,
      renderCell: (params) => {
        return (
          <TableAvatar
            name={params?.value}
            desc={`Code: ${params?.row?.clientCode}`}
          />
        );
      },
    },
    // {
    //   field: 'manualClientCode',
    //   headerName: 'Manual Client Code',
    //   minWidth: 170,
    // },
    // {
    //   field: 'parentClientName',
    //   headerName: 'Parent Client',
    //   minWidth: 300,
    // },
    {
      field: 'headOfficeAddress',
      headerName: 'Head Office Address',
      minWidth: 200,
      renderCell: (params) => {
        return <SimpleIconText text={params?.value} icon={Icons.location} />;
      },
    },
    {
      field: 'clientIndustry',
      headerName: 'Client Industry',
      minWidth: 150,
    },
    {
      field: 'ownerName',
      headerName: 'Owner Name',
      minWidth: 150,
    },
    {
      field: 'icvCertified',
      headerName: 'ICV Certified',
      minWidth: 100,
      renderCell: (params) =>
        params?.row?.icvCertified === true ? 'Yes' : 'No',
    },
    {
      field: 'contactPerson',
      headerName: 'Contact Person',
      minWidth: 220,
      flex: 1,
      Sort: 'asc',
      renderCell: (params) => {
        return (
          <TableAvatarStatus
            name={params.value}
            desc={
              <Box className='flex flex-col'>
                <Box className='flex items-center gap-1'>
                  <DataGridHeaderTooltip name='Number:' title='Contact Number' />
                  {params.row.contactNumber}
                </Box>
                <Box className='flex items-center gap-1 text-gray-500 text-sm'>
                  <DataGridHeaderTooltip name='Email:' title='Contact Email' />
                  {params.row.contactEmailId}
                </Box>
              </Box>
            }
            icon={Icons.phone}
            avatar={false}
          />
        );
      },
    },

    {
      field: 'trnNumber',
      headerName: 'TRN Number',
      minWidth: 150,
    },
    {
      field: 'tradeLicenseStartDate',
      headerName: 'Trade License Start Date',
      minWidth: 150,
    },
    {
      field: 'tradeLicenseEndDate',
      headerName: 'Trade License End Date',
      minWidth: 150,
    },

  ];

  const onRowDoubleClick = (e) => {
    if (e?.row) {
      handleEditClick(e?.row);
    }
  };

  return (
    <>
      <DashboardLayout
        title='Manage Clients'
        actionButtons={
          <CreateButton name='New' onClick={() => navigate('create')} />
        }
      >
        <GlassCard className='h-full-css'>
          <Box className='h-full-css'>
            <DataTable
              rows={rows}
              columns={columns}
              loading={isLoading}
              onRowDoubleClick={(e) => onRowDoubleClick(e)}
              getRowClassName={(params) =>
                `${params.row.workingStatus === 'InActive' &&
                'bg-red-500 bg-opacity-15'
                }`
              }
              sortingMode='server'
              paginationMode='server'
              onPaginationModelChange={(model) =>
                handlePaginationModelChange(model)
              }
              onSortModelChange={(model) => handleSortModelChange(model)}
              page={pageNumber - 1}
              pageSize={pageSize}
              rowCount={tableRecordCounts?.total}
              fetchData={fetchData}
              slots={{
                toolbar: () => (
                  <DataTableTopBar
                    setSearchKeyword={setSearchKeyword}
                    searchValue={searchKeyword}
                  />
                ),
              }}
            />
          </Box>
        </GlassCard>

        <MuiDialogOne
          title='View Details'
          open={viewData}
          onClose={() => setViewData(false)}
        >
          {viewLoading ? (
            <Box className='my-4 flex justify-center'>
              <CircularProgress />
            </Box>
          ) : (
            <Grid container spacing={3}>
              <Grid item md={6}>
                <ViewField
                  label='Client Code'
                  title={selectedRow?.clientCode}
                />
              </Grid>

              <Grid item md={6}>
                <ViewField
                  label='Client Name'
                  title={selectedRow?.clientName}
                />
              </Grid>
              <Grid item md={6}>
                <ViewField
                  label='Manual Client Code'
                  title={selectedRow?.manualClientCode}
                />
              </Grid>
              <Grid item md={6}>
                <ViewField
                  label='Parent Client'
                  title={selectedRow?.parentClientName}
                />
              </Grid>
              <Grid item md={6}>
                <ViewField
                  label='Head Office Address'
                  title={selectedRow?.headOfficeAddress}
                />
              </Grid>
              <Grid item md={6}>
                <ViewField
                  label='Client Industry'
                  title={selectedRow?.clientIndustry}
                />
              </Grid>
              <Grid item md={6}>
                <ViewField
                  label='Contact Number'
                  title={selectedRow?.contactNumber}
                />
              </Grid>

              <Grid item md={6}>
                <ViewField
                  label='Contact EmailId'
                  title={selectedRow?.contactEmailId}
                />
              </Grid>

              <Grid item md={6}>
                <ViewField
                  label='Created Date'
                  title={selectedRow?.createdDate}
                />
              </Grid>

              <Grid item md={6}>
                <ViewField
                  label='Updated Date'
                  title={selectedRow?.updatedDate}
                />
              </Grid>

              <Grid item md={6}>
                <ViewField
                  label='Status Name'
                  title={selectedRow?.statusName}
                />
              </Grid>
            </Grid>
          )}
        </MuiDialogOne>
      </DashboardLayout>
    </>
  );
};

export default Clients;
