import React from 'react';
import { Grid, Skeleton, Box } from '@mui/material';

const SkeletonLoader = ({ count, height = 36 }) => {
  return (
    <Grid container spacing={2}>
      {Array.from({ length: count }).map((_, index) => (
        <Grid item md={3} key={index}>
          <Skeleton
            variant='rounded'
            width='100%'
            height={height}
            animation='wave'
            sx={{
              backgroundColor: 'rgba(0, 0, 0, 0.11)',
              opacity: 0.6,
            }}
          />
        </Grid>
      ))}
      <Box
        sx={{
          display: 'flex',
          alignItems: 'center',
          justifyContent: 'end',
          width: '100%',
          my: 3,
        }}
      >
        <Skeleton
          animation='wave'
          variant='rounded'
          width={120}
          height={36}
          sx={{
            backgroundColor: 'rgba(0, 0, 0, 0.11)',
            opacity: 0.6,
          }}
        />
      </Box>
    </Grid>
  );
};

export default SkeletonLoader;

export const SkeletonLoaderRow = ({ count, variant = 'text' }) => {
  return (
    <Box className='flex flex-col gap-1 w-full'>
      {Array.from({ length: count }).map((_, index) => (
        <Box className='w-full' key={index}>
          <Skeleton variant={variant} sx={{ fontSize: '1rem' }}  />
        </Box>
      ))}
    </Box>
  );
};
