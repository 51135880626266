import React, { useEffect, useMemo, useState } from 'react';
import DashboardLayout from '../../../components/DashboardLayout.tsx';
import PageHeader from '../../../components/PageHeader';
import DataTable from '../../../components/DataTable.tsx';
import GlassCard from '../../../components/small/GlassCard.tsx';
import TableActionButtons from '../../../components/_form/TableActionButtons';
import { ViewField, StatusBadge } from '../../../components/_form/FormElements';
import { Box, Grid } from '@mui/material';
import { CreateButton } from '../../../components/small/Buttons';
import TableSearch from '../../../components/_form/TableSearch.jsx';
import MuiDialogOne from '../../../components/_mui/MuiDialogOne';
import { Nav } from '../../../utils';
import Crud_Service from '../../../apis/CrudService';
import { useForm } from 'react-hook-form';
import { useTranslation } from 'react-i18next';
import CommonLoader from '../../../components/page/CommonLoader';
import useLoader from '../../../components/helpers/UseLoader.tsx';
import ActionButtons from '../../../components/_form/ActionButtons.tsx';
import FmTextField from '../../../components/_mui/FmTextField.tsx';
import useTableLogic from '../../../components/helpers/UseTableLogic.tsx';
import FmAutoComplete from '../../../components/_mui/FmAutoComplete.tsx';
import DataTableToolbarButtons from '../../../components/helpers/DataTableToolbarButtons.tsx';
import { toast } from 'react-toastify';
import { statusOptions } from '../../../utils/CommonVariables.tsx';
import apiInstance from '../../../apis/ApiService.jsx';
import Icons from '../../../utils/Icon.jsx';
import { useSelector } from 'react-redux';
import { findNavByName } from '../../../utils/navUtils.ts';

const BillingFrequency = (props) => {
    const { type } = props;
    const [openCreate, setOpenCreate] = React.useState(false);
    const [openView, setOpenView] = React.useState(false);
    const [editModal, setEditModal] = useState(false);
    const [editData, setEditData] = useState(null);
    const crud = new Crud_Service();
    const { t } = useTranslation();
    const [viewData, setViewData] = useState();
    const [loading, setLoader] = useState(false);
    const [countries, setCountries] = useState([]);
    const { handleSubmit, setValue, control, reset } = useForm();
    const {
        createLoading,
        startCreateLoading,
        stopCreateLoading,
        editLoading,
        startEditLoading,
        stopEditLoading,
    } = useLoader();
    const {
        rows,
        pageSize,
        pageNumber,
        setSearchKeyword,
        isLoading,
        tableRecordCounts,
        searchKeyword,
        handlePaginationModelChange,
        handleSortModelChange,
        fetchData,
    } = useTableLogic('billingfrequencies');
    const menu = useSelector((state) => state?.menu?.items);

    // Flatten menu only once and store it for reuse
    const SubMenu = useMemo(() => {
        return menu?.map(item => item?.menus?.flat()).flat() || [];
    }, [menu]);

    // Use findNavByName to get the "Accounts Receivable" menu or its child menus
    const result = useMemo(() => findNavByName(SubMenu, "PreSales", "All master"), [SubMenu]);


    useEffect(() => {
        document.title = `HRMS-Billing Frequency`;

    }, []);

    const handleCreateClickOpen = () => {
        reset();
        setOpenCreate(true);
    };
    const handleCreateClose = () => {
        reset();
        setOpenCreate(false);
    };
    const handleSubmitBilling = async (values) => {
        startCreateLoading();
        const combinedDtaa = {
            ...values,
            countryId: Number(values?.countryId),
        };

        await crud.create('billingfrequencies', combinedDtaa, (_err, res) => {
            if (res?.status === 201) {
                stopCreateLoading();
                setOpenCreate(false);
                fetchData();
                reset();
                toast.success('Billing Frequency Created Successfully');
            } else {
                stopCreateLoading();
                setOpenCreate(true);
            }
        });
    };

    const handleViewClickOpen = async (view) => {
        setOpenView(true);
        setLoader(true);
        await crud.getSingle('billingfrequencies', view?.billingFrequencyId, (_err, res) => {
            if (res?.status === 200) {
                setViewData(res?.data);
                setLoader(false);
            }
        });
    };

    const handleEditClick = async (view) => {
        setEditModal(true);
        setLoader(true);
        await crud.getSingle('billingfrequencies', view?.billingFrequencyId, (_err, res) => {
            if (res?.status === 200) {
                Object.entries({
                    ...res?.data,
                }).forEach(([key, value]) => {
                    setValue(key, value);
                });
                setEditData(res?.data);
                setLoader(false);
            } else {
                setLoader(false);
            }
        });
    };

    const handleEditBilling = async (values) => {
        startEditLoading();
        const combinedData = {
            ...values,
            countryId: Number(values?.countryId),
        };
        await crud.update(
            'billingfrequencies',
            editData?.billingFrequencyId,
            combinedData,
            (_err, res) => {
                if (res?.status === 200) {
                    stopEditLoading();
                    setEditModal(false);
                    fetchData();
                    toast.success('Billing Frequency Updated Successfully');
                } else {
                    stopEditLoading();
                    setEditModal(true);
                }
            }
        );
    };

    const handleDeleteClick = async (view) => {
        await crud.remove('billingfrequencies', view?.billingFrequencyId, (_err, res) => {
            if (res?.status === 204) {
                fetchData();
                toast.success('Billing Frequency Deleted Successfully');
            } else {
            }
        });
    };

    const handleViewClose = () => {
        setOpenView(false);
    };

    const counters = [
        {
            name: 'All',
            count: tableRecordCounts?.total || 0,
        },
        {
            name: 'Active',
            count: tableRecordCounts?.active || 0,
        },
        {
            name: 'Deactivated',
            count: tableRecordCounts?.inActive || 0,
        },
    ];

    const ActionData = [
        {
            name: t('view'),
            icon: Icons.view,
            onClick: (props) => handleViewClickOpen(props),
        },
        {
            name: t('edit'),
            icon: Icons.edit,
            onClick: (props) => handleEditClick(props),
        },
        {
            name: t('delete'),
            icon: Icons.delete,
            onClick: (props) => handleDeleteClick(props),
            danger: true,
        },
    ];

    const columns = [
        {
            field: 'action',
            headerName: '',
            width: 140,
            sortable: false,
            disableClickEventBubbling: false,

            renderCell: (params) => {
                return (
                    <TableActionButtons
                        Actions={ActionData?.map((action) => ({
                            ...action,
                            onClick: () => action.onClick(params.row),
                        }))}
                    />
                );
            },
        },
        {
            field: 'billingFrequencyCode',
            headerName: 'Billing Frequency Code',
            flex: 1,
        },
        {
            field: 'billingFrequencyName',
            headerName: 'Billing Frequency Name',
            flex: 1,
        },

        {
            field: 'noOfMonths',
            headerName: 'No of Months',
            flex: 1,
        },

        {
            field: 'status',
            headerName: t('status'),
            flex: 1,
            renderCell: (params) => {
                return (
                    <StatusBadge
                        title={params.value === 2 ? 'Active' : 'Inactive'}
                        type={params.value === 2 ? 'green' : 'red'}
                    />
                );
            },
        },
    ];
    const downloadBilling = async () => {
        const downloadUrl = await apiInstance.getFiles('billingfrequencies/exportexcel');
        const link = document.createElement('a');
        link.href = downloadUrl;
        link.setAttribute('download', 'Billing Frequency.xlsx');
        document.body.appendChild(link);
        link.click();
        document.body.removeChild(link);
    };

    return (
        <DashboardLayout
            title='Billing Frequency'
            actionButtons={
                <>
                    <PageHeader counters={counters} />
                </>
            }
            hasSubmenu
            menu={result}
        >
            <GlassCard className='h-full'>
                <Box className='h-full'>
                    <DataTable
                        rows={rows}
                        columns={columns}
                        loading={isLoading}
                        getRowClassName={(params) =>
                            `${params.row.status === 0 && 'bg-red-50'}`
                        }
                        sortingMode='server'
                        paginationMode='server'
                        onPaginationModelChange={(model) =>
                            handlePaginationModelChange(model)
                        }
                        onSortModelChange={(model) => handleSortModelChange(model)}
                        page={pageNumber - 1}
                        pageSize={pageSize}
                        rowCount={tableRecordCounts?.total}
                        fetchData={fetchData}
                        slots={{
                            toolbar: () => (
                                <Box
                                    className='p-2 w-full flex justify-between items-center'
                                    sx={{
                                        borderBottom: '1px solid',
                                        borderColor: 'border.main',
                                    }}
                                >
                                    <Box sx={{ maxWidth: '250px' }}>
                                        <TableSearch
                                            placeholder='Search'
                                            fullWidth
                                            setSearchKeyword={setSearchKeyword}
                                            searchValue={searchKeyword}
                                        />
                                    </Box>
                                    <Box className='flex items-center gap-4 justify-end'>
                                        <CreateButton name='Create' onClick={handleCreateClickOpen} />
                                        <DataTableToolbarButtons
                                            disableColumn
                                            handleExcelExport={downloadBilling}
                                        />
                                    </Box>
                                </Box>
                            ),
                        }}
                    />
                </Box>
            </GlassCard>

            {/* create  */}

            <MuiDialogOne
                title={t('create')}
                open={openCreate}
                onClose={handleCreateClose}
            >
                <form onSubmit={handleSubmit(handleSubmitBilling)}>
                    <Grid container spacing={3}>
                        <Grid item md={12}>
                            <FmTextField
                                name='billingFrequencyName'
                                control={control}
                                label='Billing Frequency Name'
                                rules={{
                                    required: 'Billing Frequency Name is required',
                                }}
                            />
                        </Grid>

                        <Grid item md={12}>
                            <FmTextField
                                name='noOfMonths'
                                control={control}
                                label='No of Months'
                                rules={{
                                    required: 'No of Months is required',
                                }} />
                        </Grid>

                        <Grid item md={12}>
                            <ActionButtons
                                onSubmit={handleSubmit(handleSubmitBilling)}
                                onCancel={handleCreateClose}
                                onReset={reset}
                                submitLoading={createLoading}
                                cancelLoading={false}
                            />
                        </Grid>
                    </Grid>
                </form>
            </MuiDialogOne>

            {/* create */}

            {/* view */}
            <MuiDialogOne
                title='View Details'
                open={openView}
                onClose={handleViewClose}
            >
                {loading ? (
                    <CommonLoader />
                ) : (
                    <Grid container spacing={3}>
                        <Grid item md={6}>
                            <ViewField label='Billing Frequency Code' title={viewData?.billingFrequencyCode} />
                        </Grid>
                        <Grid item md={6}>
                            <ViewField label='Billing Frequency Name' title={viewData?.billingFrequencyName} />
                        </Grid>

                        <Grid item md={6}>
                            <ViewField label='No of Months' title={viewData?.noOfMonths} />
                        </Grid>

                        <Grid item md={6}>
                            <ViewField label={t('status')} title={viewData?.statusName} />
                        </Grid>
                    </Grid>
                )}
            </MuiDialogOne>

            {/* edit */}

            <MuiDialogOne
                title={t('update')}
                open={editModal}
                onClose={() => setEditModal(false)}
            >
                {loading ? (
                    <CommonLoader />
                ) : (
                    <form onSubmit={handleSubmit(handleEditBilling)}>
                        <Grid container spacing={3}>
                            <Grid item md={12}>
                                <FmTextField
                                    name='billingFrequencyName'
                                    control={control}
                                    label='Billing Frequency Name'
                                    rules={{
                                        required: 'Billing Frequency Name is required',
                                    }}
                                />
                            </Grid>
                            <Grid item md={12}>
                                <FmTextField
                                    name='noOfMonths'
                                    control={control}
                                    label='No of Months'
                                    rules={{
                                        required: 'No of Months is required',
                                    }} />
                            </Grid>
                            <Grid item md={12}>
                                <FmAutoComplete
                                    name='status'
                                    control={control}
                                    options={statusOptions}
                                    label='Status'
                                    displayField='statusName'
                                    optionFields={['statusName']}
                                    valueKey='statusId'
                                />
                            </Grid>
                            <Grid item md={12}>
                                <ActionButtons
                                    onSubmit={handleSubmit(handleEditBilling)}
                                    onCancel={() => setEditModal(false)}
                                    onReset={reset}
                                    submitLoading={editLoading}
                                    cancelLoading={false}
                                    submitText='Update'
                                />
                            </Grid>
                        </Grid>
                    </form>
                )}
            </MuiDialogOne>
        </DashboardLayout>
    );
};

export default BillingFrequency;
