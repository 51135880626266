import React from 'react';
import GlassCard from '../small/GlassCard.tsx';
import { Box } from '@mui/material';

const SimpleCardWithActions = ({ actions, title, children }) => {
  return (
    <GlassCard>
      <Box
        className='flex justify-between items-center py-1.5 px-3'
        sx={{ borderBottom: '1px solid', borderColor: 'border.main' }}
      >
        <Box className='font-semibold' color={'text.dark'}>
          {title}
        </Box>
        <Box>{actions}</Box>
      </Box>
      <Box className='p-3'>{children}</Box>
    </GlassCard>
  );
};

export default SimpleCardWithActions;
