import React from 'react';
import { Box } from '@mui/material';
import { Icon } from '@iconify/react/dist/iconify.js';

const GalleryImageGrid = ({ name, image, onClick }) => {
  return (
    <Box
      sx={{
        width: '130px',
        height: '130px',
        border: '1px solid',
        borderColor: 'border.main',
        overflow: 'hidden',
        borderRadius: '5px',
        position: 'relative',
        bgcolor: 'background.white',
        '&:hover': {
          '& .icons-zoom': {
            opacity: 1,
          },
        },
      }}
      className='flex justify-center items-center cursor-pointer'
      onClick={onClick}
      title={name}
    >
      {/* Preview for images */}

      <Box
        src={image}
        alt={name}
        sx={{
          width: '100%',
          height: '100%',
          objectFit: 'cover',
          objectPosition: 'top',
          marginBottom: '8px',
          position: 'absolute',
          left: 0,
          top: 0,
        }}
        component={'img'}
      />

      <Box
        className='flex justify-center items-center w-full h-full absolute left-0 top-0 text-4xl text-white icons-zoom'
        sx={{
          background: 'rgb(0 0 0 / 34%)',
          zIndex: 2,
          opacity: 0,
          transition: '0.5s all',
        }}
      >
        <Icon icon='tabler:zoom' />
      </Box>

      <Box
        sx={{
          overflow: 'hidden',
          position: 'absolute',
          left: '0',
          bottom: '0',
          bgcolor: 'rgb(13 101 102 / 56%)',
          backdropFilter: 'blur(20px)',
          color: '#fff',
          padding: '5px 6px',
          whiteSpace: 'nowrap',
          textOverflow: 'ellipsis',
          width: '100%',
          transition: '0.3s all',
          opacity: 1,
          textAlign: 'center',
          fontWeight: 600,
        }}
        className='titles'
      >
        {name}
      </Box>
    </Box>
  );
};

export default GalleryImageGrid;
