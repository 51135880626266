import React from 'react';
import { Box } from '@mui/material';
import { StatusBadge } from '../_form/FormElements.jsx';
import { FontSize } from './../../theme/FontSize';

const ViewStatusLabel = ({ label, title, type }) => {
  return (
    <Box>
      <Box sx={{ fontWeight: 600, fontSize: FontSize.bodyMinusOne }}>
        {label}
      </Box>
      <StatusBadge title={title} type={type} normal />
    </Box>
  );
};

export default ViewStatusLabel;
