import { Box } from '@mui/material';
import React, { useEffect, useMemo } from 'react';
import { useNavigate } from 'react-router-dom';
import { CreateButton } from '../../../../components/small/Buttons.jsx';
import Nav from '../../../../utils/Nav.tsx';
import DashboardLayout from './../../../../components/DashboardLayout.tsx';
import CreateSalesQuotationList from './CreateSalesQuotationList.tsx';
import { useSelector } from 'react-redux';
import { findNavByName } from '../../../../utils/navUtils.ts';

const CreateSalesQuotation = (props) => {
  const { type } = props;
  const [getData] = React.useState(false);
  const navigate = useNavigate();
  
  const menu = useSelector((state) => state?.menu?.items);

  const SubMenu = useMemo(() => {
    return menu?.map((item) => item?.menus?.flat()).flat() || [];
  }, [menu]);

  const result = useMemo(
    () => findNavByName(SubMenu, 'FM Division', 'Sales Quotation'),
    [SubMenu]
  );
  useEffect(() => {
    document.title = 'Sales Quotation';
  }, []);
  


  return (
    <DashboardLayout
      title={`Sales Quotations`}
      hasSubmenu={type === 4}
      menu={result}
      actionButtons={
        <>
          <Box className='flex flex-row items-center whitespace-nowrap gap-3'>
            <CreateButton
              name='Create'
              onClick={() => navigate('create')}
            />
          </Box>
        </>
      }
    >
      <CreateSalesQuotationList type={type} getData={getData} />
    </DashboardLayout>
  );
};

export default CreateSalesQuotation;
