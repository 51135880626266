import { useEffect, useState } from 'react';
import { Controller, useForm } from 'react-hook-form';
import useLoader from '../../../../components/helpers/UseLoader.tsx';
import Crud_Service from '../../../../apis/CrudService';
import { Grid, FormHelperText } from '@mui/material';
import GlassCard from '../../../../components/small/GlassCard.tsx';
import { ActionButtons } from '../../../../components/_form';
import { toast } from 'react-toastify';
import FmTextField from '../../../../components/_mui/FmTextField.tsx';
import { DatePicker, LocalizationProvider } from '@mui/x-date-pickers';
import { AdapterDayjs } from '@mui/x-date-pickers/AdapterDayjs';
import axios from 'axios';
import FmSearchableSelect from '../../../../components/_mui/FmSearchableSelect.tsx';
import dayjs from 'dayjs';
import FmAutoComplete from '../../../../components/_mui/FmAutoComplete.tsx';
import { yupResolver } from '@hookform/resolvers/yup';
import * as Yup from 'yup';
import FmDatePicker from '../../../../components/_mui/FmDatePicker.tsx';

const EmployeeConfigration = (props) => {
  const { setEnabledTabs, setValues, createdEmployee, value } = props;
  const [companies, setCompanies] = useState([]);
  const [workStatus, setWorkStatus] = useState([]);
  const [resignationDateState, setResignationEndDate] = useState(null);
  const token = localStorage.getItem('token');
  const contractBrech = [
    {
      label: 'Yes',
      value: 1,
    },
    {
      label: 'No',
      value: 2,
    },
  ];
  const validationSchema = Yup.object({
    workStatusId: Yup.number().required('Work status ID is required').min(1, 'Work status ID must be greater than or equal to 1'),
    probationEndDate: Yup.date()
    .required('Probation end date is required')
    .min(dayjs().startOf('day').toDate(), 'Probation end date must be today or in the future')
    .nullable(),
    resignationDate: Yup.date()
    .required('Resignation date is required')
    .min(dayjs().startOf('day').toDate(), 'Resignation end date must be today or in the future')
    .nullable(),
    probationMonths: Yup.number()
      .required('Probation months are required')
      .min(0, 'Probation months must be a positive number'),
    });
  const {
    handleSubmit,
    control,
    reset,
    setValue,
    setError,
    clearErrors,
    formState: { errors },
  } = useForm({
    resolver: yupResolver(validationSchema),
    mode: 'onChange',
    defaultValues: {
      resignationDate: dayjs().toDate(),
      probationEndDate: dayjs().toDate(),
    },
  });
  const { startCreateLoading, stopCreateLoading, createLoading } = useLoader();
  const crud = new Crud_Service();

  useEffect(() => {
    if (value === 2) {
      getCompanies();
      getWorkingStatus();
    }
  }, [value]);

  //get All Projects
  const getCompanies = async () => {
    await crud.getAll('companies', {}, (err, res) => {
      if (res?.status === 200) {
        setCompanies(res?.data?.data);
      } else {
      }
    });
  };

  const getWorkingStatus = async () => {
    await crud.getAll('workingstatuses', {}, (err, res) => {
      if (res?.status === 200) {
        setWorkStatus(res?.data?.data);
      } else {
      }
    });
  };

  const validateFields = () => {
    const tempErrors = {};
    // if (!formData.project) {
    //   tempErrors.project = "Please enter a project name";
    // }
    // if (!formData.costCenter) {
    //   tempErrors.costCenter = "Please enter a cost center";
    // }
    // setErrors(tempErrors);
    return Object.keys(tempErrors).length === 0;
  };



  const handleProjectSubmit = async (values) => {
    startCreateLoading();

    if (validateFields()) {
      const CombinedData = {
        ...values,
        employeeResignationRequestId: null,
        probationEndDate: dayjs(values?.probationEndDate).format('YYYY-MM-DD'),
        resignationDate: dayjs(values?.resignationDate).format('YYYY-MM-DD'),
        probationMonths: Number(values?.probationMonths),
        noticePeriod: Number(values?.noticePeriod),
      };

      const employeeId = createdEmployee?.employeeId;
      const employeeDetailId = createdEmployee?.employeeDetailId;

      const Base_URL = process.env.REACT_APP_BASE;
      const url = `${Base_URL}/employees/${employeeId}/employee-details/${employeeDetailId}/configure-detail`;

      try {
        const res = await axios.put(url, CombinedData, {
          headers: {
            Authorization: `Bearer ${token}`,
          },
        });

        if (res?.status === 200) {
          stopCreateLoading();
          toast.success('Employee Configration Created Successfully');
          setValues(3);
          setEnabledTabs(1);
          setEnabledTabs(2);
          setEnabledTabs(3);
          setEnabledTabs(4);
          setEnabledTabs(5);
          setEnabledTabs(6);
        } else {
          stopCreateLoading();
        }
      } catch (err) {
        stopCreateLoading();
        toast.error('Error creating employee:', err);
      }
    }
  };

  return (
    <GlassCard className='p-4'>
      <form onSubmit={handleSubmit(handleProjectSubmit)}>
        <LocalizationProvider dateAdapter={AdapterDayjs}>
          <Grid container spacing={3}>
            <Grid item md={6}>
              <FmAutoComplete
                name='workStatusId'
                control={control}
                options={workStatus}
                label='Working Status'
                displayField='workingStatusName'
                optionFields={['workingStatusName']}
                valueKey='workingStatusId'
              />
            </Grid>
            <Grid item md={6}>
            <Controller
              name="probationMonths"
              control={control}
              render={({ field }) => (
                <>
                  <DatePicker
                    {...field}
                    label="Probation Period Months"
                    views={['month']}
                    value={field.value ? dayjs().month(field.value - 1) : null} // Use field.value from react-hook-form state
                    onChange={(newValue) => {
                      const month = newValue ? dayjs(newValue).month() + 1 : null;
                      setValue('probationMonths', month);
                      clearErrors('probationMonths');
                    }}
                  />
                  {errors.probationMonths && (
                    <FormHelperText error>
                      {errors.probationMonths.message}
                    </FormHelperText>
                  )}
                </>
              )}
            />
            </Grid>
            <Grid item md={6}>
              {/* <Controller
                name='probationEndDate'
                control={control}
                defaultValue={null}
                render={({ field: { onChange, value } }) => (
                  <>
                    <DatePicker
                      label='Probation End date'
                      value={value}
                      format='DD/MM/YYYY'
                      minDate={dayjs(new Date())}
                      defaultValue={dayjs()}
                      onChange={(newValue) => {
                        onChange(newValue);
                      }}
                      sx={{ width: '100%' }}
                    />
                    {errors.probationEndDate && (
                      <FormHelperText error>
                        {errors.probationEndDate.message}
                      </FormHelperText>
                    )}
                  </>
                )}
              /> */}
              <FmDatePicker
              name='probationEndDate'
              label='Probation End date'
              control={control}
              />
            </Grid>
            <Grid item md={6}>
              <Controller
                name='resignationDate'
                control={control}
                defaultValue={null}
                render={({ field: { onChange, value } }) => (
                  <>
                    <DatePicker
                      label='Resignation End date'
                      value={value?dayjs(value):null}
                      defaultValue={dayjs(new Date())}
                      format='DD/MM/YYYY'
                      minDate={dayjs(new Date())}
                      onChange={(newValue) => {
                        onChange(newValue);
                        setResignationEndDate(newValue);
                      }}
                      sx={{ width: '100%' }}
                    />
                    {errors.resignationDate && (
                      <FormHelperText error>
                        {errors.resignationDate.message}
                      </FormHelperText>
                    )}
                  </>
                )}
              />
            </Grid>
            <Grid item md={6}>
            <FmTextField
              name='noticePeriod'
              label='Notice Period'
              control={control}
              pattern='NumberWithMonthRange'
              />
            </Grid>
            <Grid item md={6}>
              <FmAutoComplete
                name='contractBreachId'
                control={control}
                options={contractBrech}
                label='Contract Breach'
                displayField='label'
                optionFields={['label']}
                valueKey='value'
              />
            </Grid>
            <Grid item md={6}>
              <FmSearchableSelect
                name='companyId'
                control={control}
                apiUrl='companies'
                valueField='companyId'
                labelField={['companyCode', 'companyName']}
                showField={['companyName']}
                label={'Companies'}
              />
            </Grid>
          </Grid>
        </LocalizationProvider>

        <ActionButtons
          onSubmit={handleSubmit(handleProjectSubmit)}
          onCancel={() => setValues(1)}
          onReset={reset}
          submitLoading={createLoading}
          cancelLoading={false}
        />
      </form>
    </GlassCard>
  );
};

export default EmployeeConfigration;
