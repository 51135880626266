import React, { useRef } from 'react';
import { Box, CircularProgress, TextField } from '@mui/material';
import { Icon } from '@iconify/react';

const FmFileInput = ({
  onChange,
  documentName,
  disabled,
  name,
  loading = false,
  onBlur,
}) => {
  const fileInputRef = useRef(null);
  const getTruncatedName = (name) => {
    if (name?.length <= 10) return name;

    const fileType = name?.substring(name?.lastIndexOf('.')) || '';
    const baseName = name?.substring(0, 10).trim() || '';

    return `${baseName}...${fileType}`;
  };

  const truncatedName = getTruncatedName(
    documentName ? documentName : 'upload'
  );

  const handleButtonClick = () => {
    if (!loading && !disabled) {
      fileInputRef.current.click();
    }
  };


  return (
    <Box className='flex items-center gap-10'>
      <Box
        sx={{
          padding: '0px',
          borderRadius: '6px',
          display: 'flex',
          alignItems: 'center',
          cursor: disabled || loading ? 'not-allowed' : 'pointer',
          opacity: disabled || loading ? 0.5 : 1,
        }}
        onClick={handleButtonClick}
      >
        <span className='text-3xl'>
          <Icon icon='uim:upload-alt' />
        </span>
        {loading ? (
          <CircularProgress size={24} />
        ) : (
          <>
            <TextField
              variant='outlined'
              value={truncatedName || 'Upload'}
              ref={fileInputRef}
              onBlur={onBlur}
              fullWidth
              readOnly
              InputProps={{
                disableUnderline: true,
                style: {
                  border: 'none',
                  boxShadow: 'none',
                  cursor: disabled ? 'not-allowed' : 'pointer',
                  pointerEvents: 'none',
                  background: 'none',
                },
              }}
              sx={{
                border: 'none',
                '& .MuiOutlinedInput-notchedOutline': {
                  border: 'none',
                },
                '& .MuiInputBase-root': {
                  '&:hover': {
                    border: 'none',
                    boxShadow: 'none',
                    bgcolor: 'transparent',
                  },
                },
              }}
            />
            <input
              type='file'
              ref={fileInputRef}
              style={{ display: 'none' }}
              onChange={onChange}
              onBlur={onBlur}
              accept='.jpg,.jpeg,.png,.gif,.pdf,.doc,.docx,.xls,.xlsx'
              disabled={loading || disabled}
            />
          </>
        )}
      </Box>
    </Box>
  );
};

export default FmFileInput;
