import React, { useEffect, useRef } from 'react';
import {
  MaterialReactTable,
  MRT_RowSelectionState,
} from 'material-react-table';
import MRTExpandTableStyle from './table/MRTExpandTableStyle.tsx';
import { Box, Button, Tooltip, IconButton } from '@mui/material';
import TableSearch from './_form/TableSearch.jsx';
import { Icon } from '@iconify/react/dist/iconify.js';
import FmViewDateField from './_mui/FmViewDateField.tsx';
import ConfirmationPopoverBox from './_form/ConfirmationPopoverBox.tsx';
import NoDataAvailable from './elements/NoDataAvailable.tsx';

interface FmMRTDataTableProps {
  rows: any[];
  columns: any[];
  isLoading?: boolean;
  rowCount?: number;
  columnFilters?: any;
  globalFilter?: any;
  editingRow?: any;
  handlePaginationModelChange?: any;
  handleSortModelChange?: any;
  tableRecordCounts?: number;
  pageSize?: number;
  pageIndex?: number;
  editingRowId?: string | null;
  setPagination?: any;
  pagination?: any;
  setSorting: any;
  sorting: any;
  disableNewButton?: boolean;
  setSearchKeyword?: (value: any) => void;
  searchKeyword?: string | number;
  onCreateRow?: () => void;
  isCreatingRow?: boolean;
  enableRowNumbers: boolean;
  muiTableContainerProps?: any;
  columnPining?: any;
  fetchData?: () => void;
  setRowSelected?: (value: MRT_RowSelectionState) => void;
  enableRowSelection?: boolean;
  renderTopToolbarCustomActionsChildrens?: any;
  delectCheckBoxClicked?: () => void;
  rowSelection?: MRT_RowSelectionState;
  setRowSelection?: (value: MRT_RowSelectionState) => void;
  handleRowDoubleClick?: (row: any) => void;
  handleExport?: () => void;
  handleImport?: () => void;
  buttonName?: string;
  showPagination?: boolean;
  enableBottomToolbar?: boolean;
  enableStickyFooter?: boolean;
  muiTableFooterCellProps?: any;
  enableRowVirtualization?: boolean;
  rightPinning?: any[];
  showSerialNumber?: boolean;
  leftPinning?: any[];
}

const FmMRTDataTable: React.FC<FmMRTDataTableProps> = React.memo((props) => {
  const {
    columns,
    rows,
    rowCount,
    isLoading,
    pagination,
    sorting,
    columnFilters,
    globalFilter,
    setPagination,
    rightPinning,
    leftPinning,
    setSorting,
    editingRow,
    searchKeyword,
    setSearchKeyword,
    onCreateRow,
    isCreatingRow,
    disableNewButton = true,
    muiTableContainerProps,
    enableRowNumbers = false,
    columnPining = {
      left: ['Actions', ...(leftPinning ?? [])],
      right: ['Action', ...(rightPinning ?? [])],
    },
    enableRowSelection = false,
    muiTableFooterCellProps,
    fetchData,
    setRowSelection,
    rowSelection = {},
    renderTopToolbarCustomActionsChildrens,
    delectCheckBoxClicked,
    handleRowDoubleClick,
    handleExport,
    handleImport,
    enableBottomToolbar = true,
    buttonName,
    showPagination = true,
    enableStickyFooter = false,
    enableRowVirtualization = false,
    showSerialNumber,
  } = props;

  const processedColumns = React.useMemo(() => {
    let cols = [...columns];

    if (showSerialNumber) {
      cols = [
        {
          accessorKey: 'serialNumber',
          header: 'S.No',
          size: 60,
          enableSorting: false,
          Cell: ({ row }) => {
            const pageIndex = pagination?.pageIndex || 0;
            const pageSize = pagination?.pageSize || 10;
            return (
              <Box sx={{ textAlign: 'center' }}>
                {pageIndex * pageSize + row.index + 1}
              </Box>
            );
          },
        },
        ...cols,
      ];
    }

    return cols.map((column) => {
      if (
        column.accessorKey?.toLowerCase().includes('date') &&
        !column.enableEditing &&
        !column.customDate
      ) {
        return {
          ...column,
          Cell: ({ row }) => (
            <FmViewDateField
              value={row.original[column.accessorKey]}
              tableFontWeightRemove={true}
            />
          ),
        };
      }
      return column;
    });
  }, [columns, showSerialNumber, pagination?.pageIndex, pagination?.pageSize]);

  return (
    <MRTExpandTableStyle enableFullHeight>
      <Box>
        <MaterialReactTable
          columns={processedColumns}
          data={rows}
          manualPagination
          manualSorting={true}
          rowCount={rowCount}
          editingRow={editingRow}
          enableStickyHeader={true}
          enableRowNumbers={enableRowNumbers}
          onPaginationChange={setPagination}
          onSortingChange={setSorting}
          state={{
            columnFilters,
            globalFilter,
            isLoading,
            pagination,
            sorting,
            rowSelection,
          }}
          enableRowSelection={enableRowSelection}
          enableRowVirtualization={enableRowVirtualization}
          onRowSelectionChange={setRowSelection}
          enableColumnActions={false}
          enableKeyboardShortcut={true}
          isLoading={isLoading}
          enableFilters={false}
          enablePagination={showPagination}
          enableBottomToolbar={enableBottomToolbar}
          enableDensityToggle={false}
          initialState={{
            columnPinning: columnPining,
          }}
          enableStickyFooter={enableStickyFooter}
          muiTableBodyRowProps={({ row }) => ({
            onDoubleClick: () => {
              handleRowDoubleClick && handleRowDoubleClick(row);
            },
          })}
          muiTableContainerProps={muiTableContainerProps}
          renderTopToolbarCustomActions={() => (
            <>
              <Box className='flex items-center justify-between gap-2 w-full'>
                {rowSelection && Object.keys(rowSelection).length > 0 && (
                  <Box
                    sx={{
                      maxWidth: '40px',
                      fontSize: '25px',
                    }}
                  >
                    {/* <Icon
                      icon='gridicons:trash'
                      onClick={delectCheckBoxClicked}
                    ></Icon> */}
                    <ConfirmationPopoverBox
                      title='Delete'
                      icon={<Icon icon='gridicons:trash' />}
                      onConfirm={delectCheckBoxClicked}
                    />
                  </Box>
                )}
                <Box
                  sx={{
                    width: '100%',
                    maxWidth: '350px',
                  }}
                >
                  <TableSearch
                    placeholder='Search'
                    fullWidth
                    setSearchKeyword={setSearchKeyword}
                    searchValue={searchKeyword}
                  />
                </Box>
                <Box className='flex items-center gap-2'>
                  {renderTopToolbarCustomActionsChildrens}

                  <Tooltip title='Refresh'>
                    <IconButton sx={{ fontSize: '22px' }} onClick={fetchData}>
                      <Icon icon='ic:baseline-refresh' />
                    </IconButton>
                  </Tooltip>

                  {onCreateRow && disableNewButton && (
                    <Button
                      onClick={onCreateRow}
                      variant='contained'
                      color='secondary'
                      sx={{ ml: 1 }}
                      disabled={isCreatingRow}
                      className='py-0.5 px-3'
                      startIcon={<Icon icon='ic:baseline-add' />}
                    >
                      {buttonName || 'New'}
                    </Button>
                  )}

                  {handleExport && (
                    <Tooltip title='Export'>
                      <IconButton
                        sx={{ fontSize: '22px' }}
                        onClick={handleExport}
                      >
                        <Icon icon='material-symbols:download' />
                      </IconButton>
                    </Tooltip>
                  )}
                  {handleImport && (
                    <Tooltip title='Import'>
                      <IconButton
                        sx={{ fontSize: '22px' }}
                        onClick={handleImport}
                      >
                        <Icon icon='material-symbols:upload' />
                      </IconButton>
                    </Tooltip>
                  )}
                </Box>
              </Box>
            </>
          )}
          muiTableHeadCellProps={({ column }) => ({
            children: (
              <Box
                className='flex items-center gap-1'
                sx={{
                  cursor: column.getCanSort() ? 'pointer' : 'default',
                  //opacity: column.getIsSorted() === false ? 0.6 : 1,
                  '& svg': {
                    fontSize: '18px',
                    opacity: column.getIsSorted() === false ? 0.2 : 1,
                  },
                  '&:hover svg': {
                    opacity: 0.5,
                  },
                }}
              >
                <Box>{column.columnDef.header}</Box>
                {column.getCanSort() && column.getIsSorted() === 'asc' && (
                  <Box>
                    <Icon icon='ic:baseline-arrow-downward' />
                  </Box>
                )}
                {column.getCanSort() && column.getIsSorted() === 'desc' && (
                  <Box>
                    <Icon icon='ic:baseline-arrow-upward' />
                  </Box>
                )}
                {column.getCanSort() && column.getIsSorted() === false && (
                  <Box>
                    <Icon icon='ic:baseline-arrow-upward' />
                  </Box>
                )}
              </Box>
            ),
            onClick: column.getCanSort()
              ? column.getToggleSortingHandler()
              : undefined,
          })}
          muiTableFooterCellProps={muiTableFooterCellProps}
          renderEmptyRowsFallback={() => <NoDataAvailable />}
          muiCircularProgressProps={{
            sx: {
              display: 'none',
            },
          }}
        />
      </Box>
    </MRTExpandTableStyle>
  );
});

export default FmMRTDataTable;
