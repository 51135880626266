import React, { useEffect, useState } from 'react';
import GlassCard from '../../../components/small/GlassCard.tsx';
import { StyledCard } from '../../../components/_form';
import { Grid, Typography, Divider } from '@mui/material';
import { ViewField } from '../../../components/_form/FormElements';
import Crud_Service from '../../../apis/CrudService';
import FmViewDateField from '../../../components/_mui/FmViewDateField.tsx';
import { toast } from 'react-toastify';

const EmployeeIdentity = ({ id, i, value }) => {
  const crud = new Crud_Service();
  const [item, setItem] = useState(null);

  useEffect(() => {
    crud.getSingle(`employees/${id}/identity`, '', (err, res) => {
      if (err) {
        toast.error(err);
      } else {
        setItem(res?.data);
      }
    });
  }, [id]);

  // Grouped structure for display
  const displayGroups = [
    {
      title: 'Passport Details',
      fields: [
        { title: 'Passport Number', name: 'passportNumber' },
        { title: 'Passport Issue Place', name: 'passportIssuePlace' },
        {
          title: 'Passport Issue Date',
          name: 'passportIssueDate',
          isDate: true,
        },
        {
          title: 'Passport Expiry Date',
          name: 'passportExpiryDate',
          isDate: true,
        },
      ],
    },
    {
      title: 'Labour Card Details',
      fields: [
        { title: 'Labour Card Number', name: 'labourCardNumber' },
        {
          title: 'Labour Card Expiry Date',
          name: 'labourCardExpiryDate',
          isDate: true,
        },
      ],
    },
    {
      title: 'Visa Details',
      fields: [
        { title: 'Visa Number', name: 'visaNumber' },
        { title: 'Visa Expiry Date', name: 'visaExpiryDate', isDate: true },
      ],
    },
    {
      title: 'Emirates ID Details',
      fields: [
        { title: 'Emirates ID Number', name: 'emiratesIdNumber' },
        {
          title: 'Emirates ID Expiry Date',
          name: 'emiratesExpiryDate',
          isDate: true,
        },
      ],
    },
  ];

  const renderFields = (data, group) => (
    <Grid container spacing={1.5}>
      {group.fields.map((field, index) => {
        const value = data ? data[field.name] : '';
        return (
          <Grid item md={4} key={index}>
            {field.isDate ? (
              <FmViewDateField title={field.title} value={value} />
            ) : (
              <ViewField label={field.title} title={value || 'N/A'} />
            )}
          </Grid>
        );
      })}
    </Grid>
  );

  return (
    <>
      {value === i && (
        <GlassCard className={'w-full p-5'}>
          <StyledCard title='Employee Identity'>
            {displayGroups.map((group, idx) => (
              <div key={idx}>
                <Divider sx={{ marginBottom: 2 }} />
                <h3 className='font-semibold mb-3'>{group.title}</h3>

                {renderFields(item, group)}
              </div>
            ))}
          </StyledCard>
        </GlassCard>
      )}
    </>
  );
};

export default EmployeeIdentity;
