import React, { useEffect, useState } from 'react';
import DashboardLayout from '../../../../components/DashboardLayout.tsx';
import { Box, Grid } from '@mui/material';
import GlassCard from '../../../../components/small/GlassCard.tsx';
import ActionButtons from '../../../../components/_form/ActionButtons.tsx';
import { useNavigate, useParams } from 'react-router-dom';
import Crud_Service from '../../../../apis/CrudService.jsx';
import { useForm, useFieldArray } from 'react-hook-form';
import useLoader from '../../../../components/helpers/UseLoader.tsx';
import CommonLoader from '../../../../components/page/CommonLoader.jsx';
import FmTextField from '../../../../components/_mui/FmTextField.tsx';
import { toast } from 'react-toastify';
import FmAutoComplete from '../../../../components/_mui/FmAutoComplete.tsx';
import useCommonFetchApi from '../../../../components/helpers/useCommonFetchApi.tsx';
import { CreateButton } from '../../../../components/small/Buttons.jsx';
import FmSearchableSelect from '../../../../components/_mui/FmSearchableSelect.tsx';
import { MIPPremiumCalculatorSchema } from '../../../../utils/CommonvalidationSchemas.tsx';
import { yupResolver } from '@hookform/resolvers/yup';

const EditMIPPremiumCalculator = () => {
  const navigate = useNavigate();
  const crud = new Crud_Service();
  const [loading, setLoading] = useState(false);
  const { id } = useParams();
  const GridItem = 2.4;

  const { handleSubmit, control, setValue, reset } = useForm({
    mode: 'onChange',
    resolver: yupResolver(MIPPremiumCalculatorSchema),
  });
  const { editLoading, startEditLoading, stopEditLoading } = useLoader();

  //get Apis
  const { data: ageBands } = useCommonFetchApi(
    'companymedicalinsuranceagebands'
  );
  const { data: regions } = useCommonFetchApi('regions');
  const { data: dependencies } = useCommonFetchApi(
    'companymedicalinsurancedependencies'
  );

  const { fields, replace } = useFieldArray({
    control,
    name: 'updateMedicalInsurancePolicyCategories',
  });

  useEffect(() => {
    getMIPPremiumCalculator();
  }, []);

  //   Setting the updated client values
  const getMIPPremiumCalculator = async () => {
    setLoading(true);
    await crud.getSingle('medicalinsurancepolicies', id, (_err, res) => {
      if (res?.status === 200) {
        Object.entries({
          ...res?.data,
        }).forEach(([key, value]) => {
          setValue(key, value);
        });
        replace(res?.data?.medicalInsurancePolicyCategories);
        setLoading(false);
      } else {
        setLoading(false);
      }
    });
  };

  const handleUpdatePremiumCalculater = async (values) => {
    startEditLoading();

    //Remove Unwanted Keys
    const { medicalInsurancePolicyCategories, ...restValues } = values;

    const combinedData = {
      ...restValues,
    };

    await crud.update(
      'medicalinsurancepolicies',
      id,
      combinedData,
      (err, res) => {
        if (res?.status === 200) {
          toast.success('Medical Insurance Policy Updated Successfully');
          stopEditLoading();
          navigate(-1);
        } else {
          stopEditLoading();
        }
      }
    );
  };

  const setGrade = (value, type) => {
    setValue(`medicalInsurancePolicyCategories.${type}.gradeId`, value);
  };

  return (
    <DashboardLayout
      title='Edit MIP Premium Calculator'
      actionButtons={
        <>
          <Box className='flex flex-row items-center whitespace-nowrap gap-3'>
            <CreateButton
              name='Create'
              onClick={() =>
                navigate(
                  '/admin/company-medical-insurance-policy/medical-insurance-policy-premium-calculator/create'
                )
              }
            />
          </Box>
        </>
      }
    >
      <GlassCard className='p-5'>
        {loading ? (
          <CommonLoader />
        ) : (
          <form onSubmit={handleSubmit(handleUpdatePremiumCalculater)}>
            <Grid container spacing={2}>
              <Grid item md={GridItem}>
                <FmAutoComplete
                  name='companyMedicalInsuranceAgeBandId'
                  control={control}
                  options={ageBands}
                  label='Age Band'
                  displayField='companyMedicalInsuranceAgeBandName'
                  optionFields={['companyMedicalInsuranceAgeBandName']}
                  valueKey='companyMedicalInsuranceAgeBandId'
                />
              </Grid>
              <Grid item md={GridItem}>
                <FmAutoComplete
                  name='regionId'
                  control={control}
                  options={regions}
                  label='Region'
                  displayField='regionCode'
                  optionFields={['regionCode']}
                  valueKey='regionId'
                />
              </Grid>
              <Grid item md={GridItem}>
                <FmAutoComplete
                  name='companyMedicalInsuranceDependencyId'
                  control={control}
                  options={dependencies}
                  label='Dependency'
                  displayField='companyMedicalInsuranceDependencyName'
                  optionFields={['companyMedicalInsuranceDependencyName']}
                  valueKey='companyMedicalInsuranceDependencyId'
                />
              </Grid>
              <Grid item md={GridItem}>
                <FmTextField
                  name='principal'
                  label='Principal'
                  control={control}
                  pattern='Number'
                />
              </Grid>

              <Grid item md={GridItem}>
                <FmTextField
                  name='maternity'
                  label='Maternity'
                  control={control}
                  pattern='Number'
                />
              </Grid>

              {/* <Grid item md={GridItem}>
                <FmSearchableSelect
                  name='gradeId1'
                  control={control}
                  headerField={['Code', 'Name']}
                  apiUrl='grades'
                  valueField='gradeId'
                  labelField={['gradeCode', 'gradeName']}
                  showField={['gradeName']}
                  label={'Category A Grade'}
                  onChangeProp={(e) => setGrade(e, 0)}
                />
              </Grid>
              <Grid item md={GridItem}>
                <FmTextField
                  name='medicalInsurancePolicyCategories[0].male'
                  label='Category A Male'
                  control={control}
                  pattern='Number'
                />
              </Grid>
              <Grid item md={GridItem}>
                <FmTextField
                  name='medicalInsurancePolicyCategories[0].female'
                  label='Category A Female'
                  control={control}
                  pattern='Number'
                />
              </Grid>

              <Grid item md={GridItem}>
                <FmSearchableSelect
                  name='gradeId2'
                  control={control}
                  headerField={['Code', 'Name']}
                  apiUrl='grades'
                  valueField='gradeId'
                  labelField={['gradeCode', 'gradeName']}
                  showField={['gradeName']}
                  label={'Category B Grade'}
                  onChangeProp={(e) => setGrade(e, 1)}
                />
              </Grid>
              <Grid item md={GridItem}>
                <FmTextField
                  name='medicalInsurancePolicyCategories[1].male'
                  label='Category B Male'
                  control={control}
                  pattern='Number'
                />
              </Grid>
              <Grid item md={GridItem}>
                <FmTextField
                  name='medicalInsurancePolicyCategories[1].female'
                  label='Category B Female'
                  control={control}
                  pattern='Number'
                />
              </Grid> */}
              {fields?.map((field, index) => (
                <Grid item xs={12} container spacing={2}>
                  <Grid item md={GridItem}>
                    <FmSearchableSelect
                      name={`updateMedicalInsurancePolicyCategories[${index}].gradeId`}
                      control={control}
                      headerField={['Code', 'Name']}
                      apiUrl='grades'
                      valueField='gradeId'
                      labelField={['gradeCode', 'gradeName']}
                      showField={['gradeName']}
                      label={'Grade'}
                      defaultValue={{
                        gradeId: field?.gradeId,
                        gradeName: field?.gradeName,
                      }}
                    />
                  </Grid>
                  <Grid item md={GridItem}>
                    <FmTextField
                      name={`updateMedicalInsurancePolicyCategories[${index}].male`}
                      label='Male'
                      control={control}
                      pattern='Number'
                    />
                  </Grid>
                  <Grid item md={GridItem}>
                    <FmTextField
                      name={`updateMedicalInsurancePolicyCategories[${index}].female`}
                      label='Female'
                      control={control}
                      pattern='Number'
                    />
                  </Grid>
                  {/* <Grid item md={GridItem}>
                  <IconButton
                    onClick={() => remove(index)}
                    disabled={fields?.length === 1}
                    sx={{
                      padding: '1px',
                    }}
                    color='secondary'
                  >
                    <Icon icon='ic:baseline-clear' />
                  </IconButton>
                </Grid> */}
                </Grid>
              ))}
            </Grid>
            <ActionButtons
              onSubmit={handleSubmit(handleUpdatePremiumCalculater)}
              onReset={reset}
              submitText='Update'
              onCancel={() => navigate(-1)}
              cancelText='Go Back'
              submitLoading={editLoading}
              cancelLoading={false}
            />
          </form>
        )}
      </GlassCard>
    </DashboardLayout>
  );
};

export default EditMIPPremiumCalculator;
