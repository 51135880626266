import { yupResolver } from '@hookform/resolvers/yup';
import { Grid } from '@mui/material';
import dayjs from 'dayjs';
import React, { useState } from 'react';
import { useForm } from 'react-hook-form';
import { useNavigate } from 'react-router-dom';
import { toast } from 'react-toastify';
import Crud_Service from '../../../apis/CrudService.jsx';
import { ActionButtons } from '../../../components/_form';
import FmDatePicker from '../../../components/_mui/FmDatePicker.tsx';
import FmSearchableSelect from '../../../components/_mui/FmSearchableSelect.tsx';
import FmTextField from '../../../components/_mui/FmTextField.tsx';
import DashboardLayout from '../../../components/DashboardLayout.tsx';
import useLoader from '../../../components/helpers/UseLoader.tsx';
import GlassCard from '../../../components/small/GlassCard.tsx';
import UploadModalBox from '../../../components/upload/UploadModalBox.tsx';
import { TenderInspectionvalidationSchema } from '../../../utils/CommonvalidationSchemas.tsx';

const CreateTenderInspectionChecklist = () => {
  const navigate = useNavigate();
  const crud = new Crud_Service();
  const {
    handleSubmit,
    control,
    setValue,
    getValues,
    setError,
    clearErrors,
    reset,
  } = useForm({
    resolver: yupResolver(TenderInspectionvalidationSchema),
    mode: 'onChange',
  });
  const [counter, setCounter] = useState(1);
  const { startCreateLoading, stopCreateLoading, createLoading } = useLoader();
  const [components, setComponents] = useState([]);
  const [imageUrl, setImageUrl] = useState([]);
  const [uploadedFileIds, setUploadedFileIds] = useState([]);
  const [files, setFiles] = useState([]);
  const GridValue = 3;

  const handleSubmitTenderChecklist = async (values) => {
    startCreateLoading();
    const combinedData = {
      ...values,
      noOfFloor: Number(values?.noOfFloor),
      siteVisitDate: dayjs(values?.siteVisitDate).format('YYYY-MM-DD'),
      flatPerFloor: Number(values?.flatPerFloor),
      fileUploadIds: uploadedFileIds || [],
    };

    await crud.create(
      `tenderinspectionchecklists`,
      combinedData,
      (err, res) => {
        if (err) {
          stopCreateLoading();
          toast.error(err);
        }
        if (res?.status === 201) {
          stopCreateLoading();
          navigate(-1);
          toast.success('Tender Checklist Created Successfully');
        } else {
          stopCreateLoading();
        }
      }
    );
  };

  const MAX_IMAGES = 5;

  const handleAddComponent = () => {
    if (components.length >= MAX_IMAGES) {
      toast.error(`You can only upload a maximum of ${MAX_IMAGES} images.`);
      return;
    }

    const currentFileIds = getValues('fileUploadIds') || [];
    if (currentFileIds.length >= 5) {
      setError('fileUploadIds', {
        type: 'manual',
        message: 'You can upload a maximum of 5 files.',
      });
      return;
    } else {
      clearErrors('fileUploadIds');
    }

    setComponents([...components, { id: counter }]);
    setCounter(counter + 1);
  };

  const handleRemoveComponent = (id) => {
    setComponents(components.filter((component) => component.id !== id));
    setImageUrl((prev) => {
      const updatedUrls = { ...prev };
      delete updatedUrls[id];
      return updatedUrls;
    });
    clearErrors('fileUploadIds');
  };
  const handleFileChange = (id, fileId) => {
    if (typeof fileId === 'number') {
      const currentFileIds = getValues('fileUploadIds') || [];
      const updatedFileIds = [...currentFileIds, fileId];
      setValue('fileUploadIds', updatedFileIds);
    }
  };

  const handleTenderChange = (value) => {
    if (value) {
      setValue('clientId', value?.clientId);
      setValue('clientName', value?.clientName);
      setValue('ClientContactPerson ', value?.ClientContactPerson);
      setValue('ClientMail', value?.ClientMail);
      setValue('ClientContactNo', value?.ClientContactNo);
      clearErrors('clientId');
    }
  };

  const handleTenderCancel = () => {
    setValue('clientId', '');
    setValue('clientName', '');
    setValue('ClientContactPerson ', '');
    setValue('ClientMail', '');
    setValue('ClientContactNo', '');
  };

  const handleFileIds = (fileIds) => {
    setUploadedFileIds(fileIds);
  };

  return (
    <DashboardLayout title='Create New Tender Inspection Checklist'>
      <GlassCard className='p-5'>
        <form onSubmit={handleSubmit(handleSubmitTenderChecklist)}>
          <Grid container spacing={2}>
            <Grid item md={GridValue}>
              <FmSearchableSelect
                name='tenderId'
                control={control}
                apiUrl='tenders'
                valueField='tenderId'
                labelField={['tenderCode', 'tenderName']}
                showField={['tenderCode', 'tenderName']}
                label={'Tenders'}
                onChangeProp={handleTenderChange}
                clearSelection={handleTenderCancel}
              />
            </Grid>
            <Grid item md={GridValue}>
              <FmSearchableSelect
                name='tenderScopeId'
                control={control}
                apiUrl='tenderscopes'
                valueField='tenderScopeId'
                labelField={['subject']}
                showField={['subject']}
                label={'Tender Scope'}
              />
            </Grid>
            <Grid item md={GridValue}>
              <FmTextField
                name='tenderRegNo'
                label='Tender Reg No'
                control={control}
                disabled
              />
            </Grid>
            <Grid item md={GridValue}>
              <FmTextField
                name='clientName'
                label='Client Name'
                control={control}
                disabled
              />
            </Grid>
            <Grid item md={GridValue}>
              <FmTextField
                name='ClientContactPerson'
                label='Client Contact Person Name'
                control={control}
                disabled
              />
            </Grid>
            <Grid item md={GridValue}>
              <FmTextField
                name='ClientMail'
                label='Client Contact Person Email'
                control={control}
                disabled
              />
            </Grid>
            <Grid item md={GridValue}>
              <FmTextField
                name='ClientContactNo'
                label='Client Contact Person Number'
                control={control}
                disabled
              />
            </Grid>
            <Grid item md={GridValue}>
              <FmDatePicker
                name='siteVisitDate'
                label='Site Visit Date'
                control={control}
                disabled
              />
            </Grid>
            <Grid item md={GridValue}>
              <FmSearchableSelect
                name='siteVisitDoneBy'
                control={control}
                apiUrl='loginusers'
                valueField='loginUserId'
                headerField={['Code', 'Name', 'Email']}
                labelField={['employeeCode', 'employeeName', 'employeeMail']}
                showField={['employeeCode', 'employeeName', 'employeeMail']}
                label={'Site Visited Done By'}
              />
            </Grid>
            <Grid item md={GridValue}>
              <FmTextField
                name='locationName'
                label='Location Name'
                control={control}
              />
            </Grid>
            <Grid item md={GridValue}>
              <FmTextField
                name='buildingName'
                label='Building Name'
                control={control}
              />
            </Grid>
            <Grid item md={GridValue}>
              <UploadModalBox
                onSubmitFiles={handleFileIds}
                files={files}
                setFiles={setFiles}
              />
            </Grid>
            <Grid item md={GridValue}>
              <FmTextField
                name='existingManpowerDetail'
                label='Existing Manpower Detail'
                control={control}
                rows={3}
                maxRows={3}
                multiline={true}
              />
            </Grid>
            <Grid item md={GridValue}>
              <FmTextField
                name='existServiceProvider'
                label='Existing Contract Name'
                control={control}
                rows={3}
                maxRows={3}
                multiline={true}
              />
            </Grid>

            <Grid item md={GridValue}>
              <FmTextField
                name='remarks'
                label='Remarks'
                control={control}
                multiline={true}
                rows={3}
                maxRows={3}
              />
            </Grid>
          </Grid>
          <ActionButtons
            onSubmit={handleSubmit(handleSubmitTenderChecklist)}
            onCancel={() => navigate(-1)}
            onReset={reset}
            submitLoading={createLoading}
            cancelLoading={false}
            submitText='Create'
            cancelText='Go Back'
          />
        </form>
      </GlassCard>
    </DashboardLayout>
  );
};

export default CreateTenderInspectionChecklist;
