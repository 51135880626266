import React, { useState } from 'react';
import DashboardLayout from '../../../components/DashboardLayout.tsx';
import { Box, Grid, Button } from '@mui/material';
import { ActionButtons } from '../../../components/_form';
import { useNavigate } from 'react-router-dom';
import { useForm } from 'react-hook-form';
import dayjs from 'dayjs';
import Crud_Service from '../../../apis/CrudService.jsx';
import { toast } from 'react-toastify';
import GlassCard from '../../../components/small/GlassCard.tsx';
import useLoader from '../../../components/helpers/UseLoader.tsx';
import FmSearchableSelect from '../../../components/_mui/FmSearchableSelect.tsx';
import useCommonFetchApi from '../../../components/helpers/useCommonFetchApi.tsx';
import FmAutoComplete from '../../../components/_mui/FmAutoComplete.tsx';
import FmTextField from '../../../components/_mui/FmTextField.tsx';
import { Icon } from '@iconify/react';
import UploadSquareBoxes from '../../../components/_form/UploadSquareBoxes.tsx';
import { yupResolver } from '@hookform/resolvers/yup';
import { TenderInspectionvalidationSchema } from '../../../utils/CommonvalidationSchemas.tsx';
import FmDatePicker from '../../../components/_mui/FmDatePicker.tsx';

const CreateTenderInspectionChecklist = () => {
  const navigate = useNavigate();
  const crud = new Crud_Service();
  const {
    handleSubmit,
    control,
    setValue,
    getValues,
    setError,
    clearErrors,
    reset,
    formState: { errors },
  } = useForm({
    resolver: yupResolver(TenderInspectionvalidationSchema),
    mode: 'onChange',
    defaultValues: { visitedDate: dayjs() },
  });
  const [counter, setCounter] = useState(1);
  const { startCreateLoading, stopCreateLoading, createLoading } = useLoader();
  const [components, setComponents] = useState([]);
  const [imageUrl, setImageUrl] = useState([]);
  const [imageLoading, setImageLoading] = useState(
    components.reduce((acc, component) => {
      acc[component.id] = false; // Initialize loading state for each component
      return acc;
    }, {})
  );
  const { data: clients } = useCommonFetchApi('clients');
  const { data: locations } = useCommonFetchApi('locations');
  const { data: regions } = useCommonFetchApi('regions');
  const { data: buildings } = useCommonFetchApi('buildings');

  const GridValue = 3;

  const handleSubmitTenderChecklist = async (values) => {
    startCreateLoading();
    const combinedData = {
      ...values,
      visitedDate: dayjs(values?.visitedDate).format('YYYY-MM-DD'),
      noOfFloor: Number(values?.noOfFloor),
      flatPerFloor: Number(values?.flatPerFloor),
    };

    await crud.create(
      `tenderinspectionchecklists`,
      combinedData,
      (err, res) => {
        if (err) {
          stopCreateLoading();
          toast.error(err);
        }
        if (res?.status === 201) {
          stopCreateLoading();
          navigate(-1);
          toast.success('Tender Checklist Created Successfully');
        } else {
          stopCreateLoading();
        }
      }
    );
  };
  // const handleAddComponent = () => {
  //   const currentFileIds = getValues('fileUploadIds') || [];
  //   if (currentFileIds.length >= 3) {
  //     setError('fileUploadIds', {
  //       type: 'manual',
  //       message: 'You can upload a maximum of 3 files.',
  //     });
  //     return;
  //   } else {
  //     clearErrors('fileUploadIds');
  //   }
  //   setComponents([...components, { id: counter }]);
  //   setCounter(counter + 1);
  // };

  const handleAddComponent = () => {
    const currentFileIds = getValues('fileUploadIds') || [];

    // Check if the user has already uploaded 5 images
    if (currentFileIds.length >= 5) {
      // Show a toast error message if the limit is exceeded
      toast.error('You can upload a maximum of 5 files.');
      setError('fileUploadIds', {
        type: 'manual',
        message: 'You can upload a maximum of 5 files.',
      });
      return;
    } else {
      clearErrors('fileUploadIds');
    }

    // Add a new component if the limit is not reached
    setComponents([...components, { id: counter }]);
    setCounter(counter + 1);
  };


  const handleRemoveComponent = (id) => {
    setComponents(components.filter((component) => component.id !== id));
    setImageUrl((prev) => {
      const updatedUrls = { ...prev };
      delete updatedUrls[id];
      return updatedUrls;
    });
    clearErrors('fileUploadIds');
  };
  const handleFileChange = (id, fileId) => {
    if (typeof fileId === 'number') {
      const currentFileIds = getValues('fileUploadIds') || [];
      const updatedFileIds = [...currentFileIds];
      setValue('fileUploadIds', updatedFileIds);
    }
  };

  return (
    <DashboardLayout title='Create New Tender Inspection Checklist'>
      <GlassCard className='p-5'>
        <form onSubmit={handleSubmit(handleSubmitTenderChecklist)}>
          <Grid container spacing={2}>
            <Grid item md={GridValue}>
              <FmSearchableSelect
                name='tenderId'
                control={control}
                apiUrl='tenders'
                valueField='tenderId'
                labelField={['tenderCode', 'tenderName']}
                showField={['tenderName']}
                onChangeProp={(value) => {
                  if (value?.clientId) {
                    setValue('clientId', value?.clientId);
                    clearErrors('clientId');
                  }
                }}
                pageSize={20}
                label={'Tenders'}
              />
            </Grid>
            <Grid item md={GridValue}>
              <FmTextField
                name='propertyName'
                label='Property Name'
                control={control}
                required
              />
            </Grid>
            <Grid item md={GridValue}>
              <FmDatePicker
                name='visitedDate'
                label='Site Visited Date'
                control={control}
                required
              />
            </Grid>
            <Grid item md={GridValue}>
              <FmAutoComplete
                name='clientId'
                control={control}
                options={clients}
                label='Clients'
                displayField='clientName'
                optionFields={['clientName']}
                valueKey='clientId'
                required
              />
            </Grid>
            <Grid item md={GridValue}>
              <FmTextField
                name='existServiceProvider'
                label='Exist Service Provider'
                control={control}
                required
              />
            </Grid>
            <Grid item md={GridValue}>
              <FmAutoComplete
                name='locationId'
                control={control}
                options={locations}
                label='Locations'
                displayField='locationName'
                optionFields={['locationName']}
                valueKey='locationId'
                required
              />
            </Grid>
            <Grid item md={GridValue}>
              <FmAutoComplete
                name='emiratedId'
                control={control}
                options={regions}
                label='Emirates'
                displayField='regionName'
                optionFields={['regionName']}
                valueKey='regionId'
                required
              />
            </Grid>
            <Grid item md={GridValue}>
              <FmAutoComplete
                name='buildingId'
                control={control}
                options={buildings}
                label='Buildings'
                displayField='buildingName'
                optionFields={['buildingName']}
                valueKey='buildingId'
                required
              />
            </Grid>
            <Grid item md={GridValue}>
              <FmTextField
                name='flatPerFloor'
                label='Flats'
                control={control}
                pattern='Number'
              />
            </Grid>
            <Grid item md={GridValue}>
              <FmTextField
                label='No.of Floors'
                name='noOfFloor'
                control={control}
                pattern='Number'
              />
            </Grid>
            <Grid item md={GridValue * 2}>
              <FmTextField name='remarks' label='Remarks' control={control} />
            </Grid>
            <Grid item md={GridValue * 3}>
              <Box>
                <h3 className='mb-3 font-semibold'>Additional Images Upload</h3>
                <Box className='flex gap-5 flex-wrap'>
                  <Button
                    onClick={handleAddComponent}
                    sx={{
                      minWidth: '100px',
                      height: '100px',
                      border: '1px solid',
                      borderColor: 'border.main',
                      flexDirection: 'column',
                      padding: 0,
                      bgcolor: 'background.white',
                    }}
                  >
                    <span className='text-teal-600 text-3xl mb-3'>
                      <Icon icon='simple-line-icons:plus' />
                    </span>
                    <span>Add Upload</span>
                  </Button>

                  {components.map((component) => (
                    <UploadSquareBoxes
                      key={component.id}
                      imageUrl={component.path}
                      setImageUrl={setImageUrl} // If you need this, otherwise omit
                      handleRemove={handleRemoveComponent}
                      setComponents={setComponents}
                      id={component.id}
                      setLoading={setImageLoading}
                      isLoading={imageLoading[component.id]}
                      onChange={(fileId) =>
                        handleFileChange(component.id, fileId)
                      }
                      fileTypeId={38}
                    />
                  ))}
                </Box>
                {errors.fileUploadIds && (
                  <span style={{ color: 'red' }}>
                    {errors.fileUploadIds.message}
                  </span>
                )}
              </Box>
            </Grid>
          </Grid>
          <ActionButtons
            onSubmit={handleSubmit(handleSubmitTenderChecklist)}
            onCancel={() => navigate(-1)}
            onReset={reset}
            submitLoading={createLoading}
            cancelLoading={false}
          />
        </form>
      </GlassCard>
    </DashboardLayout>
  );
};

export default CreateTenderInspectionChecklist;
