import React, { useEffect, useMemo, useState } from 'react';
import DashboardLayout from '../../../components/DashboardLayout.tsx';
import PageHeader from '../../../components/PageHeader';
import DataTable from '../../../components/DataTable.tsx';
import GlassCard from '../../../components/small/GlassCard.tsx';
import TableActionButtons from '../../../components/_form/TableActionButtons';
import { ViewField, StatusBadge } from '../../../components/_form/FormElements';
import { Box, Grid } from '@mui/material';
import { CreateButton } from '../../../components/small/Buttons';
import TableSearch from '../../../components/_form/TableSearch.jsx';
import MuiDialogOne from '../../../components/_mui/MuiDialogOne';
import { Nav } from '../../../utils';
import Crud_Service from '../../../apis/CrudService';
import { useForm, SubmitHandler } from 'react-hook-form';
import { useTranslation } from 'react-i18next';
import CommonLoader from '../../../components/page/CommonLoader';
import useLoader from '../../../components/helpers/UseLoader.tsx';
import ActionButtons from '../../../components/_form/ActionButtons.tsx';
import useTableLogic from '../../../components/helpers/UseTableLogic.tsx';
import FmTextField from '../../../components/_mui/FmTextField.tsx';
import FmAutoComplete from '../../../components/_mui/FmAutoComplete.tsx';
import { MasterDatatableHeight } from '../../../components/helpers/Containers.jsx';
import { statusOptions } from '../../../utils/CommonVariables.tsx';
import { BuildingCategoryProps } from '../../../interfaces/PreSalesInterfaces.ts';
import { toast } from 'react-toastify';
import { yupResolver } from '@hookform/resolvers/yup';
import * as Yup from 'yup';
import Icons from '../../../utils/Icon.jsx';
import { useSelector } from 'react-redux';
import { findNavByName } from '../../../utils/navUtils.ts';
import usePermissions from '../../../utils/CommonUtils/PermissionUtil.tsx';
import DataTableToolbarButtons from '../../../components/helpers/DataTableToolbarButtons.tsx';
import FmSearchableSelect from '../../../components/_mui/FmSearchableSelect.tsx';
import { siteMasterSchema } from '../../../utils/CommonvalidationSchemas.tsx';

const SitesMaster = (props) => {
  const { type } = props;
  const [openCreate, setOpenCreate] = React.useState(false);
  const [openView, setOpenView] = React.useState(false);
  const [editModal, setEditModal] = useState(false);
  const [editData, setEditData] = useState(null);
  const crud = new Crud_Service();
  const { t } = useTranslation();
  const [viewData, setViewData] = useState();
  const [loading, setLoader] = useState(false);

  const { handleSubmit, setValue, control, reset } =
    useForm<BuildingCategoryProps>({
      resolver: yupResolver(siteMasterSchema),
      mode: 'onChange',
    });
  const {
    createLoading,
    startCreateLoading,
    stopCreateLoading,
    editLoading,
    startEditLoading,
    stopEditLoading,
  } = useLoader();
  const {
    rows,
    pageSize,
    pageNumber,
    setSearchKeyword,
    isLoading,
    tableRecordCounts,
    searchKeyword,
    handlePaginationModelChange,
    handleSortModelChange,
    fetchData,
  } = useTableLogic('sites');
  const menu = useSelector((state) => state?.menu?.items);

  const SubMenu = useMemo(() => {
    return menu?.map((item) => item?.menus?.flat()).flat() || [];
  }, [menu]);

  const resultCafm = useMemo(
    () => findNavByName(SubMenu, 'CAFM', 'All master'),
    [SubMenu]
  );
  const currentPath = location.pathname;
  const index = resultCafm?.findIndex((item) => item.url === currentPath);
  const menuPermission = resultCafm[index]?.permissionDtos;
  const permissions = usePermissions(menuPermission);

  useEffect(() => {
    document.title = `CAFM - Building Categories`;
  }, []);

  const handleCreateClickOpen = () => {
    reset();
    setOpenCreate(true);
  };
  const handleCreateClose = () => {
    reset();
    setOpenCreate(false);
  };
  const handleSubmitBuildingCategories: SubmitHandler<
    BuildingCategoryProps
  > = async (values) => {
    startCreateLoading();
    await crud.create('sites', values, (err, res) => {
      if (res?.status === 201) {
        setOpenCreate(false);
        stopCreateLoading();
        toast.success('Sites Created Successfully');
        reset();
        fetchData();
      } else {
        stopCreateLoading();
        setOpenCreate(true);
      }
    });
  };

  const handleViewClickOpen = async (view) => {
    setOpenView(true);
    setLoader(true);
    await crud.getSingle('sites', view?.siteId, (err, res) => {
      if (res?.status === 200) {
        setViewData(res?.data);
        setLoader(false);
      }
    });
  };

  const handleEditClick = async (view) => {
    setEditModal(true);
    setLoader(true);
    await crud.getSingle('sites', view?.siteId, (err, res) => {
      if (res?.status === 200) {
        Object.entries({
          ...res?.data,
        }).forEach(([key, value]) => {
          setValue(key, value);
        });
        setEditData(res?.data);
        setLoader(false);
      } else {
        setLoader(false);
      }
    });
  };

  const handleEditBuildingCategories = async (values) => {
    startEditLoading();
    await crud.update(
      'sites',
      editData?.siteId,
      values,
      (err, res) => {
        if (res?.status === 200) {
          setEditModal(false);
          stopEditLoading();
          toast.success('Sites Updated Successfully');
          fetchData();
        } else {
          stopEditLoading();
          setEditModal(true);
        }
      }
    );
  };

  const handleDeleteClick = async (view) => {
    await crud.remove('sites', view?.siteId, (err, res) => {
      if (res?.status === 204) {
        fetchData();
        toast.success('Sites Deleted Successfully');
      } else {
      }
    });
  };

  const handleViewClose = () => {
    setOpenView(false);
  };

  const counters = [
    {
      name: 'All',
      count: tableRecordCounts?.total || 0,
    },
    {
      name: 'Active',
      count: tableRecordCounts?.active || 0,
    },
    {
      name: 'Deactivated',
      count: tableRecordCounts?.inActive || 0,
    },
  ];

  const ActionData = [
    {
      name: t('view'),
      icon: Icons.view,
      onClick: (props) => handleViewClickOpen(props),
      hidden: !permissions.view,
    },
    {
      name: t('edit'),
      icon: Icons.edit,
      onClick: (props) => handleEditClick(props),
      hidden: !permissions.edit,
    },
    {
      name: t('delete'),
      icon: Icons.delete,
      onClick: (props) => handleDeleteClick(props),
      danger: true,
      hidden: !permissions.delete,
    },
  ];

  const columns = [
    {
      field: 'action',
      headerName: '',
      width: 140,
      sortable: false,
      disableClickEventBubbling: false,

      renderCell: (params) => {
        return (
          <TableActionButtons
            Actions={ActionData?.map((action) => ({
              ...action,
              onClick: () => action.onClick(params.row),
            }))}
          />
        );
      },
    },
    {
      field: 'siteTypeName',
      headerName: 'Site Type Name',
      flex: 1,
    },
    {
      field: 'address',
      headerName: 'Address',
      flex: 1,
    },
    {
      field: 'coordinates',
      headerName: 'Coordinates',
      flex: 1,
    },
    {
      field: 'dimensions',
      headerName: 'Dimensions',
      flex: 1,
    },
    {
      field: 'ownershipTypeName',
      headerName: 'Ownership Type Name',
      flex: 1,
    },
    {
      field: 'siteUsageName',
      headerName: 'Site Usage Name',
      flex: 1,
    },
    {
      field: 'status',
      headerName: t('status'),
      flex: 1,
      renderCell: (params) => {
        return (
          <StatusBadge
            title={params.value === 2 ? 'Active' : 'Inactive'}
            type={params.value === 2 ? 'green' : 'red'}
          />
        );
      },
    },
  ];

  return (
    <DashboardLayout
      title='Building Categories'
      actionButtons={
        <>
          <PageHeader counters={counters} />
        </>
      }
      hasSubmenu
      menu={resultCafm}
    >
      <GlassCard className='h-full-css'>
        <MasterDatatableHeight>
          <DataTable
            rows={rows}
            columns={columns}
            loading={isLoading}
            getRowClassName={(params) =>
              `${params.row.status === 0 && 'bg-red-50'}`
            }
            sortingMode='server'
            paginationMode='server'
            onPaginationModelChange={(model) =>
              handlePaginationModelChange(model)
            }
            onSortModelChange={(model) => handleSortModelChange(model)}
            page={pageNumber - 1}
            pageSize={pageSize}
            rowCount={tableRecordCounts?.total}
            slots={{
              toolbar: () => (
                <Box
                  className='p-2 w-full flex justify-between items-center'
                  sx={{
                    borderBottom: '1px solid',
                    borderColor: 'border.main',
                  }}
                >
                  <Box sx={{ maxWidth: '250px' }}>
                    <TableSearch
                      placeholder='Search'
                      fullWidth
                      setSearchKeyword={setSearchKeyword}
                      searchValue={searchKeyword}
                    />
                  </Box>
                  <Box className='flex items-center gap-4 justify-end'>
                    {permissions.create && (
                      <CreateButton
                        name='New'
                        onClick={handleCreateClickOpen}
                      />
                    )}
                    <DataTableToolbarButtons
                      disableColumn
                      // handleExcelExport={downloadDesignations}
                    />
                  </Box>
                </Box>
              ),
            }}
          />
        </MasterDatatableHeight>
      </GlassCard>

      <MuiDialogOne
        title={t('create')}
        open={openCreate}
        onClose={handleCreateClose}
      >
        <form onSubmit={handleSubmit(handleSubmitBuildingCategories)}>
          <Grid container spacing={3}>
            <Grid item md={12}>
              <FmTextField
                name='siteName'
                control={control}
                label='Site Name'
                required
              />
            </Grid>
            <Grid item md={12}>
              <FmSearchableSelect
                name='contractId'
                control={control}
                apiUrl='contracts'
                valueField='contractId'
                labelField={['contractCode', 'contractName']}
                showField={['contractCode', 'contractName']}
                label='Contracts'
                required
              />
            </Grid>
            <Grid item md={12}>
              <FmSearchableSelect
                name='locationId'
                control={control}
                apiUrl='locations'
                valueField='locationId'
                labelField={['locationCode', 'locationName']}
                showField={['locationCode', 'locationName']}
                label='Locations'
                required
              />
            </Grid>
            <Grid item md={12}>
              <FmSearchableSelect
                name='siteTypeId'
                control={control}
                apiUrl='sites'
                valueField='siteTypeId'
                labelField={['siteTypeCode', 'siteTypeName']}
                showField={['siteTypeCode', 'siteTypeName']}
                label={'Site Type'}
                required
              />
            </Grid>
            <Grid item md={12}>
              <FmTextField
                name='address'
                control={control}
                label='Address'
                required
              />
            </Grid>
            <Grid item md={12}>
              <FmTextField
                name='coordinates'
                control={control}
                label='Coordinates'
                required
              />
            </Grid>
            <Grid item md={12}>
              <FmTextField
                name='dimensions'
                control={control}
                label='Dimensions'
                required
              />
            </Grid>
            <Grid item md={12}>
              <FmSearchableSelect
                name='ownershipTypeId'
                control={control}
                apiUrl='ownershiptypes'
                valueField='ownershipTypeId'
                labelField={['ownershipTypeCode', 'ownershipTypeName']}
                showField={['ownershipTypeCode', 'ownershipTypeName']}
                label={'Ownership Site Type'}
                required
              />
            </Grid>
            <Grid item md={12}>
              <FmSearchableSelect
                name='siteUsageId'
                control={control}
                apiUrl='siteusage'
                valueField='siteUsageId'
                labelField={['siteUsageCode', 'siteUsageName']}
                showField={['siteUsageCode', 'siteUsageName']}
                label={'Site Usage'}
                required
              />
            </Grid>
            <Grid item md={12}>
              <ActionButtons
                onSubmit={handleSubmit(handleSubmitBuildingCategories)}
                onCancel={handleCreateClose}
                onReset={reset}
                submitLoading={createLoading}
                cancelLoading={false}
                submitText='Create'
              />
            </Grid>
          </Grid>
        </form>
      </MuiDialogOne>

      <MuiDialogOne
        title='View Details'
        open={openView}
        onClose={handleViewClose}
      >
        {loading ? (
          <CommonLoader />
        ) : (
          <Grid container spacing={3}>
            <Grid item md={6}>
              <ViewField
                label='Site Name'
                title={viewData?.siteName}
              />
            </Grid>
            <Grid item md={6}>
              <ViewField
                label='Ownership Site Type Name'
                title={viewData?.ownershipTypeName}
              />
            </Grid>
            <Grid item md={6}>
              <ViewField
                label='Contract Name'
                title={viewData?.contractName}
              />
            </Grid>
            <Grid item md={6}>
              <ViewField
                label='Location Name'
                title={viewData?.locationName}
              />
            </Grid>
            <Grid item md={6}>
              <ViewField label={t('status')} title={viewData?.statusName} />
            </Grid>
          </Grid>
        )}
      </MuiDialogOne>

      <MuiDialogOne
        title={t('update')}
        open={editModal}
        onClose={() => setEditModal(false)}
      >
        {loading ? (
          <CommonLoader />
        ) : (
          <form onSubmit={handleSubmit(handleEditBuildingCategories)}>
            <Grid container spacing={3}>
              <Grid item md={12}>
                <FmTextField
                  name='siteName'
                  control={control}
                  label='Site Name'
                  required
                />
              </Grid>
              <Grid item md={12}>
                <FmSearchableSelect
                  name='contractId'
                  control={control}
                  apiUrl='contracts'
                  valueField='contractId'
                  labelField={['contractCode', 'contractName']}
                  showField={['contractCode', 'contractName']}
                  label='Contracts'
                  required
                  defaultValue={{
                    contractId: editData?.contractId,
                    contractName:editData?.contractName
                  }}
                />
              </Grid>
              <Grid item md={12}>
                <FmSearchableSelect
                  name='siteTypeId'
                  control={control}
                  apiUrl='sites'
                  valueField='siteTypeId'
                  labelField={['siteTypeCode', 'siteTypeName']}
                  showField={['siteTypeCode', 'siteTypeName']}
                  label={'Site Type'}
                  defaultValue={{
                    siteTypeId:editData?.siteTypeId,
                    siteTypeName:editData?.siteTypeName
                  }}
                  required
                />
              </Grid>
              <Grid item md={12}>
                <FmTextField
                  name='address'
                  control={control}
                  label='Address'
                  required
                />
              </Grid>
              <Grid item md={12}>
                <FmTextField
                  name='coordinates'
                  control={control}
                  label='Coordinates'
                  required
                />
              </Grid>
              <Grid item md={12}>
                <FmTextField
                  name='dimensions'
                  control={control}
                  label='Dimensions'
                  required
                />
              </Grid>
              <Grid item md={12}>
                <FmSearchableSelect
                  name='ownershipTypeId'
                  control={control}
                  apiUrl='ownershiptypes'
                  valueField='ownershipTypeId'
                  labelField={['ownershipTypeCode', 'ownershipTypeName']}
                  showField={['ownershipTypeCode', 'ownershipTypeName']}
                  label={'Ownership Site Type'}
                  defaultValue={{
                    ownershipTypeId:editData?.ownershipTypeId,
                    ownershipTypeName:editData?.ownershipTypeName
                  }}
                  required
                />
              </Grid>
              <Grid item md={12}>
                <FmSearchableSelect
                  name='siteUsageId'
                  control={control}
                  apiUrl='siteusage'
                  valueField='siteUsageId'
                  labelField={['siteUsageCode', 'siteUsageName']}
                  showField={['siteUsageCode', 'siteUsageName']}
                  label={'Site Usage'}
                  defaultValue={{
                    siteUsageId:editData?.siteUsageId,
                    siteUsageName:editData?.siteUsageName
                  }}
                  required
                />
              </Grid>
              <Grid item md={12}>
                <FmAutoComplete
                  name='status'
                  control={control}
                  options={statusOptions}
                  label='Status'
                  displayField='statusName'
                  optionFields={['statusName']}
                  valueKey='statusId'
                />
              </Grid>
              <Grid item md={12}>
                <ActionButtons
                  onSubmit={handleSubmit(handleEditBuildingCategories)}
                  onCancel={() => setEditModal(false)}
                  submitLoading={editLoading}
                  onReset={reset}
                  cancelLoading={false}
                  submitText='Update'
                />
              </Grid>
            </Grid>
          </form>
        )}
      </MuiDialogOne>
    </DashboardLayout>
  );
};

export default SitesMaster;
