import { Icon } from '@iconify/react';
import { Box, Button, Checkbox, FormControl, Grid } from '@mui/material';
import React, { useEffect, useState } from 'react';
import { Controller, useFieldArray, useForm } from 'react-hook-form';
import { useNavigate, useParams } from 'react-router-dom';
import { toast } from 'react-toastify';
import Crud_Service from '../../../../apis/CrudService';
import ActionButtons from '../../../../components/_form/ActionButtons.tsx';
import FmTextField from '../../../../components/_mui/FmTextField.tsx';
import useLoader from '../../../../components/helpers/UseLoader.tsx';
import GlassCard from '../../../../components/small/GlassCard.tsx';
import FmSearchableSelect from '../../../../components/_mui/FmSearchableSelect.tsx';
import ConfirmationPopoverBox from '../../../../components/_form/ConfirmationPopoverBox.tsx';
import { ContractCheckListSchema } from '../../../../utils/CommonvalidationSchemas.tsx';
import { yupResolver } from '@hookform/resolvers/yup';
import FmStack from './../../../../components/layout/FmStack.tsx';

const ViewContractCheckList = () => {
  const {
    control,
    handleSubmit,
    reset,
    setValue,
    getValues,
    formState: { errors },
  } = useForm({
    resolver: yupResolver(ContractCheckListSchema),
    mode: 'onChange',
    defaultValues: {
      checkListItem: [
        {
          remarks: '',
          invoiceChecklistTypeId: null,
          isMandatoryForInvoiceGeneration: false,
          isChecklistFile: false,
        },
      ],
    },
  });

  const { fields, append, remove } = useFieldArray({
    control,
    name: 'checkListItem',
  });

  const crud = new Crud_Service();
  const { createLoading } = useLoader();
  const contractId = useParams().id;
  const [loading, setLoading] = useState(false);
  const [roundDetails, setRoundDetails] = useState();
  const navigate = useNavigate();
  const [contractCheckList, setContractCheckList] = useState(false);

  // upset method
  useEffect(() => {
    fetchDataItems();
  }, []);

  const fetchDataItems = async () => {
    setLoading(true);
    await crud.getAll(
      'contractchecklists',
      { contractId: contractId },
      (_err, res) => {
        if (res?.status === 200) {
          const updatedData = res?.data?.data.map(item => ({
            ...item,
            remarks: item.remarks, // Set default if empty
          }));
          setRoundDetails(updatedData);
          reset({ checkListItem: updatedData });  // Reset form with the updated data
        }
        setLoading(false);
      }
    );
  };


  const onSubmit = async (values) => {

    if (values) {
      const updatedPayload = {
        contractId: contractId,
        createChecklists: values?.checkListItem.map((item) => {
          return {
            remarks: item.remarks || '',
            invoiceChecklistTypeId: item.invoiceChecklistTypeId,
            // Convert to boolean explicitly and ensure it's not null
            isMandatoryForInvoiceGeneration: Boolean(item.isMandatoryForInvoiceGeneration),
            isChecklistFile: Boolean(item.isChecklistFile),
          };
        }),
      };


      setLoading(true);
      try {
        const response = await crud.create(
          'contractchecklists',
          updatedPayload,
          (err, res) => {
            if (res?.status === 201) {
              toast.success('CheckList Added Successfully');
              fetchDataItems(); // Use fetchDataItems instead of fetchData
            } else {
              toast.error('Failed to add checklist');
            }
            setLoading(false);
          }
        );
      } catch (error) {
        setLoading(false);
      }
    }
  };

  const handleRemove = (index) => {
    const itemToDelete = roundDetails[index];
    remove(index);
    if (itemToDelete?.contractChecklistId) {
      setLoading(true);
      crud.remove('contractchecklists', itemToDelete?.contractChecklistId, (_err, res) => {
        if (res?.status === 204) {
          toast.success(`CheckList Deleted Successfully`);
          fetchDataItems();
        }
        setLoading(false);
      });
    }
  };

  return (
    <GlassCard className='p-3'>
      <form onSubmit={handleSubmit(onSubmit)}>
        <FmStack spacing={2}>
          {fields?.map((field, index) => (
            <Box key={field?.id} className='relative pe-[45px]'>
              <Grid container spacing={2}>
                <Grid item md={3}>
                  <FmSearchableSelect
                    name={`checkListItem.${index}.invoiceChecklistTypeId`}
                    control={control}
                    apiUrl='invoicechecklisttypes'
                    valueField='invoiceChecklistTypeId'
                    defaultValue={{
                      invoiceChecklistTypeId: field.invoiceChecklistTypeId,
                      invoiceChecklistTypeName: field.invoiceChecklistTypeName,
                    }}
                    labelField={[
                      'invoiceChecklistTypeCode',
                      'invoiceChecklistTypeName',
                    ]}
                    showField={['invoiceChecklistTypeCode', 'invoiceChecklistTypeName']}
                    pageSize={50}
                    label='Invoice Check List Type'
                    required
                  />
                </Grid>
                <Grid item md={3}>
                  <FmTextField

                    name={`checkListItem[${index}].remarks`}
                    control={control}
                    label='Remarks'
                    maxRows={2}
                    rows={2}
                    multiline
                  />
                </Grid>
                <Grid item md={3}>
                  <FormControl component='fieldset' className='flex items-center'>
                    <div className='flex items-center'>
                      <Controller
                        name={`checkListItem.${index}.isMandatoryForInvoiceGeneration`}
                        control={control}
                        defaultValue={false}
                        render={({ field }) => (
                          <Checkbox
                            checked={Boolean(field.value)}
                            onChange={(ev) => {
                              const newValue = ev.target.checked;
                              setValue(
                                `checkListItem.${index}.isMandatoryForInvoiceGeneration`,
                                newValue
                              );
                              field.onChange(newValue);
                            }}
                            color='primary'
                            className='p-0 me-3'
                          />
                        )}
                      />
                      <Box className='mb-1 font-semibold'>
                        Mandatory for Invoice Generation
                      </Box>
                    </div>
                    {errors?.checkListItem?.[index]?.isMandatoryForInvoiceGeneration && (
                      <p style={{ color: 'red' }}>
                        {errors?.checkListItem?.[index]?.isMandatoryForInvoiceGeneration?.message}
                      </p>
                    )}
                  </FormControl>
                </Grid>
                <Grid item md={3}>
                  <FormControl component='fieldset' className='flex items-center'>
                    <div className='flex items-center'>
                      <Controller
                        name={`checkListItem.${index}.isChecklistFile`}
                        control={control}
                        defaultValue={false}
                        render={({ field: { onChange, value, ...field } }) => (
                          <Checkbox
                            {...field}
                            checked={Boolean(value)}
                            onChange={(e) => {
                              const newValue = e.target.checked;
                              setValue(`checkListItem.${index}.isChecklistFile`, newValue);
                              onChange(newValue);
                            }}
                            color='primary'
                            className='p-0 me-3'
                          />
                        )}
                      />
                      <Box className='mb-1 font-semibold'>
                        Checklist File
                      </Box>
                    </div>
                    {errors?.checkListItem?.[index]?.isChecklistFile && (
                      <p style={{ color: 'red' }}>
                        {errors?.checkListItem?.[index]?.isChecklistFile?.message}
                      </p>
                    )}
                  </FormControl>
                </Grid>
              </Grid>
              {/* <IconButton
                onClick={() => handleRemove(index)}
                disabled={fields.length === 1}
                sx={{ padding: '1px' }}
                color='error'
              >
                <Icon icon='ic:baseline-clear' />
              </IconButton> */}
              <Box className='absolute top-1 right-2'>
                <ConfirmationPopoverBox
                  icon={<Icon icon='tabler:trash' />}
                  // disabled={fields.length === 1}
                  onConfirm={() => handleRemove(index)}
                  title={'Delete'}
                />
              </Box>
            </Box>
          ))}
        </FmStack>
        <Box className='mt-5 w-full flex justify-end'>
          <Button
            variant='text'
            onClick={() =>
              append({
                invoiceCheckListTypeId: null,
                remarks: '',
                invoiceCheckListId: null,
                isMandatoryForInvoiceGeneration: null,
                isCheckListFile: null,
              })
            }
            className='uppercase font-bold text-red-500 pe-0'
            startIcon={<Icon icon='ic:baseline-plus' />}
          >
            Add Field
          </Button>
        </Box>
        <Box className='mt-5'>
          <ActionButtons
            submitText={contractCheckList ? 'Save' : 'Create'}
            onSubmit={handleSubmit(onSubmit)}
            onCancel={() => navigate(-1)}
            submitLoading={createLoading}
            disabled={fields.length === 0}
          />
        </Box>
      </form>
    </GlassCard>
  );
};

export default ViewContractCheckList;
