import React, { useEffect, useState } from 'react';
import { Box, Grid, FormControlLabel, Radio, RadioGroup } from '@mui/material';
import { AdapterDayjs } from '@mui/x-date-pickers/AdapterDayjs';
import { LocalizationProvider } from '@mui/x-date-pickers/LocalizationProvider';
import { ActionButtons } from '../../../components/_form';
import { useNavigate } from 'react-router-dom';
import Crud_Service from '../../../apis/CrudService';
import { Controller, useForm } from 'react-hook-form';
import { toast } from 'react-toastify';
import DashboardLayout from '../../../components/DashboardLayout.tsx';
import useLoader from '../../../components/helpers/UseLoader.tsx';
import FmTextField from '../../../components/_mui/FmTextField.tsx';
import GlassCard from '../../../components/small/GlassCard.tsx';
import { yupResolver } from '@hookform/resolvers/yup';
import { supplierValidationSchema } from '../../../utils/CommonvalidationSchemas.tsx';
import FmAutoComplete from '../../../components/_mui/FmAutoComplete.tsx';
import FmCurrencyInput from '../../../components/_mui/FmCurrencyInput.tsx';

const CreateSuppliers = () => {
  const navigate = useNavigate();
  const [countries, setCountries] = useState([]);
  const [regions, setRegions] = useState([]);
  const [supplierTypes, setSupplierTypes] = useState([]);
  const { handleSubmit, control, reset, watch, setValue } = useForm({
    resolver: yupResolver(supplierValidationSchema),
    mode: 'onChange',
  });
  const { createLoading, startCreateLoading, stopCreateLoading } = useLoader();
  const crud = new Crud_Service();

  useEffect(() => {
    getCountries();
    getRegions();
    getSupplierTypes();
  }, []);

  //get All countries
  const getCountries = async () => {
    await crud.getAll(`countries`, '', (err, res) => {
      if (err) {
        toast.error('Error fetching countries:', err);
        return;
      }
      if (res?.status === 200) {
        setCountries(res?.data?.data);
      }
    });
  };

  //get All Regions
  const getRegions = async () => {
    await crud.getAll(
      `regions`,
      {
        sortDirection: 'asc',
        sortBy: 'regionName',
      },
      (err, res) => {
        if (err) {
          toast.error('Error fetching regions:', err);
          return;
        }
        if (res?.status === 200) {
          setRegions(res?.data?.data);
        }
      }
    );
  };

  //get All Supllier Types
  const getSupplierTypes = async () => {
    await crud.getAll(
      `suppliertypes`,
      { sortDirection: 'asc', sortBy: 'supplierTypeName' },
      (err, res) => {
        if (err) {
          toast.error('Error fetching supplier types:', err);
          return;
        }
        if (res?.status === 200) {
          setSupplierTypes(res?.data?.data);
        }
      }
    );
  };
  const handleSubmitSuppliers = async (values) => {
    const CombinedData = {
      ...values,
      agreement: values?.agreement || false,
      creditLimit: !values?.debitORCredit
        ? 0
        : Number(values?.creditLimit) || 0,
      debitORCredit: values?.debitORCredit || false,
      taxPercentage: Number(values?.taxPercentage),
      emailId: values?.emailId || null,
    };
    startCreateLoading();

    await crud.create('suppliers', CombinedData, (err, res) => {
      if (err) {
        stopCreateLoading();
        toast.error('Something went wrong:', err);
        return;
      }
      if (res?.status === 201) {
        toast.success('Supplier Created Successfully');
        navigate(-1);
        stopCreateLoading();
      } else {
        stopCreateLoading();
      }
    });
  };
  const debitORCreditchange = watch('debitORCredit');

  useEffect(() => {
    setValue('creditLimit', 0);
  }, [debitORCreditchange]);

  return (
    <>
      <DashboardLayout title='Create New Supplier'>
        <Box className='mx-auto' sx={{ maxWidth: '950px' }}>
          <GlassCard className='p-5'>
            <form onSubmit={handleSubmit(handleSubmitSuppliers)}>
              <LocalizationProvider dateAdapter={AdapterDayjs}>
                <Grid container spacing={2}>
                <Grid item md={6}>
                    <FmTextField
                      name='supplierCode'
                      control={control}
                      label='Supplier Code'
                      required
                    />
                  </Grid>
                  <Grid item md={6}>
                    <FmTextField
                      name='officialSupplierName'
                      control={control}
                      label='Supplier Name'
                      required
                    />
                  </Grid>
                  <Grid item md={6}>
                    <FmAutoComplete
                      name='supplierTypeId'
                      control={control}
                      label='Supplier Type'
                      options={supplierTypes}
                      displayField='supplierTypeName'
                      optionFields={['supplierTypeName']}
                      valueKey='supplierTypeId'
                      required
                    />
                  </Grid>
                  <Grid item md={6}>
                    <FmCurrencyInput
                      name='mobileNumber'
                      control={control}
                      label='Mobile Number'
                    />
                  </Grid>
                  <Grid item md={6}>
                    <FmTextField
                      name='emailId'
                      control={control}
                      label='Email Id'
                    />
                  </Grid>
                  <Grid item md={6}>
                    <FmAutoComplete
                      name='regionId'
                      control={control}
                      label='Region'
                      options={regions}
                      displayField='regionName'
                      optionFields={['regionName']}
                      valueKey='regionId'
                    />
                  </Grid>
                  <Grid item md={6}>
                    <FmAutoComplete
                      name='countryId'
                      control={control}
                      label='Country'
                      options={countries}
                      displayField='countryName'
                      optionFields={['countryName']}
                      valueKey='countryId'
                    />
                  </Grid>
                  <Grid item md={6}>
                    <FmTextField
                      name='taxNumber'
                      control={control}
                      label='Tax Number'
                    />
                  </Grid>
                  <Grid item md={6}>
                    <FmTextField
                      name='taxPercentage'
                      control={control}
                      label='Tax Percentage'
                      pattern='Percentage'
                    />
                  </Grid>
                  <Grid item md={6}>
                    <FmTextField
                      name='tradeLicenseNumber'
                      control={control}
                      label='Trade License Number'
                    />
                  </Grid>
                  <Grid item md={3}>
                    <h3 className='mb-1 font-semibold'>Agreement</h3>
                    <Controller
                      name='agreement'
                      control={control} // Make sure you have passed 'control' from useForm
                      defaultValue={false} // Default value for the radio group
                      render={({ field }) => (
                        <RadioGroup
                          {...field} // Spread the field props into the RadioGroup
                          row
                          aria-labelledby='demo-row-radio-buttons-group-label'
                        >
                          <FormControlLabel
                            value='true' // Radio values should be strings if you're using 'true'/'false'
                            control={
                              <Radio className='m-0 p-0 me-2' size='small' />
                            }
                            label='Yes'
                            className='me-4'
                          />
                          <FormControlLabel
                            value='false' // Same here for 'false'
                            control={
                              <Radio className='m-0 p-0 me-2' size='small' />
                            }
                            label='No'
                          />
                        </RadioGroup>
                      )}
                    />
                  </Grid>
                  <Grid item md={3}>
                    <h3 className='mb-1 font-semibold'>Debit/Credit</h3>
                    <Controller
                      name='debitORCredit'
                      control={control}
                      defaultValue={false}
                      render={({ field }) => (
                        <RadioGroup
                          row
                          aria-labelledby='demo-row-radio-buttons-group-label'
                          {...field}
                        >
                          <FormControlLabel
                            value={false}
                            control={
                              <Radio className='m-0 p-0 me-2' size='small' />
                            }
                            label='Debit'
                            className='me-4'
                          />
                          <FormControlLabel
                            value={true}
                            control={
                              <Radio className='m-0 p-0 me-2' size='small' />
                            }
                            label='Credit'
                          />
                        </RadioGroup>
                      )}
                    />
                  </Grid>
                  {debitORCreditchange === 'true' && (
                    <Grid item md={3}>
                      <FmTextField
                        name='creditLimit'
                        control={control}
                        label='Credit Limit'
                        pattern='Number'
                        required
                      />
                    </Grid>
                  )}
                                    <Grid item md={6}>
                    <FmTextField
                      name='address'
                      control={control}
                      label='Address'
                      multiline
                      rows={3}
                      maxRows={4}
                    />
                  </Grid>
                  <Grid item md={6}>
                    <FmTextField
                      name='headOfficeAddress'
                      control={control}
                      label='Head Office Address'
                      multiline
                      rows={3}
                      maxRows={4}
                    />
                  </Grid>
                </Grid>
                <ActionButtons
                  onSubmit={handleSubmit(handleSubmitSuppliers)}
                  onCancel={() => navigate(-1)}
                  onReset={reset}
                  submitLoading={createLoading}
                  cancelLoading={false}
                  submitText='Create'
                />
              </LocalizationProvider>
            </form>
          </GlassCard>
        </Box>
      </DashboardLayout>
    </>
  );
};

export default CreateSuppliers;
