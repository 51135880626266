import React, { useState } from 'react';
import DashboardLayout from '../../../components/DashboardLayout.tsx';
import FmTabs from '../../../components/_mui/FmTabs.tsx';
import GlassCard from '../../../components/small/GlassCard.tsx';
import { Box } from '@mui/material';
import LeaveAccruals from './LeaveAccruals.tsx';
import GratuityAccruals from './GratuityAccruals.tsx';

const TabsList = [
  {
    id: 1,
    name: 'Leave Accruals',
  },
  {
    id: 2,
    name: 'Gratuity Accruals',
  },
];

const Accruals = () => {
  const [value, setValue] = useState(1);

  const handleChange = (event, newValue) => {
    setValue(newValue);
  };

  return (
    <DashboardLayout title='Accruals'>
      <Box className='flex h-full flex-col gap-2'>
        <GlassCard>
          <FmTabs value={value} onChange={handleChange} TabsList={TabsList} />
        </GlassCard>
        <Box className='flex-1 min-h-0 '>
          {value === 1 && <LeaveAccruals />}
          {value === 2 && <GratuityAccruals />}
        </Box>
      </Box>
    </DashboardLayout>
  );
};

export default Accruals;
