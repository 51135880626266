import React, { useState } from 'react';
import Box from '@mui/material/Box';
import Stack from '@mui/material/Stack';
import HeaderMenu from './helpers/HeaderMenu';
import HeaderNotification from './helpers/HeaderNotification';
import HeaderHistoryDropdown from './helpers/HeaderHistoryDropdown';
import PropTypes, { string } from 'prop-types';
import Icons from '../utils/Icon';
import HeaderSearchButton from './helpers/HeaderSearchButton';
import HeaderAppDropdown from './helpers/HeaderAppDropdown';
import { IconButtonWithTooltip } from './small/Buttons';
import HeaderLanguage from './helpers/HeaderLanguage';
import { useTheme } from '../ThemeContext.jsx';
import HelpCenter from './helpers/HelpCenter.tsx';

const Header = (props) => {
  const { type } = props;
  const [showMenu, setShowMenu] = useState(false);
  const { toggleTheme, mode } = useTheme();

  const [activeMenuId, setactiveMenuId] = useState(0);

  const handleMenuOpen = (i) => {
    setShowMenu(!showMenu);
    setactiveMenuId(i);
  };

  return (
    <>
      <Box
        id='header_id'
        sx={{
          width: '100%',
          display: 'flex',
          px: '8px',
          py: '0px',
          justifyContent: 'space-between',
          alignItems: 'center',
          zIndex: 11,
          background: (theme) => theme.palette.background.header,
          color: '#ffffff',
          height: '40px',
        }}
        className='sm:flex-col'
      >
        <Stack direction='row' alignItems='center' spacing={3}>
          <Box
            component='img'
            src={
              mode === 'dark'
                ? '/images/logo-white.png'
                : '/images/logo-white.png'
            }
            sx={{ maxWidth: '100%', height: '35px' }}
            loading='lazy'
          />
          {type !== 'ESS' && <HeaderAppDropdown />}
          <HeaderSearchButton />
        </Stack>
        <Stack direction='row' alignItems='center' spacing={2}>
          <HelpCenter />

          <Stack direction='row' alignItems='center' spacing={0.2}>
            <HeaderLanguage />
            <IconButtonWithTooltip
              name={mode === 'dark' ? 'Light Mode' : 'Dark Mode'}
              icon={mode === 'dark' ? Icons.sun : Icons.moon}
              onClick={toggleTheme}
            />
            {/* <HeaderHistoryDropdown /> */}
            <HeaderNotification />
          </Stack>
          <HeaderMenu />
        </Stack>
      </Box>
    </>
  );
};

Header.propTypes = {
  title: PropTypes.string,
  type: string,
};

export default Header;
