import { Box, Grid } from '@mui/material';
import React, { useEffect, useMemo, useState } from 'react';
import { useForm } from 'react-hook-form';
import { useTranslation } from 'react-i18next';
import { useSelector } from 'react-redux';
import { toast } from 'react-toastify';
import apiInstance from '../../../apis/ApiService.jsx';
import Crud_Service from '../../../apis/CrudService';
import ActionButtons from '../../../components/_form/ActionButtons.tsx';
import { StatusBadge, ViewField } from '../../../components/_form/FormElements';
import TableActionButtons from '../../../components/_form/TableActionButtons';
import TableSearch from '../../../components/_form/TableSearch.jsx';
import FmAutoComplete from '../../../components/_mui/FmAutoComplete.tsx';
import FmTextField from '../../../components/_mui/FmTextField.tsx';
import MuiDialogOne from '../../../components/_mui/MuiDialogOne';
import DashboardLayout from '../../../components/DashboardLayout.tsx';
import DataTable from '../../../components/DataTable.tsx';
import DataTableToolbarButtons from '../../../components/helpers/DataTableToolbarButtons.tsx';
import useLoader from '../../../components/helpers/UseLoader.tsx';
import useTableLogic from '../../../components/helpers/UseTableLogic.tsx';
import CommonLoader from '../../../components/page/CommonLoader';
import PageHeader from '../../../components/PageHeader';
import { CreateButton } from '../../../components/small/Buttons';
import GlassCard from '../../../components/small/GlassCard.tsx';
import { statusOptions } from '../../../utils/CommonVariables.tsx';
import Icons from '../../../utils/Icon.jsx';
import { findNavByName } from '../../../utils/navUtils.ts';
import useCommonFetchApi from '../../../components/helpers/useCommonFetchApi.tsx';
import usePermissions from '../../../utils/CommonUtils/PermissionUtil.tsx';
import FmSearchableSelect from '../../../components/_mui/FmSearchableSelect.tsx';

const RegionMasters = (props) => {
  const { type } = props;
  const [openCreate, setOpenCreate] = React.useState(false);
  const [openView, setOpenView] = React.useState(false);
  const [editModal, setEditModal] = useState(false);
  const [editData, setEditData] = useState(null);
  const crud = new Crud_Service();
  const { t } = useTranslation();
  const [viewData, setViewData] = useState();
  const [loading, setLoader] = useState(false);
  const [countryId, setCountryId] = useState(null);
  const [countries, setCountries] = useState([]);
  const { handleSubmit, setValue, control, reset } = useForm();
  const {
    createLoading,
    startCreateLoading,
    stopCreateLoading,
    editLoading,
    startEditLoading,
    stopEditLoading,
  } = useLoader();
  const {
    rows,
    pageSize,
    pageNumber,
    setSearchKeyword,
    isLoading,
    tableRecordCounts,
    searchKeyword,
    handlePaginationModelChange,
    handleSortModelChange,
    fetchData,
  } = useTableLogic('regions');

  useEffect(() => {
    document.title = `HRMS-Regions`;
    getcountries();
  }, []);

  //get Countries
  const getcountries = async () => {
    await crud.getAll('countries', '', (_err, res) => {
      if (res?.status === 200) {
        setCountries(res?.data?.data);
      }
    });
  };

  const handleCreateClickOpen = () => {
    reset();
    setOpenCreate(true);
  };
  const handleCreateClose = () => {
    reset();
    setOpenCreate(false);
  };
  const handleSubmitRegion = async (values) => {
    startCreateLoading();
    const combinedDtaa = {
      ...values,
      countryId: Number(values?.countryId),
      emirateId: Number(values?.emirateId),
    };

    await crud.create('regions', combinedDtaa, (_err, res) => {
      if (res?.status === 201) {
        toast.success('Region Created Successfully');
        stopCreateLoading();
        setOpenCreate(false);
        fetchData();
        reset();
      } else {
        stopCreateLoading();
        setOpenCreate(true);
      }
    });
  };

  const handleViewClickOpen = async (view) => {
    setOpenView(true);
    setLoader(true);
    await crud.getSingle('regions', view?.regionId, (_err, res) => {
      if (res?.status === 200) {
        setViewData(res?.data);
        setLoader(false);
      } else {
      }
    });
  };

  const handleEditClick = async (view) => {
    setEditModal(true);
    setLoader(true);
    await crud.getSingle('regions', view?.regionId, (_err, res) => {
      if (res?.status === 200) {
        Object.entries({
          ...res?.data,
        }).forEach(([key, value]) => {
          setValue(key, value);
        });
        setEditData(res?.data);
        setCountryId(res?.data?.countryId);
        setLoader(false);
      } else {
        setLoader(false);
      }
    });
  };

  const handleEditRegion = async (values) => {
    startEditLoading();
    const combinedData = {
      ...values,
      countryId: Number(values?.countryId),
      emirateId: Number(values?.emirateId),
    };
    await crud.update(
      'regions',
      editData?.regionId,
      combinedData,
      (_err, res) => {
        if (res?.status === 200) {
          toast.success('Region Updated Successfully');
          stopEditLoading();
          setEditModal(false);
          fetchData();
        } else {
          stopEditLoading();
          setEditModal(true);
        }
      }
    );
  };

  const handleDeleteClick = async (view) => {
    await crud.remove('regions', view?.regionId, (_err, res) => {
      if (res?.status === 204) {
        toast.success('Region Deleted Successfully');
        fetchData();
      } else {
      }
    });
  };

  const handleViewClose = () => {
    setOpenView(false);
  };

  const counters = [
    {
      name: 'All',
      count: tableRecordCounts?.total || 0,
    },
    {
      name: 'Active',
      count: tableRecordCounts?.active || 0,
    },
    {
      name: 'Deactivated',
      count: tableRecordCounts?.inActive || 0,
    },
  ];

  const columns = [
    {
      field: 'action',
      headerName: '',
      width: 140,
      sortable: false,
      disableClickEventBubbling: false,

      renderCell: (params) => {
        return (
          <TableActionButtons
            Actions={ActionData?.map((action) => ({
              ...action,
              onClick: () => action.onClick(params.row),
            }))}
          />
        );
      },
    },
    {
      field: 'regionCode',
      headerName: 'Region Code',
      flex: 1,
    },
    {
      field: 'regionName',
      headerName: 'Region Name',
      flex: 1,
    },
    {
      field: 'countryCode',
      headerName: 'Country Code',
      flex: 1,
    },
    {
      field: 'countryName',
      headerName: 'Country Name',
      flex: 1,
    },
    {
      field: 'regionCode',
      headerName: 'Region Code',
      flex: 1,
    },
    {
      field: 'regionName',
      headerName: 'Region Name',
      flex: 1,
    },
    {
      field: 'status',
      headerName: t('status'),
      flex: 1,
      renderCell: (params) => {
        return (
          <StatusBadge
            title={params.value === 2 ? 'Active' : 'Inactive'}
            type={params.value === 2 ? 'green' : 'red'}
          />
        );
      },
    },
  ];
  const downloadRegions = async () => {
    const downloadUrl = await apiInstance.getFiles('regions/exportexcel');
    const link = document.createElement('a');
    link.href = downloadUrl;
    link.setAttribute('download', 'Region.xlsx');
    document.body.appendChild(link);
    link.click();
    document.body.removeChild(link);
  };
  const menu = useSelector((state) => state?.menu?.items);

  const SubMenu = useMemo(() => {
    return menu?.map((item) => item?.menus?.flat()).flat() || [];
  }, [menu]);

  const resultHRMS = useMemo(
    () => findNavByName(SubMenu, 'HRMS', 'All Masters'),
    [SubMenu]
  );
  const resultPresales = useMemo(
    () => findNavByName(SubMenu, 'PreSales', 'All master'),
    [SubMenu]
  );
  const resultProcurement = useMemo(
    () => findNavByName(SubMenu, 'Procurement', 'All Masters'),
    [SubMenu]
  );
  const resultCafm = useMemo(
    () => findNavByName(SubMenu, 'CAFM', 'All master'),
    [SubMenu]
  );
  const currentPath = location.pathname;
  const index =
    type === 'presales'
      ? resultPresales?.findIndex((item) => item.url === currentPath)
      : type === 'hrms'
        ? resultHRMS?.findIndex((item) => item.url === currentPath)
        : type === 'procurement'
          ? resultProcurement?.findIndex((item) => item.url === currentPath)
          : resultCafm?.findIndex((item) => item.url === currentPath);

  const menuPermission =
    type === 'presales'
      ? resultPresales[index]?.permissionDtos
      : type === 'hrms'
        ? resultHRMS[index]?.permissionDtos
        : type === 'procurement'
          ? resultProcurement[index]?.permissionDtos
          : resultCafm[index]?.permissionDtos;

  const permissions = usePermissions(menuPermission);

  const ActionData = [
    {
      name: t('view'),
      icon: Icons.view,
      onClick: (props) => handleViewClickOpen(props),
      hidden: !permissions.view,
    },
    {
      name: t('edit'),
      icon: Icons.edit,
      onClick: (props) => handleEditClick(props),
      hidden: !permissions.edit,
    },
    {
      name: t('delete'),
      icon: Icons.delete,
      onClick: (props) => handleDeleteClick(props),
      danger: true,
      hidden: !permissions.delete,
    },
  ];

  return (
    <DashboardLayout
      title='Regions'
      actionButtons={
        <>
          <PageHeader counters={counters} />
        </>
      }
      hasSubmenu
      menu={
        type === 'presales'
          ? resultPresales
          : type === 'procurement'
            ? resultProcurement
            : type === 'cafm'
              ? resultCafm
              : resultHRMS
      }
    >
      <GlassCard className='h-full'>
        <Box className='h-full'>
          <DataTable
            rows={rows}
            columns={columns}
            loading={isLoading}
            getRowClassName={(params) =>
              `${params.row.status === 0 && 'bg-red-50'}`
            }
            sortingMode='server'
            paginationMode='server'
            onPaginationModelChange={(model) =>
              handlePaginationModelChange(model)
            }
            onSortModelChange={(model) => handleSortModelChange(model)}
            page={pageNumber - 1}
            pageSize={pageSize}
            rowCount={tableRecordCounts?.total}
            fetchData={fetchData}
            slots={{
              toolbar: () => (
                <Box
                  className='p-2 w-full flex justify-between items-center'
                  sx={{
                    borderBottom: '1px solid',
                    borderColor: 'border.main',
                  }}
                >
                  <Box sx={{ maxWidth: '250px' }}>
                    <TableSearch
                      placeholder='Search'
                      fullWidth
                      setSearchKeyword={setSearchKeyword}
                      searchValue={searchKeyword}
                    />
                  </Box>
                  <Box className='flex items-center gap-4 justify-end'>
                    {permissions.create && (
                      <CreateButton
                        name='Create'
                        onClick={handleCreateClickOpen}
                      />
                    )}
                    <DataTableToolbarButtons
                      disableColumn
                      handleExcelExport={downloadRegions}
                    />
                  </Box>
                </Box>
              ),
            }}
          />
        </Box>
      </GlassCard>

      {/* create  */}

      <MuiDialogOne
        title={t('create')}
        open={openCreate}
        onClose={handleCreateClose}
      >
        <form onSubmit={handleSubmit(handleSubmitRegion)}>
          <Grid container spacing={3}>
            <Grid item md={12}>
              <FmTextField
                name='regionName'
                control={control}
                label='Region Name'
                rules={{
                  required: 'Region Name is required',
                }}
                required
              />
            </Grid>

            <Grid item md={12}>
              <FmAutoComplete
                name='countryId'
                control={control}
                options={countries}
                displayField='countryName'
                optionFields={['countryName']}
                onChange={(v) => {
                  setCountryId(v);
                }}
                valueKey='countryId'
                label='Country Name'
                rules={{
                  required: 'Country Name is required',
                }}
                required
              />
            </Grid>

            <Grid item md={12}>
              <FmSearchableSelect
                name='emirateId'
                control={control}
                apiUrl='emirates'
                valueField='emirateId'
                queryparam='countryId'
                queryparamValue={countryId}
                labelField={['emirateName', 'emirateCode']}
                showField={['emirateName', 'emirateCode']}
                label='Emirates'
                required
              />
            </Grid>

            <Grid item md={12}>
              <ActionButtons
                onSubmit={handleSubmit(handleSubmitRegion)}
                onCancel={handleCreateClose}
                onReset={reset}
                submitLoading={createLoading}
                cancelLoading={false}
              />
            </Grid>
          </Grid>
        </form>
      </MuiDialogOne>

      {/* create */}

      {/* view */}
      <MuiDialogOne
        title='View Details'
        open={openView}
        onClose={handleViewClose}
      >
        {loading ? (
          <CommonLoader />
        ) : (
          <Grid container spacing={3}>
            <Grid item md={6}>
              <ViewField label='Region Code' title={viewData?.regionCode} />
            </Grid>
            <Grid item md={6}>
              <ViewField label='Region Name' title={viewData?.regionName} />
            </Grid>
            <Grid item md={6}>
              <ViewField label='Country Code' title={viewData?.countryCode} />
            </Grid>
            <Grid item md={6}>
              <ViewField label='Country Name' title={viewData?.countryName} />
            </Grid>
            <Grid item md={6}>
              <ViewField label='Emirate Code' title={viewData?.emirateCode} />
            </Grid>
            <Grid item md={6}>
              <ViewField label='Emirate Name' title={viewData?.emirateName} />
            </Grid>
            <Grid item md={6}>
              <ViewField label={t('status')} title={viewData?.statusName} />
            </Grid>
          </Grid>
        )}
      </MuiDialogOne>

      {/* edit */}

      <MuiDialogOne
        title={t('update')}
        open={editModal}
        onClose={() => setEditModal(false)}
      >
        {loading ? (
          <CommonLoader />
        ) : (
          <form onSubmit={handleSubmit(handleEditRegion)}>
            <Grid container spacing={3}>
              <Grid item md={12}>
                <FmTextField
                  name='regionName'
                  control={control}
                  label='Region Name'
                  rules={{
                    required: 'Region Name is required',
                  }}
                  required
                />
              </Grid>
              <Grid item md={12}>
                <FmAutoComplete
                  name='countryId'
                  control={control}
                  options={countries}
                  label='Countries'
                  onChange={(v) => {
                    setCountryId(v);
                  }}
                  displayField='countryName'
                  optionFields={['countryName']}
                  valueKey='countryId'
                  required
                />
              </Grid>
              <Grid item md={12}>
                <FmSearchableSelect
                  name='emirateId'
                  control={control}
                  apiUrl='emirates'
                  valueField='emirateId'
                  queryparam='countryId'
                  queryparamValue={countryId}
                  labelField={['emirateName', 'emirateCode']}
                  showField={['emirateName', 'emirateCode']}
                  label='Emirates'
                  required
                  defaultValue={{
                    emirateId: editData?.emirateId,
                    emirateName: editData?.emirateName,
                  }}
                />
              </Grid>

              <Grid item md={12}>
                <FmAutoComplete
                  name='status'
                  control={control}
                  options={statusOptions}
                  label='Status'
                  displayField='statusName'
                  optionFields={['statusName']}
                  valueKey='statusId'
                />
              </Grid>
              <Grid item md={12}>
                <ActionButtons
                  onSubmit={handleSubmit(handleEditRegion)}
                  onCancel={() => setEditModal(false)}
                  onReset={reset}
                  submitLoading={editLoading}
                  cancelLoading={false}
                  submitText='Update'
                />
              </Grid>
            </Grid>
          </form>
        )}
      </MuiDialogOne>
    </DashboardLayout>
  );
};

export default RegionMasters;
