import React from 'react';
import currency from 'currency.js';

//  currency formatters and symbol positions
const currencyConfig = {
  INR: {
    symbol: '₹',
    prefix: 'Rs. ',
    precision: 2,
    separator: ',',
    decimal: '.',
    symbolPosition: 'before',
  },
  AED: {
    symbol: 'د.إ',
    prefix: 'AED ',
    precision: 2,
    separator: ',',
    decimal: '.',
    symbolPosition: 'before',
  },
};

const CurrencyView = ({ value, currencyType = 'AED', color = '' }) => {
  const config = currencyConfig[currencyType] || currencyConfig['INR'];
  if (value === null || value === undefined || value === '' || value === 0)
    return <span className='font-semibold'>{'-'}</span>;

  // Format the currency value
  const formattedValue = currency(value, {
    symbol: '',
    precision: config.precision,
    separator: config.separator,
    decimal: config.decimal,
  }).format();

  // Correctly position the currency prefix
  const displayValue =
    config.symbolPosition === 'after'
      ? `${formattedValue} ${config.prefix || ''}`
      : `${config.prefix}${formattedValue}`;

  return <span className={`font-semibold ${color || ''}`}>{displayValue}</span>;
};

export default CurrencyView;
