import React, { useState } from 'react';
import Icons from '../../../../utils/Icon.jsx';
import { Box, Button } from '@mui/material';
import { useNavigate, useLocation } from 'react-router-dom';
import TableActionButtons from '../../../../components/_form/TableActionButtons.jsx';
import { Controller, useForm } from 'react-hook-form';
import useLoader from '../../../../components/helpers/UseLoader.tsx';
import Crud_Service from '../../../../apis/CrudService.jsx';
import { toast } from 'react-toastify';
import FmMRTDataTable from '../../../../components/FmMRTDataTable.tsx';
import GlassCard from '../../../../components/small/GlassCard.tsx';
import FmSearchableSelect from '../../../../components/_mui/FmSearchableSelect.tsx';
import MuiDatePickerOne from '../../../../components/_mui/MuiDatePickerOne.tsx';
import dayjs from 'dayjs';
import { DatePicker } from '@mui/x-date-pickers';

const BoldBox = ({ title, primary }) => {
  return (
    <Box className='font-semibold' sx={{ color: primary && 'primary.main' }}>
      {title}
    </Box>
  );
};

const CreateCheckOutFormList = ({
  rows,
  searchKeyword,
  setSearchKeyword,
  isLoading,
  pagination,
  sorting,
  tableRecordCounts,
  setPagination,
  setSorting,
  columnFilters,
  globalFilter,
  setColumnFilters,
  setGlobalFilter,
  fetchData,
}) => {
  const [editingRowId, setEditingRowId] = useState(null);
  const [editingRowData, setEditingRowData] = useState(null);
  const crud = new Crud_Service();
  const navigate = useNavigate();
  const location = useLocation();
  const { handleSubmit, control } = useForm();
  const { startEditLoading, stopEditLoading } = useLoader();

  const handleCancel = () => {
    setEditingRowId(null);
    setEditingRowData(null);
  };
  const handleView = (id) => {
    navigate(`${location.pathname}/view/${id}`);
  };
  const handleEdit = (row) => {
    setEditingRowId(row?.checkOutFormId);
    setEditingRowData({ ...row });
  };

  const handleUpdateCheckOut = async (values) => {
    const combinedData = {
      ...values,
      checkOutDate: dayjs(values?.checkOutDate).format('YYYY-MM-DD'),
      clientId: values?.clientId || editingRowData?.clientId,
      companyId: values?.companyId || editingRowData?.companyId,
      projectId: values?.projectId || editingRowData?.projectId,
      contractId: values?.contractId || editingRowData?.contractId,
      workOrderId: values?.workOrderId || editingRowData?.workOrderId,
      inchargeId: values?.inchargeId || editingRowData?.inchargeId,
      status: 2,
    };
    startEditLoading();
    await crud.update(
      'checkoutforms',
      editingRowId,
      combinedData,
      (err, res) => {
        if (res?.status === 200) {
          stopEditLoading();
          fetchData();
          toast.success('Item Updated Successfully');
          setEditingRowData(null);
          setEditingRowId(null);
        } else {
          stopEditLoading();
        }
      }
    );
  };

  const handleDeleteEvent = async (view) => {
    await crud.remove('checkoutforms', view?.checkOutFormId, (err, res) => {
      if (res?.status === 204) {
        fetchData();
        toast.success('Item Deleted Successfully');
      } else {
      }
    });
  };

  const ActionData = [
    {
      name: 'Edit',
      icon: Icons.edit,
      onClick: (props) => {
        navigate(`${location.pathname}/view/${props?.checkOutFormId}`);
      },
    },
    // {
    //   name: 'Edit',
    //   icon: Icons.edit,
    //   onClick: (props) => {
    //     handleEdit(props);
    //   },
    // },
    {
      name: 'Delete',
      icon: Icons.delete,
      onClick: (props) => handleDeleteEvent(props),
      danger: true,
    },
  ];

  const columns = [
    {
      header: 'Actions',
      size: 60,
      Cell: ({ row }) => (
        <Box className='flex gap-2'>
          {editingRowId === row?.original?.checkOutFormId ? (
            <>
              <Button onClick={handleSubmit(handleUpdateCheckOut)}>Save</Button>
              <Button onClick={handleCancel}>Cancel</Button>
            </>
          ) : (
            <TableActionButtons
              Actions={ActionData?.map((action) => ({
                ...action,
                onClick: () => action.onClick(row?.original),
              }))}
            />
          )}
        </Box>
      ),
    },
    {
      accessorKey: 'checkOutDate',
      header: 'Checkout Date',
      enableEditing: true,
      Cell: ({ row }) => {
        return editingRowId === row?.original?.checkOutFormId ? (
          <Controller
            name='checkOutDate'
            control={control}
            defaultValue={editingRowData?.checkOutDate}
            render={({ field }) => (
              <DatePicker
                label='Checkout Date'
                value={field?.value ? dayjs(field?.value) : null}
                format='DD/MM/YYYY'
                onChange={field?.onChange}
              />
            )}
          />
        ) : row?.original?.checkOutDate ? (
          dayjs(row?.original?.checkOutDate).format('DD-MM-YYYY')
        ) : (
          ''
        );
      },
    },
    {
      accessorKey: 'inchargeId',
      header: 'In Charge Name',
      enableEditing: true,
      Cell: ({ cell }) => {
        const checkOut: any = rows?.find(
          (item) => item?.checkOutFormId === cell?.row?.original?.checkOutFormId
        );
        return editingRowId === cell?.row?.original?.checkOutFormId ? (
          <FmSearchableSelect
            name='loginUserId'
            control={control}
            apiUrl='loginusers'
            valueField='loginUserId'
            headerField={['In Charge Name']}
            defaultValue={{
              loginUserId: editingRowData?.loginUserId,
              loginUserName: editingRowData?.inchargeName,
            }}
            labelField={['loginUserName']}
            showField={['loginUserName']}
            label='In Charge Name'
            required
          />
        ) : (
          checkOut?.inchargeName || ''
        );
      },
    },
    {
      accessorKey: 'contractCode',
      header: 'Contract Code',
      enableEditing: true,
      Cell: ({ cell }) => {
        const checkOut: any = rows?.find(
          (item) => item?.checkOutFormId === cell?.row?.original?.checkOutFormId
        );
        return editingRowId === cell?.row?.original?.checkOutFormId ? (
          <FmSearchableSelect
            name='contractId'
            control={control}
            apiUrl='contracts'
            valueField='contractId'
            headerField={['Contract Code']}
            defaultValue={{
              contractId: editingRowData?.contractId,
              contractCode: editingRowData?.contractCode,
            }}
            labelField={['contractCode']}
            showField={['contractCode']}
            label='Contract'
            required
          />
        ) : (
          checkOut?.contractCode || ''
        );
      },
    },
    {
      accessorKey: 'companyCode',
      header: 'Company Code',
      enableEditing: true,
      Cell: ({ cell }) => {
        const checkOut: any = rows?.find(
          (item) => item?.checkOutFormId === cell?.row?.original?.checkOutFormId
        );
        return editingRowId === cell?.row?.original?.checkOutFormId ? (
          <FmSearchableSelect
            name='companyId'
            control={control}
            apiUrl='companies'
            valueField='companyId'
            headerField={['Code']}
            defaultValue={{
              companyId: editingRowData?.companyId,
              companyCode: editingRowData?.companyCode,
            }}
            labelField={['companyCode']}
            showField={['companyCode']}
            label='Company'
            required
          />
        ) : (
          checkOut?.companyCode || ''
        );
      },
    },

    {
      accessorKey: 'clientId',
      header: 'Client Name',
      enableEditing: true,
      Cell: ({ cell }) => {
        const checkOut: any = rows?.find(
          (item) => item?.checkOutFormId === cell?.row?.original?.checkOutFormId
        );
        return editingRowId === cell?.row?.original?.checkOutFormId ? (
          <FmSearchableSelect
            name='clientId'
            control={control}
            apiUrl='clients'
            valueField='clientId'
            headerField={['Client Name']}
            defaultValue={{
              clientId: editingRowData?.clientId || '',
              clientName: editingRowData?.clientName || '',
            }}
            labelField={['clientName']}
            showField={['clientName']}
            label='Client'
            required
          />
        ) : (
          checkOut?.clientName || ''
        );
      },
    },
    {
      accessorKey: 'projectCode',
      header: 'Project',
      enableEditing: true,
      Cell: ({ cell }) => {
        const checkOut: any = rows?.find(
          (item) => item?.checkOutFormId === cell?.row?.original?.checkOutFormId
        );
        return editingRowId === cell?.row?.original?.checkOutFormId ? (
          <FmSearchableSelect
            name='projectId'
            control={control}
            apiUrl='projects'
            valueField='projectId'
            headerField={['Project Code']}
            defaultValue={{
              projectId: editingRowData?.projectId,
              projectCode: editingRowData?.projectCode,
            }}
            labelField={['projectCode']}
            showField={['projectCode']}
            label='Project Code'
            required
          />
        ) : (
          checkOut?.projectCode || ''
        );
      },
    },
    {
      accessorKey: 'workOrderId',
      header: 'Work Order',
      enableEditing: true,
      Cell: ({ cell }) => {
        const checkOut: any = rows?.find(
          (item) => item?.checkOutFormId === cell?.row?.original?.checkOutFormId
        );
        return editingRowId === cell?.row?.original?.checkOutFormId ? (
          <FmSearchableSelect
            name='workOrderId'
            control={control}
            apiUrl='workorders'
            valueField='workOrderId'
            headerField={['User Name']}
            defaultValue={{
              workOrderId: editingRowData?.workOrderId,
              workOrderName: editingRowData?.workOrderName,
            }}
            labelField={['workOrderName', 'workOrderCode']}
            showField={['workOrderName', 'workOrderCode']}
            label='work Order'
            required
          />
        ) : (
          checkOut?.workOrderName || ''
        );
      },
    },
  ];

  return (
    <>
      <GlassCard className='my-4'>
        <FmMRTDataTable
          columns={columns}
          enableRowSelection={false}
          rows={rows}
          rowCount={tableRecordCounts}
          editingRow={editingRowId}
          pagination={pagination}
          sorting={sorting}
          fetchData={fetchData}
          columnFilters={columnFilters}
          globalFilter={globalFilter}
          setPagination={setPagination}
          setSorting={setSorting}
          setColumnFilters={setColumnFilters}
          setGlobalFilter={setGlobalFilter}
          isLoading={isLoading}
          setSearchKeyword={setSearchKeyword}
          searchKeyword={searchKeyword}
        />
      </GlassCard>
    </>
  );
};

export default CreateCheckOutFormList;
