import { Box } from '@mui/material';
import React, { useEffect, useMemo } from 'react';
import { useNavigate } from 'react-router-dom';
import { CreateButton } from '../../../../components/small/Buttons.jsx';
import { findNavByName } from '../../../../utils/navUtils.ts';
import DashboardLayout from './../../../../components/DashboardLayout.tsx';
import { useSelector } from 'react-redux';
import CreateSalesOrderList from './CreateSalesOrderList.tsx';

const CreateSalesOrder = (props) => {
  const { type } = props;
  const navigate = useNavigate();

  const menu = useSelector((state) => state?.menu?.items);

  const SubMenu = useMemo(() => {
    return menu?.map((item) => item?.menus?.flat()).flat() || [];
  }, [menu]);

  const result = useMemo(
    () => findNavByName(SubMenu, 'Financial', 'Accounts Receivable'),
    [SubMenu]
  );

  const getTitleByType = (type) => {
    switch (type) {
      case 2:
      case 1:
      case 4:
        return 'Sales Orders';
      case 3:
        return 'Sales Returns';
      default:
        return 'Sales Quotation';
    }
  };

  // Set the document title whenever `type` changes
  useEffect(() => {
    document.title = getTitleByType(type);
  }, [type]);

  return (
    <DashboardLayout
      title={getTitleByType(type)}
      hasSubmenu={type !== 4}
      menu={result}
      actionButtons={
        <Box className='flex flex-row items-center whitespace-nowrap gap-3'>
          <CreateButton name='Create' onClick={() => navigate('create')} />
        </Box>
      }
    >
      <CreateSalesOrderList type={type} getData={false} />
    </DashboardLayout>
  );
};

export default CreateSalesOrder;
