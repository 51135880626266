import { yupResolver } from '@hookform/resolvers/yup';
import { Icon } from '@iconify/react';
import { Box, Button } from '@mui/material';
import React, { useEffect, useRef, useState } from 'react';
import { useForm } from 'react-hook-form';
import { toast } from 'react-toastify';
import Crud_Service from '../../../../../apis/CrudService.jsx';
import TableActionButtons from '../../../../../components/_form/TableActionButtons.jsx';
import FmSearchableSelect from '../../../../../components/_mui/FmSearchableSelect.tsx';
import FmTextField from '../../../../../components/_mui/FmTextField.tsx';
import FmMRTDataTable from '../../../../../components/FmMRTDataTable.tsx';
import useTableLogic from '../../../../../components/helpers/MRTUseTableLogic.tsx';
import useLoader from '../../../../../components/helpers/UseLoader.tsx';
import GlassCard from '../../../../../components/small/GlassCard.tsx';
import { FTItemsSchema } from '../../../../../utils/CommonvalidationSchemas.tsx';
import MuiDialogOne from '../../../../../components/_mui/MuiDialogOne.jsx';
import { useParams } from 'react-router-dom';
import FmFileInput from '../../../../../components/_mui/FmFileInput.tsx';
import { useHotkeys } from 'react-hotkeys-hook';
import useCommonFetchApi from '../../../../../components/helpers/useCommonFetchApi.tsx';
import { ViewField } from '../../../../../components/_form/FormElements.jsx';
import { FileType } from '../../../../../constants/FileType.ts';

const ListViewPettyCashItem = (props) => {
  const { pettyCashTransactionId } = props;
  const [editingRowId, setEditingRowId] = useState(null);
  const [editingRowData, setEditingRowData] = useState(null);
  const id = useParams()?.id;
  const [openUpload, setOpenUpload] = useState(false);
  const [filesData, setFilesData] = useState(null);
  const crud = new Crud_Service();
  const firstColumnInputRef = useRef(null);
  const [loader, setLoader] = useState(false);
  const [editingRowIndex, setEditingRowIndex] = useState(null);
  const [stateError, setStateError] = useState(false);
  const { data: salesorders } = useCommonFetchApi('salesorders');
  const [uploadLoading, setUploadLoading] = useState(false);
  const [uploadedfileData, setUploadedfileData] = useState(null);
  const [rowState, setRowState] = useState({
    editingRowId: null,
    newRowId: null,
    isCreatingRow: false,
  });
  const { createLoading, startCreateLoading, stopCreateLoading } = useLoader();
  const {
    control,
    setValue,
    reset,
    getValues,
    watch,
    formState: { isValid },
  } = useForm({
    resolver: yupResolver(FTItemsSchema),
    mode: 'all',
  });
  const selectedSalesId = watch('salesOrderId');
  const watchVat = watch('vat');
  const watchAmount = watch('amount');

  const {
    rows,
    setRows,
    isLoading,
    pagination,
    sorting,
    columnFilters,
    globalFilter,
    tableRecordCounts,
    setPagination,
    setSorting,
    setColumnFilters,
    setGlobalFilter,
    fetchData,
    setSearchKeyword,
    searchKeyword,
  } = useTableLogic(
    'pettycashtransactionitems',
    ['pettyCashTransactionId'],
    [id]
  );

  const handleFileUpload = async (e) => {
    const file = e.target.files[0];
    setUploadLoading(true);
    if (file) {
      setUploadedfileData(null);
      const fileFormData = new FormData();
      fileFormData.append('file', file);
      fileFormData.append('fileTypeId', FileType.PettyCash);

      await crud.create(`/files/upload`, fileFormData, (err, res) => {
        if (res?.status === 200) {
          setUploadLoading(false);
          setUploadedfileData(res?.data);

        } else {
          setUploadLoading(false);
        }
      });
    }
  };

  useEffect(() => {
    const selectedEmpDetail = salesorders?.find(
      (sales) => sales?.salesOrderId === selectedSalesId
    );
    if (selectedEmpDetail) {
      setValue('projectId', selectedEmpDetail.projectId);
      setValue('projectName', selectedEmpDetail.projectName);
      setValue('contractId', selectedEmpDetail.contractId);
      setValue('contractName', selectedEmpDetail.contractName);
    }
  }, [selectedSalesId, setValue, salesorders]);

  useEffect(() => {
    if (watchAmount && watchVat) {
      const amount = Number(watchAmount);
      const vatPercentage = Number(watchVat);
      const vatAmount = (amount * vatPercentage) / 100;
      const total = amount + vatAmount;
      setValue('totalAmount', total.toFixed(2));
    } else if (watchAmount) {
      setValue('totalAmount', Number(watchAmount).toFixed(2));
    }
  }, [watchVat, watchAmount]);

  const handleAddNewRow = () => {
    const newId = 'new_row_' + Date.now();
    reset();
    setEditingRowData(null);
    setEditingRowId(null);
    setUploadedfileData(null);
    setRowState({
      newRowId: newId,
      isCreatingRow: true,
    });
    setEditingRowIndex(
      editingRowIndex === rows.length ? editingRowIndex + 1 : rows.length
    );
    setRows((prevRows) => [
      ...prevRows,
      {
        pettyCashTransactionItemId: newId,
        pettyCashTransactionId: pettyCashTransactionId,
        salesOrderId: '',
        costCenterId: '',
        employeeId: '',
        divisionId: '',
        inchargeId: '',
        chartOfAccountId: '',
        amount: '',
        taxId: '',
        vat: '',
        totalAmount: '',
        fileId: null,
        remarks: '',
        isEditable: true,
        isNew: true,
      },
    ]);
    // reset();
  };

  const handleBlurAndFetch = async () => {
    setLoader(true);

    const values = getValues();

    const updateCombinedData = {
      ...values,
      pettyCashTransactionId: pettyCashTransactionId,
      fileId: uploadedfileData?.fileId || values?.fileId,
      status: 2,
    };

    await crud.update(
      'pettycashtransactionitems',
      editingRowId,
      updateCombinedData,
      (err, res) => {
        if (res?.status === 200) {
          const totalRows = rows.length;
          if (editingRowIndex <= totalRows && rows.some((row) => row.isNew)) {
            const updatedRow = res?.data;
            const updatedRows = [...rows];
            updatedRows[editingRowIndex] = {
              ...updatedRow,
              isEditable: true,
              isNew: true,
            };
            setRows(updatedRows);
            handleAddNewRow();
          } else {
            const updatedRow = res?.data;
            const updatedRows = [...rows];
            updatedRows[editingRowIndex] = {
              ...updatedRow,
              isEditable: false,
              isNew: false,
            };

            setRows(updatedRows);
            setStateError(false);
            setLoader(false);
          }
        } else {
          setStateError(true);
        }
      }
    );
  };

  const handleCancel = (row) => {
    if (row?.original?.pettyCashTransactionItemId === rowState?.newRowId) {
      setRows((prevRows) => {
        if (prevRows?.length > 0) {
          return prevRows?.slice(0, -1);
        }
        return prevRows;
      });

      setRowState((prevState) => ({
        ...prevState,
        newRowId: null,
        isCreatingRow: false,
      }));
    } else {
      setEditingRowId(null);
    }
    setUploadedfileData(null);
    reset();
  };

  const handleEdit = (row) => {
    reset();
    setUploadedfileData(null);
    if (rowState?.newRowId) {
      setRows((prevRows) => {
        if (prevRows?.length > 0) {
          return prevRows?.slice(0, -1);
        }
        return prevRows;
      });
      setRowState((prevState) => ({
        ...prevState,
        newRowId: null,
        isCreatingRow: false,
      }));
    }
    setEditingRowId(row?.pettyCashTransactionItemId);
    Object.entries({
      ...row,
    }).forEach(([key, value]) => {
      setValue(key, value);
    });
    setEditingRowData({ ...row });
  };

  useHotkeys(
    'Enter',
    () => handleAddNewRow(),
    {
      enableOnTags: ['INPUT', 'TEXTAREA'],
    },
    [editingRowIndex, rows, stateError, loader]
  );

  useHotkeys(
    'Tab',
    (event) => {
      if (event.key !== 'Tab') return;
      if (stateError) {
        event.preventDefault();
        return;
      }
      const totalRows = rows.length;
      const nextRowIndex = editingRowIndex + 1;

      if (
        nextRowIndex < totalRows &&
        stateError === false &&
        loader === false
      ) {
        handleEdit(rows[nextRowIndex]);
        setEditingRowIndex(nextRowIndex);
      }
    },
    [editingRowIndex, rows, stateError, loader, isValid]
  );

  useEffect(() => {
    if (editingRowIndex !== null && firstColumnInputRef.current && !isLoading) {
      firstColumnInputRef.current.focus();
    }
  }, [editingRowIndex, isLoading]);

  useEffect(() => {
    const selectedEmpDetail = salesorders.find(
      (sales) => sales?.salesOrderId === selectedSalesId
    );
    if (selectedEmpDetail) {
      setValue('contractId', selectedEmpDetail.contractId);
      setValue('workOrderId', selectedEmpDetail.workOrderId);
      setValue('divisionId', selectedEmpDetail.divisionId);
      setValue('clientId', selectedEmpDetail.clientId);
      setValue('inchargeId', selectedEmpDetail.projectInChargeId);
    }
  }, [selectedSalesId, setValue]);

  useEffect(() => {
    if (editingRowData) {
      Object.entries(editingRowData).forEach(([key, value]) => {
        setValue(key, value);
      });
    }
  }, [editingRowData, setValue]);
  const previousValues = useRef(getValues());

  const handleCancelAndAddNewRow = () => {
    const newId = 'new_row_' + Date.now();
    reset();
    setEditingRowData(null);
    setEditingRowId(null);
    setUploadedfileData(null);

    setRowState({
      newRowId: newId,
      isCreatingRow: true,
    });
    setEditingRowIndex(
      editingRowIndex === rows?.length ? editingRowIndex + 1 : rows?.length
    );
    setRows((prevRows) => [
      ...prevRows,
      {
        pettyCashTransactionItemId: newId,
        pettyCashTransactionId: pettyCashTransactionId,
        salesOrderId: '',
        costCenterId: '',
        employeeId: '',
        divisionId: '',
        inchargeId: '',
        chartOfAccountId: '',
        amount: '',
        taxId: '',
        vat: '',
        totalAmount: '',
        fileId: null,
        remarks: '',
        isEditable: true,
        isNew: true,
      },
    ]);
    reset();
  };

  const handleBlur = () => {
    const currentValues = getValues();
    const amount = getValues('amount');
    if (!amount) {
      return;
    }
    const hasChanges = Object.keys(currentValues).some(
      (key) => currentValues[key] !== previousValues.current[key]
    );

    if ((hasChanges && isValid) || (hasChanges && editingRowId)) {
      apiCallAsync(currentValues);
      previousValues.current = currentValues;
    }
  };

  const apiCallAsync = async (values) => {
    setLoader(true);

    if (!values.amount) {
      setLoader(false);
      return;
    }

    let fileIdToUse = uploadedfileData?.fileId;
    if (editingRowId === null) {
      const convertedValues = {
        ...values,
        amount: Number(values.amount),
        pettyCashTransactionId: pettyCashTransactionId,
        fileId: fileIdToUse,
      };

      try {
        await crud.create(
          'pettycashtransactionitems',
          convertedValues,
          (err, res) => {
            if (res?.status === 201) {
              setEditingRowId(res?.data?.pettyCashTransactionItemId);
              setStateError(false);
              setEditingRowData({});
              setUploadedfileData(null);
            } else {
              setStateError(true);
            }
            setLoader(false);
          }
        );
      } catch (error) {
        setStateError(true);
        setLoader(false);
      }
    } else {
      const updatedCombinedData = {
        ...values,
        amount: Number(values.amount),
        pettyCashTransactionId: pettyCashTransactionId,
        status: 2,
        fileId: fileIdToUse || values?.fileId,
      };

      try {
        await crud.update(
          'pettycashtransactionitems',
          editingRowId,
          updatedCombinedData,
          (err, res) => {
            if (res?.status === 200) {
              const updatedRow = res?.data;
              const updatedRows = [...rows];
              updatedRows[editingRowIndex] = {
                ...updatedRow,
                isEditable: rows.some((row) => row.isNew),
                isNew: rows.some((row) => row.isNew),
              };
              setRows(updatedRows);
              setStateError(false);
              setUploadedfileData(null);
            } else {
              setStateError(true);
            }
            setLoader(false);
          }
        );
      } catch (error) {
        setStateError(true);
        setLoader(false);
      }
    }
  };

  const handleDelete = async (props) => {
    await crud.remove(
      'pettycashtransactionitems',
      props?.pettyCashTransactionItemId,
      (__err, res) => {
        if (res?.status === 204) {
          toast.success('Petty Cash Item Deleted Successfully');
          fetchData();
        } else {
        }
      }
    );
  };

  const ActionData = [
    {
      name: 'Delete',
      icon: <Icon icon='solar:trash-bin-minimalistic-broken' />,
      onClick: (props) => handleDelete(props),
    },
  ];
  const columns = [
    {
      accessorKey: 'salesOrderId',
      header: 'Sales Order Code',
      enableEditing: true,
      Cell: ({ row }) => {
        const isNewRow =
          row?.original?.pettyCashTransactionItemId === rowState?.newRowId;
        const isEditing =
          editingRowId === row?.original?.pettyCashTransactionItemId ||
          isNewRow;
        return isEditing ? (
          <FmSearchableSelect
            name='salesOrderId'
            control={control}
            apiUrl='salesorders'
            valueField='salesOrderId'
            labelField={['salesOrderCode', 'salesOrderName']}
            showField={['salesOrderCode', 'salesOrderName']}
            label='Sales Order'
            inputRef={firstColumnInputRef}

            onBlur={handleBlur}
            defaultValue={{
              salesOrderId: editingRowData?.salesOrderId,
              salesOrderName: editingRowData?.salesOrderName,
            }}
          />
        ) : (
          row?.original?.salesOrderCode || ''
        );
      },
    },

    {
      accessorKey: 'contractName',
      header: 'Contract',
      enableEditing: true,
      Cell: ({ row }) => {
        const isNewRow =
          row?.original?.pettyCashTransactionItemId === rowState?.newRowId;
        const isEditing =
          editingRowId === row?.original?.pettyCashTransactionItemId ||
          isNewRow;
        const selectedEmpDetail = salesorders?.find(
          (sales) => sales?.salesOrderId === watch('salesOrderId')
        );

        return isEditing ? (
          <ViewField
            label=''
            title={selectedEmpDetail?.contractName}
          />
        ) : (
          watch('contractName') || row?.original?.contractName || ''
        );
      },
    },
    {
      accessorKey: 'projectName',
      header: 'Projects',
      enableEditing: true,
      Cell: ({ row }) => {
        const isNewRow =
          row?.original?.pettyCashTransactionItemId === rowState?.newRowId;
        const isEditing =
          editingRowId === row?.original?.pettyCashTransactionItemId ||
          isNewRow;
        const selectedEmpDetail = salesorders?.find(
          (sales) => sales?.salesOrderId === watch('salesOrderId')
        );

        return isEditing ? (
          <ViewField
            label=''
            title={selectedEmpDetail?.projectName}
          />
        ) : (
          watch('projectName') || row?.original?.projectName || ''
        );
      },
    },

    {
      accessorKey: 'costCenterId',
      header: 'Cost Center',
      enableEditing: true,
      Cell: ({ row }) => {
        const isNewRow =
          row?.original?.pettyCashTransactionItemId === rowState?.newRowId;
        const isEditing =
          editingRowId === row?.original?.pettyCashTransactionItemId ||
          isNewRow;
        return isEditing ? (
          <FmSearchableSelect
            name='costCenterId'
            control={control}
            apiUrl='costCenters'
            valueField='costCenterId'
            labelField={['costCenterCode', 'costCenterName']}
            showField={['costCenterCode', 'costCenterName']}
            label='Cost Center'
            onBlur={handleBlur}
            defaultValue={{
              costCenterName: editingRowData?.costCenterName,
              costCenterId: editingRowData?.costCenterId,
            }}
          />
        ) : (
          row?.original?.costCenterName || ''
        );
      },
    },

    {
      accessorKey: 'employeeId',
      header: 'Employee',
      enableEditing: true,
      Cell: ({ row }) => {
        const isNewRow =
          row?.original?.pettyCashTransactionItemId === rowState?.newRowId;
        const isEditing =
          editingRowId === row?.original?.pettyCashTransactionItemId ||
          isNewRow;

        return isEditing ? (
          <FmSearchableSelect
            name='employeeId'
            control={control}
            apiUrl='employees'
            valueField='employeeId'
            labelField={['employeeCode', 'fullName', 'emailId']}
            showField={['employeeCode', 'fullName', 'emailId']}
            headerField={['Code', 'Name', 'Email']}
            label='Employees'
            onBlur={handleBlur}
            defaultValue={{
              employeeId: editingRowData?.employeeId,
              firstName: editingRowData?.employeeName,
            }}
          />
        ) : (
          row?.original?.employeeName || ''
        );
      },
    },

    {
      accessorKey: 'divisionId',
      header: 'Division',
      enableEditing: true,
      Cell: ({ row }) => {
        const isNewRow =
          row?.original?.pettyCashTransactionItemId === rowState?.newRowId;
        const isEditing =
          editingRowId === row?.original?.pettyCashTransactionItemId ||
          isNewRow;

        return isEditing ? (
          <FmSearchableSelect
            name='divisionId'
            control={control}
            apiUrl='divisions'
            valueField='divisionId'
            labelField={['divisionCode', 'divisionName']}
            showField={['divisionCode', 'divisionName']}
            label='Division'
            onBlur={handleBlur}
            defaultValue={{
              divisionId: editingRowData?.divisionId,
              divisionName: editingRowData?.divisionName,
            }}
          />
        ) : (
          row?.original?.divisionName || ''
        );
      },
    },

    {
      accessorKey: 'inchargeId',
      header: 'In Charge',
      enableEditing: true,
      Cell: ({ row }) => {
        const isNewRow =
          row?.original?.pettyCashTransactionItemId === rowState?.newRowId;
        const isEditing =
          editingRowId === row?.original?.pettyCashTransactionItemId ||
          isNewRow;

        return isEditing ? (
          <FmSearchableSelect
            name='inchargeId'
            control={control}
            apiUrl='loginusers'
            valueField='loginUserId'
            headerField={['Name', 'Email']}
            labelField={['employeeName', 'employeeMail']}
            showField={['employeeName', 'employeeMail']}
            label='In Charge'
            onBlur={handleBlur}
            defaultValue={{
              loginUserId: editingRowData?.inchargeId,
              employeeName: editingRowData?.inchargeName,
              employeeMail: editingRowData?.inchargeMail,
            }}
          />
        ) : (
          row?.original?.inchargeName || ''
        );
      },
    },

    {
      accessorKey: 'chartOfAccountId',
      header: 'Accounts (IOA)',
      enableEditing: true,
      Cell: ({ row }) => {
        const isNewRow =
          row?.original?.pettyCashTransactionItemId === rowState?.newRowId;
        const isEditing =
          editingRowId === row?.original?.pettyCashTransactionItemId ||
          isNewRow;

        return isEditing ? (
          <FmSearchableSelect
            name='chartOfAccountId'
            control={control}
            apiUrl='chartofaccounts'
            valueField='chartOfAccountId'
            labelField={['chartOfAccountCode', 'chartOfAccountName']}
            showField={['chartOfAccountCode', 'chartOfAccountName']}
            label='Accounts (IOA)'
            onBlur={handleBlur}
            defaultValue={{
              chartOfAccountId: row?.original?.chartOfAccountId,
              chartOfAccountName: row?.original?.chartOfAccountName,
            }}
          />
        ) : (
          row?.original?.chartOfAccountName || ''
        );
      },
    },
    {
      accessorKey: 'taxId',
      header: 'Tax Code',
      enableEditing: true,
      size: 160,
      Cell: ({ row }) => {
        const isNewRow =
          row?.original?.pettyCashTransactionItemId === rowState?.newRowId;
        const isEditing =
          editingRowId === row?.original?.pettyCashTransactionItemId ||
          isNewRow;

        return isEditing ? (
          <FmSearchableSelect
            name='taxId'
            control={control}
            apiUrl='taxcodes'
            valueField='taxCodeId'
            labelField={['taxCodeCode', 'taxCodeName']}
            showField={['taxCodeCode', 'taxCodeName']}
            label='Tax Code'
            onBlur={handleBlur}
            defaultValue={{
              taxId: editingRowData?.taxId,
              taxCodeName: editingRowData?.taxCodeName,
            }}
          />
        ) : (
          row?.original?.taxCodeName || ''
        );
      },
    },

    {
      accessorKey: 'vat',
      header: 'VAT',
      size: 100,
      enableEditing: true,
      Cell: ({ row }) => {
        const isNewRow =
          row?.original?.pettyCashTransactionItemId === rowState?.newRowId;
        const isEditing =
          editingRowId === row?.original?.pettyCashTransactionItemId ||
          isNewRow;

        return isEditing ? (
          <FmTextField
            name='vat'
            control={control}
            label='VAT'
            type="number"
            onBlur={handleBlur}
          />
        ) : (
          row?.original?.vat ? `${row?.original?.vat}` : ''
        );
      },
    },

    {
      accessorKey: 'amount',
      header: 'Amount',
      enableEditing: true,
      Cell: ({ row }) => {
        const isNewRow =
          row?.original?.pettyCashTransactionItemId === rowState?.newRowId;
        const isEditing =
          editingRowId === row?.original?.pettyCashTransactionItemId ||
          isNewRow;

        return isEditing ? (
          <FmTextField
            name='amount'
            control={control}
            label='Amount'
            type="number"
            onBlur={handleBlur}
            required
            rules={{ required: 'Amount is required' }}
          />
        ) : (
          row?.original?.amount || ''
        );
      },
    },
    {
      accessorKey: 'totalAmount',
      header: 'Total',
      enableEditing: true,
      Cell: ({ row }) => {
        const isNewRow =
          row?.original?.pettyCashTransactionItemId === rowState?.newRowId;
        const isEditing =
          editingRowId === row?.original?.pettyCashTransactionItemId ||
          isNewRow;

        return isEditing ? (
          <FmTextField
            name='totalAmount'
            control={control}
            label='Total'
            disabled={true}
          />
        ) : (
          row?.original?.totalAmount || ''
        );
      },
    },

    {
      accessorKey: 'fileId',
      header: 'Upload',
      enableEditing: true,
      Cell: ({ row }) => {
        const isNewRow = row?.original?.pettyCashTransactionItemId === rowState?.newRowId;
        const isEditing = editingRowId === row?.original?.pettyCashTransactionItemId || isNewRow;

        return isEditing ? (
          <FmFileInput
            name="fileId"
            documentName={uploadedfileData?.originalFileName || row?.original?.fileName || ''}
            onChange={handleFileUpload}
            loading={uploadLoading}
          />
        ) : (
          row?.original?.fileName || ''
        );
      },
    },

    {
      accessorKey: 'remarks',
      header: 'Remarks',
      enableEditing: true,
      Cell: ({ row }) => {
        const isNewRow = row?.original?.pettyCashTransactionItemId === rowState?.newRowId;
        const isEditing =
          editingRowId === row?.original?.pettyCashTransactionItemId || isNewRow;
        return isEditing ? (
          <FmTextField
            name='remarks'
            control={control}
            multiline={true}
            rows={2}
            maxRows={3}
            onBlur={handleBlurAndFetch}
          />
        ) : (
          row?.original?.remarks
        );
      },
    },
    {
      header: 'Action',
      enableColumnPinning: true,
      enableEditing: false,
      enableSorting: false,
      size: 100,
      muiTableBodyCellProps: {
        className: 'sticky-pinning-right',
      },
      muiTableHeadCellProps: {
        className: 'sticky-pinning-right',
      },
      Cell: ({ row, table }) => {
        const isNewRow =
          row?.original?.pettyCashTransactionItemId === rowState?.newRowId;
        const isEditing =
          editingRowId === row?.original?.pettyCashTransactionItemId ||
          isNewRow;

        const isLastRow = row.index === table.getRowModel().rows.length - 1;

        return (
          <Box className='flex gap-2'>
            {isEditing ? (
              <>
                <Button
                  onClick={() => handleCancel(row)}
                  className='py-0.5 px-2'
                  variant='contained'
                  color='error'
                >
                  Close
                </Button>
                {isLastRow && rowState?.isCreatingRow === false && (
                  <Button
                    onFocus={() => handleCancelAndAddNewRow()}
                    hidden={true}
                  ></Button>
                )}
              </>
            ) : (
              <Box className='flex gap-2'>
                <TableActionButtons
                  Actions={ActionData?.map((action) => ({
                    ...action,
                    onClick: () => action.onClick(row?.original),
                  }))}
                />
                {isLastRow && (
                  <Button
                    variant='contained'
                    color='primary'
                    className='px-3 py-1'
                    onClick={() => {
                      setEditingRowIndex(row?.index + 1);
                      handleAddNewRow();
                    }}
                  >
                    Add
                  </Button>
                )}
              </Box>
            )}
          </Box>
        );
      },
    },
  ];

  const handleClose = () => {
    setOpenUpload(false);
    setFilesData(null);
  };
  const handleUploadSheet = (event) => {
    startCreateLoading();
    const file = event.target.files[0];
    if (file) {
      const fileFormData = new FormData();
      fileFormData.append('file', file);
      fileFormData.append('fileTypeId', 59);

      crud.create('files/upload', fileFormData, (_err, res) => {
        setFilesData(res?.data);
        if (res?.status === 200) {
          crud.create(
            `pettycashtransactionitems/${id}/process/${res.data?.fileId}`,
            '',
            (_err, res) => {
              if (res?.status === 200) {
                stopCreateLoading();
                toast.success('Receipt Items Uploaded Successfully');
                fetchData();
                setOpenUpload(false);
              } else {
                stopCreateLoading();
              }
            }
          );
        } else {
          setOpenUpload(false);
          stopCreateLoading();
        }
      });
    }
  };
  return (
    <>
      <GlassCard className='mt-4 h-[400px]'>
        <FmMRTDataTable
          rows={rows}
          columns={columns}
          onCreateRow={handleAddNewRow}
          isCreatingRow={rowState?.isCreatingRow}
          enableRowSelection={false}
          rowCount={tableRecordCounts}
          editingRow={editingRowId}
          pagination={pagination}
          fetchData={fetchData}
          sorting={sorting}
          columnFilters={columnFilters}
          globalFilter={globalFilter}
          setPagination={setPagination}
          setSorting={setSorting}
          setColumnFilters={setColumnFilters}
          setGlobalFilter={setGlobalFilter}
          isLoading={isLoading}
          setSearchKeyword={setSearchKeyword}
          searchKeyword={searchKeyword}
          handleRowDoubleClick={(row) => {
            handleEdit(row?.original);
            setEditingRowIndex(row?.index);
          }}
          showSerialNumber={true}
          muiTableContainerProps={{
            sx: {
              maxHeight: '100%',
              flex: 1,
            },
          }}
        />
        <MuiDialogOne
          title='Upload Sheet'
          open={openUpload}
          onClose={handleClose}
        >
          <FmFileInput
            onChange={handleUploadSheet}
            documentName={filesData?.originalFileName}
            loading={createLoading}
          />
        </MuiDialogOne>
      </GlassCard>
    </>
  );
};
export default ListViewPettyCashItem;
