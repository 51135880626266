import React from 'react';
import { Box, Button } from '@mui/material';

const EnquiryButtonShape = ({ name, icon, count, active, onClick }) => {
  return (
    <Box
      className={`flex gap-2 items-center relative`}
      sx={{
        bgcolor: active ? 'background.white' : 'none',
        padding: '4px 8px',
        borderRadius: '5px',
        fontWeight: active ? 700 : 500,
        color: active ? 'primary.main' : 'text.main',
        border: '1px solid',
        borderColor: active ? 'primary.main' : 'border.main',
        '&:hover': {
          bgcolor: 'background.light',
        },
      }}
      component={Button}
      type='button'
      onClick={onClick}
    >
      <Box className='text-xl'>{icon}</Box>
      <Box>{name}</Box>
      <Box className='count'>({count})</Box>
    </Box>
  );
};

export default EnquiryButtonShape;
