import React, { useEffect, useState } from 'react';
import { Box, Button } from '@mui/material';
import FmTextField from '../../../../components/_mui/FmTextField.tsx';
import { Controller, useForm } from 'react-hook-form';
import GlassCard from '../../../../components/small/GlassCard.tsx';
import FmFileInput from '../../../../components/_mui/FmFileInput.tsx';
import Crud_Service from '../../../../apis/CrudService.jsx';
import useTableLogic from '../../../../components/helpers/MRTUseTableLogic.tsx';
import { Icon } from '@iconify/react/dist/iconify.js';
import FmMRTDataTable from '../../../../components/FmMRTDataTable.tsx';
import TableActionButtons from '../../../../components/_form/TableActionButtons.jsx';
import { toast } from 'react-toastify';

const InternalSalesInvoiceChecklist = (props) => {
  const { contractItemId, salesOrderId, internalInvoiceId } = props;
  const [rowState, setRowState] = useState({
    editingRowId: null,
    newRowId: null,
    isCreatingRow: false,
  });
  const [editingRowId, setEditingRowId] = useState(null);
  const [editingRowData, setEditingRowData] = useState(null);
  const crud = new Crud_Service();
  const {
    rows,
    setRows,
    isLoading,
    pagination,
    sorting,
    columnFilters,
    globalFilter,
    tableRecordCounts,
    setPagination,
    fetchData,
    setSorting,
    setColumnFilters,
    setGlobalFilter,
    setSearchKeyword,
    searchKeyword,
  } = useTableLogic(
    `invoicechecklists`,
    ['contractId', 'internalInvoiceId'],
    [contractItemId, internalInvoiceId]
  );
  const {
    control,
    reset,
    setValue,
    formState: { isValid, errors },
    getValues,
    trigger,
    handleSubmit,
  } = useForm({});
  const [fileError, setFileError] = useState(false);
  const [editingRowIndex, setEditingRowIndex] = useState(null);
  const [loading, setLoading] = useState(false);
  const [fileId, setFileId] = useState(null);
  const [rules, setRules] = useState(null);

  useEffect(() => {
    if (editingRowId) {
      const rowData = rows?.find(
        (row) => row?.invoiceChecklistId === editingRowId
      );

      if (rowData) {
        setValue('remarks', rowData?.remarks || '');
        setValue('fileId', rowData?.fileId || '');

        // Dynamically update validation rules
        setRules({
          remarks: rowData?.isMandatoryForInvoiceGeneration
            ? { required: 'Remarks are required' }
            : {},
          fileId: rowData?.isChecklistFile
            ? { required: 'File is required' }
            : {},
        });

        if (rowData.isChecklistFile && !rowData.fileId) {
          setFileError(true);
        } else {
          setFileError(false);
        }
      }
    }
  }, [editingRowId, rowState, setValue, setRules, rows]);

  const handleFileUpload = async (event) => {
    setLoading(true);
    const file = event.target.files[0];
    if (file) {
      const fileFormData = new FormData();
      fileFormData.append('file', file);
      fileFormData.append('fileTypeId', 77);
      try {
        const response = await new Promise((resolve, reject) => {
          crud.create('files/upload', fileFormData, (_err, res) => {
            if (res?.status === 200) {
              resolve(res);
              setLoading(false);
            } else {
              reject(new Error('File upload failed'));
              setLoading(false);
            }
          });
        });

        setFileId(response?.data?.fileId);
        setValue('fileId', response?.data?.fileId);
        setValue(`documentName`, response?.data?.originalFileName);
        setFileError(false);
      } catch (error) {
        setLoading(false);
      } finally {
        setLoading(false);
      }
    }
  };

  const handleCancel = (row) => {
    if (row?.original?.invoiceChecklistId === rowState?.newRowId) {
      setRows((prevRows) => {
        if (prevRows?.length > 0) {
          return prevRows?.slice(0, -1);
        }
        return prevRows;
      });

      setRowState((prevState) => ({
        ...prevState,
        newRowId: null,
        isCreatingRow: false,
      }));
    } else {
      setEditingRowId(null);
    }
    reset();
  };

  const handleEdit = (row) => {
    if (row?.isChecklistFile && row?.fileId === null) {
      setFileError(true);
    } else {
      setFileError(false);
    }
    reset();
    if (rowState?.newRowId) {
      setRows((prevRows) => {
        if (prevRows?.length > 0) {
          return prevRows?.slice(0, -1);
        }
        return prevRows;
      });
      setRowState((prevState) => ({
        ...prevState,
        newRowId: null,
        isCreatingRow: false,
      }));
    }
    setEditingRowId(row?.invoiceChecklistId);
    Object.entries({
      ...row,
    }).forEach(([key, value]) => {
      setValue(key, value);
    });
    setEditingRowData({ ...row });
  };

  const handleUpdateInvoiceChecklist = async (values) => {

    const updateCombinedData = {
      ...values,
      fileId: values?.fileId || fileId,
    };

    await crud.update(
      `invoicechecklists/${internalInvoiceId}/contract/${contractItemId}`,
      '',
      updateCombinedData,
      (err, res) => {
        if (res?.status === 200) {
          toast.success('Invoice Checklist Updated Successfully');
          fetchData();
          setEditingRowData(null);
          setEditingRowId(null);
        } else {
        }
      }
    );
  };

  const ActionData = [
    {
      name: 'Edit',
      icon: <Icon icon='solar:pen-2-broken' />,
      onClick: (props) => {
        handleEdit(props);
      },
    },
  ];

  const columns = [
    {
      accessorKey: 'checklistTypeId ',
      header: 'Check List Type Name',
      enableEditing: true,
      Cell: ({ row }) => {
        const isNewRow =
          row?.original?.invoiceChecklistId === rowState?.newRowId;
        const isEditing =
          editingRowId === row?.original?.invoiceChecklistId || isNewRow;
        return row?.original?.checklistTypeName;
      },
    },
    {
      accessorKey: 'remarks',
      header: 'Remarks',
      enableEditing: true,
      Cell: ({ row }) => {
        const isNewRow =
          row?.original?.invoiceChecklistId === rowState?.newRowId;
        const isEditing =
          editingRowId === row?.original?.invoiceChecklistId || isNewRow;
        return isEditing ? (
          <>
            <FmTextField
              name='remarks'
              control={control}
              disabled={row?.original?.updatedBy !== null}
              rules={rules?.remarks}
            />
          </>
        ) : (
          row?.original?.remarks
        );
      },
    },
    {
      accessorKey: 'fileId',
      header: 'Upload',
      size: 150,
      Cell: ({ row }) => {
        const isNewRow =
          row?.original?.invoiceChecklistId === rowState?.newRowId;
        const isEditing =
          editingRowId === row?.original?.invoiceChecklistId || isNewRow;

        return isEditing ? (
          <>
            <Controller
              name='fileId'
              control={control}
              rules={rules?.fileId}
              render={({ field, fieldState: { error } }) => (
                <>
                  <FmFileInput
                    {...field}
                    documentName={
                      getValues('documentName') || row?.original?.fileName
                    }
                    onChange={(e) => {
                      handleFileUpload(e);
                      field.onChange(e);
                      trigger('fileId');
                    }}
                    loading={loading}
                    disabled={row?.original?.updatedBy !== null}
                  />
                  {error && (
                    <p className='error text-red-600 font-semibold font-size-sm'>
                      {error?.message}
                    </p>
                  )}
                </>
              )}
            />
          </>
        ) : row?.original?.fileName ? (
          row?.original?.fileName
        ) : (
          <Icon
            icon='ic:baseline-attachment'
            style={{ fontSize: '24px' }}
            className='text-gray-400'
            color='inherit'
          />
        );
      },
    },
    {
      header: 'Actions',
      enableColumnPinning: true,
      enableEditing: false,
      enableSorting: false,
      Cell: ({ row }) => (
        <Box className='flex gap-2'>
          {editingRowId === row?.original?.invoiceChecklistId ? (
            <>
              <Button
                onClick={handleSubmit(handleUpdateInvoiceChecklist)}
                disabled={row?.original?.updatedBy !== null}
              >
                Save
              </Button>
              <Button onClick={handleCancel}>Cancel</Button>
            </>
          ) : (
            <TableActionButtons
              Actions={ActionData?.map((action) => ({
                ...action,
                onClick: () => action.onClick(row?.original),
              }))}
            />
          )}
        </Box>
      ),
    },
  ];

  return (
    <GlassCard className='overflow-hidden w-full'>
      <FmMRTDataTable
        rows={rows}
        columns={columns}
        muiTableContainerProps={{
          sx: {
            maxHeight: '100%',
            flex: 1,
          },
        }}
        enableRowSelection={false}
        rowCount={tableRecordCounts}
        editingRow={editingRowId}
        pagination={pagination}
        sorting={sorting}
        columnFilters={columnFilters}
        globalFilter={globalFilter}
        setPagination={setPagination}
        showPagination={false}
        setSorting={setSorting}
        fetchData={fetchData}
        setColumnFilters={setColumnFilters}
        setGlobalFilter={setGlobalFilter}
        isLoading={isLoading}
        setSearchKeyword={setSearchKeyword}
        searchKeyword={searchKeyword}
        // onCreateRow={handleAddNewRow}
        handleRowDoubleClick={(row) => {
          handleEdit(row?.original);
          setEditingRowIndex(row?.index);
        }}
        // handleExport={handleExport}
        // handleImport={() => setOpenUpload(true)}
      />
    </GlassCard>
  );
};

export default InternalSalesInvoiceChecklist;
