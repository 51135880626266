import { yupResolver } from '@hookform/resolvers/yup';
import { Icon } from '@iconify/react';
import { Box, CircularProgress, Grid, Stack } from '@mui/material';
import React, { useEffect, useMemo, useState } from 'react';
import { useForm } from 'react-hook-form';
import { useTranslation } from 'react-i18next';
import { useSelector } from 'react-redux';
import { toast } from 'react-toastify';
import * as Yup from 'yup';
import apiInstance from '../../../../apis/ApiService.jsx';
import Crud_Service from '../../../../apis/CrudService.jsx';
import ActionButtons from '../../../../components/_form/ActionButtons.tsx';
import {
  StatusBadge,
  ViewField,
} from '../../../../components/_form/FormElements.jsx';
import TableActionButtons from '../../../../components/_form/TableActionButtons.jsx';
import TableSearch from '../../../../components/_form/TableSearch.jsx';
import FmAutoComplete from '../../../../components/_mui/FmAutoComplete.tsx';
import FmSearchableSelect from '../../../../components/_mui/FmSearchableSelect.tsx';
import FmTextField from '../../../../components/_mui/FmTextField.tsx';
import MuiDialogOne from '../../../../components/_mui/MuiDialogOne.jsx';
import DashboardLayout from '../../../../components/DashboardLayout.tsx';
import DataTable from '../../../../components/DataTable.tsx';
import DataTableToolbarButtons from '../../../../components/helpers/DataTableToolbarButtons.tsx';
import useCommonFetchApi from '../../../../components/helpers/useCommonFetchApi.tsx';
import useLoader from '../../../../components/helpers/UseLoader.tsx';
import useTableLogic from '../../../../components/helpers/UseTableLogic.tsx';
import CommonLoader from '../../../../components/page/CommonLoader.jsx';
import PageHeader from '../../../../components/PageHeader.jsx';
import { CreateButton } from '../../../../components/small/Buttons.jsx';
import GlassCard from '../../../../components/small/GlassCard.tsx';
import { findNavByName } from '../../../../utils/navUtils.ts';

const StoreLocations = () => {
  const { t } = useTranslation();
  const crud = new Crud_Service();
  const [loading, setLoading] = useState(false);
  const [editData, setEditData] = useState(null);
  const [selectedRow, setSelectedRow] = useState();
  const [showView, setShowView] = useState(false);
  const [openCreate, setOpenCreate] = useState(false);
  const [openEdit, setOpenEdit] = useState(false);

  const schema = Yup.object().shape({
    storeLocationName: Yup.string().required('Store Location Name is required'),
    locationId: Yup.number()
      .typeError('Location must be a number')
      .required('Location is required'),
  });
  const { handleSubmit, control, reset } = useForm({
    resolver: yupResolver(schema),
    mode: 'onChange',
  });
  const {
    handleSubmit: handleEditSubmit,
    control: editControl,
    setValue: setEditValue,
    reset: resetEdit,
    clearErrors: clearEditErrors,
  } = useForm({
    resolver: yupResolver(schema),
    mode: 'onChange',
  });
  const [locationValue, setLocationValue] = useState(null);
  const [supplierValue, setSupplierValue] = useState(null);
  const [supplierInputValue, setSupplierInputValue] = useState('');
  const {
    rows,
    pageSize,
    pageNumber,
    setSearchKeyword,
    isLoading,
    tableRecordCounts,
    handlePaginationModelChange,
    handleSortModelChange,
    searchKeyword,
    fetchData,
  } = useTableLogic(`storelocations`, 'LocationId', locationValue?.locationId);
  const {
    createLoading,
    startCreateLoading,
    stopCreateLoading,
    editLoading,
    startEditLoading,
    stopEditLoading,
  } = useLoader();

  useEffect(() => {
    document.title = `Procurement-Store Locations`;
  }, []);

  //fetch Common Apis
  const { data: storelocations } = useCommonFetchApi('storelocations');
  const { data: regions } = useCommonFetchApi('regions');
  const { data: projects } = useCommonFetchApi('projects');

  useEffect(() => {
    if (locationValue?.locationId || supplierValue?.supplierId) {
      fetchData();
    }
  }, [locationValue, supplierValue]);

  const handleSubmitStoreLocations = async (values) => {
    startCreateLoading();
    await crud.create('storelocations', values, (err, res) => {
      if (res?.status === 201) {
        fetchData();
        toast.success('Store Locations Created Successfully');
        setOpenCreate(false);
        stopCreateLoading();
      } else {
        toast.error('Failed to create data');
        stopCreateLoading();
      }
    });
  };

  const handleViewClick = async (view) => {
    setShowView(true);
    setLoading(true);
    await crud.getSingle(
      'storelocations',
      view?.storeLocationId,
      (err, res) => {
        if (res?.status === 200) {
          setSelectedRow(res?.data);
          setLoading(false);
        } else {
          setLoading(false);
        }
      }
    );
  };

  const handleEditClick = async (view) => {
    setOpenEdit(true);
    setLoading(true);
    await crud.getSingle(
      'storelocations',
      view?.storeLocationId,
      (err, res) => {
        if (res?.status === 200) {
          Object.entries({
            ...res?.data,
          }).forEach(([key, value]) => {
            setEditValue(key, value);
          });
          setEditData(res?.data);
          setLoading(false);
        } else {
          setLoading(true);
        }
      }
    );
  };

  const handleEditStoreLocation = async (values) => {
    startEditLoading();
    await crud.update(
      'storelocations',
      values?.storeLocationId,
      values,
      (err, res) => {
        if (res?.status === 200) {
          fetchData();
          toast.success('Store Location Updated Successfully');
          setOpenEdit(false);
          stopEditLoading();
        } else {
          toast.error('Failed to update data');
          stopEditLoading();
        }
      }
    );
  };

  const handleDelete = async (view) => {
    await crud.remove('storelocations', view?.storeLocationId, (err, res) => {
      if (err) {
        toast.error('Error fetching Job Requisitions:', err);
        return;
      }
      if (res?.status === 204) {
        fetchData();
        toast.success('Store Location Deleted Successfully');
      } else {
        toast.error('Failed to delete data');
      }
    });
  };

  const Actions = [
    {
      name: t('view'),
      icon: <Icon icon='solar:eye-linear' />,
      onClick: (props) => handleViewClick(props),
    },
    {
      name: t('edit'),
      icon: <Icon icon='solar:pen-new-round-linear' />,
      onClick: (props) => handleEditClick(props),
    },
    {
      name: t('delete'),
      icon: <Icon icon='solar:trash-bin-2-linear' />,
      onClick: (props) => handleDelete(props),
      danger: true,
    },
  ];

  const columns = [
    {
      field: 'column_sort',
      headerName: 'Actions',
      minWidth: 150,
      width: 100,
      sortable: false,
      disableClickEventBubbling: false,

      renderCell: (params) => {
        return (
          <TableActionButtons
            Actions={Actions?.map((action) => ({
              ...action,
              onClick: () => action.onClick(params.row),
            }))}
            selectedRow={params.row}
            setSelectedRow={setSelectedRow}
          />
        );
      },
    },
    {
      field: 'storeLocationCode',
      headerName: 'Store Location Code',
      width: 170,
    },
    {
      field: 'storeLocationName',
      headerName: 'Store Location Name',
      width: 170,
    },
    {
      field: 'parentStoreLocationName',
      headerName: 'Parent Store Location Name',
      width: 170,
    },
    {
      field: 'locationName',
      headerName: 'Location Name',
      width: 170,
    },
    {
      field: 'regionName',
      headerName: 'Region Name',
      width: 170,
    },
    {
      field: 'projectName',
      headerName: 'Project Name',
      width: 170,
    },

    {
      field: 'description',
      headerName: 'Description',
      type: 'number',
      width: 170,
    },
    {
      field: 'statusName',
      headerName: 'Status',
      width: 100,
      renderCell: (params) => {
        return (
          <StatusBadge
            title={params.value === 'ACTIVE' ? 'Active' : 'Inactive'}
            type={params.value === 'ACTIVE' ? 'green' : 'red'}
          />
        );
      },
    },
  ];

  const counters = [];

  //Row Double Click
  const onRowDoubleClick = (e) => {
    if (e?.row) {
      handleEditClick(e?.row);
    }
  };
  const downloadJobRequisitions = async () => {
    const downloadUrl = await apiInstance.getFiles(
      'storelocations/exportexcel'
    );

    const link = document.createElement('a');
    link.href = downloadUrl;
    link.setAttribute('download', 'JObRequisitions.xlsx');
    document.body.appendChild(link);
    link.click();
    document.body.removeChild(link);
  };
  const menu = useSelector((state) => state?.menu?.items);

  const SubMenu = useMemo(() => {
    return menu?.map((item) => item?.menus?.flat()).flat() || [];
  }, [menu]);

  const result = useMemo(
    () => findNavByName(SubMenu, 'CAFM', 'All Master'),
    [SubMenu]
  );
  return (
    <DashboardLayout
      title='Store Locations'
      actionButtons={
        <>
          <Box className='flex flex-row items-center whitespace-nowrap gap-3'>
            <PageHeader counters={counters} />
            <CreateButton
              name='New'
              onClick={() => {
                setOpenCreate(true);
                reset();
              }}
            />
          </Box>
        </>
      }
      hasSubmenu
      menu={result}
    >
      <GlassCard className='h-full-css'>
        <Box className='h-full-css'>
          <DataTable
            rows={rows}
            columns={columns}
            loading={isLoading}
            onRowDoubleClick={(e) => onRowDoubleClick(e)}
            getRowClassName={(params) =>
              `${params.row.workingStatus === 'InActive' &&
              'bg-red-500 bg-opacity-15'
              }`
            }
            sortingMode='server'
            paginationMode='server'
            onPaginationModelChange={(model) =>
              handlePaginationModelChange(model)
            }
            onSortModelChange={(model) => handleSortModelChange(model)}
            page={pageNumber - 1}
            pageSize={pageSize}
            rowCount={tableRecordCounts?.total}
            slots={{
              toolbar: () => (
                <Box
                  className='p-2 w-full flex justify-between items-center'
                  sx={{
                    borderBottom: '1px solid',
                    borderColor: 'border.main',
                  }}
                >
                  <Box className='flex-1'>
                    <Stack direction='row' spacing={2} alignItems='center'>
                      <Box sx={{ maxWidth: '250px' }}>
                        <TableSearch
                          placeholder='Search'
                          fullWidth
                          setSearchKeyword={setSearchKeyword}
                          searchValue={searchKeyword}
                        />
                      </Box>
                    </Stack>
                  </Box>

                  <DataTableToolbarButtons
                    handleExcelExport={downloadJobRequisitions}
                  />
                </Box>
              ),
            }}
          />
        </Box>
      </GlassCard>

      <MuiDialogOne
        title='Create'
        open={openCreate}
        onClose={() => {
          setOpenCreate(false);
        }}
      >
        <form onSubmit={handleSubmit(handleSubmitStoreLocations)}>
          <Grid container spacing={2}>
            <Grid item md={6}>
              <FmTextField
                name='storeLocationName'
                control={control}
                label='Store Location Name'
              />
            </Grid>
            <Grid item md={6}>
              <FmSearchableSelect
                name='parentStoreLocationId'
                control={control}
                apiUrl='storelocations'
                valueField='storeLocationId'
                labelField={['storeLocationName']}
                showField={['storeLocationName']}
                label='Parent Store Location'
              />
            </Grid>
            <Grid item md={6}>
              <FmSearchableSelect
                name='locationId'
                control={control}
                apiUrl='locations'
                valueField='locationId'
                labelField={['locationCode', 'locationName']}
                showField={['locationName']}
                label='Locations'
              />
            </Grid>
            <Grid item md={6}>
              <FmAutoComplete
                name='regionId'
                control={control}
                options={regions}
                label='Regions'
                displayField='regionName'
                optionFields={['regionName']}
                valueKey='regionId'
              />
            </Grid>
            <Grid item md={6}>
              <FmAutoComplete
                name='projectId'
                control={control}
                options={projects}
                label='Projects'
                displayField='projectName'
                optionFields={['projectName']}
                valueKey='projectId'
              />
            </Grid>
            <Grid item md={12}>
              <FmTextField
                name='description'
                control={control}
                label='Descriptions'
                multiline={true}
                rows={3}
                maxRows={4}
              />
            </Grid>

            <Grid item md={12}>
              <ActionButtons
                onReset={reset}
                onSubmit={handleSubmit(handleSubmitStoreLocations)}
                onCancel={() => setOpenCreate(false)}
                submitLoading={createLoading}
                cancelLoading={false}
              />
            </Grid>
          </Grid>
        </form>
      </MuiDialogOne>

      <MuiDialogOne
        title='View'
        open={showView}
        onClose={() => setShowView(false)}
      >
        {loading ? (
          <CircularProgress color='primary' sx={{ ml: '50%' }} />
        ) : (
          <Grid container spacing={3}>
            <Grid item md={6}>
              <ViewField
                label='Store Location Code'
                title={selectedRow?.storeLocationCode}
              />
            </Grid>
            <Grid item md={6}>
              <ViewField
                label='Store Location Name'
                title={selectedRow?.storeLocationName}
              />
            </Grid>
            <Grid item md={6}>
              <ViewField
                label='Location Name'
                title={selectedRow?.locationName}
              />
            </Grid>

            <Grid item md={6}>
              <ViewField label='Region Name' title={selectedRow?.regionName} />
            </Grid>
            <Grid item md={6}>
              <ViewField
                label='Project Name'
                title={selectedRow?.projectName}
              />
            </Grid>
            <Grid item md={6}>
              <ViewField label='Status' title={selectedRow?.statusName} />
            </Grid>
          </Grid>
        )}
      </MuiDialogOne>

      <MuiDialogOne
        title='Edit'
        open={openEdit}
        onClose={() => {
          setOpenEdit(false);
          clearEditErrors();
        }}
      >
        {loading ? (
          <CommonLoader />
        ) : (
          <form onSubmit={handleEditSubmit(handleEditStoreLocation)}>
            <Grid container spacing={2}>
              <Grid item md={6}>
                <FmTextField
                  name='storeLocationName'
                  control={editControl}
                  label='Store Location Name'
                />
              </Grid>
              <Grid item md={6}>
                <FmSearchableSelect
                  name='parentStoreLocationId'
                  control={editControl}
                  apiUrl='storelocations'
                  valueField='storeLocationId'
                  labelField={['storeLocationName']}
                  showField={['storeLocationName']}
                  label='Parent Store Location'
                  defaultValue={{
                    parentStoreLocationId: editData?.parentStoreLocationId,
                    storeLocationName: editData?.parentStoreLocationName,
                  }}
                />
              </Grid>
              <Grid item md={6}>
                <FmSearchableSelect
                  name='locationId'
                  control={editControl}
                  apiUrl='locations'
                  valueField='locationId'
                  labelField={['locationCode', 'locationName']}
                  showField={['locationName']}
                  label='Locations'
                  defaultValue={{
                    locationId: editData?.locationId,
                    locationName: editData?.locationName,
                  }}
                />
              </Grid>
              <Grid item md={6}>
                <FmAutoComplete
                  name='regionId'
                  control={editControl}
                  options={regions}
                  label='Regions'
                  displayField='regionName'
                  optionFields={['regionName']}
                  valueKey='regionId'
                />
              </Grid>
              <Grid item md={6}>
                <FmAutoComplete
                  name='projectId'
                  control={editControl}
                  options={projects}
                  label='Projects'
                  displayField='projectName'
                  optionFields={['projectName']}
                  valueKey='projectId'
                />
              </Grid>
              <Grid item md={12}>
                <FmTextField
                  name='description'
                  control={editControl}
                  label='Descriptions'
                  multiline={true}
                  rows={3}
                  maxRows={4}
                />
              </Grid>

              <Grid item md={12}>
                <ActionButtons
                  onReset={resetEdit}
                  onSubmit={handleEditSubmit(handleEditStoreLocation)}
                  onCancel={() => setOpenEdit(false)}
                  submitLoading={editLoading}
                  cancelLoading={false}
                  submitText='Update'
                />
              </Grid>
            </Grid>
          </form>
        )}
      </MuiDialogOne>
    </DashboardLayout>
  );
};

export default StoreLocations;
