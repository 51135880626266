import { yupResolver } from '@hookform/resolvers/yup';
import { Grid, Box } from '@mui/material';
import dayjs from 'dayjs';
import React, { useEffect, useState } from 'react';
import { useForm } from 'react-hook-form';
import { useNavigate } from 'react-router-dom';
import { toast } from 'react-toastify';
import * as yup from 'yup';
import Crud_Service from '../../../../apis/CrudService.jsx';
import ActionButtons from '../../../../components/_form/ActionButtons.tsx';
import FmDatePicker from '../../../../components/_mui/FmDatePicker.tsx';
import FmSearchableSelect from '../../../../components/_mui/FmSearchableSelect.tsx';
import FmTextField from '../../../../components/_mui/FmTextField.tsx';
import DashboardLayout from '../../../../components/DashboardLayout.tsx';
import GlassCard from './../../../../components/small/GlassCard.tsx';
import { Icon } from '@iconify/react/dist/iconify.js';
import ViewContractDetails from '../CreateSalesQuotation/View/ViewContractDetails.tsx';
import CurrencyView from '../../../../components/_mui/FmCurrencyView.tsx';

const GridSize = 3;
const schema = yup.object().shape({
  orderDate: yup.date().required('Order Date is required'),
  salesOrderName: yup.string().required('Sales Order Name is required'),
  contractId: yup.number()
    .required('Contract is required'),
});

interface ContractDetails {
  projectCode?: string;
  projectName?: string;
  clientName?: string;
  contactPerson?: string;
  contactNumber?: string;
  contactEmailId?: string;
  locationName?: string;
  paymentTerms?: string;
}

interface FormValues {
  contractId: number;
  salesOrderName: string;
  orderDate: Date;
  deliveryTerms?: string;
  narration?: string;
  paymentTerms?: string;
  remarks?: string;
  salesQuotationId?: number;
  vat?: string;
  amount?: string;
}

let IconsListing = [
  {
    icon: <Icon icon='fluent:building-retail-20-regular' />,
    label: 'Type',
    fields: [
      { key: 'contractTypeName', label: 'Name' },
    ],
  },
  {
    icon: <Icon icon='fluent:building-retail-20-regular' />,
    label: 'Project Details',
    fields: [
      { key: 'projectCode', label: 'Code' },
      { key: 'projectName', label: 'Name' },
    ],
  },
  {
    icon: <Icon icon='fluent:building-people-20-regular' />,
    label: 'Client Details',
    fields: [{ key: 'clientName', label: 'Name' }],
  },
  {
    icon: <Icon icon='fluent:person-20-regular' />,
    label: 'Contact Details',
    fields: [
      { key: 'contactPerson', label: 'Name' },
      { key: 'contactNumber', label: 'Mobile' },
      { key: 'contactEmailId', label: 'Email' },
    ],
  },
  {
    icon: <Icon icon='fluent:location-20-regular' />,
    label: 'Location',
    fields: [{ key: 'locationName', label: 'Name' }],
  },
  {
    icon: <Icon icon='fluent:payment-20-regular' />,
    label: 'Payment Terms',
    fields: [{ key: 'paymentTerms', label: 'Terms' }],
  },
  {
    icon: <Icon icon='fluent:payment-20-regular' />,
    label: 'Branch',
    fields: [{ key: 'branchName', label: 'Branch' }],
  },
];

const AddSalesOrder = (props) => {
  const { setGetData, type } = props;
  const crud = new Crud_Service();
  const [loading, setLoading] = useState(false);
  const { control, handleSubmit, reset, setValue } =
    useForm<FormValues>({
      resolver: yupResolver(schema),
      mode: 'onChange',
    });
  const [selectedOrder, setSelectedOrder] = useState(null);
  const [selectedContract, setSelectedContract] = useState<ContractDetails>({});
  const [viewContractData, setViewContractData] = useState({});

  const navigate = useNavigate();

  useEffect(() => {
    setValue('amount', selectedContract?.budget);
  }, [selectedContract]);

  useEffect(() => {
    reset();
    setSelectedOrder(null);
  }, [type]);

  useEffect(() => {
    if (selectedOrder?.salesQuotationId) {
      setValue('deliveryTerms', selectedOrder?.deliveryTerms);
      setValue('narration', selectedOrder?.narration);
      setValue('paymentTerms', selectedOrder?.paymentTerms);
      setValue('remarks', selectedOrder?.remarks);
      setValue('contractId', selectedOrder?.contractId);
    }
    setValue('contractName', selectedOrder?.contractName);
    setValue('contractCode', selectedOrder?.contractCode);
    setValue();
  }, [selectedOrder]);

  const handleSelectedQuotation = (props) => {
    const salesQuotationDetails = {
      icon: <Icon icon='fluent:building-retail-20-regular' />,
      label: 'Sales Quotation Details',
      fields: [
        { key: 'salesQuotationCode', label: 'Code' },
        { key: 'salesQuotationName', label: 'Name' },
      ],

    };

    IconsListing = IconsListing.filter(
      (icon) => icon.label !== 'Sales Quotation Details'
    );
    IconsListing.unshift(salesQuotationDetails);

    setSelectedOrder(props);
  };
  const viewDataFetch = async () => {
    crud.getSingle('contracts', selectedOrder?.contractId, (err, res) => {
      if (res?.status === 200) {
        setViewContractData(res?.data);
      } else {
        setViewContractData([]);
      }
    });
  };
  useEffect(() => {
    if (selectedOrder?.contractId) {
      viewDataFetch();
    }
  }, [selectedOrder?.contractId]);

  const handleSelectedContract = (props) => {
    setSelectedContract(props);
    setSelectedOrder(props);
  };
  // const amount = watch('amount');
  // useEffect(() => {
  //   const amountValue = parseFloat(amount) || 0;

  //   const amount = amountValue + vatAmount;
  //   setValue('amount', amount.toFixed(2));
  // }, [amount, vat, setValue]);

  const submitSalesOrder = async (values) => {
    setLoading(true);
    const combinedValues = {
      ...values,
      salesOrderTypeId: type,
      salesOrderName: values?.salesOrderName,
      salesQuotationId: values?.salesQuotationId || null,
      contractId: values?.contractId || null,
      currencyId: values?.currencyId?.currencyId || 4,
      orderDate: values?.orderDate
        ? dayjs(values?.orderDate).format('YYYY-MM-DD')
        : null,
      vat: values?.vat || 0,
    };
    await crud.create('salesorders', combinedValues, (_err, res) => {
      if (res?.status === 201) {
        toast.success('Added Sales Order Successfully');
        navigate('../view/' + res?.data?.salesOrderId);
        setGetData(true);
        reset();
        setSelectedOrder(null);
        setLoading(false);
      } else {
        setLoading(false);
      }
    });
  };

  const handleReset = () => {
    reset();
    setSelectedOrder(null);
  };
  const handleClearSelection = () => {
    IconsListing = IconsListing.filter(
      (icon) => icon.label !== 'Sales Quotation Details'
    );
    setSelectedOrder(null);
  };
  return (
    <DashboardLayout title='Create Sales Order'>
      <Grid container spacing={3}>
        {/* Left side - Form */}
        <Grid item xs={12} md={9}>
          <GlassCard className='p-2'>
            <form onSubmit={handleSubmit(submitSalesOrder)}>
              <Grid container spacing={2}>
                <Grid item md={GridSize}>
                  <FmTextField
                    name='salesOrderName'
                    label='Name'
                    control={control}
                    required
                  />
                </Grid>


                <Grid item md={GridSize}>
                  <FmSearchableSelect
                    name='contractId'
                    control={control}
                    defaultValue={{
                      projectName: selectedOrder?.projectName,
                      contractCode: selectedOrder?.contractCode,
                    }}
                    apiUrl='contracts'
                    valueField='contractId'
                    // clearSelection={() => setSelectedContract({})}
                    headerField={['Contract', 'Project']}
                    labelField={['contractCode', 'projectName']}
                    showField={['contractCode', 'projectName']}
                    label='Contract'
                    onChangeProp={setSelectedContract}
                    stackedFields={{
                      contractCode: [
                        { Code: 'contractCode' },
                        { Name: 'contractName' },
                      ],
                      projectName: [
                        { Code: 'projectCode' },
                        { Name: 'projectName' },
                      ],
                    }}
                    onChangeProp={(value) => handleSelectedContract(value)}
                    required
                  />
                </Grid>

                <Grid item md={GridSize * 2}>
                  <FmSearchableSelect
                    name='salesQuotationId'
                    label='Sales Quotation'
                    apiUrl={'salesquotations/quotation-data'}
                    valueField='salesQuotationId'
                    clearSelection={handleClearSelection}
                    onChangeProp={(value) => handleSelectedQuotation(value)}
                    headerField={['Contract', 'Project', 'Sales Quotation']}
                    stackedFields={{
                      contractName: [
                        { Code: 'contractCode' },
                        { Name: 'contractName' },
                      ],

                      projectName: [
                        { Code: 'projectCode' },
                        { Name: 'projectName' },
                      ],
                      salesQuotationCode: [
                        { Code: 'salesQuotationCode' },
                        { Name: 'salesQuotationName' },
                      ],
                    }}
                    labelField={[
                      'contractName',
                      'projectName',
                      'salesQuotationCode',
                    ]}
                    showField={['contractName', 'salesQuotationCode']}
                    queryparam2={`quotationStatusId`}
                    queryparamValue2={2}
                    queryparamValue={selectedContract?.contractId}
                    queryparam='contractId'
                    control={control}
                  />
                </Grid>
                <Grid item md={GridSize}>
                  <FmDatePicker
                    name='orderDate'
                    label='Order Date'
                    control={control}
                    required
                  />
                </Grid>

                <Grid item md={GridSize}>
                  <FmSearchableSelect
                    name='currencyId'
                    control={control}
                    apiUrl='currencies'
                    valueField='currencyId'
                    defaultValue={{ currencyName: 'AED' }}
                    headerField={['Name']}
                    labelField={['currencyName']}
                    showField={['currencyName']}
                    label={'Currency'}
                  />
                </Grid>
                <Grid item md={GridSize}>
                  <FmSearchableSelect
                    name='paymentTermId'
                    control={control}
                    apiUrl='paymentterms'
                    valueField='paymentTermId'
                    headerField={['Payment Term']}
                    labelField={['paymentTermName']}
                    showField={['paymentTermName']}
                    label='Payment Term'
                  />
                </Grid>

                <Grid item md={GridSize}>
                  <FmTextField
                    name='narration'
                    label='Narration'
                    control={control}
                  />
                </Grid>
                <Grid item md={GridSize}>
                  <FmTextField
                    name='deliveryTerms'
                    label='Delivery Terms'
                    control={control}
                  />
                </Grid>

                <Grid item md={GridSize}>
                  <FmTextField
                    name='vat'
                    label='VAT (%)'
                    control={control}
                    pattern='Percentage'
                  />
                </Grid>

                <Grid item md={GridSize}>
                  <Box className='flex justify-between flex-col gap-0.5'>
                    <Box className='mb-1 flex items-center gap-2 font-semibold justify-between'>
                      <Box>Amount : <CurrencyView value={selectedContract?.budget} /></Box>
                    </Box>
                  </Box>
                </Grid>

                <Grid item md={GridSize * 2}>
                  <FmTextField
                    name='remarks'
                    multiline
                    rows={4}
                    label='Remarks'
                    control={control}
                  />
                </Grid>
              </Grid>

              <ActionButtons
                onSubmit={handleSubmit(submitSalesOrder)}
                onReset={handleReset}
                cancelText='Back'
                onCancel={() =>
                  navigate('/financial/accounts-receivable/sales-order')
                }
                submitLoading={loading}
                cancelLoading={false}
                submitText='Create'
              />
            </form>
          </GlassCard>
        </Grid>

        {/* Right side - Contract Details */}
        <Grid item xs={12} md={3}>
          <Box className='relative h-full w-full'>
            <ViewContractDetails
              IconsListing={IconsListing}
              selectedOrder={selectedOrder}
              selectedContract={viewContractData}
            />
          </Box>
        </Grid>
      </Grid>
    </DashboardLayout>
  );
};

export default AddSalesOrder;
