import React, { useEffect, useState } from 'react';
import GlassCard from '../../../components/small/GlassCard.tsx';
import { StyledCard } from '../../../components/_form/index.jsx';
import { Grid } from '@mui/material';
import { ViewField } from '../../../components/_form/FormElements.jsx';
import Crud_Service from '../../../apis/CrudService.jsx';
import FmViewDateField from '../../../components/_mui/FmViewDateField.tsx';
import { toast } from 'react-toastify';

const AccrualConfig = (props) => {
    const crud = new Crud_Service();
    const [item, setItem] = useState(null);
    const { id, i, value } = props;

    useEffect(() => {
        crud.getAll(`employees/${id}/employee-accurals-config`, '', (err, res) => {
            if (err) {
                toast.error(err);
            } else {
                setItem(res?.data);
            }
        });
    }, [id]);

    // The given structure for display
    const displayStructure = {
        groupName: 'Accruals Config',
        child: [
            { title: 'Etisalat CUG Amount (1 Year)', name: 'accrualEtisalatBillAmountPerYear' },
            { title: 'Insurance Amount (2 Years)', name: 'accrualInsuranceAmount' },
            { title: 'VISA Amount (2 Years)', name: 'accrualVisaExpenseAmount' },
            { title: 'Camp Amount (1 Year)', name: 'accrualLabourCampAmountPerYear' },
            { title: 'No. of Dependents', name: 'noOfDependents' },
        ],
    };

    //Formatting Fields for display
    const formatFieldValue = (field, value) => {
        switch (field.name) {
            case 'empIdCardProvided':
                return value ? 'Yes' : 'No';
            default:
                return value || '';
        }
    };

    const renderFields = (data, structure) => {
        return structure.child.map((field, i) => {
            const value = data ? data[field.name] : '';
            return (
                <Grid item md={5} key={i}>
                    <ViewField
                        label={field.title}
                        title={String(formatFieldValue(field, value))}
                    />
                </Grid>
            );
        });
    };

    return (
        <GlassCard className={value === i ? 'w-full  p-5' : 'hidden'}>
            <StyledCard title={'Accruals Config'}>
                <Grid container spacing={1.5}>
                    {renderFields(item, displayStructure)}
                </Grid>
            </StyledCard>
        </GlassCard>
    );
};

export default AccrualConfig;
