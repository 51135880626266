import React, { useEffect, useMemo } from 'react';
import { DataGrid, useGridApiRef } from '@mui/x-data-grid';
import { Box, IconButton } from '@mui/material';
import { Icon } from '@iconify/react/dist/iconify.js';
import NoDataAvailable from './elements/NoDataAvailable.tsx';
import ReactDOM from 'react-dom/client';
import { FontSize } from '../theme/FontSize.jsx';
import DatatableLoader from './elements/DatatableLoader.tsx';

const minHeightStyle = 38;

interface DataTableProps {
  rows: any[];
  columns: any[];
  fetchData: () => void;
  pageSize?: number;
  pageSizeOptions?: number[];
  onPaginationModelChange?: (model: { page: number; pageSize: number }) => void;
  onSortModelChange?: (model: any[]) => void;
  sortingMode?: 'client' | 'server';
  paginationMode?: 'client' | 'server';
  getRowClassName?: (params: any) => string;
  toolbarComponent?: React.ReactNode;
  columnHeaderHeight?: number;
  onRowDoubleClick?: (e: any) => void;
  page?: number;
  initialPageSize?: number;
  rowCount?: number;
  sortableKeys?: Record<string, string>;
  showSerialNumber?: boolean;
  borderEnabled?: boolean;
  shrinkColumns?: boolean;
  slots?: any;
  nakedTable?: boolean;
  [key: string]: any;
}
const DataTable = (props: DataTableProps) => {
  const {
    rows,
    columns,
    fetchData,
    pageSize = 50,
    pageSizeOptions = [10, 25, 50, 100],
    onPaginationModelChange,
    onSortModelChange,
    isLoading,
    sortingMode,
    paginationMode,
    getRowClassName,
    toolbarComponent,
    columnHeaderHeight,
    onRowDoubleClick,
    page = 0,
    initialPageSize,
    rowCount = 0,
    sortableKeys,
    onRowSelectionModelChange,
    checkboxSelection,
    showSerialNumber,
    borderEnabled = false,
    shrinkColumns = false,
    nakedTable = false,
    slots,
    ...rest
  } = props;

  const autosizeOptions = {
    includeOutliers: true,
  };

  const apiRef = useGridApiRef();
  apiRef.current?.autosizeColumns &&
    apiRef.current?.autosizeColumns(autosizeOptions);

  const handlesortModelChange = (sortModel) => {
    onSortModelChange(sortModel);
  };

  const memoizedColumns = useMemo(() => {
    if (!showSerialNumber) return columns;
    return [
      {
        field: 'serialNumber',
        headerName: 'S.No',
        flex: 1,
        minWidth: 60,
        align: 'center',
        sortable: false,
        renderCell: (params) => {
          const index = rows.findIndex((row) => row.id === params.id);
          return index + 1;
        },
      },
      ...columns,
    ];
  }, [columns, rows, showSerialNumber]);

  // **🔹 Memoize Rows**
  const memoizedRows = useMemo(() => rows, [rows]);

  const AppendRefreshButton = useMemo(
    () => () =>
      (
        <Box display='flex' justifyContent='space-between' alignItems='center'>
          {toolbarComponent}
          <IconButton
            onClick={fetchData}
            sx={{
              fontSize: '22px',
              padding: '0px',
              width: '32px',
              height: '32px',
            }}
            title='Refresh'
          >
            <Icon icon='ic:baseline-refresh' />
          </IconButton>
        </Box>
      ),
    [fetchData, toolbarComponent]
  );

  useEffect(() => {
    const refreshContainer = document.getElementById('place_refresh');
    if (!refreshContainer) return;
    const rootButtons = ReactDOM.createRoot(refreshContainer);
    rootButtons.render(<AppendRefreshButton />);
  }, [AppendRefreshButton]);

  return (
    <>
      <DataGrid
        rows={memoizedRows}
        columns={memoizedColumns}
        pageSize={pageSize}
        initialState={{
          pagination: {
            paginationModel: {
              pageSize: 50,
            },
          },
        }}
        loading={isLoading}
        pageSizeOptions={pageSizeOptions}
        sortingMode={sortingMode}
        paginationMode={paginationMode}
        getRowClassName={getRowClassName}
        onPaginationModelChange={onPaginationModelChange}
        onSortModelChange={handlesortModelChange}
        onRowDoubleClick={onRowDoubleClick}
        page={page}
        disableColumnMenu={true}
        rowHeight={30}
        getRowHeight={() => 'auto'}
        localeText={{
          toolbarColumns: '',
          toolbarExport: '',
        }}
        disableRowSelectionOnClick
        disableVirtualization
        columnHeaderHeight={columnHeaderHeight}
        rowCount={rowCount || 0}
        sx={{
          '&.MuiDataGrid-root': {
            color: 'text.main',
            border: 'none',
            background: 'none',
            fontSize: FontSize.bodyMinusOne,
            position: 'relative',

            '& .MuiDataGrid-cellContent': {
              whiteSpace: !shrinkColumns ? 'normal' : 'nowrap',
              wordBreak: !shrinkColumns && 'break-word',
            },

            '& .MuiDataGrid-cell.nowrap-cell .MuiDataGrid-cellContent': {
              whiteSpace: 'nowrap',
              wordBreak: 'inherit',
            },

            '& .MuiDataGrid-columnHeaderTitleContainer': {
              position: 'relative',
              '& .MuiDataGrid-iconButtonContainer': {
                position: 'absolute',
                right: 0,
                top: '50%',
                zIndex: 1,
                transform: 'translateY(-50%)',
                '& .MuiIconButton-root': {
                  padding: '1px',
                },
              },
            },

            '& .MuiDataGrid-columnHeader': {
              '& .MuiDataGrid-columnHeaderTitleContainer': {
                alignItems: 'center',
                opacity: 1,
                '& .MuiDataGrid-iconButtonContainer': {
                  position: 'relative',
                  visibility: 'visible',
                  width: 'auto',
                  pointerEvents: 'none',
                  opacity: 0.2,
                  top: 0,
                  transform: 'inherit',
                  '& .MuiButtonBase-root': {
                    color: 'inherit',
                    padding: 0,
                    marginLeft: '4px',
                    '&:hover': {
                      opacity: 1,
                    },
                  },
                  '& .MuiDataGrid-overlay': {
                    bgcolor: 'background.overlayTwo',
                  },
                  '& .MuiSvgIcon-root': {
                    fontSize: '18px',
                    opacity: 1,
                  },
                },

                '&:hover .MuiDataGrid-iconButtonContainer': {
                  opacity: 0.8,
                },
              },
              '&.MuiDataGrid-columnHeader--sorted': {
                '& .MuiDataGrid-columnHeaderTitleContainer': {
                  opacity: 1,
                  '&:hover': {
                    opacity: 1,
                  },
                  '& .MuiDataGrid-iconButtonContainer': {
                    opacity: 1,
                  },
                },
              },
            },

            '& .MuiDataGrid-overlayWrapper': {
              position: 'absolute',
              height: '100%',
              '& .MuiDataGrid-overlayWrapperInner': {
                height: '100%',
              },
            },
          },

          '& .MuiDataGrid-columnHeaderTitle': {
            fontWeight: nakedTable ? 400 : 600,
            whiteSpace: shrinkColumns ? 'nowrap' : 'pre-line',
            wordBreak: !shrinkColumns && 'break-word',
            textOverflow: shrinkColumns ? 'ellipsis' : 'inherit',
          },
          '& .MuiDataGrid-cell': {
            border: !nakedTable ? '1px solid' : 'none',
            borderLeft: nakedTable && 'none',
            borderColor: 'border.table',
            borderTop: 'none',
            padding: nakedTable ? '5px 8px' : '2px 8px',
            borderLeft: 'none',
            '& .MuiInputBase-root': {
              fontSize: 'inherit',
              '& .MuiOutlinedInput-notchedOutline': {
                display: 'none',
              },
            },
          },
          '& .MuiDataGrid-cell:focus': {
            outline: 'inherit!important',
          },
          '& .MuiDataGrid-cell--editable:focus': {
            outline: 'inherit !important',
          },
          '& .MuiDataGrid-withBorderColor': {
            borderColor: 'border.table',
          },
          '& .MuiDataGrid-columnHeaders': {
            bgcolor: nakedTable ? 'inherit' : 'background.table',
            display: nakedTable && 'none',
          },
          '& .MuiDataGrid-columnHeader': {
            border: nakedTable ? 'none' : '1px solid',
            borderTop: 'none',
            padding: '5px 8px',
            borderLeft: 'none',
            borderColor: nakedTable ? 'transparent' : 'border.table',
            outline: 'inherit !important',
            color: 'text.main',
            fontWeight: nakedTable ? 'inherit' : 600,
          },
          '& .MuiCheckbox-root': {
            padding: '2px',
            '& .MuiSvgIcon-root': {
              fontSize: '20px',
            },
          },
          '& .MuiDataGrid-cellCheckbox': {
            outline: 'inherit !important',
          },
          '& .MuiDataGrid-columnSeparator': {
            display: 'none',
          },
          '& .MuiDataGrid-footerContainer': {
            justifyContent: 'center',

            minHeight: minHeightStyle,
            '& .MuiToolbar-root.MuiToolbar-gutters': {
              minHeight: minHeightStyle,
            },
          },
          '& .MuiTablePagination-selectLabel, & .MuiTablePagination-root, & .MuiTablePagination-displayedRows':
            {
              fontSize: 'inherit',
              color: 'inherit',
            },
          '& .MuiDataGrid-row.Mui-hovered': {
            bgcolor: nakedTable ? 'background.white' : 'background.tableHover',
            '& .MuiDataGrid-cell': {
              bgcolor: !nakedTable
                ? (theme) => `${theme.palette.background.tableHover} !important`
                : 'background.body',
            },
            //  boxShadow: '0px 0px 5px 0px rgb(0 0 0 / 8%)',
          },
        }}
        slotProps={{
          basePopper: {
            placement: 'bottom-end',
          },
          columnsPanel: {
            sx: {
              '& .MuiDataGrid-columnsPanelRow': {
                padding: '4px 0',
              },
              '& .MuiDataGrid-panelHeader': {
                display: 'none',
              },
            },
          },
        }}
        checkboxSelection={checkboxSelection}
        onRowSelectionModelChange={onRowSelectionModelChange}
        slots={{
          noRowsOverlay: NoDataAvailable,
          loadingOverlay: DatatableLoader,
          ...slots,
        }}
        {...rest}
      />
    </>
  );
};

export default DataTable;
