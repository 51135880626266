import React, { useEffect, useRef, useState } from 'react';
import Crud_Service from '../../../../../apis/CrudService.jsx';
import { useParams } from 'react-router-dom';
import { useForm } from 'react-hook-form';
import { useHotkeys } from 'react-hotkeys-hook';
import { Box, Button, Stack } from '@mui/material';
import TableActionButtons from '../../../../../components/_form/TableActionButtons.jsx';
import GlassCard from '../../../../../components/small/GlassCard.tsx';
import FmMRTDataTable from '../../../../../components/FmMRTDataTable.tsx';
import useTableLogic from '../../../../../components/helpers/MRTUseTableLogic.tsx';
import FmSearchableSelect from '../../../../../components/_mui/FmSearchableSelect.tsx';
import Icons from '../../../../../utils/Icon.jsx';
import { toast } from 'react-toastify';
import FmCurrencyTextField from '../../../../../components/_mui/FmCurrencyTextField.tsx';
import CurrencyView from '../../../../../components/_mui/FmCurrencyView.tsx';
import { ViewField } from '../../../../../components/_form/FormElements.jsx';
import { salesInvoiceStatusEnum } from '../../../../../utils/CommonVariables.tsx';

const ARReceiptsItemList = (props) => {
  const { clientId } = props;
  const { id } = useParams();
  const {
    rows,
    setRows,
    rowCount,
    isLoading,
    pagination,
    sorting,
    columnFilters,
    globalFilter,
    tableRecordCounts,
    setPagination,
    setSorting,
    setColumnFilters,
    setGlobalFilter,
    fetchData,
    setSearchKeyword,
    searchKeyword,
  } = useTableLogic(
    'financialtransactionreceiptitems',
    ['financialTransactionReceiptId'],
    [id]
  );
  const crud = new Crud_Service();
  const [editingRowId, setEditingRowId] = useState(null);
  const [editingRowData, setEditingRowData] = useState(null);
  const {
    control,
    reset,
    getValues,
    watch,
    setValue,
    formState: { isValid },
  } = useForm({
    // resolver: yupResolver(ContractBudgetSchema),
    mode: 'all',
  });
  const firstColumnInputRef = useRef(null);
  const [stateError, setStateError] = useState(false);
  const [loader, setLoader] = useState(false);
  const [selectedSI, setSelectedSI] = useState(null)
  const [editingRowIndex, setEditingRowIndex] = useState(null);
  const selectedSalesId = watch('salesOrderId');
  const [rowState, setRowState] = useState({
    editingRowId: null,
    newRowId: null,
    isCreatingRow: false,
  });

  const handleCancel = (row) => {
    if (
      row?.original?.financialTransactionReceiptItemId === rowState?.newRowId
    ) {
      setRows((prevRows) => {
        if (prevRows?.length > 0) {
          return prevRows?.slice(0, -1);
        }
        return prevRows;
      });

      setRowState((prevState) => ({
        ...prevState,
        newRowId: null,
        isNew:false,
        isCreatingRow: false,
      }));
    } else {
      setEditingRowId(null);
    }
    reset();
  };

  const handleEdit = (row) => {
    reset();
    if (rowState?.newRowId) {
      setRows((prevRows) => {
        if (prevRows?.length > 0) {
          return prevRows?.slice(0, -1);
        }
        return prevRows;
      });
      setRowState((prevState) => ({
        ...prevState,
        newRowId: null,
        isNew: false,
        isCreatingRow: false,
      }));
      // setEditingRowIndex(null);
    }
    setEditingRowId(row?.financialTransactionReceiptItemId);
    Object.entries({
      ...row,
    }).forEach(([key, value]) => {
      setValue(key, value);
    });
    setEditingRowData({ ...row });
  };

  useHotkeys(
    'Enter',
    () => handleAddNewRow(),
  );
  const [billingValues, setBillingValues] = useState([
    { name: 'Quantity', value: 0 },
    { name: 'Invoiced Amount', value: 0 },
    { name: 'Received Amount', value: 0 },
    { name: 'Pending Amount', value: 0 },
  ]);


  useEffect(() => {
    setBillingValues([
      { name: 'Quantity', value: rows?.length },
      {
        name: 'Invoiced Amount (AED)',
        value: rows?.reduce((acc, row) => acc + Number(row?.totalAmount), 0),
      },
      {
        name: 'Received Amount (AED)',
        value: rows?.reduce((acc, row) => acc + Number(row?.receivedAmount), 0),
      },
      {
        name: 'Pending Amount (AED)',
        value: rows?.reduce((acc, row) => acc + Number(row?.pendingAmount), 0),
      },
    ]);
  }, [rows]);

  useHotkeys(
    'Tab',
    (event) => {
      if (event.key !== 'Tab') return;
      if (stateError) {
        event.preventDefault();
        return;
      }
      const totalRows = rows.length;
      const nextRowIndex = editingRowIndex + 1;

      if (
        nextRowIndex < totalRows &&
        stateError === false &&
        loader === false
      ) {
        handleEdit(rows[nextRowIndex]);
        setEditingRowIndex(nextRowIndex);
      }
    },
    [editingRowIndex, rows, stateError, loader, isValid]
  );

  useEffect(() => {
    if (editingRowIndex !== null && firstColumnInputRef.current && !isLoading) {
      firstColumnInputRef.current.focus();
    }
  }, [editingRowIndex, isLoading]);

  const previousValues = useRef(getValues());



  const handleBlur = () => {
    const currentValues = getValues();
    const salesInvoiceId = getValues('salesInvoiceId');

    if (!salesInvoiceId) {
      firstColumnInputRef.current.focus();
      return;
    }

    const hasChanges = Object.keys(currentValues).some(
      (key) => currentValues[key] !== previousValues.current[key]
    );

    if ((hasChanges && isValid) || (hasChanges && editingRowId)) {
      apiCallAsync(currentValues); // Call the API with the new values
      previousValues.current = currentValues; // Update previous values
    }
  };

  const apiCallAsync = async (values) => {
    setLoader(true);
    const convertedValues = {
      ...values,
      receivedAmount: Number(values?.receivedAmount) || 0,
      financialTransactionReceiptId: Number(id),
    };

    const updatedCombinedData = {
      ...values,
      receivedAmount: Number(values?.receivedAmount) || 0,
      financialTransactionReceiptId: Number(id),
      status: 2,
    };

    if (editingRowId === null) {
      await crud.create(
        'financialtransactionreceiptitems',
        convertedValues,
        (err, res) => {
          if (res?.status === 201) {
            setEditingRowId(res?.data?.financialTransactionReceiptItemId);
          } else {
          }
        }
      );
    } else {
      await crud.update(
        'financialtransactionreceiptitems',
        editingRowId,
        updatedCombinedData,
        (err, res) => {
          if (res?.status === 200) {
            if (rows?.some((row) => row?.isNew)) {
              const updatedRow = res?.data;
              const updatedRows = [...rows];
              updatedRows[editingRowIndex] = {
                ...updatedRow,
                isEditable: true,
                isNew: true,
              };
              setRows(updatedRows);
              setStateError(false);
              setLoader(false);
            } else {
              setStateError(false);
              setLoader(false);
              const updatedRow = res?.data;
              const updatedRows = [...rows];
              updatedRows[editingRowIndex] = updatedRow;
              setRows(updatedRows);
            }
          } else {
            setStateError(true);
          }
        }
      );
    }
  };
  const handleAddNewRow = () => {
    const newId = 'new_row_' + Date.now();
    reset();
    setEditingRowData(null);
    setEditingRowId(null);
    setSelectedSI(null);
    setRowState({
      isNew: true,
      newRowId: newId,
      isCreatingRow: true,
    });
    setEditingRowIndex(
      editingRowIndex === rows.length ? editingRowIndex + 1 : rows.length
    );
    setRows((prevRows) => [
      ...prevRows,
      {
        financialTransactionReceiptItemId: newId,
        financialTransactionReceiptId: id,
        salesInvoiceId: '',
        chartOfAccountId: '',
        receivedAmount: '',
        isEditable: true,
        isNew: true,
      },
    ]);
    reset();
  };

  //final Step Api Call
  const handleBlurAndFetch = async () => {
    setLoader(true);
    const values = getValues();

    const receivedAmount = Number(values?.receivedAmount);
    const invoicedAmount = Number(values?.totalAmount) || 0; // Ensure you have this value

    const pendingAmount = invoicedAmount - receivedAmount; // Calculate pending amount

    const updateCombinedData = {
      ...values,
      receivedAmount: receivedAmount || 0,
      pendingAmount: pendingAmount || 0, // Include pending amount in the update
      financialTransactionReceiptId: Number(id),
      status: 2,
    };
    await crud.update(
      'financialtransactionreceiptitems',
      editingRowId,
      updateCombinedData,
      (err, res) => {
        if (res?.status === 200) {
          const totalRows = rows.length -1;
          if (editingRowIndex === totalRows || rowState?.isNew===true) {
            const updatedRow = res?.data;
            const updatedRows = [...rows];
            updatedRows[editingRowIndex] = {
              ...updatedRow,
              isEditable: true,
              isNew: true,
            };
            setRows(updatedRows);
            handleAddNewRow();
          } else {
            const updatedRow = res?.data;
            const updatedRows = [...rows];
            updatedRows[editingRowIndex] = {
              ...updatedRow,
              isEditable: false,
              isNew: false,
            };

            setRows(updatedRows);
            setStateError(false);
            setLoader(false);
          }
        } else {
          setStateError(true);
        }
      }
    );
  };

  const handleDeleteBudget = async (view) => {
    await crud.remove(
      'financialtransactionreceiptitems',
      view?.financialTransactionReceiptItemId,
      (err, res) => {
        if (res?.status === 204) {
          fetchData();
          toast.success('Receipt Item Deleted Successfully');
        } else {
        }
      }
    );
  };


  const handleSelectedSalesInvoice = (data) => {
    crud.getSingle('salesinvoices', data?.salesInvoiceId, (err, res) => {
      if (res?.status === 200) {
        setSelectedSI({
          projectName: data?.projectName,
          contractName: data?.contractName,
        });
      }
    });
  };

  const ActionData = [
    {
      name: 'Delete',
      icon: Icons.delete,
      onClick: (props) => handleDeleteBudget(props),
      danger: true,
    },
  ];

  const columns = [
    {
      accessorKey: 'salesInvoiceId',
      header: 'Sales Invoice',
      enableEditing: true,
      Cell: ({ row }) => {
        const isNewRow =
          row?.original?.financialTransactionReceiptItemId ===
          rowState?.newRowId;
        const isEditing =
          editingRowId === row?.original?.financialTransactionReceiptItemId ||
          isNewRow;
        return isEditing ? (
          <FmSearchableSelect
            name='salesInvoiceId'
            control={control}
            apiUrl={`salesinvoices`}
            valueField={'salesInvoiceId'}
            headerField={['Code']}
            labelField={['salesInvoiceCode']}
            showField={['salesInvoiceCode']}
            extraQueryParams={["isAllInvoices", "clientId", "invoiceStatusId"]}
            extraQueryParamValues={["false", clientId, salesInvoiceStatusEnum?.Acknowledge]}
            onChangeProp={handleSelectedSalesInvoice}
            inputRef={firstColumnInputRef}
            label={'Sales Invoice'}
            onBlur={handleBlur}
            defaultValue={{
              salesInvoiceId: editingRowData?.salesInvoiceId,
              salesInvoiceCode: editingRowData?.salesInvoiceCode,
            }}
          />
        ) : (
          row?.original?.salesInvoiceCode || ''
        );
      },
    },
    {
      accessorKey: 'projectName',
      header: 'Project',
      size: 250,
      Cell: ({ row }) => {
        const isNewRow =
          row?.original?.financialTransactionReceiptItemId ===
          rowState?.newRowId;
        const isEditing =
          editingRowId === row?.original?.financialTransactionReceiptItemId ||
          isNewRow;
        return isEditing ? (
          <ViewField
            label="Project Name"
            title={selectedSI?.projectName || row?.original?.projectName}
          />
        ) : (
          <div className='flex flex-col w-full'>
            <div className='flex items-center gap-1'>
              <span>Code:</span>
              {row?.original?.projectCode}
            </div>
            <div className='font-semibold'>{row?.original?.projectName}</div>
          </div>
        );
      },
    },
    {
      accessorKey: 'contractName',
      header: 'Contract',
      size: 200,
      Cell: ({ row }) => {
        const isNewRow =
          row?.original?.financialTransactionReceiptItemId ===
          rowState?.newRowId;
        const isEditing =
          editingRowId === row?.original?.financialTransactionReceiptItemId ||
          isNewRow;
        return isEditing ? (
          <ViewField
            label="Contract Name"
            title={selectedSI?.contractName || row?.original?.contractName}
          />
        ) : (
          <div className='flex flex-col w-full'>
            <div className='flex items-center gap-1'>
              <span>Code:</span>
              {row?.original?.contractCode}
            </div>
            <div className='font-semibold'>{row?.original?.contractName}</div>
          </div>
        );
      },
    },
    {
      accessorKey: 'chartOfAccountId',
      header: 'Accounts',
      enableEditing: true,
      Cell: ({ row }) => {
        const isNewRow =
          row?.original?.financialTransactionReceiptItemId ===
          rowState?.newRowId;
        const isEditing =
          editingRowId === row?.original?.financialTransactionReceiptItemId ||
          isNewRow;
        return isEditing ? (
          <FmSearchableSelect
            name='chartOfAccountId'
            control={control}
            apiUrl='chartofaccounts'
            valueField='chartOfAccountId'
            labelField={['chartOfAccountCode', 'chartOfAccountName']}
            showField={['chartOfAccountCode', 'chartOfAccountName']}
            label='Accounts'
            onBlur={handleBlur}
            defaultValue={{
              chartOfAccountId: editingRowData?.chartOfAccountId,
              chartOfAccountName: editingRowData?.chartOfAccountName,
            }}
          />
        ) : (
          row?.original?.chartOfAccountName || ''
        );
      },
    },
    {
      accessorKey: 'totalAmount',
      header: 'Invoiced Amount',
      enableEditing: true,
      size: 150,
      Cell: ({ row }) => {
        return <CurrencyView value={row?.original?.totalAmount} />;
      },
    },
    {
      accessorKey: 'receivedAmount',
      header: 'Received Amount',
      enableEditing: true,
      size: 150,
      Cell: ({ row }) => {
        const isNewRow =
          row?.original?.financialTransactionReceiptItemId ===
          rowState?.newRowId;
        const isEditing =
          editingRowId === row?.original?.financialTransactionReceiptItemId ||
          isNewRow;
        return isEditing ? (
          <FmCurrencyTextField
            name='receivedAmount'
            label='Received Amount'
            control={control}
            onBlur={handleBlurAndFetch}
            onChange={(e) => {
              const value = e.target.value;
              const invoicedAmount = row?.original?.totalAmount || 0;
              const pendingAmount = invoicedAmount - value;
              setEditingRowData((prev) => ({
                ...prev,
                pendingAmount: pendingAmount,
              }));
              setValue('pendingAmount', pendingAmount);
            }}
          />
        ) : (
          <CurrencyView value={row?.original?.receivedAmount} />
        );
      },
    },
    {
      accessorKey: 'pendingAmount',
      header: 'Pending Amount',
      enableEditing: true,
      size: 150,
      Cell: ({ row }) => {
        const isNewRow =
          row?.original?.financialTransactionReceiptItemId ===
          rowState?.newRowId;
        const isEditing =
          editingRowId === row?.original?.financialTransactionReceiptItemId ||
          isNewRow;
        return isEditing ? (
          <CurrencyView value={editingRowData?.pendingAmount} />
        ) : (
          <CurrencyView value={row?.original?.pendingAmount} />
        );
      },
    },

    {
      header: 'Action',
      enableColumnPinning: true,
      enableEditing: false,
      enableSorting: false,
      size: 150,
      muiTableBodyCellProps: {
        sx: {
          borderLeft: '1px solid',
          borderColor: 'border.main',
        },
      },
      Cell: ({ row, table }) => {
        const isNewRow =
          row?.original?.financialTransactionReceiptItemId ===
          rowState?.newRowId;
        const isEditing =
          editingRowId === row?.original?.financialTransactionReceiptItemId ||
          isNewRow;

        // Check if the current row is the last row in the table
        const isLastRow = row.index === table.getRowModel().rows.length - 1;

        return (
          <Box className='flex gap-2'>
            {isEditing ? (
              <>
                <Button
                  onClick={() => handleCancel(row)}
                  variant='contained'
                  color='error'
                  className='px23 py-0.5'
                >
                  Close
                </Button>
              </>
            ) : (
              <Box className='flex gap-2'>
                <TableActionButtons
                  Actions={ActionData?.map((action) => ({
                    ...action,
                    onClick: () => action.onClick(row?.original),
                  }))}
                />
                {isLastRow && (
                  <Button
                    variant='contained'
                    color='primary'
                    className='px23 py-0.5'
                    onClick={() => {
                      setEditingRowIndex(row?.index + 1);
                      handleAddNewRow();
                    }}
                  >
                    Add Row
                  </Button>
                )}
              </Box>
            )}
          </Box>
        );
      },
    },
  ];



  return (
    <Box className='flex  flex-col gap-1'>
      <GlassCard className='h-[450px]'>
        <FmMRTDataTable
          rows={rows}
          columns={columns}
          onCreateRow={handleAddNewRow}
          isCreatingRow={rowState?.isCreatingRow}
          enableRowSelection={false}
          rowCount={tableRecordCounts}
          editingRow={editingRowId}
          fetchData={fetchData}
          pagination={pagination}
          sorting={sorting}
          columnFilters={columnFilters}
          globalFilter={globalFilter}
          setPagination={setPagination}
          setSorting={setSorting}
          setColumnFilters={setColumnFilters}
          setGlobalFilter={setGlobalFilter}
          isLoading={isLoading}
          setSearchKeyword={setSearchKeyword}
          searchKeyword={searchKeyword}
          handleRowDoubleClick={(row) => {
            handleEdit(row?.original);
            setEditingRowIndex(row?.index);
          }}
          muiTableContainerProps={{
            sx: {
              maxHeight: '100%',
              flex: 1,
            },
          }}
          showSerialNumber={true}
        />
      </GlassCard>
      <GlassCard className='p-2 bg-teal-700 border-none uppercase font-semibold text-white'>
        <Stack
          direction='row'
          flexWrap='wrap'
          spacing={3}
          justifyContent='space-between'
        >
          {billingValues?.map((item, i) => {
            return (
              <Box key={i} className='flex justify-between items-center'>
                <Box>{item.name}</Box>
                <Box className='font-bold ms-3'>{item.value}</Box>
              </Box>
            );
          })}
        </Stack>
      </GlassCard>
    </Box>
  );
};

export default ARReceiptsItemList;
