import React, { useRef } from 'react';
import { Box, TextField } from '@mui/material';
import { LocalizationProvider } from '@mui/x-date-pickers/LocalizationProvider';
import { AdapterDayjs } from '@mui/x-date-pickers/AdapterDayjs';
import { DatePicker } from '@mui/x-date-pickers/DatePicker';
import { Icon } from '@iconify/react/dist/iconify.js';

const MuiDatePickerOne = ({
  name,
  TextRequired = false,
  TextFieldVariant = 'filled',
  TextFieldClassName = '',
  value,
  onChange,
  views,
  readOnly = false,
  ...props
}) => {
  const anchorElRef = useRef(null);

  return (
    <Box ref={anchorElRef}>
      <LocalizationProvider dateAdapter={AdapterDayjs}>
        <DatePicker
          {...props}
          value={value}
          onChange={(newValue) => {
            onChange(newValue);
          }}
          readOnly={readOnly}
          views={views}
          slots={{
            textField: (params) => (
              <TextField
                {...params}
                name={name}
                fullWidth
                variant={TextFieldVariant}
                required={TextRequired}
                className={TextFieldClassName}
              />
            ),
            openPickerIcon: () => (
              <Icon icon='stash:data-date-light' width='24' height='24' />
            ),
          }}
          slotProps={{
            popper: {
              anchorEl: () => anchorElRef.current,
            },
          }}
        />
      </LocalizationProvider>
    </Box>
  );
};

export default MuiDatePickerOne;
