import React, { useEffect, useMemo } from 'react';
import DashboardLayout from '../../../../../components/DashboardLayout.tsx';
import AddInternalInvoicesAR from './../Create/AddInternalInvoicesAR.tsx';
import BackToButton from '../../../../../components/small/BackToButton.tsx';
import { useNavigate } from 'react-router-dom';
import { Box } from '@mui/material';
import { useSelector } from 'react-redux';
import { findNavByName } from '../../../../../utils/navUtils.ts';

const EditInternalInvoiceAR = () => {
  const navigate = useNavigate();
  const menu = useSelector((state) => state?.menu?.items);
  useEffect(() => {
    document.title = 'Edit Internal Invoices | Accounts Receivable | Financial';
  }, []);
  const SubMenu = useMemo(() => {
    return menu?.map((item) => item?.menus?.flat()).flat() || [];
  }, [menu]);

  // Use findNavByName to get the "Accounts Receivable" menu or its child menus
  const result = useMemo(
    () => findNavByName(SubMenu, 'Financial', 'Accounts Receivable'),
    [SubMenu]
  );

  return (
    <DashboardLayout
      title='Edit Internal Invoices'
      navigation
      actionButtons={
        <BackToButton
          title='Back to List'
          onClick={() =>
            navigate('/financial/accounts-receivable/internal-invoice')
          }
          className='px-0 py-0'
        />
      }
      titleReverse
      menu={result}
    >
      <Box className=' flex flex-col gap-2 h-full'>
        <AddInternalInvoicesAR editMode />
      </Box>
    </DashboardLayout>
  );
};

export default EditInternalInvoiceAR;
