import { Box, Stack, Divider } from '@mui/material';
import React from 'react';
import Breadcums from '../helpers/Breadcums';

const Title = ({ title, children, titleReverse, navDual }) => {
  return (
    <Box
      className={` ${
        titleReverse && 'flex-row-reverse'
      } flex flex-row items-center justify-between`}
      sx={{
        color: 'text.main',
        borderBottom: '1px solid',
        borderColor: 'border.main',
        padding: '0px 10px',
        minHeight: 35,
      }}
    >
      {/* flex */}
      <Stack
        spacing={2}
        direction='row'
        divider={<Divider orientation='vertical' flexItem />}
      >
        <Box color={'text.dark'} sx={{ fontWeight: 600 }}>
          {title}
        </Box>
        <Box className='ms-4'>
          <Breadcums />
        </Box>
      </Stack>

      {navDual && <Box>{navDual}</Box>}

      {/* flex */}
      <Box className='flex flex-row items-center gap-3'>{children}</Box>
    </Box>
  );
};

export default Title;
