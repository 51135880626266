import React, { useMemo } from 'react'
import DashboardLayout from '../../../../components/DashboardLayout.tsx'
import { useSelector } from 'react-redux';
import { findNavByName } from '../../../../utils/navUtils.ts';
import { Box } from '@mui/material';
import { t } from 'i18next';
import { CreateButton } from '../../../../components/small/Buttons.jsx';
import { useNavigate } from 'react-router-dom';
import Crud_Service from '../../../../apis/CrudService.jsx';
import FmMRTDataTable from '../../../../components/FmMRTDataTable.tsx';
import useTableLogic from '../../../../components/helpers/MRTUseTableLogic.tsx';
import GlassCard from '../../../../components/small/GlassCard.tsx';
import TableActionButtons from '../../../../components/_form/TableActionButtons.jsx';
import Icons from '../../../../utils/Icon.jsx';
import { toast } from 'react-toastify';
import FmViewDateField from '../../../../components/_mui/FmViewDateField.tsx';

const InventoryTransferList = () => {
    const {
        rows,
        isLoading,
        pagination,
        sorting,
        columnFilters,
        globalFilter,
        tableRecordCounts,
        setPagination,
        setSorting,
        setColumnFilters,
        setGlobalFilter,
        fetchData,
        setSearchKeyword,
        searchKeyword,
    } = useTableLogic(`inventoryitemtransfers`);
    const crud = new Crud_Service();
    const navigate = useNavigate();
    const menu = useSelector((state) => state?.menu?.items);
    const SubMenu = useMemo(() => {
        return menu?.map((item) => item?.menus?.flat()).flat() || [];
    }, [menu]);

    const result = useMemo(
        () => findNavByName(SubMenu, "Procurement", "Inventory"),
        [SubMenu]
    );
    const handleDelete = async (view) => {
        await crud.remove('inventoryitemtransfers', view?.inventoryItemTransferId, (err, res) => {
            if (res?.status === 204) {
                toast.success('Inventory Transfer Deleted Successfully');
                fetchData();
            }
        });
    };
    const ActionData = [
        {
            name: 'Edit',
            icon: Icons.edit,
            onClick: (_props) => {
                navigate(`${location.pathname}/edit/${_props?.inventoryItemTransferId}`);
            },
        },
        {
            name: 'Delete',
            icon: Icons.delete,
            onClick: (props) => handleDelete(props),
            danger: true,
        },
    ];
    const columns = [
        {
            header: 'Actions',
            size: 80,
            minSize: 80,
            Cell: ({ row }) => (
                <Box className='flex gap-2'>
                    <TableActionButtons
                        Actions={ActionData?.map((action) => ({
                            ...action,
                            onClick: () => action.onClick(row?.original),
                        }))}
                    />
                </Box>
            ),
        },
        {
            accessorKey: 'itemName',
            header: 'Item Name',
            size: 150,
        },
        {
            accessorKey: 'fromStoreLocationName',
            header: 'From Store Location',
            size: 150,
        },
        {
            accessorKey: 'toStoreLocationName',
            header: 'To Store Location',
            size: 150,
        },
        {
            accessorKey: 'quantityToTransfer',
            header: 'Quantity To Transfer',
            size: 150,
        },
        {
            accessorKey: 'transferDate',
            header: 'Transfer Date',
            size: 150,
            renderCell: (params) => {
                return <>
                    <FmViewDateField value={params?.value} />
                </>;
            },
        },
        {
            accessorKey: 'transferredByName',
            header: 'Transferred By',
            size: 150,
        },
    ]
    return (
        <DashboardLayout
            title={t('Inventory Transfer')}
            hasSubmenu={true}
            menu={result}
            actionButtons={
                <>
                    <Box className='flex flex-row items-center whitespace-nowrap gap-3'>
                        <CreateButton
                            name='New'
                            onClick={() =>
                                navigate(
                                    '/procurement/inventory-management/inventory-transfer/create'
                                )
                            }
                        />
                    </Box>
                </>
            }
            hasSubmenu
            menu={result}
        >
            <GlassCard className='h-full-css'>
                <FmMRTDataTable
                    columns={columns}
                    enableRowSelection={false}
                    rows={rows}
                    rowCount={tableRecordCounts}
                    pagination={pagination}
                    sorting={sorting}
                    fetchData={fetchData}
                    columnFilters={columnFilters}
                    globalFilter={globalFilter}
                    setPagination={setPagination}
                    setSorting={setSorting}
                    setColumnFilters={setColumnFilters}
                    setGlobalFilter={setGlobalFilter}
                    isLoading={isLoading}
                    setSearchKeyword={setSearchKeyword}
                    searchKeyword={searchKeyword}
                    muiTableContainerProps={{
                        sx: {
                            maxHeight: '100%',
                            flex: 1,
                        },
                    }}
                    showSerialNumber={true}
                />
            </GlassCard>
        </DashboardLayout>
    )
}

export default InventoryTransferList
