import React, { useEffect, useState } from 'react';
import GlassCard from '../../../components/small/GlassCard.tsx';
import MRTExpandTableStyle from '../../../components/table/MRTExpandTableStyle.tsx';
import { Box } from '@mui/material';
import FmMRTDataTable from '../../../components/FmMRTDataTable.tsx';
import useTableLogic from '../../../components/helpers/MRTUseTableLogic.tsx';
import dayjs, { Dayjs } from 'dayjs';
import DataGridHeaderTooltip from '../../../components/datagrid/DataGridHeaderTooltip.tsx';
import TableAvatar from '../../../components/_form/TableAvatar.jsx';
import FmDateTableFilter from '../../../components/filter/FmDateTableFilter.tsx';
import FmViewDateField from '../../../components/_mui/FmViewDateField.tsx';
import ViewTwoline from '../../../components/view/ViewTwoline.tsx';
import { StatusBadge } from '../../../components/_form/FormElements.jsx';
import IconWithTooltip from '../../../components/elements/IconWithTooltip.tsx';
import { Icon } from '@iconify/react/dist/iconify.js';

const LeaveAccruals = () => {
  const [selectedDate, setSelectedDate] = useState<Dayjs>(dayjs());
  const currentMonthGet = dayjs().startOf('month');


  const {
    rows,
    rowCount,
    isLoading,
    pagination,
    sorting,
    columnFilters,
    globalFilter,
    tableRecordCounts,
    setPagination,
    setSorting,
    setColumnFilters,
    setGlobalFilter,
    fetchData,
    setSearchKeyword,
    searchKeyword,
  } = useTableLogic(
    'employeeaccrualsleaves',
    ['fromDate'],
    [selectedDate?.format('YYYY-MM-DD')]
  );

  useEffect(() => {
    fetchData();
  }, [selectedDate]);

  const columns = [
    {
      accessorKey: 'employeeName',
      header: 'Employee Name',
      Cell: ({ cell, row }) => (
        <TableAvatar
          name={cell.getValue()}
          desc={`Code : ${row?.original?.employeeCode}`}
        />
      ),
      size: 300,
    },

    {
      accessorKey: 'joiningOrReturnFromLeaveDate',
      header: (
        <DataGridHeaderTooltip
          name='Joining or RFL'
          title='Joining Or Return From Leave'
        />
      ),
      customDate: true,
      size: 120,
      Cell: ({ renderedCellValue, row }) => (
        <Box>
          <Box className='flex gap-2 items-center'>
            <IconWithTooltip
              icon={
                row?.original?.joiningOrReturnFromLeaveDateType === 1 ? (
                  <Icon
                    icon='fluent:people-add-32-light'
                    width='24'
                    height='24'
                  />
                ) : row?.original?.joiningOrReturnFromLeaveDateType === 2 ? (
                  <Icon
                    icon='proicons:airplane-landing'
                    width='24'
                    height='24'
                  />
                ) : null
              }
              title={row?.original?.joiningOrReturnFromLeaveDateTypeName}
            />
            <ViewTwoline
              data={[
                {
                  name: false,
                  value: <FmViewDateField value={renderedCellValue} />,
                },
              ]}
            />
          </Box>
        </Box>
      ),
    },

    {
      accessorKey: 'startDate',
      header: 'Date',
      customDate: true,
      size: 180,
      Cell: ({ row }) => (
        <ViewTwoline
          data={[
            {
              name: 'From:',
              value: <FmViewDateField value={row.original['startDate']} />,
              className: 'text-green-600',
            },
            {
              name: 'To:',
              value: <FmViewDateField value={row.original['endDate']} />,
              className: ' text-red-600',
            },
          ]}
        />
      ),
    },

    {
      accessorKey: 'airTicketPerDay',
      header: 'Air Ticket (AED)',
      size: 140,
      Cell: ({ renderedCellValue, row }) => (
        <ViewTwoline
          data={[
            {
              name: 'Per Day:',
              value: renderedCellValue,
              className: ' w-full',
            },
            {
              name: 'Total:',
              value: row?.original?.airTicketTotal,
              className: ' w-full  font-semibold',
            },
            {
              name: 'Cumulative:',
              value: row?.original?.cumulativeAirTicketTotal,
              className: ' w-full  font-semibold',
            },
          ]}
          rounded
        />
      ),
      muiTableBodyCellProps: {
        className: 'bg-yellow-50',
      },
    },

    {
      accessorKey: 'leavePerDay',
      header: 'Leave',
      size: 120,
      muiTableBodyCellProps: {
        className: 'bg-sky-50',
      },
      Cell: ({ renderedCellValue, row }) => (
        <ViewTwoline
          data={[
            {
              name: 'Per Day:',
              value: renderedCellValue,
            },
            {
              name: 'Total:',
              value: row?.original?.leaveTotal,
              className: 'font-semibold',
            },
            {
              name: 'Cumulative:',
              value: row?.original?.cumulativeLeaveTotal,
              className: ' w-full  font-semibold',
            },
          ]}
          rounded
        />
      ),
    },

    {
      accessorKey: 'lastGenerationDate',
      header: 'Last Generation',
      customDate: true,
      size: 160,
      Cell: ({ renderedCellValue, row }) => (
        <ViewTwoline
          data={[
            {
              name: false,
              value: renderedCellValue,
              className: 'font-semibold',
            },
            {
              name: false,
              value: `Days - ${row?.original?.lastGenerationDays}`,
            },
          ]}
        />
      ),
    },

    {
      accessorKey: 'runningStatusName',
      header: 'Running Status',
      size: 150,
      Cell: ({ renderedCellValue }) => (
        <StatusBadge title={renderedCellValue} type={'green'} />
      ),
    },
  ];

  const handlePrevMonth = () => {
    setSelectedDate((prevDate) => prevDate.subtract(1, 'month'));
  };

  const handleNextMonth = () => {
    setSelectedDate((prevDate) => prevDate.add(1, 'month'));
  };

  const handleDateOnChange = (e) => {
    setSelectedDate(e);
  };

  return (
    <GlassCard className='overflow-hidden h-full-css'>
      <MRTExpandTableStyle enableFullHeight>
        <Box>
          <FmMRTDataTable
            columns={columns}
            enableRowSelection={false}
            rows={rows}
            rowCount={tableRecordCounts}
            pagination={pagination}
            sorting={sorting}
            columnFilters={columnFilters}
            globalFilter={globalFilter}
            setPagination={setPagination}
            setSorting={setSorting}
            fetchData={fetchData}
            muiTableContainerProps={{
              sx: {
                maxHeight: '100%',
                flex: 1,
              },
            }}
            setColumnFilters={setColumnFilters}
            setGlobalFilter={setGlobalFilter}
            isLoading={isLoading}
            setSearchKeyword={setSearchKeyword}
            searchKeyword={searchKeyword}
            renderTopToolbarCustomActionsChildrens={
              <FmDateTableFilter
                onHandleNext={handleNextMonth}
                onHandlePrev={handlePrevMonth}
                onChange={(e) => handleDateOnChange(e)}
                value={selectedDate}
              />
            }
          // showSerialNumber={true}
          />
        </Box>
      </MRTExpandTableStyle>
    </GlassCard>
  );
};

export default LeaveAccruals;
