import React, { useEffect, useMemo } from 'react';
import DashboardLayout from '../../../components/DashboardLayout.tsx';
import { Box } from '@mui/material';
import { CreateButton } from '../../../components/small/Buttons.jsx';
import { useNavigate } from 'react-router-dom';
import ListVendorDocuments from './ListVendorDocuments.tsx';
import { useSelector } from 'react-redux';
import { findNavByName } from '../../../utils/navUtils.ts';

const VendorDocuments = (props) => {
  const { type } = props;
  const navigate = useNavigate();
  useEffect(() => {
    document.title = 'Vendor Document | Financial';
  }, []);

  const menu = useSelector((state) => state?.menu?.items);

  const SubMenu = useMemo(() => {
    return menu?.map((item) => item?.menus?.flat()).flat() || [];
  }, [menu]);

  // Use findNavByName to get the "Accounts Receivable" menu or its child menus
  const result = useMemo(
    () => findNavByName(SubMenu, 'Financial', 'Accounts Payable'),
    [SubMenu]
  );

  return (
    <DashboardLayout
      title={type === 1 ? 'Vendor Document' : 'Supplier Invoice'}
      actionButtons={
        type === 1 && (
          <Box display='flex' flexDirection='row' alignItems='center' gap={3}>
            <Box>
              <CreateButton name='Create' onClick={() => navigate('create')} />
            </Box>
          </Box>
        )
      }
      hasSubmenu={type !== 1}
      menu={result}
    >
      <Box className='flex flex-col h-full gap-2'>
        <ListVendorDocuments type={type} />
      </Box>
    </DashboardLayout>
  );
};

export default VendorDocuments;
