import { Box, Grid } from '@mui/material';
import React, { useEffect, useMemo, useState } from 'react';
import { useForm } from 'react-hook-form';
import { useTranslation } from 'react-i18next';
import Icons from '../../../utils/Icon.jsx';
import apiInstance from '../../../apis/ApiService.jsx';
import Crud_Service from '../../../apis/CrudService';
import ActionButtons from '../../../components/_form/ActionButtons.tsx';
import { StatusBadge } from '../../../components/_form/FormElements';
import TableActionButtons from '../../../components/_form/TableActionButtons';
import TableSearch from '../../../components/_form/TableSearch.jsx';
import FmAutoComplete from '../../../components/_mui/FmAutoComplete.tsx';
import FmSearchableSelect from '../../../components/_mui/FmSearchableSelect.tsx';
import FmTextField from '../../../components/_mui/FmTextField.tsx';
import MuiDialogOne from '../../../components/_mui/MuiDialogOne';
import DashboardLayout from '../../../components/DashboardLayout.tsx';
import DataTable from '../../../components/DataTable.tsx';
import CommonView from '../../../components/helpers/CommonView.jsx';
import DataTableToolbarButtons from '../../../components/helpers/DataTableToolbarButtons.tsx';
import DynamicViewFields from '../../../components/helpers/DynamicViewFields.jsx';
import useCommonFetchApi from '../../../components/helpers/useCommonFetchApi.tsx';
import useLoader from '../../../components/helpers/UseLoader.tsx';
import useTableLogic from '../../../components/helpers/UseTableLogic.tsx';
import CommonLoader from '../../../components/page/CommonLoader';
import PageHeader from '../../../components/PageHeader';
import { CreateButton } from '../../../components/small/Buttons';
import GlassCard from '../../../components/small/GlassCard.tsx';
import { statusOptions } from '../../../utils/CommonVariables.tsx';
import { findNavByName } from '../../../utils/navUtils.ts';
import { useSelector } from 'react-redux';
import { toast } from 'react-toastify';

const Emirates = (props) => {
  const { type } = props;
  const [openCreate, setOpenCreate] = React.useState(false);
  const [openView, setOpenView] = React.useState(false);
  const [loading, setLoader] = useState(false);
  const crud = new Crud_Service();
  const [selectedRow, setSelectedRow] = useState();
  const [viewDetails, setViewdetails] = useState(false);
  const [openEdit, setOpenEdit] = React.useState(false);
  const [editData, setEditData] = useState(null);
  const menu = useSelector((state) => state?.menu?.items);
  const [editingRowData, setEditingRowData] = useState(null);
  const {
    createLoading,
    startCreateLoading,
    stopCreateLoading,
    editLoading,
    startEditLoading,
    stopEditLoading,
  } = useLoader();
  const {
    rows,
    pageSize,
    pageNumber,
    setSearchKeyword,
    isLoading,
    tableRecordCounts,
    searchKeyword,
    handlePaginationModelChange,
    handleSortModelChange,
    fetchData,
  } = useTableLogic('emirates');
  const { handleSubmit, setValue, control, reset } = useForm();
  useTranslation();

  useEffect(() => {
    document.title = `HRMS-Emirates`;
  }, []);

  const downloadEmirates = async () => {
    const downloadUrl = await apiInstance.getFiles('emirates/exportexcel');

    const link = document.createElement('a');
    link.href = downloadUrl;
    link.setAttribute('download', 'emirates.xlsx');
    document.body.appendChild(link);
    link.click();
    document.body.removeChild(link);
  };

  const handleSubmitEmirate = async (values) => {
    startCreateLoading();
    await crud.create('emirates', values, (_err, res) => {
      if (res?.status === 201) {
        stopCreateLoading();
        setOpenCreate(false);
        fetchData();
        reset();
        toast.success('Emirate Created Successfully');
      } else {
        stopCreateLoading();
      }
    });
  };

  const handleCreateClickOpen = () => {
    reset();
    setOpenCreate(true);
  };
  const handleCreateClose = () => {
    reset();
    setOpenCreate(false);
  };
  const handleViewClickOpen = async (props) => {
    setOpenView(true);
    setLoader(true);
    setViewdetails(props);
    await crud.getSingle('emirates', props?.emirateId, (_err, res) => {
      if (res?.status === 200) {
        setSelectedRow(res?.data);
        setLoader(false);
      }
    });
  };
  const handleViewClose = () => {
    setOpenView(false);
  };

  const handleEditOpen = async (props) => {
    setOpenEdit(true);
    setLoader(true);
    await crud.getSingle('emirates', props?.emirateId, (_err, res) => {
      if (res?.status === 200) {
        setEditingRowData(res.data);
        Object.entries({
          ...res?.data,
        }).forEach(([key, value]) => {
          setValue(key, value);
        });
        setEditData(res?.data);
        setLoader(false);
      } else {
        setLoader(true);
      }
    });
  };
  const handleEditEmirates = async (values) => {
    startEditLoading();
    await crud.update('emirates', editData?.emirateId, values, (_err, res) => {
      if (res?.status === 200) {
        stopEditLoading();
        setOpenEdit(false);
        fetchData();
        toast.success('Emirate Updated Successfully');
      } else {
        stopEditLoading();
      }
    });
  };

  const handleDelete = async (props) => {
    await crud.remove('emirates', props?.emirateId, (_err, res) => {
      if (res?.status === 204) {
        fetchData();
        toast.success('Emirate Deleted Successfully');
      }
    });
  };

  const counters = [
    {
      name: 'All',
      count: tableRecordCounts?.total || 0,
    },
    {
      name: 'Active',
      count: tableRecordCounts?.active || 0,
    },
    {
      name: 'Deactivated',
      count: tableRecordCounts?.inActive || 0,
    },
  ];

  const excludeKeys = [
    'emirateId',
    'regionId',
    'updatedDate',
    'createdDate',
    'countryId',
    'sortNumber',
    'status',
  ];


  const ActionData = [
    {
      name: 'View',
      icon: Icons.view,
      onClick: (props) => handleViewClickOpen(props),
    },
    {
      name: 'Edit',
      icon: Icons.edit,
      onClick: (props) => handleEditOpen(props),
    },
    {
      name: 'Delete',
      icon: Icons.delete,
      onClick: (props) => handleDelete(props),
      danger: true,
    },
  ];

  const columns = [
    {
      field: 'action',
      headerName: '',
      width: 140,
      sortable: false,
      disableClickEventBubbling: false,

      renderCell: (params) => {
        return (
          <TableActionButtons
            Actions={ActionData?.map((action) => ({
              ...action,
              onClick: () => action.onClick(params.row),
            }))}
            selectedRow={params.row}
            setSelectedRow={setSelectedRow}
          />
        );
      },
    },
    {
      field: 'emirateCode',
      headerName: 'Emirate Code',
      flex: 1,
    },
    {
      field: 'emirateName',
      headerName: 'Emirate Name',
      flex: 1,
    },
    {
      field: 'countryCode',
      headerName: 'Country Code',
      flex: 1,
    },
    {
      field: 'countryName',
      headerName: 'Country Name',
      flex: 1,
    },
    {
      field: 'status',
      headerName: 'Status',
      flex: 1,
      renderCell: (params) => {
        return (
          <StatusBadge
            title={params.value === 2 ? 'Active' : 'Inactive'}
            type={params.value === 2 ? 'green' : 'red'}
          />
        );
      },
    },
  ];

  const SubMenu = useMemo(() => {
    return menu?.map((item) => item?.menus?.flat()).flat() || [];
  }, [menu]);

  const resultPR = useMemo(
    () => findNavByName(SubMenu, 'PR', 'All master'),
    [SubMenu]
  );

  const resultHRMS = useMemo(
    () => findNavByName(SubMenu, 'HRMS', 'All Masters'),
    [SubMenu]
  );

  const resultPresales = useMemo(
    () => findNavByName(SubMenu, 'PreSales', 'All masters'),
    [SubMenu]
  );

  const resultProcurement = useMemo(
    () => findNavByName(SubMenu, 'Procurement', 'All Masters'),
    [SubMenu]
  );

  const resultCafm = useMemo(
    () => findNavByName(SubMenu, 'CAFM', 'All master'),
    [SubMenu]
  );


  return (
    <DashboardLayout
      title='Emirates'
      actionButtons={
        <>
          <PageHeader counters={counters} />
        </>
      }
      hasSubmenu
      menu={
        type === 'pr'
          ? resultPR
          : type === 'hrms'
            ? resultHRMS
            : type === 'presales'
              ? resultPresales
              : type === 'procurement'
                ? resultProcurement
                : type === 'cafm'
                  ? resultCafm
                  : null
      }
    >
      <GlassCard className='h-full-css'>
        <Box className='h-full-css'>
          <DataTable
            rows={rows}
            columns={columns}
            loading={isLoading}
            getRowClassName={(params) =>
              `${params.row.status === 0 && 'bg-red-50'}`
            }
            sortingMode='server'
            paginationMode='server'
            onPaginationModelChange={(model) =>
              handlePaginationModelChange(model)
            }
            onSortModelChange={(model) => handleSortModelChange(model)}
            page={pageNumber - 1}
            pageSize={pageSize}
            rowCount={tableRecordCounts?.total}
            fetchData={fetchData}
            slots={{
              toolbar: () => (
                <Box
                  className='p-2 w-full flex justify-between items-center'
                  sx={{
                    borderBottom: '1px solid',
                    borderColor: 'border.main',
                  }}
                >
                  <Box sx={{ maxWidth: '250px' }}>
                    <TableSearch
                      placeholder='Search'
                      fullWidth
                      setSearchKeyword={setSearchKeyword}
                      searchValue={searchKeyword}
                    />
                  </Box>

                  <Box className='flex items-center gap-4 justify-end'>
                    <CreateButton
                      name='Create'
                      onClick={handleCreateClickOpen}
                    />
                    <DataTableToolbarButtons
                      disableColumn
                      handleExcelExport={downloadEmirates}
                    />
                  </Box>
                </Box>
              ),
            }}
          />
        </Box>
      </GlassCard>

      {/* create edit */}

      <MuiDialogOne
        title='Create'
        open={openCreate}
        onClose={handleCreateClose}
      >
        <form onSubmit={handleSubmit(handleSubmitEmirate)}>
          <Grid container spacing={3}>
            <Grid item md={12}>
              <FmTextField
                name='emirateName'
                control={control}
                label='Emirate Name'
                rules={{
                  required: 'Emirate Name is required',
                }}
                required
              />
            </Grid>
            <Grid item md={12}>
              <FmSearchableSelect
                name='countryId'
                label='Country'
                apiUrl={'countries'}
                valueField='countryId'
                labelField={['countryName', 'countryCode']}
                showField={['countryName', 'countryCode']}
                control={control}
              />
            </Grid>
            <Grid item md={12}>

              <ActionButtons
                onSubmit={handleSubmit(handleSubmitEmirate)}
                onReset={reset}
                onCancel={handleCreateClose}
                submitLoading={createLoading}
                cancelLoading={false}
                submitText='Create'
              />
            </Grid>
          </Grid>
        </form>
      </MuiDialogOne>

      {/* create edit */}

      <MuiDialogOne
        title='View Details'
        open={openView}
        onClose={handleViewClose}
      >
        {viewDetails && viewDetails?.emirateId && (
          <CommonView
            url='emirates'
            id={viewDetails?.emirateId}
            excludeKeys={excludeKeys}
            renderFields={(data, fields) => (
              <DynamicViewFields data={data} fields={fields} />
            )}
          />
        )}
      </MuiDialogOne>

      {/*EDIT*/}
      <MuiDialogOne
        title='Edit'
        open={openEdit}
        onClose={() => setOpenEdit(false)}
      >
        {loading ? (
          <CommonLoader />
        ) : (
          <form onSubmit={handleSubmit(handleEditEmirates)}>
            <Grid container spacing={3}>
              <Grid item md={12}>
                <FmTextField
                  name='emirateName'
                  control={control}
                  label='Emirate Name'
                  rules={{
                    required: 'Emirate Name is required',
                  }}
                  emirateName
                />
              </Grid>

              <Grid item md={12}>
                <FmSearchableSelect
                  name='countryId'
                  label='Country'
                  apiUrl={'countries'}
                  valueField='countryId'
                  labelField={['countryName', 'countryCode']}
                  showField={['countryName', 'countryCode']}
                  control={control}
                  defaultValue={{
                    regionId: editingRowData?.regionId,
                    regionName: editingRowData?.regionName,
                  }}
                />
              </Grid>
              <Grid item md={12}>
                <FmAutoComplete
                  name='status'
                  control={control}
                  options={statusOptions}
                  label='Status'
                  displayField='statusName'
                  optionFields={['statusName']}
                  valueKey='statusId'
                />
              </Grid>
              <Grid item md={12}>
                <ActionButtons
                  onSubmit={handleSubmit(handleEditEmirates)}
                  onReset={reset}
                  onCancel={() => setOpenEdit(false)}
                  submitLoading={editLoading}
                  cancelLoading={false}
                  submitText='Update'
                />
              </Grid>
            </Grid>
          </form>
        )}
      </MuiDialogOne>
    </DashboardLayout>
  );
};

export default Emirates;
