import React from 'react';
import { Box, CircularProgress } from '@mui/material';

const ElementLoader = ({ image }) => {
  return (
    <Box className='flex justify-center w-full'>
      {image ? (
        <Box
          src='/images/loading.gif'
          component={'img'}
          sx={{ width: '45px', height: 'auto' }}
        />
      ) : (
        <CircularProgress />
      )}
    </Box>
  );
};

export default ElementLoader;
