import React from 'react';
import { Box, Button } from '@mui/material';
import { Icon } from '@iconify/react';

const NoReqSelected = () => {
  return (
    <>
      {/* no req */}
      <Box className='text-center flex flex-col justify-center items-center'>
        <Box
          src='/images/requisition.svg'
          component='img'
          className='mb-5 grayscale'
        />
        <Box className='font-semibold text-base mb-2'>
          No Requisitions Selected !
        </Box>
        <Box className='opacity-70'>
          In Order to create a local purchase order you must select some
          requisitions and click create LPO.
        </Box>
      </Box>
      {/* no req */}
    </>
  );
};

const Requisitions = ({
  matReqData,
  handleClearAll,
  handleCreateLPO,
  handleCreateRFO,
  createRFO,
}) => {
  return (
    <Box
      className='flex flex-col justify-between h-full w-full'
      sx={{
        borderRadius: '0px',
        bgcolor: 'background.light',
        border: '1px solid',
        borderColor: 'border.main',
      }}
    >
      {/* header */}
      <Box className='flex justify-between items-center p-3'>
        <h3 className='font-bold text-base'>
          Requisition <span> ({matReqData.length})</span>
        </h3>
        <Button
          endIcon={<Icon icon='material-symbols:delete-outline' />}
          onClick={handleClearAll}
        >
          Clear All
        </Button>
      </Box>
      {/* header */}

      {/* content */}
      <Box className='px-3 flex-1 min-h-0 overflow-auto'>
        {matReqData.length === 0 ? (
          <NoReqSelected />
        ) : (
          <>
            {matReqData?.map((item) => {
              return (
                <Box
                  className='flex gap-3 shadow-sm p-2 mb-2 justify-between'
                  sx={{ bgcolor: 'background.white' }}
                >
                  <Box className='font-semibold'>
                    {item?.materialRequisitionCode}
                  </Box>
                  <Box>{item?.projectName}</Box>
                </Box>
              );
            })}
          </>
        )}
      </Box>

      {/* content */}

      {/* LPO */}

      <Box className='flex justify-end p-3 gap-3'>
        {createRFO && (
          <Button
            variant='contained'
            onClick={handleCreateRFO}
            disabled={matReqData.length === 0 ? true : false}
          >
            Create RFQ
          </Button>
        )}
        <Button
          variant='contained'
          onClick={handleCreateLPO}
          disabled={matReqData.length === 0 ? true : false}
        >
          Create LPO
        </Button>
      </Box>
      {/* LPO */}
    </Box>
  );
};

export default Requisitions;
