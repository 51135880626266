import React, { useEffect, useMemo, useState } from 'react';
import DashboardLayout from '../../../components/DashboardLayout.tsx';
import PageHeader from '../../../components/PageHeader';
import DataTable from '../../../components/DataTable.tsx';
import GlassCard from '../../../components/small/GlassCard.tsx';
import TableActionButtons from '../../../components/_form/TableActionButtons';
import { ViewField, StatusBadge } from '../../../components/_form/FormElements';
import { Box, Grid } from '@mui/material';
import { CreateButton } from '../../../components/small/Buttons';
import TableSearch from '../../../components/_form/TableSearch.jsx';
import MuiDialogOne from '../../../components/_mui/MuiDialogOne';
import { Nav } from '../../../utils';
import Crud_Service from '../../../apis/CrudService';
import { useForm, SubmitHandler } from 'react-hook-form';
import { useTranslation } from 'react-i18next';
import CommonLoader from '../../../components/page/CommonLoader';
import useLoader from '../../../components/helpers/UseLoader.tsx';
import ActionButtons from '../../../components/_form/ActionButtons.tsx';
import useTableLogic from '../../../components/helpers/UseTableLogic.tsx';
import FmTextField from '../../../components/_mui/FmTextField.tsx';
import FmAutoComplete from '../../../components/_mui/FmAutoComplete.tsx';
import { MasterDatatableHeight } from '../../../components/helpers/Containers.jsx';
import { statusOptions } from '../../../utils/CommonVariables.tsx';
import { TenderFollowupTypeProps } from '../../../interfaces/PreSalesInterfaces.ts';
import { toast } from 'react-toastify';
import { yupResolver } from '@hookform/resolvers/yup';
import * as Yup from 'yup';
import Icons from '../../../utils/Icon.jsx';
import { useSelector } from 'react-redux';
import { findNavByName } from '../../../utils/navUtils.ts';

const ContactModesMasters = () => {
  const [openCreate, setOpenCreate] = React.useState(false);
  const [openView, setOpenView] = React.useState(false);
  const [editModal, setEditModal] = useState(false);
  const [editData, setEditData] = useState(null);
  const crud = new Crud_Service();
  const { t } = useTranslation();
  const [viewData, setViewData] = useState();
  const [loading, setLoader] = useState(false);
  const ContactModeSchema = Yup.object().shape({
    contactModeName: Yup.string().required('Contact Mode Name is required'),
  });
  const { handleSubmit, setValue, control, reset } =
    useForm<TenderFollowupTypeProps>({
      resolver: yupResolver(ContactModeSchema),
      mode: 'onChange',
    });
  const {
    createLoading,
    startCreateLoading,
    stopCreateLoading,
    editLoading,
    startEditLoading,
    stopEditLoading,
  } = useLoader();
  const {
    rows,
    pageSize,
    pageNumber,
    setSearchKeyword,
    isLoading,
    tableRecordCounts,
    searchKeyword,
    handlePaginationModelChange,
    handleSortModelChange,
    fetchData,
  } = useTableLogic('contactmodes');
  const menu = useSelector((state) => state?.menu?.items);

  // Flatten menu only once and store it for reuse
  const SubMenu = useMemo(() => {
    return menu?.map(item => item?.menus?.flat()).flat() || [];
  }, [menu]);

  // Use findNavByName to get the "Accounts Receivable" menu or its child menus
  const result = useMemo(() => findNavByName(SubMenu, "PreSales", "All master"), [SubMenu]);

  useEffect(() => {
    document.title = `Pre-Sales - Contact Modes`;
  }, []);

  const handleCreateClickOpen = () => {
    reset();
    setOpenCreate(true);
  };
  const handleCreateClose = () => {
    reset();
    setOpenCreate(false);
  };
  const handleSubmitContactModes: SubmitHandler<
    TenderFollowupTypeProps
  > = async (values) => {
    startCreateLoading();
    await crud.create('contactmodes', values, (err, res) => {
      if (res?.status === 201) {
        setOpenCreate(false);
        stopCreateLoading();
        toast.success('Tender Status Created Successfully');
        reset();
        fetchData();
      } else {
        stopCreateLoading();
        setOpenCreate(true);
      }
    });
  };

  const handleViewClickOpen = async (view) => {
    setOpenView(true);
    setLoader(true);
    await crud.getSingle('contactmodes', view?.contactModeId, (err, res) => {
      if (res?.status === 200) {
        setViewData(res?.data);
        setLoader(false);
      } else {
      }
    });
  };

  const handleEditClick = async (view) => {
    setEditModal(true);
    setLoader(true);
    await crud.getSingle('contactmodes', view?.contactModeId, (err, res) => {
      if (res?.status === 200) {
        Object.entries({
          ...res?.data,
        }).forEach(([key, value]) => {
          setValue(key, value);
        });
        setEditData(res?.data);
        setLoader(false);
      } else {
        setLoader(false);
      }
    });
  };

  const handleEditContactModes = async (values) => {
    startEditLoading();
    await crud.update(
      'contactmodes',
      editData?.contactModeId,
      values,
      (err, res) => {
        if (res?.status === 200) {
          setEditModal(false);
          stopEditLoading();
          toast.success('Tender Status Updated Successfully');
          fetchData();
        } else {
          stopEditLoading();
          setEditModal(true);
        }
      }
    );
  };

  const handleDeleteClick = async (view) => {
    await crud.remove('contactmodes', view?.contactModeId, (err, res) => {
      if (res?.status === 204) {
        fetchData();
      } else {
      }
    });
  };

  const handleViewClose = () => {
    setOpenView(false);
  };

  const counters = [
    {
      name: 'All',
      count: tableRecordCounts?.total || 0,
    },
    {
      name: 'Active',
      count: tableRecordCounts?.active || 0,
    },
    {
      name: 'Deactivated',
      count: tableRecordCounts?.inActive || 0,
    },
  ];

  const ActionData = [
    {
      name: t('view'),
      icon: Icons.view,
      onClick: (props) => handleViewClickOpen(props),
    },
    {
      name: t('edit'),
      icon: Icons.edit,
      onClick: (props) => handleEditClick(props),
    },
    {
      name: t('delete'),
      icon: Icons.delete,
      onClick: (props) => handleDeleteClick(props),
      danger: true,
    },
  ];

  const columns = [
    {
      field: 'action',
      headerName: '',
      width: 140,
      sortable: false,
      disableClickEventBubbling: false,

      renderCell: (params) => {
        return (
          <TableActionButtons
            Actions={ActionData?.map((action) => ({
              ...action,
              onClick: () => action.onClick(params.row),
            }))}
          />
        );
      },
    },
    {
      field: 'contactModeCode',
      headerName: 'Contact Mode Code',
      flex: 1,
    },
    {
      field: 'contactModeName',
      headerName: 'Contact Mode Name',
      flex: 1,
    },
    {
      field: 'status',
      headerName: t('status'),
      flex: 1,
      renderCell: (params) => {
        return (
          <StatusBadge
            title={params.value === 2 ? 'Active' : 'Inactive'}
            type={params.value === 2 ? 'green' : 'red'}
          />
        );
      },
    },
  ];

  return (
    <DashboardLayout
      title='Contact Modes'
      actionButtons={
        <>
          <PageHeader counters={counters} />
        </>
      }
      hasSubmenu
      menu={result}
    >
      <GlassCard className='h-full-css'>
        <MasterDatatableHeight>
          <DataTable
            rows={rows}
            columns={columns}
            loading={isLoading}
            getRowClassName={(params) =>
              `${params.row.status === 0 && 'bg-red-50'}`
            }
            sortingMode='server'
            paginationMode='server'
            onPaginationModelChange={(model) =>
              handlePaginationModelChange(model)
            }
            onSortModelChange={(model) => handleSortModelChange(model)}
            page={pageNumber - 1}
            pageSize={pageSize}
            rowCount={tableRecordCounts?.total}
            fetchData={fetchData}
            slots={{
              toolbar: () => (
                <Box
                  className='p-2 w-full flex justify-between items-center'
                  sx={{
                    borderBottom: '1px solid',
                    borderColor: 'border.main',
                  }}
                >
                  <Box sx={{ maxWidth: '250px' }}>
                    <TableSearch
                      placeholder='Search'
                      fullWidth
                      setSearchKeyword={setSearchKeyword}
                      searchValue={searchKeyword}
                    />
                  </Box>
                  <CreateButton name='New' onClick={handleCreateClickOpen} />
                </Box>
              ),
            }}
          />
        </MasterDatatableHeight>
      </GlassCard>

      {/* create  */}

      <MuiDialogOne
        title={t('create')}
        open={openCreate}
        onClose={handleCreateClose}
      >
        <form onSubmit={handleSubmit(handleSubmitContactModes)}>
          <Grid container spacing={3}>
            <Grid item md={12}>
              <FmTextField
                name='contactModeName'
                control={control}
                label='Contact Mode Name'
                rules={{
                  required: 'Contact Mode Name is required',
                }}
              />
            </Grid>
            <Grid item md={12}>
              <ActionButtons
                onSubmit={handleSubmit(handleSubmitContactModes)}
                onCancel={handleCreateClose}
                onReset={reset}
                submitLoading={createLoading}
                cancelLoading={false}
                submitText='Create'
              />
            </Grid>
          </Grid>
        </form>
      </MuiDialogOne>

      {/* create */}

      {/* view */}
      <MuiDialogOne
        title='View Details'
        open={openView}
        onClose={handleViewClose}
      >
        {loading ? (
          <CommonLoader />
        ) : (
          <Grid container spacing={3}>
            <Grid item md={6}>
              <ViewField
                label='Contact Mode Code'
                title={viewData?.contactModeCode}
              />
            </Grid>
            <Grid item md={6}>
              <ViewField
                label='Contact Mode Name'
                title={viewData?.contactModeName}
              />
            </Grid>

            <Grid item md={6}>
              <ViewField label={t('status')} title={viewData?.statusName} />
            </Grid>
          </Grid>
        )}
      </MuiDialogOne>

      {/* edit */}

      <MuiDialogOne
        title={t('update')}
        open={editModal}
        onClose={() => setEditModal(false)}
      >
        {loading ? (
          <CommonLoader />
        ) : (
          <form onSubmit={handleSubmit(handleEditContactModes)}>
            <Grid container spacing={3}>
              <Grid item md={12}>
                <FmTextField
                  name='contactModeName'
                  control={control}
                  label='Contact Mode Name'
                  rules={{
                    required: 'Contact Mode Name is required',
                  }}
                />
              </Grid>
              <Grid item md={12}>
                <FmAutoComplete
                  name='status'
                  control={control}
                  options={statusOptions}
                  label='Status'
                  displayField='statusName'
                  optionFields={['statusName']}
                  valueKey='statusId'
                />
              </Grid>
              <Grid item md={12}>
                <ActionButtons
                  onSubmit={handleSubmit(handleEditContactModes)}
                  onCancel={() => setEditModal(false)}
                  onReset={reset}
                  submitLoading={editLoading}
                  cancelLoading={false}
                  submitText='Update'
                />
              </Grid>
            </Grid>
          </form>
        )}
      </MuiDialogOne>
    </DashboardLayout>
  );
};

export default ContactModesMasters;
