import React, { useState } from 'react';
import GlassCard from '../../../../components/small/GlassCard.tsx';
import { t } from 'i18next';
import { Box, Grid, Button, Chip } from '@mui/material';
import FmTextField from '../../../../components/_mui/FmTextField.tsx';
import FmAutoComplete from '../../../../components/_mui/FmAutoComplete.tsx';
import UploadModalBox from '../../../../components/upload/UploadModalBox.tsx';
import { yupResolver } from '@hookform/resolvers/yup';
import { useForm } from 'react-hook-form';
import { BooleanOptions } from '../../../../utils/CommonVariables.tsx';
import FmSearchableSelect from '../../../../components/_mui/FmSearchableSelect.tsx';
import ActionButtons from '../../../../components/_form/ActionButtons.tsx';
import Crud_Service from '../../../../apis/CrudService.jsx';
import { useNavigate, useParams } from 'react-router-dom';
import { toast } from 'react-toastify';
import useLoader from '../../../../components/helpers/UseLoader.tsx';
import dayjs from 'dayjs';
import useTableLogic from '../../../../components/helpers/MRTUseTableLogic.tsx';
import { Icon } from '@iconify/react';
import FmMRTDataTable from '../../../../components/FmMRTDataTable.tsx';
import { inspectionreportitems } from '../../../../utils/CommonvalidationSchemas.tsx';
import apiInstance from '../../../../apis/ApiService.jsx';
import TableActionButtons from '../../../../components/_form/TableActionButtons.jsx';
import FmDatePicker from '../../../../components/_mui/FmDatePicker.tsx';
const GridItem = 2.4;

const BuildInspectionChecklist = () => {
  const {
    rows,
    isLoading,
    pagination,
    sorting,
    columnFilters,
    globalFilter,
    tableRecordCounts,
    setPagination,
    setSorting,
    setColumnFilters,
    setGlobalFilter,
    fetchData,
    setSearchKeyword,
    searchKeyword,
  } = useTableLogic('inspectionreportitems');
  const [, setCreatingRow] = useState(null);
  const [files, setFiles] = useState([]);
  const [uploadedFileIds, setUploadedFileIds] = useState([]);
  const [filesEdit, setFilesEdit] = useState([]);
  const [uploadedFileIdsEdit, setUploadedFileIdsEdit] = useState([]);
  const crud = new Crud_Service();
  const { startCreateLoading, stopCreateLoading, createLoading } = useLoader();
  const navigate = useNavigate();
  const [editingRowData, setEditingRowData] = useState(null);
  const [editingRowId, setEditingRowId] = useState(null);
  const inspectionReportId = useParams();

  const handleCancel = () => {
    setEditingRowId(null);
    setEditingRowData(null);
  };

  const handleEdit = (row) => {
    setEditingRowId(row?.inspectionReportItemId);
    setEditingRowData({ ...row });
    Object.keys(row).forEach((key) => {
      setValue(key, row[key]);
    });
  };

  const handleDeleteItems = async (values) => {
    crud.remove(
      'inspectionreportitems',
      values.inspectionReportItemId,
      (err, res) => {
        if (res?.status === 204) {
          fetchData();
          toast.success('Item deleted Successfully');
        } else {
        }
      }
    );
  };
  const handleEditItem = async (values) => {
    const combinedData = {
      status: 2,
      description: values?.description || '',
      remarks: values?.remarks || '',
      verified: values?.verified ?? null,
      taskOwnerId: values?.taskOwnerId || null,
      inspectionReportId: Number(inspectionReportId?.id) || null,
      targetDateForRectification: values?.targetDateForRectification
        ? dayjs(values.targetDateForRectification).format('YYYY-MM-DD')
        : null,
      updateInspectionReportItemImages: Array.isArray(
        values?.inspectionReportItemImages
      )
        ? values.inspectionReportItemImages.map((file, index) => ({
          fileId:
            uploadedFileIdsEdit && uploadedFileIdsEdit[index]
              ? Number(uploadedFileIdsEdit[index])
              : file.fileId,
          inspectionReportItemImageId:
            file?.inspectionReportItemImageId || null,
          status: 2,
        }))
        : [],
    };

    await crud.update(
      'inspectionreportitems',
      editingRowData?.inspectionReportItemId,
      combinedData,
      (err, res) => {
        if (res?.status === 200) {
          toast.success('Item Updated Successfully');
          fetchData();
          setEditingRowId(null);
          setEditingRowData(null);
          setUploadedFileIds([]);
        } else {
        }
      }
    );
  };

  const { handleSubmit, control, setValue } = useForm();

  const {
    handleSubmit: createHandleSubmit,
    control: createControl,
    reset: createReset,
  } = useForm({
    resolver: yupResolver(inspectionreportitems),
    mode: 'onChange',
  });

  const handleFileIds = (fileIds) => {
    setUploadedFileIds(fileIds);
  };
  const handleFileEditIds = (fileIds) => {
    setUploadedFileIdsEdit(fileIds);
  };
  const ActionData = [
    {
      name: 'Edit',
      icon: <Icon icon='solar:pen-2-broken' />,
      onClick: (props) => handleEdit(props),
    },
    {
      name: 'Delete',
      icon: <Icon icon='solar:trash-bin-minimalistic-broken' />,
      onClick: (props) => handleDeleteItems(props),
      danger: true,
    },
  ];

  const handleSubmitProjectPlan = async (values) => {
    startCreateLoading();
    await crud.create(
      'inspectionreportitems',
      {
        ...values,
        inspectionReportId: inspectionReportId.id,
        targetDateForRectification: dayjs(
          values?.targetDateForRectification
        ).format('YYYY-MM-DD'),
        fileUploadIds: uploadedFileIds,
      },
      (err, res) => {
        if (res?.status === 201) {
          stopCreateLoading();
          toast.success(t('Inspection Report Created Successfully!'));
          fetchData();
          createReset();
        } else {
          stopCreateLoading();
          toast.error(t('Something went wrong!'));
        }
      }
    );
  };

  const handleDownloadFile = async (props) => {
    try {
      const fileIds = props?.inspectionReportItemImages?.map(
        (item) => item?.fileId
      );

      if (!fileIds || fileIds.length === 0) {
        throw new Error('No file IDs available to download.');
      }

      for (const fileId of fileIds) {
        const imageUrl = await apiInstance.getFiles(`files/download/${fileId}`);
        const link = document.createElement('a');
        link.href = imageUrl;
        link.setAttribute('download', `${props?.documentName}`);
        document.body.appendChild(link);
        link.click();
        document.body.removeChild(link);
      }
    } catch (err) {
      toast.error('Error fetching image');
    }
  };
  // Define the columns
  const columns = [
    {
      accessorKey: 'description',
      header: 'Description',
      enableEditing: true,
      Cell: ({ cell }) => {
        return editingRowId === cell?.row?.original?.inspectionReportItemId ? (
          <FmTextField
            name='description'
            control={control}
            label='Description'
            required
          />
        ) : (
          cell.getValue() || ''
        );
      },
    },
    {
      accessorKey: 'verified',
      header: 'Verified',
      enableEditing: true,
      Cell: ({ row }) => {
        return editingRowId === row?.original?.inspectionReportItemId ? (
          <FmAutoComplete
            name='verified'
            control={control}
            options={BooleanOptions}
            label='Verified'
            displayField='label'
            required
            optionFields={['label']}
            valueKey='value'
          />
        ) : row?.original?.verified === true ? (
          <Chip label='Yes' sx={{ color: 'success.main' }} />
        ) : row?.original?.verified === false ? (
          <Chip label='No' sx={{ color: 'error.main' }} />
        ) : (
          <Chip label='N/A' color='default' />
        );
      },
    },
    {
      accessorKey: 'taskOwnerId',
      header: 'Task Owner',
      enableEditing: true,
      Cell: ({ row }) => {
        return editingRowId === row?.original?.inspectionReportItemId ? (
          <FmSearchableSelect
            name='taskOwnerId'
            control={control}
            apiUrl='loginusers'
            valueField='loginUserId'
            label='Task Owner'
            labelField={['employeeCode', 'employeeName', 'employeeMail']}
            showField={['employeeCode', 'employeeName', 'employeeMail']}
            defaultValue={{
              taskOwnerId: editingRowData?.taskOwnerId,
              loginUserName: editingRowData?.taskOwnerName,
            }}
          />
        ) : (
          row?.original?.taskOwnerName || ''
        );
      },
    },
    {
      accessorKey: 'targetDateForRectification',
      header: 'Date for Rectification',
      enableEditing: true,
      Cell: ({ row }) => {
        return editingRowId === row?.original?.inspectionReportItemId ? (
          <FmDatePicker
            name='targetDateForRectification'
            control={control}
            label='Date for Rectification'
            minDate={dayjs()}
            required
          />
        ) : (
          row?.original?.targetDateForRectification || ''
        );
      },
    },
    {
      accessorKey: 'remarks',
      header: 'Remarks',
      enableEditing: true,
      Cell: ({ cell }) => {
        return editingRowId === cell?.row?.original?.inspectionReportItemId ? (
          <FmTextField
            name='remarks'
            control={control}
            label='Remarks'
            multiline
          />
        ) : (
          cell.getValue() || ''
        );
      },
    },
    {
      accessorKey: 'fileUploadIds',
      header: 'Files',
      enableEditing: true,
      Cell: ({ row }) => {
        return editingRowId === row?.original?.inspectionReportItemId ? (
          <UploadModalBox
            onSubmitFiles={handleFileEditIds}
            files={filesEdit}
            setFiles={setFilesEdit}
          />
        ) : (
          row?.original?.inspectionReportItemImages?.length > 0 && (
            <Button
              className='p-0 font-semibold'
              sx={{ color: 'primary.main' }}
              startIcon={<Icon icon='tabler:template' />}
              onClick={() => handleDownloadFile(row?.original)}
            >
              {'Add Files'}
            </Button>
          )
        );
      },
    },
    {
      header: 'Actions',
      enableColumnPinning: true,
      enableEditing: false,
      enableSorting: false,
      Cell: ({ row }) => (
        <Box className='flex gap-2'>
          {editingRowId === row?.original?.inspectionReportItemId ? (
            <>
              <Button
                onClick={handleSubmit(handleEditItem)}
                loading={isLoading}
              >
                Save
              </Button>
              <Button onClick={handleCancel}>Cancel</Button>
            </>
          ) : (
            <TableActionButtons
              Actions={ActionData?.map((action) => ({
                ...action,
                onClick: () => action.onClick(row?.original),
              }))}
              selectedRow={row?.original}
              setSelectedRow={() => {}}
            />
          )}
        </Box>
      ),
    },
  ];

  return (
    <>
      <h3 className='font-bold text-sm mb-5 pt-4'>
        Building Inspection Checklist
      </h3>

      <GlassCard className='p-3 mb-5'>
        <form onSubmit={createHandleSubmit(handleSubmitProjectPlan)}>
          <Grid container spacing={2}>
            <Grid item md={GridItem}>
              <FmTextField
                name='description'
                control={createControl}
                label='Description'
                required
              />
            </Grid>

            <Grid item md={GridItem}>
              <FmAutoComplete
                name='verified'
                control={createControl}
                options={BooleanOptions}
                label='Verified'
                displayField='label'
                optionFields={['label']}
                valueKey='value'
                required
              />
            </Grid>
            <Grid item md={GridItem}>
              <FmSearchableSelect
                name='taskOwnerId'
                control={createControl}
                apiUrl='loginusers'
                valueField='loginUserId'
                label='Task Owner'
                labelField={['employeeCode', 'employeeName', 'employeeMail']}
                showField={['employeeCode', 'employeeName', 'employeeMail']}
              />
            </Grid>

            <Grid item md={GridItem}>
              <FmDatePicker
                name='targetDateForRectification'
                control={createControl}
                label='Date for Rectification'
                minDate={dayjs()}
                required
              />
            </Grid>

            <Grid item md={GridItem}>
              <UploadModalBox
                onSubmitFiles={handleFileIds}
                files={files}
                setFiles={setFiles}
              />
            </Grid>
            <Grid item md={GridItem}>
              <FmTextField
                name='remarks'
                control={createControl}
                label='Remarks'
                multiline={true}
                rows={2}
                maxRows={3}
              />
            </Grid>
          </Grid>
        </form>
        <ActionButtons
          onSubmit={createControl.handleSubmit(handleSubmitProjectPlan)}
          onReset={createReset}
          onCancel={() => navigate(-1)}
          submitLoading={createLoading}
        />
      </GlassCard>

      <GlassCard>
        <FmMRTDataTable
          rows={rows}
          columns={columns}
          enableRowSelection={false}
          rowCount={tableRecordCounts}
          editingRow={editingRowId}
          pagination={pagination}
          sorting={sorting}
          columnFilters={columnFilters}
          globalFilter={globalFilter}
          setPagination={setPagination}
          setSorting={setSorting}
          setColumnFilters={setColumnFilters}
          setGlobalFilter={setGlobalFilter}
          isLoading={isLoading}
          setSearchKeyword={setSearchKeyword}
          searchKeyword={searchKeyword}
        />
      </GlassCard>
    </>
  );
};

export default BuildInspectionChecklist;
