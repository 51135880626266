import React from 'react';
import { Tabs, Tab } from '@mui/material';

const FmTabsOne = ({ value, handleChange, data, centered = false }) => {
  return (
    <Tabs
      value={value}
      onChange={handleChange}
      centered={centered}
      sx={{
        minHeight: '38px',
        '& .MuiTabs-flexContainer': {
          //  margin: '5px 0px',
        },
        '& .MuiTab-root': {
          minHeight: '38px',
          fontWeight: 500,
          padding: '10px 5px',
          textAlign: 'center',
          justifyContent: 'center',
          opacity: 0.8,
          borderRadius: '0px',
          marginRight: '5px',

          '&:hover': {
            opacity: 1,
          },
          '&.Mui-selected': {
            fontWeight: 600,
            opacity: 1,
            color: 'text.dark',
          },
        },
      }}
    >
      {data.map((item, i) => {
        return (
          !item.hidden && <Tab value={item.id} label={item.name} key={i} />
        );
      })}
    </Tabs>
  );
};

export default FmTabsOne;
