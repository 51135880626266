import React, { useEffect, useRef, useState } from 'react';
import GlassCard from '../../../../../components/small/GlassCard.tsx';
import { Box, Button, Divider, Stack } from '@mui/material';
import { Icon } from '@iconify/react';
import FmMRTDataTable from '../../../../../components/FmMRTDataTable.tsx';
import useTableLogic from '../../../../../components/helpers/MRTUseTableLogic.tsx';
import { useForm } from 'react-hook-form';
import Crud_Service from '../../../../../apis/CrudService.jsx';
import TableActionButtons from '../../../../../components/_form/TableActionButtons.jsx';
import { toast } from 'react-toastify';
import FmTextField from '../../../../../components/_mui/FmTextField.tsx';
import FmSearchableSelect from '../../../../../components/_mui/FmSearchableSelect.tsx';
import { salesorderitemschema } from '../../../../../utils/CommonvalidationSchemas.tsx';
import { yupResolver } from '@hookform/resolvers/yup';
import { useHotkeys } from 'react-hotkeys-hook';
import { useSelector } from 'react-redux';
import CurrencyView from '../../../../../components/_mui/FmCurrencyView.tsx';
import FmCurrencyTextField from '../../../../../components/_mui/FmCurrencyTextField.tsx';
import { ViewField } from '../../../../../components/_form/FormElements.jsx';
import { salesOrderStatusEnum } from '../../../../../utils/CommonVariables.tsx';

const BoldBox = ({ title, primary }) => {
  return (
    <Box className='font-semibold' sx={{ color: primary && 'primary.main' }}>
      {title}
    </Box>
  );
};

const VewMrtTableSalesOrder = (props) => {
  const { id } = props;

  const {
    rows,
    setRows,
    isLoading,
    pagination,
    sorting,
    columnFilters,
    globalFilter,
    tableRecordCounts,
    setPagination,
    setSorting,
    setColumnFilters,
    setGlobalFilter,
    fetchData,
    setSearchKeyword,
    searchKeyword,
  } = useTableLogic('salesorderitems', ['salesOrderId'], [id]);

  const crud = new Crud_Service();
  const [editingRowId, setEditingRowId] = useState(null);
  const [editingRowData, setEditingRowData] = useState({});
  const [loader, setLoader] = useState(false);
  const [rowState, setRowState] = useState({
    newRowId: null,
    isCreatingRow: false,
  });
  const firstColumnInputRef = useRef(null);
  const [stateError, setStateError] = useState(false);
  const [editingRowIndex, setEditingRowIndex] = useState(null);
  const [uploadedfileData, setUploadedfileData] = useState(null);
  const [formValues, setFormValues] = useState({});
  const {
    control,
    setValue,
    watch,
    getValues,
    reset,
    formState: { isValid, errors },
  } = useForm({
    resolver: yupResolver(salesorderitemschema),
    mode: 'all',
  });
  const { salesQuotation } = useSelector((state) => state?.salesQuotation);
  const [billingValues, setBillingValues] = useState([
    { name: 'Items', value: 0 },
    { name: 'Gross', value: 0 },
    { name: 'Discount', value: 0 },
    { name: 'VAT', value: 0 },
    { name: 'Total', value: 0 },
  ]);



  useEffect(() => {
    if (salesQuotation?.salesQuotationId !== null) {
      fetchData();
    }
  }, [salesQuotation]);
  const handleAddNewRow = () => {
    const newId = 'new_row_' + Date.now();
    reset();
    setEditingRowData(null);
    setEditingRowId(null);
    setUploadedfileData(null);
    setRowState({
      newRowId: newId,
      isCreatingRow: true,
    });
    setEditingRowIndex(
      editingRowIndex === rows.length ? editingRowIndex + 1 : rows.length
    );
    setRows((prevRows) => [
      ...prevRows,
      {
        salesOrderItemId: newId,
        salesOrderId: id,
        description: '',
        divisionId: '',
        quantity: 0,
        rate: 0,
        gross: 0,
        discount: 0,
        vat: 0,
        amountWithTax: 0,
        amount: 0,
        remarks: '',
        isEditable: true,
        isNew: true,
      },
    ]);
    // reset();
  };

  const handleBlurAndFetch = async () => {
    setLoader(true);
    const values = getValues();
    const updateCombinedData = {
      ...values,
      salesOrderId: id,
      quantity: values?.quantity || 0,
      discount: values?.discount || 0,
      vat: values?.vat || 0,
      status: 2,
    };

    await crud.update(
      'salesorderitems',
      editingRowId,
      updateCombinedData,
      (err, res) => {
        if (res?.status === 200) {
          const totalRows = rows.length;
          if (editingRowIndex <= totalRows && rows.some((row) => row.isNew)) {
            const updatedRow = res?.data;
            const updatedRows = [...rows];
            updatedRows[editingRowIndex] = {
              ...updatedRow,
              isEditable: true,
              isNew: true,
            };
            setRows(updatedRows);
            handleAddNewRow();
          } else {
            const updatedRow = res?.data;
            const updatedRows = [...rows];
            updatedRows[editingRowIndex] = {
              ...updatedRow,
              isEditable: false,
              isNew: false,
            };

            setRows(updatedRows);
            setStateError(false);
            setLoader(false);
          }
        } else {
          setStateError(true);
        }
      }
    );
  };

  const handleCancel = (row) => {
    if (row?.original?.salesOrderItemId === rowState?.newRowId) {
      setRows((prevRows) => {
        if (prevRows?.length > 0) {
          return prevRows?.slice(0, -1);
        }
        return prevRows;
      });

      setRowState((prevState) => ({
        ...prevState,
        newRowId: null,
        isCreatingRow: false,
      }));
    } else {
      setEditingRowId(null);
    }
    setUploadedfileData(null);
    reset();
  };

  const handleEdit = (row) => {

    reset();
    setUploadedfileData(null);
    if (rowState?.newRowId) {
      setRows((prevRows) => {
        if (prevRows?.length > 0) {
          return prevRows?.slice(0, -1);
        }
        return prevRows;
      });
      setRowState((prevState) => ({
        ...prevState,
        newRowId: null,
        isCreatingRow: false,
      }));
    }
    setEditingRowId(row?.salesOrderItemId);
    Object.entries({
      ...row,
    }).forEach(([key, value]) => {
      setValue(key, value);
    });
    setEditingRowData({ ...row });
  };

  useHotkeys('Enter', () => handleAddNewRow());

  useHotkeys(
    'Tab',
    (event) => {
      if (event.key !== 'Tab') return;
      if (stateError) {
        event.preventDefault();
        return;
      }
      const totalRows = rows.length;
      const nextRowIndex = editingRowIndex + 1;

      if (
        nextRowIndex < totalRows &&
        stateError === false &&
        loader === false
      ) {
        handleEdit(rows[nextRowIndex]);
        setEditingRowIndex(nextRowIndex);
      }
    },
    [editingRowIndex, rows, stateError, loader, isValid]
  );

  useEffect(() => {
    if (editingRowIndex !== null && firstColumnInputRef.current && !isLoading) {
      firstColumnInputRef.current.focus();
    }
  }, [editingRowIndex, isLoading]);

  useEffect(() => {
    if (editingRowData) {
      Object.entries(editingRowData).forEach(([key, value]) => {
        setValue(key, value);
      });
    }
  }, [editingRowData, setValue]);
  const previousValues = useRef(getValues());

  const handleBlur = () => {
    const currentValues = getValues();
    const amount = getValues('amount');
    if (!amount) {
      return;
    }
    const hasChanges = Object.keys(currentValues).some(
      (key) => currentValues[key] !== previousValues.current[key]
    );

    if ((hasChanges && isValid) || (hasChanges && editingRowId)) {
      apiCallAsync(currentValues);
      previousValues.current = currentValues;
    }
  };
  const apiCallAsync = async (values) => {
    setLoader(true);

    if (!values.amount) {
      setLoader(false);
      return;
    }
    if (editingRowId === null) {
      const convertedValues = {
        ...values,
        salesOrderId: id,
        divisionId: Number(values?.divisionId),
        quantity: values?.quantity || 0,
        discount: values?.discount || 0,
        vat: values?.vat || 0,
      };

      try {
        await crud.create('salesorderitems', convertedValues, (err, res) => {
          if (res?.status === 201) {
            setEditingRowId(res?.data?.salesOrderItemId);
            setStateError(false);
            setEditingRowData({});
            setUploadedfileData(null);
          } else {
            setStateError(true);
          }
          setLoader(false);
        });
      } catch (error) {
        setStateError(true);
        setLoader(false);
      }
    } else {
      const updatedCombinedData = {
        ...values,
        salesOrderId: id,
        quantity: values?.quantity || 0,
        discount: values?.discount || 0,
        vat: values?.vat || 0,
        status: 2,
      };

      try {
        await crud.update(
          'salesorderitems',
          editingRowId,
          updatedCombinedData,
          (err, res) => {
            if (res?.status === 200) {
              const updatedRow = res?.data;
              const updatedRows = [...rows];
              updatedRows[editingRowIndex] = {
                ...updatedRow,
                isEditable: rows.some((row) => row.isNew),
                isNew: rows.some((row) => row.isNew),
              };
              setRows(updatedRows);
              setStateError(false);
              setUploadedfileData(null);
            } else {
              setStateError(true);
            }
            setLoader(false);
          }
        );
      } catch (error) {
        setStateError(true);
        setLoader(false);
      }
    }
  };
  const handleDelete = async (props) => {
    await crud.remove(
      'salesorderitems',
      props?.salesOrderItemId,
      (__err, res) => {
        if (res?.status === 204) {
          toast.success('Sales Order Item Deleted Successfully');
          fetchData();
        } else {
        }
      }
    );
  };
  const ActionData = [
    {
      name: 'Delete',
      icon: <Icon icon='solar:trash-bin-minimalistic-broken' />,
      onClick: (props) => handleDelete(props),
      danger: true,
    },
  ];
  useEffect(() => {
    if (!rows?.length) return; // Exit early if no rows exist
    // Get form values
    const quantity = parseFloat(watch('quantity')) || 0;
    const rate = parseFloat(watch('rate')) || 0;
    const vatPercentage = parseFloat(watch('vat')) || 0;
    const discount = parseFloat(watch('discount')) || 0;

    // Calculate gross amount
    const gross = quantity * rate;
    setValue('gross', gross.toFixed(2));
    const discountAmount = gross - discount;
    // Calculate VAT and total amount
    const vatAmount = Math.max(0, (discountAmount * vatPercentage) / 100); // Ensure VAT is non-negative
    const totalAmount = Math.max(0, gross - discount + vatAmount).toFixed(2);

    setValue('amountWithTax', vatAmount.toFixed(2));
    setValue('amount', totalAmount);

    // Process rows if available
    if (rows?.length) {
      const filteredRows = editingRowId
        ? rows.filter((row) => row.salesOrderItemId !== editingRowId)
        : rows;

      const sumField = (field) =>
        filteredRows.reduce(
          (total, row) => total + parseFloat(row[field] || 0),
          0
        );

      const averageField = (field) =>
        filteredRows.length
          ? (sumField(field) / filteredRows.length).toFixed(2)
          : '0.00';

      const grossSum = sumField('gross');
      const totalSum = sumField('amount');
      const discountAvg = sumField('discount');
      const vatAvg = averageField('vat');

      const editedGross = parseFloat(watch('gross')) || 0;
      const editedTotalAmount = parseFloat(watch('amount')) || 0;

      // Update billing values
      setBillingValues((prev) =>
        prev.map((item) => {
          const fieldValues = {
            Items: rows.length,
            Gross: (grossSum + editedGross).toFixed(2),
            Discount: (parseFloat(discountAvg) + discount).toFixed(2),
            VAT: parseFloat(vatAvg) //calculate fromValues.vat when upadet
              .toFixed(2),
            Total: (totalSum + editedTotalAmount).toFixed(2),
          };
          return fieldValues[item.name] !== undefined
            ? { ...item, value: fieldValues[item.name] }
            : item;
        })
      );
    }
  }, [isLoading, formValues?.discount, formValues?.vat, formValues?.rate, rows]);

  const columns = [
    {
      accessorKey: 'divisionId',
      header: 'Division',
      enableEditing: true,
      Cell: ({ row }) => {
        const isNewRow = row?.original?.salesOrderItemId === rowState?.newRowId;
        const isEditing =
          editingRowId === row?.original?.salesOrderItemId || isNewRow;
        return isEditing ? (
          <FmSearchableSelect
            name='divisionId'
            control={control}
            apiUrl='divisions'
            valueField='divisionId'
            labelField={['divisionCode', 'divisionName']}
            showField={['divisionCode', 'divisionName']}
            label='Division'
            // inputRef={firstColumnInputRef}
            onBlur={handleBlur}
            defaultValue={{
              divisionId: editingRowData?.divisionId,
              divisionName: editingRowData?.divisionName,
            }}
            required
          />
        ) : (
          row?.original?.divisionName
        );
      },
    },
    {
      accessorKey: 'description',
      header: 'Description',
      enableEditing: true,
      Cell: ({ row }) => {
        const isNewRow = row?.original?.salesOrderItemId === rowState?.newRowId;
        const isEditing =
          editingRowId === row?.original?.salesOrderItemId || isNewRow;
        return isEditing ? (
          <>
            <FmTextField
              name='description'
              control={control}
              label='Description'
              onBlur={handleBlur}
            />
          </>
        ) : (
          row?.original?.description
        );
      },
    },

    {
      accessorKey: 'quantity',
      header: 'Quantity',
      enableEditing: true,
      size: 100,
      Cell: ({ row }) => {
        const isNewRow = row?.original?.salesOrderItemId === rowState?.newRowId;
        const isEditing =
          editingRowId === row?.original?.salesOrderItemId || isNewRow;
        return isEditing ? (
          <FmTextField name='quantity' control={control} onBlur={handleBlur} />
        ) : (
          <BoldBox title={row?.original?.quantity || ''} />
        );
      },
    },
    {
      accessorKey: 'rate',
      header: 'Rate',
      enableEditing: true,

      Cell: ({ row }) => {
        const isNewRow = row?.original?.salesOrderItemId === rowState?.newRowId;
        const isEditing =
          editingRowId === row?.original?.salesOrderItemId || isNewRow;
        return isEditing ? (
          <FmCurrencyTextField
            name='rate'
            control={control}
            onBlur={handleBlur}
            labelRemove={true}
            onChange={(e) =>
              setFormValues((prevValues) => ({
                ...prevValues,
                rate: e.target.value,
              }))
            }
          />
        ) : (
          <CurrencyView value={row?.original?.rate} currencyType={'AED'} />
        );
      },
    },
    {
      accessorKey: 'gross',
      header: 'Gross',
      Cell: ({ row }) => {
        const isNewRow = row?.original?.salesOrderItemId === rowState?.newRowId;
        const isEditing =
          editingRowId === row?.original?.salesOrderItemId || isNewRow;
        const gross = watch('gross');
        return isEditing ? (
          <CurrencyView value={gross} />
        ) : (
          <CurrencyView value={row?.original?.gross} currencyType={'AED'} />
        );
      },
    },

    {
      accessorKey: 'discount',
      header: 'Discount',
      enableEditing: true,
      size: 100,
      Cell: ({ row }) => {
        const isNewRow = row?.original?.salesOrderItemId === rowState?.newRowId;
        const isEditing =
          editingRowId === row?.original?.salesOrderItemId || isNewRow;
        return isEditing ? (
          <FmTextField
            name='discount'
            control={control}
            onBlur={handleBlur}
            onChange={(e) =>
              setFormValues((prevValues) => ({
                ...prevValues,
                discount: e.target.value,
              }))
            }
          />
        ) : (
          <BoldBox title={row?.original?.discount || ''} />
        );
      },
    },
    {
      accessorKey: 'vat',
      header: 'VAT(%)  ',
      enableEditing: true,
      size: 100,
      Cell: ({ row }) => {
        const isNewRow = row?.original?.salesOrderItemId === rowState?.newRowId;
        const isEditing =
          editingRowId === row?.original?.salesOrderItemId || isNewRow;
        return isEditing ? (
          <FmTextField
            name='vat'
            control={control}
            onBlur={handleBlur}
            pattern='Percentage'
            onChange={(e) =>
              setFormValues((prevValues) => ({
                ...prevValues,
                vat: e.target.value,
              }))
            }
          />
        ) : (
          <BoldBox title={row?.original?.vat || ''} />
        );
      },
    },
    {
      accessorKey: 'amountWithTax',
      header: 'VAT Amount',
      enableEditing: true,
      Cell: ({ row }) => {
        const isNewRow = row?.original?.salesOrderItemId === rowState?.newRowId;
        const isEditing =
          editingRowId === row?.original?.salesOrderItemId || isNewRow;
        const amountWithTax = watch('amountWithTax');
        return isEditing ? (
          <CurrencyView value={amountWithTax} />
        ) : (
          <CurrencyView
            value={row?.original?.amountWithTax}
            currencyType={'AED'}
          />
        );
      },
    },
    {
      accessorKey: 'amount',
      header: 'Total',
      enableEditing: true,
      Cell: ({ row }) => {
        const isNewRow = row?.original?.salesOrderItemId === rowState?.newRowId;
        const isEditing =
          editingRowId === row?.original?.salesOrderItemId || isNewRow;
        const amount = watch('amount');
        return isEditing ? (
          <CurrencyView value={amount} />
        ) : (
          <CurrencyView value={row?.original?.amount} currencyType={'AED'} />
        );
      },
    },
    {
      accessorKey: 'remarks',
      header: 'Remarks',
      enableEditing: true,

      Cell: ({ row }) => {
        const isNewRow = row?.original?.salesOrderItemId === rowState?.newRowId;
        const isEditing =
          editingRowId === row?.original?.salesOrderItemId || isNewRow;
        return isEditing ? (
          <FmTextField
            name='remarks'
            control={control}
            onBlur={handleBlurAndFetch}
          />
        ) : (
          <BoldBox title={row?.original?.remarks || ''} />
        );
      },
    },
    {
      header: 'Action',
      enableColumnPinning: true,
      enableEditing: false,
      enableSorting: false,
      size: 100,
      muiTableBodyCellProps: {
        className: 'sticky-pinning-right',
      },
      muiTableHeadCellProps: {
        className: 'sticky-pinning-right',
      },
      Cell: ({ row, table }) => {
        const isNewRow = row?.original?.salesOrderItemId === rowState?.newRowId;
        const isEditing =
          editingRowId === row?.original?.salesOrderItemId || isNewRow;

        const isLastRow = row.index === table.getRowModel().rows.length - 1;

        return (
          <Box className='flex gap-2'>
            {isEditing ? (
              <>
                <Button
                  onClick={() => handleCancel(row)}
                  className='py-0.5 px-2'
                  variant='contained'
                  color='error'
                >
                  Close
                </Button>
                {isLastRow && rowState?.isCreatingRow === false && (
                  <Button
                    onFocus={() => handleAddNewRow()}
                    hidden={true}
                  ></Button>
                )}
              </>
            ) : (
              <Box className='flex gap-2'>
                <TableActionButtons
                  Actions={ActionData?.map((action) => ({
                    ...action,
                    onClick: () => action.onClick(row?.original),
                  }))}
                />

                {isLastRow &&
                  <Button
                    variant='contained'
                    color='primary'
                    className='px-3 py-1'
                    onClick={() => {
                      setEditingRowIndex(row?.index + 1);
                      handleAddNewRow();
                    }}
                  >
                    Add
                  </Button>
                }
              </Box>
            )}
          </Box>
        );
      },
    },
  ];

  return (
    <>
      <GlassCard className='h-[400px]'>
        <FmMRTDataTable
          rows={rows}
          columns={columns}
          onCreateRow={handleAddNewRow}
          isCreatingRow={rowState?.isCreatingRow}
          enableRowSelection={false}
          rowCount={tableRecordCounts}
          editingRow={editingRowId}
          pagination={pagination}
          fetchData={fetchData}
          sorting={sorting}
          columnFilters={columnFilters}
          globalFilter={globalFilter}
          setPagination={setPagination}
          setSorting={setSorting}
          setColumnFilters={setColumnFilters}
          setGlobalFilter={setGlobalFilter}
          isLoading={isLoading}
          setSearchKeyword={setSearchKeyword}
          searchKeyword={searchKeyword}
          handleRowDoubleClick={(row) => {
            handleEdit(row?.original);
            setEditingRowIndex(row?.index);
          }}
          showSerialNumber={true}
          muiTableContainerProps={{
            sx: {
              maxHeight: '100%',
              flex: 1,
            },
          }}
        />
      </GlassCard>

      <GlassCard className='p-3 mt-2 bg-teal-700 text-white uppercase border-none'>
        <Stack
          direction='row'
          flexWrap='wrap'
          spacing={3}
          justifyContent='space-between'
          divider={<Divider orientation='vertical' flexItem />}
        >
          {billingValues?.map((item, i) => {
            return (
              <Box key={i} className='flex justify-between items-center'>
                <Box>{item.name}</Box>
                <Box className='font-bold ms-3'>{item.value}</Box>
              </Box>
            );
          })}
        </Stack>
      </GlassCard>
    </>
  );
};

export default VewMrtTableSalesOrder;
