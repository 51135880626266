import React from 'react';
import { Button, ButtonGroup, ButtonProps } from '@mui/material';
import { Icon } from '@iconify/react';
import { useNavigate, useLocation } from 'react-router-dom';
import { Box } from '@mui/material';

interface DualNavigationButtonProps extends Omit<ButtonProps, 'onClick'> {
  previousLabel?: string;
  nextLabel?: string;
  onNavClick: (val: boolean) => void;
  previousDisabled?: boolean;
  nextDisabled?: boolean;
}
const BackToButton = ({ title, ...props }) => {
  return (
    <Button
      variant='text'
      {...props}
      sx={{
        textTransform: 'inherit',
        color: 'primary.main',
        display: 'flex',
        alignItems: 'center',
        fontSize: 'inherit',
        fontWeight: '700',
        borderRadius: '35px',
        px: '20px',
        '& svg': {
          fontSize: '22px',
          marginRight: '10px',
        },
      }}
    >
      <Icon icon='mdi:arrow-back' /> {title}
    </Button>
  );
};

export const HeaderBackToButton = ({ title, ...props }) => {
  return (
    <Button
      variant='text'
      {...props}
      sx={{
        textTransform: 'inherit',
        color: 'text.main',
        display: 'flex',
        alignItems: 'center',
        fontWeight: '600',
        borderRadius: '4px',
        '& svg': {
          fontSize: '25px',
        },
      }}
      startIcon={<Icon icon='radix-icons:arrow-left' />}
    >
      {title}
    </Button>
  );
};

export const BacktoList = ({ title = 'Back to List', ...props }) => {
  const navigate = useNavigate();
  const location = useLocation();

  const handleBackClick = () => {
    const pathSegments = location.pathname.split('/');
    if (pathSegments.length > 2) {
      pathSegments.pop();
      pathSegments.pop();
    }

    const newPath = pathSegments.join('/');
    navigate(newPath);
  };

  return (
    <Button
      variant='text'
      {...props}
      sx={{
        textTransform: 'inherit',
        color: 'primary.main',
        display: 'flex',
        alignItems: 'center',
        fontWeight: 700,
        borderRadius: '4px',
        padding: '0px',
        '& svg': {
          fontSize: '25px',
        },
      }}
      startIcon={<Icon icon='mdi:arrow-back' />}
      onClick={handleBackClick}
    >
      {title}
    </Button>
  );
};

export const BacktoListSimple = ({ title = 'Back to List', ...props }) => {
  const navigate = useNavigate();

  return (
    <Button
      variant='text'
      {...props}
      sx={{
        textTransform: 'inherit',
        color: 'primary.main',
        display: 'flex',
        alignItems: 'center',
        fontWeight: 700,
        borderRadius: '4px',
        padding: '0px',
        '& svg': {
          fontSize: '25px',
        },
      }}
      startIcon={<Icon icon='mdi:arrow-back' />}
      onClick={() => navigate(-1)}
    >
      {title}
    </Button>
  );
};

export const DualNavigationButton = (props: DualNavigationButtonProps) => {
  const {
    previousLabel = 'Previous',
    nextLabel = 'Next',
    onNavClick,
    previousDisabled = false,
    nextDisabled = false,
    ...additionalprops
  } = props;
  return (
    <ButtonGroup
      sx={{
        display: 'flex',
        alignItems: 'center',
        gap: 1,
      }}
      {...additionalprops}
    >
      <Button
        variant='text'
        onClick={() => onNavClick(false)}
        disabled={previousDisabled}
        sx={{
          textTransform: 'inherit',
          display: 'flex',
          alignItems: 'center',
          fontWeight: 400,
          borderRadius: '4px 0 0 4px',
          padding: '0px 5px',
          borderRight: 'none',
          '& svg': {
            fontSize: '20px',
            marginRight: '5px',
          },
          ...(previousDisabled && {
            opacity: 0.6,
            cursor: 'not-allowed',
          }),
        }}
      >
        <Icon icon='ri:arrow-left-s-line' />
        {previousLabel}
      </Button>
      <Box>|</Box>
      <Button
        variant='text'
        onClick={() => onNavClick(true)}
        disabled={nextDisabled}
        sx={{
          textTransform: 'inherit',
          display: 'flex',
          alignItems: 'center',
          fontWeight: 400,
          borderRadius: '0 4px 4px 0',
          padding: '0px 5px',
          borderLeft: 'none',
          '& svg': {
            fontSize: '20px',
            marginLeft: '5px',
          },
          ...(nextDisabled && {
            opacity: 0.6,
            cursor: 'not-allowed',
          }),
        }}
      >
        {nextLabel}
        <Icon icon='ri:arrow-right-s-line' />
      </Button>
    </ButtonGroup>
  );
};
export default BackToButton;
