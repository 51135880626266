import React, { useEffect, useState } from 'react';
import GlassCard from './../../../../components/small/GlassCard.tsx';
import { Box, Button } from '@mui/material';
import useTableLogic from '../../../../components/helpers/MRTUseTableLogic.tsx';
import Icons from '../../../../utils/Icon.jsx';
import Crud_Service from '../../../../apis/CrudService.jsx';
import { toast } from 'react-toastify';
import dayjs from 'dayjs';
import { Controller, useForm } from 'react-hook-form';
import TableActionButtons from '../../../../components/_form/TableActionButtons.jsx';
import FmSearchableSelect from '../../../../components/_mui/FmSearchableSelect.tsx';
import FmTextField from '../../../../components/_mui/FmTextField.tsx';
import MuiDatePickerOne from '../../../../components/_mui/MuiDatePickerOne.tsx';
import FmMRTDataTable from '../../../../components/FmMRTDataTable.tsx';
import { yupResolver } from '@hookform/resolvers/yup';
import { editSalesQuotationSchema } from '../../../../utils/CommonvalidationSchemas.tsx';
import { useNavigate } from 'react-router-dom';

const BoldBox = ({ title, primary }) => {
  return <Box sx={{ color: primary && 'primary.main' }}>{title}</Box>;
};

const CreateSalesQuotationList = (props) => {
  const { type, getData } = props;
  const {
    rows,
    isLoading,
    pagination,
    sorting,
    columnFilters,
    globalFilter,
    tableRecordCounts,
    setPagination,
    setSorting,
    setColumnFilters,
    setGlobalFilter,
    fetchData,
    setSearchKeyword,
    searchKeyword,
  } = useTableLogic('salesquotations');
  const [viewDetails, setViewdetails] = useState(null);
  const [viewRow, setViewRow] = useState([]);
  const crud = new Crud_Service();
  const navigate = useNavigate();
  const { handleSubmit, control, setValue } = useForm({
    resolver: yupResolver(editSalesQuotationSchema),
    mode: 'onChange',
  });
  const [editingRowData, setEditingRowData] = useState([]);
  const [editingRowId, setEditingRowId] = useState(null);

  useEffect(() => {
    viewDetails && fetchSalesQuotation();
  }, [viewDetails]);

  useEffect(() => {
    getData && fetchData();
  }, [getData]);

  const fetchSalesQuotation = async () => {
    await crud.getSingle('salesquotations', viewDetails, (_err, res) => {
      if (res?.status === 200) {
        setViewRow(res?.data);
      } else {
      }
    });
  };

  const handleDeleteSalesQuotation = async (view) => {
    await crud.remove(
      'salesquotations',
      view?.salesQuotationId,
      (_err, res) => {
        if (res?.status === 204) {
          fetchData();
          toast.success('Deleted Successfully');
        } else {
        }
      }
    );
  };

  const ActionData = [
    {
      name: 'Edit',
      icon: Icons.edit,
      onClick: (props) => navigate(`edit/${props?.salesQuotationId}`),
    },
    {
      name: 'Delete',
      icon: Icons.delete,
      onClick: (props) => handleDeleteSalesQuotation(props),
      danger: true,
    },
  ];

  const handleEditItem = async (values) => {
    const data = {
      ...values,
      quotationDate: dayjs(values?.quotationDate).format('YYYY-MM-DD'),
      contactPersonId:
        values.contactPersonId || editingRowData?.contactPersonId,
      companyId: values?.companyId || editingRowData?.companyId,
      locationId: values.locationId || editingRowData?.locationId,
      regionId: values.regionId || editingRowData?.regionId,
      workOrderId: values.workOrderId || editingRowData?.workOrderId,
      quotationStatusId:
        values.quotationStatusId || editingRowData?.quotationStatusId,
      status: 2,
      salesOrderTypeId: type,
    };
    setEditingRowId(values?.salesQuotationId);
    setEditingRowData(values);
    await crud.update(
      'salesquotations',
      editingRowData?.salesQuotationId,
      data,
      (_err, res) => {
        if (res?.status === 200) {
          toast.success('Updated Successfully');
          fetchData();
          setEditingRowId(null);
        } else {
        }
      }
    );
  };
  const columns = [
    {
      header: 'Actions',
      Cell: ({ row }) => (
        <Box className='flex gap-2'>
          {editingRowId === row?.original?.salesQuotationId ? (
            <>
              <Button onClick={handleSubmit(handleEditItem)}>Save</Button>
              <Button onClick={handleCancel}>Cancel</Button>
            </>
          ) : (
            <TableActionButtons
              Actions={ActionData?.map((action) => ({
                ...action,
                onClick: () => action.onClick(row?.original),
              }))}
            />
          )}
        </Box>
      ),
    },
    {
      accessorKey: 'salesQuotationName',
      header: 'Sales Quotation Name',
      enableEditing: true,
      Cell: ({ cell }) => {
        const order = rows?.find(
          (row) =>
            row?.salesQuotationId === cell?.row?.original?.salesQuotationId
        );
        return editingRowId === cell?.row?.original?.salesQuotationId ? (
          <FmTextField
            name='salesQuotationName'
            control={control}
            defaultValue={editingRowData?.salesQuotationName}
            label='Sales Quotation Name'
            required
          />
        ) : (
          <BoldBox title={order?.salesQuotationName} />
        );
      },
    },
    {
      accessorKey: 'quotationDate',
      header: 'Quotation Date',
      enableEditing: true,
      Cell: ({ row }) => {
        return editingRowId === row?.original?.salesQuotationId ? (
          <Controller
            name='quotationDate'
            control={control}
            defaultValue={editingRowData?.quotationDate}
            render={({ field }) => (
              <MuiDatePickerOne
                {...field}
                format='DD-MM-YYYY'
                value={field?.value ? dayjs(field?.value) : null}
                onChange={field.onChange}
              />
            )}
          />
        ) : row?.original?.quotationDate ? (
          dayjs(row?.original?.quotationDate).format('DD-MM-YYYY')
        ) : (
          ''
        );
      },
    },
    {
      accessorKey: 'workOrderId', // Client is searchable select
      header: 'Work Order',
      enableEditing: true,
      Cell: ({ cell }) => {
        const order = rows?.find(
          (row) =>
            row?.salesQuotationId === cell?.row?.original?.salesQuotationId
        );
        return editingRowId === cell?.row?.original?.salesQuotationId ? (
          <FmSearchableSelect
            name='workOrderId'
            control={control}
            apiUrl='workorders'
            valueField='workOrderId'
            headerField={['workOrderId']}
            labelField={['workOrderCode']}
            defaultValue={editingRowData}
            showField={['workOrderCode']}
            label={'Work Order'}
            required
          />
        ) : (
          <BoldBox title={order?.workOrderCode} />
        );
      },
    },

    {
      accessorKey: 'regionId', // Client is searchable select
      header: 'Region',
      enableEditing: true,
      Cell: ({ cell }) => {
        const order = rows?.find(
          (row) =>
            row?.salesQuotationId === cell?.row?.original?.salesQuotationId
        );
        return editingRowId === cell?.row?.original?.salesQuotationId ? (
          <FmSearchableSelect
            name='regionId'
            control={control}
            apiUrl='regions'
            valueField='regionId'
            defaultValue={editingRowData}
            headerField={['regionName', 'regionCode']}
            labelField={['regionName', 'regionCode']}
            showField={['regionName', 'regionCode']}
            label={'Region'}
            required
          />
        ) : (
          <BoldBox title={order?.regionName} />
        );
      },
    },
    {
      accessorKey: 'companyId',
      header: 'Company',
      enableEditing: true,
      Cell: ({ cell }) => {
        const order = rows?.find(
          (row) =>
            row?.salesQuotationId === cell?.row?.original?.salesQuotationId
        );
        return editingRowId === cell?.row?.original?.salesQuotationId ? (
          <FmSearchableSelect
            name='companyId'
            control={control}
            apiUrl='companies'
            valueField='companyId'
            defaultValue={editingRowData}
            headerField={['companyName', 'companyCode']}
            labelField={['companyName', 'companyCode']}
            showField={['companyName']}
            label={'Company'}
            required
          />
        ) : (
          <BoldBox title={order?.companyName} />
        );
      },
    },
    {
      accessorKey: 'locationId',
      header: 'Supply Location',
      enableEditing: true,
      Cell: ({ cell }) => {
        const order = rows?.find(
          (row) =>
            row?.salesQuotationId === cell?.row?.original?.salesQuotationId
        );
        return editingRowId === cell?.row?.original?.salesQuotationId ? (
          <FmSearchableSelect
            name='locationId'
            control={control}
            apiUrl='locations'
            valueField='locationId'
            defaultValue={editingRowData}
            labelField={['locationName', 'locationCode']}
            headerField={['locationName', 'locationCode']}
            showField={['locationName']}
            label={'Location'}
            required
          />
        ) : (
          <BoldBox title={order?.locationName} />
        );
      },
    },
    {
      accessorKey: 'paymentTerms',
      header: 'Payment Terms',
      enableEditing: true,
      Cell: ({ cell }) => {
        const order = rows?.find(
          (row) =>
            row?.salesQuotationId === cell?.row?.original?.salesQuotationId
        );
        return editingRowId === cell?.row?.original?.salesQuotationId ? (
          <FmTextField
            name='paymentTerms'
            control={control}
            defaultValue={editingRowData?.paymentTerms}
            label='Payment Terms'
            required
          />
        ) : (
          <BoldBox title={order?.paymentTerms} />
        );
      },
    },
    {
      accessorKey: 'narration',
      header: 'Narration',
      enableEditing: true,
      Cell: ({ cell }) => {
        const order = rows?.find(
          (row) =>
            row?.salesQuotationId === cell?.row?.original?.salesQuotationId
        );
        return editingRowId === cell?.row?.original?.salesQuotationId ? (
          <FmTextField
            name='narration'
            control={control}
            defaultValue={editingRowData?.narration}
            label='Narration'
          />
        ) : (
          <BoldBox title={order?.narration} />
        );
      },
    },
    {
      accessorKey: 'deliveryTerms',
      header: 'Delivery Terms',
      enableEditing: true,
      Cell: ({ cell }) => {
        const order = rows?.find(
          (row) =>
            row?.salesQuotationId === cell?.row?.original?.salesQuotationId
        );
        return editingRowId === cell?.row?.original?.salesQuotationId ? (
          <FmTextField
            name='deliveryTerms'
            control={control}
            defaultValue={editingRowData?.deliveryTerms}
            label='Delivery Terms'
            required
          />
        ) : (
          <BoldBox title={order?.deliveryTerms} />
        );
      },
    },
    {
      accessorKey: 'subject',
      header: 'Subject',
      enableEditing: true,
      Cell: ({ cell }) => {
        const order = rows?.find(
          (row) =>
            row?.salesQuotationId === cell?.row?.original?.salesQuotationId
        );
        return editingRowId === cell?.row?.original?.salesQuotationId ? (
          <FmTextField
            name='subject'
            control={control}
            defaultValue={editingRowData?.subject}
            label='Subject'
            required
          />
        ) : (
          <BoldBox title={order?.subject} />
        );
      },
    },
    {
      accessorKey: 'contactPersonId',
      header: 'Contact Person',
      enableEditing: true,
      Cell: ({ cell }) => {
        const order = rows?.find(
          (row) =>
            row?.salesQuotationId === cell?.row?.original?.salesQuotationId
        );
        return editingRowId === cell?.row?.original?.salesQuotationId ? (
          <FmSearchableSelect
            name='contactPersonId'
            control={control}
            apiUrl='loginusers'
            valueField='loginUserId'
            defaultValue={{
              loginUserId: editingRowData?.contactPersonId,
              employeeName: editingRowData?.contactPersonName,
              employeeMail: editingRowData?.contactPersonMail,
            }}
            labelField={['employeeCode', 'employeeName', 'employeeMail']}
            showField={['employeeCode', 'employeeName', 'employeeMail']}
            label={'Contact Person'}
            required
          />
        ) : (
          <BoldBox title={order?.contactPersonName} />
        );
      },
    },
    {
      accessorKey: 'notes',
      header: 'Notes',
      enableEditing: true,
      Cell: ({ cell }) => {
        const order = rows?.find(
          (row) =>
            row?.salesQuotationId === cell?.row?.original?.salesQuotationId
        );
        return editingRowId === cell?.row?.original?.salesQuotationId ? (
          <FmTextField
            name='notes'
            control={control}
            defaultValue={editingRowData?.notes}
            label='Notes'
            required
          />
        ) : (
          <BoldBox title={order?.notes} />
        );
      },
    },
    {
      accessorKey: 'validity',
      header: 'Validity',
      enableEditing: true,
      Cell: ({ cell }) => {
        const order = rows?.find(
          (row) =>
            row?.salesQuotationId === cell?.row?.original?.salesQuotationId
        );
        return editingRowId === cell?.row?.original?.salesQuotationId ? (
          <FmTextField
            name='validity'
            control={control}
            defaultValue={editingRowData?.validity}
            label='Validity'
            required
          />
        ) : (
          <BoldBox title={order?.validity} />
        );
      },
    },
    {
      accessorKey: 'quotationStatusId',
      header: 'Quotation Status',
      enableEditing: true,
      Cell: ({ cell }) => {
        const order = rows?.find(
          (row) =>
            row?.salesQuotationId === cell?.row?.original?.salesQuotationId
        );
        return editingRowId === cell?.row?.original?.salesQuotationId ? (
          <FmSearchableSelect
            name='quotationStatusId'
            control={control}
            apiUrl='salesquotations/statuses'
            valueField='salesQuotationStatusId'
            defaultValue={{
              salesQuotationStatusId: order?.quotationStatusId,
              salesQuotationStatusName: order?.quotationStatusName,
            }}
            labelField={['salesQuotationStatusName']}
            showField={['salesQuotationStatusName']}
            label={'Quotation Status'}
            required
          />
        ) : (
          <BoldBox title={order?.quotationStatusName} />
        );
      },
    },
    {
      accessorKey: 'remarks',
      header: 'Remarks',
      enableEditing: true,
      Cell: ({ cell }) => {
        const order = rows?.find(
          (row) =>
            row?.salesQuotationId === cell?.row?.original?.salesQuotationId
        );
        return editingRowId === cell?.row?.original?.salesQuotationId ? (
          <FmTextField
            name='remarks'
            control={control}
            defaultValue={editingRowData?.remarks}
            label='Remarks'
            required
          />
        ) : (
          <BoldBox title={order?.remarks} />
        );
      },
    },
    {
      accessorKey: 'attn',
      header: 'Attachments',
      enableEditing: true,
      Cell: ({ cell }) => {
        const order = rows?.find(
          (row) =>
            row?.salesQuotationId === cell?.row?.original?.salesQuotationId
        );
        return editingRowId === cell?.row?.original?.salesQuotationId ? (
          <FmTextField
            name='attn'
            control={control}
            defaultValue={editingRowData?.attn}
            label='Attn'
            required
          />
        ) : (
          <BoldBox title={order?.attn} />
        );
      },
    },
  ];

  const handleEdit = (row) => {
    setEditingRowId(row?.salesQuotationId);
    setEditingRowData({ ...row });
    Object.entries({
      ...row,
    }).forEach(([key, value]) => {
      setValue(key, value);
    });
  };
  const handleCancel = () => {
    setEditingRowId(null);
    setEditingRowData(null);
  };

  return (
    <>
      <GlassCard className='h-full-css'>
        <FmMRTDataTable
          columns={columns}
          enableRowSelection={false}
          rows={rows}
          rowCount={tableRecordCounts}
          editingRow={editingRowId}
          pagination={pagination}
          sorting={sorting}
          columnFilters={columnFilters}
          globalFilter={globalFilter}
          setPagination={setPagination}
          setSorting={setSorting}
          setColumnFilters={setColumnFilters}
          setGlobalFilter={setGlobalFilter}
          isLoading={isLoading}
          setSearchKeyword={setSearchKeyword}
          searchKeyword={searchKeyword}
          muiTableContainerProps={{
            sx: {
              maxHeight: '100%',
              flex: 1,
            },
          }}
        />
      </GlassCard>
    </>
  );
};

export default CreateSalesQuotationList;
