import React, { useEffect, useMemo, useRef, useState } from 'react';
import Crud_Service from '../../../../../apis/CrudService.jsx';
import { useNavigate, useParams } from 'react-router-dom';
import { useForm } from 'react-hook-form';
import { ContractBudgetSchema } from '../../../../../utils/CommonvalidationSchemas.tsx';
import { useHotkeys } from 'react-hotkeys-hook';
import FmTextField from '../../../../../components/_mui/FmTextField.tsx';
import { Box, Button, Typography } from '@mui/material';
import TableActionButtons from '../../../../../components/_form/TableActionButtons.jsx';
import GlassCard from '../../../../../components/small/GlassCard.tsx';
import FmMRTDataTable from '../../../../../components/FmMRTDataTable.tsx';
import MuiDialogOne from '../../../../../components/_mui/MuiDialogOne.jsx';
import useTableLogic from '../../../../../components/helpers/MRTUseTableLogic.tsx';
import { yupResolver } from '@hookform/resolvers/yup';
import Icons from '../../../../../utils/Icon.jsx';
import { toast } from 'react-toastify';
import InternalBudgetSupplier from './InternalBudgetSupplier.tsx';
import CurrencyView from '../../../../../components/_mui/FmCurrencyView.tsx';
import FmCurrencyTextField from '../../../../../components/_mui/FmCurrencyTextField.tsx';
import { salesOrderStatusEnum } from '../../../../../utils/CommonVariables.tsx';

const InternalBudget = (props) => {
  const { id } = useParams();
  const {
    rows,
    setRows,
    rowCount,
    isLoading,
    pagination,
    sorting,
    columnFilters,
    globalFilter,
    tableRecordCounts,
    setPagination,
    setSorting,
    setColumnFilters,
    setGlobalFilter,
    fetchData,
    setSearchKeyword,
    searchKeyword,
  } = useTableLogic(`internalbudgetings`, ['salesOrderId'], [id]);
  const crud = new Crud_Service();
  const [editingRowId, setEditingRowId] = useState(null);
  const [editingRowData, setEditingRowData] = useState(null);
  const {
    control,
    getValues,
    setValue,
    trigger,
    handleSubmit,
    formState: { isValid, errors },
  } = useForm({
    resolver: yupResolver(ContractBudgetSchema),
    mode: 'all',
  });
  const [tabValue, setTabValue] = useState(
    Number(localStorage.getItem('selectedBudgetTab')) || 1
  );
  const firstColumnInputRef = useRef(null);
  const [stateError, setStateError] = useState(false);
  const [loader, setLoader] = useState(false);
  const [editingRowIndex, setEditingRowIndex] = useState(null);
  const [open, setOpen] = useState(0);
  const [rowState, setRowState] = useState({
    editingRowId: null,
    newRowId: null,
    isCreatingRow: false,
  });
  const [rowCalculations, setRowCalculations] = useState({});
  const navigation = useNavigate();
  const [salesOrder, setSalesOrder] = useState(null);

  useEffect(() => {
    crud.getSingle(`salesorders`, id, (err, res) => {
      if (res?.status === 200) {
        setSalesOrder(res?.data);
      }
    });
  }, []);

  const handleChange = (_event, newValue) => {
    setTabValue(newValue);
    localStorage.setItem('selectedBudgetTab', newValue);
  };

  const updatedRows = useMemo(() => {
    const updatedRows = rows.map((row) => {
      const balance =
        row?.internalBudget -
        (row?.staffSalariesAmount +
          row?.otherPreliminariesAmount +
          row?.labourAmount +
          row?.materialsAmount +
          row?.supplierTotalAmount);

      const upsertInternalBudgetingSuppliers =
        row?.internalBudgetingSuppliers || [];
      const internalBudget = row?.internalBudget || row.budget;
      return {
        ...row,
        balance,
        internalBudget,
        upsertInternalBudgetingSuppliers,
      };
    });
    return updatedRows;
  }, [rows]);

  useEffect(() => {
    if (rows.length > 0) {
      const hasChanged = !updatedRows.every((row, index) => {
        const existingRow = rows[index];
        return (
          row.balance === existingRow.balance &&
          JSON.stringify(row.upsertInternalBudgetingSuppliers) ===
          JSON.stringify(existingRow.upsertInternalBudgetingSuppliers)
        );
      });

      if (hasChanged) {
        setRows(updatedRows);

        const calculations = updatedRows.reduce((acc, row) => {
          acc[row.internalBudgetingId] = {
            ...row, //change here to update row values
            balance: row.balance,
            upsertInternalBudgetingSuppliers:
              row.upsertInternalBudgetingSuppliers,
          };
          return acc;
        }, {});

        setRowCalculations(calculations);
      }
    }
  }, [updatedRows, rows, setRows]);

  const handleCancel = (row) => {
    setEditingRowId(null);
    setEditingRowData(null);
  };

  const handleEdit = (row) => {
    if (editingRowId) {
      return;
    }
    if (rowState?.newRowId) {
      setRows((prevRows) => {
        if (prevRows?.length > 0) {
          return prevRows?.slice(0, -1);
        }
        return prevRows;
      });
      setRowState((prevState) => ({
        ...prevState,
        newRowId: null,
        isCreatingRow: false,
      }));
    }
    setEditingRowId(row?.internalBudgetingId);
    Object.entries({
      ...row,
    }).forEach(([key, value]) => {
      setValue(key, value);
    });
    setEditingRowData({ ...row });
  };

  const handleInputChange = async (e, field, rowId) => {
    const value = parseFloat(e.target.value) || 0;
    setValue(field, value);
    const currentValues = getValues();

    const supplierTotal =
      rows[rowId]?.upsertInternalBudgetingSuppliers?.reduce(
        (sum, supplier) => sum + (Number(supplier.amount) || 0),
        0
      ) || 0;
    const newTotal =
      (Number(currentValues?.staffSalariesAmount) || 0) +
      (Number(currentValues?.otherPreliminariesAmount) || 0) +
      (Number(currentValues?.labourAmount) || 0) +
      (Number(currentValues?.materialsAmount) || 0) +
      (Number(
        currentValues?.upsertInternalBudgetingSuppliers?.reduce(
          (sum, supplier) => sum + (Number(supplier.amount) || 0),
          0
        )
      ) || 0);

    const budget = currentValues?.internalBudget || 0;
    const balance = budget - newTotal;

    setRowCalculations((prev) => ({
      ...prev,
      [rowId]: {
        ...prev[rowId],
        totalAmount: newTotal,
        balance: balance,
      },
    }));

    setEditingRowData((prev) => ({
      ...prev,
      balance: balance,
    }));

    await trigger();
  };

  const handleAddNewRow = async () => { };

  useHotkeys(
    'Enter',
    () => handleAddNewRow(),
    {
      enableOnTags: ['INPUT', 'TEXTAREA'], // Optional: Enable hotkey only when focused on specific tags
    },
    [editingRowIndex, rows, stateError, loader]
  );

  useHotkeys(
    'Tab',
    (event) => {
      if (event.key !== 'Tab') return;
      if (stateError) {
        event.preventDefault();
        return;
      }
      const totalRows = rows.length;
      const nextRowIndex = editingRowIndex + 1;

      if (
        nextRowIndex < totalRows &&
        stateError === false &&
        loader === false
      ) {
        handleEdit(rows[nextRowIndex]);
        setEditingRowIndex(nextRowIndex);
      }
    },
    [editingRowIndex, rows, stateError, loader, isValid]
  );

  useEffect(() => {
    if (editingRowIndex !== null && firstColumnInputRef.current && !isLoading) {
      firstColumnInputRef.current.focus();
    }
  }, [editingRowIndex, isLoading]);

  const handleSubmitInternalBudget = async (values) => {
    setLoader(true);
    const convertedValues = {
      staffSalariesAmount: Number(values?.staffSalariesAmount),
      otherPreliminariesAmount: Number(values?.otherPreliminariesAmount),
      labourAmount: Number(values?.labourAmount),
      materialsAmount: Number(values?.materialsAmount),
      internalBudget: Number(values?.internalBudget),
      upsertInternalBudgetingSuppliers:
        rowCalculations[values.internalBudgetingId]
          ?.upsertInternalBudgetingSuppliers,
    };

    const updatedCombinedData = {
      divisionId: Number(values?.divisionId),
      budget: Number(values?.budget),
      staffSalariesAmount: Number(values?.staffSalariesAmount),
      otherPreliminariesAmount: Number(values?.otherPreliminariesAmount),
      labourAmount: Number(values?.labourAmount),
      materialsAmount: Number(values?.materialsAmount),
      internalBudget: Number(values?.internalBudget),
      salesOrderId: Number(values?.salesOrderId),
      upsertInternalBudgetingSuppliers:
        rowCalculations[values.internalBudgetingId]
          ?.upsertInternalBudgetingSuppliers ?? [],
      status: 2,
    };
    if (editingRowId === null) {
      await crud.create('internalbudgetings', convertedValues, (err, res) => {
        if (res?.status === 201) {
          setEditingRowId(res?.data?.internalBudgetingId);
          toast.success('Internal Budget Created Successfully');
        } else {
        }
      });
    } else {
      await crud.update(
        'internalbudgetings',
        editingRowId,
        updatedCombinedData,
        (err, res) => {
          if (res?.status === 200) {
            if (rows.some((row) => row.isNew)) {
              const updatedRow = res?.data;
              const updatedRows = [...rows];
              updatedRows[editingRowIndex] = {
                ...updatedRow,
                isEditable: true,
                isNew: true,
              };
              setRows(updatedRows);
              setStateError(false);
              setLoader(false);
              setEditingRowId(null);
              fetchData();
              toast.success('Internal Budget Updated Successfully');
            } else {
              setStateError(false);
              setLoader(false);
              const updatedRow = res?.data;
              const updatedRows = [...rows];

              updatedRows[editingRowIndex] = updatedRow;
              setEditingRowId(null);
              fetchData();
              toast.success('Internal Budget Updated Successfully');

              setRows(updatedRows);
            }
          } else {
            setStateError(true);
          }
        }
      );
    }
  };

  const ActionData = [
    {
      name: 'Edit',
      icon: Icons.edit,
      onClick: (props) => {
        handleEdit(props);
      },
    },
  ];

  const addSuppliers = (props, id) => {
    rowCalculations[id].upsertInternalBudgetingSuppliers =
      props.upsertInternalBudgetingSuppliers;
    const totalSupplierAmount = props?.upsertInternalBudgetingSuppliers?.reduce(
      (total, item) => {
        return total + (parseFloat(item.amount) || 0);
      },
      0
    );
    const balance = rowCalculations[id]?.balance - totalSupplierAmount;
    setValue(
      'upsertInternalBudgetingSuppliers',
      props.upsertInternalBudgetingSuppliers
    );
    setRowCalculations((prev) => ({
      ...prev,
      [id]: {
        ...prev[id],
        balance,
      },
    }));
  };

  const columns = [
    {
      header: 'Actions',
      enableColumnPinning: true,
      enableEditing: false,
      enableSorting: false,
      size: 80,
      Cell: ({ row }) => {
        const isNewRow =
          row?.original?.internalBudgetingId === rowState?.newRowId;
        const isEditing =
          editingRowId === row?.original?.internalBudgetingId || isNewRow;

        return (
          <Box className='flex gap-2'>
            {isEditing ? (
              <>
                <Button
                  onClick={handleSubmit(handleSubmitInternalBudget)}
                  className='px-1 py-0.5'
                  variant='contained'
                  color='primary'
                >
                  Save
                </Button>
                <Button
                  onClick={handleCancel}
                  className='px-1 py-0.5'
                  variant='contained'
                  color='error'
                >
                  Cancel
                </Button>
              </>
            ) : (
              <TableActionButtons
                Actions={ActionData?.map((action) => ({
                  ...action,
                  onClick: () => action.onClick(row?.original),
                }))}
              />
            )}
          </Box>
        );
      },
    },
    {
      accessorKey: 'divisionId',
      header: 'Division',
      enableEditing: true,
      Cell: ({ row }) => {
        return row?.original?.divisionName || '';
      },
    },

    {
      accessorKey: 'internalBudget',
      header: 'Internal Budget',
      enableEditing: true,
      Cell: ({ row }) => {
        const isNewRow =
          row?.original?.internalBudgetingId === rowState?.newRowId;
        const isEditing =
          editingRowId === row?.original?.internalBudgetingId || isNewRow;
        return isEditing ? (
          <FmCurrencyTextField
            name='internalBudget'
            control={control}

            variant='outlined'
            onChange={(e) =>
              handleInputChange(
                e,
                'internalBudget',
                row.original.internalBudgetingId
              )
            }
          />
        ) : (
          <CurrencyView
            value={row?.original?.internalBudget}
            currencyType={'AED'}
          />
        );
      },
    },

    {
      accessorKey: 'staffSalariesAmount',
      header: 'Staff Salaries',
      enableEditing: true,
      Cell: ({ row }) => {
        const isNewRow =
          row?.original?.internalBudgetingId === rowState?.newRowId;
        const isEditing =
          editingRowId === row?.original?.internalBudgetingId || isNewRow;
        return isEditing ? (
          <FmCurrencyTextField
            name='staffSalariesAmount'
            control={control}
            variant='outlined'
            onChange={(e) =>
              handleInputChange(
                e,
                'staffSalariesAmount',
                row.original.internalBudgetingId
              )
            }
          />
        ) : (
          <CurrencyView
            value={row?.original?.staffSalariesAmount}
            currencyType={'AED'}
          />
        );
      },
    },
    {
      accessorKey: 'otherPreliminariesAmount',
      header: 'Other Preliminaries',
      enableEditing: true,
      Cell: ({ row }) => {
        const isNewRow =
          row?.original?.internalBudgetingId === rowState?.newRowId;
        const isEditing =
          editingRowId === row?.original?.internalBudgetingId || isNewRow;
        return isEditing ? (
          <FmCurrencyTextField
            name='otherPreliminariesAmount'
            control={control}
            variant='outlined'
            onChange={(e) =>
              handleInputChange(
                e,
                'otherPreliminariesAmount',
                row.original.internalBudgetingId
              )
            }
          />
        ) : (
          <CurrencyView
            value={row?.original?.otherPreliminariesAmount}
            currencyType={'AED'}
          />
        );
      },
    },
    {
      accessorKey: 'labourAmount',
      header: 'Labour Amount',
      enableEditing: true,
      Cell: ({ row }) => {
        const isNewRow =
          row?.original?.internalBudgetingId === rowState?.newRowId;
        const isEditing =
          editingRowId === row?.original?.internalBudgetingId || isNewRow;
        return isEditing ? (
          <FmCurrencyTextField
            name='labourAmount'
            control={control}
            variant='outlined'
            onChange={(e) =>
              handleInputChange(
                e,
                'labourAmount',
                row.original.internalBudgetingId
              )
            }
          />
        ) : (
          <CurrencyView
            value={row?.original?.labourAmount}
            currencyType={'AED'}
          />
        );
      },
    },
    {
      accessorKey: 'materialsAmount',
      header: 'Material Amount',
      enableEditing: true,
      Cell: ({ row }) => {
        const isNewRow =
          row?.original?.internalBudgetingId === rowState?.newRowId;
        const isEditing =
          editingRowId === row?.original?.internalBudgetingId || isNewRow;
        return isEditing ? (
          <FmCurrencyTextField
            name='materialsAmount'
            control={control}
            variant='outlined'
            sx={{ width: '100%' }}
            onChange={(e) =>
              handleInputChange(
                e,
                'materialsAmount',
                row.original.internalBudgetingId
              )
            }
          />
        ) : (
          <CurrencyView
            value={row?.original?.materialsAmount}
            currencyType={'AED'}
          />
        );
      },
    },
    {
      accessorKey: 'balance',
      header: 'Balance',
      size: 100,
      Cell: ({ row }) => {
        const rowId = row.original.internalBudgetingId;

        const balance = rowCalculations[rowId]?.balance || 0;
        return <CurrencyView value={balance} currencyType={'AED'} />;
      },
    },
    {
      header: 'Supplier',
      size: 300,
      enableColumnPinning: true,
      Cell: ({ row }) => {
        const isNewRow = row?.original?.internalBudgetingId === rowState?.newRowId;
        const isEditing = editingRowId === row?.original?.internalBudgetingId || isNewRow;
        const internalBudgetingId = row.original.internalBudgetingId;
        const supplierData = rowCalculations[internalBudgetingId]?.upsertInternalBudgetingSuppliers || [];
        const totalAmount = supplierData.reduce((acc, curr) => acc + curr.amount, 0, 0);

        return (
          <Box className="w-full flex items-center gap-2 justify-between">
            <Button
              startIcon={Icons.plus}
              onClick={() => setOpen(internalBudgetingId)}
              className="px-3 py-0.5"
              variant="contained"
            >
              Add ({supplierData.length})
            </Button>
            <Box className="font-semibold">
              Total: <CurrencyView value={totalAmount} currencyType="AED" />
            </Box>
          </Box>
        );
      },
    }
  ];

  const handleApprove = async () => {
    await crud.update(
      `internalbudgetings/${id}/update-status`,
      '',
      {},
      (err, res) => {
        if (res?.status === 200) {
          toast.success('Internal Budget Approved Successfully');
        } else {
        }
      }
    );
  };

  return (
    <>
      <GlassCard className='h-[400px]'>
        <FmMRTDataTable
          rows={rows}
          columns={columns}
          isCreatingRow={rowState?.isCreatingRow}
          enableRowSelection={false}
          rowCount={tableRecordCounts}
          editingRow={editingRowId}
          fetchData={fetchData}
          pagination={pagination}
          sorting={sorting}
          columnFilters={columnFilters}
          onCreateRow={salesOrder?.approved ? null : handleApprove}
          buttonName='Approve'
          buttonColor='secondary'
          globalFilter={globalFilter}
          setPagination={setPagination}
          setSorting={setSorting}
          setColumnFilters={setColumnFilters}
          setGlobalFilter={setGlobalFilter}
          isLoading={isLoading}
          setSearchKeyword={setSearchKeyword}
          searchKeyword={searchKeyword}
          handleRowDoubleClick={(row) => {
            handleEdit(row?.original);
            setEditingRowIndex(row?.index);
          }}
          showSerialNumber={true}
          muiTableContainerProps={{
            sx: {
              maxHeight: '100%',
              flex: 1,
            },
          }}
          rightPinning={['Supplier']}
        />
      </GlassCard>

      <MuiDialogOne
        title={`Suppliers (Balance Budget ${rowCalculations[open]?.balance || 0
          })`}
        open={open !== 0}
        onClose={() => setOpen(0)}
        width='900px'
      >
        <InternalBudgetSupplier
          setOpen={setOpen}
          fetchData={fetchData}
          id={open}
          rows={rowCalculations}
          addSuppliers={addSuppliers}
        />
      </MuiDialogOne>
    </>
  );
};

export default InternalBudget;
