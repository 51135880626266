import React from 'react';
import { Box } from '@mui/material';
import PerfectScrollbar from 'react-perfect-scrollbar';

const ToolbarBoxesFlex = ({ children }) => {
  return (
    <Box
      className='flex justify-between items-center p-1.5'
      sx={{ borderBottom: '1px solid', borderColor: 'border.main' }}
    >
      {children}
    </Box>
  );
};

const PerfectScrollBarBoxes = ({ children, ...props }) => {
  return (
    <Box {...props}>
      <PerfectScrollbar options={{ wheelPropagation: false }}>
        {children}
      </PerfectScrollbar>
    </Box>
  );
};

const OrderViewSidebarBoxes = ({ side, children }) => {
  return (
    <Box className='flex gap-5 w-full justify-start'>
      <Box className='flex-1 w-96'>{children}</Box>
      <Box sx={{ minWidth: '320px', width: '320px' }}>{side}</Box>
    </Box>
  );
};

export { ToolbarBoxesFlex, PerfectScrollBarBoxes, OrderViewSidebarBoxes };
