import React, { useEffect, useState } from 'react';
import GlassCard from '../../../../../components/small/GlassCard.tsx';
import BackToButton from '../../../../../components/small/BackToButton.tsx';
import { useNavigate, useLocation, useParams } from 'react-router-dom';
import { ViewTable } from '../../../../../components/_form/FormElements.jsx';
import { Grid } from '@mui/material';
import DashboardLayout from './../../../../../components/DashboardLayout.tsx';
import TenantVehicleList from './TenantVehicleList.tsx';
import ItemsPropertyList from './ItemsPropertyList.tsx';
import ReceiptKeysList from './ReceiptKeysList.tsx';
import useTableLogic from '../../../../../components/helpers/MRTUseTableLogic.tsx';
import Crud_Service from '../../../../../apis/CrudService.jsx';
import EditCheckInForm from '../../CreateCheckInForm/View/EditCheckInForm.tsx';

const ViewCreateCheckInForm = () => {
  const navigation = useNavigate();
  const location = useLocation();
  const [viewData, setViewData] = useState([]);
  const {
    rows,
    isLoading,
    pagination,
    sorting,
    columnFilters,
    globalFilter,
    tableRecordCounts,
    setPagination,
    setSorting,
    setColumnFilters,
    setGlobalFilter,
    fetchData,
    setSearchKeyword,
    searchKeyword,
  } = useTableLogic('checkinformtenantvehicles');

  const { id } = useParams();

  const crud = new Crud_Service();

  const viewDataFetch = async () => {
    crud.getSingle('checkinforms', id, (err, res) => {
      if (res?.status === 200) {
        setViewData(res?.data);
      } else {
        setViewData([]);
      }
    });
  };
  useEffect(() => {
    if (id) {
      viewDataFetch();
    }
  }, [id]);
  useEffect(() => {
    document.title = 'View | Check In Form | FM Division ';
  });

  return (
    <>
      <DashboardLayout title='View Check In Form'>
        {/* glasscard */}
        <EditCheckInForm/>
        {/* glasscard */}

        <TenantVehicleList
          rows={rows}
          tableRecordCounts={tableRecordCounts}
          pagination={pagination}
          sorting={sorting}
          fetchData={fetchData}
          setPagination={setPagination}
          setSorting={setSorting}
          columnFilters={columnFilters}
          globalFilter={globalFilter}
          setColumnFilters={setColumnFilters}
          setGlobalFilter={setGlobalFilter}
          isLoading={isLoading}
          setSearchKeyword={setSearchKeyword}
          searchKeyword={searchKeyword}
          checkInFormId={id}
        />
        <ItemsPropertyList CheckInFormId={id} />
        <ReceiptKeysList CheckInFormId={id} />
      </DashboardLayout>
    </>
  );
};

export default ViewCreateCheckInForm;
