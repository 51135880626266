import React from 'react';
import GlassCard from '../../../components/small/GlassCard.tsx';
import { Grid } from '@mui/material';
import MuiDatePickerOne from '../../../components/_mui/MuiDatePickerOne.tsx';
import Crud_Service from '../../../apis/CrudService.jsx';
import FmAutoComplete from '../../../components/_mui/FmAutoComplete.tsx';
import FmSearchableSelect from '../../../components/_mui/FmSearchableSelect.tsx';
import { Controller, useForm } from 'react-hook-form';
import dayjs from 'dayjs';
import FmTextField from '../../../components/_mui/FmTextField.tsx';
import ActionButtons from '../../../components/_form/ActionButtons.tsx';
import useLoader from '../../../components/helpers/UseLoader.tsx';
import { toast } from 'react-toastify';
import { yupResolver } from '@hookform/resolvers/yup';
import { AddUniformRequistionsSchema } from '../../../utils/CommonvalidationSchemas.tsx';
import FmDatePicker from '../../../components/_mui/FmDatePicker.tsx';
import { useNavigate } from 'react-router-dom';
import DashboardLayout from '../../../components/DashboardLayout.tsx';
import useCommonFetchApi from '../../../components/helpers/useCommonFetchApi.tsx';

const GridValue = 2.4;

const AddUniformRequistions = (props) => {
  const crud = new Crud_Service();
  const { handleSubmit, control, reset, watch } = useForm({
    resolver: yupResolver(AddUniformRequistionsSchema),
    defaultValues: {
      endDate: dayjs(),
    },
  });
  const navigate = useNavigate();
  const { startCreateLoading, stopCreateLoading, createLoading } = useLoader();

  //get All Apis
  const { data: loginusers } = useCommonFetchApi('loginusers');
  const { data: uniformsizes } = useCommonFetchApi('uniformsizes');
  const { data: uniformtypes } = useCommonFetchApi('uniformtypes');

  const handleSubmituniforms = async (values) => {
    startCreateLoading();

    const combinedData = {
      ...values,
      endDate: dayjs(values?.endDate).format('YYYY-MM-DD'),
    };

    await crud.create(
      'uniformandaccessoriesrequisitions',
      combinedData,
      (err, res) => {
        if (res?.status === 201) {
          toast.success(
            'Uniform and Accessories-Requisition Created Successfully'
          );
          stopCreateLoading();
          navigate(-1);
          reset();
        } else {
          stopCreateLoading();
        }
      }
    );
  };

  return (
    <DashboardLayout title='Create Uniform and Accessories-Requisition'>
      <GlassCard className='mb-3 p-3'>
        <form onSubmit={handleSubmit(handleSubmituniforms)}>
          <Grid container spacing={2}>
            <Grid item md={GridValue}>
              <FmSearchableSelect
                name='projectId'
                control={control}
                apiUrl='projects'
                valueField='projectId'
                headerField={['Project Code', 'Project Name']}
                labelField={['projectCode', 'projectName']}
                showField={['projectName']}
                label={'Projects'}
                required
              />
            </Grid>
            <Grid item md={GridValue}>
              <FmSearchableSelect
                name='contractId'
                control={control}
                apiUrl='contracts'
                valueField='contractId'
                headerField={['Contract Code', 'Contract Name']}
                labelField={['contractCode', 'contractName']}
                showField={['contractName']}
                label={'Contracts'}
                required
              />
            </Grid>
            <Grid item md={GridValue}>
              <FmDatePicker
                name='endDate'
                label='End Date'
                control={control}
                required
              />
            </Grid>
            <Grid item md={GridValue}>
              <FmSearchableSelect
                name='requestedBy'
                control={control}
                apiUrl='employees'
                valueField='employeeId'
                headerField={['Code', 'Name', 'Email']}
                labelField={['employeeCode', 'fullName', 'emailId']}
                showField={['employeeCode', 'fullName', 'emailId']}
                label='Requested By'
                required
              />
            </Grid>
            <Grid item md={GridValue}>
              <FmAutoComplete
                name='approvedBy'
                control={control}
                options={loginusers}
                label='Approved By'
                displayField='employeeName'
                optionFields={['employeeName']}
                valueKey='loginUserId'
                required
              />
            </Grid>
            <Grid item md={GridValue}>
              <FmSearchableSelect
                name='employeeId'
                control={control}
                apiUrl='employees'
                valueField='employeeId'
                headerField={['Code', 'Name', 'Email']}
                labelField={['employeeCode', 'fullName', 'emailId']}
                showField={['employeeCode', 'fullName', 'emailId']}
                label='Employees'
                required
              />
            </Grid>
            <Grid item md={GridValue}>
              <FmAutoComplete
                name='uniformSizeId'
                control={control}
                options={uniformsizes}
                label='Uniform Size'
                displayField='uniformSizeName'
                optionFields={['uniformSizeName']}
                valueKey='uniformSizeId'
                required
              />
            </Grid>
            <Grid item md={GridValue}>
              <FmAutoComplete
                name='uniformTypeId'
                control={control}
                options={uniformtypes}
                label='Uniform Type'
                displayField='uniformTypeName'
                optionFields={['uniformTypeName']}
                valueKey='uniformTypeId'
                required
              />
            </Grid>
            <Grid item md={GridValue}>
              <FmTextField
                name='qrCode'
                control={control}
                label='QR Code'
                required
              />
            </Grid>
            <Grid item md={GridValue}>
              <FmTextField
                name='quantity'
                control={control}
                label='Quantity'
                required
              />
            </Grid>
            <Grid item md={5}>
              <FmTextField
                name='remarks'
                control={control}
                label='Remarks'
                multiline={true}
                rows={3}
              />
            </Grid>
          </Grid>
          <ActionButtons
            onSubmit={handleSubmit(handleSubmituniforms)}
            onReset={reset}
            onCancel={() => navigate(-1)}
            submitLoading={createLoading}
            submitText='Create'
            cancelText='Cancel'
          />
        </form>
      </GlassCard>
    </DashboardLayout>
  );
};

export default AddUniformRequistions;
