import { Icon } from '@iconify/react';

const StatusIcons = [
  {
    icon: <Icon icon='fluent:mail-open-person-24-regular' />,
  },
  {
    icon: <Icon icon='fluent:cursor-click-24-regular' />,
  },
  {
    icon: <Icon icon='fluent:call-connecting-20-regular' />,
  },
  {
    icon: <Icon icon='fluent:trophy-28-regular' />,
  },
  {
    icon: <Icon icon='fluent:trophy-off-28-regular' />,
  },
  {
    icon: <Icon icon='fluent:calendar-cancel-24-regular' />,
  },
  {
    icon: <Icon icon='fluent:checkmark-circle-24-regular' />,
  },
];

export const statusIconMap = {
  Open: StatusIcons[0].icon,
  Submitted: StatusIcons[1].icon,
  'Under Follow-up': StatusIcons[2].icon,
  Awarded: StatusIcons[3].icon,
  'Not Awarded': StatusIcons[4].icon,
  Cancelled: StatusIcons[5].icon,
  Closed: StatusIcons[6].icon,
};

export const statusColorsMap = {
  Open: 'amber',
  Submitted: 'blue',
  'Under Follow-up': 'purple',
  Awarded: 'cyan',
  'Not Awarded': 'orange',
  Cancelled: 'red',
  Closed: 'green',
};
