import React, { useEffect, useState } from 'react';
import BackToButton from './../../../components/small/BackToButton.tsx';
import { Box, Button, Tabs, Tab } from '@mui/material';
import GlassCard from '../../../components/small/GlassCard.tsx';
import { Icon } from '@iconify/react';
import JobDetails from './View/JobDetails.tsx';
import TicketsDocumentUpload from './View/TicketsDocumentUpload.tsx';
import TicketsDetailsHistory from './View/TicketsDetailsHistory.tsx';
import DashboardLayout from '../../../components/DashboardLayout.tsx';
import Crud_Service from '../../../apis/CrudService.jsx';
import { useNavigate, useParams } from 'react-router-dom';
import FmFileInput from '../../../components/_mui/FmFileInput.tsx';
import ActionButtons from '../../../components/_form/ActionButtons.tsx';
import useLoader from '../../../components/helpers/UseLoader.tsx';
import { useForm } from 'react-hook-form';
import { t } from 'i18next';
import { toast } from 'react-toastify';
import MuiDialogOne from '../../../components/_mui/MuiDialogOne.jsx';
import useTableLogic from '../../../components/helpers/UseTableLogic.tsx';
import DynamicViewFields from '../../../components/helpers/DynamicViewFields.jsx';
import { SkeletonLoaderRow } from '../../../components/page/SkeletonLoader.tsx';
import FmTabsOne from '../../../components/tabs/FmTabsOne.tsx';
import UpdateTicket from './View/UpdateTicket.tsx';

const ViewSourceArray = [
  {
    name: 'Job Detail',
  },
  {
    name: 'Documents Upload',
  },
  {
    name: 'History',
  },
];

const ViewDetailTicket = (props) => {
  const { editingRowId, setEditingRowId } = props;
  const { id } = useParams();
  const navigator = useNavigate();
  const [tabValue, setTabValues] = useState(0);
  const [viewData, setViewData] = useState([]);
  const [openUpload, setOpenUpload] = useState(false);
  const [uploadedfileData, setUploadedfileData] = useState(null);
  const [uploadLoading, setUploadLoading] = useState(false);
  const { startCreateLoading, stopCreateLoading, createLoading } = useLoader();
  const [editModal, setEditModal] = useState(false);

  const [pageLoading, setPageLoading] = useState(true);

  const {
    rows,
    pageSize,
    pageNumber,
    setSearchKeyword,
    searchKeyword,
    isLoading,
    tableRecordCounts,
    handlePaginationModelChange,
    handleSortModelChange,
    fetchData,
  } = useTableLogic(`tickets/${id}/attachments`);

  const crud = new Crud_Service();

  const { reset, getValues } = useForm();
  const handleTabChange = (event, newValue) => {
    setTabValues(newValue);
  };
  const viewDataFetch = async () => {
    crud.getSingle('tickets', id, (err, res) => {
      if (res?.status === 200) {
        setViewData(res?.data);
        setPageLoading(false);
      }
    });
  };

  const handleFileUpload = async (e) => {
    const file = e.target.files[0];
    setUploadLoading(true);
    if (file) {
      const fileFormData = new FormData();
      fileFormData.append('file', file);
      fileFormData.append('fileTypeId', 64);

      await crud.create(`/files/upload`, fileFormData, (err, res) => {
        if (res?.status === 200) {
          setUploadLoading(false);
          setUploadedfileData(res?.data);
        } else {
          setUploadLoading(false);
        }
      });
    }
  };

  const handleSubmitAttachment = async () => {
    startCreateLoading();
    const combinedData = {
      ticketId: id,
      attachmentId: uploadedfileData?.fileId,
    };
    await crud.create(`ticketattachments`, combinedData, (err, res) => {
      if (res?.status === 201) {
        toast.success('Attachment Uploaded Successfully');
        stopCreateLoading();
        setOpenUpload(false);
        fetchData();
        setUploadedfileData(null);
        setOpenUpload(false);
      } else {
        stopCreateLoading();
      }
    });
  };

  useEffect(() => {
    if (id) {
      viewDataFetch();
    }
  }, [id]);

  const excludeKeys = [
    'id',
    'createdDate',
    'createdBy',
    'updatedDate',
    'updatedBy',
    'deletedDate',
    'deletedBy',
    'isDeleted',
    'isActive',
    'isApproved',
    'contractId',
    'editable',
    'deletable',
    'statusId',
  ];

  const generateFields = (data) => {
    return Object.keys(data)
      .filter((key) => !excludeKeys.includes(key))
      .map((key) => ({
        key: key,
        label: key
          .replace(/([A-Z])/g, ' $1')
          .replace(/^./, (str) => str.toUpperCase()),
      }));
  };

  return (
    <DashboardLayout
      title='View Ticket'
      actionButtons={
        <BackToButton
          title='Back to Tickets'
          className='px-2 py-0'
          onClick={() => navigator('/cafm/tickets')}
        />
      }
      titleReverse
      removeSpace
    >
      <Box className='flex w-full h-full'>
        <GlassCard className='h-full overflow-auto basis-[250px] rounded-none flex flex-col  border-t-0 border-l-0 bg-neutral-100'>
          {pageLoading ? (
            <SkeletonLoaderRow count={22} />
          ) : (
            <>
              <Box className='p-2'>
                <Button
                  variant='outlined'
                  startIcon={<Icon icon='ic:twotone-attachment' />}
                  onClick={() => {
                    setOpenUpload(true);
                    setUploadedfileData(null);
                  }}
                  className='p-1'
                  fullWidth
                >
                  Upload Attachment
                </Button>
              </Box>

              <Box
                className='flex-1 min-h-0 overflow-auto p-2'
                sx={{
                  borderTop: '1px solid',
                  borderBottom: '1px solid',
                  borderColor: 'border.main',
                }}
              >
                <DynamicViewFields
                  fields={generateFields(viewData)}
                  data={viewData}
                  gridValue={12}
                  gridSpacing={1}
                />
              </Box>
              <Box className='p-2'>
                <Button
                  variant='contained'
                  fullWidth
                  className=' p-1'
                  onClick={() => setEditModal(true)}
                >
                  Edit Fields
                </Button>
              </Box>
            </>
          )}
        </GlassCard>

        <Box className='flex-1 min-w-0 h-full overflow-auto p-3'>
          {pageLoading ? (
            <SkeletonLoaderRow count={5} />
          ) : (
            <GlassCard className='mb-4'>
              <FmTabsOne
                data={ViewSourceArray}
                value={tabValue}
                handleChange={handleTabChange}
              />
            </GlassCard>
          )}

          {!pageLoading && (
            <>
              {tabValue === 0 && (
                <JobDetails
                  viewData={viewData}
                  setViewData={setViewData}
                  editingRowId={editingRowId}
                  setEditingRowId={setEditingRowId}
                  fetchData={fetchData}
                  crud={crud}
                />
              )}
              {tabValue === 1 && (
                <TicketsDocumentUpload
                  uploadedfileData={uploadedfileData}
                  rows={rows}
                  pageSize={pageSize}
                  pageNumber={pageNumber}
                  setSearchKeyword={setSearchKeyword}
                  isLoading={isLoading}
                  handlePaginationModelChange={handlePaginationModelChange}
                  handleSortModelChange={handleSortModelChange}
                  searchKeyword={searchKeyword}
                  setUploadedfileData={setUploadedfileData}
                  setOpenUpload={setOpenUpload}
                  fetchData={fetchData}
                  tableRecordCounts={tableRecordCounts}
                  crud={crud}
                />
              )}

              {tabValue === 2 && <TicketsDetailsHistory />}
            </>
          )}
        </Box>
      </Box>

      <MuiDialogOne
        open={openUpload}
        onClose={() => setOpenUpload(false)}
        title='Upload your Attachment'
        width='450px'
      >
        <FmFileInput
          name='file'
          documentName={uploadedfileData?.originalFileName}
          onChange={handleFileUpload}
          loading={uploadLoading}
        />
        <ActionButtons
          onSubmit={handleSubmitAttachment}
          onCancel={() => setOpenUpload(false)}
          submitLoading={createLoading}
          cancelLoading={false}
          disabled={uploadLoading || !uploadedfileData}
        />
      </MuiDialogOne>

      <MuiDialogOne
        open={editModal}
        onClose={() => setEditModal(false)}
        title='Update Ticket'
        width='80%'
      >
        <UpdateTicket
          crud={crud}
          id={id}
          fetchData={fetchData}
          onClose={() => setEditModal(false)}
        />
      </MuiDialogOne>
    </DashboardLayout>
  );
};

export default ViewDetailTicket;
