import React, { useEffect, useMemo, useState } from 'react';
import DashboardLayout from '../../../components/DashboardLayout.tsx';
import PageHeader from '../../../components/PageHeader';
import DataTable from '../../../components/DataTable.tsx';
import GlassCard from '../../../components/small/GlassCard.tsx';
import TableActionButtons from '../../../components/_form/TableActionButtons';
import { ViewField, StatusBadge } from '../../../components/_form/FormElements';
import { Icon } from '@iconify/react';
import {
  Box,
  TextField,
  Button,
  Grid,
  FormControl,
  InputLabel,
  Select,
  MenuItem,
} from '@mui/material';
import { CreateButton } from '../../../components/small/Buttons';
import TableSearch from '../../../components/_form/TableSearch.jsx';
import MuiDialogOne from '../../../components/_mui/MuiDialogOne';
import { Nav } from '../../../utils';
import { createMaster, deleteMaster } from '../../../apis/employeeMasterApi';
import { CertificateType } from '../../../interfaces/EmployeeMasterInterfaces';
import Crud_Service from '../../../apis/CrudService';
import { toast } from 'react-toastify';
import { useSelector } from 'react-redux';
import { findNavByName } from '../../../utils/navUtils.ts';

const CertificateTypeMasters = () => {
  const [openCreate, setOpenCreate] = React.useState(false);
  const [openView, setOpenView] = React.useState(false);
  const [data, setData] = useState<CertificateType[]>([]);
  const crud = new Crud_Service();
  const [selectedRow, setSelectedRow] = useState<CertificateType>();
  const [openEdit, setOpenEdit] = React.useState(false);
  const menu = useSelector((state) => state?.menu?.items);

  useEffect(() => {
    document.title = `HRMS-Certificate Type`;
    const getCertifications = () => {
      crud.getAll('certifications', '', (err, res) => {
        if (err) {
          toast.error('Error fetching Certificates:', err);
          return;
        }
        if (res?.status === 200) {
          const transformedData: CertificateType[] = res?.data?.data.map(
            (row: any) => ({
              ...row,
              id: row?.certificationId,
            })
          );
          setData(transformedData);
        }
      });
    };

    if (!openCreate) {
      getCertifications();
    }
  }, [openCreate]);

  const handleCreateClickOpen = () => {
    setOpenCreate(true);
  };
  const handleCreateClose = () => {
    setOpenCreate(false);
  };

  const handleViewClickOpen = (ev, selectedRow) => {
    setSelectedRow(selectedRow);
    setOpenView(true);
  };
  const handleViewClose = () => {
    setOpenView(false);
  };

  const handleSubmit = async (id?: number | undefined) => {
    try {
      const certificationName =
        document.getElementById('certificationName')?.value;
      const certificationCode =
        document.getElementById('certificationCode')?.value;
      const documentTypeId = document.getElementById('documentTypeId')?.value;
      const status = document.getElementById('status')?.checked ? 1 : 2;
      await createMaster(
        'certifications',
        {
          certificationCode: certificationCode,
          certificationName: certificationName,
          documentTypeId: documentTypeId,
          status: status,
        },
        id
      );
      setOpenCreate(false);
    } catch (e) {}
  };

  const handleEditOpen = (ev, selectedRow) => {
    setSelectedRow(selectedRow);
    setOpenEdit(true);
  };

  const handleEditClose = async () => {
    setOpenEdit(false);
    handleSubmit(selectedRow?.certificationId);
    const selectedIndex = data.findIndex(
      (item) => item.certificationId === selectedRow?.certificationId
    );

    // If selectedIndex is found, update the data array with the updated selectedRow
    if (selectedIndex !== -1) {
      setData((prevData) => {
        const newData = [...prevData];
        newData[selectedIndex] = selectedRow;
        return newData;
      });
    }
  };

  const handleEdit = (event) => {
    const { id, value } = event.target;
    setSelectedRow((prevState) => ({
      ...prevState,
      [id]: id === 'status' ? (event.target.checked ? 1 : 2) : value,
    }));
  };

  const handleDelete = async (eve, selectedRow) => {
    setData((prevData) =>
      prevData.filter(
        (item: CertificateType) =>
          item.certificationId !== selectedRow.certificationId
      )
    );
    await deleteMaster('certifications', selectedRow.id);
  };
  const counters = [
    {
      name: 'All',
      count: 15,
    },
    {
      name: 'Active',
      count: 85,
    },
    {
      name: 'Deactivated',
      count: 2,
    },
  ];

  const ActionData = [
    {
      name: 'View',
      icon: <Icon icon='solar:eye-linear' />,
      onClick: handleViewClickOpen,
    },
    {
      name: 'Edit',
      icon: <Icon icon='solar:pen-new-round-linear' />,
      onClick: handleEditOpen,
    },
    {
      name: 'Delete',
      icon: <Icon icon='solar:trash-bin-2-linear' />,
      onClick: handleDelete,
      danger: true,
    },
  ];

  const columns = [
    {
      field: 'certificationName',
      headerName: 'Certification Name',
      flex: 1,
    },
    {
      field: 'documentTypeName',
      headerName: 'Document Name',
      flex: 1,
    },

    {
      field: 'status',
      headerName: 'Status',
      flex: 1,
      renderCell: (params) => {
        return (
          <StatusBadge
            title={params.value === 2 ? 'Active' : 'Inactive'}
            type={params.value === 2 ? 'green' : 'red'}
          />
        );
      },
    },

    {
      field: 'action',
      headerName: '',
      width: 140,
      sortable: false,
      disableClickEventBubbling: false,

      renderCell: (params) => {
        return (
          <TableActionButtons
            Actions={ActionData}
            selectedRow={params.row}
            setSelectedRow={setSelectedRow}
          />
        );
      },
    },
  ];

  const SubMenu = useMemo(() => {
    return menu?.map((item) => item?.menus?.flat()).flat() || [];
  }, [menu]);

  const resultHRMS = useMemo(
    () => findNavByName(SubMenu, 'HRMS', 'All Masters'),
    [SubMenu]
  );

  return (
    <DashboardLayout
      title='Certificate Type'
      actionButtons={
        <>
          <PageHeader counters={counters} />
        </>
      }
      hasSubmenu
      menu={resultHRMS}
      parentMenuHide
    >
      <GlassCard className='h-full-css'>
        <Box className='h-full-css'>
          <DataTable
            rows={data}
            columns={columns}
            getRowClassName={(params) =>
              `${params.row.status === 0 && 'bg-red-50'}`
            }
            slots={{
              toolbar: () => (
                <Box
                  className='p-2 w-full flex justify-between items-center'
                  sx={{
                    borderBottom: '1px solid',
                    borderColor: 'border.main',
                  }}
                >
                  <Box sx={{ width: 'auto' }}>
                    <TableSearch placeholder='Search' />
                  </Box>
                  <CreateButton name='Create' onClick={handleCreateClickOpen} />
                </Box>
              ),
            }}
          />
        </Box>
      </GlassCard>

      {/* create edit */}

      <MuiDialogOne
        title='Create'
        open={openCreate}
        onClose={handleCreateClose}
      >
        <Grid container spacing={1}>
          <Grid item md={12}>
            <TextField
              fullWidth
              id='certificationName'
              label='Certificate Name'
              variant='filled'
            />
          </Grid>
          <Grid item md={12}>
            <TextField
              fullWidth
              label='Certificate Code'
              variant='filled'
              id='certificationCode'
            />
          </Grid>
          <Grid item md={12}>
            <TextField
              fullWidth
              label='Document Type'
              variant='filled'
              id='documentTypeId'
            />
          </Grid>
          <Grid item md={12}>
            <FormControl fullWidth>
              <InputLabel>Status</InputLabel>
              <Select label='Status' id='status'>
                <MenuItem value={2}>Active</MenuItem>
                <MenuItem value={1}>In Active</MenuItem>
              </Select>
            </FormControl>
          </Grid>

          <Grid item md={12}>
            <Box className='text-end'>
              <Button
                onClick={() => handleSubmit()}
                variant='contained'
                sx={{ minWidth: '200px' }}
              >
                Submit
              </Button>
            </Box>
          </Grid>
        </Grid>
      </MuiDialogOne>

      {/* create edit */}

      <MuiDialogOne
        title='View Details'
        open={openView}
        onClose={handleViewClose}
      >
        <Grid container spacing={1}>
          <Grid item md={12}>
            <ViewField
              label='  certificationName'
              title={selectedRow?.certificationName}
            />
            <ViewField
              label='certification Code'
              title={selectedRow?.certificationCode}
            />{' '}
            <ViewField
              label='document Type Name'
              title={selectedRow?.documentTypeName}
            />{' '}
            <ViewField
              label='document Type Id'
              title={selectedRow?.documentTypeId}
            />{' '}
          </Grid>

          <Grid item md={12}>
            <ViewField
              label='Status'
              title={selectedRow?.status === 1 ? 'ACTIVE' : 'DEACTIVE'}
            />
          </Grid>
        </Grid>
      </MuiDialogOne>
      <MuiDialogOne
        title='Edit'
        open={openEdit}
        onClose={() => setOpenEdit(false)}
      >
        <Grid container spacing={3}>
          <Grid item md={12}>
            <TextField
              fullWidth
              label='Certification Name'
              variant='filled'
              id='certificationName'
              value={selectedRow?.certificationName}
              onChange={handleEdit}
            />
          </Grid>
          <Grid item md={12}>
            <TextField
              fullWidth
              label='Certification Code'
              variant='filled'
              onChange={handleEdit}
              id='certificationCode'
              value={selectedRow?.certificationCode}
            />
          </Grid>
          <Grid item md={12}>
            <TextField
              fullWidth
              label='document Type Id'
              variant='filled'
              onChange={handleEdit}
              id='documentTypeId'
              value={selectedRow?.documentTypeId}
            />
          </Grid>

          <Grid item md={12}>
            <FormControl fullWidth>
              <InputLabel>Status</InputLabel>
              <Select
                label='Status'
                id='status'
                value={selectedRow?.status}
                onChange={handleEdit}
              >
                <MenuItem value={2}>Active</MenuItem>
                <MenuItem value={1}>In Active</MenuItem>
              </Select>
            </FormControl>
          </Grid>

          <Grid item md={12}>
            <Box className='text-end'>
              <Button
                variant='contained'
                onClick={() => handleEditClose()}
                sx={{ minWidth: '200px' }}
              >
                Submit
              </Button>
            </Box>
          </Grid>
        </Grid>
      </MuiDialogOne>
    </DashboardLayout>
  );
};

export default CertificateTypeMasters;
