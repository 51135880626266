import React from 'react';
import DashboardLayout from '../../../../components/DashboardLayout.tsx';
import GlassCard from '../../../../components/small/GlassCard.tsx';
import { useNavigate, useParams } from 'react-router-dom';
import CommonView from '../../../../components/helpers/CommonView.jsx';
import DynamicViewFields from '../../../../components/helpers/DynamicViewFields.jsx';
import { CreateButton } from '../../../../components/small/Buttons.jsx';
import ActionButtons from '../../../../components/_form/ActionButtons.tsx';

const ViewComparisonPolicy = () => {
  const navigate = useNavigate();
  const { id } = useParams();

  const excludeKeys = [
    'companyInsurancePolicyComparisonId',
    'companyInsurancePolicyId',
    'companyInsurancePolicyTypeOfProductId',
    'firstInsurer',
    'secondInsurer',
    'thirdInsurer',
    'startDate',
    'updatedDate',
    'status',
  ];

  return (
    <DashboardLayout
      title='View Company Insurance Policy - Comparison'
      actionButtons={
        <CreateButton
          name='Create'
          onClick={() =>
            navigate('/admin/company-insurance-policy/comparison/create')
          }
        />
      }
    >
      <GlassCard className='p-5'>
        <CommonView
          url='companyinsurancepolicycomparisons'
          id={id}
          excludeKeys={excludeKeys}
          renderFields={(data, fields) => (
            <DynamicViewFields data={data} fields={fields} />
          )}
        />
        <ActionButtons
          cancelLoading={false}
          cancelText='Go Back'
          submitText='Edit'
          onSubmit={() =>
            navigate(`/admin/company-insurance-policy/comparison/edit/${id}`)
          }
          onCancel={() => navigate('/admin/company-insurance-policy/comparison')}
        />
      </GlassCard>
    </DashboardLayout>
  );
};

export default ViewComparisonPolicy;
