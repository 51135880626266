import React from 'react';
import { Box } from '@mui/material';
import { FontSize } from './../../theme/FontSize.jsx';
import { colorBgOnly } from '../../utils/Colors/ColorClasses.tsx';

const ViewStatus = ({ title, type, icon }) => {
  const badgeClass = colorBgOnly[type];
  return (
    <Box
      className={`${badgeClass}  px-2 py-1 text-white inline-flex items-center font-semibold gap-2`}
      sx={{
        borderRadius: '5px',
        textTransform: 'uppercase',
        fontSize: FontSize.bodyMinusOne,
      }}
    >
      {icon && <Box className='text-xl'>{icon}</Box>}
      <Box> {title}</Box>
    </Box>
  );
};

export default ViewStatus;
