import { yupResolver } from '@hookform/resolvers/yup';
import { Icon } from '@iconify/react';
import {
  Box,
  Divider,
  FormControlLabel,
  Stack,
  Switch,
  Tab,
  Tabs,
  Grid,
} from '@mui/material';
import React, { useEffect, useState } from 'react';
import { useForm } from 'react-hook-form';
import { useNavigate, useParams } from 'react-router-dom';
import { toast } from 'react-toastify';
import * as yup from 'yup';
import apiInstance from '../../../apis/ApiService.jsx';
import Crud_Service from '../../../apis/CrudService.jsx';
import TableActionButtons from '../../../components/_form/TableActionButtons.jsx';
import FmTextField from '../../../components/_mui/FmTextField.tsx';
import FmViewDateField from '../../../components/_mui/FmViewDateField.tsx';
import MuiDialogOne from '../../../components/_mui/MuiDialogOne.jsx';
import DashboardLayout from '../../../components/DashboardLayout.tsx';
import useTableLogic from '../../../components/helpers/MRTUseTableLogic.tsx';
import useLoader from '../../../components/helpers/UseLoader.tsx';
import BackToButton from '../../../components/small/BackToButton.tsx';
import GlassCard from '../../../components/small/GlassCard.tsx';
import MRTSaveAndCancel from '../../../components/table/MRTSaveAndCancel.tsx';
import FmMRTDataTable from './../../../components/FmMRTDataTable.tsx';
import ViewFieldInline from './../../../components/view/ViewFieldInline.tsx';
import ViewTenderBuildingImages from './ViewBuilding/ViewTenderBuildingImages.tsx';
import ViewSiteVisitImages from './ViewSiteVisit/ViewSiteVisitImages.tsx';
import { ViewField } from '../../../components/_form/FormElements.jsx';

const navMenu = [
  {
    id: 1,
    name: 'Checklist Items',
  },
  {
    id: 2,
    name: 'Building Images',
  },
  {
    id: 3,
    name: 'Site Visit Images',
  },
];

const ViewTenderCheckListDetail = () => {
  const { id } = useParams();
  const [updateView, setUpdateView] = useState(false);
  const [openView, setOpenView] = useState(false);
  const [tenderChecklist, setTenderChecklist] = useState({});
  const { startEditLoading, stopEditLoading } = useLoader();
  const {
    rows,
    isLoading,
    pagination,
    setRows,
    sorting,
    setSearchKeyword,
    columnFilters,
    globalFilter,
    tableRecordCounts,
    setPagination,
    setSorting,
    setColumnFilters,
    setGlobalFilter,
    searchKeyword,
    fetchData,
  } = useTableLogic(
    `tenderinspectionchecklistdetail`,
    ['tenderInspectionCheckListId'],
    [id]
  );
  const crud = new Crud_Service();
  const navigate = useNavigate();
  const [rowState, setRowState] = useState({
    newRowId: null,
    isCreatingRow: false,
  });
  const validationSchema = yup.object().shape({
    sspName: yup.string().required('SSP Name is required'),
  });
  const {
    handleSubmit,
    control,
    reset,
    setValue,
    formState: { errors },
  } = useForm({
    resolver: yupResolver(validationSchema),
    mode: 'onChange',
  });

  useEffect(() => {
    getTenderChecklist();
  }, []);

  const getTenderChecklist = async () => {
    await crud.getSingle('tenderinspectionchecklists', id, (err, res) => {
      if (res?.status === 200) {
        setTenderChecklist(res?.data);
      } else {
        toast.error('something_Went_Wrong');
      }
    });
  };

  const handleAddNewRow = () => {
    const newId = 'new_row_' + Date.now();

    setRowState({
      newRowId: newId,
      isCreatingRow: true,
    });

    setRows((prevRows) => [
      {
        tenderInspectionCheckListDetailId: newId,
        tenderInspectionCheckListId: id,
        assetName: '',
        subLocation: '',
        quantity: '',
        make: '',
        sspName: '',
        condition: '',
        remarks: '',
      },
      ...prevRows,
    ]);

    reset();
  };
  useEffect(() => { }, [rows]);
  //Update Assests
  const handleEditCheckListDetail = async (values) => {
    startEditLoading();
    const convertedValues = {
      ...values,
      tenderInspectionCheckListId: Number(id),

      subLocation: values?.subLocation,
      quantity: values?.quantity,
      make: values?.make,
      sspName: values?.sspName,
      condition: values?.condition,
      remarks: values?.remarks,
      assetName: values?.assetName,
      status: 2,
    };
    const combinedData = {
      tenderInspectionCheckListId: id,
      tenderInspectionCheckListDetails: [convertedValues],
    };

    const updateCombinedData = {
      ...values,
      subLocation: values?.subLocation,
      quantity: values?.quantity,
      make: values?.make,
      sspName: values?.sspName,
      condition: values?.condition,
      remarks: values?.remarks,
      assetName: values?.assetName,
      tenderInspectionCheckListId: id,
      status: 2,
    };
    if (rowState?.newRowId) {
      await crud.create(
        'tenderinspectionchecklistdetail',

        combinedData,
        (err, res) => {
          if (err) {
            toast.error(err);
          }
          if (res?.status === 201) {
            toast.success('Assets Created Successfully');

            setEditingRowId(null);
            setRowState((prevState) => ({
              ...prevState,
              newRowId: null,
              isCreatingRow: false,
            }));
            setEditingRowData(null);
            fetchData();
          }
        }
      );
    } else {
      try {
        await crud.update(
          'tenderinspectionchecklistdetail',
          editingRowId,
          updateCombinedData,
          (err, res) => {
            if (err) {
              toast.error(err);
            }
            if (res?.status === 200) {
              toast.success('Assets Updated Successfully');
              setUpdateView(false);
              setEditingRowId(null);
              setEditingRowData(null);
              fetchData();
            }
          }
        );
      } catch (error) {
        toast.error('An error occurred while updating:', error);
      } finally {
        stopEditLoading();
      }
    }
  };

  //Delete Assets
  const handleDeleteClick = async (props) => {
    await crud.remove(
      'tenderinspectionchecklistdetail',
      props?.tenderInspectionCheckListDetailId,
      (err, res) => {
        if (res?.status === 204) {
          toast.success('Assets Deleted Successfully');
          fetchData();
        } else {
        }
      }
    );
  };
  const handleEdit = async (row) => {
    setEditingRowId(row?.tenderInspectionCheckListDetailId);
    setEditingRowData({ ...row });

    Object.entries({
      ...row,
    }).forEach(([key, value]) => {
      setValue(key, value);
    });
  };
  const ActionData = [
    // {
    //   name: 'View',
    //   icon: <Icon icon='solar:eye-linear' />,
    //   onClick: (props) => handleViewClick(props),
    // },
    {
      name: 'Edit',
      icon: <Icon icon='solar:pen-new-round-linear' />,
      onClick: (props) => {
        handleEdit(props);
      },
    },
    {
      name: 'Delete',
      icon: <Icon icon='mi:delete' />,
      onClick: (props) => handleDeleteClick(props),
      danger: true,
    },
  ];

  const [editingRowId, setEditingRowId] = useState(null);
  const [editingRowData, setEditingRowData] = useState(null);
  const [openUpload, setOpenUpload] = useState(false);
  const [tabValue, setTabValue] = useState(navMenu[0]?.id || 0);

  const handleChange = (_event, newValue) => {
    setTabValue(newValue);
  };

  const handleCancel = (row) => {
    if (
      row?.original?.tenderInspectionCheckListDetailId === rowState?.newRowId
    ) {
      setRows((prevRows) =>
        prevRows.filter(
          (r) => r?.tenderInspectionCheckListDetailId !== rowState?.newRowId
        )
      );
      setRowState((prevState) => ({
        ...prevState,
        newRowId: null,
        isCreatingRow: false,
      }));
    } else {
      setEditingRowId(null);
    }
    reset();
  };
  const columns = [
    {
      header: 'Actions',
      enableColumnPinning: true,
      enableEditing: false,
      enableSorting: false,
      size: 80,
      Cell: ({ row }) => {
        const isNewRow =
          row?.original?.tenderInspectionCheckListDetailId ===
          rowState?.newRowId;
        const isEditing =
          editingRowId === row?.original?.tenderInspectionCheckListDetailId ||
          isNewRow;

        return (
          <Box className='flex gap-2'>
            {isEditing ? (
              <MRTSaveAndCancel
                onClickCancel={() => handleCancel(row)}
                onClickSave={handleSubmit(handleEditCheckListDetail)}
              />
            ) : (
              <TableActionButtons
                Actions={ActionData?.map((action) => ({
                  ...action,
                  onClick: () => action.onClick(row?.original),
                }))}
              />
            )}
          </Box>
        );
      },
    },

    {
      accessorKey: 'description',
      header: 'Description',
      enableEditing: true,
      Cell: ({ row }) => {
        const isNewRow =
          row?.original?.tenderInspectionCheckListDetailId ===
          rowState?.newRowId;
        const isEditing =
          editingRowId === row?.original?.tenderInspectionCheckListDetailId ||
          isNewRow;
        return isEditing ? (
          <FmTextField name='description' control={control} />
        ) : (
          row?.original?.description || ''
        );
      },
    },
    {
      accessorKey: 'availability',
      header: 'Availability',
      enableEditing: true,
      Cell: ({ row }) => {
        const isNewRow =
          row?.original?.tenderInspectionCheckListDetailId ===
          rowState?.newRowId;
        const isEditing =
          editingRowId === row?.original?.tenderInspectionCheckListDetailId ||
          isNewRow;
        return isEditing ? (
          <FormControlLabel
            value='availability'
            control={
              <Switch
                color='warning'
                size='small'
                onChange={(e) => setValue('availability', e.target.checked)}
              />
            }
            label='Yes/No'
          // labelPlacement='end'
          />
        ) : (
          row?.original?.availability || ''
        );
      },
    },
    {
      accessorKey: 'quantity',
      header: 'Quantity',
      enableEditing: true,
      Cell: ({ row }) => {
        const isNewRow =
          row?.original?.tenderInspectionCheckListDetailId ===
          rowState?.newRowId;
        const isEditing =
          editingRowId === row?.original?.tenderInspectionCheckListDetailId ||
          isNewRow;
        return isEditing ? (
          <FmTextField type='number' name='quantity' control={control} />
        ) : (
          row?.original?.quantity || ''
        );
      },
    },
    {
      accessorKey: 'sspName',
      header: 'SSP Name',
      enableEditing: true,
      Cell: ({ row }) => {
        const isNewRow =
          row?.original?.tenderInspectionCheckListDetailId ===
          rowState?.newRowId;
        const isEditing =
          editingRowId === row?.original?.tenderInspectionCheckListDetailId ||
          isNewRow;
        return isEditing ? (
          <FmTextField name='sspName' control={control} />
        ) : (
          row?.original?.sspName || ''
        );
      },
    },
    {
      accessorKey: 'remarks',
      header: 'Remarks',
      enableEditing: true,
      Cell: ({ row }) => {
        const isNewRow =
          row?.original?.tenderInspectionCheckListDetailId ===
          rowState?.newRowId;
        const isEditing =
          editingRowId === row?.original?.tenderInspectionCheckListDetailId ||
          isNewRow;
        return isEditing ? (
          <FmTextField name='remarks' control={control} />
        ) : (
          row?.original?.remarks || ''
        );
      },
    },
    {
      accessorKey: 'createdDate',
      header: 'Created Date',
    },
    {
      accessorKey: 'updateDate',
      header: 'Updated Date',
    },
  ];
  const handleExport = async () => {
    const downloadUrl = await apiInstance.getFiles(
      `tenderinspectionchecklistdetail/${id}/export-excel`
    );

    const link = document.createElement('a');
    link.href = downloadUrl;
    link.setAttribute('download', 'CheckList.xlsx');
    document.body.appendChild(link);
    link.click();
    document.body.removeChild(link);
  };
  const handleUploadSheet = (event) => {
    const file = event.target.files[0];
    if (file) {
      const fileFormData = new FormData();
      fileFormData.append('file', file);
      fileFormData.append('fileTypeId', 4);

      crud.create('files/upload', fileFormData, (_err, res) => {
        if (res?.status === 200) {
          crud.create(
            `tenderinspectionchecklistdetail/${id}/process/${res.data?.fileId}`,
            '',
            (_err, res) => {
              if (res?.status === 200) {
                fetchData();
                setOpenUpload(false);
              }
            }
          );
        } else {
          setOpenUpload(false);
        }
      });
    }
  };
  const handleClose = () => {
    setOpenUpload(false);
  };

  return (
    <DashboardLayout
      title='View Tender Inspection'
      actionButtons={
        <BackToButton
          title='Back to List'
          onClick={() => navigate(-1)}
          className='px-2 mb-1'
        />
      }
      titleReverse
    >
      {/* Glass Card */}
      <GlassCard className='p-2 mb-2'>
        <Grid container>
          <Grid item md={3}>
            <ViewField
              label='Tender Name'
              title={tenderChecklist?.tenderName}
            />
            <ViewField
              label='Client Name'
              title={tenderChecklist?.clientName}
            />
          </Grid>
          <Grid item md={3}>
            <ViewField
              label={'Location Name'}
              title={tenderChecklist?.locationName}
            />
            <ViewField
              label={'Building Name'}
              title={tenderChecklist?.buildingName}
            />
          </Grid>
          <Grid item md={3}>
            <ViewField
              label={'No of floors'}
              title={tenderChecklist?.noOfFloor}
            />
            <ViewField
              label={'Existing Service Provider'}
              title={tenderChecklist?.existServiceProvider}
            />
          </Grid>
          <Grid item md={3}>
            <ViewFieldInline
              name={'Site visit date'}
              value={tenderChecklist?.visitedDate}
            />
            <FmViewDateField
              title={'Created Date'}
              value={tenderChecklist?.createdDate}
            />
            <FmViewDateField
              title={'Updated Date'}
              value={tenderChecklist?.updatedDate}
            />
          </Grid>
        </Grid>
      </GlassCard>
      {/* Glass Card */}

      {/* Tabs */}
      <GlassCard className='mb-2'>
        <Tabs
          value={tabValue}
          onChange={handleChange}
          textColor='secondary'
          indicatorColor='secondary'
          centered
        >
          {navMenu?.map((item) => {
            return <Tab value={item?.id} label={item?.name} key={item?.name} />;
          })}
        </Tabs>
      </GlassCard>
      {tabValue === 1 && (
        <GlassCard className='overflow-hidden'>
          <FmMRTDataTable
            columns={columns}
            enableRowSelection={false}
            rows={rows}
            onCreateRow={handleAddNewRow}
            isCreatingRow={rowState?.isCreatingRow}
            rowCount={tableRecordCounts}
            editingRow={editingRowId}
            pagination={pagination}
            setSearchKeyword={setSearchKeyword}
            sorting={sorting}
            columnFilters={columnFilters}
            globalFilter={globalFilter}
            setPagination={setPagination}
            setSorting={setSorting}
            setColumnFilters={setColumnFilters}
            setGlobalFilter={setGlobalFilter}
            isLoading={isLoading}
            searchKeyword={searchKeyword}
            fetchData={fetchData}
            handleExport={handleExport}
            handleImport={() => setOpenUpload(true)}
            muiTableContainerProps={{
              sx: {
                maxHeight: '300px',
              },
            }}
          />
          <MuiDialogOne
            title='Upload Sheet'
            open={openUpload}
            onClose={handleClose}
          >
            <input
              type='file'
              onChange={handleUploadSheet}
              id='file'
              accept='.csv, application/vnd.openxmlformats-officedocument.spreadsheetml.sheet, application/vnd.ms-excel'
            />
          </MuiDialogOne>
        </GlassCard>
      )}
      {tabValue === 2 && <ViewTenderBuildingImages />}
      {tabValue === 3 && <ViewSiteVisitImages />}
    </DashboardLayout>
  );
};

export default ViewTenderCheckListDetail;
