import React from 'react';
import { Box } from '@mui/material';

const converted = {
  fontSize: '18px',
  position: 'absolute',
  top: '50px',
  right: '300px',
  textTransform: 'uppercase',
  transform: 'rotate(-15deg)',
  textAlign: 'center',
  pointerEvents: 'none',
  display: 'flex',
  alignItems: 'center',
  gap: '20px',
  border: '2px solid #f71010',
  color: '#f71010',
  fontWeight: 700,
  padding: ['6px 15px'],
  borderRadius: '5px',
};

const ViewWaterMarkEnq = ({ name, icon }) => {
  return (
    <Box sx={converted}>
      <Box className='text-3xl'>{icon}</Box>
      <Box>{name}</Box>
    </Box>
  );
};

export default ViewWaterMarkEnq;
