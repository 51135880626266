import { yupResolver } from '@hookform/resolvers/yup';
import { Icon } from '@iconify/react';
import { Box, Grid } from '@mui/material';
import React, { useEffect, useMemo, useState } from 'react';
import { useForm } from 'react-hook-form';
import { useTranslation } from 'react-i18next';
import { useSelector } from 'react-redux';
import { toast } from 'react-toastify';
import Crud_Service from '../../../apis/CrudService.jsx';
import ActionButtons from '../../../components/_form/ActionButtons.tsx';
import { StatusBadge } from '../../../components/_form/FormElements.jsx';
import TableActionButtons from '../../../components/_form/TableActionButtons.jsx';
import TableSearch from '../../../components/_form/TableSearch.jsx';
import FmAutoComplete from '../../../components/_mui/FmAutoComplete.tsx';
import FmTextField from '../../../components/_mui/FmTextField.tsx';
import MuiDialogOne from '../../../components/_mui/MuiDialogOne.jsx';
import DashboardLayout from '../../../components/DashboardLayout.tsx';
import DataTable from '../../../components/DataTable.tsx';
import CommonView from '../../../components/helpers/CommonView.jsx';
import DynamicViewFields from '../../../components/helpers/DynamicViewFields.jsx';
import useCommonFetchApi from '../../../components/helpers/useCommonFetchApi.tsx';
import useLoader from '../../../components/helpers/UseLoader.tsx';
import useTableLogic from '../../../components/helpers/UseTableLogic.tsx';
import CommonLoader from '../../../components/page/CommonLoader.jsx';
import PageHeader from '../../../components/PageHeader.jsx';
import { CreateButton } from '../../../components/small/Buttons.jsx';
import GlassCard from '../../../components/small/GlassCard.tsx';
import { FloorvalidationSchema } from '../../../utils/CommonvalidationSchemas.tsx';
import { FloorAreaUOMEnum, statusOptions } from '../../../utils/CommonVariables.tsx';
import { findNavByName } from '../../../utils/navUtils.ts';
import usePermissions from '../../../utils/CommonUtils/PermissionUtil.tsx';
import FmSearchableSelect from '../../../components/_mui/FmSearchableSelect.tsx';
import DataTableToolbarButtons from '../../../components/helpers/DataTableToolbarButtons.tsx';

const Floor = (props) => {
  const { t } = useTranslation();
  const { type } = props;

  const [openCreate, setOpenCreate] = useState(false);
  const [openEdit, setOpenEdit] = useState(false);
  const [loading, setLoading] = useState(false);
  const [editData, setEditData] = useState(null);

  const { handleSubmit, setValue, reset, control } = useForm({
    resolver: yupResolver(FloorvalidationSchema),
  });
  const {
    createLoading,
    startCreateLoading,
    stopCreateLoading,
    editLoading,
    startEditLoading,
    stopEditLoading,
  } = useLoader();
  const {
    rows,
    pageSize,
    pageNumber,
    setSearchKeyword,
    isLoading,
    tableRecordCounts,
    handlePaginationModelChange,
    handleSortModelChange,
    searchKeyword,
    setRows,
    fetchData,
  } = useTableLogic('floors');
  const crud = new Crud_Service();
  const [viewDetails, setViewdetails] = useState(false);
  const handleViewClick = async (props) => {
    setViewdetails(props);
  };
  const { data: blocks } = useCommonFetchApi('blocks') || {
    data: [],
  };
  const { data: floortype } = useCommonFetchApi('floortype') || {
    data: [],
  };
  const { data: unitOfMeasurements } = useCommonFetchApi(
    'unitOfMeasurements'
  ) || {
    data: [],
  };
  const { data: wings } = useCommonFetchApi('wings') || {
    data: [],
  };
  useEffect(() => {
    document.title = `Floor | Admin`;
  }, []);

  //create the Card Type
  const handleSubmitFloor = async (values) => {
    const combinedData = {
      ...values,
      floorArea: values?.floorArea,
    };
    startCreateLoading();
    await crud.create('floors', combinedData, (err, res) => {
      if (res?.status === 201) {
        toast.success('Floor Created Successfully!');
        setOpenCreate(false);
        stopCreateLoading();
        fetchData();
      } else {
        stopCreateLoading();
      }
    });
  };

  const handleCreateClickOpen = () => {
    reset();
    setOpenCreate(true);
  };
  const handleCreateClose = () => {
    reset();
    setOpenCreate(false);
  };

  const counters = [
    {
      name: 'All',
      count: tableRecordCounts?.total || 0,
    },
    {
      name: 'Active',
      count: tableRecordCounts?.active || 0,
    },
    {
      name: 'Deactivated',
      count: tableRecordCounts?.inActive || 0,
    },
  ];

  const excludeKeys = [
    'floorId',
    'blockId',
    'createdDate',
    'updatedDate',
    'wingId',
    'floorAreaUOMId',
    'status',
    'sortNumber',
    'floorTypeId',
    'deleteable',
    'editable',
  ];

  //handle Delete Record
  const handleDeleteClick = async (view) => {
    await crud.remove('floors', view?.floorId, (err, res) => {
      if (res?.status === 204) {
        toast.success('Floor Deleted Successfully');
        fetchData();
      } else {
      }
    });
  };

  const handleEditClick = async (view) => {
    setOpenEdit(true);
    setLoading(true);
    await crud.getSingle('floors', view?.floorId, (err, res) => {
      if (res?.status === 200) {
        Object.entries({
          ...res?.data,
        }).forEach(([key, value]) => {
          setValue(key, value);
        });
        setEditData(res?.data);
        setLoading(false);
      }
    });
  };

  const handleEditFloor = async (values) => {
    startEditLoading();
    await crud.update('floors', editData?.floorId, values, (err, res) => {
      if (res?.status === 200) {
        toast.success('Floor Updated Successfully');
        setOpenEdit(false);
        stopEditLoading();
        fetchData();
      } else {
        stopEditLoading();
        setOpenEdit(true);
      }
    });
  };
  const menu = useSelector((state) => state?.menu?.items);

  const SubMenu = useMemo(() => {
    return menu?.map((item) => item?.menus?.flat()).flat() || [];
  }, [menu]);

  const resultAdmin = useMemo(
    () => findNavByName(SubMenu, 'Admin', 'All Masters'),
    [SubMenu]
  );
  const resultCafm = useMemo(
    () => findNavByName(SubMenu, 'CAFM', 'All master'),
    [SubMenu]
  );
  const currentPath = location.pathname;
  const index = resultCafm?.findIndex((item) => item.url === currentPath);
  const menuPermission = resultCafm[index]?.permissionDtos;
  const permissions = usePermissions(menuPermission);

  const ActionData = [
    {
      name: 'View',
      icon: <Icon icon='solar:eye-linear' />,
      hidden: !permissions.view,

      onClick: (props) => handleViewClick(props),
    },
    {
      name: 'Edit',
      icon: <Icon icon='solar:pen-new-round-linear' />,
      hidden: !permissions.edit,

      onClick: (props) => handleEditClick(props),
    },
    {
      name: 'Delete',
      icon: <Icon icon='solar:trash-bin-2-linear' />,
      hidden: !permissions.delete,

      onClick: (props) => handleDeleteClick(props),
      danger: true,
    },
  ];

  const columns = [
    {
      field: 'action',
      headerName: '',
      width: 140,
      sortable: false,
      disableClickEventBubbling: false,

      renderCell: (params) => {
        return (
          <TableActionButtons
            Actions={ActionData?.map((action) => ({
              ...action,
              onClick: () => action.onClick(params.row),
            }))}
          />
        );
      },
    },
    {
      field: 'floorName',
      headerName: 'Floor Name',
      flex: 1,
    },
    {
      field: 'floorTypeName',
      headerName: 'Floor Type Name',
      flex: 1,
    },
    {
      field: 'floorArea',
      headerName: 'Floor Area',
      flex: 1,
    },
    {
      field: 'floorAreaUOMName',
      headerName: 'Floor Area UOM Name',
      flex: 1,
    },
    {
      field: 'wingName',
      headerName: 'Wing Name',
      flex: 1,
    },
    {
      field: 'blockName',
      headerName: 'Block Name',
      flex: 1,
    },

    {
      field: 'status',
      headerName: 'Status',
      flex: 1,
      renderCell: (params) => {
        return (
          <StatusBadge
            title={params.value === 2 ? 'Active' : 'Inactive'}
            type={params.value === 2 ? 'green' : 'red'}
          />
        );
      },
    },
  ];

  return (
    <DashboardLayout
      title='Floor'
      actionButtons={
        <>
          <PageHeader counters={counters} />
        </>
      }
      menu={
        type === 'admin' ? resultAdmin : type === 'cafm' ? resultCafm : null
      }
      hasSubmenu={true}
    >
      <GlassCard>
        <Box sx={{ height: 'calc(100vh - 200px)' }}>
          <DataTable
            rows={rows}
            columns={columns}
            loading={isLoading}
            getRowClassName={(params) =>
              `${params.row.status === 0 && 'bg-red-50'}`
            }
            sortingMode='server'
            paginationMode='server'
            onPaginationModelChange={(model) =>
              handlePaginationModelChange(model)
            }
            onSortModelChange={(model) => handleSortModelChange(model)}
            page={pageNumber - 1}
            pageSize={pageSize}
            rowCount={tableRecordCounts?.total}
            slots={{
              toolbar: () => (
                <Box
                  className='p-2 w-full flex justify-between items-center'
                  sx={{
                    borderBottom: '1px solid',
                    borderColor: 'border.main',
                  }}
                >
                  <Box sx={{ maxWidth: '250px' }}>
                    <TableSearch
                      placeholder='Search'
                      fullWidth
                      setSearchKeyword={setSearchKeyword}
                      searchValue={searchKeyword}
                    />
                  </Box>
                  <Box className='flex items-center gap-4 justify-end'>
                    {permissions.create && (
                      <CreateButton
                        name='New'
                        onClick={handleCreateClickOpen}
                      />
                    )}
                    <DataTableToolbarButtons
                      disableColumn
                      // handleExcelExport={downloadDesignations}
                    />
                  </Box>
                </Box>
              ),
            }}
          />
        </Box>
      </GlassCard>

      {/* create */}

      <MuiDialogOne
        title='Create New'
        open={openCreate}
        onClose={handleCreateClose}
      >
        <form onSubmit={handleSubmit(handleSubmitFloor)}>
          <Grid container spacing={3}>
            <Grid item md={12}>
              <FmTextField
                name='floorName'
                control={control}
                label='Floor Name'
                required
              />
            </Grid>
            <Grid item md={12}>
              <FmAutoComplete
                name='floorTypeId'
                control={control}
                options={floortype}
                label='Floor Type'
                displayField='floorTypeName'
                optionFields={['floorTypeName']}
                valueKey='floorTypeId'
                required
              />
            </Grid>
            <Grid item md={12}>
              <FmTextField
                name='floorArea'
                control={control}
                label='Floor Area'
                required
              />
            </Grid>
            <Grid item md={12}>
              <FmAutoComplete
                name='floorAreaUOMId'
                control={control}
                options={FloorAreaUOMEnum}
                label='Floor Area UOM'
                displayField='label'
                optionFields={['label']}
                valueKey='value'
                required
              />
            </Grid>
            <Grid item md={12}>
              <FmAutoComplete
                name='wingId'
                control={control}
                options={wings}
                label='Wings'
                displayField='wingName'
                optionFields={['wingName']}
                valueKey='wingId'
                required
              />
            </Grid>
            <Grid item md={12}>
              <FmAutoComplete
                name='blockId'
                control={control}
                options={blocks}
                label='Block'
                displayField='blockName'
                optionFields={['blockName']}
                valueKey='blockId'
                required
              />
            </Grid>

            <Grid item md={12}>
              <ActionButtons
                onSubmit={handleSubmit(handleSubmitFloor)}
                onCancel={handleCreateClose}
                onReset={reset}
                submitLoading={createLoading}
                cancelLoading={false}
                submitText='Create'
              />
            </Grid>
          </Grid>
        </form>
      </MuiDialogOne>

      {/* view */}
      <MuiDialogOne
        title='View Details'
        open={viewDetails}
        onClose={() => setViewdetails(!viewDetails)}
      >
        {viewDetails && viewDetails?.floorId && (
          <CommonView
            url='floors'
            id={viewDetails?.floorId}
            excludeKeys={excludeKeys}
            renderFields={(data, fields) => (
              <DynamicViewFields data={data} fields={fields} />
            )}
          />
        )}
      </MuiDialogOne>

      {/* edit */}
      <MuiDialogOne
        title='Update'
        open={openEdit}
        onClose={() => setOpenEdit(false)}
      >
        {loading ? (
          <CommonLoader />
        ) : (
          <form onSubmit={handleSubmit(handleEditFloor)}>
            <Grid container spacing={3}>
              <Grid item md={12}>
                <FmTextField
                  name='floorName'
                  control={control}
                  label='Floor Name'
                  required
                />
              </Grid>
              <Grid item md={12}>
                <FmAutoComplete
                  name='floorTypeId'
                  control={control}
                  options={floortype}
                  label='Floor Type'
                  displayField='floorTypeName'
                  optionFields={['floorTypeName']}
                  valueKey='floorTypeId'
                  required
                />
              </Grid>
              <Grid item md={12}>
                <FmTextField
                  name='floorArea'
                  control={control}
                  label='Floor Area'
                  required
                />
              </Grid>
              <Grid item md={12}>
                <FmAutoComplete
                  name='floorAreaUOMId'
                  control={control}
                  options={FloorAreaUOMEnum}
                  label='Floor Area UOM'
                  displayField='label'
                  optionFields={['label']}
                  valueKey='value'
                  required
                />
              </Grid>
              <Grid item md={12}>
                <FmAutoComplete
                  name='wingId'
                  control={control}
                  options={wings}
                  label='Wings'
                  displayField='wingName'
                  optionFields={['wingName']}
                  valueKey='wingId'
                  required
                />
              </Grid>
              <Grid item md={12}>
                <FmAutoComplete
                  name='blockId'
                  control={control}
                  options={blocks}
                  label='Block'
                  displayField='blockName'
                  optionFields={['blockName']}
                  valueKey='blockId'
                  required
                />
              </Grid>
              <Grid item md={12}>
                <ActionButtons
                  onSubmit={handleSubmit(handleEditFloor)}
                  onCancel={() => setOpenEdit(false)}
                  onReset={reset}
                  submitLoading={editLoading}
                  cancelLoading={false}
                  submitText='Update'
                />
              </Grid>
            </Grid>
          </form>
        )}
      </MuiDialogOne>
    </DashboardLayout>
  );
};

export default Floor;
