import { toast } from 'react-toastify';
// api.jsx

const BASE_URL = "https://fm.duceapps.com/api/v1";


const getEmployeeDocuments = async (employeeId) => {
  try {
    const response = await fetch(
      `${BASE_URL}/employees/${employeeId}/documents`
    );
    if (!response.ok) {
      throw new Error("Failed to fetch employee documents");
    }
    const data = await response.json();
    return data;
  } catch (error) {
    toast.error("Error fetching employee documents:", error);
    throw error;
  }
};
const getJobRequisitions = async () => {
  try {
    const response = await fetch(`${BASE_URL}/jobrequisitions`);
    if (!response.ok) {
      throw new Error("Failed to fetch job requisitions");
    }
    const data = await response.json();
    return data;
  } catch (error) {
    toast.error("Error fetching job requisitions:", error);
    throw error;
  }
};
const getJobRecruitments = async () => {
  try {
    const response = await fetch(`${BASE_URL}/jobrecruitments`);
    if (!response.ok) {
      throw new Error("Failed to fetch job Recruitments");
    }
    const data = await response.json();
    return data;
  } catch (error) {
    toast.error("Error fetching job Recruitments:", error);
    throw error;
  }
};

const getFinalSettlements = async () => {
  try {
    const response = await fetch(`${BASE_URL}/finalsettlements`);
    if (!response.ok) {
      throw new Error("Failed to fetch final settlements");
    }
    const data = await response.json();
    return data;
  } catch (error) {
    toast.error("Error fetching final settlements:", error);
    throw error;
  }
};

const getDepartments = async () => {
  try {
    const response = await fetch(`${BASE_URL}/departments`);
    if (!response.ok) {
      throw new Error("Failed to fetch departments");
    }
    const data = await response.json();
    return data;
  } catch (error) {
    toast.error("Error fetching departments:", error);
    throw error;
  }
};
const deleteMaster = async (type, id) => {
  try {
    await fetch(`${BASE_URL}/${type}/${id}`, {
      method: "DELETE",
      headers: {
        "Content-Type": "application/json",
        Authorization: `Bearer ${localStorage.getItem("token")}`,
      },
    });
  } catch (error) {
    toast.error("Error deleting department:", error);
    throw error;
  }
};
const createMaster = async (type, values, id) => {
  try {
    if (id) {
      const response = await fetch(`${BASE_URL}/${type}/${id}`, {
        method: "PUT",
        headers: {
          "Content-Type": "application/json",
          Authorization: `Bearer ${localStorage.getItem("token")}`,
        },
        body: JSON.stringify(values),
      });
      if (!response.ok) {
        throw new Error("Failed to create department");
      }
      const data = await response.json();
      return data;
    } else {
      const response = await fetch(`${BASE_URL}/${type}`, {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
          Authorization: `Bearer ${localStorage.getItem("token")}`,
        },
        body: JSON.stringify(values),
      });
      if (!response.ok) {
        throw new Error("Failed to edit department");
      }
      const data = await response.json();
      return data;
    }
  } catch (error) {
    toast.error("Error creating department:", error);
    throw error;
  }
};

const getDesignations = async () => {
  try {
    const response = await fetch(`${BASE_URL}/designations`);
    if (!response.ok) {
      throw new Error("Failed to fetch designations");
    }
    const data = await response.json();
    return data;
  } catch (error) {
    toast.error("Error fetching designations:", error);
    throw error;
  }
};

const getLocations = async () => {
  try {
    const response = await fetch(`${BASE_URL}/locations`);
    if (!response.ok) {
      throw new Error("Failed to fetch locations");
    }
    const data = await response.json();
    return data;
  } catch (error) {
    toast.error("Error fetching locations:", error);
    throw error;
  }
};
const getNationalities = async () => {
  try {
    const response = await fetch(`${BASE_URL}/nationalities`);
    if (!response.ok) {
      throw new Error("Failed to fetch nationalities");
    }
    const data = await response.json();
    return data;
  } catch (error) {
    toast.error("Error fetching nationalities:", error);
    throw error;
  }
};
const getExperienceTypes = async () => {
  try {
    const response = await fetch(`${BASE_URL}/experiencetypes`);
    if (!response.ok) {
      throw new Error("Failed to fetch Employee Types");
    }
    const data = await response.json();
    return data;
  } catch (error) {
    toast.error("Error fetching Employee Types:", error);
    throw error;
  }
};
const getContracts = async () => {
  try {
    const response = await fetch(`${BASE_URL}/contracts`);
    if (!response.ok) {
      throw new Error("Failed to fetch contracts");
    }
    const data = await response.json();
    return data;
  } catch (error) {
    toast.error("Error fetching contracts:", error);
    throw error;
  }
};
const getBloodGroups = async () => {
  try {
    const response = await fetch(`${BASE_URL}/bloodgroups`);
    if (!response.ok) {
      throw new Error("Failed to fetch blood groups");
    }
    const data = await response.json();
    return data;
  } catch (error) {
    toast.error("Error fetching blood groups:", error);
    throw error;
  }
};
const getDocumentTypes = async () => {
  try {
    const response = await fetch(`${BASE_URL}/documenttypes`);
    if (!response.ok) {
      throw new Error("Failed to fetch document types");
    }
    const data = await response.json();
    return data;
  } catch (error) {
    toast.error("Error fetching document types:", error);
    throw error;
  }
};

const getCancellationTypes = async () => {
  try {
    const response = await fetch(`${BASE_URL}/cancellationtypes`);
    if (!response.ok) {
      throw new Error("Failed to fetch cancellation types");
    }
    const data = await response.json();
    return data;
  } catch (error) {
    toast.error("Error fetching cancellation types:", error);
    throw error;
  }
};

const getEventAttendances = async (empId) => {
  try {
    const response = await fetch(
      `${BASE_URL}/employees/${empId}/event-attendances`
    );
    if (!response.ok) {
      throw new Error("Failed to fetch event attendances");
    }
    const data = await response.json();
    return data;
  } catch (error) {
    toast.error("Error fetching event attendances:", error);
    throw error;
  }
};

export {
  getEmployeeDocuments,
  getJobRequisitions,
  getJobRecruitments,
  getFinalSettlements,
  getDepartments,
  getDesignations,
  getLocations,
  getNationalities,
  getExperienceTypes,
  getContracts,
  getBloodGroups,
  getDocumentTypes,
  getCancellationTypes,
  createMaster,
  getEventAttendances,
  deleteMaster,
};
