import React, { useState } from 'react';
import { Box, Button, Stack } from '@mui/material';
import GlassCard from '../../../../components/small/GlassCard.tsx';
import ActionButtons from '../../../../components/_form/ActionButtons.tsx';
import FmTextField from '../../../../components/_mui/FmTextField.tsx';
import FmSearchableSelect from '../../../../components/_mui/FmSearchableSelect.tsx';
import FmDatePicker from '../../../../components/_mui/FmDatePicker.tsx';
import { useForm } from 'react-hook-form';
import MuiDialogOne from '../../../../components/_mui/MuiDialogOne.jsx';
import useTableLogic from '../../../../components/helpers/MRTUseTableLogic.tsx';
import FmMRTDataTable from '../../../../components/FmMRTDataTable.tsx';
import FmAutoComplete from '../../../../components/_mui/FmAutoComplete.tsx';
import { ticketsNoteStatusOptions } from '../../../../utils/CommonVariables.tsx';
import { TicketNoteValidationSchema } from '../../../../utils/CommonvalidationSchemas.tsx';
import { yupResolver } from '@hookform/resolvers/yup';
import useLoader from '../../../../components/helpers/UseLoader.tsx';
import dayjs from 'dayjs';
import { toast } from 'react-toastify';
import Crud_Service from '../../../../apis/CrudService.jsx';
import FmViewDateField from '../../../../components/_mui/FmViewDateField.tsx';
import TableActionButtons from '../../../../components/_form/TableActionButtons.jsx';
import { Icon } from '@iconify/react/dist/iconify.js';
import StatusNewBadge from '../../../../components/_form/StatusNewBadge.tsx';

const JobNotes = (props) => {
  const [editingRowId, setEditingRowId] = useState(null);
  const crud = new Crud_Service();
  const [editingRowData, setEditingRowData] = useState(null);
  const {
    createLoading,
    stopEditLoading,
    startCreateLoading,
    stopCreateLoading,
  } = useLoader();
  const { handleSubmit, control, reset, setValue } = useForm({
    mode: 'onChange',
    resolver: yupResolver(TicketNoteValidationSchema),
  });
  const { openCreate, setOpenCreate, handleCreateClose, navigate, id } = props;

  const {
    rows,
    isLoading,
    pagination,
    sorting,
    columnFilters,
    globalFilter,
    tableRecordCounts,
    setPagination,
    setSorting,
    setColumnFilters,
    setGlobalFilter,
    fetchData,
    setSearchKeyword,
    searchKeyword,
  } = useTableLogic(`tickets/${id}/notes`, ['ticketId'], [id]);

  const handleCreateClickOpen = () => {
    reset();
    setOpenCreate(true);
  };

  const handleSubmitTicketNote = async (values) => {
    startCreateLoading();
    const combinedData = {
      ...values,
      ticketId: id,
      dueDateTime: dayjs(values?.dueDateTime).format('YYYY-MM-DD'),
    };
    await crud.create(`ticketnotes`, combinedData, (err, res) => {
      if (res?.status === 201) {
        toast.success('Ticket Note Created Successfully');
        stopCreateLoading();
        setOpenCreate(false);
        fetchData();
      } else {
        stopCreateLoading();
      }
    });
  };

  const handleDelete = async (props) => {
    await crud.remove('ticketnotes', props?.ticketNoteId, (_err, res) => {
      if (res?.status === 204) {
        toast.success('Ticket Note Deleted Successfully');
        fetchData();
      }
    });
  };

  const handleUpdateTicketNote = async (values) => {
    const combinedData = {
      ...values,
      dueDateTime: dayjs(values?.date).format('YYYY-MM-DD'),
      status: 2,
    };

    await crud.update(
      'ticketnotes',
      editingRowId,
      combinedData,
      (_err, res) => {
        if (res?.status === 200) {
          stopEditLoading();
          fetchData();
          toast.success('Ticket Note Updated Successfully');
          setEditingRowData(null);
          setEditingRowId(null);
        } else {
          stopEditLoading();
        }
      }
    );
  };

  const handleCancel = () => {
    setEditingRowId(null);
    setEditingRowData(null);
  };
  const handleEdit = (row) => {
    setEditingRowId(row?.ticketNoteId);
    setEditingRowData({ ...row });
    Object.entries({
      ...row,
    }).forEach(([key, value]) => {
      setValue(key, value);
    });
  };
  const ActionData = [
    {
      name: 'Edit',
      icon: <Icon icon='solar:pen-2-broken' />,
      onClick: (props) => handleEdit(props),
    },
    {
      name: 'Delete',
      icon: <Icon icon='solar:trash-bin-minimalistic-broken' />,
      onClick: (props) => handleDelete(props),
      danger: true,
    },
  ];

  const columns = [
    {
      header: 'Actions',
      enableColumnPinning: true,
      enableEditing: false,
      enableSorting: false,
      size: 100,
      Cell: ({ row }) => (
        <Box className='flex gap-2'>
          {editingRowId === row?.original?.ticketNoteId ? (
            <>
              <Button
                onClick={handleSubmit(handleUpdateTicketNote)}
                variant='contained'
                className='p-1 px-2'
              >
                Save
              </Button>
              <Button
                onClick={handleCancel}
                variant='outlined'
                color='error'
                className='p-1 px-2'
              >
                Cancel
              </Button>
            </>
          ) : (
            <TableActionButtons
              Actions={ActionData?.map((action) => ({
                ...action,
                onClick: () => action.onClick(row?.original),
              }))}
            />
          )}
        </Box>
      ),
    },
    {
      accessorKey: 'employeeId',
      header: 'Name',
      size: 150,
      enableEditing: false, // Non-editable
      Cell: ({ cell }) => cell?.row?.original?.employeeName,
    },
    {
      accessorKey: 'dueDateTime',
      header: 'Due Date',
      size: 100,
      enableEditing: false, // Non-editable
      Cell: ({ cell }) =>
        FmViewDateField({ value: cell?.row?.original?.dueDateTime }),
    },
    {
      accessorKey: 'subject',
      header: 'Subject',
      enableEditing: false, // Non-editable
      Cell: ({ cell }) => cell?.row?.original?.subject,
    },
    {
      accessorKey: 'description',
      header: 'Description',
      enableEditing: false, // Non-editable
      Cell: ({ cell }) => cell?.row?.original?.description,
    },

    {
      accessorKey: 'ticketNoteStatusId',
      header: 'Ticket Note Status',
      size: 220,
      enableEditing: true, // Editable
      Cell: ({ cell }) => {
        return editingRowId === cell?.row?.original?.ticketNoteId ? (
          <FmAutoComplete
            name='ticketNoteStatusId'
            control={control}
            options={ticketsNoteStatusOptions}
            label='Ticket Note Status'
            displayField='statusName'
            optionFields={['statusName']}
            valueKey='statusId'
          />
        ) : (
          <StatusNewBadge
            title={cell?.row?.original?.ticketNoteStatusName}
            type={cell?.row?.original?.ticketNoteStatusName}
          />
        );
      },
    },
  ];
  return (
    <>
      <GlassCard className='h-[550px]'>
        <FmMRTDataTable
          columns={columns}
          enableRowSelection={false}
          rows={rows}
          rowCount={tableRecordCounts}
          editingRow={editingRowId}
          pagination={pagination}
          sorting={sorting}
          columnFilters={columnFilters}
          renderTopToolbarLeftChildrens={
            <h3 className='font-bold ms-3'>Job Notes</h3>
          }
          globalFilter={globalFilter}
          onCreateRow={handleCreateClickOpen}
          setPagination={setPagination}
          setSorting={setSorting}
          setColumnFilters={setColumnFilters}
          setGlobalFilter={setGlobalFilter}
          isLoading={isLoading}
          setSearchKeyword={setSearchKeyword}
          searchKeyword={searchKeyword}
          fetchData={fetchData}
          muiTableContainerProps={{ sx: { maxHeight: '100%', flex: 1 } }}
        />
      </GlassCard>
      <MuiDialogOne
        title='Add Note'
        open={openCreate}
        onClose={handleCreateClose}
        width='450px'
      >
        <form onSubmit={handleSubmit(handleSubmitTicketNote)}>
          <Stack spacing={3}>
            <FmSearchableSelect
              name='employeeId'
              control={control}
              apiUrl='employees'
              valueField='employeeId'
              headerField={['Code', 'Name', 'Email']}
              labelField={['employeeCode', 'fullName', 'emailId']}
              showField={['employeeCode', 'fullName', 'emailId']}
              label='Name'
            />
            <FmDatePicker
              name='dueDateTime'
              label='Due Date'
              control={control}
              required
            />
            <FmTextField name='subject' control={control} label='Subject' />
            <FmTextField
              name='description'
              control={control}
              label='Description'
              multiline={true}
              rows={2}
              maxRows={3}
            />
            <FmAutoComplete
              name='ticketNoteStatusId'
              control={control}
              options={ticketsNoteStatusOptions}
              label='Ticket Note Status'
              displayField='statusName'
              optionFields={['statusName']}
              valueKey='statusId'
            />
            <ActionButtons
              onSubmit={handleSubmit(handleSubmitTicketNote)}
              onCancel={handleCreateClose}
              onReset={reset}
              submitLoading={createLoading}
              cancelLoading={false}
              submitText='Create'
            />
          </Stack>
        </form>
      </MuiDialogOne>
    </>
  );
};

export default JobNotes;
