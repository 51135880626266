import React, { useState } from 'react';
import GlassCard from '../../../../components/small/GlassCard.tsx';
import MRTExpandTableStyle from '../../../../components/table/MRTExpandTableStyle.tsx';
import { Box, Button, Grid, Icon } from '@mui/material';
import { useForm } from 'react-hook-form';
import ActionButtons from '../../../../components/_form/ActionButtons.tsx';
import Crud_Service from '../../../../apis/CrudService.jsx';
import { toast } from 'react-toastify';
import { useTranslation } from 'react-i18next';
import SubContractListTable from './SubContractListTable.tsx';
import useTableLogic from '../../../../components/helpers/MRTUseTableLogic.tsx';
import { yupResolver } from '@hookform/resolvers/yup';
import { contractSubSchema } from '../../../../utils/CommonvalidationSchemas.tsx';
import FmSearchableSelect from '../../../../components/_mui/FmSearchableSelect.tsx';
import FmTextField from '../../../../components/_mui/FmTextField.tsx';
import UploadSquareBoxes from '../../../../components/_form/UploadSquareBoxes.tsx';
import GlassCardCollapse from '../../../../components/small/GlassCardCollapse.tsx';
import useLoader from '../../../../components/helpers/UseLoader.tsx';

const gridValue = 2.4;

const AddFields = (props) => {
  const { fetchData, contractId } = props;
  const [components, setComponents] = useState([]);
  const [scopeDoc, setScopeDoc] = useState([]);
  const [counter, setCounter] = useState(1);
  const [imageUrl, setImageUrl] = useState([]);
  const [selectedSupplier, setSelectedSupplier] = React.useState(null);
  const { createLoading, startCreateLoading, stopCreateLoading } = useLoader();
  const [imageLoading, setImageLoading] = useState(
    components.reduce((acc, component) => {
      acc[component.id] = false; // Initialize loading state for each component
      return acc;
    }, {})
  );
  const {
    handleSubmit,
    reset,
    control,
    setError,
    clearErrors,
    getValues,
    setValue,
    formState: { errors },
  } = useForm({
    mode: 'onChange',
    resolver: yupResolver(contractSubSchema),
  });
  const crud = new Crud_Service();
  const { t } = useTranslation();

  const onSubmit = async (values) => {
    const payload = {
      ...values,
      contractId, // Add contractId to the payload
      signContractDocumentId: values?.fileUploadIds[0],
      signScopeDocumentId: values?.fileUploadIds[1],
    };
    await crud.create('subcontracts', payload, (err, res) => {
      startCreateLoading();
      if (err) {
        toast.error('Something went wrong:', err);
        return;
      }
      if (res?.status === 201) {
        toast.success('Sub Contract Created successfully');
        reset();
        setComponents([]);
        setScopeDoc([]);
        fetchData();
        stopCreateLoading();
      } else {
        stopCreateLoading();
      }
    });
  };
  const handleAddComponent = () => {
    const currentFileIds = getValues('fileUploadIds') || [];
    if (currentFileIds.length >= 3) {
      setError('fileUploadIds', {
        type: 'manual',
        message: 'You can upload a maximum of 3 files.',
      });
      return;
    } else {
      clearErrors('fileUploadIds');
    }
    setComponents([...components, { id: counter }]);
    setCounter(counter + 1);
  };

  const handleRemoveComponent = (id) => {
    setComponents(components.filter((component) => component.id !== id));
    setImageUrl((prev) => {
      const updatedUrls = { ...prev };
      delete updatedUrls[id];
      return updatedUrls;
    });
    clearErrors('fileUploadIds');
  };

  const handleAddSopeComponent = () => {
    const currentFileIds = getValues('fileUploadIds') || [];
    if (currentFileIds.length >= 3) {
      setError('fileUploadIds', {
        type: 'manual',
        message: 'You can upload a maximum of 3 files.',
      });
      return;
    } else {
      clearErrors('fileUploadIds');
    }
    setScopeDoc([...scopeDoc, { id: counter }]);
    setCounter(counter + 1);
  };

  const handleRemoveScopeComponent = (id) => {
    setScopeDoc(scopeDoc.filter((scopeDoc) => scopeDoc.id !== id));
    setImageUrl((prev) => {
      const updatedUrls = { ...prev };
      delete updatedUrls[id];
      return updatedUrls;
    });
    clearErrors('fileUploadIds');
  };
  const handleFileChange = (id, fileId) => {
    if (typeof fileId === 'number') {
      const currentFileIds = getValues('fileUploadIds') || [];
      const updatedFileIds = [...currentFileIds, fileId];
      setValue('fileUploadIds', updatedFileIds);
    }
  };

  const handleSelectedSupplier = (e) => {
    setSelectedSupplier(e);
  };
  return (
    <GlassCardCollapse className='mb-2' title='Create New'>
      <form onSubmit={handleSubmit(onSubmit)}>
        <Grid container spacing={2}>
          <Grid item md={gridValue}>
            <FmSearchableSelect
              name='divisionId'
              control={control}
              apiUrl='divisions'
              valueField='divisionId'
              labelField={['divisionName']}
              showField={['divisionName']}
              label='Division'
            />
          </Grid>
          <Grid item md={gridValue}>
            <FmSearchableSelect
              name='supplierId'
              control={control}
              apiUrl='suppliers'
              onChangeProp={handleSelectedSupplier}
              valueField='supplierId'
              labelField={['supplierCode', 'officialSupplierName']}
              showField={['supplierCode', 'officialSupplierName']}
              label='Supplier'
            />
          </Grid>
          <Grid item md={gridValue}>
            <FmSearchableSelect
              name='supplierPaymentTermId'
              control={control}
              apiUrl='supplierpaymentterms'
              valueField='supplierPaymentTermId'
              labelField={['supplierPaymentTermCode', 'paymentTermName']}
              showField={['supplierPaymentTermCode', 'paymentTermName']}
              label={'Supplier Payment Terms'}
            />
          </Grid>
          <Grid item md={gridValue}>
            <FmTextField
              name='primaryAddress'
              control={control}
              label='Primary Address'
            />
          </Grid>
          <Grid item md={gridValue}>
            <FmTextField
              name='secondaryAddress'
              control={control}
              label='Secondary Address'
            />
          </Grid>
          <Grid item md={gridValue}>
            <FmTextField
              name='thirdAddress'
              control={control}
              label='Third Address'
            />
          </Grid>
          <Grid item md={gridValue}>
            <FmSearchableSelect
              name='primaryContactPersonId'
              control={control}
              queryparam='supplierId'
              queryparamValue={selectedSupplier?.supplierId}
              apiUrl='supplierspocs'
              valueField='supplierSPOCId'
              labelField={['supplierSPOCName', 'phoneNumber']}
              showField={['supplierSPOCName', 'phoneNumber']}
              label={'Primary Contact Person'}
            />
          </Grid>
          <Grid item md={gridValue}>
            <FmSearchableSelect
              name='secondaryContactPersonId'
              control={control}
              queryparam='supplierId'
              queryparamValue={selectedSupplier?.supplierId}
              apiUrl='supplierspocs'
              valueField='supplierSPOCId'
              labelField={['supplierSPOCName', 'phoneNumber']}
              showField={['supplierSPOCName', 'phoneNumber']}
              label={'Second Contact Person'}
            />
          </Grid>
          <Grid item md={gridValue}>
            <FmSearchableSelect
              name='thirdContactPersonId'
              control={control}
              queryparam='supplierId'
              queryparamValue={selectedSupplier?.supplierId}
              apiUrl='supplierspocs'
              valueField='supplierSPOCId'
              labelField={['supplierSPOCName', 'phoneNumber']}
              showField={['supplierSPOCName', 'phoneNumber']}
              label={'Third Contact Person'}
            />
          </Grid>
          <Grid item md={gridValue}>
            <Box>
              <Box className='flex gap-5 flex-wrap'>
                <Button
                  onClick={handleAddComponent}
                  variant='outlined'
                  className='px-1 w-full'
                >
                  <span>Upload Sign Contract Document</span>
                </Button>

                {components.map((component) => (
                  <UploadSquareBoxes
                    key={component.id}
                    imageUrl={component.path}
                    setImageUrl={setImageUrl} // If you need this, otherwise omit
                    handleRemove={handleRemoveComponent}
                    setComponents={setComponents}
                    id={component.id}
                    setLoading={setImageLoading}
                    isLoading={imageLoading[component.id]}
                    onChange={(fileId) =>
                      handleFileChange(component.id, fileId)
                    }
                    fileTypeId={38}
                  />
                ))}
              </Box>
              {errors.fileUploadIds && (
                <span style={{ color: 'red' }}>
                  {errors.fileUploadIds.message}
                </span>
              )}
            </Box>
          </Grid>
          <Grid item md={gridValue}>
            <Box>
              <Box className='flex gap-5 flex-wrap'>
                <Button
                  onClick={handleAddSopeComponent}
                  variant='outlined'
                  className='px-1 w-full'
                >
                  <span>Upload Sign Scope Document</span>
                </Button>

                {scopeDoc.map((scopeDoc) => (
                  <UploadSquareBoxes
                    key={scopeDoc.id}
                    imageUrl={scopeDoc.path}
                    setImageUrl={setImageUrl} // If you need this, otherwise omit
                    handleRemove={handleRemoveScopeComponent}
                    setComponents={setScopeDoc}
                    id={scopeDoc.id}
                    setLoading={setImageLoading}
                    isLoading={imageLoading[scopeDoc.id]}
                    onChange={(fileId) => handleFileChange(scopeDoc.id, fileId)}
                    fileTypeId={38}
                  />
                ))}
              </Box>
              {errors.fileUploadIds && (
                <span style={{ color: 'red' }}>
                  {errors.fileUploadIds.message}
                </span>
              )}
            </Box>
          </Grid>
        </Grid>
        <ActionButtons
          onSubmit={handleSubmit(onSubmit)}
          onReset={reset}
          submitLoading={createLoading}
          cancelLoading={false}
        />
      </form>
    </GlassCardCollapse>
  );
};

const ViewContractSubContract = (props) => {
  const { contractId } = props;
  const [view, setView] = useState(false);
  const [viewRow, setViewRow] = useState([]);
  const {
    rows,
    isLoading,
    pagination,
    sorting,
    columnFilters,
    globalFilter,
    tableRecordCounts,
    setPagination,
    setSorting,
    setColumnFilters,
    setGlobalFilter,
    fetchData,
    setSearchKeyword,
    searchKeyword,
  } = useTableLogic('subcontracts', 'contractId', contractId);

  return (
    <>
      <AddFields fetchData={fetchData} contractId={contractId} />

      <GlassCard>
        <MRTExpandTableStyle>
          <SubContractListTable
            rows={rows}
            tableRecordCounts={tableRecordCounts}
            pagination={pagination}
            sorting={sorting}
            setPagination={setPagination}
            setSorting={setSorting}
            columnFilters={columnFilters}
            globalFilter={globalFilter}
            setColumnFilters={setColumnFilters}
            setGlobalFilter={setGlobalFilter}
            isLoading={isLoading}
            setSearchKeyword={setSearchKeyword}
            searchKeyword={searchKeyword}
            fetchData={fetchData}
          />
        </MRTExpandTableStyle>
      </GlassCard>
    </>
  );
};

export default ViewContractSubContract;
