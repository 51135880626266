import React from 'react';
import { Box, Tooltip } from '@mui/material';
import { ViewTable } from '../_form/FormElements.jsx';

const TableExtraContentTooltip = ({ children, titleRows = [] }) => {
  const TitleContent = () => {
    return (
      <Box className='min-w-48 p-2 flex flex-col gap-1 min-h-4'>
        <ViewTable disableBorder paddingLow right rows={titleRows} />
      </Box>
    );
  };

  return (
    <Tooltip
      title={<TitleContent />}
      placement='left'
      arrow
      classes={{
        tooltip: 'px-0 py-1 rounded-lg',
        arrow: 'text-white',
      }}
      slotProps={{
        tooltip: {
          sx: {
            background: 'rgb(255 255 255 / 50%)',
            backdropFilter: 'blur(20px)',
            boxShadow: 8,
            color: 'text.main',
          },
        },
      }}
    >
      <Box className='w-full'>{children}</Box>
    </Tooltip>
  );
};

export default TableExtraContentTooltip;
