import React, { useEffect, useState } from 'react';
import DashboardLayout from '../../../components/DashboardLayout.tsx';
import { Box, Grid } from '@mui/material';
import GlassCard from '../../../components/small/GlassCard.tsx';
import ActionButtons from '../../../components/_form/ActionButtons.tsx';
import { useNavigate, useParams } from 'react-router-dom';
import Crud_Service from '../../../apis/CrudService.jsx';
import { useForm } from 'react-hook-form';
import useLoader from '../../../components/helpers/UseLoader.tsx';
import CommonLoader from '../../../components/page/CommonLoader.jsx';
import FmTextField from '../../../components/_mui/FmTextField.tsx';
import { toast } from 'react-toastify';
import FmAutoComplete from '../../../components/_mui/FmAutoComplete.tsx';
import { LocalizationProvider } from '@mui/x-date-pickers';
import dayjs from 'dayjs';
import useCommonFetchApi from '../../../components/helpers/useCommonFetchApi.tsx';
import { CreateButton } from '../../../components/small/Buttons.jsx';
import { attachments } from '../../../utils/CommonVariables.tsx';
import FmFileInput from '../../../components/_mui/FmFileInput.tsx';
import { AdapterDayjs } from '@mui/x-date-pickers/AdapterDayjs';
import { AccommodationSchema } from '../../../utils/CommonvalidationSchemas.tsx';
import FmDatePicker from '../../../components/_mui/FmDatePicker.tsx';
import { yupResolver } from '@hookform/resolvers/yup';
import apiInstance from '../../../apis/ApiService.jsx';
import UploadModalBox from '../../../components/upload/UploadModalBox.tsx';

const EditAccommodation = () => {
  const navigate = useNavigate();
  const crud = new Crud_Service();
  const [loading, setLoading] = useState(false);
  const [editData, setEditData] = useState(null);
  const { handleSubmit, control, setValue, reset } = useForm({
    mode: 'onChange',
    resolver: yupResolver(AccommodationSchema),
  });
  const { startEditLoading, stopEditLoading, editLoading } = useLoader();
  const { id } = useParams();
  const [uploadLoading, setUploadLoading] = useState([]);
  const [fileInputs, setFileInputs] = useState(attachments);
  const [files, setFiles] = useState([]);
  const [uploadedFileIds, setUploadedFileIds] = useState([]);
  const [previewdData, setPreviewData] = useState([]);
  const GridValue = 2.4;

  const handleFileIds = (fileIds) => {
    setUploadedFileIds(fileIds);
  };

  //get All Apis
  const { data: locations } = useCommonFetchApi('locations');
  const { data: premisetypes } = useCommonFetchApi('premisetypes');
  const { data: premisepurposes } = useCommonFetchApi('premisepurposes');

  useEffect(() => {
    getAccomomodation();
  }, []);

  const getAccomomodation = async () => {
    setLoading(true);
    await crud.getSingle('accommodations', id, (_err, res) => {
      if (res?.status === 200) {
        const data = res?.data;
        Object.entries({
          ...data,
        }).forEach(([key, value]) => {
          setValue(key, value);
        });
        setEditData(res?.data);
        setLoading(false);
      } else {
      }
    });
  };

  // // Function to handle file upload
  // const handleFileUpload = async (e, index) => {
  //   const file = e.target.files[0];
  //   if (!file) return;

  //   if (file) {
  //     const newLoadingState = [...uploadLoading];
  //     newLoadingState[index] = true;
  //     setUploadLoading(newLoadingState);

  //     const fileFormData = new FormData();
  //     fileFormData.append('file', file);
  //     fileFormData.append('fileTypeId', 50);

  //     await crud.create(`/files/upload`, fileFormData, (__err, res) => {
  //       if (res?.status === 200) {
  //         newLoadingState[index] = false;
  //         setUploadLoading(newLoadingState);
  //         setFileInputs((prevFiles) => {
  //           const updatedFiles = [...prevFiles];
  //           updatedFiles[index].fileId = res?.data?.fileId;
  //           updatedFiles[index].fileName = res?.data?.originalFileName;
  //           return updatedFiles;
  //         });
  //       } else {
  //         newLoadingState[index] = false;
  //         setUploadLoading(newLoadingState);
  //       }
  //     });
  //   }
  // };

  useEffect(() => {
    if (editData && editData?.accommodationImages) {
      editData?.accommodationImages?.forEach((image) => {
        handleDownloadFile(image?.fileId);
      });
    }
  }, [editData]);

  const handleDownloadFile = async (fileId) => {
    try {
      // Check if fileId is null, undefined, or an empty string
      if (!fileId) {
        return; // Exit early without calling the API
      }

      const imageUrl = await apiInstance.getFiles(`files/download/${fileId}`);

      setPreviewData((prevData) => [...prevData, imageUrl, fileId]);
    } catch (err) {
      toast.error(`Error fetching image: ${err.message}`);
    }
  };



  const handleUpdateAccommodation = async (values) => {
    startEditLoading();
    const combinedData = {
      ...values,
      startDate: dayjs(values?.startDate).format('YYYY-MM-DD'),
      endDate: dayjs(values?.endDate).format('YYYY-MM-DD'),
      status: 2,
      updateAccommodationImages: uploadedFileIds?.map((fileId, index) => ({
        accommodationImageId:
          values?.accommodationImages?.[index]?.accommodationImageId || 0,
        fileId: values?.accommodationImages?.[index]?.fileId || fileId,
        status: 2,
      })),
    };

    await crud.update('accommodations', id, combinedData, (_err, res) => {
      if (res?.status === 200) {
        toast.success('Accommodation Updated Successfully');
        navigate(-1);
        stopEditLoading();
      } else {
        stopEditLoading();
      }
    });
  };

  return (
    <DashboardLayout
      title='Edit Accommodation'
      actionButtons={
        <>
          <Box className='flex flex-row items-center whitespace-nowrap gap-3'>
            <CreateButton
              name='Create'
              onClick={() =>
                navigate('/admin/accommodation/accomodations/create')
              }
            />
          </Box>
        </>
      }
    >
      <GlassCard className='p-5'>
        {loading ? (
          <CommonLoader />
        ) : (
          <form onSubmit={handleSubmit(handleUpdateAccommodation)}>
            <LocalizationProvider dateAdapter={AdapterDayjs}>
              <Grid container spacing={3}>
                <Grid item md={GridValue}>
                  <FmTextField
                    name='accommodationName'
                    control={control}
                    label='Accommodation Name'
                  />
                </Grid>
                <Grid item md={GridValue}>
                  <FmAutoComplete
                    name='locationId'
                    control={control}
                    options={locations}
                    label='Location'
                    displayField='locationName'
                    optionFields={['locationName']}
                    valueKey='locationId'
                  />
                </Grid>
                <Grid item md={GridValue}>
                  <FmAutoComplete
                    name='premiseTypeId'
                    control={control}
                    options={premisetypes}
                    label='Premise Types'
                    displayField='premiseTypeName'
                    optionFields={['premiseTypeName']}
                    valueKey='premiseTypeId'
                  />
                </Grid>
                <Grid item md={GridValue}>
                  <FmAutoComplete
                    name='premisePurposeId'
                    control={control}
                    options={premisepurposes}
                    label='Premise Purposes'
                    displayField='premisePurposeName'
                    optionFields={['premisePurposeName']}
                    valueKey='premisePurposeId'
                  />
                </Grid>
                <Grid item md={GridValue}>
                  <FmTextField
                    name='duration'
                    control={control}
                    label='Duration'
                  />
                </Grid>
                <Grid item md={GridValue}>
                  <FmTextField
                    name='noOfRoom'
                    control={control}
                    label='No. of Room'
                    pattern='Number'
                  />
                </Grid>
                <Grid item md={GridValue}>
                  <FmTextField
                    name='noOfBedSpace'
                    control={control}
                    label='No. of Bed Space'
                    pattern='Number'
                  />
                </Grid>
                <Grid item md={GridValue}>
                  <FmTextField name='size' control={control} label='Size' />
                </Grid>
                <Grid item md={GridValue}>
                  <FmDatePicker
                    name='startDate'
                    control={control}
                    label='Start Date'
                    defaultValue={dayjs().add(-1, 'day')}
                  />
                  {/* <Controller
                    name='startDate'
                    control={control}
                    defaultValue={null}
                    render={({ field: { onChange, value } }) => (
                      <DatePicker
                        label='Start Date'
                        value={value ? dayjs(value) : null}
                        format='DD/MM/YYYY'
                        onChange={(newValue) => {
                          onChange(newValue);
                          setStartDate(newValue);
                        }}
                      />
                    )}
                  /> */}
                </Grid>
                <Grid item md={GridValue}>
                  <FmDatePicker
                    name='endDate'
                    control={control}
                    label='End Date'
                    defaultValue={dayjs().add(-1, 'day')}
                  />
                  {/* <Controller
                    name='endDate'
                    control={control}
                    defaultValue={null}
                    render={({ field: { onChange, value } }) => (
                      <DatePicker
                        label='End Date'
                        value={value ? dayjs(value) : null}
                        format='DD/MM/YYYY'
                        minDate={startDate ? dayjs(startDate) : null}
                        onChange={(newValue) => {
                          onChange(newValue);
                        }}
                      />
                    )}
                  /> */}
                </Grid>
                <Grid item md={GridValue}>
                  <FmTextField
                    name='beneficiaryDetails'
                    control={control}
                    label='Beneficiary Details'
                  />
                </Grid>
                <Grid item md={GridValue}>
                  <FmTextField
                    name='contactInformation'
                    control={control}
                    label='Contact Information'
                  />
                </Grid>
                <Grid item md={GridValue}>
                  <FmTextField
                    name='contractNo'
                    control={control}
                    label='Contract No'
                  />
                </Grid>
                <Grid item md={GridValue}>
                  <FmTextField
                    name='securityDeposit'
                    control={control}
                    label='Security Deposit'
                    pattern='Decimal'
                  />
                </Grid>
                <Grid item md={GridValue}>
                  <FmTextField
                    name='rentalCharges'
                    control={control}
                    label='Rental Charges'
                    pattern='Decimal'
                  />
                </Grid>
                <Grid item md={GridValue}>
                  <FmTextField
                    name='laundryCharges'
                    control={control}
                    label='Laundry Charges'
                    pattern='Decimal'
                  />
                </Grid>
                <Grid item md={GridValue}>
                  <FmTextField
                    name='chargesMonth'
                    control={control}
                    label='Charge Month'
                    pattern='Decimal'
                  />
                </Grid>
                <Grid item md={GridValue}>
                  <FmTextField
                    name='negotiation'
                    control={control}
                    label='Negotiation'
                    pattern='Decimal'
                  />
                </Grid>
                <Grid item md={4.8}>
                  <UploadModalBox
                    onSubmitFiles={handleFileIds}
                    files={files}
                    setFiles={setFiles}
                    previewdData={previewdData}
                  />
                </Grid>
                <Grid item md={5}>
                  <FmTextField
                    name='remarks'
                    control={control}
                    label='Remarks'
                    multiline={true}
                    rows={2}
                    maxRows={3}
                  />
                </Grid>
              </Grid>
              {/* <Grid container spacing={3} className='mt-1'>
                <Grid item md={10}>
                  <Box className='flex flex-wrap gap-2 items-center'>
                    {/* //TODO: for Feature User */}
              {/* <Box className='font-bold me-3'>
                    Additional Images{' '}
                    <span>
                      {fileCount} of {attachments?.length}
                    </span>
                  </Box> 
                    {attachments?.map((_file, index) => (
                      <FmFileInput
                        name='fileId'
                        documentName={fileInputs[index].fileName}
                        onChange={(e) => handleFileUpload(e, index)}
                        loading={uploadLoading[index]}
                      />
                    ))}
                  </Box>
                </Grid>
              </Grid> */}
              <ActionButtons
                onSubmit={handleSubmit(handleUpdateAccommodation)}
                onCancel={() => navigate(-1)}
                submitText='Update'
                cancelText='Go Back'
                onReset={() => {
                  reset(); // Reset the form fields
                  setFileInputs(
                    attachments?.map((attachment) => ({
                      id: attachment?.id,
                      file: null,
                      fileName: '',
                      fileId: null,
                    }))
                  );
                }}
                submitLoading={editLoading}
                cancelLoading={false}
              />
            </LocalizationProvider>
          </form>
        )}
      </GlassCard>
    </DashboardLayout>
  );
};

export default EditAccommodation;
