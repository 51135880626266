import { Icon } from '@iconify/react';

const Icons = {
  bell: <Icon icon='ic:outline-notifications-active' />,
  history: <Icon icon='ri:chat-history-line' />,
  search: <Icon icon='mdi:magnify' />,
  profile: <Icon icon='fluent:people-search-24-regular' />,
  settings: <Icon icon='fluent:settings-20-regular' />,
  logout: <Icon icon='fluent:arrow-exit-20-regular' />,
  moon: <Icon icon='ri:moon-foggy-line' />,
  sun: <Icon icon='material-symbols:sunny-outline' />,
  powerOff: <Icon icon='solar:power-bold' />,
  menu: <Icon icon='mynaui:text-align-left' />,
  menuClose: <Icon icon='mynaui:text-align-right' />,
  arrow: <Icon icon='material-symbols:keyboard-arrow-down' />,
  arrowUp: <Icon icon='material-symbols:keyboard-arrow-up' />,
  hrms: <Icon icon='solar:accessibility-broken' />,
  apps: <Icon icon='fluent:tab-desktop-search-16-regular' />,
  language: <Icon icon='ri:global-line' />,
  english: <Icon icon='twemoji:flag-england' />,
  arabic: <Icon icon='twemoji:flag-united-arab-emirates' />,
  france: <Icon icon='twemoji:flag-france' />,
  mails: <Icon icon='lucide:mails' />,
  grid: <Icon icon='mdi:apps' />,
  save: <Icon icon='cil:save' />,
  edit: <Icon icon='fluent:edit-line-horizontal-3-24-regular' />,
  delete: <Icon icon='fluent:delete-24-regular' />,
  clear: <Icon icon='codicon:chrome-close' />,
  view: <Icon icon='fluent:eye-24-regular' />,
  impersonator: <Icon icon='fluent:person-tentative-20-regular' />,
  download: <Icon icon='fluent:arrow-download-24-regular' />,
  exit: <Icon icon='fluent:preview-link-16-regular' />,
  bank: <Icon icon='fluent:building-bank-16-filled' />,
  plus: <Icon icon='material-symbols-light:add' width='24' height='24' />,
  company: <Icon icon='fluent:building-32-regular' />,
  cheque: <Icon icon='fluent:document-signature-20-regular' />,
  cash: <Icon icon='ion:cash-outline' />,
  card: <Icon icon='famicons:card-outline' />,
  date: <Icon icon='healthicons:calendar' />,
  blood: (
    <Icon icon='healthicons:ui-folder-family-outline' width={20} height={20} />
  ),
  mobile: <Icon icon='cil:mobile' width='15' height='15' />,
  email: <Icon icon='iconamoon:email-duotone' width='15' height='15' />,
  project: <Icon icon='eos-icons:project-outlined' width='24' height='24' />,
  checklist: (
    <Icon
      icon='fluent:document-table-checkmark-24-regular'
      width='24'
      height='24'
    />
  ),
  info: <Icon icon='si:info-duotone' width='20' height='20' />,
  comments: (
    <Icon
      icon='material-symbols-light:comment-outline-sharp'
      width='20'
      height='20'
    />
  ),
  send: <Icon icon='teenyicons:send-outline' width='20' height='20' />,
  passport: <Icon icon='bi:passport' width='20' height='20' />,
  location: <Icon icon='akar-icons:location' width='20' height='20' />,
  male: <Icon icon='fontisto:male' width='11' height='11' />,
  female: <Icon icon='fontisto:famale' width='11' height='11' />,
  leaveApproval: <Icon icon='fluent:checkmark-circle-32-regular' />,
  leaveJoining: <Icon icon='fluent:add-20-regular' />,
};

export default Icons;
