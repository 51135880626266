import React from 'react';
import {
  MaterialReactTable,
  MRT_RowSelectionState,
} from 'material-react-table';
import MRTExpandTableStyle from './table/MRTExpandTableStyle.tsx';
import { Box, Button, Tooltip, IconButton } from '@mui/material';
import TableSearch from './_form/TableSearch.jsx';
import { Icon } from '@iconify/react/dist/iconify.js';
import FmViewDateField from './_mui/FmViewDateField.tsx';
import ConfirmationPopoverBox from './_form/ConfirmationPopoverBox.tsx';
import NoDataAvailable from './elements/NoDataAvailable.tsx';

interface FmMRTDataTableProps {
  rows: any[];
  columns: any[];
  isLoading?: boolean;
  rowCount?: number;
  columnFilters?: any;
  globalFilter?: any;
  editingRow?: any;
  handlePaginationModelChange?: any;
  handleSortModelChange?: any;
  tableRecordCounts?: number;
  pageSize?: number;
  pageIndex?: number;
  editingRowId?: string | null;
  setPagination?: any;
  pagination?: any;
  setSorting: any;
  sorting: any;
  disableNewButton?: boolean;
  setSearchKeyword?: (value: any) => void;
  searchKeyword?: string | number;
  onCreateRow?: () => void | null;
  isCreatingRow?: boolean;
  enableRowNumbers: boolean;
  muiTableContainerProps?: any;
  columnPining?: any;
  fetchData?: () => void;
  setRowSelected?: (value: MRT_RowSelectionState) => void;
  enableRowSelection?: boolean;
  renderTopToolbarCustomActionsChildrens?: any;
  delectCheckBoxClicked?: () => void;
  rowSelection?: MRT_RowSelectionState;
  setRowSelection?: (value: MRT_RowSelectionState) => void;
  handleRowDoubleClick?: (row: any) => void;
  handleExport?: () => void;
  handleImport?: () => void;
  buttonName?: string;
  buttonColor?: string;
  showPagination?: boolean;
  enableBottomToolbar?: boolean;
  handlePrint?: () => void;
  enableStickyFooter?: boolean;
  muiTableFooterCellProps?: any;
  enableRowVirtualization?: boolean;
  rightPinning?: any[];
  showSerialNumber?: boolean;
  leftPinning?: any[];
  enableColumnPinning?: boolean;
  setGlobalFilter?: any;
  onColumnFiltersChange?: any;
  enableFilters?: boolean;
  positionToolbarAlertBanner?: string;
  rowSelectionCustomProps?: any;
  muiSelectCheckboxProps?: any;
  enableSelectAll?: boolean;
  renderTopToolbarLeftChildrens?: any;
  enableGrouping?: boolean;
  extraInitialState?: any;
  enableExpandAll?: boolean;
  setTableInstance?: any;
  nakedTable?: boolean;
}

const FmMRTDataTable: React.FC<FmMRTDataTableProps> = React.memo((props) => {
  const {
    columns,
    rows,
    rowCount,
    isLoading,
    pagination,
    sorting,
    columnFilters,
    globalFilter,
    setGlobalFilter,
    setPagination,
    rightPinning,
    leftPinning,
    setSorting,
    editingRow,
    searchKeyword,
    setSearchKeyword,
    onCreateRow,
    isCreatingRow,
    disableNewButton = true,
    muiTableContainerProps,
    enableRowNumbers = false,
    columnPining = {
      left: ['Actions', ...(leftPinning ?? [])],
      right: ['Action', ...(rightPinning ?? [])],
    },
    enableRowSelection = false,
    muiTableFooterCellProps,
    fetchData,
    setRowSelection,
    rowSelection = {},
    renderTopToolbarCustomActionsChildrens,
    handlePrint,
    delectCheckBoxClicked,
    handleRowDoubleClick,
    handleExport,
    handleImport,
    enableBottomToolbar = true,
    buttonName,
    showPagination = true,
    buttonColor,
    enableStickyFooter = false,
    enableRowVirtualization = false,
    showSerialNumber,
    enableColumnPinning = true,
    onColumnFiltersChange,
    enableFilters = false,
    positionToolbarAlertBanner,
    rowSelectionCustomProps,
    muiSelectCheckboxProps,
    enableSelectAll = false,
    renderTopToolbarLeftChildrens,
    enableGrouping,
    extraInitialState,
    enableExpandAll,
    setTableInstance,
    nakedTable = false,
  } = props;

  const processedColumns = React.useMemo(() => {
    let cols = [...columns];

    if (showSerialNumber) {
      cols = [
        {
          accessorKey: 'serialNumber',
          header: 'S.No',
          size: 60,
          enableSorting: false,
          Cell: ({ row }) => {
            const pageIndex = pagination?.pageIndex || 0;
            const pageSize = pagination?.pageSize || 10;
            return (
              <Box sx={{ textAlign: 'center' }}>
                {pageIndex * pageSize + row.index + 1}
              </Box>
            );
          },
        },
        ...cols,
      ];
    }

    return cols.map((column) => {
      if (
        column.accessorKey?.toLowerCase().includes('date') &&
        !column.enableEditing &&
        !column.customDate
      ) {
        return {
          ...column,
          Cell: ({ row }) => (
            <FmViewDateField
              value={row.original[column.accessorKey]}
              tableFontWeightRemove={true}
            />
          ),
        };
      }
      return column;
    });
  }, [columns, showSerialNumber, pagination?.pageIndex, pagination?.pageSize]);

  return (
    <MRTExpandTableStyle enableFullHeight nakedTable={nakedTable}>
      <Box>
        <MaterialReactTable
          columns={processedColumns}
          data={rows}
          manualPagination
          manualSorting={true}
          rowCount={rowCount}
          onColumnFiltersChange={onColumnFiltersChange}
          editingRow={editingRow}
          enableStickyHeader={true}
          enableRowNumbers={enableRowNumbers}
          onPaginationChange={setPagination}
          onSortingChange={setSorting}
          enableGlobalFilter={false}
          enableGrouping={enableGrouping}
          state={{
            columnFilters,
            globalFilter,
            isLoading,
            pagination,
            sorting,
            rowSelection,
          }}
          enableRowSelection={enableRowSelection}
          enableRowVirtualization={enableRowVirtualization}
          onRowSelectionChange={setRowSelection}
          enableColumnActions={false}
          enableKeyboardShortcut={true}
          isLoading={isLoading}
          manualFiltering={true}
          enableFilters={enableFilters}
          enablePagination={showPagination}
          enableBottomToolbar={enableBottomToolbar}
          enableDensityToggle={false}
          initialState={{
            columnPinning: columnPining,
            ...extraInitialState,
          }}
          enableSelectAll={enableSelectAll}
          groupedColumnMode={false}
          muiToolbarAlertBannerProps={{
            sx: {
              display: enableGrouping && 'none',
            },
          }}
          enableStickyFooter={enableStickyFooter}
          muiTableBodyRowProps={({ row }) => ({
            onDoubleClick: () => {
              handleRowDoubleClick && handleRowDoubleClick(row);
            },
          })}
          muiTableContainerProps={muiTableContainerProps}
          muiSelectCheckboxProps={muiSelectCheckboxProps}
          enableColumnPinning={enableColumnPinning}
          renderTopToolbarCustomActions={({ table }) => (
            <>
              <Box className='flex flex-1 items-center justify-between gap-2'>
                <Box className='flex gap-1 items-center'>
                  {rowSelection && Object.keys(rowSelection).length > 0 && (
                    <>
                      {!rowSelectionCustomProps ? (
                        <Box
                          sx={{
                            maxWidth: '40px',
                            fontSize: '25px',
                          }}
                        >
                          <ConfirmationPopoverBox
                            title='Delete'
                            icon={<Icon icon='gridicons:trash' />}
                            onConfirm={delectCheckBoxClicked}
                          />
                        </Box>
                      ) : (
                        <Box className='flex items-center gap-1'>
                          {rowSelectionCustomProps}
                        </Box>
                      )}
                    </>
                  )}

                  <Box>
                    <TableSearch
                      placeholder='Search'
                      fullWidth
                      setSearchKeyword={setSearchKeyword}
                      searchValue={searchKeyword}
                    />
                  </Box>
                  {renderTopToolbarLeftChildrens}
                </Box>

                {enableGrouping && setTableInstance(table)}

                <Box className='flex items-center gap-2'>
                  {renderTopToolbarCustomActionsChildrens}
                  {handlePrint && (
                    <IconButton onClick={handlePrint} sx={{ fontSize: '22px' }}>
                      <Icon icon='ic:baseline-print' />
                    </IconButton>
                  )}
                  {onCreateRow && disableNewButton && (
                    <Button
                      onClick={() => onCreateRow(table)}
                      variant='contained'
                      color={buttonColor || 'secondary'}
                      sx={{ ml: 1 }}
                      disabled={isCreatingRow}
                      className='py-0.5 px-3'
                      startIcon={<Icon icon='ic:baseline-add' />}
                      id='new-buttons'
                    >
                      {buttonName || 'New'}
                    </Button>
                  )}

                  <Tooltip title='Refresh'>
                    <IconButton sx={{ fontSize: '22px' }} onClick={fetchData}>
                      <Icon icon='ic:baseline-refresh' />
                    </IconButton>
                  </Tooltip>

                  {handleExport && (
                    <Tooltip title='Export'>
                      <IconButton
                        sx={{ fontSize: '22px' }}
                        onClick={handleExport}
                      >
                        <Icon icon='material-symbols:download' />
                      </IconButton>
                    </Tooltip>
                  )}
                  {handleImport && (
                    <Tooltip title='Import'>
                      <IconButton
                        sx={{ fontSize: '22px' }}
                        onClick={handleImport}
                      >
                        <Icon icon='material-symbols:upload' />
                      </IconButton>
                    </Tooltip>
                  )}
                </Box>
              </Box>
            </>
          )}
          muiTableFooterCellProps={muiTableFooterCellProps}
          renderEmptyRowsFallback={() => <NoDataAvailable />}
          positionToolbarAlertBanner={positionToolbarAlertBanner}
          muiExpandButtonProps={({ row, table }) => ({
            onClick: () =>
              table.setExpanded({ [row.id]: !row.getIsExpanded() }),
          })}
          muiCircularProgressProps={{
            sx: {
              display: 'none',
            },
          }}
          enableExpandAll={enableExpandAll}
          displayColumnDefOptions={{
            'mrt-row-expand': {
              size: 60,
              maxSize: 60,
            },
          }}
        />
      </Box>
    </MRTExpandTableStyle>
  );
});

export default FmMRTDataTable;
