import React, { useEffect, useState } from 'react';
import { Box } from '@mui/material';
import CommonLoader from '../page/CommonLoader';
import Crud_Service from '../../apis/CrudService';
import { Typography, Paper } from '@mui/material';
import { toast } from 'react-toastify';
const CommonView = ({
  url,
  id = 0,
  renderFields,
  excludeKeys = [],
  viewData = false,
}) => {
  const crud = new Crud_Service();
  const [loading, setLoading] = useState(false);
  const [data, setData] = useState({});

  useEffect(() => {
    fetchData();
  }, [id]);

  const fetchData = async () => {
    setLoading(true);
    await crud.getSingle(url, id, (_err, res) => {
      if (res?.status === 200) {
        setLoading(false);
        setData(res?.data);
        viewData && viewData(res?.data);
      } else {
        setLoading(false);
        toast.error('Something went wrong!', res);
      }
    });
  };

  const generateFields = (data) => {
    return Object.keys(data)
      .filter((key) => !excludeKeys.includes(key))
      .map((key) => ({
        key: key,
        label: key
          .replace(/([A-Z])/g, ' $1')
          .replace(/^./, (str) => str.toUpperCase()),
      }));
  };

  return (
    <Box>
      {loading ? <CommonLoader /> : renderFields(data, generateFields(data))}
      {id === null && (
        <Paper elevation={3}>
          <Box
            display='flex'
            justifyContent='center'
            alignItems='center'
            height={200}
            padding={2}
            bgcolor='grey.100'
          >
            <Typography variant='h6' color='textSecondary'>
              No Record Found
            </Typography>
          </Box>
        </Paper>
      )}
    </Box>
  );
};

export default CommonView;
