import React, { useEffect, useState } from 'react';
import { Icon } from '@iconify/react';
import GlassCard from '../../../../components/small/GlassCard.tsx';
import Crud_Service from '../../../../apis/CrudService.jsx';
import { toast } from 'react-toastify';
import useTableLogic from '../../../../components/helpers/MRTUseTableLogic.tsx';
import FmMRTDataTable from '../../../../components/FmMRTDataTable.tsx';
import { useNavigate, useParams } from 'react-router-dom';
import { Controller, useForm } from 'react-hook-form';
import {
  Box,
  Button,
  FormControl,
  FormControlLabel,
  Radio,
  RadioGroup,
} from '@mui/material';
import TableActionButtons from '../../../../components/_form/TableActionButtons.jsx';
import FmTextField from '../../../../components/_mui/FmTextField.tsx';
import { yupResolver } from '@hookform/resolvers/yup';
import { monthsupdateschema } from '../../../../utils/CommonvalidationSchemas.tsx';
import CommonView from '../../../../components/helpers/CommonView.jsx';
import DynamicViewFields from '../../../../components/helpers/DynamicViewFields.jsx';
import MuiDialogOne from '../../../../components/_mui/MuiDialogOne.jsx';
import FmSearchableSelect from '../../../../components/_mui/FmSearchableSelect.tsx';
import DashboardLayout from '../../../../components/DashboardLayout.tsx';
import Nav from '../../../../utils/Nav.tsx';
import FmDatePicker from '../../../../components/_mui/FmDatePicker.tsx';
import { DatePicker, LocalizationProvider } from '@mui/x-date-pickers';
import { AdapterDayjs } from '@mui/x-date-pickers/AdapterDayjs';
import FmTimePicker from '../../../../components/_mui/FmTimePicker.tsx';
import dayjs from 'dayjs';
import { CreateButton } from '../../../../components/small/Buttons.jsx';

const MonthUpdate = () => {
  const crud = new Crud_Service();
  const { id } = useParams();
  const [editingRowData, setEditingRowData] = useState(null);
  const [rowState, setRowState] = useState({
    newRowId: null,
    isCreatingRow: false,
  });
  const { handleSubmit, reset, setValue, control, watch } = useForm({
    resolver: yupResolver(monthsupdateschema),
    mode: 'onChange',
    defaultValues: {
      year: editingRowData?.year
        ? dayjs(editingRowData.year).format('YYYY')
        : null,
    },
  });
  const navigate = useNavigate();
  const [editingRowId, setEditingRowId] = useState(null);
  const [viewdetails, setViewdetails] = useState(null);
  const [monthId, setMonthId] = useState(dayjs().month() + 1);
  const [yearId, setYearId] = useState(dayjs().year());

  const {
    rows,
    setRows,
    isLoading,
    pagination,
    sorting,
    columnFilters,
    globalFilter,
    tableRecordCounts,
    setSearchKeyword,
    searchKeyword,
    setPagination,
    setSorting,
    setColumnFilters,
    setGlobalFilter,
    fetchData,
  } = useTableLogic(`vehiclemonthlyupdates?year=${yearId}&month=${monthId}`);

  const handleDelete = async (props) => {
    await crud.remove(
      'vehiclemonthlyupdates',
      props?.vehicleMonthlyUpdateId,
      (_err, res) => {
        if (res?.status === 204) {
          toast.success('MonthlyUpdate Item Deleted Successfully');
          fetchData();
        } else {
        }
      }
    );
  };

  const handleViewClick = (props) => {
    navigate(`view/${props?.vehicleMonthlyUpdateId}`);
  };

  const handleEdit = async (row) => {
    navigate(`edit/${row?.vehicleMonthlyUpdateId}`);
  };

  const ActionData = [
    {
      name: 'View Details',
      icon: <Icon icon='solar:eye-linear' />,
      onClick: (props) => handleViewClick(props),
    },
    {
      name: 'Edit',
      icon: <Icon icon='solar:pen-2-broken' />,
      onClick: (props) => handleEdit(props),
    },
    {
      name: 'Delete',
      icon: <Icon icon='mi:delete' />,
      onClick: (props) => handleDelete(props),
      danger: true,
    },
  ];

  const handleCancel = (row) => {
    if (row?.original?.vehicleMonthlyUpdateId === rowState?.newRowId) {
      setRows((prevRows) =>
        prevRows.filter((r) => r?.vehicleMonthlyUpdateId !== rowState?.newRowId)
      );
      setRowState((prevState) => ({
        ...prevState,
        newRowId: null,
        isCreatingRow: false,
      }));
    } else {
      setEditingRowId(null);
    }
    reset();
  };

  const handleUpdateMaterialrequestItems = async (values) => {
    const combinedData = {
      ...values,
      ownVehicleId: values?.ownVehicleId || null,
      hiredVehicleId: values?.hiredVehicleId || null,
      contractStartingPeriod: dayjs(values?.contractStartingPeriod).format(
        'YYYY-MM-DD'
      ),
      contractEndingPeriod: dayjs(values?.contractEndingPeriod).format(
        'YYYY-MM-DD'
      ),
    };

    const updateCombinedData = {
      ...values,
      ownVehicleId: values?.ownVehicleId || null,
      hiredVehicleId: values?.hiredVehicleId || null,
      contractStartingPeriod: dayjs(values?.contractStartingPeriod).format(
        'YYYY-MM-DD'
      ),
      contractEndingPeriod: dayjs(values?.contractEndingPeriod).format(
        'YYYY-MM-DD'
      ),
      status: 2,
    };
    if (rowState?.newRowId) {
      await crud.create('vehiclemonthlyupdates', combinedData, (_err, res) => {
        if (res?.status === 201) {
          toast.success('MonthlyUpdate Item Created Successfully');
          fetchData();
          setRowState((prevState) => ({
            ...prevState,
            newRowId: null,
            isCreatingRow: false,
          }));
        } else {
        }
      });
    } else {
      await crud.update(
        'vehiclemonthlyupdates',
        editingRowId,
        updateCombinedData,
        (_err, res) => {
          if (res?.status === 200) {
            toast.success('MonthlyUpdate Item Updated Successfully');
            fetchData();
            setEditingRowData(null);
            setEditingRowId(null);
          } else {
          }
        }
      );
    }
  };

  useEffect(() => {
    if (monthId && yearId) {
      fetchData();
    }
  }, [monthId, yearId]);

  const vehicleMethodChange = watch('vehicleMethod') === 'true' ? true : false;
  const columns = [
    {
      header: 'Actions',
      enableColumnPinning: true,
      enableEditing: false,
      enableSorting: false,
      Cell: ({ row }) => {
        const isNewRow =
          row?.original?.vehicleMonthlyUpdateId === rowState?.newRowId;
        const isEditing =
          editingRowId === row?.original?.vehicleMonthlyUpdateId || isNewRow;

        return (
          <Box className='flex gap-2'>
            {isEditing ? (
              <>
                <Button
                  onClick={handleSubmit(handleUpdateMaterialrequestItems)}
                >
                  Save
                </Button>
                <Button onClick={() => handleCancel(row)}>Cancel</Button>
              </>
            ) : (
              <TableActionButtons
                Actions={ActionData?.map((action) => ({
                  ...action,
                  onClick: () => action.onClick(row?.original),
                }))}
              />
            )}
          </Box>
        );
      },
    },
    {
      accessorKey: 'divisionId',
      header: 'Division',
      enableEditing: true,
      Cell: ({ row }) => {
        const isNewRow =
          row?.original?.vehicleMonthlyUpdateId === rowState?.newRowId;
        const isEditing =
          editingRowId === row?.original?.vehicleMonthlyUpdateId || isNewRow;
        return isEditing ? (
          <FmSearchableSelect
            name='divisionId'
            control={control}
            apiUrl='divisions'
            valueField='divisionId'
            labelField={['divisionName']}
            showField={['divisionName']}
            label='Division'
            defaultValue={{
              divisionId: row?.original?.divisionId || 0,
              divisionName: row?.original?.divisionName || '',
            }}
          />
        ) : (
          row?.original?.divisionId || ''
        );
      },
    },
    {
      accessorKey: 'vehicleMethod',
      header: 'Vehicle Method',
      enableEditing: true,
      Cell: ({ row }) => {
        const isNewRow =
          row?.original?.vehicleMonthlyUpdateId === rowState?.newRowId;
        const isEditing =
          editingRowId === row?.original?.vehicleMonthlyUpdateId || isNewRow;
        return isEditing ? (
          <FormControl component='fieldset'>
            <Controller
              name='vehicleMethod'
              control={control}
              defaultValue={true}
              rules={{ required: true }}
              render={({ field }) => (
                <RadioGroup {...field} sx={{ flexDirection: 'row' }}>
                  <FormControlLabel
                    value={true}
                    control={<Radio />}
                    label='Own Vehicle'
                  />
                  <FormControlLabel
                    value={false}
                    control={<Radio />}
                    label='Hired Vehicle'
                  />
                </RadioGroup>
              )}
            />
          </FormControl>
        ) : row?.original?.vehicleMethod ? (
          'Own Vehicle'
        ) : (
          'Hired Vehicle'
        );
      },
    },
    {
      accessorKey: 'ownVehicleId',
      header: 'Vehicle',
      enableEditing: true,
      Cell: ({ row }) => {
        const isNewRow =
          row?.original?.vehicleMonthlyUpdateId === rowState?.newRowId;
        const isEditing =
          editingRowId === row?.original?.vehicleMonthlyUpdateId || isNewRow;

        return isEditing ? (
          vehicleMethodChange === true ? (
            <FmSearchableSelect
              name='ownVehicleId'
              control={control}
              apiUrl='ownvehicles'
              valueField='ownVehicleId'
              labelField={['vehicleName']}
              showField={['vehicleName']}
              label='Own Vehicle'
              defaultValue={{
                ownVehicleId: row?.original?.ownVehicleId || 0,
                vehicleName: row?.original?.ownVehicleTypeName || '',
              }}
            />
          ) : (
            <FmSearchableSelect
              name='hiredVehicleId'
              control={control}
              apiUrl='hiredVehicles'
              valueField='hiredVehicleId'
              labelField={['hiredVehicleCode']}
              showField={['hiredVehicleCode']}
              label='Hired Vehicle'
              defaultValue={{
                hiredVehicleId: row?.original?.hiredVehicleId || 0,
                hiredVehicleCode: row?.original?.hiredVehicleTypeName || '',
              }}
            />
          )
        ) : (
          row?.original?.vehicleName || ''
        );
      },
    },
    {
      accessorKey: 'month',
      header: 'Month',
      enableEditing: true,
      Cell: ({ row }) => {
        const isNewRow =
          row?.original?.vehicleMonthlyUpdateId === rowState?.newRowId;
        const isEditing =
          editingRowId === row?.original?.vehicleMonthlyUpdateId || isNewRow;
        return isEditing ? (
          <FmSearchableSelect
            name='month'
            control={control}
            apiUrl='months'
            valueField='monthId'
            labelField={['monthName']}
            showField={['monthName']}
            label='Month'
            defaultValue={{
              month: row?.original?.month || 0,
              monthName: row?.original?.monthName || '',
            }}
          />
        ) : (
          row?.original?.month || ''
        );
      },
    },
    {
      accessorKey: 'year',
      header: 'Year',
      enableEditing: true,
      Cell: ({ row }) => {
        const isNewRow =
          row?.original?.vehicleMonthlyUpdateId === rowState?.newRowId;
        const isEditing =
          editingRowId === row?.original?.vehicleMonthlyUpdateId || isNewRow;
        return isEditing ? (
          <LocalizationProvider dateAdapter={AdapterDayjs}>
            <Controller
              name='year'
              control={control}
              render={({
                field: { onChange, value },
                fieldState: { error },
              }) => (
                <DatePicker
                  label='Year'
                  views={['year']}
                  value={value ? dayjs().set('year', value) : null}
                  onChange={(newValue) => {
                    const year = newValue ? dayjs(newValue).year() : null;
                    onChange(year);
                  }}
                  slotProps={{
                    textField: {
                      fullWidth: true,
                      error: !!error,
                      helperText: error ? error.message : null,
                      required: true,
                    },
                  }}
                />
              )}
            />
          </LocalizationProvider>
        ) : (
          row?.original?.year || ''
        );
      },
    },
    {
      accessorKey: 'locationId',
      header: 'Location',
      enableEditing: true,
      Cell: ({ row }) => {
        const isNewRow =
          row?.original?.vehicleMonthlyUpdateId === rowState?.newRowId;
        const isEditing =
          editingRowId === row?.original?.vehicleMonthlyUpdateId || isNewRow;
        return isEditing ? (
          <FmSearchableSelect
            name='locationId'
            control={control}
            apiUrl='locations'
            valueField='locationId'
            labelField={['locationName']}
            showField={['locationName']}
            label='Location'
            defaultValue={{
              locationId: row?.original?.locationId || 0,
              locationName: row?.original?.locationName || '',
            }}
          />
        ) : (
          row?.original?.locationName || ''
        );
      },
    },
    {
      accessorKey: 'projectName',
      header: 'Project',
      enableEditing: true,
      Cell: ({ row }) => {
        const isNewRow =
          row?.original?.vehicleMonthlyUpdateId === rowState?.newRowId;
        const isEditing =
          editingRowId === row?.original?.vehicleMonthlyUpdateId || isNewRow;
        return isEditing ? (
          <FmSearchableSelect
            name='projectId'
            control={control}
            apiUrl='projects'
            valueField='projectId'
            labelField={['projectName']}
            showField={['projectName']}
            label='Project'
            defaultValue={{
              projectId: row?.original?.projectId || 0,
              projectName: row?.original?.projectName || '',
            }}
          />
        ) : (
          row?.original?.projectName || ''
        );
      },
    },
    {
      accessorKey: 'siteId',
      header: 'Site',
      enableEditing: true,
      Cell: ({ row }) => {
        const isNewRow =
          row?.original?.vehicleMonthlyUpdateId === rowState?.newRowId;
        const isEditing =
          editingRowId === row?.original?.vehicleMonthlyUpdateId || isNewRow;
        return isEditing ? (
          <FmSearchableSelect
            name='siteId'
            control={control}
            apiUrl='buildings'
            valueField='buildingId'
            labelField={['buildingName']}
            showField={['buildingName']}
            label='Site'
            defaultValue={{
              siteId: row?.original?.siteId || 0,
              buildingName: row?.original?.siteName || '',
            }}
          />
        ) : (
          row?.original?.siteId || ''
        );
      },
    },
    {
      accessorKey: 'currentKilometer',
      header: 'Current Kilometer',
      enableEditing: true,
      Cell: ({ row }) => {
        const isNewRow =
          row?.original?.vehicleMonthlyUpdateId === rowState?.newRowId;
        const isEditing =
          editingRowId === row?.original?.vehicleMonthlyUpdateId || isNewRow;
        return isEditing ? (
          <FmTextField
            name='currentKilometer'
            label='Current Kilometer'
            control={control}
          />
        ) : (
          row?.original?.currentKilometer || ''
        );
      },
    },
    {
      accessorKey: 'pickUpTime',
      header: 'Pick-Up Time',
      enableEditing: true,
      Cell: ({ row }) => {
        const isNewRow =
          row?.original?.vehicleMonthlyUpdateId === rowState?.newRowId;
        const isEditing =
          editingRowId === row?.original?.vehicleMonthlyUpdateId || isNewRow;
        return isEditing ? (
          <FmTimePicker
            name='pickUpTime'
            label='Pick-Up Time'
            control={control}
            required
          />
        ) : (
          row?.original?.pickUpTime || ''
        );
      },
    },
    {
      accessorKey: 'dropoffTime',
      header: 'Drop-Off Time',
      enableEditing: true,
      Cell: ({ row }) => {
        const isNewRow =
          row?.original?.vehicleMonthlyUpdateId === rowState?.newRowId;
        const isEditing =
          editingRowId === row?.original?.vehicleMonthlyUpdateId || isNewRow;
        return isEditing ? (
          <FmTimePicker
            name='dropoffTime'
            label='Drop-Off Time'
            control={control}
            required
          />
        ) : (
          row?.original?.dropoffTime || ''
        );
      },
    },
    {
      accessorKey: 'transporterName',
      header: 'Transporter Name',
      enableEditing: true,
      Cell: ({ row }) => {
        const isNewRow =
          row?.original?.vehicleMonthlyUpdateId === rowState?.newRowId;
        const isEditing =
          editingRowId === row?.original?.vehicleMonthlyUpdateId || isNewRow;
        return isEditing ? (
          <FmTextField
            name='transporterName'
            label='Transporter Name'
            control={control}
          />
        ) : (
          row?.original?.transporterName || ''
        );
      },
    },
    {
      accessorKey: 'contractStartingPeriod',
      header: 'Contract Starting Period',
      enableEditing: true,
      Cell: ({ row }) => {
        const isNewRow =
          row?.original?.vehicleMonthlyUpdateId === rowState?.newRowId;
        const isEditing =
          editingRowId === row?.original?.vehicleMonthlyUpdateId || isNewRow;
        return isEditing ? (
          <FmDatePicker
            name='contractStartingPeriod'
            label='Contract Starting Period'
            control={control}
          />
        ) : (
          row?.original?.contractStartingPeriod || ''
        );
      },
    },
    {
      accessorKey: 'contractEndingPeriod',
      header: 'Contract Ending Period',
      enableEditing: true,
      Cell: ({ row }) => {
        const isNewRow =
          row?.original?.vehicleMonthlyUpdateId === rowState?.newRowId;
        const isEditing =
          editingRowId === row?.original?.vehicleMonthlyUpdateId || isNewRow;
        return isEditing ? (
          <FmDatePicker
            name='contractEndingPeriod'
            label='Contract Ending Period'
            control={control}
          />
        ) : (
          row?.original?.contractEndingPeriod || ''
        );
      },
    },
    {
      accessorKey: 'rentAmount',
      header: 'Rent Amount',
      enableEditing: true,
      Cell: ({ row }) => {
        const isNewRow =
          row?.original?.vehicleMonthlyUpdateId === rowState?.newRowId;
        const isEditing =
          editingRowId === row?.original?.vehicleMonthlyUpdateId || isNewRow;
        return isEditing ? (
          <FmTextField
            name='rentAmount'
            pattern='Number'
            label='Rent Amount'
            control={control}
          />
        ) : (
          row?.original?.rentAmount || ''
        );
      },
    },
    {
      accessorKey: 'contactPerson',
      header: 'Contact Person',
      enableEditing: true,
      Cell: ({ row }) => {
        const isNewRow =
          row?.original?.vehicleMonthlyUpdateId === rowState?.newRowId;
        const isEditing =
          editingRowId === row?.original?.vehicleMonthlyUpdateId || isNewRow;
        return isEditing ? (
          <FmSearchableSelect
            name='contactPerson'
            control={control}
            apiUrl='loginusers'
            valueField='loginUserId'
            headerField={['userName']}
            labelField={['loginUserName']}
            showField={['loginUserName']}
            label='Contact Person'
            defaultValue={{
              contactPerson: row?.original?.contactPerson || 0,
              loginUserName: row?.original?.contactPersonName || '',
            }}
          />
        ) : (
          row?.original?.contactPerson || ''
        );
      },
    },
    {
      accessorKey: 'remarks',
      header: 'Remarks',
      enableEditing: true,
      Cell: ({ row }) => {
        const isNewRow =
          row?.original?.vehicleMonthlyUpdateId === rowState?.newRowId;
        const isEditing =
          editingRowId === row?.original?.vehicleMonthlyUpdateId || isNewRow;
        return isEditing ? (
          <FmTextField
            name='remarks'
            label='Remarks'
            control={control}
            multiline
            rows={2}
            maxRows={3}
          />
        ) : (
          row?.original?.remarks || ''
        );
      },
    },
  ];

  const handleAddNewRow = () => {
    const newId = 'new_row_' + Date.now();
    setRowState({
      newRowId: newId,
      isCreatingRow: true,
    });
    setRows((prevRows) => [
      ...prevRows,
      'divisionName',
      'vehicleName',
      'vehicleNumber',
      'driverName',
    ]);
    reset();
  };

  const excludeKeys = [
    'divisionName',
    'vehicleName',
    'vehicleNumber',
    'driverName',
    'months',
    'year',
  ];

  return (
    <>
      <DashboardLayout
        title='Monthly Update'
        hasSubmenu
        actionButtons={
          <CreateButton name='Create' onClick={() => navigate('create')} />
        }
        menu={Nav[10].child[7].children}
      >
        <GlassCard className='p-3 px-4 py-3 gap-3 font-semibold bg-blue-50 '>
          <Box className='flex justify-end'>
            <LocalizationProvider dateAdapter={AdapterDayjs}>
              <DatePicker
                label='Filter By Month and Year'
                views={['month', 'year']}
                value={dayjs(`${yearId}-${monthId}`, 'YYYY-M')}
                onChange={(newValue) => {
                  const month = newValue ? dayjs(newValue).month() + 1 : null;
                  const year = newValue ? dayjs(newValue).year() : null; // Extracting only the year
                  setYearId(year);
                  setMonthId(month);
                }}
              />
            </LocalizationProvider>
          </Box>
        </GlassCard>

        <GlassCard>
          <FmMRTDataTable
            columns={columns}
            enableRowSelection={false}
            rows={rows}
            // onCreateRow={handleAddNewRow}
            isCreatingRow={rowState?.isCreatingRow}
            rowCount={tableRecordCounts}
            editingRow={editingRowId}
            pagination={pagination}
            setSearchKeyword={setSearchKeyword}
            sorting={sorting}
            fetchData={fetchData}
            columnFilters={columnFilters}
            globalFilter={globalFilter}
            setPagination={setPagination}
            setSorting={setSorting}
            setColumnFilters={setColumnFilters}
            setGlobalFilter={setGlobalFilter}
            isLoading={isLoading}
            searchKeyword={searchKeyword}
            handleRowDoubleClick={(e) => handleEdit(e?.original)}
          />
          <MuiDialogOne
            title='View Details'
            open={viewdetails}
            onClose={() => setViewdetails(!viewdetails)}
          >
            {viewdetails && viewdetails?.vehicleMonthlyUpdateId && (
              <CommonView
                url=''
                id={viewdetails?.vehicleMonthlyUpdateId}
                excludeKeys={excludeKeys}
                renderFields={(data, fields) => (
                  <DynamicViewFields data={data} fields={fields} />
                )}
              />
            )}
          </MuiDialogOne>
        </GlassCard>
      </DashboardLayout>
    </>
  );
};

export default MonthUpdate;
