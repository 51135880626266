import React, { useEffect, useState } from 'react';
import GlassCard from '../../../../components/small/GlassCard.tsx';
import Icons from '../../../../utils/Icon.jsx';
import { Box, Button } from '@mui/material';
import dayjs from 'dayjs';
import { useForm } from 'react-hook-form';
import Crud_Service from '../../../../apis/CrudService.jsx';
import TableActionButtons from '../../../../components/_form/TableActionButtons.jsx';
import useTableLogic from '../../../../components/helpers/MRTUseTableLogic.tsx';
import { toast } from 'react-toastify';
import FmMRTDataTable from '../../../../components/FmMRTDataTable.tsx';
import { useNavigate } from 'react-router-dom';
import FmViewDateField from '../../../../components/_mui/FmViewDateField.tsx';

const BoldBox = (props) => {
  const { value, primary } = props;
  return <Box sx={{ color: primary && 'primary.main' }}>{value}</Box>;
};

const WorkOrderList = (props) => {
  const { getData } = props;
  const [editingRowData, setEditingRowData] = useState(null);
  const [editingRowId, setEditingRowId] = useState(null);
  const crud = new Crud_Service();
  const [] = useState([]);
  const navigate = useNavigate();
  const { handleSubmit } = useForm();
  const {
    rows,
    isLoading,
    pagination,
    sorting,
    columnFilters,
    globalFilter,
    tableRecordCounts,
    setPagination,
    setSorting,
    setColumnFilters,
    setGlobalFilter,
    fetchData,
    setSearchKeyword,
    searchKeyword,
  } = useTableLogic('workorders');

  useEffect(() => {
    getData && fetchData();
  }, [getData]);

  const handleEditItem = async (values) => {
    const data = {
      ...values,
      startDate: dayjs(values?.startDate).format('YYYY-MM-DD'),
      status: 2,
    };
    setEditingRowId(values?.workOrderId);
    setEditingRowData(values);
    await crud.update(
      'workorders',
      editingRowData?.workOrderId,
      data,
      (_err, res) => {
        if (res?.status === 200) {
          toast.success('Work Order Updated Successfully');
          fetchData();
        } else {
        }
      }
    );
  };

  const handleDeleteWorkOrder = async (view) => {
    await crud.remove('workorders', view?.workOrderId, (_err, res) => {
      if (res?.status === 204) {
        fetchData();
        toast.success('Work Order Deleted Successfully');
      } else {
      }
    });
  };

  const handleEdit = (row) => {
    navigate(`edit/${row?.workOrderId}`);
  };
  const handleCancel = () => {
    setEditingRowId(null);
    setEditingRowData(null);
  };

  const ActionData = [
    {
      name: 'View',
      icon: Icons.view,
      onClick: (_props) => {
        navigate(`view/${_props.workOrderId}`);
      },
    },
    {
      name: 'Edit',
      icon: Icons.edit,
      onClick: (props) => {
        handleEdit(props);
      },
    },
    {
      name: 'Delete',
      icon: Icons.delete,
      onClick: (props) => handleDeleteWorkOrder(props),
      danger: true,
    },
  ];
  // Define the columns
  const columns = [
    {
      header: 'Actions',
      Cell: ({ row }) => (
        <Box className='flex gap-2'>
          {editingRowId === row?.original?.workOrderId ? (
            <>
              <Button onClick={handleSubmit(handleEditItem)}>Save</Button>
              <Button onClick={handleCancel}>Cancel</Button>
            </>
          ) : (
            <TableActionButtons
              Actions={ActionData?.map((action) => ({
                ...action,
                onClick: () => action.onClick(row?.original),
              }))}
            />
          )}
        </Box>
      ),
    },

    {
      accessorKey: 'companyId', // Company is searchable select
      header: 'Company',
      enableEditing: true,
      Cell: ({ cell }) => {
        const order = rows?.find(
          (row) => row?.workOrderId === cell?.row?.original?.workOrderId
        );
        return <BoldBox value={order?.companyName} />;
      },
    },
    {
      accessorKey: 'clientId',
      header: 'Client',
      enableEditing: true,
      Cell: ({ cell }) => {
        const order = rows?.find(
          (row) => row?.workOrderId === cell?.row?.original?.workOrderId
        );
        return (
          <>
            <BoldBox value={order?.clientName} />
            <BoldBox value={order?.clientCode} />
          </>
        );
      },
    },
    {
      accessorKey: 'projectId', // Project is searchable select
      header: 'Project',
      enableEditing: true,
      Cell: ({ cell }) => {
        const order = rows?.find(
          (row) => row?.workOrderId === cell?.row?.original?.workOrderId
        );
        return (
          <>
            <BoldBox value={order?.projectName} />
            <BoldBox value={order?.projectCode} />
          </>
        );
      },
    },
    {
      accessorKey: 'contractId', // Contract is searchable select
      header: 'Contract',
      enableEditing: true,
      Cell: ({ cell }) => {
        const order = rows?.find(
          (row) => row?.workOrderId === cell?.row?.original?.workOrderId
        );
        return (
          <>
            <BoldBox value={order?.contractName} />
            <BoldBox value={order?.contractCode} />
          </>
        );
      },
    },
    {
      accessorKey: 'previousContractId', // Previous contract is searchable select
      header: 'Previous Contract',
      enableEditing: true,
      Cell: ({ cell }) => {
        const order = rows?.find(
          (row) => row?.workOrderId === cell?.row?.original?.workOrderId
        );
        return (
          <>
            <BoldBox value={order.previousContractName} />
            <BoldBox value={order.previousContractCode} />
          </>
        );
      },
    },
    {
      accessorKey: 'workOrderName',
      header: 'Work Order',
      enableEditing: true,
      Cell: ({ cell }) => {
        const order = rows?.find(
          (row) => row?.workOrderId === cell?.row?.original?.workOrderId
        );
        return (
          <>
            <BoldBox value={order?.workOrderName} />
            <BoldBox value={order?.workOrderCode} />
          </>
        );
      },
    },
    {
      accessorKey: 'workOrderTypeId',
      header: 'Work Order Type',
      enableEditing: true,
      Cell: ({ cell }) => {
        const order = rows?.find(
          (row) => row?.workOrderId === cell?.row?.original?.workOrderId
        );
        return <BoldBox value={order?.workOrderTypeName} />;
      },
    },
    {
      accessorKey: 'divisionId',
      header: 'Division',
      enableEditing: true,
      Cell: ({ cell }) => {
        const order = rows?.find(
          (row) => row?.workOrderId === cell?.row?.original?.workOrderId
        );
        return (
          <>
            <BoldBox value={order?.divisionName} />
            <BoldBox value={order?.divisionCode} />
          </>
        );
      },
    },
    {
      accessorKey: 'startDate',
      header: 'Start Date',
      enableEditing: true,
      Cell: ({ row }) => {
        return <FmViewDateField value={row?.original?.startDate} />;
      },
    },

    {
      accessorKey: 'amount',
      header: 'Amount',
      enableEditing: true,
      Cell: ({ cell }) => {
        const order = rows?.find(
          (row) => row?.workOrderId === cell?.row?.original?.workOrderId
        );

        const options = { maximumFractionDigits: 2 };
        const formattedNumber = Intl.NumberFormat('en-AE', options).format(
          order?.amount
        );
        return (
          <Box className='flex justify-between'>
            <BoldBox value={'AED'} />
            <BoldBox value={formattedNumber} />
          </Box>
        );
      },
      meta: {
        textAlign: 'right',
      },
      muiTableBodyCellProps: {
        sx: {
          textAlign: 'right',
        },
      },
    },
  ];

  return (
    <>
      <GlassCard className='h-full'>
        <FmMRTDataTable
          columns={columns}
          enableRowSelection={false}
          rows={rows}
          rowCount={tableRecordCounts}
          editingRow={editingRowId}
          fetchData={fetchData}
          pagination={pagination}
          sorting={sorting}
          columnFilters={columnFilters}
          globalFilter={globalFilter}
          setPagination={setPagination}
          setSorting={setSorting}
          setColumnFilters={setColumnFilters}
          setGlobalFilter={setGlobalFilter}
          isLoading={isLoading}
          setSearchKeyword={setSearchKeyword}
          searchKeyword={searchKeyword}
          handleRowDoubleClick={(ev) => handleEdit(ev.original)}
          muiTableContainerProps={{
            sx: { maxHeight: '100%', flex: 1 },
          }}
        />
      </GlassCard>
    </>
  );
};

export default WorkOrderList;
