import React, { useEffect, useState } from 'react';
import DashboardLayout from '../../../../../components/DashboardLayout.tsx';
import ViewCreateSalesQuotationList from './ViewCreateSalesQuotationList.tsx';
import EditSalesQuotation from './EditSalesQuotation.tsx';
import FmTabsOne from '../../../../../components/tabs/FmTabsOne.tsx';
import GlassCard from '../../../../../components/small/GlassCard.tsx';
import BackToButton from '../../../../../components/small/BackToButton.tsx';
import { useNavigate, useParams } from 'react-router-dom';
import CreateSalesQuotationChecklist from './../CreateSalesQuotationChecklist.tsx';
import CreateSalesQuotationInternalBudget from '../CreateSalesQuotationInternalBudget.tsx';
import Milestones from './../Milestones.tsx';
import Containers from './../../../../../components/helpers/Containers';

const navMenu = [
  {
    id: 1,
    name: 'Sales Quotation Items',
  },
  {
    id: 2,
    name: 'Check List',
  },
  {
    id: 3,
    name: 'Budget',
  },
  {
    id: 4,
    name: 'Milestones',
  },
];

const ViewSalesQuotation = (props) => {
  const { type } = props;
  const [getData, setGetData] = useState(false);
  const navigate = useNavigate();
  const id = useParams();


  const [tabValue, setTabValue] = useState(
    Number(localStorage.getItem('SalesQuoatSelectedTab')) || 1
  );

  const handleChange = (_event, newValue) => {
    setTabValue(newValue);
    localStorage.setItem('SalesQuoatSelectedTab', newValue);
  };

  useEffect(() => {
    document.title = `View | Sales ${type === 2 ? 'Order | Accounts Receivable' : ''
      }${type === 3 ? 'Return | Accounts Receivable' : ''} ${type === 'quotation' ? 'Quotation | Work Order | FM Division' : ''
      }${type === 'order' ? 'Order | Work Order | FM Division' : ''} `;
    // fetchData();
  }, []);

  return (
    <DashboardLayout
      title='View Sales Quotation'
      actionButtons={
        <BackToButton
          className='px-0'
          onClick={() => navigate(-1)}
          title='Back to List'
        />
      }
      titleReverse
    >
      <EditSalesQuotation />
      <GlassCard className='my-5 px-2' bgLight>
        <FmTabsOne
          value={tabValue}
          handleChange={handleChange}
          data={navMenu}
          centered
        />
      </GlassCard>

      {tabValue === 1 && (
        <ViewCreateSalesQuotationList
          type={type}
          getData={getData}
          setGetData={setGetData}
        />
      )}

      {tabValue === 2 && (
        <Containers width={800}>
          <CreateSalesQuotationChecklist salesQuotationId={id} />
        </Containers>
      )}

      {tabValue === 3 && <CreateSalesQuotationInternalBudget />}

      {tabValue === 4 && (
        <Containers width={800}>
          {' '}
          <Milestones />
        </Containers>
      )}
    </DashboardLayout>
  );
};

export default ViewSalesQuotation;
