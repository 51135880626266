import { Icon } from '@iconify/react';
import { Box, Button, InputAdornment, Stack } from '@mui/material';
import { AdapterDayjs } from '@mui/x-date-pickers/AdapterDayjs';
import { LocalizationProvider } from '@mui/x-date-pickers/LocalizationProvider';
import { MobileDatePicker } from '@mui/x-date-pickers/MobileDatePicker';
import dayjs from 'dayjs';
import React, { useEffect, useState } from 'react';
import { toast } from 'react-toastify';
import Crud_Service from '../../../apis/CrudService.jsx';
import CheckboxSearchFilter from '../../../components/_form/CheckboxSearchFilter.jsx';
import {
  FilledTextPercentage,
  LeaveColors,
  LeaveText,
} from '../../../components/_form/FormElements.jsx';
import MuiPopoverCustom from '../../../components/_form/MuiPopoverCustom.jsx';
import TableAvatar from '../../../components/_form/TableAvatar.jsx';
import TableSearch from '../../../components/_form/TableSearch.jsx';
import MuiDialogOne from '../../../components/_mui/MuiDialogOne.jsx';
import DashboardLayout from '../../../components/DashboardLayout.tsx';
import DataTable from '../../../components/DataTable.tsx';
import DataTableToolbarButtons from '../../../components/helpers/DataTableToolbarButtons.tsx';
import { CreateButton } from '../../../components/small/Buttons.jsx';
import GlassCard from '../../../components/small/GlassCard.tsx';
import { Nav } from '../../../utils/index.jsx';
import AttendanceTimePicker from './AttendanceTimePicker.tsx';
import apiInstance from '../../../apis/ApiService.jsx';
import useLoader from '../../../components/helpers/UseLoader.tsx';

const IndicadreorsData = [
  {
    smalltext: 'P',
    text: 'presentDays',
  },
  {
    smalltext: 'PH',
    text: 'Public Holiday',
  },
  {
    smalltext: 'H',
    text: 'Holiday',
  },
  {
    smalltext: 'O',
    text: 'Overtime',
  },
  {
    smalltext: 'L',
    text: 'Leave',
  },
  {
    smalltext: 'FH',
    text: 'First Leave',
  },
  {
    smalltext: 'SH',
    text: 'Second Leave',
  },
  {
    smalltext: '',
    text: 'Not Available',
  },
];

const SelectFilter = [
  {
    name: 'Department',
    child: [
      {
        id: 16,
        value: 'Construction',
        checked: false,
      },
      {
        id: 17,
        value: 'IT',
        checked: false,
      },
      {
        id: 18,
        value: 'Finance',
        checked: false,
      },

      {
        id: 19,
        value: 'Human Resources',
        checked: false,
      },

      {
        id: 20,
        value: 'Marketing',
        checked: false,
      },
    ],
  },
];

const AttendanceSummary = () => {
  const [textShow, setTextShow] = useState(false);
  const crud = new Crud_Service();
  const [rowData, setRowData] = useState([]);
  const [isLoading, setIsLoading] = useState(false);
  const [openUpload, setOpenUpload] = useState(false);
  const [searchKeyword, setSearchKeyword] = useState('');
  const [selectedDate, setSelectedDate] = useState(dayjs());
  const [pageSize, setPageSize] = useState(50);
  const [pageNumber, setPageNumber] = useState(1);
  const [sortBy, setSortBy] = useState('');
  const [tablerecordCounts, setTableRecordCounts] = useState(null);
  const [sortDirection, setSortDirection] = useState('');
  const [dialogData, setDialogData] = useState({});
  const [dialogOpen, setDialogOpen] = useState(false);
  const [selectedDayData, setSelectedDayData] = useState(null);
  const [isNew, setIsNew] = useState(false);
  const {startCreateLoading, stopCreateLoading, createLoading} = useLoader();

  const toggleLoading = (loading) => {
    setIsLoading(loading);
  };

  useEffect(() => {
    fetchData(selectedDate, searchKeyword);
  }, [pageNumber, pageSize, sortBy, sortDirection, searchKeyword]);

  const handlePagination = (model) => {
    setPageSize(model?.pageSize);
    setPageNumber(model?.page + 1);
  };
  const fetchData = async (date) => {
    toggleLoading(true);
    const year = date.format('YYYY');
    const month = date.format('MM');
    const api =
      searchKeyword === ''
        ? `employeemonthlyattendances/${year}/${month}`
        : `employeemonthlyattendances/${year}/${month}?searchKeyword=${searchKeyword}`;
    crud.getAll(
      api,
      {
        pageSize,
        pageNumber,
        sortBy,
        sortDirection,
        searchKeyword,
      },
      (err, res) => {
        if (err) {
          toast.error('Error fetching Employee Attendance:', err);
          toggleLoading(false);
          return;
        } else if (res?.status === 204) {
          toggleLoading(false);
          setRowData([]);
          return;
        }
        setTableRecordCounts(res?.data);
        setRowData(res.data.data);
        toggleLoading(false);
      }
    );
  };
  const handleTextShow = () => {
    setTextShow(!textShow);
  };
  const handleSortChange = (sortModel) => {
    if (sortModel.length > 0) {
      setSortBy(sortModel[0].field);
      setSortDirection(sortModel[0].sort);
    } else {
      setSortBy('');
      setSortDirection('');
    }
  };

  const isWeekend = (year, month, day) => {
    const date = new Date(year, month, day);
    const dayOfWeek = date.getDay();
    return dayOfWeek === 0;
  };

  const headerTextWrapper = (month, day, date) => {
    return (
      <Box>
        <Box sx={{ fontWeight: 500 }}>
          {month} {day}
        </Box>
        <Box sx={{ fontSize: '12px', opacity: 0.7, lineHeight: 1 }}>{date}</Box>
      </Box>
    );
  };

  const isCurrentDate = (year, month, day) => {
    const today = new Date();
    return (
      today.getFullYear() === year &&
      today.getMonth() === month &&
      today.getDate() === day
    );
  };

  const handleClose = () => {
    setOpenUpload(false);
  };

  const generateRows = (employees) => {
    return employees.map((employee, index) => {
      const attendance = {};

      employee.days.forEach((day, dayIndex) => {
        const dayKey = `day_${dayIndex}`;
        attendance[dayKey] = day.attendanceCode;
      });

      return {
        id: index,
        ...attendance,
        ...employee,
      };
    });
  };

  const rows = generateRows(rowData || []);

  const generateDateColumns = (days) => {
    return days.map((day, index) => ({
      field: `day_${index}`,
      headerName: headerTextWrapper(
        new Date(day.date).toLocaleString('default', { month: 'short' }),
        new Date(day.date).getDate(),
        day.day
      ),
      headerClassName: `text-no-wrap p-0 ${
        isWeekend(
          new Date(day.date).getFullYear(),
          new Date(day.date).getMonth(),
          new Date(day.date).getDate()
        )
          ? 'right-border-add bg-yellow-100'
          : isCurrentDate(
              new Date(day.date).getFullYear(),
              new Date(day.date).getMonth(),
              new Date(day.date).getDate()
            )
          ? 'bg-teal-100'
          : 'bg-yellow-100'
      }`,
      width: 90,
      editable: true,
      sortable: false,
      cellClassName: `${
        isWeekend(
          new Date(day.date).getFullYear(),
          new Date(day.date).getMonth(),
          new Date(day.date).getDate()
        )
          ? 'right-border-add bg-yellow-50'
          : isCurrentDate(
              new Date(day.date).getFullYear(),
              new Date(day.date).getMonth(),
              new Date(day.date).getDate()
            )
          ? 'bg-teal-100'
          : 'bg-yellow-50'
      }`,
      align: 'center',
      headerAlign: 'center',
      renderCell: (params) => {
        const dayData = params.row.days?.[index]; // Access specific day's data
        return (
          <>
            {!textShow ? (
              <LeaveColors text={dayData?.attendanceCode} tooltipOff={false} />
            ) : (
              <LeaveText text={dayData?.attendanceCode} tooltipOff={false} />
            )}
          </>
        );
      },
    }));
  };

  const columns = [
    {
      field: 'employeeCode',
      headerName: 'Code',
      hideable: false,
      width: 80,
      sortable: false,
    },
    {
      field: 'employeeName',
      headerName: 'Name',
      hideable: false,
      width: 300,
      renderCell: (params) => {
        return (
          <TableAvatar
            name={params.value}
            img={params.row.imageUrl}
            desc={params.row.mobile_no}
          />
        );
      },
    },
    {
      field: 'cadre',
      headerName: 'Cadre',
      sortable: false,
      width: 75,
    },

    {
      field: 'contractCode',
      headerName: 'Contract Code',
      sortable: false,
      width: 150,
    },

    {
      field: 'buildingCode',
      sortable: false,
      headerName: 'Build Code',
      width: 120,
    },
    {
      field: 'presentDays',
      headerName: 'P',
      sortable: false,
      width: 60,
      headerClassName: 'bg-orange-100',
      cellClassName: 'bg-orange-50',
    },
    {
      field: 'absentDays',
      headerName: 'A',
      sortable: false,
      width: 60,
      headerClassName: 'bg-orange-100',
      cellClassName: 'bg-orange-50',
    },
    {
      field: 'medicalLeave',
      headerName: 'ML',
      width: 60,
      sortable: false,
      headerClassName: 'bg-orange-100',
      cellClassName: 'bg-orange-50',
    },
    {
      field: 'notMinutes',
      headerName: 'NOT',
      width: 120,
      sortable: false,
      hide: false,
      headerClassName: 'bg-purple-100',
      cellClassName: 'bg-purple-50',
      renderCell: (params) => {
        const valueInHours = params.value ? params.value / 60 : 0;
        return <div>{valueInHours.toFixed(2)}</div>;
      },
    },
    {
      field: 'wotMinutes',
      headerName: 'WOT',
      width: 120,
      sortable: false,
      hide: false,
      headerClassName: 'bg-purple-100',
      cellClassName: 'bg-purple-50',
      renderCell: (params) => {
        const valueInHours = params.value ? params.value / 60 : 0;
        return <div>{valueInHours.toFixed(2)}</div>;
      },
    },
    {
      field: 'photMinutes',
      headerName: 'PHOT',
      width: 120,
      sortable: false,
      disableColumnMenu: true,
      headerClassName: 'bg-purple-100',
      cellClassName: 'bg-purple-50',
      renderCell: (params) => {
        const valueInHours = params.value ? params.value / 60 : 0;
        return <div>{valueInHours.toFixed(2)}</div>;
      },
    },
    ...generateDateColumns(rowData[0]?.days || []),

    {
      field: 'totalDays',
      headerName: 'Total Days',
      width: 100,
      sortable: false,
      align: 'right',
    },
    {
      field: 'attendance',
      headerName: 'Attendance (%)',
      width: 130,
      sortable: false,
      renderCell: (params) => {
        return <FilledTextPercentage text={params.value} />;
      },
    },
  ];

  const handleUploadSheet = (event) => {
    const file = event.target.files[0];
    if (file) {
      const fileFormData = new FormData();
      fileFormData.append('file', file);
      fileFormData.append('fileTypeId', 4);

      crud.create('files/upload', fileFormData, (_err, res) => {
        if (res?.status === 200) {
          crud.create(`files/process/${res.data?.fileId}`, '', (_err, res) => {
            if (res?.status === 200) {
              fetchData(dayjs());
              setOpenUpload(false);
            }
          });
        } else {
          setOpenUpload(false);
        }
      });
    }
  };

  const handleMonthChange = (event) => {
    fetchData(event);
    setSelectedDate(event);
  };
  const handleCellClick = (params) => {
    const { field, row } = params;

    if (field.startsWith('day_')) {
      const dayIndex = parseInt(field.split('_')[1], 10); // Extract the day index
      const dayData = row.days?.[dayIndex]; // Retrieve the corresponding day's data

      if (dayData) {
        setSelectedDayData(dayData);
        setDialogData(row);
      }
    }
  };

  const handleDialogClose = () => {
    // reset();
    setDialogOpen(false);
    setSelectedDayData(null);
    setDialogData(null);
    setIsNew(false);
  };

  const handleSubmitAttendance = async (values) => {
    startCreateLoading()
    try {
      if (selectedDayData?.employeeMonthlyAttendanceId) {
        const updateData = {
          employeeId: dialogData?.employeeId,
          attendanceDate: values?.date,
          startTime: dayjs(values?.startTime).isValid()
            ? dayjs(values?.startTime).format('HH:mm:ss')
            : values?.startTime,
          endTime: dayjs(values?.endTime).isValid()
            ? dayjs(values?.endTime).format('HH:mm:ss')
            : values?.endTime,
          remarks: values?.remarks,
          employeeMonthlyAttendanceId:
            selectedDayData?.employeeMonthlyAttendanceId,
        };
        await crud.update(
          'employeemonthlyattendances',
          selectedDayData?.employeeMonthlyAttendanceId,
          updateData,
          (_err, res) => {
            if (res?.status === 200) {
              toast.success('Attendance Updated Successfully');
              handleDialogClose();
              selectedDayData(null);
              fetchData(selectedDate);
              stopCreateLoading()
            } else {
              setDialogOpen(true);
              stopCreateLoading()
            }
          }
        );
      } else {
        const createData = {
          ...values,
          employeeId: values?.employeeId || dialogData?.employeeId,
          attendanceDate:
            dayjs(values?.date).format('YYYY-MM-DD') ||
            dayjs(selectedDayData?.attendanceDate).format('YYYY-MM-DD'),
          startTime: dayjs(values?.startTime).isValid()
            ? dayjs(values?.startTime).format('HH:mm:ss')
            : values?.startTime,
          endTime: dayjs(values?.endTime).isValid()
            ? dayjs(values?.endTime).format('HH:mm:ss')
            : values?.endTime,
          remarks: values?.remarks,
        };

        await crud.create(
          'employeemonthlyattendances',
          createData,
          (_err, res) => {
            if (res?.status === 201) {
              toast.success('Attendance added successfully');
              handleDialogClose();
              setSelectedDayData(null);
              fetchData(selectedDate);
              stopCreateLoading()
            } else {
              setDialogOpen(true);
              stopCreateLoading()
            }
          }
        );
      }
    } catch (error) {}
  };

  const handleExcelExport = async (selectedDate) => {
    const year = selectedDate.format('YYYY');
    const month = selectedDate.format('MM');
    const downloadUrl = await apiInstance.getFiles(
      `employeemonthlyattendances/export-excel/${year}/${month}`
    );

    const link = document.createElement('a');
    link.href = downloadUrl;
    link.setAttribute('download', 'Attendence.xlsx');
    document.body.appendChild(link);
    link.click();
    document.body.removeChild(link);
  };

  return (
    <>
      <DashboardLayout
        title='Attendance Summary'
        hasSubmenu
        menu={Nav[0].child[1].children}
        parentMenu={Nav[0].child[1].name}
        parentMenuUrl={Nav[0].child[1].path}
        actionButtons={
          <>
            <Stack spacing={1} direction='row'>
              <MuiPopoverCustom
                data={IndicadreorsData}
                openState={textShow}
                handleTextShow={handleTextShow}
              />
              <Button
                variant='contained'
                color='primary'
                onClick={() => setOpenUpload(true)}
                startIcon={<Icon icon='lucide:sheet' />}
              >
                Upload Attendance Sheet
              </Button>
            </Stack>
          </>
        }
      >
        {/* Listing */}
        {
          <GlassCard>
            <Box
              className='box_height_1'
              sx={{
                '& .text-no-wrap': {},
                '& .text-no-wrap .MuiDataGrid-columnHeaderTitle': {
                  whiteSpace: 'normal',
                  lineHeight: '18px',
                  textAlign: 'center',
                  fontWeight: 300,
                },
                '& .MuiDataGrid-root  .MuiDataGrid-cell.right-border-add, & .MuiDataGrid-root .MuiDataGrid-columnHeader.right-border-add ':
                  {
                    borderRight: '2px dashed #d6c10c',
                  },
              }}
            >
              <DataTable
                rows={rows || []}
                columns={columns}
                initialState={{
                  pinnedColumns: { left: ['employeeName'] },
                }}
                loading={isLoading}
                sortingMode='server'
                paginationMode='server'
                onPaginationModelChange={(model) => handlePagination(model)}
                onSortModelChange={(model) => handleSortChange(model)}
                page={pageNumber - 1}
                onCellClick={handleCellClick}
                pageSize={pageSize}
                rowCount={tablerecordCounts?.total}
                slots={{
                  toolbar: () => (
                    <Box
                      className='flex justify-between items-center p-2'
                      sx={{
                        bgcolor: 'background.white',
                        borderBottom: '1px solid',
                        borderColor: 'border.main',
                      }}
                    >
                      <Box className='flex-1'>
                        <Stack direction='row' spacing={2} alignItems='center'>
                          <Box sx={{ maxWidth: '250px' }}>
                            <TableSearch
                              placeholder='Search'
                              fullWidth
                              setSearchKeyword={setSearchKeyword}
                              searchValue={searchKeyword}
                            />
                          </Box>

                          {/* TODO: For Future Use <CheckboxSearchFilter data={SelectFilter} /> */}
                        </Stack>
                      </Box>
                      <Stack direction='row' spacing={2} alignItems='center'>
                        <Box>
                          <CreateButton
                            variant='contained'
                            name='Create New Attendance'
                            onClick={() => setIsNew(true)}
                          />
                        </Box>
                        <Box>
                          <LocalizationProvider dateAdapter={AdapterDayjs}>
                            <MobileDatePicker
                              views={['year', 'month']}
                              value={dayjs(selectedDate)}
                              onChange={(day) => handleMonthChange(day)}
                              slotProps={{
                                textField: {
                                  id: 'hello-mobile-datepicker',
                                  sx: {
                                    '& .MuiInputBase-root': {
                                      cursor: 'pointer',
                                    },
                                    '& .MuiOutlinedInput-notchedOutline': {
                                      display: 'none',
                                    },
                                    '& .MuiInputAdornment-root': {
                                      margin: '0px',
                                    },
                                    '& .MuiInputAdornment-root svg': {
                                      fontSize: '20px',
                                    },
                                    '& .MuiInputBase-input': {
                                      padding: '8px!important',
                                      width: 'auto',
                                      margin: '0 5px',
                                      maxWidth: '130px',
                                      cursor: 'pointer',
                                    },
                                  },

                                  InputProps: {
                                    startAdornment: (
                                      <InputAdornment position='start'>
                                        {' '}
                                        <Icon icon='fontisto:date' />
                                      </InputAdornment>
                                    ),
                                    endAdornment: (
                                      <InputAdornment position='end'>
                                        {' '}
                                        <Icon icon='fe:drop-down' />
                                      </InputAdornment>
                                    ),
                                  },
                                },
                              }}
                            />
                          </LocalizationProvider>
                        </Box>

                        <DataTableToolbarButtons
                          handleExcelExport={() =>
                            handleExcelExport(selectedDate)
                          }
                          disablePrintCSV={true}
                        />
                      </Stack>
                    </Box>
                  ),
                }}
              />
            </Box>
            <MuiDialogOne
              title='Upload Attendance Document'
              open={openUpload}
              onClose={handleClose}
            >
              <input
                type='file'
                onChange={handleUploadSheet}
                id='file'
                accept='.csv, application/vnd.openxmlformats-officedocument.spreadsheetml.sheet, application/vnd.ms-excel'
              />
            </MuiDialogOne>
            <AttendanceTimePicker
              selectedDayData={selectedDayData}
              handleSubmitAttendance={handleSubmitAttendance}
              setDialogData={setDialogData}
              dialogData={dialogData}
              setSelectedDayData={setSelectedDayData}
              isNew={isNew}
              setIsNew={setIsNew}
              setDialogOpen={setDialogOpen}
              dialogOpen={dialogOpen}
              handleDialogClose={handleDialogClose}
              createLoading={createLoading}
            />
          </GlassCard>
        }
      </DashboardLayout>
    </>
  );
};

export default AttendanceSummary;
