import { Box, Button, Grid } from '@mui/material';
import React from 'react';
import FmSearchableSelect from '../../../components/_mui/FmSearchableSelect.tsx';
import FmTextField from '../../../components/_mui/FmTextField.tsx';
import UploadSquare from '../../../components/upload/UploadSquare.tsx';
import { Icon } from '@iconify/react/dist/iconify.js';
import FieldPopOverBox from '../../../components/_form/FieldPopOverBox.tsx';
import { vendorDocumentStatusOptions } from '../../../utils/CommonVariables.tsx';

const GridSize = 2;

const CreateForm = ({
  control,
  editData,
  notEditable,
  getValues,
  files,
  setFiles,
  previewdData,
  loadingFiles,
  setRemovedFiles,
  editLoading,
  handleSubmit,
  setOpen,
  handleEditVendorDocument,
  anchorEl,
  rejectOpen,
  handleRejectClose,
  id,
  setRejectOpen,
  handleRejectClick,
}) => {
  const status = getValues('vendorDocumentStatusName');
  const [approveRejectButton, setApproveRejectButton] = React.useState(false);

  const getButtonLabel = () => {
    switch (status) {
      case 'Submitted':
        return 'Update';
      case 'Document Transferred':
        return 'Acknowledge';
      case 'Acknowledged':
        return 'Upload Document';
      case 'Delivery Notes Submitted':
        return 'Verify';
      case 'Upload Document':
        return 'Submit Notes';
      case 'Approved':
      case 'Verified':
        return null;
      default:
        return null;
    }
  };

  return (
    <>
      <Grid container spacing={2}>
        <Grid item md={GridSize}>
          <FmSearchableSelect
            name={`supplierId`}
            control={control}
            apiUrl='suppliers'
            valueField='supplierId'
            required
            headerField={['Code', 'Name']}
            labelField={['supplierInvitationCode', 'officialSupplierName']}
            sortBy={'officialSupplierName'}
            showField={['supplierInvitationCode', 'officialSupplierName']}
            disabled={notEditable}
            label='Supplier'
            defaultValue={{
              supplierId: editData?.supplierId,
              officialSupplierName: editData?.supplierName,
            }}
          />
        </Grid>
        <Grid item md={GridSize}>
          <FmSearchableSelect
            name={`documentTypeId`}
            control={control}
            apiUrl='documenttypes'
            valueField='documentTypeId'
            required
            queryparam={'documentTypeCategoryId'}
            queryparamValue={11}
            disabled={notEditable}
            headerField={['Document Type']}
            labelField={['documentTypeName']}
            showField={['documentTypeName']}
            label='Document Type'
            defaultValue={{
              documentTypeId: editData?.documentTypeId,
              documentTypeName: editData?.documentTypeName,
            }}
          />
        </Grid>
        <Grid item md={GridSize}>
          <FmTextField
            name={`localPurchaseOrderNo`}
            label='Local Purchase Order No.'
            control={control}
            disabled={notEditable}
            type='string'
          />
        </Grid>
        <Grid item md={GridSize}>
          <FmTextField
            name={`invoiceNumber`}
            label='Document Ref. No./Invoice No.'
            control={control}
            disabled={notEditable}
            type='string'
            required
          />
        </Grid>
        <Grid item md={GridSize}>
          <FmSearchableSelect
            name={`documentTransferredTo`}
            control={control}
            apiUrl='loginusers'
            valueField='loginUserId'
            queryparam='isCoordinator'
            disabled={notEditable}
            required
            queryparamValue={true}
            headerField={['Code', 'Name', 'Email']}
            onChangeProp={(value) => {
              if (value) {
                setApproveRejectButton(true);
              }
            }}
            labelField={['employeeCode', 'employeeName', 'employeeMail']}
            showField={['employeeCode', 'employeeName', 'employeeMail']}
            label='Document Transferred To'
            defaultValue={{
              employeeName: editData?.documentTransferredToUserName,

              loginUserId: editData?.documentTransferredTo,
              employeeMail: editData?.documentTransferredToUserMailId,
            }}
          />
        </Grid>
        <Grid item md={GridSize}>
          <FmTextField
            readOnly
            label='Status'
            name={`vendorDocumentStatusName`}
            control={control}
            disabled
          />
        </Grid>
        <Grid item md={12}>
          <UploadSquare
            files={files}
            setFiles={setFiles}
            onFilesSelected={setFiles}
            previewdData={previewdData}
            disableUpload={!notEditable}
            glassCard={false}
            loadingFiles={loadingFiles}
            setRemovedFiles={setRemovedFiles}
          />
        </Grid>
      </Grid>
      <Box className='mt-4'></Box>
      <Grid
        container
        spacing={2}
        sx={{
          display: 'flex',
          justifyContent: 'space-between',
          alignItems: 'flex-end',
        }}
      >
        <Grid item md={GridSize * 3}>
          <FmTextField
            name={`remarks`}
            label='Remarks'
            disabled={notEditable}
            control={control}
            multiline={true}
            rows={2}
            maxRows={5}
          />
        </Grid>
        {getValues('vendorDocumentStatusId') !==
          vendorDocumentStatusOptions.Verified &&
          getValues('vendorDocumentStatusId') !==
            vendorDocumentStatusOptions.SiteTeamApproved &&
          getValues('vendorDocumentStatusId') !==
            vendorDocumentStatusOptions.SiteTeamRejected &&
          getValues('vendorDocumentStatusId') !==
            vendorDocumentStatusOptions.FinanceTeamRejected &&
          getValues('vendorDocumentStatusId') !==
            vendorDocumentStatusOptions.FinanceTeamApproved &&
          getValues('vendorDocumentStatusId') !==
            vendorDocumentStatusOptions.Rejected && (
            <Grid item md={GridSize * 2}>
              <Box className='justify-end w-full flex gap-2'>
                <Button
                  variant='contained'
                  color='secondary'
                  type='submit'
                  disabled={editLoading}
                  onClick={() => {
                    getValues('vendorDocumentStatusName') === 'Acknowledged'
                      ? setOpen(true)
                      : handleSubmit(handleEditVendorDocument);
                  }}
                  startIcon={<Icon icon='mdi:content-save' />}
                >
                  {getButtonLabel() && <button>{getButtonLabel()}</button>}
                </Button>
                {getValues('vendorDocumentStatusId') ===
                  vendorDocumentStatusOptions.DocumentTransferred && (
                  <>
                    <Button
                      variant='contained'
                      color='error'
                      disabled={editLoading}
                      onClick={(ev) => {
                        setRejectOpen(true);
                        handleRejectClick(ev);
                      }}
                      startIcon={<Icon icon='mdi:close-circle' />}
                    >
                      Reject
                    </Button>

                    <FieldPopOverBox
                      title='Reject Reason'
                      anchorEl={anchorEl}
                      type={3}
                      open={rejectOpen}
                      setRejectOpen={setRejectOpen}
                      onClose={handleRejectClose}
                      id={id}
                    />
                  </>
                )}
              </Box>
            </Grid>
          )}

        {/* Coordinator Rejection */}

        {getValues('vendorDocumentStatusName') === 'Verified' && (
          <Grid item md={GridSize}>
            <Box
              className='justify-end w-full flex gap-2'
              sx={{
                '& .MuiButton-root': {
                  minWidth: '120px',
                  height: '36px',
                },
              }}
            >
              <Button
                variant='contained'
                color='success'
                type='submit'
                disabled={editLoading}
                onClick={() => {}}
                startIcon={<Icon icon='mdi:check-circle' />}
              >
                Approve
              </Button>
              <Button
                variant='contained'
                color='error'
                disabled={editLoading}
                onClick={(ev) => {
                  setRejectOpen(true);
                  handleRejectClick(ev);
                }}
                startIcon={<Icon icon='mdi:close-circle' />}
              >
                Reject
              </Button>
            </Box>
            <FieldPopOverBox
              title='Reject Reason'
              anchorEl={anchorEl}
              type={1}
              open={rejectOpen}
              setRejectOpen={setRejectOpen}
              onClose={handleRejectClose}
              id={id}
            />
          </Grid>
        )}
      </Grid>
    </>
  );
};

export default CreateForm;
