import React, { useState } from 'react';
import GlassCard from '../../../../components/small/GlassCard.tsx';
import MuiDialogOne from '../../../../components/_mui/MuiDialogOne.jsx';
import { Box, Grid } from '@mui/material';
import useTableLogic from '../../../../components/helpers/MRTUseTableLogic.tsx';
import FmMRTDataTable from '../../../../components/FmMRTDataTable.tsx';
import TableActionButtons from '../../../../components/_form/TableActionButtons.jsx';
import { Icon } from '@iconify/react/dist/iconify.js';
import CommonView from '../../../../components/helpers/CommonView.jsx';
import DynamicViewFields from '../../../../components/helpers/DynamicViewFields.jsx';
import { useParams } from 'react-router-dom';
import FmViewDateField from '../../../../components/_mui/FmViewDateField.tsx';
import { ViewField } from '../../../../components/_form/FormElements.jsx';
import CommonLoader from '../../../../components/page/CommonLoader.jsx';
import Crud_Service from '../../../../apis/CrudService.jsx';
import dayjs from 'dayjs';

const BoldBox = ({ title, primary }) => {
  return (
    <Box className='font-semibold' sx={{ color: primary && 'primary.main' }}>
      {title}
    </Box>
  );
};

const ViewProjectContractsList = () => {
  const [loading, setLoading] = useState(false);
  const [rowState, setRowState] = useState({
    newRowId: null,
    isCreatingRow: false,
  });
  const [editingRowId, setEditingRowId] = useState(null);
  const [openView, setOpenView] = useState(false);
  const [selectedRowData, setSelectedRowData] = useState<any>(null);
  const projectId = useParams();
  const crud = new Crud_Service();

  const {
    rows,
    setRows,
    isLoading,
    pagination,
    sorting,
    columnFilters,
    globalFilter,
    tableRecordCounts,
    setSearchKeyword,
    searchKeyword,
    setPagination,
    setSorting,
    setColumnFilters,
    setGlobalFilter,
    fetchData,
  } = useTableLogic('contracts', ['projectId'], [projectId?.id]);

  const handleViewClose = () => {
    setOpenView(false);
  };
  const handleViewClickOpen = async (view) => {
    setOpenView(true);
    setLoading(true);
    await crud.getSingle(
      'contracts',
      view?.contractId,
      (_err, res) => {
        if (res?.status === 200) {
          setSelectedRowData(res?.data);
          setLoading(false);
        } else {
          setLoading(false);
        }
      }
    );
  };
  const ActionData = [
    {
      name: 'View Details',
      icon: <Icon icon='solar:eye-linear' />,
      onClick: (props) => handleViewClickOpen(props),
    },
  ];

  const columns = [
    {
      header: 'Actions',
      enableColumnPinning: true,
      enableEditing: false,
      enableSorting: false,
      size: 50,
      Cell: ({ row }) => {
        return (
          <Box className='flex gap-2'>
            <TableActionButtons
              Actions={ActionData?.map((action) => ({
                ...action,
                onClick: () => action.onClick(row?.original),
              }))}
            />
          </Box>
        );
      },
    },
    {
      accessorKey: 'contractName',
      header: 'Contract Name',
    },
    {
      accessorKey: 'contractScopeName',
      header: 'Contract Scope',
    },

    {
      accessorKey: 'contractEndDate',
      header: 'Contract End Date',
      Cell: (params) => {
        const date = params?.cell?.row?.original?.contractEndDate;
        if (date === null) {
          return '';
        }
        return (
          <FmViewDateField
            value={params?.cell?.row?.original?.contractEndDate}
          />
        );
      },
    },
    {
      accessorKey: 'perviousContractCode',
      header: 'Prev Contract Code',
    },
    {
      accessorKey: 'guaranteeName',
      header: 'Guarantee Name',
    },
    {
      accessorKey: 'guaranteeStartDate',
      header: 'Guarantee Start Date',

      Cell: (params) => {
        const date = params?.cell?.row?.original?.guaranteeStartDate;
        if (date === null) {
          return '';
        }
        return (
          <FmViewDateField
            value={params?.cell?.row?.original?.guaranteeStartDate}
          />
        );
      },
    },
    {
      accessorKey: 'guaranteeEndDate',
      header: 'Guarantee End Date',
      Cell: (params) => {
        const date = params?.cell?.row?.original?.guaranteeEndDate;
        if (date === null) {
          return '';
        }
        return (
          <FmViewDateField
            value={params?.cell?.row?.original?.guaranteeEndDate}
          />
        );
      },
    },

    {
      accessorKey: 'guaranteeAmount',
      header: 'Guarantee Amount',
    },

    {
      accessorKey: 'remarks',
      header: 'Remarks',
    },
  ];

  return (
    <>
      <GlassCard>
        <FmMRTDataTable
          columns={columns}
          enableRowSelection={false}
          rows={rows}
          isCreatingRow={rowState?.isCreatingRow}
          rowCount={tableRecordCounts}
          editingRow={editingRowId}
          pagination={pagination}
          setSearchKeyword={setSearchKeyword}
          sorting={sorting}
          columnFilters={columnFilters}
          globalFilter={globalFilter}
          setPagination={setPagination}
          setSorting={setSorting}
          setColumnFilters={setColumnFilters}
          setGlobalFilter={setGlobalFilter}
          isLoading={isLoading}
          setSearchKeyword={setSearchKeyword}
          searchKeyword={searchKeyword}
          fetchData={fetchData}
          muiTableContainerProps={{
            sx: {
              maxHeight: '350px',
            },
          }}
        />
      </GlassCard>

      <MuiDialogOne
        title='View Details'
        open={openView}
        onClose={handleViewClose}
      >
        {loading ? (
          <CommonLoader />
        ) : (
          <Grid container spacing={3} className='mb-5'>
            <Grid item md={6}>
              <ViewField
                label='Client Name'
                title={selectedRowData?.clientName || 'N/A'}
              />
            </Grid>
            <Grid item md={6}>
              <ViewField
                label='Guarantee Name'
                title={
                  selectedRowData?.contractScopeMaps
                    ? selectedRowData.contractScopeMaps.map(scopes => scopes.contractScopeName).join(', ')
                    : 'N/A'
                }
              />
            </Grid>
            <Grid item md={6}>
              <ViewField
                label='Contract End Date'
                title={dayjs(selectedRowData?.contractEndDate).format('DD-MM-YYYY') || 'N/A'}
              />
            </Grid>
            <Grid item md={6}>
              <ViewField
                label='Prev Contract Code'
                title={selectedRowData?.perviousContractCode || 'N/A'}
              />
            </Grid>
            <Grid item md={6}>
              <ViewField
                label='Guarantee Name'
                title={
                  selectedRowData?.contractGuarantees
                    ? selectedRowData.contractGuarantees.map(guarantee => guarantee.guaranteeName).join(', ')
                    : 'N/A'
                }
              />
            </Grid>

            <Grid item md={6}>
              <ViewField
                label='Guarantee Start Date'
                title={dayjs(selectedRowData?.guaranteeStartDate).format('DD-MM-YYYY') || 'N/A'}
              />
            </Grid>{' '}
            <Grid item md={6}>
              <ViewField
                label='Guarantee End Date'
                title={dayjs(selectedRowData?.guaranteeEndDate).format('DD-MM-YYYY' || 'N/A')}
              />
            </Grid>
            <Grid item md={6}>
              <ViewField
                label='Guarantee Amount'
                title={selectedRowData?.guaranteeAmount || 'N/A'}
              />
            </Grid>
            <Grid item md={6}>
              <ViewField label='Remarks' title={selectedRowData?.remarks || 'N/A'} />
            </Grid>
          </Grid>
        )}
      </MuiDialogOne>
    </>
  );
};

export default ViewProjectContractsList;
