import React, { useEffect, useState } from 'react';
import GlassCard from '../../../components/small/GlassCard.tsx';
import { StyledCard } from '../../../components/_form';
import { Grid } from '@mui/material';
import { ViewField } from '../../../components/_form/FormElements';
import Crud_Service from '../../../apis/CrudService';
import FmViewDateField from '../../../components/_mui/FmViewDateField.tsx';
import { toast } from 'react-toastify';

const EmployeeIdentity = (props) => {
  const crud = new Crud_Service();
  const [item, setItem] = useState(null);
  const { id, i, value } = props;
  useEffect(() => {
    crud.getSingle(`employees/${id}/identity`, '', (err, res) => {
      if (err) {
        toast.error(err);
      } else {
        setItem(res?.data);
      }
    });
  }, [id]);

  // The given structure for display
  const displayStructure = {
    groupName: 'Employee Identity Details',
    child: [
      { title: 'Passport Number', name: 'passportNumber' },
      { title: 'Passport Issue Place', name: 'passportIssuePlace' },
      { title: 'Passport Issue Date', name: 'passportIssueDate' },
      { title: 'Passport Expiry Date', name: 'passportExpiryDate' },
      { title: 'Labour Card Number', name: 'labourCardNumber' },
      { title: 'Labour Card Expiry Date', name: 'labourCardExpiryDate' },
      { title: 'Visa Number', name: 'visaNumber' },
      { title: 'Visa Expiry Date', name: 'visaExpiryDate' },
    ],
  };

  //Formatting Fields for display
  const formatFieldValue = (field, value) => {
    switch (field.name) {
      default:
        return value || '';
    }
  };

  const renderFields = (data, structure) => {
    return structure.child.map((field, i) => {
      const value = data ? data[field.name] : '';
      return (
        <Grid item md={4} key={i}>
          {field.name === 'createdDate' ||
            field.name === 'passportIssueDate' ||
            field.name === 'passportExpiryDate' ||
            field.name === 'labourCardExpiryDate' ||
            field.name === 'visaExpiryDate' ||
            field.name === 'emiratesExpiryDate' ? (
            <FmViewDateField title={field.title} value={value} />
          ) : (
            <ViewField
              label={field.title}
              title={String(formatFieldValue(field, value))}
            />
          )}
        </Grid>
      );
    });
  };

  return (
    <GlassCard className={value === i ? 'w-full  p-5' : 'hidden'}>
      <StyledCard title={'Employee Identity'}>
        <Grid container spacing={1.5}>
          {renderFields(item, displayStructure)}
        </Grid>
      </StyledCard>
    </GlassCard>
  );
};

export default EmployeeIdentity;
