import { Box, Grid, Button, Icon } from "@mui/material";
import { LocalizationProvider, DatePicker } from "@mui/x-date-pickers";
import { AdapterDayjs } from "@mui/x-date-pickers/AdapterDayjs";
import dayjs from "dayjs";
import React, { useEffect, useState } from "react";
import { Controller, useForm } from "react-hook-form";
import { ActionButtons } from "../../../components/_form/index.jsx";
import UploadSquareBoxes from "../../../components/_form/UploadSquareBoxes.tsx";
import FmAutoComplete from "../../../components/_mui/FmAutoComplete.tsx";
import FmSearchableSelect from "../../../components/_mui/FmSearchableSelect.tsx";
import FmTextField from "../../../components/_mui/FmTextField.tsx";
import CommonLoader from "../../../components/page/CommonLoader.jsx";
import GlassCard from "../../../components/small/GlassCard.tsx";
import { useNavigate, useParams } from "react-router-dom";
import useLoader from "../../../components/helpers/UseLoader.tsx";
import FmDatePicker from "../../../components/_mui/FmDatePicker.tsx";
import { toast } from "react-toastify";
import Crud_Service from "../../../apis/CrudService.jsx";
import { yupResolver } from "@hookform/resolvers/yup";
import { requestQuoteSchema } from "../../../utils/CommonvalidationSchemas.tsx";

function EditRequestQuotes() {
  const navigate = useNavigate();
  const { handleSubmit, control, reset, getValues, setValue,watch,trigger} = useForm({
    mode: "onChange",
    resolver:yupResolver(requestQuoteSchema),
  });
  const { startCreateLoading, stopCreateLoading, createLoading } = useLoader();
  const id = useParams()?.id;
  const crud = new Crud_Service();
  const [editedData, setEditedData] = useState(null);

  useEffect(() => {
    id && getEditQuoteRequest();
  }, [id]);

  const getEditQuoteRequest = async () => {
    await crud.getSingle("requestforquotes", id, (_err, res) => {
      if (res?.status === 200) {
        Object.entries({
          ...res?.data,
        }).forEach(([key, value]) => {
          setValue(key, value);
          setEditedData(res?.data);
        });
      } 
    });
  };

  const handleSubmitForm = async (values) => {
    const combinedData = {
      ...values,
      requestForQuoteStartDate: dayjs(values?.requestForQuoteStartDate).format(
        "YYYY-MM-DD"
      ),
      requestForQuoteEndingDate: dayjs(
        values?.requestForQuoteEndingDate
      ).format("YYYY-MM-DD"),
      status: 2,
    };

    await crud.update("requestforquotes", id, combinedData, (err, res) => {
      if (res?.status === 200) {
        toast.success("RFQ_Updated_Successfully");
      }
    });
  };
  const requestForQuoteStartDate=watch('requestForQuoteStartDate')
  return (
    <GlassCard className="p-8">
      <Box className={"mb-4"}>
        <Grid spacing={2} container>
            <form onSubmit={handleSubmitForm}>
              <LocalizationProvider dateAdapter={AdapterDayjs}>
                <Grid container spacing={3}>
                  <Grid item md={3}>
                    <FmTextField
                      name="requestForQuoteNumber"
                      control={control}
                      label="Request For Quote Number"
                    />
                  </Grid>
                  <Grid item md={3}>
                    <FmTextField
                      name="materialRequisitionCode"
                      control={control}
                      label="Material Requisition Code"
                    />
                  </Grid>
                  <Grid item md={3}>
                    <FmSearchableSelect
                      name="companyId"
                      control={control}
                      apiUrl="companies"
                      valueField="companyId"
                      labelField={["companyName", "companyCode"]}
                      showField={["companyName","companyCode"]}
                      label={"Company"}
                      defaultValue={{
                        companyId: editedData?.companyId,
                        companyName: editedData?.companyName,
                      }}
                    />
                  </Grid>
                  <Grid item md={3}>
                    <FmSearchableSelect
                      name="divisionId"
                      control={control}
                      apiUrl="divisions"
                      valueField="divisionId"
                      labelField={["divisionName", "divisionCode"]}
                      showField={["divisionName","divisionCode"]}
                      label={"Division"}
                      defaultValue={{
                        divisionId: editedData?.divisionId,
                        divisionName: editedData?.divisionName,
                      }}
                    />
                  </Grid>
                  <Grid item md={3}>
                    <FmSearchableSelect
                      name="projectId"
                      control={control}
                      apiUrl="projects"
                      valueField="projectId"
                      labelField={["projectName", "projectCode"]}
                      showField={["projectName","projectCode"]}
                      label={"Project"}
                      defaultValue={{
                        projectId: editedData?.projectId,
                        projectName: editedData?.projectName,
                      }}
                    />
                  </Grid>
                  <Grid item md={3}>
                    <FmSearchableSelect
                      name="regionId"
                      control={control}
                      apiUrl="regions"
                      valueField="regionId"
                      labelField={["regionName", "regionCode"]}
                      showField={["regionName","regionCode"]}
                      label={"Region"}
                      defaultValue={{
                        regionId: editedData?.regionId,
                        regionName: editedData?.regionName,
                      }}
                    />
                  </Grid>
                  <Grid item md={3}>
                    <FmDatePicker
                      name="requestForQuoteStartDate"
                      label="Request For Quote Start Date"
                      control={control}
                      required
                      onChangeProp={() => trigger("requestForQuoteEndingDate")}

                    />
                  </Grid>
                  <Grid item md={3}>
                    <FmDatePicker
                      name="requestForQuoteEndingDate"
                      label="Request For Quote End Date"
                      control={control}
                      required
                      onChangeProp={() => trigger("requestForQuoteStartDate")}
                      minDate={requestForQuoteStartDate?dayjs(requestForQuoteStartDate):null}
                    />
                  </Grid>

                  <Grid item md={12}>
                    <ActionButtons
                      onSubmit={handleSubmit(handleSubmitForm)}
                      onCancel={() => navigate(-1)}
                      onReset={reset}
                      submitLoading={createLoading}
                      cancelLoading={false}
                      submitText={"Update"}
                    />
                  </Grid>
                </Grid>
              </LocalizationProvider>
            </form>
        </Grid>
      </Box>
    </GlassCard>
  );
}

export default EditRequestQuotes;
