import React from 'react';
import { Box } from '@mui/material';

const LoginLayout = ({ children, lottieFiles, lottieHide }) => {
  return (
    <Box className={`flex h-screen`}>
      <Box className={`flex overflow-hidden  sm:flex-col  w-full h-screen`}>
        {!lottieHide && (
          <>
            <Box
              className='w-full p-5 flex  overflow-hidden flex-1 flex-col pointer-events-none'
              sx={{ background: (theme) => theme.palette.background.header }}
            >
              <Box>{lottieFiles}</Box>
            </Box>
          </>
        )}
        <Box className='w-full flex justify-center py-10 items-center sm:p-5 bg-white flex-1'>
          {children}
        </Box>
      </Box>
    </Box>
  );
};

export default LoginLayout;
