import React, { useEffect, useRef, useState } from 'react';
import GlassCard from '../../../../../components/small/GlassCard.tsx';
import { Box, Button, Divider, Stack } from '@mui/material';
import { Icon } from '@iconify/react';
import MuiDialogOne from '../../../../../components/_mui/MuiDialogOne.jsx';
import FmMRTDataTable from '../../../../../components/FmMRTDataTable.tsx';
import useTableLogic from '../../../../../components/helpers/MRTUseTableLogic.tsx';
import { useForm } from 'react-hook-form';
import Crud_Service from '../../../../../apis/CrudService.jsx';
import TableActionButtons from '../../../../../components/_form/TableActionButtons.jsx';
import { toast } from 'react-toastify';
import { t } from 'i18next';
import FmTextField from '../../../../../components/_mui/FmTextField.tsx';
import FmSearchableSelect from '../../../../../components/_mui/FmSearchableSelect.tsx';
import { salesquotationItemsSchema } from '../../../../../utils/CommonvalidationSchemas.tsx';
import { yupResolver } from '@hookform/resolvers/yup';
import { useHotkeys } from 'react-hotkeys-hook';
import useLoader from '../../../../../components/helpers/UseLoader.tsx';
import apiInstance from '../../../../../apis/ApiService.jsx';
import { useParams } from 'react-router-dom';
import ActionButtons from '../../../../../components/_form/ActionButtons.tsx';
import FmFileInput from '../../../../../components/_mui/FmFileInput.tsx';
import { useSelector } from 'react-redux';
import { ViewField } from '../../../../../components/_form/FormElements.jsx';
import CurrencyView from '../../../../../components/_mui/FmCurrencyView.tsx';

const BoldBox = ({ title, primary }) => {
  return (
    <Box className='font-semibold' sx={{ color: primary && 'primary.main' }}>
      {title}
    </Box>
  );
};

const ViewCreateSalesQuotationList = ({ type, getData, setGetData }) => {
  const id = useParams()?.id;
  const {
    rows,
    setRows,
    isLoading,
    pagination,
    sorting,
    columnFilters,
    globalFilter,
    tableRecordCounts,
    setPagination,
    setSorting,
    setColumnFilters,
    setGlobalFilter,
    fetchData,
    setSearchKeyword,
    searchKeyword,
  } = useTableLogic(
    `salesquotationitems?salesQuotationId=${id}&sortDirection=asc`
  );
  const [viewDetails, setViewdetails] = useState(false);
  const [viewData, setViewData] = useState(null);
  const crud = new Crud_Service();
  const [editingRowId, setEditingRowId] = useState(null);
  const [editingRowData, setEditingRowData] = useState({});
  const [loader, setLoader] = useState(false);
  const [rowSelection, setRowSelection] = useState({});
  const [selectedRowIds, setSelectedRowIds] = useState([]);
  const [rowState, setRowState] = useState({
    newRowId: null,
    isCreatingRow: false,
  });
  const firstColumnInputRef = useRef(null);
  const [stateError, setStateError] = useState(false);
  const [editingRowIndex, setEditingRowIndex] = useState(null);
  const [currentRowIndex, setCurrentRowIndex] = useState(0);
  const [newRowCreated, setNewRowCreated] = useState(false);
  const [openUpload, setOpenUpload] = useState(false);
  const [uploadLoading, setUploadLoading] = useState(false);
  const [uploadedfileData, setUploadedfileData] = useState(null);
  const { startCreateLoading, stopCreateLoading, createLoading } = useLoader();
  const [formValues, setFormValues] = useState({});
  const [billingValues, setBillingValues] = useState([
    { name: 'Items', value: 0 },
    { name: 'Gross', value: '-' },
    { name: 'Discount', value: 0 },
    { name: 'VAT', value: 0 },
    { name: 'Total', value: 0 },
  ]);
  const salesQuotationId = useParams().id;
  const {
    control: lineControl,
    handleSubmit: handleLineSubmit,
    setValue,
    watch,
    getValues,
    reset,
    formState: { isValid, errors },
  } = useForm(
    {
      resolver: yupResolver(salesquotationItemsSchema),
      mode: 'all',
    }
  );
  const { salesQuotation } = useSelector(
    (state) => state?.salesQuotation
  );

  useEffect(() => {
    const quantity = watch('quantity') || 0;
    const rate = watch('rate') || 0;
    const discount = watch('discount') || 0;
    const vat = watch('vat') || 0;

    if (quantity && rate) {
      const gross = quantity * rate;
      setValue('gross', gross);

      const discountAmount = gross - (gross * discount) / 100;
      setValue('discountAmount', discountAmount);
      const vatAmount = parseFloat(((gross * vat) / 100).toFixed(2));
      setValue('vatAmount', vatAmount);
      const vatTotal = gross + vatAmount;
      const totalAmount = parseFloat((vatTotal + discountAmount).toFixed(2));
      setValue('totalAmount', vatTotal + discountAmount);

    }
  }, [watch('quantity'), watch('rate'), watch('discount'), watch('vat')]);

  useEffect(() => {
    if (getData) {
      fetchData();
      setGetData(false);
    }
  }, [getData]);


  const handleFileUpload = async (e) => {
    setUploadLoading(true);
    const file = e.target.files[0];
    if (file) {
      const fileFormData = new FormData();
      fileFormData.append('file', file);
      fileFormData.append('fileTypeId', 40);

      await crud.create(`/files/upload`, fileFormData, (err, res) => {
        if (res?.status === 200) {
          setUploadLoading(false);
          setUploadedfileData(res?.data);
        } else {
          setUploadLoading(false);
          toast.error(t('something_Went_Wrong'));
        }
      });
    }
  };

  const handleSubmitImport = async () => {
    startCreateLoading();
    await crud.create(
      `salesquotationitems/${salesQuotationId}/process/${uploadedfileData?.fileId}`,
      '',
      (err, res) => {
        if (res?.status === 200) {
          toast.success('Estimation AMC Sheet Detail Created Successfully');
          stopCreateLoading();
          setOpenUpload(false);
          setUploadedfileData(null);
          fetchData();
        } else {
          stopCreateLoading();
          toast.error('Something Went Wrong');
        }
      }
    );
  };
  const handleExportAMC = async () => {
    const downloadUrl = await apiInstance.getFiles(
      `salesquotationitems/${salesQuotationId}/export-excel`
    );
    const link = document.createElement('a');
    link.href = downloadUrl;
    link.setAttribute('download', 'Sales Quotation.xlsx');
    document.body.appendChild(link);
    link.click();
    document.body.removeChild(link);
  };

  const handleViewClick = async (props) => {
    setViewdetails(true);
    setViewData(
      `${type === 'quotation'
        ? props?.salesQuotationItemId
        : props?.salesQuotationItemId
      }`
    );
  };

  const handleDelete = (props) => {
    crud.remove(
      'salesquotationitems',
      props.salesQuotationItemId,
      (_err, res) => {
        if (res?.status === 204) {
          fetchData();
        } else {
        }
      }
    );
  };

  useEffect(() => {
    if (editingRowIndex !== null && firstColumnInputRef.current && !isLoading) {
      firstColumnInputRef.current.focus();
    }
  }, [editingRowIndex, isLoading]);
  useEffect(() => {
    if (editingRowData) {
      Object.entries(editingRowData).forEach(([key, value]) => {
        setValue(key, value);
      });
    }
  }, [editingRowData, setValue]);

  // useEffect(() => {
  //   // Filter rows[0]?.estimationAmcSheetDetails? based on rowSelection keys
  //   if (rowSelection) {
  //     const selectedRowIds = rows
  //       ?.filter((_, index) => rowSelection[index]) // Check if row index is selected in rowSelection
  //       .map((row) => row?.salesQuotationItemId); // Get the `salesQuotationItemId` of selected rows[0]?.estimationAmcSheetDetails?

  //     setSelectedRowIds(selectedRowIds);
  //   }
  // }, [rowSelection]);

  const delectCheckBoxClicked = async () => {
    await crud.remove(
      'estimationamcsheetdetails',
      selectedRowIds,
      (err, res) => {
        if (res) {
          fetchData();
          setSelectedRowIds([]);
          setRowSelection({});
        }
      }
    );
  };

  const handleAddNewRow = () => {
    // TODO
    // if (salesQuotation?.salesQuotationId !== null) {
    //   return;
    // }
    const newId = "new_row_" + Date.now();
    reset();
    setEditingRowData(null);
    setEditingRowId(null);
    setRowState({
      newRowId: newId,
      isCreatingRow: true,
    });

    setEditingRowIndex(
      editingRowIndex === rows.length ? editingRowIndex + 1 : rows.length
    );
    setNewRowCreated(false);
    setRows((prevRows) => [
      ...prevRows,
      {
        salesQuotationItemId: newId, // Ensure this is set as needed
        salesQuotationId: salesQuotationId,
        description: '',
        divisionId: 0,
        quantity: 0,
        rate: 0,
        gross: 0,
        discount: '',
        vat: 0,
        vatAmount: 0,
        totalAmount: 0,
        remarks: '',
        isEditable: true,
        isNew: true,
      },
    ]);
    reset();
  };

  const previousValues = useRef(getValues()); // store previous values to compare with

  const handleBlur = () => {
    const currentValues = getValues();

    // Compare the previous values with the current values
    const hasChanges = Object.keys(currentValues).some(
      (key) => currentValues[key] !== previousValues.current[key]
    );

    if ((hasChanges && isValid) || (hasChanges && editingRowId)) {
      apiCallAsync(currentValues); // Call the API with the new values
      previousValues.current = currentValues; // Update previous values
    }
  };

  const apiCallAsync = async (values) => {
    setLoader(true);
    if (editingRowId === null) {
      const convertedValues = {
        ...values,
        salesQuotationId: salesQuotationId,
        quantity: Number(values?.quantity) || 0,
        rate: Number(values?.rate) || 0,
        discount: Number(values?.discount) || 0,
        vat: Number(values?.vat) || 0,
        description: values?.description || "",
        divisionId: Number(values?.divisionId),
        remarks: values?.remarks || '',
      };

      try {
        await crud.create(
          'salesquotationitems',
          convertedValues,
          (err, res) => {
            if (res?.status === 201) {
              setEditingRowId(res?.data?.salesQuotationItemId);
              setStateError(false);
              setEditingRowData({});
              setUploadedfileData(null);
            } else {
              setStateError(true);
            }
            setLoader(false);
          }
        );
      } catch (error) {
        setStateError(true);
        setLoader(false);
      }
    } else {
      const updatedCombinedData = {
        ...values,
        salesQuotationId: salesQuotationId,
        description: values?.description,
        divisionId: Number(values?.divisionId),
        quantity: Number(values.quantity) || 0,
        rate: Number(values.rate) || 0,
        discount: Number(values.discount) || 0,
        vat: Number(values.vat) || 0,
        remarks: values?.remarks,
        status: 2,
      };

      try {
        await crud.update(
          'salesquotationitems',
          editingRowId,
          updatedCombinedData,
          (err, res) => {
            if (res?.status === 200) {
              const updatedRow = res?.data;
              const updatedRows = [...rows];
              updatedRows[editingRowIndex] = {
                ...updatedRow,
                isEditable: rows.some((row) => row.isNew),
                isNew: rows.some((row) => row.isNew),
              };
              setRows(updatedRows);
              setStateError(false);
              setUploadedfileData(null);
            } else {
              setStateError(true);
            }
            setLoader(false);
          }
        );
      } catch (error) {
        setStateError(true);
        setLoader(false);
      }
    }
  };

  const handleCancel = (row) => {
    if (row?.original?.salesQuotationItemId === rowState?.newRowId) {
      setRows((prevRows) => {
        if (prevRows?.length > 0) {
          return prevRows?.slice(0, -1);
        }
        return prevRows;
      });
      setRowState((prevState) => ({
        ...prevState,
        newRowId: null,
        isCreatingRow: false,
      }));
      setEditingRowIndex(null);
    } else {
      setEditingRowId(null);
      setEditingRowIndex(null);
    }
    reset();
  };
  const handleEdit = (row) => {
    reset();
    if (rowState?.newRowId) {
      setRows((prevRows) => {
        if (prevRows?.length > 0) {
          return prevRows?.slice(0, -1);
        }
        return prevRows;
      }
      );
      setRowState((prevState) => ({
        ...prevState,
        newRowId: null,
        isCreatingRow: false,
      }));
      // setEditingRowIndex(null);
    }
    setEditingRowId(row?.salesQuotationItemId);
    Object.entries({
      ...row,
    }).forEach(([key, value]) => {
      setValue(key, value);
    });
    setEditingRowData({ ...row });
  };

  useHotkeys(
    'Enter',
    () => handleAddNewRow(),
    {
      enableOnTags: ['INPUT', 'TEXTAREA'], // Optional: Enable hotkey only when focused on specific tags
    },
    [editingRowIndex, rows, stateError, loader]
  );

  useHotkeys(
    'Tab',
    (event) => {
      if (event.key !== 'Tab') return;
      if (stateError) {
        event.preventDefault();
        return;
      }
      const totalRows = rows.length;
      const nextRowIndex = editingRowIndex + 1;

      if (
        nextRowIndex < totalRows &&
        stateError === false &&
        loader === false
      ) {
        handleEdit(rows[nextRowIndex]);
        setEditingRowIndex(nextRowIndex);
      }
    },
    [editingRowIndex, rows, stateError, loader, isValid]
  );

  const handleBlurAndFetch = async () => {
    setLoader(true);
    // const updatedRow = getValues(); // Get the latest values from the form
    // const updatedRows = [...rows]; // Clone the rows[0] to avoid direct mutation

    // // Update the specific row in the cloned array
    // updatedRows[editingRowIndex] = updatedRow;

    // setRows(updatedRows);

    const values = getValues();
    // const { salesQuotationItemId, ...restValues } = values; // Destructure and exclude salesQuotationItemId
    const updateCombinedData = {
      ...values,
      salesQuotationId: salesQuotationId,
      description: values?.description,
      divisionId: Number(values?.divisionId),
      quantity: Number(values.quantity) || 0,
      rate: Number(values.rate) || 0,
      discount: Number(values.discount) || 0,
      vat: Number(values.vat) || 0,
      remarks: values?.remarks,
      status: 2,
    };
    await crud.update(
      'salesquotationitems',
      editingRowId,
      updateCombinedData,
      (err, res) => {
        if (res?.status === 200) {
          const totalRows = rows.length;
          if (editingRowIndex <= totalRows && rows.some((row) => row.isNew)) {
            // setNewRowCreated(true);
            const updatedRow = res?.data;
            const updatedRows = [...rows];
            updatedRows[editingRowIndex] = {
              ...updatedRow,
              isEditable: true,
              isNew: true,
            };
            setRows(updatedRows);
            handleAddNewRow();
          } else {
            const updatedRow = res?.data;
            const updatedRows = [...rows];
            updatedRows[editingRowIndex] = {
              ...updatedRow,
              isEditable: false,
              isNew: false,
            };

            setRows(updatedRows);
            setStateError(false);
            setLoader(false);
          }
        } else {
          setStateError(true);
        }
      }
    );
  };

  const handleCancelAndAddNewRow = () => {
    const newId = 'new_row_' + Date.now();
    reset();
    setEditingRowData(null);
    setEditingRowId(null);
    setRowState({
      newRowId: newId,
      isCreatingRow: true,
    });

    setEditingRowIndex(
      editingRowIndex === rows.length ? editingRowIndex + 1 : rows.length
    );
    setNewRowCreated(false);
    setRows((prevRows) => [
      ...prevRows,
      {
        salesQuotationItemId: 'newId', // Ensure this is set as needed
        salesQuotationId: salesQuotationId,
        description: '',
        divisionId: '',
        quantity: 0,
        rate: 0,
        gross: 0,
        discount: '',
        vat: 0,
        vatAmount: 0,
        totalAmount: 0,
        remarks: '',
        isEditable: true,
        isNew: true,
      },
    ]);
    reset();
  };
  const ActionData = [
    {
      name: 'Delete',
      icon: <Icon icon='solar:trash-bin-minimalistic-broken' />,
      onClick: (props) => handleDelete(props),
      danger: true,
    },
  ];
  // Define the columns
  const columns = [

    {
      accessorKey: 'description',
      header: 'Description',
      enableEditing: true,

      Cell: ({ cell, row }) => {
        const isNewRow = row?.original?.salesQuotationItemId === rowState?.newRowId;
        const isEditing =
          editingRowId === row?.original?.salesQuotationItemId || isNewRow;
        return isEditing ? (
          <FmTextField
            name='description'
            control={lineControl}
            // defaultValue={editingRowData?.description}
            onBlur={handleBlur}
          />
        ) : (
          <BoldBox title={cell?.row?.original?.description || ''} />
        );
      },
    },
    {
      accessorKey: 'divisionId',
      header: 'Division Name',
      flex: 1,
      minWidth: 200,
      enableEditing: true,
      Cell: ({ cell, row }) => {
        const isNewRow = row?.original?.salesQuotationItemId === rowState?.newRowId;
        const isEditing =
          editingRowId === row?.original?.salesQuotationItemId || isNewRow;
        return isEditing ? (
          <FmSearchableSelect
            name='divisionId'
            control={lineControl}
            apiUrl='divisions'
            valueField='divisionId'
            labelField={['divisionCode', 'divisionName']}
            showField={['divisionCode', 'divisionName']}
            defaultValue={editingRowData}
            pageSize={20}
            label='Division'
            // onChangeProp={(row) => {
            //   setSelectedItem(row);
            // }}
            onBlur={handleBlur}
            required
          />
        ) : (
          row?.original?.divisionName || ''
        );
      },
    },
    {
      accessorKey: 'quantity',
      header: 'Quantity',
      size: 100,
      enableEditing: true,
      Cell: ({ cell, row }) => {
        const isNewRow = row?.original?.salesQuotationItemId === rowState?.newRowId;
        const isEditing =
          editingRowId === row?.original?.salesQuotationItemId || isNewRow;
        return isEditing ? (
          <FmTextField
            name='quantity'
            control={lineControl}
            defaultValue={editingRowData?.quantity}
            onBlur={handleBlur}
            required
          />
        ) : (
          <BoldBox title={cell?.row?.original?.quantity || ''} />
        );
      },
    },
    {
      accessorKey: 'rate',
      header: 'Rate',
      enableEditing: true,
      size: 100,

      Cell: ({ cell, row }) => {
        const isNewRow = row?.original?.salesQuotationItemId === rowState?.newRowId;
        const isEditing =
          editingRowId === row?.original?.salesQuotationItemId || isNewRow;
        return isEditing ? (
          <FmTextField
            name='rate'
            control={lineControl}
            defaultValue={editingRowData?.rate}
            onBlur={handleBlur}
            required
          />
        ) : (
          <BoldBox title={cell?.row?.original?.rate || ''} />
        );
      },
    },
    {
      accessorKey: 'gross',
      header: 'Gross',

      Cell: ({ cell, row }) => {
        const isNewRow = row?.original?.salesQuotationItemId === rowState?.newRowId;
        const isEditing =
          editingRowId === row?.original?.salesQuotationItemId || isNewRow;
        const gross = watch('gross')
        return isEditing ? (
          <ViewField
            label={`Gross`}
            title={gross}

          />) : (
          <CurrencyView
            value={cell?.row?.original?.gross}
            currencyType={'AED'}
          />
        );
      },
    },


    {
      accessorKey: 'discount',
      header: 'Discount(%)',
      enableEditing: true,
      size: 100,

      Cell: ({ cell, row }) => {
        const isNewRow = row?.original?.salesQuotationItemId === rowState?.newRowId;
        const isEditing =
          editingRowId === row?.original?.salesQuotationItemId || isNewRow;
        return isEditing ? (
          <FmTextField
            name='discount'
            control={lineControl}
            defaultValue={editingRowData?.discount}
            onBlur={handleBlur}
            required
          />
        ) : (
          <BoldBox title={cell?.row?.original?.discount || ''} />
        );
      },
    },
    {
      accessorKey: 'vat',
      header: 'VAT(%)',
      size: 100,
      enableEditing: true,
      Cell: ({ cell, row }) => {
        const isNewRow = row?.original?.salesQuotationItemId === rowState?.newRowId;
        const isEditing =
          editingRowId === row?.original?.salesQuotationItemId || isNewRow;
        return isEditing ? (
          <FmTextField
            name='vat'
            control={lineControl}
            defaultValue={editingRowData?.vat}
            onBlur={handleBlur}
            required
          />
        ) : (
          <BoldBox title={cell?.row?.original?.vat || ''} />
        );
      },
    },
    {
      accessorKey: 'vatAmount',
      header: 'Vat Amount',
      enableEditing: true,

      Cell: ({ cell, row }) => {
        const isNewRow = row?.original?.salesQuotationItemId === rowState?.newRowId;
        const isEditing =
          editingRowId === row?.original?.salesQuotationItemId || isNewRow;
        const vatAmount = watch('vatAmount');
        return isEditing ? (
          <ViewField
            label={`VAT Amount`}
            title={vatAmount}
          />
        ) : (
          <CurrencyView
            value={cell?.row?.original?.vatAmount}
            currencyType={'AED'}
          />
        );
      },
    },

    {
      accessorKey: 'totalAmount',
      header: 'Total Amount',
      enableEditing: true,

      Cell: ({ cell, row }) => {
        const isNewRow = row?.original?.salesQuotationItemId === rowState?.newRowId;
        const isEditing =
          editingRowId === row?.original?.salesQuotationItemId || isNewRow;
        const totalAmount = watch('totalAmount');

        return isEditing ? (
          <ViewField
            label={`Total Amount`}
            title={totalAmount}
          />
        ) : (
          <CurrencyView
            value={cell?.row?.original?.totalAmount}
            currencyType={'AED'}
          />
        );
      },
    },

    {
      accessorKey: 'remarks',
      header: 'Remarks',
      enableEditing: true,

      Cell: ({ cell, row }) => {
        const isNewRow = row?.original?.salesQuotationItemId === rowState?.newRowId;
        const isEditing =
          editingRowId === row?.original?.salesQuotationItemId || isNewRow;
        return isEditing ? (
          <FmTextField
            name='remarks'
            control={lineControl}
            defaultValue={editingRowData?.remarks}

            onBlur={handleBlurAndFetch}
          />
        ) : (
          <BoldBox title={cell?.row?.original?.remarks || ''} />
        );
      },
    },
    {
      header: 'Action',
      enableColumnPinning: false,
      enableEditing: false,
      enableSorting: false,
      Cell: ({ row, table }) => {
        const isNewRow =
          row?.original?.salesQuotationItemId === rowState?.newRowId;
        const isEditing =
          editingRowId === row?.original?.salesQuotationItemId || isNewRow;

        // Check if the current row is the last row in the table
        const isLastRow = row.index === table.getRowModel().rows.length - 1;

        return (
          <Box className='flex gap-2'>
            {isEditing ? (
              <>
                <Button onClick={() => handleCancel(row)}>Close</Button>
                {isLastRow && rowState?.isCreatingRow === false && (
                  <Button
                    onFocus={() => handleCancelAndAddNewRow(row)}
                  ></Button>
                )}
              </>
            ) : (
              <Box className='flex gap-2'>
                {salesQuotation?.quotationStatusId !== 2 && (
                  <TableActionButtons
                    Actions={ActionData.map((action) => ({
                      ...action,
                      onClick: () => action.onClick(row?.original),
                    }))}
                  />
                )}

                {isLastRow && (
                  <Button
                    variant='contained'
                    color='primary'
                    disabled={salesQuotation?.quotationStatusId === 2}
                    className='px-3 py-1'
                    onClick={() => {
                      setEditingRowIndex(row?.index + 1);
                      handleAddNewRow(row);
                    }}
                  >
                    Add
                  </Button>
                )}
              </Box>
            )}
          </Box>
        );
      },
    },
  ];
  useEffect(() => {
    if (rows?.length > 0) {
      const filteredRows = editingRowId
        ? rows.filter((row) => row.salesQuotationItemId !== editingRowId) // Exclude the editing row
        : rows; // If no editingRowId, use all rows

      const gross = filteredRows.reduce(
        (total, row) => total + parseFloat(row.gross || 0),
        0
      );
      const discount = filteredRows.reduce(
        (total, row) => total + parseFloat(row.discount || 0),
        0
      );

      const net = filteredRows.reduce(
        (total, row) => total + parseFloat(row.net || 0),
        0
      );
      const vat = filteredRows.reduce(
        (total, row) => total + parseFloat(row.vat || 0),
        0
      );
      const total = filteredRows.reduce(
        (total, row) =>
          total + parseFloat((row.rate || 0) * (row.quantity || 0)),
        0
      );

      // Update billing values
      setBillingValues((prev) =>
        prev.map((item) => {
          switch (item.name) {
            case 'Items':
              return { ...item, value: rows.length }; // Add form value if available
            case 'Gross':
              return {
                ...item,
                value:
                  gross +
                  parseFloat(
                    formValues?.gross == 0
                      ? 0
                      : formValues?.gross || editingRowData?.gross || 0
                  ),
              }; // Add form value if available
            case 'Discount':
              return {
                ...item,
                value:
                  discount +
                  parseFloat(
                    formValues?.discount == 0
                      ? 0
                      : formValues?.discount || editingRowData?.discount || 0
                  ),
              }; // Add form value if available
            case 'Net':
              return {
                ...item,
                value:
                  net +
                  parseFloat(
                    formValues?.net == 0
                      ? 0
                      : formValues?.net || editingRowData?.net || 0
                  ),
              }; // Add form value if available
            case 'VAT':
              return {
                ...item,
                value:
                  vat +
                  parseFloat(
                    formValues?.vat == 0
                      ? 0
                      : formValues?.vat || editingRowData?.vat || 0
                  ),
              }; // Add form value if available
            case 'Total':
              return {
                ...item,
                value:
                  total +
                  (formValues?.quantity == '' || formValues?.rate == ''
                    ? 0
                    : parseFloat(
                      (formValues?.rate || editingRowData?.rate || 0) *
                      (formValues?.quantity ||
                        editingRowData?.quantity ||
                        0)
                    )),
              };
            default:
              return item;
          }
        })
      );
    }
  }, [
    formValues?.discount,
    formValues?.quantity,
    formValues?.gross,
    formValues?.vat,
    formValues?.net,
    formValues?.rate,
    editingRowId,
    rows,
    editingRowData?.salesQuotationItemId,
  ]);

  return (
    <>
      <GlassCard className='my-4'>
        <FmMRTDataTable
          muiTableContainerProps={{
            sx: {
              maxHeight: '350px',
            },
          }}
          columns={columns}
          enableRowSelection={false}
          rowSelection={rowSelection}
          setRowSelection={setRowSelection}
          delectCheckBoxClicked={delectCheckBoxClicked}
          rows={rows}
          onCreateRow={handleAddNewRow}
          isCreatingRow={rowState?.isCreatingRow}
          rowCount={tableRecordCounts}
          editingRow={editingRowId}
          pagination={pagination}
          setSearchKeyword={setSearchKeyword}
          sorting={sorting}
          columnFilters={columnFilters}
          globalFilter={globalFilter}
          setPagination={setPagination}
          setSorting={setSorting}
          setColumnFilters={setColumnFilters}
          setGlobalFilter={setGlobalFilter}
          isLoading={isLoading}
          disableNewButton={rows.length === 0}
          setSearchKeyword={setSearchKeyword}
          searchKeyword={searchKeyword}
          columnPining={false}
          fetchData={fetchData}
          handleRowDoubleClick={(row) => {
            if (salesQuotation?.quotationStatusId === 2) {
              return;
            }
            handleEdit(row?.original);
            setEditingRowIndex(row?.index);
          }}
          renderTopToolbarCustomActionsChildrens={
            <Box className='flex justify-end gap-2 me-2'>
              <Button
                variant='outlined'
                color='primary'
                className='px-3 py-1'
                disabled={salesQuotation?.quotationStatusId === 2}
                onClick={() => {
                  setOpenUpload(true);
                  setUploadedfileData(null);
                }}
              >
                Import
              </Button>
              <Button
                variant='outlined'
                color='primary'
                className='px-3 py-1'
                onClick={handleExportAMC}
              >
                Export
              </Button>
            </Box>
          }
        />

        <MuiDialogOne
          open={openUpload}
          onClose={() => setOpenUpload(false)}
          title='Import Sales Quotation Items'
          description='Upload your amc details Xlsx file'
        >
          <FmFileInput
            name='file'
            documentName={uploadedfileData?.originalFileName || ''}
            onChange={handleFileUpload}
            loading={uploadLoading}
          />
          <ActionButtons
            onSubmit={handleSubmitImport}
            onCancel={() => setOpenUpload(false)}
            submitLoading={createLoading}
            onReset={reset}
            cancelLoading={false}
          />
        </MuiDialogOne>
      </GlassCard>
      <GlassCard className='p-3'>
        <Stack
          direction='row'
          flexWrap='wrap'
          spacing={3}
          justifyContent='space-between'
          divider={<Divider orientation='vertical' flexItem />}
        >
          {billingValues?.map((item, i) => {
            return (
              <Box key={i} className='flex justify-between items-center'>
                <Box>{item.name}</Box>
                <Box className='font-bold ms-3' sx={{ color: 'success.main' }}>
                  {item.value}
                </Box>
              </Box>
            );
          })}
        </Stack>
      </GlassCard>
    </>
  );
};

export default ViewCreateSalesQuotationList;
