import { Box } from '@mui/material';
import React from 'react';
import { useNavigate } from 'react-router-dom';
import { toast } from 'react-toastify';
import Crud_Service from '../../../../apis/CrudService';
import useTableLogic from '../../../../components/helpers/MRTUseTableLogic.tsx';
import GlassCard from '../../../../components/small/GlassCard.tsx';
import FmMRTDataTable from '../../../../components/FmMRTDataTable.tsx';
import TableActionButtons from '../../../../components/_form/TableActionButtons.jsx';
import CurrencyView from '../../../../components/_mui/FmCurrencyView.tsx';
import {
  AccountName,
  BranchName,
  RequestDate,
  CompanyName,
} from '../../../../components/table/MRTCommonColumns.tsx';
import Icons from '../../../../utils/Icon.jsx';
import StatusNewBadge from '../../../../components/_form/StatusNewBadge.tsx';

const ListCreditNote = () => {
  const {
    rows,
    isLoading,
    pagination,
    sorting,
    columnFilters,
    globalFilter,
    tableRecordCounts,
    setSearchKeyword,
    searchKeyword,
    setPagination,
    setSorting,
    fetchData,
  } = useTableLogic('creditnotes');
  const navigation = useNavigate();
  const crud = new Crud_Service();
  const handleDelete = async (props) => {
    await crud.remove('creditnotes', props?.creditNoteId, (_err, res) => {
      if (res?.status === 204) {
        toast.success('Credit Note Deleted Successfully');
        fetchData();
      } else {
      }
    });
  };

  const ActionData = [
    {
      name: 'Edit',
      icon: Icons.edit,
      onClick: (props) => navigation(`edit/${props?.creditNoteId}`),
    },
    {
      name: 'Delete',
      icon: Icons.delete,
      onClick: (props) => handleDelete(props),
      danger: true,
    },
  ];
  const columns = [
    {
      header: 'Actions',
      enableColumnPinning: true,
      size: 50,
      Cell: ({ row }) => {
        return (
          <Box className='flex gap-2'>
            <TableActionButtons
              Actions={ActionData?.map((action) => ({
                ...action,
                onClick: () => action.onClick(row?.original),
              }))}
            />
          </Box>
        );
      },
    },
    {
      accessorKey: 'creditNoteCode',
      header: 'Code',
      size: 120,
    },
    {
      accessorKey: 'creditNoteStatusName ',
      header: 'Credit Note Status',
      size: 150,
      enableEditing: true,
      Cell: ({ cell }) => {
        return (
          <StatusNewBadge
            title={cell?.row?.original?.creditNoteStatusName}
            type={cell?.row?.original?.creditNoteStatusName}
          />
        );
      },
    },

    AccountName,
    BranchName,
    RequestDate,
    {
      accessorKey: 'placeOfSupplyName',
      header: 'Place Of Supply Name',
      size: 200,
    },
    CompanyName,
    {
      accessorKey: 'salesInvoiceCode',
      header: 'Sales Invoice',
      size: 140,
    },
    {
      accessorKey: 'jurisdictionName',
      header: 'Jurisdiction Name',
      size: 150,
    },

    {
      accessorKey: 'narration',
      header: 'Narration',
      size: 150,
    },

    {
      accessorKey: 'totalCreditNoteGrossAmount',
      header: 'Gross',
      Cell: ({ cell }) => {
        return (
          <Box>
            <CurrencyView value={cell.getValue()} />
          </Box>
        );
      },
    },
    {
      accessorKey: 'totalCreditNoteVATAmount',
      header: 'VAT Amount',
      Cell: ({ cell }) => {
        return (
          <Box>
            <CurrencyView value={cell.getValue()} />
          </Box>
        );
      },
    },
    {
      accessorKey: 'totalCreditNoteAmount',
      header: 'Total Amount',
      enableColumnPinning: true,
      Cell: ({ cell }) => {
        return (
          <Box>
            <CurrencyView value={cell.getValue()} />
          </Box>
        );
      },
      muiTableBodyCellProps: {
        align: 'center',
        className: 'font-semibold',
      },
      muiTableHeadCellProps: {
        align: 'center',
      },
    },
  ];

  return (
    <>
      <GlassCard className='overflow-hidden h-full-css'>
        <FmMRTDataTable
          columns={columns}
          rows={rows}
          rowCount={tableRecordCounts}
          pagination={pagination}
          setSearchKeyword={setSearchKeyword}
          sorting={sorting}
          fetchData={fetchData}
          columnFilters={columnFilters}
          globalFilter={globalFilter}
          setPagination={setPagination}
          setSorting={setSorting}
          isLoading={isLoading}
          setSearchKeyword={setSearchKeyword}
          rightPinning={['totalCreditNoteAmount']}
          searchKeyword={searchKeyword}
          muiTableContainerProps={{
            sx: {
              maxHeight: '100%',
              flex: 1,
            },
          }}
          showSerialNumber={true}
        />
      </GlassCard>
    </>
  );
};

export default ListCreditNote;
