import { yupResolver } from '@hookform/resolvers/yup';
import { Icon } from '@iconify/react';
import { Box, Button, Grid } from '@mui/material';
import { AdapterDayjs } from '@mui/x-date-pickers/AdapterDayjs';
import { LocalizationProvider } from '@mui/x-date-pickers/LocalizationProvider';
import dayjs from 'dayjs';
import React, { useEffect, useState } from 'react';
import { useForm } from 'react-hook-form';
import { useTranslation } from 'react-i18next';
import { useNavigate, useParams } from 'react-router-dom';
import { toast } from 'react-toastify';
import * as Yup from 'yup';
import apiInstance from '../../../apis/ApiService.jsx';
import Crud_Service from '../../../apis/CrudService';
import { ActionButtons } from '../../../components/_form';
import UploadSquareBoxes from '../../../components/_form/UploadSquareBoxes.tsx';
import FmAutoComplete from '../../../components/_mui/FmAutoComplete.tsx';
import FmDatePicker from '../../../components/_mui/FmDatePicker.tsx';
import FmSearchableSelect from '../../../components/_mui/FmSearchableSelect.tsx';
import FmTextField from '../../../components/_mui/FmTextField.tsx';
import DashboardLayout from '../../../components/DashboardLayout.tsx';
import Containers from '../../../components/helpers/Containers.jsx';
import useCommonFetchApi from '../../../components/helpers/useCommonFetchApi.tsx';
import useLoader from '../../../components/helpers/UseLoader.tsx';
import CommonLoader from '../../../components/page/CommonLoader';
import GlassCard from '../../../components/small/GlassCard.tsx';
import { projectTypes } from '../../../utils/CommonVariables.tsx';

const EditProjects = () => {
  const navigate = useNavigate();
  const crud = new Crud_Service();
  const { id } = useParams();
  const [loading, setLoading] = useState(false);
  const [editData, setEditData] = useState(null);
  const defaultValues = {
    tenderName: editData?.tenderName || '',
    departmentId: editData?.departmentId || null,
    departmentName: editData?.departmentName || '',
    loginUserId: editData?.projectInChargeId || null,
  };
  const [imageUrl, setImageUrl] = useState([]);
  const [components, setComponents] = useState([]);
  const [counter, setCounter] = useState(1);
  const [imageLoading, setImageLoading] = useState(
    components.reduce((acc, component) => {
      acc[component.id] = false; // Initialize loading state for each component
      return acc;
    }, {})
  );
  const validationSchema = Yup.object().shape({
    // tenderId: Yup.string().required('Tender is required'),
    projectName: Yup.string().required('Project Name is required'),
    projectDescription: Yup.string().required(
      'Project Description is required'
    ),
    locationId: Yup.string().required('Location is required'),

    // projectInChargeId: Yup.string().required('Project In Charge is required'),
    divisionId: Yup.string().required('Division is required'),
    projectTypeId: Yup.string().required('Project Type is required'),
    projectValue: Yup.number()
      .typeError('Project Value must be a number')
      .required('Project Value is required'),
    deliveryDate: Yup.date()
      .required('Delivery Date is required')
      .typeError('Invalid date format')
      .test('invalidDate', 'Invalid Date', (value) => {
        return value instanceof Date && !isNaN(value.getTime());
      }),
  });
  const { handleSubmit, control, setValue, getValues, reset } = useForm({
    defaultValues,

    resolver: yupResolver(validationSchema),
    mode: 'onChange',
  });
  const { editLoading, startEditLoading, stopEditLoading } = useLoader();
  const [employees, setEmployees] = useState([]);
  const [locations, setLocations] = useState([]);
  const [tenders, setTenders] = useState([]);
  const [departments, setDepartments] = useState([]);
  const [clients, setClients] = useState([]);
  const { t } = useTranslation();
  const [employeeSearchValue, setEmployeeSearchValue] = useState('');

  useEffect(() => {
    employeeSearchValue.length > 1 && getEmployees(employeeSearchValue);
  }, [employeeSearchValue]);

  useEffect(() => {
    getProjects();
    getEmployees('');
    getLocations();
    getTenders();
    getDepartments();
    getClients();
  }, []);
  const { data: divisions } = useCommonFetchApi('divisions');
  const { data: loginUsers } = useCommonFetchApi('loginusers');

  const getProjects = async () => {
    setLoading(true);
    await crud.getSingle('projects', id, (err, res) => {
      if (res?.status === 200) {
        Object.entries({
          ...res?.data,
        }).forEach(([key, value]) => {
          if (key === 'projectImages') {
            const initialFileUploadIds =
              value?.map((image) => image.fileId) || [];

            // Set fileUploadIds in form values
            setValue('fileUploadIds', initialFileUploadIds);

            const initialComponents =
              value?.map((image) => ({
                id: image.projectImageId,
                path: image.fileName, // Assuming fileName is the path or URL to the image
              })) || [];

            setComponents(initialComponents);
          }
          setValue(key, value);
        });
        setEditData(res?.data);
        setLoading(false);
      } else {
      }
    });
  };

  //get All Employees
  const getEmployees = async (employeeSearchValue) => {
    await apiInstance.getAllEmployee(employeeSearchValue).then((res) => {
      if (res?.status === 200) {
        setEmployees(res?.data?.data);
      } else {
      }
    });
  };
  //get All Locations
  const getLocations = async () => {
    await crud.getAll('locations', '', (err, res) => {
      if (err) {
        toast.error(err);
      }
      if (res?.status === 200) {
        setLocations(res?.data?.data);
      }
    });
  };

  //get All Tenders
  const getTenders = async () => {
    await crud.getAll('tenders', '', (err, res) => {
      if (err) {
        toast.error(err);
      }
      if (res?.status === 200) {
        setTenders(res?.data?.data);
      }
    });
  };

  //get All Departments
  const getDepartments = async () => {
    await crud.getAll('departments', '', (err, res) => {
      if (err) {
        toast.error(err);
      }
      if (res?.status === 200) {
        setDepartments(res?.data?.data);
      }
    });
  };

  //get All Departments
  const getClients = async () => {
    await crud.getAll('clients', '', (err, res) => {
      if (err) {
        toast.error(err);
      }
      if (res?.status === 200) {
        setClients(res?.data?.data);
      }
    });
  };

  const handleEditProjects = async (values) => {
    startEditLoading();

    const updatedProjectImages = values?.fileUploadIds?.map(
      (fileId, index) => ({
        projectImageId: values?.projectImages[index]?.projectImageId || null, // use existing imageId if available
        fileId: fileId, // from the file upload input
        status: 1, // or whatever status you need
      })
    );

    const combinedData = {
      projectName: values?.projectName,
      projectDescription: values?.projectDescription,

      deliveryDate: dayjs(values?.deliveryDate).format('YYYY-MM-DD'),
      locationId: values?.locationId,
      clientId: values?.clientId,
      divisionId: values?.divisionId,
      projectTypeId: values?.projectTypeId,
      projectValue: Number(values?.projectValue),
      projectInChargeId: values?.projectInChargeId,
      // tenderId: values?.tenderId,
      companyId: values?.companyId,
      branchId: values?.branchId,
      status: 1,
      updateProjectImages: updatedProjectImages,
    };
    await crud.update('projects', id, combinedData, (err, res) => {
      if (err) {
        stopEditLoading();

        return;
      }
      if (res?.status === 200) {
        toast.success('Project Updated Successfully');
        stopEditLoading();
        navigate(-1);
      } else {
        stopEditLoading();
      }
    });
  };
  const handleAddComponent = () => {
    setComponents([...components, { id: counter }]);
    setCounter(counter + 1);
  };

  const handleRemoveComponent = (id) => {
    setComponents(components.filter((component) => component.id !== id));
    setImageUrl((prev) => {
      const updatedUrls = { ...prev };
      delete updatedUrls[id];
      return updatedUrls;
    });
  };

  const handleFileChange = (id, fileId) => {
    if (typeof fileId === 'number') {
      const currentFileIds = getValues('fileUploadIds') || [];

      // Find the index for the current component (based on `id`)
      const componentIndex = components.findIndex(
        (component) => component.id === id
      );

      // If the component exists, update the `fileId` for that component
      if (componentIndex !== -1) {
        const updatedFileIds = [...currentFileIds];
        updatedFileIds[componentIndex] = fileId; // Replace the fileId at the correct index
        setValue('fileUploadIds', updatedFileIds);
      } else {
        // If the component is not found, add the new fileId
        setValue('fileUploadIds', [...currentFileIds, fileId]);
      }
    }
  };

  return (
    <DashboardLayout title='Edit Projects'>
      <Containers width={700}>
        <GlassCard className='p-4'>
          {loading ? (
            <CommonLoader />
          ) : (
            <form onSubmit={handleSubmit(handleEditProjects)}>
              <LocalizationProvider dateAdapter={AdapterDayjs}>
                <Grid container spacing={3}>
                  <Grid item md={12}>
                    <FmTextField
                      name='projectCode'
                      control={control}
                      label='Project Code'
                      disabled={true}
                    />
                  </Grid>
                  <Grid item md={6}>
                    <FmTextField
                      name='tenderName'
                      control={control}
                      label='Tender'
                      readOnly
                    />
                  </Grid>
                  <Grid item md={6}>
                    <FmTextField
                      name='projectName'
                      control={control}
                      label='Project Name'
                      required
                    />
                  </Grid>
                  <Grid item md={6}>
                    <FmDatePicker
                      name='deliveryDate'
                      control={control}
                      label='Delivery Date'
                      required
                    />
                  </Grid>
                  <Grid item md={12}>
                    <FmTextField
                      name='projectDescription'
                      control={control}
                      label='Project description'
                      multiline
                      rows={3}
                      maxRows={4}
                      required
                    />
                  </Grid>
                  <Grid item md={6}>
                    <FmSearchableSelect
                      name='projectInChargeId'
                      control={control}
                      apiUrl='loginUsers'
                      valueField='loginUserId'
                      headerField={['Code', 'Name', 'Mail']}
                      labelField={['employeeCode', 'employeeName', 'emailId']}
                      showField={['employeeCode', 'employeeName', 'emailId']}
                      label='Project In Charge'
                      defaultValue={{
                        loginUserId: editData?.projectInChargeId,
                        employeeName: editData?.projectInChargeName,
                        emailId: editData?.projectInChargeMail,
                      }}
                    />
                  </Grid>
                  <Grid item md={6}>
                    <FmAutoComplete
                      name='locationId'
                      control={control}
                      options={locations}
                      optionFields={['locationName']}
                      displayField='locationName'
                      valueKey='locationId'
                      label='Location'
                    />
                  </Grid>

                  <Grid item md={6}>
                    <FmAutoComplete
                      name='clientId'
                      control={control}
                      options={clients}
                      optionFields={['clientName']}
                      displayField='clientName'
                      valueKey='clientId'
                      label='Client'
                    />
                  </Grid>

                  <Grid item md={6}>
                    <FmAutoComplete
                      name='divisionId'
                      control={control}
                      options={divisions}
                      optionFields={['divisionName']}
                      displayField='divisionName'
                      valueKey='divisionId'
                      label='Division'
                    />
                  </Grid>
                  <Grid item md={6}>
                    <FmAutoComplete
                      name='projectTypeId'
                      control={control}
                      options={projectTypes}
                      setSearchValue={() => {}}
                      headers={['Name']}
                      optionFields={['projectTypeName']}
                      displayField='projectTypeName'
                      valueKey='projectTypeId'
                      label='Project Type'
                      required
                    />
                  </Grid>

                  <Grid item md={6}>
                    <FmTextField
                      name='projectValue'
                      control={control}
                      label='Project Value'
                      pattern='Decimal'
                    />
                  </Grid>
                  <Grid item md={6}>
                    <FmTextField
                      name='incentive'
                      control={control}
                      label='Project Incentive'
                      pattern='Decimal'
                    />
                  </Grid>
                  <Grid item md={6}>
                    <FmSearchableSelect
                      name='companyId'
                      control={control}
                      apiUrl='companies'
                      valueField='companyId'
                      labelField={['companyCode', 'companyName']}
                      showField={['companyCode', 'companyName']}
                      label='Company'
                      defaultValue={{
                        companyId: editData?.companyId,
                        companyName: editData?.companyName,
                      }}
                    />
                  </Grid>
                  <Grid item md={6}>
                    <FmSearchableSelect
                      name='branchId'
                      control={control}
                      apiUrl='branches'
                      valueField='branchId'
                      labelField={['branchName']}
                      showField={['branchName']}
                      label={'Branch'}
                      defaultValue={{
                        branchId: editData?.branchId,
                        branchName: editData?.branchName,
                      }}
                    />
                  </Grid>
                  <Grid item md={12}>
                    <Box>
                      <h3 className='mb-3 font-semibold'>
                        Additional Files Upload
                      </h3>
                      <Box className='flex gap-5 flex-wrap'>
                        <Button
                          onClick={handleAddComponent}
                          sx={{
                            minWidth: '100px',
                            height: '100px',
                            border: '1px solid',
                            borderColor: 'border.main',
                            flexDirection: 'column',
                            padding: 0,
                            bgcolor: 'background.white',
                          }}
                        >
                          <span className='text-teal-600 text-3xl mb-3'>
                            <Icon icon='simple-line-icons:plus' />
                          </span>
                          <span>Add Upload</span>
                        </Button>

                        {components.map((component) => (
                          <UploadSquareBoxes
                            key={component.id}
                            imageUrl={component.path}
                            setImageUrl={setImageUrl} // If you need this, otherwise omit
                            handleRemove={handleRemoveComponent}
                            setComponents={setComponents}
                            id={component.id}
                            label='Upload File'
                            setLoading={setImageLoading}
                            isLoading={imageLoading[component.id]}
                            onChange={(fileId) =>
                              handleFileChange(component.id, fileId)
                            }
                            fileTypeId={38}
                          />
                        ))}
                      </Box>
                    </Box>
                  </Grid>
                  <Grid item md={12}>
                    <ActionButtons
                      onSubmit={handleSubmit(handleEditProjects)}
                      onCancel={() => navigate(-1)}
                      onReset={reset}
                      submitLoading={editLoading}
                      cancelLoading={false}
                      submitText='Update'
                      disabled={editData?.editable === false}
                    />
                  </Grid>
                </Grid>
              </LocalizationProvider>
            </form>
          )}
        </GlassCard>
      </Containers>
    </DashboardLayout>
  );
};

export default EditProjects;
