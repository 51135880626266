import React from 'react';
import GlassCard from '../../../components/small/GlassCard.tsx';
import { FormHelperText, Grid, TextField } from '@mui/material';
import { Controller, useForm } from 'react-hook-form';
import FmTextField from '../../../components/_mui/FmTextField.tsx';
import { LocalizationProvider } from '@mui/x-date-pickers/LocalizationProvider';
import { AdapterDayjs } from '@mui/x-date-pickers/AdapterDayjs';
import { DatePicker } from '@mui/x-date-pickers/DatePicker';
import dayjs from 'dayjs';
import Crud_Service from '../../../apis/CrudService.jsx';
import { toast } from 'react-toastify';
import useCommonFetchApi from '../../../components/helpers/useCommonFetchApi.tsx';
import FmAutoComplete from '../../../components/_mui/FmAutoComplete.tsx';
import useLoader from '../../../components/helpers/UseLoader.tsx';
import { yupResolver } from '@hookform/resolvers/yup';
import * as Yup from 'yup';
import ActionButtons from '../../../components/_form/ActionButtons.tsx';
import { useNavigate } from 'react-router-dom';
import DashboardLayout from '../../../components/DashboardLayout.tsx';
import FmDatePicker from '../../../components/_mui/FmDatePicker.tsx';
import { eventCalculationSchema } from '../../../utils/CommonvalidationSchemas.tsx';

const AddEventsCalendar = (props) => {
  const crud = new Crud_Service();
  const navigate = useNavigate();
  const { startCreateLoading, stopCreateLoading, createLoading } = useLoader();
 
  const { control, handleSubmit, reset } = useForm({
    resolver: yupResolver(eventCalculationSchema),
    mode: 'onChange',
  });
  const { data: prepareBy } = useCommonFetchApi('loginUsers') || {
    data: [],
  };

  const handleSubmitEventCalendar = async (data) => {
    startCreateLoading();
    const combinedData = {
      ...data,
      eventCalendarName: data?.eventCalendarName || 'Untitled Event',
      eventDate: dayjs(data?.eventDate).format('YYYY-MM-DD'),
      grantTotal: Number(data?.grantTotal) || 0,
      preparedBy: Number(data?.preparedBy) || 0,
    };
    await crud.create('eventcalendars', combinedData, (err, res) => {
      if (err) {
        stopCreateLoading();
        toast.error('Something went wrong:', err);
        return;
      }
      if (res?.status === 201) {
        toast.success('Event Created successfully');
        reset();
        stopCreateLoading();
        navigate(-1);
      } else {
        stopCreateLoading();
      }
    });
  };

  return (
    <DashboardLayout title='Create Events Calendar'>

    <GlassCard className='mb-2 w-full p-3'>
      <form onSubmit={handleSubmit(handleSubmitEventCalendar)}>
        <LocalizationProvider dateAdapter={AdapterDayjs}>
          <Grid container spacing={2}>
            <Grid item md={2}>
            <FmDatePicker
                name='eventDate'
                control={control}
                label='Event Date'
                required
              />
            </Grid>

            <Grid item md={2}>
              <FmTextField
                name='eventCalendarName'
                control={control}
                label='Event Name'
              />
            </Grid>
            <Grid item md={2}>
              <FmTextField
                name='grantTotal'
                control={control}
                label='Grand Total'
                pattern='Number'
              />
            </Grid>
            <Grid item md={2}>
              <FmAutoComplete
                name='preparedBy'
                control={control}
                options={prepareBy}
                label='Prepared By'
                displayField='loginUserName'
                optionFields={['loginUserName']}
                valueKey='loginUserId'
                required
              />
            </Grid>
          </Grid>
          <ActionButtons
            onSubmit={handleSubmit(handleSubmitEventCalendar)}
            onReset={reset}
            submitLoading={createLoading}
            cancelLoading={false}
            submitText='Create'
            cancelText='Go Back'
            onCancel={() => navigate(-1)}
          />
        </LocalizationProvider>
      </form>
    </GlassCard>
    </DashboardLayout>
  );
};

export default AddEventsCalendar;
