import React, { memo } from 'react';
import { Box, IconButton, Tooltip } from '@mui/material';
import ConfirmationButton from './DeleteConfimation.tsx';

const TableActionButtons = (props) => {
  const { Actions, selectedRow, setSelectedRow } = props;

  return (
    <Box className='flex flex-row'>
      {Actions?.map((item, i) => {
        if (item?.hidden) return null;
        if (item?.name === 'Delete') {
          return (
            <ConfirmationButton
              key={i}
              onConfirm={() => item?.onClick(selectedRow, 'TableActionButtons')}
              context='TableActionButtons'
              row={selectedRow}
              name={item?.name}
              icon={item?.icon}
              disabled={item?.disabled}
            />
          );
        }
        return (
          <Tooltip key={i} title={item?.name} arrow>
            <IconButton
              onClick={(ev) => {
                item.onClick(ev, item, selectedRow);
                setSelectedRow && setSelectedRow(selectedRow);
              }}
              disabled={item?.disabled}
              sx={{
                p: 0,
                width: '25px',
                height: '25px',
                color: item?.danger ? 'red' : 'text.main',
                '& svg': {
                  fontSize: '18px',
                },
              }}
            >
              {item?.icon}
            </IconButton>
          </Tooltip>
        );
      })}
    </Box>
  );
};

export default memo(TableActionButtons);
