import React, { useEffect, useState } from 'react';
import DashboardLayout from '../../../../components/DashboardLayout.tsx';
import GlassCard from '../../../../components/small/GlassCard.tsx';
import BackToButton from '../../../../components/small/BackToButton.jsx';
import { Box, Grid } from '@mui/material';
import { useNavigate, useParams } from 'react-router-dom';
import ViewContractCustomer from './ViewContractCustomer.tsx';
import ViewContractLocationList from './ViewContractLocationList.tsx';
import ViewContractProjectTeam from './ViewContractProjectTeam.tsx';
import ViewContractRevenue from './ViewContractRevenue.tsx';
import ViewContractCost from './ViewContractCost.tsx';
import ViewContractSubContract from './ViewContractSubContract.tsx';
import ViewContractSchedule from './ViewContractSchedule.tsx';
import Crud_Service from '../../../../apis/CrudService.jsx';
import ElementLoader from './../../../../components/page/ElementLoader.tsx';
import { ViewField } from '../../../../components/_form/FormElements.jsx';
import ViewStartEndDate from './../../../../components/view/ViewStartEndDate.tsx';
import FmViewDateField from './../../../../components/_mui/FmViewDateField.tsx';
import TableAvatar from '../../../../components/_form/TableAvatar.jsx';
import ViewChipText from '../../../../components/view/ViewChipText.tsx';
import ViewContractRevenueSchedule from './ViewRevenueSchedule.tsx';
import ViewContractCheckList from './ViewContractCheckList.tsx';
import ViewContractBudgeting from './ViewContractBudgeting.tsx';
import ViewFieldsHori from '../../../../components/view/ViewFieldsHori.tsx';
import { ViewTable } from '../../../../components/_form/FormElements.jsx';
import FmTabsOne from '../../../../components/tabs/FmTabsOne.tsx';

const navMenu = [
  {
    id: 1,
    name: 'Checklist',
  },
  {
    id: 2,
    name: 'Customer',
  },
  {
    id: 3,
    name: 'Location',
  },
  {
    id: 4,
    name: 'Project Team',
  },
  // TODO: Add revenue and cost tabs when required
  // {
  //   id: 5,
  //   name: 'Revenue Tab',
  // },
  // {
  //   id: 6,
  //   name: 'Cost Tab',
  // },
  {
    id: 7,
    name: 'Sub Contractors',
  },
  {
    id: 8,
    name: 'Schedule',
  },
  {
    id: 9,
    name: 'Revenue Schedule',
  },
  {
    id: 10,
    name: 'Contract Budget',
  },
];

const ViewContracts = () => {
  const [value, setValue] = useState(() => {
    return parseInt(localStorage.getItem('selectedTabContracts')) || 0;
  });
  const id = useParams().id;
  const crud = new Crud_Service();
  const navigation = useNavigate();
  const handleChange = (_event, newValue) => {
    setValue(newValue);
    localStorage.setItem('selectedTabContracts', newValue);
  };
  const [contractData, setContractData] = useState({});
  const [loader, setLoader] = useState(false);

  const fetchContractData = async () => {
    await crud.getSingle('contracts', id, (_err, res) => {
      if (res?.status === 200) {
        setContractData(res?.data);
        setLoader(false);
      } else {
      }
    });
  };
  useEffect(() => {
    document.title = 'View | Contract | Presales';
    fetchContractData();
    setLoader(true);
  }, []);

  return (
    <DashboardLayout
      title={contractData?.contractCode}
      actionButtons={
        <BackToButton
          onClick={() => navigation(-1)}
          title='Back to Contract'
          className='p-0'
        />
      }
      titleReverse
    >
      <GlassCard className='p-3 mb-2'>
        {loader ? (
          <div className='flex justify-center'>
            <ElementLoader />
          </div>
        ) : (
          <>
            <Box>
              <Box className='mb-4'>
                <Grid container spacing={2}>
                  <Grid item md={6}>
                    <Box className='flex gap-2 mb-1'>
                      <div>{`Contract Code - ${contractData?.contractCode}`}</div>
                      <div>|</div>
                      <div>{`Project Code - ${contractData?.projectCode}`}</div>
                    </Box>

                    <Box
                      className='font-bold text-sm mb-1'
                      color={'primary.main'}
                    >
                      {contractData?.contractName}
                    </Box>
                    <Box className='flex gap-4 items-center mb-2'>
                      <Box>
                        <Box className='mb-1'>Contract Start/End Date</Box>
                        <ViewStartEndDate
                          start={
                            <FmViewDateField
                              value={contractData?.contractStartDate}
                            />
                          }
                          end={
                            <FmViewDateField
                              value={contractData?.contractEndDate}
                            />
                          }
                        />
                      </Box>
                      <ViewChipText
                        label={contractData?.contractTypeName}
                        fwAdded
                      />
                      <ViewChipText
                        label={`Budget : ${contractData?.budget} ${contractData?.currencyName || '-'
                          }`}
                        fwAdded
                      />
                    </Box>
                    <Box className='flex items-center gap-3'>
                      <TableAvatar
                        name={contractData?.contactPerson}
                        desc={contractData?.contactEmailId}
                      />
                      <div>|</div>
                      <ViewField
                        label='Client '
                        title={contractData?.clientName}
                      />
                    </Box>
                    <Box className='flex items-center gap-8 mt-2'>
                      <Box>
                        <Box className='mb-1'>Gurantee Start Date</Box>
                        <ViewStartEndDate
                          start={
                            <FmViewDateField
                              value={contractData?.guaranteeStartDate || 'null'}
                            />
                          }
                        />
                      </Box>
                      <Box>
                        <Box className='mb-1'>Gurantee End Date</Box>
                        <ViewStartEndDate
                          end={
                            <FmViewDateField
                              value={contractData?.guaranteeEndDate || 'null'}
                            />
                          }
                        />
                      </Box>
                    </Box>
                  </Grid>
                  <Grid item md={6}>
                    <Box className='flex gap-2 mb-3'>
                      <ViewField
                        label='Contact Number'
                        title={contractData?.contactNumber}
                      />

                      <ViewField
                        label='Contract Cost'
                        title={`${contractData?.contractCost} ${contractData?.currencyName || '-'
                          }`}
                      />

                      <ViewField
                        label='Contract Terms'
                        title={contractData?.contractTerms}
                      />

                      <ViewField
                        label='Contract Mode '
                        title={contractData?.contractModeName}
                      />
                    </Box>

                    <Grid container spacing={2}>
                      <Grid item md={6}>
                        <ViewTable
                          paddingLow
                          rows={[
                            {
                              label: 'Previous Contract Code',
                              title: contractData?.perviousContractCode,
                            },
                            {
                              label: 'Reference Number',
                              title: contractData?.referenceNumber,
                            },
                            {
                              label: 'Contract Type ',
                              title: contractData?.contractTypeName,
                            },
                            {
                              label: 'Contract Scope ',
                              title: contractData?.contractScopeName || '-',
                            },
                            {
                              label: 'Billing Frequency ',
                              title: contractData?.billingFrequencyName || '-',
                            },
                          ]}
                        />
                      </Grid>
                      <Grid item md={6}>
                        <ViewTable
                          paddingLow
                          rows={[
                            {
                              label: 'Threshold Value',
                              title: contractData?.thresholdValue || '0',
                              titleBold: true,
                            },
                            {
                              label: 'Currency ',
                              title: contractData?.currencyName || '-',
                            },
                            {
                              label: 'Guarantee ',
                              title: contractData?.guaranteeName || '-',
                            },
                            {
                              label: 'Guarantee Amount',
                              title: contractData?.guaranteeAmount || '-',
                            },
                            {
                              label: 'Remarks',
                              title: contractData?.remarks || '-',
                            },
                          ]}
                        />
                      </Grid>
                    </Grid>
                  </Grid>
                </Grid>
              </Box>
            </Box>
          </>
        )}
      </GlassCard>
      <GlassCard className='mb-2' bgLight>
        <FmTabsOne
          data={navMenu}
          value={value}
          handleChange={handleChange}
          centered
        />
      </GlassCard>
      {value === 1 && <ViewContractCheckList contractId={id} />}
      {value === 2 && <ViewContractCustomer contractId={id} />}
      {value === 3 && (
        <ViewContractLocationList
          contractId={id}
          projectId={contractData?.projectId}
        />
      )}
      {value === 4 && <ViewContractProjectTeam contractId={id} />}
      {value === 5 && <ViewContractRevenue />}
      {value === 6 && <ViewContractCost />}
      {value === 7 && <ViewContractSubContract contractId={id} />}
      {value === 8 && <ViewContractSchedule contractId={id} />}
      {value === 9 && <ViewContractRevenueSchedule contractId={id} />}
      {value === 10 && <ViewContractBudgeting contractData={contractData} />}
    </DashboardLayout>
  );
};

export default ViewContracts;
