import React, { useEffect, useState } from 'react';
import { Box, Grid } from '@mui/material';
import { useForm } from 'react-hook-form';
import { yupResolver } from '@hookform/resolvers/yup';
import dayjs from 'dayjs';
import { toast } from 'react-toastify';
import { useNavigate } from 'react-router-dom';
import Crud_Service from '../../../../../apis/CrudService';
import { ActionButtons } from '../../../../../components/_form';
import FmDatePicker from '../../../../../components/_mui/FmDatePicker.tsx';
import FmFileInput from '../../../../../components/_mui/FmFileInput.tsx';
import FmSearchableSelect from '../../../../../components/_mui/FmSearchableSelect.tsx';
import FmTextField from '../../../../../components/_mui/FmTextField.tsx';
import GlassCard from '../../../../../components/small/GlassCard.tsx';
import { PurchaseVoucherSchema } from '../../../../../utils/CommonvalidationSchemas.tsx';
import useLoader from '../../../../../components/helpers/UseLoader.tsx';
import FmCurrencyTextField from '../../../../../components/_mui/FmCurrencyTextField.tsx';
import {
  StatusBadge,
  ViewField,
} from '../../../../../components/_form/FormElements.jsx';
import { SkeletonLoaderRow } from '../../../../../components/page/SkeletonLoader.tsx';
import MuiStepperOne from '../../../../../components/_mui/MuiStepperOne.jsx';

const GridSize = 3;

const EditPV = (props) => {
  const { type, id, statusApprovedItemsRef } = props;
  const navigate = useNavigate();

  const crud = new Crud_Service();
  const [salesOrder, setSalesOrder] = React.useState();
  const { control, handleSubmit, reset, getValues, setValue, watch, register } =
    useForm({
      resolver: yupResolver(PurchaseVoucherSchema),
      mode: 'onChange',
    });
  const { editLoading, stopEditLoading, startEditLoading } = useLoader();
  const [loading, setLoading] = useState(false);
  const [fileId, setFileId] = useState(null);
  const [pVData, setPVData] = useState({});
  const [dataLoad, setDataLoad] = useState(false);

  useEffect(() => {
    fetchData();
  }, []);

  useEffect(() => {
    register('purchaseVoucherStatusId');
  }, [register]);

  const fetchData = async () => {
    setDataLoad(true);
    await crud.getSingle('purchasevouchers', id, (_err, res) => {
      if (res) {
        setPVData(res?.data);

        const isEditable = [1, 2].includes(res?.data?.purchaseVoucherStatusId);

        if (!isEditable) {
          statusApprovedItemsRef.current = true;
        } else {
          statusApprovedItemsRef.current = false;
        }

        Object.entries(res?.data).map(([key, value]) => {
          setValue(key, value);
          if (key === 'vendorId') {
            setValue('supplierId', value);
          }
        });
      }
      setDataLoad(false);
    });
  };

  const submitPurchaseVoucher = async (values) => {
    startEditLoading();
    const data = {
      ...values,
      purchaseVoucherDate: dayjs(values?.purchaseVoucherDate).format(
        'YYYY-MM-DD'
      ),
      paymentDueDate: dayjs(values?.paymentDueDate).format('YYYY-MM-DD'),
      supplierInvoiceDate: dayjs(values?.supplierInvoiceDate).isValid()
      ? dayjs(values?.supplierInvoiceDate).format('YYYY-MM-DD')
      : null,
      purchaseVoucherTypeId: type,
      vendorId: values.supplierId,
      fileId: fileId,
    };
    await crud.update('purchasevouchers', id, data, (_err, res) => {
      if (res?.status === 200) {
        toast.success('Purchase Voucher Updated Successfully');
        stopEditLoading();
        fetchData();
      } else {
        stopEditLoading();
      }
    });
  };

  const handleSalesOrderChange = (ev) => {
    setSalesOrder(ev);
    setValue('locationId', ev?.locationId ?? null, { shouldValidate: true });
    setValue('approvedBy', ev?.approvedBy ?? null, { shouldValidate: true });
    setValue('companyId', ev?.companyId ?? null, { shouldValidate: true });
    setValue('currencyId', ev?.currencyId ?? '', { shouldValidate: true });
    setValue('inChargeName', ev?.inChargeName ?? null, {
      shouldValidate: true,
    });
    setValue('paymentTypeId', ev?.paymentTypeId ?? null, {
      shouldValidate: true,
    });
  };

  const handleFileUpload = async (event) => {
    setLoading(true);
    const file = event.target.files[0];
    if (file) {
      const fileFormData = new FormData();
      fileFormData.append('file', file);
      fileFormData.append('fileTypeId', 33);
      try {
        const response = await new Promise((resolve, reject) => {
          crud.create('files/upload', fileFormData, (_err, res) => {
            if (res?.status === 200) {
              resolve(res);
              setLoading(false);
            } else {
              reject(new Error('File upload failed'));
              setLoading(false);
            }
          });
        });
        setFileId(response?.data?.fileId);
        setValue(`documentName`, response?.data?.originalFileName);
      } catch (error) {
        setLoading(false);
      } finally {
        setLoading(false);
      }
    }
  };

  const handleStatusChange = (statusNumber) => {
    setValue('purchaseVoucherStatusId', statusNumber, { shouldValidate: true });
    handleSubmit(submitPurchaseVoucher)();
  };

  return (
    <>
      <Grid container spacing={1}>
        <Grid item md={10}>
          <GlassCard className='p-4'>
            <form onSubmit={handleSubmit(submitPurchaseVoucher)}>
              {dataLoad ? (
                <SkeletonLoaderRow count={15} />
              ) : (
                <Grid container spacing={2}>
                  <Grid item md={GridSize}>
                    <FmTextField
                      name='purchaseVoucherName'
                      label='Name'
                      control={control}
                      disabled={
                        statusApprovedItemsRef.current === true ? true : false
                      }
                    />
                  </Grid>

                  <Grid item md={GridSize}>
                    <FmTextField
                      name='purchaseVoucherCode'
                      label='Code'
                      control={control}
                      required
                      disabled={
                        statusApprovedItemsRef.current === true ? true : false
                      }
                    />
                  </Grid>
                  <Grid item md={GridSize * 2}>
                    <FmSearchableSelect
                      name='salesOrderId'
                      control={control}
                      apiUrl='salesorders/order-data'
                      valueField='salesOrderId'
                      labelField={[
                        'salesOrderCode',
                        'projectName',
                        'contractName',
                      ]}
                      disabled={
                        statusApprovedItemsRef.current === true ? true : false
                      }
                      defaultValue={{
                        salesOrderId: pVData?.salesOrderId,
                        salesOrderName: pVData?.salesOrderName,
                      }}
                      stackedFields={{
                        salesOrderCode: [
                          { Code: 'salesOrderCode' },
                          { Name: 'salesOrderName' },
                        ],
                        projectName: [
                          { Code: 'projectCode' },
                          { Name: 'projectName' },
                        ],
                        contractName: [
                          { Code: 'contractCode' },
                          { Name: 'contractName' },
                        ],
                      }}
                      headerField={['Sales Order', 'Project', 'Contract']}
                      showField={['salesOrderCode', 'salesOrderName']}
                      onChangeProp={(ev) => handleSalesOrderChange(ev)}
                      label='Sales Order'
                    />
                  </Grid>
                  <Grid item md={GridSize}>
                    <FmSearchableSelect
                      name='companyId'
                      label='Company'
                      apiUrl={'companies'}
                      valueField='companyId'
                      labelField={['companyName', 'companyCode']}
                      showField={['companyName', 'companyCode']}
                      control={control}
                      disabled={
                        statusApprovedItemsRef.current === true ? true : false
                      }
                      defaultValue={{
                        companyId: pVData?.companyId,
                        companyName: pVData?.companyName,
                      }}
                      required
                    />
                  </Grid>
                  <Grid item md={GridSize}>
                    <FmSearchableSelect
                      name='divisionId'
                      control={control}
                      apiUrl='divisions'
                      valueField='divisionId'
                      labelField={['divisionName']}
                      showField={['divisionName']}
                      label='Division'
                      disabled={
                        statusApprovedItemsRef.current === true ? true : false
                      }
                      defaultValue={{
                        divisionId: pVData?.divisionId,
                        divisionName: pVData?.divisionName,
                      }}
                    />
                  </Grid>

                  <Grid item md={GridSize}>
                    <FmSearchableSelect
                      name='supplierId'
                      control={control}
                      apiUrl='suppliers'
                      valueField='supplierId'
                      defaultValue={{
                        supplierId: pVData?.vendorId,
                        officialSupplierName: pVData?.vendorName,
                        supplierCode: pVData?.vendorInvoiceItemCode,
                      }}
                      disabled={
                        statusApprovedItemsRef.current === true ? true : false
                      }
                      labelField={['supplierCode', 'officialSupplierName']}
                      showField={['supplierCode', 'officialSupplierName']}
                      required
                      label={'Supplier'}
                    />
                  </Grid>
                  <Grid item md={GridSize}>
                    <FmDatePicker
                      name='supplierInvoiceDate'
                      label='Supplier Invoice Date'
                      control={control}
                      disabled={
                        statusApprovedItemsRef.current === true ? true : false
                      }
                    />
                  </Grid>
                  <Grid item md={GridSize}>
                    <FmTextField
                      name='invoiceNo'
                      label='Invoice No'
                      control={control}
                      disabled={
                        statusApprovedItemsRef.current === true ? true : false
                      }
                    />
                  </Grid>

                  <Grid item md={GridSize}>
                    <FmSearchableSelect
                      name='currencyId'
                      label='Currency'
                      apiUrl={'currencies'}
                      valueField='currencyId'
                      required
                      labelField={['currencyName', 'currencyCode']}
                      showField={['currencyName', 'currencyCode']}
                      control={control}
                      disabled={
                        statusApprovedItemsRef.current === true ? true : false
                      }
                      defaultValue={{
                        currencyId:
                          salesOrder?.currencyId || pVData?.currencyId,
                        currencyName:
                          salesOrder?.currencyName || pVData?.currencyName,
                      }}
                    />
                  </Grid>
                  <Grid item md={GridSize}>
                    <FmSearchableSelect
                      name='paymentTypeId'
                      control={control}
                      apiUrl='paymenttypes'
                      valueField='paymentTypeId'
                      headerField={['Payment Type']}
                      labelField={['paymentTypeName']}
                      showField={['paymentTypeName']}
                      label='Payment Type'
                      disabled={
                        statusApprovedItemsRef.current === true ? true : false
                      }
                      defaultValue={{
                        paymentTypeId:
                          salesOrder?.paymentTypeId || pVData?.paymentTypeId,
                        paymentTypeName:
                          salesOrder?.paymentTypeName ||
                          pVData?.paymentTypeName,
                      }}
                    />
                  </Grid>
                  <Grid item md={GridSize}>
                    <FmDatePicker
                      name='paymentDueDate'
                      label='Payment Due Date'
                      control={control}
                      disabled={
                        statusApprovedItemsRef.current === true ? true : false
                      }
                    />
                  </Grid>

                  <Grid item md={GridSize}>
                    <FmCurrencyTextField
                      name='exchangeRate'
                      label='Exchange Rate'
                      control={control}
                      disabled={
                        statusApprovedItemsRef.current === true ? true : false
                      }
                    />
                  </Grid>

                  <Grid item md={GridSize}>
                    <FmFileInput
                      name={`fileId`}
                      documentName={getValues(`documentName`)}
                      onChange={(e) => handleFileUpload(e)}
                      loading={loading}
                      disabled={
                        statusApprovedItemsRef.current === true ? true : false
                      }
                    />
                  </Grid>

                  <Grid item md={GridSize * 2}>
                    <FmTextField
                      name='remarks'
                      multiline
                      rows={4}
                      label='Remarks'
                      control={control}
                      disabled={
                        statusApprovedItemsRef.current === true ? true : false
                      }
                    />
                  </Grid>

                  {id && (
                    <Grid item md={12}>
                      <Box className={`mb-3`}>
                        <MuiStepperOne
                          api='enums/purchase-voucher-status'
                          status={pVData?.purchaseVoucherStatusId}
                          type={2}
                          handleStatusChange={handleStatusChange}
                          previousStatusId={
                            pVData?.previousPurchaseVoucherStatusId
                          }
                        />
                      </Box>
                    </Grid>
                  )}

                  {!id && (
                    <Grid item md={12}>
                      <ActionButtons
                        onSubmit={handleSubmit(submitPurchaseVoucher)}
                        onReset={reset}
                        submitLoading={editLoading}
                        cancelLoading={false}
                        onCancel={() =>
                          navigate(
                            '/financial/accounts-payable/purchase-voucher'
                          )
                        }
                        cancelText='Go Back'
                        submitText='Update'
                        removeSpacing
                        disabled={
                          statusApprovedItemsRef.current === true ? true : false
                        }
                      />
                    </Grid>
                  )}
                </Grid>
              )}
            </form>
          </GlassCard>
        </Grid>
        <Grid item md={2}>
          <Box className='relative w-full h-full'>
            <GlassCard
              className='p-2 absolute left-0 top-0 w-full h-full overflow-auto'
              bgDark
            >
              <Box className='mb-2'>
                <StatusBadge title={'View Details'} type={'orange'} />
              </Box>
              <Box className='flex flex-col gap-2'>
                <ViewField
                  label={'Type'}
                  title={
                    pVData?.contractTypeName ||
                    salesOrder?.contractTypeName ||
                    'N/A'
                  }
                />
                <ViewField
                  label={'Sales Order Code'}
                  title={
                    pVData?.salesOrderCode ||
                    salesOrder?.salesOrderCode ||
                    'N/A'
                  }
                />
                <ViewField
                  label={'Contract Code'}
                  title={
                    pVData?.contractCode || salesOrder?.contractCode || 'N/A'
                  }
                />
                <ViewField
                  label={'Project Code'}
                  title={
                    pVData?.projectCode || salesOrder?.projectCode || 'N/A'
                  }
                />
                <ViewField
                  label={'Client Name'}
                  title={pVData?.clientName || salesOrder?.clientName || 'N/A'}
                />
                <ViewField
                  label={'Division Name'}
                  title={
                    pVData?.divisionName || salesOrder?.divisionName || 'N/A'
                  }
                />
                <ViewField
                  label={'In Charge'}
                  title={
                    pVData?.contractInChargeName ||
                    salesOrder?.contractInChargeName ||
                    'N/A'
                  }
                />
                <ViewField
                  label={'Payment Term'}
                  title={
                    pVData?.paymentTermName ||
                    salesOrder?.paymentTermName ||
                    'N/A'
                  }
                />
                <ViewField
                  label={'Branch Name'}
                  title={pVData?.branchName || salesOrder?.branchName || 'N/A'}
                />
              </Box>
            </GlassCard>
          </Box>
        </Grid>
      </Grid>
    </>
  );
};

export default EditPV;
