import React, { useEffect } from 'react';
import DashboardLayout from '../../../components/DashboardLayout.tsx';
import AddVendorInvoiceReceivable from './AddVendorInvoiceReceivable.tsx';
import ListVendorInvoiceReceivable from './ListVendorInvoiceReceivable.tsx';
import { Box } from '@mui/material';

const VendorInvoiceReceivable = () => {
  const [data, getData] = React.useState(false);
  useEffect(() => {
    document.title = 'Vendor Invoice Receivable | Financial';
  }, []);

  return (
    <DashboardLayout title={'Vendor Invoice Receivable'}>
      <Box className='flex flex-col h-full gap-2'>
        <AddVendorInvoiceReceivable getData={getData} />
        <ListVendorInvoiceReceivable getData={getData} data={data} />
      </Box>
    </DashboardLayout>
  );
};

export default VendorInvoiceReceivable;
