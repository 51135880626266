import { Box } from '@mui/material';
import { DatePicker, LocalizationProvider } from '@mui/x-date-pickers';
import { AdapterDayjs } from '@mui/x-date-pickers/AdapterDayjs';
import dayjs, { Dayjs } from 'dayjs';
import React from 'react';
import { Control, Controller, FieldValues, Path } from 'react-hook-form';
import { Icon } from '@iconify/react/dist/iconify.js';

interface FmDatePickerProps<T extends FieldValues> {
  name: Path<T>;
  label: string;
  control: Control<T>;
  required?: boolean;
  readOnly?: boolean;
  format?: string;
  sx?: React.CSSProperties;
  defaultValue?: Dayjs | undefined;
  defaultValueNotRequired?: boolean;
  minDate?: Dayjs | undefined;
  maxDate?: Dayjs | undefined;
  onChangeProp?: (value: Dayjs | null) => void;
  onBlur?: () => void;
  inputRef?: React.Ref<any>;
  disabled?: boolean;
}

const FmDatePicker = <T extends FieldValues>({
  name,
  label,
  control,
  required,
  format,
  sx,
  defaultValue = dayjs(),
  minDate,
  maxDate,
  readOnly,
  onChangeProp,
  onBlur,
  defaultValueNotRequired,
  inputRef,
  disabled,
}: FmDatePickerProps<T>) => {
  return (
    <LocalizationProvider dateAdapter={AdapterDayjs}>
      <Controller
        name={name}
        control={control}
        defaultValue={defaultValueNotRequired ? undefined : (defaultValue as any)} // Temporary workaround for typing
        render={({
          field: { onChange, onBlur: fieldOnBlur, value },
          fieldState: { error },
        }) => (
          <Box>
            <DatePicker
              label={label}
              minDate={minDate}
              maxDate={maxDate}
              format={format || 'DD/MM/YYYY'}
              sx={sx || { width: '100%' }}
              readOnly={readOnly}
              disabled={disabled}
              value={value ? dayjs(value) : null}
              onChange={(newValue) => {
                onChange(newValue ? dayjs(newValue) : null);
                onChangeProp && onChangeProp(newValue);
              }}
              slots={{
                openPickerIcon: () => (
                  <Icon icon="stash:data-date-light" width="24" height="24" />
                ),
              }}
              slotProps={{
                textField: {
                  fullWidth: true,
                  error: !!error,
                  helperText: error ? error.message : null,
                  required,
                  InputLabelProps: {
                    sx: {
                      '& .MuiInputLabel-asterisk': {
                        color: 'red',
                      },
                    },
                  },
                  inputRef,
                  variant: 'filled',
                  onBlur: () => {
                    fieldOnBlur();
                    onBlur?.();
                  },
                },
              }}
            />
          </Box>
        )}
      />
    </LocalizationProvider>
  );
};

export default FmDatePicker;