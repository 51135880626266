import React, { useMemo, useState } from 'react';
import { Box, Chip } from '@mui/material';
import FmSearchableSelect from './../_mui/FmSearchableSelect.tsx';
import { useForm } from 'react-hook-form';

const FmPurchaseVoucher = ({ handleDeleteExtra, setOnFilter, onFilter }) => {
  const { control, getValues, setValue, reset, watch } = useForm();
  const [selectedValues, setSelectedValues] = useState(false);

  const selectedVendorName = useMemo(
    () => getValues('officialSupplierName') || '',
    [watch('officialSupplierName')]
  );

  const handleDelete = () => {
    setOnFilter({
      vendorId: 0,
      purchaseVoucherStatusId: 0,
      checkboxSelection: false,
      showChip: false,
    });
    handleDeleteExtra();
  };

  return (
    <Box className='flex gap-2 items-center'>
      {onFilter?.showChip ? (
        <Chip
          label={selectedVendorName}
          onDelete={() => {
            handleDelete();
            reset();
          }}
          className='max-w-[250px]'
        />
      ) : (
        <Box className='w-[200px]'>
          <FmSearchableSelect
            name='vendorId'
            control={control}
            apiUrl='suppliers'
            valueField='supplierId'
            labelField={['supplierCode', 'officialSupplierName']}
            showField={['supplierCode', 'officialSupplierName']}
            label={'Supplier'}
            onChangeProp={(e) => {
              setValue('vendorId', e?.supplierId || null);
              setValue('officialSupplierName', e?.officialSupplierName || '');
              setOnFilter({
                vendorId: getValues('vendorId') || 0,
                purchaseVoucherStatusId: 0,
                checkboxSelection: getValues('vendorId') === 0 ? false : true,
                showChip: getValues('vendorId') === 0 ? false : true,
              });
            }}
            className='table-filter-button'
          />
        </Box>
      )}
    </Box>
  );
};

export default FmPurchaseVoucher;
