import React from 'react';
import { Box, Stack } from '@mui/material';
import CurrencyView from '../_mui/FmCurrencyView.tsx';
import GlassCard from '../small/GlassCard.tsx';

const BillingFooter = ({
  billingValues,
  specialLabels = ['VAT'],
  currencyLabels = ['Gross', 'Discount', 'Total'],
}) => {
  return (
    <GlassCard className='p-2 bg-teal-700 border-none uppercase font-semibold text-white'>
      <Stack
        direction='row'
        flexWrap='wrap'
        spacing={3}
        justifyContent='space-between'
      >
        {billingValues?.map((item, i) => {
          const value = isNaN(item.value) || item.value === null ? 0 : item.value;

          return (
            <Box key={i} className='flex justify-between items-center'>
              <Box>
                {specialLabels.includes(item.name) ? `${item.name} %` : item.name}
              </Box>
              <Box className='font-bold ms-3'>
                {currencyLabels.includes(item.name) ? (
                  <CurrencyView value={value} currencyType='AED' />
                ) : (
                  value
                )}
              </Box>
            </Box>
          );
        })}
      </Stack>
    </GlassCard>
  );
};

export default BillingFooter;
