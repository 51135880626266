import React, { useState } from 'react';
import GlassCard from './../../../../components/small/GlassCard.tsx';
import MRTExpandTableStyle from './../../../../components/table/MRTExpandTableStyle.tsx';
import { Icon } from '@iconify/react';
import { Box } from '@mui/material';
import { useNavigate, useLocation } from 'react-router-dom';
import FmMRTDataTable from '../../../../components/FmMRTDataTable.tsx';
import FmSearchableSelect from '../../../../components/_mui/FmSearchableSelect.tsx';
import { Button } from '@mui/material';
import { useForm } from 'react-hook-form';
import Crud_Service from '../../../../apis/CrudService';
import { toast } from 'react-toastify';
import { addCheckInvalidationSchema } from '../../../../utils/CommonvalidationSchemas.tsx';
import { yupResolver } from '@hookform/resolvers/yup';
import TableActionButtons from '../../../../components/_form/TableActionButtons.jsx';

const CreateCheckInFormList = (props) => {
  const {
    rows,
    isLoading,
    pagination,
    sorting,
    columnFilters,
    globalFilter,
    tableRecordCounts,
    setPagination,
    setSorting,
    setColumnFilters,
    setGlobalFilter,
    fetchData,
    setSearchKeyword,
    searchKeyword,
  } = props;
  const [, setCreatingRow] = useState(null);
  const location = useLocation();
  const crud = new Crud_Service();
  const [isSaving, setIsSaving] = useState(false);

  const [editingRowData, setEditingRowData] = useState(null);
  const [editingRowId, setEditingRowId] = useState(null);
  const navigate = useNavigate();

  const { handleSubmit, control, setValue } = useForm({
    resolver: yupResolver(addCheckInvalidationSchema),
    mode: 'onChange',
  });
  const handleView = (id) => {
    navigate(`${location.pathname}/view/${id}`);
  };

  const handleDeleteItems = async (values) => {
    setIsSaving(true);
    crud.remove('checkinforms', values.checkInFormId, (err, res) => {
      if (res?.status === 204) {
        setIsSaving(false);
        fetchData();
        toast.success('Item deleted Successfully');
      } else {
        setIsSaving(false);
      }
    });
  };

  const handleEdit = (props) => {
    navigate(`${location.pathname}/view/${props.checkInFormId}`);
  };

  const ActionData = [
    // {
    //   name: 'View',
    //   icon: <Icon icon='solar:eye-linear' />,
    //   // onClick:() => navigation(`${location.pathname}/view`)
    //   onClick: (props) => {
    //     handleView(props.checkInFormId);
    //   },
    // },
    {
      name: 'Edit',
      icon: <Icon icon='solar:pen-2-broken' />,
      onClick: (props) => handleEdit(props),
    },
    {
      name: 'Delete',
      icon: <Icon icon='solar:trash-bin-minimalistic-broken' />,
      onClick: (props) => handleDeleteItems(props),
      danger: true,
    },
  ];


  const handleCancel = () => {
    setEditingRowId(null);
    setEditingRowData(null);
  };
  const handleEditItem = async (values) => {
    // Extract projectDivisionId from the original row data
    const combinedData = {
      ...values,
      status: 2,
    };
    await crud.update(
      'checkinforms',
      editingRowData?.checkInFormId,
      combinedData,
      (err, res) => {
        if (res?.status === 200) {
          setEditingRowId(null);
          setEditingRowData(null);
          toast.success('Check In Form Updated Successfully');
          fetchData();
        } else {
        }
      }
    );
  };

  // Define the columns
  const columns = [
    {
      accessorKey: 'clientId',
      header: 'Client',
      enableEditing: true,
      Cell: ({ row }) => {
        return editingRowId === row?.original?.checkInFormId ? (
          <FmSearchableSelect
            name='clientId'
            control={control}
            apiUrl='clients'
            valueField='clientId'
            headerField={['Client Name']}
            labelField={['clientName']}
            showField={['clientName']}
            
            label='Client'
            required
            defaultValue={{
              clientId: editingRowData?.clientId,
              clientName: editingRowData?.clientName,
            }}
          />
        ) : (
          row?.original?.clientName
        );
      },
    },
    {
      accessorKey: 'companyId',
      header: 'Company',
      enableEditing: true,
      Cell: ({ row }) => {
        return editingRowId === row?.original?.checkInFormId ? (
          <FmSearchableSelect
            name='companyId'
            control={control}
            apiUrl='companies'
            valueField='companyId'
            headerField={['Company Name']}
            labelField={['companyName']}
            showField={['companyName']}
            
            label='Company'
            required
            defaultValue={{
              companyId: editingRowData?.companyId,
              companyName: editingRowData?.companyName,
            }}
          />
        ) : (
          row?.original?.companyName
        );
      },
    },
    {
      accessorKey: 'projectId',
      header: 'Project Code',
      enableEditing: true,
      Cell: ({ row }) => {
        return editingRowId === row?.original?.checkInFormId ? (
          <FmSearchableSelect
            name='projectId'
            control={control}
            apiUrl='projects'
            valueField='projectId'
            headerField={['Project Code']}
            labelField={['projectCode']}
            showField={['projectCode']}
            
            label='Project Code'
            required
            defaultValue={{
              projectId: editingRowData?.projectId,
              projectCode: editingRowData?.projectCode,
            }}
          />
        ) : (
          row?.original?.projectCode
        );
      },
    },
    {
      accessorKey: 'employeeId',
      header: 'Employee',
      enableEditing: true,
      Cell: ({ row }) => {
        return editingRowId === row?.original?.checkInFormId ? (
          <FmSearchableSelect
            name='employeeId'
            control={control}
            apiUrl='employees'
            valueField='employeeId'
            headerField={['Employee Name']}
            labelField={['firstName']}
            showField={['firstName']}
            
            label='Employee'
            required
            defaultValue={{
              employeeId: editingRowData?.employeeId,
              firstName: editingRowData?.employeeName,
            }}
          />
        ) : (
          row?.original?.employeeName
        );
      },
    },
    {
      accessorKey: 'workOrderId',
      header: 'Work Order',
      enableEditing: true,
      Cell: ({ row }) => {
        return editingRowId === row?.original?.checkInFormId ? (
          <FmSearchableSelect
            name='workOrderId'
            control={control}
            apiUrl='workOrders'
            valueField='workOrderId'
            headerField={['Work Order Code']}
            labelField={['workOrderCode']}
            showField={['workOrderCode']}
            
            label='Work Order'
            required
            defaultValue={{
              workOrderId: editingRowData?.workOrderId,
              workOrderCode: editingRowData?.workOrderCode,
            }}
          />
        ) : (
          row?.original?.workOrderName
        );
      },
    },
    {
      accessorKey: 'contractId',
      header: 'Contract',
      enableEditing: true,
      Cell: ({ row }) => {
        return editingRowId === row?.original?.checkInFormId ? (
          <FmSearchableSelect
            name='contractId'
            control={control}
            apiUrl='contracts'
            valueField='contractId'
            headerField={['Contract Code']}
            labelField={['contractCode']}
            showField={['contractCode']}
            
            label='Contract'
            required
            defaultValue={{
              contractId: editingRowData?.contractId,
              contractCode: editingRowData?.contractCode,
            }}
          />
        ) : (
          row?.original?.contractName
        );
      },
    },
    {
      header: 'Actions',
      enableColumnPinning: true,
      enableEditing: false,
      enableSorting: false,
      size: 50,
      Cell: ({ row }) => (
        <Box className='flex gap-2'>
          {editingRowId === row?.original?.checkInFormId ? (
            <>
              <Button
                onClick={handleSubmit(handleEditItem)}
                loading={isLoading}
              >
                Save
              </Button>
              <Button onClick={handleCancel}>Cancel</Button>
            </>
          ) : (
            <TableActionButtons
              Actions={ActionData?.map((action) => ({
                ...action,
                onClick: () => action.onClick(row?.original),
              }))}
              selectedRow={row?.original}
              setSelectedRow={() => {}}
            />
          )}
        </Box>
      ),
    },
  ];

  return (
    <>
      <GlassCard className='my-4'>
        <MRTExpandTableStyle>
          <FmMRTDataTable
            rows={rows}
            columns={columns}
            enableRowSelection={false}
            rowCount={tableRecordCounts}
            editingRow={editingRowId}
            pagination={pagination}
            sorting={sorting}
            columnFilters={columnFilters}
            globalFilter={globalFilter}
            setPagination={setPagination}
            setSorting={setSorting}
            setColumnFilters={setColumnFilters}
            setGlobalFilter={setGlobalFilter}
            isLoading={isLoading}
            setSearchKeyword={setSearchKeyword}
            searchKeyword={searchKeyword}
            fetchData={fetchData}
            handleRowDoubleClick={(ev) => handleEdit(ev?.original)}
          />
        </MRTExpandTableStyle>
      </GlassCard>
    </>
  );
};

export default CreateCheckInFormList;
