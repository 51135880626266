// useLoader.ts
import { useReducer } from 'react';
import { loaderReducer, initialState, SET_LOADING, SET_CREATE_LOADING, SET_EDIT_LOADING } from '../../store/LoaderReducer.ts';

const useLoader = () => {
  const [state, dispatch] = useReducer(loaderReducer, initialState);

  const startCreateLoading = () => dispatch({ type: SET_CREATE_LOADING, payload: true });
  const stopCreateLoading = () => dispatch({ type: SET_CREATE_LOADING, payload: false });
  const startEditLoading = () => dispatch({ type: SET_EDIT_LOADING, payload: true });
  const stopEditLoading = () => dispatch({ type: SET_EDIT_LOADING, payload: false });
  const startLoading = () => dispatch({ type: SET_LOADING, payload: true });
  const stopLoading = () => dispatch({ type: SET_LOADING, payload: false });

  return {
    ...state,
    startCreateLoading,
    stopCreateLoading,
    startEditLoading,
    stopEditLoading,
    startLoading,
    stopLoading,
  };
};

export default useLoader;
