import React from 'react';
import DashboardLayout from '../../../../components/DashboardLayout.tsx';
import GlassCard from '../../../../components/small/GlassCard.tsx';
import { useNavigate, useParams } from 'react-router-dom';
import CommonView from '../../../../components/helpers/CommonView.jsx';
import DynamicViewFields from '../../../../components/helpers/DynamicViewFields.jsx';
import { CreateButton } from '../../../../components/small/Buttons.jsx';
import ActionButtons from '../../../../components/_form/ActionButtons.tsx';

const ViewCompanyMedicalInsurancePolicy = () => {
  const navigate = useNavigate();
  const { id } = useParams();

  const excludeKeys = [
    'companyInsurancePolicyCategoryId',
    'companyMedicalInsuranceAgeBandId',
    'companyMedicalInsuranceDependencyId',
    'companyMedicalInsurancePolicyId',
    'companyMedicalInsuranceStatusId',
    'companyMedicalInsuranceTypeId',
    'employeeDetailId',
    'employeeId',
    'fileId',
    'regionId',
    'createdDate',
    'updatedDate',
    'status',
  ];

  return (
    <DashboardLayout
      title='View Company Medical Insurance Policy'
      actionButtons={
        <CreateButton
          name='Create'
          onClick={() =>
            navigate('/admin/company-medical-insurance-policy/list/create')
          }
        />
      }
    >
      <GlassCard className='p-5'>
        <CommonView
          url='companymedicalinsurancepolicies'
          id={id}
          excludeKeys={excludeKeys}
          renderFields={(data, fields) => (
            <DynamicViewFields data={data} fields={fields} />
          )}
        />
        <ActionButtons
          cancelLoading={false}
          cancelText='Go Back'
          submitText='Edit'
          onSubmit={() =>
            navigate(`/admin/company-medical-insurance-policy/list/edit/${id}`)
          }
          onCancel={() =>
            navigate('/admin/company-medical-insurance-policy/list')
          }
        />
      </GlassCard>
    </DashboardLayout>
  );
};

export default ViewCompanyMedicalInsurancePolicy;
