import {
  Autocomplete,
  Box,
  CircularProgress,
  Paper,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableFooter,
  TableHead,
  TableRow,
  TextField,
} from '@mui/material';
import axios from 'axios';
import debounce from 'lodash/debounce';
import React, {
  forwardRef,
  useCallback,
  useEffect,
  useRef,
  useState,
} from 'react';
import { Controller } from 'react-hook-form';
import { toast } from 'react-toastify';

interface FmSearchableSelectProps {
  name: string;
  control: any;
  apiUrl: string;
  valueField: string;
  labelField: string[];
  pageSize?: number;
  required?: boolean;
  label: string;
  defaultValue?: any[];
  showField?: string[];
  onChangeProp?: (value: any) => void;
  resultChange?: string;
  disabled?: boolean;
  callApi?: string | null;
  queryparam?: string;
  queryparamValue?: any;
  queryparam2?: string;
  queryparamValue2?: any;
  onBlur?: () => void;
  header?: string;
  inputRef?: any;
  sortBy?: string[];
  sortDirection?: 'asc' | 'desc';
  disabledOptions?: any;
  clearSelection?: () => void;
  headerField: string[];
  stackedFields?: {
    [key: string]: { [title: string]: string }[];
  };
}

const FmSearchableSelect = (props: FmSearchableSelectProps) => {
  const {
    name,
    control,
    apiUrl,
    valueField,
    labelField,
    pageSize = 100,
    required,
    label,
    defaultValue,
    showField,
    onChangeProp,
    resultChange,
    disabled,
    callApi,
    queryparam,
    queryparamValue,
    queryparam2,
    queryparamValue2,
    onBlur,
    sortBy,
    sortDirection = 'asc',
    inputRef,
    disabledOptions,
    header,
    clearSelection,
    headerField,
    stackedFields,
  } = props;

  const [pageCount, setPageCount] = useState(1);
  const [searchQuery, setSearchQuery] = useState('');
  const [options, setOptions] = useState([]);
  const [loading, setLoading] = useState(false);
  const [initialLoad, setInnitialLoad] = useState(false);
  const [, setOpttionsEmpty] = useState(false);
  const rooturl = process.env.REACT_APP_BASE;
  const [hasMore, setHasMore] = useState(true);
  const [totalLength, setTotalLength] = useState(0);
  const listboxRef = useRef(null);
  const [defaultValueState, setDefaultValueState] = useState(null);

  useEffect(() => {
    if (defaultValue) {
      setDefaultValueState(defaultValue);
    }
  }, [defaultValue]);

  const fetchData = useCallback(
    debounce(async (page, search = '') => {
      setLoading(true);
      try {
        const config = getAxiosConfig();
        const regionQuery =
          (queryparamValue ? `${queryparam}=${queryparamValue}&` : '') +
          (queryparam2 && queryparamValue2
            ? `${queryparam2}=${queryparamValue2}&`
            : '');

        const sortQuery = sortBy
          ? `&sortBy=${sortBy}&sortDirection=${sortDirection}`
          : '';

        const url = `${rooturl}/${apiUrl}?${regionQuery}pageSize=${pageSize}&pageNumber=${page}&searchKeyword=${search}${sortQuery}`;

        const response = await axios.get(url, config);
        const result =
          resultChange === 'candidates'
            ? response?.data?.candidates?.data
            : response?.data?.data || [];
        setTotalLength(response?.data?.total);

        setHasMore(response?.data?.data?.length > 0);

        if (result.length === 0) {
          setOpttionsEmpty(true);
        } else {
          setOpttionsEmpty(false);
        }
        if (disabledOptions) {
          const filteredResult = result.filter(
            (item) => item.employeeId !== disabledOptions
          );

          setOptions((prev) =>
            page === 1 ? filteredResult : [...prev, ...filteredResult]
          );
        } else {
          setOptions((prev) => (page === 1 ? result : [...prev, ...result]));
        }
      } catch (error) {
        toast.error('Error fetching data:', error);
      } finally {
        setLoading(false);
      }
    }, 500),
    [apiUrl, pageSize, queryparamValue, sortBy, sortDirection]
  );

  useEffect(() => {
    if (initialLoad) {
      fetchData(pageCount, searchQuery);
    }
  }, [initialLoad, fetchData, pageCount, searchQuery, callApi]);

  const getAxiosConfig = () => {
    const token = localStorage.getItem('token');
    return {
      headers: {
        Authorization: `Bearer ${token}`,
      },
    };
  };

  // Label text Overwrite
  function processText(text, maxLength) {
    const isTruncated = text.length > maxLength;
    return isTruncated ? `${text.slice(0, maxLength)}...` : text;
  }

  const getOptionLabel = (option) => {
    if (option.isLoading) return 'Loading more...';
    return (
      showField
        ?.map((field) => option[field])
        .filter(Boolean)
        .join(' - ') || ''
    );
  };

  const handleScroll = (event) => {
    const scrollContainer = event.target;
    const bottom =
      scrollContainer.scrollHeight - 5 <
      scrollContainer.scrollTop + scrollContainer.clientHeight;

    if (bottom && hasMore && !loading) {
      listboxRef.current = scrollContainer.scrollTop;
      setPageCount((prev) => prev + 1);
    }
  };

  useEffect(() => {
    if (listboxRef.current && !loading) {
      const lazyContainer = document.querySelector('#lazyLoadDropdown-listbox');
      if (lazyContainer && listboxRef.current !== null) {
        lazyContainer.scrollTop = listboxRef.current;
      }
    }
  }, [loading]);

  const CustomListbox = forwardRef((props, ref) => (
    <TableBody
      ref={ref}
      {...props}
      onScroll={handleScroll}
      sx={{
        display: 'block',
        padding: '0 !important',
        transition: '0.5s all',
        '& .MuiTableRow-root': {
          display: 'table-row !important',
        },
      }}
    />
  ));

  const renderCellContent = (field: string, option: any) => {
    if (stackedFields && stackedFields[field]) {
      return (
        <Box className='flex flex-col gap-1'>
          {stackedFields[field].map((titleValuePair, subIndex) => {
            const [title, fieldName] = Object.entries(titleValuePair)[0];
            return (
              <Box key={subIndex}>
                <span className='text-gray-500 font-medium'>{title}</span>
                <span className='mx-1'>-</span>
                <span className={subIndex > 0 ? 'text-gray-600' : ''}>
                  {option[fieldName]}
                </span>
              </Box>
            );
          })}
        </Box>
      );
    }
    return <Box className='title'>{option[field]}</Box>;
  };

  return (
    <Controller
      name={name}
      control={control}
      render={({ field, fieldState }) => (
        <Box className='w-full relative'>
          <Autocomplete
            id='lazyLoadDropdown'
            disabled={disabled}
            freeSolo
            forcePopupIcon            
            options={loading ? [...options, { isLoading: true }] : options}
            filterOptions={(options) => options}
            value={
              options.find((option) => option[valueField] === field.value) ||
              defaultValueState ||
              null
            }
            getOptionLabel={getOptionLabel}
            ListboxComponent={CustomListbox}
            renderOption={(props, option) => (
              <TableRow
                {...props}
                sx={{
                  cursor: 'pointer',
                '&[aria-selected="true"]': {
                  backgroundColor: 'background.mainLight',
                  '& td': {
                    fontWeight: 600,
                  },
                },
                  '&:hover, &.Mui-focused': {
                    backgroundColor: 'background.tableHover',
                  },
                }}
                key={option.id}
                className={option.statusName === 'INACTIVE' && 'bg-red-100'}
              >
                {labelField?.map((field, index) => (
                  <TableCell
                    key={index}
                    colSpan={labelField?.length === 1 && 2}
                  >
                    {renderCellContent(field, option)}
                  </TableCell>
                ))}
              </TableRow>
            )}
            PaperComponent={(props) => (
              <Paper
                {...props}
                sx={{
                  my: 0.5,
                  minWidth: labelField?.length > 2 ? '600px' : '400px',
                  boxShadow: 'rgba(0, 0, 0, 0.25) 0px 25px 50px -12px',
                  border: '1px solid',
                  borderColor: 'border.main',
                }}
              >
                <TableContainer
                  sx={{
                    position: 'relative',
                    '& .MuiTableCell-root': {
                      fontSize: 'inherit',
                      color: 'inherit',
                      padding: '5px 10px',
                      wordBreak: 'break-word',
                      border: '1px solid',
                      borderColor: 'border.main',
                      width: labelField?.length === 1 ? '400px' : '250px',
                      '&.full-width': {
                        width: labelField?.length > 2 ? '600px' : '400px',
                      },
                    },
                    '& .MuiTableCell-head, & .MuiTableCell-footer': {
                      border: 'none',
                    },
                    '& .MuiTableFooter-root': {
                      borderTop: '1px solid',
                      borderColor: 'border.main',
                    },
                  }}
                >
                  {loading && (
                    <Box
                      sx={{
                        position: 'absolute',
                        left: 0,
                        top: 0,
                        bgcolor: 'background.overlay',
                        zIndex: 1,
                      }}
                      className='w-full h-full flex justify-center items-center'
                    >
                      <CircularProgress color='primary' size={40} />
                    </Box>
                  )}

                  <Table sx={{ tableLayout: 'fixed' }}>
                    <TableHead>
                      <TableRow
                        sx={{ display: 'block', bgcolor: 'background.light' }}
                      >
                        {(Array.isArray(headerField) && headerField.length > 0
                          ? headerField
                          : ['Code', 'Name']
                        ).map((header, index) => (
                          <TableCell key={index} sx={{ fontWeight: 'bold' }}>
                            {header}
                          </TableCell>
                        ))}
                      </TableRow>
                    </TableHead>
                    {props.children}
                    <TableFooter sx={{ display: 'block' }}>
                      <TableRow>
                        <TableCell
                          colSpan={labelField?.length > 1 ? 2 : 1}
                          className={labelField?.length > 1 ? 'full-width' : ''}
                        >
                          <Box className='flex justify-between items-center w-full'>
                            <Box className='flex-1'>
                              Total Results:{' '}
                              <span className='font-semibold'>
                                {' '}
                                {totalLength}
                              </span>
                            </Box>
                            <Box className='text-end flex-1'>
                              {options.length} / {totalLength}
                            </Box>
                          </Box>
                        </TableCell>
                      </TableRow>
                    </TableFooter>
                  </Table>
                </TableContainer>
              </Paper>
            )}
            slotProps={{
              popper: {
                sx: {
                  minWidth: labelField?.length > 2 ? '600px !important' : '400px !important',
                  width: '100%',
                },
              },
            }}
            renderInput={(params) => (
              <TextField
                {...params}
                label={processText(label, 40)}
                title={label}
                disabled={disabled || loading}
                variant='filled'
                required={required}
                error={!!fieldState.error}
                inputRef={inputRef}
                sx={{
                  '& .MuiInputLabel-root': {
                    '& .MuiInputLabel-asterisk': {
                      color: 'red',
                    },
                  },
                }}
                onBlur={() => {
                  field.onBlur();
                  onBlur?.();
                }}
                helperText={fieldState.error ? fieldState.error.message : null}
              />
            )}
            onInputChange={(_event, value, reason) => {
              if (reason === 'clear') {
                field.onChange(null);
                //TODO
                if (clearSelection) {
                  clearSelection(name);
                }
                setDefaultValueState(null);
                setPageCount(1);
                setSearchQuery('');
              }
              if (reason === 'input') {
                setPageCount(1);
                setSearchQuery(value);
                field.onChange(value);
              }
            }}
            onChange={(_event, option, reason) => {
              if (reason === 'selectOption') {
                field.onChange(option ? option[valueField] : null);
                onChangeProp
                  ? onChangeProp(option)
                  : field.onChange(option[valueField]);
              }
              if (reason === 'clear') {
                field.onChange(null);
                //TODO
                if (clearSelection) {
                  clearSelection(name);
                }
                setDefaultValueState(null);
                setPageCount(1);
                setSearchQuery('');
                // fetchData(1,'')
              }
            }}
            onOpen={() => {
              setTimeout(() => {
                setInnitialLoad(true);
                const lazyContainer = document.querySelector(
                  '#lazyLoadDropdown-listbox'
                );
                if (lazyContainer) {
                  lazyContainer.addEventListener('scroll', handleScroll);
                }
              }, 100);
            }}
            onClose={() => {
              setInnitialLoad(true);
              const lazyContainer = document.querySelector(
                '#lazyLoadDropdown-listbox'
              );
              if (lazyContainer) {
                lazyContainer.removeEventListener('scroll', handleScroll);
              }
            }}
          />
          {options.length === 0 && initialLoad && (
            <Box
              className='flex gap-2 items-center justify-end pe-1'
              sx={{
                position: 'absolute',
                left: '0',
                top: '3px',
                color: 'red',
                textTransform: 'uppercase',
                width: '100%',
                fontSize: '8px',
                fontWeight: 600,
              }}
            >
              <span>No data found</span>
            </Box>
          )}
        </Box>
      )}
    />
  );
};

export default FmSearchableSelect;
