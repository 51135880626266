import React, { useState } from 'react';
import { Controller, useForm } from 'react-hook-form';
import FmMRTDataTable from '../../../../../../components/FmMRTDataTable.tsx';
import {
  Box,
  Button,
  FormControl,
  FormControlLabel,
  Grid,
  Radio,
  RadioGroup,
} from '@mui/material';
import TableActionButtons from '../../../../../../components/_form/TableActionButtons';
import FmTextField from '../../../../../../components/_mui/FmTextField.tsx';
import FmSearchableSelect from '../../../../../../components/_mui/FmSearchableSelect.tsx';
import { DatePicker } from '@mui/x-date-pickers';
import dayjs from 'dayjs';
import MuiDatePickerOne from '../../../../../../components/_mui/MuiDatePickerOne.tsx';

const BoldBox = ({ title }) => {
  return <Box className='font-semibold'>{title}</Box>;
};
const WitnessDataTable = (props) => {
  const {
    rows,
    tableRecordCounts,
    editingRowId,
    editingRowData,
    ActionData,
    handleCancel,
    pagination,
    sorting,
    setPagination,
    setSorting,
    columnFilters,
    globalFilter,
    setColumnFilters,
    setGlobalFilter,
    isLoading,
    setSearchKeyword,
    searchKeyword,
    crud,
    handleUpdateWitness,
  } = props;
  const defaultValues = {
    loginUserId: editingRowData?.accidentWitnessUserId || null,
    loginUserName: editingRowData?.accidentWitnessUserName || '',
  };
  const { handleSubmit, control, setValue } = useForm({
    defaultValues,
  });
  const [radioChange, setRadioChange] = useState(1);

  const columns = [
    {
      header: 'Actions',
      enableColumnPinning: true,
      enableEditing: false,
      enableSorting: false,
      Cell: ({ row }) => (
        <Box className='flex gap-2'>
          {editingRowId === row?.original?.accidentWitnessId ? (
            <>
              <Button onClick={handleSubmit(handleUpdateWitness)}>Save</Button>
              <Button onClick={handleCancel}>Cancel</Button>
            </>
          ) : (
            <TableActionButtons
              Actions={ActionData?.map((action) => ({
                ...action,
                onClick: () => action.onClick(row?.original),
              }))}
            />
          )}
        </Box>
      ),
    },
    {
      accessorKey: 'accidentWitnessCode',
      header: 'Accident Witness Code',
      enableEditing: false,
      Cell: ({ cell }) => {
        return <BoldBox title={cell?.getValue()} />;
      },
    },
    {
      accessorKey: 'accidentWitnessNameOrUser',
      header: 'Witness',
      enableEditing: true,
      Cell: ({ row }) => {
        return editingRowId === row?.original?.accidentWitnessId ? (
          <Grid container spacing={2}>
            <Grid item md={12}>
              <FormControl>
                <RadioGroup
                  sx={{ flexDirection: 'row' }}
                  defaultValue={
                    row?.original?.accidentWitnessName !== '' ? 2 : 1
                  }
                  onChange={(e) =>
                    setRadioChange(
                      row?.original?.accidentWitnessName !== '' ? 2 : 1
                    )
                  }
                >
                  <FormControlLabel
                    value={1}
                    control={<Radio />}
                    label='Witness Users'
                  />
                  <FormControlLabel
                    value={2}
                    control={<Radio />}
                    label='Witness Name'
                  />
                </RadioGroup>
              </FormControl>
            </Grid>

            <Grid item md={12}>
              {radioChange === 1 ? (
                <FmSearchableSelect
                  name='accidentWitnessUserId'
                  control={control}
                  apiUrl='loginusers'
                  valueField='loginUserId'
                  headerField={['Email', 'Name']}
                  labelField={['emailId', 'loginUserName']}
                  showField={['loginUserName']}
                  
                  defaultValue={defaultValues}
                />
              ) : (
                <FmTextField
                  name='accidentWitnessName'
                  control={control}
                  defaultValue={editingRowData?.accidentWitnessName}
                  label='Accident Witness Name'
                />
              )}
            </Grid>
          </Grid>
        ) : (
          row?.original?.accidentWitnessUserName ||
            row?.original?.accidentWitnessName
        );
      },
    },
    {
      accessorKey: 'dateOfWorkResumption',
      header: 'Date of Resumption',
      enableEditing: true,
      Cell: ({ row }) => {
        return editingRowId === row?.original?.accidentWitnessId ? (
          <Controller
            name='dateOfWorkResumption'
            control={control}
            defaultValue={editingRowData?.dateOfWorkResumption}
            render={({ field }) => (
              <MuiDatePickerOne
                {...field}
                format='DD-MM-YYYY'
                value={field?.value ? dayjs(field?.value) : null}
                onChange={field.onChange}
              />
            )}
          />
        ) : row?.original?.dateOfWorkResumption ? (
          dayjs(row?.original?.dateOfWorkResumption).format('DD-MM-YYYY')
        ) : (
          ''
        );
      },
    },
  ];

  return (
    <div>
      <FmMRTDataTable
        columns={columns}
        enableRowSelection={false}
        rows={rows}
        rowCount={tableRecordCounts}
        editingRow={editingRowId}
        pagination={pagination}
        sorting={sorting}
        columnFilters={columnFilters}
        globalFilter={globalFilter}
        setPagination={setPagination}
        setSorting={setSorting}
        setColumnFilters={setColumnFilters}
        setGlobalFilter={setGlobalFilter}
        isLoading={isLoading}
        setSearchKeyword={setSearchKeyword}
        searchKeyword={searchKeyword}
      />
    </div>
  );
};

export default WitnessDataTable;
