import React, { useRef } from 'react';
import { Box, TextField, IconButton } from '@mui/material';
import { LocalizationProvider } from '@mui/x-date-pickers/LocalizationProvider';
import { AdapterDayjs } from '@mui/x-date-pickers/AdapterDayjs';
import { DatePicker } from '@mui/x-date-pickers/DatePicker';
import { Icon } from '@iconify/react';

const FmDateTableFilter = ({ value, onChange, onHandlePrev, onHandleNext }) => {
  const anchorElRef = useRef(null);
  return (
    <Box className='px-2 flex gap-2 items-center'>
      <Box className='font-semibold'>Filter</Box>
      <Box className='flex gap-2 items-center'>
        {onHandlePrev && (
          <IconButton onClick={onHandlePrev}>
            <Icon icon='la:angle-left' width='20' height='20' />
          </IconButton>
        )}
        <Box className='w-[180px]' ref={anchorElRef}>
          <LocalizationProvider dateAdapter={AdapterDayjs}>
            <DatePicker
              value={value}
              onChange={onChange}
              views={['year', 'month']}
              slots={{
                textField: (params) => (
                  <TextField
                    {...params}
                    fullWidth
                    variant={'outlined'}
                    className={'filter-date'}
                  />
                ),
                openPickerIcon: () => (
                  <Icon icon='stash:data-date-light' width='24' height='24' />
                ),
              }}
              slotProps={{
                popper: {
                  anchorEl: () => anchorElRef.current,
                },
              }}
            />
          </LocalizationProvider>
        </Box>
        {onHandleNext && (
          <IconButton onClick={onHandleNext}>
            <Icon icon='la:angle-right' width='20' height='20' />
          </IconButton>
        )}
      </Box>
    </Box>
  );
};

export default FmDateTableFilter;
