import React from 'react';
import { Tooltip } from '@mui/material';

const IconWithTooltip = ({ icon, title }) => {
  return (
    <Tooltip title={title}>
      <div className='inline-block'>{icon}</div>
    </Tooltip>
  );
};

export default IconWithTooltip;
